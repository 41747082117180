<template>
  <div>
    <div class="sk-modal-section__parameter__criteria">
      <div class="sk-modal-section__parameter__criteria--display-flex">
        <div class="sk-modal-section__parameter__criteria__criteria-number">
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.criteria') }} {{ index + 1 }}
        </div>
        <div class="sk-modal-section__parameter__criteria__criteria-choice">
          <template v-if="isTriggerTypeHours || isTriggerTypeWorkDurationAndHours">
            <div
              class="sk-modal-section__parameter__criteria__section"
            >
              <div class="sk-modal-section__parameter__criteria__section--sk-input-title">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.hours.work_start_at') }}
              </div>
              <div
                class="sk-modal-section__parameter__criteria__section--sk-input-group"
              >
                <SkInputGroup separator>
                  <template #prepend>
                    <SkTimePicker
                      v-model="mealAdditionTriggerStartsAt"
                      :interval-in-minutes="15"
                    />
                  </template>
                  <!-- eslint-disable-next-line max-len -->
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.and') }}
                  <template #append>
                    <SkTimePicker
                      v-model="mealAdditionTriggerEndsAt"
                      :interval-in-minutes="15"
                    />
                  </template>
                </SkInputGroup>
              </div>
            </div>
          </template>
          <template v-if="isTriggerTypeWorkDuration || isTriggerTypeWorkDurationAndHours">
            <!-- eslint-disable-next-line max-len -->
            <div class="sk-modal-section__parameter__criteria__section">
              <div class="sk-modal-section__parameter__criteria__section--sk-input-title">
                <template v-if="!isTriggerTypeWorkDurationAndHours">
                  <!-- eslint-disable-next-line max-len -->
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.work_duration.min_max_hours') }}
                </template>
                <template v-else>
                  <!-- eslint-disable-next-line max-len -->
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.work_duration.min_max_hours_and') }}
                </template>
              </div>
              <div class="sk-modal-section__parameter__criteria__section--sk-input-group">
                <!-- eslint-disable max-len -->
                <SkInputGroup
                  :errored="errorForm"
                  :error-message="$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.hours.empty_hours_error')"
                  unit="h"
                  separator
                >
                  <template #prepend>
                    <SkInput
                      v-model="mealAdditionTriggerWorkDurationMin"
                      type="number"
                      min="0"
                      step="1"
                      max="23"
                    />
                    <!-- eslint-enable max-len -->
                  </template>
                  <!-- eslint-disable-next-line max-len -->
                  {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.and') }}
                  <template #append>
                    <!-- eslint-disable max-len -->
                    <SkInput
                      v-model="mealAdditionTriggerWorkDurationMax"
                      type="number"
                      min="0"
                      step="1"
                      max="23"
                    />
                    <!-- eslint-enable max-len -->
                  </template>
                </SkInputGroup>
              </div>
            </div>
          </template>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <div class="sk-modal-section__parameter__criteria__section sk-modal-section__parameter__criteria__criteria-meal pl-5">
          <ClosingBraceIcon v-if="isTriggerTypeWorkDurationAndHours" />
          <template>
            <div
              class="sk-modal-section__parameter__criteria__section--sk-input-title
                modal-section__parameter__criteria__total"
            >
              <!-- eslint-disable-next-line max-len -->
              {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.meal_count.meal_count') }}
            </div>
            <div class="sk-modal-section__parameter__criteria__section--sk-input">
              <!-- eslint-disable max-len -->
              <SkInput
                v-model="mealAdditionTriggerMealCount"
                :errored="errorMealCount"
                type="number"
                step="1"
                min="0"
              />
              <!-- eslint-disable max-len -->
            </div>
          </template>
        </div>
      </div>
      <template v-if="canDeleteMealAdditionTrigger">
        <div class="sk-modal-section__parameter__criteria__action-delete">
          <SkCircleButton
            v-track="'meal_delete_criteria'"
            icon="TrashCanV2Icon"
            @click="deleteMealAdditionTrigger"
          />
        </div>
      </template>
    </div>
    <SkOroraButton
      v-if="canAddMealAdditionTrigger"
      v-track="'meal_add_criteria'"
      variant="tertiary"
      icon="PlusSignV2Icon"
      class="mt-2"
      @click.stop="addMealAdditionTrigger"
    >
      <!-- eslint-disable-next-line max-len -->
      {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.add_criteria') }}
    </SkOroraButton>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'MealRulesSettingCriteria',
  props: {
    currentStep: {
      type: String,
      required: true,
    },
    mealAdditionTrigger: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('mealRule', ['mealRule']),
    ...mapState('config', ['config']),
    isTriggerTypeHours() {
      return this.triggerType === this.config.meal_rules.trigger_types.hours;
    },
    isTriggerTypeWorkDuration() {
      return this.triggerType === this.config.meal_rules.trigger_types.work_duration;
    },
    isTriggerTypeWorkDurationAndHours() {
      return this.triggerType === this.config.meal_rules.trigger_types.work_duration_and_hours;
    },
    triggerType() {
      return this.mealRule.attributes.triggerType[this.currentStep];
    },
    errorMealCount() {
      return isNaN(parseInt(this.mealAdditionTriggerMealCount, 10)) ||
        this.mealAdditionTriggerMealCount < 0;
    },
    errorWorkDurationMin() {
      return isNaN(parseInt(this.mealAdditionTriggerWorkDurationMin, 10)) ||
        this.mealAdditionTriggerWorkDurationMin < 0 ||
        this.mealAdditionTriggerWorkDurationMin > 23;
    },
    errorWorkDurationMax() {
      return isNaN(parseInt(this.mealAdditionTriggerWorkDurationMax, 10)) ||
        this.mealAdditionTriggerWorkDurationMax < 0 ||
        this.mealAdditionTriggerWorkDurationMax > 23;
    },
    errorForm() {
      return this.errorMealCount || this.errorWorkDurationMin || this.errorWorkDurationMax;
    },
    canAddMealAdditionTrigger() {
      return this.index < this.config.meal_rules.max_triggers && this.index + 1 === this.length;
    },
    canDeleteMealAdditionTrigger() {
      return this.index !== 0;
    },
    mealAdditionTriggerMealCount: {
      get() {
        return this.mealAdditionTrigger.attributes.mealCount;
      },
      set(newValue) {
        this.mealAdditionTrigger.attributes.mealCount = newValue;
      },
    },
    mealAdditionTriggerWorkDurationMin: {
      get() {
        return this.mealAdditionTrigger.attributes.workDurationMin;
      },
      set(newValue) {
        this.mealAdditionTrigger.attributes.workDurationMin = newValue;
      },
    },
    mealAdditionTriggerWorkDurationMax: {
      get() {
        return this.mealAdditionTrigger.attributes.workDurationMax;
      },
      set(newValue) {
        this.mealAdditionTrigger.attributes.workDurationMax = newValue;
      },
    },
    mealAdditionTriggerStartsAt: {
      get() {
        const startsAtHour = this.mealAdditionTrigger.attributes.rangeStartsAtHour;
        const startsAtMin = this.mealAdditionTrigger.attributes.rangeStartsAtMin;
        const startsAt = (startsAtMin + (startsAtHour * 100)).toString();

        return skDate(this.cleanTimeValue(startsAt), 'Hmm').format();
      },
      set(newValue) {
        this.mealAdditionTrigger.attributes.rangeStartsAtHour = skDate(newValue).hours();
        this.mealAdditionTrigger.attributes.rangeStartsAtMin = skDate(newValue).minutes();
      },
    },
    mealAdditionTriggerEndsAt: {
      get() {
        const endsAtHour = this.mealAdditionTrigger.attributes.rangeEndsAtHour;
        const endsAtMin = this.mealAdditionTrigger.attributes.rangeEndsAtMin;
        const endsAt = (endsAtMin + (endsAtHour * 100)).toString();

        return skDate(this.cleanTimeValue(endsAt), 'Hmm').format();
      },
      set(newValue) {
        this.mealAdditionTrigger.attributes.rangeEndsAtHour = skDate(newValue).hours();
        this.mealAdditionTrigger.attributes.rangeEndsAtMin = skDate(newValue).minutes();
      },
    },
    errorWorkDuration() {
      if (this.mealAdditionTriggerWorkDurationMin === 0) return false;
      return !this.mealAdditionTriggerWorkDurationMin || !this.mealAdditionTriggerWorkDurationMax;
    },
  },
  watch: {
    errorForm() {
      this.$emit('handle-error-form', this.errorForm);
    },
  },
  methods: {
    addMealAdditionTrigger() {
      this.$emit('add-meal-addition-trigger', {
        order: this.length + 1,
        step: this.currentStep,
      });
    },
    deleteMealAdditionTrigger() {
      this.$emit('delete-meal-addition-trigger', this.mealAdditionTrigger.id);
    },
    cleanTimeValue(value) {
      // if midnight is set, value = 0, so *100 is still 0
      if (value.length === 1) {
        return '00'.concat(value);
      }
      if (value.length === 2) {
        return '0'.concat(value);
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-modal-section__parameter__criteria {
  border: 1px solid $sk-grey-10;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;

  .modal-section__parameter__criteria__total {
    max-width: 72px;
  }

  .sk-modal-section__parameter__criteria--display-flex {
    display: flex;
  }

  .sk-modal-section__parameter__criteria__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .sk-modal-section__parameter__criteria__action-delete {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .sk-modal-section__parameter__criteria__criteria-number {
    width: 12%;
    height: 20px;
    font-size: $fs-text-s;
    font-weight: bold;
    color: $sk-grey;
    padding-top: 13px;
  }

  .sk-modal-section__parameter__criteria__criteria-choice {
    width: 58%;
  }

  .sk-modal-section__parameter__criteria__criteria-meal {
    width: 30%;
  }

  .sk-modal-section__parameter__criteria__section--sk-input-group {
    width: 150px;
    height: 45px;
  }

  .sk-modal-section__parameter__criteria__section--sk-input-title {
    font-weight: bold;
  }

  .sk-modal-section__parameter__criteria__section--sk-input {
    width: 60px;
    height: 45px;
  }
}

</style>
