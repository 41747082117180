<template>
  <table class="employee-shifts-table">
    <TableHeader :on-day-rate="onDayRate" />
    <WeekRow
      v-for="monday in weekRange"
      :key="monday.format()"
      :shifts="getWeekShiftsFromMonday(monday)"
      :monday="monday.format()"
      :shift-type="shiftType"
      :on-day-rate="onDayRate"
    />
  </table>
</template>

<script>
import skDate from '@skello-utils/dates';
import { ALL_SHIFT_TYPE } from '@app-js/shared/constants/shift';
import TableHeader from './TableHeader';
import WeekRow from './WeekRow';

export default {
  name: 'EmployeeShiftsTable',
  components: {
    TableHeader,
    WeekRow,
  },
  props: {
    shifts: {
      type: Array,
      required: true,
    },
    shiftType: {
      type: String,
      default: ALL_SHIFT_TYPE,
    },
    onDayRate: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Store every week's shift with the week monday as the key (YYYY-MM-DD)
      shiftsByWeek: {},
    };
  },
  computed: {
    weekFrom() {
      return this.skStartDate.isoWeek();
    },
    weekTo() {
      return this.skEndDate.isoWeek();
    },
    skStartDate() {
      return skDate.utc(this.startDate);
    },
    skEndDate() {
      return skDate.utc(this.endDate);
    },
    // This method returns a list of all mondays between the start_date and the end_date.
    // These mondays are then used in the WeekRow component to show week numbers and put shifts
    // in the correct row.
    weekRange() {
      if (!this.weekFrom || !this.weekTo) return undefined;
      const monday = this.skStartDate.clone().startOf('isoWeek');
      const mondaysBetweenStartAndEndDate = [];

      while (monday.isBefore(this.skEndDate.clone().endOf('isoWeek'))) {
        mondaysBetweenStartAndEndDate.push(skDate.utc(monday));
        monday.add(1, 'week');
      }

      return mondaysBetweenStartAndEndDate;
    },
  },
  created() {
    this.groupShiftsByWeek();
  },
  methods: {
    groupShiftsByWeek() {
      this.shiftsByWeek = this.shifts.reduce((obj, shift) => {
        const shiftMonday = skDate.utc(shift.attributes.startsAt)
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD');

        if (!obj[shiftMonday]) {
          obj[shiftMonday] = [shift];
        } else {
          obj[shiftMonday].push(shift);
        }

        return obj;
      }, {});
    },
    getWeekShiftsFromMonday(monday) {
      return this.shiftsByWeek[monday.format('YYYY-MM-DD')] || [];
    },
  },
};
</script>

<style lang="scss">
.employee-shifts-table {
  width: 100%;

  tr {
    height: 60px;
    border-bottom: 1px solid $sk-grey-10;
  }

  th,
  td {
    padding: 0 7px;
    max-width: 100px;
    min-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .employee-shifts-table__column__date {
    min-width: 110px;
  }

  .employee-shifts-table__column__poste,
  .employee-shifts-table__column__shop,
  .employee-shifts-table__column__start,
  .employee-shifts-table__column__note {
    min-width: 100px;
  }

  .employee-shifts-table__column__note,
  .employee-shifts-table__column__working-time {
    text-align: center;
  }
}
</style>
