<template>
  <TimeclockChecklist v-if="shouldShowTimeclockDemoChecklist" />
</template>

<script>
import { mapGetters } from 'vuex';
import TimeclockChecklist from './TimeclockChecklist';

export default {
  name: 'ChecklistContainer',
  components: {
    TimeclockChecklist,
  },
  computed: {
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockDemoChecklist']),
  },
};
</script>
