<template>
  <div class="badgings__fixed-inner">
    <div
      v-if="displayDayBadgingInformation"
      class="day-badging__information-container"
    >
      <div class="day-badging__information__container__inner">
        <div class="day-badging__information__container__inner__content">
          <CircledIIcon
            fill="#2b66fe"
            class="day-badging__information__container__inner__content__information-icon"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="dayBadgingInformationText" />
        </div>
        <span>
          <a
            class="day-badging__information__close-link"
            @click="closeDayBadgingInformation"
          >
            <b class="day-badging__information__close-link__content">
              {{ $t('badgings.day_badging_information.i_understood') }}
            </b>
          </a>
        </span>
      </div>
    </div>
    <ShiftsAbsencesTabs
      v-if="showShiftsAbsencesTabs"
      data-test="ShiftsAbsencesTabs"
    />
    <router-view />
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
} from 'vuex';
import ShiftsAbsencesTabs from '@app-js/badgings/shared/components/ShiftsAbsencesTabs';
import { FEATURES } from '@app-js/shared/constants/features.js';

export default {
  name: 'Days',
  components: { ShiftsAbsencesTabs },
  data() {
    return {
      showDayBadgingInformation: true,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('punchClock', ['punchClockSettings']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentShop', ['isHospitalitySector']),
    ...mapGetters('features', ['areFeaturesEnabled']),
    isDayClocksInOut() {
      return this.punchClockSettings.dayClocksInOut;
    },
    displayDayBadgingInformation() {
      return localStorage.getItem('closedDayBadgingInformationUserId') !== this.currentUser.id &&
        !this.isHospitalitySector &&
        this.isDayClocksInOut &&
        this.showDayBadgingInformation;
    },
    dayBadgingInformationText() {
      return this.$t('badgings.day_badging_information.text');
    },
    showShiftsAbsencesTabs() {
      return this.areFeaturesEnabled(
        [FEATURES.FEATURE_ABSENCES, FEATURES.FEATURE_PLANNING], this.currentShop.id, () => true,
      );
    },
  },
  methods: {
    closeDayBadgingInformation() {
      this.showDayBadgingInformation = false;
      localStorage.setItem('closedDayBadgingInformationUserId', this.currentUser.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.day-badging__information-container {
  width: 100%;
  height: 44px;
  margin-top: 31px;
  margin-bottom: 8px;
  border: 1px solid $sk-grey-10;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: $sk-grey-50;
  border-radius: 4px;
}

.day-badging__information__container__inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.day-badging__information__container__inner__content {
  display: flex;
  align-items: center;
}

.day-badging__information__container__inner__content__information-icon {
  margin-right: 16px;
}

.day-badging__information__close-link {
  cursor: pointer;
}

.day-badging__information__close-link__content {
  display: flex;
  align-items: center;
}
</style>
