<template>
  <div :class="wrapperClasses">
    <div
      v-show="displayShiftSwapper"
      class="user-shift-swapper__arrows-wrapper"
      @mouseenter="$emit('mouse-inside-shift-swapper', true)"
      @mouseleave="$emit('mouse-inside-shift-swapper', false)"
    >
      <DoubleArrowIcon class="user-shift-swapper__arrows-icon" />
    </div>
    <div :class="rectangleClasses">
      <slot name="dayCells" />
    </div>
  </div>
</template>
<script>

export default {
  name: 'UserShiftSwapper',
  props: {
    displayShiftSwapper: {
      type: Boolean,
      required: true,
    },
    isDraggedOver: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'user-shift-swapper__wrapper': true,
        'user-shift-swapper__wrapper--dragged-over': this.isDraggedOver,
      };
    },
    rectangleClasses() {
      return {
        'user-shift-swapper__rectangle': true,
        'user-shift-swapper__rectangle--hidden': !this.displayShiftSwapper,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.user-shift-swapper__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background: none;

  &--dragged-over {
    background-color: $sk-grey-10;
    border-radius: 6px;
    box-shadow: inset 0 0 0 3px white, inset 0 -4px 0 0 white;
  }
}

.user-shift-swapper__arrows-wrapper {
  background-color: $sk-blue;
  position: relative;
  margin-left: -20px;
  min-width: 20px;
  border-radius: 6px 0 0 6px;
  margin-bottom: 1px; // avoid spread over planning bottom border
  cursor: grab;
  z-index: 11;
}

.user-shift-swapper__arrows-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-shift-swapper__rectangle {
  display: flex;
  width: 100%;
  box-shadow: 0 2px 0 0 $sk-blue inset, -2px -3px 0 0 $sk-blue inset;

  &--hidden {
    box-shadow: none;
  }
}
</style>
