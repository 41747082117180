<template>
  <ApexChart
    type="bar"
    height="100%"
    :width="chartWidth"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { bigNumberFormatter } from '../shared/utils/numbers_formatter';

export default {
  name: 'StackedColumn',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    seriesColors: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    chartWidth: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    chartOptions() {
      return {
        colors: this.seriesColors,
        legend: { show: false },
        chart: {
          stacked: true,
          width: this.chartWidth,
          toolbar: {
            show: false,
          },
          events: {
            mounted: () => { this.$emit('load'); },
          },
        },
        // states are specific to the bar behavior
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
          },
          series: { stacking: 'normal' },
        },
        grid: {
          borderColor: '#dddddd',
          padding: {
            top: -15,
            right: 30,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          categories: this.categories,
          axisTicks: { show: true },
          labels: {
            style: {
              fontFamily: 'Gellix, sans-serif',
              colors: new Array(this.categories.length).fill('#727272'),
              fontSize: '10px',
            },
          },
          axisBorder: {
            show: true,
            color: '#dddddd',
            height: 1,
          },
        },
        dataLabels: { enabled: false },
        yaxis: {
          // Currently used for revenue and labor cost. No need for the yaxis to be negative
          min: 0,
          // Set the max to the highest value + 10%
          // Set the max to 5 if no values
          max: max => (max ? Math.ceil(max * 1.1) : 5),
          forceNiceScale: true,
          labels: {
            style: {
              fontFamily: 'Gellix, sans-serif',
              colors: ['#727272'],
              fontSize: '10px',
            },
            // Here we need an arrow function to access
            // the global 'this' instead of this function local 'this'
            formatter: val => `${bigNumberFormatter(val)}${this.unit}`,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          marker: { show: false },
          custom: this.customTooltip,
        },
      };
    },
  },
  methods: {
    customTooltip({ series, dataPointIndex }) {
      this.$emit('data-event', { series, dataPointIndex });
      return '<div class="alex-special" />';
    },
  },
};
</script>
