class ShiftAnimationStack {
  constructor(processShift, onAnimationStart, onAnimationEnd) {
    this._batchSize = 1;
    this._processShift = processShift;
    this._onAnimationStart = onAnimationStart;
    this._onAnimationEnd = onAnimationEnd;
    this._shiftStack = [];
    this._animationRunning = false;
  }

  addShifts(shifts) {
    this._shiftStack.push(...shifts);

    if (!this._animationRunning) {
      // Performance improvement:
      // If there are too many shifts to animate,
      // Increase batch size to start moving shifts faster
      this._batchSize = shifts.length > 30 ? 5 : 1;
      this._onAnimationStart();
      this.animateShifts();
    }
  }

  animateShifts() {
    this._animationRunning = true;

    setTimeout(
      () => {
        if (this._shiftStack.length <= this._batchSize) {
          this._onAnimationEnd();
        }

        // Take first shifts in list and remove from stack
        const shifts = this._shiftStack.splice(0, this._batchSize);

        if (shifts.length === 0) {
          // No more shifts to assign - stop animation
          this._animationRunning = false;
          return;
        }

        // Call weekPlanningVue.processShift on batch of shifts
        shifts.forEach(this._processShift);
        // Call next shifts animation
        this.animateShifts();
      },
      0,
    );
  }

  cancelAnimation() {
    this._animationRunning = false;
    this._shiftStack = [];
    this._onAnimationEnd();
  }
}

export default ShiftAnimationStack;
