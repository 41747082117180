<!-- eslint-disable max-len -->
<template>
  <SkModal
    id="pause-compensation-activation-modal"
    ref="pauseCompensationActivationModal"
    :modal-title="$t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.title')"
    size="inherited"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSection class="pause-compensation-modal-section">
        <p>{{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.description') }}</p>
        <p>{{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.explanation') }}</p>
        <div class="pause-compensation-modal-section__init-date">
          <div class="pause-compensation-modal-section__datepicker-title">
            <p class="">
              {{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.date.title') }}
            </p>
          </div>
          <div class="pause-compensation-modal-section__datepicker-wrapper">
            <SkDatePicker
              ref="datepicker"
              v-model="initDate"
              :disabled-date="disabledDays"
              :placeholder="$t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.date.placeholder')"
              :errored="hasDateError"
              :error-message="dateErrorMessage"
              :lang="$i18n.locale"
              input-moment-format="LL"
              hide-footer
              data-test="break-paid-datepicker"
            />
            <p class="pause-compensation-modal-section__date-hint sk-subtitle--small">
              {{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.date.hint') }}
            </p>
          </div>
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="hasDateError"
        :loading="submitLoading"
        data-test="break-paid-submit__btn"
        @click="handleSubmit"
      >
        {{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
<!-- eslint-enable max-len -->
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

export default {
  name: 'PauseCompensationActivationModal',
  data() {
    return {
      initDate: null,
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),

    isDateOnSunday() {
      if (!this.initDate) return false;

      return skDate(this.initDate).isSunday();
    },
    hasDateError() {
      return !this.initDate || !this.isDateOnSunday;
    },
    dateErrorMessage() {
      if (!this.isDateOnSunday) {
        return this.$t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.date.not_sunday');
      }

      return this.$t('errors.missing_value');
    },
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),
    ...mapMutations('currentShop', ['squashOriginalCurrentShopData', 'setShopAttributes']),

    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    handleCancel() {
      this.$refs.datepicker.resetModified();
      this.initDate = null;
      this.$emit('cancel', event);
    },
    handleSubmit() {
      this.submitLoading = true;

      const params = {
        pause_compensation: {
          pause_compensation_starts_at: this.initDate,
        },
      };

      httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}/pause_compensation`, params)
        .then(() => {
          this.setShopAttributes({ pauseCompensationStartsAt: skDate(this.initDate).add(1, 'd').format('YYYY-MM-DD') });
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_PAID_BREAK_ENABLED, {
            pauseCompensationStartsAt: params.pause_compensation.pause_compensation_starts_at,
          });
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.main_rules.pause_compensation.modal.activation_complete'),
            variant: 'success',
          });
          this.$refs.pauseCompensationActivationModal.hide();
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pause-compensation-modal-section {
  width: 570px;
  border-bottom: 0 !important;

  .pause-compensation-modal-section__init-date {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .pause-compensation-modal-section__datepicker-title {
    width: 200px;
    font-weight: $fw-semi-bold;
    line-height: 22px;
    display: flex;

    &:first-child {
      margin-top: 12px;
    }
  }

  .pause-compensation-modal-section__datepicker-wrapper {
    width: 340px;
  }

  .pause-compensation-modal-section__date-hint {
    margin-left: 10px;
  }
}
</style>
