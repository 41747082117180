<template>
  <div
    id="employees-sidebar"
    class="employees__sidebar"
  >
    <Transition name="employee">
      <ProfileCompletionNotification
        v-if="isPayrollPreparationEnabled && hasAtLeastOneIncompleteProfile"
        :class="profileCompletionNotificationClasses"
        data-test="profiles-completion-bar"
      >
        <template #action>
          <span @click.prevent="toggleProfileCompletion">
            {{ profileNotificationText }}
          </span>
        </template>
      </ProfileCompletionNotification>
    </Transition>
    <div
      v-if="!hideTabs && !showIncompleteEmployees"
      class="sidebar__navigation"
    >
      <SkNav>
        <SkNavItem
          :active="!isArchivedList"
          @click="selectTab('active')"
        >
          {{ $t('employees.sidebar.active') }}
        </SkNavItem>
        <SkNavItem
          :active="isArchivedList"
          @click="selectTab('archived')"
        >
          {{ $t('employees.sidebar.archived') }}
        </SkNavItem>
      </SkNav>
    </div>
    <div
      ref="scrollable_element"
      class="sidebar__employee-list"
    >
      <SkListItemCanvas
        v-if="loading"
        :item-count="5"
      />
      <div
        v-else-if="employees.length === 0"
        class="sidebar__employee-list__empty-item"
      >
        {{ $t('employees.sidebar.no_employees') }}
      </div>
      <EmployeeListItem
        v-for="employee in employees"
        v-else
        :key="employee.id"
        :employee="employee"
        :is-selected="isSelected(employee.id)"
      />
      <div
        v-if="loadingMore"
        class="sidebar__employee-list__spinner-wrapper"
      >
        <SkLoader class="sidebar__employee-list__spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import EmployeeListItem from './EmployeeListItem';
import ProfileCompletionNotification from '../../../shared/components/PayrollPreparation/ProfileCompletionNotification';

export default {
  name: 'EmployeesSidebar',
  components: {
    EmployeeListItem,
    ProfileCompletionNotification,
  },
  data() {
    return {
      localSelectedId: null,
    };
  },
  computed: {
    ...mapState('employees', [
      'loading',
      'employees',
      'pagination',
      'activeTab',
      'searchQuery',
      'hasAtLeastOneIncompleteProfile',
      'showIncompleteEmployees',
    ]),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapGetters('employees', [
      'isArchivedList',
      'tabHidden',
      'canAccessPayrollEmployee',
      'getRouteNameForPayrollPreparation',
    ]),
    hideTabs() {
      return this.searchQuery && this.searchQuery.length > 0;
    },
    loadingMore() {
      return !this.loading && this.pagination.currentPage < this.pagination.totalPages;
    },
    profileNotificationText() {
      return this.showIncompleteEmployees ?
        this.$t('employees.profile_completion_notification.quit') :
        this.$t('employees.profile_completion_notification.show');
    },
    isPayrollPreparationEnabled() {
      return this.canAccessPayrollEmployee;
    },
    profileCompletionNotificationClasses() {
      return {
        'sidebar-notification': true,
        'sidebar-notification--active': this.showIncompleteEmployees,
      };
    },
  },
  watch: {
    selectedEmployee(employee) {
      if (this.localSelectedId !== employee.id) {
        this.localSelectedId = employee.id;
      }
    },
  },
  created() {
    this.fetchOnScroll = debounce(this.fetchOnScroll.bind(this), 300);
  },
  mounted() {
    this.$refs.scrollable_element.addEventListener('scroll', this.fetchOnScroll);
  },
  beforeDestroy() {
    this.$refs.scrollable_element.removeEventListener('scroll', this.fetchOnScroll);
  },
  methods: {
    ...mapActions('employees', ['fetchNextPage', 'reloadSidebar']),
    ...mapMutations('employees', ['setActiveTab', 'setShowIncompleteEmployees']),
    async selectTab(tab) {
      this.setActiveTab({ activeTab: tab });
      await this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });
    },
    isSelected(employeeId) {
      return employeeId === this.localSelectedId;
    },
    fetchOnScroll(event) {
      const el = this.$refs.scrollable_element;
      const bottomList = el.scrollHeight - el.scrollTop <= el.offsetHeight + 80;

      if (bottomList) {
        const params = { cluster_node_id: this.navContext.clusterNodeId };

        if (this.canAccessPayrollEmployee) {
          params.incomplete_profiles = true;
          params.with_missing_attributes = this.showIncompleteEmployees;
        }

        this.fetchNextPage(params);
      }
    },
    toggleProfileCompletion() {
      if (this.showIncompleteEmployees) {
        this.$skAnalytics.track('payroll_preparation_reset_showed_employee_missing_data');
        this.selectTab('active').then(this.redirectToFirstUser);
      } else {
        this.$skAnalytics.track('payroll_preparation_showed_employee_missing_data');
        this.reloadSidebar({
          cluster_node_id: this.navContext.clusterNodeId,
          with_missing_attributes: true,
        }).then(this.redirectToFirstUser);
      }

      this.setShowIncompleteEmployees(!this.showIncompleteEmployees);
    },
    redirectToFirstUser() {
      if (this.employees?.length === 0) {
        return;
      }

      const firstId = this.employees[0].id;
      const shopId = this.$router.currentRoute.params.shop_id || 'all';
      const replaceParams = {
        name: this.getRouteNameForPayrollPreparation,
        params: { user_id: firstId, shop_id: shopId },
      };

      if (this.showIncompleteEmployees) {
        replaceParams.query = { incomplete_profiles_just_activated: true };
      }

      this.$router.replace(replaceParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.employees__sidebar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $sk-grey-10;
  width: 310px;
  padding: 0 10px;

  .sidebar__employee-list {
    overflow: auto;
  }

  .sidebar__employee-list__empty-item {
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fs-text-m;
  }

  .sidebar__employee-list__spinner-wrapper {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar__employee-list__spinner {
      &::after {
        color: $sk-blue;
      }
    }
  }

  .sidebar-notification {
    margin-top: 12px;

    &--active {
      margin-bottom: 8px;
    }
  }

  .employee-enter-active,
  .employee-leave-active {
    transition: opacity .6s ease;
  }

  .employee-enter-from,
  .employee-leave-to {
    opacity: .01;
  }
}
</style>
