<script>
export default {
  computed: {
    isAvailabilityRequestsView() {
      return this.$route.name.includes('availability_requests');
    },
    isLeaveRequestsView() {
      return this.$route.name.includes('leave_requests');
    },
    isOwnRequestsView() {
      return this.$route.name.includes('own');
    },
    isTeamsRequestsView() {
      return this.$route.name.includes('teams');
    },
    isAllRequestsView() {
      return this.$route.params.shop_id === 'all';
    },
  },
};
</script>
