<template>
  <tr class="shop__row">
    <td class="shop__row__shop-name">
      <div class="shop__row__shop-name__and-status">
        <div class="shop__row__name">
          {{ truncatedShopName }}
        </div>
        <SkTag
          v-if="shop.attributes.cancelled"
          variant="danger"
          with-bullet-point
          class="shop__row__status"
        >
          {{ $t('organisation_settings.tabs.shops.cancelled_shop') }}
        </SkTag>
      </div>
    </td>
    <td class="shop__row__users-count">
      {{ shop.attributes.usersCount }}
      {{ $t('organisation_settings.tabs.shops.employees') }}
    </td>
    <td class="shop__row__city">
      {{ shop.attributes.city }}
    </td>
    <td
      v-if="canEditShopBillingData"
      class="shop__row__actions-container"
    >
      <router-link :to="shopSettingsUrl">
        <SkCircleButton
          v-tooltip="$t('organisation_settings.tabs.shops.table.actions.go_to_shop.tooltip')"
          icon="CogV2Icon"
        />
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';

import { truncateString } from '@skello-utils/formatting/strings';

export default {
  name: 'ShopRow',
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopBillingData', 'isSystemAdmin']),

    truncatedShopName() {
      return truncateString(this.shop.attributes.name, 30);
    },
    shopSettingsUrl() {
      return {
        name: 'shop_settings_shop',
        params: { shop_id: this.shop.id },
      };
    },
  },
  methods: {
    handleDelete(event) {
      this.$refs.dropdown.hide();
      this.$emit('delete-shop', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.shop__row {
  &:hover {
    background: $sk-grey-5;
  }

  th,
  td {
    padding: 15px 0 15px 10px !important;
  }

  .shop__row__shop-name {
    width: 45%;
  }

  .shop__row__users-count {
    width: 20%;
  }

  .shop__row__city {
    width: 25%;
  }

  .shop__row__actions-container {
    width: 10%;
  }

  .shop__row__shop-name__and-status {
    display: flex;
    align-items: center;
  }

  .shop__row__name {
    white-space: nowrap;
  }

  .shop__row__status {
    margin-left: 16px;
    margin-right: 10px;
  }
}
</style>
