<template>
  <div class="planning__toolbar-actions-bar">
    <component
      :is="item.name"
      v-for="(item, index) of items"
      :key="index"
      :ref="`actionItem${index}`"
      v-bind="item.props"
      :is-data-loading="isDataLoading"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import ActionsSeparator from './Toolbar/ActionsSeparator';
import PlanningFiltersAction from './Toolbar/PlanningFiltersAction';
import UndoAction from './Toolbar/UndoAction';
import RedoAction from './Toolbar/RedoAction';
import SmartPlannerAction from './Toolbar/SmartPlannerAction';
import PopularShiftsAction from './Toolbar/PopularShiftsAction';
import WorkloadPlanAction from './Toolbar/WorkloadPlanAction';
import EsignatureAction from './Toolbar/EsignatureAction';
import PrintAction from './Toolbar/PrintAction';
import OptimizationAction from './Toolbar/OptimizationAction';

export default {
  name: 'ToolbarActionItems',
  components: {
    ActionsSeparator,
    PlanningFiltersAction,
    UndoAction,
    RedoAction,
    SmartPlannerAction,
    PopularShiftsAction,
    WorkloadPlanAction,
    EsignatureAction,
    PrintAction,
    OptimizationAction,
  },
  props: {
    isDataLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('planningsState', [
      'isPostesView',
      'isDailyView',
      'isMonthlyView',
    ]),
    isPackOfferPremium() {
      return this.currentOrganisation.attributes.packOffer.name === 'premium';
    },
    isPackOfferBasic() {
      return this.currentOrganisation.attributes.packOffer.name === 'basic';
    },
    canDisplayUndoRedo() {
      return this.currentOrganisation.attributes.packOffer.plannings_undo_enabled;
    },
    monthlyViewItems() {
      const actionBarItems = [
        { name: 'PlanningFiltersAction' },
      ];

      if (this.isPackOfferBasic) {
        actionBarItems.push(
          { name: 'ActionsSeparator' },
          { name: 'PrintAction' },
        );

        return actionBarItems;
      }

      actionBarItems.push(
        { name: 'ActionsSeparator' },
        { name: 'UndoAction', props: { sourceType: 'Month' } },
        { name: 'RedoAction', props: { sourceType: 'Month' } },
        { name: 'ActionsSeparator' },
        { name: 'PrintAction' },
      );

      return actionBarItems;
    },
    genericItems() {
      const actionBarItems = [
        { name: 'PlanningFiltersAction' },
        { name: 'ActionsSeparator' },
        { name: 'UndoAction', props: { sourceType: 'Week' } },
        { name: 'RedoAction', props: { sourceType: 'Week' } },
      ];

      if (!this.isPostesView) {
        actionBarItems.push(
          {
            name: 'ActionsSeparator',
            props: {
              shouldHideOnSmallScreen: true,
              shouldBeVisible: this.canDisplayUndoRedo,
            },
          },
          { name: 'SmartPlannerAction' },
          { name: 'PopularShiftsAction' },
          { name: 'WorkloadPlanAction' },
          {
            name: 'ActionsSeparator',
            props: {
              shouldHideOnSmallScreen: true,
              shouldBeVisible: this.currentLicense.attributes.canCreateShifts && !this.isDailyView,
            },
          },
          { name: 'EsignatureAction' },
        );
      }

      actionBarItems.push(
        { name: 'PrintAction' },
      );

      if (this.isDailyView || this.isPackOfferPremium) {
        return actionBarItems;
      }

      actionBarItems.splice(5, 0, { name: 'OptimizationAction' });

      return actionBarItems;
    },
    items() {
      if (this.isMonthlyView) {
        return this.monthlyViewItems;
      }

      return this.genericItems;
    },
  },
  mounted() {
    this.listenOnRoot(MODAL_SHOW_EVENT, this.closePops);
  },
  methods: {
    closePops() {
      this.$nextTick(() => {
        this.items.forEach((_, index) => {
          this.$refs[`actionItem${index}`][0].$refs.popov?.handleHide();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.planning__toolbar-actions-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

</style>
