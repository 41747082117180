import { svcIntelligenceUrl } from '@config/env';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { IntelligenceRepository } from '@skelloapp/svc-intelligence-sdk';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

class SvcIntelligenceClient {
  constructor() {
    const baseURL = svcIntelligenceUrl;

    const httpClient = new HttpClient(baseURL, {
      clientSource: 'skelloApp:web',
    });

    httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithRetry),
    );

    httpClient.clientWithoutRetry.interceptors.request.use(sendRequestConfig);
    httpClient.clientWithoutRetry.interceptors.response.use(
      successReceivedRequestConfig,
      error => errorReceivedRequestConfig(error, httpClient.clientWithoutRetry),
    );

    this.intelligenceRepository = new IntelligenceRepository(httpClient);
  }

  async requestAnalysisPlanning(documentId, shopId, documentType) {
    await this.intelligenceRepository.analyse(
      {
        analysisType: documentType,
        shopId,
      },
      {
        documentId,
      },
    );
  }

  async validationResponses(allValidationResponsesDTO) {
    await this.intelligenceRepository.validationResponses(allValidationResponsesDTO);
  }
}

export const svcIntelligenceClient = new SvcIntelligenceClient();

export default svcIntelligenceClient;
