/*
This file is used to create and disconnect websockets for the planning page (weekly or monthly)
The websocket is created by using the combination of shopId and date (monday of the week)

The websocket (which is being used in svcKPIs and websocket repo) is designed to listen for weekly events.
If we are in monthly planning, we create multiple websockets for each week in the month.

What events trigger the websocket? Creating, erasing shifts, etc.
When moving from weekly / monthly or moving between dates, we disconnect the previous websockets and create new ones.

For each socket that is created, a record in dynamoDB is created with relevant information.
DynamoDB --> websocket-[ENV]
*/

import WebsocketClient from '@skello-utils/websocket_client/websocket_client';
import skDate from '@skello-utils/dates';

export function createWebsockets(websocketUrl, shopId, dates, handleWebsocket) {
  return dates.map(date => {
    const monday = skDate.utc(date).startOf('isoWeek').format('YYYY-MM-DD');
    const socket = new WebsocketClient(`${websocketUrl}/?shopId=${shopId}&date=${monday}`);
    socket.connect(event => handleWebsocket(event));
    return socket;
  });
}

export function disconnectWebsockets(sockets) {
  sockets.forEach(socket => socket.disconnect());
}
