<template>
  <div class="badgings__sidebar">
    <ul class="badgings__sidebar__list_item">
      <li
        v-for="day in weekDays"
        :key="day"
      >
        <DayItem :day="day" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';
import DayItem from './DayItem';

export default {
  name: 'WeekSidebar',
  components: { DayItem },
  computed: {
    ...mapState('badgings', ['currentDate']),
    weekDays() {
      if (!this.currentDate) return [];
      const weekDays = [skDate(this.currentDate).startOf('isoWeek').format('YYYY-MM-DD')];

      for (let i = 1; i < 7; i += 1) {
        weekDays.push(skDate(this.currentDate).startOf('isoWeek').add(i, 'day').format('YYYY-MM-DD'));
      }

      return weekDays;
    },
  },
};
</script>
