<template>
  <tr>
    <td class="attendance-sheets-esignature-panel__user-row-cell">
      <div class="attendance-sheets-esignature-panel__user-row-cell--employee">
        <AvatarMedallion
          :avatar-url="getAvatarUrlForUser(user.id)"
          :medallion-text="userInitials(user)"
          medallion-background="#2b66fe"
          medallion-content-color="white"
        />
        <div class="attendance-sheets-esignature-panel__user-row-cell__name">
          <div class="attendance-sheets-esignature-panel__user-row-cell__table-label">
            {{ truncatedFirstName }}
          </div>
          <div class="attendance-sheets-esignature-panel__user-row-cell__table-label">
            {{ truncatedLastName }}
          </div>
        </div>
      </div>
    </td>
    <td class="attendance-sheets-esignature-panel__user-row-cell">
      <div :class="signatureStatusClasses">
        {{ signatureStatusLabel }}
      </div>
    </td>
    <td class="attendance-sheets-esignature-panel__user-row-cell">
      <a
        v-if="signatureStatusIs('signed')"
        v-track="'esignature_download_individual_timesheet'"
        rel="noopener noreferrer"
        :href="downloadUrl"
        target="_blank"
        class="attendance-sheets-esignature-panel__user-row-cell__download-label"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
      >
        <DownloadArrowIcon
          width="9px"
          height="9px"
          :fill="fillColor"
        />
        <span class="attendance-sheets-esignature-panel__user-row-cell__table-label">
          {{ $t('attendance_sheets_esignature.follow_up_step.actions.download') }}
        </span>
      </a>
      <a
        v-else-if="canResendSignature"
        v-track="'esignature_resend_individual_timesheet'"
        class="attendance-sheets-esignature-panel__user-row-cell__resend-label"
        @click="resendSignatureRequest"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
      >
        <PaperAirplaneIcon
          width="9px"
          height="9px"
          :fill="fillColor"
        />
        <span class="attendance-sheets-esignature-panel__user-row-cell__table-label">
          {{ $t('attendance_sheets_esignature.follow_up_step.actions.re_send') }}
        </span>
      </a>
      <div
        v-else
        class="attendance-sheets-esignature-panel__user-row-cell__download-label--disabled"
      >
        -
      </div>
    </td>
  </tr>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';
import skDate from '@skello-utils/dates';
import { truncateString } from '@skello-utils/formatting/strings';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'UserRow',
  components: {
    AvatarMedallion,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fillColor: '#727272',
    };
  },
  computed: {
    ...mapGetters('employees', [
      'userInitials',
      'getAvatarUrlForUser',
    ]),
    ...mapGetters('planningsState', ['monday']),
    ...mapState('currentShop', ['currentShop']),
    canResendSignature() {
      return this.signatureStatusIs('partiallySigned', 'signatureRequestSent') &&
        this.user.attributes.email;
    },
    downloadUrl() {
      const params = new URLSearchParams({
        signature_request_id: this.user.attributes.signatureRequestId,
        external_id: this.user.attributes.externalId,
        date: skDate(this.monday),
        user_name: this.user.attributes.lastName,
        user_id: this.user.id,
        shop_id: this.currentShop.id,
      });

      return `/v3/api/request_esignatures/download?${params.toString()}`;
    },
    signatureStatusClasses() {
      if (this.signatureStatusIsPending()) {
        return {
          'attendance-sheets-esignature-panel__user-row-cell__status--pending': true,
          'attendance-sheets-esignature-panel__user-row-cell__status': true,
          'attendance-sheets-esignature-panel__user-row-cell__table-label': true,
        };
      }
      if (this.signatureStatusIs('signed')) {
        return {
          'attendance-sheets-esignature-panel__user-row-cell__status--signed': true,
          'attendance-sheets-esignature-panel__user-row-cell__status': true,
          'attendance-sheets-esignature-panel__user-row-cell__table-label': true,
        };
      }
      return {
        'attendance-sheets-esignature-panel__user-row-cell__status--error': true,
        'attendance-sheets-esignature-panel__user-row-cell__status': true,
        'attendance-sheets-esignature-panel__user-row-cell__table-label': true,
      };
    },
    signatureStatusLabel() {
      if (this.signatureStatusIsPending()) {
        return this.$t('attendance_sheets_esignature.follow_up_step.status.pending');
      }
      if (this.signatureStatusIs('signed')) {
        return this.$t('attendance_sheets_esignature.follow_up_step.status.signed');
      }
      return '';
    },
    truncatedFirstName() {
      return truncateString(this.user.attributes.firstName, 15);
    },
    truncatedLastName() {
      return truncateString(this.user.attributes.lastName, 15);
    },
  },
  methods: {
    handleMouseover() {
      this.fillColor = '#2b66fe';
    },
    handleMouseleave() {
      this.fillColor = '#727272';
    },
    signatureStatusIs(...statuses) {
      return statuses.includes(this.user.attributes.signatureStatus);
    },
    signatureStatusIsPending() {
      return this.signatureStatusIs('awaiting-news-from-esignature-svc', 'documentCreated', 'signatureRequestCreated', 'signatureRequestSent', 'signedPendingDownload');
    },
    resendSignatureRequest() {
      const params = {
        shop_id: this.user.attendanceSheet.shopId,
        date: this.user.attendanceSheet.date,
        user_id: this.user.id,
      };

      httpClient
        .post('/v3/api/request_esignatures/resend_attendance_sheet_esignature', params)
        .then(() => this.$skToast({
          message: this.$t('attendance_sheets_esignature.follow_up_step.actions.re_send_success'),
          variant: 'success',
        }))
        .catch(() => this.$skToast({
          message: this.$t('attendance_sheets_esignature.follow_up_step.actions.re_send_error'),
          variant: 'error',
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-table > tbody td {
  border-bottom: 0;
}

.attendance-sheets-esignature-panel__user-row-cell {
  padding: 6px 0;
}

.attendance-sheets-esignature-panel__user-row-cell--employee {
  display: flex;
  align-items: center;
  width: 110px;
}

.attendance-sheets-esignature-panel__user-row-cell__name {
  flex-direction: column;
  margin-left: 10px;
}

.attendance-sheets-esignature-panel__user-row-cell__status {
  margin-right: 25px;
}

.attendance-sheets-esignature-panel__user-row-cell__status--pending {
  color: #bfbfbf;
}

.attendance-sheets-esignature-panel__user-row-cell__status--signed {
  color: #42c05e;
}

.attendance-sheets-esignature-panel__user-row-cell__status--error {
  color: $sk-error;
}

.attendance-sheets-esignature-panel__user-row-cell__download-label,
.attendance-sheets-esignature-panel__user-row-cell__resend-label {
  text-decoration: none;
  color: $sk-black;

  &:hover {
    color: $sk-blue;
    cursor: pointer;
  }
}

.attendance-sheets-esignature-panel__user-row-cell__table-label {
  font-size: $fs-text-s !important;
  white-space: nowrap;
}
</style>
