<template>
  <!--
    this component is mostly a wrapper of SkCircleButton and SkDropdown
    hence the use of $attrs to made them fall-through,
    allowing the use of only specific wrapper-related props
  -->
  <SkDropdown
    :ref="dropdownRef"
    v-bind="$attrs"
    :y-offset="$attrs['y-offset'] || '4'"
  >
    <template #anchor>
      <SkCircleButton
        ref="buttonAnchor"
        v-tooltip:[buttonTooltipModifiers]="buttonTooltip"
        v-bind="$attrs"
        :icon="icon"
        v-on="items.length === 1 ? { click: items[0].callback } : {}"
      />
    </template>
    <template #menu>
      <div
        v-if="!$attrs.loading && items.length > 1"
        ref="dropdownContent"
        class="dropdown-content"
      >
        <div
          v-for="(item, index) in items"
          :key="`${itemKey}-${item.id || index}`"
          v-tooltip:[itemsTooltipModifiers]="item.disabled ? item.tooltip : undefined"
          v-track="item.tracker"
          :data-test="item.dataTest"
          :class="{
            'dropdown-content__item': true,
            'dropdown-content__item--disabled': item.disabled,
          }"
          @click.stop="handleClick(item)"
        >
          <component
            :is="item.icon"
            v-if="item.icon"
            :fill="item.iconColor || '#000'"
            width="20px"
            height="20px"
          />
          <span :style="item.color ? `color: ${item.color}` : null">
            {{ item.label }}
          </span>
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
export default {
  name: 'DropdownCircleButton',
  props: {
    icon: {
      type: String,
      default: 'KebabV2Icon',
    },
    buttonTooltip: {
      type: String,
      default: null,
    },
    buttonTooltipModifiers: {
      type: Array,
      default: null,
    },
    dropdownRef: {
      type: String,
      default: 'dropdownCircleButton',
    },
    itemKey: {
      type: String,
      default: 'button-dropdown-item',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsTooltipModifiers: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    this.listenOnRoot('hide-dropdown-popover', this.hide);
  },
  methods: {
    handleClick(item) {
      if (item.disabled) return;

      item.callback();
      this.hide();
    },
    hide() {
      this.$refs[this.dropdownRef].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  background: $sk-white;
  box-shadow: 0px 6px 16px 0px #0000001F;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  padding: 4px;
}

.dropdown-content__item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px 8px 12px;
  color: $sk-black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-content__item:hover {
  background: $sk-grey-5;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-content__item--disabled {
  color: $sk-grey-30;
}

.dropdown-content__item--disabled:hover {
  background: $sk-grey-5;
  cursor: default;
}

</style>
