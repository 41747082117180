const SURVEY_KEYS = {
  automaticPlanningCesKey: 'xy9EK94COZXFCodF',
  dayViewCesKey: 'DnBG2aEYvGzsqKDK',
  planningNpsKey: '3cTiBAdzWDfieoSp',
};

export const displayDelightedSurvey = (keyName, userDatas) => {
  // How Delighted surveys works:
  // https://www.notion.so/skelloapp/DelightedSurvey-1473c4082a994790a922efa7297c3a21

  // eslint-disable-next-line
  !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(o,u)}}(window,document,SURVEY_KEYS[keyName],"delightedSurvey");
  try {
    delightedSurvey.survey(userDatas);
  } catch (error) {
    console.error(`Survey was not shown: ${error.message}`);
  }
};
