<template>
  <div
    v-show="visible"
    class="dashboard-section dashboard-flip-cards square-4"
  >
    <div class="flip-card">
      <div class="face front arrived-and-gone-front">
        <div />
        <div class="number">
          {{ usersArrivedArchivedLength }}
        </div>
        <div class="subtitle">
          {{ $t('home_dashboard.departures_arrivals.subtitle') }}
          {{ actualDateDisplay }}
        </div>
      </div>
      <div class="face back">
        <div class="dashboard-title dtitle-spread noflip">
          <h2>
            {{ $t('home_dashboard.departures_arrivals.subtitle') }}
            {{ actualDateDisplay }}
          </h2>
          <div class="dt-rpart">
            <div id="turnover-btn">
              <SkCircleButton
                icon="ChevronLeftV2Icon"
                size="small"
                bordered
                @click="usersArrivedArchivedTime('prev')"
              />
              <SkCircleButton
                icon="ChevronRightV2Icon"
                size="small"
                bordered
                @click="usersArrivedArchivedTime('next')"
              />
            </div>
          </div>
        </div>
        <div class="dashboard-content d-limited-content">
          <div
            v-if="usersArrivedArchivedLength === 0"
            class="d-no-result"
          >
            {{ $t('home_dashboard.departures_arrivals.none') }}
          </div>
          <ul
            v-else
            class="trials-list"
          >
            <li
              v-for="usersArrivedArchived in usersArrivedArchiveds"
              :key="usersArrivedArchived.id"
              class="trial-item noflip"
            >
              <a
                :href="usersArrivedArchived.link"
                :class="'noflip ar-link ' + usersArrivedArchived.type"
                target="blank"
                data-seg="DboardTrialPeriod"
              >
                <div class="trial-user-name">
                  {{ usersArrivedArchived.name }}
                </div>
                <div class="trial-date">
                  <template v-if="usersArrivedArchived.type === 'archived'">
                    {{ $t('home_dashboard.departures_arrivals.archived_at') }}
                    {{ usersArrivedArchived.date }}
                  </template>
                  <template v-else>
                    {{ $t('home_dashboard.departures_arrivals.arrived_on') }}
                    {{ usersArrivedArchived.date }}
                  </template>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

import { SkCircleButton } from '@skelloapp/skello-ui';

export default {
  name: 'DeparturesArrivalsCard',
  components: {
    SkCircleButton,
  },
  data() {
    return {
      visible: true,
      usersArrivedArchiveds: null,
      usersArrivedArchivedLength: null,
      actualDate: null,
      actualDateDisplay: null,
      get_users_arrived_archived_path: '/get_users_arrived_archived',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    initialLoad() {
      this.actualDate = skDate().startOf('month').subtract(1, 'months');
      this.usersArrivedArchivedTime('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'DeparturesArrivalsCard');
    },
    usersArrivedArchivedTime(statut) {
      if (statut === 'prev') {
        this.actualDate.subtract(1, 'months');
      } else if (statut === 'next') {
        this.actualDate.add(1, 'months');
      }

      this.actualDateDisplay = this.actualDate.locale(this.$i18n.locale).format('MMMM YYYY').toLowerCase();

      const params = {
        start_date: this.actualDate.format('YYYY-MM-DD'),
      };

      // View all is handled by not passing the shop id
      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.get_users_arrived_archived_path, { params })
        .then(response => {
          const data = response.data;
          this.usersArrivedArchivedLength = data.usersArrivedArchivedLength;
          this.usersArrivedArchiveds = data.usersArrivedArchiveds;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
