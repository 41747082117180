<template>
  <SkCard class="card__wrapper">
    <SkCollapse
      ref="collapse"
      :disabled="upsellAvailable || (!switchActivated && !readOnly)"
    >
      <template #labelLeft>
        <div class="sk-flex card__label">
          <div class="partner-icon sk-flex-center">
            <component :is="iconName" />
          </div>
          <div class="sk-flex-center">
            <h2 class="sk-header--2">
              {{ name }}
            </h2>
          </div>
        </div>
      </template>
      <template #labelRight>
        <div
          v-if="upsellAvailable"
          :class="cardTagClasses"
        >
          <SkTag>
            {{ $t('organisation_settings.tabs.business_intelligence_partners.upsell.option_label') }}
          </SkTag>
        </div>
        <div
          v-if="!readOnly"
          class="switch-wrapper"
        >
          <SkSwitch
            ref="localSwitch"
            v-model="switchActivated"
            v-tooltip.bottom="upsellTooltipText"
            :disabled="disabled"
          />
        </div>
      </template>
      <template #content>
        <div class="card__content">
          <slot />
        </div>
      </template>
    </SkCollapse>
  </SkCard>
</template>

<script>
import {
  Dvore,
  Rosaly,
  Spayr,
  TimeSkipper,
  GoTenzo,
  Silae,
  Yokitup,
  SkelloLogo,
} from './icons';

export default {
  name: 'BiPartnerCard',
  components: {
    Dvore,
    Rosaly,
    Spayr,
    TimeSkipper,
    GoTenzo,
    Yokitup,
    Silae,
    SkelloLogo,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    upsellAvailable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchActivated: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    upsellTooltipText() {
      if (!this.upsellAvailable) return null;

      return this.$t('organisation_settings.tabs.business_intelligence_partners.upsell.tooltip');
    },
    cardTagClasses() {
      return {
        'sk-flex-center': true,
        card__tag: !this.readOnly,
        'card__tag--read-only-card': this.readOnly,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card__wrapper {
  padding: 0;

  ::v-deep .sk-collapse__caret-wrapper {
    margin: 0 13px;
  }

  .card__tag {
    padding-right: 30px;
  }

  .card__tag--read-only-card {
    padding-right: 15px;
  }
}

.card__label {
  padding-left: 14px;
}

.card__content {
  padding: 30px;
  padding-top: 2px;
}

.partner-icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid $sk-grey-10;
  margin-right: 20px;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  margin-top: -3px;
}
</style>
