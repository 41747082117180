<template>
  <div :class="cellClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TitlesColumnCell',
  props: {
    customClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    cellClasses() {
      return [...this.customClasses, 'headers-column'];
    },
  },
};
</script>

<style lang="scss" scoped>
.headers-column {
  height: 50px;
  position: relative;
  border-top: solid 1px $sk-grey-10;
  font-size: $fs-text-s;
  padding: 0 15px;
  margin: 0 9px;
  display: flex;
  align-items: center;
  color: $sk-black;

  &:first-child {
    border-bottom: none;
  }

  &:last-child {
    border-bottom: solid 1px $sk-grey-10;
  }
}
</style>
