<template>
  <div class="shops__container">
    <div
      v-if="loading"
      class="shops__container__spinner"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <div
        v-if="displayUpsellBanner"
        class="shops__container__header"
      >
        <div class="shops__container__tilte__and__description">
          <h1 class="sk-header--1">
            {{ $t('organisation_settings.tabs.shops.title_content_upsell_banner') }}
          </h1>
          <p class="sk-subtitle--large">
            {{ $t('organisation_settings.tabs.shops.subtitle_upsell_banner') }}
          </p>
        </div>
        <div class="shops__container__banner">
          <UpsellBanner
            :description="$tc('organisation_settings.tabs.shops.banner.description', activeShops)"
            :button-message="$t('organisation_settings.tabs.shops.banner.creation_button')"
            :is-loading="upsellLoading"
            @click="handleUpsell"
          />
        </div>
      </div>
      <div v-else>
        <h1 class="sk-header--1">
          {{ $t('organisation_settings.tabs.shops.title_content') }}
        </h1>
        <div
          class="shops__container__head"
        >
          <p class="shops__container__head__explanation sk-subtitle--large">
            {{ $t('organisation_settings.tabs.shops.subtitle') }}
          </p>
          <SkOroraButton
            icon="PlusSignV2Icon"
            variant="highlighted"
            @click="navigateToShopForm"
          >
            {{ $t('organisation_settings.tabs.shops.add_shop') }}
          </SkOroraButton>
        </div>
      </div>
      <div
        class="shops__container__table"
      >
        <SkTable :columns="headers">
          <ShopRow
            v-for="shop in sortedShops"
            :key="shop.id"
            :shop="shop"
          />
        </SkTable>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import {
  mapGetters, mapState,
} from 'vuex';

import {
  httpClient,
  svcBillingAutomationClient,
} from '@skello-utils/clients';

import ShopRow from './ShopRow';

import UpsellBanner from '../../shared/components/UpsellBanner';

export default {
  name: 'OrganisationShops',
  components: { ShopRow, UpsellBanner },
  data() {
    return {
      loading: true,
      shops: [],
      upsellLoading: false,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopBillingData']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['currentUser']),
    headers() {
      const headers = [
        { title: this.$t('organisation_settings.tabs.shops.table.headers.shop_name') },
        { title: this.$t('organisation_settings.tabs.shops.table.headers.users_count') },
        { title: this.$t('organisation_settings.tabs.shops.table.headers.city') },
      ];

      if (this.canEditShopBillingData) {
        headers.push({ title: this.$t('organisation_settings.tabs.shops.table.headers.actions') });
      }

      return headers;
    },
    displayUpsellBanner() {
      // Canary feature flag to disable the new rollout in-app for KA
      if (this.isDevFlagEnabled('FEATUREDEV_ROLLOUT_KEEP_OLD_EXPERIENCE')) {
        return false;
      }

      return this.currentOrganisation.attributes.createdFromSvcBillingAutomation &&
        this.currentOrganisation.attributes.packOffer.name !== 'Timeclock Standalone Success';
    },
    sortedShops() {
      if (this.loading) return [];

      return orderBy(this.shops, 'attributes.name');
    },
    activeShops() {
      return this.shops.filter(shop => !shop.attributes.cancelled).length;
    },
  },
  mounted() {
    this.fetchShops();
  },
  methods: {
    async handleUpsell() {
      this.$skAnalytics.track('inapp_rollout_click_create_shop');

      this.upsellLoading = true;
      try {
        const { quoteId } = await svcBillingAutomationClient.createQuoteByOrgaId(
          this.currentOrganisation.id,
          this.currentUser.attributes.firstName,
          this.currentUser.attributes.lastName,
          this.currentUser.attributes.email,
        );
        svcBillingAutomationClient.redirectUserToDealroom(
          quoteId,
          null,
          this.currentUser.attributes.lang,
        );
      } catch (error) {
        if (error instanceof Object) {
          console.error('Create quote error:', error.response?.data?.message);
        }

        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      }
      this.upsellLoading = false;
    },
    navigateToShopForm() {
      this.$skAnalytics.track('add_shop_btn_click', { source: 'global_settings' });

      this.$router.push({ name: 'organisation_settings_shops_new_form' });
    },

    fetchShops() {
      return httpClient
        .get(
          '/v3/api/shops',
          { params: { skip_pagination: true, include_users_count: true } },
        )
        .then(response => {
          this.loading = false;
          this.shops = response.data.data;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.shops__container {
  padding-bottom: 100px;

  .sk-table > thead th {
    padding-left: 10px;
  }

  .shops__container__spinner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }

  .shops__container__head {
    display: flex;
    align-items: flex-start;
  }

  .shops__container__head__explanation {
    flex: 1;
    padding: 5px 0 0;
    color: $sk-grey;
  }

  .shops__container__table {
    padding-top: 24px;
  }

  .shops__container__banner {
    padding-top: 24px;
  }

  .shops__container__header {
    display: flex;
    flex-direction: column;
  }
}
</style>
