<template>
  <div class="shift-info-card-item">
    <component
      :is="icon"
      fill="white"
      width="16"
      height="16"
    />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.shift-info-card-item {
  display: flex;
  column-gap: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.38px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
  }
}
</style>
