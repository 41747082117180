<template>
  <img
    width="28"
    src="./images/logo-e-paye.jpg"
  >
</template>

<script>
export default {
  name: 'Epaye',
};
</script>
