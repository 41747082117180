<template>
  <SkDropdown
    ref="workloadPlanDropdown"
    v-tooltip="tooltipText"
    class="workload-plan-dropdown"
    :prevent-overflow="false"
    placement="bottom-start"
    y-offset="5px"
    trigger="click"
    :disabled="disabled"
    @show-dropdown="handleShow"
    @hide-dropdown="handleHide"
  >
    <template #anchor>
      <div class="workload-plan-dropdown__anchor">
        <SkCircleButton
          icon="LineWithBarsIcon"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #menu>
      <div class="workload-plan-dropdown__wrapper">
        <h3 class="workload-plan-dropdown__wrapper__title">
          {{ $t('plannings.toolbar.actions_bar.icons_labels.workload_plan.label') }}
        </h3>
        <div
          v-for="(item, index) in dropdownListItem"
          :key="index"
          :class="dropdownContentClass(item)"
          @click.stop="item.action"
        >
          <div class="workload-plan-dropdown__icon">
            <component
              :is="item.icon"
              v-if="!item.isTitle"
              :fill="dropDownIconColor(item.disabled)"
              width="15"
              height="15"
            />
          </div>
          <div class="workload-plan-dropdown__label-ellipsis">
            <span :class="workloadPopoverLabelClass(item)">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkloadPlanDropdown',
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapGetters('planningsWorkloadPlans', ['hasNeedsOnPeriod']),

    dropdownListItem() {
      return [
        {
          isTitle: true,
          label: this.$i18n.t('workload_plan.dropdown.from_model'),
        },
        {
          icon: 'DownloadIcon',
          label: this.$i18n.t('workload_plan.dropdown.download_model'),
          action: this.openDownloadWorkloadPlanModal,
        },
        {
          icon: 'UploadIcon',
          label: this.$i18n.t('workload_plan.dropdown.upload_model'),
          action: this.openUploadWorkloadPlanModal,
        },
        {
          isTitle: true,
          label: this.$i18n.t('workload_plan.dropdown.from_skello'),
        },
        {
          icon: this.createOrEditIcon,
          label: this.createOrEditTitle,
          action: this.openWorkloadPlanBottomPanel,
        },
        {
          icon: 'DuplicateContractIcon',
          label: this.$i18n.t('workload_plan.dropdown.duplicate'),
          action: this.openDuplicateWorkloadPlanModal,
        },
      ];
    },
    tooltipText() {
      if (!this.disabled) return this.$i18n.t('plannings.toolbar.actions_bar.icons_labels.workload_plan.label');

      return this.$i18n.t('plannings.toolbar.actions_bar.icons_labels.workload_plan.tooltip.no_access');
    },
    isAlreadySavedWorkloadPlans() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;
      return this.hasNeedsOnPeriod(openingTime.toISOString(), closingTime.toISOString());
    },
    createOrEditTitle() {
      const key = this.isAlreadySavedWorkloadPlans ? 'edit_workload_plans' : 'add_workload_plans';

      return this.$i18n.t(`workload_plan.dropdown.${key}`);
    },
    createOrEditIcon() {
      return this.isAlreadySavedWorkloadPlans ? 'EditIcon' : 'PlusSignIcon';
    },
  },
  mounted() {
    this.listenOnRoot('open-workload-plan-dropdown', this.openWorkloadPlan);
  },
  methods: {
    workloadPopoverLabelClass(item) {
      return {
        'workload-plan-dropdown__content__label': !item.isTitle,
        'workload-plan-dropdown__content__title': item.isTitle,
      };
    },
    dropdownContentClass(item) {
      return {
        'workload-plan-dropdown__content': true,
        'workload-plan-dropdown__content--title': item.isTitle,
        'workload-plan-dropdown__content--label': !item.isTitle,
        'workload-plan-dropdown__content--hover': !item.isTitle,
      };
    },
    openWorkloadPlan() {
      this.$refs.workloadPlanDropdown.open();
    },
    handleShow() {
      this.emitOnRoot('setMenuVisibility', false);
    },
    handleHide() {
      this.$refs.workloadPlanDropdown.hide();
      this.emitOnRoot('setMenuVisibility', true);
    },
    openUploadWorkloadPlanModal() {
      this.handleHide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'upload-workload-plan-modal');
    },
    openWorkloadPlanBottomPanel() {
      this.handleHide();
      this.emitOnRoot('open-workload-plan-bottom-panel');
    },
    openDownloadWorkloadPlanModal() {
      this.handleHide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'download-workload-plan-modal');
    },
    openDuplicateWorkloadPlanModal() {
      this.handleHide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'duplicate-workload-plan-modal');
    },
    dropDownIconColor(disabled) {
      return disabled ? '#b0b0b0' : '#727272';
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-dropdown {
  ::v-deep .sk-dropdown__menu {
    transform: translate3d(calc(100vw - 530px), 100px, 0) !important;
  }
}

.workload-plan-dropdown__content {
  display: flex;
  align-items: center;
  background-color: white;

  &--title {
    padding: 8px 15px;
  }

  &--label {
    padding: 10px 15px;
  }

  &--hover {
    &:hover {
      background: $sk-grey-10;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  &__icon {
    width: 18px;
  }

  &__label {
    margin-left: 18px;
    font-size: $fs-text-m;
  }

  &__title {
    margin-left: 0;
    font-weight: $fw-semi-bold;
    color: $sk-grey;
    font-size: $fs-text-s;
  }
}

.workload-plan-dropdown__anchor {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

.workload-plan-dropdown__wrapper {
  background-color: white;
  padding: 16px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
  border-radius: 2px;
  width: 303px;

  &__title {
    font-size: $fs-text-l;
  }
}

.workload-plan-dropdown__icon {
  display: flex;
  align-items: center;
}

.workload-plan-dropdown__wrapper__title {
  margin-bottom: 0;
  font-size: $fs-text-l;
  padding: 0 15px 8px;
}

.workload-plan-dropdown__label-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.planning__toolbar__new-feature-tag {
  position: absolute;
  font-size: 7px;
  border-radius: 25px;
  top: 80%;
  text-align: center;
  width: 40px;
  color: white;
  background: linear-gradient(#459fff, $sk-blue-50, $sk-royal-blue);
  border: solid 1px $sk-grey-5;

  &--disabled {
    background: $sk-grey-30;
  }
}
</style>
