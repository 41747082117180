<template>
  <tr
    class="matched-badging__table__row"
  >
    <!-- EMPLOYEE COLUMN -->
    <td class="matched-badging__table__cell-employee-name">
      <router-link
        v-tooltip.top="userNameTooltip"
        :to="userBadgingsRoute"
      >
        {{ firstName }} <br> {{ lastName }}
      </router-link>
    </td>

    <!-- SHIFTS PLANNED -->
    <td class="matched-badging__table__cell-shifts-planned">
      <div
        v-if="hasAbsenceBeenBadged"
        v-tooltip.top="absenceCalculationTooltip"
        class="text-truncate"
      >
        {{ absencePosteName }}
      </div>
      <div
        v-else-if="hasNoShift || shouldShowNoShift"
        class="text-truncate"
      >
        {{ $t('badgings.days.tabs.shifts.none') }}
      </div>
      <div
        v-else
      >
        <div class="sk-flex-start">
          <span>{{ shiftStartsAt }}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{{ shiftEndsAt }}</span>
        </div>
      </div>
    </td>

    <!-- SHIFTS BREAK PLANNED -->
    <td class="border-right">
      <div
        v-if="!(matchedBadging.isAbsence || hasNoShift || shouldShowNoShift)"
      >
        {{ shiftBreak }}
      </div>
    </td>

    <!-- SHIFTS BADGINGS -->
    <td class="matched-badging__table__cell-badging-hours">
      <div
        v-if="!hasNoBadging"
        class="sk-flex-start"
      >
        <span>{{ badgingStartsAt }}</span>
        <span>&nbsp;-&nbsp;</span>
        <span v-if="!hasOnlyBadgingIn">
          {{ badgingEndsAt }}
        </span>
        <div
          v-if="isBadgingClosedByBackend"
          v-tooltip="closedByBackendTooltip"
        >
          *
        </div>
      </div>
    </td>

    <!-- SHIFTS BREAK BADGINGS -->
    <td class="border-right">
      <div
        v-if="!hasNoBadging && !turnedAsAbsence"
        v-tooltip.top="badgingBreaksTooltip"
      >
        {{ badgingBreaksDuration }}
      </div>
    </td>

    <!-- POSTE COLUMN -->
    <td class="matched-badging__table__cell-position">
      <div class="matched-badging__table__cell-position--content">
        <svg>
          <ellipse
            cx="3"
            cy="3"
            rx="3"
            ry="3"
            :style="`fill:${matchedBadging.posteColor}`"
          />
        </svg>

        <div
          v-tooltip.top="posteNameTooltip"
          class="text-truncate"
        >
          {{ matchedBadging.posteName }}
        </div>
      </div>
    </td>
    <!-- SHIFTS PAYED -->
    <td class="matched-badging__table__cell-shifts-paid">
      <div
        v-if="!turnedAsAbsence && matchedBadging.posteId"
      >
        {{ matchedBadging.selectedStartsAt }}
        -
        {{ matchedBadging.selectedEndsAt }}
      </div>
    </td>
    <!-- SHIFTS BREAK PAYED -->
    <td
      class="border-right"
    >
      <div
        v-if="!turnedAsAbsence && matchedBadging.posteId"
      >
        {{ matchedBadging.selectedBreak }} mn
      </div>
    </td>
    <!-- VALIDATED BY -->
    <td class="matched-badging__table__cell-validated-by">
      {{ matchedBadging.validatedBy }}
    </td>
    <!-- VALIDATED AT -->
    <td class="border-right matched-badging__table__cell-validated-at">
      {{ formatDayDate(matchedBadging.validatedAt) }}
    </td>
    <!-- ANOMALY REASON -->
    <td class="border-right matched-badging__table__cell-anomaly-reason">
      {{ $t(`badgings.history.anomaly_reasons.${matchedBadging.anomalyReason}`) }}
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
} from '@app-js/shared/constants/shift';
import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';
import { BADGING_BUFFER_BEFORE_OPENING } from '@app-js/shared/store/modules/timeclock/badgings';
import { formatBreakOrPauseTime } from '@skello-utils/formatting/time';

const MILLISECONDS_IN_A_MINUTE = 60000;

export default {
  name: 'MatchedBadgingHistoryRow',
  components: {},
  props: {
    matchedBadging: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('badgings', [
      'currentDate',
    ]),
    turnedAsAbsence() {
      return !!this.matchedBadging.turnedAsAbsence;
    },
    userBadgingsRoute() {
      return {
        name: 'badgings_shifts_users',
        params: {
          user_id: this.matchedBadging.userId,
        },
        query: {
          date: this.currentDate,
        },
      };
    },
    absencePosteName() {
      return this.matchedBadging.validated && this.matchedBadging.previsionalPosteIsAbsence ?
        this.matchedBadging.previsionalPosteName :
        this.matchedBadging.absencePosteName;
    },
    shouldShowNoShift() {
      return this.matchedBadging.validated ?
        !this.matchedBadging.previsionalPosteName && !this.matchedBadging.hasBeenIgnored :
        !this.matchedBadging.posteId && !this.matchedBadging.absencePosteId;
    },
    hasAbsenceBeenBadged() {
      return this.matchedBadging.validated ?
        this.matchedBadging.previsionalPosteIsAbsence :
        this.matchedBadging.isAbsence && !!this.matchedBadging.badgingStartsAt;
    },
    matchedBadgingDate() {
      const startsAt = this.hasNoShift ?
        this.matchedBadging.badgingStartsAt :
        this.matchedBadging.predictedStartsAt;

      return skDate(startsAt).utc().add(BADGING_BUFFER_BEFORE_OPENING, 'm');
    },
    hasNoShift() {
      const { predictedStartsAt, predictedEndsAt } = this.matchedBadging;

      return !(predictedStartsAt || predictedEndsAt);
    },
    hasNoBadging() {
      const { badgingStartsAt, badgingEndsAt } = this.matchedBadging;

      return !(badgingStartsAt || badgingEndsAt);
    },
    hasOnlyBadgingIn() {
      const { badgingStartsAt, badgingEndsAt } = this.matchedBadging;

      return badgingStartsAt && !badgingEndsAt;
    },
    firstName() {
      return capitalize(this.matchedBadging.firstName);
    },
    lastName() {
      return capitalize(this.matchedBadging.lastName);
    },
    absenceCalculationTooltip() {
      let tooltip = `${this.$t('badgings.days.tabs.shifts.absence.planned')}
                     ${this.absencePosteName} <br>`;

      if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_DAY) {
        tooltip += this.$t('badgings.days.tabs.shifts.absence.all_day');
      } else if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_HALF_DAY) {
        tooltip += this.$t('badgings.days.tabs.shifts.absence.half_day');
      } else if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_HOURS) {
        tooltip += `${this.shiftStartsAt} - ${this.shiftEndsAt}`;
      }

      return tooltip;
    },
    userNameTooltip() {
      if (this.firstName.length > 16 || this.lastName.length > 16) {
        return `${this.firstName} ${this.lastName}`;
      }

      return null;
    },
    shiftStartsAt() {
      return this.timeToText(this.matchedBadging.predictedStartsAt);
    },
    shiftEndsAt() {
      return this.timeToText(this.matchedBadging.predictedEndsAt);
    },
    badgingStartsAt() {
      return this.timeToText(this.matchedBadging.badgingStartsAt);
    },
    badgingEndsAt() {
      return this.timeToText(this.matchedBadging.badgingEndsAt);
    },
    shiftBreak() {
      return formatBreakOrPauseTime(this.matchedBadging.predictedBreak);
    },
    badgingBreaksTooltip() {
      if (this.matchedBadging.badgingBreaks.length === 0) return null;

      let breakString = '';

      this.matchedBadging.badgingBreaks.forEach((breakTimes, index) => {
        const startBreakTime = skDate(breakTimes[0]).format('HH:mm');
        const endBreakTime = breakTimes[1] ? skDate(breakTimes[1]).format('HH:mm') : '-';

        breakString += `${this.$t('badgings.days.tabs.shifts.start_break')} : ${startBreakTime} <br>` +
          `${this.$t('badgings.days.tabs.shifts.end_break')} : ${endBreakTime}`;

        // If line is not last one
        if (index + 1 !== this.matchedBadging.badgingBreaks.length) {
          breakString += '<br><br>';
        }
      });

      return breakString;
    },
    badgingBreaksDuration() {
      let breakDuration = 0;

      this.matchedBadging.badgingBreaks.forEach(breakTimes => {
        if (breakTimes.length < 2 || breakTimes.some(value => !value)) return;

        const startBreakTime = skDate(breakTimes[0]);
        const endBreakTime = skDate(breakTimes[1]);
        breakDuration += endBreakTime.diff(startBreakTime);
      });

      return `${(breakDuration / MILLISECONDS_IN_A_MINUTE).toFixed()} mn`;
    },
    isBadgingClosedByBackend() {
      return this.matchedBadging.closedByBackend;
    },
    closedByBackendTooltip() {
      return this.matchedBadging.validated ? null : this.$t('badgings.closed_by_backend');
    },
    posteNameTooltip() {
      return this.matchedBadging.posteName;
    },
  },
  methods: {
    timeToText(date) {
      return skDate(date).utc().format('HH:mm');
    },
    formatDayDate(date) {
      return skDate(date).utc().format('L');
    },
  },
};
</script>

<style lang="scss" scoped>
.matched-badging__table__row {
  td {
    padding-top: 9px;
    padding-bottom: 8px;
  }

  .border-right {
    border-right: 1px solid $sk-grey-10;
  }

  .matched-badging__table__cell {
    &-employee-name {
      padding-right: 16px;

      & > a {
        text-decoration: none;
        color: inherit;
      }

      @media (max-width: 1200px) {
        padding-right: 8px;
      }
    }

    &-shifts-planned {
      max-width: 113px;
      min-width: 113px;
      padding-right: 16px;

      @media (max-width: 1200px) {
        padding-right: 8px;
      }
    }

    &-badging-hours {
      max-width: 127px;
      min-width: 127px;
      padding-right: 16px;
      padding-left: 16px;

      @media (max-width: 1200px) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &-position {
      padding-right: 16px;
      padding-left: 16px;
      max-width: 97px;
      min-width: 97px;

      @media (max-width: 1200px) {
        padding-right: 8px;
        padding-left: 8px;
      }

      &--content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        max-width: 97px;
        min-width: 97px;

        & > svg {
          height: 6px;
          width: 6px;
          min-width: 6px;
        }
      }
    }

    &-shifts-paid {
      max-width: 127px;
      min-width: 127px;
      padding-right: 16px;
      padding-left: 16px;

      @media (max-width: 1200px) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &-validated-by {
      max-width: 104px;
      min-width: 104px;
      padding-right: 16px;
      padding-left: 16px;

      @media (max-width: 1200px) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &-validated-at {
      max-width: 88px;
      min-width: 88px;
      padding-right: 16px;

      @media (max-width: 1200px) {
        padding-right: 8px;
      }
    }

    &-anomaly-reason {
      max-width: 217px;
      min-width: 117px;
      padding-left: 16px;

      @media (max-width: 1200px) {
        padding-left: 8px;
      }
    }
  }
}

</style>
