<template>
  <SkModal
    id="send-punch-clock-modal"
    ref="sendPunchClockModal"
    :modal-title="$t('employees.tabs.personal_data.profile.identity.mail_only_modal.title')"
    :submit-disabled="submitDisabled"
    :tracking-options="trackingOptions"
  >
    <template #title-icon>
      <div class="send-punch-clock-token__modal__icon">
        <PaperAirplaneIcon fill="#727272" />
      </div>
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <div class="row px-3">
          <div class="send-punch-clock-modal_pin-code-block">
            <img
              class="send-punch-clock-modal_code-pin-icon"
              src="../../shared/svg/code-pin-visual.svg"
            >
            <div class="pl-4">
              <div class="send-punch-clock-modal_title">
                {{ $t('employees.tabs.personal_data.profile.identity.mail_only_modal.info_title') }}
              </div>
              <div class="send-punch-clock-modal_subtitle">
                {{
                  $t('employees.tabs.personal_data.profile.identity.mail_only_modal.info_subtitle')
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="row px-3">
          <i18n path="employees.tabs.personal_data.profile.identity.mail_only_modal.description">
            <template #name>
              <b>{{ employeeFullName }}</b>
            </template>
          </i18n>
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="submitDisabled"
        @click="handleSubmit"
      >
        {{ $t('employees.tabs.personal_data.profile.identity.mail_only_modal.send') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import { getErrorMessage } from '@skello-utils/errors';

export default {
  name: 'SendPunchClockModal',
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('employees', ['fullName']),
    submitDisabled() {
      return this.submitting;
    },
    trackingOptions() {
      return {
        submit: 'send_pin_code_validate',
        cancel: 'send_pin_code_cancel',
      };
    },
    employeeFullName() {
      return this.fullName(this.employee);
    },
  },
  methods: {
    ...mapActions('selectedEmployee', ['sendPunchClockToken']),
    handleSubmit() {
      this.submitting = true;
      this.sendPunchClockToken({
        employee_id: this.employee.id,
        send_by_sms: false,
        send_by_email: true,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.personal_data.profile.identity.modal.success', { name: this.employee.attributes.firstName }),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: getErrorMessage(error),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
      this.submitting = false;
      this.$refs.sendPunchClockModal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.send-punch-clock-token__modal__icon {
  color: $sk-grey;
  background: $sk-grey-10;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.send-punch-clock-modal_pin-code-block {
  box-sizing: border-box;
  padding: 24px;
  background: $sk-white;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .send-punch-clock-modal_code-pin-icon {
    height: 68;
  }

  .send-punch-clock-modal_title {
    color: $sk-black;
    font-weight: 700;
    font-size: $fs-text-l;
    margin-bottom: 8px;
  }

  .send-punch-clock-modal_subtitle {
    color: $sk-grey;
    font-weight: 400;
    font-size: $fs-text-m;
    line-height: 18px;
  }
}

</style>
