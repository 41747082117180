import TimeclockOnboarding from './TimeclockOnboarding';

const timeclockOnboardingRoutes = [
  {
    path: '/v3/shops/:shop_id/timeclock-onboarding',
    component: TimeclockOnboarding,
    name: 'timeclock_onboarding',
  },
];

export default timeclockOnboardingRoutes;
