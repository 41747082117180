<template>
  <div class="automatic-planning__sidepanel__step-form__wrapper">
    <template v-if="isStep('from-history')">
      <div class="step-form__from-history__container">
        <div class="step-form__from-history__icon">
          <IconInfo
            width="24"
            height="24"
          />
        </div>
        <p class="step-form__intro__history">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="$t('automatic_planning.sidebar.from_history.intro_paragraph_start')" />
          {{ $t('automatic_planning.sidebar.from_history.intro_paragraph_more') }}
          <a
            class="sk-link--medium"
            @click="goBack"
          >
            {{ $t('automatic_planning.sidebar.from_history.intro_paragraph_link') }}
          </a>
        </p>
      </div>
    </template>
    <template v-else>
      <p class="step-form__intro">
        {{ $t('automatic_planning.sidebar.intro_paragraph_start') }}
        <b>{{ $t('automatic_planning.sidebar.title') }}</b>
        {{ $t('automatic_planning.sidebar.intro_paragraph_end') }}
        <a
          :href="automaticPlanningRulesUrl"
          class="sk-link--medium"
          @click="trackLinkClick"
        >
          {{ $t('automatic_planning.sidebar.provenance_choice.link') }}
        </a>
      </p>
    </template>

    <template v-if="isStep('from-template')">
      <hr class="step-form__separation">
      <h3 class="provenance-choice__subtitle">
        {{ $t('automatic_planning.sidebar.from_template.label') }}
      </h3>
      <SkSelectV2
        v-model="templateId"
        class="provenance-choice__select"
        :options="planningTemplateOptions"
        :label="$t('automatic_planning.sidebar.from_template.template_placeholder')"
        :no-results-label="$t('search_bar.no_result')"
        :search-placeholder="$t('labels.search')"
      />
    </template>
    <template v-else-if="isStep('previous-week')">
      <hr class="step-form__separation">
      <h2 class="previous-week__subtitle">
        {{ $t('automatic_planning.sidebar.previous_week.week_subtitle') }}
      </h2>
      <div class="previous-week__weeks-select">
        <label class="previous-week__select-label">
          {{ $t('automatic_planning.sidebar.previous_week.week_label') }}
        </label>
        <div
          v-for="week in availableWeeks"
          :key="week.toString()"
          v-tooltip.bottom-end="weekTooltip(week)"
        >
          <button
            :class="weekButtonClasses(week)"
            :disabled="weekDisabled(week)"
            @click="setWeek(week)"
          >
            {{ weekNum(week) }}
          </button>
        </div>
      </div>
    </template>

    <hr class="step-form__separation">
    <h3 class="step-form__title">
      {{ $t('automatic_planning.sidebar.step_form.filters_prompt') }}
    </h3>
    <div class="step-form__option">
      <label for="automatic-planning-postes">
        {{ $t('automatic_planning.sidebar.step_form.postes') }}
      </label>
      <SkSelectV2
        id="automatic-planning-postes"
        v-model="selectedPosteIds"
        :options="posteOptions"
        :group-options="allButtonOption"
        :label="$t('automatic_planning.sidebar.step_form.postes_select_placeholder')"
        :search-placeholder="$t('automatic_planning.sidebar.step_form.search')"
        :group-count-label="$t('automatic_planning.sidebar.step_form.postes_select.count')"
        :no-results-label="$t('automatic_planning.sidebar.step_form.postes_select.no_result')"
        name="automatic-planning-postes"
        width="240px"
        multi
        object-value
        no-confirm
        append-to-body
      >
        <template #group-count="{ count }">
          {{ $tc('automatic_planning.sidebar.step_form.postes_placeholder', count) }}
        </template>
        <template #selected-option="{ value }">
          {{
            $tc(
              'automatic_planning.sidebar.step_form.postes_placeholder',
              value.length
            )
          }}
        </template>
        <template #option="{ option }">
          <div class="step-form__select-text text-truncate">
            {{ option.text }}
          </div>
        </template>
      </SkSelectV2>
    </div>
    <div class="step-form__option">
      <label for="automatic-planning-employees">
        {{ $t('automatic_planning.sidebar.step_form.users') }}
      </label>
      <SkSelectV2
        id="automatic-planning-users"
        v-model="selectedEmployeeIds"
        :options="userOptions"
        :group-options="allButtonOption"
        :disabled-options="usersWithoutCompetencies"
        :label="$t('automatic_planning.sidebar.step_form.users')"
        :search-placeholder="$t('automatic_planning.sidebar.step_form.search')"
        :group-count-label="$t('automatic_planning.sidebar.step_form.users_select.count')"
        :no-results-label="$t('automatic_planning.sidebar.step_form.users_select.no_result')"
        name="automatic-planning-users"
        width="240px"
        multi
        object-value
        append-to-body
        no-confirm
      >
        <template #group-count="{ count }">
          {{ $tc('automatic_planning.sidebar.step_form.users_placeholder', count) }}
        </template>
        <template #selected-option="{ value }">
          {{
            $tc(
              'automatic_planning.sidebar.step_form.users_placeholder',
              value.length
            )
          }}
        </template>
        <template #option="{ option }">
          <div
            v-tooltip="disabledUserTooltip(option)"
            class="text-truncate step-form__select-text"
          >
            {{ option.text }}
          </div>
        </template>
      </SkSelectV2>
    </div>

    <div class="automatic-planning__rules-box__submit">
      <SkOroraButton
        variant="tertiary"
        @click="stepBack"
      >
        {{ $t('automatic_planning.sidebar.back') }}
      </SkOroraButton>
      <SkOroraButton
        :disabled="submitDisabled"
        :loading="submittingAssignment"
        @click="submit"
      >
        {{ $t('automatic_planning.sidebar.generate') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { uniq } from '@skello-utils/collection';

export default {
  name: 'StepForm',
  props: {
    automaticPlanningRulesUrl: {
      type: String,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    submittingAssignment: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      posteOptions: [],
      userOptions: [],
      selectedEmployees: [],
      selectedPostes: [],
      weekSelected: {},
      templateId: '',
    };
  },
  computed: {
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsTemplates', ['templates']),
    ...mapState('planningsAutomaticPlanning', ['config']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('planningsAutomaticPlanning', ['provenanceSteps']),
    ...mapGetters('planningsState', ['monday']),
    ...mapState('planningsCompetencies', ['competencies']),
    ...mapState('currentShop', ['currentShop']),
    selectedEmployeeIds: {
      get() {
        return this.selectedEmployees.map(user => user.id);
      },
      set(newValue) {
        const users = this.userOptions.filter(user => newValue.includes(user.id));
        if (users.length !== this.userOptions.length) {
          this.$skAnalytics.track('automatic_planning_change_employees');
        }
        this.selectedEmployees = users;
      },
    },
    selectedPosteIds: {
      get() {
        return this.selectedPostes.map(poste => poste.id);
      },
      set(newValue) {
        const postes = this.posteOptions.filter(poste => newValue.includes(poste.id));
        if (postes.length !== this.posteOptions.length) {
          this.$skAnalytics.track('automatic_planning_change_postes');
        }
        this.selectedPostes = postes;

        const competenciesUsers = [];

        // for each selected poste, we fetch the employees who are assigned
        // to this poste in the smart planner settings, and add selects them
        // if they aren't already selected
        this.selectedPostes.forEach(selectedPoste => {
          this.competencies.forEach(competency => {
            if (competency.posteId !== selectedPoste.id) return;
            const allCompetenciesWithSamePoste =
              this.competencies.filter(
                currentCompetency => currentCompetency.posteId === competency.posteId,
              );

            allCompetenciesWithSamePoste.forEach(comp => {
              competenciesUsers.push(
                ...this.userOptions.filter(userOption => userOption.id === comp.userId),
              );
            });
          });
        });
        this.selectedEmployees = uniq(competenciesUsers);
      },
    },
    sourceWeeks() {
      return this.config.shift_in_previous_weeks;
    },
    availableWeeks() {
      const monday = skDate(this.monday);
      return [
        monday.clone().subtract(5, 'week'),
        monday.clone().subtract(4, 'week'),
        monday.clone().subtract(3, 'week'),
        monday.clone().subtract(2, 'week'),
        monday.clone().subtract(1, 'week'),
      ];
    },
    planningTemplateOptions() {
      return this.templates.map(template => ({
        id: template.id,
        text: template.attributes.name,
      }));
    },
    allButtonOption() {
      return [
        {
          id: 0,
          text: this.$t('automatic_planning.sidebar.step_form.users_select.select_all'),
          matchKey: '*',
        },
        { id: 1, separator: true, matchKey: null },
      ];
    },
    submitDisabled() {
      if (this.submittingAssignment || !this.provenanceSteps) {
        return true;
      }

      if (this.isStep(this.provenanceSteps.from_template)) {
        if (!this.templateId) return true;
      } else if (this.isStep(this.provenanceSteps.previous_week)) {
        if (!this.weekSelected) return true;
      }

      return this.selectedPostes.length === 0 || this.selectedEmployees.length === 0;
    },
    usersWithCompetencies() {
      if (!this.competencies) return [];

      return uniq(this.competencies.map(competency => competency.userId));
    },
    usersWithoutCompetencies() {
      if (!this.competencies) return [];

      const usersMissingCompetencies =
        this.userOptions.filter(option => !this.usersWithCompetencies.includes(option.id));

      // 0 is added to disable the "all" button
      return usersMissingCompetencies.length === 0 ?
        [] :
        [0, ...usersMissingCompetencies.map(option => option.id)];
    },
  },
  beforeMount() {
    if (this.currentStep === 'current-planning') {
      this.$skAnalytics.track('automatic_planning_unassigned');
    } else if (this.currentStep === 'from-template') {
      this.$skAnalytics.track('automatic_planning_template');
    } else { // previous-week
      this.$skAnalytics.track('automatic_planning_previous_week');
    }
  },
  mounted() {
    this.selectAllEmployees();
    this.selectAllPostes();
    this.setDefaultSelectedWeek();
  },
  methods: {
    ...mapActions('planningsCompetencies', ['fetchCompetencies']),
    isStep(step) {
      return this.currentStep && this.currentStep === step;
    },
    selectAllEmployees() {
      this.userOptions = this.users.map(user => ({
        id: user.id,
        text: this.fullName(user),
      }));
      this
        .fetchCompetencies({ shopId: this.currentShop.id, users: this.users })
        .then(() => {
          this.selectedEmployees =
            this.userOptions.filter(option => this.usersWithCompetencies.includes(option.id));
        });
    },
    selectAllPostes() {
      this.posteOptions = this.postes.map(poste => ({
        id: poste.id,
        text: poste.attributes.name,
      }));
      this.selectedPostes = this.posteOptions;
    },
    stepBack() {
      this.$skAnalytics.track('automatic_planning_back');
      this.$emit('update-step', 'provenance');
    },
    submit() {
      const { templateId, weekSelected, selectedEmployees, selectedPostes } = this;
      this.$emit('submit', { templateId, selectedEmployees, selectedPostes, weekSelected });
    },
    trackLinkClick() {
      this.$skAnalytics.track('automatic_planning_settings', { source: 'Start SP' });
    },
    goBack() {
      this.$emit('update-step', 'provenance');
      this.$skAnalytics.track('automatic_planning_back_on_base_references');
    },
    weekTooltip(week) {
      const weekStart = week.format('DD MMMM');
      const weekEnd = week.clone().endOf('w').format('DD MMMM');

      if (this.weekDisabled(week)) {
        return this.$t(
          'automatic_planning.sidebar.previous_week.week_disabled',
          { weekStart, weekEnd },
        );
      }

      return `${weekStart} - ${weekEnd}`;
    },
    weekButtonClasses(week) {
      return {
        'previous-week__weeks-select__btn': true,
        'previous-week__weeks-select__btn--active': week === this.weekSelected,
        'previous-week__weeks-select__btn--disabled': this.weekDisabled(week),
      };
    },
    weekDisabled(week) {
      const weekArray = this.sourceWeeks.find(sourceWeek => week.format('YYYY-MM-DD') === sourceWeek[0]);
      return weekArray && !weekArray[1];
    },
    setDefaultSelectedWeek() {
      const lastAvailableWeek = this.availableWeeks[this.availableWeeks.length - 1];
      if (!this.weekDisabled(lastAvailableWeek)) {
        this.weekSelected = lastAvailableWeek;
      }
    },
    weekNum(week) {
      return this.$t(
        'automatic_planning.sidebar.week_btn',
        { week_number: week.isoWeek() },
      );
    },
    setWeek(weekSelected) {
      this.weekSelected = weekSelected;
    },
    disabledUserTooltip(option) {
      return this.usersWithCompetencies.includes(option.id) ?
        null : this.$t('automatic_planning.sidebar.step_form.users_select.no_competency');
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: .5rem;
}

.automatic-planning__sidepanel__step-form__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.automatic-planning__rules-box__submit {
  display: flex;
  position: absolute;
  width: 412px;
  padding: 30px 24px 24px;
  bottom: 0;
  right: 0;
  margin-top: auto;
  justify-content: space-between;
}

.step-form__from-history__container {
  display: flex;
  border: 1px solid $sk-grey-10;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.step-form__from-history__icon {
  margin-left: 22px;
  margin-right: 18px;
}

.step-form__intro__history {
  color: $sk-grey;
  margin-right: 22px;
  margin-bottom: 12px;
  line-height: 19px;
}

.sk-link--medium:hover {
  text-decoration: underline;
}

.step-form__intro {
  color: $sk-grey;
  margin-top: 10px;
  margin-bottom: 4px;
}

.step-form__step-title {
  margin-top: 21px;
  margin-bottom: 19px;
  line-height: 19px;
}

.step-form__select-label {
  margin-bottom: 24px;
}

.automatic-planning__back-btn {
  cursor: pointer;
  color: $sk-grey;
  display: flex;
  align-items: center;
}

.automatic-planning__back-btn:hover {
  cursor: pointer;
}

.step-form__title {
  font-size: $fs-text-m;
  font-weight: $fw-semi-bold;
  margin-bottom: 14px;
  margin-top: 4px;
}

.step-form__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.step-form__select-text {
  font-size: $fs-text-m;
  width: 165px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step-form__separation {
  margin: 16px 0;
  border: 0;
  border-top: 1px solid #eee;
}

.previous-week__select-label {
  margin-bottom: 0;
  margin-right: 60px;
  font-weight: 100;
}

.previous-week__subtitle {
  font-size: $fs-text-m;
  margin-top: 4px;
  margin-bottom: 20px;
  font-weight: $fw-semi-bold;
}

.previous-week__weeks-select {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

.previous-week__weeks-select__btn {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background: $sk-grey-10;
  color: $sk-grey;
  margin: 0 5px;
  border: 0;

  &:focus {
    outline: 0;
  }
}

.previous-week__weeks-select__btn--active {
  background: $sk-blue;
  color: white;
}

.previous-week__weeks-select__btn--disabled {
  opacity: .4;
  cursor: not-allowed;
}

.provenance-choice__subtitle {
  margin-top: 4px;
  margin-bottom: 11px;
  font-weight: $fw-semi-bold;
  color: $sk-black;
  line-height: 22px;
  font-size: $fs-text-m;
}

.provenance-choice__select {
  margin-bottom: 4px;
}
</style>
