<template>
  <div :class="arrowClass">
    <UpArrowV2Icon />
  </div>
</template>

<script>
const DIRECTIONS = ['up', 'down'];

export default {
  name: 'SortingArrow',
  props: {
    name: {
      type: String,
      required: true,
    },
    sortedName: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      required: true,
      validator: value => DIRECTIONS.includes(value),
    },
  },
  computed: {
    arrowClass() {
      return {
        [`sorting-arrow--${this.direction}`]: (this.name === this.sortedName),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sorting-arrow--down {
  transform: rotate(180deg);
  transition: transform .3s;
}

.sorting-arrow--up {
  transform: rotate(0deg);
  transition: transform .3s;
}
</style>
