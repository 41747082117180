<template>
  <tr class="generic_shop_row">
    <td class="generic_shop_row-name-cell">
      <span class="generic_shop_row-name-cell__label">
        {{ shop.attributes.name }}
      </span>

      <SkOroraTag
        v-if="isPending"
        v-tooltip.right="$t('organisation_settings.tabs.pos_partners.chift.pending_connection')"
        class="generic_shop_row-name-cell__tag"
        size="large"
        color="skWarning50"
        icon="InfoV2Icon"
        variant="orange"
      >
        {{ $t('organisation_settings.tabs.pos_partners.chift.pending') }}
      </SkOroraTag>
    </td>
    <td v-if="shouldRenderDropdown">
      <SkSelectV2
        ref="rowDropdown"
        v-model="providerShop"
        :options="providerShops"
      />
    </td>
    <td
      v-if="shouldRenderButton"
      class="pos-card__connection"
    >
      <SkOroraButton
        variant="tertiary"
        :variant-color="isLinked ? $skColors.skError : null"
        @click.stop="onLinkClick"
      >
        {{ linkText }}
      </SkOroraButton>
    </td>
  </tr>
</template>

<script>
import { SkSelectV2 } from '@skelloapp/skello-ui';
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'GenericShopRow',
  components: {
    SkSelectV2,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
    shop: {
      type: Object,
      required: true,
    },
  },
  emits: ['link', 'unlink', 'update-integration', 'create-integration'],
  data: () => ({
    providerShopBeingEdited: null,
  }),
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('partnersTools', ['genericPartners']),

    providerShop: {
      get() {
        if (this.integration.providerShops.length) {
          return this.providerShopBeingEdited ||
            this.genericPartners.shopsToLink[this.shop.id]?.config?.locationId ||
            null;
        }

        return null;
      },
      set(newValue) {
        this.providerShopBeingEdited = newValue;
        this.handleIntegrationChanges();
      },
    },
    integrationToCreate() {
      const integrationToCreate = {
        shopId: this.shop.id,
        organisationId: this.currentOrganisation.id,
      };

      if (this.integration.chiftId) {
        return {
          ...integrationToCreate,
          providerId: 'CHIFT',
          config: {
            consumerId: this.integration.consumerId,
            locationId: this.providerShop,
            providerId: String(this.integration.chiftId),
          },
        };
      }

      return {
        ...integrationToCreate,
        providerId: this.integration.id,
        config: { ...this.linkedIntegrationData?.config || {}, posShopId: this.providerShop },
      };
    },
    integrationToUpdate() {
      return {
        ...this.linkedIntegrationData,
        config: {
          ...this.linkedIntegrationData?.config || {},
          [this.posShopKey]: this.providerShop,
        },
      };
    },
    posShopKey() {
      return this.integration.chiftId ? 'locationId' : 'posShopId';
    },
    isOrganisationLevelIntegration() {
      if (this.integration.chiftId) {
        return this.integration.isSupportingLocations;
      }

      return this.integration.requirements.shop.actions.length === 0;
    },
    shouldRenderDropdown() {
      return this.isOrganisationLevelIntegration;
    },
    shouldRenderButton() {
      return !this.isOrganisationLevelIntegration;
    },
    linkedIntegrationData() {
      return this.integration.registeredIntegrations
        .find(integrationData => integrationData.shopId === this.shop.id);
    },
    isLinked() {
      return !!this.linkedIntegrationData;
    },
    linkText() {
      return this.isLinked ?
        this.$t('organisation_settings.tabs.pos_partners.unlink_shop') :
        this.$t('organisation_settings.tabs.pos_partners.link_shop');
    },
    linkClass() {
      return this.isLinked ? 'pos-card__connection--unlink' : 'pos-card__connection--link';
    },
    providerShops() {
      return this.integration.providerShops.map(shop => ({
        text: shop.name,
        id: shop.id,
      }));
    },
    isPending() {
      return this.integration.chiftId && !this.integration.isSupportingLocations &&
        !!this.integration.registeredIntegrations.find(
          integration => integration.shopId === this.shop.id && !integration.config.isActive,
        );
    },
  },
  mounted() {
    const config = (
      this.linkedIntegrationData?.config ||
      this.genericPartners.shopsToLink[this.shop.id]?.config
    ) ?? {};
    this.providerShopBeingEdited = config[this.posShopKey];
  },
  methods: {
    ...mapMutations('partnersTools', [
      'setShopToLink',
      'setLinkedShopsToUpdate',
    ]),
    onLinkClick() {
      const emitEvent = this.isLinked ? 'unlink' : 'link';
      this.$emit(emitEvent, this.shop);
    },
    handleIntegrationChanges() {
      if (this.linkedIntegrationData) {
        this.updateIntegration();
      } else {
        this.createIntegration();
      }
    },
    handleOutsideClick() {
      if (!this.shouldRenderDropdown) return;

      this.$refs.rowDropdown.$refs.dropdown.hide();
      this.$refs.rowDropdown.handleHide();
    },
    createIntegration() {
      if (!this.providerShop) return;

      this.setShopToLink({
        shopIntegrationInfo: this.integrationToCreate,
        shopId: this.shop.id,
      });
    },
    updateIntegration() {
      this.setLinkedShopsToUpdate({
        shopIntegrationInfo: this.integrationToUpdate,
        shopId: this.shop.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-table > tbody {
  td {
    border-bottom: none;
  }
}

.generic_shop_row {
  height: 60px;
  border-radius: 2px;
}

.generic_shop_row-name-cell {
  padding-left: 5px;
  display: flex;
  align-items: center;
  height: 60px;

  &__label {
    max-width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tag {
    margin-left: 8px;
    cursor: default;
  }
}

.pos-card__connection {
  text-align: right;
  padding-right: 20px;

  a {
    cursor: pointer;
  }
}

.pos-card__connection--link {
  color: $sk-blue;
}

.pos-card__connection--unlink {
  color: $sk-error;
}
</style>
