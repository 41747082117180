<template>
  <SkSidePanel
    id="follow-up-docs-esignature-panel"
    ref="FollowUpDocsEsignatureSidePanel"
    size="ds-medium"
    medallion-content-component="SignIcon"
    medallion-background-color="#f3f3f3"
    medallion-content-color="#727272"
    :title="$t('employees.signature_followup.side_bar.title')"
  >
    <template #body>
      <FollowUpStep />
    </template>
  </SkSidePanel>
</template>

<script>

import { SIDEPANEL_SHOW_EVENT } from '@skelloapp/skello-ui';
import FollowUpStep from './FollowUpStep';

export default {
  name: 'FollowUpDocsEsignatureSidePanel',
  components: {
    FollowUpStep,
  },
  mounted() {
    this.listenOnRoot('open-docs-esignature', this.handleShow);
  },
  methods: {
    handleShow() {
      this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'follow-up-docs-esignature-panel');
    },
  },
};
</script>

  <style lang="scss" scoped>
  // to remove the default horizontal padding on panel body

  ::v-deep .sk-sidepanel__container {
    width: 620px !important;
  }

  ::v-deep .sk-sidepanel__body {
    padding: 10px 0 !important;
  }

  ::v-deep .sk-sidepanel__body--no-footer {
    min-height: calc(100vh - 84px) !important;
    overflow-x: unset !important;
    overflow-y: unset !important;
  }

  // design spec to have a different padding on nav items
  ::v-deep .sk-nav {
    padding-left: 25px;
  }

  ::v-deep .sk-sidepanel__title {
    padding-top: 18px !important;
    padding-left: 42px !important;
  }

  .follow-up-docs-esignature-panel__header {
    display: flex;
  }

  .follow-up-docs-esignature-panel__header__title {
    line-height: 22px;
    margin-top: 2px;
  }

  .follow-up-docs-esignature-panel__header__medaillon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    margin-right: 15px !important;
    background: $sk-grey-10;
  }
  </style>
