<template>
  <!-- eslint-disable  max-len -->
  <div :class="columnClasses">
    <div
      v-tooltip.auto="initialCounterTitle"
      :class="weekColumnTitleClass"
    >
      {{ $t('dates.week', { weekNumber: week.week }) }}
    </div>
    <div v-if="!week.disabled">
      <TableCell :data="week.hours_worked" />
      <TableCell :data="week.absence_hours_off_counter" />
      <TableCell
        :show-dash="week.active_contract_interim"
        :tooltip="interimContractWarning"
        :data="week.contract_data"
        :color="week.active_contract_interim ? '#d03e50' : ''"
      />
      <!-- If variable contract hours start this month but not this week, display placeholder -->
      <TableCell
        v-if="showVariableContractHoursLine && !week.variable_contract_hours"
        show-dash
      />
      <TableCell
        v-else-if="showVariableContractHoursLine"
        :data="week.variable_contract_hours.hours"
        has-subtitle
        padding="0 0 20px"
      >
        <template v-if="week.variable_contract_hours">
          <VariableContractHoursTag
            :nb-weeks="week.variable_contract_hours.cycles"
            :specific-week-index="week.variable_contract_hours.index"
            size="small"
            class="employee-counters__week-tag"
          />
        </template>
      </TableCell>
      <TableCell
        v-if="majoration"
        :show-dash="week.show_dash"
        :tooltip="interimContractWarning"
        :data="week.over_hours"
        :subtitle="roundedValue(week.over_hours_w_majoration - week.over_hours)"
        :color="week.active_contract_interim ? '#d03e50' : ''"
        has-subtitle
        only-positive
      />
      <TableCell
        :show-dash="week.show_dash"
        :data="roundedValue(week.hours_counter - week.manual_changes)"
        show-number-sign
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
      <TableCell
        :show-dash="week.show_dash"
        :tooltip="manualChangesTitle"
        :data="week.manual_changes"
        editable
        @click="openModal"
      />
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <modify-counter-modal
          :id="week.week"
          ref="modal"
          :manual-changes="week.manual_changes"
          :old-counter="week.hours_counter"
          :date="week.end_week_date"
        />
      </MountingPortal>
      <TableCell
        :show-dash="week.show_dash"
        :data="week.hours_counter"
        show-number-sign
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
    </div>
    <div v-else>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell v-if="majoration" />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { roundFloat } from '@skello-utils/formatting/numbers';
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';

import { TableCell } from '../shared/components';
import ModifyCounterModal from './ModifyCounterModal';

export default {
  name: 'HoursCounterWeekColumn',
  components: {
    TableCell,
    ModifyCounterModal,
    VariableContractHoursTag,
  },
  props: {
    week: {
      type: Object,
      required: true,
    },
    showVariableContractHoursLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('employeeCounters', ['majoration']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    emptyWeek() {
      return this.week.show_dash;
    },
    canPerformManualModification() {
      return (
        this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser
      ) && this.canEditEmployeeInfo;
    },
    weekColumnTitleClass() {
      return {
        'employee-counters__week-title': true,
        'employee-counters__week-title--initial': this.week.counter_initial,
      };
    },
    initialCounterTitle() {
      return this.week.counter_initial ?
        this.$i18n.t('employees.tabs.counters.hours_counter.tooltip.counter_initialized_this_week') :
        '';
    },
    columnClasses() {
      return {
        'employee-counters__week': true,
        'employee-counters__week--disabled': this.week.disabled,
      };
    },
    manualChangesTitle() {
      return !this.emptyWeek ?
        this.$i18n.t('employees.tabs.counters.hours_counter.tooltip.modify_counter') :
        '';
    },
    interimContractWarning() {
      return this.week.active_contract_interim ?
        this.$i18n.t('employees.tabs.counters.hours_counter.tooltip.dash_interim_contract') :
        '';
    },
  },
  methods: {
    openModal(event) {
      if (this.emptyWeek) return;
      if (!this.canPerformManualModification) {
        this.$skToast({
          message: this.$i18n.t('employees.tabs.counters.hours_counter.manual_changes_modal.unauthorized'),
          variant: 'error',
        });

        return;
      }
      this.$skAnalytics.track('manual_change_hours_counter');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, this.$refs.modal.id);
    },
    roundedValue(value) {
      return roundFloat(value, 2);
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-counters__week-tag {
  margin-bottom: 14px;
}
</style>
