<template>
  <div id="shop-form__page-container ">
    <div class="shop-form__navbar">
      <SkLogo />
      <SkMedallion
        :text="userInitials"
        background-color="#2b66fe"
        color="white"
      />
    </div>
    <div class="row shop-form__row">
      <div class="col-3 shop-form__progress_bar">
        <div class="shop-form__progress_bar_item shop-form__progress_bar--active">
          {{ $t('organisation_settings.shop_form.progress_bar.new_shop') }}
        </div>
        <div class="shop-form__list">
          <li
            class="shop-form__progress_bar_item
            shop-form__progress_bar--active"
          >
            {{ $t('organisation_settings.shop_form.progress_bar.shop_data') }}
          </li>
          <li
            v-if="isBillingOnShop"
            :class="shopBillingStepSidebarClass"
          >
            {{ $t('organisation_settings.shop_form.progress_bar.billing_data') }}
          </li>
        </div>
      </div>
      <div class="col-6">
        <router-view />
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <CongratulationModal
            ref="congratulationModal"
            @back-to-skello="handleSkelloRedirection"
            @new-shop="handleNewShop"
          />
        </MountingPortal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';

import {
  MODAL_HIDE_EVENT,
  SkLogo,
} from '@skelloapp/skello-ui';

import { httpClient } from '@skello-utils/clients';

import CongratulationModal from './CongratulationModal';

export default {
  name: 'ShopForm',
  components: { SkLogo, CongratulationModal },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // No permission
      if (!vm.currentLicense.attributes.canCreateShop) return false;

      // No params (ususally refreshing page)
      if (vm.currentOrganisation.attributes.clusters && !to.params.parentClusterNodeId) {
        vm.setNavbarVisibility(true);

        vm.$emit('toggle-sidebar');

        return vm.$router.push({
          name: 'organisation_settings_clusters',
          params: { skipBeforeRouteLeave: true },
        });
      }

      vm.setNavbarVisibility(false);

      vm.$emit('toggle-sidebar');

      return true;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.setNavbarVisibility(true);

    this.$emit('toggle-sidebar');

    next();
  },
  props: {
    parentClusterNodeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      steps: ['shop', 'billing'],
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('config', ['config', 'shopCreationCurrentStep']),
    ...mapGetters('features', ['isBundleSystemActive']),
    ...mapGetters({ userInitials: 'currentUser/initials' }),
    isShopStep() {
      return this.shopCreationCurrentStep === 'shop';
    },
    isBillingOnShop() {
      if (this.currentOrganisation.attributes.doNotPay) return false;

      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[1];
    },
    shopBillingStepSidebarClass() {
      return {
        'shop-form__progress_bar--active': !this.isShopStep,
        'shop-form__progress_bar--inactive': this.isShopStep,
      };
    },
  },
  mounted() {
    this.$emit('onboarding-loaded');
  },
  methods: {
    ...mapMutations(['setNavbarVisibility']),
    handleSkelloRedirection() {
      httpClient
        .get('/v3/api/current_organisation')
        .then(response => {
          const organisation = response.data;

          const redirectBaseUrl = `/v3/shops/${organisation.data.attributes.lastShopId}`;
          window.location.href = this.isBundleSystemActive() ? redirectBaseUrl : `${redirectBaseUrl}/plannings/weeks`;

          this.clearLocalStorage();
        });
    },
    handleNewShop() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, this.$refs.congratulationModal.$el.id);
      this.clearLocalStorage();

      Object.assign(this.$data, this.$options.data.call(this));
      this.$router.push({
        name: 'organisation_settings_shops_new_form',
        params: { parentClusterNodeId: this.parentClusterNodeId },
        hash: this.$route.hash,
      });
    },
    clearLocalStorage() {
      localStorage.removeItem('configured');
      localStorage.removeItem('createdShop');
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-form__navbar {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-size: $fs-text-m;
  border-bottom: 1px solid #ddd;
}

.shop-form__progress_bar {
  font-size: $fs-text-m;
  line-height: 17px;
  text-align: left;
  margin-top: 200px;
  display: table;
  padding-left: 6%;

  .shop-form__progress_bar--active {
    color: $sk-blue;
    border-left: 2px solid $sk-blue;
  }

  .shop-form__progress_bar--inactive {
    color: $sk-grey;
    border-left: 2px solid $sk-grey-10;
  }
}

.shop-form__progress_bar_item {
  padding-bottom: 10px;
  padding-left: 25px;
  padding-top: 10px;
}

.shop-form__list {
  list-style-type: disc;
  font-size: $fs-text-m;
  line-height: 17px;
  text-align: left;

  li {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-position: inside;
  }
}

.shop-form__row {
  margin-left: 0;
  margin-right: 0;
}

#shop-form__page-container {
  align-self: center;
  text-align: center;
  height: 100vh;
  overflow: auto;
}

#link-logo-skello {
  height: 32px;
}

.shop-form__spinner-wrapper {
  display: flex;
  justify-content: center;
  color: $sk-blue;
  font-size: 2em;
  padding-top: 4em;
}
</style>
