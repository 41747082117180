<template>
  <div>
    <div>
      <div class="optimization__structure__title">
        {{ $t('optimization.sidebar.structure_rules') }}
      </div>
      <div class="optimization__structure__array optimization__structure__array--header">
        <span class="optimization__structure__padding">
          {{ $t('optimization.sidebar.activate') }}
        </span>
        <span>
          {{ $t('optimization.sidebar.rule_type') }}
        </span>
      </div>
      <div
        v-for="rule in structureRules"
        :key="`optimization-rule-${rule}`"
        class="optimization__structure__array optimization__structure__array--row"
      >
        <SkSwitch
          v-model="optiStructure.attributes[rule]"
          class="optimization__structure__switch"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t(`optimization.sidebar.structure_rule_labels.${rule}`, convention.attributes)" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OptimizationStructure',
  data() {
    return {
      structureRules: [
        'uses_availabilities',
        'uses_unavailabilities',
        'uses_daily_rest',
        'uses_over_hours',
        'uses_max_daily_hours',
        'uses_max_weekly_hours',
        'uses_maximum_daily_amplitude',
        'uses_minimum_daily_hours',
        'uses_structure',
      ],
    };
  },
  computed: {
    ...mapState('planningsOptimisation', ['optiStructure']),
    ...mapState('convention', ['convention']),
  },
};
</script>

<style lang="scss" scoped>
.optimization__structure__array {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $sk-grey-10;

  &--header {
    font-size: $fs-text-s;
    line-height: 15px;
    color: $sk-grey;
    padding-top: 18px;
    padding-bottom: 11px;
  }

  &--row {
    padding-top: 14px;
    padding-bottom: 15px;
  }
}

.optimization__structure__padding {
  padding-right: 26px;
}

.optimization__structure__switch {
  padding-right: 11px;
}

.optimization__structure__title {
  font-size: $fs-text-m;
  color: $sk-black;
  font-weight: $fw-semi-bold;
  margin: 0;
  text-align: start;
}
</style>
