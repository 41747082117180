<template>
  <tr>
    <td>
      <div class="cell">
        {{ $t('plannings.monthly_v3.counters.daily.totals') }}
        <QuestionMarkV2Icon
          v-tooltip="tooltip"
          class="icon"
          fill="#000000"
          height="12"
          width="12"
        />
      </div>
    </td>

    <template v-for="(week, weekKey, weekIndex) of weeks">
      <td
        v-for="(day, dayIndex) of week.days"
        :key="`${weekKey + '-' + day.date}`"
      >
        <div class="cell">
          <!-- empty divs to keep last cell aligned during loading -->
          <div
            v-if="dailyCountersRowComponentStoreProps.arePlanningDataBatchesLoading ||
              dailyCountersRowComponentStoreProps.kpisLoading"
          />
          <span
            v-else
            :style="{ fontSize: kpisFontSize }"
          >
            {{ getDayWorkedHours(weekIndex, dayIndex) }}
          </span>
        </div>
      </td>

      <!-- Empty column that corresponds to weekly counters column in the table -->
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <td />
    </template>

    <!-- Empty column that will expand based on TableHeader's dynamic width to fill the screen -->
    <td />

    <td>
      <div class="cell">
        <SkLoader
          v-if="dailyCountersRowComponentStoreProps.kpisLoading"
          size="medium"
        />
        <div
          v-else
          class="cell total"
        >
          {{ totalWorkedHours }}
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import { QuestionMarkV2Icon } from '@skelloapp/skello-ui';

export default {
  name: 'DailyCountersRow',
  components: {
    QuestionMarkV2Icon,
  },
  props: {
    weeks: {
      type: Object,
      required: true,
    },
    dailyCountersRowComponentStoreProps: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltip() {
      return this.$t('plannings.monthly_v3.counters.daily.tooltip');
    },
    firstShowedDay() {
      return Object.values(this.weeks)[0].from;
    },
    totalWorkedHours() {
      const hours = this.kpiWorkedHours?.total;

      return hours ? `${hours.toFixed(2)}h` : '-';
    },
    kpiWorkedHours() {
      return this.dailyCountersRowComponentStoreProps.kpis.real?.worked_hours;
    },
    kpisFontSize() {
      if (!this.kpiWorkedHours?.daily) return '12px';

      const maxDigits = Math.max(
        ...this.kpiWorkedHours.daily.map(hours => hours.toFixed(2).replace('.00', '').length),
      );

      if (maxDigits <= 4) return '12px';
      if (maxDigits === 5) return '10px';
      return '8px';
    },
  },
  created() {
    this.fetchMonthlyKpis();
  },
  methods: {
    ...mapActions('planningsKpis', ['fetchKpis']),
    fetchMonthlyKpis() {
      const params = {
        shopId: this.dailyCountersRowComponentStoreProps.currentShop.id,
        date: this.firstShowedDay,
        workedHoursOnly: true,
        source: 'direct_created',
        filters: {},
        weeksToFetch: this.dailyCountersRowComponentStoreProps.weeksToFetch,
        // The following line will be removed in ticket DEV-15880
        absencesCountry:
          this.dailyCountersRowComponentStoreProps.currentShop.attributes.absencesCountry,
      };

      this.fetchKpis(params);
    },
    getDayWorkedHours(weekIndex, dayIndex) {
      const index = weekIndex * 7 + dayIndex;
      const hours = this.kpiWorkedHours?.daily[index];

      return hours ? `${hours.toFixed(2).replace('.00', '')}h` : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  z-index: 5;
  background-color: $sk-white;
  position: sticky;
  padding: 0;
  bottom: 0;
  height: 32px;

  &:first-child {
    z-index: 10;
    left: 0;
  }

  &:last-child {
    z-index: 10;
    right: 0;
  }
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-s;
  width: 100%;

  &.total {
    font-weight: $fw-semi-bold;
  }
}

.icon {
  margin-left: 5px;
}

.kpis {
  &__day {
    font-size: $fs-text-s;
    font-weight: $fw-regular;
  }
}

</style>
