<template>
  <div>
    <div
      class="optimization__sidepanel-body__card optimization__sidepanel-body__card--first"
      @click="goTo('structure')"
    >
      {{ $t('optimization.sidebar.structure_rules') }}
      <div class="body__card__arrow-container">
        <RightArrowV2Icon
          width="16"
          height="16"
        />
      </div>
    </div>
    <div
      class="optimization__sidepanel-body__card"
      @click="goTo('postes')"
    >
      {{ $t('optimization.sidebar.poste_rules') }}
      <div class="body__card__arrow-container">
        <RightArrowV2Icon
          width="16"
          height="16"
        />
      </div>
    </div>
    <div
      v-if="currentOrganisation.attributes.packOffer.name === 'premium'"
      class="optimization__sidepanel-body__card"
      @click="goTo('perfs')"
    >
      {{ $t('optimization.sidebar.performance_rules') }}
      <div class="body__card__arrow-container">
        <RightArrowV2Icon
          width="16"
          height="16"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { RightArrowV2Icon } from '@skelloapp/skello-ui';

export default {
  name: 'OptimizationHome',
  components: {
    RightArrowV2Icon,
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
  },
  methods: {
    goTo(page) {
      this.$emit('change-page', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.optimization__sidepanel-body__card {
  cursor: pointer;
  height: 60px;
  width: 360px;
  border-radius: 4px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: $fs-text-m;
  line-height: 22px;
  padding: 0 20px;
}

.body__card__arrow-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sk-grey-10;
  border-radius: 50%;
}
</style>
