<template>
  <div class="user_row__wrapper">
    <div class="user_row__left">
      <DraggableIcon
        class="user_row__drag_icon"
        fill="#727272"
        height="10"
        width="12"
      />
      {{ fullName(user) }}
    </div>
    <div class="user_row__right">
      <span
        class="user_row__license"
        :style="contractHoursColor"
      >
        {{ user.relationships.highestLicense.attributes.name }}
      </span>
      <span class="user_row__contract">{{ contractInfo }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LICENSE_COLORS } from '@app-js/shared/constants/colors';

export default {
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('employees', ['fullName']),

    contractHoursColor() {
      return {
        color:
          LICENSE_COLORS[this.user.relationships.highestLicense.attributes.originalType].color,
      };
    },
    contractInfo() {
      if (this.user.attributes.onExtra) {
        return this.$t('plannings.sidebar.extra');
      }
      if (this.user.attributes.onDayRate) {
        return this.$t('plannings.sidebar.day_rate');
      }

      return `${this.user.attributes.currentContractHours}h`;
    },
  },
};
</script>
<style lang="scss" scoped>
.user_row__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  background-color: $sk-grey-10;
  border-radius: 3px;
  cursor: grab;
}

.user_row__drag_icon {
  margin-right: 13px;
}

.user_row__license {
  padding-right: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_row__contract {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $sk-blue;
  height: 24px;
  width: 41px;
  border-radius: 100px;
  background-color: $sk-white;
}

.user_row__left {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.user_row__right {
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-size: $fs-text-s;
}
</style>
