/* eslint-disable default-case */

import skDate from '@skello-utils/dates';

const getNotificationMessageFormat = locale => (locale === 'en' ? 'MMMM DD, YYYY' : 'DD MMMM YYYY');
const getNotificationCreationFormat = locale => (locale === 'us' ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm');

const formatDate = (date, format, locale) => {
  skDate.locale(locale);

  return skDate(date).format(format);
};

export const registerFormatEvent = (locale, namespacePrefix) => event => {
  switch (event.subtype) {
    case 'validate_all_days':
    case 'unvalidate_all_days':
      return {
        url: `/v3/shops/${event.shopId}/plannings/weeks/employees?date=${skDate(event.context.weekly_option_date).format('YYYY-MM-DD')}`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: {
          ...event.context,
          weekly_option_date: formatDate(
            event.context.weekly_option_date,
            getNotificationMessageFormat(locale),
            locale,
          ),
        },
      };
    case 'validate_day':
    case 'unvalidate_day':
      return {
        url: `/v3/shops/${event.shopId}/plannings/days?date=${skDate(event.context.weekly_option_date).format('YYYY-MM-DD')}`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: {
          ...event.context,
          weekly_option_date: formatDate(
            event.context.weekly_option_date,
            getNotificationMessageFormat(locale),
            locale,
          ),
        },
      };
    case 'create_shift':
      return {
        url: `/v3/shops/${event.shopId}/plannings/weeks/employees?date=${skDate(event.context.date).format('YYYY-MM-DD')}`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: {
          ...event.context,
          date: formatDate(
            event.context.date,
            getNotificationMessageFormat(locale),
            locale,
          ),
        },
      };
    case 'create_amendment':
      return {
        url: `/v3/shops/${event.shopId}/employees/${event.context.amendment_user_id}/personal`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: {
          ...event.context,
          date: formatDate(
            event.context.date,
            getNotificationMessageFormat(locale),
            locale,
          ),
        },
      };
    case 'employee_profile_updated':
      return {
        url: `/v3/shops/${event.shopId}/employees/${event.userId}/personal`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: event.context,
      };
    case 'activate_optimisation':
      return {
        url: `/shops/${event.shopId}/users/${event.userId}`,
        key: `${namespacePrefix}.notification.${event.subtype}_html`,
        createdAt: skDate(event.createdAt).format(getNotificationCreationFormat(locale)),
        context: event.context,
      };
    default:
      throw new Error(`Unknown type of event ${event.subtype}`);
  }
};
