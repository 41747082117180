<template>
  <SkModal
    id="badging-settings-modal"
    ref="badgingSettingsModal"
    :modal-title="$t('badgings.toolbar.settings_modal.title')"
    @cancel="handleCancel"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <template>
          <div class="badging-settings-modal__options">
            {{ $t('badgings.toolbar.settings_modal.description.badge_on_which_shop.intro') }}
            <br>
            {{ $t('badgings.toolbar.settings_modal.description.badge_on_which_shop.explanation') }}
            <div class="badging-settings-modal__options__select">
              <div class="badging-settings-modal__select-title">
                {{ $t('badgings.toolbar.settings_modal.label.badge_on_which_shop') }}
              </div>
              <!-- eslint-disable max-len -->
              <SkSelectV2
                v-model="uniqPunchClockForBusinessUnits"
                :disabled="!canEditPunchClockSettings"
                :options="badgeOnWhichShopOptions"
                :label="$t('badgings.toolbar.settings_modal.select_placeholder.badge_on_which_shop')"
                width="320px"
              >
                <template #selected-option="{ value }">
                  <span v-tooltip="showBadgeOnWhichShopTooltip(value)">
                    {{ truncateBadgeOnWhichShopOption(value, 50) }}
                  </span>
                </template>
                <template #option="{ option }">
                  <div> {{ option.text }} </div>
                </template>
              </SkSelectV2>
            </div>
          </div>
        </template>
        <template v-if="showDayBadgingForm">
          <div class="badging-settings-modal__detail-separator" />
          <div class="badging-settings-modal__options">
            {{ $t('badgings.toolbar.settings_modal.description.day_badging') }}
            <div class="badging-settings-modal__options__select--last">
              <div class="badging-settings-modal__select-title">
                {{ $t('badgings.toolbar.settings_modal.label.day_badging') }}
              </div>
              <SkSelectV2
                v-model="dayBadgingActivated"
                :disabled="!canEditPunchClockSettings"
                :options="dayBadgingOptions"
                :label="$t('badgings.toolbar.settings_modal.select_placeholder.day_badging')"
                width="320px"
                append-to-body
              >
                <template #selected-option="{ value }">
                  <span v-tooltip="showDayBadgingTooltip(value)">
                    {{ truncateDayBadgingOption(value, 50) }}
                  </span>
                </template>
                <template #option="{ option }">
                  <div v-if="option.id === false">
                    {{ option.text }}
                  </div>
                  <div
                    v-else
                    class="badging-settings-modal__select-line"
                  >
                    {{ option.text }}
                    <i> {{ $t('badgings.toolbar.settings_modal.select_options.day_badging_yes_detail') }} </i>
                  </div>
                </template>
              </SkSelectV2>
            </div>
          </div>
        </template>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="'badgings_settings'"
        :loading="submitLoading"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';
import { arrayToSelectOptions } from '@skello-utils/form';
import { truncateString } from '@skello-utils/formatting/strings';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'BadgingSettingsModal',
  props: {
    showDayBadgingSettingsForSector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitLoading: false,
      shouldRecalculRows: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('punchClock', ['punchClockSettings']),
    ...mapGetters('currentLicense', ['canEditPunchClockSettings']),
    ...mapGetters('badgings', ['isEmployeesView']),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isHospitalitySector']),
    ...mapGetters('features', ['isFeatureEnabled']),

    uniqPunchClockForBusinessUnits: {
      get() {
        return this.currentShop.attributes.uniqPunchClockForBusinessUnits;
      },
      set(newValue) {
        this.setShopAttributes({ uniqPunchClockForBusinessUnits: newValue });
      },
    },
    dayBadgingActivated: {
      get() {
        return this.currentShop.attributes.dayBadgingActivated;
      },
      set(newValue) {
        this.shouldRecalculRows = true;
        this.setShopAttributes({ dayBadgingActivated: newValue });
      },
    },
    showDayBadgingForm() {
      return this.showDayBadgingSettingsForSector && this.canEditPunchClockSettings;
    },
    badgeOnWhichShopOptions() {
      const badgeOnWhichShopOptions = [
        true,
        false,
      ];
      return arrayToSelectOptions(badgeOnWhichShopOptions,
        value => {
          const option = value ? 'main_shop' : 'badging_shop';
          return this.$t(`badgings.toolbar.settings_modal.select_options.${option}`);
        });
    },
    dayBadgingOptions() {
      const dayBadgingOptions = [
        false,
        true,
      ];
      return arrayToSelectOptions(dayBadgingOptions,
        value => {
          if (value) {
            return this.$t('badgings.toolbar.settings_modal.select_options.day_badging_yes');
          }
          return this.$t('badgings.toolbar.settings_modal.select_options.day_badging_no');
        });
    },
  },
  methods: {
    ...mapMutations('currentShop', [
      'setShopAttributes',
      'squashCurrentShop',
      'squashOriginalCurrentShopData',
      'currentShopSuccess',
    ]),
    ...mapMutations('badgings', [
      'performingMatchedBadgingsRequest',
      'initDayBadgingSuccess',
      'matchedBadgingsRequestComplete',
    ]),
    ...mapActions('currentShop', ['updateShop']),
    handleCancel() {
      this.squashCurrentShop();
    },
    handleSubmit() {
      const params = {
        badging: {
          uniq_punch_clock_for_business_units: this.uniqPunchClockForBusinessUnits,
        },
      };
      if (this.showDayBadgingForm) {
        params.shop = {
          day_badging_activated: this.dayBadgingActivated,
        };
      }

      this.submitLoading = true;

      httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}`, params)
        .then(response => {
          this.$refs.badgingSettingsModal.hide();
          this.currentShopSuccess(response.data);
          this.squashOriginalCurrentShopData();

          if (this.shouldRecalculRows) {
            this.performingMatchedBadgingsRequest();
            this.initDayBadgingSuccess({
              isEmployeesView: this.isEmployeesView,
              currentShop: this.currentShop,
              punchClockSettings: this.punchClockSettings,
              currentUser: this.currentUser,
              isPlanningFeatureEnabled: this.isFeatureEnabled(FEATURES.FEATURE_PLANNING,
                currentShop.id, () => true),
              isHospitalitySector: this.isHospitalitySector,
            });
            this.matchedBadgingsRequestComplete();
          }
          this.$skToast({
            message: this.$t('badgings.toolbar.settings_modal.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    truncateBadgeOnWhichShopOption(value, length) {
      return truncateString(this.getBadgeOnWhichShopText(value), length);
    },
    showBadgeOnWhichShopTooltip(value) {
      const badgeOnWhichShopText = this.getBadgeOnWhichShopText(value);

      return badgeOnWhichShopText.length > 50 ? badgeOnWhichShopText : null;
    },
    getBadgeOnWhichShopText(value) {
      return this.badgeOnWhichShopOptions.find(
        option => option.id === value,
      ).text;
    },
    truncateDayBadgingOption(value, length) {
      return truncateString(this.getDayBadgingText(value), length);
    },
    showDayBadgingTooltip(value) {
      const dayBadgingText = this.getDayBadgingText(value);

      return dayBadgingText.length > 50 ? dayBadgingText : null;
    },
    getDayBadgingText(value) {
      if (!value) {
        return this.$t('badgings.toolbar.settings_modal.select_options.day_badging_no');
      }
      return this.$t('badgings.toolbar.settings_modal.select_options.day_badging_yes') +
        this.$t('badgings.toolbar.settings_modal.select_options.day_badging_yes_detail');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__section {
  padding: 0 !important;
  margin: 0 24px;
}

::v-deep .sk-dropdown__anchor {
  width: 320px;
}

::v-deep .sk-modal__footer {
  padding: 0 24px 24px !important;
}

.badging-settings-modal__options {
  margin-top: 24px;
}

.badging-settings-modal__options__select {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 24px;
  align-items: center;

  &--last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
  }
}

.badging-settings-modal__select-title {
  width: 146px; // 270 without new tag
  font-weight: bold;
}

.badging-settings-modal__detail-separator {
  border-bottom: 1px solid #dcdcdc;
}

.badging-settings-modal__select-line {
  line-height: 1;
}
</style>
