<template>
  <div
    v-if="document"
    class="document-card"
  >
    <div class="left-container">
      <SkMedallion
        class="document-icon"
        background-color="#eef4ff"
        icon="FileIcon"
        color="#2b66fe"
      />
      <div class="document-card__infos">
        <div class="document-card__infos__title">
          {{ document.attributes.title }}
        </div>
        <div class="document-card__infos__labels">
          <div>
            {{ $t('shop_settings.text_document_templates.created_on',
                  { date: skDate(document.attributes.createdAt).format('ll') })
            }}
          </div>
          <div class="document-card__dot-link__dot" />
          <div>pdf</div>
        </div>
      </div>
    </div>
    <div class="right-container">
      <SkMedallion
        :text="employeeInitials(document.relationships.user)"
        background-color="#2b66fe"
        color="white"
        class="document-card__employee-medallion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'DocumentCard',
  props: {
    document: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      employeeInitials: 'employees/userInitials',
    }),
  },
  methods: {
    skDate,
  },
};
</script>

<style lang="scss" scoped>
.document-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  height: 72px;
}

.left-container {
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 40px;
}

.right-container {
  display: flex;
  margin: 4px 0;
  width: 32px;
  height: 32px;
}

.document-icon {
  width: 40px !important;
  height: 40px !important;
}

.document-card__infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
  height: 28px;

  .document-card__infos__title {
    display: flex;
    align-items: center;
    font-size: $fs-text-m;
    line-height: 10px;
  }

  .document-card__infos__labels {
    display: flex;
    align-items: center;
    color: $sk-grey;
    font-size: $fs-text-s;
    line-height: 8px;
  }
}

.document-card__employee-medallion {
  margin: auto 0 auto auto;
}

.document-card__dot-link__dot {
  background: $sk-grey-10;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  margin: 0 7px;
}
</style>
