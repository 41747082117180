<template>
  <SkModal
    id="organisation-counters-reset-modal"
    :modal-title="$t('reports.counter_reset.modal.title')"
    size="ds-medium"
    @hidden="handleClusterShopsSelectionDropdownHide"
  >
    <template #body>
      <SkModalSection border-bottom="none">
        {{ $t('reports.counter_reset.modal.content_text') }}
        <strong>
          {{
            $t(
              'reports.counter_reset.modal.bold_content_text',
              {
                weekNumber: weekTag,
                dayDate: formattedPeriodEndDate,
              }
            )
          }}
        </strong>
      </SkModalSection>
      <SkModalSection border-bottom="none">
        <div :class="modalContentClasses">
          <div class="organisation-counters-reset-modal-content__select__label">
            {{ $t('reports.counter_reset.modal.shops_section.selection_label') }}
          </div>
          <div :class="selectionClasses">
            <ClusterAffiliationSelect
              v-if="isNetworkOrganisation"
              ref="ClusterAffiliationSelect"
              v-model="selectedNodes"
              class="cluster-select"
              :primary-node="primaryNode"
              :user="user"
              :display-modulated-shops-only="true"
              width="572px"
              append-to-body
            />
            <SkSelectV2
              v-else
              v-model="selectedShopIds"
              :class="shopsSelectClass"
              :options="filteredShops"
              :group-options="shopGroupOptions"
              :label="$t('reports.counter_reset.modal.shops_section.label')"
              :no-results-label="$t('search_bar.no_result')"
              :search-placeholder="$t('labels.search')"
              width="320px"
              append-to-body
              multi
            >
              <template
                v-if="selectedShopIds.length > 0"
                #selected-option="{ value }"
              >
                {{ $tc('reports.counter_reset.modal.shops_section.selection_count', value.length) }}
              </template>
            </SkSelectV2>
          </div>
        </div>
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton
        variant="secondary"
        :disabled="noShopSelected"
        :loading="isSubmitting"
        @click="handleSubmit"
      >
        {{ $t('reports.counter_reset.modal.actions.update') }}
      </SkOroraButton>
      <SkOroraButton
        :variant-color="$skColors.skError"
        @click="handleCancel"
      >
        {{ $t('reports.counter_reset.modal.actions.cancel') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import { getErrorMessage } from '@skello-utils/errors';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

import ClusterAffiliationSelect from '@app-js/shared/components/ClusterAffiliationSelect';

export default {
  name: 'ResetOrganisationCountersModal',
  components: { ClusterAffiliationSelect },
  props: {
    clusterNodes: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isNetworkOrganisation: {
      type: Boolean,
      default: false,
    },
    endDate: {
      type: String,
      required: true,
    },
    primaryNode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedShopIds: [],
      localSelectedNodes: [],
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    selectedNodes: {
      get() {
        return this.localSelectedNodes;
      },
      set(options) {
        this.localSelectedNodes =
          options.values
            .map(node => ({
              id: node.id,
              attributes: {
                name: node.attributes.name,
                shopId: node.attributes.shopId ? String(node.attributes.shopId) : null,
                shopCancelled: node.attributes.shopCancelled,
                organisationId: String(node.attributes.organisationId),
                parentId: String(node.attributes.parentId),
                depth: node.attributes.depth,
                ancestry: node.attributes.ancestry,
                descendantIds: node.attributes.descendantIds,
                ancestorIds: node.attributes.ancestorIds,
                modulationStatus: node.attributes.modulationStatus ?
                  String(node.attributes.modulationStatus) :
                  null,
              },
            }));

        this.selectedShopIds =
          this.localSelectedNodes
            .filter(node => node.attributes.shopId)
            .map(node => node.attributes.shopId);
      },
    },

    noShopSelected() {
      return this.selectedShopIds.length === 0;
    },
    periodEndDate() {
      const endOfPeriod = skDate(this.endDate).utc(true);
      const endOfWeek = endOfPeriod.clone().endOf('week').startOf('day');

      if (endOfPeriod.isBefore(endOfWeek)) {
        endOfWeek.subtract(1, 'week');
      }

      return endOfWeek;
    },
    formattedPeriodEndDate() {
      return this.periodEndDate.format('DD/MM/YYYY');
    },
    weekTag() {
      return this.periodEndDate.isoWeek();
    },
    filteredShops() {
      return this.clusterNodes
        .filter(node => node.attributes.modulationStatus === this.config.modulation_activated)
        .map(node => ({ id: node.attributes.shopId, text: node.attributes.name }));
    },
    shopGroupOptions() {
      return [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];
    },
    modalContentClasses() {
      return {
        'organisation-counters-reset-modal-content': true,
        'organisation-counters-reset-modal-content__no-cluster': !this.isNetworkOrganisation,
      };
    },
    selectionClasses() {
      return {
        'organisation-counters-reset-modal-content__select__clusters': this.isNetworkOrganisation,
        'organisation-counters-reset-modal-content__select__shops': !this.isNetworkOrganisation,
      };
    },
    shopsSelectClass() {
      return {
        'organisation-counters-reset-modal--content__no-cluster--empty':
          this.selectedShopIds.length === 0,
      };
    },
    successMessage() {
      return (
        this.$t('reports.counter_reset.update_toast') +
        this.$tc(
          'reports.counter_reset.modal.shops_section.selection_count',
          this.selectedShopIds.length,
        )
      );
    },
  },
  methods: {
    handleClusterShopsSelectionDropdownHide() {
      if (!this.isNetworkOrganisation) return;

      this.$refs.ClusterAffiliationSelect.forceCloseDropdown();
    },
    handleSubmit() {
      if (this.selectedShopIds.length === 0) return;

      this.$root.$emit('confirm', event, {
        title: this.$t('reports.counter_reset.modal.actions.confirm.title'),
        description: this.$tc('reports.counter_reset.modal.actions.confirm.description', this.selectedShopIds.length),
        submitLabel: this.$t('reports.counter_reset.modal.actions.confirm.submit_label'),
        onConfirmSuccess: this.onSubmitConfirm,
      });
    },
    onSubmitConfirm() {
      this.isSubmitting = true;

      return httpClient
        .post('/v3/api/shops/reset_shops_counters', {
          shop_ids: this.selectedShopIds,
          date: this.endDate,
        })
        .then(() => {
          this.$emit('submit');
          this.$skToast({
            message: this.successMessage,
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: getErrorMessage(error),
            variant: 'error',
          });

          throw error;
        })
        .finally(() => {
          this.isSubmitting = false;
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'organisation-counters-reset-modal');
        });
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'organisation-counters-reset-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-counters-reset-modal-content {
  padding: 0 0 34px;

  &__no-cluster {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__select {
    &__label {
      font-weight: $fw-semi-bold;
      text-align: left;
    }

    &__shops {
      width: 320px;
    }

    &__clusters {
      padding: 10px 0 0;

      ::v-deep .cluster-node-select__selected-option {
        min-width: 572px;
      }
    }
  }
}

.organisation-counters-reset-modal--content__no-cluster--empty {
  // Design specification: placeholder must be centered when no options are selected
  ::v-deep .sk-select__selected-option--filled-in-with-label {
    align-items: stretch;

    .sk-select__label {
      top: 12px;
      font-size: $fs-text-m;
    }
  }
}
</style>
