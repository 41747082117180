<template>
  <div class="months-planning">
    <LoadingPage v-if="isMonthlyPlanningLoading" />
    <MonthlySkeleton
      v-else-if="shouldShowSkeleton"
      :current-scroll-offset="currentScrollOffset"
      @update-visible-width="updateVisibleWidth"
    />

    <MonthlyTable
      v-else
      :current-scroll-offset="currentScrollOffset"
      @update-visible-width="updateVisibleWidth"
    />

    <HorizontalScrollBar
      v-if="!isMonthlyPlanningLoading"
      template="monthly"
      :visible-width="visibleWidth"
      :are-counters-displayed="true"
    />

    <div id="shift-info-mounting" />
    <div id="availabilities-popup-mounting" />

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageShiftModal />
      <ManageLeaveRequestModal :source="{ page: 'plannings', sub: 'month' }" />
      <TransferLeaveRequestModal
        v-if="isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2')"
      />
      <ReadOnlyShiftModal />
      <BlockingAlertModal />
      <PublishPlanningModalMonthly />
    </MountingPortal>

    <ShiftMenu />
    <ShiftAlerts />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { computeNumberOfUsersToFetch } from '@app-js/plannings/shared/utils/planning_helpers';
import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';
import MonthlySkeleton from '@app-js/plannings/shared/components/Skeleton/Monthly';
import PublishPlanningModalMonthly from '@app-js/plannings/shared/components/Toolbar/PublishPlanningModal/ModalMonthly';
import ShiftAlerts from '@app-js/plannings/shared/components/Shift/Alerts';
import ShiftMenu from '@app-js/plannings/shared/components/Shift/menu';
import ManageShiftModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/ManageShiftModal';
import ReadOnlyShiftModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/ReadOnlyShiftModal';
import HorizontalScrollBar from '@app-js/shared/components/HorizontalScrollBar';
import ManageLeaveRequestModal from '@app-js/shared/components/ManageLeaveRequestModal';
import TransferLeaveRequestModal from '@app-js/shared/components/TransferLeaveRequestModal';
import BlockingAlertModal from '@app-js/plannings/pages/Weeks/shared/PlanningTable/PlanningRow/BlockingAlertModal';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';
import MonthlyTable from './Table';

export default {
  name: 'Months',
  components: {
    PublishPlanningModalMonthly,
    ReadOnlyShiftModal,
    ManageShiftModal,
    ManageLeaveRequestModal,
    TransferLeaveRequestModal,
    BlockingAlertModal,
    LoadingPage,
    ShiftMenu,
    MonthlyTable,
    ShiftAlerts,
    MonthlySkeleton,
    HorizontalScrollBar,
  },
  data() {
    return {
      planningVisibleWidth: 0,
      visibleWidth: 0,
      currentScrollOffset: 0,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsShifts', ['blockingAlertShiftsByUser']),
    ...mapState('planningsLoading', ['isPlanningDataLoading', 'abortRecursiveBatching', 'arePlanningDataBatchesLoading', 'planningDataStatus']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    shouldShowSkeleton() {
      return this.isMonthlySkeletonEnabled && (
        this.planningDataStatus === PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
    },
    isMonthlySkeletonEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_MONTHLY_SKELETON');
    },
    isMonthlyPlanningLoading() {
      return !this.isMonthlySkeletonEnabled && this.isPlanningDataLoading;
    },
    shopId() {
      return this.$route.params.shop_id;
    },
    amountOfUsersToFetch() {
      // NOTE: Taking the window height will add some extra px we do not need
      // (corresponding to the navbar and the table header), but it allows us to have
      // this value during created hook instead of mounted, which will make the planning
      // somewhat faster
      const availableScreenHeight = window.innerHeight || 0;

      return computeNumberOfUsersToFetch({ height: availableScreenHeight, rowHeight: 48 });
    },
  },
  watch: {
    $route(to, from) {
      const hasDateChanged = to.query.date !== from.query.date;

      // If the date has changed, we can re-init the month here
      if (hasDateChanged) this.initMonth();
    },
    abortRecursiveBatching(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.initMonth();
      }
    },
    blockingAlertShiftsByUser(newValue) {
      if (Object.values(newValue).length > 0) {
        this.emitOnRoot(MODAL_SHOW_EVENT, null, 'blocking-alert-modal');
      }
    },
    isMonthlyPlanningLoading(newValue) {
      if (newValue === false) {
        const { name, path, fullPath } = this.$route;
        const measure = performance?.measure?.(path, name);

        if (!measure) {
          return;
        }

        this.$skAnalytics.track('page_load_time_planning_month', {
          duration: Math.round(measure.duration),
          fullPath,
          shopId: this.currentUser.id,
          userId: this.currentShop.id,
        });
      }
    },
  },
  created() {
    this.initMonth();
  },
  methods: {
    ...mapActions('planningsLoading', ['fetchBatchablePlanningShopDateRangeData']),
    ...mapMutations('planningsLoading', ['setAbortRecursiveBatching']),
    ...mapMutations('planningsState', ['setAsSelectedTab']),
    initMonth() {
      if (this.arePlanningDataBatchesLoading || this.isPlanningDataLoading) {
        this.setAbortRecursiveBatching(true);
        return;
      }

      // For monthly view selected counter tab is always total
      this.setAsSelectedTab('total');

      this.fetchBatchablePlanningShopDateRangeData({
        shopId: this.shopId,
        period: 'month',
        fetchDayRateUsersDaysWorked: true,
        shouldFetchByBatches: true,
        batchSize: this.amountOfUsersToFetch,
      })
        .then(() => {
          this.$emit('planning-loaded');
          // Add after data loading monthly view options
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
          throw error;
        });
    },
    updateVisibleWidth({ visibleWidth, scrollOffset }) {
      this.visibleWidth = visibleWidth;
      this.currentScrollOffset = scrollOffset;
    },
  },
};
</script>

<style lang="scss" scoped>
.months-planning {
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}
</style>
