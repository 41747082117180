<template>
  <SkEventModal
    flubber-color="#D9E6FF"
    :header-icon="tabletIcon"
    header-icon-color="#2B66FE"
    submit-button-data-test="validate_employee"
    :submit-btn-label="$t('onboarding_modal.timeclock_onboarding.button.done')"
    :on-submit-click="handleSubmit"
    :cancel-btn-label="$t('onboarding_modal.timeclock_onboarding.button.previous')"
    :on-cancel-click="handleCancel"
    :additional-btn-label="$t('onboarding_modal.timeclock_onboarding.button.later')"
    :on-additional-click="handleLater"
  >
    <template #content>
      <div class="container">
        <div class="qr-step__title">
          {{ $t('onboarding_modal.timeclock_onboarding.download_app') }}
        </div>
        <div class="qr-step__text">
          {{ $t('onboarding_modal.timeclock_onboarding.instructions') }}
        </div>
        <div class="qr-step__wrapper">
          <div class="qr-step__content">
            <img src="../assets/qr.svg">
            <lottie-player
              :src="lottieQr"
              background="transparent"
              speed="1"
              style="width: 200px; height: 200px"
              loop
              autoplay
            />
          </div>
          <div class="qr-step__bottom-text sk-text-medium-regular">
            {{ $t('onboarding_modal.timeclock_onboarding.qr_text') }}
          </div>
        </div>
      </div>
    </template>
  </SkEventModal>
</template>

<script>
import {
  TabletIcon, SkEventModal,
} from '@skelloapp/skello-ui';
import lottieQrFile from '../assets/scan.lottie.json';
import '@lottiefiles/lottie-player';

export default {
  name: 'QrStep',
  components: { SkEventModal },
  props: {

  },
  data() {
    return {
      tabletIcon: TabletIcon,
    };
  },
  computed: {
    lottieQr() {
      return JSON.stringify(lottieQrFile);
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
    handleCancel() {
      this.$emit('go-back');
    },
    handleLater() {
      this.$emit('submit');
    },
  },
};
</script>
<style lang="scss" scoped>

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .qr-step__content img {
      position: absolute;
      width: 160px;
      height: 160px;
    }

  .qr-step__title {
    font-family: Gellix;
    font-size: $fs-heading-l;
    font-weight: $fw-semi-bold;
    height: 36px;
    line-height: 36px;
  }

  .qr-step__text {
    color: $sk-grey-50;
    font-family: Gellix;
    font-size: $fs-text-l;
    font-weight: $fw-regular;
    padding-top: 16px;
    line-height: normal;
    padding-bottom: 32px;
  }

  .qr-step__row {
    display: flex;
    justify-content: space-between;
    font-family: Gellix;
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    color: $sk-grey-50;
    margin-bottom: 12px;
  }

  .qr-step__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .qr-step__content {
    position: relative;
    display: grid;
    place-items: center;
    color: $sk-grey-50;
  }

  .qr-step__bottom-text {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-grey-50;
  }

</style>
