<template>
  <div
    ref="dayViewRow"
    :class="dailyViewRowClasses"
    @scroll="handleEmitScroll"
    @click.stop="shakeLock"
    @mousemove="setMouseX"
    @mouseenter="setDragAndCreateVisibility(true, $event)"
    @mouseleave="setDragAndCreateVisibility(false, $event)"
    @mousedown="preventDragAndCreateVisibility"
  >
    <div
      :class="planningRowClasses"
      :style="zoomRange.planningRow"
    >
      <HourCell
        v-for="(date, index) in currentShopWorkingHours.slice(0, -1)"
        :key="index"
        :current-hour-cell-date="date"
        :current-date="globalConfig.currentDate"
        :hour-cell-store-props="hourCellStoreProps"
        @drop-shift="onDropShift"
      />
      <Availability
        v-for="availability in currentDayAvailabilities"
        :key="'availability' + availability.id"
        :availability="availability"
        :date="globalConfig.currentDate"
        :current-shop="globalConfig.currentShop"
        :is-overlapping="isAvailabilityOverlapping(availability)"
        :day-view-planning-size-variables="globalConfig.dayViewPlanningSizeVariables"
      />
      <Shift
        v-for="shift in dailyShifts"
        :ref="`shift_${shift.id}`"
        :key="`${shift.id}-${shift.attributes.startsAt}-${shift.attributes.endsAt}`"
        :shift="shift"
        :class="getShiftClasses(shift.id)"
        :global-config="globalConfig"
        :date="globalConfig.currentDate"
        :draggable="!isCurrentDayDisabled && !isShiftFromAnotherShop(shift)"
        :resizable="areShiftsEditable && !isShiftFromAnotherShop(shift)"
        @mouseenter.native="showMenu(shift)"
        @mouseleave.native="hideMenu"
        @dragstart.native="handleShiftDragStart(shift, $event)"
        @dragend.native="handleShiftDragEnd"
        @dragcancel.native="handleShiftDragCancel"
        @click.native="openShiftModal(shift)"
        @resizestop="dimensions => onResizeStop(shift, dimensions)"
        @resizestart="isResizingShift = true"
      />
      <DragAndCreate
        v-if="isDragAndCreateVisible"
        :style="dragAndCreateHeight"
        :is-unassigned-shifts-row="isUnassignedShiftsRow"
        :mouse-x-position="mouseX"
        :row-item="rowItem"
        :current-date="globalConfig.currentDate"
        :availabilities="currentDayAvailabilities"
        :last-user-shift="lastUserShift"
        :current-shop="globalConfig.currentShop"
        :last-scroll-value="lastScrollValue"
        :nb-current-shop-working-hours="currentShopWorkingHours.length - 1"
        :day-view-planning-size-variables="globalConfig.dayViewPlanningSizeVariables"
      />
    </div>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapActions,
  mapMutations,
} from 'vuex';

import skDate from '@skello-utils/dates';

import HourCell from '@app-js/plannings/pages/Days/shared/PlanningTable/PlanningRow/HourCell';
import Shift from '@app-js/plannings/pages/Days/Shift';
import DragAndCreate from '@app-js/plannings/pages/Days/DragAndCreate';
import Availability from '@app-js/plannings/pages/Days/Availability';
import { zoomPlanningDay } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import { availabilitiesForDay } from '@app-js/shared/utils/availabilities_helper';

import {
  getValidShiftTimes,
  sanitizeShift,
  openingAndClosingTimeAt,
  workingHours,
  isContractStartedForUserAtDate,
} from '@app-js/plannings/shared/utils/planning_helpers';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';

export default {
  components: {
    Availability,
    DragAndCreate,
    HourCell,
    Shift,
  },
  props: {
    globalConfig: {
      type: Object,
      required: true,
    },
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    shifts: {
      type: Array,
      required: true,
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    visibleDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isResizingShift: false,
      lastScrollValue: 0,
      draggedShiftId: null,
      mouseX: 0,
      displayDragAndCreate: false,
      dragAndCreateLocked: false,
      draggedShiftElement: null,
    };
  },
  computed: {
    openingAndClosingTime() {
      return openingAndClosingTimeAt(
        this.globalConfig.currentShop.attributes.openingTime,
        this.globalConfig.currentShop.attributes.closingTime,
        skDate.utc(this.globalConfig.currentDate).format(),
      );
    },
    dailyViewRowClasses() {
      return {
        'daily-view-row__wrapper': true,
        'daily-view-row__wrapper--disabled': !this.globalConfig.currentLicense.attributes.canCreateShifts || this.isCurrentDayDisabled,
        'daily-view-row__wrapper--scroll': this.globalConfig.isDayViewScrollable,
      };
    },
    planningRowClasses() {
      return {
        'planning-row__days__wrapper': true,
        'planning-row__days__wrapper--disabled': !this.globalConfig.currentLicense.attributes.canCreateShifts || this.isCurrentDayDisabled,
        'planning-row__days__wrapper--disabled-opacity': !this.areShiftsEditable,
      };
    },
    currentShopWorkingHours() {
      return workingHours(this.globalConfig.currentShop, this.globalConfig.currentDate);
    },
    currentDayAvailabilities() {
      // English locale because we compare day name (monday, tuesday...) saved in english in db
      const day = skDate(this.globalConfig.currentDate).utc(true).locale('En');
      return this.dayAvailabilities({
        date: day.format('YYYY-MM-DD'),
        weekDay: day.format('dddd').toLowerCase(),
      });
    },
    dailyShifts() {
      return this.globalConfig.dayCellShifts(this.globalConfig.currentDate, this.shifts);
    },
    isCurrentDayDisabled() {
      return this.isDayLocked ||
        !this.globalConfig.currentLicense.attributes.canCreateShifts ||
        (!this.isUnassignedShiftsRow &&
          (!this.hasUserStartedContract || this.isArchivedUser)
        );
    },
    isDayLocked() {
      return this.visibleDays.find(day => day.date === this.globalConfig.currentDate)?.isLocked;
    },
    areShiftsEditable() {
      return !this.isDayLocked &&
        this.globalConfig.currentLicense.attributes.canEditShifts &&
        (this.isUnassignedShiftsRow ||
          (this.hasUserStartedContract && !this.isArchivedUser));
    },
    lastUserShift() {
      return [...this.globalConfig.dayCellShifts(this.globalConfig.currentDate, this.shifts)]
        .sort((s1, s2) => s1.id - s2.id).pop();
    },
    isArchivedUser() {
      if (!this.globalConfig.isEmployeesView) return false;

      return skDate(this.globalConfig.currentDate).isAfter(this.rowItem.attributes.archivedAt);
    },
    isDragAndCreateVisible() {
      if (this.isDraggingAndCreatingOnThisRow) return true;

      return !this.isCurrentDayDisabled &&
        this.displayDragAndCreate &&
        !this.dragAndCreateLocked &&
        this.mouseX <= this.maximumOffset &&
        !this.isDraggingAndCreating &&
        !this.globalConfig.shiftDragging &&
        !this.isResizingShift;
    },
    isDraggingAndCreating() {
      return this.globalConfig.shiftDragAndCreatingRowId !== undefined;
    },
    isDraggingAndCreatingOnThisRow() {
      return this.globalConfig.shiftDragAndCreatingRowId === this.rowItem.id;
    },
    hasUserStartedContract() {
      return isContractStartedForUserAtDate(this.rowItem, this.globalConfig.currentDate);
    },
    dragAndCreateHeight() {
      const height = zoomPlanningDay(this.globalConfig.planningZoom).planningRow.height;

      return { height: `${parseInt(height, 10) - 1}px` };
    },
    zoomRange() {
      return { planningRow: zoomPlanningDay(this.globalConfig.planningZoom).planningRow };
    },
    maximumOffset() {
      const { pixelPerQuarterHours, sidebarWidth } = this.globalConfig.dayViewPlanningSizeVariables;
      const hourWidth = 4 * pixelPerQuarterHours;
      return (this.currentShopWorkingHours.length - 1) * hourWidth + sidebarWidth;
    },
    hourCellStoreProps() {
      return {
        currentShop: this.globalConfig.currentShop,
        dayViewPlanningSizeVariables: this.globalConfig.dayViewPlanningSizeVariables,
        is24hShop: this.globalConfig.is24hShop,
      };
    },
  },
  watch: {
    // Reset scroll when changing shop
    $route() {
      this.$refs.dayViewRow.scrollLeft = 0;
    },
  },
  mounted() {
    this.listenOnRoot('manage-shift-modal-closed', this.preventDragAndCreateVisibility);
    this.listenOnRoot('day-view-scroll', this.handleScroll);
    this.listenOnRoot('shift-menu-clicked', e => {
      const selectedShift =
        this.globalConfig.dayCellShifts(this.globalConfig.currentDate, this.shifts)
          .find(shift => e.shiftId === shift.id);

      if (selectedShift) {
        if (e.action === 'DELETE_SHIFT') {
          this.destroyShift({
            shiftId: selectedShift.id,
            shopId: this.globalConfig.currentShop.id,
            periodStartsAt: this.globalConfig.monday,
            periodEndsAt: this.globalConfig.sunday,
          })
            .then(() => {
              this.hideMenu();
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.destroy.success'),
                variant: 'success',
              });
            })
            .catch(() => {
              this.emitOnRoot('shift-menu-action-failed');
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
                variant: 'error',
              });
            });
        } else if (e.action === 'MOVE_TO_UNASSIGNED') {
          this.updateShift({
            shifts: [
              { ...selectedShift, attributes: { ...selectedShift.attributes, userId: null } },
            ],
            shopId: this.globalConfig.currentShop.id,
            periodStartsAt: this.globalConfig.monday,
            periodEndsAt: this.globalConfig.sunday,
          })
            .then(() => {
              this.hideMenu();
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.unassigned.success'),
                variant: 'success',
              });
            })
            .catch(() => {
              this.emitOnRoot('shift-menu-action-failed');
              this.$skToast({
                message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
                variant: 'error',
              });
            });
        }
      }
    });
  },
  methods: {
    ...mapActions('planningsShifts', ['updateShift', 'destroyShift']),
    ...mapMutations('planningsState', ['setShiftDragging']),
    handleEmitScroll(event) {
      // This is for the last-scroll-value of the <DragAndCreate> component
      this.lastScrollValue = event.target.scrollLeft;
      this.emitOnRoot('day-view-scroll', event);
      this.emitOnRoot('modal-alert-scroll', event);
    },
    handleScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.dayViewRow === event.srcElement) return;
      this.$refs.dayViewRow.scrollLeft = event.target.scrollLeft;
    },
    shakeLock() {
      if (this.globalConfig.isDailyView && this.isCurrentDayDisabled) {
        this.emitOnRoot('day-view-shake-lock');
      }
    },
    setMouseX(event) {
      this.mouseX = event.clientX;
      this.setDragAndCreateVisibility(true, event);
    },
    setDragAndCreateVisibility(value, event) {
      const shiftsElRect = this.dailyShifts.map(shift => this.$refs[`shift_${shift.id}`][0].$el.getBoundingClientRect());

      const isHoverAShift = shiftsElRect.some(shiftElRect => {
        const shiftElRectLeft = shiftElRect.left;
        const shiftElRectRight = shiftElRect.left + shiftElRect.width;
        const isHoveringAShift =
          event.clientX >= shiftElRectLeft && event.clientX <= shiftElRectRight;
        return isHoveringAShift;
      });

      if ((value && this.dragAndCreateLocked) || isHoverAShift) {
        this.displayDragAndCreate = false;
        this.dragAndCreateLocked = false;
        return;
      }

      this.displayDragAndCreate = value;
    },
    preventDragAndCreateVisibility() {
      this.dragAndCreateLocked = true;
    },
    isAvailabilityOverlapping(availability) {
      const startTime = skDate(availability.attributes.startTime, 'HH:mm');
      const endTime = skDate(availability.attributes.endTime, 'HH:mm');
      const filteredCurrentDayAvailabilities = this.currentDayAvailabilities
        .filter(a => a.attributes.status !== availability.attributes.status);
      for (const current of filteredCurrentDayAvailabilities) {
        const currentStart = skDate(current.attributes.startTime, 'HH:mm');
        const currentEnd = skDate(current.attributes.endTime, 'HH:mm');
        if (
          current.id !== availability.id &&
          currentStart.isBefore(endTime) &&
          startTime.isBefore(currentEnd)
        ) return true;
      }
      return false;
    },
    onDropShift({ shift, user, newStart, newEnd }) {
      if (this.isCurrentDayDisabled) return;

      const originalUserId = user.id ? parseInt(user.id, 10) : null;
      const userId = this.isUnassignedShiftsRow ? null : parseInt(this.rowItem.id, 10);

      // prevent from firing a shift update when a user drop the shift at the same place
      if (
        userId === originalUserId &&
        skDate(shift.attributes.startsAt).utc(true).isSame(skDate(newStart).utc(true)) &&
        skDate(shift.attributes.endsAt).utc(true).isSame(skDate(newEnd).utc(true))
      ) return;

      const shopId = parseInt(shift.attributes.shopId, 10);
      const localShift = cloneDeep(shift);

      // [BADGING] in case absenceShift is validated into a workshift, nullify absence params.
      if (shift.relationships.previsionalPoste &&
        shift.relationships.previsionalPoste.attributes.absenceKey &&
        shift.relationships.poste &&
        !shift.relationships.poste.attributes.absenceKey) {
        localShift.attributes.absenceCalculation = '';
        localShift.attributes.hoursWorth = 0;
        localShift.attributes.dayAbsence = false;
      }

      const isAbsence = !!localShift.relationships.poste.attributes.absenceKey;

      if (!isAbsence || localShift.attributes.absenceCalculation === 'hours') {
        localShift.attributes.startsAt = newStart;
        localShift.attributes.endsAt = this.getShiftEndsAt(newEnd);
        if (this.globalConfig.currentShop.attributes.maxHoursWithoutPause > skDate(localShift.attributes.endsAt).diff(skDate(localShift.attributes.startsAt), 'hours')) {
          localShift.attributes.pauseTime = 0;
        }
      }

      localShift.attributes.delay = 0;
      localShift.attributes.previsionalStart = null;
      localShift.attributes.previsionalEnd = null;
      localShift.attributes.previsionalSaved = null;
      localShift.attributes.previsionalPosteId = null;
      localShift.attributes.userId = userId;
      localShift.attributes.updatedAt = skDate().utc().format();
      sanitizeShift(localShift, true, { oldUser: user, newUser: this.rowItem }, this.globalConfig);

      this.updateShift({
        shiftId: localShift.id,
        shifts: [localShift],
        shopId,
        periodStartsAt: this.globalConfig.monday,
        periodEndsAt: this.globalConfig.sunday,
        isFromDragAndDrop: true,
      })
        .catch(error => {
          let message;
          if (error.response) {
            message = error.response.data.message === this.$t('plannings.table.cells.error.backend') ?
              this.$t('plannings.table.cells.error.no_postes') :
              error.response.data.message;
          } else {
            message = this.$t('errors.standard_message');
          }
          this.$skToast({
            message,
            variant: 'error',
          });
        })
        .finally(() => { this.draggedShiftId = null; });
    },
    getShiftClasses(shiftId) {
      return {
        'planning-row__days__wrapper__shift': !this.isCurrentDayDisabled,
        'planning-row__days__wrapper__shift--hide': shiftId === this.draggedShiftId,
      };
    },
    showMenu(shift) {
      if (
        this.isCurrentDayDisabled ||
        this.isShiftFromAnotherShop(shift) ||
        this.isResizingShift
      ) return;

      // Calculate shift menu position, to be placed at the bottom right of the shift
      const shiftElRect = this.$refs[`shift_${shift.id}`][0].$el.getBoundingClientRect();
      const menuPositionLeft = shiftElRect.left + shiftElRect.width - 10; // offset the menu from the alerts (design validated)
      const menuPositionTop = shiftElRect.top + shiftElRect.height;

      // 146 matches total column which is fixed
      // 5 matches half trash icon width - Design validated
      if (menuPositionLeft > (window.innerWidth - 146 - 5)) return;

      this.emitOnRoot('show-shift-menu', {
        shiftId: shift.id,
        isShiftFromAnotherShop: false, // Always false because of early return
        isUnassignedShiftsRow: this.isUnassignedShiftsRow,
        top: menuPositionTop,
        left: menuPositionLeft,
      });
    },
    hideMenu() {
      this.emitOnRoot('hide-shift-menu');
    },
    handleShiftDragStart(shift, event) {
      if (this.isCurrentDayDisabled || this.isShiftFromAnotherShop(shift)) return;

      this.removePointerEventsFromShifts(event.target);
      this.setShiftDragging(true);
      this.draggedShiftId = shift.id;
      this.draggedShiftElement = event.target;

      const shiftLeftPosition = event.target.getBoundingClientRect().x;
      const innerShiftToMouseSpacing = event.clientX - shiftLeftPosition;
      const { pixelPerMinute } = this.globalConfig.dayViewPlanningSizeVariables;

      event.dataTransfer.clearData();
      event.dataTransfer.setData(getDragDataFormat('shift'), JSON.stringify({
        shift,
        user: this.rowItem,
        position: innerShiftToMouseSpacing / pixelPerMinute,
      }));

      this.hideMenu();
      this.disableCurrentShiftPointerEvents(event.target);
    },
    handleShiftDragEnd() {
      if (this.isCurrentDayDisabled) return;
      this.resetShiftDragState();
    },
    handleShiftDragCancel() {
      this.resetShiftDragState();
    },
    disableCurrentShiftPointerEvents(element) {
      setTimeout(() => {
        element.style.pointerEvents = 'none';
        element.style.opacity = '0.3';
      }, 0);
    },
    enableCurrentShiftPointerEvents(element) {
      element.style.pointerEvents = '';
      element.style.opacity = '';
    },
    resetShiftDragState() {
      if (this.draggedShiftElement) {
        this.enableCurrentShiftPointerEvents(this.draggedShiftElement);
      }
      this.reAddPointerEventsToShifts();
      this.preventDragAndCreateVisibility();
      this.setShiftDragging(false);
      this.draggedShiftId = null;
      this.draggedShiftElement = null;
    },
    // We want to be able to drop the dragged shift on top of the other shifts (to the cells below)
    // But since the schedule shifts have pointer-events, they "block" the cells.
    // Removing pointer-events during drag, allows the dragged shift to be dropped "through" the schedule shifts
    removePointerEventsFromShifts(currentlyDraggingShift) {
      currentlyDraggingShift.classList.add('planning-row__day-shift-wrapper--currently-dragging');

      Array.from(document.getElementsByClassName('planning-row__day-shift-wrapper')).forEach(element => {
        element.classList.add('planning-row__day-shift-wrapper--drag-started');
      });
    },
    reAddPointerEventsToShifts() {
      Array.from(document.getElementsByClassName('planning-row__day-shift-wrapper--currently-dragging')).forEach(element => {
        element.classList.remove('planning-row__day-shift-wrapper--currently-dragging');
      });

      Array.from(document.getElementsByClassName('planning-row__day-shift-wrapper')).forEach(element => {
        element.classList.remove('planning-row__day-shift-wrapper--drag-started');
      });
    },
    isShiftFromAnotherShop(shift) {
      return (
        shift.attributes.shopId !== parseInt(this.globalConfig.currentShop.id, 10)
      );
    },
    closeAlerts() {
      this.emitOnRoot('hide-alerts-global');
    },
    openShiftModal(shift) {
      if (
        this.isResizingShift ||
        this.isShiftFromAnotherShop(shift)
      ) return;
      this.closeAlerts();

      if (!this.areShiftsEditable) {
        this.emitOnRoot('init-read-only-shift-modal', null, { shift, isUnassignedShift: this.isUnassignedShiftsRow });
        return;
      }

      this.emitOnRoot(
        'manageShiftModal',
        event,
        {
          shift,
          rowItem: this.rowItem,
          isUnassignedShift: this.isUnassignedShiftsRow,
          date: skDate(this.globalConfig.currentDate).utc(true),
          availabilities: this.currentDayAvailabilities,
        },
      );
    },
    getShiftEndsAt(endsAt) {
      const endTime = skDate.utc(endsAt);
      const { closingTime } = this.openingAndClosingTime;

      // If shift end is after shop closing time crop it to closing time
      if (this.globalConfig.is24hShop || closingTime.isSameOrAfter(endTime)) return endsAt;
      return closingTime.format();
    },
    onResizeStop(shift, { left, right }) {
      setTimeout(() => { this.isResizingShift = false; }, 1); // Avoid triggering shift modal
      const { startsAt, endsAt } = getValidShiftTimes(
        { startsAt: shift.attributes.startsAt, endsAt: shift.attributes.endsAt },
        this.globalConfig.currentShop,
        this.globalConfig.currentDate,
      );
      const { pixelPerMinute } = this.globalConfig.dayViewPlanningSizeVariables;

      const shopId = parseInt(shift.attributes.shopId, 10);
      const startOffsetInMinutes = left / pixelPerMinute;
      const endOffsetInMinutes = right / pixelPerMinute;
      const start = skDate.utc(startsAt).add(startOffsetInMinutes, 'm');
      const end = skDate.utc(endsAt).add(endOffsetInMinutes, 'm');
      const updatedShift = cloneDeep(shift);
      updatedShift.attributes.startsAt = this.getShiftStartsAt(start.format());
      updatedShift.attributes.endsAt = this.getShiftEndsAt(end.format());
      sanitizeShift(updatedShift);
      this.updateShift({
        shiftId: updatedShift.id,
        shifts: [updatedShift],
        shopId,
        periodStartsAt: this.globalConfig.monday,
        periodEndsAt: this.globalConfig.sunday,
      })
        .catch(error => {
          let message;
          if (error.response) {
            message = error.response.data.message === this.$t('plannings.table.cells.error.backend') ?
              this.$t('plannings.table.cells.error.no_postes') :
              error.response.data.message;
          } else {
            message = this.$t('errors.standard_message');
          }
          this.$skToast({
            message,
            variant: 'error',
          });
        });
    },
    getShiftStartsAt(startsAt) {
      const startTime = skDate.utc(startsAt);
      const { openingTime } = this.openingAndClosingTime;

      // If shift start is before shop opening time crop it to opening time
      if (openingTime.isSameOrBefore(startTime)) return startsAt;
      return openingTime.format();
    },
    isInsideShopHours(start, end) {
      if (this.globalConfig.is24hShop) return true;
      const { openingTime, closingTime } = this.openingAndClosingTime;

      const time = openingAndClosingTimeAt(
        start,
        end,
        skDate.utc(this.globalConfig.currentDate).format(),
      );

      const startsAfterClosing = time.openingTime.isSameOrAfter(closingTime);
      const endsBeforeOpening = time.closingTime.isSameOrBefore(openingTime);

      return !startsAfterClosing && !endsBeforeOpening;
    },
    dayAvailabilities({ date, weekDay }) {
      // Check if availability matches the date or is recurring and on the same week day
      // Ignore availabilities outside of shop hours
      return availabilitiesForDay(this.availabilities, { date, weekDay })
        .filter(({ attributes }) => (
          this.isInsideShopHours(attributes.startTime, attributes.endTime)
        ));
    },
  },
};
</script>
<style lang="scss" scoped>
.daily-view-row__wrapper {
  position: relative;
  overflow-x: hidden;
  scrollbar-width: none; // hide horizontal scrolling bar
  width: 100%;

  &::-webkit-scrollbar { // hide horizontal scrolling bar ( cross-broswer )
    display: none;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--scroll {
    overflow-x: scroll;
  }
}

.planning-row__days__wrapper {
  display: flex;
  min-width: 0; // allow the planning row to be smaller than its content

  &__shift {
    cursor: grab;

    &--hide {
      transition: transform .01s;
      opacity: 0.3;
    }
  }

  &--disabled {
    width: fit-content;
  }

  &--disabled-opacity {
    // if day view is locked, we apply opacity to all direct children
    & > div {
      opacity: .7;
    }
  }
}
</style>
