<template>
  <div class="sk-form-section">
    <h3 class="sk-form-section__title">
      {{ $t('shop_settings.tabs.rules.main_rules.title') }}
    </h3>
    <SkForm
      v-if="isFeatureEnabled(FEATURES.FEATURE_COLLECTIVE_AGREEMENT, currentShop.id, () => true)"
      :title="$t('shop_settings.tabs.rules.main_rules.convention')"
      split
    >
      <template>
        <div class="row sk-form__row">
          <SkSelectV2
            v-model="conventionName"
            :label="$t('shop_settings.tabs.rules.main_rules.convention_label')"
            :options="conventionNameOptions"
            :disabled="!canEditShopRulesAndAbsences"
            :search-placeholder="$t('labels.search')"
            :no-results-label="$t('search_bar.no_result')"
            class="shops-settings-rules__convention-dropdown"
            data-test="shops-settings-rules__convention-dropdown"
          >
            <template #selected-option="{ value }">
              <span v-tooltip="showConventionTooltip(value)">
                {{ truncateConventionName(value) }}
              </span>
            </template>
            <template #option="{ option }">
              <span v-tooltip="showConventionTooltip(option.id)">
                {{ truncateConventionName(option.id) }}
              </span>
            </template>
          </SkSelectV2>
        </div>
      </template>
    </SkForm>
    <SkForm split>
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.main_rules.work_hours.message') }}</label>
      </template>
      <template #title-icon>
        <span>
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('shop_settings.tabs.rules.main_rules.work_hours.tooltip')"
            width="18"
            height="18"
          />
        </span>
      </template>
      <div class="row sk-form__row">
        <div class="col-md-6 p-0">
          <SkInputGroup
            class="sk-form__row__time-picker"
            separator
          >
            <template #prepend>
              <SkTimePicker
                v-model="openingTime"
                readonly
                :disabled="!canEditShopRulesAndAbsences"
              />
            </template>
            <template>-</template>
            <template #append>
              <SkTimePicker
                v-model="closingTime"
                readonly
                :disabled="!canEditShopRulesAndAbsences"
              />
            </template>
          </SkInputGroup>
        </div>
      </div>
    </SkForm>
    <SkForm
      v-if="arePlanningAndBreaksFeaturesEnabled"
      split
    >
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.main_rules.pause_trigger.message') }}</label>
      </template>
      <template>
        <div class="row sk-form__row">
          <div class="col-md-2 p-0">
            <SkInputGroup
              :errored="erroredFloat"
              :error-message="errorMessage"
            >
              <SkTimePicker
                v-model="maxHoursWithoutPause"
                readonly
                center
                :disabled="!canEditShopRulesAndAbsences"
                :interval-in-minutes="15"
                separator="h"
                @input="validateCurrentShopAttributes"
              />
            </SkInputGroup>
          </div>
        </div>
      </template>
      <template #title-icon>
        <span>
          <CircledQuestionMarkIcon
            v-tooltip.top="pauseTriggerTooltip"
            width="18"
            height="18"
          />
        </span>
      </template>
    </SkForm>
    <SkForm
      v-if="arePlanningAndBreaksFeaturesEnabled"
      :last="!isPayrollFeatureEnabled && !showLegalWeeklyHours"
      split
    >
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.main_rules.pause_compensation.message') }}</label>
      </template>
      <template>
        <div class="row sk-form__row">
          <div class="col-3 pl-0">
            <SkSwitch
              v-model="pauseCompensationActivated"
              :disabled="!canEditShopRulesAndAbsences"
            />
          </div>
        </div>
      </template>
      <template #title-icon>
        <span>
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('shop_settings.tabs.rules.main_rules.pause_compensation.tooltip')"
            width="18"
            height="18"
          />
        </span>
      </template>
    </SkForm>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <PauseCompensationActivationModal ref="pauseCompensationActivationModal" />
    </MountingPortal>

    <SkForm
      v-if="showLegalWeeklyHours"
      split
      :last="!isPayrollFeatureEnabled && showLegalWeeklyHours"
    >
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.main_rules.legal_weekly_hours.title') }}</label>
      </template>
      <template>
        <div class="row sk-form__row">
          <div class="col-md-6 pl-0">
            <SkInputGroup
              :errored="hasLegalWeeklyHoursError"
              :error-message="$t('shop_settings.tabs.rules.main_rules.legal_weekly_hours.error_message')"
            >
              <SkInput
                v-model="legalWeeklyHours"
                :label="$t('shop_settings.tabs.rules.main_rules.legal_weekly_hours.placeholder')"
                type="number"
                min="0"
                @keyup="validateCurrentShopAttributes"
              />
              <template #append>
                {{ $t('shop_settings.tabs.rules.main_rules.legal_weekly_hours.append') }}
              </template>
            </SkInputGroup>
          </div>
        </div>
      </template>
    </SkForm>
    <SkForm
      v-if="isPayrollFeatureEnabled"
      :last="isPayrollFeatureEnabled"
      split
    >
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.main_rules.hourly_wage_rate.message') }}</label>
      </template>
      <template>
        <div class="row sk-form__row">
          <div class="col-md-2 p-0">
            <SkInputGroup
              :errored="erroredPercentage"
              :error-message="$t('shop_settings.tabs.rules.main_rules.hourly_wage_rate.error_message')"
            >
              <SkInput
                id="hourly-wage-rate-input"
                v-model="hourlyWageRate"
                :disabled="!canEditShopRulesAndAbsences"
                type="number"
                min="0"
                max="100"
                step="1"
                @keyup="validateCurrentShopHourlyWageRate"
              />
              <template #append>
                %
              </template>
            </SkInputGroup>
          </div>
        </div>
      </template>
      <template #title-icon>
        <CircledQuestionMarkIcon
          v-tooltip.top="$t(
            'shop_settings.tabs.rules.main_rules.hourly_wage_rate.tooltip'
          )"
          width="18"
          height="18"
        />
      </template>
    </SkForm>

    <MealRules
      v-if="isFeatureEnabled(FEATURES.FEATURE_MEALS, currentShop.id, () => true)"
      class="meal_rules__container"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { truncateString } from '@skello-utils/formatting/strings';
import { isPositiveFloat } from '@skello-utils/validators';

import { FEATURES } from '@app-js/shared/constants/features';
import MealRules from './MealRules';
import PauseCompensationActivationModal from './PauseCompensationActivationModal';

export default {
  name: 'MainRules',
  components: { PauseCompensationActivationModal, MealRules },
  data() {
    return {
      erroredFloat: false,
      confirmPauseCompensationLoading: false,
      erroredPercentage: false,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentShop', { convention: state => state.currentShop.relationships.convention }),
    ...mapState('config', ['config']),
    ...mapGetters('config', ['countryConventions']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences', 'canEditEmployeeInfo', 'isSystemAdmin']),
    ...mapGetters('features', ['isFeatureEnabled', 'areFeaturesEnabled']),
    pauseCompensationActivated: {
      get() {
        return !!this.currentShop.attributes.pauseCompensationStartsAt;
      },

      set(newValue) {
        if (newValue) {
          this.emitOnRoot(
            MODAL_SHOW_EVENT,
            event,
            this.$refs.pauseCompensationActivationModal.$el.id,
          );
        } else {
          this.$root.$emit('confirm', event, {
            title: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.pause_compensation.title'),
            description: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.pause_compensation.description'),
            actionText: this.$t('actions.continue'),
            onConfirmSuccess: this.deactivatePauseCompensation,
          });
        }
      },
    },

    conventionName: {
      get() {
        return this.convention.attributes.name;
      },

      set(newConventioName) {
        const newConvention = this.getConventionForName(newConventioName);
        this.setShopConvention({
          name: newConventioName,
          legibleName: newConvention.legible_name,
          baseSector: newConvention.sector,
        });
      },
    },

    openingTime: {
      get() {
        return skDate(this.currentShop.attributes.openingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.setShopAttributes({ openingTime: skDate(newValue).format('HH:mm') });
      },
    },

    closingTime: {
      get() {
        return skDate(this.currentShop.attributes.closingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.setShopAttributes({ closingTime: skDate(newValue).format('HH:mm') });
      },
    },
    maxHoursWithoutPause: {
      get() {
        return this.floatToStingDate(this.currentShop.attributes.maxHoursWithoutPause);
      },

      set(newValue) {
        this.setShopAttributes({ maxHoursWithoutPause: this.dateToFloat(newValue) });
      },
    },

    hourlyWageRate: {
      get() {
        return this.currentShop.attributes.hourlyWageRate;
      },

      set(newValue) {
        this.setShopAttributes({ hourlyWageRate: newValue });
      },
    },

    legalWeeklyHours: {
      get() {
        return this.currentShop.attributes.legalWeeklyHours;
      },

      set(newLegalWeeklyHours) {
        this.setShopAttributes({ legalWeeklyHours: newLegalWeeklyHours });
      },
    },

    hasLegalWeeklyHoursError() {
      return !this.currentShop.attributes.legalWeeklyHours;
    },

    conventionNameOptions() {
      return this.displayedConventions.map(convention => ({
        id: convention.name,
        text: convention.legible_name,
      }));
    },

    displayedConventions() {
      // The es_labour_law is filtered because from business side
      // only make sense if the shop has flexible convention enabled.
      // The option is keeped in the selector if the shop convention selection
      // independently of the flexible convention enabled or not.
      return this.countryConventions(
        this.currentShop.attributes.country,
        this.convention.attributes.name,
      ).filter(
        convention => (this.convention.attributes.flexible ||
          this.convention.attributes.name === 'es_labour_law' ||
          convention.name !== 'es_labour_law'
        ),
      );
    },

    canEditEmployeeDataSettings() {
      return this.canEditShopRulesAndAbsences && this.canEditEmployeeInfo;
    },
    arePlanningAndBreaksFeaturesEnabled() {
      return this.areFeaturesEnabled(
        [FEATURES.FEATURE_PLANNING, FEATURES.FEATURE_BREAKS], this.currentShop.id, () => true);
    },
    isPayrollFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PAYROLL, this.currentShop.id, () => true);
    },
    errorMessage() {
      if (!this.currentShop.attributes.maxHoursWithoutPause) {
        return this.$t('shop_settings.tabs.rules.main_rules.pause_trigger.error_message.empty_field');
      }
      if (this.currentShop.attributes.maxHoursWithoutPause < 0) {
        return this.$t('shop_settings.tabs.rules.main_rules.pause_trigger.error_message.negative_value');
      }
      if (isPositiveFloat(this.currentShop.attributes.maxHoursWithoutPause)) {
        return this.$t('shop_settings.tabs.rules.main_rules.pause_trigger.error_message.not_integer');
      }

      return null;
    },
    pauseTriggerTooltip() {
      const tooltipState =
        this.currentShop.attributes.pauseCompensationStartsAt ? 'pause_paid' : 'pause_unpaid';
      return this.$t(`shop_settings.tabs.rules.main_rules.pause_trigger.tooltip.${tooltipState}`);
    },
    showLegalWeeklyHours() {
      return this.isFeatureEnabled(FEATURES.FEATURE_REPORT, this.currentShop.id, () => true) &&
        this.isSystemAdmin && this.checkFeatureFlag('ACCESS_LEGAL_WEEKLY_HOURS');
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes', 'setShopConvention', 'currentShopError']),
    dateToFloat(value) {
      const newValue = skDate(value);
      return newValue.hours() + (newValue.minutes() / 60);
    },
    floatToStingDate(value) {
      const hours = Math.trunc(value);
      const minutes = (value - Math.floor(value)).toFixed(2) * 60;

      return skDate(`${hours}.${minutes}`, 'HH:mm').format();
    },
    getConventionForName(name) {
      return this.displayedConventions.find(
        convention => convention.name === name,
      );
    },
    truncateConventionName(name) {
      return truncateString(this.getConventionForName(name).legible_name);
    },
    showConventionTooltip(name) {
      const conventionNameText = this.getConventionForName(name).legible_name;

      return conventionNameText.length > 50 ? conventionNameText : null;
    },
    validateCurrentShopAttributes() {
      this.erroredFloat = !isPositiveFloat(this.currentShop.attributes.maxHoursWithoutPause);
      this.currentShopError(this.erroredFloat || this.hasLegalWeeklyHoursError);
    },
    validateCurrentShopHourlyWageRate() {
      const hourlyWageRate = this.currentShop.attributes.hourlyWageRate;
      this.erroredPercentage = !isPositiveFloat(hourlyWageRate) || hourlyWageRate > 100;
      this.currentShopError(this.erroredPercentage);
    },
    deactivatePauseCompensation() {
      this.confirmPauseCompensationLoading = true;

      const params = {
        pause_compensation: {
          pause_compensation_starts_at: null,
        },
      };

      return httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}/pause_compensation`, params)
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_PAID_BREAK_DISABLED, {
            pauseCompensationStartsAt: params.pause_compensation.pause_compensation_starts_at,
          });
          this.setShopAttributes({ pauseCompensationStartsAt: null });
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.pause_compensation.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.confirmPauseCompensationLoading = false;
        });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shops-settings-rules__convention-dropdown {
  padding-right: 15px;
}

.meal_rules__container {
  padding: 40px 0;
}

.sk-form__row__time-picker {
  ::v-deep .sk-input__input {
    text-align: center;
  }
}

#hourly-wage-rate-input {
  width: 150%;
}
</style>
