<template>
  <div id="main-body">
    <div
      id="main-content"
      class="container"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { loadLanguageAsync } from '@plugins/i18n';

export default {
  name: 'Static',
  mounted() {
    const navigatorLang = navigator.language || navigator.userLanguage;
    const urlParamsLang = new URLSearchParams(window.location.search).get('lang');
    const lang = urlParamsLang || navigatorLang.substring(0, 2);

    loadLanguageAsync(lang);
    moment.locale(lang);
  },
};
</script>

<style lang="scss">
#main-content {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}
</style>
