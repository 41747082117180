<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 35 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.283 31.4773C33.1368 32.3284 33.8142 33.3397 34.2764 34.4531C34.7385 35.5666 34.9765 36.7603 34.9765 37.9659C34.9765 39.1714 34.7385 40.3651 34.2764 41.4786C33.8142 42.592 33.1368 43.6033 32.283 44.4545L17.6438 59.0937C15.7985 60.9481 13.292 61.9936 10.6758 62.0001C8.05965 62.0065 5.54805 60.9734 3.69356 59.1281C1.83907 57.2827 0.793599 54.7763 0.787139 52.1601C0.78068 49.5439 1.81376 47.0323 3.65911 45.1778L24.8084 24.0371L32.283 31.4773Z"
      fill="#2e26f2"
    />
    <path
      d="M10.8756 10.0957L2.68628 18.2764C0.966215 19.9977 0 22.3316 0 24.765C0 27.1985 0.966215 29.5323 2.68628 31.2536L10.135 38.6938L31.2757 17.5617L24.7828 24.0546L10.8756 10.0957Z"
      fill="#459FFF"
    />
    <path
      d="M32.0253 2.87887C30.1755 1.03523 27.6704 0 25.0588 0C22.4471 0 19.942 1.03523 18.0922 2.87887L10.876 10.0951L24.809 24.0282L31.3019 17.5353L32.0253 16.8119C33.8689 14.9622 34.9042 12.457 34.9042 9.8454C34.9042 7.23377 33.8689 4.72863 32.0253 2.87887Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'SkMedallion',
  props: {
    width: {
      type: String,
      default: '35',
    },
    height: {
      type: String,
      default: '62',
    },
  },
};
</script>
