<template>
  <div class="annualization-details__row">
    <div
      v-if="type === 'overHours'"
      class="annualization-details__slice"
    >
      <span class="annualization-details__label">
        {{ $t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.slice.label',
              {
                cardinality,
              })
        }}
      </span>
      <span class="annualization-details__sublabel">
        <!-- eslint-disable max-len -->
        {{ $t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.slice.sublabel',
              {
                cardinality: cardinality.toLowerCase(),
              })
        }}
        <!-- eslint-enable max-len -->
      </span>
    </div>
    <div
      v-else
      class="annualization-details__slice"
    >
      <span class="annualization-details__label">
        <!-- eslint-disable max-len -->
        {{ $t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.slice.label',
              {
                cardinality,
              })
        }}
        <!-- eslint-enable max-len -->
      </span>
      <span class="annualization-details__sublabel">
        {{ complementaryHoursSublabel }}
      </span>
    </div>
    <SkInputGroup
      v-if="type === 'overHours'"
      class="annualization-details__over-hours-input--first"
      unit="h"
      separator
    >
      <template #prepend>
        <SkInput
          v-model="slice[0]"
          placeholder="0"
          type="number"
          min="0"
          :disabled="!canEditShopRulesAndAbsences || sliceNumber === 0"
        />
      </template>
      <template> {{ $t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.separator') }}</template>
      <template #append>
        <SkInput
          v-model="slice[1]"
          placeholder="0"
          type="number"
          min="0"
          :disabled="!canEditShopRulesAndAbsences"
        />
      </template>
    </SkInputGroup>
    <SkInputGroup
      v-else
      class="annualization-details__over-hours-input--first"
    >
      <SkInput
        v-model="slice[0]"
        placeholder="0"
        type="number"
        min="0"
        :disabled="!canEditShopRulesAndAbsences || sliceNumber === 1"
      />
      <template #append>
        %
      </template>
    </SkInputGroup>
    <span class="annualization-details__label--maj">
      {{ $t('shop_settings.tabs.details.annualization.over_hours_section.majoration') }}
    </span>
    <SkInputGroup class="annualization-details__over-hours-input--second">
      <SkInput
        v-if="type === 'overHours'"
        v-model="slice[2]"
        placeholder="0"
        type="number"
        min="0"
        :disabled="!canEditShopRulesAndAbsences"
      />
      <SkInput
        v-else
        v-model="localComplementaryHoursMajoration"
        placeholder="0"
        type="number"
        min="0"
        :disabled="!canEditShopRulesAndAbsences"
      />
      <template #append>
        %
      </template>
    </SkInputGroup>
    <span v-if="sliceNumber > 0 && type === 'overHours'">
      <SkCircleButton
        icon="TrashCanV2Icon"
        icon-color="#d03e50"
        @click="$emit('handle-delete-slice')"
      />
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OverHoursSliceRow',
  props: {
    sliceNumber: {
      type: Number,
      required: true,
    },
    slice: {
      type: Array,
      required: true,
    },
    complementaryHoursMajoration: {
      type: [Number, String],
      default: 0,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    cardinality() {
      return this.sliceNumberToCardinality(this.sliceNumber);
    },
    previousCardinality() {
      return this.sliceNumberToCardinality(this.sliceNumber - 1);
    },
    complementaryHoursSublabel() {
      if (this.sliceNumber === 0) {
        return this.$t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.slice.first_sublabel',
          {
            cardinality: this.cardinality.toLowerCase(),
          });
      }
      return this.$t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.slice.next_sublabel',
        {
          cardinality: this.cardinality.toLowerCase(),
          previousCardinality: this.previousCardinality.toLowerCase(),
        });
    },
    localComplementaryHoursMajoration: {
      get() {
        return this.complementaryHoursMajoration;
      },
      set(newMajoration) {
        this.$emit('update-complementary-hours-majoration', newMajoration);
      },
    },
  },
  methods: {
    sliceNumberToCardinality(sliceNumber) {
      switch (sliceNumber) {
        case 0:
          return this.$t('shop_settings.tabs.details.annualization.over_hours_section.cardinality.first');
        case 1:
          return this.$t('shop_settings.tabs.details.annualization.over_hours_section.cardinality.second');
        case 2:
          return this.$t('shop_settings.tabs.details.annualization.over_hours_section.cardinality.third');
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-details__slice {
  width: 360px;
  overflow-wrap: break-word;
}

.annualization-details__label {
  display: flex;
  align-items: center;

  &--maj {
    width: 135px;
  }
}

.annualization-details__sublabel {
  color: $sk-grey-50;
}

.annualization-details__over-hours-input {
  &--first {
    width: 190px;
  }

  &--second {
    width: 78px;
  }
}
</style>
