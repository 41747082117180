<template>
  <div class="workload-plan-bottom-panel__hour-hours__container">
    <div
      v-for="(hour, index) in hours"
      :key="index"
      class="workload-plan-bottom-panel__hour-hours__cell"
      :style="{width: `${dayViewPlanningSizeVariables.pixelPerQuarterHours * 4}px`}"
    >
      <span class="workload-plan-bottom-panel__hour-hours__cell--value">
        {{ hour }}h
      </span>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'Quarters',
  computed: {
    ...mapGetters('planningsWorkloadPlans', ['arrayOfOpeningQuarters']),
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),

    hours() {
      return [...new Set(this.arrayOfOpeningQuarters.map(hour => skDate.utc(hour).format('HH')))];
    },
  },
};
</script>

<style scoped lang="scss">
.workload-plan-bottom-panel__hour-hours {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
  }

  &__cell {
    &--value {
      font-size: $fs-text-m;
      font-weight: $fw-semi-bold;
    }
  }
}
</style>
