<template>
  <div class="employee__navigation__wrapper">
    <SkNav>
      <SkNavItem :to="{ name: 'user_personal', params: { shop_id: currentShop.id } }">
        {{ $t('employees.navigation_items.personal_information') }}
        <div
          v-if="displayMissingInformationNotification('user_personal')"
          class="employee__navigation__nav-item__notification"
        />
      </SkNavItem>
      <SkNavItem
        :disabled="!canAccessSensitiveTabs"
        :to="{ name: 'user_contracts', params: { shop_id: currentShop.id } }"
      >
        {{ $t('employees.navigation_items.contract_and_wage_data') }}
        <div
          v-if="displayMissingInformationNotification('user_contracts')"
          class="employee__navigation__nav-item__notification"
        />
      </SkNavItem>

      <SkNavItem
        :disabled="!canAccessSensitiveTabs"
        :to="{ name: 'user_hr_file', params: { shop_id: currentShop.id } }"
      >
        {{ $t('employees.navigation_items.hr_folder') }}
        <div
          v-if="displayMissingInformationNotification('user_hr_file')"
          class="employee__navigation__nav-item__notification"
        />
      </SkNavItem>
      <SkNavItem
        v-if="trackersActive || historyActive"
        :disabled="!canAccessCountersTab"
        :to="{ name: 'user_counters', params: { shop_id: currentShop.id } }"
        data-test="employee-counters-tab"
      >
        {{ historyAndCountersTabLabel }}
      </SkNavItem>
      <SkNavItem
        v-tooltip="documentTabTooltip"
        :disabled="!canAccessDocumentsTab"
        :to="{ name: 'user_documents', params: { shop_id: currentShop.id } }"
        @click.native="resetCurrentFolder"
      >
        {{ documentTabLabel }}
      </SkNavItem>
    </SkNav>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';

export default {
  name: 'Navigation',
  mixins: [payrollPreparationMixins],
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('selectedEmployee', { selectedEmployee: 'employee' }),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', ['canReadEmployeeInfo', 'canManageEmployeeDocuments']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    ...mapGetters('selectedEmployee', ['isCurrentUser', 'isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    ...mapGetters('features', ['isFeatureEnabled']),
    canAccessSensitiveTabs() {
      if (this.isCurrentUserOrSystemAdmin) return true;

      return this.isStrictSubordinateOfCurrentUser && this.canReadEmployeeInfo;
    },
    canAccessCountersTab() {
      return this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser;
    },
    isSvcDocumentInMaintenance() {
      return this.checkFeatureFlag('MAINTENANCE_SVC_DOCUMENTS');
    },
    canAccessDocumentsTab() {
      if (this.isSvcDocumentInMaintenance) return false;
      if (this.isCurrentUserOrSystemAdmin) return true;

      return this.isStrictSubordinateOfCurrentUser &&
        this.canReadEmployeeInfo && this.canManageEmployeeDocuments;
    },
    documentTabLabel() {
      if (this.isSvcDocumentInMaintenance) return this.$t('employees.navigation_items.document_in_maintenance');
      return this.$t('employees.navigation_items.documents');
    },
    documentTabTooltip() {
      if (this.isSvcDocumentInMaintenance) {
        return this.$t('employees.tabs.documents.maintenance.description');
      }
      return '';
    },
    trackersActive() {
      return this.isFeatureEnabled(FEATURES.FEATURE_TRACKERS, this.currentShop.id, () => true);
    },
    historyActive() {
      return this.isFeatureEnabled(FEATURES.FEATURE_HISTORY, this.currentShop.id, () => true);
    },
    historyAndCountersTabLabel() {
      if (this.trackersActive && this.historyActive) {
        return this.$t('employees.navigation_items.history_and_counter');
      }

      if (this.trackersActive) {
        return this.$t('employees.navigation_items.counter');
      }

      if (this.historyActive) {
        return this.$t('employees.navigation_items.history');
      }

      return '';
    },
  },
  methods: {
    ...mapMutations('employeeDocuments', ['updateCurrentFolder']),
    resetCurrentFolder() {
      this.$router.push({ query: {} });
      this.updateCurrentFolder('');
    },
  },
};
</script>

<style lang="scss" scoped>
.employee__navigation__wrapper {
  padding-bottom: 38px;

  // Edge case, no hover effect for employees child routes
  ::v-deep .router-link-exact-active:hover,
    .sk-tab__item--active:hover:hover {
    color: $sk-black;
  }
}

.employee__navigation__nav-item__notification {
  height: 6px;
  width: 6px;
  background-color: $sk-info;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: -2px;
}
</style>
