<template>
  <SkOroraDialog
    id="payslips-dispatch-modal"
    ref="payslipsDispatchModal"
    :title="$t('employees.payslips_dispatch.title')"
    :cancel-button-label="$t('employees.payslips_dispatch.cancel')"
    :next-step-button-label="$t('employees.payslips_dispatch.next')"
    :submit-button-label="$tc('employees.payslips_dispatch.submit', payslipsMatchingAssociatedCount)"
    :previous-step-button-label="$t('employees.payslips_dispatch.back')"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="submitLoading || isPoolingAnalysis"
    :full-height="currentStep === 3"
    :landing-step="currentStep"
    show-progress-bar
    :show-progress-bar-label="false"
    :size="currentStep === 3 ? 'large' : 'medium'"
    :step-count="3"
    :testing-options="{ cancel: 'cancel_button', next: 'continueValidate_button' }"
    :on-update-step-async="setCurrentStep"
    @concealed="resetData"
    @submit.prevent="handleUnassociatedPayslips"
    @close="checkBeforeModalClose"
    @cancel="checkBeforeModalClose"
    @go-to-previous-step="handleGoingBack"
  >
    <template #body>
      <div class="payslips-dispatch-modal">
        <template v-if="currentStep === 1">
          <span class="sk-text-medium-regular">
            {{ $t('employees.payslips_dispatch.step_1.description', {shopName}) }}
          </span>
          <label
            class="payslips-dispatch-modal__period"
            @click.prevent
          >
            {{ $t('employees.payslips_dispatch.step_1.label_period') }}
            <SkDatePicker
              v-model="period"
              :lang="$i18n.locale"
              :placeholder="$t('employees.payslips_dispatch.step_1.placeholder_period')"
              range
            />
          </label>
        </template>
        <template v-if="currentStep === 2">
          <span class="sk-text-medium-regular">
            {{ $t('employees.payslips_dispatch.step_2.description') }}
          </span>
          <div class="payslips-dispatch-upload">
            <VueDropzone
              id="payslips-dispatch-upload__dropzone"
              ref="payslipsImport"
              :include-styling="false"
              :options="dropzoneOptions"
              use-custom-slot
              @vdropzone-file-added="handleAddedFile"
            >
              <div class="payslips-dispatch-upload__dropzone-content">
                <div class="payslips-dispatch-upload__dropzone-label">
                  <UploadFileV2Icon
                    fill="#2B66FE"
                    height="60"
                    width="60"
                  />
                  <p
                    class="payslips-dispatch-upload__dropzone-text
                    payslips-dispatch-upload__dropzone-text--colored"
                  >
                    {{ $t('employees.payslips_dispatch.step_2.dropzone.text') }}
                  </p>
                  <p
                    class="payslips-dispatch-upload__dropzone-text"
                  >
                    {{ $t('employees.payslips_dispatch.step_2.dropzone.accepted_format_single_page') }}
                  </p>
                </div>
              </div>
            </VueDropzone>
            <SkOroraLink
              :href="$t('cookie_consent.privacy_policy_link')"
              target="_blank"
              size="x-small"
              style="color: #B0B0B0"
            >
              <span class="payslips-dispatch-upload__know-more sk-text-x-small-semibold">
                {{ $t('employees.payslips_dispatch.step_2.know_more') }}
              </span>
            </SkOroraLink>
            <SkOroraTable
              v-if="filesToAnalyze.length > 0"
              class="payslips-dispatch-modal__files"
              actions-col
              :columns="filesTableColumns"
              :rows="fileRows"
              @delete-row="onDeleteFileRow"
            />
          </div>
        </template>
        <template v-if="currentStep === 3">
          <div class="payslips-dispatch-modal__matching">
            <div class="payslips-dispatch-modal__cards">
              <SkColumnInfoCard
                class="payslips-dispatch-modal__card"
                :columns="[infoCards[0]]"
                :variant="handleInfoCardVariant('light-blue')"
              />
              <SkColumnInfoCard
                class="payslips-dispatch-modal__card"
                :columns="[infoCards[1]]"
                :variant="handleInfoCardVariant('success')"
              />
              <SkColumnInfoCard
                class="payslips-dispatch-modal__card"
                :columns="[infoCards[2]]"
                :variant="handleInfoCardVariant('warning')"
              />
            </div>
            <SkOroraTable
              actions-col
              :columns="matchingTableColumns"
              :rows="handleDisplayedRows"
              @delete-row="onDeleteRow"
              @sort-status="sortByStatus"
              @update-row="onUpdateRow"
            />
          </div>
        </template>
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import vue2Dropzone from 'vue2-dropzone';
import JSZip from 'jszip';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
  websocketApiGatewayUrl,
  isProd,
} from '@config/env';
import {
  MODAL_HIDE_EVENT,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';
import {
  svcDocumentV2Client,
  svcIntelligenceClient,
  httpClient,
} from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import WebsocketClient from '@skello-utils/websocket_client/websocket_client';
import skDate from '@skello-utils/dates';
import {
  getNumberOfPages,
  isFileEncrypted,
} from '@skello-utils/file';
import {
  matchEmployeesToPayslips, matchingAnalysis, criteriaEnum,
} from './matching.service';
import { toValidationResponseDTO } from './validationResponseTransformer';

const MAX_DOCUMENT_SIZE = 10_000_000; // 10MB
const CUSTOM_TTL = 3_600_000; // 1 HOUR
const WEBSOCKET_TIMEOUT_MS = 60000; // 1 MINUTE

export default {
  name: 'PayslipsDispatchModal',
  components: {
    VueDropzone: vue2Dropzone,
  },
  data() {
    return {
      acceptedMimeTypes: [
        'application/pdf',
        'application/zip',
        'application/x-zip-compressed', // zip format for Windows
      ],
      currentStep: 1,
      dropzoneOptions: {
        autoProcessQueue: false,
        autoQueue: false,
        clickable: true,
        createImageThumbnails: false,
        headers: {
          'X-Request-With': 'XMLHttpRequest',
          Authorization: `Bearer ${authClient.authToken.token}`,
        },
        hiddenInputContainer: '.payslips-dispatch-upload',
        params: {},
        timeout: null,
        url: '/fake',
      },
      filesTableColumns: [
        {
          key: 'name',
          component: 'SkOroraInput',
          label: this.$t('employees.payslips_dispatch.step_2.headers.name'),
          sortable: false,
          editable: false,
          width: 356,
        },
        {
          key: 'displayedSize',
          component: 'SkOroraInput',
          label: this.$t('employees.payslips_dispatch.step_2.headers.size'),
          sortable: false,
          editable: false,
          width: 176,
        },
      ],
      filesToAnalyze: [],
      isPoolingAnalysis: false,
      submitLoading: false,
      websocketEvents: {},
      payslipsMatching: [],
      period: [
        skDate().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        skDate().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      ],
      sortedByStatus: true,
      canRename: false,
      uploadTimestamp: undefined,
      websocketClients: [],
      globalTimer: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('employees', ['employeePayslipsManagees']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    onlyDispatchPayslipToV2() {
      return this.isDevFlagEnabled('FEATUREDEV_DISPATCH_PAYSLIP_ONLY_V2');
    },
    matchingTableColumns() {
      return [
        {
          key: 'finalFileName',
          button: {
            icon: this.headerRenameButtonIcon,
            onClick: this.toggleCanRename,
            label: this.renameOrCancel,
            variant: 'secondary',
            size: 'small',
          },
          component: 'SkLinkCell',
          label: this.$t('employees.payslips_dispatch.step_3.headers.name'),
          componentProps: {
            isAnimated: true,
            target: '_blank',
          },
          sortable: false,
          editable: false,
          width: 282,
        },
        {
          key: 'totalPages',
          label: this.$t('employees.payslips_dispatch.step_3.headers.page'),
          component: 'SkOroraInput',
          sortable: false,
          editable: false,
          width: 80,
        },
        {
          key: 'status',
          label: this.$t('employees.payslips_dispatch.step_3.headers.status'),
          sortable: true,
          component: 'SkOroraTag',
          componentProps: {
            size: 'large',
          },
          variant: row => (row.userId ? 'green' : 'orange'),
          editable: false,
          width: 144,
        },
        {
          key: 'userId',
          label: this.$t('employees.payslips_dispatch.step_3.headers.employee'),
          sortable: false,
          component: 'SkOroraSelect',
          placeholder: this.$t('employees.payslips_dispatch.step_3.employee_placeholder'),
          options: this.employeeOptions,
          componentProps: {
            groupOptions: this.groupOptions,
            isMultiChoices: false,
            noSearchResultLabel: this.$t('employees.payslips_dispatch.step_3.no_search_result'),
          },
          editable: true,
          width: 282,
        },
      ];
    },
    fileRows() {
      return this.filesToAnalyze.map(file => ({
        id: file.upload.uuid,
        name: file.name,
        displayedSize: Math.round(file.size / 10000) / 100 + this.$t('employees.tabs.documents.create_modal.byte_unit'),
      }));
    },
    employeeOptions() {
      const employees = this.employeePayslipsManagees;
      const matchedPayslips = this.payslipsMatching.filter(payslip => payslip.userId);

      return employees.sort((a, b) => {
        if (a.attributes.lastName < b.attributes.lastName) {
          return -1;
        }
        if (a.attributes.lastName > b.attributes.lastName) {
          return 1;
        }
        return 0;
      }).sort((a, b) => {
        if (this.payslipsMatching.some(payslip => payslip.userId === a.id)) {
          return 1;
        }
        if (this.payslipsMatching.some(payslip => payslip.userId === b.id)) {
          return -1;
        }
        return 0;
      }).map(({ id, attributes }) => {
        const matched = matchedPayslips.some(payslip => payslip.userId === id);
        return {
          id,
          text: this.displayEmployeesName(attributes),
          matchKey: matched ? 'associated_key' : 'to_associate_key',
        };
      });
    },
    groupOptions() {
      return [
        { id: '1', text: this.$t('employees.payslips_dispatch.step_3.status.to_associate'), matchKey: 'to_associate_key', isCategory: true },
        { id: '2', text: this.$t('employees.payslips_dispatch.step_3.status.associated'), matchKey: 'associated_key', isCategory: true },
      ];
    },
    shopName() {
      return this.currentShop.attributes.name || this.currentOrganisation.attributes.name;
    },
    isSubmitDisabled() {
      if (this.currentStep === 1) {
        return this.period.some(date => !date);
      }
      // [PAYSLIPS_COMMENTS] Add specific test for this function
      if (this.currentStep === 2 && this.filesToAnalyze.length === 0) {
        return true;
      }

      if (this.currentStep === 3) {
        return !this.isPoolingAnalysis && !this.atLeastOnePayslipCompleted;
      }

      return false;
    },
    atLeastOnePayslipCompleted() {
      return this.payslipsMatching.some(({ userId }) => userId);
    },
    payslipsMatchingAssociatedCount() {
      return this.payslipsMatching.filter(({ userId }) => userId).length;
    },
    infoCards() {
      const payslipsLeftToAssociate =
        this.payslipsMatching.length - this.payslipsMatchingAssociatedCount;

      return [
        {
          title: this.$tc('employees.payslips_dispatch.step_3.cards.detected', this.payslipsMatching.length),
          content: `${this.payslipsMatching.length}`,
        },
        {
          title: this.$tc('employees.payslips_dispatch.step_3.cards.associated', this.payslipsMatchingAssociatedCount),
          content: `${this.payslipsMatchingAssociatedCount}`,
        },
        {
          title: this.$tc('employees.payslips_dispatch.step_3.cards.to_associate', payslipsLeftToAssociate),
          content: `${payslipsLeftToAssociate}`,
        },
      ];
    },
    matchingTableRow() {
      return this.payslipsMatching.map(payslip => ({
        ...payslip,
        totalPages: payslip.websocketEvent.length,
        status: this.$t(`employees.payslips_dispatch.step_3.status.${payslip?.userId ? 'associated' : 'to_associate'}`),
      }));
    },
    handleDisplayedRows() {
      const maxRows = this.calculateMaximumVisibleRows();
      const dynamicSize = this.isPoolingAnalysis ? maxRows - this.payslipsMatching.length : 0;
      const loadingRows = dynamicSize >= 0 ? Array(dynamicSize).fill({ isLoading: true }) : [];

      return [...this.matchingTableRow, ...loadingRows];
    },
    renameOrCancel() {
      return this.canRename ? this.$t('employees.payslips_dispatch.cancel') : this.$t('employees.payslips_dispatch.step_3.rename');
    },
    debugEnabled() {
      return this.$route.query.debug === 'true' && !isProd;
    },
    headerRenameButtonIcon() {
      return this.canRename ? 'BackArrowV2Icon' : 'SparklesIcon';
    },
  },
  beforeDestroy() {
    if (this.globalTimer) {
      clearTimeout(this.globalTimer);
    }
  },
  methods: {
    ...mapActions('employees', ['fetchEmployeePayslipsManagees']),
    async handleAddedFile(file) {
      const isValid = await this.isValidFile(file);
      this.$skAnalytics.track('payslips_dispatch_imported_files', {
        filetype: file.type,
        status: isValid ? 'success' : 'error',
      });

      if (isValid && (file.type === 'application/zip' || file.type === 'application/x-zip-compressed')) {
        let unzippedFiles = await this.unzipFile(file);
        const protectedFiles = [];
        await Promise.all(
          unzippedFiles.map(async unzippedFile => {
            const isProtected = await this.handleProtectedFile(unzippedFile);
            if (isProtected) {
              protectedFiles.push(unzippedFile);
            }
          }),
        );

        unzippedFiles = unzippedFiles.filter(f => !protectedFiles.includes(f));

        if (protectedFiles.length > 0) {
          this.$skToast({
            message: this.$t('employees.payslips_dispatch.step_2.dropzone.errors.encrypted'),
            variant: 'error',
          });
        }

        this.filesToAnalyze = [...this.filesToAnalyze, ...unzippedFiles];
      } else if (isValid) {
        this.filesToAnalyze.push(file);
      }
    },
    async unzipFile(file) {
      const unzippedFiles = [];
      const zipper = new JSZip();
      const zipFolder = await zipper.loadAsync(file);
      const promises = Object.keys(zipFolder.files).map(async filename => {
        const fileData = await zipFolder.files[filename].async('base64');

        // Comes from resource fork on macOS &&
        // This is a mimetype check for PDF - cf https://stackoverflow.com/a/58158656
        if (!filename.startsWith('__MACOSX') && fileData.startsWith('JVBERi0')) {
          const fileFromZipContent = new File(
            [Uint8Array.from(atob(fileData), m => m.codePointAt(0))],
            `${filename}`,
            { type: 'application/pdf' },
          );
          // Adding custom properties to keep it uniform with directly uploaded files
          const customProperties = { upload: { uuid: uuidv4() } };
          Object.assign(fileFromZipContent, customProperties);
          unzippedFiles.push(fileFromZipContent);
        }
      });

      await Promise.all(promises);
      return unzippedFiles;
    },
    async bulkUpdateDocumentV2() {
      this.submitLoading = true;

      const bulkPatchParams = this.payslipsMatching
        .filter(payslip => payslip.userId && payslip.newDocumentId)
        .map(payslip => ({
          id: payslip.newDocumentId,
          employeeId: payslip.userId,
          folderPath: '/pay_slips',
          fileName: this.canRename ? `${payslip.finalFileName.text}.pdf` : payslip.finalFileName.text,
          title: payslip.finalFileName.text,
          creatorId: this.currentUser.id,
        }));

      return svcDocumentV2Client.document.bulkUpdate(bulkPatchParams);
    },
    async bulkUpdateDocumentV1() {
      const promises = this.payslipsMatching
        .filter(payslip => payslip.userId && payslip.newDocumentId)
        .map(async payslip => {
          const buffer = await svcDocumentV2Client.document.download(payslip.newDocumentId);
          const uint8View = new Uint8Array(buffer);

          const file = new File(
            [uint8View],
            this.canRename ? `${payslip.finalFileName.text}.pdf` : payslip.finalFileName.text,
            { type: 'application/pdf' },
          );

          const formData = new FormData();

          formData.append('file', file);
          formData.append('document[title]', payslip.finalFileName.text);
          formData.append('document[folder]', 'pay_slips');
          formData.append('user_id', payslip.userId);

          await httpClient.post(`/v3/api/users/${payslip.userId}/documents`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        });

      return Promise.all(promises);
    },
    saveProcessData() {
      const allValidationResponsesDTO = this.payslipsMatching
        .filter(payslip => payslip.userId && payslip.newDocumentId)
        .map(payslip => toValidationResponseDTO(payslip, this.currentShop.id));

      svcIntelligenceClient.validationResponses(allValidationResponsesDTO);
    },
    async handleSubmit() {
      this.submitLoading = true;

      this.saveProcessData();

      try {
        // Currently, we dispatch payslips to both v1 and v2. However, v2 has a TTL of 5 minutes to trigger
        // email and push notifications. In the future, we will remove the v1 dispatch. Refer to the document v2
        // bulkPatch in documentManager within this file. To remove v1, enable the feature flag FEATUREDEV_DISPATCH_PAYSLIP_ONLY_V2
        // and set the environment variable FEATURE_DISPATCH_PAYSLIP_ONLY_V2 in svc-documents-v2.
        // For more details, please see https://skello.atlassian.net/browse/DEV-21392

        if (!this.onlyDispatchPayslipToV2) {
          await this.bulkUpdateDocumentV1();
        }

        // FIXME: In some cases, the authorizer fails to authorize the request to the document v2 service.
        // Here, the svcDocV2 client is used to send emails and push notifications to the employees.
        try {
          await this.bulkUpdateDocumentV2();
        } catch (e) {
          console.error('Error while dispatching payslips to v2', e);
        }

        this.$skAnalytics.track('payslips_dispatch_dispatched_payslips', {
          detected: `${this.payslipsMatching.length}`,
          matched: `${this.payslipsMatchingAssociatedCount}`,
          unmatched: `${this.payslipsMatching.length - this.payslipsMatchingAssociatedCount}`,
          period: this.period,
        });

        this.emitOnRoot(MODAL_HIDE_EVENT, null, 'payslips-dispatch-modal');

        this.$skToast({
          message: this.$tc('employees.payslips_dispatch.step_3.success', this.payslipsMatchingAssociatedCount),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      } finally {
        this.submitLoading = false;
      }
      this.$skAnalytics.track('payslips_dispatch_final_submit');
    },
    setCurrentStep(step) {
      if (this.currentStep === 1 && step === 2) {
        this.fetchEmployeePayslipsManagees({ clusterNodeId: this.navContext.clusterNodeId });
        this.$skAnalytics.track('payslips_dispatch_step1_validated_period');
      } else if (this.currentStep === 2 && step === 3) {
        this.resetData(step);
        this.uploadTimestamp = new Date();
        this.uploadFiles();
        this.$skAnalytics.track('payslips_dispatch_step2_uploaded_documents');
      } else if (this.currentStep === 3 && step === 2) {
        this.$refs.payslipsDispatchModal.currentStep = 2;
        this.resetData(step);
        this.$skAnalytics.track('payslips_dispatch_step3_go_back_step2');
      }
      this.currentStep = step;
    },
    initEventsReceived(docId, fileName) {
      this.websocketEvents[docId] = {
        numberOfEventsReceived: 0,
        originalFileName: fileName,
        totalPages: null,
        isListening: true,
      };
    },
    openAllWebsockets() {
      if (!websocketApiGatewayUrl) {
        console.error('Missing the websocket API Gateway URL');

        return;
      }

      Object.keys(this.websocketEvents)
        .forEach(originalDocumentId => this.openWebsocket(originalDocumentId));
    },
    openWebsocket(originalDocumentId) {
      const websocketClient = new WebsocketClient(`${websocketApiGatewayUrl}/?type=Generic&uuid=${originalDocumentId}`);
      this.websocketClients.push({ websocketClient, originalDocumentId });

      websocketClient.connect(event => {
        let data;
        try {
          data = JSON.parse(event.data).data;
          this.onMessageReceived(data, originalDocumentId);
          this.createTimer();
        } catch (e) {
          this.websocketEvents[originalDocumentId].numberOfEventsReceived += 1;
          console.error('An error occurred while fetching websocket', e);

          return;
        }

        this.websocketEvents[originalDocumentId].totalPages = data.totalPages;
        this.websocketEvents[originalDocumentId].numberOfEventsReceived += 1;

        if (
          this.websocketEvents[originalDocumentId].totalPages ===
          this.websocketEvents[originalDocumentId].numberOfEventsReceived
        ) {
          websocketClient.disconnect();
          this.websocketEvents[originalDocumentId].isListening = false;
          const areAllWebsocketsDisconnected = Object.keys(this.websocketEvents).map(
            docId => this.websocketEvents[docId].isListening).every(value => value === false);
          if (areAllWebsocketsDisconnected) {
            this.extractPages();
          }
        }
      });
    },
    async getNumberOfPagesByUrl(url) {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch the document');

      const arrayBuffer = await response.arrayBuffer();
      const uint8View = new Uint8Array(arrayBuffer);

      return getNumberOfPages(uint8View);
    },
    async extractPages() {
      const MAX_PAGES_PER_DOCUMENT = 5;

      try {
        this.payslipsMatching =
         await Promise.all(this.payslipsMatching.map(async payslip => {
           try {
             const pages = payslip.websocketEvent.map(({ currentPage }) => currentPage).sort();

             const [{ documentId: newDocumentId, url }] =
              await svcDocumentV2Client.document.extractPages([{
                documentId: payslip.originalDocumentId,
                from: Math.min(...pages),
                to: Math.max(...pages),
                source: 'SVC_INTELLIGENCE_EXTRACT_PAGES',
              }]);

             // security: don't upload the file if it has more than MAX_PAGES_PER_DOCUMENT pages
             const nbPages = await this.getNumberOfPagesByUrl(url);
             if (nbPages >= MAX_PAGES_PER_DOCUMENT) {
               // for now, no alerts and silently ignore the document
               console.warn(`[Security] Document has too many pages (${nbPages})`, { ...payslip });
               return payslip;
             }

             return {
               ...payslip,
               newDocumentId,
               finalFileName: {
                 ...payslip.finalFileName,
                 href: url,
               },
             };
           } catch (e) {
             console.error('Failed to extract pages', { ...payslip }, e);
             return payslip;
           }
         }));

        const lengthPerFiles =
          Object.values(
            Object.groupBy(this.payslipsMatching, ({ originalFileName }) => originalFileName),
          ).map(file => file.length);
        const isIndividual = lengthPerFiles.every(x => x === 1);
        const isGrouped = lengthPerFiles.every(x => x !== 1);

        let filetype = '';
        if (isIndividual) {
          filetype = 'individual';
        } else if (isGrouped) {
          filetype = 'grouped';
        } else {
          filetype = 'both';
        }

        this.$skAnalytics.track('payslips_dispatch_analysed_payslips', {
          filetype,
          detected: `${this.payslipsMatching.length}`,
          matched: `${this.payslipsMatchingAssociatedCount}`,
          unmatched: `${this.payslipsMatching.length - this.payslipsMatchingAssociatedCount}`,
          period: this.period,
          dni: this.payslipsMatching.filter(({ criteria }) => criteria === criteriaEnum.DNI).length,
          fullName: this.payslipsMatching.filter(
            ({ criteria }) => criteria === criteriaEnum.FIRSTNAME_AND_LASTNAME,
          ).length,
          fullAddress: this.payslipsMatching.filter(
            ({ criteria }) => criteria === criteriaEnum.CITY_AND_STREET,
          ).length,
          duration: (new Date().getTime() - this.uploadTimestamp.getTime()) / 1000,
        });
      } catch (error) {
        // temporary error handling
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      } finally {
        this.isPoolingAnalysis = false;
      }
    },
    async uploadFiles() {
      this.$refs.payslipsImport.processQueue(this.filesToAnalyze);
      this.isPoolingAnalysis = true;

      try {
        const dtos = this.filesToAnalyze.map(file => ({
          action: {
            toAnalyze: true,
            toSplit: true,
          },
          customTTL: CUSTOM_TTL,
          fileName: file.name,
          mimeType: file.type,
          // We must use employeeId here in order to provide api requirement
          // do not provide shopId since we patch with employeeId at the end
          employeeId: this.currentUser.id,
        }));
        const createDocumentsResponseDtos =
          await svcDocumentV2Client.document.createAndUpload(dtos, this.filesToAnalyze);
        createDocumentsResponseDtos.forEach(({ id, fileName }) => {
          this.initEventsReceived(id, fileName);
        });
        this.openAllWebsockets();
      } catch (error) {
        this.onUploadDocumentFail(error);
        this.uploadTimestamp = undefined;
        throw error;
      }
    },
    onUploadDocumentFail() {
      this.$refs.payslipsImport.removeAllFiles();
      this.$skToast({
        message: this.$t('employees.payslips_dispatch.step_2.dropzone.errors.upload'),
        variant: 'error',
      });
    },
    async isValidFile(file) {
      let errorMsg = null;
      if (!file?.type || !this.acceptedMimeTypes.includes(file.type)) {
        errorMsg = this.$t('employees.payslips_dispatch.step_2.dropzone.errors.format');
      }

      if (!errorMsg && file.size > MAX_DOCUMENT_SIZE) {
        errorMsg = this.$t('employees.payslips_dispatch.step_2.dropzone.errors.size');
      }

      if (!errorMsg && file.type === 'application/pdf') {
        const isEncrypted = await this.handleProtectedFile(file);
        errorMsg = isEncrypted ? this.$t('employees.payslips_dispatch.step_2.dropzone.errors.encrypted') : null;
      }

      if (errorMsg) {
        this.$skToast({
          message: errorMsg,
          variant: 'error',
        });
        this.$refs.payslipsImport.removeFile(file);
        return false;
      }
      return true;
    },
    addPayslip(originalDocumentId, matching, message, analysisOutput) {
      // here save more ?
      const matchings = matchingAnalysis({
        payslips: this.payslipsMatching,
        page: analysisOutput,
      });
      if (
        matchings.length === 1 &&
        // we don't want to match pages between different documents
        this.payslipsMatching[matchings[0]].originalDocumentId === originalDocumentId
      ) {
        this.payslipsMatching[matchings[0]].websocketEvent.push(message);
      } else {
        this.payslipsMatching = this.sortPayslipsMatching([...this.payslipsMatching, {
          id: Math.ceil(Math.random() * 1000000),
          originalDocumentId,
          dni: analysisOutput.dni,
          firstName: analysisOutput.firstName,
          lastName: analysisOutput.lastName,
          startDate: analysisOutput.startPayrollPeriod,
          endDate: analysisOutput.endPayrollPeriod,
          city: analysisOutput.city,
          postalCode: analysisOutput.postalCode,
          streetName: analysisOutput.streetName,
          streetNumber: analysisOutput.streetNumber,
          skelloUserIdMatch: matching?.userId, // only added for validation response : the original proposed user
          userId: matching?.userId, // this one will be the employee id matched
          criteria: matching?.criteria,
          websocketEvent: [message],
          originalFileName: this.websocketEvents[originalDocumentId].originalFileName,
          finalFileName: { text: this.websocketEvents[originalDocumentId].originalFileName, href: '' },
        }]);
      }
    },
    onMessageReceived(message, originalDocumentId) {
      let analysisOutput;
      let matching;

      // parse the analysis
      try {
        analysisOutput = JSON.parse(message.output);
        // here it is safe to save
      } catch (error) {
        console.error('Error while parsing the message', error);
        this.addPayslip(originalDocumentId, null, message, {});
        this.logDebug({
          ...message,
          error,
        });
      }
      if (!analysisOutput) return;

      // try to match an employee
      try {
        matching = matchEmployeesToPayslips({
          employees: this.employeePayslipsManagees,
          userToMatch: analysisOutput,
        });
        // llmResponse = all the analysisOutput in array with all document id
      } catch (error) {
        console.error('Error while matching', error);
        this.addPayslip(originalDocumentId, null, message, {});
        this.logDebug({
          ...analysisOutput,
          error,
        });
      }

      // add the matching to the table
      this.addPayslip(originalDocumentId, matching, message, analysisOutput);

      this.logDebug({
        ...analysisOutput,
        criteria: matching?.criteria,
        matchedEmployee: (
          matching?.userId ?
            this.employeePayslipsManagees.find(e => e.id === matching?.userId)?.attributes :
            undefined
        ),
      });
    },
    sortPayslipsMatching(payslipsMatching) {
      return [...payslipsMatching]
        // Sort by status
        .sort((a, b) => {
          if ((a.userId && b.userId) || (!a.userId && !b.userId)) {
            return 0;
          }
          const c = a.userId ? 1 : -1;
          return this.sortedByStatus ? c : -c;
        });
    },
    onDeleteFileRow(row) {
      this.filesToAnalyze = this.filesToAnalyze.filter(file => file.upload.uuid !== row.id);
    },
    onDeleteRow(row) {
      this.payslipsMatching = this.payslipsMatching.filter(
        payslip => payslip.id !== row.id,
      );
    },
    onUpdateRow(row) {
      this.payslipsMatching = this.payslipsMatching.map(payslip => {
        // set userId
        if (payslip.id === row.id) {
          payslip = {
            ...payslip,
            userId: row.userId,
          };
        }
        // rename files
        if (this.canRename) {
          payslip = this.renamePayslip(payslip);
        }

        return payslip;
      });
    },
    sortByStatus() {
      this.sortedByStatus = !this.sortedByStatus;
      this.payslipsMatching = this.sortPayslipsMatching(this.payslipsMatching);
    },
    renameAllPayslips() {
      this.payslipsMatching = this.payslipsMatching.map(payslip => this.renamePayslip(payslip));
    },
    renamePayslip(payslip) {
      const payslipTranslation = this.$t('employees.payslips_dispatch.step_3.payslip_translation').toLowerCase();
      const employee = this.employeePayslipsManagees.find(e => e.id === payslip.userId);
      const [start, end] = this.period;

      payslip = {
        ...payslip,
        ...(payslip.userId && this.canRename ?
          { finalFileName: { text: `${start}-${end}_${employee?.attributes.firstName}-${employee?.attributes.lastName}_${payslipTranslation}`, href: payslip.finalFileName.href } } :
          { finalFileName: { text: payslip.originalFileName, href: payslip.finalFileName.href } }
        ),
      };

      return payslip;
    },
    toggleCanRename() {
      if (this.canRename) {
        this.$skAnalytics.track('payslips_dispatch_step3_cancel_rename_files');
      } else {
        this.$skAnalytics.track('payslips_dispatch_step3_rename_files');
      }
      this.canRename = !this.canRename;
      this.renameAllPayslips();
    },
    resetData(step) {
      if ((this.currentStep === 2 && step === 3) || (this.currentStep === 3 && step === 2)) {
        this.payslipsMatching = [];
        this.canRename = false;
        this.websocketEvents = {};
        this.debug = [];
        this.globalTimer = null;
      }
      if (!step) {
        Object.assign(this.$data, this.$options.data.call(this));
      }
    },
    displayEmployeesName(userAttributes) {
      return `${userAttributes.firstName} ${userAttributes.lastName}`;
    },
    handleInfoCardVariant(variant) {
      if (this.isPoolingAnalysis) {
        return 'loading';
      }
      return variant;
    },
    calculateMaximumVisibleRows() {
      const modalHeight = document.getElementById('payslips-dispatch-modal').clientHeight;
      const maxHeight = modalHeight - 433;
      const maxVisibleRowsNumber = Math.trunc(maxHeight / 44);
      return maxVisibleRowsNumber;
    },
    logDebug(data) {
      // do not throw for debug purpose ...
      try {
        if (this.debugEnabled) {
          // eslint-disable-next-line no-console
          console.info(data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    checkBeforeModalClose(event) {
      if (this.currentStep === 3) {
        event.preventDefault();
        this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: this.$refs.payslipsDispatchModal.hide,
          submitColor: this.$skColors.skBlue50,
          title: this.$t('actions.continue'),
        });
      }
    },
    handleGoingBack(event) {
      if (this.currentStep === 3 && this.isPoolingAnalysis) {
        event.preventDefault();
        this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
          title: this.$t('actions.continue'),
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: () => {
            this.setCurrentStep(2);
            this.disconnectAllWebsockets();
            this.isPoolingAnalysis = false;
          },
          submitColor: this.$skColors.skBlue50,
        });
      }
    },
    disconnectAllWebsockets() {
      this.websocketClients.forEach(({ websocketClient }) => websocketClient.disconnect());
    },
    createTimer() {
      if (this.globalTimer) {
        clearTimeout(this.globalTimer);
      }
      this.globalTimer = setTimeout(() => {
        if (this.isPoolingAnalysis) {
          this.disconnectAllWebsockets();
          this.extractPages();
        }
      }, WEBSOCKET_TIMEOUT_MS);
    },
    handleUnassociatedPayslips() {
      let title;
      let description;
      const associatedPayslipCount = this.payslipsMatchingAssociatedCount;
      const numberOfPayslipsToAssociate =
        this.payslipsMatching.length - associatedPayslipCount;

      if (numberOfPayslipsToAssociate > 0) {
        title = this.$tc('employees.payslips_dispatch.warning.action', numberOfPayslipsToAssociate > 1, { nombre: numberOfPayslipsToAssociate });
        description = this.$t('employees.payslips_dispatch.warning.remaining_unassociated_payslips');
      } else {
        title = this.$tc('employees.payslips_dispatch.information.action', associatedPayslipCount > 1, { nombre: associatedPayslipCount });
        description = this.$t('employees.payslips_dispatch.information.associated_payslips');
      }
      this.openConfirmationBeforeDispatchingPayslips(title, description);
    },
    async handleProtectedFile(file) {
      const arrayBuffer = await file.arrayBuffer();
      const uint8View = new Uint8Array(arrayBuffer);
      return isFileEncrypted(uint8View);
    },
    openConfirmationBeforeDispatchingPayslips(title, description) {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        title,
        description,
        onConfirm: () => {
          this.handleSubmit();
        },
        submitColor: this.$skColors.skBlue50,
      });
    },
  },
};

</script>
<!-- unscoped style to override dropzone classes -->
<style lang="scss">
// using the dropzone id to not potentially affect other dropzones
.payslips-dispatch-upload {
  height: calc(80% - 120px);
  min-height: 174px;
  .dz-preview,
  .dz-file-preview {
    display: none;
  }
  .dz-message {
    height: 100%;
  }
}
</style>
<style scoped lang="scss">

.payslips-dispatch-modal {
  color: $sk-black;
  display: flex;
  padding: 24px;
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  &__cards {
    display: flex;
    gap: 16px;

    & > div {
      min-width: 0;
      width: 100%;
    }
  }

  &__matching {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  &__matching-header {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &__matching-cell {
    color: $sk-black;
    padding-left: 16px;
    width: 100%;

    ::v-deep .sk-orora-select {
      border-color: transparent;
    }
  }

  &__period {
    align-items: center;
    display: flex;
    font-weight: $fw-semi-bold;
    justify-content: space-between;
    width: 100%;

    & > .sk-datepicker__wrapper {
      width: 304px;
    }
  }
}

.payslips-dispatch-upload {
  width: 100%;

  &__dropzone-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    // Handling the dashed border to be able to change the length of it
    background-position:  0 0, 0 0, 100% 0, 0 100%;
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-repeat: no-repeat;
    background-image:
          repeating-linear-gradient(
            0deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
            ), // left
          repeating-linear-gradient(
            90deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
            ), // top
          repeating-linear-gradient(
            180deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
            ), // right
          repeating-linear-gradient(
            270deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
            ) // bottom
      ;
  }

  &__dropzone-label {
    display: flex;
    width: 780px;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    margin: 32px 0;
  }

  &__dropzone-text {
    color: $sk-grey-50;
    font-family: Gellix;
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    line-height: normal;
    margin-bottom: 0;

    &--colored {
      color: $sk-blue-50;
      font-weight: $fw-semi-bold;
      font-size: $fs-text-l;
    }
  }

  &__know-more {
    color: $sk-grey-30;
  }

  .payslips-dispatch-modal__files {
    padding-top: 24px;
  }
}

</style>
