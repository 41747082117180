<template>
  <!-- eslint-disable  max-len -->
  <div class="clusters-tree-toolbar">
    <h2 class="sk-header--2">
      {{ organisationName }}
    </h2>
    <div class="toolbar_action_buttons_container">
      <ToolbarActionButtons
        :action-button-class="actionButtonClass"
        :toggle-rename-modal="toggleRenameModal"
        :toggle-move-modal="toggleMoveModal"
        :toggle-delete-modal="toggleDeleteModal"
        :delete-button-class="deleteButtonClass"
      />
    </div>
    <ToolbarArrowButtons :arrow-button-class="arrowButtonClass" />
  </div>
</template>

<script>
import ToolbarActionButtons from '../../ui/ToolbarActionButtons';
import ToolbarArrowButtons from '../../ui/ToolbarArrowButtons';

export default {
  components: { ToolbarActionButtons, ToolbarArrowButtons },
  props: {
    currentCluster: {
      type: Object,
      default: null,
    },
    organisationName: {
      type: String,
      default: '',
    },
    cardSelected: {
      type: Boolean,
      required: true,
    },
    toggleRenameModal: {
      type: Function,
      required: true,
    },
    toggleMoveModal: {
      type: Function,
      required: true,
    },
    toggleDeleteModal: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    finderScrollable: {
      type: Boolean,
      required: true,
    },
    userCanDeleteCurrentCluster: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    arrowButtonClass() {
      return {
        'can-click': !this.loading && this.finderScrollable,
      };
    },
    actionButtonClass() {
      return {
        'action-enabled': this.cardSelected,
      };
    },
    deleteButtonClass() {
      return {
        'action-enabled': this.cardSelected && this.userCanDeleteCurrentCluster,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  margin-top: 30px;
  border-bottom: 1px solid #e8eaed;
}

.toolbar_action_buttons_container {
  width: 242px;
}
</style>
