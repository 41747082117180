<template>
  <SkOroraDialog
    id="rename-document-modal"
    ref="renameDocumentModal"
    size="small"
    :title="$t('employees.tabs.documents.rename_document.modal.title')"
    :submit-button-label="$t('employees.tabs.documents.rename_document.modal.validate')"
    :submit-disabled="isSubmitDisabled"
    :tracking-options="trackingOptions"
    :is-submit-loading="loadingUpdateDocument"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <div class="document-send-email-modal__subject">
        <SkOroraInput
          v-model="currentTitle"
          :label="$t('employees.tabs.documents.rename_document.modal.field.title.label')"
          :placeholder="$t('employees.tabs.documents.rename_document.modal.field.title.placeholder')"
        />
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'RenameDocumentModal',
  props: {
    documentTitle: {
      type: String,
      default: null,
    },
    documentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentTitle: '',
    };
  },
  computed: {
    ...mapGetters('employeeDocuments', ['isDocumentV2']),
    ...mapState('employeeDocuments', ['loadingUpdateDocument']),
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    trackingOptions() {
      return {
        submit: 'hris_rename_document_submit',
        cancel: 'hris_rename_document_cancel',
      };
    },
    isSubmitDisabled() {
      return this.loadingUpdateDocument || !this.currentTitle;
    },
  },
  methods: {
    ...mapActions('employeeDocuments', ['updateDocument', 'updateDocumentV2']),
    show() {
      this.$nextTick(() => {
        this.resetData();
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'rename-document-modal');
      });
    },
    resetData() {
      this.currentTitle = this.documentTitle;
    },
    async handleSubmit(_event) {
      if (this.loadingUpdateDocument) return;

      const successMsg = this.$t('employees.tabs.documents.rename_document.modal.submit.success');
      const errorMsg = this.$t('employees.tabs.documents.rename_document.modal.submit.error');

      const newTitle = this.currentTitle.trim();

      try {
        if (this.isDocumentV2(this.documentId)) {
          await this.handleRenameV2(newTitle);
        } else {
          await this.handleRename(newTitle);
        }

        this.$skToast({
          message: successMsg,
          variant: 'success',
          dimension: 'short',
          containerId: 'employees__container',
        });
      } catch (error) {
        this.$skToast({
          message: errorMsg,
          variant: 'error',
          dimension: 'short',
          containerId: 'employees__container',
        });
      } finally {
        this.$refs.renameDocumentModal.hide();
      }
    },
    handleRename(title) {
      return this.updateDocument({
        employeeId: this.selectedEmployee.id,
        documentId: this.documentId,
        title,
      });
    },
    handleRenameV2(title) {
      return this.updateDocumentV2({
        documentId: this.documentId,
        title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.document-send-email-modal__subject {
  padding: 8px 24px;
}
</style>
