export const ANALYTICS_KEYS = {
  abort_duplicate_planning: 'Abort duplicate from template', // Removed in DEV-13725
  abort_shift_creation: 'Abort shift creation', // Removed in DEV-13725
  absence_details_public_holidays_recovery: 'Viewed details absence public holidays recovery',
  absence_disable: 'Disable absence',
  absence_enable: 'Enable absence',
  absence_owed_meal_enable: 'Click on meal check box',
  absence_search: 'Searched an absence (absence_sidebar_absences_tab)',
  absence_shift_created: 'Planning, Modal: "Creer une absence"', // Removed in DEV-13725
  absence_side_bar_hide: 'Closed absence sidebar',
  absence_side_bar_search: 'Searched an absence (absence_sidebar)',
  absence_side_bar_show: 'Triggered absence sidebar',
  absence_unassigned_shift_created: 'Planning, Modal: "Creer une absence non assigné"',
  absences_hours_recalculation_cancelled: 'Canceled hours recalculation (absence categorization)',
  absences_hours_recalculation_validate: 'Saved hours recalculation (absence categorization)',
  absences_sidebar_close: 'Closed absence sidebar',
  absences_sidebar_search: 'Searched an absence (absence_sidebar)',
  absences_tab: 'Click on absences tab', // Removed in DEV-13725
  account_creation_billing_submit: 'Confirmed paiement',
  account_creation_go_to_skello: 'Click on start using Skello (account creation)',
  account_creation_organisation_submit: 'Created organisation (account creation)',
  account_creation_shop_submit: 'Created shop',
  activated_public_holidays_counter: 'Activated public holidays counter',
  activate_hour_tracker: 'Activate hour tracker',
  add_amendment_cancel: 'Canceled amendment addition',
  add_amendment_from_history: 'Clicked on add amendment btn from History amendment',
  add_contract_amendment: 'Contract hours change amendment - contract dropdown',
  add_contracted_hours_amendment_edition: 'Contract hours change amendment - edition',
  add_employee: 'Clicked on add employee btn',
  add_employee_cancel: 'Canceled add employee', // Removed in DEV-13725
  add_employee_cancel_shop: 'Canceled shop selected (add_employee)',
  add_employee_cancel_team: 'Canceled team selected (add_employee)',
  add_employee_close: 'Close add employee',
  add_employee_next: 'Clicked on next btn (add_employee)',
  add_employee_previous: 'Clicked on previous btn (add_employee)',
  add_employee_remove_shop: 'Removed shop access (add_employee)',
  add_employee_shop: 'Clicked on shop select (add_employee)', // Removed in DEV-13725
  add_employee_submit: 'Added employee',
  add_employee_team: 'Clicked on team select (add_employee)',
  add_employee_validate_shop: 'Validated shop selected (add_employee)',
  add_employee_validate_team: 'Validated team selected (add_employee)',
  add_shop_btn_click: 'Clicked on add a shop',
  added_workload_plan: 'Created staff needs',
  alert_deleted: 'Alert deleted',
  alert_disable: 'Enable Alert',
  alert_enable: 'Disable Alert',
  alert_or_notification_sent_to_the_user: 'Alert or notification sent to the user',
  alert_updated: 'Alert updated',
  alerts_tab: 'Click on Alerts tab',
  alerts_update: 'Saved trigger alert updates',
  analytics_dashboard_max_period_datepicker: 'Too long period on datepicker (analytics dashboard)',
  analytics_upsell: 'Admin system visited the dashboard tab on the upsell state',
  annualization_absences_param_cancelled: 'Cancel absences selection in the advanced parameters page',
  annualization_absences_param_clicked: 'Click on absences select in the advanced parameters page',
  annualization_absences_param_validated: 'Validate absences selection in the advanced parameters page',
  annualization_activate: 'Activate annualization feature',
  annualization_advanced_settings_edited: 'Click on the submit button of the confirmation modal when editing a setting',
  annualization_amendment_edition_annual_base: 'Edition of the annual reference',
  annualization_amendment_edition_prorated_base: 'Edition of the base to be done',
  annualization_amendment_permanent_selector: 'Clicks on permanent selector',
  annualization_amendment_temporary_selector: 'Clicks on temporary selector',
  annualization_cancel_init: 'Cancel init employee annualization',
  annualization_cancel_step1: 'Cancel annualization activation step 1',
  annualization_cancel_step2: 'Cancel annualization activation step 2',
  annualization_click_next_activation: 'Click on next on the annualization step 1',
  annualization_click_previous_activation: 'Click on previous on the step 2',
  annualization_click_tab: 'Click on annualization tab',
  annualization_create_contract_amendment: 'Annualisation contract hours change amendment - create',
  annualization_deactive: 'Confirm annualization disable',
  annualization_disable_switch: 'Disable annualization switch',
  annualization_display_advanced_parameters: 'Show annualization advanced parameters page',
  annualization_enable_switch: 'Enable annualization switch',
  annualization_open_report: 'Open annualisation report column',
  annualization_start_init: 'Click to init employee annualization',
  annualization_temporary_amendment_edition_base_after_period: 'Edition of the base to be done after the period',
  annualization_temporary_amendment_edition_base_during_period: 'Edition of the base to be done during the period',
  annualization_validate_init: 'Validate init employee annualization',
  archive_employee: 'Clicked on archive employee btn', // Removed in DEV-13725
  automatic_planning_aborted: '(SP) Aborted schedule creation',
  automatic_planning_back: '(SP) Clicked back to option page',
  automatic_planning_back_on_base_references: '(SP) Clicked back on base references',
  automatic_planning_back_to_planning: '(SP) Clicked back to Smart Planner',
  automatic_planning_button_click: '(SP) Clicked on Smart Planner',
  automatic_planning_change_employees: '(SP) Updated employee list on assignation',
  automatic_planning_change_postes: '(SP) Updated poste list on assignation',
  automatic_planning_close_results_modal: '(SP) Closed result confirmation modal',
  automatic_planning_close_sidebar: '(SP) Closed Smart Planner sidebar',
  automatic_planning_enable_ai_v1: '(SP) Enabled Smart Planner One Click Scheduler',
  automatic_planning_generate: '(SP) Launched Smart Planner',
  automatic_planning_popover_closed: '(SP) Closed Smart Planner One Click Scheduler',
  automatic_planning_popover_completed: '(SP) Smart Planner One Click Scheduler completed modal',
  automatic_planning_popover_deactivated: '(SP) Smart Planner One Click Scheduler deactivation modal',
  automatic_planning_popover_deleted: '(SP) Deleted Smart Planner One Click Scheduler shifts',
  automatic_planning_popover_error: '(SP) Error Smart Planner One Click Scheduler',
  automatic_planning_popover_keep_activated: '(SP) Kept Smart Planner One Click Scheduler',
  automatic_planning_popover_survey: '(SP) Smart Assistant Deletion Survey Displayed',
  automatic_planning_popover_survey_submitted: '(SP) Smart Assistant Deletion Survey submitted',
  automatic_planning_popover_validated: '(SP) Validated Smart Planner One Click Scheduler',
  automatic_planning_previous_week: '(SP) Chose previous week option',
  automatic_planning_set_up: '(SP) Set up planner settings',
  automatic_planning_settings: '(SP) Clicked on planner settings',
  automatic_planning_template: '(SP) Chose template option',
  automatic_planning_unassigned: '(SP) Chose unnassigned shifts option',
  automatic_planning_upsell: '(SP) Clicked on upsell button',
  badging_history_next_week: 'Badging: bouton \'semaine suivante\'', // Removed in DEV-13725
  badging_history_previous_week: 'Badging: bouton \'semaine précédente\'', // Removed in DEV-13725
  badgings: 'Clicked on Badgeuse tab',
  badgings_days: 'Clicked on Days tab (badging)', // Removed in DEV-13725
  badgings_days_absences: 'Clicked on Absences planned tab (badging-days)', // Removed in DEV-13725
  badgings_days_planned: 'Clicked on Shifts tab (badging-days)', // Removed in DEV-13725
  badgings_history: 'Clicked on History tab (badging)', // Removed in DEV-13725
  badgings_open_filter: 'Opened filters (badging)', // Removed in DEV-13725
  badgings_settings: 'Changed badging settings (badging)',
  badgings_submit_filters: 'Filtered days tab (badging)',
  badgings_users: 'Clicked on Employees tab (badging)', // Removed in DEV-13725
  badgings_validate: 'Approved badgings',
  balance_disable: 'Disable balance switch (initHours counters)', // Removed in DEV-13725
  balance_enable: 'Enable balance switch (initHours counters)',
  blocking_alert_disable: 'Disable blocking Alert',
  blocking_alert_enable: 'Enable blocking Alert',
  bulk_edit_delete_shift: 'Deletion action from the bulk menu',
  bulk_edit_paste_shift: 'Paste action from the bulk menu',
  bulk_edit_add_shift: 'Add a shift action from the bulk menu',
  bulk_edit_unassigned_shift: 'Unassign action from the bulk menu',
  bulk_edit_copy_shift: 'Copy action from the bulk menu',
  bulk_edit_right_click: 'Right click menu is performed',
  cancel_archive_employee: 'Canceled employee archivage',
  cancel_future_archive_employee: 'Canceled employee futur archive', // Removed in DEV-13725
  canceled_desactivation_public_holidays_counter: 'Canceled desactivation public holidays counter',
  canceled_public_holidays_counter_settings: 'Canceled public holidays counter settings',
  check_mail_pin_code: 'Checked email for pin code',
  check_sms_pin_code: 'Checked sms for pin code',
  click_back_on_v2: 'Click back on v2 version',
  click_on_a_shift: 'Click on a Shift', // Removed in DEV-13725
  click_on_add_second_shift: 'Click on add second shift',
  click_on_add_shift_week: 'Click on Add Shift (week)', // Removed in DEV-13725
  click_on_add_unassigned_shift: 'Click on Add Unassigned Shift',
  click_on_counter_tab: 'Click on counter tab', // Removed in DEV-13725
  click_on_custom_absence: 'Click on absence "personnalisée"', // Removed in DEV-13725
  click_on_day_absence: 'Click on absence "toute la journée"', // Removed in DEV-13725
  click_on_delete_planning: 'Delete Planning',
  click_on_duplicate_planning_submit: 'Duplicate from template',
  click_on_duplicate_week_submit: 'Planning Template : Duplicate method',
  click_on_employee_name_week: 'Click on Employee Name (week)',
  click_on_ground_rule_tab: 'Click on ground rule tab', // Removed in DEV-13725
  click_on_half_day_absence: 'Click on absence "demi-journée"', // Removed in DEV-13725
  click_on_publish_planning: 'publish_planning',
  click_on_publish_planning_with_comment: 'Comment added during schedule publication',
  click_on_publish_planning_with_payload: 'publish_planning_with_payload',
  click_on_quick_add_shift: 'Click on Shift Plus button (week)', // Removed in DEV-13725
  click_on_shift_destroy_button: 'Click on Shift Destroy button',
  click_on_shift_destroy_in_modal_week: 'Click on Shift Destroy button (modal)',
  clicked_on_email_btn: 'Clicked on email btn on toolbar',
  clicked_on_payslips_dispatch_btn: 'Clicked on payslips dispatch btn on toolbar',
  clicked_on_payslip_email: 'Clicked on payslip email',
  clicked_on_public_holidays_counter_tab: 'Clicked on public holidays counter tab', // Removed in DEV-13725
  clicked_on_punch_clock_pin_btn: 'Clicked on punch clock pin btn on toolbar',
  clicked_on_report_tab: 'Clicked on report tab', // Removed in DEV-13725
  compared_kpi_values: '(KPI) Compared predicted and real values',
  contract_and_salary: 'Clicked on contract & salary data tab', // Removed in DEV-13725
  create_repeated_shift_1: 'Create Shift repeated #1 (week)',
  create_repeated_shift_2: 'Create Shift repeated #2 (week)',
  create_repeated_shift_3: 'Create Shift repeated #3 (week)',
  create_repeated_shift_4: 'Create Shift repeated #4 (week)',
  create_repeated_shift_5: 'Create Shift repeated #5 (week)',
  create_repeated_shift_6: 'Create Shift repeated #6 (week)',
  deactivate_user_hour_tracker: 'Deactivate hour tracker for user',
  deactivated_public_holidays_counter: 'Desactivated public holidays counter',
  delete_document: 'Deleted a document',
  delete_document_cancel: 'Deleted a document cancel',
  delete_document_validate: 'Deleted a document',
  deleted_shop: 'Deleted shop',
  display_active_employees: 'Clicked on active employees sidebar', // Removed in DEV-13725
  display_archived_employees: 'Clicked on archived employees sidebar', // Removed in DEV-13725
  display_employee_availabilities: 'clicked on dispo btn', // Removed in DEV-13725
  documents: 'Clicked on Document tab', // Removed in DEV-13725
  dowloaded_template_workload_plan: 'Downloaded staff needs template',
  download_attendance_sheets: 'Download attendance sheets',
  downloaded_group_report: 'Downloaded group report',
  downloaded_payroll_integration_report: 'Downloaded payroll integration report',
  downloaded_report: 'Downloaded report',
  downloaded_short_time_working_report: 'Downloaded short-time working report',
  dpae_click_send_automatic_dpae: 'Clicked on send automatic DPAE',
  dpae_link_click_urssaf_site: 'Clicked on URSSAF site link',
  dpae_select_completed_dpae_option: 'DPAE Radio button - Pressed Yes',
  dpae_select_not_completed_dpae_option: 'DPAE Radio button - Pressed No',
  duplicate_keep_assignment_shifts: 'Duplicate: keep assignment shifts', // Unused yet
  duplicate_unassigned_shifts: 'Duplicate: unassigned shifts copied', // Unused yet
  duplicated_last_week: '(KPI) Duplicated previous week',
  duplicated_last_week_cancel: '(KPI) Aborted previous week duplication',
  edit_initialisation_date_hour_tracker: 'Edit initialisation date Hour tracker',
  edited_workload_plan: 'Edited staff needs',
  employee: 'click on employee profile (sidebar)',
  employee_history_absence: 'Selected absences (employee_history)',
  employee_history_all: 'Selected all (employee_history)',
  employee_history_download_attendance: 'Download attendance sheets (employee_history)',
  employee_history_next_week: 'click on right arrow (employee_history)', // Removed in DEV-13725
  employee_history_openned_week: 'Opened date picker (employee_history)',
  employee_history_poste: 'Selected posts (employee_history)',
  employee_history_previous_week: 'click on left arrow (employee_history)', // Removed in DEV-13725
  employee_history_select_week: 'Selected dates (employee_history)',
  employees: 'click on employee tab', // Removed in DEV-13725
  esignature_documents_employee_document_space_request_signature: 'Request signature in documents employee space',
  esignature_documents_generation_request_signature: 'Request signature in document generation',
  esignature_documents_open_follow_up: 'Open follow up documents esignature',
  esignature_documents_place_signatures_modal_cancel: 'Cancel place modal esginature documents',
  esignature_documents_place_signatures_modal_send: 'Send place modal esginature documents',
  esignature_documents_resend_employee_space: 'Resend document from employee space',
  esignature_documents_resend_follow_up_space: 'Resend document from follow up space',
  esignature_documents_select_employee_modal_cancel: 'Cancel select employee modal esginature documents',
  esignature_documents_select_employee_modal_next: 'Next select employee modal esginature documents',
  esignature_download_all_timesheets: 'Downloaded all signed individual timesheets',
  esignature_download_individual_timesheet: 'Downloaded an E-signature signed individual timesheet',
  esignature_resend_individual_timesheet: 'Manually resend an individual timesheet for signature',
  esignature_schedule_panel_cancel: 'Cancelled E-signature request panel',
  esignature_schedule_panel_open: 'Opened E-signature request panel',
  esignature_schedule_panel_submit: 'Sent E-signature request panel',
  esignature_upsell_aware_admin: 'Esignature Upsell: click on share with my admins',
  esignature_upsell_aware_admin_send_email: 'Esignature Upsell: mail sent to admins',
  esignature_upsell_back: 'Esignature Upsell: click on back button (step 2)',
  esignature_upsell_banner_cta_click: 'Esignature Upsell: clicked on incitation banner CTA',
  esignature_upsell_choose_option: 'Esignature Upsell: click step 1 "choose option"',
  esignature_upsell_close: 'Esignature Upsell: close modal',
  esignature_upsell_confirm_subscription: 'Esignature Upsell: click step 2 "Subscription confirmation"',
  esignature_upsell_error_fetch_prices: 'Esignature Upsell: error occurs while fetching prices',
  esignature_upsell_error_submit: 'Esignature Upsell: intervention needed after error on fetching prices',
  esignature_upsell_finish: 'Esignature Upsell: Finish upsell step 3 ("start" button)',
  esignature_upsell_open: 'Esignature Upsell: open modal',
  esignature_upsell_request_demo: 'Esignature Upsell: click on request demo',
  estimated_hourly_rate_charged: 'Estimated hourly rate charged',
  export_planning_attendance: 'Export Attendance Sheets (week)',
  export_planning_day: 'Export Planning (day)',
  export_planning_day_exceeded_shifts: 'Export planning (day) ended in failure due to exceeding the max number of shifts',
  export_planning_month: 'Export Month Planning (week)',
  export_planning_week: 'Export Planning (week)',
  extend_amendments_list: 'Clicked on extend amendments list',
  file_delete_confirmed_desktop: 'User confirms deletion of a file on desktop',
  filters_applied: 'Filters applied',
  filters_search: 'Search filters',
  filters_clear: 'Filters cleared',
  fortify_silae_accountant_email_sent: 'Sent email to accountant',
  fortify_silae_activation_modal_configuration: 'Filled in 3 Silae API keys',
  fortify_silae_activation_modal_next: 'Continued on Silae reassurance modal',
  fortify_silae_deactivate: 'Deactivated Silae integration',
  fortify_silae_keys_accountant_submit: 'Chose to delegate to accountant',
  fortify_silae_keys_self_submit: 'Chose to self-submit Silae keys',
  fortify_silae_keys_update: 'Modified Silae API keys',
  fortify_silae_shop_pin_submit: 'Submitted shop’s PIN to synchronize Silae SIRH integration',
  fortify_silae_start_setup: 'Started setting up Silae integration',
  global_settings_tab: 'Clicked on global settings tab', // Removed in DEV-13725
  help_shop_access: 'Clicked on article link shop access',
  history_counters: 'Click on history/counters tab', // Removed in DEV-13725
  history_counters_counters_tab: 'clicked on hours counter tab', // Removed in DEV-13725
  history_counters_history_tab: 'clicked on history tab', // Removed in DEV-13725
  history_counters_paid_leaves_tab: 'clicked on paid leaves counter tab',
  hours_counter_disable: 'Disable hours counter switch',
  hours_counter_disable_cancelled: 'Canceled hours counter disable',
  hours_counter_disable_confirmed: 'Confirmed hours counter disable',
  hours_counter_enable: 'Enable hours counter switch',
  hours_counter_initialization_cancel: 'Canceled hours counter initialization',
  hours_counter_initialization_cancel_from_resume: 'Canceled hours counter initialization (from resumed)',
  hours_counter_initialization_resume: 'Resumed hours counter initialization',
  hours_counter_initialization_save_and_finish_later_validate: 'Saved & finished later hours counter initialization',
  hours_counter_initialization_submit_from_resume: 'Finished hours counter initialization (from resumed)',
  hours_counter_initialization_validate: 'Saved hours counter initialization',
  hr_folder: 'Clicked on tab HR file', // Removed in DEV-13725
  hris_add_document: 'Click on add a document btn', // Removed in DEV-13725
  hris_click_initiales: 'click on initiales (text_documents)',
  hris_confirmed_window_doc: 'Finished the document creation (confirmed_window_doc)',
  hris_confirmed_window_doc_sent_by_mail: 'Clicked on send the document (confirmed_window_doc)',
  hris_move_document: 'Clicked on Move a document',
  hris_move_document_cancel: 'Canceled a document movement',
  hris_move_document_validate: 'Moved a document',
  hris_select_template: 'Selected a template',
  hris_send_by_email: 'Clicked on send by email',
  hris_send_document_document_tab_cancel: 'Canceled document sending (send_document_document-tab)',
  hris_send_document_document_tab_change_title: 'Changed email object (send_document_document-tab)',
  hris_send_document_document_tab_send_by_mail: 'Clicked on send the document (Document tab)',
  hris_send_document_document_tab_validate: 'Send the document (send_document_document-tab)',
  hris_send_document_post_creation_cancel: 'Canceled document sending (send_document_post-creation)',
  hris_send_document_post_creation_validate: 'Sent the document (send_document_post-creation)',
  hris_tab: 'click on text document templates tab', // Removed in DEV-13725
  hris_template_cancel_creation: 'Canceled template selection',
  hris_template_create: 'Click on create a template',
  hris_text_document_template_save: 'Saved a document (text_documents)',
  hris_upload_file: 'Clicked on Upload a file btn', // Removed in DEV-13725
  hris_upload_file_validate: 'Uploaded a document',
  in_planning_select: 'Clicked on select display employee in planning',
  in_planning_select_cancel: 'Canceled select display employee in planning',
  in_planning_select_validate: 'Validated select display employee in planning',
  inapp_rollout_click_create_network_shop: 'Clicked on the create shop button via network orga',
  inapp_rollout_click_create_shop: 'Clicked on the create shop button',
  init_counter_minus: 'Click on - (initHourscounters)',
  init_counter_plus: 'Click on + (initHourscounters)',
  init_plc_minus: 'Clicked on - (initPlc)',
  init_plc_plus: 'Clicked on + (initPlc)',
  leave_request_accept_through_url: 'User clicked on CTA “Accepter la demande” from email',
  leave_request_deleted: 'User deleted a leave request',
  leave_request_download_guide: 'Download employees guide',
  leave_request_preselected_manager_changed: 'User changed default receiver on leave request creation modal',
  leave_request_processed: 'Processed an absence request',
  leave_request_redirect_to_planning: 'Viewed schedule (absence request)',
  leave_request_show_through_url: 'User clicked on CTA “Voir la demande” from email',
  leave_request_transfer: 'User clicked on “Transférer” on Transfer modal',
  leave_request_transfer_through_url: 'User clicked on CTA “Transférer la demande” from email',
  lock_day: 'Lock Day (day V3)',
  lock_day_week: 'Lock Day (week)',
  lock_week: 'Lock week (week)',
  locked_plannings_report: 'Locked plannings (report)',
  maj_init_hours_counter_minus: 'Click on - (initMajorationHourscounters)',
  maj_init_hours_counter_plus: 'Click on + (initMajorationHourscounters)',
  majoration_disable: 'Disable majoration switch (initHours counters)', // Removed in DEV-13725
  majoration_enable: 'Enable majoration switch (initHours counters)', // Removed in DEV-13725
  majoration_hours_counter_cancelled: 'Canceled majoration hours counter update',
  majoration_hours_counter_update: 'Saved majoration hours counter update',
  manual_change_days_counter: 'Modified days counter',
  manual_change_days_counter_cancel: 'Cancel days counter modification',
  manual_change_days_counter_validate: 'Validate days counter modification',
  manual_change_hours_counter: 'modified hours_counter on employee',
  manual_change_hours_counter_cancel: 'Canceled hours counter modification',
  manual_change_hours_counter_validate: 'Saved hours counter modification',
  manual_plc_change_counter: 'modified paid leaves_counter on employee',
  manual_plc_change_counter_cancel: 'Canceled paid leaves counter modification',
  manual_plc_change_counter_validate: 'Saved paid leaves counter modification',
  meal_add_criteria: 'Added a criteria',
  meal_back_to_first_step: 'Go back on first step meal rule',
  meal_compensation_disable: 'Disable meal start switch',
  meal_compensation_enable: 'Enable meal start switch',
  meal_compensation_init: 'Started meal rules',
  meal_delete_criteria: 'Deleted a criteria',
  meal_rules_cancelled: 'Canceled meal rules',
  meal_rules_edit: 'Edited meal rules',
  meal_rules_edit_cancelled: 'Canceled meal rules (editmeal)',
  meal_rules_edit_validate: 'Saved meal rules (editmeal)',
  meal_rules_validate: 'Saved meal rules',
  month_view_V3_back_to_v2: 'Click back V2 (monthv3)',
  month_view_V3_back_to_v3: 'Click back v3 (monthV3)',
  month_view_V3_click_month_view: 'Click month view',
  month_view_V3_click_on_shift: 'Click on shift (monthV3)',
  month_view_V3_lock_day: 'Lock day (monthV3)',
  month_view_V3_open_print_modal: 'Open print modal (monthv3)',
  month_view_V3_print: 'Click print (monthV3)',
  month_view_V3_print_attendance: 'Click print timesheets (monthV3)',
  month_view_V3_print_month: 'Click print schedule (monthV3)',
  month_view_V3_unlock_day: 'Unlock day (monthV3)',
  move_to_unassigned_shift: 'Move shift to unassigned (button)',
  multicontract_add_amendment_cancel: '(Multicontract) Canceled amendment addition',
  multicontract_add_amendment_current_contract: '(Multicontract) Added an amendment to the current contract',
  multicontract_add_contracted_hours_amendment_validate: '(Multicontract) Added amendment to the contracted hours',
  multicontract_add_gross_wage_from_current_contract: '(Multicontract) Confirmed gross wage',
  multicontract_add_hourly_rate_from_current_contract: '(Multicontract) Confirmed hourly rate',
  multicontract_apply_document_template_on_current_contract: '(Multicontract) Created document for current contract',
  multicontract_apply_document_template_on_future_contract: '(Multicontract) Created document for future contract',
  multicontract_apply_document_template_on_past_contract: '(Multicontract) Created document for past contract',
  multicontract_cancel_amendment_current_contract: '(Multicontract) Cancelled adding an amendment to the current contract',
  multicontract_cancel_modify_on_current_contract: '(Multicontract) Cancelled the update of the current contract',
  multicontract_cancel_on_contract_creation_document: '(Multicontract) Aborted document creation',
  multicontract_canecel_period_amendment_current_contract: 'Cancelled amendment period to the current contract',
  multicontract_confirm_delete_contract: '(Multicontract) Confirmed contrat deletion',
  multicontract_create_contract_button: '(Multicontract) Created new contract',
  multicontract_delete_on_future_contract: '(Multicontract) Deleted future contract',
  multicontract_delete_on_past_contract: '(Multicontract) Delete past contract',
  multicontract_display_full_creation_form: '(Multicontract) Opened full contract creation form',
  multicontract_display_less_creation_form: '(Multicontract) Closed full contract creation form',
  multicontract_duplicate_on_future_contract: '(Multicontract) Duplicated future contract',
  multicontract_duplicate_on_past_contract: '(Multicontract) Duplicated past contract',
  multicontract_estimated_hourly_rate_on_new: '(Multicontract) Estimated hourly rate with charges for new contract',
  multicontract_go_back_create: '(Multicontract) Aborted contract creation',
  multicontract_modify_on_current_contract: '(Multicontract) Updated current contract',
  multicontract_modify_on_future_contract: '(Multicontract) Updated future contract',
  multicontract_modify_on_past_contract: '(Multicontract) Updated past contract',
  multicontract_new_contract_button: '(Multicontract) Clicked on create contract button',
  multicontract_quick_add_contracted_hours_amendment: '(Multicontract) Quick-added amendment to the contracted hours',
  multicontract_quick_add_hourly_rate: '(Multicontract) Quick-updated hourly rate',
  multicontract_quick_add_monthly_salary: '(Multicontract) Quick-updated gross wage',
  multicontract_show_all_information: '(Multicontract) Viewed current contract read-only version',
  multicontract_show_future_tab: '(Multicontract) Viewed future tab', // Removed in DEV-13725
  multicontract_show_history_tab: '(Multicontract) Viewed history tab', // Removed in DEV-13725
  multicontract_sorted_contract_type: '(Multicontract) Sorted contract type',
  multicontract_sorted_created_at: '(Multicontract) Sorted creation date',
  multicontract_sorted_start_date: '(Multicontract) Sorted contract period',
  multicontract_validate_amendment_current_contract: '(Multicontract) Confirmed the adding amendment to the current contract',
  multicontract_validate_modify_on_current_contract: '(Multicontract) Confirmed update current contract',
  multicontract_validate_on_contract_creation_document: '(Multicontract) Confirmed document creation',
  multicontract_validate_period_amendment_current_contract: '(Multicontract) Confirmed amendment period to the current contract',
  multicontract_view_on_future_contract: '(Multicontract) Viewed future contract read-only version',
  multicontract_view_on_past_contract: '(Multicontract) Viewed past contract read-only version',
  new_dashboard: 'Changed granularity',
  new_shop_btn: 'Clicked on add a shop',
  number_weeks_duplicated: 'Number of weeks duplicated',
  onboarding_add_employee: 'Create an employee from the onboarding modal',
  onboarding_add_poste: 'Create a position from the onboarding modal',
  onboarding_edit_poste: 'Edit a position from the onboarding modal',
  onboarding_import_schedule_file_step: 'A user imports a file using the schedule import feature on the onboarding',
  onboarding_redirect_user_to_schedule: 'The user is redirected to the schedule after finishing the onboarding',
  onboarding_skip_schedule_import_step: 'Clicked the button to enter the data manually',
  onboarding_submit_employee_step: 'Validate the employee step from the onboarding modal',
  onboarding_submit_position_step: 'Validate the position step from the onboarding modal',
  onboarding_submit_profiling_step: 'Validate the profiling step from the onboarding modal',
  open_kpi: '(KPI) Opened module', // Not used yet
  opened_kpi_module: '(KPI) Opened module',
  opt_out_call_cancelled_subscription: 'Clics on phone number',
  opt_out_cancel_subcription_confirmation: 'Cancellation confirmation',
  opt_out_cancel_subscription: 'Clics on cancel subscription',
  organisation_settings_licenses_tab: 'Clicked on licenses tab', // Removed in DEV-13725
  organisation_settings_organisation_tab: 'Clicked on organisation tab', // Removed in DEV-13725
  organisation_settings_partner_tools_tab: 'Clicked on partner tools tab', // Removed in DEV-13725
  organisation_settings_partners_tools: 'Clicked on POS software tab', // Removed in DEV-13725
  organisation_settings_pay_partners: 'Clicked on payroll software tab',
  organisation_settings_shops_tab: 'Clicked on shops tab', // Removed in DEV-13725
  organisation_settings_tree_tab: 'Clicked on tree view tab', // Removed in DEV-13725
  page_load_time_planning_day: 'Tracks the load time of the planning day view page',
  page_load_time_planning_month: 'Tracks the load time of the planning month view page',
  page_load_time_planning_week: 'Tracks the load time of the planning week view page',
  paid_leaves_counter_balance_disable: 'Disable balance switch (initHours counters)', // Removed in DEV-13725
  paid_leaves_counter_balance_enable: 'Enable balance switch (initHours counters)',
  paid_leaves_counter_disable: 'Disable Paid leaves counters switch',
  paid_leaves_counter_enable: 'Enable Paid leaves counters switch',
  paid_leaves_counter_initialization_cancel: 'Canceled paid leaves counter initialization',
  paid_leaves_counter_initialization_cancel_from_resume: 'Canceled paid leaves counter initialization (from resumed)',
  paid_leaves_counter_initialization_resume: 'Resumed paid leaves counter initialization',
  paid_leaves_counter_initialization_save_and_finish_later_submit: 'Saved & finished later paid leaves counter initialization',
  paid_leaves_counter_initialization_validate: 'Saved paid leaves counter initialization',
  paid_leaves_counter_initialization_validate_from_resume: 'Finished paid leaves counter initialization (from resumed)',
  paid_leaves_counters_disable_cancelled: 'Canceled Paid leaves counters disable',
  paid_leaves_counters_disable_confirmed: 'Confirmed Paid leaves counters disable',
  payroll_preparation_reset_showed_employee_missing_data: 'Reset show employees with missing payroll data',
  payroll_preparation_showed_employee_missing_data: 'Showed employees with missing payroll data',
  payslips_dispatch_analysed_payslips: 'Analysed payslips dispatch (dispatch)',
  payslips_dispatch_dispatched_payslips: 'Dispatched payslips (dispatch)',
  payslips_dispatch_final_submit: 'Distributed documents',
  payslips_dispatch_imported_files: 'Imported files (dispatch)',
  payslips_dispatch_loading_payslips: 'Loaded analyse payslips (dispatch)',
  payslips_dispatch_step1_validated_period: 'Validated payroll period',
  payslips_dispatch_step2_uploaded_documents: 'Uploaded documents',
  payslips_dispatch_step3_cancel_rename_files: 'Has canceled renaming',
  payslips_dispatch_step3_go_back_step2: 'Go back to step 2',
  payslips_dispatch_step3_rename_files: 'Has renamed files',
  personal_information: 'Click on Personal Infos in Employee tab', // Removed in DEV-13725
  planning_conflicts_cancel_modal: 'Canceled planning conflicts manager',
  planning_conflicts_validate_modal: 'Validated planning conflicts manager',
  planning_conflicts_view_details: 'Viewed details planning conflict manager',
  planning_day_weekly_total: 'Click on day planning weekly total',
  planning_days: 'Clicked on Planning day tab', // Removed in DEV-13725
  planning_history_next_week: 'Planning week: bouton \'semaine suivante\'', // Removed in DEV-13725
  planning_history_previous_week: 'Planning week: bouton \'semaine précédente\'', // Removed in DEV-13725
  planning_loaded: 'Week planning has been loaded', // Removed in DEV-13725
  planning_months: 'Clicked on Planning month tab',
  planning_print_allow_unassigned_shifts_display: 'Activate unassigned shifts display',
  planning_print_allow_unassigned_shifts_hide: 'Desactivate unassigned shifts display',
  planning_print_birthday_display_display: 'Activate birthdays display',
  planning_print_birthday_display_hide: 'Desactivate birthdays display',
  planning_print_counter_display_display: 'Activate counters display',
  planning_print_counter_display_hide: 'Desactivate counters display',
  planning_print_meal_in_pdf_display: 'Activate meals display',
  planning_print_meal_in_pdf_hide: 'Desactivate meals display',
  planning_print_pause_in_pdf_display: 'Activate break display',
  planning_print_pause_in_pdf_hide: 'Desactivate break display',
  planning_print_total_in_pdf_display: 'Activate total hours display',
  planning_print_total_in_pdf_hide: 'Desactivatre total hours display',
  planning_week_hours_done_detail: 'Click on hours done details', // Removed in DEV-13725
  planning_week_monthly_total: 'Click on Monthly total',
  planning_weeks: 'Clicked on Planning week tab', // Removed in DEV-13725
  plannings_open_filter: 'Opened filters (planning)', // Removed in DEV-13725
  plannings_submit_filters: 'Filtered days tab (planning)',
  poste_add: 'Added a post', // Removed in DEV-13725
  poste_delete: 'Deleted a post',
  postes_tab: 'Click on posts tab', // Removed in DEV-13725
  preview_document: 'Previewed a document',
  punch_clock_settings_updated: 'punch_clock_settings_updated',
  quick_add_contracted_hours_amendment: 'Contract hours change amendment - quick-add',
  redirect_pending_leave_requests_from_schedule: 'Clicked on a pending request from the schedule',
  redo_btn_planning: 'Click on Redo',
  remove_admendment: 'Removed amendment',
  remove_amendment_from_history: 'Removed amendment from History amendment',
  remove_shop: 'Removed shop access',
  report_annualization_update_cancellation: 'Canceled updating annualization trackers in the report\'s update annualization trackers modal',
  report_annualization_update_clicked: 'Click on the update annualization trackers button in the report tab',
  report_annualization_update_confirmation: 'Click on the update button in the report\'s update annualization trackers modal',
  report_rules_cancelled: 'Cancel hours recalculation',
  report_rules_save: 'Saved hours recalculation',
  report_rules_tab: 'Clicked on report rules tab', // Removed in DEV-13725
  report_rules_update: 'Saved report rules updates',
  report_update_tracker_click: 'Click on the update trackers button in the report tab',
  report_update_tracker_reset_confirmation: 'Click on the confirmation button to reset hour trackers in the report',
  report_update_tracker_update_confirmation: 'Click on the submit button to update hour trackers in the report',
  reports_history_next_week: 'Rapport: bouton \'période suivante\'', // Removed in DEV-13725
  reports_history_previous_week: 'Rapport: bouton \'période précédente\'', // Removed in DEV-13725
  reset_pin_code: 'Reinitialized pin code',
  restore_employee: 'Restored employee',
  rtt_counter_activation: 'Activate the switch button for the rtt counter',
  rtt_counter_activation_submit: 'Validate the activation modale of RTT tracker',
  rtt_counter_deactivation: 'Deactivate the switch button for the rtt counter',
  rules_tab: 'Click on rules tab', // Removed in DEV-13725
  rules_updated: 'Updated rules',
  rup_export: 'Exported RUP',
  sc_next_period_action: 'SC - Go to the next period',
  sc_open_print_modale: 'SC - Open the print modale',
  sc_previous_period_action: 'SC - Go to the previous period',
  sc_redo_action: 'SC - Redo action',
  sc_undo_action: 'SC - Undo action',
  search_employee: 'Searched employees',
  self_serve_change_email: 'Step 2 - Click on "Change my email address"',
  self_serve_choose_pack_offer: 'Step 5  - Click on "Choose plan"',
  self_serve_commitment_after_trial_ended_submit: 'Trial Ended - Click on confirm subscription CTA',
  self_serve_confirm_payment: 'Step 6 - Click on "Confirm payment"',
  self_serve_convention: 'Step 4 - Click on convention',
  self_serve_employee_counts: 'Step 3 - Click on # employees',
  self_serve_link_societe_com: 'Step 4 - Click on link "societe.com"',
  self_serve_login: 'Step 2 - Click on "Log in"',
  self_serve_logout: 'Click on "Log out"',
  self_serve_more_info: 'Step 5 - Click on "Learn more"',
  self_serve_resend_email: 'Step 2 - Click on "Click here" to resend verification email',
  self_serve_sector: 'Step 4 - Click on sector',
  self_serve_shop_counts: 'Step 3 - Click on # shops',
  self_serve_start_using_skello: 'Final Step - Click on CTA "Start using Skello"',
  self_serve_step_five_go_back: 'Step 5 - Click on "Back"',
  self_serve_step_four_continue: 'Step 4 - Click on CTA "Continue"',
  self_serve_step_four_go_back: 'Step 4 - Click on "Back"',
  self_serve_step_six_go_back: 'Step 6 - Click on "Back""',
  self_serve_step_three_continue: 'Step 3 - Click on CTA "Continue"',
  self_serve_submit_account_creation: 'Step 1 - Click on "Create my Skello account"',
  self_serve_manual_company_entry_click: 'Manual company entry - link click',
  self_serve_manual_company_entry_success: 'Manual company entry - success',
  send_invitation: 'Sent Invitation',
  send_pin_code: 'Clicked on send pin code',
  send_pin_code_cancel: 'Cancel send pin code',
  send_pin_code_validate: 'Sent pin code',
  sent_email_to_employees: 'Sent email to employees',
  shift_created: 'Shift created',
  shift_created_poste: 'Shift created (poste)',
  shift_edited: 'Shift Edited',
  shift_edited_poste: 'Shift Edited (poste)',
  shift_file_upload_open_picker: 'File Upload - Open file picker',
  shift_file_open_desktop: 'User clicks on a file to open it',
  shift_moved_poste: 'Shift Moved (poste)',
  shift_moved_week: 'Shift Moved (week)',
  shift_pasted_from_popular: 'Shift Pasted from Popular Shift (week)',
  shift_pasted_poste: 'Shift Pasted (poste)',
  shift_pasted_week: 'Shift Pasted (week)',
  shift_quick_update_user: 'Quick update (poste)',
  shop_select: 'Clicked on shop selected',
  shop_select_cancel: 'Canceled shop selected',
  shop_select_validate: 'Validated shop selected',
  shop_settings_tab: 'Clicked on shop settings tab', // Removed in DEV-13725
  shop_update_save: 'Saved shop update', // Removed in DEV-13725
  shops_tab: 'Click on shop tab', // Removed in DEV-13725
  sort_amendment_by_creation_date: 'Sorted History amendment by creation date',
  sort_amendment_by_effective_period: 'Sorted History amendment by effective period',
  sort_amendment_by_type: 'Sorted History amendment by Type',
  sort_leave_requests: 'Sort leave requests',
  ss_account_creation_step1_login_link: 'The clicks on the link to navigate to the login page if the user already has an account.',
  ss_account_creation_step1_login_link_already_exists: 'The clicks on the link to navigate to the login page if the user already has an account.',
  ss_account_creation_step1_submit: 'The clicks on the button to submit the account creation form.',
  ss_account_creation_step1_terms_privacy_policy_link: 'The clicks on the link to the terms and privacy policy document.',
  ss_account_creation_step2_login_link: 'The clicks on the link to navigate to the login page if the user already has an account.',
  ss_back_button_step_five: 'Back button clicked in self-serve step five - pack choice',
  ss_back_button_step_four: 'Back button clicked in self-serve step four - shop information',
  ss_back_button_step_six: 'Back button clicked in self-serve step six - billing information',
  ss_billing_information_step6_annual_payment: 'The clicks on annualy payment',
  ss_billing_information_step6_monthly_payment: 'The clicks on monthly payment',
  ss_billing_information_step6_submit: 'The clicks on the submit free trial',
  ss_billing_information_step6_terms_of_use_link: 'The clicks on the link to read the terms of use',
  ss_checklist_add_employees: 'Success in creating new employees',
  ss_checklist_modal_add_employees: 'Clicks on the add employee step within the modal',
  ss_checklist_modal_control_and_validate: 'Clicks on the control and validate step within the modal',
  ss_checklist_modal_discover_employee_experience: 'Clicks on the discover employee step within the modal',
  ss_checklist_modal_install: 'Clicks on the install step within the modal',
  ss_checklist_modal_invite_employees: 'Clicks on the send pin step within the modal',
  ss_checklist_send_pin_to_all: 'Send pin code to all employees',
  ss_checklist_timeclock_success_setup_later: 'Clicked on setup later on timeclock welcome video',
  ss_checklist_timeclock_success_setup_now: 'The number of people who choose setup right away in the video modal',
  ss_email_validation_step2_modify_email: 'The clicks on the button to modify the email adress',
  ss_email_validation_step2_resend: 'The clicks on the link to resend the validation email',
  ss_lead_definition_step3_next_button: 'The clicks on the button to go to the next step, and selected data',
  ss_pack_choice_step5_planning: 'The clicks on the planning pack choice',
  ss_pack_choice_step5_planning_and_timeclock: 'The clicks on the planning pack + time clock option ',
  ss_pack_choice_step5_timeclock: 'The clicks on the time clock pack choice',
  ss_shop_info_step4_next_button: 'The clicks on the button to go to the next step',
  started_public_holidays_counter_settings: 'Started public holidays counter settings',
  submit_leave_request_filters: 'Submit filter',
  switch_month_to_week: 'Switch from month planning to week plan', // Unused yet
  team_add: 'Added a team',
  team_delete: 'Deleted a team',
  team_employee_submit: 'Saved employee selection',
  team_rotation_cancel: 'Cancelled team rotation',
  team_rotation_disable: 'Disable team rotation switch',
  team_rotation_enable: 'Enable team rotation switch',
  team_rotation_modal_show: 'Started team rotation',
  team_rotation_next_step: 'Added variable working hours',
  team_rotation_submit: 'Saved team rotation',
  team_rotation_update: 'Edited team rotation',
  team_select: 'Clicked on team select',
  team_select_cancel: 'Canceled team selected',
  team_select_validate: 'Validated team selected',
  teams_tab: 'Click on Teams tab', // Removed in DEV-13725
  text_document_template_create: 'click on add a text document template',
  text_document_template_delete: 'Deleted a text document template',
  text_document_template_disable: 'Disable a text document template',
  text_document_template_enable: 'Enable a text document template',
  text_document_template_save: 'Saved a new text document template',
  text_document_template_update: 'click on update a text document template',
  trigger_absences_side_pannel_absence_tab: 'Triggered absence sidebar (absences_tab)',
  unassigned_shift_created: 'Unassigned Shift created',
  unassigned_shift_created_poste: 'Unassigned Shift created (poste)',
  undo_btn_planning: 'Click on Undo',
  unlock_day: 'Unlock Day (day V3)',
  unlock_day_week: 'Unlock Day (week)',
  unlock_week: 'Unlock week (week)',
  unpaid_invoice_banner_link_click: 'Click on unpaid banner payment link',
  update_employee: 'Updated employee',
  update_license: 'Updated license', // Removed in DEV-13725
  update_organisation: 'Updated organisation',
  updated_public_holidays_counter_settings: 'Updated public holidays counter settings',
  updated_public_holidays_counter_settings_employee_tab: 'Updated public holidays counter settings(employee tab)',
  updated_volume_name: '(KPI) Updated volume name',
  upload_file: 'Clicked on Upload a file btn', // Removed in DEV-13725
  upload_file_cancel: 'Canceled document uploading', // Removed in DEV-13725
  upload_file_validate: 'Uploaded a document',
  validate_archive_employee: 'archived employee on modal',
  validate_update_public_holidays_counter_settings: 'Validated update public holidays counter settings',
  validated_public_holidays_counter_settings: 'Validated public holidays counter settings',
  viewed_details_public_holidays_settings: 'Viewed details public holidays settings',
  viewed_display_settings: '(KPI) Viewed display settings',
  viewed_real_tab: '(KPI) Viewed real tab',
  week_planning_intermediate_validate_period: 'Lock a period',
  week_planning_poste_filter_modified: 'Click inside Postes Filter (week) modified', // Removed in DEV-13725
  week_planning_remove_filter: 'Remove filters selection', // Removed in DEV-13725
  week_planning_sort_by_name_AToZ: 'Click on sort_by_name button', // Removed in DEV-13725
  week_planning_sort_by_name_custom: 'Click on sort_by_custom button', // Removed in DEV-13725
  week_planning_sort_by_name_ZToA: 'Click on sort_by_name button (reverse)', // Removed in DEV-13725
  week_planning_sort_employees: 'Sort Employees', // Removed in DEV-13725
  week_planning_switch_line: 'Switch Line (week)',
  week_planning_team_filter_modified: 'Click inside Teams Filter (week) modified', // Removed in DEV-13725
  week_planning_zoom_changes: 'Change schedule size',
};
