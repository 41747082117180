<template>
  <SkModal
    id="badging-rules-modal"
    :modal-title="$t('badgings.history.rules_modal.title')"
  >
    <template #body>
      <div class="badging-rules-modal__body">
        <span class="badging-rules-modal__context">
          {{ $t('badgings.history.rules_modal.context') }}
        </span>
        <ul class="badging-rules-modal__rules-block">
          <li>{{ $t('badgings.history.rules_modal.shift_without_badge') }}</li>
          <li>{{ $t('badgings.history.rules_modal.badge_without_shift') }}</li>
          <li>{{ $t('badgings.history.rules_modal.badged_absence') }}</li>
          <li>{{ lateArrivalSettings }}</li>
          <li>{{ earlyArrivalSettings }}</li>
          <li>{{ lateDepartureSettings }}</li>
          <li>{{ earlyDepartureSettings }}</li>
        </ul>
      </div>
    </template>
    <template #footer>
      <SkOroraButton @click="handleClose">
        {{ $t('badgings.history.rules_modal.close') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'BadgingRulesModal',
  computed: {
    ...mapState('badgings', ['settings']),

    lateArrivalSettings() {
      return this.settings?.lateArrivalTakesPlannedDate ?
        this.$t('badgings.history.rules_modal.late_arrival_takes_planned_date') :
        this.$t('badgings.history.rules_modal.late_arrival_takes_arrival_date');
    },
    earlyArrivalSettings() {
      return this.settings?.earlyArrivalTakesPlannedDate ?
        this.$t('badgings.history.rules_modal.early_arrival_takes_planned_date') :
        this.$t('badgings.history.rules_modal.early_arrival_takes_arrival_date');
    },
    lateDepartureSettings() {
      return this.settings?.lateDepartureCountedAsLate ?
        this.$t('badgings.history.rules_modal.late_departure_counted_as_late') :
        this.$t('badgings.history.rules_modal.late_departure_counted_as_planned_departure');
    },
    earlyDepartureSettings() {
      return this.settings?.earlyDepartureCountedAsEarly ?
        this.$t('badgings.history.rules_modal.early_departure_counted_as_early') :
        this.$t('badgings.history.rules_modal.early_departure_counted_as_planned_departure');
    },
  },
  methods: {
    handleClose() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'badging-rules-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-rules-modal__body {
  padding: 16px 24px;
}

.badging-rules-modal__context {
  font-size: $fs-text-m;
  font-weight: $fw-semi-bold;
}

ul.badging-rules-modal__rules-block {
  margin-block-start: 16px;
  padding-inline-start: 25px;
}
</style>
