<template>
  <SkModal
    id="avatar-upload-modal"
    ref="modal"
    :modal-title="modalTitle"
    :cancel-button-label="$t('employees.upload_avatar.cancel')"
    class="avatar-upload-modal"
    size="ds-medium"
    @cancel="closeModal"
    @close="closeModal"
  >
    <template #body>
      <div
        class="avatar-upload__body"
      >
        <div class="avatar-upload__wrapper">
          <CropUploader
            ref="cropUploader"
            v-model="show"
            field="img"
            :width="256"
            :height="256"
            :url="avatarPath"
            :headers="headers"
            :lang-ext="translationOverrides"
            :lang-type="$i18n.locale"
            img-format="png"
            no-square
            @src-file-set="enableSubmit"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
          />
        </div>
        <div
          v-if="value"
          class="avatar-delete__wrap"
        >
          <div class="avatar-delete">
            <img
              class="avatar-delete__photo"
              :src="value"
            >
            <SkCircleButton
              icon="TrashCanV2Icon"
              icon-color="#FFFFFF"
              background-color="#D03E50"
              size="medium"
              @click="deleteAvatar"
            />
          </div>
          <div class="avatar-delete__text">
            {{ $t('employees.upload_avatar.current') }}
          </div>
        </div>
      </div>
    </template>

    <template #submit-btn>
      <SkOroraButton
        :loading="sendingAvatar"
        :disabled="!imageShowing"
        @click="handleSubmit"
      >
        {{ $t('employees.upload_avatar.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import CropUploader from 'vue-image-crop-upload';
import {
  MODAL_SHOW_EVENT,
  SkModal,
  SkOroraButton,
  SkCircleButton,
} from '@skelloapp/skello-ui';

import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';

export default {
  name: 'AvatarUploadModal',
  components: { CropUploader, SkModal, SkOroraButton, SkCircleButton },
  props: {
    userId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      imageShowing: false,
      sendingAvatar: false,
      show: true,
      headers: {
        'X-Request-With': 'XMLHttpRequest',
        Authorization: `Bearer ${authClient.authToken.token}`,
      },
      imgDataUrl: '', // the datebase64 url of created image
      translationOverrides: {
        error: {
          onlyImg: this.$t('employees.upload_avatar.errors.only_img'),
          outOfSize: this.$t('employees.upload_avatar.errors.out_of_size'),
          lowestPx: this.$t('employees.upload_avatar.errors.lowest_px'),
        },
      },
    };
  },
  computed: {
    avatarPath() {
      return `/v3/api/users/${this.userId}/avatar`;
    },
    modalTitle() {
      const title = this.value ? 'change_avatar' : 'select_avatar';
      return this.$t(`employees.upload_avatar.${title}`);
    },
  },
  methods: {
    enableSubmit(fileName, fileType, fileSize) {
      setTimeout(() => {
        if (this.$refs.cropUploader.hasError) {
          return;
        }
        this.imageShowing = true;
      }, 200);
    },
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    openModal() {
      // Crop uploader takes some time to mount - only show modal at next tick
      setTimeout(() => {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'avatar-upload-modal');
      }, 0);
    },
    closeModal() {
      this.$refs.modal.hide();
      this.$refs.cropUploader.setStep(1);
      this.$refs.cropUploader.reset();
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
    },
    validateAndClose(url) {
      this.closeModal();
      // Changes trigger a re-render: run after modal is closed
      setTimeout(() => {
        this.$emit('input', url);
        this.$refs.cropUploader.setStep(1);
      }, 200);
    },
    cropUploadSuccess(response) {
      this.sendingAvatar = false;
      setTimeout(
        () => { this.validateAndClose(response.url); },
        500,
      );
      this.closeModal();
      this.$emit('input', response.url);
      this.$skToast({
        message: this.$t('employees.upload_avatar.upload_success'),
        variant: 'success',
      });
    },
    cropUploadFail() {
      this.sendingAvatar = false;
      this.$skToast({
        message: this.$t('employees.upload_avatar.upload_failure'),
        variant: 'error',
      });
    },
    handleSubmit() {
      this.sendingAvatar = true;
      this.$refs.cropUploader.prepareUpload();
    },
    deleteAvatar() {
      httpClient
        .delete(this.avatarPath)
        .then(() => {
          this.closeModal();
          this.$emit('input', '');
          this.$skToast({
            message: this.$t('employees.upload_avatar.delete_success'),
            variant: 'success',
          });
          this.imageShowing = false;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.upload_avatar.delete_failure'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// Override v2 bug that adds margin to all SVG
::v-deep svg {
  margin: 0;
}

.avatar-upload__wrapper {
  flex: 1;

  // Vue-image-crop-upload comes with its own modal
  // We change it to match design system
  ::v-deep .vue-image-crop-upload {
    position: static;

    .vicp-wrap {
      position: static;
      width: auto;
      height: auto;
      animation: 0ms;
      padding-top: 24px;
      padding-bottom: 5px;
      box-shadow: none;

      .vicp-error {
        height: auto;
      }

      .vicp-operate {
        display: none;
      }

      // Drop area
      .vicp-step1 .vicp-drop-area .vicp-hint {
        color: $sk-grey;
        line-height: 21px;
      }

      // Step with cropping and preview
      .vicp-step2 .vicp-crop {
        .vicp-crop-right .vicp-preview .vicp-preview-item {
          img {
            border: 1px solid $sk-blue;
          }

          span {
            bottom: -35px;
            color: $sk-grey;
          }
        }

        .vicp-crop-left .vicp-range {
          margin-bottom: 0;
        }
      }

      // loader during upload
      .vicp-step3 .vicp-upload .vicp-progress-wrap .vicp-progress {
        background-color: $sk-blue;
        box-shadow: 0 2px 6px 0 $sk-blue-5;

        &::after {
          background-color: $sk-blue;
          box-shadow: 0 2px 6px 0 $sk-blue-5;
        }
      }
    }

    .vicp-preview-item-circle {
      color: $sk-grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .vicp-close {
      display: none;
    }

    .vicp-preview {
      margin-right: 100px;
      margin-top: 30px;
    }
  }

  // Styling the range input: have to define each browser attributes
  // Does not work if put inside the block above
  ::v-deep .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range {
    input[type=range]::-moz-range-thumb {
      background: $sk-blue;
    }

    input[type=range]::-webkit-slider-thumb {
      background: $sk-blue;
    }

    input[type=range]::-ms-thumb {
      background: $sk-blue;
    }

    input[type=range]::-webkit-slider-runnable-track {
      background: $sk-blue-5;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: $sk-blue-5;
    }

    input[type=range]::-moz-range-track {
      background: $sk-blue-5;
    }

    input[type=range]::-ms-fill-lower {
      background: $sk-blue-5;
    }

    input[type=range]:focus::-ms-fill-lower {
      background: $sk-blue-5;
    }

    input[type=range]::-ms-fill-upper {
      background: $sk-blue-5;
    }

    input[type=range]:focus::-ms-fill-upper {
      background: $sk-blue-5;
    }
  }
}

.avatar-upload__body {
  display: flex;
}

.avatar-delete__wrap {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
}

.avatar-delete {
  position: relative;
}

.avatar-delete__photo {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.avatar-delete__text {
  color: $sk-grey;
  margin-top: 17px;
}

.sk-circle-button {
  position: absolute;
  border-radius: 100%;
  bottom: -4px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
