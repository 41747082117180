<template>
  <SkModal
    id="missing-intermediate-locked-days-modal"
    ref="missingIntermediateLockedDaysModal"
    class="missing-intermediate-locked-days-modal"
    :modal-title="$t('reports.modals.missing_intermediate_locked_days.title')"
    size="ds-medium"
    @close="closeModal"
  >
    <template #body>
      <SkModalSection border-bottom="none">
        <div class="missing-intermediate-locked-days-modal__description">
          <div class="missing-intermediate-locked-days-modal__lock">
            <SkMedallion
              icon="OpenLockIcon"
              color="#48BF33"
              size="x-small"
              background-color="#f3f3f3"
            />
          </div>
          <span>{{ $t('reports.modals.missing_intermediate_locked_days.description') }}</span>
        </div>
      </SkModalSection>
      <div class="missing-intermediate-locked-days-modal__section">
        <span
          class="missing-intermediate-locked-days-modal__bold-title"
        >
          {{ $t(
            'reports.modals.missing_intermediate_locked_days.period',
            {
              startDate: formatDate(startDate),
              endDate: formatDate(endDate)
            }) }}
        </span>
        <div
          v-for="(option, monthlyOptionsIndex) in monthlyOptions"
          :key="option.data"
        >
          <SkCollapse
            v-if="missingLockedDaysCount(option.intermediateLockedDays, monthlyOptionsIndex) > 0"
          >
            <template #labelLeft>
              <span
                class="missing-intermediate-locked-days-modal__bold-text"
                @click="redirectToPlanningWeek(option.date)"
              >
                {{ formattedWeekNumber(option.date) }}
              </span>
            </template>
            <template #labelRight>
              <span class="missing-intermediate-locked-days-modal__days-count">
                {{ missingDaysLabel(option.intermediateLockedDays, monthlyOptionsIndex) }}
              </span>
            </template>
            <template #content>
              <div
                v-for="day in formattedMissingDays(
                  option.date, option.intermediateLockedDays, monthlyOptionsIndex)"
                :key="day"
                class="missing-intermediate-locked-days-modal__day-row"
              >
                <CalendarV2Icon
                  width="18"
                  height="18"
                />
                <span class="missing-intermediate-locked-days-modal__day">{{ day }}</span>
              </div>
            </template>
          </SkCollapse>
        </div>
      </div>
      <SkModalSection border-bottom="none">
        <div class="missing-intermediate-locked-days-modal__email-wrapper">
          <span>{{ $t('reports.modals.missing_intermediate_locked_days.email_description') }}</span>
          <SkOroraButton v-modal.send-email-modal>
            {{ $t('reports.modals.missing_intermediate_locked_days.email_button') }}
          </SkOroraButton>
        </div>
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <SendEmailModal
            has-bcc
            :email-subject="$t('reports.modals.missing_intermediate_locked_days.email_subject')"
            :email-body="emailBody"
            :text-area-min-height="256"
            :text-area-max-height="256"
            :default-selected-user-licenses="defaultSelectedUserLicenses"
            only-read-user-licenses
            @show="closeModal"
          />
        </MountingPortal>
      </SkModalSection>
    </template>
    <template #footer>
      <div class="missing-intermediate-locked-days-modal__footer">
        <SkOroraButton
          variant="tertiary"
          @click="closeModal"
        >
          {{ $t('reports.modals.missing_intermediate_locked_days.back_to_report') }}
        </SkOroraButton>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { capitalize } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

import SendEmailModal from '@app-js/shared/components/SendEmailModal';

export default {
  name: 'MissingIntermediateLockedDaysModal',
  components: { SendEmailModal },
  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsState', ['monthlyOptions', 'shopPlanningConfig']),
    ...mapState('report', ['saasReportInfos']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('planningsState', ['daysOfWeekInPeriod', 'daysOfWeekInPeriodWithoutVisibleDays']),

    emailBody() {
      const intro = this.$t('reports.modals.missing_intermediate_locked_days.email_body_intro');
      const description = this.$t('reports.modals.missing_intermediate_locked_days.email_body_description');
      const missingDays = this.allMissingDays.flat().map(day => ` • ${day}\n`).join('');
      const signature = this.currentUser.attributes.firstName;
      return `${intro}${missingDays}\n${description}\n${signature}`;
    },
    allMissingDays() {
      return this.monthlyOptions
        .map((option, index) => this.formattedMissingDays(
          option.date, option.intermediateLockedDays, index,
        ));
    },
    defaultSelectedUserLicenses() {
      return ['advanced_planner', 'standard_planner'];
    },
  },
  methods: {
    formatDate(date) {
      return skDate(date).format('DD/MM/YYYY');
    },
    closeModal(event) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'missing-intermediate-locked-days-modal');
    },
    formattedWeekNumber(date) {
      const weekNumber = skDate(date).week();
      const week = this.$t('reports.modals.missing_intermediate_locked_days.week');
      return `${week} ${weekNumber}`;
    },
    // Transform each day of the period in a readable date
    //
    // This function is used in the context of weeklyOption
    // Param: monday [String] - first day of the week from weeklyOption
    // Param: days [Array(7)] - intermediateLockedDays from weeklyOption
    // Param: monthlyOptionsIndex [Integer] - the index of the week regarding the period fetched
    formattedMissingDays(monday, days, monthlyOptionsIndex) {
      const payload = {
        firstDay: this.startDate,
        lastDay: this.endDate,
        days,
        weeklyOptionIndex: monthlyOptionsIndex,
      };

      const intermediateLockedDaysToDate = this.daysOfWeekInPeriod(payload).map(
        (isDayLocked, index) => !isDayLocked &&
          capitalize(this.getMonday(monday).clone().add(index, 'days').format('dddd DD MMMM YYYY')),
      );

      const intermediateLockedDaysToDateWtoVisibleDays = this.daysOfWeekInPeriodWithoutVisibleDays(
        { ...payload, days: intermediateLockedDaysToDate },
      );

      return intermediateLockedDaysToDateWtoVisibleDays.filter(Boolean);
    },
    // Returns the number of days that are not intermediate locked
    // Takes into account the period and the visible days.
    missingLockedDaysCount(days, monthlyOptionsIndex) {
      const payload = {
        firstDay: this.startDate,
        lastDay: this.endDate,
        days,
        weeklyOptionIndex: monthlyOptionsIndex,
      };

      const daysInPeriod = this.daysOfWeekInPeriod(payload);

      return this.daysOfWeekInPeriodWithoutVisibleDays({
        ...payload, days: daysInPeriod,
      }).filter(day => !day).length;
    },
    // If the startDate of the period is after monday, use the startDate
    getMonday(monday) {
      const skMonday = skDate(monday);

      if (skMonday.isBefore(skDate(this.startDate))) {
        return skDate(this.startDate);
      }

      return skMonday;
    },
    missingDaysLabel(days, index) {
      return this.$tc(
        'reports.modals.missing_intermediate_locked_days.missing_days',
        this.missingLockedDaysCount(days, index),
      );
    },
    redirectToPlanningWeek(date) {
      this.$router.push({
        name: 'plannings_weeks_employees',
        query: { date },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.missing-intermediate-locked-days-modal {
  &__footer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    padding-top: 24px;
  }

  &__lock {
    padding-right: 8px;
  }

  &__description {
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    line-height: normal;
    align-items: center;
  }

  &__section {
    padding: 15px 0 5px;
    margin: 0 24px;
  }

  &__email-wrapper {
    display: flex;
    background-color: $sk-grey-5;
    flex-direction: column;
    padding: 16px;
    align-items: center;

    span {
      text-align: center;
      padding: 0 48px 16px;
    }
  }

  &__bold-title {
    font-weight: $fw-semi-bold;
  }

  &__bold-text {
    font-weight: $fw-semi-bold;
  }

  &__day-row {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    padding-left: 16px;
  }

  &__day {
    padding-left: 16px;
  }

  &__days-count {
    background-color: $sk-info-10;
    color: $sk-info;
    border-radius: 16px;
    padding: 4px 12px;
    margin-right: 16px;
  }
}
</style>
