<template>
  <InnerColumn
    id="paid-leaves-counter-month-column"
    :custom-column-classes="monthColumnClasses"
    :custom-title-classes="monthTitleClasses"
    :title="month.month"
    :show-asterisk="month.is_cp_acquisition_month_start"
    v-on="$listeners"
  >
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <EditPaidLeavesCounterModal
        :id="month.month"
        :date="month.end_month_display_date"
        :manual-changes="month.manual_changes"
        :old-counter="roundToTwoDecimals(totalWithChanges)"
      />
    </MountingPortal>
    <TableCell
      :class="cellClasses"
      :color="cellColor"
      :data="roundToTwoDecimals(month.pl_acquired)"
      :height="cellHeight"
      :show-dash="month.show_dash"
      only-positive
    />
    <TableCell
      :color="cellColor"
      :class="cellClasses"
      :data="roundToTwoDecimals(month.pl_taken)"
      :height="cellHeight"
      :show-dash="month.show_dash"
      only-positive
    />
    <TableCell
      v-if="hasYearlyDistinction"
      :class="cellClasses"
      :color="cellColor"
      :data="roundToTwoDecimals(previousYearTakenPaidLeaves)"
      :height="cellHeight"
      :show-dash="month.show_dash"
      only-positive
    />
    <TableCell
      v-if="hasYearlyDistinction"
      :class="cellClasses"
      :color="cellColor"
      :data="roundToTwoDecimals(currentYearTakenPaidLeaves)"
      :height="cellHeight"
      :show-dash="month.show_dash"
      only-positive
    />
    <TableCell
      :class="manualModificationCellClasses"
      :color="hasYearlyDistinction ? '#000000' : '#ff9600'"
      :data="roundToTwoDecimals(totalWithoutChanges)"
      :height="cellHeight"
      :show-dash="month.show_dash"
      show-number-sign
    />
    <TableCell
      :class="cellClasses"
      :color="cellColor"
      :data="roundToTwoDecimals(month.pl_manual_changes)"
      :editable="canReadPaidVacationCounter"
      :height="cellHeight"
      :tooltip="tooltipText"
      :show-dash="month.show_dash"
      show-number-sign
      @click="openModal"
    />
    <TableCell
      :class="manualModificationCellClasses"
      :color="hasYearlyDistinction ? '#000000' : '#ff9600'"
      :height="cellHeight"
      :data="roundToTwoDecimals(totalWithChanges)"
      :show-dash="month.show_dash"
      show-number-sign
    />
  </InnerColumn>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { roundFloat } from '@skello-utils/formatting/numbers';
import {
  InnerColumn,
  TableCell,
} from '../../shared/components';
import EditPaidLeavesCounterModal from '../EditPaidLeavesCounterModal';

export default {
  name: 'PaidLeavesCounterMonthColumn',
  components: { InnerColumn, TableCell, EditPaidLeavesCounterModal },
  props: {
    month: {
      type: Object,
      required: true,
    },
    previousMonth: {
      type: Object,
      default: () => {},
    },
    hasYearlyDistinction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canReadPaidVacationCounter']),
    tooltipText() {
      if (this.month.show_dash || !this.canReadPaidVacationCounter) return '';

      return this.$t('employees.tabs.counters.paid_leaves_counter.edit');
    },
    totalWithChanges() {
      return this.hasYearlyDistinction ?
        this.month.current_year_counter_balance_w_changes :
        this.month.pl_total_w_changes;
    },
    totalWithoutChanges() {
      return this.hasYearlyDistinction ?
        this.month.current_year_counter_balance_wo_changes :
        this.month.pl_total_wo_changes;
    },
    previousYearTakenPaidLeaves() {
      // No paid leave taken
      if (this.month.pl_taken === 0) return 0;

      // First month of the year
      if (!this.previousMonth) {
        // This means that the employee didn't impact the current year balance
        // so only previous year is impacted
        if (this.month.current_year_counter_balance_wo_changes === this.month.pl_acquired) {
          return this.month.pl_taken;
        }
        return this.month.pl_taken -
          (this.month.pl_acquired - this.month.current_year_counter_balance_wo_changes);
      }

      // The taken paid leave exceed the previous year balance so the balance is at 0
      // and we take everything
      if (this.month.pl_taken >= this.previousMonth.previous_year_counter_balance) {
        return this.previousMonth.previous_year_counter_balance;
      }
      return this.month.pl_taken;
    },
    currentYearTakenPaidLeaves() {
      // Everything that not taken on previous year is reported to the current year
      return this.month.pl_taken - this.previousYearTakenPaidLeaves;
    },
    monthColumnClasses() {
      if (this.hasYearlyDistinction) {
        return [];
      }

      return [{
        'paid-leaves-counter__month-column--open': !this.month.closed,
        'paid-leaves-counter__month-column--current': this.month.current_month,
        'paid-leaves-counter__month-column--previsional': this.month.previsional,
      }];
    },
    monthTitleClasses() {
      return this.hasYearlyDistinction ? [] : ['paid-leaves-counter__month-column__title'];
    },
    cellClasses() {
      return {
        'employee-counters__column--previsional': this.month.previsional,
      };
    },
    manualModificationCellClasses() {
      return {
        ...this.cellClasses,
        'paid-leaves-counter__month-column__cell--bold': !this.hasYearlyDistinction,
      };
    },
    cellHeight() {
      return this.hasYearlyDistinction ? '50px' : '60px';
    },
    cellColor() {
      return this.hasYearlyDistinction ? '#000000' : '#4d626f';
    },
  },
  methods: {
    openModal(event) {
      if (this.month.show_dash || !this.canReadPaidVacationCounter) return;

      this.$skAnalytics.track('manual_change_plc_counter');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, this.month.month);
    },
    roundToTwoDecimals(float) {
      return roundFloat(float, 2);
    },
  },
};
</script>

<style lang="scss">
// unscoped css to inject in child component
#paid-leaves-counter-month-column {
  .paid-leaves-counter__month-column__title {
    display: block;
    height: 83px;
    padding-top: 17px;
    color: $sk-grey;
    text-align: center;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
  }
}
</style>
<style lang="scss" scoped>
#paid-leaves-counter-month-column {
  &.paid-leaves-counter__month-column--open {
    transform: translateY(-10px);
    background: white;
  }

  &.paid-leaves-counter__month-column--current {
    &::before {
      content: '';
      position: absolute;
      background-color: $sk-error;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      top: -6px;
      left: -3px;
    }

    &::after {
      content: '';
      position: absolute;
      background-color: $sk-error;
      height: 382px;
      width: 2px;
      top: 0;
      left: -1px;
    }
  }

  .paid-leaves-counter__month-column__cell--bold {
    font-weight: $fw-semi-bold;
  }
}
</style>
