<template>
  <div :class="stickyBarClasses">
    <div class="sticky-bar__btn">
      <SkOroraButton
        v-track="trackingOptions.update"
        :loading="submitSpinner"
        :disabled="disabled"
        data-test="sticky-bar-submit__btn"
        @click="$emit('submit', $event)"
      >
        {{ submitButtonLabel }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StickyBar',
  props: {
    containerScrollId: {
      type: String,
      default: 'app-wrapper',
    },
    submitButtonLabel: {
      type: String,
      default: '',
    },
    submitSpinner: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trackingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isContainerFullyScrolled: false,
    };
  },
  computed: {
    stickyBarClasses() {
      return {
        'sk-sticky-bar': true,
        'sk-sticky-bar--up': this.visible,
        'sk-sticky-bar--down': !this.visible,
        'sk-sticky-bar--fully-scrolled': this.isContainerFullyScrolled,
      };
    },
  },
  mounted() {
    const container = this.getContainer();
    if (container) {
      container.addEventListener('scroll', this.toggleFullyScrolled);
    }
    this.toggleFullyScrolled();
  },
  destroyed() {
    const container = this.getContainer();
    if (container) {
      container.removeEventListener('scroll', this.toggleFullyScrolled);
    }
  },
  methods: {
    getContainer() {
      return document.getElementById(this.containerScrollId);
    },
    toggleFullyScrolled() {
      const container = document.getElementById(this.containerScrollId);

      this.isContainerFullyScrolled =
        container.scrollHeight === container.clientHeight + container.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-sticky-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  box-shadow: 0 -6px 24px rgba(0, 0, 0, .13);
  z-index: 3;
  transition: all ease-in .2s;
  overflow: hidden;

  .sticky-bar__btn {
    padding: 15px;
  }

  &.sk-sticky-bar--fully-scrolled {
    box-shadow: none;
  }

  &.sk-sticky-bar--up {
    opacity: 1;
    margin-top: 0;
    height: 70px;
  }

  &.sk-sticky-bar--down {
    opacity: 0;
    height: 0;
  }
}
</style>
