import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import {
  associationMatcherCollection,
  associationMatcherItem,
} from '@skello-utils/association_matchers';
import { filterUsers } from '@app-js/shared/utils/filters_helper';
import skDate from '@skello-utils/dates';
import { fetchInChunks } from '@skello-utils/batches';

const ENDPOINT_NAMESPACE = '/v3/api/plannings';

const initialState = {
  users: [],
  availabilities: [],
  daysWorked: {},
  usersLoading: false,
  availabilitiesLoading: false,
  error: null,
  weeklyTotalDifferenceByUser: {},
  totalNumberOfUsers: 0,
};

const matchRelationshipToUsers = (user, included) => {
  const highestLicense = associationMatcherItem(user, included, { key: 'highestLicense', type: 'license' });
  const contract = associationMatcherItem(user, included, { key: 'contract', type: 'contract' });
  const userRecurrentShifts = associationMatcherCollection(user, included, { key: 'userRecurrentShifts', type: 'userRecurrentShifts' });
  const relationships = { contract, highestLicense };

  if (userRecurrentShifts) {
    const recurrentShiftsDesc = userRecurrentShifts.sort(
      (urs, urs1) => urs1.attributes.occurence - urs.attributes.occurence,
    );

    relationships.userRecurrentShifts = Object.groupBy(
      recurrentShiftsDesc,
      ({ attributes }) => attributes.isoWeekDay,
    );
  }

  Object.assign(user.relationships, relationships);

  return user;
};

const mutations = {
  setPlanningUsers(state, { users, overwriteStore = true }) {
    const parsedUsers = users.data.map(user => matchRelationshipToUsers(user, users.included));
    if (overwriteStore) {
      state.users = parsedUsers;
      state.totalNumberOfUsers = users.meta?.pagination?.total_count || state.users.length;
      return;
    }

    state.users = [...state.users, ...parsedUsers];
  },
  setAvailabilities(state, { data, overwriteStore = true }) {
    data.forEach(({ attributes }) => {
      // Unlike shifts availabilitites are stored with timezone offset applied but in UTC0.
      // Example: user's browser in GMT+2 creates an availability selecting start at 0h00
      // it will be stored in database as 22h00 of the previous day in UTC0
      // Not using skDate.utc() here is a workaround
      const startsAt = skDate(attributes.startsAt);
      const endsAt = skDate(attributes.endsAt);
      attributes.startTime = startsAt.format('HH:mm');
      attributes.endTime = endsAt.format('HH:mm');
      attributes.dayStr = startsAt.format('YYYY-MM-DD');
    });
    state.availabilities = overwriteStore ? data : [...state.availabilities, ...data];
  },
  setDaysWorked(state, { daysWorked, overwriteStore = true }) {
    state.daysWorked = overwriteStore ? daysWorked : { ...state.daysWorked, ...daysWorked };
  },
  setError(state, error) {
    state.error = error;
  },
  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },
  setWeeklyTotalDifferenceFor(state, { userId, diff }) {
    Vue.set(state.weeklyTotalDifferenceByUser, userId, diff);
  },
};

const actions = {
  fetchPlanningUsers({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest', 'usersLoading');

    return httpClient
      .get(`${ENDPOINT_NAMESPACE}/users`, { params })
      .then(response => {
        commit('setPlanningUsers', { users: response.data, overwriteStore });
        return response;
      })
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'usersLoading'));
  },
  async fetchAvailabilities({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest', 'availabilitiesLoading');

    try {
      const accumulatedData = await fetchInChunks(params, `${ENDPOINT_NAMESPACE}/availabilities`);

      commit('setAvailabilities', { ...accumulatedData, overwriteStore });
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'availabilitiesLoading');
    }
  },
  sortUsers({ commit }, params) {
    commit('performingRequest', 'usersLoading');

    return httpClient
      .patch('/v3/api/plannings/users/sort_users', params)
      .then(response => response)
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'usersLoading'));
  },
  async fetchDayRateUsersDaysWorked({ commit }, { params, overwriteStore = true }) {
    commit('performingRequest');

    try {
      const accumulatedData = await fetchInChunks(params, `${ENDPOINT_NAMESPACE}/day_rate_total`);

      commit('setDaysWorked', { daysWorked: accumulatedData, overwriteStore });
      return accumulatedData;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete');
    }
  },
};

const storeGetters = {
  availabilitiesForUser: state => userId => state.availabilities.filter(availability => (
    availability.attributes.userId === parseInt(userId, 10)
  )),

  getShiftsForFilter: (_state, _selfGetters, _rootState, rootGetter) => {
    let shifts = rootGetter['planningsShifts/shiftsForCurrentWeek'];
    if (rootGetter['planningsState/isDailyView']) {
      const currentDate = rootGetter['planningsState/currentDate'];
      shifts = rootGetter['planningsShifts/dayCellShifts'](currentDate, shifts);
    } else if (rootGetter['planningsState/isMonthlyView']) {
      shifts = rootGetter['planningsShifts/monthlyShifts'];
    }

    return shifts;
  },
  // duplicate of filteredUsers in order to get users with pending leave request
  // but to not impact more than planning displayed user
  // and not other features dependent of the users in planning

  displayedInPlanningUsers: (state, getters, rootState) => {
    const { filters } = rootState.planningsState;
    const { users } = state;
    const shifts = getters.getShiftsForFilter;

    return filterUsers(
      filters, users, shifts.concat(rootState.planningsShifts.pendingLeaveRequestShifts),
    );
  },
  filteredUsers: (state, getters, rootState) => {
    const { filters } = rootState.planningsState;
    const { users } = state;
    const shifts = getters.getShiftsForFilter;

    return filterUsers(filters, users, shifts);
  },
  dayFilteredUsers: (state, _getters, rootState, rootGetter) => date => {
    const { filters } = rootState.planningsState;
    const { users } = state;
    const shifts = rootGetter['planningsShifts/shiftsForCurrentPeriod'];
    const dayShifts = rootGetter['planningsShifts/dayCellShifts'](date, shifts);

    return filterUsers(filters, users, dayShifts);
  },
  birthdayUsersAtDay: state => day => {
    const date = skDate(day);
    return state.users
      .filter(user => {
        const userBirthday = skDate(user.attributes.birthday);
        return userBirthday.date() === date.date() && userBirthday.month() === date.month();
      });
  },
  totalNumberOfUsers(state, _getters, _rootState, rootGetters) {
    const isProgressiveLoadingEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_NEW_PROGRESSIVE_FETCHING_STORE');

    if (isProgressiveLoadingEnabled) return state.totalNumberOfUsers;

    return state.users.length;
  },
  planningRowsCount: (state, getters, rootState, rootGetters) => {
    if (state.usersLoading) {
      return 0;
    }

    const userLength = getters.displayedInPlanningUsers.length;
    if (rootState.planningsState.isPostesView) {
      return userLength;
    }
    const isUnassignedShiftsAllowed = rootState.planningsState.shopPlanningConfig
      .attributes.allowUnassignedShifts;
    const hasUnassignedShifts = rootGetters['planningsShifts/unassignedShifts']?.length > 0;
    const isUnassignedFeatureEnabled = rootGetters['currentOrganisation/checkPackOfferFlag']('unassigned_shifts_enabled');

    const showUnassignedRow =
      (isUnassignedShiftsAllowed || hasUnassignedShifts) &&
      isUnassignedFeatureEnabled;

    return showUnassignedRow ? userLength + 1 : userLength;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: storeGetters,
};
