<template>
  <div class="horizontal-scroll-table__wrapper">
    <slot name="headers-column" />
    <div
      ref="horizontalScrollTable"
      :style="{ width: tableWidth }"
      :class="horizontalScrollTableClasses"
    >
      <div class="horizontal-scroll-table__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'HorizontalScrollTable',
  props: {
    customClasses: {
      type: Array,
      default: null,
    },
    customScrollingClasses: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      tableWidth: null,
      horizontallyScrolled: null,
    };
  },
  computed: {
    horizontalScrollTableClasses() {
      if (this.customClasses) {
        return [
          ...this.customClasses,
          { [this.customScrollingClasses]: this.horizontallyScrolled },
        ];
      }

      return {
        'horizontal-scroll-table__content-scroll': true,
        'horizontal-scroll-table__content-scroll--scrolling': this.horizontallyScrolled,
      };
    },
  },
  mounted() {
    // Init table sizing and scrolling feature
    this.$nextTick(() => {
      this.setTableWidth();
      this.simplebar = new SimpleBar(this.$refs.horizontalScrollTable);
      this.simplebar.getScrollElement().addEventListener('scroll', () => {
        this.horizontallyScrolled = this.hasLeftOffset();
      });
      window.addEventListener('resize', this.handleWindowResize);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    scrollTo(target) {
      const targetCoordinate = target.getClientRects()[0].left;
      const offsetCoordinate = this.$refs.horizontalScrollTable.getClientRects()[0].left;

      this.simplebar.getScrollElement().scrollTo({
        left: targetCoordinate - offsetCoordinate - 270,
        behavior: 'smooth',
      });
    },
    // eslint-disable-next-line func-names
    handleWindowResize: debounce(function () {
      this.setTableWidth();
    }, 300),
    setTableWidth() {
      const table = this.$refs.horizontalScrollTable;
      this.tableWidth = `${table.parentElement.clientWidth - (table.offsetLeft + 20)}px`;
    },
    // This is a method and not a computed as we need to re-evaluate $refs every time ($refs is non reactive)
    hasLeftOffset() {
      const tableLeft = this.simplebar.getContentElement().firstChild.getClientRects()[0].left;
      const offset = this.$refs.horizontalScrollTable.getClientRects()[0].left;
      return tableLeft < offset;
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-scroll-table__wrapper {
  background-color: white;
}

.horizontal-scroll-table__content {
  display: flex;
  height: fit-content;
  background: white;
}

.horizontal-scroll-table__content-scroll {
  position: absolute;
  bottom: 0;
  left: 306px;
  padding-left: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  &.horizontal-scroll-table__content-scroll--scrolling::after {
    top: 40px;
    left: 0;
    position: absolute;
    content: '';
    width: 12px;
    height: 426px;
    background: linear-gradient(to right, rgba(0, 0, 0, .05) 0%, rgba(0, 0, 0, .001) 100%);
  }
}
</style>
