<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="92"
    height="99"
    viewBox="0 0 92 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3562_426)">
      <g clip-path="url(#clip0_3562_426)">
        <rect
          x="16"
          y="19.6248"
          width="60"
          height="54.9999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <path
          d="M16 20.8748C16 20.1844 16.5596 19.6248 17.25 19.6248H71C71.6904 19.6248 72.25 20.1844 72.25 20.8748V70.8747C72.25 71.565 71.6904 72.1247 71 72.1247H16V20.8748Z"
          fill="#F2F8FF"
        />
        <rect
          x="40.375"
          y="38.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="30.3774"
          y="38.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="50.3726"
          y="38.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="60.3774"
          y="38.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="20.3726"
          y="48.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="30.3774"
          y="48.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="40.375"
          y="48.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="50.3726"
          y="48.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="60.3774"
          y="48.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="20.3726"
          y="58.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="30.3774"
          y="58.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="40.375"
          y="58.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <rect
          x="50.3726"
          y="58.9998"
          width="7.5"
          height="7.49999"
          rx="1.25"
          fill="#B7D9FF"
        />
        <path
          d="M16 20.8748C16 20.1844 16.5596 19.6248 17.25 19.6248H74.75C75.4404 19.6248 76 20.1844 76 20.8748V34.6247H16V20.8748Z"
          fill="#E12F57"
        />
        <ellipse
          cx="30.0625"
          cy="23.9998"
          rx="3.75"
          ry="3.75"
          fill="white"
        />
        <ellipse
          cx="61.9375"
          cy="23.9998"
          rx="3.75"
          ry="3.75"
          fill="white"
        />
        <rect
          x="28.1875"
          y="14.9375"
          width="3.75"
          height="11.25"
          rx="1.875"
          fill="#6D7D8C"
        />
        <rect
          x="60.0625"
          y="14.9375"
          width="3.75"
          height="11.25"
          rx="1.875"
          fill="#6D7D8C"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_3562_426"
        x="-14"
        y="-8"
        width="120"
        height="120"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 0.882353 0 0 0 0 0.956863 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3562_426"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3562_426"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3562_426">
        <rect
          width="60"
          height="61.2499"
          fill="white"
          transform="translate(16 13.3748)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CalendarIcon',
};
</script>
