<template>
  <SkSelectV2
    v-model="selectIds"
    :disabled="disableSelect"
    :options="posteAndAbsenceOptions"
    :group-options="groupOptions"
    :label="$t('plannings.toolbar.modal.shared.select.postes_and_absences')"
    :search-placeholder="$t('actions.search')"
    :no-results-label="$t('plannings.toolbar.modal.shared.select.no_search_results')"
    width="320px"
    multi
    append-to-body
    no-confirm
  >
    <template #group-count="{ count }">
      {{ $tc('plannings.toolbar.modal.shared.select.postes_and_absences_count', count) }}
    </template>
    <template #selected-option="{ value }">
      {{ $tc('plannings.toolbar.modal.shared.select.postes_and_absences_count', value.length) }}
    </template>
  </SkSelectV2>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PosteAndAbsenceSelector',
  props: {
    selectedIds: {
      type: Array,
      required: true,
    },
    posteAndAbsenceOptions: {
      type: Array,
      required: true,
    },
    disableSelect: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsPostes', ['absences', 'postes']),
    selectIds: {
      get() {
        return this.selectedIds;
      },
      set(newSelectedIds) {
        this.$emit('select-postes-and-absences', { selectedPosteAndAbsenceIds: newSelectedIds });
      },
    },
    groupOptions() {
      return [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];
    },
  },
};
</script>
