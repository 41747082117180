<template>
  <SkSidePanel
    id="attendance-sheets-esignature-panel"
    ref="AttendanceSheetsEsignatureSidePanel"
    size="ds-medium"
    medallion-content-component="SignIcon"
    medallion-background-color="#f3f3f3"
    medallion-content-color="#727272"
    :title="$t('attendance_sheets_esignature.header.title')"
    :subtitle="sidebarSubtitle"
    @closed="handleClose"
  >
    <template #body>
      <SkNav>
        <SkNavItem
          data-test="es-tab-send"
          :active="isStep('send')"
          @click="setStep('send')"
        >
          {{ $t('attendance_sheets_esignature.tabs.send') }}
        </SkNavItem>
        <SkNavItem
          data-test="es-tab-tracking"
          :active="isStep('follow-up')"
          :disabled="isWeeklyAttendanceSheetSignaturesEmpty"
          @click="setStep('follow-up')"
        >
          <span v-tooltip="followTabTooltipText">
            {{ $t('attendance_sheets_esignature.tabs.follow') }}
          </span>
        </SkNavItem>
      </SkNav>
      <SendStep
        v-if="showSidebar && isStep('send')"
        :is-planning-data-completed="isPlanningDataCompleted"
        @close="handleCloseFromStep"
      />
      <FollowUpStep
        v-if="showSidebar && isStep('follow-up')"
        :is-planning-data-completed="isPlanningDataCompleted"
        @close="handleCloseFromStep"
      />
    </template>
  </SkSidePanel>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import {
  SIDEPANEL_SHOW_EVENT, SIDEPANEL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';

import SendStep from './SendStep';
import FollowUpStep from './FollowUpStep';

export default {
  name: 'AttendanceSheetsEsignatureSidePanel',
  components: {
    SendStep,
    FollowUpStep,
  },
  data() {
    return {
      step: 'send',
      showSidebar: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', [
      'currentDate',
      'monday',
      'sunday',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapState('planningsState', ['weeklyAttendanceSheetSignatures']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsShifts', ['shifts']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted', 'isFirstBatchLoaded']),
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
    displayWeek() {
      return skDate(this.currentDate).isoWeek();
    },
    displayMonday() {
      return skDate(this.monday).format('DD MMM');
    },
    displaySunday() {
      return skDate(this.sunday).format('DD MMM YYYY');
    },
    isWeeklyAttendanceSheetSignaturesEmpty() {
      return this.weeklyAttendanceSheetSignatures.length === 0;
    },
    sidebarSubtitle() {
      return this.$t('attendance_sheets_esignature.header.subtitle', {
        first_day: this.displayMonday,
        last_day: this.displaySunday,
        week: this.displayWeek,
      });
    },
    followTabTooltipText() {
      if (!this.isWeeklyAttendanceSheetSignaturesEmpty) return '';

      return this.$t('attendance_sheets_esignature.tabs.follow_unaccessible_tooltip');
    },
  },
  mounted() {
    this.listenOnRoot('open-esignature', this.handleShow);
  },
  methods: {
    isStep(step) {
      return this.step === step;
    },
    setStep(step) {
      if (step === 'follow-up' && this.isWeeklyAttendanceSheetSignaturesEmpty) return;

      this.step = step;
    },
    handleShow() {
      if (this.showSidebar) return;

      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-all-planning-data');
      }

      this.showSidebar = true;
      this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'attendance-sheets-esignature-panel');
      this.$skAnalytics.track('esignature_schedule_panel_open');

      this.setStep(this.isWeeklyAttendanceSheetSignaturesEmpty ? 'send' : 'follow-up');
    },
    handleClose() {
      if (!this.showSidebar) return;
      this.showSidebar = false;
    },
    handleCloseFromStep() {
      this.$root.$emit(SIDEPANEL_HIDE_EVENT, 'attendance-sheets-esignature-panel');
    },
  },
};
</script>

<style lang="scss" scoped>
// to remove the default horizontal padding on panel body
::v-deep .sk-sidepanel__body {
  padding: 10px 0 !important;
}

::v-deep .sk-sidepanel__body--no-footer {
  min-height: calc(100vh - 84px) !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
}

// design spec to have a different padding on nav items
::v-deep .sk-nav {
  padding-left: 25px;
}

.attendance-sheets-esignature-panel__header {
  display: flex;
}

.attendance-sheets-esignature-panel__header__title {
  line-height: 22px;
  margin-top: 2px;
}

.attendance-sheets-esignature-panel__header__medaillon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-right: 15px;
  background: $sk-grey-10;
}
</style>
