<template>
  <img
    width="38"
    src="./images/logo-inexpaie.jpg"
  >
</template>

<script>
export default {
  name: 'Inexpaie',
};
</script>
