import { AVAILABILITY_RECURRENCE_WEEK } from '@app-js/shared/constants/availability';

export function availabilitiesForDay(availabilities, { date, weekDay }) {
  // Check if availability matches the date or is recurring and on the same week day
  return availabilities.filter(({ attributes }) => (
    ((
      attributes.recurrence === AVAILABILITY_RECURRENCE_WEEK &&
      attributes.dayOfWeek === weekDay
    ) ||
    (
      attributes.recurrence !== AVAILABILITY_RECURRENCE_WEEK &&
      attributes.dayStr === date
    ))
  ));
}
