<template>
  <img
    width="60"
    src="./images/logo-laddition.svg"
  >
</template>

<script>
export default {
  name: 'Laddition',
};

</script>
