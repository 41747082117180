<template>
  <div>
    <div
      v-if="!isSvcCommunicationsV2Enabled"
      class="title"
    >
      {{ $t('plannings.toolbar.modal.publish_planning.notification.section_title.label') }}
      <CircledQuestionMarkIcon
        v-tooltip.bottom="$t('plannings.toolbar.modal.publish_planning.notification.section_title.tooltip')"
        class="icon"
        width="18"
        height="18"
        color="#727272"
      />
    </div>
    <div :class="emailClasses">
      <div class="notification-row notification-row--multiline">
        <span class="label">
          {{ isSvcCommunicationsV2Enabled ? $t('plannings.toolbar.modal.publish_planning.notification.send_by_email') : $t('plannings.toolbar.modal.publish_planning.notification.email') }}
        </span>
        <span>
          <SkSwitch
            :value="email"
            data-test="publish-planning-modal__email-switch"
            @input="$emit('update:email', $event)"
          />
        </span>
      </div>
      <div
        v-if="email"
        class="notification-row notification-row--multiline"
      >
        <div class="email-label">
          <div class="">
            {{ $t('plannings.toolbar.modal.publish_planning.notification.add_comment') }}
          </div>
        </div>
        <span class="notifcation-row__comment">
          <SkTextarea
            :value="emailAdditionalComment"
            :label="$t('plannings.toolbar.modal.publish_planning.notification.comment_label')"
            :min-height="54"
            rows="3"
            auto-grow
            @input="$emit('update:emailAdditionalComment', $event)"
          />
        </span>
      </div>
    </div>
    <div v-if="isSvcCommunicationsV2Enabled">
      <div class="notification-row notification-row--multiline">
        <span class="label">
          {{ $t('plannings.toolbar.modal.publish_planning.notification.send_by_sms_or_notification') }}
        </span>
        <span>
          <SkSwitch
            :value="sms"
            data-test="publish-planning-modal__sms-switch"
            @input="$emit('update:sms', $event)"
          />
        </span>
      </div>
    </div>
    <div v-else>
      <div class="notification-row">
        <span class="label">
          {{ $t('plannings.toolbar.modal.publish_planning.notification.sms') }}
        </span>
        <span>
          <SkSwitch
            :value="sms"
            data-test="publish-planning-modal__sms-switch"
            @input="$emit('update:sms', $event)"
          />
        </span>
      </div>
      <div class="notification-row">
        <span class="label center">
          {{ $t('plannings.toolbar.modal.publish_planning.notification.mobile_app.label') }}
          <CircledQuestionMarkIcon
            v-tooltip.bottom="$t('plannings.toolbar.modal.publish_planning.notification.mobile_app.tooltip')"
            class="icon"
            width="18"
            height="18"
            color="#727272"
          />
        </span>
        <span>
          <SkSwitch
            value
            disabled
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationOptions',
  props: {
    email: {
      type: Boolean,
      required: true,
    },
    sms: {
      type: Boolean,
      required: true,
    },
    emailAdditionalComment: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    isSvcCommunicationsV2Enabled() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_SVC_COMMUNICATIONS_V2');
    },
    emailClasses() {
      return {
        'email-row': true,
        'email-row--multiline': this.isSvcCommunicationsV2Enabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 21px;
  margin-bottom: 5px;
  font-weight: $fw-bold;
  display: flex;
  align-items: center;
}

.email-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $sk-grey-10;

  &--multiline {
    border-bottom: none;
  }
}

.notification-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
  border-bottom: 1px solid $sk-grey-10;

  &--multiline {
    border-bottom: none;
  }
}

.notifcation-row__comment {
  width: 320px;
  margin-bottom: 20px;
}

.label {
  color: $sk-grey;

  &.center {
    display: flex;
    align-items: center;
  }
}

.email-label {
  color: $sk-grey;
  min-height: 70px;
}

.icon {
  margin-left: 13px;
}
</style>
