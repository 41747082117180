<template>
  <div
    :class="columnClasses"
    @click="$emit('click', $event)"
  >
    <div
      v-tooltip.auto="tooltip"
      :class="titleClasses"
    >
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InnerColumn',
  props: {
    isClosed: {
      type: Boolean,
      default: false,
    },
    showAsterisk: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    customTitleClasses: {
      type: Array,
      default: () => [],
    },
    customColumnClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columnClasses() {
      return [
        ...this.customColumnClasses,
        { 'inner-column--open': !this.isClosed },
      ];
    },
    titleClasses() {
      return [
        ...this.customTitleClasses,
        'inner-column__title',
        { 'inner-column__title--star': this.showAsterisk },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-column--open {
  box-shadow: 0 0 0;
  border-right: solid 1px rgba(0, 0, 0, .08);

  &:last-child {
    border-right: none;
  }
}

.inner-column__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  color: $sk-grey;
  font-size: $fs-text-s;
}

.inner-column__title--star::after {
  color: $sk-blue;
  content: '*';
}
</style>
