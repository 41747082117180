<template>
  <div :class="summarySectionClasses">
    <span class="employee-rtt-counter__summary-section__title">
      {{ periodSummary.title }}
    </span>
    <SkColumnInfoCard
      :is-loading="isLoading"
      :columns="computedPeriodSummary"
      :variant="periodSummary.isCurrentPeriod ? 'blue' : 'primary'"
      class="employee-rtt-counter__summary-section__card"
    />
    <div
      v-if="displayWarning"
      class="employee-rtt-counter__summary-section__info"
    >
      <CircledIIcon
        fill="#f0900d"
        class="employee-rtt-counter__summary-section__info__icon"
      />
      {{ warningMessage }}
    </div>
  </div>
</template>

<script>
import { nlToBr } from '@skello-utils/formatting/strings';
import { roundFloat } from '@skello-utils/formatting/numbers';

export default {
  name: 'PeriodSummariesSection',
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    periodSummary: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    displayWarning() {
      return this.periodSummary.balance <= 0;
    },
    computedPeriodSummary() {
      const { balance, manualChangesCount, owedRtt, takenRtt } = this.periodSummary;

      return [
        {
          content: roundFloat(owedRtt + manualChangesCount, 2),
          title: this.$t('employees.tabs.counters.rtt_counter.summary_section.owed_rtt.title'),
          tooltip: this.owedTooltip,
        },
        {
          content: roundFloat(takenRtt, 2),
          title: this.$t('employees.tabs.counters.rtt_counter.summary_section.taken_rtt'),
        },
        {
          content: roundFloat(balance, 2),
          title: this.$t('employees.tabs.counters.rtt_counter.summary_section.remaining_balance'),
        },
      ];
    },
    owedTooltip() {
      let tooltip = this.$t(
        'employees.tabs.counters.rtt_counter.summary_section.owed_rtt.tooltip.label',
        { owed_rtt: roundFloat(this.periodSummary.owedRtt, 2) },
      );

      if (this.periodSummary.manualChangesCount > 0) {
        tooltip += nlToBr(this.$t(
          'employees.tabs.counters.rtt_counter.summary_section.owed_rtt.tooltip.manual_changes',
          { manual_changes: this.periodSummary.manualChangesCount },
        ));
      }

      if (this.periodSummary.reducedRtt > 0) {
        tooltip += nlToBr(this.$t(
          'employees.tabs.counters.rtt_counter.summary_section.owed_rtt.tooltip.reduced_rtt',
          { reduced_rtt: roundFloat(this.periodSummary.reducedRtt, 2) },
        ));
      }

      return tooltip;
    },
    summarySectionClasses() {
      return {
        'employee-rtt-counter__summary-section': true,
        'employee-rtt-counter__summary-section--display-warning': this.displayWarning,
      };
    },
    warningMessage() {
      return this.$t(this.periodSummary.balance < 0 ?
        'employees.tabs.counters.rtt_counter.summary_section.warning_message.negative' :
        'employees.tabs.counters.rtt_counter.summary_section.warning_message.none',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-rtt-counter__summary-section {
  display: flex;
  flex-direction: column;
  padding: 40px 24px 26px;
  background: $sk-white;

  &__title {
    font-weight: $fw-semi-bold;
    margin-bottom: 8px;
    line-height: normal;
  }

  &__card {
    height: 100%;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: 4px;
    font-size: $fs-text-m;
    color: $sk-warning;

    &__icon {
      margin-right: 8px;
    }
  }

  &--display-warning {
    padding-bottom: 16px;
  }
}
</style>
