<template>
  <div :class="navbarClasses">
    <SkelloLinkedLogo />

    <template v-if="!isEmployee">
      <ClusterNavigationDropdown v-if="showClusterNavigation" />
      <ShopsDropdown v-else />
    </template>
    <ShopHeader
      v-else-if="isTeamScheduleView && currentShop.id !== 'all'"
      class="navbar__wrapper--shop-header"
      :shop-name="currentShop.attributes.name"
    />
    <div
      v-if="!isLoading"
      class="navbar__links__wrapper"
      :style="{ 'margin-left': isEmployee ? '32px' : '0' }"
    >
      <DotLink
        v-if="isFeatureEnabled(FEATURES.FEATURE_PLANNING, currentShop.id, () => true)"
        :anchor-text="$t('navbar.planning')"
        :active="$route.matched.some(({ name }) => name === 'plannings')"
        :to="planningUrl"
        class="navbar__links__element"
        data-test="navbar__planning"
      />
      <DotLink
        v-if="isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES, currentShop.id, () => true) &&
          canAccessEmployeesView"
        :anchor-text="$t('navbar.employees')"
        :active="$route.matched.some(({ name }) => name === 'users')"
        :to="employeesRouterUrl"
        class="navbar__links__element"
        data-test="navbar__employee"
      />
      <DotLink
        v-else-if="isFeatureEnabled(FEATURES.FEATURE_PROFILE, currentShop.id, () => true)"
        :anchor-text="$t('navbar.employee_profile')"
        :to="{ name: 'profile_information' }"
        class="navbar__links__element"
        data-test="navbar__profile"
      />
      <DotLink
        v-if="isFeatureEnabled(FEATURES.FEATURE_REPORT, currentShop.id, () => true)
          && canAccessReportView"
        :anchor-text="$t('navbar.report')"
        :to="reportUrl"
        class="navbar__links__element"
        data-test="navbar__report"
      />
      <span
        v-if="canAccessPunchClockView && showPunchClockCrownIcon"
        class="feature__upsell__icon"
        data-test="punch-clock-crown-upsell-icon"
      >
        <CrownIcon />
      </span>
      <DotLink
        v-if="isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK, currentShop.id, () => true) &&
          canAccessPunchClockView"
        :anchor-text="$t('navbar.punch_clock')"
        :active="$route.matched.some(({ name }) => name === 'badgings')"
        :to="punchClockUrl"
        class="navbar__links__element"
        data-test="navbar__punch-clock"
      />
      <span
        v-if="isFeatureEnabled(FEATURES.FEATURE_ANALYTICS, currentShop.id, () => true) &&
          canAccessAnalyticsDashboardView && showAnalyticsDashboardCrownIcon"
        class="feature__upsell__icon"
      >
        <CrownIcon />
      </span>
      <DotLink
        v-if="isFeatureEnabled(FEATURES.FEATURE_ANALYTICS, currentShop.id, () => true) &&
          canAccessAnalyticsDashboardView"
        :anchor-text="$t('navbar.analytics_dashboard')"
        :to="analyticsDashboardUrl"
        class="navbar__links__element"
        data-test="navbar__analytics-dashboard"
      />
    </div>
    <Actions />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import ShopHeader from '@app-js/shared/components/Navbar/ShopHeader';
import { FEATURES } from '@app-js/shared/constants/features.js';
import CrownIcon from '@app-js/shared/assets/svg/upsell/CrownIcon';
import SkelloLinkedLogo from './SkelloLinkedLogo';
import ClusterNavigationDropdown from './ClusterNavigationDropdown/index';
import ShopsDropdown from './ShopsDropdown';
import DotLink from './DotLink';
import Actions from './Actions';

export default {
  name: 'Navbar',
  components: {
    SkelloLinkedLogo,
    ShopHeader,
    CrownIcon,
    ShopsDropdown,
    DotLink,
    Actions,
    ClusterNavigationDropdown,
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('config', ['config']),
    ...mapGetters('currentLicense', ['isSystemAdmin', 'isEmployee']),
    ...mapGetters('currentOrganisation', ['atLeastOneShopWithPunchClock']),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled', 'isBundleSystemActive']),
    isProgressiveLoadingEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_NEW_PROGRESSIVE_FETCHING_STORE');
    },
    isLoading() {
      return !this.currentLicense.id;
    },
    showClusterNavigation() {
      return this.currentOrganisation.attributes.clusters;
    },
    showPunchClockCrownIcon() {
      return this.navContext.viewAllShops ?
        !this.atLeastOneShopWithPunchClock :
        !this.isBundleSystemActive(this.currentShop.id) &&
          this.currentShop.attributes &&
          !this.currentShop.attributes.hasPunchClock;
    },
    showAnalyticsDashboardCrownIcon() {
      return !this.currentOrganisation.attributes.analyticsModule;
    },
    reportUrl() {
      const startDate = skDate().startOf('month').format('YYYY-MM-DD');
      const endDate = skDate().endOf('month').format('YYYY-MM-DD');

      // Route names defined in app/javascript/src/v3/app/reports/reports_routes.js
      const viewAll = this.currentShop.id === 'all';

      const routerQuery = {
        name: 'reports',
        params: {
          shop_id: this.currentShop.id,
        },
        query: {
          start_date: startDate,
          end_date: endDate,
        },
      };

      // view all needs clusterNodeId in router params
      if (viewAll) {
        routerQuery.query.cluster_node_id = this.navContext.clusterNodeId;
      }

      return routerQuery;
    },
    planningUrl() {
      if (this.isEmployee) {
        return { name: 'plannings_employee' };
      }

      const shopId = this.isAllShopsView && this.currentUser.attributes.canManageAffiliatedShop ?
        this.currentUser.attributes.shopId.toString() :
        this.currentShop.id;

      const query = { date: this.redirectDate };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids;
      }

      return {
        name: 'plannings',
        params: { shop_id: shopId },
        query,
      };
    },
    isAllShopsView() {
      return this.navContext.viewAllShops || this.navContext.viewIntermediateNodes;
    },
    isTeamScheduleView() {
      return this.$route.name === 'plannings_employee_team_schedule';
    },
    punchClockUrl() {
      const shopId = this.isAllShopsView ?
        // View 'all' without at least one shop with punch clock.
        // Show punch clock upsell modal on first shop of organisation.
        this.firstPunchClockShopId || this.firstShopId :
        this.currentShop.id;

      const query = { date: this.redirectDate };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids;
      }

      return {
        name: 'badgings',
        params: {
          shop_id: shopId,
          hasPunchClock: this.isAllShopsView && this.atLeastOneShopWithPunchClock,
        },
        query,
      };
    },
    analyticsDashboardUrl() {
      return {
        name: 'analytics_dashboard',
        params: {
          shop_id: this.currentShop.id,
        },
      };
    },
    firstShopId() {
      return this.currentOrganisation.attributes.firstShopId;
    },
    firstPunchClockShopId() {
      return this.currentOrganisation.attributes.firstPunchClockShopId;
    },
    canAccessEmployeesView() {
      return this.currentLicense.attributes.canReadEmployeeInfo;
    },
    canAccessReportView() {
      return this.currentLicense.attributes.canReadReport;
    },
    canAccessPunchClockView() {
      return this.currentLicense.attributes.canReadPunchClockData;
    },
    canAccessAnalyticsDashboardView() {
      return this.isAnalyticsDashboardTabDisplayed &&
        this.currentLicense.attributes.canEditManagementIndicators;
    },
    isAnalyticsDashboardTabDisplayed() {
      if (this.isBundleSystemActive(this.currentShop.id)) {
        return this.isFeatureEnabled(FEATURES.FEATURE_ANALYTICS, this.currentShop.id) && (
          this.currentOrganisation.attributes.analyticsModule || (
            this.checkFeatureFlag('FEATURE_COMMERCIAL_DASHBOARD_TAB_ENABLED') &&
            this.isSystemAdmin
          ));
      }

      return this.currentOrganisation.attributes.analyticsModule || (
        this.checkFeatureFlag('FEATURE_COMMERCIAL_DASHBOARD_TAB_ENABLED') &&
          this.currentOrganisation.attributes.packOffer.name === 'premium' &&
          this.isSystemAdmin
      );
    },
    employeesRouterUrl() {
      const shopId = this.isAllShopsView ? 'all' : this.currentShop.id;

      return {
        name: 'users',
        params: {
          shop_id: shopId,
          reload: true,
        },
      };
    },
    navbarClasses() {
      return {
        navbar__wrapper: true,
        'navbar__wrapper--fade': this.isProgressiveLoadingEnabled,
      };
    },
    redirectDate() {
      // if no date is forced in url, we take last planning date from local storage
      const planningDate = [this.$route.query.date, localStorage.planningDate]
        .find(date => date);

      return !planningDate ? skDate().format('YYYY-MM-DD') : planningDate;
    },
  },
  created() {
    this.fetchNotifications();
  },
  methods: {
    ...mapActions('notifications', ['fetchNotifications']),
  },
};
</script>

<style lang="scss">
.navbar__wrapper {
  min-height: 50px;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: $fs-text-m;
  border-bottom: none;
  z-index: 9;

  &--fade {
    animation: fadeIn 0.3s ease-out;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  &--shop-header {
    margin-left:32px;
    margin-top: -3px !important;
  }
  .sk-select__caret {
    margin: 16px 5px auto auto !important;
  }

  @media (max-width: $md-width) {
    padding: 0 20px;
  }

  .navbar__links__wrapper {
    flex: 1;
    justify-content: flex-start;
    margin-top: -3px !important; // v3-transition
    display: flex;
    align-items: center;

    @media (max-width: $md-width) {
      justify-content: space-between;
      margin-right: 40px;
    }
  }

  .navbar__links__element {
    margin-right: 30px;

    @media (max-width: $md-width) {
      margin: 0;
    }
  }

  // v3-transition
  // #### This CSS allow us to adapt v3 navbar to v2 navbar size ####
  // #### will be removed once all pages are fully migrated to v3 ####
  .sk-select__selected-option {
    height: 35px !important;
    width: 250px !important;
  }

  .navbar__links__element {
    margin-right: 45px !important;
  }

  .planning-v3__icon {
    width: 25px;
    height: 25px;
    display: inline-flex;
    margin-left: 40px;
    cursor: pointer;
  }

  .feature__upsell__icon {
    background: white;
    padding: 2px 4px 2px 8px;
    border-radius: 50%;
    margin-left: -7px;
    margin-bottom: -3px;

    svg {
      fill: $sk-blue;
      margin-bottom: 1px;
    }
  }
}
</style>
