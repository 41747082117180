<template>
  <SkModal
    id="variable-contract-hours-modal"
    ref="variableContractHoursModal"
    :steps="2"
    :submit-disabled="isSubmitDisabled"
    :modal-title="$t('shop_settings.tabs.teams.variable_hours_modal.title')"
    :previous-step-button-label="$t('shop_settings.tabs.teams.variable_hours_modal.previous_step')"
    :next-step-button-label="$t('shop_settings.tabs.teams.variable_hours_modal.next_step')"
    :submit-button-label="$t('shop_settings.tabs.teams.variable_hours_modal.submit')"
    :landing-step="selectedStep"
    size="ds-medium"
    show-progress-bar
    :testing-options="modalTestingOptions"
    @show="handleShow"
    @close="handleClose"
    @cancel="handleClose"
    @update-step="setCurrentStep"
    @submit="handleSubmit"
  >
    <template #body>
      <TeamScheduleParametersSection
        v-if="currentStep === 1 && team"
        :team="team"
        :team-schedule="teamSchedule"
        :is-team-schedule-update="isTeamScheduleUpdate"
      />
      <div
        v-else-if="teamScheduleLoading || !areCyclicAmendmentsLoaded"
        class="team-schedule-modal__spinner"
      >
        <SkLoader size="large" />
      </div>
      <CyclicAmendmentsSection
        v-else-if="areCyclicAmendmentsLoaded && currentStep === 2 && team"
        :cyclic-amendments-by-user="cyclicAmendmentsByUser"
        :team-schedule="teamSchedule"
        :team-users="teamUsersWithContractHours.concat(teamUsersWithoutContractHours)"
      />
    </template>
  </SkModal>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

import CyclicAmendmentsSection from './CyclicAmendmentsSection';
import TeamScheduleParametersSection from './TeamScheduleParametersSection';

export default {
  name: 'VariableContractHoursModal',
  components: { CyclicAmendmentsSection, TeamScheduleParametersSection },
  props: {
    team: {
      type: Object,
      default: null,
    },
    addedUsers: {
      type: Array,
      default: () => [],
    },
    removedUsers: {
      type: Array,
      default: () => [],
    },
    allTeamUserIds: {
      type: Array,
      default: () => [],
    },
    selectedStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentStep: 1,
      teamSchedule: null,
      // cyclicAmendmentsByUser: key is user id, value is array of amendments
      areCyclicAmendmentsLoaded: false,
      cyclicAmendmentsByUser: {},
      modalTestingOptions: {
        cancel: 'team-schedule-modal__cancel',
        next: 'team-schedule-modal__next',
        previous: 'team-schedule-modal__previous',
        submit: 'team-schedule-modal__submit',
      },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teamScheduleLoading', 'teamEmployees']),
    ...mapGetters('shopTeams', ['teamSchedulesForTeam', 'lastTeamScheduleForTeam']),
    isSubmitDisabled() {
      return !this.isAddingOrRemovingUser &&
        (!this.teamSchedule ||
          !this.teamSchedule.weekCycle ||
          this.teamSchedule.weekCycleStartIndex === null ||
          (this.isTeamScheduleUpdate && !this.changesToTeamSchedule));
    },
    allTeamUsers() {
      let teamUsers = this.team.relationships.users;

      if (this.hasTeamScheduleNewUsers) {
        teamUsers = this.addedUsers.concat(teamUsers);
      }

      if (this.hasTeamScheduleRemovedUsers) {
        teamUsers =
          teamUsers.filter(teamUser => (
            !this.removedUsers.map(user => user.id).includes(teamUser.id)
          ));
      }

      return teamUsers;
    },
    // For cyclic amendments considerations, if we are adding users, we only need the addedUsers
    // instead of all the team members
    filteredUsers() {
      if (this.hasTeamScheduleNewUsers) {
        return this.addedUsers;
      }

      return this.allTeamUsers;
    },
    teamUsersWithContractHours() {
      // users affected by variable hours are those with hourly rate
      // => excluding extra, temp or daily rate
      return this.filteredUsers.filter(
        user => !user.attributes.onExtra && !user.attributes.onDayRate,
      );
    },
    teamUsersWithoutContractHours() {
      // users in team without hourly rate: extra, temp or daily rate
      return this.filteredUsers.filter(
        user => user.attributes.onExtra || user.attributes.onDayRate,
      );
    },
    isTeamScheduleUpdate() {
      return !!this.existingTeamSchedule;
    },
    hasTeamScheduleNewUsers() {
      return this.addedUsers.length > 0;
    },
    hasTeamScheduleRemovedUsers() {
      return this.removedUsers.length > 0;
    },
    hasTeamScheduleOnlyRemovedUsers() {
      return !this.hasTeamScheduleNewUsers && this.hasTeamScheduleRemovedUsers;
    },
    existingTeamSchedule() {
      const teamSchedules = this.teamSchedulesForTeam(this.team.id).sort(
        (a, b) => a.attributes.startDate - b.attributes.startDate,
      );

      if (!teamSchedules ||
        teamSchedules.length === 0 ||
        !!teamSchedules[teamSchedules.length - 1].attributes.endDate
      ) return null;

      // TODO: After MVP, better handle currentTeamSchedule modification
      return teamSchedules[teamSchedules.length - 1];
    },
    requestParams() {
      const { startDate, endDate, weekCycle, weekCycleStartIndex } = this.teamSchedule;
      const teamSchedule = {
        start_date: startDate,
        end_date: endDate,
        week_cycle: weekCycle,
        week_cycle_start_index: weekCycleStartIndex,
        team_id: this.team.id,
      };

      if (this.isTeamScheduleUpdate) {
        teamSchedule.id = this.existingTeamSchedule.id;
      }

      return {
        team_schedule: teamSchedule,
        amendments: this.cyclicAmendmentsArray,
        shop_id: this.currentShop.id,
        team_user_ids: this.allTeamUserIds,
      };
    },
    cyclicAmendmentsArray() {
      // convert object of amendments by userId to array containing all amendments
      return Object.values(this.cyclicAmendmentsByUser)
        .flat()
        .map(amendment => {
          let hours = amendment.hours;
          if (amendment.unedited) {
            // If no amount of hours was set for amendment -> use currentPermanentContractHours as default
            const amendmentUser = this.teamUsersWithContractHours
              .find(user => user.id === amendment.userId);
            hours = amendmentUser.attributes.currentPermanentContractHours;
          }

          return {
            user_id: amendment.userId,
            schedule_week_index: amendment.scheduleWeekIndex,
            hours,
          };
        });
    },
    successTeamScheduleUpdateMessage() {
      if (this.hasTeamScheduleOnlyRemovedUsers) {
        return this.$t('shop_settings.tabs.teams.table.actions.update.success_message');
      }

      return this.$t('shop_settings.tabs.teams.variable_hours_modal.success');
    },
    isAddingOrRemovingUser() {
      return (this.hasTeamScheduleNewUsers || this.hasTeamScheduleRemovedUsers);
    },
    changesToTeamSchedule() {
      return !(this.existingTeamSchedule.attributes.weekCycle === this.teamSchedule.weekCycle &&
        this.existingTeamSchedule.attributes.weekCycleStartIndex ===
        this.teamSchedule.weekCycleStartIndex);
    },
  },
  methods: {
    ...mapActions('shopTeams', [
      'fetchCyclicAmendments',
      'createTeamSchedule',
      'updateTeamSchedule',
      'updateRollingTeamEmployees',
    ]),
    ...mapMutations('shopTeams', ['setEmployeesToTeam']),

    handleShow() {
      if (!this.isAddingOrRemovingUser) {
        this.$skAnalytics.track('team_rotation_modal_show');
      }

      this.currentStep = this.selectedStep;
      this.teamSchedule = {};

      // We need contract information from teamEmployees in CyclicAmendmentsHoursSection
      // for team relationship users
      // Included users in team includes archived users that teamEmployees doesn't have, we filter them out
      this.team.relationships.users = this.team.relationships.users
        .map(relationshipEmployee => (
          this.teamEmployees.find(teamEmployee => teamEmployee.id === relationshipEmployee.id)
        ))
        .filter(employee => employee);

      if (this.isTeamScheduleUpdate) {
        const { startDate, endDate, weekCycle, weekCycleStartIndex } =
          this.existingTeamSchedule.attributes;
        // team has a team schedule => update existing
        this.teamSchedule = {
          startDate: skDate(startDate).utc().format('YYYY-MM-DD'),
          endDate,
          weekCycle,
          weekCycleStartIndex,
        };
      } else {
        const lastTeamScheduleForTeam = this.lastTeamScheduleForTeam(this.team.id);
        let startDate;
        if (lastTeamScheduleForTeam) {
          startDate = skDate(lastTeamScheduleForTeam.attributes.endDate).add(1, 'day').utc().format('YYYY-MM-DD');
        } else {
          startDate = skDate().startOf('isoWeek').format('YYYY-MM-DD');
        }
        // team has no team schedule => create new one
        this.teamSchedule = {
          startDate,
          endDate: null,
          weekCycle: null,
          weekCycleStartIndex: null,
        };
      }

      if (this.hasTeamScheduleOnlyRemovedUsers) {
        this.$nextTick(() => {
          // We don't want to display the modal in this case so we hide it
          this.$refs.variableContractHoursModal.hide();
        });
      }
    },
    handleClose(event) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.resetModalData();
          this.$refs.variableContractHoursModal.hide();
          this.$skAnalytics.track('team_rotation_cancel');
        },
      });
    },
    setCurrentStep(currentStep) {
      if (currentStep === 2) {
        this.getCyclicAmendments(currentStep);
        if (!this.isAddingOrRemovingUser) {
          this.$skAnalytics.track('team_rotation_next_step');
        }
      } else {
        this.currentStep = currentStep;
      }
    },
    getCyclicAmendments(currentStep) {
      this.cyclicAmendmentsByUser = {};

      // Adding users to team -> create cyclic amendments for corresponding users
      if (this.hasTeamScheduleNewUsers) {
        this.teamUsersWithContractHours.forEach(user => {
          this.cyclicAmendmentsByUser[user.id] =
            this.createAmendmentsForUserFromWeekIndex(user.id, 0);
        });
        this.areCyclicAmendmentsLoaded = true;
        this.currentStep = currentStep;
      }

      // If we are adding or removing users from team -> we don't need to consider
      // other teammates' cyclic amendments
      if (this.isAddingOrRemovingUser) return;

      if (this.isTeamScheduleUpdate) {
        // If team already had a teamSchedule => get corresponding cylic amendments
        const cyclicAmendmentsByUser = {};
        const teamSchedule = this.existingTeamSchedule;
        this.fetchCyclicAmendments(teamSchedule.id)
          .then(result => {
            const cyclicAmendments = result.data.map(
              ({ attributes }) => ({
                userId: attributes.userId.toString(),
                scheduleWeekIndex: attributes.scheduleWeekIndex,
                hours: attributes.hours,
              }),
            );

            const oldWeekCycle = teamSchedule.attributes.weekCycle;
            const currentWeekCycle = this.teamSchedule.weekCycle;
            this.teamUsersWithContractHours.forEach(user => {
              const userId = user.id;
              // Get amendments matching user id
              // + in case weekCycle has been reduced => keep only those fitting the new weekCycle
              // i.e. those with week index lower than the total number of weeks in cycle
              cyclicAmendmentsByUser[userId] = cyclicAmendments.filter(amendment => (
                amendment.userId === userId &&
                amendment.scheduleWeekIndex < currentWeekCycle
              ));
              if (!cyclicAmendmentsByUser[userId].length) {
                // No amendments found for user: user was added to team after creating previous amendments
                // => Create new ones
                cyclicAmendmentsByUser[userId] =
                  this.createAmendmentsForUserFromWeekIndex(userId, 0);
              } else if (currentWeekCycle > oldWeekCycle) {
                // In case current weekCycle has more weeks than previous one
                // Create filler amendments for missing weeks
                cyclicAmendmentsByUser[userId] =
                  cyclicAmendmentsByUser[userId].concat(
                    this.createAmendmentsForUserFromWeekIndex(userId, oldWeekCycle),
                  );
              }
            });

            this.cyclicAmendmentsByUser = cyclicAmendmentsByUser;

            if (this.hasTeamScheduleOnlyRemovedUsers) {
              // modal wasn't shown, so we directly handle the update
              this.handleUpdate();
            } else {
              this.areCyclicAmendmentsLoaded = true;
              this.currentStep = currentStep;
            }
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('errors.standard_message'),
              variant: 'error',
            });
          });
      } else {
        // If team didn't have a teamSchedule previously or number of weekCycle has changed
        // => we need to create new cyclic amendments for each user and week in cycle
        this.teamUsersWithContractHours.forEach(user => {
          const userId = user.id;
          this.cyclicAmendmentsByUser[userId] =
            this.createAmendmentsForUserFromWeekIndex(userId, 0);
        });
        this.areCyclicAmendmentsLoaded = true;
        this.currentStep = currentStep;
      }
    },
    createAmendmentsForUserFromWeekIndex(userId, startWeekIndex) {
      const amendmentsForUser = [];
      for (
        let weekIndex = startWeekIndex;
        weekIndex < this.teamSchedule.weekCycle;
        weekIndex += 1
      ) {
        amendmentsForUser.push({
          userId,
          scheduleWeekIndex: weekIndex,
          hours: 0,
          unedited: true,
        });
      }
      return amendmentsForUser;
    },
    resetModalData() {
      this.cyclicAmendmentsByUser = {};
      this.areCyclicAmendmentsLoaded = false;
    },
    handleSubmit(event) {
      event.preventDefault();

      this.$skAnalytics.track('team_rotation_submit');

      if (this.isAddingOrRemovingUser) {
        this.handleChangeTeamEmployees();
      } else if (this.isTeamScheduleUpdate) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    handleCreate() {
      const params = this.requestParams;
      this.createTeamSchedule(params)
        .then(() => {
          this.$refs.variableContractHoursModal.hide();
        })
        .catch(error => {
          // If error type is Skello::IllegalOperation : a user in team already belongs to a
          // team with schedule -> display custom message
          const messageKey = error.response.data.message === 'USER_ALREADY_IN_TEAM_WITH_SCHEDULE' ?
            'shop_settings.tabs.teams.variable_hours_modal.error.user_already_has_variable_hours' :
            'errors.standard_message';

          this.$skToast({
            message: this.$t(messageKey),
            variant: 'error',
          });
        });
    },
    handleUpdate() {
      const params = this.requestParams;
      this.updateTeamSchedule(params)
        .then(() => {
          this.$skToast({
            message: this.successTeamScheduleUpdateMessage,
            variant: 'success',
          });
          this.$refs.variableContractHoursModal.hide();
          this.resetModalData();
        })
        .catch(error => {
          // If error type is Skello::IllegalOperation : a user in team already belongs to a
          // team with schedule -> display custom message
          const messageKey = error.response.data.message === 'USER_ALREADY_IN_TEAM_WITH_SCHEDULE' ?
            'shop_settings.tabs.teams.variable_hours_modal.error.user_already_has_variable_hours' :
            'errors.standard_message';

          this.$skToast({
            message: this.$t(messageKey),
            variant: 'error',
          });
        });
    },
    handleChangeTeamEmployees() {
      const params = this.requestParams;
      const startsAt = skDate.max([
        skDate().startOf('isoWeek'),
        skDate(this.teamSchedule.startDate).utc(true),
      ]).format('YYYY-MM-DD');

      params.amendments = params.amendments.map(amendment => (
        {
          ...amendment,
          starts_at: startsAt,
          ends_at: this.existingTeamSchedule.endDate,
          team_schedule_id: this.existingTeamSchedule.id,
        }
      ));

      this.updateRollingTeamEmployees(params)
        .then(() => {
          this.setEmployeesToTeam({
            teamId: this.team.id,
            employees: this.allTeamUsers,
          });

          this.$skToast({
            message: this.successTeamScheduleUpdateMessage,
            variant: 'success',
          });
          this.$refs.variableContractHoursModal.hide();
          this.resetModalData();
        })
        .catch(error => {
          // If error type is Skello::IllegalOperation : a user in team already belongs to a
          // team with schedule -> display custom message
          const messageKey = error.response.data.message === 'USER_ALREADY_IN_TEAM_WITH_SCHEDULE' ?
            'shop_settings.tabs.teams.variable_hours_modal.error.user_already_has_variable_hours' :
            'errors.standard_message';

          this.$skToast({
            message: this.$t(messageKey),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-schedule-modal__header-title {
  font-size: $fs-heading-xs;
  font-weight: bolder;
}

.team-schedule-modal__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

// Adjust header separation line color
#variable-contract-hours-modal ::v-deep .sk-modal__header {
  border-bottom: 1px solid $sk-grey-10;
}

// Adjust progress bar size and position
#variable-contract-hours-modal ::v-deep .inbound__progress-container {
  width: 230px;
  height: 3px;
  margin-left: 18px;
}

#variable-contract-hours-modal ::v-deep .inbound__progress-bar {
  height: 3px;
}

// Adjust footer padding
#variable-contract-hours-modal ::v-deep .sk-modal__footer {
  padding: 10px 24px;
}
</style>
