<template>
  <SkModal
    id="create-template-modal"
    ref="createTemplateModal"
    :modal-title="$t('plannings.toolbar.modal.create_template.title')"
    size="ds-medium"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <SkMedallion
        icon="FloppyDiskIcon"
        color="#727272"
        background-color="#f3f3f3"
      />
    </template>
    <template #body>
      <SkModalSection
        class="create-template-modal__body"
        border-bottom="none"
      >
        <div class="create-template-modal__title">
          {{ $t('plannings.toolbar.modal.create_template.body.title') }}
        </div>
        <SkInput
          ref="templateNameInput"
          v-model="templateName"
          :label="$t('plannings.toolbar.modal.create_template.body.placeholder')"
          :errored="isTemplateNameAlreadyExisting"
          :error-message="templateNameErrorMessage"
          class="create-template-modal__input"
        />
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="!templateName || isTemplateNameAlreadyExisting"
        :loading="creatingTemplate"
        data-test="create-template-modal__submit"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'CreateTemplateModal',
  data() {
    return {
      templateName: '',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsTemplates', [
      'creatingTemplate',
      'templates',
    ]),
    ...mapGetters('planningsUsers', ['filteredUsers']),
    ...mapGetters('planningsState', [
      'monday',
      'sunday',
      'isDailyView',
      'currentDate',
    ]),
    isTemplateNameAlreadyExisting() {
      return this.templates.some(template => template.attributes.name === this.templateName);
    },
    templateNameErrorMessage() {
      if (!this.isTemplateNameAlreadyExisting) return '';

      return this.$t('plannings.toolbar.modal.create_template.body.error_message');
    },
  },
  methods: {
    ...mapActions('planningsTemplates', ['createTemplate', 'fetchTemplates']),
    handleShow() {
      this.templateName = '';

      setTimeout(() => {
        this.$refs.templateNameInput.focus();
      }, 0);
    },
    handleSubmit() {
      const params = {
        shop_id: this.currentShop.id,
        template: {
          name: this.templateName,
          template_type: this.isDailyView ? 'day' : 'week',
        },
        starts_at: this.isDailyView ? this.currentDate : this.monday,
        ends_at: this.isDailyView ? this.currentDate : this.sunday,
        user_ids: this.filteredUsers.map(user => user.id),
      };

      this.createTemplate(params)
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.create_template.toaster.success'),
            variant: 'success',
          });
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'create-template-modal');

          const fetchParams = {
            shopId: this.currentShop.id,
            activePlanning: this.isDailyView ? 'day' : 'week',
          };
          this.fetchTemplates(fetchParams);
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'create-template-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
// Product asked for a specfic modal size
#create-template-modal {
  ::v-deep .sk-modal__footer {
    padding-top: 30px;
  }
}

.create-template-modal {
  &__disk-icon {
    display: flex;
    padding: 9px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 15px;
    background-color: $sk-grey-10;
  }

  &__title {
    font-weight: $fw-semi-bold;
    margin-bottom: 24px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 320px;
  }
}

::v-deep .sk-input__error-wrapper {
  min-height: 24px !important;
}
</style>
