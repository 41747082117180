<template>
  <div>
    <!-- We need the v-show here to keep alive the component during the loading
    And display the file when it's loaded -->
    <vue-dropzone
      v-show="!isLoading"
      id="upload-file-dropzone"
      ref="uploadFile"
      :options="dropzoneOptions"
      :include-styling="false"
      :use-custom-slot="true"
      @vdropzone-file-added="handleAddedFile"
      @vdropzone-removed-file="handleRemovedFile"
      @vdropzone-error="handleError"
    >
      <div
        v-show="isDefaultDropZoneStateDisplayed"
        class="upload-file__dropzone__content"
      >
        <div class="upload-file__dropzone__content__text">
          {{ $t('workload_plan.modals.upload_model.upload_file.title') }}
        </div>
        <div class="upload-file__dropzone__content__text dropzone-spacing">
          {{ $t('workload_plan.modals.upload_model.upload_file.condition') }}
        </div>
        <SkOroraButton
          variant="secondary"
          class="dropzone-spacing"
        >
          {{ $t('workload_plan.modals.upload_model.upload_file.cta') }}
        </SkOroraButton>
      </div>
    </vue-dropzone>

    <div
      v-if="isLoading"
      class="upload-file__dropzone__content upload-file__dropzone__content--loading"
    >
      <div class="upload-file__dropzone__content--loading__wrapper">
        <span class="upload-file__dropzone__content--loading__text">
          {{ $t('workload_plan.modals.upload_model.upload_file.loading') }}
        </span>
        <SkLoader
          size="medium"
          class="upload-file__dropzone__content--loading__spinner"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { TrashCanV2Icon } from '@skelloapp/skello-ui';

const CustomTrashCanIcon = Vue.extend(TrashCanV2Icon);
const AUTHORIZED_FILE_TYPE = [
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
];

export default {
  name: 'UploadFile',
  components: { vueDropzone: vue2Dropzone },
  props: {
    isFileAdded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        autoProcessQueue: false, // Dropzone should wait for the user to click on submit button to actually upload
        autoQueue: false,
        uploadMultiple: false,
        createImageThumbnails: false,
        maxFiles: 1,
        acceptedFiles: AUTHORIZED_FILE_TYPE.join(','),
        hiddenInputContainer: '#upload-file-dropzone',
        url: '/fake', // We dont use dropzone for the XHR call but it requires a url
        maxFilesize: 5,
        previewTemplate: this.selectedFileTemplate,
      };
    },
    isDefaultDropZoneStateDisplayed() {
      return !this.isFileAdded && !this.isLoading;
    },
    selectedFileTemplate() {
      // This part of HTML does not follow BEM logical for class names,
      // because dropzone has its own query selector to display the right component.
      // Replace is to change the color of the svg, very hacky but no alternative.
      // The color is $sk-grey-50
      return `
        <div class="dz-complete">
          <div class="dz__container">
            <div class="dz-title">
              <span data-dz-name--title>
                ${this.$i18n.t('workload_plan.modals.upload_model.uploaded.title')}
              </span>
            </div>
            <div class="dz__details">
              <div class="dz__details--title"><span data-dz-name></span></div>
              <div class="dz-right-section">
                <span class="dz-right-section--size" data-dz-size></span>
                <button class="dz-right-section__delete--icon" data-dz-remove>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 30 25"
                    id="workload-trash-icon"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    ${(new CustomTrashCanIcon().$mount().$el.innerHTML).replace('black', '#727272')}
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
        </div>
      `;
    },
  },
  methods: {
    handleAddedFile(file) {
      this.isLoading = true;

      const isValid = this.validateFile(file);
      this.$emit('on-file-change', isValid);
      this.$emit('on-file-upload', file);

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    handleRemovedFile() {
      this.$emit('on-file-change', false);
    },
    handleError(_file, error) {
      if (!error) return;

      if (typeof error === 'string' && error.includes('You can not upload any more files.')) {
        this.displayError('max_file');
        if (this.isFileAdded) this.$emit('on-file-change', false);
      }
    },
    validateFile(file) {
      if (!file || !file.type) return false;

      if (!AUTHORIZED_FILE_TYPE.includes(file.type)) {
        this.displayError('file_type');
        return false;
      }

      // autoQueue is false so we need to put the file in the queue when accepted
      this.$refs.uploadFile.getAcceptedFiles().forEach(acceptedFile => {
        this.$refs.uploadFile.dropzone.enqueueFile(acceptedFile);
      });

      return true;
    },
    displayError(error) {
      // clean the drop zone queue
      this.$refs.uploadFile.removeAllFiles();

      this.$skToast({
        message: this.$t(`workload_plan.modals.upload_model.errors.${error}`),
        variant: 'error',
      });
    },
  },
};
</script>

<style lang="scss">
#upload-file-dropzone {
  width: 100%;
  cursor: pointer;
}

.upload-file__dropzone__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 151px;
  border: 2px dashed $sk-grey-30;
  border-radius: 4px;

  &__text {
    font-size: $fs-text-m;
    color: $sk-grey;
  }

  &--loading {
    border: 2px dashed $sk-blue;
  }

  &--loading__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
      font-size: $fs-text-m;
      color: $sk-grey;
    }

    &__spinner {
      margin-top: 8px;
    }
  }
}

.dropzone-spacing {
  margin-top: 8px;
}

.dz__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: default;

  .dz-title {
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
  }
}

.dz__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $sk-grey-5;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;

  .dz-title {
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
  }

  &--title {
    font-size: $fs-text-m;
    color: $sk-grey;
  }

  .dz-right-section {
    display: flex;
    flex-direction: row;
    color: $sk-grey;
    justify-content: center;
    align-items: center;
    font-size: $fs-text-m;

    &__delete--icon {
      margin-left: 16px;
      display: flex;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
}
</style>
