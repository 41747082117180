<script>

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'DeleteFolderModal',
  methods: {
    show() {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t(
          'shop_settings.documents_management.delete_folder.confirm_message',
        ),
        onConfirm: () => this.$emit('delete-folder'),
        title: this.$t('shop_settings.documents_management.delete_folder.confirm_title'),
        cancelLabel: this.$t('shop_settings.documents_management.delete_folder.cancel'),
        submitLabel: this.$t('shop_settings.documents_management.delete_folder.submit'),
      });
    },
  },
};

</script>
