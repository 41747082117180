<template>
  <div class="hr_file">
    <h1 class="hr_file__title">
      {{ $t('profile.hr_file.title') }}
    </h1>
    <form
      class="container"
      @submit.prevent
    >
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.birth.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkDatePicker
              v-model="birthday"
              :lang="$i18n.locale"
              :placeholder="$t('profile.hr_file.birth.fields.birthdate')"
              width="100%"
              :disabled="!canEditSelfHr"
              data-test="hr_file__birthdate"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="birthPlace"
              :label="$t('profile.hr_file.birth.fields.city_of_birth')"
              :errored="isErrored('birthPlace')"
              :error-message="errorMessage('birthPlace')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__birth_place"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="birthDepartment"
              :label="$t('profile.hr_file.birth.fields.region_of_birth')"
              :errored="isErrored('birthDepartment]')"
              :error-message="errorMessage('birthDepartment]')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__birth_department"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkSelectV2
              v-model="countryCodeOfBirth"
              :options="countryOptions"
              :label="$t('profile.hr_file.birth.fields.country_of_birth')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__birth_country"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.nationality.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="nationality"
              :options="countryOptions"
              :label="$t('profile.hr_file.nationality.fields.nationality')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__nationality"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.gender.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="gender"
              :options="genderOptions"
              :label="$t('profile.hr_file.gender.fields.gender')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__gender"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.address.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="address"
              :label="$t('profile.hr_file.address.fields.address')"
              :errored="isErrored('address')"
              :error-message="errorMessage('address')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__address"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="addressDetails"
              :label="$t('profile.hr_file.address.fields.addressDetails')"
              :errored="isErrored('addressDetails')"
              :error-message="errorMessage('addressDetails')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__address-details"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="postalCode"
              :label="$t('profile.hr_file.address.fields.zip_code')"
              :errored="isErrored('postalCode')"
              :error-message="errorMessage('postalCode')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__postal_code"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="city"
              :label="$t('profile.hr_file.address.fields.city')"
              :errored="isErrored('city')"
              :error-message="errorMessage('city')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__city"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="countryCodeOfResidence"
              :options="countryOptions"
              :label="$t('profile.hr_file.address.fields.country')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__country"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.bank_data.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="paymentMethod"
              input-id="payment-method"
              :options="paymentMethodOptions"
              :label="$t('profile.hr_file.bank_data.fields.payment_methods')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__payment_methods"
            />
          </div>
        </div>
        <div
          v-show="paymentMethod === 'bank_transfer'"
          class="row sk-form__row"
        >
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="bankInfo"
              :label="$t('profile.hr_file.bank_data.fields.iban.text')"
              :errored="isErrored('bankInfo')"
              :error-message="errorMessage('bankInfo')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__bank_info"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="swiftCode"
              :label="$t('profile.hr_file.bank_data.fields.bic')"
              :errored="isErrored('swiftCode')"
              :error-message="errorMessage('swiftCode')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__swift_code"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.ssn.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkInput
              v-model="socialSecurityNumber"
              :label="$t('profile.hr_file.ssn.fields.ssn')"
              :errored="isErrored('socialSecurityNumber')"
              :error-message="errorMessage('socialSecurityNumber')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__social_security_number"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        class="hr_file__skello-form"
        :title="$t('profile.hr_file.fiscality.title')"
        split
      >
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkInput
              v-model="numberOfDependents"
              type="number"
              :label="$t('profile.hr_file.fiscality.fields.number_of_dependents')"
              :errored="isErrored('numberOfDependents')"
              :error-message="errorMessage('numberOfDependents')"
              :disabled="!canEditSelfHr"
              data-test="hr_file__social_number_of_dependents"
            />
          </div>
        </div>
      </SkForm>
    </form>
    <StickyBar
      container-scroll-id="profile__content"
      :visible="hasFormChanged"
      :submit-spinner="isProcessingUpdate"
      :submit-button-label="$t('employees.sticky_bar.submit')"
      :disabled="isSendingDisabled"
      data-test="hr_file__submit"
      @submit="handleSubmit"
    />
  </div>
</template>
<script>
import {
  mapActions, mapState,
} from 'vuex';
import {
  SkDatePicker, SkForm, SkInput, SkSelectV2,
} from '@skelloapp/skello-ui';
import StickyBar from '@app-js/shared/components/Stickybar';

import { arrayToSelectOptions } from '@skello-utils/form';
import COUNTRY_KEYS from '@app-js/shared/constants/country-keys';

export default {
  name: 'HrFile',
  components: {
    SkDatePicker,
    SkForm,
    SkInput,
    SkSelectV2,
    StickyBar,
  },
  data() {
    return {
      birthday: undefined,
      birthPlace: undefined,
      birthDepartment: undefined,
      countryCodeOfBirth: undefined,
      nationality: undefined,
      gender: undefined,
      address: undefined,
      addressDetails: undefined,
      postalCode: undefined,
      city: undefined,
      countryCodeOfResidence: undefined,
      paymentMethod: undefined,
      bankInfo: undefined,
      numberOfDependents: undefined,
      swiftCode: undefined,
      socialSecurityNumber: undefined,
      hasErrors: false,
      inputErrors: {},
      isProcessingUpdate: false,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),

    canEditSelfHr() {
      return this.currentUser.relationships.highestLicense.attributes.canEditSelfHr;
    },
    countryOptions() {
      return arrayToSelectOptions(COUNTRY_KEYS, value => this.$t(`countries.${value}`));
    },
    genderOptions() {
      return arrayToSelectOptions(this.config.genders, value => this.$t(`genders.${value}`));
    },
    paymentMethodOptions() {
      return arrayToSelectOptions(this.config.payment_methods, value => this.$t(`payment_methods.${value}`));
    },
    hasFormChanged() {
      return this.hasChanged('birthday') ||
        this.hasChanged('birthPlace') ||
        this.hasChanged('birthDepartment') ||
        this.hasChanged('countryCodeOfBirth') ||
        this.hasChanged('nationality') ||
        this.hasChanged('gender') ||
        this.hasChanged('address') ||
        this.hasChanged('addressDetails') ||
        this.hasChanged('postalCode') ||
        this.hasChanged('city') ||
        this.hasChanged('countryCodeOfResidence') ||
        this.hasChanged('paymentMethod') ||
        this.hasChanged('bankInfo') ||
        this.hasChanged('swiftCode') ||
        this.hasChanged('socialSecurityNumber') ||
        this.hasChanged('numberOfDependents');
    },
    isSendingDisabled() {
      return !this.canEditSelfHr || (this.hasErrors && !this.allErroredFieldsChanged);
    },
    allErroredFieldsChanged() {
      return Object.keys(this.inputErrors).length > 0 &&
        Object.keys(this.inputErrors)
          .every(field => this[field] !== this.inputErrors[field].erroredValue);
    },
    profileHrParams() {
      return {
        birthday: this.birthday,
        birth_place: this.birthPlace,
        birth_department: this.birthDepartment,
        country_code_of_birth: this.countryCodeOfBirth,
        nationality: this.nationality,
        gender: this.gender,
        address: this.address,
        address_details: this.addressDetails,
        postal_code: this.postalCode,
        city: this.city,
        country_code_of_residence: this.countryCodeOfResidence,
        payment_method: this.paymentMethod,
        bank_info: this.bankInfo,
        number_of_dependents: this.numberOfDependents,
        swift_code: this.swiftCode,
        social_security_number: this.socialSecurityNumber,
      };
    },
  },
  beforeMount() {
    this.initForm();
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUserProfile']),

    initForm() {
      this.birthday = this.currentUser.attributes.birthday;
      this.birthPlace = this.currentUser.attributes.birthPlace;
      this.birthDepartment = this.currentUser.attributes.birthDepartment;
      this.countryCodeOfBirth = this.currentUser.attributes.countryCodeOfBirth;
      this.nationality = this.currentUser.attributes.nationality;
      this.gender = this.currentUser.attributes.gender;
      this.address = this.currentUser.attributes.address;
      this.addressDetails = this.currentUser.attributes.addressDetails;
      this.postalCode = this.currentUser.attributes.postalCode;
      this.city = this.currentUser.attributes.city;
      this.countryCodeOfResidence = this.currentUser.attributes.countryCodeOfResidence;
      this.paymentMethod = this.currentUser.attributes.paymentMethod;
      this.bankInfo = this.currentUser.attributes.bankInfo;
      this.swiftCode = this.currentUser.attributes.swiftCode;
      this.socialSecurityNumber = this.currentUser.attributes.socialSecurityNumber;
      this.numberOfDependents = this.currentUser.attributes.numberOfDependents;
    },
    hasChanged(field) {
      return this[field] !== this.currentUser.attributes[field];
    },
    isErrored(field) {
      return !!this.inputErrors[field] && this[field] === this.inputErrors[field]?.erroredValue;
    },
    errorMessage(field) {
      if (!this.isErrored(field)) return undefined;

      return this.inputErrors[field]?.errorMessage;
    },
    handleSubmit() {
      this.isProcessingUpdate = true;
      this.updateCurrentUserProfile({ user: this.profileHrParams })
        .then(() => {
          this.hasErrors = false;
          this.inputErrors = {};

          this.$skToast({
            message: this.$t('profile.personal_information.update.success'),
            variant: 'success',
          });
        })
        .catch(({ response }) => {
          this.hasErrors = true;

          if (response.status === 422) {
            this.setUnprocessableEntityInputErrors(response.data.errors);
          }
          const errorMessage = response.data.message?.alert ?? this.$t('errors.standard_message');
          this.$skToast({
            message: errorMessage,
            variant: 'error',
          });
        })
        .finally(() => {
          this.isProcessingUpdate = false;
        });
    },
    setUnprocessableEntityInputErrors(fieldErrors) {
      Object.entries(fieldErrors).forEach(([field, errors]) => {
        this.inputErrors[field] = {
          erroredValue: this[field],
          /* NOTE: this is to avoid displaying information about other users emails,
          * as by default it reveals if this email is used by another user,
          * and that's not recomended.
          */
          errorMessage: field === 'email' ? this.$t('errors.invalid_value') : errors.join(', '),
        };
      });
    },
  },
};
</script>
<style lang="scss">
  .hr_file {
    &__skello-form {
      .sk-form__header {
        label {
          height: 44px;
          width: 100%;
          padding: 14px 0 14px 0;
          line-height: 16px;
        }

        &__title.pl-3 {
          padding-left: 0 !important;
        }
      }

      .sk-form-body-section {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      &.sk-form.sk-form--split {
        .row {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .col-lg-6, .col-md-12, .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>

<style lang="scss" scoped>

.hr_file {
  width: 100%;

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    margin-bottom: 32px;
  }

  .container {
    padding-right: 0;
    padding-left: 0;
  }

  @media (min-width: 992px) {
    .container {
        max-width: 900px;
    }
  }

  .sk-form__col {

    &.col-md-6 {
      padding: 0 6px;

      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }

  .sk-sticky-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
