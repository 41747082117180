<template>
  <div class="sk-new-tag">
    {{ $t(`shop_settings.tabs.new`) }}
  </div>
</template>

<script>
export default {
  name: 'NewTag',
};
</script>

<style lang="scss" scoped>
.sk-new-tag {
  width: fit-content;
  padding: 0 7px;
  text-align: center;
  font-size: $fs-text-s;
  font-weight: $fw-semi-bold;
  border-radius: 14px;
  color: $sk-white;
  background-color: $sk-blue;
}
</style>
