<template>
  <!-- eslint-disable  max-len -->
  <div
    ref="finder"
    class="cluster_nodes-finder"
  >
    <Column
      v-for="cluster in clusterList"
      :key="`column_${cluster.parentId}`"
      :cluster="cluster"
      :handle-click-on-card="handleClickOnCard"
      :current-cluster-id="currentClusterId"
      :toggle-create-modal="toggleCreateModal"
      :on-drag-over="onDragOver"
      :target-in-drop-zone="targetInDropZone"
      :target-out-of-drop-zone="targetOutOfDropZone"
      :on-drag-leave="onDragLeave"
      :on-add="onAdd"
      :on-move="onMove"
      :on-drag-start="onDragStart"
      :on-drag-end="onDragEnd"
      :dragging-item="draggingItem"
      :show-drop-zone="showDropZone"
      :loading="loading"
    />
    <DropModal
      v-if="showDropModal"
      :dropped-element="draggingItem"
      :origin-element="formerCluster"
      :cancel-drop="cancelLastDrop"
      :show-drop-modal="showDropModal"
      :move-card="moveCard"
      :target-id="targetId"
      :target-depth="targetDepth"
      :target-name="targetName"
      :reset-data="resetData"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Column from '../../ui/Column';
import DropModal from '../DropModal';

export default {
  name: 'ClusterTreeFinder',
  components: { Column, DropModal },
  props: {
    organisationName: {
      type: String,
      required: true,
    },
    clusterList: {
      type: Array,
      required: true,
    },
    handleClickOnCard: {
      type: Function,
      required: true,
    },
    toggleDropModal: {
      type: Function,
      required: true,
    },
    moveCard: {
      type: Function,
      required: true,
    },
    showDropModal: {
      type: Boolean,
      required: true,
    },
    currentClusterId: {
      type: Number,
      default: null,
    },
    toggleCreateModal: {
      type: Function,
      required: true,
    },
    setFinderScrollable: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropZone: false,
      draggingOver: false,
      draggedInzone: false,
      currentTarget: {},
      draggingItem: {},
      dropZoneTargetCluster: null,
      elementInNewList: false,
      currentClusterList: null,
      movingParentToChild: false,
      itemPlaceholder: null,
      formerCluster: null,
    };
  },
  computed: {
    targetName() {
      if (this.dropZoneTargetCluster) {
        const parentIndex = this.clusterList.findIndex(
          cluster => cluster.parentId === this.dropZoneTargetCluster.parentId,
        );
        if (parentIndex === 0) {
          return this.organisationName;
        }
        const clusterWithNewElement = this.multiColumnFindClusterById(
          this.dropZoneTargetCluster.parentId,
        );

        return clusterWithNewElement.name;
      }
      if (this.currentTarget.name) {
        return this.currentTarget.name;
      }
      return 'undefined';
    },
    targetId() {
      if (this.dropZoneTargetCluster) {
        return this.dropZoneTargetCluster.parentId;
      }
      return this.currentTarget.id;
    },
    targetDepth() {
      if (this.dropZoneTargetCluster) {
        return this.dropZoneTargetCluster.parentDepth;
      }
      return this.currentTarget.depth;
    },
    draggingOverItself() {
      return (this.currentTarget.id === 'item-placeholder') || this.currentTarget.id === this.draggingItem.id;
    },
    realMoveOccur() {
      return ((this.draggingOver && !this.draggingOverItself) || this.draggedInzone) &&
        !this.isShop(this.currentTarget);
    },
  },
  watch: {
    clusterList() {
      const finder = this.$refs.finder;
      this.$nextTick(() => {
        if (finder) {
          this.setFinderScrollable(finder.scrollWidth > finder.clientWidth);
        } else {
          this.setFinderScrollable(false);
        }
      });
    },
  },
  mounted() {
    this.$root.$on('scroll', leftOrRight => this.scroll(leftOrRight));
  },
  methods: {
    resetData() {
      this.targetHtmlClass.remove('over-element', 'not-droppable');
      this.draggingItem = {};
      this.currentTarget = {};
      this.elementInNewList = false;
      this.showDropZone = false;
      this.draggedInzone = false;
      this.movingParentToChild = false;
      this.dropZoneTargetCluster = null;
      this.currentClusterList = null;
      this.itemPlaceholder = null;
      this.formerCluster = null;
      this.cleanHtmlClass();
    },
    cleanHtmlClass() {
      const htmlClusterCards = Array.from(document.querySelectorAll('.cluster_node-card'));
      htmlClusterCards.forEach(htmlClusterCard => {
        htmlClusterCard.classList.remove('over-element', 'not-droppable');
      });
      const allDraggingItems = Array.from(document.querySelectorAll('.item--dragged'));
      allDraggingItems.forEach(draggingItem => {
        draggingItem.remove();
      });
      const allClusterPlaceholder = Array.from(document.querySelectorAll('.cluster-placeholder'));
      allClusterPlaceholder.forEach(clusterPlaceholder => {
        clusterPlaceholder.remove();
      });
      const allPlaceholderItems = Array.from(document.querySelectorAll('.item-placeholder'));
      allPlaceholderItems.forEach(placeholderItem => {
        placeholderItem.remove();
      });
    },
    scroll(direction) {
      const finder = this.$refs.finder;
      if (direction === 'right') {
        finder.scrollBy({
          top: 0,
          left: finder.scrollWidth / this.clusterList.length,
          behavior: 'smooth',
        });
      } else {
        finder.scrollBy({
          top: 0,
          left: (-finder.scrollWidth) / this.clusterList.length,
          behavior: 'smooth',
        });
      }
    },
    isCluster(item) {
      if (item) return (item.type === 'cluster_node');
      return false;
    },

    isShop(item) {
      if (item) return (item.type === 'shop');
      return false;
    },

    hasShops() {
      return (this.currentTarget.nbShops > 0);
    },
    findClusterInCurrentList(indexList, id) {
      return this.clusterList[indexList].children.find(
        cluster => cluster.id === id,
      );
    },
    multiColumnFindClusterById(id) {
      for (let index = 0; index < this.clusterList.length; index += 1) {
        const subClusterList = this.clusterList[index];
        if (subClusterList.children.length > 0) {
          const clusterFound = this.findClusterInCurrentList(index, id);
          if (clusterFound) {
            return clusterFound;
          }
        }
      }
      return null;
    },
    setClusterWithAction() {
      const htmlClusterCards = document.querySelectorAll('.cluster_node-card');
      htmlClusterCards.forEach(htmlClusterCard => {
        const clusterNode = this.multiColumnFindClusterById(parseInt(htmlClusterCard.id, 10));
        const alert = this.cantDropElement(clusterNode);
        if (alert.status) {
          htmlClusterCard.classList.remove('over-element');
          htmlClusterCard.classList.add('not-droppable');
        } else if (this.isCluster(this.clusterNode) && this.clusterNode.id !== 'item-placeholder') {
          htmlClusterCard.classList.remove('not-droppable');
          htmlClusterCard.classList.add('over-element');
        }
      });
    },
    // Call when you start dragging a card
    onDragStart(evt, draggingItem, cluster) {
      this.formerCluster = cluster;
      this.currentTarget = {};
      this.draggingItem = draggingItem;
      this.itemPlaceholder = cloneDeep(this.draggingItem);
      this.itemPlaceholder.id = 'item-placeholder';
      this.formerCluster.children.splice(evt.oldIndex, 0, this.itemPlaceholder);
      this.setClusterWithAction();
      this.showDropZone = true;
      if (this.isShop(this.draggingItem)) {
        this.showDropZone = false;
      }
    },
    addOnCurrentColumn() {
      if (this.isCluster(this.currentTarget) && !this.elementInNewList && this.currentTarget.id !== 'item-placeholder') {
        const alert = this.cantDropElement(this.currentTarget);
        if (alert.status) {
          this.resetData();
          this.$skToast({
            message: alert.text,
            variant: 'error',
          });
          return;
        }

        this.saveMutation();
        this.targetHtmlClass.remove('over-element', 'not-droppable');
        this.toggleDropModal();
      }
    },
    // Call when you drop a card
    onDragEnd(evt) {
      if (this.realMoveOccur) {
        // Add element if on current column, nothing done if in different column
        this.addOnCurrentColumn();
        return;
      }
      // Reset data if nothing is added
      if (this.formerCluster) this.formerCluster.children.splice(evt.oldIndex, 1);
      this.showDropZone = false;
      this.resetData();
    },
    // Call when you drag over dropping zone
    targetInDropZone(parentCluster) {
      this.dropZoneTargetCluster = parentCluster;
      this.draggedInzone = true;
    },
    // Call when you drag out dropping zone
    targetOutOfDropZone() {
      this.dropZoneTargetCluster = null;
      this.draggedInzone = false;
    },
    // Call when you drag over a cluster card
    onDragOver(target, evt) {
      this.draggingOver = true;
      this.targetHtmlClass = evt.currentTarget.classList;
      this.currentTarget = target;
      const alert = this.cantDropElement(this.currentTarget);
      if (alert.status) {
        this.targetHtmlClass.remove('over-element');
        this.targetHtmlClass.add('not-droppable');
      } else if (this.isCluster(this.currentTarget) && this.currentTarget.id !== 'item-placeholder') {
        this.targetHtmlClass.remove('not-droppable');
        this.targetHtmlClass.add('over-element');
      }
    },
    // Call when you drag out of a cluster card
    onDragLeave() {
      if (this.targetHtmlClass && this.targetHtmlClass.contains('over-element')) {
        this.targetHtmlClass.remove('over-element', 'not-droppable');
      }
      this.draggingOver = false;
      this.currentTarget = {};
    },
    onMove() {
      this.clusterList.forEach(column => {
        const clickedItemId = column.clickedItemId;
        if (clickedItemId === this.draggingItem.id && this.targetDepth >= this.draggingItem.depth) {
          this.movingParentToChild = true;
        }
      });
    },
    // call when added an item on a new list
    onAdd(clusterTarget) {
      this.elementInNewList = true;
      this.saveMutation(clusterTarget);
      if (this.draggedInzone) {
        this.toggleDropModal();
      } else if (this.targetHtmlClass.contains('over-element') || this.targetHtmlClass.contains('not-droppable')) {
        // when you drop an element on a cluster in a new list
        this.checkIfCanDropOnElement();
      } else {
        this.removeOnNewList();
        this.clusterList[this.formerParentIndex].children.push(this.draggingItem);
        this.clusterList[this.formerParentIndex].children.sort((a, b) => a.name > b.name);
      }
    },
    isSelfParent(targetClusterNode) {
      for (let index = 0; index < this.clusterList.length; index += 1) {
        const column = this.clusterList[index];
        const clickedItemId = column.clickedItemId;
        if (clickedItemId === this.draggingItem.id &&
          targetClusterNode.depth > this.draggingItem.depth) {
          return true;
        }
      }
      return false;
    },
    cantDropElement(targetClusterNode) {
      let text = '';
      let status = false;
      if (this.isCluster(this.draggingItem) &&
           targetClusterNode.nb_node === 0 &&
           targetClusterNode.nb_shop > 0) {
        text = this.$t('organisation_settings.tabs.clusters.drop_notification_1');
        status = true;
      } else if (this.isShop(this.draggingItem) && targetClusterNode.nb_node > 0) {
        text = this.$t('organisation_settings.tabs.clusters.drop_notification_2');
        status = true;
      } else if (this.isSelfParent(targetClusterNode)) {
        text = this.$t('organisation_settings.tabs.clusters.drop_notification_3', {
          item: this.draggingItem.name,
        });
        status = true;
      } else if (this.isShop(targetClusterNode)) {
        text = this.$t('organisation_settings.tabs.clusters.drop_notification_4', {
          item: this.draggingItem.name,
        });
        status = true;
      }

      return {
        text,
        status,
      };
    },
    checkIfCanDropOnElement() {
      const alert = this.cantDropElement(this.currentTarget);
      if (alert.status || !this.currentTarget.id) {
        this.removeOnNewList();
        this.resetData();
        if (alert.status) {
          this.$skToast({
            message: alert.text,
            variant: 'error',
          });

          return;
        }
      }
      this.removeOnNewList();
      this.targetHtmlClass.remove('over-element', 'not-droppable');
      this.toggleDropModal();
    },

    cancelLastDrop() {
      if (this.draggedInzone) {
        this.removeOnNewList();
      }
      this.toggleDropModal();
      this.resetData();
    },

    removeOnNewList() {
      const indexToRemove = this.clusterList[this.newParentIndex].children.findIndex(
        child => child.id === this.draggingItem.id,
      );
      this.clusterList[this.newParentIndex].children.splice(indexToRemove, 1);
    },

    removeOnCurrentList() {
      this.currentClusterList.children = this.currentClusterList.children.filter(
        child => child.id !== Number(this.draggingItem.id),
      );
    },

    saveMutation(clusterTarget) {
      if (this.draggedInzone || !this.targetHtmlClass.contains('over-element')) {
        this.setParentIndexOfDropping(clusterTarget);
      } else {
        this.setParentIndexOfTarget();
      }
      this.currentClusterList = this.clusterList[this.newParentIndex];
      this.formerParentIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === Number(this.draggingItem.parent_id),
      );

      this.draggingItem = this.currentClusterList.children.find(
        child => child.id === Number(this.draggingItem.id),
      );
    },

    setParentIndexOfDropping(clusterTarget) {
      this.newParentIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === clusterTarget.parentId,
      );
    },

    setParentIndexOfTarget() {
      this.newParentIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === this.currentTarget.parent_id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cluster_nodes-finder {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-height: calc(100vh - 105px - 18px - 3em);
}
</style>
