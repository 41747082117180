<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#FDF2F8"
      border-color="#9D174D"
      data-test="column__over-hours__closed"
    />
    <transition name="hrat-column">
      <div
        v-if="columnActive"
        class="column-wrap column-wrap--open"
        data-test="column__over-hours__opened"
      >
        <div
          class="column__header pay-elements-title"
          data-test="column__over-hours__close-button"
          @click="closeColumn"
        >
          {{ title }}
          <div class="column__close-btn">
            <ReportCloseIcon />
          </div>
        </div>
        <span>
          <table
            v-if="!modulation
              || modulationMajoration
              || (isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')
                && currentShop.attributes.hybridModulation)"
          >
            <thead class="column__title-wrap">
              <!-- Additional hours -->
              <th
                v-for="complementaryHoursColumn in complementaryHoursColumns"
                :key="complementaryHoursColumn"
                class="column__title column__title--open"
              >
                {{ complementaryHoursColumn }}
              </th>
              <!-- Exceptional overtime hours -->
              <th
                v-for="(overHoursColumn, index) in overHoursColumns"
                :key="`overHours-${overHoursColumn}-${index}`"
                class="column__title column__title--open"
              >
                {{ overHoursColumn }}
              </th>
              <th
                v-if="modulationMajoration"
                class="column__title column__title--open"
              >
                {{ $t('reports.over_hours.total_over_hours') }}
              </th>
              <!-- Total structural overtime hours -->
              <th
                v-if="showStructuralOverHours"
                class="column__title column__title--open"
              >
                {{ $t('reports.salary_data.total_structural_over_hours') }}
              </th>
              <!-- Structural overtime hours -->
              <th
                v-for="(structuralOverHoursColumn, index) in structuralOverHoursColumns"
                :key="`structuralOverHours-${structuralOverHoursColumn}-${index}`"
                class="column__title column__title--open"
              >
                {{ structuralOverHoursColumn }}
              </th>
              <th
                v-for="(nightHoursColumn, index) in nightHoursColumns"
                :key="`nightHours-${nightHoursColumn}-${index}`"
                class="column__title column__title--open"
              >
                {{ nightHoursColumn }}
              </th>
              <th
                class="column__title column__title--open"
              >
                {{ $t('reports.over_hours.sunday_hours') }}
                <span v-if="flexibleConvention">
                  {{ sundayMaj }}%
                </span>
                ({{ $t('reports.hour_symbol') }})
              </th>
              <th class="column__title column__title--open">
                {{ $t('reports.over_hours.holidays_hours') }}
                <span v-if="flexibleConvention">
                  {{ holidayMaj }}%
                </span>
                ({{ $t('reports.hour_symbol') }})
              </th>
              <th
                v-if="showFirstMay"
                class="column__title column__title--open"
              >
                {{ $t('reports.over_hours.first_may_hours') }}
                <span v-if="flexibleConvention">
                  {{ firstMayMaj }}%
                </span>
                ({{ $t('reports.hour_symbol') }})
              </th>
              <th
                v-if="showPentecost"
                class="column__title column__title--open"
              >
                {{ $t('reports.over_hours.pentecost_hours') }}
                ({{ $t('reports.hour_symbol') }})
              </th>
              <th
                v-if="showCuts"
                class="column__title column__title--open"
              >
                {{ $t('reports.over_hours.breaks') }} (>{{ cutLength }}h)
              </th>
            </thead>

            <tbody>
              <template v-for="employeeInfos in employeesInfos">
                <tr
                  v-for="contractInfos in employeeInfos.contracts_infos"
                  :key="contractInfos.contract.id"
                  class="column__cell-wrap"
                >
                  <!-- Additional hours -->
                  <td
                    v-for="(complementaryHours, index) in
                      computeComplementaryHours(contractInfos.complementary_hours)"
                    :key="`complementaryHours-${index}`"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{
                      displayHoursOrDash(
                        contractInfos.interim_or_day_rate,
                        complementaryHours,
                        employeeInfos.user_infos.counter_initialized && !modulationMajoration,
                      )
                    }}
                  </td>
                  <!-- Exceptional overtime hours -->
                  <td
                    v-for="(overHours, index) in contractInfos.over_hours"
                    :key="`overHours-${index}`"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{
                      displayHoursOrDash(
                        contractInfos.interim_or_day_rate,
                        overHours,
                        employeeInfos.user_infos.counter_initialized && !modulationMajoration,
                      )
                    }}
                  </td>

                  <td
                    v-if="modulationMajoration"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ displayHoursOrDash(
                      contractInfos.interim_or_day_rate,
                      contractInfos.total_over_hours_w_majoration
                    )
                    }}
                  </td>
                  <!-- Total structural overtime hours -->
                  <td
                    v-if="showStructuralOverHours"
                    class="column__cell column__cell--open"
                  >
                    {{
                      displayHoursOrDash(
                        contractInfos.interim_or_day_rate,
                        contractInfos.monthly_over_hours
                      )
                    }}
                  </td>
                  <!-- Structural overtime hours -->
                  <td
                    v-for="(structuralOverHours, index) in contractInfos.structural_over_hours"
                    :key="`structuralOverHours-${index}`"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ displayHoursOrDash(contractInfos.interim_or_day_rate, structuralOverHours) }}
                  </td>
                  <td
                    v-for="(nightHours, index) in contractInfos.total_night_shifts"
                    :key="`nightHours-${index}`"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ nightHours }}
                  </td>
                  <td
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ contractInfos.total_sundays }}
                  </td>
                  <td class="column__cell prime-column-cell column__cell--open relative">
                    {{ holidayHours(contractInfos) }}
                    <div
                      v-if="showHolidayCounterPopover(contractInfos) &&
                        holidayHours(contractInfos) !== 0"
                      class="report-holidays__total report-holidays__popover"
                    >
                      <table>
                        <tr>
                          <th class="report-holidays__description">
                            {{ $t('reports.over_hours.working_hours_to_pay') }}
                          </th>
                          <td class="report-holidays__value">
                            {{ contractInfos.paid_holidays_hours.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="report-holidays__description">
                            {{ $t('reports.over_hours.working_hours_to_recover') }}
                          </th>
                          <td class="report-holidays__value">
                            {{ contractInfos.recovered_holidays_hours.toFixed(2) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="
                              report-holidays__description
                              report-holidays__value--total
                            "
                          >
                            {{ $t('reports.over_hours.total_working_holidays_hours') }}
                          </th>
                          <td
                            class="
                              report-holidays__value
                              report-holidays__value--total
                            "
                          >
                            {{ totalHolidayHours(contractInfos) }}
                          </td>
                        </tr>
                      </table>
                      <div class="report-holidays__popover-caret" />
                      <div class="report-holidays__separator" />
                    </div>
                  </td>
                  <td
                    v-if="showFirstMay"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ contractInfos.may_first_hours }}
                  </td>
                  <td
                    v-if="showPentecost"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ contractInfos.pentecost_hours }}
                  </td>
                  <td
                    v-if="showCuts"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ contractInfos.total_cuts }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <!--
            Previously a shop with modulation but without majoration could not access this tab
            Now we're allowing them to consult only structural over hours
          -->
          <table v-else>
            <thead class="column__title-wrap">
              <th
                v-if="showStructuralOverHours"
                class="column__title column__title--open"
              >
                {{ $t('reports.salary_data.total_structural_over_hours') }}
              </th>
              <!-- Structural overtime hours -->
              <th
                v-for="(structuralOverHoursColumn, index) in structuralOverHoursColumns"
                :key="`structuralOverHours-${structuralOverHoursColumn}-${index}`"
                class="column__title column__title--open"
              >
                {{ structuralOverHoursColumn }}
              </th>
            </thead>
            <tbody>
              <template v-for="employeeInfos in employeesInfos">
                <tr
                  v-for="contractInfos in employeeInfos.contracts_infos"
                  :key="contractInfos.contract.id"
                  class="column__cell-wrap"
                >
                  <td
                    v-if="showStructuralOverHours"
                    class="column__cell column__cell--open"
                  >
                    {{
                      displayHoursOrDash(
                        contractInfos.interim_or_day_rate,
                        contractInfos.monthly_over_hours
                      )
                    }}
                  </td>
                  <!-- Structural overtime hours -->
                  <td
                    v-for="(structuralOverHours, index) in contractInfos.structural_over_hours"
                    :key="`structuralOverHours-${index}`"
                    class="column__cell prime-column-cell column__cell--open"
                  >
                    {{ displayHoursOrDash(contractInfos.interim_or_day_rate, structuralOverHours) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'OverHoursColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    holidayMaj: {
      type: Number,
      default: 0,
    },
    firstMayMaj: {
      type: Number,
      default: 0,
    },
    sundayMaj: {
      type: Number,
      default: 0,
    },
    cutLength: {
      type: Number,
      default: 0,
    },
    modulation: {
      type: Boolean,
      default: false,
    },
    modulationMajoration: {
      type: Boolean,
      default: false,
    },
    showCuts: {
      type: Boolean,
      default: false,
    },
    showFirstMay: {
      type: Boolean,
      default: false,
    },
    showPentecost: {
      type: Boolean,
      default: false,
    },
    flexibleConvention: {
      type: Boolean,
      default: false,
    },
    nightHoursColumns: {
      type: Array,
      default: () => [],
    },
    overHoursColumns: {
      type: Array,
      default: () => [],
    },
    complementaryHoursColumns: {
      type: Array,
      default: () => [],
    },
    structuralOverHoursColumns: {
      type: Array,
      default: () => [],
    },
    holidaysCounterConfigured: {
      type: Boolean,
      default: false,
    },
    conventionOnlyHasOneChms: {
      type: Boolean,
      default: false,
    },
    showStructuralOverHours: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: this.$t('reports.over_hours.title'),
      modifiedPrimeUserId: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
  methods: {
    // TOFIX DEV-11747:
    // complementary hours maj slice in AccountingHelper#split_complementary_hours
    // returns array of [0, complementaryHours] when conventionOnlyHasOneChms thus we keep complementaryHours[1]
    computeComplementaryHours(complementaryHours) {
      return complementaryHours.includes('-') || !this.conventionOnlyHasOneChms ? complementaryHours : [complementaryHours[1]];
    },
    displayHoursOrDash(isInterimOrDayRate, hours, showDash = false) {
      if (
        isInterimOrDayRate ||
        (
          showDash &&
          this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER') &&
          this.currentShop.attributes.hybridModulation
        )
      ) return '-';
      if (Number(hours) === hours && hours % 1 !== 0) {
        return parseFloat(hours).toFixed(2);
      }
      return hours;
    },
    holidayHours(contractInfos) {
      return this.holidaysCounterConfigured ?
        this.totalHolidayHours(contractInfos) :
        contractInfos.holiday_hours.toFixed(2);
    },
    totalHolidayHours(contractInfos) {
      if ((contractInfos.paid_holidays_hours + contractInfos.recovered_holidays_hours) === 0) {
        return 0;
      }

      return (
        contractInfos.paid_holidays_hours + contractInfos.recovered_holidays_hours
      ).toFixed(2);
    },
    showHolidayCounterPopover(contractInfos) {
      return this.holidaysCounterConfigured && !contractInfos.interim_or_day_rate;
    },
  },
};
</script>

<style lang="scss" scoped>
.pay-elements-title {
  border-top: 4px solid #ff1d45;
}

.report-holidays__total {
  font-size: .8em;
  font-weight: bold;
  color: #aaa;
}

.column__title-wrap {
  height: 68.5px;
}

.column__title--open {
  min-width: 160px !important;
}

.column__cell--open .report-holidays__popover {
  display: none;
}

.column__cell--open:hover .report-holidays__popover {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 20px rgba(222, 225, 235, .8);
  border-top: 3px #ff1d45 solid;
  border-radius: 3px;
  font-size: .9em;
  width: 255px;
  height: 90px;
  bottom: 50px;
  left: -85px;
  z-index: 5;
}

.report-holidays__popover-caret {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: 135px;
  bottom: -4px;
  z-index: -1;
}

.report-holidays__separator {
  position: absolute;
  left: 200px;
  top: 12px;
  height: 65px;
  width: 1px;
  border-left: 1px solid #dcdcdc;
}

.column__cell--open:hover:first-child,
.column__cell--open:hover:nth-child(2) {
  &.report-holidays__popover {
    bottom: -85px;
  }

  .report-holidays__popover-caret {
    bottom: 78px;
  }
}

.report-holidays__description {
  min-width: 200px;
  padding: 0 20px 0 0 !important;
}

.report-holidays__description,
.report-holidays__value {
  font-weight: normal;
  padding-left: 15px !important;
  padding-top: 10px !important;
  color: #adadad;

  &.report-holidays__value--total {
    font-weight: bold;
    color: #9a9a9a;
  }
}
</style>
