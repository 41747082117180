<template>
  <div class="wrapper">
    <span class="wrapper__label">
      {{ $t('workload_plan.modals.duplicate.apply_on') }}
    </span>
    <div>
      <SkDatePicker
        ref="multipleDatePicker"
        v-tooltip="datePickerTooltip"
        :value="value"
        :lang="$i18n.locale"
        :disabled-date="isDateDisabled"
        :disabled="isDatePickerDisabled"
        multiple
        hide-footer
        input-moment-format="YYYY-MM-DD"
        :class="inputClasses"
        :placeholder="$t('workload_plan.modals.duplicate.select_day')"
        v-on="$listeners"
      >
        <template
          v-if="numberOfSelectedDays"
          #input
        >
          <div class="wrapper__input-template">
            {{
              $tc(
                'workload_plan.modals.duplicate.days_selected',
                numberOfSelectedDays,
                { count: numberOfSelectedDays },
              )
            }}
          </div>
        </template>
      </SkDatePicker>
      <div
        v-if="numberOfSelectedDays"
        class="wrapper__days-list"
      >
        <div
          v-for="(day, index) in sortedFormattedSelectedDays"
          :key="day"
          class="wrapper__days-list-item"
        >
          <div
            v-tooltip="$t('workload_plan.modals.duplicate.delete')"
            @click.stop="removeDayByIndex(index)"
          >
            <CrossV2Icon
              class="wrapper__days-list-icon"
              fill="#E12F57"
              height="16"
              width="16"
            />
          </div>
          <span
            class="wrapper__days-list-date"
          >
            {{ day }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'MultipleDatePicker',
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
    dateToDuplicate: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('planningsState', ['isDayVisible']),
    ...mapGetters('planningsWorkloadPlans', ['maximumWeeksRange']),
    maximumDays() {
      return this.maximumWeeksRange * 7;
    },
    datePickerTooltip() {
      if (!this.isDatePickerDisabled) return null;
      return this.$t(
        'workload_plan.modals.duplicate.maximum_selected',
        { max: this.maximumDays },
      );
    },
    numberOfSelectedDays() {
      return this.value?.length || 0;
    },
    sortedFormattedSelectedDays() {
      const arrayOfSortedDates = (this.value || [])
        .map(day => skDate(day))
        .sort((a, b) => a.valueOf() - b.valueOf());
      return arrayOfSortedDates.map(day => capitalize(day.format('dddd Do MMMM YYYY')));
    },
    isDatePickerDisabled() {
      return this.value?.length >= this.maximumDays;
    },
    inputClasses() {
      return {
        wrapper__input: true,
        'wrapper__input--disabled': this.isDatePickerDisabled,
      };
    },
  },
  watch: {
    // This is to force the datepicker to not reopen
    // when going from disabled to not disabled
    isDatePickerDisabled(newValue) {
      const datepicker = this.$refs.multipleDatePicker.$refs.datepicker;
      if (newValue === false && datepicker) {
        this.$nextTick(datepicker.closePopup);
      }
    },
  },
  methods: {
    removeDayByIndex(index) {
      this.value.splice(index, 1);
    },
    isDateDisabled(date) {
      if (
        this.dateToDuplicate &&
        skDate(this.dateToDuplicate).isSame(skDate(date), 'd')
      ) return true;
      return !this.isDayVisible(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__input {
    width: 300px;

    &-template {
      min-width: 300px;
      height: 44px;
      background-color: $sk-grey-5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px;
      padding: 0 16px;

      &:hover {
        cursor: pointer;
      }
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__days-list {
    display: flex;
    flex-direction: column;
    color: $sk-grey-50;
    padding-top: 24px;

    &-icon {
      cursor: pointer;
      margin-right: 8px;
    }

    &-date {
      padding-bottom: 6px;
    }

    &-item {
      display: flex;
      align-items: center;
    }
  }

  &__label {
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
    align-self: flex-start;
    padding-top: 10px;
  }
}
</style>
