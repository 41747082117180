<template>
  <div class="loading__skeleton">
    <SkeletonHeader
      :visible-days="visibleDays"
      :planning-zoom="planningZoom"
    />
    <div class="skeleton__body">
      <div class="skeleton__body__left-slider cell--shadow cell--shadow-right" />
      <div class="skeleton__body__days">
        <div
          v-for="visibleDay in visibleDays.length"
          :key="visibleDay"
          class="skeleton__body__day"
        />
      </div>
      <div class="skeleton__body__right-slider cell--shadow cell--shadow-left" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SkeletonHeader from './SkeletonHeader.vue';

export default {
  name: 'WeeklySkeleton',
  components: {
    SkeletonHeader,
  },
  computed: {
    ...mapGetters('planningsState', ['visibleDays']),
    ...mapGetters('currentUser', ['planningZoom']),
  },
};
</script>

<style lang="scss" scoped>
.loading__skeleton {
  flex: 1;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.6s ease-out 0s,
    skeletonAnimation 1.2s ease-out 0.6s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes skeletonAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.skeleton__body {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.skeleton__body__days {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.skeleton__body__day {
  flex: 1;
  border-right: 1px solid $sk-grey-10;
  border-bottom: 1px solid $sk-grey-10;
  border-left: 0;
  border-top: 0;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #ddd 20%, white);
}

.skeleton__body__day:last-child {
  border-right: none;
}

.skeleton__body__left-slider {
  position: relative;
  width: 160px;
  display: flex;
  flex-direction: column;
}

.skeleton__body__right-slider {
  position: relative;
  width: 146px;
  display: flex;
  flex-direction: column;
}
</style>
