<template>
  <div
    :tabindex="open ? -1 : tabindex"
    :class="containerClass"
    @click="toggleDropdown"
  >
    <GlobalEvents
      v-if="open"
      @keyup.enter="selectFocusedOption"
      @keyup.escape="clearSelectedItems"
    />
    <input
      ref="input"
      v-model="inputSelectedNodes"
      class="cluster-node-select__input"
      type="hidden"
    >
    <SkDropdown
      ref="dropdown"
      :append-to-body="appendToBody"
      :disabled="disabled"
      :trigger="null"
      :x-offset="xOffset"
      placement="bottom"
      @hide-dropdown="closeDropdown"
    >
      <template #anchor>
        <div :class="selectedElementClasses">
          <label class="cluster-node-select__label">{{ label }}</label>
          {{ totalSelectedShopsCount }}
          {{ $t('employees.add_employee_modal.affiliations.employee_assignment_append') }}
          <CaretIcon
            v-if="(!disabled && open)"
            class="cluster-node-select__caret caret--down"
          />
          <CaretIcon
            v-else
            class="cluster-node-select__caret caret--up"
          />
        </div>
      </template>
      <template #menu>
        <div
          :style="dropdownMenuStyle"
          class="cluster-node-select__dropdown-menu"
        >
          <div class="cluster-node-select__search">
            <SkSearch
              ref="searchInput"
              v-model="searchQuery"
              :placeholder="$t('employees.add_employee_modal.affiliations.search_placeholder')"
              :tabindex="tabindex"
              @blur.prevent="closeDropdown"
            />
          </div>
          <div
            v-if="loading"
            class="cluster-node-select-spinner"
          >
            <SkSelectSpinner />
          </div>
          <div
            v-else
            ref="dropdown_menu_body"
            :style="clusterNodeOptionsStyle"
            class="cluster-node-select__options"
            tabindex="-1"
            @mousedown.prevent
            @focus="toggleDropdown"
            @scroll="handleScroll"
          >
            <template>
              <SkListItem
                v-if="showToggleAllItem"
                class="cluster-node-select__list-all"
                @click.stop="toggleAll"
              >
                <template #left>
                  <div class="cluster-node-select__list-all-item__left">
                    <SkCheckBox
                      :value="allChecked"
                      :disabled="loading"
                    />
                  </div>
                </template>
                {{ selectAllLabel }}
              </SkListItem>
              <ClusterNodeItem
                v-for="(clusterNode, index) in filteredClusterNodes"
                :key="index"
                :handle-height="handleHeight"
                :cluster-node="clusterNode"
                :cluster-nodes="localOptions"
                :checked-nodes="selectedNodes"
                :search-query="searchQuery"
                :user="user"
              />
            </template>
          </div>
          <div
            v-if="!loading"
            :class="footerClasses"
            tabindex="-1"
            @mousedown.prevent
            @focus="toggleDropdown"
          >
            <SkOroraButton
              size="small"
              variant="secondary"
              @click.stop="clearSelectedItems"
            >
              {{ $t('actions.cancel') }}
            </SkOroraButton>
            <SkOroraButton
              size="small"
              style="margin-left: 10px;"
              @click.stop="submitSelectedItems"
            >
              {{ $t('actions.submit') }}
            </SkOroraButton>
          </div>
        </div>
      </template>
    </SkDropdown>
  </div>
</template>

<script>
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import {
  mapState,
  mapGetters,
} from 'vuex';
import GlobalEvents from 'vue-global-events';

import { httpClient } from '@skello-utils/clients';
import ClusterNodeItem from './ClusterNodeItem';

export default {
  name: 'ClusterAffiliationSelect',
  components: { ClusterNodeItem, GlobalEvents },
  props: {
    value: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    primaryNode: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '500px',
    },
    displayModulatedShopsOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedNodes: clone(this.value),
      localOptions: [],
      height: 180,
      open: false,
      searchQuery: '',
      focusedIndex: -1,
      fullyScrolled: false,
      loading: false,
      allChecked: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('selectedEmployee', ['activeNodes']),
    ...mapState('config', ['config']),
    inputSelectedNodes: {
      get() {
        if (!this.selectedNodes) return null;

        const getValue = value => {
          if (typeof value === 'object' && value.id) return value.id;
          return value;
        };

        return this.selectedNodes.map(value => getValue(value));
      },
    },
    initialActiveShopNodes() {
      return this.activeNodes.filter(node => !node.attributes.shopCancelled);
    },
    activeShopNodes() {
      return this.localOptions.filter(node => node.attributes.shopId !== null)
        .filter(node => !node.attributes.shopCancelled);
    },
    totalSelectedShopsCount() {
      let shopsCount = 0;

      this.selectedNodes.forEach(node => {
        shopsCount += this.getShopsCount(node);
      });

      return shopsCount;
    },
    showToggleAllItem() {
      return this.localOptions.some(clusterNode => clusterNode.attributes.depth === 1);
    },
    selectAllLabel() {
      return this.isSystemAdmin ?
        this.currentOrganisation.attributes.name :
        this.$t('employees.add_employee_modal.affiliations.select_all_nodes');
    },
    xOffset() {
      return this.appendToBody ? '-175px' : '-275px';
    },
    clusterNodeOptionsStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    footerClasses() {
      return {
        'cluster-node-select__footer': true,
        'cluster-node-select__footer--no-shadow': this.fullyScrolled,
      };
    },
    selectedElementClasses() {
      return {
        'cluster-node-select__selected-option': true,
        'cluster-node-select__selected-option--filled-in-with-label': this.selectedNodes && this.label,
        'cluster-node-select__selected-option--disabled': this.disabled,
      };
    },
    containerClass() {
      return {
        'cluster-node-select': true,
        'cluster-node-select--small': this.appendToBody,
      };
    },
    filteredClusterNodes() {
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();

        return this.localOptions.filter(item => item.attributes.name.toLowerCase().includes(query));
      }

      return this.localOptions.filter(clusterNode => clusterNode.attributes.depth === 1);
    },
    dropdownMenuStyle() {
      return { width: this.width };
    },
  },
  watch: {
    value(newValue) {
      if (newValue !== this.selectedNodes) {
        this.selectedNodes = clone(newValue);
      }
    },
  },

  mounted() {
    this.handleScroll();
    this.listenOnRoot('selectNode', this.selectClusterNode);
  },

  methods: {
    /* eslint-disable padded-blocks */
    selectClusterNode(clusterNode) {
      if (this.localOptions.length === 0) return;

      const ancestor = this.checkedAncestor(clusterNode);

      // needed only for report page and modulation only filtering
      const checkedChildrenShop = this.allChildren(clusterNode).filter(child => (
        this.selectedNodes.find(checkedValue => checkedValue.id === child.id)
      ));
      const checkedChildrenShopIds = checkedChildrenShop.map(model => model.id);

      // If cluster node already checked
      if (this.selectedNodes.find(checkedValue => checkedValue.id === clusterNode.id)) {
        this.selectedNodes = this.selectedNodes.filter(
          checkedValue => checkedValue.id !== clusterNode.id,
        );

        this.pushPrimaryIfInChildren(clusterNode);
      } else if (
        this.displayModulatedShopsOnly &&
        this.selectedNodes.find(checkedValue => checkedChildrenShopIds.includes(checkedValue.id))
      ) {
        this.removeChildren(clusterNode);
      } else if (ancestor) {

        // if the ancestor is an uncle
        if (ancestor.id !== clusterNode.attributes.parentId) {
          const parent = this.parent(clusterNode);

          const ancestorChildren = this.children(ancestor);

          const parentAncestorIds = parent.attributes.ancestorIds;
          parentAncestorIds.push(Number(parent.id));

          const ancestorChildrenToCheck = ancestorChildren.filter(cN => (
            !parentAncestorIds.includes(cN.id)
          ));

          this.selectedNodes = [...this.selectedNodes, ...ancestorChildrenToCheck];
        }

        this.selectedNodes = [...this.selectedNodes, ...this.siblings(clusterNode)];
        const ancestorIndex = this.selectedNodes.indexOf(ancestor);

        this.selectedNodes.splice(ancestorIndex, 1);
        this.pushPrimaryIfInChildren(clusterNode);

      // if all siblings checked, push parent to values (ie: recursive) and check if all nodes are present
      } else if (this.allSiblingsChecked(clusterNode) &&
        clusterNode.attributes.depth !== 1 &&
        this.parent(clusterNode).attributes.editable) {

        this.removeSiblings(clusterNode);
        this.selectClusterNode(this.parent(clusterNode));

      // if cluster node is network and we want to select it
      } else if (clusterNode.attributes.shopId === null) {
        // remove all childrens from checked values
        this.removeChildren(clusterNode);
        this.pushClusterNodeToSelectedNodes(clusterNode, { shopLevel: false });

      // if cluster node is a shop node, just push it to array
      } else {
        this.pushClusterNodeToSelectedNodes(clusterNode, { shopLevel: false });
      }

      this.allChecked = this.totalSelectedShopsCount === this.activeShopNodes.length;
      this.handleScroll();
    },
    getShopsCount(node) {
      const shops = this.activeShopNodes.length > 0 ?
        this.activeShopNodes :
        this.initialActiveShopNodes;

      if (node.attributes.shopId !== null && !node.attributes.shopCancelled) return 1;
      if (node.attributes.shopsCount) return node.attributes.shopsCount;

      return shops.filter(option => node.attributes.descendantIds.includes(Number(option.id)),
      ).length;
    },
    pushClusterNodeToSelectedNodes(clusterNode, { shopLevel }) {
      // if not in report section, push whatever node level or modulation status
      if (!this.displayModulatedShopsOnly) {
        this.selectedNodes.push(clusterNode);
        return;
      }
      // if on cluster node level, clusterNode is push only if all children are in modulation status
      if (!shopLevel) {
        const children = this.allChildren(clusterNode);
        const childrenWithModulation =
          children.filter(child => (
            child.attributes.modulationStatus === this.config.modulation_activated
          ));
        if (children.length === childrenWithModulation.length) {
          this.selectedNodes.push(clusterNode);
        } else {
          childrenWithModulation.forEach(child => {
            this.pushClusterNodeToSelectedNodes(child, { shopLevel: true });
          });
        }
        return;
      }
      // if shop level, push the cluster only if modulation is activated
      if (clusterNode.attributes.modulationStatus === this.config.modulation_activated) {
        this.selectedNodes.push(clusterNode);
      }
    },
    checkedAncestor(clusterNode) {
      return this.selectedNodes.find(
        checkedValue => clusterNode.attributes.ancestorIds.includes(Number(checkedValue.id)),
      );
    },
    allSiblingsChecked(clusterNode) {
      const siblingIds = this.siblings(clusterNode).map(sibling => sibling.id);

      const valueIds = this.selectedNodes.map(checkedValue => checkedValue.id);

      return isEqual(sortBy(intersection(siblingIds, valueIds)), sortBy(siblingIds));
    },
    siblings(clusterNode) {
      const activeSiblings = [];
      const allSiblings = this.localOptions.filter(option => (
        option.attributes.ancestry === clusterNode.attributes.ancestry &&
        option.id !== clusterNode.id
      ));
      allSiblings.forEach(sibling => {
        if (sibling.attributes.shopCancelled) return;
        activeSiblings.push(sibling);
      });
      return activeSiblings;
    },
    parent(childNode) {
      return this.localOptions.find(clusterNode => (
        String(clusterNode.id) === String(childNode.attributes.parentId)
      ));
    },
    children(parentNode) {
      return this.localOptions.filter(
        clusterNode => clusterNode.attributes.parentId === parentNode.id,
      );
    },
    allChildren(clusterNode) {
      let allChildren = [];
      if (clusterNode.attributes.shopId === null) {
        this.children(clusterNode).forEach(child => {
          allChildren.push(this.allChildren(child));
          allChildren = allChildren.flat();
        });
      } else {
        allChildren.push(clusterNode);
      }
      return allChildren;
    },
    removeSiblings(clusterNode) {
      const checkedValues = this.selectedNodes;

      this.siblings(clusterNode).forEach(sibling => {
        const indexToRemove = checkedValues.findIndex(
          checkedValue => checkedValue.id === sibling.id,
        );

        if (indexToRemove === -1) return;

        this.selectedNodes.splice(indexToRemove, 1);
      });
    },
    removeChildren(clusterNode) {
      const children = this.children(clusterNode);

      children.forEach(child => {
        const checkedValuesIds = this.selectedNodes.map(value => value.id);

        if (child.attributes.shopId === null) this.removeChildren(child);

        const indexToRemove = checkedValuesIds.findIndex(id => id === child.id);

        if (indexToRemove === -1) return;

        this.selectedNodes.splice(indexToRemove, 1);
      });
    },
    pushPrimaryIfInChildren(clusterNode) {
      const children = this.children(clusterNode);

      children.forEach(child => {
        if (child.attributes.shopId === null) {
          this.pushPrimaryIfInChildren(child);
        } else if (this.primaryNode.id === child.id) {
          this.selectClusterNode(this.primaryNode);
        }
      });
    },
    selectFocusedOption() {
      if (this.focusedIndex === -1) {
        this.closeDropdown();
        return;
      }

      this.selectClusterNode(this.localOptions[this.focusedIndex]);
    },
    toggleAll() {
      this.allChecked = !this.allChecked;

      this.selectedNodes = [];

      if (this.allChecked) {
        this.localOptions
          .filter(clusterNode => (
            clusterNode.attributes.editable && clusterNode.attributes.shopId !== null
          ))
          .forEach(clusterNode => this.selectClusterNode(clusterNode));
        this.localOptions
          .filter(clusterNode => clusterNode.attributes.editable &&
            clusterNode.attributes.shopId === null &&
            clusterNode.attributes.descendantIds.length === 0,
          )
          .forEach(clusterNode => this.selectClusterNode(clusterNode));
      } else {
        this.selectClusterNode(this.primaryNode);
      }
    },
    submitSelectedItems() {
      let primaryNode = this.localOptions.find(
        option => String(option.attributes.shopId) === String(this.user.attributes.shopId),
      );

      if (!primaryNode) {
        primaryNode = this.initialActiveShopNodes.find(
          option => String(option.attributes.shopId) === String(this.user.attributes.shopId),
        );
      }

      this.$emit('new-primary-node-selected', primaryNode.id);

      // for report page, we want to return not only the cluster nodes but all the shops
      const values = this.displayModulatedShopsOnly ?
        this.selectedNodes.flatMap(node => this.allChildren(node)) :
        this.selectedNodes;

      this.$emit('input', { values, allChecked: this.allChecked });

      this.closeDropdown();
    },
    clearSelectedItems() {
      this.selectedNodes = clone(this.value);
      this.closeDropdown();
    },
    closeDropdown() {
      if (!this.open) return;

      if (event && (!!event.target.className.match('search') ||
        !!event.target.className.match('select__footer'))) return;

      this.forceCloseDropdown();
    },
    forceCloseDropdown() {
      this.$refs.searchInput.blur();
      this.$el.blur();

      this.$refs.dropdown.hide();
      this.handleHide();
    },
    toggleDropdown() {
      if (this.open) {
        this.closeDropdown();
        return;
      }

      this.$refs.dropdown.open();

      this.open = true;

      this.$refs.searchInput.focus();

      this.fetchClusterNodes();
    },
    fetchClusterNodes() {
      this.loading = true;

      const params = { can_create_employee: true, skip_pagination: true, report_nodes: true };

      httpClient
        .get('/v3/api/cluster_nodes', { params })
        .then(response => {
          this.localOptions = response.data.data.map(clusterNode => {
            const modulationStatus =
              clusterNode.attributes.modulationStatus ?
                String(clusterNode.attributes.modulationStatus) :
                null;

            const isModulationActive = modulationStatus === this.config.modulation_activated;

            let tooltip = '';
            if (!clusterNode.attributes.editable) {
              tooltip = this.$t('employees.add_employee_modal.affiliations.indeterminate_tooltip');
            } else if (
              this.displayModulatedShopsOnly &&
              clusterNode.attributes.shopId &&
              !isModulationActive
            ) {
              tooltip = this.$t('reports.counter_reset.modal.no_modulation_tooltip');
            }

            return {
              id: clusterNode.id,
              type: 'clusterNode',
              attributes: {
                name: clusterNode.attributes.name,
                shopId:
                  clusterNode.attributes.shopId ? String(clusterNode.attributes.shopId) : null,
                shopCancelled: clusterNode.attributes.shopCancelled,
                organisationId: String(clusterNode.attributes.organisationId),
                parentId: String(clusterNode.attributes.parentId),
                depth: clusterNode.attributes.depth,
                ancestry: clusterNode.attributes.ancestry,
                editable: this.displayModulatedShopsOnly ?
                  clusterNode.attributes.shopId === null ||
                  (clusterNode.attributes.editable && isModulationActive) :
                  clusterNode.attributes.editable,
                ancestorIds: clusterNode.attributes.ancestorIds,
                descendantIds: clusterNode.attributes.descendantIds,
                modulationStatus,
                tooltip,
              },
            };
          });
        })
        .finally(() => {
          this.loading = false;

          if (this.selectedNodes.length === 1 && !this.selectedNodes[0].attributes.ancestry) {
            this.allChecked = true;
          } else {
            this.allChecked = this.totalSelectedShopsCount === this.activeShopNodes.length;
          }

          this.handleHeight();
        });
    },
    handleHide() {
      this.open = false;
      this.$refs.searchInput.clearQuery();

      this.selectedNodes = clone(this.value);
      this.resetScroll();
    },
    handleHeight(newHeight) {
      const el = this.$refs.dropdown_menu_body;
      if (!el) return;

      // Apply a max-height on the scrolling area
      this.height = Math.min(180, el.scrollHeight + newHeight);
    },
    handleScroll() {
      const el = this.$refs.dropdown_menu_body;
      if (!el) return;

      this.fullyScrolled = el.scrollHeight === el.clientHeight + el.scrollTop;
    },
    resetScroll() {
      const el = this.$refs.dropdown_menu_body;
      if (!el) return;
      el.scrollTop = 0;
    }, /* eslint-enable padded-blocks */
  },
};
</script>

<style lang="scss">
.cluster-node-select {
  position: relative;
  outline: 0;

  .cluster-node-select__empty-option {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: default;
  }

  &--small {
    width: 350px;
  }
}

.cluster-node-select__list-options__wrapper {
  padding-left: 15px;
}

.cluster-node-select__selected-option,
.cluster-node-select__selected-option--filled-in,
.cluster-node-select__selected-option--disabled {
  background: $sk-white;
  border-radius: 4px;
  height: 45px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  min-width: 350px;
}

.cluster-node-select-spinner {
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 10px;
  height: 35px;
}

.cluster-node-select__label {
  position: absolute;
  margin: 0;
  left: 10px;
  font-weight: $fw-regular;
  color: $sk-grey;
  cursor: pointer;
}

.cluster-node-select__selected-option {
  display: flex;
  align-items: center;
  background: $sk-grey-5;
  border-radius: 4px;
  height: 45px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;

  &.cluster-node-select__selected-option--filled {
    background: $sk-grey-10;
    border: none;
  }

  &.cluster-node-select__selected-option--outlined {
    background: none;
    border: 1px solid $sk-grey-10;
  }
}

.cluster-node-select__label {
  font-size: $fs-text-m;
}

.cluster-node-select__selected-option--filled-in-with-label {
  align-items: flex-end;
  padding-bottom: 5px;

  .cluster-node-select__label {
    top: 5px;
    font-size: $fs-text-s;
  }
}

.cluster-node-select__selected-option--disabled {
  display: flex;
  cursor: not-allowed;
  opacity: .6;
}

.cluster-node-select__caret {
  margin: 20px 5px auto auto;
}

.caret--down {
  transform: rotate(180deg);
  transition: transform .3s;
}

.caret--up {
  transform: rotate(0deg);
  transition: transform .3s;
}

.cluster-node-select__dropdown-menu {
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  position: absolute;
}

.cluster-node-select__search {
  padding: 10px;
}

.cluster-node-select__options {
  overflow: auto;
  cursor: pointer;
}

.cluster-node-select__list-item,
.cluster-node-select__list-item--focused {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 15px;

  &:hover {
    background: $sk-grey-10;
  }
}

.cluster-node-select__list-all {
  height: 45px;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid $sk-grey-10;
  border-top: 1px solid $sk-grey-10;

  &:hover {
    background: $sk-grey-10;
  }
}

.cluster-node-select-all-spinner {
  height: 250px;
  display: flex;
  justify-content: center;
}

.cluster-node-select__list-item--select-all {
  padding-left: 50px;
}

.cluster-node-select__list-all-item__left {
  margin-right: 15px;
}

.cluster-node-select__list-item--focused {
  background: $sk-grey-10;
}

.cluster-node-select__list-item__left {
  margin-right: 15px;
}

.cluster-node-select__footer {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $sk-grey-10;
  box-shadow: 0 -5px 8px rgba(0, 0, 0, .1);

  &--no-shadow {
    box-shadow: none;
  }
}
</style>
