import { capitalize } from '@skello-utils/formatting/strings';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

export const formatEmployees = employees => employees.reduce((acc, employee) => ({
  ...acc,
  [employee.id]: {
    attributes: {
      firstName: employee.attributes.firstName,
      lastName: employee.attributes.lastName,
    },
  },
}),
{});

export const fullName = employee => (employee ?
  `${capitalize(employee.attributes.firstName)} ${capitalize(employee.attributes.lastName)}` :
  null);

export const getEventSubtype = (action, isAbsence, isDailyView) => {
  let subtypeDay;
  let subtypeWeek;
  switch (action) {
    case 'CREATE':
      subtypeDay = isAbsence ?
        EVENT_SUBTYPE_ACTION.CREATE_DAY_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.CREATE_DAY_PLANNING_SHIFT;
      subtypeWeek = isAbsence ?
        EVENT_SUBTYPE_ACTION.CREATE_WEEK_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.CREATE_WEEK_PLANNING_SHIFT;
      break;
    case 'UPDATE_SWAP_USER_SHIFTS':
      subtypeWeek = EVENT_SUBTYPE_ACTION.UPDATE_SWAP_USER_SHIFTS;
      break;
    case 'UPDATE':
      subtypeDay = isAbsence ?
        EVENT_SUBTYPE_ACTION.UPDATE_DAY_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.UPDATE_DAY_PLANNING_SHIFT;
      subtypeWeek = isAbsence ?
        EVENT_SUBTYPE_ACTION.UPDATE_WEEK_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.UPDATE_WEEK_PLANNING_SHIFT;
      break;
    case 'DELETE':
      subtypeDay = isAbsence ?
        EVENT_SUBTYPE_ACTION.DELETE_DAY_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.DELETE_DAY_PLANNING_SHIFT;
      subtypeWeek = isAbsence ?
        EVENT_SUBTYPE_ACTION.DELETE_WEEK_PLANNING_ABSENCE :
        EVENT_SUBTYPE_ACTION.DELETE_WEEK_PLANNING_SHIFT;
      break;
    case 'BULK_DELETE':
      subtypeDay = EVENT_SUBTYPE_ACTION.DELETE_BULK_DAY_PLANNING_SHIFT;
      subtypeWeek = EVENT_SUBTYPE_ACTION.DELETE_BULK_WEEK_PLANNING_SHIFT;
      break;
    default:
      throw new Error('Unsupported action');
  }
  return isDailyView ? subtypeDay : subtypeWeek;
};
