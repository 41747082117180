<template>
  <TitlesColumn
    id="paid-leaves-counter-titles-column"
    :custom-classes="headerClasses"
  >
    <div class="employee-counters__initialization-date-wrapper">
      <InitDateLabel
        tracker="pto"
        :init-date="initDate"
      />
    </div>
    <TitlesColumnCell :custom-classes="cellClasses">
      <div>
        <div
          v-tooltip.top="$t('employees.tabs.counters.paid_leaves_counter.tooltip.real_or_previsional_computing')"
          class="employee-paid-leaves-counter__line-title__acquired"
        >
          {{ $t('employees.tabs.counters.paid_leaves_counter.acquired') }}
        </div>
        <a
          :href="$t('employees.tabs.counters.paid_leaves_counter.link')"
          class="sk-link--small employee-paid-leaves-counter__subtitle"
          target="_blank"
        >
          {{ $t('employees.tabs.counters.paid_leaves_counter.learn_more') }}
        </a>
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell :custom-classes="cellClasses">
      <div
        v-tooltip="$t('employees.tabs.counters.paid_leaves_counter.tooltip.planning_paid_leaves')"
        class="employee-paid-leaves-counter__line-title__spent"
      >
        {{ $t('employees.tabs.counters.paid_leaves_counter.spent') }}
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell :custom-classes="manualModificationCellClasses">
      <span class="employee-paid-leaves-counter__line-title__before_manual_edit">
        {{ $t('employees.tabs.counters.paid_leaves_counter.before_manual_edit') }}
      </span>
    </TitlesColumnCell>
    <TitlesColumnCell :custom-classes="cellClasses">
      <div>
        <div class="employee-paid-leaves-counter__line-title__manual_edit">
          {{ $t('employees.tabs.counters.paid_leaves_counter.manual_edit') }}
        </div>
        <div class="employee-paid-leaves-counter__line-title__manual_edit__subtitle">
          {{ $t('employees.tabs.counters.paid_leaves_counter.click_to_edit') }}
        </div>
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell :custom-classes="manualModificationCellClasses">
      <span class="employee-paid-leaves-counter__line-title__after_manual_edit">
        {{ $t('employees.tabs.counters.paid_leaves_counter.after_manual_edit') }}
      </span>
    </TitlesColumnCell>
  </TitlesColumn>
</template>

<script>

import {
  TitlesColumn,
  TitlesColumnCell,
  InitDateLabel,
} from '../../shared/components';

export default {
  name: 'PaidLeavesCounterTitlesColumn',
  components: { TitlesColumn, TitlesColumnCell, InitDateLabel },
  props: {
    initDate: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    headerClasses() {
      return ['paid-leaves-counter__titles-column'];
    },
    cellClasses() {
      return ['paid-leaves-counter__titles-column__cell'];
    },
    manualModificationCellClasses() {
      return [...this.cellClasses, 'cell-text--bold'];
    },
  },
};
</script>
<style lang="scss" scoped>
#paid-leaves-counter-titles-column {
  &.paid-leaves-counter__titles-column {
    width: 360px;
    margin: 0;
    background-color: white;
    padding-top: 0;
    color: $sk-grey;
    border-radius: 3px 0 0 3px;
    border-top: solid 3px transparent;
    box-shadow: 0 0 0;
  }

  .paid-leaves-counter__titles-column__cell {
    height: 60px;
    margin: 0;
    position: relative;
    border-top: solid 1px $sk-grey-10;
    font-size: $fs-text-m;
    padding-left: 10px;
  }

  .cell-text--bold {
    font-weight: $fw-semi-bold;
  }
}

.employee-counters__initialization-date-wrapper {
  display: flex;
  height: 163px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $sk-black;
}
</style>
