<template>
  <tr class="absences__table__row">
    <td class="absences__table__cell-employee">
      <div class="absences__table__cell-employee-medallion">
        <SkMedallion
          :text="medallionInitial"
          background-color="#2b66fe"
          color="#fff"
        />
      </div>
      <div
        v-if="isEmployeesView"
        class="absences__table__cell-employee-name"
      >
        {{ dayLabel }}
      </div>
      <div
        v-else
        v-tooltip.top="userNameTooltip"
        class="absences__table__cell-employee-name text-truncate"
      >
        {{ employeeFullName }}
      </div>
    </td>
    <td class="absences__table__cell-absence-type">
      {{ absenceName }}
    </td>
    <td>
      {{ absenceHours }}
    </td>
    <td class="absences__table__cell-total">
      {{ absenceDuration }}
    </td>
  </tr>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
} from '@app-js/shared/constants/shift';

export default {
  name: 'AbsenceRow',
  props: {
    absence: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('badgings', ['isEmployeesView']),
    dayLabel() {
      return upperFirst(skDate(this.absence.attributes.startsAt).format('dddd'));
    },
    medallionInitial() {
      return this.isEmployeesView ? this.dayLabel[0] : this.firstNameInitial;
    },
    firstNameInitial() {
      const { firstName } = this.absence.relationships.user.attributes;

      return upperFirst(firstName[0]);
    },
    employeeFullName() {
      const { firstName, lastName } = this.absence.relationships.user.attributes;

      return `${upperFirst(firstName)} ${upperFirst(lastName)}`;
    },
    absenceName() {
      return this.absence.relationships.poste.attributes.name;
    },
    absenceHours() {
      switch (this.absence.attributes.absenceCalculation) {
        case ABSENCE_TYPE_DAY: return this.$t('badgings.days.tabs.absences.calculation.day');
        case ABSENCE_TYPE_HALF_DAY: return this.$t('badgings.days.tabs.absences.calculation.half-day');
        case ABSENCE_TYPE_HOURS: return this.timeToText;
        default: return '-';
      }
    },
    userNameTooltip() {
      return this.employeeFullName.length > 40 ? this.employeeFullName : null;
    },
    absenceDuration() {
      // eslint-disable-next-line max-len
      const durationInHours = skDate.utc(skDate.duration(this.absence.attributes.durationInSeconds, 's').asMilliseconds()).format('HH:mm');

      return durationInHours.replace(':', 'h');
    },
    timeToText() {
      const { startsAt, endsAt } = this.absence.attributes;

      return `${skDate(startsAt).utc().format('HH:mm')} - ${skDate(endsAt).utc().format('HH:mm')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
tr.absences__table__row {
  border-bottom: 1px solid $sk-grey-10;

  td {
    padding: 7px 0;
    vertical-align: middle;
  }

  .absences__table__cell-employee {
    .absences__table__cell-employee-medallion {
      float: left;
      padding-right: 10px;

      .sk-medallion {
        font-weight: $fw-semi-bold;
      }
    }

    .absences__table__cell-employee-name {
      margin-top: 6px;
    }
  }

  .absences__table__cell-absence-type {
    padding-right: 50px;
  }

  .absences__table__cell-total {
    font-weight: $fw-semi-bold;
    padding-right: 35px;
  }
}
</style>
