import { httpClient } from '@skello-utils/clients';

const initialState = {
  navContext: {
    shopId: null,
    clusterNodeId: null,
    name: null,
    viewAllShops: false,
    viewIntermediateNodes: false,
    badging: false,
  },
  currentClusterNodeLoading: false,
};

const mutations = {
  updateNavContext(state, newContext) {
    state.navContext = newContext;
  },

  fetchCurrentClusterNodePending(state) {
    state.currentClusterNodeLoading = true;
  },

  fetchCurrentClusterNodeComplete(state) {
    state.currentClusterNodeLoading = false;
  },
};

const actions = {
  /**
   * Set given organisation as current navigation context
   */
  selectOrganisation({ dispatch, commit }, organisation) {
    commit('updateNavContext', {
      clusterNodeId: String(organisation.attributes.rootNodeId),
      shopId: null,
      name: organisation.attributes.name,
      viewAllShops: true,
      viewIntermediateNodes: false,
    });

    return dispatch('modifyContextCallback', String(organisation.attributes.rootNodeId));
  },

  selectClusterNode({ commit, dispatch }, { clusterNode, childrenAreShopNodes }) {
    commit('updateNavContext', {
      clusterNodeId: String(clusterNode.id),
      shopId: null,
      ancestry: clusterNode.attributes.ancestry,
      name: clusterNode.attributes.name,
      viewIntermediateNodes: true,
      viewAllShops: false,
      childrenAreShopNodes,
    });

    return dispatch('modifyContextCallback', String(clusterNode.id));
  },

  /**
   * Set given shop as current navigation context
   */
  selectShop({ dispatch, commit }, shop) {
    commit('updateNavContext', {
      clusterNodeId: String(shop.attributes.clusterNodeId),
      shopId: shop.id,
      name: shop.attributes.name,
      viewAllShops: false,
      viewIntermediateNodes: false,
      badging: shop.attributes.badging,
    });

    return dispatch('modifyContextCallback', String(shop.attributes.clusterNodeId));
  },

  /**
   * Callback when navContext is updated
   */
  modifyContextCallback({ dispatch }, clusterNodeId) {
    const fetchPlatformAlerts = dispatch('platformAlerts/fetchPlatformAlerts', clusterNodeId, { root: true });
    const fetchCurrentLicense = dispatch('currentLicense/fetchCurrentLicense', clusterNodeId, { root: true });
    return Promise.all([fetchPlatformAlerts, fetchCurrentLicense]);
  },

  fetchCurrentClusterNode({ commit, dispatch }, clusterNodeId) {
    commit('fetchCurrentClusterNodePending');

    return httpClient
      .get(`/v3/api/cluster_nodes/${clusterNodeId}`)
      .then(response => {
        dispatch('selectClusterNode', { clusterNode: response.data.data });

        return response;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        commit('fetchCurrentClusterNodeComplete');
      });
  },
};

const getters = {
  isReportsPage: (_state, _getters, rootState) => rootState.route.name.includes('reports'),
  isProfilePage: (_state, _getters, rootState) => rootState.route.name.includes('profile'),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
