<template>
  <div class="settings__documents-management__container">
    <h1 class="sk-header--1">
      {{ title }}
    </h1>
    <p class="sk-subtitle--large">
      {{ subtitle }}
    </p>

    <SkOroraTabs v-if="isFoldersManagementEnabled">
      <SkOroraTabsItem
        :active="activeTab === 'text_documents'"
        size="large"
        index="text_documents"
        @tab-clicked="setActiveTab"
      >
        {{ $t('shop_settings.documents_management.text_documents') }}
      </SkOroraTabsItem>
      <SkOroraTabsItem
        :active="activeTab === 'folders_management'"
        size="large"
        index="folders_management"
        @tab-clicked="setActiveTab"
      >
        {{ $t('shop_settings.documents_management.folders_management') }}
      </SkOroraTabsItem>
    </SkOroraTabs>

    <div v-if="activeTab === 'text_documents'">
      <TextDocumentTemplates />
    </div>
    <div v-else-if="activeTab === 'folders_management'">
      <FoldersManagement />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextDocumentTemplates from './TextDocumentTemplates/index.vue';
import FoldersManagement from './FoldersManagement/index.vue';

export default {
  name: 'DocumentsManagement',
  components: {
    TextDocumentTemplates,
    FoldersManagement,
  },
  data() {
    return {
      activeTab: 'text_documents',
      isEventSent: false,
    };
  },
  computed: {
    ...mapGetters('foldersManagement', ['isFoldersManagementEnabled']),
    title() {
      return this.isFoldersManagementEnabled ?
        this.$t('shop_settings.documents_management.title') :
        this.$t('shop_settings.text_document_templates.title');
    },
    subtitle() {
      return this.isFoldersManagementEnabled ?
        this.$t('shop_settings.documents_management.subtitle') :
        this.$t('shop_settings.text_document_templates.subtitle');
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;

      if (!this.isEventSent && tab === 'folders_management') {
        this.$skAnalytics.track('viewed_folder_management_space');
        this.isEventSent = true;
      }
    },
  },
};
</script>

<style scoped>

.sk-orora-tabs {
    margin-top: 18px;
  }
</style>
