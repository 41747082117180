<template>
  <div class="competencies__wrapper">
    <div class="competencies__explanation">
      {{ $t(`shop_settings.tabs.automatic_planning.competencies.explanation`) }}
    </div>

    <div
      v-if="isLoadingFetch"
      class="automatic-planning__spinner"
    >
      <SkLoader size="large" />
    </div>
    <SkCard
      v-else
      class="competencies__card"
    >
      <h2 class="sk-header--2 competencies__table-title">
        {{ $t(`shop_settings.tabs.automatic_planning.competencies.table_title`) }}
      </h2>

      <SkTable
        :columns="headers"
        class="competencies__table"
      >
        <CompetenciesRow
          v-for="user in users"
          :key="user.id"
          :user="user"
          :postes="postes"
          :competencies="competencies"
          @change="userCompetenciesChanged"
        />
      </SkTable>
    </SkCard>
    <StickyBar
      :visible="showStickyBar"
      :submit-button-label="$t('shop_settings.sticky_bar.submit')"
      :submit-spinner="isLoadingCompetenciesUpdate"
      container-scroll-id="shop-settings__container"
      class="shop-settings-rules__container__sticky-bar"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

import StickyBar from '@app-js/shared/components/Stickybar';

import CompetenciesRow from './CompetenciesRow';

export default {
  name: 'Competencies',
  components: { CompetenciesRow, StickyBar },
  data() {
    return {
      headers: [
        { title: this.$t('shop_settings.tabs.automatic_planning.competencies.table.user_name') },
        { title: this.$t('shop_settings.tabs.automatic_planning.competencies.table.primary_poste') },
        { title: this.$t('shop_settings.tabs.automatic_planning.competencies.table.secondary_poste') },
      ],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState(
      'automaticPlanning',
      [
        'competencies',
        'originalCompetencies',
        'isLoadingCompetenciesUpdate',
        'isLoadingCompetenciesFetch',
      ],
    ),
    ...mapState('planningsUsers', ['users', 'usersLoading']),
    ...mapState('planningsPostes', ['postes', 'postesLoading']),
    posteOptions() {
      return this.postes.map(poste => ({
        id: poste.id,
        text: poste.attributes.name,
      }));
    },
    showStickyBar() {
      return JSON.stringify(this.competencies) !== JSON.stringify(this.originalCompetencies);
    },
    isLoadingFetch() {
      return this.usersLoading ||
        this.postesLoading ||
        this.isLoadingCompetenciesFetch;
    },
  },
  methods: {
    ...mapActions('automaticPlanning', ['submitCompetencies']),
    ...mapMutations('automaticPlanning', ['userCompetenciesChanged']),
    handleSubmit() {
      this.submitCompetencies({ shopId: this.currentShop.id, users: this.users })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_failure'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.competencies__card {
  padding: 23px;
  padding-bottom: 14px;
}

.competencies__explanation {
  font-size: $fs-text-l;
  line-height: 24px;
  color: $sk-grey;
  margin-bottom: 25px;
}

.competencies__table-title {
  margin-bottom: 12px;
}

.competencies__table {
  margin-bottom: 0;
}

.shop-settings-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
