import Vue from 'vue';
import { Quill } from 'vue2-editor';

import TemplateVariable from './TemplateVariable';

const Embed = Quill.import('blots/embed');

const TemplateVariableComponentClass = Vue.extend(TemplateVariable);

// See documentation https://github.com/quilljs/parchment/#blots
// Inspirated from https://github.com/quilljs/quill/blob/develop/formats/image.js
// https://github.com/quilljs/quill/issues/2140
// https://github.com/quilljs/quill/issues/1759
export default class TemplateVariableBlot extends Embed {
  static create(value) {
    const { label, field } = value;
    const node = super.create(value);

    const component = new TemplateVariableComponentClass({
      propsData: {
        inText: true,
        label,
        field,
      },
    });
    component.$mount();
    const html = component.$el.innerHTML;
    node.innerHTML = html;

    node.classList.add('sk-template-variable--in-text');

    node.setAttribute('data-field', field);
    node.setAttribute('data-label', label);

    component.$destroy();

    return node;
  }

  static value(domNode) {
    return {
      field: domNode.getAttribute('data-field'),
      label: domNode.getAttribute('data-label'),
    };
  }
}

TemplateVariableBlot.blotName = 'template-variable';
TemplateVariableBlot.tagName = 'template-variable';
