<template>
  <div>
    <div class="advance-payment-partners__title">
      <p class="sk-subtitle--large mt-3">
        {{ $t('organisation_settings.tabs.advance_payment_partners.title') }}
      </p>
      <p class="sk-subtitle--large mt-3">
        {{ $t('organisation_settings.tabs.advance_payment_partners.title2') }}
      </p>
    </div>

    <PartnerCard
      class="advance-payment-partners__card"
      icon-name="Rosaly"
      :disabled="false"
      :name="$t('organisation_settings.tabs.advance_payment_partners.rosaly.name')"
      read-only
    >
      <div class="advance-payment-partners__card-description">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('organisation_settings.tabs.advance_payment_partners.rosaly.description')" />
        <span>
          {{ $t('organisation_settings.tabs.advance_payment_partners.rosaly.text_before_link') }}
        </span>
        <SkOroraLink
          :href="getPartnerLink('rosaly')"
          target="_blank"
          rel="noopener noreferer"
        >
          <span>{{ $t('organisation_settings.tabs.advance_payment_partners.rosaly.link') }}</span>
        </SkOroraLink>
        <span>
          {{ $t('organisation_settings.tabs.advance_payment_partners.rosaly.text_after_link') }}
        </span>
      </div>
    </PartnerCard>

    <PartnerCard
      :disabled="false"
      class="advance-payment-partners__card"
      icon-name="Spayr"
      :name="$t('organisation_settings.tabs.advance_payment_partners.spayr.name')"
      read-only
    >
      <div class="advance-payment-partners__card-description">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('organisation_settings.tabs.advance_payment_partners.spayr.description')" />
        <span>
          {{ $t('organisation_settings.tabs.advance_payment_partners.spayr.text_before_link') }}
        </span>
        <SkOroraLink
          :href="getPartnerLink('spayr')"
          target="_blank"
          rel="noopener noreferer"
        >
          <span>{{ $t('organisation_settings.tabs.advance_payment_partners.spayr.link') }}</span>
        </SkOroraLink>
        <span>
          {{ $t('organisation_settings.tabs.advance_payment_partners.spayr.text_after_link') }}
        </span>
      </div>
    </PartnerCard>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticRootUrl } from '@config/env';

import PartnerCard from '../shared/PartnerCard';

export default {
  name: 'AdvancePaymentPartners',
  components: { PartnerCard },
  computed: {
    ...mapState('currentUser', ['currentUser']),
  },
  methods: {
    getPartnerLink(partnerName) {
      return `${staticRootUrl}/documents/${this.getFileName(partnerName)}`;
    },
    getFileName(partnerName) {
      if (partnerName === 'rosaly') {
        switch (this.currentUser.attributes.lang) {
          case 'en': return 'ROSALY_COLLECTION_OF_CONSENT.pdf';
          case 'es': return 'ROSALY_RECOGIDA_DEL_CONSENTIMIENTO.pdf';
          case 'de': return 'ROSALY_EINHOLUNG_DER_ZUSTIMMUNG.pdf';
          default: return 'ROSALY_RECUEIL_DU_CONSENTEMENT.pdf';
        }
      }
      if (partnerName === 'spayr') {
        switch (this.currentUser.attributes.lang) {
          case 'en': return 'SPAYR_COLLECTION_OF_CONSENT.pdf';
          case 'es': return 'SPAYR_RECOGIDA_DEL_CONSENTIMIENTO.pdf';
          case 'de': return 'SPAYR_EINHOLUNG_DER_EINWILLIGUNG.pdf';
          default: return 'SPAYR_RECUEIL_DU_CONSENTEMENT.pdf';
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.advance-payment-partners__title {
  margin-bottom: 42px;
}

.advance-payment-partners__card {
  margin: 21px 0;
}

.advance-payment-partners__card-description {
  color: $sk-grey;
  margin-top: 15px;
}
</style>
