<template>
  <div>
    <SkCard class="missing-partner-wrapper">
      <div class="missing-partner-card__left-part">
        <p class="sk-subtitle--small missing-partner-card__subtitle">
          {{ title }}
        </p>
        <div class="missing-partner-card__description">
          <p class="not-in-list-explanation">
            {{ subtitle }}
          </p>
        </div>
      </div>
      <div class="missing-partner-card__right-part">
        <div class="missing-partner-card__icon-wrapper sk-flex-center">
          <slot />
        </div>
      </div>
    </SkCard>
  </div>
</template>

<script>

export default {
  name: 'MissingPartnerCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.missing-partner-card__subtitle {
  color: $sk-black;
}

.not-in-list-explanation {
  margin-top: 10px;
  margin-bottom: 0;
}

.tool-subtitle {
  margin-top: 20px;
}

.missing-partner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 3em 0;
}

.missing-partner-card__icon-wrapper {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.missing-partner-card__left-part {
  width: 90%;
}

.missing-partner-card__right-part {
  width: 10%;
}
</style>
