<template>
  <tr>
    <ShopSidebarCell
      v-if="isAllShopsView"
      :name="shiftsOwner.name"
      :shop-id="shiftsOwner.id"
    />
    <UserSidebarCell
      v-else
      :user-id="ownerId"
      :user-first-name="shiftsOwner.firstName"
      :user-last-name="shiftsOwner.lastName"
    />

    <td
      v-if="!published"
      colspan="7"
    >
      <span>{{ $t('employee_view.table.row.not_published') }}</span>
    </td>
    <td
      v-for="day in weekDays"
      v-else
      :key="day.toISOString()"
    >
      <div class="shifts-wrapper">
        <Shift
          v-for="shift in shiftsByDay[day.format('YYYY-MM-DD')]"
          :key="shift.id"
          :shift="shift"
          :global-config="globalConfig"
          :is-draggable="false"
          :is-pending-leave-request="shift.attributes.isPendingLeaveRequest"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import Shift from '@app-js/plannings/shared/components/Shift';
import skDate from '@skello-utils/dates';
import ShopSidebarCell from './SidebarCells/ShopSidebarCell';
import UserSidebarCell from './SidebarCells/UserSidebarCell';

export default {
  name: 'TableRow',
  components: { ShopSidebarCell, UserSidebarCell, Shift },
  props: {
    globalConfig: {
      type: Object,
      required: true,
    },
    published: {
      type: Boolean,
      required: true,
    },
    shifts: {
      type: Array,
      default: () => [],
    },
    ownerId: {
      type: String,
      required: true,
    },
    shiftsOwner: {
      type: Object,
      required: true,
    },
    weekDays: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isAllShopsView() {
      return this.$route.name === 'plannings_employee_all';
    },
    shiftsByDay() {
      return this.shifts.reduce((acc, shift) => {
        const day = skDate.utc(shift.attributes.startsAt).format('YYYY-MM-DD');
        if (!acc[day]) acc[day] = [];
        acc[day].push(shift);
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
.shifts-wrapper {
  font-size: .8em;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.planning-row__shift-wrapper:hover {
  cursor: default;
}
</style>
