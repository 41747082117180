<template>
  <SkModal
    id="holidays-counter-details-modal"
    :modal-title="$t('employees.holidays_counter_details_modal.title')"
    :display-footer="false"
    @show="reset"
  >
    <template #body>
      <div
        v-if="loading"
        class="holidays-counter-details--loading"
      >
        <DetailsTableCanvas
          :columns="canvasColumns"
          :lines-count="6"
        />
      </div>
      <div
        v-else
        class="holidays-counter-details__content"
      >
        <SkTable
          ref="skTable"
          :columns="headers"
          @sort="handleSort"
        >
          <template #default>
            <tr
              v-for="holidayRecoveredShift in sortedHolidaysRecoveredShifts"
              :key="holidayRecoveredShift.id"
            >
              <td>{{ formattedDate(holidayRecoveredShift) }}</td>
              <td
                class="holidays-counter-details__duration"
              >
                {{ durationInHours(holidayRecoveredShift.attributes.durationInSeconds) }} h
              </td>
              <td
                v-tooltip="holidayRecoveredShift.attributes.note"
                class="text-truncate holidays-counter-details__note"
              >
                {{ holidayRecoveredShift.attributes.note || '-' }}
              </td>
            </tr>
          </template>
        </SkTable>
      </div>
    </template>
  </SkModal>
</template>

<script>
import skDate from '@skello-utils/dates';

import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';

import DetailsTableCanvas from './DetailsTableCanvas';

export default {
  name: 'HolidaysCounterDetailsModal',
  components: { DetailsTableCanvas },
  props: {
    holidaysRecoveredShifts: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortedHolidaysRecoveredShifts: [],
      sortOrder: 'desc',
    };
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t('employees.holidays_counter_details_modal.shift_date'),
          name: 'shiftDate',
          sort: true,
          defaultSort: this.sortOrder,
          icon: {
            icon: 'CircledQuestionMarkIcon',
            tooltip: this.$t('employees.holidays_counter_details_modal.tooltip'),
            width: '18',
            height: '18',
          },
        },
        {
          title: this.$t('employees.holidays_counter_details_modal.total_hours'),
        },
        {
          title: this.$t('employees.holidays_counter_details_modal.shift_note'),
        },
      ];
    },
    canvasColumns() {
      return [
        { width: 90, height: 16 },
        { width: 90, height: 16 },
        { width: 260, height: 16 },
      ];
    },
  },
  watch: {
    holidaysRecoveredShifts() {
      this.reset();
    },
  },
  methods: {
    formattedDate(shift) {
      const { startsAt, previsionalStart } = shift.attributes;
      const { closingTime, openingTime } = shift.relationships.shop.attributes;
      // previsionalStart has priority over startsAt
      const shiftDate = previsionalStart || startsAt;
      const shopTimesAt = openingAndClosingTimeAt(
        openingTime,
        closingTime,
        shiftDate,
      );

      const shiftSkDate = skDate(shiftDate).utc();
      // If shift is before shop opening time -> It belongs to previous day
      if (shiftSkDate.isBefore(shopTimesAt.openingTime)) {
        shiftSkDate.subtract(1, 'day');
      }

      return shiftSkDate.format('DD MMM YYYY');
    },
    durationInHours(durationInSeconds) {
      return (durationInSeconds / 3600).toFixed(2);
    },
    reset() {
      if (!this.sortedHolidaysRecoveredShifts || this.sortedHolidaysRecoveredShifts.length === 0) {
        this.handleSort();
      } else if (this.sortOrder !== 'desc') {
        this.$refs.skTable.handleSort(null, this.headers[0], 0);
      }
    },
    handleSort(event, columnName, direction = 'desc') {
      if (
        direction === this.sortOrder &&
        this.sortedHolidaysrecoveredShifts &&
        this.sortedHolidaysrecoveredShifts.length
      ) {
        return;
      }

      this.sortOrder = direction;
      this.sortedHolidaysRecoveredShifts =
        [...this.holidaysRecoveredShifts].sort((a, b) => (direction === 'asc' ?
          skDate(a.attributes.startsAt).unix() - skDate(b.attributes.startsAt).unix() :
          skDate(b.attributes.startsAt).unix() - skDate(a.attributes.startsAt).unix()));
    },
  },
};
</script>

<style lang="scss" scoped>
#holidays-counter-details-modal {
  .holidays-counter-details--loading {
    min-height: 300px;
    display: flex;
    justify-content: center;
    padding: 25px;
  }

  .holidays-counter-details__content {
    padding: 30px 25px;
  }

  .holidays-counter-details__duration {
    padding-right: 80px;
  }

  .holidays-counter-details__note {
    color: $sk-grey;
  }

  .text-truncate {
    max-width: 250px;
  }

  ::v-deep .sk-table {
    > thead th {
      padding-top: 0;
    }
  }

  ::v-deep .sk-modal__dialog--medium {
    width: 620px;
  }
}
</style>
