<script>
import { mapGetters } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'PrintAction',
  components: {},
  extends: GenericDropdownItem,
  computed: {
    ...mapGetters('planningsState', ['isMonthlyView']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.print.label');
    },
    icon() {
      return 'PrinterV2Icon';
    },
    iconSize() {
      return '20';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    disabled() {
      return this.isDataLoading;
    },
    isVisible() {
      return true;
    },
    dataTest() {
      return 'planning-toolbar__print';
    },
  },
  methods: {
    handler(event) {
      if (this.isMonthlyView) this.$skAnalytics.track('month_view_V3_open_print_modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'print-modal');
    },
  },
};
</script>
