<template>
  <div
    ref="bottomPanelHeaderWrapper"
    class="workload-plan-bottom-panel"
  >
    <div class="workload-plan-bottom-panel__wrapper">
      <Hours />
      <HourQuarters />
    </div>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import Hours from './Hours';
import HourQuarters from './HourQuarters';

export default {
  name: 'Quarters',
  components: { Hours, HourQuarters },
  data() {
    return { horizontalScrollElement: null };
  },
  mounted() {
    this.listenOnRoot('workload-plan-horizontal-scroll', this.handleHorizontalScroll);

    const workloadPlanBottomPanelSimpleBar = new SimpleBar(this.$refs.bottomPanelHeaderWrapper);
    this.horizontalScrollElement = workloadPlanBottomPanelSimpleBar.getScrollElement();
    this.horizontalScrollElement.addEventListener('scroll', this.handleEmitHorizontalScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleEmitHorizontalScroll);
  },
  methods: {
    handleHorizontalScroll(event) {
      // Avoid setting scrollTop on the source element that did the handleEmitVerticalScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.bottomPanelContentTabSimpleBar === event.srcElement) return;
      this.horizontalScrollElement.scrollLeft = event.target.scrollLeft;
    },
    handleEmitHorizontalScroll(event) {
      this.emitOnRoot('workload-plan-horizontal-scroll', event);
    },
  },
};
</script>

<style scoped lang="scss">
.workload-plan-bottom-panel {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  /*
    Override the simple bar css to not display it
  */
  ::v-deep .simplebar-track.simplebar-horizontal {
    /*
      add important here because the css is defined as style on the component,
      so it can not be override just by v-deep
    */
    visibility: hidden !important;
  }
}
</style>
