<template>
  <!-- Planning fully loaded -->
  <SkModal
    v-if="isPlanningDataCompleted"
    id="sort-modal"
    ref="sortModal"
    :modal-title="modalTitle"
    size="ds-medium"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <SortV2Icon
        class="sort_modal__icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <div class="sort_modal__row">
          <SkRadio
            id="sort-method-a-to-z"
            v-model="sortMethod"
            data-value="AToZ"
          >
            <label
              for="sort-method-a-to-z"
              class="parameter_line__label parameter_line__label--padding"
            >
              {{ $t('plannings.toolbar.modal.sort.a_to_z') }}
            </label>
          </SkRadio>
          <SkRadio
            id="sort-method-z-to-a"
            v-model="sortMethod"
            data-value="ZToA"
          >
            <label
              for="sort-method-z-to-a"
              class="parameter_line__label"
            >
              {{ $t('plannings.toolbar.modal.sort.z_to_a') }}
            </label>
          </SkRadio>
        </div>
        <div class="sort_modal__row sort_modal__row--last-row">
          <SkRadio
            id="sort-method-custom"
            v-model="sortMethod"
            data-value="custom"
          >
            <label
              for="sort-method-custom"
              class="parameter_line__label"
            >
              {{ $t('plannings.toolbar.modal.sort.custom.title') }}
            </label>
          </SkRadio>
          <div class="sort_modal__row__label_subtitle">
            {{ modalSubtitle }}
          </div>
          <div
            v-if="sortMethod === 'custom'"
            class="sort_modal__sort_item_row__wrapper"
          >
            <template v-if="isPostesView">
              <PosteRow
                v-for="poste in itemsToSort"
                :key="poste.id"
                :poste="poste"
                :class="sortItemRowClasses(poste.id)"
                draggable
                @dragstart.native="handleDragStart(poste)"
                @dragend.native="handleDragEnd"
                @dragover.native.prevent="handleDragOver"
                @dragenter.native="handleDragEnter(poste)"
                @drop.native="handleItemDrop"
              />
            </template>
            <template v-else>
              <UserRow
                v-for="user in itemsToSort"
                :key="user.id"
                :user="user"
                :class="sortItemRowClasses(user.id)"
                draggable
                @dragstart.native="handleDragStart(user)"
                @dragend.native="handleDragEnd"
                @dragover.native.prevent="handleDragOver"
                @dragenter.native="handleDragEnter(user)"
                @drop.native="handleItemDrop"
              />
            </template>
          </div>
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="requestLoading"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>

  <!-- Planning loading -->
  <SkModal
    v-else
    id="sort-modal"
    ref="sortModal"
    :modal-title="modalTitle"
    size="ds-medium"
    :display-footer="isPlanningDataCompleted"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <SortV2Icon
        class="sort_modal__icon"
        width="30"
        height="30"
        fill="#727272"
      />
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <LoaderContainer />
      </SkModalSection>
    </template>
  </SkModal>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

import PosteRow from './PosteRow';
import UserRow from './UserRow';

export default {
  name: 'SortModal',
  components: {
    PosteRow,
    UserRow,
    LoaderContainer,
  },
  data() {
    return {
      sortMethod: 'AToZ',
      itemDraggingId: null,
      itemHoverId: null,
      itemsToSort: null,
      requestLoading: false,
      scrollElement: null,
      isScrolling: false,
    };
  },
  computed: {
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsState', ['isPostesView']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isLoadingCompleted']),
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
    modalTitle() {
      return this.isPostesView ?
        this.$t('plannings.toolbar.modal.sort.header.title.postes') :
        this.$t('plannings.toolbar.modal.sort.header.title.employees');
    },
    modalSubtitle() {
      return this.isPostesView ?
        this.$t('plannings.toolbar.modal.sort.custom.subtitle.postes') :
        this.$t('plannings.toolbar.modal.sort.custom.subtitle.employees');
    },
    sortedItems() {
      const sortedItems = [...this.itemsToSort];
      if (this.sortMethod !== 'custom') {
        sortedItems
          .sort((a, b) => this.sortItemsAlphabetically(a, b, this.sortMethod));
      }

      return sortedItems;
    },
    sortKey() {
      return this.isPostesView ? 'planningOrder' : 'userOrder';
    },
    sortedParamItems() {
      const itemIdKey = this.isPostesView ? 'poste_id' : 'user_id';
      return this.sortedItems.map((item, index) => ({ [itemIdKey]: item.id, position: index + 1 }));
    },
  },
  mounted() {
    this.scrollElement = this.$el.querySelector('.sk-modal__body-wrapper');
  },
  methods: {
    ...mapActions('planningsUsers', ['sortUsers']),
    ...mapActions('planningsPostes', ['sortPostes']),

    handleShow() {
      // reset options to default values
      this.itemsToSort = this.isPostesView ? [...this.postes] : [...this.users];
      this.itemsToSort.sort((a, b) => a.attributes[this.sortKey] - b.attributes[this.sortKey]);
      this.sortMethod = 'custom';
      // Comparing current item sort with alphabetical ascending and descending orders
      // to determine what the current sort method is
      const currentItemIds = this.itemsToSort.map(sortItem => sortItem.id).join(' ');
      // Check if items are sorted in ascending alphabetical order
      const alphabeticalAscendingItemIds = this.itemsToSort
        .toSorted((a, b) => this.sortItemsAlphabetically(a, b, 'AToZ'))
        .map(item => item.id)
        .join(' ');
      if (currentItemIds === alphabeticalAscendingItemIds) {
        this.sortMethod = 'AToZ';
        return;
      }
      // Check if items are sorted in descending alphabetical order
      const alphabeticalDescendingItemIds = this.itemsToSort
        .toSorted((a, b) => this.sortItemsAlphabetically(a, b, 'ZToA'))
        .map(item => item.id)
        .join(' ');
      if (currentItemIds === alphabeticalDescendingItemIds) {
        this.sortMethod = 'ZToA';
      }
    },
    sortItemRowClasses(sortItem) {
      return {
        sort_modal__sort_item_row: true,
        'sort_modal__sort_item_row--dragging': this.itemDraggingId === sortItem,
        'sort_modal__sort_item_row--hover': this.itemHoverId === sortItem,
      };
    },
    handleDragStart(sortItem) {
      this.itemDraggingId = sortItem.id;
    },
    handleDragEnter(sortItem) {
      this.itemHoverId = sortItem.id;
    },
    handleDragEnd() {
      this.itemDraggingId = null;
      this.itemHoverId = null;
      this.stopScroll();
    },
    handleItemDrop() {
      if (this.itemHoverId !== this.itemDraggingId) {
        const itemMovingIndex = this.itemsToSort.findIndex(item => item.id === this.itemDraggingId);
        const itemTargetIndex = this.itemsToSort.findIndex(item => item.id === this.itemHoverId);
        this.itemsToSort.splice(itemTargetIndex, 0, this.itemsToSort.splice(itemMovingIndex, 1)[0]);
      }
      this.itemHoverId = null;
    },
    handleDragOver(event) {
      // event.layerY pixels starts after modal's header
      // 30 is the number of pixels to trigger the scroll area
      const draggingTop = event.layerY < 30;
      // footer's height is 70px + 30px to trigger the scroll area
      const draggingBottom = event.layerY > this.scrollElement.clientHeight - 100;
      const scrollSpeed = 7;
      if (!this.isScrolling) {
        if (draggingTop) {
          this.isScrolling = true;
          this.scroll(-scrollSpeed);
        } else if (draggingBottom) {
          this.isScrolling = true;
          this.scroll(scrollSpeed);
        }
      }
      if (!draggingTop && !draggingBottom) {
        this.stopScroll();
      }
    },
    scroll(scrollSpeed) {
      this.scrollElement.scrollTop += scrollSpeed;
      setTimeout(() => {
        if (this.isScrolling) {
          this.scroll(scrollSpeed);
        }
      }, 10);
    },
    stopScroll() {
      this.isScrolling = false;
    },
    handleSubmit(event) {
      this.requestLoading = true;

      const params = {
        shop_id: this.currentShop.id,
        order: this.sortedParamItems,
      };

      const updateSortAction = this.isPostesView ? 'sortPostes' : 'sortUsers';
      this[updateSortAction](params)
        .then(() => {
          this.updateItemsOrder();
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'sort-modal');
          try {
            this.$svcEvents.create(
              EVENT_SUBTYPE_ACTION.PLANNING_SORT_EMPLOYEES,
              this.sortedItems.map(item => item.id),
            );
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        })
        .finally(() => { this.requestLoading = false; });
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'sort-modal');
    },
    sortItemsAlphabetically(a, b, order) {
      const nameKey = this.isPostesView ? 'name' : 'lastName';
      return order === 'AToZ' ?
        a.attributes[nameKey].localeCompare(b.attributes[nameKey]) :
        b.attributes[nameKey].localeCompare(a.attributes[nameKey]);
    },
    updateItemsOrder() {
      this.sortedItems.forEach((item, index) => {
        // New items have 0 to their order to be on top, so we want to set orders > 0
        item.attributes[this.sortKey] = index + 1;
      });
    },
  },
};
</script>
<style lang="scss" scoped>

label {
  display: inline-block;
  margin-bottom: .5rem;
}

/* design adjustments to modal element */
#sort-modal {
  ::v-deep .sk-modal__subtitle {
    margin-top: 0;
  }

  ::v-deep .sk-modal__footer {
    padding-top: 10px;
    padding-bottom: 24px;
  }

  ::v-deep .sk-modal__section {
    padding: 24px 0;
  }

  ::v-deep .sk-radio__label {
    margin-bottom: 0;
  }

  ::v-deep .sk-radio__label-display {
    width: initial;
    margin-right: 20px;
  }
}

.parameter_line__label {
  cursor: pointer;

  &--padding {
    padding-right: 38px;
  }
}

.sort_modal__row {
  display: flex;

  &--last-row {
    flex-direction: column;
  }
}

.sort_modal__icon {
  display: flex;
  padding: 6px;
  border-radius: 50%;
  background-color: $sk-grey-5;
}

.sort_modal__row__label_subtitle {
  color: $sk-grey;
  font-size: 13px;
  padding-left: 40px;
  margin-top: -5px;
}

.sort_modal__sort_item_row {
  margin-bottom: 5px;
  margin-left: 40px;
  background-color: $sk-grey-5;

  &--hover {
    // Not present in DS
    background-color: #dfe3e9;
  }
}

.sort_modal__sort_item_row__wrapper {
  padding-top: 24px;
}
</style>
