<template>
  <div class="badgings__toolbar-navigation__wrapper">
    <SkNav>
      <SkNavItem
        :active="$route.matched.some(({ name }) => name === 'badgings_days')"
        :to="badgingShiftsDaysQuery"
      >
        {{ $t('badgings.days.title') }}
      </SkNavItem>
      <SkNavItem
        :active="$route.matched.some(({ name }) => name === 'badgings_users')"
        :to="badgingShiftsUsersQuery"
      >
        {{ $t('badgings.users.title') }}
      </SkNavItem>
      <SkNavItem
        v-if="!historyV3enabled
          && isFeatureEnabled(FEATURES.FEATURE_PLANNING, currentShop.id, () => true)"
        :href="`/shops/${currentShop.id}/badgings_history?date=${currentDate}`"
        data-test="HistorySkNavItem"
      >
        {{ $t('badgings.history.title') }}
      </SkNavItem>
      <SkNavItem
        v-else-if="isFeatureEnabled(FEATURES.FEATURE_PLANNING, currentShop.id, () => true)"
        :to="badgingHistory"
        data-test="HistorySkNavItem"
      >
        {{ $t('badgings.history.title') }}
      </SkNavItem>
    </SkNav>
  </div>
</template>

<script>

import {
  mapGetters, mapState,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';

export default {
  name: 'Navigation',
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('badgings', ['currentDate', 'users']),
    ...mapState('currentShop', ['currentShop']),
    historyV3enabled() {
      return this.isDevFlagEnabled('FEATUREDEV_PUNCHCLOCK_HISTORY_V3');
    },
    badgingShiftsDaysQuery() {
      const query = {
        date: this.currentDate,
      };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids;
      }

      return {
        name: 'badgings_days',
        query,
      };
    },
    badgingShiftsUsersQuery() {
      if (this.users.length === 0) return null;

      const query = {
        date: this.currentDate,
      };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids;
      }

      return {
        name: 'badgings_users',
        params: {
          user_id: this.$route.params.user_id || this.users[0]?.id,
        },
        query,
      };
    },
    badgingHistory() {
      const query = {
        date: this.currentDate,
      };
      return {
        name: 'badgings_history',
        query,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.badgings__toolbar-navigation__wrapper {

  ::v-deep .sk-nav__item {
    width: auto;
  }

  // asked by product
  ::v-deep .sk-nav__item__link {
    padding-bottom: 13px;
    padding-top: 16px;
  }

  ::v-deep .sk-nav {
    border: none;
    overflow-x: inherit;
  }
}
</style>
