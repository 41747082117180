<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    v-if="showDropModal"
    :on-close="cancelDrop"
  >
    <template #header>
      <ModalHeader
        :title="resolveModalTitle"
        :svg-name="'MoveSvg'"
      />
    </template>
    <template #body>
      <p
        v-if="droppedElement.type === 'cluster_node'"
        class="modal_drop-warning-text"
      >
        {{ $t('organisation_settings.tabs.clusters.drop_modal.move_node') }}

        <u>{{ droppedElement.name }}</u>
        {{ $t('organisation_settings.tabs.clusters.drop_modal.and') }}
        <b> {{ $t('organisation_settings.tabs.clusters.drop_modal.all_nodes') }} </b>
        {{ $t('organisation_settings.tabs.clusters.drop_modal.in_node') }}
        <u> {{ targetName }}</u>.
        <br>
        {{ $t('organisation_settings.tabs.clusters.drop_modal.lose_access') }}
      </p>
      <p
        v-else
        class="modal_drop-warning-text"
      >
        {{ $t('organisation_settings.tabs.clusters.drop_modal.move_shop') }}
        <u>{{ droppedElement.name }}</u>
        {{ $t('organisation_settings.tabs.clusters.drop_modal.in_node') }}
        <u> {{ targetName }}.</u>
        <br>
        {{ $t('organisation_settings.tabs.clusters.drop_modal.lose_access') }}
      </p>
      <p class="modal_drop-question">
        {{ $t('organisation_settings.tabs.clusters.drop_modal.continue') }}
      </p>
    </template>
    <template #footer>
      <div
        :class="footerClass"
        class="modal-btn_container"
      >
        <div
          class="modal-btn blue-btn"
          @click="confirmMove"
        >
          <p v-if="droppedElement.type === 'cluster_node'">
            {{ $t('organisation_settings.tabs.clusters.drop_modal.move_all') }}
          </p>
          <p v-else>
            {{ $t('organisation_settings.tabs.clusters.drop_modal.move_one') }}
          </p>
        </div>
        <div
          class="modal-btn red-btn"
          @click="cancelDrop"
        >
          {{ $t('actions.cancel') }}
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>

import SkModal from '../../ui/SkModal';
import ModalHeader from '../../ui/ModalHeader';

export default {
  name: 'DropModal',
  components: { SkModal, ModalHeader },
  props: {
    showDropModal: {
      type: Boolean,
      required: true,
    },
    moveCard: {
      type: Function,
      required: true,
    },
    cancelDrop: {
      type: Function,
      required: true,
    },
    resetData: {
      type: Function,
      required: true,
    },
    droppedElement: {
      type: Object,
      default: null,
    },
    originElement: {
      type: Object,
      default: null,
    },
    targetName: {
      type: String,
      default: null,
    },
    targetId: {
      type: Number,
      default: null,
    },
    targetDepth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      footerClass: '',
    };
  },
  computed: {
    resolveModalTitle() {
      const droppedName = this.truncateName(this.droppedElement.name);
      const targetName = this.truncateName(this.targetName);
      return this.$t('organisation_settings.tabs.clusters.drop_modal.title', { droppedName, targetName });
    },
  },
  methods: {
    truncateName(name) {
      if (name.length < 20) return name;
      return `${name.substring(0, 20)}...`;
    },
    async confirmMove() {
      await this.moveCard(
        this.droppedElement,
        this.targetId,
        this.targetDepth,
        this.originElement.parentId,
      );
      this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_drop-warning-text {
  margin-bottom: 25px;
  font-size: $fs-text-m;
  color: #2d2f30;
}

.modal_drop-question {
  font-size: $fs-text-m;
}

.modal-btn_container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
}

.modal-btn {
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  cursor: pointer;
  margin: 0 5px;
  background-color: lightgray;
  font-size: $fs-text-m;
  color: white;
  border-radius: 3px;
  line-height: 17px;

  &.blue-btn {
    padding: 12px 30px 10px;
    background-color: $sk-blue;
  }

  &.red-btn {
    padding: 12px 20px 10px;
    background-color: #ff5355;
  }
}
</style>
