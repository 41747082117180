<template>
  <div class="read-only-note__container">
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="isTextToLinkEnabled"
      v-html="formattedNote"
    />
    <!-- eslint-enable vue/no-v-html -->
    <span v-else>
      {{ note }}
    </span>
  </div>
</template>

<script>
import { linkify } from '@skello-utils/formatting/strings';
import { mapGetters } from 'vuex';

export default {
  name: 'ReadOnlyNote',
  props: {
    note: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    formattedNote() {
      return linkify(this.note);
    },
    isTextToLinkEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_TEXT_TO_LINK');
    },
  },
};
</script>

<style lang="scss" scoped>
.read-only-note__container {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
  width: 100%;
  line-height: normal;
  white-space: pre-line;
}
</style>
