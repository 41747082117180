<template>
  <div>
    <SkSelectV2
      :value="selectedWeeks"
      v-bind="selectorOptions"
      data-test="publish-planning-modal__select-weeks"
      multi
      append-to-body
      no-confirm
      @input="$emit('change', $event)"
    >
      <template #selected-option>
        {{ selectedWeeksText }}
      </template>

      <template #group-count>
        {{ everyWeekSubtitle }}
      </template>

      <template #option="{ option }">
        <div
          v-tooltip="getTooltip(option)"
          class="week-option"
        >
          {{ option.text }}
        </div>
      </template>
    </SkSelectV2>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'WeeksSelector',
  model: {
    prop: 'selectedWeeks',
    event: 'change',
  },
  props: {
    selectedWeeks: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapState('planningsState', ['currentDate', 'monthlyOptions']),
    ...mapState('planningsUsers', ['users']),
    ...mapGetters('planningsShifts', ['monthlyShifts']),
    ...mapGetters('planningsState', ['firstMonthDay']),

    weeks() {
      return this.monthlyOptions.map(({ date, publishedUserIds, employeesWithChange }) => {
        const day = skDate(date);
        const weekNumber = day.isoWeek();

        const firstDay = day.startOf('isoWeek').format('MMM D, YYYY');
        const lastDay = day.endOf('isoWeek').format('MMM D, YYYY');

        const symbol = this.$t('plannings.toolbar.modal.publish_planning.select.week_symbol', { weekNumber });
        const text = this.$t('plannings.toolbar.modal.publish_planning.select.week_title', {
          firstDay,
          lastDay,
        });

        /*
          if there is at least one user that is not published and not archived,
          it means the week can be published
        */
        const archivedUsers = this.users
          .filter(u => u.attributes.archivedAt && skDate(u.attributes.archivedAt).isBefore(day))
          .map(u => u.id);
        const unpublishableUsers = new Set([
          ...archivedUsers,
          ...publishedUserIds.map(id => id.toString()),
        ]);
        const isPublished = unpublishableUsers.size === this.users.length;
        const hasChanges = employeesWithChange.length > 0;
        const hasShifts = this.monthlyShifts.filter(({ attributes: { startsAt } }) => (
          skDate(startsAt).utc().startOf('isoWeek').isoWeek() === weekNumber
        )).length > 0;

        return {
          id: weekNumber,
          text: `${symbol} - ${text}`,
          firstDay,
          lastDay,
          isPublished,
          hasShifts,
          hasChanges,
        };
      });
    },

    groups() {
      return [
        {
          id: 'all',
          text: this.everyWeekText,
          matchKey: '*',
        },
        {
          id: 'separator',
          separator: true,
          matchKey: null,
        },
      ];
    },

    disabled() {
      const disabled = this.weeks
        .filter(({ isPublished, hasShifts, hasChanges }) => (
          (isPublished && !hasChanges) || !hasShifts),
        )
        .map(({ id }) => id);

      return disabled.length > 0 ? disabled.concat('all') : [];
    },

    selectorOptions() {
      return {
        options: this.weeks,
        groupOptions: this.groups,
        disabledOptions: this.disabled,
        label: this.$t('plannings.toolbar.modal.publish_planning.select.weeks'),
        forceSearchBarBehavior: 'force-hide',
        width: '320px',
      };
    },

    everyWeekSubtitle() {
      return this.$t('plannings.toolbar.modal.publish_planning.select.every_week_subtitle', {
        firstDay: this.weeks[0].firstDay,
        lastDay: this.weeks.at(-1).lastDay,
      });
    },

    everyWeekText() {
      const month = skDate(this.firstMonthDay).locale('en').format('MMMM').toLowerCase();
      const translatedMonth = this.$t(`plannings.toolbar.modal.shared.months.${month}`);
      return this.$t('plannings.toolbar.modal.publish_planning.select.every_week', {
        month: translatedMonth,
      });
    },

    selectedWeeksText() {
      return this.selectedWeeks.length === this.weeks.length ?
        this.everyWeekText :
        this.selectedWeeks
          .map(weekNumber => (
            this.$t('plannings.toolbar.modal.publish_planning.select.week_symbol', { weekNumber })
          ))
          .join(', ');
    },
  },
  methods: {
    getTooltip({ isPublished, hasChanges, hasShifts }) {
      if (isPublished && !hasChanges) {
        return this.$t('plannings.toolbar.modal.publish_planning.select.tooltip_published');
      }

      if (!hasShifts) {
        return this.$t('plannings.toolbar.modal.publish_planning.select.tooltip_no_shifts');
      }

      return '';
    },
  },
};
</script>
