import { httpClient } from '@skello-utils/clients';

// Increasing this value could cause a 413 Request Entity Too Large error
export const MAX_IDS_PER_REQUEST = 330;

function defaultMergeFn(merged, response) {
  if (Array.isArray(response)) {
    if (!merged) merged = [];
    merged.push(...response);
    return merged;
  }

  if (!merged) merged = {};

  if (response.data) {
    if (Array.isArray(response.data)) {
      if (!merged.data) merged.data = [];
      merged.data.push(...response.data);
    } else if (typeof response.data === 'object') {
      merged.data = { ...merged.data, ...response.data };
    }
  }

  if (response.included && Array.isArray(response.included)) {
    if (!merged.included) merged.included = [];
    const existingIncludedIds = new Set(merged.included.map(item => item.id));
    response.included.forEach(item => {
      if (!existingIncludedIds.has(item.id)) {
        merged.included.push(item);
        existingIncludedIds.add(item.id);
      }
    });
  }

  Object.keys(response).forEach(key => {
    if (key !== 'data' && key !== 'included') {
      if (Array.isArray(response[key])) {
        merged[key] = (merged[key] || []).concat(response[key]);
      } else if (typeof response[key] === 'object') {
        merged[key] = { ...merged[key], ...response[key] };
      } else {
        merged[key] = response[key];
      }
    }
  });

  return merged;
}

// Helper to chunk an array into smaller arrays of specified size
export function chunkArray(array, chunkSize) {
  const chunks = []; // [[a1, a2, ...], [a151, a152, ...], ...]
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

// Helper to perform HTTP request for a single chunk
export function fetchChunk(params, idsChunk, url, chunkParamKey = 'user_ids') {
  const chunkParams = { ...params, [chunkParamKey]: idsChunk };
  return httpClient.get(url, { params: chunkParams }).then(response => response.data);
}

// Generic helper function for fetching in chunks with custom merge logic
export async function fetchInChunks(params, url, mergeFn = defaultMergeFn, chunkParamKey = 'user_ids') {
  const ids = params[chunkParamKey];
  const chunks = ids && ids.length > MAX_IDS_PER_REQUEST ?
    chunkArray(ids, MAX_IDS_PER_REQUEST) : [ids];

  const responses =
    await Promise.all(chunks.map(chunk => fetchChunk(params, chunk, url, chunkParamKey)));

  return responses.reduce((merged, response) => mergeFn(merged, response), undefined);
}
