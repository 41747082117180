<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="158"
    height="96"
    viewBox="0 0 158 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="138.322"
      y="6.67554"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="143.347"
      y="10.3904"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 143.347 10.3904)"
      fill="#D2D7DC"
    />
    <rect
      x="3.71533"
      y="47.7571"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="8.74078"
      y="51.4719"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 8.74078 51.4719)"
      fill="#D2D7DC"
    />
    <rect
      x="53.9072"
      y="74.7434"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="58.9341"
      y="78.4583"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 58.9341 78.4583)"
      fill="#D2D7DC"
    />
    <circle
      cx="34.0013"
      cy="5.24439"
      r="4.47412"
      stroke="#D2D7DC"
      stroke-width="1.54054"
    />
    <circle
      cx="152.088"
      cy="64.801"
      r="4.47412"
      stroke="#D2D7DC"
      stroke-width="1.54054"
    />
    <path
      opacity="0.3"
      d="M130.432 49.2162C130.432 61.8361 125.373 73.2769 117.173 81.6166C108.788 90.1448 97.119 95.4324 84.2162 95.4324C71.3116 95.4324 59.6424 90.143 51.2574 81.6146C43.0574 73.275 38 61.8361 38 49.2162C38 36.5963 43.0574 25.1574 51.2574 16.8177C55.2112 12.796 59.8951 9.49482 65.0939 7.12931C70.9211 4.47711 77.395 3 84.2162 3C109.741 3 130.432 23.6917 130.432 49.2162Z"
      fill="#eef4ff"
    />
    <path
      d="M98.1032 35L76.4336 57.0931L67.8968 48.3894L65 51.3429L76.4336 63L101 37.9534L98.1032 35Z"
      fill="#ACC9FF"
    />
    <path
      d="M97.1032 32L75.4337 54.0931L66.8968 45.3894L64 48.3429L75.4337 60L100 34.9534L97.1032 32Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'Checkmark',
};
</script>
