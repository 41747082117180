<template>
  <SkSidePanel
    id="workload-plan-conflict-side-panel"
    :class="conflictSidepanelClasses"
    :title="$t('workload_plan.side_panel.title')"
    @closed="closeConflictSidePanel"
  >
    <template #body>
      <div class="conflict-side-panel__wrapper">
        <SkCollapse
          v-for="(poste, index) in sortedConflicts"
          :key="index"
          :opened-by-default="sortedConflicts.length === 1"
        >
          <template slot="labelLeft">
            <div
              v-tooltip="getTooltipAtIndex(index)"
              class="conflict-side-panel__label--container"
            >
              <div
                :style="conflictsTickStyle(poste.color)"
                class="conflict-side-panel__label--container__tick"
              />
              <div
                :ref="`conflict_section_label_container_title_${index}`"
                class="conflict-side-panel__label--container__title"
              >
                <span>{{ poste.name }}</span>
              </div>
            </div>
          </template>

          <template slot="labelRight">
            <div class="conflict-side-panel__label--tag">
              <span>
                {{
                  $tc(
                    'workload_plan.side_panel.conflict',
                    poste.conflicts.length,
                    { count: poste.conflicts.length },
                  )
                }}
              </span>
            </div>
          </template>

          <template slot="content">
            <div
              v-for="(conflict, index) in poste.conflicts"
              :key="index"
              class="conflict-side-panel__content"
            >
              <CalendarWithDot
                height="13px"
                width="13px"
                fill="#000000"
              />
              <span class="conflict-side-panel__content--label">
                {{ conflict }}
              </span>
            </div>
          </template>
        </SkCollapse>
      </div>
    </template>
  </SkSidePanel>
</template>

<script>
import {
  mapState,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';
import { SIDEPANEL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'ConflictSidePanel',
  data() {
    return {
      tooLongPosteTooltips: [],
    };
  },
  computed: {
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsWorkloadPlans', [
      'isConflictSidePanelOpen',
      'conflictsWorkloadPlans',
    ]),

    conflictSidepanelClasses() {
      return {
        'conflict-manager__side-panel__wrapper': true,
        'conflict-manager__side-panel--opened': this.isConflictSidePanelOpen,
      };
    },
    sortedConflicts() {
      return [...this.formattedConflicts].sort((a, b) => a.name.localeCompare(b.name));
    },
    formattedConflicts() {
      return Object.keys(
        this.conflictsWorkloadPlans.workloadPlansWithConflict,
      ).map(posteId => {
        const foundPoste = this.postes.find(poste => poste.id === posteId);

        return {
          name: foundPoste.attributes.name,
          color: foundPoste.attributes.color,
          conflicts: this.formatConflictsDate(
            this.conflictsWorkloadPlans.workloadPlansWithConflict[posteId],
          ),
        };
      });
    },
  },
  watch: {
    isConflictSidePanelOpen(newValue) {
      if (newValue) this.setTooltips();
      this.handleShowSidePanel(newValue);
    },
  },
  mounted() {
    this.handleShowSidePanel(this.isConflictSidePanelOpen);
  },
  methods: {
    ...mapMutations('planningsWorkloadPlans', ['toggleConflictSidePanel']),

    closeConflictSidePanel(event) {
      // Prevent to close sidebar if click outside
      if (event && event.target.className === 'sk-sidepanel__wrapper') return;

      this.toggleConflictSidePanel(false);
    },
    conflictsTickStyle(color) {
      return { 'background-color': color };
    },
    formatConflictsDate(posteConflict) {
      return Object.keys(posteConflict)
        .map(date => capitalize(skDate.utc(date).format('dddd DD MMMM YYYY')));
    },
    getTooltipAtIndex(index) {
      return this.tooLongPosteTooltips[index];
    },
    setTooltips() {
      this.$nextTick(() => {
        this.sortedConflicts.forEach((poste, index) => {
          const el = this.$refs[`conflict_section_label_container_title_${index}`];
          if (!el) return;

          const isEllipsisActive = el[0].offsetWidth < el[0].scrollWidth;
          if (!isEllipsisActive) return;

          this.tooLongPosteTooltips.push(poste.name);
        });
      });
    },
    handleShowSidePanel(isOpen) {
      if (isOpen) {
        this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'workload-plan-conflict-side-panel');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conflict-manager__side-panel__wrapper.sk-sidepanel__wrapper {
  z-index: 100;

  &.conflict-manager__side-panel--opened {
    background: none;
    pointer-events: none;

    /* Fix issue where click on modal would always close sidepanel */
    ::v-deep .sk-sidepanel__container {
      pointer-events: auto;
    }
  }
}

.conflict-side-panel__wrapper {
  padding-bottom: 10px;
  -webkit-font-smoothing: antialiased;

  /* Design specifications for open/close caret */
  ::v-deep .sk-collapse__caret-wrapper {
    svg {
      margin: 0;

      path {
        fill: black;
      }
    }
  }
}

.conflict-side-panel__label {
  &--container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__tick {
      width: 12px;
      height: 12px;
      border-radius: 20px;
    }

    &__title {
      font-size: $fs-text-m;
      font-weight: $fw-semi-bold;
      padding-left: 8px;
      color: $sk-black;
      width: 210px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &--tag {
    font-size: $fs-text-s;
    color: $sk-error;
    background-color: $sk-error-10;
    padding: 4px 16px;
    border-radius: 16px;
    min-width: 83px;
    display: flex;
    justify-content: center;
    margin-right: 24px;
  }
}

.conflict-side-panel__content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 26px;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  &--label {
    font-size: $fs-text-m;
    margin-left: 16px;
  }
}

/* Design specifications for line labels */
::v-deep .sk-collapse__label {
  padding: 14px 0 !important;
}
</style>
