const asyncInterval = async (callback, ms, maxIteration) => new Promise((resolve, reject) => {
  const interval = setInterval(() => {
    maxIteration -= 1;
    const result = callback();
    if (result) {
      resolve(result);
      clearInterval(interval);
    } else if (maxIteration < 1) {
      reject(new Error('Failed'));
      clearInterval(interval);
    }
  }, ms);
});

export const listenIntercomInit = async () => {
  const checkForIntercom = () => document.querySelector('.intercom-launcher');

  try {
    return await asyncInterval(checkForIntercom, 50, 200);
  } catch {
    return null;
  }
};
