// Polyfill for ECMAScript features
import 'core-js/stable';
// This package implements a fully-functional source transformation that takes the syntax for generators/yield
// from ECMAScript 2015 and Asynchronous Iteration proposal and spits out efficient JS-of-today (ES5) that behaves the same way
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import GlobalEvents from 'vue-global-events';
import VueConfetti from 'vue-confetti';
import * as Sentry from '@sentry/vue';
import SkelloUi from '@skelloapp/skello-ui';
import VueApexCharts from 'vue-apexcharts';
import Root from '@app-js/Root.vue';
import router from '@app-js/app-routes';
import store from '@app-js/shared/store/index';
import i18n from '@plugins/i18n';
import { sentryDsn } from '@config/env';

import { SkTrackDirective } from '@app-js/shared/directives';

import '@app-js/shared/assets/scss/index.scss';
import '@skelloapp/skello-ui/dist/skello-ui.css';

Sentry.init({
  Vue,
  dsn: sentryDsn,
  integrations: [],
});

Vue.use(PortalVue);
Vue.use(VueConfetti);
Vue.use(SkelloUi);
Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);
Vue.directive('track', SkTrackDirective);

window.GlobalEvents = window.GlobalEvents || GlobalEvents;

window.addEventListener('DOMContentLoaded', event => {
  new Vue({
    router,
    store,
    i18n,
    render: mount => mount(Root),
  }).$mount('#v3-app');
});
