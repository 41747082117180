<template>
  <div
    id="prod-quick-graphs"
    class="dashboard-section"
  >
    <div class="dashboard-title">
      <div class="flex-between full-width">
        <h2> {{ $t('home_dashboard.productivity.title') }} </h2>
      </div>
    </div>
    <div
      v-if="unauthorized"
      class="unauthorized-content"
    >
      <h3>{{ $t('home_dashboard.unauthorized') }}</h3>
    </div>
    <p
      v-if="!unauthorized"
      class="current-months"
    >
      {{ currentProductivityMonths }}
    </p>
    <div
      v-if="!unauthorized"
      class="dashboard-content dash-graph-prod"
    >
      <div id="prod-prev-day-arrow">
        <div
          class="arrow"
          @click="replaceCanvas('prev')"
        >
          <ChevronLeftV2Icon
            fill="white"
            width="20"
            height="20"
          />
        </div>
      </div>
      <canvas id="productivityChart" />
      <div id="prod-next-day-arrow">
        <div
          class="arrow"
          @click="replaceCanvas('next')"
        >
          <ChevronRightV2Icon
            fill="white"
            width="20"
            height="20"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import { Chart } from 'chart.js';
import { mapState } from 'vuex';
import {
  ChevronLeftV2Icon,
  ChevronRightV2Icon,
} from '@skelloapp/skello-ui';

export default {
  name: 'ProductivityChart',
  components: {
    ChevronLeftV2Icon,
    ChevronRightV2Icon,
  },
  data() {
    return {
      unauthorized: false,
      productivityLabels: null,
      productivityDatas: null,
      caDatas: null,
      caDatasAvailable: null,
      currentProductivityMonths: null,
      actualDate: null,
      prodCurrent: 'next',
      infos_productivity_chart_path: '/infos_productivity_chart',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop.id !== 'all';
    },
    displayedCurrencySymbol() {
      return this.currentShop?.attributes.currencySymbol || '€';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    getLastMonth() {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      return lastMonth;
    },
    resetCanvas() {
      document.getElementById('productivityChart').remove();
      const newCanvas = document.createElement('canvas');
      newCanvas.setAttribute('id', 'productivityChart');
      document.getElementById('prod-prev-day-arrow').after(newCanvas);
    },
    drawProductivityChart(animate) {
      const app = this;
      const prod = document.getElementById('productivityChart');
      const currencySymbol = this.displayedCurrencySymbol;
      const myChart = new Chart(prod, {
        type: 'line',
        data: {
          labels: app.productivityLabels,
          datasets: [
            {
              label: app.$t('home_dashboard.productivity.hourly', { currencySymbol }),
              yAxisID: 'y-axis-prod',
              data: app.productivityDatas,
              borderWidth: 2,
              borderColor: 'rgba(71, 214, 89, 0.5)',
              backgroundColor: 'rgba(31,190,213,0.0)',
              pointBackgroundColor: 'rgba(71, 214, 89, 0.5)',
            },
            {
              label: app.$t('home_dashboard.chart.productivity.label.revenue'),
              yAxisID: 'y-axis-ca',
              data: app.caDatas,
              borderWidth: 2,
              borderColor: 'rgba(255, 60, 50, 0.5)',
              backgroundColor: 'rgba(31,190,213,0.0)',
              pointBackgroundColor: 'rgba(255, 60, 50, 0.5)',
            },
          ],
        },
        options: {
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: tooltipItems => {
                const yLabel = tooltipItems.yLabel;
                if (tooltipItems.datasetIndex === 0) {
                  return app.$t('home_dashboard.chart.productivity.tooltip.productivity_per_hour_tooltip', { yLabel, currencySymbol });
                }

                if (tooltipItems.datasetIndex === 1) {
                  return app.$t('home_dashboard.chart.productivity.tooltip.revenue_tooltip', { yLabel, currencySymbol });
                }

                return '';
              },
              beforeBody(items, data) {
                if (items[0].yLabel === 0) {
                  return app.$t('home_dashboard.productivity.tooltip');
                }
                return '';
              },
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            duration: animate ? 500 : 0,
            animateRotate: animate,
            animateScale: animate,
          },
          scales: {
            ticks: {
              beginAtZero: true,
            },
            yAxes: [
              {
                position: 'left',
                id: 'y-axis-ca',
              },
              {
                position: 'right',
                id: 'y-axis-prod',
              },
            ],
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  callback(value, index, values) {
                    return index % 2 ? '' : value;
                  },
                },
              },
            ],
          },
        },
      });
    },
    initialLoad() {
      this.actualDate = this.getLastMonth();
      this.replaceCanvas('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'ProductivityChart');
    },
    replaceCanvas(statut) {
      const params = {
        statut,
        actual_date: this.actualDate,
        current: this.prodCurrent,
      };

      // View all is handled by not passing the shop id
      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.infos_productivity_chart_path, { params })
        .then(response => {
          const data = response.data;

          this.prodCurrent = statut;
          this.productivityLabels = data.labels;
          this.productivityDatas = data.datasets;
          this.caDatas = data.caDatasets;
          this.caDatasAvailable = data.caDatasAvailable;
          this.resetCanvas();
          this.drawProductivityChart(true);
          this.currentProductivityMonths = data.currentProductivityMonths;
          this.actualDate = new Date(data.new_actual_date);
        }).catch(_error => {
          this.unauthorized = true; // 401, 403 or random error
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.unauthorized-content {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 350px;
}
</style>
