<template>
  <div class="employees-documents__header">
    <h2 class="sk-header--2">
      {{ $t('employees.tabs.documents.title') }}
    </h2>
    <SkPopover
      :disabled="!quotaReached"
      placement="left"
      as-tooltip
    >
      <template #anchor>
        <SkOroraButton
          v-if="canCreateDocument"
          v-modal.create-document-modal
          :disabled="quotaReached"
          type="information"
          icon="UploadV2Icon"
        >
          {{ $t('employees.tabs.documents.add_document') }}
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <create-document-modal :tracking-options="trackingOptions" />
          </MountingPortal>
        </SkOroraButton>
      </template>
      <template #content>
        <template v-if="documentQuotaLimit !== 0">
          {{ $t('employees.tabs.documents.quota_reached.before_tag') }}
          <PackTag :type="packType" />
          {{
            $t(
              'employees.tabs.documents.quota_reached.after_tag',
              { max_documents: documentQuotaLimit }
            )
          }}
          <br>
          <br>
          {{ $t('employees.tabs.documents.quota_reached.second_line') }}
        </template>
        <template v-else>
          {{ $t('employees.tabs.documents.documents_not_allowed.before_tag') }}
          <PackTag :type="packType" />
          {{ $t('employees.tabs.documents.documents_not_allowed.after_tag') }}
          <br>
          <br>
          {{ $t('employees.tabs.documents.documents_not_allowed.second_line') }}
        </template>
      </template>
    </SkPopover>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import PackTag from '@app-js/shared/components/PackTag';

import CreateDocumentModal from './CreateDocumentModal/index.vue';

export default {
  name: 'DocumentsHeader',
  components: { CreateDocumentModal, PackTag },
  computed: {
    ...mapState('employeeDocuments', ['documents']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentOrganisation', ['maxDocumentAllowedPerUser']),
    ...mapGetters('currentLicense', [
      'canManageEmployeeDocuments',
      'canUploadSelfDocuments',
      'isSystemAdmin',
    ]),
    ...mapGetters('selectedEmployee', [
      'isStrictSubordinateOfCurrentUser',
      'isCurrentUser',
    ]),
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    documentQuotaLimit() {
      return this.maxDocumentAllowedPerUser;
    },
    documentQuotaUsed() {
      return this.documents.length;
    },
    quotaReached() {
      // If quota is infinite, it returns null
      if (this.documentQuotaLimit === null) {
        return false;
      }

      return this.documentQuotaUsed >= this.documentQuotaLimit;
    },
    canCreateDocument() {
      const canCreateOwnDocuments = this.isCurrentUser && this.canUploadSelfDocuments;

      return canCreateOwnDocuments ||
        this.isSystemAdmin ||
        (this.canManageEmployeeDocuments && this.isStrictSubordinateOfCurrentUser);
    },
    trackingOptions() {
      return {
        submit: 'upload_file_validate',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.employees-documents__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
