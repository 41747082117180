<template>
  <table class="sk-table">
    <tbody>
      <tr
        v-for="i in linesCount"
        :key="i"
      >
        <td
          v-for="(column, index) in columns"
          :key="index"
          :colspan="column.colspan"
          :class="column.class"
        >
          <SkDivCanvas
            :width="column.width"
            :height="column.height"
            animated
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DetailsTableCanvas',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    linesCount: {
      type: Number,
      default: 1,
    },
  },
};
</script>
