<template>
  <div
    :class="['demo-checklist', { expanded: !collapsed }]"
    :style="{ right: intercomInstance ? '88px' : '20px' }"
    @click="$emit('toggle-collapse')"
  >
    <div class="demo-checklist__header">
      <img src="./assets/avatar.png">
      <span class="sk-text-large-semibold demo-checklist__header-title">
        {{ $t('badgings.timeclock_demo.checklist.title') }}
      </span>
      <SkCircleButton
        v-if="!collapsed"
        class="cancel-button"
        icon="CrossV2Icon"
        size="medium"
        @click.native.stop="$emit('toggle-collapse')"
      />
    </div>

    <div
      v-if="!collapsed"
      class="demo-checklist__list"
    >
      <div
        v-for="(step, index) in visibleSteps"
        :key="step.id"
        :class="[
          'demo-checklist__list__item',
          { completed: step.completed },
          { disabled: !step.enabled },
        ]"
        @click.stop="handleStepClick(step)"
      >
        <div
          v-tooltip.left="step.tooltipKey ? $t(step.tooltipKey) : ''"
          class="demo-checklist__setup-item"
        >
          <div class="demo-checklist__icon">
            <SkRadio
              v-if="!step.completed"
              :value="false"
              :data-value="true"
              :checked="false"
              :disabled="true"
              :name="`step-radio-${index}`"
              width="16"
              height="16"
            />
            <CheckMarkV2Icon
              v-else
              width="20"
              height="20"
              fill="#2B66FE"
            />
          </div>
          <div class="sk-text-medium-regular">
            {{ $t(step.labelKey) }}
          </div>
          <div class="chevron-icon">
            <ChevronRightV2Icon
              fill="#000"
              width="20"
              height="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CheckMarkV2Icon, ChevronRightV2Icon, SkCircleButton, SkRadio,
} from '@skelloapp/skello-ui';

import { listenIntercomInit } from '@skello-utils/listen_intercom_init';

export default {
  name: 'Checklist',
  components: {
    CheckMarkV2Icon,
    ChevronRightV2Icon,
    SkCircleButton,
    SkRadio,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['step-clicked', 'toggle-collapse'],
  data() {
    return {
      intercomInstance: null,
    };
  },
  computed: {
    visibleSteps() {
      return this.steps.filter(step => step.visible);
    },
  },
  async mounted() {
    this.intercomInstance = await listenIntercomInit();
  },
  methods: {
    handleStepClick(step) {
      this.$emit('step-clicked', step);
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-checklist {
  align-content: center;
  position: fixed;
  bottom: 22px;
  background-color: white;
  border: 1px solid $sk-grey-10;
  border-radius: 22px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.12);
  padding: 0 20px 0 4px;
  height: 44px;
  gap: 8px;
  transition: all .15s ease-out;
  overflow: hidden;
  z-index: 5;
}

.demo-checklist:hover:not(.expanded) {
  cursor: pointer;
  box-shadow: 0 44px 0 0 rgba(0, 0, 0, 0.05) inset, 0 6px 16px 0 rgba(0, 0, 0, 0.12);
}

.demo-checklist.expanded {
  border-radius: 8px;
  border: none;
  border: 0 solid transparent;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.22);
  height: 304px;
  width: 348px;
  padding-right: 0;
  padding-left: 0;
  gap: 16px;

  .demo-checklist__header {
    padding: 16px;
    gap: 16px;

    img {
      border-radius: 44px;
      height: 44px;
      width: 44px;

      &:hover {
        cursor: default !important;
      }
    }

    .demo-checklist__header-title {
      flex-grow: 1;

      &:hover {
        cursor: default !important;
      }
    }

    ::v-deep .sk-circle-button__hover-layer {
      height: 36px;
      width: 36px;
    }

    &:hover {
      cursor: default !important;
    }
  }
}

.demo-checklist__header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    border-radius: 18px;
    height: 36px;
    width: 36px;
  }
}

.demo-checklist__list {
  padding: 4px;
}

.demo-checklist__list__item {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 44px;
  width: 100%;
}

.demo-checklist__list__item.disabled {
  color: $sk-grey-30;
  opacity: 0.5;
  &:hover {
    cursor: default !important;
  }
}

.demo-checklist__list__item.completed {
  background-color: $sk-blue-5;

  .demo-checklist__setup-item {
    margin-left: 3px;
  }
}

.demo-checklist__setup-item {
  display: flex;
  align-items: center;
  width: 340px;
  height: 44px;
  padding: 12px;
  gap: 8px;

  ::v-deep .sk-radio-v3 .sk-radio__label>.sk-radio__label-display {
    padding-left: 0;
  }
}

.demo-checklist__list__item:not(.completed)
.demo-checklist__setup-item:hover {
  background-color:  rgba(0, 0, 0, 0.05);
}

.demo-checklist__list__item.disabled .demo-checklist__setup-item:hover {
  background-color: initial;
}

.demo-checklist__list__item.disabled .demo-checklist__icon {
  ::v-deep .sk-radio-v3 .sk-radio__label>input:disabled+.sk-radio__radio-display {
    cursor: default !important;
  }
}

.demo-checklist__icon {
  align-items: center;
  display: flex;

  ::v-deep .sk-radio-v3 .sk-radio__label {
    margin: 0;
    height: 20px !important;
    width: 20px !important;
  }

  ::v-deep .sk-radio-v3 .sk-radio__radio-display {
    margin: 0;
  }

  ::v-deep .sk-radio-v3 .sk-radio__label>input:disabled+.sk-radio__radio-display {
    cursor: pointer;
  }

  ::v-deep .sk-radio__label:hover > .sk-radio__radio-display::before {
    border: 2px solid $sk-grey-50 !important;
    background-color: initial !important;
  }
}

.demo-checklist__list__item{
  ::v-deep .sk-radio-v3 .sk-radio__radio-display-circle--disabled:before {
    border: 2px solid $sk-grey-50;
  }
}

.chevron-icon {
  align-items: center;
  display: flex;
  margin-left: auto;
}
</style>
