<template>
  <SkOroraModal
    id="add-documents-modal"
    :title="$t('profile.documents.modal.title')"
    :dismissable="false"
    :submit-disabled="!isValid"
    data-test="add-documents-modal"
    @submit="submit"
    @hidden="unsetFile"
  >
    <template #body>
      <div id="container">
        <div
          v-if="document.file"
          class="document"
        >
          <DocumentRow
            key="add-documents-modal__document-row"
            v-model="document"
            :show-expiration-date="false"
            @remove-file="unsetFile"
            @input="changeFileName"
          />
        </div>

        <div
          v-else
          class="upload"
          :class="{ 'upload--dragging': isDragOver }"
          data-test="add-documents-modal__drag-document"
          @dragover.prevent="isDragOver = true"
          @dragleave.prevent="isDragOver = false"
          @drop.prevent="handleDrop"
        >
          <div
            class="upload__content"
            @click="$refs.fileInput.click()"
          >
            <span>
              {{ $t('profile.documents.modal.dropzone.label_1') }}
            </span>
            <span>
              {{ $t('profile.documents.modal.dropzone.label_2') }}
            </span>
            <SkOroraButton
              variant="secondary"
              size="medium"
            >
              {{ $t('profile.documents.modal.dropzone.button') }}
            </SkOroraButton>

            <input
              ref="fileInput"
              type="file"
              class="hidden"
              data-test="add-documents-modal__upload-document"
              @change="upload"
            >
          </div>
        </div>
      </div>
    </template>
  </SkOroraModal>
</template>

<script>
import {
  SkOroraModal, SkOroraButton,
} from '@skelloapp/skello-ui';
import DocumentRow from '@app-js/users/pages/documents/CreateDocumentModal/DocumentRow';

export default {
  name: 'AddDocuments',
  components: {
    SkOroraModal,
    SkOroraButton,
    DocumentRow,
  },
  data() {
    return {
      acceptedMimeTypes: [
        'application/msword', // .docx
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .doc,
        'image/jpeg',
        'image/png',
      ],
      document: {
        title: '',
        file: undefined,
      },
      isDragOver: false,
    };
  },
  computed: {
    isValid() {
      return this.document.file !== undefined && this.document.title.length >= 3;
    },
  },
  methods: {
    changeFileName(event) {
      this.document.title = event.target.value;
    },
    setFile(file) {
      if (!this.acceptedMimeTypes.includes(file.type)) {
        this.$skToast({
          message: this.$t('profile.documents.modal.upload.error.bad_format'),
          variant: 'error',
        });
        return;
      }

      this.document.file = file;
      if (!this.document.title) this.document.title = file.name;
    },
    unsetFile() {
      this.document.file = undefined;
      this.document.title = '';
    },
    upload() {
      this.setFile(this.$refs.fileInput.files[0]);
    },
    handleDrop(event) {
      event.preventDefault();

      this.isDragOver = false;
      this.setFile(event.dataTransfer.files[0]);
    },
    async submit() {
      this.$emit('upload', {
        file: this.document.file,
        name: this.document.title,
      });
    },
  },
};
</script>

<style lang="scss">
.document-row {
  &__name-input {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
#container {
  padding: 24px 16px 32px 16px;
}

.upload {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 2px dashed $sk-grey-10;

  &--dragging {
    border-color: $sk-grey-50;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
}

.document {
  display: flex;
}

.document-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
</style>
