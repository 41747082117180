<template>
  <router-link
    :to="userQuery"
    class="item__router-link"
  >
    <div :class="userItemClasses">
      <span class="user-item__user">
        {{ fullName(user) }}
      </span>
      <CheckMarkIcon
        :class="dayItemCheckboxClasses"
        fill=""
        height="24"
        width="24"
      />
    </div>
  </router-link>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

export default {
  name: 'UserItem',
  props: {
    user: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('badgings', ['currentDate', 'badgingsHistory', 'shifts', 'users']),
    ...mapGetters('badgings', ['matchedBadgingsPerUser']),
    ...mapGetters('employees', ['fullName']),
    userQuery() {
      return {
        params: {
          user_id: this.user.id,
        },
        query: {
          date: this.currentDate,
          team_ids: this.$route.query.team_ids || [],
        },
      };
    },
    userSelected() {
      return String(this.$route.params.user_id) === String(this.user.id);
    },
    userItemClasses() {
      return {
        'user-item': true,
        'user-item--selected': this.userSelected,
      };
    },
    dayItemCheckboxClasses() {
      return {
        'user-item__checkbox': true,
        'user-item__checkbox--selected': this.userSelected,
        'user-item__checkbox--validated-date': this.isWeekUserValidated,
      };
    },
    // Ignore badging if another badging with same shift is validated
    isWeekUserValidated() {
      if (this.matchedBadgingsPerUser(this.user.id).length === 0) return false;

      return this.matchedBadgingsPerUser(this.user.id).every(mB => mB.validated);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  transition: all .125s ease-in-out;

  .user-item__checkbox {
    z-index: 1;
    margin-left: 5px;
    padding: 5px;
    border: 1px solid $sk-grey-10;
    border-radius: 50%;
    background-color: $sk-white;
    fill: $sk-grey-30;

    &.user-item__checkbox__checkmark {
      fill: $sk-grey;
    }

    &.user-item__checkbox--validated-date {
      fill: $sk-white;
      background-color: $sk-success;
      border: 1px solid $sk-success;

      &.user-item__checkbox__checkmark {
        fill: $sk-white;
      }

      &.user-item__checkbox--selected {
        border: .5px solid $sk-success;
      }
    }

    &.user-item__checkbox--selected {
      border: 1px solid $sk-blue-5;
    }
  }

  .user-item__user {
    font-size: $fs-text-m;
    color: $sk-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }

  &:hover {
    cursor: pointer;
    background-color: $sk-grey-10;
  }

  &.user-item--selected {
    background: $sk-blue-5;

    .user-item__user {
      color: $sk-blue;
      font-weight: $fw-semi-bold;
    }
  }
}
</style>
