<template>
  <div
    v-if="showWorkloadPlanPanel && isEmptyStateDisplayed"
    class="workload-plan-panel__empty-state"
  >
    <div class="workload-plan-panel__empty-state__content">
      <span class="workload-plan-panel__empty-state__content__title">
        {{ $t('workload_plan.empty_state.title') }}
      </span>
      <span class="workload-plan-panel__empty-state__content__subtitle">
        {{ $t('workload_plan.empty_state.subtitle') }}
      </span>
      <SkOroraButton
        v-tooltip="openDropDownButtonTooltip"
        variant="tertiary"
        :disabled="hasDuplicatePostes"
        @click.stop="openWorkloadPlanDropDown"
      >
        {{ $t('workload_plan.empty_state.cta') }}
      </SkOroraButton>
    </div>
  </div>
  <div
    v-else-if="showWorkloadPlanPanel && !isEmptyStateDisplayed"
    ref="simpleBarWorkloadPlan"
    class="workload-plan-panel__active-state"
  >
    <WorkloadPlanLoadingState
      v-if="isWorkloadPlansLoading"
    />
    <WorkloadPlanChartPanel
      v-else-if="showWorkloadPlanPanel"
      :poste-id="posteId"
      :postes="postes"
      :planning-shifts-for-all-postes="planningShiftsForAllPostes"
      :planning-shifts-for-single-poste="planningShiftsForSinglePoste"
    />
  </div>
  <div
    v-else
    ref="simpleBarWorkloadPlan"
    class="workload-plan-panel__active-state"
  >
    <Workforce />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WorkloadPlanChartPanel from './WorkloadPlanChartPanel';
import WorkloadPlanLoadingState from './WorkloadPlanLoadingState';
import Workforce from '../../TotalEmployees/Workforce';

export default {
  components: {
    WorkloadPlanChartPanel,
    Workforce,
    WorkloadPlanLoadingState,
  },
  props: {
    posteId: {
      type: String,
      default: null,
    },
    postes: {
      type: Array,
      default: () => [],
    },
    showWorkloadPlanPanel: {
      type: Boolean,
      default: true,
    },
    isWorkloadPlansLoading: {
      type: Boolean,
      default: true,
    },
    isEmptyStateDisplayed: {
      type: Boolean,
      default: false,
    },
    planningShiftsForAllPostes: {
      type: Array,
      default: () => [],
    },
    planningShiftsForSinglePoste: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('planningsPostes', ['hasDuplicatePostes']),
    openDropDownButtonTooltip() {
      return this.hasDuplicatePostes ?
        this.$i18n.t('plannings.toolbar.actions_bar.icons_labels.workload_plan.tooltip.no_access') :
        null;
    },
  },
  mounted() {
    this.listenOnRoot('day-view-scroll', this.handleScroll);

    this.$refs.simpleBarWorkloadPlan?.addEventListener('scroll', this.handleEmitScroll);
  },
  beforeDestroy() {
    this.$refs.simpleBarWorkloadPlan?.removeEventListener('scroll', this.handleEmitScroll);
  },
  methods: {
    handleScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.simpleBarWorkloadPlan === event.srcElement) return;

      if (this.showWorkloadPlanPanel && this.isEmptyStateDisplayed) return;

      this.$refs.simpleBarWorkloadPlan.scrollLeft = event.target.scrollLeft;
    },
    handleEmitScroll(event) {
      this.emitOnRoot('day-view-scroll', event);
    },
    openWorkloadPlanDropDown() {
      this.emitOnRoot('open-workload-plan-dropdown');
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-panel {
  &__active-state {
    flex-grow: 1;
    overflow-x: auto;
    background-color: white;
    // Remove the simpleBar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  &__empty-state {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    background-image: url('./empty-workload-plan-background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $sk-black;
      text-align: center;

      &__title {
        font-size: $fs-heading-xs;
        font-weight: $fw-semi-bold;
      }

      &__subtitle {
        font-size: $fs-text-m;
        margin-top: 4px;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
