<template>
  <div :class="wrapperClasses">
    <span>{{ dayName }}</span>
    <div class="header-day__name-number">
      {{ day.format('D') }}
    </div>
    <span>{{ month }}</span>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';

export default {
  name: 'HeaderDay',
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'header-day--wrapper': true,
        'header-day--wrapper--today': skDate(this.day).isToday(),
      };
    },
    dayName() {
      const day = skDate(this.day).locale('en').format('dddd').toLowerCase();
      return this.$t(`plannings.table.header.short_day.${day}`);
    },
    month() {
      const month = skDate(this.day).locale('en').format('MMMM').toLowerCase();
      return this.$t(`plannings.table.header.short_month.${month}`);
    },
  },
};
</script>

<style scoped lang="scss">
.header-day--wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  column-gap: 4px;
  font-size: min(1vw, $fs-text-m);

  &--today {
    .header-day__name-number {
      font-weight: $fw-semi-bold;
      background: $sk-blue;
      color: $sk-white;
    }
  }
}

.header-day__name-number {
  font-size: min(1.25vw, $fs-text-l);
  width: min(2.43vw, 32px);
  height: min(2.43vw, 32px);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}
</style>
