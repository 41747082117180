<template>
  <tr class="punch-clock-rule-row">
    <td class="punch-clock-rule-row__switch-cell">
      <SkSwitch
        v-model="active"
      />
    </td>
    <td :class="descriptionClasses">
      <div class="punch-clock-rule-row__cell">
        <div class="punch-clock-rule-row__info-wrapper">
          <div v-if="includeInput">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(punchClockRuleRow.attributes.description.description1)" />

            <SkInput
              v-model="value"
              type="number"
              class="punch-clock-rule-row__input sk-input--center"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(punchClockRuleRow.attributes.description.description2)" />
          </div>
          <div v-else>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(punchClockRuleRow.attributes.description)" />
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PunchClockRuleRow',
  props: {
    punchClockRuleRow: {
      type: Object,
      required: true,
    },
    includeInput: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    active: {
      get() {
        return this.punchClockRuleRow.attributes.active;
      },
      set(newValue) {
        this.$emit('change', { kind: this.punchClockRuleRow.attributes.kind, newValue });
      },
    },
    value: {
      get() {
        return this.punchClockRuleRow.attributes.input.value;
      },
      set(newValue) {
        this.$emit('change', { kind: this.punchClockRuleRow.attributes.input.kind, newValue });
      },
    },
    descriptionClasses() {
      return {
        'punch-clock-rule-row__description': true,
        'punch-clock-rule-row__description--disabled': !this.active,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.punch-clock-rule-row:last-child td {
  border-bottom: 0;
}

.punch-clock-rule-row__cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0;
}

.punch-clock-rule-row__input {
  display: inline-flex;
  width: 50px;

  &::before {
    top: 23px;
  }

  // Override height to match alert inputs (like in the automatic planning page)
  ::v-deep .sk-input__input {
    height: 23px;
  }
}

.punch-clock-rule-row__info-wrapper {
  display: flex;
}

.punch-clock-rule-row__switch-cell {
  width: 90px;
}
</style>
