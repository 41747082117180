<template>
  <!-- eslint-disable  max-len -->
  <div
    v-show="visible"
    class="dashboard-section dashboard-flip-cards square-3"
  >
    <div class="flip-card">
      <div class="face front turnover-front">
        <div />
        <div class="number">
          {{ globalTurnover }}
        </div>
        <div class="subtitle">
          {{ $t('home_dashboard.turnover.subtitle') }}
          {{ actualDateDisplay }}
        </div>
      </div>
      <div class="face back">
        <div class="dashboard-title flex-between dtitle-spread noflip">
          <h2>
            {{ $t('home_dashboard.turnover.subtitle') }}{{ actualDateDisplay }}
            ({{ globalTurnover }})
          </h2>
          <div class="dt-rpart">
            <div id="turnover-btn">
              <SkCircleButton
                icon="ChevronLeftV2Icon"
                size="small"
                bordered
                @click="turnoverTime('prev')"
              />

              <SkCircleButton
                icon="ChevronRightV2Icon"
                size="small"
                bordered
                @click="turnoverTime('next')"
              />
            </div>
          </div>
        </div>
        <div class="dashboard-content d-limited-content">
          <ul class="trials-list">
            <li
              v-for="(shopsTurnover, index) in shopsTurnovers"
              :key="index"
              class="trial-item turnover noflip"
            >
              <a
                :href="shopsTurnover.link"
                class="turnover-link noflip"
                target="_blank"
                rel="noopener noreferrer"
                data-seg="DboardTurnOverShop"
              >
                <span
                  :style="{ backgroundColor: shopsTurnover.color }"
                  class="turnover-badge"
                >
                  {{ shopsTurnover.name }}
                </span>
                <span class="turnover-data">
                  {{ shopsTurnover.turnover }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { SkCircleButton } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

export default {
  name: 'TurnoverCard',
  components: {
    SkCircleButton,
  },
  data() {
    return {
      visible: true,
      shopsTurnovers: null,
      globalTurnover: null,
      actualDate: null,
      actualDateDisplay: null,
      shop_turnover_path: '/shops_turnover',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
    userAffiliatedShopId() {
      return this.currentUser && this.currentUser.attributes.shopId;
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    initialLoad() {
      this.actualDate = skDate().startOf('month').subtract(1, 'months');
      this.turnoverTime('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'TurnoverCard');
    },
    turnoverTime(statut) {
      if (statut === 'prev') {
        this.actualDate.subtract(1, 'months');
      } else if (statut === 'next') {
        this.actualDate.add(1, 'months');
      }

      this.actualDateDisplay = this.actualDate.locale(this.$i18n.locale).format('MMMM YYYY').toLowerCase();

      const params = {
        start_date: this.actualDate.format('YYYY-MM-DD'),
        single_shop: this.isSingleShop,
      };

      // When view all, we need to set an id in the url for the controller
      let urlId = this.userAffiliatedShopId;

      // View all is handled by not passing the shop id
      if (this.isSingleShop) {
        params.id = this.currentShop.id;
        urlId = this.currentShop.id;
      }

      return httpClient.get(`/${urlId}${this.shop_turnover_path}`, { params })
        .then(response => {
          const data = response.data;
          this.globalTurnover = data.global_turnover;
          this.shopsTurnovers = data.shops_turnover;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
