import { Quill } from 'vue2-editor';

const Inline = Quill.import('blots/inline');

// it replace the <font></font> inserted by the navigator on spell checking
// into <span></span>.
// Quill doesn't inteprete <font></font>
// cf. https://github.com/quilljs/quill/issues/2096
export default class SpellCheckerHandler extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    this.remove();

    return span;
  }
}
