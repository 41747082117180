<template>
  <SkModal
    id="leaving-choice-modal"
    ref="modal"
    :modal-title="$t('text_document_templates.editor.leaving_choice_modal.title')"
  >
    <template #body>
      <SkModalSection
        class="leaving-choice-modal__body"
        border-bottom="none"
      >
        <p>{{ $t('text_document_templates.editor.leaving_choice_modal.helptext') }}</p>
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton
        variant="tertiary"
        :variant-color="$skColors.skError"
        class="leave-without-save-button"
        @click.prevent="$emit('no-save')"
      >
        {{ $t('text_document_templates.editor.leaving_choice_modal.no_save') }}
      </SkOroraButton>
      <SkOroraButton
        variant="secondary"
        @click="$emit('cancel'); closeModal();"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        v-modal="saveModalTag"
        @click.prevent="$emit('save')"
      >
        {{ $t('text_document_templates.editor.leaving_choice_modal.save') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LeavingChoiceModal',
  props: {
    saveModalTag: {
      type: String,
      default: null,
      validator: value => [
        'save-text-document-modal',
        'save-text-document-template-modal',
      ].includes(value),
    },
  },
  computed: {
    ...mapState('textDocumentTemplates', {
      textDocumentTemplate: state => state.selectedTextDocumentTemplate,
    }),
    title: {
      get() {
        return this.textDocumentTemplate ? this.textDocumentTemplate.attributes.title : null;
      },
      set(title) {
        if (this.textDocumentTemplate) {
          this.textDocumentTemplate.attributes.title = title;
        }
      },
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
#leaving-choice-modal {
  button.leave-without-save-button {
    margin-right: auto;
  }
}
</style>
