<template>
  <div
    :class="setArrowButtonClass"
    class="arrows_container"
  >
    <div
      class="arrow_button"
      @click="() => $root.$emit('scroll', 'left')"
    >
      <AngleLeft />
    </div>
    <div
      class="arrow_button"
      @click="() => $root.$emit('scroll', 'right')"
    >
      <AngleRight />
    </div>
  </div>
</template>

<script>
import AngleLeft from '../../assets/svg/AngleLeft';
import AngleRight from '../../assets/svg/AngleRight';

export default {
  components: { AngleLeft, AngleRight },
  props: {
    arrowButtonClass: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setArrowButtonClass() {
      return this.arrowButtonClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow_button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $sk-grey-10;
  display: flex;

  .can-click &:hover {
    cursor: pointer;
    transition: background-color .3s ease;
    background-color: #d6dce0;
  }
}

.arrows_container {
  display: flex;
  justify-content: space-between;
  width: 70px;
  opacity: .3;

  &.can-click {
    opacity: 1;
  }
}
</style>
