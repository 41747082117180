<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="8"
    height="12"
    viewBox="0 0 8 12"
  >
    <path
      d="M6.29243 12L7.7074 10.585L3.12241 6L7.7074 1.41502L6.29243 -1.237e-07L0.292429 6L6.29243 12Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 8px; // ie11 support
  width: initial;
  height: 12px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 8px; // edge support
    height: 12px; // edge support
  }
}
</style>
