<template>
  <tr class="competencies__row">
    <td class="competencies__user-cell">
      <div class="competencies__user-wrapper">
        <img
          v-if="user.attributes.avatarUrl"
          :src="user.attributes.avatarUrl"
          class="competencies__avatar"
        >
        <div
          v-else
          class="competencies__monogram"
        >
          {{ monogram }}
        </div>
        <div class="competencies__username">
          {{ username }}
        </div>
      </div>
    </td>
    <td class="competencies__left-input-cell">
      <SkSelectV2
        v-model="primaryCompetencies"
        :group-options="allButtonOption"
        :options="posteOptions"
        :label="$t('shop_settings.tabs.automatic_planning.competencies.input.primary')"
        :group-count-label="$t('shop_settings.tabs.automatic_planning.competencies.input.count')"
        :disabled="inputDisabled"
        :disabled-options="primaryDisabledOptions"
        :search-placeholder="$t('labels.search')"
        :no-results-label="$t('search_bar.no_result')"
        width="230px"
        multi
        no-confirm
      >
        <template #empty-option>
          <div class="competencies-input__empty-option">
            {{ $t('shop_settings.tabs.automatic_planning.competencies.input.no_available') }}
          </div>
        </template>
        <template #selected-option>
          {{ primaryCompetenciesSelectedLabel }}
        </template>
        <template #option="{ option }">
          <div class="competencies-input__option">
            <div class="competencies-input__poste-name text-truncate">
              <div class="competencies-input__title-text text-truncate">
                {{ option.text }}
              </div>
            </div>
            <div
              class="competencies-input__vignette"
              :style="vignetteStyle(option)"
            />
          </div>
        </template>
      </SkSelectV2>
    </td>
    <td>
      <SkSelectV2
        v-model="secondaryCompetencies"
        :group-options="allButtonOption"
        :options="posteOptions"
        :label="$t('shop_settings.tabs.automatic_planning.competencies.input.secondary')"
        :group-count-label="$t('shop_settings.tabs.automatic_planning.competencies.input.count')"
        :disabled="inputDisabled"
        :disabled-options="secondaryDisabledOptions"
        :search-placeholder="$t('labels.search')"
        :no-results-label="$t('search_bar.no_result')"
        width="230px"
        multi
        no-confirm
      >
        <template #empty-option>
          <div class="competencies-input__empty-option">
            {{ $t('shop_settings.tabs.automatic_planning.competencies.input.no_available') }}
          </div>
        </template>
        <template #selected-option>
          {{ secondaryCompetenciesSelectedLabel }}
        </template>
        <template #option="{ option }">
          <div class="competencies-input__option">
            <div class="competencies-input__poste-name text-truncate">
              <div class="competencies-input__title-text text-truncate">
                {{ option.text }}
              </div>
            </div>
            <div
              class="competencies-input__vignette"
              :style="vignetteStyle(option)"
            />
          </div>
        </template>
      </SkSelectV2>
    </td>
  </tr>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

export default {
  name: 'CompetenciesRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    postes: {
      type: Array,
      required: true,
    },
    competencies: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('employees', ['userInitials', 'fullName']),
    primaryCompetencies: {
      get() {
        const userId = this.user.id;
        return this.competencies
          .filter(competency => competency.userId === userId && competency.primary)
          .map(competency => competency.posteId);
      },
      set(newValue) {
        const competencies = newValue.map(
          posteId => ({ posteId, primary: true, userId: this.user.id }),
        );
        const secondary = this.secondaryCompetencies.map(
          posteId => ({ posteId, primary: false, userId: this.user.id }),
        );
        competencies.push(...secondary);
        this.$emit('change', { competencies, user: this.user });
      },
    },
    secondaryCompetencies: {
      get() {
        const userId = this.user.id;
        return this.competencies
          .filter(competency => competency.userId === userId && !competency.primary)
          .map(competency => competency.posteId);
      },
      set(newValue) {
        const competencies = newValue.map(
          posteId => ({ posteId, primary: false, userId: this.user.id }),
        );
        const primary = this.primaryCompetencies.map(
          posteId => ({ posteId, primary: true, userId: this.user.id }),
        );
        competencies.push(...primary);
        this.$emit('change', { competencies, user: this.user });
      },
    },
    allButtonOption() {
      return [
        {
          id: 0,
          text: this.$t('shop_settings.tabs.automatic_planning.competencies.input.all'),
          matchKey: '*',
        },
      ];
    },
    primaryDisabledOptions() {
      const blockAll = this.secondaryCompetencies.length > 0;
      return blockAll ? [0, ...this.secondaryCompetencies] : this.secondaryCompetencies;
    },
    secondaryDisabledOptions() {
      const blockAll = this.primaryCompetencies.length > 0;
      return blockAll ? [0, ...this.primaryCompetencies] : this.primaryCompetencies;
    },
    primaryCompetenciesSelectedLabel() {
      return this.$tc(
        'shop_settings.tabs.automatic_planning.competencies.input.label',
        this.primaryCompetencies.length,
      );
    },
    secondaryCompetenciesSelectedLabel() {
      return this.$tc(
        'shop_settings.tabs.automatic_planning.competencies.input.label',
        this.secondaryCompetencies.length,
      );
    },
    posteOptions() {
      return this.postes.map(poste => ({
        id: poste.id,
        text: poste.attributes.name,
        attributes: {
          color: poste.attributes.color,
        },
      }));
    },
    username() {
      return this.fullName(this.user);
    },
    monogram() {
      return this.userInitials(this.user);
    },
    inputDisabled() {
      return !this.currentLicense.attributes.canEditShopRulesAndAbsences;
    },
  },
  methods: {
    vignetteStyle(option) {
      return {
        'background-color': option.attributes.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

// competencies-input__vignette has a position: absolute without a parent relative.
::v-deep .sk-select__options {
  position: relative;
}

.competencies__row:last-child td {
  border-bottom: 0;
}

.competencies__avatar,
.competencies__monogram {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.competencies__monogram {
  background: $sk-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 11px;
}

.competencies__user-cell {
  width: 100%;
}

.competencies__user-wrapper {
  display: flex;
  align-items: center;
}

.competencies__username {
  margin-left: 18px;
  font-weight: bold;
}

.competencies-input__vignette {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  position: absolute;
  right: 15px;
}

.competencies-input__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.competencies-input__title-text {
  max-width: 130px;
}

.competencies__left-input-cell {
  padding-right: 19px;
  width: 215px;
}
</style>
