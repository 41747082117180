<template>
  <div class="duplicate-workload-plan-modal__day-to-duplicate">
    <span class="duplicate-workload-plan-modal__day-to-duplicate__label">
      {{ $t('workload_plan.modals.duplicate.postes_to_duplicate_label') }}
    </span>

    <SkSelectV2
      :value="value"
      :options="postesOptions"
      :group-options="groupOptions"
      :disabled-options="disabledPosteIds"
      :label="$t('workload_plan.modals.duplicate.postes')"
      :search-placeholder="$t('actions.search')"
      :no-results-label="$t('workload_plan.modals.duplicate.no_result')"
      force-search-bar-behavior="force-show"
      width="300px"
      append-to-body
      no-confirm
      multi
      v-on="$listeners"
    >
      <template #group-count="{ count }">
        {{ $tc('workload_plan.modals.duplicate.postes_selected', count) }}
      </template>
      <template #selected-option="{ value }">
        {{ $tc('workload_plan.modals.duplicate.postes_selected', value.length) }}
      </template>
      <template #option="slotProps">
        <div
          v-tooltip="tooltipForOption(slotProps.option.id)"
        >
          {{ slotProps.option.text }}
        </div>
      </template>
    </SkSelectV2>
  </div>
</template>

<script>
export default {
  name: 'SelectPostes',
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabledPosteIds: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    postesOptions() {
      return this.postes.map(({ id, attributes }) => ({
        id, text: attributes.name,
      }));
    },
    groupOptions() {
      return [
        { id: 'all', text: this.$t('workload_plan.modals.duplicate.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
        { id: 'postes', text: this.$t('workload_plan.modals.duplicate.postes'), matchKey: null },
      ];
    },
  },
  methods: {
    tooltipForOption(optionId) {
      return this.disabledPosteIds.includes(optionId) ?
        this.$t('workload_plan.modals.duplicate.no_need') : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-workload-plan-modal__day-to-duplicate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__label {
    font-size: 14px;
    font-weight: $fw-semi-bold;
  }
}
</style>
