<template>
  <div
    v-tooltip.right.text-align-left="tooltip"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      class="location-icon__container"
      :style="{ 'background-color': iconColor(!hover) }"
    >
      <template v-if="isFromMobile">
        <div
          v-if="hasLocations"
          class="location-enable-icon__container"
          @click="onClickLocation"
        >
          <LocationIcon
            :fill="iconColor(hover)"
            width="16"
            height="16"
          />
        </div>
        <LocationDisabledIcon
          v-else
          :fill="iconColor(hover)"
          width="16"
          height="16"
        />
      </template>
      <div
        v-else
        :class="shopIconContainerClasses"
        @click="onClickShopIcon"
      >
        <ShopIcon
          :fill="iconColor(hover)"
          width="16"
          height="16"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { BADGED_FROM_MOBILE } from '../constants';

export default {
  name: 'BadgingLocationIcon',
  props: {
    badging: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    tooltip() {
      if (!this.hasLocations) return this.noLocationTooltip;
      let res = '';
      if (this.badging.in) {
        res += `<b>${this.$t('badgings.days.tabs.shifts.location_icons.start_of_shift')}</b><br>${this.addressInToDisplay}<br>`;
      }
      if (this.badging.in && this.badging.out) res += '<br>';
      if (this.badging.out) {
        res += `<b>${this.$t('badgings.days.tabs.shifts.location_icons.end_of_shift')}</b><br>${this.addressOutToDisplay}`;
      }
      return res;
    },
    locations() {
      return this.badging.locations;
    },
    isFromMobile() {
      return this.badging.badgedFrom === BADGED_FROM_MOBILE;
    },
    hasLocations() {
      return !!this.locations;
    },
    canEnableMobilePunchClock() {
      return this.currentShop.attributes.canEnableMobilePunchClock;
    },
    noLocationTooltip() {
      if (this.isFromMobile) return this.$t('badgings.days.tabs.shifts.location_icons.mobile_no_location_tooltip');
      if (!this.canEnableMobilePunchClock) {
        return this.$t('badgings.days.tabs.shifts.location_icons.upsell_tooltip');
      }
      return this.$t('badgings.days.tabs.shifts.location_icons.tablet_location_tooltip');
    },
    isMissingLocationForOneClockInOut() {
      if (!this.hasLocations) return false;
      return !!(
        (this.badging.in && !this.locations.in) || (this.badging.out && !this.locations.out)
      );
    },
    noPunchLocationMessage() {
      return this.$t('badgings.days.tabs.shifts.location_icons.no_location');
    },
    addressInToDisplay() {
      if (!this.locations?.in?.address) return this.noPunchLocationMessage;
      return this.formatAddress(this.locations.in.address);
    },
    addressOutToDisplay() {
      if (!this.locations?.out?.address) return this.noPunchLocationMessage;
      return this.formatAddress(this.locations.out.address);
    },
    shopIconContainerClasses() {
      return {
        'shop-icon__container': true,
        'shop-icon__container--hover': !this.canEnableMobilePunchClock,

      };
    },
  },
  methods: {
    formatAddress(address) {
      return address.trim();
    },
    iconColor(hover) {
      if (!this.isFromMobile) return hover ? '#ebf3fe' : '#2b66fe'; // blue
      if (!this.hasLocations) return hover ? '#f6f6f6' : '#727272'; // light grey
      if (this.isMissingLocationForOneClockInOut) return hover ? '#fdf0c8' : '#f0900d'; // orange
      return hover ? '#eafaf3' : '#08a488'; // green
    },
    onClickLocation() {
      if (this.locations.in && this.locations.out) {
        window.open(`https://www.google.com/maps/dir/\
          ${this.formatAddress(this.locations.in.address).replace(' ', '+')}/\
          ${this.formatAddress(this.locations.out.address).replace(' ', '+')}\
        `);
      }

      if (this.locations?.in) {
        window.open(`https://www.google.com/maps/place/\
          ${this.formatAddress(this.locations.in.address)?.replace(' ', '+')}/\
        `);
        return;
      }

      window.open(`https://www.google.com/maps/place/\
        ${this.formatAddress(this.locations.out.address)?.replace(' ', '+')}/\
      `);
    },
    onClickShopIcon(event) {
      if (!this.canEnableMobilePunchClock) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'punch-clock-upsell-modal');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .location-icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .location-enable-icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .shop-icon__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .shop-icon__container--hover {
    &:hover {
      cursor: pointer;
    }
  }
</style>
