<template>
  <!-- eslint-disable  max-len -->
  <div class="cluster_nodes-column">
    <div>
      <div class="cluster_nodes-column-scroll">
        <draggable
          v-model="cluster.children"
          v-bind="dragOptions"
          :move="onMove"
          @add="onAdd(cluster)"
          @start="setDragStart($event, cluster)"
          @end="onDragEnd"
        >
          <ClusterCard
            v-for="item in cluster.children"
            :key="item.id"
            :item="item"
            :handle-click-on-card="handleClickOnCard"
            :clicked-item-id="cluster.clickedItemId"
            :current-cluster-id="currentClusterId"
            :on-drag-over="onDragOver"
            :on-drag-leave="onDragLeave"
            :loading="loading"
          />
        </draggable>
      </div>
      <draggable
        v-if="showClustersDropZone(draggingItem, cluster)"
        :list="cluster.children"
        v-bind="dragOptions"
        class="drop-zone-wrapper"
        @add="onAdd(cluster)"
      >
        <div
          class="drop-zone-area"
          @dragover="setDropZone"
          @dragleave="targetOutOfDropZone"
        >
          {{ $t('actions.move') }}
        </div>
      </draggable>
      <div
        v-if="cluster.children[0] && cluster.children[0].type === 'cluster_node' && cluster.children[0].siblings_editable"
        :class="`add_node_col_${cluster.parentId}`"
        :parent-id="cluster.parentId"
        class="add_cluster_button"
        @click.stop="toggleCreateModal"
      >
        + {{ $t('organisation_settings.tabs.clusters.add_node') }}
      </div>
      <div
        v-else-if="cluster.children[0] && cluster.children[0].siblings_editable"
        :class="`add_shop_col_${cluster.parentId}`"
        class="add_cluster_button"
        @click="navigateToShopCreation"
      >
        {{ $t('organisation_settings.tabs.clusters.add_shop') }}
        <div class="external-link-svg-container">
          <ExternalLinkSvg fill="#2b66fe" />
        </div>
      </div>
    </div>
    <div v-if="cluster.children.length <= 0">
      <div
        :class="`add_node_col_${cluster.parentId}`"
        :parent-id="cluster.parentId"
        class="add_cluster_button without-border"
        @click.stop="toggleCreateModal"
      >
        + {{ $t('organisation_settings.tabs.clusters.add_node') }}
      </div>
      <div
        :class="`add_shop_col_${cluster.parentId}`"
        class="add_cluster_button without-border"
        @click="navigateToShopCreation"
      >
        {{ $t('organisation_settings.tabs.clusters.add_shop') }}
        <div class="external-link-svg-container">
          <ExternalLinkSvg fill="#2b66fe" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { svcBillingAutomationClient } from '@skello-utils/clients';

import draggable from 'vuedraggable';
import ClusterCard from '../ClusterCard';
import ExternalLinkSvg from '../../assets/svg/ExternalLinkSvg';

export default {
  components: { draggable, ClusterCard, ExternalLinkSvg },
  props: {
    cluster: {
      type: Object,
      required: true,
    },
    handleClickOnCard: {
      type: Function,
      required: true,
    },
    currentClusterId: {
      type: Number,
      default: null,
    },
    onDragStart: {
      type: Function,
      required: true,
    },
    onDragEnd: {
      type: Function,
      required: true,
    },
    onDragOver: {
      type: Function,
      required: true,
    },
    targetInDropZone: {
      type: Function,
      required: true,
    },
    targetOutOfDropZone: {
      type: Function,
      required: true,
    },
    onMove: {
      type: Function,
      required: true,
    },
    onAdd: {
      type: Function,
      required: true,
    },
    onDragLeave: {
      type: Function,
      required: true,
    },
    draggingItem: {
      type: Object,
      required: false,
      default: null,
    },
    showDropZone: {
      type: Boolean,
      required: true,
    },
    toggleCreateModal: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    dragOptions() {
      return {
        group: 'ClusterCard',
        sort: false,
        ghostClass: 'drop-placeholder',
        delay: 0,
        chosenClass: 'item--dragged',
      };
    },
    isRolloutEnabled() {
      // Canary feature flag to disable the new rollout in-app for KA
      if (this.isDevFlagEnabled('FEATUREDEV_ROLLOUT_KEEP_OLD_EXPERIENCE')) {
        return false;
      }

      return this.currentOrganisation.attributes.createdFromSvcBillingAutomation &&
        this.currentOrganisation.attributes.packOffer.name !== 'Timeclock Standalone Success';
    },
  },
  methods: {
    showClustersDropZone(draggingItem, cluster) {
      return this.showDropZone && draggingItem.depth - 1 > cluster.parentDepth;
    },
    async navigateToShopCreation() {
      if (this.isRolloutEnabled) {
        this.$skAnalytics.track('inapp_rollout_click_create_network_shop');

        try {
          const { quoteId } = await svcBillingAutomationClient.createQuoteByOrgaId(
            this.currentOrganisation.id,
            this.currentUser.attributes.firstName,
            this.currentUser.attributes.lastName,
            this.currentUser.attributes.email,
          );
          svcBillingAutomationClient.redirectUserToDealroom(
            quoteId,
            this.cluster.parentId,
            this.currentUser.attributes.lang,
          );
        } catch (error) {
          if (error instanceof Object) {
            console.error('Create quote error:', error.response?.data?.message);
          }

          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        }

        return;
      }

      this.$skAnalytics.track('add_shop_btn_click', { source: 'global_settings' });

      this.$router.push({
        name: 'organisation_settings_shops_new_form',
        params: {
          parentClusterNodeId: this.cluster.parentId,
        },
      });
    },
    setDragStart(evt, cluster) {
      const draggingItemId = evt.item.attributes.id.value;
      const draggingItem = this.cluster.children.find(
        item => item.id === Number(draggingItemId),
      );
      this.onDragStart(evt, draggingItem, cluster);
    },
    setDropZone(evt) {
      this.targetInDropZone(this.cluster);
    },
  },
};
</script>

<style lang="scss" scoped>
.cluster_nodes-column-scroll {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow-y: auto;
  max-height: 55vh;
  border-right: .5px solid #e8eaed;
  border-left: .5px solid #e8eaed;
}

.cluster_nodes-column {
  width: 25%;
  min-width: 240px;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  margin-left: -1px;
  margin-top: -1px;
  border-top: 1px solid #e8eaed;
  position: relative;
  left: 1px;
}

.external-link-svg-container {
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.drop-placeholder {
  display: none;
}

.drop-zone-wrapper {
  border: 1px solid #e8eaed;
  min-height: 60px;
  padding: 8px;
  width: 100%;
}

.drop-zone-area {
  border-radius: 4px;
  display: flex;
  background: $sk-grey-10;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 2px dashed #0d2ed9;
  transition: .3s ease;
  margin-top: -1px;
  font-size: $fs-text-m;
  line-height: 17px;
  color: #0d2ed9;
  font-weight: bold;
}

.card-cluster.item--dragged {
  color: white;
  background-color: #0d46d9;
}

.card-shop.item--dragged {
  color: white;
  background-color: $sk-blue-50;
}

.delimitation-bar {
  width: 100%;
  height: 1px;
  border: .5px solid #e8eaed;
}

.add_cluster_button {
  display: flex;
  justify-content: center;
  width: 100%;
  color: $sk-blue-50;
  background-color: $sk-white;
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  font-size: $fs-text-m;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  &.without-border {
    border-top: none;
    border-bottom: none;
    border-right: none;
  }
}
</style>
