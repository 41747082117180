<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="tooltip__title"
    v-html="title"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  name: 'TooltipTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip__title {
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
}
</style>
