<template>
  <SkModalV2
    id="loading-modal"
    ref="loadingModal"
    size="ds-medium"
    :display-footer="false"
    :title="$t('reports.modals.loading.title')"
    :dismissable="false"
    @show="resetData"
    @close="handleClose"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="loading-modal__description">
          {{ description }}
        </div>
        <div class="loading-modal__loader">
          <SkLoader size="x-large" />
          <div class="loading-modal__loader-percent">
            {{ percentage }}%
          </div>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
export default {
  name: 'LoadingModal',
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      percentage: 0,
    };
  },
  methods: {
    resetData() {
      this.setPercentage(0);
    },
    setPercentage(num) {
      this.percentage = num;
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header__wrapper {
  padding: 20px 24px !important;
}

::v-deep .sk-spinner {
  height: 70px;
  width: 70px;
}

.loading-modal__description {
  text-align: center;
}

.loading-modal__loader {
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-m;
  color: $sk-blue;
}

.loading-modal__loader-percent {
  color: $sk-grey;
  position: absolute;
  padding-bottom: 6px;
}
</style>
