<template>
  <div class="annualization-setup__form">
    <div class="annualization-setup__form__row">
      <SkInfoCard>
        <!-- eslint-disable vue/no-v-html -->
        <span>
          <span
            class="annualization-setup__form__info sk-text-large-regular"
            v-html="infoCardText"
          />
          <SkOroraLink
            target="_blank"
            size="large"
            :href="$t(`${amendmentsLocaleKey}.info.link`)"
          >
            {{ $t(`${amendmentsLocaleKey}.info.help_center`) }}
          </SkOroraLink>
        </span>
        <!-- eslint-enable vue/no-v-html -->
      </SkInfoCard>
    </div>
    <div
      v-if="isTemporary"
      class="annualization-setup__form__row"
    >
      <div class="annualization-setup__form__row__left-cell">
        <div class="annualization-setup__form__row__left-cell__title">
          <span class="sk-text-large-semibold">
            {{ $t(`${amendmentsLocaleKey}.amendment_theoretical_balance`) }}
          </span>
          <QuestionMarkV2Icon
            v-tooltip.top="inputTooltip('amendment_theoretical_balance')"
            width="20"
            height="20"
            class="annualization-setup__form__row__left-cell__icon"
          />
        </div>
        <span class="annualization-setup__form__row__left-cell__subtext sk-text-medium-regular">
          {{ genericSubtext(amendmentStartsAt, amendmentEndsAt) }}
        </span>
      </div>
      <div class="annualization-setup__form__cell">
        <div class="sk-flex">
          <SkInputGroup
            :errored="hasAmendmentTheoreticalBalanceError"
            :error-message="$t('errors.missing_value')"
            class="annualization-setup__form__cell__input"
          >
            <SkInput
              v-model="amendmentTheoreticalBalance"
              type="number"
              min="0"
            />
            <template #append>
              <span class="sk-text-medium-regular theoretical-balance__label">
                h
              </span>
            </template>
          </SkInputGroup>
        </div>
      </div>
    </div>
    <div
      v-else
      class="annualization-setup__form__row"
    >
      <div class="annualization-setup__form__row__left-cell">
        <div class="annualization-setup__form__row__left-cell__title">
          <span class="sk-text-large-semibold">
            {{ $t(`${amendmentsLocaleKey}.new_theoretical_balance`) }}
          </span>
          <QuestionMarkV2Icon
            v-tooltip.top="inputTooltip('new_theoretical_balance')"
            width="20"
            height="20"
            class="annualization-setup__form__row__left-cell__icon"
          />
        </div>
        <span class="annualization-setup__form__row__left-cell__subtext sk-text-medium-regular">
          {{ $t(`${amendmentsLocaleKey}.new_theoretical_balance_subtext`) }}
        </span>
      </div>
      <div class="annualization-setup__form__cell">
        <div class="sk-flex">
          <SkInputGroup
            :errored="hasNewTheoreticalBalanceError"
            :error-message="$t('errors.missing_value')"
            class="annualization-setup__form__cell__input"
          >
            <SkInput
              v-model="newTheoreticalBalance"
              data-test="new_reference"
              type="number"
              min="0"
            />
            <template #append>
              <span class="sk-text-medium-regular theoretical-balance__label">
                h
              </span>
            </template>
          </SkInputGroup>
        </div>
      </div>
    </div>
    <div
      v-if="isTemporary"
      class="annualization-setup__form__row"
    >
      <div class="annualization-setup__form__row__left-cell">
        <div class="annualization-setup__form__row__left-cell__title">
          <span class="sk-text-large-semibold">
            {{ $t(`${amendmentsLocaleKey}.theoretical_balance_after_amendment`) }}
          </span>
          <QuestionMarkV2Icon
            v-tooltip.top="inputTooltip('theoretical_balance_after_amendment')"
            width="20"
            height="20"
            class="annualization-setup__form__row__left-cell__icon"
          />
        </div>
        <span class="annualization-setup__form__row__left-cell__subtext sk-text-medium-regular">
          {{ genericSubtext(afterAmendmentDate, annualizationPeriodEndsAt) }}
        </span>
      </div>
      <div class="annualization-setup__form__cell">
        <div class="sk-flex">
          <SkInputGroup
            :errored="hasTheoreticalBalanceAfterAmendmentError"
            :error-message="$t('errors.missing_value')"
            class="annualization-setup__form__cell__input"
          >
            <SkInput
              v-model="theoreticalBalanceAfterAmendment"
              type="number"
              min="0"
            />
            <template #append>
              <span class="sk-text-medium-regular theoretical-balance__label">
                h
              </span>
            </template>
          </SkInputGroup>
        </div>
      </div>
    </div>
    <div
      v-else
      class="annualization-setup__form__row"
    >
      <div class="annualization-setup__form__row__left-cell">
        <div class="annualization-setup__form__row__left-cell__title">
          <span class="sk-text-large-semibold">
            {{ $t(`${amendmentsLocaleKey}.new_base_to_realize`) }}
          </span>
          <QuestionMarkV2Icon
            v-tooltip.top="inputTooltip('new_base_to_realize')"
            width="20"
            height="20"
            class="annualization-setup__form__row__left-cell__icon"
          />
        </div>
        <span class="annualization-setup__form__row__left-cell__subtext sk-text-medium-regular">
          {{ genericSubtext(amendmentStartsAt, annualizationPeriodEndsAt) }}
        </span>
      </div>
      <div class="annualization-setup__form__cell">
        <div class="sk-flex">
          <SkInputGroup
            :errored="hasProratedTheoreticalError"
            :error-message="$t('errors.missing_value')"
            class="annualization-setup__form__cell__input"
          >
            <SkInput
              v-model="proratedTheoretical"
              data-test="new_hours_todo"
              type="number"
              min="0"
            />
            <template #append>
              <span class="sk-text-medium-regular theoretical-balance__label">
                h
              </span>
            </template>
          </SkInputGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPeriodDatesAt } from '@skelloapp/skello-annualization';

import skDate from '@skello-utils/dates';

export default {
  name: 'AnnualizationSetup',
  props: {
    amendment: {
      type: Object,
      required: true,
    },
    shopAnnualizationConfig: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amendmentsLocaleKey: 'employees.tabs.contracts.full_contract.data.salary.amendments.update_card.create_amendment.modal_v2',
      amendmentTypes: [
        { id: 'temporary', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.temporary') },
        { id: 'permanent', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.permanent') },
      ],
    };
  },
  computed: {
    amendmentTheoreticalBalance: {
      get() {
        return this.value.amendmentTheoreticalBalance;
      },
      set(newValue) {
        this.emitUpdate({ amendmentTheoreticalBalance: newValue });
      },
    },
    theoreticalBalanceAfterAmendment: {
      get() {
        return this.value.theoreticalBalanceAfterAmendment;
      },
      set(newValue) {
        this.emitUpdate({ theoreticalBalanceAfterAmendment: newValue });
      },
    },
    newTheoreticalBalance: {
      get() {
        return this.value.newTheoreticalBalance;
      },
      set(newValue) {
        this.emitUpdate({ newTheoreticalBalance: newValue });
      },
    },
    proratedTheoretical: {
      get() {
        return this.value.proratedTheoretical;
      },
      set(newValue) {
        this.emitUpdate({ proratedTheoretical: newValue });
      },
    },
    afterAmendmentDate() {
      return skDate.utc(this.amendment.endsAt).add(1, 'day').format('DD/MM/YY');
    },
    amendmentStartsAt() {
      return skDate.utc(this.amendment.startsAt).format('DD/MM/YY');
    },
    amendmentEndsAt() {
      return skDate.utc(this.amendment.endsAt).format('DD/MM/YY');
    },
    annualizationPeriodEndsAt() {
      const referenceDay = this.isTemporary ?
        skDate.utc(this.amendment.endsAt).add(1, 'day') :
        skDate.utc(this.amendment.startsAt);
      const period = getPeriodDatesAt(
        referenceDay,
        skDate.utc(this.shopAnnualizationConfig.resetDate),
      );

      return skDate.utc(period.endDate).format('DD/MM/YY');
    },
    hasAmendmentTheoreticalBalanceError() {
      return !this.amendmentTheoreticalBalance ||
        isNaN(parseInt(this.amendmentTheoreticalBalance, 10)) ||
        parseInt(this.amendmentTheoreticalBalance, 10) < 0;
    },
    hasNewTheoreticalBalanceError() {
      return !this.newTheoreticalBalance ||
        isNaN(parseInt(this.newTheoreticalBalance, 10)) ||
        parseInt(this.newTheoreticalBalance, 10) < 0;
    },
    hasProratedTheoreticalError() {
      return !this.proratedTheoretical ||
        isNaN(parseInt(this.proratedTheoretical, 10)) ||
        parseInt(this.proratedTheoretical, 10) < 0;
    },
    hasTheoreticalBalanceAfterAmendmentError() {
      return !this.theoreticalBalanceAfterAmendment ||
        isNaN(parseInt(this.theoreticalBalanceAfterAmendment, 10)) ||
        parseInt(this.theoreticalBalanceAfterAmendment, 10) < 0;
    },
    infoCardText() {
      return this.$t(
        `${this.amendmentsLocaleKey}.info.${this.amendment.type}_description`,
        { date: skDate.utc(this.amendment.startsAt).format('DD/MM/YYYY') },
      );
    },
    isTemporary() {
      return this.amendment.type === this.amendmentTypes[0].id;
    },
  },
  methods: {
    emitUpdate(annualizationData) {
      this.$nextTick(() => {
        this.$emit('input', {
          amendmentTheoreticalBalance: this.amendmentTheoreticalBalance,
          theoreticalBalanceAfterAmendment: this.theoreticalBalanceAfterAmendment,
          newTheoreticalBalance: this.newTheoreticalBalance,
          proratedTheoretical: this.proratedTheoretical,
          ...annualizationData,
        });
      });
    },
    genericSubtext(from, to) {
      return this.$t(`${this.amendmentsLocaleKey}.generic_subtext`, { from, to });
    },
    inputTooltip(key) {
      return this.$t(`${this.amendmentsLocaleKey}.${key}_tooltip`);
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-setup__form {
  padding: 8px 24px;

  &__info {
    color: $sk-black;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      padding-bottom: 24px;
    }

    &__left-cell {
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        align-items: center;
      }

      &__subtext {
        color: $sk-grey-50;
      }

      &__icon {
        margin: 0 10px 0 4px;
      }
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    width: calc(50% + 32px);

    &__input {
      display: flex;
      flex-direction: column;
      width: 130px;
    }
  }
}

.theoretical-balance__label {
  color: $sk-grey-50;
}

::v-deep .sk-input-group__error-label {
  margin: 4px 0 0 0 !important;
  line-height: normal;
  font-weight: $fw-regular;
}
</style>

<style lang="scss">
span.sk-text-large-regular b,
span.sk-text-large-regular a {
  color: $sk-black;
  font-size: $fs-text-l;
  line-height: normal;
  font-weight: $fw-semi-bold;
}
</style>
