<template>
  <div>
    <div class="error-step__user-icon__wrapper">
      <div class="error-step__user-icon">
        <UserWithSparklesIcon />
      </div>
    </div>
    <div>
      <i18n
        path="plannings.toolbar.modal.esignature_upsell.error_step.title"
        tag="h3"
        class="error-step__title sk-header--3"
      >
        <template #breakLine>
          <br>
        </template>
      </i18n>
      <h4 class="error-step__subtitle">
        {{ $t('plannings.toolbar.modal.esignature_upsell.error_step.subtitle') }}
      </h4>
    </div>
    <div class="error-step__submit">
      <SkOroraButton
        v-if="displaySubmit"
        :loading="isSending"
        @click="handleSubmit"
      >
        {{ $t('plannings.toolbar.modal.esignature_upsell.error_step.submit') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  mapState,
  mapGetters,
} from 'vuex';

import UserWithSparklesIcon from './assets/UserWithSparklesIcon';

export default {
  name: 'ErrorStep',
  components: { UserWithSparklesIcon },
  data() {
    return {
      isSending: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled']),
    displaySubmit() {
      return this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL_ERROR_SUBMIT');
    },
  },
  methods: {
    handleSubmit() {
      if (this.isSending) return;

      this.isSending = true;
      this.$skAnalytics.track('esignature_upsell_error_submit');

      const params = {
        shop_id: this.currentShop.id,
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('plannings.toolbar.modal.esignature_upsell.error_step.submit'),
          upsell_type: 'esignature',
          current_page: 'Esignature needs intervention',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.esignature_upsell.request_demo.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('plannings.toolbar.modal.esignature_upsell.request_demo.error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.isSending = false;
          this.$emit('submit');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-step__user-icon__wrapper {
  margin-top: 57px;
  margin-bottom: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.error-step__user-icon {
  width: 157px;
  height: 95px;
}

.error-step__title {
  font-size: $fs-heading-m;
  text-align: center;
}

.error-step__subtitle {
  text-align: center;
  color: $sk-grey;
  font-weight: $fw-regular;
  margin-top: 12px;
  margin-bottom: 100px;
}

.error-step__submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
