<template>
  <span
    v-if="shopPlanningConfig.attributes.birthdayDisplay &&
      birthdaysToday.length > 0"
    v-tooltip.bottom.offset="formatBirthdays"
  >
    {{ $t('plannings.table.header.days.emoji.cake') }}
  </span>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

export default {
  name: 'BirthdayTag',
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsState', ['shopPlanningConfig']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('planningsUsers', ['birthdayUsersAtDay']),
    birthdaysToday() {
      return this.birthdayUsersAtDay(this.day.date);
    },
    formatBirthdays() {
      const userNames = this.birthdaysToday.map(user => this.fullName(user)).join(', ');
      return `${this.$t('plannings.table.header.days.tooltips.birthdays')} ${userNames}`;
    },
  },
};
</script>
