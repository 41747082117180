<template>
  <div class="workforce__wrapper">
    <div
      v-for="(workforce, quarterIndex) in workforcesByQuarters"
      :key="`workforce-quarter-${quarterIndex}`"
      :style="{width: `${dayViewPlanningSizeVariables.pixelPerQuarterHours}px`}"
      class="workforce__quarter"
    >
      <div :class="workforceQuarterValueClasses(quarterIndex)">
        {{ workforce }}
      </div>
      <div
        v-if="isCurrentQuarter(quarterIndex)"
        class="workforce__label"
      >
        {{ $tc('plannings.table.header.employees', workforce) }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';

export default {
  name: 'Workforce',
  data() {
    return {
      dateTimeQuarters: null,
      currentTime: skDate().utc(true),
    };
  },
  computed: {
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek', 'dayCellShifts']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('planningsUsers', ['filteredUsers']),

    workShiftsForShop() {
      const shiftsForDay = this.dayCellShifts(this.currentDate, this.shiftsForCurrentWeek);
      const filteredUserIds = this.filteredUsers.map(user => user.id);

      return shiftsForDay.filter(shift => (
        !shift.relationships.poste.attributes.absenceKey &&
        shift.attributes.shopId === parseInt(this.currentShop.id, 10) &&
        shift.attributes.userId &&
        filteredUserIds.includes(String(shift.attributes.userId))
      ));
    },
    workforcesByQuarters() {
      if (!this.dateTimeQuarters) return [];

      // Create an empty array the size of all quarters
      // and fill it with total workforces for each quarter
      return this.dateTimeQuarters.map(dateTimeQuarter => (
        // Go through all shifts to check the ones that match the quarter
        this.workShiftsForShop.reduce((totalForQuarter, shift) => {
          if (
            skDate.utc(shift.attributes.startsAt).isBefore(skDate(dateTimeQuarter).add(15, 'minutes')) && // +15 min to handle the case where a shift starts 1->14 minutes after the start of the hour quarter
            skDate.utc(shift.attributes.endsAt).isAfter(dateTimeQuarter)
          ) {
            return totalForQuarter + 1;
          }
          return totalForQuarter;
        }, 0)),
      );
    },
  },
  watch: {
    currentDate() {
      this.updateDateTimeQuarters();
    },
    currentShop() {
      this.updateDateTimeQuarters();
    },
  },
  mounted() {
    this.updateDateTimeQuarters();
    // Time Indicator needs to be synchronized with minute change on three
    // different components between Header, workforce by hour quarter and Planning
    // setTimeout is used in a way to wait for a minute change to trigger
    // the setInterval loop
    setTimeout(() => {
      setInterval(() => {
        this.setCurrentTime();
      }, 60 * 1000);
    }, (60 - skDate().seconds()) * 1000);
  },
  methods: {
    updateDateTimeQuarters() {
      const { openingTime, closingTime } = openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        skDate.utc(this.currentDate).format(),
      );
      // Fill an array of skDate with each quarters of all shop hours
      const currentQuarter = openingTime.clone();
      this.dateTimeQuarters = [currentQuarter.clone()];
      while (currentQuarter.isBefore(closingTime)) {
        this.dateTimeQuarters.push(currentQuarter.add(15, 'm').clone());
      }
      // A shift cannot start on last quarter, remove it
      this.dateTimeQuarters.pop();
    },
    workforceQuarterValueClasses(quarterIndex) {
      return {
        'workforce__quarter-value': true,
        'workforce__quarter-value--current': this.isCurrentQuarter(quarterIndex),
      };
    },
    isCurrentQuarter(quarterIndex) {
      // Last quarter cannot be highlighted
      if (quarterIndex === this.dateTimeQuarters.length - 1) return false;

      return this.currentTime.isBetween(this.dateTimeQuarters[quarterIndex], this.dateTimeQuarters[quarterIndex + 1], undefined, '[)');
    },
    setCurrentTime() {
      this.currentTime = skDate().utc(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.workforce__wrapper {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.workforce__quarter {
  display: inline-block;
  height: 100%;
  text-align: center;
  font-size: 9px;
  position: relative;
}

.workforce__quarter-value {
  color: $sk-grey;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  &--current {
    position: relative;
    top: -5px;
    left: -2px;
    width: 21px;
    height: 21px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $sk-grey;
    border-radius: 50%;
    color: white;
  }
}

.workforce__label {
  position: absolute;
  color: $sk-grey;
  margin: -5px 0 0 -9px;
}
</style>
