<template>
  <div class="empty-requests-page__container">
    <div class="empty-requests-page__content">
      <div class="empty-requests-page__icon">
        <component
          :is="icon"
          height="72px"
          width="72px"
          :fill="$skColors.skBlue50"
        />
      </div>
      <span class="empty-requests-page__header">
        {{ headerText }}
      </span>
      <p class="empty-requests-page__paragraph">
        {{ contentText }}
      </p>
      <div
        v-if="buttonText"
        class="empty-requests-page__cta"
      >
        <SkOroraButton
          v-tooltip="disabledTooltip"
          :disabled="isButtonDisabled"
          variant="highlighted"
          :data-test="addButtonDataTest"
          @click="buttonCallback"
        >
          {{ buttonText }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyRequestsPage',
  props: {
    icon: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    contentText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: null,
    },
    buttonCallback: {
      type: Function,
      default: () => {},
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    addButtonDataTest: {
      type: String,
      required: true,
    },
  },
  computed: {
    disabledTooltip() {
      if (!this.isButtonDisabled) return null;

      return this.$t('requests.leave_requests.own.disabled');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-requests-page__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  width: calc(100% - 310px + 32px); // sidebar width + global padding
}

.empty-requests-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;

  p {
    margin: 0;
  }
}

.empty-requests-page__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sk-blue-5;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.empty-requests-page__header {
  color: $sk-black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 8px;
  font-size: $fs-heading-s;
  font-weight: $fw-semi-bold;
  line-height: normal;
}

.empty-requests-page__paragraph {
  color: $sk-grey-50;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $fs-text-m;
  line-height: normal;
}

.empty-requests-page__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
</style>
