<template>
  <div class="price-step__wrapper">
    <i18n
      path="plannings.toolbar.modal.esignature_upsell.price_step.subtitle"
      tag="h3"
      class="price-step__subtitle"
    >
      <template #shopName>
        <b>{{ shopName }}</b>
      </template>
    </i18n>

    <div class="price-step__table">
      <div class="price-step__line">
        <div class="price-step__cell">
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.option_name') }}
        </div>
        <div class="price-step__cell price-step__price">
          {{ esignaturePrice }} €
        </div>
      </div>

      <hr class="price-step__separator">

      <div class="price-step__line">
        <div class="price-step__cell">
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.ex_vat') }}
        </div>
        <div class="price-step__cell price-step__price">
          {{ esignaturePrice }} €
        </div>
      </div>

      <div class="price-step__line">
        <div class="price-step__cell">
          {{
            $t(
              'plannings.toolbar.modal.esignature_upsell.price_step.vat',
              { tax_rate: displayedTaxRate }
            )
          }}
        </div>
        <div class="price-step__cell price-step__price">
          {{ taxAmount }} €
        </div>
      </div>

      <div class="price-step__line price-step__line--total">
        <div class="price-step__cell">
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.total') }}
        </div>
        <div
          class="price-step__cell price-step__price"
          data-test="es-total-price"
        >
          {{
            $t(
              'plannings.toolbar.modal.esignature_upsell.price_step.monthly_price',
              { total: priceWithTaxes }
            )
          }}
        </div>
      </div>
    </div>

    <div class="price-step__terms">
      {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.terms') }}
    </div>

    <label class="price-step__check-line">
      <div class="price-step__check">
        <SkCheckBox
          v-model="areConditionsAccepted"
          data-test="es-check-terms"
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="price-step__check-text"
      >
        {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.accept_conditions.text') }}
        <SkOroraLink
          :href="$t('plannings.toolbar.modal.esignature_upsell.price_step.accept_conditions.href')"
          size="small"
          target="_blank"
          rel="noopener norefferer"
        >
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.accept_conditions.link_text') }}
        </SkOroraLink>
      </div>
      <!-- eslint-enable vue/no-v-html -->
    </label>

    <label class="price-step__check-line">
      <div class="price-step__check">
        <SkCheckBox
          v-model="isReengagementAccepted"
          data-test="es-check-renew"
        />
      </div>
      <div class="price-step__check-text">
        {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.restart_engagement') }}
      </div>
    </label>

    <div class="price-step__submits">
      <div class="price-step__submits__side">
        <a
          class="price-step__back"
          @click="triggerPrevious"
        >
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.back') }}
        </a>
      </div>

      <div class="price-step__submit-btn">
        <SkOroraButton
          data-test="es-confirm-subscription"
          :disabled="isNextDisabled"
          :loading="isLoading"
          @click="triggerNext"
        >
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_step.submit') }}
        </SkOroraButton>
      </div>

      <div class="price-step__submits__side" />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

const COUNTRY_PRICES = {
  ES: 30,
  FR: 40,
  PF: 40,
  GP: 40,
  MQ: 40,
  RE: 40,
  other: 40,
};

const TAX_RATES = {
  ES: 21,
  FR: 20,
  PF: 16,
  GP: 8.5,
  MQ: 8.5,
  RE: 8.5,
  other: 0,
};

export default {
  name: 'PriceStep',
  props: {
    featurePrice: {
      type: Object,
      require: true,
      default() { return {}; },
    },
    initialStep: {
      type: String,
      default: 'IntroStep',
    },
  },
  data() {
    return {
      areConditionsAccepted: false,
      isReengagementAccepted: false,
      featurePrices: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    shopName() {
      return this.currentShop.attributes.name;
    },
    isNextDisabled() {
      return !(this.areConditionsAccepted && this.isReengagementAccepted);
    },
    esignaturePrice() {
      if (this.isDevFlagEnabled('FEATUREDEV_SALESFORCE_UPSELL_PRICE_FETCHER')) {
        return this.featurePrice.base_price.toFixed(2);
      }
      return COUNTRY_PRICES[this.currentShop.attributes.country] ?? COUNTRY_PRICES.other;
    },
    displayedTaxRate() {
      if (this.currentShop.attributes.country) {
        if (this.isDevFlagEnabled('FEATUREDEV_SALESFORCE_UPSELL_PRICE_FETCHER')) {
          return this.featurePrice.vat * 100;
        }
        return this.taxRate;
      }
      return '-';
    },
    taxRate() {
      if (Object.keys(TAX_RATES).includes(this.currentShop.attributes.country)) {
        return TAX_RATES[this.currentShop.attributes.country];
      }

      return TAX_RATES.other;
    },
    taxAmount() {
      if (this.isDevFlagEnabled('FEATUREDEV_SALESFORCE_UPSELL_PRICE_FETCHER')) {
        const price = Math.max(
          (this.featurePrice.base_price - this.featurePrice.discount),
          0,
        );

        return (price * this.featurePrice.vat).toFixed(2);
      }

      if (!this.taxRate) return 0;

      return (this.esignaturePrice * (this.taxRate / 100)).toFixed(2);
    },
    priceWithTaxes() {
      if (!this.isDevFlagEnabled('FEATUREDEV_SALESFORCE_UPSELL_PRICE_FETCHER')) {
        return this.legacyPriceWithTaxes;
      }

      const price = Math.max(
        (this.featurePrice.base_price - this.featurePrice.discount),
        0,
      );

      const result =
        (
          parseFloat(price)
        ) + parseFloat(this.taxAmount);
      return result.toFixed(2);
    },
    legacyPriceWithTaxes() {
      if (!this.taxRate) return this.esignaturePrice;
      return (this.esignaturePrice + (this.esignaturePrice * (this.taxRate / 100))).toFixed(2);
    },
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('currentOrganisation', ['requestFeaturesUpsellDownsell']),
    triggerNext() {
      if (this.isNextDisabled || this.isLoading) return;

      this.isLoading = true;
      this.$skAnalytics.track('esignature_upsell_confirm_subscription');

      const params = {
        features: [{
          shop_id: this.currentShop.id,
          feature_name: 'esignature',
          status: 'Activated',
        }],
      };

      this.requestFeaturesUpsellDownsell(params)
        .finally(() => {
          // We need to update the current shop to know if the e-signature is activated on the shop
          const { id: shopId } = this.currentShop;
          this.updateCurrentShop({ shopId })
            .finally(() => {
              this.$emit('update-step', 'ActivatedStep');
              this.isLoading = false;
            });
        });
    },
    triggerPrevious() {
      this.$skAnalytics.track('esignature_upsell_back');
      this.$emit('go-back');
      this.$emit('update-step', this.initialStep);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: .5rem;
}

.price-step__wrapper {
  margin-top: 15px;
}

.price-step__subtitle {
  font-weight: $fw-regular;
  font-size: $fs-text-m;
  margin-bottom: 15px;
}

.price-step__table {
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  padding: 20px;
  font-size: $fs-text-s;
  margin-bottom: 10px;
}

.price-step__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-step__price {
  color: $sk-grey;
}

.price-step__separator {
  border: 0;
  border-top: 1px solid $sk-grey-10;
  margin: 15px 0;
}

.price-step__line--total {
  margin-top: 9px;
  font-size: $fs-text-l;
  color: #febf3f;
  font-weight: $fw-semi-bold;

  .price-step__price {
    color: #febf3f;
  }
}

.price-step__terms {
  font-size: $fs-text-xs;
  line-height: 130%;
  margin-bottom: 25px;
  color: $sk-grey;
}

.price-step__check-line {
  display: flex;
  align-items: center;
  font-size: $fs-text-s;
  margin-bottom: 10px;
  cursor: pointer;
}

.price-step__check {
  margin-right: 8px;
}

.price-step__submits {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.price-step__back {
  cursor: pointer;
  color: $sk-grey;

  &:hover {
    cursor: pointer;
  }
}

.price-step__submits__side {
  width: 20%;
}
</style>
