<template>
  <!-- eslint-disable max-len -->
  <SkModal
    id="change-modulation-majoration-modal"
    ref="changeModulationMajorationModal"
    :tracking-options="trackingOptions"
    :modal-title="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.title')"
    size="inherited"
    @show="handleShowModal"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <div
        v-if="loading"
        class="shop-settings-modal-change-majoration-section__spinner"
      >
        <SkSelectSpinner />
      </div>
      <div v-else>
        <div class="shop-settings-modal-change-majoration-section__balance__warning-banner">
          <SkInnerBanner variant="danger">
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.warning_banner') }}
          </SkInnerBanner>
        </div>
        <SkModalSection class="shop-settings-modal-change-majoration-section">
          <div class="shop-settings-modal-change-majoration-section__majoration">
            <div class="shop-settings-modal-change-majoration-section__majoration__title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.majoration') }}
            </div>
            <div class="shop-settings-modal-change-majoration-section__majoration__switch">
              <SkSwitch
                v-model="majorationActivated"
                disabled
              />
            </div>
          </div>
        </SkModalSection>
        <SkModalSection class="shop-settings-modal-change-majoration-section">
          <div class="shop-settings-modal-change-majoration-section__init-date">
            <div class="shop-settings-modal-change-majoration-section__datepicker-title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.date.title') }}
              <div class="shop-settings-modal-change-majoration-section__datepicker-title__tooltip">
                <CircledQuestionMarkIcon
                  v-tooltip="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.date.tooltip')"
                  width="18"
                  height="30"
                />
              </div>
            </div>
            <div class="shop-settings-modal-change-majoration-section__datepicker-wrapper">
              <SkDatePicker
                ref="datepicker"
                v-model="initDate"
                :disabled-date="disabledDays"
                :placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.date.placeholder')"
                :errored="hasInitDateError"
                :error-message="initDateErrorMessage"
                :lang="$i18n.locale"
                input-moment-format="dddd DD MMM YYYY"
                hide-footer
              />
              <p
                class="shop-settings-modal-change-majoration-section__date-hint sk-subtitle--small"
              >
                {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.date.hint') }}
              </p>
            </div>
          </div>
        </SkModalSection>
        <SkModalSection
          :padding-bottom="false"
          border-bottom="none"
          class="shop-settings-modal-change-majoration-section"
        >
          <div class="shop-settings-modal-change-majoration-section__balance__header">
            <b class="shop-settings-modal-change-majoration-section__balance__header__title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.title') }}
            </b>
            <SkSwitch
              v-model="balanceActivated"
              v-tooltip="disabledHistoricTooltip"
              :disabled="usersEmpty"
              class="shop-settings-modal-change-majoration-section__balance__switch"
              @click.native="trackBalanceActivated"
            />
          </div>
          <p class="sk-subtitle--medium">
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.explanation') }}
          </p>
          <div v-if="balanceActivated">
            <SkCard class="shop-settings-modal-change-majoration-section__balance__card">
              <div class="shop-settings-modal-change-majoration-section__balance__card__icon">
                <CircledIIcon
                  fill=""
                  width="24"
                  height="24"
                />
              </div>
              <div>
                <div>
                  <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.card.explanation_1')" />
                  <CircledPlusSignThinIcon class="shop-settings-modal-change-majoration-section__icon-modifier" />
                  {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.card.explanation_2') }}
                </div>
                <div>
                  <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.card.explanation_3')" />
                  <CircledMinusSignThinIcon class="shop-settings-modal-change-majoration-section__icon-modifier" />
                  {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.card.explanation_2') }}
                </div>
              </div>
            </SkCard>
            <div class="shop-settings-modal-change-majoration-section__balance__form__hint">
              <p
                class="sk-subtitle--small
                  shop-settings-modal-change-majoration-section__balance__form__hint__text"
              >
                {{ $t('employees.tabs.counters.hours_counter.modal.balance.hint') }}
              </p>
              <CircledQuestionMarkIcon
                v-tooltip="$t('employees.tabs.counters.hours_counter.modal.balance.hint_tooltip')"
                width="14"
                height="14"
              />
            </div>
            <UserRow
              v-for="user in users"
              :key="user.id"
              :user="user"
              :unit="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.hours')"
              :tracking-options="trackingOptions"
              @keyup="handleErroredBalance"
            />
          </div>
        </SkModalSection>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="trackingOptions.submit"
        :submit-disabled="!loading"
        :disabled="hasInitDateError || emptyOrNaNUserBalance"
        :loading="loadingSubmit"
        @click="handleSubmit"
      >
        {{ $t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import {
  mapState,
  mapMutations,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import { isFloat } from '@skello-utils/validators';

import UserRow from '@app-js/shared/components/CountersInitializationModal/UserRow';

export default {
  name: 'ChangeModulationMajorationModal',
  components: { UserRow },
  data() {
    return {
      originalData: null,
      majorationActivated: false,
      balanceActivated: false,
      initDate: null,
      users: [],
      loading: true,
      loadingSubmit: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),

    isInitDateSunday() {
      if (!this.initDate) return false;

      return skDate(this.initDate).isSunday();
    },
    hasInitDateError() {
      return !this.initDate || !this.isInitDateSunday;
    },
    initDateErrorMessage() {
      if (!this.isInitDateSunday) {
        return this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.date.not_sunday');
      }

      return this.$t('errors.missing_value');
    },
    balanceSectionClasses() {
      return {
        'shop-settings-modal-change-majoration-section__balance--disabled': !this.balanceActivated,
      };
    },
    usersEmpty() {
      return this.users.length === 0;
    },
    allBalanceModifier() {
      return this.users.reduce((object, user) => {
        object[user.id] = user.balanceModifier;
        return object;
      }, {},
      );
    },
    allUsersInitialCounters() {
      return this.users.reduce((object, user) => {
        object[user.id] = user.balance;
        return object;
      }, {},
      );
    },
    emptyOrNaNUserBalance() {
      if (!this.balanceActivated) return false;

      return this.users.some(user => user.balance === null || isNaN(user.balance) || user.balance === '');
    },
    disabledHistoricTooltip() {
      if (!this.usersEmpty || this.loading) return '';

      return this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.empty_users_explanation');
    },
    trackingOptions() {
      return {
        submit: 'majoration_hours_counter_update',
        cancel: 'majoration_hours_counter_cancelled',
        plus: 'maj_init_hours_counter_plus',
        minus: 'maj_init_hours_counter_minus',
      };
    },
  },
  watch: {
    balanceActivated(newValue) {
      if (newValue) return;
      this.$refs.changeModulationMajorationModal.resetScroll();
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),

    trackBalanceActivated(newValue) {
      const title = newValue ? 'balance_majoration_enable' : 'balance_majoration_disable';

      this.$skAnalytics.track(title);
    },

    handleShowModal() {
      this.majorationActivated = !this.currentShop.attributes.modulationMajoration;

      if (this.currentShop.attributes.countersInitializationDoneAt) {
        // in front we need sunday but we register following monday in back
        this.initDate =
          skDate(this.currentShop.attributes.countersInitializationDoneAt).getLastSunday().format('YYYY-MM-DD');
      }

      this.fetchUsers();
    },
    fetchUsers() {
      const modifier = { balanceModifier: '+' };

      httpClient
        .get('/v3/api/users/hours_counters_reinitialization_users', { params: { shop_id: this.currentShop.id } })
        .then(response => {
          response.data.data.forEach(user => this.users.push(user));
          this.users = this.users.map(
            user => ({ ...user, ...modifier, balance: null, errorBalance: false }),
          );
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.fetch_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.originalData = cloneDeep(
            {
              initDate: this.initDate,
              users: this.users,
            },
          );
        });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    handleErroredBalance(event, user) {
      if (['Tab', 'Shift'].includes(event.key)) return;

      user.errorBalance = user.balance === '' || user.balance === null || !isFloat(user.balance);
    },
    dirty() {
      for (const key in this.originalData) {
        if (!isEqual(this[key], this.originalData[key])) return true;
      }
      return false;
    },
    handleCancel(event) {
      if (this.dirty()) {
        /* eslint-disable no-alert */
        // Has to be a window.confirm
        // To trigger browser 'data will not be saved' text
        // In modern browsers
        const confirm = window.confirm(this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.leave_without_saving_confirm'));
        if (!confirm) {
          event.preventDefault();
          return;
        }
      }
      this.$refs.datepicker.resetModified();
      this.resetData();
    },
    handleSubmit() {
      const params = {
        modulation_majoration: this.majorationActivated,
        start_date: this.initDate,
        counter_sign: this.allBalanceModifier,
        user_initial_counter: this.allUsersInitialCounters,
        balance_not_inherited: !this.balanceActivated,
        reinitialize_modulation_majoration: true,
      };

      this.loadingSubmit = true;

      httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}/initial_counters`, params)
        .then(response => {
          this.setShopAttributes({
            countersInitializationDoneAt: this.initDate,
            modulationMajoration: this.majorationActivated,
          });

          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.init_complete'),
            variant: 'success',
          });
          this.resetData();
          this.$refs.changeModulationMajorationModal.hide();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-modal-change-majoration-section__spinner {
  display: flex;
  height: 350px;
  width: 620px;
  justify-content: center;
}

.shop-settings-modal-change-majoration-section__balance__warning-banner {
  width: 618px;
}

.shop-settings-modal-change-majoration-section {
  width: 570px;

  .shop-settings-modal-change-majoration-section__init-date,
  .shop-settings-modal-change-majoration-section__majoration,
  .shop-settings-modal-change-majoration-section__balance__header {
    display: flex;
    justify-content: space-between;
  }

  .shop-settings-modal-change-majoration-section__datepicker-title {
    width: 200px;
    font-weight: $fw-semi-bold;
    line-height: 22px;
    display: flex;

    .shop-settings-modal-change-majoration-section__datepicker-title__tooltip {
      margin: 8px 0 0 15px;
    }
  }

  .shop-settings-modal-change-majoration-section__majoration__title,
  .shop-settings-modal-change-majoration-section__balance__header__title {
    font-weight: $fw-semi-bold;
    margin: auto 0;
  }

  .shop-settings-modal-change-majoration-section__datepicker-wrapper {
    width: 340px;
  }

  .shop-settings-modal-change-majoration-section__majoration__switch {
    width: 340;
    float: right;
  }

  .shop-settings-modal-change-majoration-section__date-hint {
    margin-left: 10px;
  }

  .shop-settings-modal-change-majoration-section__balance__card {
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid $sk-grey-10;
    font-size: $fs-text-m;
    color: $sk-grey;
    display: flex;
    justify-content: flex-start;
    padding: 10px 70px 10px 10px;
  }

  .shop-settings-modal-change-majoration-section__balance__card__icon {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    fill: $sk-blue;
  }

  .shop-settings-modal-change-majoration-section__balance__form {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 10px;
  }

  .shop-settings-modal-change-majoration-section__balance__medallion {
    margin-right: 20px;
  }

  .shop-settings-modal-change-majoration-section__balance__modifiers {
    margin-right: 10px;
    margin-left: auto;
    cursor: pointer;
  }

  .shop-settings-modal-change-majoration-section__balance__modifier {
    margin-left: 3px;
  }

  .shop-settings-modal-change-majoration-section__balance__form__input {
    margin-right: 0;
    width: 200px;
  }

  .shop-settings-modal-change-majoration-section__balance__form__hint {
    margin: 20px 0 20px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .shop-settings-modal-change-majoration-section__balance__form__hint__text {
    margin-right: 4px;
  }

  .shop-settings-modal-change-majoration-section__icon-modifier {
    margin-bottom: -4px;
  }

  .shop-settings-modal-change-majoration-section__balance--disabled {
    opacity: .3;
    pointer-events: none;
  }
}
</style>
