<template>
  <div
    v-if="isBrainLoadingAnimationAvailable && loadingBrainShiftConfig.brainLoading"
    class="planning-row__day-cell__brain-api-loading"
    :style="{
      opacity: brainLoadingAnimationOpacity,
      'background-color': brainLoadingAnimationBackgrounColor,
    }"
  >
    <div class="planning-row__shift-inner-top">
      <div class="planning-row__shift-inner-top-left" />
      <div class="planning-row__shift-inner-top-right" />
    </div>
    <div class="planning-row__shift-inner-bottom">
      <div />
    </div>
  </div>
</template>

<script>
const brainLoadingAnimationOpacityLevels = [
  1,
  0,
  1,
  0,
];

export default {
  name: 'LoadingBrainShift',
  props: {
    day: {
      type: Object,
      required: true,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    dayCellShifts: {
      type: Array,
      required: true,
    },
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    isCellDisabled: {
      type: Boolean,
      default: false,
    },
    loadingBrainShiftConfig: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animationValue: 1,
      animationInterval: null,
    };
  },
  computed: {
    isBrainLoadingAnimationAvailable() {
      return !this.isAvailabilitiesComponentDisplayed &&
        !this.isCellDisabled &&
        !this.isUnassignedShiftsRow &&
        this.dayCellShifts.length === 0 &&
        this.getCurrentSlotBrainShifts();
    },
    brainLoadingAnimationOpacity() {
      return brainLoadingAnimationOpacityLevels[this.animationValue];
    },
    brainLoadingAnimationBackgrounColor() {
      const brainShiftOnThisSlot = this.getCurrentSlotBrainShifts();
      return brainShiftOnThisSlot.attributes.color || '#ddd';
    },
  },
  watch: {
    'loadingBrainShiftConfig.brainLoading': function handleBrainLoadingChange(newValue) {
      if (newValue) {
        this.startBrainLoadingAnimation();
      } else {
        this.stopBrainLoadingAnimation();
      }
    },
  },
  destroyed() {
    this.stopBrainLoadingAnimation();
  },
  methods: {
    getCurrentSlotBrainShifts() {
      const userId = this.rowItem.id;
      return this.loadingBrainShiftConfig.brainShifts.find(
        brainShift => brainShift.attributes.startsAt.split(' ')[0] === this.day.date &&
          brainShift.relationships.user?.data?.id === userId,
      );
    },
    startBrainLoadingAnimation() {
      this.animationValue = Math.floor(Math.random() * brainLoadingAnimationOpacityLevels.length);
      this.animationInterval = setInterval(() => {
        this.animationValue = Math.floor(Math.random() * brainLoadingAnimationOpacityLevels.length);
      }, 200);
    },
    stopBrainLoadingAnimation() {
      clearInterval(this.animationInterval);
    },

  },
};
</script>

<style lang="scss" scoped>
.planning-row__day-cell__brain-api-loading {
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100% - 6px);
  border-radius: 6px;
  margin: 3px;
  padding: .4em;
  transition: all 1s ease-in-out;

  .planning-row__shift-inner-top {
    flex: 0 0 16px;
    margin-bottom: 3px;
    justify-content: space-between;
    display: flex;

    div.planning-row__shift-inner-top-left {
      width: 30%;
      height: 14px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .2);
    }

    div.planning-row__shift-inner-top-right {
      width: 5%;
      height: 14px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .2);
    }
  }

  .planning-row__shift-inner-bottom {
    flex-grow: 1;

    div {
      height: 14px;
      width: 90%;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .2);
    }
  }
}
</style>
