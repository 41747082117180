<template>
  <div class="skeleton-section skeleton-section--bordered">
    <LineSkeleton
      class="settings-detail__skeleton--title"
      emphasized
    />
    <div class="skeleton-section__row">
      <span class="skeleton-section__label">
        <LineSkeleton class="settings-detail__skeleton--label-simple" />
      </span>
      <span class="skeleton-section__single-input">
        <LineSkeleton class="settings-detail__skeleton--input-large" />
      </span>
    </div>
    <div class="skeleton-section__row">
      <span class="skeleton-section__label">
        <LineSkeleton class="settings-detail__skeleton--label-simple" />
      </span>
      <span>
        <LineSkeleton class="settings-detail__skeleton--input-small" />
      </span>
    </div>
  </div>
</template>

<script>
import LineSkeleton from '../../../shared/components/LineSkeleton';

export default {
  name: 'AbsencesSectionSkeleton',
  components: {
    LineSkeleton,
  },
};
</script>

<style lang="scss" scoped>
@use '../../../shared/scss/skeleton';
</style>
