<template>
  <div class="send-email-modal__recipients-section">
    <div class="send-email-modal__recipients-section__selection">
      <SkSelect
        v-model="localRecipients"
        :ajax-client="ajaxClient"
        :ajax-format-params="ajaxFormatParams"
        :ajax-format-response="ajaxFormatResponse"
        :disabled-options="recipientIdsWithoutEmail"
        :label="$t('employees.send_email.recipients.label')"
        ajax-url="/v3/api/users"
        :search-placeholder="$t('labels.search')"
        object-value
        paginated
        select-all
        multi
        append-to-body
      >
        <template #select-all-label>
          {{ $t('employees.send_email.recipients.select_all') }}
        </template>
        <template #option="{ option }">
          <div
            v-tooltip="recipientWhitoutEmailTooltip(option)"
            class="send-email-modal__recipients__item"
          >
            <div class="send-email-modal__recipients__item-text text-truncate">
              <div> {{ option.text }} </div>
              <div
                :style="handleLicenseNameColor(option)"
                class="send-email-modal__recipients__item-subtitle"
              >
                {{ option.attributes.currentLicenseName }}
              </div>
            </div>
          </div>
        </template>
        <template #empty-option>
          {{ $t('search_bar.no_result') }}
        </template>
        <template #selected-option="{ value }">
          <div class="selected-option">
            {{ resolveSelectedOption(value) }}
          </div>
        </template>
      </SkSelect>
      <SkCheckBox
        v-if="hasBcc"
        v-model="sendMailWithBcc"
        class="send-email-modal__recipients-section__selection__bcc"
        :label="$t('employees.send_email.recipients.bcc')"
      />
    </div>
    <div>
      <div
        v-if="localRecipients.length === 0"
        class="send-email-modal__recipients--empty"
      >
        <CircledExclamationMarkIcon fill="#727272" />
        <span class="send-email-modal__recipients--empty__text">
          {{ $t('employees.send_email.recipients.empty') }}
        </span>
      </div>
      <div
        v-else-if="!shouldShowCollapse"
        class="send-email-modal__recipients__label"
      >
        <div class="send-email-modal__recipients__truncate-label">
          <div
            v-for="recipient in localRecipients"
            :key="recipient.id"
            class="send-email-modal__recipients__item-tag"
          >
            <div class="send-email-modal__recipients__item-text">
              {{ recipient.attributes.email }}
            </div>
            <div
              class="send-email-modal__recipients__item-button"
              @click.stop="deleteRecipient(recipient)"
            >
              <ClosingXIcon
                width="10"
                height="10"
              />
            </div>
          </div>
        </div>
      </div>
      <SkCollapse
        v-else
        ref="recipientsSectionsCollapse"
        @open="handleCollapseOpen"
        @close="handleCollapseClose"
      >
        <template slot="labelLeft">
          <div class="send-email-modal__recipients__truncate-label">
            <div
              v-for="recipient in limitedRecipients"
              :key="recipient.id"
              class="send-email-modal__recipients__item-tag"
              @click.stop
            >
              <div class="send-email-modal__recipients__item-text">
                {{ recipient.attributes.email }}
              </div>
              <div
                class="send-email-modal__recipients__item-button"
                @click="deleteRecipient(recipient)"
              >
                <ClosingXIcon
                  width="10"
                  height="10"
                />
              </div>
            </div>
          </div>
        </template>
        <template slot="labelRight">
          <div
            v-if="showElipsis"
            class="send-email-modal__recipients__show-more-button"
            @click.stop="openCollapse"
          >
            {{ $tc('employees.send_email.recipients.show_more',hiddenRecipientsCount) }}
          </div>
        </template>
        <template slot="content">
          <div class="send-email-modal__recipients__label-content">
            <div
              v-for="recipient in allRecipients"
              :key="recipient.id"
              class="send-email-modal__recipients__item-tag"
            >
              <div class="send-email-modal__recipients__item-text">
                {{ recipient.attributes.email }}
              </div>
              <div
                class="send-email-modal__recipients__item-button"
                @click.stop="deleteRecipient(recipient)"
              >
                <ClosingXIcon
                  width="10"
                  height="10"
                />
              </div>
            </div>
          </div>
        </template>
      </SkCollapse>
    </div>
  </div>
</template>

<script>
import difference from 'lodash/difference';
import {
  mapState,
  mapGetters,
} from 'vuex';

import { isValidEmail } from '@skello-utils/validators';
import { LICENSE_COLORS } from '@app-js/shared/constants/colors';

import { httpClient } from '@skello-utils/clients';

const MAX_LINE_WIDTH = 55;

export default {
  name: 'RecipientsSection',
  props: {
    value: {
      type: Array,
      required: true,
    },
    hasBcc: {
      type: Boolean,
      default: false,
      required: false,
    },
    useBcc: {
      type: Boolean,
      required: true,
    },
    onlyReadUserLicenses: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultSelectedUserLicenses: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      ajaxClient: () => httpClient,
      localRecipients: this.value,
      showElipsis: true,
      localCollapseOpen: false,
      indexOfLastEmailLabel: null,
      recipientIdsWithoutEmail: [],
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapGetters('employees', ['fullName']),

    sendMailWithBcc: {
      get() {
        return this.useBcc;
      },
      set(newValue) {
        this.$emit('switch-bcc', newValue);
      },
    },
    shouldShowCollapse() {
      let stringLength = 0;

      this.localRecipients.forEach(recipient => {
        stringLength += recipient.attributes.email.length;
      });

      return stringLength > MAX_LINE_WIDTH;
    },
    limitedRecipients() {
      return this.localRecipients.slice(0, this.indexOfLastEmailLabel);
    },
    allRecipients() {
      return difference(this.localRecipients, this.limitedRecipients);
    },
    hiddenRecipientsCount() {
      if (this.indexOfLastEmailLabel === -1) {
        return this.localRecipients.length;
      }

      return this.localRecipients.length - this.indexOfLastEmailLabel;
    },
    hasDefaultSelectedUserLicenses() {
      return this.defaultSelectedUserLicenses.length > 0;
    },
  },
  watch: {
    localRecipients(newValue) {
      let stringLength = 0;
      let index = 0;

      newValue.forEach(recipient => {
        stringLength += recipient.attributes.email.length;

        index += 1;

        if (stringLength > MAX_LINE_WIDTH) {
          index -= 1;
        }
      });

      if (this.shouldShowCollapse && !this.localCollapseOpen) {
        this.showElipsis = true;
      }

      this.indexOfLastEmailLabel = index;

      this.$emit('input', newValue);
    },
    value(newValue) {
      this.localRecipients = newValue;
    },
  },
  created() {
    if (this.hasDefaultSelectedUserLicenses) {
      // To be able to select by default recipients before opening the select,
      // we need to fetch them.
      this.fetchRecipients();
    }
  },
  methods: {
    fetchRecipients() {
      if (!this.hasDefaultSelectedUserLicenses) return;

      this.ajaxClient()
        .get('/v3/api/users/planners', {
          params: {
            with_license: true,
            shop_id: this.currentShop.id,
          },
        })
        .then(response => {
          // The ajaxFormatResponse function emit the needed defaultSelectedUserLicenses
          this.ajaxFormatResponse(response.data);
        });
    },
    ajaxFormatParams(params) {
      return {
        cluster_node_id: this.navContext.clusterNodeId,
        ...params,
      };
    },
    ajaxFormatResponse(response) {
      let recipients = response.data.map(employee => {
        if (!isValidEmail(employee.attributes.email)) {
          this.recipientIdsWithoutEmail.push(employee.id);
        }

        return this.getRecipentFormat(employee);
      });

      const currentUserIndex = recipients.findIndex(
        recipient => recipient.id === this.currentUser.id,
      );

      if (currentUserIndex !== -1) {
        recipients.splice(currentUserIndex, 1);
      }

      if (this.onlyReadUserLicenses) {
        recipients = this.filterReadOnlyLicenses(recipients);
      }

      if (this.hasDefaultSelectedUserLicenses && this.localRecipients.length === 0) {
        // Select by default only recipient that correspond to defaultSelectedUserLicenses props
        // Ex: ['standard_planner', 'advanced_planner']
        this.$emit('input', this.filterByLicenseRecipients(recipients));
      }
      return recipients;
    },
    getRecipentFormat(recipient) {
      return {
        id: recipient.id,
        text: this.fullName(recipient),
        attributes: {
          email: recipient.attributes.email,
          firstName: recipient.attributes.firstName,
          lastName: recipient.attributes.lastName,
          currentLicenseName: recipient.attributes.currentLicenseName,
          currentLicenseType: recipient.attributes.currentLicenseType,
        },
      };
    },
    filterReadOnlyLicenses(recipients) {
      const readOnlyLicenses = ['standard_read_only', 'advanced_read_only'];

      return recipients.filter(
        recipient => !readOnlyLicenses.includes(recipient.attributes.currentLicenseType) &&
          recipient.attributes.currentLicenseType !== 'standard_employee' &&
          !this.config.can_read_self_only_licenses
            .includes(recipient.attributes.currentLicenseType),
      );
    },
    filterByLicenseRecipients(recipients) {
      if (!this.hasDefaultSelectedUserLicenses) {
        return recipients;
      }

      return recipients.filter(
        recipient => this.defaultSelectedUserLicenses
          .includes(recipient.attributes.currentLicenseType) &&
          isValidEmail(recipient.attributes.email),
      );
    },
    recipientWhitoutEmailTooltip(option) {
      return this.recipientIdsWithoutEmail.includes(option.id) ?
        this.$t('employees.send_email.recipients.no_email_tooltip') :
        null;
    },
    handleLicenseNameColor(employee) {
      return {
        color: LICENSE_COLORS[employee.attributes.currentLicenseType].color,
      };
    },
    openCollapse() {
      this.$refs.recipientsSectionsCollapse.handleLabelClick();
    },
    handleCollapseOpen() {
      this.localCollapseOpen = true;
      this.showElipsis = false;
    },
    handleCollapseClose() {
      this.localCollapseOpen = false;
      this.showElipsis = true;
    },
    deleteRecipient(recipient) {
      this.$emit('delete-recipient', recipient);
    },
    resolveSelectedOption(value) {
      return Array.isArray(value) ? value.map(({ text }) => text).join() : '';
    },
  },
};
</script>

<style lang="scss">
.send-email-modal__email-section {
  height: 350px;
}

.send-email-modal__recipients-section {
  display: flex;
  flex-direction: column;
  margin: 0 24px;

  .send-email-modal__recipients-section__selection {
    display: flex;
    margin-bottom: 16px;
  }

  .send-email-modal__recipients-section__selection__bcc {
    padding-left: 24px;
  }

  // Override DS
  .sk-collapse__label {
    padding: 0 !important;
  }

  .sk-select__dropdown-menu {
    min-width: 290px;
  }

  .sk-dropdown__menu {
    left: 143px !important;
  }

  .sk-collapse__content {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sk-select__label {
    font-size: $fs-text-m !important;
    font-weight: $fw-semi-bold;
    color: black;
    margin-bottom: 12px;
    position: relative;
    top: 5px;
  }

  .selected-option-name {
    display: none;
    overflow: visible;
  }

  .sk-select__selected-option.sk-select__selected-option--filled {
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }
}

.send-email-modal__recipients__label {
  display: flex;
  align-items: center;
}

.send-email-modal__recipients__truncate-label {
  display: flex;
  align-items: center;
}

.send-email-modal__recipients__show-more-button {
  font-size: $fs-text-s;
  padding-right: 8px;
}

.send-email-modal__recipients__item-text {
  display: flex;
  flex-direction: column;
  font-size: $fs-text-s;
  padding-right: 10px;
}

.send-email-modal__recipients__item-button {
  cursor: pointer;
}

.send-email-modal__recipients__item-subtitle {
  font-size: $fs-text-xs;
}

.send-email-modal__recipients--empty {
  display: flex;
  align-items: center;
  color: $sk-grey;
}

.send-email-modal__recipients--empty__text {
  padding-left: 8px;
}

.send-email-modal__recipients__label-content {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
}

.send-email-modal__recipients__item-tag {
  border: 1px solid $sk-grey-10;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: initial;
  margin-right: 10px;
}

.send-email-modal__recipients__item {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.selected-option {
  margin-top: 14px;
}
</style>
