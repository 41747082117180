<template>
  <div class="kpis-dates__container">
    <div class="kpis-dates__left-spacer" />
    <div
      v-for="(weekDay, index) in weekDays"
      :key="index"
      :class="dateCellClasses(index, weekDay)"
    >
      <span>{{ formatDayOfWeek(weekDay) }}</span>
      <span class="kpis-dates__cell-day">{{ weekDay.format('D') }}</span>
      <span>{{ formatMonth(weekDay) }}</span>
    </div>

    <div class="kpis-total-label">
      {{ this.$t('kpis.total.label') }}
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';

import skDate from '@skello-utils/dates';
import { mapState } from 'vuex';

export default {
  name: 'DatesRow',
  props: {
    date: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsState', ['shopPlanningConfig']),
    weekDays() {
      return this.shopPlanningConfig.attributes.visibleDays.reduce((memo, visibleDay, index) => {
        if (visibleDay) {
          memo.push(skDate(this.date).add(index, 'days'));
        }
        return memo;
      }, []);
    },
  },
  methods: {
    dateCellClasses(index, weekDay) {
      const isToday = weekDay.format('DD-MM-YYYY') === skDate().format('DD-MM-YYYY');
      return {
        'kpis-dates__cell': true,
        'kpis-dates__cell-today': isToday,
      };
    },
    formatDayOfWeek(date) {
      return upperFirst(date.format('ddd'));
    },
    formatMonth(date) {
      return date.format('MMM').toLowerCase().replace('.', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.kpis-dates__container {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: $sk-white;
}

.kpis-dates__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-s;
  flex-grow: 1;
  flex-basis: 0;
  flex-wrap: wrap;
  color: $sk-grey;

  .kpis-dates__cell-day {
    margin: 0 2px;
  }
}

.kpis-dates__cell-today {
  color: $sk-black;

  .kpis-dates__cell-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    background-color: $sk-blue;
    color: white;
    border-radius: 50%;
  }
}

.kpis-dates__left-spacer {
  height: 100%;
  width: 166px;
}

.kpis-total-label {
  font-size: $fs-text-s;
  color: $sk-grey;
  text-align: center;
  padding-right: 15px;
  width: 146px;
}
</style>
