<template>
  <!-- eslint-disable  max-len -->
  <div
    :class="cardClass"
    class="cluster_node-card"
    @click="handleCLick"
  >
    <div class="cluster_node-card-left-inner-container">
      <div
        v-if="draggable"
        class="cluster_node-drag_area"
      >
        <DragAreaSvg :fill="isCurrentCluster ? 'white' : '#ADB2B6'" />
      </div>
      <div
        :class="itemIconClass"
        class="cluster_node-card-icon"
      >
        <ShopIconSvg
          v-if="item.type === 'shop'"
          fill="#2b66fe"
        />
        <ClusterNodeIconSvg
          v-else
          :fill="iconFillingColor"
        />
      </div>
      <div class="cluster_node-info">
        <div
          :title="item.name"
          class="cluster_node-name"
        >
          {{ item.name }}
        </div>
        <div
          v-if="item.type === 'cluster_node'"
          class="cluster_node-stats"
        >
          {{
            $t('organisation_settings.tabs.clusters.card.label_nodes', {
              nb_node: item.nb_node,
              nb_shop: item.nb_shop
            })
          }}
        </div>
        <div
          v-else
          class="cluster_node-stats"
        >
          {{
            $tc('organisation_settings.tabs.clusters.card.label_employees', item.nb_employee)
          }}
        </div>
      </div>
    </div>
    <div
      v-if="canOpenTarget"
      class="cluster_node-caret"
    >
      <AngleRight :fill="isCurrentCluster ? 'white' : '#2d2f30'" />
    </div>
  </div>
</template>

<script>

// -- NB:
// duplicated component used in modal only -- same as "Card"
// --> see if it has to be split after drag&drop feature

import DragAreaSvg from '../../assets/svg/DragAreaSvg';
import ClusterNodeIconSvg from '../../assets/svg/ClusterNodeIconSvg';
import ShopIconSvg from '../../assets/svg/ShopIconSvg';
import AngleRight from '../../assets/svg/AngleRight';

export default {
  components: {
    DragAreaSvg, ClusterNodeIconSvg, ShopIconSvg, AngleRight,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    handleClickOnCard: {
      type: Function,
      required: true,
    },
    clickedItemId: {
      type: Number,
      default: null,
    },
    currentClusterId: {
      type: Number,
      default: null,
    },
    currentMovedCluster: {
      type: Object,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    onlyMove: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isClicked() {
      return (this.item.id === this.clickedItemId);
    },
    isCurrentCluster() {
      return (this.item.id === this.currentClusterId);
    },
    itemIconClass() {
      return {
        'shop-icon': this.item.type === 'shop',
        'cluster-icon': this.item.type === 'cluster_node',
        'card-clicked': this.isClicked,
        'current-card': this.isCurrentCluster,
      };
    },
    cardClass() {
      return {
        'background-card-clicked': this.isClicked,
        'background-card-for-shop': this.item.type === 'shop',
        'remove-hover': !this.canOpenTarget,
        'remove-cursor': !this.canOpenTarget,
        'background-current-card': this.isCurrentCluster,
        draggable: this.draggable,
        'not-draggable': !this.draggable,
      };
    },
    canOpenTarget() {
      if (this.onlyMove) return this.canOpenTargetOnMove;

      return this.canOpenTargetOnDelete;
    },
    canOpenTargetOnMove() {
      return this.item.type !== 'shop' && (this.currentMovedClusterIsShop || (this.currentMovedClusterIsCluster && !this.clusterContainsShop(this.item)));
    },
    canOpenTargetOnDelete() {
      return this.item.type !== 'shop' && (this.currentMovedCluster.nb_node === 0 || !this.clusterContainsShop(this.item));
    },
    clusterIsShopOrHasNoChildren() {
      return !this.clusterContainsShop(this.item) || this.currentMovedClusterIsShop;
    },
    currentMovedClusterIsShop() {
      return this.currentMovedCluster.type === 'shop';
    },
    currentMovedClusterIsCluster() {
      return this.currentMovedCluster.type === 'cluster_node';
    },
    iconFillingColor() {
      if (this.isCurrentCluster) {
        return '#0d2ed9';
      }
      if (this.isClicked) {
        return 'white';
      }

      return '#0d2ed9';
    },
    isNodeAndHasChildren() {
      return (this.item.nb_node + this.item.nb_shop) !== 0;
    },
    nodeHaveOnlyShopsChildren() {
      return this.item.nb_shop !== 0 && this.item.nb_node === 0;
    },
  },
  methods: {
    handleCLick() {
      if (this.canOpenTarget) this.handleClickOnCard(this.item);
    },
    clusterContainsShop(el) {
      return el.type === 'cluster_node' && el.nb_shop !== 0 && el.nb_node === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.cluster_node-caret {
  margin-right: 12px;
}

.cluster_node-info {
  margin-left: 9px;
  max-width: 70%;
  min-width: 70%;
}

.cluster_node-name {
  font-size: $fs-text-m;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.cluster_node-stats {
  font-size: $fs-text-s;
}

.cluster_node-card-icon {
  flex: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  margin-left: 6px;
}

.cluster-icon {
  background-color: #dbe3f9;

  &.card-clicked {
    background-color: #0d46d9;
  }

  &.current-card {
    background-color: #dbe3f9;
  }
}

.shop-icon {
  background-color: $sk-blue-10;
}

.remove-cursor {
  cursor: default !important;
}

.cluster_node-card {
  cursor: pointer;
  height: 70px;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s ease;
  width: 100%;
  margin-top: -1px;

  &.draggable {
    width: calc(100% - 30px);
    margin: 10px 15px;
    border-radius: 4px;
    border-right: 1px solid #e8eaed;
    border-left: 1px solid #e8eaed;

    &.background-card-clicked {
      border: 1px solid transparent;
    }

    &:hover {
      box-shadow: 0 2px 12px rgba(0, 0, 0, .15);
      border: 1px solid transparent;
    }
  }

  &.not-draggable {
    &:hover {
      background-color: $sk-grey-10;
    }
  }

  &.remove-hover:hover {
    background-color: unset;
  }
}

.cluster_node-card-left-inner-container {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
}

.cluster_node-drag_area {
  margin-left: 10px;
}

.background-card-clicked {
  color: #2d2f30;
  background-color: $sk-grey-10;

  &.background-current-card {
    color: white;
    background-color: #0d46d9;
  }

  &.background-card-for-shop {
    color: white;
    background-color: #00bedd;
  }
}
</style>
