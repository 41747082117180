<template>
  <SkModalSection class="zoom-range-modal__content">
    <div class="zoom-range-modal__content-title">
      {{ $t('plannings.toolbar.modal.display_options.zoom_range_selection.title') }}
    </div>
    <div class="zoom-range-modal__content__wrapper">
      <div class="zoom-range-modal__range-slider">
        <div class="zoom-range-modal__side-ranges">
          <div
            ref="leftSide"
            class="zoom-range-modal__side-ranges--left-side"
          />
          <div
            ref="rightSide"
            class="zoom-range-modal__side-ranges--right-side"
          />
          <input
            v-model="rangeValue"
            class="zoom-range-modal__range-slider__range"
            type="range"
            min="0"
            max="6"
            step="1"
            data-test="display-options__zoom-range-slider"
          >
        </div>
      </div>
      <div class="zoom-range-modal__slider-label">
        <div
          v-for="label in sliderLabels"
          :key="label.value"
          :style="rangeLabelStyle(label)"
        >
          <span> {{ displayPercentage(label) }} </span>
        </div>
      </div>
    </div>
    <br>
  </SkModalSection>
</template>

<script>

import {
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'ZoomRangeSelection',
  data() {
    return {
      middleValue: 3,
      // TODO remove backValues when planningZoom will be migrated after v2 ends
      sliderLabels: [
        { displayedPercentage: 25, value: 0, backValues: [10, 20], anchored: true },
        { displayedPercentage: 50, value: 1, backValues: [30], anchored: false },
        { displayedPercentage: 75, value: 2, backValues: [40], anchored: false },
        { displayedPercentage: 100, value: 3, backValues: [50], anchored: true },
        { displayedPercentage: 125, value: 4, backValues: [60], anchored: false },
        { displayedPercentage: 150, value: 5, backValues: [70], anchored: false },
        { displayedPercentage: 175, value: 6, backValues: [80, 90, 100], anchored: true },
      ],
    };
  },
  computed: {
    ...mapGetters('currentUser', ['planningZoom']),
    rangeValue: {
      get() {
        return this.sliderLabels.find(
          sliderValue => sliderValue.displayedPercentage === this.planningZoom,
        ).value;
      },
      set(newValue) {
        const planningZoomValue = this.sliderLabels.find(
          sliderValue => sliderValue.value === parseInt(newValue, 10),
        );
        // TODO change backValues to displayedPercentage value after v2 ends
        this.setCurrentUserAttributes({ planningZoom: planningZoomValue.backValues[0] });
      },
    },
  },
  watch: {
    rangeValue() {
      this.getBackground();
    },
  },
  mounted() {
    this.getBackground();
  },
  methods: {
    ...mapMutations('currentUser', ['setCurrentUserAttributes']),
    displayPercentage(sliderValue) {
      return `${sliderValue.displayedPercentage.toString()}%`;
    },
    getBackground() {
      const { leftSide, rightSide } = this.$refs;

      // computing this.rangeValue as percentage based on middleValue.
      // we want to determine either inputValue is lower
      // than the middleValue to recalculate the linear background of the left Side
      // or the other way arround for the right side of the range slider.
      if (this.rangeValue >= this.middleValue) {
        const value = ((this.rangeValue - this.middleValue) / this.middleValue) * 100;
        rightSide.style.background =
          `linear-gradient(to right, #2b66fe 0%, #2b66fe, ${value}%, #fff, ${value}%, #dddddd 100%)`;
        leftSide.style.background = '#dddddd';
      } else {
        const value = ((this.middleValue - this.rangeValue) / this.middleValue) * 100;
        leftSide.style.background =
          `linear-gradient(to left, #2b66fe 0%, #2b66fe, ${value}%, #fff, ${value}%, #dddddd 100%)`;
        rightSide.style.background = '#dddddd';
      }
    },
    rangeLabelStyle(label) {
      const style = {};
      if (!label.anchored && label.value !== parseInt(this.rangeValue, 10)) {
        style.visibility = 'hidden';
      }
      if (!label.anchored && label.value === parseInt(this.rangeValue, 10)) {
        style.visibility = '';
        style.color = '#2b66fe';
      }
      if (label.anchored && label.value === parseInt(this.rangeValue, 10)) {
        style.color = '#2b66fe';
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-range-modal__slider-label {
  display: flex;
  justify-content: space-between;
  width: 575px;
  font-size: $fs-text-s;
  margin-left: -6px;
  color: $sk-grey;
}

.zoom-range-modal__side-ranges {
  position: relative;
  display: flex;
  height: 16px;
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;

  &--left-side {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &--right-side {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &--left-side,
  &--right-side {
    background: $sk-grey-10;
    width: 100%;
    content: "";
    height: 4px;
    margin-top: 6px;
  }
}

.zoom-range-modal__range-slider {
  margin: 20px 0 0;
  width: 100%;
}

.zoom-range-modal__range-slider__range {
  position: absolute;
  -webkit-appearance: none;
  width: 100%;
  background: transparent;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $sk-blue;
    cursor: pointer;
    transition: background .15s ease-in-out;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border: 0;
    border-radius: 50%;
    background: $sk-blue;
    cursor: pointer;
    transition: background .15s ease-in-out;
  }
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

.zoom-range-modal__content-title {
  width: 200px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  margin-top: 10px;
}

.zoom-range-modal__content__medallion {
  width: 40px !important;
  height: 40px !important;
  margin: 0 10px 0 0;
}
</style>
