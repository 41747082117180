<template>
  <div class="request-esignature-upsell-modal__recipients-section">
    <div class="request-esignature-upsell-modal__recipients-section-select">
      <SkSelect
        v-model="selectedRecipientList"
        :options="recipientOptions"
        :disabled-options="recipientIdsWithoutEmail"
        :label="$t('employees.send_email.recipients.label')"
        :search-placeholder="$t('labels.search')"
        object-value
        paginated
        select-all
        multi
      >
        <template #select-all-label>
          {{ $t('employees.send_email.recipients.select_all') }}
        </template>
        <template #option="{ option }">
          <div
            v-tooltip="recipientWhitoutEmailTooltip(option)"
            class="request-esignature-upsell-modal__recipients__item"
          >
            <div class="request-esignature-upsell-modal__recipients__item-text text-truncate">
              <div> {{ option.text }} </div>
              <div
                :style="handleLicenseNameColor(option)"
                class="request-esignature-upsell-modal__recipients__item-subtitle"
              >
                {{ option.attributes.currentLicenseName }}
              </div>
            </div>
          </div>
        </template>
        <template #empty-option>
          {{ $t('search_bar.no_result') }}
        </template>
      </SkSelect>
    </div>
    <div
      v-if="selectedRecipientList.length === 0"
      class="request-esignature-upsell-modal__recipients--empty"
    >
      <CircledExclamationMarkIcon fill="#727272" />
      <span class="request-esignature-upsell-modal__recipients--empty__text">
        {{ $t('employees.send_email.recipients.empty') }}
      </span>
    </div>
    <div
      v-else-if="!shouldShowCollapse"
      class="request-esignature-upsell-modal__recipients__label"
    >
      <div class="request-esignature-upsell-modal__recipients__truncate-label">
        <div
          v-for="recipient in selectedRecipientList"
          :key="recipient.id"
          class="request-esignature-upsell-modal__recipients__item-tag"
        >
          <div class="request-esignature-upsell-modal__recipients__item-text">
            {{ recipient.attributes.email }}
          </div>
          <div
            class="request-esignature-upsell-modal__recipients__item-button"
            @click.stop="deleteRecipient(recipient)"
          >
            <ClosingXIcon
              width="10"
              height="10"
            />
          </div>
        </div>
      </div>
    </div>
    <SkCollapse
      v-else
      ref="recipientsSectionsCollapse"
      @open="toggleCollapse"
      @close="toggleCollapse"
    >
      <template slot="labelLeft">
        <div class="request-esignature-upsell-modal__recipients__truncate-label">
          <div
            v-for="recipient in limitedRecipients"
            :key="recipient.id"
            class="request-esignature-upsell-modal__recipients__item-tag"
            @click.stop
          >
            <div class="request-esignature-upsell-modal__recipients__item-text">
              {{ recipient.attributes.email }}
            </div>
            <div
              class="request-esignature-upsell-modal__recipients__item-button"
              @click="deleteRecipient(recipient)"
            >
              <ClosingXIcon
                width="10"
                height="10"
              />
            </div>
          </div>
        </div>
      </template>
      <template slot="labelRight">
        <div
          v-if="showElipsis"
          class="request-esignature-upsell-modal__recipients__show-more-button"
          @click.stop="openCollapse"
        >
          {{ $tc('employees.send_email.recipients.show_more',hiddenRecipientsCount) }}
        </div>
      </template>
      <template slot="content">
        <div class="request-esignature-upsell-modal__recipients__label-content">
          <div
            v-for="recipient in hiddenRecipients"
            :key="recipient.id"
            class="request-esignature-upsell-modal__recipients__item-tag"
          >
            <div class="request-esignature-upsell-modal__recipients__item-text">
              {{ recipient.attributes.email }}
            </div>
            <div
              class="request-esignature-upsell-modal__recipients__item-button"
              @click.stop="deleteRecipient(recipient)"
            >
              <ClosingXIcon
                width="10"
                height="10"
              />
            </div>
          </div>
        </div>
      </template>
    </SkCollapse>
  </div>
</template>

<script>
import difference from 'lodash/difference';
import { mapGetters } from 'vuex';
import { isValidEmail } from '@skello-utils/validators';
import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import { truncateString } from '@skello-utils/formatting/strings';

export default {
  name: 'RecipientsSection',
  props: {
    value: {
      type: Array,
      required: true,
    },
    recipients: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showElipsis: true,
      isLocalCollapseOpen: false,
      indexOfLastEmailLabel: null,
      recipientIdsWithoutEmail: [],
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName']),

    selectedRecipientList: {
      get() {
        return this.value;
      },
      set(newValue) {
        let stringLength = 0;
        let index = 0;

        newValue.forEach(recipient => {
          stringLength += recipient.attributes.email.length;
          index += 1;

          if (stringLength > 45) {
            index -= 1;
          }
        });
        if (this.shouldShowCollapse && !this.isLocalCollapseOpen) {
          this.showElipsis = true;
        }
        this.indexOfLastEmailLabel = index;
        this.$emit('input', newValue);
      },
    },
    hiddenRecipients() {
      return difference(this.selectedRecipientList, this.limitedRecipients);
    },
    recipientOptions() {
      return this.recipients.map(recipient => ({
        id: recipient.id,
        text: truncateString(this.fullName(recipient), 30),
        attributes: {
          email: recipient.attributes.email,
          firstName: recipient.attributes.firstName,
          lastName: recipient.attributes.lastName,
          currentLicenseName: recipient.attributes.currentLicenseName,
          currentLicenseType: recipient.attributes.currentLicenseType,
        },
      }));
    },
    shouldShowCollapse() {
      let stringLength = 0;

      this.selectedRecipientList.forEach(recipient => {
        stringLength += recipient.attributes.email.length;
      });

      return stringLength > 45;
    },
    limitedRecipients() {
      return this.selectedRecipientList.slice(0, this.indexOfLastEmailLabel);
    },
    hiddenRecipientsCount() {
      if (this.indexOfLastEmailLabel === -1) {
        return this.selectedRecipientList.length;
      }

      return this.selectedRecipientList.length - this.indexOfLastEmailLabel;
    },
  },
  methods: {
    selectAllByDefault() {
      let stringLength = 0;
      let index = 0;

      this.recipients.forEach(recipient => {
        if (!recipient.attributes.email || !isValidEmail(recipient.attributes.email)) {
          this.recipientIdsWithoutEmail.push(recipient.id);
        } else {
          this.selectedRecipientList.push({
            id: recipient.id,
            text: this.fullName(recipient),
            attributes: {
              email: recipient.attributes.email,
              firstName: recipient.attributes.firstName,
              lastName: recipient.attributes.lastName,
              currentLicenseName: recipient.attributes.currentLicenseName,
              currentLicenseType: recipient.attributes.currentLicenseType,
            },
          });
          stringLength += recipient.attributes.email.length;
          index += 1;

          if (stringLength > 45) {
            index -= 1;
          }
        }
      });
      if (this.shouldShowCollapse && !this.isLocalCollapseOpen) {
        this.showElipsis = true;
      }
      this.indexOfLastEmailLabel = index;
    },
    recipientWhitoutEmailTooltip(option) {
      return this.recipientIdsWithoutEmail.includes(option.id) ?
        this.$t('employees.send_email.recipients.no_email_tooltip') :
        null;
    },
    handleLicenseNameColor(employee) {
      return {
        color: LICENSE_COLORS[employee.attributes.currentLicenseType].color,
      };
    },
    openCollapse() {
      this.$refs.recipientsSectionsCollapse.handleLabelClick();
    },
    toggleCollapse() {
      this.isLocalCollapseOpen = !this.isLocalCollapseOpen;
      this.showElipsis = !this.showElipsis;
    },
    deleteRecipient(recipient) {
      const recipientIndex = this.selectedRecipientList.indexOf(recipient);
      this.selectedRecipientList.splice(recipientIndex, 1);
    },
  },
};
</script>

<style lang="scss">
.request-esignature-upsell-modal__recipients-section {
  display: flex;
  flex-direction: row;
  margin: 0 24px;

  // Override DS
  .sk-collapse__label {
    height: 44px;
  }

  .sk-select__dropdown-menu {
    min-width: 290px;
  }

  .sk-dropdown__menu {
    left: 143px !important;
  }

  .sk-collapse__content {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sk-select__label {
    font-size: $fs-text-m !important;
    font-weight: $fw-semi-bold;
    color: black;
    margin-bottom: 12px;
    position: relative;
    top: 5px;
  }

  .selected-option-name {
    display: none;
    overflow: visible;
  }

  .sk-select__selected-option.sk-select__selected-option--filled {
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }
}

.request-esignature-upsell-modal__recipients__label {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.request-esignature-upsell-modal__recipients__truncate-label {
  display: flex;
  align-items: center;
}

.request-esignature-upsell-modal__recipients__show-more-button {
  font-size: $fs-text-s;
  padding-right: 20px;
}

.request-esignature-upsell-modal__recipients__item-text {
  display: flex;
  flex-direction: column;
  font-size: $fs-text-s;
  padding-right: 10px;
}

.request-esignature-upsell-modal__recipients__item-button {
  cursor: pointer;
}

.request-esignature-upsell-modal__recipients__item-subtitle {
  font-size: $fs-text-xs;
}

.request-esignature-upsell-modal__recipients--empty {
  display: flex;
  align-items: center;
  color: $sk-grey;
  margin-left: 16px;
}

.request-esignature-upsell-modal__recipients--empty__text {
  margin-left: 16px;
}

.request-esignature-upsell-modal__recipients__label-content {
  display: flex;
  flex-flow: row wrap;
}

.request-esignature-upsell-modal__recipients__item-tag {
  border: 1px solid $sk-grey-10;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: initial;
  margin-right: 10px;
  margin-top: 5px;
}

.request-esignature-upsell-modal__recipients__item {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
</style>
