import Reports from './Reports';

// Note: shop_id can be either a shop id or 'all'
const reportsRoutes = [
  {
    path: '/v3/shops/:shop_id/reports',
    component: Reports,
    name: 'reports',
  },
];

export default reportsRoutes;
