<template>
  <div class="comment_container">
    <div class="comment_container__medallion">
      <AvatarMedallion
        :avatar-url="getAvatarUrlForUser(user.id)"
        :medallion-text="userInitials(user)"
        size="small"
      />
    </div>
    <div class="comment_content_container">
      <div class="comment_header__container">
        <span class="comment_header__username">
          {{ fullName(user) }}
        </span>
        <span class="comment_header__timestamp">
          {{ formattedDate }}
        </span>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="isTextToLinkEnabled"
        v-html="formattedComment"
      />
      <!-- eslint-enable vue/no-v-html -->
      <span v-else>
        {{ employeeComment.content }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';
import { linkify } from '@skello-utils/formatting/strings';

export default {
  name: 'Comment',
  components: { AvatarMedallion },
  props: {
    employeeComment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      missingUser: {
        id: null,
        attributes: {
          firstName: this.$t(
            'plannings.table.manage_shift_modal.tabs.shift.comments.user_missing.first_name',
          ),
          lastName: this.$t(
            'plannings.table.manage_shift_modal.tabs.shift.comments.user_missing.last_name',
          ),
        },
      },
    };
  },
  computed: {
    ...mapState('planningsUsers', ['users']),
    ...mapGetters('employees', [
      'userInitials',
      'getAvatarUrlForUser',
      'fullName',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    formattedDate() {
      return skDate(`${this.employeeComment.date} ${this.employeeComment.time}`)
        .utc(true)
        .format('DD/MM/YYYY HH:mm');
    },
    user() {
      const userWhoCommented = this.users.find(
        user => user.id === String(this.employeeComment.userId),
      );

      return userWhoCommented || this.missingUser;
    },
    formattedComment() {
      return linkify(this.employeeComment.content);
    },
    isTextToLinkEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_SHIFT_TEXT_TO_LINK');
    },
  },
};
</script>

<style lang="scss" scoped>
.comment_container {
  display: flex;
  flex: 1;
}

.comment_container__medallion {
  margin-top: 4px;
}

.comment_content_container {
  width: 100%;
  padding: 4px 0 4px 8px;
  line-break: anywhere;
}

.comment_header__container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.comment_header__username {
  font-weight: $fw-semi-bold;
}

.comment_header__timestamp {
  display: flex;
  align-items: center;
  font-size: $fs-text-s;
  color: $sk-grey-50;
}
</style>
