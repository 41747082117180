<template>
  <transition name="slide-bar">
    <div class="enhanced-sticky-bar">
      <div class="enhanced-sticky-bar__actions-container">
        <div class="enhanced-sticky-bar__actions-container--main">
          <SkOroraButton
            v-track="trackingOptions.cancel"
            :disabled="isDisabled || isSubmitting"
            data-test="enhanced-sticky-bar__submit-button"
            variant="secondary"
            @click="$emit('cancel', $event)"
          >
            {{ cancelLabel }}
          </SkOroraButton>
          <SkOroraButton
            v-track="trackingOptions.submit"
            :disabled="isDisabled"
            :loading="isSubmitting"
            data-test="enhanced-sticky-bar__submit-button"
            @click="$emit('submit', $event)"
          >
            {{ submitLabel }}
          </SkOroraButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'EnhancedStickyBar',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
    submitLabel: {
      type: String,
      required: true,
    },
    trackingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.enhanced-sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $sk-white;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .12);
  z-index: 3;
  transition: all ease-in .2s;
  overflow: hidden;
}

.enhanced-sticky-bar__actions-container {
  display: flex;
  justify-content: center;
  padding: 12px 24px;
}

.slide-bar-enter-active,
.slide-bar-leave-active {
  transition: all .3s ease;
}

.slide-bar-enter,
.slide-bar-leave-to {
  transform: translateY(64px);
}
</style>
