<template>
  <div>
    <h1 class="sk-header--1">
      {{ $t('organisation_settings.tabs.partners_tools.title') }}
    </h1>
    <SkNav>
      <SkNavItem
        v-for="item in navItems"
        :key="item.route"
        :active="isActive(item)"
        to="#"
        @click.native="setAsSelectedTab(item)"
      >
        {{ item.name }}
      </SkNavItem>
    </SkNav>
    <router-view />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'PartnersTools',
  beforeRouteLeave(_to, _from, next) {
    // If we have unsaved changes in the store,
    // we prompt for confirmation on route leave
    if (this.unsavedChangesOnPartnerTools) {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          next();
        },
        submitColor: this.$skColors.skBlue50,
        title: this.$t('actions.continue'),
      });
    } else {
      next();
    }
  },
  data() {
    return {
      selectedNavItem: null,
      isDvoreActivated: false,
    };
  },
  computed: {
    ...mapState('partnersTools', ['unsavedChangesOnPartnerTools']),
    ...mapState('currentOrganisation', ['currentOrganisation']),

    isInHealthSector() {
      return this.currentOrganisation.attributes.sector === 'health';
    },
    navItems() {
      const navItemsArray =
        [
          {
            name: this.$t('organisation_settings.tabs.partners_tools.pos_partners'),
            route: 'organisation_settings_partners_tools',
          },
          {
            name: this.$t('organisation_settings.tabs.partners_tools.pay_partners'),
            route: 'organisation_settings_pay_partners',
          },
          {
            name: this.$t('organisation_settings.tabs.partners_tools.bi_partners'),
            route: 'organisation_settings_bi_partners',
          },
        ];

      navItemsArray.push({
        name: this.$t('organisation_settings.tabs.partners_tools.advance_payment_partners'),
        route: 'organisation_settings_advance_payment_partners',
      });

      if (this.isInHealthSector) {
        // Remove link to pos partners_tools if in health, which does not use pos integration
        navItemsArray.shift();
      }

      return navItemsArray;
    },
  },
  created() {
    const route = this.isInHealthSector ?
      'organisation_settings_pay_partners' : this.$router.currentRoute.name;
    if (this.isInHealthSector) {
      this.$router.push({ name: route });
    }
    this.selectedNavItem = route;
  },
  beforeUpdate() {
    this.$router.push({ name: this.selectedNavItem });
  },
  methods: {
    isActive(item) {
      return this.selectedNavItem === item.route;
    },
    setAsSelectedTab(item) {
      this.$router.push({ name: item.route });
      this.selectedNavItem = item.route;
    },
  },
};
</script>
