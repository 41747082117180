<template>
  <img
    width="35"
    src="./images/logo-quadratus.jpg"
  >
</template>

<script>
export default {
  name: 'Quadratus',
};
</script>
