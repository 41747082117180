<template>
  <HorizontalScrollTable
    ref="paidLeavesCounterTable"
    :custom-classes="tableClasses"
    :custom-scrolling-classes="tableScrollingClasses"
  >
    <template #headers-column>
      <YearlyDistinctionTitlesColumn
        v-if="hasYearlyDistinction"
        :init-date="ptoTrackerInitDate"
      />
      <PaidLeavesCounterTitlesColumn
        v-else
        :init-date="ptoTrackerInitDate"
      />

      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <InitPaidLeavesCounterModal
          v-if="hasPaidLeavesCounter"
          :init-date="ptoTrackerInitDate"
          :update-counter="true"
        />
      </MountingPortal>
    </template>
    <PaidLeavesCounterYearColumn
      v-for="year in yearsToDisplay"
      :key="year.year"
      :year="year"
      :has-yearly-distinction="hasYearlyDistinction"
      @click="handleClickOnYearColumn(year)"
    >
      <PaidLeavesCounterMonthColumn
        v-for="(month, index) in year.months"
        :ref="isCurrentMonthColumn(month)"
        :key="month.month"
        :month="month"
        :previous-month="index === 0 ? null : year.months[index - 1]"
        :has-yearly-distinction="hasYearlyDistinction"
        @click="handleClickOnMonthColumn(month)"
      />
    </PaidLeavesCounterYearColumn>
  </HorizontalScrollTable>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import 'simplebar/dist/simplebar.min.css';

import skDate from '@skello-utils/dates';
import { roundFloat } from '@skello-utils/formatting/numbers';

import YearlyDistinctionTitlesColumn from './YearlyDistinctionTitlesColumn';
import PaidLeavesCounterTitlesColumn from './PaidLeavesCounterTitlesColumn';
import PaidLeavesCounterMonthColumn from './PaidLeavesCounterMonthColumn';
import PaidLeavesCounterYearColumn from './PaidLeavesCounterYearColumn';

import InitPaidLeavesCounterModal from '../InitPaidLeavesCounterModal.vue';

import { HorizontalScrollTable } from '../../shared/components';

export default {
  name: 'PaidLeavesCounterTable',
  components: {
    HorizontalScrollTable,
    PaidLeavesCounterTitlesColumn,
    YearlyDistinctionTitlesColumn,
    PaidLeavesCounterYearColumn,
    PaidLeavesCounterMonthColumn,
    InitPaidLeavesCounterModal,
  },
  props: {
    hasYearlyDistinction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableWidth: null,
      horizontallyScrolled: null,
    };
  },
  computed: {
    ...mapGetters('selectedEmployee', ['hasPaidLeavesCounter']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('employeePaidLeavesCounter', ['plcLoading', 'years']),
    cellHeight() {
      return this.hasYearlyDistinction ? '50px' : '60px';
    },
    tableClasses() {
      if (this.hasYearlyDistinction) return null;

      return ['employee-paid-leaves-counter__table', 'employee-paid-leaves-counter__table-scroll'];
    },
    ptoTrackerInitDate() {
      return this.employee.attributes.plcInitializationDoneAt ?
        skDate(this.employee.attributes.plcInitializationDoneAt) : null;
    },
    tableScrollingClasses() {
      if (this.hasYearlyDistinction) return null;

      return ['employee-paid-leaves-counter__table-scroll--scrolling'];
    },
    columnHeaderClasses() {
      if (this.hasYearlyDistinction) return null;

      return ['employee-counters__table-year-column-title'];
    },
    // Template data for uninitialized counter
    templateYears() {
      const templateMonths = [];
      const initMoment = skDate().startOf('year');

      for (let i = 0; i < 10; i++) {
        templateMonths.push(
          { month: initMoment.add(1, 'month').locale(this._i18n._vm.locale).format('MMM'), show_dash: true },
        );
      }

      return [{
        year: skDate().format('YYYY'),
        months: templateMonths,
        closed: false,
      }];
    },
    yearsToDisplay() {
      return this.hasPaidLeavesCounter ? this.years : this.templateYears;
    },
  },
  watch: {
    plcLoading(isLoading) {
      if (!isLoading) {
        this.$nextTick(() => {
          this.scrollToMonth();
        });
      }
    },
  },
  mounted() {
    if (this.hasPaidLeavesCounter) {
      this.fetchEmployeePaidLeavesCounter({
        user_id: this.employee.id,
        silentLoading: false,
      });
    }
  },
  methods: {
    ...mapActions('employeePaidLeavesCounter', [
      'fetchEmployeePaidLeavesCounter',
      'fetchEmployeePeriodSummaries',
    ]),
    handleClickOnYearColumn(column) {
      if (!column.closed) return;

      this.fetchEmployeePeriodSummaries({ user_id: this.employee.id, date: column.link });
      this.fetchEmployeePaidLeavesCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      })
        .then(() => {
          this.scrollToMonth();
        });
    },
    handleClickOnMonthColumn(column) {
      if (!column.closed) return;

      this.fetchEmployeePeriodSummaries({ user_id: this.employee.id, date: column.link });
      this.fetchEmployeePaidLeavesCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      });
    },
    isCurrentMonthColumn(month) {
      return month.current_month ? 'currentMonthColumn' : 'notCurrentMonthColumn';
    },
    roundToTwoDecimals(float) {
      return roundFloat(float, 2);
    },
    scrollToMonth() {
      // scroll to current month when displayed otherwise scroll to first month of diplayed year
      const targetMonth = this.$refs.currentMonthColumn && this.$refs.currentMonthColumn[0] ?
        this.$refs.currentMonthColumn[0].$el :
        this.$refs.notCurrentMonthColumn[0].$el;

      this.$refs.paidLeavesCounterTable.scrollTo(targetMonth);
    },
  },
};
</script>
