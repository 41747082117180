<template>
  <div :class="sectionClasses">
    <h3 class="sk-header--3">
      {{ title }}
    </h3>
    <div class="absence-section__header">
      <span class="absence-section__header__active">
        {{ $t('shop_settings.tabs.absences.table.active') }}
      </span>
      <span class="absence-section__header__type">
        {{ $t('shop_settings.tabs.absences.table.absence_type') }}
      </span>
      <span
        v-if="showPtoReduction"
        class="absence-section__header__pto"
      >
        <span class="absence-section__header__pto__explanation">
          {{ $t('shop_settings.tabs.absences.table.pto_enablement') }}
        </span>
      </span>
      <span
        v-if="isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')
          && currentShop.attributes.hybridModulation"
        class="absence-section__header__pto"
      >
        <span class="absence-section__header__pto__explanation">
          {{ $t('shop_settings.tabs.absences.table.indemnified_absences_employer') }}
        </span>
      </span>
      <span
        v-if="isMealCompensationDone"
        class="absence-section__header__compensation"
      >
        {{ $t('shop_settings.tabs.absences.table.meal_trigger') }}
      </span>
    </div>
    <draggable
      :value="absences"
      :options="dragOptions"
      :class="dropZoneClass"
      @change="handleDrag($event)"
      @start="$emit('drag-start', newAbsenceType(absenceType))"
      @end="$emit('drag-end')"
    >
      <transition-group
        :id="listId"
        type="transition"
        name="flip-list"
      >
        <div
          v-for="absence in absences"
          :key="absence.id"
          class="absence-section__content"
        >
          <AbsenceRow
            :absence="absence"
            :show-drag-svg="isAbsenceDraggable(absence)"
            :show-pto-reduction="showPtoReduction"
            :class="absenceDraggableClasses(absence)"
          />
        </div>
      </transition-group>
    </draggable>
    <div
      v-if="offCounterAbsenceIsEmpty && isAbsencesOffCounter"
      :class="emptySectionClasses"
    >
      {{ $t('shop_settings.tabs.absences.empty_section') }}
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import draggable from 'vuedraggable';
import AbsenceRow from '../AbsenceRow';

export default {
  name: 'ModulationAbsenceSection',
  display: 'Transition',
  components: { draggable, AbsenceRow },
  props: {
    firstSection: {
      type: Boolean,
      default: false,
    },
    secondSection: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    lastSection: {
      type: Boolean,
      default: false,
    },
    absences: {
      type: Array,
      required: true,
    },
    absenceType: {
      type: String,
      required: true,
    },
    showDropZone: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState('absences', ['modulationShopAbsences']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    ...mapGetters('currentShop', ['isMealCompensationDone', 'checkFeatureFlag', 'isDevFlagEnabled']),
    dragOptions() {
      return {
        group: 'description',
        filter: '.absence-section__content--frozen',

        // animation duration (flip)
        animation: 50,

        ghostClass: 'ghost',
        dragClass: 'sortable-drag',

        // distance in pixel to trigger drag destination zone
        emptyInsertThreshold: 60,

        disabled: !this.canEditShopRulesAndAbsences || !this.absenceType === 'neutralAbsences',
      };
    },
    sectionClasses() {
      return {
        'section-wrapper': true,
        'section-wrapper__border__first-section': this.firstSection,
        'section-wrapper__border__second-section': this.secondSection,
        'section-wrapper__border__last-section': this.lastSection,
      };
    },
    listId() {
      if (this.absenceType === 'absencesOffCounter') {
        return 'list-off-counter';
      }
      if (this.absenceType === 'absencesInCounter') {
        return 'list-in-counter';
      }
      return 'list-neutral';
    },
    dropZoneClass() {
      return {
        'drop-zone': this.showDropZone === this.absenceType,
        'drop-zone--empty': this.offCounterAbsenceIsEmpty,
      };
    },
    emptySectionClasses() {
      return {
        'empty-section': !this.showDropZone,
        'empty-section--dragging': this.showDropZone,
      };
    },
    offCounterAbsenceIsEmpty() {
      return this.modulationShopAbsences.offCounterAbsences.length === 0;
    },
    isAbsencesOffCounter() {
      return this.absenceType === 'absencesOffCounter';
    },
    showPtoReduction() {
      return this.checkFeatureFlag('FEATURE_SHOW_PTO_REDUCTION_ABSENCES');
    },
  },

  methods: {
    ...mapMutations('absences', [
      'updateDraggableList',
      'addDraggableAbsence',
      'removeDraggableAbsence',
    ]),

    absenceDraggableClasses(absence) {
      return {
        'absence-row': true,
        'absence-section__content--frozen': !this.isAbsenceDraggable(absence),
      };
    },
    isAbsenceDraggable(absence) {
      return absence.attributes.draggable && this.canEditShopRulesAndAbsences;
    },
    newAbsenceType(oldAbsenceType) {
      return oldAbsenceType === 'absencesOffCounter' ? 'absencesInCounter' : 'absencesOffCounter';
    },
    handleDrag(event) {
      const data = { data: event, targetList: this.absenceType };
      if ('added' in event) {
        this.addDraggableAbsence(data);
      }
      if ('removed' in event) {
        this.removeDraggableAbsence(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#list-in-counter,
#list-off-counter {
  display: block;
}

.section-wrapper {
  border: 1px solid $sk-grey-10;
  padding-bottom: 10px;

  &.section-wrapper__border__first-section {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  &.section-wrapper__border__second-section {
    border-radius: 0;
    border-bottom: none;
  }

  &.section-wrapper__border__last-section {
    border-radius: 0 0 4px 4px;
  }
}

.last-section-border {
  border-bottom: 1px solid $sk-grey-10;
}

h3.sk-header--3 {
  padding: 16px 16px 8px;
}

.drop-zone {
  border: 2px dashed $sk-blue;
  border-radius: 2px;
  background: #f4f7fe;
  min-height: 55px;

  &.drop-zone--empty {
    min-height: 55px;
  }

  .absence-row__wrapper {
    background: #dee6f5 !important;
  }
}

.empty-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  font-size: 13px;
  color: #b0afaf;
}

.empty-section--dragging {
  display: none;
}

.flip-list-move {
  transition: transform .5s;
}

.ghost {
  opacity: 1;
  background: #ccd4de;
  margin: 5px 10px;
  border-radius: 2px;

  .absence-row {
    opacity: 0;
    min-height: 40px;
  }
}

.sortable-drag {
  border-radius: 2px;

  .absence-row {
    background: white;
    transform-origin: right top;
    transform: translateX(5px);
  }
}

.list-group {
  min-height: 40px;
}

.absence-section__header {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: $fs-text-s;
  color: $sk-grey;
  padding: 0 26px 2px;

  &__active {
    width: 106px;
  }

  &__type {
    width: 286px;
  }

  &__pto {
    display: flex;
    align-items: flex-end;
    width: 157px;

    &__explanation {
      width: 125px;
    }

    &__tooltip {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  &__compensation {
    width: 118px;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.absence-section__content {
  margin: 0 16px;
}
</style>
