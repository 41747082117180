<template>
  <SkOroraDialog
    id="create-folder-modal"
    ref="createFolderDialog"
    size="small"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="loadingCreateFolder"
    :title="$t('shop_settings.documents_management.create_folder.title')"
    :submit-button-label="$t('shop_settings.documents_management.create_folder.submit')"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit="handleSubmit"
  >
    <template #body>
      <div class="create-folder-modal">
        <SkOroraInput
          id="folder-name-input"
          v-model.trim="folderName"
          :label="$t('shop_settings.documents_management.create_folder.name_label')"
          :placeholder="$t('shop_settings.documents_management.create_folder.name_placeholder')"
          :errored="hasError"
          :hint-message="errorMessage"
          @input="validateName"
          @keyup.enter="handleSubmit"
        />
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CreateFolderModal',
  props: {
    existingFolderNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      folderName: '',
      hasError: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('foldersManagement', ['loadingCreateFolder']),
    folderNameExists() {
      return this.existingFolderNames
        .map(n => n.toLowerCase().trim())
        .includes(this.folderName.toLowerCase().trim());
    },
    isSubmitDisabled() {
      return this.loadingCreateFolder || !this.folderName.trim() ||
      this.folderNameExists || this.hasError;
    },
  },
  methods: {
    show() {
      this.$refs.createFolderDialog.show();
    },
    handleSubmit() {
      if (this.isSubmitDisabled) return;
      this.hasError = false;
      this.errorMessage = '';
      this.$emit('create-folder', this.folderName.trim());
      this.resetData();
      this.$refs.createFolderDialog.hide();
    },
    handleCancel() {
      this.resetData();
      this.$refs.createFolderDialog.hide();
    },
    validateName() {
      this.hasError = this.folderNameExists;
      this.errorMessage = this.hasError ?
        this.$t('shop_settings.documents_management.create_folder.already_exists_error') :
        '';
    },
    resetData() {
      this.folderName = '';
      this.hasError = false;
      this.errorMessage = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.create-folder-modal {
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__label {
    margin-bottom: 4px;
  }
}
</style>
