<template>
  <tr
    :class="rowClasses"
  >
    <td :class="validatedClasses">
      <span class="matched-badging__table__shop-icon">
        <!-- Display principalShop icon only when shop has punchClock (not in upsell) -->
        <SkMedallion
          v-if="showMedallion"
          v-tooltip.top="otherShopTooltip"
          size="x-small"
          icon="ShopIcon"
          background-color="#eef4ff"
          color="#2b66fe"
        />
      </span>
      <div class="matched-badging__validated-icon__wrapper badging-table__cell">
        <CheckMarkIcon
          v-tooltip.top="validateRowTooltip"
          :class="matchedBadgingValidatedClasses"
          fill=""
          height="24"
          width="24"
          @click.native="validateRow"
        />
      </div>
    </td>
    <td
      v-if="isPlanningFeatureEnabled"
      :class="employeeMedallionClasses"
    >
      <div class="badging-table__cell">
        <SkMedallion
          :text="medallionInitial"
          background-color="#2b66fe"
          color="#fff"
        />
      </div>
    </td>
    <td :class="employeeClasses">
      <div class="badging-table__cell badging-table__label">
        <div class="text-truncate">
          <div>
            <div v-if="isEmployeesView">
              {{ dayLabel }}
            </div>
            <router-link
              v-else
              v-tooltip.top="userNameTooltip"
              :to="userBadgingsRoute"
            >
              <div v-if="isPlanningFeatureEnabled">
                {{ firstName }} <br> {{ lastName }}
              </div>
              <div v-else>
                {{ firstName }} {{ lastName }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </td>
    <!-- SHIFTS PLANNED -->
    <td
      v-if="!isPlanningFeatureEnabled"
      :class="matchedBadgingTableCellTurnedAbsence"
    >
      <SkCheckBox
        v-if="canTurnToAbsence"
        v-model="turnedAsAbsence"
        v-tooltip.bottom="turnedAsAbsenceTooltip"
        :disabled="!canEditPunchClock"
        :class="employeeHasNotBadgedClasses"
        data-test="badging-absence__checkbox"
      />
    </td>
    <td
      v-if="isPlanningFeatureEnabled"
      :class="plannedShiftsHoursClasses"
    >
      <div
        v-if="hasAbsenceBeenBadged"
        v-tooltip.top="absenceCalculationTooltip"
        class="badging-table__cell matched-badging__table__cell-absence-name text-truncate"
      >
        {{ absencePosteName }}
      </div>
      <div
        v-else-if="hasNoShift || shouldShowNoShift"
        class="text-truncate"
      >
        {{ $t('badgings.days.tabs.shifts.none') }}
      </div>
      <div
        v-else
      >
        <div class="badging-table__cell sk-flex-start">
          <div
            :class="startsHoursSelectedClasses(matchedBadging.predictedStartsAt)"
            @click="selectStartsAt(matchedBadging.predictedStartsAt)"
          >
            {{ shiftStartsAt }}
          </div>
          <span>-</span>
          <div
            :class="endsHoursSelectedClasses(matchedBadging.predictedEndsAt)"
            @click="selectEndsAt(matchedBadging.predictedEndsAt)"
          >
            {{ shiftEndsAt }}
          </div>
        </div>
        <div
          v-if="matchedBadging.isGrouped && detailsOpen"
          class="grouped-shifts__previsional-shifts grouped-shifts__detail-font"
        >
          <div
            v-for="shift in groupedShifts"
            :key="shift.id"
          >
            <div
              v-if="shift.id !== groupedShifts[0].id"
              class="grouped-shifts__separator"
            />
            <div class="grouped-shifts__poste">
              <div
                :style="vignetteStyle(getShiftPosteAttributes(shift).color)"
                class="grouped-shifts__poste-vignette"
              />
              <div v-tooltip="showPosteTooltip(shift.relationships.poste.attributes.name)">
                {{ truncatePosteName(getShiftPosteAttributes(shift).name, 15) }}
              </div>
            </div>
            <div>
              {{ timeToText(shift.attributes.previsionalStart || shift.attributes.startsAt) }} -
              {{ timeToText(shift.attributes.previsionalEnd || shift.attributes.endsAt) }}
            </div>
          </div>
        </div>
      </div>
    </td>
    <td
      v-if="isPlanningFeatureEnabled"
      :class="plannedShiftsBreakClasses"
    >
      <div
        v-if="matchedBadging.isAbsence || hasNoShift || shouldShowNoShift"
        class="badging-table__cell matched-badging__table__cell-shift-break--empty"
      >
        -
      </div>
      <div
        v-else
      >
        <div
          :class="breakHoursSelectedClasses(shiftBreak)"
          @click="selectBreakTime(shiftBreak)"
        >
          {{ shiftBreak }}
        </div>
        <div v-if="matchedBadging.isGrouped && detailsOpen">
          <div
            v-for="(shift, i) in groupedShifts"
            :key="shift.id"
            :class="breakStyle(shift, i)"
          >
            <ActivityIcon
              v-if="shouldDisplayActivityIcon(shift)"
              class="matched-badging__table__row__grouped-shifts-activity-icon"
              :note="shift.attributes.note"
              :tasks="shift.attributes.tasks"
              :comments="shift.attributes.comments"
              fill-color="black"
              shift-color="white"
              width="16px"
              height="16px"
              @click="showNoteModal($event, shift)"
            />
            {{ groupedShiftBreak(shift) }}
          </div>
        </div>
      </div>
    </td>
    <!-- LOCATION  -->
    <td
      v-if="(turnedAsAbsence && isPlanningFeatureEnabled) ||
        !turnedAsAbsence"
      class="matched-badging__table__cell--location"
    >
      <div
        :class="[isPlanningFeatureEnabled
          ? 'matched-badging__table__cell--location-icon'
          : 'matched-badging__table__cell--location-icon-noplanning']"
      >
        <BadgingLocationIcon
          v-if="badging && isPlanningFeatureEnabled"
          :badging="badging"
        />
        <NoPlanningBadgingLocationIcon
          v-else-if="badging"
          :badging="badging"
        />
      </div>
    </td>
    <!-- SHIFTS BADGINGS TURNED AS ABSENCE -->
    <td
      v-if="turnedAsAbsence && isPlanningFeatureEnabled"
      colspan="4"
      :class="badgingTurnedAbsenceClasses"
    >
      <div class="badging-table__cell turned-absence__container">
        <SkSelect
          v-model="absence"
          :disabled="!canEditPunchClock"
          :ajax-client="ajaxClient"
          :ajax-format-params="ajaxParamsFetchAbsences"
          :ajax-format-response="ajaxResponseAbsences"
          :label="turnedAsAbsenceLabel"
          :search-placeholder="$t('labels.search')"
          ajax-url="/v3/api/absences"
          object-value
          data-test="badging-absence__dropdown"
        >
          <template #selected-option="{ value }">
            <div class="text-truncate">
              {{ value.text }}
            </div>
          </template>
          <template #empty-option>
            {{ $t('search_bar.no_result') }}
          </template>
          <template #option="{ option }">
            <div> {{ option.text }} </div>
          </template>
        </SkSelect>
        <SkInputGroup separator>
          <template #prepend>
            <SkTimePicker
              v-model="selectedStartsAt"
              :error-message="$t('badgings.days.tabs.shifts.invalid_time')"
              :disabled="!canEditPunchClock"
              :reference-date="currentDate"
              :interval-in-minutes="15"
            />
          </template>
          <template>-</template>
          <template #append>
            <SkTimePicker
              v-model="selectedEndsAt"
              :error-message="$t('badgings.days.tabs.shifts.invalid_time')"
              :disabled="!canEditPunchClock"
              :reference-date="currentDate"
              :interval-in-minutes="15"
            />
          </template>
        </SkInputGroup>
      </div>
    </td>
    <td
      v-else-if="turnedAsAbsence && !isPlanningFeatureEnabled"
      :colspan="3"
      :class="badgingTurnedAbsenceClasses"
    >
      <div class="badging-table__cell turned-absence__container--no-planning">
        <SkSelect
          v-model="absence"
          :disabled="!canEditPunchClock"
          :ajax-client="ajaxClient"
          :ajax-format-params="ajaxParamsFetchAbsences"
          :ajax-format-response="ajaxResponseAbsences"
          :label="turnedAsAbsenceLabel"
          :search-placeholder="$t('labels.search')"
          ajax-url="/v3/api/absences"
          object-value
          data-test="badging-absence__dropdown"
        >
          <template #selected-option="{ value }">
            <div class="text-truncate">
              {{ value.text }}
            </div>
          </template>
          <template #empty-option>
            {{ $t('search_bar.no_result') }}
          </template>
          <template #option="{ option }">
            <div> {{ option.text }} </div>
          </template>
        </SkSelect>
      </div>
    </td>
    <!-- SHIFTS BADGINGS -->
    <td
      v-if="!turnedAsAbsence"
      :class="badgingsHoursClasses"
    >
      <div
        v-if="hasNoBadging"
        class="text-truncate"
      >
        {{ $t('badgings.days.tabs.shifts.no_badgings') }}
      </div>
      <div
        v-else
        class="sk-flex-start badging-table__cell"
      >
        <div
          :class="startsHoursSelectedClasses(matchedBadging.badgingStartsAt)"
          @click="selectStartsAt(matchedBadging.badgingStartsAt)"
        >
          {{ badgingStartsAt }}
        </div>
        <span>-</span>
        <div v-if="hasOnlyBadgingIn">
          <div class="matched-badging__table__cell-badging-hours--progess">
            <div class="matched-badging__table__cell-badging-hours--progess-status" />
          </div>
        </div>
        <div
          v-else
          :class="endsHoursSelectedClasses(matchedBadging.badgingEndsAt)"
          @click="selectEndsAt(matchedBadging.badgingEndsAt)"
        >
          {{ badgingEndsAt }}
        </div>
        <div
          v-if="isBadgingClosedByBackend"
          v-tooltip="closedByBackendTooltip"
          class="matched-badging__table__cell-closed-by-backend__mark"
        >
          *
        </div>
      </div>
    </td>
    <td
      v-if="!turnedAsAbsence"
      :class="badgingsBreakClasses"
    >
      <div
        v-if="hasNoBadging"
        class="badging-table__cell matched-badging__table__cell-badging-break--empty"
      >
        -
      </div>
      <div
        v-else
        v-tooltip.top="badgingBreaksTooltip"
        :class="breakHoursSelectedClasses(badgingBreaksDuration)"
        @click="selectBreakTime(badgingBreaksDuration)"
      >
        {{ badgingBreaksDuration }}
      </div>
    </td>
    <!-- SHIFTS PAYED -->
    <td
      v-if="shouldRenderRemuneratedShifts"
      :class="
        matchedBadging.isGrouped && detailsOpen ? 'grouped-shifts__main-line-top' : ''"
    >
      <div
        :class="remuneratedShiftsClasses"
      >
        <div
          class="matched-badging__table__cell-seperator-label-detailed
          matched-badging__table__cell-seperator-label-detailed--start"
        />
        <div class="badging-table__cell">
          <SkInputGroup separator>
            <template #prepend>
              <SkTimePicker
                :key="`${matchedBadgingKey}selectedStartsAt`"
                v-model="selectedStartsAt"
                :error-message="$t('badgings.days.tabs.shifts.invalid_time')"
                :disabled="!canEditPunchClock"
                :reference-date="currentDate"
                :interval-in-minutes="15"
                center
              />
            </template>
            <template>-</template>
            <template #append>
              <SkTimePicker
                :key="`${matchedBadgingKey}selectedEndsAt`"
                v-model="selectedEndsAt"
                :error-message="$t('badgings.days.tabs.shifts.invalid_time')"
                :disabled="!canEditPunchClock"
                :reference-date="currentDate"
                :interval-in-minutes="15"
                center
              />
            </template>
          </SkInputGroup>
        </div>
        <div
          class="matched-badging__table__cell-seperator-label-detailed
          matched-badging__table__cell-seperator-label-detailed--end"
        />
      </div>
      <div
        v-if="showRemuneratedShiftsDetails"
        class="grouped-shifts__remunerated-shifts grouped-shifts__detail-font"
      >
        <div
          v-for="shift in filteredGroupedShifts"
          :key="shift.id"
        >
          <div
            v-if="shift.id !== filteredGroupedShifts[0].id"
            class="grouped-shifts__separator"
          />
          <div class="grouped-shifts__poste">
            <div
              :style="vignetteStyle(getShiftPosteAttributes(shift).color)"
              class="grouped-shifts__poste-vignette"
            />
            <div v-tooltip="showPosteTooltip(shift.relationships.poste.attributes.name)">
              {{ truncatePosteName(shift.relationships.poste.attributes.name, 15) }}
            </div>
          </div>
          <div>
            {{
              selectedStartsAt && shift.id === filteredGroupedShifts[0].id ?
                selectedStartsAtText : timeToText(shift.attributes.startsAt)
            }} -
            {{
              selectedEndsAt &&
                shift.id === filteredGroupedShifts[filteredGroupedShifts.length - 1].id ?
                  selectedEndsAtText : timeToText(shift.attributes.endsAt)
            }}
          </div>
        </div>
      </div>
    </td>
    <td
      v-if="shouldRenderRemuneratedShifts"
      :class="remuneratedShiftsBreakClasses"
    >
      <div>
        <div class="badging-table__cell">
          <SkInput
            v-model="matchedBadging.selectedBreak"
            :disabled="!canEditPunchClock"
            type="number"
            min="0"
            step="1"
            center
          />
        </div>
        <div
          class="matched-badging__table__cell-seperator-label-detailed
          matched-badging__table__cell-seperator-label-detailed--break"
        />
      </div>
      <div v-if="showRemuneratedShiftsDetails">
        <div
          v-for="(shift, i) in filteredGroupedShifts"
          :key="shift.id"
          :class="payedBreakStyle(i)"
        >
          {{ formatBreakOrPauseTime(shift.attributes.pauseTime) }}
        </div>
      </div>
    </td>
    <td
      v-if="shouldAddPoste"
      class="matched-badging__table__cell__poste-assign"
      colspan="2"
    >
      <SkPopover
        ref="shop-poste-popover"
        :disabled="!canEditPunchClock || matchedBadging.ignored"
        placement="bottom-start"
        y-offset="12"
        has-footer
        :title="$t('badgings.days.tabs.shifts.poste_assignment.title')"
        :submit-button-disabled="!newBadgingShopPoste && !lastUserShopPoste"
        :submit-button-label="$t('badgings.days.tabs.shifts.poste_assignment.submit')"
        :cancel-button-label="$t('badgings.days.tabs.shifts.poste_assignment.cancel')"
        @show-popover="fetchLastUserShopPoste({
          shopId: currentShop.id, userId: matchedBadging.userId
        })"
        @hide-popover="clearNewBadgingShopPoste"
        @submit="submitShopPoste"
        @cancel="cancelPosteAssignment"
      >
        <!-- eslint-enable max-len -->
        <template #anchor>
          <SkOroraButton
            :disabled="!canEditPunchClock || matchedBadging.ignored"
            class="matched-badging__shop-poste__button"
            variant="tertiary"
            size="small"
          >
            {{ $t('badgings.days.tabs.shifts.poste_assignment.title') }}
          </SkOroraButton>
        </template>
        <template #content>
          <div
            v-if="lastUserShopPosteLoading"
            class="shop-poste__spinner"
          >
            <SkLoader />
          </div>
          <div
            v-else
            class="shop-poste__select"
          >
            <ClipboardFilledIcon />
            <SkSelect
              v-model="selectedUserShopPoste"
              :disabled="!canEditPunchClock"
              :label="shopPosteLabel"
              :ajax-client="ajaxClient"
              :ajax-format-params="ajaxParamsFetchShopPostes"
              :ajax-format-response="ajaxResponseShopPostes"
              :ajax-url="`/v3/api/shops/${currentShop.id}/postes`"
              :search-placeholder="$t('labels.search')"
              append-to-body
            >
              <template #selected-option="{ value }">
                <div class="shop-poste__selected-item text-truncate">
                  {{ value.attributes.name }}
                  <div
                    :style="{ backgroundColor: `${value.attributes.color}` }"
                    class="shop-poste__color"
                  />
                </div>
              </template>
              <template #empty-option>
                {{ $t('search_bar.no_result') }}
              </template>
              <template #option="{ option }">
                <div class="shop-poste-list__item">
                  {{ option.attributes.name }}
                  <div
                    :style="{ backgroundColor: `${option.attributes.color}` }"
                    class="shop-poste__color"
                  />
                </div>
              </template>
            </SkSelect>
          </div>
        </template>
      </SkPopover>
    </td>
    <td
      v-if="isPlanningFeatureEnabled"
      :class="matchedBadgingTableCellTurnedAbsence"
    >
      <SkCheckBox
        v-if="canTurnToAbsence"
        v-model="turnedAsAbsence"
        v-tooltip.bottom="turnedAsAbsenceTooltip"
        :disabled="!canEditPunchClock"
        class="badging-table__cell"
        data-test="badging-absence__checkbox"
      />
    </td>
    <!-- MEALS BENEFITS -->
    <td
      v-if="currentShop.attributes.isMealRuleBenefitInKind"
      :class="mealsClasses"
    >
      <div class="badging-table__cell">
        <SkInput
          v-model="matchedBadging.meal"
          :disabled="!canEditPunchClock || turnedAsAbsence"
          type="number"
          min="0"
          step="1"
          integer
          center
        />
      </div>
    </td>
    <!-- TOTAL DURATION -->
    <td :class="totalDurationClasses">
      {{ selectedShiftDuration }}
    </td>
    <!-- NOTES -->
    <td
      v-if="!isDayClocksInOut && isPlanningFeatureEnabled"
      class="pr-1"
    >
      <div
        v-if="!hasNoShift"
        v-tooltip.bottom.offset="shiftNoteTooltip"
        class="matched-badging__table__row__notes"
      >
        <ActivityIcon
          v-if="shouldDisplayActivityIcon(matchedBadging.shift)"
          class="matched-badging__table__row__activity-icon"
          :note="matchedBadging.shift.attributes.note"
          :tasks="matchedBadging.shift.attributes.tasks"
          :comments="matchedBadging.shift.attributes.comments"
          fill-color="black"
          shift-color="white"
          width="16px"
          height="16px"
          @click="showNoteModal($event, matchedBadging.shift)"
        />
        <SkCircleButton
          v-else-if="canCreateShifts && !anyLockOnCurrentDay"
          icon="PlusSignV2Icon"
          size="small"
          bordered
          @click="showNoteModal($event, matchedBadging.shift)"
        />
      </div>
    </td>
    <!-- ACTIONS -->
    <td
      :class="actionsClasses"
    >
      <SkDropdown
        v-if="matchedBadging.badgingStartsAt && isPlanningFeatureEnabled"
        ref="dropdown"
        :prevent-overflow="false"
        placement="bottom-start"
        y-offset="5px"
        trigger="click"
      >
        <template #anchor>
          <SkCircleButton
            v-tooltip.bottom-end="$t('badgings.days.tabs.shifts.tooltip.actions')"
            icon="KebabV2Icon"
          />
        </template>
        <template #menu>
          <div class="matched-badging__table__cell-actions__menu">
            <div
              :class="ignoreBadgingClass"
              @click="ignoreBadging"
            >
              <CircledXIcon
                class="matched-badging__table__cell-actions__item-icon"
              />
              {{ ignoreBadgingText }}
            </div>
          </div>
        </template>
      </SkDropdown>
      <SkOroraButton
        v-else-if="!isPlanningFeatureEnabled"
        class="badging-table-ignore-inner"
        variant="tertiary"
        size="small"
        :icon="ignoreUnignoreIcon"
        @click="ignoreBadging"
      >
        <div class="badging-table-ignore">
          {{ ignoreBadge }}
        </div>
      </SkOroraButton>
    </td>
    <td
      v-if="atLeastOneGroupedBadging"
      :class="caretClasses"
    >
      <SkCircleButton
        v-if="matchedBadging.isGrouped"
        v-tooltip.bottom-end="$t('badgings.days.tabs.shifts.tooltip.show_details')"
        icon="ChevronRightV2Icon"
        :class="caretClass"
        class="caret-svg"
        icon-color="#000000"
        size="small"
        @click="showOrHideDetails"
      />
    </td>
  </tr>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
  ABSENCE_TYPE_HOURS,
} from '@app-js/shared/constants/shift';
import { FEATURES } from '@app-js/shared/constants/features.js';
import skDate from '@skello-utils/dates';
import { isEmpty } from '@skello-utils/array';
import { httpClient } from '@skello-utils/clients';
import { truncateString } from '@skello-utils/formatting/strings';
import ActivityIcon from '@app-js/plannings/shared/components/ShiftActivities/ActivityIcon';
import { BADGING_BUFFER_BEFORE_OPENING } from '@app-js/shared/store/modules/timeclock/badgings';
import { roundFloat } from '@skello-utils/formatting/numbers';
import {
  convertSecondsToMinutes, formatBreakOrPauseTime,
} from '@skello-utils/formatting/time';
import BadgingLocationIcon from './BadgingLocationIcon';
import NoPlanningBadgingLocationIcon from './NoPlanningBadgingLocationIcon';

const ROUNDING_PRECISION = 1;

export default {
  name: 'MatchedBadgingRow',
  components: {
    BadgingLocationIcon,
    NoPlanningBadgingLocationIcon,
    ActivityIcon,
  },
  props: {
    matchedBadging: {
      type: Object,
      required: true,
    },
    atLeastOneGroupedBadging: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ajaxClient: () => httpClient,
      ignoreUnignoreButtonClicked: false,
      detailsOpen: false,
      newBadgingShopPoste: null,
      originalMatchedBadging: { ...this.matchedBadging },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('punchClock', ['punchClockSettings']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('config', ['config']),
    ...mapState('badgings', [
      'currentDate',
      'lastUserShopPoste',
      'lastUserShopPosteLoading',
      'badgingsHistory',
    ]),
    ...mapGetters('badgings', [
      'isEmployeesView',
      'isPositionsFeatureEnabled',
      'erroredFields',
      'missingMandatoryFields',
      'matchedBadgingMissingHoursField',
      'matchedBadgingMissingBreakField',
      'matchedBadgingBreakInvalid',
      'matchedBadgingBreakNegative',
      'matchedBadgingBreakLongerThanShift',
      'matchedBadgingPosteNotSet',
      'anyLockOnCurrentDay',
      'matchedBadgingsPerDay',
    ]),
    ...mapGetters('planningsShifts', ['shouldDisplayTasks', 'shouldDisplayComments']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ignoreUnignoreIcon() {
      return this.ignoreUnignoreButtonClicked ? 'OpenEyeV2Icon' : 'ClosedEyeV2Icon';
    },
    isPlanningFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING, this.currentShop.id, () => true);
    },
    isEmployeeColumnLastFromBlock() {
      return !this.isPlanningFeatureEnabled;
    },
    referenceDate() {
      const date = this.matchedBadging.predictedStartsAt || this.matchedBadging.badgingStartsAt;

      return this.isEmployeesView ? skDate.utc(date).format('YYYY-MM-DD') : this.currentDate;
    },
    selectedStartsAt: {
      get() {
        return this.isAbsenceCreatedFromPlaceholder ? null :
          this.formatDateAndTime(this.referenceDate, this.matchedBadging.selectedStartsAt);
      },

      set(newValue) {
        this.updateRealOrPlaceholderMatchedBadging({
          key: this.matchedBadgingKey,
          selectedStartsAt: skDate(newValue).format('HH:mm'),
        });
      },
    },
    selectedEndsAt: {
      get() {
        return this.isAbsenceCreatedFromPlaceholder ? null :
          this.formatDateAndTime(this.referenceDate, this.matchedBadging.selectedEndsAt);
      },

      set(newValue) {
        this.updateRealOrPlaceholderMatchedBadging({
          key: this.matchedBadgingKey,
          selectedEndsAt: skDate(newValue).format('HH:mm'),
        });
      },
    },
    absence: {
      get() {
        const { absencePosteId, absencePosteName, turnedAbsenceName } = this.matchedBadging;

        return {
          id: absencePosteId,
          text: this.matchedBadging.validated ? absencePosteName : turnedAbsenceName,
        };
      },

      set(newValue) {
        const absence = {};

        if (this.matchedBadging.validated) {
          absence.absencePosteName = newValue.text;
        } else {
          absence.turnedAbsenceName = newValue.text;
        }

        this.updateRealOrPlaceholderMatchedBadging({
          key: this.matchedBadgingKey,
          absencePosteId: newValue.id,
          ...absence,
        });
      },
    },
    selectedUserShopPoste: {
      get() {
        return this.newBadgingShopPoste || this.lastUserShopPoste;
      },
      set(newValue) {
        this.newBadgingShopPoste = newValue;
      },
    },
    turnedAsAbsence: {
      get() {
        return !!this.matchedBadging.turnedAsAbsence;
      },

      set(isTurn) {
        if (isTurn) {
          if (!this.hasNoShift) {
            const { pauseCompensationStartsAt } = this.currentShop.attributes;

            let selectEndsAt = skDate(this.matchedBadging.predictedEndsAt);

            if (!pauseCompensationStartsAt) {
              selectEndsAt = selectEndsAt
                .subtract(this.matchedBadging.predictedBreak, 's')
                .format();
            }

            this.selectStartsAt(this.matchedBadging.predictedStartsAt);
            this.selectEndsAt(selectEndsAt);
            this.selectBreakTime('0 mn');
          }

          this.updateRealOrPlaceholderMatchedBadging({
            key: this.matchedBadgingKey,
            defaultMeal: this.matchedBadging.meal,
            meal: 0,
            turnedAsAbsence: true,
          });
        } else {
          if (!this.hasNoShift) {
            this.updateRealOrPlaceholderMatchedBadging({
              key: this.matchedBadgingKey,
              selectedBreak: roundFloat(
                convertSecondsToMinutes(this.matchedBadging.predictedBreak),
                ROUNDING_PRECISION,
              ),
              selectedStartsAt: skDate(this.matchedBadging.predictedStartsAt).utc().format('HH:mm'),
              selectedEndsAt: skDate(this.matchedBadging.predictedEndsAt).utc().format('HH:mm'),
            });
          }

          const { shift, defaultMeal, isGrouped, posteName } = this.matchedBadging;
          this.updateRealOrPlaceholderMatchedBadging({
            key: this.matchedBadgingKey,
            meal: defaultMeal || 0,
            turnedAsAbsence: false,
            posteId: !shift || (!!shift && this.getShiftPosteAttributes(shift).absenceKey) ?
              null : this.getShiftPosteAttributes(shift).id,
            posteName: isGrouped || !this.isPositionsFeatureEnabled ?
              null :
              posteName || this.selectedUserShopPoste.attributes.name,
          });
        }
      },
    },
    badging() {
      return this.matchedBadging.badging;
    },
    isDayClocksInOut() {
      return this.punchClockSettings.dayClocksInOut;
    },
    punchOnAffiliatedShop() {
      return this.punchClockSettings.punchOnAffiliatedShop;
    },
    validateRowTooltip() {
      return !this.matchedBadging.validated && this.isEmployeesView ?
        this.$t('badgings.days.tabs.shifts.validate.tooltip') :
        null;
    },
    showMedallion() {
      return this.currentShop.attributes.hasPunchClock &&
        this.matchedBadging.isUserShopHasNoBadging &&
        this.isBadgingGoToPrincipalShop;
    },
    showRemuneratedShiftsDetails() {
      return this.matchedBadging.isGrouped &&
        this.detailsOpen &&
        this.matchedBadging.validated &&
        (!this.hasNoBadging || !!this.selectedStartsAt || !!this.selectedEndsAt);
    },
    caretClass() {
      return { open: this.detailsOpen, closed: !this.detailsOpen };
    },
    groupedShifts() {
      return this.matchedBadging.shift?.shifts;
    },
    filteredGroupedShifts() {
      let selectedEndsAt;
      const midnight = '00:00';

      if (this.matchedBadging.selectedEndsAt === midnight) {
        const tomorrow = skDate(this.referenceDate).add(1, 'day').format('YYYY-MM-DD');
        selectedEndsAt = this.formatDateAndTime(tomorrow, midnight);
      } else {
        selectedEndsAt = this.selectedEndsAt;
      }
      return this.matchedBadging.shift.shifts.filter(shift => {
        const shiftStartsAt = skDate(skDate(shift.attributes.startsAt).utc().format('YYYY-MM-DD HH:mm')).format();
        const shiftEndsAt = skDate(skDate(shift.attributes.endsAt).utc().format('YYYY-MM-DD HH:mm')).format();

        if ((!this.selectedStartsAt || (
          this.selectedStartsAt && skDate(this.selectedStartsAt).isBefore(skDate(shiftEndsAt))
        )) && (!selectedEndsAt || (
          selectedEndsAt && skDate(selectedEndsAt).isAfter(skDate(shiftStartsAt)))
        )) {
          return shift;
        }

        return null;
      });
    },
    userBadgingsRoute() {
      return {
        name: 'badgings_shifts_users',
        params: {
          user_id: this.matchedBadging.userId,
        },
        query: {
          date: this.currentDate,
        },
      };
    },
    matchedBadgingKey() {
      return this.matchedBadging.key;
    },
    absencePosteName() {
      return this.matchedBadging.validated && this.matchedBadging.previsionalPosteIsAbsence ?
        this.matchedBadging.previsionalPosteName :
        this.matchedBadging.absencePosteName;
    },
    shouldShowNoShift() {
      return this.matchedBadging.validated ?
        !this.matchedBadging.previsionalPosteName && !this.matchedBadging.hasBeenIgnored :
        !this.matchedBadging.posteId && !this.matchedBadging.absencePosteId;
    },
    shouldAddPoste() {
      return (this.notPredictedBadging || this.hasAbsenceBeenBadged) && !this.hasPosteChanged &&
        !this.turnedAsAbsence && !this.matchedBadging.posteId && this.isPositionsFeatureEnabled;
    },
    isAbsenceCreatedFromPlaceholder() {
      return this.matchedBadging.badging &&
        !this.matchedBadging.badging?.in && !this.matchedBadging.badging?.out;
    },
    isPlaceholderMatchedBadging() {
      return this.isAbsenceCreatedFromPlaceholder &&
        !this.matchedBadging.isAbsence;
    },
    hasNoAbsenceNoPosteIdAndNoPlaceholderBadges() {
      return !this.turnedAsAbsence &&
        !(this.matchedBadging.posteId || this.matchedBadging.isGrouped) &&
        !this.isPlaceholderMatchedBadging;
    },
    employeeHasNotBadgedClasses() {
      return {
        'badging-table__cell': true,
        'unmatched-badging__table__cell--unmatched': this.isPlaceholderMatchedBadging,
      };
    },
    shouldRenderRemuneratedShifts() {
      if (this.isPlanningFeatureEnabled) {
        return !this.turnedAsAbsence &&
          (!this.isPositionsFeatureEnabled ||
            this.matchedBadging.posteId ||
            this.matchedBadging.isGrouped);
      }
      return !this.isPositionsFeatureEnabled || !this.hasNoAbsenceNoPosteIdAndNoPlaceholderBadges;
    },
    canTurnToAbsence() {
      return this.hasPosteChanged ||
        this.matchedBadging.posteId ||
        (!this.matchedBadging.posteId && !this.isPositionsFeatureEnabled) ||
        this.turnedAsAbsence ||
        this.matchedBadging.isGrouped;
    },
    turnedAsAbsenceLabel() {
      return this.absence.text ? null : this.$t('badgings.days.tabs.shifts.turned_as_absence.select_label');
    },
    hasAbsenceBeenBadged() {
      return this.matchedBadging.validated ?
        this.matchedBadging.previsionalPosteIsAbsence :
        this.matchedBadging.isAbsence && !!this.matchedBadging.badgingStartsAt;
    },
    notPredictedBadging() {
      if (this.hasPosteChanged) return false;

      if (this.matchedBadging.validated) {
        return !this.matchedBadging.previsionalPosteName;
      }

      return !this.matchedBadging.predictedStartsAt && !this.matchedBadging.predictedEndsAt &&
        this.matchedBadging.badgingStartsAt;
    },
    matchedBadgingDate() {
      const startsAt = this.hasNoShift ?
        this.matchedBadging.badgingStartsAt :
        this.matchedBadging.predictedStartsAt;

      return skDate(startsAt).utc().add(BADGING_BUFFER_BEFORE_OPENING, 'm');
    },
    dayLabel() {
      const dateForDayLabel = this.matchedBadgingDate.utc();
      if (dateForDayLabel.format('HH:mm') < this.currentShop.attributes.openingTime) {
        dateForDayLabel.subtract(1, 'day');
      }
      return upperFirst(dateForDayLabel
        .locale(this._i18n._vm.locale)
        .format('dddd'));
    },
    shopPosteLabel() {
      return (this.newBadgingShopPoste || this.lastUserShopPoste) ?
        null : this.$t('badgings.days.tabs.shifts.poste_assignment.label');
    },
    badgingIgnored() {
      return this.matchedBadging.ignored;
    },
    ignoreBadge() {
      return this.badgingIgnored ?
        this.$t('badgings.days.tabs.shifts.headers.unignore') :
        this.$t('badgings.days.tabs.shifts.headers.ignore');
    },
    ignoreBadgingText() {
      return this.badgingIgnored ?
        this.$t('badgings.days.tabs.shifts.unignore_badge') :
        this.$t('badgings.days.tabs.shifts.ignore_badge');
    },
    ignoreBadgingClass() {
      return {
        'matched-badging__table__cell-actions__item': true,
        'matched-badging__table__cell-actions__item--disabled': !this.canEditPunchClock,
      };
    },
    rowClasses() {
      return {
        'matched-badging__table__row': true,
        'matched-badging__table__row--ignored': this.badgingIgnored,
        'matched-badging__table__row--validated': this.matchedBadging.validated,
      };
    },
    validatedClasses() {
      return {
        'matched-badging__table__cell-validated': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__validated': this.matchedBadging.isGrouped,
      };
    },
    employeeMedallionClasses() {
      return {
        'matched-badging__table__cell-employee-medallion': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__medaillon': this.matchedBadging.isGrouped,
      };
    },
    employeeClasses() {
      return {
        'matched-badging__table__cell-employee-name': this.isPlanningFeatureEnabled,
        'pl-4': !this.isPlanningFeatureEnabled,
        'matched-badging__table__cell-employee-name-noplanning': !this.isPlanningFeatureEnabled,
        'matched-badging__table__cell-employee-name__separator': this.isEmployeeColumnLastFromBlock,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
      };
    },
    plannedShiftsHoursClasses() {
      return {
        'matched-badging__table__cell-shift-hours': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__simple-top': this.matchedBadging.isGrouped,
      };
    },
    plannedShiftsBreakClasses() {
      return {
        'matched-badging__table__cell-shift-break': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__simple-top': this.matchedBadging.isGrouped,
      };
    },
    badgingTurnedAbsenceClasses() {
      return {
        'matched-badging__table__cell--turned-absence': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__turned-absence--on': this.matchedBadging.isGrouped,
        'matched-badging__table__cell--turned-absence--border': !this.isPlanningFeatureEnabled,
      };
    },
    badgingTurnedAbsencePaidShiftsClasses() {
      return {
        'matched-badging__table__cell--turned-absence-paid-shifts': true,
      };
    },
    badgingsHoursClasses() {
      return {
        'matched-badging__table__cell-badging-hours': true,
        'pr-3': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__simple-top': this.matchedBadging.isGrouped,
      };
    },
    badgingsBreakClasses() {
      return {
        'matched-badging__table__cell-badging-break': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__simple-top': this.matchedBadging.isGrouped,
      };
    },
    remuneratedShiftsClasses() {
      return {
        'grouped-shifts__remunerated-top': this.matchedBadging.isGrouped,
        'grouped-shifts__remunerated-top--open': this.matchedBadging.isGrouped && this.detailsOpen,
        'grouped-shifts__remunerated-top--closed': this.matchedBadging.isGrouped && !this.detailsOpen,
        'matched-badging__table__cell-selected-hours': true,
      };
    },
    remuneratedShiftsBreakClasses() {
      return {
        'matched-badging__table__cell-selected-break': true,
        'matched-badging__table__cell-selected-break-noplanning': !this.isPlanningFeatureEnabled,
        'pr-3': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__remunerated-break': this.matchedBadging.isGrouped,
      };
    },
    mealsClasses() {
      return {
        'matched-badging__table__cell-meal': true,
        'pl-3': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__box-top': this.matchedBadging.isGrouped,
      };
    },
    totalDurationClasses() {
      return {
        'matched-badging__table__cell-total-duration': true,
        'pl-3': true,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__simple-top': this.matchedBadging.isGrouped,
      };
    },
    actionsClasses() {
      return {
        'matched-badging__table__cell-actions': true,
        'matched-badging__table__cell-actions grouped': this.atLeastOneGroupedBadging,
        'matched-badging__table__cell-actions not-grouped': !this.atLeastOneGroupedBadging,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
      };
    },
    caretClasses() {
      return {
        'caret-not-ignored': true,
        'grouped-shifts__main-line-top': true,
        'grouped-shifts__caret': true,
      };
    },
    otherShopTooltip() {
      return this.$t('badgings.days.tabs.shifts.other_shop', { shop_name: this.matchedBadging.userShopName });
    },
    isBadgingGoToPrincipalShop() {
      return this.punchOnAffiliatedShop;
    },
    canCreateShifts() {
      return this.currentLicense.attributes.canCreateShifts;
    },
    canEditPunchClock() {
      return this.currentLicense?.attributes.canEditPunchClockData &&
        this.canCreateShifts;
    },
    hasNoShift() {
      const { predictedStartsAt, predictedEndsAt } = this.matchedBadging;

      return !(predictedStartsAt || predictedEndsAt);
    },
    hasNoBadging() {
      const { badgingStartsAt, badgingEndsAt } = this.matchedBadging;

      return !(badgingStartsAt || badgingEndsAt);
    },
    hasOnlyBadgingIn() {
      const { badgingStartsAt, badgingEndsAt } = this.matchedBadging;

      return badgingStartsAt && !badgingEndsAt;
    },
    matchedBadgingValidatedClasses() {
      return {
        'matched-badging__validated-icon': true,
        'cursor--pointer': this.isEmployeesView,
        'matched-badging__validated-icon--validated': this.matchedBadging.validated,
      };
    },
    matchedBadgingTableCellTurnedAbsence() {
      return {
        'matched-badging__table__cell-turned-absence': this.isPlanningFeatureEnabled,
        'matched-badging__table__cell-turned-absence--checked': this.turnedAsAbsence,
        'grouped-shifts__main-line-top': this.matchedBadging.isGrouped,
        'grouped-shifts__turned-absence--on': this.matchedBadging.isGrouped && this.turnedAsAbsence,
        'grouped-shifts__turned-absence--off': this.matchedBadging.isGrouped && !this.turnedAsAbsence,
        'pl-4': !this.isPlanningFeatureEnabled,
      };
    },
    firstName() {
      return upperFirst(this.matchedBadging.firstName);
    },
    lastName() {
      return upperFirst(this.matchedBadging.lastName);
    },
    medallionInitial() {
      return this.isEmployeesView ? this.dayLabel[0] : this.firstName[0];
    },
    employeeBadgingUrl() {
      const { userId } = this.matchedBadging;

      const date = skDate(this.currentDate).format('YYYY-MM-DD');

      return `/shops/${this.currentShop.id}/badgings/${userId}?date=${date}`;
    },
    absenceCalculationTooltip() {
      let tooltip = `${this.$t('badgings.days.tabs.shifts.absence.planned')}
                     ${this.absencePosteName} <br>`;

      if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_DAY) {
        tooltip += this.$t('badgings.days.tabs.shifts.absence.all_day');
      } else if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_HALF_DAY) {
        tooltip += this.$t('badgings.days.tabs.shifts.absence.half_day');
      } else if (this.matchedBadging.absenceCalculation === ABSENCE_TYPE_HOURS) {
        tooltip += `${this.shiftStartsAt} - ${this.shiftEndsAt}`;
      }

      return tooltip;
    },
    userNameTooltip() {
      if (this.firstName.length > 16 || this.lastName.length > 16) {
        return `${this.firstName} ${this.lastName}`;
      }

      return null;
    },
    selectedStartsAtText() {
      return this.matchedBadging.selectedStartsAt;
    },
    selectedEndsAtText() {
      return this.matchedBadging.selectedEndsAt;
    },
    shiftStartsAt() {
      return this.timeToText(this.matchedBadging.predictedStartsAt);
    },
    shiftEndsAt() {
      return this.timeToText(this.matchedBadging.predictedEndsAt);
    },
    badgingStartsAt() {
      return this.timeToText(this.matchedBadging.badgingStartsAt);
    },
    badgingEndsAt() {
      return this.timeToText(this.matchedBadging.badgingEndsAt);
    },
    shiftBreak() {
      return formatBreakOrPauseTime(this.matchedBadging.predictedBreak);
    },
    hasShiftNote() {
      return this.matchedBadging.shift?.attributes.note?.length > 0;
    },
    shiftNoteTooltip() {
      return this.hasShiftNote ? this.matchedBadging.shift.attributes.note : this.$t('badgings.days.tabs.shifts.tooltip.add_note');
    },
    badgingBreaksTooltip() {
      if (this.matchedBadging.badgingBreaks.length === 0) return null;

      let breakString = '';

      this.matchedBadging.badgingBreaks.forEach((breakTimes, index) => {
        const startBreakTime = skDate(breakTimes[0]).format('HH:mm');
        const endBreakTime = breakTimes[1] ? skDate(breakTimes[1]).format('HH:mm') : '-';

        breakString += `${this.$t('badgings.days.tabs.shifts.start_break')} : ${startBreakTime} <br>` +
          `${this.$t('badgings.days.tabs.shifts.end_break')} : ${endBreakTime}`;

        // If line is not last one
        if (index + 1 !== this.matchedBadging.badgingBreaks.length) {
          breakString += '<br><br>';
        }
      });

      return breakString;
    },
    badgingBreaksDuration() {
      let breakDuration = 0;

      this.matchedBadging.badgingBreaks.forEach(breakTimes => {
        if (breakTimes.length < 2 || breakTimes.some(value => !value)) return;

        const startBreakTime = skDate(breakTimes[0]);
        const endBreakTime = skDate(breakTimes[1]);
        breakDuration += endBreakTime.diff(startBreakTime);
      });

      return `${(breakDuration / 60000).toFixed()} mn`;
    },
    selectedShiftDuration() {
      if (!this.isPlanningFeatureEnabled &&
        ((!this.selectedStartsAt || !this.selectedEndsAt) &&
          !this.matchedBadgingsPerDay().value?.includes(this.matchedBadging))) {
        return '';
      }

      if (!this.selectedStartsAt || !this.selectedEndsAt) {
        return '0h';
      }

      if (this.selectedStartsAt === this.selectedEndsAt) {
        return '24h';
      }

      const { pauseCompensationStartsAt, timezone } = this.currentShop.attributes;

      const endTime = this.selectedStartsAt >= this.selectedEndsAt ?
        skDate(this.selectedEndsAt).add(1, 'd').format() :
        this.selectedEndsAt;

      // Format start and end times
      const formattedStartAt = skDate(this.selectedStartsAt).format('YYYY-MM-DDTHH:mm:ss');
      const formattedEndAt = skDate(endTime).format('YYYY-MM-DDTHH:mm:ss');

      // Calculate duration considering timezone
      let durationInMilliseconds = skDate.tz(formattedEndAt, timezone)
        .diff(skDate.tz(formattedStartAt, timezone));

      if (!pauseCompensationStartsAt && !this.turnedAsAbsence) {
        durationInMilliseconds -= skDate.duration(this.matchedBadging.selectedBreak, 'minutes').asMilliseconds();
      }

      const durationInHours = `${skDate.duration(durationInMilliseconds).asHours().toFixed(2) }h`;

      return durationInHours;
    },
    hasPosteChanged() {
      return this.originalMatchedBadging.posteId !== this.matchedBadging.posteId;
    },
    turnedAsAbsenceTooltip() {
      return this.canEditPunchClock ?
        this.$t('badgings.days.tabs.shifts.turned_as_absence.tooltip') :
        null;
    },
    isRowNotCompleted() {
      return this.missingMandatoryFields.includes(this.matchedBadging);
    },
    isRowErrored() {
      return this.erroredFields.includes(this.matchedBadging);
    },
    isBadgingClosedByBackend() {
      return this.matchedBadging.closedByBackend;
    },
    closedByBackendTooltip() {
      return this.matchedBadging.validated ? null : this.$t('badgings.closed_by_backend');
    },
  },
  methods: {
    ...mapActions('badgings', ['fetchLastUserShopPoste', 'updateMatchedBadging']),
    shouldDisplayActivityIcon(shift) {
      const { note, tasks, comments } = shift.attributes;

      return !!note ||
        this.shouldDisplayComments(comments) ||
        (!isEmpty(tasks) && this.shouldDisplayTasks);
    },
    selectStartsAt(date) {
      if (!this.canEditPunchClock || this.turnedAsAbsence) return;

      this.updateRealOrPlaceholderMatchedBadging({
        key: this.matchedBadgingKey,
        selectedStartsAt: skDate(date)
          .utc()
          .format('HH:mm'),
      });
    },
    selectEndsAt(date) {
      if (!this.canEditPunchClock) return;

      this.updateRealOrPlaceholderMatchedBadging({
        key: this.matchedBadgingKey,
        selectedEndsAt: skDate(date).utc().format('HH:mm'),
      });
    },
    selectBreakTime(breaktime) {
      if (!this.canEditPunchClock || this.turnedAsAbsence) return;

      // here break time is handled with array of array
      this.updateRealOrPlaceholderMatchedBadging({
        key: this.matchedBadgingKey,
        selectedBreak: Number(breaktime.split(' ')[0]),
      });
    },
    showPosteTooltip(value) {
      return value.length > 15 ? value : null;
    },
    showNoteModal(event, shift) {
      if (!this.canCreateShifts) return;

      this.emitOnRoot(
        'init-manage-shift-note-modal',
        event,
        {
          shift,
          date: this.matchedBadgingDate,
        },
      );
    },
    truncatePosteName(value, length) {
      return truncateString(value, length);
    },
    startsHoursSelectedClasses(time) {
      return {
        'matched-badging__table__cell-hours': true,
        'matched-badging__table__cell-hours-start': true,
        'matched-badging__table__cell-hours--disabled': !this.canEditPunchClock,
        'matched-badging__table__cell-hours-start--selected':
          this.hoursEqual(time, this.selectedStartsAt),
      };
    },
    endsHoursSelectedClasses(time) {
      return {
        'matched-badging__table__cell-hours': true,
        'matched-badging__table__cell-hours-end': true,
        'matched-badging__table__cell-hours--disabled': !this.canEditPunchClock,
        'matched-badging__table__cell-hours-end--selected':
          this.hoursEqual(time, this.selectedEndsAt) ||
          (this.turnedAsAbsence && this.hoursEqual(time, skDate(this.selectedEndsAt)
            .add(this.matchedBadging.predictedBreak, 's')
            .format())),
      };
    },
    breakHoursSelectedClasses(breaktime) {
      const { pauseCompensationStartsAt } = this.currentShop.attributes;

      return {
        'badging-table__cell': true,
        'matched-badging__table__cell-hours': true,
        'matched-badging__table__cell-hours-break': true,
        'matched-badging__table__cell-hours--disabled': !this.canEditPunchClock,
        'matched-badging__table__cell-hours-break--selected':
          Number(breaktime.split(' ')[0]) === Number(this.matchedBadging.selectedBreak) ||
          (this.turnedAsAbsence && pauseCompensationStartsAt),
      };
    },
    breakStyle(shift, index) {
      return {
        'grouped-shifts__detail-font': true,
        'grouped-shifts__first-break': !index,
        'grouped-shifts__second-break-and-more': index !== 0,
        'grouped-shifts__without-activity-icon': !this.shouldDisplayActivityIcon(shift),
      };
    },
    payedBreakStyle(index) {
      const props = {
        'grouped-shifts__detail-font': true,
        'grouped-shifts__first-payed-break': !index,
        'grouped-shifts__second-break-and-more': index !== 0,
      };
      return props;
    },
    timeToText(date) {
      return skDate(date).utc().format('HH:mm');
    },
    formatDateAndTime(date, hours) {
      if (!date || !hours) return null;

      return skDate(`${ date } ${ hours }`).format();
    },
    hoursEqual(firstDate, secondDate) {
      if (!firstDate || !secondDate) return false;

      return skDate(firstDate).minutes() === skDate(secondDate).minutes() &&
        skDate(firstDate).utc().hours() === skDate(secondDate).hours();
    },
    ajaxParamsFetchAbsences(params) {
      return {
        shop_id: this.currentShop.id,
        ...params,
      };
    },
    ajaxResponseAbsences(response) {
      return response.data
        .filter(({ attributes }) => Boolean(attributes.active))
        .map(
          ({ id, attributes }) => ({
            id,
            text: attributes.name,
          }),
        )
        .sort((a, b) => a.text.localeCompare(b.text, 'en', { sensitivity: 'base' }));
    },
    groupedShiftBreak(shift) {
      const badgingHistories = this.badgingsHistory
        .map(badgingsHistory => badgingsHistory.badgings)
        .flat();

      const shiftHistory = badgingHistories.filter(
        badgingHistory => String(badgingHistory.shift_id) === String(shift?.id),
      );

      if (this.matchedBadging.validated && shiftHistory.length > 0) {
        return `${shiftHistory[0].shift_pause} mn`;
      }

      return formatBreakOrPauseTime(shift.attributes.pauseTime);
    },
    ignoreBadging() {
      if (!this.canEditPunchClock) return;
      this.matchedBadging.ignored = !this.matchedBadging.ignored;
      this.ignoreUnignoreButtonClicked = !this.ignoreUnignoreButtonClicked;
    },
    ajaxParamsFetchShopPostes() {
      return { shop_id: this.currentShop.id };
    },
    ajaxResponseShopPostes(response) {
      return response.data.map(poste => {
        poste.text = poste.attributes.name;
        return poste;
      });
    },
    cancelPosteAssignment() {
      this.$refs['shop-poste-popover'].hide();
    },
    clearNewBadgingShopPoste() {
      this.newBadgingShopPoste = null;
    },
    validateRow() {
      if (!this.isEmployeesView) return;

      this.$skAnalytics.track('badgings_validate', { source: 'user_day' });

      if (this.isRowNotCompleted || this.isRowErrored) {
        const message = this.getErrorMessage();

        this.$skToast({
          message,
          variant: 'error',
        });

        return;
      }

      const componentParams = {
        rows: [this.matchedBadging],
        start_date: this.currentDate,
        date: this.currentDate,
        shop_id: this.currentShop.id,
        user_id: this.$route.params.user_id || null,
      };

      this.updateMatchedBadging({ componentParams })
        .then(() => {
          this.$skToast({
            message: this.$t('badgings.toolbar.validate_day_modal.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    submitShopPoste() {
      const newShopPoste = this.newBadgingShopPoste || this.lastUserShopPoste;

      if (this.matchedBadging.isAbsence) {
        this.selectStartsAt(this.matchedBadging.predictedStartsAt);
        this.selectEndsAt(this.matchedBadging.predictedEndsAt);
      } else if (!this.matchedBadging.shiftId) {
        this.selectStartsAt(this.matchedBadging.badgingStartsAt);

        if (this.matchedBadging.badgingEndsAt) {
          this.selectEndsAt(this.matchedBadging.badgingEndsAt);
        }
      }

      this.newBadgingShopPoste = newShopPoste;

      this.updateRealOrPlaceholderMatchedBadging({
        key: this.matchedBadgingKey,
        posteId: newShopPoste.id,
        posteName: newShopPoste.attributes.name,
        absenceCalculation: '',
        absencePosteId: null,
      });

      this.$refs['shop-poste-popover'].hide();
    },
    getErrorMessage() {
      let errorMessage;

      if (this.matchedBadgingMissingHoursField.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.hours_mandatory_field');
      }
      if (this.matchedBadgingMissingBreakField.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.break_mandatory_field');
      }
      if (this.matchedBadgingBreakInvalid.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.invalid_break');
      }
      if (this.matchedBadgingBreakNegative.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.break_negative');
      }
      if (this.matchedBadgingBreakLongerThanShift.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.break_superior_shift');
      }
      if (this.matchedBadgingPosteNotSet.includes(this.matchedBadging)) {
        errorMessage = this.$t('badgings.days.tabs.shifts.validate.errors.poste_missing');
      }

      return errorMessage;
    },
    vignetteStyle(color) {
      return {
        'background-color': color,
      };
    },
    showOrHideDetails() {
      this.detailsOpen = !this.detailsOpen;
    },
    getShiftPosteAttributes(shift) {
      return shift.relationships.previsionalPoste?.attributes ||
        shift.relationships.poste.attributes;
    },
    updateRealOrPlaceholderMatchedBadging(matchedBadgingValues) {
      this.$emit('turned-as-absence', matchedBadgingValues, this.isPlaceholderMatchedBadging);
    },
  },
};
</script>

<style lang="scss" scoped>
$input_height: 30px;
$hours_column_width: 15%;
$break_column_width: 6%;

.matched-badging__table__row {
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  ::v-deep .sk-input {
    height: $input_height;
  }

  ::v-deep .sk-input__input {
    height: $input_height;
  }

  ::v-deep .sk-input-group {
    width: 135px;
    height: $input_height;
    border: none;
  }

  ::v-deep .sk-input-group__prepend {
    height: $input_height !important;
  }

  ::v-deep .sk-circle-button__hover-layer{
    &:hover:not(.sk-circle-button--disabled,.sk-circle-button--loading) {
      background-color: unset !important;
    }
  }

  ::v-deep .sk-select__selected-option.sk-select__selected-option--filled {
    background-color: $sk-grey-5;
  }

  ::v-deep .sk-input-group--separated {
    background: unset;
  }

  ::v-deep .sk-input-group__append {
    height: $input_height !important;

    .sk-input-group__separator,
    .sk-input__input {
      padding-left: 0 !important;
    }
  }

  ::v-deep .sk-input::before {
    visibility: hidden;
  }

  &--validated {
    background-color: rgba($sk-blue-5, .2);

    .matched-badging__table__cell-actions.not-grouped {
      .badging-table-ignore-inner {
        background-color: transparent !important;

        &:hover {
          background-color: rgba(0, 0, 0, .05) !important;
        }
      }
    }
  }
}

.matched-badging__table__row__grouped-shifts-activity-icon {
  margin-bottom: 1px;
  height: 16px;
  width: 16px;
}

.matched-badging__table__row__notes {
  display: flex;
  justify-content: center;
}

.matched-badging__table__row__activity-icon {
  height: 16px;
  width: 16px;
}

.badging-table__label {
  height: 40px;
  display: flex;
  align-items: center;
}

.badging-table-ignore-inner {
  display:flex;
  justify-content: center;
  padding: 0px 12px 0px 12px;
  gap: 4px;
  border-radius: 14px 0px 0px 0px;
  left: 0px;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
    cursor: pointer;
    border-radius: 14px;
    padding: 0px 12px 0px 12px;
    gap: 4px;
  }

  @media screen and (min-width: 1530px) {
    left: 10px;
  }

  @media screen and (min-width: 1740px) {
    left: 20px;
  }
}

.badging-table-ignore {
  width:36px;
  height:15px;
}

.matched-badging__table__shop-icon {
  position: absolute;
  top: 20px;
  left: -20px;
}

.matched-badging__table__cell-validated {
  position: relative;
  width: 4%;
}

.matched-badging__table__cell-hours {
  width: 42px;
  height: 24px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;

  &-start {
    margin-right: 5px;

    &:hover {
      background-color: #cbede9;
    }

    &--selected {
      pointer-events: none;
      background-color: $sk-green-jungle;
      color: white;

      &:hover {
        pointer-events: none;
        background-color: $sk-green-jungle;
        color: white;
      }
    }
  }

  &-end {
    margin-left: 5px;

    &:hover {
      background-color: #d3d4f0;
    }

    &--selected {
      pointer-events: none;
      background-color: $sk-blue-indigo;
      color: white;

      &:hover {
        pointer-events: none;
        background-color: $sk-blue-indigo;
        color: white;
      }
    }
  }

  &-break {
    width: 50px;
    padding: 5px 0;
    justify-content: flex-start;

    &:hover {
      background-color: #fff0d1;
    }

    &--selected {
      background-color: #fdbf3f;
      color: white;
      justify-content: center;

      &:hover {
        background-color: #fdbf3f;
        color: white;
      }
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.matched-badging__validated-icon__wrapper {
  margin-top: 7px;
}

.matched-badging__validated-icon {
  z-index: 1;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid $sk-grey-10;
  border-radius: 50%;
  background-color: $sk-white;
  fill: $sk-grey-30;

  &.matched-badging__validated-icon--validated {
    fill: white;
    background-color: $sk-success;
    border: 1px solid $sk-success;
  }
}

.matched-badging__table__cell-employee-medallion {
  width: 45px;
}

.matched-badging__table__cell-employee-name {
  width: 10%;

  &__separator {
    border-right: 1px solid $sk-grey-10;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.matched-badging__table__cell-employee-name-noplanning {
  width: 17%;

  &__separator {
    border-right: 1px solid $sk-grey-10;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.matched-badging__table__cell-shift-hours {
  max-width: $hours_column_width;

  .matched-badging__table__cell-absence-name::after {
    color: $sk-blue;
    content: '*';
  }
}

.matched-badging__table__cell--location-icon {
  display: flex;
  justify-content: center;
}

.matched-badging__table__cell--location-icon-noplanning {
  display: flex;
  justify-content: flex-start;
}

.matched-badging__table__cell-badging-hours {
  width: $hours_column_width;
  padding-left: 20px;

  &--progess {
    margin-left: 10px;
    position: relative;
    width: 40px;
    height: 24px;
    border-radius: 2px;
    background: rgba(72, 77, 194, .15);

    &-status {
      position: absolute;
      background-color: $sk-blue-indigo;
      width: 8px;
      height: 24px;
      border-radius: 2px;
      left: 0;
      top: 0;
    }
  }
}

.matched-badging__table__cell-shift-break {
  width: $break_column_width;
  border-right: 1px solid $sk-grey-10;

  &--empty {
    padding-left: 15px;
  }
}

.matched-badging__table__cell-badging-break {
  width: $break_column_width;
  border-right: 1px solid $sk-grey-10;

  &--empty {
    padding-left: 15px;
  }
}

.matched-badging__table__cell-seperator-label {
  position: absolute;
  height: 2px;
  border-radius: 5px;
  width: 60px;
  bottom: 11px;
  z-index: 10;

  &--start {
    background-color: $sk-green-jungle;
  }

  &--end {
    left: 85px;
    background-color: $sk-blue-indigo;
  }

  &--break {
    background-color: #fdbf3f;
    width: 44px;
  }
}

.matched-badging__table__cell-selected-hours {
  position: relative;
  padding: 0 10px;
  width: $hours_column_width;

  ::v-deep .sk-input-group__separator {
    padding: 0;
  }

  ::v-deep .sk-dropdown__anchor {
    width: 60px;
  }
}

.unmatched-badging__table__cell-selected-hours {
  position: relative;
  padding: 0 10px;
  width: $hours_column_width;

  ::v-deep .sk-input-group__separator {
    padding: 0;
  }

  ::v-deep .sk-dropdown__anchor {
    width: 60px;
  }

  ::v-deep .matched-badging__table__cell-seperator-label-detailed {
    &--start {
      bottom: 3px !important;
      left: 24px !important;
    }

    &--end {
      left: 98px !important;
      bottom: 3px !important;
      background-color: $sk-blue-indigo;
    }

    &--break {
      background-color: #fdbf3f;
      width: 44px;
    }
  }
}

.matched-badging__table__cell-selected-break {
  width: $break_column_width;
  position: relative;

  .sk-input {
    width: 44px;
  }
}

.matched-badging__table__cell-selected-break-noplanning {
  border-right: 1px solid $sk-grey-10;
}

.matched-badging__table__cell-meal {
  .sk-input {
    width: 44px;
  }
}

.matched-badging__table__row--ignored {
  background-color: $sk-grey-5;

  ::v-deep .matched-badging__table__cell__poste-assign {
    .sk-button--tertiary.sk-button--small.sk-button--disabled.sk-text-small-semibold {
      background-color: unset;
    }
  }

  ::v-deep .badging-table-ignore-inner {
    background-color: unset;
  }
}

.matched-badging__table__row--ignored > td:not(
  .matched-badging__table__cell-actions):not(.caret-not-ignored) {
    opacity: .5;
  }

.matched-badging__table__row--ignored .badging-table__cell {
  pointer-events: none;
}

.badging-table__cell {
  ::v-deep .sk-input-group__separator {
    height: 100%;
    align-items: center;
    background: white;
  }
}

.unmatched-badging__table__cell--unmatched {
  display: none;
}

.matched-badging__table__cell-actions__menu {
  width: 200px;
  background: white;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
}

.matched-badging__table__cell-actions.grouped {
  padding-top: 10px !important;

  ::v-deep .sk-dropdown__anchor {
    text-align: left;
  }
}

.matched-badging__table__cell-actions.not-grouped {
  ::v-deep .sk-dropdown__anchor {
    text-align: right;
  }
}

.matched-badging__table__cell-actions__item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none !important;
  color: $sk-black;

  &-icon {
    margin-right: 15px;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  &:hover {
    background: $sk-grey-10;
  }
}

.matched-badging__table__cell-turned-absence {
  position: relative;
  border-right: 1px solid $sk-grey-5;
}

.matched-badging__table__cell-turned-absence--checked {
  padding-left: 0;
  padding-right: 8px;
}

.matched-badging__table__cell--turned-absence--border {
  border-right: 1px solid #ddd;
}

.matched-badging__table__cell--turned-absence {
  padding-left: 8px;
  padding-right: 0;

  ::v-deep .sk-input-group__separator {
    padding: 0;
  }

  ::v-deep .sk-dropdown__anchor
           .sk-input.sk-input--no-animation
           .sk-input__input {
    width: 60px;
    text-align: center;
    padding-left: 0;
  }
}

.matched-badging__table__cell--turned-absence-paid-shifts {
  padding-left: 11px;
  padding-right: 0;
}

.turned-absence__container--no-planning {
  display: flex;
  width: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  ::v-deep .sk-select {
    width: 95% !important;
    padding-right: 0;

    .sk-dropdown__anchor {
      padding: 3px;
    }
  }

  ::v-deep .sk-select__caret {
    margin: auto 5px auto auto;
  }

  ::v-deep .sk-select__selected-option,
  .sk-select__selected-option--filled-in,
  .sk-select__selected-option--disabled {
    background: white;
  }

  ::v-deep .sk-select__selected-option {
    height: $input_height;
  }

  ::v-deep .sk-input-group {
    width: 140px;
    padding: 3px;

    .sk-input__input {
      color: $sk-grey;
    }

    .sk-input-group__separator {
      background: white;
      color: $sk-grey;
      padding: 5px 5px 5px 0;
      height: $input_height;
    }

    .sk-input-group__prepend {
      .sk-input__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .sk-input-group__append {
      .sk-input__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.turned-absence__container {
  display: inline-flex;
  height: 42px;
  width: 100%;
  background-color: $sk-grey-5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  ::v-deep .sk-select {
    width: 50% !important;
    padding-right: 0;

    .sk-dropdown__anchor {
      padding: 5px;
    }
  }

  ::v-deep .sk-select__caret {
    margin: auto 5px auto auto;
  }

  ::v-deep .sk-select__selected-option,
  .sk-select__selected-option--filled-in,
  .sk-select__selected-option--disabled {
    background: white;
  }

  ::v-deep .sk-select__selected-option {
    background: white;
    height: 32px;
  }

  ::v-deep .sk-input-group {
    width: 140px;
    padding: 5px 0 0 6px;

    .sk-input__input {
      background: white;
      height: 32px;
    }

    .sk-input-group__separator {
      background: white;
      padding: 5px;
      height: 32px;
    }

    .sk-input-group__prepend {
      .sk-input__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .sk-input-group__append {
      .sk-input__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.matched-badging__table__cell__poste-assign {
  .matched-badging__shop-poste__button {
    width: 120px;
    margin-left: 9px;
    display: flex;
    justify-content: center;
  }

  ::v-deep .sk-popover {
    // This one is required so that the select dropdown doesnt go under popover
    z-index: 2;
  }
}

.shop-poste__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
  height: 45px;
}

.shop-poste-list__item {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-poste__select {
  padding: 20px;
  width: 320px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    margin-right: 15px;
  }
}

.shop-poste__color {
  width: 16px;
  height: 16px;
  display: inline-flex;
  margin-left: 10px;
  border-radius: 50%;
}

.shop-poste__selected-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  width: 100%;
}

.grouped-shifts__validated {
  padding-top: 12px !important;
}

.grouped-shifts__medaillon {
  padding-top: 15px !important;
}

.grouped-shifts__main-line-top {
  vertical-align: top;
}

.grouped-shifts__main-line-baseline {
  vertical-align: baseline;
}

.grouped-shifts__detail-font {
  font-size: $fs-text-s;
}

.grouped-shifts__previsional-shifts {
  margin-top: 15px;
  margin-bottom: 14px;
}

.grouped-shifts__remunerated-shifts {
  margin-top: 15px;
  margin-bottom: 14px;
  padding-left: 10px;
}

.grouped-shifts__remunerated-top {
  &--open {
    top: 3px;
  }

  &--closed {
    top: -2px;
  }
}

.grouped-shifts__poste-vignette {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: sticky;
  margin-right: 4px;
}

.grouped-shifts__poste {
  color: $sk-grey;
  display: inline-flex;
  align-items: center;
}

.grouped-shifts__separator {
  width: 130%;
  height: 0;
  left: 480px;
  top: 352px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid $sk-grey-10;
}

.caret-svg {
  fill: $sk-black;

  &.open {
    transform: rotate(-90deg);
  }

  &.closed {
    transform: rotate(90deg);
  }
}

.matched-badging__table__cell-seperator-label-detailed {
  position: absolute;
  height: 2px;
  border-radius: 5px;
  width: 60px;

  &--start {
    background-color: $sk-green-jungle;
    bottom: -2px !important;
  }

  &--end {
    left: 85px;
    background-color: $sk-blue-indigo;
  }

  &--break {
    background-color: #fdbf3f;
    width: 44px;
  }
}

.grouped-shifts__first-break {
  margin-top: 16px;
}

.grouped-shifts__second-break-and-more {
  margin-top: 14px;
}

.grouped-shifts__without-activity-icon {
  margin-top: 31px;
}

.grouped-shifts__first-payed-break {
  margin-top: 30px;
}

.grouped-shifts__turned-absence {
  &--on {
    padding-top: 10px !important;
  }

  &--off {
    padding-top: 18px !important;
  }
}

.grouped-shifts__simple-top {
  padding-top: 18px !important;
}

.grouped-shifts__box-top {
  padding-top: 14px !important;
}

.grouped-shifts__remunerated-break {
  padding-top: 12px !important;
}

.grouped-shifts__caret {
  pointer-events: auto;
  cursor: pointer;
  padding-top: 11px !important;
}

.matched-badging__table__cell-closed-by-backend__mark {
  padding-left: 2px;
}
</style>
