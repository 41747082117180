<template>
  <div class="period-lock__wrapper">
    <SkLoader
      v-if="isLoaderActive || isUpdating"
      size="small"
    />
    <span
      v-else
      class="period-lock"
    >
      <CloseLockIcon
        v-if="displayLockIcon"
        v-tooltip.bottom.offset="unlockTooltip"
        :data-test="`unlock__${dataTestSuffix}`"
        fill=""
        :class="periodLockClasses"
        height="100%"
        width="100%"
        @click.native="handleClickOnLock"
      />
      <OpenLockIcon
        v-else
        v-tooltip.bottom.offset="lockTooltip"
        :data-test="`lock__${dataTestSuffix}`"
        class="period-lock__icon"
        height="100%"
        width="100%"
        @click.native="() => updateLockValue(true)"
      />
    </span>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';

export default {
  name: 'PeriodLock',
  props: {
    lockValue: {
      type: String,
      required: true,
    },
    isLoaderActive: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    shopIds: {
      type: Array,
      required: true,
    },
    parentNodeId: {
      type: Number,
      default: undefined,
    },
    weekIndex: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['isGreenLockActivated']),
    isWeekLock() {
      return this.weekIndex !== undefined;
    },
    dataTestSuffix() {
      return this.isWeekLock ? `week-${this.weekIndex}` : 'period';
    },
    lockTooltip() {
      return this.isWeekLock ? this.$t('reports.planning_hours.lock_week') : this.$t('reports.lock.tooltip');
    },
    unlockTooltip() {
      if (this.isIntermediary) return this.lockTooltip;

      return this.isWeekLock ? this.$t('reports.planning_hours.unlock_week') : this.$t('reports.unlock.tooltip');
    },
    periodLockClasses() {
      return {
        'period-lock__icon': true,
        'period-lock--permanent': this.lockValue === 'permanent',
        'period-lock--intermediary': this.isIntermediary,
      };
    },
    displayLockIcon() {
      return this.lockValue?.length > 0;
    },
    isIntermediary() {
      return this.isGreenLockActivated && this.lockValue === 'intermediary';
    },
  },
  methods: {
    ...mapActions('report', ['updatePeriodLock']),
    ...mapMutations('report', ['updateWeekLockValue', 'updateWeekLockData']),
    handleClickOnLock() {
      this.updateLockValue(this.lockValue !== 'permanent');
    },
    updateLockValue(newLockValue) {
      if (this.isLoaderActive || this.isUpdating) return;

      this.isUpdating = true;

      this.trackUpdatePeriodLocked(newLockValue);

      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        shop_ids: this.shopIds,
        locked: newLockValue,
        isWeekLock: this.isWeekLock,
      };

      this.updatePeriodLock(params)
        .then(() => {
          if (this.isWeekLock) {
            this.updateWeekLockValue({ lockValue: newLockValue, weekIndex: this.weekIndex });
            this.emitOnRoot('week-lock-toggled');
          } else {
            this.$emit('set-period-locked', newLockValue ? 'permanent' : '');
            this.updateWeekLockData({ isLocked: newLockValue, startDate: this.startDate });
          }

          const actionType = newLockValue ? 'lock' : 'unlock';
          const successMessage = this.isWeekLock ?
            `reports.planning_hours.${actionType}_week_success` : `reports.${actionType}.success`;
          this.$skToast({
            message: this.$t(successMessage),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        })
        .finally(() => { this.isUpdating = false; });
    },
    trackUpdatePeriodLocked(newLockValue) {
      if (this.isWeekLock) {
        const action = newLockValue ? 'lock' : 'unlock';
        this.$skAnalytics.track(`${action}_week`);
      } else if (newLockValue) {
        this.$skAnalytics.track(
          'locked_plannings_report',
          {
            cluster_id: this.parentNodeId,
            source: 'Organisation/Network',
          },
        );
      } // No tracker for unlock period
    },
  },
};
</script>
<style lang="scss" scoped>
.period-lock__wrapper {
  margin-left: 16px;
  margin-top: 1px;
}

.period-lock {
  border-radius: 50%;
  display: block;
  width: 14px;
  height: 14px;

  &--permanent {
    fill: $sk-error;
  }

  &--intermediary {
    fill: $sk-success;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
    box-shadow: inset 15px 0 $sk-grey-10, 0 0 0 7px $sk-grey-10;
  }
}

.period-lock__icon {
  position: relative;
  bottom: 2px;
}
</style>
