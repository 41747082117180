<template>
  <div class="read-only-input__container">
    <div
      v-if="label"
      class="read-only-input-label"
    >
      {{ label }}
    </div>
    <span>
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ReadOnlyInput',
  props: {
    label: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.read-only-input__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 8px;
  height: 44px;
}

.read-only-input-label {
  font-size: 12px;
  color: $sk-grey-50;
}
</style>
