// This module is not used for the moment
// use it when we will use persmission from the jwt token

const initialState = {
  aggregatedLicenses: {},
  highestLicense: null,
  userLicenseForCluster: {},
  userLicenseForShop: {},
};

const mutations = {
  setCurrentUserLicenses: (state, payload) => {
    Object.assign(state, payload);
  },

  clearCurrentUserLicenses: state => {
    Object.assign(state, initialState);
  },
};

const getters = {
  getCurrentUserLicenseForClusterNode: state => clusterNodeId => (
    // If no license on selected node, fallback on highest license
    // (same behavior as app/controllers/v3/application_controller.rb)
    state.userLicenseForCluster[clusterNodeId] || state.highestLicense
  ),
  getCurrentUserLicenseForShop: state => shopId => (
    // If no license on selected node, fallback on highest license
    // (same behavior as app/controllers/v3/application_controller.rb)
    state.userLicenseForShop[shopId] || state.highestLicense
  ),
  isCurrentUserLicensesInitialized: state => Boolean(state.highestLicense),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
};
