<template>
  <div class="user-item__wrapper">
    <SkMedallion
      :text="userInitials(employee)"
      background-color="#2b66fe"
      color="white"
    />
    <div class="user-item__informations">
      <div class="user-item__full-name">
        {{ fullName(employee) }}
      </div>
      <div class="user-item__license__contract_type">
        <div>{{ license.attributes.licenseName }}</div>
        <div class="user-item__dot-separator" />
        <div>{{ contractType(contract.attributes.contractTypeId).name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'UserItem',
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['contract']),
    ...mapGetters('contracts', ['contractType']),
    ...mapGetters('selectedEmployee', ['license']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
  },
};
</script>

<style lang="scss" scoped>
.user-item__wrapper {
  display: flex;
  align-items: center;
}

.user-item__informations {
  margin-left: 10px;
}

.user-item__license__contract_type {
  display: flex;
  align-items: center;
  color: $sk-grey;
  font-size: $fs-text-s;
}

.user-item__dot-separator {
  background: $sk-grey-10;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: block;
  margin: 0 8px;
}
</style>
