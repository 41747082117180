<template>
  <div class="analytics-dashboard__number-comparison">
    <div :class="mainValueClasses">
      <span class="analytics-dashboard__number-comparison__main-value">
        {{ mainDisplayedNumber }}
      </span>
      <span class="analytics-dashboard__number-comparison__main-details">{{ unit }}</span>
    </div>
    <div class="analytics-dashboard__number-comparison__comparison">
      <span :class="percentClasses">
        {{ percentageValue }}%
      </span>
      <span class="analytics-dashboard__number-comparison__comparison-versus">
        {{ $t('analytics_dashboard.cards.versus') }}
      </span>
      <span class="analytics-dashboard__number-comparison__comparison-text">
        {{ comparisonLegend }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberComparison',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  computed: {
    mainValueClasses() {
      return ['analytics-dashboard__number-comparison__main',
        `analytics-dashboard__number-comparison__main-value-${this.performance}`,
      ];
    },
    percentClasses() {
      return [
        'analytics-dashboard__number-comparison__comparison-percent',
        `analytics-dashboard__number-comparison__comparison-percent-${this.performance}`,
      ];
    },
    mainDisplayedNumber() {
      // We use toLocaleString('fr') because we want the spacing between thousands
      // but replace the decimal separator as a dot for uniformity with the rest of the page
      return this.roundTwo(this.mainValue).toLocaleString('fr').replace(',', '.');
    },
    mainValue() {
      return (this.mainSerie && this.mainSerie.total) || 0;
    },
    comparisonValue() {
      return (this.comparisonSerie && this.comparisonSerie.total) || 0;
    },
    comparisonLegend() {
      return this.comparisonSerie.name;
    },
    differenceComputation() {
      if (this.series.length !== 2) return 0;
      return this.mainValue - this.comparisonValue;
    },
    differencePercentage() {
      if (this.series.length !== 2) return 0;
      const dividend = this.differenceComputation;
      const divisor = this.comparisonValue;

      return this.roundTwo(this.safeDivision(dividend, divisor));
    },
    performance() {
      if (this.isPositive) return 'positive';
      if (this.isNegative) return 'negative';

      return 'neutral';
    },
    performanceSymbol() {
      if (this.isNegative) return '-';

      return '+';
    },
    percentageValue() {
      if (this.mainSerie.total > 0 && this.comparisonSerie.total === 0) return '-';

      return `${this.performanceSymbol}${this.differencePercentage}`;
    },
    isPositive() {
      return this.differenceComputation > 0;
    },
    isNegative() {
      return this.differenceComputation < 0;
    },
    comparisonSerie() {
      return this.series[0];
    },
    mainSerie() {
      return this.series[1];
    },
  },
  methods: {
    safeDivision(dividend, divisor) {
      if (divisor === null || divisor === 0) return 0;
      return (dividend / divisor) * 100;
    },
    roundTwo(number) {
      const float = parseFloat(number);
      if (isNaN(float)) return '0';
      return (Math.round(Math.abs(float) * 100) / 100);
    },
    isNumberInteger(number) {
      return number % 1 === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-dashboard__number-comparison {
  flex-direction: column;
  text-align: center;

  &__main {
    font-size: 2.5em;
    font-weight: $fw-semi-bold;

    &-value {
      &-positive {
        color: $sk-success;
      }

      &-negative {
        color: $sk-error;
      }

      &-neutral {
        color: $sk-grey;
      }
    }

    &-details {
      font-size: $fs-display-xs;
      font-weight: 400;
    }
  }

  &__comparison {
    font-size: $fs-heading-s;

    &-percent {
      &-positive {
        color: $sk-success;
      }

      &-negative {
        color: $sk-error;
      }

      &-neutral {
        color: $sk-grey;
      }
    }

    &-versus {
      font-style: italic;
    }

    &-text {
      font-weight: $fw-semi-bold;
    }
  }
}
</style>
