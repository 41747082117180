import Requests from './Requests';

import RequestsPage from './shared/components/RequestsPage';
import RequestsSidebar from './shared/components/RequestsSidebar';

const userRequestsRoutes = [
  {
    path: '/v3/requests/:shop_id',
    name: 'requests',
    component: Requests,
    children: [
      {
        // "empty" child to handle redirection when going on this path
        path: 'leave-requests',
        name: 'leave_requests',
      },
      {
        path: 'leave-requests/own',
        name: 'leave_requests_own',
        components: {
          default: RequestsPage,
          sidebar: RequestsSidebar,
        },
      },
      {
        path: 'leave-requests/teams',
        name: 'leave_requests_teams',
        components: {
          default: RequestsPage,
          sidebar: RequestsSidebar,
        },
      },
      {
        // "empty" child to handle redirection when going on this path
        path: 'availability-requests',
        name: 'availability_requests',
      },
      {
        path: 'availability-requests/own',
        name: 'availability_requests_own',
        components: {
          default: RequestsPage,
          sidebar: RequestsSidebar,
        },
      },
      {
        path: 'availability-requests/teams',
        name: 'availability_requests_teams',
        components: {
          default: RequestsPage,
          sidebar: RequestsSidebar,
        },
      },
    ],
  },
];

export default userRequestsRoutes;
