<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.3061 35.4785H72.5282C75.6396 30.7146 75.1048 24.2657 70.9216 20.0825C66.1236 15.2845 58.3446 15.2845 53.5467 20.0825L52.4457 21.1834V15.1856C52.4459 6.79887 45.6471 0 37.2604 0C28.8737 0 22.0748 6.79887 22.0748 15.1856V21.7738H19.5462C8.75109 21.7738 0 30.5249 0 41.32C0 52.1151 8.75109 60.8662 19.5462 60.8662H77.3061C84.3168 60.8662 90 55.183 90 48.1723C90 41.1618 84.3168 35.4785 77.3061 35.4785V35.4785Z"
      fill="#EDF5FF"
    />
    <path
      d="M77.3059 35.4786H72.528C75.6394 30.7147 75.1046 24.2658 70.9214 20.0826C66.1234 15.2846 58.3444 15.2846 53.5465 20.0826L52.4455 21.1835V15.1857C52.4455 6.83695 45.7077 0.0642773 37.3735 0.00292969V60.8663H77.3059C84.3166 60.8663 89.9998 55.1831 89.9998 48.1724C89.9998 41.1618 84.3166 35.4786 77.3059 35.4786V35.4786Z"
      fill="#D6E8FD"
    />
    <path
      d="M33.368 33.8071C33.0579 33.7756 32.7452 33.7549 32.4277 33.7549C29.1754 33.7551 26.2629 35.4364 24.6367 38.2531L4.01356 73.9735C2.38741 76.7902 2.38741 80.1534 4.01356 82.9699C5.63971 85.7867 8.55241 87.4682 11.8047 87.4682H33.368V33.8071Z"
      fill="#FD657D"
    />
    <path
      d="M60.8426 73.9735L40.2194 38.2531C38.593 35.4366 35.6805 33.7549 32.4282 33.7549V87.4682H53.0514C56.3037 87.4682 59.2164 85.7865 60.8426 82.9699C62.4687 80.1534 62.4687 76.7902 60.8426 73.9735V73.9735Z"
      fill="#E43B54"
    />
    <path
      d="M33.0205 84.7177H9.86112C8.21159 84.7177 6.73432 83.8648 5.90938 82.4363C5.08461 81.0077 5.08461 79.3017 5.90938 77.8731L28.4771 38.7848C29.3018 37.3563 30.7791 36.5034 32.4286 36.5034C32.6287 36.5034 32.8262 36.5164 33.0203 36.541V31.2396C32.8243 31.2282 32.6271 31.2217 32.4284 31.2217C28.8696 31.2219 25.6825 33.0619 23.9029 36.1441L1.33555 75.2322C-0.443882 78.3144 -0.443882 81.9947 1.33555 85.077C3.11499 88.1592 6.30225 89.9994 9.86112 89.9994H33.0205V84.7177Z"
      fill="#E43B54"
    />
    <path
      d="M63.5217 75.2322L40.954 36.1441C39.1744 33.0619 35.9873 31.2219 32.4282 31.2217V36.5034C34.0779 36.5034 35.555 37.3563 36.38 38.7848L58.9476 77.8731C59.7724 79.3017 59.7724 81.0077 58.9476 82.4363C58.1227 83.8648 56.6454 84.7177 54.9961 84.7177H32.4282V89.9994H54.9959C58.5548 89.9994 61.742 88.1592 63.5217 85.077C65.3013 81.9949 65.3013 78.3145 63.5217 75.2322V75.2322Z"
      fill="#DF004B"
    />
    <path
      d="M32.4576 68.1809V52.5684H29.2505L30.3094 68.1809H32.4576Z"
      fill="#FED943"
    />
    <path
      d="M32.4577 72.1897C32.4475 72.1897 32.4379 72.1885 32.4277 72.1885C30.5146 72.1885 29.1479 73.4868 29.1479 75.2291C29.1479 76.9372 30.5146 78.338 32.4277 78.338C32.4379 78.338 32.4475 78.3368 32.4577 78.3366V72.1897Z"
      fill="#FED943"
    />
    <path
      d="M35.6055 52.5684H32.4282V68.1809H34.5466L35.6055 52.5684Z"
      fill="#FCBE2C"
    />
    <path
      d="M35.7073 75.2291C35.7073 73.4868 34.3408 72.1885 32.4277 72.1885V78.338C34.3408 78.338 35.7073 76.9372 35.7073 75.2291Z"
      fill="#FCBE2C"
    />
  </svg>
</template>

<script>
export default { name: 'ConflictIcon' };
</script>
