<template>
  <div class="data-display__wrapper">
    <div class="data-display__label">
      {{ label }}
    </div>
    <div class="data-display__value">
      {{ value }}
      <div
        v-if="withAction"
        class="data-display__action"
      >
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractAttribute',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    withAction: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-display__wrapper {
  display: flex;
  flex-direction: column;
}

.data-display__label {
  font-size: $fs-text-s;
  line-height: 15px;
  color: $sk-grey;
  margin-bottom: 5px;
}

.data-display__value {
  display: flex;
  font-size: $fs-text-l;
  line-height: 22px;
}

.data-display__action {
  display: flex;
  align-items: center;
}
</style>
