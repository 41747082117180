<template>
  <SkSelectV2
    v-model="userId"
    :options="formatedUsers"
    :no-results-label="$t('search_bar.no_result')"
    :label="$t('plannings.table.manage_shift_modal.tabs.absence.select_user')"
    :data-test="dataTest"
    @keydown.native.enter.stop=""
  >
    <template
      v-if="displayOptionsSubtext"
      #option="{ option }"
    >
      <div class="text-truncate">
        {{ option.text }}
        <div class="select-option__subtext text-truncate">
          {{ option.subtext }}
        </div>
      </div>
    </template>
  </SkSelectV2>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShiftEmployeeSelector',
  props: {
    canDisplayUnassignedShift: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      required: true,
    },
    displayOptionsSubtext: {
      type: Boolean,
      default: false,
    },
    isUpdateModal: {
      type: Boolean,
      required: true,
    },
    shiftUserId: {
      type: Number,
      default: null,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentUserId: null,
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName']),
    userId: {
      get() {
        return !this.currentUserId ? 'unassigned' : this.currentUserId;
      },
      set(newValue) {
        this.currentUserId = newValue === 'unassigned' ?
          null : newValue;
        this.$emit('update-user', this.currentUserId);
      },
    },
    formatedUsers() {
      const users = this.users
        .map(user => ({
          id: Number(user.id),
          text: this.fullName(user),
          ...(this.displayOptionsSubtext && { subtext: user.subtext }),
        }))
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));

      if (this.canDisplayUnassignedShift || (this.isUpdateModal && !this.currentUserId)) {
        users.unshift({ id: 'unassigned', text: this.$t('plannings.table.manage_shift_modal.tabs.shift.unassigned') });
      }

      return users;
    },
  },
  created() {
    // In position view -> select first employee by default
    if (!this.isUpdateModal) {
      this.userId = this.formatedUsers[0].id;
    } else {
      this.currentUserId = this.shiftUserId;
    }
  },
};
</script>

<style lang="scss" scoped>
.select-option__subtext {
  font-size: $fs-text-s;
  color: $sk-grey;
}
</style>
