import Vue from 'vue';

const trackBtnClick = vTrackSegment => () => {
  if (!vTrackSegment) return;

  Vue.prototype.$skAnalytics.track(vTrackSegment);
};

export default {
  bind(el, binding) {
    const vTrackSegment = binding.value;
    if (!vTrackSegment) return;

    el.addEventListener('click', trackBtnClick(vTrackSegment), true);
  },

  unbind(el) {
    el.removeEventListener('click', trackBtnClick(), true);
  },
};
