<template>
  <div class="text-document-templates__container">
    <div
      v-if="loadingFetch"
      class="text-document-templates__spinner"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <Summary v-if="hasTextDocumentTemplates" />
      <NoVisibleTextDocumentTemplates
        v-if="!hasTextDocumentTemplates"
        :tracking-options="trackingOptions"
      />
      <TextDocumentTemplatesTable
        v-else
        :text-document-templates="textDocumentTemplates"
        @text-document-template-enabled-changed="handleEnabledChanged"
        @delete-text-document-template="handleDelete"
      />
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import Summary from './Summary';
import NoVisibleTextDocumentTemplates from './NoVisibleTextDocumentTemplates';
import TextDocumentTemplatesTable from './TextDocumentTemplatesTable';

export default {
  name: 'TextDocumentTemplates',
  components: {
    Summary,
    NoVisibleTextDocumentTemplates,
    TextDocumentTemplatesTable,
  },
  computed: {
    ...mapState('textDocumentTemplates', ['loadingFetch', 'textDocumentTemplates']),
    ...mapGetters('textDocumentTemplates', ['hasTextDocumentTemplates']),
    trackingOptions() {
      return {
        submit: 'text_document_template_create',
      };
    },
  },
  created() {
    this.fetchTextDocumentTemplates();
  },
  methods: {
    ...mapActions('textDocumentTemplates', [
      'fetchTextDocumentTemplates',
      'updateTextDocumentTemplate',
      'deleteTextDocumentTemplate',
    ]),
    handleDelete(template) {
      this.deleteTextDocumentTemplate(template.id).then(() => {
        this.$skToast({
          message: this.$t('shop_settings.text_document_templates.deleted'),
          variant: 'success',
        });
      });
    },
    handleEnabledChanged(template) {
      const { enabled } = template.attributes;
      this.updateTextDocumentTemplate({ id: template.id, enabled });
    },
  },
};
</script>

<style scoped>
.text-document-templates__container {
  padding: 20px;
}
.text-document-templates__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
