<template>
  <div :class="['availability-wrapper', wrapperClasses]">
    <div class="icon-wrapper">
      <CrossedCalendarIcon
        v-if="availabilityStatus === 'unavailable'"
        width="12"
        height="12"
        style="padding: 1.5px;"
        fill="#d03e50"
      />
      <CheckedCalendarIcon
        v-else
        width="12"
        height="12"
        style="padding: 1.5px;"
        fill="#188377"
      />
      <span>{{ availabilitiesCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Availability',
  props: {
    availabilityStatus: {
      type: String,
      required: true,
    },
    availabilitiesCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        unavailable: this.availabilityStatus === 'unavailable',
        available: this.availabilityStatus === 'available',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.availability-wrapper {
  flex-grow: 1;
  width: 100%;
  padding: 4px;
  font-size: $fs-text-xs;
  line-height: 9px;

  &.available {
    background-color: $sk-success-10;
    color: $sk-success;
  }

  &.unavailable {
    background-color: $sk-error-10;
    color: $sk-error;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    column-gap: 2px;
  }
}
</style>
