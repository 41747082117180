import { httpClient } from '@skello-utils/clients';

const PER_PAGE = 10;

const initialState = {
  error: null,
  organisationCredentials: [],
  isCreatingCredentials: false,
  isFetchingCredentials: false,
  isFetchingGlobalIntegrations: false,
  isFetchingShopsCount: false,
  isFetchingSilaeMissingPinShops: false,
  silaeMissingPinShops: [],
  payIntegrations: [],
  customPayIntegrations: [],
  totalShopsCount: 0,
};

const mutations = {
  fetchOrganisationCredentialsSuccess(state, payload) {
    state.organisationCredentials = payload.data;
  },
  setIsCreatingCredentials(state, isCreating) {
    state.isCreatingCredential = isCreating;
  },
  setIsFetchingCredentials(state, isFetching) {
    state.isFetchingCredentials = isFetching;
  },
  setIsFetchingPayIntegrations(state, isFetching) {
    state.isFetchingGlobalIntegrations = isFetching;
  },
  setIsFetchingTotalShopsCount(state, isFetching) {
    state.isFetchingShopsCount = isFetching;
  },
  setIsFetchingSilaeMissingPinShops(state, isFetching) {
    state.isFetchingSilaeMissingPinShops = isFetching;
  },
  setCustomPayIntegrations(state, integrations) {
    state.customPayIntegrations = integrations;
  },
  setPayIntegrations(state, integrations) {
    state.payIntegrations = integrations;
  },
  setTotalShopsCount(state, shopsCount) {
    state.totalShopsCount = shopsCount;
  },
  setSilaeMissingPinShops(state, payload) {
    state.silaeMissingPinShops = payload.data;
  },
  addCredential(state, credential) {
    state.organisationCredentials.push(credential);
  },
  updateCredential(state, credential) {
    const index = state.organisationCredentials.findIndex(
      ({ attributes }) => attributes.name === credential.attributes.name,
    );

    if (index === -1) throw new Error('Integration not found');

    state.organisationCredentials.splice(index, 1, credential);
  },
  removeCredential(state, integrationName) {
    const index = state.organisationCredentials.findIndex(
      ({ attributes }) => attributes.name === integrationName,
    );

    if (index === -1) throw new Error('Integration not found');

    state.organisationCredentials.splice(index, 1);
  },
  requestError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchGlobalIntegrations({ commit }) {
    commit('setIsFetchingPayIntegrations', true);

    const params = {
      per_page: 1,
      report_nodes: true,
      shop_level: true,
    };

    try {
      const response = await httpClient.get('/v3/api/cluster_nodes', { params });

      commit('setCustomPayIntegrations', response.data.data[0].attributes.integrations);
      commit('setPayIntegrations', response.data.data[0].attributes.globalIntegrations);
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingPayIntegrations', false);
    }
  },
  async fetchMissingPinShops({ commit }) {
    commit('setIsFetchingSilaeMissingPinShops', true);

    const params = {
      only_missing_pin: true,
      skip_pagination: true,
    };

    try {
      const response = await httpClient.get('/v3/api/shops', { params });

      commit('setSilaeMissingPinShops', response.data);
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingSilaeMissingPinShops', false);
    }
  },
  async fetchOrganisationCredentials({ commit }) {
    commit('setIsFetchingCredentials', true);

    try {
      const response = await httpClient.get('/v3/api/organisation_credentials');
      commit('fetchOrganisationCredentialsSuccess', response.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingCredentials', false);
    }
  },
  async fetchTotalShopsCount({ commit }) {
    commit('setIsFetchingTotalShopsCount', true);

    try {
      const response = await httpClient.get('/v3/api/shops', {
        include_shops_where_employee: true,
        per_page: 1,
      });

      commit('setTotalShopsCount', response.data.meta.pagination.total_count);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsFetchingTotalShopsCount', false);
    }
  },
  async createOrganisationCredential({ commit }, params) {
    try {
      const response = await httpClient.post('/v3/api/organisation_credentials', {
        organisation_credential: params,
      });
      commit('addCredential', response.data.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async updateOrganisationCredential({ commit }, { name, ...params }) {
    try {
      const response = await httpClient.patch(`/v3/api/organisation_credentials/${name}`, {
        organisation_credential: params,
      });

      commit('updateCredential', response.data.data);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    }
  },
  async deleteOrganisationCredential({ commit }, { name }) {
    commit('setIsCreatingCredentials', true);

    try {
      const response = await httpClient.delete(`/v3/api/organisation_credentials/${name}`);

      commit('removeCredential', name);

      return response;
    } catch (error) {
      commit('requestError', error.response);

      throw error;
    } finally {
      commit('setIsCreatingCredentials', false);
    }
  },
};

const getters = {
  isIntegrationEnabled: state => integrationName => (
    state.organisationCredentials.some(({ attributes }) => attributes.name === integrationName)
  ),
  isIntegrationPending: state => integrationName => {
    const credential =
      state.organisationCredentials.find(({ attributes }) => attributes.name === integrationName);

    if (!credential) return false;

    return credential.attributes.status === 'pending';
  },
  isSilaeEvpExportEnabled: (state, _getters, rootState) => (
    state.payIntegrations.some(({ name }) => name.includes('silae')) ||
    state.customPayIntegrations.some(
      integration => rootState.config.config.pay_exports[integration].toLowerCase().includes('silae'),
    )
  ),
  paginatedSilaeMissingPinShops: state => {
    const paginatedShops = [];

    const lastPageShops = state.silaeMissingPinShops.reduce((currentPageShops, shop, index) => {
      currentPageShops.push(shop);

      if ((index + 1) % PER_PAGE === 0) {
        paginatedShops.push(currentPageShops);
        currentPageShops = [];
      }

      return currentPageShops;
    }, []);

    if (lastPageShops.length > 0) {
      paginatedShops.push(lastPageShops);
    }

    return paginatedShops;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
