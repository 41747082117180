const APP_VUE_JS_IDLE_TIME_IN_HOURS = 2;
const IDLE_EVENTS = ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'];

class SkIdleUserPageReloader {
  constructor() {
    this.idleTimerId = null;
  }

  start() {
    this.setIdleTimeout();

    IDLE_EVENTS.forEach(event => {
      window.addEventListener(event, this.handleUserMoved());
    });
  }

  stop() {
    IDLE_EVENTS.forEach(event => {
      window.removeEventListener(event, this.handleUserMoved());
    });

    this.cancelTimeout();
  }

  handleUserMoved() {
    this.cancelTimeout();
    this.setIdleTimeout();
  }

  setIdleTimeout() {
    this.idleTimerId = setTimeout(() => {
      window.location.reload(true);
    }, APP_VUE_JS_IDLE_TIME_IN_HOURS * 7200000);
  }

  cancelTimeout() {
    if (this.idleTimerId) {
      clearTimeout(this.idleTimerId);
    }

    this.idleTimerId = null;
  }
}

export const skIdleUserPageReloader = new SkIdleUserPageReloader();
