<template>
  <div id="documents">
    <AddDocuments
      v-if="canUploadDocuments"
      @upload="upload"
    />
    <div class="documents__header">
      <div class="header__title">
        {{ $t('profile.documents.title') }}
      </div>
      <div v-if="canUploadDocuments">
        <SkOroraButton
          icon="PlusSignV2Icon"
          variant="secondary"
          data-test="documents__open-document-modal"
          @click="showModal"
        >
          {{ $t('profile.documents.modal.title') }}
        </SkOroraButton>
      </div>
    </div>
    <div class="documents__content">
      <div
        v-if="loading"
        class="documents__content--loading"
      >
        <SkLoader size="x-large" />
      </div>
      <div v-else>
        <div
          v-for="document in combinedDocuments"
          :key="document.id"
          class="content__item"
        >
          <div class="item__label">
            {{ document.title }}
          </div>
          <div class="item__actions">
            <SkCircleButton
              icon="DownloadV2Icon"
              :loading="downloading === document.id"
              data-test="documents__download-document"
              @click="download(document)"
            />
            <SkCircleButton
              icon="TrashCanV2Icon"
              icon-color="#D03E50"
              :loading="deleting === document.id"
              data-test="documents__delete-document"
              @click="remove(document)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SvcDocumentsClient } from '@skello-utils/clients/svc_documents/client';
import { svcDocumentV2Client } from '@skello-utils/clients';
import { captureException } from '@sentry/vue';
import {
  SkOroraButton,
  SkCircleButton,
  MODAL_SHOW_EVENT,
  SkLoader,
} from '@skelloapp/skello-ui';
import {
  mapState,
  mapGetters,
} from 'vuex';
import AddDocuments from './modal/AddDocuments.vue';

const unwrap = document => ({
  id: document.id,
  userId: document.user_id,
  title: document.title,
  filename: document.filename,
  format: document.format,
  folder: document.folder,
  createdAt: document.created_at,
  expirationDate: document.expiration_date,
  svcDocV2Id: document.svc_doc_v2_id,
});

const transformV2DocumentToV1 = document => ({
  id: document.id,
  user_id: document.employeeId,
  title: document.title || document.fileName,
  filename: document.fileName,
  format: document.mimeType,
  folder: document.folderPath?.substring(1) || '',
  created_at: document.createdAt,
  expiration_date: document.expirationDate || null,
  deletedAt: document.deletedAt,
  svcDocV2Id: document?.svcDocV2Id || null,
});

export default {
  name: 'Documents',
  components: {
    SkLoader,
    SkOroraButton,
    SkCircleButton,
    AddDocuments,
  },
  data() {
    return {
      documents: [],
      documentsV2: [],
      loading: false,
      deleting: undefined,
      downloading: undefined,
      client: new SvcDocumentsClient(),
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentOrganisation', ['maxDocumentAllowedPerUser']),
    canUploadDocuments() {
      return this.isDevFlagEnabled('FEATUREDEV_DOCUMENTS_UPLOAD_PERMISSION') &&
      this.currentUser.relationships.highestLicense.attributes.canUploadSelfDocuments &&
      !this.quotaReached;
    },
    quotaReached() {
      if (this.maxDocumentAllowedPerUser === null) {
        return false;
      }

      return this.combinedDocuments.length >= this.maxDocumentAllowedPerUser;
    },
    // Used to combine svc documents v1 and V2 while migrating
    combinedDocuments() {
      if (!this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2')) return this.documents;

      const allDocuments = [...this.documents, ...this.documentsV2];

      // This makes sure that the documents don't appear twice from V1 and V2
      // when the document migration in ongoing or has been done
      // Every doc V1 that will have been migrated will contain a `svc_doc_v2_id`
      const filteredDocs = allDocuments.filter(doc => this.isDocumentV2(doc) || !doc.svcDocV2Id);

      // This makes sure the documents are sorted by createdAt desc when mixing documents from V1 and V2
      // Same condition as in svcDocumentV2 DocumentManager.ts
      return filteredDocs.sort((a, b) => {
        const dateA = this.isDocumentV2(a) ? new Date(a.createdAt) : new Date(a.created_at);
        const dateB = this.isDocumentV2(b) ? new Date(b.createdAt) : new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      });
    },
    isUploadingToDocumentsV2() {
      return this.isDevFlagEnabled('FEATUREDEV_UPLOAD_DOCS_TO_V2');
    },
  },
  created() {
    this.fetchAll();
  },
  mounted() {
    if (this.$route.query.redirectOrigin === 'payslip') {
      this.$router.replace({
        query: {
          ...structuredClone(this.$route.query),
          redirectOrigin: undefined,
        },
      });
      this.$skAnalytics.track('clicked_on_payslip_email');
    }
  },
  methods: {
    // This methods calls the svcDocumentV2Client to create a documment and get the S3 presigned url
    // NB: The document will be deleted by dynamoDB TTL after ~24h if the upload to S3 fails
    async createDocumentV2(file, title) {
      const response = await svcDocumentV2Client.document.create([
        {
          fileName: file.name,
          mimeType: file.type,
          employeeId: this.currentUser.id,
          title,
          creatorId: this.currentUser.id,
        },
      ]);

      if (!response || !response[0]) {
        this.$skToast({
          message: this.$t('profile.documents.modal.upload.error.default'),
          variant: 'error',
        });
      }

      return response[0];
    },
    async fetchAll() {
      this.loading = true;
      try {
        if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2')) {
          await this.fetchAllfromV2();
        }
        const response = await this.client.fetch(this.currentUser.id);

        this.documents = response.map(unwrap);
      } catch (e) {
        captureException(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchAllfromV2() {
      const v2Response = await svcDocumentV2Client.document
        .find({ employeeId: this.currentUser.id });
      this.documentsV2 = v2Response.documents.map(transformV2DocumentToV1).map(unwrap);
    },
    isDocumentV2(documentId) {
      return this.documentsV2.some(document => document.id === documentId);
    },
    async upload(payload) {
      this.loading = true;

      if (this.isUploadingToDocumentsV2) {
        await this.uploadToV2(payload.file, payload.name);
      } else {
        const extension = `.${ payload.file.name.split('.').pop()}`;
        const name = payload.name.includes(extension) ? payload.name : `${payload.name}${extension}`;

        await this.client.upload({
          title: name,
          creatorId: Number(this.currentUser.id),
          userId: Number(this.currentUser.id),
          file: payload.file,
          filename: payload.name,
        });
        await this.fetchAll();
      }

      this.loading = false;

      this.$skToast({
        message: this.$t('profile.documents.modal.upload.success'),
        variant: 'success',
      });
    },
    async uploadToV2(file, title) {
      try {
        const createResponse = await this.createDocumentV2(file, title);
        const url = createResponse.uploadUrl;

        await svcDocumentV2Client.document.upload(url, file);

        this.documentsV2 = [transformV2DocumentToV1(createResponse), ...this.documentsV2];
      } catch {
        this.$skToast({
          message: this.$t('profile.documents.modal.upload.error.default'),
          variant: 'error',
        });
      }
    },
    async download(doc) {
      this.downloading = doc.id;

      let response;

      if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(doc.id)) {
        response = await svcDocumentV2Client.document.download(doc.id);
      } else {
        response = await this.client.download(doc.id, this.currentUser.id);
      }
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');

      link.href = url;
      link.type = doc.format;
      link.download = doc.filename;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.downloading = undefined;
    },
    async remove(document) {
      this.deleting = document.id;

      try {
        if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(document.id)) {
          await svcDocumentV2Client.document.delete(document.id, 0);
        } else {
          await this.client.delete(document.id, this.currentUser.id);
        }

        const targetDocuments = this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(document.id) ?
          this.documentsV2 :
          this.documents;
        const index = targetDocuments.findIndex(doc => doc.id === document.id);

        if (index >= 0) {
          targetDocuments.splice(index, 1);

          this.$skToast({
            message: this.$t('profile.documents.modal.actions.delete.success'),
            variant: 'success',
          });
        }
      } catch (error) {
        const response = error.response;
        const key = response?.status === 403 ? 'permission' : 'unknown';

        this.$skToast({
          message: this.$t(`profile.documents.delete.error.${key}`),
          variant: 'error',
        });
      } finally {
        this.deleting = undefined;
      }
    },

    showModal(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'add-documents-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
  .documents__header {
    display: flex;
    margin-bottom: 32px;

    .header__title {
      flex: 1;
      font-size: 32px;
      font-weight: 600;
      line-height: 35.68px;
    }
  }

.documents__content {

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .content__item {
    border-bottom: 1px solid $sk-grey-10;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &:first-child {
      border-top: 1px solid $sk-grey-10;
    }
  }

  .item__label {
    flex: 1;
  }
}
</style>
