<template>
  <!-- eslint-disable  max-len -->
  <div class="settings__report-rules__container">
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.report_rules.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.report_rules.subtitle') }}
    </p>
    <div class="settings__report-rules__main-content">
      <div
        v-if="loadingFetch"
        class="settings__report-rules__spinner"
      >
        <SkLoader size="large" />
      </div>
      <div v-else>
        <MountingPortal
          v-if="currentShop.attributes.modulationMajoration"
          mount-to="#modals-portal"
          append
        >
          <RecalculateCountersModal
            ref="recalculateCountersModal"
            @submit="handleSubmit"
          />
        </MountingPortal>
        <div
          v-if="overHoursMajSlices && overHoursMajSlices.length > 0"
          class="sk-form-section"
        >
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.over_hours_maj_section_title') }}
            <span class="sk-form-section__title__addition">
              {{
                $t('shop_settings.tabs.report_rules.over_hours_maj_section_title_addition')
              }}
            </span>
          </h3>
          <ReportRuleRow
            v-for="(overHoursMajSlice, index) in overHoursMajSlices"
            :key="overHoursMajSlice.id"
            v-model="overHoursMajSlice.attributes.majoration"
            :title="$t('shop_settings.tabs.report_rules.over_hours_maj.title',
                       { cardinality: cardinalityToHuman(index + 1, 'over_hours_maj') })"
            :description="$t('shop_settings.tabs.report_rules.over_hours_maj.description',
                             { cardinality: cardinalityToHuman(index + 1, 'over_hours_maj').toLocaleLowerCase() })"
            :last="index === overHoursMajSlices.length - 1"
            @keyup.native.enter="handleSubmit"
          >
            <template #middleColumn>
              <SkInputGroup
                class="settings__report-rules__over-hours-input"
                separator
                unit="h"
              >
                <template #prepend>
                  <SkInput
                    v-model="overHoursMajSlice.attributes.startHour"
                    :errored="erroredOverHours[index]"
                    :error-message="errorOverHoursMessage[index]"
                    :max="String(overHoursMajSlice.attributes.endHour)"
                    step="1"
                    type="number"
                    @keyup="validateStartOverHours(overHoursMajSlice, index)"
                  />
                </template>
                <template> {{ $t('shop_settings.tabs.report_rules.over_hours_maj.separator') }}</template>
                <template #append>
                  <SkInput
                    v-model="overHoursMajSlice.attributes.endHour"
                    :errored="erroredOverHours[index]"
                    step="1"
                    type="number"
                    @keyup="validateEndOverHours(overHoursMajSlice, index)"
                  />
                </template>
              </SkInputGroup>
            </template>
          </ReportRuleRow>
        </div>

        <div
          v-if="complementaryHoursMajSlices && complementaryHoursMajSlices.length > 0"
          class="sk-form-section"
        >
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.complementary_hours_maj_section_title') }}
            <span class="sk-form-section__title__addition">
              {{
                $t('shop_settings.tabs.report_rules.complementary_hours_maj_section_title_addition')
              }}
            </span>
          </h3>
          <ReportRuleRow
            v-for="(complementaryHoursMajSlice, index) in complementaryHoursMajSlices"
            :key="complementaryHoursMajSlice.id"
            v-model="complementaryHoursMajSlice.attributes.majoration"
            :title="complementaryHoursMajSliceTitle(index)"
            :description="complementaryHoursMajSliceDescription(index)"
            :last="index === complementaryHoursMajSlices.length - 1"
            @keyup.native.enter="handleSubmit"
          >
            <template #middleColumn>
              <SkInputGroup
                v-if="complementaryHoursMajSlices.length > 1"
                class="settings__report-rules__comp-hours-input"
              >
                <SkInput
                  v-model="complementaryHoursMajSlice.attributes.trigger"
                  :errored="erroredTrigger[index]"
                  :error-message="errorTriggerMessage[index]"
                  :disabled="index === 1"
                  type="number"
                  step="1"
                  min="0"
                  @keyup="validateTrigger(complementaryHoursMajSlice.attributes.trigger, index)"
                />
                <template #append>
                  %
                </template>
              </SkInputGroup>
            </template>
          </ReportRuleRow>
        </div>

        <div class="sk-form-section">
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.night_hours_maj_section_title') }}
          </h3>
          <div
            v-for="(nightHoursMajSlice, index) in nightHoursMajSlices"
            :key="nightHoursMajSlice.id"
          >
            <ReportRuleRow
              v-model="nightHoursMajSlice.attributes.majoration"
              :title="nightHoursMajSliceTitle(index)"
              :description="nightHoursMajSliceDescription(index)"
              :last="index === nightHoursMajSlices.length - 1"
              @keyup.native.enter="handleSubmit"
            >
              <template #middleColumn>
                <SkInputGroup
                  class="settings__report-rules__night-hours-input"
                  separator
                >
                  <template #prepend>
                    <SkTimePicker
                      :interval-in-minutes="allowedMinuteInterval"
                      :value="numberToTime(nightHoursMajSlice.attributes.startHour)"
                      @input="setNightStartHour(index, ...arguments)"
                    />
                  </template>
                  <template> {{ $t('shop_settings.tabs.report_rules.over_hours_maj.separator') }}</template>
                  <template #append>
                    <SkTimePicker
                      :interval-in-minutes="allowedMinuteInterval"
                      :value="numberToTime(nightHoursMajSlice.attributes.endHour)"
                      @input="setNightEndHour(index, ...arguments)"
                    />
                  </template>
                </SkInputGroup>
              </template>
            </ReportRuleRow>
          </div>
        </div>

        <div
          v-if="typeof convention.attributes.sundayMaj !== 'undefined'"
          class="sk-form-section"
        >
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.sunday_maj_section_title') }}
          </h3>
          <ReportRuleRow
            v-model="convention.attributes.sundayMaj"
            :title="$t('shop_settings.tabs.report_rules.sunday_maj.title')"
            :description="$t('shop_settings.tabs.report_rules.sunday_maj.description')"
            last
            @keyup.native.enter="handleSubmit"
          />
        </div>

        <div class="sk-form-section">
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.holiday_maj_section_title') }}
          </h3>
          <ReportRuleRow
            v-model="convention.attributes.holidayMaj"
            :title="$t('shop_settings.tabs.report_rules.holiday_maj.title')"
            :description="$t('shop_settings.tabs.report_rules.holiday_maj.description')"
            last
            @keyup.native.enter="handleSubmit"
          />
        </div>

        <div
          v-if="typeof convention.attributes.firstMayMaj !== 'undefined'"
          class="sk-form-section"
        >
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.first_may_maj_section_title') }}
          </h3>
          <ReportRuleRow
            v-model="convention.attributes.firstMayMaj"
            :title="$t('shop_settings.tabs.report_rules.first_may_maj.title')"
            :description="$t('shop_settings.tabs.report_rules.first_may_maj.description')"
            last
            @keyup.native.enter="handleSubmit"
          />
        </div>

        <div
          v-if="typeof convention.attributes.cutLength !== 'undefined'"
          class="sk-form-section"
        >
          <h3 class="sk-form-section__title">
            {{ $t('shop_settings.tabs.report_rules.cut_length_section_title') }}
          </h3>
          <ReportRuleRow
            :title="$t('shop_settings.tabs.report_rules.cut_length.title')"
            :description="$t('shop_settings.tabs.report_rules.cut_length.description')"
            last
            @keyup.native.enter="handleSubmit"
          >
            <template #middleColumn>
              <SkInputGroup class="settings__report-rules__cut-length-input">
                <SkInput
                  v-model="convention.attributes.cutLength"
                  :errored="erroredCutLength"
                  :error-message="errorCutLengthMessage"
                  step="1"
                  type="number"
                  @keyup="validateCutLength(convention.attributes.cutLength)"
                />
                <template #append>
                  h
                </template>
              </SkInputGroup>
            </template>
          </ReportRuleRow>
        </div>
      </div>
    </div>
    <StickyBar
      :visible="!loadingFetch && showStickyBar"
      :disabled="erroredForm"
      :submit-spinner="loadingUpdate"
      :submit-button-label="$t('shop_settings.tabs.report_rules.actions.update.title')"
      :tracking-options="trackingOptions"
      container-scroll-id="shop-settings__container"
      class="settings__report-rules__container__sticky-bar"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { isPositiveInteger } from '@skello-utils/validators';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';

import StickyBar from '../../../shared/components/Stickybar';
import ReportRuleRow from './ReportRuleRow';
import RecalculateCountersModal from './RecalculateCountersModal';

export default {
  name: 'ReportRules',
  components: {
    ReportRuleRow,
    StickyBar,
    RecalculateCountersModal,
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToConvention) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashOriginalConventionData();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      errorCutLengthMessage: null,
      erroredCutLength: false,
      errorOverHoursMessage: {
        0: null,
        1: null,
        2: null,
      },
      erroredOverHours: {
        0: false,
        1: false,
        2: false,
      },
      errorTriggerMessage: {
        0: null,
        1: null,
      },
      erroredTrigger: {
        0: false,
        1: false,
      },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('reportRules', ['convention', 'loadingFetch', 'loadingUpdate', 'error']),
    ...mapState('reportRules', {
      overHoursMajSlices: state => state.convention.relationships &&
                                   state.convention.relationships.overHoursMajSlices,
      nightHoursMajSlices: state => state.convention.relationships &&
                                    state.convention.relationships.nightHoursMajSlices,
      complementaryHoursMajSlices: state => state.convention.relationships &&
                                    state.convention.relationships.complementaryHoursMajSlices,
    }),
    ...mapGetters('reportRules', ['needRecalculation', 'showStickyBar', 'unsavedChangesToConvention']),
    allowedMinuteInterval() {
      // When flexible convention active, we want to permit the selection of 1/2 hours
      return this.convention.attributes.flexible ? 30 : 0;
    },
    erroredForm() {
      return this.error || Object.values(this.erroredOverHours).includes(true) ||
             Object.values(this.erroredTrigger).includes(true) ||
             this.erroredCutLength;
    },
    trackingOptions() {
      return {
        update: 'report_rules_update',
      };
    },
  },
  watch: {
    currentShop() {
      this.fetchConvention();
    },
    convention(convention) {
      if (!convention.attributes.flexible) {
        this.$router.push({
          name: 'shop_settings_main_rules',
          params: { shop_id: this.currentShop.id },
        });
      }
    },
    error(error) {
      this.$skToast({
        message: error.message,
        variant: 'error',
      });
    },
  },
  created() {
    this.fetchConvention();
  },
  methods: {
    ...mapActions('reportRules', ['fetchConvention', 'updateReportRules']),
    ...mapMutations('reportRules', ['squashOriginalConventionData', 'squashConvention']),
    validateStartOverHours(value, index) {
      const { startHour, endHour } = value.attributes;

      this.erroredOverHours[index] = !isPositiveInteger(startHour);

      if (!this.erroredOverHours[index]) {
        this.erroredOverHours[index] = startHour > endHour;
      }

      if (startHour > endHour) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.above_end');
      } else if (!startHour) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.empty');
      } else if (!isPositiveInteger(startHour)) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.not_number');
      }
    },
    validateEndOverHours(value, index) {
      const { startHour, endHour } = value.attributes;

      this.erroredOverHours[index] = !isPositiveInteger(endHour);

      if (!this.erroredOverHours[index]) {
        this.erroredOverHours[index] = startHour > endHour;
      }

      if (startHour > endHour) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.below_start');
      } else if (!endHour) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.empty');
      } else if (!isPositiveInteger(endHour)) {
        this.errorOverHoursMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.hour_error_message.not_number');
      }
    },
    validateTrigger(value, index) {
      this.erroredTrigger[index] = !isPositiveInteger(value);

      if (!value) {
        this.errorTriggerMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.majoration_error_message.empty');
      } else if (!isPositiveInteger(value)) {
        this.errorTriggerMessage[index] =
          this.$t('shop_settings.tabs.report_rules.row.majoration_error_message.not_number');
      }
    },
    validateCutLength(value) {
      this.erroredCutLength =
        Number(value) < 0 ||
        Number(value) > 23 ||
        !isPositiveInteger(value);

      if (!value) {
        this.errorCutLengthMessage =
          this.$t('shop_settings.tabs.report_rules.row.cuts__error_message.empty');
      } else if (!isPositiveInteger(value)) {
        this.errorCutLengthMessage =
          this.$t('shop_settings.tabs.report_rules.row.cuts__error_message.not_number');
      } else if (Number(value) < 0 || Number(value) > 23) {
        this.errorCutLengthMessage =
          this.$t('shop_settings.tabs.report_rules.row.cuts__error_message.must_be_between');
      }
    },
    complementaryHoursMajSliceTitle(index) {
      if (this.complementaryHoursMajSlices.length <= 1) return null;

      return this.$t('shop_settings.tabs.report_rules.complementary_hours_maj.title',
        { cardinality: this.cardinalityToHuman(index + 1, 'complementary_hours_maj') });
    },
    complementaryHoursMajSliceDescription(index) {
      if (this.complementaryHoursMajSlices.length <= 1) {
        return this.$t('shop_settings.tabs.report_rules.complementary_hours_maj.no_trigger_description');
      }

      return this.$t('shop_settings.tabs.report_rules.complementary_hours_maj.description',
        { cardinality: this.cardinalityToHuman(index + 1, 'complementary_hours_maj').toLocaleLowerCase() });
    },
    nightHoursMajSliceTitle(index) {
      if (this.nightHoursMajSlices.length === 1) {
        return this.$t('shop_settings.tabs.report_rules.night_hours_maj.one_maj_title');
      }

      return this.$t('shop_settings.tabs.report_rules.night_hours_maj.title',
        { cardinality: this.cardinalityToHuman(index + 1, 'night_hours_maj') });
    },
    nightHoursMajSliceDescription(index) {
      if (this.nightHoursMajSlices.length === 1) {
        return this.$t('shop_settings.tabs.report_rules.night_hours_maj.one_maj_description');
      }

      return this.$t('shop_settings.tabs.report_rules.night_hours_maj.description',
        { cardinality: this.cardinalityToHuman(index + 1, 'night_hours_maj').toLocaleLowerCase() });
    },
    cardinalityToHuman(cardinality, sliceType) {
      switch (cardinality) {
        case 1:
          return this.$t(`shop_settings.tabs.report_rules.${sliceType}.cardinality.first`);
        case 2:
          return this.$t(`shop_settings.tabs.report_rules.${sliceType}.cardinality.second`);
        case 3:
          return this.$t(`shop_settings.tabs.report_rules.${sliceType}.cardinality.third`);
        default:
          return cardinality;
      }
    },
    numberToTime(number) {
      const hours = Math.floor(number);
      const minutes = (number - hours) * 60;
      return skDate().hour(hours).minute(minutes).format();
    },
    dateToNumber(date) {
      const hour = date.hour();
      const minutes = date.minute();
      return hour + minutes / 60;
    },
    setNightStartHour(index, newValue) {
      const date = skDate(newValue);
      this.nightHoursMajSlices[index].attributes.startHour = this.dateToNumber(date);
    },
    setNightEndHour(index, newValue) {
      const date = skDate(newValue);
      this.nightHoursMajSlices[index].attributes.endHour = this.dateToNumber(date);
    },
    handleCancel() {
      this.squashOriginalConventionData();
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'shop_settings_main_rules' });
      }
    },
    handleSubmit(event, recalculationDate) {
      if (!recalculationDate && this.$refs.recalculateCountersModal && this.needRecalculation) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'recalculate-counters-modal');
        return;
      }

      this.updateReportRules({ convention: this.convention, recalculationDate })
        .then(() => {
          this.squashOriginalConventionData();
          this.$skToast({
            message: this.$t('shop_settings.tabs.report_rules.actions.update.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.settings__report-rules__container {
  .sk-form-section__title__addition {
    font-weight: normal;
  }

  .settings__report-rules__main-content {
    margin-top: 30px !important;
    margin-bottom: 80px;
  }

  .settings__report-rules__spinner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }

  .settings__report-rules__over-hours-input {
    width: 180px;
    margin-left: -30px;
  }

  .settings__report-rules__comp-hours-input {
    width: 100px;
    margin-left: -30px;
  }

  .settings__report-rules__night-hours-input {
    width: 180px;
    margin-left: -30px;
  }

  .settings__report-rules__cut-length-input {
    width: 80px;
  }

  .sk-form__header__title {
    padding-top: 17px !important;
  }

  .sk-form-header__description {
    padding-top: 35px !important;
    padding-bottom: 10px !important;
    width: 350px;
  }

  .sk-form-body-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.settings__report-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
