<template>
  <div class="absences__table">
    <SkTable
      :columns="headers"
      :loading="shiftsLoading"
      :is-empty="isEmpty"
      @sort="handleSort"
    >
      <template #empty-state>
        <div class="absences__table__empty">
          <EmptyBadgingsLogo />
          <p class="absences__table__empty__message">
            {{ $t('badgings.days.table.empty_state.planned_absences') }}
          </p>
        </div>
      </template>
      <AbsenceRow
        v-for="absence in dayAbsencesPerDay()"
        :key="absence.id"
        :absence="absence"
      />
    </SkTable>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import { EmptyBadgingsLogo } from '@app-js/badgings/shared/svg';
import AbsenceRow from '@app-js/badgings/shared/components/AbsenceRow';

export default {
  name: 'Absences',
  components: { EmptyBadgingsLogo, AbsenceRow },
  data() {
    return {
      headers: [
        {
          title: this.$t('badgings.days.tabs.absences.headers.employees'),
          name: 'userName',
          sort: true,
          defaultSort: 'asc',
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.type'),
          name: 'absenceName',
          sort: true,
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.planned'),
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.total'),
          class: 'table-column__filled-section',
        },
      ],
    };
  },
  computed: {
    ...mapState('badgings', ['shiftsLoading']),
    ...mapGetters('badgings', ['dayAbsencesPerDay']),
    isEmpty() {
      return !this.dayAbsencesPerDay() || this.dayAbsencesPerDay().length === 0;
    },
  },
  methods: {
    ...mapMutations('badgings', ['sortAbsences']),
    handleSort(event, columnName, direction) {
      this.sortAbsences({ columnName, direction });
    },
  },
};
</script>
