<template>
  <div
    ref="bottomPanelPosteSimpleBar"
    class="workload-plan-bottom-panel__postes__wrapper"
  >
    <div
      v-for="(poste, index) in postes"
      :key="index"
      class="workload-plan-bottom-panel__postes__cell"
      @mouseenter="setOverflowTooltip(poste.attributes.name, index)"
    >
      <div
        :id="`workload_plan_bottom-panel_postes-${index}`"
        v-tooltip.bottom-start="overflowTooltip"
        class="workload-plan-bottom-panel__postes__cell--value"
      >
        {{ poste.attributes.name }}
      </div>
    </div>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'Postes',
  props: {
    postes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      overflowTooltip: null,
      posteScrollElement: null,
    };
  },
  computed: {
    isScrollableHorizontally() {
      const el = this.tabContentScrollElement;
      if (!el) return false;

      return el.scrollWidth > el.clientWidth;
    },
  },
  mounted() {
    this.listenOnRoot('workload-plan-vertical-scroll', this.handleVerticalScroll);

    const workloadPlanBottomPanelSimpleBar = new SimpleBar(this.$refs.bottomPanelPosteSimpleBar);
    this.posteScrollElement = workloadPlanBottomPanelSimpleBar.getScrollElement();
    this.posteScrollElement.addEventListener('scroll', this.handleEmitScroll);
  },
  methods: {
    setOverflowTooltip(name, index) {
      const el = document.getElementById(`workload_plan_bottom-panel_postes-${index}`);
      if (!el) return;

      this.$nextTick(() => {
        const isEllipsisActive = el.offsetWidth < el.scrollWidth;
        this.overflowTooltip = isEllipsisActive ? name : null;
      });
    },
    handleEmitScroll(event) {
      this.emitOnRoot('workload-plan-vertical-scroll', event);
    },
    handleVerticalScroll(event) {
      // Avoid setting scrollTop on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.bottomPanelPosteSimpleBar === event.srcElement) return;
      this.posteScrollElement.scrollTop = event.target.scrollTop;
    },
  },
};
</script>

<style scoped lang="scss">
.workload-plan-bottom-panel__postes {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 10px;
    max-height: 300px;

    /*
      Override the simple bar css to not display it
    */
    ::v-deep .simplebar-track.simplebar-vertical {
      /*
        add important here because the css is defined as style on the component,
        so it can not be override just by v-deep
      */
      visibility: hidden !important;
    }
  }

  &__cell {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-right: 8px;

    &--value {
      font-size: $fs-text-s;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
