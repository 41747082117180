<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#FAF5FF"
      border-color="#6B21A8"
      data-test="column__absence__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__absence__opened"
    >
      <div
        class="column__header hrt-absences-title"
        data-test="column__absence__close-button"
        @click="closeColumn"
      >
        {{ title }}
        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
        <SkOroraLink
          role="button"
          class="open-absences-explanation"
          data-test="absence-explanation-button"
          @click.stop="showAbsencePanel"
        >
          {{ $t('reports.absences_period.see_absences_types') }}
        </SkOroraLink>
      </div>

      <table class="absences__table">
        <thead class="column__title-wrap">
          <!-- Using first employee to create table headers -->
          <th
            v-for="absenceObject in sortedAbsencesObjectsPresent(firstContractAbsenceDetails)"
            :key="`headers-${absenceObject.absence}`"
            class="column__title column__title--open column__title--min_width"
          >
            {{ getAbsenceName(absenceObject.absence) }}
          </th>
          <th
            class="column__title column__title--open column__title--min_width"
          >
            {{ $t('reports.absences_period.lateness') }}
          </th>
        </thead>

        <tbody>
          <template v-for="employeeInfos in employeesInfos">
            <tr
              v-for="contractInfos in employeeInfos.contracts_infos"
              :key="contractInfos.contract.id"
              class="column__cell-wrap"
            >
              <td
                v-for="absenceObject in sortedAbsencesObjectsPresent(contractInfos.absence_details)"
                :key="`list-${absenceObject.absence}`"
                class="column__cell column__cell--open column__cell--min_width"
              >
                {{ displayedAbsenceValue(absenceObject) }}
              </td>
              <td class="column__cell column__cell--open column__cell--min_width">
                {{ +contractInfos.total_delays.toFixed(2) }}h
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'AbsencesColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    activeAbsences: {
      type: [Array, Object],
      default: () => [],
    },
    currentShop: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: this.$t('reports.absences_period.title'),
    };
  },
  computed: {
    firstContractAbsenceDetails() {
      return Object.values(
        Object.values(this.employeesInfos)[0].contracts_infos,
      )[0].absence_details;
    },
  },
  methods: {
    sortedAbsences(absences) {
      const sortedAbsences = [];

      Object.keys(absences).forEach(absenceName => {
        sortedAbsences.push(
          {
            absence: absenceName,
            data: absences[absenceName],
            unit: this.computeAbsenceUnit(absenceName),
          },
        );
      });

      return sortedAbsences;
    },
    computeAbsenceUnit(absenceName) {
      if (this.activeAbsences[absenceName]) return this.$t('reports.day_symbol');

      return this.$t('reports.hour_symbol');
    },
    absencesPresent(absence) {
      return this.activeAbsences[absence] !== undefined;
    },
    sortedAbsencesObjectsPresent(employeeAbsence) {
      return this.sortedAbsences(employeeAbsence)
        .filter(absenceObject => this.absencesPresent(absenceObject.absence));
    },
    displayedAbsenceValue(absence) {
      return `${Math.round(absence.data * 100) / 100}${absence.unit}`;
    },
    getAbsenceName(absenceKey) {
      const { absencesCountry } = this.currentShop.attributes;
      const i18nKey = this.getI18nAccountingAbsenceKey(
        absencesCountry,
        absenceKey,
      );

      return this.$t(i18nKey);
    },
    getI18nAccountingAbsenceKey(absencesCountry, absenceKey) {
      const parsedCountry = absencesCountry.toLowerCase();
      const parsedKey = absenceKey.toLowerCase();

      return `reports.absences.${parsedCountry}.${parsedKey}`;
    },
    showAbsencePanel() {
      this.emitOnRoot('open-absences-panel');
    },
  },
};
</script>

<style lang="scss" scoped>
.hrt-absences-title {
  display: block !important;
  min-width: 200px;
  border-top: 4px solid #6b21a8;
}

.open-absences-explanation {
  display: block;
  text-align: center;
  font-size: $fs-text-s;
  padding-top: 2px;
}

.absences__table {
  width: 300px;
}

.column__title--min_width,
.column__cell--min_width {
  min-width: 200px;
}

.column__title--fixed_width,
.column__cell--fixed_width,
.column__title-wrap-fixed_width {
  min-width: 200px;
  max-width: 200px;
}
</style>
