<template>
  <SkEventModal
    :flubber-color="$skColors.skBlue10"
    :header-icon="monochromeSkelloIcon"
    :header-icon-color="$skColors.skBlue50"
    submit-button-data-test="profile_continue"
    :on-submit-click="handleSubmit"
    :submit-btn-label="$t('onboarding_modal.profiling.submit_text')"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isSubmitLoading"
  >
    <template #content>
      <div class="profiling-step__title">
        {{ `${$t('onboarding_modal.profiling.title')} ${currentUser.attributes.firstName}` }}
      </div>
      <div class="profiling-step__text">
        {{ $t('onboarding_modal.profiling.text') }}
      </div>
      <div class="profiling-step__reasons">
        <SkOroraChipGroup
          v-model="profilingReasons"
          variant="rect"
        />
      </div>
    </template>
  </SkEventModal>
</template>

<script>

import { MonochromeSkelloIcon } from '@skelloapp/skello-ui';
import {
  mapGetters, mapActions, mapState,
} from 'vuex';

export default {
  name: 'ProfilingStep',
  components: {},
  props: {
    isInternalTesting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      monochromeSkelloIcon: MonochromeSkelloIcon,
      profilingReasons: [
        {
          key: 'team_planning',
          label: this.$t('onboarding_modal.profiling.reasons.team_planning'),
          isSelected: false,
          icon: 'CalendarV2Icon',
        },
        {
          key: 'monitor_time_and_absences',
          label: this.$t('onboarding_modal.profiling.reasons.monitor_time_and_absences'),
          isSelected: false,
          icon: 'PunchClockV2Icon',
        },
        {
          key: 'prepare_payroll',
          label: this.$t('onboarding_modal.profiling.reasons.prepare_payroll'),
          isSelected: false,
          icon: 'MoneyIcon',
        },
        {
          key: 'digitalize_rh_handling',
          label: this.$t('onboarding_modal.profiling.reasons.digitalize_rh_handling'),
          isSelected: false,
          icon: 'TeamV2Icon',
        },
        {
          key: 'monitor_performance',
          label: this.$t('onboarding_modal.profiling.reasons.monitor_performance'),
          isSelected: false,
          icon: 'ChartV2Icon',
        },
        {
          key: 'communicate_with_employees',
          label: this.$t('onboarding_modal.profiling.reasons.communicate_with_employees'),
          isSelected: false,
          icon: 'ChatBubbleIcon',
        },
      ],
      isSubmitLoading: false,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'sector']),
    ...mapState('currentUser', ['currentUser']),

    isSubmitDisabled() {
      return !this.profilingReasons.some(reason => reason.isSelected);
    },
  },
  mounted() {
    if (!this.isInternalTesting && this.currentUser.attributes.isOnboardingEmployeeFilled) {
      this.done();
    }
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUser']),

    handleSubmit() {
      if (this.isSubmitLoading) return;
      if (this.isInternalTesting) {
        this.done();
        return;
      }

      this.isSubmitLoading = true;
      const onboardingReasons = this.profilingReasons
        .filter(reason => reason.isSelected)
        .map(reason => reason.key);

      this.updateCurrentUser({ onboarding_reasons: onboardingReasons }).then(() => {
        this.done();
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isSubmitLoading = false;
      });
    },
    done() {
      this.$skAnalytics.track('onboarding_submit_profiling_step');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
  .profiling-step__title {
    font-family: Gellix;
    font-size: $fs-heading-l;
    font-weight: $fw-semi-bold;
    height: 36px;
    line-height: 36px;
  }

  .profiling-step__text {
    width: 780px;
    color: $sk-grey-50;
    font-family: Gellix;
    font-size: $fs-text-l;
    font-weight: $fw-regular;
    padding-top: 16px;
    padding-bottom: 32px;
    line-height: 18px;
  }

  .profiling-step__reasons {
    padding-bottom: 16px;
  }
</style>
