<script>
import { mapState } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import GenericDropdownItemVue from './GenericDropdownItem';

export default {
  name: 'CreateFromTemplateAction',
  extends: GenericDropdownItemVue,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.create_from_template.label');
    },
    icon() {
      return 'NewV2Icon';
    },
    visibleIn() {
      return 'dropdown';
    },
    disabled() {
      return this.currentLicense.attributes.canCreateShifts === false;
    },
    tooltip() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.create_from_template.tooltip.forbidden');
    },
    isVisible() {
      return true;
    },
    dataTest() {
      return 'planning-toolbar__create-from-template';
    },
  },
  methods: {
    handler(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-from-template-modal');
    },
  },
};
</script>
