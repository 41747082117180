<template>
  <div>
    <div class="employees__content-wrapper">
      <ProfileSection />
      <PrimaryShopSection />
      <SecondaryClusterNodeSection v-if="clustersOrganisation" />
      <SecondaryShopSection v-else />
    </div>

    <StickyBar
      :visible="employeeIsLoaded && hasChangesOnPersonalInfo"
      :submit-spinner="updatingEmployee"
      :submit-button-label="$t('employees.sticky_bar.submit')"
      :disabled="inputError"
      :tracking-options="{ update: 'update_employee' }"
      class="employee-sticky-bar"
      container-scroll-id="employees__container"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { captureException } from '@sentry/vue';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';
import ProfileSection from './ProfileSection';
import SecondaryShopSection from './SecondaryShopSection';
import PrimaryShopSection from './PrimaryShopSection';
import SecondaryClusterNodeSection from './SecondaryClusterNodeSection';
import StickyBar from '../../../shared/components/Stickybar';

export default {
  name: 'Personal',
  components: {
    ProfileSection,
    PrimaryShopSection,
    SecondaryShopSection,
    SecondaryClusterNodeSection,
    StickyBar,
  },
  mixins: [payrollPreparationMixins],
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToEmployee) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.resetToOriginalState();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      hasMissingFieldsBeforeEdit: this.hasMissingMandatoryFieldsForPayroll('user_personal'),
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('selectedEmployee', ['updatingEmployee', 'inputError', 'employee']),
    ...mapGetters('selectedEmployee', [
      'employeeIsLoaded',
      'unsavedChangesToEmployee',
      'hasChangesOnPersonalInfo',
    ]),
    ...mapGetters('employees', ['displayPayrollPreparation']),
    clustersOrganisation() {
      return this.currentOrganisation.attributes.clusters;
    },
  },
  methods: {
    ...mapActions('selectedEmployee', ['updateSelectedEmployeePersonalInfo', 'resetToOriginalState']),
    ...mapActions('employees', ['reloadSidebar']),
    handleSubmit() {
      this.updateSelectedEmployeePersonalInfo()
        .then(() => {
          try {
            this.$svcEvents.create(
              EVENT_SUBTYPE_ACTION.EMPLOYEE_LICENSE_UPDATE,
              { user: this.employee },
            );
          } catch (error) {
            captureException(error);
          }

          const hasMissingFieldsAfterEdit = this.displayMissingInformationNotification('user_personal');
          if (this.hasMissingFieldsBeforeEdit && this.displayPayrollPreparation) {
            const hasRedirected = this.redirectToNextTabIfComplete('user_personal', () => this.$nextTick(this.showSuccessToaster));
            if (hasRedirected) return;

            if (this.displayPayrollPreparation && !hasMissingFieldsAfterEdit) {
              this.redirectToNextEmployee();
              this.reloadSidebar({
                cluster_node_id: this.navContext.clusterNodeId,
                with_missing_attributes: true,
                silent: true,
              });
            }
          }
          this.showSuccessToaster();
          this.hasMissingFieldsBeforeEdit = this.displayMissingInformationNotification('user_personal');
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.update_employee.error_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    showSuccessToaster() {
      this.$skToast({
        message: this.$t('employees.update_employee.success_message'),
        variant: 'success',
        containerId: 'employees__container',
      });
    },
  },
};
</script>
