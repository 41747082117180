<template>
  <div class="accept-invitation">
    <h1 class="accept-invitation__title">
      {{ $t('accept_invitation.title') }}
    </h1>

    <Banner
      :hidden="!showBanner"
      :message="bannerMessage"
      :type="bannerType"
    />

    <form
      class="accept-invitation__form"
      @submit.prevent
    >
      <PasswordConfirmation
        ref="passwordConfirmation"
        v-model="password"
        :password-placeholder="$t('accept_invitation.password')"
        :confirm-password-placeholder="$t('accept_invitation.confirm_password')"
        @validation-updated="handleValidationUpdate"
      />

      <div class="accept-invitation__accept-privacy-policy">
        <CheckBox
          v-model="acceptPrivacyPolicy"
          class="accept-invitation__accept-privacy-policy__checkbox"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t('accept_invitation.accept_privacy_policy')" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </form>

    <SkOroraButton
      size="large"
      class="accept-invitation__submit-btn"
      :disabled="!newPasswordsAreValid || !acceptPrivacyPolicy"
      :loading="loading"
      @click="handleSubmit"
    >
      {{ $t('accept_invitation.submit') }}
    </SkOroraButton>
  </div>
</template>

<script>
import {
  Banner,
  CheckBox,
} from '@app-js/static/shared/components/ui';
import { SkOroraButton } from '@skelloapp/skello-ui';
import PasswordConfirmation from '@app-js/shared/components/PasswordConfirmation';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'AcceptInvitation',
  components: {
    Banner,
    PasswordConfirmation,
    CheckBox,
    SkOroraButton,
  },
  data() {
    return {
      loading: false,
      password: '',
      newPasswordsAreValid: false,
      invitationToken: null,
      acceptPrivacyPolicy: false,
      bannerType: 'error',
      formSuccessfullySent: false,
      formError: false,
    };
  },
  computed: {
    showBanner() {
      return this.formSuccessfullySent || this.formError;
    },
    bannerMessage() {
      if (this.formSuccessfullySent) {
        return this.$t('accept_invitation.update.success');
      }

      return this.$t('accept_invitation.update.error');
    },
  },
  mounted() {
    this.invitationToken = this.$route.query.invitation_token;
  },
  methods: {
    handleValidationUpdate(isValid) {
      this.newPasswordsAreValid = isValid;
    },
    async handleSubmit() {
      this.loading = true;

      try {
        const invitationResponse = await httpClient.put('/users/invitation', {
          user: {
            password: this.password,
            password_confirmation: this.$refs.passwordConfirmation.confirmPassword,
            invitation_token: this.invitationToken,
          },
        });

        this.bannerType = 'success';
        this.formSuccessfullySent = true;
        this.formError = false;

        const homeUrlResponse = await httpClient.get('/v3/home_url', {
          params: {
            invitation: invitationResponse.data.token,
          },
        });
        window.location.replace(homeUrlResponse.data.url);
      } catch (err) {
        this.formSuccessfullySent = false;
        this.bannerType = 'error';
        this.formError = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.sk-button__content-wrapper {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.accept-invitation {
  max-width: 570px;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Gellix;

  &__title {
    font-family: Gellix;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 12px;
  }

  &__submit-btn {
    margin-top: 40px;
    width: calc(100% - 40px);
  }

  &__accept-privacy-policy {
    width: 100%;
    font-size: $fs-text-m;
    margin-top: 40px;
    display: flex;
    align-items: center;

    &__checkbox {
      margin-right: 15px;
    }
  }
}
</style>
