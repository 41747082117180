<template>
  <!-- eslint-disable max-len -->
  <div class="full-contract-data__wrapper">
    <div class="full-contract__title">
      <div class="full-contract__title--bold">
        <span v-if="isExtendedView">
          {{ $t('employees.tabs.contracts.full_contract.data.contract.titles.contract_data') }}
        </span>
        <span v-else>
          {{ $t('employees.tabs.contracts.full_contract.data.contract.titles.contract_info') }}
        </span>
      </div>
      <div
        v-if="!isContractReadOnlyView"
        class="full-contract__title__mandatory-warning"
      >
        {{ $t('employees.tabs.contracts.full_contract.data.mandatory_fields') }}
      </div>
    </div>
    <div
      v-if="displayMissingInformationNotification('user_contracts')"
      class="full-contract__notification"
    >
      <MissingInformationNotification />
    </div>
    <div class="full-contract__content__form">
      <SkForm
        :title="$t('employees.tabs.contracts.full_contract.data.contract.type.mandatory')"
        split
      >
        <div
          v-if="isContractReadOnlyView"
          class="row sk-form__row"
        >
          <div class="col-6 full-contract__readonly">
            {{ displayedContractTypeName }}
          </div>
          <div class="col-6 full-contract__readonly">
            {{ displayedTimeBasisCategory }}
          </div>
          <div
            v-if="isFixedTermContractReasonEnabled && isContractFixedTerm"
            class="col-12 full-contract__readonly"
          >
            {{ displayedFixedTermContractReason }}
          </div>
        </div>
        <template v-else>
          <div class="row sk-form__row">
            <div class="col-6">
              <SkSelectV2
                v-model="contractTypeId"
                :options="contractTypes"
                :disabled="!canEditContract"
                :label="$t('employees.tabs.contracts.full_contract.data.contract.type.label')"
                :search-placeholder="$t('labels.search')"
                :no-results-label="$t('search_bar.no_result')"
                :is-highlighted="showHighlightForField(contractTypeId)"
              />
            </div>
            <div
              v-if="isExtendedView"
              class="col-6"
            >
              <SkSelectV2
                v-model="timeBasisCategory"
                :options="timeBasisCategories"
                :disabled="!canEditContract"
                :label="$t('employees.tabs.contracts.full_contract.data.contract.time_basis_category.label')"
                :is-highlighted="showHighlightForField(timeBasisCategory)"
              />
            </div>
          </div>
          <div
            v-if="isExtendedView && isFixedTermContractReasonEnabled && isContractFixedTerm"
            class="row sk-form__row"
          >
            <div class="col-12">
              <SkSelectV2
                v-model="fixedTermContractReason"
                :disabled="!canEditContract || isContractFixedTermSeasonal"
                :label="$t('employees.tabs.contracts.full_contract.data.contract.fixed_term_contract_reason.label')"
                :no-results-label="$t('search_bar.no_result')"
                :options="fixedTermContractReasons"
              >
                <template #selected-option="{ value }">
                  <span class="text-truncate">
                    {{ $t(`contract.fixed_term_contract_reasons.${value}`) }}
                  </span>
                </template>

                <template #option="{ option }">
                  <div class="text-truncate">
                    {{ option.text }}
                  </div>
                </template>
              </SkSelectV2>
            </div>
          </div>
        </template>
      </SkForm>
    </div>

    <div
      v-if="isContractIntern && isExtendedView"
      class="full-contract__content__form"
    >
      <SkForm
        :title="$t('employees.tabs.contracts.full_contract.data.contract.tutor.label')"
        split
      >
        <div class="row">
          <div
            v-if="isContractReadOnlyView"
            class="col-12 full-contract__readonly"
          >
            {{ formatAttribute(tutor) }}
          </div>
          <div
            v-else
            class="col-12"
          >
            <SkInput
              v-model="tutor"
              :disabled="!canEditContract"
              :label="$t('employees.tabs.contracts.full_contract.data.contract.tutor.label')"
            />
          </div>
        </div>
      </SkForm>
    </div>

    <div
      v-if="isExtendedView"
      class="full-contract__content__form"
    >
      <SkForm
        :title="$t('employees.tabs.contracts.full_contract.data.contract.poste_name.label')"
        split
      >
        <template #title-icon>
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.poste_name.tooltip')"
            width="18"
            height="18"
          />
        </template>
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-12 full-contract__readonly">
            {{ formatAttribute(posteName) }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-12">
            <SkInput
              v-model="posteName"
              :disabled="!canEditContract"
              :label="$t('employees.tabs.contracts.full_contract.data.contract.poste_name.label')"
              :is-highlighted="showHighlightForField(posteName)"
            />
          </div>
        </div>
      </SkForm>
      <SkForm
        v-if="isUpgradeMultiContractsActivated"
        :title="$t('employees.tabs.contracts.full_contract.data.arrival_date.title')"
        split
      >
        <template #title-icon>
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.arrival_date.tooltip')"
            width="18"
            height="18"
          />
        </template>
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-12 full-contract__readonly">
            {{ displayAsDate(arrivalDate) }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-12">
            <SkDatePicker
              v-model="arrivalDate"
              :disabled="!canEditHrData"
              :lang="$i18n.locale"
              :placeholder="$t('employees.tabs.contracts.full_contract.data.arrival_date.placeholder')"
              :is-highlighted="showHighlightForField(arrivalDate)"
              width="100%"
            />
          </div>
        </div>
      </SkForm>
    </div>

    <div class="full-contract__content__form">
      <SkForm
        :title="startDateLabel"
        split
      >
        <template
          v-if="!isUpgradeMultiContractsEnabled"
          #title-icon
        >
          <CircledQuestionMarkIcon
            v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.start_date.tooltip')"
            width="18"
            height="18"
          />
        </template>
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-6 full-contract__readonly">
            {{ fullStartDate }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-6">
            <SkDatePicker
              v-model="startDate"
              :disabled-date="isDateUnavailableAsNewContractStartDate()"
              :placeholder="startDatePlaceholder"
              :editable="false"
              :loading="loading"
              :lang="$i18n.locale"
              :is-highlighted="showHighlightForField(startDate)"
              clearable
              hide-footer
            />
          </div>
          <div class="col-6">
            <SkTimePicker
              v-if="startDate"
              v-model="startDateHour"
              class="full-contract_start_date"
              :disabled="!canEditContract"
              :editable="false"
              :clearable="false"
              :interval-in-minutes="15"
            />
          </div>
        </div>
      </SkForm>
    </div>

    <div
      v-if="isExtendedView"
      class="full-contract__content__form"
    >
      <SkForm
        :title="$t('employees.tabs.contracts.full_contract.data.contract.trial_end.label')"
        split
      >
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-6 full-contract__readonly">
            {{ displayAsDate(trialEnd) }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-6">
            <SkDatePicker
              v-model="trialEnd"
              class="full-contract_trial_date"
              :disabled="!canEditContract"
              :errored="isTrialEndErrored"
              :error-message="$t('employees.tabs.contracts.full_contract.data.contract.trial_end.constraint')"
              :placeholder="$t('employees.tabs.contracts.full_contract.data.contract.trial_end.input_label')"
              :editable="false"
              :disabled-date="notBeforeStartDate"
              :lang="$i18n.locale"
              clearable
            />
          </div>
          <div
            v-show="isContractTrialEndSoon"
            class="col-6"
          >
            <div class="sk-form__row__icon-wrapper">
              <CircledBellIcon
                v-tooltip.top="
                  $t('employees.tabs.contracts.full_contract.data.contract.trial_end.icon_tooltip')
                "
              />
            </div>
          </div>
        </div>
      </SkForm>
    </div>

    <div class="full-contract__content__form">
      <SkForm
        :title="endDateContractLabel"
        split
      >
        <div
          v-if="isContractReadOnlyView"
          class="row"
        >
          <div class="col-6 full-contract__readonly">
            {{ displayAsDate(endDate) }}
          </div>
        </div>
        <div
          v-else
          class="row"
        >
          <div class="col-6">
            <SkInputGroup
              :errored="isEndDateErrored"
              :error-message="endDateErrorMessage"
              class="full-contract__end-date-group"
            >
              <SkDatePicker
                v-model="endDate"
                v-tooltip.bottom="endDateTooltipText"
                :disabled="!canEditContract || !isStartDateDefined"
                :placeholder="$t('employees.tabs.contracts.full_contract.data.contract.end_date.label')"
                :editable="false"
                :disabled-date="isDateUnavailableAsContractEndDate()"
                :errored="isEndDateErrored"
                :loading="loading"
                :lang="$i18n.locale"
                :is-highlighted="showHighlightForField(!isEndDateErrored)"
                clearable
                hide-footer
              />
            </SkInputGroup>
          </div>
        </div>
      </SkForm>
    </div>

    <div v-show="isExtendedView">
      <div
        v-if="isContractReadOnlyView"
        class="full-contract__content__form"
      >
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.contract.status.label')"
          class="full-contract__readonly-status-block"
          split
        >
          <template #title-icon>
            <CircledQuestionMarkIcon
              v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.status.tooltip')"
              width="18"
              height="18"
            />
          </template>
          <div class="row">
            <div class="col-12 full-contract__readonly">
              {{ displayedStatus }}
            </div>
          </div>
        </SkForm>
        <SkForm
          class="full-contract__readonly-status-block"
          split
        >
          <div class="row">
            <div class="col-12 full-contract__readonly">
              {{ displayLevelAndStep }}
            </div>
          </div>
        </SkForm>
      </div>

      <div
        v-else
        class="full-contract__content__form"
      >
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.contract.status.label')"
          split
        >
          <template #title-icon>
            <CircledQuestionMarkIcon
              v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.status.tooltip')"
              width="18"
              height="18"
            />
          </template>
          <div class="row sk-form__row">
            <div class="col-6">
              <SkSelectV2
                v-model="status"
                :options="statuses"
                :disabled="!canEditContract"
                :label="$t('employees.tabs.contracts.full_contract.data.contract.status.label')"
                :is-highlighted="showHighlightForField(status)"
              >
                <template #selected-option="{ value }">
                  <span
                    id="contract-status"
                    v-tooltip.top="statusTooltip(value)"
                    class="text-truncate"
                  >
                    {{ $t(`contract.status.${value}`) }}
                  </span>
                </template>

                <template #option="{ option }">
                  <div class="step-form__select-text--half-width text-truncate">
                    {{ option.text }}
                  </div>
                </template>
              </SkSelectV2>
            </div>
          </div>
          <div
            v-if="areLevelsDisplayed"
            class="row"
          >
            <div class="col-6">
              <SkSelectV2
                v-model="level"
                :options="levels"
                :disabled="!canEditContract"
                :label="levelLabel"
                :is-highlighted="showHighlightForField(level)"
              >
                <template #selected-option="{ value }">
                  <span
                    id="contract-level"
                    v-tooltip.top="levelTooltip(value)"
                    class="text-truncate"
                  >
                    {{ value }}
                  </span>
                </template>

                <template #option="{ option }">
                  <div class="step-form__select-text--half-width text-truncate">
                    {{ option.text }}
                  </div>
                </template>
              </SkSelectV2>
            </div>
            <div
              v-if="steps.length > 0"
              class="col-6"
            >
              <SkSelectV2
                v-model="step"
                :options="steps"
                :label="stepLabel"
                :disabled="!canEditContract || !level"
                :is-highlighted="showHighlightForField(step)"
              />
            </div>
          </div>
        </SkForm>
      </div>

      <div
        v-if="isExtendedView && currentOrganisation.attributes.hrisIntegration"
        class="full-contract__content__form"
      >
        <SkForm
          :title="$t('employees.tabs.contracts.full_contract.data.contract.pay_identification_number.label')"
          split
        >
          <template #title-icon>
            <div class="full-contract__icon-title__wrapper">
              <div class="new-tag__wrapper">
                <NewTag />
              </div>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.pay_identification_number.tooltip')"
                width="18"
                height="18"
              />
            </div>
          </template>
          <div
            v-if="isContractReadOnlyView"
            class="row"
          >
            <div class="col-12 full-contract__readonly">
              {{ formatAttribute(payIdentificationNumber) }}
            </div>
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col-12">
              <SkInput
                v-model="payIdentificationNumber"
                :disabled="!canEditContract"
                :label="$t('employees.tabs.contracts.full_contract.data.contract.pay_identification_number.label')"
                @keypress.native="preventSemiColumn($event)"
              />
            </div>
          </div>
        </SkForm>
      </div>

      <div
        v-if="isFrenchShop"
        class="full-contract__content__form"
      >
        <div
          v-if="isContractReadOnlyView"
          class="full-contract__content__form"
        >
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.title')"
            class="full-contract__readonly-status-block"
            split
          >
            <template #title-icon>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.tooltip')"
                width="18"
                height="18"
              />
            </template>
            <div class="row">
              <div class="col-12 full-contract__readonly">
                {{ displayedPcsEseCurrentMainCategory }}
              </div>
            </div>
          </SkForm>
          <SkForm
            class="full-contract__readonly-status-block"
            split
          >
            <div class="row">
              <div class="col-12 full-contract__readonly">
                {{ displayedPcsEseCurrentSubCategory }}
              </div>
            </div>
          </SkForm>
        </div>

        <div
          v-else
          class="full-contract__content__form"
        >
          <SkForm
            :title="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.title')"
            split
          >
            <template #title-icon>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.tooltip')"
                width="18"
                height="18"
              />
            </template>
            <div class="row sk-form__row">
              <div class="col-12">
                <SkSelectV2
                  v-model="pcsEseMainCategory"
                  :options="pcsEseMainCategories"
                  :disabled="!canEditContract"
                  :label="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.label')"
                  :is-highlighted="showHighlightForField(pcsEseMainCategory)"
                >
                  <template #selected-option="{ value }">
                    <span>
                      {{ displayedPcsEseMainCategory(value) }}
                    </span>
                  </template>
                  <template #option="{ option }">
                    <div class="step-form__select-text text-truncate">
                      {{ option.text }}
                    </div>
                  </template>
                </SkSelectV2>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <SkSelectV2
                  v-model="pcsEseSubCategory"
                  :options="pcsEseSubCategories"
                  :disabled="!canEditContract || !isPcsEseMainCategorySelected"
                  :label="$t('employees.tabs.contracts.full_contract.data.contract.pcs_ese.subcategory.label')"
                  :is-highlighted="showHighlightForField(pcsEseSubCategory)"
                >
                  <template #selected-option="{ value }">
                    <span>
                      {{ displayedPcsEseSubCategory(value) }}
                    </span>
                  </template>
                  <template #option="{ option }">
                    <div class="step-form__select-text text-truncate">
                      {{ option.text }}
                    </div>
                  </template>
                </SkSelectV2>
              </div>
            </div>
          </SkForm>
        </div>

        <div class="full-contract__dpae">
          <div class="col-md-6 col-lg-6 full-contract__dpae-title">
            <span>
              {{ $t('employees.tabs.contracts.full_contract.data.contract.dpae.label') }}
            </span>
            <div
              v-if="!isContractReadOnlyView"
              class="full-contract__dpae-link"
            >
              <SkOroraButton
                variant="tertiary"
                icon="ExternalLinkV2Icon"
                href="https://www.due.urssaf.fr/declarant/index.jsf"
                target="_blank"
                @click="trackUrssafLinkClick"
              >
                {{ $t('employees.tabs.contracts.full_contract.data.contract.dpae.cta') }}
              </SkOroraButton>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-6 sk-form__row__button-wrapper"
          >
            <div
              v-if="isContractReadOnlyView"
              class="full-contract__readonly full-contract__content__dpae"
            >
              <span v-if="dpaeDepositCompleted">
                {{ $t('employees.tabs.contracts.full_contract.data.contract.dpae.value.done') }}
              </span>
              <span v-else>
                {{ $t('employees.tabs.contracts.full_contract.data.contract.dpae.value.to_do') }}
              </span>
            </div>
            <div
              v-else
              class="full-contract__content__dpae--edition"
            >
              <SkRadio
                v-for="option in dpaeOptions"
                :key="option.value"
                v-model="dpaeDepositCompleted"
                :data-value="option.value"
                :disabled="!canEditContract || dpaeLoading"
                :is-highlighted="showHighlightForField(dpaeDepositCompleted)"
                class="full-contract__content__dpae--edition__radio"
              >
                <span>
                  {{ option.text }}
                </span>
              </SkRadio>
              <div v-tooltip.bottom="sendDpaeTooltip">
                <SkOroraButton
                  v-if="showSendDpaeButton"
                  class="full-contract__content__dpae--edition__send-request-button"
                  variant="secondary"
                  :disabled="isSendDpaeRequestButtonDisabled"
                  :loading="dpaeLoading"
                  @click="sendDpaeRequest"
                >
                  {{ $t('employees.tabs.contracts.full_contract.data.contract.dpae.send_button') }}
                </SkOroraButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="full-contract__content__form">
        <SkForm
          :class="canReadEmployeePersonalInfosAndBankData ? 'sk-form--split--bottom-border' : 'sk-form--split'"
          :title="$t('employees.tabs.contracts.full_contract.data.last_medical_exam.title')"
          split
        >
          <div
            v-if="isContractReadOnlyView"
            class="row"
          >
            <div class="col-6 full-contract__readonly">
              {{ displayAsDate(lastMedicalExam) }}
            </div>
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col-6">
              <SkDatePicker
                v-model="lastMedicalExam"
                :placeholder="$t('employees.tabs.contracts.full_contract.data.last_medical_exam.placeholder')"
                :loading="loading"
                :lang="$i18n.locale"
                clearable
                hide-footer
              />
            </div>
          </div>
        </SkForm>
      </div>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';

import { arrayToSelectOptions } from '@skello-utils/form';
import {
  getCountryForContractTypes,
  getContractTypeKeyText,
} from '@app-js/users/shared/utils/contract_types_helper';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';
import NewTag from '@app-js/shared/components/NewTag';
import MissingInformationNotification from '@app-js/shared/components/PayrollPreparation/MissingInformationNotification';

export default {
  name: 'ContractData',
  components: {
    NewTag,
    MissingInformationNotification,
  },
  mixins: [payrollPreparationMixins],
  props: {
    isExtendedView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      dpaeLoading: false,
      isContractLevelEllipsisActive: true,
      isContractStatusEllipsisActive: true,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('convention', ['convention']),
    ...mapState('contracts', ['allContracts', 'contract']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('employees', ['showIncompleteEmployees']),
    ...mapGetters('config', ['hourlyRateCounterType']),
    ...mapGetters('contracts', [
      'contractType',
      'isContractFixedTerm',
      'isContractFixedTermSeasonal',
      'isContractInterim',
      'isContractIntern',
      'isContractPermanent',
      'isTrialEndErrored',
      'isContractReadOnlyView',
      'isContractVersionView',
      'isContractEndDateOptional',
      'isContractEditView',
    ]),
    ...mapGetters('currentLicense', [
      'canEditEmployeeInfo',
      'canEditSelfHr',
      'canReadEmployeePersonalInfosAndBankData',
      'canManageEmployeeAmendments',
    ]),
    ...mapGetters('selectedEmployee', [
      'isCurrentUserOrStrictSubordinate',
      'isCurrentUserOrSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
      'missingMandatoryFieldsForDpae',
      'isAutomaticDpaeEnabled',
    ]),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled']),

    sendDpaeTooltip() {
      if (!this.isSendDpaeRequestButtonDisabled) return null;

      const missingFields = this.missingMandatoryFieldsForDpae
        .map(key => this.$t(`employees.tabs.contracts.full_contract.data.contract.dpae.tooltip.${key}`));
      const formattedFields = missingFields.map(field => `<br>• ${field}`).join('');
      return `${this.$t('employees.tabs.contracts.full_contract.data.contract.dpae.tooltip.description')}${formattedFields}`;
    },
    showSendDpaeButton() {
      return this.dpaeDepositCompleted === false &&
        this.isAutomaticDpaeEnabled &&
        this.isContractEditView;
    },
    isFixedTermContractReasonEnabled() {
      const shop = this.currentShop.id === 'all' ?
        this.employee.relationships.shop :
        this.currentShop;

      return this.checkFeatureFlag('FEATURE_FIXED_TERM_CONTRACT_REASON', shop);
    },
    isSendDpaeRequestButtonDisabled() {
      return this.missingMandatoryFieldsForDpae.length > 0;
    },
    isUpgradeMultiContractsActivated() {
      return this.isDevFlagEnabled('FEATUREDEV_UPGRADE_MULTICONTRACTS');
    },
    isUpgradeMultiContractsEnabled() {
      return this.isUpgradeMultiContractsActivated && this.isContractVersionView;
    },
    startDateLabel() {
      const translationKey = this.isUpgradeMultiContractsEnabled ? '.version' : '';
      return this.$i18n.t(`employees.tabs.contracts.full_contract.data.contract.start_date${translationKey}.label_mandatory`);
    },
    startDatePlaceholder() {
      const translationKey = this.isUpgradeMultiContractsEnabled ? '.version' : '';
      return this.$i18n.t(`employees.tabs.contracts.full_contract.data.contract.start_date${translationKey}.label`);
    },

    convention() {
      return this.employee.relationships.convention;
    },

    sector() {
      return this.convention.attributes.sector;
    },

    // permissions
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },

    canEditHrData() {
      if (!this.canEditEmployeeInfo) return false;
      return (
        (this.canEditSelfHr && this.isCurrentUserOrSystemAdmin) ||
        this.isStrictSubordinateOfCurrentUser
      );
    },

    isFrenchShop() {
      return this.employee.relationships.shop.attributes.isFrenchShop;
    },
    dpaeOptions() {
      return [
        {
          value: true,
          text: this.$t('employees.tabs.contracts.full_contract.data.contract.dpae.value.done'),
        },
        {
          value: false,
          text: this.$t('employees.tabs.contracts.full_contract.data.contract.dpae.value.to_do'),
        },
      ];
    },
    contractTypes() {
      const shopCountry = getCountryForContractTypes(
        this.employee.relationships.shop.attributes.country.toLowerCase(),
      ).toUpperCase();

      return this.config.contract_data.contract_types
        .filter(ct => (
          ct.country === shopCountry ||
          Number(ct.id) === this.contract.attributes.contractTypeId
        ))
        .map(ct => ({
          id: ct.id,
          text: getContractTypeKeyText(ct.country, ct.key),
        }))
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
    },
    contractTypeId: {
      get() {
        return this.contract.attributes.contractTypeId;
      },
      set(newContractTypeId) {
        this.setContractAttributes({ contractTypeId: newContractTypeId });

        if (this.isContractInterim) {
          this.setContractAttributes({ counterType: this.hourlyRateCounterType });
          this.setContractAttributes({ contractHours: 0 });
          this.setContractAttributes({ workDays: 0 });
        }
      },
    },
    displayedContractTypeName() {
      if (!this.contractTypeId) return '-';

      const contractType =
        this.config.contract_data.contract_types
          .find(ct => ct.id === this.contract.attributes.contractTypeId);
      const contractTypeCountry = contractType.country;
      const contractTypeKey = contractType.key;

      return getContractTypeKeyText(contractTypeCountry, contractTypeKey);
    },
    fixedTermContractReason: {
      get() {
        return this.contract.attributes.fixedTermContractReason?.toLowerCase() ?? 'empty_option';
      },
      set(newFixedTermContractReason) {
        this.setContractAttributes({
          fixedTermContractReason: newFixedTermContractReason !== 'empty_option' ?
            newFixedTermContractReason :
            null,
        });
      },
    },
    fixedTermContractReasons() {
      return [
        { id: 'empty_option', text: this.$t('contract.fixed_term_contract_reasons.empty_option') },
        ...arrayToSelectOptions(
          this.config.contract_data.fixed_term_contract_reasons_collection,
          value => this.$t(`contract.fixed_term_contract_reasons.${value}`),
        ),
      ];
    },
    displayedFixedTermContractReason() {
      if (!this.fixedTermContractReason) return '-';

      return this.$t(`contract.fixed_term_contract_reasons.${this.fixedTermContractReason}`);
    },

    // timeBasisCategory
    timeBasisCategory: {
      get() {
        return this.contract.attributes.timeBasisCategory;
      },
      set(newTimeBasisCategory) {
        this.setContractAttributes({ timeBasisCategory: newTimeBasisCategory });
      },
    },

    timeBasisCategories() {
      return arrayToSelectOptions(
        this.config.contract_data.time_basis_category_collection,
        value => this.$t(`contract.time_basis_category.${value}`),
      );
    },
    displayedTimeBasisCategory() {
      if (!this.timeBasisCategory) return '-';

      return this.$t(`contract.time_basis_category.${this.timeBasisCategory}`);
    },

    // tutor
    tutor: {
      get() {
        return this.contract.attributes.tutor;
      },
      set(newTutor) {
        this.setContractAttributes({ tutor: newTutor });
      },
    },

    // posteName
    posteName: {
      get() {
        return this.contract.attributes.posteName;
      },
      set(newPosteName) {
        this.setContractAttributes({ posteName: newPosteName });
      },
    },

    arrivalDate: {
      get() {
        return this.employee.attributes.arrivalDate;
      },
      set(value) {
        this.setEmployeeAttributes({ arrivalDate: value });
      },
    },

    payIdentificationNumber: {
      get() {
        return this.contract.attributes.payIdentificationNumber;
      },
      set(newPayIdentificationNumber) {
        this.setContractAttributes({ payIdentificationNumber: newPayIdentificationNumber });
      },
    },

    lastMedicalExam: {
      get() {
        return this.employee.attributes.lastMedicalExam;
      },
      set(value) {
        this.setEmployeeAttributes({ lastMedicalExam: value });
      },
    },

    // startDate
    /**
     * The date part (YYYY-MM-DD) from contract.start_date
     */
    startDate: {
      get() {
        if (!this.contract.attributes.startDate) return null;

        return this.contract.attributes.startDate.substring(0, 10);
      },
      set(newStartDate) {
        this.setContractAttributes({ startDate: this.computeStartDate(newStartDate) });
        this.setContractAttributes({ endDate: null });
        this.setContractAttributes({ trialEnd: null });
      },
    },
    fullStartDate() {
      if (!this.startDate) return '-';

      return this.$t('employees.tabs.contracts.full_contract.data.contract.start_date.readonly', {
        date: skDate(this.startDate).format('DD MMMM YYYY'),
        hour: skDate(this.startDateHour).format('HH'),
        min: skDate(this.startDateHour).format('mm'),
      });
    },
    isStartDateDefined() {
      return !!this.contract.attributes.startDate;
    },
    /**
     * The hour part (HH:MM:SS) from contract.start_date
     */
    startDateHour: {
      get() {
        // TimePicker expects a datetime, use .utc to ignore timezone
        return skDate(this.contract.attributes.startDate).utc().format('YYYY-MM-DDTHH:mm');
      },
      set(newStartDate) {
        // Ignore timezone from TimePicker and force UTC
        this.setContractAttributes({ startDate: skDate(newStartDate).utc(true).format() });
      },
    },
    // trialEnd
    trialEnd: {
      get() {
        return this.contract.attributes.trialEnd;
      },
      set(newTrialEnd) {
        this.setContractAttributes({ trialEnd: newTrialEnd });
      },
    },
    isContractTrialEndSoon() {
      if (!this.trialEnd || this.isTrialEndErrored) return false;

      if (this.isContractInterim || this.isContractIntern) return false;

      return skDate(this.trialEnd) >= skDate().startOf('day') &&
            skDate(this.trialEnd) <= skDate().add(30, 'days');
    },
    // End Date
    endDate: {
      get() {
        return this.contract.attributes.endDate;
      },
      set(newEndDate) {
        this.setContractAttributes({ endDate: newEndDate });
      },
    },
    isEndDateErrored() {
      // Not errored if contractType / startDate has not been defined
      // Not errored if endDate has been defined
      if (!this.contractTypeId || !this.isStartDateDefined || this.isEndDateDefined) return false;

      // Else, errored if mandatory
      return this.isEndDateMandatory;
    },
    isEndDateDefined() {
      return !!this.contract.attributes.endDate;
    },
    // End date is mandatory
    // Except when contract is permanent or optional end date, and there is no future contracts
    isEndDateMandatory() {
      return !this.isContractEndDateOptional() ||
        this.allContractsAfterContractStartDate().length > 0;
    },
    endDateTooltipText() {
      return !this.isStartDateDefined ?
        this.$t('employees.tabs.contracts.full_contract.data.contract.start_date.constraint') :
        '';
    },
    endDateErrorMessage() {
      return this.isContractPermanent() ?
        this.$t('employees.tabs.contracts.full_contract.data.contract.end_date.overlap_constraint') :
        this.$t('employees.tabs.contracts.full_contract.data.contract.end_date.constraint_one');
    },
    endDateContractLabel() {
      return this.isEndDateMandatory ?
        this.$t('employees.tabs.contracts.full_contract.data.contract.end_date.mandatory_label') :
        this.$t('employees.tabs.contracts.full_contract.data.contract.end_date.label');
    },
    // Status
    status: {
      get() {
        return this.contract.attributes.status;
      },
      set(newStatus) {
        this.setContractAttributes({ status: newStatus });
        this.$nextTick(() => {
          this.setContractStatusEllipsisActive();
        });
      },
    },
    statuses() {
      const statusKey = this.isFrenchShop ? 'fr' : 'generics';

      // At the beginning, the status options were the same for all countries.
      // Now, We introduced some new options for shops in France, and generic statuses are now legacy for France.
      const contractStatusTypes = [...this.config.contract_data.contract_status_types[statusKey]];

      // If the contract has a legacy status selected, then we still want to have it displayed in the options.
      if (this.status && !contractStatusTypes.includes(this.status)) {
        contractStatusTypes.unshift(this.status);
      }

      return arrayToSelectOptions(
        contractStatusTypes,
        value => this.$t(`contract.status.${value}`),
      );
    },
    displayedStatus() {
      if (!this.status) return '-';

      return this.$t(`contract.status.${this.status}`);
    },

    // Level
    level: {
      get() {
        return this.contract.attributes.level;
      },
      set(newLevel) {
        // need to reset step as it depends on the level
        this.setContractAttributes({ step: null, level: newLevel });
        this.$nextTick(() => {
          this.setContractLevelEllipsisActive();
        });
      },
    },
    levels() {
      const availableLevelsWithOptions = this.convention.attributes.contractData;
      const levels = Object.keys(availableLevelsWithOptions).map(value => ({
        id: value,
        text: value,
      }));

      return levels;
    },
    areLevelsDisplayed() {
      return !!this.convention.attributes.contractData;
    },
    levelLabel() {
      switch (this.convention.attributes.name) {
        case 'fast_food':
        case 'chr':
        case 'hardware_store':
          return this.$t('contract.levels.level');
        case 'medical_office':
          return this.$t('contract.levels.professional_streams');
        default:
          return this.$t('contract.levels.bp_pc');
      }
    },

    displayLevelAndStep() {
      if (!this.level && !this.step) return '-';

      if (this.level && !this.step) return this.level;

      if (!this.level && this.step) return `- ${this.step}`;

      if (this.convention.attributes.name === 'medical_office') {
        return this.$t(
          'employees.tabs.contracts.full_contract.data.contract.professional_streams_and_benchmark_job',
          { level: this.level, step: this.step },
        );
      }

      return this.$t(
        'employees.tabs.contracts.full_contract.data.contract.level_and_step',
        { level: this.level, step: this.step },
      );
    },

    // Step
    step: {
      get() {
        return this.contract.attributes.step;
      },

      set(newStep) {
        this.setContractAttributes({ step: newStep });
      },
    },
    steps() {
      const stepOptions = this.convention.attributes.contractData;
      const stepOption = stepOptions[`${this.level}`];

      return arrayToSelectOptions(stepOption);
    },
    stepLabel() {
      switch (this.convention.attributes.name) {
        case 'fast_food':
        case 'chr':
        case 'hardware_store':
        case 'road_transport':
          return this.$t('contract.steps.echelon');
        case 'medical_office':
          return this.$t('contract.steps.benchmark_job');
        case 'boulangerie':
        case 'patisserie':
          return this.$t('contract.steps.coefficient');
        default:
          return this.$t('contract.steps.grade');
      }
    },

    pcsEse() {
      return this.contract.attributes.pcsEse;
    },
    pcsEseCategories() {
      if (this.sector === 'general') {
        return this.pcsEseAllconfig;
      }

      return this.config.pcs_ese.general.concat(this.config.pcs_ese[this.sector]);
    },
    pcsEseMainCategory: {
      get() {
        if (!this.pcsEse) {
          return null;
        }

        return this.pcsEse.slice(0, 2);
      },

      set(newValue) {
        this.setContractAttributes({ pcsEse: newValue });
      },
    },
    pcsEseMainCategories() {
      // some categories belong to multiple sectors so we want to exclude duplicates
      const uniqPcEseCategories =
        [...new Map(this.pcsEseCategories.map(category => [category.id, category])).values()];

      let categories = uniqPcEseCategories.map(pcsEseCategory => ({
        id: pcsEseCategory.id,
        text: `${pcsEseCategory.id} - ${pcsEseCategory.title}`,
      }));

      const currentPcsEseId = this.pcsEse;
      const currentPcsEseCategoryExists =
        uniqPcEseCategories.find(p => p.id === this.pcsEseMainCategory);
      if (currentPcsEseId && !currentPcsEseCategoryExists) {
        const currentPcsEse = this.pcsEseAllconfig.find(p => p.id === this.pcsEseMainCategory);

        categories = categories.concat([{
          id: currentPcsEse.id,
          text: `${currentPcsEse.id} - ${currentPcsEse.title}`,
        }]).sort((category1, category2) => category1.id - category2.id);
      }

      return categories;
    },
    displayedPcsEseCurrentMainCategory() {
      if (!this.pcsEse) return '-';

      const pcsEse = this.pcsEseAllconfig.find(p => p.id === this.pcsEseMainCategory);
      return `${pcsEse.id} ${pcsEse.title}`;
    },

    isPcsEseMainCategorySelected() {
      return !!this.pcsEse;
    },

    pcsEseSubCategory: {
      get() {
        if (!this.isPcsEseMainCategorySelected) return null;
        if (this.pcsEseMainCategory === this.pcsEse) return null;

        return this.pcsEse;
      },

      set(newValue) {
        this.setContractAttributes({ pcsEse: newValue });
      },
    },
    pcsEseSubCategories() {
      if (!this.pcsEse) return null;

      let mainPcsEse = this.pcsEseCategories.find(p => p.id === this.pcsEseMainCategory);
      // if you don't have a mainPcsEse, it means you changed sector and the current pcs_ese is not available
      // in this case, we don't want the use to have all the children of the mainPcEse we will find from another sector
      if (!mainPcsEse) {
        mainPcsEse = this.pcsEseAllconfig.find(p => p.id === this.pcsEseMainCategory);
        const currentSubPcsEse = mainPcsEse.children.find(p => p.id === this.pcsEse);
        return [{
          id: currentSubPcsEse.id,
          text: `${currentSubPcsEse.id} - ${currentSubPcsEse.title}`,
        }];
      }

      const subCategories = mainPcsEse.children.map(pcsEseSubCategory => ({
        id: pcsEseSubCategory.id,
        text: `${pcsEseSubCategory.id} - ${pcsEseSubCategory.title}`,
      }));

      return subCategories;
    },
    displayedPcsEseCurrentSubCategory() {
      if (!this.pcsEse || this.pcsEse.length <= 2) return '-';

      const mainPcsEse = this.pcsEseAllconfig.find(p => p.id === this.pcsEse.slice(0, 2));
      const subPcsEse = mainPcsEse.children.find(p => p.id === this.pcsEse);

      return `${subPcsEse.id} - ${subPcsEse.title}`;
    },

    // DPAE Status
    dpaeDepositCompleted: {
      get() {
        return this.contract.attributes.dpaeDepositCompleted;
      },
      set(newDpaeStatus) {
        if (this.dpaeDepositCompleted === newDpaeStatus) return;

        this.setContractAttributes({ dpaeDepositCompleted: newDpaeStatus });
        const key = newDpaeStatus ? 'dpae_select_completed_dpae_option' : 'dpae_select_not_completed_dpae_option';
        this.$skAnalytics.track(key, { contract_id: this.contract.id });
      },
    },
  },
  watch: {
    isContractFixedTerm(isFixedTerm) {
      if (!isFixedTerm) {
        this.setContractAttributes({ fixedTermContractReason: null });
      }
    },
  },
  created() {
    this.initiateContracts();
  },
  methods: {
    ...mapActions('contracts', ['fetchAllContracts']),
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    ...mapMutations('contracts', [
      'setContractAttributes',
      'setErrorToContract',
      'setDpaeDepositCompleted',
    ]),
    ...mapGetters('contracts', [
      'allContractsAfterContractStartDate',
      'isDateUnavailableAsNewContractStartDate',
      'isDateUnavailableAsContractEndDate',
    ]),
    initiateContracts() {
      this.fetchAllContracts({
        employeeId: this.$route.params.user_id,
      })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.full_contract.errors.loading'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });

      this.$nextTick(() => {
        this.setContractLevelEllipsisActive();
        this.setContractStatusEllipsisActive();
      });

      this.pcsEseAllconfig =
      Object
        .values(this.config.pcs_ese)
        .flat()
        .sort((category1, category2) => category1.id - category2.id);
    },
    displayDpaeToaster(variant) {
      this.$skToast({
        message: this.$t(`employees.tabs.contracts.full_contract.data.contract.dpae.toast.${variant}`),
        variant,
        containerId: 'employees__container',
      });
    },
    displayedPcsEseMainCategory(value) {
      let currentPcsEse = this.config.pcs_ese[this.sector].find(p => p.id === value.slice(0, 2));

      // Since we are searching on a list linked to the sector, if you changed sector we will not find the currentPcsEse
      // In that case, we still want to display the registered value, thus we search through all sectors
      if (!currentPcsEse) {
        currentPcsEse = this.pcsEseAllconfig.find(p => p.id === value.slice(0, 2));
      }

      return `${currentPcsEse.id} - ${currentPcsEse.title}`;
    },
    displayedPcsEseSubCategory(value) {
      let currentMainPcsEse =
        this.config.pcs_ese[this.sector].find(p => p.id === this.pcsEseMainCategory);

      if (!currentMainPcsEse) {
        currentMainPcsEse = this.pcsEseAllconfig.find(p => p.id === value.slice(0, 2));
      }

      const currentSubPcsEse = currentMainPcsEse.children.find(p => p.id === value);

      return `${currentSubPcsEse.id} - ${currentSubPcsEse.title}`;
    },
    preventSemiColumn(event) {
      if (event.key === ';') event.preventDefault();
    },
    computeStartDate(date) {
      // Concat date part and hour part
      // If date is set for the first time, hour part is set at 08:00
      if (!date) return null;

      const hours = this.contract.attributes.startDate ?
        this.contract.attributes.startDate.substring(10) :
        'T08:00:00Z';

      return date + hours;
    },
    displayAsDate(date) {
      if (!date) return '-';

      return skDate(date).format('DD MMMM YYYY');
    },
    notBeforeStartDate(date) {
      const { startDate } = this.contract.attributes;
      if (!startDate || !date) { return false; }

      return skDate.utc(date).isBefore(skDate.utc(startDate).format('YYYY-MM-DD'));
    },
    sendDpaeRequest() {
      this.dpaeLoading = true;

      this.$skAnalytics.track('dpae_click_send_automatic_dpae', { contract_id: this.contract.id });
      svcEmployeesClient.createDpaeDeposit(
        this.employee.relationships.shop.id,
        this.employee.id,
        this.contract.id,
      )
        .then(() => {
          this.displayDpaeToaster('success');
          this.setDpaeDepositCompleted(true);
        })
        .catch(error => {
          this.displayDpaeToaster('error');
          throw error;
        })
        .finally(() => {
          this.dpaeLoading = false;
        });
    },
    setContractLevelEllipsisActive() {
      const el = document.getElementById('contract-level');
      if (!el) return;

      this.isContractLevelEllipsisActive = el.offsetWidth < el.scrollWidth;
    },
    setContractStatusEllipsisActive() {
      const el = document.getElementById('contract-status');

      if (!el) return;
      this.isContractStatusEllipsisActive = el.offsetWidth <= el.scrollWidth;
    },
    statusTooltip(string) {
      string = this.$t(`contract.status.${string}`);

      return this.isContractStatusEllipsisActive ? string : null;
    },
    trackUrssafLinkClick() {
      this.$skAnalytics.track('dpae_link_click_urssaf_site', { contract_id: this.contract.id });
    },
    levelTooltip(string) {
      return this.isContractLevelEllipsisActive ? string : null;
    },
    formatAttribute(property) {
      return property || '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.full-contract__end-date-group {
  text-align: left;

  ::v-deep .sk-input-group__error-label {
    width: 210px;
  }
}

.full-contract_start_date {
  ::v-deep .sk-time-picker__error {
    width: 100%;
  }
}

.full-contract__dpae {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 40px;
  border-top: 1px solid $sk-grey-10;
  min-height: 88px;

  &-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 14px;
    color: $sk-grey-50;
    padding: 0;
  }

  &-link {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: $fw-semi-bold;
    margin-top: 8px;

    &-icon {
      margin-right: 6px;
    }
  }
}

.full-contract__content__dpae--edition {
  display: flex;
  align-items: center;
  flex-direction: row;
  // the skRadio button has a default surrounding margin of 4px
  margin-left: -4px;

  &__radio {
    margin-top: 6px;
  }

  &__radio:last-of-type {
    margin-left: 24px;
  }

  &__send-request-button {
    margin-left: 24px;
  }
}

.full-contract__content__dpae.full-contract__readonly {
  padding-top: 3px;
  padding-bottom: 0;
}

.full-contract__icon-title__wrapper {
  display: flex;

  .new-tag__wrapper {
    padding-right: 16px;
  }
}

.full-contract__notification {
  padding: 0 0 10px 30px;
  margin-top: -20px;
}

.step-form__select-text {
  max-width: 400px;

  &--half-width {
    max-width: 200px;
  }
}
</style>
