<template>
  <Toolbar class="badgings__toolbar-wrapper">
    <ToolbarDatePicker
      v-if="currentDate"
      :current-date="currentDate"
      tracker-prefix="badging"
      :additional-query-params="additionalQueryParams"
    />
    <Navigation />
    <div class="badgings__toolbar-actions">
      <div
        :style="cannotEditPunchClockStyle"
        class="badgings__toolbar-actions__last-sync"
      >
        {{ lastTabletSyncLabel }}
        <div class="badgings__toolbar-actions__last-sync-date">
          {{ lastTabletSyncDate }}
        </div>
      </div>
      <template v-if="isDaysView">
        <FiltersPopover
          ref="filtersPopover"
          class="toolbar-actions-bar__filters-icon"
          source="badging"
          :are-filters-active="areFiltersActive"
          :data-sources="{ teams }"
          :initial-selected-items="initialFilterSelections"
          :loading="teamsLoading"
          :submit-handler="handleSubmitFilters"
        />
      </template>
      <PrintAction
        v-if="displayTimesheetPrintButton"
        :is-disabled="disabledPrintAction"
        :title-override="printActionTitleOverride"
      />
      <div
        v-if="canEditPunchClockData"
        class="badging__toolbar-actions__validate-day__section"
      >
        <div class="sk-divider--dark" />
        <div
          v-tooltip.down.oneLine.preventOverflow="showTooltipForButton"
        >
          <SkOroraButton
            :loading="loadingPartnerTools"
            :disabled="disabledButton"
            variant="highlighted"
            data-test="badging-validate-day__btn"
            @click="submitWithPartnerTool"
          >
            {{ validateOrEditDay }}
          </SkOroraButton>
        </div>
      </div>
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <PrintModal
          v-if="displayTimesheetPrintButton"
        />
        <BadgingSettingsModal
          v-if="canEditPunchClockSettings"
          :show-day-badging-settings-for-sector="showDayBadgingSettingsForSector"
        />
        <ValidateDayModal />
      </MountingPortal>
    </div>
  </Toolbar>
</template>
<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features.js';
import { paramAsArray } from '@app-js/shared/utils/filters/helpers/formatting_helpers';

import skDate from '@skello-utils/dates';
import Toolbar from '@app-js/shared/components/Toolbar';
import ToolbarDatePicker from '@app-js/shared/components/Toolbar/ToolbarDatePicker';
import FiltersPopover from '@app-js/shared/components/FiltersPopover/FiltersPopover';
import { createShiftApiRequest } from '@skello-store/modules/plannings/api/shift';
import PrintAction from './PrintAction';
import BadgingSettingsModal from '../BadgingSettingsModal';
import Navigation from '../Navigation';
import ValidateDayModal from '../ValidateDayModal';
import PrintModal from './PrintModal.vue';

export default {
  name: 'BadgingsToolbar',
  components: {
    BadgingSettingsModal,
    FiltersPopover,
    Navigation,
    PrintAction,
    PrintModal,
    Toolbar,
    ToolbarDatePicker,
    ValidateDayModal,
  },
  data() {
    return {
      initialFilterSelections: {},
      loadingPartnerTools: false,
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('badgings', [
      'currentDate',
      'dayLocked',
      'teams',
      'teamsLoading',
      'users',
      'matchedBadgings',
      'settings',
    ]),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', ['isHospitalitySector', 'isShopLinkedToPosPartner']),
    ...mapGetters('badgings', [
      'matchedBadgingMissingHoursField',
      'matchedBadgingMissingBreakField',
      'matchedBadgingBreakInvalid',
      'matchedBadgingBreakNegative',
      'matchedBadgingBreakLongerThanShift',
      'matchedBadgingPosteNotSet',
      'matchedBadgingRemuneratedEndBeforeStart',
      'erroredFields',
      'missingMandatoryFields',
      'matchedBadgingsPerUser',
      'matchedBadgingsPerDay',
      'pageMatchedBadgings',
      'isEmployeesView',
      'isDaysView',
      'areAllRowsValidatedForDay',
      'areAllRowsValidatedForUser',
      'currentDayLockedOnPlanning',
      'currentWeekLockedOnPlanning',
      'placeholderMatchedBadgings',
    ]),
    ...mapGetters('features', [
      'isFeatureEnabled',
      'hasTimeclockButNotPlanning',
    ]),
    anyPlaceholderBadgeToShift() {
      return this.placeholderMatchedBadgings
        .filter(placeholder => placeholder.absencePosteId &&
          placeholder.turnedAsAbsence).length > 0;
    },
    canEditManagementIndicators() {
      return this.currentLicense.attributes.canEditManagementIndicators;
    },
    canEditPunchClockData() {
      return this.currentLicense.attributes.canEditPunchClockData &&
        this.currentLicense.attributes.canCreateShifts;
    },
    canEditPunchClockSettings() {
      return this.currentLicense.attributes.canEditPunchClockSettings &&
        this.checkPackOfferFlag('secondary_shops_enabled');
    },
    showDayBadgingSettingsForSector() {
      /* May be extended to other sectors in the future */
      return !this.isHospitalitySector;
    },
    lastTabletSyncDate() {
      if (!this.settings?.lastTabletSync) return null;

      return skDate(this.settings.lastTabletSync).fromNow();
    },
    lastTabletSyncLabel() {
      if (!this.settings?.lastTabletSync) return this.$t('badgings.toolbar.never_sync');

      return this.$t('badgings.toolbar.last_sync');
    },
    dayIsInFuture() {
      return skDate().isBefore(this.currentDate);
    },
    weekIsInFuture() {
      return skDate().isBefore(skDate(this.currentDate).startOf('isoWeek'));
    },
    disabledButtonForDay() {
      return this.dayIsInFuture || this.currentDayLockedOnPlanning ||
        (this.dayInPastAndNoBadging && !this.anyPlaceholderBadgeToShift);
    },
    disabledButtonForUser() {
      return this.weekIsInFuture ||
        this.matchedBadgingsPerUser().length === 0 ||
        this.currentWeekLockedOnPlanning;
    },
    disabledButton() {
      return this.isEmployeesView ? this.disabledButtonForUser : this.disabledButtonForDay;
    },
    displayTimesheetPrintButton() {
      return this.hasTimeclockButNotPlanning(this.currentShop.id);
    },
    disabledPrintAction() {
      return !this.matchedBadgings ||
        this.matchedBadgings.length === 0 ||
        !this.users?.length;
    },
    printActionTitleOverride() {
      if (!this.disabledPrintAction) return '';

      return !this.users?.length ?
        this.$t('action_bar.print.no_employees_label') :
        this.$t('action_bar.print.no_badges_label');
    },
    showTooltipForButtonForDay() {
      let text;
      if (this.dayIsInFuture) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.day_is_in_future');
      }

      if (this.dayInPastAndNoBadging) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.day_past_no_badgings');
      }

      if (this.currentDayLockedOnPlanning) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.day_locked_on_planning');
      }

      return text;
    },
    showTooltipForButtonForUser() {
      let text;
      if (this.weekIsInFuture) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.week_is_in_future');
      }

      if (this.matchedBadgingsPerUser.length === 0) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.week_past_no_badgings');
      }

      if (this.currentWeekLockedOnPlanning) {
        text = this.$t('badgings.toolbar.validate_day_modal.tooltip.week_locked_on_planning');
      }

      return text;
    },
    showTooltipForButton() {
      return this.isEmployeesView ?
        this.showTooltipForButtonForUser :
        this.showTooltipForButtonForDay;
    },
    validateOrEditDay() {
      if (this.dayLocked && this.itemSidebarValidated && !this.anyPlaceholderBadgeToShift) {
        return this.$t('badgings.days.button.edit_day');
      }

      return this.isEmployeesView ?
        this.$t('badgings.days.button.validate_week') :
        this.$t('badgings.days.button.validate_day');
    },
    showModalOnlyWithoutIntegration() {
      return this.isShopLinkedToPosPartner ? null : 'validate-day-modal';
    },
    cannotEditPunchClockStyle() {
      return this.canEditPunchClockData ? null : { marginRight: '35px' };
    },
    dayInPastAndNoBadging() {
      return skDate().isAfter(this.currentDate) && this.matchedBadgingsPerDay().length === 0;
    },
    urlTeamIds() {
      return this.$route.query.team_ids;
    },
    areFiltersActive() {
      return this.urlTeamIds !== undefined && this.urlTeamIds.length > 0;
    },
    additionalQueryParams() {
      return { team_ids: (this.urlTeamIds || []) };
    },
    itemSidebarValidated() {
      return this.isDaysView ? this.areAllRowsValidatedForDay : this.areAllRowsValidatedForUser;
    },
  },
  watch: {
    teamsLoading(newValue) {
      if (!newValue) {
        this.$refs.filtersPopover?.filtersLoaded();
      }
    },
  },
  mounted() {
    this.$router.onReady(() => {
      this.$nextTick(() => {
        this.initializeFilterSelections();
      });
    });
  },
  methods: {
    ...mapActions('planningsShifts', ['deleteShifts']),
    ...mapActions('partnersTools', ['fetchLadditionShops', 'fetchPosProvidersOnShop']),
    ...mapActions('badgings', ['updateMatchedBadging']),
    ...mapMutations('badgings', [
      'updateDayLocked',
      'filterAbsencesByTeam',
      'filterMatchedBadgingsByTeam',
      'setSelectedTeamIds',
    ]),
    initializeFilterSelections() {
      const { team_ids: teamIds } = this.$route.query;
      this.initialFilterSelections = {
        team_ids: paramAsArray(teamIds),
      };
    },
    async loadPosIntegrations(shopId) {
      if (!this.canEditManagementIndicators) return;

      const { id: organisationId } = this.currentOrganisation;
      await Promise.all([
        this.fetchPosProvidersOnShop({ organisationId, shopIds: [shopId] }),
        this.fetchLadditionShops([shopId]),
      ]);
    },
    async triggerMatchedBadgingsUpdateProcess() {
      const shiftsToBeDeleted = this.pageMatchedBadgings
        .filter(s => s.isAbsence && !s.turnedAsAbsence && s.absencePosteId &&
          s.badging.in === '' && s.badging.out === '').map(s => s.shiftId);
      if (shiftsToBeDeleted.length > 0) {
        const currentDate = shiftsToBeDeleted[0].currentDate;

        await this.deleteShifts({
          shop_id: this.currentShop.id,
          skipRemoveShift: true,
          shift_ids: shiftsToBeDeleted,
          starts_at: skDate(currentDate).startOf('isoWeek').format('YYYY-MM-DD'),
          ends_at: skDate(currentDate).endOf('isoWeek').format('YYYY-MM-DD'),
        });
      }

      const componentParams = {
        rows: this.pageMatchedBadgings.filter(s => !shiftsToBeDeleted.includes(s.shiftId)),
        start_date: this.currentDate,
        date: this.currentDate,
        shop_id: this.currentShop.id,
        team_ids: this.urlTeamIds,
        user_id: this.$route.params.user_id || null,
      };

      this.updateMatchedBadging({ componentParams })
        .then(() => {
          this.loadingPartnerTools = false;
          this.$skToast({
            message: this.$t('badgings.toolbar.validate_day_modal.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.loadingPartnerTools = false;
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    async submitWithPartnerTool(event) {
      if (this.dayLocked && this.itemSidebarValidated && !this.anyPlaceholderBadgeToShift) {
        this.updateDayLocked(!this.dayLocked);
        return;
      }

      if (this.erroredFields.length !== 0 ||
        (this.missingMandatoryFields.length !== 0)) {
        const errorMessage = this.getErrorMessage();

        if (errorMessage) {
          this.$skToast({
            message: errorMessage,
            variant: 'error',
          });
        }
        return;
      }

      await this.loadPosIntegrations(this.currentShop.id);

      if (this.isShopLinkedToPosPartner || this.isEmployeesView ||
            !this.isFeatureEnabled(this.FEATURES.FEATURE_KPIS, this.currentShop.id, () => true)) {
        this.loadingPartnerTools = true;
        this.$skAnalytics.track('badgings_validate', {
          source: this.isEmployeesView ? 'user_week' : 'day',
        });

        this.generateAbsenceShifts()
          .finally(() => {
            this.triggerMatchedBadgingsUpdateProcess();
          });
      } else {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, this.showModalOnlyWithoutIntegration);
      }
    },
    getErrorMessage() {
      let errorMessage = null;
      if (this.matchedBadgingRemuneratedEndBeforeStart) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.selected_start_higher_than_end');
      }
      if (this.matchedBadgingMissingHoursField.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.hours_mandatory_field');
      }
      if (this.matchedBadgingMissingBreakField.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.break_mandatory_field');
      }
      if (this.matchedBadgingBreakInvalid.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.invalid_break');
      }
      if (this.matchedBadgingBreakNegative.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.break_negative');
      }
      if (this.matchedBadgingBreakLongerThanShift.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.break_superior_shift');
      }
      if (this.matchedBadgingPosteNotSet.length !== 0) {
        errorMessage = this.$t('badgings.toolbar.validate_day_modal.errors.poste_missing');
      }

      return errorMessage;
    },
    // eslint-disable-next-line camelcase
    handleSubmitFilters({ team_ids = [] }) {
      this.setSelectedTeamIds({ teamIds: team_ids });
      this.$router.replace({
        name: this.$router.currentRoute.name,
        query: { date: this.currentDate, team_ids },
      });

      this.filterAbsencesByTeam();
      this.filterMatchedBadgingsByTeam();
    },
    shopStartAndEndHours(currentDate) {
      const shopOpeningHour = this.currentShop.attributes.openingTime;
      const shopClosingHour = this.currentShop.attributes.closingTime;

      if (shopOpeningHour === '00:00' && shopClosingHour === '00:00') {
        const dayAfter = skDate(currentDate).add(1, 'day').format('YYYY-MM-DD');
        return [`${currentDate}T00:00:00Z`, `${dayAfter}T00:00:00Z`];
      }

      return [`${currentDate}T${skDate(shopOpeningHour, 'HH:mm').format('HH:mm')}:00Z`,
        `${currentDate}T${skDate(shopClosingHour, 'HH:mm').format('HH:mm')}:00Z`];
    },
    generateAbsenceShifts() {
      const placeholdersToAbsenceShifts = this.placeholderMatchedBadgings
        .filter(placeholder => !placeholder.isAbsence &&
          placeholder.turnedAsAbsence && placeholder.absencePosteId)
        .map(placeholder => {
          const [startsAt, endsAt] = this.shopStartAndEndHours(placeholder.currentDate);
          return {
            id: null,
            attributes: {
              startsAt,
              endsAt,
              note: null,
              userId: placeholder.user.id,
              shopId: placeholder.shop.id,
              nbMeal: 0,
              pauseTime: 0,
              dayAbsence: true,
              absenceCalculation: placeholder.absenceCalculation || 'hours',
              hoursWorth: null,
              absenceDurationInSeconds: (new Date(endsAt) - new Date(startsAt)) / 1000,
              delay: 0,
            },
            relationships: {
              poste: {
                id: placeholder.absencePosteId,
              },
            },
          };
        });
      if (placeholdersToAbsenceShifts.length === 0) return Promise.resolve();

      return createShiftApiRequest({
        periodStartsAt: skDate().startOf('week').format('YYYY-MM-DD'),
        periodEndsAt: skDate().endOf('week').format('YYYY-MM-DD'),
        shopId: this.currentShop.id,
        performLater: false,
        shifts: placeholdersToAbsenceShifts,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.badgings__toolbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.badgings__toolbar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.badgings__toolbar-actions__last-sync {
  width: 235px;
  font-size: $fs-text-s;
  color: $sk-grey;
  text-align: right;
  margin-right: 10px;
}

.badgings__toolbar-actions__last-sync-date {
  display: flex;
  justify-content: flex-end;
  font-weight: $fw-semi-bold;
}

.badgings__toolbar-datepicker {
  display: flex;
  align-items: center;

  .badging-history__toolbar__date-actions {
    padding: 0 10px;
  }
}

.datepicker__daterange__label {
  width: 149px;
  height: 36px;
  background-color: $sk-white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $sk-grey;

  &:hover {
    cursor: pointer;
  }
}
.badging__toolbar-actions__validate-day__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sk-divider--dark {
  margin: 0 30px 0 12px;
}

.badgings__toolbar-actions__settings-dropdown__menu {
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;

  a.settings-dropdown__item {
    display: flex;
    align-items: center;
    color: $sk-black;
    padding: 10px 15px;
    width: 100%;

    &:hover {
      background: $sk-grey-10;
      border-radius: 3px;
      text-decoration: none;
    }

    .settings-dropdown__item-icon {
      margin-right: 15px;
    }
  }
}
</style>
