<template>
  <ul
    v-show="visible"
    class="over-hours-plannings-list list-unstyled"
  >
    <li
      v-for="(employeeOverHours, index) in employeesOverHours"
      :key="index"
      class="over-hours-planning-item"
    >
      <a
        :href="employeeOverHours.link"
        class="over-hours-planning-link"
        target="_blank"
        data-seg="DboardOverHoursPlanning"
      >
        <div class="flex-center">
          <div class="over-hours-badge" />
          <div class="over-hours-planning-name">
            {{ employeeOverHours.name }}
          </div>
        </div>
        <div>
          <div class="over-hours-planning-hours">
            <span class="bold-hours">{{ employeeOverHours.value }}</span> {{ $t('home_dashboard.chart.over_hours.employees.over_time') }}
          </div>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'OverHoursEmployeeList',
  data() {
    return {
      visible: true,
      employeesOverHours: [],
      employees_over_hours_path: '/employees_over_hours',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.loadOverHours);
  },
  methods: {
    handleReloadDone() {
      this.$emit('data-loaded');
    },
    loadOverHours() {
      return httpClient.get(`${this.employees_over_hours_path}?id=${this.currentShop.id}`)
        .then(response => {
          this.employeesOverHours = response.data;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
</style>
