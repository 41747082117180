<template>
  <div :class="transparent ? 'stripe-box--transparent' : 'stripe-box'">
    <div class="stripe-box__logo-wrapper">
      <StripeLogo class="stripe-box__logo" />
      <Lock
        v-if="showLock"
        class="stripe-box__lock"
      />
    </div>
    <div class="stripe-box__checklist">
      <div
        v-for="i in 3"
        :key="i"
        class="stripe-box__checklist-item sk-text-small-regular"
      >
        <BlueCheck />
        {{ $t(`organisation_settings.sepa_modal.stripe_box.check${i}`) }}
      </div>
    </div>
  </div>
</template>
<script>

import BlueCheck from './BlueCheck';
import StripeLogo from './StripeLogo';
import Lock from './Lock';

export default {
  name: 'StripeBox',
  components: {
    BlueCheck,
    StripeLogo,
    Lock,
  },
  props: {
    showLock: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: $sk-grey-5;
}

.stripe-box--transparent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $sk-grey-10;
}

.stripe-box__logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

.stripe-box__logo {
  max-width: 130px;
}

.stripe-box__lock {
  margin-top: 16px;
}

.stripe-box__checklist {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px 40px 15px 0;
}

.stripe-box__checklist-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
