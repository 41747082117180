<template>
  <div
    :data-field="field"
    :data-label="label"
    :class="classes"
    :draggable="!inText"
    @drag="handleDragFrame"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <div
      v-if="!inText"
      class="sk-template-variable__dots"
    >
      <div class="sk-template-variable__dot" />
      <div class="sk-template-variable__dot" />
      <div class="sk-template-variable__dot" />
    </div>
    <div
      v-if="!inText"
      class="sk-template-variable__dots"
    >
      <div class="sk-template-variable__dot" />
      <div class="sk-template-variable__dot" />
      <div class="sk-template-variable__dot" />
    </div>
    <span
      v-if="inText"
      class="sk-template-variable__label"
    >{{ label }}</span>
    <div
      v-else
      class="sk-template-variable__label"
    >
      {{ label }}
    </div>
    <span
      v-if="inText"
      class="sk-template-variable__close-icon"
      onclick="var elt = this.parentNode.parentNode; elt.parentNode.removeChild(elt)"
    >
      <ClosingXIcon
        width="12"
        height="12"
        fill=""
        class="sk-template-variable__close-icon-fill"
      />
    </span>
  </div>
</template>

<script>
const EVENT_DATA_TRANSFER_KEY = 'template-variable';
const BROWSER_IS_FIREFOX = !document.caretRangeFromPoint && document.caretPositionFromPoint;
const ADAPT_OFFSET_X = 12; // allows better visibility on caret

export default {
  name: 'TemplateVariable',
  props: {
    field: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    inText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inDragging: false,
      templateVariable: {},
      dragDebounce: 0,
      dragOverDebounce: 0,
      editor: null,
    };
  },
  computed: {
    classes() {
      return {
        'sk-template-variable': true,
        'sk-template-variable--in-text': this.inText,
        'sk-template-variable--in-dragging': this.inDragging,
        'sk-template-variable--in-panel': !this.inDragging,
      };
    },
  },
  mounted() {
    document.addEventListener('dragover', this.getMousePositionFirefox, false);
    setTimeout(() => { this.editor = document.querySelectorAll('.ql-editor--with_variable')[0]; }, 0);
  },
  beforeDestroy() {
    document.removeEventListener('dragover', this.getMousePositionFirefox, false);
  },
  methods: {
    handleDragEnd() {
      this.inDragging = false;

      if (BROWSER_IS_FIREFOX) {
        this.handleFirefoxCaret();
      }
    },
    handleDragFrame(evt) {
      if (this.dragDebounce === 50) this.dragDebounce = 0;
      this.dragDebounce += 1;
      if (this.dragDebounce !== 1) return;

      const templateVariable = this.templateVariable;
      if (!templateVariable) return;
      const { offsetX, offsetY } = templateVariable;
      const selection = document.getSelection();
      let range;
      if (document.caretRangeFromPoint) { // Chrome/safari
        range = document.caretRangeFromPoint(
          evt.clientX - offsetX - ADAPT_OFFSET_X,
          evt.clientY - offsetY,
        );
      } else if (BROWSER_IS_FIREFOX) {
        if (!this.firefoxX || !this.firefoxY) return;
        const position = document.caretPositionFromPoint(
          this.firefoxX - offsetX - ADAPT_OFFSET_X,
          this.firefoxY - offsetY,
        );

        range = document.createRange();
        range.setStart(position.offsetNode, position.offset);
      }

      if (selection && range) {
        selection.setBaseAndExtent(
          range.startContainer,
          range.startOffset,
          range.startContainer,
          range.startOffset,
        );
      }

      if (BROWSER_IS_FIREFOX) {
        this.handleFirefoxCaret();
      }
    },
    getMousePositionFirefox(event) {
      if (!this.inDragging) return;
      if (this.dragOverDebounce === 50) this.dragOverDebounce = 0;
      this.dragOverDebounce += 1;
      if (this.dragOverDebounce !== 1) return;

      this.firefoxX = event.x || event.clientX;
      this.firefoxY = event.y || event.clientY;
    },
    handleFirefoxCaret() {
      // cheat for firefox allowing caret to be visible both while dragging / after drop
      this.editor.blur();
      this.editor.focus();
    },
    handleDragStart(event) {
      this.templateVariable = {
        field: this.field,
        label: this.label,
        offsetX: event.offsetX,
        offsetY: event.offsetY - (event.target.clientHeight / 2),
      };

      event.dataTransfer.setData(
        EVENT_DATA_TRANSFER_KEY,
        JSON.stringify(this.templateVariable),
      );
      this.inDragging = true;
    },
  },
};
export { EVENT_DATA_TRANSFER_KEY };
</script>

<style lang="scss">
.sk-template-variable-cursor {
  height: 18px;
  width: 3px;
  background-color: $sk-blue;
  position: relative;
  left: -30px;
  top: 5px;
}

.sk-template-variable {
  background-color: $sk-grey-5;
  border-radius: 3px;
  font-size: $fs-text-m;
  line-height: 17px;
  cursor: grab;
  width: 170px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
}

.sk-template-variable--in-panel {
  background-color: $sk-grey-5;
  opacity: 1;
  margin-bottom: 10px;
}

.sk-template-variable--in-dragging {
  background-color: $sk-blue;
  color: white;
  opacity: .85;

  .sk-template-variable__dot {
    background: white;
  }
}

.sk-template-variable--in-text {
  font-size: $fs-text-m;
  background-color: $sk-grey-5;
  border-radius: 3px;
  padding: 2px;
  text-align: center;
  cursor: not-allowed;

  .sk-template-variable__dot {
    &:first-child {
      margin-top: 0;
    }
  }

  .sk-template-variable__close-icon-fill {
    fill: $sk-grey;
  }

  &:hover {
    background-color: $sk-blue;
    color: white;

    .sk-template-variable__dot {
      background: white;
    }

    .sk-template-variable__close-icon-fill {
      fill: white;
    }
  }

  .sk-template-variable__label {
    margin-left: 7px;
  }

  .sk-template-variable__close-icon {
    margin-left: 16px;
    margin-right: 8px;
    vertical-align: text-top;

    &:hover {
      cursor: pointer !important;
    }
  }
}

.sk-template-variable__dots {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
}

.sk-template-variable__dot {
  background: $sk-grey;
  height: 2px;
  width: 2px;
  margin: 1px;
  border-radius: 50%;

  &:first-child {
    margin-top: 8px;
  }
}

.sk-template-variable__label {
  margin: auto;
}
</style>
