<template>
  <div class="hr-name-row-wrap">
    <a
      :href="employee.link"
      class="hr-name-row"
    >
      <div class="name-column__name">
        <div class="name-column__first-name">
          {{ employee.first_name }}
        </div>
        <div class="name-column__last-name">
          {{ employee.last_name }}
        </div>
      </div>
      <div
        :class="employee.contract_hours_class"
        class="name-column__contract"
      >
        <span v-if="!infos.interim_or_day_rate">
          {{ infos.contract_hours }}h
        </span>
        <span v-else-if="infos.on_day_rate_contract">
          {{ $t('reports.employee.day_rate') }}
        </span>
        <span v-else>
          {{ $t('reports.employee.extra') }}
        </span>
        <div
          v-if="isAnnualized"
          :class="employee.contract_hours_class"
        >
          {{ $t('reports.annualization.annualized') }}
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'NameRow',
  props: {
    employee: {
      type: Object,
      required: true,
    },
    infos: {
      type: Object,
      required: true,
    },
    isAnnualized: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-name-row-wrap {
  border-top: 1px solid #eee;
}

.hr-name-row {
  height: 60px;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  font-size: .9em;

  &:hover {
    text-decoration: none;
  }
}

.name-column__contract {
  text-align: right;
  font-weight: bold;
  font-size: .77em;
}

.name-column__annualized {
  color: #f0900d;
  font-size: 14px;
  font-weight: $fw-regular;
}

.admin-badge {
  color: #0d2ed9;
}

.manager-badge {
  color: #5fb96e;
}

.employee-badge {
  color: #e7a497;
}

.name-column__name {
  padding-right: 1em;
}

.name-column__first-name,
.name-column__last-name {
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #787e87;
}

.name-column__first-name {
  line-height: 1em;
  font-weight: bold;
}

.name-column__last-name {
  text-transform: uppercase;
}
</style>
