<template>
  <div :class="{'billing-line': collapsable}">
    <SkCollapse v-if="collapsable">
      <template slot="labelLeft">
        <div class="billing-line__header__left">
          <div class="billing-line__header__title-wrapper">
            <h2 class="billing-line__header__title">
              {{ localBillingLine.name }}
            </h2>
          </div>
        </div>
      </template>

      <template slot="content">
        <BillingLineContent
          v-model="localBillingLine"
          :needs-padding="true"
        />
      </template>
    </SkCollapse>

    <template v-else>
      <BillingLineContent
        v-model="localBillingLine"
        :needs-padding="false"
      />
    </template>
  </div>
</template>

<script>
import { SkCollapse } from '@skelloapp/skello-ui';
import BillingLineContent from './BillingLineContent.vue';

export default {
  name: 'BillingLine',
  components: {
    SkCollapse,
    BillingLineContent,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    collapsable: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    localBillingLine: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-line {
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: $fs-text-m;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__right {
      display: flex;
    }

    &__left {
      // needed for text-overflow ellipsis to work on customer-name
      // https://css-tricks.com/flexbox-truncated-text/
      min-width: 0;
    }

    &__title-wrapper {
      display: flex;
      align-items: center;
    }

    &__title {
      margin: 0;
      margin-right: 5px;
      font-size: $fs-text-l;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
