<template>
  <!-- eslint-disable  max-len -->
  <div
    :id="item.id"
    :class="cardClass"
    class="cluster_node-card"
    @dragover="setDragOver"
    @dragleave="onDragLeave"
    @click="handleCLick"
  >
    <div class="cluster_node-card-left-inner-container">
      <div
        v-if="draggable"
        class="cluster_node-drag_area"
      >
        <DragAreaSvg :fill="isCurrentCluster ? 'white' : '#ADB2B6'" />
      </div>
      <div
        :class="itemIconClass"
        class="cluster_node-card-icon"
      >
        <ShopIconSvg
          v-if="item.type === 'shop'"
          fill="#2b66fe"
        />
        <ClusterNodeIconSvg
          v-else
          :fill="iconFillingColor"
        />
      </div>
      <div class="cluster_node-info">
        <div
          :title="item.name"
          class="cluster_node-name"
        >
          {{ item.name }}
        </div>
        <div
          v-if="item.type === 'cluster_node'"
          class="cluster_node-stats"
        >
          {{
            $t('organisation_settings.tabs.clusters.card.label_nodes', {
              nb_node: item.nb_node,
              nb_shop: item.nb_shop
            })
          }}
        </div>
        <div
          v-else
          class="cluster_node-stats"
        >
          {{
            $tc('organisation_settings.tabs.clusters.card.label_employees', item.nb_employee)
          }}
        </div>
      </div>
    </div>
    <div
      v-if="item.type === 'cluster_node' && isNodeAndHasChildren"
      class="cluster_node-caret"
    >
      <AngleRight :fill="isCurrentCluster ? 'white' : '#2d2f30'" />
    </div>
  </div>
</template>

<script>
import DragAreaSvg from '../../assets/svg/DragAreaSvg';
import ClusterNodeIconSvg from '../../assets/svg/ClusterNodeIconSvg';
import ShopIconSvg from '../../assets/svg/ShopIconSvg';
import AngleRight from '../../assets/svg/AngleRight';

export default {
  components: {
    DragAreaSvg, ClusterNodeIconSvg, ShopIconSvg, AngleRight,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    handleClickOnCard: {
      type: Function,
      required: true,
    },
    clickedItemId: {
      type: Number,
      default: null,
    },
    currentClusterId: {
      type: Number,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    onDragOver: {
      type: Function,
      required: true,
    },
    onDragLeave: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isClicked() {
      return (this.item.id === this.clickedItemId);
    },
    isCurrentCluster() {
      return (this.item.id === this.currentClusterId);
    },
    isShop() {
      return (this.item.type === 'shop');
    },
    isCluster() {
      return (this.item.type === 'cluster_node');
    },
    itemIconClass() {
      return {
        'shop-icon': this.isShop,
        'cluster-icon': this.isCluster,
        'card-clicked': this.isClicked,
        'current-card': this.isCurrentCluster,
      };
    },
    cardClass() {
      return {
        'not-clickable': this.loading,
        'shop-placeholder': this.isShop && this.item.id === 'item-placeholder',
        'cluster-placeholder': this.isCluster && this.item.id === 'item-placeholder',
        'card-cluster': this.isCluster,
        'card-shop': this.isShop,
        'background-card-clicked': this.isClicked,
        'background-card-for-shop': this.isShop,
        'background-current-card': this.isCurrentCluster,
        draggable: this.draggable,
        'not-draggable': !this.draggable,
      };
    },
    iconFillingColor() {
      if (this.isCurrentCluster) {
        return '#0d2ed9';
      }
      if (this.isClicked) {
        return 'white';
      }

      return '#0d2ed9';
    },
    isNodeAndHasChildren() {
      return (this.item.nb_node + this.item.nb_shop) !== 0;
    },
  },
  methods: {
    handleCLick() {
      if (this.item.id === this.currentClusterId) return;
      this.handleClickOnCard(this.item);
    },
    setDragOver(evt) {
      this.onDragOver(this.item, evt);
    },
  },
};
</script>

<style lang="scss" scoped>
.cluster-placeholder {
  background-color: $sk-blue;
  color: white;
  opacity: .3;
}

.shop-placeholder {
  background-color: $sk-blue-50;
  color: white;
  opacity: .3;
}

.over-element {
  border: 2px solid #0d2ed9 !important;
  background-color: $sk-grey-10;
}

.not-droppable {
  opacity: .3;
}

.cluster_node-caret {
  margin-right: 12px;
}

.cluster_node-info {
  margin-left: 9px;
  max-width: 70%;
  min-width: 70%;
}

.cluster_node-name {
  font-size: $fs-text-m;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
}

.cluster_node-stats {
  font-size: $fs-text-s;
}

.cluster_node-card-icon {
  flex: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
}

.cluster-icon {
  background-color: #dbe3f9;

  &.card-clicked {
    background-color: $sk-blue;
  }

  &.current-card {
    background-color: #dbe3f9;
  }
}

.shop-icon {
  background-color: $sk-blue-10;
}

.cluster_node-card {
  cursor: pointer;
  height: 70px;
  border: 1px solid #e8eaed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .3s ease;
  width: 100%;
  margin-top: -1px;

  &.draggable {
    width: calc(100% - 18px);
    margin: 10px 9px;
    border-radius: 4px;

    &.background-card-clicked {
      border: 1px solid transparent;
    }

    &:hover {
      box-shadow: 0 2px 12px rgba(0, 0, 0, .15);
      border: 1px solid transparent;
    }
  }

  &.not-clickable {
    cursor: wait;

    &:hover {
      box-shadow: unset;
      border: 1px solid #e8eaed;
    }
  }

  &.not-draggable {
    &:hover {
      background-color: $sk-grey-10;
    }
  }
}

.cluster_node-card-left-inner-container {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
}

.cluster_node-drag_area {
  padding-left: 10px;
  padding-top: 28px;
  padding-right: 21px;
  cursor: grab;
  height: 70px;
  width: 8px;
}

.background-card-clicked {
  color: #2d2f30;
  background-color: $sk-grey-10;

  &.background-current-card {
    color: white;
    background-color: $sk-blue;
  }

  &.background-card-for-shop {
    color: white;
    background-color: $sk-blue-50;
  }
}
</style>
