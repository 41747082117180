<template>
  <SkModalV2
    id="annualization-config-modal"
    ref="annualizationConfigModal"
    :step-count="2"
    :submit-disabled="isSubmitDisabled"
    :submit-loading="isSubmitLoading"
    :title="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.modal_title')"
    :testing-options="{
      next: 'annualization__step-one-submit-button',
      submit: 'annualization__step-two-submit-button',
    }"
    show-progress-bar
    @close="handleClose"
    @cancel="handleClose"
    @go-to-next-step="handleNextStep"
    @submit="handleSubmit"
    @update-step="handleUpdateStep"
  >
    <template #body>
      <ParametersSection
        v-if="currentStep === 1"
        ref="parametersSection"
      />
      <HoursVolumeSection v-else />
    </template>
  </SkModalV2>
</template>

<script>
import isNaNFromLodash from 'lodash/isNaN';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import skDate from '@skello-utils/dates';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';

import ParametersSection from './ParametersSection';
import HoursVolumeSection from './HoursVolumeSection';

const isNaN = value => value === null || value === '' || isNaNFromLodash(value);

export default {
  name: 'AnnualizationConfigModal',
  components: { HoursVolumeSection, ParametersSection },
  props: {
  },
  data() {
    return {
      currentStep: 1,
      isSubmitLoading: false,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isAnnualizationContractHoursChangeEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopAnnualizationConfig', ['initializationDate', 'periodStartDate']),
    ...mapState('shopEmployees', ['annualizedUserIds', 'userAnnualizationConfigs']),
    isFirstStepValid() {
      return this.annualizedUserIds.length > 0 &&
        !!this.initializationDate &&
        !!this.periodStartDate;
    },
    isSecondStepValid() {
      const configValues = Object.values(this.userAnnualizationConfigs);
      const isNotMissingTheoreticalProperty = configValues.every(config => 'theoretical' in config);

      return configValues.length === this.annualizedUserIds.length &&
        !configValues.some(config => isNaN(config.theoretical)) &&
        isNotMissingTheoreticalProperty;
    },
    isSubmitDisabled() {
      return !(this.currentStep === 1 ? this.isFirstStepValid : this.isSecondStepValid);
    },
    isInitDateValid() {
      if (!this.periodStartDate || !this.initializationDate) {
        return false;
      }
      const startDate = skDate.utc(this.periodStartDate);
      const initializationDate = skDate.utc(this.initializationDate);
      return startDate.isSameOrBefore(initializationDate);
    },
    shouldNotShowUnsavedWarning() {
      const noChangesInParameterSection = !this.$refs.parametersSection?.isAnyChanges;

      return this.currentStep === 1 && noChangesInParameterSection;
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    ...mapActions('shopAnnualizationConfig', ['createShopAnnualizationConfig']),
    ...mapMutations('shopAnnualizationConfig', ['setInitializationDate', 'setPeriodStartDate']),
    ...mapMutations('shopEmployees', ['setUserAnnualizationConfigs', 'setAnnualizedUserIds']),
    handleClose(event) {
      if (this.shouldNotShowUnsavedWarning) {
        this.hideModal();
        return;
      }

      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          if (this.currentStep === 1) {
            this.$skAnalytics.track('annualization_cancel_step1');
          } else {
            this.$skAnalytics.track('annualization_cancel_step2');
          }
          return this.hideModal();
        },

      });
    },
    handleNextStep(event) {
      if (!this.isFirstStepValid) {
        event.preventDefault();
        return;
      }

      if (!this.isInitDateValid) {
        event.preventDefault();
        this.$skToast({
          message: this.$t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.error_initialization_date'),
          variant: 'error',
        });
        return;
      }

      this.$skAnalytics.track('annualization_click_next_activation');
    },
    handleSubmit(event) {
      event.preventDefault();

      this.isSubmitLoading = true;

      const shopId = this.currentShop.id;

      const startDate = skDate.utc(this.periodStartDate);
      const initializationDate = skDate.utc(this.initializationDate);

      startDate.set('year', initializationDate.year());

      if (startDate.isAfter(initializationDate)) {
        startDate.subtract(1, 'year');
      }

      const resetDate = startDate.clone().add(1, 'year').toISOString();

      const shopParams = {
        shop_id: shopId,
        annualization_config: {
          initialization_date: this.initializationDate,
          reset_date: resetDate,
          solidarity_day_enabled: true,
          overhours_activated: false,
        },
      };

      const employeesParams = Object
        .entries(this.userAnnualizationConfigs)
        .map(([userId, config]) => {
          const params = {
            userId,
            shopId,
            initializationDate: this.initializationDate,
            theoreticalBalances: { [startDate.valueOf()]: config.theoretical },
            initializationRealizedBalance: config.initializationRealizedBalance ?? 0,
            initializationRemainingBalance: 0,
            initializationPtoCount: 25,
          };

          if (this.isAnnualizationContractHoursChangeEnabled) {
            params.contractHours = { [startDate.valueOf()]: config.contractHours };
          }

          return params;
        });

      svcEmployeesClient.bulkCreateAnnualizationConfigs(employeesParams)
        .then(() => {
          this.sendEventLog();

          this.createShopAnnualizationConfig(shopParams)
            .then(() => {
              this.$skAnalytics.track('annualization_activate');
              this.setShopAttributes({ isAnnualizationV2Active: true });
              this.$skToast({
                message: this.$t('shop_settings.tabs.rules.counter_rules.annualization_v2.init_complete'),
                variant: 'success',
              });
            });
        })
        .catch(() => this.$skToast({
          message: this.$t('shop_settings.tabs.rules.counter_rules.annualization_v2.init_error'),
          variant: 'error',
        }))
        .finally(() => {
          this.isSubmitLoading = false;
          this.hideModal();
        });
    },
    handleUpdateStep(newStep) {
      const lastStep = this.currentStep;
      this.currentStep = newStep;

      if (lastStep === 2 && newStep === 1) {
        this.$skAnalytics.track('annualization_click_previous_activation');
        this.$nextTick(() => {
          this.$refs.parametersSection.isAnyChanges = true;
        });
      }

      if (this.currentStep === 1) {
        // reset annualization configs when back to step 1
        this.setUserAnnualizationConfigs({});
      }
    },
    hideModal() {
      this.resetData();
      this.$refs.annualizationConfigModal.hide();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
      this.setAnnualizedUserIds([]);
      this.setInitializationDate(null);
      this.setPeriodStartDate(null);
      this.$refs.parametersSection?.resetData();
    },
    async sendEventLog() {
      try {
        await this.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_ANNUALIZATION_TRACKER_ENABLED);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .annualization-modal__parameters-section {
    &__selection-container {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }

    &__selection-row > *:first-child {
      flex-grow: 1;
      margin-left: 16px;
    }

    &__selection-row > *:last-child {
      width: 417px;
      margin-left: 16px;
    }
  }
</style>
