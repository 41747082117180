<template>
  <tr class="automatic-planning-row">
    <td class="automatic-planning-row__switch-cell">
      <SkSwitch
        v-model="active"
        :disabled="switchDisabled"
      />
    </td>
    <td :class="descriptionClasses">
      <div class="automatic-planning-row__cell">
        <div class="automatic-planning-row__info-wrapper">
          <div v-if="includeInput">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(`${descriptionString}.description1`)" />

            <SkInput
              v-model="value"
              :disabled="inputsDisabled"
              :errored="errored"
              type="number"
              class="automatic-planning-row__input sk-input--center"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(`${descriptionString}.description2`)" />
          </div>
          <div v-else>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="$t(`${descriptionString}.description`)" />
          </div>
          <div
            v-if="infoMessage"
            class="automatic-planning-row__info"
          >
            <CircledQuestionMarkIcon
              v-tooltip="infoMessage"
              height="18"
              width="18"
            />
          </div>
        </div>
        <div
          v-if="includeCheckBox"
          class="automatic-planning-row__check-box-wrapper"
        >
          <SkCheckBox
            v-model="optionalValue"
            :disabled="inputsDisabled"
            height="18"
            width="18"
            :label="checkBoxMessage"
          />
        </div>
        <div
          v-if="errorMessage"
          class="automatic-planning-row__error"
        >
          {{ errorMessage }}
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'RulesRow',
  props: {
    automaticPlanningRule: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    includeInput: {
      type: Boolean,
      required: true,
    },
    includeCheckBox: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    active: {
      get() {
        return this.automaticPlanningRule.attributes.active;
      },
      set(newValue) {
        this.$emit('toggle-active', this.automaticPlanningRule.id, newValue);
      },
    },
    optionalValue: {
      get() {
        return this.automaticPlanningRule.attributes.optionalValue;
      },
      set(newValue) {
        this.$emit('toggle-optional-value', this.automaticPlanningRule.id, newValue);
      },
    },
    value: {
      get() {
        return this.automaticPlanningRule.attributes.value;
      },
      set(newValue) {
        this.$emit('change', this.automaticPlanningRule.id, newValue);
      },
    },
    switchDisabled() {
      return this.disabled || !this.currentLicense.attributes.canEditShopRulesAndAbsences;
    },
    inputsDisabled() {
      return !this.active || !this.currentLicense.attributes.canEditShopRulesAndAbsences;
    },
    descriptionClasses() {
      return {
        'automatic-planning-row__description': true,
        'automatic-planning-row__description--disabled': !this.active,
      };
    },
    descriptionString() {
      const kind = this.automaticPlanningRule.attributes.kind;
      return `shop_settings.tabs.automatic_planning.rules.${kind}`;
    },
    errored() {
      return !!this.errorMessage;
    },
    // Question mark icon only shows if it's defined in i18n
    // Else it returns '' (no error)
    infoMessage() {
      const ruleKind = this.automaticPlanningRule.attributes.kind;
      const translationKey = `shop_settings.tabs.automatic_planning.rule_messages.${ruleKind}`;
      // Check if key exists in i18n
      if (!this.$te(translationKey)) {
        return '';
      }

      return this.$t(translationKey);
    },
    checkBoxMessage() {
      if (this.includeCheckBox) {
        const ruleKind = this.automaticPlanningRule.attributes.kind;
        return this.$t(`shop_settings.tabs.automatic_planning.check_box_messages.${ruleKind}`);
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning-row:last-child td {
  border-bottom: 0;
}

.automatic-planning-row__cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0;
}

.automatic-planning-row__check-box-wrapper {
  width: 170px;
}

.automatic-planning-row__input {
  display: inline-flex;
  width: 50px;

  &::before {
    top: 23px;
  }

  // Override height to match alert inputs in AlertRow
  ::v-deep .sk-input__input {
    height: 23px;
  }

  // error message already managed - not in input. Don't show div
  ::v-deep .sk-input__error-wrapper {
    display: none;
  }
}

.automatic-planning-row__switch-cell {
  padding-right: 44px;
}

.automatic-planning-row__description {
  width: 100%;
  position: relative;
}

.automatic-planning-row__description--disabled {
  opacity: .3;
}

.automatic-planning-row__info-wrapper {
  display: flex;
}

.automatic-planning-row__info {
  display: flex;
  align-items: center;
  margin-left: 13px;
}

.automatic-planning-row__error {
  font-size: $fs-text-s;
  line-height: 15px;
  color: $sk-error;
  position: absolute;
  bottom: 6px;
}
</style>
