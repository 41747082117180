<template>
  <div class="editor-navbar">
    <div class="editor-navbar__left">
      <SkCircleButton
        icon="ArrowLeftIcon"
        @click="handleBack"
      />
      <div class="sk-divider--light editor-navbar__divider" />
      <div
        v-if="!loadingFetch"
        class="editor-navbar__infos"
      >
        <div class="editor-navbar__infos__title">
          <TitleInput
            v-model="localTitle"
            :editable="titleEditable"
          />
        </div>
        <div class="editor-navbar__infos__labels">
          <div>{{ dateLabel }}</div>
          <div
            v-if="creator"
            class="dot-link__dot"
          />
          <div v-if="creator">
            {{ fullName(creator) }}
          </div>
        </div>
      </div>
    </div>
    <div class="editor-navbar__actions-wrapper">
      <div
        v-if="saveModalTag"
        v-modal="saveModalTag"
        class="editor-navbar__save-button__wrapper"
      >
        <SkOroraButton v-track="'text_document_template_save'">
          {{ buttonLabel }}
        </SkOroraButton>
      </div>
      <SkOroraButton
        v-else
        @click="$emit('save')"
      >
        {{ buttonLabel }}
      </SkOroraButton>
      <div class="sk-divider--light editor-navbar__divider" />
      <UserDropdown />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';

import UserDropdown from '../../shared/components/Navbar/UserDropdown';
import TitleInput from './TitleInput';

export default {
  name: 'EditorNavbar',
  components: { TitleInput, UserDropdown },
  props: {
    title: {
      type: String,
      default: null,
    },
    titleEditable: {
      type: Boolean,
      default: true,
    },
    saveModalTag: {
      type: String,
      default: null,
      validator: value => [
        'save-text-document-modal',
        'save-text-document-template-modal',
      ].includes(value),
    },
  },
  data() {
    return {
      localTitle: this.title,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('textDocumentTemplates', {
      loadingFetch: state => state.loadingFetch,
      textDocumentTemplate: state => state.selectedTextDocumentTemplate,
    }),
    ...mapGetters({ fullName: 'employees/fullName' }),
    creator() {
      if (this.textDocumentTemplate?.relationships?.creator) {
        return this.textDocumentTemplate.relationships.creator;
      }
      if (this.isNew) return this.currentUser;
      return null;
    },
    isNew() {
      return !this.textDocumentTemplate || !this.textDocumentTemplate.id;
    },
    buttonLabel() {
      return this.$t(
        `text_document_templates.editor.${this.isNew ? 'create_button' : 'update_button'}`,
      );
    },
    dateLabel() {
      if (this.isNew) {
        return this.$t('text_document_templates.editor.in_creation');
      }

      return this.$t('text_document_templates.editor.updated_on', {
        date: this.formattedUpdatedDate,
      });
    },
    formattedUpdatedDate() {
      const dateString = this.textDocumentTemplate.attributes.updatedAt;
      if (!dateString) return '';

      return skDate(dateString).format('ll');
    },
  },
  watch: {
    title(newTitle) {
      if (newTitle === this.localTitle) return;
      this.localTitle = newTitle;
    },
    localTitle(newTitle) {
      if (this.titleEditable && newTitle !== this.title) {
        this.$emit('title-changed', newTitle);
      }
    },
  },
  methods: {
    handleBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'shop_settings_documents_management' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-navbar {
  width: 100vw;
  height: 65px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 30px;
  font-size: $fs-text-m;
  border-bottom: 1px solid $sk-grey-10;

  @media (max-width: $md-width) {
    padding: 0 20px;
  }

  .editor-navbar__left {
    display: flex;
    align-items: center;

    .dot-link__dot {
      background: $sk-grey;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      display: block;
      margin: 0 7px;
    }

    .editor-navbar__infos {
      .editor-navbar__infos__title {
        display: flex;
        align-items: center;

        .editor-navbar__infos__title__button {
          margin-left: 10px;
          display: none;
        }

        &:hover {
          .editor-navbar__infos__title__button {
            display: block;
          }
        }
      }

      .editor-navbar__infos__labels {
        display: flex;
        align-items: center;
        color: $sk-grey;
        font-size: .85em;
        padding-top: 3px;
      }
    }
  }

  .editor-navbar__actions-wrapper {
    display: flex;
    margin-left: auto;
    align-items: center;
    height: 80px;
  }

  .editor-navbar__save-button__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: 25px;

    @media (max-width: $md-width) {
      margin-left: 12px;
    }
  }

  .editor-navbar__divider {
    margin: 0 25px;

    @media (max-width: $md-width) {
      margin: 0 15px;
    }
  }
}
</style>
