export const getI18nAbsenceKey = (
  absencesCountry,
  absenceKey,
) => {
  if (absenceKey === 'restricted' || !absenceKey || !absencesCountry) return 'absence_sidepanel.absences.restricted';

  const parsedCountry = absencesCountry.toLowerCase();
  const parsedKey = absenceKey.toLowerCase();

  return `absence_sidepanel.absences.${parsedCountry}.${parsedKey}`;
};
