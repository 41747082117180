<template>
  <!-- LOADING -->
  <SkModal
    v-if="isLoading"
    id="publish-planning-modal"
    ref="publishPlanningModal"
    :modal-title="$t('plannings.toolbar.modal.publish_planning.header.title')"
    :modal-subtitle="subtitle"
    :display-footer="false"
    @show="$emit('show')"
    @close="$emit('close')"
  >
    <template
      #body
    >
      <SkModalSection border-bottom="none">
        <LoaderContainer />
      </SkModalSection>
    </template>
  </SkModal>
  <!-- FINISHED LOADING -->
  <SkModal
    v-else
    id="publish-planning-modal"
    ref="publishPlanningModal"
    :modal-title="$t('plannings.toolbar.modal.publish_planning.header.title')"
    :modal-subtitle="subtitle"
    @show="$emit('show')"
    @close="$emit('close')"
  >
    <template
      #body
    >
      <SkModalSection border-bottom="none">
        <slot name="content" />

        <NotificationOptions v-bind.sync="notificationOptions" />
      </SkModalSection>
    </template>

    <template
      v-if="!isLoading"
      #submit-btn
    >
      <SkOroraButton
        v-track="'click_on_publish_planning'"
        :disabled="isButtonDisabled"
        :loading="isPublishing"
        data-test="publish-planning-modal__submit"
        @click="$emit('submit', notificationOptions)"
      >
        {{ $t('plannings.toolbar.modal.publish_planning.action.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';
import NotificationOptions from './NotificationOptions';

export default {
  name: 'ModalBase',
  components: {
    NotificationOptions,
    LoaderContainer,
  },
  props: {
    subtitle: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isButtonDisabled: {
      type: Boolean,
      required: true,
    },
    isPublishing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      notificationOptions: {
        email: false,
        sms: false,
        emailAdditionalComment: null,
      },
    };
  },
  computed: {
    ...mapState('planningsState', ['userPlanningConfig']),
  },
  mounted() {
    this.notificationOptions.email = this.userPlanningConfig.attributes.publishSendMail;
    this.notificationOptions.sms = this.userPlanningConfig.attributes.publishSendSms;
  },
};
</script>

<style lang="scss" scoped>

::v-deep .sk-modal {
  .sk-modal__header .sk-modal__subtitle {
    margin-top: 6px;
  }

  .sk-modal__section {
    padding: 24px;
  }
}
</style>
