<template>
  <SkSidePanel
    id="absence-side-panel"
    :class="sidePanelClasses"
    :title="$t('absence_sidepanel.header.title')"
    @closed="closeAbsencesSidePanel"
  >
    <template #searchbar>
      <div class="absence-sidebar__searchbar">
        <SkSearch
          v-model="localQuery"
          :placeholder="$t('absence_sidepanel.header.search_placeholder')"
          size="medium"
          @focus="toggleFocusSearch"
          @blur="toggleFocusSearch"
        />
      </div>
    </template>
    <template #body>
      <div v-if="absencesLoaded">
        <div v-if="modulation && checkPackOfferFlag('hours_counter_enabled')">
          <AbsenceSection
            :absences="absenceByType('off_counter')"
            :title="$t('absence_sidepanel.section.in_modulation.off_counter')"
            :current-shop="referenceShop"
          />
          <AbsenceSection
            :absences="absenceByType('in_counter')"
            :title="$t('absence_sidepanel.section.in_modulation.in_counter')"
            :current-shop="referenceShop"
          />
          <AbsenceSection
            :absences="absenceByType('neutral')"
            :title="$t('absence_sidepanel.section.in_modulation.neutral')"
            :current-shop="referenceShop"
          />
        </div>
        <div v-else>
          <AbsenceSection
            :absences="absenceByType('indemnified_by_employer')"
            :title="$t('absence_sidepanel.section.no_modulation.indemnified_by_shop')"
            :current-shop="referenceShop"
          />
          <AbsenceSection
            :absences="absenceByType('indemnified_by_other')"
            :title="$t('absence_sidepanel.section.no_modulation.indemnified_by_third')"
            :current-shop="referenceShop"
          />
          <AbsenceSection
            :absences="absenceByType('not_indemnified')"
            :title="$t('absence_sidepanel.section.no_modulation.no_indemnified')"
            :current-shop="referenceShop"
          />
          <AbsenceSection
            :absences="absenceByType('neutral')"
            :title="$t('absence_sidepanel.section.no_modulation.neutral')"
            :current-shop="referenceShop"
          />
        </div>
      </div>
      <div
        v-else
        class="absence-panel__spinner__wrapper"
      >
        <SkLoader size="medium" />
      </div>
    </template>
  </SkSidePanel>
</template>

<script>
import debounce from 'lodash/debounce';
import deburr from 'lodash/deburr';
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import { SIDEPANEL_SHOW_EVENT } from '@skelloapp/skello-ui';
import AbsenceSection from './AbsenceSection';

export default {
  name: 'AbsenceSidePanel',
  components: { AbsenceSection },
  data() {
    return {
      searchQuery: '',
      absences: null,
      filteredAbsences: null,
      searchIsOnFocus: false,
      showPanel: false,
      absencesLoaded: false,
      referenceShop: {},
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['absenceSidePanelOpeningOrigin']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),

    localQuery: {
      get() {
        return this.searchQuery;
      },
      set(query) {
        this.searchQuery = query;
        if (query.trim().length === 0) {
          this.filteredAbsences = this.absences;
        } else {
          this.debouncedTrack();
          this.debouncedSearch(query.trim());
        }
      },
    },

    modulation() {
      return this.referenceShop.attributes.modulation;
    },

    sidePanelClasses() {
      return {
        'sk-sidepanel__wrapper--from-shift-modal': this.absenceSidePanelOpeningOrigin === 'shift-modal',
      };
    },
  },
  mounted() {
    this.listenOnRoot('open-absences-panel', this.showHandler);
    this.listenOnRoot('close-absences-panel', this.closeAbsencesSidePanel);

    this.debouncedSearch = debounce(query => {
      const searchResult = this.absences.filter(
        absence => this.matchName(absence.attributes.name, query),
      );
      this.filteredAbsences = searchResult;
    }, 200);

    this.debouncedTrack = debounce(() => {
      if (this.searchQuery) {
        this.$skAnalytics.track('absence_side_bar_search');
      }
    }, 1500);
  },
  methods: {
    ...mapMutations('planningsState', ['setAbsenceSidePanelOpeningOrigin']),

    showHandler(event) {
      this.showPanel = true;
      this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'absence-side-panel');

      // shop is only defined when coming from employees all view
      this.referenceShop = event?.shop && this.currentShop?.id === 'all' ?
        event.shop : this.currentShop;
      this.fetchAbsences();
    },
    fetchAbsences() {
      return httpClient
        .get('/v3/api/absences', {
          params: {
            shop_id: this.referenceShop.id,
          },
        })
        .then(response => {
          this.absences = this.sortByName(response.data.data);
          this.filteredAbsences = this.absences;
        })
        .catch(e => {
          this.$skToast({
            message: this.$t('alert_messages.default'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.absencesLoaded = true;
        });
    },

    absenceByType(absenceType) {
      if (this.absencesLoaded) {
        return this.filteredAbsences.filter(absence => (
          absence.attributes.absenceType === absenceType
        ));
      }
      return [];
    },

    matchName(absenceName, query) {
      return deburr(absenceName.toLowerCase()).includes(deburr(query).toLowerCase());
    },

    clearQuery() {
      this.searchQuery = '';
    },

    toggleFocusSearch() {
      this.searchIsOnFocus = !this.searchIsOnFocus;
    },

    closeAbsencesSidePanel() {
      if (!this.showPanel) return;

      this.clearQuery();
      this.showPanel = false;
      this.setAbsenceSidePanelOpeningOrigin(null);
    },

    sortByName(array) {
      return array.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
    },
  },
};
</script>

<style lang="scss" scoped>
.absence-panel__spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: $sk-blue;
}

.sk-sidepanel__wrapper--from-shift-modal.sk-sidepanel__wrapper {
  z-index: 12;
  background: none;
  pointer-events: none;

  /* Fix issue where click on modal would always close sidepanel */
  ::v-deep .sk-sidepanel__container {
    pointer-events: auto;
  }
}

.absence-sidebar__searchbar {
  padding-top: 5px;
}
</style>@
