<template>
  <!-- margin-right-position does not exist on DS mondal -> need to fin a solution  -->
  <SkKpisModal
    id="user-kpis-settings-option-modal"
    ref="optionsModal"
    :close="close"
    :border-bottom="false"
    :display-footer="false"
    class="kpis__options-modal"
    width="300px"
    sk-modal-right-position
  >
    <template #header>
      {{ $t('kpis.modals.options.title') }}
    </template>
    <template #body>
      <div
        v-for="(value, setting) in sortedUserKpisSettings"
        :key="setting"
        class="kpis__option-container"
      >
        <div class="kpis__option-title">
          {{ kpiOptionTitle(setting) }}
        </div>
        <div class="kpis__option-switch">
          <SkLoader
            v-show="isUserKpiSettingLoading(setting)"
            size="small"
          />
          <SkSwitch
            v-show="!isUserKpiSettingLoading(setting)"
            v-tooltip.right-start="tooltipFor(setting)"
            :value="value"
            :disabled="disableSetting(setting)"
            @input="handleUpdateUserKpisSettings({ $event, setting })"
          />
        </div>
      </div>
    </template>
  </SkKpisModal>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { camelToSnake } from '@skello-utils/formatting/strings';
import SkKpisModal from '../SkKpisModal';

export default {
  name: 'OptionsModal',
  components: { SkKpisModal },
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsKpis', ['userKpisSettings', 'userKpisSettingLoading']),
    ...mapState('currentUser', ['currentUser']),
    // Force sorting keys for display
    sortedUserKpisSettings() {
      return {
        volume: this.userKpisSettings.volume,
        averageTicket: this.userKpisSettings.averageTicket,
        volumeProductivity: this.userKpisSettings.volumeProductivity,
        totalSalaryMass: this.userKpisSettings.totalSalaryMass,
        totalSalaryMassWithCosts: this.userKpisSettings.totalSalaryMassWithCosts,
        salaryMassProductiveWithCosts: this.userKpisSettings.salaryMassProductiveWithCosts,
        salaryMassUnproductiveWithCosts: this.userKpisSettings.salaryMassUnproductiveWithCosts,
        salaryMassRatio: this.userKpisSettings.salaryMassRatio,
        productivity: this.userKpisSettings.productivity,
      };
    },
  },
  methods: {
    ...mapActions('planningsKpis', ['updateUserKpisSettings']),
    handleUpdateUserKpisSettings(params) {
      this.updateUserKpisSettings({
        newValue: params.$event,
        attribute: camelToSnake(params.setting),
        userId: this.currentUser.id,
      }).then(() => {
        this.$skToast({
          message: this.$t('kpis.modals.options.toasts.success'),
          variant: 'success',
          containerId: 'kpis__wrapper',
        });
      }).catch(error => {
        this.$skToast({
          message: this.$t('kpis.modals.options.toasts.error'),
          variant: 'error',
          containerId: 'kpis__wrapper',
        });
      });
    },
    disableSetting(setting) {
      if (setting !== 'salaryMassUnproductiveWithCosts' && setting !== 'salaryMassProductiveWithCosts') {
        return false;
      }

      return !this.userKpisSettings.totalSalaryMassWithCosts;
    },
    tooltipFor(setting) {
      if (setting !== 'salaryMassUnproductiveWithCosts' && setting !== 'salaryMassProductiveWithCosts') {
        return '';
      }

      if (this.userKpisSettings.totalSalaryMassWithCosts) return '';

      return this.$t('kpis.modals.options.tooltip');
    },
    kpiOptionTitle(setting) {
      if (setting === 'salaryMassUnproductiveWithCosts' || setting === 'salaryMassProductiveWithCosts') {
        setting += '_full_name';
      }

      return this.$t(`kpis.attributes.${camelToSnake(setting)}.name`);
    },
    isUserKpiSettingLoading(setting) {
      return this.userKpisSettingLoading[camelToSnake(setting)];
    },
  },
};
</script>

<style lang="scss" scoped>
.kpis__options-modal {
  color: $sk-black;
}

.kpis__option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px -2px 0;
  height: 36px;

  .kpis__option-title {
    max-width: 200px;
    font-style: normal;
    font-weight: normal;
    font-size: $fs-text-m;
    flex-wrap: nowrap;
    display: flex;
    height: 100%;
    max-height: 44px;
    align-items: center;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.kpis__option-switch {
  width: 52px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.sk-modal__closing-x {
  position: relative;
}
</style>
