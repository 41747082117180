<template>
  <SkModalV2
    id="rtt-modal"
    ref="rttModal"
    :title="$t('shop_settings.rtt_modal.title')"
    @show="handleShow"
    @cancel="confirmCancel"
    @close="confirmCancel"
  >
    <template
      v-if="isLoading"
      #body
    >
      <div class="rtt-modal__init--align_center__flex__justify_center">
        <SkLoader size="x-large" />
      </div>
    </template>
    <template
      v-else
      #body
    >
      <SkModalSectionV2>
        <div class="rtt-modal__init-row">
          <div class="rtt-modal__init--align_center__flex rtt-modal__init--bold">
            {{ $t('shop_settings.rtt_modal.acquisition_method.title') }}
            <CircledINoFillIcon
              v-tooltip.longform="rttActivationTooltip"
              class="rtt-modal__init-acquisition_method-info"
              width="18"
              height="30"
            />
          </div>
          <div class="rtt-modal__init--align_center__flex">
            <SkRadio
              id="monthly"
              v-model="shopAbsenceConfigParams.acquisitionMode"
              class="rtt-modal__init-sk_radio"
              data-value="monthly"
            >
              <label
                for="monthly"
                class="label--clickable rtt-modal__init-label"
              >
                {{ $t('shop_settings.rtt_modal.acquisition_method.monthly') }}
              </label>
            </SkRadio>
            <SkRadio
              id="annually"
              v-model="shopAbsenceConfigParams.acquisitionMode"
              class="rtt-modal__init-sk_radio"
              data-value="annually"
            >
              <label
                for="annually"
                class="label--clickable rtt-modal__init-label"
              >
                {{ $t('shop_settings.rtt_modal.acquisition_method.annually') }}
              </label>
            </SkRadio>
          </div>
        </div>
        <div class="rtt-modal__init-acquisition_period rtt-modal__init-row">
          <div class="rtt-modal__init--bold">
            {{ $t('shop_settings.rtt_modal.acquisition_period') }}
          </div>
          <SkDatePicker
            v-model="resetDateDisplay"
            class="rtt__start-date-datepicker"
            :disabled-date="disabledDate"
            :lang="$i18n.locale"
            :placeholder="$t('shop_settings.rtt_modal.initialisation_date')"
            type="month"
            data-test="rtt-counter-modal__acquisition-date__input"
            append-to-body
            hide-footer
          >
            <template
              v-if="shopAbsenceConfigParams.resetDate"
              #input
            >
              <span class="rtt__start-date-datepicker__label">
                {{ rttPeriodLabel }}
              </span>
            </template>
          </SkDatePicker>
        </div>
        <div class="rtt-modal__init-row">
          <div class="rtt-modal__init--bold">
            {{ $t('shop_settings.rtt_modal.start_date.title') }}
          </div>
          <div class="rtt-modal__datepicker-wrapper">
            <SkDatePicker
              v-model="shopAbsenceConfigParams.initializationDate"
              :placeholder="$t('shop_settings.rtt_modal.start_date.placeholder')"
              :error-message="$t('errors.missing_value')"
              :lang="$i18n.locale"
              input-moment-format="MMMM YYYY"
              type="month"
              data-test="rtt-counter-modal__init-date__input"
              hide-footer
              popup-extra-class="rtt-modal__datepicker-popup"
            />
            <p class="rtt-modal__date-hint sk-subtitle--small">
              {{ $t('shop_settings.rtt_modal.start_date.hint') }}
            </p>
          </div>
        </div>
      </SkModalSectionV2>
      <SkModalSectionV2
        :padding-bottom="false"
        border-bottom="none"
      >
        <div class="paid-leaves-counter-modal__balance__header rtt-modal__init-row">
          <span class="rtt-modal__init--bold">
            {{ $t('shop_settings.rtt_modal.balance.title') }}
          </span>
          <SkSwitch
            v-model="isBalanceActivated"
            class="rtt-modal__balance__switch"
          />
        </div>
        <p class="sk-subtitle--medium">
          {{ $t('shop_settings.rtt_modal.balance.explanation') }}
        </p>
        <div v-if="isBalanceActivated">
          <SkInfoCard class="rtt-modal__balance__card">
            <div>
              {{ $t('shop_settings.rtt_modal.balance.explanation_card') }}
            </div>
          </SkInfoCard>
          <UserBalanceRow
            v-for="user in dailyRateUsers"
            :key="user.attributes.id"
            :user-initials="userInitials(user)"
            :user-full-name="fullName(user)"
            :initialization-counter="employeesAbsenceConfig[user.id].initializationCounter"
            @new-balance="value => employeesAbsenceConfig[user.id].initializationCounter = value"
          />
        </div>
        <div
          v-if="isPendingWanted && isBalanceActivated"
          class="rtt-modal__balance__pending-banner"
        >
          <SkInnerBanner
            variant="danger"
            rounded
          >
            {{ $t('shop_settings.rtt_modal.pending_alert.alert') }}
          </SkInnerBanner>
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="'rtt_counter_activation_submit'"
        :disabled="isLoadingForSubmit || isFormNotFilled"
        :loading="isLoadingForSubmit"
        data-test="rtt-counter-modal__submit__button"
        @click="handleSubmit"
      >
        {{ submitText }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import isNanFromLodash from 'lodash/isNaN';
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';

import { computeHistoryRecoveryUpdate } from '@skelloapp/skello-absences';
import { nlToBr } from '@skello-utils/formatting/strings';
import { msHttpClient } from '@skello-utils/clients';
import { svcEmployeesUrl } from '@config/env';

import UserBalanceRow from '@app-js/shared/components/CountersInitializationModal/RttCounterInitializationModal/UserBalanceRow';

export default {
  name: 'RttConfigModal',
  components: { UserBalanceRow },
  data() {
    return {
      shopAbsenceConfigParams: {
        acquisitionMode: '',
        resetDate: null,
        initializationDate: null,
      },
      resetDate: null,
      employeesAbsenceConfig: {},
      isBalanceActivated: false,
      isPendingWanted: false,
      isLoadingForSubmit: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopAbsenceConfig', ['shopAbsenceConfig']),
    ...mapState('config', ['config']),
    ...mapState('shopEmployees', ['employees']),
    ...mapGetters('config', ['dailyRateCounterType']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    resetDateDisplay: {
      get() {
        return this.resetDate;
      },
      set(value) {
        this.resetDate = value;
        if (!value) {
          this.shopAbsenceConfigParams.resetDate = null;
          return;
        }
        this.shopAbsenceConfigParams.resetDate = skDate.utc(value).add(1, 'year').format('YYYY-MM-DD');
      },
    },
    rttPeriodLabel() {
      if (!this.resetDate) return '';
      const startDate = skDate.utc(this.resetDate);
      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');
      return `${startDate.format('DD/MM/YYYY')} - ${endDate.format('DD/MM/YYYY')}`;
    },
    areUserBalancesEmptyOrNaN() {
      const isNaN = field => field === null || field === '' || isNanFromLodash(field);
      return Object.values(this.employeesAbsenceConfig).some(
        employeesAbsenceConfig => isNaN(employeesAbsenceConfig.initializationCounter),
      );
    },
    submitText() {
      return this.isPendingWanted ?
        this.$t('shop_settings.rtt_modal.pending_alert.submit_pending') :
        this.$t('shop_settings.rtt_modal.pending_alert.submit');
    },
    isFormNotFilled() {
      return !this.shopAbsenceConfigParams.resetDate ||
        !this.shopAbsenceConfigParams.initializationDate ||
        !this.shopAbsenceConfigParams.acquisitionMode;
    },
    dailyRateUsers() {
      return this.employees.filter(employee => (
        employee.relationships.contract.attributes.counterType === this.dailyRateCounterType
      ));
    },
    rttActivationTooltip() {
      return nlToBr(this.$t('shop_settings.rtt_modal.acquisition_method.tooltip'));
    },
  },
  methods: {
    ...mapActions('shopAbsenceConfig', ['createAbsenceConfig']),
    handleShow() {
      if (this.shopAbsenceConfig &&
        this.shopAbsenceConfig.attributes.rttConfig.initialization_status ===
        this.config.shop_absence_config.pending) {
        this.isLoading = true;
        this.resetDate =
          skDate.utc(this.shopAbsenceConfig.attributes.rttConfig.reset_date).subtract(1, 'y').format('YYYY-MM-DD');
        this.shopAbsenceConfigParams = {
          acquisitionMode: this.shopAbsenceConfig.attributes.rttConfig.acquisition_mode,
          initializationDate: this.shopAbsenceConfig.attributes.rttConfig.initialization_date,
          resetDate: this.shopAbsenceConfig.attributes.rttConfig.reset_date,
        };
        let userConfigs = null;

        msHttpClient.get(`${svcEmployeesUrl}/v1/absence-configs/${this.currentShop.id}/RTT`)
          .then(response => {
            userConfigs = response.data;
            this.dailyRateUsers.forEach(employee => {
              const userConfig =
                userConfigs?.find(user => user.userId === employee.id);
              this.employeesAbsenceConfig[employee.id] = {
                initializationCounter: userConfig?.updates[0]?.value ?? null,
              };
              this.isBalanceActivated = true;
              this.isLoading = false;
            });
          });
      } else {
        this.shopAbsenceConfigParams.resetDate = null;
        this.resetDate = null;
        this.dailyRateUsers.forEach(employee => {
          this.employeesAbsenceConfig[employee.id] = {
            initializationCounter: null,
          };
        });
      }
      this.isPendingWanted = false;
    },
    handleSubmit() {
      // condition when all users balance are not filled && balance is activated
      if (this.isBalanceActivated && this.areUserBalancesEmptyOrNaN && !this.isPendingWanted) {
        this.isPendingWanted = true;
        this.$nextTick(() => {
          this.$refs.rttModal.scrollToBottom();
        });
        return;
      }
      const shopAbsenceConfigParams = {
        shop_id: this.currentShop.id,
        shopAbsenceConfig: {
          rtt_config: {
            acquisition_mode: this.shopAbsenceConfigParams.acquisitionMode,
            initialization_date: this.shopAbsenceConfigParams.initializationDate,
            reset_date: this.shopAbsenceConfigParams.resetDate,
          },
        },
      };

      const paramsEmployeesAbsenceConfig = [];

      for (const [userId, config] of Object.entries(this.employeesAbsenceConfig)) {
        if (config.initializationCounter || !this.isBalanceActivated) {
          paramsEmployeesAbsenceConfig.push({
            userId,
            shopId: this.currentShop.id,
            initializationDate: this.shopAbsenceConfigParams.initializationDate,
            initializationCounter: 0,
            updates: config.initializationCounter ?
              computeHistoryRecoveryUpdate(
                new Date(this.shopAbsenceConfigParams.initializationDate),
                config.initializationCounter,
              ) : [],
            absenceType: 'RTT',
          });
        }
      }

      this.isLoadingForSubmit = true;

      if (this.isBalanceActivated && this.areUserBalancesEmptyOrNaN) {
        shopAbsenceConfigParams.shopAbsenceConfig.rtt_config.initialization_status =
          this.config.shop_absence_config.pending;
      } else {
        shopAbsenceConfigParams.shopAbsenceConfig.rtt_config.initialization_status =
          this.config.shop_absence_config.initialized;
      }

      Promise.all([
        this.createAbsenceConfig(shopAbsenceConfigParams),
        msHttpClient.post(`${svcEmployeesUrl}/v1/absence-configs/bulk_create`, paramsEmployeesAbsenceConfig),
      ])
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.rtt_modal.init_complete'),
            variant: 'success',
          });

          this.$refs.rttModal.hide();
          this.resetForm();
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.rtt_modal.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.isLoadingForSubmit = false;
        });
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    confirmCancel(event) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: this.handleConfirmModal,
      });
    },
    handleConfirmModal(event) {
      this.$refs.rttModal.hide();
      this.resetForm();
      this.$emit('cancel', event);
    },
    disabledDate(date) {
      return skDate(date).isBefore(skDate().startOf('year')) || skDate(date).isAfter(skDate().endOf('year'));
    },
  },
};
</script>

<style lang="scss" scoped>
.rtt-modal__init-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0;
  }

  &.rtt-modal__init-acquisition_period {
    padding-bottom: 8px;
  }

  &.paid-leaves-counter-modal__balance__header {
    padding-bottom: 0;
  }
}

.rtt-modal__init-label {
  padding-right: 8px;
  margin-bottom: 0;
}

.rtt-modal__init--align_center__flex {
  display: flex;
  align-items: center;
}

.rtt-modal__init--align_center__flex__justify_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.rtt-modal__init-acquisition_method-info {
  margin-left: 8px;
}

.rtt__start-date-datepicker {
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: 4px;
  opacity: .6;
  opacity: 1;
  background-color: $sk-grey-5;
  width: 292px;
}

.rtt-modal__init--bold {
  font-weight: $fw-semi-bold;
}

.rtt-modal__balance__card {
  margin: 16px 0;
}

.rtt-modal__init__medallion {
  margin-right: 13px;
}

.rtt-modal__init__user-row {
  justify-content: space-between;
  border-bottom: 1px $sk-grey-10 solid;
  padding: 8px 0;
}

.shop-settings__user-row__input {
  width: 132px;
}

.rtt__start-date-datepicker__label {
  padding-left: 10px;
}

.rtt-modal__init__user_fullname {
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style lang="scss">
// /!/ Careful : unscoped css to draw attention on year selection
// Years are very important in RTT Counter
// Because clients often get it wrong (especially at the end of year/beginning of new year)
// And it's not possible to change once initialized
// So we make it bolder to avoid user's errors
.rtt-modal__datepicker-popup {
  .mx-btn-text {
    color: black;
  }

  .mx-calendar-header-label {
    .mx-btn-text {
      font-size: $fs-text-l;
      font-weight: bold;
    }
  }
}
</style>
