<template>
  <div class="text-document-templates-empty">
    <NoVisibleTextDocumentTemplatesIcon />
    <p class="sk-subtitle--medium text-document-templates-empty__message">
      {{ $t('shop_settings.text_document_templates.no_visible_text_document_templates') }}
    </p>
    <p
      v-if="isHrisActive"
      class="text-document-templates_empty__add-button"
    >
      <SkOroraButton
        v-track="trackingOptions.submit"
        @click="handleNew"
      >
        {{ $t('shop_settings.text_document_templates.summary.add_button') }}
      </SkOroraButton>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import NoVisibleTextDocumentTemplatesIcon from './NoVisibleTextDocumentTemplatesIcon';

export default {
  name: 'NoVisibleTextDocumentTemplates',
  components: { NoVisibleTextDocumentTemplatesIcon },
  props: {
    trackingOptions: {
      type: Object,
      default: () => ({ submit: 'text_document_template_create' }),
    },
  },
  computed: {
    ...mapGetters('currentOrganisation', ['isHrisActive']),
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'text_document_templates_new' });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-document-templates-empty {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  p.text-document-templates_empty__message {
    margin: 30px 0 0 30px;
  }

  .text-document-templates_empty__add-button {
    margin-top: 29px;
  }
}
</style>
