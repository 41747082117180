<template>
  <div
    @mouseenter="setPublishTextOverflowing"
  >
    <SkPopover
      :disabled="!publishButtonTooltip"
      placement="bottom"
      as-tooltip
    >
      <template #anchor>
        <SkOroraButton
          :disabled="isPublishDisabled"
          :variant="publishButtonVariant"
          :icon="buttonIcon"
          :calculated-width="isProgressiveLoadingEnabled"
          :class="publishButtonClass"
          @click="publishPlanning"
        >
          <span ref="publishButtonText">{{ buttonText }}</span>
        </SkOroraButton>
      </template>
      <template #content>
        {{ publishButtonTooltip }}
      </template>
    </SkPopover>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';

export default {
  name: 'PublishPlanningAction',
  props: {
    isDataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPublishTextOverflowing: false,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsState', ['weeklyOptions', 'monthlyOptions']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek', 'monthlyShifts']),
    ...mapGetters('planningsState', ['isMonthlyView', 'isWeeklyView']),
    ...mapGetters('planningsUsers', ['totalNumberOfUsers']),

    buttonIcon() {
      if (this.isDataLoading) return null;

      return this.isPublished ? 'CheckMarkV2Icon' : null;
    },
    buttonText() {
      // publishButtonText and publishButtonSubtext relies on fetched data
      // do not access them while said data is loading and return default publish label
      if (!this.publishButtonSubtext) return this.publishButtonText;
      if (this.isProgressiveLoadingEnabled) return this.publishButtonText;

      return `${this.publishButtonText} ${this.publishButtonSubtext}`;
    },
    isPublished() {
      if (this.isLoadingInitialData) return false;
      if (this.isMonthlyView) {
        /*
          We don't check for archived users, since this prop is only available after fetching all batches
        */
        return this.monthlyOptions
          .every(({ employeesWithChange, publishedUserIds }) => (
            publishedUserIds.length === this.totalNumberOfUsers &&
              employeesWithChange.length === 0
          ));
      }

      return (
        this.publishedUsersLength !== 0 &&
        this.publishedUsersLength === this.totalNumberOfUsers &&
        !this.usersWithChangeLength
      );
    },
    isNotPublished() {
      if (this.isLoadingInitialData) return true;
      const publishedAndChanged = this.publishedUserIds
        .filter(user => this.usersWithChange.includes(parseInt(user.id, 10)));
      return publishedAndChanged.length === this.publishedUserIds.length;
    },
    visibleMonthShifts() {
      const weeks = this.monthlyOptions.map(({ date }) => skDate(date).isoWeek());
      return this.monthlyShifts.filter(shift => {
        const shiftWeek = skDate(shift.attributes.startsAt).isoWeek();
        return weeks.includes(shiftWeek);
      });
    },
    isPublishDisabled() {
      if (
        this.isProgressiveLoadingEnabled &&
        this.isWeeklyView &&
        this.isLoadingInitialData
      ) return true;
      if (this.isDataLoading || this.isLoadingInitialData) return true;

      if (this.isMonthlyView) {
        return this.visibleMonthShifts.length === 0 || this.isPublished;
      }
      return (
        // no shift
        this.shiftsForCurrentWeekCurrentShop.length === 0 ||
        // published
        this.isPublished
      );
    },
    isPublishDisplayed() {
      return this.currentLicense.attributes.canPublishPlanning;
    },
    shiftsForCurrentWeekCurrentShop() {
      return this.shiftsForCurrentWeek.filter(
        shift => Number(shift.attributes.shopId) === Number(this.currentShop.id),
      );
    },
    publishButtonVariant() {
      if (
        this.isDataLoading ||
        this.isPublishDisabled ||
        this.isPublished ||
        this.isNotPublished ||
        (this.isProgressiveLoadingEnabled && this.isLoadingInitialData)
      ) {
        return 'highlighted';
      }
      return 'secondary';
    },
    usersWithChange() {
      return this.weeklyOptions.attributes?.employeesWithChange || [];
    },
    usersWithChangeLength() {
      return this.usersWithChange.length;
    },
    usersPublishedWithoutChanges() {
      return this.publishedUserIds.filter(userId => !this.usersWithChange.includes(userId));
    },
    publishedUserIds() {
      if (this.isDataLoading) return [];
      // In monthly view there is no publishedUserIds.
      if (this.isMonthlyView) return [];
      return this.weeklyOptions.attributes?.publishedUserIds || [];
    },
    publishedUsersLength() {
      return this.publishedUserIds.length;
    },
    // return the length of employees on current planning context
    planningUsersLength() {
      if (!this.publishedUsersLength) return 0;

      const userIds = this.users.map(user => parseInt(user.id, 10));
      const validPublishedUserIds =
        this.publishedUserIds.filter(userId => userIds.includes(userId));
      return validPublishedUserIds.length;
    },
    publishButtonClass() {
      return {
        'publish-button-fade': this.isProgressiveLoadingEnabled && !this.isPublishDisabled,
      };
    },
    isPublishDisabledAndNotPublished() {
      return this.isPublishDisabled && !this.isPublished;
    },
    publishButtonText() {
      // Published to all employees
      if (this.isPublished) return this.$t('plannings.toolbar.actions_bar.publish_planning.state.is_published');

      // Not published
      if (this.isNotPublished || this.isPublishDisabled) return this.$t('plannings.toolbar.actions_bar.publish_planning.state.do_publish');

      // Partially published with no employees count
      if (this.isProgressiveLoadingEnabled) {
        return this.$t('plannings.toolbar.actions_bar.publish_planning.state.partially_published');
      }
      // Partially published with employees count
      return this.$t('plannings.toolbar.actions_bar.publish_planning.state.partially_published_for');
    },
    publishButtonTooltip() {
      return this.isPublishTextOverflowing ? this.publishButtonText : '';
    },
    publishButtonSubtext() {
      // publish subtext is only present if planning is partially published
      // and hasn't been modified since last publish
      // For isMonthlyView there is no Subtext
      if (
        this.isMonthlyView ||
        this.isPublished ||
        this.isNotPublished ||
        this.isPublishDisabled ||
        (this.teams.length === 0 && this.planningUsersLength === 0)
      ) {
        // don't display anything if not published, published to all or modified
        return '';
      }
      // count the number of teams the planning has been published to
      // for each team, we need to check if every members are present in published users
      if (this.teams.length > 0) {
        const publishedTeamsCount = this.teams.reduce((accPublishedTeams, currentTeam) => {
          if (currentTeam.relationships.users.length !== 0 &&
            currentTeam.relationships.users.every(user => (
              this.publishedUserIds.includes(parseInt(user.id, 10))
            ))) {
            return accPublishedTeams + 1;
          }
          return accPublishedTeams;
        }, 0);
        // display the number of teams if published to at least one team
        if (publishedTeamsCount !== 0) {
          return this.$tc('plannings.toolbar.actions_bar.publish_planning.subtext.teams', publishedTeamsCount);
        }
      }

      // display the number of employees on current planning context
      return this.$tc('plannings.toolbar.actions_bar.publish_planning.subtext.employees', this.usersPublishedWithoutChanges.length);
    },
  },
  methods: {
    setPublishTextOverflowing() {
      if (this.isDataLoading) return;
      this.isPublishTextOverflowing = this.$refs.publishButtonText &&
        this.$refs.publishButtonText.offsetWidth < this.$refs.publishButtonText.scrollWidth;
    },
    publishPlanning(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'publish-planning-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.publish-button-fade {
  animation: fadeIn .3s ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
