<template>
  <div>
    <Checklist
      v-if="checklistVisible"
      :collapsed="checklistCollapsed"
      :steps="checklistSteps"
      @step-clicked="handleStepClicked"
      @toggle-collapse="toggleChecklistCollapsed"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <DownloadTimeclockModal />
      <EmployeeExperienceVideoModal />
      <ManageBadges
        v-if="showManageBadges"
        id="manage_badges_step"
        @submit="onSubmit"
        @cancel="onCancel"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DownloadTimeclockModal from '@app-js/badgings/shared/components/TimeclockChecklist/DownloadTimeclockModal/index';
import ManageBadges from '@app-js/badgings/shared/components/TimeclockChecklist/ManageBadges';
import EmployeeExperienceVideoModal from '@app-js/badgings/shared/components/TimeclockChecklist/EmployeeExperienceVideoModal';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import Checklist from './Checklist';

export default {
  name: 'TimeclockChecklist',
  components: {
    Checklist,
    DownloadTimeclockModal,
    EmployeeExperienceVideoModal,
    ManageBadges,
  },
  data() {
    return {
      showManageBadges: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('timeclockOnboarding', ['checklistVisible', 'checklistCollapsed']),
    ...mapGetters('timeclockOnboarding', ['checklistSteps', 'allStepsCompleted']),
  },
  destroyed() {
    if (!this.allStepsCompleted) return;
    this.$skToast({
      message: this.$t('badgings.timeclock_demo.checklist.end_of_configuration_toast'),
      variant: 'success',
      containerId: 'badgings__container',
    });
  },
  methods: {
    ...mapMutations('timeclockOnboarding', ['setChecklistVisible', 'toggleChecklistCollapsed']),
    ...mapActions('timeclockOnboarding', ['updateStepCompletion']),
    handleStepClicked(step) {
      if (!step.enabled) return;

      const stepID = step.id;
      this.toggleChecklistCollapsed();

      const navigateAndShowModal = modalId => {
        const notInEmployeesTab = this.$router.currentRoute.name !== 'user_personal';

        if (notInEmployeesTab) {
          this.$router.replace({ name: 'user_personal', params: { shop_id: this.currentShop.id } });
        }
        setTimeout(() => {
          this.emitOnRoot(MODAL_SHOW_EVENT, null, modalId);
        }, notInEmployeesTab ? 1000 : 0);
      };

      switch (stepID) {
        case 'add_employees_step': {
          navigateAndShowModal('new-add-employee-modal');
          this.$skAnalytics.track('ss_checklist_modal_add_employees');
          break;
        }
        case 'manage_badges_step':
          this.showManageBadges = true;
          this.setChecklistVisible(false);
          this.$skAnalytics.track('ss_checklist_modal_control_and_validate');
          break;
        case 'download_timeclock_step':
          this.emitOnRoot(MODAL_SHOW_EVENT, null, `${stepID}_modal`);
          this.$skAnalytics.track('ss_checklist_modal_install');
          break;
        case 'explore_employee_experience_step':
          this.emitOnRoot(MODAL_SHOW_EVENT, null, `${stepID}_modal`);
          this.$skAnalytics.track('ss_checklist_modal_discover_employee_experience');
          break;
        case 'invite_employees_step':
          navigateAndShowModal('invite_employees_modal');
          this.$skAnalytics.track('ss_checklist_modal_invite_employees');
          break;
        default:
          break;
      }
    },
    onSubmit(stepID) {
      this.updateStepCompletion({ id: stepID, completed: true });
      switch (stepID) {
        case 'manage_badges_step':
          this.showManageBadges = false;
          this.setChecklistVisible(true);
          break;
        default:
          break;
      }
    },
    onCancel(stepID) {
      switch (stepID) {
        case 'manage_badges_step':
          this.showManageBadges = false;
          this.setChecklistVisible(true);
          break;
        default:
          break;
      }
    },
  },
};
</script>
