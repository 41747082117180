// Mostly inspired from https://github.com/kensnyder/quill-image-drop-module/blob/master/src/ImageDrop.js

import { Quill } from 'vue2-editor';

import { EVENT_DATA_TRANSFER_KEY } from './TemplateVariable';
import TemplateVariableBlot from './TemplateVariableBlot';

/**
 * Custom module for quilljs to allow user to drag TemplateVariable into the editor
 * @see https://quilljs.com/blog/building-a-custom-module/
 */
export default class TemplateVariableDrop {
  /**
   * Instantiate the module given a quill instance and any options
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    // save the quill reference
    this.quill = quill;
    // bind handlers to this instance
    this.handleDrop = this.handleDrop.bind(this);
    // listen for drop events
    this.quill.root.addEventListener('drop', this.handleDrop, false);

    // By default, data/elements cannot be dropped in other elements.
    // To allow a drop, we must prevent the default handling of the element
    this.quill.root.addEventListener(
      'dragover',
      event => {
        event.preventDefault();
      },
      false,
    );
  }

  /**
   * Handler for drop event to read dropped files from evt.dataTransfer
   * @param {Event} evt
   */
  handleDrop(evt) {
    evt.preventDefault();
    const templateVariable = evt.dataTransfer.getData(EVENT_DATA_TRANSFER_KEY);
    if (templateVariable) {
      const { label, field } = JSON.parse(templateVariable);
      // Insert the TemplateVariable into the document at the current cursor position
      const index = (this.quill.getSelection() || {}).index || this.quill.getLength();

      this.quill.removePlaceholder().insertEmbed(
        index,
        TemplateVariableBlot.blotName,
        { label, field },
        Quill.sources.API,
      );
      this.quill.insertText(index + 1, ' ', Quill.sources.API); // Add a space
      this.quill.setSelection(index + 2); // Set the cursor after
    }
  }
}
