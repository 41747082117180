<template>
  <SkModal
    id="blocking-alert-for-week-lock-modal"
    ref="blockingAlertForWeekLockModal"
    class="blocking_alert_modal__wrapper"
    :dismissable="false"
    :modal-title="$t('plannings.toolbar.modal.blocking_alert_for_week_lock_modal.title')"
    @show="handleShow"
  >
    <template #body>
      <SkModalSection border-bottom="none">
        <div class="blocking_alert_modal__subtitle">
          <CircledExclamationMarkIcon fill="#d03e50" />
          {{ $t('plannings.toolbar.modal.blocking_alert_modal.caution') }}
        </div>
        <div class="blocking_alert_modal__description--bold">
          {{ $t('plannings.toolbar.modal.blocking_alert_for_week_lock_modal.description_bold') }}
        </div>
        <div class="blocking_alert_modal__description">
          {{ $t('plannings.toolbar.modal.blocking_alert_for_week_lock_modal.description') }}
        </div>
        <div class="blocking_alert_modal__second_section-title">
          {{
            $t('plannings.toolbar.modal.blocking_alert_for_week_lock_modal.second_section.title')
          }}
        </div>
        <div
          v-for="user in matchedUsersWithWeeklyBlockingAlert"
          :key="user.id"
          class="blocking_alert_modal__second_section-users"
        >
          <span class="blocking_alert_modal__user">
            <SkMedallion
              :text="userInitials(user)"
              size="small"
              background-color="#2b66fe"
              color="white"
            />
            <span class="blocking_alert_modal__fullname">

              {{ fullName(user) }}
            </span>
          </span>
          <span class="blocking_alert_modal__weekly_user_diff">
            <CircledMinusSignThinIcon
              sign-fill="#2b66fe"
              background-fill="#d9e6ff"
              border-fill="none"
            />
            <span class="blocking_alert_modal__weekly_user_diff_number">
              {{ $t(
                'dates.hours_format',
                formatDurationToHoursAndMinutes(weeklyTotalDifferenceByUser[user.id])
              ) }}
            </span>
          </span>
        </div>
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton @click="handleConfirm">
        {{ $t('plannings.toolbar.modal.blocking_alert_modal.confirm') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import { totalDuration } from '@skelloapp/skello-planning-helper';
import { formatDurationToHoursAndMinutes } from '@app-js/plannings/shared/utils/planning_helpers';

export default {
  name: 'BlockingAlertForWeekLockModal',
  computed: {
    ...mapState('planningsUsers', ['users', 'weeklyTotalDifferenceByUser']),
    ...mapState('planningsShifts', ['usersWithWeeklyBlockingAlert']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapGetters('planningsState', ['isDailyView']),
    ...mapGetters('planningsShifts', ['shiftsForUser']),

    matchedUsersWithWeeklyBlockingAlert() {
      return this.users.filter(user => (
        this.usersWithWeeklyBlockingAlert.includes(parseInt(user.id, 10))
      ));
    },
  },
  methods: {
    formatDurationToHoursAndMinutes,
    ...mapMutations('planningsUsers', ['setWeeklyTotalDifferenceFor']),

    handleConfirm() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'blocking-alert-for-week-lock-modal');
    },
    handleShow() {
      // In order for weekly hours totals to be displayed on daily view
      // they need to be calculated here (they are already calculated in CountersCell for weekly view)
      if (!this.isDailyView) return;

      this.matchedUsersWithWeeklyBlockingAlert.forEach(user => {
        const shifts = this.shiftsForUser(user.id);
        const value = Math.round(totalDuration(shifts, this.currentShop.attributes.modulation)) -
          user.attributes.currentContractHours * 60;

        this.setWeeklyTotalDifferenceFor({ userId: user.id, diff: value });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blocking_alert_modal__subtitle {
  font-weight: 700;
  font-size: $fs-text-m;
  color: $sk-error;
}

.blocking_alert_modal__description--bold,
.blocking_alert_modal__second_section-title {
  font-weight: 700;
}

.blocking_alert_modal__description {
  padding-bottom: 34px;
}

.blocking_alert_modal__second_section-users {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
  border-bottom: 1px solid $sk-grey-10;
}

.blocking_alert_modal__weekly_user_diff_number {
  color: $sk-blue;
  padding-left: 5px;
}

.blocking_alert_modal__fullname {
  color: $sk-grey;
  padding-left: 12px;
}

.blocking_alert_modal__user,
.blocking_alert_modal__weekly_user_diff {
  display: flex;
  align-items: center;
}
</style>
