<template>
  <div class="days-locked-notification">
    <span class="days-locked-notification__text">
      {{ numberOfDays }} {{ $tc('reports.day_locked_notification.warning', numberOfDays) }}
    </span>
    <span
      class="days-locked-notification__button"
      @click.stop="showDetails"
    >
      {{ $t('reports.day_locked_notification.show') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'DaysLockedNotification',
  props: {
    numberOfDays: {
      type: Number,
      required: true,
    },
  },
  methods: {
    showDetails() {
      this.$emit('show-details');
    },
  },
};
</script>

<style lang="scss" scoped>
.days-locked-notification {
  display: flex;
  padding: 6px 12px;
  background-color: $sk-info-10;
  border-radius: 14px;
  height: 28px;
  align-items: center;
  gap: 12px;

  &__text {
    display: flex;
  }

  &__button {
    display: flex;
    cursor: pointer;
    font-weight: $fw-semi-bold;
  }
}
</style>
