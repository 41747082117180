const sanitize = str => (str ?? '')
  // Convert to lowercase
  .toLowerCase()
  // Replace accents
  .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  // Remove non-alphanumeric characters
  .replace(/[^a-z0-9]/g, '')
  // Remove spaces at the beginning and end
  .trim();

export const criteriaEnum = {
  DNI: 'dni',
  FIRSTNAME_AND_LASTNAME: 'firstname/lastname',
  CITY_AND_STREET: 'city_streetname/streetnumber',
};

export const matchEmployeesToPayslips = ({ employees, userToMatch }) => {
  let matchedUserIds = [];
  let workingEmployeesPool = [...employees]; // Clone the employees array
  let matchedUser = null;

  const matchByCriteria = (criteriaFn, criteria) => {
    if (matchedUser) {
      return;
    }

    matchedUserIds = workingEmployeesPool
      .reduce((acc, employee) => (
        criteriaFn(employee) ? [...acc, employee.id] : acc), [],
      );

    if (matchedUserIds.length === 1) {
      matchedUser = {
        ...userToMatch,
        userId: matchedUserIds[0],
        criteria,
      };
    }
    if (matchedUserIds.length > 1) {
      // If multiple matches, filter workingEmployeesPool to only matched users
      workingEmployeesPool = workingEmployeesPool
        .filter(({ id }) => matchedUserIds.includes(id));
    }
  };

  // First loop: Match by identification number (DNI)
  if (userToMatch.dni) {
    matchByCriteria(
      employee => employee.attributes.identityNumber === userToMatch.dni,
      criteriaEnum.DNI,
    );
  }

  // Second loop: Match by sanitized first name and last name
  matchByCriteria(employee => (
    employee.attributes.firstName &&
    employee.attributes.lastName &&
    sanitize(employee.attributes.firstName) === sanitize(userToMatch.firstName) &&
    sanitize(employee.attributes.lastName) === sanitize(userToMatch.lastName)
  ), criteriaEnum.FIRSTNAME_AND_LASTNAME);

  // Third loop: Match by sanitized city and sanitized street name/street number
  matchByCriteria(employee => (
    (employee.attributes.city &&
    sanitize(employee.attributes.city) === sanitize(userToMatch.city)) &&
    (employee.attributes.address &&
    sanitize(employee.attributes.address) ===
    sanitize(`${userToMatch.streetNumber}${userToMatch.streetName}`))
  ), criteriaEnum.CITY_AND_STREET);

  return matchedUser;
};

export const matchingAnalysis = ({ payslips, page }) => {
  if (Object.keys(page).length === 0) return [];

  const matchingPagetoPayslip = (pageToMatch, payslip) => {
    let allKeysNullOrUndefined = true;
    const allKeys = [
      'dni',
      'firstName',
      'lastName',
      'startDate', 'startPayrollPeriod',
      'endDate', 'endPayrollPeriod',
      'streetNumber',
      'streetName',
      'postalCode',
      'city',
    ];

    for (const key of allKeys) {
      let val1 = pageToMatch[key];
      let val2 = payslip[key];

      // The pageToMatch and the payslip don't have the same keys for payroll period
      switch (key) {
        case 'startPayrollPeriod':
          val2 = payslip.startDate;
          break;
        case 'endPayrollPeriod':
          val2 = payslip.endDate;
          break;
        case 'startDate':
          val1 = pageToMatch.startPayrollPeriod;
          break;
        case 'endDate':
          val1 = pageToMatch.endPayrollPeriod;
          break;
        default:
          break;
      }

      if (!(val1 === null || val1 === undefined || val2 === null || val2 === undefined)) {
        allKeysNullOrUndefined = false;

        if (key === 'firstName' || key === 'lastName') {
          const isSameFirstName = pageToMatch.firstName === payslip.firstName;
          const isSameLastName = pageToMatch.lastName === payslip.lastName;
          const isLastNameEqualsToFirstName = pageToMatch.lastName === payslip.firstName;
          const isFirstNameEqualsToLastName = pageToMatch.firstName === payslip.lastName;

          if (
            !(
              (isSameFirstName && isSameLastName) ||
              (isLastNameEqualsToFirstName && isFirstNameEqualsToLastName)
            )
          ) {
            return false;
          }
        }

        if (val1 !== val2 && key !== 'firstName' && key !== 'lastName') {
          return false;
        }
      }
    }

    if (allKeysNullOrUndefined) return false;

    return true;
  };

  const matchingPayslips = [];

  const sanitizedPage = Object.fromEntries(
    Object.entries(page).map(([key, value]) => {
      const sanitizedStringValue = typeof value === 'string' ? sanitize(value) : value;
      return [key, sanitizedStringValue];
    }),
  );

  payslips.forEach((payslip, index) => {
    const sanitizedPayslip = Object.fromEntries(
      Object.entries(payslip).map(([key, value]) => {
        const sanitizedStringValue = typeof value === 'string' ? sanitize(value) : value;
        return [key, sanitizedStringValue];
      }),
    );

    const isMatch = matchingPagetoPayslip(sanitizedPage, sanitizedPayslip);

    if (isMatch) {
      matchingPayslips.push(index);
    }
  });

  return matchingPayslips;
};
