<script>
import { mapGetters } from 'vuex';

import GenericToolbarAction from './GenericToolbarAction';
import WorkloadPlanDropdown from '../../WorkloadPlan/Dropdown';

export default {
  name: 'WorkloadPlanAction',
  components: {
    WorkloadPlanDropdown,
  },
  extends: GenericToolbarAction,
  computed: {
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentLicense', ['canEditManagementIndicators']),
    ...mapGetters('planningsState', ['isDailyView']),
    ...mapGetters('planningsPostes', ['hasDuplicatePostes']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.workload_plan.label');
    },
    icon() {
      return 'LineWithBarsIcon';
    },
    visibleIn() {
      return 'toolbar';
    },
    isDropdown() {
      return true;
    },
    popovComponent() {
      return 'WorkloadPlanDropdown';
    },
    isWorkloadPlanEnabled() {
      return this.checkPackOfferFlag('workload_plan_enabled') &&
        this.canEditManagementIndicators &&
        this.isDailyView;
    },
    isVisible() {
      return this.isWorkloadPlanEnabled;
    },
    disabled() {
      return this.hasDuplicatePostes;
    },
    dataTest() {
      return 'planning-toolbar__upload-workload-plan';
    },
  },
  methods: {},
};
</script>
