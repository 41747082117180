<template>
  <SkEventModal
    :flubber-color="$skColors.skInfo10"
    :header-icon="calendarV2Icon"
    :cancel-btn-label="$t('onboarding_modal.planning_import.skip_step')"
    cancel-button-data-test="skip_LLM"
    :on-cancel-click="handleSkipStep"
    header-icon-color="#8B45FF"
  >
    <template #content>
      <div class="planning-import-step__title">
        {{ $t('onboarding_modal.planning_import.title') }}
        <SkOroraTag
          class="planning-import-step__title-tag"
          size="large"
          color="skViolet"
          background-color="skInfo10"
        >
          {{ $t('onboarding_modal.planning_import.beta_tag') }}
        </SkOroraTag>
      </div>
      <div class="planning-import-step__text">
        {{ $t('onboarding_modal.planning_import.text') }}
      </div>
      <div class="planning-import-step__advice">
        <div class="planning-import-step__advice__details">
          <div class="planning-import-step__advice-left-column">
            <div class="planning-import-step__advice-with-icon">
              <CheckMarkV2Icon
                width="16"
                height="16"
                fill="#727272"
              />
              {{ $t('onboarding_modal.planning_import.advices.employees_names') }}
            </div>
            <div class="planning-import-step__advice-with-icon">
              <CheckMarkV2Icon
                width="16"
                height="16"
                fill="#727272"
              />
              {{ $t('onboarding_modal.planning_import.advices.shifts_hours') }}
            </div>
          </div>
          <div class="planning-import-step__advice-with-icon">
            <CheckMarkV2Icon
              width="16"
              height="16"
              fill="#727272"
            />
            {{ $t('onboarding_modal.planning_import.advices.positions') }}
          </div>
        </div>
      </div>
      <VueDropzone
        v-show="!uploadingDocument"
        id="planning-import-step"
        ref="planningImportStep"
        :include-styling="false"
        :options="dropzoneOptions"
        use-custom-slot
        @vdropzone-file-added="handleAddedFile"
      >
        <div class="planning-import-step__dropzone-content">
          <div class="planning-import-step__dropzone-label">
            <UploadFileV2Icon
              fill="#8b45ff"
              height="60"
              width="60"
            />
            <p
              class="planning-import-step__dropzone-text
              planning-import-step__dropzone-text--colored"
            >
              {{ $t('onboarding_modal.planning_import.import') }}
            </p>
            <p
              v-if="isDevFlagEnabled('FEATUREDEV_ONBOARDING_ENABLE_MULTI_PAGE')"
              class="planning-import-step__dropzone-text"
            >
              {{ $t('onboarding_modal.planning_import.accepted_format_multi_page') }}
            </p>
            <p
              v-else
              class="planning-import-step__dropzone-text"
            >
              {{ $t('onboarding_modal.planning_import.accepted_format_single_page') }}
            </p>
          </div>
        </div>
      </VueDropzone>
      <div
        v-if="!uploadingDocument"
        class="planning-import-step__disclaimer"
      >
        {{ $t('onboarding_modal.planning_import.disclaimer') }}
      </div>
      <div
        v-else
        class="planning-import-step__loader"
      >
        <SkLoader size="x-large" />
        <p class="planning-import-step__planning-analysis">
          {{ $t('onboarding_modal.planning_import.planning_analysis') }}
        </p>
      </div>
    </template>
  </SkEventModal>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { CalendarV2Icon } from '@skelloapp/skello-ui';
import {
  mapState,
  mapGetters,
} from 'vuex';
import {
  svcDocumentV2Client,
  svcIntelligenceClient,
} from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';

const MAX_DOCUMENT_SIZE = 50000000; // 5MB

export default {
  name: 'PlanningImportStep',
  components: {
    VueDropzone: vue2Dropzone,
  },
  data() {
    return {
      calendarV2Icon: CalendarV2Icon,
      uploadingDocument: false,
      acceptedMimeTypes: ['application/pdf'],
      dropzoneOptions: {
        autoProcessQueue: false,
        autoQueue: false,
        clickable: true,
        createImageThumbnails: false,
        headers: {
          'X-Request-With': 'XMLHttpRequest',
          Authorization: `Bearer ${authClient.authToken.token}`,
        },
        hiddenInputContainer: '#planning-import-step',
        params: {},
        timeout: null,
        url: '/fake',
      },
      maxPage: 1,
      fileNbPages: 0,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
  mounted() {
    this.listenOnRoot('critical-error-received', this.handleCriticalError);
  },
  methods: {
    async isValidFile(file) {
      if (!file || !file.type || !this.acceptedMimeTypes.includes(file.type)) {
        this.$skToast({
          message: this.$t('employees.tabs.documents.create_modal.errors.format'),
          variant: 'error',
        });
        this.$refs.planningImportStep.removeFile(file);
        return false;
      }

      if (file.size > MAX_DOCUMENT_SIZE) {
        this.$skToast({
          message: this.$t('employees.tabs.documents.create_modal.errors.size'),
          variant: 'error',
        });
        this.$refs.planningImportStep.removeFile(file);
        return false;
      }

      // check number of page
      if (file.type === 'application/pdf' && !this.isDevFlagEnabled('FEATUREDEV_ONBOARDING_ENABLE_MULTI_PAGE')) {
        const nbPages = await this.getNumberOfPages(file);
        this.fileNbPages = nbPages;

        if (nbPages > this.maxPage) {
          this.$skToast({
            message: this.$t('onboarding_modal.planning_import.max_page_error'),
            variant: 'error',
          });
          this.$refs.planningImportStep.removeFile(file);
          return false;
        }
      }

      return true;
    },
    async getNumberOfPages(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () => {
          const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
          resolve(count);
        };
      });
    },
    async handleAddedFile(file) {
      const isValid = await this.isValidFile(file);

      this.$skAnalytics.track('onboarding_import_schedule_file_step', {
        filename: file.name,
        filetype: file.type,
        nbPage: this.fileNbPages,
        status: isValid ? 'success' : 'error',
      });

      this.$refs.planningImportStep.processQueue(file);
      this.uploadingDocument = isValid;

      try {
        // TODO: use the createAndUpload method from the svcDocumentV2Client
        const response = await svcDocumentV2Client.document.create([
          {
            fileName: file.name,
            mimeType: file.type,
            shopId: this.currentShop.id,
          },
        ]);

        const { id: documentId, uploadUrl } = response[0];
        await svcDocumentV2Client.document.upload(uploadUrl, file);

        if (isValid) {
          await svcIntelligenceClient.requestAnalysisPlanning(documentId, this.currentShop.id, 'PLANNING');
          this.$emit('document-updated', documentId);
        }
      } catch (error) {
        this.onUploadDocumentFail(error);
        throw error;
      }
    },
    handleSkipStep() {
      this.$skAnalytics.track('onboarding_skip_schedule_import_step');
      this.$emit('next', { skip: true });
    },
    onUploadDocumentFail(error) {
      this.$refs.planningImportStep?.removeAllFiles();
      this.uploadingDocument = false;
      this.$skToast({
        message: this.$t('employees.tabs.documents.create_modal.errors.upload'),
        variant: 'error',
      });
    },
    handleCriticalError() {
      this.$refs.planningImportStep?.removeAllFiles();
      this.uploadingDocument = false;
      this.$skToast({
        message: this.$t('onboarding_modal.planning_import.llm_critical_error'),
        variant: 'error',
      });
    },
  },
};
</script>
<!-- unscoped style to override dropzone classes -->
<style lang="scss">
// using the dropzone id to not potentially affect other dropzones
#planning-import-step {
  height: calc(80% - 120px);
  min-height: 174px;
  .dz-preview,
  .dz-file-preview {
    display: none;
  }
  .dz-message {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>

.planning-import-step__title {
  display: flex;
  font-family: Gellix;
  font-size: $fs-heading-l;
  font-weight: $fw-semi-bold;
  height: 36px;
  line-height: 36px;
}

.planning-import-step__title-tag {
  margin: 10px 0 0 8px;
}

.planning-import-step__text {
  color: $sk-grey-50;
  font-family: Gellix;
  font-size: $fs-text-l;
  font-weight: $fw-regular;
  padding: 16px 0;
  line-height: normal;
}

.planning-import-step__advice {
  color: $sk-grey-50;
  font-family: Gellix;
  font-size: $fs-text-m;
  font-weight: $fw-regular;
  padding: 0 0 32px 0;
  line-height: normal;
}

.planning-import-step__advice__details {
  display: flex;
  flex-direction: row;
}

.planning-import-step__advice-with-icon {
  display: flex;
  gap: 4px;
}

.planning-import-step__advice-left-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 8px;
  min-width: 386px;
}

.planning-import-step__disclaimer {
  color: $sk-grey-50;
  font-family: Gellix;
  font-size: $fs-text-xxs;
  font-weight: $fw-regular;
  padding: 8px 0 0 0;
  line-height: 11.15px;
}

.planning-import-step__dropzone-content {
  cursor: pointer;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  // Handling the dashed border to be able to change the length of it
  background-position:  0 0, 0 0, 100% 0, 0 100%;
  background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
  background-repeat: no-repeat;
  background-image:
        repeating-linear-gradient(
          0deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
          ), // left
        repeating-linear-gradient(
          90deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
          ), // top
        repeating-linear-gradient(
          180deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
          ), // right
        repeating-linear-gradient(
          270deg, $sk-grey-10, $sk-grey-10 13px, transparent 13px, transparent 18px
          ) // bottom
    ;

  &:hover {
    background-color: #f9f5ff;
  }
}

.planning-import-step__dropzone-label {
  display: flex;
  width: 780px;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  margin: 32px 0;
}

.planning-import-step__dropzone-text {
  color: $sk-grey-50;
  font-family: Gellix;
  font-size: $fs-text-m;
  font-weight: $fw-regular;
  line-height: normal;
  margin-bottom: 0;

  &--colored {
    color: $sk-violet;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-l;
  }
}

.planning-import-step__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-weight: $fw-regular;
  font-size: $fs-text-l;
}

.planning-import-step__planning-analysis {
  padding-top: 8px;
  margin-bottom: 0;
}
</style>
