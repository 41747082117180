<template>
  <div class="task__container">
    <div class="task__checkbox__container">
      <SkCheckBox
        v-model="task.isChecked"
        :disabled="disabled"
        style="align-items: baseline; width: fit-content;"
      />
      <TasksTextarea
        v-model="task.label"
        :disabled="disabled"
        :text-decoration-line="task.isChecked ? 'line-through' : undefined"
      />
    </div>
    <SkCircleButton
      v-if="!disabled"
      icon="TrashCanV2Icon"
      icon-color="#727272"
      background-color="transparent"
      size="small"
      @click="handleDelete"
    />
  </div>
</template>

<script>
import TasksTextarea from '@app-js/plannings/shared/components/ShiftActivities/Tasks/TasksTextarea';

export default {
  name: 'Task',
  components: { TasksTextarea },
  props: {
    task: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.task__container {
  display: flex;
  flex: 1;
  padding: 4px 0;
}

.task__checkbox__container {
  display: flex;
  flex: 1;
  grid-gap: 8px;
  padding: 4px 0;
}

.task__checkbox {
  text-decoration-line: line-through;
}
</style>
