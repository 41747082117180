<template>
  <!-- the id is needed for payrollPreparationMixins/redirectToNextTabIfComplete -->
  <div
    id="employee-header"
    class="employee-header"
  >
    <div class="employee-header__profile">
      <div class="employee-header__medallion-wrapper">
        <AvatarMedallion
          :avatar-url="getAvatarUrlForUser(employee.id)"
          :medallion-text="initials"
          medallion-content-color="white"
          medallion-background="#2b66fe"
          size="xx-large"
        />
        <div
          v-if="canEditAvatar"
          v-modal.avatar-upload-modal
          class="employee-header__photo-btn"
        >
          <CameraIcon
            fill="#2b66fe"
            height="10"
            width="10"
          />
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <AvatarUploadModal
              v-model="avatar"
              :user-id="employee.id"
            />
          </MountingPortal>
        </div>
      </div>
      <div class="employee-header__profile__info">
        <div>
          <span
            v-if="isCurrentlyArchived"
            class="employee-header__archived-date"
          >
            {{ employeeArchivalDate }}
          </span>
          <h2 class="sk-header--2">
            {{ fullName }}
          </h2>
          <SkTag :variant="licenseTagVariant">
            {{ licenseName }}
          </SkTag>
        </div>
        <div
          v-if="isCurrentlyArchived"
          class="employee-header__archived-tag"
        >
          <SkTag variant="danger">
            {{ $t('employees.archived') }}
          </SkTag>
          <SkPopover
            :title="$t('employees.header.archival_note_header')"
            :prevent-overflow="false"
            placement="bottom-start"
          >
            <template #anchor>
              <span
                v-if="employee.attributes.archiveNote"
                class="archival-note-warning"
              >
                <span class="nipple" />
                {{ $t('employees.header.show_archival_note') }}
              </span>
            </template>
            <template #content>
              <div class="archival-note__wrapper">
                <p>{{ employee.attributes.archiveNote }}</p>
              </div>
            </template>
          </SkPopover>
        </div>
      </div>
    </div>
    <template v-if="canArchiveAndRestoreUsers">
      <div v-if="isCurrentlyArchived">
        <SkOroraButton
          ref="restore_employee_button"
          v-track="'restore_employee'"
          v-tooltip="$t('employees.header.restore_employee_tooltip')"
          :loading="unarchiveRequestPending"
          icon="CircleBackArrowV2Icon"
          variant="secondary"
          @click="unarchive"
        >
          {{ $t('employees.header.restore_employee') }}
        </SkOroraButton>
      </div>
    </template>
    <div
      v-if="!isCurrentlyArchived"
      class="employee-header__actions"
    >
      <div class="employee-header__action">
        <AccountCheckIcon
          v-if="hasCreatedAccount"
          v-tooltip="$t('employees.header.invite.disabled')"
          class="employee-header__action__created-account-icon"
          fill="#727272"
        />
        <SkCircleButton
          v-else
          v-tooltip="sendInvitationTooltip"
          v-track="'send_invitation'"
          icon="EnvelopeV2Icon"
          @click="sendInvite"
        />
      </div>
      <div v-if="canSeeAvailabilitiesButton || canArchiveEmployee">
        <div class="sk-divider--light" />
      </div>
      <div
        v-if="canSeeAvailabilitiesButton"
        class="employee-header__action"
        data-test="availability-button"
      >
        <router-link
          v-if="currentUser.id === employee.id"
          :to="availabilityRequestRoute"
        >
          <SkOroraButton
            icon="CheckedCalendarV2Icon"
            variant="secondary"
          >
            {{ $t('employees.header.availabilities') }}
          </SkOroraButton>
        </router-link>
        <SkOroraButton
          v-else
          v-modal.employee-availabilities-modal
          icon="CheckedCalendarV2Icon"
          variant="secondary"
        >
          {{ $t('employees.header.availabilities') }}
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <employee-availabilities-modal
              :title="$t('employees.employee_availabilities_modal.title')"
            />
          </MountingPortal>
        </SkOroraButton>
      </div>
      <div
        v-if="canArchiveEmployee"
        class="employee-header__action"
      >
        <SkOroraButton
          v-modal.archive-employee-modal
          icon="ArchiveBoxV2Icon"
          variant="secondary"
          :variant-color="$skColors.skError"
        >
          {{ $t('employees.header.archive_employee') }}
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <ArchiveEmployeeModal />
            <AvatarUploadModal
              v-model="avatar"
              :user-id="employee.id"
            />
          </MountingPortal>
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';
import { FEATURES } from '@app-js/shared/constants/features.js';
import EmployeeAvailabilitiesModal from '@app-js/requests/shared/components/EmployeeAvailabilitiesModal/EmployeeAvailabilitiesModal.vue';
import CameraIcon from './CameraIcon.vue';
import ArchiveEmployeeModal from './ArchiveEmployeeModal.vue';
import AvatarUploadModal from './AvatarUploadModal.vue';

export default {
  name: 'Header',
  components: {
    AvatarUploadModal,
    CameraIcon,
    ArchiveEmployeeModal,
    EmployeeAvailabilitiesModal,
    AvatarMedallion,
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentUser', ['visibleOrganisationLicenses']),
    ...mapState('selectedEmployee', ['employee', 'unarchiveRequestPending']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentLicense', [
      'canArchiveAndRestoreUsers',
      'canEditEmployeeInfo',
      'canManageEmployeeRequests',
      'isSystemAdmin',
    ]),
    ...mapGetters('selectedEmployee', [
      'fullName',
      'initials',
      'license',
      'stringifiedArchivedAt',
      'isCurrentlyArchived',
      'isCurrentUserOrSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
    ]),
    ...mapGetters('employees', [
      'getAvatarUrlForUser',
      'canAccessPayrollEmployee',
      'displayPayrollPreparation',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    avatar: {
      get() {
        return this.getAvatarUrlForUser(this.employee.id);
      },
      set(newValue) {
        this.setAvatarUrlForUser({
          user_id: this.employee.id,
          url: newValue,
        });
      },
    },
    canEditAvatar() {
      if (this.isCurrentUserOrSystemAdmin) {
        return true;
      }

      return this.canEditEmployeeInfo;
    },
    licenseName() {
      return this.$t(`employees.licenses.name.${this.employee.attributes.currentLicenseType}`);
    },
    availabilityRequestRoute() {
      return {
        name: 'availability_requests_own',
        params: { shop_id: this.shopId },
      };
    },
    shopId() {
      return this.navContext.shopId || this.currentUser.attributes.shopId;
    },
    hasCreatedAccount() {
      return this.employee.attributes.accountCreated;
    },
    hasInvitationBeenSent() {
      return !!this.employee.attributes.invitationSentAt;
    },
    canSeeAvailabilitiesButton() {
      return this.canManageEmployeeRequests && (
        (
          this.isCurrentUserOrSystemAdmin &&
          this.currentLicense.attributes.canCreateSelfAvailabilities
        ) || this.isStrictSubordinateOfCurrentUser
      ) && this.isFeatureEnabled(FEATURES.FEATURE_AVAILABILITIES, this.currentShop.id, () => true);
    },
    employeeArchivalDate() {
      const displayedMessage = `${this.$t('employees.header.archived_at')} ${this.stringifiedArchivedAt}`;

      if (this.employee.attributes.archivedBy) {
        return displayedMessage.concat(`, ${this.$t('employees.header.archived_by')}
          ${this.employee.attributes.archivedBy}`);
      }
      return displayedMessage;
    },
    canBeArchived() {
      return !this.employee.attributes.archivedAt;
    },
    canArchiveEmployee() {
      if (!this.canBeArchived) return false;

      if (this.isSystemAdmin) {
        return this.currentUser.id !== this.employee.id;
      }

      return this.canArchiveAndRestoreUsers && this.isStrictSubordinateOfCurrentUser;
    },
    licenseTagVariant() {
      if (!this.license) {
        return 'orange';
      }

      const employeeLicense = this.visibleOrganisationLicenses.find(license => (
        license.id === String(this.license.attributes.licenseId)
      ));

      switch (employeeLicense.attributes.originalType) {
        case 'system_admin':
        case 'advanced_master':
        case 'standard_master':
          return 'blue';
        case 'advanced_read_only':
        case 'standard_read_only':
        case 'advanced_planner':
        case 'standard_planner':
          return 'green';
        case 'advanced_employee':
        case 'standard_employee':
          return 'orange';
        default:
          return 'orange';
      }
    },
    sendInvitationTooltip() {
      if (this.hasInvitationBeenSent) {
        return this.$t('employees.header.invite.reinvite');
      }

      return this.$t('employees.header.invite.title');
    },
  },
  methods: {
    ...mapMutations('employees', ['setActiveTab', 'setAvatarUrlForUser']),
    ...mapActions('selectedEmployee', ['unarchiveEmployee', 'inviteEmployee']),
    ...mapActions('employees', ['reloadSidebar', 'fetchUserMissingAttributes']),
    unarchive() {
      this.unarchiveEmployee({ employee_id: this.employee.id })
        .then(() => {
          this.setActiveTab({ activeTab: 'active' });
          this.reloadSidebar({
            cluster_node_id: this.navContext.clusterNodeId,
            with_missing_attributes: this.displayPayrollPreparation,
          });
          if (this.canAccessPayrollEmployee) this.fetchUserMissingAttributes();
        });
    },
    sendInvite() {
      if (this.employee.attributes.email) {
        this.inviteEmployee({ employee_id: this.employee.id }).then(() => {
          this.$skToast({
            message: this.$t('employees.header.invite.success_message'),
            variant: 'success',
            containerId: 'employees__container',
          });
        });
      } else {
        this.$skToast({
          message: this.$t('employees.header.invite.error_message'),
          variant: 'error',
          containerId: 'employees__container',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-header__avatar {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.employee-header__medallion-wrapper {
  position: relative;
}

.employee-header__photo-btn {
  position: absolute;
  right: -5px;
  bottom: -5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: $sk-blue-5;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, .24);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.employee-header {
  padding: 35px 0 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.employee-header__actions {
    display: flex;
    align-items: center;

    div.employee-header__action {
      padding-left: 10px;
    }

    div.sk-divider--light {
      margin: 0 10px;
    }
  }

  .employee-header__action__created-account-icon {
    display: flex;
    margin-right: 10px;
  }

  div.employee-header__profile {
    display: flex;
    align-items: center;

    div.employee-header__profile__info {
      margin-left: 20px;
      display: flex;
      align-items: center;

      span.employee-header__archived-date {
        color: $sk-grey;
      }

      div.employee-header__archived-tag {
        display: flex;
        align-items: center;
        color: $sk-blue;
        padding-left: 25px;

        span.archival-note-warning {
          margin-left: 30px;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .archival-note__wrapper {
          width: 300px;
          padding-top: 8px;
        }
      }
    }
  }
}
</style>
