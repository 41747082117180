<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95163 2.74284L6.85638 3.8095L5.78969 1.90475L6.85638 0L4.95163 1.06666L3.04688 0L4.11354 1.90475L3.04688 3.8095L4.95163 2.74284Z"
      :fill="fill"
    />
    <path
      d="M14.0942 10.2092L12.1895 9.14258L13.2561 11.0473L12.1895 12.9521L14.0942 11.8854L15.999 12.9521L14.9323 11.0473L15.999 9.14258L14.0942 10.2092Z"
      :fill="fill"
    />
    <path
      d="M15.999 0L14.0942 1.06666L12.1895 0L13.2561 1.90475L12.1895 3.8095L14.0942 2.74284L15.999 3.8095L14.9323 1.90475L15.999 0Z"
      :fill="fill"
    />
    <path
      d="M10.2095 4.03912C9.90474 3.73436 9.4476 3.73436 9.14284 4.03912L0.22857 12.9534C-0.0761901 13.2581 -0.0761901 13.7153 0.22857 14.02L1.98094 15.7724C2.2857 16.0771 2.74284 16.0771 3.0476 15.7724L11.9619 6.85815C12.2666 6.55339 12.2666 6.09625 11.9619 5.79149L10.2095 4.03912ZM9.37141 8.22957L7.77142 6.62958L9.59998 4.80102L11.2 6.40101L9.37141 8.22957Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'MagicWandIcon',
  props: {
    width: {
      type: String,
      default: '15',
    },
    height: {
      type: String,
      default: '15',
    },
    fill: {
      type: String,
      default: '#727272',
    },
  },
};
</script>
