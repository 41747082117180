<template>
  <SkModalV2
    id="archive-employee-modal"
    :title="$t('employees.archive_employee_modal.archive_employee')"
    :subtitle="modalSubtitle"
    @show="handleShow"
    @hidden="resetData"
  >
    <template #body>
      <SkInnerBanner
        v-if="isInManyShopsPlanning"
        variant="danger"
      >
        {{ $t('employees.archive_employee_modal.employee_in_multiple_shops') }}
      </SkInnerBanner>
      <SkModalSectionV2
        border-bottom="none"
        :padding-bottom="false"
      >
        <SkInfoCard>
          <div>
            <!-- eslint-disable-next-line max-len -->
            {{ $t('employees.archive_employee_modal.no_longer_in_planning', { organisation_name: currentOrganisation.attributes.name }) }}
            {{ $t('employees.archive_employee_modal.in_hr_section_for_one_month') }}
            {{ $t('employees.archive_employee_modal.archive_contract_warning') }}

            <span
              v-if="isInManyShopsPlanning"
              class="archive-employee-modal__info"
            >
              {{ $t('employees.archive_employee_modal.prefer_show_in_planning') }}
            </span>
          </div>
        </SkInfoCard>
        <div class="archive-employee-modal__info-callout">
          <IconInfo class="archive-employee-modal__info-callout__icon" />
          <span>
            {{ $t('employees.archive_employee_modal.archive_contract_delete_email_warning') }}
          </span>
        </div>
        <div class="archive-employee-modal__inputs">
          <div class="archive-employee-modal__inputs__row">
            <p>
              {{ $t('employees.archive_employee_modal.archive_date') }}
            </p>
            <div class="archive-employee-modal__input">
              <SkDatePicker
                v-model="archiveDate"
                :errored="hasArchiveDateError"
                :error-message="$t('errors.missing_value')"
                :lang="$i18n.locale"
                input-moment-format="dddd DD MMM YYYY"
              />
            </div>
          </div>
          <div class="archive-employee-modal__inputs__row">
            <p>
              {{ $t('employees.archive_employee_modal.add_note') }}
            </p>
            <div class="archive-employee-modal__input">
              <SkTextarea
                v-model="archiveNote"
                :label="$t('employees.archive_employee_modal.add_note_placeholder')"
                :max-height="99"
                :min-height="54"
                auto-grow
              />
            </div>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #footer>
      <SkOroraButton
        v-track="'cancel_archive_employee'"
        variant="secondary"
        :variant-color="$skColors.skError"
        @click="handleCancel"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        v-track="'validate_archive_employee'"
        :loading="archiveRequestPending"
        :disabled="hasArchiveDateError"
        icon="ArchiveBoxV2Icon"
        :variant-color="$skColors.skError"
        @click="handleSubmit"
      >
        {{ $t('employees.archive_employee_modal.archive') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';
import { getErrorMessage } from '@skello-utils/errors';
import { capitalize } from '@skello-utils/formatting/strings';
import { getContractTypeTranslationsObject } from '@app-js/users/shared/utils/contract_types_helper';

export default {
  name: 'ArchiveEmployeeModal',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      archiveDate: skDate().format('YYYY-MM-DD'),
      archiveNote: null,
    };
  },
  computed: {
    ...mapGetters('selectedEmployee', ['isInManyShopsPlanning']),
    ...mapGetters('contracts', ['isActive', 'contractType']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', {
      selectedEmployee: state => state.employee,
      archiveRequestPending: state => state.archiveRequestPending,
    }),
    ...mapState('contracts', ['contract', 'sort']),
    ...mapState('config', ['config']),
    ...mapState('employees', ['displayPayrollPreparation']),
    hasArchiveDateError() {
      return !this.archiveDate;
    },
    contractValueLabel() {
      if (!this.contract.attributes) return '';

      if (this.contract.attributes.counterType === 'hourly_rate') {
        return this.$t(
          'employees.add_employee_modal.subtitle.per_hour_unit',
          { value: this.contract.attributes.contractHours },
        );
      }

      return this.$t(
        'employees.add_employee_modal.subtitle.per_year_unit',
        { value: this.contract.attributes.workDays },
      );
    },
    modalSubtitle() {
      const { firstName, lastName } = this.selectedEmployee.attributes;

      if (!this.contract.attributes) return `${capitalize(firstName)} ${capitalize(lastName)}`;

      const contractType = this.contractType(this.contract.attributes.contractTypeId);
      const contractTypeTranslations = getContractTypeTranslationsObject(this.$t('contract_types.keys'));
      const contractTypeName = contractTypeTranslations[contractType.key];
      const contractValueLabel = contractType.category === 'interim' ? '' : this.contractValueLabel;

      return (
        `${capitalize(firstName)} ${capitalize(lastName)} \u2022 ${contractTypeName} ${contractValueLabel}`
      );
    },
  },
  methods: {
    ...mapMutations('employees', ['setActiveTab', 'setShowIncompleteEmployees']),
    ...mapMutations('contracts', ['setContractAttributes']),
    ...mapActions('selectedEmployee', ['archiveEmployee']),
    ...mapActions('employees', ['reloadSidebar', 'fetchUserMissingAttributes']),
    ...mapActions('contracts', ['fetchCurrentContract', 'updateContract', 'fetchContracts']),

    handleShow() {
      this.fetchCurrentContract({ employeeId: this.selectedEmployee.id });
    },
    handleSubmit(event) {
      const message = this.$t('employees.archive_employee_modal.success');
      this
        .archiveEmployee({
          employee_id: this.selectedEmployee.id,
          archive_note: this.archiveNote,
          archived_at: this.archiveDate,
        })
        .then(() => {
          this.$skToast({
            message,
            variant: 'success',
            containerId: 'employees__container',
          });

          const isEmployeeArchiveDateInPast =
            skDate(this.selectedEmployee.attributes.archivedAt).endOf('day') < skDate().endOf('day');

          if (this.isActive) {
            // trialEnd must not be after endDate, thus there are cases where we update it as well
            if (this.contract.attributes.trialEnd &&
              skDate(this.contract.attributes.trialEnd) > skDate(this.archiveDate)) {
              this.setContractAttributes({ trialEnd: this.archiveDate });
            }
            this.setContractAttributes({ endDate: this.archiveDate });
            this.updateContract({
              employeeId: this.selectedEmployee.id,
              contractId: this.contract.id,
              clusterNodeId: this.navContext.clusterNodeId,
            }).then(() => {
              // we wanna fetch contracts when contract is archived before today only on contract page
              // with either user_contracts_history or user_contracts_future
              if (isEmployeeArchiveDateInPast &&
                this.$router.currentRoute.name.startsWith('user_contracts_')) {
                this.fetchContracts({
                  employeeId: this.selectedEmployee.id,
                  filter: this.$router.currentRoute.name,
                  sortField: this.sort.sortField,
                  direction: this.sort.direction,
                });
              }
            });
          }

          // Switch to archived tab if employee is moved there
          if (isEmployeeArchiveDateInPast || this.displayPayrollPreparation) {
            this.setShowIncompleteEmployees(false);
            this.setActiveTab({ activeTab: 'archived' });
            this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });
            this.fetchUserMissingAttributes();
          }
          this.closeModal(event);
        })
        .catch(error => {
          this.$skToast({
            message: getErrorMessage(error),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    handleCancel(event) {
      this.closeModal(event);
    },
    closeModal(event) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'archive-employee-modal');
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
.archive-employee-modal__inputs {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__row {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
      padding-top: 12px;
      font-weight: $fw-semi-bold;
    }

    &:first-child {
      padding-bottom: 10px;
    }
  }
}

.archive-employee-modal__info {
  font-weight: $fw-semi-bold;
}

.archive-employee-modal__input {
  width: 340px;
}

.archive-employee-modal__info-callout {
  background-color: $sk-blue-5;
  border-radius: 4px;
  margin-top: 10px;
  padding: 16px 16px 16px 20px;
  display: flex;
  align-items: flex-start;

  &__icon {
    margin-right: 16px;
  }
}
</style>
