<template>
  <div class="sk-chart__legend">
    <div
      v-for="(serie, index) in series"
      :key="index"
      v-tooltip.bottom="tooltipText(serie)"
      :class="chartLegendItemClasses(serie)"
    >
      <div
        v-if="serie.type !== 'line' || !legendPositionIsRight"
        :class="chartLegendItemIconClass"
        :style="iconStyle(index)"
      />
      <div
        v-else
        class="sk-chart__legend-small-item__container"
      >
        <div
          class="sk-chart__legend-item__icon-small"
          :style="iconStyle(index)"
        />
        <div
          class="sk-chart__legend-item__icon-small"
          :style="iconStyle(index)"
        />
      </div>
      <span class="sk-chart__legend-item__label">
        {{ serie.name }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ChartLegend',
  props: {
    seriesColors: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    position: {
      type: String,
      required: true,
      default: 'bottom',
    },
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),

    isPackOfferPremium() {
      return this.currentOrganisation.attributes.packOffer.name === 'premium';
    },
    chartLegendItemIconClass() {
      return {
        'sk-chart__legend-item__icon': true,
        'sk-chart__legend-item__icon-size': this.legendPositionIsRight,
      };
    },
    legendPositionIsRight() {
      return this.position === 'right';
    },
    packOfferName() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
  },
  methods: {
    iconStyle(index) {
      return {
        backgroundColor: this.seriesColors[index],
      };
    },
    chartLegendItemClasses(serie) {
      return {
        'sk-chart__legend-item__disabled': serie.premiumMetric && !this.isPackOfferPremium,
        'sk-chart__legend-item': true,
        'sk-chart__legend-item__right': this.legendPositionIsRight,
      };
    },
    tooltipText(serie) {
      if (!serie.premiumMetric || this.isPackOfferPremium) return null;

      return this.$t('analytics_dashboard.legend.tooltip', {
        pack_name_translated: this.packOfferName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-chart__legend {
  display: flex;
  flex-direction: row;
  background-color: $sk-grey-5;
  border-radius: 6px;
  min-width: 125px;
  width: fit-content;
  height: 30px;
  padding: 9px 12px;
  margin-top: 3px;

  &-item {
    display: flex;
    align-items: center;

    &__disabled {
      opacity: .4;
    }

    &__right {
      margin-bottom: 2px;
      margin-left: 4px;
    }

    &__icon {
      width: 12px;
      height: 12px;
      border-radius: 2px;
      margin-right: 7px;

      &-size {
        height: 2px;
        width: 15px;
        border-radius: 0;
      }

      &-small {
        height: 2px;
        width: 5.5px;
      }
    }

    &__label {
      color: $sk-grey;
      font-size: $fs-text-s;
      margin-right: 8px;
    }
  }
}

.sk-chart__legend-small-item__container {
  width: 15px;
  display: flex;
  justify-content: space-between;
  margin-right: 7px;
}
</style>
