<template>
  <div class="shop-settings__employee-row">
    <SkAvatar
      class="shop-settings__employee-row__medallion"
      size="x-large"
      :background-color="$skColors.skPrimaryBlue10"
      :color="$skColors.skPrimaryBlue50"
      :text="userInitials(employee)"
    />
    <div class="sk-text-large-regular">
      {{ fullName(employee) }}
    </div>
    <div class="shop-settings__employee-row__modifiers">
      <SkCircleButton
        v-track="trackingOptions.minus"
        icon="MinusSignIcon"
        size="small"
        :active="employee.balanceModifier === '-'"
        :bordered="!employee.balanceModifier || employee.balanceModifier === '+'"
        @click="updateEmployee('balanceModifier', '-')"
      />
      <SkCircleButton
        v-track="trackingOptions.plus"
        icon="PlusSignV2Icon"
        size="small"
        :active="employee.balanceModifier === '+'"
        :bordered="!employee.balanceModifier || employee.balanceModifier === '-'"
        @click="updateEmployee('balanceModifier', '+')"
      />
    </div>
    <SkInputGroup
      :errored="employee.balanceError"
      class="shop-settings__employee-row__input"
    >
      <SkInput
        v-model="employee.balance"
        :placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.placeholder')"
        type="number"
        step="1"
        min="0"
        @keyup="$emit('keyup', $event, value)"
      />
      <template #append>
        {{ unit }}
      </template>
    </SkInputGroup>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'EmployeeRow',
  props: {
    value: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    trackingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('employees', ['fullName', 'userInitials']),
    employee: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    updateEmployee(key, value) {
      this.employee = { ...this.employee, [key]: value };
      this.$emit('keyup', this.employee);
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings__employee-row {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .shop-settings__employee-row__medallion {
    margin-right: 14px;
  }

  .shop-settings__employee-row__modifiers {
    display: flex;
    gap: 4px;
    margin-inline: auto 8px;
  }

  .shop-settings__employee-row__modifier {
    margin-left: 4px;
  }

  .shop-settings__employee-row__input {
    margin-right: 0;
    width: 200px;

    ::v-deep .sk-input-group__error-label {
      margin: 0;
    }
  }
}
</style>
