<template>
  <SkModalV2
    id="download-workload-plan-modal"
    ref="downloadWorkloadPlanModal"
    :icon-options="{
      color: '#000000',
      backgroundColor: 'white',
      name: 'DownloadIcon',
    }"
    :title="$t('workload_plan.modals.download_model.title')"
    @show="handleShow"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="download-workload-plan-modal__content">
          <div class="download-workload-plan-modal__info">
            <div class="download-workload-plan-modal__info--icon">
              <CircledIIcon
                fill="#2b66fe"
                height="24"
                width="24"
              />
            </div>
            <div class="download-workload-plan-modal__info--container">
              <span>
                {{ $t('workload_plan.modals.download_model.infos.title') }}
              </span>
              <span class="download-workload-plan-modal__info--text">
                {{ $t('workload_plan.modals.download_model.infos.text') }}
              </span>
            </div>
          </div>
          <div class="download-workload-plan-modal__date-picker">
            <span class="download-workload-plan-modal__date-picker--label">
              {{ $t('workload_plan.modals.download_model.date_picker.label') }}
            </span>
            <SkDatePicker
              v-model="selectedDates"
              range
              class="download-workload-plan-modal__date-picker--picker"
              :clearable="false"
              input-moment-format="YYYY-MM-DD"
              :lang="$i18n.locale"
            >
              <template #input>
                <div :class="downloadWorkloadPlanModalInputClasses">
                  {{ displayedDates }}
                </div>
              </template>
            </SkDatePicker>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="!hasSelectedDate"
        @click="handleSubmit"
      >
        {{ $t('workload_plan.modals.download_model.action.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { generateWorkloadPlanFile }
  from '@skello-utils/workload_plan/workload_plan_excel_file_writer';

export default {
  name: 'DownloadWorkloadPlanModal',
  data() {
    return {
      selectedDates: [null, null],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['postes']),
    ...mapGetters('planningsState', ['visibleDaysConfig']),
    ...mapGetters('planningsWorkloadPlans', ['maximumWeeksRange', 'openingHourQuarters']),
    routeQueryParams() {
      return { ...this.$route.query };
    },
    hasSelectedDate() {
      return !!this.selectedDates[0] && !!this.selectedDates[1];
    },
    displayedDates() {
      if (!this.hasSelectedDate) {
        return this.$i18n.t('workload_plan.modals.download_model.date_picker.placeholder');
      }

      return `${skDate(this.selectedDates[0]).format('DD/MM/YYYY')} - ${skDate(this.selectedDates[1]).format('DD/MM/YYYY')}`;
    },
    downloadWorkloadPlanModalInputClasses() {
      return {
        'download-workload-plan-modal__date-picker--input': true,
        'download-workload-plan-modal__date-picker--input--active': this.hasSelectedDate,
      };
    },
  },
  watch: {
    selectedDates(newValue, oldValue) {
      if (newValue[0] === oldValue[0] && newValue[1] === oldValue[1]) return;

      this.handleMaxRange(newValue);
    },
  },
  methods: {
    handleSubmit() {
      generateWorkloadPlanFile({
        period: this.selectedDates,
        shopName: this.currentShop.attributes.name,
        sheetName: this.$t('workload_plan.modals.download_model.file_name.needs'),
        postes: this.postes,
        visibleDays: this.visibleDaysConfig,
        hourQuarters: this.openingHourQuarters,
      }).then(() => {
        this.successToaster();
        this.$skAnalytics.track('dowloaded_template_workload_plan');
        this.$refs.downloadWorkloadPlanModal.hide();
      }).catch(this.errorToaster);
    },
    successToaster() {
      this.$skToast({
        message: this.$t('workload_plan.modals.download_model.success'),
        variant: 'success',
      });
    },
    errorToaster() {
      this.$skToast({
        message: this.$t('workload_plan.modals.errors.generic'),
        variant: 'error',
      });
    },
    handleShow() {
      this.selectedDates = [null, null];
    },
    handleMaxRange(date) {
      const startDate = skDate(date[0]);
      let endDate = skDate(date[1]);

      if (endDate.clone().subtract(this.maximumWeeksRange, 'weeks').isSameOrAfter(startDate, 'day')) {
        endDate = startDate.clone().add(this.maximumWeeksRange * 7 - 1, 'days');
        this.selectedDates = [startDate, endDate];

        this.$skToast({
          message: this.$t('dates.notice_max_range_weeks', { max: this.maximumWeeksRange }),
          variant: 'notice',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-workload-plan-modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    &--label {
      font-size: $fs-text-m;
      font-weight: $fw-semi-bold;
      line-height: 18px;
    }

    &--picker {
      margin-left: 16px;
      width: 100%;
    }

    &--input {
      min-width: 300px;
      height: 45px;
      background-color: $sk-grey-5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px;
      padding: 0 16px;
      color: $sk-grey;

      &--active {
        color: $sk-black;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: $fs-text-m;
    padding: 20px;
    border-radius: 4px;
    border: solid 1px $sk-grey-10;

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 16px;
    }

    &--icon {
      display: flex;
      align-self: flex-start;
    }

    &--text {
      color: $sk-grey;
    }
  }
}
</style>
