<template>
  <div>
    <div
      :class="rootClass"
      @click="selectFolder('')"
    >
      <CaretIcon
        v-if="showFolders"
        :fill="caretFill"
        class="folders-select__caret"
      />
      <HouseIcon
        :fill="houseFill"
        class="folders-select__house"
      />
      <div class="folders-select__folder-details">
        {{ $t('employees.tabs.documents.title') }}
        <p class="folders-select__document-count">
          {{ folderCount }} {{ $t('employees.tabs.documents.hris_folders.title') }}
          • {{ legibleDocumentsCount('') }}
        </p>
      </div>
      <div class="folders-select__right-section">
        <p v-if="localValue">
          {{ selectLabel }}
        </p>
        <CheckMarkIcon
          v-else
          fill="white"
          class="folders-select__checkmark"
        />
      </div>
    </div>
    <div
      v-if="showFolders && !limitedDocumentTemplates"
      class="folders-select__folders"
    >
      <div class="folders-select__divider" />
      <div class="folders-select__rows">
        <div
          v-for="folder in foldersList"
          :key="folder"
          :class="rowClass(folder)"
          @click="selectFolder(folder)"
        >
          <component
            :is="folderState(folder)"
            size="small"
            style="margin-right: 20px"
          />
          <div class="folders-select__folder-details">
            {{ folderName(folder) }}
            <p class="folders-select__document-count">
              {{ legibleDocumentsCount(folder) }}
            </p>
          </div>
          <div class="folders-select__right-section">
            <p v-if="localValue !== folder">
              {{ selectLabel }}
            </p>
            <CheckMarkIcon
              v-else
              fill="white"
              class="folders-select__checkmark"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { FOLDER_STATES } from '@app-js/shared/constants/hris';

import FullFolder from '../assets/FullFolder';
import EmptyFolder from '../assets/EmptyFolder';

export default {
  name: 'FoldersSelect',
  components: { FullFolder, EmptyFolder },
  props: {
    value: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      required: true,
    },
    showFolders: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    ...mapState('employeeDocuments', ['documents']),
    ...mapGetters('employeeDocuments', ['documentsByFolder']),
    ...mapGetters('currentOrganisation', ['limitedDocumentTemplates']),
    ...mapGetters('foldersManagement', ['folderName', 'foldersList']),
    folderCount() {
      return this.foldersList.length;
    },
    rootClass() {
      return {
        'folders-select__root': true,
        'folders-select__root--selected': !this.localValue,
      };
    },
    houseFill() {
      return this.localValue ? '#2b66fe' : 'white';
    },
    caretFill() {
      return this.localValue ? 'black' : 'white';
    },
  },
  watch: {
    localValue(folder) {
      this.$emit('input', folder);
    },
  },
  methods: {
    folderState(folder) {
      return this.folderContents(folder) ? FOLDER_STATES.full : FOLDER_STATES.empty;
    },
    rowClass(folder) {
      return {
        'folders-select__row': true,
        'folders-select__row--selected': this.localValue === folder,
      };
    },
    selectFolder(folder) {
      this.localValue = folder;
    },
    folderContents(folder) {
      return this.documentsByFolder[folder];
    },
    legibleDocumentsCount(folder) {
      const documents = this.folderContents(folder);
      const localeKey = folder === '' ? 'unfiled_documents' : 'documents';
      return `${documents ? documents.length : 0} ${this.$t(`employees.tabs.documents.move_modal.${localeKey}`)}`;
    },
  },
};
</script>

<style lang="scss">
.folders-select__root {
  .folders-select__caret {
    margin-right: 20px;
  }

  .folders-select__house {
    margin-right: 15px;
  }
}

.folders-select__folders {
  display: flex;
}

.folders-select__rows {
  width: 100%;
  padding-right: 5px;
}

.folders-select__divider {
  border: 1.5px solid $sk-blue;
  margin: 30px 38px;
  border-radius: 2px;
}

.folders-select__root,
.folders-select__row {
  padding: 0 15px;
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $sk-grey-10;
  cursor: pointer;

  &:hover {
    background: $sk-grey-5;

    .folders-select__right-section {
      opacity: 1;
      margin-right: 20px;
      margin-left: auto;

      p {
        font-size: $fs-text-s;
        color: $sk-grey-50;
        margin: 0;
      }
    }
  }

  .folders-select__folder-details {
    display: flex;
    flex-direction: column;
  }

  .folders-select__document-count {
    color: $sk-grey-50;
    font-size: $fs-text-s;
    margin: 0;
  }

  .folders-select__right-section {
    opacity: 0;
  }

  &--selected {
    background: $sk-blue;
    color: white;
    border-radius: 4px;
    border: none;

    .folders-select__document-count {
      color: $sk-grey-10;
    }

    .folders-select__right-section {
      opacity: 1;
      margin-right: 20px;
      margin-left: auto;
    }

    &:hover {
      background: $sk-blue;
    }
  }
}
</style>
