<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 350 227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M256 95C256 119.576 246.148 141.855 230.179 158.096C213.85 174.703 191.127 185 166 185C140.87 185 118.146 174.7 101.817 158.092C85.8487 141.851 76 119.576 76 95C76 70.4244 85.8487 48.1487 101.817 31.9082C109.517 24.0765 118.638 17.6478 128.762 13.0413C140.11 7.87648 152.717 5 166 5C215.706 5 256 45.2943 256 95Z"
      fill="#FFF2D9"
    />
    <rect
      :fill="fill"
      x="269.363"
      y="13"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
    />
    <rect
      :fill="fill"
      x="279.15"
      y="20.2344"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 279.15 20.2344)"
    />
    <rect
      :fill="fill"
      x="7.23438"
      y="93"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
    />
    <rect
      :fill="fill"
      x="17.0215"
      y="100.234"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 17.0215 100.234)"
    />
    <rect
      :fill="fill"
      x="82.9785"
      y="175.553"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
    />
    <rect
      :fill="fill"
      x="92.7656"
      y="182.787"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 92.7656 182.787)"
    />
    <circle
      cx="66.2128"
      cy="10.2128"
      r="8.71277"
      stroke="#D2D7DC"
      stroke-width="3"
    />
    <circle
      cx="296.17"
      cy="126.191"
      r="8.71277"
      stroke="#D2D7DC"
      stroke-width="3"
    />
    <path
      d="M137.893 131.345V50.6552C137.893 48.0844 139.977 46 142.548 46H187.548L203.065 61.5172V131.345C203.065 133.916 200.981 136 198.41 136H142.548C139.977 136 137.893 133.916 137.893 131.345Z"
      fill="#FDBF3F"
    />
    <path
      d="M192.202 64.6207H203.064V61.5172L187.547 46V59.9655C187.547 62.5363 189.631 64.6207 192.202 64.6207Z"
      fill="#FD882F"
    />
    <path
      d="M192.202 61.5172H203.064L187.547 46V56.8621C187.547 59.4329 189.631 61.5172 192.202 61.5172Z"
      fill="#FFDB8F"
    />
    <path
      d="M144.102 50.6543H147.205V53.7577H144.102V50.6543Z"
      fill="#FD882F"
    />
    <path
      d="M150.309 50.6543H153.412V53.7577H150.309V50.6543Z"
      fill="#FD882F"
    />
    <path
      d="M156.516 50.6543H159.619V53.7577H156.516V50.6543Z"
      fill="#FD882F"
    />
    <path
      d="M128 80.1387H191.039V110.397H128V80.1387Z"
      fill="#FDB62F"
    />
    <path
      d="M135.564 87.7012H140.608V92.7443H135.564V87.7012Z"
      fill="#FD882F"
    />
    <path
      d="M145.65 87.7012H183.474V92.7443H145.65V87.7012Z"
      fill="#FD882F"
    />
    <path
      d="M135.564 97.7871H140.608V102.83H135.564V97.7871Z"
      fill="#FD882F"
    />
    <path
      d="M145.65 97.7871H183.474V102.83H145.65V97.7871Z"
      fill="#FD882F"
    />
    <path
      d="M138.086 110.396H191.039V115.44H138.086V110.396Z"
      fill="#FD882F"
    />
  </svg>
</template>

<script>
export default {
  name: 'NoVisibleTextDocumentTemplatesIcon',
  props: {
    width: {
      type: String,
      default: '267',
    },
    height: {
      type: String,
      default: '197',
    },
    fill: {
      type: String,
      default: '#D2D7DC',
    },
  },
};
</script>
