<template>
  <div class="workload-plan-bottom-panel__hour-quarters__container">
    <div
      v-for="(quarter, index) in hourQuarters"
      :key="index"
      class="workload-plan-bottom-panel__hour-quarters__cell"
      :style="{width: `${dayViewPlanningSizeVariables.pixelPerQuarterHours}px`}"
    >
      <span
        v-if="isNotZero(quarter)"
        class="workload-plan-bottom-panel__hour-quarters__cell--value"
      >
        {{ quarter }}
      </span>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'Quarters',
  computed: {
    ...mapGetters('planningsWorkloadPlans', ['arrayOfOpeningQuarters']),
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),

    hourQuarters() {
      return this.arrayOfOpeningQuarters.map(quarters => skDate.utc(quarters).format('mm'));
    },
  },
  methods: {
    isNotZero(quarter) {
      return quarter !== '00';
    },
  },
};
</script>

<style scoped lang="scss">
.workload-plan-bottom-panel__hour-quarters {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -5px;
    width: fit-content;
  }

  &__cell {
    &--value {
      font-size: 11px;
      color: $sk-grey;
    }
  }
}
</style>
