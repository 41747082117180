import Plannings from './Plannings';
import Days from './pages/Days';
import Months from './pages/Months';
import Weeks from './pages/Weeks';
import Employees from './pages/Weeks/Employees';
import Postes from './pages/Weeks/Postes';
import store from '../shared/store';

const planningsRoutes = [
  {
    path: '/v3/shops/:shop_id/plannings',
    component: Plannings,
    name: 'plannings',
    redirect: to => {
      if (localStorage.favoritePlanningView === 'days') {
        return { name: 'plannings_days' };
      }

      if (localStorage.favoritePlanningView === 'months') {
        return { name: 'plannings_months' };
      }

      return { name: 'plannings_weeks_employees' };
    },
    children: [
      {
        path: 'weeks',
        name: 'plannings_weeks',
        redirect: { name: 'plannings_weeks_employees' },
        component: Weeks,
        children: [
          {
            path: 'employees',
            name: 'plannings_weeks_employees',
            component: Employees,
          },
          {
            path: 'postes',
            name: 'plannings_weeks_postes',
            component: Postes,
          },
        ],
      },
      {
        path: 'days',
        name: 'plannings_days',
        component: Days,
      },
      {
        path: 'months',
        name: 'plannings_months',
        component: Months,
      },
    ],
  },
];

export default planningsRoutes;
