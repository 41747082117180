<template>
  <SkSelectV2
    :value="selectedUsers"
    v-bind="selectorOptions"
    data-test="publish-planning-modal__select-employees"
    multi
    append-to-body
    no-confirm
    @input="$emit('change', $event)"
  >
    <template #group-count="{ count }">
      {{ $tc('plannings.toolbar.modal.shared.select.employee_count', count) }}
    </template>

    <template #selected-option="{ value }">
      {{ $tc('plannings.toolbar.modal.shared.select.employee_count', value.length) }}
    </template>
  </SkSelectV2>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

export default {
  name: 'UsersSelector',
  model: {
    prop: 'selectedUsers',
    event: 'change',
  },
  props: {
    selectedUsers: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters('employees', ['fullName']),
    ...mapState('planningsState', ['weeklyOptions']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('shopTeams', ['teams']),

    employees() {
      return this.users.map(user => ({
        id: user.id,
        text: this.fullName(user),
        teamIds: user.relationships.teams.data.map(team => team.id),
        with_changes: [
          this.weeklyOptions.attributes.employeesWithChange.includes(parseInt(user.id, 10)),
        ],
      }));
    },

    groups() {
      const groups = [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        {
          id: 'withChanges',
          text: this.$t('plannings.toolbar.modal.publish_planning.select.with_change'),
          matchKey: 'with_changes',
          matchVal: true,
        },
        { id: 'separator', separator: true, matchKey: null },
      ];

      if (this.teams.length !== 0) {
        groups.push({ id: 'teams', text: this.$t('plannings.toolbar.modal.shared.select.teams'), matchKey: null });

        groups.push(...this.teams.map(({ id, attributes: { name } }) => ({
          id,
          text: name,
          matchKey: 'teamIds',
          matchVal: id,
        })));

        groups.push({ id: 'employee', text: this.$t('navbar.employees'), matchKey: null });
      }

      return groups;
    },

    selectorOptions() {
      return {
        options: this.employees,
        groupOptions: this.groups,
        label: this.$t('plannings.toolbar.modal.shared.select.employees'),
        searchPlaceholder: this.$t('actions.search'),
        noResultsLabel: this.$t('plannings.toolbar.modal.shared.select.no_search_results'),
        width: '320px',
      };
    },
  },
};
</script>
