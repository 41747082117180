<template>
  <div
    class="loader-container"
    :class="{ 'side-panel': isSidePanel }"
  >
    <SkLoader :size="size" />
  </div>
</template>

<script>
export default {
  name: 'LoaderContainer',
  props: {
    size: {
      type: String,
      default: 'large',
    },
    isSidePanel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 8px 24px 8px 24px;

  &.side-panel {
    min-height: calc(100vh - 220px);
  }
}
</style>
