class GenericFilterHandler {
  constructor(setFilters, storageFilterKeys, localStorageKey) {
    // mutation from PlanningsState store
    this.setFilters = setFilters;
    this.storageFilterKeys = storageFilterKeys;
    this.localStorageKey = localStorageKey;
  }

  /**
   * Apply filters :
   * 1. Override store and local storage data with URL filters if set
   * 2. Use local storage data to override store and url filters (reset or update)
   * 3. return route query with filters
   * This ensures that url filters are the main source of truth to determine the active filters.
   * While allowing for filters to be persisted through local storage if url filters are missing
   * @param {*} to -> current page
   * @param {*} from -> previous page (null on component mounted flow)
   * @returns route query with filters
   */
  applyFilters(to) {
    const urlFilters = this.#getUrlFilters(to);
    if (!this.#areFiltersEmpty(urlFilters)) {
      this.#setLocalStorageFilters(urlFilters, to);
    }

    const localStorageFilters = this.#getFormattedLocalStorageFilters();
    if (!this.#areFiltersEmpty(localStorageFilters) > 0 &&
        to.params.shop_id !== localStorageFilters.shopId) {
      return this.#resetFilters(to);
    }

    this.#setStoreFilters(localStorageFilters);
    return this.#addFiltersToRouteQuery(localStorageFilters, to);
  }

  updateFilters(filters, router) {
    this.#setStoreFilters(filters);
    this.#setLocalStorageFilters(filters, router.currentRoute);
    const query = this.#addFiltersToRouteQuery(filters, router.currentRoute);
    this.#updateRoute(query, router);
  }

  #getUrlFilters(route) {
    const urlFilters = {};
    for (const filter of this.storageFilterKeys) {
      const param = route.query[filter];
      urlFilters[filter] = this.#paramAsArray(param);
    }
    return this.#cleanFilters(urlFilters);
  }

  #getLocalStorageFilters() {
    const filtersStorageData = localStorage.getItem(this.localStorageKey);
    if (!filtersStorageData) return {};

    return JSON.parse(filtersStorageData);
  }

  #getFormattedLocalStorageFilters() {
    const filters = this.#getLocalStorageFilters();
    return this.#cleanFilters(filters);
  }

  #setStoreFilters(filters) {
    delete filters.shopId;
    this.setFilters(filters);
  }

  #setLocalStorageFilters(filters, route) {
    const newFilters = this.#cleanFilters(filters);
    newFilters.shopId = route.params.shop_id;

    localStorage.setItem(this.localStorageKey, JSON.stringify(newFilters));
  }

  #addFiltersToRouteQuery(filters, route) {
    const queryFilters = this.#cleanFilters(filters);
    const query = {
      ...route.query,
      ...queryFilters,
    };

    return query;
  }

  #updateRoute(query, router) {
    router.replace({
      name: router.currentRoute.name,
      query,
    });
  }

  #resetFilters(route) {
    localStorage.removeItem(this.localStorageKey);
    this.#setStoreFilters({});
    return this.#addFiltersToRouteQuery({}, route);
  }

  #cleanFilters(filters) {
    if (!filters) return {};

    const newFilters = { ...filters };
    Object.keys(newFilters)
      .filter(key => this.storageFilterKeys.includes(key))
      .forEach(key => {
        if (this.#isFilterEmpty(filters[key])) {
          delete newFilters[key];
        }
      });

    return newFilters;
  }

  #paramAsArray(param) {
    if (param === undefined) {
      return undefined;
    }
    if (typeof param === 'string') {
      return [param];
    }
    return [...param];
  }

  #isSwitchingShop(to, from) {
    if (!from) return false;

    return to.params.shop_id !== from.params.shop_id;
  }

  #areFiltersEmpty(filters) {
    if (!filters) return true;

    return Object.keys(filters)
      .filter(key => this.storageFilterKeys.includes(key))
      .every(key => this.#isFilterEmpty(filters[key]));
  }

  #isFilterEmpty(filter) {
    return !filter || filter.length === 0;
  }
}

export default GenericFilterHandler;
