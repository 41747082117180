<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8339 5.72334L13.1106 0H0.4375V23.711H18.8339V5.72334Z"
      fill="#B1CEFE"
    />
    <path
      d="M4.526 9.40356H14.7462C14.9719 9.40356 15.1551 9.22082 15.1551 8.99475C15.1551 8.76868 14.9719 8.58594 14.7462 8.58594H4.526C4.30033 8.58594 4.11719 8.76868 4.11719 8.99475C4.11719 9.22082 4.30033 9.40356 4.526 9.40356Z"
      fill="#2b66fe"
    />
    <path
      d="M4.526 6.13207H8.6141C8.83976 6.13207 9.0229 5.94934 9.0229 5.72326C9.0229 5.49719 8.83976 5.31445 8.6141 5.31445H4.526C4.30033 5.31445 4.11719 5.49719 4.11719 5.72326C4.11719 5.94934 4.30033 6.13207 4.526 6.13207Z"
      fill="#2b66fe"
    />
    <path
      d="M14.7462 11.8555H4.526C4.30033 11.8555 4.11719 12.0382 4.11719 12.2643C4.11719 12.4903 4.30033 12.6731 4.526 12.6731H14.7462C14.9719 12.6731 15.1551 12.4903 15.1551 12.2643C15.1551 12.0382 14.9719 11.8555 14.7462 11.8555Z"
      fill="#2b66fe"
    />
    <path
      d="M14.7462 15.127H4.526C4.30033 15.127 4.11719 15.3097 4.11719 15.5358C4.11719 15.7618 4.30033 15.9446 4.526 15.9446H14.7462C14.9719 15.9446 15.1551 15.7618 15.1551 15.5358C15.1551 15.3097 14.9719 15.127 14.7462 15.127Z"
      fill="#2b66fe"
    />
    <path
      d="M14.7462 18.3965H4.526C4.30033 18.3965 4.11719 18.5792 4.11719 18.8053C4.11719 19.0314 4.30033 19.2141 4.526 19.2141H14.7462C14.9719 19.2141 15.1551 19.0314 15.1551 18.8053C15.1551 18.5792 14.9719 18.3965 14.7462 18.3965Z"
      fill="#2b66fe"
    />
    <path
      d="M13.1094 0V5.72334H18.8327L13.1094 0Z"
      fill="#2b66fe"
    />
    <path
      d="M14.7984 20.1211L14.7955 20.1244L13.9824 23.1042L15.8822 21.2048L14.7984 20.1211Z"
      fill="#EDDCC7"
    />
    <path
      d="M22.6666 14.4173L22.1568 13.9075C21.84 13.5907 21.3261 13.5907 21.0093 13.9075L19.5449 15.3715L20.6287 16.4552L22.6666 14.4173Z"
      fill="#A8BACA"
    />
    <path
      d="M20.6306 16.4568L19.5469 15.373L14.7992 20.1207L15.8829 21.2044L20.6306 16.4568Z"
      fill="#A8BACA"
    />
    <path
      d="M17.1075 22.4353L17.1107 22.432L15.8818 21.2031L13.9821 23.1025L13.9277 23.3024L17.1075 22.4353Z"
      fill="#D6C4B1"
    />
    <path
      d="M21.8593 17.6848L23.3237 16.2204C23.6405 15.9036 23.6405 15.3897 23.3237 15.0729L22.6688 14.418L20.6309 16.4559L21.8593 17.6848Z"
      fill="#727272"
    />
    <path
      d="M15.8825 21.2048L17.1113 22.4336L21.859 17.6859L20.6302 16.4571L15.8825 21.2048Z"
      fill="#727272"
    />
    <path
      d="M13.6394 23.9993C13.5347 23.9993 13.4301 23.9592 13.3504 23.8795C13.1905 23.7196 13.1905 23.4613 13.3504 23.3014L14.2526 22.3992C14.4124 22.2393 14.6708 22.2393 14.8306 22.3992C14.9905 22.559 14.9905 22.8174 14.8306 22.9772L13.9284 23.8795C13.8487 23.9592 13.744 23.9993 13.6394 23.9993Z"
      fill="#000000"
    />
  </svg>
</template>

<script>
export default {
  name: 'AmendmentIcon',
  props: {
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
  },
};
</script>
