<template>
  <header class="requests-header">
    <div class="requests-header__text">
      <h1 class="sk-heading-medium-semibold">
        {{ title }}
      </h1>
      <p class="sk-text-large-regular">
        {{ subtitle }}
      </p>
    </div>
    <div class="requests-header__cta">
      <slot name="actions" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'RequestsPageHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.requests-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
}

.requests-header__text {
  display: flex;
  flex-direction: column;

  h1 {
    line-height: 24px;
  }

  .sk-text-large-regular {
    color: $sk-grey-50;
  }
}

.requests-header__cta {
  display: flex;
  gap: 8px;
}
</style>
