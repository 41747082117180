<template>
  <div class="personal_information">
    <h1 class="personal_information__title">
      {{ $t('profile.personal_information.title') }}
    </h1>
    <div class="personal_information__medallion-wrapper">
      <AvatarMedallion
        id="personal_information__sk-medallion-container"
        :avatar-url="avatarUrl"
        :medallion-text="initials"
        medallion-content-color="#2B66FE"
        medallion-background="#D9E6FF"
        size="xx-large"
      />
      <div
        v-modal.avatar-upload-modal
        class="personal_information__medallion-btn"
      >
        <SkCircleButton
          icon="PencilV2Icon"
          icon-color="#000000"
          background-color="#FFFFFF"
          bordered
          size="small"
        />
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <AvatarUploadModal
            v-model="avatarUrl"
            :user-id="currentUser.id"
          />
        </MountingPortal>
      </div>
    </div>
    <form
      class="personal_information__form"
      @submit.prevent
    >
      <SkInput
        v-model="params.firstName"
        class="personal_information__input"
        :label="$t('profile.personal_information.first_name')"
        data-test="personal_information__first_name"
      />
      <SkInput
        v-model="params.lastName"
        class="personal_information__input"
        :label="$t('profile.personal_information.last_name')"
        data-test="personal_information__last_name"
      />
      <SkInput
        v-if="isBirthNameActiveAttribute"
        v-model="params.birthName"
        class="personal_information__input"
        :label="$t('profile.personal_information.birth_name.label')"
        data-test="personal_information__birth_name"
      />
      <span class="personal_information__input">
        <SkSelectV2
          v-model="params.lang"
          :label="$t('profile.personal_information.language')"
          :options="languageSelectOptions"
          data-test="personal_information__language"
        />
      </span>
      <SkPhoneInput
        ref="phoneInput"
        v-model="phone"
        class="personal_information__input"
        :error-message="$t('employees.attributes.phone_number.error_message')"
        :label="$t('profile.personal_information.phone')"
        :default-country="defaultPhoneCountry"
        data-test="personal_information__phone"
        @input="checkPhoneErrored"
      />
      <SkInput
        v-model="params.email"
        class="personal_information__input"
        :label="$t('profile.personal_information.email')"
        type="email"
        data-test="personal_information__email"
      />
      {{
      /* NOTE: we do not allow for password updates here though it can be present
      ** in the data sent. The password is not displayed in the form unless the email is
      ** changed and is then used to check for the ability to update email.
      ** That's an old behavior that we kept for compatibility reasons.
      */
      }}
      <SkInput
        v-if="hasEmailChanged"
        v-model="password"
        :errored="isPasswordEmpty"
        class="personal_information__input"
        :label="$t('profile.personal_information.password')"
        type="password"
        data-test="personal_information__password"
      />
    </form>
    <SkCheckBox
      v-model="isBirthNameActiveAttribute"
      class="personal_information__checkbox"
      data-test="personal_information__birth_name_checkbox"
      :label="$t('profile.personal_information.birth_name.checkbox')"
    />
    <StickyBar
      container-scroll-id="profile__content"
      :visible="hasFormChanged"
      :disabled="disabled"
      :submit-spinner="isProcessingUpdate"
      :submit-button-label="$t('employees.sticky_bar.submit')"
      data-test="personal_information__submit"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  isEqual,
  snakeCase,
} from 'lodash';
import { languageToCountryCode } from '@skello-utils/country-keys.js';

import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';
import AvatarUploadModal from '@app-js/users/shared/components/AvatarUploadModal.vue';
import {
  SkInput,
  SkPhoneInput,
  SkSelectV2,
  SkCircleButton,
} from '@skelloapp/skello-ui';
import StickyBar from '@app-js/shared/components/Stickybar';
import { loadLanguageAsync } from '@plugins/i18n';

export default {
  name: 'Information',
  components: {
    AvatarMedallion,
    AvatarUploadModal,
    SkCircleButton,
    SkInput,
    SkPhoneInput,
    SkSelectV2,
    StickyBar,
  },
  data() {
    return {
      defaultPhoneCountry: null,
      isProcessingUpdate: false,
      initialIsBirthNameActive: false,
      isBirthNameActive: false,
      isPhoneErrored: false,
      params: undefined,
      initialParams: undefined,
      password: undefined,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentUser', ['initials']),

    hasFormChanged() {
      return !this.isPhoneErrored && (
        this.hasBirthNameChanged || !isEqual(this.paramsToCheck, this.initialParams)
      );
    },
    paramsToCheck() {
      // we only omit the birthName if the text input is empty
      if (this.params.birthName === '') {
        return {
          ...this.params,
          birthName: this.initialParams.birthName,
        };
      }

      return this.params;
    },
    isBirthNameActiveAttribute: {
      get() {
        return this.isBirthNameActive;
      },
      set(value) {
        if (!value) {
          this.params.birthName = null;
        } else {
          this.params.birthName = this.initialParams.birthName;
        }
        this.isBirthNameActive = value;
      },
    },
    phone: {
      get() {
        return this.params.phone;
      },
      set(value) {
        this.params.phone = value.replaceAll(' ', '');
      },
    },
    disabled() {
      return this.hasEmailChanged && this.isPasswordEmpty;
    },
    isPasswordEmpty() {
      return !this.password;
    },
    languageSelectOptions() {
      return this.config.langs.map(
        lang => (
          {
            id: lang,
            text: this.$t(`profile.personal_information.lang.${lang}`),
          }
        ),
      );
    },
    avatarUrl: {
      get() {
        return this.currentUser.avatarUrl;
      },
      set(newValue) {
        this.setAvatarUrl({ url: newValue });
      },
    },
    hasEmailChanged() {
      return this.params.email !== this.initialParams.email;
    },
    hasBirthNameChanged() {
      return this.params.birthName !== this.initialParams.birthName && this.params.birthName !== '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUserProfile']),
    ...mapMutations('currentUser', ['setAvatarUrl']),

    init() {
      this.params = this.setParams();
      this.initialParams = this.setParams();
      this.defaultPhoneCountry = languageToCountryCode(this.currentUser.attributes.lang);
      if (this.currentUser.attributes.birthName !== null) {
        this.isBirthNameActive = true;
      }
      this.initialIsBirthNameActive = this.isBirthNameActive;
    },
    setParams() {
      return {
        firstName: this.currentUser.attributes.firstName,
        lastName: this.currentUser.attributes.lastName,
        birthName: this.currentUser.attributes.birthName,
        email: this.currentUser.attributes.email,
        phone: this.currentUser.attributes.phone,
        lang: this.currentUser.attributes.lang,
      };
    },
    handleSubmit() {
      this.isProcessingUpdate = true;
      if (this.isBirthNameActive === false || this.params.birthName === '') {
        this.params.birthName = null;
      }
      this.updateCurrentUserProfile({
        user: Object.keys(this.params).reduce(
          (acc, key) => ({ [snakeCase(key)]: this.params[key], ...acc }),
          { password: this.password },
        ),
      })
        .then(() => {
          loadLanguageAsync(this.params.lang);

          this.init();

          this.$skToast({
            message: this.$t('profile.personal_information.update.success'),
            variant: 'success',
          });
        })
        .catch(({ response }) => {
          const errorMessage = response.data.message?.alert ?? this.$t('errors.standard_message');

          this.$skToast({
            message: errorMessage,
            variant: 'error',
          });
        })
        .finally(() => {
          this.isProcessingUpdate = false;
          this.password = undefined;
        });
    },
    checkPhoneErrored() {
      this.isPhoneErrored = Boolean(this.$refs?.phoneInput?.inputErrored);
    },
  },
};
</script>

<style lang="scss" >
  // FIXME: Using an id to increase the selector specificity is kind of hacky
  // but as the specified design size does not match any of the available sizes in
  // the AvatarMedallion component and as its styles are scoped it leaves solely a few options.
  #personal_information__sk-medallion-container {
    & > .sk-medallion {
      height: 88px;
      width: 88px;
      line-height: 35px;

      & > span {
        font-size: 32px;
      }

      // FIXME: Here we have to use !important because the styles are set inline
      // in the child component
      & > .sk-medallion__image {
        height: 88px !important;
        width: 88px !important;
      }
    }

  }
</style>

<style lang="scss" scoped>
  .personal_information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__medallion-wrapper {
      height: 88px;
      width: 88px;
      margin-bottom: 32px;
      position: relative;
    }

    &__medallion-btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 35px;
      margin-bottom: 32px;
    }

    &__form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      width: 100%;
    }

    &__input {
      width: calc(50% - 12px);

      ::v-deep .sk-phone-input__error-wrapper {
        position: absolute;
      }
    }

    &__checkbox {
      margin-top: 24px;
    }

    .sk-sticky-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
</style>
