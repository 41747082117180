<template>
  <table class="sk-table">
    <tbody>
      <tr>
        <td />
        <td
          v-for="i in 7"
          :key="i"
        >
          <SkDivCanvas
            :width="60"
            :height="16"
            animated
          />
        </td>
      </tr>
      <tr>
        <td>
          <SkDivCanvas
            :width="120"
            :height="16"
            animated
          />
        </td>
        <td
          v-for="i in 7"
          :key="i"
        >
          <SkDivCanvas
            :width="60"
            :height="16"
            animated
          />
        </td>
      </tr>
      <tr>
        <td>
          <SkDivCanvas
            :width="120"
            :height="16"
            animated
          />
        </td>
        <td
          v-for="i in 7"
          :key="i"
        >
          <SkDivCanvas
            :width="60"
            :height="16"
            animated
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'HolidaysCounterTableCanvas',
};
</script>

<style lang="scss" scoped>
.sk-table {
  background: $sk-grey-5;
  border-radius: 6px;

  tr {
    height: 60px;

    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }

  td:first-of-type {
    padding-left: 17px;
  }
}
</style>
