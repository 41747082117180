<template>
  <div class="wrapper">
    <div
      ref="simpleBar"
      class="scroll-bar__wrapper"
    >
      <div class="total-employees__workforce">
        <div class="workforce__wrapper">
          <!--
            In this component we display only empty div, the objective here,
            is to have the same width as the PlanningTables component,
            to be able to play with the overflow, and display an horizontal scroll bar
            at the bottom of the screen.
            So we display as much empty div as arrayOfOpeningQuarters.
          -->
          <div
            v-for="(workforce, hourQuarterIndex) in arrayOfOpeningQuarters"
            :key="`workforce-quarter-${hourQuarterIndex}`"
            class="workforce__quarter"
            :style="{width: `${dayViewPlanningSizeVariables.pixelPerQuarterHours}px`}"
          />
        </div>
      </div>

      <HorizontalScrollBar
        :visible-width="visibleWidth"
        template="daily"
        :are-counters-displayed="!isWorkloadPlanDisplayed"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {
  mapGetters,
  mapState,
} from 'vuex';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import HorizontalScrollBar from '@app-js/shared/components/HorizontalScrollBar';

export default {
  name: 'WorkForceScrollBar',
  components: {
    HorizontalScrollBar,
  },
  props: {
    isWorkloadPlanDisplayed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      scrollElement: null,
      visibleWidth: 0,
      debouncedUpdateVisibleWidth: () => {},
    };
  },
  computed: {
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsWorkloadPlans', ['arrayOfOpeningQuarters']),
  },
  watch: {
    $route() {
      this.scrollElement.scrollLeft = 0;
    },
  },
  mounted() {
    this.listenOnRoot('day-view-scroll', this.handleScroll);

    const simpleBar = new SimpleBar(this.$refs.simpleBar);
    this.scrollElement = simpleBar.getScrollElement();
    this.scrollElement.addEventListener('scroll', this.handleEmitScroll);

    this.listenOnRoot('planning-horizontal-scroll', this.programmaticScroll);

    this.debouncedUpdateVisibleWidth = debounce(this.updateVisibleWidth, 100);
    window.addEventListener('resize', this.debouncedUpdateVisibleWidth);
    this.scrollElement.addEventListener('scroll', this.debouncedUpdateVisibleWidth);

    this.updateVisibleWidth();
  },
  beforeDestroy() {
    this.scrollElement.removeEventListener('scroll', this.handleEmitScroll);

    window.removeEventListener('resize', this.debouncedUpdateVisibleWidth);
    this.scrollElement.removeEventListener('scroll', this.debouncedUpdateVisibleWidth);
  },
  methods: {
    handleScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.scrollElement === event.srcElement) return;
      this.scrollElement.scrollLeft = event.target.scrollLeft;
      this.emitOnRoot('hide-shift-menu');
    },
    handleEmitScroll(event) {
      this.emitOnRoot('day-view-scroll', event);
    },
    programmaticScroll(scrollOffset) {
      const { scrollWidth, clientWidth } = this.scrollElement;
      this.scrollElement.scrollLeft = scrollOffset * (scrollWidth - clientWidth);
    },
    updateVisibleWidth() {
      const { clientWidth, scrollWidth, scrollLeft } = this.scrollElement;
      this.visibleWidth = clientWidth / scrollWidth;
      const hiddenWidth = scrollWidth - clientWidth;
      this.emitOnRoot('planning-horizontal-scroll', hiddenWidth === 0 ? 0 : scrollLeft / hiddenWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-bar__wrapper {
  width: 100%;
  height: 100%;
}

.wrapper {
  height: fit-content;
  position: absolute;
  bottom: 0;
  left: 161px;
  // The width is 100% minus the sidebars of the planning.
  width: calc(100% - 307px);
  background-color: transparent;
}

.workforce__wrapper {
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  background-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.workforce__quarter {
  display: inline-block;
  height: 100%;
  text-align: center;
  font-size: 9px;
  position: relative;
}

::v-deep {
  // Hide SimpleBar's horizontal scrollbar
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
     visibility: hidden;
  }
}
</style>
