/**
 * Returns a list of dates in which the shop is open in a given period
 * @param skDate startPeriod
 * @param skDate endPeriod
 * @param boolean[] visibleDaysWeekTemplate : array of 7 booleans, one for each day of the week.
 * Example [true, true, true, true, true, true, true]
 * @returns skDate[]
 */
export const visibleDaysInPeriod = (startPeriod, endPeriod, visibleDaysWeekTemplate) => {
  const daysInPeriod = [];
  const currentDay = startPeriod.clone();

  // Add every day in the period except non-visible days
  while (currentDay <= endPeriod) {
    if (visibleDaysWeekTemplate[currentDay.isoWeekday() - 1]) daysInPeriod.push(currentDay.clone());
    currentDay.add(1, 'day');
  }

  return daysInPeriod;
};

export const frenchDateFormatter = date => date.format('DD/MM/YYYY');

/**
 * Given an array of object that and a key, returns a Set of object.key
 * Example: setOfValues([{value: 1}, {value: 2}, {value: 2}], 'value') => Set {1, 2}
 */
export const setOfObjectValues = (array, key) => new Set(array.map(item => item[key]));

/**
 * Replace the value of the workload plan by null in case it's 0
 *
 * workloadPlan : workload plan object eg { startsAt: '...', value: 2 }
 * @returns workload plan with either a number or null
 */
export const nullifyZeros = workloadPlan => (
  workloadPlan.value === 0 ? { ...workloadPlan, value: null } : workloadPlan
);
