<template>
  <PaidLeavesCounterInitializationModalContentWrapper
    id="pto-periods-initialization-modal-content-wrapper"
    ref="ptoPeriodsInitializationModalContentWrapper"
    :modal-title="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.title')"
    :handle-submit="handleSubmit"
    :handle-show="handleShowModal"
    :handle-confirm="handleConfirmModal"
    :handle-cancel="handleCancel"
    :balance-activated="balanceActivated"
    :init-date="initDate"
    :pending-wanted="pendingWanted"
    :is-switch-disabled="isSwitchDisabled"
    :loading="loading"
    :submit-loading="submitLoading"
    @update-balance-activated="updateBalanceActivated"
    @update-init-date="updateInitDate"
  >
    <template #middle-section>
      <SkModalSectionV2 class="paid-leaves-counter-modal">
        <div class="paid-leaves-counter-modal__plc-period">
          <div class="paid-leaves-counter-modal__plc-period__title">
            {{ $t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.plc_period.title') }}
          </div>
          <div class="paid-leaves-counter-modal__plc-period__select">
            <SkSelectV2
              v-model="plcStartMonth"
              :label="$t('shop_settings.tabs.rules.counter_rules.plc_start_month.label')"
              :options="plcStartMonthOptions"
            />
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #balance>
      <SkInfoCard class="paid-leaves-counter-modal__balance__card">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="$t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.explanation_card')" />
      </SkInfoCard>
      <div
        v-if="!isSwitchDisabled"
        class="paid-leaves-counter-modal__balance__column-titles"
      >
        <span> {{ $t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.previous_history') }} </span>
        <span> {{ $t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.current_history') }} </span>
      </div>
      <UserRow
        v-for="user in users"
        :key="user.id"
        :user="user"
        :unit="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.days')"
        :enable-error-proc="pendingWanted"
        @keyup="handleErroredBalance"
      />
    </template>
  </PaidLeavesCounterInitializationModalContentWrapper>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {
  mapState,
  mapMutations,
} from 'vuex';

import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { arrayToSelectOptions } from '@skello-utils/form';
import PaidLeavesCounterInitializationModalContentWrapper from '@app-js/shared/components/CountersInitializationModal/PaidLeavesCounterInitializationModalContentWrapper';
import UserRow from '@app-js/shared/components/CountersInitializationModal/PtoPeriodsDistinctionInitializationModal/UserRow.vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

export default {
  name: 'PtoPeriodsInitializationModal',
  components: { PaidLeavesCounterInitializationModalContentWrapper, UserRow },
  data() {
    return {
      originalData: null,
      balanceActivated: false,
      initDate: null,
      pendingWanted: false,
      users: [],
      loading: true,
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    plcStartMonth: {
      get() {
        return this.currentShop.attributes.plcStartMonth;
      },

      set(newValue) {
        this.setShopAttributes({ plcStartMonth: newValue });
      },
    },
    allUsersInitialPlc() {
      return this.users.reduce((initalCounters, user) => {
        initalCounters[user.id] = [user.previousYearBalance, user.currentYearBalance];
        return initalCounters;
      }, {},
      );
    },
    usersValidInitialPlc() {
      return this.users.reduce((initalCounters, user) => {
        if (!this.isErroredBalance(user.previousYearBalance) &&
          !this.isErroredBalance(user.currentYearBalance)) {
          initalCounters[user.id] = [user.previousYearBalance, user.currentYearBalance];
        }
        return initalCounters;
      }, {},
      );
    },
    emptyOrNaNUserBalance() {
      const isEmptyOrNaN = field => field === null || field === '' || isNaN(field);
      return this.users.some(
        user => isEmptyOrNaN(user.previousYearBalance) || isEmptyOrNaN(user.currentYearBalance),
      );
    },
    plcStartMonthOptions() {
      return arrayToSelectOptions(
        this.config.plc_start_months,
        value => this.$t(`shop_settings.tabs.rules.counter_rules.plc_start_months.${value}`),
      );
    },
    isAnyChange() {
      return Object.keys(this.originalData)
        .some(key => !isEqual(this[key], this.originalData[key]));
    },
    isSwitchDisabled() {
      return this.users.length === 0;
    },
    initModalRef() {
      return this.$refs.ptoPeriodsInitializationModalContentWrapper
        .$refs.paidLeavesCounterInitializationModal;
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    handleShowModal() {
      this.initDate = this.currentShop.attributes.plcInitializationDoneAt;

      this.balanceActivated =
        this.currentShop.attributes.plcStatus === this.config.plc_pending;

      this.fetchUsers();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    handleConfirmModal(event) {
      this.initModalRef.hide();
      this.resetData();
      this.$emit('cancel', event);
    },
    handleCancel(event) {
      if (this.isAnyChange && this.originalData) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: this.handleConfirmModal,
        });
      }
    },
    fetchUsers() {
      httpClient
        .get('/v3/api/users/paid_leaves_counters_users', { params: { shop_id: this.currentShop.id } })
        .then(response => {
          this.users = [...response.data.data].map(user => {
            const defaultPreviousBalance =
              typeof user.attributes.previousInitialPlc === 'number' ? user.attributes.previousInitialPlc : null;
            const defaultCurrentBalance =
              typeof user.attributes.currentInitialPlc === 'number' ? user.attributes.currentInitialPlc : null;
            return {
              ...user,
              previousYearBalance: defaultPreviousBalance,
              currentYearBalance: defaultCurrentBalance,
              errorBalance: true,
            };
          });
          if (!this.balanceActivated) this.balanceActivated = !this.emptyOrNaNUserBalance;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.fetch_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.originalData = cloneDeep(
            {
              initDate: this.initDate,
              users: this.users,
            },
          );
        });
    },
    handleErroredBalance(event, user) {
      user.errorPreviousBalance = this.isErroredBalance(user.previousYearBalance);
      user.errorCurrentBalance = this.isErroredBalance(user.currentYearBalance);
    },
    isErroredBalance(balance) {
      return typeof balance !== 'number';
    },
    handleSubmit() {
      // condition when all users balance are not filled && balance is activated
      if (this.balanceActivated && this.emptyOrNaNUserBalance && !this.pendingWanted) {
        this.pendingWanted = true;
        this.$skAnalytics.track('paid_leaves_counter_initialization_save_and_finish_later_submit');
        this.$nextTick(() => {
          this.initModalRef.scrollToBottom();
        });
        return;
      }

      if (
        !this.pendingWanted &&
        this.currentShop.attributes.plcStatus === this.config.plc_pending
      ) {
        this.$skAnalytics.track('paid_leaves_counter_initialization_validate_from_resume');
      } else if (!this.balanceActivated) {
        this.$skAnalytics.track('paid_leaves_counter_initialization_validate');
      }

      const params = {
        paid_leave_counter: {
          start_date: this.initDate,
          users_initial_plc: this.balanceActivated ?
            this.usersValidInitialPlc : this.allUsersInitialPlc,
          balance_not_inherited: !this.balanceActivated,
          plc_pending: this.pendingWanted,
          shop_id: this.currentShop.id,
          // employees with contract starting in the future
          should_update_future_users: true,
        },
        shop: {
          plc_start_month: this.plcStartMonth,
        },
      };

      this.submitLoading = true;

      httpClient
        .patch('/v3/api/paid_leaves_counters', params)
        .then(response => {
          const context = {
            date: params.paid_leave_counter.start_date,
            period: params.shop.plc_start_month,
          };

          if (!params.paid_leave_counter.balance_not_inherited) {
            context.tracker_history = params.paid_leave_counter.users_initial_plc;
          }

          try {
            this.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_PTO_TRACKER_ENABLE, context);
          } catch (error) {
            captureException(error);
          }

          if (this.initDate) {
            this.setShopAttributes({ plcInitializationDoneAt: this.initDate });
          }
          if (params.paid_leave_counter.plc_pending) {
            this.setShopAttributes({
              plcStatus: this.config.plc_pending,
              plcInitialized: true,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_pending'),
              variant: 'success',
            });
          } else {
            this.setShopAttributes({
              plcStatus: this.config.plc_initialized,
              plcInitialized: true,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_complete'),
              variant: 'success',
            });
          }
          this.resetData();
          this.initModalRef.hide();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    updateBalanceActivated(newValue) {
      this.balanceActivated = newValue;
    },
    updateInitDate(newValue) {
      this.initDate = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-leaves-counter-modal {
  &__plc-period {
    display: flex;
    justify-content: space-between;

    &__title {
      width: 180px;
      line-height: 18px;
      color: $sk-grey;
      display: flex;
      align-items: center;
    }

    &__select {
      width: 340px;
    }
  }

  &__balance__card {
    margin: 16px 0 20px;
  }

  &__balance__column-titles {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    width: 224px;
    padding-bottom: 4px;
    font-weight: $fw-semi-bold;

    span:last-child {
      min-width: 92px;
    }
  }
}
</style>
