<template>
  <div
    v-if="isVisible"
    class="badgings__toolbar-actions-bar"
  >
    <SkPopover
      v-if="isPopoverDisplayed"
      :class="toolbarActionClasses"
      as-tooltip
    >
      <template #anchor>
        <div class="badgings__toolbar__btn-wrapper">
          <SkCircleButton
            v-if="!separator"
            :icon="icon"
            :disabled="disabled"
          />
          <div
            v-if="isNew"
            :class="newFeatureFlagClasses"
          >
            {{ $t('plannings.toolbar.actions_bar.new') }}
          </div>
          <div
            v-else-if="isPreOrderable"
            :class="preOrderableFlagClasses"
          >
            {{ $t('plannings.toolbar.actions_bar.pre_order') }}
          </div>
        </div>
      </template>
      <template #content>
        <template v-if="isFeatureComingSoon">
          <strong>
            <i class="em em-drum_with_drumsticks" />
            {{ $t('plannings.toolbar.actions_bar.tooltip_soon.title') }}
          </strong>
          <p>{{ $t('plannings.toolbar.actions_bar.tooltip_soon.content') }}</p>
        </template>
        <p v-else>
          {{ tooltip }}
        </p>
      </template>
    </SkPopover>
    <span
      v-else-if="isButtonDisplayed"
      v-tooltip="showTooltip ? title : ''"
      :class="toolbarActionClasses"
    >
      <div class="badgings__toolbar__btn-wrapper">
        <SkCircleButton
          v-if="!separator"
          :icon="icon"
          :disabled="disabled"
          :icon-size="iconSize"
          :data-test="dataTest"
          @click="handler"
        />
        <div
          v-else-if="isPreOrderable"
          :class="preOrderableFlagClasses"
        >
          {{ $t('plannings.toolbar.actions_bar.pre_order') }}
        </div>
      </div>
    </span>
    <component
      :is="popovComponent"
      v-else
      ref="popovs"
      :class="toolbarActionClasses"
      :disabled="disabled"
      :is-new="isNew"
    />
    <ScheduleRecommendationPopover
      v-if="isRecommendationPopoverDisplayed"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ScheduleRecommendationPopover from '@app-js/plannings/shared/components/Toolbar/ScheduleRecommendation/ScheduleRecommendationPopover';

export default {
  name: 'GenericToolbarAction',
  components: {
    ScheduleRecommendationPopover,
  },
  props: {
    isDataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('planningsAutomaticPlanning', ['isBrainFeatureFlagActive']),
    disabled() {
      return false;
    },
    showTooltip() {
      return !this.disabled;
    },
    tooltip() {
      return '';
    },
    separator() {
      return false;
    },
    visibleIn() {
      return '';
    },
    icon() {
      return '';
    },
    title() {
      return '';
    },
    iconSize() {
      return '';
    },
    dataTest() {
      return '';
    },
    popovComponent() {
      return '';
    },
    hideOnSmallScreen() {
      return false;
    },
    isVisible() {
      return false;
    },
    isNew() {
      return false;
    },
    isPreOrderable() {
      return false;
    },
    isDropdown() {
      return false;
    },
    isPopov() {
      return false;
    },
    isPopoverDisplayed() {
      return this.disabled && this.tooltip && !this.isDropdown;
    },
    isButtonDisplayed() {
      return !this.isPopov && !this.isDropdown;
    },
    isFeatureComingSoon() {
      return this.tooltip === 'soon';
    },
    isRecommendationPopoverDisplayed() {
      return this.icon === 'MagicWandV2Icon' && this.isBrainFeatureFlagActive;
    },
    toolbarActionClasses() {
      return {
        'planning__toolbar__vertical-border': this.separator,
        'hide-on-small-screen': this.visibleIn === 'toolbar-m-dropdown-s' || this.hideOnSmallScreen,
        'sk-icon-button--tooltip-shown': this.$refs.tooltip?.showTooltip,
      };
    },
    newFeatureFlagClasses() {
      return {
        'planning__toolbar__new-feature-tag': true,
        'planning__toolbar__new-feature-tag--disabled': this.disabled,
      };
    },
    preOrderableFlagClasses() {
      return {
        'planning__toolbar__preorderable-tag': true,
        'planning__toolbar__preorderable-tag--disabled': this.disabled,
      };
    },
  },
  methods: {
    onFailure() {
      this.$skToast({
        message: this.$t('errors.standard_message'),
        variant: 'error',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.planning__toolbar-actions-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.planning__toolbar__btn-wrapper {
  position: relative;
}

.planning__toolbar__new-feature-tag {
  position: absolute;
  font-size: 7px;
  border-radius: 25px;
  top: 80%;
  text-align: center;
  width: 40px;
  color: white;
  background: linear-gradient(#459fff, $sk-blue, $sk-royal-blue);
  border: solid 1px $sk-grey-5;

  &--disabled {
    background: $sk-grey-30;
  }
}

.planning__toolbar__preorderable-tag {
  flex: 1;
  position: absolute;
  font-size: $fs-text-xxs;
  border-radius: 25px;
  margin-left: -13px;
  top: 78%;
  text-align: center;
  justify-items: center;
  width: 66px;
  height: 14px;
  color: white;
  background: linear-gradient(#459fff, $sk-blue, $sk-royal-blue);
  border: solid 1px $sk-grey-5;
  z-index: 1;

  &--disabled {
    background: $sk-grey-30;
  }
}

.planning__toolbar__vertical-border {
  width: 25px;
  height: 0;
  border: .1px solid $sk-grey-10;
  transform: rotate(90deg);
}

// Dropdown hidden menu
.planning__toolbar__settings-menu {
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  padding: 10px 0;

  &--hidden {
    visibility: hidden;
  }

  .settings-dropdown__item {
    display: flex;
    align-items: center;
    color: $sk-black;
    padding: 10px 15px;
    width: 100%;

    // we want to force the smart planner icon from dropdown to be gray (like the others)
    ::v-deep svg > path {
      fill: $sk-grey;
    }

    &:hover {
      background: $sk-grey-10;
      border-radius: 3px;
      text-decoration: none;
    }

    &--disabled {
      color: $sk-grey-30;
      cursor: default;
    }

    &--popov {
      height: 41px;
      padding: 0;
    }
  }

  .settings-dropdown__item-icon {
    margin-right: 15px;
  }
}

.sk-icon-button--tooltip-shown {
  ::v-deep .planning__toolbar__new-feature-tag {
    display: none;
  }

  ::v-deep .planning__toolbar__preorderable-tag {
    display: none;
  }
}
</style>

<!--
This unscoped style tag is used to override the SkPopover default styles in 2 cases :
- hiding/displaying dropdown and toolbar icon at 1200px breakpoints
- matching design requirements for the coming soon tooltip.
-->
<style lang="scss">
.planning__toolbar-actions-bar {
  .sk-popover--tooltip {
    padding: 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 155px;

    * {
      margin: 0;
      padding: 0;
    }
  }

  // Manage action bar on small screen (< 1200px)
  .hide-on-small-screen {
    display: block;
  }

  .planning__toolbar__settings-menu {
    a.settings-dropdown__item.display-on-small-screen {
      padding: 10px 15px;
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .hide-on-small-screen {
      display: none;
    }

    .planning__toolbar__settings-menu {
      a.settings-dropdown__item.display-on-small-screen {
        display: flex;
      }
    }
  }
}
</style>
