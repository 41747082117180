<template>
  <div :class="progressIconClasses">
    <div class="progress-icon--column">
      {{ text }}
    </div>
    <svg
      class="progress-icon--level"
      :height="28"
      :width="28"
    >
      <circle
        stroke="#188377"
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="ringStyle"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'ProgressIcon',
  props: {
    total: {
      type: Number,
      required: true,
    },
    partial: {
      type: Number,
      required: true,
    },
  },
  data() {
    const radius = 14;
    const stroke = 2;
    const normalizedRadius = radius - stroke + 1;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference,
      radius,
      stroke,
    };
  },
  computed: {
    progressIconClasses() {
      return {
        'progress-icon': true,
        'progress-icon--row': true,
        'progress-icon--complete': this.isComplete,
        'progress-icon--partial': !this.isComplete,
      };
    },
    text() {
      return `${this.partial}/${this.total}`;
    },
    isComplete() {
      return this.partial === this.total;
    },
    percent() {
      return (this.partial * 100) / this.total;
    },
    ringStyle() {
      return {
        strokeLinecap: 'round',
        strokeDashoffset: this.strokeDashoffset,
      };
    },
    strokeDashoffset() {
      return this.circumference - (this.percent / 100) * this.circumference;
    },
  },
  mounted() {
    if (this.partial < 0) throw new Error('Partial cannot be negative');
    if (this.total < 0) throw new Error('Total cannot be negative');
    if (this.partial > this.total) throw new Error('Partial cannot be greater than total');
  },
};
</script>

<style lang="scss" scoped>
.progress-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 9px;
  position: relative;
}

.progress-icon--level {
  position: absolute;
  rotate: -90deg;
 }

.progress-icon--partial {
  background: $sk-grey-10;
  color: $sk-grey-50;
}

.progress-icon--complete {
  background: $sk-success;
  color: #fff;
}

.progress-icon--row {
  display: flex;
  justify-content: center;
}

.progress-icon--column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
