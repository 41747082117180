import COUNTRY_KEYS from '@app-js/shared/constants/country-keys';

/* eslint-disable */
export function countryKeys(country) {
    const countries = [...COUNTRY_KEYS]; // copy country_keys not to modify it
    if (country && COUNTRY_KEYS.includes(country)) {
        countries.splice(countries.indexOf(country), 1);
        countries.unshift(country);
    }
    return countries;
}

export function languageToCountryCode(language) {
    if (language === 'en') return 'GB';
    return language.toUpperCase();
};
