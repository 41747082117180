<template>
  <SkModalV2
    id="new-leave-request-modal"
    ref="newLeaveRequestModal"
    class="new-leave-request-modal"
    :title="leaveRequestModalLabels[isOwnRequestsView ? 'own' : 'teams'].title"
    :submit-button-label="leaveRequestModalLabels[isOwnRequestsView ? 'own' : 'teams'].submit"
    :submit-loading="isSubmitting"
    :submit-disabled="!isSubmittable"
    :testing-options="{
      cancel: 'leave-request__cancel-button',
      submit: 'leave-request__submit-button',
    }"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit.prevent="handleSubmit"
    @show="handleShow"
  >
    <template #body>
      <div
        v-if="!isOwnRequestsView && areEmployeesLoading"
        class="new-leave-request-modal__loader-wrapper"
      >
        <SkLoader size="large" />
      </div>
      <form v-else>
        <ShopSelectRow
          v-if="isAllRequestsView"
          v-model="shopId"
          :shops="shops"
        />
        <section
          v-if="!isOwnRequestsView"
          class="new-leave-request-modal__inputs__teams"
        >
          <SkModalSectionV2>
            <label for="employee">
              {{ $t('requests.leave_requests.modal.teams.employee.label') }}*
            </label>
            <SkSelectV2
              v-model="employeeId"
              data-test="leave-request__select-employee__input"
              input-id="employee"
              name="employee"
              :label="$t('requests.leave_requests.modal.teams.employee.placeholder')"
              :no-results-label="$t('requests.leave_requests.no_search_results')"
              width="394px"
              :options="employeeOptions"
              append-to-body
            />
          </SkModalSectionV2>
        </section>
        <AbsenceSelectRow
          ref="absenceRow"
          v-model="absenceId"
          :absences="absenceOptions"
          :is-loading="isFetchingAbsences"
        />
        <AbsenceTemporalityRow v-model="temporality" />
        <SkModalSectionV2>
          <label for="sentTo">
            {{ $t('requests.leave_requests.modal.sent_to') }}
          </label>
          <SkSelectV2
            v-model="managerId"
            data-test="leave-request__select-send-to_input"
            input-id="sentTo"
            name="SentTo"
            :is-input-loading="isFetchingManagers"
            :options="managerOptions"
            :label="$t('requests.leave_requests.modal.sent_to')"
            :no-results-label="$t('requests.leave_requests.no_search_results')"
            width="394px"
            append-to-body
            @input="trackManagerChange"
          />
        </SkModalSectionV2>
        <SkModalSectionV2
          class="new-leave-request-modal__comments__section"
          border-bottom="none"
        >
          <label for="comments">
            {{ $t('requests.leave_requests.modal.comments.label') }}
          </label>
          <span class="new-leave-request-modal__comments">
            <SkMedallion
              icon="ClipboardIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              size="small"
            />
            <SkTextarea
              v-model="comments"
              data-test="leave-request__select-notes-input"
              :min-height="93"
              text-area-id="comments"
              :label="$t('requests.leave_requests.modal.comments.placeholder')"
            />
          </span>
        </SkModalSectionV2>
      </form>
    </template>
  </SkModalV2>
</template>

<script>

import { capitalize } from '@skello-utils/formatting/strings';
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';

import AbsenceSelectRow from './LeaveRequests/AbsenceSelectRow.vue';
import ShopSelectRow from './LeaveRequests/ShopSelectRow.vue';
import AbsenceTemporalityRow from './LeaveRequests/AbsenceTemporalityRow.vue';

const DO_NOT_SEND_BY_MAIL_ID = 0;

export default {
  name: 'NewLeaveRequestModal',
  components: {
    AbsenceSelectRow,
    AbsenceTemporalityRow,
    ShopSelectRow,
  },
  mixins: [RoutesMixins],
  data() {
    return {
      leaveRequestModalLabels: {
        own: {
          title: this.$t('requests.leave_requests.modal.own.title'),
          submit: this.$t('requests.leave_requests.modal.own.submit'),
        },
        teams: {
          title: this.$t('requests.leave_requests.modal.teams.title'),
          submit: this.$t('requests.leave_requests.modal.teams.submit'),
        },
      },
      absenceId: null,
      localShopId: null,
      comments: null,
      localEmployeeId: null,
      sentTo: null,
      managerId: null,
      preselectedManagerIdByEmployees: {},
      temporality: { duration: 'day', fullDays: [], halfDay: null },
      isFetchingAbsences: false,
      isFetchingManagers: false,
      isSubmitting: false,
      hasManagerBeenPreselected: false,
      hasPreselectedManagerChanged: false,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('requests', ['currentNodeShopsWithEmployees', 'currentShopIds', 'tableFilters']),
    ...mapGetters('currentLicense', ['canManageEmployeeRequests']),
    ...mapGetters('currentUser', ['currentUserInPlanningShopIds']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('requests', [
      'absencesByShopId',
      'areEmployeesLoading',
      'managedEmployees',
      'managersByEmployeeId',
      'pagination',
      'sort',
    ]),
    shops() {
      const allShops = this.canManageEmployeeRequests ?
        this.currentNodeShopsWithEmployees :
        this.currentNodeShops;

      if (!this.isOwnRequestsView) return allShops;

      const shopsInPlanning = allShops.filter(
        shop => this.currentUserInPlanningShopIds.includes(parseInt(shop.id, 10)),
      );

      return shopsInPlanning;
    },
    shopId: {
      get() {
        const allShops = this.shops;

        if (!this.isAllRequestsView) {
          return this.currentShop.id;
        }

        if (this.localShopId) {
          return this.localShopId;
        }
        const userShopId = this.currentUser.attributes.shopId;
        const shopId = allShops.some(shop => shop.id === userShopId) ?
          userShopId :
          allShops[0]?.id;

        return String(shopId);
      },
      set(newValue) {
        this.localShopId = newValue;

        this.resetDataOnShopSelection();
      },
    },
    absenceOptions() {
      return this.absencesByShopId[this.shopId] ?? [];
    },
    employeeOptions() {
      const shopId = Number(this.shopId);
      const inSelectedShopAndInPlanning = employee => employee.attributes.shopId === shopId &&
        employee.relationships.memberships?.find(
          membership => membership.attributes.shopId === shopId,
        )?.attributes.inPlanning;

      const managedEmployees = this.isAllRequestsView ?
        this.managedEmployees.filter(inSelectedShopAndInPlanning) : this.managedEmployees;

      return managedEmployees.map(({ id, attributes }) => ({
        id,
        text: `${capitalize(attributes.firstName)} ${attributes.lastName}`,
      }));
    },
    managerOptions() {
      const doNoSendByMailOption = {
        id: DO_NOT_SEND_BY_MAIL_ID,
        text: this.$t('requests.leave_requests.modal.send_no_email'),
      };

      // handle case when no managers are found
      if (
        !this.managersByEmployeeId[this.userIdParam] ||
        this.managersByEmployeeId[this.userIdParam].length === 0
      ) {
        return [doNoSendByMailOption];
      }

      return [doNoSendByMailOption, ...this.managersByEmployeeId[this.userIdParam].map(manager => ({
        id: manager.id,
        text: manager.name,
      }))];
    },
    isSubmittable() {
      if (this.isOwnRequestsView) {
        return this.areDatesValid;
      }
      return this.checkTeamSubmit;
    },
    checkTeamSubmit() {
      return this.employeeId && this.areDatesValid;
    },
    areDatesValid() {
      return (
        (this.temporality.duration === 'day' && this.temporality.fullDays[0]) ||
        (this.temporality.duration === 'half-day' && this.temporality.halfDay)
      );
    },
    userIdParam() {
      return this.isOwnRequestsView ? this.currentUser.id : this.employeeId;
    },
    managerIdParam() {
      return this.managerId === DO_NOT_SEND_BY_MAIL_ID ? null : this.managerId;
    },
    startDateParam() {
      return this.temporality.duration === 'day' ? this.temporality.fullDays[0] : this.temporality.halfDay;
    },
    endDateParam() {
      return this.temporality.duration === 'day' ? this.temporality.fullDays[1] : this.temporality.halfDay;
    },
    employeeId: {
      get() {
        return this.localEmployeeId;
      },
      set(newValue) {
        if (newValue === this.localEmployeeId) {
          return;
        }

        this.localEmployeeId = newValue;

        if (newValue !== DO_NOT_SEND_BY_MAIL_ID && newValue !== null) {
          this.handleEmployeeSelection();
        }
      },
    },
    shopIds() {
      if (this.isAllRequestsView) {
        const shops = this.canManageEmployeeRequests ?
          this.currentNodeShopsWithEmployees :
          this.currentNodeShops;
        return shops.map(shop => shop.id);
      }
      return [this.shopId];
    },
  },
  methods: {
    ...mapActions('requests', [
      'createLeaveRequest',
      'fetchLeaveRequests',
      'fetchManagers',
      'fetchPendingRequestsCount',
      'fetchPreSelectedManager',
      'fetchShopAbsences',
    ]),
    trackManagerChange() {
      if (this.hasManagerBeenPreselected) {
        this.hasPreselectedManagerChanged = true;
      }
    },
    async handleSubmit() {
      this.isSubmitting = true;

      let leaveRequestParams;
      if (this.isDevFlagEnabled('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
        leaveRequestParams = {
          shopId: this.shopId,
          employeeId: this.userIdParam,
          positionId: this.absenceId,
          receiverId: this.managerIdParam ? String(this.managerIdParam) : null,
          startsAt: this.startDateParam,
          endsAt: this.endDateParam,
          employeeMessage: this.comments,
          calculation: this.temporality.duration,
          posteId: this.absenceId,
        };
      } else {
        leaveRequestParams = {
        // TO DO change shop_id when all shops are implemented
          shop_id: this.currentShop.id,
          user_id: this.userIdParam,
          poste_id: this.absenceId,
          manager_id: this.managerIdParam,
          start_date: this.startDateParam,
          end_date: this.endDateParam,
          employee_message: this.comments,
          absence_calculation: this.temporality.duration,
        };
      }

      try {
        const leaveRequest = await this.createLeaveRequest(leaveRequestParams);
        // track here
        if (this.hasPreselectedManagerChanged) {
          this.$skAnalytics.track('leave_request_preselected_manager_changed', { leave_request_id: leaveRequest.id });
          this.hasPreselectedManagerChanged = false;
        }

        this.$refs.newLeaveRequestModal.hide();
        this.$skToast({ message: this.$t('requests.toast.success.create'), variant: 'success' });
        await this.fetchLeaveRequests({
          isOwnRequestsView: this.isOwnRequestsView,
          shopIds: this.shopIds,
          userId: this.userIdParam,
          pagination: this.pagination,
          sort: this.sort,
          filters: this.tableFilters,
          isAllRequestsView: this.isAllRequestsView,
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      } finally {
        if (this.currentShop.id !== 'all') {
          this.fetchPendingRequestsCount({ shop_id: this.currentShop.id });
        }

        this.isSubmitting = false;
        this.handleCancel();
      }
    },
    handleCancel() {
      this.$nextTick(() => {
        this.temporality = { duration: 'day', fullDays: [], halfDay: null };
        this.comments = null;
        this.employeeId = null;
        this.localShopId = null;
        this.managerId = DO_NOT_SEND_BY_MAIL_ID;

        this.$refs.absenceRow.reset();
      });
    },
    async handleEmployeeSelection() {
      const currentEmployeeId = this.userIdParam;
      this.isFetchingManagers = true;

      if (!this.managersByEmployeeId[this.userIdParam]) {
        this.managerId = DO_NOT_SEND_BY_MAIL_ID;
        await this.fetchManagers({
          shopId: this.shopId,
          employeeId: this.userIdParam,
        });
      }

      if (currentEmployeeId !== this.userIdParam) {
        return;
      }

      if (this.preselectedManagerIdByEmployees[this.userIdParam]) {
        this.managerId = this.preselectedManagerIdByEmployees[this.userIdParam];
      } else {
        await this.getPreSelectedManager();
      }

      this.isFetchingManagers = false;
    },
    async fetchAbsences() {
      this.isFetchingAbsences = true;

      try {
        await this.fetchShopAbsences(this.shopId);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });
        throw error;
      }

      this.isFetchingAbsences = false;
    },
    async getPreSelectedManager() {
      if (
        this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2') &&
        this.userIdParam &&
        this.currentShop
      ) {
        const preSelectedManagerId = await this.fetchPreSelectedManager({
          employeeId: this.userIdParam,
          shopId: this.shopId,
          managerIds: JSON.stringify(
            this.managerOptions
              .filter(manager => manager.id !== DO_NOT_SEND_BY_MAIL_ID)
              .map(manager => String(manager.id)),
          ),
        });

        if (preSelectedManagerId) {
          const id = Number(preSelectedManagerId);

          this.managerId = id;
          this.preselectedManagerIdByEmployees[this.userIdParam] = id;
          this.hasManagerBeenPreselected = true;
          this.hasPreselectedManagerChanged = false;
        }
      }
    },
    handleShow() {
      if (this.isOwnRequestsView) {
        this.employeeId = this.currentUser.id;
      }

      if (this.shopId && !this.absencesByShopId[this.shopId]) {
        this.fetchAbsences();
      }
    },
    resetDataOnShopSelection() {
      this.absenceId = null;

      if (!this.absencesByShopId[this.localShopId]) {
        this.fetchAbsences();
      }

      this.employeeId = null;
      this.managerId = null;
      this.isFetchingManagers = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-leave-request-modal__loader-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
}

.new-leave-request-modal label {
  font-weight: $fw-semi-bold;
  margin-bottom: 0;
}

::v-deep .sk-radio__label {
  margin-bottom: 0;
}

.sk-modal__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-leave-request-modal__comments {
  display: flex;
  gap: 8px;
  width: 394px;
}

.new-leave-request-modal__comments__section {
  align-items: flex-start;
}

.new-leave-request-modal__radios {
  display: flex;
  gap: 16px;
  width: 394px;
}
</style>
