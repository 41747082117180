<template>
  <div>
    <div
      v-show="!disabled"
      class="tasks-manager__new-task__container"
      :style="tasksManagerNewTaskContainerStyle"
    >
      <TasksTextarea
        v-model="newTaskLabel"
        :display-border-on-focus="false"
        :placeholder="$t('plannings.table.manage_shift_modal.tabs.shift.tasks.add_task_placeholder')"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown.enter.prevent="handleAddTask"
      />
      <SkCircleButton
        icon="PlusSignV2Icon"
        background-color="transparent"
        size="small"
        :disabled="newTaskLabel.length === 0"
        @click="handleAddTask"
      />
    </div>
    <DraggableTasksList
      :value="value"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import TasksTextarea from '@app-js/plannings/shared/components/ShiftActivities/Tasks/TasksTextarea';
import DraggableTasksList from '@app-js/plannings/shared/components/ShiftActivities/Tasks/DraggableTasksList';

export default {
  name: 'TasksManager',
  components: { TasksTextarea, DraggableTasksList },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTaskLabel: '',
      borderBottom: '1px solid #ddd',
      isDragging: false,
    };
  },
  computed: {
    tasksManagerNewTaskContainerStyle() {
      return {
        borderBottom: this.borderBottom,
      };
    },
  },
  created() {
    this.listenOnRoot('reset-tasks-manager', () => {
      Object.assign(this.$data, this.$options.data.call(this));
    });
  },
  methods: {
    handleAddTask() {
      if (this.newTaskLabel.length === 0) return;

      this.value.push({
        label: this.newTaskLabel,
        isChecked: false,
        order: this.value.length,
      });
      this.newTaskLabel = '';
    },
    handleFocus() {
      this.borderBottom = 'solid 1px #2b66fe';
    },
    handleBlur() {
      this.borderBottom = '1px solid #ddd';
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks-manager__new-task__container {
  display: flex;
  align-items: center;
  padding: 4px 0;
  margin: 0 0 4px 24px;
}
</style>
