<template>
  <div class="document-row">
    <div class="document-row__name-row">
      <div class="document-row__file-icon">
        <SkMedallion
          background-color="#D9E6FF"
          color="#2B66FE"
          icon="FileV2Icon"
          size="large"
        />
      </div>
      <div class="document-row__details">
        <div class="document-row__details--name">
          {{ document.title }}
        </div>
        <div class="document-row__details--size">
          {{ Math.round(document.file.size / 10000) / 100 }} {{ $t('employees.tabs.documents.create_modal.byte_unit') }}
        </div>
      </div>
      <div class="document-row__delete">
        <SkCircleButton
          :disabled="disabled"
          icon="TrashCanV2Icon"
          icon-color="#D03E50"
          size="small"
          @click="$emit('remove-file', document.file)"
        />
      </div>
    </div>
    <div class="document-row__inputs-row">
      <div class="document-row__name-input">
        <SkInput
          v-model="value.title"
          :disabled="disabled"
          :errored="value.title === ''"
          :error-message="$t('employees.tabs.documents.create_modal.errors.title')"
          :label="$t('employees.tabs.documents.create_modal.placeholder.document_name')"
          max-length="60"
        />
      </div>
      <SkDatePicker
        v-if="showExpirationDate"
        v-model="value.expirationDate"
        :disabled-date="date => date < today"
        :disabled="disabled"
        :lang="$i18n.locale"
        :placeholder="$t('employees.tabs.documents.create_modal.placeholder.expiration_date')"
        append-to-body
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentRow',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    showExpirationDate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      today: new Date(new Date().setHours(0, 0, 0, 0)),
    };
  },
  computed: {
    document: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.document-row {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;

  &__name-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__file-icon {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0; // prevent flex-grow to overflow
    margin-right: 8px;
    font-weight: $fw-regular;

    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $sk-black;
      font-size: $fs-text-l;
    }

    &--size {
      color: $sk-grey-50;
      font-size: $fs-text-s;
    }

    &__delete {
      flex-shrink: 0;
    }
  }

  &__inputs-row {
    display: flex;
    gap: 8px;
  }

  &__name-input {
    width: 352px;
  }
}
</style>
