import skDate from '@skello-utils/dates';

export const shiftDuration = (shift, timezone) => {
  const endsAt = skDate.utc(shift.attributes.endsAt).format('YYYY-MM-DDTHH:mm:ss');
  const startsAt = skDate.utc(shift.attributes.startsAt).format('YYYY-MM-DDTHH:mm:ss');

  const endsAtWithTimezone = skDate.tz(endsAt, timezone);
  const startsAtWithTimezone = skDate.tz(startsAt, timezone);

  return skDate.duration(endsAtWithTimezone.diff(startsAtWithTimezone));
};

export const adjustedShiftDuration = (shift, currentShop, date) => {
  const duration = shiftDuration(shift, currentShop.attributes.timezone).clone();
  const pauseTime = !shift.attributes.pauseTime ? 0 : shift.attributes.pauseTime / 60;

  // If pause compensation is inactive or starts after current shift's date
  // -> pause isn't paid: we need to deduce it from the duration
  const pauseCompensationStartsAt = currentShop.attributes.pauseCompensationStartsAt;
  if (!pauseCompensationStartsAt || date.isBefore(skDate(pauseCompensationStartsAt))) {
    duration.subtract(skDate.duration(pauseTime, 'm'));
  }

  duration.subtract(skDate.duration(shift.attributes.delay, 'm'));

  return duration;
};

export const formattedDuration = (shift, currentShop, date) => {
  const duration = adjustedShiftDuration(shift, currentShop, date);
  const sign = duration.asMinutes() < 0 ? '-' : '';
  const hours = Math.abs(Math.floor(duration.asHours()));
  const minutes = Math.abs(duration.minutes());

  if (minutes === 0) {
    return `${sign}${hours}h`;
  }
  if (minutes < 10) {
    return `${sign}${hours}h0${minutes}`;
  }
  return `${sign}${hours}h${minutes}`;
};
