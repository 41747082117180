<template>
  <SkModal
    id="request-esignature-upsell-modal"
    ref="RequestEsignatureUpsellModal"
    size="ds-medium"
    :modal-title="$t('plannings.toolbar.modal.esignature_upsell.aware_admin.title')"
    :modal-subtitle="$t('plannings.toolbar.modal.esignature_upsell.aware_admin.subtitle')"
    @close="closeModal"
  >
    <template #body>
      <div class="request-esignature-upsell-modal__recipients-section-wrapper">
        <RecipientsSection
          ref="recipientSection"
          v-model="email.selectedRecipients"
          :recipients="email.recipients"
        />
      </div>
      <div class="request-esignature-upsell-modal__email-wrapper">
        <div class="request-esignature-upsell-modal__email-intro">
          {{ $t('plannings.toolbar.modal.esignature_upsell.aware_admin.intro') }}
        </div>
        <SkInput
          v-model="email.subject"
          :label="$t('employees.send_email.subject')"
        />
        <div class="request-esignature-upsell-modal__email-body-section">
          <SkTextarea
            v-model="email.body"
            :label="$t('employees.send_email.body')"
            :min-height="265"
            :max-height="120"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <SkOroraButton
        v-track="'esignature_upsell_aware_admin_send_email'"
        :loading="isSendingRequest"
        :disabled="isSendEmailButtonDisabled"
        @click="handleSubmit"
      >
        {{ $t('plannings.toolbar.modal.esignature_upsell.aware_admin.submit_mail') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';
import {
  MODAL_HIDE_EVENT, MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';
import RecipientsSection from './RecipientsSection';

export default {
  name: 'RequestEsignatureUpsellModal',
  components: {
    RecipientsSection,
  },
  data() {
    return {
      email: {
        subject: '',
        body: '',
        recipients: [],
        selectedRecipients: [],
      },
      isSendingRequest: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    hasValidUserInput() {
      return (this.email.subject && this.email.subject.trim() !== '') ||
        (this.email.body && this.email.body.trim() !== '');
    },
    isSendEmailButtonDisabled() {
      return !this.hasValidUserInput ||
        this.email.recipients.length === 0 ||
        this.isSendingRequest;
    },
  },
  mounted() {
    this.listenOnRoot('show-request-esignature-upsell-modal', event => {
      this.email.subject = this.$t('plannings.toolbar.modal.esignature_upsell.aware_admin.subject');
      this.email.body = this.$t('plannings.toolbar.modal.esignature_upsell.aware_admin.body');
      this.email.recipients = event.recipients;
      this.emitOnRoot(MODAL_SHOW_EVENT, null, 'request-esignature-upsell-modal');
      setTimeout(() => this.$refs.recipientSection.selectAllByDefault(), 50);
    });
  },
  methods: {
    handleSubmit() {
      if (this.isSendingRequest) return;

      this.isSendingRequest = true;

      const userIds = this.email.selectedRecipients.map(recipient => recipient.id);

      // In case we want to send file here :
      // It is very important to use FormData when uploading file trough XHR !
      // This way we receive the request with the proper headers in the backend
      // Dont try to use Base64 encoded strings or you wont receveive an <Http::UploadedFile>
      const data = new FormData();
      data.append('mailing[subject]', this.email.subject);
      data.append('mailing[body]', this.email.body);
      data.append('mailing[bcc]', false);
      data.append('mailing[user_ids]',
        // Need to stringify to keep array structure
        JSON.stringify(userIds),
      );
      data.append('mailing[upsell_mail]', true);

      httpClient
        .post('/v3/api/mailing/send_email', data)
        .then(this.handleRequestSuccess)
        .catch(this.handleRequestError)
        .finally(() => { this.isSendingRequest = false; });
    },
    handleRequestSuccess(response) {
      this.closeModal();
      this.$skToast({
        message: this.$t('employees.send_email.toast.success'),
        variant: 'success',
        containerId: 'employees__container',
      });
    },
    handleRequestError(error) {
      this.$skToast({
        message: this.$t('employees.send_email.toast.error'),
        variant: 'error',
        containerId: 'employees__container',
      });
    },
    closeModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'request-esignature-upsell-modal');
    },
  },
};
</script>

<style lang="scss">
#request-esignature-upsell-modal {
  .request-esignature-upsell-modal__recipients-section-wrapper {
    padding: 16px 0;
    margin: 0;
    border-bottom: 1px solid $sk-grey-10;
  }

  .request-esignature-upsell-modal__email-wrapper {
    padding: 20px 24px 0;
    margin: 0;
  }

  .sk-select {
    width: 130px !important;
  }

  .sk-modal__footer {
    margin: 0;
    padding: 32px 24px 24px;
    justify-content: center;
  }

  .request-esignature-upsell-modal__email-intro {
    padding-bottom: 24px;
  }

  .request-esignature-upsell-modal__email-body-section {
    padding-top: 16px;

    .sk-textarea {
      height: 220px;
    }

    .sk-textarea__input {
      padding: 0 16px 8px;
    }
  }
}
</style>
