<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 58 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.3241 47H1.56515C0.535146 47 -0.213854 46.022 0.055146 45.027L10.2211 17.156C10.4051 16.474 11.0241 16 11.7311 16H56.4901C57.5201 16 58.0001 16.984 58.0001 17.973L47.8341 45.844C47.6501 46.526 47.0311 47 46.3241 47Z"
      fill="#B1CEFE" />
    <path
      d="M50.2681 7H25.0001L20.0001 0H1.73212C0.776122 0 0.00012207 0.775 0.00012207 1.732V44.46C0.0691221 44.462 0.138122 44.466 0.205122 44.47L10.2201 17.156C10.4041 16.473 11.0231 16 11.7301 16H52.0001V8.732C52.0001 7.775 51.2241 7 50.2681 7Z"
      fill="#2b66fe" />
  </svg>
</template>

<script>
export default {
  name: 'EmptyFolder',
  props: {
    size: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    width() {
      return this.size === 'small' ? '32' : '45';
    },
    height() {
      return this.size === 'small' ? '26' : '36';
    },
  },
};
</script>
