<template>
  <!-- eslint-disable  max-len -->
  <div class="organisation-infos__container">
    <div
      v-if="loading"
      class="organisation-infos-spinner__wrapper"
    >
      <SkLoader />
    </div>
    <div v-else>
      <MountingPortal
        append
        mount-to="#modals-portal"
      >
        <CancelFreeTrialModal @free-trial-cancelled="cancelFreeTrial" />
      </MountingPortal>
      <h1 class="sk-header--1">
        {{ $t('organisation_settings.tabs.organisation_info.title') }}
      </h1>
      <p class="sk-subtitle--large organisation-infos__head-section">
        {{ $t('organisation_settings.tabs.organisation_info.subtitle') }}
      </p>
      <FreeTrialBanner
        v-if="showSelfServeFreeTrial"
        :disabled="isFreeTrialCancelled"
      />
      <div class="sk-form-section">
        <h3 class="mt-3 mb-2">
          {{ $t('organisation_settings.tabs.organisation_info.organisation_data_title') }}
        </h3>
        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.name.section')"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('organisation_settings.tabs.organisation_info.name.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="name"
                :label="$t('organisation_settings.tabs.organisation_info.name.label')"
                :errored="hasNameError"
                :error-message="$t('organisation_settings.tabs.organisation_info.name.error')"
                @keyup="checkError"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.corporate_name.section')"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('organisation_settings.tabs.organisation_info.corporate_name.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="denominationSociale"
                :label="$t('organisation_settings.tabs.organisation_info.corporate_name.label')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.address.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="address"
                :label="$t('organisation_settings.tabs.organisation_info.address.labels.address')"
              />
            </div>
          </div>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="zipcode"
                :label="$t('organisation_settings.tabs.organisation_info.address.labels.zipcode')"
              />
            </div>
            <div class="col-6 organisation-infos__name-section__last-col">
              <SkInput
                v-model="city"
                :label="$t('organisation_settings.tabs.organisation_info.address.labels.city')"
              />
            </div>
          </div>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="region"
                :label="$t('organisation_settings.tabs.organisation_info.address.labels.region')"
              />
            </div>
            <div class="col-6 organisation-infos__name-section__last-col">
              <SkSelectV2
                v-model="country"
                :label="$t('organisation_settings.tabs.organisation_info.address.labels.country')"
                :options="countries"
                :search-placeholder="$t('labels.search')"
                :no-results-label="$t('search_bar.no_result')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.siren.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="siren"
                :label="$t('organisation_settings.tabs.organisation_info.siren.label')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.vat_number.section')"
          :last="!isBillingInfoOnOrganisation"
          split
        >
          <div class="row sk-form__row sk-form__row--last_row">
            <div class="col-12">
              <SkInput
                v-model="tvaNumber"
                :label="$t('organisation_settings.tabs.organisation_info.vat_number.label')"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          v-if="isBillingInfoOnOrganisation"
          :title="$t('shop_settings.tabs.shop.billing_info.address.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="billingAddress"
                :label="$t('shop_settings.tabs.shop.address.labels.address')"
                :errored="errors['billingAddress']"
                :error-message="errorMessage"
                @keyup="validateNoMandatoryEmptyFields('billingAddress')"
              />
            </div>
          </div>

          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="billingZipcode"
                :label="$t('shop_settings.tabs.shop.address.labels.zipcode')"
              />
            </div>
            <div class="col-6">
              <SkInput
                v-model="billingCity"
                :label="$t('shop_settings.tabs.shop.address.labels.city')"
              />
            </div>
          </div>

          <SkSelectV2
            v-model="billingCountry"
            :label="$t('shop_settings.tabs.shop.address.labels.country')"
            :options="countries"
            :no-results-label="$t('search_bar.no_result')"
            :search-placeholder="$t('labels.search')"
          />
        </SkForm>

        <SkForm
          v-if="isBillingInfoOnOrganisation"
          :title="$t('shop_settings.tabs.shop.billing_info.email.section')"
          split
          last
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('shop_settings.tabs.shop.billing_info.email.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-12">
              <SkInput
                v-model="billingEmail"
                :label="$t('shop_settings.tabs.shop.billing_info.email.label')"
                :errored="errors['billingEmail']"
                :error-message="errorMessage"
                @keyup="validateNoMandatoryEmptyFields('billingEmail')"
              />
            </div>
          </div>
        </SkForm>

        <h3 class="mt-4 mb-3">
          {{ $t('organisation_settings.tabs.organisation_info.organisation_settings_title') }}
        </h3>
        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.primary_contact.section')"
          split
        >
          <div class="row sk-form__row">
            <div class="col-6">
              <SkSelect
                v-model="primaryContact"
                :ajax-client="ajaxClient"
                :ajax-format-params="ajaxParamsAdmins"
                :ajax-format-response="ajaxFormatResponse"
                :label="$t('organisation_settings.tabs.organisation_info.primary_contact.label')"
                :search-placeholder="$t('labels.search')"
                ajax-url="/v3/api/users/administrators"
                object-value
                paginated
              >
                <template #selected-option="slotProps">
                  {{ slotProps.value.text }}
                </template>
                <template #option="{ option }">
                  <div> {{ option.text }} </div>
                </template>
                <template #empty-option>
                  {{ $t('search_bar.no_result') }}
                </template>
              </SkSelect>
            </div>
          </div>
        </SkForm>

        <SkForm
          v-if="isMealRulesEnabled"
          data-test="meal-rule-input"
          :title="$t('organisation_settings.tabs.organisation_info.meal_price.section', {
            currency_symbol: currentShop.attributes.currencySymbol
          })"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('organisation_settings.tabs.organisation_info.meal_price.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-6">
              <SkInput
                v-model="mealPrice"
                :label="$t('organisation_settings.tabs.organisation_info.meal_price.label')"
                type="number"
                min="0"
              />
            </div>
          </div>
        </SkForm>

        <SkForm
          :title="$t('organisation_settings.tabs.organisation_info.business_sector.section')"
          :last="!firstPunchClockShopId"
          split
        >
          <div class="row sk-form__row">
            <div class="col-6">
              <SkSelectV2
                v-model="businessSector"
                :label="$t('organisation_settings.tabs.organisation_info.business_sector.label')"
                :options="businessSectors"
                :search-placeholder="$t('labels.search')"
                :no-results-label="$t('search_bar.no_result')"
              />
            </div>
          </div>
        </SkForm>
        <SkForm
          v-if="firstPunchClockShopId && secondaryShopsEnabled"
          :title="$t('organisation_settings.tabs.organisation_info.employees_can_punch_offsite.section')"
          :last="!!firstPunchClockShopId"
          split
        >
          <template #title-icon>
            <span>
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('organisation_settings.tabs.organisation_info.employees_can_punch_offsite.tooltip')"
                width="18"
                height="18"
              />
            </span>
          </template>
          <div class="row sk-form__row">
            <div class="col-6">
              <SkSwitch v-model="canEmployeePunchOffsite" />
            </div>
          </div>
        </SkForm>

        <div
          v-if="!isBillingInfoNoEntity"
          class="organisation-infos__sepa-link"
        >
          <SkOroraButton v-modal.update-sepa-modal>
            {{ paymentTypeText }}
          </SkOroraButton>
          <MountingPortal
            mount-to="#modals-portal"
            append
          >
            <UpdateSepaModal
              ref="updateSepaModal"
              :is-payment-type-sepa="isPaymentTypeSepa"
              @billing-info-to-sepa="setBillingInfoToSepa"
            />
          </MountingPortal>
        </div>

        <div v-if="showBillingInfo">
          <h3 class="mt-4 mb-3">
            {{ billingInfoTitle }}
          </h3>

          <div
            v-for="billingLine, index in billingInfoLines"
            :key="index"
          >
            <BillingLine
              v-model="billingInfoLines[index]"
              :collapsable="hasMultiplePayingEntities"
            />
          </div>
        </div>

        <div v-if="showChargebeeWidgets">
          <h3 class="mt-4 mb-3">
            {{ $t('organisation_settings.tabs.organisation_info.invoices.title') }}
          </h3>

          <div class="organisation-infos__banner-box">
            <div class="organisation-infos__banner__content">
              <CircledIIcon
                fill="#2b66fe"
                width="24"
                height="24"
                class="organisation-infos__banner__icon"
              />
              <span>{{ $t('organisation_settings.tabs.organisation_info.invoices.banner') }}</span>
            </div>
          </div>

          <SkTable
            :columns="chargebeeTableColumns"
          >
            <ChargebeeWidgetLine
              v-for="chargebeeLine in chargebeeLines"
              :key="chargebeeLine.chargebeeId"
              :chargebee-line="chargebeeLine"
              :cb-instance="cbInstance"
            />
          </SkTable>
        </div>
      </div>
    </div>

    <StickyBar
      :tracking-options="trackingOptions"
      :visible="unsavedChanges"
      :submit-button-label="$t('organisation_settings.sticky_bar.submit')"
      :disabled="inputError || !canAllBillingInfoLineChange"
      container-scroll-id="organisation-settings"
      class="sk-sticky-bar"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { arrayToSelectOptions } from '@skello-utils/form';
import { countryKeys } from '@skello-utils/country-keys.js';
import {
  httpClient,
  svcBillingAutomationClient,
} from '@skello-utils/clients';
import { FEATURES } from '@app-js/shared/constants/features.js';
import StickyBar from '@app-js/shared/components/Stickybar';
import { chargebeeSite } from '@config/env';

import BillingLine from './BillingLine';
import CancelFreeTrialModal from './CancelFreeTrialModal';
import ChargebeeWidgetLine from './ChargebeeWidgetLine';
import FreeTrialBanner from './FreeTrialBanner';
import UpdateSepaModal from './UpdateSepaModal';

export default {
  name: 'OrganisationInfos',
  components: {
    BillingLine,
    CancelFreeTrialModal,
    ChargebeeWidgetLine,
    FreeTrialBanner,
    StickyBar,
    UpdateSepaModal,
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.unsavedChangesToCurrentOrganisation) {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashCurrentOrganisation();
          next();
        },
        submitColor: this.$skColors.skBlue50,
        title: this.$t('actions.continue'),
      });
    } else {
      next();
    }
  },
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (vm.$store.getters['currentLicense/isSystemAdmin']) {
        vm.$router.push({ name: to.name });
      } else if (vm.currentLicense.attributes.canEditShopPermissions) {
        vm.$router.push({ name: 'organisation_settings_licenses' });
      } else if (vm.currentLicense.attributes.canCreateShop) {
        vm.$router.push({ name: 'organisation_settings_shops' });
      } else if (vm.isEmployee) {
        vm.$router.push({ name: 'plannings_employee_all' });
      } else {
        vm.$router.push({ name: 'home_dashboard', params: { shop_id: 'all' } });
      }
    });
  },
  data() {
    return {
      ajaxClient: () => httpClient,
      trackingOptions: {
        update: 'update_organisation',
      },
      loading: true,
      errors: {
        billingEmail: false,
        billingAddress: false,
      },
      billingOwners: [],
      billingInfo: {},
      billingInfoLines: [],
      chargebeeLines: [],
      originalBillingInfo: {},
      shops: [],
      iban: null, // used to set iban in updateSepaModal
      chargebeeTableColumns: [
        { title: this.$t('organisation_settings.tabs.organisation_info.invoices.location_name') },
        { title: this.$t('organisation_settings.tabs.organisation_info.invoices.action') },
      ],
      cbInstance: window.Chargebee.init({
        site: chargebeeSite,
      }),
      FEATURES,
      isFreeTrialCancelled: false,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', [
      'currentOrganisation',
      'inputError',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentOrganisation', [
      'checkPackOfferFlag',
      'isFreeTrialEnabled',
      'unsavedChangesToCurrentOrganisation',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),
    name: {
      get() {
        return this.currentOrganisation.attributes.name;
      },

      set(newValue) {
        this.setOrganisationAttributes({ name: newValue });
      },
    },

    denominationSociale: {
      get() {
        return this.currentOrganisation.attributes.denominationSociale;
      },

      set(newValue) {
        this.setOrganisationAttributes({ denominationSociale: newValue });
      },
    },

    address: {
      get() {
        return this.currentOrganisation.attributes.headquartersAddress;
      },

      set(newValue) {
        this.setOrganisationAttributes({ headquartersAddress: newValue });
      },
    },

    zipcode: {
      get() {
        return this.currentOrganisation.attributes.headquartersPostcode;
      },

      set(newValue) {
        this.setOrganisationAttributes({ headquartersPostcode: newValue });
      },
    },

    city: {
      get() {
        return this.currentOrganisation.attributes.headquartersCity;
      },

      set(newValue) {
        this.setOrganisationAttributes({ headquartersCity: newValue });
      },
    },

    region: {
      get() {
        return this.currentOrganisation.attributes.headquartersRegion;
      },

      set(newValue) {
        this.setOrganisationAttributes({ headquartersRegion: newValue });
      },
    },

    country: {
      get() {
        return this.currentOrganisation.attributes.headquartersCountry;
      },
      set(value) {
        this.setOrganisationAttributes({ headquartersCountry: value });
      },
    },

    siren: {
      get() {
        return this.currentOrganisation.attributes.siren;
      },

      set(newValue) {
        this.setOrganisationAttributes({ siren: newValue });
      },
    },

    tvaNumber: {
      get() {
        return this.currentOrganisation.attributes.tvaNumber;
      },

      set(newValue) {
        this.setOrganisationAttributes({ tvaNumber: newValue });
      },
    },

    billingAddress: {
      get() {
        return this.billingInfo.attributes.address;
      },

      set(newValue) {
        this.billingInfo.attributes.address = newValue;
      },
    },

    billingZipcode: {
      get() {
        return this.billingInfo.attributes.zipcode;
      },

      set(newValue) {
        this.billingInfo.attributes.zipcode = newValue;
      },
    },

    billingCity: {
      get() {
        return this.billingInfo.attributes.city;
      },

      set(newValue) {
        this.billingInfo.attributes.city = newValue;
      },
    },

    billingCountry: {
      get() {
        return this.billingInfo.attributes.country;
      },
      set(newValue) {
        this.billingInfo.attributes.country = newValue;
      },
    },

    billingEmail: {
      get() {
        return this.billingInfo.attributes.email;
      },

      set(newValue) {
        this.billingInfo.attributes.email = newValue;
      },
    },

    primaryContact: {
      get() {
        if (!this.currentOrganisation.relationships.owner) return null;

        return {
          id: this.currentOrganisation.relationships.owner.id,
          text: this.fullName(this.currentOrganisation.relationships.owner),
        };
      },

      set(newValue) {
        this.setPrimaryContact(newValue);
      },
    },

    mealPrice: {
      get() {
        return this.currentOrganisation.attributes.benefitInKind;
      },

      set(newValue) {
        this.setOrganisationAttributes({
          benefitInKind: newValue === '' ? 0 : newValue,
        });
      },
    },

    businessSector: {
      get() {
        return this.currentOrganisation.attributes.businessSector;
      },

      set(newValue) {
        this.setOrganisationAttributes({ businessSector: newValue });
      },
    },

    canEmployeePunchOffsite: {
      get() {
        return this.currentOrganisation.attributes.publicBadgings;
      },

      set(newValue) {
        this.setOrganisationAttributes({ publicBadgings: newValue });
      },
    },

    showBillingInfo() {
      return this.billingInfoLines.length;
    },

    showSelfServeFreeTrial() {
      return this.isFreeTrialEnabled && this.isSystemAdmin;
    },

    billingInfoTitle() {
      if (this.hasMultiplePayingEntities) {
        return this.$t('organisation_settings.tabs.organisation_info.billing_info.multiple_entities_title');
      }

      return this.$t('organisation_settings.tabs.organisation_info.billing_info.single_entity_title');
    },

    billingOwnerIds() {
      return this.billingOwners.map(owner => owner.chargebee_id);
    },

    showChargebeeWidgets() {
      return this.chargebeeLines.length;
    },

    hasMultiplePayingEntities() {
      return this.billingInfoLines.length > 1;
    },

    canAllBillingInfoLineChange() {
      if (!this.haveAnyBillingInfoLineChange) return true;

      return this.billingInfoLines.every(line => line.canChange);
    },

    haveAnyBillingInfoLineChange() {
      return this.billingInfoLines.some(line => line.hasChanged);
    },

    secondaryShopsEnabled() {
      return this.checkPackOfferFlag('secondary_shops_enabled');
    },

    countries() {
      return arrayToSelectOptions(countryKeys('FR'), value => this.$t(`countries.${value}`));
    },

    businessSectors() {
      return this.config.business_sectors.map(businessSector => ({
        id: businessSector,
        text: this.$t(`business_sector.${businessSector}`),
      }));
    },

    hasNameError() {
      return !this.name;
    },

    isEmployee() {
      return Object.values(this.config.licenses_by_level.employees).includes(
        this.currentLicense.attributes.originalType,
      );
    },
    isAdmin() {
      return this.isSystemAdmin || this.currentUser.attributes.superAdmin;
    },
    firstPunchClockShopId() {
      return this.currentOrganisation.attributes.firstPunchClockShopId;
    },
    errorMessage() {
      return this.$t('shop_settings.tabs.shop.error.empty_field');
    },
    unsavedChanges() {
      return this.unsavedChangesToLegacyBillingInfo ||
        this.unsavedChangesToCurrentOrganisation ||
        this.unsavedChangesToBillingInfo;
    },
    unsavedChangesToLegacyBillingInfo() {
      // in updateSepaModal, we change billingInfo.paymentType and we set this.iban; this change is handled
      // in modal, so if iban is set we don't want StickyBar to pop
      if (this.loading || !this.isBillingInfoOnOrganisation || this.iban) return false;

      return this.legacyBillingInfoHasChanged;
    },

    unsavedChangesToBillingInfo() {
      if (this.loading) return false;

      return this.haveAnyBillingInfoLineChange;
    },

    // Used to check if billingInfo has changed from what was fetched from SkelloApp API
    legacyBillingInfoHasChanged() {
      return JSON.stringify(this.billingInfo) !== JSON.stringify(this.originalBillingInfo);
    },
    isBillingInfoOnOrganisation() {
      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[2];
    },
    isBillingInfoOnShop() {
      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[1];
    },
    isBillingInfoNoEntity() {
      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[0];
    },
    isPaymentTypeSepa() {
      return this.billingInfo.attributes.paymentType === this.config.payment_types[0];
    },
    isDemoOrganisation() {
      return this.currentOrganisation.attributes.status === 'demo';
    },
    paymentTypeText() {
      if (this.isPaymentTypeSepa) return this.$t('organisation_settings.sepa_modal_link.update_payment_type');

      return this.$t('organisation_settings.sepa_modal_link.change_payment_type');
    },
    isMealRulesEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_MEALS, this.currentShop.id, () => this.checkPackOfferFlag('meal_rules_enabled'));
    },
  },
  watch: {
    // allows to handle unsavedChanges on billingInfo
    billingInfo: {
      deep: true,
      // handler is mandatory
      handler() { },
    },
  },
  mounted() {
    // The isFreeTrialCancelled attribute is not always present in the organisation attributes
    this.isFreeTrialCancelled = !!this.currentOrganisation.attributes.isFreeTrialCancelled;

    if (this.isBillingInfoNoEntity) {
      this.loading = false;
      if (!this.isAdmin || this.isDemoOrganisation) return;

      this.fetchChargebeeLines();
    } else {
      this.fetchBillingInfo();
    }
  },
  methods: {
    ...mapActions('currentOrganisation', ['updateCurrentOrganisation']),
    ...mapMutations('currentOrganisation', [
      'setOrganisationAttributes',
      'squashCurrentOrganisation',
      'setOrganisationInputError',
      'setPrimaryContact',
    ]),
    checkError() {
      this.setOrganisationInputError(!this.name);
    },
    cancelFreeTrial() {
      this.isFreeTrialCancelled = true;
    },
    ajaxParamsAdmins(params) {
      return {
        ...params,
      };
    },
    ajaxFormatResponse(response) {
      return response.data.map(user => ({
        id: user.id,
        text: this.fullName(user),
        attributes: {
          firstName: user.attributes.firstName,
          lastName: user.attributes.lastName,
        },
      }));
    },
    fetchChargebeeLines() {
      if (!this.isDevFlagEnabled('FEATUREDEV_CHARGEBEE_INVOICES_ENABLED')) return;

      svcBillingAutomationClient
        .getChargebeeIdsFromSalesforce(this.currentOrganisation.id)
        .then(async response => {
          // If paying by shop, we need shop names to display
          this.billingOwners = response.owners;
          if (this.billingOwners.some(owner => owner.sk_shop_id)) {
            await this.fetchShops();
          }
          if (this.billingOwners.some(owner => owner.chargebee_id)) {
            this.fetchPaymentSources();
          }
          this.billingOwners.forEach(owner => {
            this.chargebeeLines.push(this.extractChargebeeLine(owner));
          });
        }).then(() => {
          this.mapOwnersToSkelloResources();
        });
    },
    fetchPaymentSources() {
      if (!this.isDevFlagEnabled('FEATUREDEV_CHARGEBEE_INVOICES_ENABLED')) return;

      svcBillingAutomationClient
        .getSources(this.billingOwnerIds)
        .then(response => {
          this.mapOwnersToPaymentSources(response);
        });
    },
    mapOwnersToPaymentSources(paymentSources) {
      // Reset billingInfoLines when we fetch paymentSources
      // After an update
      this.billingInfoLines = [];

      this.billingOwners.forEach(owner => {
        const paymentSource = paymentSources.find(
          source => source.customer_id === owner.chargebee_id,
        );
        if (paymentSource) {
          this.billingInfoLines.push(
            {
              address: paymentSource.billingAddress.line1 || '*'.repeat(50),
              city: paymentSource.billingAddress.city || '*'.repeat(10),
              zipCode: paymentSource.billingAddress.zip || '*'.repeat(5),
              country: paymentSource.billingAddress.country || '',
              email: paymentSource.customerEmail,
              canChange: true,
              chargebeeId: owner.chargebee_id,
              hasChanged: false,
              name: owner.name,
              ibanWithSpacesAllowed: this.starPaddedIban(paymentSource.bank_account.last4),
            },
          );
        }
      });
    },
    starPaddedIban(lastFourDigits) {
      // An IBAN is 34 characters long, so we pad the first 30 characters with *
      return '*'.repeat(30) + lastFourDigits;
    },
    fetchShops() {
      return httpClient
        .get(
          '/v3/api/shops',
          { params: { skip_pagination: true } },
        )
        .then(response => {
          this.shops = response.data.data;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    mapOwnersToSkelloResources() {
      this.billingOwners.forEach(owner => {
        if (owner.sk_shop_id) {
          owner.name = this.shops.find(
            shop => shop.id === owner.sk_shop_id.toString(),
          ).attributes.name;
        }
        if (owner.sk_organisation_id) {
          owner.name = this.currentOrganisation.attributes.denominationSociale;
        }
      });
    },
    extractChargebeeLine(owner) {
      const paysByOrganisation = !!owner.sk_organisation_id;
      const relatedEntity = paysByOrganisation ?
        this.currentOrganisation :
        this.shops.find(shop => shop.id === owner.sk_shop_id.toString());
      return {
        chargebeeId: owner.chargebee_id,
        name: relatedEntity.attributes.name,
      };
    },
    fetchBillingInfo() {
      if (this.isBillingInfoNoEntity) {
        this.loading = false;
        return;
      }

      let params = {};
      // FIXME: DEV-9074 if billing_on_shop, we need shop_id in order to
      // find organisation's first billing_info and then payment_type
      // (if no shop_id, API will return 422)
      // payment_type should really be on billing_info ? or on organisation ?
      if (this.isBillingInfoOnShop) {
        params = {
          shop_id: this.currentOrganisation.attributes.firstShopId,
        };
      }

      httpClient
        .get('/v3/api/billing_infos', { params })
        .then(response => {
          this.billingInfo = response.data.data;
          this.originalBillingInfo = cloneDeep(this.billingInfo);
          this.loading = false;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    updateBillingInfo() {
      const params = {
        iban: this.iban,
        billing_infos: {
          email: this.billingInfo.attributes.email,
          address: this.billingInfo.attributes.address,
          zipcode: this.billingInfo.attributes.zipcode,
          city: this.billingInfo.attributes.city,
          country: this.billingInfo.attributes.country,
          payment_type: this.billingInfo.attributes.paymentType,
        },
      };

      return httpClient
        .patch('/v3/api/billing_infos', params)
        .then(response => {
          this.billingInfo = response.data.data;
          this.originalBillingInfo = cloneDeep(this.billingInfo);
        });
    },
    handleSubmit() {
      if (!this.unsavedChanges) {
        this.$skToast({
          message: this.$t('organisation_settings.update_organisation.no_change_message'),
          variant: 'information',
        });
        return;
      }

      if (this.haveAnyBillingInfoLineChange && this.canAllBillingInfoLineChange) {
        this.updateBillingInformations();
      }

      // Legacy billing info
      if (this.unsavedChangesToLegacyBillingInfo) {
        this.updateBillingInfo();
      }

      if (this.unsavedChangesToCurrentOrganisation) {
        this.updateOrganisation();
      }
    },
    updateBillingInformations() {
      const billingInfoLinesToUpdate = this.billingInfoLines.filter(line => line.hasChanged);

      const billingInformations = billingInfoLinesToUpdate.map(line => ({
        chargebeeId: line.chargebeeId,
        email: line.email,
        iban: line.iban,
        address: line.address,
        zipCode: line.zipCode,
        city: line.city,
        country: line.country,
      }));

      svcBillingAutomationClient.updateBillingInformations(
        this.currentOrganisation.id,
        billingInformations,
      ).then(() => {
        this.$skToast({
          message: this.$t('organisation_settings.update_organisation.success_message'),
          variant: 'success',
        });

        this.resetBillingLineChanges();
        this.fetchPaymentSources();
      }).catch(() => {
        this.$skToast({
          message: this.$t('organisation_settings.update_organisation.error_message'),
          variant: 'error',
        });
      });
    },
    updateOrganisation() {
      this.updateCurrentOrganisation(this.currentOrganisation)
        .then(() => {
          this.$skToast({
            message: this.$t('organisation_settings.update_organisation.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_settings.update_organisation.error_message'),
            variant: 'error',
          });
        });
    },
    resetBillingLineChanges() {
      this.billingInfoLines = [];
    },
    validateNoMandatoryEmptyFields(field) {
      // since we got both email/address on both models organisation and billingInfo
      // billingField removes billing prefix
      // e.g: billingEmail becomes email
      const billingField = field.slice(7).toLowerCase();

      this.errors[field] = !this.billingInfo.attributes[billingField];
    },
    setBillingInfoToSepa(iban) {
      this.billingInfo.attributes.paymentType = this.config.payment_types[0];
      this.iban = iban;
      this.updateBillingInfo()
        .then(response => {
          this.$skToast({
            message: this.$t('organisation_settings.sepa_modal.toasts.success'),
            variant: 'success',
          });
          this.$refs.updateSepaModal.billingInfoUpdateSucceeded();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('organisation_settings.sepa_modal.toasts.error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.$refs.updateSepaModal.stopLoading();
          this.iban = null;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-infos-spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: white;
}

.organisation-infos__head-section {
  padding: 5px 0 0;
  color: $sk-grey;
}

.organisation-infos__name-section__last-col {
  padding-left: 5px;
}

.organisation-infos__sepa-link {
  padding-top: 26px;
}

.organisation-infos__fake-link {
  color: $sk-blue;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.organisation-infos__banner-box {
  margin: 0 0 10px;
  border: 1px solid $sk-grey-10;
  display: flex;
  align-items: center;
  padding: 20px;
  color: $sk-grey-50;
  border-radius: 4px;
}

.organisation-infos__banner__content {
  display: flex;
  align-items: flex-start;
}

.organisation-infos__banner__icon {
  margin-right: 16px;
}

.sk-sticky-bar {
  position: sticky;
  bottom: 0;
}
</style>
