<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086
         10 6C10 8.20914 8.20914 10 6 10ZM1 6C1 8.76142 3.23858 11 6 11C8.76142 11
         11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6ZM5.5
         5.5V8.5H6.5V5.5H5.5ZM5.5 3.5V4.5H6.5V3.5H5.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'WorkloadPlanTooltipInfoIcon',
  props: {
    width: {
      type: String,
      default: '24',
      required: false,
    },
    height: {
      type: String,
      default: '24',
      required: false,
    },
    fill: {
      type: String,
      default: '#727272',
      required: false,
    },
  },
};
</script>
