<template>
  <TooltipWrapper
    :background-color="backgroundColor"
    :color-text="colorText"
  >
    <template v-if="!!tooltipData">
      <TooltipTitle :title="tooltipData.title" />

      <div
        class="kpi-tooltip__numbers__wrapper"
        :style="analyticTooltipCssVar"
      >
        <span
          v-if="showPredicted"
          class="kpi-tooltip__numbers"
        >
          <span class="kpi-tooltip__numbers__label kpi-tooltip__numbers__label-color-prev">
            {{ textLabels[0] }}
          </span>
          <span class="kpi-tooltip__numbers__value">
            {{ tooltipData.predictedValue }}
          </span>
          <span class="kpi-tooltip__numbers__unit">
            {{ tooltipData.unit }}
          </span>
        </span>

        <span
          v-if="showPredicted"
          :class="kpiTooltipSeperator"
        />

        <span class="kpi-tooltip__numbers">
          <span class="kpi-tooltip__numbers__label kpi-tooltip__numbers__label-color-real">
            {{ textLabels[1] }}
          </span>
          <span class="kpi-tooltip__numbers__value">
            {{ tooltipData.realValue }}
          </span>
          <span class="kpi-tooltip__numbers__unit">
            {{ tooltipData.unit }}
          </span>
        </span>
      </div>

      <div
        v-if="showDifference"
        class="kpi-tooltip__performance__wrapper"
      >
        <PerformanceArrow
          :performance="tooltipData.performance"
          :arrow="tooltipData.arrow"
        />
        <div
          :class="performanceClassName"
          class="kpi-tooltip__performance__difference"
        >
          {{ $t('kpis.performance_tooltip.difference') }}
          <span class="kpi-tooltip__performance__difference--numbers">
            <!-- eslint-disable-next-line max-len -->
            <span>{{ formatNumber(tooltipData.differenceComputation) }} {{ displayedUnit }}</span>
            <span v-if="!infinitePerformance">
              ({{ formatNumber(tooltipData.differencePercentage) }}%)
            </span>
          </span>
        </div>
      </div>
    </template>
  </TooltipWrapper>
</template>

<script>
import TooltipWrapper from '../shared/TooltipWrapper';
import TooltipTitle from '../shared/TooltipTitle';

export default {
  name: 'AnalyticsTooltip',
  components: {
    TooltipWrapper,
    TooltipTitle,
  },
  props: {
    tooltipData: {
      type: Object,
      default: null,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'black',
    },
    colorText: {
      type: String,
      required: false,
      default: 'white',
    },
    labelColors: {
      type: Array,
      required: false,
      default: null,
    },
    showPredicted: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDifference: {
      type: Boolean,
      required: false,
      default: true,
    },
    customLabels: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    textLabels() {
      if (this.customLabels && this.customLabels.length === 2) return this.customLabels;

      return [
        this.$t('kpis.performance_tooltip.predicted'),
        this.$t('kpis.performance_tooltip.real'),
      ];
    },
    performanceClassName() {
      return `kpi-tooltip__performance__difference-${this.tooltipData.performance}`;
    },
    infinitePerformance() {
      return this.tooltipData.predictedValue === 0 && this.tooltipData.realValue !== 0;
    },
    displayedUnit() {
      // displays unit only for light mode
      if (this.backgroundColor === 'black') return '';

      // If unit is %, difference is displayed in points and not %
      return this.tooltipData.unit === '%' ?
        this.$t('kpis.performance_tooltip.points_shortened') :
        this.tooltipData.unit;
    },
    kpiTooltipSeperator() {
      return {
        'kpi-tooltip__numbers__seperator': true,
        'kpi-tooltip__numbers__seperator-light': this.backgroundColor === 'white',
      };
    },
    analyticTooltipCssVar() {
      return {
        '--label-color-prev': this.labelColors === null ? 'inherit' : this.labelColors[0],
        '--label-color-real': this.labelColors === null ? 'inherit' : this.labelColors[1],
      };
    },
  },
  methods: {
    // Display numbers like this : -100.01 / 0.00 / +100.01
    formatNumber(num) {
      let display = num.toFixed(2);
      if (num > 0) display = `+${display}`;
      return display;
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-tooltip__numbers__wrapper {
  display: flex;
  align-items: center;
}

.kpi-tooltip__numbers {
  display: flex;
  align-items: baseline;
}

.kpi-tooltip__numbers__label {
  margin-right: 3px;
  font-size: $fs-text-s;

  &-color-prev {
    color: var(--label-color-prev);
  }

  &-color-real {
    color: var(--label-color-real);
  }
}

.kpi-tooltip__numbers__value {
  font-size: $fs-text-m;
}

.kpi-tooltip__numbers__unit {
  font-size: $fs-text-xs;
  margin-left: 4px;
}

.kpi-tooltip__numbers__seperator {
  margin: 0 12px;
  border: 1px solid rgba(255, 255, 255, .24);
  height: 15px;

  &-light {
    border: 1px solid $sk-grey;
    opacity: .16;
  }
}

.kpi-tooltip__performance__wrapper {
  margin-top: 5px;
  display: flex;
}

.kpi-tooltip__performance__difference {
  margin-left: 10px;
  font-size: $fs-text-s;
}

.kpi-tooltip__performance__difference--numbers {
  margin-left: 4px;
}

.kpi-tooltip__performance__difference-positive {
  color: $sk-success;
}

.kpi-tooltip__performance__difference-neutral {
  color: $sk-grey;
}

.kpi-tooltip__performance__difference-negative {
  color: $sk-error;
}
</style>
