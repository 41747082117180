<template>
  <div class="workload-plan-panel-loading-animation__container">
    <!-- Same number of line as with ApexChart -->
    <hr
      v-for="n in 7"
      :key="n"
      class="workload-plan-panel-loading__line"
    >
  </div>
</template>

<script>
export default { name: 'WorkloadPlanLoadingState' };
</script>

<style lang="scss" scoped>
.workload-plan-panel-loading {
  &-animation__container {
    background:
      radial-gradient(
        50% 100% at 50% 100%,
        rgba(58, 129, 40, 0) 0%,
        rgba(255, 255, 255, 1) 35%,
        rgba(58, 129, 40, 0) 100%,
      );
    background-size: 70% 400%;
    background-repeat: no-repeat;
    animation: loading-animation 1s linear infinite;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__line {
    background:
      radial-gradient(
        50% 100% at 50% 100%,
        rgba(58, 129, 40, 0) 0%,
        rgba(255, 255, 255, 1) 35%,
        rgba(58, 129, 40, 0) 100%,
      );
    background-size: 70% 400%;
    background-repeat: no-repeat;
    animation: loading-animation 1s linear infinite;
    margin-bottom: 20px;
    margin-top: 0;
    height: 1px;
    background-color: #8e9aa6;
    opacity: .1;
    border: none;
    width: 100%;
  }

  @keyframes loading-animation {
    0% {
      background-position: -175% 50%;
    }

    100% {
      background-position: 275% 50%;
    }
  }
}
</style>
