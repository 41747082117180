<template>
  <div class="sk-modal-header-vue">
    <div
      :style="{ backgroundColor: svgBackgroundColor }"
      class="sk-modal-icon-container"
    >
      <div
        :is="svgName"
        :fill="svgFill"
      />
    </div>
    <div class="sk-modal-header-title">
      {{ title }}
    </div>
  </div>
</template>

<script>

import AngleLeft from '../../assets/svg/AngleLeft';
import AngleRight from '../../assets/svg/AngleRight';
import BinSvg from '../../assets/svg/BinSvg';
import ClosingXSvg from '../../assets/svg/ClosingXSvg';
import ClusterNodeIconSvg from '../../assets/svg/ClusterNodeIconSvg';
import DragAreaSvg from '../../assets/svg/DragAreaSvg';
import ExternalLinkSvg from '../../assets/svg/ExternalLinkSvg';
import MoveSvg from '../../assets/svg/MoveSvg';
import PenSvg from '../../assets/svg/PenSvg';
import ShopIconSvg from '../../assets/svg/ShopIconSvg';

export default {
  components: {
    AngleLeft,
    AngleRight,
    BinSvg,
    ClosingXSvg,
    ClusterNodeIconSvg,
    DragAreaSvg,
    ExternalLinkSvg,
    MoveSvg,
    PenSvg,
    ShopIconSvg,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    svgName: {
      type: String,
      required: true,
    },
    svgFill: {
      type: String,
      default: 'black',
    },
    svgBackgroundColor: {
      type: String,
      default: '#dddddd',
    },
  },
};
</script>

<style>
.sk-modal-header-vue {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.sk-modal-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.sk-modal-header-title {
  font-size: $fs-heading-xs;
  font-weight: bold;
}
</style>
