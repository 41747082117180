export const SHOP_POSTES_COLORS = [
  '#1C159E',
  '#4E3FD4',
  '#3227F4',
  '#4094EF',
  '#6CDEF8',
  '#4050B2',
  '#757AE6',
  '#7E8EB9',
  '#93AEF9',
  '#3986C2',
  '#508FA5',
  '#ADDBE4',
  '#66B4AC',
  '#90CDB7',
  '#5BBB61',
  '#6AD670',
  '#6ADDBD',
  '#7CE0C3',
  '#99D250',
  '#F8D158',
  '#F3C243',
  '#E8A93E',
  '#F19A38',
  '#EA9662',
  '#ED622C',
  '#EE7761',
  '#DE6B59',
  '#ED6965',
  '#EB3323',
  '#9A2720',
  '#6E4966',
  '#986E97',
  '#AA9EF8',
  '#6E2792',
  '#902EAC',
  '#832FF4',
  '#D06BF7',
  '#DD4D79',
  '#DC4458',
  '#C7415D',
  '#3C0837',
  '#755649',
];

export const LICENSE_COLORS = {
  system_admin: {
    backgroundColor: '#eef4ff',
    color: '#2b66fe',
  },
  advanced_master: {
    backgroundColor: '#eef4ff',
    color: '#2b66fe',
  },
  standard_master: {
    backgroundColor: '#eef4ff',
    color: '#2b66fe',
  },
  advanced_read_only: {
    backgroundColor: '#e8faed',
    color: '#188377',
  },
  standard_read_only: {
    backgroundColor: '#e8faed',
    color: '#188377',
  },
  advanced_planner: {
    backgroundColor: '#e8faed',
    color: '#188377',
  },
  standard_planner: {
    backgroundColor: '#e8faed',
    color: '#188377',
  },
  advanced_employee: {
    backgroundColor: '#fdf0c8',
    color: '#f0900d',
  },
  standard_employee: {
    backgroundColor: '#fdf0c8',
    color: '#f0900d',
  },
};

export const SIGNATURE_SELECTOR_COLORS = [
  {
    stroke: '#0076AB',
    fill: '#EBF4F9',
  },
  {
    stroke: '#B15500',
    fill: '#FBF1E6',
  },
];
