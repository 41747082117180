<template>
  <div :class="tagClasses">
    {{ $t(`pack_offers.${type}`) }}
  </div>
</template>

<script>
import { toSnakeCase } from '@skello-utils/formatting/strings';

export default {
  name: 'PackTag',
  props: {
    type: {
      type: String,
      default: null,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagClasses() {
      return {
        'sk-pack-tag': true,
        [`sk-pack-tag--${this.normalizedType}`]: true,
        'sk-pack-tag--shadowed': this.shadow,
      };
    },
    normalizedType() { // Normalize the offer pack to match the i18n keys
      return this.type ? toSnakeCase(this.type) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-pack-tag {
  padding: 2px 8px;
  border-radius: 3px;
  font-size: $fs-text-s;
  display: inline-block;
  white-space: nowrap;
  color: white;

  &.sk-pack-tag--shadowed {
    margin: 0 .25rem;
  }

  &--basic {
    background: $sk-green-jungle;

    &.sk-pack-tag--shadowed {
      box-shadow: 0 8px 16px #b7f4ed;
    }
  }

  &--success,
  &--success_plus {
    background: $sk-blue-indigo;

    &.sk-pack-tag--shadowed {
      box-shadow: 0 8px 16px #bfc2fb;
    }
  }

  &--premium {
    background: #fdbf3f;

    &.sk-pack-tag--shadowed {
      box-shadow: 0 8px 16px #fff4dc;
    }
  }

  &--timeclock_standalone_success {
    background: $sk-blue-indigo;

    &.sk-pack-tag--shadowed {
      box-shadow: 0 8px 16px #bfc2fb;
    }
  }
}
</style>
