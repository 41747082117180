import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

const generatePosteUpdateNameEvent = (initialPoste, updatedPoste) => ({
  subtype: EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_NAME,
  payload: {
    id: initialPoste.id,
    oldName: initialPoste.attributes.name,
    newName: updatedPoste.name,
  },
});

const generatePosteUpdateColorEvent = (initialPoste, updatedPoste) => ({
  subtype: EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_COLOR,
  payload: {
    id: initialPoste.id,
    name: initialPoste.attributes.name,
    oldColor: initialPoste.attributes.color,
    newColor: updatedPoste.color,
  },
});

const generatePosteUpdatePauseTimeEvent = (initialPoste, updatedPoste) => ({
  subtype: EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_PAUSE_TIME,
  payload: {
    id: initialPoste.id,
    name: initialPoste.attributes.name,
    oldPauseTime: initialPoste.attributes.pauseTime,
    newPauseTime: updatedPoste.pause_time,
  },
});

export const generatePosteUpdateEvent = (initialPoste, updatedPoste) => {
  if (updatedPoste.name && updatedPoste.name !== initialPoste.attributes.name) {
    return generatePosteUpdateNameEvent(initialPoste, updatedPoste);
  } if (updatedPoste.color && updatedPoste.color !== initialPoste.attributes.color) {
    return generatePosteUpdateColorEvent(initialPoste, updatedPoste);
  } if (updatedPoste.pause_time && updatedPoste.pause_time !== initialPoste.attributes.pauseTime) {
    return generatePosteUpdatePauseTimeEvent(initialPoste, updatedPoste);
  }
  return undefined;
};
