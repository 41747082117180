<template>
  <div class="upsell-banner__wrapper">
    <div class="upsell-banner__icon-description">
      <CrownIcon fill="#FFAA0A" />
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="upsell-banner__description sk-text-large-regular"
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <SkOroraButton
      icon="PlusSignV2Icon"
      class="upsell-banner__button"
      variant-color="#FFAA0A"
      :disabled="false"
      :loading="isLoading"
      v-on="$listeners"
    >
      {{ buttonMessage }}
    </SkOroraButton>
  </div>
</template>
<script>
export default {
  name: 'UpsellBanner',
  props: {
    description: {
      type: String,
      required: true,
    },
    buttonMessage: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-banner {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-image: url('../../img/upsell-banner-flubber');
    background-repeat: no-repeat;
    background-color: #FFFAF0;
  }

  &__description {
    width: 100%;
  }

  &__icon-description {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
  }

  &__button {
    margin-left: auto;
  }
}
</style>

<style lang="scss">
.upsell-banner__description b {
  font-weight: $fw-semi-bold;
}
</style>
