<template>
  <div>
    <img
      src="../shared/img/blurred-dashboard.png"
      height="100%"
      width="100%"
    >
    <DashboardUpsellModal />
  </div>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import DashboardUpsellModal from './DashboardUpsellModal/index';

export default {
  name: 'CommercialDashboard',
  components: {
    DashboardUpsellModal,
  },
  mounted() {
    this.emitOnRoot(MODAL_SHOW_EVENT, event, 'dashboard-upsell-modal');
  },
};
</script>
