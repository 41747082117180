<template>
  <div class="automatic-planning__sidepanel__intro__wrapper">
    <p class="automatic-planning__sidepanel__body">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="$t('automatic_planning.sidebar.intro.main_paragraph')" />
      <SkOroraLink
        :href="$t('automatic_planning.sidebar.infos.link')"
        target="_blank"
      >
        <span>{{ $t('automatic_planning.sidebar.infos.title') }}</span>
      </SkOroraLink>.
    </p>

    <hr class="automatic-planning-intro__separation">

    <div>
      {{ $t('automatic_planning.sidebar.intro.setup.cta') }}
      <b>{{ $t('automatic_planning.sidebar.intro.setup.cta_rules') }}</b>
    </div>
    <div class="automatic-planning__rules-box">
      <div class="automatic-planning__rule">
        <div
          v-tooltip="ruleTooltip"
          :class="ruleCheckmarkClasses"
        >
          <CheckMarkIcon
            width="50%"
            height="50%"
          />
        </div>

        <div class="automatic-planning__rule__text">
          <h3 class="automatic-planning__rule__header">
            {{ $t('automatic_planning.sidebar.intro.setup.rule_title') }}
          </h3>

          <p class="automatic-planning__rule__desc">
            {{ $t('automatic_planning.sidebar.intro.setup.rule_paragraph') }}
          </p>
        </div>
      </div>

      <div class="automatic-planning__rule-employee">
        <div
          v-tooltip="competenciesTooltip"
          :class="competenciesCheckmarkClasses"
        >
          <CheckMarkIcon
            width="50%"
            height="50%"
          />
        </div>

        <div class="automatic-planning__rule__text">
          <h3 class="automatic-planning__rule__header">
            {{ $t('automatic_planning.sidebar.intro.setup.competencies_title') }}
          </h3>

          <p class="automatic-planning__rule__desc">
            {{ $t('automatic_planning.sidebar.intro.setup.competencies_paragraph') }}
          </p>
        </div>
      </div>
    </div>

    <div class="automatic-planning__rules-box__submit">
      <SkOroraButton
        :href="nextStepLink"
        @click="handleNextStep"
      >
        {{ $t('automatic_planning.sidebar.lets_go') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AutomaticPlanningIntro',
  props: {
    automaticPlanningRulesUrl: {
      type: String,
      required: true,
    },
    automaticPlanningCompetenciesUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsAutomaticPlanning', ['config', 'predictionModelReady']),
    ruleCheckmarkClasses() {
      return this.checkmarkIconClasses(this.rulesActivated);
    },
    competenciesCheckmarkClasses() {
      return this.checkmarkIconClasses(this.competenciesFilled);
    },
    nextStepLink() {
      if (!this.rulesActivated) return this.automaticPlanningRulesUrl;
      if (!this.competenciesFilled) return this.automaticPlanningCompetenciesUrl;
      return '';
    },
    rulesActivated() {
      return this.config.rules_activated;
    },
    competenciesFilled() {
      return this.config.competencies_filled;
    },
    ruleTooltip() {
      if (this.rulesActivated) return '';

      return this.$t('automatic_planning.sidebar.intro.setup.rules_missing');
    },
    competenciesTooltip() {
      if (this.competenciesFilled) return '';

      return this.$t('automatic_planning.sidebar.intro.setup.competencies_missing');
    },
    automaticPlanningReady() {
      return this.rulesActivated && this.competenciesFilled;
    },
  },
  mounted() {
    if (this.automaticPlanningReady) {
      this.nextStep();
    }
  },
  methods: {
    checkmarkIconClasses(checkmarkSetupDone) {
      return {
        'automatic-planning__rule__check': true,
        'automatic-planning__rule__check--disabled': !checkmarkSetupDone,
      };
    },
    handleNextStep() {
      this.$skAnalytics.track('automatic_planning_set_up');
      if (!this.rulesActivated || !this.competenciesFilled) return;
      this.nextStep();
    },
    nextStep() {
      this.$emit('update-step', this.predictionModelReady ? 'from-history' : 'provenance');
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__sidepanel__intro__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.automatic-planning__sidepanel__body {
  margin-top: 10px;
  margin-bottom: 0;
}

.automatic-planning-intro__separation {
  margin: 16px 0;
  border: 0;
  border-top: 1px solid #eee;
}

.automatic-planning__rules-box {
  margin-top: 4px;
}

.automatic-planning__rules-box__submit {
  display: flex;
  margin-top: auto;
  padding: 14px 0;
}

.automatic-planning__rule {
  display: flex;
  margin-top: 15px;
  padding: 15px;
  background: white;
  box-shadow: 4px 0 20px rgba(0, 0, 0, .12);
  border-radius: 4px;
}

.automatic-planning__rule-employee {
  display: flex;
  margin-top: 10px;
  padding: 15px;
  background: white;
  box-shadow: 4px 0 20px rgba(0, 0, 0, .12);
  border-radius: 4px;
}

.automatic-planning__rule__check {
  margin-right: 20px;
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#459fff, $sk-blue-50, $sk-royal-blue);
  margin-top: auto;
  margin-bottom: auto;

  // White outline for checkmark
  ::v-deep path {
    fill: white;
  }

  &--disabled {
    background: $sk-grey-10;

    // Disabled style to Roundbutton not used anywhere else in design system
    ::v-deep path {
      fill: $sk-grey-30;
    }
  }
}

.automatic-planning__rule__text {
  flex: 1 1 auto;
}

.automatic-planning__rule__desc {
  color: $sk-grey-50;
  margin-bottom: 5px;
}

.automatic-planning__rule__link-wrap {
  margin-bottom: 14px;
}

.automatic-planning__settings-link {
  color: $sk-blue;
}

.automatic-planning__rule__header {
  margin-top: 0;
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
  line-height: 17px;
  margin-bottom: 10px;
}
</style>
