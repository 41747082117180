<template>
  <div
    id="dashboard"
  >
    <div v-show="!isLoading">
      <DashboardFirstLine />
      <DashboardSecondLine />
      <DashboardThirdLine />
    </div>

    <div
      v-show="isLoading"
      class="dashboard-loader"
    >
      <SkLoader size="large" />
    </div>

    <StaffCostsUpdateModal
      :users-to-update="staffCostData"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import DashboardFirstLine from './first_line.vue';
import DashboardSecondLine from './second_line.vue';
import DashboardThirdLine from './third_line.vue';
import StaffCostsUpdateModal from './Modals/StaffCostsUpdateModal.vue';

export default {
  name: 'HomeDashboard',
  components: {
    DashboardFirstLine,
    DashboardSecondLine,
    DashboardThirdLine,
    StaffCostsUpdateModal,
  },
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (!vm.currentLicense.attributes.canEditManagementIndicators) {
        vm.redirectToHome();
        next(false);
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const hasShopChanged = String(to.params.shop_id) !== this.currentShop.id;

    // No need to fetch cluster node here,
    // cluster_nodes_id is never passed to this page
    if (hasShopChanged) {
      const { shop_id: shopId } = to.params;
      // this line is necessary to work with all and single shop
      await this.updateCurrentShop({ shopId });

      if (shopId === 'all') {
        await this.selectOrganisation(this.currentOrganisation);
      } else {
        // normally it is done in the update shop, but if we remove it,
        // the shop dropdown is not refreshed correctly
        await this.selectShop(this.currentShop);
      }
    }

    this.reloadData();

    next();
  },
  data() {
    return {
      dataLoaded: {
        AbsenteismChart: false,
        ProductivityChart: false,
        ActualityChart: false,
        TrialsEndsCard: false,
        DocsExpiringCard: false,
        TurnoverCard: false,
        DeparturesArrivalsCard: false,
        OverHoursChart: false,
        UnfinishedProfilesChart: false,
        StaffCostsCard: false,
      },
      staffCostData: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters({ isEmployee: 'currentLicense/isEmployee' }),
    ...mapState('currentLicense', ['currentLicense']),
    isLoading() {
      // If the user is not allowed to edit management indicators, we show the loader only
      if (!this.currentLicense.attributes.canEditManagementIndicators) return true;

      return false;
    },

  },
  created() {
    this.listenOnRoot('dashboard-v3-data-loaded', this.handleDataLoaded);
    this.listenOnRoot('dashboard-v3-staff-cost-update', this.handleStaffCostUpdate);
  },
  mounted() {
    this.setBrowserTabTitle();

    // Give all the flip cards the ability to flip on click
    Array.from(document.getElementById('dashboard').querySelectorAll('.dashboard-flip-cards'))
      .forEach(element => {
        element.addEventListener('click', this.handleCardClicks, true);
      });

    this.reloadData();
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapActions('navContext', [
      'selectOrganisation',
      'selectShop',
    ]),
    ...mapActions('currentShop', ['updateCurrentShop']),
    reloadData() {
      this.resetDataLoadedStates();
      this.resetAllCards();

      this.emitOnRoot('dashboard-v3-reload-data');
    },
    handleDataLoaded(componentName) {
      this.dataLoaded[componentName] = true;
    },
    resetDataLoadedStates() {
      Object.keys(this.dataLoaded).forEach(componentName => {
        this.dataLoaded[componentName] = false;
      });
    },
    handleStaffCostUpdate(data) {
      this.staffCostData = data;
    },
    handleCardClicks(event) {
      const element = event.target;

      // The elements that contain this class should not flip the card
      if (element.closest('.noflip')?.classList.contains('noflip')) return;

      // Standard case when card is facing front
      if (element.classList.contains('flip-card')) {
        element.classList.toggle('flipped');
        return;
      }

      // When card is facing back, find the parent
      const closest = element.closest('.flip-card');
      closest?.classList.toggle('flipped');
    },
    // Un-flip all flipped cards
    resetAllCards() {
      Array.from(document.getElementById('dashboard').querySelectorAll('.flip-card'))
        .forEach(element => {
          if (element.classList.contains('flipped')) {
            element.classList.toggle('flipped');
          }
        });
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.$t('home_dashboard.tab_title');
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
    redirectToHome() {
      this.$skToast({
        message: this.$t('errors.unauthorized'),
        variant: 'error',
      });
      setTimeout(() => {
        window.location = '/';
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@use './shared/css/home';

#dashboard {
  background: #f7f9fc !important;
  overflow-y: auto;
  height: 100%;
  font-size: .9em;

  a:hover {
    text-decoration: none;
  }

  .full-width {
    width: 100%;
  }

  #notifications-wrapper {
    height: 380px;
    border-top: 2px solid $sk-error;
  }

  #notifications-wrapper .dashboard-content,
  #unfinished-profiles .dashboard-content,
  #over-hours-plannings .dashboard-content {
    position: absolute;
    inset: 50px 0 0;
    padding-top: 0;
    overflow: hidden scroll;
  }

  #unfinished-profiles .dashboard-content,
  #over-hours-plannings .dashboard-content {
    padding: 0;
  }

  $square-margin: 10px;

  .dashboard-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dashboard-section {
    background-color: white;
    margin: 20px;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 2px rgb(0 0 0 / .1);
    min-height: 300px;

    &.square-1 {
      margin-bottom: $square-margin;
      margin-right: $square-margin;
    }

    &.square-2 {
      margin-bottom: $square-margin;
      margin-left: $square-margin;
    }

    &.square-3 {
      margin-right: $square-margin;
    }

    &.square-4 {
      margin-left: $square-margin;
    }

    &.bottom {
      height: 320px;
      margin-bottom: 20px;
    }

    &.small-margin {
      margin: 5px;
    }

    .dashboard-content {
      padding: 10px;
    }

    .dashboard-title {
      margin: 0;
      padding: 1em;
      font-size: 1.5em;
      border-bottom: 1px solid #ebebef;
      height: 50px;
      border-radius: 5px 5px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h2,
      h3,
      h4 {
        margin-top: 0;
        margin-bottom: 0;
      }

      h2 {
        font-size: .8em;
        font-weight: $fw-semi-bold;
        letter-spacing: .015em;
        color: $sk-black;
      }

      h3 {
        font-size: 1em;
      }
    }
  }

  .dashboard-graph-button {
    font-size: .5em !important;
    font-weight: normal;
    color: $sk-black;
    border: 1px solid $sk-black;
    padding: .5em;
    border-radius: 3px;
    background-color: rgb(250 250 250);
    max-width: 50%;
  }

  #quick-graphs,
  #prod-quick-graphs {
    .current-months {
      margin-left: 2em;
      margin-top: 8px;
      margin-bottom: 8px;
      color: $sk-grey-50;
      font-weight: $fw-semi-bold;
    }
  }

  #quick-graphs {
    border-top: 2px solid rgb(31 190 213 / .5);
  }

  #prod-quick-graphs {
    border-top: 2px solid rgb(71 214 89 / .5);
  }

  .unfinished-profiles-wrapper {
    width: 50%;
    position: relative;
    max-width: 200px;

    #unfinished-profiles-percentage {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
      font-weight: lighter;
    }
  }

  @media (width >= 992px) {
    .unfinished-profiles-wrapper {
      max-width: none;
    }
  }

  .show-notif-url {
    color: $sk-grey-10;
  }

  .unread .show-notif-url {
    color: $sk-royal-blue;
  }

  .notifications-list {
    list-style: none;
    padding-left: 0;

    .notification-item {
      padding-left: 0.5em;
      border-bottom: 1px solid $sk-grey-10;

      &.unread {
        border-left: 2px solid $sk-royal-blue;
      }

      &:hover {
        background-color: $sk-grey-10;
      }
    }

    .notification-link {
      display: flex;
      flex-direction: column;
      padding: 1em 0;
      color: $sk-black;
    }

    .notification-timestamp {
      padding-right: 0.5em;
      text-align: right;
    }
  }

  .user-monogram {
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $sk-royal-blue;
    border: 1px solid $sk-royal-blue;
    border-radius: 50%;
    font-size: .9em;

    &.sidebar {
      background-color: #999;
      color: white;
      font-weight: 300;
      border: 0;
    }

    &.large {
      height: 50px;
      width: 50px;
      font-size: 1.5em;
    }
  }

  .trials-list {
    list-style: none;
    padding-left: 0;

    .trial-item {
      border-bottom: 1px solid #f4f4f4;
    }

    .trial-link {
      display: flex;
      align-items: center;
      padding: .5em 0;
      color: $sk-black;

      &:hover {
        color: $sk-black;
        background-color: $sk-grey-10;
      }
    }

    .monogram-wrapper {
      padding-left: .5em;
      padding-right: 1em;
      display: flex;
      justify-content: center;

      .user-monogram {
        width: 40px;
        height: 40px;
        font-size: 16px;
      }
    }

    .trial-user-name {
      font-weight: $fw-semi-bold;
      font-size: 1em;

      &.doc {
        color: $sk-royal-blue;
      }
    }

    .trial-date {
      font-size: .8em;
      text-transform: uppercase;
      color: $sk-grey-50;
    }

    .trial-eye {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      margin: 0 12px;
    }
  }

  .unfinished-profiles-text {
    width: 50%;

    .unfinished-profiles-number {
      font-weight: $fw-semi-bold;
      padding-bottom: 1em;
      font-size: 1.1em;
    }

    .unfinished-profiles-explanation {
      color: $sk-black;
      font-weight: lighter;
    }
  }

  .unfinished-profile-name {
    margin-left: 10%;
    color: $sk-black;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .unfinished-profiles-done {
    font-weight: lighter;
  }

  .unfinished-profile-item {
    height: 25%;
    padding: 0 20px;
    border-radius: 0 0 5px 5px;
    background-color: $sk-white;
  }

  .unfinished-profile-content-graph {
    padding: 0 20px;
    height: 75%;
  }

  .unfinished-profile-complete {
    color: $sk-black;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .over-hours-planning-item {
    .over-hours-planning-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em;
      border-bottom: 1px solid $sk-grey-10;
      color: inherit;

      &:hover {
        background-color: $sk-grey-10;
      }
    }

    .over-hours-badge {
      height: 10px;
      width: 10px;
      background-color: rgb(255 0 0 / .5);
      border-radius: 50%;
      margin-right: 10px;
    }

    .over-hours-planning-name {
      font-weight: $fw-semi-bold;
      font-size: 1.2em;
    }

    .over-hours-planning-hours {
      text-align: right;
      letter-spacing: .02em;

      .bold-hours {
        font-weight: $fw-semi-bold;
      }
    }

    .over-hours-planning-users {
      text-align: right;
      font-size: .8em;
      font-weight: lighter;
    }
  }

  .simple-box {
    display: flex;
    margin: 20px;

    .part {
      &:first-child {
        margin: 0 5px 0 0;
      }

      &:last-child {
        margin: 0 0 0 5px;
      }
    }
  }

  .archived .pastille {
    background-color: #f00;
  }

  .arrived .pastille {
    background-color: #0f0;
  }

  .ar-link {
    padding: 1em;
    padding-left: 1.5em;
    display: block;
  }

  .ar-link span {
    display: block;
  }

  .ar-link {
    color: $sk-grey-50;

    &.arrived {
      border-left: 4px solid #47b6ac;
    }

    &.archived {
      border-left: 4px solid #ff5606;
    }
  }

  .ar-link:hover {
    background-color: #eee;
  }

  .ar-link .name {
    font-weight: $fw-semi-bold;
  }

  .ar-link .timezone {
    text-align: right;
  }

  .pastille {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .part {
    background-color: white;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 2px rgb(0 0 0 / .1);
  }

  .dash-graph,
  .dash-graph-prod {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #prod-prev-day-arrow,
  #prod-next-day-arrow,
  #next-day-arrow,
  #prev-day-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
    align-items: center;
    width: 50px;

    .arrow {
      background-color: black;
      color: #eee;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
    }
  }

  #next-day-arrow,
  #prod-next-day-arrow {
    right: 0;

    .arrow {
      border-radius: 50% 0 0 50%;
    }
  }

  #prev-day-arrow,
  #prod-prev-day-arrow {
    left: 0;

    .arrow {
      border-radius: 0 50% 50% 0;
    }
  }

  .dashboard-content #prod-prev-day-arrow,
  .dashboard-content #prod-next-day-arrow,
  .dashboard-content #next-day-arrow,
  .dashboard-content #prev-day-arrow {
    opacity: .5;
    display: flex;

    .arrow:hover {
      cursor: pointer;
    }
  }

  .gl-tover {
    font-size: 13px;
    cursor: default;
  }

  .gl-amount {
    font-size: 13px;
    cursor: default;
    text-align: right;
  }

  .mid-box {
    width: 100%;
    height: 270px;
    display: flex;
  }

  .m-mid-box {
    display: flex;
    flex-grow: 1;
    max-width: 50%;
    flex-direction: column;
  }

  .d-limited-content {
    color: $sk-grey-30;
    height: 220px;
    overflow: auto;
  }

  .d-no-result {
    text-align: center;
    margin: 10px;
  }

  .dashboard-flip-cards {
    perspective: 800;
    -webkit-perspective: 800;
    height: 320px;
    position: relative;

    a {
      text-decoration: none;
    }

    .dashboard-title {
      font-size: 1.3em;
    }

    .flip-card {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: .5s;

      &.flipped {
        transform: rotateY(-180deg);
      }

      .face {
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: hidden;
        z-index: 2;
        border-radius: 5px;
      }

      .front {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        color: $sk-white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .back {
        transform: rotateY(-180deg);
        background-color: white;
      }

      .number {
        font-size: 4em;
        font-weight: lighter;
      }

      .subtitle {
        font-weight: $fw-semi-bold;
        font-style: normal;
        font-size: 1em;
        text-align: center;
        padding: 0 20px;
      }
    }

    .dashboard-content {
      padding-top: 0;
    }

    &#staff-costs-flip-cards {
      .front {
        background-color: #7628bf;
        font-size: 1.8em;
      }
    }
  }

  .doc-expiring-front {
    background-color: #00bed3;
  }

  .turnover-front {
    background-color: #47b6ac;
  }

  .arrived-and-gone-front {
    background-color: #a0dde6;
  }

  .trials-front {
    background-color: #3e4eb8;
  }

  .global-turnover-data {
    font-size: .7em;
    margin-left: auto;
    font-weight: $fw-semi-bold;
    color: $sk-black;
  }

  .turnover-link {
    display: flex;
    align-items: center;
    justify-content: space-around !important;
    color: #555;
    font-size: .8em;
    margin: 0;
    width: 100%;
    padding: 0;
  }

  .turnover-badge {
    padding: .5em;
    background-color: #2dbcd3;
    color: white;
    border-radius: 15px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .turnover-data {
    margin-left: auto;
    font-size: 1.25em;
    font-weight: $fw-semi-bold;
  }

  .trial-item.turnover {
    padding: 1em;
    display: flex;
  }

  .staff-ratio-box {
    font-size: 3em;
    font-weight: lighter;
    color: $sk-royal-blue;
    border-radius: 4px;
  }

  .tiny-submit {
    width: 3.5em !important;
    padding: .5em;
  }

  .quarter .flex-m6 {
    width: 25% !important;
  }

  .prod-no-datas {
    position: absolute;
    padding-left: 50px;
    padding-right: 50px;
    inset: 50px 0 0;
    display: flex;
    place-content: center center;
    flex-direction: column;
    text-align: center;
    background-color: rgb(0 0 0 / .2);
    font-size: 12px;
    font-weight: $fw-semi-bold;
  }

  #turnover-btn {
    margin-left: 10px;
    display: flex;
  }

  #turnover-btn button {
    margin-left: 5px;
  }

  .dt-rpart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .dtitle-spread {
    justify-content: space-between !important;
  }

  .labor-cost-inside-form {
    .input-daterange {
      width: auto;

      input {
        width: 100px;
        font-size: .8em;
      }

      .input-group-addon {
        text-align: center;
        width: 35px;
      }
    }

    .labor-cost-shop {
      margin-left: .5em;
    }
  }

  #staff-costs-flip-cards {
    height: 270px;
  }

  .staff-cost-input {
    border: $sk-royal-blue;
    border-radius: 3px;
    font-weight: normal;
    color: $sk-black;
    font-size: 13px;
  }

  .staff-costs-label {
    font-style: .8em;
    color: #666;
  }

  .staff-costs-revenue-input {
    margin: 1em 0;
  }

  .staff-costs-std-input {
    height: 42px;
  }

  .staff-costs-general-width {
    width: 195px;
    max-width: none !important;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
}
</style>
