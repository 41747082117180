<template>
  <div
    :class="classes"
    :style="style"
  >
    <span class="signature-selector--text">{{ signature.signer.name }}</span>
    <div @click="close">
      <ClosingXIcon
        v-show="signature.placed"
        class="signature-selector--icon"
        :fill="boxStyle.stroke"
      />
    </div>
  </div>
</template>

<script>

import { ClosingXIcon } from '@skelloapp/skello-ui';

export default {
  name: 'SignatureSelector',
  components: {
    ClosingXIcon,
  },
  props: {
    signature: {
      type: Object,
      required: true,
    },
    mouseX: {
      type: Number,
      required: true,
    },
    mouseY: {
      type: Number,
      required: true,
    },
    boxStyle: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 99,
    },
    height: {
      type: Number,
      required: false,
      default: 39,
    },
  },
  data() {
    return {
      offset: 5,
    };
  },
  computed: {
    classes() {
      return {
        'signature-selector': true,
        'signature-selector--nonplaced': !this.signature.placed,
        'signature-selector--placed': this.signature.placed,
      };
    },
    style() {
      return {
        left: `${this.left}px`,
        top: `${this.top}px`,
        borderColor: this.boxStyle.stroke,
        color: this.boxStyle.stroke,
        background: this.boxStyle.fill,
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    x() {
      if (this.signature.placed) return this.signature.x;
      return this.mouseX + this.offset;
    },
    y() {
      if (this.signature.placed) return this.signature.y;
      return this.mouseY + this.offset;
    },
    left() {
      if (this.isOffCanvasHorizontally) return this.x - this.width - this.offset;
      return this.x;
    },
    isOffCanvasHorizontally() {
      return this.x + this.width > this.$parent.$refs.pdfCanvas.offsetWidth;
    },
    top() {
      if (this.isOffCanvasVertically) return this.y - this.height - this.offset;
      return this.y;
    },
    isOffCanvasVertically() {
      return this.y + this.height > this.$parent.$refs.pdfCanvas.offsetHeight;
    },
  },
  methods: {
    close() {
      this.$emit('close', this.signature);
    },
  },
};
</script>

<style>
.signature-selector {
  padding: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 3.28px;
  border: 1px;
  border-style: dashed;
  user-select: none;
}

.signature-selector--nonplaced {
  justify-content: center;
  opacity: .5;
}

.signature-selector--placed {
  justify-content: space-around;
  cursor: default;
  opacity: 1;
}

.signature-selector--icon {
  cursor: pointer;
  margin-left: 5px;
  display: block;
}

.signature-selector--text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
