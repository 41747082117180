<template>
  <div class="badgings-days__header">
    <SkTab>
      <router-link :to="badgingShiftsDaysUrl">
        <SkTabItem>
          {{ $t('badgings.days.tabs.shifts.title') }} ({{ nbMatchedBadgings.length }})
        </SkTabItem>
      </router-link>
      <router-link :to="badgingAbsencesDaysUrl">
        <SkTabItem>
          {{ $t('badgings.days.tabs.absences.title') }} ({{ nbAbsences.length }})
        </SkTabItem>
      </router-link>
    </SkTab>
  </div>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

export default {
  name: 'Days',
  computed: {
    ...mapState('badgings', ['currentDate']),
    ...mapGetters('badgings', [
      'isEmployeesView',
      'matchedBadgingsPerUser',
      'dayAbsencesPerUser',
      'matchedBadgingsPerDay',
      'dayAbsencesPerDay',
    ]),
    nbMatchedBadgings() {
      return this.isEmployeesView ?
        this.matchedBadgingsPerUser() :
        this.matchedBadgingsPerDay();
    },
    nbAbsences() {
      return this.isEmployeesView ? this.dayAbsencesPerUser : this.dayAbsencesPerDay();
    },
    badgingShiftsDaysUrl() {
      const query = {
        date: this.currentDate,
      };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids || [];
      }

      return {
        name: this.isEmployeesView ? 'badgings_shifts_users' : 'badgings_shifts_days',
        query,
      };
    },
    badgingAbsencesDaysUrl() {
      const query = {
        date: this.currentDate,
      };

      if (this.$route.query.team_ids) {
        query.team_ids = this.$route.query.team_ids;
      }

      return {
        name: this.isEmployeesView ? 'badgings_absences_users' : 'badgings_absences_days',
        query,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.badgings-days__header {
  margin: 24px 0;
}
</style>
