<template>
  <!-- eslint-disable  max-len -->
  <div class="absences__regular__tables__wrapper">
    <RegularAbsencesSection
      :absences="absencesByAbsenceType('indemnified_by_employer')"
      :title="$t('shop_settings.tabs.absences.no_modulation.section_title.indemnified_by_employer')"
      first-section
    />
    <RegularAbsencesSection
      :absences="absencesByAbsenceType('indemnified_by_other')"
      :title="$t('shop_settings.tabs.absences.no_modulation.section_title.indemnified_by_other')"
      middle-section
    />
    <RegularAbsencesSection
      :absences="absencesByAbsenceType('not_indemnified')"
      :title="$t('shop_settings.tabs.absences.no_modulation.section_title.not_indemnified')"
      middle-section
    />
    <RegularAbsencesSection
      :absences="absencesByAbsenceType('neutral')"
      :title="$t('shop_settings.tabs.absences.no_modulation.section_title.neutral_leaves')"
      last-section
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RegularAbsencesSection from './Section';

export default {
  components: { RegularAbsencesSection },
  computed: {
    ...mapState('absences', ['shopAbsences']),
  },
  methods: {
    absencesByAbsenceType(absenceType) {
      return this.shopAbsences.filter(absence => absence.attributes.absenceType === absenceType);
    },
  },
};
</script>

<style lang="scss" scoped>
.absences__regular__tables__wrapper {
  margin: 30px 0;
  background: $sk-white;
  align-self: flex-start;
}
</style>
