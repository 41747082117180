import skDate from '@skello-utils/dates';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import {
  setOfObjectValues,
  nullifyZeros,
} from './workload_plan_excel_helper';

/**
 * Find the opening day from a dateTime and opening / closing time
 * Mostly useful to get the information from cross-day openings.
 * Only works for date that really are in opening / closing period
 *
 * Example : shop opened from 22:00 to 02:00
 * openingDayForDate("2023-09-12T23:00:00", "22:00", "02:00") => "2023-09-12"
 * openingDayForDate("2023-09-12T01:00:00", "22:00", "02:00") => "2023-09-11"
 *
 * date : date to find the opening day from eg : '2023-09-12T11:00:00'
 * openingTime : opening time of the shop. eg : '12:00'
 * closingTime : closing time of the shop. eg : '18:00'
 *
 * @returns date
 */
const openingDayForDate = (
  date,
  openingTime,
  closingTime,
) => {
  const openingAndClosingThatDay = openingAndClosingTimeAt(
    openingTime,
    closingTime,
    skDate.utc(date).format(),
  );

  if (
    skDate.utc(date).isBetween(
      openingAndClosingThatDay.openingTime,
      openingAndClosingThatDay.closingTime,
      undefined,
      '[)',
    )
  ) {
    return skDate.utc(date).format('YYYY-MM-DD');
  }

  // If date is not in the opening that day, that means that it on the precedent day.
  return skDate.utc(date).subtract(1, 'day').format('YYYY-MM-DD');
};

/**
 * Re-group the workload plans of each poste by opening day
 * {
 *   posteId: [ workloadPlan1, workloadPlan2, ... ],
 * }
 * To
 * {
 *   posteId: {
 *     day1: [workloadPlan1, workloadPlan2],
 *     ...
 *   }
 * }
 *
 * openingTime: "HH:mm"
 * closingTime: "HH:mm"
 */
const groupByDate = (
  workloadPlansByPoste,
  openingTime,
  closingTime,
) => Object.keys(workloadPlansByPoste).reduce((acc, posteId) => {
  acc[posteId] = {};

  workloadPlansByPoste[posteId]
    .sort((a, b) => skDate.utc(a.startsAt).valueOf() - skDate.utc(b.startsAt).valueOf())
    .forEach(workloadPlan => {
      const openingDay = openingDayForDate(workloadPlan.startsAt, openingTime, closingTime);

      if (!acc[posteId][openingDay]) acc[posteId][openingDay] = [];

      acc[posteId][openingDay].push(workloadPlan);
    });

  return acc;
}, {});

/**
 * Compare both workload plans list and return if they are equals or not
 */
const areWorkloadPlansEqual = (workloadPlans1, workloadPlans2) => {
  // Since the workload plans are both on the same format: all the hour quarters are present and in the same order,
  // we can compare them as string
  const stringifiedWorkloadPlans1 = JSON.stringify(
    workloadPlans1.map(nullifyZeros),
  );
  const stringifiedWorkloadPlans2 = JSON.stringify(
    workloadPlans2.map(nullifyZeros),
  );

  return stringifiedWorkloadPlans1 === stringifiedWorkloadPlans2;
};

/**
 * Return true if the workload plan list has only null values
 */
const workloadPlansHasOnlyNullValues = workloadPlans => {
  // Use of Set to know if all the workload plans values are the same or not
  const workloadPlanUniqValues = setOfObjectValues(
    workloadPlans.map(nullifyZeros),
    'value',
  );

  // Only one value and is null
  return workloadPlanUniqValues.size === 1 && workloadPlanUniqValues.has(null);
};

/**
 * param fetchedWorkloadPlans : workload plan store fetched from svc
 * param loadedWorkloadPlans : workload plan store loaded from file
 * param openingTime : 'HH:mm'
 * param closingTime : 'HH:mm'
 *
 * returns {
 *   workloadPlansToSave: basic workload plan store format like fetchedWorkloadPlans or loadedWorkloadPlans
 *   conflicts: workload plan store format but grouped by dates :
 *   {
 *     posteId: {
 *       '2023-09-03': WorkloadPlan[],
 *       '2023-09-04': WorkloadPlan[],
 *       ...
 *     }
 *   }
 */
export const manageConflicts = (
  fetchedWorkloadPlans,
  loadedWorkloadPlans,
  openingTime,
  closingTime,
) => {
  const conflicts = {};
  const workloadPlansToSave = {};

  // Regroup the workload plans by date to be able to make faster comparison
  const fetchedWorkloadPlansByDate = groupByDate(
    fetchedWorkloadPlans,
    openingTime,
    closingTime,
  );
  const loadedWorkloadPlansByDate = groupByDate(
    loadedWorkloadPlans,
    openingTime,
    closingTime,
  );

  Object.keys(loadedWorkloadPlansByDate).forEach(poste => {
    Object.keys(loadedWorkloadPlansByDate[poste]).forEach(day => {
      // No difference => no conflicts, nothing to save
      if (
        areWorkloadPlansEqual(
          fetchedWorkloadPlansByDate[poste][day],
          loadedWorkloadPlansByDate[poste][day])
      ) {
        return;
      }

      // Difference between both lists but all the workload plans from the file are with null values
      // => no conflict, nothing to save
      if (workloadPlansHasOnlyNullValues(loadedWorkloadPlansByDate[poste][day])) {
        return;
      }

      // Difference between both lists but fetched is null => Add it to workload plans to save
      if (workloadPlansHasOnlyNullValues(fetchedWorkloadPlansByDate[poste][day])) {
        if (!workloadPlansToSave[poste]) workloadPlansToSave[poste] = [];

        // Saving all the day (may be improved)
        workloadPlansToSave[poste].push(...loadedWorkloadPlansByDate[poste][day]);
        return;
      }

      // If there is difference but was not a previous case, it means we are in a case of conflict
      if (!conflicts[poste]) conflicts[poste] = {};
      if (!conflicts[poste][day]) conflicts[poste][day] = [];

      // Conflict for all the day (may be improved)
      conflicts[poste][day].push(...loadedWorkloadPlansByDate[poste][day]);
    });
  });

  return Promise.resolve({ workloadPlansToSave, conflicts });
};
