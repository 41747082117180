<template>
  <Popper
    :id="id"
    ref="manageBadgesStep"
    :options="{
      modifiers: {
        preventOverflow: { enabled: true },
        applyStyle: { enabled: false },
      },
    }"
    :trigger="'click'"
    :force-show="true"
  >
    <div class="sk-popover">
      <SkOroraHeader
        :title="$t('badgings.timeclock_demo.modals.manage_badges.title')"
        :subtitle="$t('badgings.timeclock_demo.modals.manage_badges.subtitle')"
        :is-closable="true"
        @close="handleCancel"
      />
      <div
        ref="contentWrapper"
        class="sk-popover-content"
      >
        <div class="sk-popover-content-placeholder">
          <video
            ref="manage-badges-video"
            controls
            controlsList="nodownload"
            style="width: 100%; height: 100%;"
          >
            <source
              :src="videoUrl"
              type="video/mp4"
            >
          </video>
        </div>
      </div>
      <div class="sk-popover-footer">
        <SkOroraFooter
          :cancel-button-label="$t('badgings.timeclock_demo.modals.labels.cancel')"
          :submit-button-label="$t('badgings.timeclock_demo.modals.labels.done')"
          :is-submit-disabled="false"
          @submit="handleSubmit"
          @cancel="handleCancel"
        />
      </div>
    </div>
    <div slot="reference">
      <slot name="anchor" />
    </div>
  </Popper>
</template>

<script>
import Popper from 'vue-popperjs';
import { awsBucketPublicAssets } from '@config/env';
import {
  SkOroraFooter,
  SkOroraHeader,
} from '@skelloapp/skello-ui';
import { mapMutations } from 'vuex';

export default {
  name: 'ManageBadges',
  components: {
    Popper,
    SkOroraHeader,
    SkOroraFooter,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoUrl: this.getVideoUrl(),
    };
  },
  watch: {
    '$i18n.locale': 'updateLocale',
  },
  methods: {
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    handleCancel() {
      this.stopVideo();
      this.$emit('cancel', this.id);
    },
    handleSubmit() {
      this.stopVideo();
      this.$emit('submit', this.id);
      this.toggleChecklistCollapsed();
    },
    stopVideo() {
      this.$refs['manage-badges-video'].pause();
    },
    getVideoUrl(locale = this.$i18n.locale) {
      return `${awsBucketPublicAssets}/media/ManageBadgesVideo_${locale.toUpperCase()}.mp4`;
    },
    reloadVideo() {
      const video = this.$refs['manage-badges-video'];
      if (video) {
        video.load();
      }
    },
    updateLocale(newLocale) {
      this.videoUrl = this.getVideoUrl(newLocale);
      this.reloadVideo();
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-popover {
  z-index: $z-index-popover;
  position: absolute;
  background: white;
  color: $sk-black;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 624px;
  max-width: min(calc(100% - 40px));
  max-height: min(calc(100% - 40px));
  white-space: normal;
  word-break: break-all;
  font-size: $fs-text-m;
  bottom: 20px;
  right: 20px;

  .sk-popover__close-icon {
    cursor: pointer;
    z-index: $z-index-popover;
  }

  &-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .sk-popover-content-placeholder {
      position: relative;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-footer {
    flex-shrink: 0;
  }
}
</style>
