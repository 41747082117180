<template>
  <tr class="shop-postes__table__row">
    <td class="shop-postes__table__cell-color">
      <ColorPicker v-model="newShopPoste.color" />
    </td>
    <td class="shop-postes__table__cell-poste_name">
      <SkDropdown
        ref="suggestionsDropdown"
        placement="bottom"
        trigger="click"
      >
        <template #anchor>
          <SkInput
            v-model="newShopPoste.name"
            :label="$t('shop_settings.tabs.shop_postes.table.fields.name.label')"
            :errored="erroredName"
            :error-message="$t('shop_settings.tabs.shop_postes.table.fields.name.error_message')"
            @focus="handleFocus"
          />
        </template>
        <template #menu>
          <div class="organisation-postes-suggestions__menu">
            <div
              v-for="posteName in postesSuggestions"
              :key="posteName"
              class="organisation-postes-suggestions__row"
              @click="selectPosteName(posteName)"
            >
              {{ posteName }}
            </div>
          </div>
        </template>
      </SkDropdown>
    </td>
    <td
      v-if="pauseTimeInPercentage"
      class="shop-postes__table__cell-percentage"
    >
      <SkSwitch v-model="newShopPoste.percentage_pause_time" />
    </td>
    <td class="shop-postes__table__cell-pause-time">
      <SkInputGroup
        :errored="erroredPauseTimeUpdate || erroredPercentagePauseTimeUpdate"
        :error-message="errorPauseTimeMessage"
      >
        <SkInput
          v-model="newShopPoste.pause_time"
          type="number"
        />
        <template #append>
          <span v-if="newShopPoste.percentage_pause_time">
            %
          </span>
          <span v-else>
            {{ $t('dates.minutes') }}
          </span>
        </template>
      </SkInputGroup>
    </td>
    <td class="shop-postes__table__cell-action-button">
      <SkOroraButton
        :loading="loadingCreate"
        @click="handleCreate"
      >
        {{ $t('shop_settings.tabs.shop_postes.table.actions.create.title') }}
      </SkOroraButton>
    </td>
  </tr>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { SHOP_POSTES_COLORS } from '@app-js/shared/constants/colors';

import ColorPicker from './ColorPicker';

export default {
  name: 'NewPosteRow',
  components: { ColorPicker },
  data() {
    return {
      newShopPoste: {
        name: null,
        color: SHOP_POSTES_COLORS[0],
        percentage_pause_time: false,
        pause_time: 0,
      },
      erroredName: false,
    };
  },
  computed: {
    ...mapState('shopPostes', ['loadingCreate', 'postesSuggestions']),
    ...mapState('convention', ['convention']),
    ...mapState('config', ['config']),
    ...mapState('navContext', ['navContext']),
    pauseTimeInPercentage() {
      return this.config.convention_percentage_pause_time.includes(
        this.convention.attributes.name,
      );
    },
    erroredPauseTimeUpdate() {
      return this.newShopPoste.pause_time === '' || Number(this.newShopPoste.pauseTime) < 0;
    },
    erroredPercentagePauseTimeUpdate() {
      if (!this.newShopPoste.percentage_pause_time) return false;

      return (
        Number(this.newShopPoste.pause_time) < 0 ||
        Number(this.newShopPoste.pause_time) > 100
      );
    },
    erroredForm() {
      return (
        this.erroredPauseTimeUpdate ||
        this.erroredName ||
        this.erroredPercentagePauseTimeUpdate
      );
    },
    errorPauseTimeMessage() {
      if (this.erroredPercentagePauseTimeUpdate) {
        return (
          this.$t('shop_settings.tabs.shop_postes.table.fields.percentage_pause_time.error_message')
        );
      }
      if (Number(this.newShopPoste.pauseTime) < 0) {
        return this.$t('shop_settings.tabs.shop_postes.table.fields.pause_time.error_message_2');
      }

      return this.$t('shop_settings.tabs.shop_postes.table.fields.pause_time.error_message');
    },
  },
  methods: {
    ...mapActions('shopPostes', ['fetchPostesSuggestions']),
    selectPosteName(posteName) {
      this.newShopPoste.name = posteName;
      this.$refs.suggestionsDropdown.$refs.popper.doClose();
    },
    handleFocus() {
      this.erroredName = false;
      this.fetchPostesSuggestions({ shopId: this.navContext.shopId });
    },
    handleCreate() {
      if (!this.newShopPoste.name) this.erroredName = true;

      if (this.erroredForm) return;

      this.$emit('create-shop-poste', this.newShopPoste);
    },
    resetValues() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss">
.organisation-postes-suggestions__spinner {
  width: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.organisation-postes-suggestions__menu {
  background: white;
  width: 260px;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
}

.organisation-postes-suggestions__row {
  height: 35px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $sk-grey-10;
  }
}
</style>
