<script>
import { mapState } from 'vuex';
import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'OptimizationAction',
  components: {},
  extends: GenericToolbarAction,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.optimization.label');
    },
    icon() {
      return 'OptimizationIcon';
    },
    isOptimizationAllowed() {
      return this.currentOrganisation.attributes.packOffer.opti_enabled;
    },
    packOfferNameTranslated() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
    isPackOfferPremium() {
      return this.currentOrganisation.attributes.packOffer.name === 'premium';
    },
    optimizationVisibleIn() {
      return this.isPackOfferPremium ? 'dropdown' : 'toolbar-m-dropdown-s';
    },
    optimizationTooltip() {
      if (this.isOptimizationAllowed) return '';

      return this.$t('plannings.toolbar.actions_bar.icons_labels.optimization.tooltip', {
        pack_name_translated: this.packOfferNameTranslated,
      });
    },
    visibleIn() {
      return this.optimizationVisibleIn;
    },
    disabled() {
      return this.isDataLoading || !this.isOptimizationAllowed;
    },
    tooltip() {
      return this.optimizationTooltip;
    },
    isVisible() {
      return this.currentLicense.attributes.canCreateShifts;
    },
  },
  methods: {
    handler() {
      this.emitOnRoot('open-optimisation-sidepanel');
    },
  },
};
</script>
