<template>
  <PaidLeavesCounterInitializationModalContentWrapper
    id="pto-periods-initialization-modal-content-wrapper"
    ref="ptoPeriodsInitializationModalContentWrapper"
    :modal-title="$t('employees.tabs.counters.paid_leaves_counter.modal.title')"
    :handle-submit="handleSubmit"
    :handle-cancel="handleCancel"
    :balance-activated="balanceActivated"
    :init-date="initDate"
    :submit-loading="submitLoading"
    :are-balances-errored="errorPreviousBalance || errorCurrentBalance"
    @update-balance-activated="updateBalanceActivated"
    @update-init-date="updateInitDate"
  >
    <template #balance>
      <SkInfoCard class="shop-settings-modal-paid-leaves-section__balance__card">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="$t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.explanation_card')" />
      </SkInfoCard>
      <div class="shop-settings-modal-paid-leaves-section__balance__header">
        <span> {{ $t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.previous_history') }} </span>
        <span> {{ $t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.current_history') }} </span>
      </div>
      <UserRow
        :key="employee.id"
        :user="employee"
        :unit="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.days')"
        @keyup="handleErroredBalance"
      />
    </template>
  </PaidLeavesCounterInitializationModalContentWrapper>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import PaidLeavesCounterInitializationModalContentWrapper from '@app-js/shared/components/CountersInitializationModal/PaidLeavesCounterInitializationModalContentWrapper';
import UserRow from '@app-js/shared/components/CountersInitializationModal/PtoPeriodsDistinctionInitializationModal/UserRow.vue';

export default {
  name: 'PtoPeriodsInitializationModal',
  components: { PaidLeavesCounterInitializationModalContentWrapper, UserRow },
  data() {
    return {
      balanceActivated: false,
      initDate: null,
      users: [],
      submitLoading: false,
      errorPreviousBalance: true,
      errorCurrentBalance: true,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('selectedEmployee', ['initials']),

    userInitialPlc() {
      return {
        [this.employee.id]: [this.employee.previousYearBalance, this.employee.currentYearBalance],
      };
    },
    initModalRef() {
      return this.$refs.ptoPeriodsInitializationModalContentWrapper
        .$refs.paidLeavesCounterInitializationModal;
    },
  },
  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    ...mapActions('employeePaidLeavesCounter', ['fetchEmployeePaidLeavesCounter', 'fetchEmployeePeriodSummaries']),

    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    handleCancel(event) {
      this.initModalRef.hide();
      this.resetData();
      this.$emit('cancel', event);
    },
    handleErroredBalance(event, user) {
      const isErrored = field => typeof field !== 'number';
      this.errorPreviousBalance = isErrored(user.previousYearBalance);
      this.errorCurrentBalance = isErrored(user.currentYearBalance);
    },
    handleSubmit() {
      const params = {
        paid_leave_counter: {
          start_date: this.initDate,
          users_initial_plc: this.userInitialPlc,
          balance_not_inherited: !this.balanceActivated,
          shop_id: this.currentShop.id,
        },
      };

      this.submitLoading = true;

      httpClient
        .patch('/v3/api/paid_leaves_counters', params)
        .then(response => {
          this.$skToast({
            message: this.$t('employees.tabs.counters.paid_leaves_counter.modal.toast.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
          this.setEmployeeAttributes({ plcInitializationDoneAt: this.initDate });
          this.fetchEmployeePaidLeavesCounter({
            user_id: this.employee.id,
            silentLoading: false,
          });
          this.fetchEmployeePeriodSummaries({ user_id: this.employee.id });
          this.resetData();
          this.initModalRef.hide();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.tabs.counters.paid_leaves_counter.modal.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    updateBalanceActivated(newValue) {
      this.balanceActivated = newValue;
    },
    updateInitDate(newValue) {
      this.initDate = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-modal-paid-leaves-section {
  &__balance__card {
    margin: 16px 0;
  }

  &__balance__header {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    width: 230px;
    padding-bottom: 4px;
    font-weight: $fw-semi-bold;
  }
}
</style>
