<template>
  <div class="opti-hours__container">
    <div class="opti-hours__left-spacer" />
    <div
      v-for="index in rowsCount"
      :key="`opti-hours-${index}`"
      class="opti-hours__cell"
    >
      <span class="opti-hours__cell-day">
        {{ displayedHour(index - 1) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatesRow',
  components: {},
  props: {
    rowsCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    displayedHour(index) {
      return index < 10 ? `0${index}h` : `${index}h`;
    },
  },
};
</script>

<style lang="scss" scoped>
.opti-hours__container {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  background: $sk-grey-5;
  border-bottom: 1px solid $sk-grey-10;
}

.opti-hours__cell::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 1px;
  bottom: -10px;
  border-right: 1px solid $sk-grey-10;
}

.opti-hours__cell {
  display: flex;
  position: relative;
  justify-content: left;
  align-items: center;
  font-size: $fs-text-s;
  flex-grow: 1;
  flex-basis: 0;
  flex-wrap: wrap;
  color: $sk-grey;

  .opti-hours__cell-day {
    margin-left: -5px;
    z-index: 2; // need to overlap opti-hours__left-spacer
  }
}

.opti-hours__cell-today {
  color: $sk-black;

  .opti-hours__cell-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 30px;
    background-color: $sk-blue;
    color: white;
    border-radius: 50%;
  }
}

.opti-hours__left-spacer {
  height: 100%;
  width: 61px;
  position: sticky;
  left: 0;
  z-index: 2;
  background: $sk-grey-5;
}

.kpis-total-label {
  font-size: $fs-text-s;
  color: $sk-grey;
  text-align: center;
  padding-right: 15px;
}
</style>
