<template>
  <table class="text-document-templates__table">
    <tr>
      <th class="table__header-cell">
        {{ $t('shop_settings.text_document_templates.table.headers.enabled') }}
      </th>
      <th />
      <th class="table__header-cell">
        {{ $t('shop_settings.text_document_templates.table.headers.title') }}
      </th>
      <th class="table__header-cell">
        {{ $t('shop_settings.text_document_templates.table.headers.last_edit') }}
      </th>
      <th class="table__header-cell">
        {{ $t('shop_settings.text_document_templates.table.headers.creator') }}
      </th>
      <th />
    </tr>
    <tr
      v-for="textDocumentTemplate in textDocumentTemplates"
      :key="textDocumentTemplate.id"
      class="table__row"
    >
      <td
        class="table__cell"
        @click.capture="toggleTextDocumentTemplateEnabled(textDocumentTemplate)"
      >
        <SkSwitch v-model="textDocumentTemplate.attributes.enabled" />
      </td>
      <td
        class="table__cell"
        @click="editTextDocumentTemplate(textDocumentTemplate)"
      >
        <SkMedallion
          icon="FileIcon"
          background-color="#FFF2D9"
          color="#FDBF3F"
        />
      </td>
      <td
        class="table__cell table__cell--important"
        @click="editTextDocumentTemplate(textDocumentTemplate)"
      >
        {{ textDocumentTemplate.attributes.title }}
      </td>
      <td
        class="table__cell table__cell--inactive"
        @click="editTextDocumentTemplate(textDocumentTemplate)"
      >
        {{ $t('shop_settings.text_document_templates.table.rows.updated_on',
              { date: skDate(textDocumentTemplate.attributes.updatedAt).format('ll') })
        }}
      </td>
      <td
        class="table__cell table__cell--inactive"
        @click="editTextDocumentTemplate(textDocumentTemplate)"
      >
        {{ getCreator(textDocumentTemplate) }}
      </td>
      <td class="table__cell">
        <SkCircleButton
          v-track="'text_document_template_delete'"
          icon="TrashCanV2Icon"
          @click="$emit('delete-text-document-template', textDocumentTemplate)"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'TextDocumentTemplatesTable',
  props: {
    textDocumentTemplates: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters({ fullName: 'employees/fullName' }),
  },
  methods: {
    getCreator(textDocumentTemplate) {
      return textDocumentTemplate.relationships.creator ?
        this.$t(
          'shop_settings.text_document_templates.table.rows.created_by',
          { creator: this.fullName(textDocumentTemplate.relationships.creator) },
        ) : '-';
    },
    toggleTextDocumentTemplateEnabled(textDocumentTemplate) {
      textDocumentTemplate.attributes.enabled = !textDocumentTemplate.attributes.enabled;
      this.$emit('text-document-template-enabled-changed', textDocumentTemplate);
      this.$skAnalytics.track(textDocumentTemplate.attributes.enabled ?
        'text_document_template_enable' : 'text_document_template_disable');
    },
    editTextDocumentTemplate(textDocumentTemplate) {
      this.$router.push({
        name: 'text_document_templates_edit',
        params: { text_document_template_id: textDocumentTemplate.id },
      });
    },
    skDate,
  },
};
</script>

<style lang="scss" scoped>
.text-document-templates__table {
  margin-top: 40px;
  width: 100%;

  .table__row {
    a.row-edit {
      text-decoration: none;
      color: $sk-blue;
    }

    &:hover {
      background: $sk-grey-5;
    }
  }

  th {
    padding: 7px 10px;
    border-bottom: 1px solid $sk-grey-10;
  }

  .table__header-cell {
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    color: $sk-grey;
    margin: 0;
  }

  td.table__cell {
    cursor: pointer;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid $sk-grey-10;

    a {
      color: $sk-black;

      &:hover {
        color: $sk-blue-50;
        text-decoration: none;
      }
    }
  }

  .table__cell--empty {
    border: none;
    text-align: center;
  }

  .table__cell--important {
    font-weight: bold;
  }

  .table__cell--inactive {
    color: $sk-grey;
    font-size: .9em;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  .table__link {
    color: $sk-black;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: underline;
      color: $sk-blue;
    }
  }
}
</style>
