<template>
  <VueDraggableResizable
    ref="dragAndCreate"
    :x="dragAndCreateInitialX + 5"
    :y="-3"
    :w="quarterWidth - 1"
    :min-width="quarterWidth - 1"
    :max-width="widthToClosingTime + 1"
    :grid="[quarterWidth, 0]"
    :h="47"
    active
    prevent-desactivation
    class="drag-and-create"
    :handles="[]"
    @mousedown.native="handleDragAndCreate"
    @mouseup.native.stop
    @click.native="openManageShiftModal"
    @resizestop="openManageShiftModal"
  >
    <div class="drag-and-create__label">
      <PlusSignV2Icon
        width="12"
        height="12"
        fill="#2b66fe"
      />
    </div>
  </VueDraggableResizable>
</template>
<script>
import { mapMutations } from 'vuex';
import skDate from '@skello-utils/dates';
import {
  openingAndClosingTimeAt,
  roundMinutesToQuarterHour,
} from '@app-js/plannings/shared/utils/planning_helpers';
import VueDraggableResizable from 'vue-draggable-resizable';

export default {
  name: 'DragAndCreate',
  components: {
    VueDraggableResizable,
  },
  props: {
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
    mouseXPosition: {
      type: Number,
      default: 0,
    },
    rowItem: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
    availabilities: {
      type: Array,
      required: true,
    },
    lastUserShift: {
      type: Object,
      default: null,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    lastScrollValue: {
      type: Number,
      required: true,
    },
    nbCurrentShopWorkingHours: {
      type: Number,
      required: true,
    },
    dayViewPlanningSizeVariables: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shiftHours: null,
    };
  },
  computed: {
    widthToClosingTime() {
      const nbQuartersToClosingTime = this.nbCurrentShopWorkingHours * 4 -
        Math.round(this.dragAndCreateInitialX / this.quarterWidth);

      if (nbQuartersToClosingTime < 0) return 0;

      return nbQuartersToClosingTime * this.quarterWidth;
    },
    dragAndCreateInitialX() {
      const { sidebarWidth } = this.dayViewPlanningSizeVariables;

      return (
        this.mouseXPosition -
        sidebarWidth -
        this.quarterWidth / 2 +
        this.lastScrollValue
      );
    },
    quarterWidth() {
      return this.dayViewPlanningSizeVariables.pixelPerQuarterHours;
    },
  },
  methods: {
    ...mapMutations('planningsState', ['setShiftDragAndCreatingRowId']),

    handleDragAndCreate(event) {
      this.setShiftDragAndCreatingRowId(this.rowItem.id);
      // Hack to start library drag on mousedown
      this.$nextTick(() => this.$refs.dragAndCreate.handleDown('mr', event));
    },
    getShiftHours() {
      const { openingTime } = openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        skDate.utc(this.currentDate).format(),
      );

      const { pixelPerMinute } = this.dayViewPlanningSizeVariables;

      const left = this.$refs.dragAndCreate.left;
      const minutesOffset = roundMinutesToQuarterHour(left / pixelPerMinute);
      const shiftStartAt = openingTime.add(minutesOffset, 'm');

      const width = this.$refs.dragAndCreate.width;
      const shiftDurationMinutes = roundMinutesToQuarterHour(width / pixelPerMinute);
      const shiftEndsAt = shiftStartAt.clone().add(shiftDurationMinutes, 'm');

      return { startsAt: shiftStartAt.format(), endsAt: shiftEndsAt.format() };
    },
    openManageShiftModal() {
      if (this.isUnassignedShiftsRow) {
        this.$skAnalytics.track('click_on_add_unassigned_shift');
      }

      if (!this.$refs.dragAndCreate) return;

      const { startsAt, endsAt } = this.getShiftHours();

      this.emitOnRoot(
        'manageShiftModal',
        null,
        {
          rowItem: this.rowItem,
          isUnassignedShift: this.isUnassignedShiftsRow,
          date: skDate.utc(this.currentDate),
          availabilities: this.availabilities,
          lastUserShift: this.lastUserShift,
          startsAt,
          endsAt,
        },
      );

      this.setShiftDragAndCreatingRowId(undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-and-create {
  cursor: pointer;
  position: absolute;
  display: flex;
  border: 2px solid $sk-blue;
  border-radius: 6px;
  margin: 3px;
  left: -2px;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.drag-and-create__label {
  color: $sk-blue;
  display: flex;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
