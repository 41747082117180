<template>
  <tr
    class="availability__row"
  >
    <td
      v-if="columns.employee.active"
      ref="employeeLabel"
      class="availability__employee-row"
    >
      {{ employee }}
    </td>
    <td
      v-if="columns.type.active"
      ref="typeLabel"
      class="availability__type-row"
    >
      {{ $t('requests.availability_requests.cell.type.' + request.attributes.status) }}
    </td>
    <td
      v-if="columns.recurrence.active"
      ref="recurrenceLabel"
      class="availability__status-row"
    >
      {{ $t('requests.availability_requests.cell.recurrence.' + request.attributes.recurrence) }}
    </td>
    <td
      v-if="columns.creation_date.active"
      ref="creationDateLabel"
      class="availability__creation-date-row"
    >
      {{ askedDate }}
    </td>
    <td
      v-if="columns.date.active"
      ref="dateLabel"
      class="availability__dates-row"
    >
      {{ displayDate }}
    </td>
    <td
      v-if="columns.hours.active"
      ref="hoursLabel"
      class="availability__duration-row"
    >
      {{ hours }}
    </td>
    <td
      v-if="columns.actions.active"
      ref="actionsLabel"
      class="availability__actions-row--select"
    >
      <SkSelectV2
        v-model="selectedAction"
        :options="actionOptions"
        :disabled="isActionDisabled"
        :label="$t('requests.availability_requests.actions.placeholder')"
        append-to-body
        data-test="availability-team-requests__table__status-select-action"
        @input="onActionChange"
      />
    </td>
    <td
      v-if="columns.status.active"
      class="availability__status-row"
    >
      <SkTag :variant="statusVariant">
        {{ $t('requests.statuses.' + request.attributes.requestStatus) }}
      </SkTag>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import AvailabilityRowMixins from '../../../mixins/AvailabilityRowMixins';

export default {
  name: 'AvailabilityRequestsTeamRow',
  mixins: [AvailabilityRowMixins],
  data() {
    return {
      actionOptions: [
        { id: 'accepted', text: this.$t('requests.availability_requests.actions.accepted') },
        { id: 'refused', text: this.$t('requests.availability_requests.actions.refused') },
      ],
      selectedAction: ['accepted', 'refused'].includes(this.request.attributes.requestStatus) ?
        this.request.attributes.requestStatus :
        null,
      isActionDisabled: false,
    };
  },
  methods: {
    ...mapActions('requests', ['updateAvailabilityRequest']),

    async onActionChange(value) {
      try {
        this.isActionDisabled = true;
        this.selectedAction = value;
        await this.updateAvailabilityRequest({
          availability: {
            ...this.request,
            attributes: {
              ...this.request.attributes,
              requestStatus: value,
            },
          },
        });
        this.$skToast({
          message: this.$t(`requests.availability_requests.toast.success.${value}`),
          variant: 'success',
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });
      } finally {
        this.isActionDisabled = false;
      }
    },
  },
};

</script>
<style lang="scss" scoped>
  .availability__row {
    td {
      padding: 14.5px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .availability__type-row,
    .availability__status-row,
    .availability__dates-row {
      min-width: 100px;
    }

    .availability__actions-row--select {
      min-width: 195px;
    }
  }
</style>
