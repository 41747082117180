<template>
  <span>
    <slot />
    <span
      v-for="(emoji, index) in emojiList"
      :key="index"
      :class="selected(emoji)"
      class="emoji"
    >
      <i
        :class="iconClass(emoji)"
        class="em"
        @click="handleChange(emoji)"
      />
    </span>
  </span>
</template>

<script>
import clone from 'lodash/clone';

const FOOD_EMOJIS = [
  'em-fork_and_knife',
  'em-wine_glass',
  'em-hamburger',
  'em-pizza',
  'em-spaghetti',
  'em-fries',
  'em-poultry_leg',
  'em-sushi',
  'em-rice_ball',
  'em-curry',
  'em-fried_shrimp',
  'em-cookie',
  'em-icecream',
  'em-apple',
  'em-watermelon',
  'em-herb',
];

export default {
  name: 'EmojiSelect',
  props: {
    value: {
      type: String,
      default: 'em-fries',
    },
    emojiList: {
      type: Array,
      default: () => FOOD_EMOJIS,
    },
  },
  data() {
    return {
      localValue: clone(this.value),
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.localValue) {
        this.localValue = clone(newValue);
      }
    },
  },
  methods: {
    handleChange(newValue) {
      this.localValue = newValue;
      this.$emit('input', this.localValue);
    },
    iconClass(emoji) {
      return [emoji, `${emoji}--form`];
    },
    selected(emoji) {
      return {
        'em-selected': this.localValue === emoji,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.emoji {
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: border-color .2s;
  border-radius: 50%;

  &.em-selected {
    border-color: $sk-azure-blue;
  }
}
</style>
