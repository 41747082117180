<template>
  <div :class="holidayTagClasses">
    {{ number }}
    <span :class="holidayTagCircleClasses" />
    {{ text }}
  </div>
</template>

<script>

const TAG_VARIANTS = [
  'primary',
  'danger',
  'light',
  'blue',
  'green',
  'orange',
];

export default {
  name: 'HolidayTag',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => TAG_VARIANTS.includes(value),
    },
    number: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    holidayTagClasses() {
      return {
        'holiday-tag': true,
        [`holiday-tag--${this.variant}`]: true,
      };
    },
    holidayTagCircleClasses() {
      return {
        'holiday-tag-circle': true,
        [`holiday-tag-circle--${this.variant}`]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.holiday-tag {
  padding: 3px 5px;
  border-radius: 2px;
  font-size: $fs-text-s;
  display: inline-block;
  white-space: nowrap;

  &.holiday-tag--orange {
    background: $sk-warning-10;
  }

  &.holiday-tag--blue {
    background: $sk-blue-5;
  }
}

.holiday-tag-circle {
  cursor: initial;
  content: '';
  margin: 0 2px;
  position: relative;
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  transform: perspective(1px) translateY(-50%);

  &.holiday-tag-circle--orange {
    background-color: $sk-warning;
  }

  &.holiday-tag-circle--blue {
    background-color: $sk-blue;
  }
}
</style>
