<template>
  <div id="notifications">
    <div class="header__title">
      {{ $t('profile.notifications.title') }}
    </div>
    <div class="header__subtitle">
      {{ $t('profile.notifications.subtitle') }}
    </div>
    <div class="notifications__form">
      <div class="form__line">
        <div class="line__label">
          {{ $t('profile.notifications.form.planning_change.label') }}
        </div>
        <div class="line__input">
          <SkCheckBox
            v-model="params.receivesEmail"
            class="sk-checkbox-container"
            data-test="notifications__planning-change__checkbox-receives_email"
            :label="$t('profile.notifications.form.planning_change.option.by_mail')"
          />
          <SkCheckBox
            v-model="params.receivesText"
            class="sk-checkbox-container"
            data-test="notifications__planning-change__checkbox-receives_text"
            :label="$t('profile.notifications.form.planning_change.option.by_sms')"
          />
        </div>
      </div>
      <div
        v-if="isSystemAdmin"
        class="form__line"
      >
        <div class="line__label">
          {{ $t('profile.notifications.form.trial_end_notification.label') }}
        </div>
        <div class="line__input">
          <SkSwitch
            v-model="params.receivesNotificationTrialsEnd"
            class="remove-margin-switch"
            data-test="notifications__receives_notification_trials_end__switch"
          />
        </div>
      </div>
      <div
        v-if="isDocumentNotificationEnabled"
        class="form__line"
      >
        <div class="line__label">
          {{ $t('profile.notifications.form.receives_document_notification.label') }}
        </div>
        <div class="line__input">
          <SkSwitch
            v-model="params.receivesDocumentNotification"
            class="remove-margin-switch"
            data-test="notifications__receives_document_notification__switch"
          />
        </div>
      </div>
      <div
        v-if="canEditActivityReport"
        class="form__line"
      >
        <div class="line__label">
          {{ $t('profile.notifications.form.frequency.label') }}
        </div>
        <div class="line__input">
          <SkCheckBox
            v-for="(notification, index) in receivesNotifications"
            :key="index"
            v-model="params[notification]"
            class="sk-checkbox-container"
            :data-test="`notifications__activity-report__checkbox-${snakeCase(notification)}`"
            :label="$t(`profile.notifications.form.frequency.option.${snakeCase(notification)}`)"
          />
        </div>
      </div>
      <div
        v-if="canEditActivityReport && isPersonalizedHourActivityReportEnabled"
        class="form__line"
      >
        <div class="line__label">
          {{ $t('profile.notifications.form.hour.label') }}
        </div>
        <div class="line__input">
          <SkTimePicker
            v-model="activityReportAt"
            class="time-picker"
            data-test="notifications__activity-report__time-picker-hour"
            :interval-in-minutes="60"
            readonly
          />
        </div>
      </div>
      <div
        v-if="canEditActivityReport"
        class="form__line"
      >
        <div class="line__label label_top">
          {{ $t('profile.notifications.form.activity_report.content.label') }}
        </div>
        <div class="line__input">
          <div>
            <div
              v-for="(option, index) in notificationOptions"
              :key="index"
              class="input__row"
            >
              <SkSwitch
                v-model="params.notificationOptions[option]"
                :data-test="`notifications__activity-report__switch-${snakeCase(option)}`"
              />
              <span>
                {{ $t(`profile.notifications.form.activity_report.content.option.${snakeCase(option)}`) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickyBar
      container-scroll-id="profile__content"
      :visible="hasFormChanged"
      :submit-spinner="updating"
      :submit-button-label="$t('profile.notifications.submit')"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import {
  snakeCase,
  isEqual,
} from 'lodash';
import {
  SkCheckBox,
  SkSwitch,
  SkTimePicker,
} from '@skelloapp/skello-ui';
import StickyBar from '@app-js/shared/components/Stickybar';
import skDate from '@skello-utils/dates';

const DEFAULT_ACTIVITY_REPORT_AT = '05:00';

const NOTIFICATION_OPTIONS = [
  'absences',
  'justArrived',
  'turnover',
  'trialEnd',
  'overHours',
  'revenues',
  'productivity',
  'hoursWorked',
  'salaryMass',
  'personnelRatio',
];

const RECEIVES_NOTIFICATIONS = [
  'receivesNotificationDaily',
  'receivesNotificationWeekly',
  'receivesNotificationMonthly',
];

export default {
  name: 'Notifications',
  components: {
    StickyBar,
    SkCheckBox,
    SkSwitch,
    SkTimePicker,
  },
  data() {
    return {
      updating: false,

      params: undefined,
      initialParams: undefined,

      notificationOptions: NOTIFICATION_OPTIONS,
      receivesNotifications: RECEIVES_NOTIFICATIONS,

      isPersonalizedHourActivityReportEnabled: false,
      canEditActivityReport: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    activityReportAt: {
      get() {
        return skDate(this.params.activityReportAt ?? DEFAULT_ACTIVITY_REPORT_AT, 'HH:mm').format();
      },
      set(newValue) {
        this.params.activityReportAt = skDate(newValue).hour().toString();
      },
    },
    hasFormChanged() {
      return !isEqual(this.params, this.initialParams);
    },
    isDocumentNotificationEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_DOCUMENTS_NOTIFICATION_V1');
    },
  },
  created() {
    this.params = structuredClone(this.currentUser.attributes);
    this.initialParams = structuredClone(this.currentUser.attributes);

    this.canEditActivityReport = this.currentLicense.attributes.canEditActivityReport;
    this.isPersonalizedHourActivityReportEnabled = this.isDevFlagEnabled('FEATUREDEV_PERSONALIZED_HOUR_ACTIVITY_REPORT');
  },
  methods: {
    snakeCase,
    ...mapActions('currentUser', ['updateCurrentUserProfile']),
    notificationOptionsParams() {
      return {
        activity_report_at: this.params.activityReportAt,
        receives_email: this.params.receivesEmail,
        receives_text: this.params.receivesText,
        receives_notification_trials_end: this.params.receivesNotificationTrialsEnd,
        receives_notification_daily: this.params.receivesNotificationDaily,
        receives_notification_weekly: this.params.receivesNotificationWeekly,
        receives_notification_monthly: this.params.receivesNotificationMonthly,
        receives_document_notification: this.params.receivesDocumentNotification,
        ...NOTIFICATION_OPTIONS.reduce(
          (acc, option) => (
            { ...acc, [snakeCase(option)]: this.params.notificationOptions[option] }
          ),
          {},
        ),
      };
    },
    async handleSubmit() {
      this.updating = true;

      try {
        await this.updateCurrentUserProfile({ user: this.notificationOptionsParams() });
      } catch ({ response }) {
        this.$skToast({
          message: response.data.message?.alert ?? this.$t('errors.standard_message'),
          variant: 'error',
        });
      } finally {
        this.updating = false;
      }

      this.initialParams = structuredClone(this.currentUser.attributes);
      this.$skToast({
        message: this.$t('profile.personal_information.update.success'),
        variant: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 12px;
}

.header__subtitle {
  font-size: 16px;
  font-weight: 400;
  color: $sk-grey-50;
  margin-bottom: 32px;
}

.notifications__form {
  .form__line {
    border-top: 1px solid $sk-grey-10;
    padding: 26px 12px;
    display: flex;
    align-items: center;

    .line__label {
      font-weight: 600;
      font-size: 14px;

      &.label_top {
        align-self: baseline;
      }
    }

    .line__input {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .time-picker {
        width: 60px;
      }

      .input__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;

        .sk-switch-v3 {
          height: 28px;
        }
      }

      .sk-checkbox-container {
        margin-right: 32px;
        user-select: none;
      }

      .sk-switch-v3 {
        height: 0px;

        &.remove-margin-switch {
          margin-right: -18px;
        }
      }
    }
  }
}

.sk-sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
