<template>
  <div
    ref="bottomPanelContentTabSimpleBar"
    class="workload-plan-bottom-panel__tab--simple-bar"
  >
    <div class="workload-plan-bottom-panel__tab">
      <div
        v-for="poste in orderedPostes"
        :key="poste.id"
        class="workload-plan-bottom-panel__tab__row"
      >
        <QuarterHoursInputCell
          v-for="quarter in hourQuarters"
          :id="quarter + poste.id"
          :key="quarter"
          class="workload-plan-bottom-panel__tab__input-cell"
          :style="{width: `${dayViewPlanningSizeVariables.pixelPerQuarterHours}px`}"
          :is-creation="!hasWorkloadPlanOnDay"
          :quarter-value="displayedWorkloadPlanValue(poste, quarter)"
          @on-quarter-value-change="onQuarterValueChange(poste, quarter, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  mapGetters, mapState,
} from 'vuex';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import QuarterHoursInputCell from './QuarterHoursInputCell';

export default {
  name: 'Content',
  components: {
    QuarterHoursInputCell,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      tabContentScrollElement: null,
    };
  },
  computed: {
    ...mapGetters('planningsWorkloadPlans', [
      'arrayOfOpeningQuarters',
      'workloadPlansForPeriod',
      'hasNeedsOnPeriod',
    ]),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsWorkloadPlans', ['workloadPlansByPostes']),
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),

    hourQuarters() {
      return this.arrayOfOpeningQuarters.map(quarter => skDate.utc(quarter).format('YYYY-MM-DDTHH:mm:ss'));
    },
    orderedPostes() {
      return [...this.postes].sort((posteA, posteB) => (
        posteA.attributes.name.toLowerCase()
          .localeCompare(posteB.attributes.name.toLowerCase())
      ));
    },
    hasWorkloadPlanOnDay() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;

      return this.hasNeedsOnPeriod(openingTime.toISOString(), closingTime.toISOString());
    },
    workloadPlansForCurrentDay() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;

      return this.workloadPlansForPeriod(
        openingTime.toISOString(),
        closingTime.toISOString(),
      );
    },
  },
  mounted() {
    this.listenOnRoot('workload-plan-vertical-scroll', this.handleVerticalScroll);
    this.listenOnRoot('workload-plan-horizontal-scroll', this.handleHorizontalScroll);

    const workloadPlanBottomPanelSimpleBar =
      new SimpleBar(this.$refs.bottomPanelContentTabSimpleBar, { forceVisible: 'x' });
    this.tabContentScrollElement = workloadPlanBottomPanelSimpleBar.getScrollElement();
    this.tabContentScrollElement.addEventListener('scroll', this.handleEmitScroll);

    this.$emit(
      'on-is-scrollable-horizontally',
      this.tabContentScrollElement.scrollWidth > this.tabContentScrollElement.clientWidth,
    );
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleEmitScroll);
  },
  methods: {
    // Takes the value entered by the user OR the initial value OR null if nothing exists
    displayedWorkloadPlanValue(poste, quarterHour) {
      const intialWorkloadPlan = this.findInitialWorkloadPlan(poste.id, quarterHour);
      const editedWorkloadPlan =
        this.value[poste.id]?.find(wp => wp.startsAt === quarterHour);

      return editedWorkloadPlan ?
        editedWorkloadPlan.value :
        (intialWorkloadPlan?.value ?? null);
    },
    onQuarterValueChange({ id: posteId }, quarterHour, value) {
      const initialWorkloadPlan = this.findInitialWorkloadPlan(posteId, quarterHour);
      const newValue = parseInt(value, 10);
      let newEditedWorkloadPlans = {};

      // If the new value is the same as the old one we remove the workload plan from the edited list
      if (this.areWorkloadPlanValuesEqual(initialWorkloadPlan?.value, newValue)) {
        newEditedWorkloadPlans = this.removeQuarterFromEditedWorkloadPlans(posteId, quarterHour);
      } else {
        // Otherwise, we should add or update the workload plan with the new value
        newEditedWorkloadPlans = this.upsertQuarterToWorkloadPlans(
          initialWorkloadPlan,
          posteId,
          quarterHour,
          newValue,
        );
      }

      this.$emit('input', newEditedWorkloadPlans);
    },
    findInitialWorkloadPlan(posteId, quarterHour) {
      return this.workloadPlansForCurrentDay[posteId]?.find(wp => wp.startsAt === quarterHour);
    },
    // Check if the two values are the same, or if both are falsy (which means they are the same
    // in our case)
    areWorkloadPlanValuesEqual(value1, value2) {
      return value1 === value2 || (!value1 && !value2);
    },
    // Return the workload plan object without the specified quarter for the posteId
    removeQuarterFromEditedWorkloadPlans(posteId, quarterHour) {
      const newWorkloadPlans = { ...this.value };

      if (newWorkloadPlans?.[posteId]) {
        newWorkloadPlans[posteId] =
          newWorkloadPlans[posteId].filter(wp => wp.startsAt !== quarterHour);

        // Remove the key if empty
        if (newWorkloadPlans[posteId].length === 0) delete newWorkloadPlans[posteId];
      }

      return newWorkloadPlans;
    },
    // Return the workload plan object with the specified posteId / quarter
    // created or updated with the new value
    upsertQuarterToWorkloadPlans(initialWorkloadPlan, posteId, quarterHour, quarterValue) {
      const newWorkloadPlans = { ...this.value };

      newWorkloadPlans[posteId] ??= [];

      const alreadyEditedWorkloadPlanIndex =
        newWorkloadPlans[posteId].findIndex(wp => wp.startsAt === quarterHour);

      if (alreadyEditedWorkloadPlanIndex !== -1) {
        newWorkloadPlans[posteId][alreadyEditedWorkloadPlanIndex].value = quarterValue || null;
      } else {
        newWorkloadPlans[posteId].push({ ...initialWorkloadPlan, value: quarterValue || null });
      }

      return newWorkloadPlans;
    },
    handleEmitScroll(event) {
      this.emitOnRoot('workload-plan-horizontal-scroll', event);
      this.emitOnRoot('workload-plan-vertical-scroll', event);
    },
    handleVerticalScroll(event) {
      // Avoid setting scrollTop on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.bottomPanelContentTabSimpleBar === event.srcElement) return;
      this.tabContentScrollElement.scrollTop = event.target.scrollTop;
    },
    handleHorizontalScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.$refs.bottomPanelContentTabSimpleBar === event.srcElement) return;
      this.tabContentScrollElement.scrollLeft = event.target.scrollLeft;
    },
  },
};
</script>

<style scoped lang="scss">
.workload-plan-bottom-panel__tab {
  border: 1px solid rgba(black, .15);
  background-color: white;
  overflow: hidden;
  width: fit-content;
  height: fit-content;

  /* Remove the default scroll bar */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &--simple-bar {
    max-height: 300px;
    width: 100%;

    /*
      Override the simple bar css to put it above the shadow
      To have some space on the right side
    */
    ::v-deep .simplebar-track.simplebar-vertical {
      z-index: 3; /* put the scroll bar above the shadow */
    }

    ::v-deep .simplebar-track.simplebar-horizontal {
      z-index: 3; /* put the scroll bar above the shadow */
    }
  }

  &__row {
    display: flex;
    border-bottom: 2px solid $sk-grey-5;
    height: 40px;

    &:last-child {
      border-bottom: none;
    }
  }

  &__input-cell {
    border-right: 1px solid $sk-grey-5;
    &:last-child {
      border-right: none;
    }

    &:nth-child(4n):not(:last-child) {
      border-right: 3px solid $sk-grey-5;
    }
  }
}
</style>
