<template>
  <div class="text-document-templates__summary">
    <div class="text-document-templates__summary__count">
      {{ $t('shop_settings.text_document_templates.summary.count',
            { count: textDocumentTemplates.length.toString() }) }}
    </div>
    <div v-if="creators.length !== 0">
      {{ $t('shop_settings.text_document_templates.summary.created_by_all') }}
    </div>
    <div class="text-document-templates__summary__creators">
      <SkMedallion
        v-for="creator in creators.slice(0, 3)"
        :key="creator.id"
        :text="userInitials(creator)"
        background-color="#2b66fe"
        color="white"
        border-color="white"
      />
    </div>
    <div class="text-document-templates__summary__button">
      <SkOroraButton
        v-track="'hris_template_create'"
        icon="PlusSignV2Icon"
        @click="handleNew"
      >
        {{ $t('shop_settings.text_document_templates.summary.add_button') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'Summary',
  computed: {
    ...mapState('textDocumentTemplates', ['loading', 'textDocumentTemplates']),
    ...mapGetters('textDocumentTemplates', ['creators']),
    ...mapGetters('employees', ['userInitials']),
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'text_document_templates_new' });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-document-templates__summary {
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  height: 90px;
  margin: 20px 0 40px;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .text-document-templates__summary__count {
    margin-right: 60px;
    padding-left: 15px;
    font-weight: bold;
  }

  .text-document-templates__summary__creators {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
    padding: -20px;
    margin-left: 30px;
  }

  .text-document-templates__summary__button {
    margin-left: auto;
    padding-right: 15px;
  }
}
</style>
