<template>
  <div
    v-show="showPopover"
    :ref="refName"
    class="counters-cell__popover-wrapper"
    :style="menuPosition"
  >
    <GlobalEvents
      v-if="showPopover"
      @click="handleGlobalClick"
    />
    <div class="counters-cell__popover-content">
      <slot
        name="popover-content"
        :counters="counters"
      />
    </div>
    <div class="counters-cell__popover-close">
      <CrossV2Icon
        class="counters-cell__popover-close-icon"
        fill="black"
        height="16"
        width="16"
        @click.native="hidePopover"
      />
    </div>
  </div>
</template>

<script>
import GlobalEvents from 'vue-global-events';
import { CrossV2Icon } from '@skelloapp/skello-ui';

export default {
  name: 'CountersCellPopover',
  components: {
    GlobalEvents,
    CrossV2Icon,
  },
  props: {
    listenerId: {
      type: String,
      required: true,
    },
    popoverHeight: {
      type: Number,
      required: true,
    },
    refName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      counters: null,
      absoluteLeft: 0,
      absoluteTop: 0,
      showPopover: false,
    };
  },
  computed: {
    menuPosition() {
      return {
        top: `${this.absoluteTop}px`,
        left: `${this.absoluteLeft}px`,
      };
    },
  },
  mounted() {
    this.listenOnRoot('show-counter-detail', event => {
      if (event.origin !== this.listenerId) {
        this.hidePopover();
        return;
      }

      // 10 is a pretty margin, 33 is the height of KPIs's bar
      const prettyMargin = 10 + 33;

      // Set popover position
      // Fixed popover height divided by 2 to center it with the icon
      this.absoluteTop = event.y - this.popoverHeight / 2;
      // Fixed popover width + 10 for a pretty margin
      this.absoluteLeft = event.x - 360 - 10;

      this.counters = event.counters;

      this.showPopover = true;
      this.$nextTick(() => {
        // Recalculate popover position when it is outside viewport
        const wrapperSize = this.$refs[this.refName].getBoundingClientRect();
        const popoverDistanceFromBottom = window.innerHeight - (wrapperSize.y + wrapperSize.height);
        if (popoverDistanceFromBottom < 0) {
          this.absoluteTop += popoverDistanceFromBottom - prettyMargin;
        }
      });

      this.listenOnRoot('planning-table-scroll', this.handleScroll);
    });
  },
  methods: {
    hidePopover() {
      if (this.showPopover) {
        this.showPopover = false;
      }
    },
    handleScroll(scrollValue) {
      this.absoluteTop += scrollValue;
    },
    handleGlobalClick(event) {
      if (this.$refs[this.refName] && this.$refs[this.refName].contains(event.target)) return;

      this.hidePopover();
    },
  },
};
</script>

<style lang="scss" scoped>
.counters-cell__popover-wrapper {
  position: absolute;
  display: flex;
  z-index: 8;
  width: 360px;
  background: white;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .24);
  border-radius: 4px;

  .counters-cell__popover-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 0 24px 12px;
  }

  .counters-cell__popover-close {
    padding: 12px 12px 12px 8px;
  }

  .counters-cell__popover-close-icon {
    cursor: pointer;
  }
}
</style>
