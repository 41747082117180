<template>
  <div class="all-shops--wrapper">
    <LoadingPage
      v-if="isDataLoading"
      class="loader"
    />

    <Table
      v-else
      :shifts-owners="currentUserShops"
      :grouped-shifts="shiftsByShop"
      :published-owners="publishedShops"
      :global-config="globalConfig"
    />
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
import { employeeSpaceClient } from '@skello-utils/clients';
import Table from '@app-js/employees/shared/components/Table';
import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';

export default {
  name: 'AllShopsPlanning',
  components: { Table, LoadingPage },
  data() {
    return {
      isDataLoading: false,
      shiftsByShop: {},
      publishedShops: [],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop', 'currentUserShops']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('planningsState', ['currentDate']),
    globalConfig() {
      return {
        currentShop: this.currentShop,
        planningZoom: this.planningZoom,
        shops: this.currentUserShops,
      };
    },
  },
  watch: {
    $route() {
      this.initializeData();
    },
  },
  created() {
    this.initializeData();
  },
  methods: {
    ...mapActions('currentShop', ['fetchShops']),
    async initializeData() {
      this.isDataLoading = true;

      try {
        await Promise.all([
          this.fetchShops(),
          this.fetchPlanningData(),
        ]);
      } catch (error) {
        this.$emit('error-happened', { error });
      }

      this.isDataLoading = false;
    },
    async fetchPlanningData() {
      const {
        shiftsByShop, publishedShops,
      } = await employeeSpaceClient.fetchEmployeeShifts({
        employeeId: this.currentUser.id,
        date: this.currentDate,
      });

      this.publishedShops = publishedShops;
      this.shiftsByShop = shiftsByShop;
    },
  },
};
</script>

<style scoped lang="scss">
.all-shops--wrapper {
  height: 100%;
  display: flex;
  overflow: hidden;
}
</style>
