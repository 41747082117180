<template>
  <TooltipWrapper
    background-color="white"
    color-text="black"
  >
    <template>
      <div class="workload-plan-tooltip">
        <span class="workload-plan-tooltip__title">
          {{ displayedTime }}
        </span>
        <div
          v-for="(poste, index) in displayedPostes"
          :key="index"
          class="workload-plan-tooltip__list"
        >
          <div class="workload-plan-tooltip__list__label">
            {{ poste.name }}
          </div>
          <div class="workload-plan-tooltip__list__total">
            {{ poste.plannedShifts }} / {{ poste.plannedWorkload }}
          </div>
        </div>

        <div
          v-if="hasTooManyPostes"
          class="workload-plan-tooltip__more-postes"
        >
          <span class="workload-plan-tooltip__more-postes--dots">...</span>
          <span>{{
            $tc(
              'workload_plan.tooltip.too_many_postes',
              postesCountAboveLimit,
              { numberOfPostes: postesCountAboveLimit },
            )
          }}</span>
        </div>

        <div
          v-if="total"
          class="workload-plan-tooltip__list workload-plan-tooltip__total"
        >
          <div class="workload-plan-tooltip__list__label">
            {{ $t('workload_plan.tooltip.total') }}
          </div>
          <div class="workload-plan-tooltip__list__total">
            {{ total.plannedShifts }} / {{ total.plannedWorkload }}
          </div>
        </div>

        <div
          v-if="hasTooManyPostes"
          class="workload-plan-tooltip__more-info"
        >
          <WorkloadPlanTooltipInfoIcon
            width="22"
            height="14"
          />
          <span class="workload-plan-tooltip__more-info__text">
            {{ $t('workload_plan.tooltip.info') }}
          </span>
        </div>
      </div>
    </template>
  </TooltipWrapper>
</template>

<script>
import TooltipWrapper from '../shared/TooltipWrapper';
import WorkloadPlanTooltipInfoIcon from '../shared/icons/WorkloadPlanTooltipInfoIcon';

const MAXIMUM_OF_POSTES_DISPLAYED = 14;

export default {
  name: 'WorkloadPlanTooltip',
  components: { TooltipWrapper, WorkloadPlanTooltipInfoIcon },
  props: {
    tooltipDatas: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayedTime() {
      return `${this.tooltipDatas.startsAt} - ${this.tooltipDatas.endsAt}`;
    },
    isTotalDisplayed() {
      return this.tooltipDatas.postes.length > 1;
    },
    total() {
      if (!this.isTotalDisplayed) return null;

      return {
        plannedShifts: this.sumPostesDatas('plannedShifts'),
        plannedWorkload: this.sumPostesDatas('plannedWorkload'),
      };
    },
    displayedPostes() {
      return this.tooltipDatas.postes?.slice(0, MAXIMUM_OF_POSTES_DISPLAYED);
    },
    hasTooManyPostes() {
      return this.tooltipDatas.postes.length > MAXIMUM_OF_POSTES_DISPLAYED;
    },
    postesCountAboveLimit() {
      return this.tooltipDatas.postes.length - MAXIMUM_OF_POSTES_DISPLAYED;
    },
  },
  methods: {
    sumPostesDatas(dataType) {
      return this.tooltipDatas.postes.reduce((acc, poste) => acc + poste[dataType], 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 153px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  padding: 8px;
  padding-top: 16px;
  font-size: $fs-text-s;
  color: $sk-black;

  &__title {
    margin-bottom: 4px;
    font-size: $fs-text-s;
  }

  &__more-postes {
    font-size: $fs-text-xs;
    margin-top: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--dots {
      font-size: $fs-text-m;
    }
  }

  &__more-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 12px;

    &__text {
      font-size: $fs-text-xs;
      color: $sk-grey;
      text-align: left;
      margin-left: 5px;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    font-size: $fs-text-xs;

    &__label {
      min-width: 100px;
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__total {
      margin-left: 8px;
      width: inherit;
      display: flex;
      justify-content: flex-end;
    }

    &:last-child {
      margin-top: 8px;
    }
  }

  &__total {
    font-weight: $fw-semi-bold;

    .workload-plan-tooltip__list__total {
      margin-left: 0;
    }

    .workload-plan-tooltip__list__label {
      min-width: 0;
    }
  }
}
</style>
