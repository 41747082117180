<template>
  <SkModal
    id="variable-hours-history-modal"
    ref="variableHoursHistoryModal"
    :modal-title="$t('employees.tabs.contracts.actions.historic.modal.title')"
    :display-footer="false"
  >
    <template #title-icon>
      <SkMedallion
        icon="HistoryIcon"
        background-color="#f3f3f3"
        color="#727272"
      />
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <h3 class="sk-header--3">
          {{ fullName }}
        </h3>
        <SkTable
          :columns="columnHeader"
        >
          <HistoricRow
            v-for="amendments in groupedCyclicAmendments"
            :key="`historic-row-${amendments[0].id}`"
            :cyclic-amendments="amendments"
          />
        </SkTable>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import groupBy from 'lodash/groupBy';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import skDate from '@skello-utils/dates';

import HistoricRow from './HistoricRow';

export default {
  name: 'VariableHoursHistoryModal',
  components: { HistoricRow },
  data() {
    return {
      columnHeader: [
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.date') },
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.team') },
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.rolling') },
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.start') },
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.end') },
        { title: this.$t('employees.tabs.contracts.actions.historic.modal.header.contract_hours') },
      ],
    };
  },
  computed: {
    ...mapState('amendments', ['cyclicAmendments']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teams']),
    ...mapGetters('selectedEmployee', ['fullName']),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable']),

    groupedCyclicAmendments() {
      const amendments = this.cyclicAmendments.map(amendment => (
        {
          ...amendment,
          teamName: this.teams.find(team => (
            team.relationships.teamSchedules.data.some(ts => (
              parseInt(ts.id, 10) === amendment.attributes.teamScheduleId
            ))
          )).attributes.name,
        }
      ));
      return Object.values(
        groupBy(amendments, amendment => amendment.attributes.teamScheduleId),
      ).sort((a, b) => {
        if (skDate(a[0].attributes.startsAt).isBefore(skDate(b[0].attributes.startsAt))) {
          return -1;
        }
        return 1;
      });
    },
  },
  mounted() {
    this.fetchTeams({
      shopId: this.currentShop.id,
      isVariableContractHoursAvailable: this.isVariableContractHoursAvailable,
    });
  },
  methods: {
    ...mapActions('shopTeams', ['fetchTeams']),
  },
};
</script>
