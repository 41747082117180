<template>
  <!-- eslint-disable  max-len -->
  <div>
    <div
      v-if="loadingAbsences"
      class="absences__section__spinner"
    >
      <SkLoader />
    </div>
    <div v-else>
      <div class="absence-section__text__description">
        <h1 class="sk-header--1">
          {{ $t('shop_settings.tabs.absences.title') }}
        </h1>
        <p class="sk-subtitle--large">
          {{ $t('shop_settings.tabs.absences.no_modulation.not_in_modulation_subtitle_part_1') }}
          <br>
          <SkOroraButton
            v-if="!isMealCompensationDone"
            v-track="'trigger_absences_side_pannel_absence_tab'"
            class="absences-section__text__button"
            variant="secondary"
            @click="showAbsencePanel"
          >
            {{ $t('shop_settings.tabs.absences.explanation.title') }}
          </SkOroraButton>
        </p>
        <p
          v-if="isMealCompensationDone"
          class="sk-subtitle--large absence-section__text__description"
        >
          <SkOroraButton
            v-track="'trigger_absences_side_pannel_absence_tab'"
            class="absences-section__text__button"
            variant="secondary"
            @click="showAbsencePanel"
          >
            {{ $t('shop_settings.tabs.absences.explanation.title') }}
          </SkOroraButton>
        </p>
        <div
          v-if="isMealCompensationDone"
          class="absences-section__header__counter"
        >
          <div class="absence-section__meal-triggers-counter">
            {{ mealTriggerCount }}
          </div>
          <p>{{ $t('shop_settings.tabs.absences.no_modulation.not_in_modulation_counter_explanation') }}</p>
          <span>&nbsp;{{ $t('shop_settings.tabs.absences.no_modulation.not_in_modulation_counter_explanation_text_in_bold') }}</span>
        </div>
      </div>
      <ModulationAbsenceTable v-if="modulationEnabled && canReadShopRulesAndAbsences" />
      <RegularAbsenceTable v-else />
      <div class="absences__section__stickybar">
        <StickyBar
          :submit-button-label="$t('shop_settings.sticky_bar.submit')"
          :submit-spinner="updatingAbsenceCounter"
          :visible="dirty"
          container-scroll-id="shop-settings__container"
          @submit="handleSubmit"
        />
      </div>
      <MountingPortal
        v-if="modulationEnabled"
        mount-to="#modals-portal"
        append
      >
        <RecalculateCountersModal
          ref="recalculateCountersModal"
          @submit-recalculation="submitAbsencesModifications"
        />
      </MountingPortal>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';

import StickyBar from '@app-js/shared/components/Stickybar';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import RegularAbsenceTable from './RegularAbsence/Table';
import ModulationAbsenceTable from './ModulationAbsence/Table';
import RecalculateCountersModal from './RecalculateCountersModal';

export default {
  name: 'Absences',
  components: {
    RegularAbsenceTable,
    ModulationAbsenceTable,
    StickyBar,
    RecalculateCountersModal,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['currentLicense/canManageShopRulesAndAbsences']) {
        vm.$router.push({ name: 'shop_settings' });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: next,
      });
    } else {
      next();
    }
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('absences', [
      'originalModulationShopAbsences',
      'shopAbsences',
      'modulationShopAbsences',
      'loadingAbsences',
      'updatingAbsenceCounter',
      'dirty',
    ]),
    ...mapGetters('currentShop', ['modulationActivated', 'isMealCompensationDone']),
    ...mapGetters('currentLicense', ['canReadShopRulesAndAbsences']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),

    mealTriggerCount() {
      return this.shopAbsences.filter(absence => absence.attributes.triggersOwedMeal).length;
    },
    modulationEnabled() {
      return this.checkPackOfferFlag('hours_counter_enabled') && this.modulationActivated;
    },
  },
  watch: {
    currentShop(newShop, oldShop) {
      if (newShop !== oldShop) {
        this.fetchAbsences(this.currentShop);
      }
    },
  },
  created() {
    this.fetchAbsences(this.currentShop);
  },
  methods: {
    ...mapActions('absences', ['fetchAbsences', 'updateAbsenceCounterModulation']),
    ...mapMutations('absences', ['squashOriginalModulationShopAbsences']),

    showAbsencePanel(event) {
      this.$root.$emit('open-absences-panel', event);
    },

    handleSubmit(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'recalculate-counters-modal');
    },

    submitAbsencesModifications(recalculationDate) {
      const absencesOffCounterIds = [];
      const absencesInCounterIds = [];

      this.modulationShopAbsences.offCounterAbsences.forEach(absOffCounter => {
        absencesOffCounterIds.push(absOffCounter.id);
      });
      this.modulationShopAbsences.inCounterAbsences.forEach(absInCounter => {
        absencesInCounterIds.push(absInCounter.id);
      });
      this.updateAbsenceCounterModulation({
        shopId: this.currentShop.id,
        absencesOffCounterIds,
        absencesInCounterIds,
        recalculationDate,
      })
        .then(() => {
          this.squashOriginalModulationShopAbsences();
          this.$skToast({
            message: this.$t(
              'shop_settings.tabs.absences.in_modulation.update_counter.success_message',
            ),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.absences.in_modulation.update_counter.error_message'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.absences__section__stickybar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.absences__section__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.absences-section__text__button {
  margin-top: 12px
}

.absences-section__header__counter {
  display: flex;
  align-items: center;
  font-size: $fs-text-m;
  margin-top: 20px;
  color: #333;

  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    color: $sk-black;
  }

  span {
    font-weight: bold !important;
    line-height: 17px;
  }
}

.absence-section__meal-triggers-counter {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $sk-blue;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
</style>
