<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg card-cluster-icon"
    width="16"
    height="14"
    viewBox="0 0 16 14"
  >
    <path
      d="M8.07691 3.76855H7.53845V10.2301H8.07691V3.76855Z"
    />
    <path
      d="M14 10.2305H13.4615V7.26893H2.15382V10.2305H1.61536V6.73047H14V10.2305Z"
    />
    <path
      d="M1.88462 13.9997C0.843769 13.9997 0 13.1559 0 12.1151C0 11.0742 0.843769 10.2305 1.88462 10.2305C2.92546 10.2305 3.76923 11.0742 3.76923 12.1151C3.76923 13.1559 2.92546 13.9997 1.88462 13.9997Z"
    />
    <path
      d="M13.7307 13.9997C12.6898 13.9997 11.8461 13.1559 11.8461 12.1151C11.8461 11.0742 12.6898 10.2305 13.7307 10.2305C14.7715 10.2305 15.6153 11.0742 15.6153 12.1151C15.6153 13.1559 14.7715 13.9997 13.7307 13.9997Z"
    />
    <path
      d="M7.80771 13.9997C6.76687 13.9997 5.9231 13.1559 5.9231 12.1151C5.9231 11.0742 6.76687 10.2305 7.80771 10.2305C8.84856 10.2305 9.69233 11.0742 9.69233 12.1151C9.69233 13.1559 8.84856 13.9997 7.80771 13.9997Z"
    />
    <path
      d="M7.80771 3.76874C6.76687 3.76874 5.9231 2.92497 5.9231 1.88413C5.9231 0.843281 6.76687 -0.000488281 7.80771 -0.000488281C8.84856 -0.000488281 9.69233 0.843281 9.69233 1.88413C9.69233 2.92497 8.84856 3.76874 7.80771 3.76874Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#0d2ed9',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 21px; // ie11 support
  width: initial;
  height: 12px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 16px; // edge support
    height: 14px; // edge support
  }
}
</style>
