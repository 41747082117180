export const paramAsArray = param => {
  // Param isn't set in URL
  if (param === undefined) {
    return [];
  }
  // Only one value for param in URL -> string
  if (typeof param === 'string') {
    return [param];
  }
  // Several values for param in URL -> array
  return [...param];
};
