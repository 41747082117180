<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    class="clusters-tree-app-x-svg"
  >
    <path
      d="M11.589 9.28767C11.4247 9.12329 11.4247 8.87671 11.589 8.71233L17.7534 2.54795C17.9178 2.38356 18 2.13699 18 1.9726C18 1.80822 17.9178 1.56164 17.7534 1.39726L16.6027 0.246575C16.4384 0.0821918 16.1918 0 16.0274 0C15.7808 0 15.6164 0.0821918 15.4521 0.246575L9.28767 6.41096C9.12329 6.57534 8.87671 6.57534 8.71233 6.41096L2.54795 0.246575C2.38356 0.0821918 2.13699 0 1.9726 0C1.80822 0 1.56164 0.0821918 1.39726 0.246575L0.246575 1.39726C0.0821918 1.56164 0 1.80822 0 1.9726C0 2.13699 0.0821918 2.38356 0.246575 2.54795L6.41096 8.71233C6.57534 8.87671 6.57534 9.12329 6.41096 9.28767L0.246575 15.4521C0.0821918 15.6164 0 15.863 0 16.0274C0 16.1918 0.0821918 16.4384 0.246575 16.6027L1.39726 17.7534C1.56164 17.9178 1.80822 18 1.9726 18C2.13699 18 2.38356 17.9178 2.54795 17.7534L8.71233 11.589C8.87671 11.4247 9.12329 11.4247 9.28767 11.589L15.4521 17.7534C15.6164 17.9178 15.863 18 16.0274 18C16.1918 18 16.4384 17.9178 16.6027 17.7534L17.7534 16.6027C17.9178 16.4384 18 16.1918 18 16.0274C18 15.863 17.9178 15.6164 17.7534 15.4521L11.589 9.28767Z"
      fill="#2D2F30"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black',
    },
    size: {
      type: String,
      default: '18',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-x-svg {
  margin: 0;
  width: 18px; // ie11 support
  width: initial;
  height: 18px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 18px; // edge support
    height: 18px; // edge support
  }
}
</style>
