<template>
  <SkModalSection
    class="variable_contract_hours_section__wrapper"
    border-bottom="none"
  >
    <h3>{{ $t('employees.add_employee_modal.variable_contract_hours.title') }}</h3>
    <div class="sk-subtitle--medium add-employee-modal__variable_contract_hours__subtitle">
      {{ $t('employees.add_employee_modal.variable_contract_hours.subtitle') }}
    </div>
    <div class="variable_contract_hours_section__row_wrapper">
      <div
        v-for="amendment in employeeCyclicAmendments"
        :key="amendment.attributes.scheduleWeekIndex"
        class="variable_contract_hours_section__row"
      >
        <VariableContractHoursTag
          class="variable_contract_hours_section__row-tag"
          :nb-weeks="employeeCyclicAmendments.length"
          :specific-week-index="amendment.attributes.scheduleWeekIndex"
        />
        <SkInputGroup class="variable_contract_hours_section__row-input">
          <SkInput
            v-model="amendment.attributes.hours"
            :label="$t('contract.hourly_rate.label')"
            type="number"
            min="0"
          />
          <template #append>
            {{ $t('contract.counter_types.hourly_rate.unit') }}
          </template>
        </SkInputGroup>
      </div>
    </div>
  </SkModalSection>
</template>

<script>
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';

export default {
  name: 'VariableContractHoursSection',
  components: {
    VariableContractHoursTag,
  },
  props: {
    employeeCyclicAmendments: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.variable_contract_hours_section__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 30px !important;
  padding-top: 10px !important;
}

.variable_contract_hours_section__row {
  display: flex;
  align-items: center;
  max-width: 344px;
  flex-basis: 344px;
}

.variable_contract_hours_section__row-tag {
  margin-right: 16px;
}

.variable_contract_hours_section__row-input {
  min-width: 44px;
  flex-basis: 302px;
}

.add-employee-modal__variable_contract_hours__subtitle {
  margin-bottom: 18px;
  color: $sk-grey;
}

.variable_contract_hours_section__row_wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 10px 30px;
}
</style>
