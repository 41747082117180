<template>
  <div>
    <SmartAppBanner
      v-if="isMobile"
      :size="isAuthRequired ? 'small' : 'large'"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <SkConfirmationModal />
      <SkOroraConfirmationDialog />
      <SkOroraBackdrop />
      <SkOroraFloatingPopoverMenu />
      <CookieDialog />
    </MountingPortal>
    <div
      v-if="loading"
      class="bootloader"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapActions,
} from 'vuex';
import { skIdleUserPageReloader } from '@skello-utils/idle';
import SmartAppBanner from '@app-js/shared/components/SmartAppBanner/SmartAppBanner';
import { isMobile } from '@skello-utils/mobile.js';
import CookieDialog from '@app-js/shared/components/CookieDialog/CookieDialog';
import {
  httpClient, impersonateClient,
} from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { setSvcUsersFeatureFlags } from '@skello-utils/feature-flags';
import { staticRoutes } from './static';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/es';
import 'moment/dist/locale/de';
import 'moment/dist/locale/it';

export default {
  name: 'Root',
  components: { SmartAppBanner, CookieDialog },
  data() {
    return {
      isMobile: isMobile(),
      loading: true,
    };
  },
  computed: {
    isUsingStatelessToken() {
      return this.$route.query.deal_room ||
        this.$route.query.invitation ||
        this.$route.query.profile ||
        this.$route.query.roll_out;
    },
    isAuthRequired() {
      return !staticRoutes.map(route => route.name).includes(this.$route.name);
    },
  },
  created() {
    this.$nextTick(() => {
      document.title = this.$t('tab_titles.default');
    });

    skIdleUserPageReloader.start();

    this.setSvcUsersFeatureFlags();
  },
  beforeDestroy() {
    skIdleUserPageReloader.stop();
  },
  async mounted() {
    if (this.$route.query.needs_jwt === 'true' && this.isUsingStatelessToken) {
      await this.handleStatelessTokenConnection();
    }

    await impersonateClient.tryImpersonate();

    await this.checkAuthAndRedirect();

    this.loading = false;
  },
  methods: {
    ...mapMutations(['setNavbarVisibility']),
    ...mapActions('currentUser', ['authWithJWT']),

    async checkAuthAndRedirect() {
      const isConnected = await this.isConnectedByJwtAndBySession();

      if (this.isAuthRequired && !isConnected) {
        this.navigateToLogin();
      } else if (this.$route.path === '/') {
        /* NOTE: This logic will be handle in frontend soon by migrating HomeRedirector
        ** in front end see https://skello.atlassian.net/browse/REAPER-199
        */
        const { data } = await httpClient.get('/v3/home_url');
        /* NOTE: When a logged in superadmin access skello app directly
          ** by entering the url manually, then we use a full super admin url and change site.
          ** The router needs to use the push api only for relative path.
          */
        if (data.url.startsWith('/')) {
          this.$router.push({ path: data.url });
        } else {
          window.location = data.url;
        }
      }
    },

    async setSvcUsersFeatureFlags() {
      const { data } = await httpClient.get('/v3/api/feature_flags');

      setSvcUsersFeatureFlags(data);
    },
    navigateToLogin() {
      this.setNavbarVisibility(false);

      this.$router.push({ name: 'Login', query: this.$route.query });
    },
    async isConnectedByJwtAndBySession() {
      try {
        const authToken = await authClient.getAuthToken();

        return !!authToken;
      } catch (error) {
        return false;
      }
    },
    /* NOTE: Stateless token provides a way to transmit info about who's attempting connecting
    ** for users coming from workflow outside of the main application. (ie deal room, invitation, etc)
    */
    async handleStatelessTokenConnection() {
      let params = {};

      if (this.$route.query.deal_room) {
        params = {
          token: this.$route.query.deal_room,
          purpose: 'deal_room',
        };
      } else if (this.$route.query.invitation) {
        params = {
          token: this.$route.query.invitation,
          purpose: 'invitation',
        };
      } else if (this.$route.query.profile) {
        params = {
          token: this.$route.query.profile,
          purpose: 'profile',
        };
      } else if (this.$route.query.roll_out) {
        params = {
          token: this.$route.query.roll_out,
          purpose: 'roll_out',
        };
      }

      await this.authWithJWT(params);

      // Clears the search params from the URL after using the stateless token
      this.$router.replace(this.$route.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.bootloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
