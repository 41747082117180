<template>
  <div class="spinner-wrapper">
    <SkLoader size="x-large" />
    <div class="spinner-text">
      {{ $t('plannings.table.loading.placeholder') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-m;
  color: $sk-blue;
}

.spinner-text {
  padding-top: 30px;
}
</style>
