<template>
  <div class="automatic-planning__upsell__wrapper">
    <p>
      {{ $t('automatic_planning.sidebar.upsell.hook_phrase_1') }}
      <strong>{{ $t('automatic_planning.sidebar.upsell.smart_planner') }}</strong>
      {{ $t('automatic_planning.sidebar.upsell.hook_phrase_3') }}
    </p>
    <p>
      <strong>{{ $t('automatic_planning.sidebar.upsell.explanation_phrase_1') }}</strong>
      {{ $t('automatic_planning.sidebar.upsell.explanation_phrase_2') }}
    </p>
    <p>
      <!-- tada emoji -->
      &#127881;
      <strong>{{ $t('automatic_planning.sidebar.upsell.smart_planner') }}</strong>
      {{ $t('automatic_planning.sidebar.upsell.call_to_action_phrase_1') }}
      {{ $t('automatic_planning.sidebar.upsell.call_to_action_phrase_2') }}
      <SkOroraLink
        :href="$t('automatic_planning.sidebar.infos.link')"
        target="_blank"
        class="sk-link--medium"
      >
        <span>{{ $t('automatic_planning.sidebar.upsell.learn_more_link') }}</span>
      </SkOroraLink>.
    </p>

    <hr class="automatic-planning__upsell__separator">

    <div class="automatic-planning__upsell__cta__wrapper">
      <div class="automatic-planning__upsell__pack-offer">
        <div class="automatic-planning__upsell__pack-offer__pack-wrapper">
          <div>
            {{ $t('automatic_planning.sidebar.upsell.pack_offer_limitation_1') }}
            <PackTag
              :type="packType"
              shadow
            />
          </div>
          <p>{{ $t('automatic_planning.sidebar.upsell.pack_offer_limitation_2') }}</p>
        </div>
        <div class="automatic-planning__upsell__cta">
          <SkOroraButton @click="upsellRequested">
            {{ $t('automatic_planning.sidebar.upsell_link') }}
          </SkOroraButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PackTag from '@app-js/shared/components/PackTag';

export default {
  name: 'AutomaticPlanningUpsell',
  components: { PackTag },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
  },
  methods: {
    upsellRequested() {
      this.$emit('upsell');
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__upsell__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.automatic-planning__upsell__separator {
  background: #e8eaed;
  border: 0;
  height: 1px;
  width: 100%;
  margin: 16px 0;
}

.automatic-planning__upsell__cta__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.automatic-planning__upsell__pack-offer {
  line-height: 22px;
}

.automatic-planning__upsell__pack-offer__pack-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.automatic-planning__upsell__cta {
  display: flex;
  justify-content: center;
}
</style>
