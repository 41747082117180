<template>
  <SkModalV2
    :id="'select-employee-report-modal'"
    :title="modalTitle"
    size="large"
    :submit-disabled="!areAnyEmployeesSelected"
    :testing-options="modalTestingOptions"
    @show="resetData"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSectionV2
        border-bottom="none"
      >
        <div class="select-employees-report-modal__select-employees">
          <strong>{{ $t('reports.select_employees.users_label') }}</strong>
          <div>
            <SelectEmployees
              ref="selectEmployees"
              class="select-employee-report-modal__input"
              :employees-infos="employeesInfos"
              @set-selected-employees="handleSelectedEmployees"
            />
            <div
              v-show="!areAnyEmployeesSelected"
              class="select-employees__error-message"
            >
              {{ $t('reports.modals.loading.errors.no_employees') }}
            </div>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import SelectEmployees from './SelectEmployees';

export default {
  name: 'SelectEmployeeReportModal',
  components: {
    SelectEmployees,
  },
  props: {
    exportData: {
      type: Object,
      required: true,
    },
    employeesInfos: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedUserIds: [],
      modalTestingOptions: {
        submit: 'select-employee-report-modal__submit',
        cancel: 'select-employee-report-modal__cancel',
      },
    };
  },
  computed: {
    modalTitle() {
      if (!this.exportData) return '';

      return ['excel', 'unemployment'].includes(this.exportData.type) ?
        this.$t(`reports.modals.${this.exportData.type}.modal_title`) :
        this.$t('reports.modals.integration.modal_title', { integrationName: this.exportData.name });
    },
    disableSubmit() {
      return this.selectedUserIds.length === 0;
    },
    areAnyEmployeesSelected() {
      return this.selectedUserIds.length > 0;
    },
  },
  methods: {
    resetData() {
      this.$refs.selectEmployees.resetData();
    },
    handleSubmit() {
      this.$emit('send-select-employees-report', this.exportData, this.selectedUserIds);
    },
    handleSelectedEmployees(newSelectedUserIds) {
      this.selectedUserIds = newSelectedUserIds;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-employee-report-modal__input {
  width: 320px !important;
}

.select-employees-report-modal__select-employees {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.select-employees__error-message {
  color: $sk-error;
  position: fixed;
}
</style>
