<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="97"
    height="40"
    viewBox="0 0 97 40"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.1279 20.6681C96.1279 13.8323 92.8168 8.43848 86.4883 8.43848C80.1332 8.43848 76.2881 13.8323 76.2881 20.6147C76.2881 28.6521 80.8275 32.7108 87.3428 32.7108C90.5204 32.7108 92.9236 31.9899 94.7393 30.9752V25.6347C92.9236 26.5426 90.8408 27.1034 88.1973 27.1034C85.6072 27.1034 83.3108 26.1955 83.0171 23.0446H96.0745C96.0745 22.6975 96.1279 21.309 96.1279 20.6681ZM82.9369 18.1314C82.9369 15.114 84.7794 13.859 86.4616 13.859C88.0905 13.859 89.8261 15.114 89.8261 18.1314H82.9369Z"
      fill="#32325D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.9816 8.43848C63.3648 8.43848 61.6826 9.66678 60.748 10.5213L60.4009 8.86571H54.5264V40.0006L61.2019 38.5853L61.2286 31.0286C62.1899 31.7229 63.6051 32.7108 65.9549 32.7108C70.7346 32.7108 75.0871 28.8657 75.0871 20.4011C75.0604 12.6574 70.6545 8.43848 65.9816 8.43848ZM64.3795 26.8363C62.8041 26.8363 61.8695 26.2756 61.2286 25.5813L61.2019 15.6748C61.8962 14.9004 62.8575 14.3664 64.3795 14.3664C66.8094 14.3664 68.4916 17.09 68.4916 20.588C68.4916 24.1661 66.8361 26.8363 64.3795 26.8363Z"
      fill="#32325D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.3408 6.86248L52.0431 5.42056V0L45.3408 1.41522V6.86248Z"
      fill="#32325D"
    />
    <path
      d="M52.0431 8.89258H45.3408V32.2571H52.0431V8.89258Z"
      fill="#32325D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.1578 10.8682L37.7306 8.89222H31.9629V32.2567H38.6385V16.4223C40.2139 14.3662 42.8841 14.74 43.7119 15.0337V8.89222C42.8574 8.57179 39.7333 7.98434 38.1578 10.8682Z"
      fill="#32325D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.8067 3.09766L18.2914 4.48617L18.2646 25.8747C18.2646 29.8266 21.2286 32.7372 25.1805 32.7372C27.3701 32.7372 28.9723 32.3366 29.8534 31.856V26.4354C28.999 26.7826 24.78 28.0109 24.78 24.0589V14.5796H29.8534V8.89205H24.78L24.8067 3.09766Z"
      fill="#32325D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75568 15.6748C6.75568 14.6334 7.61015 14.2329 9.02537 14.2329C11.0547 14.2329 13.6182 14.847 15.6475 15.9418V9.66678C13.4312 8.78561 11.2417 8.43848 9.02537 8.43848C3.60481 8.43848 0 11.2689 0 15.9952C0 23.365 10.1469 22.1901 10.1469 25.3677C10.1469 26.596 9.07877 26.9966 7.58345 26.9966C5.36716 26.9966 2.53672 26.0887 0.293725 24.8604V31.2155C2.77704 32.2836 5.28705 32.7375 7.58345 32.7375C13.1375 32.7375 16.9559 29.9872 16.9559 25.2075C16.9292 17.2502 6.75568 18.6654 6.75568 15.6748Z"
      fill="#32325D"
    />
  </svg>
</template>

<script>
export default {
  name: 'StripeLogo',
};
</script>
