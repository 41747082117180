<template>
  <div
    v-show="visible"
    class="dashboard-section dashboard-flip-cards square-2"
  >
    <div class="flip-card">
      <div class="face front doc-expiring-front">
        <div />
        <div class="number">
          {{ docsEndingSoonLength }}
        </div>
        <div class="subtitle">
          {{ $t('home_dashboard.doc_expiring.subtitle') }}
          {{ actualDateDisplay }}
        </div>
      </div>
      <div class="face back">
        <div class="dashboard-title dtitle-spread noflip">
          <h2>
            {{ $t('home_dashboard.doc_expiring.subtitle') }}
            {{ actualDateDisplay }}
          </h2>
          <div class="dt-rpart">
            <div id="turnover-btn">
              <SkCircleButton
                icon="ChevronLeftV2Icon"
                size="small"
                bordered
                @click="docsTime('prev')"
              />

              <SkCircleButton
                icon="ChevronRightV2Icon"
                size="small"
                bordered
                @click="docsTime('next')"
              />
            </div>
          </div>
        </div>
        <div class="dashboard-content d-limited-content">
          <div
            v-if="docsEndingSoonLength === 0"
            class="d-no-result"
          >
            {{ $t('home_dashboard.doc_expiring.no_doc') }}
          </div>
          <ul
            v-else
            class="trials-list"
          >
            <li
              v-for="docsEndingSoon in docsEndingSoons"
              :key="docsEndingSoon.id"
              class="trial-item noflip"
            >
              <a
                :href="docsEndingSoon.link"
                class="trial-link noflip"
                target="blank"
                data-seg="DboardDocExpiration"
              >
                <div class="monogram-wrapper">
                  <div
                    :title="docsEndingSoon.name"
                    class="user-monogram sidebar"
                  >
                    {{ docsEndingSoon.monogram }}
                  </div>
                </div>
                <div class="flex-grow">
                  <div class="trial-user-name doc">
                    {{ docsEndingSoon.title }}
                  </div>
                  <div class="trial-date">
                    {{ docsEndingSoon.readableDate }}
                  </div>
                </div>
                <div class="trial-eye">
                  <OpenEyeV2Icon
                    height="100%"
                    width="100%"
                    fill="#EEE"
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

import { SkCircleButton } from '@skelloapp/skello-ui';

export default {
  name: 'DocsExpiringCard',
  components: {
    SkCircleButton,
  },
  data() {
    return {
      visible: true,
      docsEndingSoons: null,
      docsEndingSoonLength: null,
      actualDate: null,
      actualDateDisplay: null,
      get_docs_ending_path: '/get_docs_ending',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    initialLoad() {
      this.actualDate = this.getLastMonth();
      this.docsTime('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'DocsExpiringCard');
    },
    getLastMonth() {
      return skDate().startOf('month').subtract(1, 'months');
    },
    docsTime(statut) {
      if (statut === 'prev') {
        this.actualDate.subtract(1, 'months');
      } else if (statut === 'next') {
        this.actualDate.add(1, 'months');
      }

      this.actualDateDisplay = this.actualDate.locale(this.$i18n.locale).format('MMMM YYYY').toLowerCase();

      const params = {
        start_date: this.actualDate.format('YYYY-MM-DD'),
      };

      // View all is handled by not passing the shop id
      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.get_docs_ending_path, { params })
        .then(response => {
          const data = response.data;
          this.docsEndingSoonLength = data.docsEndingSoonLength;
          this.docsEndingSoons = data.docsEndingSoons;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
