export const REGION_KEYS = {
  DE: [
    'de_bb',
    'de_be',
    'de_bw',
    'de_by',
    'de_hb',
    'de_he',
    'de_hh',
    'de_mv',
    'de_ni',
    'de_nw',
    'de_rp',
    'de_sh',
    'de_sl',
    'de_sn',
    'de_st',
    'de_th',
  ],
  ES: [
    'es_an',
    'es_ar',
    'es_cb',
    'es_ce',
    'es_cl',
    'es_cm',
    'es_cn',
    'es_ct',
    'es_ex',
    'es_ga',
    'es_ib',
    'es_lo',
    'es_m',
    'es_ml',
    'es_mu',
    'es_na',
    'es_o',
    'es_pv',
    'es_vc',
  ],
};
