<template>
  <SkModalV2
    id="rtt-config-user-initialization-modal"
    ref="rttConfigUserInitializationModal"
    :title="$t('employees.tabs.counters.rtt_counter.table.init_modal.title')"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSectionV2>
        <div class="rtt-modal__init-row">
          <div class="rtt-modal__init--bold">
            {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.start_date.title') }}
          </div>
          <div class="rtt-modal__datepicker-wrapper">
            <SkDatePicker
              v-model="initializationDate"
              :disabled-date="disabledDays"
              :error-message="$t('errors.missing_value')"
              :lang="$i18n.locale"
              :placeholder="$t('employees.tabs.counters.rtt_counter.table.init_modal.start_date.placeholder')"
              hide-footer
              input-moment-format="MMMM YYYY"
              popup-extra-class="rtt-modal__datepicker-popup"
              type="month"
            />
            <p class="rtt-modal__date-hint sk-subtitle--small">
              {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.start_date.hint') }}
            </p>
          </div>
        </div>
      </SkModalSectionV2>
      <SkModalSectionV2
        :padding-bottom="false"
        border-bottom="none"
      >
        <div class="paid-leaves-counter-modal__balance__header rtt-modal__init-row">
          <span class="rtt-modal__init--bold">
            {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.balance.title') }}
          </span>
          <SkSwitch
            v-model="isBalanceActivated"
            class="rtt-modal__balance__switch"
          />
        </div>
        <p class="sk-subtitle--medium">
          {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.balance.explanation') }}
        </p>
        <div v-if="isBalanceActivated">
          <SkInfoCard class="rtt-modal__balance__card">
            <div>
              {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.balance.explanation_card') }}
            </div>
          </SkInfoCard>
          <UserBalanceRow
            :user-initials="userInitials(employee)"
            :user-full-name="fullName(employee)"
            :initialization-counter="initializationCounter"
            @new-balance="value => initializationCounter = value"
          />
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="isUserBalanceErrored || !initializationDate"
        :loading="isSubmitting"
        @click="handleSubmit"
      >
        {{ $t('employees.tabs.counters.rtt_counter.table.init_modal.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';

import { computeHistoryRecoveryUpdate } from '@skelloapp/skello-absences';
import skDate from '@skello-utils/dates';
import UserBalanceRow from '@app-js/shared/components/CountersInitializationModal/RttCounterInitializationModal/UserBalanceRow';

export default {
  name: 'RttConfigUserInitializationModal',
  components: { UserBalanceRow },
  data() {
    return {
      initializationDate: null,
      initializationCounter: null,
      isBalanceActivated: false,
      isBalanceErrored: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('shopAbsenceConfig', ['shopAbsenceConfig']),
    isUserBalanceErrored() {
      return this.isBalanceActivated && typeof this.initializationCounter !== 'number';
    },
    shopInitializationDate() {
      return skDate.utc(this.shopAbsenceConfig.attributes.rttConfig.initialization_date);
    },
  },
  methods: {
    ...mapActions('employeeRttCounter', ['fetchCounterData', 'setPeriodDates']),
    ...mapMutations('employeeRttCounter', ['setEmployeeAbsenceConfig']),
    disabledDays(date) {
      const dateMonth = date.getMonth();
      const dateYear = date.getFullYear();

      const initializationMonth = this.shopInitializationDate.month();
      const initializationYear = this.shopInitializationDate.year();

      return dateYear < initializationYear ||
        (dateYear === initializationYear && dateMonth < initializationMonth);
    },
    handleCancel(event) {
      this.$refs.rttConfigUserInitializationModal.hide();
      this.resetData();
      this.$emit('cancel', event);
    },
    handleSubmit() {
      this.isSubmitting = true;

      const initializationDate = skDate.utc(this.initializationDate);
      const updates = this.initializationCounter ?
        computeHistoryRecoveryUpdate(initializationDate.toDate(), this.initializationCounter) :
        [];

      const userId = this.employee.id;
      const shopId = this.currentShop.id === 'all' ? this.employee.attributes.shopId : this.currentShop.id;
      const absenceType = 'RTT';

      const employeesAbsenceConfigParams = {
        userId,
        shopId,
        initializationDate: initializationDate.format('YYYY-MM-DD'),
        initializationCounter: 0,
        updates,
        absenceType,
      };

      svcEmployeesClient.createAbsenceConfig(employeesAbsenceConfigParams)
        .then(response => {
          this.$skToast({
            message: this.$t('employees.tabs.counters.rtt_counter.table.init_modal.toast.success'),
            variant: 'success',
            containerId: 'employees__container',
          });

          const newAbsenceConfig = {
            userId: response.data.userId,
            shopId: response.data.shopId,
            absenceType: response.data.absenceType,
            initializationDate: response.data.initializationDate,
            initializationCounter: response.data.initializationCounter,
            updates: response.data.updates,
          };

          this.setPeriodDates();
          this.setEmployeeAbsenceConfig(newAbsenceConfig);
          this.fetchCounterData({ absenceType, shopId, userId });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.counters.rtt_counter.table.init_modal.toast.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
</script>

<style lang="scss" scoped>
.rtt-modal__init-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &.paid-leaves-counter-modal__balance__header {
    padding-bottom: 0;
   }
}

.rtt-modal__init--bold {
  font-weight: $fw-semi-bold;
}

.rtt-modal__balance__card {
  margin: 16px 0;
}
</style>

<style lang="scss">
  // /!/ Careful : unscoped css to draw attention on year selection
  .rtt-modal__datepicker-popup {
    .mx-btn-text {
      color: black;
    }

    .mx-calendar-header-label {
      .mx-btn-text {
        font-size: $fs-text-l;
        font-weight: bold;
      }
    }
  }
</style>
