export const STORE_URL = {
  ios: 'https://apps.apple.com/fr/app/skello/id1215389131',
  android: 'https://play.google.com/store/apps/details?id=app.skello.skello',
};

const platform = navigator?.userAgentData?.platform || navigator?.platform;
export const isIOS = () => /iPad|iPhone|iPod/.test(platform) ||
    (platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const isAndroid = () => /Android/i.test(navigator.userAgent);

export const isMobile = () => isIOS() || isAndroid();
