<template>
  <SkModal
    id="new-year-holiday-counter-info-modal"
    :modal-title="newYearInfoModalTitle"
    @close="hideModal"
    @hidden="resetData"
  >
    <template #body>
      <div class="new-year-info-modal__body">
        <CircledIIcon
          class="new-year-info-modal__icon"
          fill="#2b66fe"
          width="24"
          height="24"
        />
        <div class="new-year-info-modal__disclaimer">
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.new_year_info.info') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="new-year-info-modal__footer">
        <SkCheckBox
          v-model="gotIt"
          :label="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.new_year_info.got_it')"
        />
        <div class="actions">
          <SkOroraButton
            variant="secondary"
            @click="hideModal"
          >
            {{ $t('actions.cancel') }}
          </SkOroraButton>
          <SkOroraButton @click="handleSubmit">
            {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.new_year_info.continue_button') }}
          </SkOroraButton>
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

export default {
  name: 'NewYearHolidaysCounterInfoModal',
  data() {
    return {
      gotIt: true,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),

    newYearInfoModalTitle() {
      // eslint-disable-next-line max-len
      return this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.new_year_info.title', {
        year: skDate().year(),
      });
    },
  },
  methods: {
    resetData() {
      this.gotIt = true;
    },
    toggleGotIt() {
      this.gotIt = !this.gotIt;
    },
    handleSubmit() {
      if (this.gotIt) {
        const newYearModalOpenedOnceKey = `newYearModalOpenedForUser:${this.currentUser.id}`;
        localStorage.setItem(newYearModalOpenedOnceKey, 'true');
      }
      this.hideModal();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'holidays-settings-modal');
    },
    hideModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'new-year-holiday-counter-info-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.new-year-info-modal__body {
  padding: 24px 25px 0;
  display: flex;

  .new-year-info-modal__icon {
    margin: -2px 20px 0 0;
  }

  .new-year-info-modal__disclaimer {
    max-width: 90%;
  }
}

.new-year-info-modal__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}
</style>
