<template>
  <SkModal :on-close="toggleRenameModal">
    <template #header>
      <ModalHeader
        :title="resolveModalTitle"
        :svg-name="'PenSvg'"
      />
    </template>
    <template #body>
      <div class="input-wrapper">
        <span>{{ resolveSubtitle }}</span>
        <input
          ref="focusOnMe"
          v-model="newName"
          class="rename-input"
          @keyup.enter="submit"
        >
      </div>
    </template>
    <template #footer>
      <div class="actions">
        <ModalButton
          :button-text="$t('actions.rename')"
          :on-press-action="submit"
          button-color="#2b66fe"
        />
        <ModalButton
          :button-text="$t('actions.cancel')"
          :on-press-action="toggleRenameModal"
          button-color="#ff5355"
        />
      </div>
    </template>
  </SkModal>
</template>

<script>
import SkModal from '../../ui/SkModal';
import ModalHeader from '../../ui/ModalHeader';
import ModalButton from '../../ui/ModalButton';

export default {
  components: { SkModal, ModalHeader, ModalButton },
  props: {
    currentCluster: {
      type: Object,
      default: null,
    },
    showRenameModal: {
      type: Boolean,
      default: false,
    },
    toggleRenameModal: {
      type: Function,
      required: true,
    },
    renameNode: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      newName: '',
    };
  },
  computed: {
    resolveModalTitle() {
      const name = this.currentCluster.name.length > 35 ?
        `${this.currentCluster.name.substring(0, 30)}...` :
        this.currentCluster.name;
      return `${this.$t('actions.rename')} ${name}`;
    },
    resolveSubtitle() {
      if (this.currentCluster.type === 'shop') {
        return this.$t('organisation_settings.tabs.clusters.rename_shop');
      }

      return this.$t('organisation_settings.tabs.clusters.rename_node');
    },
  },
  mounted() {
    this.$refs.focusOnMe.focus();
  },
  methods: {
    submit() {
      this.renameNode(this.newName);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header,
.icon-container {
  display: flex;
  align-items: center;
}

.icon-container {
  justify-content: center;
  background-color: $sk-grey-10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.modal-header-title {
  font-size: $fs-heading-xs;
}

.input-wrapper {
  font-size: 13px;
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.rename-input {
  margin-top: 5px;
  width: 100%;
  border: 1px solid #00bedd;
  box-sizing: border-box;
  border-radius: 3px;
  height: 44px;
  padding: 0 5px;
  font-size: $fs-text-l;
  font-weight: normal;
}

.actions {
  margin: 30px 0 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
