<template>
  <SkOroraDialog
    id="update-sepa-modal"
    ref="updateSepaModal"
    :is-submit-disabled="disabledSubmit"
    :is-submit-loading="loadingSubmit"
    :submit-label="$t('organisation_settings.sepa_modal.submit')"
    :title="titleText"
    size="medium"
    @cancel="resetData"
    @close="resetData"
    @submit="handleSubmit"
  >
    <template #body>
      <main class="update-sepa-modal">
        <div :class="stripeClasses">
          <StripeBox />
        </div>
        <div
          v-if="!isPaymentTypeSepa"
          class="sk-text-large-regular"
        >
          {{ $t('organisation_settings.sepa_modal.transfer_description') }}
        </div>
        <div class="update-sepa-modal__inputs-container">
          <template v-if="!isBillingInfoOnOrganisation && isPaymentTypeSepa">
            <div class="update-sepa-modal__input-row">
              <label class="sk-text-large-semibold">
                {{ $t('organisation_settings.sepa_modal.labels.shop') }}
              </label>
              <div class="update-sepa-modal__input">
                <SkSelect
                  v-model="shops"
                  :ajax-client="ajaxClient"
                  :ajax-format-response="ajaxFormatResponse"
                  :label="$t('organisation_settings.sepa_modal.placeholder.shop')"
                  :search-placeholder="$t('labels.search')"
                  ajax-url="/v3/api/shops"
                  height="200px"
                  width="320px"
                  select-all
                  object-value
                  append-to-body
                  multi
                  paginated
                >
                  <template #empty-option>
                    {{ $t('employees.add_employee_modal.affiliations.no_search_results') }}
                  </template>

                  <template #selected-option="slotProps">
                    {{ slotProps.value.length }}
                    {{ $t('employees.tabs.personal_data.assignment.assignment_card.fields.employee_assignment_append') }}
                  </template>

                  <template #select-all-label>
                    {{ $t('employees.add_employee_modal.affiliations.select_all_shops') }}
                  </template>

                  <template #option="slotProps">
                    <div class="shops-list__item">
                      <SkMedallion
                        icon="ShopIcon"
                        background-color="#d9e6ff"
                        class="shops-list__item-icon"
                        color="#2b66fe"
                      />
                      <span>
                        {{ slotProps.option.text }}
                      </span>
                    </div>
                  </template>
                </SkSelect>
              </div>
            </div>
          </template>
          <div class="update-sepa-modal__input-row">
            <label
              class="sk-text-large-semibold"
              for="iban-input"
            >
              {{ labelText }}
            </label>
            <div class="update-sepa-modal__input">
              <SkInput
                v-model.trim="iban"
                :errored="!!ibanErrorMessage"
                :error-message="ibanErrorMessage"
                :debounce="validateIban"
                :debounce-ms="500"
                :label="$t('organisation_settings.sepa_modal.placeholder.iban')"
                input-id="iban-input"
              />
            </div>
          </div>
        </div>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import { mapState } from 'vuex';
import IBAN from 'iban';

import { httpClient } from '@skello-utils/clients';
import { sortCollectionBy } from '@skello-utils/collection';

import StripeBox from '../../shared/components/StripeBox';

export default {
  name: 'UpdateSepaModal',
  components: { StripeBox },
  props: {
    isPaymentTypeSepa: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iban: null,
      ibanErrorMessage: '',
      ajaxClient: () => httpClient,
      shops: [],
      loadingSubmit: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('config', ['config']),

    isBillingInfoOnOrganisation() {
      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[2];
    },
    disabledSubmit() {
      const isBillingEnabled = this.isBillingInfoOnOrganisation || this.shops.length > 0;
      return !this.iban || !!this.ibanErrorMessage || !isBillingEnabled || this.loadingSubmit;
    },
    handleSubmit() {
      if (this.isPaymentTypeSepa) return this.updateSepa;

      return this.createSepa;
    },
    labelText() {
      if (this.isPaymentTypeSepa) return this.$t('organisation_settings.sepa_modal.labels.sepa_iban');

      return this.$t('organisation_settings.sepa_modal.labels.transfer_iban');
    },
    titleText() {
      if (this.isPaymentTypeSepa) return this.$t('organisation_settings.sepa_modal.sepa_title');

      return this.$t('organisation_settings.sepa_modal.transfer_title');
    },
    stripeClasses() {
      return {
        'update-sepa-modal__stripe': !this.isPaymentTypeSepa,
        'update-sepa-modal__stripe-no-description': this.isPaymentTypeSepa,
      };
    },
  },
  methods: {
    ajaxFormatResponse(response) {
      const shops = sortCollectionBy(response.data, 'attributes.name');

      return shops.map(shop => ({
        id: shop.id,
        text: shop.attributes.name,
      }));
    },
    validateIban() {
      this.ibanErrorMessage = null;
      if (!this.iban) {
        this.ibanErrorMessage = this.$t('organisation_settings.sepa_modal.errors.iban.mandatory');
      } else if (!IBAN.isValid(this.iban)) {
        this.ibanErrorMessage = this.$t('organisation_settings.sepa_modal.errors.iban.invalid');
      }
    },
    updateSepa() {
      const params = {
        sepa: {
          shops: this.shops,
          iban: this.iban,
        },
      };

      this.loadingSubmit = true;

      httpClient
        .post('/v3/api/sepa/update_stripe_iban', params)
        .then(() => {
          this.$skToast({
            message: this.$t('organisation_settings.sepa_modal.toasts.success'),
            variant: 'success',
          });
          this.resetData();
          this.$refs.updateSepaModal.hide();
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('organisation_settings.sepa_modal.toasts.error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loadingSubmit = false;
        });
    },
    createSepa() {
      this.loadingSubmit = true;
      this.$emit('billing-info-to-sepa', this.iban, event);
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    billingInfoUpdateSucceeded() {
      this.$refs.updateSepaModal.hide();
      this.resetData();
    },
    stopLoading() {
      this.loadingSubmit = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#update-sepa-modal {
  .update-sepa-modal {
    padding: 8px 24px;

    &__stripe {
      margin-bottom: 16px;
    }

    &__stripe-no-description {
      margin-bottom: 24px;
    }

    &__inputs-container {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 16px;
    }

    &__input-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        margin: 0;
        width: 232px;
      }
    }

    &__input {
      width: 320px;
      flex-shrink: 0;
    }
  }
}
</style>
