<template>
  <!-- eslint-disable  max-len -->
  <div :class="columnClasses">
    <div class="employee-counters__week-title">
      {{ $t('dates.week', { weekNumber: week.week }) }}
    </div>
    <div v-if="!week.disabled">
      <TableCell :data="week.days_worked" />
      <TableCell
        :show-dash="week.active_contract_interim"
        :data="week.contract_days"
        :tooltip="interimContractWarning"
        :color="week.active_contract_interim ? '#d03e50' : ''"
      />
      <TableCell
        :data="week.days_counter - week.manual_changes"
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
      <TableCell
        :show-dash="week.show_dash"
        :tooltip="$t('employees.tabs.counters.hours_counter.tooltip.modify_counter')"
        :data="week.manual_changes"
        editable
        @click="openModal"
      />
      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <modify-counter-modal
          :id="week.week"
          ref="modal"
          :manual-changes="week.manual_changes"
          :old-counter="week.days_counter"
          :date="week.end_week_date"
        />
      </MountingPortal>
      <TableCell
        :data="week.days_counter"
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
      <TableCell :data="week.contract_days - week.days_counter" />
    </div>
    <div v-else>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { TableCell } from '../shared/components';
import ModifyCounterModal from './ModifyCounterModal';

export default {
  name: 'DayRateCounterWeekColumn',
  components: { TableCell, ModifyCounterModal },
  props: {
    week: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    columnClasses() {
      return {
        'employee-counters__week': true,
        'employee-counters__week--disabled': this.week.disabled,
      };
    },
    canPerformManualModification() {
      return (
        this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser
      ) && this.canEditEmployeeInfo;
    },
    interimContractWarning() {
      return this.week.active_contract_interim ?
        this.$i18n.t('employees.tabs.counters.day_rate_counter.tooltip.dash_interim_contract') :
        '';
    },
  },
  methods: {
    openModal(event) {
      if (this.emptyWeek) return;
      if (!this.canPerformManualModification) {
        this.$skToast({
          message: this.$i18n.t('employees.tabs.counters.day_rate_counter.manual_changes_modal.unauthorized'),
          variant: 'error',
        });

        return;
      }
      this.$skAnalytics.track('manual_change_days_counter');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, this.$refs.modal.id);
    },
  },
};
</script>
