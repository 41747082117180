<template>
  <div class="duplicate-workload-plan-modal__day-to-duplicate">
    <span class="duplicate-workload-plan-modal__day-to-duplicate__label">
      {{ $t('workload_plan.modals.duplicate.day_to_duplicate_label') }}
    </span>

    <SkSelectV2
      :value="value"
      :options="previousDays"
      width="300px"
      force-search-bar-behavior="force-hide"
      append-to-body
      no-search
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'SelectDayToDuplicate',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('planningsState', ['currentDate']),

    previousDays() {
      const day = skDate(this.currentDate);

      return Array(7).fill().map(() => {
        day.subtract(1, 'd');
        const formattedDay = day.format('dddd Do MMMM YYYY');
        return ({ id: formattedDay, text: capitalize(formattedDay) });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-workload-plan-modal__day-to-duplicate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__label {
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }
}
</style>
