<template>
  <SkOroraDialog
    id="shop-form__congratulation-modal"
    is-artwork
    :icon-options="{ name: 'ShopV2Icon', color: $skColors.skBlue50 }"
    :is-closable="false"
    :is-dismissable="false"
    :title="$t('organisation_settings.shop_form.congratulation_modal.title')"
    size="medium"
    @hidden="handleHide"
    @show="handleShow"
  >
    <template #body>
      <main class="congratulation-modal sk-text-large-regular">
        {{ $t('organisation_settings.shop_form.congratulation_modal.content') }}
      </main>
    </template>
    <template #footer>
      <footer class="congratulation-modal__footer">
        <SkOroraButton
          variant="secondary"
          @click="handleNewShop"
        >
          {{ $t('organisation_settings.shop_form.congratulation_modal.add_shop') }}
        </SkOroraButton>
        <SkOroraButton @click="handleSkelloRedirection">
          {{ $t('organisation_settings.shop_form.congratulation_modal.submit') }}
        </SkOroraButton>
      </footer>
    </template>
  </SkOroraDialog>
</template>

<script>
import { staticRootUrl } from '@config/env';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

const CONFETTI_OPTIONS = {
  defaultDropRate: 8,
  particlesPerFrame: 1,
  particles: [
    {
      type: 'image',
      url: `${staticRootUrl}/images/blue_tahjxr.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/red_shpawh.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/pink_idvj0f.png`,
      size: 20,
    },
    {
      type: 'image',
      url: `${staticRootUrl}/images/Vector_egy69s.png`,
      size: 20,
    },
  ],
};

export default {
  name: 'CongratulationModal',
  methods: {
    handleShow() {
      this.$confetti.start(CONFETTI_OPTIONS);
    },
    handleHide() {
      this.$confetti.stop();
    },
    handleNewShop(event) {
      this.$skAnalytics.track('add_shop_btn_click', { source: 'global settings' });

      this.$emit('new-shop', event);
    },
    handleSkelloRedirection() {
      this.$confetti.stop();

      this.$emit('back-to-skello');
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'shop-form__congratulation-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.congratulation-modal {
  padding: 8px 24px;
  color: $sk-grey-50;
}

.congratulation-modal__footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px 24px 0;

  .sk-button + .sk-button {
    margin-left: 12px;
  }
}
</style>
