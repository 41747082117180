<template>
  <div
    v-show="visible"
    class="dashboard-section dashboard-flip-cards square-1"
  >
    <div class="flip-card">
      <div class="face front trials-front">
        <div />
        <div class="number">
          {{ trialsEndingSoonLength }}
        </div>
        <div class="subtitle">
          {{ dashboard_trial_period_subtitle }}
          {{ actualDateDisplay }}
        </div>
      </div>
      <div class="face back">
        <div class="dashboard-title dtitle-spread noflip">
          <h2>
            {{ dashboard_trial_period_subtitle }}
            {{ actualDateDisplay }}
          </h2>
          <div class="dt-rpart">
            <div id="turnover-btn">
              <SkCircleButton
                icon="ChevronLeftV2Icon"
                size="small"
                bordered
                @click="trialsTime('prev')"
              />

              <SkCircleButton
                icon="ChevronRightV2Icon"
                size="small"
                bordered
                @click="trialsTime('next')"
              />
            </div>
          </div>
        </div>
        <div class="dashboard-content d-limited-content">
          <div
            v-if="trialsEndingSoonLength === 0"
            class="d-no-result"
          >
            {{ dashboard_trial_period_none }}
          </div>
          <ul
            v-else
            class="trials-list"
          >
            <li
              v-for="trialsEndingSoon in trialsEndingSoons"
              :key="trialsEndingSoon.id"
              class="trial-item noflip"
            >
              <a
                :href="trialsEndingSoon.link"
                class="trial-link noflip"
                target="blank"
                data-seg="DboardTrialPeriod"
              >
                <div class="monogram-wrapper">
                  <div
                    :style="{ backgroundColor: trialsEndingSoon.user_color }"
                    class="user-monogram sidebar"
                  >
                    {{ trialsEndingSoon.monogram }}
                  </div>
                </div>
                <div class="flex-grow">
                  <div class="trial-user-name">
                    {{ trialsEndingSoon.name }}
                  </div>
                  <div class="trial-date">
                    {{ trialsEndingSoon.endDate }}
                  </div>
                </div>
                <div class="trial-eye">
                  <OpenEyeV2Icon
                    height="100%"
                    width="100%"
                    fill="#EEE"
                  />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

export default {
  name: 'TrialsEndsCard',
  data() {
    return {
      visible: true,
      trialsEndingSoons: null,
      trialsEndingSoonLength: null,
      actualDate: null,
      actualDateDisplay: null,
      dashboard_trial_period_subtitle: this.$t('home_dashboard.trial_period.subtitle'),
      dashboard_trial_period_none: this.$t('home_dashboard.trial_period.none'),
      get_trials_ending_path: '/get_trials_ending',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    initialLoad() {
      this.actualDate = skDate().startOf('month').subtract(1, 'months');
      this.trialsTime('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'TrialsEndsCard');
    },
    trialsTime(statut) {
      if (statut === 'prev') {
        this.actualDate.subtract(1, 'months');
      } else if (statut === 'next') {
        this.actualDate.add(1, 'months');
      }

      this.actualDateDisplay = this.actualDate.locale(this.$i18n.locale).format('MMMM YYYY').toLowerCase();

      const params = {
        start_date: this.actualDate.format('YYYY-MM-DD'),
      };

      // View all is handled by not passing the shop id
      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.get_trials_ending_path, { params })
        .then(response => {
          const data = response.data;
          this.trialsEndingSoonLength = data.trialsEndingSoonLength;
          this.trialsEndingSoons = data.trialsEndingSoons;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
