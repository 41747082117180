<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#F3F9EC"
      border-color="#345222"
      data-test="column__contract-data__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__contract-data__opened"
    >
      <div
        class="column__header contract-data-title"
        data-test="column__contract-data__close-button"
        @click="closeColumn"
      >
        {{ title }}

        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
      </div>

      <table>
        <thead class="column__title-wrap">
          <th v-if="isIdentityNumberEnabled">
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.identity_number') }}
            </div>
          </th>
          <th v-if="visibleSalary">
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.pay_identification_number') }}
            </div>
          </th>
          <th>
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.contract_type') }}
            </div>
          </th>
          <th>
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.poste_name') }}
            </div>
          </th>
          <th>
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.start_date') }}
            </div>
          </th>
          <th>
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.end_date') }}
            </div>
          </th>
          <th>
            <div class="column__title column__title--open">
              {{ this.$t('reports.contract_data.archived_note') }}
            </div>
          </th>
        </thead>

        <tbody>
          <template v-for="employeeInfos in employeesInfos">
            <tr
              v-for="contractInfos in employeeInfos.contracts_infos"
              :key="contractInfos.contract.id"
              class="column__cell-wrap"
            >
              <!-- Numéro d’identification -->
              <td
                v-if="isIdentityNumberEnabled"
                class="column__cell column__cell--open"
              >
                {{ employeeInfos.user_infos.identity_number }}
              </td>
              <!-- Matricule -->
              <td
                v-if="visibleSalary"
                class="column__cell column__cell--open"
              >
                {{ employeeInfos.user_infos.pay_identification_number }}
              </td>

              <!-- Type de contrat -->
              <td class="column__cell column__cell--open">
                {{ contractTypeName(contractInfos) }}
              </td>

              <!-- Intitulé du poste -->
              <td class="column__cell column__cell--open">
                {{ contractInfos.contract.poste_name }}
              </td>

              <!-- Date d'embauche -->
              <td class="column__cell column__cell--open">
                {{ contractStartDate(contractInfos, employeeInfos.user_infos) }}
              </td>

              <!-- Date de fin de contrat -->
              <td class="column__cell column__cell--open">
                {{ contractEndDate(contractInfos) }}
              </td>

              <!-- Note de sortie -->
              <td class="column__cell column__cell--open column__cell--text_contained">
                {{ employeeInfos.user_infos.archive_note }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import { getContractTypeI18nKey } from '@app-js/users/shared/utils/contract_types_helper';
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'ContractDataColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    visibleSalary: {
      type: Boolean,
      default: false,
    },
    currentShop: {
      type: Object,
      default: () => {},
    },
    contractTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.$t('reports.contract_data.title'),
    };
  },
  computed: {
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    isIdentityNumberEnabled() {
      return this.checkFeatureFlag('FEATURE_ID_NUMBER');
    },
  },
  methods: {
    // If there is no start_date on contract, the fallback is user created_at at 8AM
    contractStartDate(contractInfos, userInfos) {
      if (contractInfos.contract.start_date) {
        return skDate(contractInfos.contract.start_date).format('DD/MM/YY');
      }

      return skDate(userInfos.fallback_start_date).format('DD/MM/YY');
    },
    contractEndDate(contractInfos) {
      return contractInfos.contract.end_date ?
        skDate(contractInfos.contract.end_date).format('DD/MM/YY') :
        '';
    },
    contractTypeName(contractInfos) {
      const contractTypeId = contractInfos.contract.contract_type_id;
      if (!contractTypeId) { // when an org is created the org owner doesn't have a contract.
        return '';
      }

      const contractType = this.contractTypes.find(ct => Number(ct.id) === contractTypeId);
      if (!contractType) return '';

      return this.$t(getContractTypeI18nKey(contractType.country, contractType.key)) || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-data-title {
  border-top: 4px solid #345222;
}

.column__cell--text_contained {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.column__title--open {
  height: 66px;
}
</style>
