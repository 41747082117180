<template>
  <ul
    v-show="visible"
    class="over-hours-plannings-list list-unstyled"
  >
    <li
      v-for="(overHours, index) in shopsOverHours"
      :key="index"
      class="over-hours-planning-item"
    >
      <a
        :href="overHours.link"
        class="over-hours-planning-link"
        target="_blank"
        data-seg="DboardOverHoursPlanning"
      >
        <div class="flex-center">
          <div class="over-hours-badge" />
          <div class="over-hours-planning-name">
            {{ overHours.name }}
          </div>
        </div>
        <div>
          <div class="over-hours-planning-hours">
            <span class="bold-hours">{{ overHours.value }}</span> {{ $t('home_dashboard.chart.over_hours.shops.over_time') }}
          </div>
          <div class="over-hours-planning-users">
            {{ $tc('chart.over_hours.shops.per_n_employees', overHours.numberEmployees) }}
          </div>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import { mapState } from 'vuex';

export default {
  name: 'OverHoursShopList',
  data() {
    return {
      visible: true,
      shopsOverHours: [],
      shops_over_hours_path: '/shops_over_hours',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.loadOverHours);
  },
  methods: {
    handleReloadDone() {
      this.$emit('data-loaded');
    },
    loadOverHours() {
      // for now we don't want to call api when we are on view all
      //   because it doesnt work in v2 anyway
      // and it could be a very big performance problem on huge organisations
      if (!this.isSingleShop) {
        this.handleReloadDone();
        return;
      }
      httpClient.get(this.shops_over_hours_path)
        .then(response => {
          this.shopsOverHours = response.data;
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
</style>
