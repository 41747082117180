<template>
  <SkSelectV2
    v-model="selectedUserIds"
    :disabled="disableSelect"
    :options="userOptions"
    :group-options="teamOptions"
    :label="$t('reports.select_employees.label')"
    :search-placeholder="$t('actions.search')"
    :no-results-label="$t('reports.select_employees.no_search_results')"
    multi
    append-to-body
    no-confirm
    :testing-options="selectTestingOptions"
  >
    <template #group-count="{ count }">
      {{ $tc('reports.select_employees.employee_count', count) }}
    </template>
    <template #selected-option="{ value }">
      {{ $tc('reports.select_employees.employee_count', value.length) }}
    </template>
  </SkSelectV2>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'SelectEmployees',
  props: {
    employeesInfos: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedUserIds: [],
      selectTestingOptions: {
        dropdown: 'select-employees__dropdown',
        selectValue: 'select-employees__select-value',
      },
    };
  },
  computed: {
    ...mapState('shopTeams', ['teams']),
    disableSelect() {
      return this.userOptions.length === 0;
    },
    optionHeaders() {
      const optionHeaders = [
        { id: 'all', text: this.$t('reports.select_employees.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];

      if (this.teams.length !== 0) {
        optionHeaders.push({ id: 2, text: this.$t('reports.select_employees.teams'), matchKey: null });
      }

      return optionHeaders;
    },
    teamOptions() {
      const teams = this.teams.reduce((accumulatorTeams, currentTeam) => {
        // don't show teams without users in it
        const usersInTeam = this.users.map(user => user.id).filter(userId => (
          currentTeam.relationships.users.map(teamUser => parseInt(teamUser.id, 10))
            .includes(userId)
        ));

        if (usersInTeam.length) {
          accumulatorTeams.push({
            id: currentTeam.id,
            text: currentTeam.attributes.name,
            matchKey: 'teamIds',
            matchVal: currentTeam.id,
            usersIds: usersInTeam,
          });
        }
        return accumulatorTeams;
      }, []);

      teams.push({ id: 'employees', text: this.$t('navbar.employees'), matchKey: null });

      // set group team options with his header
      return this.optionHeaders.concat(teams);
    },
    users() {
      return Object.values(this.employeesInfos).map(user => user.user_infos).filter(Boolean);
    },
    userOptions() {
      return this.users.map(user => {
        // select all user
        const teamsIds = [];
        this.teamOptions.forEach(team => {
          if (Array.isArray(team.usersIds) && team.usersIds.includes(user.id)) {
            teamsIds.push(team.id);
          }
        });

        return {
          id: user.id,
          text: `${user.first_name} ${user.last_name}`,
          teamIds: teamsIds,
        };
      });
    },
  },
  watch: {
    selectedUserIds() {
      this.$emit('set-selected-employees', this.selectedUserIds);
    },
  },
  methods: {
    resetData() {
      this.selectedUserIds = this.userOptions.map(user => user.id);
    },
  },
};
</script>
