<template>
  <div>
    <!-- Planning data is loaded -->
    <div
      v-if="isPlanningDataCompleted"
      class="attendance-sheets-esignature__panel-main"
    >
      <div class="attendance-sheets-esignature-panel__select-line__description">
        <div class="select-line__description-text">
          {{ $t('attendance_sheets_esignature.send_step.info.description') }}
          <SkOroraLink
            :href="$t('attendance_sheets_esignature.send_step.info.learn_more_link')"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ $t('attendance_sheets_esignature.send_step.info.learn_more') }}
          </SkOroraLink>
        </div>
      </div>
      <div class="attendance-sheets-esignature-panel__info-box align-items-center">
        <div class="info-box__icon">
          <CircledIIcon
            fill="#2b66fe"
            width="24"
            height="24"
          />
        </div>
        <div class="info-box__label">
          {{ $t('attendance_sheets_esignature.send_step.info.sub_description') }}
        </div>
      </div>
      <div class="attendance-sheets-esignature-panel__form-row">
        <span class="form-row__main-label align-items-center">
          {{ $t('attendance_sheets_esignature.send_step.select.publish_to.label') }}
        </span>
        <br>
        <SkSelectV2
          v-model="selectedUserIds"
          :options="userOptions"
          :group-options="teamOptions"
          :disabled-options="disabledSelectUserIds"
          :label="$t('plannings.toolbar.modal.shared.select.employees')"
          :search-placeholder="$t('actions.search')"
          :no-results-label="$t('plannings.toolbar.modal.shared.select.no_search_results')"
          searchleft-icon-name="MagnifyingGlassIcon"
          multi
        >
          <template #group-count="{ count }">
            {{ $tc('plannings.toolbar.modal.shared.select.employee_count', count) }}
          </template>
          <template #selected-option="{ value }">
            {{ $tc('plannings.toolbar.modal.shared.select.employee_count', value.length) }}
          </template>
          <template #option="{ option }">
            <div v-tooltip="userTooltip(option)">
              <div> {{ option.text }} </div>
            </div>
          </template>
        </SkSelectV2>
      </div>
    </div>
    <!-- Planning data is loading -->
    <LoaderContainer
      v-else
      :is-side-panel="true"
    />
    <div class="attendance-sheets-esignature-panel-footer">
      <SkOroraButton
        v-track="'esignature_schedule_panel_cancel'"
        variant="secondary"
        data-test="attendance-sheets-esignature-panel__cancel"
        @click="handleClose"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        v-track="'esignature_schedule_panel_submit'"
        data-test="es-send"
        :disabled="!isPlanningDataCompleted || selectedUserIds.length === 0"
        :loading="isSending"
        @click="handleSubmit"
      >
        {{ $t('attendance_sheets_esignature.send_step.actions.submit') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'SendStep',
  components: {
    LoaderContainer,
  },
  props: {
    isPlanningDataCompleted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedUserIds: [],
      selectUserIdsWithoutEmail: [],
      selectUserIdsWithoutShift: [],
      isSending: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['monday', 'sunday']),
    ...mapGetters('employees', ['fullName']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('shopTeams', ['teams']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsShifts', ['shifts']),
    ...mapState('planningsState', ['weeklyAttendanceSheetSignatures']),
    disabledSelectUserIds() {
      return [
        ...this.selectUserIdsWithoutEmail,
        ...this.selectUserIdsWithoutShift,
      ];
    },
    userOptions() {
      return this.users.map(user => ({
        id: user.id,
        text: this.fullName(user),
        teamIds: user.relationships.teams.data.map(team => team.id),
        not_sent: [!this.weeklyAttendanceSheetSignatures
          .find(signatureRecord => signatureRecord.employeeId === user.id)],
      }));
    },
    optionHeaders() {
      const optionHeaders = [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        {
          id: 'notSent',
          text: this.$t('attendance_sheets_esignature.send_step.select.not_sent_option'),
          matchKey: 'not_sent',
          matchVal: true,
        },
        { id: 'separator', separator: true, matchKey: null },
      ];
      if (this.teams.length !== 0) {
        optionHeaders.push({ id: 'teams', text: this.$t('plannings.toolbar.modal.shared.select.teams'), matchKey: null });
      }
      return optionHeaders;
    },
    teamOptions() {
      const teams = this.teams.map((team, index) => ({
        id: team.id,
        text: team.attributes.name,
        matchKey: 'teamIds',
        matchVal: team.id,
      }));
      teams.push({ id: 'employee', text: this.$t('navbar.employees'), matchKey: null });
      return this.optionHeaders.concat(teams);
    },
    employeeWithoutEmailTooltip() {
      return this.$t('attendance_sheets_esignature.send_step.select.no_email_tooltip');
    },
    noShiftTooltip() {
      return this.$t('attendance_sheets_esignature.send_step.select.no_shift_tooltip');
    },
    selectUserIdsWithSignatureSent() {
      return this.weeklyAttendanceSheetSignatures.map(
        signatureRecord => signatureRecord.employeeId,
      );
    },
  },
  mounted() {
    // Sunday shifts from previous week shows up in the shifts store.
    // To avoid breaking something planning-side, we check that the shifts start on monday or after.
    const filteredShifts =
      this.shifts.filter(
        shift => skDate.utc(shift.attributes.startsAt).isSameOrAfter(skDate.utc(this.monday)) &&
          skDate.utc(shift.attributes.startsAt).isSameOrBefore(skDate.utc(this.sunday).endOf('day')),
      );

    this.selectUserIdsWithoutEmail = this.users.filter(
      user => !user.attributes.email,
    ).map(user => user.id);

    this.selectUserIdsWithoutShift = this.users.filter(
      user => filteredShifts
        .find(shift => String(shift.attributes.userId) === user.id) === undefined,
    ).map(user => user.id);

    this.selectedUserIds =
      this.users
        .filter(
          user => !this.selectUserIdsWithSignatureSent.includes(user.id) &&
            !this.disabledSelectUserIds.includes(user.id),
        ).map(user => user.id);
  },
  methods: {
    ...mapMutations('planningsState', ['addWeeklyAttendanceSheetSignatures']),
    sendAttendanceSheetsForSignature() {
      const params = {
        shop_id: this.currentShop.id,
        date: this.monday,
        user_ids: this.selectedUserIds,
      };
      return httpClient.post('/v3/api/request_esignatures/bulk_create', params);
    },
    isEmployeeWithoutEmail(option) {
      return this.selectUserIdsWithoutEmail.includes(option.id);
    },
    isEmployeeWithoutShifts(option) {
      return this.selectUserIdsWithoutShift.includes(option.id);
    },
    userTooltip(option) {
      if (this.isEmployeeWithoutShifts(option)) {
        return this.noShiftTooltip;
      }
      if (this.isEmployeeWithoutEmail(option)) {
        return this.employeeWithoutEmailTooltip;
      }
      return '';
    },
    updateWeeklyAttendanceSheetSignatures() {
      const { id: shopId } = this.currentShop;
      const { organisationId } = this.currentShop.relationships.clusterNode.attributes;

      // We're adding this custom signatures to the store since there's a delay between
      // the moment that the user sends the timesheet to sign and the moment that the record
      // is created in the svc DB, which is what lets the user know that the process is started.
      // For this reason, we're creating this "temporal" records which will be cleaned as soon
      // as the svc returns us their states.
      const customSignatures = this.users.reduce((accumulator, currentUser) => {
        if (!this.selectedUserIds.includes(currentUser.id)) {
          return accumulator;
        }

        const customSignature = {
          employeeId: currentUser.id,
          signatureStatus: 'awaiting-news-from-esignature-svc',
          shopId,
          organisationId,
          type: 'ATTENDANCESHEET',
          isCustom: true,
        };

        accumulator.push(customSignature);

        return accumulator;
      }, []);

      this.addWeeklyAttendanceSheetSignatures(customSignatures);
    },
    handleSubmit() {
      this.isSending = true;
      this.sendAttendanceSheetsForSignature()
        .then(response => {
          this.$skToast({
            message: this.$tc(
              'attendance_sheets_esignature.send_step.actions.success',
              this.selectedUserIds.length,
              { count: this.selectedUserIds.length },
            ),
            variant: 'success',
          });
          this.updateWeeklyAttendanceSheetSignatures();
          this.selectedUserIds = [];
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('attendance_sheets_esignature.send_step.actions.error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.$emit('close');
          this.isSending = false;
        });
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.attendance-sheets-esignature__panel-main {
  // 100% of screen vertical height minus the space taken by other components in the panel
  min-height: calc(100vh - 220px);
  height: 100px;
  padding: 0 24px;
}

.attendance-sheets-esignature-panel__select-line__description {
  padding: 24px 0 0;
  display: flex;

  div.select-line__description-text {
    line-height: 22px;
  }
}

.attendance-sheets-esignature-panel__info-box {
  margin: 24px 0;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;

  .info-box__icon {
    margin: 22px 0;
    margin-left: 22px;
    margin-right: 18px;
  }

  .info-box__label {
    padding-right: 32px;
    color: $sk-grey;
  }
}

//!important to override margin: 0; on sk-subtitle--medium
.attendance-sheets-esignature-panel__header__subtitle {
  margin-bottom: 16px !important;
}

.attendance-sheets-esignature-panel__form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.attendance-sheets-esignature-panel-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 60px;
  padding: 0 24px 14px;
  margin-top: auto;
}

.form-row__main-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.align-items-center {
  display: flex;
  align-items: center;
}
</style>
