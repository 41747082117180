<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="21"
    height="12"
    viewBox="0 0 21 12"
  >
    <path
      d="M10.6 1.4L14.2 5H0V7H14.2L10.6 10.6L12 12L18 6L12 0L10.6 1.4ZM19 0V12H21V0H19Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 21px; // ie11 support
  width: initial;
  height: 12px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 21px; // edge support
    height: 12px; // edge support
  }
}
</style>
