<template>
  <!-- eslint-disable max-len -->
  <SkModalV2
    id="createPosteModal"
    ref="createPosteModal"
    :title="$t('plannings.table.create_poste_modal.title')"
    :submit-button-label="$t('actions.create')"
    :submit-loading="submitLoading"
    @cancel.prevent="handleClose"
    @close.prevent="handleClose"
    @submit.prevent="handleCreate"
  >
    <template #body>
      <SkModalSection
        border-bottom="none"
      >
        <table class="create-poste-modal__table">
          <tr class="create-poste-modal__table__row create-poste-modal__table__row--headers">
            <th class="create-poste-modal__table__cell-color">
              <span class="create-poste-modal__header">
                {{ $t('shop_settings.tabs.shop_postes.table.headers.color') }}
              </span>
            </th>
            <th class="create-poste-modal__table__cell-poste_name">
              <span class="create-poste-modal__header">
                {{ $t('plannings.table.create_poste_modal.input.poste_name.label') }}
              </span>
            </th>
            <th
              v-if="pauseTimeInPercentage"
              class="create-poste-modal__table__cell-switch"
            >
              <span class="create-poste-modal__header">
                {{ $t('plannings.table.create_poste_modal.switch_header') }}
              </span>
            </th>
            <th class="create-poste-modal__table__cell-pause-time">
              <span class="create-poste-modal__header">
                {{ $t('shop_settings.tabs.shop_postes.table.headers.pause_time') }}
              </span>
            </th>
          </tr>
          <tr class="create-poste-modal__table__row">
            <td class="create-poste-modal__table__cell-color">
              <ColorPicker v-model="newShopPoste.color" />
            </td>
            <td class="create-poste-modal__table__cell-poste_name">
              <SkDropdown
                ref="createPosteModalSuggestionsDropdown"
                placement="bottom"
                trigger="click"
              >
                <template #anchor>
                  <SkInput
                    ref="posteNameInput"
                    v-model="newShopPoste.name"
                    :label="$t('shop_settings.tabs.shop_postes.table.fields.name.label')"
                    :errored="isErroredName"
                    :error-message="$t('shop_settings.tabs.shop_postes.table.fields.name.error_message')"
                    @focus="handleFocus"
                    @blur="handleBlur"
                  />
                </template>
                <template #menu>
                  <div class="organisation-postes-suggestions__menu">
                    <div
                      v-for="posteName in postesSuggestions"
                      :key="posteName"
                      class="organisation-postes-suggestions__row"
                      @click="selectPosteName(posteName)"
                    >
                      {{ posteName }}
                    </div>
                  </div>
                </template>
              </SkDropdown>
            </td>
            <td
              v-if="pauseTimeInPercentage"
              class="create-poste-modal__table__cell-color"
            >
              <SkSwitch v-model="newShopPoste.percentage_pause_time" />
            </td>
            <td class="create-poste-modal__table__cell-pause-time">
              <SkInputGroup
                :errored="erroredPauseTimeUpdate"
                :error-message="errorPauseTimeMessage"
              >
                <SkInput
                  v-model="pauseTime"
                  type="number"
                />
                <template #append>
                  <span v-if="newShopPoste.percentage_pause_time">
                    %
                  </span>
                  <span v-else>
                    {{ $t('dates.minutes') }}
                  </span>
                </template>
              </SkInputGroup>
            </td>
          </tr>
        </table>
      </SkModalSection>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import { SHOP_POSTES_COLORS } from '@app-js/shared/constants/colors';
import ColorPicker from '@app-js/shop_settings/pages/Postes/ColorPicker';

export default {
  name: 'CreatePosteModal',
  components: { ColorPicker },
  data() {
    return {
      shift: null,
      newShopPoste: {
        name: null,
        color: '',
        percentage_pause_time: false,
        pause_time: 0,
      },
      isNameFocused: false,
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('shopPostes', ['loadingCreate', 'postesSuggestions']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('convention', ['convention']),
    ...mapState('config', ['config']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    pauseTime: {
      get() {
        return this.newShopPoste.pause_time;
      },
      set(newValue) {
        if (Number(newValue) < 0) {
          this.newShopPoste.pause_time = 0;
          return;
        }

        if (this.newShopPoste.percentage_pause_time && Number(newValue) > 100) {
          this.newShopPoste.pause_time = 100;
          return;
        }

        this.newShopPoste.pause_time = newValue;
      },
    },
    pauseTimeInPercentage() {
      if (!this.convention?.attributes?.name) return false;
      return this.config.convention_percentage_pause_time.includes(
        this.convention.attributes.name,
      );
    },
    errorPauseTimeMessage() {
      return this.$t('plannings.table.create_poste_modal.input.pause_time.error');
    },
    erroredPauseTimeUpdate() {
      return this.newShopPoste.pause_time === '';
    },
    isErroredName() {
      return !this.newShopPoste.name && !this.isNameFocused;
    },
    erroredForm() {
      return (
        this.erroredPauseTimeUpdate ||
        this.isErroredName
      );
    },
  },
  created() {
    if (!this.convention?.attributes?.name) this.fetchConvention(this.currentShop.id);
    this.listenOnRoot('init-create-poste-modal', (event, props) => {
      this.$emit('update-modal-state', true);
      this.shift = props.shift;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'createPosteModal');
      this.newShopPoste.color = this.findFirstNotUsedColor();
      this.$nextTick(() => {
        this.$refs.posteNameInput.focus();
      });
    });
  },
  methods: {
    ...mapActions('shopPostes', ['fetchPostesSuggestions']),
    ...mapActions('planningsPostes', ['createPoste']),
    ...mapActions('convention', ['fetchConvention']),
    findFirstNotUsedColor() {
      const usedColors = this.postes.map(poste => poste.attributes.color);
      const availableColors = SHOP_POSTES_COLORS.filter(color => !usedColors.includes(color));
      return availableColors[Math.floor(Math.random() * (availableColors.length - 1))] ||
        SHOP_POSTES_COLORS[0];
    },
    selectPosteName(posteName) {
      this.newShopPoste.name = posteName;
      this.$refs.createPosteModalSuggestionsDropdown.$refs.popper.doClose();
    },
    handleFocus() {
      this.isNameFocused = true;
      this.fetchPostesSuggestions({ shopId: this.navContext.shopId });
    },
    handleBlur() {
      this.isNameFocused = false;
    },
    resetValues() {
      Object.assign(this.$data, this.$options.data());
    },
    handleClose() {
      this.$emit('update-modal-state', false);
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'createPosteModal');
      this.resetValues();
    },
    async handleCreate() {
      if (this.erroredForm) return;
      this.submitLoading = true;

      try {
        const response = await this.createPoste({
          shopId: this.currentShop.id, poste: this.newShopPoste,
        });
        this.$skToast({
          message: this.$t('shop_settings.tabs.shop_postes.table.actions.create.success_message'),
          variant: 'success',
        });
        this.$emit('create-poste', response.data.id);
        this.handleClose();
      } catch (e) {
        this.$skToast({
          message: this.$t('shop_settings.tabs.shop_postes.table.actions.create.error_message'),
          variant: 'error',
        });
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-postes-suggestions__spinner {
  width: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.organisation-postes-suggestions__menu {
  position: absolute;
  background: white;
  width: 260px;
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  right: -75px;
}

.organisation-postes-suggestions__row {
  height: 35px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $sk-grey-10;
  }
}

.manage_note_modal__label_container {
  display: flex;
  flex-direction: column;
  min-width: 120px;
  margin-right: 100px;
}

.manage_note_modal__title {
  font-weight: $fw-semi-bold;
}

.manage_note_modal__subtitle {
  color: $sk-grey;
}

.manage_note_modal__body {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}

.create-poste-modal__table__row {
  display: flex;
  gap: 8px;

  &--headers {
    border-bottom: 1px solid $sk-grey-10;
    padding-bottom: 10px;
  }
}

.create-poste-modal__table__cell-poste_name {
  flex: 1;
}

.create-poste-modal__table__cell-pause-time {
  width: 120px;
}

.create-poste-modal__table__cell-color {
  width: 65px;
}

.create-poste-modal__table {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-poste-modal__table__cell-switch {
  width: 60px;
}

.create-poste-modal__header {
  font-size: 12px;
  font-weight: 600;
  color: $sk-grey;
}
</style>

<style lang="scss">
.create-poste-modal__table__cell-color .shop-poste-color__menu {
  position: absolute;
  left: -90px;
}
</style>
