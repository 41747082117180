<template>
  <div class="upsell-esignature-banner__wrapper">
    <div class="upsell-esignature-banner__illustration">
      <UpsellBannerIllustration />
    </div>

    <div class="upsell-esignature-banner__content">
      <h3 class="sk-header--3 upsell-esignature-banner__title">
        {{ $t(`upsell_esignature_banner.${source}.title`) }}
      </h3>
      <p class="sk-subtitle--medium upsell-esignature-banner__subtitle">
        {{ $t(`upsell_esignature_banner.${source}.subtitle`) }}
      </p>
      <p>
        <a
          role="button"
          class="upsell-esignature-banner__cta"
          @click.prevent="redirectToUpsellModal"
        >
          {{ $t(`upsell_esignature_banner.${source}.cta`) }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UpsellBannerIllustration from './UpsellBannerIllustration.vue';

export default {
  name: 'UpsellEsignatureBanner',
  components: { UpsellBannerIllustration },
  props: {
    source: {
      type: String,
      required: true,
      validator: value => ['badging', 'print', 'history'].includes(value),
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    shopId() {
      return this.currentShop.id === 'all' ? this.currentOrganisation.attributes.firstShopId : this.currentShop.id;
    },
  },
  methods: {
    redirectToUpsellModal() {
      this.$skAnalytics.track('esignature_upsell_banner_cta_click', { source: this.source });
      this.$router.push({ name: 'plannings_weeks_employees', query: { open_esignature_upsell: 'true' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-esignature-banner__wrapper {
  width: 100%;
  display: flex;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
}

.upsell-esignature-banner__illustration {
  margin: 24px 7px 24px 15px;
}

.upsell-esignature-banner__content {
  margin-top: 24px;
  padding-right: 24px;
}

.upsell-esignature-banner__title {
  margin-bottom: 8px;
}

.upsell-esignature-banner__subtitle {
  line-height: 18px;
  margin-bottom: 8px;
}

.upsell-esignature-banner__cta {
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
  line-height: 18px;
  color: $sk-blue;
  margin-bottom: 24px;
  cursor: pointer;

  &:hover {
    color: $sk-royal-blue;
  }
}
</style>
