<template>
  <TitlesColumn>
    <TitlesColumnCell>
      <div class="rtt-counter__titles-column__acquisition">
        <div>
          {{ $t('employees.tabs.counters.rtt_counter.table.acquisition') }}
        </div>
        <CircledINoFillIcon
          v-tooltip.right="acquisitionTooltip"
          class="rtt-counter__titles-column__acquisition__icon"
        />
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div>
        {{ $t('employees.tabs.counters.rtt_counter.table.taken') }}
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div class="rtt-counter__titles-column__manual-changes">
        <div>
          {{ $t('employees.tabs.counters.rtt_counter.table.manual_changes') }}
        </div>
        <div class="rtt-counter__titles-column__manual-changes__subtitle">
          {{ $t('employees.tabs.counters.rtt_counter.table.manual_changes_subtitle') }}
        </div>
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div>
        {{ $t('employees.tabs.counters.rtt_counter.table.balance') }}
      </div>
    </TitlesColumnCell>
  </TitlesColumn>
</template>

<script>
import {
  TitlesColumn,
  TitlesColumnCell,
} from '../../shared/components';

export default {
  name: 'RttCounterTitlesColumn',
  components: { TitlesColumn, TitlesColumnCell },
  props: {
    periodOwedRtt: {
      type: Number,
      required: true,
    },
  },
  computed: {
    acquisitionTooltip() {
      return this.$t(
        'employees.tabs.counters.rtt_counter.table.acquisition_tooltip',
        { owed_rtt: this.periodOwedRtt },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rtt-counter__titles-column__acquisition {
  display: flex;
  align-items: center;

  &__icon {
    margin-left: 8px;
  }
}

.rtt-counter__titles-column__manual-changes {
  display: flex;
  flex-direction: column;

  &__subtitle {
    color: $sk-warning;
    font-size: $fs-text-xs;
  }
}
</style>
