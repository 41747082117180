<template>
  <div id="holidays-counter-table">
    <SkCircleButton
      v-if="displayScrollLeftButton"
      icon="ChevronLeftV2Icon"
      class="holidays-counter-table__scroll-buttons__left"
      @click="scrollLeft"
    />
    <SkCircleButton
      v-if="displayScrollRightButton"
      icon="ChevronRightV2Icon"
      class="holidays-counter-table__scroll-buttons__right"
      @click="scrollRight"
    />
    <div ref="scrollableContent">
      <table
        ref="scrollableContentTable"
        :class="tableClass"
      >
        <tr>
          <th />
          <td
            v-for="holidaySettings in sortedHolidaysSettings"
            :key="holidaySettings.id"
            class="user-holidays-counter-table__header"
          >
            <div :class="headerTitleClass">
              {{ formattedDate(holidaySettings.attributes.date) }}
            </div>
            <div
              v-tooltip="holidaySettings.attributes.name"
              class="user-holidays-counter-table__header-subtitle text-truncate"
            >
              {{ holidaySettings.attributes.name }}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('holiday_counter.table.row_first_title') }}
          </th>
          <EditableCell
            v-for="holidaySettings in sortedHolidaysSettings"
            :key="holidaySettings.id"
            :holiday-settings="holidaySettings"
            :holidays-settings="sortedHolidaysSettings"
            :disabled="!editable"
            :show-dash="asCanvas"
            type="guaranteed"
          />
        </tr>
        <tr v-if="showShiftsRow">
          <th>
            {{ $t('holiday_counter.table.row_second_title') }} /
            <br>
            <span class="user-holidays-counter-table__row-subtitle">
              {{ $t('holiday_counter.table.row_second_subtitle') }}
            </span>
            <span class="user-holidays-counter-table__row-title-icon">
              <CircledQuestionMarkIcon
                v-tooltip.top="$t('holiday_counter.table.row_hint')"
                width="18"
                height="18"
              />
            </span>
          </th>
          <td
            v-for="holidaySettings in sortedHolidaysSettings"
            :key="holidaySettings.id"
          >
            <div
              v-if="noShiftAndDateInPast(holidaySettings.attributes.date)"
              v-tooltip="$t('holiday_counter.table.cells.no_shift', {
                userName: fullName(employee),
              })"
              class="user-holidays-counter-table__shifts-cell"
            >
              <CircledExclamationMarkIcon fill="#d03e50" />
            </div>
            <div
              v-else
              :class="shiftCellClassFor(holidaySettings.attributes.date)"
            >
              {{ shiftsForDate(holidaySettings.attributes.date) }}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            {{ $t('holiday_counter.table.row_third_title') }}
          </th>
          <EditableCell
            v-for="holidaySettings in sortedHolidaysSettings"
            :key="holidaySettings.id"
            :holiday-settings="holidaySettings"
            :holidays-settings="sortedHolidaysSettings"
            :disabled="isPaidCellDisabled()"
            :show-dash="displayDash(holidaySettings)"
            type="paid"
          />
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
} from '@app-js/shared/constants/shift';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import skDate from '@skello-utils/dates';

import EditableCell from './EditableCell';

export default {
  name: 'HolidaysCounterTable',
  components: { EditableCell },
  props: {
    holidaysSettings: {
      type: Array,
      required: true,
    },
    holidaysShifts: {
      type: Array,
      required: false,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    asCanvas: {
      type: Boolean,
      default: false,
    },
    showShiftsRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      simplebar: null,
      tableWidth: 0,
      scrollableContentWidth: 0,
      leftScroll: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('employees', ['fullName']),

    tableClass() {
      return this.showShiftsRow ?
        'user-holidays-counter-table' :
        'shop-holidays-counter-table';
    },
    headerTitleClass() {
      return {
        'holidays-counter-table__header-title': true,
        'holidays-counter-table__header-title--grayed': !this.showShiftsRow,
      };
    },
    sortedHolidaysSettings() {
      return [...this.holidaysSettings].sort(
        (a, b) => skDate(a.attributes.date) - skDate(b.attributes.date),
      );
    },
    displayScrollLeftButton() {
      return this.leftScroll > 0;
    },
    displayScrollRightButton() {
      if (!this.simplebar) return true;
      const el = this.simplebar.getScrollElement();
      return (this.leftScroll + el.clientWidth) !== el.scrollWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      this.simplebar = new SimpleBar(this.$refs.scrollableContent);
      // listen for manual scroll
      this.simplebar.getScrollElement().addEventListener('scroll', () => {
        this.leftScroll = this.simplebar.getScrollElement().scrollLeft;
      });
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        // init value and refresh displayScroll{Left,Right}Button computed values
        this.leftScroll = 0;
      }, 500);
    });
  },
  methods: {
    formattedDate(date) {
      return skDate(date).format('DD MMM');
    },
    shiftsForDate(date) {
      const shifts = this.holidaysShiftsForDate(date);

      if (!shifts || this.asCanvas) {
        return '-';
      }

      const { workShifts, absenceShifts } = this.workShiftsAndAbsenceShifts(shifts);

      if (workShifts.length > 0) {
        return `${workShifts.reduce((sum, shift) => sum + shift.attributes.holidayDurationInHours, 0).toFixed(2)}h`;
      }
      if (absenceShifts.length > 0) {
        if (this.absenceTypeIs(absenceShifts, 'weekly_rest').length > 0) {
          return this.$t('holiday_counter.table.cells.weekly_rest');
        }
        if (this.absenceTypeIs(absenceShifts, 'public_holiday').length > 0) {
          return this.$t('holiday_counter.table.cells.holiday');
        }
        return this.$t('holiday_counter.table.cells.absence');
      }

      return '-';
    },
    absenceTypeIs(absenceArray, key) {
      return absenceArray.filter(
        absence => absence.relationships.poste.attributes.absenceKey === key,
      );
    },
    shiftCellClassFor(date) {
      const shifts = this.holidaysShiftsForDate(date);
      let isOnlyAbsenceShifts = false;
      if (shifts.length > 0) {
        const { workShifts, absenceShifts } = this.workShiftsAndAbsenceShifts(shifts);

        isOnlyAbsenceShifts = absenceShifts && !absenceShifts.find(
          absence => absence.relationships.poste.attributes.absenceKey === 'weekly_rest',
        ) && workShifts.length === 0;
      }

      return {
        'user-holidays-counter-table__shifts-cell': true,
        'user-holidays-counter-table__shifts-cell--orange': isOnlyAbsenceShifts,
      };
    },
    noShiftAndDateInPast(date) {
      if (this.asCanvas) return false;

      const shifts = this.holidaysShiftsForDate(date);

      return shifts.length > 0 ? false : skDate(date).isBefore(skDate());
    },
    holidaysShiftsForDate(date) {
      return this.holidaysShifts.filter(shift => {
        const { startsAt, endsAt } = shift.attributes;
        date = skDate(date).format('YYYY-MM-DD');

        const firstDayIsHoliday = this.holidaysSettings.find(d => d.attributes.date === skDate(startsAt).utc().format('YYYY-MM-DD'));
        const lastDayIsHoliday = this.holidaysSettings.find(d => d.attributes.date === skDate(endsAt).utc().format('YYYY-MM-DD'));

        const startsAtUtc = skDate(startsAt).utc().format('YYYY-MM-DD');
        const endsAtUtc = skDate(endsAt).utc().format('YYYY-MM-DD');

        if (startsAtUtc !== date && endsAtUtc === date && firstDayIsHoliday && lastDayIsHoliday) {
          return false;
        }
        // We don't want day/half-day absences that ends on a holiday to be included
        const isDayOrHalfDayAbsence = shift.relationships.poste.attributes.absenceType &&
          [ABSENCE_TYPE_DAY, ABSENCE_TYPE_HALF_DAY].includes(shift.attributes.absenceCalculation);

        return startsAtUtc === date ||
          (endsAtUtc === date && skDate(endsAt).utc().format('H:m') !== '0:0' && !isDayOrHalfDayAbsence) ||
          (startsAtUtc === date && endsAtUtc === date);
      });
    },
    isPaidCellDisabled() {
      return !this.editable;
    },
    workShiftsAndAbsenceShifts(shifts) {
      return {
        absenceShifts: shifts.filter(
          shift => shift.relationships.poste.attributes.absenceType,
        ),
        workShifts: shifts.filter(
          shift => !shift.relationships.poste.attributes.absenceType,
        ),
      };
    },
    // We display dash when
    // There is only absences in holidayShifts AND
    // if there is weekly rest where the holiday is not guaranteed
    displayDash(holidaySettings) {
      if (this.asCanvas) return true;

      const shifts = this.holidaysShiftsForDate(holidaySettings.attributes.date);

      if (shifts.length === 0) {
        return false;
      }

      const { workShifts, absenceShifts } = this.workShiftsAndAbsenceShifts(shifts);

      const hasWeeklyRestShifts = absenceShifts.filter(
        absence => absence.relationships.poste.attributes.absenceKey === 'weekly_rest',
      ).length > 0;

      return !(workShifts.length > 0 ||
        (hasWeeklyRestShifts && holidaySettings.attributes.guaranteed));
    },
    scrollLeft() {
      this.simplebar.getScrollElement().scroll({ left: this.leftScroll - 200, behavior: 'smooth' });
    },
    scrollRight() {
      this.simplebar.getScrollElement().scroll({ left: this.leftScroll + 200, behavior: 'smooth' });
    },
    onResize() {
      this.tableWidth = this.$refs.scrollableContentTable.clientWidth;
      this.scrollableContentWidth = this.$refs.scrollableContent.scrollWidth;
    },
  },
};
</script>

<style lang="scss">
// unscoped because td are shared for EditableCell components
#holidays-counter-table {
  position: relative;

  .holidays-counter-table__scroll-buttons__left,
  .holidays-counter-table__scroll-buttons__right {
    position: absolute;
    z-index: 1;
    top: 15px;
    background: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .12);
  }

  .holidays-counter-table__scroll-buttons__left {
    left: 15px;
  }

  .holidays-counter-table__scroll-buttons__right {
    right: 15px;
  }

  table {
    table-layout: fixed;
    border-radius: 6px;
    width: 100%;
    border-spacing: 0;

    &.user-holidays-counter-table {
      background: white;
      border-collapse: separate;

      tr:first-child {
        td {
          border-top: 1px solid #dfe3ec;

          &:last-of-type {
            border-top-right-radius: 6px;
          }
        }

        th:first-child {
          border-top-left-radius: 6px;
          border-top: 1px solid #dfe3ec;
        }
      }

      tr:last-child {
        th:first-child {
          border-bottom-left-radius: 6px;
        }

        td:last-child {
          border-bottom-right-radius: 6px;
        }
      }

      td {
        border-left: 1px solid #dfe3ec;
        border-bottom: 1px solid #dfe3ec;
        height: 60px;
        width: 68px;

        &:last-of-type {
          border-right: 1px solid #dfe3ec;
        }
      }

      th {
        width: 185px;
        height: 60px;
        padding: 0 20px 0 14px;
        border-left: 1px solid #dfe3ec;
        border-bottom: 1px solid #dfe3ec;
        font-weight: normal;
        text-align: left;
        position: relative;
      }
    }

    &.shop-holidays-counter-table {
      background: $sk-grey-5;

      tr {
        border-bottom: 1px solid #dfe3ec;

        &:last-of-type {
          border-bottom: none;
        }
      }

      td {
        border-left: 1px solid #dfe3ec;
        border-right: 1px solid #dfe3ec;
        height: 60px;
        width: 68px;

        &:last-of-type {
          border-right: none;
        }
      }

      th {
        width: 185px;
        height: 60px;
        padding: 0 20px 0 14px;
        border-left: none;
        font-weight: normal;
        text-align: left;
        position: relative;
      }
    }
  }
}

#holidays-counter-table::-webkit-scrollbar {
  display: none;
}

.user-holidays-counter-table__header {
  position: relative;

  &:last-of-type {
    .holidays-counter-table__header-title {
      border-top-right-radius: 6px;
    }
  }
}

.holidays-counter-table__header-title {
  text-align: center;
  width: 101%;
  height: 40px;
  position: absolute;
  left: -2px;
  top: 0;
  right: 0;
  background-color: white;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;

  &--grayed {
    background-color: $sk-grey-5;
  }
}

.user-holidays-counter-table__header-subtitle {
  color: $sk-grey;
  font-size: 11px;
  text-align: center;
  padding-top: 35px;
  padding-left: 5px;
  padding-right: 5px;
}

.user-holidays-counter-table__row-title-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.user-holidays-counter-table__row-subtitle {
  color: $sk-warning;
  max-width: 75px;
}

.user-holidays-counter-table__shifts-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;

  &--orange {
    color: $sk-warning;
  }
}
</style>
