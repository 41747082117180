<template>
  <SkModalSectionV2
    class="transfer-user-select-row"
    border-bottom="none"
  >
    <div class="transfer-user-select-row__inputs">
      <label for="receiver">
        {{ $t('requests.leave_requests.transfer_modal.receiver.label') }}
      </label>
      <SkSelectV2
        v-model="localValue"
        :disabled="isDisabled"
        :options="users"
        :no-results-label="$t('requests.receivers.no_search_results')"
        :label="$t('requests.leave_requests.transfer_modal.select.placeholder')"
        append-to-body
        data-test="transer-user__select-absence__input"
        input-id="receiver"
        name="receiver"
        width="394px"
      />
    </div>
  </SkModalSectionV2>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TransferUserSelectRow',
  props: {
    value: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    selectOption(optionText) {
      this.localValue = this.users.find(({ text }) => text === optionText)?.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.transfer-user-select-row {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  label {
    font-weight: $fw-semi-bold;
    margin-bottom: 0;
  }
}
</style>
