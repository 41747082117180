<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 147 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123.834 3.89306C135.108 10.2669 141.434 25.6207 144.754 41.885C148.042 58.1179 148.324 75.2928 141.466 89.422C134.639 103.583 120.671 114.666 105.358 118.905C90.0124 123.112 73.352 120.475 56.5976 115.483C39.8432 110.459 23.0261 103.112 12.0966 89.6732C1.16713 76.2348 -3.84353 56.6736 3.39061 43.4549C10.6248 30.2048 30.135 23.2658 44.9791 17.5199C59.8545 11.774 70.0324 7.22128 83.2167 3.67327C96.401 0.0938683 112.592 -2.48079 123.834 3.89306Z"
      fill="#f3f3f3"
    />
    <path
      d="M109.048 71.0021C109.818 70.3634 109.974 69.2312 109.391 68.4184C108.145 66.6824 107.111 66.2696 102.781 68.5467L94.1514 73.8214C91.5141 75.2231 84.594 74.8163 81.6084 74.8163C81.6084 74.8163 88.6539 75.2937 85.8424 71.0965C84.4437 69.0094 82.0322 67.8245 79.5203 67.8245H75.6394C73.3771 67.8245 71.1457 67.152 69.34 65.7901C65.1248 62.6126 57.0179 68.8472 57.0179 68.8472V86.7543H70.9458C72.9325 86.7543 74.9171 86.6041 76.881 86.3056L89.8478 84.3338C90.2906 84.3338 90.7204 84.2821 91.1362 84.1895C91.1432 84.1885 91.1491 84.1876 91.1561 84.1866C92.2056 84.0394 93.1647 83.5081 93.9307 82.7749L109.048 71.0021Z"
      fill="#FDC693"
    />
    <path
      d="M52.0435 89.739H57.0176V65.8628H52.0435V89.739Z"
      fill="#317cfc"
    />
    <path
      d="M69.2368 78.796C68.6866 78.796 68.2419 78.3503 68.2419 77.8012C68.2419 75.3091 70.1013 73.8218 73.2162 73.8218H82.884C83.4341 73.8218 83.8788 74.2675 83.8788 74.8166C83.8788 75.3657 83.4341 75.8115 82.884 75.8115H73.2162C70.2316 75.8115 70.2316 77.3087 70.2316 77.8012C70.2316 78.3504 69.7869 78.796 69.2368 78.796Z"
      fill="#F9A571"
    />
    <path
      d="M105.765 56.9097H56.0231L59.5757 61.8839H102.213L105.765 56.9097Z"
      fill="#CAD3DB"
    />
    <path
      d="M80.8941 36.0177C80.344 36.0177 79.8993 35.572 79.8993 35.0228V32.0383C79.8993 31.4892 80.344 31.0435 80.8941 31.0435C81.4443 31.0435 81.889 31.4892 81.889 32.0383V35.0228C81.889 35.572 81.4443 36.0177 80.8941 36.0177Z"
      fill="#CAD3DB"
    />
    <path
      d="M83.8787 33.0331H77.9096C77.3595 33.0331 76.9148 32.5874 76.9148 32.0383C76.9148 31.4892 77.3595 31.0435 77.9096 31.0435H83.8787C84.4289 31.0435 84.8735 31.4892 84.8735 32.0383C84.8735 32.5874 84.4289 33.0331 83.8787 33.0331Z"
      fill="#CAD3DB"
    />
    <path
      d="M102.781 56.9094C102.781 44.8221 92.9815 35.0229 80.8942 35.0229C68.8069 35.0229 59.0077 44.8221 59.0077 56.9094H102.781Z"
      fill="#E6EEF3"
    />
    <path
      d="M65.3896 48.9496C65.2205 48.9496 65.0483 48.9069 64.8912 48.8153C64.4156 48.5397 64.2534 47.9308 64.5301 47.4563C67.9095 41.6276 74.18 38.0063 80.8942 38.0063C81.4444 38.0063 81.8891 38.4521 81.8891 39.0012C81.8891 39.5513 81.4444 39.996 80.8942 39.996C74.8873 39.996 69.2765 43.2373 66.2511 48.4531C66.0671 48.7716 65.7329 48.9496 65.3896 48.9496Z"
      fill="#CAD3DB"
    />
  </svg>
</template>

<script>

export default {
  name: 'MealIcon',
  props: {
    width: {
      type: String,
      default: '167',
    },
    height: {
      type: String,
      default: '141',
    },
  },
};
</script>
