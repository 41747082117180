<template>
  <div class="sk-form-section">
    <h3 class="sk-form-section__title">
      {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.section_title') }}
    </h3>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.header_section.subtitle') }}
    </p>

    <div
      class="row shop-settings__holidays-counter__subtitle-row"
      data-test="holiday-counter"
    >
      <div class="col-6">
        <p class="sk-subtitle--medium">
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.header_section.label') }}
        </p>
      </div>
      <div class="col-2">
        <SkSwitch
          v-if="holidaysCounterEnabled"
          v-model="holidaysCounterActivated"
          :disabled="!canEditHolidaysSettings"
        />
        <UpsellPopover
          v-else
          :before-tag-text="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.header_section.upsell_tooltip_3')"
          :after-tag-text="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.header_section.upsell_tooltip_3')"
          :last-sentence="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.header_section.upsell_tooltip_3')"
        />
      </div>
      <PackTag
        v-if="!holidaysCounterEnabled"
        type="premium"
        shadow
      />
    </div>

    <SkCard
      v-if="!holidaysCounterActivated || !currentShop.attributes.holidaysCounterConfigured"
      :class="holidaysCounterCardClass"
    >
      <h4 class="shop-settings__holidays-counter__title">
        {{ holidaysCounterCardTitle }}
      </h4>
      <div class="shop-settings__holidays-counter__description-container">
        <div
          class="shop-settings__holidays-counter__description-text"
          data-test="holiday-counter__text"
        >
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.content_section.description') }}
        </div>
        <HolidayIcon class="shop-settings__holidays-counter__description-icon" />
      </div>
      <div class="shop-settings__holidays-counter__description-footer">
        <SkOroraButton
          v-track="'started_public_holidays_counter_settings'"
          v-modal.holidays-settings-modal
          :disabled="!holidaysCounterActivated || !canEditHolidaysSettings"
          class="shop-setting__holidays-counter__create-button--force-primary-color"
        >
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.content_section.action') }}
        </SkOroraButton>
      </div>
    </SkCard>
    <SkCard
      v-else
      class="shop-settings__holidays-counter-table__card"
      data-test="holidays-counter-table"
    >
      <div class="shop-settings__holidays-counter-table__header">
        <div class="holidays-counter-table__header-left">
          <div class="holidays-counter-table__header-guaranteed">
            <span class="holidays-counter-table__header-label">
              <!-- eslint-disable-next-line max-len -->
              {{ $tc('shop_settings.tabs.rules.counter_rules.holidays_counter.table.guaranteed', countHolidaysGuaranteed) }}
            </span>
            <div class="holidays-counter-table__bullet-point" />
            <span class="holidays-counter-table__header-value">
              {{ countHolidaysGuaranteed }}
            </span>
          </div>
          <div class="holidays-counter-table__header-indemnified">
            <span class="holidays-counter-table__header-label">
              {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.table.indemnified') }}
            </span>
            <div class="holidays-counter-table__header-paid">
              <span
                class="holidays-counter-table__header-value"
                data-test="holidays-counter-paid"
              >
                {{ countHolidaysPaid }}
              </span>
              <div class="holidays-counter-table__bullet-point" />
              <span class="holidays-counter-table__header-label-secondary">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.table.paid', countHolidaysPaid) }}
              </span>
            </div>
            <div class="holidays-counter-table__header-recover">
              <span
                class="holidays-counter-table__header-value"
                data-test="holidays-recover"
              >
                {{ countHolidaysRecover }}
              </span>
              <div class="holidays-counter-table__bullet-point" />
              <span class="holidays-counter-table__header-label-secondary">
                <!-- eslint-disable-next-line max-len -->
                {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.table.recover', countHolidaysRecover) }}
              </span>
            </div>
          </div>
        </div>
        <div class="holidays-counter-table__header-right">
          <SkCircleButton
            v-if="!loadingTable"
            :class="editSettingsButtonClasses"
            icon="PencilV2Icon"
            @click="handleClickOnEditSettings"
          />
        </div>
      </div>
      <div class="shop-settings__holidays-counter-table__year-selection">
        <!-- eslint-disable max-len -->
        <SkSelectV2
          v-model="selectedYear"
          :label="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.table.select_year')"
          :options="yearOptions"
        />
        <!-- eslint-enable max-len -->
      </div>
      <HolidaysCounterTableCanvas v-if="loadingTable" />
      <HolidaysCounterTable
        v-else
        :holidays-settings="holidaysSettings"
      />
    </SkCard>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <HolidaysSettingsModal
        :year="year"
        @update-success="fetchHolidaysSettings"
      />
      <NewYearHolidaysCounterInfoModal @submit="fetchHolidaysSettings" />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import skDate from '@skello-utils/dates';
import { arrayToSelectOptions } from '@skello-utils/form';

import PackTag from '@app-js/shared/components/PackTag';
import { httpClient } from '@skello-utils/clients';

import HolidaysCounterTable from '@app-js/shared/components/HolidaysCounterTable';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import HolidaysSettingsModal from './HolidaysSettingsModal';
import NewYearHolidaysCounterInfoModal from './HolidaysSettingsModal/NewYearHolidaysCounterInfo';

import HolidayIcon from '../../shared/svg/HolidayIcon';
import HolidaysCounterTableCanvas from './HolidaysCounterTableCanvas';
import { HolidaysService } from './services/HolidaysService';

import UpsellPopover from '../UpsellPopover';

export default {
  name: 'HolidaysCounter',
  components: {
    HolidayIcon,
    HolidaysCounterTable,
    HolidaysCounterTableCanvas,
    HolidaysSettingsModal,
    NewYearHolidaysCounterInfoModal,
    PackTag,
    UpsellPopover,
  },
  data() {
    return {
      loadingTable: false,
      holidaysSettings: [],
      year: skDate().year(),
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences', 'canEditEmployeeInfo']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),

    holidaysCounterActivated: {
      get() {
        return this.holidaysCounterEnabled &&
          !!this.currentShop.attributes.holidaysCounterActivationYear;
      },
      set(newValue) {
        if (newValue) {
          this.$skAnalytics.track('activated_public_holidays_counter');
          this.handleUpdateHolidaysCounterActivated(newValue);
        } else {
          this.$root.$emit('confirm', event, {
            title: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.holidays_counter.title'),
            description: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.holidays_counter.description'),
            actionText: this.$t('actions.continue'),
            onConfirm: () => {
              this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_BANK_HOLIDAYS_TRACKER_DISABLED);
              this.$skAnalytics.track('deactivated_public_holidays_counter');
              this.handleUpdateHolidaysCounterActivated(newValue);
            },
          });
        }
      },
    },
    selectedYear: {
      get() {
        return this.year;
      },
      set(newValue) {
        if (this.year !== newValue) {
          this.year = newValue;

          this.fetchHolidaysSettings();
        }
      },
    },
    editSettingsButtonClasses() {
      return {
        'shop-settings__edit-button__hidden': !this.canEditHolidaysSettings,
      };
    },
    activationYear() {
      return this.currentShop.attributes.holidaysCounterActivationYear;
    },
    currentYear() {
      return skDate().year();
    },
    holidaysCounterConfigured() {
      return this.currentShop.attributes.holidaysCounterConfigured;
    },
    canEditHolidaysSettings() {
      return this.canEditShopRulesAndAbsences &&
        this.canEditEmployeeInfo;
    },
    holidaysCounterEnabled() {
      return this.checkPackOfferFlag('holidays_counter_enabled');
    },
    holidaysCounterCardTitle() {
      return this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.content_section.title', {
        year: this.year,
      });
    },
    holidaysCounterCardClass() {
      return {
        'shop-settings__holidays-counter__card': true,
        'shop-settings__holidays-counter__card--disabled': !this.holidaysCounterActivated || !this.canEditHolidaysSettings,
      };
    },
    countHolidaysGuaranteed() {
      return this.holidaysSettings.filter(element => element.attributes.guaranteed).length;
    },
    countHolidaysPaid() {
      return this.holidaysSettings.filter(element => element.attributes.paid).length;
    },
    countHolidaysRecover() {
      return this.holidaysSettings.filter(element => !element.attributes.paid).length;
    },
    yearsBetweenCounterActivationAndNow() {
      if (!this.holidaysCounterConfigured) return [];
      if (!this.activationYear) return [this.currentYear];

      // Create an empty array of length = this.currentYear - this.activationYear + 1 (ex: 2021 - 2020 + 1 = 2)
      // and fill it with all the years between activationYear and currentYear (map) -> [2020, 2021]
      // and reverse it to get years from recent to older ones -> [2021, 2020]
      return Array(this.currentYear - this.activationYear + 1)
        .fill()
        .map((_, idx) => this.activationYear + idx)
        .reverse();
    },
    yearOptions() {
      return arrayToSelectOptions(
        this.yearsBetweenCounterActivationAndNow,
        value => value);
    },
    canvasColumns() {
      return [
        { width: 90, height: 16 },
        { width: 90, height: 16 },
        { width: 260, height: 16 },
      ];
    },
  },
  created() {
    this.loadingTable = true;

    this.fetchHolidaysSettings();
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    ...mapActions('currentShop', ['updateShop']),

    handleClickOnEditSettings() {
      const newYearModalOpenedOnceKey = `newYearModalOpenedForUser:${this.currentUser.id}`;
      const modalAlreadyOpened = localStorage.getItem(newYearModalOpenedOnceKey) === 'true';

      if (this.activationYear === this.currentYear ||
          this.year < this.currentYear ||
        (this.activationYear < this.currentYear && modalAlreadyOpened)
      ) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'holidays-settings-modal');
      } else {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'new-year-holiday-counter-info-modal');
      }
    },

    handleUpdateHolidaysCounterActivated(holidaysCounterActivated) {
      const holidaysService = new HolidaysService(httpClient);

      if (holidaysCounterActivated) {
        this.setShopAttributes({ holidaysCounterActivationYear: this.currentYear });
        holidaysService.bulkCreate(this.currentShop.id)
          .then(() => {
            this.handleUpdateShop(holidaysCounterActivated);
            this.emitOnRoot(MODAL_SHOW_EVENT, event, 'holidays-settings-modal');
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('errors.standard_message'),
              variant: 'error',
            });
          });
      } else {
        this.setShopAttributes({ holidaysCounterActivationYear: null });
        holidaysService.deleteAll(this.currentShop.id)
          .then(() => {
            this.handleUpdateShop(holidaysCounterActivated);
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('errors.standard_message'),
              variant: 'error',
            });
          });
      }
    },
    handleUpdateShop(holidaysCounterActivated) {
      this.updateShop({ shopId: this.currentShop.id })
        .then(() => {
          if (!holidaysCounterActivated) {
            this.$skToast({
              message:
                this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.holidays_counter.success'),
              variant: 'success',
            });
          }
        });
    },
    fetchHolidaysSettings() {
      httpClient
        .get(`/v3/api/shops/${this.currentShop.id}/holiday_settings?year=${this.year}`)
        .then(response => {
          this.holidaysSettings = response.data.data;
          this.loadingTable = false;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'shop-settings__container',
          });
        });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings__holidays-counter__card {
  padding: 0;
}

.shop-settings__holidays-counter__card--disabled {
  opacity: .4;
}

.shop-settings__holidays-counter__title {
  margin-top: 25px;
  padding: 0 36px 0 33px;
  font-size: $fs-text-l;
}

.shop-settings__holidays-counter__subtitle-row {
  align-items: center;
  margin-bottom: 20px;
  margin-top: 25px;

  p {
    font-weight: $fw-semi-bold;
  }
}

.shop-settings__holidays-counter__description-container {
  display: flex;
  margin-top: 18px;
  margin-bottom: 20px;
  color: $sk-grey;
  padding: 0 36px 0 33px;

  .shop-settings__holidays-counter__description-text {
    width: 90%;
  }
}

.shop-settings__holidays-counter__description-footer {
  height: 70px;
  display: flex;
  align-items: center;
  border-top: 1px solid $sk-grey-10;
}

.shop-settings__holidays-counter__description-icon {
  margin-top: -45px;

  svg {
    width: auto;
    margin-top: 10px;
  }
}

.shop-settings__holidays-counter__tooltip {
  margin-top: 15px;
}

// force button to be blue even if it's disabled
.shop-setting__holidays-counter__create-button--force-primary-color {
  background-color: $sk-blue !important;
  color: white !important;
  margin-left: 33px;
}

.shop-settings__holidays-counter-table__card {
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, .1);
  padding: 30px 16px 16px;
}

.holidays-counter-table__bullet-point {
  width: 3px;
  height: 3px;
  border-radius: 50%;
}

.shop-settings__holidays-counter-table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .holidays-counter-table__header-left {
    display: flex;
    align-items: center;

    .holidays-counter-table__header-label {
      font-weight: 700;
    }

    .holidays-counter-table__header-guaranteed {
      display: flex;
      align-items: center;
      width: 191px;
      margin-right: 29px;
      border-right: 1px solid rgba(0, 0, 0, .1);

      .holidays-counter-table__bullet-point {
        background-color: $sk-grey-10;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .holidays-counter-table__header-indemnified {
      display: flex;
      align-items: center;

      .holidays-counter-table__header-recover,
      .holidays-counter-table__header-paid {
        font-size: 11px;
      }

      .holidays-counter-table__header-paid {
        display: flex;
        align-items: center;
        background-color: $sk-blue-5;
        border-radius: 2px;
        margin-left: 16px;
        padding: 4px 9px 4px 6px;

        .holidays-counter-table__bullet-point {
          background-color: $sk-blue-50;
          margin-left: 9px;
          margin-right: 5px;
        }
      }

      .holidays-counter-table__header-recover {
        display: flex;
        align-items: center;
        background-color: $sk-warning-10;
        border-radius: 2px;
        margin-left: 10px;
        padding: 4px 9px 4px 6px;

        .holidays-counter-table__bullet-point {
          background-color: $sk-warning;
          margin-left: 9px;
          margin-right: 5px;
        }
      }
    }
  }
}

.shop-settings__holidays-counter-table__year-selection {
  margin-bottom: 10px;
  width: 180px;
}

.shop-settings__edit-button__hidden {
  visibility: hidden;
}

</style>
