<template>
  <div
    ref="simpleBar"
    class="scroll-bar__wrapper"
  >
    <Workforce class="total-employees__workforce" />
  </div>
</template>
<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { mapState } from 'vuex';

import Workforce from './Workforce';

export default {
  name: 'ScrollZone',
  components: { Workforce },
  data() {
    return {
      scrollElement: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
  },
  watch: {
    $route() {
      this.scrollElement.scrollLeft = 0;
    },
  },
  mounted() {
    this.listenOnRoot('day-view-scroll', this.handleScroll);

    const simpleBar = new SimpleBar(this.$refs.simpleBar);
    this.scrollElement = simpleBar.getScrollElement();
    this.scrollElement.addEventListener('scroll', this.handleEmitScroll);
  },
  methods: {
    handleScroll(event) {
      // Avoid setting scrollLeft on the source element that did the handleEmitScroll()
      // Otherwise on some browsers it could fire a new scroll event that would trigger an event loop
      if (this.scrollElement === event.srcElement) return;
      this.scrollElement.scrollLeft = event.target.scrollLeft;
      this.emitOnRoot('hide-shift-menu');
    },
    handleEmitScroll(event) {
      this.emitOnRoot('day-view-scroll', event);
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-bar__wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
</style>
