<template>
  <div class="automatic-planning__wrapper">
    <h1 class="sk-header--1 automatic-planning__header">
      {{ $t('shop_settings.tabs.automatic_planning.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.automatic_planning.subtitle') }}
    </p>
    <div class="automatic-planning__nav-wrapper">
      <SkNav>
        <SkNavItem
          :to="{ name: 'automatic_planning_rules', query: $route.query }"
        >
          {{ $t('shop_settings.tabs.automatic_planning.nav.rules') }}
        </SkNavItem>
        <SkNavItem
          :to="{ name: 'automatic_planning_competencies', query: $route.query }"
        >
          {{ $t('shop_settings.tabs.automatic_planning.nav.competencies') }}
        </SkNavItem>
        <div class="automatic-planning__plannings-link">
          <SkOroraButton
            v-if="showPlanninglink"
            v-track="'automatic_planning_back_to_planning'"
            variant="tertiary"
            icon="RightArrowV2Icon"
            icon-position="right"
            @click="planningLinkClick"
          >
            {{ $t('shop_settings.tabs.automatic_planning.nav.planning_link') }}
          </SkOroraButton>
        </div>
      </SkNav>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'AutomaticPlanning',
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          next();
        },
      });
    } else if (this.notFullySetup) {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.automatic_planning.sk_confirm.title'),
        description: this.$t('shop_settings.tabs.automatic_planning.sk_confirm.description'),
        cancelLabel: this.$t('shop_settings.tabs.automatic_planning.sk_confirm.cancel_button'),
        submitLabel: this.$t('shop_settings.tabs.automatic_planning.sk_confirm.confirm_button'),
        onConfirm: () => {
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      headers: [
        { title: this.$t('shop_settings.tabs.automatic_planning.table.headers.activate') },
        { title: this.$t('shop_settings.tabs.automatic_planning.table.headers.rule_type') },
      ],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState(
      'automaticPlanning',
      [
        'competencies',
        'originalCompetencies',
        'automaticPlanningRules',
        'originalAutomaticPlanningRules',
      ],
    ),
    ...mapState('planningsUsers', ['users']),
    ...mapGetters('planningsState', ['currentDate']),
    unsavedChanges() {
      return this.unsavedCompetenciesChanges || this.unsavedRulesChanges;
    },
    unsavedCompetenciesChanges() {
      return JSON.stringify(this.competencies) !== JSON.stringify(this.originalCompetencies);
    },
    unsavedRulesChanges() {
      const currentValues = this.automaticPlanningRules.map(rule => rule.attributes.value);
      const newValues = this.originalAutomaticPlanningRules.map(rule => rule.attributes.value);
      return JSON.stringify(currentValues) !== JSON.stringify(newValues);
    },
    notFullySetup() {
      return !this.automaticPlanningRules.some(rule => rule.attributes.active) ||
        this.competencies.length === 0;
    },
    shopId() {
      return this.currentShop.id;
    },
    showPlanninglink() {
      return this.currentLicense.attributes.canCreateShifts;
    },
  },
  created() {
    this.fetchRules({ shopId: this.shopId })
      .catch(() => {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        });
      });
    Promise.all([
      this.fetchUsers(),
      this.fetchPostes(this.shopId),
    ])
      .then(() => {
        this.fetchCompetencies({ shopId: this.shopId, users: this.users });
      })
      .catch(() => {
        this.$skToast({
          message: this.$t('shop_settings.update_shop.error_message'),
          variant: 'error',
        });
      });
  },
  methods: {
    ...mapActions('automaticPlanning', ['fetchCompetencies', 'fetchRules']),
    ...mapActions('planningsPostes', ['fetchPostes']),
    ...mapActions('planningsUsers', ['fetchPlanningUsers']),

    fetchUsers() {
      const fetchParams = {
        shop_id: this.shopId,
        starts_at: skDate().startOf('isoWeek').format('YYYY-MM-DD'),
        ends_at: skDate().endOf('isoWeek').add(1, 'month').format('YYYY-MM-DD'),
      };
      return this.fetchPlanningUsers({ params: fetchParams });
    },
    planningLinkClick() {
      this.$router.push({
        name: 'plannings_weeks',
        params: { shop_id: this.shopId },
        query: {
          date: this.currentDate,
          open_automatic_planning: true,
        },
        replace: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__wrapper {
  padding-bottom: 100px; // Allow space for sticky bar

  .automatic-planning__header {
    margin-bottom: 6px;
  }
}

.automatic-planning__nav-wrapper {
  margin-bottom: 45px;

  // Button on the right is so big it offsets the nav links
  // Force the nav links at the bottom
  ::v-deep .sk-nav {
    align-items: flex-end;
  }

  // When hovering on tabs -> Don't show light blue
  ::v-deep .router-link-exact-active:hover {
    color: $sk-black;
  }
}

.automatic-planning__plannings-link {
  margin-left: auto;
  font-weight: 700;
  display: flex;
  padding: 10px 0;
}

.sk-subtitle--large {
  margin-top: 6px;
  margin-bottom: 20px;
}
</style>
