<template>
  <div
    v-tooltip="tooltipContent"
    :class="folderCardClasses"
    @click="onClick"
  >
    <div
      v-if="isAddFolderCard"
      class="folder-card__add"
    >
      <PlusSignV2Icon
        class="folder-card__icon folder-card__icon--large"
        :fill="isDisabled ? $skColors.skGrey30 : $skColors.skBlue50"
      />
      <h4 class="folder-card__title">
        {{ $t('folder.create') }}
      </h4>
    </div>
    <div
      v-else
      class="folder-card__content"
    >
      <FolderOpenIcon
        class="folder-card__icon folder-card__icon--large"
        :fill="isDisabled ? $skColors.skGrey30 : $skColors.skBlue50"
      />
      <h4
        ref="folderTitleEl"
        class="folder-card__title folder-card__title--ellipsis"
      >
        {{ title }}
      </h4>
    </div>
    <SkCircleButton
      v-if="!isDisabled && !isAddFolderCard"
      icon="KebabV2Icon"
      size="small"
      variant="secondary"
      class="folder-card__kebab-button"
      @click.stop="showFloatingPopover"
    />
    <div @mouseleave="hideFloatingPopover">
      <SkOroraFloatingPopoverMenu
        ref="floatingPopoverMenu"
        :options="menuOptions"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FolderCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    isAddFolderCard: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldShowTooltip: false,
      menuOptions: [
        {
          label: this.$t('shop_settings.documents_management.rename_folder.title'),
          callback: () => {
            this.hideFloatingPopover();
            this.$emit('rename-folder', this.title);
          },
          iconLeft: 'PencilV2Icon',
        },
        {
          label: this.$t('shop_settings.documents_management.delete_folder.title'),
          callback: () => {
            this.hideFloatingPopover();
            this.$emit('delete-folder');
          },
          iconLeft: 'TrashCanV2Icon',
          variant: 'error',
        },
      ],
    };
  },
  computed: {
    folderCardClasses() {
      return [
        'folder-card',
        {
          'folder-card--disabled': this.isDisabled,
          'folder-card--add': this.isAddFolderCard,
        },
      ];
    },
    tooltipContent() {
      if (this.isDisabled) return this.$t('folder.tooltip_permanent');
      return this.shouldShowTooltip ? this.title : null;
    },
  },
  mounted() {
    const folderTitleEl = this.$refs.folderTitleEl;
    if (folderTitleEl && folderTitleEl.scrollWidth > folderTitleEl.clientWidth) {
      this.shouldShowTooltip = true;
    }
  },
  methods: {
    onClick() {
      if (this.isDisabled || !this.isAddFolderCard) return;
      this.$emit('create-folder');
    },
    showFloatingPopover(event) {
      this.$refs.floatingPopoverMenu.handleShow(event, {
        options: this.menuOptions,
        placement: 'bottom-end',
      });
    },
    hideFloatingPopover() {
      this.$refs.floatingPopoverMenu.hideFloatingPopover();
    },
  },
};
</script>

<style lang="scss" scoped>
.folder-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 180px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    .folder-card__kebab-button {
      display: block;
    }
  }
}
.folder-card--add {
  height: 100px;
  padding: 12px;
}
.folder-card--disabled {
  background-color: $sk-grey-5;
  color: $sk-grey-30;
}
.folder-card--disabled p {
  color: $sk-grey-30;
}
.folder-card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
.folder-card__add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: black;
  gap: 16px;
}
.folder-card__title {
  margin: 0;
  font-size: 14px;
  font-weight: $fw-semi-bold;
}
.folder-card__title--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.folder-card__icon {
  align-self: flex-start;
}
.folder-card__icon--large {
  width: 32px;
  height: 32px;
}
.folder-card__kebab-button {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 28px;
  height: 28px;
  background-color: #ffffff !important;
  border: 1px solid #e0e0e0;
  :deep(.sk-circle-button__hover-layer:hover:not(.sk-circle-button--disabled,
   .sk-circle-button--loading)) {
    background-color: #ffffff !important;
  }
}
</style>
