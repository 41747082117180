<template>
  <div>
    <DocumentCard
      :document="document"
      class="document-card"
    />
    <span>{{ $t('text_document_templates.sign_document_modal.first_step.description') }}</span>
    <div class="employee-selection">
      <SkSelectV2
        ref="select"
        v-model="selectedEmployeeIds"
        class="employee-selection--select"
        :label="$t('text_document_templates.sign_document_modal.first_step.add_signer')"
        :search-placeholder="$t('text_document_templates.sign_document_modal.first_step.add_signer')"
        multi
        no-confirm
        searchable
        append-to-body
        :options="employeesForSelect"
        :disabled-options="disabledEmployeeIds"
        :unselectable-options="disabledEmployeeIds"
        @input="signerChosen"
      >
        <template #option="{ option }">
          <span v-tooltip="showMissingEmailTooltip(option)">
            {{ option.text }}
          </span>
        </template>
      </SkSelectV2>
      <div class="selected-employees">
        <div
          v-for="employee in selectedEmployees"
          :key="employee.id"
          variant="light"
          class="selected-employees--tag"
        >
          <span>{{ truncateEmployeeName(employee) }}</span>
          <div
            v-if="employee.id !== selectedEmployee.id"
            class="selected-employees--tag--close"
            @click="unselectEmployee(employee.id)"
          >
            <ClosingXIcon
              width="10"
              height="10"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="checkbox-initials"
    >
      <SkCheckBox
        v-model="initialsValue"
        class="checkbox-initials__check"
      />
      <span class="checkbox-initials__message">
        {{ $t('text_document_templates.sign_document_modal.first_step.initials') }}
      </span>
    </div>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
} from 'vuex';
import DocumentCard from '@app-js/users/pages/documents/DocumentCard';
import { truncateString } from '@skello-utils/formatting/strings';

export default {
  name: 'SignerSelectionStep',
  components: {
    DocumentCard,
  },
  props: {
    areInitialsActive: {
      type: Boolean,
      default: false,
    },
    addInitials: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    selectedEmployeeIds: [],
    maxSelectableSigners: 2,
  }),
  computed: {
    ...mapState('employees', { employees: state => state.organisationAdmins }),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('textDocumentTemplates', {
      document: state => state.selectedEsignatureDocument,
    }),
    ...mapState('selectedEmployee', {
      selectedEmployee: state => state.employee,
    }),
    employeesForSelect() {
      return this.employeesToList.map(employee => ({
        id: employee.id,
        text: `${employee.attributes.firstName} ${employee.attributes.lastName}`,
        email: employee.attributes.email,
      }));
    },
    employeesToList() {
      return this.employees.filter(employee => {
        if (employee.id === this.selectedEmployee.id) return false;
        if (employee.attributes.currentLicenseType === 'system_admin') return true;
        if (this.isAdvancedMasterOnCurrentShopOrParentNode(employee)) return true;

        return false;
      });
    },
    disabledEmployeeIds() {
      // we do this.maxSelectableSigners - 1 to consider this.selectedEmployee
      if (this.selectedEmployeeIds.length < this.maxSelectableSigners - 1) {
        return this.employeesForSelect
          .filter(employee => !employee.email)
          .map(employee => employee.id);
      }

      return this.employeesForSelect
        .filter(employee => !this.selectedEmployeeIds.includes(employee.id) || !employee.email)
        .map(employee => employee.id);
    },
    selectedEmployees() {
      const employees = this.employeesForSelect
        .filter(employee => this.selectedEmployeeIds.includes(employee.id));
      employees.unshift({
        id: this.selectedEmployee.id,
        text: `${this.selectedEmployee.attributes.firstName} ${this.selectedEmployee.attributes.lastName}`,
      });
      return employees;
    },
    initialsValue: {
      get() {
        return this.areInitialsActive;
      },
      set(newValue) {
        this.addInitials(newValue);
      },
    },
    currentShopAncestry() {
      return this.currentShop.relationships.clusterNode.attributes.ancestry.split('/');
    },
  },
  created() {
    if (!this.employees?.length) {
      this.fetchOrganisationAdmins();
    }
  },
  mounted() {
    this.$emit('signers-chosen', this.selectedEmployees);
  },
  methods: {
    ...mapActions('employees', ['fetchOrganisationAdmins']),
    signerChosen() {
      if (this.selectedEmployees.length === this.maxSelectableSigners) {
        this.$refs.select.closeDropdown(event);
      }
      this.$emit('signers-chosen', this.selectedEmployees);
    },
    unselectEmployee(employeeId) {
      this.selectedEmployeeIds = this.selectedEmployeeIds.filter(id => id !== employeeId);
      this.signerChosen();
    },
    showMissingEmailTooltip(employee) {
      if (employee.email) return null;

      return this.$t('text_document_templates.sign_document_modal.first_step.missing_email');
    },
    // Signer is present in selector if he has an advanced master license:
    // - without cluster: on shop
    // - with cluster: on shop node or on a parent node of shop
    isAdvancedMasterOnCurrentShopOrParentNode(employee) {
      return employee.attributes
        .shopLicenses.some(license => (
          license.type === 'advanced_master' &&
          (this.hasLicenseOnShop(license.shop_id) || this.hasLicenseOnNode(license.cluster_node_id))
        ));
    },
    hasLicenseOnShop(shopId) {
      return String(shopId) === this.currentShop.id;
    },
    hasLicenseOnNode(clusterNodeId) {
      return this.currentShopAncestry.includes(String(clusterNodeId));
    },
    truncateEmployeeName(employee) {
      return truncateString(employee.text, 30);
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .sk-select__selected-option--filled-in-with-label {
  align-items: center;
  padding-bottom: 0;

  .sk-select__label {
    top: auto;
    font-size: $fs-text-m;
  }
}

.document-card {
  margin-bottom: 24px;
}

.employee-selection {
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid $sk-grey-10;
}

.employee-selection--select {
  width: 33% !important;
  margin-right: 30px;
}

.selected-employees {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 67%;
}

.selected-employees--tag {
  border: 1px solid $sk-grey-10;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  user-select: none;
}

.selected-employees--tag--close {
  margin-left: 5px;
  cursor: pointer;
}

.checkbox-initials {
  display: flex;
  flex-direction: row;
}

.checkbox-initials__check {
  padding-top: 16px;
}

.checkbox-initials__message {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 8px;
}

</style>
