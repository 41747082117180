<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'UndoAction',
  components: {
  },
  extends: GenericToolbarAction,
  props: {
    sourceType: {
      type: String, // 'Month' | 'Week'
      required: false,
      default: 'Week',
    },
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('planningsState', ['undoRedoLoading']),
    ...mapGetters('planningsState', ['isAtBeginningOfHistory', 'monday', 'sunday', 'periodDates']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.undo.label');
    },
    icon() {
      return 'BackArrowV2Icon';
    },
    visibleIn() {
      return 'toolbar';
    },
    disabled() {
      return this.isDataLoading || this.undoRedoLoading || this.isAtBeginningOfHistory;
    },
    dataTest() {
      return 'planning-toolbar__undo';
    },
    canDisplayUndoRedo() {
      return this.currentOrganisation.attributes.packOffer.plannings_undo_enabled;
    },
    isVisible() {
      return this.canDisplayUndoRedo;
    },
  },
  methods: {
    ...mapActions('planningsState', ['undoRedoAction']),
    handler() {
      const dates = this.periodDates(this.sourceType.toLowerCase());

      this.undoRedoAction({ periodStartsAt: dates.startsAt, periodEndsAt: dates.endsAt })
        .then(() => {
          this.$skAnalytics.track('undo_btn_planning', { source: this.sourceType });
        })
        .catch(() => this.onFailure());
    },
  },
};
</script>
