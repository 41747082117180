<template>
  <!--
    Copied from app/javascript/src/v3/onboarding/organisation_creation/pages/ShopBillingForm.vue
    If you need to make a change make it to both
  -->
  <div class="col-md-4 offset-md-4 mx-auto billing-step__page-content">
    <div class="billing-step__header">
      <h1 class="sk-header--1">
        {{ $t('organisation_settings.shop_form.billing_step.title') }}
      </h1>
    </div>
    <div class="billing-step__subtitle">
      <p class="sk-subtitle--large">
        {{ $t('organisation_settings.shop_form.billing_step.subtitle') }}
      </p>
    </div>
    <h3 class="sk-header--3 billing-info__header">
      {{ $t('organisation_settings.shop_form.billing_step.billing_title') }}
    </h3>
    <StripeBox transparent />
    <div class="billing-info__iban">
      <SkInput
        v-model.trim="billingInfo.iban"
        :errored="errors.iban"
        :error-message="ibanErrorMessage"
        :label="$t('organisation_settings.shop_form.billing_step.iban')"
        :debounce="validateIban"
        :debounce-ms="500"
      />
    </div>

    <div class="billing-info__billing-email">
      <SkInput
        v-model.trim="billingInfo.email"
        :errored="errors.email"
        :error-message="billingEmailErrorMessage"
        :label="$t('organisation_settings.shop_form.billing_step.billing_email')"
        :debounce="validateEmail"
        :debounce-ms="500"
      >
        <template #icon>
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('organisation_settings.shop_form.billing_step.tooltip.billing_email')
            "
            class="billing-step__helper"
          />
        </template>
      </SkInput>
    </div>

    <div class="row-separator" />
    <h3 class="sk-header--3">
      {{ $t('organisation_settings.shop_form.billing_step.title') }}
    </h3>

    <div class="billing-form__billing-address">
      <div class="row">
        <div class="col-10">
          {{ $t('organisation_settings.shop_form.billing_step.same_billing_address') }}
        </div>
        <div class="col-2">
          <SkSwitch
            v-model="sameAddressAsBilling"
            :label="$t('organisation_settings.shop_form.billing_step.same_billing_address')"
            class="billing-form__switch"
          />
        </div>
      </div>
      <div v-if="sameAddressAsBilling">
        <div class="billing-form__social-denomination">
          {{ shop.denominationSociale }}
        </div>
        <div class="billing-form__address">
          {{ billingAddress }}
        </div>
        <div>
          {{ serializedCity }}
        </div>
      </div>
      <div v-else>
        <SkInput
          v-model="billingAddress"
          :label="$t('organisation_settings.shop_form.address_2')"
          :error-message="$t('organisation_settings.shop_form.error.mandatory')"
          :errored="errors.address"
          @keyup="validateMandatoryField('address')"
        />
        <div class="billing-form__city row">
          <div class="col">
            <SkInput
              v-model="billingZipcode"
              :label="$t('organisation_settings.shop_form.zip_code')"
              :error-message="$t('organisation_settings.shop_form.error.mandatory')"
              :errored="errors.zipcode"
              @keyup="validateMandatoryField('zipcode')"
            />
          </div>
          <div class="col">
            <SkInput
              v-model="billingCity"
              :label="$t('organisation_settings.shop_form.city')"
              :error-message="$t('organisation_settings.shop_form.error.mandatory')"
              :errored="errors.city"
              @keyup="validateMandatoryField('city')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <SkSelectV2
              v-model="billingCountry"
              :options="countries"
              :label="$t('organisation_settings.shop_form.country')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row billing-form__legal-section">
      <div class="col">
        <div>
          {{ $t("organisation_settings.shop_form.billing_step.legal_infos_1") }}
        </div>
        <div class="billing-form__legal-info">
          {{ $t("organisation_settings.shop_form.billing_step.legal_infos_2") }}
        </div>
      </div>
    </div>
    <div class="row billing-form__navigation-buttons">
      <div class="col">
        <div class="billing-form__button billing-form__blank_button">
          <SkOroraButton
            variant="tertiary"
            @click="goBack"
          >
            {{ $t('organisation_settings.shop_form.back') }}
          </SkOroraButton>
        </div>
      </div>
      <div class="col billing-form__navigation-buttons__submit-wrapper">
        <SkOroraButton
          :disabled="disabledSubmit"
          :loading="loading"
          class="billing-form__button"
          @click="handleSubmit"
        >
          {{ $t('organisation_settings.shop_form.finish') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
} from 'vuex';
import IBAN from 'iban';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { isValidEmail } from '@skello-utils/validators';
import {
  countryKeys,
  languageToCountryCode,
} from '@skello-utils/country-keys.js';
import { arrayToSelectOptions } from '@skello-utils/form';
import { httpClient } from '@skello-utils/clients';

import StripeBox from '../../../shared/components/StripeBox';

export default {
  name: 'ShopBillingForm',
  components: { StripeBox },
  beforeRouteEnter(to, from, next) {
    to.params.shop = JSON.parse(localStorage.getItem('createdShop'));
    next();
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
    parentClusterNodeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      billingInfo: {
        iban: '',
        email: '',
        address: '',
        city: '',
        country: '',
        zipcode: '',
      },
      sameAddressAsBilling: true,
      billingEmailErrorMessage: '',
      ibanErrorMessage: '',
      errors: {
        address: false,
        city: false,
        zipcode: false,
        email: false,
        iban: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    serializedCity() {
      return `${this.billingZipcode} ${this.billingCity}, ${this.$t(`countries.${this.billingCountry}`)}`;
    },
    billingAddress: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.shop.address;
        }
        return this.billingInfo.address;
      },
      set(newValue) {
        this.billingInfo.address = newValue;
      },
    },

    billingZipcode: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.shop.zipcode;
        }
        return this.billingInfo.zipcode;
      },
      set(newValue) {
        this.billingInfo.zipcode = newValue;
      },
    },

    billingCity: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.shop.city;
        }
        return this.billingInfo.city;
      },
      set(newValue) {
        this.billingInfo.city = newValue;
      },
    },

    billingCountry: {
      get() {
        if (this.sameAddressAsBilling) {
          return this.shop.country;
        }
        return this.billingInfo.country ? this.billingInfo.country : this.defaultUserCountryCode;
      },
      set(newValue) {
        this.billingInfo.country = newValue;
      },
    },
    defaultUserCountryCode() {
      return languageToCountryCode(this.currentUser.attributes.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return arrayToSelectOptions(countryList, value => this.$t(`countries.${value}`));
    },
    isAddressValid() {
      if (this.sameAddressAsBilling) return true;

      return this.billingAddress && this.billingCity &&
        this.billingCountry && this.billingZipcode;
    },
    isFormValid() {
      return this.isAddressValid && this.billingInfo.iban &&
        this.billingInfo.email && !this.errors.email &&
        !this.errors.iban;
    },
    disabledSubmit() {
      return !this.isFormValid;
    },
  },
  created() {
    this.billingInfo.email = this.currentUser.attributes.email;
    this.updateShopCreationCurrentStep('billing_step');
  },
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem('configured') === 'true') {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'shop-form__congratulation-modal');
      }
    });
  },
  methods: {
    ...mapMutations('config', ['updateShopCreationCurrentStep']),
    validateIban() {
      this.ibanErrorMessage = null;

      if (!this.billingInfo.iban) {
        this.ibanErrorMessage = this.$t('organisation_settings.shop_form.error.mandatory');
      } else if (!IBAN.isValid(this.billingInfo.iban)) {
        this.ibanErrorMessage = this.$t('organisation_settings.shop_form.billing_step.error.iban');
      }
      this.errors.iban = !!this.ibanErrorMessage;
    },
    validateMandatoryField(attribute) {
      if (attribute === 'iban') {
        this.validateIban();
      } else if (attribute === 'email') {
        this.validateEmail();
      } else {
        this.errors[attribute] = !this.billingInfo[attribute];
      }
    },
    validateEmail() {
      this.billingEmailErrorMessage = null;

      if (!this.billingInfo.email) {
        this.billingEmailErrorMessage = this.$t('organisation_settings.shop_form.error.mandatory');
      } else if (!isValidEmail(this.billingInfo.email)) {
        this.billingEmailErrorMessage = this.$t('organisation_settings.shop_form.billing_step.error.email');
      }

      this.errors.email = !!this.billingEmailErrorMessage;
    },
    handleSubmit(sameAddressAsShop) {
      this.loading = true;

      this.createBillingInfo(sameAddressAsShop)
        .then(() => {
          this.updateShopToConfigured()
            .then(() => {
              this.emitOnRoot(MODAL_SHOW_EVENT, event, 'shop-form__congratulation-modal');
              localStorage.setItem('configured', true);
            })
            .catch(error => {
              this.$skToast({
                message: this.$t('errors.standard_message'),
                variant: 'error',
              });
            });
        })
        .catch(error => {
          const errorMessage = error.response?.data?.message;
          const isIBANError = error.response?.status === 422 && errorMessage.includes('IBAN');
          const toastMessage = isIBANError ?
            this.$t('organisation_settings.shop_form.billing_step.error.stripe_iban') :
            this.$t('errors.standard_message');
          this.$skToast({
            message: toastMessage,
            variant: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createBillingInfo(sameAddressAsShop) {
      const addressReference = sameAddressAsShop ? this.shop : this.billingInfo;

      const params = {
        shop_id: this.shop.id,
        add_shop: true,
        billing_infos: {
          email: this.billingInfo.email,
          address: addressReference.address,
          zipcode: addressReference.zipcode,
          city: addressReference.city,
          country: addressReference.country,
        },
        bank_info: {
          iban: this.billingInfo.iban,
        },
      };

      return httpClient
        .post('/v3/api/billing_infos', params);
    },
    updateShopToConfigured() {
      const params = {
        id: this.shop.id,
        shop: {
          configured: true,
        },
      };

      return httpClient
        .patch(`/v3/api/shops/${params.id}`, params)
        .catch(error => {
          throw error;
        });
    },
    goBack() {
      this.updateShopCreationCurrentStep('shop');
      this.$router.push({
        name: 'organisation_settings_shops_new_form',
        hash: this.$route.hash,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-form__billing-address {
  width: 100%;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 30px;
}

.billing-step__page-content {
  margin-top: 40px;
  max-width: 650px;
  text-align: center;
}

.billing-step__header {
  margin-bottom: 9px;
}

.billing-step__subtitle {
  max-width: 370px;
  margin: auto;
}

.billing-info__iban {
  margin-top: 30px;
  width: 100%;
}

.billing-info__billing-email {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 50%;
}

.billing-info__header {
  padding-top: 40px;
  padding-bottom: 15px;
}

.billing-form__social-denomination {
  font-weight: $fw-semi-bold;
}

.billing-form__address,
.billing-form__social-denomination {
  width: 100%;
}

.billing-form__button {
  margin-top: 40px;
}

.billing-form__blank_button {
  text-align: left;
}

.billing-form__navigation-buttons__submit-wrapper {
  text-align: right;
}

.billing-form__legal-section {
  color: $sk-grey;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: $fs-text-s;
  line-height: 22px;
}

.billing-form__navigation-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.billing-form__legal-info {
  margin-top: 15px;
}

.billing-form__switch {
  display: inline-block;
}

.billing-form__city {
  margin-top: 15px;
  margin-bottom: 15px;
}

.billing-form__input-error {
  padding-bottom: 10px;
}
</style>
