<template>
  <div class="sk-modal-wrapper">
    <div
      ref="modal"
      :style="modalContainerStyles"
      class="sk-modal-container"
      :class="skModalContainerRightPosition"
    >
      <header class="sk-modal__header">
        <slot
          name="header"
          class="sk-modal__title"
        />
        <div
          class="sk-modal__closing-x"
          @click="close"
        >
          <ClosingXSvg
            class="sk-icon-button"
            fill="#727272"
            size="10px"
          />
        </div>
      </header>
      <section class="sk-modal__body">
        <slot name="body" />
      </section>
      <footer class="footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import ClosingXSvg from '../../assets/svg/ClosingXSvg';

export default {
  name: 'SkKpisModal',
  components: { ClosingXSvg },
  props: {
    close: {
      type: Function,
      required: true,
    },
    width: {
      type: String,
      default: '620px',
    },
    title: {
      type: String,
      default: null,
    },
    skModalRightPosition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalContainerStyles() {
      return { width: this.width };
    },
    skModalContainerRightPosition() {
      return {
        'sk-modal-container__margin-right': this.skModalRightPosition,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-modal-wrapper {
  background: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.sk-modal-container {
  min-height: 100px;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  border-radius: 6px;
  position: absolute;
}

.sk-modal__header {
  position: relative;
  height: 75px;
  padding: 0 20px;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fs-heading-xs;
  font-weight: $fw-semi-bold;

  .sk-modal__closing-x {
    bottom: 7px;
    cursor: pointer;
  }

  .sk-icon-button {
    padding: 14px 0;
    border: none;
    outline: none;
    border-radius: 50%;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background: transparent;
    height: 40px;
    width: 40px;

    &:hover:not([disabled]) {
      background: $sk-grey-10;
      cursor: pointer;
      transition: all .3s ease-out;
    }

    &:focus {
      outline: 0;
    }
  }
}

.sk-modal__body {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: -10px;
}

.sk-modal-container__margin-right {
  right: 8px;
}

.footer {
  position: sticky;
  bottom: 0;
  background: $sk-white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 6px 6px;
}
</style>
