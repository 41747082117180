import cloneDeep from 'lodash/cloneDeep';
import { Quill } from 'vue2-editor';

const Delta = Quill.import('delta');

/**
 * If an image  with an online source is inserted in the editor like from Google Document
 * Convert it in Base64
 * See https://github.com/quilljs/quill/issues/893
 */
export default class QuillImageAsBase {
  /**
   * Instantiate the module given a quill instance and any options
   * @param {Quill} quill
   */
  constructor(quill) {
    // save the quill reference
    this.quill = quill;

    quill.clipboard.addMatcher('img', (node, imageDelta) => {
      const imageOp = imageDelta.ops[0];
      const imageUri = imageOp.insert.image;
      if (imageUri && imageUri.startsWith('http')) {
        // Must be asynchronous whereas addMatcher want a synchronous return value
        // So render the online image first and then replace it as soon as possible
        let copyIndex = quill.selection.savedRange.index;
        this.toDataURL(imageUri).then(data => {
          copyIndex = quill.getSelection().index;

          const properImageOp = cloneDeep(imageOp);
          properImageOp.insert.image = data; // Replace the online image URI with data

          // Refind the inserted image to replace it
          const contentDelta = quill.getContents();
          const opIndexToReplace = contentDelta.ops
            .findIndex(op => op.insert.image && op.insert.image === imageUri);
          const toRetain = contentDelta.ops.slice(0, opIndexToReplace).reduce((_toRetain, op) => {
            const delta = new Delta([op]);
            return _toRetain + delta.length();
          }, 0);

          // Apply the delta transformation to replace it in editor content
          const replaceImageDelta = new Delta()
            .retain(toRetain)
            .delete(imageDelta.length())
            .insert(properImageOp.insert, properImageOp.attributes);
          quill.updateContents(replaceImageDelta, Quill.sources.API);
        });
      }

      return imageDelta;
    });
  }

  toDataURL(url) {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      }));
  }
}
