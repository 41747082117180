<template>
  <div class="change-password">
    <h1 class="change-password__title">
      {{ $t('profile.change_password.title.main') }}
    </h1>

    <form
      class="change-password__form"
      @submit.prevent
    >
      <Password
        v-model="currentPassword"
        :placeholder="$t('profile.change_password.password')"
        :required="true"
        data-test="change-password__form--password"
      />

      <PasswordConfirmation
        ref="passwordConfirmation"
        v-model="password"
        :password-placeholder="$t('profile.change_password.new_password')"
        :confirm-password-placeholder="$t('profile.change_password.confirm_password')"
        @validation-updated="handleValidationUpdate"
      />
    </form>

    <StickyBar
      container-scroll-id="profile__content"
      :visible="true"
      :submit-spinner="updating"
      :submit-button-label="$t('profile.change_password.submit')"
      :disabled="isSubmitDisabled"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import StickyBar from '@app-js/shared/components/Stickybar';
import Password from '@app-js/shared/components/Password';
import PasswordConfirmation from '@app-js/shared/components/PasswordConfirmation';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'ChangePassword',
  components: {
    PasswordConfirmation,
    Password,
    StickyBar,
  },
  data() {
    return {
      updating: false,

      currentPassword: '',
      password: '',

      newPasswordsAreValid: false,
    };
  },
  computed: {
    isSubmitDisabled() {
      return !(this.currentPassword && this.newPasswordsAreValid);
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUserProfile']),

    handleValidationUpdate(isValid) {
      this.newPasswordsAreValid = isValid;
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && !this.isSubmitDisabled) this.handleSubmit();
    },
    async handleSubmit() {
      this.updating = true;

      try {
        await httpClient.put('/users.json', {
          user: {
            current_password: this.currentPassword,
            password: this.password,
            password_confirmation: this.$refs.passwordConfirmation.confirmPassword,
          },
        });

        this.$skToast({
          message: this.$t('profile.change_password.update.success'),
          variant: 'success',
        });

        window.location.reload();
      } catch ({ response }) {
        let message = response.data.message?.alert ?? this.$t('profile.change_password.update.error');

        if (response.status === 422) {
          message = this.$t('profile.change_password.update.invalid_actual_password');
        }

        this.$skToast({
          message,
          variant: 'error',
        });
      } finally {
        this.updating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  }

  &__form {
    width: calc(50% - 12px);
  }
}

.sk-sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
