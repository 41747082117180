<template>
  <div class="employee-paid-leaves-counter__pto-tracker">
    <div
      v-for="periodSummary in periodSummaries"
      :key="periodSummary.title"
      class="employee-paid-leaves-counter__pto-tracker__tracker-wrapper"
    >
      <span class="employee-paid-leaves-counter__pto-tracker__title">
        {{ periodSummary.title }}
      </span>
      <SkColumnInfoCard
        :is-loading="isLoading"
        :columns="getPeriodSummaryData(periodSummary)"
        :variant="
          periodSummaries.length == 1 || periodSummary.isCurrentPeriod
            ? 'primary'
            : 'blue'
        "
        class="employee-paid-leaves-counter__pto-tracker__card"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeriodSummariesSection',
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    periodSummaries: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    acquiredPaidLeavedTooltip({
      acquired,
      deferralFromPreviousPeriod,
      endDate,
      manual,
      startDate,
    }) {
      const startYear = startDate?.year();
      const endYear = endDate?.year();
      const period = startYear === endYear ? `${startYear}` : `${startYear}/${endYear}`;

      let content = this.$t(
        'employees.tabs.counters.paid_leaves_counter.summary_inserts.acquired_paid_leaves.tooltip_1',
        {
          period,
          acquired_count: acquired,
        },
      );
      if (manual) {
        content += `<br>${this.$t(
          'employees.tabs.counters.paid_leaves_counter.summary_inserts.acquired_paid_leaves.tooltip_2',
          { manual_update: manual },
        )}`;
      }
      if (deferralFromPreviousPeriod) {
        content += `<br>${this.$t(
          'employees.tabs.counters.paid_leaves_counter.summary_inserts.acquired_paid_leaves.tooltip_3',
          { previous_period_report: deferralFromPreviousPeriod },
        )}`;
      }
      return content;
    },

    remaingingPaidLeavesTooltip({
      taken,
      acquiredBalance,
      deferralToNextPeriod,
    }) {
      let content = this.$t(
        'employees.tabs.counters.paid_leaves_counter.summary_inserts.remaining_paid_leaves.tooltip_1',
        {
          taken_count: taken,
          acquired_count: acquiredBalance,
        },
      );

      if (deferralToNextPeriod) {
        content += `<br>${this.$t(
          'employees.tabs.counters.paid_leaves_counter.summary_inserts.remaining_paid_leaves.tooltip_2',
          { count: deferralToNextPeriod },
        )}`;
      }
      return content;
    },

    takenPaidLeavesTooltip(takenFromNextPeriod, takenThisPeriod, startYear, endYear) {
      let currentPeriod;
      let previousPeriod;
      let period;

      if (startYear === endYear) {
        currentPeriod = `${startYear}`;
        previousPeriod = `${startYear - 1}`;
        period = '_without_period';
      } else {
        currentPeriod = `${startYear}/${endYear}`;
        previousPeriod = `${startYear - 1}/${endYear - 1}`;
        period = '';
      }

      return this.$t(
        `employees.tabs.counters.paid_leaves_counter.summary_inserts.taken_paid_leaves.tooltip${period}`,
        {
          current_period: currentPeriod,
          previous_period: previousPeriod,
          taken_from_next_period: takenFromNextPeriod,
          taken_this_period: takenThisPeriod,
        },
      );
    },

    getPeriodSummaryData(periodSummary) {
      const {
        acquiredBalance,
        taken,
        takenFromNextPeriod,
        takenThisPeriod,
        balance,
        isBlank,
        isCurrentPeriod,
        endDate,
        startDate,
      } = periodSummary;
      return [
        {
          content: acquiredBalance,
          isBlank,
          title: isCurrentPeriod ?
            this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.acquired_paid_leaves.current_period_title') :
            this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.acquired_paid_leaves.title'),
          tooltip: this.acquiredPaidLeavedTooltip(periodSummary),
          subtitle: isCurrentPeriod ?
            this.$t(
              'employees.tabs.counters.paid_leaves_counter.summary_inserts.paid_leaves.subtitle',
            ) :
            null,
        },
        {
          content: taken,
          isBlank,
          title: this.$t(
            'employees.tabs.counters.paid_leaves_counter.summary_inserts.taken_paid_leaves.title',
          ),
          tooltip: this.takenPaidLeavesTooltip(
            takenFromNextPeriod,
            takenThisPeriod,
            startDate?.year(),
            endDate?.year(),
          ),
        },
        {
          content: balance,
          isBlank,
          title: this.$t(
            'employees.tabs.counters.paid_leaves_counter.summary_inserts.remaining_paid_leaves.title',
          ),
          tooltip: this.remaingingPaidLeavesTooltip(periodSummary),
          subtitle: !isCurrentPeriod ?
            this.$t(
              'employees.tabs.counters.paid_leaves_counter.summary_inserts.remaining_paid_leaves.subtitle',
              {
                consumption_date: endDate
                  ?.add(1, 'years')
                  .format('Do MMMM YYYY'),
              },
            ) :
            null,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-paid-leaves-counter__pto-tracker {
  display: flex;
  padding: 40px 24px 26px;
  background: $sk-white;

  &__tracker-wrapper {
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 32px;
    }
  }

  &__title {
    font-weight: $fw-semi-bold;
    margin-bottom: 8px;
    line-height: normal;
  }

  &__card {
    height: 100%;
  }
}
</style>
