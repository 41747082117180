<template>
  <Input
    v-model="password"
    :placeholder="placeholder"
    :errored="errored"
    :required="required"
    type="password"
  />
</template>

<script>
// Components
import { Input } from '@app-js/static/shared/components/ui';

export default {
  name: 'Password',
  components: {
    Input,
  },
  props: {
    value: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
