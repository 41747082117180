<template>
  <router-link
    v-if="isRouterLink"
    :to="to"
    :class="{ 'router-link-active': active }"
    class="dot-link"
  >
    <span class="dot-link__anchor">
      {{ anchorText }}
    </span>
  </router-link>
  <a
    v-else
    :href="to"
    class="dot-link"
    :class="{ 'router-link-active': active }"
  >
    <span class="dot-link__anchor">
      {{ anchorText }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'DotLink',
  props: {
    anchorText: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    isRouterLink() {
      // FIXME: all tags should be router-link when v3 is completed
      return typeof this.to !== 'string';
    },
  },
};
</script>

<style lang="scss" scoped>
.dot-link {
  text-align: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .dot-link__anchor {
    color: $sk-black;

    &:hover {
      color: $sk-blue;
    }
  }
}

.dot-link.router-link-active {
  .dot-link__anchor {
    color: $sk-blue;
  }
}
</style>
