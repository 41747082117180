export class HolidaysService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  bulkCreate(shopId, holidaysToCreate) {
    const bulkCreateUrl = `/v3/api/shops/${shopId}/holiday_settings/bulk_create`;

    let createPromise = null;

    if (holidaysToCreate !== undefined) {
      createPromise = this.httpClient.post(
        bulkCreateUrl,
        { holidays_settings: holidaysToCreate });
    } else {
      createPromise = this.httpClient.post(bulkCreateUrl);
    }

    return createPromise;
  }

  bulkUpdate(shopId, holidaysToUpdate, year) {
    return this.httpClient.patch(
      `/v3/api/shops/${shopId}/holiday_settings/bulk_update?year=${year}`,
      { holidays_settings: holidaysToUpdate });
  }

  bulkDelete(shopId, holidaySettingIds) {
    return this.httpClient.delete(
      `/v3/api/shops/${shopId}/holiday_settings/bulk_delete`,
      { data: { ids: holidaySettingIds } });
  }

  deleteAll(shopId) {
    return this.httpClient.delete(
      `/v3/api/shops/${shopId}/holiday_settings/delete_all`);
  }
}
