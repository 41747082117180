<template>
  <img
    width="28"
    src="./images/logo-extalys.png"
  >
</template>

<script>
export default {
  name: 'Extalys',
};
</script>
