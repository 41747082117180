<template>
  <div class="organisation-container">
    <div class="organisation-separator" />
    <div
      v-show="!onboardingLoading"
      id="organisation-settings"
      class="organisation-settings__wrapper"
    >
      <Sidebar v-if="showSidebar" />
      <div class="organisation-settings__container">
        <div :class="innerContentClass">
          <router-view
            v-if="!loading"
            @toggle-sidebar="toggleDisplaySidebar"
            @onboarding-loaded="onboardingLoaded"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import Sidebar from './shared/components/Sidebar';

export default {
  name: 'OrganisationSettings',
  components: {
    Sidebar,
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => vm.checkAuthorizedTab());
  },
  data() {
    return {
      loading: true,
      showSidebar: true,
      onboardingLoading: true,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentOrganisation', ['unsavedChangesToCurrentOrganisation']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    innerContentClass() {
      return {
        'organisation-settings__fixed-inner': this.showSidebar,
      };
    },
  },
  watch: {
    currentLicense() {
      this.checkAuthorizedTab();
    },
  },
  created() {
    this.setBrowserTabTitle();
    this.updateCurrentShop({ shopId: 'all' });
    this.selectOrganisation(this.currentOrganisation);
    this.onboardingLoading = this.$route.hash === '#onboarding';
    this.setNavbarVisibility(this.$route.hash !== '#onboarding');
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapMutations(['setNavbarVisibility']),
    ...mapActions('navContext', ['selectOrganisation']),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('currentOrganisation', ['squashOriginalCurrentOrganisationData', 'updateCurrentOrganisation']),

    toggleDisplaySidebar() {
      this.showSidebar = !this.showSidebar;
    },
    onboardingLoaded() {
      this.onboardingLoading = false;
    },

    checkAuthorizedTab() {
      if (!this.currentLicense.id) return;

      // if System admin: allowed automatically
      if (this.isSystemAdmin) {
        this.loading = false;
        return;
      }

      const currentRoute = this.$router.currentRoute.name;
      const canAccessLicenses = this.currentLicense.attributes.canEditShopPermissions;
      const canAccessShops = this.currentLicense.attributes.canCreateShop;

      // if allowed in the current tab: allow access
      if (currentRoute === 'organisation_settings_licenses' && canAccessLicenses) {
        this.loading = false;
        return;
      }
      if (currentRoute === 'organisation_settings_shops' && canAccessShops) {
        this.loading = false;
        return;
      }

      // if not allowed in the current tab, but allowed in another tab: redirect to other tab
      if (canAccessShops) {
        const routeName =
          this.currentOrganisation.attributes.clusters ?
            'organisation_settings_clusters' :
            'organisation_settings_shops';
        this.$router.push({ name: routeName });
        this.loading = false;
        return;
      }
      if (canAccessLicenses) {
        this.$router.push({ name: 'organisation_settings_licenses' });
        this.loading = false;
        return;
      }

      // if not allowed in any tab, redirect to home
      window.location = '/';
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.$t('organisation_settings.tab_title', {
          organisationName: this.currentOrganisation.attributes.name,
        });
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .organisation-separator {
    height: 1px;
    background-color: $sk-grey-10;
  }

  .organisation-settings__wrapper {
    flex: 1;
    overflow-y: auto;
    display: flex;

    div.organisation-settings__container {
      flex: 1;
      overflow: auto;

      div.organisation-settings__fixed-inner {
        max-width: 1060px;
        padding: 25px 0 0 40px;
      }
    }
  }
}
</style>
