/*
#######################################################################################
# !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! #
#######################################################################################
##               FILE USED FOR BATCHED RELEASE OF SVC USER LOGINS                    ##
##                    IT MUST ALWAYS BE EDITED ALONG WITH                            ##
##                   app/models/svc_user/canary_domains.rb                           ##
#######################################################################################
# !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! #
#######################################################################################
*/
export const INCLUDED_DOMAINS = Object.freeze([
  'laligue44.org',
  'moncler.com',
  'arpej-reze.fr',
  'numeriks.fr',
  'googlemail.com',
  'naoshotel.com',
  'popinns.com',
  'sateb.net',
  'indy.fr',
  'omiq.es',
  'octopusenergy.fr',
  'vapohm.fr',
  'paris-society.com',
  'live.be',
  'deco-flamme.com',
  'yopmail.com',
  'scr.re',
  'hotelbb.com',
  'auxine-logistique.com',
  'mail.te',
  'akt.io',
  'saintjuliendeconcelles.fr',
  'alsace.eu',
  'lemoulindesigny.fr',
  'acsomur.fr',
  'cegetel.net',
  'team.coursier.fr',
  'pokawa.com',
  'ca-cotesdarmor.fr',
  'lapioche.fr',
  'elloha.com',
  'rt-hartmann.fr',
  'mfr.asso.fr',
  'nextories.com',
  'anibio.fr',
  'flyops.net',
  'aliceadsl.fr',
  'ukr.net',
  'easy2call.fr',
  'resonance.golf',
  'les3brasseurs.com',
  'lestelsia-casinos.fr',
  'salobrehotel.com',
  'dbmail.com',
  'ville-valenton.fr',
  'lerenouveau88.fr',
  'ca-bretagne.fr',
  'cite-esperance.org',
  'rentacar.fr',
  'mrbricolage.fr',
  'alises54.fr',
  'boulangerie-ange.fr',
  'inurbamobility.com',
  'socotec.com',
  'lamiedepain-boulangerie.fr',
  'vendezvotrevoiture.fr',
  'cce.fr',
  'landaise-menuiserie.fr',
  'alie974.fr',
  'polene-paris.com',
  'atlasfondations.com',
  'legrenier-bio.fr',
  'ambassadair.fr',
  'gamil.com',
  'feuillette.fr',
  'depann2000.com',
  'cmsvacances.fr',
  'eibnice.fr',
  'padoa-group.com',
  'institutpaulbocuse.com',
  'rutronik.com',
  'relaisthalasso.com',
  't-online.de',
  'gbh.fr',
  'volt-corp.com',
  'thot-it.com',
  'domitys.fr',
  'heliairmonaco.com',
  'biltoki.com',
  'labrigadedevero.com',
  'djulajewelry.com',
  'sabrinamontecarlo.com',
  'marcel.cab',
  'csjc.corsica',
  'adsea77.fr',
  'epoca.health',
  'koezio.co',
  'gmai.com',
  'lfdj.com',
  'topsec.fr',
  'sylq.io',
  'yellohvillage.com',
  'mnt37.fr',
  'vitalaire.fr',
  'lezingam.com',
  'blue-elements.com',
  'bam-karaokebox.com',
  'divattesurloire.fr',
  'lagardere-tr.fr',
  'hanagroup.eu',
  'digital4b.fr',
  'ieseg.fr',
  'lacompagniedu20e.fr',
  'altras.ch',
  'ac-montpellier.fr',
  'das-festspielhaus.de',
  'alliance-energies.fr',
  'netcourrier.com',
  'sellor.com',
  'villarddelans.com',
  'lrdm.eu',
  'elevacapital.com',
  'bs-solution.com',
  'quiquedacosta.es',
  'ige-xao.com',
  'wp.pl',
  'biomatlante.com',
  'comabric.net',
  'previseo.fr',
  'wikicampers.com',
  'monoprix.fr',
  'sciencespo.fr',
  'mairie-colomiers.fr',
  'theroof.fr',
  'buly1803.com',
  'castel-freres.re',
  'ovatis.fr',
  'jour.fr',
  'chainethermale.fr',
  'lesevents.org',
  'popchef.com',
  'audio-2000.com',
  'pb-groupe.com',
  'anicura.es',
  'rocketmail.com',
  'connexite-sante.com',
  'cananga.net',
  'starcroisieres.com',
  'cubyn.com',
  'synaps.one',
  'breizhcafe.com',
  'leslipfrancais.fr',
  'dune-asbl.be',
  'fitnesspark-martinique.com',
  'tam-way.com',
  'ubble.ai',
  'mios.leclerc',
  'mamashelter.com',
  'msm-normandie.fr',
  'hoomy.fr',
  'ideliasante.fr',
  'haschill.com',
  'free-now.com',
  'kindredgroup.com',
  'lilo.org',
  'ratpsmartsystems.com',
  'delarte.fr',
  'enedis.fr',
  'optic2000royer.fr',
  'areas.com',
  'carcassonne-agglo.fr',
  'groupebeaumontguez.fr',
  'rebesferrer.com',
  'franchise.cmarket.fr',
  'easyhotel.com',
  'makeenenergy.com',
  'planetoceanworld.fr',
  'pharmaciedugrandcap.com',
  'ehl.ch',
  'ceme-sa.com',
  's2k.fr',
  'fnacdarty.com',
  'nexity.fr',
  'velyvelo.com',
  'nicotech.fr',
  'emeraldstay.com',
  'adventuregroup.fr',
  'test.com',
  'tri-cycle.fr',
  'groupebk.com',
  'emileetrosa.fr',
  'jacquemus.com',
  'labeilleverte.net',
  'groupelamerepoulard.com',
  'ecla.com',
  'terrabotanica.fr',
  'distriprot.com',
  'kedgebs.com',
  '24-7services.eu',
  'temple-nobleart.com',
  'distrimaxmayotte.com',
  'recode.fr',
  'toutetbon.fr',
  'altrad.com',
  'ouest-biologie.com',
  'pizza-pai.fr',
  'ekonsilio.com',
  'adee.fr',
  'rocade.fr',
  'ami2.com',
  'prismamedia.com',
  'brooklynfitboxing.com',
  'institutlyfe.com',
  'mailo.com',
  'alpi.fr',
  'lilyofthevalley.com',
  'clinique-veto.fr',
  'dijonbourgogne-events.com',
  'responsage.com',
  'edu.ferrandi-paris.fr',
  'avossoins.fr',
  'cinewest.fr',
  'eataly.fr',
  'team-break.fr',
  'actioncoupdepouce.org',
  'dazestore.com',
  'fer-play.fr',
  'mac.com',
  'point-smoke.fr',
  'lefenouil-biocoop.fr',
  'imrs95.com',
  'ribeirou.fr',
  'trampolinepark.fr',
  'okkohotels.com',
  'vet-alfort.fr',
  'rubi.com',
  'chezmeunier.fr',
  'passion-graphic.fr',
  'labienaimee.com',
  'savoirvivre.paris',
  'abatik.com',
  'latelierdamaya.com',
  'shopopop.com',
  'so-tec.fr',
  'jpr-distribution.com',
  'cglc.fr',
  'biovet.fr',
  'pharmacieanglofrancaise.fr',
  'manavamoorearesort.pf',
  'gorgesdugardon.fr',
  'teamactive.fr',
  'vertical-art.fr',
  'chlorophylle-coop.com',
  'info-services.biz',
  'balzac-paris.fr',
  'hotels-emeraude.com',
  '3bois.fr',
  'planningfamilial59.org',
  'nestorparis.com',
  'barnes-international.com',
  'hypercacher.com',
  'mister-garden.com',
  'maisondelachimie.com',
  'soliha.fr',
  'intersport-segef.com',
  'voyagesante.fr',
  'essec.edu',
  'syncor.fr',
  'pernod-ricard.com',
  'tropicalementvotre.com',
  'bakjy.com',
  'acatfrance.fr',
  'pierre-fabre.com',
  'petrossian.fr',
  'gsab.fr',
  'batir.mq',
  'oiko.eu',
  'semna.fr',
  'crescendo-restauration.fr',
  'afsplomberiecvc.fr',
  'japhy.fr',
  'baofamily.co',
  'rivedroite-paris.com',
  'osullivans-pubs.com',
  'benebono.co',
  'voiapp.io',
  'avenir-renovations.fr',
  'fontevraud.fr',
  'jobo.paris',
  'pssmfrance.fr',
  'lescuistotsmigrateurs.com',
  'etaples.leclerc',
  'skema.edu',
  'live.com.pt',
  'dedienne-aero.com',
  'rubrash.com',
  'essca.eu',
  'portcros-parcnational.fr',
  'dermae.fr',
  'blockout.fr',
  'roussel-international.com',
  'harrywinston.com',
  'offisecure.com',
  'lestavernes.com',
  'ymca-rocheton.fr',
  'lsdiag.fr',
  'hiflow.com',
  'vuelvecarolina.com',
  'enavanttoutes.fr',
  'grupomosh.com',
  'imc-telecom.fr',
  'temoana.pf',
  'compagnons-du-devoir.fr',
  'veterinaire-domancy.fr',
  'groupeubique.com',
  'ampere-emob.com',
  'les-carmes-haut-brion.com',
  'qiiro.eu',
  'fermedesainteluce.com',
  'pharmaciemoreau.com',
  'lavinia.com',
  'scab-artipole.fr',
  'montisport.fr',
  'lasergame-evolution.com',
  'rdholding.fr',
  'skello.com',
  'airfrance.fr',
  'ecasso.fr',
  'rhone-dental-clinic.ch',
  'sodexo.com',
  'thefrenchfix.com',
  'artimat-distribution.fr',
  'gmx.net',
  'soss.lu',
  'catella.fr',
  'labio.fr',
  'mba-france.eu',
  'deichmann.com',
  'trio-tech.com',
  'gruporicardosanz.com',
  'stl-nautisme.com',
  'llisanegra.com',
  'veyrat-equipement.com',
  'bdspolytechgrenoble.fr',
  'pumpkin-app.com',
  'adn-sante.com',
  'ratp.fr',
  'mawersa.com',
  'groupe-seche.com',
  'lecab.fr',
  'lamaisondetompouce.com',
  'lilian-berillon.fr',
  'zouvai.com',
  'les-sablons.com',
  'izee.fr',
  'carmignac.com',
  'cid-france.com',
  'carlili.fr',
  'queven.leclerc',
  'irisoptic.fr',
  'caille.com',
  'benetton.com',
  'ditesco.fr',
  'philip-shp.com',
  'biarritzsauvetagecotier.com',
  'studycoach.eu',
  'thefrenchbastards.fr',
  'cykleo.fr',
  'socsa.fr',
  'elpobletrestaurante.com',
  'phportesdetoulon.com',
  'uxco-management.com',
  'lameridionale.fr',
  'skynet.be',
  'clinadent.com',
  'pm.me',
  'paack.co',
  'fruitsrougesduperigord.com',
  'mondialfeu.fr',
  'cimalp.fr',
  'mailinator.com',
  'cote-albatre.com',
  'u-bordeaux.fr',
  'provence-europagro.eu',
  'amalivre.fr',
  'lockall.fr',
  'ocspecial.com',
  'logicann.fr',
  'biocoopdm.fr',
  'grupconfiteria.com',
  'armandheitz.com',
  'abctaxis.ch',
  'citedesechanges.com',
  'pax-ing.fr',
  'bonjourbonsoir.paris',
  'bioburger.fr',
  'actemium.com',
  'elizerestauration.fr',
  'qq.com',
  'groupecrequy.fr',
  'radissonblu.com',
  'mhfitpro.com',
  'godaddy.com',
  'dac-apsante60est.fr',
  'mce-transport.com',
  'giet-bodarwe.be',
  'heetch.com',
  'thiriet.com',
  'sbh.fr',
  'brittany-ferries.fr',
  'audencia.com',
  'playground-event.fr',
  'segraeti.com',
  'madurest.com',
  'el-martillo.com',
  'sporting-form.fr',
  'ecolekoenig.com',
  'opticlegrand.net',
  'climbingdistrict.com',
  'tradinglabgroup.com',
  'soretail.re',
  'nigloland.fr',
  'gemstar-brands.com',
  'drof.fr',
  'grouperomanee.com',
  'alpazurhotels.com',
  'wesk.mobi',
  'eatsalad.com',
  'jardiland.com',
  'lacoteetlarete.fr',
  'b-upclermont.fr',
  'iesc.fr',
  'my.com',
  'mado.re',
  'dometvie.fr',
  'evon.energy',
  'honotel.com',
  'miirohotels.com',
  'pharmanature.fr',
  'hi-cdg.com',
  'lcac.pro',
  'gmx.at',
  'publicisdrugstore.com',
  'mcvet.fr',
  'andresy-confitures.fr',
  'altitop.com',
  'freshburritos.com',
  'inseec-france.com',
  'jungle.bio',
  'omycare.fr',
  'panorama.group',
  'interia.pl',
  'deltaboisnegoce.com',
  'ephaco.com',
  'aptima.fr',
  'galerieslafayette.com',
  'aceservices-cnd.fr',
  'fhegroupe.com',
  'tvpaint.fr',
  'ac-creteil.fr',
  'tlv-conciergerie.com',
  'somme.fr',
  'ulysse-transport.fr',
  'totum.fr',
  'tbs-education.org',
  'ipag.fr',
  'biocooplaubre.fr',
  'cevennes-mont-lozere.fr',
  'jacob-primeurs.com',
  'maulersas.fr',
  'sistem-provence.fr',
  'riou.solutions',
  'ainu.fr',
  'lombard-gedimat.com',
  'vatel.mg',
  'postigoauditores.es',
  'hostnfly.com',
  'kidsandus.es',
  'tunon.org',
  'mon-marche.fr',
  'importmontes.com',
  'domainedespresverts.com',
  'rubis-materiaux.com',
  'grandir-nature.com',
  'armitiere.com',
  'gedimat.fr',
  'delidess.fr',
  'burger.fr',
  'suez.com',
  'edenphone.com',
  'lagardere-paris-racing.fr',
  'rosabonheur.fr',
  'catalys-conseil.fr',
  'falogistics.fr',
  'hitrental.com',
  'baresto.fr',
  'mih-invest.fr',
  'goutrouge.com',
  'gpb-dieuze-morhange.fr',
  'mmmmaaaiiilll.te',
  'mmmmaaaiiill.te',
  'mmmmaaiiill.te',
  'futtererproperty.com',
  'juriprest.fr',
  'greenfactory.fr',
  'moulinrouge.fr',
  'centreoeil.fr',
  'esm-telecom.com',
  'ragazzi-gp.fr',
  'arevcollection.com',
  'groupe-delineo.fr',
  'jaimelagalette.com',
  'contex.fr',
  'edu.em-lyon.com',
  'groupegca.com',
  'cfc-clim-froid.com',
  'groupe-mels.com',
  'groupementhk.fr',
  'auchan.fr',
  'abv.bg',
  '3vhotels.com',
  'solidaritesante63.org',
  'magma-group.fr',
  'neonoe.com',
  'cityone.fr',
  'doneo.co',
  'systemessolaires.fr',
  'cabinetdentairec2s.fr',
  'luxeuil-vosges-sud.fr',
  'nhow-hotels.com',
  'drinksandco.com',
  'bricosducoeur.org',
  'premium-conciergerie.com',
  'cm-partenaires.com',
  'gustofamily.com',
  'bigsquadra.com',
  'ducasse-paris.com',
  'cabaia.fr',
  'lombard-mougenot.com',
  'sports-aventure.fr',
  'mata-conseil.fr',
  'vaires-torcy.iledeloisirs.fr',
  'leslunetiers.fr',
  'theothers.tv',
  'lacollecterie.org',
  'o2.pl',
  'eurodonti.com',
  'spa-avenue.fr',
  'groupe-martin.fr',
  'zaacom.com',
  'parcanimalierdauvergne.fr',
  'prout.fr',
  'akeonet.com',
  'maison-kayser.com',
  'naver.com',
  'medetram.yt',
  'id-pub.fr',
  'bolt.eu',
  'frasershospitality.com',
  'pleaseapp.com',
  'arvernenergies.fr',
  'cha.fr',
  'the-box.fr',
  'mairie-combs-la-ville.fr',
  'exsel.re',
  'hauserwirth.com',
  'getluko.com',
  'hollysdiner.fr',
  'hexagone-mutualite-courtage.fr',
  'gruppopulcinella.com',
  'pradierparis.com',
  'cabinet-acce.fr',
  'ninametayer.com',
  'gsf.fr',
  'aireolia.com',
  'residencesmedicis.com',
  'meeko.pro',
  'sorella-care.com',
  'vsip-group.fr',
  'centralcom.fr',
  'sovadom.com',
  'bureau-saveurs.com',
  'assurance-maladie.fr',
  'groupebk.fr',
  'cafedesimages.fr',
  'lagoresortmenorca.com',
  'depannage3j.com',
  'aubureau.fr',
  'westminster-nice.com',
  'prugent.com',
  'naturabuy.fr',
  'mail.fr',
  'virgilio.it',
  'lechateaudelatour.fr',
  'relentlessco.co',
  'nebuleusebijoux.com',
  'restaurantsdelille.com',
  'kemmrod.fr',
  'santeformapro.com',
  'upyourbizz-tm.fr',
  'neoma-bs.com',
  'gks.re',
  'tutanota.com',
  'immav.fr',
  'cimholding.com',
  'galactusinvest.com',
  'netheos-services.com',
  'bieristan.fr',
  'icould.com',
  'tartinedebonheur.fr',
  'emma-patisserie.fr',
  'actemotel.com',
  'trentotto.fr',
  'atlantic-ingenierie.com',
  'appeldulivre.fr',
  'telefonica.net',
  'ati-isolation.com',
  'hotelvillam-paris.com',
  'la-clinique-e-sante.com',
  'noos.fr',
  'lpofrance.com',
  'altermundi.com',
  'vatel.re',
  'memo-i.com',
  'parcdupetitprince.com',
  'hotel-fleur-depee.com',
  'gmal.com',
  'leserignanplage.com',
  'hapimag.com',
  'novadvice.fr',
  'vert-marine.com',
  'unit-consulting.fr',
  'lafoodconnection.com',
  'odontia.fr',
  'msmateriaux.fr',
  'augusteetferdinand.com',
  'numerize.com',
  'tiime.fr',
  'plantula.fr',
  'califrais.fr',
  'la-tournee.co',
  'campinggavina.com',
  'teamorthosmile.fr',
  'haviland.fr',
  'condalchef.com',
  'evergreen-hotels.com',
  'sodimatmedical.fr',
  'irm4pav.fr',
  'cimms.fr',
  'lecreusetdart.fr',
  'horace.co',
  'splsudplaisance.fr',
  'papillonsblancs-dunkerque.fr',
  'meert.fr',
  'cabotbordeaux.com',
  'hostalempuries.com',
  'triple-d.fr',
  'yay.paris',
  'adenat.fr',
  'chmvt.com',
  'caviarkaspia.com',
  'croix-rouge.fr',
  'palaisdesthes.com',
  'intermarche-angers.com',
  'skl.io',
  'cibelec.fr',
  'groupelacitadelle.com',
  'pionniers.co',
  'axioncom.fr',
  'acciome08.fr',
  'summerhotelsgroup.com',
  'beginrestaurante.com',
  'opera-intendance.fr',
  'naturalgrass.fr',
  'natoora.com',
  'atlant-services.com',
  'bagelstein.com',
  'maud.fr',
  'tryba.fr',
  'example.com',
  'apf.fr',
  'sushishop.fr',
  'matsuri.fr',
  'pressequip.fr',
  'hotelchristopher.com',
  'fivan.org',
  'cowool.co',
  'nomadeshop.com',
  'sogeho.com',
  'cite-hotels.com',
  'seati-services.com',
  'homecinesolutions.fr',
  'portovenere.fr',
  'urpsml-centre.org',
  'acje91.fr',
  'biocoop53.fr',
  'midica.fr',
  'odento.fr',
  'lespetitesfamilles.fr',
  'roz-marine-thalasso.com',
  'metropole.me',
  'franprix.fr',
  'grupobellaciao.com',
  'bsp-auto.com',
  'oleron-meubles.fr',
  'op.tersea.com',
  'vahle.com',
  'breathe.life',
  'ortho-sante.eu',
  'valvignes.com',
  '369degres.com',
  'gtpgestion.com',
  'idfdistribution.com',
  'iflyworld.com',
  'eneregie68.com',
  'symphonie-hotels.com',
  'dfo-troyes.com',
  'espace-lab.ch',
  'demenagerfacile.com',
  'junohouseclub.com',
  'supervan.fr',
  'etu.univ-amu.fr',
  'evasionauthentic.com',
  'gmx.ch',
  'amad-loire.fr',
  'suitcase-hospitality.com',
  'cybertek.fr',
  'gamail.com',
  'freenet.de',
  'laviesaine.fr',
  'lbf.beer',
  'tchatbooster.com',
  'lcagroupe.fr',
  'alzeoenvironnement.com',
  'streetbangkok.fr',
  'hotel-coteouest.com',
  'hotelcottonhouse.com',
  'weareelevate.global',
  'akoya-hotel.com',
  'wingflavors.fr',
  'cofrex.fr',
  'leperchoir.fr',
  'leretouralaterre.fr',
  'eiffelseine.com',
  'clim-on.com',
  'w44.fr',
  'hoteletperformance.com',
  'asap-telecom.fr',
  'skell.com',
  'chikaranomoto.com',
  'hermes-plage.fr',
  'sinestresibiza.com',
  'losnaranjos.com',
  'mailfggdfkfgcgfghfh14jjkjlppll.te',
  'mailfggdfkfgcgfghfhgg14jjkjlppll.te',
  'mailfggdfkfgcgghfh14jjkjlppll.te',
  'mail.test',
  'ubaye.com',
  'maksyma.fr',
  'avicenter.fr',
  'wellsandco.com',
  'sa-autrement.com',
  'medilab66.fr',
  'carrementfleurs.fr',
  'mmail.te',
  'mmmail.te',
  'batir-martinique.com',
  'mmmmaaiill.te',
  'mmmmaaiil.te',
  'mmmmaail.te',
  'mmmmail.te',
  'monsantbenet.com',
  'mouratoglou-resort.com',
  'callandco.fr',
  'naoshotelgroupe.com',
  'acqs.com',
  'premiereclasse.fr',
  'bycharlot.com',
  'groupeverlaine.fr',
  'infamail.com',
  'live.co.uk',
  'apee-na.org',
  'devor.me',
  'trusk.com',
  'cml-aubagne.com',
  'soleo13.com',
  'coyarestaurant.com',
  'plaqchocolat.com',
  'poiscaille.fr',
  'in2strides.com',
  'domain.io',
  'languedoc-mutualite.fr',
  'cotesushi.com',
  'cafedesbains.com',
  'kellydeli.com',
  'cdm-hotelspa.com',
  'immogroom.com',
  'cajoo.eu',
  'detektin.com',
  'amadigital.fr',
  'fugustructures.com',
  'ouestelio.fr',
  'test.fr',
  'highsociety.fr',
  'baila.com',
  'tardyentreprises.com',
  'sasjeangoyty.fr',
  'ugolf.eu',
  'autocines.com',
  'suntory.com',
  'lesmaisonsdecampagne.com',
  'yakablois.fr',
  'la-demesure.com',
  'fr.boulanger.com',
  'noseparis.com',
  'biocoop-cholet.fr',
  'iclood.com',
  '3vh.fr',
  'jesuisavelo.com',
  'blank.app',
  'praia-irmao.com',
  'mjcmontlucon.fr',
  'abr.coop',
  'fidcoma.lu',
  'fmv-pressurage.fr',
  'mairie-saint-bonnet.net',
  'ecoidees.com',
  'conciergebb.fr',
  'main-gauche.com',
  'codimas.com',
  'mknfrance-elec.com',
  'pastel-cycles.com',
  'adrisport.fr',
  'bk.ru',
  'montblanc-collection.com',
  'castorama.fr',
  'spline.fr',
  'le-patacrepe.com',
  'mclloyd.com',
  'dentylis.fr',
  'groupecv.fr',
  'monstade.fr',
  'grandepharmacieducentre.fr',
  'lafeesylda.fr',
  'hdtech-solution.com',
  'groupefhb.fr',
  'mspqs.fr',
  'cresus.fr',
  'casyope.fr',
  'groupeamadeus.com',
  'stiilt.com',
  'pl.chambagri.fr',
  'liberact.fr',
  'live.de',
  'cree.re',
  'hom-eva.fr',
  'hogsalt.com',
  'institutdesactuaires.fr',
  'nautipolis.fr',
  'cassis.fr',
  'appui-sante-cba.fr',
  'groupe-gf.fr',
  'kpmg.fr',
  'issey-europe.com',
  'bancodeboquerones.com',
  'ednh.fr',
  'quicksign.org',
  'meltparis.com',
  'blotweb.com',
  'golfmoliets.com',
  'koezio.be',
  'menton-riviera-merveilles.fr',
  'sharies.co',
  'groupecristal.fr',
  'rexclub.com',
  'luludansmarue.org',
  'cargonautes.fr',
  'radiologie60.fr',
  'govirtuo.com',
  'caramail.fr',
  'minimistan.org',
  'tgs-france.fr',
  'caractere-imprimeur.com',
  'reseau3s.fr',
  'peyrassol.com',
  'hotel-marinca.fr',
  'physalie.fr',
  'waldorfastoria.com',
  'education.gouv.fr',
  'reneoltra.fr',
  'defrance-c2mh.com',
  'campus.ehg.ch',
  'jmfinvest.fr',
  'relaisdechambord.com',
  'reeftechnology.com',
  'canantes.com',
  'lemeteor.fr',
  'bmsconseil.com',
  'hotelfrenchcoco.com',
  'strasbourg.eu',
  'christophemichalak.com',
  'ledomedevincennes.fr',
  'heschung.com',
  'livezoku.com',
  'chameleonfitness.net',
  'ecomail.fr',
  'magicpass.ch',
  'aquoisia.fr',
  'five-senses.co.uk',
  'briquehouse.fr',
  'vespok360.com',
  'ekstend.io',
  'sportipolis.fr',
  'nordnet.fr',
  'ateau.fr',
  'hotels-fhi.com',
  'halluneed.com',
  'ljbtn.com',
  'citerneo.com',
  'clarisse-environnement.fr',
  'ynov.com',
  'humansconnexion.com',
  'gruppomimo.com',
  'wildandthemoon.com',
  'savewater-tmb.fr',
  'urbancanopee.com',
  'sauleda.cat',
  'centreaquatiquenungesser.fr',
  'urbakery.com',
  'live.com.ar',
  'ik.me',
  'cei.fr',
  'libertysurf.fr',
  'makifornia.be',
  'ac-versailles.fr',
  'courtyard-sd.com',
  'coutumecafe.com',
  'mangerbiosudouest.fr',
  'hotelmarignan.fr',
  'gamarde-les-bains.com',
  'eel.fr',
  'familianuri.com',
  'domainedefontenille.com',
  'catinvest.fr',
  'sme-sarl.com',
  'creaflex.nc',
  'syndeac.org',
  'geolotus.fr',
  'castellmiquel.com',
  'burdigala.com',
  'epi1959.com',
  'groupe-rush.com',
  'groupe-pvg.fr',
  'up2play.fr',
  'inwood-hotels.com',
  'quai-cyrano.com',
  'cvmoder.com',
  'animaretz.fr',
  'gmil.com',
  'staffmatch.com',
  'irm68.com',
  'lexipaie.com',
  'rocabella.fr',
  // BATCH 1 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 2
  // 'skello.io',
  // 'neuf.fr',
  // 'msn.com',
  // 'aol.com',
  // 'gmx.fr',
  // 'me.com',
  // 'yahoo.es',
  // 'live.com',
  // 'outlook.es',
  // 'protonmail.com',
  // 'ymail.com',
  // 'gmail.fr',
  // 'aol.fr',
  // BATCH 2 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 3
  // 'outlook.com',
  // 'sfr.fr',
  // 'free.fr',
  // 'wanadoo.fr',
  // 'hotmail.es',
  // BATCH 3 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 4
  // 'yahoo.com',
  // 'live.fr',
  // 'laposte.net',
  // 'hotmail.it',
  // BATCH 4 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 5
  // 'hotmail.fr',
  // 'hotmail.com',
  // 'orange.fr',
  // 'icloud.com',
  // 'outlook.fr',
  // 'weldom.fr',
  // BATCH 5 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 6
  // 'gmail.com',
  // BATCH 6 START HERE: DELETE LINE AND UNCOMMENT UNTIL BATCH 7
  // 'bbox.fr',
  // 'salaries-segef.com',
  // 'beginrestaurante.work',
  // 'audacia-asso.fr',
  // 'jdplcretail.com',
  // 'paris2024.org',
  // 'lemarchedeleopold.com',
  // 'hellopharmacie.com',
  // 'radiologie-imef.fr',
  // 'starbucks.fr',
  // 'franchise.carrefour.com',
  // 'bigmamma.com',
  // BATCH 7 START HERE: DELETE LINE AND UNCOMMENT FOLLOWING LINES
  // 'mousquetaires.com',
  // 'fitnesspark-group.com',
  // 'systeme-u.fr',
  // 'chateauform.com',
  // 'accor.com',
  // 'rimowa.com',
  // 'ilristorante.fr',
  // 'recrea.fr',
  // 'aslairlines.com',
  // 'laduree.com',
  // 'afg-autisme.com',
  // 'cartier.com',
  // 'zoo-amneville.com',
  // 'krys.com',
  // 'terroirs-avenir.fr',
  // 'nousantigaspi.com',
  // 'sidetemp.com',
  // 'club-internet.fr',
  // 'sharko.fr',
  // 'fragonard.com',
  // 'mairie-champigny94.fr',
  // 'live.it',
  // 'fer-expert.fr',
  // 'marceletfils.com',
  // 'bluewin.ch',
  // 'eiffage.com',
  // 'edhec.com',
  // 'afrimarketgroup.com',
  // 'justqueen.com',
  // 'coursier.fr',
  // 'webcup.tech',
  // 'reseaucristal.fr',
  // 'numericable.fr',
  // 'gmx.com',
  // 'welcomeatwork.com',
  // 'azoteagrupo.com',
  // 'mail.com',
  // 'sadfwi.com',
  // 'proton.me',
  // 'paris.fr',
  // 'lesoceades.fr',
  // 'etincelles.com',
  // 'comptastar.fr',
  // 'mouratoglou.com',
  // 'paris-st-denis.com',
  // 'anicura.fr',
  // 'lunettespourtous.com',
  // 'keepcool.fr',
  // 'cooperative-u.fr',
  // 'sophie-lebreuilly.com',
  // 'wellness-sportclub.fr',
  // 'segef.com',
  // 'planetsushi.fr',
  // 'partouche.com',
  // 'chateaudechantilly.fr',
  // BATCH 7 ENDS HERE.
]).join('|');

export const INCLUDED_DOMAINS_REGEXP = new RegExp(`.+@(${INCLUDED_DOMAINS})$`);
