<template>
  <div class="holidays-counter__wrapper">
    <div
      v-if="!holidaysCounterEnabled"
      class="holidays-counter__initialization-overlay__wrapper"
    >
      <div class="counters__initialization-overlay">
        <div class="counters__initialization-overlay__icon">
          <CheckedCalendarIcon
            height="18"
            width="18"
            fill="#2b66fe"
          />
        </div>
        <div class="counters__initialization-overlay__title">
          <h1 class="sk-header--1">
            {{ $t('employees.tabs.counters.holiday_counter.welcome') }}
          </h1>
          <div class="counters__initialization-overlay__description">
            {{ $t('employees.tabs.counters.holiday_counter.init_overlay.upsell_1') }}
            <PackTag
              :type="currentOrganisation.attributes.packOffer.name"
              shadow
            />
            {{ $t('employees.tabs.counters.holiday_counter.init_overlay.upsell_2') }}
            <div class="counters__initialization-overlay__button">
              <SkOroraButton
                type="information"
                @click="clickOnUpsell"
              >
                {{
                  $t('employees.pack_offers_upsell.tooltip.sentence_upsell',
                     { pack_name: $t('employees.pack_offers.premium') }
                  )
                }}
              </SkOroraButton>
            </div>
          </div>
        </div>
      </div>
      <div class="counters__initialization-overlay__background" />
    </div>
    <h3 class="sk-header--3">
      {{ $t('employees.tabs.counters.holiday_counter.title') }}
    </h3>
    <div class="counters__initialization__year-selection">
      <SkSelectV2
        v-model="selectedYear"
        :label="$t('employees.tabs.counters.holiday_counter.select_year')"
        :options="yearOptions"
      />
    </div>
    <div class="holidays-counter__totals-title">
      <strong>
        {{ $t('employees.tabs.counters.holiday_counter.cards.title') }}
      </strong>
      <SkOroraButton
        v-if="canReadEmployeeInfo && holidaysRecoveredShifts.length"
        v-track="'absence_details_public_holidays_recovery'"
        v-modal.holidays-counter-details-modal
        variant="tertiary"
        icon="GraphBarsIcon"
      >
        {{ $t('employees.tabs.counters.holiday_counter.buttons.details') }}
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <HolidaysCounterDetailsModal
            :key="`holidays-recovered-shifts-${year}`"
            :holidays-recovered-shifts="holidaysRecoveredShifts"
            :loading="loadingTable || loadingCards"
          />
        </MountingPortal>
      </SkOroraButton>
    </div>
    <div class="holidays-counter__cards-container">
      <div
        v-tooltip="$t('employees.tabs.counters.holiday_counter.cards.owned_absences.tooltip', {
          maj: shopHolidayMaj,
        })"
        class="holidays-counter__cards"
      >
        <div class="holidays-counter__cards-title">
          {{ $t('employees.tabs.counters.holiday_counter.cards.owned_absences.title') }}
        </div>
        <SkDivCanvas
          v-if="loadingCards"
          :width="190"
          :height="40"
          animated
        />
        <div
          v-else
          class="holidays-counter__cards-data"
        >
          {{ acquiredHolidayRecoveryHours }} h
        </div>
      </div>
      <div
        v-tooltip="$t('employees.tabs.counters.holiday_counter.cards.taken_absences.tooltip', {
          year,
          userName: fullName(employee),
        })"
        class="holidays-counter__cards"
      >
        <div class="holidays-counter__cards-title">
          {{ $t('employees.tabs.counters.holiday_counter.cards.taken_absences.title') }}
        </div>
        <SkDivCanvas
          v-if="loadingCards"
          :width="190"
          :height="40"
          animated
        />
        <div
          v-else
          class="holidays-counter__cards-data"
        >
          {{ holidaysRecoveredShiftsHours }} h
        </div>
      </div>
      <div class="holidays-counter__cards">
        <div class="holidays-counter__cards-title">
          {{ $t('employees.tabs.counters.holiday_counter.cards.remaining_balance.title') }}
          <span class="holidays-counter__question-mark">
            <!-- eslint-disable max-len -->
            <CircledQuestionMarkIcon
              v-tooltip.top.longform="$t('employees.tabs.counters.holiday_counter.cards.remaining_balance.tooltip')"
              width="16"
              height="16"
              fill="white"
            />
            <!-- eslint-enable max-len -->
          </span>
        </div>
        <SkDivCanvas
          v-if="loadingCards"
          :width="190"
          :height="40"
          background-color="#296ced"
          gradient-color="#3176f7"
          animated
        />
        <div
          v-else
          class="holidays-counter__cards-data"
        >
          {{ holidayHoursRemainingBalance }} h
        </div>
      </div>
    </div>
    <p class="holidays-counter__table-title">
      <strong>
        {{ holidaysCounterTableTitle }}
      </strong>
    </p>
    <div
      v-if="loadingTable"
      class="holidays-counter__table-spinner"
    >
      <SkLoader size="large" />
    </div>
    <div v-else-if="!holidaysCounterEnabled">
      <HolidaysCounterTable
        :holidays-settings="currentShopHolidays"
        :holidays-shifts="holidaysShifts"
        show-shifts-row
        as-canvas
      />
    </div>
    <div v-else>
      <HolidaysCounterTable
        :holidays-settings="holidaysSettings"
        :holidays-shifts="holidaysShifts"
        :editable="canEditEmployeeInfo"
        show-shifts-row
        @update-day="updateHolidaysSettings"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { associationMatcherItem } from '@skello-utils/association_matchers';
import skDate from '@skello-utils/dates';
import PackTag from '@app-js/shared/components/PackTag';
import { httpClient } from '@skello-utils/clients';
import { arrayToSelectOptions } from '@skello-utils/form';
import HolidaysCounterTable from '@app-js/shared/components/HolidaysCounterTable';

import HolidaysCounterDetailsModal from './HolidaysCounterDetailsModal';

export default {
  name: 'HolidaysCounter',
  components: {
    HolidaysCounterDetailsModal,
    HolidaysCounterTable,
    PackTag,
  },
  data() {
    return {
      loadingTable: false,
      loadingCards: false,
      holidaysSettings: [],
      holidaysShifts: [],
      holidaysRecoveredShifts: [],
      shopHolidays: [],
      currentYearHolidaysRecoveredShiftsHours: 0,
      lastYearHolidaysRecoveredShiftsHours: 0,
      year: skDate().year(),
      upsellRequested: false,
      holidaysRecoveredShiftsHours: '-',
      acquiredHolidayRecoveryHours: '-',
      holidayHoursRemainingBalance: '-',
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo', 'canReadEmployeeInfo']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('employees', ['fullName', 'yearOfCreation']),

    selectedYear: {
      get() {
        return this.year;
      },
      set(newValue) {
        if (this.year === newValue) return;

        this.year = newValue;
        this.fetchHolidaysSettings();
      },
    },
    activationYear() {
      return this.currentShop.attributes.holidaysCounterActivationYear;
    },
    firstYearDisplayedInHolidayCounter() {
      return Math.max(
        this.activationYear,
        this.yearOfCreation(this.employee),
      );
    },
    currentYear() {
      return skDate().year();
    },
    holidaysCounterConfigured() {
      return this.currentShop.attributes.holidaysCounterConfigured;
    },
    holidaysCounterEnabled() {
      return this.checkPackOfferFlag('holidays_counter_enabled');
    },
    holidaysCounterTableTitle() {
      return this.$t('employees.tabs.counters.holiday_counter.table.title', {
        year: this.year,
      });
    },
    shopHolidayMaj() {
      return this.employee.relationships.convention.attributes.holidayMaj;
    },
    currentShopHolidays() {
      return this.shopHolidays.map(holiday => ({
        id: holiday.name,
        attributes: {
          name: holiday.name,
          date: holiday.date,
        },
      }));
    },
    yearsToDisplayInHolidayCounter() {
      if (!this.holidaysCounterConfigured) return [];
      if (!this.activationYear) return [this.currentYear];

      // Comparison year is the most recent year between holiday counter's activation on the shop and employee creation
      // Create an empty array of length = this.currentYear - firstYearDisplayedInHolidayCounter + 1 (ex: 2021 - 2020 + 1 = 2)
      // and fill it with all the years between firstYearDisplayedInHolidayCounter and currentYear (map) -> [2020, 2021]
      // and reverse it to get years from recent to older ones -> [2021, 2020]
      return Array(this.currentYear - this.firstYearDisplayedInHolidayCounter + 1)
        .fill()
        .map((_, idx) => this.firstYearDisplayedInHolidayCounter + idx)
        .reverse();
    },
    yearOptions() {
      return arrayToSelectOptions(
        this.yearsToDisplayInHolidayCounter,
        value => value);
    },
  },
  created() {
    this.fetchHolidaysSettings();
  },
  methods: {
    fetchHolidaysSettings() {
      if (!this.holidaysCounterEnabled) {
        this.fetchShopHolidays();
      } else {
        this.fetchUserHolidays();
      }
    },
    updateHolidaysSettings(params) {
      const { shopId } = this.employee.attributes;

      httpClient
        // eslint-disable-next-line max-len
        .patch(`/v3/api/users/${this.employee.id}/holiday_settings/${params.id}?shop_id=${shopId}&year=${this.year}`, params)
        .then(response => {
          this.holidaysSettings = response.data.data;
          this.fetchUserHolidays();

          this.$skToast({
            message: this.$t('employees.tabs.counters.holiday_counter.update_success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    fetchUserHolidays(year = this.year) {
      this.loadingTable = true;
      this.loadingCards = true;

      return httpClient
        .get(`/v3/api/users/${this.employee.id}/holidays_counter?year=${year}`)
        .then(response => {
          const payload = response.data;

          this.acquiredHolidayRecoveryHours = payload.acquired_hours;
          this.holidaysRecoveredShiftsHours = payload.recovered_hours;
          this.holidayHoursRemainingBalance = payload.remaining_balance;

          this.holidaysSettings = payload.holiday_settings.data;

          const holidayShifts = payload.holiday_shifts.data;
          holidayShifts.forEach(shift => {
            const poste = associationMatcherItem(shift, payload.holiday_shifts.included, {
              key: 'poste',
              type: 'poste',
            });
            Object.assign(shift.relationships, { poste });
          });
          this.holidaysShifts = holidayShifts;

          const holidaysRecoveredShifts = payload.holiday_recovered_shifts.data;
          holidaysRecoveredShifts.forEach(shift => {
            const shop = associationMatcherItem(shift, payload.holiday_recovered_shifts.included, {
              key: 'shop',
              type: 'shop',
            });
            Object.assign(shift.relationships, { shop });
          });
          this.holidaysRecoveredShifts = holidaysRecoveredShifts;

          this.loadingTable = false;
          this.loadingCards = false;
        })
        .catch(error => {
          let message = this.$t('errors.standard_message');
          if (error.response.status === 404) {
            message = this.$t('employees.tabs.counters.holiday_counter.not_found');
          }

          this.$skToast({
            message,
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    fetchShopHolidays() {
      this.loadingTable = true;

      const params = {
        shop_id: this.employee.attributes.shopId,
        year: this.year,
      };

      httpClient
        .get('/v3/api/holidays', { params })
        .then(response => {
          this.shopHolidays = response.data;
          this.loadingTable = false;
        });
    },
    clickOnUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('employees.pack_offers_upsell.upsell_already_requested'),
          variant: 'success',
          containerId: 'holidays-counter__wrapper',
        });
        return;
      }

      const params = {
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('employees.tabs.counters.holiday_counter.init_overlay.upgrade_pack'),
          upsell_type: 'holidays_counter',
          current_page: 'Compteur de JF',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.upsellRequested = true;
          this.$skToast({
            message: this.$t('employees.pack_offers_upsell.upsell_request'),
            variant: 'success',
            containerId: 'holidays-counter__wrapper',
          });
        })
        .catch(() => {
          this.upsellRequested = true;
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'holidays-counter__wrapper',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.holidays-counter__wrapper {
  position: relative;
  background: white;
  padding: 17px 20px 30px;
}

.holidays-counter__totals-title {
  padding-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1028px;
}

.holidays-counter__cards-container {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.holidays-counter__table-spinner {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.holidays-counter__cards {
  width: 326px;
  height: 100px;
  border-radius: 6px;
  border: 1px solid #dfe3ec;
  padding: 15px 20px 16px;
  margin-left: 25px;

  &:first-of-type {
    margin: 0;
  }

  &:last-of-type {
    background-color: $sk-blue;
    border: none;

    .holidays-counter__cards-title {
      display: flex;
      color: white;
      font-weight: $fw-semi-bold;
    }

    .holidays-counter__cards-data {
      color: white;
    }
  }
}

.holidays-counter__question-mark {
  margin-left: 15px;
}

.holidays-counter__cards-title {
  color: $sk-grey;
  font-size: $fs-text-m;
}

.holidays-counter__cards-data {
  color: $sk-black;
  font-size: $fs-display-xs;
  font-weight: $fw-semi-bold;
}

.holidays-counter__table-title {
  padding-top: 33px;
}

.counters__initialization-overlay__icon {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba($sk-blue, .15);
  vertical-align: middle;
}

.counters__initialization-overlay__background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, .2);
  z-index: 2;
}

.counters__initialization-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 13%;
  left: 25%;
  opacity: 1;
  z-index: 3;
  width: 420px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 6px;
  padding: 25px;
  background-color: white;
}

.counters__initialization-overlay__title {
  text-align: center;
  margin: 10px 40px !important;
}

.counters__initialization-overlay__description {
  margin-top: 10px;
}

.counters__initialization-overlay__button {
  margin-top: 20px;
  padding: 11.5px 14px;
}

.counters__initialization__year-selection {
  margin-top: 9px;
  width: 214px;
}
</style>
