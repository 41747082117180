import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import {
  httpClient,
  msHttpClient,
  svcPosClient,
} from '@skello-utils/clients';
import { svcPosBaseUrl } from '@config/env';

// Compare original vs actual mapping of shops id's
const isDirty = (original, actual) => {
  for (const key in original) {
    if (original[key].id !== actual[key].id) return true;
  }
  return false;
};
const CHIFT_TILLER_ID = 9020;

const initialState = {
  zeltyShopsLoading: false,
  isLightspeedAuthorizing: false,
  lightspeedAuthenticating: false,
  lightspeedShopsLoading: false,
  updatingOrganisationShops: false,
  unsavedChangesOnPartnerTools: false,
  originalMatching: {},
  zeltyMatchingShopsList: {},
  lightspeedMatchingShopsList: {},
  ladditionShopsLoading: true,
  ladditionMatchingShopsList: {},
  ladditionActivated: false,
  integrations: {},
  registeredIntegrations: {},
  hasSvcPosError: false,
  genericPartners: {
    shopsToLink: {},
    linkedShopsToUpdate: {},
  },
};

const mutations = {
  // Zelty
  zeltyPerformingRequest(state) {
    state.zeltyShopsLoading = true;
  },

  zeltyRequestComplete(state) {
    state.zeltyShopsLoading = false;
  },

  setZeltyShop(state, { shopId, newValue }) {
    Object.assign(state.zeltyMatchingShopsList[shopId], newValue);
    state.unsavedChangesOnPartnerTools =
      isDirty(state.originalMatching, state.zeltyMatchingShopsList);
  },

  resetZeltyMatchingShopsList(state) {
    state.zeltyMatchingShopsList = {};
    state.originalMatching = {};
    state.unsavedChangesOnPartnerTools = false;
  },

  setShopToLink(state, { shopIntegrationInfo, shopId }) {
    state.genericPartners.shopsToLink[shopId] = shopIntegrationInfo;
  },

  setLinkedShopsToUpdate(state, { shopIntegrationInfo, shopId }) {
    state.genericPartners.linkedShopsToUpdate[shopId] = shopIntegrationInfo;
  },

  cleanLinkedShopFromStore(state) {
    state.genericPartners = {
      shopsToLink: {},
      linkedShopsToUpdate: {},
    };
  },

  // Lightspeed
  lightspeedShopsPerformingRequest(state) {
    state.lightspeedShopsLoading = true;
  },

  lightspeedShopsRequestComplete(state) {
    state.lightspeedShopsLoading = false;
  },

  lightspeedAuthenticatingPerformingRequest(state) {
    state.lightspeedAuthenticating = true;
  },

  lightspeedAuthenticatingRequestComplete(state) {
    state.lightspeedAuthenticating = false;
  },

  lightspeedAuthorizingPerformingRequest(state) {
    state.isLightspeedAuthorizing = true;
  },

  lightspeedAuthorizingRequestComplete(state) {
    state.isLightspeedAuthorizing = false;
  },

  setLightspeedShop(state, { shopId, newValue }) {
    Object.assign(state.lightspeedMatchingShopsList[shopId], newValue);
    state.unsavedChangesOnPartnerTools =
      isDirty(state.originalMatching, state.lightspeedMatchingShopsList);
  },

  resetLightspeedMatchingShopsList(state) {
    state.lightspeedMatchingShopsList = {};
    state.originalMatching = {};
    state.unsavedChangesOnPartnerTools = false;
  },

  // Common
  setOriginalMatching(state, list) {
    state.originalMatching = cloneDeep(list);
  },

  updatingOrganisationShopsPerformingRequest(state) {
    state.updatingOrganisationShops = true;
  },

  updatingOrganisationShopsRequestComplete(state) {
    state.updatingOrganisationShops = false;
  },

  // TODO: replace providerId by ladditionShopName when available
  setLadditionResponse(state, { shopId, providerId }) {
    Vue.set(state.ladditionMatchingShopsList, shopId, providerId);
    state.ladditionActivated = true;
  },

  ladditionPerformingRequest(state) {
    state.ladditionShopsLoading = true;
  },

  ladditionRequestComplete(state) {
    state.ladditionShopsLoading = false;
  },
  setLadditionActivated(state, value) {
    state.ladditionActivated = value;
  },
  removeLinkedAdditionShop(state, shopId) {
    Vue.delete(state.ladditionMatchingShopsList, shopId);
  },
  resetLaddition(state) {
    state.ladditionShopsLoading = false;
    state.ladditionActivated = false;
    state.ladditionMatchingShopsList = {};
  },
  // SVC-POS
  setProviderRequirements(state, { providerId, requirements }) {
    state.integrations[providerId].requirements = requirements;
  },

  setProviderShops(state, { providerId, shops }) {
    state.integrations[providerId].providerShops = shops;
  },

  fetchingPosProvidersPerformingRequest(state) {
    state.integrations = {};
  },

  fetchingPosProvidersRequestComplete(state, { providers }) {
    const updatedProviders = providers.data.reduce((accumulator, currentProvider) => {
      if (currentProvider?.provider !== 'CHIFT') {
        accumulator[currentProvider.id] = {
          id: currentProvider.id,
          name: currentProvider.name,
          logo: currentProvider.logo,
          active: currentProvider.enabled,
          disabled: false,
          loading: false,
          registeredIntegrations: [],
          providerShops: [],
        };
        return accumulator;
      }

      const existingIntegration = Object.values(state.integrations).find(
        integration => integration.chiftId === currentProvider.id,
      );

      if (!existingIntegration) return accumulator;

      accumulator[existingIntegration.name] = {
        ...existingIntegration,
        active: currentProvider.enabled,
        consumerId: currentProvider.consumerId,
        loading: false,
        pending: !currentProvider.enabled,
      };

      return accumulator;
    }, {});

    state.integrations = { ...state.integrations, ...updatedProviders };
  },

  svcPosPerformingRequest(state) {
    state.integrations = state.integrations || {};
    Object.keys(state.integrations).forEach(providerId => {
      state.integrations[providerId].loading = true;
    });
  },

  svcPosRequestComplete(state) {
    Object.keys(state.integrations).forEach(providerId => {
      state.integrations[providerId].loading = false;
    });
  },

  setSvcPosError(state, hasError) {
    state.hasSvcPosError = hasError;
  },

  setIntegrationEnable(state, { value, providerId }) {
    state.integrations[providerId].active = value;
    this.commit('partnersTools/recomputeDisabledState');
  },

  registerShopIntegration(state, { integration }) {
    let providerId = integration.providerId;

    if (providerId === 'CHIFT') {
      providerId = Object.values(state.integrations).find(
        provider => provider.active,
      ).id;
    }

    state.integrations[providerId].registeredIntegrations.push(integration);
  },

  updateRegisteredShopIntegration(state, { integration }) {
    let providerId = integration.providerId;

    if (providerId === 'CHIFT') {
      providerId = Object.values(state.integrations).find(
        provider => provider.active,
      ).id;
    }

    const index = state.integrations[providerId].registeredIntegrations
      .findIndex(oldIntegration => oldIntegration.shopId === integration.shopId);

    state.integrations[providerId].registeredIntegrations[index] = integration;
  },

  unRegisterShopIntegration(state, { providerId, shopId }) {
    state.integrations[providerId].registeredIntegrations =
      state.integrations[providerId].registeredIntegrations.filter(
        registeredIntegration => registeredIntegration.shopId !== shopId,
      );
  },

  activatingPosIntegration(state, { providerId }) {
    // Prevents being able to activate other providers while this one being activated
    state.integrations[providerId].loading = true;
    this.commit('partnersTools/setIntegrationEnable', { value: true, providerId });
  },

  deactivatingPosIntegration(state, { providerId }) {
    // Prevents being able to activate other providers while this one being activated
    state.integrations[providerId].loading = true;
  },

  cancelledPosIntegrationActivation(state, { providerId }) {
    state.integrations[providerId].loading = false;
    this.commit('partnersTools/setIntegrationEnable', { value: false, providerId });
  },

  completePosIntegrationActivation(state, { providerId, activationSuccess }) {
    state.integrations[providerId].loading = false;
    this.commit('partnersTools/setIntegrationEnable', { value: activationSuccess, providerId });
  },

  setRegistredIntegration(state, { integration }) {
    const providerId = integration.providerId;

    if (providerId) {
      state.registeredIntegrations[providerId] ??= [];
      state.registeredIntegrations[providerId].push(integration);
    }
  },

  resetIntegration(state, { providerId }) {
    state.integrations[providerId].activated = false;
    if (state.integrations[providerId].pending) {
      state.integrations[providerId].pending = false;
    }
    state.integrations[providerId].providerShops = [];
    state.integrations[providerId].registeredIntegrations = [];
  },

  resetRegisteredIntegrations(state) {
    Object.values(state.integrations).forEach(integration => {
      integration.registeredIntegrations = [];
    });
  },

  recomputeDisabledState() {
    // We need to recompute the disabled state of the integrations every time
    // the user changes the state of an integration)
    for (const integration of this.getters['partnersTools/integrationProviders']) {
      integration.disabled = this.getters['partnersTools/shouldDisableIntegration'](integration);
    }
  },

  setChiftProviders(state, { providers }) {
    state.integrations = providers.reduce((accumulator, provider) => {
      accumulator[provider.name] = {
        isSupportingLocations: provider.isSupportingLocations,
        id: provider.name,
        name: provider.name,
        chiftId: provider.chiftIntegrationId,
        logo: provider.iconUrl,
        active: false,
        disabled: false,
        loading: false,
        registeredIntegrations: [],
        providerShops: [],
      };

      return accumulator;
    }, {});
  },
};

const actions = {
  fetchIntegrationsForShops({ commit }, { shopIds }) {
    commit('svcPosPerformingRequest');

    return msHttpClient
      .get(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopIds.join(',') } })
      .then(response => {
        Object.values(response.data).forEach(integration => {
          commit('setRegistredIntegration', { integration });
        });
        return response;
      })
      .catch(error => {
        console.error(error.message);
        commit('setSvcPosError', true);
        throw error;
      })
      .finally(() => {
        commit('svcPosRequestComplete');
      });
  },

  // L'addition
  async fetchLadditionShops({ commit, dispatch, state }, shopIds) {
    commit('ladditionPerformingRequest');
    commit('recomputeDisabledState');

    if (Object.keys(state.registeredIntegrations).length === 0) {
      await dispatch('fetchIntegrationsForShops', { shopIds });
    }

    Object.values(state.registeredIntegrations).forEach(providerIntegrations => {
      Object.values(providerIntegrations)
        .filter(integrationEntity => integrationEntity.providerId === 'ADDITION')
        .forEach(integrationEntity => {
          commit('setLadditionResponse', { shopId: integrationEntity.shopId, providerId: integrationEntity.providerId });
        });
    });

    commit('ladditionRequestComplete');
    commit('recomputeDisabledState');
  },

  linkLadditionShop({ commit }, { shopId, organisationId, token }) {
    const integration = {
      shopId,
      organisationId,
      providerId: 'ADDITION',
      config: { token },
    };

    return msHttpClient
      .post(`${svcPosBaseUrl}/integrations`, integration)
      .then(({ data }) => commit('setLadditionResponse', { shopId, providerId: data.providerId }));
  },

  deleteLadditionIntegrations({ commit, state }) {
    const shopIds = Object.keys(state.ladditionMatchingShopsList);

    if (!shopIds || shopIds.length === 0) {
      commit('resetLaddition');
      return Promise.resolve();
    }

    commit('ladditionPerformingRequest');
    commit('recomputeDisabledState');

    return msHttpClient
      .delete(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopIds.join(',') } })
      .then(() => {
        commit('resetLaddition');
      })
      .finally(() => {
        commit('ladditionRequestComplete');
        commit('recomputeDisabledState');
      });
  },

  unlinkLadditionShop({ commit }, shopId) {
    commit('recomputeDisabledState');

    return msHttpClient
      .delete(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopId } })
      .then(() => commit('removeLinkedAdditionShop', shopId))
      .finally(() => {
        commit('ladditionRequestComplete');
        commit('recomputeDisabledState');
      });
  },

  linkAdditionShop({ commit }, shopId) {
    commit('ladditionPerformingRequest');
    commit('recomputeDisabledState');

    return msHttpClient
      .delete(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopId } })
      .then(() => commit('removeLinkedAdditionShop', shopId))
      .finally(() => {
        commit('ladditionRequestComplete');
        commit('recomputeDisabledState');
      });
  },

  // Zelty
  fetchZeltyShops({ commit }, zeltyKey) {
    commit('zeltyPerformingRequest');
    return httpClient
      .post('/v3/api/integrations/zelty/fetch_zelty_shops', { zelty_key: zeltyKey })
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        return response;
      })
      .finally(() => {
        commit('zeltyRequestComplete');
      });
  },

  updateZeltyOrganisationShop({ commit }, params) {
    const zeltyMatchingShopsList = params.zeltyMatchingShopsList;

    // Paginable handle key in camel case
    // eslint-disable-next-line camelcase
    const current_page = params.pagination.current_page;
    // eslint-disable-next-line camelcase
    const per_page = params.pagination.per_page;

    commit('updatingOrganisationShopsPerformingRequest');
    const matchingShopsList = {};

    Object.entries(zeltyMatchingShopsList).forEach(matching => {
      const zeltyShop = matching[1];

      // FIXME : https://skello.atlassian.net/browse/DEV-8467
      matchingShopsList[matching[0]] = {
        id: zeltyShop.id,
        name: (zeltyShop.id === null) ? '' : zeltyShop.text,
      };
    });

    return httpClient
      .patch('/v3/api/integrations/zelty', {
        matching_shops_list: matchingShopsList,
        current_page,
        per_page,
      })
      .then(response => response.data)
      .finally(() => {
        commit('updatingOrganisationShopsRequestComplete');
      });
  },

  deleteZeltyIntegration() {
    return httpClient
      .delete('/v3/api/integrations/zelty')
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        commit('recomputeDisabledState');
        return response;
      });
  },

  // Lightspeed
  fetchLightspeedShops({ commit }) {
    commit('lightspeedShopsPerformingRequest');
    return httpClient
      .get('/v3/api/integrations/lightspeed/lightspeed_shops')
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        return response;
      })
      .finally(() => {
        commit('lightspeedShopsRequestComplete');
      });
  },

  authorizeLightspeed({ commit }) {
    commit('lightspeedAuthorizingPerformingRequest');

    return httpClient
      .get('/v3/api/integrations/lightspeed/authorize_url')
      .then(response => response.data)
      .finally(() => {
        commit('lightspeedAuthorizingRequestComplete');
      });
  },

  authenticateLightspeed({ commit }, code) {
    commit('lightspeedAuthenticatingPerformingRequest');

    return httpClient
      .get(`/v3/api/integrations/lightspeed/authenticate_callback?code=${code}`)
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        return response;
      })
      .finally(() => {
        commit('lightspeedAuthenticatingRequestComplete');
        commit('recomputeDisabledState');
      });
  },

  updateLightspeedOrganisationShop({ commit }, params) {
    const lightspeedMatchingShopsList = params.lightspeedMatchingShopsList;

    // Paginable handle key in camel case
    // eslint-disable-next-line camelcase
    const current_page = params.pagination.current_page;
    // eslint-disable-next-line camelcase
    const per_page = params.pagination.per_page;

    commit('updatingOrganisationShopsPerformingRequest');
    const matchingShopsList = {};

    Object.entries(lightspeedMatchingShopsList).forEach(matching => {
      const lightspeedShop = matching[1];

      // FIXME : https://skello.atlassian.net/browse/DEV-8467
      matchingShopsList[matching[0]] = {
        id: lightspeedShop.id,
        name: (lightspeedShop.id === null) ? '' : lightspeedShop.text,
      };
    });

    return httpClient
      .patch('/v3/api/integrations/lightspeed', {
        matching_shops_list: matchingShopsList,
        current_page,
        per_page,
      })
      .then(response => response.data)
      .finally(() => {
        commit('updatingOrganisationShopsRequestComplete');
      });
  },

  deleteLightspeedIntegration() {
    return httpClient
      .delete('/v3/api/integrations/lightspeed')
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        commit('recomputeDisabledState');
        return response;
      });
  },

  // Tiller
  activateTillerIntegration() {
    return httpClient
      .patch('/v3/api/integrations/tiller/activate')
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        commit('recomputeDisabledState');
        return response.data;
      });
  },

  deactivateTillerIntegration() {
    return httpClient
      .patch('/v3/api/integrations/tiller/deactivate')
      .then(response => {
        commit('currentOrganisation/currentOrganisationSuccess', response.data, { root: true });
        commit('recomputeDisabledState');
        return response;
      });
  },

  // SVC POS Providers
  async fetchPosProviders({ commit }, organisationId) {
    return msHttpClient
      .get(`${svcPosBaseUrl}/providers?organisationConfigId=${organisationId}`)
      .then(response => {
        commit('fetchingPosProvidersRequestComplete', { providers: response.data });
        commit('recomputeDisabledState');
      })
      .catch(error => {
        console.error(error.message);
        const providersOnError = { count: 0, data: [] };
        commit('fetchingPosProvidersRequestComplete', { providers: providersOnError });
        throw error;
      });
  },

  fetchProviderRequirements({ commit }, providerId) {
    return msHttpClient
      .get(`${svcPosBaseUrl}/providers/${providerId}/requirements`)
      .then(response => {
        commit('setProviderRequirements', { providerId, requirements: response.data });
        return response.data;
      });
  },

  async activatePosIntegration({ commit }, { providerId, provider, config, organisationId }) {
    // provider relates to chift, while ProviderId relates to Agora
    const providerName = provider?.chiftId ? 'CHIFT' : (provider?.id || providerId);

    await msHttpClient.post(`${svcPosBaseUrl}/providers`, {
      config: config || {},
      id: organisationId, // TODO: CHECK THIS
      organisationConfigId: organisationId,
      providerId: providerName,
    });

    commit('completePosIntegrationActivation', {
      providerId: provider?.id || providerId,
      activationSuccess: true,
    });
  },

  async deactivatePosIntegration({ commit, dispatch, state }, { providerId, organisationId }) {
    if (state.integrations[providerId].chiftId) {
      await svcPosClient.deleteProvider(organisationId);
      commit('resetIntegration', { providerId });
      commit('recomputeDisabledState');
    } else {
      await msHttpClient.delete(`${svcPosBaseUrl}/providers?organisationConfigId=${organisationId}`);
      await dispatch('deleteShopIntegrations', { providerId });
    }

    commit('completePosIntegrationActivation', { providerId, activationSuccess: false });
  },

  async fetchRegisteredIntegrations({ commit, dispatch, state }, { shopIds }) {
    // Populates the integrations state with the registered integrations
    commit('resetRegisteredIntegrations');

    if (Object.keys(state.registeredIntegrations).length === 0) {
      await dispatch('fetchIntegrationsForShops', { shopIds });
    }

    Object.values(state.registeredIntegrations).forEach(providerIntegrations => {
      Object.values(providerIntegrations).forEach(integration => {
        if (['ZELTY', 'ADDITION', 'LIGHTSPEED', 'TILLER'].includes(integration.providerId)) {
          return;
        }

        commit('registerShopIntegration', { integration });
      });
    });
  },

  fetchPosShops({ commit }, { provider, organisationConfigId }) {
    if (provider.chiftId) {
      return svcPosClient.getLocations(organisationConfigId)
        .then(shops => {
          commit('setProviderShops', { providerId: provider.id, shops });
          return shops;
        });
    }

    return msHttpClient
      .get(`${svcPosBaseUrl}/providers/${organisationConfigId}/shops`)
      .then(response => {
        commit('setProviderShops', { providerId: provider.id, shops: response.data });
        return response.data;
      });
  },

  createShopPosIntegration({ commit }, { providerId, shopId, config, organisationId }) {
    const integration = {
      shopId,
      organisationId,
      providerId,
      config,
    };

    return msHttpClient
      .post(`${svcPosBaseUrl}/integrations`, integration)
      .then(() => commit('registerShopIntegration', { integration }));
  },

  updateShopPosIntegration({ commit }, integration) {
    return msHttpClient
      .put(`${svcPosBaseUrl}/integrations`, integration, { headers: { 'Content-Type': 'application/json' } },
      )
      .then(() => commit('updateRegisteredShopIntegration', { integration }));
  },

  async deleteShopPosIntegration({ commit, state }, { organisationId, providerId, shopId }) {
    if (state.integrations[providerId].chiftId) {
      await svcPosClient.deleteShopProvider(organisationId, shopId);
    } else {
      await msHttpClient
        .delete(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopId } });
    }
    commit('unRegisterShopIntegration', { providerId, shopId });
  },

  deleteShopIntegrations({ commit, state }, { providerId }) {
    const shopIds = state.integrations[providerId].registeredIntegrations.map(int => int.shopId);

    if (!shopIds || shopIds.length === 0) {
      commit('resetIntegration', {
        providerId,
      });
      return Promise.resolve();
    }

    return msHttpClient
      .delete(`${svcPosBaseUrl}/integrations`, { params: { shopIds: shopIds.join(',') } })
      .then(() => {
        commit('resetIntegration', {
          providerId,
        });
      });
  },

  async fetchPosProvidersOnShop({
    dispatch,
    getters,
    state,
    rootGetters,
  }, { organisationId, shopIds }) {
    // Fetches Chift providers only if there are no integrations
    if (
      !Object.keys(state.integrations).length &&
      rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_CHIFT')
    ) {
      await dispatch('fetchChiftProviders');
    }

    // Fetches active integration only if there are no active one
    if (!getters.retrieveActiveIntegration) {
      await dispatch('fetchPosProviders', organisationId);
    }

    // Fetches registered integrations only if there is an active integration
    if (getters.retrieveActiveIntegration) {
      await dispatch('fetchRegisteredIntegrations', { shopIds });
    }
  },

  async fetchChiftProviders({ commit }) {
    commit('fetchingPosProvidersPerformingRequest');
    await svcPosClient.getProviders()
      .then(providers => commit('setChiftProviders', { providers }));
  },
};

const getters = {
  isAdditionIntegrationEnabled: state => (
    state.ladditionActivated) || false,
  isLightspeedIntegrationEnabled: (state, _getters, rootState) => !!rootState
    .currentOrganisation.currentOrganisation.attributes.lightspeedKey ||
    state.lightspeedAuthenticating,
  isZeltyIntegrationEnabled:
    (
      _state,
      _getters,
      rootState,
    ) => !!rootState.currentOrganisation.currentOrganisation.attributes.zeltyKey,

  isTillerIntegrationEnabled:
  (
    _state,
    _getters,
    rootState,
    rootGetters,
  ) => !rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_POS_HIDE_TILLER_LEGACY') && !!rootState.currentOrganisation.currentOrganisation.attributes.tillerActivated,
  isAnyOtherIntegrationEnabled: (_state,
    createdGetters,
    _rootState,
    rootGetters,
  ) => providerId => {
    const aLegacyIntegrationIsEnabled =
      (providerId !== 'ADDITION' && createdGetters.isAdditionIntegrationEnabled) ||
      (providerId !== 'LIGHTSPEED' && createdGetters.isLightspeedIntegrationEnabled) ||
      (providerId !== 'ZELTY' && createdGetters.isZeltyIntegrationEnabled) ||
      (providerId !== 'TILLER' && createdGetters.isTillerIntegrationEnabled);
    const isProviderActiveOrPending = provider => provider.active || provider.pending;
    const shouldHideTillerLegacy = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_POS_HIDE_TILLER_LEGACY');
    const isAnyProviderMeetingCriteria = provider => {
      const isOtherThanTillerActivated =
        provider.chiftId !== CHIFT_TILLER_ID && isProviderActiveOrPending(provider);
      const isChiftTillerActivated =
        shouldHideTillerLegacy &&
        provider.chiftId === CHIFT_TILLER_ID &&
        isProviderActiveOrPending(provider);
      return isOtherThanTillerActivated || isChiftTillerActivated;
    };
    return aLegacyIntegrationIsEnabled || createdGetters.integrationProviders.some(
      posIntegration => posIntegration.id !== providerId &&
      isAnyProviderMeetingCriteria(posIntegration),
    );
  },
  retrieveActiveIntegration: state => Object
    .values(state.integrations)
    .find(integration => integration.active),
  isActiveIntegrationFromChift:
    (_state, createdGetters) => createdGetters.retrieveActiveIntegration?.chiftId,
  integrationProviders: state => Object.values(state.integrations),
  shouldDisableIntegration:
    (state, createdGetters) => provider => {
      let providerId = provider.id;

      if (['ZELTY', 'ADDITION', 'LIGHTSPEED', 'TILLER'].includes(provider)) {
        providerId = provider;
      }

      return createdGetters.isAnyOtherIntegrationEnabled(providerId) ||
        state.hasSvcPosError ||
        state.ladditionShopsLoading;
    },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
