<template>
  <div class="meal-rule-row">
    <div>
      <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
      <!-- eslint-disable vue/no-v-html max-len -->
      <p
        v-if="isTriggerTypeHours || isTriggerTypeWorkDurationAndHours"
        class="meal-rule-row__criteria"
        v-html="$t('shop_settings.tabs.rules.main_rules.meal_rules.card.criteria.hours', {
          rangeStartsAtHour: twoDigitsTime(criteria.attributes.rangeStartsAtHour),
          rangeStartsAtMin: twoDigitsTime(criteria.attributes.rangeStartsAtMin),
          rangeEndsAtHour: twoDigitsTime(criteria.attributes.rangeEndsAtHour),
          rangeEndsAtMin: twoDigitsTime(criteria.attributes.rangeEndsAtMin) })"
      />
      <p
        v-if="isTriggerTypeWorkDuration"
        class="meal-rule-row__criteria"
        v-html="$t('shop_settings.tabs.rules.main_rules.meal_rules.card.criteria.work_duration', {
          workDurationMin: criteria.attributes.workDurationMin,
          workDurationMax: criteria.attributes.workDurationMax })"
      />
      <p
        v-if="isTriggerTypeWorkDurationAndHours"
        class="meal-rule-row__criteria meal-rule-row__criteria-large"
        v-html="$t('shop_settings.tabs.rules.main_rules.meal_rules.card.criteria.work_duration_and_hours', {
          workDurationMin: criteria.attributes.workDurationMin,
          workDurationMax: criteria.attributes.workDurationMax})"
      />
    </div>
    <div>
      <p class="meal-rule-row__text--bold">
        {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.card.nb_meals', { nbMeals: criteria.attributes.mealCount }) }}
      </p>
    </div>
    <!-- eslint-enable vue/no-v-html max-len -->
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MealRuleCriteriaRow',
  props: {
    criteria: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('config', ['config']),

    isTriggerTypeHours() {
      return this.config.meal_rules.trigger_types.hours ===
        this.criteria.attributes.triggerType;
    },
    isTriggerTypeWorkDuration() {
      return this.config.meal_rules.trigger_types.work_duration ===
        this.criteria.attributes.triggerType;
    },
    isTriggerTypeWorkDurationAndHours() {
      return this.config.meal_rules.trigger_types.work_duration_and_hours ===
        this.criteria.attributes.triggerType;
    },
  },
  methods: {
    twoDigitsTime(value) {
      if (value.toString().length === 2) return value;

      return '0'.concat(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.meal-rule-row {
  display: flex;
  justify-content: space-between;
}

.meal-rule-row__criteria {
  color: $sk-grey;
  line-height: 22px;
  margin: 0;
}

.meal-rule-row__criteria-large {
  margin-bottom: 10px;
}

.meal-rule-row__text--bold {
  font-weight: $fw-semi-bold;
}
</style>
