<template>
  <div
    :class="availabilityClasses"
    :style="availabilityStyle"
  >
    <div :class="hoursClasses">
      <CrossedCalendarIcon
        v-if="isUnavailability"
        width="12"
        height="12"
        fill="#d03e50"
      />
      <CheckedCalendarIcon
        v-else
        width="12"
        height="12"
        fill="#188377"
      />
      <span
        v-if="shouldShowHours"
        :class="hoursTextClasses"
      >
        {{ formattedAvailabilityHours }}
      </span>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import { AVAILABILITY_RECURRENCE_WEEK } from '@app-js/shared/constants/availability';

export default {
  name: 'Availability',
  props: {
    availability: {
      type: Object,
      required: true,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    isOverlapping: {
      type: Boolean,
      required: true,
    },
    dayViewPlanningSizeVariables: {
      type: Object,
      required: true,
    },
  },
  data() {
    return ({ ...this.computeTimeRange() });
  },
  computed: {
    shouldShowHours() {
      return this.availabilityDurationInMinutes > 15;
    },
    availabilityWidth() {
      const adjustedShiftDuration = Math.max(15, this.availabilityDurationInMinutes);
      return adjustedShiftDuration * this.dayViewPlanningSizeVariables.pixelPerMinute - 1;
    },
    openingAndClosingTime() {
      return openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        skDate.utc(this.date).format(),
      );
    },
    availabilityDurationInMinutes() {
      const { closingTime } = this.openingAndClosingTime;
      const duration = skDate.duration(this.endTime.diff(this.startTime)).asMinutes();
      const minutesAfterClosingTime = Math.max(
        0, skDate.duration(this.endTime.diff(closingTime)).asMinutes(),
      );

      // Compensate negative offset if the availability starts before opening or ends after closing
      return duration -
        minutesAfterClosingTime -
        Math.abs(Math.min(0, this.availabilityStartOffsetInMinutes));
    },
    availabilityMarginLeft() {
      const adjustedShiftOffset = Math.max(0, this.availabilityStartOffsetInMinutes);
      return adjustedShiftOffset * this.dayViewPlanningSizeVariables.pixelPerMinute + 2;
    },
    availabilityStartOffsetInMinutes() {
      const { openingTime } = this.openingAndClosingTime;
      return this.startTime.diff(openingTime, 'm');
    },
    formattedAvailabilityHours() {
      const { startTime, endTime } = this.availability.attributes;
      return `${startTime} - ${endTime}`;
    },
    isUnavailability() {
      return this.availability.attributes.status === 'unavailable';
    },
    availabilityStyle() {
      return {
        width: `${this.availabilityWidth}px`,
        height: this.isOverlapping ? '50%' : '100%',
        marginLeft: `${this.availabilityMarginLeft}px`,
        top: this.isUnavailability && this.isOverlapping ? '50%' : 0,
        justifyContent: this.isOverlapping ? 'center' : 'flex-start',
        paddingBottom: this.isOverlapping ? 0 : '10px',
        paddingTop: '6px',
      };
    },
    availabilityClasses() {
      return {
        'planning-row__day-availability-wrapper': true,
        'planning-row__day-availability-wrapper--unavailable': this.isUnavailability,
        'planning-row__day-availability-wrapper--available': !this.isUnavailability,
      };
    },
    hoursClasses() {
      return {
        'planning-row__day-availability-hours': true,
        'planning-row__day-availability-hours--only-icon': this.availabilityDurationInMinutes <= 15,
        'planning-row__day-availability-hours--not-overlapping': !this.isOverlapping,
      };
    },
    hoursTextClasses() {
      return {
        'planning-row__day-availability-hours-text': true,
        'planning-row__day-availability-hours-text--overlapping': this.isOverlapping,
        'planning-row__day-availability-hours-text--small': this.availabilityDurationInMinutes <= 30,
      };
    },
  },
  methods: {
    computeTimeRange() {
      const currentDay = skDate(this.date);

      // utcOffset(0) to compute correct availability length and offset compared to HeaderHours
      const newStartsAt = skDate(this.availability.attributes.startsAt).clone().utcOffset(0, true);
      const newEndsAt = skDate(this.availability.attributes.endsAt).clone().utcOffset(0, true);

      // If the availability is recurring, ignore the date and set to the current date.
      if (this.availability.attributes.recurrence === AVAILABILITY_RECURRENCE_WEEK) {
        newStartsAt.set({
          date: currentDay.date(), month: currentDay.month(), year: currentDay.year(),
        });
        newEndsAt.set({
          date: currentDay.date(), month: currentDay.month(), year: currentDay.year(),
        });
      }

      // If the availability spans two days, it must end at the closing time => adjust the end time
      if (newEndsAt < newStartsAt || newStartsAt.isSame(newEndsAt)) {
        newEndsAt.add(1, 'day');
      }

      return { startTime: newStartsAt, endTime: newEndsAt };
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__day-availability {
  &-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: auto;
    height: calc(100% - 1px);
    z-index: 0;

    &--available {
      color: $sk-success;
      background: rgba($sk-success, .1);
    }

    &--unavailable {
      color: $sk-error;
      background: rgba($sk-error, .1);
    }
  }
}

.planning-row__day-availability-hours {
  padding: 0 8px;
  display: flex;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  &--only-icon {
    padding-left: 8px;
  }

  &--not-overlapping {
    flex-direction: column;
  }

  &-text {
    padding-top: 6px;

    &--small {
      padding-top: 4px;
      line-height: 11px;
    }

    &--overlapping {
      padding-left: 2px;
      white-space: nowrap;
      overflow: hidden; // Hide overflowing text for short availabilities like 15 min
      text-overflow: ellipsis;
    }
  }
}
</style>
