<template>
  <SkOroraDialog
    id="individual-hours-counter-initialization-modal"
    ref="individualHoursCounterInitializationModal"
    size="medium"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isSubmitLoading"
    :submit-button-label="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.submit')"
    :title="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.title_individual_counters')"
    :tracking-options="trackingOptions"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit="handleSubmit"
  >
    <template #body>
      <main class="individual-hours-counter-initialization-modal__main">
        <section class="individual-hours-counter-initialization-modal__section">
          <label
            class="sk-text-medium-semibold"
            for="majoration-switch"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.majoration') }}
          </label>
          <div class="shop-settings-modal-hours-counter-section__majoration">
            <SkSwitch v-model="isMajorationEnabled" />
          </div>
        </section>
        <section class="individual-hours-counter-initialization-modal__section">
          <label
            class="sk-text-medium-semibold"
            for="employees"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.employees.select_label') }}
          </label>
          <div>
            <SkSelectV2
              v-model="selectedEmployeeIds"
              :label="'Sélectionner des employés'"
              :options="employeeOptions"
              :group-options="employeeGroupOptions"
              :no-results-label="$t('requests.leave_requests.no_search_results')"
              :search-placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.employees.search_placeholder')"
              append-to-body
              input-id="employees"
              multi
              no-confirm
              searchable
              width="276px"
            >
              <template
                v-if="selectedEmployeeIds.length > 0"
                #selected-option="{ value }"
              >
                {{ $tc('shop_settings.tabs.teams.table.fields.teammates.group_name', value.length) }}
              </template>
            </SkSelectV2>
          </div>
        </section>
        <section class="individual-hours-counter-initialization-modal__section__majoration">
          <span>
            <label
              class="sk-text-medium-semibold"
              for="start-datepicker"
            >
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.label') }}
            </label>
            <QuestionMarkV2Icon
              v-tooltip="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.tooltip')"
              class="individual-hours-counter-initialization-modal__help-icon"
              width="16"
              height="36"
            />
          </span>
          <div class="individual-hours-counter-initialization-modal__datepicker">
            <SkDatePicker
              id="start-datepicker"
              ref="startDatepicker"
              v-model="initDate"
              data-test="shop-settings-modulation__datepicker"
              :disabled-date="disabledDays"
              :placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.placeholder')"
              :lang="$i18n.locale"
              input-moment-format="dddd DD MMM YYYY"
              hide-footer
            />
            <p class="sk-subtitle--small">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.hint') }}
            </p>
          </div>
        </section>
        <section class="individual-hours-counter-initialization-modal__section">
          <label
            class="sk-text-medium-semibold"
            for="balance-switch"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.title') }}
          </label>
          <SkSwitch
            v-model="isBalanceEnabled"
            v-tooltip="historyTooltip"
            class="shop-settings-modal-hours-counter-section__balance__switch"
            :disabled="selectedEmployees.length === 0"
          />
        </section>
        <p class="sk-subtitle--medium sk-text-medium-regular">
          {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.explanation') }}
        </p>
        <section v-if="isBalanceEnabled">
          <SkInfoCard class="individual-hours-counter-initialization-modal__info-card">
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.card.explanation') }}
          </SkInfoCard>
          <EmployeeRow
            v-for="(_, index) in selectedEmployees"
            :key="selectedEmployees[index].id"
            v-model="selectedEmployees[index]"
            :unit="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.unit')"
            :tracking-options="trackingOptions"
            @keyup="checkBalanceError(selectedEmployees[index])"
          />
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';
import EmployeeRow from '@app-js/shared/components/CountersInitializationModal/EmployeeRow';

export default {
  name: 'IndividualHoursCounterInitializationModal',
  components: { EmployeeRow },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMajorationEnabled: false,
      isLocalBalanceEnabled: false,
      isSubmitLoading: false,
      initDate: null,
      originalData: null,
      selectedEmployeeIds: [],
      selectedEmployees: [],
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    isBalanceEnabled: {
      get() {
        return this.isLocalBalanceEnabled && this.selectedEmployees.length > 0;
      },
      set(value) {
        this.isLocalBalanceEnabled = value;
      },
    },
    isSubmitDisabled() {
      return !this.initDate ||
        this.selectedEmployeeIds.length === 0;
    },
    employeesById() {
      return this.localEmployees.reduce(
        (employees, employee) => {
          employees[employee.id] = employee;

          return employees;
        },
        {},
      );
    },
    employeeOptions() {
      return this.localEmployees
        .map(employee => ({
          id: employee.id,
          text: this.fullName(employee),
          lastName: employee.attributes.lastName,
        }))
        .sort((firstEmployee, secondEmployee) => (
          firstEmployee.lastName.localeCompare(secondEmployee.lastName)
        ));
    },
    employeeGroupOptions() {
      return [
        { id: 'all', matchKey: '*', text: this.$t('search_bar.all') },
        { id: 'employees', matchKey: null, text: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.employees.label') },
      ];
    },
    historyTooltip() {
      if (this.selectedEmployees.length > 0) return '';

      return this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.tooltip');
    },
    localEmployees() {
      return this.employees.map(employee => ({
        ...employee,
        balance: null,
        balanceModifier: '+',
      }));
    },
    trackingOptions() {
      return {
        cancel: 'hours_counter_initialization_cancel',
        plus: 'init_counter_plus',
        minus: 'init_counter_minus',
      };
    },
  },
  watch: {
    selectedEmployeeIds(newValue) {
      this.selectedEmployees = newValue.map(id => this.employeesById[id]);
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    resetData() {
      Object.assign(this.$data, this.$options.data());
      this.$refs.startDatepicker.resetModified();
    },
    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    handleCancel(event) {
      if (
        this.initDate ||
        this.isMajorationEnabled ||
        this.selectedEmployeeIds.length > 0
      ) {
        event.preventDefault();

        this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
          title: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.warning.title'),
          description: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.warning.description'),
          cancelLabel: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.warning.cancel'),
          submitLabel: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.warning.submit'),
          onConfirm: () => {
            this.$refs.individualHoursCounterInitializationModal.hide();
            this.resetData();
            this.$emit('cancel', event);
          },
        });

        return;
      }

      this.resetData();
      this.$emit('cancel', event);
    },
    async handleSubmit(event) {
      event.preventDefault();

      this.$skAnalytics.track('activate_hour_tracker', { source: 'shop_settings' });

      const { modifiers: counterSigns, balances: counterValues } = this.selectedEmployees.reduce(
        ({ modifiers, balances }, employee) => {
          modifiers[employee.id] = employee.balanceModifier ?? '+';
          balances[employee.id] = employee.balance ?? 0;

          return { modifiers, balances };
        },
        { modifiers: {}, balances: {} },
      );

      const params = {
        balance_not_inherited: !this.isBalanceEnabled,
        counter_sign: counterSigns,
        modulation_activated: true,
        modulation_majoration: this.isMajorationEnabled,
        start_date: this.initDate,
        user_initial_counter: counterValues,
      };

      this.isSubmitLoading = true;

      try {
        await httpClient.patch(`/v3/api/shops/${this.currentShop.id}/initial_counters`, params);
      } catch (error) {
        this.$skToast({
          message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.init_error'),
          variant: 'error',
        });

        captureException(error);
      }

      const context = {
        premium_pay: params.modulation_majoration,
        date: params.start_date,
      };

      if (this.isBalanceEnabled) {
        context.tracker_history = Object.keys(counterValues).reduce(
          (history, id) => {
            history[id] = `${counterSigns[id]}${counterValues[id]}`;
            return history;
          },
          {},
        );
      }

      try {
        await this.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_HOUR_TRACKER_ENABLE, context);
      } catch (error) {
        captureException(error);
      }

      this.setShopAttributes({
        countersInitializationDoneAt: this.initDate,
        modulation: true,
        modulationMajoration: this.isMajorationEnabled,
        modulationStatus: this.config.modulation_activated,
      });

      this.$skToast({
        message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.init_complete'),
        variant: 'success',
      });

      this.resetData();
      this.isSubmitLoading = false;
      this.$refs.individualHoursCounterInitializationModal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.individual-hours-counter-initialization-modal__main {
  margin: 8px 24px;
  display: flex;
  flex-direction: column;
}

.individual-hours-counter-initialization-modal__section__majoration,
.individual-hours-counter-initialization-modal__section {
  display: flex;
  align-items: center;
  gap: 24px;

  &:not(first-child) {
    margin-top: 12px;
  }

  > label, span {
    display: flex;
    align-items: center;
    width: 276px;
  }
}

.individual-hours-counter-initialization-modal__section__majoration {
  align-items: baseline;
}

.individual-hours-counter-initialization-modal__datepicker {
  width: 276px;
}

.individual-hours-counter-initialization-modal__info-card {
  margin-top: 12px;
}

.individual-hours-counter-initialization-modal__help-icon {
  margin-left: 4px;
}
</style>
