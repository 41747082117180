<template>
  <div
    :ref="`shift_el_${shift.id}`"
    :class="shiftClasses"
    :style="shiftStyle"
    :data-test="dataTest"
    @mouseleave="$emit('mouseleave')"
  >
    <DraggableIcon
      v-if="isPopularShift"
      class="shift__draggable-icon"
      fill="white"
    />
    <div
      v-if="isDelayed"
      class="planning-row__shift-delay"
      :style="zoomRange.wrapperDelay"
    />
    <div
      :style="zoomRange.innerTop"
      class="planning-row__shift-inner-top"
    >
      <div
        v-if="shift.attributes.previsionalSaved"
        class="planning-row__shift-validated"
        :style="zoomRange.validated"
      >
        <span
          class="planning-row__shift-validated--previsional-time"
          :style="zoomRange.previsional"
        >
          {{ previsionalTime }}
        </span>
        <span class="planning-row__shift-validated--real-time">
          {{ time }}
        </span>
      </div>
      <div v-else>
        <span class="planning-row__shift-time">
          {{ time }}
        </span>
      </div>

      <span
        v-if="otherShopName"
        ref="shopName"
        v-tooltip.top="shopNameTooltip"
        class="planning-row__shift-shop-name"
      >
        {{ otherShopName }}
      </span>
      <div class="planning-row__shift-icons">
        <ActivityIcon
          v-if="shouldDisplayActivityIcon"
          :note="note"
          :tasks="shift.attributes.tasks"
          :comments="shift.attributes.comments"
          :shift-color="shiftColor"
          :width="zoomRange.alertIcon.width"
          :height="zoomRange.alertIcon.height"
        />
        <span
          v-if="mealTooltip"
          v-tooltip.top="mealTooltip"
          class="planning-row__shift-meal"
        >
          <i
            :class="`em ${mealIcon}`"
            :style="zoomRange.icons"
          />
        </span>
        <PendingShiftTooltip v-if="isPendingLeaveRequest" />
        <span
          v-if="isDurationDisplayed && !isPendingLeaveRequest"
          class="planning-row__shift-duration"
          :style="zoomRange.icons"
        >
          {{ durationInHours }}h
        </span>
        <div
          v-if="isPostesView && !isPopularShift && alertInfo"
          class="planning-row__shift-alert"
          :style="zoomRange.alertIcon"
        >
          <CircledExclamationMarkIcon
            :background-color="alertIconColor"
            fill="#ffffff00"
            @click.native.stop="openAlertPopov"
          />
        </div>
      </div>
    </div>
    <div
      ref="div-parent"
      class="planning-row__shift-inner-bottom"
    >
      <QuickSelect
        v-if="isPostesView"
        ref="employeeQuickSelect"
        :label="employeeName"
        :zoom-range="zoomRange.label"
        :items="quickSelectEmployeeItems"
        :disabled="isPendingLeaveRequest || isDayLock"
        :is-pending-leave-request="isPendingLeaveRequest"
        @item-click="handleQuickSelectEmployee"
      />
      <span
        v-else
        ref="tagLabel"
        v-tooltip="tagLabelTooltip"
        class="planning-row__shift-label"
        :style="zoomRange.label"
      >
        {{ posteName }}
      </span>
      <div
        v-if="!isPostesView && !isPopularShift"
        class="planning-row__shift-alert"
        :style="zoomRange.alertIcon"
      >
        <div v-if="alertInfo">
          <CircledExclamationMarkIcon
            :background-color="alertIconColor"
            fill="#ffffff00"
            @click.native.stop="openAlertPopov"
          />
        </div>
      </div>
    </div>
    <div
      v-if="hasLeftEarly"
      class="planning-row__shift-left-early"
      :style="zoomRange.wrapperLeftEarly"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
} from '@app-js/shared/constants/shift';
import { getI18nAbsenceKey } from '@app-js/shared/utils/absences_helper';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import QuickSelect from '@app-js/plannings/shared/components/Shift/QuickSelect';
import PendingShiftTooltip from '@app-js/plannings/shared/components/Shift/PendingShiftTooltip';
import ActivityIcon from '@app-js/plannings/shared/components/ShiftActivities/ActivityIcon';
import { isEmpty } from '@skello-utils/array';

export default {
  name: 'Shift',
  components: {
    QuickSelect,
    ActivityIcon,
    PendingShiftTooltip,
  },
  props: {
    globalConfig: {
      type: Object,
      required: true,
    },
    shift: {
      type: Object,
      required: true,
    },
    isPopularShift: {
      type: Boolean,
      default: false,
    },
    // Can not use default 'draggable' props. need to add another one to do custom check
    isDraggable: {
      type: Boolean,
      default: true,
    },
    isPendingLeaveRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTagLabelCut: false,
      isShopNameCut: false,
      showAlerts: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('planningsState', ['areUnassignedShiftsAllowed', 'isDayLockedForShift', 'isShiftInFilters', 'isEmployeePlanningSpace']),
    ...mapGetters('planningsShifts', ['shouldDisplayTasks', 'shouldDisplayComments']),
    ...mapGetters('currentLicense', ['isEmployee']),
    isDayLock() {
      return this.isDayLockedForShift(this.shift);
    },
    isAWorkShift() {
      return !this.shift.relationships.poste.attributes.absenceKey || this.wasAbsencePlanned;
    },
    isAnAbsence() {
      return (!this.isAWorkShift && !this.wasAbsencePlanned) || this.isPendingLeaveRequest;
    },
    isDelayed() {
      if (this.isEmployee) return false;

      if (this.globalConfig.currentShop.attributes.badging) {
        return this.shift.attributes.lateness > 0;
      }
      return this.shift.attributes.delay > 0;
    },
    mealIcon() {
      if (this.globalConfig.currentShop.attributes.mealEmoji) {
        return this.globalConfig.currentShop.attributes.mealEmoji;
      }

      if ('shops' in this.globalConfig) {
        return this.globalConfig.shops[this.shift.attributes.shopId].mealEmoji;
      }

      return null;
    },
    hasLeftEarly() {
      if (this.isEmployee) return false;

      return this.globalConfig.currentShop.attributes.badging &&
        skDate(this.shift.attributes.endsAt).utc()
          .isBefore(skDate(this.shift.attributes.previsionalEnd).utc()) &&
        !(this.wasWorkShiftPlanned || this.wasAbsencePlanned || this.wasNothingPlanned);
    },
    isDurationDisplayed() {
      return !this.isPostesView &&
        (!this.globalConfig.customShift || this.shift.attributes.showDuration);
    },
    wasWorkShiftPlanned() {
      if (this.shift.relationships.previsionalPoste && this.shift.relationships.poste) {
        return !this.shift.relationships.previsionalPoste.attributes.absenceKey &&
          this.shift.relationships.poste.attributes.absenceKey;
      }
      return false;
    },
    wasAbsencePlanned() {
      if (this.shift.relationships.previsionalPoste && this.shift.relationships.poste) {
        return this.shift.relationships.previsionalPoste.attributes.absenceKey &&
          !this.shift.relationships.poste.attributes.absenceKey; // case when validated shift is afterward updated in absence shift
      }
      return false;
    },
    employeeName() {
      if (!this.shift.attributes.userId) { // unassigned shifts
        return this.isPostesView ? this.$t('plannings.table.manage_shift_modal.tabs.shift.unassigned') : '';
      }

      const userIdStr = this.shift.attributes.userId.toString();
      return this.globalConfig.employeesNames
        .find(employeeName => employeeName.id === userIdStr)
        .formattedName;
    },
    posteName() {
      if (this.isAnAbsence) {
        let absencesCountry = this.globalConfig.currentShop.attributes.absencesCountry;

        if (!absencesCountry && 'shops' in this.globalConfig) {
          absencesCountry = this.globalConfig.shops[this.shift.attributes.shopId].absencesCountry;
        }

        const { absenceKey } = this.shift.relationships.poste.attributes;
        const i18nKey = getI18nAbsenceKey(
          absencesCountry,
          absenceKey,
        );
        const translation = this.$t(i18nKey);

        return translation.name ?? translation;
      }
      return this.shift.relationships.poste.attributes.name;
    },
    wasNothingPlanned() {
      return !this.shift.relationships.previsionalPoste;
    },
    isFromAnotherShop() {
      return this.shift.attributes.shopId !== parseInt(this.globalConfig.currentShop.id, 10) && this.globalConfig.currentShop.id !== 'all';
    },
    previsionalTime() {
      if (this.wasWorkShiftPlanned) {
        return this.$t('plannings.shift.badging.shift_planned');
      }

      if (this.wasAbsencePlanned) {
        return this.$t('plannings.shift.badging.absence_planned');
      }

      if (this.wasNothingPlanned) {
        return this.$t('plannings.shift.badging.no_shift_planned');
      }

      const displayedPrevisionalStartAt = this.shift.attributes.showStartTime ? this.previsionalStartsAtTime : '.';
      const displayedPrevisionalEndsAt = this.shift.attributes.showEndTime ? this.previsionalEndsAtTime : '.';

      return `${displayedPrevisionalStartAt} -\xa0${displayedPrevisionalEndsAt}`;
    },
    time() {
      if (this.shift.attributes.absenceCalculation) {
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_DAY) return this.$t('plannings.shift.time.day');
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY) return this.$t('plannings.shift.time.half_day');
      }

      const displayedStartAt = this.shift.attributes.showStartTime || this.isAnAbsence ? this.startsAtTime : '.';
      const displayedEndsAt = this.shift.attributes.showEndTime || this.isAnAbsence ? this.endsAtTime : '.';

      return `${displayedStartAt} -\xa0${displayedEndsAt}`;
    },
    previsionalStartsAtTime() {
      return skDate(this.shift.attributes.previsionalStart).utc().format('HH:mm');
    },
    previsionalEndsAtTime() {
      return skDate(this.shift.attributes.previsionalEnd).utc().format('HH:mm');
    },
    startsAtTime() {
      return skDate(this.shift.attributes.startsAt).utc().format('HH:mm');
    },
    endsAtTime() {
      return skDate(this.shift.attributes.endsAt).utc().format('HH:mm');
    },
    durationInHours() {
      return parseFloat((this.shift.attributes.durationInSeconds / 3600).toFixed(2));
    },
    note() {
      return !this.isFromAnotherShop ? this.shift.attributes.note : null;
    },
    alertInfo() {
      if (this.isFromAnotherShop || !this.shift.relationships.alerts ||
        this.shift.relationships.alerts.length === 0) return null;

      // TODO: implement tooltip display
      return this.shift.relationships.alerts.map(
        alert => `- ${alert.attributes.title}`,
      ).join('<br>');
    },
    otherShopName() {
      return this.isFromAnotherShop ? this.shift.attributes.shopName : null;
    },
    shiftClasses() {
      return {
        'planning-row__shift-wrapper': true,
        'planning-row__shift-wrapper--active': (this.isAWorkShift && !this.isFromAnotherShop) || this.wasAbsencePlanned,
        'planning-row__shift-wrapper--opacited': !this.isShiftInFilters(this.shift),
        'planning-row__shift-wrapper--absence': this.isAnAbsence,
        'planning-row__shift-wrapper--other-shop': this.isFromAnotherShop,
        'planning-row__shift-wrapper--pending-shift': this.isPendingLeaveRequest,
        'planning-row__shift-wrapper--popular-shift': this.isPopularShift,
        'planning-row__shift-wrapper--draggable': this.isDraggable,
        'planning-row__shift-wrapper--blocking-alert': this.blockingAlertsShifts,
        'planning-row__shift-wrapper--brain-shifts-loading': false,
      };
    },
    mealTooltip() {
      if (this.isFromAnotherShop ||
        this.shift.attributes.nbMeal === 0 ||
        !this.shift.attributes.benefitInKind) {
        return null;
      }
      return this.$t('plannings.shift.meal_tooltip', {
        nbMeal: this.shift.attributes.nbMeal,
      });
    },
    tagLabelTooltip() {
      if (!this.isTagLabelCut) return null;

      const shiftUserId = this.shift.attributes.userId;
      const fromCurrentUser = parseInt(this.currentUser.id, 10) === shiftUserId;

      if (this.isEmployee && this.isAnAbsence && !fromCurrentUser) {
        return this.$t('absence_sidepanel.absences.restricted');
      }

      return this.shift.relationships.poste.attributes.name;
    },
    shopNameTooltip() {
      return this.isShopNameCut ? this.otherShopName : null;
    },
    zoomRange() {
      const { shift, popularShift } = zoomPlanningWeek(this.globalConfig.planningZoom);
      const innerBottom = this.isPopularShift ? popularShift.innerBottom : shift.innerBottom;
      return {
        alertIcon: shift.alertIcon,
        innerTop: shift.innerTop,
        innerBottom,
        validated: shift.validated,
        label: shift.label,
        wrapper: shift.wrapper,
        wrapperDelay: shift.wrapperDelay,
        wrapperLeftEarly: shift.wrapperLeftEarly,
        previsional: shift.previsional,
        icons: shift.icons,
      };
    },
    shouldDisplayActivityIcon() {
      const { note, tasks, comments } = this.shift.attributes;

      // Until the EmployeeV3 space evolves we only want to show notes
      if (this.isEmployeePlanningSpace) return !!note;

      return !!note ||
        (this.shouldDisplayTasks && !isEmpty(tasks)) ||
        this.shouldDisplayComments(comments);
    },
    shiftColor() {
      return this.shift.attributes.color;
    },
    shiftStyle() {
      const style = {};
      if (
        (this.isAWorkShift &&
          !this.isFromAnotherShop &&
          this.shift.attributes.color) ||
        this.wasAbsencePlanned
      ) {
        style.backgroundColor = this.shiftColor;
      }

      // default colors are managed with CSS (absences and shifts in other shop)
      return { ...style, ...this.zoomRange.innerBottom, ...this.zoomRange.wrapper };
    },
    dataTest() {
      if (((this.isAWorkShift && !this.isFromAnotherShop) ||
        this.wasAbsencePlanned) &&
        !this.isPopularShift) {
        return 'shift-work_shift';
      }
      if (this.isFromAnotherShop) return 'shift__another-shop';
      if (this.isAnAbsence) return 'shift__absence';
      if (this.isPopularShift) return 'shift__popular-shift';
      return 'shift-no-data-test';
    },
    alertIconColor() {
      return this.blockingAlertsShifts ? '#d03e50' : '#fff';
    },
    blockingAlertsShifts() {
      return this.globalConfig.blockingAlertShiftsByUser ?
        Object.values(this.globalConfig.blockingAlertShiftsByUser)
          .find(shift => this.shift.id === shift.id) :
        null;
    },
    isPostesView() {
      return this.globalConfig.isPostesView;
    },
    quickSelectEmployeeItems() {
      const employeeItems = this.globalConfig.employeesNames;
      const unassigned = [{
        id: null,
        formattedName: this.$t('plannings.table.manage_shift_modal.tabs.shift.unassigned'),
      }];

      return this.areUnassignedShiftsAllowed ? unassigned.concat(employeeItems) : employeeItems;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions('planningsShifts', ['updateShift']),
    onResize() {
      this.isTagLabelCut = !this.$refs.tagLabel ||
        this.$refs.tagLabel.offsetWidth < this.$refs.tagLabel.scrollWidth;

      this.isShopNameCut = !this.$refs.shopName ||
        this.$refs.shopName.offsetWidth < this.$refs.shopName.scrollWidth;
    },
    openAlertPopov() {
      const shiftElRect = this.$refs[`shift_el_${this.shift.id}`].getBoundingClientRect();
      // Ajust div position
      const menuPositionLeft = shiftElRect.left + shiftElRect.width - 25 / 2;
      const menuPositionTop = shiftElRect.top - shiftElRect.height / 2;

      this.emitOnRoot('show-alerts', {
        shift: this.shift,
        top: menuPositionTop + 63,
        left: menuPositionLeft,
      });
    },
    handleQuickSelectEmployee(employeeId, event) {
      // When selecting the same employee
      if (String(this.shift.attributes.userId) === String(employeeId)) {
        this.$refs.employeeQuickSelect.hide();
        return;
      }

      const localShift = JSON.parse(JSON.stringify(this.shift));
      localShift.attributes.userId = employeeId;
      const shopId = parseInt(localShift.attributes.shopId, 10);

      const params = {
        shiftId: localShift.id,
        shifts: [localShift],
        shopId,
        periodStartsAt: this.globalConfig.monday,
        periodEndsAt: this.globalConfig.sunday,
        isFromEmployeeQuickSelect: true,
      };

      this.updateShift(params)
        .then(() => this.$skAnalytics.track('shift_quick_update_user'))
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__shift-wrapper--opacited {
  opacity: .3;
}

.planning-row__shift-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100% - 6px);
  border-radius: 6px;
  margin: 3px;
  padding: 1em;
  font-size: 1em;

  // it has always a border pixels offset differences between ext-shift and std-shifts
  border: 1px dashed transparent;

  &:hover {
    cursor: pointer;
  }

  & + .planning-row__shift-wrapper {
    // remove double margin when a cell contains multiple shifts
    margin-top: 0;
  }

  &--popular-shift {
    margin: 0;
    width: 100%;
  }

  &--blocking-alert {
    box-shadow: 4px 4px 32px 0 rgba($sk-error, .34);
  }

  // Main positioning and sizing
  div {
    display: flex;
    align-items: center;

    &.planning-row__shift-inner-top {
      z-index: 1;
      justify-content: space-between;
      margin-bottom: 3px;
    }

    &.planning-row__shift-inner-bottom {
      position: relative;
      flex-grow: 1;
    }

    // Child divs in top and bottom have the same positioning behavior
    // Left divs are elastic depending to its parent size and the right div size
    // Right divs sticked to the right
    span:first-child {
      flex-grow: 1;
    }

    div.planning-row__shift-icons {
      align-items: stretch;
    }
  }

  .shift__draggable-icon {
    position: absolute;
    left: 5%;
    top: 37%;
    height: 10px;
    width: 6px;
  }

  // inner divs common design
  .planning-row__shift-time {
    margin-right: 5px;
    line-height: .8em;
    font-weight: $fw-semi-bold;
    font-size: 1.1em;
  }

  .planning-row__shift-validated {
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2px;
    font-size: 1em;
    line-height: 11px;

    &--previsional-time {
      margin: -5px auto -2px 0;
      font-size: .8em;
    }

    &--real-time {
      font-weight: 700;
      margin-bottom: -3px;
    }
  }

  .planning-row__shift-icons {
    i {
      margin-left: 5px;
    }

    .planning-row__shift-meal {
      font-size: .8em;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  .planning-row__shift-label {
    border-radius: 2px;
    height: 14px;
    z-index: 1;
    background-color: rgba(#000, .15);
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    font-size: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 12px;
  }

  .planning-row__shift-alert {
    width: 10px;
    margin-left: 7px;
    cursor: pointer;
    z-index: 1;

    div {
      width: 100%;
      height: 100%;
    }
  }

  .planning-row__shift-shop-name {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1px; // because text-overflow doesn't get along well with italic
    cursor: default;
  }

  .planning-row__shift-duration {
    margin-left: 5px;
  }

  .planning-row__shift-delay,
  .planning-row__shift-left-early {
    background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey;
    width: 16px;
    position: absolute;
    top: -1px;
    bottom: -1px;
  }

  .planning-row__shift-delay {
    border-radius: 6px 0 0 6px;
    left: -1px;
  }

  .planning-row__shift-left-early {
    border-radius: 0 6px 6px 0;
    right: -1px;
  }

  // Specific versions
  &--active {
    color: white;
    background-color: $sk-blue;
  }

  &--absence {
    color: white;
    background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey;
  }

  &--pending-shift {
    background-color: $sk-grey-10;
    color: $sk-grey;

    .planning-row__shift-label {
      color: white;
    }

    ::v-deep svg > * {
      fill: $sk-grey;
    }
  }

  &--other-shop {
    border-color: $sk-grey;
    background-color: $sk-grey-10;
    color: $sk-grey;

    .planning-row__shift-label {
      color: white;
    }

    ::v-deep svg > * {
      fill: $sk-grey;
    }
  }
}

.planning-row__shift-wrapper--draggable {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.planning-row__day-cell__wrapper--disabled > .planning-row__shift-wrapper {
  cursor: not-allowed;
}
</style>
