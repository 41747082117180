<template>
  <SkEventModal
    :flubber-color="$skColors.skBlue10"
    :header-icon="MonochromeSkelloIcon"
    :header-icon-color="$skColors.skBlue50"
    :on-submit-click="handleSubmit"
    :submit-btn-label="$t('onboarding_modal.timeclock_onboarding.splash.next')"
    :is-submit-disabled="isSubmitDisabled"
  >
    <template #content>
      <div class="sk-heading-large-semibold">
        {{ `${$t('onboarding_modal.timeclock_onboarding.device_selection.title')} ${currentUser.attributes.firstName}` }}
      </div>
      <div class="device-step__subtitle sk-text-large-regular">
        {{ $t('onboarding_modal.timeclock_onboarding.device_selection.subtitle') }}
      </div>
      <SkOroraChipGroup
        v-model="devicesOptions"
        variant="rect"
        :multi-select="false"
      />
    </template>
  </SkEventModal>
</template>

<script>

import {
  mapActions, mapState,
} from 'vuex';
import { MonochromeSkelloIcon } from '@skelloapp/skello-ui';

export default {
  name: 'DeviceStep',
  components: {},
  data() {
    return {
      devicesOptions: [
        {
          key: 'tablet',
          label: this.$t('onboarding_modal.timeclock_onboarding.device_selection.tablet_device_label'),
          isSelected: false,
          icon: 'TabletIcon',
          description: this.$t('onboarding_modal.timeclock_onboarding.device_selection.tablet_device_description'),
        },
        {
          key: 'mobile',
          label: this.$t('onboarding_modal.timeclock_onboarding.device_selection.phone_device_label'),
          isSelected: false,
          icon: 'Mobile',
          description: this.$t('onboarding_modal.timeclock_onboarding.device_selection.phone_device_description'),
        },
        {
          key: 'mixed',
          label: this.$t('onboarding_modal.timeclock_onboarding.device_selection.tablet_phone_devices_label'),
          isSelected: false,
          icon: 'TabletIcon',
          secondIcon: 'Mobile',
          description: this.$t('onboarding_modal.timeclock_onboarding.device_selection.tablet_phone_devices_description'),
        },
      ],
      MonochromeSkelloIcon,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentShop', ['currentShop']),

    isSubmitDisabled() {
      return !this.devicesOptions.some(device => device.isSelected);
    },
  },
  methods: {
    ...mapActions('punchClock', ['updatePartialPunchClockSettings']),
    handleSubmit() {
      const mobileSelected = this.devicesOptions.some(option => option.isSelected && ['mixed', 'mobile'].includes(option.key));
      this.updatePartialPunchClockSettings({
        shopId: this.currentShop.id,
        params: {
          shopId: this.currentShop.id,
          enabledMobile: mobileSelected,
        },
      });

      this.$emit('update', this.devicesOptions);
    },
  },
};

</script>

<style lang="scss" scoped>

.device-step__subtitle {
  width: 780px;
  color: $sk-grey-50;
  padding: 16px 0 32px 0;
  line-height: 18px;
}

</style>
