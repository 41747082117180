<template>
  <div class="conflict-item__wrapper">
    <div class="conflict-item__date">
      <CalendarWithDot fill="#000000" />
      {{ formattedConflictDate }}
    </div>
    <div class="conflict-item__description">
      <div class="conflict-item__planning-shift__section">
        <div class="conflict-item__section-title">
          {{ $t('plannings.toolbar.sidepanel.conflict.conflict_section.planning_shift.title') }}
        </div>
        <div class="conflict-item__poste-section">
          <div class="conflict-item__poste-name">
            {{ planningShift.poste.attributes.name }}
          </div>
          <div
            :style="conflictPosteColorFor('planning_shift')"
            class="conflict-item__poste-section-dot"
          />
        </div>
        <div class="conflict-item__time-range">
          {{ conflictTimeLabel(planningShift) }}
        </div>
      </div>
      <div class="conflict-item__template-shift__section">
        <div class="conflict-item__section-title">
          {{ $t('plannings.toolbar.sidepanel.conflict.conflict_section.template_shift.title') }}
        </div>
        <div class="conflict-item__poste-section">
          <div class="conflict-item__poste-name">
            {{ templateShift.poste.attributes.name }}
          </div>
          <div
            :style="conflictPosteColorFor('template_shift')"
            class="conflict-item__poste-section-dot"
          />
        </div>
        <div class="conflict-item__time-range">
          {{ conflictTimeLabel(templateShift) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import { CalendarWithDot } from '@skelloapp/skello-ui';
import { ABSENCE_TYPE_HOURS } from '@app-js/shared/constants/shift';

import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'ConflictItem',
  components: { CalendarWithDot },
  props: {
    conflict: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedConflictDate() {
      const date = skDate(this.planningShift.starts_at).format('dddd LL');
      return capitalize(date);
    },
    planningShift() {
      return this.conflict.planning_shift;
    },
    templateShift() {
      return this.conflict.template_shift;
    },
  },
  methods: {
    conflictPosteColorFor(type) {
      return {
        backgroundColor: this.conflict[type].poste.attributes.color,
      };
    },
    conflictTimeLabel(shift) {
      // Half-day/day
      const absenceCalc = shift.absence_calculation;
      if (absenceCalc && absenceCalc !== ABSENCE_TYPE_HOURS) {
        return this.$t(
          `plannings.toolbar.sidepanel.conflict.conflict_section.absence_label.${absenceCalc}`,
        );
      }

      // hours
      const shiftTimes = this.formattedTimeFor(shift);
      return `${shiftTimes.startsAt} - ${shiftTimes.endsAt}`;
    },
    formattedTimeFor(shift) {
      return {
        startsAt: skDate(shift.starts_at).utc().format('HH:mm'),
        endsAt: skDate(shift.ends_at).utc().format('HH:mm'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.conflict-item__wrapper {
  width: 100%;
  border-bottom: 1px solid $sk-grey-10;
  padding: 20px 0 18px;
  animation-duration: .125s;
  animation-name: fadeIn;
}

.conflict-item__date {
  padding-left: 30px;
  padding-bottom: 15px;
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
  color: $sk-black;

  svg {
    display: inline-flex;
    margin: 0 16px -2px 0;
  }
}

.conflict-item__planning-shift__section {
  width: 50%;
  padding-left: 44px;
}

.conflict-item__template-shift__section {
  padding-left: 30px;
  width: 50%;
}

.conflict-item__description {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  .conflict-item__poste-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
    padding-bottom: 10px;
    color: $sk-black;

    .conflict-item__poste-section-dot {
      height: 10px;
      width: 10px;
      min-width: 10px; /* Prevent squish if poste name too long */
      border-radius: 50%;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }

  .conflict-item__section-title {
    font-size: $fs-text-s;
    line-height: 15px;
    color: $sk-grey;
    padding-bottom: 10px;
  }

  .conflict-item__time-range {
    font-size: $fs-text-m;
    color: $sk-black;
  }
}

.conflict-item__poste-name {
  overflow: hidden;
  max-width: 113px;
  display: block;
  text-overflow: ellipsis;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
