<template>
  <tr class="alerts__row">
    <td>
      <SkSwitch
        v-model="active"
        :disabled="disabled"
      />
    </td>
    <td :class="alertsTableCellDescription">
      <div>
        <div class="alerts__row__description__title">
          {{ alert.attributes.title }}
        </div>
        <div
          v-if="conventionFlexible && alertWithEditableConventionField && isConventionLoaded"
          class="alerts__row__description__message"
        >
          <div>
            {{ fullAlert.first_string }}
            <div class="alerts__row__flexible-description__input">
              <SkInput
                v-if="fullAlert.first_input"
                v-model="editableConventionField"
                :disabled="disabled"
                :name="fullAlert.first_input.name"
                :type="fullAlert.first_input.type"
                :min="fullAlert.first_input.min"
                :max="fullAlert.first_input.max"
                :step="fullAlert.first_input.step"
                :errored="editableConventionFieldErrored"
                :error-message="$t('shop_settings.tabs.shop.error.empty_field')"
                class="sk-input--center sk-input--pointer"
                required
              />
            </div>
            {{ fullAlert.second_string }}
            <div
              v-if="displayAsInput"
              class="alerts__row__flexible-description__input"
            >
              <SkInput
                v-model="inputEditableConventionField"
                :disabled="disabled"
                :name="fullAlert.second_input.name"
                :type="fullAlert.second_input.type"
                :min="fullAlert.second_input.min"
                :max="fullAlert.second_input.max"
                :step="fullAlert.second_input.step"
                :errored="inputEditableConventionFieldErrored"
                :error-message="$t('shop_settings.tabs.shop.error.empty_field')"
                class="sk-input--center sk-input--pointer"
              />
            </div>
            <div
              v-if="displayAsSelect"
              class="alerts__row__flexible-description__input"
            >
              <SkSelectV2
                v-model="selectEditableConventionField"
                :disabled="disabled"
                :options="maxCutsCalculationOptions"
                :class="alertSelectClasses"
                @show="showDropdown"
                @hide="hideDropdown"
              />
            </div>
            {{ fullAlert.third_string }}
          </div>
        </div>
        <div
          v-else
          class="alerts__row__description__message"
        >
          {{ alert.attributes.message }}
        </div>
      </div>
    </td>
    <td :class="alertsTableCellBlocking">
      <SkCheckBox
        v-if="blockingAlert"
        v-model="blocking"
        :disabled="disabled"
        class="alerts__row__blocking__container"
      />
    </td>
  </tr>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

import { arrayToSelectOptions } from '@skello-utils/form';

export default {
  name: 'AlertRow',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapState('convention', ['convention', 'loading']),
    conventionFlexible() {
      return this.currentShop.relationships.convention.attributes.flexible;
    },
    blockingAlert() {
      return this.alert.attributes.blockable;
    },
    alertWithEditableConventionField() {
      return this.alert.attributes.editable;
    },
    alertsTableCellDescription() {
      return {
        alerts__row__description: true,
        'alerts__row--disabled': !this.alert.attributes.active,
      };
    },
    alertsTableCellBlocking() {
      return {
        alerts__row__blocking: true,
        'alerts__row--disabled': !this.alert.attributes.active,
      };
    },
    fullAlert() {
      return this.alert.attributes.fullAlert;
    },
    firstAlertField() {
      return this.alert.attributes.conventionFlexibleFieldFromAlert[0];
    },
    secondAlertField() {
      return this.alert.attributes.conventionFlexibleFieldFromAlert[1];
    },
    active: {
      get() {
        return this.alert.attributes.active;
      },

      set(newValue) {
        this.$skAnalytics.track(newValue ? 'alert_enable' : 'alert_disable');
        this.alert.attributes.active = newValue;
      },
    },
    blocking: {
      get() {
        return this.alert.attributes.blocking;
      },

      set(newValue) {
        this.$skAnalytics.track(newValue ? 'blocking_alert_enable' : 'blocking_alert_disable');
        this.alert.attributes.blocking = newValue;
      },
    },
    editableConventionField: {
      get() {
        return this.convention.attributes[this.firstAlertField];
      },

      set(newValue) {
        this.setConventionAttributes({ [this.firstAlertField]: newValue });
      },
    },
    inputEditableConventionField: {
      get() {
        return this.convention.attributes[this.secondAlertField];
      },

      set(newValue) {
        this.setConventionAttributes({ [this.secondAlertField]: newValue });
      },
    },
    selectEditableConventionField: {
      get() {
        return this.convention.attributes[this.secondAlertField];
      },

      set(newValue) {
        this.setConventionAttributes({ [this.secondAlertField]: newValue });
      },
    },
    displayAsInput() {
      return this.fullAlert.second_input && !this.fullAlert.second_input.select;
    },
    displayAsSelect() {
      return this.fullAlert.second_input && this.fullAlert.second_input.select;
    },
    alertSelectClasses() {
      return {
        'alerts__row__description__select-field': true,
        alerts__row__description__dropdown__focus: this.dropdownOpen,
      };
    },
    editableConventionFieldErrored() {
      return this.editableConventionField === '';
    },
    inputEditableConventionFieldErrored() {
      return this.inputEditableConventionField === '';
    },
    isConventionLoaded() {
      return !this.loading;
    },
  },
  watch: {
    active(value) {
      this.updateAlert({
        alert: this.alert,
        shopId: this.currentShop.id,
      })
        .then(response => {
          const newValue = value ? 'active' : 'inactive';

          this.$skToast({
            message: this.$t(
              `shop_settings.tabs.alerts.table.active.update.${newValue}.success_message`,
            ),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.alerts.table.error_message'),
            variant: 'error',
          });
        });
    },
    blocking(value) {
      this.updateAlert({
        alert: this.alert,
        shopId: this.currentShop.id,
      })
        .then(() => {
          const newValue = value ? 'active' : 'inactive';

          this.$skToast({
            message: this.$t(
              `shop_settings.tabs.alerts.table.blocking.update.${newValue}.success_message`,
            ),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.alerts.table.error_message'),
            variant: 'error',
          });
        });
    },
  },
  created() {
    this.maxCutsCalculationOptions = arrayToSelectOptions(
      this.config.max_cuts_calculation,
      value => this.$t(`shop_settings.tabs.alerts.flexible.${value}`),
    );
  },
  methods: {
    ...mapActions('alerts', ['updateAlert']),
    ...mapMutations('convention', ['setConventionAttributes']),
    optionSelected(option) {
      return option.text === this.convention.attributes[this.secondAlertField];
    },
    showDropdown() {
      this.dropdownOpen = true;
    },
    hideDropdown() {
      this.dropdownOpen = false;
    },
  },
};
</script>

<style lang="scss">
.alerts__row {
  border-top: 1px solid $sk-grey-10;

  th,
  td {
    padding: 20px 0;
  }

  .alerts__row--disabled {
    opacity: .3;
    pointer-events: none;
  }

  .alerts__row__description {
    padding: 16px;
    line-height: 1.8;

    .alerts__row__description__title {
      color: $sk-black;
      font-weight: bold;
    }

    .alerts__row__description__message {
      color: $sk-grey;
    }
  }

  .alerts__row__blocking {
    padding-right: 0;

    .alerts__row__blocking__container {
      float: right;
    }
  }
}

.alerts__row__flexible-description__input {
  display: inline-block;

  .sk-input {
    height: 23px;
    width: 50px;

    &::before {
      top: 23px;
    }

    .sk-input__input {
      height: 23px;
    }
  }

  .alerts__row__description__select-field {
    color: $sk-black;
    height: 30px;
    min-width: 50px;

    .sk-select__selected-option {
      min-width: 90px;
      height: 23px;
      border-bottom: 2px solid transparent;

      svg {
        position: absolute;
        right: 10px;
        bottom: 6px;
      }
    }

    &.alerts__row__description__dropdown__focus {
      .sk-select__selected-option {
        border-color: $sk-blue;
      }
    }
  }

  .check-mark-icon {
    fill: $sk-blue;
  }

  .alerts__row__flexible-description__select__list {
    width: 35px;
  }
}
</style>

<style lang="scss" scoped>
  ::v-deep .sk-input__error-wrapper {
    position: absolute;
  }

  ::v-deep .sk-input__error-label {
    margin: 0 0 0 -8px !important;
    padding: 2px 8px;
    background: $sk-white;
    white-space: nowrap;
  }
</style>
