<template>
  <div class="boot-loader">
    <div class="boot-loader__content">
      <!-- // v3-transition
        <SkFullLogo class="boot-loader__logo"/>
        <SkLoader class="boot-loader__spinner"/>
      -->
    </div>
  </div>
</template>

<script>
// import { SkFullLogo } from '@app-js/shared/assets/svg/branding';

export default {
  name: 'BootLoader',
  // components: { SkFullLogo },
};
</script>

<style lang="scss" scoped>
.boot-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px); // v3-transition
  width: 100vw;
  margin-top: 50px; // v3-transition
}

.boot-loader__content {
  width: 150px;
}

.boot-loader__logo {
  width: 150px;
  height: 50px;
  margin-bottom: 10px;
}

.boot-loader__spinner {
  display: block;
  margin: 0 auto;
  text-align: center;

  &::after {
    color: $sk-blue;
  }
}
</style>
