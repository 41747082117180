import { merge } from '@app-js/shared/utils/object';

import badgings from '@app-js/badgings/shared/lang';
import users from '@app-js/users/shared/lang';
import organisationSettings from '@app-js/organisation_settings/shared/lang';
import plannings from '@app-js/plannings/shared/lang';
import reports from '@app-js/reports/shared/lang';
import shopSettings from '@app-js/shop_settings/shared/lang';
import textDocumentTemplates from '@app-js/text_document_templates/shared/lang';
import analyticsDashboard from '@app-js/analytics_dashboard/shared/lang';
import employeeView from '@app-js/employees/shared/lang';
import homeDashboard from '@app-js/home_dashboard/shared/lang';
import requests from '@app-js/requests/shared/lang';
import profile from '@app-js/profile/shared/lang';
import common from '@app-js/shared/lang';
import staticFr from '@app-js/static/shared/lang';

const { fr } = merge(
  common,
  badgings,
  shopSettings,
  organisationSettings,
  plannings,
  users,
  textDocumentTemplates,
  reports,
  analyticsDashboard,
  employeeView,
  homeDashboard,
  requests,
  profile,
  staticFr,
);

export default fr;
