<template>
  <div :class="contentClasses">
    <div class="billing-line-content__input-row">
      <div class="billing-line-content__input-row__line-container">
        <SkInput
          v-model.trim="iban"
          class="billing-line-content__input-row__line-container__input-md"
          type="text"
          :label="$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.iban_input')"
          :errored="!!ibanErrorMessage"
          :error-message="ibanErrorMessage"
          :debounce="validateIban"
          :debounce-ms="100"
        />
        <SkInput
          v-model.trim="email"
          class="billing-line-content__input-row__line-container__input-md"
          type="text"
          :label="$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.email_input')"
          :errored="!!emailErrorMessage"
          :error-message="emailErrorMessage"
          :debounce="validateEmail"
          :debounce-ms="100"
        />
      </div>
    </div>
    <h3 class="mt-4 mb-3">
      {{ $t('organisation_settings.tabs.organisation_info.billing_info.billing_line.address_title') }}
    </h3>
    <div class="billing-line-content__input-row">
      <div class="billing-line-content__input-row__line-container">
        <SkInput
          v-model.trim="address"
          class="billing-line-content__input-row__line-container__input-lg"
          type="text"
          :label="$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.address_input')"
          :errored="!!addressErrorMessage"
          :error-message="addressErrorMessage"
          :debounce="validateAddressForm"
          :debounce-ms="100"
        />
      </div>
    </div>
    <div class="billing-line-content__input-row">
      <div class="billing-line-content__input-row__line-container">
        <SkInput
          v-model.trim="city"
          class="billing-line-content__input-row__line-container__input-sm"
          type="text"
          :label="$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.city_input')"
          :errored="!!cityErrorMessage"
          :error-message="cityErrorMessage"
          :debounce="validateAddressForm"
          :debounce-ms="100"
        />
        <SkInput
          v-model.trim="zipCode"
          class="billing-line-content__input-row__line-container__input-sm"
          type="text"
          :label="$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.zipcode_input')"
          :errored="!!zipCodeErrorMessage"
          :error-message="zipCodeErrorMessage"
          :debounce="validateAddressForm"
          :debounce-ms="100"
        />
        <SkSelectV2
          v-model="country"
          class="billing-line-content__input-row__line-container__input-sm"
          :label="$t('organisation_settings.tabs.organisation_info.address.labels.country')"
          :options="countries"
          :search-placeholder="$t('labels.search')"
          :no-results-label="$t('search_bar.no_result')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SkInput,
  SkSelectV2,
} from '@skelloapp/skello-ui';
import { arrayToSelectOptions } from '@skello-utils/form';
import { countryKeys } from '@skello-utils/country-keys.js';
import { removeWhiteSpaces } from '@skello-utils/method_helper';
import {
  isValidEmail,
  isValidIban,
} from '@skello-utils/validators';

export default {
  name: 'BillingLineContent',
  components: {
    SkInput,
    SkSelectV2,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    needsPadding: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ibanWithSpacesAllowed: this.value?.ibanWithSpacesAllowed || null,
      ibanErrorMessage: null,
      emailErrorMessage: null,
      addressErrorMessage: null,
      cityErrorMessage: null,
      zipCodeErrorMessage: null,
      countryErrorMessage: null,
      loading: false,
      originalEmail: this.value?.email || null,
      originalIban: this.value?.ibanWithSpacesAllowed || null,
      originalAddress: this.value?.address || null,
      originalCity: this.value?.city || null,
      originalZipCode: this.value?.zipCode || null,
      originalCountry: this.value?.country || null,
    };
  },
  computed: {
    localValue: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit('input', { ...newValue });
      },
    },
    address: {
      get() {
        return this.value?.address;
      },
      set(value) {
        const updatedValue = { ...this.localValue, address: value };
        this.localValue = updatedValue;
      },
    },
    city: {
      get() {
        return this.value?.city;
      },
      set(value) {
        const updatedValue = { ...this.localValue, city: value };
        this.localValue = updatedValue;
      },
    },
    zipCode: {
      get() {
        return this.value?.zipCode;
      },
      set(value) {
        const updatedValue = { ...this.localValue, zipCode: value };
        this.localValue = updatedValue;
      },
    },
    country: {
      get() {
        return this.value?.country;
      },
      set(value) {
        const updatedValue = { ...this.localValue, country: value };
        this.localValue = updatedValue;
        this.$nextTick(() => this.validateAddressForm());
      },
    },
    email: {
      get() {
        return this.value?.email || null;
      },
      set(value) {
        const updatedValue = { ...this.localValue, email: value };
        this.localValue = updatedValue;
      },
    },
    iban: {
      get() {
        return this.ibanWithSpacesAllowed &&
          removeWhiteSpaces(this.ibanWithSpacesAllowed);
      },
      set(value) {
        this.ibanWithSpacesAllowed = value;
        const updatedValue = { ...this.localValue, iban: value };
        this.localValue = updatedValue;
      },
    },
    countries() {
      return arrayToSelectOptions(countryKeys('FR'), value => this.$t(`countries.${value}`));
    },
    isSubmitDisabled() {
      return this.anyErrorMessage || this.anyEmptyField;
    },
    contentClasses() {
      return {
        'billing-line-content': true,
        'billing-line-content--padded': this.needsPadding,
      };
    },
    anyErrorMessage() {
      return !!(this.ibanErrorMessage || this.emailErrorMessage || this.addressErrorMessage ||
      this.cityErrorMessage || this.zipCodeErrorMessage || this.countryErrorMessage);
    },
    anyEmptyField() {
      return !(this.iban || this.email || this.address ||
        this.city || this.zipCode || this.country);
    },
    emailHasChanged() {
      return this.originalEmail !== this.email;
    },
    ibanHasChanged() {
      return this.originalIban !== this.iban;
    },
    addressHasChanged() {
      return this.originalAddress !== this.address;
    },
    cityHasChanged() {
      return this.originalCity !== this.city;
    },
    zipCodeHasChanged() {
      return this.originalZipCode !== this.zipCode;
    },
    countryHasChanged() {
      return this.originalCountry !== this.country;
    },
    anyBillingAddressFieldHasChanged() {
      return this.addressHasChanged ||
        this.cityHasChanged ||
        this.zipCodeHasChanged ||
        this.countryHasChanged;
    },
    anyFieldHasChanged() {
      return this.emailHasChanged ||
        this.ibanHasChanged ||
        this.anyBillingAddressFieldHasChanged;
    },
  },
  watch: {
    isSubmitDisabled() {
      this.localValue = { ...this.localValue, canChange: !this.isSubmitDisabled };
    },
    anyFieldHasChanged() {
      if (this.anyBillingAddressFieldHasChanged) this.resetAddressFieldsErrorMessages();

      this.localValue = { ...this.localValue, hasChanged: this.anyFieldHasChanged };
    },
    anyBillingAddressFieldHasChanged() {
      if (!this.anyBillingAddressFieldHasChanged) this.resetAddressFieldsErrorMessages();
    },
  },
  mounted() {
    this.originalAddress = this.address;
    this.originalCity = this.city;
    this.originalZipCode = this.zipCode;
    this.originalCountry = this.country;
  },
  methods: {
    validateMandatoryField(attribute) {
      const value = this[attribute];

      // We don't accept empty string or remaining '*' in the input
      if (value === '' || value.includes('*')) {
        this[`${attribute}ErrorMessage`] = this.$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    validateAddressForm() {
      if (!this.anyBillingAddressFieldHasChanged) return;

      this.validateAddress();
      this.validateCity();
      this.validateZipCode();
      this.validateCountry();
    },
    validateIban() {
      this.validateMandatoryField('iban');
      if (this.iban && !isValidIban(this.iban)) {
        this.ibanErrorMessage = this.$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.iban_invalid');
      }
    },
    validateEmail() {
      this.validateMandatoryField('email');
      if (this.email && !isValidEmail(this.email)) {
        this.emailErrorMessage = this.$t('organisation_settings.tabs.organisation_info.billing_info.billing_line.email_invalid');
      }
    },
    validateAddress() {
      this.validateMandatoryField('address');
    },
    validateCity() {
      this.validateMandatoryField('city');
    },
    validateZipCode() {
      this.validateMandatoryField('zipCode');
    },
    validateCountry() {
      this.validateMandatoryField('country');
    },
    resetAllErrorMessages() {
      this.resetIbanErrorMessage();
      this.resetEmailErrorMessage();
      this.resetAddressFieldsErrorMessages();
    },
    resetIbanErrorMessage() {
      this.ibanErrorMessage = null;
    },
    resetEmailErrorMessage() {
      this.emailErrorMessage = null;
    },
    resetAddressFieldsErrorMessages() {
      this.addressErrorMessage = null;
      this.cityErrorMessage = null;
      this.zipCodeErrorMessage = null;
      this.countryErrorMessage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-line-content {
  &--padded {
    padding: 0 24px 16px;
  }

  &__input-row {
    &__line-container {
      display: flex;
      justify-content: space-between;

      &:last-of-type {
        margin: 8px 0 6px;
      }

      &__input-md {
        max-width: 49.5%;
      }

      &__input-lg {
        max-width: 100%;
      }

      &-input-group-sm {
        min-width: 32%;

        .sk-select {
          max-width: 32%;
          height: 44px;
        }
      }

      &__input-sm {
        max-width: 32%;
      }
    }
  }
}

</style>
