<template>
  <div class="employees-weeks-planning__wrapper">
    <PlanningTable />
  </div>
</template>

<script>
import PlanningTable from '@app-js/plannings/shared/PlanningTable';

export default {
  name: 'Employees',
  components: { PlanningTable },
};
</script>

<style lang="scss" scoped>
.employees-weeks-planning__wrapper {
  flex: 1;
  overflow: hidden;
}
</style>
