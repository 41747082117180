<template>
  <SkModalV2
    id="transfer-request-modal"
    ref="TransferLeaveRequestModal"
    class="transfer-request-modal"
    :subtitle="subtitle"
    :title="$t('requests.leave_requests.transfer_modal.title', { employeeName })"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSectionV2
        id="label-forward-section"
        border-bottom="none"
      >
        <p class="sk-text-medium-regular">
          {{ $t('requests.leave_requests.transfer_modal.label_forward', { employeeName }) }}
        </p>
      </SkModalSectionV2>
      <div
        v-if="isLoadingData"
        class="transfer-request-modal__loader-wrapper"
      >
        <SkLoader size="large" />
      </div>
      <form
        v-else
        @submit.prevent
      >
        <TransferUserSelectRow
          ref="transferUserRow"
          v-model="receiverId"
          :users="managerOptions"
        />
      </form>
    </template>

    <template #footer>
      <SkOroraButton
        v-if="!hideBackButton"
        id="back-button"
        data-test="transfer-request__back-button"
        type="button"
        variant="secondary"
        @click="stepBack"
      >
        {{ $t('requests.leave_requests.transfer_modal.back') }}
      </SkOroraButton>
      <SkOroraButton
        data-test="transfer-request__cancel-button"
        type="button"
        variant="secondary"
        @click="handleCancel"
      >
        {{ $t('requests.leave_requests.transfer_modal.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        data-test="transfer-request__accept-button"
        type="submit"
        variant="primary"
        :loading="isAccepting"
        :disabled="!canSubmit"
        @click="handleTransfer"
      >
        {{ $t('requests.leave_requests.transfer_modal.transfer') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import { capitalize } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';

import TransferUserSelectRow from '@app-js/requests/shared/components/LeaveRequests/TransferUserSelectRow';
import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';

export default {
  name: 'TransferLeaveRequestModal',
  components: {
    TransferUserSelectRow,
  },
  mixins: [RoutesMixins],
  data() {
    return {
      hideBackButton: false,
      isLoadingData: false,
      receiverId: null,
      employeeName: null,
      receiverName: null,
      firstReceiverName: null,
      createDate: null,
      createTime: null,
      isAccepting: false,
      request: null,
      source: null,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('planningsState', ['periodDates']),
    ...mapGetters('requests', ['tableFilters', 'currentShopIds']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('requests', ['managersByEmployeeId', 'sort', 'pagination']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentOrganisation', ['currentNodeShops']),
    ...mapState('currentShop', ['currentShop']),
    useSvcRequests() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1');
    },
    managerOptions() {
      return this.transferrableManagers
        .map(manager => ({
          id: manager.id,
          text: manager.name,
        }));
    },
    transferrableManagers() {
      if (!this.request) {
        return [];
      }

      const currentEmployeeId = parseInt(this.request.employeeId, 10);
      const currentlyAssignedManagerId = parseInt(this.request.receiverId, 10);

      return this.managersByEmployeeId[currentEmployeeId]
        .filter(manager => manager.id &&
          manager.id !== currentEmployeeId &&
          manager.id !== currentlyAssignedManagerId);
    },
    subtitle() {
      if (!this.request) {
        return '';
      }

      const createDate = skDate(this.request.createdAt);
      let date = createDate.format('DD/MM/YYYY');
      let time = createDate.format('HH[h]mm');

      if (!this.request.firstReceiver) {
        return this.$t('requests.leave_requests.transfer_modal.subtitle_not_transferred_without_manager', { date, time });
      }

      if (this.request.receiver && this.request.transferDate) {
        const transferDate = skDate(this.request.transferDate);
        date = transferDate.format('DD/MM/YYYY');
        time = transferDate.format('HH[h]mm');
        const managerName = `${this.request.receiver.firstName} ${this.request.receiver.lastName}`;
        return this.$t('requests.leave_requests.transfer_modal.subtitle_transferred', { managerName, date, time });
      }

      const firstManagerName = `${this.request.firstReceiver.firstName} ${this.request.firstReceiver.lastName}`;
      return this.$t('requests.leave_requests.transfer_modal.subtitle_not_transferred', { managerName: firstManagerName, date, time });
    },
    canSubmit() {
      return this.receiverId;
    },
    isOwnRequest() {
      if (!this.request) {
        return false;
      }

      return this.currentUser.id === this.request.employeeId.toString();
    },
    newAssignedManager() {
      return this.transferrableManagers.find(manager => manager.id === this.receiverId);
    },
    fetchPendingLeaveRequestShiftsParams() {
      const { startsAt, endsAt } = this.periodDates(this.source.sub);

      if (this.useSvcRequests) {
        return {
          shopIds: JSON.stringify([this.request.shopId]),
          startsAt,
          endsAt,
          statusFilters: JSON.stringify(['pending']),
          skipPagination: true,
        };
      }

      return {
        shop_ids: [this.request.shopId],
        starts_at: startsAt,
        ends_at: endsAt,
        statuses: 'pending',
        skip_pagination: true,
      };
    },
  },
  async created() {
    this.listenOnRoot('open-transfer-request-modal', data => {
      this.request = data.request;
      this.source = data.source;
      this.hideBackButton = !!data.hideBackButton;

      this.setModalData();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'transfer-request-modal');
    });
  },
  methods: {
    ...mapActions('requests', ['fetchLeaveRequests', 'transferLeaveRequest', 'fetchManagers']),
    ...mapActions('planningsShifts', ['fetchPendingLeaveRequestShifts']),
    ...mapMutations('planningsState', ['closeTotalPeriodTab']),
    setModalData() {
      this.employeeName = `${capitalize(this.request.employee?.firstName)} ${capitalize(this.request.employee?.lastName)}`;
      this.createDate = skDate.utc(this.request.createdAt).format('DD/MM/YYYY');
      this.createTime = skDate.utc(this.request.createdAt).format('HH:mm');

      if (this.request.firstReceiver) {
        this.firstReceiverName = `${capitalize(this.request.firstReceiver?.firstName)} ${capitalize(this.request.firstReceiver?.lastName)}`;
      }

      if (this.request.transferredAt) {
        this.transferDate = skDate.utc(this.request.transferredAt).format('DD/MM/YYYY');
        this.transferTime = skDate.utc(this.request.transferredAt).format('HH:mm');
      }
    },
    async fetchManagersByEmployeeId(employeeId) {
      this.isLoadingData = true;

      const shopId = this.isAllRequestsView ?
        this.request.shopId :
        this.currentShop.id;

      try {
        await this.fetchManagers({
          shopId,
          employeeId,
        });
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });
        throw error;
      }

      this.isLoadingData = false;
    },
    async handleTransfer() {
      let source;

      if (this.source.page === 'leave_requests') {
        source = 'absence_space';
      } else {
        source = this.source.sub === 'month' ? 'monthly_schedule' : 'weekly_schedule';
      }

      this.$skAnalytics.track('leave_request_transfer', { source });

      const leaveRequestParams = {
        status: this.request.status,
        receiverId: this.receiverId,
      };

      this.isAccepting = true;

      try {
        await this.transferLeaveRequest({
          leaveRequestId: this.request.id,
          leaveRequestParams,
        });

        if (this.source.page === 'leave_requests') {
          const shopIds = this.isAllRequestsView ?
            this.currentNodeShops.map(shop => shop.id) :
            [this.currentShop.id];
          await this.fetchLeaveRequests({
            isOwnRequestsView: this.isOwnRequest,
            shopIds,
            userId: this.currentUser.id,
            pagination: this.pagination,
            sort: this.sort,
            filters: this.tableFilters,
            isAllRequestsView: this.isAllRequestsView,
          });
        } else {
          if (this.source.sub !== 'month') {
            this.closeTotalPeriodTab();
          }
          await this.fetchPendingLeaveRequestShifts(this.fetchPendingLeaveRequestShiftsParams);
        }

        const toastMessage = this.$t(
          'requests.leave_requests.transfer_modal.toast.success',
          { managerName: this.newAssignedManager.name },
        );
        this.$skToast({ message: toastMessage, variant: 'success' });
      } catch (error) {
        console.error(error);
        if (error.response.status === 403) {
          this.$skToast({
            message: error.response.data.error,
            variant: 'error',
          });
        } else {
          this.$skToast({
            message: this.$t('requests.toast.error'),
            variant: 'error',
          });
          throw error;
        }
      } finally {
        this.resetModalData();
        this.hideModal();
        this.isAccepting = false;
      }
    },
    handleShow() {
      const currentEmployeeId = parseInt(this.request.employeeId, 10);

      if (!this.managersByEmployeeId[currentEmployeeId]) {
        this.fetchManagersByEmployeeId(currentEmployeeId);
      }
    },
    handleCancel() {
      this.resetModalData();
      this.hideModal();
    },
    resetModalData() {
      this.$nextTick(() => {
        this.isLoadingData = false;
        this.receiverId = null;
        this.request = null;
        this.isAccepting = false;
      });
    },
    stepBack() {
      this.hideModal();
      this.resetModalData();
      this.showManageModal();
    },
    hideModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'transfer-request-modal');
    },
    showManageModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'manage-request-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
  #back-button {
    margin-right: auto !important;
    border: none;
  }
  #label-forward-section {
    padding: 24px 0 0 0;
  }
  #label-forward-section > p {
    margin-bottom: 0px;
  }
</style>
