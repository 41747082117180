<template>
  <div
    ref="tableWrapper"
    class="table-wrapper"
  >
    <table>
      <thead>
        <tr>
          <th>
            {{ headerTitle }}
          </th>

          <th
            v-for="day in weekDays"
            :key="day.toISOString()"
          >
            <HeaderDay :day="day" />
          </th>
        </tr>
      </thead>

      <tbody>
        <TableRow
          v-for="(shiftsOwner, ownerId) in shiftsOwners"
          :key="ownerId"
          :owner-id="ownerId"
          :shifts-owner="shiftsOwner"
          :shifts="groupedShifts[ownerId]"
          :week-days="weekDays"
          :global-config="globalConfig"
          :published="publishedOwners.includes(parseInt(ownerId, 10))"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skDate from '@skello-utils/dates';
import SimpleBar from 'simplebar';
import HeaderDay from './HeaderDay';
import TableRow from './TableRow';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'AllShopsTable',
  components: { HeaderDay, TableRow },
  props: {
    groupedShifts: {
      type: Object,
      required: true,
    },
    shiftsOwners: {
      type: Object,
      required: true,
    },
    globalConfig: {
      type: Object,
      required: true,
    },
    publishedOwners: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('planningsState', ['currentDate']),
    weekDays() {
      const date = skDate(this.currentDate);
      const startOfWeek = date.clone().startOf('isoWeek');
      const endOfWeek = date.clone().endOf('isoWeek');
      const weekDates = [];
      const currentDay = startOfWeek.clone();
      while (currentDay.isSameOrBefore(endOfWeek, 'day')) {
        weekDates.push(currentDay.clone());
        currentDay.add(1, 'day');
      }
      return weekDates;
    },
    isAllShopsView() {
      return this.$route.name === 'plannings_employee_all';
    },
    headerTitle() {
      return this.isAllShopsView ?
        this.$t('employee_view.table.header.establishments') :
        this.$t('employee_view.table.header.employees');
    },
  },
  mounted() {
    const _ = new SimpleBar(this.$refs.tableWrapper);
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  scrollbar-width: none; // hide horizontal scrolling bar

  &::-webkit-scrollbar { // hide horizontal scrolling bar ( cross-broswer )
    display: none;
  }
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 14px;
}

::v-deep {
  th {
    height: 60px;
    position: sticky !important;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid $sk-grey-10;
    background-color: $sk-white;

    &:first-child {
      width: 160px;
      z-index: 20;
    }
  }

  td {
    height: 48px;
  }

  th,
  td {
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    text-align: center;
    position: relative;

    &:first-child::after {
      content: " ";
      width: 14px;
      position: absolute;
      top: 0;
      right: -14px;
      background: linear-gradient(to right, rgba(0, 0, 0, .4), #fff);
      height: 100%;
      opacity: .12;
      z-index: -1; // put the shadow under the cell's content
    }
  }

  td:not(:first-child) {
    border-left: 1px solid $sk-grey-10;
    border-bottom: 1px solid $sk-grey-10;

    &:nth-child(2) {
      border-left: none;
    }
  }

  tr:last-child td:first-child::before {
    content: " ";
    height: 14px;
    position: absolute;
    left: 0;
    bottom: -14px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .4), #fff);
    width: 100%;
    opacity: .12;
    z-index: -1; // put the shadow under the cell's content
  }
}
</style>
