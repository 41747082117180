<template>
  <div class="add-absence-row__wrapper">
    <div class="add-absence__sidebar-wrapper cell--shadow cell--shadow-right">
      <QuickAddAbsence />
    </div>
    <div
      v-for="day in visibleDays"
      :key="day.date"
      class="add-absence-row__empty-cell"
    />
  </div>
</template>

<script>
import QuickAddAbsence from './QuickAddAbsence';

export default {
  name: 'AddAbsenceRow',
  components: {
    QuickAddAbsence,
  },
  props: {
    visibleDays: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.add-absence-row__wrapper {
  display: flex;
  height: 72px;
}

.add-absence__sidebar-wrapper {
  min-width: 160px;
  height: 100%;
  border-bottom: 1px solid $sk-grey-10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-absence-row__empty-cell {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid $sk-grey-10;
}

.add-absence-row__empty-cell:last-child {
  border-right: none;
}
</style>
