<template>
  <div class="optimization__wrapper sk-body">
    <SkSidePanel
      id="schedule-optimisation-panel"
      medallion-content-component="OptimizationIcon"
      medallion-background-color="#f3f3f3"
      medallion-content-color="#727272"
      :title="$t('optimization.sidebar.title')"
      :subtitle="$t('optimization.sidebar.subtitle')"
      @closed="closeSidebar"
    >
      <template #body>
        <div
          ref="bodyWrapper"
          class="optimization__sidepanel-body__wrapper"
        >
          <div
            v-if="isSidePanelLoading"
            class="optimization__sidepanel-body--loading"
          >
            <LoaderContainer />
          </div>
          <div
            v-else
            class="optimization__sidepanel-body"
            @scroll="toggleFullyScrolled"
          >
            <div
              v-if="!isPage('home')"
              class="optimization__perfs__go_back"
              @click="goTo('home')"
            >
              <div class="optimization__perfs__icon_wrapper">
                <LeftArrowIcon
                  height="9"
                  width="9"
                  fill="#000000"
                />
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$t('optimization.sidebar.back')" />
            </div>
            <Home
              v-if="isPage('home')"
              @change-page="goTo"
            />
            <Structure v-if="isPage('structure')" />
            <Postes v-if="isPage('postes')" />
            <Perfs v-if="isPage('perfs')" />
            <Verification v-if="isPage('verification')" />
          </div>
        </div>
        <div
          v-if="!isSidePanelLoading"
          :class="footerClasses"
        >
          <SkOroraButton
            :disabled="isAnyRequestPending"
            @click="handleVerifyOptiStructure"
          >
            {{ $t('optimization.sidebar.submit') }}
          </SkOroraButton>
        </div>
      </template>
    </SkSidePanel>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { SIDEPANEL_SHOW_EVENT } from '@skelloapp/skello-ui';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';
import Home from './Home';
import Structure from './Structure';
import Postes from './Postes';
import Perfs from './Perfs';
import Verification from './Verification';

export default {
  name: 'OptimizationSidePanel',
  components: {
    Home,
    Perfs,
    Postes,
    Structure,
    Verification,
    LoaderContainer,
  },
  data() {
    return {
      currentPage: 'home',
      contentHeight: 0,
      isFullyScrolledBottom: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsOptimisation', ['requestPending']),
    ...mapState('convention', ['loading']),
    ...mapState('planningsLoading', ['planningDataStatus']),
    ...mapGetters('planningsState', ['monday']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled', 'isFirstBatchLoaded', 'isLoadingCompleted']),
    isPlanningDataCompleted() {
      if (this.isProgressiveLoadingEnabled) {
        return this.isLoadingCompleted;
      }
      return true;
    },
    isSidePanelLoading() {
      return !this.isPlanningDataCompleted || this.isAnyRequestPending;
    },
    isAnyRequestPending() {
      return this.isOptiRequestPending || this.loading;
    },
    isOptiRequestPending() {
      // omitting updatePosteStructure for general loader
      const { updatePosteStructure, ...pendingRequests } = this.requestPending;
      return Object.values(pendingRequests).some(
        isRequestPending => !!isRequestPending,
      );
    },
    isFooterDisplayed() {
      return !this.isPage('verification');
    },
    footerClasses() {
      return {
        'optimization__sidepanel-footer': true,
        'optimization__sidepanel-footer--on-scroll': (!this.isFullyScrolledBottom && !this.isPage('home') && !this.isPage('perfs')),
      };
    },
  },
  mounted() {
    this.listenOnRoot('open-optimisation-sidepanel', this.openSidebar);

    this.$nextTick(() => {
      this.simplebar = new SimpleBar(this.$refs.bodyWrapper);
    });
  },
  methods: {
    ...mapActions('planningsOptimisation', ['fetchOptiStructure', 'verifyOptiStructure']),
    ...mapActions('convention', ['fetchConvention']),
    openSidebar() {
      this.fetchOptiStructure(this.currentShop.id);
      this.fetchConvention(this.currentShop.id);
      // don't re-fetch on double click button
      this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'schedule-optimisation-panel');
      if (this.isProgressiveLoadingEnabled && this.isFirstBatchLoaded) {
        this.$root.$emit('need-recursive-fetch');
      }
    },
    closeSidebar() {
      this.currentPage = 'home';
    },
    goTo(page) {
      this.currentPage = page;
    },
    handleVerifyOptiStructure() {
      const params = {
        date: this.monday,
        timezoneOffset: -120,
        shopId: this.currentShop.id,
      };

      this.verifyOptiStructure(params)
        .then(() => {
          this.goTo('verification');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    isPage(page) {
      return this.currentPage === page;
    },
    toggleFullyScrolled() {
      const el = this.$refs.bodyWrapper;

      this.isFullyScrolledBottom = el.scrollHeight === el.clientHeight + el.scrollTop;
    },
  },
};
</script>
<style lang="scss" scoped>
.optimization__wrapper {
  position: absolute;
  z-index: 10;

  // A legacy bit of code in v2 modifies all svg to add margin
  ::v-deep svg {
    margin: 0;
  }
}

.optimization__sidepanel-body--loading {
  height: calc(100vh - 181px);
}

::v-deep .sk-sidepanel__container {
  display: flex;
  flex-direction: column;

  .sk-sidepanel__body {
    padding: 0;
  }
}

::v-deep .sk-sidepanel__body {
  min-height: calc(100vh - 181px) !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.optimization__sidepanel-body__wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.optimization__sidepanel-body {
  padding: 24px 24px 10px;

  &--content-loading {
    height: calc(100vh - 143px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.optimization__perfs__go_back {
  cursor: pointer;
  background: $sk-grey-5;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 20px;
}

.optimization__perfs__icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: white;
  margin-right: 13px;
}

.optimization__sidepanel-footer {
  position: absolute;
  bottom: 0;
  background: white;
  width: 412px;
  padding: 30px 24px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.optimization__sidepanel-footer--on-scroll {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
}
</style>
