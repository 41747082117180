<template>
  <div
    class="column-wrap column-wrap--closed relative"
    @click="handleColumnClick"
  >
    <div
      :style="colorWrapStyle"
      class="colorwrap"
    />
    <div
      :style="borderStyle"
      class="column__top-stroke"
    />
    <div class="column__title column__title--closed">
      {{ title }}
    </div>
    <div>
      <div
        v-for="(employeeInfos, employeeId) in employeesInfos"
        :key="employeeId"
      >
        <div
          v-for="contractInfos in employeeInfos.contracts_infos"
          :key="contractInfos.contract.id"
          class="column__cell column__cell--closed"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClosedColumn',
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    handleColumnClick: {
      type: Function,
      default: () => () => {},
    },
  },
  computed: {
    borderStyle() {
      return {
        backgroundColor: this.borderColor,
      };
    },
    colorWrapStyle() {
      return {
        backgroundColor: this.backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.column-wrap--closed {
  margin-top: 31px;
  width: 166px;
  font-size: .9em;
  transition: all .5s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 10px 0 rgba(123, 142, 189, .35);
    transform: translateY(-6px);

    .column__top-stroke {
      height: 4px;
    }
  }
}

.column__top-stroke {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 3px 3px 0 0;
  transition: all .5s ease;
  height: 3px;
}

.colorwrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 3px;
}
</style>
