<template>
  <div class="postes-weeks-planning__wrapper">
    <PlanningTable />
  </div>
</template>

<script>
import PlanningTable from '@app-js/plannings/shared/PlanningTable';

export default {
  name: 'Postes',
  components: {
    PlanningTable,
  },
};
</script>

<style lang="scss" scoped>
.postes-weeks-planning__wrapper {
  flex: 1;
  overflow: hidden;
}
</style>
