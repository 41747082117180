<template>
  <div
    ref="dayCellWrapper"
    v-tooltip="dayCellTooltip"
    :class="dayCellClasses"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @dragenter="showDropzone = true"
    @dragleave="onDragLeave"
  >
    <Dropzone
      v-if="shouldShowDropzone"
      @on-drop="onDrop"
    />

    <div
      v-if="showNewShiftButton"
      class="day-cell--new"
      @click="openManageShiftModal"
    >
      <PlusSignV2Icon
        width="16"
        height="16"
        fill="#2b66fe"
      />
    </div>

    <MountingPortal
      v-if="showAvailabilitiesPopup"
      mount-to="#availabilities-popup-mounting"
      append
    >
      <AvailabilitiesPopup
        ref="availabilitiesPopup"
        :availabilities="availabilities"
        :style="availabilitiesPopupStyle"
      />
    </MountingPortal>
    <Shift
      v-for="shift in shiftsForDay"
      :key="shift.id"
      :shift="shift"
      :is-locked-day="isDayCellLocked"
      :draggable="isShiftDraggable(shift)"
      :style="{
        // workaround to hide element while keeping it visible on drag
        // https://stackoverflow.com/a/36379727
        transition: '.01s',
        cursor: cursorForShift(shift),
      }"
      :day-cell-component-store-props="dayCellComponentStoreProps"
      :shift-component-store-props="shiftComponentStoreProps"
      @click.native="(event) => onShiftClicked(event, shift)"
      @dragstart.native="handleDragStart(shift, $event)"
      @dragend.native="handleDragEnd()"
    />

    <AvailabilitiesCorner
      v-if="availabilities.length > 0 && shiftsForDay.length > 0"
      :availabilities="availabilities"
      :is-last-visible-day="day.isLastVisibleDay"
    />

    <template v-if="onlyAvailabilities">
      <Availability
        v-if="unavailables.length > 0"
        availability-status="unavailable"
        :availabilities-count="unavailables.length"
      />
      <Availability
        v-if="availabes.length > 0"
        availability-status="available"
        :availabilities-count="availabes.length"
      />
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapMutations,
} from 'vuex';
import lodash from 'lodash';

import skDate from '@skello-utils/dates';
import AvailabilitiesCorner from '@app-js/plannings/shared/components/Availabilities/AvailabilitiesCorner';
import {
  computeShiftTimes,
  getValidShiftTimes,
  sanitizeShift,
} from '@app-js/plannings/shared/utils/planning_helpers';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';
import AvailabilitiesPopup from './AvailabilitiesPopup';
import Shift from './Shift';
import Availability from './Availability';
import Dropzone from './Dropzone';

export default {
  name: 'DayCell',
  components: {
    Availability,
    Shift,
    Dropzone,
    AvailabilitiesCorner,
    AvailabilitiesPopup,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    shifts: {
      type: Array,
      default: () => [],
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    day: {
      type: Object,
      required: true,
    },
    dayCellComponentStoreProps: {
      type: Object,
      required: true,
    },
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    delayedSelect: null,
    hovered: false,
    showDropzone: false,
    draggingSelfShift: false,
    availabilitiesPopupTop: 0,
    availabilitiesPopupLeft: 0,
    showAvailabilitiesPopup: false,
  }),
  computed: {
    shiftsForDay() {
      return this.shifts.filter(shift => {
        const isFromSameShop =
          shift.attributes.shopId === parseInt(this.dayCellComponentStoreProps.currentShop.id, 10);
        const isPendingLeaveRequest = shift.attributes.isPendingLeaveRequest;
        return isFromSameShop || !isPendingLeaveRequest;
      });
    },
    hasUserStartedContract() {
      return this.isContractStartedForUserAtDate(this.user, this.day.date);
    },
    isArchivedUser() {
      if (this.isUnassignedShiftsRow) return false;
      return skDate(this.day.date).isAfter(this.user.attributes.archivedAt);
    },
    isDayCellDisabled() {
      return !this.dayCellComponentStoreProps.currentLicense.attributes.canCreateShifts ||
              !this.hasUserStartedContract ||
              this.isArchivedUser;
    },
    isDayCellLocked() {
      return this.day.isLocked;
    },
    dayCellTooltip() {
      if (this.isArchivedUser) {
        return this.$t('plannings.table.cells.disable_cell_tooltip.archived_employee');
      }
      if (!this.hasUserStartedContract) {
        return this.$t('plannings.table.cells.disable_cell_tooltip.employee_has_not_started');
      }
      return '';
    },
    shouldShowDropzone() {
      return (
        this.showDropzone &&
        !this.isDayCellLocked &&
        !this.isDayCellDisabled &&
        this.dayCellComponentStoreProps.draggedShift !== null &&
        !this.draggingSelfShift
      );
    },
    onlyAvailabilities() {
      return this.shiftsForDay.length === 0 && this.availabilities.length > 0;
    },
    availabes() {
      return this.availabilities.filter(availability => availability.attributes.status === 'available');
    },
    unavailables() {
      return this.availabilities.filter(availability => availability.attributes.status === 'unavailable');
    },
    dayCellClasses() {
      return {
        'day-cell-wrapper': true,
        'day-cell-wrapper__disabled': this.isDayCellDisabled,
        'day-cell-wrapper__locked': this.isDayCellLocked,
        'day-cell-wrapper__only-availabilities': this.onlyAvailabilities,
      };
    },
    showNewShiftButton() {
      if (this.isDayCellDisabled) return false;
      return this.hovered && this.shiftsForDay.length === 0 && !this.day.isLocked;
    },
    canEditShifts() {
      return !this.isDayCellLocked &&
        this.dayCellComponentStoreProps.currentLicense.attributes.canEditShifts &&
        this.hasUserStartedContract && !this.isArchivedUser;
    },
    availabilitiesPopupStyle() {
      return {
        top: `${this.availabilitiesPopupTop}px`,
        left: `${this.availabilitiesPopupLeft}px`,
      };
    },
    shiftComponentStoreProps() {
      const {
        currentShop,
        isShiftInFilters,
        draggedShift,
      } = this.dayCellComponentStoreProps;

      return {
        currentShop,
        isShiftInFilters,
        draggedShift,
      };
    },
  },
  mounted() {
    this.listenOnRoot('shift-menu-clicked', e => {
      const selectedShift = this.shiftsForDay.find(shift => e.shiftId === shift.id);

      if (!selectedShift) return;

      if (e.action === 'ADD_NEW_SHIFT') {
        this.openManageShiftModal();
      } else if (e.action === 'DELETE_SHIFT') {
        this.destroyShift({
          shiftId: selectedShift.id,
          shopId: this.dayCellComponentStoreProps.currentShop.id,
          periodStartsAt: skDate(this.day.date).startOf('isoWeek').format('YYYY-MM-DD'),
          periodEndsAt: skDate(this.day.date).endOf('isoWeek').format('YYYY-MM-DD'),
        }).then(() => {
          this.emitOnRoot('hide-shift-menu');
          this.$skToast({
            message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.destroy.success'),
            variant: 'success',
          });
        }).catch(() => {
          this.emitOnRoot('shift-menu-action-failed');
          this.$skToast({
            message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.error'),
            variant: 'error',
          });
        });
      }
    });
  },
  methods: {
    ...mapActions('planningsShifts', ['destroyShift', 'updateShift', 'createShift']),
    ...mapMutations('planningsState', ['setSelectedDate']),
    ...mapMutations('monthlyPlanning', ['setDraggingShift', 'setUsingDraggedShiftId']),
    onShiftClicked(event, shift) {
      if (shift.id === 'tmp') return;
      if (
        shift.attributes.isPendingLeaveRequest &&
        this.dayCellComponentStoreProps.currentLicense.attributes.canManageEmployeeRequests
      ) {
        const shiftId = shift.id.replace('tmp', '');

        this.emitOnRoot(
          'open-manage-request-modal',
          this.dayCellComponentStoreProps.pendingLeaveRequestShifts
            .find(({ id }) => id === shiftId).attributes,
        );
      } else {
        this.openManageShiftModal(event, shift);
      }
    },
    isContractStartedForUserAtDate(user, date) {
      if (this.isUnassignedShiftsRow) return true;
      if (!user.attributes.hiringDate) return true;

      const userContractStartDate = user.attributes.onExtra ?
        skDate(user.attributes.hiringDate).utc().format('YYYY-MM-DD') :
        skDate(user.attributes.hiringDate).utc().startOf('isoWeek').format('YYYY-MM-DD');

      return skDate(date).utc(true).isSameOrAfter(userContractStartDate);
    },
    isShiftDraggable(shift) {
      return !this.isDayCellDisabled &&
        !this.isDayCellLocked &&
        shift.id !== 'tmp' &&
        this.dayCellComponentStoreProps.usingDraggedShiftId !== shift.id;
    },
    cursorForShift(shift) {
      if (this.isShiftDraggable(shift)) return 'pointer';
      if (this.dayCellComponentStoreProps.usingDraggedShiftId === shift.id || shift.id === 'tmp') return 'wait';
      return 'inherit';
    },
    onMouseEnter() {
      if (this.dayCellComponentStoreProps.draggedShift !== null) return;

      this.selectCellDate();
      this.hovered = true;

      this.showAvailabilitiesPopup = this.availabilities.length > 0 && this.shifts.length === 0;
      if (this.showAvailabilitiesPopup) {
        this.$nextTick(this.computeAvailabilitiesPopupPosition);
      }
    },
    onMouseLeave() {
      this.clearCellDate();
      this.hovered = false;
      this.showAvailabilitiesPopup = false;
    },
    selectCellDate() {
      if (this.day.date === this.dayCellComponentStoreProps.selectedDate) {
        this.setSelectedDate(this.day.date);
        return;
      }
      this.setSelectedDate(null);

      this.delayedSelect = setTimeout(() => {
        this.setSelectedDate(this.day.date);
      }, 200);
    },
    clearCellDate() {
      clearTimeout(this.delayedSelect);
    },
    openManageShiftModal(event, shift = null) {
      if (this.isUnassignedShiftsRow) this.$skAnalytics.track('click_on_add_unassigned_shift');
      if (this.isDayCellDisabled && shift === null) return;

      if (!this.canEditShifts && shift !== null) {
        this.emitOnRoot('init-read-only-shift-modal', event, { shift, isUnassignedShift: false });
        return;
      }

      const params = {
        rowItem: this.user,
        isUnassignedShift: this.isUnassignedShiftsRow,
        date: skDate.utc(this.day.date),
        availabilities: this.availabilities,
      };

      if (shift) {
        params.shift = shift;
        params.isPendingLeaveRequest = shift.attributes.isPendingLeaveRequest;
      } else {
        const defaultShiftTimes = this.isUnassignedShiftsRow ?
          {} :
          computeShiftTimes({
            user: this.user,
            shop: this.dayCellComponentStoreProps.currentShop,
            shifts: this.shiftsForDay,
            date: this.day.date,
          });
        const sortedShifts = [...this.shiftsForDay].sort((s1, s2) => s1.id - s2.id);

        params.lastUserShift = sortedShifts[sortedShifts - 1];
        params.startsAt = defaultShiftTimes?.startsAt;
        params.endsAt = defaultShiftTimes?.endsAt;
        params.dayCellShifts = this.shiftsForDay;
      }

      this.emitOnRoot('manageShiftModal', event, params);
    },
    computeAvailabilitiesPopupPosition() {
      const dayCellRect = this.$refs.dayCellWrapper.getBoundingClientRect();

      let left = dayCellRect.right + 8;
      const availabilitiesPopupWidth = this.$refs.availabilitiesPopup.$el.offsetWidth;

      if (left + availabilitiesPopupWidth >= window.innerWidth) {
        left = dayCellRect.left - availabilitiesPopupWidth - 8;
      }

      const availabilitiesPopupHeight = this.$refs.availabilitiesPopup.$el.offsetHeight;
      let top = dayCellRect.top - availabilitiesPopupHeight / 2 + dayCellRect.height / 2;

      if (top <= 1) {
        top = 1;
      } else if (top + availabilitiesPopupHeight >= window.innerHeight - 1) {
        top = window.innerHeight - availabilitiesPopupHeight - 1;
      }

      this.availabilitiesPopupTop = top;
      this.availabilitiesPopupLeft = left;
    },
    handleDragStart(shift, event) {
      // Hide shift menu when dragging
      this.hovered = false;
      this.draggingSelfShift = true;
      this.emitOnRoot('hide-shift-menu');
      this.setDraggingShift(shift);
      event.dataTransfer.setData(getDragDataFormat('user'), JSON.stringify(this.user));
    },
    handleDragEnd() {
      this.resetDragState();
      this.setDraggingShift(null);
    },
    resetDragState() {
      this.showDropzone = false;
      this.draggingSelfShift = false;
    },
    async onDrop({ target, originalUser }) {
      this.resetDragState();

      const shift = lodash.cloneDeep(this.dayCellComponentStoreProps.draggedShift);

      if (target === 'copy') {
        shift.id = null;
        shift.attributes.provenance = 'duplicate_week';
      }

      // [BADGING] in case absenceShift is validated into a workshift, nullify absence params.
      if (shift.relationships.previsionalPoste &&
        shift.relationships.previsionalPoste.attributes.absenceKey &&
        shift.relationships.poste &&
        !shift.relationships.poste.attributes.absenceKey) {
        shift.attributes.absenceCalculation = '';
        shift.attributes.hoursWorth = 0;
        shift.attributes.dayAbsence = false;
      }

      const { startsAt, endsAt } = getValidShiftTimes(
        shift.attributes,
        this.dayCellComponentStoreProps.currentShop,
        this.day.date,
      );
      shift.attributes.startsAt = startsAt;
      shift.attributes.endsAt = endsAt;

      shift.attributes.delay = 0;
      shift.attributes.previsionalStart = null;
      shift.attributes.previsionalEnd = null;
      shift.attributes.previsionalSaved = null;
      shift.attributes.previsionalPosteId = null;

      shift.attributes.userId = this.user.id;

      sanitizeShift(shift, true, { newUser: this.user, oldUser: originalUser }, {
        currentShop: this.dayCellComponentStoreProps.currentShop,
        absences: this.dayCellComponentStoreProps.absences,
        config: this.dayCellComponentStoreProps.config,
        isShopOnPaidVacationCalculationTypeOpeningDay:
          this.dayCellComponentStoreProps.isShopOnPaidVacationCalculationTypeOpeningDay,
        isShopOnPaidVacationCalculationTypeCalendarDay:
          this.dayCellComponentStoreProps.isShopOnPaidVacationCalculationTypeCalendarDay,
      });

      const params = {
        shifts: [shift],
        periodStartsAt: skDate(this.day.date).startOf('isoWeek').format('YYYY-MM-DD'),
        periodEndsAt: skDate(this.day.date).endOf('isoWeek').format('YYYY-MM-DD'),
        shopId: this.dayCellComponentStoreProps.currentShop.id,
        isFromDragAndDrop: true,
      };

      this.setUsingDraggedShiftId(this.dayCellComponentStoreProps.draggedShift.id);

      if (target === 'copy') await this.createShift(params);
      else if (target === 'move') await this.updateShift(params);

      this.setUsingDraggedShiftId(null);
    },

    onDragLeave(event) {
      const cellRect = this.$el.getBoundingClientRect();
      const isInside = (
        event.clientX > cellRect.left &&
    event.clientX < cellRect.right &&
    event.clientY > cellRect.top &&
    event.clientY < cellRect.bottom

      );
      if (!isInside) {
        this.showDropzone = false;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.day-cell {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
    padding: 4px;
    height: 100%;

    &__disabled {
      cursor: not-allowed;
      background-color: $sk-grey-5;
    }

    &__locked {
      cursor: not-allowed;

      & > div {
        opacity: .31;
      }
    }

    &__only-availabilities {
      padding: 0;
      row-gap: 0;
    }
  }

  &--new {
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 4px;
    background-color: white;
    display: grid;
    place-items: center;
    border: 2px solid $sk-blue-50;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
