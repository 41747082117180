<template>
  <!-- This component is designed to adjust the SkTimePicker
  to the shifts specific needs :
  - handle a time period over two days for shops with night hours
  - notify parent component in case of invalid input format
  - handle timezone -->
  <SkDropdown
    id="shift-time-picker"
    ref="shiftTimePicker"
    :disabled="disabled"
    placement="bottom"
    trigger="click"
    :x-offset="xOffset"
    y-offset="3"
    @show-dropdown="handleShowDropdown"
    @hide-dropdown="handleHideDropdown"
  >
    <template #anchor>
      <!-- ErrorMessage handled by SkTimePicker -->
      <SkInput
        ref="timeInput"
        :value="localValueToInputValue"
        :disabled="disabled"
        :errored="errored"
        :error-message="$t('plannings.table.manage_shift_modal.timepicker.format_error')"
        :label="label"
        :center="center"
        @keyup="onKeyup"
        @blur="onBlur"
        @tab-press="onTabPress"
      />
    </template>
    <template #menu>
      <div
        ref="shiftTimePickerMenu"
        class="shift-time-picker__dropdown-menu"
      >
        <div
          v-for="(timeOption, index) in timeOptions"
          :key="index"
          :ref="setActiveTimeOption(timeOption)"
          class="shift-time-picker__dropdown-row"
          @click="onSelect(timeOption)"
        >
          {{ timeLabel(timeOption) }}
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import clone from 'lodash/clone';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import skDate from '@skello-utils/dates';

export default {
  name: 'ShiftTimePicker',
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    referenceDate: {
      type: Object,
      required: true,
    },
    intervalInMinutes: {
      type: Number,
      default: 60,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    hideLastOption: {
      type: Boolean,
      default: false,
    },
    xOffset: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localValue: clone(this.value),
      errored: false,
      dropdownOpen: false,
    };
  },
  computed: {
    openingAndClosingTime() {
      return openingAndClosingTimeAt(
        this.currentShop.attributes.openingTime,
        this.currentShop.attributes.closingTime,
        this.referenceDate.format(),
      );
    },
    openingTime() {
      return this.openingAndClosingTime.openingTime;
    },
    closingTime() {
      return this.openingAndClosingTime.closingTime;
    },
    localValueToInputValue() {
      if (!this.localValueSkDate.isValid()) return '';

      return this.timeLabel(this.localValue);
    },
    // Generate time options for select
    timeOptions() {
      const arrayLength = Math.floor(
        this.closingTime.diff(this.openingTime, 'minutes') / this.intervalInMinutes,
      ) + 1;

      let timeOption = this.openingTime.clone();

      const timeOptionsArray = [];
      while (timeOptionsArray.length < arrayLength) {
        timeOptionsArray.push(this.skDateToStr(timeOption));
        timeOption.add(this.intervalInMinutes, 'minutes');
        if (timeOption > this.closingTime) {
          timeOption = this.closingTime;
        }
      }

      return this.hideLastOption ? timeOptionsArray.slice(0, -1) : timeOptionsArray;
    },
    localValueSkDate() {
      return this.strToSkDate(this.localValue);
    },
  },
  watch: {
    // Update local value in case time is modified outside the component
    value(newValue) {
      if (newValue !== this.localValue && skDate(newValue).isValid()) {
        this.localValue = newValue;
      }
      this.setIsErrored(!skDate(newValue).isValid());
    },
  },
  methods: {
    setIsErrored(isErrored) {
      this.$emit('errored', isErrored);
      this.errored = isErrored;
    },
    setLocalValue(localValue) {
      this.localValue = localValue;
      this.$emit('input', localValue);
    },
    handleShowDropdown() {
      this.dropdownOpen = true;

      this.scrollToLocalValue();
    },
    handleHideDropdown() {
      this.dropdownOpen = false;
    },
    timeLabel(time) {
      return this.strToSkDate(time).format('HH:mm');
    },
    strToSkDate(timeStr) {
      return skDate.utc(timeStr);
    },
    skDateToStr(timeSkDate) {
      return timeSkDate.format();
    },
    onSelect(timeOption) {
      this.setLocalValue(timeOption);
      this.setIsErrored(false);
      this.$refs.shiftTimePicker.$refs.popper.doClose();
    },
    onKeyup(event) {
      const value = event.target.value;
      if (!value) return;
      // Check if value format is valid and notify modal
      const hoursAndMinutesFormat = /^[0-2][0-9]:?[0-5][0-9]$/;
      this.setIsErrored(!value || !value.match(hoursAndMinutesFormat));
      if (this.errored) return;

      const inputTime = this.timeFromInput(value);

      // if shop uses night hours and inputTime is set to the next day before closingTime
      if (inputTime.isBefore(this.openingTime) &&
        inputTime.clone().add(1, 'day').isSameOrBefore(this.closingTime)) {
        inputTime.add(1, 'day');
      }

      // If input is before or after shop opening hours -> change accordingly
      if (inputTime.isBefore(this.openingTime)) {
        inputTime.set({
          hour: this.openingTime.hours(),
          minute: this.openingTime.minutes(),
        });
      } else if (inputTime.isAfter(this.closingTime)) {
        inputTime.set({
          hour: this.closingTime.hours(),
          minute: this.closingTime.minutes(),
        });
      }

      // If inputTime is same as localValue -> reset input to localValue
      // Fixes an issue when user input matches localValue but input is not correctly formated
      const inputTimeStr = this.skDateToStr(inputTime);
      if (inputTimeStr === this.localValue) {
        this.restoreLocalValueAsInput();
        return;
      }

      // New value for time picker
      this.setLocalValue(inputTimeStr);

      if (this.dropdownOpen) {
        this.scrollToLocalValue();
      }
    },
    onBlur(time) {
      // in case of empty input, display localValue instead
      if (time === '') {
        this.restoreLocalValueAsInput();
        return;
      }

      // in this method, we want to handle incomplete user inputs
      // i.e. these cases: hh:m, h:mm, h:m, hh:, h:, hhm, hmm, hh, hm, h
      const incompleteTimeFormat = /^[0-2]?[0-9]:?([0-5][0-9]?)?$/;
      const matcher = time.match(incompleteTimeFormat);

      // if user input doesn't match an incomplete time: no need to handle
      if (!matcher) {
        this.restoreLocalValueAsInput(true);
        return;
      }
      // if time input is complete, it is already handled by onKeyup handler
      if (time.replace(':', '').length === 4) return;

      const timeLength = time.length;
      let hours;
      let minutes;
      if (time.includes(':')) {
        // input includes ':' -> no ambiguity over hours and minutes
        const timeElements = time.split(':');
        hours = timeElements[0];
        minutes = timeElements[1] ? timeElements[1] : '00';
      } else if (timeLength === 1) {
        // only one digit -> represents hours
        hours = time;
        minutes = '00';
      } else if (timeLength === 2) {
        // 2 digits
        // first pass : consider both digits as hours
        hours = time;
        minutes = '00';

        if (!this.isValidTime(hours, minutes)) {
          // else consider one digit as hours and one digit as minutes
          hours = time.substring(0, 1);
          minutes = time.substring(1, 2);
        }
      } else {
        // 3 digits
        // first pass : consider first digit as hours, and the other digits as minutes
        hours = time.substring(0, 1);
        minutes = time.substring(1, timeLength);

        if (!this.isValidTime(hours, minutes)) {
          // else use 2 first digits as hours
          hours = time.substring(0, 2);
          minutes = time.substring(2, timeLength);
        }
      }

      if (!this.isValidTime(hours, minutes)) {
        this.restoreLocalValueAsInput();
        return;
      }

      // use onKeyup method to process the time
      this.onKeyup({
        target: {
          value: this.timeStrFromHoursAndMinutes(hours, minutes),
        },
      });
    },
    restoreLocalValueAsInput(isErrored = false) {
      this.$refs.timeInput.localValue = this.localValueToInputValue;
      this.setIsErrored(isErrored);
    },
    timeStrFromHoursAndMinutes(hours, minutes) {
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      if (minutes.length === 1) {
        minutes = `${minutes}0`;
      }

      return `${hours}${minutes}`;
    },
    timeFromInput(value) {
      // value format is HH:mm or HHmm
      const hour = parseInt(value.substring(0, 2), 10);
      const minute = value.includes(':') ?
        parseInt(value.substring(3, 5), 10) :
        parseInt(value.substring(2, 4), 10);

      return this.referenceDate.clone().set({ hour, minute });
    },
    // Check if time exists among available options
    isValidTime(hours, minutes) {
      // Make sure hours < 24 and minutes < 60
      if (minutes.length === 1) {
        minutes = `${minutes}0`;
      }
      if (parseInt(hours, 10) > 23 || parseInt(minutes, 10) > 59) return false;

      const timeStr = this.timeStrFromHoursAndMinutes(hours, minutes);
      // If it's before openingTime, we need to check in case it's a night hour
      const inputTime = this.timeFromInput(timeStr);
      return (inputTime.isSameOrBefore(this.closingTime) &&
        inputTime.isSameOrAfter(this.openingTime)) ||
        inputTime.add(1, 'day').isSameOrBefore(this.closingTime);
    },
    // Scroll select dropdown to the activeTimeOption
    scrollToLocalValue() {
      if (!this.localValue) return;

      if (!this.simplebar) {
        this.simplebar = new SimpleBar(this.$refs.shiftTimePickerMenu);
      }
      this.$nextTick(() => {
        // Prevent to calculate valuePosition from previous position
        this.simplebar.getScrollElement().scrollTop = 0;

        const valuePosition = this.$refs.activeTimeOption[0].getClientRects()[0].top;
        const offSet = this.$refs.shiftTimePickerMenu.getClientRects()[0].top;
        this.simplebar.getScrollElement().scrollTo({
          top: valuePosition - offSet,
        });
      });
    },
    // Find the timeOption closest to localValue
    setActiveTimeOption(timeOption) {
      const timeOptionTime = skDate(timeOption.slice(11, -1), 'hh:mm:ss'); // truncate date
      const localValueSkDateTime = skDate(this.localValue.slice(11, -1), 'hh:mm:ss');
      const timeDifference = localValueSkDateTime.diff(timeOptionTime, 'minutes');
      // If time difference is lower than half the interval between each time options ->
      // this timeOption is the closest to localValue -> set to activeTimeOption
      return Math.abs(timeDifference) < (this.intervalInMinutes / 2) ? 'activeTimeOption' : null;
    },
    onTabPress() {
      this.$refs.shiftTimePicker.$refs.popper.doClose();
    },
  },
};
</script>

<style lang="scss" scoped>
$row-height: calc(48px);

.shift-time-picker__dropdown-menu {
  width: 70px;
  background: white;
  max-height: calc(#{$row-height} * 3 + #{$row-height} / 2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 4px;
  overflow: auto;
}

.shift-time-picker__dropdown-row {
  height: $row-height;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: $fs-text-m;

  &:hover {
    background: $sk-grey-10;
  }
}

// display error message properly over other elements
::v-deep .sk-input__error-wrapper {
  position: absolute;
  top: 41px;
  left: 0;
  z-index: 2;

  .sk-input__error-label {
    margin: 0;
    background-color: white;
    line-height: 12px;
  }
}

// bottom colored line should appear inside input
::v-deep .sk-input::before {
  top: 42px;
}
</style>
