<template>
  <tr>
    <td class="follow-up-docs-esignature-panel__user-row-cell">
      <div class="follow-up-docs-esignature-panel__user-row-cell__signers">
        <div class="follow-up-docs-esignature-panel__user-row-cell__signers-name">
          <div
            v-tooltip="signersName"
            class="table-label"
          >
            {{ truncatedSignersName }}
          </div>
        </div>
      </div>
    </td>
    <td class="follow-up-docs-esignature-panel__user-row-cell">
      <div
        v-tooltip="documentName"
        class="table-label"
      >
        {{ truncatedDocumentName }}
      </div>
    </td>
    <td class="follow-up-docs-esignature-panel__user-row-cell">
      <div
        v-tooltip="signedBy"
        class="table-label"
      >
        <ProgressIcon
          :partial="partialSigners"
          :total="totalSigners"
        />
      </div>
    </td>
    <td class="follow-up-docs-esignature-panel__user-row-cell">
      <div class="table-label">
        {{ sendingState }}
      </div>
    </td>
    <td
      class="follow-up-docs-esignature-panel__user-row-cell"
    >
      <div class="table-label">
        <div
          v-show="shouldShowResend"
          class="resend-button"
          @click="resendSignatureRequest"
        >
          <PaperAirplaneIcon />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>

import { mapState } from 'vuex';
import { truncateString } from '@skello-utils/formatting/strings';
import { httpClient } from '@skello-utils/clients';
import ProgressIcon from './ProgressIcon.vue';

export default {
  name: 'UserRow',
  components: { ProgressIcon },
  props: {
    docEsignature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fillColor: '#727272',
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    progressCircleStyles() {
      return { strokeDasharray: this.dashArray, strokeDashoffset: this.dashOffset };
    },
    radius() {
      return 8;
    },
    dashArray() {
      return this.radius * Math.PI * 2;
    },
    dashOffset() {
      return this.dashArray - (this.dashArray * this.signatureProgressPercentage) / 100;
    },
    signersName() {
      return this.docEsignature.attributes.signersName;
    },
    truncatedSignersName() {
      return truncateString(this.docEsignature.attributes.signersName, 30);
    },
    documentName() {
      return this.docEsignature.attributes.documentName;
    },
    truncatedDocumentName() {
      return truncateString(this.docEsignature.attributes.documentName, 30);
    },
    sendingState() {
      const { sendingState } = this.docEsignature.attributes;

      if (!sendingState) return '-';

      const date = new Date(this.docEsignature.attributes.sendingState);
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

      return date.toLocaleDateString('en-US', dateOptions);
    },
    signatureProgressPercentage() {
      if (this.isSignatureStatus('signed')) {
        return 100;
      }

      if (this.isSignatureStatus('partial')) {
        return 50;
      }

      return 0;
    },
    signedBy() {
      if (this.isSignatureStatus('signed')) {
        // Avoid unnecessary computation. If document is already signed by all we don't want to show the signers
        return false;
      }
      const { signers } = this.docEsignature.attributes;
      const partiallySignedByNames = signers.reduce((acc, signer) => {
        if (signer.is_signed) {
          acc.push(`${signer.firstName} ${signer.lastName}`);
        }
        return acc;
      }, []).join(', ');
      return partiallySignedByNames.length && `${partiallySignedByNames} ${this.$t('employees.signature_followup.tooltip.signed_the_document')}`;
    },
    partialSigners() {
      return this.docEsignature.attributes.signers.filter(signer => signer.is_signed).length;
    },
    totalSigners() {
      return this.docEsignature.attributes.signers.length;
    },
    shouldShowResend() {
      return this.currentShop.id !== 'all' && this.isSignatureStatus('pending', 'partial');
    },
  },
  methods: {
    isSignatureStatus(...statuses) {
      return statuses.includes(this.docEsignature.attributes.signatureStatus);
    },
    handleMouseover() {
      this.fillColor = '#2b66fe';
    },
    handleMouseleave() {
      this.fillColor = '#727272';
    },
    signatureStatusIs(...statuses) {
      return statuses.includes(this.docEsignature.attributes.signatureStatus);
    },
    signatureStatusIsPending() {
      return this.signatureStatusIs('awaiting-news-from-esignature-svc', 'documentCreated', 'signatureRequestCreated', 'signatureRequestSent', 'signedPendingDownload');
    },
    resendSignatureRequest() {
      this.$skAnalytics.track('esignature_documents_resend_follow_up_space');
      // We can assume based on product specs that the first signer will always be
      // the owner of the document
      const documentOwnerId = this.docEsignature.attributes.signers[0].id;
      const params = {
        document_id: this.docEsignature.id,
        organisation_id: this.currentOrganisation.id,
        user_id: documentOwnerId,
        shop_id: this.currentShop.id,
      };

      httpClient
        .post('/v3/api/request_esignatures/resend_document_esignature', params)
        .then(() => this.$skToast({
          message: this.$t('employees.signature_followup.resend.success'),
          variant: 'success',
        }))
        .catch(() => this.$skToast({
          message: this.$t('employees.signature_followup.resend.error'),
          variant: 'error',
        }));
    },
  },
};
</script>

<style lang="scss" scoped>

.sk-table > tbody td {
  border-bottom: 0;
}

.follow-up-docs-esignature-panel__user-row-cell {
  padding: 6px 0;
  align-items: center;
}

.follow-up-docs-esignature-side-panel__progress-bar {
  align-items: center;
}

.follow-up-docs-esignature-panel__user-row-cell__signers {
  display: flex;
  align-items: center;
  width: 110px;
}

.follow-up-docs-esignature-panel__user-row-cell__signers-name {
  flex-direction: column;
  margin-left: 10px;
}

.table-label {
  font-size: $fs-text-s !important;
  white-space: normal;
}

.resend-button {
  display: flex;
  align-content: center;
  cursor: pointer;
  left: 25%;
  position: relative;
}

</style>
