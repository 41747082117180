<template>
  <div class="sk-toolbar__wrapper badgings__toolbar-wrapper">
    <div class="badgings__toolbar__datepicker--canvas">
      <div class="badgings__toolbar__datepicker-canvas__input" />
      <div class="badgings__toolbar__datepicker-canvas__label" />
    </div>

    <div class="badgings__toolbar__navigation--canvas">
      <div class="badgings__toolbar__navigation-canvas__item" />
      <div class="badgings__toolbar__navigation-canvas__item" />
      <div class="badgings__toolbar__navigation-canvas__item" />
    </div>

    <div class="badgings__toolbar-actions badgings__toolbar-actions--canvas">
      <div class="badgings__toolbar-actions__last-sync--canvas" />
      <div class="badgings__toolbar-actions__submit--canvas" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolbarCanvas',
};
</script>

<style lang="scss" scoped>
.sk-toolbar__wrapper {
   min-height: 51px;
   background: $sk-grey-5;
   width: 100%;
   top: 0;
   display: flex;
   align-items: center;
   border-bottom: none;
   z-index: 8;
}

.badgings__toolbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.badgings__toolbar-actions {
  width: 280px;
  display: flex;
}

.badgings__toolbar-actions--canvas {
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}

.badgings__toolbar-actions__last-sync--canvas {
  height: 20px;
  width: 70px;
  opacity: .15;
  border-radius: 2px;
  margin-right: 20px;
  background: linear-gradient(-90deg, $sk-grey 0%, #f5f5f5 30%, $sk-grey 80%);
  animation: loadingSkeleton 1s ease-in-out infinite;
  background-size: 400% 400%;
}

.badgings__toolbar-actions__submit--canvas {
  width: 140px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(-90deg, $sk-blue-5 0%, #ebf2fd 30%, $sk-blue-5 80%);
  animation: loadingSkeleton 1s ease-in-out infinite;
  background-size: 400% 400%;
}

.badgings__toolbar__datepicker--canvas,
.badgings__toolbar__navigation--canvas {
  display: flex;
  align-items: center;
}

.badgings__toolbar__datepicker--canvas {
  justify-content: flex-start;
  margin-left: 48px;
}

.badgings__toolbar__navigation--canvas {
  justify-content: center;
}

.badgings__toolbar__datepicker-canvas__input {
  width: 150px;
  height: 40px;
  border-radius: 6px;
  margin-right: 20px;
  background: linear-gradient(-90deg, white 0%, #f5f5f5 30%, white 80%);
  animation: loadingSkeleton 1s ease-in-out infinite;
  background-size: 400% 400%;
}

.badgings__toolbar__datepicker-canvas__label {
  width: 70px;
  height: 20px;
  opacity: .15;
  border-radius: 2px;
  background: linear-gradient(-90deg, $sk-grey 0%, #f5f5f5 30%, $sk-grey 80%);
  animation: loadingSkeleton 1s ease-in-out infinite;
  background-size: 400% 400%;
}

.badgings__toolbar__navigation-canvas__item {
  width: 80px;
  height: 16px;
  border-radius: 2px;
  margin-left: 20px;
  background: linear-gradient(-90deg, #d3d8dc 0%, #f5f5f5 30%, #d3d8dc 80%);
  animation: loadingSkeleton 1s ease-in-out infinite;
  background-size: 400% 400%;

  &:first-child {
    margin-left: 0;
  }
}

@keyframes loadingSkeleton {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}
</style>
