export const planningWeekZoom = {
  25: {
    planningWrapper: {
      fontSize: '7px',
    },
    planningRow: {
      minHeight: '32px',
    },
    shift: {
      alertIcon: {
        height: '8px',
        width: '8px',
      },
      innerTop: {
        flex: '0 0 10px',
        marginBottom: '2px',
      },
      innerBottom: {
        padding: '1px 6px',
      },
      label: {
        height: '9px',
      },
      validated: {
        paddingTop: '3px',
        lineHeight: '8px',
      },
      wrapper: {
        borderRadius: '3px',
      },
      wrapperLeftEarly: {
        borderRadius: '0 3px 3px 0',
      },
      wrapperDelay: {
        borderRadius: '3px 0 0 3px',
      },
      menuTopMargin: 15,
    },
    popularShift: {
      innerBottom: {
        padding: '1px 3px 3px 28px',
      },
    },
    headerWrapper: {
      height: '41px',
    },
    headerDayFooter: {
      marginTop: '-5px',
      padding: '0 11px 20px',
    },
    headerDay: {
      icon: {
        height: '9px',
        width: '9px',
      },
      name: {
        width: '25px',
        height: '25px',
      },
    },
    headerSidebar: {
      fontSize: '11px',
    },
    diffHours: {
      icon: {
        width: '10px',
        height: '10px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'x-small',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-10px',
          left: '11px',
        },
      },
    },
  },
  50: {
    planningWrapper: {
      fontSize: '8px',
    },
    planningRow: {
      minHeight: '42px',
    },
    shift: {
      alertIcon: {
        height: '9px',
        width: '9px',
      },
      innerTop: {
        flex: '0 0 16px',
      },
      innerBottom: {
        padding: '2px 6px',
      },
      label: {
        height: '10px',
      },
      validated: {
        paddingTop: '0',
      },
      wrapper: {
        borderRadius: '4px',
      },
      wrapperLeftEarly: {
        borderRadius: '0 4px 4px 0',
      },
      wrapperDelay: {
        borderRadius: '4px 0 0 4px',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '1px 5px 3px 28px',
      },
    },
    headerWrapper: {
      height: '47px',
    },
    headerDayFooter: {
      marginTop: '-5px',
      padding: '0 17px 4px',
    },
    headerDay: {
      icon: {
        height: '10px',
        width: '10px',
      },
      name: {
        width: '26px',
        height: '26px',
      },
    },
    headerSidebar: {
      fontSize: '12px',
    },
    diffHours: {
      icon: {
        width: '11px',
        height: '11px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'small',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-13px',
          left: '12px',
        },
      },
    },
  },
  75: {
    planningWrapper: {
      fontSize: '9px',
    },
    planningRow: {
      minHeight: '44px',
    },
    shift: {
      alertIcon: {
        height: '11px',
        width: '11px',
      },
      innerTop: {
        flex: '0 0 16px',
      },
      innerBottom: {
        padding: '2px 6px',
      },
      label: {
        height: '12px',
      },
      validated: {
        paddingTop: '0',
      },
      wrapper: {
        borderRadius: '5px',
      },
      wrapperLeftEarly: {
        borderRadius: '0 5px 5px 0',
      },
      wrapperDelay: {
        borderRadius: '5px 0 0 5px',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '1px 7px 3px 28px',
      },
    },
    headerWrapper: {
      height: '55px',
    },
    headerDayFooter: {
      padding: '0 17px 8px',
    },
    headerDay: {
      icon: {
        height: '12px',
        width: '12px',
      },
      name: {
        width: '28px',
        height: '28px',
      },
    },
    headerSidebar: {
      fontSize: '13px',
    },
    diffHours: {
      icon: {
        width: '12px',
        height: '12px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'small',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-10px',
          left: '13px',
        },
      },
    },
  },
  100: {
    planningWrapper: {
      fontSize: '10px',
    },
    planningRow: {
      minHeight: '48px',
    },
    shift: {
      alertIcon: {
        height: '12px',
        width: '12px',
      },
      innerTop: {
        flex: '0 0 16px',
      },
      innerBottom: {
        padding: '3px 6px',
      },
      label: {
        height: '14px',
      },
      validated: {
        paddingTop: '0',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '1px 9px 3px 28px',
      },
    },
    headerWrapper: {
      height: '61px',
    },
    headerDayFooter: {
      padding: '0 17px 10px',
    },
    headerDay: {
      icon: {
        height: '14px',
        width: '14px',
      },
      name: {
        width: '31px',
        height: '31px',
      },
    },
    headerSidebar: {
      fontSize: '14px',
    },
    diffHours: {
      icon: {
        width: '14px',
        height: '14px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'medium',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-13px',
          left: '18px',
        },
      },
    },
  },
  125: {
    planningWrapper: {
      fontSize: '11px',
    },
    planningRow: {
      minHeight: '54px',
    },
    shift: {
      alertIcon: {
        height: '14px',
        width: '14px',
      },
      innerTop: {
        flex: '0 0 16px',
      },
      innerBottom: {
        padding: '4px 6px',
      },
      label: {
        height: '16px',
      },
      validated: {
        paddingTop: '0',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '2px 9px 3px 28px',
      },
    },
    headerWrapper: {
      height: '68px',
    },
    headerDayFooter: {
      padding: '0 17px 14px',
    },
    headerDay: {
      icon: {
        height: '16px',
        width: '16px',
      },
      name: {
        width: '33px',
        height: '33px',
      },
    },
    headerSidebar: {
      fontSize: '15px',
    },
    diffHours: {
      icon: {
        width: '16px',
        height: '16px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'large',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-13px',
          left: '18px',
        },
      },
    },
  },
  150: {
    planningWrapper: {
      fontSize: '12.5px',
    },
    planningRow: {
      minHeight: '55px',
    },
    shift: {
      alertIcon: {
        height: '15px',
        width: '15px',
      },
      innerTop: {
        flex: '0 0 16px',
      },
      innerBottom: {
        padding: '5px 6px',
      },
      label: {
        height: '16px',
      },
      validated: {
        paddingTop: '0',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '3px 9px 5px 28px',
      },
    },
    headerWrapper: {
      height: '74px',
    },
    headerDayFooter: {
      padding: '0 17px 17px',
    },
    headerDay: {
      icon: {
        height: '17px',
        width: '17px',
      },
      name: {
        width: '33px',
        height: '33px',
      },
    },
    headerCounterLinkLeft: {
      marginRight: '-10px',
    },
    headerCounterLinkRight: {
      marginLeft: '-10px',
    },
    headerSidebar: {
      fontSize: '16.5px',
    },
    diffHours: {
      icon: {
        width: '16.5px',
        height: '16.5px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'large',
      },
      warning: {
        size: 'x-small',
        style: {
          top: '-13px',
          left: '18px',
        },
      },
    },
  },
  175: {
    planningWrapper: {
      fontSize: '14px',
    },
    planningRow: {
      minHeight: '68px',
    },
    shift: {
      alertIcon: {
        height: '16px',
        width: '16px',
      },
      innerTop: {
        flex: '0 0 28px',
      },
      innerBottom: {
        padding: '5px 6px',
      },
      label: {
        height: '18px',
      },
      validated: {
        paddingTop: '0',
      },
      previsional: {
        margin: '-5px auto 0 0',
      },
      menuTopMargin: -15,
      icons: {
        marginLeft: '1px',
      },
    },
    popularShift: {
      innerBottom: {
        padding: '5px 9px 7px 28px',
      },
    },
    headerWrapper: {
      height: '80px',
    },
    headerDayFooter: {
      padding: '0 17px 24px',
    },
    headerDay: {
      icon: {
        height: '18px',
        width: '18px',
      },
      name: {
        width: '34px',
        height: '34px',
      },
    },
    headerCounterLinkLeft: {
      marginRight: '-10px',
    },
    headerCounterLinkRight: {
      marginLeft: '-10px',
    },
    headerSidebar: {
      fontSize: '18px',
    },
    diffHours: {
      icon: {
        width: '17px',
        height: '17px',
      },
    },
    avatarMedaillon: {
      avatar: {
        size: 'large',
      },
      warning: {
        size: 'small',
        style: {
          top: '-13px',
          left: '18px',
        },
      },
    },
  },
};
