<template>
  <SkEventModal
    v-if="!isScreenLoading"
    flubber-color="#ffe5e5"
    :header-icon="teamIcon"
    :header-icon-color="$skColors.skCoral"
    submit-button-data-test="validate_employee"
    :on-submit-click="handleSubmit"
    :submit-btn-label="$t('onboarding_modal.employee.submit_text')"
    :is-submit-disabled="isSubmitDisabled"
    :cancel-btn-label="canGoBack ? $t('onboarding_modal.employee.go_back') : ''"
    :on-cancel-click="handleCancel"
  >
    <template #content>
      <div class="employee-step__title">
        {{ employeeStepTitle }}
      </div>
      <div class="employee-step__text">
        {{ $t('onboarding_modal.employee.text') }}
      </div>
      <EmployeeStepUserRow
        v-for="(user, index) in employees"
        :key="index"
        :user="user"
        :index="index"
        :employees="employees"
        :any-imported-employees="importedEmployees.length > 0"
        @update-errored="user.errored = $event"
        @update-duplicate-email="setDuplicateEmailAsErrored"
        @delete-row="handleDeleteRow"
      />
      <SkOroraButton
        v-track="'onboarding_add_employee'"
        variant="tertiary"
        icon="PlusSignV2Icon"
        data-test="add_employee"
        @click="addEmployeeRow"
      >
        {{ $t('onboarding_modal.employee.add_employee') }}
      </SkOroraButton>
    </template>
  </SkEventModal>
</template>

<script>
import {
  SkOroraButton,
  TeamV2Icon,
  SkEventModal,
} from '@skelloapp/skello-ui';
import EmployeeStepUserRow from './EmployeeStepUserRow';

export default {
  name: 'EmployeeStep',
  components: {
    SkEventModal,
    SkOroraButton,
    EmployeeStepUserRow,
  },
  props: {
    importedEmployees: {
      type: Array,
      default: () => [],
    },
    // this props will be filled when going back on this step
    createdEmployees: {
      type: Array,
      default: () => [],
    },
    canGoBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teamIcon: TeamV2Icon,
      employees: [],
      isScreenLoading: false,
    };
  },
  computed: {
    isSubmitDisabled() {
      const anyErroredEmployee = this.employees.some(employee => employee.errored);
      if (anyErroredEmployee) return true;

      const anyOngoingLine =
        this.employees.filter(employee => !!employee.firstName !== !!employee.lastName).length > 0;
      if (anyOngoingLine) return true;

      const anyEmailLineMissingRequiredFields =
        this.employees.filter(
          employee => employee.email && (!employee.firstName || !employee.lastName),
        ).length > 0;
      if (anyEmailLineMissingRequiredFields) return true;

      const anyEmailLineWithDuplicate =
        this.employees.filter(employee => employee.emailLocalDuplicates);
      if (anyEmailLineWithDuplicate.length > 0) return true;

      return this.employees.filter(employee => employee.firstName && employee.lastName).length < 2;
    },
    employeeStepTitle() {
      const validEmployeesCount =
        this.employees.filter(
          employee => employee.firstName || employee.lastName || employee.email,
        ).length;

      return this.$tc('onboarding_modal.employee.title', validEmployeesCount);
    },
  },
  mounted() {
    this.isScreenLoading = true;

    if (this.createdEmployees.length > 0) {
      this.employees = this.createdEmployees;
    } else if (this.importedEmployees.length > 0) {
      this.initializeEmployeesFromLLM();
    } else {
      this.addEmployeeRow();
      this.addEmployeeRow();
    }

    this.isScreenLoading = false;
  },
  methods: {
    initializeEmployeesFromLLM() {
      const addExtraEmployeeRow = this.importedEmployees.length === 1;

      this.employees = this.importedEmployees.map(employee => ({
        id: null,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: '',
        emailLocalDuplicates: null,
        errored: false,
        old: {
          firstName: employee.firstName,
          lastName: employee.lastName,
        },
      }));

      if (addExtraEmployeeRow) {
        this.addEmployeeRow();
      }
    },
    addEmployeeRow() {
      this.employees.push(
        { id: null, firstName: '', lastName: '', email: '', emailLocalDuplicates: null, errored: false, old: undefined },
      );
    },
    handleDeleteRow(index) {
      this.employees.splice(index, 1);
    },
    setDuplicateEmailAsErrored({ email, isDuplicating }) {
      // if we're removing a duplicate and there are still some left, don't reset the others errored
      if (!isDuplicating) {
        if (this.employees.filter(employee => employee.email === email).length > 1) {
          return;
        }
      }

      this.employees.forEach(employee => {
        if (employee.email === email) {
          employee.emailLocalDuplicates = isDuplicating ? email : null;
        }
      });
    },
    handleSubmit() {
      this.$skAnalytics.track('onboarding_submit_employee_step');
      this.$emit('update-employees', this.employees);
    },
    handleCancel() {
      this.$emit('previous');
    },
  },
};
</script>

<style lang="scss" scoped>
  .employee-step__title {
    font-family: Gellix;
    font-size: $fs-heading-l;
    font-weight: $fw-semi-bold;
    height: 36px;
    line-height: 36px;
  }

  .employee-step__text {
    color: $sk-grey-50;
    font-family: Gellix;
    font-size: $fs-text-l;
    font-weight: $fw-regular;
    padding-top: 16px;
    line-height: normal;
    padding-bottom: 32px;
  }

  .employee-step__row {
    display: flex;
    justify-content: space-between;
    font-family: Gellix;
    font-size: $fs-text-m;
    font-weight: $fw-regular;
    color: $sk-grey-50;
    margin-bottom: 12px;
  }
</style>
