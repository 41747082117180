<template>
  <img
    width="42"
    src="./images/logo-spayr.png"
  >
</template>

<script>
export default {
  name: 'Spayr',
};
</script>
