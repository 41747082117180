<template>
  <div class="calendar">
    <div class="title">
      {{ $t('profile.calendar.header') }}
    </div>
    <div class="content">
      <div class="content__description">
        {{ $t('profile.calendar.subtitle') }}
      </div>
      <div class="content__input">
        <SkInput
          :value="url"
          :disabled="true"
        />
        <div
          id="copy"
          :data-clipboard-text="url"
        >
          <FilesV2Icon />
        </div>
      </div>
    </div>
    <div class="cards">
      <div class="cards__title">
        {{ $t('profile.calendar.content.title') }}
      </div>
      <div class="cards__content">
        <div
          v-for="calendar in calendars"
          :key="calendar.ref"
          :ref="calendar.ref"
          class="card"
          @click="scrollTo(calendar.ref)"
        >
          <SkCollapse>
            <template #labelLeft>
              <div class="card__title">
                <div class="card__icon">
                  <component
                    :is="calendar.icon"
                    height="32"
                    width="32"
                  />
                </div>
                {{ $t(`profile.calendar.content.cards.${calendar.ref}.title`) }}
              </div>
            </template>
            <template #content>
              <div class="card__content">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="$t(`profile.calendar.content.cards.${calendar.ref}.content`)" />

                <div class="documentation">
                  <SkOroraButton
                    :href="calendar.link"
                    variant="tertiary"
                    icon="ExternalLinkV2Icon"
                    target="_blank"
                  >
                    {{ $t(`profile.calendar.content.cards.${calendar.ref}.documentation`) }}
                  </SkOroraButton>
                </div>
              </div>
            </template>
          </skcollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { captureException } from '@sentry/vue';
import ClipboardJS from 'clipboard';
import { httpClient } from '@skello-utils/clients';
import {
  SkInput,
  FilesV2Icon,
  AppleIcon,
  SkCollapse,
} from '@skelloapp/skello-ui';
import GoogleIcon from '@app-js/shared/assets/svg/calendar/GoogleIcon.vue';
import OutlookIcon from '@app-js/shared/assets/svg/calendar/OutlookIcon.vue';

export default {
  name: 'Calendar',
  components: {
    SkInput,
    FilesV2Icon,
    AppleIcon,
    GoogleIcon,
    OutlookIcon,
    SkCollapse,
  },
  data() {
    return {
      url: '',
      calendars: [
        {
          ref: 'apple_mac',
          icon: 'AppleIcon',
          link: 'https://support.apple.com/102301',
        },
        {
          ref: 'apple_iphone',
          icon: 'AppleIcon',
          link: 'https://support.apple.com/102301',
        },
        {
          ref: 'google_calendar',
          icon: 'GoogleIcon',
          link: 'https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop',
        },
        {
          ref: 'outlook_calendar',
          icon: 'OutlookIcon',
          link: 'https://support.microsoft.com/office/pr%C3%A9sentation-de-la-publication-de-calendriers-internet-a25e68d6-695a-41c6-a701-103d44ba151d?redirectSourcePath=%252ffr-fr%252farticle%252fAfficher-et-s-abonner-%2525C3%2525A0-des-calendriers-Internet-7cd5d35a-4a90-4d70-a2f7-f6fadd7e2d9a',
        },
      ],
      copyPlugin: new ClipboardJS('#copy'),
    };
  },
  async created() {
    this.copyPlugin.on('success', () => {
      this.$skToast({
        message: this.$t('profile.calendar.copy.success'),
        variant: 'success',
      });
    });

    this.copyPlugin.on('error', () => {
      this.$skToast({
        message: this.$t('profile.calendar.copy.error'),
        variant: 'error',
      });
    });

    try {
      const response = await httpClient.get('/calendar_subscriptions');

      this.url = response.data.ics_feed_url;
    } catch (error) {
      captureException(error);
    }
  },
  methods: {
    scrollTo(ref) {
      this.$nextTick(() => {
        const card = this.$refs[ref]?.[0];

        if (card) {
          card.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  .title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
    line-height: 1;
  }

  .content {
    margin-bottom: 32px;

    &__description {
      font-size: 14px;
      margin-bottom: 16px;
    }

    &__input {
      display: flex;
      align-items: center;
      max-width: 514px;
      background-color: $sk-grey-5;
      border-radius: 4px;

      svg {
        margin: 0px 8px;
        height: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .cards {
    &__title {
      font-size: 20px;
      font-weight: 600;
    }

    .card {
      border: 1px solid $sk-grey-10;
      border-radius: 4px;
      padding: 0 20px;
      background: #fff;
      margin-top: 16px;

      &__icon {
        border: 1px solid $sk-grey-10;
        height: 70px;
        width: 70px;
        border-radius: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      &__title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
      }

      &__content {
        padding: 0px 20px;
        margin-bottom: 12px;

        .documentation {
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

  }
}
</style>
