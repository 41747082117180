<template>
  <div :class="sectionClasses">
    <h3 class="sk-header--3">
      {{ title }}
    </h3>
    <div class="absence-section__header">
      <span class="absence-section__header__active">
        {{ $t('shop_settings.tabs.absences.table.active') }}
      </span>
      <span class="absence-section__header__type">
        {{ $t('shop_settings.tabs.absences.table.absence_type') }}
      </span>
      <span
        v-if="showPtoReduction"
        class="absence-section__header__pto"
      >
        <span class="absence-section__header__pto__explanation">
          {{ $t('shop_settings.tabs.absences.table.pto_enablement') }}
        </span>
      </span>
      <span
        v-if="isMealCompensationDone"
        class="absence-section__header__compensation"
      >
        {{ $t('shop_settings.tabs.absences.table.meal_trigger') }}
      </span>
    </div>
    <div class="absence-section__content">
      <AbsenceRow
        v-for="absence in absences"
        :key="absence.id"
        :absence="absence"
        :show-pto-reduction="showPtoReduction"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';
import AbsenceRow from '../AbsenceRow';

export default {
  components: { AbsenceRow },
  props: {
    title: {
      type: String,
      required: true,
    },
    absences: {
      type: Array,
      required: true,
    },
    firstSection: {
      type: Boolean,
      default: false,
    },
    middleSection: {
      type: Boolean,
      default: false,
    },
    lastSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isMealCompensationDone', 'checkFeatureFlag']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    sectionClasses() {
      return {
        'section-wrapper': true,
        'section-wrapper__border__first-section': this.firstSection,
        'section-wrapper__border__middle-section': this.middleSection,
        'section-wrapper__border__last-section': this.lastSection,
      };
    },
    showPtoReduction() {
      // neutral absences currently are in the last section
      // but we do not want to rely on lastSection to display the tag since order can change one day
      return this.absences[0].attributes.absenceType !== 'neutral' &&
        this.checkFeatureFlag('FEATURE_SHOW_PTO_REDUCTION_ABSENCES') &&
        this.isFeatureEnabled(FEATURES.FEATURE_PTO, this.currentShop.id, () => true);
    },
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  border: 1px solid $sk-grey-10;
  border-bottom: none;
  border-radius: 4px;
  padding-bottom: 10px;
}

.section-wrapper {
  border: 1px solid $sk-grey-10;
  padding-bottom: 10px;

  &.section-wrapper__border__first-section {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  &.section-wrapper__border__middle-section {
    border-radius: 0;
    border-bottom: none;
  }

  &.section-wrapper__border__last-section {
    border-radius: 0 0 4px 4px;
  }
}

h3.sk-header--3 {
  padding: 8px;
}

.absence-section__header {
  display: flex;
  align-items: center;
  height: 30px;
  font-size: $fs-text-s;
  color: $sk-grey;
  padding: 0 26px 2px;

  &__active {
    width: 106px;
  }

  &__type {
    width: 286px;
  }

  &__pto {
    display: flex;
    align-items: flex-end;

    &__explanation {
      width: 118px;
    }

    &__tooltip {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  &__compensation {
    width: 118px;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.absence-section__content {
  margin: 0 16px;
}
</style>
