<template>
  <SkModalV2
    id="update-annualization-trackers-modal"
    ref="updateAnnualizationTrackersModal"
    class="update-annualization-trackers-modal"
    :submit-button-label="$t('reports.modals.update_annualization_trackers.submit')"
    :submit-disabled="isSubmitDisabled"
    :submit-loading="isSubmitLoading"
    :test-options="testingOptions"
    :title="$t('reports.modals.update_annualization_trackers.title')"
    @cancel="handleCancel"
    @show="resetData"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div>
          {{ $t('reports.modals.update_annualization_trackers.info', { week, date }) }}
        </div>

        <div class="update-annualization-trackers-modal__select-employees">
          <span>
            {{ $t('reports.modals.update_annualization_trackers.select.users_label') }}
          </span>

          <div class="update-annualization-trackers-modal__select-employees-wrapper">
            <SelectEmployees
              ref="selectEmployees"
              :employees-infos="annualizedEmployeesInfos"
              @set-selected-employees="handleSelectedEmployees"
            />
          </div>
        </div>

        <div border-bottom="none">
          <div class="update-annualization-trackers-modal__update-info">
            <InfoV2Icon
              class="update-annualization-trackers-modal__update-info-icon"
              width="14"
              fill="#727272"
            />
            {{ $t('reports.modals.update_annualization_trackers.update_tracker_info') }}
          </div>
          <table class="update-annualization-trackers-modal__table">
            <colgroup>
              <col
                span="1"
                width="100%"
              >
              <col
                span="3"
                width="66%"
              >
            </colgroup>
            <thead>
              <tr>
                <th />
                <!-- eslint-disable vue/no-v-html -->
                <th v-html="trackerLabelBefore" />
                <th v-html="trackerLabelValue" />
                <th v-html="trackerLabelAfter" />
                <!-- eslint-enable vue/no-v-html -->
              </tr>
            </thead>
            <tbody>
              <TrackerRow
                v-for="user in selectedUsers"
                :key="user.id"
                :user="user"
                :tracker-in-seconds="advanceDelayInSeconds(user.id)"
                :is-counter-initialized="!!employeesInfos[user.id].annualization_data.advanceDelay"
                @update-user-manual-changes="newManualChanges =>
                  handleManualChangeUpdate(user.id, newManualChanges)"
              />
            </tbody>
          </table>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { nlToBr } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';
import SelectEmployees from './SelectEmployees';
import TrackerRow from './TrackerRow';

export default {
  name: 'UpdateAnnualizationTrackers',
  components: {
    SelectEmployees,
    TrackerRow,
  },
  props: {
    employeesInfos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      manualChangesPerUser: {},
      manualChangesPerUserInput: {},
      selectedUserIds: [],
      isSubmitLoading: false,
      trackerAction: 'reset',
      trackerLabelAfter: nlToBr(this.$t('reports.modals.update_annualization_trackers.table.tracker_value_after')),
      trackerLabelBefore: nlToBr(this.$t('reports.modals.update_annualization_trackers.table.tracker_value_before')),
      trackerLabelValue: nlToBr(this.$t('reports.modals.update_annualization_trackers.table.change_value')),
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),

    annualizedEmployeesInfos() {
      return Object
        .values(this.employeesInfos)
        .filter(employeeInfo => employeeInfo.annualization_data);
    },
    date() {
      return this.lastSunday.format('L');
    },
    isSubmitDisabled() {
      return this.selectedUsers.length === 0 || this.isFormErrored;
    },
    isFormErrored() {
      const formValues = Object.values(this.manualChangesPerUserInput);

      return formValues.length === 0 ||
        formValues.some(value => value === '') ||
        formValues.some(value => value === 0);
    },
    lastSunday() {
      const endDate = skDate.utc(this.$route.query.end_date);

      if (endDate.day() === 0) {
        return endDate;
      }

      return endDate.startOf('isoWeek').subtract(1, 'd');
    },
    selectedUsers() {
      return this.users.filter(user => this.selectedUserIds.includes(user.id));
    },
    testingOptions() {
      return {
        cancel: 'update-annualization-trackers-modal__cancel',
        close: 'update-annualization-trackers-modal__close',
        submit: 'update-annualization-trackers-modal__submit',
      };
    },
    users() {
      return Object.values(this.employeesInfos).map(user => user.user_infos).filter(Boolean);
    },
    week() {
      return this.lastSunday.isoWeek();
    },
  },
  methods: {
    advanceDelayInSeconds(userId) {
      return this.employeesInfos[userId].annualization_data.realized * 3600;
    },
    periodManualChanges(userId) {
      return this.employeesInfos[userId].annualization_data.periodManualChanges;
    },
    handleCancel() {
      this.$skAnalytics.track('report_annualization_update_cancellation');

      this.$refs.updateAnnualizationTrackersModal.hide();
    },
    handleManualChangeUpdate(userId, value) {
      Vue.set(this.manualChangesPerUserInput, userId, value);

      // annualization lib aggregates manual changes by Mondays
      const lastChangeTimeStamp = this.lastSunday.startOf('isoWeek').valueOf();
      const lastWeekManualChanges = this.periodManualChanges(userId)[lastChangeTimeStamp];

      const newManualChanges = typeof lastWeekManualChanges === 'number' ?
        lastWeekManualChanges + value :
        value;

      Vue.set(this.manualChangesPerUser, userId, newManualChanges);
    },
    handleSelectedEmployees(newSelectedUserIds) {
      this.selectedUserIds = newSelectedUserIds;
    },
    async handleSubmit(event) {
      this.$skAnalytics.track('report_annualization_update_confirmation');

      event.preventDefault();
      this.isSubmitLoading = true;

      const shopId = this.currentShop.id;
      const body = Object.entries(this.manualChangesPerUser).map(([userId, value]) => ({
        shopId,
        userId,
        manualChanges: {
          [this.lastSunday.valueOf()]: value,
        },
      }));

      try {
        await svcEmployeesClient.bulkUpdateAnnualizationConfigs(shopId, body);

        this.$skToast({
          message: this.$t('reports.modals.update_annualization_trackers.update_success'),
          variant: 'success',
        });

        this.$emit('trackers-updated');
      } catch {
        this.$skToast({
          message: this.$t('reports.modals.update_annualization_trackers.update_error'),
          variant: 'error',
        });
      } finally {
        this.isSubmitLoading = false;
        this.$refs.updateAnnualizationTrackersModal.hide();
      }
    },
    resetData() {
      this.$refs.selectEmployees.resetData();
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
</script>

<style lang="scss" scoped>
.update-annualization-trackers-modal__select-employees {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
  padding: 24px 0;
}

.update-annualization-trackers-modal__select-employees-wrapper {
  width: 265px;
}

.update-annualization-trackers-modal__update-info {
  display: flex;
  align-items: center;
  margin: 0 0 16px 4px;
}

.update-annualization-trackers-modal__update-info-icon {
  margin-right: 8px;
}

.update-annualization-trackers-modal__table {
  table-layout: fixed;
  width: 100%;
  padding: 0 24px;

  tr th {
    text-align: center;
    font-weight: $fw-semi-bold;
  }
}
</style>
