<template>
  <div :class="tabClasses">
    <div
      v-tooltip.left="iconTooltip"
      :class="totalPeriodTabHeaderClass"
      @click="toggleTab"
    >
      <ClosingXIcon
        v-if="displayTotalPeriodTab"
        width="8"
        height="8"
        fill="white"
      />
      <OpenDotsIcon v-else />
    </div>
    <div class="total-period-tab-header__title-container">
      <div class="total-period-tab-header__title">
        {{ $t('plannings.table.header.counters.total_period.title') }}
      </div>
      <div class="total-period-tab-header__subtitle">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  ClosingXIcon, OpenDotsIcon,
} from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';

export default {
  name: 'TotalPeriodTabHeader',
  components: { ClosingXIcon, OpenDotsIcon },
  props: {
    isDailyView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', ['displayTotalPeriodTab']),
    ...mapGetters('planningsState', ['monday']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    totalPeriodTabHeaderClass() {
      return {
        'total-period-tab-header__icon': true,
        'total-period-tab-header__icon--faded': this.isProgressiveLoadingEnabled,
      };
    },
    iconTooltip() {
      const tradKey = this.isDailyView ? 'total_week' : 'total_month';
      return this.displayTotalPeriodTab ?
        this.$t('plannings.table.header.counters.total_period.close') :
        this.$t(`plannings.table.header.counters.total_period.${tradKey}`);
    },
    tabClasses() {
      return {
        'total-period-tab-header__container': true,
        'total-period-tab-header__container--open': this.displayTotalPeriodTab,
      };
    },
    subtitle() {
      const tradKey = this.isDailyView ? 'week' : 'month';
      return this.$t(`plannings.table.header.counters.total_period.subtitle.${tradKey}`);
    },
  },
  methods: {
    ...mapMutations('planningsState', ['toggleTotalPeriodTab']),
    ...mapActions('planningsShifts', ['fetchShifts']),
    toggleTab() {
      this.toggleTotalPeriodTab();
      if (this.displayTotalPeriodTab) {
        const tracker = this.isDailyView ? 'planning_day_weekly_total' : 'planning_week_monthly_total';
        this.$skAnalytics.track(tracker);
        if (!this.isDailyView) {
          const params = {
            shop_id: this.currentShop.id,
            starts_at: skDate(this.monday).startOf('month').format('YYYY-MM-DD'),
            ends_at: skDate(this.monday).endOf('month').format('YYYY-MM-DD'),
            is_monthly_fetch: true,
          };

          this.fetchShifts({ params });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total-period-tab-header__container {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2;
  position: absolute;
  left: -23px;
  top: 0;
  transition: 1s;
  -webkit-transition: 1s;
  -ms-transition: 1s;

  &--open {
    left: -91px;
  }
}

.total-period-tab-header__icon {
  position: relative;
  top: 3px;
  width: 23px;
  height: 23px;
  background-color: black;
  border-radius: 12px 0 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * {
    flex: 1;
  }

  &--faded {
    animation: colorIn 0.3s ease-out;

    @keyframes colorIn {
      0% {
        background-color: transparent;
      }
      100% {
        background-color: $sk-black;
      }
    }
  }
}

.total-period-tab-header__title-container {
  background-color: $sk-grey-5;
  height: 100%;
  width: 68px;
  color: $sk-grey;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: content-box;

  .total-period-tab-header__title {
    padding-top: 1.3em;
    font-size: 1.4em;
    line-height: 10px;
  }

  .total-period-tab-header__subtitle {
    font-size: 1em;
  }
}
</style>
