import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import {
  createWeekPlanningShift,
  updateWeekPlanningShift,
  deleteWeekPlanningShift,
  deleteBulkWeekPlanningShift,
  createWeekPlanningAbsence,
  updateWeekPlanningAbsence,
  deleteWeekPlanningAbsence,
  updateSwapPlanningUserShifts,
} from './events/planning/week_planning';
import {
  createDayPlanningShift,
  updateDayPlanningShift,
  deleteDayPlanningShift,
  deleteBulkDayPlanningShift,
  createDayPlanningAbsence,
  updateDayPlanningAbsence,
  deleteDayPlanningAbsence,
} from './events/planning/day_planning';
import { planningPublish } from './events/planning/planning_publish';
import { planningSetting } from './events/planning/planning_setting';
import { planningApplyTemplate } from './events/planning/planning_apply_template';
import { formatShopHoursChanged } from './events/shop/shop_hours_changed';
import { formatShopHourTrackerEnable } from './events/shop/shop_hour_tracker_enable';
import { formatShopHourTrackerDisable } from './events/shop/shop_hour_tracker_disable';
import { formatShopPTOTrackerEnable } from './events/shop/shop_pto_tracker_enable';
import { formatShopPTOTrackerDisable } from './events/shop/shop_pto_tracker_disable';
import { planningSortEmployees } from './events/planning/planning_sort_employees';
import { formatReportBulkHourTrackerReset } from './events/report/report_bulk_hour_tracker_reset';
import { formatReportBulkHourTrackerUpdate } from './events/report/report_bulk_hour_tracker_update';
import { formatCompanyLicenseUpdate } from './events/company/company_license_update';
import { formatEmployeeHourTrackerUpdate } from './events/employee/employee_hour_tracker_update';
import { formatEmployeeLicenseUpdate } from './events/employee/employee_license_update';
import { formatEmployeePTOTrackerUpdate } from './events/employee/employee_PTO_tracker_update';
import { formatShopTeamCreate } from './events/shop/team/shop_team_create';
import { formatShopTeamDelete } from './events/shop/team/shop_team_delete';
import { formatEmployeeDocumentUploaded } from './events/employee/employee_document_uploaded';
import { formatEmployeeDocumentDeleted } from './events/employee/employee_document_deleted';
import {
  formatShopTeamUpdateEmployees, formatShopTeamUpdateName,
} from './events/shop/team/shop_team_update';
import { formatShopPosteCreate } from './events/shop/poste/shop_poste_create';
import { formatShopPosteDelete } from './events/shop/poste/shop_poste_delete';
import {
  formatShopPosteUpdateColor,
  formatShopPosteUpdateName,
  formatShopPosteUpdatePauseTime,
} from './events/shop/poste/shop_poste_update';
import { formatShopBasicRulesUpdated } from './events/shop/shop_basic_rules_updated';
import { formatShopAnnualizationTrackerEnabled } from './events/shop/shop_annualization_tracker_enabled';
import { formatShopAnnualizationTrackerDisabled } from './events/shop/shop_annualization_tracker_disabled';
import { formatShopMealSettingsUpdated } from './events/shop/shop_meal_settings_updated';
import { formatShopMealSettingsDisabled } from './events/shop/shop_meal_settings_disabled';
import { formatShopMealSettingsEnabled } from './events/shop/shop_meal_settings_enabled';
import { formatShopPaidBreakEnabled } from './events/shop/shop_paid_break_enabled';
import { formatShopPaidBreakDisabled } from './events/shop/shop_paid_break_disabled';
import { formatShopBankHolidaysTrackerEnabled } from './events/shop/shop_bank_holidays_tracker_enabled';
import { formatShopBankHolidaysTrackerDisabled } from './events/shop/shop_bank_holidays_tracker_disabled';

const mapShopSubtype = {
  [EVENT_SUBTYPE_ACTION.SHOP_HOURS_CHANGED]: formatShopHoursChanged,
  [EVENT_SUBTYPE_ACTION.SHOP_HOUR_TRACKER_ENABLE]: formatShopHourTrackerEnable,
  [EVENT_SUBTYPE_ACTION.SHOP_HOUR_TRACKER_DISABLE]: formatShopHourTrackerDisable,
  [EVENT_SUBTYPE_ACTION.SHOP_PTO_TRACKER_ENABLE]: formatShopPTOTrackerEnable,
  [EVENT_SUBTYPE_ACTION.SHOP_PTO_TRACKER_DISABLE]: formatShopPTOTrackerDisable,

  [EVENT_SUBTYPE_ACTION.SHOP_TEAM_CREATE]: formatShopTeamCreate,
  [EVENT_SUBTYPE_ACTION.SHOP_TEAM_DELETE]: formatShopTeamDelete,
  [EVENT_SUBTYPE_ACTION.SHOP_TEAM_UPDATE_NAME]: formatShopTeamUpdateName,
  [EVENT_SUBTYPE_ACTION.SHOP_TEAM_UPDATE_EMPLOYEES]: formatShopTeamUpdateEmployees,

  [EVENT_SUBTYPE_ACTION.SHOP_POSTE_CREATE]: formatShopPosteCreate,
  [EVENT_SUBTYPE_ACTION.SHOP_POSTE_DELETE]: formatShopPosteDelete,
  [EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_COLOR]: formatShopPosteUpdateColor,
  [EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_NAME]: formatShopPosteUpdateName,
  [EVENT_SUBTYPE_ACTION.SHOP_POSTE_UPDATE_PAUSE_TIME]: formatShopPosteUpdatePauseTime,
  [EVENT_SUBTYPE_ACTION.SHOP_ANNUALIZATION_TRACKER_ENABLED]: formatShopAnnualizationTrackerEnabled,
  [EVENT_SUBTYPE_ACTION.SHOP_ANNUALIZATION_TRACKER_DISABLED]:
    formatShopAnnualizationTrackerDisabled,
  [EVENT_SUBTYPE_ACTION.SHOP_BANK_HOLIDAYS_TRACKER_ENABLED]: formatShopBankHolidaysTrackerEnabled,
  [EVENT_SUBTYPE_ACTION.SHOP_BANK_HOLIDAYS_TRACKER_DISABLED]:
    formatShopBankHolidaysTrackerDisabled,
  [EVENT_SUBTYPE_ACTION.SHOP_PAID_BREAK_ENABLED]: formatShopPaidBreakEnabled,
  [EVENT_SUBTYPE_ACTION.SHOP_PAID_BREAK_DISABLED]: formatShopPaidBreakDisabled,
  [EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_UPDATED]: formatShopMealSettingsUpdated,
  [EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_DISABLED]: formatShopMealSettingsDisabled,
  [EVENT_SUBTYPE_ACTION.SHOP_MEAL_SETTINGS_ENABLED]: formatShopMealSettingsEnabled,
  [EVENT_SUBTYPE_ACTION.SHOP_BASIC_RULES_UPDATED]: formatShopBasicRulesUpdated,
};

const mapReportSubtype = {
  [EVENT_SUBTYPE_ACTION.REPORT_BULK_HOUR_TRACKER_RESET]: formatReportBulkHourTrackerReset,
  [EVENT_SUBTYPE_ACTION.REPORT_BULK_HOUR_TRACKER_UPDATE]: formatReportBulkHourTrackerUpdate,
};

const mapCompanySubtype = {
  [EVENT_SUBTYPE_ACTION.COMPANY_LICENSE_UPDATE]: formatCompanyLicenseUpdate,
};

const mapEmployeeSubtype = {
  [EVENT_SUBTYPE_ACTION.EMPLOYEE_HOUR_TRACKER_UPDATE]: formatEmployeeHourTrackerUpdate,
  [EVENT_SUBTYPE_ACTION.EMPLOYEE_PTO_TRACKER_UPDATE]: formatEmployeePTOTrackerUpdate,
  [EVENT_SUBTYPE_ACTION.EMPLOYEE_LICENSE_UPDATE]: formatEmployeeLicenseUpdate,
  [EVENT_SUBTYPE_ACTION.EMPLOYEE_DOCUMENT_UPLOADED]: formatEmployeeDocumentUploaded,
  [EVENT_SUBTYPE_ACTION.EMPLOYEE_DOCUMENT_DELETED]: formatEmployeeDocumentDeleted,
};

const mapPlanningSubtype = {
  // WEEK PLANNING SHIFT
  [EVENT_SUBTYPE_ACTION.CREATE_WEEK_PLANNING_SHIFT]: createWeekPlanningShift,
  [EVENT_SUBTYPE_ACTION.UPDATE_WEEK_PLANNING_SHIFT]: updateWeekPlanningShift,
  [EVENT_SUBTYPE_ACTION.DELETE_WEEK_PLANNING_SHIFT]: deleteWeekPlanningShift,
  [EVENT_SUBTYPE_ACTION.DELETE_BULK_WEEK_PLANNING_SHIFT]: deleteBulkWeekPlanningShift,
  // DAY PLANNING SHIFT
  [EVENT_SUBTYPE_ACTION.CREATE_DAY_PLANNING_SHIFT]: createDayPlanningShift,
  [EVENT_SUBTYPE_ACTION.UPDATE_DAY_PLANNING_SHIFT]: updateDayPlanningShift,
  [EVENT_SUBTYPE_ACTION.DELETE_DAY_PLANNING_SHIFT]: deleteDayPlanningShift,
  [EVENT_SUBTYPE_ACTION.DELETE_BULK_DAY_PLANNING_SHIFT]: deleteBulkDayPlanningShift,
  // // WEEK PLANNING ABSENCE
  [EVENT_SUBTYPE_ACTION.CREATE_WEEK_PLANNING_ABSENCE]: createWeekPlanningAbsence,
  [EVENT_SUBTYPE_ACTION.UPDATE_WEEK_PLANNING_ABSENCE]: updateWeekPlanningAbsence,
  [EVENT_SUBTYPE_ACTION.DELETE_WEEK_PLANNING_ABSENCE]: deleteWeekPlanningAbsence,
  // // DAY PLANNING ABSENCE
  [EVENT_SUBTYPE_ACTION.CREATE_DAY_PLANNING_ABSENCE]: createDayPlanningAbsence,
  [EVENT_SUBTYPE_ACTION.UPDATE_DAY_PLANNING_ABSENCE]: updateDayPlanningAbsence,
  [EVENT_SUBTYPE_ACTION.DELETE_DAY_PLANNING_ABSENCE]: deleteDayPlanningAbsence,
  // SWAP SHIFTS BETWEEN 2 USERS
  [EVENT_SUBTYPE_ACTION.UPDATE_SWAP_USER_SHIFTS]: updateSwapPlanningUserShifts,
  // OTHERS PLANNING
  [EVENT_SUBTYPE_ACTION.PLANNING_PUBLISH]: planningPublish,
  [EVENT_SUBTYPE_ACTION.PLANNING_SETTING]: planningSetting,
  [EVENT_SUBTYPE_ACTION.PLANNING_APPLY_TEMPLATE]: planningApplyTemplate,
  [EVENT_SUBTYPE_ACTION.PLANNING_SORT_EMPLOYEES]: planningSortEmployees,
};

export const MAP_CREATE_SUBTYPES = {
  // PLANNING SUBTYPE
  ...mapPlanningSubtype,
  // SHOP SUBTYPE
  ...mapShopSubtype,
  // REPORT SUBTYPE
  ...mapReportSubtype,
  // COMPANY SUBTYPE
  ...mapCompanySubtype,
  // EMPLOYEE SUBTYPE
  ...mapEmployeeSubtype,
};
