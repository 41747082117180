<template>
  <SkModal
    id="staff-register-modal"
    ref="staffRegisterModal"
    :modal-title="$t('employees.toolbar.register.modal.title')"
    size="medium"
    @hidden="resetData"
  >
    <template #body>
      <div class="staff-register-modal__body">
        <CircledExclamationMarkIcon
          class="staff-register-modal__icon"
          fill=""
          height="24"
          width="24"
        />
        <div class="staff-register-modal__disclaimer">
          {{ $t('employees.toolbar.register.modal.disclaimer') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="staff-register-modal__footer">
        <div class="got-it">
          <SkCheckBox
            v-model="gotIt"
            :label="$t('employees.toolbar.register.modal.got_it')"
          />
        </div>
        <div class="actions">
          <SkOroraButton
            variant="secondary"
            @click="cancel"
          >
            {{ $t('employees.toolbar.register.modal.cancel') }}
          </SkOroraButton>
          <SkOroraButton
            :loading="downloadOngoing"
            @click="download"
          >
            {{ $t('employees.toolbar.register.modal.download') }}
          </SkOroraButton>
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'StaffRegisterModal',
  data() {
    return {
      gotIt: true,
      downloadOngoing: false,
    };
  },
  methods: {
    ...mapActions('currentUser', ['updateCurrentUser']),
    resetData() {
      this.gotIt = true;
      this.downloadOngoing = false;
    },
    cancel() {
      this.$refs.staffRegisterModal.handleFooterCancel();
    },
    download() {
      this.downloadOngoing = true;
      if (this.gotIt) {
        this.updateCurrentUser({
          current_user: { export_warning_enabled: false },
        });
      }
      this.$emit('download');
    },
    toggleGotIt() {
      this.gotIt = !this.gotIt;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-register-modal__body {
  padding: 25px 25px 0;
  display: flex;

  .staff-register-modal__icon {
    margin: 10px 20px 0 0;
    fill: $sk-black;
  }

  .staff-register-modal__disclaimer {
    max-width: 90%;
  }
}

.staff-register-modal__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
