<template>
  <div class="poste_row__wrapper">
    <div class="poste_row__left">
      <DraggableIcon
        class="poste_row__drag_icon"
        fill="#727272"
        height="10"
        width="12"
      />
      {{ poste.attributes.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PosteRow',
  props: {
    poste: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.poste_row__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  background-color: $sk-grey-10;
  border-radius: 3px;
  cursor: grab;
}

.poste_row__drag_icon {
  margin-right: 13px;
}

.poste_row__left {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
</style>
