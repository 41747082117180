// Depending on zoom value (25%, 50%...), display icons (note and meal)
// only from a specific duration (60 min, 75 min...)
export const MAPPING_ZOOM_TO_DISPLAY = {
  25: 60,
  50: 60,
  75: 60,
  100: 75,
  125: 75,
  150: 90,
  175: 90,
};

export const planningDayZoom = {
  25: {
    planningRow: {
      height: '32px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '7px',
        marginTop: '1px',
      },
      poste: {
        fontSize: '8px',
      },
      previsionalValidated: {
        lineHeight: '6px',
      },
    },
    headerDay: {
      name: {
        fontSize: '11px',
      },
    },
  },
  50: {
    planningRow: {
      height: '41px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '7px',
        marginTop: '2px',
      },
      poste: {
        fontSize: '8px',
      },
      previsionalValidated: {
        lineHeight: '8px',
      },
    },
    headerDay: {
      name: {
        fontSize: '12px',
      },
    },
  },
  75: {
    planningRow: {
      height: '43px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '9px',
        marginTop: '1px',
      },
      poste: {
        fontSize: '8px',
      },
      previsionalValidated: {
        lineHeight: '9px',
      },
    },
    headerDay: {
      name: {
        fontSize: '14px',
      },
    },
  },
  100: {
    planningRow: {
      height: '47px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '10px',
        marginTop: '1px',
      },
      poste: {
        fontSize: '10px',
      },
      previsionalValidated: {
        lineHeight: '10px',
      },
    },
    headerDay: {
      name: {
        fontSize: '16px',
      },
    },
  },
  125: {
    planningRow: {
      height: '53px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '10px',
        marginTop: '1px',
      },
      poste: {
        fontSize: '10px',
      },
      previsionalValidated: {
        lineHeight: '12px',
      },
    },
    headerDay: {
      name: {
        fontSize: '17px',
      },
    },
  },
  150: {
    planningRow: {
      height: '54px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '12px',
        marginTop: '1px',
      },
      poste: {
        fontSize: '10px',
      },
      previsionalValidated: {
        lineHeight: '13px',
      },
    },
    headerDay: {
      name: {
        fontSize: '22px',
      },
    },
  },
  175: {
    planningRow: {
      height: '67px',
      minHeight: null,
    },
    shift: {
      duration: {
        fontSize: '12px',
        marginTop: '2px',
      },
      poste: {
        fontSize: '13px',
      },
      previsionalValidated: {
        lineHeight: '13px',
      },
    },
    headerDay: {
      name: {
        fontSize: '24px',
      },
    },
  },
};
