<template>
  <div
    :class="wrapperClass"
    :style="cssVars"
    @mouseenter="onMouseEvent('mouse-enter')"
    @mouseleave="onMouseEvent('mouse-leave')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TooltipWrapper',
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: 'black',
    },
    textColor: {
      type: String,
      required: false,
      default: 'white',
    },
    isUpsell: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        '--background': this.backgroundColor,
        '--color': this.textColor,
      };
    },
    wrapperClass() {
      return this.isUpsell ? { upsell_wrapper: true } : { generic_wrapper: true };
    },
  },
  methods: {
    onMouseEvent(event) {
      this.$emit(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell_wrapper {
  text-align: start;
  width: 320px;
  padding: 15px 20px;
  background: var(--background);
  font-size: $fs-text-m;
  color: var(--color);
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
  z-index: 250;
}

.generic_wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 280px;
  height: 110px;
  padding: 15px 20px;
  background: var(--background);
  color: var(--color);
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .16);
  z-index: 200;
}
</style>
