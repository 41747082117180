<template>
  <div class="absence__modulation__tables__wrapper">
    <ModulationAbsenceSection
      :absences="modulationShopAbsences.offCounterAbsences"
      :show-drop-zone="showDropZone"
      :title="$t('shop_settings.tabs.absences.in_modulation.off_counter_leaves.title')"
      first-section
      absence-type="absencesOffCounter"
      @drag-start="onDragStart($event)"
      @drag-end="onDragEnd"
    />
    <ModulationAbsenceSection
      :absences="modulationShopAbsences.inCounterAbsences"
      :show-drop-zone="showDropZone"
      :title="$t('shop_settings.tabs.absences.in_modulation.in_counter_leaves.title')"
      second-section
      absence-type="absencesInCounter"
      @drag-start="onDragStart($event)"
      @drag-end="onDragEnd"
    />
    <ModulationAbsenceSection
      :absences="modulationShopAbsences.neutralAbsences"
      :title="$t('shop_settings.tabs.absences.in_modulation.neutral_leaves.title')"
      last-section
      absence-type="neutralAbsences"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ModulationAbsenceSection from './Section';

export default {
  components: { ModulationAbsenceSection },
  data() {
    return {
      showDropZone: null,
    };
  },
  computed: {
    ...mapState('absences', ['modulationShopAbsences']),
  },
  methods: {
    onDragStart(targetAbsencesList) {
      this.showDropZone = targetAbsencesList;
    },
    onDragEnd() {
      this.showDropZone = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.absence__modulation__tables__wrapper {
  margin: 30px 0 110px;
  background: white;
  align-self: flex-start;
}
</style>
