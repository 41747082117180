<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="#2b66fe"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="20"
      fill="#eef4ff"
    />
    <path
      d="M22.5144 24.4898C21.9864 24.4898 21.4663 24.288 21.0685
      23.8902L20.094 22.9157C19.8026 22.6243 19.8026 22.152 20.094 21.8606C20.3854
      21.5692 20.8577 21.5692 21.1491 21.8606L22.1236 22.8351C22.3424
      23.0539 22.7203 23.0539 22.9381 22.8351C23.1956 22.5726 23.1956 22.2027
      22.9729 21.975L20.1338 19.1369C19.8424 18.8455 19.8424 18.3732
      20.1338 18.0818C20.4252 17.7904 20.8975 17.7904 21.1889 18.0818L24.0329
      20.9258C24.8255 21.7343 24.8255 23.041 24.0379 23.8445C23.6063
      24.277 23.0564 24.4898 22.5144 24.4898Z"
      fill="#2b66fe"
    />

    <path
      d="M11.532 18.3421C11.2396 18.3421 10.9612 18.1691 10.8428 17.8817C10.6847
      17.5008 10.8657 17.0643 11.2466 16.9071L15.8289 15.0078C16.8173
      14.6061 17.94 14.8358 18.6938 15.5895L19.2377 16.1335C19.5291 16.4249 19.5291
      16.8972 19.2377 17.1886C18.9464 17.4799 18.474 17.4799 18.1826
      17.1886L17.6387 16.6446C17.3145 16.3204 16.8163 16.216 16.3947 16.3881L11.8174
      18.2844C11.7239 18.3242 11.6274 18.3421 11.532 18.3421Z"
      fill="#2b66fe"
    />

    <path
      d="M19.2524 19.9134C18.7314 19.9134 18.2163 19.7105 17.8294 19.3237C17.373
      18.8662 17.1622 18.2139 17.2656 17.5765C17.369
      16.9391 17.7747 16.3871 18.3525 16.0978L21.639 14.452C22.3242 14.1099 23.1217
      14.0821 23.8288 14.3754L29.2175 16.5791C29.5994 16.7352
      29.7813 17.1708 29.6262 17.5516C29.4701 17.9325 29.0365 18.1154 28.6537
      17.9603L23.2599 15.7547C22.9487 15.6254 22.6036 15.6374
      22.3073 15.7855L19.0207 17.4313C18.8686 17.5079 18.7662 17.6471 18.7383
      17.8151C18.7105 17.9832 18.7642 18.1473 18.8845 18.2686C19.0446
      18.4287 19.2862 18.4655 19.4891 18.366L22.4107 16.8943C22.7777 16.7103
      23.2271 16.8575 23.4121 17.2254C23.5971 17.5934 23.4499 18.0419
      23.081 18.2268L20.1603 19.6986C19.871 19.8428 19.5607 19.9134 19.2524 19.9134Z"
      fill="#2b66fe"
    />

    <path
      d="M20.6582 26.3629C20.1063 26.3629 19.5872 26.1481 19.1964
      25.7573L17.9832 24.5441C17.6918 24.2537 17.6918 23.7804
      17.9832 23.489C18.2745 23.1976 18.7469 23.1976 19.0383
      23.489L20.2514 24.7022C20.4692 24.92 20.8491 24.919 21.0649
      24.7022C21.1743 24.5938 21.2339 24.4486 21.2339 24.2955C21.2339
      24.1413 21.1743 23.9971 21.0659 23.8888L20.0913 22.9142C19.8
      22.6229 19.8 22.1505 20.0913 21.8591C20.3827 21.5678 20.8551
      21.5678 21.1464 21.8591L22.121 22.8337C22.5118 23.2235 22.7266 23.7426 22.7266
      24.2955C22.7266 24.8474 22.5118 25.3665 22.121 25.7573C21.7301
      26.1481 21.2111 26.3629 20.6582 26.3629Z"
      fill="#2b66fe"
    />

    <path
      d="M18.5351 28C18.0151 28 17.498 27.8071 17.1002
      27.4203L14.9164 25.4384C14.1239 24.7194 13.0986 24.3237
      12.0296 24.3237H11.5324C11.1207 24.3237 10.7866 23.9895
      10.7866 23.5778C10.7866 23.1662 11.1207 22.832 11.5324
      22.832H12.0296C13.4706 22.832 14.8518 23.365 15.9188
      24.3336L18.1205 26.3324C18.3641 26.5671 18.7261 26.5661 18.9478
      26.3433C19.0701 26.221 19.1298 26.0768 19.1298 25.9237C19.1298
      25.7705 19.0701 25.6254 18.9617 25.517L17.9872 24.5424C17.6958
      24.2511 17.6958 23.7787 17.9872 23.4874C18.2786 23.196 18.7509
      23.196 19.0423 23.4874L20.0168 24.4619C20.8233 25.2684 20.8233
      26.579 20.0168 27.3845C19.6061 27.7962 19.0701 28 18.5351 28Z"
      fill="#2b66fe"
    />

    <path
      d="M26.0776 24.6494C25.7951 24.6494 25.5127 24.6037 25.2393
      24.5092L23.4612 23.9006C23.0714 23.7674 22.8636 23.3428
      22.9978 22.953C23.1311 22.5641 23.5537 22.3533 23.9455 22.4896L25.7226
      23.0981C26.1054 23.2274 26.535 23.1439 26.8413 22.8774L28.3956
      21.2595C28.6819 20.9621 29.1553 20.9552 29.4506 21.2396C29.747
      21.525 29.7559 21.9973 29.4705 22.2947L27.8695 23.9563C27.3494
      24.4157 26.714 24.6494 26.0776 24.6494Z"
      fill="#2b66fe"
    />

    <path
      d="M31.4208 25.1629H28.9348C28.5231 25.1629 28.189 24.8288
      28.189 24.4171V15.9646C28.189 15.5529 28.5231 15.2188 28.9348
      15.2188H31.4208C31.8325 15.2188 32.1666 15.5529 32.1666
      15.9646V24.4171C32.1666 24.8288 31.8325 25.1629 31.4208
      25.1629ZM29.6806 23.6713H30.675V16.7104H29.6806V23.6713Z"
      fill="#2b66fe"
    />

    <path
      d="M11.5322 25.1629H9.04611C8.63442 25.1629 8.30029
      24.8288 8.30029 24.4171V15.9646C8.30029 15.5529 8.63442 15.2188 9.04611
      15.2188H11.5322C11.9438 15.2188 12.278 15.5529
      12.278 15.9646V24.4171C12.278 24.8288 11.9438 25.1629 11.5322 25.1629ZM9.79192
      23.6713H10.7863V16.7104H9.79192V23.6713Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'ContractIcon',
  props: {
    width: {
      type: String,
      default: '14',
    },
    height: {
      type: String,
      default: '16',
    },
    fill: {
      type: String,
      default: '#2b66fe',
    },
  },
};
</script>
