<template>
  <div class="absence-section__section__wrapper">
    <div class="absence-section__section__title">
      {{ title }}
    </div>
    <SkCollapse
      v-for="absence in absences"
      :key="absence.id"
      close-on-content-click
    >
      <template slot="labelLeft">
        {{ getI18nAbsence(absence).name }}
      </template>
      <template slot="content">
        <div class="absence-section__section__content">
          {{ getI18nAbsence(absence).explanation }}
          <div class="absence-section__section__footer">
            {{ getI18nAbsence(absence).example }}
          </div>
        </div>
      </template>
    </SkCollapse>
  </div>
</template>

<script>
import { SkCollapse } from '@skelloapp/skello-ui';
import { getI18nAbsenceKey } from '@app-js/shared/utils/absences_helper';

export default {
  name: 'AbsenceSection',
  components: { SkCollapse },
  props: {
    title: {
      type: String,
      required: true,
    },
    absences: {
      type: Array,
      required: true,
    },
    currentShop: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getI18nAbsence(absence) {
      const { absenceKey } = absence.attributes;
      const { absencesCountry } = this.currentShop.attributes;
      const i18nKey = getI18nAbsenceKey(
        absencesCountry,
        absenceKey,
      );

      return this.$t(i18nKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.absence-section__section__title {
  font-weight: bold;
  margin-bottom: 1em;
}

.absence-section__section__wrapper {
  //required by design product
  padding-bottom: 24px;

  ::v-deep .sk-collapse__label:hover {
    background: $sk-grey-5;
    transition: all .2s ease-out;
  }
}

.absence-section__section__content {
  cursor: pointer;
  margin: 16px 4px 16px 16px;
  text-align: justify;
  padding-left: 16px;
  border-left: 3px solid $sk-grey-10;
  user-select: none;
}

.absence-section__section__footer {
  color: $sk-grey;
  padding-top: 18px;
}
</style>
