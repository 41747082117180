<template>
  <!-- eslint-disable max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.777778 0H13.2222C13.4285 0 13.6263 0.0819442 13.7722 0.227806C13.9181 0.373667 14 0.571498 14 0.777778V14.7778C14 14.9841 13.9181 15.1819 13.7722 15.3278C13.6263 15.4736 13.4285 15.5556 13.2222 15.5556H0.777778C0.571498 15.5556 0.373667 15.4736 0.227806 15.3278C0.0819442 15.1819 0 14.9841 0 14.7778V0.777778C0 0.571498 0.0819442 0.373667 0.227806 0.227806C0.373667 0.0819442 0.571498 0 0.777778 0ZM1.55556 1.55556V14H12.4444V1.55556H1.55556ZM3.11111 3.11111H10.8889V6.22222H3.11111V3.11111ZM3.11111 7.77778H4.66667V9.33333H3.11111V7.77778ZM3.11111 10.8889H4.66667V12.4444H3.11111V10.8889ZM6.22222 7.77778H7.77778V9.33333H6.22222V7.77778ZM6.22222 10.8889H7.77778V12.4444H6.22222V10.8889ZM9.33333 7.77778H10.8889V12.4444H9.33333V7.77778Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalculatorIcon',
  props: {
    width: {
      type: String,
      default: '14',
    },
    height: {
      type: String,
      default: '16',
    },
    fill: {
      type: String,
      default: '#727272',
    },
  },
};
</script>
