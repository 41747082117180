<template>
  <!-- eslint-disable  max-len -->
  <tr class="shop-postes__table__row">
    <td class="shop-postes__table__cell-color">
      <ColorPicker
        v-model="shopPosteColor"
        :disabled="disabled"
        @input="updateShopPosteColor"
      />
    </td>
    <td class="shop-postes__table__cell-poste_name">
      <SkInput
        v-model="shopPosteName"
        :disabled="disabled"
        :label="$t('shop_settings.tabs.shop_postes.table.fields.name.label')"
        :errored="erroredNameUpdate"
        :error-message="$t('shop_settings.tabs.shop_postes.table.fields.name.error_message')"
        @blur="updateShopPosteName"
      />
    </td>
    <td
      v-if="pauseTimeInPercentage"
      class="shop-postes__table__cell-percentage"
    >
      <SkSwitch
        v-model="percentagePauseTime"
        :disabled="disabled"
      />
    </td>
    <td class="shop-postes__table__cell-pause-time">
      <SkInputGroup
        :errored="erroredPauseTimeUpdate || erroredPercentagePauseTimeUpdate"
        :error-message="errorPauseTimeMessage"
      >
        <SkInput
          v-model="pauseTime"
          :disabled="disabled"
          type="number"
          step="1"
          @blur="updateShopPostePauseTime"
        />
        <template #append>
          <span v-if="percentagePauseTime">
            %
          </span>
          <span v-else>
            {{ $t('dates.minutes') }}
          </span>
        </template>
      </SkInputGroup>
    </td>
    <td class="shop-postes__table__cell-action-button">
      <SkCircleButton
        v-track="'poste_delete'"
        :disabled="disabled"
        icon="TrashCanV2Icon"
        @click="deletePoste"
      />
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

import ColorPicker from './ColorPicker';

export default {
  name: 'PosteRow',
  components: { ColorPicker },
  props: {
    shopPoste: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localShopPoste: {
        name: this.shopPoste.attributes.name,
        color: null,
        pauseTime: this.shopPoste.attributes.pauseTime,
        percentagePauseTime: false,
      },
    };
  },
  computed: {
    ...mapState('convention', ['convention']),
    ...mapState('config', ['config']),
    pauseTimeInPercentage() {
      return this.config.convention_percentage_pause_time.includes(
        this.convention.attributes.name,
      );
    },
    pauseTimeEdited() {
      return Number(this.localShopPoste.pauseTime) !== Number(this.shopPoste.attributes.pauseTime);
    },
    pauseTime: {
      get() {
        // pauseTime is saved in seconds in DB
        return this.localShopPoste.pauseTime / 60;
      },
      set(newValue) {
        // need to save pauseTime in seconds in DB
        this.localShopPoste.pauseTime = newValue * 60;
      },
    },
    erroredNameUpdate() {
      return this.localShopPoste.name === '';
    },
    erroredPauseTimeUpdate() {
      return this.localShopPoste.pauseTime === '' || Number(this.localShopPoste.pauseTime) < 0;
    },
    erroredPercentagePauseTimeUpdate() {
      if (!this.shopPoste.attributes.percentagePauseTime) return false;

      // even if it is in percentage, the value is converted in "seconds" in db
      // but also locally in front, where all the values are converted in "seconds"
      // TODO: better handle value for pause in percentage or not with migration in db
      return (
        Number(this.localShopPoste.pauseTime) < 0 ||
        Number(this.localShopPoste.pauseTime) > 100 * 60
      );
    },
    erroredForm() {
      return (
        this.erroredPauseTimeUpdate ||
        this.erroredNameUpdate ||
        this.erroredPercentagePauseTimeUpdate
      );
    },
    errorPauseTimeMessage() {
      if (this.erroredPercentagePauseTimeUpdate) {
        return (
          this.$t('shop_settings.tabs.shop_postes.table.fields.percentage_pause_time.error_message')
        );
      }
      if (Number(this.localShopPoste.pauseTime) < 0) {
        return this.$t('shop_settings.tabs.shop_postes.table.fields.pause_time.error_message_2');
      }

      return this.$t('shop_settings.tabs.shop_postes.table.fields.pause_time.error_message');
    },
    shopPosteColor: {
      get() {
        return this.shopPoste.attributes.color;
      },
      set(value) {
        this.localShopPoste.color = value;
      },
    },
    shopPosteName: {
      get() {
        return this.shopPoste.attributes.name;
      },
      set(value) {
        this.localShopPoste.name = value;
      },
    },
    percentagePauseTime: {
      get() {
        return this.shopPoste.attributes.percentagePauseTime;
      },
      set(value) {
        this.localShopPoste.percentagePauseTime = value;

        if (this.erroredForm) return;

        this.$emit('update-shop-poste', {
          id: this.shopPoste.id,
          shopPoste: {
            percentage_pause_time: this.localShopPoste.percentagePauseTime,
          },
        });
      },
    },
  },
  methods: {
    updateShopPosteName() {
      if (this.erroredForm ||
        (this.localShopPoste.name === this.shopPoste.attributes.name)) return;

      this.$emit('update-shop-poste', {
        id: this.shopPoste.id,
        shopPoste: {
          name: this.localShopPoste.name,
        },
      });
    },
    updateShopPostePauseTime(value) {
      if (this.erroredForm || !this.pauseTimeEdited) return;

      this.$emit('update-shop-poste', {
        id: this.shopPoste.id,
        shopPoste: {
          name: this.localShopPoste.name,
          // converting minutes to seconds
          pause_time: this.pauseTime * 60,
        },
      });
    },
    updateShopPosteColor() {
      if (this.erroredForm) return;

      this.$emit('update-shop-poste', {
        id: this.shopPoste.id,
        shopPoste: {
          name: this.localShopPoste.name,
          color: this.localShopPoste.color,
        },
      });
    },
    deletePoste() {
      this.$emit('delete-shop-poste', this.shopPoste);
    },
  },
};
</script>

<style lang="scss">
tr.shop-postes__table__row {
  border-top: 1px solid $sk-grey-10;

  td {
    padding: 15px 0;
    vertical-align: top;
  }

  .shop-postes__table__cell-color {
    width: 60px;
    padding-right: 30px;
  }

  .shop-postes__table__cell-poste_name {
    padding-right: 30px;
    width: 274px;
  }

  .shop-postes__table__cell-percentage {
    padding-right: 30px;
    width: 150px;
  }

  .shop-postes__table__cell-pause-time {
    width: 120px;

    ::v-deep .sk-input-group {
      width: 120px;
    }
  }

  .shop-postes__table__cell-action-button {
    text-align: right;
  }
}
</style>
