<template>
  <div>
    <div>
      <div class="optimization__perfs__title">
        {{ $t('optimization.sidebar.performance_rules') }}
      </div>
      <div class="optimization__perfs__row__wrapper">
        <span class="optimization__perfs__row--text">
          {{ $t('optimization.sidebar.previsonal_ca') }}
        </span>
        <div class="optimization__perfs__row">
          <SkInput
            v-model="optiStructure.attributes.revenue"
            type="number"
          />
        </div>
      </div>
      <div class="optimization__perfs__row__wrapper">
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="optimization__perfs__row--text"
          v-html="$t('optimization.sidebar.my_ratio')"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div class="optimization__perfs__row">
          <SkInput
            v-model="optiStructure.attributes.max_salary_mass_ratio"
            type="number"
          />
        </div>
      </div>
      <div class="optimization__perfs__row__wrapper">
        <span class="optimization__perfs__row--text">
          {{ $t('optimization.sidebar.compare_hours') }}
        </span>
        <div class="optimization__perfs__row">
          <SkSwitch v-model="optiStructure.attributes.uses_available_hours" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OptimizationPerfs',
  computed: {
    ...mapState('planningsOptimisation', ['optiStructure']),
  },
};
</script>

<style lang="scss" scoped>
.optimization__perfs__row {
  width: 77px;

  &--text {
    max-width: 273px;
  }
}

.optimization__perfs__row__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.optimization__perfs__title {
  font-size: $fs-text-m;
  color: $sk-black;
  font-weight: $fw-semi-bold;
  margin: 0;
  text-align: start;
}
</style>
