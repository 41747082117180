<template>
  <SkModalSection border-bottom="none">
    <div class="holidays-settings-modal__paid-section__content">
      <!-- eslint-disable-next-line max-len -->
      {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.choose_paid_holidays') }}
      <SkTable :columns="holidaysColumns">
        <HolidayRow
          v-for="settings in holidaysSettings"
          :key="settings.id"
          :holiday-settings="settings"
          paid-days-step
          @update-holiday-settings="updateHolidaySettings"
        />
      </SkTable>
      <div>
        <div class="holidays-settings-modal__paid-section__footer-header">
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.total') }}
        </div>
        <div class="holidays-settings-modal__paid-section__footer-tag">
          <!-- eslint-disable max-len -->
          <HolidayTag
            variant="blue"
            :number="nbHolidaysPaid"
            :text="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.paid')"
            class="holidays-settings-modal__paid-section__footer-tag__text"
          />
          <HolidayTag
            variant="orange"
            :number="nbHolidaysRecovered"
            :text="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.recover')"
            class="holidays-settings-modal__paid-section__footer-tag__text"
          />
          <!-- eslint-enable max-len -->
        </div>
      </div>
    </div>
  </SkModalSection>
</template>

<script>
import HolidayRow from '../components/HolidayRow';
import HolidayTag from '../components/HolidayTag';

export default {
  name: 'PaidDaysStep',
  components: { HolidayRow, HolidayTag },
  props: {
    holidaysSettings: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      holidaysColumns: [
        { title: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.guaranteed') },
        {
          title:
            this.$t(
              'shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.dates',
              { year: this.year },
            ),
        },
        { title: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.holidays') },
        { title: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.indemnisation') },
      ],
    };
  },
  computed: {
    holidaysGuaranteed() {
      return this.holidaysSettings.filter(holidaySetting => holidaySetting.attributes.guaranteed);
    },
    nbHolidaysPaid() {
      return this.holidaysSettings.filter(holidaySetting => holidaySetting.attributes.paid).length;
    },
    nbHolidaysRecovered() {
      return this.holidaysSettings.filter(holidaySetting => !holidaySetting.attributes.paid).length;
    },
    nbHolidaysGuaranteed() {
      return this.holidaysGuaranteed.length;
    },
    oneHolidayGuaranteed() {
      return this.nbHolidaysGuaranteed === 1;
    },
  },
  methods: {
    updateHolidaySettings({ id, attribute, value }) {
      this.$emit('update-holiday-settings', { id, attribute, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.holidays-settings-modal__paid-section__content {
  margin-top: 30px;

  &--table {
    margin-top: 10px;
  }
}

::v-deep th:first-child {
  .table-column__label {
    padding-left: 5px;
  }
}

.holidays-settings-modal__paid-section__footer-header {
  color: $sk-grey;
  font-size: $fs-text-s;
}

.holidays-settings-modal__paid-section__footer-tag {
  display: inline-block;
  height: 20px;
  margin-top: 5px;
}

.holidays-settings-modal__paid-section__footer-tag__text {
  text-transform: lowercase;
  margin-right: 5px;
}
</style>
