<template>
  <SkModalSectionV2 class="absence-select-row">
    <label for="absenceType">
      {{ $t('requests.leave_requests.modal.absence_type.label') }}
    </label>
    <SkSelectV2
      v-model="localValue"
      :disabled="isDisabled"
      :is-input-loading="isLoading"
      :label="$t('requests.leave_requests.modal.absence_type.hint')"
      :no-results-label="$t('requests.leave_requests.no_search_results')"
      :options="absenceOptions"
      append-to-body
      data-test="leave-request__select-absence__input"
      input-id="absenceType"
      name="absenceType"
      width="394px"
    />
  </SkModalSectionV2>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AbsenceSelectRow',
  props: {
    value: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    absences: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    absenceOptions() {
      const { mostUsedAbsences, otherAbsences } = this.absences
        .reduce((sortedAbs, { attributes: { absenceFlags, active, absenceKey, id, name } }) => {
          if (!(active && absenceFlags.includes('leave_request_absences'))) return sortedAbs;

          (['paid_leave', 'recovery', 'working_time_recovery'].includes(absenceKey) ?
            sortedAbs.mostUsedAbsences :
            sortedAbs.otherAbsences
          ).push({ id, text: name, key: absenceKey });

          return sortedAbs;
        }, { mostUsedAbsences: [], otherAbsences: [] });

      return mostUsedAbsences
        .sort((firstAbsence, secondAbsence) => firstAbsence.key.localeCompare(secondAbsence.key))
        .concat(otherAbsences);
    },
  },
  watch: {
    absenceOptions() {
      this.initializeLocalValue();
    },
  },
  mounted() {
    this.initializeLocalValue();
  },
  methods: {
    initializeLocalValue() {
      if (this.localValue === null && this.absenceOptions.length > 0) {
        this.localValue = this.absenceOptions[0].id;
      }
    },
    reset() {
      this.localValue = this.absenceOptions[0].id;
    },
    selectOption(absenceKey) {
      this.localValue = this.absenceOptions.find(({ key }) => key === absenceKey).id;
    },
  },
};

</script>
<style lang="scss" scoped>
.absence-select-row label {
  font-weight: $fw-semi-bold;
  margin-bottom: 0;
}
</style>
