import { WORK_SHIFT_KEY } from '@app-js/shared/constants/shift';

function createDtos(shifts, documents, shopId, userId) {
  return shifts.flatMap(shift => documents.map(document => ({
    fileName: document.file.name,
    mimeType: document.file.type,
    shopId,
    creatorId: userId,
    shiftId: shift.id,
  })));
}

export function prepareDocumentsCreateAndUpload(
  mainShifts,
  secondaryShifts,
  uploadDocumentsQueue,
  userId,
  shopId,
) {
  // for each shift, create a dto for each document
  // and create a list of the files to upload
  const mainDocuments = uploadDocumentsQueue
    .filter(file => file.workShiftKey === WORK_SHIFT_KEY.MAIN);
  const secondaryDocuments = uploadDocumentsQueue
    .filter(file => file.workShiftKey === WORK_SHIFT_KEY.SECONDARY);

  const mainDtos = createDtos(mainShifts, mainDocuments, shopId, userId);
  const secondaryDtos = createDtos(secondaryShifts, secondaryDocuments, shopId, userId);

  const mainFiles = mainDocuments.map(file => file.file);
  const secondaryFiles = secondaryDocuments.map(file => file.file);

  return {
    dtos: [...mainDtos, ...secondaryDtos],
    files: [...mainFiles, ...secondaryFiles],
  };
}
