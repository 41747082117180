<template>
  <div class="shop-settings__user-row">
    <SkMedallion
      :ext="userInitials"
      background-color="#2b66fe"
      color="white"
      class="shop-settings__user-row__medallion"
    />
    <span class="shop-settings__user-row__name">
      {{ userFullName }}
    </span>
    <div class="shop-settings__user-row__inputs">
      <SkInputGroup
        :errored="enableErrorProc && user.errorPreviousBalance"
        class="shop-settings__user-row__input"
      >
        <SkInput
          v-model="user.previousYearBalance"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.placeholder')"
          type="number"
          :step="1"
          @keyup="$emit('keyup', $event, user)"
        />
        <template #append>
          {{ unit }}
        </template>
      </SkInputGroup>
      <SkInputGroup
        :errored="enableErrorProc && user.errorCurrentBalance"
        class="shop-settings__user-row__input"
      >
        <SkInput
          v-model="user.currentYearBalance"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.pto_periods_distinction.modal.balance.placeholder')"
          type="number"
          :step="1"
          @keyup="$emit('keyup', $event, user)"
        />
        <template #append>
          {{ unit }}
        </template>
      </SkInputGroup>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    enableErrorProc: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userInitials() {
      return this.user.attributes.firstName.trim()[0].toUpperCase() +
        this.user.attributes.lastName.trim()[0].toUpperCase();
    },
    userFullName() {
      const { firstName, lastName } = this.user.attributes;
      const separatorSpace = ' ';

      return firstName.charAt(0).toUpperCase() + firstName.slice(1) +
        separatorSpace +
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings__user-row {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  border-top: 1px solid $sk-grey-10;

  &:last-child {
    border-bottom: 1px solid $sk-grey-10;
  }

  &__medallion {
    margin-right: 14px;
  }

  &__name {
    font-size: $fs-text-m;
  }

  &__inputs {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    width: 224px;
    justify-content: space-between;
  }

  &__input {
    margin-right: 0;
    width: 92px;

    ::v-deep .sk-input-group__error-label {
      margin: 0;
    }

    ::v-deep .sk-input-group .sk-input-group__append {
      padding: 0 0 0 6px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
