<template>
  <div class="lauch-step">
    <lottie-player
      class="lauch-step__skello-annimation"
      background="transparent"
      autoplay="true"
      :src="lottieLogo"
    />
  </div>
</template>
<script>
import lottieLogoJson from '../lotties/lottie-logo-animation.json';
import '@lottiefiles/lottie-player';

export default {
  name: 'LaunchStep',
  computed: {
    lottieLogo() {
      return JSON.stringify(lottieLogoJson);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$emit('next', { skip: true });
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
  .lauch-step {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &__skello-annimation {
      width: 25%;
      display: block;
      margin: auto;
    }
  }
</style>
