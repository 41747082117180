<template>
  <div :class="headerWrapperClass">
    <div
      :class="headerDayNameClass"
      role="button"
      @click="handleClick"
    >
      <span class="header-day__name-weekday">{{ weekDay }}</span>
      <span
        :class="headerDayNameNumberClass"
        :style="zoomRange.headerDayName"
      >
        {{ weekDayNumber }}
      </span>
      <span>{{ month }}</span>
    </div>
    <div
      class="header-day__footer"
      :style="zoomRange.headerDayFooter"
    >
      <span class="header-day__footer-left">
        <span
          class="header-day__footer-note"
          :style="zoomRange.headerDayIcon"
          @click="showNoteModal"
        >
          <ClipboardIcon
            v-tooltip.bottom.offset="noteTooltip"
            :class="noteIconClass"
            fill=""
            height="100%"
            width="100%"
          />
        </span>
        <span
          v-if="day.holiday && day.holiday.name"
          v-tooltip.bottom.offset="day.holiday.name"
          :class="headerDayHolidayClass"
        >
          {{ $t('plannings.table.header.days.emoji.tada') }}
        </span>
      </span>
      <span
        class="header-day__footer-right"
        :style="zoomRange.headerDayIcon.height"
      >
        <BirthdayTag
          :day="day"
          :class="headerDayBirthdayClass"
        />
        <span
          :class="headerDayFooterClass"
          :style="zoomRange.headerDayIcon"
        >
          <span
            v-if="isDayValidationPending"
            class="footer-lock__spinner-wrapper"
          >
            <SkLoader size="small" />
          </span>
          <template v-else>
            <CloseLockIcon
              v-if="day.isLocked"
              v-track="'unlock_day_week'"
              v-tooltip.bottom.offset="closedLockIconTooltip"
              :class="lockIconClass"
              fill=""
              height="100%"
              width="100%"
              @click.native="handleValidateDayClick(false, $event)"
            />
            <OpenLockIcon
              v-else
              v-track="'lock_day_week'"
              v-tooltip.bottom.offset="openLockIconTooltip"
              height="100%"
              width="100%"
              fill=""
              :class="lockIconClass"
              @click.native="handleValidateDayClick(true)"
            />
          </template>
        </span>
      </span>
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageNoteModal :day="day" />
      <UnlockRequestModal
        :date="day.date"
        type="day"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { uniq } from '@skello-utils/collection';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

import skDate from '@skello-utils/dates';
import BirthdayTag from '@app-js/plannings/shared/components/BirthdayTag';
import ManageNoteModal from '@app-js/plannings/shared/components/modal/ManageNoteModal.vue';
import UnlockRequestModal from '@app-js/plannings/shared/components/modal/UnlockRequestModal.vue';

export default {
  name: 'HeaderDay',
  components: { ManageNoteModal, UnlockRequestModal, BirthdayTag },
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shakeLockIcon: false,
    };
  },
  computed: {
    ...mapState('planningsUsers', ['users']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsState', [
      'validateDayLoading',
      'unfinishedFeaturesVisible',
      'undoRedoLoading',
      'weeklyOptions',
    ]),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapState('planningsShifts', ['usersWithWeeklyBlockingAlert']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek']),
    ...mapGetters('planningsState', ['isOnlyOneDayUnlocked']),
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    headerDayNameClass() {
      return {
        'header-day__name': true,
        'header-day__name--today': this.dayDate.isToday(),
        'header-day__name--locked': this.day.isLocked,
      };
    },
    headerWrapperClass() {
      return {
        'header-day__wrapper': true,
        'header-day__wrapper--today': this.dayDate.isToday(),
        'header-day__wrapper--locked': this.day.isLocked,
      };
    },
    headerDayFooterClass() {
      return {
        'header-day__footer-lock': true,
        'header-day__footer-lock--disabled': this.isLockDisabled,
      };
    },
    headerDayNameNumberClass() {
      return {
        'header-day__name-number': true,
        'header-day__name-number--faded': this.isProgressiveLoadingEnabled,
      };
    },
    headerDayHolidayClass() {
      return {
        'header-day__footer-emoji': true,
        'header-day__footer-emoji--tada': true,
        'header-day__footer-emoji--faded': this.isProgressiveLoadingEnabled,
      };
    },
    headerDayBirthdayClass() {
      return {
        'header-day__footer-emoji': true,
        'header-day__footer-emoji--faded': this.isProgressiveLoadingEnabled,
      };
    },
    weekDay() {
      const day = this.dayDate.locale('en').format('dddd').toLowerCase();
      return this.$t(`plannings.table.header.short_day.${day}`);
    },
    weekDayNumber() {
      return this.dayDate.format('D');
    },
    month() {
      const month = this.dayDate.locale('en').format('MMMM').toLowerCase();
      return this.$t(`plannings.table.header.short_month.${month}`);
    },
    zoomRange() {
      const { headerDay, headerDayFooter } = zoomPlanningWeek(this.planningZoom);
      return {
        headerDayName: headerDay.name,
        headerDayIcon: headerDay.icon,
        headerDayFooter,
      };
    },
    dayDate() {
      return skDate(this.day.date);
    },
    isLockDisabled() {
      return (
        !this.canTmpLockPlanning ||
        this.day.permanentLocked ||
        this.undoRedoLoading
      );
    },
    isLockDefault() {
      return !this.day.isLocked &&
        !this.day.validated &&
        !this.day.intermediateLocked &&
        !this.day.permanentLocked;
    },
    lockIconClass() {
      return {
        'header-day__footer-lock--default': this.isLockDefault,
        'header-day__footer-lock--default--faded': this.isProgressiveLoadingEnabled && this.isLockDefault,
        'header-day__footer-lock--validated': this.day.validated,
        'header-day__footer-lock--validated--faded': this.isProgressiveLoadingEnabled && this.day.validated,
        'header-day__footer-lock--intermediate': this.day.intermediateLocked,
        'header-day__footer-lock--intermediate--faded': this.isProgressiveLoadingEnabled && this.day.intermediateLocked,
        'header-day__footer-lock--permanent': this.day.permanentLocked,
        'header-day__footer-lock--permanent--faded': this.isProgressiveLoadingEnabled && this.day.permanentLocked,
        'header-day__footer-lock--shakeit': this.shakeLockIcon,
        'header-day__footer-lock--disabled': this.isLockDisabled,
      };
    },
    isDayValidationPending() {
      return this.validateDayLoading[this.dayDate.dayIndex()];
    },
    canTmpLockPlanning() {
      return this.currentLicense.attributes.canTmplockPlanning;
    },
    canLockPlanning() {
      return this.currentLicense.attributes.canLockPlanning;
    },
    openLockIconTooltip() {
      if (this.canTmpLockPlanning) {
        return this.$t('plannings.table.header.actions.day_validation.validate.label');
      }
      return this.$t('plannings.table.header.actions.day_validation.validate.unauthorized');
    },
    closedLockIconTooltip() {
      if (this.day.permanentLocked) {
        if (this.canLockPlanning) {
          return this.$t('plannings.table.header.actions.day_validation.permanent_unlock.label');
        }
        return this.$t('plannings.table.header.actions.day_validation.permanent_unlock.unauthorized');
      }

      if (this.canTmpLockPlanning) {
        return this.$t('plannings.table.header.actions.day_validation.unvalidate.label');
      }
      return this.$t('plannings.table.header.actions.day_validation.unvalidate.unauthorized');
    },
    noteTooltip() {
      if (this.day.event?.attributes.note) {
        return this.day.event.attributes.note;
      }
      if (this.currentLicense.attributes.canCreateShifts) {
        return this.$t('plannings.table.add_note_modal.header.add_note_title');
      }
      return this.$t('plannings.table.add_note_modal.header.unauthorized');
    },
    noteIconClass() {
      // default is grey, blue colored when there's a note
      return {
        'header-day__footer-note-fill--empty': !this.day.event?.attributes.note,
        'header-day__footer-note-fill--validated': this.day.event?.attributes.note,
      };
    },
  },
  mounted() {
    // Listen to clicks on day cells of the column -> add shake effect on lock icon if day is locked
    this.listenOnRoot('click-on-locked-day', this.clickOnLockedHandler);
  },
  methods: {
    ...mapActions('planningsState', ['validatePeriod']),
    ...mapMutations('planningsShifts', ['setUsersWithWeeklyBlockingAlert']),
    handleClick() {
      if (this.checkPackOfferFlag('day_planning_enabled')) {
        this.$router.push({
          name: 'plannings_days',
          query: {
            date: this.day.date,
          },
        });
      }
    },
    validationSuccessMessage(validation) {
      if (validation) {
        return this.$t('plannings.table.header.actions.day_validation.validate.success');
      }
      return this.$t('plannings.table.header.actions.day_validation.unvalidate.success');
    },
    validationErrorMessage(validation) {
      if (validation) {
        return this.$t('plannings.table.header.actions.day_validation.validate.error');
      }
      return this.$t('plannings.table.header.actions.day_validation.unvalidate.error');
    },
    handleValidateDayClick(validation, event) {
      if (this.isLockDisabled) {
        return;
      }

      // Only system administrator can intermediate unvalidate
      // Other users can do "unlock request"
      if (
        this.day.intermediateLocked &&
        !this.isSystemAdmin &&
        !validation
      ) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, `unlock-request-modal-day-${this.day.date}`);
        return;
      }

      if (validation && this.isOnlyOneDayUnlocked) {
        this.setUsersWithWeeklyBlockingAlert(uniq(
          this.shiftsForCurrentWeek
            .filter(shift => (
              this.hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) &&
              !this.getUserById(shift.attributes.userId).attributes.onExtra
            ))
            .map(shift => shift.attributes.userId),
        ));

        if (this.usersWithWeeklyBlockingAlert.length > 0) {
          this.emitOnRoot(MODAL_SHOW_EVENT, null, 'blocking-alert-for-week-lock-modal');
          return;
        }
      }

      this.validatePeriod({
        currentDate: this.day.date,
        startDate: this.day.date,
        endDate: this.day.date,
        shopId: this.currentShop.id,
        validationLevel: this.day.intermediateLocked ? 'intermediate_locked_days' : 'validated_days',
        validationValue: validation,
        dayLock: !this.day.intermediateLocked,
      })
        .then(() => {
          this.$skToast({
            message: this.validationSuccessMessage(validation),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.validationErrorMessage(validation),
            variant: 'error',
          });
        });
    },
    hasShiftBlockingMinWeeklyWorkingTimeAlert(shift) {
      return shift.relationships.alerts &&
        shift.relationships.alerts.some(alert => (
          alert.attributes.blocking && alert.attributes.name === 'minimum_weekly_work_time'
        ));
    },
    getUserById(userId) {
      return this.users.find(user => parseInt(user.id, 10) === userId);
    },
    clickOnLockedHandler(event, date) {
      // Only relevant if day is locked
      if (!this.day.isLocked) return;

      // If clicked cell has different date -> it doesn't concern this header
      if (date !== this.day.date) return;

      if (event) {
        event.stopPropagation();
      }

      // no need to shake again if already shaking
      if (this.shakeLockIcon) return;

      // do shake animation (1s duration)
      this.shakeLockIcon = true;
      setTimeout(() => {
        this.shakeLockIcon = false;
      }, 1000);
    },
    showNoteModal(event) {
      if (!this.currentLicense.attributes.canCreateShifts) return;

      this.emitOnRoot(MODAL_SHOW_EVENT, event, `manage-note-modal-${this.day.date}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-day__wrapper {
  border-right: 1px solid $sk-grey-10;
  border-bottom: 1px solid $sk-grey-10;
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.header-day__wrapper:last-child {
  border-right: none;
}

.header-day__name {
  font-size: 1vw;
  line-height: 22px;
  color: $sk-grey;
  margin-top: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: baseline;
  flex: 1;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  &--today {
    color: $sk-blue;

    .header-day__name-number {
      font-weight: $fw-semi-bold;
      background: $sk-blue;
      color: $sk-white;

      &--faded {
        animation: colorInToday .3s ease-out;
      }
    }
  }
}

.header-day__name-number {
  font-size: 1.25vw;
  width: 2.43vw;
  height: 2.43vw;
  margin: 0 3px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
}

.header-day__footer {
  display: flex;
  align-items: center;
  height: 24px;

  &-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.header-day__footer-emoji {
  padding: 0 10px;
  font-size: 1.1em;

  &--tada {
    line-height: normal;
  }

  &--faded {
    animation: fadeIn 0.3s ease-out;
  }
}

.header-day__footer-note,
.header-day__footer-lock {
  display: flex;
  align-items: flex-end;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  padding: 2px;
  // opacity impact z-index, since we cannot
  // manualy change z-index since it impacts
  // total column, we apply a < 1 opacity on lock
  // https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
  opacity: .99;

  &--default {
    fill: $sk-grey;

    &--faded {
      animation: colorInGrey10ToGrey 0.3s ease-out;
    }
  }

  &--validated {
    fill: $sk-blue;

    &--faded {
      animation: colorInGrey10ToBlue 0.3s ease-out;
    }
  }

  &--intermediate {
    fill: $sk-success;

    &--faded {
      animation: colorInGrey10ToSuccess 0.3s ease-out;
    }
  }

  &--permanent {
    fill: $sk-error;

    &--faded {
      animation: colorInGrey10ToError 0.3s ease-out;
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes small_shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-2px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  }

  &--shakeit {
    animation-name: small_shake;
    animation-duration: 1s;
  }
}

.header-day__footer-note-fill {
  &--empty {
    fill: $sk-grey;
    animation: colorInGrey10ToGrey 0.3s ease-out;
  }

  &--validated {
    fill: $sk-blue;
    animation: colorInGrey10ToBlue 0.3s ease-out;
  }
}

.header-day__footer-note:hover,
.header-day__footer-lock:hover,
.header-day__name-number:hover {
  cursor: pointer;
}

.header-day__footer-lock:hover,
.header-day__footer-note:hover {
  box-shadow: inset 18px 0 $sk-grey-10, 0 0 0 7px $sk-grey-10;
}

.header-day__footer-lock--disabled:hover {
  box-shadow: none;
}

.header-day__name:not(.header-day__name--today) .header-day__name-number:hover {
  background: $sk-grey-10;
}

.header-day__wrapper--today {
  background-color: rgba($sk-blue-5, .4);
}

.header-day__wrapper--locked {
  background-color: rgba($sk-grey-10, .2);
}

.header-day__name--locked {
  opacity: .7;
}

.header-day__name-weekday {
  text-transform: capitalize;
}

.footer-lock__spinner-wrapper {
  line-height: 0;
  color: $sk-blue;
}

@media screen and (min-width: 1440px) {
  .header-day__name {
    font-size: 1.6em;
  }

  .header-day__name-number {
    font-size: 1.1em;
    width: 31px;
    height: 31px;
  }
}

@keyframes colorInToday {
  0% {
    color: $sk-grey;
    background-color: transparent;
  }
  100% {
    color: $sk-white;
    background-color: $sk-blue;
  }
}

@keyframes colorInGrey10ToBlue {
  0% {
    fill: $sk-grey-10;
  }
  100% {
    fill: $sk-blue;
  }
}

@keyframes colorInGrey10ToSuccess {
  0% {
    fill: $sk-grey-10;
  }
  100% {
    fill: $sk-success;
  }
}

@keyframes colorInGrey10ToGrey {
  0% {
    fill: $sk-grey-10;
  }
  100% {
    fill: $sk-grey;
  }
}

@keyframes colorInGrey10ToError {
  0% {
    fill: $sk-grey-10;
  }
  100% {
    fill: $sk-error;
  }
}
</style>
