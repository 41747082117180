<template>
  <SkModalSectionV2
    border-bottom="none"
    :padding-bottom="false"
  >
    <div class="parameters-section__title">
      <h3 class="sk-header--3">
        {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.title') }}
      </h3>
    </div>
    <div class="parameters-section__info-card">
      <SkInfoCard>
        <div class="parameters-section__info-card__title">
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.info_card.title') }}
        </div>
        <div>
          {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.info_card.content') }}
        </div>
      </SkInfoCard>
    </div>
    <div class="parameters-section__selection-row">
      <span class="parameters-section__selection-title">
        {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.selected_employees.label') }}
      </span>
      <div class="parameters-section__selection-input">
        <SkSelectV2
          v-model="localAnnualizedUserIds"
          :class="selectAnnualizedUsersClass"
          :empty-option-display="$t('shop_settings.tabs.rules.counter_rules.annualization.parameters_section.no_employees')"
          :label="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.selected_employees.label')"
          :no-results-label="$t('search_bar.no_result')"
          :options="annualizableUsersOptions"
          :group-options="annualizableUsersGroupOptions"
          :testing-options="{
            ['groupOption-0']: 'annualization__employees-dropdown-option-all',
            submit: 'annualization__employees-dropdown-submit-button',
          }"
          :search-placeholder="$t('labels.search')"
          append-to-body
          data-test="annualization__employees-select"
          multi
          searchable
          width="417px"
        >
          <template
            v-if="localAnnualizedUserIds.length > 0"
            #selected-option="{ value }"
          >
            {{ $tc('shop_settings.tabs.teams.table.fields.teammates.group_name', value.length) }}
          </template>
          <template #option="{ option }">
            <div tooltip.right-start="disabledSelectTooltip(option.id)">
              {{ option.text }}
            </div>
          </template>
        </SkSelectV2>
      </div>
    </div>
    <div class="parameters-section__selection-row">
      <span class="parameters-section__selection-title">
        {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.reference_period.label') }}
      </span>
      <div class="parameters-section__selection-input">
        <SkDatePicker
          v-model="localPeriodStartDate"
          :editable="false"
          :lang="$i18n.locale"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.reference_period.placeholder')"
          append-to-body
          class="parameters-section__selection-date-picker"
          data-test="annualization__period-datepicker"
          hide-footer
        >
          <template
            v-if="localPeriodStartDate"
            #input
          >
            <div class="parameters-section__datepicker-selected-option">
              {{ annualizationPeriodLabel }}
            </div>
          </template>
        </SkDatePicker>
      </div>
    </div>
    <div class="parameters-section__selection-row">
      <span class="parameters-section__selection-title">
        {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.initialisation_date.label') }}
      </span>
      <div class="parameters-section__selection-input">
        <SkDatePicker
          v-model="localInitializationDate"
          :editable="false"
          :lang="$i18n.locale"
          :disabled-date="disabledDate"
          :placeholder="$t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.initialisation_date.placeholder')"
          append-to-body
          data-test="annualization__initialization-date-datepicker"
          hide-footer
          class="parameters-section__selection-date-picker"
        >
          <template
            v-if="localInitializationDate"
            #input
          >
            <div class="parameters-section__datepicker-selected-option">
              {{ annualizationInitDateLabel }}
            </div>
          </template>
        </SkDatePicker>
        <div class="parameters-section__datepicker-hint">
          <p class="sk-subtitle--small">
            {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.parameters_section.initialisation_date.hint') }}
          </p>
        </div>
      </div>
    </div>
  </SkModalSectionV2>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'ParametersSection',
  data() {
    return {
      isAnyChanges: false,
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('shopEmployees', ['annualizableEmployees']),
    ...mapState('shopEmployees', ['annualizedUserIds']),
    ...mapState('shopAnnualizationConfig', ['initializationDate', 'periodStartDate']),
    annualizationInitDateLabel() {
      return skDate(this.initializationDate).format('DD MMMM YYYY');
    },
    annualizableUsersOptions() {
      return this.annualizableEmployees
        .map(employee => ({ id: employee.id, text: this.fullName(employee) }));
    },
    annualizationPeriodLabel() {
      const startDate = skDate.utc(this.periodStartDate);
      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');

      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`;
    },
    annualizableUsersGroupOptions() {
      return [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];
    },
    localInitializationDate: {
      get() {
        return this.initializationDate;
      },
      set(value) {
        if (value !== null) {
          this.isAnyChanges = true;
        }
        this.setInitializationDate(value);
      },
    },
    localPeriodStartDate: {
      get() {
        return this.periodStartDate;
      },
      set(value) {
        if (value !== null) {
          this.isAnyChanges = true;
        }
        this.setPeriodStartDate(value);
      },
    },
    localAnnualizedUserIds: {
      get() {
        return this.annualizedUserIds;
      },
      set(userIds) {
        if (userIds.length > 0) {
          this.isAnyChanges = true;
        }
        this.setAnnualizedUserIds(userIds);
      },
    },
    selectAnnualizedUsersClass() {
      return {
        'parameters-section__selection-input--empty': this.localAnnualizedUserIds.length === 0,
      };
    },
  },
  methods: {
    ...mapMutations('shopAnnualizationConfig', ['setInitializationDate', 'setPeriodStartDate']),
    ...mapMutations('shopEmployees', ['setAnnualizedUserIds']),
    resetData() {
      this.isAnyChanges = false;
    },
    disabledDate(date) {
      return skDate(date).isBefore(skDate(this.localPeriodStartDate));
    },
  },
};
</script>

<style lang="scss" scoped>
.parameters-section {
  &__title {
    margin-bottom: 20px;
  }

  &__info-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &__title {
      margin-bottom: 14px;
      color: $sk-black;
      font-weight: $fw-semi-bold;
      font-size: $fs-text-m;
    }
  }

  &__selection-row {
    display: flex;
    align-items: last baseline;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
      align-items: baseline;
    }
  }

  &__selection-title {
    flex-shrink: 0;
    width: 155px;
    padding-right: 8px;
    color: $sk-black;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
  }

  &__selection-input {
    flex-grow: 1;
  }

  &__selection-input--empty {
    // Design specification: placeholder must be centered when no options are selected
    ::v-deep .sk-select__selected-option--filled-in-with-label .sk-select__label {
      top: 12px;
      font-size: $fs-text-m;
    }
  }

  &__selection-date-picker {
    display: flex;
    align-items: center;
    height: 44px;
    width: 417px;
    border-radius: 4px;
    opacity: 1;
    background-color: $sk-grey-5;
  }

  &__datepicker-selected-option {
    padding-left: 10px;
  }

  &__datepicker-hint {
    margin-left: 10px;
  }
}
</style>
