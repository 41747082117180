<template>
  <div>
    <div class="rtt-counter__initialization-overlay">
      <div class="rtt-counter__initialization-overlay-icon">
        <component
          :is="icon"
          height="18"
          width="18"
        />
      </div>
      <div class="rtt-counter__initialization-overlay-title">
        <h2 class="sk-header--2">
          {{ title }}
        </h2>
        <p class="rtt-counter__initialization-overlay-description">
          {{ description }}
        </p>
      </div>
      <SkListItem class="rtt-counter__initialization-overlay-item">
        <template #left>
          <SkMedallion
            :text="userInitials(employee)"
            background-color="#2b66fe"
            color="#fff"
          />
        </template>
        <div class="rtt-counter__initialization-overlay-full-name">
          {{ fullName(employee) }}
        </div>
      </SkListItem>
      <SkOroraButton
        data-test="rtt-counter__initialization-button"
        @click="handleInitClick"
      >
        {{ $t('employees.tabs.counters.paid_leaves_counter.initialize') }}
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <RttConfigUserInitializationModal ref="rttConfigUserInialisationModal" />
        </MountingPortal>
      </SkOroraButton>
    </div>
    <div class="rtt-counter__initialization-overlay-background" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import RttConfigUserInitializationModal from './RttConfigUserInitializationModal';

export default {
  name: 'InitializeCounterOverlay',
  components: { RttConfigUserInitializationModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences', 'canReadPaidVacationCounter']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('shopAbsenceConfig', ['shopAbsenceConfig']),
    canPerformManualModification() {
      return (
        this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser
      ) && this.canReadPaidVacationCounter;
    },
    unauthorizedAction() {
      return !this.canEditShopRulesAndAbsences || !this.canPerformManualModification;
    },

  },
  methods: {
    handleInitClick() {
      if (this.unauthorizedAction) {
        this.$skToast({
          message: this.$t('employees.tabs.counters.rtt_counter.unauthorized'),
          variant: 'error',
        });
        return;
      }

      if (this.shopAbsenceConfig) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'rtt-config-user-initialization-modal');

        return;
      }

      const shopId = this.employee.attributes.shopId;
      this.$router.push({ name: 'shop_settings_counter_rules', params: { shop_id: shopId } });
    },
  },
};
</script>

<style lang="scss" scoped>
.rtt-counter__initialization-overlay-icon {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba($sk-blue, .15);
  vertical-align: middle;
}

.rtt-counter__initialization-overlay-background {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  height: 450px;
  background: rgba(0, 0, 0, .2);
}

.rtt-counter__initialization-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 13%;
  left: 25%;
  opacity: 1;
  z-index: 1;
  width: 420px;
  height: 372px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 6px;
  padding: 25px;
  background-color: white;
}

.rtt-counter__initialization-overlay-title {
  text-align: center;
  margin: 10px 20px !important;
}

.rtt-counter__initialization-overlay-item {
  width: 211px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  cursor: default;
}

.rtt-counter__initialization-overlay-description {
  margin-top: 10px;
}

.rtt-counter__initialization-overlay-full-name {
  padding-left: 15px;
}

.rtt-counter__initialization-overlay-button {
  padding: 11.5px 120px;
}
</style>
