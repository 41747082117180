<script>
export default {
  data() {
    return {
      columnActive: false,
    };
  },
  methods: {
    openColumn() {
      this.columnActive = true;
    },
    closeColumn(evt) {
      if (evt.target.classList.contains('modal-absences-explanation')) {
        return;
      }
      this.columnActive = false;
    },
  },
};
</script>
