import store from '@app-js/shared/store/index';

import Editor from './editor';

const textDocumentTemplatesRoutes = [
  {
    path: '/v3/shops/:shop_id/text_document_templates/new',
    name: 'text_document_templates_new',
    component: Editor,
    beforeEnter: (to, from, next) => {
      store.commit('textDocumentTemplates/newTextDocumentTemplate');
      store.commit('setNavbarVisibility', false);

      next();
    },
  },
  {
    path: '/v3/shops/:shop_id/text_document_templates/:text_document_template_id/edit',
    name: 'text_document_templates_edit',
    component: Editor,
    beforeEnter: (to, from, next) => {
      store.commit('setNavbarVisibility', false);
      store.dispatch('textDocumentTemplates/fetchTextDocumentTemplates', {
        shopId: to.params.shop_id,
      }).then(() => {
        store.commit(
          'textDocumentTemplates/selectTextDocumentTemplate',
          to.params.text_document_template_id,
        );
      }).then(() => {
        store.commit('setNavbarVisibility', false);
        next();
      });
    },
  },
  {
    path: '/v3/text_documents/new',
    name: 'text_document_new',
    component: Editor,
    props: { isTextDocument: true },
    beforeEnter: (to, from, next) => {
      const employeeId = to.query.user_id;
      const textDocumentTemplateId = to.query.text_document_template_id;
      const contractId = to.query.contract_id;
      const folder = to.query.folder;
      const clusterNodeId = to.query.cluster_node_id;

      Promise.all([
        store.dispatch({
          type: 'selectedEmployee/fetchEmployee',
          id: employeeId,
          cluster_node_id: clusterNodeId,
        }),
        store.dispatch({
          type: 'textDocumentTemplates/fetchTextDocumentTemplates',
          id: employeeId,
          cluster_node_id: clusterNodeId,
        }),
        store.dispatch({
          type: 'textDocumentTemplates/fetchTextDocumentTemplateVariablesValues',
          text_document_template_id: textDocumentTemplateId,
          employee_id: employeeId,
          contract_id: contractId,
          cluster_node_id: clusterNodeId,
        }),
      ])
        .then(() => store.dispatch('textDocumentTemplates/newTextDocument', {
          textDocumentTemplateId,
          employeeId,
          folder,
        }))
        .then(() => {
          store.commit('setNavbarVisibility', false);
          next();
        })
        .catch(error => {
          commit('textDocumentTemplates/catchTextDocumentTemplatesError', error);
        });
    },
  },
];

export default textDocumentTemplatesRoutes;
