<template>
  <!-- eslint-disable  max-len -->
  <div
    v-show="visible"
    id="staff-costs-flip-cards"
    class="dashboard-flip-cards dashboard-section bottom"
  >
    <div class="flip-card">
      <div class="face front">
        {{ $t('home_dashboard.staff_costs.title') }}
      </div>
      <div class="face back noflip">
        <div id="staff-costs">
          <div class="dashboard-title">
            <h2>
              {{ $t('home_dashboard.staff_costs.title2') }}
            </h2>
          </div>
          <div
            v-if="canEditManagementIndicator"
            class="dashboard-content"
            style="padding: 1em;"
          >
            <div class="flex-row staff-cost-nowrap">
              <!-- FORM -->
              <div class="flex-m6 labor-cost-inside-form-wrapper">
                <div class="labor-cost-inside-form">
                  <div class="flex-between responsive">
                    <div class="flex controls_wrapper">
                      <div class="daterange_wrapper">
                        <label
                          for="labor_cost_start_date"
                          class="staff-costs-label"
                        >{{ $t('home_dashboard.staff_costs.labor_cost.start_date') }}</label>
                        <div class="input-daterange flex-start">
                          <SkDatePicker
                            id="labor_cost_start_date"
                            v-model="form.start_date"
                            name="labor_cost[start_date]"
                            input-moment-format="DD/MM/YYYY"
                            :lang="$i18n.locale"
                          />
                          <span class="input-group-addon">{{ $t("home_dashboard.staff_costs.common.to") }}</span>
                          <SkDatePicker
                            id="labor_cost_end_date"
                            v-model="form.end_date"
                            name="labor_cost[end_date]"
                            input-moment-format="DD/MM/YYYY"
                            :lang="$i18n.locale"
                          />
                        </div>
                      </div>

                      <div class="turnover_wrapper">
                        <label
                          for="labor_cost_turnover"
                          class="required staff-costs-label"
                        >
                          <abbr title="required">*</abbr> {{ $t('home_dashboard.staff_costs.labor_cost.turnover') }}
                        </label>
                        <SkInput
                          ref="staffCostTurnoverInput"
                          v-model="form.turnover"
                          data-test="staff-costs__turnover-input"
                          type="number"
                          min="0"
                          step="1"
                          class="turnover__input"
                          :errored="!isTurnoverFilled"
                          error-message="Veuillez renseigner une valeur"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="staff-costs-revenue-input">
                    <label
                      for="labor_cost_shop"
                      class="staff-costs-label"
                    >{{ $t('home_dashboard.staff_costs.labor_cost.shop') }}</label>
                    <div class="form-group select optional labor_cost_shop staff-costs-general-width">
                      <SkSelect
                        v-model="form.selectedShop"
                        append-to-body
                        :options="shopOptions"
                        :ajax-format-response="shopsFormatResponse"
                        :ajax-url="get_shops_path"
                        :ajax-client="ajaxClient"
                      >
                        <template #selected-option="{ value }">
                          <div class="text-truncate">
                            {{ value.text }}
                          </div>
                        </template>
                        <template #empty-option>
                          {{ $t('search_bar.no_result') }}
                        </template>
                        <template #option="{ option }">
                          <div> {{ option.text }} </div>
                        </template>
                      </SkSelect>
                    </div>
                  </div>
                </div>

                <div>
                  <SkOroraButton
                    class="sk-btn-submit"
                    :disabled="isSubmitDisabled"
                    @click="handleSubmit"
                  >
                    {{ $t('home_dashboard.staff_costs.compute') }}
                  </SkOroraButton>
                </div>
              </div>

              <!-- LOADER & RESULT DISPLAY -->
              <template v-if="showResult">
                <div
                  v-if="isLoading"
                  class="flex-m6 result_wrapper calculation_loader"
                >
                  <SkLoader size="large" />
                </div>
                <div
                  v-else
                  class="flex-m6 result_wrapper"
                >
                  <div class="staff-ratio-box">
                    {{ displayCostResult }}
                  </div>
                  <div class="flex-end">
                    {{ $t('home_dashboard.staff_costs.labor_cost.duration', {start_date: readableDateFormat(form.start_date), end_date: readableDateFormat(form.end_date)}) }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-else
            class="unauthorized-content"
          >
            <h5>{{ $t('home_dashboard.unauthorized') }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'StaffCostsCard',
  data() {
    return {
      ajaxClient: () => httpClient,
      visible: true,
      form: {
        selectedShop: { id: 'all', text: this.$t('home_dashboard.staff_costs.common.all') },
        start_date: skDate().startOf('month').format('YYYY-MM-DD'),
        end_date: skDate().endOf('month').format('YYYY-MM-DD'),
        turnover: null,
      },
      shopOptions: [{ id: 'all', text: this.$t('home_dashboard.staff_costs.common.all') }],
      usersToUpdate: [],
      costResult: null,
      notification_labor_cost_path: '/labor_cost',
      get_shops_path: '/v3/api/shops?only_active_shops=true&skip_pagination=true',
      isLoading: false,
      showResult: false,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentUser', ['currentUser']),
    canEditManagementIndicator() {
      return this.currentLicense.attributes.canEditManagementIndicators || false;
    },
    isTurnoverFilled() {
      return this.form.turnover && this.form.turnover > 0;
    },
    displayCostResult() {
      const cost = this.costResult ? this.costResult.toFixed(2) : 0;
      return `${cost} %`;
    },
    isSubmitDisabled() {
      return this.isLoading || !this.isTurnoverFilled;
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
    this.listenOnRoot('dashboard-v3-staff-cost-updated', this.handleSubmit);
  },
  methods: {
    // SkDatePicker needs to be in the english format, but the endpoint on labor_cost is expecting the french format
    frenchFormatDate(date) {
      return skDate(date).format('DD/MM/YYYY');
    },
    // The displayed date used by the result
    readableDateFormat(date) {
      return skDate(date).format('dddd D MMM');
    },
    initialLoad() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'StaffCostsCard');
    },
    shopsFormatResponse(response) {
      return response.data.map(shop => ({
        text: shop.attributes.name,
        id: shop.id,
      }));
    },
    async handleSubmit() {
      this.isLoading = true;
      // This boolean is used to show the result bloc,
      // because it is not shown the first time we flip the card
      this.showResult = true;
      const response = await this.calculateStaffCost();

      // Depending on the response we display the modal and call the patch endpoint
      // Because if some users doesnt have the hourly rate filled, it is patched and then the calculation cost is starting
      if (response.status === 'not_allowed') {
        this.showResult = false;
        this.usersToUpdate = response.data.users.map(user => ({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
        }));
        this.openModal();
      } else {
        // if it is a success we display directly the result
        this.costResult = response.data.result;
      }

      this.isLoading = false;
    },
    async calculateStaffCost() {
      const params = {
        start_date: this.frenchFormatDate(this.form.start_date),
        end_date: this.frenchFormatDate(this.form.end_date),
        turnover: this.form.turnover,
        shop_id: this.form.selectedShop.id,
        staff_costs: 0,
      };
      const response = await httpClient.get(this.notification_labor_cost_path, { params });
      return response.data;
    },
    openModal() {
      this.emitOnRoot('dashboard-v3-staff-cost-update', this.usersToUpdate);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'staff-costs-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: .5rem;
}

.unauthorized-content {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.labor-cost-inside-form-wrapper {
  margin-left: 5px;
}

.staff-cost-nowrap {
  flex-flow: nowrap;
}

.controls_wrapper {
  height: 70px;
}

.turnover_wrapper {
  margin-left: 1em;
}

.turnover__input {
  width: 175px !important;
}

.calculation_loader {
  justify-content: center;
  height: 100%;
}

.result_wrapper {
  margin-top: 105px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
