<template>
  <div class="alert-card">
    <div class="punchclock-rules__alert-inner-container">
      <div class="punchclock-rules__alert-icon-container">
        <CircledExclamationMarkIcon
          fill="#d03e50"
        />
      </div>
      <div
        class="punchclock-rules__alert-inner-text-container"
      >
        <h3>{{ title }}</h3>
        <span style="color: #727272;">{{ content }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WarningCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.alert-card {
  border-radius: 4px;
  background: white;
  box-shadow: 0 16px 24px rgba(0, 0, 0, .04), inset 4px 0 0 $sk-error;
  padding: 16px 24px;
  margin-top: 32px;
}

.punchclock-rules__alert-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: $sk-error-10;
}

.punchclock-rules__alert-icon-container svg {
  width: 20px;
  height: 20px;
}

.punchclock-rules__alert-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.punchclock-rules__alert-inner-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}
</style>
