<template>
  <div
    class="draggable-task__container"
    :style="{ pointerEvents: disabled ? 'none' : 'auto' }"
  >
    <DoubleKebabMenuIcon
      class="draggable-item"
      :style="draggableIconStyle"
      fill="#727272"
      height="20"
      width="20"
    />
    <Task
      :task="task"
      :disabled="disabled"
      @delete="() => handleDelete(task.id)"
    />
  </div>
</template>

<script>
import Task from '@app-js/plannings/shared/components/ShiftActivities/Tasks/Task';

export default {
  name: 'DraggableTask',
  components: { Task },
  props: {
    task: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDragging: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  computed: {
    draggableIconStyle() {
      return {
        cursor: this.isDragging ? 'inherit' : 'grab',
      };
    },
  },
  methods: {
    handleDelete(taskId) {
      this.$emit('delete', taskId);
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task__container {
  display: flex;
  align-items: center;
  border-radius: 4px;

  .draggable-item {
    visibility: hidden;
  }

  &:hover {
    background-color: #f3f3f3;

    .draggable-item {
      visibility: visible;
    }
  }
}

.draggable-item {
  margin-right: 4px;
}
</style>
