<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="298"
    height="175"
    viewBox="0 0 298 175"
    fill="none"
    class="logo-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M159 166C197.66 166 229 134.66 229 96C229 57.3401 197.66 26 159 26C120.34 26 89 57.3401 89 96C89 134.66 120.34 166 159 166Z"
      fill="#F1F6FF"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M184.332 127.259L134.116 127.502C131.883 127.513 130.065 125.712 130.054 123.48L129.927 97.1416L188.227 96.8594L188.354 123.197C188.365 125.43 186.564 127.248 184.332 127.259Z"
        fill="#2b66fe"
      />
      <path
        d="M188.331 102.797H130.03L128.225 81.5459C127.947 78.9073 130.016 76.6094 132.669 76.6094H185.692C188.345 76.6094 190.414 78.9073 190.136 81.5459L188.331 102.797Z"
        fill="#0D53CA"
      />
      <path
        d="M185.692 76.6094H160.368V102.797H188.332L190.137 81.5459C190.415 78.9073 188.345 76.6094 185.692 76.6094Z"
        fill="#0D53CA"
      />
      <path
        d="M132.735 70.1611C132.735 67.0475 135.259 64.5234 138.373 64.5234C141.486 64.5234 144.01 67.0475 144.01 70.1611V76.6188H132.735V70.1611Z"
        fill="#BBC4D0"
      />
      <path
        d="M172.301 70.1611C172.301 67.0475 174.825 64.5234 177.939 64.5234C181.053 64.5234 183.577 67.0475 183.577 70.1611V76.6188H172.301V70.1611Z"
        fill="#727272"
      />
      <circle
        cx="138.372"
        cy="70.5713"
        r="2.76759"
        fill="#eef4ff"
      />
      <circle
        cx="177.938"
        cy="71.1865"
        r="2.76759"
        fill="#eef4ff"
      />
      <circle
        cx="170.558"
        cy="94.7619"
        r="2.35758"
        fill="white"
      />
      <circle
        cx="148.008"
        cy="94.7619"
        r="2.35758"
        fill="white"
      />
      <rect
        x="152.621"
        y="110.035"
        width="13.1204"
        height="4.71515"
        rx="2.35758"
        fill="white"
      />
    </g>
    <rect
      x="269.362"
      y="13"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      fill="#D2D7DC"
    />
    <rect
      x="279.149"
      y="20.2344"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 279.149 20.2344)"
      fill="#D2D7DC"
    />
    <rect
      x="7.23389"
      y="93"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      fill="#D2D7DC"
    />
    <rect
      x="17.0215"
      y="100.234"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 17.0215 100.234)"
      fill="#D2D7DC"
    />
    <rect
      x="76.2339"
      y="157"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      fill="#D2D7DC"
    />
    <rect
      x="86.0215"
      y="164.234"
      width="2.55319"
      height="17.0213"
      rx="1.2766"
      transform="rotate(90 86.0215 164.234)"
      fill="#D2D7DC"
    />
    <circle
      cx="66.2128"
      cy="10.2128"
      r="8.71277"
      stroke="#D2D7DC"
      stroke-width="3"
    />
    <circle
      cx="287.213"
      cy="125.213"
      r="8.71277"
      stroke="#D2D7DC"
      stroke-width="3"
    />
    <defs>
      <filter
        id="filter0_d"
        x="112.2"
        y="56.5234"
        width="93.9624"
        height="94.9788"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.36375 0 0 0 0 0.57825 0 0 0 0 0.9 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EmptyBadgingsLogo',
};
</script>

<style lang="scss" scoped>
.logo-svg {
  width: 298px; // ie11 support
  height: 175px; // ie11 support
}
</style>
