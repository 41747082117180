<template>
  <div class="workload-plan-bottom-panel__content">
    <div :class="workloadPlanBottomPanelContentClasses">
      <Postes
        :postes="orderedPostes"
      />
    </div>
    <div :class="workloadPlanBottomPanelContainerClasses">
      <div class="workload-plan-bottom-panel__content__wrapper">
        <Header />
      </div>

      <Tab
        :value="value"
        v-on="$listeners"
        @on-is-scrollable-horizontally="setIsScrollableHorizontally"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '../Header';
import Postes from '../Postes';
import Tab from '../Tab';

export default {
  name: 'Content',
  components: {
    Header,
    Postes,
    Tab,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isHorizontalScrolling: false,
      isVerticalScrolling: false,
      currentHorizontalScrollPosition: 0,
      currentVerticalScrollPosition: 0,
      isScrollableHorizontally: false,
    };
  },
  computed: {
    ...mapState('planningsPostes', ['postes']),
    orderedPostes() {
      return [...this.postes].sort((posteA, posteB) => (
        posteA.attributes.name.toLowerCase()
          .localeCompare(posteB.attributes.name.toLowerCase())
      ));
    },
    workloadPlanBottomPanelContentClasses() {
      return {
        'workload-plan-bottom-panel__content__postes': true,
        'workload-plan-bottom-panel__content__postes--shadow': this.isHorizontalScrolling,
      };
    },
    workloadPlanBottomPanelContainerClasses() {
      return {
        'workload-plan-bottom-panel__content__container': true,
        'workload-plan-bottom-panel__content__container--bottom-shadow': this.isVerticalScrolling,
        'workload-plan-bottom-panel__content__container--right-shadow': this.isScrollableHorizontally,
      };
    },
  },
  mounted() {
    this.listenOnRoot('workload-plan-vertical-scroll', this.handleVerticalScroll);
    this.listenOnRoot('workload-plan-horizontal-scroll', this.handleHorizontalScroll);
  },
  methods: {
    handleHorizontalScroll(event) {
      const isHorizontalScrolling = event.target.scrollLeft !== 0;

      this.isScrollableHorizontally =
        event.target.scrollLeft + event.target.clientWidth !== event.target.scrollWidth;
      this.isHorizontalScrolling = isHorizontalScrolling;
      this.currentHorizontalScrollPosition = event.target.scrollLeft;
      this.isVerticalScrolling = !isHorizontalScrolling && this.currentVerticalScrollPosition;
    },
    handleVerticalScroll(event) {
      const isVerticalScrolling = event.target.scrollTop !== 0;

      this.currentVerticalScrollPosition = event.target.scrollTop;
      this.isVerticalScrolling = isVerticalScrolling;
      this.isHorizontalScrolling = !isVerticalScrolling && this.currentHorizontalScrollPosition;
    },
    setIsScrollableHorizontally(value) {
      this.isScrollableHorizontally = value;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@app-js/plannings/pages/Days/css_variables' as *;

.workload-plan-bottom-panel__content {
  display: flex;
  justify-content: flex-end;
  background-color: $sk-white;

  &__postes {
    width: $postes-list-width;
    min-width: $postes-list-width;
    padding-top: 16px;
    position: absolute;
    background-color: $sk-white;
    left: 0;
    height: 100%;

    /*
      This css is used to add a shadow on the right of the poste list component
      Box-shadow can not be used here, because it's not made to have shadow on only one side.
    */
    &--shadow::after {
      background: linear-gradient(to right, rgba(0, 0, 0, .4), white);
      right: -14px;
      content: " ";
      width: 14px;
      position: absolute;
      top: 0;
      height: 100%;
      opacity: .12;
      z-index: 1; /* Put it above the tab content */
    }
  }

  &__wrapper {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    width: calc(100% - $postes-list-width);
    padding-right: 16px;
    position: relative;

    &--right-shadow {
      .workload-plan-bottom-panel__tab--simple-bar::before,
      .workload-plan-bottom-panel__content__wrapper::before {
        background: linear-gradient(to left, rgba(0, 0, 0, .4), white);
        content: " ";
        width: 14px;
        position: absolute;
        bottom: 0;
        height: 100%;
        opacity: .12;
        right: 0;
        z-index: 1;
      }

      .workload-plan-bottom-panel__content__wrapper::before {
        top: 0;
        right: 16px;
        height: 67px;
      }
    }

    /*
      This css is used to add a shadow on the bottom of the panel during the vertical scroll
      Box-shadow can not be used here, because it's not made to have shadow on only one side.
    */
    &--bottom-shadow {
      .workload-plan-bottom-panel__tab--simple-bar::after {
        background: linear-gradient(to top, rgba(0, 0, 0, .4), white);
        content: " ";
        width: calc(100% + $postes-list-width);
        position: absolute;
        bottom: 0;
        height: 14px;
        opacity: .12;
        right: 0;
        z-index: 1;
      }
    }
  }
}
</style>
