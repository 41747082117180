<template>
  <div
    :class="columnClasses"
    @click="$emit('click', $event)"
  >
    <div :class="titleClasses">
      {{ month.month }}
    </div>
    <div v-if="month.closed">
      <TableCell :tooltip="cellTitle" />
      <TableCell :tooltip="cellTitle" />
      <TableCell :tooltip="cellTitle" />
      <TableCell
        v-if="showVariableContractHoursLine"
        :tooltip="cellTitle"
      />
      <TableCell
        v-if="majoration"
        :show-dash="month.show_dash"
        :data="month.over_hours"
        :subtitle="roundedValue(month.over_hours_w_majoration - month.over_hours)"
        has-subtitle
      />
      <TableCell
        :show-dash="month.show_dash"
        :tooltip="cellTitle"
        :data="roundedValue(month.hours_counter - month.manual_changes)"
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
      <TableCell
        :show-dash="month.show_dash"
        :tooltip="cellTitle"
        :data="roundedValue(month.manual_changes)"
      />
      <TableCell
        :show-dash="month.show_dash"
        :tooltip="cellTitle"
        :data="roundedValue(month.hours_counter)"
        class="employee-counters__column__bold-cell"
        color="#ff9600"
      />
    </div>
    <div
      v-else
      class="employee-counters__table-weeks-wrapper"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { roundFloat } from '@skello-utils/formatting/numbers';
import { TableCell } from '../shared/components';

export default {
  name: 'HoursCounterMonthColumn',
  components: { TableCell },
  props: {
    month: {
      type: Object,
      required: true,
    },
    showVariableContractHoursLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('employeeCounters', ['majoration']),
    columnClasses() {
      return {
        'employee-counters__column': this.month.closed,
        'employee-counters__column--open': !this.month.closed,
        'employee-counters__column--previsional': this.month.previsional,
      };
    },
    cellTitle() {
      return this.month.closed ?
        this.$i18n.t('employees.tabs.counters.hours_counter.tooltip.show_more') :
        '';
    },
    titleClasses() {
      return {
        'employee-counters__column-title': true,
        'employee-counters__column-title--open': !this.month.closed,
      };
    },
  },
  methods: {
    roundedValue(value) {
      return roundFloat(value, 2);
    },
  },
};
</script>
