<template>
  <SkModal
    id="punch-clock-upsell-modal"
    :display-footer="false"
    :dismissable="false"
    @hidden="handleHide"
    @show="handleShow"
  >
    <template #body>
      <div class="punch_clock_upsell_modal__wrapper">
        <div class="punch_clock_upsell__title-section">
          <i18n
            path="badgings.upsell_modal.title"
            tag="h3"
            class="sk-header--3 punch_clock_upsell__title"
          >
            <template #highlight>
              <span class="punch_clock_upsell__title--highlight">{{ $t('badgings.upsell_modal.highlight_title') }}</span>
            </template>
          </i18n>
          <div class="punch_clock_upsell__subtitle">
            <span class="punch_clock_upsell__subtitle--highlight">{{ $t('badgings.upsell_modal.subtitle_blue') }}</span>{{ $t('badgings.upsell_modal.subtitle') }}
          </div>
        </div>
        <div class="punch_clock_upsell__three-blocks">
          <div class="punch_clock_upsell__three-blocks__block">
            <img
              :src="visuel1"
              class="punch_clock_upsell__three-blocks__image"
            >
            <div class="punch_clock_upsell__three-blocks__text">
              {{ $t('badgings.upsell_modal.three_blocks.first_block') }}
            </div>
          </div>

          <div class="punch_clock_upsell__three-blocks__block">
            <img
              :src="visuel2"
              class="punch_clock_upsell__three-blocks__image"
            >
            <div class="punch_clock_upsell__three-blocks__text">
              {{ $t('badgings.upsell_modal.three_blocks.second_block') }}
            </div>
          </div>

          <div class="punch_clock_upsell__three-blocks__block">
            <div class="punch_clock_upsell__three-blocks__image-wrapper">
              <img
                :src="visuel3"
                class="punch_clock_upsell__three-blocks__image"
              >
            </div>
            <div class="punch_clock_upsell__three-blocks__text">
              {{ $t('badgings.upsell_modal.three_blocks.third_block') }}
            </div>
          </div>
        </div>
        <div class="punch-clock__upsell-modal__request__button">
          <SkOroraButton @click="requestUpsell">
            {{ $t('badgings.upsell_modal.submit_request') }}
          </SkOroraButton>
        </div>
        <SkOroraButton
          v-if="!shopHasPunchClock"
          variant="tertiary"
        >
          <router-link
            class="punch-clock__upsell-modal__back-to-planning-link"
            :to="planningUrl"
          >
            {{ $t('badgings.upsell_modal.back_to_planning') }}
          </router-link>
        </SkOroraButton>
        <a
          v-else
          class="punch-clock__upsell-modal__back-to-planning-link"
          href="#"
          @click="hideModal"
        >
          {{ $t('badgings.upsell_modal.back_to_badging') }}
        </a>
      </div>
    </template>
  </SkModal>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  mapGetters,
  mapState,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'PunchClockUpsellModal',
  props: {
    initialStep: {
      type: String,
      default: 'IntroStep',
    },
  },
  data() {
    return {
      requestDemoLoading: false,
      isLoading: false,
      visuel1: null,
      visuel2: null,
      visuel3: null,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('config', ['config']),
    planningUrl() {
      return `/v3/shops/${this.currentShop.id}/plannings?date=${this.redirectDate}`;
    },
    shopHasPunchClock() {
      return this.currentShop.attributes.badging;
    },
  },
  async mounted() {
    const visuel1 = await import(`./assets/${this.currentUser.attributes.lang}/Visuel_1.jpg`);
    const visuel2 = await import(`./assets/${this.currentUser.attributes.lang}/Visuel_2.jpg`);
    const visuel3 = await import(`./assets/${this.currentUser.attributes.lang}/Visuel_3.jpg`);

    this.visuel1 = visuel1.default;
    this.visuel2 = visuel2.default;
    this.visuel3 = visuel3.default;
  },
  methods: {
    handleShow() {
      // Remove scroll when punch-clock__upsell__modal is shown
      if (document.querySelector('body')) {
        this.oldBodyOverflow = document.querySelector('body').style.overflow;
        document.querySelector('body').style.overflow = 'hidden';
        // Disable pointer events to prevent actions if user delete upsell modal from DOM
        document.querySelector('.badgings__wrapper').style.pointerEvents = 'none';
      }
    },
    handleHide() {
      // when clicking on the cross with punch clock disabled on shop
      if (!this.shopHasPunchClock) window.location.href = this.planningUrl;
      // Revert scroll when punch-clock__upsell__modal is hidden
      if (document.querySelector('body')) {
        document.querySelector('body').style.overflow = this.oldBodyOverflow;
        this.oldBodyOverflow = '';
        // Removes the lock on click events
        document.querySelector('.badgings__wrapper').style.pointerEvents = 'auto';
      }
    },
    hideModal() {
      this.handleHide();
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'punch-clock-upsell-modal');
    },
    requestUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('badgings.upsell_modal.upsell_already_requested'),
          variant: 'success',
          containerId: 'punch-clock-upsell-modal',
        });

        return;
      }
      const params = {
        shop_id: this.currentShop.id,
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: this.currentShop.id,
          current_shop_name: this.currentShop.attributes.name,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('badgings.upsell_modal.submit_request'),
          upsell_type: 'badging',
          current_page: 'Badgeuse',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.upsellRequested = true;
          this.$skToast({
            message: this.$t('badgings.upsell_modal.submit_confirm'),
            variant: 'success',
            containerId: 'punch-clock-upsell-modal',
          });
        })
        .catch(error => {
          this.upsellRequested = true;
          this.$skToast({
            message: getErrorMessage(error),
            variant: 'error',
            containerId: 'punch-clock-upsell-modal',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal {
  .sk-modal__dialog {
    width: 620px;
  }

  // Over write sk-modal_header for illustration banner on this kind of modal
  // (Same as onboardings modals)
  .sk-modal__header {
    border-bottom: 0;
    padding: 17px 24px 0;
    height: 60px;
  }
}

.punch_clock_upsell_modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  padding-bottom: 30px;
  min-height: 480px;
}

.punch_clock_upsell__title {
  text-align: center;
  font-size: $fs-heading-m;
  padding-bottom: 24px;
  color: $sk-black;
}

.punch_clock_upsell__title--highlight {
  color: $sk-blue-50;
}

.punch_clock_upsell__subtitle {
  color: $sk-grey;
  font-size: $fs-text-l;
}

.punch_clock_upsell__subtitle--highlight {
  color: $sk-blue-50;
}

.punch_clock_upsell__subtitle {
  font-weight: 400;
  font-size: $fs-text-l;
  line-height: 21px;
  color: $sk-grey;
  text-align: center;
}

.punch_clock_upsell__three-blocks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.punch_clock_upsell__three-blocks__block {
  width: 164px;
}

.punch_clock_upsell__three-blocks__image {
  margin-bottom: 16px;
  width: 100%;
}

.punch_clock_upsell__three-blocks__text {
  font-size: $fs-text-s;
  line-height: 16px;
  text-align: center;
  color: $sk-grey;
}

.punch_clock_upsell__submits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
}

.punch_clock_upsell__image-text__timesheet,
.punch_clock_upsell__image-text__month {
  position: absolute;
  white-space: nowrap;
}

.punch-clock__upsell-modal__back-to-planning-link {
  color: $sk-black;

  &:hover {
    text-decoration: none;
  }
}
</style>
