<template>
  <ApexChart
    ref="mixed_line_area_chart"
    type="line"
    height="100%"
    :width="chartWidth"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
export default {
  name: 'MixedLineArea',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    seriesColors: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    chartWidth: {
      type: Number,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        colors: this.seriesColors,
        legend: { show: false },
        chart: {
          width: this.chartWidth,
          zoom: { enabled: false },
          toolbar: {
            show: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          dashArray: [8, 0],
        },
        fill: {
          type: 'solid',
          opacity: [1, 0.2],
        },
        grid: {
          borderColor: '#dddddd',
          padding: {
            top: -15,
            right: 40,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        markers: {
          // It should display a marker if we have only one data for the current granularity.
          // For instance data for 1 month with a "month" granularity.
          size: this.categories.length > 1 ? 0 : 5,
          colors: this.seriesColors,
        },
        xaxis: {
          categories: this.categories,
          axisTicks: { show: true },
          labels: {
            style: {
              fontFamily: 'Gellix, sans-serif',
              colors: new Array(this.categories.length).fill('#727272'),
              fontSize: '10px',
            },
          },
          axisBorder: {
            show: true,
            color: '#dddddd',
            height: 1,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              fontFamily: 'Gellix, sans-serif',
              colors: ['#727272'],
              fontSize: '10px',
            },
            // Here we need an arrow function to access
            // the global 'this' instead of this function local 'this'
            formatter: val => `${this.roundTwo(val)}${this.unit}`,
          },
        },
        tooltip: {
          marker: { show: false },
          custom: this.customTooltip,
          intersect: false,
        },
      };
    },
  },
  watch: {
    // Due to lifecycle of Apex, we need to use the updateOptions method
    // Using width: this.chartWidth in the computed is not taken into account
    // When we change chartWidth values from the Card component
    chartWidth(_newV, _oldV) {
      this.$refs.mixed_line_area_chart.chart.updateOptions({
        chart: { width: this.chartWidth },
      });
    },
  },
  methods: {
    customTooltip({ series, dataPointIndex }) {
      this.$emit('data-event', { series, dataPointIndex });
      return '<div class="alex-special" />';
    },
    roundTwo(number) {
      return (Math.round(Math.abs(number) * 100) / 100).toString();
    },
  },
};
</script>
