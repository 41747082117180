<template>
  <SkModalV2
    id="primes-modal"
    ref="modal"
    :title="$t('reports.modals.primes.modal_title')"
    :submit-disabled="disableSubmit"
    @show="resetData"
    @submit="handleAddPrime"
  >
    <template #body>
      <SkModalSectionV2 v-if="existingPrimeAmount !== 0">
        <div class="primes-modal__form-line">
          <span class="primes-modal__label">
            {{ $t('reports.modals.primes.labels.existing_primes') }}
          </span>

          <span class="primes-modal__field">
            <span class="primes-modal__existing-amount">
              {{ $t(
                'reports.modals.primes.existing_amount',
                {amount: existingPrimeAmount, currencySymbol}
              ) }}
            </span>
            <span
              class="primes-modal__delete-icon"
              @click="handleDeletePrime"
            >
              <TrashCanV2Icon
                data-test="primes-modal__delete-existing-primes"
                width="18"
                height="16"
                fill="#6d7d8c"
              />
            </span>
          </span>
        </div>
      </SkModalSectionV2>
      <SkModalSectionV2>
        <div class="primes-modal__form-line primes-modal__form-line--margin-bottom">
          <span class="primes-modal__label">
            {{ $t('reports.modals.primes.labels.new_amount') }}
          </span>

          <span class="primes-modal__field">
            <SkInput
              v-model="newPrimeAmount"
              class="primes-modal__new-amount"
              :errored="isNewAmountInvalid"
              :error-message="$t('reports.modals.primes.errors.invalid_amount')"
              :placeholder="$t('reports.modals.primes.placeholders.new_amount')"
              data-test="primes-modal__new-prime-amount"
            />
            <span class="new-amount__currency-symbol">
              {{ currencySymbol }}
            </span>
          </span>
        </div>

        <div class="primes-modal__form-line">
          <span class="primes-modal__label">
            {{ $t('reports.modals.primes.labels.prime_date') }}
          </span>

          <span class="primes-modal__field">
            <SkDatePicker
              v-model="newPrimeDate"
              class="primes-modal__prime-date"
              :placeholder="$t('reports.modals.primes.placeholders.select_month')"
              type="month"
              :lang="$i18n.locale"
              input-moment-format="MM/YYYY"
              hide-footer
              data-test="primes-modal__new-prime-date"
            />
          </span>
        </div>
      </SkModalSectionV2>
      <SkModalSectionV2 border-bottom="none">
        <div class="primes-modal__form-line">
          <span class="primes-modal__label">
            {{ $t('reports.modals.primes.labels.description') }}
          </span>

          <span class="primes-modal__field">
            <SkTextarea
              v-model="newPrimeDescription"
              class="primes-modal__description"
              :label="$t('reports.modals.primes.placeholders.add_description')"
              data-test="primes-modal__new-prime-description"
              auto-grow
              :min-height="54"
              :max-height="99"
              rows="3"
            />
          </span>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'PrimesModal',
  props: {
    employeeId: {
      type: Number,
      default: null,
    },
    contractId: {
      type: Number,
      default: null,
    },
    shopId: {
      type: Number,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: false,
      default: '€',
    },
  },
  data() {
    return {
      newPrimeAmount: '',
      newPrimeDate: '',
      newPrimeDescription: '',
    };
  },
  computed: {
    ...mapState('report', ['saasReportData']),
    disableSubmit() {
      return this.newPrimeAmount === '' ||
        this.isNewAmountInvalid ||
        !this.newPrimeDate ||
        this.newPrimeDate === '';
    },
    contractInfos() {
      return this.saasReportData[this.employeeId].contracts_infos[this.contractId];
    },
    existingPrimeAmount() {
      if (!this.employeeId || !this.contractId) {
        return 0;
      }

      return this.contractInfos.primes;
    },
    isNewAmountInvalid() {
      return this.newPrimeAmount !== '' && isNaN(this.newPrimeAmount);
    },
  },
  methods: {
    ...mapActions('report', ['addPrime', 'deletePrime']),
    ...mapMutations('report', ['updatePrimeAmount']),
    resetData() {
      this.newPrimeAmount = '';
      this.newPrimeDate = skDate(this.startDate).format('MM/YYYY');
      this.newPrimeDescription = '';
    },
    handleAddPrime() {
      const params = {
        shop_id: this.shopId,
        contract_id: this.contractId,
        prime: {
          for_month: skDate(this.newPrimeDate).format('MM/YYYY'),
          amount: this.newPrimeAmount,
          description: this.newPrimeDescription,
        },
      };

      this.addPrime(params)
        .then(() => {
          this.updatePrimeAmount({
            employeeId: this.employeeId,
            contractId: this.contractId,
            prime: this.existingPrimeAmount + Number(this.newPrimeAmount),
          });
          this.$skToast({
            message: this.$t('reports.modals.primes.success.add'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    handleDeletePrime() {
      const params = {
        shop_id: this.shopId,
        contract_id: this.contractId,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      this.deletePrime(params)
        .then(() => {
          this.updatePrimeAmount({
            employeeId: this.employeeId,
            contractId: this.contractId,
            prime: 0,
          });
          this.$skToast({
            message: this.$t('reports.modals.primes.success.delete'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.primes-modal__delete-icon {
  margin-left: 28px;
  height: 16px;
  cursor: pointer;
}

.primes-modal__form-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--margin-bottom {
    margin-bottom: 8px;
  }
}

.primes-modal__label {
  font-weight: bold;
}

.primes-modal__field {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.new-amount__currency-symbol {
  position: relative;
  right: 23px;
  width: 0;
}

.primes-modal__new-amount,
.primes-modal__prime-date {
  width: 286px;
}

.primes-modal__description {
  width: 376px !important;
}
</style>
