<template>
  <div :class="{'loading-wrapper': docsEsignatureLoading}">
    <SkLoader
      v-if="docsEsignatureLoading"
      size="x-large"
    />

    <div
      v-else
      id="bodyContentWrapper"
      class="follow-up-docsEsignature-esignature-panel__body"
    >
      <SkTable
        :columns="header"
      >
        <UserRow
          v-for="docEsignature in docsEsignaturesToRender"
          :key="docEsignature.id"
          :doc-esignature="docEsignature"
        />
      </SkTable>
      <div
        v-if="noDocsToRender"
        class="follow-up-docsEsignature_no-docs-to-render"
      >
        <div
          class="follow-up-docsEsignature_no-docs-to-render__medallion"
        >
          <FileIcon
            :width="40"
            :height="40"
            fill="#ffffff"
          />
        </div>
        <p><strong>{{ $t('employees.signature_followup.side_bar.no_docs_to_render.title') }}</strong></p>
        <p class="body">
          {{ $t('employees.signature_followup.side_bar.no_docs_to_render.message.part1') }}
          <SkMedallion
            icon="KebabIcon"
            color="#000000"
            background-color="#dddddd"
            class="follow-up-docsEsignature_no-docs-to-render__medallion-options"
            size="small"
          />
          {{ $t('employees.signature_followup.side_bar.no_docs_to_render.message.part2') }}
          <b>{{ $t('employees.signature_followup.side_bar.no_docs_to_render.message.part3') }}</b>
          {{ $t('employees.signature_followup.side_bar.no_docs_to_render.message.part4') }}
          <b>{{ $t('employees.signature_followup.side_bar.no_docs_to_render.message.part5') }}</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import { capitalize } from '@skello-utils/formatting/strings';
import { mapState } from 'vuex';
import UserRow from './UserRow';

export default {
  name: 'FollowUpStep',
  components: {
    UserRow,
  },
  data() {
    return {
      docsEsignaturesToRender: [],
      areDocsAlreadyLoaded: false,
      isFullyScrolledBottom: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('employees', ['docsEsignature', 'docsEsignatureLoading']),
    header() {
      const headers = [
        {
          title: this.$t('employees.signature_followup.side_bar.header.signer'),
        },
        {
          title: this.$t('employees.signature_followup.side_bar.header.document'),
        },
        {
          title: this.$t('employees.signature_followup.side_bar.header.signature_status'),
        },
        {
          title: this.$t('employees.signature_followup.side_bar.header.sending_state'),
        },
        {
          title: this.$t('employees.signature_followup.side_bar.header.resend'),
        },
      ];
      return headers;
    },
    employeesContainer() {
      return document.getElementById('bodyContentWrapper');
    },
    noDocsToRender() {
      return this.areDocsAlreadyLoaded && this.docsEsignaturesToRender.length === 0;
    },
  },
  watch: {
    docsEsignature(docsEsignature) {
      this.docsEsignaturesToRender = this.docsEsignature
        .filter(docEsignature => docEsignature.signatureStatus !== 'cancelled')
        .map(docEsignature => {
          const status = this.calculateStatus(docEsignature.signatureStatus);
          const signersJoinedName = docEsignature.signers
            .map(signer => `${capitalize(signer.firstName)} ${capitalize(signer.lastName)}`)
            .join(', ') || '-';

          return {
            id: docEsignature.documentId,
            attributes: {
              signersName: signersJoinedName,
              signersCount: docEsignature.signers.length,
              documentName: docEsignature.documentName,
              signatureStatus: status,
              sendingState: docEsignature.sentAt,
              signers: docEsignature.signers,
            },
          };
        });
      this.areDocsAlreadyLoaded = true;
      // FIXME: Is sorting an status by string order instead of status priority
      // this.docsEsignaturesToRender = orderBy(this.docsEsignaturesToRender, ['attributes.signatureStatus'], ['asc']);
      this.docsEsignaturesToRender = orderBy(this.docsEsignaturesToRender, ['attributes.sendingState'], ['asc']);
    },
  },
  created() {
    this.toggleFullyScrolled = debounce(this.toggleFullyScrolled.bind(this), 10);
  },
  mounted() {
    if (this.employeesContainer) {
      this.employeesContainer.addEventListener('scroll', this.toggleFullyScrolled);
    }

    this.toggleFullyScrolled();
  },
  destroyed() {
    this.employeesContainer?.removeEventListener('scroll', this.toggleFullyScrolled);
  },
  methods: {
    calculateStatus(signatureStatus) {
      if (signatureStatus === 'signedPendingDownload' || signatureStatus === 'signed') {
        return 'signed';
      }

      if (signatureStatus === 'partiallySigned') {
        return 'partial';
      }

      return 'pending';
    },
    toggleFullyScrolled() {
      const el = this.employeesContainer;

      if (!el) return;

      this.isFullyScrolledBottom = el.scrollHeight === el.clientHeight + el.scrollTop;
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-up-docsEsignature-esignature-panel__body {
  min-height: calc(100vh - 110px);
  height: 100px;
  padding: 0 24px;
  overflow-x: hidden;
  overflow-y: auto;

  ::v-deep th {
    .table-column__label--sortable {
      margin-left: 0;
    }
  }
}

.follow-up-docsEsignature-esignature-panel__count-tag {
  padding: 0 !important; // Overriding default padding
  margin-top: 24px;
  margin-bottom: 20px;
  width: 125px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-m;
  font-weight: 700;
}

.follow-up-docsEsignature_no-docs-to-render__medallion {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94px !important;
  width: 94px !important;
  background-color: $sk-blue !important;
  border-radius: 50% !important;
}

.follow-up-docsEsignature_no-docs-to-render__medallion-options {
  display: inline-block;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.follow-up-docsEsignature-esignature-panel__count-tag__icon {
  margin-left: 10px;
}

.follow-up-docsEsignature-esignature-panel-footer {
  position: absolute;
  bottom: 0;
  background: white;
  width: 523px;
  padding: 30px 24px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.follow-up-docsEsignature-esignature-panel-footer--on-scroll {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
}

.follow-up-docsEsignature_no-docs-to-render {
  width: 80%;
  text-align: center;
  margin: 100px auto;

  .body {
    color: $sk-grey !important;
  }
}

.loading-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.submit-wrapper {
  margin-left: 10px;
}
</style>
