<template>
  <SkModal
    id="add-employee-modal"
    ref="addEmployeeModal"
    :modal-title="$t('employees.add_employee_modal.title')"
    :modal-subtitle="modalSubtitle"
    :submit-disabled="!firstStepIsValid"
    :previous-step-button-label="$t('employees.add_employee_modal.labels.previous_step')"
    :next-step-button-label="$t('employees.add_employee_modal.labels.next_step')"
    :steps="2"
    :tracking-options="trackingOptions"
    size="large"
    show-progress-bar
    @cancel="handleCancel"
    @close="handleCancel"
    @show="handleShow"
    @go-to-next-step="validateFirstStep"
    @update-step="setCurrentStep"
  >
    <template #body>
      <div v-show="localStep === 1">
        <ProfileSection
          ref="profileSection"
          @performing-request="handleEmailRequest"
        />
        <ContractDataSection />
      </div>
      <div v-if="localStep === 2">
        <ClusterAffiliationsStep v-if="clustersOrganisation" />
        <AffiliationsStep
          v-else
          :organisation-licenses="organisationLicenses"
        />
        <VariableContractHoursSection
          v-if="isVariableContractHoursSectionDisplayed"
          :employee-cyclic-amendments="employeeCyclicAmendments"
        />
        <SkCard
          v-if="emailFilledIn"
          class="add-employee-modal__affiliation-invitation-card"
        >
          <SkCheckBox v-model="sendInvitation" />
          <div class="add-employee-modal__affiliation-invitation-card-explanation">
            <div>
              {{ $t('employees.add_employee_modal.affiliations.send_invitation.title') }}
            </div>
            <p class="sk-subtitle--small">
              {{ $t('employees.add_employee_modal.affiliations.send_invitation.subtitle') }}
            </p>
          </div>
        </SkCard>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-if="localStep === 2"
        data-test="add-employee-modal-submit-btn"
        :loading="loading"
        @click="handleSubmit"
      >
        {{ $t('employees.add_employee_modal.labels.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

import { capitalize } from '@skello-utils/formatting/strings';
import { httpClient } from '@skello-utils/clients';
import {
  isValidPhoneNumber,
  isValidEmail,
} from '@skello-utils/validators';
import skDate from '@skello-utils/dates';
import { getContractTypeKeyText } from '@app-js/users/shared/utils/contract_types_helper';

import ProfileSection from './ProfileSection';
import ContractDataSection from './ContractDataSection';
import AffiliationsStep from './AffiliationsStep';
import ClusterAffiliationsStep from './ClusterAffiliationsStep.vue';
import VariableContractHoursSection from './VariableContractHoursSection.vue';

export default {
  name: 'AddEmployeeModal',
  components: {
    ProfileSection,
    ContractDataSection,
    AffiliationsStep,
    ClusterAffiliationsStep,
    VariableContractHoursSection,
  },
  data() {
    return {
      localStep: null,
      originalData: null,
      sendInvitation: true,
      isValidating: false,
      employeeCyclicAmendments: [],
    };
  },
  computed: {
    ...mapState('newEmployee', {
      newEmployee: state => state.newEmployee,
      contract: state => state.newEmployee.relationships.contract,
      error: state => state.error,
      loading: state => state.loading,
    }),
    ...mapState('config', ['config']),
    ...mapState('config', {
      dailyCounter: state => state.config.contract_data.counter_type_daily,
    }),
    ...mapState('navContext', ['navContext']),
    ...mapState('employees', ['employees']),
    ...mapState('currentUser', ['currentUser', 'organisationLicenses']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable']),

    clustersOrganisation() {
      return this.currentOrganisation.attributes.clusters;
    },
    dayRateCounterTypeSelected() {
      return this.contract.attributes.counterType === this.dailyCounter;
    },
    selectedContractType() {
      if (!this.contract.attributes.contractTypeId) return null;

      return this.config.contract_data.contract_types
        .find(ct => ct.id === this.contract.attributes.contractTypeId);
    },
    selectedContractTypeName() {
      if (!this.selectedContractType) {
        return '';
      }

      const contractTypeCountry = this.selectedContractType.country;
      const contractTypeKey = this.selectedContractType.key;

      return getContractTypeKeyText(contractTypeCountry, contractTypeKey);
    },
    interimContractTypeSelected() {
      if (!this.selectedContractType) return false;

      return this.selectedContractType.category === 'interim';
    },
    firstStepIsValid() {
      if (this.interimContractTypeSelected) {
        return this.isPersonalDataValid && !this.error && !this.isValidating;
      }

      return this.isPersonalDataValid &&
        this.selectedContractType &&
        this.areCounterTypesInfoValid &&
        !this.error &&
        !this.isValidating;
    },
    isPersonalDataValid() {
      const { attributes } = this.newEmployee;
      return attributes.firstName && attributes.lastName &&
        (!attributes.email || isValidEmail(attributes.email)) &&
        (!attributes.phoneNumber || isValidPhoneNumber(attributes.phoneNumber));
    },
    areCounterTypesInfoValid(state) {
      const { contract } = this.newEmployee.relationships;
      // Check workDays or contractHours according to counterType
      if (contract.attributes.counterType === this.dailyCounter) {
        return contract.attributes.workDays && contract.attributes.workDays > 0;
      }
      return contract.attributes.contractHours && contract.attributes.contractHours > 0;
    },
    contractValueLabel() {
      if (this.interimContractTypeSelected) return '';
      return (
        this.dayRateCounterTypeSelected ?
          this.$t('employees.add_employee_modal.subtitle.per_year_unit', { value: this.contract.attributes.workDays }) :
          this.$t('employees.add_employee_modal.subtitle.per_hour_unit', { value: this.contract.attributes.contractHours })
      );
    },
    modalSubtitle() {
      const { firstName, lastName } = this.newEmployee.attributes;

      if (!firstName || !lastName || this.localStep === 1) {
        return '';
      }

      let separator = '';
      if (this.contract.attributes.contractTypeId) {
        separator = '\u2022';
      }

      return (
        `${capitalize(firstName)}
         ${capitalize(lastName)} ${separator}
         ${this.selectedContractTypeName} ${this.contractValueLabel}`
      );
    },
    trackingOptions() {
      return {
        close: 'add_employee_close',
        previous: 'add_employee_previous',
        submit: 'add_employee_submit',
        next: 'add_employee_next',
      };
    },
    emailFilledIn() {
      return !!this.newEmployee.attributes.email;
    },
    isVariableContractHoursSectionDisplayed() {
      return this.isVariableContractHoursAvailable && this.employeeTeamSchedule &&
        !this.interimContractTypeSelected && !this.dayRateCounterTypeSelected;
    },
    employeeTeamSchedule() {
      const teamMembershipWithTeamSchedule = this.newEmployee.relationships.teamMemberships.find(
        tm => tm.attributes.teamSchedules.length > 0,
      );

      if (!teamMembershipWithTeamSchedule) return null;

      return teamMembershipWithTeamSchedule.attributes.teamSchedules[0];
    },
  },
  watch: {
    employeeTeamSchedule(newValue) {
      this.employeeCyclicAmendments = [];
      if (!newValue || this.interimContractTypeSelected || this.dayRateCounterTypeSelected) return;

      for (let index = 0; index < newValue.attributes.weekCycle; index += 1) {
        this.employeeCyclicAmendments.push({
          attributes: {
            hours: null,
            scheduleWeekIndex: index,
            teamScheduleId: newValue.id,
            startsAt: skDate().startOf('isoWeek').format('YYYY-MM-DD'),
            endsAt: newValue.attributes.endDate,
          },
        });
      }
    },
  },
  methods: {
    ...mapActions('newEmployee', ['createNewEmployee']),
    ...mapActions('selectedEmployee', ['fetchEmployee']),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop']),
    ...mapMutations('employees', { resetEmployeesStore: 'reset' }),
    ...mapMutations('selectedEmployee', ['setEmployeeWithTeamScheduleSelected']),
    ...mapMutations('newEmployee', ['setPrimaryNode', 'reset']),
    handleEmailRequest(newValue) {
      this.isValidating = newValue;
    },
    validateFirstStep(event) {
      if (!this.firstStepIsValid) {
        event.preventDefault();
      }
    },
    closeAndResetModal() {
      this.$refs.addEmployeeModal.hide();
      this.reset();
      this.$refs.profileSection.resetData();
      // Reset employeeWithTeamScheduleSelected for new employee
      this.setEmployeeWithTeamScheduleSelected({ employeeId: -1, value: false });
    },
    handleCancel(event) {
      if (JSON.stringify(this.originalData) !== JSON.stringify(this.newEmployee)) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: () => {
            this.closeAndResetModal();
          },
        });
      }
    },
    async handleShow() {
      const standardEmployeeLicense =
        last(orderBy(this.organisationLicenses, 'attributes.position'));

      if (this.navContext.viewAllShops || !this.navContext.shopId) {
        const params = { can_create_employee: true };

        await httpClient
          .get('/v3/api/cluster_nodes/first_shop_node', { params })
          .then(response => {
            this.setPrimaryNode({ clusterNode: response.data.data, standardEmployeeLicense });
          });
      } else {
        await httpClient
          .get(`/v3/api/cluster_nodes/${this.navContext.clusterNodeId}`)
          .then(response => {
            this.setPrimaryNode({ clusterNode: response.data.data, standardEmployeeLicense });
          });
      }

      this.originalData = cloneDeep(this.newEmployee);
    },

    handleSubmit() {
      if (this.loading) return;

      const amendmentParams = this.employeeCyclicAmendments.map(amendment => ({
        hours: amendment.attributes.hours ?
          amendment.attributes.hours :
          this.contract.attributes.contractHours,
        schedule_week_index: amendment.attributes.scheduleWeekIndex,
        team_schedule_id: amendment.attributes.teamScheduleId,
        starts_at: amendment.attributes.startsAt,
        ends_at: amendment.attributes.endsAt,
      }));

      this.createNewEmployee({
        organisation: this.currentOrganisation,
        sendInvitation: this.sendInvitation,
        amendments: amendmentParams,
      })
        .then(createdEmployee => {
          // Go to shops/:id/employees/:id page of the newly created employee
          const shopId = createdEmployee.attributes.shopId;
          const employeeId = createdEmployee.id;
          this.updateCurrentShop({ shopId })
            .then(() => {
              this.selectShop(this.currentShop);

              // The 'reload' flag ensures the employee sidebar is reloaded,
              // even if shopId is already the currentShop
              this.$router.push({
                name: 'user_personal',
                params: { user_id: employeeId, shop_id: this.currentShop.id, reload: true },
              });
            });

          this.$skAnalytics.track('add_employee_submit');
          this.closeAndResetModal();

          this.$skToast({
            message: this.$t('employees.add_employee_modal.submit_success_message'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          if (error.data) {
            this.$skToast({
              message: error.data.message,
              variant: 'error',
              containerId: 'employees__container',
            });
          } else {
          // Display all types of errors
            this.$skToast({
              message: error,
              variant: 'error',
              containerId: 'employees__container',
            });
          }
        });
    },
    setCurrentStep(currentStep) {
      this.localStep = currentStep;
    },
  },
};
</script>
<style lang="scss">
#add-employee-modal {
  .sk-modal__footer {
    padding: 30px 25px 24px !important;
  }

  .add-employee-modal__affiliation-invitation-card {
    display: flex;
    align-items: center;
    width: 720px;
    height: 65px;
    padding: 0 20px !important;
    margin: auto;
  }

  .add-employee-modal__affiliation-invitation-card-explanation {
    padding-left: 30px;
  }
}
</style>
