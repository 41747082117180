<template>
  <div>
    <div class="optimization__postes_structure">
      <div class="optimization__postes__headers">
        {{ $t('optimization.sidebar.poste_rules') }}
      </div>
      <div class="optimization__postes_structure__calculation">
        {{ $t('optimization.sidebar.poste_structures.calculation_method.title') }}
        <SkSelectV2
          v-model="selectedType"
          :options="typeOptions"
          width="154px"
          class="optimization__postes_structure__select"
        />
      </div>
      <div
        v-if="isTemplateStructureSelected"
        class="optimization__postes_structure__calculation"
      >
        {{ $t('optimization.sidebar.poste_structures.calculation_method.subtitle') }}
        <SkSelectV2
          v-model="selectedTemplateId"
          :options="selectTemplateOptions"
          width="154px"
          class="optimization__postes_structure__select"
          @input="handleTemplateClick"
        />
      </div>
      <div
        v-if="displayPosteStructure"
        class="optimization__postes_structure__container"
      >
        <div class="optimization__postes__headers">
          {{ $t('optimization.sidebar.poste_structures.shop_structure') }}
        </div>
        <div
          v-for="poste in postesOpti"
          :key="poste.id"
          class="optimization__postes_structure__wrapper"
        >
          <SkCollapse
            :ref="`poste_collapse_${poste.id}`"
            @open="handleOpenPosteCollapse(poste.id)"
            @close="handleClosePosteCollapse(poste.id)"
          >
            <template #labelLeft>
              <div>
                <span
                  class="optimization__postes_structure__post-dot"
                  :style="{ background: `${poste.attributes.color}`}"
                />
                <div class="optimization__postes_structure__post-name">
                  {{ poste.attributes.name }}
                </div>
              </div>
            </template>

            <template #content>
              <div class="optimization__postes_structure__content">
                <div
                  v-if="isPosteStructureLoading"
                  class="optimization__postes_structure--content-loading"
                >
                  <SkLoader size="large" />
                </div>
                <PosteTable
                  v-else
                  :poste-id="parseInt(poste.id, 10)"
                  :poste-structure="selectedPosteStructures[poste.id]"
                />
                <div class="postes_structure__button-container">
                  <SkOroraButton
                    class="postes_structure__button"
                    size="small"
                    :disabled="disabledSubmit(poste.id)"
                    @click="handleSubmit"
                  >
                    {{ $t('optimization.sidebar.poste_structures.action.save') }}
                  </SkOroraButton>
                </div>
              </div>
            </template>
          </SkCollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapState,
  mapActions,
} from 'vuex';

import PosteTable from './PosteTable';

export default {
  name: 'Postes',
  components: {
    PosteTable,
  },
  data() {
    return {
      typeOptions: [
        { id: 'manual', text: this.$t('optimization.sidebar.poste_structures.calculation_method.manual') },
        { id: 'template', text: this.$t('optimization.sidebar.poste_structures.calculation_method.template') },
      ],
      selectedType: null,
      selectedTemplateId: null,
      editedPosteStructure: {},
      openedPosteCollapseId: null,
    };
  },
  computed: {
    ...mapState('planningsTemplates', ['templates']),
    ...mapState('planningsOptimisation', [
      'postesOpti',
      'optiStructure',
      'templatesOpti',
      'posteStructure',
      'requestPending',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['postes']),
    selectedPosteStructures() {
      return this.isManualStructureSelected ?
        this.editedPosteStructure.manual :
        this.editedPosteStructure.template;
    },
    isManualStructureSelected() {
      return this.selectedType === 'manual';
    },
    isTemplateStructureSelected() {
      return this.selectedType === 'template';
    },
    isPosteStructureLoading() {
      return this.requestPending.updatePosteStructure;
    },
    selectTemplateOptions() {
      return this.templatesOpti.map(({ attributes }) => ({
        id: attributes.id,
        text: attributes.name,
      }));
    },
    displayPosteStructure() {
      return this.isManualStructureSelected ||
      (this.isTemplateStructureSelected && this.selectedTemplateId);
    },
  },
  created() {
    this.editedPosteStructure = cloneDeep(this.posteStructure);
  },
  mounted() {
    this.selectedType = this.typeOptions[0].id;
    this.listenOnRoot('save-new-cell-value', params => this.saveNewCellValue(params));
  },
  methods: {
    ...mapActions('planningsOptimisation', ['fetchTemplatePosteStructure', 'updatePosteStructure']),
    saveNewCellValue(params) {
      const tmp = { ...this.editedPosteStructure[this.selectedType] };
      tmp[params.posteId][params.rowIndex][params.cellIndex] = params.cellValue;
      this.$set(this.editedPosteStructure, this.selectedType, tmp);
    },
    disabledSubmit(posteId) {
      const posteStructureToString =
        JSON.stringify(this.posteStructure[this.selectedType][posteId]);
      const editedPosteStructureToString =
        JSON.stringify(this.editedPosteStructure[this.selectedType][posteId]);
      return (posteStructureToString ===
        editedPosteStructureToString) || this.isPosteStructureLoading;
    },
    handleSubmit() {
      const params = {
        shop_id: this.currentShop.id,
        calculation_method: this.selectedType,
        planning_template_id: this.selectedTemplateId,
        poste_structure: this.selectedPosteStructures,
      };
      this.updatePosteStructure(params)
        .then(() => {
          this.editedPosteStructure = cloneDeep(this.posteStructure);
          this.$skToast({
            message: this.$t('optimization.sidebar.poste_structures.action.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('optimization.sidebar.poste_structures.action.error'),
            variant: 'error',
          });
        });
    },
    handleTemplateClick() {
      if (this.selectedTemplateId) {
        this.fetchTemplatePosteStructure(this.selectedTemplateId)
          .then(() => {
            this.editedPosteStructure = cloneDeep(this.posteStructure);
            this.closePosteCollapse();
          });
      }
    },
    closePosteCollapse() {
      if (this.openedPosteCollapseId) {
        this.$refs[`poste_collapse_${this.openedPosteCollapseId}`][0].closeCollapse();
        this.openedPosteCollapseId = null;
      }
    },
    handleOpenPosteCollapse(posteId) {
      this.closePosteCollapse();
      this.openedPosteCollapseId = posteId;
    },
    handleClosePosteCollapse(posteId) {
      if (this.openedPosteCollapseId !== posteId) return;

      this.openedPosteCollapseId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.optimization__postes_structure {
  width: 100%;

  &--content-loading {
    height: 320px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 50%;
  }
}

::v-deep .optimization__postes_structure svg.sk-select__caret {
  margin: 20px 5px auto auto;
}

::v-deep .sk-collapse__label {
  padding: 24px 24px 18px !important;
}

.optimization__postes_structure__container {
  margin-top: 20px;

  .optimization__postes__headers {
    padding-bottom: 20px;
  }
}

.optimization__postes__headers {
  font-size: $fs-text-m;
  color: $sk-black;
  font-weight: $fw-semi-bold;
  margin: 0;
  text-align: start;
}

.optimization__structure__padding {
  padding-top: 10px;
}

.optimization__postes_structure__wrapper {
  box-shadow: 0 8px 36px rgba(0, 0, 0, .08);
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.optimization__postes_structure__content {
  padding: 0 24px 24px;
  width: 362px;
}

.postes_structure__button-container {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}

.postes_structure__button {
  height: 24px;
}

.optimization__postes_structure__calculation {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optimization__postes_structure__post-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.optimization__postes_structure__post-name {
  font-size: $fs-text-m;
  display: inline;
  margin-left: 5px;
}
</style>
