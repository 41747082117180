<template>
  <SkModal
    :id="`manage-note-modal-${day.date}`"
    ref="manageNoteModal"
    :modal-title="updating ?
      $t('plannings.table.add_note_modal.header.update_note_title') :
      $t('plannings.table.add_note_modal.header.add_note_title')
    "
    :modal-subtitle="date"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <SkMedallion
        icon="ClipboardIcon"
        color="#727272"
        background-color="#f3f3f3"
      />
    </template>
    <template #body>
      <SkModalSection
        border-bottom="none"
        class="manage_note_modal__body"
      >
        <div class="manage_note_modal__title">
          {{ $t('plannings.table.add_note_modal.body.note') }}
        </div>
        <SkTextarea
          ref="noteTextarea"
          v-model="note"
          :label="$t('plannings.table.add_note_modal.body.placeholder')"
          :max-height="99"
          :min-height="54"
          rows="3"
          auto-grow
        />
      </SkModalSection>
    </template>
    <template
      v-if="updating"
      #cancel-btn
    >
      <SkOroraButton
        variant="secondary"
        :variant-color="$skColors.skError"
        icon="FilledTrashCanIcon"
        :loading="eventDeleteLoading"
        @click="handleDestroy"
      >
        {{ $t('actions.delete') }}
      </SkOroraButton>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="eventManagingLoading"
        :disabled="disableSubmit"
        @click="handleSubmit"
      >
        {{ updating ? $t('actions.update') : $t('actions.add') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'ManageNoteModal',
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updating: false,
      date: null,
      note: null,
    };
  },
  computed: {
    ...mapState('planningsState', ['eventManagingLoading', 'eventDeleteLoading']),
    disableSubmit() {
      return !this.note || this.note.trim() === '';
    },
    addingNote() {
      return false;
    },
  },
  methods: {
    ...mapActions('planningsState', ['createEvent', 'updateEvent', 'destroyEvent']),
    handleDestroy() {
      this.$skAnalytics.track('alert_deleted');

      this.destroyEvent(this.day.event.id)
        .then(() => {
          this.emitOnRoot(MODAL_HIDE_EVENT, null, `manage-note-modal-${this.day.date}`);
          this.$skToast({
            message: this.$t('plannings.table.add_note_modal.actions.destroy.success'),
            variant: 'success',
          });
        });
    },
    handleSubmit() {
      if (this.updating) {
        this.$skAnalytics.track('alert_updated');
        this.updateEvent({ id: this.day.event.id, event: { note: this.note } })
          .then(() => {
            this.emitOnRoot(MODAL_HIDE_EVENT, null, `manage-note-modal-${this.day.date}`);
            this.$skToast({
              message: this.$t('plannings.table.add_note_modal.actions.update.success'),
              variant: 'success',
            });
          });
      } else {
        this.$skAnalytics.track('alert_or_notification_sent_to_the_user');
        this.createEvent({ event: { date: this.day.date, note: this.note } })
          .then(() => {
            this.emitOnRoot(MODAL_HIDE_EVENT, null, `manage-note-modal-${this.day.date}`);
            this.$skToast({
              message: this.$t('plannings.table.add_note_modal.actions.create.success'),
              variant: 'success',
            });
          });
      }
    },
    handleShow() {
      this.note = this.day.event ? this.day.event.attributes.note : null;
      this.date = skDate(this.day.date).format('dddd Do MMMM YYYY');
      this.updating = !!this.note;

      this.$nextTick(() => {
        this.$refs.noteTextarea.focus();
      }, 0);
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, `manage-note-modal-${this.day.date}`);
    },
  },
};

</script>

<style lang="scss" scoped>
.manage_note_modal__title {
  padding-right: 198px;
  font-weight: $fw-semi-bold;
}

// Capitalize date
::v-deep .sk-modal__subtitle {
  text-transform: capitalize;
}

// Design specs to reduce space between header and subtitle
::v-deep .sk-modal__header .sk-modal__subtitle {
  margin-top: 0;
}

.manage_note_modal__body {
  display: flex;
  align-items: center;
}

// This to sk-modal__header : design specs
::v-deep .sk-modal__section {
  padding-bottom: 5px;
}

::v-deep footer.sk-modal__footer {
  padding-bottom: 24px;
}

::v-deep .sk-modal__header {
  height: 76px;
}
</style>
