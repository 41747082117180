<template>
  <div>
    <div
      v-if="isLoadingRulesFetch"
      class="automatic-planning__spinner"
    >
      <SkLoader size="large" />
    </div>
    <SkCard
      v-else
      class="automatic-planning__card-container"
    >
      <div
        v-for="ruleKey in ruleKeys"
        :key="ruleKey"
        class="automatic-planning__card"
      >
        <div class="automatic-planning-header_wrapper">
          <h2 class="sk-header--2 automatic-planning__card__header">
            {{ $t(`shop_settings.tabs.automatic_planning.card.titles.${ruleKey}`) }}
          </h2>
          <CircledQuestionMarkIcon
            v-if="infoMessage(ruleKey)"
            v-tooltip="infoMessage(ruleKey)"
            class="automatic-planning-header_info"
            height="18"
            width="18"
          />
        </div>
        <SkTable
          :columns="headers"
          class="automatic-planning__table"
        >
          <RulesRow
            v-for="automaticPlanningRule in sortedRules(ruleKey)"
            :key="automaticPlanningRule.attributes.kind"
            :automatic-planning-rule="automaticPlanningRule"
            :disabled="isRuleDisabled(automaticPlanningRule)"
            :error-message="errorMessage(automaticPlanningRule)"
            :include-input="isRuleWithInput(automaticPlanningRule)"
            :include-check-box="isRuleWithCheckBox(automaticPlanningRule)"
            @toggle-active="handleRuleToggled"
            @toggle-optional-value="handleCheckBoxRuleToggled"
            @change="handleRuleValueChanged"
          />
        </SkTable>
      </div>
    </SkCard>
    <StickyBar
      :visible="showStickyBar"
      :submit-button-label="$t('shop_settings.sticky_bar.submit')"
      :submit-spinner="isLoadingUpdate"
      :disabled="erroredRuleKinds.length > 0"
      container-scroll-id="shop-settings__container"
      class="shop-settings-rules__container__sticky-bar"
      @submit="handleSubmitValues"
    />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

import StickyBar from '../../../../shared/components/Stickybar';
import RulesRow from './RulesRow';

const RULES_WITH_INPUTS = [
  'max_day_straight_nb',
  'min_daily_hours',
  'max_coupure_hours',
  'max_coupure_nb',
  'max_daily_hours',
  'daily_amplitude',
  'daily_rest_min_hours',
  'weekly_rest',
  'max_weekly_overtime_full_time',
  'max_weekly_overtime_part_time',
];

const SORTED_RULES = {
  availability: ['availabilities', 'unavailabilities'],
  workTime: [
    'max_day_straight_nb',
    'daily_rest_min_hours',
    'min_daily_hours',
    'max_coupure_hours',
    'max_coupure_nb',
    'max_daily_hours',
    'daily_amplitude',
    'weekly_rest',
  ],
  overtime: [
    'no_overtime_full_time',
    'no_overtime_part_time',
    'max_weekly_overtime_full_time',
    'max_weekly_overtime_part_time',
  ],
};

export default {
  name: 'Rules',
  components: { StickyBar, RulesRow },
  data() {
    return {
      isLoadingUpdate: false,
      ruleKeys: Object.keys(SORTED_RULES),
      headers: [
        { title: this.$t('shop_settings.tabs.automatic_planning.table.headers.activate') },
        { title: this.$t('shop_settings.tabs.automatic_planning.table.headers.rule_type') },
      ],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('automaticPlanning', ['automaticPlanningRules', 'originalAutomaticPlanningRules', 'isLoadingRulesFetch']),
    showStickyBar() {
      const currentValues = this.automaticPlanningRules.map(rule => rule.attributes.value);
      const newValues = this.originalAutomaticPlanningRules.map(rule => rule.attributes.value);
      return JSON.stringify(currentValues) !== JSON.stringify(newValues);
    },
    disabledRuleKinds() {
      // If rule "no overtime allowed" is active,
      // Then rule "allow x hours of overtime" cannot also be active.
      // This is true for part time and full time
      return [
        { noOvertimeKind: 'no_overtime_full_time', maxOvertimeKind: 'max_weekly_overtime_full_time' },
        { noOvertimeKind: 'no_overtime_part_time', maxOvertimeKind: 'max_weekly_overtime_part_time' },
      ].map(({ noOvertimeKind, maxOvertimeKind }) => {
        const noOvertimeRule = this.ruleWithKind(noOvertimeKind);
        const maxOvertimeRule = this.ruleWithKind(maxOvertimeKind);
        if (noOvertimeRule.attributes.active) {
          return maxOvertimeKind;
        }
        if (maxOvertimeRule.attributes.active) {
          return noOvertimeKind;
        }
        return '';
      });
    },
    // Return rule errored if both min_daily_hours and max_daily_hours are active,
    // and min_daily_hours > max_daily_hours
    erroredRuleKinds() {
      const erroredKinds = [];

      const minDailyHourRule = this.ruleWithKind('min_daily_hours');
      const maxDailyHourRule = this.ruleWithKind('max_daily_hours');

      if (
        (minDailyHourRule && maxDailyHourRule) &&
        (minDailyHourRule.attributes.active && maxDailyHourRule.attributes.active)
      ) {
        const maxDailyHours = maxDailyHourRule.attributes.value;
        const minDailyHours = minDailyHourRule.attributes.value;

        if (minDailyHours > maxDailyHours) {
          erroredKinds.push('min_daily_hours');
        }
      }

      return erroredKinds;
    },
    shopId() {
      return this.currentShop.id;
    },
  },
  methods: {
    ...mapActions('automaticPlanning', [
      'toggleRule',
      'updateRules',
      'toggleOptionalValue',
    ]),
    ...mapMutations('automaticPlanning', ['ruleValueChanged', 'ruleOptionalValueChanged']),
    ruleWithKind(kind) {
      return this.automaticPlanningRules.find(rule => rule.attributes.kind === kind);
    },
    isRuleWithInput(rule) {
      return RULES_WITH_INPUTS.includes(rule.attributes.kind);
    },
    isRuleWithCheckBox(rule) {
      return rule.attributes.kind === 'weekly_rest' && rule.attributes.value > 1;
    },
    isRuleDisabled(automaticPlanningRule) {
      return this.disabledRuleKinds.includes(automaticPlanningRule.attributes.kind);
    },
    isRuleErrored(rule) {
      return this.erroredRuleKinds.includes(rule.attributes.kind);
    },
    errorMessage(rule) {
      if (!this.isRuleErrored(rule)) return '';

      return this.$t(`shop_settings.tabs.automatic_planning.rule_errors.${rule.attributes.kind}`);
    },
    infoMessage(ruleKey) {
      const translationKey = `shop_settings.tabs.automatic_planning.card.infos.${ruleKey}`;
      // Check if key exists in i18n
      return this.$te(translationKey) ? this.$t(translationKey) : '';
    },
    handleRuleToggled(ruleId) {
      this
        .toggleRule({ ruleId, shopId: this.shopId })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_failure'),
            variant: 'error',
          });
        });
    },
    handleCheckBoxRuleToggled(ruleId) {
      this
        .toggleOptionalValue({ ruleId, shopId: this.shopId })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_failure'),
            variant: 'error',
          });
        });
    },
    handleRuleValueChanged(ruleId, newValue) {
      const automaticPlanningRule = this.automaticPlanningRules.find(rule => rule.id === ruleId);
      if (this.isRuleWithInput(automaticPlanningRule) && !newValue) newValue = 0;
      this.ruleValueChanged({ ruleId, newValue });
    },
    handleOptionalValueChanged(ruleId, newValue) {
      this.ruleOptionalValueChanged({ ruleId, newValue });
    },
    sortedRules(ruleKey) {
      const ruleKinds = SORTED_RULES[ruleKey]; // get the rule name (for example 'no_overtime_full_time')
      const rules = ruleKinds.map(this.ruleWithKind); // get the rule associated with that kind
      return rules.filter(kinds => kinds); // filter out null or undefined
    },
    handleSubmitValues() {
      this
        .updateRules({ shopId: this.shopId })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.automatic_planning.notices.submit_failure'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning__card-container {
  padding-bottom: 20px;
}

.automatic-planning__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}

.automatic-planning__card {
  margin-top: 18px;
}

.automatic-planning__card__header {
  margin: 5px -10px;
}

.automatic-planning__table {
  margin-bottom: 0;
}

.automatic-planning-header_wrapper {
  display: flex;
  align-items: center;
}

.automatic-planning-header_info {
  margin-left: 25px;
}

.shop-settings-rules__container__sticky-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
