<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle
        cx="39"
        cy="31"
        r="15"
        fill="#2b66fe"
      />
    </g>
    <path
      d="M44.0065 38H34.9166C33.8582 38 33 37.1418 33 36.0834V30.0705C33 29.012 33.8582 28.1538 34.9166 28.1538H44.0065C45.0649 28.1538 45.9231 29.012 45.9231 30.0705V36.0834C45.9231 37.1418 45.0649 38 44.0065 38Z"
      fill="#CBD2DB"
    />
    <path
      d="M44.0063 28.1538H39.4614V38H44.0063C45.0648 38 45.923 37.1418 45.923 36.0834V30.0705C45.923 29.012 45.0648 28.1538 44.0063 28.1538Z"
      fill="#dddddd"
    />
    <path
      d="M39.4617 34.3077C38.954 34.3077 38.5386 33.8923 38.5386 33.3846V31.5385C38.5386 31.0308 38.954 30.6154 39.4617 30.6154C39.9693 30.6154 40.3847 31.0308 40.3847 31.5385V33.3846C40.3847 33.8923 39.9693 34.3077 39.4617 34.3077Z"
      fill="#2b66fe"
    />
    <path
      d="M34.9167 28.1538H36.077V26.1538C36.077 24.5422 37.5954 23.2308 39.4616 23.2308C41.3277 23.2308 42.8462 24.5422 42.8462 26.1538V28.1538H44.0065C44.0302 28.1538 44.0533 28.1566 44.077 28.1572V26.1538C44.077 23.8692 42 22 39.4616 22C36.9231 22 34.8462 23.8692 34.8462 26.1538V28.1572C34.8699 28.1566 34.893 28.1538 34.9167 28.1538Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="78"
        height="78"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.658854 0 0 0 0 0.770165 0 0 0 0 0.958333 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Lock',
};
</script>
