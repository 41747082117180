<template>
  <SkOroraPopoverFilter
    ref="skOroraPopoverFilter"
    :title="$t('filters.popover.title')"
    :placeholder="$t('filters.popover.search_placeholder')"
    :left-button-label="$t('filters.popover.footer.delete')"
    :no-results-message="$t('filters.popover.no_results_message')"
    :right-button-label="rightButtonLabel()"
    :loading="loading"
    :filters="filters"
    :are-filters-active="areFiltersActive"
    :selected-options.sync="selectedItems"
    :debounce-method="trackingSearch"
    :debounce-ms="1000"
    :left-button-disabled="leftButtonDisabled || loading"
    :right-button-disabled="isRightButtonDisabled"
    :left-button-tooltip="leftButtonTooltip"
    @submit-filters="handleSubmit"
    @handle-clear="handleClear"
    @hide-popover="resetInitialSelectedItems"
  />
</template>

<script>
import { mapState } from 'vuex';

import { getI18nAbsenceKey } from '@app-js/shared/utils/absences_helper';
import {
  getOptionsEmployees,
  getOptionsTeams,
  getOptionsPostes,
  getOptionsAbsences,
  getOptionsStatuses,
  getOptionsShops,
  getOptionsAvailabilityTypes,
} from '@app-js/shared/utils/filters/get_options';

export default {
  name: 'FiltersPopover',
  components: {
  },
  props: {
    dataSources: {
      type: Object,
      required: true,
    },
    submitHandler: {
      type: Function,
      required: true,
    },
    initialSelectedItems: {
      type: Object,
      default: () => ({}),
    },
    areFiltersActive: {
      type: Boolean,
      default: false,
    },
    rightButtonDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: {},
      // To refracto in mixin
      filters_map: {
        teams: {
          filterKey: 'team_ids',
          title: this.$t('filters.popover.options.teams'),
          optionBuilder: getOptionsTeams,
        },
        users: {
          filterKey: 'user_ids',
          title: this.$t('filters.popover.options.employees'),
          optionBuilder: getOptionsEmployees,
        },
        postes: {
          filterKey: 'poste_ids',
          title: this.$t('filters.popover.options.postes'),
          optionBuilder: getOptionsPostes,
        },
        absences: {
          filterKey: 'absence_ids',
          title: this.$t('filters.popover.options.absences'),
          optionBuilder: getOptionsAbsences,
          builderConfig: {
            labelFormatter: (absenceKey, absencesCountry) => {
              const i18nKey = getI18nAbsenceKey(
                absencesCountry || this.currentShop.attributes.absencesCountry,
                `${absenceKey}.name`,
              );
              return this.$t(i18nKey);
            },
          },
          sort: (a, b) => a.label.localeCompare(b.label),
        },
        statuses: {
          filterKey: 'status',
          title: this.$t('filters.popover.options.status'),
          optionBuilder: getOptionsStatuses,
          builderConfig: {
            labelFormatter: status => this.$t(`requests.statuses.${status}`),
          },
        },
        availabilityTypes: {
          filterKey: 'availability_type',
          title: this.$t('filters.popover.options.availability_types'),
          optionBuilder: getOptionsAvailabilityTypes,
          builderConfig: {
            labelFormatter: availabilityType => this.$t(`requests.availability_types.${availabilityType}`),
          },
        },
        shops: {
          filterKey: 'shop_ids',
          title: this.$t('filters.popover.options.shops'),
          optionBuilder: getOptionsShops,
        },
      },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    filters() {
      return Object.entries(this.dataSources).map(([sourceName, sourceData]) => {
        const mapper = this.filters_map[sourceName];
        const filter = {
          filterKey: mapper.filterKey,
          title: mapper.title,
        };

        if (mapper.builderConfig) {
          filter.options = mapper.optionBuilder(sourceData, mapper.builderConfig);
        } else {
          filter.options = mapper.optionBuilder(sourceData);
        }

        if (mapper.sort) {
          filter.options = filter.options.sort(mapper.sort);
        }

        filter.options =
            this.addOptionsInitialSelectedItemsOnTop(
              mapper.optionBuilder(
                sourceData,
                mapper.builderConfig ? mapper.builderConfig : undefined,
              ),
              this.initialSelectedItems[mapper.filterKey] || [],
            );

        return filter;
      });
    },
    leftButtonDisabled() {
      return Object
        .entries(this.selectedItems)
        .every(([_, value]) => Array.isArray(value) && value.length === 0);
    },
    leftButtonTooltip() {
      return this.leftButtonDisabled ? this.$t('filters.popover.footer.tooltip_delete') : '';
    },
    isRightButtonDisabled() {
      return this.rightButtonDisabled || this.hasNoResults() || this.loading;
    },
    trackingApplySelectedOptions() {
      const counts = {};
      const keyMapping = {
        team_ids: 'teams_selected',
        user_ids: 'users_selected',
        employeeIds: 'users_selected',
        poste_ids: 'postes_selected',
        absence_ids: 'absences_selected',
        status: 'statuses_selected',
        availability_type: 'availability_types_selected',
        shop_ids: 'shops_selected',
      };
      Object.entries(this.selectedItems).forEach(([key, value]) => {
        if (!Object.keys(keyMapping).includes(key)) return;
        const newKey = keyMapping[key];
        counts[newKey] = value?.length ?? 0;
      });
      return counts;
    },
  },
  watch: {
    initialSelectedItems: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.selectedItems = { ...this.selectedItems, ...newValue };
      },
    },
  },
  methods: {
    addOptionsInitialSelectedItemsOnTop(options, initialSelectedItems) {
      return [...options].sort((a, b) => {
        const aIndex = initialSelectedItems.indexOf(a.id);
        const bIndex = initialSelectedItems.indexOf(b.id);
        return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
      });
    },
    handleSubmit() {
      this.submitHandler(this.selectedItems);
      this.$skAnalytics.track(
        'filters_applied',
        {
          ...this.trackingApplySelectedOptions,
          shop_id: this.currentShop.id,
          user_id: this.currentUser.id,
          source: this.source,
          opened_at: this.$refs.skOroraPopoverFilter.openedAt.toISOString(),
          applied_at: new Date().toISOString(),
          with_search: !!this.$refs.skOroraPopoverFilter.searchValue,
        },
      );
      this.$refs.skOroraPopoverFilter.handleResetSearch();
      this.$refs.skOroraPopoverFilter.hidePopover();
    },
    handleClear() {
      this.selectedItems = {};
      this.$skAnalytics.track('filters_clear', { shop_id: this.currentShop.id, user_id: this.currentUser.id });
    },
    handleInitialAccordionState() {
      this.$refs.skOroraPopoverFilter.handleInitialAccordionState();
    },
    resetInitialSelectedItems() {
      this.selectedItems = this.initialSelectedItems;
    },
    hasNoResults() {
      return this.$refs.skOroraPopoverFilter && this.$refs.skOroraPopoverFilter.hasNoResults;
    },
    rightButtonLabel() {
      return this.hasNoResults() ? this.$t('filters.popover.footer.no_result') : this.$t('filters.popover.footer.submit');
    },
    filtersLoaded() {
      this.$refs.skOroraPopoverFilter.$emit('filters-loaded');
    },
    trackingSearch() {
      const search = this.$refs.skOroraPopoverFilter.searchValue;
      this.$skAnalytics.track(
        'filters_search',
        {
          search_value: search,
          search_length: search.length,
          shop_id: this.currentShop.id,
          user_id: this.currentUser.id,
        });
    },
  },
};
</script>
