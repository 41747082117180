<template>
  <!--
    Copied from app/javascript/src/v3/onboarding/organisation_creation/pages/ShopForm.vue
    If you need to make a change make it to both
  -->
  <div class="col-md-4 offset-md-4 mx-auto shop-info__page">
    <div class="shop-info__header">
      <div class="shop-info__header__title">
        <h1 class="sk-header--1">
          {{ $t('organisation_settings.shop_form.title') }}
        </h1>
      </div>
      <p class="sk-subtitle--large">
        {{ $t('organisation_settings.shop_form.subtitle') }}
      </p>
    </div>
    <div class="shop-info__form">
      <div class="row">
        <div class="col shop-info__form-title">
          <h3 class="sk-header--3">
            {{ $t('organisation_settings.shop_form.form_title_1') }}
          </h3>
        </div>
        <div class="col shop-info__form-helper">
          <p class="sk-subtitle--medium">
            {{ $t('organisation_settings.shop_form.helper.beggining') }}
            <span class="shop-info__form-helper-blue">*</span>
            {{ $t('organisation_settings.shop_form.helper.end') }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <SkInput
            v-model="shop.name"
            :label="$t('organisation_settings.shop_form.shop_name')"
            :error-message="$t('organisation_settings.shop_form.error.mandatory')"
            :errored="errors.name"
            @keyup="validateAttribute($event, 'name')"
          >
            <template #icon>
              <CircledQuestionMarkIcon
                v-tooltip="$t('organisation_settings.shop_form.tooltip.name')"
                class="shop-info__helper"
              />
            </template>
          </SkInput>
        </div>
        <div class="col-6">
          <SkInput
            v-model="shop.denominationSociale"
            :label="$t('organisation_settings.shop_form.shop_social_denomination')"
            :error-message="denominationSocialeErrorMessage"
            :errored="errors.denominationSociale"
            @keyup="validateAttribute($event, 'denominationSociale')"
          >
            <template #icon>
              <CircledQuestionMarkIcon v-tooltip="$t('organisation_settings.shop_form.tooltip.social_denomination')" />
            </template>
          </SkInput>
        </div>
      </div>
      <div class="row shop-info__form-row">
        <div class="col">
          <SkInput
            v-model="shop.address"
            :label="$t('organisation_settings.shop_form.address')"
            :error-message="$t('organisation_settings.shop_form.error.mandatory')"
            :errored="errors.address"
            @keyup="validateAttribute($event, 'address')"
          />
        </div>
      </div>
      <div class="row shop-info__form-row">
        <div class="col">
          <SkInput
            v-model="shop.zipcode"
            :label="$t('organisation_settings.shop_form.zip_code')"
            :error-message="$t('organisation_settings.shop_form.error.mandatory')"
            :errored="errors.zipcode"
            @keyup="validateAttribute($event, 'zipcode')"
          />
        </div>
        <div class="col">
          <SkInput
            v-model="shop.city"
            :label="$t('organisation_settings.shop_form.city')"
            :error-message="$t('organisation_settings.shop_form.error.mandatory')"
            :errored="errors.city"
            @keyup="validateAttribute($event, 'city')"
          />
        </div>
      </div>
      <div class="row shop-info__form-row">
        <div class="col">
          <SkSelectV2
            v-model="country"
            :options="countries"
            :label="$t('organisation_settings.shop_form.country')"
            :no-results-label="$t('search_bar.no_result')"
            :search-placeholder="$t('labels.search')"
          />
        </div>
        <div
          v-if="isRegionDisplayed"
          class="col"
        >
          <SkSelectV2
            v-model="shop.countryRegion"
            :label="$t('shop_settings.tabs.shop.address.labels.region')"
            :options="regionsFor(shop.country)"
            :search-placeholder="$t('labels.search')"
            :no-results-label="$t('search_bar.no_result')"
          />
        </div>
      </div>
      <div class="row shop-info__form-row">
        <div class="col">
          <SkInput
            v-model="shop.siret"
            :label="$t('organisation_settings.shop_form.siret')"
            :error-message="$t('organisation_settings.shop_form.error.mandatory')"
            :errored="errors.siret"
            @keyup="validateAttribute($event, 'siret')"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col shop-info__form-title">
        <h3 class="sk-header--3">
          {{ $t('organisation_settings.shop_form.form_title_2') }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <SkSelectV2
          v-model="shop.conventionName"
          :label="$t('organisation_settings.shop_form.convention')"
          :options="conventions"
          :no-results-label="$t('search_bar.no_result')"
        >
          <template #selected-option="{ value }">
            <span v-tooltip="showConventionTooltip(value)">
              {{ truncateConvention(value, 30) }}
            </span>
          </template>
          <template #option="{ option }">
            <span v-tooltip="showConventionTooltip(option)">
              {{ truncateConvention(option, 60) }}
            </span>
          </template>
        </SkSelectV2>
      </div>
      <div class="col-5">
        <SkInputGroup
          class="shop-info__form-timepicker_label"
          separator
        >
          <template #prepend>
            <SkTimePicker
              ref="openingTime"
              v-model="openingTime"
              readonly
              :label="$t('organisation_settings.shop_form.working_hours')"
            />
          </template>
          <template>
            <div class="shop-info__timepicker-divider">
              -
            </div>
          </template>
          <template #append>
            <!-- label=" " is a hack of SkInputGroup to share label for two inputs
            the shared label is :label="$t('organisation_settings.shop_form.working_hours')" -->
            <SkTimePicker
              ref="closingTime"
              v-model="closingTime"
              readonly
              label=" "
            />
          </template>
        </SkInputGroup>
      </div>
      <div class="col">
        <CircledQuestionMarkIcon
          v-tooltip="$t('organisation_settings.shop_form.tooltip.opening_hours')"
          class="shop-info__helper shop-info__helper--full_height"
        />
      </div>
    </div>
    <div class="row shop-info__last_row">
      <div class="col">
        <SkOroraButton
          class="shop-info__button"
          variant="tertiary"
          @click="goBack"
        >
          {{ $t('organisation_settings.shop_form.cancel') }}
        </SkOroraButton>
      </div>
      <div class="col shop-info__submit-button">
        <SkOroraButton
          :disabled="isDisabled"
          :loading="loading"
          class="shop-info__button"
          @click="handleSubmit"
        >
          {{ $t('organisation_settings.shop_form.submit') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { REGION_KEYS } from '@app-js/shared/constants/region-keys.js';
import {
  countryKeys,
  languageToCountryCode,
} from '@skello-utils/country-keys.js';
import { truncateString } from '@skello-utils/formatting/strings';
import { arrayToSelectOptions } from '@skello-utils/form';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import skDate from '@skello-utils/dates';
import { getErrorMessage } from '@skello-utils/errors';

export default {
  name: 'ShopForm',
  beforeRouteLeave(to, _from, next) {
    if (this.unsavedChangesToShop && !to.params.skipBeforeRouteLeave) {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.shop = cloneDeep(this.originalShopData);
          next();
        },
        submitColor: this.$skColors.skBlue50,
      });
    } else {
      next();
    }
  },
  props: {
    parentClusterNodeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      errors: {
        name: false,
        denominationSociale: false,
        address: false,
        zipcode: false,
        city: false,
        siret: false,
        openingTime: false,
        closingTime: false,
      },
      shop: {
        name: '',
        denominationSociale: '',
        siret: '',
        address: '',
        zipcode: '',
        city: '',
        country: '',
        countryRegion: '',
        openingTime: '00:00',
        closingTime: '00:00',
        conventionName: '',
      },
      loading: false,
      originalShopData: {},
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('config', ['countryConventions']),
    isDisabled() {
      const { shop } = this;

      return !(
        shop.name &&
        shop.denominationSociale &&
        shop.denominationSociale.trim().length >= 3 &&
        shop.address &&
        shop.zipcode &&
        shop.city &&
        shop.conventionName &&
        shop.siret &&
        !this.errorsOnTimepicker
      );
    },
    openingTime: {
      get() {
        return skDate(this.shop.openingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.shop.openingTime = skDate(newValue).format('HH:mm');
      },
    },

    closingTime: {
      get() {
        return skDate(this.shop.closingTime, 'HH:mm').format();
      },

      set(newValue) {
        this.shop.closingTime = skDate(newValue).format('HH:mm');
      },
    },
    conventions() {
      return this.countryConventions(
        this.shop.country,
        this.shop.conventionName,
      ).map(convention => ({
        id: convention.name,
        text: convention.legible_name,
      }));
    },
    defaultUserCountryCode() {
      return languageToCountryCode(this.currentUser.attributes.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return arrayToSelectOptions(countryList, value => this.$t(`countries.${value}`));
    },
    errorsOnTimepicker() {
      // When loading the view with navigation keys there is
      // no refs we don't want to throw an error then
      if (!this.$refs.openingTime || !this.$refs.closingTime) { return false; }

      return this.$refs.openingTime.errored && this.$refs.closingTime.errored;
    },
    unsavedChangesToShop() {
      if (this.organisationDoNotPay) return false;

      const originalShop = cloneDeep(this.originalShopData);

      return JSON.stringify(this.shop) !== JSON.stringify(originalShop);
    },
    isBillingOnShop() {
      if (this.organisationDoNotPay) return false;

      return this.currentOrganisation.attributes.paymentEntity === this.config.payment_entities[1];
    },
    organisationDoNotPay() {
      return !!this.currentOrganisation.attributes.doNotPay;
    },
    denominationSocialeErrorMessage() {
      if (
        this.shop.denominationSociale &&
        this.shop.denominationSociale.trim().length > 0 &&
        this.shop.denominationSociale.trim().length < 3
      ) {
        return this.$t('organisation_settings.shop_form.error.mandatory_length', { min: 3 });
      }
      return this.$t('organisation_settings.shop_form.error.mandatory');
    },
    isRegionDisplayed() {
      return ['ES', 'DE'].includes(this.country);
    },
    country: {
      get() {
        return this.shop.country;
      },
      set(newValue) {
        this.shop.country = newValue;
        this.shop.countryRegion = '';
      },
    },
  },
  mounted() {
    this.fetchCurrentOrganisation()
      .then(() => {
        httpClient
          .get(`/v3/api/shops/${this.currentOrganisation.attributes.lastShopId}`)
          .then(response => {
            const payload = response.data;
            const shop = payload.data;
            const convention = associationMatcherItem(shop, payload.included, {
              key: 'convention',
              type: 'convention',
            });

            this.shop.conventionName = convention.attributes.name;
            this.shop.openingTime = shop.attributes.openingTime;
            this.shop.closingTime = shop.attributes.closingTime;
            if (!shop.attributes.configured) {
              this.shop.name = shop.attributes.name;
              this.shop.denominationSociale = shop.attributes.denominationSociale;
              this.shop.siret = shop.attributes.siret;
              this.shop.address = shop.attributes.address;
              this.shop.zipcode = shop.attributes.zipcode;
              this.shop.city = shop.attributes.city;
              this.shop.id = shop.id;
              this.shop.countryRegion = shop.attributes.countryRegion;
              this.shop.country = shop.attributes.country;
            }
            this.originalShopData = cloneDeep(this.shop);
          });
        this.updateShopCreationCurrentStep('shop');
      });

    this.shop.country = this.defaultUserCountryCode;
  },
  methods: {
    ...mapMutations('config', ['updateShopCreationCurrentStep']),
    ...mapActions('currentOrganisation', ['fetchCurrentOrganisation']),
    goBack() {
      const defaultRouteName = this.currentOrganisation.attributes.clusters ? 'organisation_settings_clusters' : 'organisation_settings_shops';

      if (this.shop.id) {
        httpClient
          .delete(`/v3/api/shops/${this.shop.id}`)
          .then(() => {
            this.$router.push({ name: defaultRouteName });
          });
      } else if (this.$route.hash === '#onboarding') {
        this.$router.push({
          name: 'plannings_weeks',
          params: { shop_id: this.currentOrganisation.attributes.lastShopId },
        });
      } else {
        this.$router.push({ name: defaultRouteName });
      }
    },
    validateForm() {
      this.$emit('submit');
    },
    truncateConvention(string, length) {
      return truncateString(this.getConventionText(string), length);
    },
    showConventionTooltip(string) {
      const conventionText = this.getConventionText(string);

      return conventionText.length > 50 ? conventionText : null;
    },
    getConventionText(string) {
      return string.text ? string.text : this.conventions.find(
        convention => convention.id === string,
      ).text;
    },
    validateAttribute(event, attribute) {
      if (attribute === 'denominationSociale') {
        this.errors[attribute] = !(this.shop[attribute] && this.shop[attribute].trim().length >= 3);
      } else {
        this.errors[attribute] = !this.shop[attribute];
      }
    },
    handleSubmit(event) {
      this.$skAnalytics.track('account_creation_shop_submit', { source: 'global settings' });
      if (this.loading) return;

      this.loading = true;

      this.createOrUpdateShop()
        .then(async response => {
          this.shop.id = response.data.id;
          this.originalShopData = cloneDeep(this.shop);

          if (this.isBillingOnShop) {
            this.updateShopCreationCurrentStep('billing_step');
            localStorage.setItem('createdShop', JSON.stringify(this.shop));
            this.$router.push({
              name: 'organisation_settings_shops_new_billing_form',
              params: { shop: this.shop, parentClusterNodeId: this.parentClusterNodeId },
              hash: this.$route.hash,
            });
          } else {
            // TODO Add a call to notify a new shop to stripe
            this.updateShopToConfigured();
            this.emitOnRoot(MODAL_SHOW_EVENT, event, 'shop-form__congratulation-modal');
            this.clearShopData();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearShopData() {
      Object.assign(this.$data, this.$options.data());
    },
    createOrUpdateShop() {
      this.loading = true;

      const params = {
        shop_info: {
          name: this.shop.name,
          denomination_sociale: this.shop.denominationSociale,
          siret: this.shop.siret,
          country: this.shop.country,
          country_region: this.shop.countryRegion,
          address: this.shop.address,
          zipcode: this.shop.zipcode,
          city: this.shop.city,
          parent_cluster_node_id: this.parentClusterNodeId,
        },
        shop: {
          opening_time: this.shop.openingTime,
          closing_time: this.shop.closingTime,
        },
        convention: { name: this.shop.conventionName },
      };

      // update, when user come back from billing step
      if (this.shop.id) {
        return httpClient
          .patch(`/v3/api/shops/${this.shop.id}`, params)
          .then(response => response.data)
          .catch(error => {
            this.$skToast({
              message: getErrorMessage(error, this.$t('errors.standard_message')),
              variant: 'error',
            });

            throw error;
          });
      }

      return httpClient
        .post('/v3/api/shops', params)
        .then(async response => {
          // Creating a new shop changes the managed shops of the user
          // thus we need to refresh the JWT token to reflect this change,
          // since this info might be used when calling microservices
          await authClient.forceRefreshToken();

          return response.data;
        })
        .catch(error => {
          this.$skToast({
            message: getErrorMessage(error, this.$t('errors.standard_message')),
            variant: 'error',
          });

          throw error;
        });
    },
    updateShopToConfigured() {
      const params = {
        shop: {
          configured: true,
        },
      };

      return httpClient
        .patch(`/v3/api/shops/${this.shop.id}`, params)
        .catch(error => {
          throw error;
        });
    },
    regionsFor(country) {
      return arrayToSelectOptions(REGION_KEYS[country], value => this.$t(`regions.${country}.${value}`));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-select__dropdown-menu {
  width: 430px !important;
}

.shop-info__form-helper-blue {
  color: $sk-blue;
}

.shop-info__form-title {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 18px;
}

.shop-info__form-helper {
  margin-top: 35px;
  margin-bottom: 20px;
}

.sk-subtitle--medium {
  font-size: $fs-text-m;
  line-height: 17px;
  text-align: right;
  color: $sk-grey;
}

.shop-info__header__title {
  margin-bottom: 9px;
}

.shop-info__submit-button {
  text-align: right;
}

.shop-info__page {
  margin-top: 50px;
  max-width: 572px;
}

.shop-info__button {
  margin-top: 40px;
}

.shop-info__helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  position: relative;
  bottom: 4px;
  top: 0;
}

.shop-info__header {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.shop-info__form {
  padding-bottom: 30px;
  border-bottom: 1px solid $sk-grey-10;
}

.shop-info__form-row {
  margin-top: 10px;
}

.shop-info__last_row {
  margin-bottom: 50px;
}

.shop-info__form-timepicker_label {
  ::v-deep .sk-input-group {
    height: 45px;
  }
}

.shop-info__helper--full_height {
  height: 100%;
}

.shop-info__timepicker-divider {
  padding-top: 10px;
}
</style>
