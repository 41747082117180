<template>
  <SkModalSection class="day-options-modal____content">
    <div class="day-options-modal____content-title">
      {{ $t('plannings.toolbar.modal.display_options.day_selection.title') }}
    </div>
    <div class="day-options-modal__medallions">
      <div class="day-options-modal__medallions-wrapper">
        <SkMedallion
          v-for="(day, index) in visibleDays"
          :key="index"
          class="day-options-modal__medallion"
          size="large"
          :text="medallionLetters(day.date)"
          :background-color="backgroundMedallionColor(day.selectedStatus)"
          :color="contentMedallionColor(day.selectedStatus)"
          :data-test="dataTest(index)"
          @click.native="toggleVisibleDaySelection(index, day.selectedStatus)"
        />
      </div>
      <SkOroraButton
        variant="tertiary"
        size="small"
        class="day-options-modal__toggle-all"
        @click="toggleAllVisibleDaysSelection"
      >
        {{ toggleAllLabel }}
      </SkOroraButton>
    </div>
  </SkModalSection>
</template>

<script>
import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'DayOptionsSelection',
  props: {
    visibleDays: {
      type: Array,
      required: true,
    },
  },
  computed: {
    toggleAllLabel() {
      return this.$t('plannings.toolbar.modal.display_options.day_selection.select_all');
    },
    isOnlyOneDaySelected() {
      return this.visibleDays.filter(visibleDay => !visibleDay.selectedStatus).length >= 6;
    },
    isEveryDaySelected() {
      return this.visibleDays.filter(visibleDay => visibleDay.selectedStatus).length === 7;
    },
  },
  methods: {
    backgroundMedallionColor(selectedStatus) {
      return selectedStatus ? '#2b66fe' : '#dddddd';
    },
    contentMedallionColor(selectedStatus) {
      return selectedStatus ? '#FFFFFF' : '#727272';
    },
    medallionLetters(date) {
      return capitalize(date);
    },
    toggleVisibleDaySelection(index, selectedStatus) {
      if (this.isOnlyOneDaySelected && selectedStatus) {
        this.$skToast({
          message: this.$t('plannings.toolbar.modal.display_options.day_selection.warning'),
          variant: 'error',
        });
        return;
      }

      this.$emit('update-visible-days', {
        index,
        selectedStatus: !selectedStatus,
      });
    },
    dataTest(index) {
      return `display-options__day-selection-${index + 1}`;
    },
    toggleAllVisibleDaysSelection() {
      this.visibleDays.forEach((_, index) => {
        this.$emit('update-visible-days', {
          index,
          selectedStatus: true,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.day-options-modal____content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.day-options-modal____content-title {
  width: 200px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  margin-top: 10px;
}

.day-options-modal__medallions {
  margin-bottom: 2px;
}

.day-options-modal__medallions-wrapper {
  display: flex;
  position: relative;
}

.day-options-modal__medallion {
  margin: 0 10px 0 0;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
}

.day-options-modal__toggle-all {
  margin-top: 10px;
}
</style>
