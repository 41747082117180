import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';

const initialState = () => ({
  newEmployee: {
    id: null,
    type: 'user',
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      shopId: null,
    },
    relationships: {
      shop: {
        attributes: {
          name: '',
          country: '',
        },
      },
      contract: {
        attributes: {
          contractTypeId: null,
          counterType: '',
          contractHours: null,
          workDays: null,
        },
      },
      userLicenses: [],
      memberships: [],
      teamMemberships: [],
      automaticPlanningCompetencies: [],
    },
  },
  activeNodes: [],
  licenseOnClusterId: null,
  error: null,
  loading: false,
});

// Used for removing in planning gmembership
const markForDestructionOrRemoveFromCollection = (collection, item) => {
  if (item.id) {
    Vue.set(item, '_destroy', true);
  } else {
    const index = collection.indexOf(item);
    collection.splice(index, 1);
  }
};

// This function is used to get the outer section between a collection of items and a selection of ids
// using a discriminating attribute id in the collection
// eg: outerSelection([{ key: 1}, { key: 2 }, { key: 3 }], [2, 3], 'key') => { key: 1 }
const outerSectionOfExistingAndSelected = (collection, selectionIds, discriminatingAttributeId) => {
  const castedSelectionIds = selectionIds.map(item => Number(item));
  return collection.filter(item => {
    const castedAttributeId = Number(item.attributes[discriminatingAttributeId]);
    return !castedSelectionIds.includes(castedAttributeId);
  });
};

const mutations = {
  performingCreateEmployeeRequest(state) {
    state.loading = true;
  },

  createEmployeeRequestComplete(state) {
    state.loading = false;
  },

  setNewEmployeeAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.newEmployee.attributes[attribute] = payload[attribute];
    });
  },

  setContractAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.newEmployee.relationships.contract.attributes[attribute] = payload[attribute];
    });
  },

  updateLicenseOnClusterNodeId(state, id) {
    state.licenseOnClusterId = id;
  },

  setPrimaryNode(state, payload) {
    state.newEmployee.relationships.userLicenses = [];
    state.newEmployee.relationships.memberships = [];
    state.newEmployee.relationships.teamMemberships = [];

    state.newEmployee.attributes.shopId = payload.clusterNode.attributes.shopId;
    state.newEmployee.relationships.shop.attributes.name = payload.clusterNode.attributes.name;
    state.newEmployee.relationships.shop.attributes.country =
      payload.clusterNode.attributes.country;

    state.activeNodes = [{
      id: payload.clusterNode.id,
      attributes: {
        shopId: payload.clusterNode.attributes.shopId ?
          String(payload.clusterNode.attributes.shopId) : null,
        clusterNodeId: payload.clusterNode.id,
        name: payload.clusterNode.attributes.name,
        parentId: String(payload.clusterNode.attributes.parentId),
        depth: payload.clusterNode.attributes.depth,
        ancestry: payload.clusterNode.attributes.ancestry,
        ancestorIds: payload.clusterNode.attributes.ancestorIds,
        descendantIds: payload.clusterNode.attributes.descendantIds,
        automaticPlanningUses: payload.clusterNode.attributes.automaticPlanningUses,
        automaticPlanningQuota: payload.clusterNode.attributes.automaticPlanningQuota,
      },
    }];

    state.newEmployee.relationships.userLicenses.push({
      id: null,
      type: 'userLicense',
      attributes: {
        shopId: payload.clusterNode.attributes.shopId ?
          String(payload.clusterNode.attributes.shopId) : null,
        licensePosition: Number(payload.standardEmployeeLicense.attributes.position),
        licenseId: String(payload.standardEmployeeLicense.id),
        licenseName: payload.standardEmployeeLicense.attributes.name,
        clusterNodeId: payload.clusterNode.id,
      },
    });

    state.newEmployee.relationships.memberships.push({
      id: null,
      type: 'membership',
      attributes: {
        shopId: payload.clusterNode.attributes.shopId ?
          String(payload.clusterNode.attributes.shopId) : null,
        inPlanning: true,
        name: payload.clusterNode.attributes.name,
        clusterNodeId: payload.clusterNode.id,
      },
    });
  },

  addActiveNodes(state, { nodes, lowestLicense, existingAdminSystemUserLicense }) {
    const selectedNodeIds = nodes.map(node => node.id);

    const existingUserLicenses =
      state.newEmployee.relationships.userLicenses.filter(userLicense => (
        selectedNodeIds.includes(userLicense.attributes.clusterNodeId)
      ));

    nodes.forEach(node => {
      const existingUserLicense = existingUserLicenses.find(userLicense => (
        userLicense.attributes.clusterNodeId === node.id
      ));

      if (!existingUserLicense) {
        state.activeNodes.push({
          id: node.id,
          type: 'activeNode',
          attributes: {
            shopId: node.attributes.shopId,
            name: node.attributes.name,
            clusterNodeId: node.id,
            parentId: node.attributes.parentId,
            depth: node.attributes.depth,
            ancestry: node.attributes.ancestry,
            shopsCount: node.attributes.shopsCount,
            descendantIds: node.attributes.descendantIds,
            ancestorIds: node.attributes.ancestorIds,
            automaticPlanningUses: node.attributes.automaticPlanningUses,
            automaticPlanningQuota: node.attributes.automaticPlanningQuota,
          },
        });

        state.newEmployee.relationships.userLicenses.push({
          id: null,
          type: 'userLicense',
          attributes: {
            clusterNodeId: node.id,
            shopId: node.attributes.shopId,
            licensePosition: lowestLicense.attributes.position,
            licenseId: lowestLicense.id,
            licenseName: lowestLicense.attributes.name,
          },
        });

        state.newEmployee.relationships.memberships.push({
          id: null,
          type: 'membership',
          attributes: {
            shopId: node.attributes.shopId,
            inPlanning: false,
            name: node.attributes.name,
            clusterNodeId: node.id,
          },
        });
      }
    });
  },

  removeActiveNodes(state, nodes) {
    const selectedNodeIds = nodes.map(node => node.id);
    const selectedShopIds = nodes.map(node => String(node.attributes.shopId));
    const affiliatedShopNode = state.activeNodes.find(node => (
      node.attributes.shopId === String(state.newEmployee.attributes.shopId)
    ));

    // Keep track of the primary shop for an employee, as its membership should never be deleted
    const affiliatedShopNodeId = affiliatedShopNode && affiliatedShopNode.attributes.shopId;

    const activeNodesToDestroy = state.activeNodes.filter(activeNode => (
      !selectedNodeIds.includes(activeNode.id)
    ));

    const userLicensesToDestroy =
      state.newEmployee.relationships.userLicenses.filter(userLicense => (
        !selectedNodeIds.includes(userLicense.attributes.clusterNodeId)
      ));

    const membershipsToDestroy = state.newEmployee.relationships.memberships.filter(membership => (
      membership.attributes.shopId !== affiliatedShopNodeId &&
      !selectedShopIds.includes(String(membership.attributes.shopId))
    ));

    activeNodesToDestroy.forEach(activeNode => {
      const index = state.activeNodes.indexOf(activeNode);

      state.activeNodes.splice(index, 1);
    });

    userLicensesToDestroy.forEach(userLicense => {
      const index = state.newEmployee.relationships.userLicenses.indexOf(userLicense);

      state.newEmployee.relationships.userLicenses.splice(index, 1);
    });

    membershipsToDestroy.forEach(membership => {
      const index = state.newEmployee.relationships.memberships.indexOf(membership);

      state.newEmployee.relationships.memberships.splice(index, 1);
    });
  },

  promoteNewEmployeeToSystemAdmin(state, { newLicense, inClusters, rootNodeId }) {
    if (inClusters) {
      state.newEmployee.relationships.memberships = [{
        id: null,
        type: 'membership',
        attributes: {
          shopId: state.newEmployee.attributes.shopId,
          inPlanning: false,
          clusterNodeId: String(rootNodeId),
        },
      }];
    }

    state.newEmployee.relationships.userLicenses =
      state.newEmployee.relationships.userLicenses.map(ul => ({
        id: null,
        type: 'userLicense',
        attributes: {
          licenseId: newLicense.id,
          licenseName: newLicense.text,
          licensePosition: 0,
          shopId: ul.attributes.shopId,
          clusterNodeId: ul.attributes.clusterNodeId,
        },
      }));
  },

  demoteNewEmployeeFromSystemAdmin(state, { newLicense }) {
    state.newEmployee.relationships.userLicenses = [];

    state.newEmployee.relationships.memberships.forEach(mem => {
      state.newEmployee.relationships.userLicenses.push({
        id: null,
        type: 'userLicense',
        attributes: {
          licenseId: newLicense.id,
          licenseName: newLicense.text,
          licensePosition: Number(newLicense.attributes.position),
          shopId: mem.attributes.shopId,
          clusterNodeId: mem.attributes.clusterNodeId,
        },
      });
    });
  },

  updateNewEmployeeUserLicense(state, { clusterNodeId, newLicense }) {
    const userLicenseToUpdate = state.newEmployee.relationships.userLicenses.find(ul => (
      String(clusterNodeId) === String(ul.attributes.clusterNodeId)
    ));

    if (!userLicenseToUpdate) return;

    userLicenseToUpdate.attributes.licenseId = newLicense.id;
    userLicenseToUpdate.attributes.licenseName = newLicense.text;
    userLicenseToUpdate.attributes.licensePosition = newLicense.position;
  },

  manageNewEmployeeInPlanningShopNodeMemberships(state, { clusterNode, selectedNodes }) {
    const selectedNodesIds = selectedNodes.map(node => Number(node.id));

    const clusterNodeAndDescendantIds = [
      Number(clusterNode.id), ...clusterNode.attributes.descendantIds,
    ];

    const existingMemberships = state.newEmployee.relationships.memberships.filter(
      membership => (
        clusterNodeAndDescendantIds.includes(Number(membership.attributes.clusterNodeId))
      ),
    );

    const inPlanningMembershipsToDestroy = outerSectionOfExistingAndSelected(
      existingMemberships,
      selectedNodesIds,
      'clusterNodeId',
    );

    inPlanningMembershipsToDestroy.forEach(membership => {
      markForDestructionOrRemoveFromCollection(
        state.newEmployee.relationships.memberships,
        membership,
      );
    });

    selectedNodes.forEach(node => {
      const existingMembership = existingMemberships.find(membership => (
        membership.attributes.clusterNodeId === Number(node.id)
      ));
      if (existingMembership) {
        if (
          String(state.employee.attributes.shopId) === String(existingMembership.attributes.shopId)
        ) {
          existingMembership.attributes.inPlanning = true;
        } else {
          Vue.set(existingMembership, '_destroy', false);
        }
      } else {
        state.newEmployee.relationships.memberships.push({
          id: null,
          type: 'membership',
          attributes: {
            clusterNodeId: Number(node.attributes.clusterNodeId),
            inPlanning: true,
            shopId: node.attributes.shopId,
            shopName: node.attributes.shopName,
          },
        });
      }
    });
  },

  manageNewEmployeeTeamMemberships(state, { shopId, selectedTeams }) {
    const selectedTeamsIds = selectedTeams.map(team => team.id);

    const existingTeamMemberships = state.newEmployee.relationships.teamMemberships.filter(tM => (
      tM.attributes.shopId === shopId
    ));

    const teamMembershipsToDestroy = existingTeamMemberships.filter(teamMembership => (
      !selectedTeamsIds.includes(teamMembership.attributes.teamId)
    ));

    teamMembershipsToDestroy.forEach(teamMembership => {
      const index = state.newEmployee.relationships.teamMemberships.indexOf(teamMembership);

      state.newEmployee.relationships.teamMemberships.splice(index, 1);
    });

    selectedTeams.forEach(team => {
      const existingTeamMembership = existingTeamMemberships.find(teamMembership => (
        teamMembership.attributes.teamId === team.id
      ));

      if (!existingTeamMembership) {
        state.newEmployee.relationships.teamMemberships.push({
          id: null,
          type: 'teamMembership',
          attributes: {
            userId: null,
            name: team.text,
            teamId: team.id,
            shopId,
            teamSchedules: team.attributes.teamSchedules,
          },
        });
      }
    });
  },

  manageNewEmployeeCompetencies(state, { shopId, selectedPostes }) {
    const selectedPosteIds = selectedPostes.map(poste => Number(poste.id));

    const existingCompetencies =
      state.newEmployee.relationships.automaticPlanningCompetencies.filter(competency => (
        competency.attributes.shopId === Number(shopId)
      ));

    const competenciesToDestroy = existingCompetencies.filter(competency => (
      !selectedPosteIds.includes(competency.attributes.posteId)
    ));

    competenciesToDestroy.forEach(competency => {
      const index =
        state.newEmployee.relationships.automaticPlanningCompetencies.indexOf(competency);

      state.newEmployee.relationships.automaticPlanningCompetencies.splice(index, 1);
    });

    selectedPostes.forEach(poste => {
      const existingCompetency = existingCompetencies.find(competency => (
        competency.attributes.posteId === Number(poste.id)
      ));

      if (!existingCompetency) {
        state.newEmployee.relationships.automaticPlanningCompetencies.push({
          id: null,
          type: 'automaticPlanningCompetency',
          attributes: {
            userId: null,
            primary: poste.primary,
            posteId: poste.id,
            shopId,
          },
        });
      }
    });
  },

  catchEmployeeError(state, error) {
    state.error = error;
  },

  reset(state) {
    const originalState = initialState();

    Object.keys(originalState).forEach(key => {
      state[key] = originalState[key];
    });

    state.relationships = [];
  },
};

const actions = {
  createNewEmployee({ state, commit }, { organisation, sendInvitation, amendments }) {
    commit('performingCreateEmployeeRequest');

    const formattedTeamMemberships = state.newEmployee.relationships.teamMemberships.map(tm => ({
      id: null,
      team_id: tm.attributes.teamId,
    }));

    const newSystemAdmin =
      state.newEmployee.relationships.userLicenses[0].attributes.licensePosition === 0;

    let formattedUserLicenses;

    if (newSystemAdmin) {
      formattedUserLicenses = [{
        id: null,
        license_id: state.newEmployee.relationships.userLicenses[0].attributes.licenseId,
        cluster_node_id: organisation.attributes.rootNodeId,
      }];
    } else {
      formattedUserLicenses = state.newEmployee.relationships.userLicenses.map(ul => ({
        id: null,
        cluster_node_id: ul.attributes.clusterNodeId,
        license_id: ul.attributes.licenseId,
      }));
    }

    const { automaticPlanningCompetencies } = state.newEmployee.relationships;
    const formattedCompetencies = automaticPlanningCompetencies.map(
      ({ attributes }) => ({
        primary: !!attributes.primary,
        shop_id: attributes.shopId,
        poste_id: attributes.posteId,
      }),
    );

    const formattedMemberships = state.newEmployee.relationships.memberships
      .filter(membership => membership.attributes.shopId !== null)
      .map(membership => ({
        shop_id: membership.attributes.shopId,
        in_planning: membership.attributes.inPlanning,
      }));

    const params = {
      personal_info: {
        first_name: state.newEmployee.attributes.firstName,
        last_name: state.newEmployee.attributes.lastName,
        email: state.newEmployee.attributes.email,
        phone: state.newEmployee.attributes.phoneNumber,
        shop_id: state.newEmployee.attributes.shopId,
      },
      contract: {
        contract_type_id: state.newEmployee.relationships.contract.attributes.contractTypeId,
        counter_type: state.newEmployee.relationships.contract.attributes.counterType,
        contract_hours: state.newEmployee.relationships.contract.attributes.contractHours,
        work_days: state.newEmployee.relationships.contract.attributes.workDays,
        transport_cost: null,
        amendments,
      },
      associations: {
        user_licenses: formattedUserLicenses,
        team_memberships: formattedTeamMemberships,
        automatic_planning_competencies: formattedCompetencies,
      },
      memberships: formattedMemberships,
      send_invitation: sendInvitation,
    };

    return httpClient.post('/v3/api/users', params)
      .then(response => response.data.data)
      .catch(error => {
        commit('catchEmployeeError', error);
        throw error;
      })
      .finally(() => {
        commit('createEmployeeRequestComplete');
      });
  },
};

const getters = {
  isSystemAdmin(state) {
    if (state.newEmployee.relationships.userLicenses.length === 0) return false;
    return state.newEmployee.relationships.userLicenses[0].attributes.licensePosition === 0;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
