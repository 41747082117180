<template>
  <div class="flex-row">
    <div class="flex-m6">
      <AbsenteismChart />
    </div>
    <div class="flex-m6">
      <ProductivityChart />
    </div>
  </div>
</template>

<script>
import AbsenteismChart from './Charts/AbsenteismChart.vue';
import ProductivityChart from './Charts/ProductivityChart.vue';

export default {
  name: 'DashboardFirstLine',
  components: { AbsenteismChart, ProductivityChart },
};
</script>

<style lang="scss" scoped></style>
