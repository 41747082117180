<template>
  <SkModal
    id="new-add-employee-modal"
    ref="addEmployeeModal"
    :modal-title="$t('employees.add_employee_modal.new_title')"
    :cancel-button-label="$t('employees.add_employee_modal.labels.cancel')"
    @show="handleShow"
  >
    <template #body>
      <p class="modal-description">
        {{ $t('employees.add_employee_modal.description') }}
      </p>

      <SkOroraTable
        style="padding: 0 24px 24px 24px"
        :rows="employees"
        :columns="columns"
        new-entry-row
        actions-col
        :new-entry-row-label="$t('employees.add_employee.new')"
        @create-row="createEmployeeEntry"
        @delete-row="deleteEmployeeEntry"
      >
        <template #actions-data="{ row }">
          <!-- Show an empty actions td for the first row. It is not possible to delete it -->
          <div
            v-if="row.id === 0"
            style="width: 36px;"
          />
        </template>
      </SkOroraTable>
    </template>

    <template #submit-btn>
      <SkOroraButton
        :loading="loading"
        :disabled="submitDisabled || filledEmployees.length === 0"
        @click="submit"
      >
        {{ $tc('employees.add_employee_modal.new_submit', filledEmployees.length, { count: filledEmployees.length }) }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import { httpClient } from '@skello-utils/clients';
import {
  mapState, mapGetters, mapActions,
  mapMutations,
} from 'vuex';
import { isValidEmail } from '@skello-utils/validators';

export default {
  name: 'NewAddEmployeeModal',
  data() {
    return {
      loading: false,
      validatedIds: new Set(),
      nextId: 0,
      columns: [],
      employees: [],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop', 'currentUserShops']),
    ...mapState('currentUser', ['organisationLicenses']),
    ...mapGetters('config', ['hourlyRateCounterType', 'permanentContractTypes']),
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockDemoChecklist']),
    filledEmployees() {
      return this.employees.filter(e => (
        e.name.length > 0 || e.surname.length > 0 || e.email?.length > 0
      ));
    },
    firstShop() {
      if (Object.keys(this.currentUserShops).length === 0) return null;
      return this.currentUserShops[Object.keys(this.currentUserShops)[0]];
    },
    submitDisabled() {
      return this.loading || (this.currentShop.id === 'all' && !this.firstShop);
    },
    isValidInput() {
      return this.employees.every(employee => {
        const validNameAndSurname = employee.name.length > 0 && employee.surname.length > 0;
        const validEmail =
          employee.email === undefined ||
          employee.email.length === 0 ||
          isValidEmail(employee.email);

        const emptyRow = (
          employee.name.length === 0 &&
          employee.surname.length === 0 &&
          (employee.email === undefined || employee.email.length === 0)
        );

        return (validNameAndSurname && validEmail) || emptyRow;
      });
    },
  },
  mounted() {
    this.resetState();
  },
  methods: {
    ...mapActions('currentShop', ['fetchShops']),
    ...mapActions('timeclockOnboarding', ['updateStepCompletion']),
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    resetState() {
      const errorTooltip = this.$t('employees.add_employee_modal.error_tooltip');
      const placeholder = this.$t('employees.add_employee_modal.placeholder');
      const emailErrorTooltip = this.$t('employees.add_employee_modal.email_error_tooltip');

      this.columns = [
        {
          key: 'name',
          component: 'SkOroraInput',
          label: this.$t('employees.add_employee.name'),
          placeholder,
          editable: true,
          width: 192,
          required: row => this.validatedIds.has(row.id) &&
            (row.name.length === 0 && (row.surname.length > 0 || row.email?.length > 0)),
          errorTooltip,
        },
        {
          key: 'surname',
          component: 'SkOroraInput',
          label: this.$t('employees.add_employee.surname'),
          placeholder,
          editable: true,
          width: 192,
          required: row => this.validatedIds.has(row.id) &&
            (row.surname.length === 0 && (row.name.length > 0 || row.email?.length > 0)),
          errorTooltip,
        },
        {
          key: 'email',
          component: 'SkOroraInput',
          label: this.$t('employees.add_employee.email'),
          placeholder,
          editable: true,
          grow: true,
          required: row => this.validatedIds.has(row.id) &&
            (row.email?.length > 0 && !isValidEmail(row.email) &&
            (row.name.length > 0 || row.surname.length > 0)),
          errorTooltip: emailErrorTooltip,
        },
      ];

      this.loading = false;
      this.validatedIds = new Set();
      this.employees = [];
      this.nextId = 0;
      this.createEmployeeEntry();
    },
    createEmployeeEntry() {
      this.employees.push({ id: this.nextId, name: '', surname: '' });
      this.nextId += 1;
    },
    deleteEmployeeEntry(employee) {
      this.employees = this.employees.filter(e => e.id !== employee.id);
      if (this.employees.length === 0) this.createEmployeeEntry();
    },
    async submit(event) {
      event.preventDefault();
      if (!this.isValidInput) {
        this.validatedIds = new Set(this.employees.map(e => e.id));
        return;
      }

      await Promise.all(this.filledEmployees.map(employee => this.createEmployee(employee)));
      this.$skAnalytics.track('ss_checklist_add_employees');

      this.$emit('submit');

      if (this.shouldShowTimeclockDemoChecklist) {
        this.updateStepCompletion({ id: 'add_employees_step', completed: true });
        this.toggleChecklistCollapsed();
      }
    },
    async createEmployee(employee) {
      if (this.currentShop.id === 'all' && this.firstShop === null) return;

      this.loading = true;

      let contractTypeId = null;
      const contractTypesCountries =
        this.permanentContractTypes.map(contractType => contractType.country);

      if (contractTypesCountries.includes(this.currentShop.attributes.country)) {
        contractTypeId = this.permanentContractTypes.find(
          contractType => contractType.country === this.currentShop.attributes.country,
        ).id;
      } else {
        contractTypeId = this.permanentContractTypes.find(contractType => contractType.country === 'FR').id;
      }

      const standardEmployeeLicense = last(orderBy(this.organisationLicenses, 'attributes.position'));
      const shopId = this.currentShop.id === 'all' ? this.firstShop.id : this.currentShop.id;
      const clusterNodeId = this.currentShop.id === 'all' ? this.firstShop.clusterNodeId : this.currentShop.attributes.clusterNodeId;

      const params = {
        shop_id: shopId,
        personal_info: {
          first_name: employee.name,
          last_name: employee.surname,
          email: employee.email,
          shop_id: shopId,
        },
        contract: {
          contract_hours: 0,
          contract_type_id: contractTypeId,
          counter_type: this.hourlyRateCounterType,
        },
        associations: {
          user_licenses: [{
            id: null,
            license_id: standardEmployeeLicense.id,
            cluster_node_id: clusterNodeId,
          }],
          team_memberships: [],
        },
        memberships: [{
          in_planning: true,
          shop_id: shopId,
        }],
        send_invitation: false,
      };

      await httpClient.post('/v3/api/users', params);
      this.$refs.addEmployeeModal.hide();
    },
    async handleShow() {
      this.resetState();
      if (this.currentShop.id === 'all' && this.firstShop === null) {
        this.loading = true;
        await this.fetchShops();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .sk-modal__dialog.sk-modal__dialog--medium {
  width: 876px;
}

.modal-description {
  padding: 24px;
  margin-bottom: 0;
  color: $sk-grey-50;
  font-size: 16px;
}
</style>
