<template>
  <div
    :style="{
      backgroundImage: `url(${BackgroundUrl})`,
      display: 'grid',
      placeItems: 'center',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
    }"
  >
    <img
      v-if="showStartAnimation"
      :src="OnboardingVideoUrl"
      width="25%"
      style="object-fit: contain;"
    >

    <div
      v-if="showEndAnimation"
      style="display: flex;flex-direction: column;align-items: center;gap: 16px;"
    >
      <SkLoader
        v-if="!preparationFinished"
        :is-dark-mode="true"
        :custom-size="72"
      />
      <lottie-player
        v-else
        class="finish-animation"
        background="transparent"
        autoplay="true"
        :src="lottieLogo"
      />
      <i18n
        tag="span"
        class="preparation sk-display-small-semibold"
        :path="preparationTextKey"
      >
        <br>
      </i18n>
    </div>

    <Transition
      name="slide"
      :css="animateModal"
    >
      <DeviceStep
        v-if="currentStep === 0"
        @update="handleDeviceUpdate"
      />
      <qrStep
        v-else-if="currentStep === 1"
        @go-back="handleGoBack"
        @submit="onSubmitClick"
      />
    </Transition>
  </div>
</template>

<script>
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
import DeviceStep from './components/DeviceStep.vue';
import qrStep from './components/qrStep.vue';
import OnboardingVideoUrl from './assets/onboarding.gif';
import BackgroundUrl from './assets/background.png';
import FinishLottieJson from './assets/finish_lottie.json';
import '@lottiefiles/lottie-player';

export default {
  name: 'TimeclockOnboarding',
  components: { DeviceStep, qrStep },
  data: () => ({
    OnboardingVideoUrl,
    BackgroundUrl,
    showEndAnimation: false,
    showStartAnimation: true,
    preparationFinished: false,
    currentStep: 0,
    animateModal: true,
  }),
  computed: {
    ...mapState('currentShop', ['currentShop']),

    preparationTextKey() {
      return this.preparationFinished ?
        'onboarding_modal.timeclock_onboarding.splash.preparation_finished' :
        'onboarding_modal.timeclock_onboarding.splash.preparation';
    },
    lottieLogo() {
      return JSON.stringify(FinishLottieJson);
    },
  },
  mounted() {
    this.setNavbarVisibility(false);

    setTimeout(() => {
      this.showStartAnimation = false;
    }, 2000);
  },
  methods: {
    ...mapMutations(['setNavbarVisibility']),
    ...mapActions('currentShop', ['updateShop']),
    ...mapMutations('currentShop', ['updateOnboardingStatus']),

    async setNewTimeclockOnboardingStatus(key, value) {
      try {
        await this.updateOnboardingStatus({ [key]: value });
        await this.updateShop(
          {
            shopId: this.currentShop.id,
            overrideParams: { onboarding_status: this.currentShop.attributes?.onboarding_status },
          },
        );
      } catch (e) {
        this.updateOnboardingStatus({ [key]: !value });
        console.error(`Error while update ${key}`, e);
      }
    },
    handleDeviceUpdate(devicesOptions) {
      this.animateModal = false;
      if (devicesOptions.some(device => device.key === 'tablet' && device.isSelected) ||
        devicesOptions.some(device => device.key === 'mixed' && device.isSelected)) {
        this.currentStep = 1;
      } else {
        this.setNewTimeclockOnboardingStatus('tablet_selected', false);
        this.startEndAnimationAndFinish();
      }
    },
    handleGoBack() {
      this.currentStep = 0;
    },
    async startEndAnimationAndFinish() {
      this.animateModal = true;
      await this.$nextTick();
      this.currentStep = 2;
      this.showEndAnimation = true;
      await this.awaitSeconds(2);
      this.preparationFinished = true;
      await this.awaitSeconds(1);
      window.location.href = '/';
    },
    async onSubmitClick() {
      try {
        await this.setNewTimeclockOnboardingStatus('tablet_selected', true);
        this.startEndAnimationAndFinish();
      } catch (e) {
        console.error('Error while submitting', e);
      }
    },
    awaitSeconds(seconds) {
      return new Promise(resolve => {
        setTimeout(resolve, seconds * 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active, .slide-leave-active {
  transition-duration: 300ms;
}

.slide-enter-active {
  transition-delay: 2000ms;
}

.slide-enter, .slide-leave-to {
  opacity: 0;
  transform: translateY(200px);
}

.preparation {
  color: $sk-white;
  text-align: center;
}

.finish-animation {
  width: 72px;
  height: 72px;
}
</style>
