<template>
  <tr
    class="table-row"
  >
    <th class="empty-cell" />

    <template v-for="(week, weekKey, weekIdx) of monthlyVisibleWeeks">
      <th
        v-for="(day) of week.days"
        :key="`${weekKey + '-' + day.date}`"
        :class="dayCellHeaderClasses"
      >
        <HeaderDay :day-info="day" />
      </th>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <th
        :class="[weeklyCounterClasses(weekKey), `week-${weekIdx + 1}`]"
        @click="weeklyCounterClicked(weekKey)"
        @mouseenter="weeklyCounterHovered(weekIdx + 1)"
        @mouseleave="weeklyCounterHovered(null)"
      >
        <div
          class="weekly-counter-wrapper"
        >
          <component
            :is="counterColumnIcon(weekKey)"
            fill="black"
            height="24"
            width="24"
          />
          <span v-show="headerComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekKey]">
            {{ $t('plannings.monthly_v3.counters.weekly.header', { weekIdx: weekTag(weekKey) }) }}
          </span>
        </div>
      </th>
    </template>

    <template>
      <th
        ref="emptyCol"
        :style="{
          width: `max(${remainingWidth - 120}px, 0px)`,
          'border-bottom': 'none'
        }"
      />

      <th v-if="ptoActivated">
        <div class="header-counter header-counter--dropdown">
          <span class="header-counter--text">
            {{ headerDatesText }}
          </span>

          <SkSelectV2
            v-model="counterValue"
            :options="counterSelectOptions"
          />
        </div>
      </th>

      <th v-else>
        <div class="header-counter">
          <span class="header-counter--title">
            {{ $t('plannings.table.header.counters.total') }}

          </span>
          <span class="header-counter--text">
            {{ headerDatesText }}
          </span>
        </div>
      </th>
    </template>
  </tr>
</template>

<script>
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import HeaderDay from '@app-js/plannings/pages/Months/Table/Header/HeaderDay.vue';
import skDate from '@skello-utils/dates';

export default {
  name: 'TableHeader',
  components: {
    HeaderDay,
  },
  props: {
    headerComponentStoreProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      remainingWidth: 0,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['monthlyVisibleWeeks']),
    ...mapState('planningsState', ['selectedWeeklyCountersInMonthlyView']),

    counterValue: {
      get() {
        return this.headerComponentStoreProps.selectedCounter;
      },
      set(newValue) {
        this.setSelectedCounter(newValue);
      },
    },
    hasYearlyDistinction() {
      return this.headerComponentStoreProps.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER');
    },
    ptoActivated() {
      return this.headerComponentStoreProps.currentShop.attributes.plcInitialized;
    },
    headerDatesText() {
      // NOTE: The second week will always be 100% of the month we're looking at
      const secondWeek = Object.values(this.monthlyVisibleWeeks)[1];

      const firstMonthDay = skDate(secondWeek.days[0].date).startOf('month').format('DD');
      const lastMonthDay = skDate(secondWeek.days[0].date).endOf('month').format('DD');
      const month = skDate(secondWeek.days[0].date).locale('en').format('MMMM').toLowerCase();
      const parsedMonth = this.$t(`plannings.table.header.counters.short_month.${month}`);
      return `${firstMonthDay} - ${lastMonthDay} ${parsedMonth}`;
    },
    dayCellHeaderClasses() {
      return {
        'no-data': this.headerComponentStoreProps.filteredUsers.length === 0,
      };
    },
    counterSelectOptions() {
      return [
        {
          id: 'hours_worked',
          text: this.$t('plannings.monthly_v3.counters.user.total'),
        },
        {
          id: 'pto',
          text: this.hasYearlyDistinction ? this.$t('plannings.monthly_v3.counters.user.yearly_pto') : this.$t('plannings.monthly_v3.counters.user.pto'),
        },
      ];
    },
  },
  watch: {
    'headerComponentStoreProps.selectedWeeklyCountersInMonthlyView': {
      deep: true,
      async handler() {
        await this.$nextTick();
        this.updateEmptyColumnWidth();
      },
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateEmptyColumnWidth);
    this.updateEmptyColumnWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateEmptyColumnWidth);
  },
  methods: {
    ...mapMutations('planningsState', ['toggleWeeklyCounterInMonthlyView']),
    ...mapMutations('monthlyPlanning', ['setSelectedCounter']),
    updateEmptyColumnWidth() {
      if (this.$refs.emptyCol === undefined) return;

      const rect = this.$refs.emptyCol.getBoundingClientRect();
      this.remainingWidth = window.innerWidth - rect.left;
    },
    weeklyCounterClicked(weekStart) {
      // load data if opening the counter
      if (!this.selectedWeeklyCountersInMonthlyView[weekStart]) {
        this.$emit('load-week', weekStart);
      }

      this.toggleWeeklyCounterInMonthlyView(weekStart);
    },
    weeklyCounterHovered(weekIdx) {
      this.$emit('weekly-counter-hovered', weekIdx);
    },
    weeklyCounterClasses(weekStart) {
      return {
        'weekly-counter': true,
        'weekly-counter--selected': this.headerComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekStart],
      };
    },
    counterColumnIcon(weekStart) {
      return this.headerComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekStart] ? 'CaretsPinchingIcon' : 'CaretsExpandingIcon';
    },
    weekTag(weekStart) {
      return skDate(weekStart).isoWeek();
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  th {
    z-index: 5;
    width: 45px;
    // Needed so that height: 100% works in weekly-counter-wrapper.
    // The value 0 doesn't mean anything since the height is set by TableRow
    height: 0;
    background-color: $sk-white;
    border-bottom: 1px solid $sk-grey-10;
    position: sticky;
    padding: 0;
    top: 0;

    &:first-child {
      width: 160px;
      border-bottom: none;
      z-index: 10;
      left: 0;
    }

    &.no-data {
      border-bottom: none;
    }

    &.weekly-counter {
      border-bottom: none;
      width: 24px;

      &--selected {
        width: 72px;
      }
    }
  }

  &.table-row {
    th {
      &:last-child {
        width: 120px;
        border-bottom: none;
        z-index: 10;
        right: 0;
      }

      &.weekly-counter {
        cursor: pointer;
      }
    }
  }
}

.weekly-counter-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 9px;
  row-gap: 6px;
  height: 100%;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 0.3s forwards; /* 1s delay before showing */

  span {
    font-weight: $fw-regular;
    font-size: $fs-text-m;
    line-height: 15.61px;
  }

  svg {
    rotate: 90deg;
  }
}

.header-counter {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  align-items: center;

  &--dropdown {
    gap: 4px;
  }

  &--title {
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }

  &--text {
    font-size: $fs-text-s;
    font-weight: $fw-regular;
  }
}

::v-deep {
  .sk-select__selected-option {
    height: 36px;
    justify-content: space-between;
    gap: 4px;
    font-weight: $fw-regular;
  }

  .sk-dropdown__menu {
    font-weight: $fw-regular;
  }

  .sk-select__caret {
    margin: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.5; /* Start at 50% opacity */
  }
  100% {
    opacity: 1; /* End at 100% opacity */
  }
}
</style>
