<template>
  <tbody>
    <tr class="employee-shifts-table__week-info">
      <th
        class="employee-shifts-table__column__date"
        :colspan="colspan"
      >
        <span :class="weekInfoClasses">
          {{ $t('employees.employee_shifts_table.week') }} {{ weekNumber }}
        </span>
      </th>
      <th class="employee-shifts-table__column__working-time">
        {{ weekWorkingTimeText }}
      </th>
    </tr>

    <ShiftRow
      v-for="shift in shiftsInWeek(shiftType)"
      :key="shift.id"
      :shift="shift"
      :on-day-rate="onDayRate"
    />
  </tbody>
</template>

<script>
import skDate from '@skello-utils/dates';
import _ from 'lodash';
import {
  ALL_SHIFT_TYPE,
  POSTE_SHIFT_TYPE,
  ABSENCE_SHIFT_TYPE,
} from '@app-js/shared/constants/shift';
import { FEATURES } from '@app-js/shared/constants/features';
import {
  mapGetters, mapState,
} from 'vuex';
import ShiftRow from './ShiftRow';

const SHIFT_TYPES = [ALL_SHIFT_TYPE, POSTE_SHIFT_TYPE, ABSENCE_SHIFT_TYPE];

export default {
  name: 'WeekTableBody',
  components: {
    ShiftRow,
  },
  props: {
    shifts: {
      type: Array,
      required: true,
    },
    monday: {
      type: String,
      required: true,
    },
    onDayRate: {
      type: Boolean,
      default: false,
    },
    shiftType: {
      type: String,
      default: ALL_SHIFT_TYPE,
      validator: value => SHIFT_TYPES.includes(value),
    },
  },
  computed: {
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapState('currentShop', ['currentShop']),
    weekWorkingTimeText() {
      return this.onDayRate === true ?
        this.weekWorkingTimeInDaysText :
        this.weekWorkingTimeInHoursText;
    },
    weekWorkingTimeInHoursText() {
      const workedHours = this.workedHoursInWeek();
      return workedHours === 0 ?
        '-' :
        `${+workedHours.toFixed(2)}${this.$t('employees.employee_shifts_table.labels.hours')}`;
    },
    weekWorkingTimeInDaysText() {
      const workedDays = this.workedDaysInWeek();

      return workedDays === 0 ?
        '-' :
        `${+workedDays.toFixed(2)}${this.$t('employees.employee_shifts_table.labels.days')}`;
    },
    weekInfoClasses() {
      return {
        'date-text--current-week': this.weekNumber === this.currentWeekNumber,
      };
    },
    weekNumber() {
      return this.skDateMonday.isoWeek();
    },
    skDateMonday() {
      return skDate.utc(this.monday);
    },
    currentWeekNumber() {
      return skDate().isoWeek();
    },
    isPositionsFeatureEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_EMPLOYEE_POSITIONS,
        this.currentShop.id,
        () => true);
    },
    isShiftsFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_SHIFTS, this.currentShop.id, () => true);
    },
    colspan() {
      const numColumns = 8;
      const featurePerColumn = [this.isPositionsFeatureEnabled, this.isShiftsFeatureEnabled];
      const falseCount = featurePerColumn.filter(feature => !feature).length;
      return numColumns - falseCount;
    },
  },
  methods: {
    numberOfShiftsOnDay(day) {
      return this.shifts.filter(shift => skDate(shift.attributes.startsAt).format('YYYY-MM-DD') === day).length;
    },
    workedHoursInWeek() {
      const workedHoursInSeconds = this.shiftsInWeek(POSTE_SHIFT_TYPE)
        .reduce((accumulator, shift) => accumulator + shift.attributes.durationInSeconds, 0);

      return skDate.duration(workedHoursInSeconds, 's').asHours();
    },
    workedDaysInWeek() {
      const workedDays = this.shiftsInWeek(POSTE_SHIFT_TYPE)
        .reduce((accumulator, shift) => accumulator + shift.attributes.durationInDays, 0);

      return workedDays;
    },
    shiftsInWeek(shiftType) {
      const shifts = this.onDayRate ? this.shiftsWithDurationInDays(this.shifts) : this.shifts;

      return _(this.filteredShiftsWithType(shifts, shiftType))
        .filter(shift => shift.attributes.weekNumber === this.weekNumber)
        .sortBy(shift => skDate(shift.attributes.startsAt).toDate())
        .value();
    },
    filteredShiftsWithType(shifts, type) {
      switch (type) {
        case ALL_SHIFT_TYPE: return shifts;
        case POSTE_SHIFT_TYPE:
          return shifts.filter(shift => !shift.relationships.poste.attributes.absenceType);
        case ABSENCE_SHIFT_TYPE:
          return shifts.filter(shift => !!shift.relationships.poste.attributes.absenceType);
        default: return shifts;
      }
    },
    shiftsWithDurationInDays(shifts) {
      shifts.forEach(shift => {
        this.addDurationInDaysToShift(shift);
      });
      return shifts;
    },
    addDurationInDaysToShift(shift) {
      const shiftsOnSameDay = this.numberOfShiftsOnDay(skDate(shift.attributes.startsAt).format('YYYY-MM-DD'));
      shift.attributes.shiftsOnSameDay = shiftsOnSameDay;

      if (!shiftsOnSameDay || shiftsOnSameDay === 0) {
        shift.attributes.durationInDays = '';
      } else {
        shift.attributes.durationInDays = 1 / shiftsOnSameDay;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tbody {
  .employee-shifts-table__week-info {
    background: $sk-white;
  }

  .date-text--current-week {
    position: relative;

    &::after {
      cursor: initial;
      content: '';
      position: absolute;
      background-color: $sk-blue;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      top: 50%;
      transform: perspective(1px) translateY(-50%);
      right: -14px;
    }
  }
}
</style>
