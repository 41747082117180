<template>
  <img
    width="42"
    src="./images/logo-rosaly.png"
  >
</template>

<script>
export default {
  name: 'Rosaly',
};
</script>
