import { merge } from 'lodash';
import { planningDayZoom } from './zoom_day_values';
import { planningWeekZoom } from './zoom_week_values';
import { planningPosteZoom } from './zoom_poste_values';

export const zoomPlanningWeek = zoomValue => planningWeekZoom[zoomValue];

export const zoomPlanningDay = zoomValue => merge(
  // creates a shallow copy otherwise it seems planningWeekZoom
  // values are changed when zoomPlanningDay is called
  JSON.parse(JSON.stringify(planningWeekZoom[zoomValue])),
  planningDayZoom[zoomValue],
);

export const zoomPlanningPoste = zoomValue => planningPosteZoom[zoomValue];
