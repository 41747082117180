<template>
  <div class="alerts__container">
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.alerts.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t('shop_settings.tabs.alerts.subtitle') }}
    </p>
    <div
      v-if="loading"
      class="alerts__container__alerts__spinner"
    >
      <SkLoader size="large" />
    </div>
    <div v-else>
      <p
        v-if="conventionFlexible"
        class="sk-subtitle--large"
      >
        {{ $t('shop_settings.tabs.alerts.flexible.subtitle') }}
      </p>
      <SkCard
        v-if="!conventionFlexible"
        class="alerts__table__non-flexible"
      >
        <h2 class="sk-header--2">
          {{ $t('shop_settings.tabs.alerts.explanation_card.title') }}
        </h2>
        <SkTable :columns="header">
          <AlertRow
            v-for="alert in sortedAlerts"
            :key="alert.id"
            :disabled="!canEditLegalAlertsConfig"
            :alert="alert"
          />
        </SkTable>
      </SkCard>
      <div v-else>
        <SkCard class="alerts__container__editable">
          <h2 class="sk-header--2">
            {{ $t('shop_settings.tabs.alerts.flexible.explanation_card.editable.title') }}
          </h2>
          <SkTable
            :columns="header"
            class="alerts__container__editable__table"
          >
            <AlertRow
              v-for="alert in sortedEditableAlerts"
              :key="alert.id"
              :disabled="!canEditLegalAlertsConfig"
              :alert="alert"
            />
          </SkTable>
        </SkCard>
        <SkCard
          v-if="!emptyNonEditableAlerts"
          class="alerts__container__non-editable"
        >
          <h2 class="sk-header--2 alerts__explanation-card__title__header">
            {{ $t('shop_settings.tabs.alerts.flexible.explanation_card.non_editable.title') }}
          </h2>
          <SkTable
            :columns="header"
            class="alerts__container__non-editable__table"
          >
            <AlertRow
              v-for="alert in sortedNonEditableAlerts"
              :key="alert.id"
              :alert="alert"
              :disabled="!canEditLegalAlertsConfig"
            />
          </SkTable>
        </SkCard>
      </div>
      <div class="alerts__container__sticky-bar">
        <StickyBar
          :visible="showStickyBar"
          :submit-button-label="$t('shop_settings.sticky_bar.submit')"
          :submit-spinner="updatingConvention"
          :tracking-options="trackingOptions"
          :disabled="hasAlertsError"
          container-scroll-id="shop-settings__container"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import StickyBar from '../../../shared/components/Stickybar';
import AlertRow from './AlertRow';

export default {
  name: 'Alerts',
  components: { AlertRow, StickyBar },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToConvention) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: next,
      });
    } else {
      next();
    }
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('alerts', ['alerts', 'loading']),
    ...mapState('convention', ['convention', 'updatingConvention']),
    ...mapGetters('alerts', ['sortedAlerts', 'sortedEditableAlerts', 'sortedNonEditableAlerts']),
    ...mapGetters('convention', ['unsavedChangesToConvention']),
    ...mapGetters('currentLicense', ['canEditLegalAlertsConfig']),
    conventionFlexible() {
      return this.convention.attributes && this.convention.attributes.flexible;
    },
    canEditConvention() {
      return this.canEditLegalAlertsConfig;
    },
    emptyNonEditableAlerts() {
      return this.sortedNonEditableAlerts.length === 0;
    },
    header() {
      return [
        { title: this.$t('shop_settings.tabs.alerts.table.headers.activate') },
        { title: this.$t('shop_settings.tabs.alerts.table.headers.alert_type') },
        { title: this.$t('shop_settings.tabs.alerts.table.headers.blocking') },
      ];
    },
    showStickyBar() {
      return this.unsavedChangesToConvention && this.conventionFlexible && this.canEditConvention;
    },
    trackingOptions() {
      return {
        update: 'alerts_update',
      };
    },
    // If convention is flexible and some alert input fields are empty -> error
    hasAlertsError() {
      if (!this.conventionFlexible) return false;

      return this.sortedEditableAlerts.some(alert => {
        const { conventionFlexibleFieldFromAlert, fullAlert, editable } = alert.attributes;
        if (!editable || !conventionFlexibleFieldFromAlert) return false;

        const fieldsToCheck = [conventionFlexibleFieldFromAlert[0]];
        // Check if alert has second input field
        const hasSecondInput = fullAlert.second_input && !fullAlert.second_input.select;
        if (hasSecondInput) {
          fieldsToCheck.push(conventionFlexibleFieldFromAlert[1]);
        }

        return fieldsToCheck.some(field => this.convention.attributes[field] === '');
      });
    },
  },
  watch: {
    currentShop(newValue, oldValue) {
      this.fetchConvention(this.currentShop.id);
      this.fetchAlerts(this.currentShop.id);
    },
  },
  created() {
    this.fetchConvention(this.currentShop.id);
    this.fetchAlerts(this.currentShop.id);
  },
  methods: {
    ...mapActions('alerts', ['fetchAlerts']),
    ...mapActions('convention', ['fetchConvention', 'updateConvention']),
    handleSubmit() {
      if (!this.unsavedChangesToConvention) {
        this.$skToast({
          message: this.$t('shop_settings.sticky_bar.empty_submit'),
          variant: 'information',
        });
        return;
      }

      this.updateConvention(this.currentShop.id)
        .then(response => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.alerts.flexible.update_convention.success_message'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.alerts.flexible.update_convention.error_message'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.alerts__container {
  padding-bottom: 100px;

  .alerts__container__alerts__spinner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }

  .sk-card__container.alerts__table__non-flexible,
  .sk-card__container.alerts__container__editable {
    margin-top: 30px;
  }

  .alerts__container__editable.sk-card__container {
    border-radius: 4px 4px 0 0;
  }

  .alerts__container__non-editable.sk-card__container {
    border-radius: 0 0 4px 4px;
    border-top: 1px solid transparent;
  }

  .sk-header--2 {
    margin: 20px -10px 10px;
  }

  .sk-table__head th {
    padding: 10px 0;

    &:nth-child(2) {
      padding: 10px 15px;
    }
  }

  .alerts__container__editable__table tbody td,
  .alerts__container__non-editable__table tbody td,
  .alerts__table__non-flexible tbody td {
    border-bottom: none;
  }

  .alerts__container__sticky-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
