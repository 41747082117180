<template>
  <CountersCellPopover
    listener-id="annualization-detail"
    :popover-height="195"
    ref-name="annualization_detail_wrapper"
  >
    <template #popover-content="{ counters }">
      <div
        v-if="counters"
        class="counters-cell__annualization"
      >
        <div
          class="
            counters-cell__annualization-details
            counters-cell__annualization-details__title--warning"
        >
          <div class="counters-cell__annualization-details__title">
            {{ $t('plannings.table.cells.annualization.details.begin_of_week') }}
          </div>
          <div class="counters-cell__annualization-details__value-icon">
            <CounterCellIcon
              :cell-style="counters.zoomRange.icon"
              :icon-name="counters.advanceDelayBeginningOfWeek > 0 ?
                'PlusSignV2Icon' :
                'MinusSignIcon'"
              icon-color="#f0900d"
              color="#fdf0c8"
            />
            <span class="counters-cell__annualization-details__value">
              {{ formattedTime(Math.abs(counters.advanceDelayBeginningOfWeek)) }}
            </span>
          </div>
        </div>
        <div class="counters-cell__annualization-details--separator" />
        <div
          class="
            counters-cell__annualization-details
            counters-cell__annualization-details__title--warning"
        >
          <div class="counters-cell__annualization-details__title">
            {{ counters.differenceBetween > 0 ?
              $t('plannings.table.cells.annualization.details.additional_hours') :
              $t('plannings.table.cells.annualization.details.substracted_hours') }}
          </div>
          <div class="counters-cell__annualization-details__value-icon">
            <CounterCellIcon
              :cell-style="counters.zoomRange.icon"
              :icon-name="counters.differenceBetween > 0 ?
                'PlusSignV2Icon' :
                'MinusSignIcon'"
              icon-color="#f0900d"
              color="#fdf0c8"
            />
            <span class="counters-cell__annualization-details__value">
              {{ formattedTime(Math.abs(counters.differenceBetween)) }}
            </span>
          </div>
        </div>
        <div class="counters-cell__annualization-details--separator" />
        <div
          class="
            counters-cell__annualization-details
            counters-cell__annualization-details__title--warning"
        >
          <div class="counters-cell__annualization-details__title">
            {{ $t('plannings.table.cells.annualization.details.end_of_week') }}
          </div>
          <div class="counters-cell__annualization-details__value-icon">
            <CounterCellIcon
              :cell-style="counters.zoomRange.icon"
              :icon-name="counters.advanceDelayEndOfWeek > 0 ?
                'PlusSignV2Icon' :
                'MinusSignIcon'"
              icon-color="#f0900d"
              color="#fdf0c8"
            />
            <span class="counters-cell__annualization-details__value">
              {{ formattedTime(Math.abs(counters.advanceDelayEndOfWeek)) }}
            </span>
          </div>
        </div>
        <div class="counters-cell__annualization-details--separator" />
        <div class="counters-cell__annualization-details">
          <div class="counters-cell__annualization-details__title">
            {{ $t('plannings.table.cells.annualization.details.remaining_balance') }}
          </div>
          <div
            class="
            counters-cell__annualization-details__value
            counters-cell__annualization-details__value-balance"
          >
            <!-- eslint-disable-next-line max-len -->
            {{ formattedTime(counters.remainingBalance) }} / {{ formattedTime(counters.theoreticalBalance) }}
          </div>
        </div>
      </div>
    </template>
  </CountersCellPopover>
</template>

<script>
import CountersCellPopover from '@app-js/plannings/shared/components/Counter/CountersCellPopover';
import CounterCellIcon from '../CountersCell/CounterCellIcon';

export default {
  name: 'AnnualizationDetail',
  components: {
    CountersCellPopover,
    CounterCellIcon,
  },
  methods: {
    formattedTime(value) {
      if (!value) return this.$t('dates.hours_format', { hours: '00', minutes: '00' });

      const absValue = Math.abs(value);
      const sign = Math.sign(value);

      const minutes = `${Math.floor((absValue * 60) % 60)}`.padStart(2, '0');
      const hours = `${Math.floor(absValue) * sign}`.padStart(2, '0');

      return this.$t('dates.hours_format', { hours, minutes });
    },
  },
};
</script>

<style lang="scss" scoped>
.counters-cell__annualization-details {
  font-size: $fs-text-s;
  display: flex;
  justify-content: space-between;
}

.counters-cell__annualization-details--separator {
  border-bottom: 1px solid $sk-grey-10;
  margin: 12px 0;
}

.counters-cell__annualization-details__title--warning {
  color: $sk-warning;
}

.counters-cell__annualization-details__title {
  font-weight: 600;
}

.counters-cell__annualization-details__value-icon {
  display: flex;
  align-items: center;
}

.counters-cell__annualization-details__value {
  font-size: 13px;
  margin-left: 3px;
}

.counters-cell__annualization-details__value-balance {
  font-weight: 600;
}
</style>
