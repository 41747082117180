<template>
  <div :class="annualizationCounterClasses">
    <div
      v-if="isLoading || isAnnualizationLoading"
      class="counters-spinner__wrapper"
    >
      <SkLoader />
    </div>
    <template v-else>
      <div
        v-if="referencePeriod"
        class="sk-text-medium-semibold counter-header"
      >
        {{ referencePeriod }}
        <span>{{
          $t(
            'employees.tabs.counters.annualization_counter.period_bounds.suffix'
          )
        }}</span>
      </div>
      <CounterSection
        :realized-balance="userRealizedBalance"
        :remaining-balance="userRemainingBalance"
        :impacted-theoretical-balance="userImpactedTheoreticalBalance"
        :theoretical-impacting-absences="theoreticalImpactingAbsences"
        :annualization-config="annualizationConfig"
        @reload-counter-section="reloadCounter"
      />
      <div class="employee-annualization__header">
        <div class="employee-annualization__header__tag">
          <span class="sk-text-medium-regular">
            {{
              $t(
                'employees.tabs.counters.annualization_counter.initialization_date'
              )
            }}
          </span>
          <SkOroraTag variant="blue">
            {{ initializationDate }}
          </SkOroraTag>
        </div>
      </div>
      <div
        v-if="nextTheoreticalBalance"
        class="employee-annualization__header"
      >
        <div class="employee-annualization__header__tag">
          <span class="sk-text-medium-regular">{{
            $t(
              'employees.tabs.counters.annualization_counter.period_bounds.next_reference'
            )
          }}</span>
          <SkOroraTag
            variant="blue"
            size="large"
          >
            {{ `${nextTheoreticalBalance}h` }}
          </SkOroraTag>
        </div>
      </div>
      <div
        v-else
        class="employee-annualization__header"
      >
        <div class="employee-annualization__header__tag">
          <span class="sk-text-medium-regular">{{
            $t(
              'employees.tabs.counters.annualization_counter.period_bounds.reference',
              { periodBounds }
            )
          }}</span>
          <SkOroraTag
            v-if="userImpactedTheoreticalBalance"
            variant="blue"
          >
            {{ `${userImpactedTheoreticalBalance}h` }}
          </SkOroraTag>
        </div>
      </div>
    </template>
    <InitializationOverlay
      v-if="!annualizationConfig && shopAnnualizationConfig"
      :title="$t('employees.tabs.counters.annualization_counter.welcome')"
      :description="$t('employees.tabs.counters.annualization_counter.helper')"
      icon="HolidaysV2Icon"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { getKeyByClosestFutureTimestamp } from '@skelloapp/skello-annualization';

import skDate from '@skello-utils/dates';
import { isEmptyObject } from '@skello-utils/validators';
import InitializationOverlay from './InitializationOverlay';
import CounterSection from './CounterSection';

export default {
  name: 'AnnualizationCounter',
  components: { CounterSection, InitializationOverlay },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('annualization', [
      'annualizationImpactedTheoreticalBalance',
      'annualizationRealizedBalanceAt',
      'annualizationRemainingBalanceAt',
      'annualizationTheoreticalBalanceAt',
      'annualizationTheoreticalImpactingAbsencesAt',
      'employeeAnnualizationConfigs',
      'isAnnualizationLoading',
      'getClosestTheoreticalBalance',
    ]),
    ...mapGetters('amendments', [
      'activeTemporaryAmendment',
      'hasActivePermanentAmendment',
      'hasFuturePermanentAmendment',
    ]),
    ...mapGetters('currentShop', ['isAnnualizationContractHoursChangeEnabled']),
    ...mapState('annualization', [
      'annualizationData',
      'currentPeriodStartDate',
      'currentPeriodEndDate',
      'shopAnnualizationConfig',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['contract']),

    annualizationConfig() {
      return this.employeeAnnualizationConfigs[this.employee.id];
    },
    currentAnnualizationEndDatePeriod() {
      if (!this.annualizationConfig || !this.currentPeriodEndDate) {
        return undefined;
      }

      const proratedTheoreticalBalances =
        this.annualizationConfig.proratedTheoreticalBalances || {};

      let endDate;

      try {
        const startOfCurrentWeek = skDate.utc().startOf('isoWeek');
        let timestamp = getKeyByClosestFutureTimestamp(
          proratedTheoreticalBalances,
          startOfCurrentWeek.valueOf(),
        );

        if (timestamp === startOfCurrentWeek.valueOf()) {
          timestamp = getKeyByClosestFutureTimestamp(
            proratedTheoreticalBalances,
            startOfCurrentWeek.add(1, 'week').valueOf(),
          );
        }

        endDate = skDate.utc(timestamp).subtract(1, 'day').valueOf();
      } catch (error) {
        console.error(error.message);
      } finally {
        endDate = Math.min(...[endDate, this.currentPeriodEndDate.valueOf()].filter(Number));
      }

      return endDate;
    },
    periodBounds() {
      // TODO check if this condition is possible?
      if (!this.currentPeriodStartDate && !this.currentPeriodEndDate) {
        const currentYear = skDate.utc().year();

        return `01/01/${currentYear} - 31/12/${currentYear}`;
      }

      return `${this.currentPeriodStartDate.format(
        'DD MMM',
      )} - ${this.currentPeriodEndDate.format('DD MMM YYYY')}`;
    },
    annualizationCounterClasses() {
      return {
        'employee-counters__wrapper': true,
        'employee-annualization__wrapper': true,
        'employee-annualization__wrapper--loading': this.isLoading || this.isAnnualizationLoading || !this.annualizationConfig,
      };
    },
    referencePeriod() {
      if (
        !this.annualizationConfig ||
        (!this.activeTemporaryAmendment && !this.hasActivePermanentAmendment) ||
        isEmptyObject(this.annualizationConfig?.proratedTheoreticalBalances) ||
        !this.isAnnualizationContractHoursChangeEnabled
      ) {
        return null;
      }

      const balances = this.activeTemporaryAmendment ?
        this.annualizationConfig.proratedTheoreticalBalances :
        this.annualizationConfig.theoreticalBalances;
      const periodsTimestamps = Object.keys(balances);

      const currentPeriod = {};

      const sortedTimestamps = periodsTimestamps.sort((a, b) => a - b);
      sortedTimestamps.forEach((timestamp, index) => {
        if (skDate().isAfter(skDate(Number(timestamp)))) {
          currentPeriod.startDate = Number(timestamp);
          if (sortedTimestamps[index + 1]) {
            currentPeriod.endDate = Number(sortedTimestamps[index + 1]);
          } else {
            currentPeriod.endDate = this.currentPeriodEndDate;
          }
        }
      });

      return `${skDate.utc(currentPeriod.startDate).format('DD MMM')} - ${skDate
        .utc(currentPeriod.endDate)
        .subtract(1, 'd')
        .format('DD MMM YYYY')}`;
    },
    initializationDate() {
      if (!this.shopAnnualizationConfig) {
        return undefined;
      }

      const initDate = this.annualizationData?.[this.employee.id]?.initializationDate ||
        this.shopAnnualizationConfig.initializationDate;

      return skDate.utc(initDate).format('DD/MM/YYYY');
    },
    nextTheoreticalBalance() {
      if (
        !this.annualizationData ||
        !this.annualizationData?.[this.employee.id]?.nextPeriodStartDate
      ) {
        return undefined;
      }

      const { nextPeriodStartDate } = this.annualizationData[this.employee.id];
      // eslint-disable-next-line max-len
      return this.annualizationTheoreticalBalanceAt(nextPeriodStartDate, this.employee.id) ??
        this.getClosestTheoreticalBalance(nextPeriodStartDate, this.employee.id);
    },
    theoreticalImpactingAbsences() {
      if (this.isAnnualizationContractHoursChangeEnabled) {
        if (!this.annualizationConfig || !this.currentAnnualizationEndDatePeriod) {
          return undefined;
        }

        return this.annualizationTheoreticalImpactingAbsencesAt(
          this.currentAnnualizationEndDatePeriod,
          this.employee.id,
        );
      }

      if (!this.annualizationData?.[this.employee.id]) {
        return undefined;
      }

      return this.annualizationData[this.employee.id]
        ?.theoreticalImpactingAbsences;
    },
    userImpactedTheoreticalBalance() {
      if (this.isAnnualizationContractHoursChangeEnabled) {
        if (!this.annualizationConfig || !this.currentAnnualizationEndDatePeriod) {
          return undefined;
        }

        return this.annualizationTheoreticalBalanceAt(
          this.currentAnnualizationEndDatePeriod,
          this.employee.id,
        );
      }

      if (!this.annualizationData) {
        return undefined;
      }

      return this.annualizationImpactedTheoreticalBalance(this.employee.id);
    },
    userRemainingBalance() {
      if (this.isAnnualizationContractHoursChangeEnabled) {
        if (!this.annualizationConfig || !this.currentAnnualizationEndDatePeriod) {
          return undefined;
        }

        return this.annualizationRemainingBalanceAt(
          this.currentAnnualizationEndDatePeriod,
          this.employee.id,
        );
      }

      if (!this.annualizationConfig || !this.currentPeriodEndDate) {
        return undefined;
      }

      return this.annualizationRemainingBalanceAt(
        this.currentPeriodEndDate?.toDate(),
        this.employee.id,
      );
    },
    userRealizedBalance() {
      if (!this.annualizationConfig) {
        return undefined;
      }

      let endDate = this.currentPeriodEndDate?.toDate();

      if (this.isAnnualizationContractHoursChangeEnabled &&
          this.currentAnnualizationEndDatePeriod) {
        endDate = this.currentAnnualizationEndDatePeriod;
      }

      return this.annualizationRealizedBalanceAt(
        endDate,
        this.employee.id,
      );
    },
  },

  created() {
    this.isLoading = true;
    const employeeId = this.employee.id;

    this.fetchCurrentContract({ employeeId })
      .then(async () => {
        await this.fetchAmendments({
          employeeId,
          contractId: this.contract.id,
        });
      })
      .catch(() => {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  async mounted() {
    const shopId = this.employee.attributes.shopId;
    const queries = [
      this.fetchEmployeeAnnualizationConfigs({
        shopId,
        userId: this.employee.id,
      }),
    ];

    // shop config has been already fetched on all shops view to know if annu is active on user's shop
    if (this.currentShop.id !== 'all') {
      queries.push(this.fetchShopAnnualizationConfig({ shopId }));
    }

    try {
      await Promise.all(queries);
      await this.fetchAndComputeAnnualizationData({
        shopId,
        userIds: [this.employee.id],
      });
    } catch (error) {
      if (error.response?.status === 404) {
        // svc-employees returns a 404 when employee does not have an annualization config
        return;
      }

      this.$skToast({
        message: this.$t('errors.standard_message'),
        variant: 'error',
      });

      throw error;
    }
  },
  methods: {
    ...mapActions('annualization', [
      'fetchAndComputeAnnualizationData',
      'fetchEmployeeAnnualizationConfigs',
      'fetchShopAnnualizationConfig',
    ]),
    ...mapActions('contracts', ['fetchCurrentContract']),
    ...mapActions('amendments', ['fetchAmendments']),
    async reloadCounter() {
      this.fetchEmployeeAnnualizationConfigs({
        shopId: this.employee.attributes.shopId,
        userId: this.employee.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-annualization__wrapper {
  padding: 24px 20px 74px;
  background-color: #fff;

  &--loading {
    min-height: 436px;
  }
}

.employee-annualization__header__tag {
  display: flex;
  gap: 8px;
  margin: 16px 0;
}

.employee-annualization__info-card {
  margin-top: 24px;

  &__title {
    color: $sk-black;
  }
}

.counter-header {
  margin-bottom: 8px;
}
</style>
