<template>
  <SkModal
    id="blocking-alert-modal"
    ref="blockingAlertModal"
    class="blocking_alert_modal__wrapper"
    :dismissable="false"
    @show="handleShow"
  >
    <template #header>
      <div class="sk-modal__title-container">
        <h2 class="sk-header--2">
          {{ $t('plannings.toolbar.modal.blocking_alert_modal.title') }}
        </h2>
      </div>
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <div class="blocking_alert_modal__subtitle">
          <CircledExclamationMarkIcon fill="#d03e50" />
          {{ $t('plannings.toolbar.modal.blocking_alert_modal.caution') }}
        </div>
        <div
          v-for="shift in blockingAlertShiftsByUserValues"
          :key="shift.id"
          class="blocking_alert_modal__description"
        >
          {{ alertMessage(shift) }}
        </div>
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton
        :loading="isWaitingResponse"
        @click="handleConfirm"
      >
        {{ $t('plannings.toolbar.modal.blocking_alert_modal.confirm') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'BlockingAlertModal',
  data() {
    return {
      deletionError: false,
      shiftsToUpdateInConfirm: [],
      previousActionOnShow: '',
      isWaitingResponse: false,
    };
  },
  computed: {
    ...mapState('planningsShifts', ['blockingAlertShiftsByUser']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsState', ['monday', 'sunday', 'areUnassignedShiftsAllowed', 'previousAction', 'isMonthlyView']),
    ...mapGetters('employees', ['fullName']),

    blockingAlertShiftsByUserValues() {
      return Object.values(this.blockingAlertShiftsByUser);
    },
  },
  methods: {
    ...mapActions('planningsShifts', ['deleteShifts', 'fetchShiftAlerts', 'updateShift']),
    ...mapActions('planningsState', ['undoRedoAction']),
    ...mapMutations('planningsShifts', ['removeShifts', 'resetBlockingAlerts', 'upsertShift']),

    // Display alert and make modifications in backend
    async handleShow() {
      // We store previous action since it will be erased by the 'decrementHistory' of undoRedoAction
      this.previousActionOnShow = this.previousAction.action;
      this.isWaitingResponse = true;
      try {
      // Case 1: alert is triggered by update -> undo action
        if (this.previousActionOnShow === 'UPDATE') {
          const response = await this.undoRedoAction({
            periodStartsAt: this.monday,
            periodEndsAt: this.sunday,
            isRedo: false,
            skipUpsertShift: true,
          });
          this.shiftsToUpdateInConfirm = response.data;
        // Case 2: unassigned shift are allowed -> we set shift to unassigned
        } else if (this.areUnassignedShiftsAllowed) {
          const unassignedShifts = this.blockingAlertShiftsByUserValues.map(shift => (
            { ...shift, attributes: { ...shift.attributes, userId: null } }
          ));
          const response = await this.updateShift({
            shifts: unassignedShifts,
            shopId: this.currentShop.id,
            periodStartsAt: this.monday,
            periodEndsAt: this.sunday,
            skipUpsertShift: true,
          });
          this.shiftsToUpdateInConfirm = response.data;
        // Case 3: none of the above is applicable -> delete shift
        } else {
          await this.deleteShifts({
            shift_ids: this.blockingAlertShiftsByUserValues.map(shift => shift.id),
            shop_id: this.currentShop.id,
            starts_at: this.monday,
            ends_at: this.sunday,
            skipRemoveShift: true,
          });
        }
      } catch (error) {
        this.deletionError = true;
      } finally {
        this.isWaitingResponse = false;
      }
    },
    // Hide alert and make modification in frontend
    handleConfirm() {
      if (this.deletionError) {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      } else {
        // Case 1: alert is triggered by update -> undo action
        // Case 2: unassigned shift are allowed -> we set shift to unassigned
        if (this.previousActionOnShow === 'UPDATE' || this.areUnassignedShiftsAllowed) {
          this.upsertShift({
            payload: this.shiftsToUpdateInConfirm,
            isMonthlyView: this.isMonthlyView,
          });
        // Case 3: none of the above is applicable -> delete shift
        } else {
          this.removeShifts({
            shift_ids: this.blockingAlertShiftsByUserValues.map(shift => shift.id),
          });
        }
        const userIds = Object.keys(this.blockingAlertShiftsByUser);
        this.resetBlockingAlerts();
        this.fetchShiftAlerts({
          shop_id: this.currentShop.id,
          starts_at: this.monday,
          ends_at: this.sunday,
          user_ids: userIds,
          readonly: true,
        });

        this.emitOnRoot(MODAL_HIDE_EVENT, null, 'blocking-alert-modal');
      }
    },
    alertMessage(shift) {
      const user = this.users.find(u => parseInt(u.id, 10) === shift.attributes.userId);
      return `${this.fullName(user)} : ${shift.relationships.alerts.find(alert => alert.attributes.blocking).attributes.message}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.blocking_alert_modal__subtitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: $fs-text-m;
  color: $sk-error;
  margin-bottom: 10px;

  & > svg {
    margin-right: 10px;
  }
}
</style>
