<template>
  <SkOroraDialog
    id="explore_employee_experience_step_modal"
    :title="$t('badgings.timeclock_demo.modals.employee_experience.title')"
    :subtitle="$t('badgings.timeclock_demo.modals.employee_experience.subtitle')"
    :cancel-button-label="$t('badgings.timeclock_demo.modals.labels.later')"
    :submit-button-label="$t('badgings.timeclock_demo.modals.labels.done')"
    @cancel="stopVideo"
    @close="stopVideo"
    @submit="submit"
  >
    <template #body>
      <main class="explore-employee-experience-step-modal__video">
        <video
          ref="welcome-video"
          controls
          controlsList="nodownload"
          style="width: 100%; height: 100%;"
        >
          <source
            :src="videoUrl"
            type="video/mp4"
          >
        </video>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions, mapMutations,
} from 'vuex';
import { awsBucketPublicAssets } from '@config/env';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'EmployeeExperienceVideoModal',
  data() {
    return {
      videoUrl: this.getVideoUrl(),
    };
  },
  watch: {
    '$i18n.locale': 'updateLocale',
  },
  methods: {
    ...mapActions('timeclockOnboarding', ['updateStepCompletion']),
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    async submit() {
      this.updateStepCompletion({ id: 'explore_employee_experience_step', completed: true });
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'explore_employee_experience_step_modal');
      this.stopVideo();
      this.toggleChecklistCollapsed();
    },
    stopVideo() {
      this.$refs['welcome-video'].pause();
    },
    getVideoUrl(locale = this.$i18n.locale) {
      return `${awsBucketPublicAssets}/media/EmployeeExperienceVideo_${locale.toUpperCase()}.mp4`;
    },
    reloadVideo() {
      const video = this.$refs['welcome-video'];
      if (video) {
        video.load();
      }
    },
    updateLocale(newLocale) {
      this.videoUrl = this.getVideoUrl(newLocale);
      this.reloadVideo();
    },
  },
};
</script>

<style scoped lang="scss">
.explore-employee-experience-step-modal__video {
  display: flex;
}
</style>
