export const ABSENCE_TYPE_DAY = 'day';
export const ABSENCE_TYPE_HALF_DAY = 'half-day';
export const ABSENCE_TYPE_HOURS = 'hours';
export const ALL_SHIFT_TYPE = 'all';
export const POSTE_SHIFT_TYPE = 'poste';
export const ABSENCE_SHIFT_TYPE = 'absence';
export const WORK_SHIFT_KEY = Object.freeze({
  MAIN: 'main',
  SECONDARY: 'secondary',
});
