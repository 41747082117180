import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
/* eslint-disable  no-useless-escape */
/* eslint-disable  max-len */

import { isValidIBAN } from 'ibantools';
import { isSIRET } from 'siret';
import { removeWhiteSpaces } from './method_helper';

export const isPositiveInteger = value => {
  value = Number(value);
  return !isNaN(value) && isNumber(value) && value >= 0 && parseInt(value, 10) === value;
};

export const isPresent = (value, min = 2) => (
  value && value.trim().length >= min
);

export const isValidEmail = value => value && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

export const isValidIban = value => value && isValidIBAN(removeWhiteSpaces(value));

export const sameValues = (value1, value2) => (
  value1 && value2 && (value1 === value2)
);

/**
 * Numbers matching :
 * 0689912549
 * 06 89 91 25 49
 * +33698912549
 * +33 6 79 91 25 49
 * +33 - 6 - 79 - 91 - 25 - 49
 * (555) - 555 - 5555
 * 555 - 555 - 5555
 * +1 - 238 6 79 91 25 49
 * +1 - 555 - 532 - 3455
 * +15555323455
 * +7 06 79 91 25 49
*/
export const isValidPhoneNumber = value => value && /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))$/.test(value);

const turnToFloat = value => {
  const number = parseFloat(value, 10);

  return isNaN(number) ? null : number;
};

export const isPositiveFloat = value => {
  value = turnToFloat(value);

  const localValue = value;

  return !isNaN(localValue) && isNumber(localValue) && /^[+]?\d+([.]\d+)?$/.test(localValue);
};

export const isFloatWithPrependSign = value => {
  value = turnToFloat(value);

  const localValue = value;

  return !isNaN(localValue) && isNumber(localValue) && /^\-?[0-9]*(\.|,)?[0-9]+$/.test(localValue);
};

export const isFloat = value => {
  value = turnToFloat(value);

  let localValue = value;

  if (value !== undefined) localValue = value;

  return !isNaN(localValue) && isNumber(localValue) && /^[0-9]*(\.)?[0-9]+$/.test(localValue);
};

/**
 * Checks if `obj` is empty (= null or no length).
 *
 * @param {Object} obj The value to check.
 * @returns {boolean} Returns `true` if `value` is a empty, else `false`.
 */
export const isEmptyObject = obj => obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);

/**
 * Validators for password
 *
 * @param string: The password value to check.
 * @returns {boolean}
 */

export const isValidPasswordLength = value => value.length >= 8 && value.length < 72;

export const isValidPasswordUpper = value => /[A-Z]/.test(value);

export const isValidPasswordLower = value => /[a-z]/.test(value);

export const isValidPasswordDigit = value => /\d/.test(value);

export const isValidSiret = value => value && isSIRET(value);
