<template>
  <a href="/">
    <SkLogo class="sk-logo sk-logo--big" />
    <SkLogoS
      width="19"
      height="28"
      class="sk-logo sk-logo--small"
    />
  </a>
</template>

<script>
import {
  SkLogo,
  SkLogoS,
} from '@skelloapp/skello-ui';

export default {
  name: 'SkelloLinkedLogo',
  components: {
    SkLogo,
    SkLogoS,
  },
};
</script>

<style lang="scss" scoped>
svg.sk-logo {
  margin: 0;
}

.sk-logo--big {
  display: none;
}

@media screen and (min-width: 1200px) {
  .sk-logo--big {
    display: block;
  }

  .sk-logo--small {
    display: none;
  }
}
</style>
