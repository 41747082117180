<template>
  <SkOroraDialog
    id="invite_employees_modal"
    ref="inviteEmployeesModal"
    :title="$t('badgings.timeclock_demo.modals.invite_employees.title')"
    :cancel-button-label="$t('badgings.timeclock_demo.modals.labels.later')"
    :submit-button-label="$t('badgings.timeclock_demo.modals.labels.send')"
    :is-submit-disabled="disabledSubmit"
    :icon-options="{ name: 'PaperAirplaneV2Icon'}"
    size="small"
    @submit="handleSubmit"
  >
    <template #body>
      <div class="invite-employees-modal-description">
        <span class="sk-text-medium-regular">
          {{ $t('badgings.timeclock_demo.modals.invite_employees.description') }}
        </span>
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'InviteEmployeesModal',
  data() {
    return {
      sendingRequest: false,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('employees', ['employees']),
    disabledSubmit() {
      return !this.isEmployeeDataLoaded;
    },
    isEmployeeDataLoaded() {
      return this.employees.length > 0 && this.employee.id;
    },
  },
  methods: {
    ...mapActions('timeclockOnboarding', ['updateStepCompletion']),
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    handleSubmit() {
      this.sendingRequest = true;
      const data = {
        send_by_sms: false,
        send_by_email: true,
        send_to_all: true,
      };

      this.$skAnalytics.track('ss_checklist_send_pin_to_all');

      httpClient
        .patch(`/v3/api/users/${this.employee.id}/send_pin`, data)
        .then(this.handleRequestSuccess)
        .catch(this.handleRequestError);
    },
    handleRequestSuccess() {
      this.sendingRequest = false;

      this.$skToast({
        message: this.$t('employees.send_email.toast.success'),
        variant: 'success',
        containerId: 'employees__container',
      });

      this.updateStepCompletion({ id: 'invite_employees_step', completed: true });

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'invite_employees_modal');

      this.toggleChecklistCollapsed();
    },
    handleRequestError() {
      this.sendingRequest = false;
      this.$skToast({
        message: this.$t('employees.send_email.toast.error'),
        variant: 'error',
        containerId: 'employees__container',
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .sk-orora-dialog__body-wrapper {
  gap: 8px;
}

.invite-employees-modal-description {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  color: $sk-grey-50;
}
</style>
