<template>
  <textarea
    ref="textarea"
    class="task__text-input"
    :placeholder="placeholder"
    :value="value"
    :style="textInputStyle"
    :disabled="disabled"
    @focus="handleFocus"
    @blur="handleBlur"
    v-on="eventListeners"
  />
</template>

<script>
const DEFAULT_LINE_HEIGHT = 16;
const DEFAULT_PADDING_VERTICAL = 2;

export default {
  name: 'TasksTextarea',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textDecorationLine: {
      type: String,
      default: undefined,
    },
    lineHeight: {
      type: Number,
      default: DEFAULT_LINE_HEIGHT,
    },
    paddingVertical: {
      type: Number,
      default: DEFAULT_PADDING_VERTICAL,
    },
    displayBorderOnFocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initialHeight: `${this.lineHeight + this.paddingVertical * 2}px`,
      height: `${this.lineHeight + this.paddingVertical * 2}px`,
      borderBottom: 'none',
    };
  },
  computed: {
    textInputStyle() {
      return {
        'text-decoration-line': this.textDecorationLine,
        height: this.height,
        lineHeight: `${this.lineHeight}px`,
        padding: `${this.paddingVertical}px 0`,
        borderBottom: this.borderBottom,
      };
    },
    eventListeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      };
    },
  },
  watch: {
    value() {
      this.computeHeight();
    },
  },
  created() {
    this.computeHeight();
  },
  methods: {
    handleFocus() {
      this.borderBottom = this.displayBorderOnFocus ? 'solid 1px #2b66fe' : 'none';
    },
    handleBlur() {
      this.borderBottom = 'none';
    },
    computeHeight() {
      // reset height so this.$refs.textarea.scrollHeight can be recomputed for nextTick
      this.height = this.initialHeight;
      this.$nextTick(() => {
        this.height = `${this.$refs.textarea.scrollHeight}px`;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task__text-input {
  display: flex;
  flex: 1;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  resize: none;
  overflow: hidden;
}
</style>
