<template>
  <div class="full-contract__header-wrapper">
    <div
      class="full-contract__header-navigation"
      @click="goBack"
    >
      <SkCircleButton icon="ArrowLeftIcon" />
      <span class="full-contract__header-navigation__text">
        {{ $t('employees.tabs.contracts.full_contract.header.back') }}
      </span>
    </div>
    <div class="sk-divider--light full-contract__header-divider" />

    <UserItem />

    <div class="full-contract__header-information">
      <SkTag
        v-if="isActive || isOver || isComingUp"
        :variant="tagVariant"
        with-bullet-point
        class="full-contract__header-status"
      >
        {{ $t(`employees.tabs.contracts.current.attributes.status.${status}`) }}
      </SkTag>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import UserItem from './UserItem';

export default {
  name: 'Header',
  components: { UserItem },
  computed: {
    ...mapState('contracts', ['contract']),
    ...mapGetters('contracts', [
      'isActive',
      'isOver',
      'isComingUp',
      'isContractCreationView',
    ]),

    status() {
      if (this.isActive) return 'active';
      if (this.isOver) return 'over';
      if (this.isComingUp) return 'coming_up';

      return 'none';
    },
    tagVariant() {
      return this.isOver ? 'danger' : 'blue';
    },
  },
  methods: {
    goBack() {
      if (this.isContractCreationView) this.$skAnalytics.track('multicontract_go_back_create');
      if (this.isComingUp) {
        this.$router.push({ name: 'user_contracts_future' });
      } else {
        this.$router.push({ name: 'user_contracts' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-contract__header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.full-contract__header-navigation {
  cursor: pointer;
}

.full-contract__header-navigation__text {
  margin-left: 15px;
}

.full-contract__header-divider {
  margin: 0 25px;

  @media (max-width: $md-width) {
    margin: 0 15px;
  }
}

.full-contract__header-information {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.full-contract__header-status {
  margin-left: 20px;
}
</style>
