<template>
  <div>
    <SkCard class="team-schedule-modal__how-it-works__container">
      <CircledIIcon
        class="team-schedule-modal__how-it-works__icon"
        fill="#2b66fe"
        width="24"
        height="24"
      />
      <!-- eslint-disable max-len, vue/no-v-html -->
      <div
        class="team-schedule-modal__how-it-works__content"
        v-html="$t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.how_it_works')"
      />
      <!-- eslint-enable max-len, vue/no-v-html -->
    </SkCard>
    <div v-if="teamSchedule">
      <SkModalSection class="team-schedule-modal__select-section">
        <div class="team-schedule-modal__select__label">
          {{ $t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.date.label') }}
        </div>
        <div class="team-schedule-modal__select">
          <!-- eslint-disable max-len -->
          <SkDatePicker
            ref="datepicker"
            v-model="startDate"
            :default-value="mondayAsDate"
            :disabled-date="disabledDate"
            :disabled="isTeamScheduleUpdate"
            :error-message="$t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.date.error')"
            :clearable="false"
            :lang="$i18n.locale"
            input-moment-format="DD MMM YYYY"
            append-to-body
          />
          <!-- eslint-enable max-len -->
        </div>
      </SkModalSection>
      <SkModalSection class="team-schedule-modal__select-section">
        <div class="team-schedule-modal__select__label">
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle.label') }}
        </div>
        <div class="team-schedule-modal__select">
          <!-- eslint-disable max-len -->
          <SkSelectV2
            v-model="teamSchedule.weekCycle"
            :options="weekCycleOptions"
            :label="$t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle.placeholder')"
            :search-placeholder="$t('labels.search')"
            :no-results-label="$t('search_bar.no_result')"
            :testing-options="selectWeekCycleTestingOptions"
            width="340px"
            append-to-body
            @input="handleWeekCycleInput"
          >
            <!-- eslint-enable max-len -->
            <template #option="{ option }">
              <div class="team-schedule-modal__option">
                <VariableContractHoursTag
                  class="team-schedule-modal__option__tag"
                  :nb-weeks="option.id"
                />
                {{ option.text }}
              </div>
            </template>
          </SkSelectV2>
        </div>
      </SkModalSection>
      <SkModalSection
        class="team-schedule-modal__select-section"
        border-bottom="none"
      >
        <div class="team-schedule-modal__select__label">
          <!-- eslint-disable-next-line max-len -->
          {{ $t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle_first_week_index.label') }}
        </div>
        <div class="team-schedule-modal__select">
          <!-- eslint-disable max-len -->
          <SkSelectV2
            v-model="teamSchedule.weekCycleStartIndex"
            :options="weekCycleStartIndexOptions"
            :disabled="!teamSchedule.weekCycle"
            :label="$t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle_first_week_index.placeholder')"
            :search-placeholder="$t('labels.search')"
            :no-results-label="$t('search_bar.no_result')"
            :testing-options="selectStartIndexTestingOptions"
            width="340px"
            append-to-body
          >
            <!-- eslint-enable max-len -->
            <template #selected-option="{ value }">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="weekCycleStartIndexOptions[value].text" />
            </template>
            <template #option="{ option }">
              <div class="team-schedule-modal__option">
                <VariableContractHoursTag
                  class="team-schedule-modal__option__tag"
                  :nb-weeks="teamSchedule.weekCycle"
                  :specific-week-index="option.id"
                />
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="option.text" />
              </div>
            </template>
          </SkSelectV2>
        </div>
      </SkModalSection>
    </div>
    <div
      v-if="isTeamScheduleUpdate"
      class="team-schedule-modal__card"
    >
      <CircledExclamationMarkIcon
        class="team-schedule-modal__card--icon"
        fill="#fff"
        width="24"
        height="24"
      />
      <span class="team-schedule-modal__card--text">
        <!-- eslint-disable max-len -->
        {{ $t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.update_warning') }}
        <!-- eslint-enable max-len -->
      </span>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import { mapGetters } from 'vuex';

import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';

const MAX_WEEKS_ROTATION = 13;

export default {
  name: 'TeamScheduleParametersSection',
  components: { VariableContractHoursTag },
  props: {
    team: {
      type: Object,
      default: null,
    },
    teamSchedule: {
      type: Object,
      default: null,
    },
    isTeamScheduleUpdate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectWeekCycleTestingOptions: {
        dropdown: 'team-schedule-modal__select-week__dropdown',
        options: 'team-schedule-modal__select-week__options',
      },
      selectStartIndexTestingOptions: {
        dropdown: 'team-schedule-modal__select-start-index__dropdown',
        options: 'team-schedule-modal__select-start-index__options',
      },
    };
  },
  computed: {
    ...mapGetters('shopTeams', ['lastTeamScheduleForTeam']),
    weekCycleOptions() {
      const options = [];
      for (let i = 2; i <= MAX_WEEKS_ROTATION; i += 1) {
        options.push({ id: i, text: this.weekCycleOptionText(i) });
      }

      return options;
    },
    startDate: {
      get() {
        return this.teamSchedule.startDate;
      },
      set(newValue) {
        this.teamSchedule.startDate = newValue;
      },
    },
    mondayAsDate() {
      return skDate().startOf('isoWeek').toDate();
    },
    weekCycleStartIndexOptions() {
      if (!this.teamSchedule.weekCycle) {
        return [];
      }

      const options = [];
      for (let i = 0; i < this.teamSchedule.weekCycle; i += 1) {
        options.push({ id: i, text: this.weekCycleStartIndexOptionText(i) });
      }

      return options;
    },
  },
  methods: {
    weekCycleOptionText(value) {
      return this.$tc(
        'shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle.number_of_weeks',
        value,
      );
    },
    weekCycleStartIndexOptionText(value) {
      return this.$t(
        'shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.week_cycle_first_week_index.week_with_index',
        { index: this.$t(`variable_contract_hours.specific_week_tag.${value}`) },
      );
    },
    handleWeekCycleInput() {
      this.$set(this.teamSchedule, 'weekCycleStartIndex', null);
    },
    // Enable current and future mondays only
    disabledDate(date) {
      const lastTeamScheduleForTeam = this.lastTeamScheduleForTeam(this.team.id);

      if (!lastTeamScheduleForTeam) {
        return !skDate(date).isMonday() ||
          skDate(date).startOf('isoWeek').isBefore(this.mondayAsDate);
      }
      const endDate = skDate(lastTeamScheduleForTeam.attributes.endDate);

      return !skDate(date).isMonday() ||
        skDate(date).startOf('isoWeek').isBefore(this.mondayAsDate) ||
        skDate(date).startOf('isoWeek').isBefore(endDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-schedule-modal__select-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Need to overwrite padding-top/bottom, can't find correct selector without !important/
::v-deep .sk-modal__section {
  padding: 7px 0 !important;
}

.team-schedule-modal__how-it-works__container {
  margin: 24px 24px 15px;
  padding: 12px;
  display: flex;
}

.team-schedule-modal__how-it-works__icon {
  margin-left: 10px;
  margin-top: 8px;
  flex-shrink: 0;
}

.team-schedule-modal__how-it-works__content {
  padding-left: 18px;
  color: $sk-grey;
}

.team-schedule-modal__select__label {
  font-weight: bold;
}

.team-schedule-modal__select {
  width: 340px;
}

.team-schedule-modal__option {
  display: flex;
  align-items: center;
}

.team-schedule-modal__option__tag {
  margin-right: 12px;
}

.team-schedule-modal__card {
  display: flex;
  justify-content: center;
  margin: 24px;
  padding: 12px 30px;
  color: $sk-white;
  background-color: $sk-error;
  border-radius: 4px;

  &--icon {
    margin-right: 17px;
  }

  &--text {
    width: 470px;
  }
}
</style>
