export const isMacOS = () => /Mac/i.test(
  navigator.userAgentData?.platform || navigator.userAgent,
);

export const isWindows = () => /Windows/i.test(
  navigator.userAgentData?.platform || navigator.userAgent,
);

export const isSafariBrowser = () => /^((?!chrome|android).)*safari/i.test(
  navigator.userAgentData?.platform || navigator.userAgent,
);
