<template>
  <img
    width="32"
    src="./images/logo-sage.jpg"
  >
</template>

<script>
export default {
  name: 'Sage',
};
</script>
