<template>
  <div class="shift-info-card--wrapper">
    <span class="shift-name">{{ shift.relationships.poste.attributes.name }}</span>

    <div class="shift-time">
      <div>
        <span v-if="isFromAnotherShop">{{ shift.attributes.shopName }}</span>
        <span>{{ formattedShiftHours }}</span>
      </div>
      <span v-if="!shift.attributes.isPendingLeaveRequest">{{ shiftDuration }}h</span>
    </div>
    <div
      v-if="formattedPrevisionalShiftHours"
      class="shift-time"
    >
      <div>
        <span>{{ formattedPrevisionalShiftHours }}</span>
      </div>
    </div>

    <ShiftInfoCardItem
      v-if="shift.attributes.tasks.length > 0"
      icon="CheckMarkV2Icon"
      :text="shiftCompletedTasksText"
    />

    <ShiftInfoCardItem
      v-if="shift.attributes.nbMeal !== null && shift.attributes.nbMeal > 0"
      icon="CutleryIcon"
      :text="shiftMealsTakenText"
    />

    <ShiftInfoCardItem
      v-if="shift.attributes.note !== null"
      icon="NotePageIcon"
      :text="shift.attributes.note"
    />

    <ShiftInfoCardItem
      v-if="shift.attributes.comments.length > 0"
      icon="ChatBubbleWithDotsIcon"
      :text="shiftComments"
    />
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
} from '@app-js/shared/constants/shift';
import ShiftInfoCardItem from './ShiftInfoCardItem';

export default {
  components: {
    ShiftInfoCardItem,
  },
  props: {
    shift: {
      type: Object,
      required: true,
    },
    currentShop: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedShiftHours() {
      if (this.shift.attributes.absenceCalculation) {
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_DAY) return this.$t('plannings.shift.time.day');
        if (this.shift.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY) return this.$t('plannings.shift.time.half_day');
      }
      const prefix = this.shift.attributes.previsionalStart ?
        `${this.$t('plannings.shift.badging.shift_badged')} ` : '';

      const start = this.shift.attributes.showStartTime || this.isAbsence ? this.shiftStartTime : '.';
      const end = this.shift.attributes.showEndTime || this.isAbsence ? this.shiftEndTime : '.';

      return `${prefix}${start} - ${end}`;
    },
    formattedPrevisionalShiftHours() {
      if (!this.shift.attributes.previsionalStart) { return false; }
      const prefix = `${this.$t('plannings.shift.badging.shift_planned')}`;
      return `${prefix} ${this.shiftPlannedStartTime} - ${this.shiftPlannedEndTime}`;
    },
    shiftCompletedTasksText() {
      const nbTotalTasks = this.shift.attributes.tasks.length;
      return this.$t('plannings.shift.completed_tasks', { nbCompletedTasks: this.shiftCompletedTasks, nbTotalTasks });
    },
    shiftMealsTakenText() {
      return this.$t('plannings.shift.meals_taken', { nbMeal: this.shift.attributes.nbMeal });
    },
    shiftStartTime() {
      return skDate(this.shift.attributes.startsAt).utc().format('HH:mm');
    },
    shiftEndTime() {
      return skDate(this.shift.attributes.endsAt).utc().format('HH:mm');
    },
    shiftPlannedStartTime() {
      return skDate(this.shift.attributes.previsionalStart).utc().format('HH:mm');
    },
    shiftPlannedEndTime() {
      return skDate(this.shift.attributes.previsionalEnd).utc().format('HH:mm');
    },
    shiftDuration() {
      return parseFloat((this.shift.attributes.durationInSeconds / 3600).toFixed(2));
    },
    shiftComments() {
      const commentsCount = this.shift.attributes.comments.length;
      return this.$tc('plannings.shift.comments', commentsCount, { count: commentsCount });
    },
    shiftCompletedTasks() {
      return this.shift.attributes.tasks.filter(task => task.isChecked).length;
    },
    isFromAnotherShop() {
      return this.shift.attributes.shopId !==
        parseInt(this.currentShop.id, 10);
    },
    isAbsence() {
      return this.shift.relationships.poste.attributes.absenceKey !== null;
    },
  },
};
</script>

<style scoped lang="scss">
.shift-info-card--wrapper {
  position: fixed;
  padding: 15px;
  display: flex;
  width: 248px;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 4px;
  background-color: $sk-black;
  z-index: 6;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, .22);
  color: white;
  font-weight: 400;
}

.shift-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 17.84px;
}

.shift-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 13.38px;

  > div {
    display: flex;
    flex-direction: column;

    :last-child {
      font-weight: 600;
    }
  }
}
</style>
