import { read } from 'xlsx';
import skDate from '@skello-utils/dates';
import {
  validatePostesOrReturnError,
  validatePeriodOrReturnError,
  validateDaysOrReturnError,
  validateHourQuartersOrReturnError,
  validateWorkloadPlans,
} from './workload_plan_excel_validators';

const EXCLUDED_KEYS = ['!ref', '!merges', '!margins'];

const getWorksheet = workbook => {
  // TODO DEV-15587 : Check that there is at least one sheet
  /* Product spec : always read the first sheet */
  const sheetName = Object.keys(workbook.Sheets)[0];

  return workbook.Sheets[sheetName];
};

/*
  Extract period from the worksheet.
  Period is present on the cell A1.
*/
const extractPeriod = worksheet => {
  const key = 'A1';
  const period = worksheet[key]?.v?.match(/^{(.+)} - {(.+)}/);

  if (!period) return {};

  const start = period[1];
  const end = period[2];

  return { start, end };
};

// Decompose a cell coordinate into an array [column, row] eg 'B23' into ['B', 23]
const parseCell = cellCoordinate => {
  const [_, col, row, ..._rest] = cellCoordinate.match(/^([A-Z]+)([0-9]+)$/);

  return [col, parseInt(row, 10)];
};

/*
  Extract postes cells from the worksheet
  Poste cells are cells present on line 1 except columns A and B
  Returns array of {column, value}
  With:
    column: column coordinate of the poste, eg: 'C'
    value: full name of the poste, eg: 'Driver'
 */
const extractPostes = worksheet => {
  const posteCells = Object.keys(worksheet).filter(cell => {
    if (EXCLUDED_KEYS.includes(cell)) return false;

    const [column, row] = parseCell(cell);

    return row === 1 && !['A', 'B'].includes(column);
  });

  return posteCells.map(cellPosition => ({
    column: parseCell(cellPosition)[0],
    value: worksheet[cellPosition].v,
  }));
};

/*
  Extract day cells from the worksheet
  Days cells are cells present in column A except line 1
  Returns array of {row, value}
  With:
    row: row of the day, eg: 32
    value: ISO date eg "2023-09-21"
 */
const extractDays = worksheet => {
  const dayCells = Object.keys(worksheet).filter(cell => {
    if (EXCLUDED_KEYS.includes(cell)) return false;

    const [column, row] = parseCell(cell);

    return column === 'A' && row !== 1;
  });

  return dayCells.map(cellPosition => {
    // Extract the date without the day name
    const day = worksheet[cellPosition].w.match(/\d{2}\/\d{2}\/\d{4}$/);

    if (!day) return {};

    return {
      row: parseCell(cellPosition)[1],
      value: skDate(day[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
  });
};

/*
  Extract hour quarters cells from the worksheet
  hour quarters are cells on column B except line 1
  Returns array of {column, value}
  With:
    row: row of the hour quarter, eg: 32
    value: ISO8601 date eg "2023-09-21T10:00:00"
 */
const extractHourQuarter = (worksheet, days) => {
  let prevValue;
  let prevDayRow;

  const quartersCells = Object.keys(worksheet).filter(cell => {
    if (EXCLUDED_KEYS.includes(cell)) return false;

    const [column, row] = parseCell(cell);

    return column === 'B' && row !== 1;
  });

  return quartersCells.map(cellPosition => {
    const [_cellColumn, cellRow] = parseCell(cellPosition);

    // Get the day of the current quarter of hours
    const currentDayRow = Math.max(
      ...days.filter(dayCell => dayCell.row <= cellRow)
        .map(dayCell => dayCell.row),
    );
    const currentDayValue = days.find(dayCell => dayCell.row === currentDayRow).value;

    // Optimistic full date, calculated from day + quarter of hours
    const calculatedValue = skDate.utc(`${currentDayValue}T${worksheet[cellPosition].w}:00`);

    // If the new date is before the previous one, and we are on the same opening day,
    // that means we've changed day
    if (prevValue && prevDayRow === currentDayRow && calculatedValue < prevValue) {
      calculatedValue.add(1, 'day');
    }

    prevValue = calculatedValue;
    prevDayRow = currentDayRow;

    return {
      row: cellRow,
      value: calculatedValue.format('YYYY-MM-DDTHH:mm:ss'),
    };
  });
};

/*
  Extract workload plans cells from the worksheet
  Workload plans are every cells not in column A and B and not on line 1
  Returns array of {row, column, value}
  With:
    row: row of the workload plan, eg: 32
    column: column of the workload plan, eg: 'D'
    value: value in the cell, eg: 23
 */
const extractWorkloadPlans = worksheet => {
  const quartersCells = Object.keys(worksheet).filter(cell => {
    if (EXCLUDED_KEYS.includes(cell)) return false;

    const [column, row] = parseCell(cell);

    return row !== 1 && !['A', 'B'].includes(column);
  });

  return quartersCells.map(cellPosition => {
    const [column, row] = parseCell(cellPosition);

    return {
      row,
      column,
      value: worksheet[cellPosition].w,
    };
  });
};

export const readWorksheetAndformatWorkloadPlans = ({
  worksheet,
  postes = [],
  openingHourQuarters = [],
  visibleDays = [true, true, true, true, true, true, true], // Shop visible days format
}) => {
  const result = {
    periodStartsAt: '', // for redirection after closing the modal
    periodEndsAt: '', // for displaying purpose on the modal
    errors: [],
    workloadPlans: {},
  };
  const period = extractPeriod(worksheet);
  const periodError = validatePeriodOrReturnError(period);

  // Early return if the period is not right
  if (periodError) {
    return {
      ...result,
      errors: [periodError],
    };
  }

  const daysCells = extractDays(worksheet);
  const dayErrors = validateDaysOrReturnError(daysCells, period, visibleDays);

  // Early return if the days are not right
  if (dayErrors) {
    return {
      ...result,
      errors: [dayErrors],
    };
  }

  const postesCells = extractPostes(worksheet);
  const posteErrors = validatePostesOrReturnError(postesCells, postes);

  const hourQuarterCells = extractHourQuarter(worksheet, daysCells);
  const hourQuartersErrors = validateHourQuartersOrReturnError(
    hourQuarterCells,
    daysCells,
    openingHourQuarters,
  );

  if (posteErrors) result.errors.push(posteErrors);
  if (hourQuartersErrors) result.errors.push(hourQuartersErrors);

  result.periodStartsAt = period.start;
  result.periodEndsAt = period.end;

  // Early return if there is already errors
  if (result.errors.length > 0) return result;

  const workloadPlanCells = extractWorkloadPlans(worksheet);
  result.errors = validateWorkloadPlans(workloadPlanCells);
  if (result.errors.length > 0) return result;

  // Loop through postes columns
  postesCells.forEach(({ column, value: posteName }) => {
    // Find the poste id from the posteName
    const posteId = postes.find(poste => poste.attributes.name === posteName).id;
    result.workloadPlans[posteId] = [];

    // Loop through quarters and add the workload plan.
    // Add null if the workload plan is not present in the file
    hourQuarterCells.forEach(({ row: quarterRow, value: quarter }) => {
      // Either find a value, or use null to indicate absence of value
      const foundCellValue = workloadPlanCells.find(
        cell => cell.column === column && cell.row === quarterRow,
      )?.value ?? null;
      // Convert the number to integer if necessary
      const workloadPlanValue = foundCellValue ? +foundCellValue : foundCellValue;

      result.workloadPlans[posteId].push({ startsAt: quarter, value: workloadPlanValue });
    });
  });

  return result;
};

export const openAndReadWorkloadPlanFile =
  async ({ file, ...params }) => file.arrayBuffer().then(f => {
    const workbook = read(f);
    const worksheet = getWorksheet(workbook);

    return readWorksheetAndformatWorkloadPlans({ ...params, worksheet });
  });
