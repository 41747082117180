import Badgings from './Badgings';
import Days from './pages/Days';
import Users from './pages/Users';
import History from './pages/History';
import MatchedDaysBadgings from './pages/Days/MatchedBadgings';
import MatchedUsersBadgings from './pages/Users/MatchedBadgings';
import DayAbsences from './pages/Days/Absences';
import UserAbsences from './pages/Users/Absences';

const badgingsRoutes = [
  {
    path: '/v3/shops/:shop_id/badgings',
    component: Badgings,
    name: 'badgings',
    redirect: { name: 'badgings_days' },
    children: [
      {
        path: 'days',
        name: 'badgings_days',
        redirect: { name: 'badgings_shifts_days' },

        component: Days,
        children: [
          {
            path: 'shifts',
            name: 'badgings_shifts_days',
            component: MatchedDaysBadgings,
          },
          {
            path: 'planned_absences',
            name: 'badgings_absences_days',
            component: DayAbsences,
          },
        ],
      },
      {
        path: 'users/:user_id',
        name: 'badgings_users',
        redirect: { name: 'badgings_shifts_users' },
        component: Users,
        children: [
          {
            path: 'shifts',
            name: 'badgings_shifts_users',
            component: MatchedUsersBadgings,
          },
          {
            path: 'planned_absences',
            name: 'badgings_absences_users',
            component: UserAbsences,
          },
        ],
      },
      {
        path: 'history',
        name: 'badgings_history',
        component: History,
      },
    ],
  },
];

export default badgingsRoutes;
