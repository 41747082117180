<template>
  <div>
    <SkOroraDialog
      id="rename-folder-modal"
      ref="renameFolderDialog"
      size="small"
      :is-submit-disabled="isSubmitDisabled"
      :title="$t('shop_settings.documents_management.rename_folder.title')"
      :submit-button-label="$t('shop_settings.documents_management.rename_folder.submit')"
      @cancel="handleCancel"
      @close="handleCancel"
      @submit="openConfirmationDialog"
    >
      <template #body>
        <div class="rename-folder-modal">
          <SkOroraInput
            id="rename-folder-input"
            v-model.trim="newFolderName"
            :label="$t('shop_settings.documents_management.rename_folder.name_label')"
            :placeholder="$t('shop_settings.documents_management.rename_folder.name_placeholder')"
            :errored="hasError"
            :hint-message="errorMessage"
            @input="validateName"
            @keyup.enter="openConfirmationDialog"
          />
        </div>
      </template>
    </SkOroraDialog>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'RenameFolderModal',
  props: {
    existingFolderNames: {
      type: Array,
      default: () => [],
    },
    currentFolderName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newFolderName: '',
      hasError: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('foldersManagement', ['loadingRenameFolder']),
    folderNameExists() {
      return this.existingFolderNames
        .map(n => n.toLowerCase().trim())
        .includes(this.newFolderName.toLowerCase().trim()) &&
        this.newFolderName.toLowerCase().trim() !== this.currentFolderName.toLowerCase().trim();
    },
    isSubmitDisabled() {
      return (
        this.loadingRenameFolder ||
        !this.newFolderName.trim() ||
        this.newFolderName === this.currentFolderName ||
        this.folderNameExists ||
        this.hasError
      );
    },
  },
  watch: {
    currentFolderName(newVal) {
      this.newFolderName = newVal;
      this.validateName();
    },
  },
  mounted() {
    this.newFolderName = this.currentFolderName;
  },
  methods: {
    show() {
      this.$refs.renameFolderDialog.show();
    },
    openConfirmationDialog() {
      if (this.isSubmitDisabled) return;

      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t(
          'shop_settings.documents_management.rename_folder.confirm_message',
          { name: this.newFolderName },
        ),
        onCancel: this.handleCancel,
        onConfirm: this.handleSubmit,
        title: this.$t('shop_settings.documents_management.rename_folder.confirm_title'),
        submitColor: this.$skColors.skBlue50,
      });
    },
    handleSubmit() {
      if (this.isSubmitDisabled) return;
      this.$emit('rename-folder', this.newFolderName.trim());
      this.resetData();
      this.$refs.renameFolderDialog.hide();
    },
    closeConfirmationDialog() {
      this.$refs.confirmDialog.hide();
    },
    handleCancel() {
      this.resetData();
      this.$refs.renameFolderDialog.hide();
    },
    validateName() {
      if (this.folderNameExists) {
        this.setError(
          this.$t('shop_settings.documents_management.rename_folder.already_exists_error'),
        );
      } else {
        this.clearError();
      }
    },
    setError(message) {
      this.hasError = true;
      this.errorMessage = message;
    },
    clearError() {
      this.hasError = false;
      this.errorMessage = '';
    },
    resetData() {
      this.newFolderName = this.currentFolderName;
      this.clearError();
    },
  },
};
</script>

<style lang="scss" scoped>
.rename-folder-modal {
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__label {
    margin-bottom: 4px;
  }
}
</style>
