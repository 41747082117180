import { svcDocumentV2Client } from '@skello-utils/clients';

export const MAX_NUMBER_OF_FILES = 6;
export const MAX_FILE_SIZE_MB = 10;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB
export const LOCAL_URL_TIMEOUT_SECONDS = 30;
export const LOCAL_URL_TIMEOUT = LOCAL_URL_TIMEOUT_SECONDS * 1000;
export const AUTHORIZED_FILE_TYPE = [
  'application/pdf', // .pdf
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', // .doc
  'application/vnd.oasis.opendocument.text', // .odt
  'text/plain', // .txt
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.oasis.opendocument.spreadsheet', // ods
  'text/csv', // .csv
  'application/csv', // .csv
  'image/jpeg', // .jpg || .jpeg
  'image/png', // .png
  'image/gif', // .gif
  'image/bmp', // .bmp
  'image/tiff', // .tiff
  'audio/mpeg', // .mp3
  'audio/wav', // .wav
  'video/mp4', // .mp4
];
export const INLINE_FILE_TYPE = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/tiff',
  'audio/mpeg',
  'audio/wav',
  'video/mp4',
];
export const ERROR_TYPES = {
  MAX_FILES_EXCEEDED: 'max_file_exceeded',
  FILE_TYPE: 'file_type',
  FILE_SIZE: 'file_size',
  FILE_DUPLICATED: 'file_duplicated',
  DOCUMENT_LOAD: 'fetch_documents',
  DOCUMENT_UPLOAD: 'upload_documents',
  DOCUMENT_DELETE: 'delete_documents',
  INVALID: 'invalid',
};

const initialState = {
  documents: [],
  documentsMetadata: [],
  creatingAndUploading: false,
  loadingDocuments: false,
  error: null,
  uploadDocumentsQueue: [],
  deleteDocumentsQueue: [],
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loadingDocuments = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loadingDocuments = false;
    }
  },

  setError(state, error) {
    state.error = error;
  },

  setDocumentsMetadata(state, metadata) {
    state.documentsMetadata = metadata;
  },

  setDocuments(state, payload) {
    state.documents = payload;
  },

  fetchDocumentsSuccess(state, documents) {
    state.documents = documents;
  },

  createDocumentsSuccess(state, payload) {
    state.documentsMetadata.push(payload);
  },

  resetDocuments(state) {
    state.documents = [];
    state.documentsMetadata = [];
    state.uploadDocumentsQueue = [];
    state.deleteDocumentsQueue = [];
  },

  addToUploadQueue(state, payload) {
    state.uploadDocumentsQueue.push(payload);
  },

  removeFromUploadQueue(state, fileName) {
    state.uploadDocumentsQueue = state.uploadDocumentsQueue
      .filter(doc => doc.file.name !== fileName);
  },

  removeDocument(state, key) {
    state.documents = state.documents.filter(doc => doc.key !== key);
  },

  addToDeleteQueue(state, id) {
    if (typeof id === 'string') {
      state.deleteDocumentsQueue.push(id);
    }
  },
};

const actions = {
  async fetchDocuments({ commit }, { shiftId, shopId }) {
    /*
     find: returns a list of documents for a shift
     findUrl: returns the url of a document based on document id
    */
    if (shiftId) {
      try {
        commit('performingRequest', 'loadingDocuments');

        const response = await svcDocumentV2Client.document.find({ shiftId, shopId });
        commit('setDocumentsMetadata', response.documents);

        // This part can be separated to a different action, when clicking on the document link
        const documents = await Promise.all(
          response.documents.map(async doc => ({
            ...doc,
            url: await svcDocumentV2Client.document.getUrl(doc.id, INLINE_FILE_TYPE.includes(doc.mimeType) ? 'inline' : 'attachment'),
          })),
        );
        commit('fetchDocumentsSuccess', documents);
      } catch (error) {
        commit('setError', error);
        throw error;
      } finally {
        commit('requestComplete', 'loadingDocuments');
      }
    } else {
      commit('resetDocuments');
    }
  },
  async createAndUpload({ commit }, { dtos, files }) {
    try {
      commit('performingRequest', 'creatingAndUploading');

      const createDocumentsResponseDtos =
        await svcDocumentV2Client.document.createAndUpload(dtos, files);

      commit('setDocuments', createDocumentsResponseDtos);
      return createDocumentsResponseDtos;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },
  async createDocuments({ commit }, dtos) {
    try {
      commit('performingRequest', 'creatingAndUploading');

      const createDocumentsResponseDtos =
        await svcDocumentV2Client.document.create(dtos);

      commit('createDocumentsSuccess', createDocumentsResponseDtos);
      return createDocumentsResponseDtos;
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },
  async uploadDocuments({ commit }, { url, file }) {
    try {
      commit('performingRequest', 'creatingAndUploading');
      await svcDocumentV2Client.document.upload(url, file);
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('requestComplete', 'creatingAndUploading');
    }
  },

  deleteDocument({ commit }, { key }) {
    return svcDocumentV2Client.document.delete(key, 0)
      .then(() => {
        commit('removeDocument', key);
      })
      .catch(error => {
        commit('setError', error);
      });
  },

  async deleteDocuments({ commit, state }) {
    const deletePromises =
      state.deleteDocumentsQueue.map(
        documentKey => svcDocumentV2Client.document.delete(documentKey, 0)
          .then(() => {
            commit('removeDocument', documentKey);
          })
          .catch(error => {
            commit('setError', error);
            throw error;
          }),
      );

    await Promise.all(deletePromises);
  },
};

const getters = {
  isLoadingDocuments: state => state.loadingDocuments,
  documents: state => state.documents,
  documentsMetadata: state => state.documentsMetadata.map(doc => ({
    ...doc,
    name: doc.fileName,
    type: doc.mimeType,
    uploaded: true,
  })),
  hasError: state => !!state.error,
  uploadDocumentsQueue: state => state.uploadDocumentsQueue,
  deleteDocumentsQueue: state => state.deleteDocumentsQueue,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
