import skDate from '@skello-utils/dates';
import store from '@app-js/shared/store/index';
import { visibleDaysInPeriod } from './workload_plan_excel_helper';

export const MAX_WORKLOAD_PLAN_VALUE = 200;

/*
 * Validates the presence of the positions in the file.
 * Returns a list of errors found.
 */
export const validatePostesOrReturnError = (posteCells, shopPostes) => {
  const posteCellNames = posteCells.map(cell => cell.value);
  const shopPostesNames = shopPostes.map(poste => poste.attributes.name);

  const unknownPostes = posteCellNames.filter(pcn => !shopPostesNames.includes(pcn));

  const isUnknownPostes = unknownPostes.length > 0;
  const isMissingPoste = posteCellNames.length !== shopPostesNames.length;
  const isDuplicatePoste = [...new Set(posteCellNames)].length !== posteCellNames.length;

  return posteCellNames.length === 0 ||
         isUnknownPostes ||
         isMissingPoste ||
         isDuplicatePoste ?
    'invalid_postes' :
    undefined;
};

/*
 * Validates the presence of the period in the file.
 * Returns either an error message or undefined
 */
export const validatePeriodOrReturnError = ({ start, end }) => {
  const startDate = skDate(start, 'DD/MM/YYYY');
  const endDate = skDate(end, 'DD/MM/YYYY');

  const isStartValid = startDate.isValid();
  const isEndValid = endDate.isValid();
  const isStartBeforeEnd = startDate.isSameOrBefore(endDate);
  const isLessOrEqualToMax = skDate.duration(endDate.diff(startDate)).asWeeks() <
    store.getters['planningsWorkloadPlans/maximumWeeksRange'];

  return isStartValid && isEndValid && isStartBeforeEnd && isLessOrEqualToMax ?
    undefined :
    'invalid_structure';
};

/*
 * Validates the presence of the days in the file.
 * Returns either an error message or undefined
 */
export const validateDaysOrReturnError = (days, { start, end }, visibleDays) => {
  const periodStartDate = skDate.utc(start, 'DD/MM/YYYY');
  const periodEndDate = skDate.utc(end, 'DD/MM/YYYY');
  const allDays = visibleDaysInPeriod(periodStartDate, periodEndDate, visibleDays);

  // All days are in the right period (between start and end)
  const daysInPeriod =
    days.filter(day => skDate.utc(day.value) >= periodStartDate &&
      skDate.utc(day.value) <= periodEndDate,
    );

  // All days are in the period interval
  const areAllDaysInPeriod = daysInPeriod.length === days.length;

  // All days are present
  const allDaysArePresent = allDays.length === days.length;

  return areAllDaysInPeriod && allDaysArePresent ?
    undefined :
    'invalid_structure';
};

/*
 * Validates the presence of the hour quarters in the file.
 * Returns either an error message or undefined
 */
export const validateHourQuartersOrReturnError = (loadedHourQuarters, days, openingHours) => {
  const errors = [];

  days.forEach(day => {
    // Avoid doing the next days if one error is found
    if (errors.length > 0) return;

    let prevQuarter;

    openingHours.forEach(quarter => {
      if (errors.length > 0) return;

      const currentQuarter = skDate.utc(`${day.value}T${quarter}:00`);

      // If we pass midnight, add one day
      if (prevQuarter && prevQuarter > currentQuarter) {
        currentQuarter.add(1, 'day');
      }

      prevQuarter = currentQuarter;

      if (
        !loadedHourQuarters.find(
          loadedQuarter => loadedQuarter.value === currentQuarter.format('YYYY-MM-DDTHH:mm:ss'),
        )) {
        errors.push(currentQuarter.toISOString());
      }
    });
  });

  const totalNumberOfQuarters = days.length * openingHours.length;
  if (totalNumberOfQuarters !== loadedHourQuarters.length) errors.push('additional_quarters');

  return errors.length === 0 ?
    undefined :
    'invalid_opening_hours';
};

export const validateWorkloadPlans = workloadPlans => {
  const errors = [];

  workloadPlans.forEach(workloadPlan => {
    const isValueNull = workloadPlan.value === null;
    const isValueValid = isValueNull || /^\d+$/.test(workloadPlan.value);
    const isValueLessOrEqualToMax =
      isValueNull || !isValueValid || parseInt(workloadPlan.value, 10) <= MAX_WORKLOAD_PLAN_VALUE;

    if (!isValueValid) errors.push(`invalid_cell#not_positive_number#${workloadPlan.column}${workloadPlan.row}`);
    if (!isValueLessOrEqualToMax) errors.push(`invalid_cell#max_value#${workloadPlan.column}${workloadPlan.row}`);
  });

  return errors;
};
