<template>
  <div class="requests__wrapper">
    <div class="requests__navbar-separator" />
    <div
      v-if="isLoading"
      class="requests__loader"
    >
      <SkLoader size="large" />
    </div>
    <div
      v-else
      class="requests__container"
    >
      <router-view name="sidebar" />
      <div class="requests__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import RoutesMixin from '@app-js/requests/mixins/RoutesMixins';

export default {
  name: 'Requests',
  mixins: [RoutesMixin],
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.checkAuthorizedTab(to);

      if (
        vm.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P3') &&
        to.name.includes('leave_requests') &&
        !!to.query.show
      ) {
        vm.$skAnalytics.track('leave_request_show_through_url');
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.shop_id !== 'all') {
      if (String(to.params.shop_id) !== this.currentShop.id) {
        this.isSelectedShopLoaded = false;
        this.updateAndSelectShop(to.params.shop_id);
      }
    } else {
      this.isSelectedClusterNodeLoaded = false;
      let clusterNodeId = this.currentOrganisation.attributes.rootNodeId;
      if (to.query.cluster_node_id) {
        clusterNodeId = to.query.cluster_node_id;
      }
      this.updateAndSelectClusterNode(clusterNodeId);
    }

    const resetPagination = to.name !== from.name ||
      String(to.params.shop_id) !== this.currentShop.id;

    if (resetPagination) {
      this.setPagination({
        ...this.pagination,
        current_page: 1,
      });
    } else if (to.query.page) {
      this.setPagination({
        ...this.pagination,
        current_page: parseInt(to.query.page, 10),
      });
    }

    next();

    this.checkAuthorizedTab(to);
  },
  data() {
    return {
      isLicenseAuthorized: false,
      isSelectedShopLoaded: true,
      isSelectedClusterNodeLoaded: true,
    };
  },
  computed: {
    ...mapGetters('currentLicense', [
      'canManageEmployeeRequests',
      'canCreateSelfLeaveRequests',
      'isSystemAdmin',
      'canCreateSelfAvailabilities',
    ]),
    ...mapGetters('requests', ['canAccessLeaveRequests', 'canAccessAvailabilityRequests']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('navContext', ['navContext']),
    ...mapState('requests', ['pagination']),
    availabilityRequestsDefaultRoute() {
      return {
        name: this.canManageEmployeeRequests ?
          'availability_requests_teams' :
          'availability_requests_own',
      };
    },
    leaveRequestsDefaultRoute() {
      return {
        name: this.canManageEmployeeRequests ? 'leave_requests_teams' : 'leave_requests_own',
      };
    },
    isLoading() {
      return !this.isSelectedShopLoaded ||
      !this.isLicenseAuthorized ||
      !this.isSelectedClusterNodeLoaded;
    },
  },
  watch: {
    currentLicense() {
      this.checkAuthorizedTab(this.$router.currentRoute);
    },
  },
  async created() {
    let clusterNodeId = this.currentOrganisation.attributes.rootNodeId;
    if (this.$route.query?.cluster_node_id) {
      clusterNodeId = this.$route.query.cluster_node_id;
    }
    if (
      this.navContext.shopId &&
      this.navContext.shopId !== this.$route.params.shop_id
    ) {
      this.updateAndSelectShop(this.navContext.shopId);
    } else if ((
      this.navContext.clusterNodeId &&
      String(this.navContext.clusterNodeId) !== String(clusterNodeId)) ||
      this.currentNodeShops.length === 0
    ) {
      this.isSelectedClusterNodeLoaded = false;
      this.updateAndSelectClusterNode(this.navContext.clusterNodeId);
    }

    await this.fetchCurrentUserMemberships();
  },
  methods: {
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('navContext', ['selectShop', 'fetchCurrentClusterNode']),
    ...mapActions('currentOrganisation', ['fetchShopsForClusterNode']),
    ...mapActions('currentUser', [
      'fetchCurrentUserMemberships',
    ]),
    ...mapMutations('requests', ['setPagination']),
    async updateAndSelectShop(shopId) {
      try {
        await this.updateCurrentShop({ shopId });

        this.selectShop(this.currentShop);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }
      this.isSelectedShopLoaded = true;
    },
    async updateAndSelectClusterNode(clusterNodeId) {
      try {
        await Promise.all([
          this.fetchCurrentClusterNode(clusterNodeId),
          this.fetchShopsForClusterNode({ clusterNodeId, onlyActiveShops: true }),
        ]);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }
      this.isSelectedClusterNodeLoaded = true;
    },
    async checkAuthorizedTab(toRoute) {
      // when no current license: still loading, method will be retriggered once license is fetched
      if (!this.currentLicense.id) {
        return;
      }

      const isAllowedOnPage = toRoute.name.includes('leave_requests') ? this.canAccessLeaveRequests : this.canAccessAvailabilityRequests;
      // when not allowed on the page redirect to home
      if (!isAllowedOnPage) {
        this.isLicenseAuthorized = false;
        this.redirectToHome();
        return;
      }

      if (!this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_V3_ALL') &&
      toRoute.path.includes('/all')) {
        this.redirectToDefaultRequests(
          this.leaveRequestsDefaultRoute,
          this.currentUser.attributes.shopId,
        );
        return;
      }

      if (toRoute.name === 'leave_requests') {
        this.redirectToDefaultRequests();
        return;
      }

      if (toRoute.name === 'availability_requests') {
        this.redirectToDefaultRequests(this.availabilityRequestsDefaultRoute);
        return;
      }

      // when accessed directly through /requests redirect to the first page (leave_requests_own)
      if (toRoute.name === 'requests') {
        this.redirectToDefaultRequests();
        return;
      }

      if (this.isAllowedOnTab(toRoute.name)) {
        this.isLicenseAuthorized = true;
      } else {
        this.redirectToDefaultRequests();
      }
    },
    isAllowedOnTab(toRoute) {
      if (toRoute === 'leave_requests_teams') {
        return this.isSystemAdmin || this.canManageEmployeeRequests;
      }

      if (toRoute === 'leave_requests_own') {
        return this.isSystemAdmin || this.canCreateSelfLeaveRequests;
      }

      if (toRoute === 'availability_requests_teams') {
        return this.isSystemAdmin || this.canManageEmployeeRequests;
      }

      if (toRoute === 'availability_requests_own') {
        return this.isSystemAdmin || this.canCreateSelfAvailabilities;
      }

      return false;
    },
    redirectToHome() {
      window.location = '/';
    },
    async redirectToDefaultRequests(route = this.leaveRequestsDefaultRoute, shopId) {
      if (shopId) {
        route.params = { shop_id: shopId };
      }

      this.$router.replace(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.requests__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}

.requests__navbar-separator {
  border-top: 1px solid $sk-grey-10;
}

.requests__loader {
  display: flex;
  justify-content: center;
  font-size: 2em;
  padding-top: 4em;
}

.requests__container {
  display: flex;
  height: 100%;
}

.requests__content {
  margin: 32px 32px 0 0;
  flex: 1;
}
</style>
