<template>
  <div>
    <PeriodSummarySection
      v-if="!isLoading && shopAbsenceConfig && hasCounters"
      :is-loading="isRttCounterLoading || isRttDataLoading"
      :period-summary="{
        balance,
        isCurrentPeriod,
        manualChangesCount,
        owedRtt,
        reducedRtt,
        takenRtt,
        title: periodSummaryTitle,
      }"
    />
    <div class="employee-counters__wrapper">
      <div
        v-if="!isLoading && (!shopAbsenceConfig || !hasCounters)"
        class="employee-counters__wrapper--initialization"
      />
      <div
        v-if="isLoading"
        class="counters-spinner__wrapper"
      >
        <SkLoader />
      </div>
      <RttCounterTable />
      <InitializationOverlay
        v-if="!isLoading && (!shopAbsenceConfig || !hasCounters)"
        :title="$t('employees.tabs.counters.rtt_counter.welcome')"
        :description="$t('employees.tabs.counters.rtt_counter.helper')"
        icon="CalendarClockIcon"
      />
    </div>
    <SkInfoCard
      v-if="!isLoading && hasCounters"
      class="employee-counters__info-card"
    >
      <div class="employee-counters__info-card__title">
        {{ $t('employees.tabs.counters.rtt_counter.explanation_card.title') }}
      </div>
      <div>
        {{ $t(`employees.tabs.counters.rtt_counter.explanation_card.content`) }}
      </div>
    </SkInfoCard>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import InitializationOverlay from './InitializationOverlay';
import PeriodSummarySection from './PeriodSummarySection';
import RttCounterTable from './RttCounterTable';

export default {
  name: 'RttCounter',
  components: { InitializationOverlay, PeriodSummarySection, RttCounterTable },
  data() {
    return {
      horizontallyScrolled: null,
      tableWidth: null,
    };
  },
  computed: {
    ...mapGetters('employeeRttCounter', ['hasCounters', 'isRttCounterLoading']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('employeeRttCounter', [
      'balance',
      'isRttDataLoading',
      'manualChangesCount',
      'owedRtt',
      'reducedRtt',
      'rttShifts',
      'selectedPeriodStartDate',
      'selectedPeriodEndDate',
      'takenRtt',
    ]),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('shopAbsenceConfig', ['isShopAbsenceConfigLoading', 'shopAbsenceConfig']),
    isLoading() {
      return this.isRttCounterLoading || this.isShopAbsenceConfigLoading;
    },
    isCurrentPeriod() {
      const current = skDate().utc();

      return current >= this.selectedPeriodStartDate && current <= this.selectedPeriodEndDate;
    },
    periodSummaryTitle() {
      const label = this.$t('employees.tabs.counters.rtt_counter.summary_section.title');

      if (!this.selectedPeriodStartDate) return label;

      const startDate = this.selectedPeriodStartDate.format('Do MMMM YYYY');
      const endDate = this.selectedPeriodEndDate.format('Do MMMM YYYY');

      if (this.isCurrentPeriod) {
        return `${startDate} - ${endDate} (${label})`;
      }

      return `${startDate} - ${endDate}`;
    },
  },
  mounted() {
    if (!this.shopAbsenceConfig) {
      this.fetchShopAbsenceConfig(this.employee.attributes.shopId)
        .then(response => {
          if (!response.data.data) return;

          this.setShopRttConfig(this.shopAbsenceConfig.attributes.rttConfig);
          this.fetchEmployeeConfig();
        })
        .catch(() => this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
          containerId: 'employees__container',
        }));
    } else {
      this.setShopRttConfig(this.shopAbsenceConfig.attributes.rttConfig);
      this.fetchEmployeeConfig();
    }
  },
  methods: {
    ...mapActions('employeeRttCounter', ['fetchCounterData', 'fetchEmployeeAbsenceConfig', 'setPeriodDates']),
    ...mapActions('shopAbsenceConfig', ['fetchShopAbsenceConfig']),
    ...mapMutations('employeeRttCounter', ['setEmployeeAbsenceConfig', 'setShopRttConfig']),
    fetchEmployeeConfig() {
      const params = {
        userId: this.employee.id,
        shopId: this.employee.attributes.shopId,
        absenceType: 'RTT',
      };

      this.fetchEmployeeAbsenceConfig(params)
        .then(() => {
          if (this.hasCounters) {
            this.setPeriodDates().then(() => this.fetchCounterData(params));
          }
        })
        .catch(() => { this.setEmployeeAbsenceConfig({}); });
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-counters__wrapper--initialization {
  padding-top: 40px;
  background: white;
}
</style>
