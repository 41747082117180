<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="$t('reports.commentary.title')"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#FAF7F6"
      border-color="#734D41"
      data-test="column__commentary__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__commentary__opened"
    >
      <div
        class="column__header comments-title"
        data-test="column__commentary__close-button"
        @click="closeColumn"
      >
        {{ $t('reports.commentary.title') }}

        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
      </div>

      <table>
        <thead class="column__title-wrap">
          <th class="column__title column__title--open" />
        </thead>

        <tbody>
          <template v-for="employeeInfos in employeesInfos">
            <tr
              v-for="contractInfos in employeeInfos.contracts_infos"
              :key="contractInfos.contract.id"
              class="column__cell-wrap"
            >
              <!-- Commentaires -->
              <td class="column__cell column__cell--open">
                <div class="column__cell__wrapper">
                  <SkCircleButton
                    v-if="canEditReportComment"
                    class="report-comment__icon"
                    icon="PencilV2Icon"
                    :data-test="`comment-button__employee-${employeeInfos.user_infos.id}`"
                    @click="
                      displayCommentModal(employeeInfos.user_infos.id, contractInfos.contract.id)
                    "
                  />
                  <span
                    :ref="`report-comment-${contractInfos.contract.id}`"
                    v-tooltip.left="commentTooltip"
                    :class="getReportCommentClass(contractInfos.report_comment)"
                    @mouseenter="
                      setCommentTooltip(contractInfos.contract.id, contractInfos.report_comment)
                    "
                  >
                    {{ decorateComment(contractInfos.report_comment) }}
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'CommentaryColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    canEditReportComment: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: [String, Date],
      default: '',
    },
    endDate: {
      type: [String, Date],
      default: '',
    },
  },
  data() {
    return {
      commentTooltip: null,
    };
  },
  methods: {
    decorateComment(reportComment) {
      if (!this.isReportCommentAvailable(reportComment)) {
        return this.$t('reports.commentary.add');
      }

      return reportComment.comment;
    },
    displayCommentModal(employeeId, contractId) {
      this.$emit('display-comment-modal', { employeeId, contractId });
    },
    setCommentTooltip(contractId, reportComment) {
      if (!this.isReportCommentAvailable(reportComment)) {
        this.commentTooltip = null;
        return;
      }

      const element = this.$refs[`report-comment-${contractId}`][0];

      const isEllipsActive = element.offsetWidth < element.scrollWidth;
      this.commentTooltip = isEllipsActive ? reportComment.comment : null;
    },
    getReportCommentClass(reportComment) {
      return {
        'report-comment': true,
        'report-comment--active': this.isReportCommentAvailable(reportComment),
      };
    },
    isReportCommentAvailable(reportComment) {
      return reportComment?.comment && reportComment.comment.length !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.column__cell__wrapper {
  position: relative;
  min-width: 194px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comments-title {
  border-top: 4px solid #734d41;
}

.comments-title {
  padding: 12px 0 68px !important;
}

.column__title--open {
  padding: 0 !important;
  min-width: 0 !important;
}

.report-comment {
  max-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 4px;
  color: $sk-grey-30;

  &--active {
    color: $sk-black;
  }
}
</style>
