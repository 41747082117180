<template>
  <td
    :class="sidebarClasses"
    @click="handleShopSidebarClicked"
  >
    <div class="shop-cell">
      <div class="shop-cell--icon">
        <ShopV2Icon
          width="18"
          height="18"
          fill="#2b66fe"
        />
      </div>

      <div class="shop-cell--content">
        <span>{{ name }}</span>
        <span>{{ $t('employee_view.table.shop_cell.subtitle') }}</span>
      </div>
    </div>
  </td>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ShopSidebarCell',
  props: {
    name: {
      type: String,
      required: true,
    },
    shopId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    canSeeOthersSchedules() {
      const {
        attributes: { canReadSelfOnly, canReadTeammates, canReadAllEmployees },
      } = this.currentLicense;

      return !canReadSelfOnly && (canReadTeammates || canReadAllEmployees);
    },
    sidebarClasses() {
      return {
        'sidebar-clickable': this.canSeeOthersSchedules,
        'sidebar-non-clickable': !this.canSeeOthersSchedules,
      };
    },
  },
  methods: {
    handleShopSidebarClicked() {
      if (!this.canSeeOthersSchedules) return;

      this.$router.push({
        name: 'plannings_employee_team_schedule',
        params: { shop_id: this.shopId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.shop-cell {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0 12px;

  &--icon {
    background-color: $sk-blue-10;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: $fs-text-s;
    font-weight: $fw-regular;
    line-height: 13.38px;
    text-align: start;

    span:last-child {
      font-size: $fs-text-xs;
      color: $sk-blue-50;
      line-height: 11.15px;
    }
  }
}

.sidebar-clickable {
  cursor: pointer;
}

.sidebar-non-clickable {
  cursor: not-allowed;
}
</style>
