<template>
  <div :class="requestPageClasses">
    <RequestsPageHeader
      v-if="!isFetchingRequests && (requests.length > 0 || areFiltersActive)"
      :title="requestsLabels.title"
      :subtitle="requestsLabels.subtitle"
    >
      <template
        #actions
      >
        <RequestsFilterPopover />
        <SkOroraButton
          v-if="!isAvailabilityRequestsView || !isTeamsRequestsView"
          :data-test="dataTest"
          variant="highlighted"
          :is-button-disabled="isAskRequestButtonDisabled"
          @click="handleNewRequestButtonClick"
        >
          {{ requestsLabels.create }}
        </SkOroraButton>
      </template>
    </RequestsPageHeader>
    <RequestsTable
      v-show="
        !isManagingRequestThroughUrl && !isFetchingRequests &&
          (requests.length > 0 || areFiltersActive)
      "
    />
    <div
      v-if="isManagingRequestThroughUrl || isFetchingRequests"
      class="requests__loader"
    >
      <SkLoader size="large" />
    </div>
    <EmptyRequestsPage
      v-else-if="
        !isManagingRequestThroughUrl &&
          !isFetchingRequests &&
          requests.length === 0 &&
          !areFiltersActive
      "
      v-bind="emptyPageProps"
      :button-callback="emptyPageButtonCallback"
      :is-button-disabled="isAskRequestButtonDisabled"
      :add-button-data-test="dataTest"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ManageLeaveRequestModal
        v-if="isLeaveRequestsView"
        :source="{ page: 'leave_requests', sub: 'team' }"
      />
      <NewLeaveRequestModal v-if="isLeaveRequestsView" />
      <TransferLeaveRequestModal
        v-if="isLeaveRequestsView &&
          isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P2')"
      />
      <EmployeeAvailabilitiesModal
        v-if="isAvailabilityRequestsView"
        @submit="initData"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';

import axios from 'axios';
import { staticRootUrl } from '@config/env';
import { openFile } from '@skello-utils/file.js';
import { svcRequestsClient } from '@skello-utils/clients';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import GenericFilterHandler from '@app-js/shared/utils/generic_filter_handler';

import ManageLeaveRequestModal from '@app-js/shared/components/ManageLeaveRequestModal';
import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';

import TransferLeaveRequestModal from '@app-js/shared/components/TransferLeaveRequestModal.vue';
import EmployeeAvailabilitiesModal from './EmployeeAvailabilitiesModal/EmployeeAvailabilitiesModal';
import EmptyRequestsPage from './EmptyRequestsPage';
import NewLeaveRequestModal from './NewLeaveRequestModal';
import RequestsPageHeader from './RequestsPageHeader';
import RequestsTable from './RequestsTable';
import RequestsFilterPopover from './RequestsFilterPopover';

export default {
  name: 'RequestsPage',
  components: {
    EmptyRequestsPage,
    ManageLeaveRequestModal,
    RequestsPageHeader,
    RequestsTable,
    NewLeaveRequestModal,
    EmployeeAvailabilitiesModal,
    RequestsFilterPopover,
    TransferLeaveRequestModal,
  },
  mixins: [RoutesMixins],
  data() {
    return {
      isDownloadingGuide: false,
      isManagingRequestThroughUrl: false,
      requestIdToAcceptThroughUrl: null,
      requestIdToTransferThroughUrl: null,
      availabilityRequestsLabels: {
        own: {
          title: this.$t('requests.availability_requests.own.title'),
          subtitle: this.$t('requests.availability_requests.own.subtitle'),
          create: this.$t('requests.availability_requests.own.cta.create'),
        },
        teams: {
          title: this.$t('requests.availability_requests.teams.title'),
          subtitle: this.$t('requests.availability_requests.teams.subtitle'),
        },
      },
      leaveRequestsLabels: {
        own: {
          title: this.$t('requests.leave_requests.own.title'),
          subtitle: this.$t('requests.leave_requests.own.subtitle'),
          create: this.$t('requests.leave_requests.own.cta.create'),
        },
        teams: {
          title: this.$t('requests.leave_requests.teams.title'),
          subtitle: this.$t('requests.leave_requests.teams.subtitle'),
          create: this.$t('requests.leave_requests.teams.cta.create'),
        },
      },
      emptyAvailabilityRequestsPageProps: {
        own: {
          icon: 'TeamV2Icon',
          buttonText: this.$t('requests.availability_requests.empty_page.own.no_request.button'),
          contentText: this.$t('requests.availability_requests.empty_page.own.no_request.content'),
          headerText: this.$t('requests.availability_requests.empty_page.own.no_request.header'),
        },
        teams: {
          icon: 'TeamV2Icon',
          buttonText: null,
          contentText: this.$t('requests.availability_requests.empty_page.teams.no_request.content'),
          headerText: this.$t('requests.availability_requests.empty_page.teams.no_request.header'),
        },
      },
      emptyLeaveRequestsPageProps: {
        own: {
          icon: 'PalmTreeIcon',
          buttonText: this.$t('requests.leave_requests.empty_page.own.no_request.button'),
          contentText: this.$t('requests.leave_requests.empty_page.own.no_request.content'),
          headerText: this.$t('requests.leave_requests.empty_page.own.no_request.header'),
        },
        teams: {
          icon: 'TeamV2Icon',
          buttonText: this.$t('requests.leave_requests.empty_page.teams.no_request.button'),
          contentText: this.$t('requests.leave_requests.empty_page.teams.no_request.content'),
          headerText: this.$t('requests.leave_requests.empty_page.teams.no_request.header'),
        },
      },
      filterHandler: null,
    };
  },
  computed: {
    ...mapGetters('requests', [
      'canAccessLeaveRequests',
      'teamDefaultFiltersLeaveRequests',
      'ownDefaultFiltersLeaveRequests',
      'teamAllDefaultFiltersLeaveRequests',
      'ownAllDefaultFiltersLeaveRequests',
      'areFiltersActive',
      'tableFilters',
      'currentShopIds',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('requests', [
      'availabilitiesPagination',
      'availabilityRequests',
      'isFetchingAvailabilityRequests',
      'isFetchingLeaveRequests',
      'leaveRequests',
      'pagination',
      'sort',
    ]),
    ...mapGetters('currentLicense', ['canManageEmployeeRequests', 'isSystemAdmin']),
    ...mapGetters('currentUser', ['currentUserInPlanningShopIds']),

    dataTest() {
      if (!this.isLeaveRequestsView) {
        return this.isOwnRequestsView ? 'availability-request__add-request__button' : '';
      }

      return this.isOwnRequestsView ?
        'leave-request__add-absence__button' :
        'leave-request__add-request__button';
    },
    defaultFilters() {
      if (this.isAllRequestsView) {
        return this.isOwnRequestsView ?
          this.ownAllDefaultFiltersLeaveRequests : this.teamAllDefaultFiltersLeaveRequests;
      }
      return this.isOwnRequestsView ?
        this.ownDefaultFiltersLeaveRequests : this.teamDefaultFiltersLeaveRequests;
    },
    emptyPageProps() {
      const pageProps = this.isLeaveRequestsView ?
        'emptyLeaveRequestsPageProps' :
        'emptyAvailabilityRequestsPageProps';

      return this.isOwnRequestsView ? this[pageProps].own : this[pageProps].teams;
    },
    emptyPageButtonCallback() {
      if (this.isOwnRequestsView) {
        return this.handleNewRequestButtonClick;
      }

      return this.isLeaveRequestsView ? this.handleDownloadGuideClick : () => {};
    },
    isFetchingRequests() {
      return this.isLeaveRequestsView ?
        this.isFetchingLeaveRequests :
        this.isFetchingAvailabilityRequests;
    },
    requests() {
      return this.isLeaveRequestsView ? this.leaveRequests : this.availabilityRequests;
    },
    requestsLabels() {
      const requestsLabels = this.isLeaveRequestsView ?
        this.leaveRequestsLabels :
        this.availabilityRequestsLabels;

      return this.isOwnRequestsView ? requestsLabels.own : requestsLabels.teams;
    },
    requestPageClasses() {
      return {
        'requests-page': true,
        'requests-page--downloading-guide': this.isDownloadingGuide,
      };
    },
    localStorageKey() {
      if (this.isLeaveRequestsView) {
        if (this.isAllRequestsView) {
          return this.isOwnRequestsView ? 'leave_requests_own_all' : 'leave_requests_teams_all';
        }
        return this.isOwnRequestsView ? 'leave_requests_own' : 'leave_requests_teams';
      }

      return this.isOwnRequestsView ? 'availability_requests_own' : 'availability_requests_teams';
    },
    storageFilterKeys() {
      if (this.isLeaveRequestsView) {
        if (this.isAllRequestsView) {
          return this.isOwnRequestsView ? ['status', 'absenceTypeId', 'shops'] : ['status', 'employeeId', 'teams', 'absenceTypeId', 'availabilityType'];
        }
        return this.isOwnRequestsView ? ['status', 'absenceTypeId'] : ['status', 'employeeId', 'teams', 'absenceTypeId'];
      }

      return this.isOwnRequestsView ? ['status', 'absenceTypeId', 'shops'] : ['status', 'employeeId', 'teams', 'absenceTypeId', 'availabilityType'];
    },
    isAskRequestButtonDisabled() {
      let isCurrentUserDisplayedInPlanning = !this.currentUserInPlanningShopIds.length;

      if (!this.isAllRequestsView) {
        const currentShopId = parseInt(this.currentShop.id, 10);
        isCurrentUserDisplayedInPlanning = !this.currentUserInPlanningShopIds.includes(
          currentShopId,
        );
      }

      return this.isDownloadingGuide || isCurrentUserDisplayedInPlanning;
    },
  },
  watch: {
    async $route(to, from) {
      if (this.requestIdToAcceptThroughUrl) {
        await this.acceptLeaveRequestThroughUrl();
      }

      if (this.requestIdToTransferThroughUrl) {
        await this.transferLeaveRequestThroughUrl();
      }

      if (to.name !== from.name) {
        this.setFilters(this.defaultFilters);
        this.filterHandler = new GenericFilterHandler(
          this.setFilters,
          this.storageFilterKeys,
          this.localStorageKey,
        );
        this.filterHandler.applyFilters(to);
      }

      await this.initData();
    },
  },
  async mounted() {
    this.checkIfUserCanAccessOrRedirect();
    this.setFilters(this.defaultFilters);

    this.filterHandler = new GenericFilterHandler(
      this.setFilters,
      this.storageFilterKeys,
      this.localStorageKey,
    );
    this.filterHandler.applyFilters(this.$route);

    if (Object.keys(this.tableFilters).length === 0) {
      this.setFilters(this.defaultFilters);
    }

    if (
      this.isLeaveRequestsView &&
      this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_USE_MICROSERVICE_P3') && (
        this.$route.query.accept || this.$route.query.transfer
      )
    ) {
      this.requestIdToAcceptThroughUrl = this.$route.query.accept;
      this.requestIdToTransferThroughUrl = this.$route.query.transfer;

      this.$router.replace({
        query: {
          ...structuredClone(this.$route.query),
          accept: undefined,
          transfer: undefined,
        },
      });

      return; // initData will be handled by the watcher on $route
    }

    await this.initData();
  },
  methods: {
    ...mapActions('requests', [
      'acceptLeaveRequest',
      'fetchAvailabilityRequests',
      'fetchLeaveRequests',
      'fetchManagedEmployees',
    ]),
    ...mapMutations('requests', ['setFilters']),
    async acceptLeaveRequestThroughUrl() {
      this.isManagingRequestThroughUrl = true;
      this.$skAnalytics.track('leave_request_accept_through_url');

      try {
        const targetedRequest = await svcRequestsClient.getLeaveRequest(
          this.requestIdToAcceptThroughUrl,
        );

        if (!targetedRequest || !!targetedRequest.deletedAt) {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.not_found'), variant: 'error' });

          return;
        }

        if (targetedRequest.status === 'pending') {
          await this.acceptLeaveRequest({
            leaveRequestId: targetedRequest.id,
            leaveRequestParams: {
              status: 'accepted',
              calculation: targetedRequest.calculation,
              startsAt: targetedRequest.startsAt,
              endsAt: targetedRequest.endsAt,
              shopId: targetedRequest.shopId,
              validatorId: this.currentUser.id,
            },
          });
        }
      } catch (error) {
        if (error.message === 'days_locked') {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.days_locked'), variant: 'error' });
        } else if (error.response.status === 403) {
          this.$skToast({ message: error.response.data.error, variant: 'error' });
        } else if (error.response.status === 422) {
          this.$skToast({ message: this.$t('requests.leave_request.toast.error.unprocessable'), variant: 'error' });
        } else {
          this.$skToast({ message: this.$t('requests.toast.error'), variant: 'error' });

          throw error;
        }
      } finally {
        this.requestIdToAcceptThroughUrl = null;
        this.isManagingRequestThroughUrl = false;
      }

      this.$skToast({ message: this.$t('requests.leave_requests.toast.success.accept'), variant: 'success' });
    },
    async transferLeaveRequestThroughUrl() {
      this.isManagingRequestThroughUrl = true;
      this.$skAnalytics.track('leave_request_transfer_through_url');

      try {
        const targetedRequest = await svcRequestsClient.getLeaveRequest(
          this.requestIdToTransferThroughUrl,
        );

        if (!targetedRequest || !!targetedRequest.deletedAt) {
          this.$skToast({ message: this.$t('requests.leave_requests.toast.error.not_found'), variant: 'error' });

          return;
        }

        if (targetedRequest.status === 'pending') {
          this.emitOnRoot('open-transfer-request-modal', {
            request: targetedRequest,
            hideBackButton: true,
            source: { page: 'leave_requests' },
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.$skToast({ message: error.response.data.error, variant: 'error' });
        } else {
          this.$skToast({ message: this.$t('requests.toast.error.unprocessable'), variant: 'error' });

          throw error;
        }
      } finally {
        this.requestIdToTransferThroughUrl = null;
        this.isManagingRequestThroughUrl = false;
      }
    },
    handleNewRequestButtonClick(event) {
      if (this.isLeaveRequestsView) {
        this.emitOnRoot('hide-dropdown-popover');

        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'new-leave-request-modal');
      } else if (this.isAvailabilityRequestsView) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'employee-availabilities-modal');
      }
    },
    async handleDownloadGuideClick() {
      const locale = this.$i18n.locale;

      const documentName = ['en', 'es', 'fr', 'it'].includes(locale) ?
        `employee_commitment_poster_${locale.toUpperCase()}.pdf` :
        'employee_commitment_poster_EN.pdf';

      const url = `${staticRootUrl}/documents/${documentName}`;

      this.isDownloadingGuide = true;

      // URL is an s3 bucket link
      // download attribute of anchor tag does not allow cross domain URLs and opens the file in the browser
      // so in order to download the file without opening it we have to use axios
      const response = await axios.create().get(url, { responseType: 'ps' });

      this.isDownloadingGuide = false;

      openFile({
        blob: response.data,
        fileName: this.$t('requests.leave_requests.guide_filename'),
        type: 'application/pdf',
        format: 'pdf',
      });

      this.$skAnalytics.track('leave_request_download_guide');
    },
    async initData() {
      const pagination = this.isLeaveRequestsView ? this.pagination : this.availabilitiesPagination;
      const shopIds = this.isAllRequestsView ?
        this.currentNodeShops.map(shop => shop.id) :
        [this.currentShop.id];
      const params = {
        isOwnRequestsView: this.isOwnRequestsView,
        shopIds,
        userId: this.currentUser.id,
        pagination: this.$route.query.page ?
          { ...pagination, current_page: parseInt(this.$route.query.page, 10) } :
          pagination,
        sort: this.sort,
        filters: this.tableFilters,
        isAllRequestsView: this.isAllRequestsView,
      };

      try {
        const promise = [];
        if (this.canManageEmployeeRequests || this.isSystemAdmin) {
          const managedEmployeeParams = {};
          if (this.isAllRequestsView) {
            managedEmployeeParams.cluster_node_id = this.navContext.clusterNodeId;
          } else {
            managedEmployeeParams.shop_id = this.currentShop.id;
          }
          promise.push(this.fetchManagedEmployees(managedEmployeeParams));
        }

        if (this.isLeaveRequestsView) {
          promise.push([
            this.fetchLeaveRequests(params),
          ]);
        }

        if (this.isAvailabilityRequestsView) {
          promise.push([
            this.fetchAvailabilityRequests(params),
          ]);
        }
        await Promise.all(promise);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });

        throw error;
      }
    },
    checkIfUserCanAccessOrRedirect() {
      if (!this.currentUser.relationships.memberships || !this.canManageEmployeeRequests) {
        return;
      }

      let shouldRedirect = !this.currentUserInPlanningShopIds.length;

      const currentShopId = parseInt(this.currentShop.id, 10);

      if (!this.isAllRequestsView) {
        shouldRedirect = !this.currentUserInPlanningShopIds.includes(currentShopId);
      }

      if (shouldRedirect) {
        this.$router.replace({ name: 'leave_requests_teams' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.requests-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;

  &--downloading-guide {
    cursor: progress;
  }

  .requests__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 310px + 32px); // sidebar width + global padding
  }
}
</style>
