<template>
  <SkSelectV2
    v-model="selectedUserIds"
    :disabled="disabled"
    :options="userOptions"
    :group-options="teamOptions"
    :label="customLabel || $t('plannings.toolbar.modal.shared.select.employees')"
    :search-placeholder="$t('actions.search')"
    :no-results-label="
      $t('plannings.toolbar.modal.shared.select.no_search_results')
    "
    width="320px"
    :data-test="dataTest"
    multi
    append-to-body
    no-confirm
  >
    <template #group-count="{ count }">
      {{ $tc('plannings.toolbar.modal.shared.select.employee_count', count) }}
    </template>
    <template #selected-option="{ value: selecteUserIds }">
      {{
        $tc(
          'plannings.toolbar.modal.shared.select.employee_count',
          selecteUserIds.length
        )
      }}
    </template>
    <template
      v-if="displayOptionsSubtext"
      #option="{ option }"
    >
      <div class="text-truncate">
        {{ option.text }}
        <div class="select-option__subtext text-truncate">
          {{ option.subtext }}
        </div>
      </div>
    </template>
  </SkSelectV2>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlanningEmployeesSelect',
  props: {
    initialUserIds: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayOptionsSubtext: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
    teams: {
      type: Array,
      required: true,
    },
    dataTest: {
      type: String,
      default: undefined,
    },
    canDisplayUnassignedShift: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedUserIds: [],
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName']),
    optionHeaders() {
      const optionHeaders = [
        { id: 'all', text: this.$t('plannings.toolbar.modal.shared.select.all'), matchKey: '*' },
        { id: 'separator', separator: true, matchKey: null },
      ];
      if (this.teams.length !== 0) {
        optionHeaders.push({ id: 2, text: this.$t('plannings.toolbar.modal.shared.select.teams'), matchKey: null });
      }
      return optionHeaders;
    },
    teamOptions() {
      const teams = this.teams.map(currentTeam => ({
        id: currentTeam.id,
        text: currentTeam.attributes.name,
        matchKey: 'teamId',
        matchVal: currentTeam.id,
      }));

      teams.push({ id: 'employees', text: this.$t('navbar.employees'), matchKey: null });
      return this.optionHeaders.concat(teams);
    },
    userOptions() {
      const options = this.users.map(user => ({
        id: user.id,
        text: this.fullName(user),
        teamId: user.relationships.teams.data.map(team => team.id),
        ...(this.displayOptionsSubtext && { subtext: user.subtext }),
      }));

      if (this.canDisplayUnassignedShift) {
        options.unshift({
          id: 'unassigned',
          text: this.$t('plannings.toolbar.modal.shared.select.unassigned'),
        });
      }
      return options;
    },
  },
  watch: {
    selectedUserIds() {
      this.$emit('update-users', this.selectedUserIds);
    },
  },
  created() {
    if (this.initialUserIds) {
      this.selectedUserIds = this.initialUserIds;
    }
  },
};
</script>

<style lang="scss" scoped>
.select-option__subtext {
  font-size: $fs-text-s;
  color: $sk-grey;
}
</style>
