<template>
  <SkOroraDialog
    id="zelty-authentication-modal"
    ref="zeltyAuthenticationModal"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="zeltyShopsLoading"
    :submit-button-label="$t('organisation_settings.tabs.pos_partners.laddition.modal.submit_label')"
    :title="$t('organisation_settings.tabs.pos_partners.zelty.modal.title')"
    size="medium"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <main class="zelty-authentication-modal">
        <section class="sk-text-medium-regular">
          <span>{{ $t('organisation_settings.tabs.pos_partners.zelty.modal.description') }}</span>
          <span>{{ $t('organisation_settings.tabs.pos_partners.zelty.modal.sub_description') }}</span>
        </section>
        <section class="zelty-authentication-modal__input">
          <SkInput
            v-model="zeltyToken"
            :errored="hasApiKeyError"
            :error-message="computedErrorMessage"
            :label="$t('organisation_settings.tabs.pos_partners.zelty.modal.api_key_label')"
            @keyup="handleKeyUp"
          />
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';

export default {
  name: 'ZeltyAuthenticationModal',
  data() {
    return {
      zeltyToken: '',
      hasApiKeyError: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState('partnersTools', ['zeltyShopsLoading']),
    isSubmitDisabled() {
      return !this.zeltyToken || this.hasApiKeyError;
    },

    computedErrorMessage() {
      return this.$t(
        'organisation_settings.tabs.pos_partners.zelty.modal.authenticate_error',
        { error: this.errorMessage },
      );
    },
  },
  methods: {
    ...mapActions('partnersTools', ['fetchZeltyShops']),
    handleSubmit() {
      if (this.isSubmitDisabled) return;

      this.fetchZeltyShops(this.zeltyToken)
        .then(() => {
          this.$refs.zeltyAuthenticationModal.hide();
          this.zeltyToken = '';
          this.$emit('success');

          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.zelty.modal.authenticate_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.hasApiKeyError = true;
          this.errorMessage = error.response.data.message;
        });
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data());
      this.$emit('cancel');
    },
    handleKeyUp(event) {
      // if press enter then submit. otherwise, reset hasApiError
      if (event.key === 'Enter') {
        this.handleSubmit(event);
        return;
      }

      this.hasApiKeyError = false;
    },
    openModal() {
      this.$refs.zeltyAuthenticationModal.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.zelty-authentication-modal {
  padding: 8px 24px;

  &__input {
    margin-top: 24px;
  }
}
</style>
