<template>
  <!-- eslint-disable  max-len -->
  <SkModal :on-close="toggleMoveModal">
    <template #header>
      <ModalHeader
        :title="modalTitle"
        svg-name="ClusterNodeIconSvg"
        svg-background-color="#0D2ED9"
        svg-fill="white"
      />
    </template>
    <template #body>
      <div class="main-container">
        <div class="breadcrumb-container">
          <div
            v-for="parentId in breadcrumbList"
            :key="'parent' + parentId"
            class="breadcrumb-item"
          >
            <div
              v-tooltip="parentClusterNodeStore[parentId]"
              :style="breadCrumbDimensions"
              class="breadcrumb-item-name"
              @click="() => fetchChildrenListAndComputeFooterClass(parentId)"
            >
              {{ parentClusterNodeStore[parentId] }}
            </div>
            <AngleRight
              fill="#8c9396"
              class="angle-right"
            />
          </div>
        </div>
        <div
          v-if="!parentWillBeEmpty"
          class="children-list-container"
        >
          <ClusterCardForModal
            v-for="child in parentChildren"
            :key="'child' + child.id"
            :item="child"
            :handle-click-on-card="() => fetchChildrenListAndComputeFooterClass(child.id)"
            :draggable="false"
            :current-moved-cluster="currentCluster"
            :only-move="onlyMove"
          />
        </div>
        <div
          v-else
          class="children-list-container children-list-container-empty"
        >
          {{ $t('organisation_settings.tabs.clusters.empty_node') }}
        </div>
      </div>
    </template>
    <template #footer>
      <div
        :class="footerClass"
        class="modal-btn_container"
      >
        <div
          :class="submitMoveButtonClass"
          class="modal-btn blue-btn"
          @click="handleClickMove"
        >
          {{ $t('actions.move') }}
        </div>
        <div
          class="modal-btn red-btn"
          @click="toggleMoveModal"
        >
          {{ $t('actions.cancel') }}
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import 'regenerator-runtime';
import smoothscroll from 'smoothscroll-polyfill';
import AngleRight from '../../assets/svg/AngleRight';
import SkModal from '../../ui/SkModal';
import ClusterCardForModal from '../../ui/ClusterCardForModal';
import ModalHeader from '../../ui/ModalHeader';

smoothscroll.polyfill();

export default {
  name: 'MoveModal',
  components: {
    SkModal, ClusterCardForModal, AngleRight, ModalHeader,
  },
  props: {
    toggleMoveModal: {
      type: Function,
      required: true,
    },
    currentCluster: {
      type: Object,
      default: null,
    },
    removeCurrentCluster: {
      type: Function,
      required: true,
    },
    moveChildrenToNewParentColumn: {
      type: Function,
      required: true,
    },
    fetchChildrenList: {
      type: Function,
      required: true,
    },
    moveAllChildrenAndDestroyParent: {
      type: Function,
      required: true,
    },
    moveShopOrAllChildrenWithParent: {
      type: Function,
      required: true,
    },
    breadcrumbList: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      default: null,
    },
    parentChildren: {
      type: Array,
      required: true,
    },
    parentClusterNodeStore: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    onlyMove: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      footerClass: '',
      currentParentId: this.currentCluster.parent_id,
    };
  },
  computed: {
    modalTitle() {
      if (this.loading) { return ''; }
      const clusterName = this.truncateName(this.currentCluster.name);
      const parentName = this.truncateName(
        this.parentClusterNodeStore[this.breadcrumbList[this.breadcrumbList.length - 1]],
      );

      if (this.onlyMove) {
        const type = this.currentCluster.type === 'shop' ?
          this.$t('organisation_settings.tabs.clusters.type.shop') :
          this.$t('organisation_settings.tabs.clusters.type.node');

        return this.$t('organisation_settings.tabs.clusters.move_modal.from_to', {
          type,
          clusterName,
          parentName,
        });
      }

      return this.$t('organisation_settings.tabs.clusters.move_modal.from_to', {
        clusterName,
        parentName,
      });
    },
    submitMoveButtonClass() {
      if (this.loading) { return { disabled: true }; }
      return {
        disabled: this.handleDisabled,
      };
    },
    breadCrumbDimensions() {
      const totalCrumbs = this.breadcrumbList.length;
      const totalCaretWidth = (totalCrumbs - 1) * 12;
      const crumbWidth = (520 - totalCaretWidth) / totalCrumbs;
      return {
        'max-width': `${crumbWidth}px`,
      };
    },
    handleDisabled() {
      if (this.onlyMove) {
        return !this.canMoveCurrentClusterToCurrentParent || this.isCurrentClusterCurrentLocation;
      }
      return !this.canMoveChildrenToCurrentParent;
    },
    isCurrentClusterCurrentLocation() {
      if (this.loading) { return false; }
      return this.parent.id === this.currentCluster.parent_id;
    },
    handleClickMove() {
      if (this.onlyMove) {
        return this.moveShopOrAllChildrenWithParent;
      }
      return this.moveAllChildrenAndDestroyParent;
    },
    canMoveChildrenToCurrentParent() {
      const currentClusterOrParentEmpty = this.parentIsEmpty || this.currentClusterIsEmpty;
      const currentClusterAndParentSameType =
        this.currentClusterAndParentHaveNoClusterChild ||
        this.currentClusterAndParentHaveClusterChildren ||
        this.parentWillBeEmpty;
      return (currentClusterOrParentEmpty || currentClusterAndParentSameType);
    },
    canMoveCurrentClusterToCurrentParent() {
      const currentClusterIsShop = this.currentCluster.type === 'shop';
      const parentHaveNodes = this.parentHaveNodes;

      if (currentClusterIsShop) {
        return !parentHaveNodes;
      }

      return parentHaveNodes || this.parent.nb_shop === 0;
    },
    parentHaveNodes() {
      if (this.loading) { return false; }
      return this.parent.nb_node !== 0;
    },
    currentClusterAndParentHaveNoClusterChild() {
      if (this.loading) { return false; }
      return (this.parent.nb_node === 0 && this.currentCluster.nb_node === 0);
    },
    currentClusterAndParentHaveClusterChildren() {
      if (this.loading) { return false; }
      return (this.parent.nb_node !== 0 && this.currentCluster.nb_node !== 0);
    },
    currentClusterIsEmpty() {
      if (this.loading) { return false; }
      return (this.currentCluster.nb_node === 0 && this.currentCluster.nb_shop === 0);
    },
    parentIsEmpty() {
      if (this.loading) { return false; }
      return (this.parent.nb_node === 0 && this.parent.nb_shop === 0);
    },
    parentWillBeEmpty() {
      if (this.loading) { return false; }
      return (this.parentChildren.length === 0);
    },
  },
  watch: {},
  mounted() {
    setTimeout(this.resetSubmitShadows, 0);
  },
  methods: {
    handleCrumbMouseOver(parentId) {
      this.$refs[`tooltip_${parentId}`][0].classList.remove('hidden');
    },
    handleCrumbMouseOut(parentId) {
      this.$refs[`tooltip_${parentId}`][0].classList.add('hidden');
    },
    scroll() {
      const breadcrumb = document.getElementsByClassName('breadcrumb-container')[0];
      setTimeout(() => {
        breadcrumb.scrollTo({
          top: 0,
          left: breadcrumb.scrollWidth - breadcrumb.clientWidth,
          behavior: 'smooth',
        });
      }, 100);
    },
    truncateName(name) {
      if (name.length < 15) return name;
      return `${name.substring(0, 15)}...`;
    },
    resetSubmitShadows() {
      const container = document.getElementsByClassName('children-list-container')[0];

      this.footerClass = {
        'shadow-top': this.divIsScrollable(container),
      };
    },
    divIsScrollable(div) {
      if (typeof div === 'undefined') return false;
      return div.scrollHeight > div.clientHeight;
    },
    fetchChildrenListAndComputeFooterClass(parentId) {
      this.scroll();
      this.fetchChildrenList(parentId);
      setTimeout(this.resetSubmitShadows, 150);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_delete_icon_container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba($sk-error, .15);
  display: flex;
  margin-right: 20px;
}

.modal_cluster_icon_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0d2ed9;
  display: flex;
  margin-right: 14px;
}

.delete_modal-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 16px;
}

.modal_delete-warning-text {
  margin-bottom: 25px;
  font-size: $fs-text-m;
  color: #2d2f30;
}

.modal_delete-question {
  font-size: $fs-text-m;
  font-weight: bold;
}

.modal-btn_container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
}

.modal-btn {
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  cursor: pointer;
  margin: 0 5px;
  background-color: lightgray;
  font-size: $fs-text-m;
  color: white;
  border-radius: 3px;
  line-height: 17px;

  &.blue-btn {
    padding: 12px 30px 10px;
    background-color: $sk-blue;
  }

  &.red-btn {
    padding: 12px 20px 10px;
    background-color: #ff5355;
  }
}

.breadcrumb-container {
  display: flex;
  background-color: white;
  width: 100%;
  overflow-x: auto;
}

.breadcrumb-item {
  display: flex;
  color: #8c9396;
  margin-right: 10px;
  align-items: center;

  &:last-child {
    text-decoration: underline;

    .angle-right {
      display: none;
    }
  }
}

.breadcrumb-name-tooltip {
  font-size: 11px;
  background: black;
  color: white;
  position: absolute;
  top: 77px;
  padding: 3px 5px;
  white-space: nowrap;

  .hidden {
    display: none;
  }
}

.breadcrumb-item-name {
  color: #8c9396;
  font-size: $fs-text-m;
  margin-right: 6px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    color: #2d2f30;
  }
}

.child-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
}

.children-list-container {
  overflow: auto;
  max-height: 50vh;
  border-top: 1px solid #e8eaed;
  margin-top: 20px;
}

.children-list-container-empty {
  min-height: 100px;
  text-align: center;
  width: 100%;
  font-size: $fs-text-m;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e8eaed;
  margin-top: 17px;
}

.shadow-top {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
  padding: 10px !important;
}

.disabled {
  pointer-events: none;
  opacity: .3;
}
</style>
