<template>
  <img
    width="50"
    src="./images/logo-lightspeed.png"
  >
</template>

<script>
export default {
  name: 'Lightspeed',
};
</script>
