<template>
  <div
    ref="tableWrapper"
    class="table-wrapper"
  >
    <!-- TABLE -->
    <table
      class="monthly-table"
      :class="hoveredWeek && `week-${hoveredWeek}`"
    >
      <!-- DAYS AND DATES -->
      <thead>
        <Header
          :header-component-store-props="headerComponentStoreProps"
          @weekly-counter-hovered="week => { hoveredWeek = week }"
        />
      </thead>
      <!-- /DAYS AND DATES -->

      <!-- BODY -->
      <tbody>
        <tr class="skeleton-days-row">
          <!-- USERS SIDEBAR -->
          <td class="sidebar-cell" />
          <!-- /USERS SIDEBAR -->

          <!-- Total Days -->
          <template v-for="(week, weekKey) of monthlyVisibleWeeks">
            <td
              v-for="day in week.days"
              :key="`${weekKey}-${day.date}`"
              class="skeleton-day-cell"
            />
            <td
              :key="`${weekKey}-counter`"
              class="weekly-counter"
            />
          </template>
          <!-- /Total Days -->

          <!-- HOUR TRACKERS -->
          <td class="empty-col" />
          <td class="counter-col" />
        <!-- /HOUR TRACKERS -->
        </tr>

        <tr
          ref="emptyRow"
          :style="{
            height: `max(${remainingHeight - 49}px, 49px)`,
          }"
        />
      </tbody>
      <!-- /BODY -->
    </table>
    <!-- /TABLE -->
  </div>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';
import SimpleBar from 'simplebar';
import Header from './Header/index.vue';

import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'MonthlySkeleton',
  components: {
    Header,
  },
  props: {
    currentScrollOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hoveredWeek: null,
      scrollElement: null,
      visibleWidth: 0,
      remainingHeight: 0,
      daysWorkedByWeek: {},
    };
  },
  computed: {
    ...mapState('planningsState', ['selectedWeeklyCountersInMonthlyView']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('monthlyPlanning', ['selectedCounter']),
    ...mapState('planningsUsers', ['daysWorked']),

    ...mapGetters('planningsState', ['monthlyVisibleWeeks']),
    ...mapGetters('planningsUsers', ['filteredUsers']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),
    headerComponentStoreProps() {
      const {
        selectedCounter,
        currentShop,
        checkFeatureFlag,
        filteredUsers,
        selectedWeeklyCountersInMonthlyView,
      } = this;

      return {
        selectedCounter,
        currentShop,
        checkFeatureFlag,
        filteredUsers,
        selectedWeeklyCountersInMonthlyView,
      };
    },
  },
  mounted() {
    this.setDaysWorkedByWeek();

    this.listenOnRoot('planning-horizontal-scroll', this.programmaticScroll);
    window.addEventListener('resize', this.updateVisibleWidth);

    this.initializeScrollBar();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateVisibleWidth);
    this.scrollElement.removeEventListener('scroll', this.updateVisibleWidth);
  },
  methods: {
    setDaysWorkedByWeek() {
      this.daysWorkedByWeek = Object.keys(this.monthlyVisibleWeeks).reduce((acc, weekFrom) => {
        acc[weekFrom] = {};
        return acc;
      }, {});
    },
    programmaticScroll(scrollOffset) {
      const { scrollWidth, clientWidth } = this.scrollElement;
      this.scrollElement.scrollLeft = scrollOffset * (scrollWidth - clientWidth);
    },
    updateVisibleWidth() {
      this.updateTableHeight();

      const { clientWidth, scrollWidth, scrollLeft } = this.scrollElement;
      const hiddenWidth = scrollWidth - clientWidth;
      const scrollOffset = hiddenWidth === 0 ? 0 : scrollLeft / hiddenWidth;
      this.$emit('update-visible-width', {
        visibleWidth: clientWidth / scrollWidth,
        scrollOffset,
      });
      this.emitOnRoot('planning-horizontal-scroll', scrollOffset);
    },
    updateTableHeight() {
      const rect = this.$refs.emptyRow.getBoundingClientRect();
      this.remainingHeight = window.innerHeight - rect.top;
    },
    initializeScrollBar() {
      const simpleBar = new SimpleBar(this.$refs.tableWrapper);
      this.scrollElement = simpleBar.getScrollElement();
      this.scrollElement.addEventListener('scroll', this.updateVisibleWidth);
      this.updateVisibleWidth();
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  scrollbar-width: none; // hide horizontal scrolling bar

  &::-webkit-scrollbar { // hide horizontal scrolling bar ( cross-browser )
    display: none;
  }

  animation: fadeIn 0.6s ease-out 0s,
    skeletonAnimation 1.2s ease-out 0.6s infinite;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes skeletonAnimation {
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  font-size: $fs-text-xs;
  width: 0;
  table-layout: fixed;

  th, td {
    border-right: 1px solid $sk-grey-10;
    border-bottom: 1px solid $sk-grey-10;

    &:last-child {
      border-right: none;
    }
  }

  .weekly-counter {
    width: 24px;
    border-right: 1px solid $sk-grey-10;
    border-top-color: transparent;
  }
}

::v-deep {
  // Hide SimpleBar's horizontal scrollbar
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
     visibility: hidden;
  }

  tbody {
    tr {
      td:nth-child(2) {
        border-left: none;
      }

      &:first-child {
        td {
          height: 48px;
          border-top: 1px;
        }
      }

      &:not(:last-child) {
        background-color: $sk-white;
      }
    }
  }
}

.skeleton-days-row {
  height: 70vh;
  td {
    height: 49px;
    border-right: 1px solid $sk-grey-10;
    border-bottom: none; // Changed from 1px solid to none
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #ddd 5%, white);

    &.sidebar-cell {
      width: 160px;
      border-right: 1px solid $sk-grey-10;
      position: sticky;
      left: 0;
      z-index: 5;
      background-color: $sk-white;
    }

    &.skeleton-day-cell {
      width: 45px;
      background-color: $sk-white;
      border-top: 1px solid $sk-grey-10;

      &:last-child {
        border-right: none;
        border: none;
      }
    }

    &.empty-col {
      position: sticky;
      right: 120px; // Width of the counter column
      z-index: 5;
      background-color: $sk-white;
      border-right: 1px solid $sk-grey-10;
      border-image: none; // Remove gradient for sticky column
      border-left: none;
    }

    &.counter-col {
      position: sticky;
      right: 0;
      z-index: 5;
      width: 120px;
      background-color: $sk-white;
      border-right: none;
      border-image: none; // Remove gradient for sticky column
      border-left: 1px solid $sk-grey-10;
      border-image-source: linear-gradient(to bottom, #ddd 5%, white);
      border-image-slice: 1;
    }
  }
}
</style>
