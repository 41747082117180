<template>
  <div class="total-employees__wrapper">
    <div class="total-employees__spacer-left" />
    <div class="total-employees__workforce__wrapper">
      <ScrollZone />
    </div>
    <div class="total-employees__spacer-right" />
  </div>
</template>

<script>
import ScrollZone from './ScrollZone';

export default {
  name: 'TotalEmployees',
  components: { ScrollZone },
};
</script>

<style lang="scss" scoped>
.total-employees__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  box-shadow: 0 -1px 10px -2px rgba(0, 0, 0, .16);
}

.total-employees__spacer-left {
  width: 161px;
  border-right: 1px solid $sk-grey-10;
}

.total-employees__spacer-right {
  width: 146px;
  border-left: 1px solid $sk-grey-10;
}

.total-employees__workforce__wrapper {
  flex-grow: 1;
  overflow: auto;
}
</style>
