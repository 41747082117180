<template>
  <div>
    <div class="activated-step__check__wrapper">
      <div class="activated-step__check">
        <Checkmark />
      </div>
    </div>
    <div>
      <h3 class="activated-step__title sk-header--3">
        {{ $t('plannings.toolbar.modal.esignature_upsell.activated_step.title') }}
      </h3>

      <i18n
        path="plannings.toolbar.modal.esignature_upsell.activated_step.subtitle"
        tag="h4"
        class="activated-step__subtitle"
      >
        <template #shopName>
          <b>{{ shopName }}</b>
        </template>
      </i18n>
    </div>

    <div class="activated-step__submit">
      <SkOroraButton @click="handleSubmit">
        {{ $t('plannings.toolbar.modal.esignature_upsell.activated_step.submit') }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Checkmark from './assets/Checkmark';

export default {
  name: 'ActivatedStep',
  components: { Checkmark },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    shopName() {
      return this.currentShop.attributes.name;
    },
  },
  methods: {
    handleSubmit() {
      this.$skAnalytics.track('esignature_upsell_finish');
      this.$emit('submit');
      setTimeout(() => this.emitOnRoot('open-esignature'), 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.activated-step__check__wrapper {
  margin-top: 57px;
  margin-bottom: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.activated-step__check {
  width: 157px;
  height: 95px;
}

.activated-step__title {
  font-size: $fs-heading-m;
  text-align: center;
}

.activated-step__subtitle {
  text-align: center;
  color: $sk-grey;
  font-weight: $fw-regular;
  margin-top: 12px;
  margin-bottom: 100px;
}

.activated-step__submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
