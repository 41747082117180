<template>
  <SkModalV2
    id="upsert-poste-modal"
    ref="upsertPosteModal"
    :title="handleModalTitle"
    :submit-button-label="handleSubmitButtonLabel"
    :submit-disabled="isSubmitDisabled"
    :testing-options="{ submit: isEditing ? 'validate_edit_post' : 'validate_add_post' }"
    @submit="handleLocalSubmit"
    @cancel="handleClose"
    @close="handleClose"
  >
    <template #body>
      <div class="upsert-poste-modal__input">
        <SkInput
          ref="posteNameInput"
          v-model="localShopPosteName"
          :label="$t('onboarding_modal.position.upsert_modal.input_label')"
          @focus="handleFocus"
          @blur="handleBlur"
          @keyup.enter.native="handleLocalSubmit"
        />
      </div>
    </template>
  </SkModalV2>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'UpsertPosteModal',
  props: {
    handleSubmit: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      isNameFocused: false,
      localShopPosteName: '',
      isEditing: false,
      originalShopPosteName: '',
      index: undefined,
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.localShopPosteName.trim().length === 0 ||
        (this.isEditing && this.localShopPosteName === this.originalShopPosteName);
    },
    handleModalTitle() {
      if (this.isEditing) {
        return this.$t('onboarding_modal.position.upsert_modal.update.title');
      }
      return this.$t('onboarding_modal.position.upsert_modal.create.title');
    },
    handleSubmitButtonLabel() {
      if (this.isEditing) {
        return this.$t('onboarding_modal.position.upsert_modal.update.submit_button');
      }
      return this.$t('onboarding_modal.position.upsert_modal.create.submit_button');
    },
  },
  created() {
    // here event is an object with label and index
    // when creating a new chip, index is undefined and label is empty
    // when updating a chip, index is set and label is the current label being edited
    this.listenOnRoot('init-upsert-chip-modal', event => {
      this.isEditing = event.index !== undefined;

      this.localShopPosteName = event.label;
      this.originalShopPosteName = event.label;
      this.index = event.index;
      this.showModal(event.event);
      this.$nextTick(() => {
        this.$refs.posteNameInput.focus();
      });
    });
  },
  methods: {
    handleFocus() {
      this.isNameFocused = true;
    },
    handleBlur() {
      this.isNameFocused = false;
    },
    handleClose() {
      this.$refs.upsertPosteModal.hide();
      this.localShopPosteName = '';
    },
    handleLocalSubmit() {
      if (this.isSubmitDisabled) return;

      if (this.isEditing) {
        this.$skAnalytics.track('onboarding_edit_poste');
        this.handleSubmit(this.localShopPosteName, this.index);
      } else {
        this.$skAnalytics.track('onboarding_add_poste');
        this.handleSubmit(this.localShopPosteName);
      }

      this.handleClose();
    },
    showModal(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'upsert-poste-modal');
    },
  },
};
</script>
  <style lang="scss" scoped>
  .upsert-poste-modal__input {
    padding: 24px 24px 10px;
    gap: 32px;
  }
  </style>
