<template>
  <tr>
    <td class="pos-card__shop-name-cell">
      {{ matchingShop.attributes.name }}
    </td>
    <td class="pos-card__select-cell">
      <SkSelectV2
        v-model="matchedShopId"
        :options="matchedShops"
        :label="label"
        allow-empty
      />
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ShopRow',
  props: {
    label: {
      type: String,
      required: true,
    },
    matchingShop: {
      type: Object,
      required: true,
    },
    matchedShops: {
      type: Array,
      required: true,
    },
    matchingShopsList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localMatchedShopId: this.matchingShopsList[this.matchingShop.id]?.id,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    matchedShopId: {
      get() {
        // If previously matched shop was deleted on partner's website => show empty input
        if (!this.matchedShops.find(shop => shop.id === this.localMatchedShopId?.toString())) {
          return null;
        }

        return this.localMatchedShopId;
      },

      set(newValue) {
        this.localMatchedShopId = newValue;

        // Can be not found if shop was deleted on partner website after matching
        const newShop = newValue &&
          this.matchedShops.find(matchedShop => matchedShop.id === newValue);

        this.$emit('set-matching-shop-list', {
          shopId: this.matchingShop.id,
          newValue: newShop || { id: null, text: '' },
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-table > tbody {
  td {
    border-bottom: none;
  }
}
</style>
