<template>
  <SkOroraDialog
    id="welcome-video-modal"
    ref="welcomeVideoModal"
    :title="modalTitle"
    :submit-button-label="submitButtonLabel"
    :cancel-button-label="cancelButtonLabel"
    @submit="handleSubmit"
    @show="$emit('show')"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <video
        ref="welcome-video"
        controls
        controlsList="nodownload"
        style="width: 100%; height: 100%;"
      >
        <source
          :src="videoUrl"
          type="video/mp4"
        >
      </video>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { awsBucketPublicAssets } from '@config/env';

export default {
  components: {},
  data() {
    return {
      videoUrl: this.getVideoUrl(),
    };
  },
  computed: {
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockDemoChecklist']),
    modalTitle() {
      return `${this.$t('badgings.timeclock_demo.welcome_video_modal.title')} ${this.modalName}`;
    },
    submitButtonLabel() {
      return this.$t('badgings.timeclock_demo.welcome_video_modal.configure');
    },
    cancelButtonLabel() {
      return this.$t('badgings.timeclock_demo.modals.labels.later');
    },
    modalName() {
      switch (this.$i18n.locale) {
        case 'fr':
          return 'Aurélie';
        default:
          return 'Sarah';
      }
    },
  },
  watch: {
    '$i18n.locale': 'updateLocale',
  },
  methods: {
    ...mapActions('timeclockOnboarding', ['updateStep']),
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    handleSubmit() {
      this.$skAnalytics.track('ss_checklist_timeclock_success_setup_now');
      this.updateStep({ key: 'videoTimeclockIntroduction', value: true });
      this.$emit('submit');
      this.stopVideo();
      if (this.shouldShowTimeclockDemoChecklist) this.toggleChecklistCollapsed();
    },
    stopVideo() {
      this.$refs['welcome-video'].pause();
    },
    handleCancel() {
      this.$skAnalytics.track('ss_checklist_timeclock_success_setup_later');
      this.stopVideo();
    },
    getVideoUrl(locale = this.$i18n.locale) {
      const languageCode = this.getVideoLanguage(locale);
      return `${awsBucketPublicAssets}/media/WelcomeTimeclockVideo_${languageCode}.mp4`;
    },
    getVideoLanguage(locale) {
      switch (locale) {
        case 'es':
          return 'ES';
        case 'fr':
          return 'FR';
        default:
          return 'EN';
      }
    },
    reloadVideo() {
      const video = this.$refs['welcome-video'];
      if (video) {
        video.load();
      }
    },
    updateLocale(newLocale) {
      this.videoUrl = this.getVideoUrl(newLocale);
      this.reloadVideo();
    },
  },
};
</script>
