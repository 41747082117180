<template>
  <div>
    <p>
      {{ $t('organisation_settings.tabs.pos_partners.link_shops', { name: integration.name }) }}
    </p>
    <div class="pos-card__table">
      <SkTable
        :columns="headers"
        :loading="shopsFetchLoading"
      >
        <GenericShopRow
          v-for="shop in organisationShops"
          :ref="getShopRowRefKey(shop.id)"
          :key="shop.id"
          :shop="shop"
          :integration="integration"
          @link="handleOpenLinkModal"
          @unlink="handleUnlinkModal"
        />
      </SkTable>
    </div>
    <div class="table__paginate__footer">
      <div v-if="shouldShowSubmitButton">
        <SkOroraButton
          :loading="isLinkingShops || shopsFetchLoading"
          @click="handleSubmitPartnerIntegrations"
        >
          {{ $t('organisation_settings.tabs.pos_partners.update') }}
        </SkOroraButton>
      </div>
      <div
        v-if="pagination.total_pages > 1"
        class="paginate__navigation__wrapper"
      >
        <SkPaginate
          v-model="pagination.current_page"
          :total-pages="pagination.total_pages"
          :disabled="shopsFetchLoading"
          @new-page="fetchShops"
        />
      </div>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <GenericAuthenticationModal
        ref="genericAuthenticationModal"
        :is-submit-loading="isGenericAuthenticationModalSubmitLoading"
        @continue="handleContinueToLink"
      />
    </MountingPortal>
  </div>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import {
  httpClient,
  svcPosClient,
} from '@skello-utils/clients';
import { sortCollectionBy } from '@skello-utils/collection';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import GenericShopRow from './GenericShopRow';
import GenericAuthenticationModal from './Modals/AuthenticationModal/GenericAuthenticationModal';

export default {
  name: 'GenericPartner',
  components: {
    GenericShopRow,
    GenericAuthenticationModal,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLinkingShops: false,
      isGenericAuthenticationModalSubmitLoading: false,
      organisationShops: [],
      shopsFetchLoading: false,
      pagination: {
        current_page: 1,
        per_page: 10,
        total_pages: 0,
      },
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('partnersTools', ['genericPartners']),

    isOrganisationLevelIntegration() {
      if (this.integration.chiftId) {
        return this.integration.isSupportingLocations;
      }

      return this.integration.requirements.shop.actions.length === 0;
    },
    headers() {
      const titleHeader = {
        title: this.$t('organisation_settings.tabs.pos_partners.shops_table.skello_title'),
        class: 'table-column__filled-section',
      };

      const shopHeader = {
        title: this.$t('organisation_settings.tabs.pos_partners.shops_table.shop_title', { name: this.integration.id }),
        class: 'table-column__filled-section',
      };

      const emptyHeader = {
        title: null,
        class: 'table-column__empty-section',
      };

      const integrationHeaders = [titleHeader];

      if (!this.isOrganisationLevelIntegration) {
        integrationHeaders.push(emptyHeader);
      } else {
        integrationHeaders.push(shopHeader);
      }

      return integrationHeaders;
    },
    shouldShowSubmitButton() {
      return this.isOrganisationLevelIntegration;
    },
  },
  async mounted() {
    this.shopsFetchLoading = true;
    const queries = [this.fetchShops()];

    if (this.isOrganisationLevelIntegration) {
      queries.push(
        this.fetchPosShops(
          {
            provider: this.integration,
            organisationConfigId: this.currentOrganisation.id,
          },
        ),
      );
    }

    Promise.all(queries)
      .finally(() => {
        this.shopsFetchLoading = false;
      });
  },
  methods: {
    ...mapActions('partnersTools', [
      'createShopPosIntegration',
      'updateShopPosIntegration',
      'deleteShopPosIntegration',
      'fetchPosShops',
    ]),
    ...mapMutations('partnersTools', [
      'cleanLinkedShopFromStore',
    ]),

    getShopRowRefKey(shopId) {
      return `shop${shopId}Row`;
    },
    fetchShops() {
      return httpClient
        .get(
          '/v3/api/shops', {
            params: {
              current_page: this.pagination.current_page,
              per_page: this.pagination.per_page,
            },
          },
        )
        .then(response => {
          this.pagination = response.data.meta.pagination;
          this.organisationShops = sortCollectionBy(response.data.data, 'name', 'toLowerCase');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    handleOutsideClick() {
      const shopIds = this.organisationShops.map(orgShop => orgShop.id);

      shopIds.forEach(shopId => {
        const refKey = this.getShopRowRefKey(shopId);
        this.$refs[refKey][0].handleOutsideClick();
      });
    },
    handleLinkErrorOnModal(error) {
      this.$refs.genericAuthenticationModal.handleInputError(error.response.data.message);
    },
    handleOpenLinkModal(shop) {
      this.$refs.genericAuthenticationModal.openModal({
        shopId: shop.id,
        authenticationType: 'shop',
        integration: this.integration,
      });
    },
    handleCloseLinkModal() {
      this.$refs.genericAuthenticationModal.closeModal();
    },
    async handleContinueToLink(params) {
      try {
        this.isGenericAuthenticationModalSubmitLoading = true;

        if (params.integration.chiftId) {
          await this.chiftShopActivation(params);
        } else {
          await this.createShopPosIntegration(params);
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.generic.success.link_shop'),
            variant: 'success',
          });
        }

        this.handleCloseLinkModal();
      } catch (error) {
        this.handleLinkErrorOnModal(error);
      } finally {
        this.isGenericAuthenticationModalSubmitLoading = false;
      }
    },
    handleUnlinkModal(shop) {
      const name = shop.attributes.name;
      const posName = this.integration.name;
      const description = this.$t('organisation_settings.tabs.pos_partners.generic.unlink_shop_modal.body', { name, posName });
      const submitLabel = this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.submit');
      const title = this.$t('organisation_settings.tabs.pos_partners.generic.unlink_shop_modal.title', { name, posName });

      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description,
        onConfirmAsync: () => this.unlink(shop),
        submitLabel,
        title,
      });
    },
    async chiftShopActivation(posIntegration) {
      try {
        const { id, attributes: { name } } = this.currentOrganisation;
        const activateProviderResponse = await svcPosClient.activateProvider({
          name: `${name} (${id}) - Shop ${posIntegration.shopId}`,
          organisationId: id,
          providerId: posIntegration.integration.chiftId,
          providerName: posIntegration.integration.name,
          shopId: posIntegration.shopId,
        });
        window.location = activateProviderResponse.url;
      } catch {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.chift.errors.activation'),
          variant: 'error',
        });
      }
    },
    async handleSubmitPartnerIntegrations() {
      this.isLinkingShops = true;

      let { didErrorOccur } = await this.createIntegrationForUnlinkedShops();
      didErrorOccur ||= (await this.updateIntegrationForLinkedShops()).didErrorOccur;

      this.isLinkingShops = false;

      if (!didErrorOccur) {
        this.cleanLinkedShopFromStore();
        this.$emit('submit-clicked');
      }

      this.showBulkShopsLinkToast(didErrorOccur);
    },
    async createIntegrationForUnlinkedShops() {
      let didErrorOccur = false;

      const promises = Object.values(this.genericPartners.shopsToLink)
        .map(integrationParams => this.createShopPosIntegration(integrationParams)
          .catch(() => { didErrorOccur = true; }));

      await Promise.all(promises);

      return { didErrorOccur };
    },
    async updateIntegrationForLinkedShops() {
      let didErrorOccur = false;

      const promises = Object.values(this.genericPartners.linkedShopsToUpdate)
        .map(integrationParams => this.updateShopPosIntegration(integrationParams)
          .catch(() => { didErrorOccur = true; }));

      await Promise.all(promises);

      return { didErrorOccur };
    },
    showBulkShopsLinkToast(isError) {
      if (isError) {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.errors.link_shops'),
          variant: 'error',
        });

        return;
      }

      this.$skToast({
        message: this.$t('organisation_settings.tabs.pos_partners.generic.success.link_shops'),
        variant: 'success',
      });
    },
    unlink(shop) {
      return this.deleteShopPosIntegration({
        providerId: this.integration.id,
        shopId: shop.id,
        organisationId: this.currentOrganisation.id,
      }).then(() => {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.success.unlink_shop'),
          variant: 'success',
        });
      }).catch(() => {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.generic.errors.unlink_shop'),
          variant: 'error',
        });
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
  .sk-table.pos-card__table > tbody td {
    border-bottom: none;
  }

  .pos-card__table {
    min-height: 320px;
  }

  .table__paginate__footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .paginate__navigation__wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-left: 138px;
    }
  }

  /* Larger space between header and first row than between each row */
  .sk-table.pos-card__table > tbody tr:first-child td {
    padding-top: 18px;
  }
  </style>
