import EmployeePlannings from '@app-js/employees/pages/plannings';
import AllShopsPlanning from '@app-js/employees/pages/plannings/AllShops';
import TeamSchedulePlanning from '@app-js/employees/pages/plannings/TeamSchedule';

const employeesRoutes = [
  {
    path: '/v3/employee/plannings',
    redirect: { name: 'plannings_employee_all' },
    component: EmployeePlannings,
    name: 'plannings_employee',
    children: [
      {
        path: 'all',
        name: 'plannings_employee_all',
        component: AllShopsPlanning,
      },
      {
        path: 'shop/:shop_id',
        name: 'plannings_employee_team_schedule',
        component: TeamSchedulePlanning,
      },
    ],
  },
];

export default employeesRoutes;
