<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'DisplayOptionsAction',
  extends: GenericDropdownItem,
  computed: {
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.display_options.label');
    },
    icon() {
      return 'OpenEyeV2Icon';
    },
    visibleIn() {
      return 'dropdown';
    },
    disabled() {
      return this.isDataLoading;
    },
    isVisible() {
      return true;
    },
    dataTest() {
      return 'planning_toolbar-display_options';
    },
  },
  methods: {
    handler(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'display-options-modal');
    },
  },
};
</script>
