<script>
import skDate from '@skello-utils/dates';

import { ABSENCE_TYPE_HALF_DAY } from '@app-js/shared/constants/shift';

import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';
import { getI18nAbsenceKey } from '@app-js/shared/utils/absences_helper';

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

export default {
  mixins: [RoutesMixins],
  props: {
    request: {
      type: Object,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEmployeeLabelCut: false,
      isDurationLabelCut: false,
      isDatesLabelCut: false,
      isTypeLabelCut: false,
      isManagerLabelCut: false,
      isAskedLabelCut: false,
      isShopNameLabelCut: false,
    };
  },
  computed: {
    ...mapGetters('currentLicense', ['isSystemAdmin', 'canManageEmployeeRequests']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentNodeShops']),
    actionButtonIcon() {
      if (this.isOwnRequestsView) {
        return 'TrashCanV2Icon';
      }

      return this.request.attributes.status !== 'pending' ? 'KebabV2Icon' : 'CalendarV2Icon';
    },
    actionButtonColor() {
      return this.isOwnRequestsView ? '#D03E50' : 'black';
    },
    actionButtonTooltip() {
      if (this.isOwnRequestsView) {
        return this.$t('requests.leave_requests.actions.delete');
      }

      if (this.actionItems.length === 1) {
        return this.$t('requests.leave_requests.actions.go_to_planning');
      }

      return null;
    },
    actionItems() {
      const successMessage = this.$t('requests.leave_requests.toast.success.delete');
      const errorMessage = this.$t('requests.toast.error');

      const confirmationModalParams = {
        title: this.$t('requests.leave_requests.confirm.delete.title'),
        submitLabel: this.$t('requests.leave_requests.confirm.delete.submit'),
        description: this.$t('requests.leave_requests.confirm.delete.description'),
        variant: 'danger',
        onConfirmSuccess: async () => {
          try {
            await this.deleteLeaveRequest(this.request);

            this.$skAnalytics.track('leave_request_deleted', { source: 'absence_space' });
            this.$skToast({ message: successMessage, variant: 'success' });
          } catch (error) {
            this.$skToast({ message: errorMessage, variant: 'error' });

            throw error;
          }
        },
      };

      const deleteRequestItem = {
        callback: () => this.$root.$emit('confirm', event, confirmationModalParams),
        color: '#D03E50',
        dataTest: 'requests__delete-request',
        icon: 'TrashCanV2Icon',
        iconColor: '#D03E50',
        label: this.$t('requests.leave_requests.actions.delete'),
      };

      if (this.isOwnRequestsView) {
        return [deleteRequestItem];
      }

      const redirectToPlanningItem = {
        callback: () => {
          const query = this.useSvcRequests ?
            {
              date: this.request.attributes.startsAt,
              target_start_date: this.request.attributes.startsAt,
              target_end_date: this.request.attributes.endsAt,
            } : {
              date: this.request.attributes.startDate,
              target_start_date: this.request.attributes.startDate,
              target_end_date: this.request.attributes.endDate,
            };
          const { href } = this.$router.resolve({
            name: 'plannings_months',
            params: { shop_id: this.request.attributes.shopId },
            query,
          });

          const source = this.request.attributes.status === 'pending' ?
            'pending_request' :
            'processed_request';

          this.$skAnalytics.track('leave_request_redirect_to_planning', { source });

          window.open(href, '_blank');
        },
        dataTest: 'requests__show-request-on-planning',
        icon: 'CalendarV2Icon',
        label: this.$t('requests.leave_requests.actions.go_to_planning'),
      };

      const items = [redirectToPlanningItem];

      if (this.request.attributes.status !== 'pending') {
        items.push(deleteRequestItem);
      }

      return items;
    },
    startDate() {
      return skDate.utc(
        this.useSvcRequests ? this.request.attributes.startsAt : this.request.attributes.startDate,
      );
    },
    endDate() {
      return skDate.utc(
        this.useSvcRequests ? this.request.attributes.endsAt : this.request.attributes.endDate,
      );
    },
    askedDate() {
      return skDate.utc(this.request.attributes.createdAt).format('DD/MM/YYYY');
    },
    statusVariant() {
      switch (this.request.attributes.status) {
        case 'accepted':
          return 'green';
        case 'refused':
          return 'danger';
        default:
          return 'orange';
      }
    },
    statusTooltip() {
      if (!this.isAllRequestsView) {
        return null;
      }
      const { status } = this.request.attributes;
      if (status === 'pending') {
        return `${this.$t('requests.leave_requests.own.table.tooltip.sent_to')} ${this.receiverName}`;
      }
      if (status === 'accepted') {
        return `${this.$t('requests.leave_requests.own.table.tooltip.accepted_by')} ${this.validatorName}`;
      }
      return `${this.$t('requests.leave_requests.own.table.tooltip.refused_by')} ${this.validatorName}`;
    },
    displayDate() {
      if (this.startDate.isSame(this.endDate, 'day')) {
        return `${this.startDate.format('DD MMM YYYY')}`;
      }

      if (this.startDate.isSame(this.endDate, 'month')) {
        return `${this.startDate.date()} - ${this.endDate.date()} ${this.endDate.format('MMM YYYY')}`;
      }

      return `${this.startDate.format('DD MMM')} - ${this.endDate.format('DD MMM YYYY')}`;
    },
    requestShopName() {
      return this.request.attributes.shop?.name;
    },
    requestDuration() {
      if (
        this.request.attributes.calculation === ABSENCE_TYPE_HALF_DAY ||
        // legacy (to remove with FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1 code clean)
        this.request.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY
      ) {
        return this.$t('requests.half_day');
      }

      const duration = this.useSvcRequests ?
        this.endDate.diff(this.startDate, 'days') + 1 :
        this.request.attributes.duration;

      return `${duration} ${this.$tc('requests.day', duration) }`;
    },
    requestUserName() {
      if (this.useSvcRequests) {
        return `${this.request.attributes.employee.firstName} ${this.request.attributes.employee.lastName}`;
      }
      return `${this.request.attributes.userFirstName} ${this.request.attributes.userLastName}`;
    },
    shopNameTooltip() {
      return this.isShopNameLabelCut ? this.requestShopName : null;
    },
    employeeTooltip() {
      return this.isEmployeeLabelCut ? this.requestUserName : null;
    },
    durationTooltip() {
      return this.isDurationLabelCut ? this.requestDuration : null;
    },
    datesTooltip() {
      return this.isDatesLabelCut ? this.displayDate : null;
    },
    typeTooltip() {
      return this.isTypeLabelCut ? this.absenceName : null;
    },
    absenceName() {
      if (this.useSvcRequests) {
        const country = this.isAllRequestsView ?
          this.currentNodeShops.find(
            shop => shop.id === this.request.attributes.shopId,
          )?.attributes.absencesCountry?.toLowerCase() :
          this.currentShop.attributes.absencesCountry.toLowerCase();

        const i18nKey = getI18nAbsenceKey(country, this.request.attributes.position?.absenceKey);
        return this.$t(`${i18nKey}.name`);
      }
      return this.request.attributes.absenceName;
    },
    receiverName() {
      if (!this.useSvcRequests || !this.request.attributes.receiver) return '-';

      return `${this.request.attributes.receiver.firstName } ${ this.request.attributes.receiver.lastName}`;
    },
    validatorName() {
      if (!this.useSvcRequests) return null;
      if (!this.request.attributes.validator) return '-';
      return `${this.request.attributes.validator?.firstName } ${ this.request.attributes.validator?.lastName}`;
    },
    managerName() {
      return `${this.request.attributes.managerFirstName } ${ this.request.attributes.managerLastName}`;
    },
    managerTooltip() {
      if (!this.isManagerLabelCut) {
        return null;
      }

      if (!this.useSvcRequests) {
        return this.managerName;
      }

      if (this.request.attributes.status === 'pending') {
        return this.receiverName;
      }

      return this.validatorName;
    },
    askedTooltip() {
      return this.isAskedLabelCut ? this.askedDate : null;
    },
    useSvcRequests() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1');
    },
    displayValidatorColumn() {
      if (this.isAllRequestsView) {
        return false;
      }
      return this.useSvcRequests ?
        this.columns.validator?.active :
        this.columns.validated_by?.active;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapActions('requests', ['deleteLeaveRequest']),
    openManageLeaveRequestModal(request) {
      if (this.isSystemAdmin || this.canManageEmployeeRequests) {
        this.emitOnRoot('open-manage-request-modal', request.attributes);
      }
    },
    onResize() {
      // employeeLabel => isEmployeeLabelCut
      const labelToBooleanDataName = label => `is${label[0].toUpperCase()}${label.substring(1)}Cut`;

      [
        'employeeLabel',
        'durationLabel',
        'datesLabel',
        'typeLabel',
        'managerLabel',
        'askedLabel',
        'shopNameLabel',
      ].forEach(label => {
        this[labelToBooleanDataName(label)] = !this.$refs[label] ||
          this.$refs[label].offsetWidth < this.$refs[label].scrollWidth;
      });
    },
  },
};
</script>
