import OrganisationSettings from './OrganisationSettings';
import OrganisationInfos from './pages/OrganisationInfos';
import OrganisationShops from './pages/OrganisationShops';
import ShopForm from './pages/OrganisationShops/ShopForm';
import ShopStep from './pages/OrganisationShops/ShopForm/ShopStep';
import BillingStep from './pages/OrganisationShops/ShopForm/BillingStep';
import OrganisationClusters from './pages/OrganisationClusters';
import LicensesAndActions from './pages/LicensesAndActions';
import PartnersTools from './pages/PartnersTools';
import PosPartners from './pages/PartnersTools/PosPartners';
import PayPartners from './pages/PartnersTools/PayPartners';
import ChurnRequest from './pages/ChurnRequest';
import BusinessIntelligencePartners from './pages/PartnersTools/BusinessIntelligencePartners';
import AdvancePaymentPartners from './pages/PartnersTools/AdvancePaymentPartners';

const organisationRoutes = [
  {
    path: '/v3/settings',
    component: OrganisationSettings,
    children: [
      {
        path: '',
        name: 'organisation_settings',
        component: OrganisationInfos,
      },
      {
        path: 'shops',
        name: 'organisation_settings_shops',
        component: OrganisationShops,
      },
      {
        path: 'shops/new',
        component: ShopForm,
        children: [
          {
            path: '',
            name: 'organisation_settings_shops_new_form',
            component: ShopStep,
            props: true,
          },
          {
            path: 'billing',
            name: 'organisation_settings_shops_new_billing_form',
            component: BillingStep,
            props: true,
          },
        ],
        props: route => ({ parentClusterNodeId: route.params.parentClusterNodeId }),
      },
      {
        path: 'clusters',
        name: 'organisation_settings_clusters',
        component: OrganisationClusters,
      },
      {
        path: 'licenses',
        name: 'organisation_settings_licenses',
        component: LicensesAndActions,
      },
      {
        path: 'partners_tools',
        component: PartnersTools,
        children: [
          {
            path: '',
            name: 'organisation_settings_partners_tools',
            component: PosPartners,
          },
          {
            path: 'pay_partners',
            name: 'organisation_settings_pay_partners',
            component: PayPartners,
          },
          {
            path: 'bi_partners',
            name: 'organisation_settings_bi_partners',
            component: BusinessIntelligencePartners,
          },
          {
            path: 'advance_payment_partners',
            name: 'organisation_settings_advance_payment_partners',
            component: AdvancePaymentPartners,
          },
        ],
      },
    ],
  },
  {
    path: '/v3/churn-request',
    component: ChurnRequest,
    name: 'churn_request',
  },
];

export default organisationRoutes;
