<template>
  <div class="results__wrapper">
    <div>
      <SkCard class="overview__wrapper">
        <div class="overview__title">
          {{ $t('automatic_planning.results.overview_title') }}
        </div>
        <hr class="overview__divider">
        <div class="overview__details">
          <div class="overview__date">
            <div class="overview__date__text">
              {{ weekDate }}
            </div>
            <div class="overview__date__tag">
              {{ $t('automatic_planning.results.date_tag', { weekNum }) }}
            </div>
          </div>

          <div class="overview__tags">
            <div class="overview__section">
              <div class="overview__tag__title overview__tag__title--green">
                {{ $t('automatic_planning.results.percent', { successRate }) }}
              </div>
              <div class="overview__tag__subtitle">
                {{ $t('automatic_planning.results.percent_subtitle') }}
              </div>
            </div>

            <div class="overview__section">
              <div class="overview__tag__title overview__tag__title--blue">
                {{ unassignedShiftsCount }}
              </div>
              <div class="overview__tag__subtitle">
                {{ $tc('automatic_planning.results.unassigned', unassignedShiftsCount) }}
              </div>
            </div>
          </div>
        </div>
      </SkCard>

      <div
        v-if="hasUnassignedShifts"
        class="details__wrapper"
      >
        <SkCollapse>
          <template #labelLeft>
            <div class="details__title">
              {{ $t('automatic_planning.results.details_title') }}
            </div>
          </template>

          <template #content>
            <div class="details__block">
              <div class="details__intro">
                <div class="details__intro__blockquote" />
                <div class="details__intro__text">
                  {{ $t('automatic_planning.results.details_intro_1') }}
                  <strong>
                    {{ $t('automatic_planning.results.details_intro_2') }}
                  </strong>
                  {{ $t('automatic_planning.results.details_intro_3') }}
                  <a
                    class="sk-link--medium"
                    :href="automaticPlanningRulesUrl"
                  >
                    {{ $t('automatic_planning.results.details_intro_4') }}
                  </a>
                  {{ $t('automatic_planning.results.details_intro_5') }}
                </div>
              </div>
              <ul class="details__list">
                <!-- eslint-disable vue/no-v-html-->
                <li
                  v-for="rule in rules"
                  :key="rule"
                  class="details__rule"
                  v-html="ruleDesc(rule)"
                />
                <!-- eslint-enable vue/no-v-html-->
              </ul>
            </div>
          </template>
        </SkCollapse>
      </div>

      <div
        v-if="hasUnassignedShifts && canEditAutomaticPlanningRules"
        class="automatic-planing__quota-banner_rules-link"
      >
        <a
          class="sk-link--medium"
          :href="automaticPlanningRulesUrl"
          @click="trackLinkClick"
        >
          {{ $t('automatic_planning.results.rules_url') }}
        </a>
      </div>
    </div>

    <div class="automatic-planning__quota-banner__submit">
      <SkOroraButton @click="submit">
        {{ $t('automatic_planning.sidebar.finish') }}
      </SkOroraButton>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <AutomaticPlanningResultConfirmModal @handle-close-confirm-modal="handleCloseConfirmModal" />
    </MountingPortal>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import {
  SkCard,
  SkCollapse,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';
import {
  mapState,
  mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';
import AutomaticPlanningResultConfirmModal from '../../modal/AutomaticPlanningResultConfirmModal';

export default {
  name: 'ResultInfos',
  components: {
    SkCard,
    SkCollapse,
    AutomaticPlanningResultConfirmModal,
  },
  props: {
    resultInfos: {
      type: Object,
      required: true,
    },
    automaticPlanningRulesUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      automaticPlanningRules: [],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('planningsState', ['monday']),
    ...mapGetters('currentLicense', ['canAccessShopSettingsTab']),
    successRate() {
      return this.resultInfos.success_rate;
    },
    unassignedShiftsCount() {
      return this.resultInfos.unassigned_shifts.length;
    },
    hasUnassignedShifts() {
      return this.unassignedShiftsCount > 0;
    },
    rules() {
      return this.resultInfos.rules;
    },
    currentDate() {
      return skDate(this.monday);
    },
    weekDate() {
      const startWeek = this.currentDate.startOf('week').format('DD MMM');
      const endWeek = this.currentDate.endOf('week').format('DD MMM YYYY');
      return this.$t('automatic_planning.results.date_text', { startWeek, endWeek });
    },
    weekNum() {
      return this.currentDate.isoWeek();
    },
    canEditAutomaticPlanningRules() {
      return this.canAccessShopSettingsTab &&
        this.currentShop.attributes.canAccessAutomaticPlanning;
    },
  },
  mounted() {
    if (this.canEditAutomaticPlanningRules) {
      this.fetchRules();
    }
  },
  methods: {
    fetchRules() {
      httpClient
        .get(`/v3/api/shops/${this.currentShop.id}/automatic_planning/rules`)
        .then(response => {
          this.automaticPlanningRules = response.data.data;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        });
    },
    ruleDesc(ruleKind) {
      const rule =
        this.automaticPlanningRules.find(dbRule => dbRule.attributes.kind === ruleKind);
      const value = rule ? rule.attributes.value : 0;
      return this.$t(`automatic_planning.results.rules.${ruleKind}.description`, { value });
    },
    submit() {
      const hideResultConfirmModal = JSON.parse(localStorage.getItem(`hide-result-confirm-modal_${this.currentUser.id}`));

      if (hideResultConfirmModal) {
        this.closeResults();
      } else {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'automatic-planning-result-confirm-modal');
      }
    },
    handleCloseConfirmModal(event) {
      if (event.doNotShow) {
        localStorage.setItem(`hide-result-confirm-modal_${this.currentUser.id}`, true);
      }
      this.$skAnalytics.track('automatic_planning_close_results_modal');
      this.closeResults();
    },
    trackLinkClick() {
      this.$skAnalytics.track('automatic_planning_settings', { source: 'Results SP' });
    },
    closeResults() {
      // We get the automatic planning results from the local storage
      const automaticPlanningResults =
        localStorage.getItem(`automatic_planning_last_results_${this.currentShop.id}`);

      if (automaticPlanningResults) {
        const parsedResults = JSON.parse(automaticPlanningResults);

        // Since a User can have only one result by shop
        // When the User closes the sidebar results
        // We delete the result from the localstorage
        delete parsedResults[this.currentUser.id];

        // If the localstorage is empty after that,
        // then we remove the key from the local storage
        if (!Object.keys(parsedResults).length) {
          localStorage.removeItem(`automatic_planning_last_results_${this.currentShop.id}`);
        } else {
          // If it's not empty we update the localstorage with the datas without the user's results
          localStorage.setItem(
            `automatic_planning_last_results_${this.currentShop.id}`,
            JSON.stringify(parsedResults),
          );
        }
      }
      this.$emit('close-side-panel');
    },
  },
};
</script>

<style lang="scss" scoped>
.results__wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 360px;
}

.overview__wrapper {
  margin-bottom: 20px;
}

.overview__title {
  font-size: $fs-text-m;
  line-height: 16px 0 17px;
  color: $sk-black;
  margin: 17px 0 17px -20px;
  font-weight: bold;
}

.overview__divider {
  margin: 0 -36px 13px -33px;
  border: 0;
  border-top: 1px solid #eee;
}

.overview__date {
  display: flex;
  font-size: $fs-text-s;
  line-height: 15px;
  color: $sk-grey;
  margin-bottom: 18px;
  align-items: center;
}

.overview__date__tag {
  margin-left: 10px;
  background: $sk-grey-10;
  border-radius: 3px;
  padding: 4px 8px;
}

.overview__tags {
  display: flex;
  justify-content: center;
}

.overview__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 11px;
}

.overview__details {
  padding-bottom: 18px;
}

.overview__tag__title {
  font-size: 19px;
  line-height: 22px;
  border-radius: 126px;
  padding: 9px 12px;
  margin-bottom: 11px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview__tag__title--green {
  color: $sk-success;
  background: $sk-success-10;
}

.overview__tag__title--blue {
  color: $sk-blue;
  background: $sk-blue-5;
}

.overview__tag__subtitle {
  font-size: $fs-text-m;
  line-height: 17px;
  color: $sk-black;
}

.details__wrapper {
  box-shadow: 4px 0 20px rgba(0, 0, 0, .08);
  border-radius: 4px;
  padding: 0;
  background: white;
}

.details__title {
  font-weight: bold;
  font-size: $fs-text-m;
  line-height: 17px;
  color: $sk-black;
  margin: 4px 0 3px;
}

.details__block {
  padding-right: 15px;
  max-height: calc(100vh - 490px);
  overflow-y: auto;
}

.details__intro {
  display: flex;
}

.details__intro__blockquote {
  margin: 3px 0;
  border-left: 2px solid $sk-grey;
  margin-left: 15px;
}

.details__intro__text {
  margin-left: 15px;
  font-size: $fs-text-m;
  line-height: 20px;
  letter-spacing: .1px;
  color: $sk-grey;
}

.details__list {
  margin-top: 32px;
  padding-left: 40px;
}

.details__rule {
  padding-left: 8px;
  padding-bottom: 14px;

  &::marker {
    font-size: $fs-text-xxs;
  }
}

.automatic-planning-quota-banner__text {
  &--colored {
    color: #d85e5a;
  }
}

.automatic-planning__quota-banner__submit {
  display: flex;
  position: absolute;
  width: 412px;
  padding: 30px 24px 24px;
  bottom: 0;
  right: 0;
  margin-top: auto;
  justify-content: flex-end;
}

.automatic-planing__quota-banner_rules-link {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .sk-link--medium {
    padding: 10px 15px;

    &:hover {
      background: #eef4ff;
      border-radius: 3px;
    }
  }
}
</style>
