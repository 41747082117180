<template>
  <div
    class="header__wrapper"
    :style="zoomRange.headerWrapper"
  >
    <div class="header__sidebar__wrapper cell--shadow cell--shadow-right">
      <SkNav
        class="header__sidebar"
        :style="zoomRange.headerSidebar"
      >
        <SkNavItem
          :active="isEmployeesView"
          data-test="sidebar__employees"
          disabled
        >
          {{ $t('plannings.table.header.navigation.employees') }}
        </SkNavItem>
        <SkNavItem
          :active="isPostesView"
          data-test="sidebar__postes"
          disabled
        >
          {{ $t('plannings.table.header.navigation.postes') }}
        </SkNavItem>
      </SkNav>
    </div>

    <div class="header__days">
      <SkeletonDay
        v-for="day in visibleDays"
        :key="day.date"
        :day="day"
      />
    </div>
    <div class="header__sidebar__wrapper cell--shadow cell--shadow-left">
      <SkNav
        class="header__sidebar_counter"
        :style="{ fontSize: zoomRange.headerSidebar.fontSize }"
      >
        <SkNavItem
          :class="classForTotalColumn"
          :active="isActiveItem('total')"
          :style="zoomRange.headerCounterLinkLeft"
          disabled
          data-test="counters__total"
        >
          {{ $t('plannings.table.header.counters.total') }}
        </SkNavItem>
        <SkNavItem
          v-if="isCountersTabDisplayed"
          :class="secondTabClass"
          :active="isActiveItem('counters')"
          :style="zoomRange.headerCounterLinkRight"
          disabled
          data-test="counters__counters"
        >
          {{ secondTabTitle }}
        </SkNavItem>
      </SkNav>
    </div>
  </div>
</template>

<script>
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import {
  mapState,
  mapGetters,
} from 'vuex';
import SkeletonDay from './SkeletonDay.vue';

export default {
  name: 'SkeletonHeader',
  components: { SkeletonDay },
  props: {
    visibleDays: {
      type: Array,
      default: () => [],
    },
    planningZoom: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('planningsLoading', ['isFirstBatchLoaded', 'isLoadingCompleted']),
    ...mapGetters('annualization', ['isAnnualizationCurrentlyActive']),
    ...mapGetters('planningsState', [
      'isActiveItem',
      'isEmployeesView',
      'isPostesView',
    ]),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable']),
    ...mapState('currentShop', ['currentShop']),
    isAnyPlanningInformationLoaded() {
      return this.isFirstBatchLoaded || this.isLoadingCompleted;
    },
    zoomRange() {
      const {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      } = zoomPlanningWeek(this.planningZoom);
      return {
        headerWrapper,
        headerSidebar,
        headerCounterLinkLeft,
        headerCounterLinkRight,
      };
    },
    isAnnualizationActive() {
      return this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.isAnnualizationCurrentlyActive;
    },
    isCountersTabDisplayed() {
      return this.isEmployeesView &&
      (this.currentShop.attributes.modulation ||
        this.isAnnualizationActive);
    },
    classForTotalColumn() {
      return { 'counters-cell__nav__single-column': !this.isCountersTabDisplayed };
    },
    secondTabClass() {
      return {
        header__counters__counters_annualization:
          this.currentShop.attributes.modulation &&
          this.isAnnualizationActive,
      };
    },
    secondTabTitle() {
      if (this.currentShop.attributes.modulation) {
        if (this.isAnnualizationActive) {
          return this.$t('plannings.table.header.counters.counters_annualization');
        }
        return this.$t('plannings.table.header.counters.counters');
      }

      return this.$t('plannings.table.header.counters.annualization');
    },
  },
};
</script>

  <style lang="scss" scoped>
  .header__wrapper {
    width: 100%;
    display: flex;
    z-index: 7;
  }

  .header__sidebar {
    width: 160px;
    height: 100%;
    background-color: white;
    align-items: flex-end;
    justify-content: space-around;
    overflow: hidden;
  }

  ::v-deep .header__sidebar, .header__sidebar_counter {
    align-items: flex-end;

    .sk-nav__item {
      font-size: 12.6px;
    }
  }

  ::v-deep .sk-nav {
    border: none;
  }

  // This and the following rules are some product specs
  // in order to center skNav blue bar

  ::v-deep .sk-nav__item .sk-nav__item__link {
    margin: 0;
    padding: 0px 4px 16px;
  }

  ::v-deep .sk-nav__item__link--active {
    color: $sk-black;

    &:hover {
      color: $sk-black;
    }
  }

  .header__days {
    width: 100%;
    display: flex;
    background-color: white;
  }

  .header__sidebar__wrapper {
    position: relative;
    background-color: white;
    border-bottom: solid 1px $sk-grey-10;
    z-index: 1;
  }

  .header__sidebar_counter {
    width: 146px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 16px;
  }
</style>
