<template>
  <div class="folders-management-wrapper">
    <div
      v-if="loadingFetchFolders || loadingCreateFolder || loadingUpdateFolder
        || loadingDeleteFolder"
      class="folders-management-spinner-container"
    >
      <SkLoader
        size="large"
        class="folders-management-spinner"
      />
    </div>
    <div v-else>
      <p class="folders-management-wrapper-description">
        {{ $t('shop_settings.documents_management.folders_management_description') }}
      </p>
      <div class="folders-management-wrapper-container">
        <FolderCard
          v-for="folder in permanentFolders"
          :key="folder.key"
          :title="$t(folder.title)"
          :is-disabled="true"
        />
        <FolderCard
          v-for="folder in sortedCustomFolders"
          :key="folder.id"
          :title="folder.name"
          :is-disabled="false"
          @rename-folder="openRenameFolderModal(folder)"
          @delete-folder="openDeleteFolderModal(folder)"
        />
        <FolderCard
          is-add-folder-card
          :is-disabled="false"
          @create-folder="openCreateFolderModal"
        />
      </div>
    </div>
    <CreateFolderModal
      ref="createFolderModal"
      :existing-folder-names="allFolderNames"
      @create-folder="handleCreateFolder"
    />
    <RenameFolderModal
      ref="renameFolderModal"
      :existing-folder-names="allFolderNames"
      :current-folder-name="currentSelectedFolder.name"
      :folder-id="currentSelectedFolder.id"
      @rename-folder="handleRenameFolder"
    />
    <DeleteFolderModal
      ref="deleteFolderModal"
      @delete-folder="handleDeleteFolder"
    />
  </div>
</template>

<script>
import FolderCard from '@app-js/shared/components/FolderCard/index.vue';
import { SkelloFolders } from '@skelloapp/svc-documents-v2-client';
import {
  mapState, mapActions, mapGetters,
} from 'vuex';
import CreateFolderModal from './CreateFolderModal.vue';
import RenameFolderModal from './RenameFolderModal.vue';
import DeleteFolderModal from './DeleteFolderModal.vue';

export default {
  name: 'FoldersManagement',
  components: { FolderCard, CreateFolderModal, RenameFolderModal, DeleteFolderModal },
  data() {
    return {
      currentSelectedFolder: { id: '', name: '' },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('foldersManagement', ['loadingFetchFolders', 'loadingCreateFolder', 'loadingUpdateFolder', 'loadingDeleteFolder', 'customFolders']),
    ...mapGetters('foldersManagement', ['folderName']),
    sortedCustomFolders() {
      return [...this.customFolders].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    },
    permanentFolders() {
      return SkelloFolders.map(folder => ({
        key: folder,
        title: this.folderName(folder),
      }));
    },
    allFolderNames() {
      const permanentNames = this.permanentFolders.map(folder => this.$t(folder.title));
      const customNames = this.customFolders.map(folder => folder.name);
      return [...permanentNames, ...customNames];
    },
  },
  created() {
    this.fetchFolders({ shopId: this.currentShop.id });
  },
  methods: {
    ...mapActions('foldersManagement', ['fetchFolders', 'createFolder',
      'updateFolder', 'deleteFolder']),
    openCreateFolderModal() {
      this.$refs.createFolderModal.show();
    },
    handleCreateFolder(folderName) {
      this.createFolder({ shopId: this.currentShop.id, name: folderName })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.documents_management.create_folder.success_message'),
            variant: 'success',
          });
        });
    },
    openRenameFolderModal(folder) {
      this.currentSelectedFolder = folder;
      this.$refs.renameFolderModal.show();
    },
    handleRenameFolder(newName) {
      this.updateFolder({ folderId: this.currentSelectedFolder.id, name: newName })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.documents_management.rename_folder.success_message'),
            variant: 'success',
          });
        });
    },
    openDeleteFolderModal(folder) {
      this.currentSelectedFolder = folder;
      this.$refs.deleteFolderModal.show();
    },
    handleDeleteFolder() {
      this.deleteFolder({ folderId: this.currentSelectedFolder.id })
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.documents_management.delete_folder.success_message'),
            variant: 'success',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.folders-management-wrapper {
  padding-top: 48px;
  padding-left: 40px;
}

.folders-management-wrapper-description {
  font-size: 14px;
  color: $sk-grey-50;
}

.folders-management-wrapper-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.folders-management-spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}
</style>
