<template>
  <div class="row__container">
    <div class="opti-row__cell opti-row__sticky-label">
      {{ label }}
    </div>
    <template v-if="isVerificationPanel">
      <VerificationCell
        v-for="(hourValue, index) in dayStructure"
        :key="`opti-cell-${index}`"
        :hour-value="hourValue"
        class="opti-row__cell opti-row__data-cell"
      />
    </template>

    <template v-else>
      <Cell
        v-for="(hourValue, index) in dayStructure"
        :id="`opti_cell_${index}`"
        :key="`opti-cell-${index}`"
        :cell-index="index"
        :hour-value="hourValue"
        :poste-id="posteId"
        :row-index="rowIndex"
        class="opti-row__cell opti-row__data-cell"
      />
    </template>
  </div>
</template>

<script>
import Cell from './Cell';
import VerificationCell from './VerificationCell';

export default {
  name: 'Row',
  components: {
    Cell,
    VerificationCell,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    dayStructure: {
      type: Array,
      required: true,
    },
    posteId: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    isVerificationPanel: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.row__container {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
  font-style: normal;
  font-weight: normal;
}

.opti-row__sticky-label.opti-row__cell:first-child {
  background: $sk-grey-5;
  position: sticky;
  left: 0;
  z-index: 2;
  border: 1px solid $sk-grey-10;
  border-left: 0;
  border-right: 0;
  height: 100%;
  width: 61px;
  font-size: $fs-text-m;
  padding: 5px;

  &:first-child {
    border-top: 0 !important;
  }
}

.opti-row__cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $sk-grey-10;
  border-top: 0;
  border-right: 0;

  &:first-child {
    border-left: 0;
    border-bottom: 0;
    padding-left: 15px;
  }
}

.copyselected {
  border-top: 1px solid $sk-blue;
  border-bottom: 1px solid $sk-blue;
}

.copyselected__last {
  border-top: 1px solid $sk-blue;
  border-bottom: 1px solid $sk-blue;
  border-right: 1px solid $sk-blue;
}

.opti-row__data-cell {
  flex-grow: 1;
  flex-basis: 0;
  width: 30px;
}
</style>
