<template>
  <SkModalV2
    id="comment-modal"
    ref="modal"
    :title="modalTitle"
    :submit-disabled="disableSubmit"
    :submit-button-label="$t('reports.modals.comment.buttons.submit')"
    @show="initComment"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="comment-modal__form-line">
          <span class="comment-modal__label">
            {{ $t('reports.modals.comment.labels.comment') }}
          </span>

          <SkTextarea
            v-model="newComment"
            class="comment-modal__textarea"
            :label="$t('reports.modals.comment.placeholders.add_comment')"
            data-test="comment-modal__comment-field"
            auto-grow
            :min-height="54"
            :max-height="99"
            rows="3"
          />
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';
import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'CommentModal',
  props: {
    employeeId: {
      type: Number,
      default: null,
    },
    contractId: {
      type: Number,
      default: null,
    },
    shopId: {
      type: Number,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newComment: '',
    };
  },
  computed: {
    ...mapState('report', ['saasReportData']),
    disableSubmit() {
      return this.existingComment === this.newComment;
    },
    contractInfos() {
      return this.saasReportData[this.employeeId].contracts_infos[this.contractId];
    },
    employeeInfos() {
      return this.saasReportData[this.employeeId].user_infos;
    },
    existingComment() {
      if (!this.employeeId || !this.contractId || !this.contractInfos.report_comment) {
        return '';
      }

      return this.contractInfos.report_comment.comment;
    },
    modalTitle() {
      const employeeName = (this.employeeId && this.contractId) ?
        capitalize(`${this.employeeInfos.first_name} ${this.employeeInfos.last_name}`) : '';
      return this.$t('reports.modals.comment.modal_title', { employeeName });
    },
    isCreateAction() {
      return !this.contractInfos.report_comment;
    },
    submitParams() {
      return {
        shop_id: this.shopId,
        contract_id: this.contractId,
        report_comment: {
          start_date: this.startDate,
          end_date: this.endDate,
          comment: this.newComment,
        },
      };
    },
  },
  methods: {
    ...mapActions('report', ['createComment', 'updateComment']),
    ...mapMutations('report', ['editComment']),
    initComment() {
      this.$nextTick(() => {
        this.newComment = this.existingComment;
      });
    },
    handleSubmit() {
      if (this.isCreateAction) {
        this.handleCreate();
      } else {
        this.handleUpdate();
      }
    },
    handleCreate() {
      this.createComment(this.submitParams)
        .then(result => {
          this.handleSuccess(result.data.report_comment_id);
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    handleUpdate() {
      const params = { ...this.submitParams, commentId: this.contractInfos.report_comment.id };
      this.updateComment(params)
        .then(() => {
          this.handleSuccess(this.contractInfos.report_comment.id);
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    handleSuccess(commentId) {
      this.editComment({
        employeeId: this.employeeId,
        contractId: this.contractId,
        comment: this.newComment,
        commentId,
      });

      this.$skToast({
        message: this.$t('reports.modals.comment.success'),
        variant: 'success',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-modal__form-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--margin-bottom {
    margin-bottom: 8px;
  }
}

.comment-modal__label {
  font-weight: bold;
}

.comment-modal__textarea {
  width: 320px !important;
}
</style>
