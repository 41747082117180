<template>
  <div
    :class="wrapperClasses"
    @mouseenter="selectCellDate"
    @mouseleave="clearCellDate"
  >
    <div
      class="wrapper__day-info"
      @click="handleHeaderDayClick"
    >
      <div class="day-name">
        {{ dayName }}
      </div>
      <div class="day-number">
        {{ dayNumber }}
      </div>
    </div>
    <div class="icons-section">
      <div class="icons-section--left" />

      <div class="icons-section--right">
        <CloseLockIcon
          v-if="dayInfo.isLocked"
          v-tooltip.bottom.offset="closedLockIconTooltip"
          v-track="'month_view_V3_unlock_day'"
          :class="lockIconClass"
          fill=""
          height="12px"
          width="12px"
        />

        <OpenLockIcon
          v-else-if="isSelected"
          v-track="'month_view_V3_lock_day'"
          v-tooltip.bottom.offset="openLockIconTooltip"
          height="12px"
          width="12px"
          :class="lockIconClass"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState, mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'HeaderDay',
  props: {
    dayInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsState', ['selectedDate']),
    ...mapState('currentShop', ['currentShop']),

    isDayPlanningEnabled() {
      return this.checkPackOfferFlag('day_planning_enabled');
    },
    canTmpLockPlanning() {
      return this.currentLicense.attributes.canTmplockPlanning;
    },
    canLockPlanning() {
      return this.currentLicense.attributes.canLockPlanning;
    },
    closedLockIconTooltip() {
      if (this.dayInfo.permanentLocked) {
        if (this.canLockPlanning) {
          return this.$t('plannings.table.header.actions.day_validation.permanent_unlock.label');
        }
        return this.$t('plannings.table.header.actions.day_validation.permanent_unlock.unauthorized');
      }

      if (this.canTmpLockPlanning) {
        return this.$t('plannings.table.header.actions.day_validation.unvalidate.label');
      }
      return this.$t('plannings.table.header.actions.day_validation.unvalidate.unauthorized');
    },
    openLockIconTooltip() {
      if (this.canTmpLockPlanning) {
        return this.$t('plannings.table.header.actions.day_validation.validate.label');
      }
      return this.$t('plannings.table.header.actions.day_validation.validate.unauthorized');
    },
    lockIconClass() {
      return {
        'header-day__footer-lock': true,
        'header-day__footer-lock--validated': this.dayInfo.validated,
        'header-day__footer-lock--intermediate': this.dayInfo.intermediateLocked,
        'header-day__footer-lock--permanent': this.dayInfo.permanentLocked,
        'header-day__footer-lock--disabled': true,
      };
    },
    isSelected() {
      return this.dayInfo.date === this.selectedDate;
    },
    day() {
      return skDate(new Date(this.dayInfo.date));
    },
    dayName() {
      const day = this.day.utc().locale('en').format('dddd')
        .toLowerCase();
      return this.$t(`plannings.table.header.short_day.${day}`);
    },
    dayNumber() {
      return this.day.utc().locale('en').format('DD');
    },
    isInMonth() {
      return skDate(this.currentDate).utcOffset(this.day.utcOffset()).format('MM') === this.day.utc().locale('en').format('MM');
    },
    wrapperClasses() {
      const { isLocked: isDayLocked } = this.dayInfo;
      return {
        wrapper: true,
        'wrapper--locked': isDayLocked,
        'wrapper--clickable': this.isDayPlanningEnabled,
        'not-in-month': !this.isInMonth,
      };
    },
  },
  methods: {
    ...mapMutations('planningsState', ['setSelectedDate']),
    handleHeaderDayClick() {
      if (this.isDayPlanningEnabled) {
        this.$router.push({ name: 'plannings_days', query: { date: this.dayInfo.date } });
      }
    },
    selectCellDate() {
      if (this.dayInfo.date === this.selectedDate) {
        this.setSelectedDate(this.dayInfo.date);
        return;
      }
      this.setSelectedDate(null);

      const showLockDelay = 100;
      this.delayedSelect = setTimeout(() => {
        this.setSelectedDate(this.dayInfo.date);
      }, showLockDelay);
    },

    clearCellDate() {
      clearTimeout(this.delayedSelect);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44px;
  padding: 8px 0;
  transition: color 0.3s ease;

  &--locked {
    color: $sk-grey-30;
  }

  &--clickable {
    cursor: pointer;
  }

  &__day-info {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .day-name {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .day-number {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 600;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }

  &.not-in-month {
    color: $sk-grey-30;
  }
}

.icons-section {
  position: absolute;
  width: 100%;
  bottom: 2px;
  display: flex;
  justify-content: space-between;
  cursor: default;

  &--right {
    display: flex;
    align-items: center;
  }
}

.header-day__footer-lock {
  margin-left: auto;

  &--validated {
    fill: $sk-grey-30;
  }

  &--intermediate {
    fill: $sk-success;
  }

  &--permanent {
    fill: $sk-error;
  }
}
</style>
