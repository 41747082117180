<template>
  <SkModalV2
    id="export-settings-modal"
    ref="exportSettingsModal"
    :testing-options="modalTestingOptions"
    :title="$t('reports.modals.export_settings.title')"
    @show="handleShow"
    @close="handleCancel"
    @cancel="handleCancel"
  >
    <template #body>
      <div class="export_settings_modal__nav__wrapper">
        <p>{{ $t('reports.modals.export_settings.subtitle') }}</p>
        <SkNav>
          <SkNavItem
            v-for="(worksheet, index) in worksheets"
            :key="`worksheet-nav-${worksheet}-${index}`"
            data-test="export-settings-modal__nav"
            :active="isActive(worksheet)"
            @click="setAsSelectedTab(worksheet)"
          >
            <div
              class="export_settings_modal__tab-title__wrapper"
            >
              {{ $t(`reports.modals.export_settings.tab_titles.${worksheet}`) }}
            </div>
          </SkNavItem>
        </SkNav>
      </div>

      <div class="export_settings_modal__content__wrapper">
        <div
          v-for="
            (settingSection, sectionIndex) in
              Object.keys(exportCategories[selectedNavItem])
          "
          :key="`setting-section-${settingSection}-${sectionIndex}`"
        >
          <h1 class="export_settings_modal__setting-section-title">
            {{ settingSection }}
          </h1>
          <div
            v-for="(setting, index) in exportCategories[selectedNavItem][settingSection]"
            :key="`setting-${setting.column_name}-${index}`"
            class="export_settings_modal__checkbox"
          >
            <SkCheckBox
              v-model="setting.active"
              :data-test="`export-settings-modal__checkbox-${setting.column_name}`"
              :label="setting.name"
            />
          </div>
        </div>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        data-test="export-settings-modal__submit"
        :loading="exportOptionsLoading"
        @click="handleSubmit"
      >
        {{ $t('reports.modals.export_settings.buttons_label.save_export') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';

import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'ExportSettingsModal',
  props: {
    exportCategories: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedNavItem: 'first_worksheet',
      modalTestingOptions: {
        cancel: 'unemployment-report-modal__cancel',
      },
      worksheets: [],
    };
  },
  computed: {
    ...mapState('report', ['exportOptionsLoading']),
    // ...mapState('currentShop', ['currentShop']),
  },
  methods: {
    ...mapActions('report', ['updateExportOptions']),

    isActive(item) {
      return this.selectedNavItem === item;
    },
    setAsSelectedTab(item) {
      this.selectedNavItem = item;
      this.$refs.exportSettingsModal.resetScroll();
    },
    toggleSetting(setting) {
      setting.active = !setting.active;
    },
    handleSubmit(event) {
      const disabledColumns = this.exportCategoriesV1Format();

      this.updateExportOptions(disabledColumns)
        .then(() => {
          this.$skToast({
            message: this.$t('reports.modals.export_settings.toasts.success'),
            variant: 'success',
          });

          this.$refs.exportSettingsModal.hide();
          this.emitOnRoot(MODAL_HIDE_EVENT, null, 'export-settings-modal');
          this.$emit('export-settings-saved');
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    // Returns disabled columns in export in V1 controller format:
    // {
    //   "first_sheet_disabled_columns": ["disabledColumnName", ...],
    //   "third_sheet_disabled_columns": ["disabledColumnName", ...],
    //   "fourth_sheet_disabled_columns": ["disabledColumnName", ...],
    //   "legacy_second_sheet_disabled_columns": ["disabledColumnName", ...],
    //   "second_sheet_disabled_columns": ["disabledColumnName", ...]
    // }
    exportCategoriesV1Format() {
      const disabledColumns = {};
      this.worksheets.forEach(worksheet => {
        const attributeName = `${worksheet.replace('worksheet', 'sheet')}_disabled_columns`;
        disabledColumns[attributeName] = [];

        Object.keys(this.exportCategories[worksheet]).forEach(section => (
          this.exportCategories[worksheet][section].forEach(setting => {
            if (!setting?.active) {
              disabledColumns[attributeName].push(setting.column_name);
            }
          })
        ));
      });

      return disabledColumns;
    },
    handleShow() {
      this.worksheets = [
        'first_worksheet',
        'legacy_second_worksheet',
        'second_worksheet',
        'third_worksheet',
      ];

      if (this.exportCategories.fourth_worksheet) {
        this.worksheets.push('fourth_worksheet');
      }

      // if (this.currentShop.attributes.plcInitializationDoneAt) {
      //   this.worksheets.push('fifth_worksheet');
      // }
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'export-settings-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.export_settings_modal__nav__wrapper {
  position: sticky;
  top: 0;
  background-color: $sk-white;
  z-index: 1;
  padding: 15px 24px 0;
}

.export_settings_modal__tab-title__wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 100px;
  white-space: normal;
  height: 25px;
}

.export_settings_modal__setting-section-title {
  font-size: $fs-text-m;
  font-weight: $fw-semi-bold;
  margin-top: 8px;
}

.export_settings_modal__content__wrapper {
  padding: 0 0 24px;
  margin: 0 24px;
}

.export_settings_modal__checkbox {
  display: flex;
  align-items: baseline;
  margin-bottom: 8px;
  gap: 10px;
}

.export_settings_modal__checkbox__message {
  cursor: pointer;
}
</style>
