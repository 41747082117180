<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'EsignatureAction',
  components: {},
  extends: GenericToolbarAction,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['checkFeatureFlag', 'isDevFlagEnabled']),
    ...mapGetters('planningsState', ['isDailyView']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.attendance_sheets_esignature.label');
    },
    icon() {
      return 'SignV2Icon';
    },
    iconSize() {
      return '20';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    disabled() {
      return this.isDataLoading;
    },
    isNew() {
      return true;
    },
    isAttendanceSheetsEsignatureVisible() {
      return (
        !this.isDailyView &&
        this.checkFeatureFlag('FEATURE_ESIGNATURE') &&
        this.currentLicense.attributes.canPublishPlanning &&
        (
          this.currentShop.attributes.esignatureActive ||
          this.isDevFlagEnabled('FEATUREDEV_ESIGNATURE_UPSELL')
        )
      );
    },
    isVisible() {
      return this.isAttendanceSheetsEsignatureVisible;
    },
    dataTest() {
      return 'es-planning-toolbar';
    },
    active() {
      return true;
    },
  },
  methods: {
    ...mapActions('planningsState', ['fetchWeeklyAttendanceSheetSignatures']),
    handler() {
      if (this.currentShop.attributes.esignatureActive) {
        this.fetchWeeklyAttendanceSheetSignatures(this.currentShop.id)
          .then(() => this.emitOnRoot('open-esignature'));
      } else {
        this.$skAnalytics.track('esignature_upsell_open');
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'esignature-upsell-modal');
      }
    },
  },
};
</script>
