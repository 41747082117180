<template>
  <SkModal
    id="intermediate-validate-period-modal"
    ref="intermediateValidatePeriodkModal"
    :modal-title="$t('plannings.toolbar.modal.intermediate_validate_period.title')"
    size="ds-medium"
    :submit-disabled="disableSubmit"
    :testing-options="{ submit: 'intermediate-validate-period-modal__submit-btn' }"
    @show="handleShow"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #title-icon>
      <div class="intermediate-validate-period-modal__title-icon">
        <CloseLockIcon fill="#188377" />
      </div>
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <div class="intermediate-validate-period-modal__description">
          {{ $t('plannings.toolbar.modal.intermediate_validate_period.description') }}
        </div>
        <div class="intermediate-validate-period-modal__line">
          <span class="intermediate-validate-period-modal__line__label">
            {{ $t('plannings.toolbar.modal.intermediate_validate_period.datepicker.label') }}
          </span>
          <div class="intermediate-validate-period-modal__line__datepicker">
            <!-- eslint-disable max-len -->
            <SkDatePicker
              ref="datepicker"
              v-model="startDate"
              :default-value="mondayAsDate"
              :placeholder="$t('plannings.toolbar.modal.intermediate_validate_period.datepicker.start_date')"
              :disabled-date="disabledStartDate"
              :errored="invalidDate"
              :error-message="$t('plannings.toolbar.modal.intermediate_validate_period.datepicker.error')"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
            />
          </div>
          <!-- eslint-enable max-len -->
          <div class="intermediate-validate-period-modal__line__separator">
            -
          </div>
          <!-- eslint-disable max-len -->
          <div class="intermediate-validate-period-modal__line__datepicker">
            <SkDatePicker
              ref="datepicker"
              v-model="endDate"
              :default-value="sundayAsDate"
              :placeholder="$t('plannings.toolbar.modal.intermediate_validate_period.datepicker.end_date')"
              :disabled-date="disabledEndDate"
              :errored="invalidDate"
              :error-message="$t('plannings.toolbar.modal.intermediate_validate_period.datepicker.error')"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
            />
            <!-- eslint-enable max-len -->
          </div>
        </div>
        <div class="intermediate-validate-period-modal__notice">
          <IconInfo class="intermediate-validate-period-modal__notice__icon" />
          {{ $t('plannings.toolbar.modal.intermediate_validate_period.notice') }}
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>
<script>
import {
  mapGetters,
  mapState,
  mapActions,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';

export default {
  name: 'IntermediateValidatePeriodModal',
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['monday', 'sunday', 'currentDate']),
    ...mapState('currentShop', ['currentShop']),
    mondayAsDate() {
      return skDate(this.monday).toDate();
    },
    sundayAsDate() {
      return skDate(this.sunday).toDate();
    },
    invalidDate() {
      return skDate(this.startDate).isAfter(skDate(this.endDate));
    },
    disableSubmit() {
      return this.invalidDate || this.startDate === null || this.endDate === null;
    },
  },
  methods: {
    ...mapActions('planningsState', ['validatePeriod']),
    handleShow() {
      this.startDate = this.mondayAsDate;
      this.endDate = this.sundayAsDate;
    },
    disabledStartDate(date) {
      return skDate(date).isAfter(skDate(this.endDate));
    },
    disabledEndDate(date) {
      return skDate(date).isBefore(skDate(this.startDate));
    },
    handleSubmit() {
      this.$skAnalytics.track('week_planning_intermediate_validate_period');

      const params = {
        shopId: this.currentShop.id,
        startDate: this.startDate,
        endDate: this.endDate,
        currentDate: this.currentDate,
        validationLevel: 'intermediate_locked_days',
        // it's not possible to unvalidate a period atm
        validationValue: true,
      };

      this.validatePeriod(params)
        .then(() => {
          this.$skToast({ message: this.$t('plannings.toolbar.modal.intermediate_validate_period.action.success'), variant: 'success' });
        })
        .catch(() => {
          this.$skToast({ message: this.$t('plannings.toolbar.modal.intermediate_validate_period.action.error'), variant: 'error' });
        });

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'intermediate-validate-period-modal');
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'intermediate-validate-period-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.intermediate-validate-period-modal {
  &__title-icon {
    display: flex;
    padding: 9px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: $sk-success-10;
  }

  &__description {
    margin-bottom: 24px;
    margin-top: -5px;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__label {
      font-weight: $fw-semi-bold;
      padding-right: 100px;
    }

    &__datepicker {
      width: 160px;
    }

    &__separator {
      padding: 10px 17px;
    }
  }

  &__notice {
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
    padding: 11px 17px 14px 18px;
    margin-bottom: 20px;

    &__icon {
      float: left;
      margin-top: 8px;
      margin-right: 19px;
    }
  }
}
</style>
