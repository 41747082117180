export const planningPosteZoom = {
  25: {
    planningRow: {
      minHeight: '52px',
    },
  },
  50: {
    planningRow: {
      minHeight: '62px',
    },
  },
  75: {
    planningRow: {
      minHeight: '66px',
    },
  },
  100: {
    planningRow: {
      minHeight: '76px',
    },
  },
  125: {
    planningRow: {
      minHeight: '80px',
    },
  },
  150: {
    planningRow: {
      minHeight: '81px',
    },
  },
  175: {
    planningRow: {
      minHeight: '92px',
    },
  },
};
