<template>
  <div
    v-if="isWorkloadPlansLoading"
    class="workload-plan-left-panel--loading"
  >
    <div class="workload-plan-left-panel__label--loading" />
    <div class="workload-plan-left-panel__poste-select--loading" />
  </div>

  <div
    v-else
    :class="workloadPlanLeftPanelClasses"
  >
    <div
      :class="workloadPlanLeftPanelLabelClasses"
      @click="togglePanel"
    >
      <span class="workload-plan-left-panel__text">
        {{ $t('workload_plan.label') }}
      </span>
      <span class="workload-plan-left-panel__icon">
        <CaretUpIcon v-if="isWorkloadPlanPanelOpen" />
        <CaretDownIcon v-if="shouldShowCaretDown" />
      </span>
    </div>

    <PostesSelect
      v-if="isWorkloadPlanPanelOpen"
      :poste-id="posteId"
      :postes="postes"
      :disabled="isEmptyStateDisplayed"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import PostesSelect from './PostesSelect';

export default {
  name: 'WorkloadPlanLeftPanel',
  components: { PostesSelect },
  props: {
    posteId: {
      type: String,
      required: false,
      default: null,
    },
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    isWorkloadPlanPanelOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWorkloadPlanEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWorkloadPlansLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
    isEmptyStateDisplayed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    workloadPlanLeftPanelClasses() {
      return {
        'workload-plan-left-panel--open': this.isWorkloadPlanPanelOpen,
      };
    },
    workloadPlanLeftPanelLabelClasses() {
      return {
        'workload-plan-left-panel__label': true,
        'workload-plan-left-panel__label--open': this.isWorkloadPlanPanelOpen,
        'workload-plan-left-panel__label--closed': !this.isWorkloadPlanPanelOpen,
      };
    },
    shouldShowCaretDown() {
      return this.isWorkloadPlanEnabled && !this.isWorkloadPlanPanelOpen;
    },
  },
  methods: {
    togglePanel() {
      this.$emit('toggle-panel');
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-left-panel {
  &--open {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 17px 8px;
  }

  &__label {
    width: 161px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &--open {
      padding-right: 14px;
      padding-left: 8px;
    }

    &--closed {
      padding: 17px 14px 17px 8px;
    }
  }

  &__text {
    font-size: $fs-text-s;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &--loading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 17px 8px;
  }

  &__label--loading {
    height: 19px;
    width: 106px;
    background-color: $sk-grey-10;
    border-radius: 4px;
  }

  &__poste-select--loading {
    height: 27px;
    width: 144px;
    margin-top: 16px;
    background-color: $sk-grey-10;
    border-radius: 4px;
  }
}
</style>
