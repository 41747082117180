<template>
  <div
    :class="cardContainerClasses"
    @click="handleRedirection"
  >
    <div class="free-trial-card__content">
      <SkMedallion
        icon="GiftIcon"
        background-color="#d9e6ff"
        color="#2b66fe"
        size="x-small"
      />
      <span class="free-trial-card__label text-truncate">
        {{ $tc('navbar.free_trial', freeTrialDaysLeft) }}
      </span>
      <div class="free-trial-card__icon">
        <ChevronRightV2Icon
          width="16px"
          height="16px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  ChevronRightV2Icon,
  SkMedallion,
} from '@skelloapp/skello-ui';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'FreeTrialCard',
  components: {
    ChevronRightV2Icon,
    SkMedallion,
  },
  computed: {
    ...mapGetters('currentOrganisation', ['freeTrialDaysLeft']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation']),

    cardContainerClasses() {
      return {
        'free-trial-card': true,
        [`free-trial-card--${this.packOfferName}`]: true,
      };
    },
    packOfferName() {
      return this.isDevFlagEnabled('FEATUREDEV_V5_SELF_SERVE') && this.isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK, 'all') ?
        'timeclock_standalone_success' :
        this.currentOrganisation.attributes.packOffer.name;
    },
  },
  methods: {
    handleRedirection() {
      this.$router.push({ name: 'organisation_settings' });
    },
  },
};
</script>

<style lang="scss" scoped>
.free-trial-card {
  display: flex;
  height: 48px;
  margin: 8px 8px 6px 8px;
  padding: 1px;
  border-radius: 4px;

  &--basic { background: $sk-basic-gradient; }

  &--success { background: $sk-success-gradient; }

  &--premium { background: $sk-premium-gradient; }

  &--timeclock_standalone_success { background: $sk-timeclock-standalone-success-gradient; }

  &__content {
    display: flex;
    flex: 1;
    border-radius: 3px;
    padding: 10px 8px;
    align-items: center;
    background: $sk-white;

    &:hover {
      background-color: $sk-blue-5;
      cursor: pointer;
    }
  }

  &__label {
    padding-left: 8px;
    width: 158px;
    font-size: $fs-text-m;
    line-height: 16px;
  }

  &__icon {
    display: flex;
    margin-left: auto;
  }
}
</style>
