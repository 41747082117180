<template>
  <SkModalV2
    id="annualization-config-user-initialization-modal"
    ref="annualizationInitializationModal"
    :title="$t('employees.tabs.counters.annualization_counter.modal.title', employee_name)"
    :submit-button-label="$t('employees.tabs.counters.rtt_counter.table.init_modal.submit')"
    :submit-disabled="!theoretical || !initDate"
    :submit-loading="isSubmitLoading"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSectionV2
        border-bottom="none"
        :padding-bottom="isLoading"
      >
        <div
          v-if="isLoading"
          class="hours-volume-section__loader"
        >
          <SkLoader size="large" />
        </div>
        <div v-else>
          <div class="form-group">
            <div class="label">
              {{ $t('employees.tabs.counters.annualization_counter.modal.reference_period') }}
            </div>
            <div>
              <SkTag
                variant="light"
                class="tag"
              >
                {{ $t('shop_settings.tabs.rules.counter_rules.annualization_v2.hours_volume_section.reference_period.content', periodDatesLabels) }}
              </SkTag>
            </div>
          </div>

          <div class="form-group">
            <div class="label initialization-date-wrapper">
              <div>
                {{ $t('employees.tabs.counters.annualization_counter.modal.initialization_date') }}
              </div>
              <CircledQuestionMarkIcon
                v-tooltip.auto="$t('employees.tabs.counters.annualization_counter.modal.tooltip')"
                height="14"
                width="14"
              />
            </div>
            <SkDatePicker
              v-model="initDate"
              :disabled-date="disabledDate"
              :error-message="$t('shop_settings.tabs.teams.variable_hours_modal.team_schedule_section.date.error')"
              :clearable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              append-to-body
            />
          </div>
          <div class="hours-volume-section__user-input-headers">
            <div class="hours-volume-section__user-input-headers--column">
              {{ $t('employees.tabs.counters.annualization_counter.modal.theoretical') }}
              <span class="hours-volume-section__user-input-subtitles">
                {{ $t('employees.tabs.counters.annualization_counter.modal.period_date', periodDatesLabels) }}
              </span>
            </div>
            <div class="hours-volume-section__user-input-headers--column">
              {{ $t('employees.tabs.counters.annualization_counter.modal.realized') }}
              <span class="hours-volume-section__user-input-subtitles">
                {{ computedRealizedDates }}
              </span>
            </div>
            <div class="hours-volume-section__user-input-headers--column">
              {{ $t('employees.tabs.counters.annualization_counter.modal.remaining') }}
              <span class="hours-volume-section__user-input-subtitles">
                {{ computedRemainingDates }}
              </span>
            </div>
          </div>
          <div>
            <UserAnnualizationRow
              :key="employee.id"
              :contract-hours="userContractHours"
              :initials="userInitials(employee)"
              :full-name="fullName(employee)"
              :theoretical-balance="computedTheoretical"
              @update-theoretical="value => theoretical = value"
              @update-realized="value => realizedBalance = value"
            />
          </div>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import {
  AnnualizationManager,
  getPeriodDatesAt,
  theoreticalBalance,
} from '@skelloapp/skello-annualization';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';
import UserAnnualizationRow from '@app-js/shared/components/UserAnnualizationRow';

export default {
  name: 'InitializationModal',
  components: { UserAnnualizationRow },
  data() {
    return {
      fetchedEmployee: {
        attributes: {
          permanentContractHoursAt: 0,
        },
      },
      localInitDate: null,
      isLoading: false,
      isSubmitLoading: false,
      theoretical: null,
      realizedBalance: 0,
    };
  },
  computed: {
    ...mapState('annualization', ['shopAnnualizationConfig']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('currentShop', ['isAnnualizationContractHoursChangeEnabled']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    initDate: {
      get() {
        return this.localInitDate;
      },
      set(value) {
        this.localInitDate = value;
        if (this.currentShop.attributes.isFrenchShop) {
          this.theoretical = this.computeProrata();
        }
      },
    },
    periodDates() {
      const now = skDate();
      const initDate = skDate.utc(this.shopAnnualizationConfig?.initializationDate);
      const startDate = skDate.utc(this.shopAnnualizationConfig?.resetDate).subtract(1, 'year');

      const referenceDate = skDate.max([now, initDate]);

      startDate.set('year', referenceDate.year());

      if (startDate.isAfter(referenceDate)) {
        startDate.subtract(1, 'year');
      }

      const endDate = startDate.clone().add(1, 'year').subtract(1, 'day');

      return { startDate, endDate };
    },
    periodDatesLabels() {
      return {
        start_date: this.periodDates.startDate.format('DD/MM/YY'),
        end_date: this.periodDates.endDate.format('DD/MM/YY'),
      };
    },
    remainingDates() {
      return {
        start_date: skDate(this.initDate).format('DD/MM/YY'),
        end_date: this.periodDates.endDate.format('DD/MM/YY'),
      };
    },
    computedRemainingDates() {
      if (!this.initDate) return '-';
      return this.$t('employees.tabs.counters.annualization_counter.modal.period_date', this.remainingDates);
    },
    realizedDates() {
      const startDate = this.periodDates.startDate;
      const initDate = skDate.utc(this.initDate);
      const endDate = startDate.isSame(initDate, 'day') ? initDate : initDate.subtract(1, 'day');

      return {
        start_date: startDate.format('DD/MM/YY'),
        end_date: endDate.format('DD/MM/YY'),
      };
    },
    computedRealizedDates() {
      if (!this.initDate) return '-';
      return this.$t('employees.tabs.counters.annualization_counter.modal.period_date', this.realizedDates);
    },
    employee_name() {
      return {
        fisrt_name: this.employee.attributes.firstName,
        last_name: this.employee.attributes.lastName,
      };
    },
    userContractHours() {
      // When the annualization contract hours change feature is enabled, we use the fetchedEmployee
      // to get the permanentContractHoursAt value for the periodStartDate
      // Otherwise, we use the employee from the store to get the currentPermanentContractHours value
      return this.isAnnualizationContractHoursChangeEnabled ?
        this.fetchedEmployee.attributes.permanentContractHoursAt :
        this.employee.attributes.currentPermanentContractHours;
    },
    computedTheoretical() {
      return (this.theoretical === null || this.theoretical === 0) ?
        this.computeTheoreticalBalance() :
        Number(this.theoretical);
    },
  },
  methods: {
    ...mapActions('annualization', ['fetchAndComputeAnnualizationData', 'fetchEmployeeAnnualizationConfigs']),
    computeProrata() {
      if (!this.initDate) {
        return this.theoretical;
      }

      const date = skDate.utc(this.initDate);

      const diffDays = this.periodDates.endDate.diff(date, 'days') + 1;
      const diffDaysInPeriod = this.periodDates.endDate.diff(this.periodDates.startDate, 'days') + 1;
      return Math.round((diffDays / diffDaysInPeriod) * this.computeTheoreticalBalance());
    },
    computeTheoreticalBalance() {
      if (!this.isAnnualizationContractHoursChangeEnabled) {
        const annualizationManager = new AnnualizationManager({
          solidarityDayEnabled: true,
        });
        annualizationManager.setProps({
          contractHours: this.employee.attributes.currentPermanentContractHours,
        });

        return annualizationManager.computeTheoreticalBalance();
      }

      return theoreticalBalance(this.fetchedEmployee.attributes.permanentContractHoursAt);
    },
    disabledDate(date) {
      const selectedDate = skDate(date);
      return selectedDate.isBefore(this.periodDates.startDate, 'day') ||
        selectedDate.isAfter(this.periodDates.endDate, 'day');
    },
    async fetchEmployee() {
      this.isLoading = true;

      const params = {
        permanent_contract_hours_at_date: this.shopAnnualizationConfig.currentPeriodStartDate,
      };

      // We are doing this specific fetch to get permanentContractHoursAt value for the annualization periodStartDate
      // This is needed to handle contract hours amendments when initailizing the annualization feature
      try {
        const { data } = await httpClient.get(`/v3/api/users/${this.$router.currentRoute.params.user_id}`,
          { params },
        );
        this.fetchedEmployee = data.data;
      } catch (error) {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async handleShow() {
      if (this.isAnnualizationContractHoursChangeEnabled) {
        await this.fetchEmployee();
      }

      if (this.currentShop.attributes.isFrenchShop) {
        this.theoretical = this.computeTheoreticalBalance();
      }
    },
    handleCancel(event) {
      event.preventDefault();

      this.$skAnalytics.track('annualization_cancel_init');
      this.hideModal();
    },
    async handleSubmit(event) {
      event.preventDefault();
      this.isSubmitLoading = true;

      const shopId = this.currentShop.id === 'all' ? this.employee.attributes.shopId : this.currentShop.id;
      const userId = this.employee.id;
      const data = {
        userId,
        shopId: `${shopId}`,
        initializationDate: this.initDate,
        theoreticalBalances: { [this.periodDates.startDate.valueOf()]: this.theoretical },
        initializationRealizedBalance: this.realizedBalance,
        initializationRemainingBalance: this.theoretical - this.realizedBalance,
        initializationPtoCount: 25,
      };

      if (this.isAnnualizationContractHoursChangeEnabled) {
        data.contractHours = { [this.periodDates.startDate.valueOf()]: this.userContractHours };
      }

      try {
        await svcEmployeesClient.createAnnualizationConfig(data);

        this.$skAnalytics.track('annualization_validate_init');

        await this.fetchEmployeeAnnualizationConfigs({ shopId, userId });
        await this.fetchAndComputeAnnualizationData({
          shopId,
          userIds: [userId],
        });
      } catch {
        this.$skToast({
          message: this.$t('employees.tabs.counters.annualization_counter.modal.toast_error'),
          variant: 'error',
        });
      } finally {
        this.isSubmitLoading = false;
        this.hideModal();
      }
    },
    hideModal() {
      this.resetData();
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'annualization-config-user-initialization-modal');
    },
    resetData() {
      this.initDate = null;
      this.isSubmitLoading = false;
      this.theoretical = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &:nth-child(1) {
    margin-bottom: 20px;
  }

  &:nth-child(2) {
    margin-bottom: 29px;
  }

  &:nth-child(3) {
    margin-bottom: 8px;
  }

  & > div:first-child {
    width: 236px;
  }

  & > :not(:first-child) {
    flex: 1;
  }

  & .tag {
    background-color: $sk-grey-5;
  }

  & .label {
    font-family: $sk-base-font;
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }

  & .initialization-date-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .label-period-dates {
    font-size: 10px;
    color: $sk-grey-50;
  }
}

.hours-volume-section {
  &__loader {
    display: flex;
    justify-content: center;
  }

  &__user-input-headers {
    margin-left: 162px;
    margin-bottom: 10px;
    color: $sk-grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--column {
      width: 130px;
      display: flex;
      flex-direction: column;
    }
  }

  &__user-input-subtitles {
    color: $sk-grey;
    font-weight: 700;
    font-size: 10px;
  }
}

.annualization-user-row {
  display: flex;
  align-items: center;
  border-bottom: 1px $sk-grey-10 solid;
  border-top: 1px $sk-grey-10 solid;
  padding: 10px 0;

  &__user-info-container {
    font-family: $sk-base-font;
    display: flex;
    width: 200px;
    max-width: 200px;
  }

  &__medallion {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    width: 160px;
    line-height: 1.2;
  }

  &__user-fullname {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__contract-hours {
    color: $sk-red-light-2;
  }
}
</style>
