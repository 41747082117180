<template>
  <SkEventModal
    :flubber-color="$skColors.skAzureBlue10"
    :header-icon="briefcaseIcon"
    :header-icon-color="$skColors.skAzureBlue50"
    submit-button-data-test="convention_continue"
    :on-submit-click="handleSubmit"
    :submit-btn-label="$t('onboarding_modal.convention.submit_text')"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isSubmitLoading"
  >
    <template #content>
      <div class="convention-step__wrapper">
        <div class="convention-step__title-wrapper">
          <h1 class="sk-heading-large-semibold convention-step__title">
            {{ $t('onboarding_modal.convention.title') }}
          </h1>

          <div class="convention-step__text">
            <span class="sk-text-large-regular">
              {{ $t('onboarding_modal.convention.text') }}
            </span>

            <div class="convention-step__text-alert">
              <InfoV2Icon
                :fill="$skColors.skAzureBlue50"
                width="16"
                height="16"
              />

              <span class="sk-text-medium-regular">
                {{ $t('onboarding_modal.convention.text_alert') }}
              </span>
            </div>
          </div>
        </div>

        <SkSelectV2
          v-model="selectedConvention"
          :options="conventionsOptions"
        >
          <template #selected-option="{ value }">
            <span
              v-tooltip="showConventionTooltip(value)"
              class="step-three-section__select-label"
            >
              {{ getConventionNameText(value) }}
            </span>
          </template>
          <template #option="{ option }">
            <span
              v-tooltip="showConventionTooltip(option.id)"
              class="step-three-section__select-label"
              @click="trackConvention(option.text)"
            >
              {{ getConventionNameText(option.id) }}
            </span>
          </template>
        </SkSelectV2>
      </div>
    </template>
  </SkEventModal>
</template>

<script>
import {
  BriefcaseIcon, InfoV2Icon, SkSelectV2,
} from '@skelloapp/skello-ui';
import {
  mapActions, mapState,
} from 'vuex';

export default {
  name: 'ConventionStep',
  components: { InfoV2Icon, SkSelectV2 },
  props: {},
  data() {
    return {
      briefcaseIcon: BriefcaseIcon,
      isSubmitLoading: false,
      selectedConvention: null,
      conventionsOptions: [],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),

    isSubmitDisabled() {
      const noConventionSelected = !this.selectedConvention;
      const conventionNotInOptions = !this.conventionsOptions.find(
        convention => convention.id === this.selectedConvention,
      );

      return noConventionSelected || conventionNotInOptions;
    },
  },
  created() {
    this.setConventionsOptions();
    this.selectedConvention = this.currentShop.relationships.convention.attributes.name;
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),

    setConventionsOptions() {
      this.conventionsOptions = this.config.conventions_collection.map(
        convention => ({
          id: convention.name,
          text: convention.legible_name,
        }),
      );
    },
    handleSubmit() {
      if (this.isSubmitLoading) return;

      this.isSubmitLoading = true;
      const shopId = this.currentShop.id;
      const params = {
        convention: { name: this.selectedConvention },
      };

      this.updateShop({ shopId, overrideParams: params })
        .then(() => {
          this.$skAnalytics.track('onboarding_submit_convention_step');
          this.$emit('next');
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.isSubmitLoading = false;
        });
    },
    getConventionNameText(conventionName) {
      return (
        this.conventionsOptions.find(
          convention => convention.id === conventionName,
        )?.text || ''
      );
    },
    showConventionTooltip(conventionName) {
      const conventionNameText = this.getConventionNameText(conventionName);
      return conventionNameText.length > 50 ? conventionNameText : null;
    },
    trackConvention(conventionName) {
      this.$skAnalytics.track('admin_onboarding_convention', {
        source: conventionName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.convention-step__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.convention-step__title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.convention-step__title {
  margin: 0;
}

.convention-step__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $sk-grey-50;
}

.convention-step__text-alert {
  display: flex;
  flex-direction: row;
  gap: 4px;

  & > svg {
    min-width: 16px;
    min-height: 16px;
  }
}

.convention-step__reasons {
  padding-bottom: 16px;
}
</style>
