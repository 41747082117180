<template>
  <div class="my-softwares__list-row">
    <PayPartnersCard
      ref="payPartnerCard"
      icon-name="Silae"
      name="Silae"
      :disabled="!isSilaeEnabled || !isDataInitialized"
      :hide-caret="!isSilaeEnabled"
    >
      <template
        v-if="!isSilaeEnabled && isDataInitialized"
        #cta
      >
        <SkOroraButton @click.stop="openActivationModal">
          {{ $t('organisation_settings.tabs.pay_partners.cta') }}
        </SkOroraButton>
      </template>
      <template #tag>
        <SkLoader v-if="!isDataInitialized" />
        <SkOroraTag
          v-if="isSilaeEnabled && isDataInitialized"
          :background-color="tagBackgroundColor"
          :color="tagTextColor"
        >
          {{ tagText }}
        </SkOroraTag>
      </template>
      <div
        v-if="isSilaeEnabled && isDataInitialized"
        class="card-content"
      >
        <span class="sk-text-medium-regular">{{ headerText }}</span>
        <table
          v-if="!isSilaeConnectionPending && paginatedSilaeMissingPinShops.length > 0"
          class="card-content__table"
        >
          <thead>
            <th class="sk-text-medium-regular">
              {{ $t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.shop') }}
            </th>
            <th class="sk-text-medium-regular">
              {{ $t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.siret') }}
            </th>
            <th class="sk-text-medium-regular">
              {{ $t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.pin') }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="{ attributes } in paginatedSilaeMissingPinShops[currentPage - 1]"
              :key="attributes.id"
            >
              <td> {{ attributes.name }}</td>
              <td> {{ attributes.siret || '-' }}</td>
              <td>
                <SkInput
                  v-model="attributes.pay_identification_number"
                  :label="$t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.pin_input')"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <SkPaginate
          v-if="paginatedSilaeMissingPinShops.length > 1"
          v-model="currentPage"
          :total-pages="paginatedSilaeMissingPinShops.length"
        />
        <footer class="card-content__footer">
          <SkOroraButton
            icon="ArrowLogoutIcon"
            variant="tertiary"
            :variant-color="$skColors.skError"
            @click="handleSilaeDeactivation"
          >
            {{ $t('organisation_settings.tabs.pay_partners.fortify.disconnect') }}
          </SkOroraButton>

          <div class="card-content__footer-buttons">
            <SkOroraButton
              variant="secondary"
              @click="handleCredentialsFillingStepClick"
            >
              {{ updateKeysButtonText }}
            </SkOroraButton>
            <SkOroraButton
              v-if="!isSilaeConnectionPending"
              :disabled="areAllPinMissing"
              :loading="isFetchingOrUpdatingData"
              @click="handleSubmit"
            >
              {{ submitButtonText }}
            </SkOroraButton>
          </div>
        </footer>
      </div>
    </PayPartnersCard>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <SilaeActivationModal
        :land-on-credentials-step="
          isSilaeEnabled &&
            (isSilaeConnectionPending || arePinMissing || haveEmployeesMissingAttributes)
        "
        @submit="handleSilaeActivation"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import SilaeActivationModal from './SilaeActivationModal';
import PayPartnersCard from '../PayPartnersCard.vue';

export default {
  name: 'FortifySilaeCard',
  components: {
    SilaeActivationModal,
    PayPartnersCard,
  },
  data() {
    return {
      currentPage: 1,
      isDataInitialized: false,
      isFetchingOrUpdatingData: false,
      haveEmployeesMissingAttributes: false,
    };
  },
  computed: {
    ...mapGetters('organisationCredentials', [
      'isIntegrationPending',
      'isIntegrationEnabled',
      'paginatedSilaeMissingPinShops',
    ]),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('organisationCredentials', [
      'isFetchingCredentials',
      'isFetchingSilaeMissingPinShops',
      'organisationCredentials',
      'silaeMissingPinShops',
      'totalShopsCount',
    ]),
    arePinMissing() {
      return this.silaeMissingPinShops.some(
        ({ attributes }) => !attributes.pay_identification_number,
      );
    },
    areAllPinMissing() {
      return this.silaeMissingPinShops.length > 0 && this.silaeMissingPinShops.every(
        ({ attributes }) => !attributes.pay_identification_number,
      );
    },
    isSilaeEnabled() {
      return this.isIntegrationEnabled('SILAE');
    },
    isSilaeConnectionPending() {
      return this.isIntegrationPending('SILAE');
    },
    updateKeysButtonText() {
      return this.isSilaeConnectionPending ?
        this.$t('organisation_settings.tabs.pay_partners.fortify.enter_keys') :
        this.$t('organisation_settings.tabs.pay_partners.fortify.change_keys');
    },
    submitButtonText() {
      if (this.silaeMissingPinShops.length > 0) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.cta');
      }

      if (this.haveEmployeesMissingAttributes) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.missing_employee_pin.cta');
      }

      return '';
    },
    headerText() {
      if (this.isSilaeConnectionPending) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.waiting');
      }

      if (this.silaeMissingPinShops.length > 0) {
        return this.totalShopsCount === 1 ?
          this.$t('organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.description.single') :
          this.$tc(
            'organisation_settings.tabs.pay_partners.fortify.missing_shop_pin.description.plural',
            this.totalShopsCount - this.silaeMissingPinShops.length,
            {
              count: this.totalShopsCount - this.silaeMissingPinShops.length,
              total: this.totalShopsCount,
            },
          );
      }

      if (this.haveEmployeesMissingAttributes) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.missing_employee_pin.description');
      }

      return this.$t('organisation_settings.tabs.pay_partners.fortify.description');
    },
    tagBackgroundColor() {
      return this.isSilaeConnectionPending || this.silaeMissingPinShops.length > 0 ?
        'skWarning10' :
        'skSuccess10';
    },
    tagTextColor() {
      return this.isSilaeConnectionPending || this.silaeMissingPinShops.length > 0 ?
        'skWarning' :
        'skSuccess';
    },
    tagText() {
      if (this.isSilaeConnectionPending) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.connection.waiting');
      }

      if (this.silaeMissingPinShops.length > 0) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.connection.missing_data');
      }

      return this.$t('organisation_settings.tabs.pay_partners.fortify.connection.connected');
    },
  },
  async mounted() {
    await this.fetchOrganisationCredentials();

    if (this.isSilaeEnabled && this.isSilaeConnectionPending) {
      this.isDataInitialized = true;
      this.$refs.payPartnerCard.$refs.collapse.openCollapse();

      return;
    }

    const promises = [
      this.fetchTotalShopsCount(),
    ];

    if (this.isSilaeEnabled) {
      promises.push(this.fetchMissingPinShops());
    } else {
      promises.push(this.fetchGlobalIntegrations());
    }

    await Promise.all(promises);

    if (this.paginatedSilaeMissingPinShops.length === 0) {
      await this.fetchUserMissingAttributes();
    }

    this.isDataInitialized = true;
    this.$refs.payPartnerCard.$refs.collapse.openCollapse();
  },
  methods: {
    ...mapActions('organisationCredentials', [
      'deleteOrganisationCredential',
      'fetchGlobalIntegrations',
      'fetchMissingPinShops',
      'fetchOrganisationCredentials',
      'fetchTotalShopsCount',
    ]),
    ...mapMutations('organisationCredentials', ['setSilaeMissingPinShops']),
    async fetchUserMissingAttributes() {
      const response = await httpClient.get('/v3/api/users/missing_attributes_for_payroll', {
        params: {
          with_missing_attributes: true,
          cluster_node_id: this.currentOrganisation.rootNodeId,
        },
      });

      this.haveEmployeesMissingAttributes = response.data.has_missing_attributes;
    },
    handleCredentialsFillingStepClick(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'silae-activation-modal');
    },
    async handleSubmit() {
      if (this.silaeMissingPinShops.length > 0) {
        this.isFetchingOrUpdatingData = true;
        this.$skAnalytics.track('fortify_silae_shop_pin_submit', { source: 'payroll_softwares' });

        await this.updateShops();

        if (!this.arePinMissing) {
          await this.fetchUserMissingAttributes();
          this.setSilaeMissingPinShops({ data: [] });
        }

        this.isFetchingOrUpdatingData = false;

        return;
      }

      this.$router.push({
        name: 'users',
        params: { shop_id: 'all' },
        query: { show_incomplete_employees: true },
      });
    },
    async handleSilaeActivation() {
      if (this.isSilaeConnectionPending) {
        this.$refs.payPartnerCard.$refs.collapse.openCollapse();

        return;
      }

      await Promise.all([
        this.fetchTotalShopsCount(),
        this.fetchMissingPinShops(),
      ]);

      if (!this.arePinMissing) {
        this.$skToast({
          variant: 'success',
          message: this.$t('organisation_settings.tabs.pay_partners.enable.organisation.success', { name: 'Silae' }),
        });

        await this.fetchUserMissingAttributes();
        this.setSilaeMissingPinShops({ data: [] });
      }

      this.$refs.payPartnerCard.$refs.collapse.openCollapse();
    },
    async handleSilaeDeactivation() {
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        description: this.$t('organisation_settings.tabs.pay_partners.disable.description', { name: 'Silae' }),
        submitLabel: this.$t('organisation_settings.tabs.pay_partners.disable.submit'),
        title: this.$t('organisation_settings.tabs.pay_partners.disable.title', { name: 'Silae' }),
        onConfirmAsync: async () => {
          try {
            this.$skAnalytics.track('fortify_silae_deactivate');

            await this.deleteOrganisationCredential({ name: 'SILAE' });

            this.$refs.payPartnerCard.$refs.collapse.closeCollapse();
            this.$skToast({ message: this.$t('organisation_settings.tabs.pay_partners.disable.success'), variant: 'success' });
          } catch (error) {
            this.$skToast({ message: this.$t('organisation_settings.tabs.pay_partners.disable.failure'), variant: 'error' });

            throw error;
          }
        },
      });
    },
    async updateShops() {
      await Promise.all(
        this.silaeMissingPinShops.reduce((promises, { id, attributes }) => {
          if (attributes.siret) {
            promises.push(
              httpClient.patch(
                `/v3/api/shops/${id}`,
                { shop_info: { pay_identification_number: attributes.pay_identification_number } },
              ),
            );
          }

          return promises;
        }, []),
      );

      this.setSilaeMissingPinShops({
        data: this.silaeMissingPinShops.filter(
          ({ attributes }) => !attributes.pay_identification_number,
        ),
      });

      if (this.arePinMissing) return;

      this.$skToast({
        message: this.$t('organisation_settings.tabs.pay_partners.fortify.connection.shops'),
        variant: 'success',
      });
    },
    openActivationModal() {
      this.$skAnalytics.track('fortify_silae_start_setup');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'silae-activation-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.my-softwares__list-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 8px;
  padding: 0 16px;

  &__table {
    width: 100%;

    thead {
      border-bottom: 1px solid $sk-grey-30;

      th {
        color: $sk-grey-50;
        padding-bottom: 8px;
      }
    }

    tr:first-child {
      td {
        padding-top: 16px;
      }
    }

    tr:not(:last-child) {
      td {
        padding-bottom: 16px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 16px;
  }

  &__footer-buttons {
    display: flex;
    gap: 12px;
  }

  &--cta {
    align-self: end;
  }
}
</style>
