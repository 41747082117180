<template>
  <div>
    <div
      v-if="structureRules.length > 0"
      class="verification__section"
    >
      <div class="verification__section__title">
        {{ $t('optimization.sidebar.structure_rules') }}
      </div>
      <div
        v-if="structureRulesAnomaliesCount > 0"
        class="verification__anomaly-count__wrapper"
      >
        <div class="anomaly-count__icon-container">
          <ExclamationMarkIcon
            class="anomaly-count__icon"
            fill="#d03e50"
            height="24px"
            width="24px"
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="verification__anomaly-count__text"
          v-html="$t(
            'optimization.sidebar.anomalies_count.structure_rules',
            { anomaliesCount: structureRulesAnomaliesCount }
          )"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <div class="verification__detail__section">
        <SkCollapse
          v-for="rule in structureRules"
          :key="rule.key"
          :disabled="getAnomaliesCountForRule(rule) === 0"
        >
          <template slot="labelLeft">
            <div class="verification__detail__label">
              <div :class="getRuleIconClasses(rule)">
                <CheckMarkIcon
                  v-if="getAnomaliesCountForRule(rule) === 0"
                  fill="#188377"
                  width="16px"
                  height="12px"
                />
                <span
                  v-else
                  class="label__icon__number"
                >
                  {{ getAnomaliesCountForRule(rule) }}
                </span>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="verification__detail__label__text"
                v-html="getLabelForStructureRule(rule)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </template>

          <template
            v-if="getAnomaliesCountForRule(rule) > 0"
            slot="content"
          >
            <div
              v-if="!rule.isBehaviorSpecific"
              class="verification__detail__anomalies-wrapper"
            >
              <div
                v-for="(anomaly, index) in optiStatus[rule.statusKey]"
                :key="`${rule.statusKey}-anomaly-${index}`"
                class="verification__detail__anomaly"
              >
                {{ anomaly }}
              </div>
            </div>

            <div
              v-else
              class="
                verification__detail__anomalies-wrapper
                verification__detail__anomalies-wrapper--postes
              "
            >
              <SkCollapse
                v-for="poste in optiStatus.poste_structure.postes"
                :key="poste.id"
                :ref="`poste_collapse_${poste.id}`"
                :disabled="getPostesAnomaliesCount(poste.id) === 0"
                @open="handleOpenPosteCollapse(poste.id)"
                @close="handleClosePosteCollapse(poste.id)"
              >
                <template slot="labelLeft">
                  <div class="verification__detail__label">
                    <div :class="getPosteIconClasses(poste.id)">
                      <CheckMarkIcon
                        v-if="getPostesAnomaliesCount(poste.id) === 0"
                        fill="#188377"
                        width="16px"
                        height="12px"
                      />
                      <span
                        v-else
                        class="label__icon__number"
                      >
                        {{ getPostesAnomaliesCount(poste.id) }}
                      </span>
                    </div>
                    <div class="verification__detail__label__text">
                      {{ poste.name }}
                    </div>
                  </div>
                </template>

                <template
                  v-if="getPostesAnomaliesCount(poste.id) > 0"
                  slot="content"
                >
                  <div class="verification__detail__poste-table__container">
                    <PosteTable
                      :poste-id="parseInt(poste.id, 10)"
                      :poste-structure="getPosteStructure(poste.id)"
                      is-verification-panel
                    />
                  </div>
                </template>
              </SkCollapse>
            </div>
          </template>
        </SkCollapse>
      </div>
    </div>

    <div
      v-if="isOptimisationRulesDisplayed"
      class="verification__section"
    >
      <div class="verification__section__title">
        {{ $t('optimization.sidebar.performance_rules') }}
      </div>
      <div
        v-if="performanceRulesAnomaliesCount > 0"
        class="verification__anomaly-count__wrapper"
      >
        <div class="anomaly-count__icon-container">
          <ExclamationMarkIcon
            class="anomaly-count__icon"
            fill="#d03e50"
            height="24px"
            width="24px"
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="verification__anomaly-count__text"
          v-html="$t(
            'optimization.sidebar.anomalies_count.performance_rules',
            { anomaliesCount: performanceRulesAnomaliesCount }
          )"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <div class="verification__detail__section">
        <div
          v-if="optiStatus.revenue"
          class="verification__detail__line"
        >
          <div :class="getRuleIconClasses(optimisationRules[0])">
            <CheckMarkIcon
              v-if="revenueAnomaliesCount === 0"
              fill="#188377"
              width="16px"
              height="12px"
            />
            <span
              v-else
              class="line__icon__number"
            >
              {{ revenueAnomaliesCount }}
            </span>
          </div>
          <div class="verification__detail__line__label">
            {{ optiStatus.revenue.message }}
          </div>
          <div class="verification__detail__line__result">
            <PerformanceArrow
              :arrow="revenuesPercentValue > 100 ? 'positive' : 'negative'"
              :performance="revenuesPercentValue > 100 ? 'positive' : 'negative'"
              :secondary_color="false"
              :size="14"
            />
            <span :class="getResultValueClasses(revenuesPercentValue)">
              {{ revenuesPercentValue }}%
            </span>
          </div>
        </div>

        <SkCollapse
          v-for="rule in collapseOptimisationRules"
          :key="rule.key"
          :disabled="getAnomaliesCountForRule(rule) === 0"
        >
          <template slot="labelLeft">
            <div class="verification__detail__label">
              <div :class="getRuleIconClasses(rule)">
                <CheckMarkIcon
                  v-if="getAnomaliesCountForRule(rule) === 0"
                  fill="#188377"
                  width="16px"
                  height="12px"
                />
                <span
                  v-else
                  class="label__icon__number"
                >
                  {{ getAnomaliesCountForRule(rule) }}
                </span>
              </div>
              <div class="verification__detail__label__text">
                {{ getLabelForOptimisationRule(rule) }}
              </div>
            </div>
          </template>

          <template
            v-if="getAnomaliesCountForRule(rule) > 0"
            slot="content"
          >
            <div
              v-if="rule.key === 'max_salary_mass_ratio'"
              class="
                verification__detail__anomalies-wrapper
                verification__detail__anomaly
                verification__detail__anomaly--negative
              "
            >
              {{ optiStatus.max_salary_mass_ratio.warning_sentence }}
            </div>

            <div
              v-if="rule.key === 'available_hours'"
              class="
                verification__detail__anomalies-wrapper
                verification__detail__anomaly__table
              "
            >
              <div>
                <div class="anomaly__table__cell">
                  <div class="anomaly__table__cell__label">
                    {{ $t('optimization.sidebar.available_hours') }}
                  </div>
                  {{ roundHours(optiStatus.available_hours.available_hours) }}h
                </div>
                <div class="anomaly__table__cell">
                  <div class="anomaly__table__cell__label">
                    {{ $t('optimization.sidebar.predicted_hours') }}
                  </div>
                  {{ roundHours(optiStatus.available_hours.predicted_hours) }}h
                </div>
              </div>
              <div class="verification__detail__line__result">
                <PerformanceArrow
                  arrow="negative"
                  performance="negative"
                  :secondary_color="false"
                  :size="14"
                />
                <span :class="getResultValueClasses(availableHoursResultPercent)">
                  {{ availableHoursResultPercent }}%
                </span>
              </div>
            </div>
          </template>
        </SkCollapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { roundFloat } from '@skello-utils/formatting/numbers';

import PosteTable from './PosteTable';

export default {
  name: 'OptimizationVerification',
  components: {
    PosteTable,
  },
  data() {
    return {
      allStructureRules: [
        {
          key: 'uses_availabilities',
          statusKey: 'availabilities',
        },
        {
          key: 'uses_unavailabilities',
          statusKey: 'unavailabilities',
        },
        {
          key: 'uses_daily_rest',
          statusKey: 'daily_rest',
        },
        {
          key: 'uses_over_hours',
          statusKey: 'over_hours',
        },
        {
          key: 'uses_max_daily_hours',
          statusKey: 'max_daily_hours',
        },
        {
          key: 'uses_max_weekly_hours',
          statusKey: 'max_weekly_hours',
        },
        {
          key: 'uses_maximum_daily_amplitude',
          statusKey: 'maximum_daily_amplitude',
        },
        {
          key: 'uses_minimum_daily_hours',
          statusKey: 'minimum_daily_hours',
        },
        {
          key: 'uses_structure',
          statusKey: 'uses_structure',
          isBehaviorSpecific: true,
          anomaliesCountComputed: 'usesStructureAnomaliesCount',
        },
      ],
      allOptimisationRules: [
        {
          key: 'revenue',
          anomaliesCountComputed: 'revenueAnomaliesCount',
        },
        {
          key: 'max_salary_mass_ratio',
          anomaliesCountComputed: 'salaryMassRatioAnomaliesCount',
          isCollapseComponent: true,
        },
        {
          key: 'available_hours',
          anomaliesCountComputed: 'availableHoursAnomaliesCount',
          isCollapseComponent: true,
        },
      ],
      openedPosteCollapseId: null,
    };
  },
  computed: {
    ...mapState('planningsOptimisation', ['optiStructure', 'optiStatus']),
    ...mapState('convention', ['convention']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    structureRules() {
      return this.allStructureRules.filter(rule => this.optiStatus[rule.statusKey]);
    },
    optimisationRules() {
      return this.allOptimisationRules.filter(rule => this.optiStatus[rule.key]);
    },
    isOptimisationRulesDisplayed() {
      return this.currentOrganisation.attributes.packOffer.name === 'premium' &&
        this.optimisationRules.length > 0;
    },
    structureRulesAnomaliesCount() {
      return this.getTotalAnomaliesCountForRules(this.structureRules);
    },
    performanceRulesAnomaliesCount() {
      return this.getTotalAnomaliesCountForRules(this.optimisationRules);
    },
    revenuesPercentValue() {
      return this.optiStatus.revenue.percent * 100;
    },
    collapseOptimisationRules() {
      return this.optimisationRules.filter(rule => rule.isCollapseComponent);
    },
    ruleLabelParams() {
      const params = { ...this.convention.attributes };

      if (this.optiStatus.max_salary_mass_ratio) {
        params.ratioValue = this.optiStatus.max_salary_mass_ratio.max_salary_mass_ratio;
      }

      return params;
    },
    availableHoursResultPercent() {
      return Math.round(this.optiStatus.available_hours.percent * 100);
    },
    // The following computeds are called in the getAnomaliesCountForRule
    // by doing this[rule.anomaliesCountComputed]
    usesStructureAnomaliesCount() {
      if (!this.optiStatus.uses_structure) return 0;

      return this.optiStatus.uses_structure.poste_errors;
    },
    revenueAnomaliesCount() {
      return this.optiStatus.revenue && this.optiStatus.revenue.message ? 1 : 0;
    },
    salaryMassRatioAnomaliesCount() {
      return this.optiStatus.max_salary_mass_ratio &&
        this.optiStatus.max_salary_mass_ratio.has_error ?
        1 : 0;
    },
    availableHoursAnomaliesCount() {
      return this.optiStatus.available_hours &&
        this.optiStatus.available_hours.percent ?
        1 : 0;
    },
  },
  methods: {
    getAnomaliesCountForRule(rule) {
      // No specified anomaliesCount method -> standard calculation method
      if (!rule.anomaliesCountComputed) {
        return this.optiStatus[rule.statusKey].length;
      }

      return this[rule.anomaliesCountComputed];
    },
    getTotalAnomaliesCountForRules(rules) {
      return rules.reduce((accumulator, rule) => (
        accumulator + this.getAnomaliesCountForRule(rule)
      ), 0);
    },
    getRuleIconClasses(rule) {
      return {
        verification__detail__label__icon: true,
        'verification__detail__label__icon--no-anomalies':
          this.getAnomaliesCountForRule(rule) === 0,
      };
    },
    getPosteIconClasses(posteId) {
      return {
        verification__detail__label__icon: true,
        'verification__detail__label__icon--no-anomalies':
          this.getPostesAnomaliesCount(posteId) === 0,
      };
    },
    getResultValueClasses(percentValue) {
      return {
        detail__line__result__value: true,
        'detail__line__result__value--has-anomaly': percentValue !== 100,
      };
    },
    getLabelForStructureRule(rule) {
      const ruleType = !rule.isBehaviorSpecific ?
        'structure_rule_labels' :
        'specific_verification_labels';
      return this.$t(
        `optimization.sidebar.${ruleType}.${rule.key}`,
        this.ruleLabelParams,
      );
    },
    getLabelForOptimisationRule(rule) {
      return this.$t(
        `optimization.sidebar.performance_rule_labels.${rule.key}`,
        this.ruleLabelParams,
      );
    },
    getPostesAnomaliesCount(posteId) {
      return this.optiStatus.poste_structure.number_of_errors[posteId].errors_per_poste;
    },
    getPosteStructure(posteId) {
      return this.optiStatus.poste_structure.opti_poste_structure[posteId];
    },
    roundHours(hours) {
      return roundFloat(hours);
    },
    closePosteCollapse() {
      if (this.openedPosteCollapseId) {
        this.$refs[`poste_collapse_${this.openedPosteCollapseId}`][0].closeCollapse();
        this.openedPosteCollapseId = null;
      }
    },
    handleOpenPosteCollapse(posteId) {
      this.closePosteCollapse();
      this.openedPosteCollapseId = posteId;
    },
    handleClosePosteCollapse(posteId) {
      if (this.openedPosteCollapseId !== posteId) return;

      this.openedPosteCollapseId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Remove side paddings from collapse elements */
::v-deep .sk-collapse__label {
  padding: 13px 0 !important;
}

.verification__section {
  margin-bottom: 16px;
}

.verification__section__title {
  margin: 0 0 20px;
  font-size: $fs-text-m;
  color: $sk-black;
  font-weight: $fw-semi-bold;
  text-align: start;
}

.verification__anomaly-count__wrapper {
  padding: 19px;
  border: 1px solid $sk-grey-10;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.anomaly-count__icon-container {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 3px solid $sk-error;
  border-radius: 50%;
  margin-right: 19px;
}

.anomaly-count__icon {
  flex-shrink: 0;
}

.verification__detail__label {
  display: flex;
  align-items: center;
}

.verification__detail__label__icon {
  width: 30px;
  height: 30px;
  margin-right: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: $sk-error-10;

  &--no-anomalies {
    background-color: $sk-success-10;
  }
}

.verification__detail__line__result {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  flex-grow: 2;
  padding-right: 23px;
}

.detail__line__result__value {
  margin-left: 7px;
  color: $sk-success;

  &--has-anomaly {
    color: $sk-error;
  }
}

.label__icon__number,
.line__icon__number {
  color: $sk-error;
}

.verification__detail__label__text,
.verification__detail__line__label {
  padding-right: 30px;
}

.verification__detail__line {
  display: flex;
  align-items: center;
  padding: 13px 0;
}

.verification__detail__anomalies-wrapper {
  padding-left: 50px;

  &--postes {
    padding-left: 30px;
  }
}

.verification__detail__anomaly {
  padding-right: 30px;
  color: $sk-grey;
  margin-bottom: 16px;

  &--negative {
    color: $sk-error;
  }
}

.verification__detail__poste-table__container {
  width: 332px;
  padding-bottom: 20px;
}

.verification__detail__anomaly__table {
  display: flex;
}

.anomaly__table__cell {
  padding: 13px 0 7px;
}

.anomaly__table__cell__label {
  color: $sk-grey;
}
</style>
