<template>
  <FiltersPopover
    class="requests-filter-popover"
    :are-filters-active="areFiltersActive"
    :data-sources="filtersPopOverDataSources"
    :initial-selected-items="initialFilterSelections"
    :loading="areFiltersLoading"
    :source="source"
    :submit-handler="handleSubmit"
  />
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import GenericFilterHandler from '@app-js/shared/utils/generic_filter_handler';
import { paramAsArray } from '@app-js/shared/utils/filters/helpers/formatting_helpers';

import RoutesMixins from '@app-js/requests/mixins/RoutesMixins';
import FiltersPopover from '@app-js/shared/components/FiltersPopover/FiltersPopover';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'RequestsFilterPopover',
  components: { FiltersPopover },
  mixins: [RoutesMixins],
  data() {
    return {
      currentFilters: {},
      initialFilterSelections: {},
      filterHandler: null,
    };
  },
  computed: {
    ...mapState('requests', [
      'absencesByShopId',
      'managedEmployees',
      'pagination',
      'shopTeams',
      'sort',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('requests', [
      'areFiltersLoading',
      'currentNodeShopsWithEmployees',
      'tableFilters',
      'areFiltersActive',
      'allUniqueAbsences',
      'absenceIdsForKey',
      'absenceKeysForIds',
    ]),
    absences() {
      if (this.isAllRequestsView || !this.absencesByShopId[this.currentShop.id]) {
        return [];
      }

      return this.absencesByShopId[this.currentShop.id]
        .filter(({ attributes: { active, absenceFlags } }) => (
          active && absenceFlags.includes('leave_request_absences')
        ))
        .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
    },
    managedEmployeesSorted() {
      return this.managedEmployees
        .slice()
        .sort((a, b) => a.attributes.firstName.localeCompare(b.attributes.firstName));
    },
    currentNodeShopsSorted() {
      return this.currentNodeShopsWithEmployees
        .slice()
        .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
    },
    localStorageKey() {
      if (this.isLeaveRequestsView) {
        if (this.isAllRequestsView) {
          return this.isOwnRequestsView ? 'leave_requests_own_all' : 'leave_requests_teams_all';
        }
        return this.isOwnRequestsView ? 'leave_requests_own' : 'leave_requests_teams';
      }
      if (this.isAvailabilityRequestsView) {
        return this.isOwnRequestsView ? 'availability_requests_own' : 'availability_requests_teams';
      }
      return '';
    },
    storageFilterKeys() {
      return this.isOwnRequestsView ?
        ['status', 'absenceTypeId', 'availabilityType', 'shops'] :
        ['status', 'employeeId', 'teams', 'absenceTypeId', 'availabilityType', 'shops'];
    },
    filtersPopOverDataSources() {
      const STATUSES = [
        'pending',
        'accepted',
        'refused',
      ];
      const AVAILABILITY_TYPES = [
        'available',
        'unavailable',
      ];
      const dataSources = {};

      dataSources.statuses = STATUSES;
      if (this.isTeamsRequestsView) {
        dataSources.users = this.managedEmployeesSorted;
        dataSources.teams = this.shopTeams;
      }
      if (!this.isAvailabilityRequestsView) {
        if (this.isAllRequestsView) {
          dataSources.absences = this.allUniqueAbsences;
        } else {
          dataSources.absences = this.absences;
        }
        if (this.isAllRequestsView) dataSources.shops = this.currentNodeShopsSorted;
      } else {
        dataSources.availabilityTypes = AVAILABILITY_TYPES;
      }
      return dataSources;
    },
    source() {
      return this.isLeaveRequestsView ? 'absence' : 'availability';
    },
  },
  async mounted() {
    this.filterHandler = new GenericFilterHandler(
      this.setFilters,
      this.storageFilterKeys,
      this.localStorageKey,
    );
    this.currentFilters = cloneDeep(this.tableFilters);

    const promises = [];

    if (this.isAllRequestsView) {
      promises.push(this.fetchAllAbsences());
    }

    if (!this.isAllRequestsView && !this.absencesByShopId[this.currentShop.id]) {
      promises.push(this.fetchShopAbsences(this.currentShop.id));
    }

    if (this.isTeamsRequestsView && !this.isAllRequestsView) {
      promises.push(this.fetchShopTeams(this.currentShop.id));
    }

    if (promises.length > 0) {
      try {
        await Promise.all(promises);
      } catch (error) {
        this.$skToast({
          message: this.$t('requests.toast.error'),
          variant: 'error',
        });
      }
    }
    this.$router.onReady(() => {
      this.$nextTick(() => {
        this.initializeFilterSelections();
      });
    });
  },
  methods: {
    ...mapActions('requests', [
      'fetchAvailabilityRequests',
      'fetchLeaveRequests',
      'fetchShopAbsences',
      'fetchAllAbsences',
      'fetchShopTeams',
    ]),
    ...mapMutations('requests', ['setFilters', 'setPagination']),
    initializeFilterSelections() {
      /**
      * Sets up initial filter values from URL parameters or defaults.
      * For each filter:
      * 1. First checks if there's a value in the URL
      * 2. If not found in URL, uses the default value
      * 3. Converts the value to an array format
      */
      const filterDefaults = this.currentFilters;
      const query = this.$route.query;

      const absenceTypeId = this.isAllRequestsView ?
        this.absenceKeysForIds(query.absenceTypeId) :
        query.absenceTypeId;

      this.initialFilterSelections = {
        status: paramAsArray(
          query.status ?? filterDefaults.status,
        ),
        user_ids: paramAsArray(
          query.employeeId ?? filterDefaults.employeeId,
        ),
        team_ids: paramAsArray(
          query.teams ?? filterDefaults.teams,
        ),
        absence_ids: paramAsArray(
          absenceTypeId ?? filterDefaults.absenceTypeId,
        ),
        availability_type: paramAsArray(
          query.availabilityType ?? filterDefaults.availabilityType,
        ),
        shop_ids: this.isAllRequestsView ? paramAsArray(
          query.shops ?? filterDefaults.shops,
        ) : undefined,
      };
    },
    updateInitialFilterSelections(newFilters) {
      this.initialFilterSelections = {
        ...this.initialFilterSelections,
        ...newFilters,
      };
    },
    async handleSubmit({
      status = [],
      team_ids: teams = [],
      user_ids: employeeId = [],
      absence_ids: absenceTypeId = [],
      availability_type: availabilityType = [],
      shop_ids: shops = [],
    } = {}) {
      const handleRequest = async fetchFunction => {
        if (this.isAllRequestsView) {
          absenceTypeId = absenceTypeId.map(key => this.absenceIdsForKey(key)).flat();
        }

        this.currentFilters = {
          status,
          teams,
          employeeId,
          absenceTypeId,
          availabilityType,
          shops,
        };
        const shopIds = this.isAllRequestsView ?
          this.currentNodeShopsWithEmployees.map(shop => shop.id) :
          [this.currentShop.id];
        try {
          await fetchFunction({
            isOwnRequestsView: this.isOwnRequestsView,
            shopIds,
            userId: this.currentUser.id,
            pagination: {
              current_page: 1,
              per_page: this.pagination.per_page,
              total_page: this.pagination.total_page,
            },
            sort: this.sort,
            filters: this.currentFilters,
            isAllRequestsView: this.isAllRequestsView,
          });
          this.$router.push({ query: { page: 1 } });
          this.filterHandler.updateFilters(this.currentFilters, this.$router);
        } catch (error) {
          this.$skToast({
            message: this.$t('requests.toast.error'),
            variant: 'error',
          });
          throw error;
        }
        this.updateInitialFilterSelections(this.currentFilters);
      };

      if (this.isLeaveRequestsView) {
        if (!this.isNewFiltersEnabled) this.$skAnalytics.track('submit_leave_request_filters', { source: 'absence_space' });
        await handleRequest(this.fetchLeaveRequests);
      }

      if (this.isAvailabilityRequestsView) {
        await handleRequest(this.fetchAvailabilityRequests);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.requests-filter-popover {
  z-index: 9; /* new and manage leave request are z-index 10 */
}
</style>
