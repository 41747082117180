<template>
  <SkModalSection border-bottom="none">
    <h3>{{ $t('employees.add_employee_modal.affiliations.cluster_title') }}</h3>
    <div class="sk-subtitle--medium add-employee-modal__affiliation__subtitle">
      {{ $t('employees.add_employee_modal.affiliations.cluster_subtitle') }}
    </div>
    <div
      v-if="isCurrentUserSystemAdmin"
      class="system-admin__card"
    >
      <div class="system-admin__card__icon">
        <CircledIIcon
          fill="#2b66fe"
          width="24"
          height="24"
        />
      </div>
      <div class="system-admin__card__explanation">
        {{ $t('employees.add_employee_modal.affiliations.clusters.system_admin_explanation') }}
      </div>
    </div>
    <ClusterAffiliationSelect
      ref="clusterNodeSelect"
      v-model="localActiveNodes"
      :primary-node="primaryClusterNode"
      :label="$t('employees.add_employee_modal.affiliations.cluster_employee_assignment')"
      :user="newEmployee"
      append-to-body
      @new-primary-node-selected="setNewPrimaryNodeId"
    />
    <SkTable
      v-if="clusterNodeSelected"
      :columns="clusterNodeHeader"
      class="add-employee-modal__affiliation-table"
    >
      <NodeRow
        v-for="node in activeClusterNodes"
        :key="node.id"
        :node="node"
        :user="newEmployee"
        :license-on-cluster-id="licenseOnClusterId"
        :show-organisation-row="showOrganisationRow"
        :shops-count="shopsCount(node)"
        type="cluster"
        append-to-body
        @delete-row="handleDelete"
      />
    </SkTable>
    <SkTable
      v-if="shopNodeSelected"
      :columns="nodeHeader"
      :class="headerTableDisplayClasses"
    >
      <NodeRow
        v-for="node in activeShopNodes"
        :key="node.id"
        :node="node"
        :user="newEmployee"
        type="shop"
        append-to-body
        @delete-row="handleDelete"
      />
    </SkTable>
  </SkModalSection>
</template>

<script>
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import {
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';

import ClusterAffiliationSelect from '@app-js/shared/components/ClusterAffiliationSelect';
import NodeRow from '../NodeRow';

export default {
  name: 'ClusterAffiliationsStep',
  components: { NodeRow, ClusterAffiliationSelect },
  data() {
    return {
      primaryClusterNode: null,
      allClustersSelected: false,
      clusterNodeHeader: [
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.network') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.license') },
        { title: this.$t('employees.tabs.personal_data.cluster_assignment.header.in_plannings') },
        { title: null },
      ],
    };
  },
  computed: {
    ...mapState('newEmployee', {
      userLicenses: state => state.newEmployee.relationships.userLicenses,
    }),
    ...mapState('currentUser', ['organisationLicenses']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('newEmployee', ['newEmployee', 'activeNodes', 'licenseOnClusterId']),
    ...mapGetters('newEmployee', ['primaryLicense', 'isSystemAdmin']),
    ...mapGetters({ isCurrentUserSystemAdmin: 'currentLicense/isSystemAdmin' }),
    nodeHeader() {
      const columns = [
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.shop') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.license') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.in_planning') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.teams') },
      ];

      if (this.isAutomaticPlanningEnabled) {
        columns.push(
          {
            title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.title'),
            icon: {
              icon: 'CircledQuestionMarkIcon',
              tooltip: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.tooltip'),
            },
          },
        );
      }

      columns.push({ title: null }); // Closing x column

      return columns;
    },
    isAutomaticPlanningEnabled() {
      return !!this.activeNodes.find(node => node.attributes.canAccessAutomaticPlanning);
    },
    headerTableDisplayClasses() {
      return {
        'add-employee-modal__affiliation-table': !this.isAutomaticPlanningEnabled,
        'add-employee-modal__affiliation-table--small': this.isAutomaticPlanningEnabled,
      };
    },
    showOrganisationRow() {
      return this.isCurrentUserSystemAdmin && this.allClustersSelected;
    },
    activeShopNodes() {
      return this.activeNodes.filter(node => node.attributes.shopId !== null);
    },
    activeClusterNodes() {
      const nodes = this.activeNodes.filter(node => node.attributes.shopId === null);

      return sortBy(nodes, node => (node.id === this.licenseOnClusterId ? 0 : 1));
    },
    clusterNodeSelected() {
      return this.activeClusterNodes.length > 0;
    },
    shopNodeSelected() {
      return this.activeShopNodes.length > 0;
    },
    disabledNodeIds() {
      const userLicense = this.newEmployee.relationships.userLicenses.find(ul => (
        ul.attributes.shopId === String(this.newEmployee.attributes.shopId)
      ));

      if (userLicense.length < 1) return [];

      return [userLicense.attributes.clusterNodeId];
    },

    localActiveNodes: {
      get() {
        return this.activeNodes.map(activeNode => ({
          id: activeNode.id,
          text: activeNode.attributes.name,
          attributes: {
            name: activeNode.attributes.name,
            shopId: activeNode.attributes.shopId ? String(activeNode.attributes.shopId) : null,
            parentId: String(activeNode.attributes.parentId),
            depth: activeNode.attributes.depth,
            ancestry: activeNode.attributes.ancestry,
            ancestorIds: activeNode.attributes.ancestorIds,
            descendantIds: activeNode.attributes.descendantIds,
            shopsCount: activeNode.attributes.shopsCount,
            gotHigherLicenseOnPath: activeNode.attributes.gotHigherLicenseOnPath,
            currentLicensePositionOnNode: activeNode.attributes.currentLicensePositionOnNode,
          },
        }));
      },

      set(options) {
        let nodes = options.values.map(node => ({
          id: node.id,
          attributes: {
            shopId: node.attributes.shopId,
            name: node.attributes.name,
            parentId: String(node.attributes.parentId),
            depth: node.attributes.depth,
            ancestry: node.attributes.ancestry,
            descendantIds: node.attributes.descendantIds,
            ancestorIds: node.attributes.ancestorIds,
            shopsCount: this.$refs.clusterNodeSelect.getShopsCount(node),
            gotHigherLicenseOnPath: node.attributes.gotHigherLicenseOnPath,
            currentLicensePositionOnNode: node.attributes.currentLicensePositionOnNode,
          },
        }));

        const lowestLicense =
          orderBy(this.organisationLicenses, 'attributes.position').slice(-1)[0];

        this.allClustersSelected = options.allChecked;

        if (this.showOrganisationRow) {
          nodes = [{
            id: String(this.currentOrganisation.attributes.rootNodeId),
            type: 'organisationNode',
            attributes: {
              name: this.currentOrganisation.attributes.name,
              shopId: null,
              shopsCount: this.$refs.clusterNodeSelect.totalShopsCount,
              descendantIds: options.values.flatMap(node => node.attributes.descendantIds),
            },
          }];
        }

        this.addActiveNodes({ nodes, lowestLicense });
        this.removeActiveNodes(nodes);
      },
    },
  },
  mounted() {
    this.primaryClusterNode = this.activeNodes.find(activeNode => (
      activeNode.attributes.shopId === String(this.newEmployee.attributes.shopId)
    ));
  },
  methods: {
    ...mapMutations('newEmployee', ['addActiveNodes', 'removeActiveNodes', 'updateLicenseOnClusterNodeId']),
    setNewPrimaryNodeId(id) {
      this.updateLicenseOnClusterNodeId(id);
    },
    shopsCount(node) {
      // if root node selected
      if (this.showOrganisationRow) {
        return this.currentOrganisation.attributes.shopsCount;
      }

      return node.attributes.shopsCount;
    },
    handleDelete(nodeToDelete) {
      const nodes = this.activeNodes.filter(node => (
        node.id !== nodeToDelete.id
      ));

      this.removeActiveNodes(nodes);
    },
  },
};
</script>
<style lang="scss">
.system-admin__card {
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
  font-size: $fs-text-m;
  color: $sk-grey;
  display: flex;
  justify-content: flex-start;
  padding: 10px 70px 10px 10px;
}

.system-admin__card__icon {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 20px;
}

.add-employee-modal__affiliation__subtitle {
  width: 550px;
  margin-bottom: 16px;
}

.add-employee-modal__affiliation-table,
.add-employee-modal__affiliation-table--small {
  margin-top: 20px;

  th {
    padding: 0 !important;
  }

  .table-column__label {
    line-height: 1.2em;
    padding-bottom: 4px;
  }
}

.add-employee-modal__affiliation-table {
  th:nth-child(4) {
    padding-left: 30px !important;
  }
}

.shops-list__item {
  display: flex;
  position: relative;
}

.shops-list__item-icon {
  margin-right: 15px;
}

.shops-list__item-thumbtack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $sk-white;
  background-color: $sk-blue-5;
}

.shops-list__item-thumbtack__fill {
  fill: $sk-blue;
}
</style>
