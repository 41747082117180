<template>
  <SkModal
    id="holidays-settings-modal"
    ref="holidaysSettingsModal"
    :modal-title="holidaysCounterModalTitle"
    :steps="2"
    @cancel="handleCancel"
    @close="handleCancel"
    @update-step="setCurrentStep"
    @show="handleShow"
  >
    <template #body>
      <div
        v-if="loading"
        class="holidays-settings-modal__spinner"
      >
        <SkLoader />
      </div>
      <div v-else>
        <div class="holidays-settings-modal__progress-bar">
          <SkProgressBar
            :max-step="2"
            :current-step="localStep"
            :step-labels="progessBarLabels"
          />
        </div>
        <div v-if="localStep === 1">
          <GuaranteedDaysStep
            :holidays-settings="sortedHolidaySettings"
            :year="year"
            :is-past-year="isPastYear"
            @update-holiday-settings="updateHolidaySettings"
            @add-holiday-settings="addHolidaySettings"
            @remove-holiday-settings="removeHolidaySettings"
          />
        </div>
        <div v-if="isSecondStep">
          <PaidDaysStep
            :holidays-settings="sortedHolidaySettings"
            :year="year"
            @update-holiday-settings="updateHolidaySettings"
          />
        </div>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-if="isSecondStep"
        :loading="loadingSubmit"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import {
  mapMutations,
  mapState,
} from 'vuex';
import { captureException } from '@sentry/vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';

import GuaranteedDaysStep from './GuaranteedDaysStep';
import PaidDaysStep from './PaidDaysStep';

import { HolidaysService } from '../services/HolidaysService';

export default {
  name: 'HolidaySettingsModal',
  components: { PaidDaysStep, GuaranteedDaysStep },
  props: {
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localStep: null,
      loadingSubmit: false,
      holidaysSettings: [],
      originalHolidaysSettings: [],
      holidaysSettingsToDelete: [],
      loading: true,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),

    currentYear() {
      return skDate().year();
    },
    isSecondStep() {
      return this.localStep === 2;
    },
    holidaysCounterModalTitle() {
      // eslint-disable-next-line max-len
      return this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.content_section.title', {
        year: this.year,
      });
    },
    progessBarLabels() {
      return [
        this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.title'),
        this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.title'),
      ];
    },
    sortedHolidaySettings() {
      return [...this.holidaysSettings].sort(
        (holiday1, holiday2) => skDate(holiday1.attributes.date) - skDate(holiday2.attributes.date),
      );
    },
    isPastYear() {
      return this.year < this.currentYear;
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    setCurrentStep(currentStep) {
      this.localStep = currentStep;
      // SkModal doesn't update his data step if we use this fonction
      this.$refs.holidaysSettingsModal.currentStep = currentStep;
    },
    hideModal() {
      this.$refs.holidaysSettingsModal.hide();

      Object.assign(this.$data, this.$options.data());
    },
    handleCancel(event) {
      const { holidaysCounterConfigured } = this.currentShop.attributes;
      if (JSON.stringify(this.originalHolidaysSettings) !== JSON.stringify(this.holidaysSettings)) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: holidaysCounterConfigured ? this.hideModal : this.trackAndHideModal,
        });
      } else if (!holidaysCounterConfigured) {
        this.$skAnalytics.track('canceled_public_holidays_counter_settings');
      }
    },
    trackAndHideModal() {
      this.$skAnalytics.track('canceled_public_holidays_counter_settings');
      this.hideModal();
    },
    getSubmitActions() {
      const holidaysToCreate = [];
      const holidaysToUpdate = [];

      for (const holidaySetting of this.holidaysSettings) {
        if (holidaySetting.tmpId) {
          delete holidaySetting.id;
          delete holidaySetting.tmpId;
          holidaysToCreate.push(holidaySetting);
        } else {
          holidaysToUpdate.push(holidaySetting);
        }
      }

      return {
        holidaysToCreate,
        holidaysToUpdate,
        holidaysToDelete: this.holidaysSettingsToDelete,
      };
    },
    handleSubmit(event, validate = true) {
      if (validate && this.currentShop.attributes.holidaysCounterConfigured) {
        const translationSuffix = this.isPastYear ? 'past' : 'present';
        const submitLabel = this.isPastYear ? this.$t('actions.modify') : this.$t('actions.submit');
        const actionText = this.isPastYear ? undefined : this.$t('actions.continue');

        this.$root.$emit('confirm', event, {
          title: this.$t(
            `shop_settings.tabs.rules.counter_rules.holidays_counter.confirm_title.${translationSuffix}`,
            { year: this.year },
          ),
          description: this.$t(
            `shop_settings.tabs.rules.counter_rules.holidays_counter.confirm_description.${translationSuffix}`,
            { year: this.year },
          ),
          actionText,
          submitLabel,
          onCancel: this.hideModal,
          onConfirm: () => {
            this.$skAnalytics.track('updated_public_holidays_counter_settings');
            this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_BANK_HOLIDAYS_TRACKER_ENABLED);
            this.handleSubmit(event, false);
          },
        });

        return;
      }
      if (validate) {
        this.$skAnalytics.track('validated_public_holidays_counter_settings');
      }

      this.loadingSubmit = true;

      const holidayService = new HolidaysService(httpClient);

      const { holidaysToCreate, holidaysToUpdate, holidaysToDelete } = this.getSubmitActions();
      const isFirstSettings = !this.currentShop.attributes.holidaysCounterConfigured;

      const updateHolidayPromises = [
        !!holidaysToCreate.length &&
          holidayService.bulkCreate(this.currentShop.id, holidaysToCreate),
        !!holidaysToUpdate.length &&
          holidayService.bulkUpdate(this.currentShop.id, holidaysToUpdate, this.year),
        !!holidaysToDelete.length &&
          holidayService.bulkDelete(this.currentShop.id, holidaysToDelete),
      ].filter(Boolean);

      Promise.all(updateHolidayPromises)
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_BANK_HOLIDAYS_TRACKER_ENABLED);

          this.setShopAttributes({
            holidaysCounterConfigured: true,
            holidaysCounterActivationYear: isFirstSettings ?
              this.currentYear : this.currentShop.attributes.holidaysCounterActivationYear,
          });

          this.hideModal();

          this.$emit('update-success');

          this.$skToast({
            message: isFirstSettings ?
              // eslint-disable-next-line max-len
              this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_init_success_message') :
              this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.success_message'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        })
        .finally(() => { this.loadingSubmit = false; });
    },
    handleShow() {
      this.loading = true;

      httpClient
        .get(`/v3/api/shops/${this.currentShop.id}/holiday_settings?year=${this.year}`)
        .then(response => {
          this.holidaysSettings = response.data.data.sort(
            (a, b) => skDate(a.attributes.date) - skDate(b.attributes.date),
          );
          this.originalHolidaysSettings = cloneDeep(this.holidaysSettings);
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        })
        .finally(() => { this.loading = false; });
    },
    blankHoliday(date, name, custom) {
      return {
        id: `${date}:${name}`, // temporary id for v-for
        type: 'shopHolidaySetting',
        tmpId: true,
        attributes: {
          name,
          guaranteed: false,
          paid: false,
          shopId: parseInt(this.currentShop.id, 10),
          date,
          custom,
        },
      };
    },
    addHolidaySettings({ date, name, custom }) {
      this.holidaysSettings.push(
        this.blankHoliday(date, name, custom),
      );
    },
    updateHolidaySettings({ id, attribute, value }) {
      const holidaySettingToUpdate =
        this.holidaysSettings.find(holidaySetting => holidaySetting.id === id);

      holidaySettingToUpdate.attributes[attribute] = value;
    },
    removeHolidaySettings(holidaySettingId) {
      const holidayToDeleteIndex = this.holidaysSettings.findIndex(
        holiday => holiday.id === holidaySettingId,
      );

      if (this.holidaysSettings[holidayToDeleteIndex].tmpId === undefined) {
        // Delete from backend if the holiday is already submited
        this.holidaysSettingsToDelete.push(this.holidaysSettings[holidayToDeleteIndex].id);
      }
      this.holidaysSettings.splice(holidayToDeleteIndex, 1);
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// same width of modals on page
::v-deep .sk-modal__dialog {
  width: 620px;
}

.holidays-settings-modal__progress-bar {
  margin-top: 42px;
  padding: 0 64px;
}

.holidays-settings-modal__spinner {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $sk-blue;
}
</style>
