<template>
  <tr
    v-if="showContractRow"
    class="contracts-row__wrapper"
  >
    <!-- Icon + Contract type -->
    <td class="contracts-column__wrapper">
      <span
        :class="contractTitleClasses"
        @click.stop="showFullContractInfos"
      >
        <ContractIcon />
        <div class="contracts-row__cell-text text-truncate pr-sm-5">
          <div
            :id="`contract-type-${contractTypeName}`"
            v-tooltip.top="showContractTypeTooltip"
            class="contracts-row__cell-text-wrapper"
          >
            {{ contractTypeName }}
          </div>
          <InfoV2Icon
            v-if="isCreatedFromAnotherContract"
            v-tooltip.right="versionTooltip"
            height="16"
            width="16"
            fill="#727272"
            class="contracts-row__cell-icon"
          />
        </div>
      </span>
    </td>

    <!-- Creation date -->
    <td class="contracts-column__wrapper">
      <div class="contracts-row__cell-sortable">
        {{ createdAt }}
      </div>
    </td>

    <!-- Creator name -->
    <td class="contracts-column__wrapper">
      <span
        v-if="contract.relationships.creator"
        class="contracts-row__cell"
      >
        <SkMedallion
          :text="creatorInitials"
          background-color="#2b66fe"
          color="white"
        />
        <div class="contracts-row__cell-text text-truncate">
          {{ creatorName }}
        </div>
      </span>
      <span
        v-else
        class="contracts-row__cell"
      >
        {{ creatorName }}
      </span>
    </td>

    <!-- Contract hours -->
    <td class="contracts-column__wrapper">
      {{ contractCounter }}
    </td>

    <!-- Start / End date-->
    <td class="contracts-column__wrapper">
      <div class="contracts-row__cell-sortable">
        {{ startAndEndDates }}
      </div>
    </td>

    <!-- Dropdown menu -->
    <td
      v-if="canEditContract"
      class="contracts-column__wrapper contracts-row__dropdown"
    >
      <SkDropdown
        v-if="isMulticontractsEnabled"
        ref="contractRowDropdown"
        :prevent-overflow="false"
        trigger="click"
        placement="bottom-start"
        y-offset="5px"
      >
        <template #anchor>
          <SkCircleButton icon="KebabV2Icon" />
        </template>
        <template #menu>
          <div class="contracts-row__dropdown-menu">
            <!-- Edit -->
            <div
              class="contracts-row__dropdown-menu__item"
              @click="displayEditContractMode"
            >
              <PencilIcon class="contracts-row__dropdown-menu__item-icon" />
              <span class="contracts-row__dropdown-menu__item-text">
                {{ $t('employees.tabs.contracts.table.contract_row.dropdown.edit') }}
              </span>
            </div>
            <!-- Duplicate -->
            <div
              v-if="isAllowedDuplicate"
              v-tooltip.right-end="duplicateTooltipText"
              :class="duplicateMenuItemClasses"
              @click="openDuplicateModal"
            >
              <DuplicateContractIcon
                class="contracts-row__dropdown-menu__item-icon custom-size-dupliacte-icon"
              />
              <span class="contracts-row__dropdown-menu__item-text">
                {{ $t('employees.tabs.contracts.table.contract_row.dropdown.duplicate') }}
              </span>
            </div>
            <!-- Apply template -->
            <div
              v-if="isAllowedToApplyTemplate"
              class="contracts-row__dropdown-menu__item"
              @click="openTextDocumentModal"
            >
              <DocFromContractIcon class="contracts-row__dropdown-menu__item-icon" />
              <span class="contracts-row__dropdown-menu__item-text custom-spacing">
                {{ $t('employees.tabs.contracts.table.contract_row.dropdown.apply_template') }}
              </span>
            </div>
            <!-- Delete -->
            <div
              v-if="canEditContract && contractsCount > 1"
              class="contracts-row__dropdown-menu__item"
              @click="handleDelete"
            >
              <TrashCanV2Icon
                width="15"
                height="16"
                fill="black"
                class="contracts-row__dropdown-menu__item-icon
                  contracts-row__dropdown-menu__item-icon-trash"
              />
              <span class="contracts-row__dropdown-menu__item-text">
                {{ $t('employees.tabs.contracts.table.contract_row.dropdown.delete') }}
              </span>
            </div>
          </div>
        </template>
      </SkDropdown>
    </td>
  </tr>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';
import { getContractTypeKeyText } from '@app-js/users/shared/utils/contract_types_helper';
import ContractIcon from '../../svg/ContractIcon';

export default {
  name: 'ContractRow',
  components: { ContractIcon },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEllipsisActive: false,
    };
  },
  computed: {
    ...mapGetters('contracts', ['contractHasBothLimits', 'contractType', 'isInterimContract']),
    ...mapGetters('employees', ['userInitials', 'fullName']),
    ...mapGetters('currentOrganisation', ['isMulticontractsEnabled']),
    ...mapGetters('currentLicense', [
      'canManageEmployeeDocuments',
      'canReadEmployeePersonalInfosAndBankData',
      'canEditEmployeeInfo',
      'canEditEmployeePersonalInfosAndBankData',
    ]),
    ...mapState('config', ['config']),
    ...mapState('contracts', ['contractsCount', 'allContracts']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('config', ['dailyRateCounterType']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrStrictSubordinate']),
    contractTitleClasses() {
      return {
        'contracts-row__cell': true,
        'contracts-row__cell-link': this.isMulticontractsEnabled,
      };
    },
    isCreatedFromAnotherContract() {
      return !!this.contract.attributes.parentId;
    },
    versionTooltip() {
      const { parentId } = this.contract.attributes;
      const parentContract = this.allContracts.find(({ id }) => String(id) === String(parentId));

      const contractTypeName = this.getContractTypeName(parentContract?.attributes?.contractTypeId);
      return this.$t('employees.tabs.contracts.table.contract_row.version_tooltip', { contractTypeName });
    },
    isHistoryTab() {
      return this.$route.name === 'user_contracts_history';
    },
    showContractRow() {
      return this.isMulticontractsEnabled || this.isHistoryTab;
    },
    createdAt() {
      return skDate.utc(this.contract.attributes.createdAt).format('DD MMM YYYY');
    },
    startAndEndDates() {
      return `${this.formattedStartDate} - ${this.formattedEndDate}`;
    },
    formattedStartDate() {
      if (this.contract.attributes.startDate) {
        return skDate.utc(this.contract.attributes.startDate).format('DD MMM YYYY');
      }

      return '';
    },
    formattedEndDate() {
      if (this.contract.attributes.endDate) {
        return skDate.utc(this.contract.attributes.endDate).format('DD MMM YYYY');
      }

      return '';
    },
    contractTypeName() {
      return this.getContractTypeName(this.contract.attributes.contractTypeId);
    },
    creatorInitials() {
      if (!this.contract.relationships.creator) return '-';

      return this.userInitials(this.contract.relationships.creator);
    },
    creatorName() {
      if (!this.contract.relationships.creator) return '-';

      return this.fullName(this.contract.relationships.creator);
    },
    duplicateMenuItemClasses() {
      return {
        'contracts-row__dropdown-menu__item': true,
        'contracts-row__dropdown--disabled': !this.contractHasBothLimits(this.contract),
      };
    },
    duplicateTooltipText() {
      return !this.contractHasBothLimits(this.contract) ?
        this.$t('employees.tabs.contracts.actions.duplicate.tooltips.limits_warning') :
        '';
    },
    isAllowedDuplicate() {
      return this.isMulticontractsEnabled &&
        this.canEditSensitiveData &&
        this.contractHasBothLimits();
    },
    isAllowedToApplyTemplate() {
      return this.canManageDocument && this.canReadSensitiveData;
    },
    canManageDocument() {
      return this.canManageEmployeeDocuments && this.isCurrentUserOrStrictSubordinate;
    },
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },
    canEditSensitiveData() {
      return this.canEditEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    canReadSensitiveData() {
      return this.canReadEmployeePersonalInfosAndBankData && this.isCurrentUserOrStrictSubordinate;
    },
    contractCounter() {
      if (this.isInterim) return '-';

      if (this.contract.attributes.counterType === this.dailyRateCounterType) {
        return this.$tc(
          'employees.tabs.contracts.table.contract_row.contract_days',
          this.contract.attributes.workDays,
          { days: this.contract.attributes.workDays },
        );
      }

      return this.$t(
        'employees.tabs.contracts.table.contract_row.contract_hours',
        { hours: this.contract.attributes.contractHours },
      );
    },
    isInterim() {
      return this.isInterimContract(this.contract);
    },
    showContractTypeTooltip() {
      return this.isEllipsisActive ? this.contractTypeName : null;
    },
  },
  created() {
    this.fetchAllContracts({ employeeId: this.employee.id });
  },
  mounted() {
    // it forces SkDropdown to close when opening a modal
    this.listenOnRoot(MODAL_SHOW_EVENT, this.hideDropdown);
    this.setEllipsisActive();
  },
  methods: {
    ...mapMutations('contracts', ['setSelectedContract']),
    ...mapActions('contracts', ['fetchAllContracts']),
    getContractTypeName(contractTypeId) {
      if (!contractTypeId) return '-';

      const contractType =
        this.config.contract_data.contract_types
          .find(ct => ct.id === contractTypeId);
      const contractTypeCountry = contractType.country;
      const contractTypeKey = contractType.key;

      return getContractTypeKeyText(contractTypeCountry, contractTypeKey);
    },
    openDuplicateModal(event) {
      if (!this.contractHasBothLimits(this.contract)) {
        event.stopPropagation();
        return;
      }

      this.handleTracker('multicontract_duplicate');

      this.setSelectedContract(this.contract);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'duplicate-contract-modal');
    },
    openTextDocumentModal(event) {
      this.handleTracker('multicontract_apply_document_template');
      this.setSelectedContract(this.contract);
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-text-document-modal');
    },
    showFullContractInfos() {
      if (!this.isMulticontractsEnabled) return;

      this.handleTracker('multicontract_view');

      this.$router.push({ name: 'user_full_contract_show', params: { contract_id: this.contract.id } });
    },
    displayEditContractMode() {
      this.handleTracker('multicontract_modify');
      this.$router.push({ name: 'user_full_contract_edit', params: { contract_id: this.contract.id } });
    },
    handleDelete(event) {
      this.hideDropdown();
      this.handleTracker('multicontract_delete');
      this.$emit('delete-contract', this.contract.id, event);
    },
    hideDropdown() {
      this.$refs.contractRowDropdown.hide();
    },
    handleTracker(trackerName) {
      if (this.isHistoryTab) {
        this.$skAnalytics.track(`${trackerName}_on_past_contract`);
      } else {
        this.$skAnalytics.track(`${trackerName}_on_future_contract`);
      }
    },
    setEllipsisActive() {
      const el = document.getElementById(`contract-type-${this.contractTypeName}`);
      if (!el) return;

      this.isEllipsisActive = el.offsetWidth < el.scrollWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.contracts-row__wrapper {
  table-layout: fixed;

  td:first-child {
    padding-left: 10px;
    min-width: 230px;
  }

  &:hover {
    background-color: $sk-grey-5;
  }

  .contracts-column__wrapper {
    width: 15%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .contracts-row__cell {
    display: flex;
    align-items: center;
  }

  .contracts-row__cell-text {
    display: flex;
    align-items: center;
    max-width: 250px;
    padding-left: 16px;
  }

  .contracts-row__cell-text-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .contracts-row__cell-icon {
    margin-left: 8px;
  }

  .contracts-row__cell-link {
    font-weight: $fw-semi-bold;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $sk-blue;
    }
  }

  .contracts-row__cell-sortable {
    margin-left: -15px;
  }

  .contracts-row__dropdown {
    padding-right: 10px;

    ::v-deep .sk-dropdown__anchor {
      text-align: right;
      padding-left: 0;
    }
  }

  .contracts-row__dropdown-menu {
    width: 230px;
    background: white;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
  }

  .contracts-row__dropdown-menu__item {
    align-items: center;
    padding: 10px;
    cursor: pointer;
    display: flex;

    &__icon {
      margin-right: 10px;
    }

    &:hover {
      background: $sk-grey-5;
    }

    .contracts-row__dropdown-menu__item-text {
      padding-left: 10px;
    }

    .custom-spacing {
      padding-left: 8px;
      line-height: 1;
      white-space: initial;
    }

    .custom-size-dupliacte-icon {
      width: 15px;
    }

    .contracts-row__dropdown-menu__item-icon-trash {
      ::v-deep path {
        fill: $sk-error;
      }
    }
  }

  .contracts-row__dropdown--disabled {
    cursor: not-allowed;
  }
}
</style>
