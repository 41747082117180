<template>
  <SkModal
    id="automatic-planning-result-confirm-modal"
    :modal-title="$t('automatic_planning.results.warnings.title')"
    @close="hideModal"
  >
    <template #body>
      <div class="automatic-planning-result-modal__body">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="automatic-planning-result-modal__disclaimer"
          v-html="$t('automatic_planning.results.warnings.description')"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </template>
    <template #footer>
      <div class="automatic-planning-result-modal__footer">
        <div class="automatic-planning-result-modal__footer__got-it">
          <SkCheckBox
            v-model="gotIt"
            :label="$t('automatic_planning.results.warnings.checkbox')"
          />
        </div>
        <div class="actions">
          <SkOroraButton
            variant="secondary"
            @click="hideModal"
          >
            {{ $t('automatic_planning.results.warnings.cancel') }}
          </SkOroraButton>
          <SkOroraButton
            @click="handleSubmit"
          >
            {{ $t('automatic_planning.results.warnings.validate') }}
          </SkOroraButton>
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';

export default {
  name: 'AutomaticPlanningResultConfirmModal',
  data() {
    return {
      gotIt: false,
    };
  },
  created() {
    const showComfirmPopUp = JSON.parse(localStorage.getItem('show_automatic_planning_result_exit_popup'));
    if (showComfirmPopUp) this.gotIt = showComfirmPopUp;
  },
  methods: {
    handleSubmit() {
      this.$emit('handle-close-confirm-modal', { doNotShow: this.gotIt });
      this.hideModal();
    },
    hideModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'automatic-planning-result-confirm-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning-result-modal__body {
  padding: 24px 25px 0;
  display: flex;

  .automatic-planning-result-modal__icon {
    margin: -2px 20px 0 0;
  }

  .automatic-planning-result-modal__disclaimer {
    max-width: 90%;
  }
}

.automatic-planning-result-modal__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  .automatic-planning-result-modal__footer__got-it {
    align-items: center;
    display: flex;
    margin-bottom: 0;
  }
}
</style>
