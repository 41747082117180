<template>
  <div class="employee-step__row">
    <SkInput
      v-model="user.firstName"
      class="col-3 employee-step__row__input"
      :label="$t('onboarding_modal.employee.first_name')"
      :errored="hasFirstNameError"
      :error-message="$t('onboarding_modal.employee.user_row.no_empty_field')"
      @keyup="validateFirstName"
    />
    <SkInput
      v-model="user.lastName"
      class="col-3 employee-step__row__input"
      :label="$t('onboarding_modal.employee.last_name')"
      :errored="hasLastNameError"
      :error-message="$t('onboarding_modal.employee.user_row.no_empty_field')"
      @keyup="validateLastName"
    />
    <SkInput
      v-model="user.email"
      class="col-5 employee-step__row__input employee-step__row__input__email"
      :label="$t('onboarding_modal.employee.email')"
      :debounce="validateEmail"
      :debounce-ms="1000"
      :errored="hasEmailError || !!user.emailLocalDuplicates"
      :error-message="emailErrorMessage"
    />
    <div
      class="col-1 employee-step__row__icon"
    >
      <SkCircleButton
        v-show="!isDeleteDisabled"
        icon="TrashCanV2Icon"
        icon-color="#d03e50"
        background-color="transparent"
        size="medium"
        @click="emitDeleteRow"
      />
    </div>
  </div>
</template>

<script>
import { isValidEmail } from '@skello-utils/validators';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'EmployeeStep',
  components: {
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    anyImportedEmployees: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hasFirstNameError: false,
      hasLastNameError: false,
      hasEmailError: false,
      emailErrorMessage: '',
    };
  },
  computed: {
    isDeleteDisabled() {
      return this.employees.length < 3;
    },
    isMailAlreadyTakenInModal() {
      return this.user.email &&
        this.employees.filter(employee => employee.email === this.user.email).length > 1;
    },
  },
  mounted() {
    // errors are triggered on keyup which means that with LLM
    // we need to manually trigger them on websocket response
    // if there is at least first name or last name (no error on empty lines)
    if (this.anyImportedEmployees && (this.user.firstName || this.user.lastName)) {
      this.validateFirstName();
      this.validateLastName();
    }
  },
  methods: {
    validateFirstName() {
      this.hasFirstNameError = !this.user.firstName;
      this.updateParent();
    },
    validateLastName() {
      this.hasLastNameError = !this.user.lastName;
      this.updateParent();
    },
    validateEmail() {
      if (this.user.emailLocalDuplicates &&
         (!this.isMailAlreadyTakenInModal || this.user.emailLocalDuplicates !== this.user.email)
      ) {
        this.emailErrorMessage = '';
        this.$emit('update-duplicate-email', { email: this.user.emailLocalDuplicates, isDuplicating: false });
        this.user.emailLocalDuplicates = null;
      }

      if (this.user.email.length === 0) {
        this.hasEmailError = false;
        this.updateParent();
        return;
      }

      this.hasEmailError = this.user.email && !isValidEmail(this.user.email);

      if (this.isMailAlreadyTakenInModal) {
        this.emailErrorMessage = this.$t('onboarding_modal.employee.user_row.duplicate_email');
        this.$emit('update-duplicate-email', { email: this.user.email, isDuplicating: true });
        this.updateParent();
        return;
      }

      if (this.hasEmailError) {
        this.emailErrorMessage = this.$t('onboarding_modal.employee.user_row.invalid_email');

        this.updateParent();
      } else {
        httpClient
          .get('/v3/api/email_validation', { params: { email: this.user.email } })
          .then(() => {
            this.hasEmailError = false;
            this.emailErrorMessage = null;
          })
          .catch(error => {
            this.hasEmailError = true;
            this.emailErrorMessage = error.response.data.message;
          })
          .finally(() => {
            this.updateParent();
          });
      }
    },
    updateParent() {
      this.$emit('update-errored', this.hasFirstNameError || this.hasLastNameError || this.hasEmailError);
    },
    emitDeleteRow() {
      if (this.isDeleteDisabled) return;
      this.$emit('delete-row', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
  .employee-step__row__input {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 12px;
  }

  .employee-step__row__input__email {
    margin-right: 0;
  }

  .employee-step__row__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    max-height: 44px;
  }
</style>
