<template>
  <div class="absences__table">
    <SkTable
      :columns="headers"
      :loading="shiftsLoading"
      :is-empty="isEmpty"
      @sort="handleSort"
    >
      <template #empty-state>
        <div class="absences__table__empty">
          <EmptyBadgingsLogo />
          <p class="absences__table__empty__message">
            {{ $t('badgings.days.table.empty_state.planned_absences') }}
          </p>
        </div>
      </template>
      <AbsenceRow
        v-for="absence in dayAbsencesPerUser"
        :key="absence.id"
        :absence="absence"
      />
    </SkTable>
  </div>
</template>

<script>
import {
  mapState, mapMutations, mapGetters,
} from 'vuex';

import { EmptyBadgingsLogo } from '@app-js/badgings/shared/svg';
import AbsenceRow from '@app-js/badgings/shared/components/AbsenceRow';

export default {
  name: 'Absences',
  components: { EmptyBadgingsLogo, AbsenceRow },
  data() {
    return {
      headers: [
        {
          title: this.$t('badgings.days.tabs.absences.headers.day'),
          name: 'day',
          sort: true,
          defaultSort: 'asc',
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.type'),
          name: 'absenceName',
          sort: true,
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.planned'),
          class: 'table-column__filled-section',
        },
        {
          title: this.$t('badgings.days.tabs.absences.headers.total'),
          class: 'table-column__filled-section',
        },
      ],
    };
  },
  computed: {
    ...mapState('badgings', ['shiftsLoading']),
    ...mapGetters('badgings', ['dayAbsencesPerUser']),
    isEmpty() {
      return !this.dayAbsencesPerUser || this.dayAbsencesPerUser.length === 0;
    },
  },
  methods: {
    ...mapMutations('badgings', ['sortAbsences']),
    handleSort(event, columnName, direction) {
      this.sortAbsences({ columnName, direction });
    },
  },
};
</script>

<style lang="scss" scoped>
.absences__table {
  ::v-deep th:first-child {
    padding-left: 10px;
  }

  ::v-deep th:last-child {
    .table-column__label {
      justify-content: flex-end;
      padding-right: 25px;
    }
  }

  ::v-deep .table-column__filled-section {
    &:first-child {
      width: 30%;
    }
  }

  ::v-deep .sk-table > tbody td {
    border: none;
  }

  .absences__table__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;

    .absences__table__empty__message {
      margin-top: 50px;
      color: $sk-black;
      font-size: $fs-heading-xs;
    }
  }

  // Prevent to display matched badging row on intercom bubble
  table.sk-table {
    margin-bottom: 90px;
  }
}
</style>
