<template>
  <div>
    <div class="annualization-counter__initialization-overlay">
      <div class="annualization-counter__initialization-overlay__icon">
        <component
          :is="icon"
          height="24"
          width="24"
          fill="#2B66FE"
        />
      </div>
      <div class="annualization-counter__initialization-overlay__title">
        <h2 class="sk-header--2">
          {{ title }}
        </h2>
      </div>
      {{ description }}
      <div class="annualization-counter__initialization-overlay__item">
        <SkMedallion
          :text="userInitials(employee)"
          background-color="#2b66fe"
          color="#fff"
        />
        <div class="annualization-counter__initialization-overlay__full-name">
          {{ fullName(employee) }}
        </div>
      </div>
      <SkOroraButton
        data-test="annualization-counter__initialization-button"
        @click="handleInitClick"
      >
        {{ $t('employees.tabs.counters.annualization_counter.initialize') }}
        <MountingPortal
          mount-to="#modals-portal"
          append
        >
          <InitializationModal ref="annualizationInitializationModal" />
        </MountingPortal>
      </SkOroraButton>
    </div>
    <div class="annualization-counter__initialization-overlay__background" />
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import InitializationModal from './InitializationModal.vue';

export default {
  name: 'InitializeCounterOverlay',
  components: { InitializationModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences', 'canReadPaidVacationCounter']),
    ...mapGetters('employees', ['fullName', 'userInitials']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', ['employee']),
    canPerformManualModification() {
      return (
        this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser
      ) && this.canReadPaidVacationCounter;
    },
    unauthorizedAction() {
      return !this.canEditShopRulesAndAbsences || !this.canPerformManualModification;
    },

  },
  methods: {
    handleInitClick() {
      if (this.unauthorizedAction) {
        this.$skToast({
          message: this.$t('employees.tabs.counters.annualization_counter.unauthorized'),
          variant: 'error',
        });
        return;
      }

      this.$skAnalytics.track('annualization_start_init');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'annualization-config-user-initialization-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-counter__initialization-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 38px;
  left: calc(50% - 210px);
  opacity: 1;
  z-index: 1;
  width: 420px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
  border-radius: 6px;
  padding: 24px 24px 40px;
  background-color: white;
  line-height: normal;
  text-align: center;

  &__icon {
    width: 50px;
    height: 50px;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba($sk-blue, .15);
    vertical-align: middle;
  }

  &__title {
    margin: 16px 48px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 10px;
    margin: 24px;
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
  }

  &__full-name {
    padding-left: 16px;
  }

  &__background {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 436px;
    background: rgba(0, 0, 0, .2);
  }
}
</style>
