<template>
  <div :class="headerClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TitlesColumn',
  props: {
    customClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headerClasses() {
      return [...this.customClasses, 'titles-column__wrapper'];
    },
  },
};
</script>

<style lang="scss" scoped>
.titles-column__wrapper {
  width: 282px;
  background-color: white;
  color: $sk-black;
  box-shadow: 15px 0 30px -15px rgba(0, 0, 0, .15);
  padding-top: 82px;
  margin-left: 24px;
}
</style>
