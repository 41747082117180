import { isEmpty } from '@skello-utils/array';
import skDate from '@skello-utils/dates';
import cloneDeep from 'lodash/cloneDeep';
/**
 * Indicates the (x, y) coordinates for a cell on the weekly employee view of the planning.
 *
 * @param {*} userId Id of the user concerned by the cell. Must be present in the userList.
 * @param {*} userList Employees displayed on the planning on the Y-axis
 *                     (0 at the top-left and positive values at the bottom).
 * @param {*} visibleDays Days displayed on the planning on the X-axis
 *                        format : [{date: "2024-10-28", weekDay: "monday", ...}]
 * @param {*} date date to look for in the {date: "2024-10-28", weekDay: "monday"visible days array
 * @param {*} isUnassignedRowDisplayed Config of the shop if they enabled the unassign line.
 *
 * @returns coordinates at the format {x: 1, y: 2}
 */
export const getCellCoordinates = (
  userId, userList, date, visibleDays, isUnassignedRowDisplayed,
) => {
  const x = visibleDays.findIndex(visibleDay => visibleDay.date === date);

  // userId is null for cells on unassigned line
  let y = userId ? userList.findIndex(user => user.id === userId) : 0;

  if (isUnassignedRowDisplayed && userId) {
    y += 1; // as first line is for unassigned
  }

  return { x, y };
};

/**
 * Indicates the translation coordinates that needs to be applied on the most upper-left cell
 * amongst the selected cells, to reach the destination coordinates.
 *
 * @param {*} selectedCells All the cells selected on the planning to compute on most upper-left cell.
 * @param {*} cellDestinationCoordinates The destination coordinates on the planning format {x: 5, y: 6}
 *
 * @returns translation coordinates at the format {translationX: 4, translationY: 4}
 */
export const computeTranslationToApply = (selectedCells, cellDestinationCoordinates) => {
  const xValues = [];
  const yValues = [];

  Object.keys(selectedCells) // format ["x,y", ...] example ["1,2", "1,4"]
    .forEach(coordinate => {
      const [x, y] = coordinate.split(',').map(Number); // [[1,2], [1,4]] in the example

      xValues.push(x); // final result [1, 1] in the example
      yValues.push(y); // final result [2, 4] in the example
    });

  // since we're either on a line or a column but not a mix of both
  const firstCellToCopy = {
    x: Math.min(...xValues), // 1
    y: Math.min(...yValues), // 2
  };

  return {
    translationX: cellDestinationCoordinates.x - firstCellToCopy.x,
    translationY: cellDestinationCoordinates.y - firstCellToCopy.y,
  };
};

/**
* This function is used to apply the bulk update of shifts when pasting copied cells.
*
* @param {number} userId - The ID of the user.
* @param {string} startsAt - The start date and time of the shift.
* @param {string} endsAt - The end date and time of the shift.
* @param {Object} shift - The shift to update.
*
* @returns {Object} The built shift.
*/
const updateShiftUserAndDate = (userId, startsAt, endsAt, shift) => {
  shift.attributes.userId = userId;
  shift.attributes.startsAt = startsAt;
  shift.attributes.endsAt = endsAt;
  return shift;
};

/**
 * Processes a shift by calculating the new start and end times based on the destination day,
 * and updates the shift with the target user's information.
 *
 * @param {Object} shift - The shift to be processed.
 * @param {Object} destinationDay - The day to which the shift is being pasted.
 * @param {Object|null} targetUser - The user to whom the shift is being assigned. Null if unassigned.
 * @param {Array} shiftsToPaste - The array to which the processed shift will be added.
 *
 * @returns {Object} The built shift with new id and new date.
 */
const buildShiftToPaste = (shift, destinationDate, targetUser) => {
  const oldStartsAt = skDate.utc(shift.attributes.startsAt);
  const oldEndsAt = skDate.utc(shift.attributes.endsAt);
  // check if the shift start and end are on the same day
  const isSameDay = oldEndsAt.isSame(oldStartsAt, 'day');
  let newShift = cloneDeep(shift);

  // Clean id to avoid pk conflict when creating new shift
  newShift.id = null;
  const newShiftStartsAt = skDate.utc(destinationDate);
  newShiftStartsAt.set({
    h: oldStartsAt.hour(),
    m: oldStartsAt.minute(),
  });
  const newShiftEndsAt = skDate.utc(destinationDate);
  newShiftEndsAt.set({
    h: oldEndsAt.hour(),
    m: oldEndsAt.minute(),
  });

  // To handle the case where the shift ends at the next day (ex: 23:00 - 01:00)
  if (!isSameDay) {
    newShiftEndsAt.add(1, 'days');
  }
  newShift = updateShiftUserAndDate(
    targetUser ? targetUser.id : null,
    newShiftStartsAt,
    newShiftEndsAt,
    newShift,
  );
  return newShift;
};

/**
 * Builds an array of shifts to be pasted based on the copied cells and the destination coordinates.
 *
 * @param {Object} params - The parameters for building the shifts to paste.
 * @param {string} params.userId - The ID of the user to whom the shifts are being pasted.
 * @param {Array} params.displayedInPlanningUsers - The list of users displayed in the planning.
 * @param {Object} params.day - The day to which the shifts are being pasted.
 * @param {Array} params.visibleDays - The days visible in the planning.
 * @param {Object} params.copiedCells - The cells that have been copied.
 * @param {boolean} params.isUnassignedRowDisplayed - Whether the unassigned line is enabled in the shop.
 *
 * @returns {Array} The array of shifts to be pasted.
 */
export const buildShiftsToPaste = ({
  userId,
  displayedInPlanningUsers,
  day,
  visibleDays,
  copiedCells,
  isUnassignedRowDisplayed,
}) => {
  const cellDestinationCoordinates = getCellCoordinates(
    userId,
    displayedInPlanningUsers,
    day,
    visibleDays,
    isUnassignedRowDisplayed,
  );

  const translations = computeTranslationToApply(copiedCells, cellDestinationCoordinates);
  const shiftsToPaste = [];
  const users = [...displayedInPlanningUsers];
  if (isUnassignedRowDisplayed) {
    users.unshift({ id: null, name: 'Unassigned' });
  }

  Object.entries(copiedCells).forEach(([cellCoordinates, cellShifts]) => {
    if (isEmpty(cellShifts)) return;
    const [copiedCellX, copiedCellY] = cellCoordinates.split(',').map(Number);
    const newX = copiedCellX + translations.translationX;
    const newY = copiedCellY + translations.translationY;
    if (newX >= 0 && newX < visibleDays.length &&
        newY >= 0 && newY < users.length && !visibleDays[newX]?.isLocked) {
      cellShifts.forEach(shift => {
        shiftsToPaste.push(buildShiftToPaste(
          shift,
          skDate.utc(visibleDays[newX].date),
          users[newY],
        ));
      });
    }
  });
  return shiftsToPaste;
};

/**
 * Builds an array of shifts to be pasted when multiple cells are selected.
 *
 * @param {Object} params - The parameters for building the shifts to paste.
 * @param {Object} params.selectedCells - The currently selected cells where shifts will be pasted.
 * @param {Array} params.displayedInPlanningUsers - The list of users displayed in the planning.
 * @param {Array} params.visibleDays - The days visible in the planning.
 * @param {Object} params.copiedCells - The cells that have been copied.
 * @param {boolean} params.isUnassignedRowDisplayed - Whether the unassigned line is enabled in the shop.
 *
 * @returns {Array} The array of shifts to be pasted.
 */
export const buildMultiShiftsToPaste = ({
  selectedCells,
  displayedInPlanningUsers,
  visibleDays,
  copiedCells,
  isUnassignedRowDisplayed,
}) => {
  const shiftsToPaste = [];
  const users = [...displayedInPlanningUsers];
  if (isUnassignedRowDisplayed) {
    users.unshift({ id: null, name: 'Unassigned' });
  }

  const copiedEntries = Object.entries(copiedCells);

  // If there is only one copied cell
  if (copiedEntries.length === 1) {
    const [copiedCoordinate, cellShifts] = copiedEntries[0];
    if (isEmpty(cellShifts)) return shiftsToPaste;

    Object.keys(selectedCells).forEach(selectedCellCoordinate => {
      const [selectedX, selectedY] = selectedCellCoordinate.split(',').map(Number);

      if (selectedX >= 0 && selectedX < visibleDays.length &&
          selectedY >= 0 && selectedY < users.length &&
          !visibleDays[selectedX]?.isLocked) {
        const destinationUser = users[selectedY];
        const destinationDate = visibleDays[selectedX].date;

        cellShifts.forEach(shift => {
          shiftsToPaste.push(buildShiftToPaste(
            shift,
            skDate.utc(destinationDate),
            destinationUser,
          ));
        });
      }
    });
    return shiftsToPaste;
  }

  // Handle the case where multiple cells are selected
  const copiedCoordinates = Object.keys(copiedCells).map(coord => {
    const [x, y] = coord.split(',').map(Number);
    return { x, y };
  });

  const minCopiedX = Math.min(...copiedCoordinates.map(coord => coord.x));

  Object.keys(selectedCells).forEach(selectedCellCoordinate => {
    const [selectedX, selectedY] = selectedCellCoordinate.split(',').map(Number);

    Object.entries(copiedCells).forEach(([copiedCoordinate, cellShifts]) => {
      if (isEmpty(cellShifts)) return;

      const [copiedX] = copiedCoordinate.split(',').map(Number);
      const xOffset = copiedX - minCopiedX;
      const destinationX = selectedX + xOffset;

      if (destinationX >= 0 &&
          destinationX < visibleDays.length &&
          !visibleDays[destinationX]?.isLocked) {
        const destinationUser = users[selectedY];
        const destinationDate = visibleDays[destinationX].date;

        cellShifts.forEach(shift => {
          shiftsToPaste.push(buildShiftToPaste(
            shift,
            skDate.utc(destinationDate),
            destinationUser,
          ));
        });
      }
    });
  });

  return shiftsToPaste;
};
