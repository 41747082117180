<template>
  <div
    class="sk-modal-section__parameter__options"
  >
    <h3 class="mb-3">
      <!-- eslint-disable max-len -->
      <span v-if="isMealStepOwed">
        {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.question.owed_meals') }}
      </span>
      <span v-else>
        {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.question.taken_meals') }}
      </span>
      <!-- eslint-enable max-len -->
    </h3>
    <template v-if="isOwedMealsCalculationMethodHoursWorked && isMealStepTaken">
      <div class="sk-modal-section__parameter__same-criteria">
        <div class="sk-modal-section__parameter__same-criteria-title">
          <!-- eslint-disable-next-line max-len -->
          {{ $t("shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.same_criteria") }}
        </div>
        <div class="sk-modal-section__parameter__same-criteria-choice">
          <SkSwitch v-model="sameRulesOwedAndTaken" />
        </div>
      </div>
    </template>
    <template v-if="isMealStepOwed">
      <div class="sk-modal-section__parameter__options__select">
        <!-- eslint-disable max-len -->
        <div class="sk-modal-section__parameter__options__select-title">
          <span>{{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${calculationMethod}.title_part_1`) }}</span>
          <span class="sk-modal-section__parameter__options--text-blue">
            {{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${calculationMethod}.title_part_2`) }}
          </span>
          <span>{{ $t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.${calculationMethod}.title_part_3`) }}</span>
        </div>
        <SkSelectV2
          v-model="owedMealsCalculationMethod"
          :options="owedMealsCalculationMethodOptions"
          :label="$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_method.calculation_methods.select_label')"
          class="sk-modal-section__parameter__options__select--sk-select"
        >
          <!-- eslint-enable  max-len -->
          <template #selected-option="{ value }">
            <span class="text-truncate">
              {{ owedMealsCalculationMethodOptions.find(option => option.id === value).text }}
            </span>
          </template>
        </SkSelectV2>
      </div>
    </template>
    <template v-if="isOwedMealsCalculationMethodDaysWorked && isMealStepOwed">
      <!-- eslint-disable max-len -->
      <div class="sk-modal-section__parameter__options__select">
        <span class="sk-modal-section__parameter__options__select-title">
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.days_worked.method') }}
        </span>
        <SkInput
          v-model="averageMealsOwedPerDay"
          :errored="errorAverageMealsOwedPerDay"
          step="1"
          type="number"
          min="0"
          class="sk-modal-section__parameter__options__select--sk-input"
        />
      </div>
      <!-- eslint-enable max-len -->
    </template>
    <template v-else-if="isOwedMealsCalculationMethodMonthlyAverage && isMealStepOwed">
      <!-- eslint-disable max-len -->
      <div class="sk-modal-section__parameter__options__select">
        <span class="sk-modal-section__parameter__options__select-title">
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.monthly_average.method') }}
        </span>
        <SkInput
          v-model="averageMealsOwedPerMonth"
          :errored="errorAverageMealsOwedPerMonth"
          step="1"
          type="number"
          min="0"
          class="sk-modal-section__parameter__options__select--sk-input"
        />
      </div>
      <!-- eslint-enable max-len -->
    </template>
    <template v-else-if="isMealStepOwed || !sameRulesOwedAndTaken">
      <!-- eslint-disable max-len -->
      <div class="sk-modal-section__parameter__options__select">
        <span class="sk-modal-section__parameter__options__select-title">
          {{ $t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.hours_worked.method') }}
        </span>
        <SkSelectV2
          v-model="triggerType"
          :options="triggerTypeOptions"
          :label="$t('shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.select_label')"
          class="sk-modal-section__parameter__options__select--sk-select"
        >
          <template #selected-option="{ value }">
            <span class="text-truncate">
              {{ triggerTypeOptions.find(option => option.id === value).text }}
            </span>
          </template>
        </SkSelectV2>
      </div>
      <!-- eslint-enable max-len -->
    </template>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
} from 'vuex';

export default {
  name: 'MealRuleSettingParameters',
  props: {
    currentStep: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('mealRule', ['mealRule']),
    ...mapState('config', ['config']),
    owedMealsCalculationMethodOptions() {
      const methods = this.config.meal_rules.owed_meals_calculation_methods;
      return Object.values(methods).map(owedMealsCalculationMethod => ({
        id: owedMealsCalculationMethod,
        text: this.$t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.owed_meals_calculation_methods.${owedMealsCalculationMethod}.option`),
      }));
    },
    isMealStepOwed() {
      return this.currentStep === this.config.meal_rules.meal_steps.owed_meals;
    },
    isMealStepTaken() {
      return this.currentStep === this.config.meal_rules.meal_steps.taken_meals;
    },
    isOwedMealsCalculationMethodMonthlyAverage() {
      // eslint-disable-next-line
      return this.owedMealsCalculationMethod === this.config.meal_rules.owed_meals_calculation_methods.monthly_average;
    },
    isOwedMealsCalculationMethodDaysWorked() {
      // eslint-disable-next-line
      return this.owedMealsCalculationMethod === this.config.meal_rules.owed_meals_calculation_methods.days_worked;
    },
    isOwedMealsCalculationMethodHoursWorked() {
      // eslint-disable-next-line
      return this.owedMealsCalculationMethod === this.config.meal_rules.owed_meals_calculation_methods.hours_worked;
    },
    triggerTypeOptions() {
      return Object.values(this.config.meal_rules.trigger_types).map(triggerType => ({
        id: triggerType,
        text: this.$t(`shop_settings.tabs.rules.main_rules.meal_rules.modal.calculation_settings.trigger_types.${triggerType}.option`),
      }));
    },
    errorAverageMealsOwedPerDay() {
      return this.isOwedMealsCalculationMethodDaysWorked &&
             (isNaN(parseInt(this.averageMealsOwedPerDay, 10)) ||
             this.averageMealsOwedPerDay < 0);
    },
    errorAverageMealsOwedPerMonth() {
      return this.isOwedMealsCalculationMethodMonthlyAverage &&
             (isNaN(parseInt(this.averageMealsOwedPerMonth, 10)) ||
             this.averageMealsOwedPerMonth < 0);
    },
    errorForm() {
      return this.errorAverageMealsOwedPerDay || this.errorAverageMealsOwedPerMonth;
    },
    calculationMethod() {
      return this.mealRule.attributes.calculationMethod;
    },
    owedMealsCalculationMethod: {
      get() {
        return this.mealRule.attributes.owedMealsCalculationMethod;
      },
      set(newValue) {
        if (this.isMealStepOwed) {
          this.mealRule.attributes.owedMealsCalculationMethod = newValue;
        }
      },
    },
    triggerType: {
      get() {
        return this.mealRule.attributes.triggerType[this.currentStep];
      },
      set(newValue) {
        this.mealRule.attributes.triggerType[this.currentStep] = newValue;
      },
    },
    averageMealsOwedPerDay: {
      get() {
        return this.mealRule.attributes.averageMealsOwedPerDay;
      },
      set(newValue) {
        this.mealRule.attributes.averageMealsOwedPerDay = newValue;
      },
    },
    averageMealsOwedPerMonth: {
      get() {
        return this.mealRule.attributes.averageMealsOwedPerMonth;
      },
      set(newValue) {
        this.mealRule.attributes.averageMealsOwedPerMonth = newValue;
      },
    },
    sameRulesOwedAndTaken: {
      get() {
        return this.mealRule.attributes.sameRulesOwedAndTaken &&
          // eslint-disable-next-line
          this.mealRule.attributes.owedMealsCalculationMethod === this.config.meal_rules.owed_meals_calculation_methods.hours_worked;
      },
      set(newValue) {
        this.mealRule.attributes.sameRulesOwedAndTaken = newValue;
        if (!newValue) {
          this.deleteTakenMealsTriggers();
        }
      },
    },
  },
  watch: {
    errorForm() {
      this.$emit('handle-error-form', this.errorForm);
    },
  },
  methods: {
    ...mapMutations('mealRule', [
      'deleteMealAdditionTriggerForTakenMeals',
    ]),
    deleteTakenMealsTriggers() {
      this.deleteMealAdditionTriggerForTakenMeals(this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-modal-section__parameter__options {
  margin-top: 25px;

  .sk-modal-section__parameter__options--text-blue {
    color: $sk-blue;
  }

  .sk-modal-section__parameter__options__select {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    .sk-modal-section__parameter__options__select-title {
      width: 270px;
      font-weight: bold;
    }

    .sk-modal-section__parameter__options__select--sk-select {
      width: 300px !important;
      margin-right: 85px;
    }

    .sk-modal-section__parameter__options__select--sk-input {
      width: 120px;
      margin-right: 265px;
    }
  }
}

.sk-modal-section__parameter__same-criteria {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .sk-modal-section__parameter__same-criteria-title {
    width: 270px;
    font-weight: bold;
  }

  .sk-modal-section__parameter__same-criteria-choice {
    width: 300px !important;
    margin-right: 85px;
  }
}
</style>
