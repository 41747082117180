import { httpClient } from '@skello-utils/clients';
import Vue from 'vue';
import skDate from '@skello-utils/dates';
import { fetchInChunks } from '@skello-utils/batches';

const initialState = {
  years: [],
  counters: [],
  countersLoading: {},
  dayRateYears: [],
  majoration: false,
  error: null,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  employeeHoursCounterSuccess(state, payload) {
    state.majoration = payload.majoration;
    state.years = payload.structure;
  },

  employeeHoursCounterError(state, error) {
    state.error = error;
  },

  employeeDayRateCounterSuccess(state, payload) {
    state.dayRateYears = payload.structure;
  },

  usersHoursCountersPerformingRequest(state, payload) {
    payload.user_ids.forEach(userId => {
      Vue.set(state.countersLoading, parseInt(userId, 10), true);
    });
  },
  usersHoursCounterComplete(state, payload) {
    payload.user_ids.forEach(userId => {
      Vue.set(state.countersLoading, parseInt(userId, 10), false);
    });
  },
  userHoursCounterUpdateSuccess(state, payload) {
    payload.data.forEach(updatedCounter => {
      const index = state.counters.findIndex(counter => counter.id === updatedCounter.id);
      // Slice allow to replace an element with an index and param 1
      // but also allow to add into array with -1 as index (not found in array) and 0 as param
      const replaceOrAddParam = index === -1 ? 0 : 1;
      state.counters.splice(index, replaceOrAddParam, updatedCounter);
    });
  },
  usersHoursCounterSuccess(state, payload) {
    state.counters = payload.data;
  },
  usersHoursCounterError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchSelectedEmployeeHoursCounter({ commit, rootState }, componentParams) {
    return new Promise((resolve, reject) => {
      const { currentShop } = rootState.currentShop;
      const silentLoading = componentParams.silentLoading;

      const params = {
        user_id: componentParams.user_id,
        date: componentParams.date,
        shop_id: currentShop.id,
      };

      if (!silentLoading) commit('performingRequest');

      httpClient
        .get('/saas/api/planning_hours_datas', { params })
        .then(response => {
          commit('employeeHoursCounterSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('employeeHoursCounterError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },
  async fetchUsersHoursCounters({ commit, rootState, rootGetters }, options) {
    // prevent fetching any counters if counter tab is closed (and not clicking on it to open the tab)
    if (!options.allUserCounters && !rootGetters['planningsState/isActiveItem']('counters')) return null;

    const users = options.users || rootState.planningsUsers.users;
    const monday = rootGetters['planningsState/monday'];
    const shiftGetter = userId => rootGetters['planningsShifts/shiftsForUser'](userId);

    let userWithCounterHoursIds = users
      .filter(user => {
        const {
          onExtra,
          onDayRate,
          counterInitializationDoneAt,
          firstShiftStartsAt,
        } = user.attributes;

        const isEligible = !onExtra && !onDayRate;
        const initializationDateValid = counterInitializationDoneAt && skDate(counterInitializationDoneAt).isSameOrBefore(monday, 'd');
        const hasPreviousOrCurrentShift =
          (firstShiftStartsAt && skDate(firstShiftStartsAt).isBefore(monday, 'd')) ||
          shiftGetter(user.id).length > 0;

        return isEligible && initializationDateValid && hasPreviousOrCurrentShift;
      })
      .map(user => parseInt(user.id, 10));

    if (!options.allUserCounters && !options.users) {
      userWithCounterHoursIds = userWithCounterHoursIds.filter(userId => (
        rootState.planningsShifts.updatedUserIds.includes(userId)
      ));
    }

    if (userWithCounterHoursIds.length === 0) return null;

    const params = {
      user_ids: userWithCounterHoursIds,
      date: monday,
      shop_id: options.shopId,
    };

    commit('usersHoursCountersPerformingRequest', params);

    try {
      const accumulatedData = await fetchInChunks(params, '/v3/api/planning_hours_datas');

      if (options.allUserCounters) {
        commit('usersHoursCounterSuccess', accumulatedData);
      } else {
        commit('userHoursCounterUpdateSuccess', accumulatedData);
      }
      return accumulatedData;
    } catch (error) {
      commit('usersHoursCounterError', error.response?.data);
      throw error;
    } finally {
      commit('usersHoursCounterComplete', params);
    }
  },
  updateEmployeeHoursCounter({ commit }, componentParams) {
    return new Promise((resolve, reject) => {
      const silentLoading = componentParams.silentLoading;

      if (!silentLoading) commit('performingRequest');

      const params = {
        user_id: componentParams.user_id,
        date: componentParams.date,
        planning_hours_data: {
          manual_changes: componentParams.manual_changes,
        },
      };

      httpClient
        .patch('/saas/api/planning_hours_datas', params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('employeeHoursCounterError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },
  initEmployeeHoursCounter({ commit }, { shopId, params }) {
    return new Promise((resolve, reject) => {
      httpClient
        .patch(`/v3/api/users/${params.employee_id}/initial_counters`, {
          employee_hours_counter_initialized: 'true',
          start_date: params.init_date,
          counter_sign: params.balance_modifier,
          user_initial_counter: params.balance,
          shop_id: shopId,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('employeeHoursCounterError', error.response.data);
          reject(error);
        });
    });
  },
  fetchSelectedEmployeeDayRateCounter({ commit }, componentParams) {
    return new Promise((resolve, reject) => {
      const silentLoading = componentParams.silentLoading;

      const params = {
        user_id: componentParams.user_id,
        date: componentParams.date,
      };

      if (!silentLoading) commit('performingRequest');
      httpClient
        .get('/saas/api/day_rates_counters', { params })
        .then(response => {
          commit('employeeDayRateCounterSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  updateEmployeeDayRateCounter({ commit }, componentParams) {
    return new Promise((resolve, reject) => {
      const silentLoading = componentParams.silentLoading;

      if (!silentLoading) commit('performingRequest');

      const params = {
        user_id: componentParams.user_id,
        date: componentParams.date,
        day_rates_counter: {
          manual_changes: componentParams.manual_changes,
        },
      };

      httpClient
        .patch('/saas/api/day_rates_counters', params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('employeeHoursCounterError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },
};

const getters = {
  counterHoursByUserId: state => (
    userId => state.counters.find(counter => counter.attributes.userId === parseInt(userId, 10))
  ),
  countersHoursFetchingStatusForUserId: state => userId => state.countersLoading[userId],
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
