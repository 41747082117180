<template>
  <div class="organisation-licenses__card-container">
    <div class="organisation-licenses__card__body">
      <div
        v-if="!licenseActive"
        class="organisation-licences__card__inactive-block"
      />
      <SkTag
        v-if="!license.attributes.active"
        variant="danger"
        class="organisation-licenses__card__inactive-tag"
      >
        {{ $t('organisation_settings.tabs.licenses.cards.inactive') }}
      </SkTag>
      <div class="organisation-licenses__card-header">
        <SkMedallion
          :text="`${index + 1}`"
          :color="positionContentTextColor"
          :background-color="positionBackgroundColor"
        />
        <div class="organisation-licenses__card-name">
          {{ licenseName }}
        </div>
        <p class="sk-subtitle--medium">
          {{
            $t(
              'organisation_settings.tabs.licenses.users_count',
              { count: license.attributes.usersCount },
            )
          }}
        </p>
      </div>
      <div class="organisation-licenses__card__select">
        {{ $t('organisation_settings.tabs.licenses.select_label') }}
        <SkSelectV2
          v-model="generalAction"
          :options="generalActionsOptions"
          :disabled="!licenseIsEditable"
          :disabled-options="isCanReadSelfOnly"
        />
      </div>
      <div class="organisation-licenses__action-row__wrapper">
        <ActionRow
          v-for="action in checkboxActions"
          :key="action.name"
          :action="action"
          :license="license"
          @update-license="handleUpdateFromRow"
        />
        <span
          class="organisation-licenses__show-more sk-text-small-semibold"
          @click="showSidePanel"
        >
          +{{ license.attributes.actionsCount }} {{ $t('labels.others') }}
        </span>
      </div>
    </div>
    <div
      :class="footerClasses"
      class="organisation-licenses__card-footer"
    >
      <div class="organisation-licenses__card-footer__switch">
        <SkSwitch
          v-show="licenseIsEditable"
          v-model="licenseActive"
          v-tooltip="switchTooltip"
        />
      </div>
      <SkOroraButton
        :disabled="!license.attributes.active"
        variant="tertiary"
        @click="showSidePanel"
      >
        {{ handleFooterButtonLabel }}
      </SkOroraButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { LICENSE_COLORS } from '@app-js/shared/constants/colors';
import { arrayToSelectOptions } from '@skello-utils/form';

import ActionRow from './ActionRow';

export default {
  name: 'LicenseCard',
  components: { ActionRow },
  props: {
    license: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentLicense', ['currentLicense']),
    licenseActive: {
      get() {
        return this.license.attributes.active;
      },

      set(newValue) {
        this.license.attributes.active = newValue;

        this.updateLicense();
      },
    },
    generalAction: {
      get() {
        return this.license.attributes.actions.general.find(a => a.allowed).name;
      },

      set(newValue) {
        this.license.attributes.actions.general.forEach(action => {
          action.allowed = false;

          if (action.name === newValue) {
            action.allowed = true;
          }
        });

        this.updateLicense();
      },
    },
    footerClasses() {
      return {
        'organisation-licenses__card-footer--show': !this.licenseIsEditable,
        'organisation-licenses__card-footer--customize': this.licenseIsEditable,
      };
    },
    switchTooltip() {
      return this.license.attributes.active ?
        this.$t('organisation_settings.tabs.licenses.cards.deactive_license') :
        this.$t('organisation_settings.tabs.licenses.cards.active_license');
    },
    licenseIsEditable() {
      return this.currentLicense.attributes.position < this.license.attributes.position;
    },
    handleFooterButtonLabel() {
      return this.licenseIsEditable ?
        this.$t('organisation_settings.tabs.licenses.cards.footer_customize_button') :
        this.$t('organisation_settings.tabs.licenses.cards.footer_see_button');
    },
    checkboxActions() {
      return this.license.attributes.definingActions.slice(1, 3);
    },
    licenseName() {
      // eslint-disable-next-line max-len
      return this.$t(`organisation_settings.tabs.licenses.titles.${this.license.attributes.originalType}`);
    },
    positionBackgroundColor() {
      return LICENSE_COLORS[this.license.attributes.originalType].backgroundColor;
    },
    positionContentTextColor() {
      return LICENSE_COLORS[this.license.attributes.originalType].color;
    },
    generalActionsOptions() {
      return arrayToSelectOptions(
        this.license.attributes.actions.general.map(action => action.name),
        value => this.$t(`organisation_settings.tabs.licenses.general_actions.${value}`),
      );
    },
    isCanReadSelfOnly() {
      // if license is not employee
      if (!this.config.can_read_self_only_licenses.includes(this.license.attributes.originalType)) {
        return ['can_read_self_only'];
      }

      return [];
    },
  },
  methods: {
    handleUpdateFromRow() {
      this.updateLicense();
    },
    updateLicense() {
      this.$emit('update-license', this.license);
    },
    showSidePanel() {
      this.$emit('show-side-panel', this.license);
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-licenses__card-container {
  min-width: 260px;
  width: 280px;
  margin: 0 33px 30px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $sk-grey-10;
  transition: .3s ease;
  border-radius: 4px;
  padding-top: 30px;

  &:hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
  }

  .organisation-licenses__card__body {
    height: 340px;
    position: relative;
  }

  .organisation-licenses__card-name {
    font-weight: $fw-semi-bold;
    margin: 10px 15px 0;
  }

  .organisation-licenses__action-row__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 16px 0;
    height: 120px;
  }

  .organisation-licenses__show-more {
    padding: 16px 0 0 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .organisation-licenses__card-footer {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid $sk-grey-10;
    padding: 0 15px;

    &--show {
      justify-content: flex-end;
    }

    &--customize {
      justify-content: space-between;
    }
  }

  .organisation-licenses__card__inactive-tag {
    position: absolute;
    top: -20px;
    left: 10px;
    z-index: 2;
  }

  .organisation-licenses__card__select {
    width: 100%;
    padding: 10px 15px 0;
  }

  .organisation-licenses__card-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--disabled {
      opacity: .4;
    }
  }

  ::v-deep .sk-tag--danger {
    background: $sk-error-10;
    color: $sk-error;
  }

  ::v-deep .sk-button--disabled {
    background: none;
    color: $sk-blue;
  }
}

.organisation-licenses__card-footer__switch {
  z-index: 2;
}

.organisation-licences__card__inactive-block {
  position: absolute;
  top: -30px;
  background: white;
  bottom: -60px;
  left: 0;
  right: 0;
  border-radius: 3px;
  opacity: .5;
  z-index: 1;
  cursor: not-allowed;
}
</style>
