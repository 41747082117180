import ShopSettings from './ShopSettings';
import ShopRules from './pages/ShopRules';
import Alerts from './pages/Alerts';
import ReportRules from './pages/ReportRules';
import Absences from './pages/Absences';
import Postes from './pages/Postes';
import ShopInfo from './pages/ShopInfo';
import Teams from './pages/Teams';
import AutomaticPlanning from './pages/AutomaticPlanning';
import DocumentsManagement from './pages/DocumentsManagement';
import MainRules from './pages/ShopRules/MainRules';
import CounterRules from './pages/ShopRules/CounterRules';
import Rules from './pages/AutomaticPlanning/Rules';
import Competencies from './pages/AutomaticPlanning/Competencies';
import PunchClock from './pages/PunchClock';
import SettingDetails from './pages/SettingDetails';
import AnnualizationCounterDetails from './pages/SettingDetails/AnnualizationCounterDetails';

const shopRoutes = [
  {
    path: '/v3/shops/:shop_id/settings',
    component: ShopSettings,
    name: 'shop_settings',
    children: [
      {
        path: 'rules',
        name: 'shop_settings_rules',
        component: ShopRules,
        redirect: { name: 'shop_settings_main_rules' },
        children: [
          {
            path: 'base',
            name: 'shop_settings_main_rules',
            component: MainRules,
          },
          {
            path: 'counter',
            name: 'shop_settings_counter_rules',
            component: CounterRules,
          },
        ],
      },
      {
        path: 'alerts',
        name: 'shop_settings_alerts',
        component: Alerts,
      },
      {
        path: 'report_rules',
        name: 'shop_settings_report_rules',
        component: ReportRules,
      },
      {
        path: 'absences',
        name: 'shop_settings_absences',
        component: Absences,
      },
      {
        path: 'postes',
        name: 'shop_settings_postes',
        component: Postes,
      },
      {
        path: 'shop',
        name: 'shop_settings_shop',
        component: ShopInfo,
      },
      {
        path: 'teams',
        name: 'shop_settings_teams',
        component: Teams,
      },
      {
        path: 'documents_management',
        name: 'shop_settings_documents_management',
        component: DocumentsManagement,
      },
      {
        path: 'automatic_planning',
        name: 'shop_settings_automatic_planning',
        redirect: { name: 'automatic_planning_rules' },
        component: AutomaticPlanning,
        children: [
          {
            path: 'rules',
            name: 'automatic_planning_rules',
            component: Rules,
          },
          {
            path: 'competencies',
            name: 'automatic_planning_competencies',
            component: Competencies,
          },
        ],
      },
      {
        path: 'punch_clock',
        name: 'shop_settings_punch_clock',
        component: PunchClock,
      },
      {
        path: 'details',
        name: 'shop_settings_details',
        component: SettingDetails,
        children: [
          {
            path: 'annualization',
            name: 'shop_settings_details_annualization',
            component: AnnualizationCounterDetails,
          },
        ],
      },
    ],
  },
];

export default shopRoutes;
