<template>
  <div class="employee-availabilities__form-row">
    <div class="input-container">
      <SkSelectV2
        v-model="availabilityStatus"
        :options="availabilityStatusOptions"
        :disabled="!canEdit"
      />
      <SkDatePicker
        v-if="occasionalAvailability"
        ref="datepicker"
        v-model="localDate"
        :errored="!localDate"
        :error-message="$t('errors.missing_value')"
        :clearable="false"
        :lang="$i18n.locale"
        append-to-body
        prevent-null
      />
      <SkInputGroup separator>
        <template #prepend>
          <SkTimePicker
            v-model="startsAt"
            :error-message="$t('employees.employee_availabilities_modal.invalid_time')"
            :interval-in-minutes="15"
            :disabled="!canEdit"
            center
          />
        </template>
        <template>-</template>
        <template #append>
          <SkTimePicker
            v-model="endsAt"
            :error-message="$t('employees.employee_availabilities_modal.invalid_time')"
            :interval-in-minutes="15"
            :disabled="!canEdit"
            center
          />
        </template>
      </SkInputGroup>
    </div>

    <SkCircleButton
      v-if="canEdit"
      v-tooltip
      :title="$t('employees.employee_availabilities_modal.delete_availability')"
      icon="TrashCanV2Icon"
      @click="deleteAvailability"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'AvailabilityInputRow',
  props: {
    availability: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availabilityStatusOptions: [{
        id: 'available',
        text: this.$t('employees.employee_availabilities_modal.available'),
      },
      {
        id: 'unavailable',
        text: this.$t('employees.employee_availabilities_modal.unavailable'),
      }],
    };
  },
  computed: {
    localDate: {
      get() {
        return skDate(this.availability.attributes.startsAt).format('YYYY-MM-DD');
      },
      set(newValue) {
        const startsAt = skDate(`${newValue} ${skDate(this.startsAt).format('HH:mm')}`).toDate();
        const endsAt = skDate(`${newValue} ${skDate(this.endsAt).format('HH:mm')}`).toDate();
        this.setEmployeeAvailabilityAttributes({
          attributes: { startsAt, endsAt },
          availability: this.availability,
        });
      },
    },

    canEdit() {
      return this.availability.attributes.canEdit;
    },

    occasionalAvailability() {
      return this.availability.attributes.recurrence === 'none';
    },

    availabilityStatus: {
      get() {
        return this.availability.attributes.status;
      },

      set(newValue) {
        this.setEmployeeAvailabilityAttributes({
          attributes: { status: newValue },
          availability: this.availability,
        });
      },
    },

    startsAt: {
      get() {
        return this.availability.attributes.startsAt;
      },

      set(newValue) {
        this.setEmployeeAvailabilityAttributes({
          attributes: { startsAt: newValue },
          availability: this.availability,
        });
      },
    },

    endsAt: {
      get() {
        return this.availability.attributes.endsAt;
      },

      set(newValue) {
        this.setEmployeeAvailabilityAttributes({
          attributes: { endsAt: newValue },
          availability: this.availability,
        });
      },
    },
  },
  methods: {
    ...mapMutations('employeeAvailabilities', ['setEmployeeAvailabilityAttributes']),
    deleteAvailability() {
      this.setEmployeeAvailabilityAttributes({
        attributes: { _destroy: true },
        availability: this.availability,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-availabilities__form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;

  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    ::v-deep .sk-datepicker {
      width: 140px !important;
      margin-right: 20px;

      &.sk-datepicker--errored {
        margin-top: 20px;
      }
    }

    ::v-deep .sk-select {
      width: 165px !important;
      padding-right: 20px;
    }

    ::v-deep .sk-input-group {
      width: 160px;

      ::v-deep .sk-input__input {
        padding: 0 !important;
      }
    }
  }
}
</style>
