<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58002 0 0 3.58002 0 8C0 12.42 3.58002 16 8 16C12.42 16 16 12.42 16 8C16 3.57998 12.42 0 8 0ZM12 10.868L10.868 12L8 9.13202L5.132 12L3.99998 10.868L6.86799 8L3.99998 5.132L5.132 3.99998L8 6.86799L10.868 3.99998L12 5.132L9.13202 8L12 10.868Z"
      fill="#d03e50"
    />
  </svg>
</template>

<script>
export default { name: 'CrossIcon' };
</script>
