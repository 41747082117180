<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';

import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'RedoAction',
  components: {
  },
  extends: GenericToolbarAction,
  props: {
    sourceType: {
      type: String, // 'Month' | 'Week'
      required: false,
      default: 'Week',
    },
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('planningsState', ['undoRedoLoading']),
    ...mapGetters('planningsState', [
      'isAtBeginningOfHistory',
      'isAtEndOfHistory',
      'monday',
      'sunday',
      'periodDates',
    ]),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.redo.label');
    },
    icon() {
      return 'ForwardArrowV2Icon';
    },
    visibleIn() {
      return 'toolbar';
    },
    disabled() {
      return this.isDataLoading || this.undoRedoLoading || this.isAtEndOfHistory;
    },
    dataTest() {
      return 'planning-toolbar__redo';
    },
    canDisplayUndoRedo() {
      return this.currentOrganisation.attributes.packOffer.plannings_undo_enabled;
    },
    isVisible() {
      return this.canDisplayUndoRedo;
    },
  },
  methods: {
    ...mapActions('planningsState', ['undoRedoAction']),
    handler() {
      const dates = this.periodDates(this.sourceType.toLowerCase());

      this.undoRedoAction({
        periodStartsAt: dates.startsAt,
        periodEndsAt: dates.endsAt,
        isRedo: true,
      }).then(() => {
        this.$skAnalytics.track('redo_btn_planning', { source: this.sourceType });
      })
        .catch(() => this.onFailure());
    },
  },
};
</script>
