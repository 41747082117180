<template>
  <SkModalV2
    id="conflict-workload-plan-modal"
    ref="conflictWorkloadPlanModal"
    :class="modalClass"
    :title="$t('workload_plan.modals.conflict.title')"
    @cancel="handleClose"
    @close="handleClose"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="conflict-workload-plan-modal">
          <div class="conflict-workload-plan-modal__info-card">
            <div class="conflict-workload-plan-modal__info-card__text">
              <span>{{ $t('workload_plan.modals.conflict.info_card.period.has_conflict') }}</span>
              <span class="conflict-workload-plan-modal__info-card__text__period">
                {{ displayedConflictPeriod }}
              </span>
              <div class="conflict-workload-plan-modal__info-card__text__actions">
                <!-- Here we should use the new SkTag from Orora -->
                <div class="conflict-workload-plan-modal__info-card__text__actions--tag">
                  <CrossIcon />
                  <span class="conflict-workload-plan-modal__info-card__text__actions--tag-content">
                    {{ conflictMessages }}
                  </span>
                </div>
                <SkOroraButton
                  class="conflict-workload-plan-modal__info-card__text__actions--link"
                  variant="tertiary"
                  @click="openDetails"
                >
                  {{ $t('workload_plan.modals.conflict.info_card.details') }}
                </SkOroraButton>
              </div>
            </div>
            <ConflictIcon />
          </div>
          <div class="conflict-workload-plan-modal__options">
            <span class="conflict-workload-plan-modal__options__title">
              {{ $t('workload_plan.modals.conflict.options.title') }}
            </span>
            <div class="conflict-workload-plan-modal__options__selects">
              <div
                v-for="(option, index) in options"
                :key="index"
                class="conflict-workload-plan-modal__options__selects__item"
              >
                <SkRadio
                  v-model="selectedOption"
                  class="conflict-workload-plan-modal__options__selects__item--radio"
                  :data-value="option.value"
                />
                <div class="conflict-workload-plan-modal__options__selects__item--label">
                  <span> {{ option.title }} </span>
                  <span
                    class="conflict-workload-plan-modal__options__selects__item--label__description"
                  >
                    {{ option.description }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        data-test="submit_workload_conflict"
        :loading="isConflictLoading"
        @click="handleConflicts"
      >
        {{ $t('workload_plan.modals.conflict.action.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';
import { capitalize } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';
import ConflictIcon from './ConflictIcon';
import CrossIcon from './CrossIcon';

export default {
  name: 'ConflictWorkloadPlanModal',
  components: { ConflictIcon, CrossIcon },
  data() {
    return {
      isConflictLoading: false,
      selectedOption: 'save_only_non_conflict_values',
      options: [
        {
          value: 'save_only_non_conflict_values',
          title: this.$i18n.t('workload_plan.modals.conflict.options.keep.title'),
          description: this.$i18n.t('workload_plan.modals.conflict.options.keep.description'),
        },
        {
          value: 'save_with_conflict_values',
          title: this.$i18n.t('workload_plan.modals.conflict.options.replace.title'),
          description: this.$i18n.t('workload_plan.modals.conflict.options.replace.description'),
        },
      ],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['postes']),
    ...mapGetters('planningsWorkloadPlans', [
      'numberOfPostesAndConflicts',
      'workloadPlanToSaveAfterMergeConflicts',
    ]),
    ...mapGetters('planningsWorkloadPlans', ['fetchParamsByDay']),
    ...mapState('planningsWorkloadPlans', [
      'period',
      'conflictsWorkloadPlans',
      'isConflictSidePanelOpen',
    ]),

    conflictMessages() {
      const numberOfPostesInConflict =
        this.numberOfPostesAndConflicts.numberOfPostesInConflict || 0;
      const numberOfConflicts = this.numberOfPostesAndConflicts.numberOfConflicts || 0;

      const conflicts = this.$tc('workload_plan.modals.conflict.info_card.conflicts', numberOfConflicts);
      const positions = this.$tc('workload_plan.modals.conflict.info_card.postes', numberOfPostesInConflict);

      return `${numberOfConflicts} ${conflicts} - ${numberOfPostesInConflict} ${positions}`;
    },
    displayedConflictPeriod() {
      const startDate = this.period?.start;
      const endDate = this.period?.end;

      if (!startDate || !endDate) return null;
      if (startDate === endDate) return capitalize(skDate(startDate).format('dddd Do MMMM YYYY'));

      return this.$i18n.t(
        'workload_plan.modals.conflict.info_card.period.date',
        {
          start_date: skDate(startDate).format('DD/MM/YYYY'),
          end_date: skDate(endDate).format('DD/MM/YYYY'),
        },
      );
    },
    modalClass() {
      return {
        'conflict-workload-plan-modal-container--sidepanel-opened': this.isConflictSidePanelOpen,
      };
    },
  },
  methods: {
    ...mapMutations('planningsWorkloadPlans', ['toggleConflictSidePanel']),
    ...mapActions('planningsWorkloadPlans', [
      'upsertWorkloadPlans',
      'setDefaultSelectedPosteInLocalStorage',
      'fetchWorkloadPlans',
    ]),

    handleConflicts() {
      if (this.isConflictLoading) return;

      if (this.selectedOption === 'save_only_non_conflict_values') {
        this.saveWorkloadPlans(this.conflictsWorkloadPlans.workloadPlansWithoutConflict);
      } else {
        this.saveWorkloadPlans(this.workloadPlanToSaveAfterMergeConflicts);
      }
    },
    saveWorkloadPlans(workloadPlans) {
      if (Object.keys(workloadPlans).length === 0) {
        this.handleSuccess(workloadPlans);
        return;
      }
      this.isConflictLoading = true;

      this.upsertWorkloadPlans(workloadPlans)
        .then(() => this.handleSuccess(workloadPlans))
        .catch(() => {
          this.$skToast({
            message: this.$t('workload_plan.modals.errors.generic'),
            variant: 'error',
          });
        })
        .finally(() => { this.isConflictLoading = false; });
    },
    displaySuccessToast() {
      this.$skToast({
        message: this.$t('workload_plan.modals.conflict.success'),
        variant: 'success',
      });
    },
    handleClose(event) {
      // prevent the modal from closing when click on close or cancel
      event.preventDefault();

      this.$root.$emit('confirm', event, {
        title: this.$t('workload_plan.modals.conflict.confirm.title'),
        description: this.$t('workload_plan.modals.conflict.confirm.description'),
        actionText: this.$t('workload_plan.modals.conflict.confirm.action_text'),
        onConfirm: () => {
          this.closeModal();
        },
      });
    },
    handleSuccess(workloadPlans) {
      this.displaySuccessToast();
      this.setDefaultSelectedPosteInLocalStorage(workloadPlans);
      this.closeModal();
      this.refetchOrRedirectToDate();
    },
    closeModal() {
      this.toggleConflictSidePanel(false);
      this.$refs.conflictWorkloadPlanModal.hide();
    },
    refetchOrRedirectToDate() {
      const date = this.period?.start;
      const formattedDate = skDate(date).format('YYYY-MM-DD');

      const currentQuery = this.$router.currentRoute.query;

      // Force reload of the page in case the date has not changed
      if (currentQuery.date === formattedDate) {
        const posteIds = this.postes.map(poste => poste.id);

        this.fetchWorkloadPlans(
          this.fetchParamsByDay(formattedDate, posteIds),
        );
      } else {
        this.$router.replace({ query: { date: formattedDate } });
      }
    },
    getWorkloadPlanToSaveFromConflicts() {
      return this.workloadPlansFromConflictToSave;
    },
    openDetails() {
      this.toggleConflictSidePanel(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.conflict-workload-plan-modal-container--sidepanel-opened {
  transform: translateX(-133px);
}

.conflict-workload-plan-modal {
  display: flex;
  flex-direction: column;

  &__info-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: solid 1px $sk-grey-10;
    border-radius: 4px;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__period {
        margin-top: 16px;
        font-size: $fs-text-l;
      }

      &__actions {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--tag {
          display: flex;
          align-items: center;
          border-radius: 18px;
          background-color: $sk-error-10;
          color: $sk-black;
          padding: 8px 16px;

          &-content {
            margin-left: 8px;
          }
        }

        &--link {
          margin-left: 32px;
        }
      }
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    &__title {
      font-size: $fs-text-m;
      font-weight: $fw-semi-bold;
    }

    &__selects {
      padding-left: 16px;
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        flex-direction: row;
        margin-top: 24px;

        &--radio {
          // this -3px is due to the padding inside the SkRadio component
          margin-top: -3px;
        }

        &--label {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          font-size: $fs-text-m;

          &__description {
            font-size: $fs-text-s;
            color: $sk-grey;
          }
        }
      }
    }
  }
}
</style>
