<template>
  <!-- eslint-disable max-len -->
  <SkSelectV2
    v-model="selectedPostes"
    :label="$t(`employees.add_employee_modal.affiliations.user_license_table.fields.postes.title_${type}`)"
    :disabled="disabled"
    :disabled-options="disabledOptions"
    :ajax-format-response="postesAjaxFormatResponse"
    :ajax-url="fetchPostesUrl"
    :ajax-client="ajaxClient"
    :group-options="selectAll"
    :class="skSelectWidthClasses"
    :no-results-label="$t('employees.add_employee_modal.affiliations.user_license_table.fields.postes.no_available')"
    :search-placeholder="$t('labels.search')"
    append-to-body
    multi
    object-value
    no-confirm
  >
    <template #empty-option>
      <div class="competencies-input__empty-option">
        {{ $t('employees.add_employee_modal.affiliations.user_license_table.fields.postes.no_available') }}
      </div>
    </template>
    <template #selected-option>
      {{ numberPostesSelectedLabel }}
    </template>
    <template #option="{ option }">
      <div class="competencies-input__option">
        <div class="competencies-input__poste-name text-truncate">
          <div
            :ref="`optionText_${option.id}`"
            v-tooltip="overflowTooltip"
            class="competencies-input__title-text"
            @mouseenter="setOverflowTooltip(option)"
          >
            {{ option.text }}
          </div>
        </div>
        <div
          class="competencies-input__vignette"
          :style="vignetteStyle(option)"
        />
      </div>
    </template>
  </SkSelectV2>
</template>

<script>
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'PostesSelect',
  props: {
    shopId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    disabledOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      postes: [],
      ajaxClient: () => httpClient,
      selectAll: [{ id: 'all', text: 'Tous', matchKey: '*' }],
      overflowTooltip: null,
    };
  },
  computed: {
    fetchPostesUrl() {
      return `/v3/api/shops/${this.shopId}/postes`;
    },
    numberPostesSelectedLabel() {
      return this.$tc('employees.tabs.personal_data.assignment.assignment_card.fields.postes.label', this.value.length);
    },
    selectedPostes: {
      get() {
        return this.value;
      },
      set(newPostes) {
        this.$emit('input', newPostes);
      },
    },
    skSelectWidthClasses() {
      const skSelectClass = {
        'competencies-input__select__primary': true,
      };
      if (this.size) {
        const key = `competencies-input__select__primary--${this.size}`;
        skSelectClass[key] = true;
      }
      return skSelectClass;
    },
  },
  methods: {
    postesAjaxFormatResponse(response) {
      this.postes = response.data;

      return response.data.map(poste => ({
        id: poste.id,
        text: poste.attributes.name,
        attributes: {
          color: poste.attributes.color,
        },
      }));
    },
    skSelectSize(size) {
      return this.size === size;
    },
    vignetteStyle(option) {
      return {
        'background-color': option.attributes.color,
      };
    },
    // managing tooltip reactivity is complex:
    // We need ref to get element size
    // but ref is only available when ajax fetch is over
    // and component has finished building options.
    // It's easier to just use @mouseenter to ensure that option is fully loaded
    setOverflowTooltip(option) {
      const isCut = this.$refs[`optionText_${option.id}`] &&
        this.$refs[`optionText_${option.id}`].offsetWidth < this.$refs[`optionText_${option.id}`].scrollWidth;
      this.overflowTooltip = isCut ? option.text : null;
    },
  },
};

</script>

<style lang="scss" scoped>
.competencies-input__select__primary {
  margin-bottom: 10px;
  min-width: 220px;

  &--x-small {
    min-width: 151px !important;
  }
}

.competencies-input__select__primary:last-child {
  margin-bottom: 0;
}

::v-deep .sk-select__dropdown-menu {
  width: 350px;
}

.competencies-input__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
}

.competencies-input__title-text {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.competencies-input__poste-name {
  width: 138px;
  padding-right: 8px;
}

.competencies-input__vignette {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.sk-list-item--disabled .competencies-input__vignette {
  opacity: .4;
}

.competencies-input__empty-option {
  min-width: 137px;
}
</style>
