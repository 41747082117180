import axios from 'axios';
import { createConsumer } from '@anycable/web';

export default class ActionCableWebsocketClient {
  async init() {
    this._cable = await this.initCableConsumer();
    this._subscriptions = [];
  }

  subscribe({ channel, channelOptions, callback }) {
    this.verifyCable();

    const newSubscription = this._cable.subscriptions.create(
      { channel, ...channelOptions },
      { received: callback },
    );

    this._subscriptions.push(newSubscription);
  }

  unsubscribeAll() {
    this.verifyCable();

    this._subscriptions.forEach(
      subscription => { subscription.unsubscribe(); },
    );
  }

  disconnect() {
    this.verifyCable();

    this._cable.disconnect();
  }

  async initCableConsumer() {
    /*
    ** NOTE: the option tokenRefresher below comes from the anyCable library
    ** and is not native to ActionCable.
    ** It will be triggered by a server's message of this form:
    ** {"type":"disconnect","reason":"token_expired","reconnect":false}
    ** see: app/channels/application_cable/connection.rb
    */
    return createConsumer(await this.buildUrl(), {
      tokenRefresher: async transport => {
        await this.setToken();

        // Update URL for the underlying transport
        transport.setURL(await this.buildUrl());
      },
    });
  }

  async setToken() {
    const response = await axios.get('/v3/users/sessions/stateless_token', {
      validateStatus: status => status === 200,
    });

    if (!response.data?.token) {
      throw new Error('Cannot fetch token');
    }
    this.token = response.data?.token;
  }

  async buildUrl() {
    if (!this.token) {
      await this.setToken();
    }

    const baseUrl = '/cable';

    return `${baseUrl}?token=${this.token}`;
  }

  verifyCable() {
    if (!this._cable) {
      throw new Error('Cable consumer is not initialized');
    }
  }
}
