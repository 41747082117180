<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.1666 10.8333H10.8333V29.1666H29.1666V10.8333Z"
      fill="white"
    />
    <path
      d="M21.3999 17.4333L22.2399 18.6333L23.5599 17.6733V24.6333H24.9999V15.5133H23.7999L21.3999 17.4333Z"
      fill="#1E88E5"
    />
    <path
      d="M19.1192 19.7875C19.6401 19.3092 19.9634 18.6458 19.9634 17.9133C19.9634 16.4575 18.6859 15.2733 17.1159 15.2733C15.7809 15.2733 14.6392 16.1141 14.3409 17.3175L15.7217 17.6683C15.8592 17.115 16.4451 16.7133 17.1159 16.7133C17.9009 16.7133 18.5401 17.2516 18.5401 17.9133C18.5401 18.575 17.9009 19.1133 17.1159 19.1133H16.2851V20.5533H17.1159C18.0167 20.5533 18.7767 21.1792 18.7767 21.92C18.7767 22.6733 18.0551 23.2866 17.1676 23.2866C16.3659 23.2866 15.6809 22.7783 15.5726 22.105L14.1667 22.335C14.3851 23.6983 15.6751 24.7267 17.1667 24.7267C18.8392 24.7267 20.2001 23.4675 20.2001 21.92C20.2001 21.0675 19.7801 20.3025 19.1192 19.7875Z"
      fill="#1E88E5"
    />
    <path
      d="M28.3333 35H11.6666L10.8333 31.6666L11.6666 28.3333H28.3333L29.1666 31.6666L28.3333 35Z"
      fill="#FBC02D"
    />
    <path
      d="M31.6666 29.1666L34.9999 28.3333V11.6666L31.6666 10.8333L28.3333 11.6666V28.3333L31.6666 29.1666Z"
      fill="#4CAF50"
    />
    <path
      d="M28.3333 11.6667L29.1667 8.33333L28.3333 5H7.5C6.11917 5 5 6.11917 5 7.5V28.3333L8.33333 29.1667L11.6667 28.3333V11.6667H28.3333Z"
      fill="#1E88E5"
    />
    <path
      d="M28.3333 28.3333V35L34.9999 28.3333H28.3333Z"
      fill="#E53935"
    />
    <path
      d="M32.4999 5H28.3333V11.6667H34.9999V7.5C34.9999 6.11917 33.8808 5 32.4999 5Z"
      fill="#1565C0"
    />
    <path
      d="M7.5 35H11.6667V28.3333H5V32.5C5 33.8808 6.11917 35 7.5 35Z"
      fill="#1565C0"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoogleIcon',
  props: {
    height: {
      type: String,
      default: '40',
    },
    width: {
      type: String,
      default: '40',
    },
  },
};
</script>
