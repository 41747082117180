import Profile from './Profile.vue';
import Calendar from './components/calendar/Calendar.vue';
import ChangePassword from './components/change_password/ChangePassword.vue';
import Documents from './components/documents/Documents.vue';
import HrFile from './components/hr_file/HrFile.vue';
import Information from './components/information/Information.vue';
import Notifications from './components/notifications/Notifications.vue';

export const profileRoutes = [
  {
    path: '/v3/profile',
    redirect: { name: 'profile_information' },
    component: Profile,
    name: 'profile',
    children: [
      {
        path: 'information',
        name: 'profile_information',
        component: Information,
      },
      {
        path: 'hr_file',
        name: 'profile_hr_file',
        component: HrFile,
      },
      {
        path: 'documents',
        name: 'profile_documents',
        component: Documents,
      },
      {
        path: 'notifications',
        name: 'profile_notifications',
        component: Notifications,
      },
      {
        path: 'calendar',
        name: 'profile_calendar',
        component: Calendar,
      },
      {
        path: 'change_password',
        name: 'profile_change_password',
        component: ChangePassword,
      },
    ],
  },
];
