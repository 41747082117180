<template>
  <ErrorPage v-if="isError" />
  <div
    v-else
    class="planning--wrapper"
  >
    <Toolbar>
      <div class="toolbar--wrapper">
        <ToolbarDatePicker :current-date="currentDate" />
        <SkOroraButton
          v-if="!isAllShopsView"
          @click="$router.push({ name: 'plannings_employee_all' })"
        >
          {{ $t('employee_view.toolbar.all_shops_button') }}
        </SkOroraButton>
      </div>
    </Toolbar>
    <div class="planning--wrapper">
      <router-view @error-happened="handlePageError" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Toolbar from '@app-js/shared/components/Toolbar';
import ToolbarDatePicker from '@app-js/shared/components/Toolbar/ToolbarDatePicker';
import ErrorPage from '@app-js/shared/components/ErrorPage';

export default {
  name: 'EmployeePlannings',
  components: { Toolbar, ToolbarDatePicker, ErrorPage },
  data() {
    return {
      isError: false,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['currentDate']),
    isAllShopsView() {
      return this.$route.name === 'plannings_employee_all';
    },
  },
  methods: {
    handlePageError(error) {
      this.isError = true;

      setTimeout(() => {
        window.location = '/';
      }, 100);

      throw error;
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar--wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}
.planning--wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
