<template>
  <OuterColumn
    :title="periodTitle"
    :is-closed="period.isClosed"
    :is-previsional="period.isPrevisional"
    v-on="$listeners"
  >
    <template v-if="period.isClosed">
      <TableCell
        v-for="index in tableLinesCount"
        :key="`cell${index}`"
        :is-empty="period.isEmpty"
        :show-dash="period.showDash"
        :width="cellWidth"
        height="50px"
      />
    </template>
    <slot v-else />
  </OuterColumn>
</template>

<script>
import {
  OuterColumn,
  TableCell,
} from '../../shared/components';

export default {
  name: 'RttCounterYearColumn',
  components: { OuterColumn, TableCell },
  props: {
    period: {
      type: Object,
      default: null,
    },
  },
  data() {
    return { tableLinesCount: 4 };
  },
  computed: {
    cellWidth() {
      // cell is thinner when not displaying a period but just a year
      return this.isPeriodOverTwoYears ? '100px' : '60px';
    },
    isPeriodOverTwoYears() {
      return this.period.startDate.year() !== this.period.endDate.year();
    },
    periodTitle() {
      return this.period.isClosed ? this.closedPeriodTitle : this.openedPeriodTitle;
    },
    closedPeriodTitle() {
      return this.isPeriodOverTwoYears ?
        `${this.period.startDate.year()} - ${this.period.endDate.year()}` :
        `${this.period.startDate.year()}`;
    },
    openedPeriodTitle() {
      return this.$t('employees.tabs.counters.rtt_counter.table.year_header', {
        startDate: this.period.startDate.format('DD/MM/YYYY'),
        endDate: this.period.endDate.format('DD/MM/YYYY'),
      });
    },
  },
};
</script>
