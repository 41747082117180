<template>
  <SkDropdown
    ref="dropdown"
    prevent-overflow
    placement="top"
    trigger="click"
    class="quick-add-absence__quick-select-wrapper"
  >
    <template #anchor>
      <SkOroraButton
        class="add-absence-button"
        variant="tertiary"
        size="small"
      >
        {{ $t('plannings.table.absence_row.new_button') }}
      </SkOroraButton>
    </template>
    <template #menu>
      <div class="quick-add-absence__quick-select__list">
        <div
          v-for="item in absencesItems"
          :key="item.id"
          class="quick-add-absence__quick-select__option quick-select__ellipsis"
          @click="onAbsenceItemClick(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'QuickAddAbsence',
  computed: {
    ...mapGetters('planningsPostes', ['absencesForQuickAdd']),
    absencesItems() {
      return this.absencesForQuickAdd.map(absence => ({
        id: absence.id,
        name: absence.attributes.name,
      }));
    },
  },
  methods: {
    ...mapMutations('planningsPostes', ['addToquickSelectedAbsenceIds']),
    onAbsenceItemClick(id) {
      this.$refs.dropdown.hide();
      this.addToquickSelectedAbsenceIds({ id });
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-add-absence__quick-select-wrapper {
  position: relative;
  cursor: pointer;
  z-index: auto !important;
  padding: 0;

  ::v-deep .sk-dropdown__menu {
    width: 99%;
  }

  .quick-select__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .quick-add-absence__quick-select__list {
    display: block;
    max-height: 200px;
    padding: 4px 8px;
    text-align: initial;
    color: $sk-black;
    background-color: white;
    border-radius: 2px;
    overflow-y: auto;
    box-shadow: 0 6px 16px 0 #0000001f;
  }

  .quick-add-absence__quick-select__option {
    display: block;
    font-family: Gellix, sans-serif;
    font-size: 1.2em;
    line-height: 24px;

    &:hover {
      font-weight: bold;
    }
  }
}
</style>
