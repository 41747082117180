<template>
  <!-- eslint-disable  max-len -->
  <div>
    <SkListItem
      :style="styleObject"
      :disabled="isCancelledShop || isPrimaryShop"
      class="cluster-node-select__list-item"
      @click.stop.self="handleClickOnNode"
    >
      <template #left>
        <div class="cluster-node-select__list-item__checkbox">
          <SkCheckBox
            :id="String(clusterNode.id)"
            v-tooltip.top="clusterNode.attributes.tooltip"
            :value="ancestorChecked || childrenChecked || checked"
            :disabled="isCancelledShop || isPrimaryShop || !isEditable"
            :indeterminate="indeterminateClusterNode"
            @input="handleSelect"
          />
        </div>
      </template>
      <div
        v-tooltip="nodeHoverTooltip(clusterNode)"
        class="cluster-node-list__item__right"
        @click.stop="handleClickOnNode"
      >
        <div
          v-if="hasChildren"
          class="cluster-node-list__item__caret-container"
        >
          <ChevronRightIcon
            :class="caretClass"
            width="14px"
            height="9px"
            fill=""
            class="caret-svg"
          />
        </div>
        <div v-if="!isNode">
          <SkMedallion
            icon="ShopIcon"
            background-color="#d9e6ff"
            class="shops-list__item-icon"
            color="#2b66fe"
          />
          <div
            v-if="isPrimaryShop"
            class="shops-list__item-thumbtack"
          >
            <ThumbtackV2Icon
              width="12"
              height="12"
              fill=""
              class="shops-list__item-thumbtack__fill"
            />
          </div>
        </div>
        <div>
          {{ clusterNode.attributes.name }}
        </div>
      </div>
    </SkListItem>
    <div v-if="hasChildren && nodeOpen">
      <ClusterNodeItem
        v-for="child in childrens"
        :key="child.id"
        :cluster-node="child"
        :cluster-nodes="clusterNodes"
        :checked-nodes="checkedNodes"
        :handle-height="handleHeight"
        :user="user"
      />
    </div>
  </div>
</template>

<script>
import clone from 'lodash/clone';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

export default {
  name: 'ClusterNodeItem',
  props: {
    clusterNode: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    clusterNodes: {
      type: Array,
      default: null,
    },
    checkedNodes: {
      type: Array,
      default: null,
    },
    searchQuery: {
      type: String,
      default: null,
    },
    handleHeight: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      nodeOpen: false,
      localCheckedNodes: clone(this.checkedNodes),
    };
  },
  computed: {
    childrens() {
      return this.clusterNodes.filter(clusterNode => (
        clusterNode.attributes.parentId === this.clusterNode.id
      ));
    },
    activeChildren() {
      return this.clusterNodes.filter(clusterNode => (
        clusterNode.attributes.parentId === this.clusterNode.id &&
        (clusterNode.attributes.shopId !== null && !clusterNode.attributes.shopCancelled)
      ));
    },
    checkedNodeIds() {
      return this.localCheckedNodes.map(clusterNode => clusterNode.id);
    },
    childrensIds() {
      return this.childrens.map(clusterNode => clusterNode.id);
    },
    checked() {
      return this.checkedNodeIds.includes(this.clusterNode.id);
    },
    ancestorChecked() {
      if (this.clusterNode.attributes.shopCancelled) return false;
      return this.checkedNodeIds.find(checkedNodeId => (
        this.clusterNode.attributes.ancestorIds.includes(Number(checkedNodeId))
      )) !== undefined;
    },
    childrenChecked() {
      const activeChildrenIds = this.activeChildren.map(activeNode => activeNode.id);

      if (activeChildrenIds.length === 0) return false;
      const childrenIds = activeChildrenIds.map(id => Number(id));

      return isEqual(
        sortBy(
          intersection(this.checkedNodeIds, activeChildrenIds)
            .map(id => Number(id)),
        ),
        sortBy(childrenIds),
      );
    },
    indeterminateClusterNode() {
      const key = `${this.clusterNode.attributes.ancestry}/${this.clusterNode.id}`;

      if (this.childrenChecked || this.checked) return false;

      return !this.childrenChecked && !!this.localCheckedNodes.find(checkedNode => (
        checkedNode.attributes.ancestry &&
        checkedNode.attributes.ancestry.startsWith(key)
      ));
    },
    caretClass() {
      return { open: this.nodeOpen };
    },
    isCancelledShop() {
      return this.clusterNode.attributes.shopCancelled;
    },
    isPrimaryShop() {
      return String(this.clusterNode.attributes.shopId) === String(this.user.attributes.shopId);
    },
    hasChildren() {
      return this.isNode && this.childrens.length > 0;
    },
    isNode() {
      return !this.clusterNode.attributes.shopId;
    },
    styleObject() {
      let paddingLeft;

      if (!this.searchQuery) {
        // Fix values comming from design caret must be below checkbox
        paddingLeft = 15 + (this.clusterNode.attributes.depth * 32);
      } else {
        paddingLeft = 47;
      }

      return {
        'padding-left': `${paddingLeft}px`,
      };
    },
    isEditable() {
      return this.clusterNode.attributes.editable;
    },
  },
  watch: {
    checkedNodes(newValue) {
      if (newValue !== this.localCheckedNodes) {
        this.localCheckedNodes = clone(newValue);
      }
    },
  },
  methods: {
    handleClickOnNode() {
      this.handleHeight(this.childrens.length * 45);

      this.nodeOpen = !this.nodeOpen;
    },
    handleSelect() {
      this.emitOnRoot('selectNode', this.clusterNode);
      if (this.searchQuery) {
        this.emitOnRoot('clearQuery');
      }
    },
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.cluster-node-select__list-item {
  padding-left: 15px;

  .sk-list-item__middle {
    width: 100%;
    height: 100%;
  }
}

.cluster-node-list__item__right {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.cluster-node-select__list-item__checkbox {
  margin-right: 10px;
}

.cluster-node-list__item__caret-container {
  margin-right: 10px;
}

.add-employee-modal__profile-section-header {
  display: flex;
  justify-content: space-between;
}

.caret-svg {
  fill: black;

  &.open {
    transform: rotate(90deg);
  }
}
</style>
