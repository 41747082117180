<template>
  <div
    v-if="!loading && !isShopAnnualizationConfigLoading && !areEmployeeAnnualizationConfigsLoading"
    class="full-contract__container"
  >
    <div class="full-contract__wrapper">
      <div class="full-contract__sub-wrapper">
        <div class="full-contract__main">
          <div :class="fullContractHeaderClasses">
            <Header />
          </div>
          <ContractData :is-extended-view="isExtendedView" />
          <SalaryData
            :is-extended-view="isExtendedView"
            :updated-cyclic-amendments="updatedCyclicAmendments"
            @cyclic-amendment-input="handleCyclicAmendmentInput"
          />
          <div
            v-if="isContractCreationView || isContractVersionView"
            :class="buttonContainerClasses"
          >
            <SkOroraButton
              v-show="!isContractVersionView"
              variant="tertiary"
              @click="toggleExtendedView"
            >
              <span v-if="isExtendedView">
                {{ $t('employees.tabs.contracts.actions.show_infos.less') }}
              </span>
              <span v-else>
                {{ $t('employees.tabs.contracts.actions.show_infos.more') }}
              </span>
              <CaretIcon
                :class="caretClasses"
                fill="#2b66fe"
              />
            </SkOroraButton>
            <SkOroraButton
              v-if="isContractVersionView"
              :disabled="hasContractError || isPcsEsePending"
              :loading="loadingVersion"
              @click="handleVersionContract"
            >
              {{ $t('employees.tabs.contracts.actions.version.label') }}
            </SkOroraButton>
            <SkOroraButton
              v-else
              v-track="'multicontract_create_contract_button'"
              :disabled="hasContractError || isPcsEsePending"
              :loading="loadingCreate"
              data-test="create_contract_button_submit"
              @click="handleCreateContract"
            >
              {{ $t('employees.tabs.contracts.actions.create.label') }}
            </SkOroraButton>
          </div>
        </div>
        <ContractSidebar
          v-if="isContractReadOnlyView && canEditContract"
          is-sticky
        />
      </div>

      <StickyBar
        v-tooltip.auto="hasContractError ? $t('employees.sticky_bar.tooltip') : ''"
        :visible="isSubmitButtonDisplayed"
        :submit-spinner="loadingUpdate || areEmployeeAnnualizationConfigsUpdateLoading"
        :submit-button-label="$t('employees.sticky_bar.submit')"
        :disabled="hasContractError || isPcsEsePending"
        class="employee-sticky-bar"
        container-scroll-id="employees__container"
        @submit="onClickSubmit"
      />

      <MountingPortal
        mount-to="#modals-portal"
        append
      >
        <create-text-document-modal
          apply-on-selected-contract
        />
        <duplicate-contract-modal />
        <VariableHoursHistoryModal />
      </MountingPortal>
    </div>
  </div>
  <div
    v-else
    class="full-contract__loader"
  >
    <SkLoader size="x-large" />
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import skDate from '@skello-utils/dates';

import StickyBar from '@app-js/shared/components/Stickybar';
import CreateTextDocumentModal from '@app-js/users/shared/components/CreateTextDocumentModal';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';

import DuplicateContractModal from '../DuplicateContractModal';
import ContractData from './ContractData';
import VariableHoursHistoryModal from './VariableHoursHistoryModal';
import ContractSidebar from '../ContractSidebar';
import Header from './Header';
import SalaryData from './SalaryData';

export default {
  name: 'FullContract',
  components: {
    ContractData,
    ContractSidebar,
    CreateTextDocumentModal,
    DuplicateContractModal,
    Header,
    SalaryData,
    StickyBar,
    VariableHoursHistoryModal,
  },
  mixins: [payrollPreparationMixins],
  beforeRouteLeave(to, from, next) {
    // this confirm modal must not be shown after clicking on 'create' button
    if (this.hasContractChanges && !to.params.skipWarning &&
      from.params.user_id === to.params.user_id) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.squashChangesOnContract();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      showHeaderShadow: false,
      isExtendedViewToggled: false,
      areCyclicAmendmentsEdited: false,
      updatedCyclicAmendments: [],
      hasMissingFieldsBeforeEdit: false,
    };
  },
  computed: {
    ...mapState('contracts', ['contract', 'originalContract', 'loading', 'loadingUpdate', 'loadingCreate', 'loadingVersion']),
    ...mapState('amendments', ['amendments', 'cyclicAmendments']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', [
      'employee',
      'originalEmployeeData',
      'areEmployeeAnnualizationConfigsLoading',
      'areEmployeeAnnualizationConfigsUpdateLoading',
      'employeeAnnualizationConfig',
    ]),
    ...mapState('annualization', ['isShopAnnualizationConfigLoading']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentOrganisation', ['isMulticontractsEnabled']),
    ...mapGetters('contracts', [
      'hasContractChanges',
      'hasContractError',
      'isActive',
      'isContractCreationView',
      'isContractReadOnlyView',
      'isContractVersionView',
    ]),
    ...mapGetters('selectedEmployee', [
      'isCurrentUserOrStrictSubordinate',
      'hasAnnualizationTotalChanged',
      'hasChangesOnHrFile',
    ]),
    ...mapGetters('currentLicense', ['canManageEmployeeAmendments', 'canEditEmployeeInfo']),
    ...mapGetters('currentUser', ['fullName', 'impersonate']),
    ...mapGetters('employees', ['displayPayrollPreparation', 'canAccessPayrollEmployee']),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable', 'isAnnualizedWorkingTimeAvailable', 'isDevFlagEnabled']),

    isExtendedView() {
      return !this.isContractCreationView || this.isExtendedViewToggled;
    },

    fullContractHeaderClasses() {
      return {
        'full-contract__header': true,
        'full-contract__header--shadowed': this.showHeaderShadow,
      };
    },
    employeesContainer() {
      return document.getElementById('employees__container');
    },
    caretClasses() {
      return {
        'px-2': true,
        'caret--down': this.isExtendedView,
        'caret--up': !this.isExtendedView,
      };
    },
    buttonContainerClasses() {
      return {
        'full-contract__new-page__buttons-container': true,
        'full-contract__new-page__buttons-container--version': this.isContractVersionView,
      };
    },
    canManageAmendments() {
      return this.canManageEmployeeAmendments && this.isCurrentUserOrStrictSubordinate;
    },
    canEditContract() {
      return this.canEditEmployeeInfo && this.isCurrentUserOrStrictSubordinate;
    },
    isSubmitButtonDisplayed() {
      const areInputsEdited =
        this.hasContractChanges ||
        this.areCyclicAmendmentsEdited ||
        this.hasAnnualizationTotalChanged ||
        this.hasChangesOnHrFile;

      return !this.isContractCreationView &&
        !this.isContractVersionView &&
        !this.areAnyCyclicAmendmentsErrored &&
        areInputsEdited;
    },
    areAnyCyclicAmendmentsErrored() {
      return this.updatedCyclicAmendments
        .some(amendment => !!amendment.hasError);
    },
    isUpgradeMultiContractsEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_UPGRADE_MULTICONTRACTS');
    },
    hasModifiedExistingValues() {
      const original = this.originalContract.attributes;
      const modified = this.contract.attributes;

      return Object.keys(original)
        .some(key => (!!original[key] || original[key] === 0) && original[key] !== modified[key]);
    },
    // Until both dropdown have been selected we want to prevent the update
    isPcsEsePending() {
      return this.contract.attributes.pcsEse?.length === 2;
    },
  },
  created() {
    // This is to prevent the user to access the routes directly through urls
    if (!this.isMulticontractsEnabled &&
      this.$route.params.contract_id !== this.contract.id &&
      this.$route.name !== 'user_full_contract'
    ) {
      this.$router.push({ name: 'user_contracts', params: { skipWarning: true } });
      return;
    }

    // can not rely on the navigationStatus.isCreationMode in case page is refreshed
    // on landing on this page, we set the navigation status
    if (this.$route.name === 'user_full_contract_new') {
      this.setDefaultAttributesToNewContract();
      this.resetAmendments();
      return;
    }
    if (this.$route.name === 'user_full_contract_version' && this.isUpgradeMultiContractsEnabled) {
      const { startDate, endDate } = this.$route.params;
      this.setDefaultAttributesToVersionContract({ startDate, endDate });
      this.resetAmendments();
      return;
    }

    this.hasMissingFieldsBeforeEdit = this.hasMissingMandatoryFieldsForPayroll('user_contracts') && this.displayPayrollPreparation;
    this.fetchContract({
      employeeId: this.$route.params.user_id,
      contractId: this.$route.params.contract_id,
    });

    if (this.canManageAmendments) {
      this.handleFetchAmendments();
    }
  },
  async mounted() {
    await this.fetchAnnualizationsConfigs();

    if (this.employeesContainer) {
      this.employeesContainer.addEventListener('scroll', this.toggleHeaderShadowOnScroll);
    }

    this.toggleHeaderShadowOnScroll();
  },
  destroyed() {
    if (this.employeesContainer) {
      this.employeesContainer.removeEventListener('scroll', this.toggleHeaderShadowOnScroll);
    }
  },
  methods: {
    ...mapActions('employees', ['reloadSidebar', 'fetchUserMissingAttributes']),
    ...mapActions('annualization', ['fetchShopAnnualizationConfig']),
    ...mapActions('amendments', ['fetchAmendments', 'bulkUpdateAmendments']),
    ...mapActions('contracts', [
      'fetchContract',
      'updateContract',
      'createContract',
      'createVersionContract',
    ]),
    ...mapActions('selectedEmployee', [
      'fetchEmployeeAnnualizationConfig',
      'updateEmployeeAnnualizationConfig',
      'updateSelectedEmployeeHr',
    ]),
    ...mapMutations('annualization', ['setPeriodAt', 'resetAnnualizationState']),
    ...mapMutations('amendments', ['resetAmendments']),
    ...mapMutations('selectedEmployee', ['squashOriginalEmployee', 'resetOriginalEmployeeAnnualizationConfig']),
    ...mapMutations('contracts', [
      'squashChangesOnContract',
      'setContractAttributes',
      'setDefaultAttributesToNewContract',
      'setDefaultAttributesToVersionContract',
    ]),
    toggleExtendedView() {
      this.isExtendedViewToggled = !this.isExtendedViewToggled;

      if (this.isExtendedViewToggled) {
        this.$skAnalytics.track('multicontract_display_full_creation_form');
      } else {
        this.$skAnalytics.track('multicontract_display_less_creation_form');
      }
    },
    onCancelConfirm() {
      this.$skAnalytics.track('multicontract_cancel_modify_on_current_contract');

      this.squashChangesOnContract();
    },
    onClickSubmit(event) {
      if (this.isContractCreationView || this.isContractVersionView) return;
      if (
        this.isUpgradeMultiContractsEnabled &&
        this.hasModifiedExistingValues && this.isActive
      ) {
        this.$root.$emit('confirm', event, {
          title: this.$t('employees.tabs.contracts.actions.edit.version.title'),
          description: this.$t('employees.tabs.contracts.actions.edit.version.description'),
          submitLabel: this.$t('employees.tabs.contracts.actions.edit.version.confirm'),
          cancelLabel: this.$t('employees.tabs.contracts.actions.edit.version.cancel'),
          onConfirm: this.onConfirmVersionModal,
          onCancel: this.onCancelConfirm,
        });
      } else this.handleSubmit();
    },
    showSuccessToaster() {
      this.$skToast({
        message: this.$t('employees.tabs.contracts.actions.edit.success'),
        variant: 'success',
        containerId: 'employees__container',
      });
    },
    onConfirmVersionModal() {
      this.$skAnalytics.track('multicontract_validate_modify_on_current_contract');

      this.handleSubmit(true);
    },
    handleSubmit(isFromModal = false) {
      const hasContractChanges = this.hasContractChanges;
      const queries = [];

      if (hasContractChanges) {
        queries.push(
          this.updateContract({
            employeeId: this.$route.params.user_id,
            contractId: this.$route.params.contract_id,
            clusterNodeId: this.navContext.clusterNodeId,
          }),
        );
      }

      if (this.hasChangesOnHrFile) {
        queries.push(this.updateSelectedEmployeeHr());
      }

      if (this.hasAnnualizationTotalChanged) {
        queries.push(
          this.updateEmployeeAnnualizationConfig({
            shopId: this.employee.attributes.shopId,
            userId: this.employee.id,
            theoreticalBalances: this.employeeAnnualizationConfig.theoreticalBalances,
          }),
        );
      }

      if (this.isVariableContractHoursAvailable && this.areCyclicAmendmentsEdited) {
        const amendments = this.updatedCyclicAmendments.map(amendment => (
          {
            starts_at: amendment.attributes.startsAt,
            ends_at: amendment.attributes.endsAt,
            hours: amendment.attributes.hours,
            id: amendment.id,
          }
        ));
        const params = {
          employeeId: this.$route.params.user_id,
          contractId: this.$route.params.contract_id,
          amendments,
        };

        queries.push(
          this.bulkUpdateAmendments(params),
        );
      }

      return Promise.all(queries)
        .then(() => {
          this.areCyclicAmendmentsEdited = false;

          if (this.hasAnnualizationTotalChanged) {
            this.resetOriginalEmployeeAnnualizationConfig();
          }

          // If contract was changed and variable contract hours is available
          // -> refetch amendments, in case some were created during update call.
          // This can happen when contract type was changed from not having hourly rate
          // to having it
          if (this.isVariableContractHoursAvailable && hasContractChanges) {
            this.handleFetchAmendments();
          }

          if (this.canAccessPayrollEmployee) {
            this.fetchUserMissingAttributes();
          }
          this.handleRedirect(isFromModal);
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.edit.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    handleRedirect(isFromModal) {
      const onComplete = () => this.$nextTick(this.showSuccessToaster);
      let hasRedirected = false;
      const hasMissingFieldsAfterEdit = this.displayMissingInformationNotification('user_contracts');

      if (isFromModal && !this.hasMissingFieldsBeforeEdit) {
        this.$nextTick(() => this.$router.push({ name: 'user_contracts' }, onComplete));
        return;
      }
      if (this.hasMissingFieldsBeforeEdit && this.displayPayrollPreparation) {
        hasRedirected = this.redirectToNextTabIfComplete('user_contracts', onComplete);
        if (!hasRedirected && !hasMissingFieldsAfterEdit && this.displayPayrollPreparation) {
          this.redirectToNextEmployee();
        }
        this.hasMissingFieldsBeforeEdit = hasMissingFieldsAfterEdit;
      }

      // We need to refresh when the user updates its contract
      // to be able to see the right information in employee.attributes.payrollMissingAttributes
      if (this.displayPayrollPreparation) {
        this.reloadSidebar({
          cluster_node_id: this.navContext.clusterNodeId,
          with_missing_attributes: true,
          silent: true,
        });
      }
      this.showSuccessToaster();
    },
    redirectAndShowVersionSuccessToaster() {
      const routeName = `user_contracts_${this.isActive ? 'history' : 'future'}`;
      this.$router.push({ name: routeName, params: { skipWarning: true } }, () => {
        this.$nextTick(
          () => this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.version.success'),
            variant: 'success',
          }),
        );
      });
    },
    handleVersionContract() {
      if (!this.isContractVersionView) return;

      this.$skAnalytics.track('multicontract_validate_amendment_current_contract');

      this.createVersionContract({
        employeeId: this.$route.params.user_id,
        clusterNodeId: this.navContext.clusterNodeId,
        parentId: this.contract.id,
      }).then(this.redirectAndShowVersionSuccessToaster)
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.version.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    handleCreateContract() {
      if (!this.isContractCreationView) return;

      this.createContract({
        employeeId: this.$route.params.user_id,
        clusterNodeId: this.navContext.clusterNodeId,
      })
        .then(() => {
          this.$router.push({ name: 'user_contracts', params: { skipWarning: true } });
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.create.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.create.error'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    toggleHeaderShadowOnScroll() {
      // 30 matches padding on top of full-content__wrapper
      this.showHeaderShadow = this.employeesContainer.scrollTop > 30;
    },
    handleFetchAmendments() {
      this.fetchAmendments({
        employeeId: this.$route.params.user_id,
        contractId: this.$route.params.contract_id,
      }).then(() => {
        // If contract has cyclic amendments => display matching inputs on form
        if (this.isVariableContractHoursAvailable) {
          this.setUpdatedCyclicAmendments();
        }
      }).catch(() => {
        this.$skToast({
          message: this.$t('employees.tabs.contracts.actions.edit.error'),
          variant: 'error',
          containerId: 'employees__container',
        });
      });
    },
    setUpdatedCyclicAmendments() {
      this.updatedCyclicAmendments = this.cyclicAmendments
        .filter(amendment => {
          if (amendment.attributes.endsAt) {
            return skDate()
              .utc(true)
              .isBetween(
                skDate(amendment.attributes.startsAt).utc(),
                skDate(amendment.attributes.endsAt).utc(),
              );
          }
          return skDate().utc(true).isSameOrAfter(skDate(amendment.attributes.startsAt).utc());
        })
        .map(amendment => ({ id: amendment.id, attributes: { ...amendment.attributes } }));
    },
    handleCyclicAmendmentInput() {
      // Compare current cyclic amendments values with old ones to see if some were changed
      this.areCyclicAmendmentsEdited = this.updatedCyclicAmendments.some(({ attributes }) => (
        attributes.hours !== this.cyclicAmendments.find(oldAmendment => (
          oldAmendment.attributes.scheduleWeekIndex === attributes.scheduleWeekIndex
        )).attributes.hours
      ));
    },
    async fetchAnnualizationsConfigs() {
      if (!this.employee.attributes.annualizable) {
        return;
      }

      const isShopAnnualized = await this.isShopAnnualized();

      if (!isShopAnnualized) {
        this.resetAnnualizationState();
        return;
      }

      this.setPeriodAt(skDate.utc().toDate());

      const shopId = this.employee.attributes.shopId;
      const queries = [
        this.fetchEmployeeAnnualizationConfig({ shopId, userId: this.employee.id }),
      ];

      // shop config has been already fetched when selectedShop is not user main shop
      if (this.currentShop.id === shopId) {
        queries.push(this.fetchShopAnnualizationConfig({ shopId }));
      }

      try {
        await Promise.all(queries);
      } catch (error) {
        this.resetAnnualizationState();

        if (error.response?.status === 404) {
        // svc-employees returns a 404 when employee does not have an annualization config
          return;
        }

        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });

        throw error;
      }
    },
    async isShopAnnualized() {
      if (!this.isAnnualizedWorkingTimeAvailable({ shop: this.employee.relationships.shop })) {
        return false;
      }

      if (this.currentShop.id !== this.employee.attributes.shopId) {
        try {
          await this.fetchShopAnnualizationConfig({
            shopId: this.employee.attributes.shopId,
          });

          return true;
        } catch (error) {
          if (error.response.status === 404) return false;

          throw error;
        }
      }

      return this.currentShop.attributes.isAnnualizationV2Active;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-contract__container {
  background-color: $sk-white;
}

.full-contract__sub-wrapper {
  display: flex;
  border-top-right-radius: 6px;
}

.full-contract__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.full-contract__wrapper {
  box-shadow: 0 4px 36px rgba(0, 0, 0, .08);
  background: white;
  border-radius: 6px;
  margin: 30px;
  max-width: 1080px;
}

.full-contract__header {
  display: flex;
  padding: 0 30px;
  height: 80px;
  border-bottom: 1px solid $sk-grey-10;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  border-top-left-radius: 6px;
}

.full-contract__header--shadowed {
  box-shadow: 0 6px 8px rgba(0, 0, 0, .06);
}

.full-contract__new-page__buttons-container {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--version {
    justify-content: flex-end;
  }
}

.caret--down {
  transform: rotate(180deg);
  transition: transform .3s;
}

.caret--up {
  transform: rotate(0deg);
  transition: transform .3s;
}

::v-deep .full-contract__title {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: baseline;
}

::v-deep .full-contract__title__mandatory-warning {
  padding-right: 5px;
}

::v-deep .full-contract__title--bold {
  font-weight: $fw-semi-bold;
  font-size: $fs-heading-xs;
}

::v-deep .full-contract__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px 0;
}

// deep is needed because all the class are shared between ContractData and SalaryData
::v-deep .full-contract__content__form {
  .sk-form--split {
    padding: 0 40px;
  }

  // pas trouve comment faire autrement.
  // :first-child ne fonctionne que s'il n'y a aucun autre element
  .sk-form--split--no-top-border {
    border-top: none;
  }

  .sk-form--split--bottom-border {
    border-bottom: 1px solid $sk-grey-10;
    margin-bottom: 10px;
  }

  /*
    Need an override to fix in my case
    a multiline error message
  */
  ::v-deep .sk-time-picker__error {
    width: auto !important;
    margin-top: -15px;
  }
}

::v-deep .full-contract__readonly {
  padding-top: 13px;
  padding-bottom: 13px;
}

::v-deep .full-contract__readonly-status-block {
  min-height: 30px;
  border-bottom: none;
  border-top: none;

  &:first-child {
    border-top: 1px solid $sk-grey-10;
  }
}

.full-contract__loader {
  justify-content: center;
  display: flex;
}
</style>
