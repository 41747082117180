<template>
  <SkModalV2
    id="create-version-modal"
    ref="createVersionModal"
    :title="$t('employees.tabs.contracts.actions.create_version.modal.title')"
    :subtitle="displayedSubtitle"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit="handleSuccess"
  >
    <template #body>
      <div class="create-version-modal__container">
        <span class="create-version-modal__description">
          <span> {{ $t('employees.tabs.contracts.actions.create_version.modal.content.description.instruction') }} </span>
          <br>
          <span> {{ $t('employees.tabs.contracts.actions.create_version.modal.content.description.details') }} </span>
        </span>
        <div class="create-version-modal__period-picker">
          <span>
            {{ $t('employees.tabs.contracts.actions.create_version.modal.content.period_label') }}
          </span>
          <div class="create-version-modal__period-picker__selectors">
            <SkDatePicker
              ref="startDatepicker"
              v-model="startDate"
              :placeholder="$t('employees.tabs.contracts.actions.duplicate.modal.placeholders.start_date')"
              :editable="false"
              :clearable="false"
              :lang="$i18n.locale"
              :disabled-date="disabledStartDate"
              input-moment-format="DD MMM YYYY"
              hide-footer
            />
            <SkDatePicker
              ref="endDatepicker"
              v-model="endDate"
              class="create-version-modal__period-picker__selectors__end-date"
              :placeholder="$t('employees.tabs.contracts.actions.duplicate.modal.placeholders.end_date')"
              :editable="false"
              :lang="$i18n.locale"
              input-moment-format="DD MMM YYYY"
              :disabled-date="disabledEndDate"
              clearable
              hide-footer
            />
          </div>
        </div>
        <span class="create-version-modal__constraint-info">
          {{ $t('employees.tabs.contracts.actions.create_version.modal.content.constraint_info') }}
        </span>
      </div>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'CreateVersionModal',
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters('contracts', ['contractType']),
    ...mapState('contracts', ['contract', 'hasAtLeastOneFutureContract']),

    displayedSubtitle() {
      if (!this.contract || !this.contract.attributes?.contractTypeId) { return '-'; }

      const contractAttributes = this.contract.attributes;
      const translations = this.$t('contract_types.keys');
      const contractType = this.contractType(contractAttributes.contractTypeId);
      const contractTypeName = translations[contractType.country.toLowerCase()][contractType.key];
      const contractHours = contractAttributes.contractHours;
      let subtitle = `${ contractTypeName }`;
      if (contractHours) { subtitle += ` \u2022 ${contractHours}h`; }

      return subtitle;
    },
    routerParams() {
      return {
        contract_id: this.contract.id,
        startDate: skDate.utc(this.startDate).set('hour', 8).format(),
        endDate: this.endDate,
      };
    },
    formattedCurrentDate() {
      return skDate().format('YYYY-MM-DD');
    },
  },
  created() {
    const employeeId = this.$route.params.user_id;
    this.fetchCurrentContract({ employeeId })
      .then(() => {
        const contract = this.contract?.attributes;

        if (contract) {
          const { startDate, endDate } = contract;

          this.startDate = !this.hasAtLeastOneFutureContract && !this.isToday(startDate) ?
            this.formattedCurrentDate : this.formatStartDate(startDate);
          this.endDate = endDate ? this.formatEndDate(endDate) : null;
        }
      });
  },
  methods: {
    ...mapActions('contracts', ['fetchCurrentContract']),
    handleCancel() {
      this.$skAnalytics.track('multicontract_cancel_amendment_current_contract');

      this.$refs.createVersionModal.hide();
    },
    handleSuccess() {
      this.$router.push({ name: 'user_full_contract_version', params: this.routerParams });
    },
    isToday(date) {
      return skDate().isSame(date, 'day');
    },
    disabledStartDate(date) {
      const contract = this.contract?.attributes;

      if (contract) {
        const { startDate, endDate } = contract;
        // constraint:
        //    - startDate must be after the startDate of the ongoing contract.
        //    - if the ongoing contract has an endDate the contract must stay before this endDate.
        return skDate(date).isBefore(startDate) || (endDate && skDate(date).isAfter(endDate));
      }
      return false;
    },
    disabledEndDate(date) {
      // constraint: endDate can't be before startDate
      return skDate(date).isBefore(skDate(this.startDate));
    },
    formatStartDate(date) {
      return skDate(date).add(1, 'd').format('YYYY-MM-DD');
    },
    formatEndDate(date) {
      const skEndDate = skDate(date);
      if (skEndDate.isBefore(this.startDate)) return null;

      return skEndDate.format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.create-version-modal {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 14px;
    padding: 16px 24px 8px;
  }

  &__description {
    font-size: 14px;
  }

  &__period-picker {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;

    &__selectors {
      display: flex;

      &__end-date {
        margin-left: 8px;
      }
    }
  }

  &__constraint-info {
    margin-top: 16px;
  }
}
</style>
