<template>
  <SkModalV2
    id="staff-costs-modal"
    :title="$t('home_dashboard.staff_costs.modal_title')"
    :dismissable="false"
    :testing-options="modalTestingOptions"
    :submit-disabled="isSubmitDisabled"
    :submit-loading="isSubmitLoading"
    @submit.prevent="handleSubmit"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="staff-costs-modal__description">
          {{ $t('home_dashboard.staff_costs.modal_description') }}
        </div>

        <div
          v-for="user in usersToUpdate"
          :key="`staff-costs-modal__list-item-${user.id}`"
        >
          <div class="staff-costs-modal__list-item">
            <div class="flex-m6 flex-start staff-costs-modal__user-name">
              {{ user.first_name }} {{ user.last_name }}
            </div>

            <SkInput
              v-model="costs[user.id]"
              type="number"
              min="0"
              step="0.01"
              class="flex-m6 staff-costs-modal__input"
              @keyup="enableSubmit"
            />
          </div>
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import {
  MODAL_HIDE_EVENT,
  SkInput,
} from '@skelloapp/skello-ui';

export default {
  name: 'StaffCostsUpdateModal',
  components: { SkInput },
  props: {
    usersToUpdate: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      patch_labor_cost_path: '/labor_cost',
      modalTestingOptions: {
        submit: 'staff-costs-modal__submit',
        cancel: 'staff-costs-modal__cancel',
      },
      isSubmitDisabled: true,
      isSubmitLoading: false,
      costs: [],
    };
  },
  watch: {
    usersToUpdate() {
      this.enableSubmit();
    },
  },
  methods: {
    getNewValues() {
      const newValues = {};
      this.usersToUpdate.forEach(user => {
        newValues[user.id] = this.costs[user.id];
      });
      return newValues;
    },
    async updateStaffCost(newValues) {
      return httpClient
        .patch(this.patch_labor_cost_path, { users: newValues });
    },
    async handleSubmit() {
      this.isSubmitDisabled = true;
      this.isSubmitLoading = true;
      const newValues = this.getNewValues();
      await this.updateStaffCost(newValues);
      this.handleCancel();
      this.isSubmitLoading = false;
      this.emitOnRoot('dashboard-v3-staff-cost-updated', this.usersToUpdate);
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'staff-costs-modal');
    },
    enableSubmit() {
      const newValues = Object.values(this.costs);
      if (newValues.length === this.usersToUpdate.length && newValues.every(value => value)) {
        this.isSubmitDisabled = false;
      } else {
        this.isSubmitDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-costs-modal__description {
  margin-bottom: 20px;
  padding: 0 25px;
  font-size: 1.2em;
}

.staff-costs-modal__list-item {
  display: flex;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.staff-costs-modal__user-name {
  padding-left:25px
}

.staff-costs-modal__input {
  width: 286px !important;
}
</style>
