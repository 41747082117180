<template>
  <div :class="scrollbarWrapperClasses">
    <div class="horizontal-scrollbar">
      <SkCircleButton
        :disabled="disableLeftChevron"
        icon="ChevronLeftV2Icon"
        size="small"
        @click="moveLeft"
      />

      <div
        ref="track"
        class="track"
        @click="moveToPosition"
      >
        <div
          ref="thumb"
          class="thumb"
          :style="thumbStyle"
          @mousedown.capture="handleMouseDown"
        />
      </div>

      <SkCircleButton
        :disabled="disableRightChevron"
        icon="ChevronRightV2Icon"
        size="small"
        @click="moveRight"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HorizontalScrollBar',
  props: {
    visibleWidth: {
      type: Number,
      required: true,
    },
    template: {
      type: String, // 'daily'|'monthly'
      required: true,
    },
    areCountersDisplayed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      trackWidth: 0,
      mouseEventStartX: null,
      scrollOffset: 0,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    isMonthlySkeletonEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_MONTHLY_SKELETON');
    },
    disableLeftChevron() {
      return this.scrollOffset === 0;
    },
    disableRightChevron() {
      return this.scrollOffset === 1 || this.visibleWidth === 1;
    },
    thumbStyle() {
      return {
        width: `${this.visibleWidth * this.trackWidth}px`,
        translate: `${this.scrollOffset * (this.trackWidth - this.visibleWidth * this.trackWidth)}px`,
      };
    },
    scrollbarWrapperClasses() {
      return {
        'horizontal-scrollbar-wrapper': true,
        'horizontal-scrollbar-wrapper__daily-view': this.template === 'daily',
        'horizontal-scrollbar-wrapper__monthly-view-with-counters': this.areCountersDisplayed && this.template === 'monthly',
        'horizontal-scrollbar-wrapper__daily-view-with-counters': this.areCountersDisplayed && this.template === 'daily',
        'horizontal-scrollbar-wrapper--faded': true,
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyPress);
    this.listenOnRoot('planning-horizontal-scroll', this.handleScroll);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === 'ArrowLeft') {
        this.moveLeft();
        event.preventDefault();
      } else if (event.key === 'ArrowRight') {
        this.moveRight();
        event.preventDefault();
      }
    },
    handleResize() {
      this.trackWidth = this.$refs.track.offsetWidth;
    },
    handleScroll(scrollOffset) {
      this.scrollOffset = scrollOffset;
    },
    handleMouseDown(event) {
      event.preventDefault();
      this.mouseEventStartX = event.clientX - Number(this.$refs.thumb.style.translate.slice(0, -2));
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        this.mouseEventStartX = null;
      }, { once: true });
    },
    handleMouseMove(event) {
      if (this.mouseEventStartX === null) return;
      const offsetX = event.clientX - this.mouseEventStartX;
      const maxValue = this.$refs.track.offsetWidth - this.$refs.thumb.offsetWidth;
      this.scrollOffset = maxValue === 0 ? 0 : Math.max(0, Math.min(offsetX, maxValue)) / maxValue;
      this.emitOnRoot('planning-horizontal-scroll', this.scrollOffset);
    },
    moveToPosition(event) {
      if (event.target !== this.$refs.track) return;
      const offsetX = event.offsetX - this.$refs.thumb.clientWidth / 2;
      const maxValue = this.$refs.track.offsetWidth - this.$refs.thumb.offsetWidth;
      this.scrollOffset = maxValue === 0 ? 0 : Math.max(0, Math.min(offsetX, maxValue)) / maxValue;
      this.emitOnRoot('planning-horizontal-scroll', this.scrollOffset);
    },
    moveRight() {
      this.scrollOffset = Math.min(this.scrollOffset + 1 / 3, 1);
      this.emitOnRoot('planning-horizontal-scroll', this.scrollOffset);
    },
    moveLeft() {
      this.scrollOffset = Math.max(0, this.scrollOffset - 1 / 3);
      this.emitOnRoot('planning-horizontal-scroll', this.scrollOffset);
    },
  },
};
</script>

<style lang="scss" scoped>
.horizontal-scrollbar-wrapper {
  position: fixed;
  bottom: 8px;
  left: 0;
  right: 0;
  padding: 0 8px;
  margin-left: 160px;
  pointer-events: none;
  z-index: 50;

  // monthly specific style
  &__monthly-view-with-counters {
    bottom: 51px;
    margin-right: 120px;
  }

  // daily specific style
  &__daily-view {
    margin-right: 146px;
  }

  &__daily-view-with-counters {
    bottom: 50px;
  }

  &--faded {
    animation: fadeIn 0.3s ease-out;
  }
}

.horizontal-scrollbar {
  display: flex;
  align-items: center;
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  column-gap: 3px;
  border-radius: 50px;
  border: 1px solid $sk-grey-10;
  height: 28px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .05);
  background-color: $sk-white;
  pointer-events: auto;

  .track {
    position: relative;
    height: 12px;
    flex-grow: 1;
    border-radius: 8px;
    background-color: $sk-grey-5;

    .thumb {
      translate: 0;
      cursor: pointer;
      height: 100%;
      border-radius: 8px;
      background-color: $sk-grey-30;

      &:active,
      &:hover {
        background-color: $sk-grey-50;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
