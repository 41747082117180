<template>
  <div class="flex-row">
    <div class="flex-m6">
      <OverHoursChart />
    </div>
    <div class="flex-m6">
      <UnfinishedProfilesChart />
    </div>
  </div>
</template>

<script>
import OverHoursChart from './Charts/OverHoursChart';
import UnfinishedProfilesChart from './Charts/UnfinishedProfilesChart.vue';

export default {
  name: 'DashboardThirdLine',
  components: {
    OverHoursChart,
    UnfinishedProfilesChart,
  },
};
</script>
