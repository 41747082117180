<template>
  <div
    :class="rowClasses"
    class="license-card__action-row"
    @click="handleBlockedClick"
  >
    <SkCheckBox
      v-if="action.editable"
      :id="uuid"
      v-model="actionModel"
      :disabled="bundleBlocked"
    />
    <div v-else-if="actionModel">
      <CheckMarkV2Icon
        :fill="$skColors.skBlue50"
        width="20"
        height="20"
      />
    </div>
    <div v-else>
      <CrossV2Icon
        :fill="$skColors.skError50"
        width="20"
        height="20"
      />
    </div>
    <label
      :for="uuid"
      class="sk-text-medium-regular"
    >
      {{ legibleDescription }}
    </label>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'LicenseActionRow',
  props: {
    action: {
      type: Object,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uuid: uniqueId(`${this.license.id}-${this.action.name}`),
    };
  },
  computed: {
    rowClasses() {
      return {
        'license-card__action-row--blocked': this.bundleBlocked,
      };
    },
    legibleDescription() {
      const isActionFlexible = this.action.name === 'can_edit_legal_alerts_config' && this.action.without_flexible_conventions !== true;

      if (isActionFlexible) {
        return this.$t(`organisation_settings.tabs.licenses.action_descriptions.${this.action.name}.legible_description_flexible`);
      }

      return this.$t(`organisation_settings.tabs.licenses.action_descriptions.${this.action.name}.legible_description`);
    },
    allActions() {
      return Object.values(this.license.attributes.actions).flat();
    },
    bundleBlockIrrelevant() {
      // If nothing is specified in those fields, it means that there isn't specific blocking cases.
      // We can display the action independently.
      return (!this.action.bundle_restrictions && !this.action.bundle_rejections) ||
        !this.license.attributes.actions ||
        !this.action.editable;
    },
    bundleBlocked() {
      // This function allows us to make a checkbox action grey (non clickable)
      if (this.bundleBlockIrrelevant) return false;

      // We check if this specific action is restricted by at least one of the other actions.
      // If such an action exists and is not allowed, then we make this current action not clickable as well.
      const blockedByRestrictions = this.action.bundle_restrictions &&
        this.allActions
          .some(action => this.action.bundle_restrictions.includes(action.name) &&
            !action.allowed,
          );

      // We check if this specific action is rejected by at least one of the other existing actions.
      // If such an action exists and is allowed, then we make this current action not clickable because they can't
      // be active at the same time.
      const blockedByRejections = this.action.bundle_rejections &&
        this.allActions
          .some(action => this.action.bundle_rejections.includes(action.name) &&
            action.allowed,
          );

      return blockedByRestrictions || blockedByRejections;
    },
    actionModel: {
      get() {
        return this.action.allowed;
      },

      set(newValue) {
        this.action.allowed = newValue;

        const toggledAction = this.allActions.find(a => this.action.name === a.name);
        toggledAction.allowed = newValue;
        this.handleBundleConstraints();

        this.$emit('update-license', this.license);
      },
    },
  },
  mounted() {
    this.handleBundleConstraints();
  },
  methods: {
    handleBundleConstraints() {
      if (this.action.bundle_dependents && !this.action.allowed) {
        this.action.bundle_dependents.forEach(dependent => {
          const dependentAction = this.allActions.find(a => dependent === a.name);

          dependentAction.allowed = false;
        });
      }

      if (this.action.allowed) {
        this.allActions.forEach(action => {
          if (action.bundle_rejections?.includes(this.action.name)) {
            action.allowed = false;
          }
        });
      }
    },
    handleBlockedClick() {
      if (!this.bundleBlocked) return;

      this.$skToast({
        message: this.$t('organisation_settings.tabs.licenses.blocked_bundle'),
        variant: 'error',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.license-card__action-row {
  display: flex;
  align-items: center;
  font-size: $fs-text-m;
  gap: 8px;

  &--disabled {
    opacity: .4;
  }

  &--blocked {
    opacity: .5;
  }

  label {
    margin: 0
  }
}
</style>
