<template>
  <SkModal
    id="recalculate-counters-modal"
    ref="modal"
    :modal-title="$t('shop_settings.recalculate_counters_modal.title')"
    :tracking-options="trackingOptions"
    :submit-disabled="hasDateError"
    @submit="handleSubmit"
  >
    <template #body>
      <SkModalSection
        border-bottom="none"
        class="recalculate-counters-modal__section"
      >
        <div class="recalculate-counters-modal__description">
          {{ $t('shop_settings.recalculate_counters_modal.description') }}
        </div>
        <div class="row sk-form__row recalculate-counters-modal__form">
          <div class="col-md-4 recalculate-counters-modal__label">
            {{ $t('shop_settings.recalculate_counters_modal.recalculation_date') }}
          </div>
          <div class="col-md-6 offset-md-2">
            <SkDatePicker
              v-model="recalculationDate"
              :disabled-date="disabledDays"
              :clearable="false"
              :errored="hasDateError"
              :error-message="dateErrorMessage"
              :lang="$i18n.locale"
              input-moment-format="dddd DD MMM YYYY"
              append-to-body
            />
            <p class="recalculate-counters-modal__date-helptext">
              {{ $t('shop_settings.recalculate_counters_modal.recalculation_date_helptext') }}
            </p>
          </div>
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import skDate from '@skello-utils/dates';

export default {
  name: 'RecalculateCountersModal',
  data() {
    return {
      recalculationDate: skDate().getLastSunday().format('YYYY-MM-DD'),
    };
  },
  computed: {
    isDateOnSunday() {
      if (!this.recalculationDate) return false;

      return skDate(this.recalculationDate).isSunday();
    },
    hasDateError() {
      return !this.recalculationDate || !this.isDateOnSunday;
    },
    dateErrorMessage() {
      if (!this.isDateOnSunday) {
        return this.$t('shop_settings.tabs.rules.modulation.modal.date.not_sunday');
      }

      return this.$t('errors.missing_value');
    },
    trackingOptions() {
      return {
        submit: 'report_rules_save',
        cancel: 'report_rules_cancelled',
      };
    },
  },
  methods: {
    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    handleSubmit(event) {
      this.$emit('submit', event, this.recalculationDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.recalculate-counters-modal__section {
  padding-bottom: 0;
}

.recalculate-counters-modal__form {
  margin-top: 25px;
}

.recalculate-counters-modal__description {
  line-height: 24px;
}

.recalculate-counters-modal__label {
  color: $sk-black;
  font-weight: bold;
  line-height: 22px;
}

.recalculate-counters-modal__date-helptext {
  color: $sk-grey;
  font-size: $fs-text-s;
  line-height: 15px;
  padding-left: 10px;
  padding-top: 5px;
}
</style>
