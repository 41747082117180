<template>
  <div
    :class="columnClasses"
    @click="$emit('click', $event)"
  >
    <div class="employee-counters__table-year-column-title">
      {{ year.year }}
    </div>
    <div
      v-if="year.closed"
      class="employee-counters__table-year-column-cells"
    >
      <TableCell
        :data="year.days_worked"
        :tooltip="cellTitle"
      />
      <TableCell
        :data="year.contract_days"
        :tooltip="cellTitle"
      />
      <TableCell
        :data="year.days_counter - year.manual_changes"
        :tooltip="cellTitle"
        color="#ff9600"
      />
      <TableCell
        :data="year.manual_changes"
        :tooltip="cellTitle"
      />
      <TableCell
        :data="year.days_counter"
        :tooltip="cellTitle"
        color="#ff9600"
      />
      <TableCell
        :data="year.contract_days - year.days_counter"
        :tooltip="cellTitle"
      />
    </div>
    <div
      v-else
      class="employee-counters__table-months-wrapper"
    >
      <slot />
    </div>
  </div>
</template>

<script>

import { TableCell } from '../shared/components';

export default {
  name: 'DayRateCounterYearColumn',
  components: { TableCell },
  props: {
    year: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columnClasses() {
      return {
        'employee-counters__table-year-column': true,
        'employee-counters__table-year-column--open': !this.year.closed,
        'employee-counters__table-year-column--previsional': this.year.previsional,
      };
    },
    cellTitle() {
      return this.year.closed ?
        this.$t('employees.tabs.counters.hours_counter.tooltip.show_more') : '';
    },
  },
};
</script>
