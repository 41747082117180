import { EmployeesFactory } from '@skelloapp/svc-employees-client';
import { svcEmployeesUrl } from '@config/env';
import { authClient } from './auth_client';

class SvcEmployeesClient {
  constructor() {
    this.svcEmployees = EmployeesFactory.create(svcEmployeesUrl, { retries: 0 });
  }

  async createAbsenceConfig(data) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig.create(data, jwt);
  }

  async bulkCreateAbsenceConfigs(data) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig.bulkCreate(data, jwt);
  }

  async bulkCreateAnnualizationConfigs(data) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.bulkCreate(data, jwt);
  }

  async bulkDeleteAbsenceConfigs(shopId, absenceType) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig.bulkDelete(shopId, absenceType, jwt);
  }

  async bulkDeleteAnnualizationConfigs(shopId) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.bulkDelete(shopId, jwt);
  }

  async bulkUpdateAnnualizationConfigs(shopId, manualChanges) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.bulkUpdate(shopId, manualChanges, jwt);
  }

  async createAnnualizationConfig(data) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.create(data, jwt);
  }

  async createDpaeDeposit(shopId, employeeId, contractId, data = {}) {
    const jwt = await this.getToken();

    return this.svcEmployees.dpaeDeposit.create(shopId, employeeId, contractId, data, jwt);
  }

  async findOneByShopIdUserId(shopId, userId) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.findOneByShopIdUserId(shopId, userId, jwt);
  }

  async updateAnnualizationConfig(shopId, userId, params) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.updateAnnualizationConfig(
      shopId,
      userId,
      params,
      jwt,
    );
  }

  async upsertAnnualizationConfig(shopId, userId, params) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.upsertAnnualizationConfig(
      shopId,
      userId,
      params,
      jwt,
    );
  }

  async deleteAnnualizationConfig(shopId, userId) {
    const jwt = await this.getToken();

    await this.svcEmployees.annualizationConfig.deleteAnnualizationConfig(shopId, userId, jwt);
  }

  async findAllEmployeesAbsenceConfig(shopId, absenceType) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig.findAllEmployeesAbsenceConfig(shopId, absenceType, jwt);
  }

  async findAllEmployeesAnnualizationConfig(shopId) {
    const jwt = await this.getToken();

    return this.svcEmployees.annualizationConfig.findAllAnnualizationConfig(shopId, jwt);
  }

  async findOneAbsenceConfigByShopIdAbsenceTypeUserId(shopId, absenceType, userId) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig
      .findOneByShopIdAbsenceTypeUserId(shopId, absenceType, userId, jwt);
  }

  async updateAbsenceConfig(shopId, absenceType, userId, data) {
    const jwt = await this.getToken();

    return this.svcEmployees.absenceConfig
      .updateAbsenceConfig(shopId, absenceType, userId, data, jwt);
  }

  async getToken() {
    const jwt = (await authClient.getAuthToken()).token;

    if (!jwt) {
      throw new Error('Auth token is null');
    }

    return jwt;
  }
}

export default SvcEmployeesClient;
export const svcEmployeesClient = new SvcEmployeesClient();
