<template>
  <SkModalSectionV2 class="absence-temporality-row">
    <div class="absence-temporality-row__inputs">
      <label for="duration">
        {{ $t('requests.leave_requests.modal.duration.label') }}
      </label>
      <span class="absence-temporality-row__radios">
        <SkRadio
          v-for="durationOption in ['day', 'half-day']"
          :key="durationOption"
          v-model="localValue.duration"
          :data-test="durationOption === 'day' ?
            'leave-request__day-duration__input' :
            'leave-request__half-day-duration__input'
          "
          :data-value="durationOption"
          :disabled="isDisabled"
          name="duration"
        >
          {{ durationLabels[durationOption] }}
        </SkRadio>
      </span>
    </div>
    <div class="absence-temporality-row__inputs">
      <label>
        {{ dateLabels[localValue.duration] }}*
      </label>
      <SkDatePicker
        v-show="localValue.duration === 'day'"
        v-model="dateFullDays"
        :disabled="isDisabled"
        :placeholder="placeholder"
        range
        append-to-body
        data-test="leave-request__select-dates__input"
        name="period"
      />
      <SkDatePicker
        v-show="localValue.duration === 'half-day'"
        v-model="dateHalfDay"
        :disabled="isDisabled"
        :placeholder="placeholder"
        append-to-body
        data-test="leave-request__select-dates__input"
        name="period"
      />
    </div>
  </SkModalSectionV2>
</template>

<script>

export default {
  name: 'AbsenceTemporalityRow',
  props: {
    value: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      durationLabels: {
        day: this.$t('requests.leave_requests.modal.duration.full_day'),
        'half-day': this.$t('requests.leave_requests.modal.duration.half_day'),
      },
      dateLabels: {
        day: this.$t('requests.leave_requests.modal.date.full_day.label'),
        'half-day': this.$t('requests.leave_requests.modal.date.half_day.label'),
      },
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dateFullDays: {
      get() {
        return this.localValue.fullDays;
      },
      set(value) {
        this.localValue = {
          ...this.localValue,
          fullDays: value,
        };
      },
    },
    dateHalfDay: {
      get() {
        return this.localValue.halfDay;
      },
      set(value) {
        this.localValue = {
          ...this.localValue,
          halfDay: value,
        };
      },
    },
    placeholder() {
      return this.localValue.duration === 'day' ?
        this.$t('requests.leave_requests.modal.date.full_day.placeholder') :
        this.$t('requests.leave_requests.modal.date.half_day.placeholder');
    },
  },
};

</script>

<style lang="scss" scoped>
.absence-temporality-row {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__radios {
    display: flex;
    gap: 16px;
    width: 394px;
  }

  label {
    font-weight: $fw-semi-bold;
    margin-bottom: 0;
  }
}

.sk-datepicker__wrapper {
  width: 394px;
}
</style>
