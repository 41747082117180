<template>
  <tr
    class="availability__row"
  >
    <td
      v-if="columns.type.active"
      ref="typeLabel"
      class="availability__type-row"
    >
      {{ $t('requests.availability_requests.cell.type.' + request.attributes.status) }}
    </td>
    <td
      v-if="columns.recurrence.active"
      ref="recurrenceLabel"
      class="availability__status-row"
    >
      {{ $t('requests.availability_requests.cell.recurrence.' + request.attributes.recurrence) }}
    </td>
    <td
      v-if="columns.creation_date.active"
      ref="creationDateLabel"
      class="availability__dates-row"
    >
      {{ askedDate }}
    </td>
    <td
      v-if="columns.date.active"
      ref="dateLabel"
      class="availability__dates-row"
    >
      {{ displayDate }}
    </td>
    <td
      v-if="columns.hours.active"
      ref="hoursLabel"
      class="availability__dates-row"
    >
      {{ hours }}
    </td>
    <td
      v-if="columns.status.active"
      class="availability__status-row"
    >
      <SkTag :variant="statusVariant">
        {{ $t('requests.statuses.' + request.attributes.requestStatus) }}
      </SkTag>
    </td>
    <td
      v-if="columns.actions.active"
      ref="actionsLabel"
      class="availability__actions-row"
    >
      <SkCircleButton
        icon="TrashCanV2Icon"
        :icon-color="$skColors.skError"
        background-color="transparent"
        size="small"
        data-test="availability-own-requests__table__delete-action"
        @click="handleDeleteRequest"
      />
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';
import AvailabilityRowMixins from '../../../mixins/AvailabilityRowMixins';

export default {
  name: 'AvailabilityRequestsOwnRow',
  mixins: [AvailabilityRowMixins],
  methods: {
    ...mapActions('requests', ['destroyAvailabilityRequest']),
    handleDeleteRequest(event) {
      const successMessage = this.$t('requests.availability_requests.toast.success.removal');
      const errorMessage = this.$t('requests.toast.error');

      const confirmationModalParams = {
        title: this.$t('requests.availability_requests.removal_modal.title'),
        submitLabel: this.$t('requests.availability_requests.removal_modal.confirm'),
        description: this.$t('requests.availability_requests.removal_modal.description'),
        variant: 'danger',
        onConfirmSuccess: async () => {
          try {
            await this.destroyAvailabilityRequest({
              availability: {
                ...this.request,
              },
            });
            this.$skToast({ message: successMessage, variant: 'success' });
          } catch (error) {
            this.$skToast({ message: errorMessage, variant: 'error' });

            throw error;
          }
        },
      };
      this.$root.$emit('confirm', null, confirmationModalParams);
    },
  },
};
</script>
<style lang="scss" scoped>
  .availability__row {
    td {
      padding: 14.5px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .availability__actions-row {
      button {
        cursor: pointer;
      }
    }
  }
</style>
