<template>
  <div class="notification-payroll">
    <div class="notification-payroll__icon">
      <ScrollV2Icon
        width="16px"
        height="16px"
        fill="#8B45FF"
      />
    </div>
    <div class="notification-payroll__text">
      <span>{{ $t('employees.tabs.contracts.table.notification_payroll.information') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissingInformationNotification',
};
</script>

<style lang="scss" scoped>
.notification-payroll {
  display: flex;
  align-items: center;

  .notification-payroll__icon {
    background-color: $sk-info-10;
    border-radius: 50%;
    padding: 4px;
    width: 24px;
    height: 24px;
  }

  .notification-payroll__text {
    font-size: 12px;
    margin-left: 8px;
  }
}
</style>
