<template>
  <div id="employees__hr-section">
    <div class="employees__content-wrapper">
      <h3 class="sk-form-section__title">
        {{ $t('employees.tabs.hr_file.title') }}
      </h3>
      <div
        v-if="displayMissingInformationNotification('user_hr_file')"
        class="employees__container__notification"
      >
        <MissingInformationNotification />
      </div>
      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.birth.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkDatePicker
              v-model="birthdayAttribute"
              :disabled="!canEditHrData"
              :lang="$i18n.locale"
              :placeholder="$t('employees.tabs.hr_file.birth.fields.birthdate')"
              :is-highlighted="showHighlightForField(birthdayAttribute)"
              width="100%"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="birthPlaceAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.birth.fields.city_of_birth')"
              :is-highlighted="showHighlightForField(birthPlaceAttribute)"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="birthDepartmentAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.birth.fields.region_of_birth')"
              :is-highlighted="showHighlightForField(birthDepartmentAttribute)"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkSelectV2
              v-model="countryCodeOfBirthAttribute"
              :options="countries"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.birth.fields.country_of_birth')"
              :search-placeholder="searchPlaceholder"
              :no-results-label="$t('search_bar.no_result')"
              :is-highlighted="showHighlightForField(countryCodeOfBirthAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.nationality.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="nationalityAttribute"
              :disabled="!canEditHrData"
              :options="countries"
              :label="$t('employees.tabs.hr_file.nationality.fields.nationality')"
              :search-placeholder="searchPlaceholder"
              :no-results-label="$t('search_bar.no_result')"
              :is-highlighted="showHighlightForField(nationalityAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm
        v-if="!isEmployeeAndShopSameNationality"
        split
      >
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.document_type.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="documentTypeAttribute"
              :disabled="!canEditHrData"
              :options="immigrationDocuments"
              :label="$t('employees.tabs.hr_file.document_type.fields.document_type')"
              :no-results-label="$t('search_bar.no_result')"
              :is-highlighted="showHighlightForField(documentTypeAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm
        v-if="!isEmployeeAndShopSameNationality"
        split
      >
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.document_expiry.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-6 sk-form__col">
            <SkDatePicker
              v-model="expirationDateDocumentAttribute"
              :disabled="!canEditHrData"
              :lang="$i18n.locale"
              :placeholder="$t('employees.tabs.hr_file.document_expiry.fields.document_expiry')"
              :is-highlighted="showHighlightForField(expirationDateDocumentAttribute)"
              width="100%"
            />
          </div>
        </div>
      </SkForm>

      <SkForm
        v-if="isIdentityNumberEnabled"
        split
      >
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.identity_number.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkInput
              v-model="identityNumber"
              :disabled="!canEditHrData"
              :errored="!isIdentityNumberValid"
              :error-message="
                $t('employees.tabs.hr_file.identity_number.fields.error_message')"
              :label="$t('employees.tabs.hr_file.identity_number.fields.identity_number')"
              :is-highlighted="showHighlightForIdentityNumber"
              @keyup="handleIdentityNumberKeyUp"
            />
          </div>
        </div>
      </SkForm>

      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.gender.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="genderAttribute"
              :disabled="!canEditHrData"
              :options="genders"
              :label="$t('employees.tabs.hr_file.gender.fields.gender')"
              :is-highlighted="showHighlightForField(genderAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.address.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="addressAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.address.fields.address')"
              :is-highlighted="showHighlightForField(addressAttribute)"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="addressDetailsAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.address.fields.addressDetails')"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="postalCodeAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.address.fields.zip_code')"
              :is-highlighted="showHighlightForField(postalCodeAttribute)"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="cityAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.address.fields.city')"
              :is-highlighted="showHighlightForField(cityAttribute)"
            />
          </div>
        </div>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="countryCodeOfResidenceAttribute"
              :disabled="!canEditHrData"
              :options="countries"
              :label="$t('employees.tabs.hr_file.address.fields.country')"
              :search-placeholder="searchPlaceholder"
              :no-results-label="$t('search_bar.no_result')"
              :is-highlighted="showHighlightForField(countryCodeOfResidenceAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm
        v-if="canReadBankData"
        split
      >
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.bank_data.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkSelectV2
              v-model="paymentMethodAttribute"
              input-id="payment-method"
              :disabled="!canEditHrData"
              :options="paymentMethods"
              :label="$t('employees.tabs.hr_file.bank_data.fields.payment_methods')"
              :is-highlighted="showHighlightForField(paymentMethodAttribute)"
            />
          </div>
        </div>
        <div
          v-show="paymentMethodAttribute === 'bank_transfer'"
          class="row sk-form__row"
        >
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="bankInfoAttribute"
              :disabled="!canEditBankData"
              :label="$t('employees.tabs.hr_file.bank_data.fields.iban.text')"
              :errored="hasIbanError"
              :debounce="validateIban"
              :debounce-ms="1500"
              :error-message="$t('employees.tabs.hr_file.bank_data.fields.iban.error_message')"
              :is-highlighted="showHighlightForField(bankInfoAttribute)"
              @keyup="handleIbanKeyUp"
            />
          </div>
          <div class="col-12 col-md-6 sk-form__col">
            <SkInput
              v-model="swiftCodeAttribute"
              :disabled="!canEditBankData"
              :label="$t('employees.tabs.hr_file.bank_data.fields.bic')"
              :is-highlighted="showHighlightForField(swiftCodeAttribute)"
            />
          </div>
        </div>
      </SkForm>

      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.ssn.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkInput
              v-model="socialSecurityNumberAttribute"
              :disabled="disabledSsnInput"
              :label="$t('employees.tabs.hr_file.ssn.fields.ssn')"
              :errored="isSocialSecurityNumberErrored"
              :debounce="validateSocialSecurityNumber"
              :debounce-ms="1500"
              :error-message="$t('employees.tabs.hr_file.ssn.fields.error_message')"
              :is-highlighted="!isUserMissingSsn &&
                showHighlightForField(socialSecurityNumberAttribute)"
              @keyup="handleSocialSecurityNumberKeyUp"
            />
          </div>
          <div
            v-if="isFrenchShop"
            class="col-12 sk-form__col ssn-checkbox-wrapper"
          >
            <SkCheckBox
              v-model="isUserMissingSsn"
              :disabled="!canEditHrData"
              class="ssn-checkbox"
              :label="$t('employees.tabs.hr_file.ssn.fields.missing_ssn.label')"
            />
          </div>
        </div>
      </SkForm>

      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.fiscality.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkInput
              v-model="numberOfDependentsAttribute"
              type="number"
              :disabled="!canEditSelfHr"
              :label="$t('employees.tabs.hr_file.fiscality.fields.number_of_dependents')"
            />
          </div>
        </div>
      </SkForm>
      <SkForm split>
        <template #title>
          <label>{{ $t('employees.tabs.hr_file.comments.title') }}</label>
        </template>
        <div class="row sk-form__row">
          <div class="col-12 sk-form__col">
            <SkTextarea
              v-model="commentsAttribute"
              :disabled="!canEditHrData"
              :label="$t('employees.tabs.hr_file.comments.fields.comments')"
              :min-height="120"
              :max-height="120"
            />
          </div>
        </div>
      </SkForm>
    </div>

    <StickyBar
      :visible="employeeIsLoaded && hasChangesOnHrFile"
      :submit-spinner="updatingEmployee"
      :submit-button-label="$t('employees.sticky_bar.submit')"
      :disabled="inputError"
      :tracking-options="{ update: 'update_employee' }"
      class="employee-sticky-bar"
      container-scroll-id="employees__container"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import IBAN from 'iban';

import MissingInformationNotification from '@app-js/shared/components/PayrollPreparation/MissingInformationNotification';
import { arrayToSelectOptions } from '@skello-utils/form';
import COUNTRY_KEYS from '@app-js/shared/constants/country-keys';
import { payrollPreparationMixins } from '@app-js/shared/components/PayrollPreparation/mixins/PayrollPreparation';

import StickyBar from '../../../shared/components/Stickybar';

const IDENTITY_NUMBER_REGEX = /^[a-zA-Z0-9-]*$/;
const SSN_REGEX = /^(?=(?:.{13}|.{15})$)[A-Za-z0-9]*$/;

export default {
  name: 'HrFile',
  components: {
    StickyBar,
    MissingInformationNotification,
  },
  mixins: [payrollPreparationMixins],
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChangesToEmployee) {
      this.$root.$emit('confirm', event, {
        description: this.$t('warnings.unsaved_changes'),
        onConfirm: () => {
          this.resetToOriginalState();
          next();
        },
      });
    } else {
      next();
    }
  },
  data() {
    const { employee } = this.$store.state.selectedEmployee;
    const { config } = this.$store.state.config;

    return {
      hasIbanError: !!employee.attributes.bankInfo &&
        !IBAN.isValid(employee.attributes.bankInfo),
      isSocialSecurityNumberErrored:
        employee.attributes.socialSecurityNumber &&
        config.dom_tom.includes(
          employee.relationships.shop.attributes.country.toLowerCase(),
        ) &&
        !SSN_REGEX.test(employee.attributes.socialSecurityNumber) &&
        employee.attributes.socialSecurityNumber.length !== 0,
      hasMissingFieldsBeforeEdit: this.hasMissingMandatoryFieldsForPayroll('user_hr_file'),
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', [
      'employee',
      'updatingEmployee',
      'inputError',
    ]),
    ...mapGetters('currentLicense', [
      'canReadEmployeeInfo',
      'canEditEmployeeInfo',
      'canEditSelfHr',
      'canReadEmployeePersonalInfosAndBankData',
      'canEditEmployeePersonalInfosAndBankData',
    ]),
    ...mapGetters('employees', ['displayPayrollPreparation', 'canAccessPayrollEmployee']),
    ...mapGetters('selectedEmployee', [
      'isCurrentUserOrSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
      'unsavedChangesToEmployee',
      'employeeIsLoaded',
      'hasChangesOnHrFile',
    ]),
    ...mapGetters('currentShop', ['checkFeatureFlag']),

    showHighlightForIdentityNumber() {
      return this.checkFeatureFlag('FEATURE_ID_NUMBER_MANDATORY_FOR_PAYROLL') &&
        this.isIdentityNumberEnabled &&
        this.showHighlightForField(this.identityNumber);
    },
    canAccessHrFileTab() {
      if (this.isCurrentUserOrSystemAdmin) return true;

      return this.isStrictSubordinateOfCurrentUser && this.canReadEmployeeInfo;
    },
    canEditHrData() {
      if (!this.canEditEmployeeInfo) return false;
      return (
        (this.canEditSelfHr && this.isCurrentUserOrSystemAdmin) ||
        this.isStrictSubordinateOfCurrentUser
      );
    },
    canReadBankData() {
      if (!this.canReadEmployeePersonalInfosAndBankData) return false;
      return (this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser);
    },
    canEditBankData() {
      if (!this.canEditEmployeePersonalInfosAndBankData) return false;
      return (this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser);
    },
    immigrationDocuments() {
      return arrayToSelectOptions(
        this.config.immigration_documents,
        immigrationDocument => this.$t(`immigration_documents.${immigrationDocument}`),
      );
    },
    countries() {
      return arrayToSelectOptions(COUNTRY_KEYS, value => this.$t(`countries.${value}`));
    },
    genders() {
      return arrayToSelectOptions(this.config.genders, value => this.$t(`genders.${value}`));
    },
    paymentMethods() {
      return arrayToSelectOptions(this.config.payment_methods, value => this.$t(`payment_methods.${value}`));
    },
    employeeIsFrench() {
      if (!this.nationalityAttribute) return false;

      const frenchNationalities = [
        'FR', // France Métropolitaine
        'GP', // Guadeloupe
        'MQ', // Martinique
        'GF', // Guyane française
        'RE', // Réunion
        'YT', // Mayotte
        'PM', // Saint-Pierre-et-Miquelon
        'BL', // Saint-Barthélemy
        'MF', // Saint-Martin
        'WF', // Wallis et Futuna
        'PF', // Polynésie française
        'NC', // Nouvelle-Calédonie
        'TF', // Terres australes françaises
      ];

      return frenchNationalities.includes(this.nationalityAttribute);
    },
    shopCountry() {
      // when on the "all" this.currentShop.attributes.country will be undefined
      // we fallback on the employee's affiliation shop country
      return this.currentShop.attributes.country ||
        this.employee.relationships.shop.attributes.country;
    },
    isEmployeeAndShopSameNationality() {
      if (this.isFrenchShop && this.employeeIsFrench) return true;

      return this.nationalityAttribute?.toLowerCase() ===
        this.employee.relationships.shop.attributes.country?.toLowerCase();
    },
    nationalityAttribute: {
      get() {
        return this.employee.attributes.nationality;
      },
      set(value) {
        this.setEmployeeAttributes({ nationality: value });
      },
    },
    birthPlaceAttribute: {
      get() {
        return this.employee.attributes.birthPlace;
      },
      set(value) {
        this.setEmployeeAttributes({ birthPlace: value });
      },
    },
    birthdayAttribute: {
      get() {
        return this.employee.attributes.birthday;
      },
      set(value) {
        this.setEmployeeAttributes({ birthday: value });
      },
    },
    countryCodeOfBirthAttribute: {
      get() {
        return this.employee.attributes.countryCodeOfBirth;
      },
      set(value) {
        this.setEmployeeAttributes({ countryCodeOfBirth: value });
      },
    },
    documentTypeAttribute: {
      get() {
        return this.employee.attributes.documentType;
      },
      set(value) {
        this.setEmployeeAttributes({ documentType: value });
      },
    },
    expirationDateDocumentAttribute: {
      get() {
        return this.employee.attributes.expirationDateDocument;
      },
      set(value) {
        this.setEmployeeAttributes({ expirationDateDocument: value });
      },
    },
    genderAttribute: {
      get() {
        return this.employee.attributes.gender;
      },
      set(value) {
        this.setEmployeeAttributes({ gender: value });
      },
    },
    identityNumber: {
      get() {
        return this.employee.attributes.identityNumber;
      },
      set(value) {
        if (value === this.employee.attributes.identityNumber) return;
        this.setEmployeeAttributes({ identityNumber: value });
      },
    },
    addressAttribute: {
      get() {
        return this.employee.attributes.address;
      },
      set(value) {
        this.setEmployeeAttributes({ address: value });
      },
    },
    addressDetailsAttribute: {
      get() {
        return this.employee.attributes.addressDetails;
      },
      set(value) {
        this.setEmployeeAttributes({ addressDetails: value });
      },
    },
    postalCodeAttribute: {
      get() {
        return this.employee.attributes.postalCode;
      },
      set(value) {
        this.setEmployeeAttributes({ postalCode: value });
      },
    },
    cityAttribute: {
      get() {
        return this.employee.attributes.city;
      },
      set(value) {
        this.setEmployeeAttributes({ city: value });
      },
    },
    countryCodeOfResidenceAttribute: {
      get() {
        return this.employee.attributes.countryCodeOfResidence;
      },
      set(value) {
        this.setEmployeeAttributes({ countryCodeOfResidence: value });
      },
    },
    paymentMethodAttribute: {
      get() {
        return this.employee.attributes.paymentMethod;
      },
      set(value) {
        this.setEmployeeAttributes({ paymentMethod: value });
      },
    },
    bankInfoAttribute: {
      get() {
        return this.employee.attributes.bankInfo;
      },
      set(value) {
        this.setEmployeeAttributes({ bankInfo: value });
      },
    },
    swiftCodeAttribute: {
      get() {
        return this.employee.attributes.swiftCode;
      },
      set(value) {
        this.setEmployeeAttributes({ swiftCode: value });
      },
    },
    commentsAttribute: {
      get() {
        return this.employee.attributes.comments;
      },
      set(value) {
        this.setEmployeeAttributes({ comments: value });
      },
    },
    birthDepartmentAttribute: {
      get() {
        return this.employee.attributes.birthDepartment;
      },
      set(value) {
        this.setEmployeeAttributes({ birthDepartment: value });
      },
    },
    socialSecurityNumberAttribute: {
      get() {
        return this.employee.attributes.socialSecurityNumber;
      },
      set(value) {
        this.setEmployeeAttributes({ socialSecurityNumber: value });
      },
    },
    numberOfDependentsAttribute: {
      get() {
        return this.employee.attributes.numberOfDependents;
      },
      set(value) {
        this.setEmployeeAttributes({ numberOfDependents: value });
      },
    },
    isUserMissingSsn: {
      get() {
        return this.employee.attributes.isMissingSocialSecurityNumber;
      },
      set(value) {
        this.setEmployeeAttributes({ isMissingSocialSecurityNumber: value });
      },
    },
    disabledSsnInput() {
      return !this.canEditHrData || (this.isFrenchShop && this.isUserMissingSsn);
    },
    searchPlaceholder() {
      return this.$t('labels.search');
    },
    isIdentityNumberValid() {
      return IDENTITY_NUMBER_REGEX.test(this.identityNumber);
    },
    isIdentityNumberEnabled() {
      return this.checkFeatureFlag('FEATURE_ID_NUMBER');
    },
    isFrenchShop() {
      return this.config.dom_tom.includes(
        this.employee.relationships.shop.attributes.country.toLowerCase(),
      );
    },
  },
  created() {
    if (!this.canAccessHrFileTab) {
      this.$router.replace({ name: 'user_personal', params: { user_id: this.employee.id } });
    }
  },
  methods: {
    ...mapActions('employees', ['reloadSidebar', 'fetchUserMissingAttributes']),
    ...mapActions('selectedEmployee', ['updateSelectedEmployeeHr', 'resetToOriginalState']),
    ...mapMutations('selectedEmployee', [
      'setEmployeeAttributes',
      'setInputEmployeeError',
    ],
    ),
    handleIbanKeyUp() {
      this.setInputEmployeeError({
        iban: this.bankInfoAttribute && !IBAN.isValid(this.bankInfoAttribute),
      });
    },
    validateIban() {
      this.hasIbanError = this.bankInfoAttribute && !IBAN.isValid(this.bankInfoAttribute);
    },
    handleSocialSecurityNumberKeyUp() {
      if (!this.isFrenchShop) return;

      this.setInputEmployeeError({
        socialSecurityNumber: !SSN_REGEX.test(this.socialSecurityNumberAttribute) &&
          this.socialSecurityNumberAttribute?.length !== 0,
      });
    },
    validateSocialSecurityNumber() {
      if (!this.isFrenchShop) return;

      this.isSocialSecurityNumberErrored =
        !SSN_REGEX.test(this.socialSecurityNumberAttribute) &&
        this.socialSecurityNumberAttribute?.length !== 0;
    },
    showSuccessToaster() {
      this.$skToast({
        message: this.$t('employees.update_employee.success_message'),
        variant: 'success',
        containerId: 'employees__container',
      });
    },
    handleSubmit() {
      this.updateSelectedEmployeeHr()
        .then(() => {
          if (this.canAccessPayrollEmployee) {
            this.fetchUserMissingAttributes();
          }

          const hasMissingFieldsAfterEdit = this.hasMissingMandatoryFieldsForPayroll('user_hr_file');
          if (this.hasMissingFieldsBeforeEdit && this.displayPayrollPreparation) {
            const hasRedirected = this.redirectToNextTabIfComplete('user_hr_file', () => this.$nextTick(this.showSuccessToaster));
            if (hasRedirected) {
              return;
            }

            if (this.displayPayrollPreparation && !hasMissingFieldsAfterEdit) {
              this.redirectToNextEmployee();
              this.reloadSidebar({
                cluster_node_id: this.navContext.clusterNodeId,
                with_missing_attributes: true,
                silent: true,
              });
            }
          }
          this.hasMissingFieldsBeforeEdit = this.hasMissingMandatoryFieldsForPayroll('user_hr_file');
          this.showSuccessToaster();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.update_employee.error_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    handleIdentityNumberKeyUp() {
      this.setInputEmployeeError({
        identityNumber: !this.isIdentityNumberValid,
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.employees__container__notification {
  padding: 0 0 15px;
  margin-top: -19px;
}

.ssn-checkbox-wrapper {
  display: flex;
  padding-top: 4px;
}

.ssn-checkbox {
  margin-right: 8px;
}
</style>
