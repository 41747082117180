<template>
  <div
    v-tooltip.top.text-align-left="tooltip"
    class="activity-icon__container"
    v-on="$listeners"
  >
    <ClipboardV2Icon
      :width="width"
      :height="height"
      :fill="fillColor"
    />
    <div
      v-if="shouldDisplayActivityBubble"
      class="activity-icon__bubble"
      :style="{ border: `1px solid ${shiftColor}`, backgroundColor: `${fillColor}` }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@skello-utils/array';

export default {
  name: 'ActivityIcon',
  props: {
    note: {
      type: String,
      default: undefined,
    },
    tasks: {
      type: Array,
      default: undefined,
    },
    comments: {
      type: Array,
      default: undefined,
    },
    shiftColor: {
      type: String,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: undefined,
    },
    fillColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('planningsShifts', ['shouldDisplayTasks', 'shouldDisplayComments']),
    ...mapGetters('planningsState', ['isEmployeePlanningSpace']),
    shouldDisplayTaskOrComment() {
      // Until the EmployeeV3 space evolves we do not want to show tasks nor comments
      if (this.isEmployeePlanningSpace) return false;

      return (this.shouldDisplayTasks && !isEmpty(this.tasks)) ||
        this.shouldDisplayComments(this.comments);
    },
    checkedTasksCount() {
      return this.tasks.reduce((tasksNb, task) => {
        if (task.isChecked) tasksNb += 1;
        return tasksNb;
      }, 0);
    },
    shouldDisplayActivityBubble() {
      if (this.shouldDisplayComments(this.comments)) return true;
      return this.shouldDisplayTasks &&
        !isEmpty(this.tasks) &&
        this.checkedTasksCount > 0;
    },
    tasksInfo() {
      if (!this.shouldDisplayTasks || isEmpty(this.tasks)) return '';

      return this.$tc(
        'plannings.table.manage_shift_modal.tabs.shift.activity_icon.tasks',
        this.checkedTasksCount,
        { count: this.checkedTasksCount, total: this.tasks.length },
      );
    },
    commentsInfo() {
      if (!this.shouldDisplayComments(this.comments)) return '';

      return this.$tc('plannings.table.manage_shift_modal.tabs.shift.activity_icon.comments', this.comments.length);
    },
    tooltip() {
      if (this.shouldDisplayTaskOrComment) {
        let res = '';
        if (this.shouldDisplayTasks && !isEmpty(this.tasks)) res += `${this.tasksInfo}<br>`;
        if (this.note) res += `${this.$t('plannings.table.manage_shift_modal.tabs.shift.activity_icon.note')}<br>`;
        if (this.shouldDisplayComments(this.comments)) res += `${this.commentsInfo}`;
        return res;
      }

      return this.note;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-icon__container {
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}

.activity-icon__bubble {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid rgb(2, 135, 199);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
