<template>
  <div class="day-cell__daily-total__wrapper">
    <div>
      {{ $t('plannings.table.cells.daily_total.employees_count', { employeesCount }) }}
    </div>
    <div>
      {{ totalDuration }}
    </div>
  </div>
</template>
<script>
import skDate from '@skello-utils/dates';
import { uniq } from '@skello-utils/collection';

export default {
  name: 'DailyTotal',
  props: {
    shifts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    employeesCount() {
      return uniq(this.shifts.map(shift => shift.attributes.userId)).length;
    },
    totalDuration() {
      const totalDurationSeconds = this.shifts.reduce((durationAccumulator, currentShift) => (
        durationAccumulator + currentShift.attributes.durationInSeconds
      ), 0);

      const skDuration = skDate.duration(totalDurationSeconds, 's');

      // We can't use .hours because duration can exceed 24h
      const hours = Math.floor(skDuration.asHours());
      const minutes = skDuration.minutes() < 10 ? `0${skDuration.minutes()}` : skDuration.minutes();
      return `${hours}h${minutes}`;
    },
  },
};
</script>
<style lang="scss" scoped>
  .day-cell__daily-total__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 2.6em;
    padding: 6px 3px 0;
    font-size: 1.1em;
    color: $sk-grey-50;
  }
</style>
