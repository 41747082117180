<template>
  <td
    :class="editableCellClasses"
    :style="cellWidth"
    @click="handleClick"
  >
    <SkInput
      v-if="editionMode"
      ref="editableCellInput"
      v-model="currentValue"
      class="editable-cell__input"
      type="number"
      min="0"
      height="54px"
      center
      @blur="handleBlur"
      @tab-press="handleTab"
    />
    <span
      v-else
      class="editable-cell__value"
    >
      {{ displayedValue }}
    </span>
  </td>
</template>

<script>
export default {
  name: 'EditableCell',
  props: {
    cyclicAmendment: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: '92px',
    },
  },
  data() {
    return {
      editionMode: false,
      currentValue: 0,
    };
  },
  computed: {
    editableCellClasses() {
      return {
        'editable-cell': true,
        'editable-cell--display-mode': !this.editionMode,
      };
    },
    displayedValue() {
      return this.currentValue === 0 ? '-' : `${this.currentValue}h`;
    },
    cellWidth() {
      return { width: this.width };
    },
  },
  mounted() {
    this.currentValue = this.cyclicAmendment.hours;
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();

      this.handleFocus();
    },
    handleFocus() {
      this.editionMode = true;
      this.currentValue = this.currentValue === 0 ? '' : this.currentValue;

      // setTimeout is required to allow editableCellInput to be rendered after setting
      // editionMode to true
      // $nextTick doesn't work for some reason ¨\_o_/¨
      setTimeout(() => {
        this.$refs.editableCellInput.focus();
      }, 100);
    },
    handleBlur() {
      if (!this.currentValue || isNaN(this.currentValue) || this.currentValue === Infinity) {
        this.currentValue = 0;
      } else if (this.currentValue >= 10000) {
        // value cannot be more than 2 digits
        // if it is, remove extra trailing digits
        const currentValueString = this.currentValue.toString();
        this.currentValue = parseInt(currentValueString.substr(0, 2), 10);
      }

      this.editionMode = false;

      if (this.currentValue !== this.cyclicAmendment.hours) {
        this.$emit('update-hours-value', this.currentValue);
      }
    },
    handleTab() {
      this.$emit('tab-press');
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-cell {
  text-align: center;
  cursor: pointer;

  &--display-mode:hover {
    background-color: $sk-grey-10;

    .editable-cell__value {
      border-bottom: 1px solid $sk-black;
    }
  }
}

.editable-cell__value {
  padding: 4px 3px;
}

// adjust SkInput to design specifications
.editable-cell__input ::v-deep .sk-input__input {
  background-color: white;
}

::v-deep .sk-input::before {
  top: 49px;
}
</style>
