<template>
  <div
    id="users"
    class="employees__wrapper"
  >
    <Toolbar />

    <div class="employees__main">
      <Sidebar />

      <div
        id="employees__container"
        class="employees__container"
      >
        <div
          v-if="showHeader"
          class="employees__header-wrapper"
        >
          <!-- Loading skeleton -->
          <SkCanvas
            v-if="(!employeeIsLoaded || pageLoading) && !error "
            skeleton
            overlay
          />

          <div v-else-if="employeeIsLoaded">
            <!-- Future archival notification banner -->
            <div
              v-if="showFutureArchivalBanner"
              class="employee-header__future-archival-banner"
            >
              <CircledIIcon
                class="future-archival-information"
                fill=""
              />
              {{ $t('employees.header.future_archival_banner', { date: formattedArchivalDate }) }}
              <SkPopover
                :title="$t('employees.header.archival_note_header')"
                placement="bottom-start"
                y-offset="12"
              >
                <template #anchor>
                  <div
                    v-if="employee.attributes.archiveNote"
                    class="future-archival-note-warning"
                  >
                    <span class="nipple" />
                    {{ $t('employees.header.show_archival_note') }}
                  </div>
                </template>
                <template #content>
                  <div class="future-archival-note-wrapper">
                    <p>{{ employee.attributes.archiveNote }}</p>
                  </div>
                </template>
              </SkPopover>
              <SkLoader
                v-if="cancelArchivalSpinner"
                class="hide-future-archival-banner"
              />
              <div
                v-else
                v-tooltip="$t('employees.header.cancel_future_archival_tooltip')"
                class="hide-future-archival-banner"
                @click="cancelFutureArchival"
              >
                {{ $t('employees.header.hide_future_archival_banner') }}
              </div>
            </div>

            <!-- Header : Full name & action buttons -->
            <Header />

            <!-- Tabs : personnal_info / salary_and_contract / hr_file / counters / documents -->
            <Navigation />
          </div>

          <!-- Generic Error -->
          <p
            v-else
            style="margin:20px 0;"
          >
            {{ $t('employees.error_message') }}
          </p>
        </div>

        <!-- Selected tab content -->
        <div
          v-if="employeeIsLoaded && !pageLoading"
          class="employees__router-content"
        >
          <router-view />
          <FollowUpDocsEsignatureSidePanel />
        </div>
        <MountingPortal
          append
          mount-to="#modals-portal"
        >
          <DocEsignatureUpsellModal
            @continue="showEsignatureUpsellModal"
          />
          <EsignatureUpsellModal
            :initial-step="'PriceStep'"
            @go-back="showDocumentEsignatureUpsellModal"
          />
          <AllEmployeesCompletedModal
            ref="allEmployeesCompletedModal"
            @hide="onSubmitAllEmployeesCompleteModal"
          />
        </MountingPortal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

import skDate from '@skello-utils/dates';
import Toolbar from './shared/components/Toolbar';
import Header from './shared/components/Header';
import Navigation from './shared/components/Navigation';
import Sidebar from './shared/components/Sidebar';
import FollowUpDocsEsignatureSidePanel from './shared/components/FollowUpDocsEsignatureSidePanel';
import DocEsignatureUpsellModal from '../../app/shared/components/DocEsignatureUpsellModal';
import EsignatureUpsellModal from '../plannings/shared/components/Toolbar/EsignatureUpsellModal';
import AllEmployeesCompletedModal from '../../app/shared/components/PayrollPreparation/AllEmployeesCompletedModal';

export default {
  name: 'Users',
  components: {
    Toolbar,
    Sidebar,
    Header,
    Navigation,
    FollowUpDocsEsignatureSidePanel,
    DocEsignatureUpsellModal,
    EsignatureUpsellModal,
    AllEmployeesCompletedModal,
  },
  /**
   * Triggered when
   * - selecting an employee in the sidebar (:user_id is updated)
   * - selecting a shop from the navbar (:shop_id is updated)
   * - tab navigation
   */
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      vm.pageLoading = true;
      vm.setShowIncompleteEmployees(to.query?.show_incomplete_employees ?? false);
      if (to.query.show_new_employee_modal) {
        vm.emitOnRoot(MODAL_SHOW_EVENT, event, 'add-employee-modal');
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    // The goal of this condition is to have a speficic behavior when:
    // the user has just activated payroll preparation feature and clicks on the back button
    // in this case we want to disable Payroll preparation feature
    // (popstate event is triggered when clicking on back button of the browser)
    if (window?.event?.type === 'popstate') {
      if (from.query?.incomplete_profiles_just_activated === true) {
        this.setShowIncompleteEmployees(false);
      }
      if (this.$refs.allEmployeesCompletedModal.isVisible) {
        this.emitOnRoot('hide-all-employees-complete-modal');
      }
    }
    // shop_id is updated, or force reload: reload shop + employee sidebar
    if (String(to.params.shop_id) !== this.currentShop.id || to.params.reload) {
      const { shop_id: shopId } = to.params;
      await this.updateCurrentShop({ shopId });

      // click on 'all shops' in navbar in clusters for a shop
      if (to.query.cluster_node && to.params.shop_id === 'all') {
        this.selectClusterNode({ clusterNode: to.query.cluster_node });
      } else if (to.params.shop_id === 'all') {
        this.selectOrganisation(this.currentOrganisation);
      } else {
        this.selectShop(this.currentShop);

        // This avoids the next (user_personal) because in this case the watcher
        // from app.vue will handle the shop cancelled router push
        if (this.currentShop.attributes.cancelled) {
          next();
          return;
        }

        // same condition than the `isUserRedirectedToAdminOnboarding` function located
        // in app/javascript/src/v3/app/app.vue
        if (
          this.isSystemAdmin &&
          this.config &&
          (
            this.shouldShowAdminOnboarding ||
            this.shouldShowTimeclockOnboarding
          )
        ) {
          next();
          return;
        }
      }

      await this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });

      const employeeId =
        (this.employees.length > 0 && (to.params.select_first || !to.params.user_id)) ?
          this.employees[0].id : to.params.user_id;

      if (this.canAccessPayrollEmployee) {
        // Need this fetch to show payroll notif sidebar due to reactivity when we change shop
        this.fetchUserMissingAttributes();
      }
      this.pageLoading = false;

      this.setShowIncompleteEmployees(false);

      next({
        name: this.getRouteNameForPayrollPreparation,
        params: { user_id: employeeId, shop_id: to.params.shop_id },
      });
    // click on 'all shops' in navbar in clusters from a cluster
    } else if (!to.params.user_id) {
      this.selectClusterNode({ clusterNode: to.query.cluster_node });
      this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId }).then(() => {
        this.redirectToFirstEmployee();
      });
      // user_id is updated: select given employee
    } else if (from.params.user_id !== to.params.user_id) {
      this.fetchEmployee({
        id: to.params.user_id,
        cluster_node_id: this.navContext.clusterNodeId,
        with_variable_hours: this.isVariableContractHoursAvailable,
        with_annualization_data: true,
        with_shop_extended_info: true,
      }).then(() => {
        if (!this.hasMissingAttributesForPayroll(this.employee.id)) {
          this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });
          this.setShowIncompleteEmployees(false);
        }
        if (this.displayPayrollPreparation) {
          next({
            name: this.getRouteNameForPayrollPreparation,
          });
        }
      });

      this.pageLoading = false;
      next();
    } else {
      this.pageLoading = false;
      next();
    }
  },
  data() {
    return {
      updatePending: false,
      forceHideArchivalBanner: false,
      cancelArchivalSpinner: false,
      pageLoading: false,
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('selectedEmployee', ['employee', 'error']),
    ...mapState('employees', ['employees', 'activeTab']),
    ...mapState('config', ['config']),
    ...mapState('report', ['clusterNodes']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('selectedEmployee', [
      'isCurrentlyArchived',
      'hasFutureArchivalDate',
      'fullName',
      'employeeIsLoaded',
    ]),
    ...mapGetters('employees', [
      'displayPayrollPreparation',
      'getRouteNameForPayrollPreparation',
      'canAccessPayrollEmployee',
    ]),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable', 'isDevFlagEnabled', 'shouldShowAdminOnboarding']),
    ...mapGetters('timeclockOnboarding', ['shouldShowTimeclockOnboarding']),
    ...mapGetters('currentLicense', ['canArchiveAndRestoreUsers']),
    ...mapGetters('currentUser', ['isSystemAdmin']),

    showFutureArchivalBanner() {
      if (!this.employee.attributes.archivedAt) {
        return false;
      }

      return this.canArchiveAndRestoreUsers && this.hasFutureArchivalDate;
    },
    formattedArchivalDate() {
      return skDate(this.employee.attributes.archivedAt).format('ll');
    },
    isEmployee() {
      return Object.values(this.config.licenses_by_level.employees).includes(
        this.currentLicense.attributes.originalType,
      );
    },
    trackingOptions() {
      return {
        update: 'update_employee',
      };
    },
    showHeader() {
      return !this.$route.meta.removeEmployeeHeader;
    },
  },
  watch: {
    employee() {
      this.setBrowserTabTitle();
    },
  },
  async created() {
    if (this.currentShop.id === 'all') {
      this.selectOrganisation(this.currentOrganisation);
    }
    try {
      await this.fetchClusterNodes();
      await this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });

      if (this.canAccessPayrollEmployee) {
        this.fetchUserMissingAttributes();
      }

      const employeeId = this.$router.currentRoute.params.user_id;

      if (employeeId) {
        try {
          await this.fetchEmployee({
            id: employeeId,
            cluster_node_id: this.navContext.clusterNodeId,
            with_variable_hours: this.isVariableContractHoursAvailable,
            with_annualization_data: true,
            with_shop_extended_info: true,
          });
        } catch {
          this.redirectUser();
        }
      } else {
        // If no user_id is given, select the first one in the sidebar
        this.redirectToFirstEmployee();
      }
    } catch {
      this.redirectUser();
    } finally {
      this.pageLoading = false;
    }
  },
  destroyed() {
    this.resetBrowserTabTitle();
  },
  methods: {
    ...mapActions('employees', ['reloadSidebar', 'fetchUserMissingAttributes']),
    ...mapActions('selectedEmployee', [
      'fetchEmployee',
      'unarchiveEmployee',
    ]),
    ...mapActions('navContext', ['selectOrganisation', 'selectShop', 'selectClusterNode']),
    ...mapActions('currentShop', ['updateCurrentShop']),
    ...mapActions('report', ['fetchClusterNodes']),
    ...mapMutations('employees', ['setActiveTab', 'setShowIncompleteEmployees', 'setHasAtLeastOneIncompleteProfile']),

    onSubmitAllEmployeesCompleteModal() {
      const activeTab = this.isCurrentlyArchived ? 'archived' : 'active';

      this.setShowIncompleteEmployees(false);
      this.setHasAtLeastOneIncompleteProfile(false);
      this.setActiveTab({ activeTab });
      this.reloadSidebar({ cluster_node_id: this.navContext.clusterNodeId });
    },
    hideFutureArchivalBanner() {
      this.forceHideArchivalBanner = true;
    },
    setBrowserTabTitle() {
      this.$nextTick(() => {
        document.title = this.$t('employees.tab_title', {
          employeeFullName: this.fullName,
        });
      });
    },
    resetBrowserTabTitle() {
      document.title = this.$t('tab_titles.default');
    },
    cancelFutureArchival() {
      this.cancelArchivalSpinner = true;
      this.unarchiveEmployee({ employee_id: this.employee.id }).then(() => {
        this.cancelArchivalSpinner = false;
      });
    },
    redirectToFirstEmployee() {
      if (this.employees.length === 0) {
        return;
      }
      const firstId = this.employees[0].id;
      const shopId = this.$router.currentRoute.params.shop_id || 'all';
      this.pageLoading = false;

      if (this.displayPayrollPreparation) {
        this.$router.replace({
          name: this.getRouteNameForPayrollPreparation,
          params: { user_id: firstId, shop_id: shopId },
        });
      } else {
        this.$router.replace({ name: 'user_personal', params: { user_id: firstId, shop_id: shopId } });
      }
    },
    hasMissingAttributesForPayroll(userId) {
      const user = this.employees.find(({ id }) => id === userId);
      return user?.attributes?.payrollMissingAttributes?.length > 0;
    },
    redirectUser() {
      this.$skToast({
        message: this.$t('employees.permission_missing'),
        variant: 'error',
        containerId: 'employees__container',
      });

      setTimeout(() => {
        if (this.isEmployee) {
          this.$router.push({ name: 'plannings_employee' });
        } else {
          this.$router.push({ name: 'home_dashboard' });
        }
      }, 1500);
    },
    showEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'doc-esignature-upsell-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'esignature-upsell-modal');
    },
    showDocumentEsignatureUpsellModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'esignature-upsell-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'doc-esignature-upsell-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
div.employees__wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .employees__main {
    display: flex;
    flex: 1;
    // Do not scroll wrapper: search bar must remain on top
    overflow-y: hidden;

    div.employees__container {
      flex: 1;
      overflow: auto;

      div.employees__header-wrapper,
      ::v-deep div.employees__content-wrapper {
        padding: 0 30px;
        max-width: 1140px;
      }

      ::v-deep .employee-sticky-bar {
        position: sticky;
        bottom: 0;
      }
    }
  }
}

.employee-header__future-archival-banner {
  width: 100%;
  height: 44px;
  background-color: $sk-blue-5;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: $sk-blue;
  font-size: 13px;

  .future-archival-information {
    fill: $sk-blue;
    margin-right: 15px;
  }

  .future-archival-note-warning {
    font-weight: $fw-semi-bold;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;

    &:hover {
      text-decoration: underline;
    }
  }

  .future-archival-note-wrapper {
    padding-top: 8px;
    width: 300px;
  }

  .hide-future-archival-banner {
    font-weight: $fw-semi-bold;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
