<template>
  <div class="requests-table">
    <SkTable
      :key="currentRequestPage"
      class="requests-table__SkTable"
      reset-arrows-on-sort
      filled-arrow
      :columns="headerLabels"
      :is-empty="isEmpty"
      @sort="handleSort"
    >
      <component
        :is="requestRow"
        v-for="(request, index) in requests"
        :ref="`leaveRequestRow${index}`"
        :key="request.id"
        :request="request"
        :columns="requestsTableHeaders"
        @hide-action-dropdowns="hideActionDropdowns"
      />
      <template #empty-state>
        <p class="requests-table__empty-state">
          {{ $t('requests.filters.no_filtered_requests') }}
        </p>
      </template>
    </SkTable>
    <div
      v-if="tablePagination.total_pages > 1"
      class="user-requests__paginate__navigation__wrapper"
    >
      <SkPaginate
        v-model="tablePagination.current_page"
        :total-pages="tablePagination.total_pages"
        @new-page="changePage"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
  mapState,
} from 'vuex';

import RoutesMixin from '@app-js/requests/mixins/RoutesMixins';
import LeaveRequestsOwnRow from './LeaveRequests/LeaveRequestsOwnRow.vue';
import LeaveRequestsTeamRow from './LeaveRequests/LeaveRequestsTeamRow.vue';
import AvailabilityRequestsTeamRow from './AvailabilityRequests/AvailabilityRequestsTeamRow.vue';
import AvailabilityRequestsOwnRow from './AvailabilityRequests/AvailabilityRequestsOwnRow.vue';

const columnNameToParam = new Map([
  ['dates', 'start_date'],
  ['employee', 'user_last_name'],
  ['sent_at', 'created_at'],
  ['status', 'status'],
]);

const svcColumnNameToParam = new Map([
  ['dates', 'starts_at'],
  ['employee', 'employee.last_name'],
  ['sent_at', 'leave_requests.created_at'],
  ['status', 'status'],
]);

const columnNameToTracker = new Map([
  ['employee', 'employee'],
  ['dates', 'absence_date'],
  ['sent_at', 'send_date'],
  ['status', 'status'],
]);

export default {
  name: 'RequestsTable',
  components: {
    LeaveRequestsOwnRow,
    LeaveRequestsTeamRow,
    AvailabilityRequestsTeamRow,
    AvailabilityRequestsOwnRow,
  },
  mixins: [RoutesMixin],
  computed: {
    ...mapState('requests', [
      'leaveRequests',
      'availabilityRequestsTableHeaders',
      'availabilityRequests',
      'pagination',
      'availabilitiesPagination',
      'leaveRequestsTableHeadersSingleShop',
      'leaveRequestsTableHeadersAllShops',
    ]),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('requests', [
      'areFiltersActive',
      'tableFilters',
      'currentShopIds',
    ]),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentNodeShops']),
    currentRequestPage() {
      const requestType = this.isLeaveRequestsView ? 'leave_requests' : 'availability_requests';

      return this.isOwnRequestsView ? `${requestType}_own` : `${requestType}_teams`;
    },
    tablePagination() {
      return this.isLeaveRequestsView ? this.pagination : this.availabilitiesPagination;
    },
    isEmpty() {
      if (this.tablePagination.current_page > 1 && this.requests.length === 0) {
        this.changePage(this.tablePagination.current_page - 1);

        return false;
      }

      return this.requests.length === 0;
    },
    leaveRequestsTableHeaders() {
      return this.isAllRequestsView ?
        this.leaveRequestsTableHeadersAllShops :
        this.leaveRequestsTableHeadersSingleShop;
    },
    requestsTableHeaders() {
      const requestTypeHeaders = this.isLeaveRequestsView ?
        this.leaveRequestsTableHeaders :
        this.availabilityRequestsTableHeaders;
      return this.isOwnRequestsView ? requestTypeHeaders.own : requestTypeHeaders.teams;
    },
    requests() {
      return this.isLeaveRequestsView ? this.leaveRequests : this.availabilityRequests;
    },
    headerLabels() {
      const headerTableLabels = [];
      const requestKey = this.isLeaveRequestsView ? 'leave_requests' : 'availability_requests';
      Object.keys(this.requestsTableHeaders).forEach(header => {
        const title = this.$t(`requests.${requestKey}.${this.isOwnRequestsView ? 'own' : 'teams'}.table.headers.${header}`);
        headerTableLabels.push({
          title,
          sort: this.requestsTableHeaders[header].sort ?? false,
          defaultSort: this.requestsTableHeaders[header].defaultSort ?? null,
          name: this.requestsTableHeaders[header].name,
          icon: {
            icon: this.requestsTableHeaders[header].icon ?? null,
            tooltip: this.requestsTableHeaders[header].tooltip ?
              this.$t(`requests.${requestKey}.${this.isOwnRequestsView ? 'own' : 'teams'}.table.headers.tooltips.${header}`) :
              null,
            width: '12',
            height: '12',
          },
        });
      });

      return headerTableLabels;
    },
    requestRow() {
      const requestKey = this.isLeaveRequestsView ? 'LeaveRequests' : 'AvailabilityRequests';

      return this.isOwnRequestsView ? `${requestKey}OwnRow` : `${requestKey}TeamRow`;
    },
    useSvcRequests() {
      return this.isDevFlagEnabled('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1');
    },
  },
  methods: {
    ...mapActions('requests', ['fetchLeaveRequests']),
    ...mapMutations('requests', ['setIsFetchingLeaveRequests', 'setSort']),
    changePage(pageNumber = this.tablePagination.current_page) {
      this.$router.push({ query: { page: pageNumber } });
    },
    hideActionDropdowns() {
      for (let index = 0; index < this.leaveRequests.length; index++) {
        this.$refs[`leaveRequestRow${index}`][0].hideDropdown();
      }
    },
    async handleSort(_event, columnName, newDirection) {
      const sortParam = {
        column: this.useSvcRequests ?
          svcColumnNameToParam.get(columnName) :
          columnNameToParam.get(columnName),
        order: newDirection,
      };

      this.$skAnalytics.track('sort_leave_requests', { source: columnNameToTracker.get(columnName) });

      // fetch is handled by the redirection on RequestsPage when current page is not 1
      if (this.pagination.current_page !== 1) {
        this.setSort(sortParam);
        this.changePage(1);
      } else {
        const shopIds = this.isAllRequestsView ?
          this.currentNodeShops.map(shop => shop.id) :
          [this.currentShop.id];
        try {
          await this.fetchLeaveRequests({
            isOwnRequestsView: this.isOwnRequestsView,
            shopIds,
            userId: this.currentUser.id,
            pagination: this.pagination,
            sort: sortParam,
            filters: this.tableFilters,
            isAllRequestsView: this.isAllRequestsView,
          });

          this.setSort(sortParam);
        } catch (error) {
          this.$skToast({
            message: this.$t('requests.toast.error'),
            variant: 'error',
          });

          throw error;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.requests-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.requests-table__SkTable {
  margin-bottom: 0;
}

::v-deep .sk-table > thead {
  & th .table-column__label--sortable {
    margin-left: 0;
  }

  & .sk-table__empty__row .sk-table__empty__wrapper {
    align-items: flex-end;
    font-size: $fs-text-m;
    height: 48px;
    line-height: normal;
  }
}

// remove global styles applied to p HTML tag by boostrap
p {
  margin: 0;
}
</style>

<style lang="scss">
.request__shopname-row,
.availability__shopname-row {
  max-width: 148px;
  min-width: 148px;
}

.request__employee-row,
.availability__employee-row {
  max-width: 148px;
  min-width: 148px;
}

.request__duration-row,
.availability__duration-row {
  max-width: 148px;
  min-width: 148px;
}

.request__dates-row,
.availability__dates-row {
  max-width: 148px;
  min-width: 148px;
}

.availability__creation-date-row {
  max-width: 148px;
  min-width: 110px;
}

.request__type-row,
.availability__type-row {
  max-width: 148px;
  min-width: 148px;
}

.request__manager-row,
.availability__manager-row {
  max-width: 148px;
  min-width: 148px;
}

.request__asked-row,
.availability__asked-row {
  max-width: 148px;
  min-width: 148px;
}

.request__status-row,
.availability__status-row {
  max-width: 148px;
  min-width: 148px;
}

.request__actions-row {
  min-width: 57px;
  max-width: 57px;
  width: 57px;

  &--button {
    width: 28px;
  }
}

.user-requests__paginate__navigation__wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>
