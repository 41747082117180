<template>
  <!-- eslint-disable  max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 120 130"
  >
    <path
      d="M103.075 47.3046H96.7043C100.853 40.9528 100.14 32.3543 94.5621 26.7766C88.1648 20.3794 77.7928 20.3794 71.3955 26.7766L69.9277 28.2445V20.2474C69.9279 9.06516 60.8627 0 49.6805 0C38.4982 0 29.433 9.06516 29.433 20.2474V29.0318H26.0616C11.6681 29.0318 0 40.6999 0 55.0934C0 69.4868 11.6681 81.1549 26.0616 81.1549H103.075C112.422 81.1549 120 73.5773 120 64.2298C120 54.8824 112.422 47.3046 103.075 47.3046Z"
      fill="#EBF4FF"
    />
    <path
      d="M103.075 47.3038H96.7041C100.853 40.952 100.14 32.3535 94.5619 26.7758C88.1646 20.3786 77.7926 20.3786 71.3953 26.7758L69.9274 28.2437V20.2466C69.9274 9.11496 60.9436 0.0847266 49.8314 0.00292969V81.1541H103.075C112.422 81.1541 120 73.5765 120 64.229C120 54.8814 112.422 47.3038 103.075 47.3038Z"
      fill="#B6D8FF"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M96.726 104.701L36.1033 104.994C33.4082 105.007 31.213 102.833 31.1999 100.138L31.046 68.3418L101.428 68.0011L101.582 99.7973C101.595 102.493 99.4211 104.688 96.726 104.701Z"
        fill="#FAF8F8"
      />
      <path
        d="M101.556 75.1692H31.1722L28.9925 49.5143C28.6577 46.3288 31.1554 43.5547 34.3584 43.5547H98.3692C101.572 43.5547 104.07 46.3288 103.735 49.5143L101.556 75.1692Z"
        fill="#E6EBF2"
      />
      <path
        d="M98.3686 43.5547H67.7961V75.1692H101.555L103.734 49.5143C104.069 46.3288 101.571 43.5547 98.3686 43.5547Z"
        fill="#E6EBF2"
      />
      <path
        d="M34.4373 35.7689C34.4373 32.0101 37.4845 28.9629 41.2434 28.9629C45.0022 28.9629 48.0494 32.0101 48.0494 35.7689V43.565H34.4373V35.7689Z"
        fill="#BBC4D0"
      />
      <path
        d="M82.2036 35.7689C82.2036 32.0101 85.2508 28.9629 89.0097 28.9629C92.7686 28.9629 95.8157 32.0101 95.8157 35.7689V43.565H82.2036V35.7689Z"
        fill="#727272"
      />
      <circle
        cx="41.2447"
        cy="36.266"
        r="3.34115"
        fill="#eef4ff"
      />
      <circle
        cx="89.011"
        cy="37.0072"
        r="3.34115"
        fill="#eef4ff"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.5083 56.4068L79.8549 54.5723C79.7761 54.3487 79.7456 54.1581 79.8371 53.9574L80.6888 52.105C80.8693 51.7442 80.8286 51.6604 80.4218 51.668L78.3397 51.7087C78.1033 51.7112 78.0728 51.6554 77.9177 51.5055L76.489 50.1205C76.1992 49.841 76.0771 49.8741 75.9653 50.2603L75.4212 52.1736C75.3602 52.382 75.3628 52.4454 75.1568 52.5598L73.3315 53.566C72.9781 53.7617 72.9831 53.8557 73.3213 54.0793L74.9865 55.2557C75.1644 55.3828 75.2331 55.5657 75.2763 55.7995L75.6297 57.7128C75.6907 58.1143 75.7848 58.1397 76.1077 57.8958L77.6356 56.7193C77.7932 56.5974 78.0754 56.5212 78.2737 56.5491L80.1829 56.8032C80.5871 56.854 80.6533 56.7853 80.5083 56.4068Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.621 69.2933C67.6454 64.0997 66.603 58.9772 65.8531 55.923C65.0142 52.508 60.2652 53.3185 61.2363 56.9318C62.1744 60.423 62.5125 63.0783 61.5618 67.9898L63.621 69.2933Z"
      fill="#d03e50"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.535 73.7004L79.2762 64.7818C79.6245 64.4337 79.6245 63.8645 79.2762 63.5164L76.4796 60.7239C76.1313 60.3759 75.5618 60.3759 75.2136 60.7239L66.2902 71.4568L68.535 73.7004Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M82.8352 79.4987C86.397 80.6498 87.4469 75.9516 84.0759 74.9403C81.0861 74.0434 76.0778 72.7577 70.7314 76.4039L72.0814 78.5739C76.8584 77.931 79.4591 78.4062 82.8352 79.4987Z"
      fill="#d03e50"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.6209 69.2942C67.6453 64.1006 66.6029 58.9781 65.853 55.9239C65.3801 53.9928 63.6514 53.4109 62.4184 53.9267C63.0336 54.2596 63.5548 54.877 63.7836 55.8095C64.4929 58.7011 65.4666 63.4501 62.1439 68.3591L63.6209 69.2942Z"
      fill="#BD0730"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.5352 73.7016L79.2763 64.7829C79.6247 64.4349 79.6247 63.8657 79.2763 63.5176L78.778 63.0195L67.348 72.5125L68.5352 73.7016Z"
      fill="#FDB440"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M82.8354 79.4992C86.3006 80.6197 87.3886 76.2035 84.343 75.0322C84.3074 76.2976 83.2702 77.4664 81.3228 77.1056C78.0381 76.4958 75.5035 76.3408 71.3316 77.3699L72.0816 78.5743C76.8586 77.9314 79.4593 78.4066 82.8354 79.4992Z"
      fill="#BD0730"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.7281 68.6624L51.2426 63.4535C51.2019 63.0215 50.6935 62.7801 50.2714 62.8945L46.5546 63.8906C46.1326 64.0024 45.8097 64.4649 45.9927 64.8612L48.1791 69.6128C48.3621 70.0091 48.7282 70.2861 49.1502 70.1743L51.1662 69.6331C51.5883 69.5213 51.7688 69.0969 51.7281 68.6624Z"
      fill="#2b66fe"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.374 56.9523L57.1816 55.6742C57.5476 55.4303 57.5528 55.3287 57.1688 55.1178L55.1884 54.0252C54.9647 53.9007 54.9672 53.8321 54.9011 53.606L54.3088 51.5301C54.1893 51.1083 54.0571 51.0727 53.7419 51.3776L52.1911 52.8792C52.0208 53.0444 51.9902 53.1053 51.7335 53.1003L49.4709 53.057C49.031 53.0469 48.9853 53.1383 49.1836 53.5322L50.1089 55.5421C50.2081 55.758 50.175 55.9664 50.0886 56.2104L49.3793 58.1999C49.2216 58.6089 49.2954 58.6852 49.7327 58.6293L51.8046 58.3524C52.0207 58.3244 52.3258 58.4057 52.4987 58.5379L54.1537 59.816C54.5045 60.0802 54.6063 60.0523 54.6749 59.6178L55.0562 57.5419C55.1046 57.2877 55.1782 57.0896 55.374 56.9523Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88.2658 68.5392L89.1454 63.3836C89.2192 62.9542 88.7895 62.5908 88.3522 62.5908H84.5032C84.0685 62.5908 83.6363 62.9542 83.71 63.3836L84.5922 68.5392C84.6659 68.9686 84.9481 69.3319 85.3854 69.3319H87.4726C87.9073 69.3319 88.192 68.9686 88.2658 68.5392Z"
      fill="#2b66fe"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.5004 87.2375L88.919 85.5326C89.2114 85.2022 89.1885 85.1031 88.7614 84.9964L86.5623 84.4526C86.3131 84.3916 86.2979 84.3256 86.1759 84.1223L85.0649 82.2674C84.8412 81.8913 84.704 81.8913 84.4777 82.2674L83.3667 84.1223C83.2447 84.3255 83.2294 84.3916 82.9803 84.4526L80.7812 84.9964C80.3542 85.1031 80.3338 85.2023 80.6262 85.5326L82.0423 87.2375C82.1948 87.4205 82.2151 87.6314 82.1948 87.888L82.027 89.997C81.9787 90.434 82.0702 90.4873 82.4796 90.3222L84.4117 89.5167C84.6151 89.4329 84.9303 89.4329 85.1311 89.5167L87.0657 90.3222C87.4725 90.4873 87.5641 90.434 87.5183 89.997L87.348 87.888C87.3276 87.6314 87.3479 87.4204 87.5004 87.2375Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M77.8322 90.1125L73.8993 87.3277C73.5713 87.0939 73.1493 87.0431 72.8647 87.3277L71.5071 88.6845C71.2223 88.9691 71.2732 89.3909 71.5071 89.7187L74.2934 93.6495C74.5247 93.9773 75.0409 93.9341 75.3255 93.6495L77.8322 91.1441C78.117 90.8595 78.1602 90.3437 77.8322 90.1125Z"
      fill="#2b66fe"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.0868 75.5747C71.4054 74.6091 70.6199 73.6283 69.7733 72.6729C69.4046 72.2563 69.0233 71.8446 68.6344 71.4406C68.6293 71.433 68.6216 71.4253 68.614 71.4203C68.1895 71.0086 67.7573 70.6097 67.3175 70.2209C66.3972 69.4103 65.4565 68.6582 64.5261 67.9976C64.5235 67.9976 64.5235 67.9976 64.5235 67.9976C63.9998 67.6242 63.4812 67.2836 62.9728 66.9787C62.5889 66.7475 62.2126 66.5392 61.844 66.3511C59.2254 65.0248 57.0289 64.8571 56.2128 66.8212C56.195 66.8618 56.0526 67.2481 55.9433 67.4869L55.1145 69.8144L53.7214 73.7274L51.7054 79.3911L50.1215 83.8479L48.3546 88.8077C47.9198 90.0325 48.0521 90.9447 48.6139 91.4096C49.0791 91.9712 49.9918 92.1033 51.2171 91.6688L56.1797 89.9029L60.6388 88.3199L66.3056 86.305L70.2207 84.9125L72.5494 84.0842C72.7884 83.9749 73.1748 83.8327 73.2155 83.8149C75.1298 83.0221 75.0205 80.9132 73.7824 78.3824C73.5638 77.9327 73.307 77.4702 73.0223 76.9977C72.7402 76.5302 72.4275 76.055 72.0893 75.5773C72.0868 75.5772 72.0868 75.5747 72.0868 75.5747Z"
      fill="#d03e50"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M69.7731 72.6737C69.4044 72.257 69.0231 71.8454 68.6342 71.4414C68.6291 71.4338 68.6214 71.4261 68.6138 71.421C68.1893 71.0094 67.7571 70.6105 67.3173 70.2217C67.2919 70.2014 67.269 70.1785 67.2435 70.1582C66.374 69.3958 65.4842 68.6844 64.6046 68.0543C64.5792 68.0365 64.5512 68.0161 64.5258 67.9984C64.5232 67.9984 64.5232 67.9984 64.5232 67.9984C63.9995 67.6249 63.4809 67.2844 62.9725 66.977C62.5962 66.7534 62.2199 66.5425 61.8437 66.3519C60.7658 65.8056 59.7564 65.4575 58.8896 65.3711C59.843 65.9326 60.7937 66.5984 61.6912 67.2997C61.9454 67.4978 62.1971 67.6986 62.4412 67.9019C62.7666 68.1687 63.0792 68.438 63.3818 68.7048C63.5191 68.8242 63.6538 68.9462 63.7912 69.0681V69.0706C64.0911 69.3425 64.3886 69.6195 64.6784 69.9015L64.7012 69.9218C65.9393 71.205 67.1596 72.6508 68.1537 74.0584C68.7663 74.9173 69.402 75.9514 69.8468 76.8636C71.0849 79.3969 71.1942 81.5033 69.2799 82.2986C69.2723 82.3011 69.2495 82.3087 69.2189 82.3215C69.7045 83.2488 70.0528 84.1255 70.2206 84.9132L72.5493 84.0848C72.7882 83.9755 73.1747 83.8333 73.2154 83.8155C75.1297 83.0227 75.0204 80.9138 73.7823 78.383C73.7747 78.3652 73.767 78.35 73.7569 78.3322C73.5459 77.9028 73.2942 77.448 73.0222 76.9983C72.9993 76.9601 72.9739 76.9221 72.951 76.8814C72.951 76.8814 72.9485 76.8788 72.9485 76.8763C72.6714 76.424 72.3765 75.9819 72.0892 75.5779C72.0867 75.5779 72.0867 75.5754 72.0867 75.5754C71.4587 74.6835 70.7418 73.7815 69.9715 72.8972C69.9053 72.8212 69.8392 72.7475 69.7731 72.6737Z"
      fill="#BD0730"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.2206 84.9136C69.6766 82.3473 67.2055 78.8231 64.259 75.7739C61.2082 72.829 57.6821 70.3592 55.1143 69.8154L53.7212 73.7284C55.9889 74.7651 58.6329 76.7598 60.9947 79.0364C63.2726 81.397 65.2682 84.0395 66.3055 86.306L70.2206 84.9136Z"
      fill="#FFD473"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.6386 88.321L66.3054 86.3061C65.952 85.5311 65.4842 84.7129 64.9325 83.877L58.9479 86.0037C59.5683 86.7787 60.1403 87.5562 60.6386 88.321Z"
      fill="#BD0730"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.2204 84.914C70.0527 84.1263 69.7043 83.2497 69.2188 82.3223C69.0891 82.3705 68.8044 82.4798 68.6137 82.5687L64.9325 83.8773C65.4841 84.7133 65.9519 85.5314 66.3053 86.3064L70.2204 84.914Z"
      fill="#FDB440"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.6388 88.3214C59.5685 86.6825 58.1677 84.9724 56.6398 83.3895C55.0559 81.8624 53.345 80.4624 51.7052 79.3926L50.1213 83.8494C51.2222 84.6904 52.3254 85.6432 53.3729 86.6545C54.3847 87.7014 55.3381 88.8041 56.1795 89.9044L60.6388 88.3214Z"
      fill="#FFD473"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M56.18 89.9026C55.6028 89.148 54.9723 88.3907 54.3063 87.6514L48.1083 89.8543C48.0422 90.5556 48.2252 91.0892 48.6142 91.4093C49.0794 91.9708 49.9921 92.103 51.2174 91.6685L56.18 89.9026Z"
      fill="#BD0730"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.6385 88.3212C60.1402 87.5564 59.5682 86.7789 58.9479 86.0039L54.3057 87.6529C54.9718 88.3924 55.6022 89.1495 56.1793 89.9042L60.6385 88.3212Z"
      fill="#FDB440"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.3737 56.9523L57.1813 55.6742C57.5473 55.4303 57.5525 55.3287 57.1685 55.1178L55.1881 54.0252C54.9644 53.9007 54.9669 53.8321 54.9008 53.606L54.3085 51.5301C54.189 51.1083 54.0568 51.0727 53.7416 51.3776L53.0577 52.0408C53.3348 52.9402 53.4874 53.9312 53.4874 54.9704C53.4874 56.3451 53.2204 57.6333 52.7552 58.7361L54.1535 59.816C54.5043 60.0802 54.606 60.0523 54.6746 59.6178L55.056 57.5419C55.1042 57.2877 55.1779 57.0896 55.3737 56.9523Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.7281 68.6624L51.2425 63.4535C51.2019 63.0215 50.6934 62.7801 50.2714 62.8945L49.4172 63.1232C49.5112 63.6745 49.5596 64.2513 49.5596 64.8433C49.5596 66.7186 49.0638 68.4311 48.2452 69.7372C48.4409 70.065 48.774 70.2733 49.1502 70.1742L51.1662 69.633C51.5883 69.5213 51.7688 69.0969 51.7281 68.6624Z"
      fill="#0A59DD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79.8548 54.572C79.7759 54.3484 79.7455 54.1579 79.837 53.9571L80.6887 52.1048C80.8692 51.744 80.8285 51.6602 80.4217 51.6678L78.3396 51.7084C78.1032 51.711 78.0726 51.6551 77.9176 51.5052L77.1346 50.748C77.2516 51.3578 77.3125 51.9982 77.3125 52.6588C77.3125 54.7475 76.6948 56.6354 75.7032 57.9821C75.7719 58.104 75.8863 58.0633 76.1074 57.8956L77.6354 56.7192C77.793 56.5972 78.0752 56.521 78.2734 56.5489L80.1827 56.8031C80.5869 56.8539 80.653 56.7853 80.5081 56.4067L79.8548 54.572Z"
      fill="#FFC33D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88.266 68.5392L89.1456 63.3836C89.2194 62.9542 88.7897 62.5908 88.3524 62.5908H86.677C86.7075 62.911 86.7228 63.2388 86.7228 63.5716C86.7228 65.8 86.0211 67.7972 84.9127 69.1565C85.0398 69.2658 85.1975 69.3319 85.3856 69.3319H87.4729C87.9074 69.3319 88.1922 68.9686 88.266 68.5392Z"
      fill="#0A59DD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.3254 93.6485L77.8321 91.1432C78.1168 90.8586 78.1601 90.3428 77.8321 90.1115L74.0568 87.4385C74.278 88.2541 74.3975 89.1358 74.3975 90.0582C74.3975 91.1533 74.2271 92.1925 73.9246 93.1276L74.2932 93.6485C74.5245 93.9764 75.0406 93.9331 75.3254 93.6485Z"
      fill="#0A59DD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M87.5006 87.2375L88.9193 85.5326C89.2116 85.2022 89.1887 85.1031 88.7616 84.9964L86.5625 84.4526C86.3134 84.3916 86.2981 84.3256 86.1761 84.1223L85.0652 82.2674C84.8414 81.8913 84.7042 81.8913 84.4779 82.2674L84.122 82.862C84.4728 83.853 84.6686 84.9684 84.6686 86.15C84.6686 87.4255 84.4397 88.6274 84.0356 89.6742L84.4118 89.5166C84.6152 89.4328 84.9305 89.4328 85.1312 89.5166L87.0659 90.3221C87.4726 90.4872 87.5642 90.4339 87.5184 89.9969L87.3481 87.8879C87.3278 87.6314 87.3481 87.4204 87.5006 87.2375Z"
      fill="#FFC33D"
    />
    <defs>
      <filter
        id="filter0_d"
        x="12.9624"
        y="20.9629"
        width="106.803"
        height="108.055"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.830556 0 0 0 0 0.88317 0 0 0 0 0.958333 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>

export default {
  name: 'HolidayIcon',
  props: {
    width: {
      type: String,
      default: '120',
    },
    height: {
      type: String,
      default: '130',
    },
  },
};
</script>
