<template>
  <tr class="pos-card__row">
    <td class="pos-card__shop-name-cell">
      {{ shop.attributes.name }}
    </td>
    <td class="pos-card__connection">
      <SkOroraButton
        variant="tertiary"
        :variant-color="isLinked ? $skColors.skError : null"
        @click.stop="onLinkClick"
      >
        {{ linkText }}
      </SkOroraButton>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'LadditionShopRow',
  props: {
    shop: {
      type: Object,
      required: true,
    },
    matchingShopsList: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLinked() {
      return Object.keys(this.matchingShopsList).includes(this.shop.id);
    },
    linkText() {
      return this.isLinked ?
        this.$t('organisation_settings.tabs.pos_partners.unlink_shop') :
        this.$t('organisation_settings.tabs.pos_partners.link_shop');
    },
    linkDataTest() {
      return this.isLinked ?
        'partner-tools-pos-addition__start_deactivation_shop' :
        'partner-tools-pos-addition__start_integration_shop';
    },
  },
  methods: {
    onLinkClick() {
      const emitEvent = this.isLinked ? 'laddition-unlink' : 'laddition-link';
      this.$emit(emitEvent, this.shop);
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-table > tbody {
  td {
    border-bottom: none;
  }
}

.pos-card__row {
  height: 60px;
  border-radius: 2px;
}

.pos-card__shop-name-cell {
  padding-left: 5px;
}

.pos-card__connection {
  text-align: right;
  padding-right: 20px;

  a {
    cursor: pointer;
  }
}
</style>
