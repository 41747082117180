<template>
  <SkModalSection class="add-employee-modal__profile-section">
    <div class="add-employee-modal__profile-section-header">
      <h3> {{ $t('employees.add_employee_modal.personal_data.title') }} </h3>
      <p class="sk-subtitle--medium">
        {{ $t('employees.add_employee_modal.personal_data.helper_text') }}
      </p>
    </div>
    <div class="row sk-form__row">
      <div class="col-6 sk-form__col">
        <SkInput
          v-model="firstName"
          :label="$t('employees.attributes.first_name.label')"
          name="employee[first_name]"
          data-test="add-employee-first_name"
        />
      </div>
      <div class="col-6 sk-form__col">
        <SkInput
          v-model="lastName"
          :label="$t('employees.attributes.last_name.label')"
          name="employee[last_name]"
          data-test="add-employee-last_name"
        />
      </div>
    </div>
    <div class="row sk-form__row">
      <div class="col-6 sk-form__col">
        <SkInput
          v-model="email"
          :label="$t('employees.attributes.email.label')"
          :errored="erroredEmail"
          :error-message="emailErrorMessage"
          :loading="validatingEmailUniqueness"
          name="employee[email]"
          data-test="add-employee-email"
          @keyup="handleEmailValidation"
        />
      </div>
      <div class="col-6 sk-form__col">
        <SkPhoneInput
          ref="AddEmployeeSkPhoneInput"
          v-model="phoneNumber"
          :default-country="defaultCountry"
          :error-message="$t('employees.attributes.phone_number.error_message')"
          :label="$t('employees.attributes.phone_number.label')"
          name="employee[phone_number]"
          data-test="add-employee-phone_number"
          @input="handlePhoneInput"
        />
      </div>
    </div>
  </SkModalSection>
</template>
<script>
import debounce from 'lodash/debounce';
import {
  mapMutations,
  mapState,
} from 'vuex';

import { isValidEmail } from '@skello-utils/validators';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'ProfileSection',
  data() {
    return {
      erroredEmail: false,
      emailErrorMessage: null,
      erroredPhone: false,
      phoneObject: { number: '', valid: false },
      validatingEmailUniqueness: false,
    };
  },
  computed: {
    ...mapState('newEmployee', ['newEmployee']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentShop', ['currentShop']),
    firstName: {
      get() {
        return this.newEmployee.attributes.firstName;
      },
      set(newValue) {
        this.setNewEmployeeAttributes({ firstName: newValue });
      },
    },
    lastName: {
      get() {
        return this.newEmployee.attributes.lastName;
      },
      set(newValue) {
        this.setNewEmployeeAttributes({ lastName: newValue });
      },
    },
    email: {
      get() {
        return this.newEmployee.attributes.email;
      },
      set(newValue) {
        this.setNewEmployeeAttributes({ email: newValue });
      },
    },
    phoneNumber: {
      get() {
        return this.newEmployee.attributes.phoneNumber;
      },
      set() {
        this.setNewEmployeeAttributes({ phoneNumber: this.phoneObject.number });
      },
    },
    defaultCountry() {
      // To determine what country to set by default multiple options are considered in that order:
      // 1. If phone number is already set and valid, use current phone number country
      // 2. If shop country is available use it
      // 3. If organisation country is available use it (used on 'all shops' view)
      // 4. Try to determine country based on language, en -> gb is hardcoded and it doesn't work
      //    for Switzerland and Belgium
      // 5. Default to France
      return this.currentShop.attributes.country ||
        this.currentOrganisation.attributes.headquartersCountry ||
        (this.$i18n.locale === 'en' ? 'gb' : this.$i18n.locale);
    },
  },
  mounted() {
    this.validateEmail = debounce(this.validateEmail.bind(this), 1000);
  },
  methods: {
    ...mapMutations('newEmployee', ['setNewEmployeeAttributes', 'catchEmployeeError']),
    resetData() {
      this.$refs.AddEmployeeSkPhoneInput.reset();
      Object.assign(this.$data, this.$options.data());
    },
    handleEmailValidation() {
      this.$emit('performing-request', true);
      this.validateEmail();
    },
    backendEmailValidation(email) {
      this.validatingEmailUniqueness = true;
      httpClient
        .get('/v3/api/email_validation', { params: { email } })
        .then(() => {
          this.erroredEmail = false;
          this.emailErrorMessage = null;
        })
        .catch(error => {
          this.erroredEmail = true;
          this.emailErrorMessage = error.response.data.message;
        })
        .finally(() => {
          this.$emit('performing-request', false);
          this.validatingEmailUniqueness = false;
          this.catchEmployeeError(this.erroredEmail);
        });
    },
    validateEmail() {
      this.erroredEmail = false;
      this.emailErrorMessage = this.$t('employees.attributes.email.error_message');
      if (this.newEmployee.attributes.email) {
        if (isValidEmail(this.newEmployee.attributes.email)) {
          this.backendEmailValidation(this.newEmployee.attributes.email);
        } else {
          this.erroredEmail = true;
        }
      }

      this.catchEmployeeError(this.erroredEmail);
      this.$emit('performing-request', false);
    },
    handlePhoneInput(_number, phoneObject) {
      this.phoneObject = phoneObject;
      this.erroredPhone = this.newEmployee.attributes.phoneNumber && !this.phoneObject.valid;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-employee-modal__profile-section-header {
  display: flex;
  justify-content: space-between;
}

.add-employee-modal__profile-section {
  padding-bottom: 30px !important;
}
</style>
