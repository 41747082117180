<template>
  <div
    id="quick-graphs"
    class="dashboard-section"
  >
    <div class="dashboard-title">
      <div class="flex-between full-width">
        <h2 v-if="isSingleShop">
          {{ $t('home_dashboard.chart.absenteism.shop_absenteism.title', { shopName }) }}
        </h2>
        <h2 v-else>
          {{ $t('home_dashboard.absenteism.title') }}
        </h2>
        <h3 class="dashboard-graph-button text-center">
          {{ $t('home_dashboard.absenteism.details') }}
        </h3>
      </div>
    </div>
    <p class="current-months">
      {{ currentMonths }}
    </p>
    <div class="dashboard-content dash-graph">
      <div id="prev-day-arrow">
        <div
          class="arrow"
          @click="replaceCanvas('prev')"
        >
          <ChevronLeftV2Icon
            fill="white"
            width="20"
            height="20"
          />
        </div>
      </div>
      <canvas id="absence_chart" />
      <div id="next-day-arrow">
        <div
          class="arrow"
          @click="replaceCanvas('next')"
        >
          <ChevronRightV2Icon
            fill="white"
            width="20"
            height="20"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Chart } from 'chart.js';
import { httpClient } from '@skello-utils/clients';
import {
  ChevronLeftV2Icon,
  ChevronRightV2Icon,
} from '@skelloapp/skello-ui';

export default {
  name: 'AbsenteismChart',
  components: { ChevronRightV2Icon, ChevronLeftV2Icon },
  data() {
    return {
      loadHelper: null,
      currentMonths: null,
      absencesLabels: null,
      absencesDatas: null,
      tooltips: null,
      actualDate: null,
      current: null,
      infos_absences_chart_path: '/infos_absences_chart',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
    shopName() {
      return this.currentShop.attributes.name;
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },
  methods: {
    getLastMonth() {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      return lastMonth;
    },
    resetCanvas() {
      document.getElementById('absence_chart').remove();
      const newCanvas = document.createElement('canvas');
      newCanvas.setAttribute('id', 'absence_chart');
      document.getElementById('prev-day-arrow').after(newCanvas);
    },
    drawAbsencesChart(animate) {
      const app = this;
      const ctx = document.getElementById('absence_chart');
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: app.absencesLabels,
          datasets: [
            {
              label: this.$t('home_dashboard.chart.absenteism.absences_chart.label'),
              data: app.absencesDatas,
              borderWidth: 1,
              backgroundColor: 'rgba(31,190,213,0.5)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            duration: animate ? 500 : 0,
            animateRotate: animate,
            animateScale: animate,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  callback(value, index, values) {
                    return index % 2 ? '' : value;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              beforeBody(items, data) {
                return app.tooltips[items[0].index];
              },
            },
          },
        },
      });
    },
    initialLoad() {
      this.actualDate = this.getLastMonth();
      this.replaceCanvas('next');
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'AbsenteismChart');
    },
    replaceCanvas(statut) {
      const params = {
        statut,
        actual_date: this.actualDate,
        current: this.current,
      };

      // View all is handled by not passing the shop id
      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.infos_absences_chart_path, { params })
        .then(response => {
          const data = response.data;
          this.current = statut;
          this.absencesLabels = data.labels;
          this.absencesDatas = data.datasets;
          this.tooltips = data.tooltips;
          this.resetCanvas();
          this.drawAbsencesChart(true);
          this.currentMonths = data.currentMonths;
          this.actualDate = new Date(data.new_actual_date);
        }).finally(() => {
          this.handleReloadDone();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#absence_chart {
  height: 330px;
}
</style>
