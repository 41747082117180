<template>
  <SkModalV2
    id="paid-leaves-counter-initialization-modal"
    ref="paidLeavesCounterInitializationModal"
    :title="modalTitle"
    @show="handleShow"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <div
        v-if="loading"
        class="paid-leaves-counter-modal__spinner"
      >
        <SkLoader size="large" />
      </div>
      <div v-else>
        <SkModalSectionV2 class="paid-leaves-counter-modal">
          <div class="paid-leaves-counter-modal__init-date">
            <div class="paid-leaves-counter-modal__datepicker-title">
              {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.date.title') }}
            </div>
            <div class="paid-leaves-counter-modal__datepicker-title__tooltip">
              <CircledQuestionMarkIcon
                v-tooltip.longform="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.date.tooltip')"
                width="18"
                height="30"
              />
            </div>
            <div class="paid-leaves-counter-modal__datepicker-wrapper">
              <SkDatePicker
                ref="paidLeavesCounterInitializationDatePicker"
                v-model="initialDate"
                :placeholder="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.date.placeholder')"
                :errored="!initDate"
                :error-message="$t('errors.missing_value')"
                :lang="$i18n.locale"
                input-moment-format="MMMM YYYY"
                type="month"
                hide-footer
                popup-extra-class="paid-leaves-counter-modal__datepicker-popup"
                data-test="paid-leaves-counter-modal__init-date__input"
              />
              <p class="paid-leaves-counter-modal__date-hint sk-subtitle--small">
                {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.date.hint') }}
              </p>
            </div>
          </div>
        </SkModalSectionV2>
        <slot name="middle-section" />
        <SkModalSectionV2
          :padding-bottom="false"
          border-bottom="none"
          class="paid-leaves-counter-modal"
        >
          <div class="paid-leaves-counter-modal__balance__header">
            <span class="paid-leaves-counter-modal__balance__header__title">
              {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.title') }}
            </span>
            <SkSwitch
              v-model="balanceStatus"
              v-tooltip="disabledHistoricTooltip"
              :disabled="isSwitchDisabled"
              class="paid-leaves-counter-modal__balance__switch"
            />
          </div>
          <p class="sk-subtitle--medium">
            {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.explanation') }}
          </p>
          <div v-if="balanceActivated">
            <slot name="balance" />
          </div>
          <div
            v-if="pendingWanted && balanceActivated"
            class="paid-leaves-counter-modal__balance__pending-banner"
          >
            <SkInnerBanner
              variant="danger"
              rounded
            >
              {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.pending_alert') }}
            </SkInnerBanner>
          </div>
        </SkModalSectionV2>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="loading || isSubmitDisabled"
        :loading="submitLoading"
        data-test="paid-leaves-counter-modal__submit__button"
        @click="handleSubmit"
      >
        {{ submitText }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
export default {
  name: 'PaidLeavesCounterInitializationModalContentWrapper',
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    handleShow: {
      type: Function,
      default: () => {},
    },
    handleSubmit: {
      type: Function,
      required: true,
    },
    handleConfirm: {
      type: Function,
      default: () => {},
    },
    handleCancel: {
      type: Function,
      required: true,
    },
    balanceActivated: {
      type: Boolean,
      required: true,
    },
    initDate: {
      type: String,
      default: null,
    },
    pendingWanted: {
      type: Boolean,
      default: false,
    },
    isSwitchDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitLoading: {
      type: Boolean,
      required: true,
    },
    areBalancesErrored: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    balanceStatus: {
      get() {
        return this.balanceActivated;
      },
      set(newValue) {
        this.$emit('update-balance-activated', newValue);
      },
    },
    initialDate: {
      get() {
        return this.initDate;
      },
      set(newValue) {
        this.$emit('update-init-date', newValue);
      },
    },
    submitText() {
      return this.pendingWanted ?
        this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.submit_pending') :
        this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.submit');
    },
    disabledHistoricTooltip() {
      if (!this.isSwitchDisabled || this.loading) return '';
      return this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.empty_users_explanation');
    },
    isSubmitDisabled() {
      if (this.balanceActivated) {
        return this.areBalancesErrored || !this.initDate;
      }
      return !this.initDate;
    },
  },
  watch: {
    balanceActivated(newValue) {
      if (newValue) return;
      this.$refs.paidLeavesCounterInitializationModal.resetScroll();
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-leaves-counter-modal__balance__pending-banner {
  ::v-deep .sk-inner-banner {
    font-size: $fs-text-m;
  }
}

.paid-leaves-counter-modal__spinner {
  display: flex;
  height: 220px;
  justify-content: center;
  align-items: center;
}

.paid-leaves-counter-modal {
  &__init-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__balance__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;

    ::v-deep .sk-switch-v3 {
      height: 0;
    }
  }

  &__datepicker-title {
    width: 155px;
    font-weight: $fw-semi-bold;
    line-height: 22px;
    display: flex;

    &__tooltip {
      margin: 8px 0 0 16px;
    }
  }

  &__balance__header__title {
    font-weight: $fw-semi-bold;
  }

  &__datepicker-wrapper {
    margin-left: auto;
    width: 340px;
  }

  &__date-hint {
    margin-left: 10px;
  }

  &__balance__pending-banner {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
  // /!/ Careful : non scoped css!
  // Years are very important in Paid Leaves Counter
  // Because clients often get it wrong (especially at the end of year/beginning of new year)
  // And it's not possible to change once initialized
  // So we make it bolder to avoid user's errors
  .paid-leaves-counter-modal__datepicker-popup {
    .mx-btn-text {
      color: black;
    }

    .mx-calendar-header-label {
      .mx-btn-text {
        font-size: $fs-text-l;
        font-weight: bold;
      }
    }
  }
</style>
