<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.9999 0C55.8821 0 71.9999 16.1179 71.9999 35.9999C71.9999 55.882 55.8821 72 35.9999 72C16.1178 72 0 55.8821 0 35.9999C0 16.1178 16.1179 0 35.9999 0Z"
      fill="#2b66fe"
      fill-opacity="0.3"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="72"
      height="72"
    >
      <path
        d="M35.9999 0C55.8821 0 71.9999 16.1179 71.9999 35.9999C71.9999 55.882 55.8821 72 35.9999 72C16.1178 72 0 55.8821 0 35.9999C0 16.1178 16.1179 0 35.9999 0Z"
        fill="#2b66fe"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M64.9331 63.7333H7.06641V29.6333C7.06641 27.9211 8.45417 26.5333 10.1664 26.5333H61.8331C63.5453 26.5333 64.9331 27.9211 64.9331 29.6333V63.7333Z"
        fill="#6C7678"
      />
      <path
        d="M11.2002 30.6666H60.8002V59.6H11.2002V30.6666Z"
        fill="#D2D9E0"
      />
      <path
        d="M48.4006 10V55.4667H15.334V16.2H21.534V10H48.4006Z"
        fill="#EBF0F7"
      />
      <path
        d="M21.534 16.2H15.334V47.1855C30.2181 46.8776 42.2006 33.3595 42.2006 16.7167C42.2006 14.4082 41.962 12.1617 41.5259 10H21.534V16.2Z"
        fill="white"
      />
      <path
        d="M19.4658 13.1H44.2658V20.3333H19.4658V13.1Z"
        fill="#1253C0"
      />
      <path
        d="M21.5325 16.2H19.4658V20.3333H42.0008C42.1268 19.146 42.1992 17.9411 42.1992 16.7166C42.1992 15.4921 42.1279 14.2862 41.9997 13.1H21.5325V16.2Z"
        fill="#2b66fe"
      />
      <path
        d="M21.534 10L15.334 16.2H21.534V10Z"
        fill="#8892A0"
      />
      <path
        d="M5 63.733H67V67.8664C67 70.149 65.1493 71.9997 62.8667 71.9997H9.13333C6.8507 71.9997 5 70.149 5 67.8664V63.733Z"
        fill="#525B5C"
      />
      <path
        d="M27.7344 63.733V65.7997C27.7344 66.9415 28.6592 67.8664 29.801 67.8664H42.201C43.3429 67.8664 44.2677 66.9415 44.2677 65.7997V63.733H27.7344Z"
        fill="#EBF0F6"
      />
      <path
        d="M8.09961 66.8331H10.1663V68.8998H8.09961V66.8331Z"
        fill="white"
      />
      <path
        d="M12.2344 66.8331H14.301V68.8998H12.2344V66.8331Z"
        fill="white"
      />
      <path
        d="M18.4336 27.5668H43.3628V29.6334H18.4336V27.5668Z"
        fill="#727272"
      />
      <path
        d="M18.4336 31.7001H37.5503V33.7667H18.4336V31.7001Z"
        fill="#727272"
      />
      <path
        d="M18.4336 35.8333H31.6086V37.8999H18.4336V35.8333Z"
        fill="#727272"
      />
      <path
        d="M18.4336 39.9667H31.6086V42.0333H18.4336V39.9667Z"
        fill="#727272"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MissingPayPartnerIcon',
};
</script>
