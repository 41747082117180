<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="$t('reports.annualization.title')"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#f0fafd"
      border-color="#2191b2"
      data-test="column__annualization__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__annualization__opened"
    >
      <div
        class="column__header annualization-title"
        data-test="column__planning-hours__close-button"
        @click="closeColumn"
      >
        {{ $t('reports.annualization.title') }}
        <SkTag class="annualization-period-tag">
          {{ periodBounds }}
        </SkTag>
        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
      </div>
      <span>
        <table>
          <thead class="column__title-wrap">
            <th>
              <div class="column__title column__title--open">
                {{ $t('reports.annualization.theoretical_balance') }}
              </div>
            </th>
            <th>
              <div class="column__title column__title--open">
                {{ $t('reports.annualization.realized_balance') }}
              </div>
            </th>
            <th>
              <div class="column__title column__title--open">
                {{ $t('reports.annualization.remaining_balance') }}
              </div>
            </th>
            <th>
              <div class="column__title column__title--open">
                {{ $t('reports.annualization.advance_delay') }}
              </div>
            </th>
            <th>
              <div class="column__title column__title--open">
                {{ $t('reports.annualization.manual_changes') }}
              </div>
            </th>
          </thead>
          <tbody>
            <template v-for="(employeeInfos, employeeId) in employeesInfos">
              <tr
                v-for="contractInfos in employeeInfos.contracts_infos"
                :key="contractInfos.contract.id"
                class="column__cell-wrap"
              >
                <template v-if="employeesInfos[employeeId].annualization_data">
                  <td
                    v-tooltip.top="impactedTheoreticalTooltip(employeeId)"
                    class="column__cell column__cell--open"
                  >
                    {{
                      employeesInfos[employeeId].annualization_data.impactedTheoreticalBalance
                    }}
                  </td>
                  <td class="column__cell column__cell--open">
                    {{ employeesInfos[employeeId].annualization_data.realized }}
                  </td>
                  <td class="column__cell column__cell--open">
                    {{ employeesInfos[employeeId].annualization_data.remaining }}
                  </td>
                  <td class="column__cell column__cell--open">
                    {{ employeesInfos[employeeId].annualization_data.advanceDelay }}
                  </td>
                  <td
                    class="column__cell column__cell--open"
                  >
                    {{ employeesInfos[employeeId].annualization_data.manualChanges }}
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="i in 5"
                    :key="i"
                    v-tooltip.top="$t('reports.annualization.not_annualized_tooltip')"
                    class="column__cell column__cell--open"
                  >
                    -
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </span>
    </div>
  </div>
</template>

<script>

import {
  mapGetters,
  mapState,
} from 'vuex';

import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'AnnualizationColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      columnActive: false,
    };
  },
  computed: {
    ...mapState('annualization', ['currentPeriodEndDate', 'currentPeriodStartDate']),
    periodBounds() {
      return this.$t('reports.annualization.date_range', {
        startDate: this.currentPeriodStartDate.format('DD/MM/YYYY'),
        endDate: this.currentPeriodEndDate.format('DD/MM/YYYY'),
      });
    },
  },
  watch: {
    columnActive(val) {
      if (val) {
        this.$skAnalytics.track('annualization_open_report');
      }
    },
  },
  methods: {
    impactedTheoreticalTooltip(employeeId) {
      const data = this.employeesInfos[employeeId].annualization_data;

      if (data.theoreticalImpactingAbsences?.sick_leave !== undefined) {
        return this.$t('reports.annualization.theoretical_balance_tooltip', {
          sickLeave: Math.round(data.theoreticalImpactingAbsences.sick_leave * 100) / 100,
        });
      }

      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.annualization-title {
  border-top: 4px solid #2191b2;
}

.annualization-period-tag {
  margin-left: 16px;
}

.column__title {
  &--open {
    padding: 10px 5px !important;
  }
}

.column__title-wrap {
  height: 63.5px;
}
</style>
