<template>
  <SkModal
    id="employee-availabilities-modal"
    ref="modal"
    :modal-title="$t('employees.employee_availabilities_modal.title')"
    size="large"
    @show="handleShowModal"
    @cancel="handleCancel"
  >
    <template #body>
      <SkModalSection
        class="employees-availabilities__navigation"
        border-bottom="none"
      >
        <SkNav>
          <SkNavItem
            :active="isActive('Exceptional')"
            @click="setAsSelectedTab('Exceptional')"
          >
            {{ $t('employees.employee_availabilities_modal.navigation_items.exceptional') }}
          </SkNavItem>
          <SkNavItem
            :active="isActive('Recurrent')"
            @click="setAsSelectedTab('Recurrent')"
          >
            {{ $t('employees.employee_availabilities_modal.navigation_items.recurrent') }}
          </SkNavItem>
        </SkNav>
      </SkModalSection>
      <div
        v-if="loading"
        class="employees-availabilities__empty-content"
      >
        <SkLoader />
      </div>
      <div v-else>
        <div
          v-if="isActive('Recurrent')"
          class="employees-availabilities__content"
        >
          <SkModalSection
            v-for="day in days"
            :key="day"
          >
            <div class="employees-availabilities__content-title">
              {{ $t(`dates.${day}`) }}
            </div>
            <div class="employees-availabilities__section-container">
              <AvailabilityInputRow
                v-for="availability in filteredReccurentAvailabilities(day)"
                :key="availability.id"
                :availability="availability"
              />
              <SkOroraButton
                v-if="canManageEmployeesAvailabilities"
                variant="tertiary"
                @click="onAddHandler(day)"
              >
                + {{ $t('employees.employee_availabilities_modal.add_availability') }}
              </SkOroraButton>
            </div>
          </SkModalSection>
        </div>
        <div
          v-else
          class="employees-exceptional-availabilities__content"
        >
          <SkModalSection border-bottom="none">
            <div class="employees-availabilities__section-container">
              <AvailabilityInputRow
                v-for="availability in filteredExceptionalAvailabilities"
                :key="availability.id"
                :availability="availability"
              />
              <SkOroraButton
                v-if="canManageEmployeesAvailabilities"
                variant="tertiary"
                @click="onAddHandler()"
              >
                + {{ $t('employees.employee_availabilities_modal.add_availability') }}
              </SkOroraButton>
            </div>
          </SkModalSection>
        </div>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="updatePending"
        :data-test="dataTest"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { DAYS } from '@app-js/shared/constants/dates';
import RoutesMixin from '@app-js/requests/mixins/RoutesMixins';
import { AVAILABILITY_STATUS_REFUSED } from '@app-js/shared/constants/availability';

import AvailabilityInputRow from './AvailabilityInputRow';

export default {
  name: 'EmployeeAvailabilitiesModal',
  components: { AvailabilityInputRow },
  mixins: [RoutesMixin],
  data() {
    return {
      days: DAYS,
      updatePending: false,
      selectedNavItem: 'Exceptional',
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', { selectedEmployee: state => state.employee }),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('employeeAvailabilities', ['availabilities', 'originalData', 'loading']),
    ...mapGetters('employeeAvailabilities', ['activeRecurrentAvailabilities', 'exceptionalAvailabilities']),
    canManageEmployeesAvailabilities() {
      return this.isAvailabilityRequestsView ||
      this.currentLicense.attributes.canManageEmployeeRequests;
    },
    wasEdited() {
      return JSON.stringify(this.originalData) !== JSON.stringify(this.availabilities);
    },
    filteredExceptionalAvailabilities() {
      // Show availabilities that are being created
      // As well as the availabilities related to the getEmployeeManaged() method
      // * Do not show the refused availabilities
      return this.exceptionalAvailabilities.filter(
        availability => (availability.id === null ||
          availability.attributes.userId.toString() === this.getEmployeeManaged().id) &&
          availability.attributes.requestStatus !== AVAILABILITY_STATUS_REFUSED,
      );
    },
    dataTest() {
      const page = this.isAvailabilityRequestsView ? 'availability' : 'employee';
      return `${page}-page__availability-request-modal__submit-button`;
    },
  },
  mounted() {
    this.resetData();
  },
  methods: {
    ...mapActions('requests', ['fetchAvailabilityRequestsOwn']),
    ...mapActions('employeeAvailabilities', ['updateAvailabilities']),
    ...mapMutations('employeeAvailabilities', ['addAvailability', 'resetAvailabilities']),
    // Show the reccurent availabilities of a particular day
    filteredReccurentAvailabilities(day) {
      // Show availabilities that are being created
      // As well as the availabilities related to the getEmployeeManaged() method
      // * Do not show the refused availabilities
      return this.activeRecurrentAvailabilities.filter(
        availability => (availability.id === null ||
          availability.attributes.userId.toString() === this.getEmployeeManaged().id) &&
          availability.attributes.dayOfWeek === day.toLowerCase() &&
          availability.attributes.requestStatus !== AVAILABILITY_STATUS_REFUSED,
      );
    },
    getShopId() {
      // If no current shop, fallback on employee principal shop
      return this.navContext.shopId || this.getEmployeeManaged().attributes.shopId;
    },
    getEmployeeManaged() {
      return this.isAvailabilityRequestsView ? this.currentUser : this.selectedEmployee;
    },
    isActive(item) {
      return this.selectedNavItem === item;
    },
    setAsSelectedTab(item) {
      this.selectedNavItem = item;
      this.$refs.modal.resetScroll();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    onAddHandler(day) {
      this.addAvailability({
        day,
        shopId: this.getShopId(),
        employeeId: this.getEmployeeManaged().id,
      });
    },
    handleSubmit(event) {
      if (this.wasEdited) {
        event.preventDefault();
        this.updatePending = true;

        this
          .updateAvailabilities({ userId: this.getEmployeeManaged().id, shopId: this.getShopId() })
          .then(() => {
            this.$skToast({
              message: this.$t('employees.employee_availabilities_modal.toast.success'),
              variant: 'success',
              containerId: 'employees__container',
            });
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('employees.employee_availabilities_modal.toast.failure'),
              variant: 'error',
              containerId: 'employees__container',
            });
          })
          .finally(() => {
            this.updatePending = false;
            this.$emit('submit');
          });
      }
      this.$refs.modal.hide();
    },
    handleShowModal() {
      this.fetchAvailabilityRequestsOwn({
        userId: this.getEmployeeManaged().id,
      });
    },
    handleCancel(event) {
      if (this.wasEdited) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: () => {
            this.$refs.modal.hide();
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
#employee-availabilities-modal {
  .sk-modal__content {
    margin-top: 20px;
  }

  .sk-modal__header {
    height: 45px;
    border: none;
  }

  .employees-availabilities__navigation {
    margin: 0;
    padding: 0;
  }

  .sk-nav {
    position: fixed;
    background: white;
    width: 770px;
    z-index: 1;
    padding-left: 15px;
  }

  .employees-availabilities__content {
    padding-top: 70px;

    .employees-availabilities__content-title {
      font-weight: $fw-semi-bold;
      flex: 1;
    }

    .sk-modal__section {
      display: flex;
      padding: 20px 0;

      .employees-availabilities__section-container {
        flex: 5;
      }
    }
  }

  .employees-exceptional-availabilities__content {
    padding-top: 70px;

    .sk-modal__section {
      padding: 20px 0;

      .employees-availabilities__section-container {
        padding-bottom: 15px;
      }
    }
  }
}

.employees-availabilities__empty-content {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}

.employees-availabilities__add-button {
  width: 240px;
}
</style>
