<template>
  <OuterColumn
    id="paid-leaves-counter-year-column"
    :custom-content-classes="columnContentClasses"
    :custom-title-classes="columnTitleClasses"
    :title="year.year"
    :is-closed="year.closed"
    :is-previsional="year.previsional"
    v-on="$listeners"
  >
    <template v-if="year.closed">
      <TableCell
        v-for="index in columnLineCount"
        :key="`cell${index}`"
        :show-dash="year.show_dash"
        :tooltip="cellTitle"
        :height="cellHeight"
        width="100px"
      />
    </template>
    <slot v-else />
  </OuterColumn>
</template>

<script>
import {
  OuterColumn,
  TableCell,
} from '../../shared/components';

export default {
  name: 'PaidLeavesCounterYearColumn',
  components: { OuterColumn, TableCell },
  props: {
    year: {
      type: Object,
      required: true,
    },
    hasYearlyDistinction: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cellColor() {
      return this.hasYearlyDistinction ? '#000000' : '#4d626f';
    },
    cellHeight() {
      return this.hasYearlyDistinction ? '50px' : '60px';
    },
    cellTitle() {
      return this.year.closed ? this.$t('employees.tabs.counters.paid_leaves_counter.tooltip.show_more') : '';
    },
    columnContentClasses() {
      if (this.hasYearlyDistinction) return [];

      return [{ 'paid-leaves-counter__year-column__content--closed': this.year.closed }];
    },
    columnTitleClasses() {
      if (this.hasYearlyDistinction) return [];

      return ['paid-leaves-counter__year-column__title'];
    },
    columnLineCount() {
      return this.hasYearlyDistinction ? 7 : 5;
    },
  },
};
</script>

<style lang="scss">
// unscoped css to inject in child component
#paid-leaves-counter-year-column {
  .paid-leaves-counter__year-column__title {
    height: 50px;
    color: $sk-blue;
    background: $sk-blue-5;
    font-size: $fs-text-m;
  }

  .paid-leaves-counter__year-column__content--closed {
    padding-top: 73px;
  }
}
</style>
