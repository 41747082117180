<!-- eslint-disable max-len -->
<template>
  <PaidLeavesCounterInitializationModalContentWrapper
    id="paid-leaves-counter-initialization-modal-content-wrapper"
    ref="paidLeavesCounterInitializationModalContentWrapper"
    :modal-title="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.title')"
    :handle-submit="handleSubmit"
    :handle-show="handleShowModal"
    :handle-confirm="handleConfirmModal"
    :handle-cancel="handleCancel"
    :balance-activated="balanceActivated"
    :init-date="initDate"
    :pending-wanted="pendingWanted"
    :is-switch-disabled="isSwitchDisabled"
    :loading="loading"
    :submit-loading="submitLoading"
    @update-balance-activated="updateBalanceActivated"
    @update-init-date="updateInitDate"
  >
    <template #balance>
      <SkInfoCard class="paid-leaves-counter-modal__balance__card">
        <div>
          <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.card.explanation_1')" />
          <CircledPlusSignThinIcon class="paid-leaves-counter-modal__icon-modifier" />
          {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.card.explanation_2') }}
        </div>
        <div>
          <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.card.explanation_3')" />
          <CircledMinusSignThinIcon class="paid-leaves-counter-modal__icon-modifier" />
          {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.card.explanation_2') }}
        </div>
      </SkInfoCard>
      <UserRow
        v-for="user in users"
        :key="user.id"
        :user="user"
        :unit="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.balance.days')"
        :enable-error-proc="pendingWanted"
        :tracking-options="trackingOptions"
        @keyup="handleErroredBalance"
      />
    </template>
  </PaidLeavesCounterInitializationModalContentWrapper>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import {
  mapState,
  mapMutations,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import PaidLeavesCounterInitializationModalContentWrapper from '@app-js/shared/components/CountersInitializationModal/PaidLeavesCounterInitializationModalContentWrapper';
import UserRow from '@app-js/shared/components/CountersInitializationModal/UserRow';

export default {
  name: 'PaidLeavesCounterInitializationModal',
  components: { PaidLeavesCounterInitializationModalContentWrapper, UserRow },
  data() {
    return {
      originalData: null,
      balanceActivated: true,
      initDate: null,
      pendingWanted: false,
      users: [],
      loading: true,
      submitLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    balanceSectionClasses() {
      return {
        'paid-leaves-counter-modal__balance--disabled': !this.balanceActivated,
      };
    },
    isSwitchDisabled() {
      return this.users.length === 0;
    },
    allBalanceModifier() {
      return this.users.reduce((balanceModifier, user) => {
        balanceModifier[user.id] = user.balanceModifier;
        return balanceModifier;
      }, {},
      );
    },
    allUsersInitialPlc() {
      return this.users.reduce((initalCounters, user) => {
        initalCounters[user.id] = user.balance;
        return initalCounters;
      }, {},
      );
    },
    emptyOrNaNUserBalance() {
      return this.users.some(user => user.balance === null || user.balance === '' || isNaN(user.balance));
    },
    trackingOptions() {
      const cancelTracking = this.currentShop.attributes.plcStatus === this.config.plc_pending ?
        'paid_leaves_counter_initialization_cancel_from_resume' : 'paid_leaves_counter_initialization_cancel';

      return {
        cancel: cancelTracking,
        plus: 'init_plc_plus',
        minus: 'init_plc_minus',
      };
    },
    initModalRef() {
      return this.$refs.paidLeavesCounterInitializationModalContentWrapper
        .$refs.paidLeavesCounterInitializationModal;
    },
    isAnyChange() {
      return Object.keys(this.originalData)
        .some(key => !isEqual(this[key], this.originalData[key]));
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),
    handleShowModal() {
      this.initDate = this.currentShop.attributes.plcInitializationDoneAt;

      this.balanceActivated =
        this.currentShop.attributes.plcStatus === this.config.plc_pending;

      this.fetchUsers();
    },
    fetchUsers() {
      const modifier = { balanceModifier: '+' };
      httpClient
        .get('/v3/api/users/paid_leaves_counters_users', { params: { shop_id: this.currentShop.id } })
        .then(response => {
          this.users = [...response.data.data];
          this.users = this.users.map(user => {
            const defaultBalance =
              typeof user.attributes.initialPlc === 'number' ? user.attributes.initialPlc : null;
            return {
              ...user, ...modifier, balance: defaultBalance, errorBalance: true,
            };
          });
          if (!this.balanceActivated) this.anyUserBalancedFilled();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.fetch_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.originalData = cloneDeep(
            {
              initDate: this.initDate,
              users: this.users,
            },
          );
        });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    anyUserBalancedFilled() {
      const balanceValues = [];

      this.users.map(user => balanceValues.push(user.balance));
      this.balanceActivated = balanceValues.some(balance => !!balance && !isNaN(balance));
    },
    isModifierSelected(user, modifier) {
      return user.balanceModifier === modifier;
    },
    selectModifier(user, modifier) {
      user.balanceModifier = modifier;
    },
    handleErroredBalance(event, user) {
      user.errorBalance = typeof user.balance !== 'number';
    },
    handleConfirmModal() {
      this.initModalRef.hide();
      this.resetData();
      this.$emit('cancel', event);
    },
    handleCancel(event) {
      if (this.isAnyChange) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: this.handleConfirmModal,
        });
      }
    },
    handleSubmit() {
      // condition when all users balance are not filled && balance is activated
      if (this.balanceActivated && this.emptyOrNaNUserBalance && !this.pendingWanted) {
        this.pendingWanted = true;
        this.$nextTick(() => {
          this.initModalRef.scrollToBottom();
        });
        return;
      }

      if (!this.pendingWanted &&
        this.currentShop.attributes.plcStatus ===
          this.config.plc_pending) {
        this.$skAnalytics.track('paid_leaves_counter_initialization_validate_from_resume');
      } else if (!this.balanceActivated) {
        this.$skAnalytics.track('paid_leaves_counter_initialization_validate');
      }

      const params = {
        paid_leave_counter: {
          start_date: this.initDate,
          plc_signs: this.allBalanceModifier,
          users_initial_plc: this.allUsersInitialPlc,
          balance_not_inherited: !this.balanceActivated,
        },
      };

      this.submitLoading = true;

      if (this.balanceActivated && this.emptyOrNaNUserBalance) {
        this.$skAnalytics.track('paid_leaves_counter_initialization_save_and_finish_later_submit');
        params.paid_leave_counter.plc_pending = true;
      } else {
        params.paid_leave_counter.plc_initialized = true;
      }

      httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}/initial_paid_leaves_counters`, params)
        .then(response => {
          if (this.initDate) {
            this.setShopAttributes({ plcInitializationDoneAt: this.initDate });
          }
          if (params.paid_leave_counter.plc_pending) {
            this.setShopAttributes({
              plcStatus: this.config.plc_pending,
              plcInitialized: true,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_pending'),
              variant: 'success',
            });
          } else {
            this.setShopAttributes({
              plcStatus: this.config.plc_initialized,
              plcInitialized: true,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_complete'),
              variant: 'success',
            });
          }
          this.resetData();
          this.initModalRef.hide();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.modal.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    updateBalanceActivated(newValue) {
      this.balanceActivated = newValue;
    },
    updateInitDate(newValue) {
      this.initDate = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-leaves-counter-modal {
  &__balance__card {
    margin: 16px 0 20px;
  }
}
</style>
