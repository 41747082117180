<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.32242 0L3.57718 1.47044H1.04334C0.465331 1.47044 0 1.9295 0 2.49974V8.96966C0 9.5399 0.465331 9.99897 1.04334 9.99897H11.1787C11.7567 9.99897 12.222 9.5399 12.222 8.96966V2.49974C12.222 1.9295 11.7567 1.47044 11.1787 1.47044H8.64485L7.8996 0H4.32242ZM6.11101 3.08791C7.59274 3.08791 8.7939 4.27293 8.7939 5.73471C8.7939 7.19648 7.59274 8.38149 6.11101 8.38149C4.62932 8.38149 3.42813 7.19648 3.42813 5.73471C3.42813 4.27293 4.62932 3.08791 6.11101 3.08791Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'CameraIcon',
  props: {
    width: {
      type: String,
      default: '15',
    },
    height: {
      type: String,
      default: '15',
    },
    fill: {
      type: String,
      default: '#727272',
    },
  },
};
</script>
