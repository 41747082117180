<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00012 0.432816L9.87841 4.03067L13.9248 2.41211L12.2016 10.1667H1.79868L0.0754395 2.41211L4.12184 4.03067L7.00012 0.432816ZM7.00012 2.56719L4.54507 5.636L1.92481 4.5879L2.86824 8.83334H11.132L12.0754 4.5879L9.45518 5.636L7.00012 2.56719Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'CrownIcon',
};
</script>
