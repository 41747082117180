<template>
  <!-- eslint-disable  max-len -->
  <svg
    id="svg851"
    width="868"
    height="283"
    fill="none"
    version="1.1"
    viewBox="0 0 868 283"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="path674"
      d="m3 5e-7h862c1.657 0 3 1.3432 3 3v277c0 1.657-1.343 3-3 3h-862c-1.6569 0-3-1.343-3-3v-277c0-1.6569 1.3432-3 3-3z"
      fill="#eff4fe"
      stroke="#2b66fe"
      stroke-width="0"
    />
    <g
      id="g818"
      transform="translate(1.7906 .6034)"
      opacity=".7"
    >
      <g
        id="g377"
        transform="translate(698.21 74)"
      >
        <rect
          id="rect716"
          width="150"
          height="120"
          rx="4"
          fill="#fff"
        />
        <foreignObject
          x="10"
          y="70"
          width="130"
          height="45"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <p style="text-align:center;line-height:1.2em">{{ $t('employees.tabs.documents.upsell.other') }}</p>
        </foreignObject>
        <g
          id="g280"
          transform="translate(-702 -74.603)"
        >
          <path
            id="path728"
            d="m787.96 134h-23.151c-0.533 0-0.921-0.506-0.781-1.021l5.258-14.416c0.095-0.352 0.415-0.598 0.781-0.598h23.151c0.533 0 0.781 0.509 0.781 1.021l-5.258 14.416c-0.095 0.353-0.416 0.598-0.781 0.598z"
            fill="#b1cefe"
          />
          <path
            id="path730"
            d="m790 113.31h-1.173v4.655h2.069v-3.759c0-0.495-0.401-0.896-0.896-0.896z"
            fill="#2b66fe"
          />
          <path
            id="path732"
            d="m766.07 109.69h-1.173c-0.495 0-0.896 0.4-0.896 0.895v22.101c0.036 1e-3 0.071 3e-3 0.106 5e-3l1.963-5.352z"
            fill="#2b66fe"
          />
          <path
            id="path734"
            d="m769.29 118.56c0.095-0.352 0.416-0.598 0.781-0.598h18.76v-13.965h-19.655v14.876z"
            fill="#eff4f8"
          />
          <path
            id="path736"
            d="m774.79 112.28h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path738"
            d="m774.79 108.66h5.172c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-5.172c-0.285 0-0.517 0.231-0.517 0.517s0.232 0.517 0.517 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path740"
            d="m787.21 114.86h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517z"
            fill="#92a4b6"
          />
          <path
            id="path742"
            d="m769.17 118.88v-13.842h-1.552v18.097z"
            fill="#d0d8de"
          />
          <path
            id="path744"
            d="m767.62 123.13v-17.062h-1.552v21.316z"
            fill="#adb8c3"
          />
        </g>
        <g
          id="g1889"
          transform="translate(3e-6)"
        >
          <circle
            id="circle718"
            cx="25"
            cy="25"
            r="12"
            fill="#eef4ff"
          />
          <text
            id="text722"
            fill="#2b66fe"
            font-size="12px"
            font-weight="bold"
            letter-spacing="0px"
            xml:space="preserve"
          ><tspan
            id="tspan720"
            x="21"
            y="29"
          >8</tspan></text>
        </g>
      </g>
      <g
        id="g360"
        transform="translate(528.21 74)"
      >
        <rect
          id="rect746"
          width="150"
          height="120"
          rx="4"
          fill="#fff"
        />
        <foreignObject
          x="10"
          y="70"
          width="130"
          height="45"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <p style="text-align:center;line-height:1.2em">{{ $t('employees.tabs.documents.upsell.payslips') }}</p>
        </foreignObject>
        <g
          id="g269"
          transform="translate(-529 -74.603)"
        >
          <path
            id="path758"
            d="m613.96 136h-23.151c-0.533 0-0.921-0.506-0.781-1.021l5.258-14.416c0.095-0.352 0.415-0.598 0.781-0.598h23.151c0.533 0 0.781 0.509 0.781 1.021l-5.258 14.416c-0.095 0.353-0.416 0.598-0.781 0.598z"
            fill="#b1cefe"
          />
          <path
            id="path760"
            d="m616 115.31h-1.173v4.655h2.068v-3.759c0-0.495-0.401-0.896-0.895-0.896z"
            fill="#2b66fe"
          />
          <path
            id="path762"
            d="m592.07 111.69h-1.173c-0.495 0-0.896 0.4-0.896 0.895v22.101c0.036 1e-3 0.071 3e-3 0.106 5e-3l1.963-5.352z"
            fill="#2b66fe"
          />
          <path
            id="path764"
            d="m595.29 120.56c0.095-0.352 0.415-0.598 0.781-0.598h18.76v-13.965h-19.656v14.876z"
            fill="#eff4f8"
          />
          <path
            id="path766"
            d="m598.79 114.28h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path768"
            d="m598.79 110.66h5.172c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-5.172c-0.285 0-0.517 0.231-0.517 0.517s0.232 0.517 0.517 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path770"
            d="m611.21 116.86h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517z"
            fill="#92a4b6"
          />
          <path
            id="path772"
            d="m595.17 120.88v-13.842h-1.551v18.097z"
            fill="#d0d8de"
          />
          <path
            id="path774"
            d="m593.62 125.13v-17.062h-1.552v21.316z"
            fill="#adb8c3"
          />
        </g>
        <g
          id="g1894"
          transform="translate(3e-6)"
        >
          <circle
            id="circle748"
            cx="25"
            cy="25"
            r="12"
            fill="#eef4ff"
          />
          <text
            id="text752"
            fill="#2b66fe"
            font-size="12px"
            font-weight="bold"
            letter-spacing="0px"
            xml:space="preserve"
          ><tspan
            id="tspan750"
            x="21"
            y="29"
          >8</tspan></text>
        </g>
      </g>
      <g
        id="g343"
        transform="translate(358.21 74)"
      >
        <rect
          id="rect776"
          width="150"
          height="120"
          rx="4"
          fill="#fff"
        />
        <foreignObject
          x="10"
          y="70"
          width="130"
          height="45"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <p style="text-align:center;line-height:1.2em">{{ $t('employees.tabs.documents.upsell.supporting_documents') }}</p>
        </foreignObject>
        <g
          id="g381"
          transform="translate(-364 -76.603)"
        >
          <path
            id="path782"
            d="m447.67 136h-21.903c-0.504 0-0.871-0.479-0.739-0.966l4.975-13.639c0.09-0.333 0.393-0.565 0.739-0.565h21.903c0.504 0 0.739 0.481 0.739 0.965l-4.975 13.639c-0.09 0.334-0.393 0.566-0.739 0.566z"
            fill="#b1cefe"
          />
          <path
            id="path784"
            d="m449.6 116.43h-12.365l-2.447-3.426h-8.939c-0.468 0-0.848 0.379-0.848 0.848v20.909c0.034 1e-3 0.068 3e-3 0.1 5e-3l4.901-13.367c0.09-0.334 0.393-0.565 0.739-0.565h19.707v-3.557c0-0.468-0.38-0.847-0.848-0.847z"
            fill="#2b66fe"
          />
        </g>
      </g>
      <g
        id="g336"
        transform="translate(188.21 74)"
      >
        <rect
          id="rect786"
          width="150"
          height="120"
          rx="4"
          fill="#fff"
        />
        <foreignObject
          x="10"
          y="70"
          width="130"
          height="45"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <p style="text-align:center;line-height:1.2em">{{ $t('employees.tabs.documents.upsell.social_security_and_insurance') }}</p>
        </foreignObject>
        <g
          id="g258"
          transform="translate(-194 -74.603)"
        >
          <path
            id="path800"
            d="m278.96 134h-23.151c-0.533 0-0.921-0.506-0.781-1.021l5.258-14.416c0.095-0.352 0.415-0.598 0.781-0.598h23.151c0.533 0 0.781 0.509 0.781 1.021l-5.258 14.416c-0.095 0.353-0.416 0.598-0.781 0.598z"
            fill="#b1cefe"
          />
          <path
            id="path802"
            d="m281 113.31h-1.173v4.655h2.068v-3.759c0-0.495-0.401-0.896-0.895-0.896z"
            fill="#2b66fe"
          />
          <path
            id="path804"
            d="m257.07 109.69h-1.173c-0.495 0-0.896 0.4-0.896 0.895v22.101c0.036 1e-3 0.071 3e-3 0.106 5e-3l1.963-5.352z"
            fill="#2b66fe"
          />
          <path
            id="path806"
            d="m260.29 118.56c0.095-0.352 0.415-0.598 0.781-0.598h18.76v-13.965h-19.656v14.876z"
            fill="#eff4f8"
          />
          <path
            id="path808"
            d="m263.79 112.28h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path810"
            d="m263.79 108.66h5.172c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-5.172c-0.285 0-0.517 0.231-0.517 0.517s0.232 0.517 0.517 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path812"
            d="m276.21 114.86h-12.413c-0.286 0-0.518 0.231-0.518 0.517s0.232 0.517 0.518 0.517h12.413c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517z"
            fill="#92a4b6"
          />
          <path
            id="path814"
            d="m260.17 118.88v-13.842h-1.551v18.097z"
            fill="#d0d8de"
          />
          <path
            id="path816"
            d="m258.62 123.13v-17.062h-1.552v21.316z"
            fill="#adb8c3"
          />
        </g>
        <g
          id="g1879"
          transform="translate(3e-6)"
        >
          <circle
            id="circle61"
            cx="25"
            cy="25"
            r="12"
            fill="#eef4ff"
          />
          <text
            id="text792"
            fill="#2b66fe"
            font-size="12px"
            font-weight="bold"
            letter-spacing="0px"
            xml:space="preserve"
          ><tspan
            id="tspan790"
            x="21"
            y="29"
          >3</tspan></text>
        </g>
      </g>
      <g
        id="g318"
        transform="translate(18.209 74)"
      >
        <rect
          id="rect686"
          width="150"
          height="120"
          rx="4"
          fill="#fff"
        />
        <foreignObject
          x="10"
          y="70"
          width="130"
          height="45"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <p style="text-align:center;line-height:1.2em">{{ $t('employees.tabs.documents.upsell.contracts') }}</p>
        </foreignObject>
        <g
          id="g296"
          transform="translate(-24 -74.603)"
        >
          <path
            id="path700"
            d="m110 113.31h-1.173v4.656h2.069v-3.76c0-0.495-0.402-0.896-0.896-0.896z"
            fill="#2b66fe"
          />
          <path
            id="path706"
            d="m92.794 112.28h12.414c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517h-12.414c-0.2855 0-0.5172 0.231-0.5172 0.517s0.2317 0.517 0.5172 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path708"
            d="m92.793 108.66h5.1724c0.2855 0 0.5172-0.231 0.5172-0.517s-0.2317-0.517-0.5172-0.517h-5.1724c-0.2855 0-0.5173 0.231-0.5173 0.517s0.2318 0.517 0.5173 0.517z"
            fill="#92a4b6"
          />
          <path
            id="path710"
            d="m105.21 114.86h-12.414c-0.2855 0-0.5172 0.231-0.5172 0.517s0.2317 0.517 0.5172 0.517h12.414c0.286 0 0.518-0.231 0.518-0.517s-0.232-0.517-0.518-0.517z"
            fill="#92a4b6"
          />
          <path
            id="path712"
            d="m89.172 118.88v-13.842h-1.5517v18.097z"
            fill="#d0d8de"
          />
          <path
            id="path698"
            d="m107.96 134h-23.151c-0.5328 0-0.9202-0.506-0.7811-1.021l5.2583-14.416c0.0952-0.352 0.4153-0.597 0.781-0.597h23.151c0.533 0 0.781 0.508 0.781 1.02l-5.258 14.416c-0.095 0.353-0.416 0.598-0.781 0.598z"
            fill="#b1cefe"
          />
          <path
            id="path702"
            d="m86.069 109.69h-1.1731c-0.4945 0-0.8959 0.401-0.8959 0.896v22.1c0.0357 1e-3 0.0714 3e-3 0.106 5e-3l1.963-5.352z"
            fill="#2b66fe"
          />
          <path
            id="path704"
            d="m89.287 118.56c0.0952-0.352 0.4154-0.597 0.7811-0.597h18.76v-13.966h-19.656v14.876z"
            fill="#eff4f8"
          />
          <path
            id="path714"
            d="m87.621 123.13v-17.062h-1.5517v21.316z"
            fill="#adb8c3"
          />
        </g>
        <g id="g1884">
          <circle
            id="circle688"
            cx="25"
            cy="25"
            r="12"
            fill="#eef4ff"
          />
          <text
            id="text692"
            fill="#2b66fe"
            font-size="12px"
            font-weight="bold"
            letter-spacing="0px"
            xml:space="preserve"
          ><tspan
            id="tspan690"
            x="21"
            y="29"
          >3</tspan></text>
        </g>
      </g>
    </g>
    <text
      id="text822"
      fill="#000000"
      font-size="14px"
      letter-spacing="0em"
      xml:space="preserve"
    ><tspan
      id="tspan820"
      x="65"
      y="39"
    >
      {{ $t('employees.tabs.documents.upsell.title') }}
    </tspan></text>
    <foreignObject
      y="220"
      width="100%"
      height="300"
      requiredExtensions="http://www.w3.org/1999/xhtml"
    >
      <p style="font-size: $fs-heading-xs; text-align: center">{{ $t('employees.tabs.documents.upsell.subtitle') }}</p>
    </foreignObject>
  </svg>
</template>

<script>
export default {
  name: 'FakeFolders',
};
</script>
