<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    id="duplicate-contract-modal"
    ref="duplicateContractModal"
    :modal-title="$t('employees.tabs.contracts.actions.duplicate.modal.title')"
    :modal-subtitle="modalSubtitle"
    size="large"
    @show="fetchData"
    @cancel="cancel"
    @close="resetData"
  >
    <template #body>
      <div class="duplicate-contract-modal">
        <div class="sk-form-body-section">
          {{ $t('employees.tabs.contracts.actions.duplicate.modal.description_one') }}
          <br>
          {{ $t('employees.tabs.contracts.actions.duplicate.modal.description_one_bis') }}
        </div>
        <div class="sk-form-body-section">
          {{ $t('employees.tabs.contracts.actions.duplicate.modal.description_two') }}
        </div>
        <SkForm
          :title="$t('employees.tabs.contracts.actions.duplicate.modal.period_label')"
          split
        >
          <div class="duplicate-contract-modal__row">
            <SkDatePicker
              ref="startDatepicker"
              v-model="startDate"
              :placeholder="$t('employees.tabs.contracts.actions.duplicate.modal.placeholders.start_date')"
              :editable="false"
              :clearable="false"
              :disabled-date="isDateUnavailableAsNewContractStartDate()"
              :loading="loading"
              :lang="$i18n.locale"
              hide-footer
              class="mr-sm-2"
            />
            <SkDatePicker
              ref="endDatepicker"
              v-model="endDate"
              v-tooltip.bottom="endDateTooltipText"
              :disabled="!isStartDateDefined"
              :placeholder="$t('employees.tabs.contracts.actions.duplicate.modal.placeholders.end_date')"
              :editable="false"
              :disabled-date="isDateUnavailableAsContractEndDate()"
              :loading="loading"
              :lang="$i18n.locale"
              clearable
              hide-footer
            />
          </div>

          <div
            v-if="isEndDateErrored"
            class="duplicate-contract-modal__row sk-datepicker__error-message duplicate-contract-modal__end-error"
          >
            <template v-if="isContractPermanent(selectedContract)">
              {{ $t('employees.tabs.contracts.full_contract.data.contract.end_date.overlap_constraint_1') }} <br>
              {{ $t('employees.tabs.contracts.full_contract.data.contract.end_date.overlap_constraint_2') }}
            </template>
            <template v-else>
              {{ $t('employees.tabs.contracts.full_contract.data.contract.end_date.constraint_one') }}
            </template>
          </div>
        </SkForm>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="'multicontract_validate_period_amendment_current_contract'"
        :loading="submitting"
        :disabled="disabledSubmit"
        @click="handleSubmit"
      >
        {{ $t('employees.tabs.contracts.actions.duplicate.modal.submit') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>  <!-- eslint-enable  max-len -->

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

export default {
  name: 'DuplicateContractModal',
  data() {
    return {
      loading: true,
      submitting: false,
      startDateModified: false,
      endDateModified: false,
    };
  },
  computed: {
    ...mapGetters('contracts', ['contractType', 'isContractPermanent', 'isContractEditView', 'isContractReadOnlyView']),
    ...mapState('contracts', ['contracts', 'selectedContract']),
    ...mapState('selectedEmployee', ['employee']),
    startDate: {
      get() {
        // When selectedContract is not set (closed modale)
        if (!this.selectedContract || !this.selectedContract.attributes.startDate) return null;
        // Initial state - endDate not selected in datePicker
        if (!this.startDateModified) return null;

        return this.selectedContract.attributes.startDate.substring(0, 10);
      },
      set(newStartDate) {
        this.startDateModified = true;
        this.setSelectedContractAttributes({ startDate: newStartDate });
        this.setSelectedContractAttributes({ endDate: null });
      },
    },
    isStartDateDefined() {
      return this.selectedContract &&
             this.selectedContract.attributes &&
             this.selectedContract.attributes.startDate;
    },
    endDate: {
      get() {
        // When selectedContract is not set (closed modale)
        if (!this.selectedContract || !this.selectedContract.attributes.endDate) return null;
        // Initial state - endDate not selected in datePicker
        if (!this.endDateModified) return null;

        return this.selectedContract.attributes.endDate.substring(0, 10);
      },
      set(newEndDate) {
        this.endDateModified = true;
        this.setSelectedContractAttributes({ endDate: newEndDate });
      },
    },
    isEndDateDefined() {
      return this.selectedContract &&
             this.selectedContract.attributes &&
             this.selectedContract.attributes.endDate;
    },
    modalSubtitle() {
      if (!this.selectedContract) { return '-'; }

      const translations = this.$t('contract_types.keys');
      const contractType = this.contractType(this.selectedContract.attributes.contractTypeId);
      const contractTypeName = translations[contractType.country.toLowerCase()][contractType.key];
      const contractHours = this.selectedContract.attributes.contractHours;
      let subtitle = `${ contractTypeName }`;
      if (contractHours) { subtitle += ` \u2022 ${contractHours}h`; }

      return subtitle;
    },
    isEndDateErrored() {
      if (!this.isStartDateDefined) return false;

      return !this.isEndDateDefined && this.isEndDateMandatory;
    },
    // - End date is always mandatory for non permanent contracts
    // - End date can be empty for permanent contracts
    //   if this is the last permanent contract of the user
    isEndDateMandatory() {
      return !this.isContractPermanent(this.selectedContract) ||
             this.allContractsAfterContractStartDate().length > 0;
    },
    disabledSubmit() {
      return !this.isStartDateDefined || this.isEndDateErrored;
    },
    endDateTooltipText() {
      return !this.isStartDateDefined ?
        this.$t('employees.tabs.contracts.actions.duplicate.modal.constraints.start_date') :
        '';
    },
  },
  methods: {
    ...mapActions('contracts', ['duplicateContract', 'fetchAllContracts', 'fetchContracts']),
    ...mapMutations('contracts', ['unsetSelectedContract', 'setSelectedContractAttributes']),
    ...mapGetters('contracts', [
      'allContractsAfterContractStartDate',
      'isDateUnavailableAsNewContractStartDate',
      'isDateUnavailableAsContractEndDate',
    ]),
    ...mapMutations('amendments', ['resetAmendments']),

    handleSubmit() {
      this.submitting = true;

      const params = {
        employeeId: this.$route.params.user_id,
        contractId: this.selectedContract.id,
      };

      this.duplicateContract({ startDate: this.startDate, endDate: this.endDate, ...params })
        .then(response => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.duplicate.modal.success'),
            variant: 'success',
            containerId: 'employees__container',
          });

          // Re-fetch current / contracts table
          // Not on full_contract page because it will be done anyway
          // when using the "Back" button
          if (!this.isContractEditView && !this.isContractReadOnlyView) {
            if (response.data.data.attributes.current) {
              this.resetAmendments();
            } else {
              this.fetchContracts({
                employeeId: this.employee.id,
                filter: this.$router.currentRoute.name,
              });
            }
          }

          this.resetData();
          this.$refs.duplicateContractModal.hide();
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.actions.duplicate.modal.failure'),
            variant: 'error',
            containerId: 'employees__container',
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    fetchData() {
      this.fetchAllContracts({
        employeeId: this.$route.params.user_id,
      })
        .then(response => {
          this.loading = false;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.full_contract.errors.loading'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    resetData() {
      this.unsetSelectedContract();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    cancel() {
      this.$skAnalytics.track('multicontract_canecel_period_amendment_current_contract');
      this.resetData();
    },
  },
};
</script>

<style lang="scss">
.duplicate-contract-modal {
  padding: 0 20px;

  .row {
    margin-right: -10px;
  }

  .sk-form--split {
    border: none;

    .sk-form__header label {
      color: $sk-black;
    }
  }

  &__row {
    display: flex;
  }

  &__cell {
    width: calc(50% - 5px);
  }

  .duplicate-contract-modal__end-error {
    justify-content: flex-end;
    text-align: right;
    margin-top: 10px;
  }
}
</style>
