<template>
  <div
    :class="columnClasses"
    @click="$emit('click', $event)"
  >
    <div :class="columnTitleClasses">
      {{ title }}
    </div>
    <div :class="columnContentClasses">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'OuterColumn',
  props: {
    title: {
      type: String,
      required: true,
    },
    isClosed: {
      type: Boolean,
      default: true,
    },
    isPrevisional: {
      type: Boolean,
      default: false,
    },
    customContentClasses: {
      type: Array,
      default: () => [],
    },
    customTitleClasses: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columnClasses() {
      return {
        'outer-column': true,
        'outer-column--open': !this.isClosed,
        'outer-column--previsional': this.isPrevisional,
      };
    },
    columnTitleClasses() {
      return [...this.customTitleClasses, 'outer-column__header'];
    },
    columnContentClasses() {
      return [
        ...this.customContentClasses, {
          'outer-column--closed': this.isClosed,
          'outer-column__content-wrapper': !this.isClosed,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-column__header {
  display: flex;
  height: 40px;
  border-bottom: solid 1px $sk-grey-10;
  color: $sk-grey;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 5px;
  font-weight: $fw-semi-bold;
  font-size: $fs-text-xs;
}

.outer-column {
  cursor: pointer;
  border-right: solid 1px $sk-grey-10;
  border-left: solid 1px $sk-grey-10;
  border-top: solid 3px $sk-blue;
  margin-right: 2px;
  border-radius: 4px 4px 0 0;

  &--open {
    cursor: initial;
  }

  &--closed {
    padding-top: 39px;
  }

  &--previsional {
    background-color: $sk-grey-5;
  }
}

.outer-column__content-wrapper {
  display: inline-flex;
}
</style>
