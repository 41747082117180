<template>
  <div
    :class="classes"
    @click="toggleEditMode(true)"
  >
    <GlobalEvents
      v-if="editable && inEditMode"
      @click="handleClickOutside"
    />
    <div
      v-if="editable"
      v-show="inEditMode"
      class="editor__header__title__input-group"
    >
      <SkInput
        ref="titleInput"
        v-model="localValue"
        :placeholder="$t('text_document_templates.editor.title_placeholder')"
        class="editor__header__title__input"
      />
      <PencilIcon class="editor__header__title__input-group-prepend" />
    </div>
    <div
      v-show="!inEditMode"
      class="editor__header__title__static"
    >
      {{ localValue || $t('text_document_templates.editor.title_placeholder') }}
      <PencilIcon
        v-if="editable"
        class="editor__header__title__icon"
      />
    </div>
  </div>
</template>

<script>
import GlobalEvents from 'vue-global-events';

export default {
  name: 'TitleInput',
  components: { GlobalEvents },
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      changeFromAbove: false,
      localValue: this.value,
      inEditMode: false,
    };
  },
  computed: {
    classes() {
      return {
        editor__header__title: true,
        'editor__header__title--editable': this.editable,
      };
    },
    title: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.editable) {
          this.$emit('input', value);
        }
      },
    },
  },
  watch: {
    value(newValue) {
      if (newValue === this.localValue) return;
      this.changeFromAbove = true;
      this.localValue = newValue;
    },
    localValue(newValue) {
      if (this.changeFromAbove) {
        this.changeFromAbove = false;
        return;
      }
      this.$emit('input', newValue);
    },
    inEditMode(newInEditMode) {
      if (newInEditMode) {
        // Wait for next render because input is not yet displayed
        this.$nextTick(() => {
          this.$refs.titleInput.focus();
        });
      }
    },
  },
  methods: {
    toggleEditMode(value) {
      if (this.editable) {
        this.inEditMode = value;
      }
    },
    handleClickOutside(event) {
      // Hide input if user clicks outside element
      if (this.inEditMode && !this.$el.contains(event.target)) {
        this.toggleEditMode(false);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.editor__header__title__input-group {
  display: flex;
  align-items: center;
  border: 1px solid $sk-blue;
  box-sizing: border-box;
  border-radius: 2px;

  .editor__header__title__input-group-prepend {
    margin: 0 10px;
  }

  .editor__header__title__input {
    font-size: $fs-text-l;
    height: 28px;
    border: none;

    &.sk-input::before {
      background: none;
    }

    .sk-input__input {
      font-size: $fs-text-l;
      background: none;
      padding-left: 8px;
      height: 28px;
      border-bottom: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.editor__header__title {
  cursor: default;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor__header__title--editable {
  cursor: pointer;

  .editor__header__title__static {
    display: flex;
    align-items: center;
    font-size: $fs-text-l;
    height: 30px;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;

    &:hover {
      min-width: 250px;
      background: $sk-grey-10;
      border-radius: 2px;

      .editor__header__title__icon {
        visibility: visible;
      }
    }
  }

  .editor__header__title__icon {
    visibility: hidden;
  }
}
</style>
