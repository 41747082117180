<template>
  <popper
    ref="popover"
    :options="{
      placement: placement,
      modifiers: {
        preventOverflow: { enabled: true },
        offset: { offset: '0 10px'},
      },
    }"
    :delay-on-mouse-over="100"
    :delay-on-mouse-out="100"
  >
    <!-- Tooltip content (display on hover) -->
    <KpiTooltip
      :pack-type="packType"
      :pack-limitation-text="packLimitationText"
      tooltip-type="upsell"
    />
    <!-- Popper anchor for tooltip (must be below tooltip to work) -->
    <div slot="reference">
      <slot />
    </div>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import KpiTooltip from '../KpiTooltip';

export default {
  name: 'PackOfferTooltip',
  components: {
    popper: Popper,
    KpiTooltip,
  },
  props: {
    packType: {
      type: String,
      default: null,
    },
    packLimitationText: {
      type: String,
      default: null,
    },
    placement: {
      type: String,
      default: 'auto',
    },
  },
};
</script>
