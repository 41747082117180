<template>
  <div class="wysiwyg-editor">
    <vue-editor
      ref="editor"
      v-model="content"
      :editor-options="editorOptions"
      :editor-toolbar="editorToolbar"
      @keypress.native="removePlaceholder"
      @keydown.enter.native="removePlaceholder"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ImageDrop } from 'quill-image-drop-module';
import {
  VueEditor,
  Quill,
} from 'vue2-editor';

import QuillImageAsBase from './QuillImageAsBase';
import TemplateVariableDrop from './TemplateVariableDrop';
import TemplateVariableBlot from './TemplateVariableBlot';
import SpellCheckerHandler from './SpellCheckerHandler';

// Force inline-style
// See https://github.com/quilljs/quill/issues/1274#issuecomment-298317113
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);

Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageAsBase', QuillImageAsBase);
Quill.register('modules/templateVariableDrop', TemplateVariableDrop);

Quill.register({ 'formats/template-variable': TemplateVariableBlot });

// Custom Fix
SpellCheckerHandler.blotName = 'spellChekerHandler';
SpellCheckerHandler.tagName = 'FONT';

Quill.register(SpellCheckerHandler, true);
// cf. app/javascript/src/v3/app/text_document_templates/editor/SpellCheckerHandler.js

const Delta = Quill.import('delta');

class MissingVariableValue extends Error {}

function escapeRegExp(string) {
// $& means the whole matched string
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function editorVariableValue(value) {
  // We need to put some style otherwise Quill will strip the span despite the class
  return `<span class="template-variable-value" style="color: #000000;">${value}</span>`;
}
function replaceApiTemplateVariableValues(values) {
  return (match, field) => {
    if (!values[field]) {
      throw new MissingVariableValue(`Missing value for ${field}`);
    }
    return editorVariableValue(values[field]);
  };
}

function replaceApiTemplateVariableBlot(labels) {
  return (match, field) => TemplateVariableBlot.create({ label: labels[field], field }).outerHTML;
}

function replaceApiTemplateVariables(body, replaceFunc) {
  return body.replace(/\*\|(\w+?)\|\*/g, replaceFunc);
}

function replaceEditorTemplateVariablesValues(body) {
  return body.replace(
    escapeRegExp(editorVariableValue('###VALUE###')).replace('###FIELD###', '(.+?)'),
    '$1',
  );
}

function replaceEditorTemplateVariable(match, field) {
  return `*|${field}|*`;
}
function replaceEditorTemplateVariables(body) {
  // Create regex to transform variables
  const pattern = escapeRegExp(
    TemplateVariableBlot.create({
      label: '',
      field: '###FIELD###',
    }).outerHTML,
  ) // Only catch field and ignore variable stuff
    .replace(
      new RegExp(`###FIELD###.+?<\\/${TemplateVariableBlot.tagName}>`, 'gi'),
      `(\\w+?)".+?<\\/${TemplateVariableBlot.tagName}>`,
    );
  const regex = new RegExp(pattern, 'gi');

  return body.replace(regex, replaceEditorTemplateVariable);
}

export default {
  name: 'WysiwygEditor',
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    withVariables: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      placeholder: this.$t('text_document_templates.editor.body_placeholder'),
      placeholderLightClass: 'ql-editor--placeholder-light',
      localValue: this.value,
      editorOptions: {
        modules: {
          clipboard: {
            matchVisual: true,
          },
          imageDrop: true,
          imageAsBase: true,
          templateVariableDrop: true,
        },
      },
      // From https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/default-toolbar.js
      editorToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image'],
      ],
    };
  },
  computed: {
    ...mapState('config', {
      templateVariablesKeys: state => state.config.text_document_template_variables_keys,
    }),
    ...mapState('textDocumentTemplates', {
      variablesValues: state => state.selectedVariablesValues,
    }),
    replaceFunc() {
      if (this.withVariables) {
        return replaceApiTemplateVariableBlot(this.templateVariablesKeys.variables);
      }
      return replaceApiTemplateVariableValues(this.variablesValues);
    },
    quill() {
      return this.$refs.editor.quill;
    },
    content: {
      get() {
        return this.formatContent(this.value);
      },
      set(newContent) {
        if (newContent === null || newContent.length === 0 ||
          this.isEditorEmpty() || this.isPlaceholderContent(newContent)) {
          this.localValue = null;
        } else {
          this.localValue = this.formatValue(newContent);
        }
      },
    },
  },
  watch: {
    value(value) {
      if (value === this.localValue) return;
      this.localValue = value;

      this.quill.clipboard.dangerouslyPasteHTML(this.formatContent(value), Quill.sources.USER);
    },
    localValue(newLocalValue, oldLocalValue) {
      if (newLocalValue === oldLocalValue) return;

      // Proxy content update from native editor
      this.$emit('input', newLocalValue);
      this.$emit('variables-replaced');
    },
  },
  mounted() {
    this.quill.root.classList.toggle('ql-editor--with_variable', this.withVariables);

    if (this.isEmpty()) {
      this.addPlaceholder('light');
    }

    this.quill.removePlaceholder = this.removePlaceholder.bind(this);

    this.quill.on(Quill.events.SELECTION_CHANGE, (range, oldRange, source) => {
      if (source !== Quill.sources.USER) return;

      if (this.isEmpty()) {
        const clickOutsideEditor = range === null;
        if (clickOutsideEditor) {
          if (!this.hasPlaceholder('dark')) {
            this.addPlaceholder('dark');
          }
          return;
        }

        if (!this.hasPlaceholder('light')) {
          this.addPlaceholder('light');
        }
        if (range !== 0) {
          this.quill.setSelection(0); // Set cursor at start
        }
      }
    });

    this.quill.clipboard.addMatcher(Node.TEXT_NODE, node => {
      if (this.hasPlaceholder()) {
        this.removePlaceholder();
      }

      const dataToInsert = this.getTemplateVariable(node.data);

      return new Delta().insert(dataToInsert);
    });

    // eslint-disable-next-line prefer-const
    let currentFirstLine = this.firstLine();
    this.quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
      if (source !== Quill.sources.USER) return;

      if (this.isEmpty()) {
        if (!this.hasPlaceholder('light')) {
          this.addPlaceholder('light');
          return;
        }
      } else {
        this.removePlaceholder();
      }

      const newFirstLine = this.firstLine();
      if (newFirstLine !== currentFirstLine) {
        currentFirstLine = newFirstLine;
        this.$emit('first-line-changed', currentFirstLine);
      }
    });
  },
  methods: {
    isPlaceholderContent(content, checkOnly = null) {
      // since new line is already inserted before the event is triggered when ENTER pressed
      if (content.replace(/^<h1><br><\/h1>/, '') !== this.placeholder) return false;

      if (checkOnly) {
        if (checkOnly === 'light') return this.quill.root.classList.contains(this.placeholderLightClass);
        return !this.quill.root.classList.contains(this.placeholderLightClass);
      }
      return true;
    },
    hasPlaceholder(checkOnly = null) {
      return this.quill && this.isPlaceholderContent(this.quill.getHTML(), checkOnly);
    },
    firstLine() {
      return this.quill.getText().split('\n')[0];
    },
    isEditorEmpty() {
      return this.quill.getText().trim().length === 0 &&
        this.quill.container.innerHTML.length === 0;
    },
    isEmpty() {
      return this.localValue === null || this.localValue.length === 0;
    },
    formatContent(value) {
      if (!value) return null;

      try {
        return replaceApiTemplateVariables(value || '', this.replaceFunc);
      } catch (error) {
        if (error instanceof MissingVariableValue) {
          this.handleMissingVariableValue(error.message);
        }
        throw error;
      }
    },
    formatValue(content) {
      if (!content) return null;

      if (this.withVariables) {
        return replaceEditorTemplateVariables(content);
      }
      return replaceEditorTemplateVariablesValues(content);
    },
    addPlaceholder(mode = 'dark') {
      if (this.quill) {
        this.quill.clipboard.dangerouslyPasteHTML(this.placeholder);
        this.quill.root.classList.toggle(this.placeholderLightClass, mode === 'light');
      }
      return this.quill;
    },
    removePlaceholder() {
      if (this.quill) {
        if (this.hasPlaceholder()) {
          this.quill.setContents([{ insert: '\n', attributes: { header: 1 } }]);
        }
        this.quill.root.classList.remove(this.placeholderLightClass);
      }
      return this.quill;
    },
    handleMissingVariableValue(message) {
      this.$skToast({
        message,
        variant: 'error',
        dimension: 'short',
      });

      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'shop_settings_documents_management' });
      }
    },
    getTemplateVariable(data) {
      const templateVariableIndex =
        Object.values(this.templateVariablesKeys.variables).indexOf(data);
      if (templateVariableIndex !== -1) {
        const field = Object.keys(this.templateVariablesKeys.variables)[templateVariableIndex];

        return { [TemplateVariableBlot.blotName]: { label: data, field } };
      }

      return data;
    },
  },
};
</script>
<style lang="scss">
.template-variable-value {
  background-color: rgba($sk-blue, .2);
}

.ql-editor {
  margin-left: 226px; /* Panel width / 2 */
  margin-right: 226px;
  caret-color: $sk-blue;
}

.ql-editor--placeholder-light {
  color: $sk-grey-10;
}

.ql-editor--with_variable {
  margin-left: 0;
  margin-right: 451px; /* Panel width */
}

.wysiwyg-editor {
  .ql-toolbar {
    background: $sk-grey-5;
    width: 100%;
    position: fixed;
    z-index: 2;

    .ql-formats {
      border: 1px solid rgba(109, 125, 140, .4);
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      padding-right: 9px;
      padding-left: 9px;
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid $sk-grey-10;
    border-top: 0;
    height: 45px;
    padding: 12px 0 10px 20px;

    .ql-picker-item.ql-selected,
    .ql-picker-item:hover,
    .ql-picker-label.ql-active,
    .ql-picker-label:hover,
    button.ql-active,
    button:focus,
    button:hover {
      color: $sk-blue;
    }

    button.ql-active,
    button:focus,
    button:hover {
      border-radius: 5px;
      height: 28px;
      background-color: rgba($sk-blue, .15);
    }

    button {
      margin: 0 3px;
      min-width: 30px;
    }

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-container {
    padding-top: 72px; /* Editor Header and Toolbar heights */
    height: calc(100vh - 90px);
    overflow: auto;
  }

  .ql-container.ql-snow {
    border-right: 0;
    border-bottom: 0;
  }

  .quillWrapper .ql-snow .ql-stroke {
    stroke: $sk-grey;
  }

  .ql-snow {
    &.ql-toolbar {
      .ql-picker-item.ql-selected .ql-stroke,
      .ql-picker-item.ql-selected .ql-stroke-miter,
      .ql-picker-item:hover .ql-stroke,
      .ql-picker-item:hover .ql-stroke-miter,
      .ql-picker-label.ql-active .ql-stroke,
      .ql-picker-label.ql-active .ql-stroke-miter,
      .ql-picker-label:hover .ql-stroke,
      .ql-picker-label:hover .ql-stroke-miter,
      button.ql-active .ql-stroke,
      button.ql-active .ql-stroke-miter,
      button:focus .ql-stroke,
      button:focus .ql-stroke-miter,
      button:hover .ql-stroke,
      button:hover .ql-stroke-miter {
        stroke: $sk-blue;
      }

      .ql-picker-item.ql-selected .ql-fill,
      .ql-picker-item.ql-selected .ql-stroke.ql-fill,
      .ql-picker-item:hover .ql-fill,
      .ql-picker-item:hover .ql-stroke.ql-fill,
      .ql-picker-label.ql-active .ql-fill,
      .ql-picker-label.ql-active .ql-stroke.ql-fill,
      .ql-picker-label:hover .ql-fill,
      .ql-picker-label:hover .ql-stroke.ql-fill,
      button.ql-active .ql-fill,
      button.ql-active .ql-stroke.ql-fill,
      button:focus .ql-fill,
      button:focus .ql-stroke.ql-fill,
      button:hover .ql-fill,
      button:hover .ql-stroke.ql-fill {
        fill: $sk-blue;
      }
    }

    .ql-toolbar {
      .ql-picker-item.ql-selected .ql-stroke,
      .ql-picker-item.ql-selected .ql-stroke-miter,
      .ql-picker-item:hover .ql-stroke,
      .ql-picker-item:hover .ql-stroke-miter,
      .ql-picker-label.ql-active .ql-stroke,
      .ql-picker-label.ql-active .ql-stroke-miter,
      .ql-picker-label:hover .ql-stroke,
      .ql-picker-label:hover .ql-stroke-miter,
      button.ql-active .ql-stroke,
      button.ql-active .ql-stroke-miter,
      button:focus .ql-stroke,
      button:focus .ql-stroke-miter,
      button:hover .ql-stroke,
      button:hover .ql-stroke-miter {
        stroke: $sk-blue;
      }

      .ql-picker-item.ql-selected .ql-fill,
      .ql-picker-item.ql-selected .ql-stroke.ql-fill,
      .ql-picker-item:hover .ql-fill,
      .ql-picker-item:hover .ql-stroke.ql-fill,
      .ql-picker-label.ql-active .ql-fill,
      .ql-picker-label.ql-active .ql-stroke.ql-fill,
      .ql-picker-label:hover .ql-fill,
      .ql-picker-label:hover .ql-stroke.ql-fill,
      button.ql-active .ql-fill,
      button.ql-active .ql-stroke.ql-fill,
      button:focus .ql-fill,
      button:focus .ql-stroke.ql-fill,
      button:hover .ql-fill,
      button:hover .ql-stroke.ql-fill {
        fill: $sk-blue;
      }
    }

    .ql-stroke {
      stroke: $sk-grey;
    }

    .ql-fill,
    .ql-snow .ql-stroke.ql-fill {
      fill: $sk-grey;
    }

    .ql-picker {
      color: $sk-grey;
    }

    .ql-picker.ql-header {
      width: 115px;

      /*
      Overwitting title labels
      https://github.com/quilljs/quill/blob/master/assets/base.styl#L239
      */

      /* TODO i18n make it dynamic */

      .ql-picker-label::before,
      .ql-picker-item::before {
        content: 'Paragraphe';
      }

      .ql-picker-label[data-value="1"]::before,
      .ql-picker-item[data-value="1"]::before {
        content: 'Titre 1';
      }

      .ql-picker-label[data-value="2"]::before,
      .ql-picker-item[data-value="2"]::before {
        content: 'Titre 2';
      }

      .ql-picker-label[data-value="3"]::before,
      .ql-picker-item[data-value="3"]::before {
        content: 'Titre 3';
      }

      .ql-picker-label[data-value="4"]::before,
      .ql-picker-item[data-value="4"]::before {
        content: 'Titre 4';
      }

      .ql-picker-label[data-value="5"]::before,
      .ql-picker-item[data-value="5"]::before {
        content: 'Titre 5';
      }
    }
  }
}
</style>
