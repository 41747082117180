<template>
  <SkModal
    id="hours-counter-initialization-modal"
    ref="hoursCounterInitializationModal"
    :tracking-options="trackingOptions"
    :modal-title="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.title')"
    size="inherited"
    @show="handleShowModal"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <div
        v-if="isLoading"
        class="shop-settings-modal-hours-counter-section__spinner"
      >
        <SkSelectSpinner />
      </div>
      <div v-else>
        <SkModalSection class="shop-settings-modal-hours-counter-section">
          <div class="shop-settings-modal-hours-counter-section__majoration">
            <div class="shop-settings-modal-hours-counter-section__majoration__title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.majoration') }}
            </div>
            <div class="shop-settings-modal-hours-counter-section__majoration__switch">
              <SkSwitch v-model="isMajorationActivated" />
            </div>
          </div>
        </SkModalSection>
        <SkModalSection class="shop-settings-modal-hours-counter-section">
          <div class="shop-settings-modal-hours-counter-section__init-date">
            <div class="shop-settings-modal-hours-counter-section__datepicker-title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.title') }}
              <div class="shop-settings-modal-hours-counter-section__datepicker-title__tooltip">
                <CircledQuestionMarkIcon
                  v-tooltip="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.tooltip')"
                  width="18"
                  height="30"
                />
              </div>
            </div>
            <div class="shop-settings-modal-hours-counter-section__datepicker-wrapper">
              <SkDatePicker
                ref="datepicker"
                v-model="initDate"
                data-test="shop-settings-modulation__datepicker"
                :disabled-date="disabledDays"
                :placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.placeholder')"
                :errored="hasInitDateError"
                :error-message="initDateErrorMessage"
                :lang="$i18n.locale"
                input-moment-format="dddd DD MMM YYYY"
                hide-footer
              />
              <p class="sk-subtitle--small">
                {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.hint') }}
              </p>
            </div>
          </div>
        </SkModalSection>
        <SkModalSection
          :padding-bottom="false"
          border-bottom="none"
          class="shop-settings-modal-hours-counter-section"
        >
          <div class="shop-settings-modal-hours-counter-section__balance__header">
            <b class="shop-settings-modal-hours-counter-section__balance__header__title">
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.title') }}
            </b>
            <SkSwitch
              v-model="isBalanceActivated"
              v-tooltip="disabledHistoricTooltip"
              :disabled="usersEmpty"
              class="shop-settings-modal-hours-counter-section__balance__switch"
            />
          </div>
          <p class="sk-subtitle--medium">
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.explanation') }}
          </p>
          <div v-if="isBalanceActivated">
            <SkCard class="shop-settings-modal-hours-counter-section__balance__card">
              <div class="shop-settings-modal-hours-counter-section__balance__card__icon">
                <CircledIIcon
                  fill=""
                  width="24"
                  height="24"
                />
              </div>
              <div>
                <div>
                  <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
                  <!-- eslint-disable vue/no-v-html max-len -->
                  <span v-html="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.card.explanation_1')" />
                  <CircledPlusSignThinIcon class="shop-settings-modal-hours-counter-section__icon-modifier" />
                  {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.card.explanation_2') }}
                </div>
                <div>
                  <span v-html="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.card.explanation_3')" />
                  <CircledMinusSignThinIcon class="shop-settings-modal-hours-counter-section__icon-modifier" />
                  {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.card.explanation_2') }}
                </div>
                <!-- eslint-enable vue/no-v-html max-len -->
              </div>
            </SkCard>
            <div class="shop-settings-modal-hours-counter-section__balance__form__hint">
              <p
                class="sk-subtitle--small
                  shop-settings-modal-hours-counter-section__balance__form__hint__text"
              >
                {{ $t('employees.tabs.counters.hours_counter.modal.balance.hint') }}
              </p>
              <CircledQuestionMarkIcon
                v-tooltip="$t('employees.tabs.counters.hours_counter.modal.balance.hint_tooltip')"
                width="14"
                height="14"
              />
            </div>
            <UserRow
              v-for="user in users"
              :key="user.id"
              :user="user"
              :unit="$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.hours')"
              :enable-error-proc="isPendingWanted"
              :tracking-options="trackingOptions"
              @keyup="handleErroredBalance"
            />
          </div>
          <div
            v-if="isPendingWanted && isBalanceActivated"
            class="shop-settings-modal-hours-counter-section__balance__pending-banner"
          >
            <SkInnerBanner
              variant="danger"
              rounded
            >
              {{ $t('shop_settings.tabs.rules.counter_rules.modulation.modal.pending_alert') }}
            </SkInnerBanner>
          </div>
        </SkModalSection>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :submit-disabled="!isLoading"
        :disabled="hasInitDateError"
        :loading="isSubmitLoading"
        @click="handleSubmit"
      >
        {{ submitText }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNaN from 'lodash/isNaN';
import {
  mapState,
  mapMutations,
} from 'vuex';

import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { isFloat } from '@skello-utils/validators';
import skDate from '@skello-utils/dates';
import UserRow from '@app-js/shared/components/CountersInitializationModal/UserRow';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

export default {
  name: 'HoursCounterInitializationModal',
  components: { UserRow },
  data() {
    return {
      isBalanceActivated: false,
      isLoading: true,
      isMajorationActivated: false,
      isPendingWanted: false,
      isSubmitLoading: false,
      initDate: null,
      originalData: null,
      users: [],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),

    isInitDateSunday() {
      if (!this.initDate) return false;

      return skDate(this.initDate).isSunday();
    },
    hasInitDateError() {
      return !this.initDate || !this.isInitDateSunday;
    },
    initDateErrorMessage() {
      if (!this.isInitDateSunday) {
        return this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.date.not_sunday');
      }

      return this.$t('errors.missing_value');
    },
    balanceSectionClasses() {
      return {
        'shop-settings-modal-hours-counter-section__balance--disabled': !this.isBalanceActivated,
      };
    },
    usersEmpty() {
      return this.users.length === 0;
    },
    submitText() {
      return this.isPendingWanted ?
        this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.submit_pending') :
        this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.submit');
    },
    allBalanceModifier() {
      return this.users.reduce((object, user) => {
        object[user.id] = user.balanceModifier;
        return object;
      }, {},
      );
    },
    allUsersInitialCounters() {
      return this.users.reduce((object, user) => {
        object[user.id] = user.balance;
        return object;
      }, {},
      );
    },
    emptyOrNaNUserBalance() {
      return this.users.some(user => user.balance === null || isNaN(user.balance) || user.balance === '');
    },
    disabledHistoricTooltip() {
      if (!this.usersEmpty || this.isLoading) return '';

      return this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.empty_users_explanation');
    },

    trackingOptions() {
      const cancelTracking =
        this.currentShop.attributes.modulationStatus === this.config.modulation_pending ?
          'hours_counter_initialization_cancel_from_resume' : 'hours_counter_initialization_cancel';
      return {
        cancel: cancelTracking,
        plus: 'init_counter_plus',
        minus: 'init_counter_minus',
      };
    },
  },
  watch: {
    isBalanceActivated(newValue) {
      if (newValue) return;
      this.$refs.hoursCounterInitializationModal.resetScroll();
    },
  },
  methods: {
    ...mapMutations('currentShop', ['setShopAttributes']),

    handleShowModal() {
      if (this.currentShop.attributes.countersInitializationDoneAt) {
        // in front we need sunday but we register following monday in back
        this.initDate =
          skDate(this.currentShop.attributes.countersInitializationDoneAt).getLastSunday().format('YYYY-MM-DD');
      }

      if (this.currentShop.attributes.modulationMajoration) this.isMajorationActivated = true;

      this.isBalanceActivated =
        this.currentShop.attributes.modulationStatus === this.config.modulation_pending;

      this.fetchUsers();
    },
    fetchUsers() {
      const modifier = { balanceModifier: '+' };

      httpClient
        .get('/v3/api/users/hours_counters_users', { params: { shop_id: this.currentShop.id } })
        .then(response => {
          response.data.data.forEach(user => this.users.push(user));
          this.users = this.users.map(user => {
            const defaultBalance =
              typeof user.attributes.initialCounter === 'number' ? user.attributes.initialCounter : null;
            return {
              ...user, ...modifier, balance: defaultBalance, errorBalance: true,
            };
          });
          if (!this.isBalanceActivated) this.anyUserBalancedFilled();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.fetch_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.originalData = cloneDeep(
            {
              isMajorationActivated: this.isMajorationActivated,
              initDate: this.initDate,
              users: this.users,
            },
          );
        });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    anyUserBalancedFilled() {
      const balanceValues = [];
      this.users.map(user => balanceValues.push(user.balance));
      this.isBalanceActivated = balanceValues.some(balance => balance !== null && !isNaN(balance) && balance !== '');
    },
    disabledDays(date) {
      return !skDate(date).isSunday();
    },
    handleErroredBalance(event, user) {
      user.errorBalance = user.balance === '' || user.balance === null || !isFloat(user.balance);
    },
    isDirty() {
      for (const key in this.originalData) {
        if (!isEqual(this[key], this.originalData[key])) return true;
      }
      return false;
    },
    handleConfirmModal() {
      this.$refs.hoursCounterInitializationModal.hide();
      this.resetData();
      this.$emit('cancel', event);
    },
    handleCancel(event) {
      if (this.isPendingWanted) {
        this.isPendingWanted = false;
        event.preventDefault();

        return;
      }

      if (this.isDirty()) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: this.handleConfirmModal,
        });
        event.preventDefault();

        return;
      }

      this.users = [];
      this.$refs.datepicker.resetModified();
      this.$emit('cancel', event);
    },
    handleSubmit() {
      // condition when all users balance are not filled && balance is activated
      if (this.isBalanceActivated && this.emptyOrNaNUserBalance && !this.isPendingWanted) {
        this.isPendingWanted = true;
        this.$nextTick(() => {
          this.$refs.hoursCounterInitializationModal.scrollToBottom();
        });
        return;
      }

      if (!this.isPendingWanted &&
        this.currentShop.attributes.modulationStatus ===
        this.config.modulation_pending) {
        this.$skAnalytics.track('hours_counter_initialization_submit_from_resume');
      } else if (!this.isBalanceActivated) {
        this.$skAnalytics.track('hours_counter_initialization_validate');
      }

      const params = {
        modulation_majoration: this.isMajorationActivated,
        start_date: this.initDate,
        counter_sign: this.allBalanceModifier,
        user_initial_counter: this.allUsersInitialCounters,
        balance_not_inherited: !this.isBalanceActivated,
      };

      this.isSubmitLoading = true;

      if (this.isBalanceActivated && this.emptyOrNaNUserBalance) {
        params.modulation_pending = true;
        this.$skAnalytics.track('hours_counter_initialization_save_and_finish_later_validate');
      } else {
        params.modulation_activated = true;
      }

      httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}/initial_counters`, params)
        .then(response => {
          const context = { premium_pay: params.modulation_majoration, date: params.start_date };

          if (!params.balance_not_inherited) {
            context.tracker_history = Object.keys(params.user_initial_counter).reduce(
              (acc, id) => ({ ...acc, [id]: `${params.counter_sign[id]}${params.user_initial_counter[id]}` }),
              {},
            );
          }

          try {
            this.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_HOUR_TRACKER_ENABLE, context);
          } catch (error) {
            captureException(error);
          }

          this.setShopAttributes({
            countersInitializationDoneAt: this.initDate,
            modulationMajoration: this.isMajorationActivated,
          });
          if (params.modulation_pending) {
            this.setShopAttributes({ modulationStatus: this.config.modulation_pending });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.init_pending'),
              variant: 'success',
            });
          } else {
            this.setShopAttributes({
              modulation: true,
              modulationStatus: this.config.modulation_activated,
            });
            this.$skToast({
              message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.init_complete'),
              variant: 'success',
            });
          }
          this.resetData();
          this.$refs.hoursCounterInitializationModal.hide();
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.init_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.isSubmitLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-modal-hours-counter-section__spinner {
  display: flex;
  height: 270px;
  width: 620px;
  justify-content: center;
}

.shop-settings-modal-hours-counter-section {
  width: 570px;

  .shop-settings-modal-hours-counter-section__init-date,
  .shop-settings-modal-hours-counter-section__majoration,
  .shop-settings-modal-hours-counter-section__balance__header {
    display: flex;
    justify-content: space-between;
  }

  .shop-settings-modal-hours-counter-section__datepicker-title {
    width: 200px;
    font-weight: $fw-semi-bold;
    line-height: 22px;
    display: flex;

    .shop-settings-modal-hours-counter-section__datepicker-title__tooltip {
      margin: 8px 0 0 15px;
    }
  }

  .shop-settings-modal-hours-counter-section__majoration__title,
  .shop-settings-modal-hours-counter-section__balance__header__title {
    font-weight: $fw-semi-bold;
    margin: auto 0;
  }

  .shop-settings-modal-hours-counter-section__datepicker-wrapper {
    width: 340px;
  }

  .shop-settings-modal-hours-counter-section__majoration__switch {
    width: 340;
    float: right;
  }

  .shop-settings-modal-hours-counter-section__balance__card {
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid $sk-grey-10;
    font-size: $fs-text-m;
    color: $sk-grey;
    display: flex;
    justify-content: flex-start;
    padding: 10px 70px 10px 10px;
  }

  .shop-settings-modal-hours-counter-section__balance__card__icon {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    fill: $sk-blue;
  }

  .shop-settings-modal-hours-counter-section__balance__form {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 10px;
  }

  .shop-settings-modal-hours-counter-section__balance__medallion {
    margin-right: 20px;
  }

  .shop-settings-modal-hours-counter-section__balance__modifiers {
    margin-right: 10px;
    margin-left: auto;
    cursor: pointer;
  }

  .shop-settings-modal-hours-counter-section__balance__modifier {
    margin-left: 3px;
  }

  .shop-settings-modal-hours-counter-section__balance__form__input {
    margin-right: 0;
    width: 200px;
  }

  .shop-settings-modal-hours-counter-section__balance__form__hint {
    margin: 20px 0 20px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .shop-settings-modal-hours-counter-section__balance__form__hint__text {
    margin-right: 4px;
  }

  .shop-settings-modal-hours-counter-section__icon-modifier {
    margin-bottom: -4px;
  }

  .shop-settings-modal-hours-counter-section__balance--disabled {
    opacity: .3;
    pointer-events: none;
  }

  .shop-settings-modal-hours-counter-section__balance__pending-banner {
    margin-top: 30px;
  }
}
</style>
