<template>
  <SkModal
    id="send-email-modal"
    ref="modal"
    :modal-title="modalTitle"
    @close="handlePrematureClose"
    @hidden="resetAll"
    @show="$emit('show')"
  >
    <template #body>
      <SkModalSection>
        <RecipientsSection
          v-model="email.recipients"
          :use-bcc="email.bcc"
          :has-bcc="hasBcc"
          :only-read-user-licenses="onlyReadUserLicenses"
          :default-selected-user-licenses="defaultSelectedUserLicenses"
          @switch-bcc="switchBcc"
          @delete-recipient="deleteRecipient"
        />
      </SkModalSection>
      <SkModalSection
        id="send-email-modal__dropzone"
        border-bottom="none"
      >
        <SkInput
          v-model="email.subject"
          :label="$t('employees.send_email.subject')"
        />
        <div class="send-email-modal__email-body-section">
          <SkTextarea
            v-model="email.body"
            :label="$t('employees.send_email.body')"
            :max-height="textAreaMaxHeight"
            :min-height="textAreaMinHeight"
          />
        </div>
        <vue-dropzone
          v-if="hasAttachmentOption"
          id="send-email-document-dropzone"
          ref="addDocumentDropzone"
          :options="dropzoneOptions"
          :include-styling="false"
          :use-custom-slot="true"
          @vdropzone-file-added="handleFileAdded"
          @vdropzone-removed-file="handleFileRemoved"
          @vdropzone-error="handleDropZoneError"
        >
          <div
            v-show="defaultDropZoneState"
            class="dropzone--default"
          >
            <AttachmentIcon />
            <div class="dropzone__title">
              {{ $t('employees.send_email.dropzone_title') }}
            </div>
            <div class="dropzone__subtitle">
              {{ $t('employees.send_email.dropzone_subtitle') }}
            </div>
          </div>
        </vue-dropzone>
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton
        variant="secondary"
        @click="handlePrematureClose"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        :loading="sendingRequest"
        :disabled="sendEmailButtonDisabled"
        @click="handleSubmit"
      >
        {{ $t('employees.send_email.submit_label') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';

import RecipientsSection from './RecipientsSection';

export default {
  name: 'SendEmailModal',
  components: {
    RecipientsSection,
    vueDropzone: vue2Dropzone,
  },
  props: {
    modalTitle: {
      type: String,
      default() {
        return this.$t('employees.send_email.title');
      },
      required: false,
    },
    hasBcc: {
      type: Boolean,
      default: false,
      required: false,
    },
    emailSubject: {
      type: String,
      default: null,
      required: false,
    },
    emailBody: {
      type: String,
      default: null,
      required: false,
    },
    hasAttachmentOption: {
      type: Boolean,
      default: false,
      required: false,
    },
    textAreaMinHeight: {
      type: Number,
      default: 120,
      required: false,
    },
    textAreaMaxHeight: {
      type: Number,
      default: 120,
      required: false,
    },
    onlyReadUserLicenses: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultSelectedUserLicenses: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      email: {
        subject: this.emailSubject,
        body: null,
        recipients: [],
        file: null,
        bcc: false,
      },
      isFileAdded: false,
      sendingRequest: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        createImageThumbnails: false,
        maxFiles: 1,
        hiddenInputContainer: '#send-email-modal__dropzone',
        maxFilesize: 4,
        url: '/fake', // We dont use dropzone for the XHR call but it requires a url
        params: {},
        headers: {
          'X-Request-With': 'XMLHttpRequest',
          Authorization: `Bearer ${authClient.authToken.token}`,
        },
        previewTemplate: this.template(),
      },
    };
  },
  computed: {
    hasValidUserInput() {
      const hasChangedSubject = this.email.subject &&
        this.email.subject.trim() !== '';
      const hasChangedBody = this.email.body &&
        this.email.body.trim() !== '';

      return hasChangedSubject || hasChangedBody;
    },
    sendEmailButtonDisabled() {
      return !this.hasValidUserInput ||
        this.email.recipients.length === 0 ||
        this.sendingRequest;
    },
    wasEdited() {
      return this.hasValidUserInput ||
        this.email.recipients.length > 0 ||
        this.isFileAdded;
    },
    defaultDropZoneState() {
      return !this.isFileAdded;
    },
  },
  watch: {
    emailBody(newBody) {
      this.email.body = newBody;
    },
  },
  mounted() {
    this.email.body = this.emailBody;
  },
  methods: {
    deleteRecipient(recipient) {
      const recipientIndex = this.email.recipients.indexOf(recipient);
      this.email.recipients.splice(recipientIndex, 1);
    },
    switchBcc(newBccValue) {
      this.email.bcc = newBccValue;
    },
    handleFileAdded(file) {
      this.isFileAdded = true;
      this.email.file = file;
    },
    handleFileRemoved(file) {
      this.isFileAdded = false;
      this.email.file = null;
    },
    handleDropZoneError(file) {
      this.resetDropZone();
      let errorMessage = this.$t('employees.send_email.toast.error');
      if (file && (file.size / (1e6) > 4)) { // If file over 4Mb (SendInBlue attachment limit)
        errorMessage = this.$t('employees.send_email.toast.error_file_size');
      }
      this.$skToast({
        message: errorMessage,
        variant: 'error',
        containerId: 'employees__container',
      });
    },
    resetDropZone() {
      if (this.hasAttachmentOption) {
        this.$refs.addDocumentDropzone?.removeAllFiles();
      }
      this.isFileAdded = false;
    },
    resetAll() {
      const resetData = this.$options.data.apply(this);
      resetData.email.bcc = this.email.bcc;
      resetData.email.body = this.emailBody;

      Object.assign(this.$data, resetData);
      this.resetDropZone();
    },
    handleSubmit() {
      this.sendingRequest = true;

      // It is very important to use FormData when uploading file trough XHR !
      // This way we receive the request with the proper headers in the backend
      // Dont try to use Base64 encoded strings or you wont receveive an <Http::UploadedFile>
      const data = new FormData();
      if (this.email.file) {
        data.append('mailing[file]', this.email.file);
      }

      data.append('mailing[subject]', this.email.subject);
      data.append('mailing[body]', this.email.body);
      data.append('mailing[bcc]', this.email.bcc);
      data.append('mailing[user_ids]',
        // Need to stringify to keep array structure
        JSON.stringify(this.email.recipients.map(recipient => recipient.id)),
      );

      httpClient
        .post('/v3/api/mailing/send_email', data)
        .then(this.handleRequestSuccess)
        .catch(this.handleRequestError);
    },
    handleRequestSuccess(response) {
      this.sendingRequest = false;
      this.$skAnalytics.track('sent_email_to_employees', {
        nb_employees: this.email.recipients.length,
      });
      this.closeModal();
      this.$skToast({
        message: this.$t('employees.send_email.toast.success'),
        variant: 'success',
        containerId: 'employees__container',
      });
    },
    handleRequestError(error) {
      this.sendingRequest = false;
      this.$skToast({
        message: this.$t('employees.send_email.toast.error'),
        variant: 'error',
        containerId: 'employees__container',
      });
    },
    handlePrematureClose(event) {
      if (this.wasEdited) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: () => {
            this.closeModal();
          },
        });
      } else {
        this.closeModal();
      }
    },
    closeModal() {
      this.resetAll();
      this.$refs.modal.hide();
    },
    template() {
      return `
        <div class="dz-complete">
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-right-section">
              <span data-dz-size></span>
              <button class="dz-delete-button" data-dz-remove>×</button>
            </div>
          </div>
          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
        </div>
      `;
    },
  },
};
</script>

<style lang="scss">
#send-email-modal {
  .sk-modal__section {
    &:first-child {
      padding: 10px 0;
      margin: 0;
    }
  }

  .sk-select {
    width: 130px !important;
  }

  .sk-modal__footer {
    padding: 10px;
  }

  .send-email-modal__email-body-section {
    padding-top: 10px;

    .sk-textarea {
      height: 220px;
    }
  }

  .send-email-modal__spinner-container {
    height: 52px;
    color: $sk-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 375px;
    bottom: 55px;
    z-index: 1;
  }
}

#send-email-document-dropzone {
  margin-top: 25px;
  cursor: pointer;

  .dz-processing {
    height: 46px;
    border-radius: 3px;
    border: 2px dashed $sk-blue;
    box-sizing: content-box;

    .dz-details.dz-filename {
      display: none;
    }
  }

  .dz-complete {
    height: 46px;
    border-radius: 3px;
    border: none;
    background: $sk-blue-5;
    animation: none;
    opacity: 1;

    .dz-details {
      display: flex;
    }
  }

  .dz-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 10px;

    .dz-filename {
      font-size: $fs-text-m;
      color: $sk-blue;
    }

    .dz-right-section {
      display: flex;
      align-items: center;
      font-size: $fs-text-m;
      color: $sk-grey;
      font-weight: $fw-regular;
    }

    .dz-delete-button {
      border: none;
      height: 28px;
      width: 28px;
      font-size: $fs-text-l;
      cursor: pointer;
      outline: none;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $sk-grey;
      background: $sk-grey-10;
      vertical-align: middle;
      margin-left: 30px;
    }
  }

  .dropzone--default {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: $sk-grey;
    border: 2px dashed $sk-grey;

    .dropzone__title {
      margin: 0 3px 0 10px;
    }
  }

  .dz-drag-hover {
    .dropzone--default {
      border: 2px dashed $sk-blue;
    }
  }
}
</style>
