<template>
  <div :class="absenceRowClasses">
    <div class="absence-row__switch">
      <DraggableIcon v-if="showDragSvg" />
      <SkSwitch
        v-model="isAbsenceActive"
        :disabled="!canEditShopRulesAndAbsences"
      />
    </div>
    <div :class="nameClasses">
      {{ absence.attributes.name }}
    </div>
    <div
      v-if="showPtoReduction"
      class="absence-row__tag"
    >
      <SkTag
        v-if="absence.attributes.effectiveWorkTime"
        variant="blue"
      >
        {{ $t('shop_settings.tabs.absences.table.yes') }}
      </SkTag>
      <div v-else>
        -
      </div>
    </div>
    <div
      v-if="isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')
        && currentShop.attributes.hybridModulation"
      class="absence-row__tag"
    >
      <SkTag
        v-if="absence.attributes.absenceFlags.includes('indemnified_absences_employer')"
        variant="blue"
      >
        {{ $t('shop_settings.tabs.absences.table.yes') }}
      </SkTag>
      <div v-else>
        -
      </div>
    </div>
    <div
      v-if="isMealCompensationDone"
      class="absence-row__compensation"
    >
      <SkCheckBox
        v-model="triggersOwedMeals"
        :disabled="!absence.attributes.active || !canEditShopRulesAndAbsences"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

export default {
  name: 'AbsenceRow',
  props: {
    absence: {
      type: Object,
      required: true,
    },
    showDragSvg: {
      type: Boolean,
      default: false,
    },
    showPtoReduction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('currentShop', ['isMealCompensationDone', 'isDevFlagEnabled']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('absences', ['dirty']),
    absenceRowClasses() {
      return {
        'absence-row__wrapper': true,
        'absence-row__wrapper--disabled': !this.absence.attributes.active,
        'absence-row__wrapper--editable': this.showDragSvg &&
          this.absence.attributes.editable &&
          this.absence.attributes.absenceType !== 'neutral',
      };
    },
    nameClasses() {
      return {
        'absence-row': true,
        'absence-row__name': true,
        'absence-row__static': !this.showDragSvg,
      };
    },
    isAbsenceActive: {
      get() {
        return this.absence.attributes.active;
      },
      set(value) {
        const trackTitle = value ? 'absence_enable' : 'absence_disable';
        this.$skAnalytics.track(trackTitle);

        const newAbsence = { ...this.absence };

        newAbsence.attributes.active = value;

        this.updateAbsence({
          shopId: this.currentShop.id,
          absence: newAbsence,
        })
          .then(() => {
            const newValue = value ? 'active' : 'inactive';

            this.$skToast({
              message: this.$t(`shop_settings.tabs.absences.update.active.${newValue}.success_message`),
              variant: 'success',
            });
          })
          .catch(() => {
            this.$skToast({
              message: this.$t('shop_settings.tabs.absences.update.active.error_message'),
              variant: 'error',
            });
          });
      },
    },
    triggersOwedMeals: {
      get() {
        return this.absence.attributes.triggersOwedMeal;
      },
      set(value) {
        const originalTriggersOwedMealValue = this.absence.attributes.triggersOwedMeal;
        this.absence.attributes.triggersOwedMeal = value;
        this.updateAbsence({
          shopId: this.currentShop.id,
          absence: this.absence,
        })
          .then(() => {
            const newValue = value ? 'active' : 'inactive';
            if (value && this.currentShop.attributes.mealCompensationActivated) {
              this.$skAnalytics.track('absence_owed_meal_enable');
            }
            this.$skToast({
              message: this.$t(`shop_settings.tabs.absences.update.triggers_owed_meal.${newValue}.success_message`),
              variant: 'success',
            });
          })
          .catch(() => {
            this.absence.attributes.triggersOwedMeal = originalTriggersOwedMealValue;
            this.$skToast({
              message: this.$t('shop_settings.tabs.absences.update.triggers_owed_meal.error_message'),
              variant: 'error',
            });
          });
      },
    },
  },
  methods: {
    ...mapActions('absences', ['updateAbsence']),
  },
};
</script>

<style lang="scss" scoped>
.absence-row__wrapper {
  display: flex;
  align-items: center;
  background: $sk-grey-5;
  height: 40px;
  margin: 8px 0;
  padding: 0 12px 0 16px;
  border-radius: 2px;
  user-select: none;

  &.absence-row__wrapper--editable {
    cursor: grab;
  }

  &.absence-row__wrapper--disabled {
    .absence-row__name {
      opacity: .6;
    }
  }

  .absence-row {
    &__name {
      width: 310px;
      padding-left: 30px;
      align-items: center;
      font-size: $fs-text-m;
    }

    &__switch {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    &__static {
      /*
       * keep labels aligned when draggable-icon is not displayed
       * draggable-icon is width 10 + margin-right 8
       */
      margin-left: 18px;
    }

    &__tag {
      width: 157px;
    }

    &__compensation {
      display: flex;
      justify-content: flex-end;
      width: 118px;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  .absence-row__pto-acquisition-tag {
    flex: 1;
    color: $sk-grey;
  }
}
</style>
