<template>
  <router-link
    :to="to"
    :class="{ 'router-link-active': active }"
    class="navbar__notifications-counter__wrapper"
  >
    <HouseIcon
      fill=""
      height="16"
      width="19"
    />
    <span
      v-if="notificationsCount > 0"
      class="navbar__notifications-counter"
    >
      {{ notificationsCount }}
    </span>
  </router-link>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

export default {
  name: 'NotificationsCounter',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('notifications', ['notificationsCount']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
};
</script>

<style lang="scss" scoped>
.navbar__notifications-counter__wrapper {
  margin-left: 24px;
  position: relative;
  line-height: 5px;

  &.router-link-active {
    svg {
      fill: $sk-blue;
    }
  }

  .navbar__notifications-counter {
    position: absolute;
    top: -7px;
    left: 12px;
    background-color: $sk-blue-10;
    color: $sk-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 18px;
    font-size: 11px;
    height: 18px;
    border: solid white 1px;
  }
}
</style>
