<template>
  <div id="profile">
    <div id="profile__sidebar">
      <div id="profile__sidebar__menu">
        <router-link
          :to="{ name: 'profile_information' }"
          data-test="profile__sidebar__link-to-profile_information"
        >
          <!-- fill="" is needed to override the component property
        with css (otherwise it defaults on black even when the tab is in an active state) -->
          <PersonV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.personal_information.title') }}</span>
        </router-link>

        <router-link
          :to="{ name: 'profile_hr_file' }"
          data-test="profile__sidebar__link-to-hr_file"
        >
          <FileV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.hr_file.title') }}</span>
        </router-link>

        <router-link
          :to="{ name: 'profile_documents' }"
          data-test="profile__sidebar__link-to-documents"
        >
          <FolderV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.documents.title') }}</span>
        </router-link>

        <router-link
          :to="{ name: 'profile_notifications' }"
          data-test="profile__sidebar__link-to-notifications"
        >
          <BellV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.notifications.title') }}</span>
        </router-link>

        <router-link
          :to="{ name: 'profile_calendar' }"
          data-test="profile__sidebar__link-to-calendar"
        >
          <CalendarV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.calendar.title') }}</span>
        </router-link>

        <router-link
          :to="{ name: 'profile_change_password' }"
          data-test="profile__sidebar__link-to-password"
        >
          <KeyV2Icon fill="" />
          <span class="sidebar__item__label">{{ $t('profile.change_password.title.sidebar') }}</span>
        </router-link>
      </div>
    </div>
    <div id="profile__content">
      <div class="content__container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
};
</script>

<style lang="scss" scoped>
#profile {
  display: flex;
  padding: 32px;
  padding-bottom: 0px;
  margin-bottom: 32px;
  flex: 1;
  overflow: auto;
  height: 100%;
  border-top: 1px solid $sk-grey-10;
}

#profile__sidebar {
  width: 302px;
  flex: 0 0 auto;

  #profile__sidebar__menu {
    width: 246px;
    padding: 16px;
    overflow: auto;
    border-radius: 8px;
    background-color: $sk-grey-5;
  }

  .sidebar__menu {
    border-radius: 4px;
    background: $sk-grey-5;
  }

  a {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    margin-bottom: 10px;

    &:hover {
      background: $sk-grey-10;
      border-radius: 4px;
      text-decoration: none;
    }

    span.sidebar__item__label {
      display: flex;
      color: $sk-black;
      padding-left: 10px;
    }

    &.router-link-active {
      background: $sk-blue-10;
      border-radius: 4px;

      .sidebar__item__label {
        color: $sk-blue;
      }

      &:hover {
        background: $sk-blue-5;
      }

      svg {
        fill: $sk-blue-50;
      }
    }

    svg {
      fill: $sk-black;
      min-width: 20px;
    }
  }
}

#profile__content {
  overflow-y: auto;
  flex: 1;
  min-width: 400px;

  .content__container {
    max-width: 900px;
  }
}
</style>
