<template>
  <SkMedallion
    :size="size"
    :text="medallionText"
    :errored="errored"
    :error-tooltip="errorTooltip"
    :image-url="avatarUrl"
    :background-color="medallionBackground"
    :color="medallionContentColor"
    :is-highlighted="isHighlighted"
  />
</template>

<script>
import { SkMedallion } from '@skelloapp/skello-ui';

export default {
  name: 'AvatarMedallion',
  components: {
    SkMedallion,
  },
  props: {
    avatarUrl: {
      type: String,
      default: undefined,
    },
    medallionText: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'medium',
    },
    medallionBackground: {
      type: String,
      default: undefined,
    },
    medallionContentColor: {
      type: String,
      default: undefined,
    },
    errorTooltip: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
