<template>
  <div class="text-variables-panel">
    <h1 class="sk-header--1">
      {{ $t('text_document_templates.editor.variables_panel.title') }}
    </h1>
    <p>{{ $t('text_document_templates.editor.variables_panel.subtitle') }}</p>
    <div
      v-for="(fields, section) in sections"
      :key="section"
      class="text-variables-panel__section"
    >
      <h2>{{ $t(`text_document_templates.editor.variables_panel.${section}`) }}</h2>
      <div class="text-variables-panel__section__variables">
        <TemplateVariable
          v-for="field in fields"
          :key="field"
          :field="field"
          :label="templateVariablesKeys.variables[field]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TemplateVariable from './TemplateVariable';

export default {
  name: 'TemplateVariablesPanel',
  components: {
    TemplateVariable,
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', {
      templateVariablesKeys: state => state.config.text_document_template_variables_keys,
    }),
    sections() {
      const result = {
        employee: [
          'EMPLOYEE_LAST_NAME',
          'EMPLOYEE_FIRST_NAME',
          'EMPLOYEE_GENDER',
          'EMPLOYEE_BIRTHDAY',
          'EMPLOYEE_BIRTH_PLACE',
          'EMPLOYEE_BIRTH_DEPARTMENT',
          'EMPLOYEE_BIRTH_COUNTRY',
          'EMPLOYEE_ADDRESS',
          'EMPLOYEE_POSTAL_CODE',
          'EMPLOYEE_CITY',
          'EMPLOYEE_NATIONALITY',
          'EMPLOYEE_SSN',
          'EMPLOYEE_EMAIL',
          'EMPLOYEE_PHONE',
          'EMPLOYEE_DOCUMENT_TYPE',
        ],
        contract: [
          'CONTRACT_START_DATE',
          'CONTRACT_TRIAL_END_DATE',
          'CONTRACT_LEVEL',
          'CONTRACT_STEP',
          'CONTRACT_POSTE',
          'CONTRACT_STATUS',
          'CONTRACT_SALARY',
          'CONTRACT_HOURLY_WAGE',
          'CONTRACT_START_HOUR',
          'CONTRACT_END_DATE',
          'CONTRACT_TYPE',
          'CONTRACT_HOURLY_VOLUME',
        ],
        shop: [
          'SHOP_NAME',
          'SHOP_DENOMINATION_SOCIALE',
          'SHOP_SIRET',
          'SHOP_ADDRESS',
          'SHOP_ZIPCODE',
          'SHOP_CITY',
          'SHOP_COUNTRY',
        ],
        context: ['TODAY_DATE'],
      };

      if (this.currentShop.attributes.country === 'ES') {
        result.employee.push('EMPLOYEE_ID_NUMBER');
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-variables-panel {
  width: 450px;
  position: fixed;
  top: 112px; /* Editor Header and Toolbar heights */
  right: 0;
  z-index: 1;
  padding: 25px;
  padding-bottom: 50px;
  border-left: 1px solid $sk-grey-10;
  height: calc(100vh - 90px);
  overflow-y: scroll;

  h1 {
    color: $sk-black;
    font-size: $fs-heading-xs;
  }

  p {
    color: $sk-grey;
  }

  .text-variables-panel__section {
    margin-top: 24px;

    h2 {
      font-size: 1.2em;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  .text-variables-panel__section__variables {
    max-width: 360px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
</style>
