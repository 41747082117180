import AnalyticsDashboard from './AnalyticsDashboard';

const analyticsDashboardsRoutes = [
  {
    path: '/v3/shops/:shop_id/analytics',
    component: AnalyticsDashboard,
    name: 'analytics_dashboard',
  },
];

export default analyticsDashboardsRoutes;
