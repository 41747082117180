<template>
  <div class="shop-settings__user-row">
    <SkMedallion
      :text="userInitials"
      background-color="#2b66fe"
      color="white"
      class="shop-settings__user-row__medallion"
    />
    {{ userFullName }}
    <!-- eslint-disable max-len -->
    <div
      v-tooltip="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.modifier_tooltip')"
      class="shop-settings__user-row__modifiers"
    >
      <!-- eslint-enable max-len -->
      <span @click="selectModifier(user, '-')">
        <CircledMinusSignThinIcon
          v-track="trackingOptions.minus"
          :background-fill="isModifierSelected(user, '-') ? '#2b66fe' : 'none'"
          :sign-fill="isModifierSelected(user, '-') ? 'white' : '#727272'"
          :border-fill="isModifierSelected(user, '-') ? '#2b66fe' : '#727272'"
          class="shop-settings__user-row__modifier"
        />
      </span>
      <span @click="selectModifier(user, '+')">
        <CircledPlusSignThinIcon
          v-track="trackingOptions.plus"
          :background-fill="isModifierSelected(user, '+') ? '#2b66fe' : 'none'"
          :sign-fill="isModifierSelected(user, '+') ? 'white' : '#727272'"
          :border-fill="isModifierSelected(user, '+') ? '#2b66fe' : '#727272'"
          class="shop-settings__user-row__modifier"
        />
      </span>
    </div>
    <SkInputGroup
      :errored="enableErrorProc && user.errorBalance"
      class="shop-settings__user-row__input"
    >
      <!-- eslint-disable max-len -->
      <SkInput
        v-model="user.balance"
        :placeholder="$t('shop_settings.tabs.rules.counter_rules.modulation.change_majoration.balance.placeholder')"
        type="number"
        step="1"
        min="0"
        @keyup="$emit('keyup', $event, user)"
      />
      <!-- eslint-enable max-len -->
      <template #append>
        {{ unit }}
      </template>
    </SkInputGroup>
  </div>
</template>

<script>

export default {
  name: 'UserRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    enableErrorProc: {
      type: Boolean,
      required: false,
      default: true,
    },
    trackingOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    userInitials() {
      return this.user.attributes.firstName.trim()[0].toUpperCase() +
        this.user.attributes.lastName.trim()[0].toUpperCase();
    },
    userFullName() {
      const { firstName, lastName } = this.user.attributes;
      const separatorSpace = ' ';

      return firstName.charAt(0).toUpperCase() + firstName.slice(1) +
        separatorSpace +
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
    },
  },
  methods: {
    isModifierSelected(user, modifier) {
      return user.balanceModifier === modifier;
    },
    selectModifier(user, modifier) {
      user.balanceModifier = modifier;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings__user-row {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid $sk-grey-10;

  &:last-child {
    border-bottom: 1px solid $sk-grey-10;
  }

  .shop-settings__user-row__medallion {
    margin-right: 14px;
  }

  .shop-settings__user-row__modifiers {
    margin-right: 10px;
    margin-left: auto;
    cursor: pointer;
  }

  .shop-settings__user-row__modifier {
    margin-left: 3px;
  }

  .shop-settings__user-row__input {
    margin-right: 0;
    width: 200px;

    ::v-deep .sk-input-group__error-label {
      margin: 0;
    }
  }
}
</style>
