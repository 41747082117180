<template>
  <tr class="historic_row__wrapper">
    <td class="historic_row__date">
      {{ rollingDate }}
    </td>
    <td class="historic_row__col historic_row__team">
      {{ cyclicAmendments[0].teamName }}
    </td>
    <td class="historic_row__col">
      <VariableContractHoursTag
        :nb-weeks="cyclicAmendments.length"
        class="historic_row__col__variable_contract_hours_tag"
      />
      <!-- eslint-disable-next-line max-len -->
      {{ cyclicAmendments.length }} {{ $t('employees.tabs.contracts.actions.historic.modal.weeks') }}
    </td>
    <td class="historic_row__col">
      <span>
        {{ rollingStartYear }}<br>
      </span>
      {{ $t('employees.tabs.contracts.actions.historic.modal.week') }} {{ rollingWeekStart }}
    </td>
    <td class="historic_row__col">
      <template v-if="cyclicAmendments[0].attributes.endsAt">
        <span>
          {{ rollingEndYear }}<br>
        </span>
        {{ $t('employees.tabs.contracts.actions.historic.modal.week') }} {{ rollingWeekEnd }}
      </template>
      <template v-else>
        -
      </template>
    </td>
    <td class="historic_row__col historic_row__col__contract_hours">
      <div
        v-for="amendment in cyclicAmendments"
        :key="amendment.id"
        class="historic_row__col__contract_hours__row"
      >
        <VariableContractHoursTag
          :nb-weeks="cyclicAmendments.length"
          :specific-week-index="amendment.attributes.scheduleWeekIndex"
          class="historic_row__col__contract_hours__row__variable_contract_hours_tag"
        />
        {{ amendment.attributes.hours }}h
      </div>
    </td>
  </tr>
</template>

<script>
import skDate from '@skello-utils/dates';
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';

export default {
  name: 'HistoricRow',
  components: { VariableContractHoursTag },
  props: {
    cyclicAmendments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    skDateStartsAt() {
      return skDate.utc(this.cyclicAmendments[0].attributes.startsAt);
    },
    skDateEndsAt() {
      return skDate.utc(this.cyclicAmendments[0].attributes.endsAt);
    },
    rollingDate() {
      return this.skDateStartsAt.format('DD/MM/YY');
    },
    rollingStartYear() {
      return this.skDateStartsAt.format('YYYY');
    },
    rollingEndYear() {
      return this.skDateEndsAt.format('YYYY');
    },
    rollingWeekStart() {
      return this.skDateStartsAt.format('WW');
    },
    rollingWeekEnd() {
      return this.skDateEndsAt.format('WW');
    },
  },
};
</script>

<style lang="scss" scoped>
.historic_row__wrapper {
  font-size: $fs-text-s;
}

.historic_row__team {
  font-weight: $fw-semi-bold;
}

.historic_row__col {
  width: 120px;

  span {
    font-weight: $fw-semi-bold;
  }
}

.historic_row__date {
  width: 100px;
  color: $sk-grey;
}

.historic_row__col__variable_contract_hours_tag {
  width: fit-content;
}

.historic_row__col__contract_hours {
  columns: 2;
  padding-bottom: 4px;
}

.historic_row__col__contract_hours__row {
  display: inline-flex;
  padding-bottom: 6px;
  vertical-align: top;
}

.historic_row__col__contract_hours__row__variable_contract_hours_tag {
  margin-right: 6px;
  font-size: $fs-text-xs;
  height: 16px;
  width: 19px;
}
</style>
