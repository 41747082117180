<template>
  <div class="days-planning">
    <div
      class="days-planning__wrapper"
      :style="zoomRange.planningWrapper"
    >
      <LoadingPage v-if="isDailyViewLoading" />
      <template v-else>
        <WorkloadPlanPanel v-if="isWorkloadPlanDisplayed" />
        <Header />
        <PlanningTable
          ref="dayPlanningTable"
          :is-workload-plan-displayed="isWorkloadPlanDisplayed"
        />
      </template>
      <!--
        This component is used to display quick actions on shift
        We need it here to be outside the inner planning component (which has an hidden overflow)
        Otherwise, the quick add won't be visible over Header or counter columns
      -->
      <ShiftMenu />
      <ShiftAlerts />
      <Tooltips />
    </div>
    <WorkForceScrollBar
      v-if="isWorkforceScrollBarDisplayed"
      :is-workload-plan-displayed="isWorkloadPlanDisplayed"
    />

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <PublishPlanningModalWeekly />
    </MountingPortal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { zoomPlanningDay } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import { workingHours } from '@app-js/plannings/shared/utils/planning_helpers';
import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';
import PlanningTable from '@app-js/plannings/shared/PlanningTable';
import skDate from '@skello-utils/dates';
import ShiftMenu from '@app-js/plannings/shared/components/Shift/menu';
import ShiftAlerts from '@app-js/plannings/shared/components/Shift/Alerts';
import Tooltips from '@app-js/plannings/pages/Days/Tooltips';
import PublishPlanningModalWeekly from '@app-js/plannings/shared/components/Toolbar/PublishPlanningModal/ModalWeekly';
import Header from './Header';
import WorkloadPlanPanel from './WorkloadPlanPanel';
import WorkForceScrollBar from './WorkForceScrollBar';

const MINIMUM_QUARTER_SIZE_IN_PX = 15;

export default {
  name: 'Days',
  components: {
    PublishPlanningModalWeekly,
    LoadingPage,
    PlanningTable,
    Header,
    ShiftMenu,
    Tooltips,
    ShiftAlerts,
    WorkloadPlanPanel,
    WorkForceScrollBar,
  },
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      // When user manually enters a date in url from day view or click on "day" from navbar;
      // we redirect him to the next visible day to make sure he won't end up on a day that is not visible.
      vm.redirectToNextVisibleDay(to.query.date);
    });
  },
  data() {
    return {
      isInitWeekLoading: true,
      debouncedSetPlanningSize: () => {},
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsState', [
      'shopPlanningConfigLoading',
      'dayViewPlanningSizeVariables',
    ]),
    ...mapState('planningsLoading', [
      'isPlanningDataLoading',
      'abortRecursiveBatching',
      'arePlanningDataBatchesLoading',
    ]),
    ...mapGetters('planningsState', [
      'currentDate',
      'isDayVisible',
      'nextVisibleDay',
      'isDayViewScrollable',
    ]),
    ...mapGetters('currentShop', [
      'isVariableContractHoursAvailable',
      'isDevFlagEnabled',
    ]),
    ...mapGetters('planningsWorkloadPlans', ['fetchParamsByDay']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    isDailyViewLoading() {
      return this.isInitWeekLoading || this.shopPlanningConfigLoading;
    },
    shopId() {
      // TODO : set shopId in planningState from this param
      return this.$route.params.shop_id;
    },
    zoomRange() {
      return {
        planningWrapper: zoomPlanningDay(this.planningZoom).planningWrapper,
      };
    },
    isWorkloadPlanDisplayed() {
      return this.checkPackOfferFlag('workload_plan_enabled');
    },
    isWorkforceScrollBarDisplayed() {
      return !this.isDailyViewLoading && this.isDayViewScrollable;
    },
  },
  watch: {
    $route(to, from) {
      const hasWeekChanged = this.hasWeekChanged(to.query.date, from.query.date);
      const hasDayChanged = to.query.date !== from.query.date;

      // Update workload plans if day has changed but not the week
      if (!hasWeekChanged && hasDayChanged) this.handleWorkloadPlan();

      // If the day has changed, we can re-init the week here
      if (hasDayChanged) this.initWeek();
    },
    abortRecursiveBatching(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.initWeek();
      }
    },
    isDailyViewLoading(newValue) {
      if (newValue === false) {
        const { name, path, fullPath } = this.$route;
        const measure = performance?.measure?.(path, name);

        if (!measure) {
          return;
        }

        this.$skAnalytics.track('page_load_time_planning_day', {
          duration: Math.round(measure.duration),
          fullPath,
          shopId: this.currentUser.id,
          userId: this.currentShop.id,
        });
      }
    },
  },
  created() {
    this.initWeek();

    this.listenOnRoot('redirect-to-next-visible-day', () => this.redirectToNextVisibleDay(this.currentDate));
    this.debouncedSetPlanningSize = debounce(this.setPlanningSize, 100);
  },
  mounted() {
    window.addEventListener('resize', this.debouncedSetPlanningSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedSetPlanningSize);
  },
  methods: {
    ...mapActions('planningsLoading', ['fetchBatchablePlanningShopDateRangeData']),
    ...mapActions('planningsWorkloadPlans', ['fetchWorkloadPlans']),
    ...mapMutations('planningsLoading', ['setAbortRecursiveBatching']),
    ...mapMutations('planningsState', ['setAsSelectedTab', 'setDayPlanningSizeVariables', 'closeTotalPeriodTab']),

    // TODO: DEV-10003 -> Initialize planning for all views (days, weeks, months) on Plannings.vue
    initWeek() {
      this.closeTotalPeriodTab();

      if (this.arePlanningDataBatchesLoading || this.isPlanningDataLoading) {
        this.setAbortRecursiveBatching(true);
        return;
      }

      this.setPlanningSize();
      this.isInitWeekLoading = true;

      // For daily view selected counter tab is always total
      this.setAsSelectedTab('total');

      this.fetchBatchablePlanningShopDateRangeData({ shopId: this.shopId, period: 'day' })
        .then(() => {
          this.$emit('planning-loaded');

          if (this.isWorkloadPlanDisplayed) {
            this.handleWorkloadPlan();
          }

          // TODO: use isPlanningLoading from plannings-state when all fetchs for day view are used
          this.isInitWeekLoading = false;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    setPlanningSize() {
      if (!this.isDevFlagEnabled('FEATUREDEV_REACTIVE_DAY_VIEW_CANARY')) {
        this.setDayPlanningSizeVariables(2);
        return;
      }

      const viewportWidth = document.documentElement.clientWidth;
      const planningSize = viewportWidth -
        this.dayViewPlanningSizeVariables.sidebarWidth -
        this.dayViewPlanningSizeVariables.countersWidth;
      const nbWorkingHours =
        workingHours(this.currentShop, this.currentDate).length - 1;
      const nbQuartersToDisplay = nbWorkingHours * 4;
      const minimumPlanningSize = nbQuartersToDisplay * MINIMUM_QUARTER_SIZE_IN_PX;

      const idealPixelPerMinuteRatio = planningSize / minimumPlanningSize;

      if (idealPixelPerMinuteRatio < 1) {
        this.setDayPlanningSizeVariables(1);
      } else {
        this.setDayPlanningSizeVariables(idealPixelPerMinuteRatio);
      }
    },
    // Only for testing at the moment
    handleWorkloadPlan() {
      if (!this.isWorkloadPlanDisplayed) return;

      const postes = this.postes.map(poste => poste.id);
      const payload = this.fetchParamsByDay(this.currentDate, postes);

      this.fetchWorkloadPlans(payload);
    },
    hasWeekChanged(toDate, fromDate) {
      return !skDate(toDate).isSame(fromDate, 'isoWeek');
    },
    redirectToNextVisibleDay(date) {
      if (this.isDayVisible(date)) return;

      this.navigateToDay(date);
    },
    navigateToDay(date) {
      this.$router.push({ query: { date: this.nextVisibleDay(date) } });
    },
  },
};
</script>

<style lang="scss" scoped>
.days-planning {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.days-planning__wrapper {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: -1px;
}

// This :after fakes a shadow on left side only for all planning-row sidebar cells
// a regular shadow is impossible to be displayed on only one side
// deep is needed as it is common class on header and planning table sidebar cells
::v-deep .cell--shadow::after {
  content: " ";
  width: 14px;
  position: absolute;
  top: 0;
  height: 100%;
  opacity: .12;
  z-index: -1; // put the shadow under the cell's content
}

::v-deep .cell--shadow-right::after {
  background: linear-gradient(to right, rgba(0, 0, 0, .4), #fff);
  right: -14px;
}

::v-deep .cell--shadow-left::after {
  background: linear-gradient(to left, rgba(0, 0, 0, .4), #fff);
  left: -14px;
}
</style>
