<template>
  <div
    v-if="isPlanningDataCompleted"
    class="automatic-planning__sidepanel__provenance-choice__wrapper"
  >
    <h3 class="section_title">
      {{ $t('automatic_planning.sidebar.intro.schedule_optimization_title') }}
    </h3>

    <p class="provenance-choice__text">
      {{ $t('automatic_planning.sidebar.intro_paragraph_start') }}
    </p>
    <p class="provenance-choice__text">
      {{ $t('automatic_planning.sidebar.intro_paragraph_end') }}
      <SkOroraLink
        :href="automaticPlanningRulesUrl"
        @click="trackLinkClick"
      >
        <span>{{ $t('automatic_planning.sidebar.provenance_choice.link') }}</span>
      </SkOroraLink>.
    </p>

    <hr class="automatic-planning-intro__separation">

    <h3 class="provenance-choice__subtitle">
      {{ $t('automatic_planning.sidebar.provenance_choice.prompt_base') }}
    </h3>

    <div class="provenance-choice__choices">
      <label
        v-for="provenanceChoice in provenanceChoices"
        :key="provenanceChoice"
        v-tooltip="disabledTooltip(provenanceChoice)"
        :class="listItemClasses(provenanceChoice)"
        :for="radioId(provenanceChoice)"
      >
        <SkRadio
          :id="radioId(provenanceChoice)"
          v-model="currentProvenanceChoice"
          :value="provenanceChoice"
          :data-value="provenanceChoice"
          :disabled="provenanceChoiceDisabled(provenanceChoice)"
        />
        <div>
          <h4 :class="subtitleClasses(provenanceChoice)">
            {{ $t(`automatic_planning.sidebar.provenance_choice.${provenanceChoice}.subtitle`) }}
          </h4>

          <p class="provenance-choice__choice__text">
            {{ $t(`automatic_planning.sidebar.provenance_choice.${provenanceChoice}.text`) }}
          </p>
        </div>
      </label>
    </div>

    <h3 class="provenance-choice__subtitle-unassigned">
      {{ $t('automatic_planning.sidebar.provenance_choice.prompt_non_assigned') }}
    </h3>

    <div class="provenance-choice__choices">
      <label
        v-tooltip="disabledTooltip(currentPlanning)"
        :class="listItemClasses(currentPlanning)"
        :for="radioId(currentPlanning)"
      >
        <SkRadio
          :id="radioId(currentPlanning)"
          v-model="currentProvenanceChoice"
          :value="currentPlanning"
          :data-value="currentPlanning"
          :disabled="provenanceChoiceDisabled(currentPlanning)"
        />
        <div>
          <h4 :class="subtitleClasses(currentPlanning)">
            {{
              $t(`automatic_planning.sidebar.provenance_choice.${currentPlanning}.subtitle`)
            }}
          </h4>

          <p class="provenance-choice__choice__text">
            {{
              $t(`automatic_planning.sidebar.provenance_choice.${currentPlanning}.text`)
            }}
          </p>
        </div>
      </label>
    </div>

    <div class="automatic-planning__rules-box__submit">
      <SkOroraButton @click="updateStep">
        {{ $t('automatic_planning.sidebar.next') }}
      </SkOroraButton>
    </div>
  </div>
  <LoaderContainer v-else />
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';

export default {
  name: 'ProvenanceChoice',
  components: {
    LoaderContainer,
  },
  props: {
    automaticPlanningRulesUrl: {
      type: String,
      required: true,
    },
    isPlanningDataCompleted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const storedProvenance = localStorage.getItem('previousProvenanceChoice');
    // If it's undefined (never stored) or value stored is incorrect (e.g. steps have changed)
    // -> Default back to previous week
    const defaultProvenance = ['from-template', 'previous-week'].includes(storedProvenance) ?
      storedProvenance : 'previous-week';

    return {
      currentProvenanceChoice: defaultProvenance,
    };
  },
  computed: {
    ...mapState('planningsAutomaticPlanning', ['config']),
    ...mapGetters('planningsAutomaticPlanning', ['provenanceSteps', 'isBrainFeatureFlagActive']),
    currentPlanning() {
      return this.provenanceSteps.current_planning;
    },
    provenanceChoices() {
      return [
        this.provenanceSteps.previous_week,
        this.provenanceSteps.from_template,
      ];
    },
    stepToSaveForNextTime() {
      if (this.currentProvenanceChoice === this.provenanceSteps.current_planning) {
        return this.provenanceSteps.previous_week;
      }

      return this.currentProvenanceChoice;
    },
  },
  methods: {
    // Don't just use provenance to avoid a generic id like "template" in the page
    radioId(provenance) {
      return `automatic-planning-sidepanel-${provenance}`;
    },
    disabledTooltip(provenance) {
      if (this.provenanceChoiceDisabled(provenance)) {
        return this.$t(`automatic_planning.sidebar.provenance_choice.${provenance}.disabled`);
      }
      return null;
    },
    provenanceChoiceDisabled(provenance) {
      if (provenance === this.provenanceSteps.from_template) {
        return !this.config.at_least_one_template;
      }
      if (provenance === this.currentPlanning) {
        return !this.config.unassigned_shift_in_planning;
      } // previous_week
      return this.config.shift_in_previous_weeks.includes(week => week[1]);
    },
    listItemClasses(provenance) {
      return {
        'provenance-choice__choice': true,
        'provenance-choice__choice--active': provenance === this.currentProvenanceChoice,
        'provenance-choice__choice--disabled': this.provenanceChoiceDisabled(provenance),
      };
    },
    subtitleClasses(provenance) {
      return {
        'provenance-choice__choice__subtitle': true,
        'provenance-choice__choice__subtitle--active': provenance === this.currentProvenanceChoice,
      };
    },
    updateStep() {
      localStorage.setItem('previousProvenanceChoice', this.currentProvenanceChoice);
      this.$emit('update-step', this.currentProvenanceChoice);
    },
    trackLinkClick() {
      this.$skAnalytics.track('automatic_planning_settings', { source: 'Start SP' });
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: .5rem;
}

.automatic-planning__sidepanel__provenance-choice__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.automatic-planning__rules-box__submit {
  display: flex;
  position: absolute;
  width: 412px;
  padding: 30px 24px 24px;
  bottom: 0;
  right: 0;
  margin-top: auto;
  justify-content: flex-end;
}

.provenance-choice__text {
  margin-top: 10px;
  color: $sk-grey;
  margin-bottom: 0;
}

.automatic-planning-intro__separation {
  margin: 16px 0;
  border: 0;
  border-top: 1px solid #eee;
}

.provenance-choice__subtitle {
  font-weight: $fw-semi-bold;
  color: $sk-black;
  margin-bottom: 14px;
  margin-top: 0;
  line-height: 22px;
  font-size: $fs-text-m;
}

.provenance-choice__subtitle-unassigned {
  font-weight: $fw-semi-bold;
  color: $sk-black;
  margin-bottom: 14px;
  margin-top: 9px;
  line-height: 22px;
  font-size: $fs-text-m;
}

.provenance-choice__choice__subtitle--active {
  color: $sk-blue;
}

.provenance-choice__choice {
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  padding: 7px 16px 13px;
  font-weight: $fw-regular;
}

.provenance-choice__choice--active {
  // matches sk-blue-5 with opacity only on background
  background-color: rgb(238, 244, 255, .6);
}

.provenance-choice__choice--disabled {
  cursor: not-allowed;

  .provenance-choice__choice__subtitle {
    color: #bbbec2;
  }

  .provenance-choice__choice__text {
    color: #d3d8dd;
  }
}

.provenance-choice__choice__subtitle {
  letter-spacing: .1px;
  margin-top: 6px;
  margin-bottom: 5px;
  font-size: $fs-text-m;
  font-weight: normal;
}

.provenance-choice__choice__text {
  font-size: $fs-text-s;
  color: $sk-grey;
  margin-bottom: 0;
  line-height: 19px;
}
</style>
