<template>
  <SkModal
    :id="`unlock-request-modal-${type}-${date}`"
    ref="manageNoteModal"
    :modal-title="$t('plannings.table.unlock_request_modal.title')"
    size="ds-medium"
    :submit-disabled="disableSubmit"
    @show="handleShow"
    @submit="handleSubmit"
  >
    <template #title-icon>
      <div class="unlock_request_modal__icon">
        <OpenLockIcon />
      </div>
    </template>
    <template #body>
      <SkModalSection border-bottom="none">
        <!-- eslint-disable max-len vue/no-v-html -->
        <div
          class="unlock_request_modal__description"
          v-html="$t(`plannings.table.unlock_request_modal.description.${type}`, { date: formattedDate })"
        />
        <!-- eslint-enable -->
        <div class="unlock_request_modal__line">
          <div class="unlock_request_modal__title">
            <b>{{ $t('plannings.table.unlock_request_modal.label') }}</b>
          </div>
          <div class="unlock_request_modal__text-area-wrapper">
            <SkTextarea
              ref="explanationTextarea"
              v-model="explanation"
              :label="$t('plannings.table.unlock_request_modal.placeholder')"
              :max-height="99"
              :min-height="54"
              rows="3"
              auto-grow
            />
          </div>
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'UnlockRequestModal',
  props: {
    type: {
      type: String,
      default: 'day',
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formattedDate: null,
      explanation: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    disableSubmit() {
      return !this.explanation || this.explanation.trim() === '';
    },
  },
  methods: {
    ...mapActions('planningsState', ['unlockRequest']),
    handleSubmit() {
      this.unlockRequest({
        shopId: this.currentShop.id,
        type: this.type,
        date: this.date,
        explanation: this.explanation,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('plannings.table.unlock_request_modal.actions.success'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({ message: this.$t('plannings.table.unlock_request_modal.actions.error'), variant: 'error' });
        });
    },
    handleShow() {
      this.explanation = '';
      this.formattedDate = skDate(this.date).format('dddd Do MMMM YYYY');

      this.$nextTick(() => {
        this.$refs.explanationTextarea.focus();
      }, 0);
    },
  },
};

</script>

<style lang="scss" scoped>
.unlock_request_modal__icon {
  display: flex;
  padding: 9px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: $sk-grey-10;
}

.unlock_request_modal__description {
  margin-bottom: 24px;
}

.unlock_request_modal__text-area-wrapper {
  width: 320px;
}

.unlock_request_modal__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 19px;
}
</style>
