<template>
  <div class="badging-history">
    <div class="badging-history__title">
      {{ $t('badgings.history.page_title') }}
    </div>
    <div class="badging-history__rules">
      <span>
        {{ $t('badgings.history.explanation') }}
      </span>
      <span>
        <SkOroraButton
          variant="secondary"
          @click="openBadgingRulesModal"
        >
          {{ $t('badgings.history.see_rules') }}
        </SkOroraButton>
      </span>
    </div>
    <div class="badging-history__table">
      <SkTable
        :columns="headers"
        :loading="matchedBadgingsLoading"
        :is-empty="isEmptyValidated"
        @sort="handleSort"
      >
        <template #empty-state>
          <p
            v-if="isEmptyValidated"
            class="badging-history__no-data"
          >
            {{ $t('badgings.history.no_data') }}
          </p>
        </template>
        <MatchedBadgingHistoryRow
          v-for="matchedBadging in matchedBadgingsPerDayWithAnomalies"
          :key="matchedBadging.shiftId || matchedBadging.badgingId"
          :matched-badging="matchedBadging"
        />
      </SkTable>
    </div>
  </div>
</template>

<script>
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import {
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import MatchedBadgingHistoryRow from '@app-js/badgings/shared/components/MatchedBadgingHistoryRow';

import { isEmpty } from '@skello-utils/array';

export default {
  name: 'History',
  components: {
    MatchedBadgingHistoryRow,
  },
  computed: {
    ...mapState('badgings', ['matchedBadgingsLoading']),
    ...mapGetters('badgings', [
      'matchedBadgingsPerDay',
    ]),
    matchedBadgingsPerDayWithAnomalies() {
      return this.matchedBadgingsPerDay()?.filter(
        matchedBadging => matchedBadging.anomalyReason &&
          matchedBadging.validated &&
          !matchedBadging.ignored,
      );
    },
    headers() {
      return [
        {
          title: this.$t('badgings.days.tabs.shifts.headers.employees'),
          name: 'firstName',
          sort: true,
          class: 'badging-history__table--employee-name',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.shift_hours'),
          name: 'predictedStartsAt',
          sort: true,
          defaultSort: 'asc',
          class: 'badging-history__table--shifts-planned',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.shift_break'),
          class: 'table-column__border-right badging-history__table--planned-break',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.badging_hours'),
          class: 'badging-history__table--badging-hours',
          name: 'badgingStartsAt',
          sort: true,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.badging_break'),
          class: 'table-column__border-right badging-history__table--badging-break',
        },
        {
          title: this.$t('badgings.history.headers.position'),
          class: 'badging-history__table--position',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.selected_hours'),
          class: 'badging-history__table--shifts-paid',
          name: 'selectedStartsAt',
          sort: true,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.selected_break'),
          class: 'table-column__border-right badging-history__table--paid-break',
        },
        {
          title: this.$t('badgings.history.headers.validated_by'),
          class: 'badging-history__table--validated-by',
        },
        {
          title: this.$t('badgings.history.headers.validated_at'),
          class: 'table-column__border-right badging-history__table--validated-at',
        },
        {
          title: this.$t('badgings.history.headers.anomaly_reason'),
          class: 'table-column__border-right badging-history__table--anomaly-reason',
        },
      ];
    },
    isEmptyValidated() {
      return isEmpty(this.matchedBadgingsPerDayWithAnomalies);
    },
  },
  methods: {
    ...mapMutations('badgings', ['sortmatchedBadgings']),
    openBadgingRulesModal(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'badging-rules-modal');
    },
    handleSort(event, columnName, direction) {
      this.sortmatchedBadgings({ columnName, direction });
    },
  },
};
</script>

<style lang="scss">
.badging-history {
  padding: 30px 30px 0 24px;
}

.badging-history__title {
  font-weight: $fw-semi-bold;
  font-size: $fs-heading-s;
  line-height: 22.3px;
}

.badging-history__rules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}

.badging-history__table {
  margin-top: 24px;

  .badging-history__no-data {
    display: flex;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: $sk-black;
    text-align: center;
    font-family: $sk-base-font;
    font-size: $fs-text-l;
    font-style: normal;
    font-weight: $fw-semi-bold;
    line-height: normal;
  }

  &--employee-name {
    width: 126px;
    padding-right: 16px;

    @media (max-width: 1200px) {
      padding-right: 8px;
    }
  }

  &--shifts-planned {
    width: 113px;
    padding-right: 16px;

    & > .table-column__label--sortable {
      margin-left: 0 !important;
    }

    @media (max-width: 1200px) {
      padding-right: 8px;
    }
  }

  &--planned-break {
    width: 61px;
    padding-right: 16px;

    @media (max-width: 1200px) {
      padding-right: 8px;
    }
  }

  &--badging-hours {
    width: 136px;
    padding-right: 16px;
    padding-left: 16px;

    & > .table-column__label--sortable {
      margin-left: 0 !important;
    }

    @media (max-width: 1200px) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &--badging-break {
    width: 52px;
    padding-right: 16px;

    @media (max-width: 1200px) {
      padding-right: 8px;
    }
  }

  &--position {
    width: 127px;
    padding-left: 16px;

    @media (max-width: 1200px) {
      padding-left: 8px;
    }
  }

  &--shifts-paid {
    width: 127px;
    padding-right: 16px;
    padding-left: 16px;

    & > .table-column__label--sortable {
      margin-left: 0 !important;
    }

    @media (max-width: 1200px) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &--paid-break {
    width: 52px;
    padding-right: 16px;

    @media (max-width: 1200px) {
      padding-right: 8px;
    }
  }

  &--validated-by {
    width: 104px;
    padding-right: 16px;
    padding-left: 16px;

    @media (max-width: 1200px) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &--validated-at {
    width: 88px;
    padding-left: 16px;

    @media (max-width: 1200px) {
      padding-left: 8px;
    }
  }

  &--anomaly-reason {
    width: 217px;
    padding-left: 16px;

    @media (max-width: 1200px) {
      padding-left: 8px;
    }
  }
}
</style>
