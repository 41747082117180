<template>
  <SkPopover as-tooltip>
    <template #anchor>
      <SkSwitch disabled />
    </template>
    <template #content>
      <div class="tooltip-content">
        {{ beforeTagText }}
        <PackTag :type="currentOrganisation.attributes.packOffer.name" />
        {{ afterTagText }}
        <div>
          {{ lastSentence }}
        </div>
      </div>
    </template>
  </SkPopover>
</template>

<script>
import { mapState } from 'vuex';
import PackTag from '@app-js/shared/components/PackTag';

export default {
  name: 'UpsellPopover',
  components: { PackTag },
  props: {
    beforeTagText: {
      type: String,
      required: true,
    },
    afterTagText: {
      type: String,
      required: true,
    },
    lastSentence: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
  },
};
</script>
<style lang="scss" scoped>
.tooltip-content {
  width: 250px;

  div:last-child {
    margin-top: 15px;
  }
}
</style>
