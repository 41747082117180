<template>
  <SkSelectV2
    id="workload-plan-postes"
    v-model="selectedPosteId"
    class="workload_plan_postes-select__select"
    :options="posteOptions"
    :disabled="disabled"
    :search-placeholder="$t('workload_plan.postes_select.search')"
    :no-results-label="$t('workload_plan.postes_select.no_result')"
    :empty-option-display="$t('workload_plan.postes_select.no_result')"
    name="workload-plan-postes"
    width="145px"
    no-confirm
    append-to-body
  >
    <template #selected-option="{ value }">
      <span class="workload_plan_postes-select__options__text">
        {{ posteById(value).text }}
      </span>
    </template>
    <template #option="{ option }">
      <div class="workload_plan_postes-select__options__text text-truncate">
        <div
          v-tooltip.bottom-start="overflowTooltip"
          class="workload_plan_postes-select__option"
          @mouseenter="setOverflowTooltip(option.text)"
        >
          {{ getFomattedText(option.text) }}
        </div>
      </div>
    </template>
  </SkSelectV2>
</template>

<script>

export default {
  name: 'PostesSelect',
  props: {
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    posteId: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      overflowTooltip: null,
    };
  },
  computed: {
    selectedPosteId: {
      get() {
        // We need to wait for the postes to be fully loaded, to avoid searching
        // in empty array unvalid poste list
        if (this.postes.length === 0 ||
            !this.posteOptions.find(poste => poste.id === this.posteId)) {
          return null;
        }

        return this.posteId;
      },
      set(newPosteId) {
        this.$emit('on-poste-change', newPosteId);
      },
    },
    posteOptions() {
      const posteEntries = this.postes.map(poste => ({
        id: poste.id,
        text: poste.attributes.name,
      }));

      // Add the "All postes" options only if more than 1 poste
      if (this.postes.length > 1) {
        return [
          {
            id: 'all',
            text: this.$t('workload_plan.postes_select.all_postes'),
          },
          ...posteEntries,
        ];
      }
      return posteEntries;
    },
  },
  methods: {
    posteById(id) {
      return this.posteOptions.find(poste => poste.id === id);
    },
    /*
        Managing tooltip reactivity is complex.
        We need ref to get element size but ref is only available when ajax fetch is over
        and component has finished building options.
        It's easier to just use @mouseenter to ensure that option is fully loaded
     */
    setOverflowTooltip(optionText) {
      this.overflowTooltip = this.isSelectItemTextCut(optionText) ? optionText : null;
    },
    getFomattedText(optionText) {
      return this.isSelectItemTextCut(optionText) ? `${optionText.substring(0, 15)}...` : optionText;
    },
    isSelectItemTextCut(optionText) {
      return optionText.length > 15;
    },
  },
};
</script>

<style lang="scss" scoped>
.workload_plan_postes-select {
  &__select {
    margin-top: 8px;
  }

  &__options {
    &__text {
      font-size: $fs-text-s;
    }
  }

  &__option {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
</style>
