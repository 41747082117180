<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4839 7.93701V13.414L21.3999 14.619C21.4691 14.6342 21.5407 14.6342 21.6099 14.619L29.8479 9.06501C29.8419 8.79546 29.7433 8.5362 29.5686 8.33078C29.394 8.12537 29.154 7.98632 28.8889 7.93701H19.4839Z"
      fill="#0072C6"
    />
    <path
      d="M19.4839 15.457L21.2309 16.657C21.3127 16.7068 21.4066 16.7332 21.5024 16.7332C21.5982 16.7332 21.6921 16.7068 21.7739 16.657C21.4739 16.838 29.8469 11.279 29.8469 11.279V21.345C29.8687 21.549 29.8458 21.7552 29.7795 21.9494C29.7133 22.1435 29.6055 22.3209 29.4635 22.469C29.3216 22.6171 29.149 22.7324 28.9579 22.8069C28.7667 22.8813 28.5616 22.9131 28.3569 22.9H19.4829L19.4839 15.457Z"
      fill="#0072C6"
    />
    <path
      d="M10.44 12.932C10.1498 12.9306 9.86454 13.0078 9.61455 13.1553C9.36456 13.3028 9.15914 13.5152 9.02005 13.77C8.64017 14.4454 8.45789 15.214 8.49405 15.988C8.45457 16.7604 8.6371 17.528 9.02005 18.2C9.15856 18.4443 9.35892 18.6478 9.60101 18.7902C9.8431 18.9325 10.1184 19.0086 10.3992 19.0108C10.68 19.013 10.9565 18.9413 11.2008 18.8029C11.4451 18.6644 11.6487 18.4641 11.7911 18.222C12.1698 17.5531 12.3486 16.7895 12.3061 16.022C12.3494 15.2304 12.1766 14.4419 11.8061 13.741C11.6736 13.4945 11.4764 13.2889 11.2357 13.1463C10.9949 13.0038 10.7198 12.9296 10.44 12.932Z"
      fill="#0072C6"
    />
    <path
      d="M2.15308 5.155V26.582L18.4531 30V2L2.15308 5.155ZM13.0611 19.491C12.7562 19.9209 12.3507 20.2696 11.8801 20.5068C11.4095 20.744 10.888 20.8626 10.3611 20.852C9.84742 20.8611 9.33916 20.7461 8.87953 20.5166C8.41989 20.2871 8.02247 19.95 7.72108 19.534C7.00626 18.536 6.64868 17.3262 6.70608 16.1C6.64527 14.8136 7.00931 13.543 7.74208 12.484C8.05085 12.0459 8.46265 11.6906 8.94118 11.4492C9.4197 11.2078 9.95026 11.0879 10.4861 11.1C10.996 11.0898 11.5007 11.2049 11.9557 11.4352C12.4108 11.6655 12.8024 12.004 13.0961 12.421C13.8037 13.4406 14.1552 14.6653 14.0961 15.905C14.1584 17.182 13.7943 18.4436 13.0611 19.491Z"
      fill="#0072C6"
    />
  </svg>
</template>

<script>
export default {
  name: 'OutlookIcon',
  props: {
    height: {
      type: String,
      default: '40',
    },
    width: {
      type: String,
      default: '40',
    },
  },
};
</script>
