import { merge } from '@skello-utils/object';

const DOCS_ESIGNATURE_KEY = 'docsEsignatureSettings';

const getDocsEsignatureSettings = (newSettings = {}) => {
  const docsEsignatureSettings = {
    isAlreadyUsed: false,
  };

  return merge(docsEsignatureSettings, newSettings);
};

export const getDocsEsignatureSettingsFromLocalStorage = () => {
  const docsEsignatureSettings = localStorage.getItem(DOCS_ESIGNATURE_KEY);

  if (docsEsignatureSettings) {
    return merge(getDocsEsignatureSettings(), JSON.parse(docsEsignatureSettings));
  }

  return getDocsEsignatureSettings();
};

export const saveDocsEsignatureSettingsToLocalStorage = settings => {
  localStorage.setItem(DOCS_ESIGNATURE_KEY, JSON.stringify(settings));
};
