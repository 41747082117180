<template>
  <div class="header-day__wrapper">
    <div
      class="header-day__name"
    >
      <span class="header-day__name-weekday">{{ weekDay }}</span>
      <span
        class="header-day__name-number"
        :style="zoomRange.headerDayName"
      >
        {{ weekDayNumber }}
      </span>
      <span>{{ month }}</span>
    </div>
    <div
      class="header-day__footer"
      :style="zoomRange.headerDayFooter"
    >
      <div class="header-day__footer-left">
        <div
          class="header-day__footer-note"
          :style="zoomRange.headerDayIcon"
        >
          <ClipboardIcon
            v-tooltip.bottom.offset="noteTooltip"
            fill=""
            class="header-day__footer-icon"
            height="100%"
            width="100%"
          />
        </div>
      </div>
      <div
        class="header-day__footer-right"
        :style="zoomRange.headerDayIcon.height"
      >
        <div
          class="header-day__footer-lock"
          :style="zoomRange.headerDayIcon"
        >
          <OpenLockIcon
            v-tooltip.bottom.offset="openLockIconTooltip"
            v-track="'lock_day_week'"
            fill=""
            class="header-day__footer-icon"
            height="100%"
            width="100%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

import skDate from '@skello-utils/dates';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'SkeletonDay',
  props: {
    day: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shakeLockIcon: false,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('features', ['isFeatureEnabled']),
    weekDay() {
      const day = this.dayDate.locale('en').format('dddd').toLowerCase();
      return this.$t(`plannings.table.header.short_day.${day}`);
    },
    weekDayNumber() {
      return this.dayDate.format('D');
    },
    month() {
      const month = this.dayDate.locale('en').format('MMMM').toLowerCase();
      return this.$t(`plannings.table.header.short_month.${month}`);
    },
    zoomRange() {
      const { headerDay, headerDayFooter } = zoomPlanningWeek(this.planningZoom);
      return {
        headerDayName: headerDay.name,
        headerDayIcon: headerDay.icon,
        headerDayFooter,
      };
    },
    dayDate() {
      return skDate(this.day.date);
    },
    noteTooltip() {
      if (this.day.event?.attributes.note) {
        return this.day.event.attributes.note;
      }
      if (this.currentLicense.attributes.canCreateShifts) {
        return this.$t('plannings.table.add_note_modal.header.add_note_title');
      }
      return this.$t('plannings.table.add_note_modal.header.unauthorized');
    },
    openLockIconTooltip() {
      if (this.currentLicense.attributes.canTmplockPlanning) {
        return this.$t('plannings.table.header.actions.day_validation.validate.label');
      }
      return this.$t('plannings.table.header.actions.day_validation.validate.unauthorized');
    },
  },
  methods: {
    handleClick() {
      if (this.isFeatureEnabled(FEATURES.FEATURE_PLANNING, this.currentShop.id, () => this.checkPackOfferFlag('day_planning_enabled'))) {
        this.$router.push({
          name: 'plannings_days',
          query: {
            date: this.day.date,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .header-day__wrapper {
    border-right: 1px solid $sk-grey-10;
    border-bottom: 1px solid $sk-grey-10;
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .header-day__wrapper:last-child {
    border-right: none;
  }

  .header-day__name {
    font-size: 1vw;
    line-height: 22px;
    color: $sk-grey;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex: 1;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

    &--today {
      color: $sk-blue;

      .header-day__name-number {
        font-weight: $fw-semi-bold;
        background: $sk-blue;
        color: $sk-white;
      }
    }
  }

  .header-day__name-number {
    font-size: 1.25vw;
    width: 2.43vw;
    height: 2.43vw;
    margin: 0 3px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
  }

  .header-day__footer {
    display: flex;
    align-items: center;
    height: 24px;

    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .header-day__footer-note,
  .header-day__footer-lock {
    display: flex;
    align-items: flex-end;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    padding: 2px;
    // opacity impact z-index, since we cannot
    // manualy change z-index since it impacts
    // total column, we apply a < 1 opacity on lock
    // https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
    opacity: .99;
  }

  .header-day__name-weekday {
    text-transform: capitalize;
  }

  @media screen and (min-width: 1440px) {
    .header-day__name {
      font-size: 1.6em;
    }

    .header-day__name-number {
      font-size: 1.1em;
      width: 31px;
      height: 31px;
    }
  }

  .header-day__footer-icon {
    fill: $sk-grey-10;
  }
</style>
