import {
  getDocument,
  PermissionFlag,
} from 'pdfjs-dist/legacy/build/pdf';

const openFile = ({ blob, fileName, type = 'application/vnd.ms-excel', format = 'xlsx' }) => {
  const url = URL.createObjectURL(new Blob([blob], {
    type,
  }));
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.download = `${fileName}.${format}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFile = (url, fileName) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  fetch(url)
    .then(response => response.blob())
    .then(myBlob => {
      a.href = window.URL.createObjectURL(myBlob);
      a.setAttribute('download', fileName);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    });
};

const sanitizeFileName = (str, length) => {
  str = str.replace(/[^0-9A-Za-z-]/gim, '_');
  return str.trim().substring(0, length);
};

/**
 *
 * @param {Uint8Array} pdfBuffer
 * @returns number
 */
const getNumberOfPages = async pdfBuffer => getDocument({ data: pdfBuffer })
  .promise
  .then(pdfDocument => pdfDocument.numPages);

const isFileEncrypted = async pdfBuffer => {
  try {
    const pdfPromise = getDocument({ data: pdfBuffer });
    const pdf = await pdfPromise.promise;

    const permissions = await pdf.getPermissions();
    return !permissions.includes(PermissionFlag.COPY);
  } catch (error) {
    if (error.name === 'PasswordException') {
      return true;
    }
    return false;
  }
};

export {
  downloadFile,
  sanitizeFileName,
  openFile,
  getNumberOfPages,
  isFileEncrypted,
};
