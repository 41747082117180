<template>
  <SkPopover
    class="availabilities-corner__wrapper"
    :placement="isLastVisibleDay ? 'right' : 'top'"
    as-tooltip
  >
    <template #anchor>
      <AvailabilityCornerIcon
        class="availabilities-corner__icon"
        :fill-top="availabilitiesFillTop"
        :fill-bottom="availabilitiesFillBottom"
      />
    </template>
    <template #content>
      <div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-for="availability in availabilities"
          :key="availability.id"
          v-html="availabilityTooltip(availability)"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </template>
  </SkPopover>
</template>

<script>
export default {
  name: 'AvailabilitiesCorner',
  props: {
    availabilities: {
      type: Array,
      required: true,
    },
    isLastVisibleDay: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    availabilitiesFillTop() {
      // For top fill : if there is an unavailability -> red, otherwise green
      return this.availabilities.some(({ attributes }) => attributes.status === 'unavailable') ?
        '#d03e50' :
        '#188377';
    },
    availabilitiesFillBottom() {
      // For bottom fill : if there is an availability -> green, otherwise red
      return this.availabilities.some(({ attributes }) => attributes.status === 'available') ?
        '#188377' :
        '#d03e50';
    },
  },
  methods: {
    isAvailableType(availability) {
      return availability.attributes.status === 'available';
    },
    availabilityTooltip(availability) {
      const { startTime, endTime } = availability.attributes;
      return this.$tc(
        'plannings.table.cells.availabilities.tooltip',
        this.isAvailableType(availability),
        { startTime, endTime },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.availabilities-corner__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  height: 14px;
  aspect-ratio: 1;

  ::v-deep .sk-popover--tooltip {
    padding: 4px 8px;
    font-size: $fs-text-s;
    width: auto;
    white-space: nowrap;
    text-align: center;
  }
}

.availabilities-corner__icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 12;
}
</style>
