<template>
  <div
    v-if="!saasReportDataLoading && !isAnnualizationLoading"
    class="hr-admin-table__wrapper"
  >
    <NameColumn :employees-infos="employeesInfos" />

    <div class="hr-admin-table__data-columns">
      <ContractDataColumn
        :contract-types="allContractTypes"
        :current-shop="currentShop"
        :employees-infos="employeesInfos"
        :visible-salary="saasReportInfos.visible_salary"
      />

      <SalaryDataColumn
        :current-license-position="saasReportInfos.current_license_position"
        :current-user="currentUser"
        :currency-symbol="saasReportInfos.currency_symbol"
        :employees-infos="employeesInfos"
        :show-structural-over-hours="isFrenchShop"
        :visible-salary="saasReportInfos.visible_salary"
      />

      <AnnualizationColumn
        v-if="!isAnnualizationLoading && isAnnualizationActive"
        :employees-infos="employeesInfos"
      />

      <PlanningHoursColumn
        :can-lock-planning="saasReportInfos.can_lock_panning"
        :employees-infos="employeesInfos"
        :hours-counter-enabled="saasReportInfos.hours_counter_enabled"
        :is-variable-contract-hours-available="saasReportInfos.is_variable_contract_hours_available"
        :modulation="saasReportInfos.modulation"
        :modulation-majoration="saasReportInfos.modulation_majoration"
        :previous-period-locked="saasReportInfos.previous_period_locked"
        :weeks-datas="weeksDatas"
      />

      <OverHoursColumn
        v-if="isOverHoursColumnDisplayed"
        :complementary-hours-columns="saasReportInfos.complementary_hours_columns"
        :convention-only-has-one-chms="saasReportInfos.convention_only_has_one_chms"
        :cut-length="saasReportInfos.cut_length"
        :employees-infos="employeesInfos"
        :first-may-maj="saasReportInfos.first_may_maj"
        :flexible-convention="saasReportInfos.flexible_convention"
        :holiday-maj="saasReportInfos.holiday_maj"
        :holidays-counter-configured="saasReportInfos.holidays_counter_configured"
        :modulation="saasReportInfos.modulation"
        :modulation-majoration="saasReportInfos.modulation_majoration"
        :night-hours-columns="saasReportInfos.night_hours_columns"
        :over-hours-columns="saasReportInfos.over_hours_columns"
        :structural-over-hours-columns="saasReportInfos.structural_over_hours_columns"
        :show-cuts="saasReportInfos.show_cuts"
        :show-first-may="saasReportInfos.show_first_may"
        :show-pentecost="saasReportInfos.show_pentecost"
        :show-structural-over-hours="isFrenchShop"
        :sunday-maj="saasReportInfos.sunday_maj"
      />

      <AbsencesColumn
        :active-absences="saasReportInfos.active_absences"
        :current-shop="currentShop"
        :employees-infos="employeesInfos"
      />

      <PayElementsColumn
        :benefit-in-kind="saasReportInfos.benefit_in_kind"
        :can-edit-bonus="saasReportInfos.can_edit_bonus"
        :can-see-prime="saasReportInfos.can_see_prime"
        :currency-symbol="saasReportInfos.currency_symbol"
        :employees-infos="employeesInfos"
        :meal-compensation-done="saasReportInfos.meal_compensation_done"
        :meals-title="saasReportInfos.meals_title"
        :primes-enabled="saasReportInfos.primes_enabled"
        :shop-id="Number(currentShop.id)"
        :show-owed-meals="saasReportInfos.show_owed_meals"
        :start-month="startMonth"
        :visible-salary="saasReportInfos.visible_salary"
        @display-primes-modal="handleDisplayPrimesModal"
      />

      <CommentaryColumn
        v-if="isCommentaryColumnDisplayed"
        :can-edit-report-comment="saasReportInfos.can_edit_report_comment"
        :employees-infos="employeesInfos"
        :end-date="saasReportInfos.end_date"
        :shop-id="Number(currentShop.id)"
        :start-date="saasReportInfos.start_date"
        @display-comment-modal="handleDisplayCommentModal"
      />
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <UnemploymentReportModal
        :cluster-node="shopClusterNode"
        :employees-infos="employeesInfos"
        @send-unemployment-report="handleExport('send-unemployment-report', $event)"
      />
      <PrimesModal
        v-if="displayPrimesModal"
        :employee-id="modalEmployeeId"
        :contract-id="modalContractId"
        :shop-id="Number(currentShop.id)"
        :start-date="saasReportInfos.start_date"
        :end-date="saasReportInfos.end_date"
        :currency-symbol="saasReportInfos.currency_symbol"
      />
      <CommentModal
        v-if="displayCommentsModal"
        :employee-id="modalEmployeeId"
        :contract-id="modalContractId"
        :shop-id="Number(currentShop.id)"
        :start-date="saasReportInfos.start_date"
        :end-date="saasReportInfos.end_date"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import CommentModal from '@app-js/reports/shared/components/CommentModal.vue';
import PrimesModal from '@app-js/reports/shared/components/PrimesModal.vue';
import UnemploymentReportModal from '@app-js/reports/shared/components/UnemploymentReportModal.vue';

import AbsencesColumn from './components/AbsencesColumn';
import AnnualizationColumn from './components/AnnualizationColumn';
import CommentaryColumn from './components/CommentaryColumn';
import ContractDataColumn from './components/ContractDataColumn/index.vue';
import NameColumn from './components/NameColumn/index.vue';
import OverHoursColumn from './components/OverHoursColumn';
import PayElementsColumn from './components/PayElementsColumn';
import PlanningHoursColumn from './components/PlanningHoursColumn';
import SalaryDataColumn from './components/SalaryDataColumn';

export default {
  name: 'ShopReports',
  components: {
    AbsencesColumn,
    AnnualizationColumn,
    CommentaryColumn,
    ContractDataColumn,
    NameColumn,
    OverHoursColumn,
    PayElementsColumn,
    PlanningHoursColumn,
    SalaryDataColumn,
    PrimesModal,
    CommentModal,
    UnemploymentReportModal,
  },
  props: {
    employeesInfos: {
      type: [Array, Object],
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      modalEmployeeId: null,
      modalContractId: null,
    };
  },
  computed: {
    ...mapGetters('annualization', [
      'isAnnualizationLoading',
      'isAnnualizationCurrentlyActive',
    ]),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable', 'isDevFlagEnabled']),
    ...mapGetters('config', ['allContractTypes']),
    ...mapGetters('planningsState', [
      'getWeeklyOptionsForAWeek',
      'isWeekIntermediaryLocked',
      'isGreenLockActivated',
    ]),
    ...mapState('config', ['config']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('report', ['saasReportInfos', 'saasReportDataLoading']),
    ...mapState('planningsState', ['monthlyOptions']),
    shopClusterNode() {
      return {
        id: this.currentShop.relationships.clusterNode.attributes.id,
        attributes: {
          shopId: this.currentShop.id,
        },
      };
    },
    startMonth() {
      return skDate.utc(this.saasReportInfos.start_date).format('MM/YYYY');
    },
    isAnnualizationActive() {
      return (
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.isAnnualizationCurrentlyActive
      );
    },
    isOverHoursColumnDisplayed() {
      return this.isFrenchShop ||
        !this.saasReportInfos.modulation ||
        this.saasReportInfos.modulation_majoration ||
        (
          this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER') &&
          this.saasReportInfos.hybrid_modulation
        );
    },
    isCommentaryColumnDisplayed() {
      return this.saasReportInfos.show_report_comments &&
        this.saasReportInfos.report_comments_enabled;
    },
    isFrenchShop() {
      return this.config.dom_tom.includes(this.currentShop.attributes.country.toLowerCase());
    },
    displayPrimesModal() {
      return this.saasReportInfos.primes_enabled &&
        this.saasReportInfos.can_see_prime &&
        this.saasReportInfos.can_edit_bonus;
    },
    displayCommentsModal() {
      return this.saasReportInfos.can_edit_report_comment;
    },
    weeksDatas() {
      if (!this.isGreenLockActivated) return this.saasReportInfos.weeks_datas;

      return this.saasReportInfos.weeks_datas?.map(weekData => {
        const weeklyOption = this.getWeeklyOptionsForAWeek(weekData.monday);

        if (weeklyOption) {
          weekData.check_week_intermediary_lock = this.isWeekIntermediaryLocked(weeklyOption);
        }
        return weekData;
      });
    },
  },
  methods: {
    handleExport(exportEvent, type) {
      this.$emit(exportEvent, { type, nodes: this.shopClusterNode });
    },
    handleDisplayPrimesModal(props) {
      this.modalEmployeeId = props.employeeId;
      this.modalContractId = props.contractId;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'primes-modal');
    },
    handleDisplayCommentModal(props) {
      this.modalEmployeeId = props.employeeId;
      this.modalContractId = props.contractId;
      this.$nextTick(() => {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'comment-modal');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-admin-table__wrapper {
  display: flex;

  .name-column-wrapper {
    flex-shrink: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: white;
  }

  .hr-admin-table__data-columns {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 8px 16px 8px 0;
    height: 100%;
  }

  ::v-deep .hr-admin-table__column-wrapper {
    padding-left: 8px;
  }

  ::v-deep .column-wrap {
    border: 1px solid #eee;
    border-radius: 3px;
  }

  ::v-deep .column__cell {
    height: 61px;
    border-top: 1px solid #eee;
  }

  ::v-deep .column__title {
    text-align: center;

    &--closed {
      position: relative;
      height: 74px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
    }

    &--open {
      font-size: $fs-text-s;
      padding: 16px 5px;
      min-width: 125px;
    }
  }

  ::v-deep .column__header {
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    padding-top: 12px;
  }

  ::v-deep .column__cell {
    font-size: .9em;
    text-align: center;
    vertical-align: middle;
    position: relative;
  }

  $close-btn-color: #ccc;

  ::v-deep .column__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  ::v-deep .report-icon {
    color: #e1e1e1;
    border: 2px solid #e1e1e1;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 13px;
    transition: all .25s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 2px 2px 5px #d1d7e8;
      color: #d1d7e8;
    }
  }
}
</style>
