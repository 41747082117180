<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M11.4456 0.132812H7.93218C7.62599 0.132812 7.37782 0.380983 7.37782 0.687176C7.37782 0.99337 7.62599 1.24154 7.93218 1.24154H10.1073L4.79153 6.55727C4.57502 6.77378 4.57502 7.12475 4.79153 7.34126C4.89982 7.44949 5.04167 7.50363 5.18353 7.50363C5.32538 7.50363 5.46724 7.44949 5.57552 7.34126L10.8912 2.02553V4.20067C10.8912 4.50686 11.1394 4.75503 11.4456 4.75503C11.7518 4.75503 12 4.50686 12 4.20067V0.687238C12 0.380983 11.7518 0.132812 11.4456 0.132812Z"
    />
    <path
      d="M9.1778 4.75482C8.8716 4.75482 8.62343 5.00299 8.62343 5.30918V10.7577H1.10873V3.243H6.88507C7.19127 3.243 7.43944 2.99483 7.43944 2.68864C7.43944 2.38245 7.19127 2.13428 6.88507 2.13428H0.554364C0.24817 2.13428 0 2.38245 0 2.68864V11.3121C0 11.6183 0.24817 11.8664 0.554364 11.8664H9.1778C9.48399 11.8664 9.73216 11.6183 9.73216 11.3121V5.30918C9.73216 5.00299 9.48399 4.75482 9.1778 4.75482Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#00bedd',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 12px; // ie11 support
  width: initial;
  height: 12px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 12px; // edge support
    height: 12px; // edge support
  }
}
</style>
