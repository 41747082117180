<template>
  <div id="shop-settings__sidebar">
    <div
      v-if="currentShop"
      class="sidebar__menu"
    >
      <router-link
        v-if="canManageShopRulesAndAbsences"
        :to="{ name: 'shop_settings_rules' }"
      >
        <ClipboardIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.rules.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessAlertsPage"
        data-test="bundle-system"
        :to="{ name: 'shop_settings_alerts' }"
      >
        <CircledExclamationMarkIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.alerts.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessReportRulesPage"
        data-test="bundle-system"
        :to="{ name: 'shop_settings_report_rules' }"
      >
        <ClipboardIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.report_rules.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessPositionsPage"
        data-test="bundle-system"
        :to="{ name: 'shop_settings_postes' }"
      >
        <TagV2Icon
          width="20"
          height="20"
          fill=""
        />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.shop_postes.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessShopSettingsTab"
        :to="{ name: 'shop_settings_teams' }"
      >
        <TeamV2Icon
          width="20"
          height="20"
          fill=""
        />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.teams.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessAbsencesTab"
        :to="{ name: 'shop_settings_absences' }"
      >
        <ParasolIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.leave.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canEditShopBillingData"
        :to="{ name: 'shop_settings_shop' }"
      >
        <ShopIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ $t(`shop_settings.tabs.shop.title`) }}
        </span>
      </router-link>
      <router-link
        v-if="canAccessAdminDocumentTemplatesPage"
        data-test="bundle-system"
        :to="{ name: 'shop_settings_documents_management' }"
      >
        <FileIcon fill="" />
        <span class="sidebar__menu__item__label">
          {{ documentManagementTitle }}
        </span>
      </router-link>
      <router-link
        v-if="canEditAutomaticPlanningRules"
        :to="{ name: 'shop_settings_automatic_planning' }"
      >
        <MagicWandIcon
          fill=""
          height="19"
          width="19"
        />
        <span class="sidebar__menu__item__label">
          {{ $t('shop_settings.tabs.automatic_planning.sidebar_link') }}
        </span>
      </router-link>
      <router-link
        v-if="isClockRulesVisible"
        :to="{ name: 'shop_settings_punch_clock' }"
      >
        <PunchClockV2Icon
          width="20"
          height="20"
          fill=""
        />
        <span class="sidebar__menu__item__label">
          {{ $t('shop_settings.tabs.punch_clock.title') }}
        </span>
      </router-link>
    </div>
    <router-link
      v-if="canAccessGeneralSettingsPage"
      :to="{ name: 'organisation_settings' }"
      class="sidebar__org-settings sidebar__menu"
    >
      <CogIcon
        fill=""
        height="16"
        width="16"
      />
      <span class="sidebar__org-settings__label">
        {{ $t('shop_settings.tabs.organisation_settings.title') }}
        <p class="sk-subtitle--small">{{ currentOrganisation.attributes.name }}</p>
      </span>
    </router-link>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';

import MagicWandIcon from '../../svg/MagicWandIcon';

export default {
  name: 'ShopSettingsSidebar',
  components: { MagicWandIcon },
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentOrganisation', ['limitedDocumentTemplates', 'isHrisActive']),
    ...mapGetters('currentShop', [
      'sector',
      'isVariableContractHoursAvailable',
      'isAnnualizedWorkingTimeAvailable',
      'isClockRulesVisible',
    ]),
    ...mapGetters('currentLicense', [
      'canAccessShopSettingsTab',
      'canManageShopRulesAndAbsences',
      'canEditShopBillingData',
      'canEditLegalAlertsConfig',
      'canEditPunchClockSettings',
      'canManageDocumentTemplates',
    ]),
    ...mapGetters({ currentUserIsSystemAdmin: 'currentLicense/isSystemAdmin' }),
    ...mapGetters('foldersManagement', ['isFoldersManagementEnabled']),
    flexibleConvention() {
      return this.currentShop.relationships &&
        this.currentShop.relationships.convention &&
        this.currentShop.relationships.convention.attributes.flexible;
    },
    canEditAutomaticPlanningRules() {
      return this.canAccessShopSettingsTab &&
        this.currentShop.attributes.canAccessAutomaticPlanning &&
        this.isFeatureEnabled(FEATURES.FEATURE_SMART_PLANNER, this.currentShop.id, () => true);
    },
    canAccessGeneralSettingsPage() {
      return this.currentUserIsSystemAdmin ||
        this.currentLicense.attributes.canEditShopPermissions ||
        this.currentLicense.attributes.canCreateShop;
    },
    canAccessAlertsPage() {
      return this.canAccessShopSettingsTab &&
        this.isFeatureEnabled(FEATURES.FEATURE_ALERTS, this.currentShop.id, () => true);
    },
    canAccessReportRulesPage() {
      return this.canEditLegalAlertsConfig &&
        this.flexibleConvention &&
        this.isFeatureEnabled(FEATURES.FEATURE_REPORT, this.currentShop.id, () => true);
    },
    canAccessPositionsPage() {
      return this.canAccessShopSettingsTab &&
        this.isFeatureEnabled(FEATURES.FEATURE_EMPLOYEE_POSITIONS, this.currentShop.id, () => true);
    },
    canAccessAbsencesTab() {
      return this.canManageShopRulesAndAbsences &&
        this.isFeatureEnabled(FEATURES.FEATURE_ABSENCES, this.currentShop.id, () => true);
    },
    canAccessAdminDocumentTemplatesPage() {
      return this.isHrisActive &&
        this.canManageDocumentTemplates &&
        this.isFeatureEnabled(
          FEATURES.FEATURE_ADMIN_DOCUMENTS_TEMPLATES,
          this.currentShop.id,
          () => !this.limitedDocumentTemplates,
        );
    },
    documentManagementTitle() {
      return this.isFoldersManagementEnabled ?
        this.$t('shop_settings.documents_management.title') :
        this.$t('shop_settings.text_document_templates.title');
    },
  },
};
</script>

<style lang="scss" scoped>
#shop-settings__sidebar {
  overflow: auto;
  width: 300px;
  padding: 24px 10px 0 30px;

  .sidebar__menu {
    border-radius: 4px;
    background: $sk-grey-5;
    padding: 20px 20px 10px;
  }

  a {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;

    &:hover {
      background: $sk-grey-10;
      border-radius: 4px;
      text-decoration: none;
    }

    span.sidebar__menu__item__label {
      color: $sk-black;
      padding-left: 10px;
    }

    &.router-link-active {
      background: $sk-blue-10;
      border-radius: 4px;

      .sidebar__menu__item__label {
        color: $sk-blue;
      }

      &:hover {
        background: $sk-blue-5;
      }

      svg {
        fill: $sk-blue-50;
      }
    }

    svg {
      fill: $sk-black;
      min-width: 20px;
    }
  }

  .sidebar__org-settings {
    margin-top: 20px;
    padding: 35px 15px 35px 30px;
    display: flex;
    justify-content: left;
    font-weight: bold;
    color: $sk-black;

    .sidebar__org-settings__label {
      margin-left: 10px;
    }

    &:hover {
      background: $sk-grey-10;
      text-decoration: none;
    }
  }
}
</style>
