<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    :id="id"
    ref="editPaidLeavesCounterModal"
    :modal-title="$t('employees.tabs.counters.paid_leaves_counter.edit_modal.title')"
    :submit-disabled="manualChangesInputError"
    :tracking-options="trackingOptions"
    modal-title-classes="edit-paid-leaves-counter-modal__title"
    size="inherited"
    :testing-options="{ submit: 'edit-paid-leaves-counter-modal__submit-btn' }"
    @submit="handleSubmit"
    @cancel="handleCancel"
  >
    <template #body>
      <SkModalSection>
        <div class="edit-paid-leaves-counter-modal__form__input">
          <div class="edit-paid-leaves-counter-modal__form__input-label">
            {{ $t('employees.tabs.counters.paid_leaves_counter.edit_modal.counter_at') }}
            <div class="edit-paid-leaves-counter-modal__form__input-label__date">
              {{ $t('employees.tabs.counters.paid_leaves_counter.edit_modal.end_of') }} {{ dateLabel(date) }}
            </div>
          </div>
          <div> {{ oldCounterRounded }} </div>
        </div>
        <div class="edit-paid-leaves-counter-modal__form__input">
          <div
            v-tooltip="$t('employees.tabs.counters.paid_leaves_counter.edit_modal.changes_tooltip')"
            class="edit-paid-leaves-counter-modal__form__input-label"
          >
            {{ $t('employees.tabs.counters.paid_leaves_counter.edit_modal.days_to_add_or_remove') }}
          </div>
          <form
            class="edit-paid-leaves-counter-modal__form__input-right"
            @submit.prevent="handleSubmit"
          >
            <SkInput
              v-model="manualChanges"
              :errored="manualChangesInputError"
              :error-message="$t('employees.tabs.counters.paid_leaves_counter.edit_modal.error_message')"
              max-length="6"
              center
              autofocus
              error-message-align-right
              type="number"
              step="1"
            />
          </form>
        </div>
      </SkModalSection>

      <SkModalSection
        :padding-bottom="false"
        border-bottom="none"
      >
        <div class="edit-paid-leaves-counter-modal__form__input">
          <div class="edit-paid-leaves-counter-modal__form__input-label">
            {{ $t('employees.tabs.counters.paid_leaves_counter.edit_modal.new_counter_at') }}
            <div class="edit-paid-leaves-counter-modal__form__input-label__date">
              {{ $t('employees.tabs.counters.paid_leaves_counter.edit_modal.end_of') }} {{ dateLabel(date) }}
            </div>
          </div>
          <div class="edit-paid-leaves-counter-modal__form__new-counter-right">
            {{ newCounter }}
          </div>
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { captureException } from '@sentry/vue';
import { isFloatWithPrependSign } from '@skello-utils/validators';
import { roundFloat } from '@skello-utils/formatting/numbers';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';

export default {
  name: 'EditPaidLeavesCounterModal',
  props: {
    oldCounter: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      manualChanges: 0,
    };
  },

  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('selectedEmployee', ['initials']),

    oldCounterRounded() {
      return roundFloat(this.oldCounter, 2);
    },

    newCounter() {
      if (!this.manualChanges) return this.oldCounterRounded;

      return roundFloat(this.oldCounter + this.manualChanges, 2);
    },

    manualChangesInputError() {
      return !isFloatWithPrependSign(this.manualChanges);
    },
    trackingOptions() {
      return {
        cancel: 'manual_change_plc_counter_cancel',
        submit: 'manual_change_plc_counter_validate',
      };
    },
  },

  methods: {
    ...mapActions('employeePaidLeavesCounter', [
      'updateEmployeePaidLeavesCounter',
      'fetchEmployeePaidLeavesCounter',
      'fetchEmployeePeriodSummaries',
    ]),

    dateLabel(date) {
      return skDate(date, 'DD-MM-YYYY').format('MMMM YYYY');
    },

    // Paid leaves are computed from june to may.
    // Default query must be made on previous year if current date is before june
    formattedFetchDate(date) {
      return skDate(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
    },

    handleCancel() {
      this.manualChanges = 0;
    },

    handleSubmit(event) {
      if (!this.manualChanges) return;

      this
        .updateEmployeePaidLeavesCounter({
          user_id: this.employee.id,
          date: this.date,
          manual_changes: this.manualChanges,
          silentLoading: true,
        })
        .then(() => {
          skDate.locale(this.currentUser.lang);

          try {
            this.$svcEvents.create(
              EVENT_SUBTYPE_ACTION.EMPLOYEE_PTO_TRACKER_UPDATE, {
                date: skDate(this.date, 'DD-MM-YYYY').unix() * 1000,
                employee: this.employee,
                values: [this.oldCounter, this.manualChanges],
              },
            );
          } catch (error) {
            captureException(error);
          }

          this.$refs.editPaidLeavesCounterModal.hide();

          this.$skToast({
            message: this.$t('employees.tabs.counters.paid_leaves_counter.edit_modal.toast.success'),
            variant: 'success',
            containerId: 'employees__container',
          });

          this.fetchEmployeePaidLeavesCounter({
            user_id: this.employee.id,
            date: this.formattedFetchDate(this.date),
            silentLoading: true,
          });

          this.fetchEmployeePeriodSummaries({
            user_id: this.employee.id,
            date: this.formattedFetchDate(this.date),
          });

          this.manualChanges = 0;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
  },
};
</script>

<!-- unscoped style to override sk-input__error-label class -->
<style lang="scss">
.edit-paid-leaves-counter-modal__title {
  max-width: 500px;
}

.edit-paid-leaves-counter-modal__form__input {
  width: 620px;
  background: white;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-top: 20px;

  &:first-child {
    padding-right: 25px;
    padding-top: 0;
  }

  .sk-input__error-label {
    position: absolute;
    top: 44px;
    left: 0;
    font-size: $fs-text-s;
    color: $sk-error;
  }
}

.edit-paid-leaves-counter-modal__form__input-label {
  width: 240px;
  font-weight: $fw-semi-bold;
  background: white;
}

.edit-paid-leaves-counter-modal__form__input-label__date {
  font-weight: $fw-regular;
}

.edit-paid-leaves-counter-modal__form__new-counter-right {
  color: $sk-blue-50;
  font-weight: $fw-semi-bold;
}

.edit-paid-leaves-counter-modal__form__input-right {
  width: 60px;
}
</style>
