<template>
  <div class="workload-plan-legend">
    <div
      v-for="(legend, index) in legends"
      :key="index"
      :class="legendItemClasses(index)"
    >
      <div
        v-if="isLoading"
        class="workload-plan-legend--loading"
      />
      <div
        v-else
        class="workload-plan-legend--active"
      >
        <div
          :class="legendIconClasses(legend)"
          :style="iconStyle(legend)"
        />
        <span
          :id="`workload_plan_legend_text-${index}`"
          v-tooltip.bottom-end="labelTooltipText(legend)"
          :class="legendLabelClasses(legend)"
        >
          {{ legend.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WorkloadPlanLegend',
  props: {
    posteId: {
      type: String,
      required: false,
      default: null,
    },
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      overflowTooltip: null,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['isAnyFilterActiveOnPlanning']),

    legends() {
      return [
        {
          chartType: 'line',
          color: '#2b66fe',
          text: this.$t('workload_plan.legend.needs'),
        },
        {
          chartType: 'bars',
          color: '#D9E6FF',
          text: this.displayedPoste,
        },
      ];
    },
    appliedFilterWarningTooltip() {
      return this.$t('workload_plan.legend.tooltip.applied_filter_warning');
    },
    displayedPoste() {
      let displayedMessage;

      if (!this.posteId ||
        this.posteId === 'all' ||
        !this.postes?.find(poste => poste.id === this.posteId)) {
        displayedMessage =
          `${this.$t('workload_plan.legend.staff')} ${this.$t('workload_plan.legend.planned')}`;
      } else {
        const posteName = this.postes.find(poste => poste.id === this.posteId).attributes.name;
        displayedMessage = `${posteName.toLowerCase()} ${this.$t('workload_plan.legend.planned')}`;
      }

      this.setTooltip(displayedMessage);
      return displayedMessage;
    },
  },
  methods: {
    legendItemClasses(index) {
      return {
        'workload-plan-legend__item': true,
        'workload-plan-legend__item__top-spacing': index !== 0,
      };
    },
    legendIconClasses(legend) {
      return {
        'workload-plan-legend__item__icon': true,
        'workload-plan-legend__item__icon-line': legend.chartType === 'line',
        'workload-plan-legend__item__icon-square': legend.chartType === 'bars',
      };
    },
    legendLabelClasses(legend) {
      return {
        'workload-plan-legend__item__label': true,
        'workload-plan-legend__item__label__warning': this.isLabelOnWarning(legend),
      };
    },
    isLabelOnWarning(legend) {
      return legend.chartType === 'bars' && this.isAnyFilterActiveOnPlanning;
    },
    iconStyle(legend) {
      return {
        backgroundColor: legend.color,
      };
    },
    labelTooltipText(legend) {
      if (legend.chartType === 'line') return null;
      if (this.isAnyFilterActiveOnPlanning) return this.appliedFilterWarningTooltip;

      return this.overflowTooltip;
    },
    setTooltip(text) {
      const el = document.getElementById('workload_plan_legend_text-1');
      if (!el) return;

      this.$nextTick(() => {
        const isEllipsisActive = el.offsetHeight < el.scrollHeight;
        this.overflowTooltip = isEllipsisActive ? text : null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-legend {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 122px;
  max-height: 72px;
  margin: 0 12px 12px;
  padding: 8px 0 8px 8px;
  border: solid 1px $sk-grey-10;

  &--loading {
    background-color: $sk-grey-10;
    border-radius: 4px;
    width: 106px;
    height: 19px;
  }

  &--active {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;

    &__top-spacing {
      margin-top: 5px;
    }

    &__icon {
      margin-right: 8px;

      &-square {
        min-width: 16px;
        height: 16px;
        border-radius: 2px;
      }

      &-line {
        height: 3px;
        width: 16px;
        border-radius: 0;
      }
    }

    &__label {
      color: $sk-grey;
      font-size: $fs-text-s;
      margin-right: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
      cursor: default;

      &__warning {
        color: $sk-error;
      }
    }
  }
}
</style>
