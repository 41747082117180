<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="8"
    height="15"
    viewBox="0 0 8 15"
  >
    <circle
      cx="1.5"
      cy="1.5"
      r="1.5"
    />
    <circle
      cx="6.5"
      cy="1.5"
      r="1.5"
    />
    <circle
      cx="1.5"
      cy="7.5"
      r="1.5"
    />
    <circle
      cx="6.5"
      cy="7.5"
      r="1.5"
    />
    <circle
      cx="1.5"
      cy="13.5"
      r="1.5"
    />
    <circle
      cx="6.5"
      cy="13.5"
      r="1.5"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#ADB2B6',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 8px; // ie11 support
  width: initial;
  height: 15px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 8px; // EDGE support
    height: 15px; // EDGE support
  }
}
</style>
