<template>
  <div>
    <p>
      {{ $t('organisation_settings.tabs.pos_partners.link_shops', { name: 'L’Addition' }) }}
    </p>
    <div class="pos-card__table">
      <SkTable
        :columns="headers"
        :loading="shopsFetchLoading"
      >
        <LadditionShopRow
          v-for="shop in sortedOrganisationShops"
          :key="shop.id"
          :shop="shop"
          :matching-shops-list="ladditionMatchingShopsList"
          @laddition-link="onLadditionLink"
          @laddition-unlink="onLadditionUnlink"
        />
      </SkTable>
    </div>
    <div class="table__paginate__footer">
      <div
        v-if="pagination.total_pages > 1"
        class="paginate__navigation__wrapper"
      >
        <SkPaginate
          v-model="pagination.current_page"
          :total-pages="pagination.total_pages"
          :disabled="shopsFetchLoading"
          @new-page="fetchShops"
        />
      </div>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <LadditionAuthenticationModal ref="ladditionAuthenticationModal" />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState, mapActions,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { sortCollectionBy } from '@skello-utils/collection';

import LadditionAuthenticationModal from './LadditionAuthenticationModal';
import LadditionShopRow from './LadditionShopRow';

export default {
  name: 'LadditionCard',
  components: { LadditionShopRow, LadditionAuthenticationModal },
  data() {
    return {
      organisationShops: [],
      shopsFetchLoading: false,
      headers: [
        {
          title: this.$t('organisation_settings.tabs.pos_partners.shops_table.skello_title'),
          class: 'table-column__filled-section',
        },
        {
          title: null,
          class: 'table-column__empty-section',
        },
      ],
      pagination: {
        current_page: 1,
        per_page: 10,
        total_pages: 0,
      },
    };
  },
  computed: {
    ...mapState('partnersTools', ['ladditionMatchingShopsList']),

    sortedOrganisationShops() {
      return sortCollectionBy(this.organisationShops, 'name', 'toLowerCase');
    },
  },
  mounted() {
    this.fetchShops();
  },
  methods: {
    ...mapActions('partnersTools', ['unlinkLadditionShop']),

    fetchShops() {
      this.shopsFetchLoading = true;

      return httpClient
        .get(
          '/v3/api/shops', {
            params: {
              current_page: this.pagination.current_page,
              per_page: this.pagination.per_page,
            },
          },
        )
        .then(response => {
          this.pagination = response.data.meta.pagination;
          this.organisationShops = response.data.data;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.shopsFetchLoading = false;
        });
    },
    onLadditionLink(shop) {
      this.$refs.ladditionAuthenticationModal.openModal(shop.id);
    },
    onLadditionUnlink(shop) {
      const name = shop.attributes.name;
      const posName = 'L’Addition';
      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        actionText: this.$t('organisation_settings.tabs.pos_partners.laddition.modal.unlink.confirmation'),
        description: this.$t('organisation_settings.tabs.pos_partners.generic.unlink_shop_modal.body', { name, posName }),
        onConfirmAsync: () => this.unlinkShop(shop),
        submitLabel: this.$t('organisation_settings.tabs.pos_partners.generic.deactivate_modal.submit'),
        title: this.$t('organisation_settings.tabs.pos_partners.generic.unlink_shop_modal.title', { name, posName }),
      });
    },
    unlinkShop(shop) {
      return this.unlinkLadditionShop(shop.id).then(() => {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.pos_partners.laddition.modal.unlink.success'),
          variant: 'success',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-table.pos-card__table > tbody td {
  border-bottom: none;
}

.pos-card__table {
  min-height: 320px;
}

.table__paginate__footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .paginate__navigation__wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

/* Larger space between header and first row than between each row */
.sk-table.pos-card__table > tbody tr:first-child td {
  padding-top: 18px;
}
</style>
