<template>
  <div class="sk-modal-wrapper">
    <GlobalEvents
      @click="handleClickOutside"
    />
    <div
      ref="modal"
      :style="modalContainerStyles"
      class="sk-modal-container"
    >
      <div
        class="closing-x"
        @click="onClose"
      >
        <ClosingXSvg />
      </div>
      <header class="padding-top padding-left padding-right">
        <slot name="header" />
      </header>
      <section class="sk-modal-body padding-left padding-right">
        <slot name="body" />
      </section>
      <footer class="sk-modal-footer padding-bottom">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import GlobalEvents from 'vue-global-events';
import ClosingXSvg from '../../assets/svg/ClosingXSvg';

export default {
  components: { ClosingXSvg, GlobalEvents },
  props: {
    onClose: {
      type: Function,
      required: true,
    },
    width: {
      type: String,
      default: '570px',
    },
  },
  computed: {
    modalContainerStyles() {
      return { width: this.width };
    },
  },
  methods: {
    handleClickOutside(event) {
      if (this.$refs.modal.contains(event.target)) return true;
      this.onClose();
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-modal-wrapper {
  background: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.sk-modal-container {
  min-height: 100px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  border-radius: 3px;
  padding: 0;
  position: relative;
}

.padding-top {
  padding-top: 30px;
}

.padding-right {
  padding-right: 25px;
}

.padding-left {
  padding-left: 25px;
}

.full-width {
  width: 100%;
}

.closing-x {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.sk-modal-body,
.sk-modal-footer {
  border: none;
}

.sk-modal-footer {
  justify-content: center;
}
</style>
