<template>
  <HorizontalScrollTable ref="rttCounterTable">
    <template #headers-column>
      <RttCounterTitlesColumn :period-owed-rtt="periodOwedRtt" />
    </template>
    <RttCounterYearColumn
      v-for="(period, periodIndex) in periodsToDisplay"
      :key="periodIndex"
      :period="period"
      @click="handleClickOnYearColumn(period)"
    >
      <RttCounterMonthColumn
        v-for="month in period.months"
        :ref="isCurrentMonthColumn(month)"
        :key="month.date.format()"
        :month="month"
        @edit="handleEditModalShow"
      />
    </RttCounterYearColumn>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <EditRttCounterModal v-bind="editModalProps" />
    </MountingPortal>
  </HorizontalScrollTable>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import 'simplebar/dist/simplebar.min.css';

import skDate from '@skello-utils/dates';
import { roundFloat } from '@skello-utils/formatting/numbers';

import { HorizontalScrollTable } from '../../shared/components';
import RttCounterTitlesColumn from './RttCounterTitlesColumn.vue';
import RttCounterYearColumn from './RttCounterYearColumn.vue';
import RttCounterMonthColumn from './RttCounterMonthColumn.vue';
import EditRttCounterModal from './EditRttCounterModal.vue';

export default {
  name: 'RttCounterTable',
  components: {
    EditRttCounterModal,
    HorizontalScrollTable,
    RttCounterMonthColumn,
    RttCounterTitlesColumn,
    RttCounterYearColumn,
  },
  data() {
    return {
      tableWidth: null,
      horizontallyScrolled: null,
      localModalProps: {
        date: null,
        initializationCounter: null,
        oldManualChanges: null,
        oldCounter: null,
      },
    };
  },
  computed: {
    ...mapGetters('employeeRttCounter', ['hasCounters', 'isRttCounterLoading']),
    ...mapState('employeeRttCounter', ['periodOwedRtt', 'periods']),
    ...mapState('navContext', ['navContext']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('shopAbsenceConfig', ['shopAbsenceConfig']),
    editModalProps: {
      get() {
        return this.localModalProps;
      },
      set(month) {
        this.localModalProps = {
          date: month.date,
          initializationCounter: roundFloat(month.initializationCounter, 2),
          oldManualChanges: roundFloat(month.manualChanges, 2),
          oldCounter: roundFloat(month.balance, 2),
        };
      },
    },
    // Template data for uninitialized counter
    templatePeriod() {
      const initMoment = skDate().startOf('year').subtract(1, 'month').locale(this._i18n._vm.locale);
      const templateMonths = Array(12)
        .fill()
        .map(() => ({ date: initMoment.add(1, 'month').clone(), showDash: true }));

      return [{
        isClosed: false,
        months: templateMonths,
        startDate: skDate().startOf('year'),
        endDate: skDate().endOf('year'),
      }];
    },
    owedRtt() {
      return roundFloat(this.periodOwedRtt, 2);
    },
    periodsToDisplay() {
      return !!this.shopAbsenceConfig && this.hasCounters ? this.periods : this.templatePeriod;
    },
  },
  watch: {
    isRttCounterLoading(isLoading) {
      if (isLoading) return;

      this.$nextTick(() => {
        this.scrollToMonth();
      });
    },
  },
  methods: {
    ...mapActions('employeeRttCounter', ['fetchCounterData']),
    handleEditModalShow(month) {
      this.editModalProps = month;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'edit-rtt-counter-modal');
    },
    handleClickOnYearColumn(period) {
      if (!period.isClosed) return;

      this.fetchCounterData({
        shopId: this.employee.attributes.shopId,
        userId: this.employee.id,
        period: {
          startDate: period.startDate,
          endDate: period.endDate,
        },
        silentLoading: true,
      })
        .then(() => this.scrollToMonth())
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
    isCurrentMonthColumn(month) {
      return month.isCurrentMonth ? 'currentMonthColumn' : 'notCurrentMonthColumn';
    },
    scrollToMonth() {
      // scroll to current month when displayed otherwise scroll to first month of diplayed year
      const targetMonth = this.$refs.currentMonthColumn && this.$refs.currentMonthColumn[0] ?
        this.$refs.currentMonthColumn[0].$el :
        this.$refs.notCurrentMonthColumn[0].$el;

      this.$refs.rttCounterTable.scrollTo(targetMonth);
    },
  },
};
</script>
