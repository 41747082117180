<template>
  <img
    :width="width"
    :height="height"
    src="./images/logo-silae.png"
  >
</template>

<script>
export default {
  name: 'Silae',
  props: {
    width: {
      type: String,
      default: '48',
    },
    height: {
      type: String,
      default: '48',
    },
  },
};
</script>
