<template>
  <SkModalV2
    id="edit-rtt-counter-modal"
    ref="editRttCounterModal"
    :title="$t('employees.tabs.counters.rtt_counter.table.edit_modal.title')"
    @submit="handleSubmit"
    @cancel="handleCancel"
  >
    <template #body>
      <SkModalSectionV2>
        <div class="edit-rtt-counter-modal__form__input">
          <div class="edit-rtt-counter-modal__form__input-label">
            {{ $t('employees.tabs.counters.rtt_counter.table.edit_modal.balance') }}
            <div class="edit-rtt-counter-modal__form__input-label__date">
              {{ $t('employees.tabs.counters.rtt_counter.table.edit_modal.end_of') }} {{ displayDate }}
            </div>
          </div>
          <div class="edit-rtt-counter-modal__display-value">
            <div> {{ oldCounterRounded }} </div>
          </div>
        </div>
        <div class="edit-rtt-counter-modal__form__input">
          <div
            v-tooltip="$t('employees.tabs.counters.rtt_counter.table.edit_modal.changes_tooltip')"
            class="edit-rtt-counter-modal__form__input-label"
          >
            {{ $t('employees.tabs.counters.rtt_counter.table.edit_modal.days_to_add_or_remove') }}
          </div>
          <form
            class="edit-rtt-counter-modal__form__input-right"
            @submit.prevent="handleSubmit"
          >
            <SkInput
              v-model="manualChanges"
              :errored="manualChangesInputError"
              :error-message="$t('employees.tabs.counters.rtt_counter.table.edit_modal.error_message')"
              max="999"
              center
              autofocus
              error-message-align-right
              type="number"
              step="1"
            />
          </form>
        </div>
      </SkModalSectionV2>

      <SkModalSectionV2
        :padding-bottom="false"
        border-bottom="none"
      >
        <div class="edit-rtt-counter-modal__form__input">
          <div class="edit-rtt-counter-modal__form__input-label">
            {{ $t('employees.tabs.counters.rtt_counter.table.edit_modal.new_balance') }}
            <div class="edit-rtt-counter-modal__form__input-label__date">
              {{ $t('employees.tabs.counters.rtt_counter.table.edit_modal.end_of') }} {{ displayDate }}
            </div>
          </div>
          <div class="edit-rtt-counter-modal__display-value">
            <div class="edit-rtt-counter-modal__form__new-counter-right">
              {{ newCounter }}
            </div>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="manualChangesInputError || manualChanges === 0"
        :loading="isSubmitLoading"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';
import { svcEmployeesClient } from '@skello-utils/clients/svc_employees_client';

import { isFloatWithPrependSign } from '@skello-utils/validators';
import { roundFloat } from '@skello-utils/formatting/numbers';

export default {
  name: 'EditRttCounterModal',
  props: {
    initializationCounter: {
      type: Number,
      default: 0,
    },
    oldManualChanges: {
      type: Number,
      default: null,
    },
    oldCounter: {
      type: Number,
      default: null,
    },
    date: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitLoading: false,
      manualChanges: 0,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('selectedEmployee', ['initials']),
    displayDate() {
      return this.date?.format('MMMM YYYY');
    },
    oldCounterRounded() {
      return roundFloat(this.oldCounter, 2);
    },
    newCounter() {
      if (!this.manualChanges) return this.oldCounterRounded;

      return roundFloat(this.oldCounter + this.manualChanges, 2);
    },
    manualChangesInputError() {
      return !isFloatWithPrependSign(this.manualChanges);
    },
  },
  methods: {
    ...mapActions('employeeRttCounter', ['computePeriods']),
    ...mapMutations('employeeRttCounter', ['setEmployeeAbsenceConfig']),
    handleCancel() {
      this.manualChanges = 0;
    },
    handleSubmit() {
      if (!this.manualChanges) return;

      const shopId = this.employee.attributes.shopId;
      const userId = this.employee.id;
      const updates = [{
        date: this.date.format('YYYY-MM-DD'),
        value: this.manualChanges + this.oldManualChanges - this.initializationCounter,
      }];

      this.isSubmitLoading = true;

      svcEmployeesClient
        .updateAbsenceConfig(shopId, 'RTT', userId, { updates })
        .then(response => {
          this.setEmployeeAbsenceConfig(response);
          this.computePeriods();

          this.isSubmitLoading = false;
          this.$refs.editRttCounterModal.hide();

          this.$skToast({
            message: this.$t('employees.tabs.counters.rtt_counter.table.edit_modal.toast.success'),
            variant: 'success',
            containerId: 'employees__container',
          });

          this.manualChanges = 0;
        })
        .catch(() => this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
          containerId: 'employees__container',
        }))
        .finally(() => {
          this.isSubmitLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-rtt-counter-modal__form__input {
  background: white;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-top: 32px;

  &:first-child {
    padding-top: 0;
  }

  ::v-deep .sk-input__error-label {
    position: absolute;
    top: 44px;
    left: 0;
    font-size: $fs-text-s;
    color: $sk-error;
    max-width: none;
  }
}

.edit-rtt-counter-modal__form__input-label {
  width: 240px;
  font-weight: $fw-semi-bold;
  background: white;
}

.edit-rtt-counter-modal__form__input-label__date {
  font-weight: $fw-regular;
}

.edit-rtt-counter-modal__form__new-counter-right {
  color: $sk-blue-50;
  font-weight: $fw-semi-bold;
}

.edit-rtt-counter-modal__form__input-right {
  width: 60px;
}

.edit-rtt-counter-modal__display-value {
  display: flex;
  justify-content: center;
  width: 60px;
}
</style>
