<template>
  <draggable
    :value="sortedTasks"
    handle=".draggable-item"
    ghost-class="ghost"
    drag-class="drag"
    animation="50"
    :disabled="disabled"
    :style="{ cursor: isDragging ? 'grabbing' : undefined }"
    @start="isDragging=true"
    @end="handleDragEnd"
  >
    <div
      v-for="(task, index) in sortedTasks"
      :key="`task-${index}`"
      class="tasks-manager__draggable-task__container"
    >
      <DraggableTask
        :is-dragging="isDragging"
        :task="task"
        :disabled="disabled"
        @delete="() => handleDelete(index)"
      />
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import DraggableTask from '@app-js/plannings/shared/components/ShiftActivities/Tasks/DraggableTask';

export default {
  name: 'DraggableTasksList',
  components: { DraggableTask, draggable },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  computed: {
    sortedTasks() {
      return [...this.value].sort((taskA, taskB) => taskA.order - taskB.order);
    },
  },
  methods: {
    handleDelete(taskIndex) {
      this.value.sort((taskA, taskB) => taskA.order - taskB.order).splice(taskIndex, 1);
    },
    handleDragEnd(event) {
      this.isDragging = false;
      const reorderedTaks = [...this.sortedTasks];
      reorderedTaks.splice(
        event.newIndex,
        0,
        ...reorderedTaks.splice(event.oldIndex, 1),
      );
      this.$emit('input', reorderedTaks.map((task, index) => { task.order = index; return task; }));
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks-manager__draggable-task__container {
  border-radius: 4px;
}

.draggable-item {
  margin-right: 4px;
}

.ghost {
  background: $sk-grey-10;

  .draggable-task__container {
    visibility: hidden;
  }
}

.drag {
  background-color: $sk-grey-5;
  box-shadow: 0 16px 48px 0 #00000038;
}
</style>
