<template>
  <div class="availability-popup--wrapper">
    <div
      v-for="availability in [...filteredUnavailabilities, ...filteredAvailabilities]"
      :key="availability.id"
      class="availability-popup--item"
    >
      <CrossedCalendarIcon
        v-if="!isAvailableAvailability(availability)"
        width="12"
        height="12"
        fill="#d03e50"
      />
      <CheckedCalendarIcon
        v-else
        width="12"
        height="12"
        fill="#188377"
      />

      <span
        :style="{
          color: isAvailableAvailability(availability) ? '#188377' : '#d03e50',
        }"
      >
        {{ textForAvailability(availability) }}
      </span>
    </div>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';

export default {
  name: 'AvailabilityPopup',
  props: {
    availabilities: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredAvailabilities() {
      return this.availabilities.filter(({ attributes }) => attributes.status === 'available');
    },
    filteredUnavailabilities() {
      return this.availabilities.filter(({ attributes }) => attributes.status === 'unavailable');
    },
  },
  methods: {
    // This will return `true` if the availability is 'available', false otherwise
    // It assumes there are only 2 possilbe statuses: 'available' and 'unavailable'
    isAvailableAvailability(availability) {
      return availability.attributes.status === 'available';
    },
    availabilityStartTime(availability) {
      return skDate(availability.attributes.startsAt).format('HH:mm');
    },
    availabilityEndTime(availability) {
      return skDate(availability.attributes.endsAt).format('HH:mm');
    },
    textForAvailability(availability) {
      const prefix = this.isAvailableAvailability(availability) ?
        this.$t('plannings.monthly_v3.availabilities.available') :
        this.$t('plannings.monthly_v3.availabilities.unavailable');
      return `${prefix}: ${this.availabilityStartTime(availability)} - ${this.availabilityEndTime(availability)}`;
    },
  },
};
</script>

<style scoped lang="scss">
.availability-popup--wrapper {
  z-index: 10;
  background-color: white;
  position: fixed;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, .22);
  outline: 1px solid $sk-grey-10;
}

.availability-popup--item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: $fs-text-s;

  svg {
    flex-shrink: 0;
  }

  span {
    text-wrap: nowrap;
  }
}
</style>
