<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="158"
    height="96"
    viewBox="0 0 158 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="138.322"
      y="6.67554"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="143.347"
      y="10.3904"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 143.347 10.3904)"
      fill="#D2D7DC"
    />
    <rect
      x="3.71533"
      y="47.7571"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="8.74078"
      y="51.4719"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 8.74078 51.4719)"
      fill="#D2D7DC"
    />
    <rect
      x="53.9072"
      y="74.7434"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      fill="#D2D7DC"
    />
    <rect
      x="58.9341"
      y="78.4583"
      width="1.3111"
      height="8.74065"
      rx="0.655549"
      transform="rotate(90 58.9341 78.4583)"
      fill="#D2D7DC"
    />
    <circle
      cx="34.0013"
      cy="5.24439"
      r="4.47412"
      stroke="#D2D7DC"
      stroke-width="1.54054"
    />
    <circle
      cx="152.088"
      cy="64.801"
      r="4.47412"
      stroke="#D2D7DC"
      stroke-width="1.54054"
    />
    <path
      opacity="0.3"
      d="M130.432 49.2162C130.432 61.8361 125.373 73.2769 117.173 81.6166C108.788 90.1448 97.119 95.4324 84.2162 95.4324C71.3116 95.4324 59.6424 90.143 51.2574 81.6146C43.0574 73.275 38 61.8361 38 49.2162C38 36.5963 43.0574 25.1574 51.2574 16.8177C55.2112 12.796 59.8951 9.49482 65.0939 7.12931C70.9211 4.47711 77.395 3 84.2162 3C109.741 3 130.432 23.6917 130.432 49.2162Z"
      fill="#eef4ff"
    />
    <path
      d="M85.0001 50.0001C89.6964 50.0001 93.5 46.1857 93.5 41.5001C93.5 36.8038 89.6963 33 85.0001 33C80.3039 33 76.5 36.8038 76.5 41.5C76.5 46.1856 80.3038 50.0001 85.0001 50.0001ZM85.0001 37.0375C87.4651 37.0375 89.4626 39.0349 89.4626 41.5C89.4626 43.965 87.4651 45.9625 85.0001 45.9625C82.535 45.9625 80.5376 43.965 80.5376 41.5C80.5376 39.035 82.535 37.0375 85.0001 37.0375Z"
      fill="#ACC9FF"
    />
    <path
      d="M85.0001 52.125C79.3369 52.125 68 54.9619 68 60.625V67H102V60.625C102 54.9619 90.6631 52.125 85.0001 52.125ZM97.9625 62.9625H72.0375V60.625C72.0375 59.2544 78.6781 56.1625 85 56.1625C91.3218 56.1625 97.9624 59.2544 97.9624 60.625V62.9625H97.9625Z"
      fill="#ACC9FF"
    />
    <path
      d="M83.0001 48.0001C87.6964 48.0001 91.5 44.1857 91.5 39.5001C91.5 34.8038 87.6963 31 83.0001 31C78.3039 31 74.5 34.8038 74.5 39.5C74.5 44.1856 78.3038 48.0001 83.0001 48.0001ZM83.0001 35.0375C85.4651 35.0375 87.4626 37.0349 87.4626 39.5C87.4626 41.965 85.4651 43.9625 83.0001 43.9625C80.535 43.9625 78.5376 41.965 78.5376 39.5C78.5376 37.035 80.535 35.0375 83.0001 35.0375Z"
      fill="#2b66fe"
    />
    <path
      d="M83.0001 50.125C77.3369 50.125 66 52.9619 66 58.625V65H100V58.625C100 52.9619 88.6631 50.125 83.0001 50.125ZM95.9625 60.9625H70.0375V58.625C70.0375 57.2544 76.6781 54.1625 83 54.1625C89.3218 54.1625 95.9624 57.2544 95.9624 58.625V60.9625H95.9625Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'Profil',
};
</script>
