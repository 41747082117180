<template>
  <div id="organisation-settings__sidebar">
    <div class="sidebar__tabs-menu">
      <router-link
        v-if="currentUserIsSystemAdmin"
        :to="{ name: 'organisation_settings' }"
      >
        <div class="sidebar__tab-menu__link">
          <div class="sidebar__tab-menu__link__icon-wrapper">
            <OrganisationIcon
              class="sidebar__tab-menu__link__icon"
              fill=""
            />
          </div>
          <div class="sidebar__tab-menu__link__label">
            {{ $t('organisation_settings.tabs.organisation_info.title') }}
          </div>
        </div>
      </router-link>
      <router-link
        v-if="canAccessShopsTab"
        :to="{ name: 'organisation_settings_shops' }"
      >
        <div class="sidebar__tab-menu__link">
          <div class="sidebar__tab-menu__link__icon-wrapper">
            <ShopIcon
              class="sidebar__tab-menu__link__icon"
              fill=""
            />
          </div>
          <div class="sidebar__tab-menu__link__label">
            {{ $t('organisation_settings.tabs.shops.title') }}
          </div>
        </div>
      </router-link>
      <router-link
        v-if="canAccessClustersTab"
        :to="{ name: 'organisation_settings_clusters' }"
      >
        <div class="sidebar__tab-menu__link">
          <div class="sidebar__tab-menu__link__icon-wrapper">
            <ShopIcon
              class="sidebar__tab-menu__link__icon"
              fill=""
            />
          </div>
          <div class="sidebar__tab-menu__link__label">
            {{ $t('organisation_settings.tabs.clusters.title') }}
          </div>
        </div>
      </router-link>
      <router-link
        v-if="currentLicense.attributes.canEditShopPermissions"
        :to="{ name: 'organisation_settings_licenses' }"
      >
        <div class="sidebar__tab-menu__link sidebar__tab-menu__link--flex-baseline">
          <div class="sidebar__tab-menu__link__icon-wrapper">
            <KeyIcon
              class="sidebar__tab-menu__link__icon"
              fill=""
            />
          </div>
          <div class="sidebar__tab-menu__link__label">
            {{ $t('organisation_settings.tabs.licenses.title') }}
          </div>
        </div>
      </router-link>
      <router-link
        v-if="allowedPartnersToolsAccess"
        data-test="partner-tools-nav"
        :to="{ name: 'organisation_settings_partners_tools' }"
        :class="partnersToolsLinkClasses"
      >
        <div class="sidebar__tab-menu__link">
          <div class="sidebar__tab-menu__link__icon-wrapper">
            <CashRegisterV2Icon
              class="sidebar__tab-menu__link__icon"
              fill=""
            />
          </div>
          <div class="sidebar__tab-menu__link__label">
            {{ $t('organisation_settings.tabs.partners_tools.title') }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { FEATURES } from '@app-js/shared/constants/features.js';

export default {
  name: 'OrganisationSettingsSidebar',
  data() {
    return {
      FEATURES,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters({
      currentUserIsSystemAdmin: 'currentLicense/isSystemAdmin',
    }),
    ...mapGetters('features', ['isFeatureEnabled']),
    allowedPartnersToolsAccess() {
      // Only allow if system administrator
      return this.currentLicense.attributes.position === 0 &&
        this.isFeatureEnabled(FEATURES.FEATURE_PARTNER_TOOLS, this.currentShop.id, () => true);
    },
    // By default, highlighted class is applied to the current route
    // For partner tools, we need the route for both tabs to match, so that it's highlighted for both tabs
    // This code manually checks if you're in either tab and highlights it if needed
    partnersToolsLinkClasses() {
      const partnerToolsRoutes = [
        'organisation_settings_partners_tools',
        'organisation_settings_pay_partners',
        'organisation_settings_bi_partners',
        'organisation_settings_advance_payment_partners',
      ];
      return {
        'router-link-exact-active': partnerToolsRoutes.includes(this.$route.name),
      };
    },
    canAccessShopsTab() {
      if (this.currentOrganisation.attributes.clusters) return false;

      if (this.currentUserIsSystemAdmin) return true;

      return this.currentLicense.attributes.canCreateShop &&
        !this.currentOrganisation.attributes.clusters;
    },
    canAccessClustersTab() {
      if (!this.currentOrganisation.attributes.clusters) return false;

      if (this.currentUserIsSystemAdmin) return true;

      return this.currentLicense.attributes.canCreateShop &&
        this.currentOrganisation.attributes.clusters;
    },
  },
};
</script>

<style lang="scss" scoped>
#organisation-settings__sidebar {
  overflow: auto;
  padding: 30px 0 0 30px;
}

.sidebar__tabs-menu {
  width: 270px;
  border-radius: 4px;
  background: $sk-grey-5;
  padding: 20px 20px 10px;
}

a {
  display: block;
  min-height: 40px;
  margin-bottom: 10px;
  padding: 10px;
}

a:hover {
  background: $sk-grey-10;
  border-radius: 4px;
  text-decoration: none;
}

a.router-link-exact-active {
  background: $sk-blue-10;
  border-radius: 4px;

  .sidebar__tab-menu__link__label {
    color: $sk-blue;
  }

  .sidebar__tab-menu__link__icon {
    fill: $sk-blue;
  }
}

.sidebar__tab-menu__link {
  display: flex;
  align-items: center;
  width: 85%;
  color: $sk-black;
}

.sidebar__tab-menu__link__label {
  margin-left: 15px;
}

.sidebar__tab-menu__link--flex-baseline {
  align-items: baseline;
}

.sidebar__tab-menu__link__icon-wrapper {
  width: 18px;
  display: flex;
  align-items: center;
}

.sidebar__tab-menu__link__icon {
  fill: $sk-black;
}
</style>
