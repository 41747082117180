import Login from './users/login';
import ForgottenPassword from './users/forgotten_password';
import AcceptInvitation from './users/accept_invitation/AcceptInvitation';
import ResetPassword from './users/reset_password';

const staticRoutes = [
  {
    path: '/users/sign_in',
    component: Login,
    name: 'Login',
  },
  {
    path: '/users/password/new',
    component: ForgottenPassword,
    name: 'ForgottenPassword',
  },
  {
    path: '/users/password/edit',
    component: ResetPassword,
    name: 'ResetPassword',
  },
  {
    path: '/users/invitation/accept',
    component: AcceptInvitation,
    name: 'AcceptInvitation',
  },
];

export default staticRoutes;
