<template>
  <td
    v-tooltip="paidCellTooltip"
    :class="hoverClass"
    @mouseenter="showUnderlineIcon(holidaySettings.id)"
    @mouseleave="hideUnderlineIcon"
  >
    <div
      v-if="showDash"
      class="user-holidays-counter-table__paid-cell"
    >
      -
    </div>
    <SkDropdown
      v-else
      ref="editableCellDropdown"
      :disabled="disabled"
      :placement="dropdownPlacement"
      trigger="click"
      :prevent-overflow="false"
      append-to-body
    >
      <template #anchor>
        <div
          v-if="paidCell"
          class="user-holidays-counter-table__paid-cell"
        >
          <div :class="paidCellBulletPoint" />
          <!-- eslint-disable-next-line max-len -->
          <span class="user-holidays-counter-table__paid-cell__label">
            {{ $tc('holiday_counter.table.cells.paid', holidaySettings.attributes.paid) }}
          </span>
        </div>
        <div
          v-else
          class="user-holidays-counter-table__guaranteed-cell"
        >
          <component
            :is="guaranteedCellIcon.component"
            :fill="guaranteedCellIcon.fill"
            :width="guaranteedCellIcon.width"
          />
          <div
            v-if="hoveredCellId === holidaySettings.id"
            class="user-holidays-counter-table__guaranteed-cell-line"
          />
        </div>
      </template>
      <template #menu>
        <div class="editable-cell__dropdown-menu">
          <div
            class="editable-cell__dropdown-option"
            @click="selectValue(true)"
          >
            {{ options[0] }}
          </div>
          <div
            class="editable-cell__dropdown-option"
            @click="selectValue(false)"
          >
            {{ options[1] }}
          </div>
        </div>
      </template>
    </SkDropdown>
  </td>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EditableCell',
  props: {
    holidaySettings: {
      type: Object,
      required: true,
    },
    holidaysSettings: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    showDash: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoveredCellId: null,
    };
  },
  computed: {
    ...mapGetters('selectedEmployee', ['fullName']),

    paidCell() {
      return this.type === 'paid';
    },
    paidCellTooltip() {
      if (!this.paidCell || this.showDash) return '';

      if (!this.disabled) {
        return this.$t('holiday_counter.table.cells.tooltip', { name: this.fullName });
      }

      if (this.holidaySettings.attributes.paid) return '';

      return this.$t('holiday_counter.table.cells.options.recover');
    },
    dropdownPlacement() {
      const lastElemment = this.holidaysSettings[this.holidaysSettings.length - 1];

      return lastElemment === this.holidaySettings ? 'bottom-start' : 'bottom';
    },
    hoverClass() {
      if (this.disabled || this.showDash) return null;

      if (!this.paidCell) return 'user-holidays-counter-table__guaranteed-hover';

      return this.holidaySettings.attributes.paid ?
        'user-holidays-counter-table__paid-hover' :
        'user-holidays-counter-table__recover-hover';
    },
    paidCellBulletPoint() {
      return this.holidaySettings.attributes.paid ?
        'user-holidays-counter-table__paid-cell__bullet-point' :
        'user-holidays-counter-table__recover-cell__bullet-point';
    },
    guaranteedCellIcon() {
      const { guaranteed } = this.holidaySettings.attributes;

      return {
        component: guaranteed ? 'CheckMarkIcon' : 'ClosingXIcon',
        fill: guaranteed ? '#000000' : '#727272',
        width: guaranteed ? '12' : '8',
      };
    },
    options() {
      const key = 'holiday_counter.table.cells.options';

      if (this.paidCell) {
        return [this.$t(`${key}.paid`), this.$t(`${key}.recover`)];
      }

      return [this.$t(`${key}.guaranteed`), this.$t(`${key}.non_guaranteed`)];
    },
  },
  methods: {
    showUnderlineIcon() {
      if (this.disabled || this.showDash) return;

      this.hoveredCellId = this.holidaySettings.id;
    },
    hideUnderlineIcon() {
      this.hoveredCellId = null;
    },
    selectValue(value) {
      this.$refs.editableCellDropdown.hide();

      const attribute = this.paidCell ? 'paid' : 'guaranteed';
      this.$skAnalytics.track('updated_public_holidays_counter_settings_employee_tab',
        { source: this.paidCell ? 'Public holidays compensation' : 'Public holidays status' },
      );

      this.$parent.$emit('update-day', {
        id: this.holidaySettings.id,
        settings: { [attribute]: value },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-holidays-counter-table__guaranteed-hover {
  &:hover {
    cursor: pointer;
    background-color: $sk-grey-10;
  }
}

.user-holidays-counter-table__paid-hover {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: $sk-blue-5;
  }
}

.user-holidays-counter-table__recover-hover {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: $sk-blue-10;
  }
}

// full cell height for clickable dropdown
::v-deep .sk-dropdown__anchor {
  height: 60px;
}

.user-holidays-counter-table__guaranteed-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user-holidays-counter-table__guaranteed-cell-line {
  width: 11px;
  border-bottom: 1px solid black;
  padding-top: 10px;
}

.user-holidays-counter-table__paid-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  height: 100%;
}

.user-holidays-counter-table__paid-cell__bullet-point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $sk-blue;
  margin-right: 5px;
}

.user-holidays-counter-table__recover-cell__bullet-point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $sk-warning;
  margin-right: 5px;
}

.user-holidays-counter-table__paid-cell__label {
  max-width: 50px;
  overflow-wrap: break-word;
}

.editable-cell__dropdown-menu {
  background: white;
  width: 220px;
  height: 92px;
  overflow: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .15);
}

.editable-cell__dropdown-option {
  height: 46px;
  display: flex;
  align-items: center;
  padding-left: 18px;

  &:hover {
    cursor: pointer;
    background-color: $sk-grey-10;
  }
}
</style>
