<template>
  <div
    v-tooltip.left="tooltip"
    :style="{ color, width, height, padding }"
    :class="cellClasses"
    @click="$emit('click', $event)"
  >
    {{ formatValue(data) }}
    <br>
    <span
      v-if="hasSubtitle"
      class="hours-counter__table-cell-subtitle"
    >
      <slot>
        ({{ formatValue(subtitle) }})
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    data: {
      type: [String, Number],
      default: '',
    },
    subtitle: {
      type: [String, Number],
      default: '',
    },
    color: {
      type: String,
      default: '#4d626f',
    },
    width: {
      type: String,
      default: '60px',
    },
    height: {
      type: String,
      default: '60px',
    },
    padding: {
      type: String,
      default: '0',
    },
    hasSubtitle: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    showDash: {
      type: Boolean,
      default: false,
    },
    showNumberSign: {
      type: Boolean,
      default: false,
    },
    onlyPositive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cellClasses() {
      return {
        'hours-counter__table-cell': true,
        'hours-counter__table-cell--editable': this.editable && !this.showDash,
      };
    },
  },
  methods: {
    formatValue(value) {
      if (this.isEmpty) return '';
      if (this.showDash) return '-';

      value = Math.round(value * 100) / 100;
      if (this.onlyPositive) value = Math.max(value, 0);
      if (this.showNumberSign) value = this.prependSignOnValue(value);

      return value;
    },
    prependSignOnValue(value) {
      return (value <= 0) ? value : `+${value}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.hours-counter__table-cell {
  position: relative;
  border-top: solid 1px;
  border-color: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: solid 1px;
    border-color: #e1e1e1;
  }
}

.hours-counter__table-cell--editable {
  &:hover {
    cursor: pointer;
    color: black;
    text-decoration: underline;
    background: rgba(109, 125, 140, .1);
  }
}

.hours-counter__table-cell-subtitle {
  position: absolute;
  bottom: 0;
  color: $sk-grey-50;
  font-size: $fs-text-s;
}
</style>
