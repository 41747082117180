<template>
  <SkModal
    id="save-text-document-template-modal"
    ref="modal"
    :modal-title="$t('text_document_templates.save_template_text_document_modal.title')"
  >
    <template #body>
      <SkModalSection
        class="save-text-document-template-modal__body"
        border-bottom="none"
      >
        <SkInput
          v-model="title"
          :label="
            $t('text_document_templates.save_template_text_document_modal.title_input')
          "
        />
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="loadingCreate"
        @click.prevent="handleSubmit"
      >
        {{ $t('text_document_templates.save_template_text_document_modal.save') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SaveTextDocumentTemplateModal',
  computed: {
    ...mapState('textDocumentTemplates', {
      loadingCreate: state => state.loadingCreate,
      textDocumentTemplate: state => state.selectedTextDocumentTemplate,
      textDocumentTemplates: state => state.textDocumentTemplates,
    }),
    title: {
      get() {
        return this.textDocumentTemplate ? this.textDocumentTemplate.attributes.title : null;
      },
      set(title) {
        if (this.textDocumentTemplate) {
          this.textDocumentTemplate.attributes.title = title;
        }
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.emptyBody()) {
        this.$skToast({
          message: this.$t('text_document_templates.save_text_document_modal.empty_content_template'),
          variant: 'error',
        });
        return;
      }

      if (!this.textDocumentTemplate.attributes.title) {
        this.$skToast({
          message: this.$t('text_document_templates.save_text_document_modal.empty_title'),
          variant: 'error',
        });
        return;
      }

      this.$emit('save');
    },
    emptyBody() {
      return this.textDocumentTemplate.attributes.body === '<h1><br></h1>' ||
        this.textDocumentTemplate.attributes.body === null;
    },
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>
