<template>
  <div
    ref="countersWrapper"
    class="employees__counters__wrapper"
  >
    <div class="employees__counters__navigation-wrapper">
      <SkTab v-if="defaultTabs.length > 1">
        <router-link
          v-if="isAvailable('user_hours')"
          to="user_hours"
        >
          <SkTabItem
            invert-hover-color
            data-test="hour-tracker-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.hours_counter.title') }}
            <PackTag
              v-if="!hoursCounterEnabled"
              class="employees-counters__list-item-tag"
              type="success"
            />
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_day_rate')"
          v-track="'history_counters_day_rate_tab'"
          to="user_day_rate"
        >
          <SkTabItem
            invert-hover-color
            data-test="day-tracker-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.day_rate_counter.title') }}
            <PackTag
              v-if="!hoursCounterEnabled"
              class="employees-counters__list-item-tag"
              type="success"
            />
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_shift_history')"
          to="user_shift_history"
        >
          <SkTabItem
            invert-hover-color
            data-test="history-shifts__navigation-tab"
          >
            {{ $t('employees.tabs.counters.shifts_history.title') }}
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_annualization')"
          v-track="'annualization_click_tab'"
          to="user_annualization"
        >
          <SkTabItem
            invert-hover-color
            data-test="annualization-tracker-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.annualization_counter.title') }}
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_paid_leave')"
          v-track="'history_counters_paid_leaves_tab'"
          to="user_paid_leave"
        >
          <SkTabItem
            invert-hover-color
            data-test="pto-tracker-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.paid_leave_counter.title') }}
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_rtt')"
          to="user_rtt"
        >
          <SkTabItem
            invert-hover-color
            data-test="rtt-counter-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.rtt_counter.title') }}
          </SkTabItem>
        </router-link>
        <router-link
          v-if="isAvailable('user_holidays')"
          to="user_holidays"
        >
          <SkTabItem
            invert-hover-color
            data-test="holiday-tracker-table__navigation-tab"
          >
            {{ $t('employees.tabs.counters.holiday_counter.title') }}
            <PackTag
              v-if="!holidaysCounterEnabled"
              class="employees-counters__list-item-tag"
              type="premium"
            />
          </SkTabItem>
        </router-link>
      </SkTab>
    </div>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';

import PackTag from '@app-js/shared/components/PackTag';
import { FEATURES } from '@app-js/shared/constants/features';

export default {
  name: 'HistoryCountersSection',
  components: {
    PackTag,
  },
  beforeRouteUpdate(to, _from, next) {
    if (!this.isAvailable(to.name)) {
      this.$router.push(this.selectedTabName);
    } else {
      this.selectedTabName = to.name;
      next();
    }
  },
  data() {
    return {
      selectedTabName: 'user_shift_history',
      defaultTabs: ['user_shift_history'],
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapGetters('currentLicense', ['canReadPaidVacationCounter']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable', 'checkFeatureFlag']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('selectedEmployee', [
      'isStrictSubordinateOfCurrentUser',
      'isCurrentUserOrSystemAdmin',
    ]),

    hoursCounterEnabled() {
      return this.checkPackOfferFlag('hours_counter_enabled');
    },
    holidaysCounterEnabled() {
      return this.checkPackOfferFlag('holidays_counter_enabled');
    },
    holidaysCounterConfigured() {
      return this.employee.relationships.shop.attributes.holidaysCounterConfigured;
    },
    canAccessCountersTab() {
      return this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser;
    },
    isRttCounterFeatureAvailable() {
      return this.checkPackOfferFlag('rtt_counter_enabled') && this.checkFeatureFlag(
        'FEATURE_RTT_TRACKER',
        this.employee.relationships.shop,
        this.employee.relationships.convention,
      );
    },
  },
  watch: {
    employee(newValue, oldValue) {
      if (newValue.id === oldValue.id) return;
      this.setEmployeeTabs();
    },
  },
  async beforeMount() {
    if (!this.canAccessCountersTab) {
      this.$router.replace({ name: 'user_personal', params: { user_id: this.employee.id } });
      return;
    }

    await this.setEmployeeTabs();

    if (!this.isAvailable(this.$router.currentRoute.name)) {
      this.$router.push({ name: this.selectedTabName });
    }
  },
  methods: {
    ...mapActions('annualization', ['fetchShopAnnualizationConfig']),
    async isAnnualizationActive() {
      if (!this.isAnnualizedWorkingTimeAvailable({ shop: this.employee.relationships.shop }) ||
            !this.employee.attributes.annualizable) {
        return false;
      }

      if (this.currentShop.id !== this.employee.attributes.shopId) {
        try {
          await this.fetchShopAnnualizationConfig({
            shopId: this.employee.attributes.shopId,
          });

          return true;
        } catch (error) {
          if (error.response.status === 404) return false;

          throw error;
        }
      }

      return this.currentShop.attributes.isAnnualizationV2Active;
    },
    async setEmployeeTabs() {
      if (!this.isFeatureEnabled(FEATURES.FEATURE_TRACKERS, this.currentShop.id, () => true)) {
        // For now, all counters are under the same feature "FEATURE_TRACKERS"
        return;
      }

      if (this.canReadPaidVacationCounter && !this.employee.attributes.interim) {
        this.defaultTabs.push('user_paid_leave');
      }
      if (
        !this.employee.attributes.interim &&
        !this.employee.attributes.onDayRate &&
        (
          // promote to upsell
          !this.holidaysCounterEnabled ||
          // or counter is configured
          this.holidaysCounterConfigured
        )
      ) {
        this.defaultTabs.push('user_holidays');
      }

      if (this.isRttCounterFeatureAvailable && this.employee.attributes.onDayRate) {
        this.defaultTabs.push('user_rtt');
      }

      const isAnnualizationActive = await this.isAnnualizationActive();

      if (isAnnualizationActive) {
        this.defaultTabs.push('user_annualization');
      }

      const promoteToUpsell = !this.hoursCounterEnabled;
      const counterActive = this.employee.relationships.shop.attributes.modulation &&
        !this.employee.attributes.interim;

      if (promoteToUpsell || counterActive) {
        if (this.employee.attributes.onDayRate) {
          this.selectedTabName = 'user_day_rate';
          this.defaultTabs.push('user_day_rate');
        } else {
          this.selectedTabName = 'user_hours';
          this.defaultTabs.push('user_hours');
        }
      }
    },
    isAvailable(tabName) {
      return this.defaultTabs.includes(tabName);
    },
    isSelected(routeName) {
      return this.$router.currentRoute === routeName;
    },
  },
};
</script>

<style lang="scss">
@use "./shared/scss/counters";

.employees__counters__wrapper {
  width: calc(100vw - 310px);
  padding: 24px 30px 40px;
  margin-top: -40px;
  background: $sk-grey-5;
}

.employees__counters__navigation-wrapper {
  padding-bottom: 24px;
}

.employees-counters__list-item-tag {
  margin-left: 8px;
}
</style>
