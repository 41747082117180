<template>
  <div class="hris-pack__folders">
    <div
      v-for="folder in foldersList"
      :key="folder"
      class="hris-pack__folder-card"
      @click="handleClick(folder)"
    >
      <SkMedallion
        v-if="documentsCount(folder) > 0"
        :text="documentsCount(folder)"
        background-color="#eef4ff"
        color="#2b66fe"
        size="small"
        class="hris-pack__folder-card__document-count"
      />
      <component
        :is="folderState(folder)"
        class="hris-pack__folder-card__image"
      />
      <p class="hris-pack__folder-card__title">
        {{ folderName(folder) }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';

import { FOLDER_STATES } from '@app-js/shared/constants/hris';

import FullFolder from '../assets/FullFolder';
import EmptyFolder from '../assets/EmptyFolder';

export default {
  name: 'HrisFolders',
  components: { FullFolder, EmptyFolder },
  computed: {
    ...mapGetters('employeeDocuments', ['documentsByFolder']),
    ...mapGetters('foldersManagement', ['folderName', 'foldersList']),
    ...mapState('currentShop', ['currentShop']),
  },
  methods: {
    handleClick(folder) {
      this.$emit('go-to-folder', folder);
    },
    folderState(folder) {
      return this.documentsCount(folder) > 0 ? FOLDER_STATES.full : FOLDER_STATES.empty;
    },
    documentsCount(folder) {
      const documents = this.documentsByFolder[folder];
      return String(documents?.length || 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.hris-pack__folders {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .hris-pack__folder-card {
    width: 140px;
    height: 140px;
    padding: 8px;
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
    margin: 15px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &:hover {
      background: $sk-grey-5;
    }
  }

  .hris-pack__folder-card__image {
    position: relative;
    top: 25%;
  }

  .hris-pack__folder-card__title {
    margin-bottom: 20px;
    margin-top: auto;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .hris-pack__folder-card__document-count {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
</style>
