<template>
  <tr class="teams__table__row">
    <td class="teams__table__cell">
      <SkInput
        v-model="teamName"
        :disabled="disabled"
        :label="$t('shop_settings.tabs.teams.table.fields.name.label')"
        :errored="erroredUpdate"
        :error-message="errorMessage"
        @blur="updateTeamName"
      />
    </td>
    <td class="teams__table__cell">
      <SkSelectV2
        v-model="teammateIds"
        :disabled="disabled || !team.id"
        :options="teamEmployeesOptions"
        :label="$t('shop_settings.tabs.teams.table.fields.teammates.placeholder')"
        :tracking-options="trackingOptions"
        :disabled-options="disabledEmployeeIds"
        :search-placeholder="$t('labels.search')"
        :no-results-label="$t('search_bar.no_result')"
        multi
        hide-clear-button
        @input="updateTeammates"
      >
        <template #selected-option="{ value }">
          {{
            $tc('shop_settings.tabs.teams.table.fields.teammates.group_name',
                value.length,
            )
          }}
        </template>
        <template #option="{ option }">
          <!-- Remove SkPopper after MVP -->
          <SkPopover
            v-if="isVariableHoursActiveForTeam && teammateIds.includes(option.id)"
            as-tooltip
            y-offset="6"
          >
            <template #anchor>
              <div>
                {{ option.text }}
              </div>
            </template>
            <template #content>
              <!-- eslint-disable max-len -->
              <strong>{{ $t('plannings.table.manage_shift_modal.tabs.commun.other_actions.soon_available_title') }}</strong>
              <p>{{ $t('shop_settings.tabs.teams.table.fields.teammates.tooltip') }}</p>
              <!-- eslint-enable max-len -->
            </template>
          </SkPopover>
          <div
            v-else
            v-tooltip.right-start="disabledSelectTooltip(option.id)"
          >
            {{ option.text }}
          </div>
        </template>
      </SkSelectV2>
    </td>
    <td
      v-if="isVariableContractHoursAvailable"
      class="teams__table__cell teams__table__cell--flex"
      data-test="rotations-table-data"
    >
      <SkSwitch
        v-tooltip.right-start="disabledSwitchTooltip"
        :value="isVariableHoursActiveForTeam"
        :disabled="!team.id || teammateIds.length < 1 || isTeamScheduleDisabled || disabled"
        :testing-options="teamScheduleSwitchTestingOptions"
        data-test="team-schedule-switch__toggle"
        @input="toggleVariableHoursForTeam"
      />
      <div
        v-if="isVariableHoursActiveForTeam"
        class="teams__table__cell--tag"
      >
        <VariableContractHoursTag
          :nb-weeks="teamScheduleWeekCycle"
          shorten-label
        />
        {{
          $t('shop_settings.tabs.teams.table.fields.number_of_weeks_cycles', {
            week_cycles_count: teamScheduleWeekCycle,
          })
        }}
      </div>
    </td>
    <td class="teams__table__cell--last">
      <SkDropdown
        v-if="isVariableContractHoursAvailable"
        id="team-actions__dropdown"
        ref="teamActionsDropdown"
        :prevent-overflow="false"
        :disabled="!team.id"
        placement="bottom-start"
        y-offset="5px"
        trigger="click"
      >
        <template #anchor>
          <SkCircleButton icon="KebabV2Icon" />
        </template>
        <template #menu>
          <div class="team-actions__menu">
            <div :class="updateClasses">
              <span
                v-track="'team_rotation_update'"
                @click="updateVariableContractHoursSettings"
              >
                <PencilIcon
                  fill="#727272"
                  class="team-actions__menu-item__icon"
                />
                {{ $t('shop_settings.tabs.teams.table.fields.update_variable_hours') }}
              </span>
            </div>
            <div
              v-track="'team_delete'"
              :class="deleteTeamClasses"
            >
              <span @click="deleteTeam">
                <span>
                  <TrashCanV2Icon
                    width="15"
                    height="16"
                    fill="#727272"
                    class="team-actions__menu-item__icon"
                  />
                </span>
                <span>
                  {{ $t('shop_settings.tabs.teams.table.fields.delete') }}
                </span>
              </span>
            </div>
          </div>
        </template>
      </SkDropdown>
      <SkCircleButton
        v-else
        v-track="'team_delete'"
        :disabled="disabled || !team.id"
        icon="TrashCanV2Icon"
        @click="deleteTeam"
      />
    </td>
  </tr>
</template>
<script>
import partition from 'lodash/partition';
import {
  mapGetters,
  mapState,
  mapActions,
} from 'vuex';

import skDate from '@skello-utils/dates';
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';

export default {
  name: 'TeamRow',
  components: { VariableContractHoursTag },
  props: {
    team: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      erroredUpdate: false,
      errorMessage: null,
      localTeam: {
        name: null,
        user_ids: null,
      },
      teamScheduleSwitchTestingOptions: {
        input: 'team-schedule-switch__input',
      },
    };
  },
  computed: {
    ...mapGetters('shopTeams', [
      'isNameAlreadyUsed',
      'teamSchedulesForTeam',
      'lastTeamScheduleForTeam',
    ]),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('currentShop', ['isVariableContractHoursAvailable']),
    ...mapState('shopTeams', [
      'teamScheduleLoading',
      'teams',
      'teamEmployees',
    ]),
    ...mapState('currentShop', ['currentShop']),
    teamName: {
      get() {
        return this.team.attributes.name;
      },
      set(value) {
        this.localTeam.name = value;
      },
    },
    teammateIds: {
      get() {
        // Remove archived team users
        return this.employeesIds.active;
      },
      set(value) {
        this.localTeam.user_ids = value;
      },
    },
    employeesIds() {
      const filteredUsers = partition(this.team.relationships.users,
        // Returns two arrays of users based on condition
        employee => !employee.attributes.archivedAt ||
          skDate(employee.attributes.archivedAt).format() >= skDate().subtract(30, 'days').format(),
      );

      return {
        active: filteredUsers[0].map(({ id }) => id),
        archived: filteredUsers[1].map(({ id }) => id),
      };
    },
    isTeamScheduleDisabled() {
      if (this.teamSchedulesForTeam(this.team.id).length > 0) {
        return false;
      }
      // can't activate team schedule if any team member belongs to another team with schedule
      return this.teamEmployees.some(employee => {
        const employeeTeamIds = this.employeeTeamIds(employee.id);
        return this.employeeWithTeamScheduleIds.includes(employee.id) &&
          employeeTeamIds.includes(this.team.id);
      });
    },
    disabledEmployeeIds() {
      // disable employees belonging to other team with schedule
      if (!this.isVariableHoursActiveForTeam) {
        return [];
      }

      return this.teamEmployees
        .filter(employee => {
          const employeeTeamIds = this.employeeTeamIds(employee.id);
          return !employeeTeamIds.includes(this.team.id) &&
            this.employeeWithTeamScheduleIds.includes(employee.id);
        })
        .map(employee => employee.id)
        .concat(this.teammateIds); // TODO: delete when removing user from team with schedule is allowed
    },
    trackingOptions() {
      return {
        submit: 'team_employee_submit',
      };
    },
    deleteTeamClasses() {
      return {
        'team-actions__menu-item': true,
        'team-actions__menu-item--disabled': this.disabled,
      };
    },
    updateClasses() {
      return {
        'team-actions__menu-item': true,
        'team-actions__menu-item--disabled': !this.isVariableHoursActiveForTeam || this.disabled,
      };
    },
    isVariableHoursActiveForTeam() {
      // Toggle is active if there is at least one team schedule without end date
      return this.teamSchedules &&
        this.teamSchedules.length > 0 &&
        !this.teamSchedules.every(({ attributes }) => attributes.endDate);
    },
    teamSchedules() {
      return this.teamSchedulesForTeam(this.team.id);
    },
    teamSchedule() {
      return this.lastTeamScheduleForTeam(this.team.id);
    },
    teamScheduleWeekCycle() {
      return this.teamSchedule.attributes.weekCycle;
    },
    employeeWithTeamScheduleIds() {
      return this.teams
        .filter(team => this.teamSchedulesForTeam(team.id).length > 0)
        .flatMap(team => team.relationships.users)
        .map(user => user.id);
    },
    disabledSwitchTooltip() {
      if (this.isTeamScheduleDisabled) {
        return this.$t('shop_settings.tabs.teams.table.tooltips.employee_with_team_schedule');
      }
      return '';
    },
    teamEmployeesOptions() {
      return this.teamEmployees.map(employee => {
        employee.text = this.fullName(employee);
        return employee;
      });
    },
  },
  methods: {
    ...mapActions('shopTeams', ['deleteTeamSchedule', 'disableTeamSchedule']),
    updateTeamName() {
      if (this.localTeam.name === '') {
        this.erroredUpdate = true;
        this.errorMessage = this.$t('shop_settings.tabs.teams.table.fields.name.empty_name_error');
      } else if (this.localTeam.name === this.teamName) {
        this.erroredUpdate = false;
      } else if (this.isNameAlreadyUsed(this.localTeam.name)) {
        this.erroredUpdate = true;
        this.errorMessage = this.$t('shop_settings.tabs.teams.table.fields.name.duplicate_name_error');
      } else if (this.localTeam.name !== null) {
        this.erroredUpdate = false;
        if (this.team.id) {
          this.$emit('update-team', { id: this.team.id, team: { name: this.localTeam.name } });
        } else {
          this.$emit('create-team', { name: this.localTeam.name, shop_id: this.currentShop.id });
        }
      }
    },
    employeeTeamIds(employeeId) {
      return this.teams
        .filter(team => team.id && team.relationships.users.find(user => user.id === employeeId))
        .map(team => team.id);
    },
    updateTeammates() {
      const currentTeamUserIds = this.employeesIds.active.sort();
      const newTeamUserIds = [...this.localTeam.user_ids].sort();
      const noChange = JSON.stringify(currentTeamUserIds) === JSON.stringify(newTeamUserIds);
      if (noChange) return;

      // Re-add archived team users in case of user is unarchived
      const allTeamUserIds = this.localTeam.user_ids.concat(this.employeesIds.archived);

      if (!this.isVariableHoursActiveForTeam) {
        this.$emit('update-team', { id: this.team.id, team: { user_ids: allTeamUserIds } });
      } else {
        const params = {
          newTeamUserIds,
          currentTeamUserIds,
          allTeamUserIds,
          team: this.team,
        };
        this.$emit('manage-variable-hours', params);
      }
    },
    deleteTeam() {
      this.$emit('delete-team', this.team);
    },
    updateVariableContractHoursSettings() {
      if (this.teamScheduleLoading) return;

      this.$emit('manage-variable-hours', {
        team: this.team,
        allTeamUserIds: this.employeesIds.active.concat(this.employeesIds.archived),
      });
      this.$nextTick(() => {
        this.$refs.teamActionsDropdown.hide();
      });
    },
    disabledSelectTooltip(employeeId) {
      const isEmployeeAlreadyInTeam =
        this.team.relationships.users?.map(user => user.id).includes(employeeId);

      if (!isEmployeeAlreadyInTeam &&
        this.employeeWithTeamScheduleIds.includes(employeeId) &&
        this.isVariableHoursActiveForTeam) {
        return this.$t('shop_settings.tabs.teams.table.tooltips.in_team_schedule');
      }
      return '';
    },
    toggleVariableHoursForTeam() {
      if (this.teamScheduleLoading) return;

      if (this.isVariableHoursActiveForTeam) {
        this.$root.$emit('confirm', event, {
          title: this.$t('shop_settings.tabs.teams.table.fields.delete_variable_hours.confirmation'),
          onConfirm: () => {
            const params = {
              id: this.teamSchedule.id,
              shop_id: this.currentShop.id,
            };
            this.$skAnalytics.track('team_rotation_disable');

            if (skDate(this.teamSchedule.attributes.startDate).utc().isAfter(skDate().utc(true))) {
              // If team schedule is in future, delete it
              this.deleteTeamSchedule(params)
                .then(() => {
                  this.$skToast({
                    message: this.$t(
                      'shop_settings.tabs.teams.table.fields.delete_variable_hours.success',
                    ),
                    variant: 'success',
                  });
                })
                .catch(() => {
                  this.$skToast({
                    message: this.$t('errors.standard_message'),
                    variant: 'error',
                  });
                });
            } else {
              // Else disable it
              params.team_id = this.team.id;
              params.team_schedule = {
                end_date: skDate().endOf('isoWeek').format('YYYY-MM-DD'),
              };
              this.disableTeamSchedule(params)
                .then(() => {
                  this.$skToast({
                    message: this.$t(
                      'shop_settings.tabs.teams.table.fields.delete_variable_hours.success',
                    ),
                    variant: 'success',
                  });
                })
                .catch(() => {
                  this.$skToast({
                    message: this.$t('errors.standard_message'),
                    variant: 'error',
                  });
                });
            }
          },
        });
      } else {
        this.$skAnalytics.track('team_rotation_enable');

        this.$emit('manage-variable-hours', {
          team: this.team,
          allTeamUserIds: this.employeesIds.active.concat(this.employeesIds.archived),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teams__table__row {
  border-bottom: 1px solid $sk-grey-10;
  height: 78px;

  .teams__table__cell {
    width: 310px;
    padding-right: 30px;

    &--flex {
      display: flex;
      height: inherit;
      align-items: center;
      border-bottom: none;
    }

    &--tag {
      display: flex;
      flex-direction: column;
    }

    &--last {
      text-align: right;
    }
  }
}

// Shrink tooltip
::v-deep .sk-popover--tooltip {
  padding: 5px 8px;
  font-size: $fs-text-s;
  text-align: center;
  width: 155px;

  * {
    margin: 0;
    padding: 0;
  }
}

.team-actions__menu {
  width: fit-content;
  background: white;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
}

.team-actions__menu-item {
  padding: 9px 17px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 17px;
  }

  &:hover {
    background: $sk-grey-5;
  }

  &--disabled {
    color: #c3c7ca;
    cursor: not-allowed;

    span {
      pointer-events: none;
    }
  }
}
</style>
