<template>
  <div class="churn-shop-line">
    <SkCollapse
      @open="$emit('toggle-line')"
      @close="$emit('toggle-line')"
    >
      <template slot="labelLeft">
        <div class="churn-shop-line__header-left">
          <SkMedallion
            icon="ShopIcon"
            background-color="#d9e6ff"
            color="#2b66fe"
            size="large"
          />
          <div class="churn-shop-line__shop-name">
            {{ shop.name }}
          </div>
        </div>
      </template>
      <template
        v-if="!shop.legacy"
        slot="labelRight"
      >
        <SkTag
          class="churn-shop-line__renewal-date"
          variant="green"
        >
          <div class="churn-shop-line__renewal-date__tag">
            {{ this.$t('organisation_settings.churn_request.tag', {date: shop.renewalDate}) }}
          </div>
        </SkTag>
      </template>
      <template slot="content">
        <!-- eslint-disable vue/no-v-html-->
        <div
          class="churn-shop-line__body"
          v-html="$tc(`organisation_settings.churn_request.${shopTextKey}`,shopTextVariables.cancellationCutoffPeriod ,shopTextVariables)"
        />
        <!-- eslint-enable vue/no-v-html-->
      </template>
    </SkCollapse>
  </div>
</template>

<script>
import {
  SkCollapse,
  SkMedallion,
  SkTag,
} from '@skelloapp/skello-ui';

export default {
  name: 'ChurnShopLine',
  components: {
    SkCollapse,
    SkMedallion,
    SkTag,
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  computed: {
    commitmentKey() {
      return this.$t(
        `organisation_settings.churn_request.commitment.${this.commitmentValue}`,
      );
    },
    shopTextVariables() {
      return this.shop.legacy ? {} : {
        commitment: this.commitmentKey,
        renewalDate: this.shop.renewalDate,
        cancellationCutoffPeriod: this.shop.cancellationCutoffPeriod,
        cancellationRenewalDate: this.shop.cancellationRenewalDate,
      };
    },
    commitmentValue() {
      return this.shop.commitment.replaceAll(' ', '_').toLowerCase();
    },
    shopTextKey() {
      return this.shop.legacy ? 'generic_shop_text' : 'shop_text';
    },
  },
};
</script>

<style lang="scss" scoped>
.churn-shop-line {
  border: 1px solid $sk-grey-10;
  border-radius: 8px;
  margin-bottom: 16px;

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__shop-name {
    font-size: $fs-heading-s;
    font-weight: $fw-semi-bold;
    line-height: 22px;
    padding-left: 8px;
  }

  &__renewal-date {
    margin-right: 16px;

    &__tag {
      font-size: $fs-text-m;
    }
  }

  &__body {
    padding: 0 16px 16px;
    font-size: $fs-text-l;
    line-height: 18px;
    color: $sk-grey-50;
  }
}
</style>

<style lang="scss">
.shop-text-variable {
  color: $sk-black;
  font-weight: $fw-semi-bold;
}
</style>
