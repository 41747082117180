<template>
  <div class="profile-completion-notification">
    <ScrollV2Icon
      class="profile-completion-notification__icon"
      width="16"
      height="16"
      fill="#8b45ff"
    />
    <span class="profile-completion-notification__text">
      {{ $t('employees.profile_completion_notification.warning') }}
    </span>
    <div class="profile-completion-notification__button">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileCompletionNotification',
};
</script>

<style lang="scss" scoped>
.profile-completion-notification {
  display: flex;
  padding: 8px;
  background-color: $sk-info-10;
  border-radius: 4px;
  height: 53px;
  gap: 8px;

  &__text {
    display: flex;
    align-items: center;
    line-height: 18px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__button {
    display: flex;
    align-items: center;
    color: $sk-black;
    cursor: pointer;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-m;
    width: 65px;
    flex-shrink: 0;
    justify-content: center;
  }
}
</style>
