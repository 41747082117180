<!-- eslint-disable max-len -->
<template>
  <div class="skeleton-section">
    <LineSkeleton
      class="settings-detail__skeleton--subtitle"
      emphasized
    />
    <div class="skeleton-section__row">
      <span class="skeleton-section__label">
        <LineSkeleton class="settings-detail__skeleton--label-double" />
      </span>
      <span class="skeleton-section__single-input">
        <LineSkeleton class="settings-detail__skeleton--input-medium" />
      </span>
    </div>
    <div class="skeleton-section__row">
      <span class="skeleton-section__label">
        <LineSkeleton class="settings-detail__skeleton--label-double" />
      </span>
      <span>
        <LineSkeleton class="settings-detail__skeleton--input-large" />
      </span>
    </div>
    <div class="skeleton-section__cta">
      <LineSkeleton class="settings-detail__skeleton--input-medium" />
    </div>
  </div>
</template>

<script>
import LineSkeleton from '../../../shared/components/LineSkeleton';

export default {
  name: 'ComplementaryHoursSkeleton',
  components: {
    LineSkeleton,
  },
};
</script>

<style lang="scss" scoped>
@use '../../../shared/scss/skeleton';
</style>
