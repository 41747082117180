<template>
  <!-- eslint-disable max-len -->
  <svg
    width="32"
    height="32"
    viewBox="0 0 13 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.31713 9.01031L4.24748 4.02344L1.30324 6.99724C0.0025737 8.31268 0.0114419 10.3967 1.32689 11.7003L4.01691 14.3637L9.31713 9.01031Z"
      fill="#459FFF"
    />
    <path
      d="M4.25 4.02531L9.31965 9.01515L11.6431 6.66803L11.8914 6.41677C13.2837 5.00672 13.2719 2.76603 11.8648 1.37077C10.4577 -0.0244944 8.21408 -0.0126702 6.82177 1.39737L4.25 4.02531Z"
      fill="#2b66fe"
    />
    <path
      d="M9.31317 9.01562L4.04547 14.3365L1.68949 16.7162C0.294227 18.1233 0.306051 20.3669 1.71314 21.7622C3.12022 23.1574 5.36387 23.1456 6.75914 21.7385L12.0268 16.4176C13.3275 15.1022 13.3186 13.0181 12.0032 11.7145L9.31317 9.01562Z"
      fill="#2e26f2"
    />
  </svg>
</template>

<script>
export default {
  name: 'SkelloLogo',
};
</script>
