<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M9.94918 2.69624L13.2039 5.95097L4.96525 14.1897L1.71234 10.9349L9.94918 2.69624ZM15.6737 1.91128L14.2222 0.459778C13.6612 -0.101176 12.7504 -0.101176 12.1875 0.459778L10.7971 1.85017L14.0519 5.10493L15.6737 3.48312C16.1088 3.04801 16.1088 2.34635 15.6737 1.91128ZM0.00905722 15.5097C-0.0501752 15.7763 0.190506 16.0152 0.457112 15.9503L4.08399 15.071L0.831081 11.8162L0.00905722 15.5097Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 16px; // ie11 support
  width: initial;
  height: 16px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 16px; // edge support
    height: 16px; // edge support
  }
}
</style>
