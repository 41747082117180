<template>
  <div
    class="weeks-planning__wrapper"
    :style="zoomRange.planningWrapper"
  >
    <!-- To be removed -->
    <LoadingPage v-if="shouldShowLoadingPage" />
    <!--  -->
    <WeeklySkeleton v-else-if="shouldShowSkeleton" />
    <template v-else-if="shouldShowTemplate">
      <Header />
      <AutomaticPlanningLoader />
      <router-view :key="$route.fullPath" />
    </template>
    <!--
      This component is used to display quick actions on shift
      We need it here to be outside the inner planning component (which has an hidden overflow)
      Otherwise, the quick add won't be visible over Header or counter columns
    -->
    <ShiftMenu />
    <ShiftAlerts />
    <CounterWeekDetail />
    <CounterMajDetail />
    <AnnualizationDetail />

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <PublishPlanningModalWeekly />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import { zoomPlanningWeek } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';
import { computeNumberOfUsersToFetch } from '@app-js/plannings/shared/utils/planning_helpers';

import LoadingPage from '@app-js/plannings/shared/components/LoadingPage';
import WeeklySkeleton from '@app-js/plannings/shared/components/Skeleton/Weekly';
import ShiftMenu from '@app-js/plannings/shared/components/Shift/menu';
import ShiftAlerts from '@app-js/plannings/shared/components/Shift/Alerts';
import AnnualizationDetail from '@app-js/plannings/shared/components/Counter/AnnualizationDetail';
import CounterWeekDetail from '@app-js/plannings/shared/components/Counter/CounterWeekDetail';
import CounterMajDetail from '@app-js/plannings/shared/components/Counter/CounterMajDetail';
import AutomaticPlanningLoader from '@app-js/plannings/shared/components/AutomaticPlanningSidePanel/AutomaticPlanningLoader';
import PublishPlanningModalWeekly from '@app-js/plannings/shared/components/Toolbar/PublishPlanningModal/ModalWeekly';

import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';
import Header from './Header';

export default {
  name: 'Weeks',
  components: {
    PublishPlanningModalWeekly,
    LoadingPage,
    AnnualizationDetail,
    CounterWeekDetail,
    CounterMajDetail,
    Header,
    ShiftMenu,
    ShiftAlerts,
    WeeklySkeleton,
    AutomaticPlanningLoader,
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('annualization', ['annualizationMonday', 'annualizationPeriod']),
    ...mapGetters('planningsState', [
      'isPlanningLoading',
      'isPostesView',
      'monday',
      'sunday',
      'currentDate',
    ]),
    ...mapGetters('currentShop', ['isAnnualizedWorkingTimeAvailable']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    ...mapGetters('planningsAutomaticPlanning', [
      'isBrainFeatureFlagActive',
      'isPlanningElligibleForBrain',
    ]),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapState('planningsLoading', [
      'isGlobalDataLoading',
      'isPlanningDataLoading',
      'abortRecursiveBatching',
      'arePlanningDataBatchesLoading',
      'lastFetchParams',
      'planningDataStatus',
    ]),
    shopId() {
      // TODO : set shopId in planningState from this param
      return this.$route.params.shop_id;
    },
    zoomRange() {
      const { planningWrapper } = zoomPlanningWeek(this.planningZoom);
      return { planningWrapper };
    },
    amountOfUsersToFetch() {
      const zoomFactor = this.planningZoom / 100;
      const baseRowHeight = 32;
      const availableScreenHeight = window.innerHeight || 0;
      // needs to add here a variable to fetch more users if the screen is bigger
      return computeNumberOfUsersToFetch({
        height: availableScreenHeight,
        rowHeight: baseRowHeight * zoomFactor,
      });
    },
    shouldShowLoadingPage() {
      return !this.isProgressiveLoadingEnabled && this.isPlanningDataLoading;
    },
    shouldShowSkeleton() {
      return this.isProgressiveLoadingEnabled && (
        this.planningDataStatus === PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED ||
        // In postes view(in weekly), we need to wait for all data to be loaded before showing the skeleton
        (this.isPostesView && this.planningDataStatus === PLANNING_DATA_STATUS.LOADING_BATCHES)
      );
    },
    shouldShowTemplate() {
      return !this.isLoadingInitialData;
    },
  },
  watch: {
    $route(to, from) {
      const hasDateChanged = to.query.date !== from.query.date;

      // If the date has changed, we can re-init the week here
      if (hasDateChanged) {
        if (this.isProgressiveLoadingEnabled && !this.scrollingListenerAlreadyExists()) {
          this.$root.$once('scrolling', this.handleScroll);
        }
        this.initWeek();
      }
    },
    abortRecursiveBatching(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.initWeek();
      }
    },
    isPlanningLoading(newValue) {
      if (newValue === false) {
        const { name, path, fullPath } = this.$route;
        const measure = performance?.measure?.(path, name);

        if (!measure) {
          return;
        }

        this.$skAnalytics.track('page_load_time_planning_week', {
          duration: Math.round(measure.duration),
          fullPath,
          shopId: this.currentUser.id,
          userId: this.currentShop.id,
        });
      }
    },
    isPostesView(newValue, oldValue) {
      // When moving from Employees tab to Positions, trigger the recursive batching
      // only if the data is not already loaded or being loaded currently
      if (
        newValue && !oldValue &&
        this.planningDataStatus !== PLANNING_DATA_STATUS.ALL_LOADED &&
        this.planningDataStatus !== PLANNING_DATA_STATUS.LOADING_BATCHES
      ) {
        this.triggerRecursiveBatching();
      }
    },
  },
  mounted() {
    if (this.isProgressiveLoadingEnabled && !this.scrollingListenerAlreadyExists()) {
      this.$root.$once('scrolling', this.handleScroll);
    }
    const redirectMessage = sessionStorage.getItem('adminOnboardingMessage');
    if (redirectMessage) {
      sessionStorage.removeItem('adminOnboardingMessage');

      this.$skToast({
        message: redirectMessage,
        variant: 'success',
      });
    }
  },
  created() {
    if (this.$router.currentRoute.query.unlock_week === 'true' && this.isSystemAdmin) {
      this.unlockWeek();
    } else {
      this.initWeek();
    }
  },
  methods: {
    ...mapActions('annualization', ['fetchShopAnnualizationConfig']),
    ...mapActions('planningsLoading', ['fetchBatchablePlanningShopDateRangeData', 'fetchWeeklyBatch', 'fetchBatchableDataRecursively']),
    ...mapActions('planningsState', ['validatePeriod']),
    ...mapMutations('annualization', ['setPeriodAt']),
    ...mapMutations('planningsState', ['setAsSelectedTab', 'closeTotalPeriodTab']),
    ...mapMutations('planningsPostes', ['resetAbsencesForQuickAdd']),
    ...mapMutations('planningsLoading', ['setAbortRecursiveBatching', 'setPlanningDataStatus']),
    initWeek() {
      this.closeTotalPeriodTab();

      if (this.arePlanningDataBatchesLoading || this.isPlanningDataLoading) {
        this.setAbortRecursiveBatching(true);
        this.setPlanningDataStatus(PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
        return;
      }
      // reset total / counters tab default open tap
      this.setAsSelectedTab('total');

      const fetchParams = {
        shopId: this.shopId,
        period: 'week',
        fetchDayRateUsersDaysWorked: true,
      };

      if (this.isProgressiveLoadingEnabled) {
        fetchParams.shouldFetchByBatches = !this.isPostesView;
        fetchParams.batchSize = this.amountOfUsersToFetch;
      }

      this.fetchBatchablePlanningShopDateRangeData(fetchParams)
        .then(() => {
          this.$emit('planning-loaded');

          if (this.isBrainFeatureFlagActive && this.isPlanningElligibleForBrain) {
            this.emitOnRoot('set-schedule-recommendation-popover-visibility', {
              show: true,
              initiator: 'empty_schedule',
            });
          }
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
          throw error;
        });

      if (
        this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.currentShop.attributes.isAnnualizationV2Active
      ) {
        // annualization data is fetched on app/javascript/src/v3/app/plannings/Plannings.vue
        // so the store is already filled with the proper data here
        const previousPeriod = this.annualizationPeriod;

        this.setPeriodAt(this.annualizationMonday.toDate());

        if (!previousPeriod.startDate.isSame(this.annualizationPeriod.startDate)) {
          try {
            this.fetchShopAnnualizationConfig({
              shopId: this.shopId,
              date: this.annualizationMonday.format(),
            });
          } catch (error) {
            this.makeErrorToast();

            throw error;
          }
        }
      }

      // Product need
      // Quick add absences need to be reseted when changing week
      if (this.isPostesView) {
        this.resetAbsencesForQuickAdd();
      }
    },
    handleScroll() {
      this.triggerRecursiveBatching();
    },
    triggerRecursiveBatching() {
      this.$root.$off('scrolling');

      const { shopId, period, fetchDayRateUsersDaysWorked,
        batchSize, isLastUsersBatch } = this.lastFetchParams;

      this.fetchBatchableDataRecursively({
        shopId,
        period,
        fetchDayRateUsersDaysWorked,
        batchSize,
        usersBatchPage: 2,
        isLastUsersBatch,
      });
    },
    unlockWeek() {
      // If URL has unlock_week param set to true and user is admin
      // -> unlock planning then fetch data
      this.$skAnalytics.track('unlock_week');

      this.validatePeriod({
        startDate: this.monday,
        endDate: this.sunday,
        currentDate: this.currentDate,
        shopId: this.currentShop.id,
        validationLevel: 'intermediate_locked_days',
        validationValue: false,
      }).then(() => {
        const query = { ...this.$route.query };
        delete query.unlock_week;
        this.$router.replace({ query });

        this.$skToast({
          message: this.$t('plannings.table.header.actions.week_validation.unvalidate.success'),
          variant: 'success',
        });

        this.initWeek();
      }).catch(() => {
        this.$skToast({
          message: this.$t('plannings.table.header.actions.week_validation.unvalidate.error'),
          variant: 'error',
        });
      });
    },
    scrollingListenerAlreadyExists() {
      // eslint-disable-next-line no-underscore-dangle
      return this.$root._events?.scrolling && this.$root._events?.scrolling.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.weeks-planning__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: -1px;
}

// This :after fakes a shadow on left side only for all planning-row sidebar cells
// a regular shadow is impossible to be displayed on only one side
// deep is needed as it is common class on header and planning table sidebar cells
::v-deep .cell--shadow::after {
  content: " ";
  width: 14px;
  position: absolute;
  top: 0;
  height: 100%;
  opacity: .12;
  z-index: -1; // put the shadow under the cell's content
}

::v-deep .cell--shadow-right::after {
  background: linear-gradient(to right, rgba(0, 0, 0, .4), #fff);
  right: -14px;
}

::v-deep .cell--shadow-left::after {
  background: linear-gradient(to left, rgba(0, 0, 0, .4), #fff);
  left: -14px;
}
</style>
