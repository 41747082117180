import { capitalize } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';
import {
  utils,
  writeFile,
} from 'xlsx';
import {
  visibleDaysInPeriod, frenchDateFormatter,
} from './workload_plan_excel_helper';

const generatedFileName = (startPeriod, endPeriod, shopName, sheetName) => {
  const period = `${frenchDateFormatter(startPeriod)}_${frenchDateFormatter(endPeriod)}`;

  return `${shopName}_${period}_${sheetName}.xlsx`;
};

/**
 * Add the period in A1 and A2 cell
 */
const addPeriodToWorksheet = (worksheet, startPeriod, endPeriod) => {
  const periodCellContent = `{${frenchDateFormatter(startPeriod)}} - {${frenchDateFormatter(endPeriod)}}`;
  utils.sheet_add_aoa(worksheet, [[periodCellContent]], { origin: 'A1' });

  // Merge A1 and A2 ([col 0, row 0] to [col 1, row 0])
  worksheet['!merges'].push(
    { s: { c: 0, r: 0 }, e: { c: 1, r: 0 } },
  );

  // -8 to reduce a bit the column
  worksheet['!cols'].push({ wch: periodCellContent.length - 8 });
  worksheet['!cols'].push({ wch: 7 });
};

/**
 * Add all the postes in line starting from C1
 */
const addPostesToWorksheet = (worksheet, postes) => {
  const posteNames = postes
    .map(poste => poste.attributes.name)
    .sort((posteA, posteB) => posteA.toLowerCase().localeCompare(posteB.toLowerCase()));

  utils.sheet_add_aoa(worksheet, [posteNames], { origin: 'C1' });

  posteNames.forEach(name => {
    worksheet['!cols'].push({ wch: Math.max(7, name.length) });
  });
};

/**
 * Add all the days and hour quarters on columns A and B
 */
const addDaysAndQuarterHoursToWorksheet = (worksheet, days, openingHours) => {
  let row = 1;
  // Need array of array to be able to easily add hours quarter as column
  const openingHoursFormatted = openingHours.map(hour => [hour]);

  days.forEach(day => {
    const formattedDay = day.format('dddd, DD/MM/YYYY');

    // Add the day cell with the value in column A
    utils.sheet_add_aoa(worksheet, [[formattedDay]], { origin: `A${row + 1}` });
    // Add all the hour quarter in column starting from the day cell row in column B
    utils.sheet_add_aoa(worksheet, openingHoursFormatted, { origin: `B${row + 1}` });

    // Merge all the cells on column A except the one with the date in it
    // (so from row + 1 to row + nb of hourQuarters -1)
    worksheet['!merges'].push(
      { s: { c: 0, r: row + 1 }, e: { c: 0, r: row + openingHours.length - 1 } },
    );

    row += openingHours.length;
  });
};

export const generateWorkloadPlanFile = ({
  period,
  shopName,
  sheetName,
  postes,
  visibleDays,
  hourQuarters,
}) => {
  const workbook = utils.book_new();

  const startPeriod = skDate.utc(period[0]);
  const endPeriod = skDate.utc(period[1]);

  /* Create the first sheet */
  const worksheet = utils.json_to_sheet([{}]);
  worksheet['!merges'] = [];
  worksheet['!cols'] = [];

  addPeriodToWorksheet(worksheet, startPeriod, endPeriod);
  addPostesToWorksheet(worksheet, postes);

  const allDays = visibleDaysInPeriod(startPeriod, endPeriod, visibleDays);
  addDaysAndQuarterHoursToWorksheet(worksheet, allDays, hourQuarters);

  utils.book_append_sheet(workbook, worksheet, capitalize(sheetName));

  /* Download the file */
  return Promise.resolve(
    writeFile(
      workbook,
      generatedFileName(startPeriod, endPeriod, shopName, sheetName),
      { compression: true },
    ),
  );
};
