import store from '@app-js/shared/store';
import { FEATURES } from '@app-js/shared/constants/features';

/**
 * This function is used to prevent user access to a specific path based on the shop's active features
 *
 * @param {string} pathName The name of the path to prevent access to
 */
export const isRouteAllowed = pathName => {
  const shopId = store.state.currentShop.currentShop.id;
  const isFeatureEnabled = feature => store.getters['features/isFeatureEnabled'](feature, shopId, () => true);
  const areFeaturesEnabled = features => store.getters['features/areFeaturesEnabled'](features, shopId, () => true);

  switch (pathName) {
    // PLANNINGS
    case 'plannings': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_days': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_weeks_employees': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_weeks_postes': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_months': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_employee': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_employee_all': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'plannings_employee_team_schedule': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);

    // BADGINGS
    case 'badgings': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'badgings_days': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'badgings_shifts_days': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'badgings_absences_days': return areFeaturesEnabled([FEATURES.FEATURE_TIMECLOCK, FEATURES.FEATURE_ABSENCES]);
    case 'badgings_users': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'badgings_shifts_users': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'badgings_absences_users': return areFeaturesEnabled([FEATURES.FEATURE_TIMECLOCK, FEATURES.FEATURE_ABSENCES]);
    case 'badgings_history': return areFeaturesEnabled([FEATURES.FEATURE_TIMECLOCK, FEATURES.FEATURE_PLANNING]);

    // ANALYTICS
    case 'analytics_dashboard': return isFeatureEnabled(FEATURES.FEATURE_ANALYTICS);

    // DASHBOARD
    case 'home_dashboard': return isFeatureEnabled(FEATURES.FEATURE_DASHBOARD);

    // EMPLOYEES
    case 'users': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_personal': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_documents': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_counters': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_day_rate': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_holidays': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_hours': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_paid_leave': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_shift_history': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_rtt': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_annualization': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_hr_file': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_contracts_history': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_contracts': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_contracts_future': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_full_contract_new': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_full_contract_show': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_full_contract_edit': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);
    case 'user_full_contract_version': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEES);

    // REPORTS
    case 'reports': return isFeatureEnabled(FEATURES.FEATURE_REPORT);

    // ADMIN TEMPLATES
    case 'text_document_templates_new': return isFeatureEnabled(FEATURES.FEATURE_ADMIN_DOCUMENTS_TEMPLATES);
    case 'text_document_templates_edit': return isFeatureEnabled(FEATURES.FEATURE_ADMIN_DOCUMENTS_TEMPLATES);
    case 'text_document_new': return isFeatureEnabled(FEATURES.FEATURE_ADMIN_DOCUMENTS_TEMPLATES);

    // REQUESTS
    case 'requests': return areFeaturesEnabled([FEATURES.FEATURE_AVAILABILITIES, FEATURES.FEATURE_USER_REQUESTS]);
    case 'leave_requests': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);
    case 'leave_requests_own': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);
    case 'leave_requests_teams': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);
    case 'availability_requests': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);
    case 'availability_requests_own': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);
    case 'availability_requests_teams': return isFeatureEnabled(FEATURES.FEATURE_USER_REQUESTS);

    // PROFILE
    case 'profile': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);
    case 'profile_information': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);
    case 'profile_hr_file': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);
    case 'profile_documents': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);
    case 'profile_notifications': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);
    case 'profile_calendar': return isFeatureEnabled(FEATURES.FEATURE_PROFILE);

    // ORGANISATION SETTINGS
    case 'organisation_settings': return true;
    case 'organisation_settings_shops': return true;
    case 'organisation_settings_shops_new_form': return true;
    case 'organisation_settings_shops_new_billing_form': return true;
    case 'organisation_settings_clusters': return true;
    case 'organisation_settings_licenses': return true;
    case 'organisation_settings_partners_tools': return isFeatureEnabled(FEATURES.FEATURE_PARTNER_TOOLS);
    case 'organisation_settings_pay_partners': return true;
    case 'organisation_settings_bi_partners': return true;
    case 'organisation_settings_advance_payment_partners': return true;
    case 'churn_request': return true;

    // SHOP SETTINGS
    case 'shop_settings': return true;
    case 'shop_settings_rules': return true;
    case 'shop_settings_main_rules': return true;
    case 'shop_settings_counter_rules': return isFeatureEnabled(FEATURES.FEATURE_TRACKERS);
    case 'shop_settings_alerts': return isFeatureEnabled(FEATURES.FEATURE_ALERTS);
    case 'shop_settings_report_rules': return isFeatureEnabled(FEATURES.FEATURE_REPORT);
    case 'shop_settings_absences': return isFeatureEnabled(FEATURES.FEATURE_ABSENCES);
    case 'shop_settings_postes': return isFeatureEnabled(FEATURES.FEATURE_EMPLOYEE_POSITIONS);
    case 'shop_settings_shop': return true;
    case 'shop_settings_teams': return true;
    case 'shop_settings_documents_management': return isFeatureEnabled(FEATURES.FEATURE_ADMIN_DOCUMENTS_TEMPLATES);
    case 'shop_settings_automatic_planning': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'automatic_planning_rules': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'automatic_planning_competencies': return isFeatureEnabled(FEATURES.FEATURE_PLANNING);
    case 'shop_settings_punch_clock': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);
    case 'shop_settings_details': return true;
    case 'shop_settings_details_annualization': return isFeatureEnabled(FEATURES.FEATURE_TRACKERS);

    // TIMECLOCK ONBOARDING
    case 'timeclock_onboarding': return isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK);

    // DEFAULT
    // When new routes are created, they should be added and allowed based on a feature
    // By default, all other routes are blocked if we are using the new bundle system
    default: return !store.getters['features/isBundleSystemActive'](shopId);
  }
};
