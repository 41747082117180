<template>
  <InnerColumn
    :title="month.date.format('MMM')"
    :show-asterisk="month.isAcquisitionMonthStart"
  >
    <TableCell
      :class="cellClasses"
      :data="roundToTwoDecimals(month.acquired)"
      :show-dash="month.showDash || !month.acquired"
      color="#000000"
      height="50px"
    />
    <TableCell
      :class="cellClasses"
      :data="roundToTwoDecimals(month.taken)"
      :show-dash="month.showDash"
      color="#000000"
      height="50px"
    />
    <TableCell
      :class="cellClasses"
      :data="roundToTwoDecimals(month.manualChanges)"
      :editable="canReadPaidVacationCounter"
      :show-dash="month.showDash"
      color="#000000"
      height="50px"
      show-number-sign
      @click="openModal"
    />
    <TableCell
      :class="cellClasses"
      :data="roundToTwoDecimals(month.balance)"
      :show-dash="month.showDash"
      color="#000000"
      height="50px"
    />
  </InnerColumn>
</template>

<script>
import { mapGetters } from 'vuex';
import { roundFloat } from '@skello-utils/formatting/numbers';
import {
  InnerColumn,
  TableCell,
} from '../../shared/components';

export default {
  name: 'RttCounterMonthColumn',
  components: {
    InnerColumn,
    TableCell,
  },
  props: {
    month: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canReadPaidVacationCounter']),
    cellClasses() {
      return {
        'employee-counters__column--previsional': this.month.isPrevisional,
      };
    },
  },
  methods: {
    openModal() {
      if (this.month.showDash || !this.canReadPaidVacationCounter) return;

      this.$emit('edit', this.month);
    },
    roundToTwoDecimals(float) {
      return roundFloat(float, 2);
    },
  },
};
</script>
