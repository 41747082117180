<template>
  <AvailabilitiesCorner
    v-if="dayCellHasShifts"
    :availabilities="availabilities"
    :is-last-visible-day="isLastVisibleDay"
  />
  <div
    v-else
    class="day-cell__availabilities-full__wrapper"
  >
    <div
      v-if="filteredUnavailabilities.length > 0"
      class="day-cell__availability-full day-cell__availability-full--unavailabilities"
    >
      <div
        v-for="availability in filteredUnavailabilities"
        :key="availability.id"
        class="day-cell__availabilities-full__label"
      >
        <CrossedCalendarIcon
          width="9"
          height="9"
          fill="#d03e50"
        />
        <span>{{ availabilityText(availability) }}</span>
      </div>
    </div>

    <div
      v-if="filteredAvailabilities.length > 0"
      class="day-cell__availability-full day-cell__availability-full--availabilities"
    >
      <div
        v-for="availability in filteredAvailabilities"
        :key="availability.id"
        class="day-cell__availabilities-full__label"
      >
        <CheckedCalendarIcon
          width="9"
          height="9"
          fill="#188377"
        />
        <span>{{ availabilityText(availability) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AvailabilitiesCorner from '@app-js/plannings/shared/components/Availabilities/AvailabilitiesCorner';

export default {
  name: 'Availabilities',
  components: {
    AvailabilitiesCorner,
  },
  props: {
    dayCellHasShifts: {
      type: Boolean,
      required: true,
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    isLastVisibleDay: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    filteredAvailabilities() {
      return this.availabilities.filter(({ attributes }) => attributes.status === 'available');
    },
    filteredUnavailabilities() {
      return this.availabilities.filter(({ attributes }) => attributes.status === 'unavailable');
    },
  },
  methods: {
    availabilityText(availability) {
      const { startTime, endTime } = availability.attributes;
      return this.$tc(
        'plannings.table.cells.availabilities.text',
        this.isAvailableType(availability),
        { startTime, endTime },
      );
    },
    isAvailableType(availability) {
      return availability.attributes.status === 'available';
    },
  },
};
</script>

<style lang="scss" scoped>
.day-cell__availabilities-full__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.day-cell__availabilities-full__label {
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
  }
}

.day-cell__availability-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1em;

  &--availabilities {
    color: $sk-success;
    background-color: rgba($sk-success-10, .5);
  }

  &--unavailabilities {
    color: $sk-error;
    background-color: rgba($sk-error-10, .5);
  }
}
</style>
