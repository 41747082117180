<template>
  <div class="price-header__wrapper">
    <div class="price-header__left-content">
      <div class="price-header__logo-btn">
        <SkLargeS
          class="price-header__logo"
          fill="#FEBF3F"
        />
      </div>
      <div class="price-header__title-wrapper">
        <div class="price-header__title">
          {{ $t('plannings.toolbar.modal.esignature_upsell.price_header.title') }}
        </div>
      </div>
    </div>

    <div>
      <SkCircleButton
        v-tooltip
        :title="$t('actions.close')"
        icon="CrossV2Icon"
        size="small"
        @click="handleHeaderClose"
      />
    </div>
  </div>
</template>

<script>
import { SkLargeS } from '@app-js/shared/assets/svg/branding';

export default {
  name: 'PriceHeader',
  components: { SkLargeS },
  methods: {
    handleHeaderClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.price-header__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-header__left-content {
  display: flex;
}

.price-header__logo-btn {
  margin-right: 8px;
  height: 32px;
  width: 32px;
  background: rgba(254, 191, 63, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.price-header__logo {
  height: 16px;
  width: 45px;
}

.price-header__title-wrapper {
  margin: auto;
}

.price-header__title {
  font-size: $fs-heading-xs;
  font-weight: $fw-semi-bold;
  color: #febf3f;
}

.price-header__tag {
  font-size: $fs-text-xxs;
  line-height: 5px;
  background: #febf3f;
}
</style>
