<template>
  <tr class="tracker-row">
    <td>{{ formattedUserName }}</td>
    <td>{{ formattedTrackerValue }}</td>
    <td class="tracker-row__input-wrapper">
      <SkInput
        v-model="manualChanges"
        v-tooltip="inputTooltip"
        :errored="modifyInputError"
        :error-message="$t('reports.modals.reset_tracker.table.error_message')"
        :disabled="isInputDisabled"
        :max="9999"
        class="tracker-row__input"
        max-length="8"
        type="number"
        center
      />
    </td>
    <td>{{ trackerUpdated }}</td>
  </tr>
</template>

<script>
import { SkInput } from '@skelloapp/skello-ui';
import { capitalize } from '@skello-utils/formatting/strings';

export default {
  name: 'TrackerRow',
  components: { SkInput },
  props: {
    inputTooltip: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    trackerInSeconds: {
      type: Number,
      default: 0,
    },
    isInputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localManualChanges: 0,
    };
  },
  computed: {
    manualChanges: {
      get() {
        return this.localManualChanges;
      },
      set(newValue) {
        this.localManualChanges = newValue;
        this.$emit('update-user-manual-changes', newValue);
      },
    },
    formattedUserName() {
      return `${capitalize(this.user.first_name)} ${this.user.last_name.toUpperCase()}`;
    },
    formattedTrackerValue() {
      return this.trackerInSeconds || this.trackerInSeconds === 0 ? this.trackerInHours.toFixed(2) : '-';
    },
    trackerInHours() {
      return this.trackerInSeconds / 3600;
    },
    modifyInputError() {
      // eslint-disable-next-line no-useless-escape
      return String(this.manualChanges) === '' || !String(this.manualChanges).match(/^\-?[0-9]*(\.|,)?[0-9]+$/);
    },
    trackerUpdated() {
      if (this.manualChanges === '') return '-';

      return this.trackerInSeconds || this.trackerInSeconds === 0 ? Number(this.trackerInHours + this.manualChanges).toFixed(2) : '-';
    },
  },
};

</script>

<style lang="scss" scoped>
.tracker-row {
  border-bottom: 1px solid $sk-grey-10;

  td {
    padding: 8px 0;

    &:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:not(:first-child) {
      text-align: center;
      font-weight: $fw-semi-bold;
    }

    &:last-child {
      color: $sk-warning;
    }
  }
}

.tracker-row__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracker-row__input {
  width: 72px;
}
</style>
