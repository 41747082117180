<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#EFF6FF"
      border-color="#1E40AF"
      data-test="column__planning-hours__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__planning-hours__opened"
    >
      <div
        class="column__header planning-hours-title"
        data-test="column__planning-hours__close-button"
        @click="closeColumn"
      >
        {{ title }}

        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
      </div>

      <span v-if="modulation && hoursCounterEnabled">
        <table>
          <thead class="column__title-wrap">
            <th class="column__title column__title--open">
              {{ $t('reports.planning_hours.begin_period') }}
            </th>
            <th
              v-for="(week, index) in weeksDatas"
              :key="week.week_number"
              :class="weekTitleClasses(week)"
              class="column__title column__title--open"
            >
              <div class="column__title--week-data">
                <SkOroraButton
                  variant="tertiary"
                  size="small"
                  :title="weekRangeDate(week)"
                  class="pointer week_number activateTooltip"
                  @click="goToWeeklyPlanning(week)"
                >
                  {{ $t('reports.week_symbol') }}{{ week.week_number }}
                </SkOroraButton>
                <PeriodLock
                  v-if="week.week_active && canLockPlanning"
                  :lock-value="lockValue(week)"
                  :is-loader-active="updatePeriodLockLoading"
                  :start-date="formatLockDate(week.monday)"
                  :end-date="formatLockDate(week.sunday)"
                  :shop-ids="[Number(currentShop.id)]"
                  :week-index="index"
                />
                <CircledExclamationMarkIcon
                  v-if="isDayUnlocked(week) && isGreenLockActivated"
                  v-tooltip.right="payrollTooltip(week)"
                  class="payroll-notification__icon"
                  background-color="#F0E7FF"
                  fill="#8B45FF"
                  width="16px"
                  height="16px"
                />
              </div>
            </th>

            <th
              v-if="modulationMajoration"
              class="column__title column__title--open"
            >
              {{ $t('reports.planning_hours.majoration') }}
            </th>

            <th class="column__title column__title--open">
              {{ $t('reports.planning_hours.end_period') }}
            </th>

            <th class="column__title column__title--open">
              {{ $t('reports.planning_hours.counter_modifications') }}
            </th>
          </thead>

          <tbody>
            <template v-for="employeeInfos in employeesInfos">
              <tr
                v-for="contractInfos in employeeInfos.contracts_infos"
                :key="contractInfos.contract.id"
                class="column__cell-wrap"
              >
                <td class="column__cell column__cell--open hours-counter-orange">
                  <!-- eslint-disable-next-line max-len -->
                  <span v-if="counterAlreadyInitialized(employeeInfos.user_infos, contractInfos, weeksDatas)">
                    {{ plusIfPositive(contractInfos.beginning_counter) }}
                  </span>
                  <span
                    v-else-if="!contractInfos.interim_or_day_rate
                      && !employeeInfos.user_infos.counter_initialized
                      && isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')
                      && currentShop.attributes.hybridModulation"
                    class="hours-counter-gray"
                  >
                    {{ $t('reports.planning_hours.counter_not_initialized') }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>

                <template v-for="(week, index) in weeksDatas">
                  <td
                    v-if="week.week_active && !contractInfos.on_day_rate_contract"
                    :key="week.week_number"
                    class="column__cell column__cell--open relative"
                  >
                    <template
                      v-if="
                        employeeInfos.hours_by_week[index].within_shifts &&
                          matchesActiveContract(contractInfos.contract.id, employeeInfos, index) &&
                          (
                            employeeInfos.user_infos.counter_initialized ||
                            contractInfos.interim_or_day_rate
                          )
                      "
                    >
                      <div
                        v-if="!contractInfos.interim_or_day_rate"
                        :class="cumulatedTotalClasses(employeeInfos.hours_by_week[index])"
                        class="week-hours-counter"
                      >
                        <!-- eslint-disable-next-line max-len -->
                        {{ plusIfPositive(employeeInfos.hours_by_week[index].hours_counter_cumulated_total) }}
                        <sup
                          v-if="employeeInfos.hours_by_week[index].counter_initialized"
                          class="pointer activateTooltip asterisk-tooltip"
                          :title="$t('reports.planning_hours.initialized_this_week')"
                        >
                          *
                        </sup>
                      </div>
                      <div class="week-hours-total">
                        ({{ employeeInfos.hours_by_week[index].hours_counter_count }})
                      </div>
                      <div
                        v-if="isVariableContractHoursAvailable &&
                          employeeInfos.hours_by_week[index].week_cyclic_amendment_info"
                        class="week-hours-total week-hours-total--flex"
                      >
                        <VariableContractHoursTag
                          :nb-weeks="
                            employeeInfos.hours_by_week[index].week_cyclic_amendment_info.cycles
                          "
                          :specific-week-index="
                            employeeInfos.hours_by_week[index].week_cyclic_amendment_info.index
                          "
                        />
                        {{ employeeInfos.hours_by_week[index].week_cyclic_amendment_info.hours }}
                      </div>
                    </template>
                    <!-- eslint-disable max-len -->
                    <template v-else-if="!contractInfos.on_day_rate_contract && !employeeInfos.user_infos.counter_initialized && employeeInfos.hours_by_week[index].within_shifts && matchesActiveContract(contractInfos.contract.id, employeeInfos, index)">
                      <div>
                        {{
                          employeeInfos.hours_by_week[index].weekly_hours_with_indemnified_absences
                        }}
                      </div>

                      <div
                        v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences > 0"
                        class="over-hours-total"
                      >
                        {{ employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences }}+
                      </div>

                      <div
                        v-if="employeeInfos.hours_by_week[index].total_indemnified_absences > 0"
                        class="week-hours-total report-week-total-hours_popover"
                      >
                        <table>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.worked_hours') }}
                              <div
                                v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_hours_without_indemnified_absences > 0 && employeeInfos.hours_by_week[index].hours_without_absences > 0"
                                class="report-week-total-hours-details-overhours"
                              >
                                {{ $t('reports.planning_hours.with_majoration') }}
                              </div>
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ employeeInfos.hours_by_week[index].hours_without_absences }}
                              <div
                                v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_hours_without_indemnified_absences > 0 && employeeInfos.hours_by_week[index].hours_without_absences > 0"
                                class="report-week-total-hours-details-overhours"
                              >
                                {{ employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences }}+
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.abs_indemnified_by_employer') }}
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ (employeeInfos.hours_by_week[index].indemnified_absences_employer).toFixed(2) }}
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.abs_indemnified_by_other') }}
                              <div class="report-week-total-hours-details-explanation">
                                {{ $t('reports.planning_hours.without_majoration') }}
                              </div>
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ (employeeInfos.hours_by_week[index].indemnified_absences_other).toFixed(2) }}
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description report-week-total-hours-detail-total">
                              {{ $t('reports.planning_hours.total_planning_hours') }}
                            </th>
                            <td class="report-week-total-hours-details-value report-week-total-hours-detail-total">
                              {{ employeeInfos.hours_by_week[index].weekly_hours_with_indemnified_absences }}
                            </td>
                          </tr>
                        </table>
                        <div class="report-week-total-hours_popover__caret" />
                        <div class="report-week-total-hours-detail-separator" />
                      </div>
                    </template>
                    <!-- eslint-enable max-len -->
                    <template v-else>
                      <div class="week-hours-counter">
                        -
                      </div>
                    </template>
                  </td>
                  <td
                    v-else-if="week.week_active"
                    :key="week.week_number"
                    class="column__cell column__cell--open relative"
                  >
                    <div
                      v-if="matchesActiveContract(contractInfos.contract.id, employeeInfos, index)"
                      class="week-hours-total week-hours-total--bigger"
                    >
                      {{ employeeInfos.hours_by_week[index].days_worked }}
                      ({{ $t('reports.day_symbol') }})
                    </div>
                    <div
                      v-else
                      class="week-hours-total week-hours-total--bigger"
                    >
                      -
                    </div>
                  </td>
                  <td
                    v-else
                    :key="week.week_number"
                    class="column__cell column__cell--open column__cell--disabled"
                  />
                </template>

                <td
                  v-if="modulationMajoration"
                  class="column__cell column__cell--open"
                >
                  <span
                    v-if="!contractInfos.interim_or_day_rate && contractInfos.total_majoration > 0"
                    :class="overHoursClasses(contractInfos)"
                  >
                    +{{ onlyPositive(contractInfos.total_majoration) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>

                <td class="column__cell column__cell--open hours-counter-orange">
                  <!-- eslint-disable-next-line max-len -->
                  <span v-if="!contractInfos.interim_or_day_rate && employeeInfos.user_infos.counter_initialized">
                    {{ plusIfPositive(contractInfos.end_counter) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>

                <td class="column__cell column__cell--open">
                  <span v-if="!contractInfos.interim_or_day_rate">
                    {{ decorateCounterModifications(contractInfos.counter_modifications) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
            </template>
          </tbody>

        </table>
      </span>

      <span v-else>
        <table>
          <thead class="column__title-wrap">
            <th class="column__title column__title--open">
              {{ $t('reports.planning_hours.break_total') }}
            </th>

            <th
              v-for="(week, index) in weeksDatas"
              :key="week.week_number"
              :class="weekTitleClasses(week)"
              class="column__title column__title--open"
            >
              <div class="column__title--week-data">
                <SkOroraButton
                  variant="tertiary"
                  size="small"
                  :title="weekRangeDate(week)"
                  class="pointer week_number activateTooltip"
                  @click="goToWeeklyPlanning(week)"
                >
                  {{ $t('reports.week_symbol') }}{{ week.week_number }}
                </SkOroraButton>
                <PeriodLock
                  v-if="week.week_active && canLockPlanning"
                  class="week-lock__icon"
                  :lock-value="lockValue(week)"
                  :is-loader-active="updatePeriodLockLoading"
                  :start-date="formatLockDate(week.monday)"
                  :end-date="formatLockDate(week.sunday)"
                  :shop-ids="[Number(currentShop.id)]"
                  :week-index="index"
                />
                <CircledExclamationMarkIcon
                  v-if="isDayUnlocked(week) && isGreenLockActivated"
                  v-tooltip.right="payrollTooltip(week)"
                  class="payroll-notification__icon"
                  background-color="#F0E7FF"
                  fill="#8B45FF"
                  width="16px"
                  height="16px"
                />
              </div>
            </th>
          </thead>

          <tbody>
            <template v-for="employeeInfos in employeesInfos">
              <tr
                v-for="contractInfos in employeeInfos.contracts_infos"
                :key="contractInfos.contract.id"
                class="column__cell-wrap"
              >
                <td class="column__cell column__cell--open">
                  {{ contractInfos.total_pauses }}
                </td>

                <template v-for="(week, index) in weeksDatas">
                  <td
                    v-if="week.week_active && !contractInfos.on_day_rate_contract"
                    :key="week.week_number"
                    class="column__cell column__cell--open relative"
                  >
                    <!-- eslint-disable max-len -->
                    <template v-if="employeeInfos.hours_by_week[index].within_shifts && matchesActiveContract(contractInfos.contract.id, employeeInfos, index)">
                      <div>
                        {{ employeeInfos.hours_by_week[index].weekly_hours_with_indemnified_absences }}
                      </div>

                      <div
                        v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences > 0"
                        class="over-hours-total"
                      >
                        {{ employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences }}+
                      </div>

                      <div
                        v-if="employeeInfos.hours_by_week[index].total_indemnified_absences > 0"
                        class="week-hours-total report-week-total-hours_popover"
                      >
                        <table>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.worked_hours') }}
                              <div
                                v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_hours_without_indemnified_absences > 0 && employeeInfos.hours_by_week[index].hours_without_absences > 0"
                                class="report-week-total-hours-details-overhours"
                              >
                                {{ $t('reports.planning_hours.with_majoration') }}
                              </div>
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ employeeInfos.hours_by_week[index].hours_without_absences }}
                              <div
                                v-if="!contractInfos.interim_or_day_rate && employeeInfos.hours_by_week[index].over_hours_without_indemnified_absences > 0 && employeeInfos.hours_by_week[index].hours_without_absences > 0"
                                class="report-week-total-hours-details-overhours"
                              >
                                {{ employeeInfos.hours_by_week[index].over_contract_hours_without_indemnified_absences }}+
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.abs_indemnified_by_employer') }}
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ (employeeInfos.hours_by_week[index].indemnified_absences_employer).toFixed(2) }}
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description">
                              {{ $t('reports.planning_hours.abs_indemnified_by_other') }}
                              <div class="report-week-total-hours-details-explanation">
                                {{ $t('reports.planning_hours.without_majoration') }}
                              </div>
                            </th>
                            <td class="report-week-total-hours-details-value">
                              {{ (employeeInfos.hours_by_week[index].indemnified_absences_other).toFixed(2) }}
                            </td>
                          </tr>
                          <tr>
                            <th class="report-week-total-hours-details-description report-week-total-hours-detail-total">
                              {{ $t('reports.planning_hours.total_planning_hours') }}
                            </th>
                            <td class="report-week-total-hours-details-value report-week-total-hours-detail-total">
                              {{ employeeInfos.hours_by_week[index].weekly_hours_with_indemnified_absences }}
                            </td>
                          </tr>
                        </table>
                        <div class="report-week-total-hours_popover__caret" />
                        <div class="report-week-total-hours-detail-separator" />
                      </div>
                      <div
                        v-if="isVariableContractHoursAvailable &&
                          employeeInfos.hours_by_week[index].week_cyclic_amendment_info"
                        class="week-hours-total week-hours-total--flex"
                      >
                        <VariableContractHoursTag
                          :nb-weeks="
                            employeeInfos.hours_by_week[index].week_cyclic_amendment_info.cycles
                          "
                          :specific-week-index="
                            employeeInfos.hours_by_week[index].week_cyclic_amendment_info.index
                          "
                        />
                        {{ employeeInfos.hours_by_week[index].week_cyclic_amendment_info.hours }}
                      </div>
                    </template>
                    <!-- eslint-enable max-len -->
                    <template v-else>
                      <div class="week-hours-counter">
                        -
                      </div>
                    </template>
                  </td>
                  <td
                    v-else-if="week.week_active"
                    :key="week.week_number"
                    class="column__cell column__cell--open relative"
                  >
                    <div
                      v-if="matchesActiveContract(contractInfos.contract.id, employeeInfos, index)"
                      class="week-hours-total week-hours-total--bigger"
                    >
                      {{ employeeInfos.hours_by_week[index].days_worked }}
                      ({{ $t('reports.day_symbol') }})
                    </div>
                    <div
                      v-else
                      class="week-hours-total week-hours-total--bigger"
                    >
                      -
                    </div>
                  </td>
                  <td
                    v-else
                    :key="week.week_number"
                    class="column__cell column__cell--open column__cell--disabled"
                  />
                </template>
              </tr>
            </template>
          </tbody>

        </table>
      </span>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';
import PeriodLock from '@app-js/reports/shared/components/PeriodLock';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'PlanningHoursColumn',
  components: { ClosedColumn, VariableContractHoursTag, ReportCloseIcon, PeriodLock },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    modulation: {
      type: Boolean,
      default: false,
    },
    modulationMajoration: {
      type: Boolean,
      default: false,
    },
    canLockPlanning: {
      type: Boolean,
      default: false,
    },
    previousPeriodLocked: {
      type: Boolean,
      default: false,
    },
    weeksDatas: {
      type: Array,
      default: () => [],
    },
    hoursCounterEnabled: {
      type: Boolean,
      required: true,
    },
    isVariableContractHoursAvailable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: this.$t('reports.planning_hours.title'),
      columnActive: true,
    };
  },
  computed: {
    ...mapState('report', ['updatePeriodLockLoading']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsState', ['isGreenLockActivated']),
    ...mapGetters('employees', ['canAccessPayrollReport']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
  mounted() {
    if (window.EventBus) {
      window.EventBus.$on('toggleLocked', locked => {
        this.weeksDatas.forEach(weekData => {
          weekData.check_week_locks = locked;
        });

        if (locked === true && !this.previousPeriodLocked) {
          this.weeksDatas[0].check_week_locks = false;
        }

        this.$forceUpdate();
      });
    }
  },
  methods: {
    matchesActiveContract(contractId, employeeInfos, index) {
      return employeeInfos.hours_by_week[index].active_contract.id === Number(contractId);
    },
    // Show dash if 0.00, show + if positive, add toFixed(2)
    decorateCounterModifications(modifications) {
      if (modifications === 0) return '-';
      return this.plusIfPositive((modifications).toFixed(2));
    },
    weekRangeDate(week) {
      const monday = skDate(week.monday).format('DD/MM');
      const sunday = skDate(week.sunday).format('DD/MM');

      return `${monday} - ${sunday}`;
    },
    isDayUnlocked(week) {
      return this.lockValue(week) === '' && week.week_active;
    },
    payrollTooltip(week) {
      return this.$t('reports.payroll_notification.tooltip', { weekNumber: week.week_number });
    },
    counterAlreadyInitialized(userInfos, contractInfos, weeks) {
      if (!userInfos.counter_initialized || contractInfos.interim_or_day_rate) return false;

      const firstWeekMonday = skDate(weeks[0].monday);
      const userCounterInitializationDate = skDate(userInfos.counter_initialization_done_at);

      return userCounterInitializationDate < firstWeekMonday;
    },
    plusIfPositive(int) {
      if (int <= 0) return `${int}`;

      return `+${int}`;
    },
    onlyPositive(int) {
      if (int <= 0) return 0;

      return `${int}`;
    },
    weekTitleClasses(week) {
      return {
        'column__title--disabled': !week.week_active,
      };
    },
    cumulatedTotalClasses(data) {
      if (data.hours_counter_cumulated_total > 0) return 'weekly-hours-counter--positive';
      return 'weekly-hours-counter--negative';
    },
    overHoursClasses(contractInfos) {
      if (contractInfos.total_majoration > 0) return 'weekly-over-hours--positive';
      return '';
    },
    formatLockDate(date) {
      return skDate(date).format('YYYY-MM-DD');
    },
    goToWeeklyPlanning(week) {
      const dateParam = skDate(week.monday).format('YYYY-MM-DD');
      const planningPath = `/v3/shops/${this.currentShop.id}/plannings/weeks/employees?date=${dateParam}`;

      this.$router.push(planningPath);
    },
    lockValue(week) {
      if (week.check_week_locks) return 'permanent';
      if (this.isGreenLockActivated && week.check_week_intermediary_lock) return 'intermediary';

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-hours-title {
  border-top: 4px solid #1e40af;
}

.hr-admin-table-wrapper .column-wrap .hours-counter-orange {
  color: #ff9600;
}

.hours-counter-gray {
  color: #727272;
  width: 100px;
  display: inline-flex;
}

.weekly-hours-counter--positive {
  color: #ff5355;
}

.weekly-over-hours--positive {
  color: #ff5355;
  font-weight: 600;
}

.weekly-hours-counter--negative {
  color: #1fbed5;
}

.week-hours-counter {
  font-size: 1em;
}

.column__title-wrap {
  height: 70px;
}

.column__title-wrap .fa-lock {
  color: #fc5459;
}

.week-hours-total {
  font-size: .8em;
  font-weight: bold;
  color: #aaa;
}

.week-hours-total--bigger {
  font-size: 1em;
  font-style: none;
}

.over-hours-total {
  color: #ff1d45;
  font-size: .8em;
  font-weight: bold;
  font-style: italic;
}

.column__title--week-data {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;

  .payroll-notification__icon {
    margin-left: 8px;
  }
}

.column__cell--disabled {
  background-color: #f4f4f4;
}

.column__title--disabled {
  background-color: #f4f4f4 !important;
}

.column__cell--open .report-week-total-hours_popover {
  display: none;
}

.column__cell--open:hover .report-week-total-hours_popover {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 20px rgba(222, 225, 235, .8);
  border-top: 3px #00bcd4 solid;
  border-radius: 3px;
  font-size: .9em;
  width: 300px;
  height: 125px;
  bottom: 50px;
  left: -85px;
  z-index: 5;
}

.report-week-total-hours_popover__caret {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: 135px;
  bottom: -4px;
  z-index: -1;
}

.report-week-total-hours-detail-separator {
  position: absolute;
  left: 230px;
  top: 10px;
  height: 90px;
  width: 1px;
  border-left: 1px solid #dcdcdc;
}

.report-week-total-hours-details-description {
  min-width: 200px;
  padding: 0 20px 0 0 !important;
}

.report-week-total-hours-details-description,
.report-week-total-hours-details-value {
  font-weight: normal;
  padding-left: 15px !important;
  padding-top: 3px !important;
  color: #adadad;

  &.report-week-total-hours-detail-total {
    font-weight: bold;
    color: #9a9a9a;
  }
}

.week_number {
  color: black;
}

.report-week-total-hours-details-overhours {
  color: #f00;
}

.report-week-total-hours-details-overhours,
.report-week-total-hours-details-explanation {
  font-size: .8em;
  font-style: italic;
  padding: 0;
}

// this block handles the popover of details from non modulation absences
// first two lines must be under while the rest should be upper of mouse hover
tr.column__cell-wrap:nth-child(-n + 2) {
  .report-week-total-hours_popover {
    bottom: -113px;
  }

  .report-week-total-hours_popover__caret {
    bottom: 105px;
  }
}

.asterisk-tooltip {
  font-size: 150%;
  top: 0;
  left: 0;
}

.week-hours-total--flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.pointer {
  cursor: pointer;
}
</style>
