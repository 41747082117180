<template>
  <div class="button-container">
    <div
      :class="setActionButtonClass"
      class="action_container"
      @click.stop="toggleRenameModal"
    >
      <div class="action_button">
        <PenSvg />
      </div>
      {{ $t('actions.rename') }}
    </div>
    <div
      :class="setActionButtonClass"
      class="action_container"
      @click.stop="() => toggleMoveModal('onlyMove')"
    >
      <div class="action_button">
        <MoveSvg />
      </div>
      {{ $t('actions.move') }}
    </div>
    <div
      v-if="isSystemAdmin"
      :class="deleteButtonClass"
      class="action_container"
      @click.stop="toggleDeleteModal"
    >
      <div class="action_button">
        <BinSvg />
      </div>
      {{ $t('actions.delete') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PenSvg from '../../assets/svg/PenSvg';
import MoveSvg from '../../assets/svg/MoveSvg';
import BinSvg from '../../assets/svg/BinSvg';

export default {
  components: {
    PenSvg,
    MoveSvg,
    BinSvg,
  },
  props: {
    actionButtonClass: {
      type: Object,
      required: true,
    },
    deleteButtonClass: {
      type: Object,
      required: true,
    },
    toggleRenameModal: {
      type: Function,
      required: true,
    },
    toggleMoveModal: {
      type: Function,
      required: true,
    },
    toggleDeleteModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['isSystemAdmin']),
    setActionButtonClass() {
      return this.actionButtonClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.action_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: .3;
  font-size: $fs-text-s;

  &.action-enabled {
    cursor: pointer;
    opacity: 1;
  }
}

.action_button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $sk-grey-10;
  display: flex;
  margin-bottom: 15px;

  .action-enabled &:hover {
    transition: background-color .3s ease;
    background-color: #d6dce0;
  }
}

.action_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: .3;
  font-size: $fs-text-s;

  &.action-enabled {
    cursor: pointer;
    opacity: 1;
  }
}

.action_button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $sk-grey-10;
  display: flex;
  margin-bottom: 15px;

  .action-enabled &:hover {
    transition: background-color .3s ease;
    background-color: #d6dce0;
  }
}
</style>
