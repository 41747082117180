<template>
  <SkDropdown
    ref="dropdown"
    prevent-overflow
    placement="bottom"
    trigger="click"
    y-offset="2px"
    class="planning-row__shift-quick-select-wrapper planning-row__shift-label"
    :style="zoomRange"
    :disabled="disabled"
    @show-dropdown="handleShowDropdown"
    @hide-dropdown="handleHideDropdown"
  >
    <template #anchor>
      <span
        v-tooltip="labelTooltip()"
        class="planning-row__shift-quick-select"
        :style="zoomRange"
      >
        <span
          ref="label"
          class="planning-row__shift-quick-select__label quick-select__ellipsis"
        >
          {{ label }}
        </span>
        <div
          v-if="!isPendingLeaveRequest"
          class="planning-row__shift-quick-select__caret-wrapper"
        >
          <CaretIcon
            ref="caretIcon"
            fill="white"
            :class="`planning-row__shift-quick-select__caret-icon caret--${caretOrientation}`"
          />
        </div>
      </span>
    </template>
    <template #menu>
      <div class="planning-row__shift-quick-select__list">
        <div
          v-for="item in items"
          :key="item.id"
          class="planning-row__shift-quick-select__option quick-select__ellipsis"
          @click="onItemClick(item.id)"
        >
          {{ item.formattedName }}
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>

export default {
  name: 'QuickSelect',
  props: {
    label: {
      type: String,
      default: null,
    },
    zoomRange: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPendingLeaveRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ellipsisActive: false,
      caretOrientation: 'up',
    };
  },
  mounted() {
    this.checkEllipsis();

    window.addEventListener('resize', this.checkEllipsis);
    this.listenOnRoot('planning-table-scroll', this.hide);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkEllipsis);
  },
  methods: {
    labelTooltip() {
      if (!this.ellipsisActive) return null;

      return this.label;
    },
    checkEllipsis() {
      this.ellipsisActive = !this.$refs.label ||
        this.$refs.label.offsetWidth < this.$refs.label.scrollWidth;
    },
    onItemClick(itemId) {
      this.$emit('item-click', itemId);
    },
    handleShowDropdown() {
      if (this.disabled) return;

      this.caretOrientation = 'down';
    },
    handleHideDropdown() {
      if (this.disabled) return;

      this.caretOrientation = 'up';
    },
    hide() {
      this.$refs.dropdown.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.planning-row__shift-quick-select-wrapper {
  cursor: pointer;
  z-index: auto !important;
  padding: 0;

  ::v-deep .sk-dropdown__menu {
    width: 99%;
  }

  .planning-row__shift-quick-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quick-select__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .planning-row__shift-quick-select__caret-wrapper {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .planning-row__shift-quick-select__list {
    display: block;
    max-height: 200px;
    text-align: initial;
    color: $sk-black;
    background-color: white;
    border-radius: 2px;
    overflow-y: auto;
    box-shadow: 0 6px 16px 0 #0000001f;
  }

  .planning-row__shift-quick-select__option {
    display: block;
    font-family: Gellix, sans-serif;
    font-size: 1.2em;
    line-height: 24px;
    padding: 2px 8px;

    &:hover {
      background-color: $sk-grey-5;
    }
  }
}
</style>
