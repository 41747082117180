<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import PopularShiftsPopover from '../../PopularShiftsPopover';
import GenericDropdownItem from './GenericDropdownItem';

export default {
  name: 'PopularShiftsAction',
  components: {
    PopularShiftsPopover,
  },
  extends: GenericDropdownItem,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('planningsState', ['isDailyView']),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.popular_shifts.label');
    },
    icon() {
      return 'FireV2Icon';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    isPopov() {
      return true;
    },
    popovComponent() {
      return 'PopularShiftsPopover';
    },
    disabled() {
      return this.isDataLoading;
    },
    tooltip() {
      if (this.isDailyView) return this.$t('plannings.toolbar.actions_bar.tooltip_soon.title');

      return '';
    },
    isVisible() {
      return this.currentLicense.attributes.canCreateShifts;
    },
  },
  methods: {},
};
</script>
