<template>
  <img
    width="75"
    src="./images/logo-time_skipper.png"
  >
</template>

<script>
export default {
  name: 'TimeSkipper',
};
</script>
