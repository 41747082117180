<template>
  <div
    ref="dropzone"
    v-tooltip="dropzoneTooltip"
    class="day-cell--dropzone"
  >
    <div
      style="flex-grow: 1;display: flex;"
      @dragenter="onDropzoneMoveEnter"
      @dragleave="onDropzoneMoveLeave"
      @dragover.prevent
      @drop="$emit(
        'on-drop',
        {target: 'move', originalUser: $event.dataTransfer.getData(getDragDataFormat('user'))}
      )"
    >
      <div
        ref="moveDropzone"
        class="day-cell--dropzone--move"
        style="flex-grow: 1;pointer-events: none;"
        :style="{ backgroundColor: hoveringMoveDropzone ? $skColors.skBlue : $skColors.skWhite }"
      >
        <MoveIcon
          width="12"
          height="12"
          style="pointer-events:none"
          :fill="hoveringMoveDropzone ? $skColors.skWhite : $skColors.skBlue"
        />
      </div>
    </div>
    <div
      style="flex-grow: 1;display: flex;"
      @dragenter="onDropzoneCopyEnter"
      @dragleave="onDropzoneCopyLeave"
      @dragover.prevent
      @drop="$emit(
        'on-drop',
        {target: 'copy', originalUser: $event.dataTransfer.getData(getDragDataFormat('user'))}
      )"
    >
      <div
        ref="copyDropzone"
        class="day-cell--dropzone--copy"
        style="flex-grow: 1;pointer-events: none;"
        :style="{ backgroundColor: hoveringCopyDropzone ? $skColors.skBlue : $skColors.skWhite }"
      >
        <FilesV2Icon
          width="12"
          height="12"
          style="pointer-events:none"
          :style="{ 'poiner-events': 'none' }"
          :fill="hoveringCopyDropzone ? $skColors.skWhite : $skColors.skBlue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  FilesV2Icon, MoveIcon,
} from '@skelloapp/skello-ui';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';

export default {
  components: {
    FilesV2Icon,
    MoveIcon,
  },
  emits: ['on-drop'],
  data() {
    return {
      hoveringCopyDropzone: false,
      hoveringMoveDropzone: false,
      dropzoneTooltip: '',
    };
  },
  methods: {
    getDragDataFormat,
    resetDragState() {
      this.hoveringCopyDropzone = false;
      this.hoveringMoveDropzone = false;
    },
    onDropzoneMoveEnter() {
      this.hoveringMoveDropzone = true;
      this.dropzoneTooltip = this.$t('plannings.table.cells.drag_and_drop.move');
      this.$nextTick(() => this.$refs.dropzone.dispatchEvent(new MouseEvent('mouseenter')));
    },
    onDropzoneMoveLeave(event) {
      if (!this.$refs.dropzone) {
        return;
      }
      this.hoveringMoveDropzone = this.$refs.moveDropzone.contains(event.relatedTarget);
      if (this.$refs.dropzone === event.relatedTarget) {
        this.$refs.dropzone.dispatchEvent(new MouseEvent('mouseleave'));
      }
    },
    onDropzoneCopyEnter() {
      this.hoveringCopyDropzone = true;
      this.dropzoneTooltip = this.$t('plannings.table.cells.drag_and_drop.copy');
      this.$nextTick(() => this.$refs.dropzone.dispatchEvent(new MouseEvent('mouseenter')));
    },
    onDropzoneCopyLeave(event) {
      if (!this.$refs.dropzone) {
        return;
      }

      this.hoveringCopyDropzone = this.$refs.copyDropzone.contains(event.relatedTarget);
      if (this.$refs.dropzone === event.relatedTarget) {
        this.$refs.dropzone.dispatchEvent(new MouseEvent('mouseleave'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.day-cell--dropzone {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $sk-white;

  &--move {
    display:flex;
    margin: 4px 0 4px 4px;
    align-items: center;
    justify-content: center;
    border: 2px solid $sk-blue;
    border-right: 0;
    border-radius: 6px 0 0 6px;
  }

  &--copy {
    display:flex;
    margin: 4px 4px 4px 0;
    align-items: center;
    justify-content: center;
    border: 2px solid $sk-blue;
    border-left: 0;
    border-radius: 0 6px 6px 0;
  }
}
</style>
