<template>
  <tr class="chargebee-widget__line">
    <td>
      {{ chargebeeLine.name }}
    </td>
    <td>
      <SkCircleButton
        v-tooltip.right="$t('organisation_settings.tabs.organisation_info.invoices.tooltip')"
        icon="OpenEyeV2Icon"
        @click="openModal"
      />
    </td>
  </tr>
</template>

<script>
import { svcBillingAutomationClient } from '@skello-utils/clients';

export default {
  name: 'ChargebeeWidgetLine',
  props: {
    chargebeeLine: {
      type: Object,
      required: true,
    },
    cbInstance: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async openModal() {
      await this.cbInstance.logout();
      await this.cbInstance
        .setPortalSession(
          () => svcBillingAutomationClient.createPortalSessionByChargebeeId(
            this.chargebeeLine.chargebeeId,
          ),
        );
      const cbPortal = this.cbInstance.createChargebeePortal();

      cbPortal.openSection({
        sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
td:first-child {
    padding-left: 10px;
    min-width: 90%;
  }

.chargebee-widget__line {
  &:hover {
    background-color: $sk-grey-5;
  }
}
</style>
