<template>
  <div
    class="unpaid-banner--container"
  >
    <ExclamationMarkV2Icon
      width="20px"
      height="20px"
      :fill="$skColors.skError50"
    />
    <div class="unpaid-banner--message">
      <span>
        {{ message }}
      </span>
      <span v-if="!allShopsViewWithMultipleUnpaidShops && isSystemAdmin">
        <SkOroraLink
          :href="paymentlink"
          target="_blank"
          @click.native="handleTracker"
        >
          {{ $t('unpaid_banner.click_here') }}
        </SkOroraLink>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="subMessage" />
      </span>
    </div>
  </div>
</template>

<script>
import { ExclamationMarkV2Icon } from '@skelloapp/skello-ui';
import skDate from '@skello-utils/dates';
import {
  mapGetters, mapState,
} from 'vuex';

export default {
  name: 'UnpaidBanner',
  components: {
    ExclamationMarkV2Icon,
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentShop', ['isDevFlagEnabled', 'shouldShowAdminOnboarding']),
    ...mapGetters('currentLicense', ['isSystemAdmin', 'isMaster', 'isScheduler']),
    message() {
      let translationSuffix = '';
      if (this.allShopsViewWithMultipleUnpaidShops && this.isSystemAdmin) {
        return this.$t('unpaid_banner.generic_message');
      }
      if (this.isSystemAdmin) {
        translationSuffix = 'admin';
      } else if (this.isMaster || this.isScheduler) {
        translationSuffix = 'master';
      }
      const cancelDate = this.isAllShopsView ?
        this.currentOrganisation.attributes.cancelledAt :
        this.currentShop.attributes.cancelledAt;

      return this.$t(`unpaid_banner.${this.level}.message.${translationSuffix}`, {
        cancelDate: skDate(cancelDate).format('DD/MM/YYYY'),
      });
    },
    subMessage() {
      const mailOwner = this.isAllShopsView ?
        this.currentOrganisation.attributes.ownerEmail :
        this.currentShop.attributes.billing.owner_email;
      return this.$t('unpaid_banner.sub_message', { mailOwner });
    },
    allShopsViewWithMultipleUnpaidShops() {
      return this.level === 'shop_level' &&
        this.isAllShopsView &&
        this.currentOrganisation.attributes?.shopsWithUnpaidBannerCount > 1;
    },
    paymentlink() {
      return this.isAllShopsView ?
        this.currentOrganisation.attributes?.paymentLink :
        this.currentShop.attributes.billing.payment_link;
    },
    level() {
      return this.currentOrganisation.attributes?.organisationUnpaidBanner ? 'organisation_level' : 'shop_level';
    },
    isAllShopsView() {
      return this.currentShop.id === 'all';
    },
  },
  methods: {
    handleTracker(event) {
      this.$skAnalytics.track('unpaid_invoice_banner_link_click');
    },
  },
};
</script>

<style lang="scss">
.unpaid-banner {
  &--container {
    min-height: 50px;
    padding: 12px 16px;
    background-color: $sk-error-10;
    width: 100%;
    color: $sk-black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &--message {
    display: flex;
    flex-direction: column;
    line-height: normal;

    & a {
      display: inline-block;
      color: $sk-black;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $sk-black;
        text-decoration: none;
      }
    }
  }
}

.skello-gray-link {
  text-decoration: underline;
  color: $sk-grey;

  &:hover, &:focus, &:active {
    color: $sk-black;
    border: none;
    text-decoration: underline;
  }
}
</style>
