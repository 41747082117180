<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M9.19533 1.18555L3.17601 7.20487L0.804671 4.83353L0 5.6382L3.17601 8.81421L10 1.99022L9.19533 1.18555Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'BlueCheck',
};
</script>
