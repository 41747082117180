<template>
  <img
    width="32"
    src="./images/logo-adp.jpg"
  >
</template>

<script>
export default {
  name: 'Adp',
};
</script>
