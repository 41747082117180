<template>
  <SkModal :on-close="toggleCreateModal">
    <template #header>
      <ModalHeader
        :title="$t('organisation_settings.tabs.clusters.add_node')"
        :svg-name="'ClusterNodeIconSvg'"
      />
    </template>
    <template #body>
      <div>
        <div class="input-wrapper">
          <span> {{ $t('organisation_settings.tabs.clusters.name_node') }} </span>
          <input
            ref="focusOnMe"
            v-model="nodeName"
            class="create-node-input"
            @keyup.enter="submit"
          >
        </div>
        <div class="actions">
          <ModalButton
            :button-text="$t('actions.add')"
            :on-press-action="submit"
            button-color="#2b66fe"
          />
          <ModalButton
            :button-text="$t('actions.cancel')"
            :on-press-action="toggleCreateModal"
            button-color="#ff5355"
          />
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import SkModal from '../../ui/SkModal';
import ModalButton from '../../ui/ModalButton';
import ModalHeader from '../../ui/ModalHeader';

export default {
  components: { SkModal, ModalHeader, ModalButton },
  props: {
    toggleCreateModal: {
      type: Function,
      required: true,
    },
    createNode: {
      type: Function,
      required: true,
    },
    createClusterNodeLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nodeName: '',
    };
  },
  mounted() {
    this.$refs.focusOnMe.focus();
  },
  methods: {
    submit() {
      if (this.createClusterNodeLoading) return;
      this.createNode(this.nodeName);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header,
.icon-container {
  display: flex;
  align-items: center;
}

.icon-container {
  justify-content: center;
  background-color: $sk-grey-10;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.modal-header-title {
  font-size: $fs-heading-xs;
}

.input-wrapper {
  margin: 30px 0;
  font-size: 13px;
  min-width: 510px;
  font-weight: $fw-semi-bold;
}

.create-node-input {
  margin-top: 5px;
  width: 100%;
  border: 1px solid #00bedd;
  box-sizing: border-box;
  border-radius: 3px;
  height: 44px;
  padding: 0 5px;
  font-size: $fs-text-l;
  font-weight: normal;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
</style>
