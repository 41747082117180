import HomeDashboard from './HomeDashboard';

const homeDashboardRoutes = [
  {
    path: '/v3/shops/:shop_id/dashboard',
    component: HomeDashboard,
    name: 'home_dashboard',
  },
];

export default homeDashboardRoutes;
