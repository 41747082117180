<template>
  <tr
    :class="['table-row', isMonthlySkeletonEnabled && 'fade-item']"
    :style="rowStyle"
  >
    <td>
      <SidebarCell
        :ref="`sidebarCell${user.id}`"
        class="sidebar-cell"
        :row-item="user"
        :store-infos="sidebarCellInfos"
        :contract-warning-text="contractWarningText"
        :is-mouse-inside-row="displayShiftSwapper"
        :is-unassigned-shifts-row="isUnassignedShiftsRow"
      />
    </td>

    <template v-for="(week, weekKey, weekIdx) of monthlyVisibleWeeks">
      <td
        v-for="(day, dayIdx) of week.days"
        :key="`${weekKey + '-' + day.date}`"
        :class="dayIdx === week.days.length - 1 && 'last-week-day'"
      >
        <DayCell
          :ref="`user${user.id}DayCell${day.date}`"
          :user="user"
          :day="day"
          :shifts="shifts[weekKey] === undefined ? [] : shifts[weekKey][day.date]"
          :availabilities="availabilitiesForDay(availabilities, day)"
          :day-cell-component-store-props="dayCellComponentStoreProps"
          :is-unassigned-shifts-row="isUnassignedShiftsRow"
        />
      </td>

      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <td
        :class="['weekly-counter', `week-${weekIdx + 1}`]"
        @click="weeklyCounterClicked(weekKey)"
        @mouseenter="weeklyCounterHovered(weekIdx + 1)"
        @mouseleave="weeklyCounterHovered(null)"
      >
        <div class="weekly-counter-wrapper">
          <SkLoader
            v-if="!isMonthlySkeletonEnabled && showWeeklyCounterLoading(weekKey)"
            size="medium"
          />
          <div
            v-else-if="isMonthlySkeletonEnabled && showWeeklyCounterLoading(weekKey)"
            class="weekly-counter-skeleton"
          >
            <SkRectSkeleton
              width="48"
              height="14"
            />
            <SkRectSkeleton
              width="24"
              height="12"
            />
          </div>
          <CountersCell
            v-else
            v-show="userRowComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekKey]"
            :class="countersCellClass"
            :row-item="user"
            :shifts="allWeekShifts(weekKey)"
            :days-worked="daysWorkedByWeek[weekKey] || {}"
            :is-unassigned-shifts-row="isUnassignedShiftsRow"
            :contract-warning-text="contractWarningText"
            :show-question-mark="false"
          />
        </div>
      </td>
    </template>

    <!-- Empty column that will expand based on TableHeader's dynamic width to fill the screen -->
    <td class="empty-col" />

    <td v-if="showPtoCounter">
      <PtoCounterCell
        :class="countersCellClass"
        :user="user"
        :pto-counter-cell-component-store-props="ptoCounterCellComponentStoreProps"
      />
    </td>

    <td v-else>
      <CountersCell
        :ref="`countersCell${user.id}`"
        :class="countersCellClass"
        :shifts="flattenedUserShifts"
        :period-shifts="flattenedUserShifts"
        :row-item="user"
        :days-worked="userRowComponentStoreProps.daysWorked"
        :is-unassigned-shifts-row="isUnassignedShiftsRow"
        :contract-warning-text="contractWarningText"
        :show-question-mark="false"
        :should-show-extra-hours="false"
      />
    </td>
  </tr>
</template>

<script>
import {
  mapMutations, mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import DayCell from '@app-js/plannings/pages/Months/Table/DayCell/index.vue';
import SidebarCell from '@app-js/plannings/shared/PlanningTable/PlanningRow/SidebarCell';
import CountersCell from '@app-js/plannings/shared/components/CountersCell';
import { availabilitiesForDay } from '@app-js/shared/utils/availabilities_helper';
import PtoCounterCell from './PtoCounterCell.vue';

export default {
  name: 'UserRow',
  components: {
    DayCell,
    SidebarCell,
    CountersCell,
    PtoCounterCell,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    shifts: {
      type: Object,
      default: () => ({}),
    },
    availabilities: {
      type: Array,
      default: () => [],
    },
    daysWorkedByWeek: {
      type: Object,
      required: true,
    },
    userRowComponentStoreProps: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    isUnassignedShiftsRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availabilitiesForDay,
    };
  },
  computed: {
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('planningsState', ['monthlyVisibleWeeks', 'selectedWeeklyCountersInMonthlyView']),
    ...mapState('planningsState', ['selectedWeeklyCountersInMonthlyView']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    isMonthlySkeletonEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_MONTHLY_SKELETON');
    },
    rowStyle() {
      if (this.isMonthlySkeletonEnabled) {
        return {
          animationDelay: `${this.rowIndex * 0.02}s`,
        };
      }

      return {};
    },
    countersCellClass() {
      return {
        'counter-cell--faded': this.isMonthlySkeletonEnabled,
      };
    },
    showPtoCounter() {
      return this.userRowComponentStoreProps.currentShop.attributes.plcInitialized && this.userRowComponentStoreProps.selectedCounter === 'pto';
    },
    sidebarCellInfos() {
      return {
        isEmployeesView: true,
        userInitials: this.userRowComponentStoreProps.userInitials,
        currentLicense: this.userRowComponentStoreProps.currentLicense,
        employeeAnnualizationConfigs: this.userRowComponentStoreProps.employeeAnnualizationConfigs,
        isAnnualizationCurrentlyActive:
          this.userRowComponentStoreProps.isAnnualizationCurrentlyActive,
        periodTheoreticalBalanceAt: this.userRowComponentStoreProps.periodTheoreticalBalanceAt,
        isAnnualizedWorkingTimeAvailable:
          this.userRowComponentStoreProps.isAnnualizedWorkingTimeAvailable,
        planningZoom: this.userRowComponentStoreProps.planningZoom,
        getAvatarUrlForUser: this.userRowComponentStoreProps.getAvatarUrlForUser,
        monday: this.userRowComponentStoreProps.monday,
        isDailyView: this.userRowComponentStoreProps.isDailyView,
        currentDate: this.userRowComponentStoreProps.currentDate,
        areEmployeeAnnualizationConfigsLoading:
          this.userRowComponentStoreProps.areEmployeeAnnualizationConfigsLoading,
        currentShop: this.userRowComponentStoreProps.currentShop,
        shopPlanningConfig: this.userRowComponentStoreProps.shopPlanningConfig,
        teamSchedules: this.userRowComponentStoreProps.teamSchedules,
      };
    },
    contractWarningText() {
      if (this.isUnassignedShiftsRow) {
        return '';
      }
      const { attributes: {
        onExtra,
        activeContractPeriod,
        currentContractHours,
        onDayRate,
      } } = this.user;
      const hasContractHours = currentContractHours !== 0;

      if (!this.userRowComponentStoreProps.isMulticontractsEnabled || onExtra) return '';

      if (activeContractPeriod !== 'current') {
        return this.$t(`plannings.table.contracts.tooltip.missing_contract_on_${activeContractPeriod}`);
      }

      if (!hasContractHours && !onDayRate) {
        return this.$t('plannings.table.contracts.tooltip.missing_hourly_wage');
      }

      return '';
    },
    displayShiftSwapper() {
      return false;
    },
    flattenedUserShifts() {
      const userMonthlyShifts = Object.values(this.shifts);

      const flattenedMonthlyShifts = userMonthlyShifts.reduce((accumulator, weekShifts) => {
        const flattenedWeeklyShifts = Object.keys(weekShifts).reduce((weekAccumulator, weekDay) => {
          if (!this.isDateInPeriod(weekDay)) return weekAccumulator;

          const weekDayShifts = weekShifts[weekDay];

          return [...weekAccumulator, ...weekDayShifts];
        }, []);

        return [...accumulator, ...flattenedWeeklyShifts];
      }, []);

      return flattenedMonthlyShifts;
    },
    dayCellComponentStoreProps() {
      const {
        currentShop,
        currentLicense,
        selectedDate,
        pendingLeaveRequestShifts,
        isShiftInFilters,
        config,
        absences,
        draggedShift,
        usingDraggedShiftId,
        monday,
        sunday,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        isShopOnPaidVacationCalculationTypeCalendarDay,
      } = this.userRowComponentStoreProps;

      return {
        currentShop,
        currentLicense,
        selectedDate,
        pendingLeaveRequestShifts,
        isShiftInFilters,
        config,
        absences,
        draggedShift,
        usingDraggedShiftId,
        monday,
        sunday,
        isShopOnPaidVacationCalculationTypeOpeningDay,
        isShopOnPaidVacationCalculationTypeCalendarDay,
      };
    },
    ptoCounterCellComponentStoreProps() {
      const {
        bulkPaidLeaveCounters,
        checkFeatureFlag,
      } = this.userRowComponentStoreProps;

      return {
        bulkPaidLeaveCounters,
        checkFeatureFlag,
      };
    },
  },
  methods: {
    ...mapMutations('planningsState', ['toggleWeeklyCounterInMonthlyView']),
    showWeeklyCounterLoading(weekKey) {
      return (
        this.userRowComponentStoreProps.selectedWeeklyCountersInMonthlyView[weekKey] &&
        (this.daysWorkedByWeek[weekKey]?.isLoading)
      );
    },
    weeklyCounterClicked(weekStart) {
      if (!this.selectedWeeklyCountersInMonthlyView[weekStart]) {
        this.$emit('load-week', weekStart);
      }

      this.toggleWeeklyCounterInMonthlyView(weekStart);
    },
    weeklyCounterHovered(weekIdx) {
      if (this.userRowComponentStoreProps.draggedShift !== null) return;

      this.$emit('weekly-counter-hovered', weekIdx);
    },
    allWeekShifts(weekStart) {
      return Object.values(this.shifts[weekStart] ?? []).flat();
    },
    isDateInPeriod(date) {
      return skDate(date).isBetweenInclusive(
        this.userRowComponentStoreProps.firstMonthDay, this.userRowComponentStoreProps.lastMonthDay,
      );
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  td {
    padding: 0;
    border-top: 1px solid $sk-grey-10;
    border-left: 1px solid $sk-grey-10;

    &:first-child {
      background-color: $sk-white;
      z-index: 5;
      border-top: none;
      border-right: 1px solid $sk-grey-10;
      position: sticky;
      left: 0;
    }

    &.last-week-day {
      border-right: 1px solid $sk-grey-10;
    }

    &.weekly-counter {
      border-top: none;
      border-left: none;
    }

    &.empty-col {
      border-top: none;
      border-left: none;
    }
  }

  &.table-row {
    td {
      &:last-child {
        background-color: $sk-white;
        z-index: 5;
        border-top: none;
        border-left: 1px solid $sk-grey-10;
        position: sticky;
        right: 0;
      }

      &.weekly-counter {
        cursor: pointer;
      }
    }
  }
}

.weekly-counter-wrapper {
  display: flex;
  justify-content: center;
}

.weekly-counter-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5px;
}

.counter-cell {
  &--faded {
    animation: fadeIn 0.15s ease-out;
  }
}

.sidebar-cell {
  border-bottom: none;
  min-width: 100%;
  height: 100%;
}

.planning-row__counters-cell__main {
  flex-basis: 100%;
}

::v-deep .planning-row__counters-cell__wrapper {
  background-color: transparent;
  z-index: unset;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-item {
  opacity: 0;
  animation: fadeIn .3s ease-out forwards;
}
</style>
