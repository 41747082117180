<template>
  <SkOroraDialog
    id="silae-activation-modal"
    ref="silaeActivationModal"
    class="silae-activation-modal"
    :next-step-button-label="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.next')"
    :step-count="landOnCredentialsStep && !hasEmailJustBeenSent ? 1 : 3"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isSubmitting"
    :submit-button-label="submitButtonLabel"
    :title="modalTitle"
    is-artwork
    size="medium"
    @cancel="handleClose"
    @close="handleClose"
    @update-step="handleUpdateStep"
    @show="resetData"
    @submit.prevent="handleSubmit"
  >
    <template #headerImage>
      <div class="silae-activation-modal__header-icon sk-flex-center">
        <Silae
          height="70"
          width="70"
        />
      </div>
    </template>
    <template #body>
      <main
        v-if="isInformationStep"
        class="silae-activation-modal__content"
      >
        <section class="silae-activation-modal__card-container">
          <article class="silae-activation-modal__card">
            <section class="silae-activation-modal__card-description">
              <strong class="sk-text-large-semibold">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.cards.employee_info.header') }}
              </strong>
              <p class="silae-activation-modal--text sk-text-medium-regular">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.cards.employee_info.description') }}
              </p>
            </section>
            <SilaeEmployeeInfo />
          </article>
          <hr class="silae-activation-modal__card-divider">
          <article class="silae-activation-modal__card">
            <section class="silae-activation-modal__card-description">
              <header class="silae-activation-modal__evp-header">
                <strong class="sk-text-large-semibold">
                  {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.cards.evp_export.header') }}
                </strong>
                <span
                  v-if="!isSilaeEvpExportEnabled"
                  v-tooltip.bottom-end="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.cards.tooltip')"
                  class="silae-activation-modal__evp-header-icon sk-flex-center"
                >
                  <CrownJewelIcon
                    :fill="$skColors.skWarning"
                    height="20"
                    width="20"
                  />
                </span>
              </header>
              <p class="silae-activation-modal--text sk-text-medium-regular">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.cards.evp_export.description') }}
              </p>
            </section>
            <SilaeEvpExport />
          </article>
        </section>
      </main>
      <main
        v-else-if="isChooseActivationPersonStep"
        class="silae-activation-modal__content"
      >
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="
            silae-activation-modal--text
            silae-activation-modal--description
            sk-text-large-regular
          "
          v-html="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.connection_step.description')"
        />
        <!-- eslint-enable vue/no-v-html -->
        <SkOroraChipGroup
          v-model="chips"
          variant="rect"
          :multi-select="false"
        />
      </main>
      <main
        v-else-if="isKeysFillingStep"
        class="silae-activation-modal__content"
      >
        <section class="silae-activation-modal--description">
          <p class="silae-activation-modal--text sk-text-medium-regular">
            {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.description') }}
            <SkOroraLink
              :href="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.link')"
              size="large"
              target="_blank"
              rel="noopener noreferer"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.doc_link') }}
            </SkOroraLink>
          </p>
        </section>
        <section class="silae-activation-modal__inputs-container">
          <div class="silae-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="silae-client-id"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.labels.client_id') }}
            </label>
            <SkInput
              v-model="clientId"
              input-id="silae-client-id"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.placeholder.client_id')"
            />
          </div>
          <div class="silae-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="silae-client-secret"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.labels.client_secret') }}
            </label>
            <SkInput
              v-model="clientSecret"
              input-id="silae-client-secret"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.placeholder.client_secret')"
            />
          </div>
          <div class="silae-activation-modal__input-row">
            <label
              class="sk-text-medium-semibold"
              for="silae-configuration-id"
            >
              {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.labels.main_key') }}
            </label>
            <SkInput
              v-model="mainKey"
              input-id="silae-configuration-id"
              :disabled="isSubmitting"
              :placeholder="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.placeholder.main_key')"
            />
          </div>
        </section>
      </main>
      <div
        v-else
        class="silae-activation-modal__content"
      >
        <p
          class="
            silae-activation-modal--text
            silae-activation-modal--description
            sk-text-large-regular
          "
        >
          {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.description') }}
        </p>
        <section class="silae-activation-modal__email">
          <div class="silae-activation-modal__sender">
            <SkAvatar
              size="x-large"
              :text="initials"
            />
            <div class="silae-activation-modal__sender-details">
              <span class="sk-text-medium-regular truncate-text">
                {{ $t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.sent_by') }} {{ fullName }}
              </span>
              <span class="sk-text-medium-regular truncate-text">
                {{ currentUser.attributes.email }}
              </span>
            </div>
          </div>
          <hr class="silae-activation-modal--divider">
          <SkInput
            v-model="emailRecipient"
            :disabled="isSubmitting"
            :errored="isRecipientSet && !isRecipientValid"
            :error-message="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.email_format')"
            :label="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.recipient')"
            error-message-align-right
            @blur="validateEmail"
          />
          <SkInput
            v-model="emailObject"
            :disabled="isSubmitting"
            :label="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.object.label')"
          />
          <SkTextarea
            v-model="emailContent"
            :disabled="isSubmitting"
            :label="$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.content.label')"
            :max-height="256"
          />
        </section>
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { httpClient } from '@skello-utils/clients';
import { isValidEmail } from '@skello-utils/validators';

import {
  Silae,
  SilaeEmployeeInfo,
  SilaeEvpExport,
} from '../../shared/icons';

export default {
  name: 'SilaeActivationModal',
  components: {
    Silae,
    SilaeEmployeeInfo,
    SilaeEvpExport,
  },
  props: {
    landOnCredentialsStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clientId: null,
      clientSecret: null,
      mainKey: null,
      currentStep: 1,
      chips: [{
        key: 'myself',
        label: null,
        isSelected: false,
        icon: 'PencilV2Icon',
      }, {
        key: 'accountant',
        label: null,
        isSelected: false,
        icon: 'PaperAirplaneV2Icon',
      }],
      emailContent: null,
      emailObject: null,
      hasEmailJustBeenSent: false,
      isSubmitting: false,
      isRecipientSet: false,
      isRecipientValid: true,
      recipient: null,
    };
  },
  computed: {
    ...mapGetters('currentUser', ['fullName', 'initials']),
    ...mapGetters('organisationCredentials', [
      'isIntegrationEnabled',
      'isIntegrationPending',
      'isSilaeEvpExportEnabled',
    ]),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('organisationCredentials', ['totalShopsCount']),
    activateMyselfChipLabel() {
      return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.connection_step.chips.myself');
    },
    accountantActivationChipLabel() {
      return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.connection_step.chips.accountant');
    },
    activateMyselfChip() {
      return this.chips[0];
    },
    accountantActivationChip() {
      return this.chips[1];
    },
    defaultEmailContent() {
      return this.$t(
        this.isSilaeEvpExportEnabled ?
          'organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.content.prefill_w_evp' :
          'organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.content.prefill_wo_evp',
        {
          coach: this.currentUser.relationships.shop.attributes.coach,
          email: this.currentUser.attributes.email,
          shop_id: this.currentUser.relationships.shop.id,
          shops_count: this.totalShopsCount,
          user: this.fullName,
          user_id: this.currentUser.id,
        },
      );
    },
    defaultEmailObject() {
      return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.object.prefill');
    },
    emailRecipient: {
      get() {
        return this.recipient;
      },
      set(value) {
        this.recipient = value;
        this.isRecipientSet = true;
      },
    },
    isInformationStep() {
      return this.currentStep === 1 && !this.landOnCredentialsStep;
    },
    isChooseActivationPersonStep() {
      return this.currentStep === 2;
    },
    isKeysFillingStep() {
      return (this.landOnCredentialsStep && !this.hasEmailJustBeenSent) ||
        (this.currentStep === 3 && this.activateMyselfChip.isSelected);
    },
    modalTitle() {
      if (this.isInformationStep) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.reassurance_step.header');
      }

      if (this.isChooseActivationPersonStep) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.connection_step.header');
      }

      if (this.isKeysFillingStep) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.configuration_step.header');
      }

      // corresponds to currentStep === 3 && accountantActivationChip.isSelected condition
      return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.header');
    },
    isSubmitDisabled() {
      if (this.currentStep === 2) {
        return this.chips.every(chip => !chip.isSelected);
      }

      if (
        this.landOnCredentialsStep ||
        (this.currentStep === 3 && this.activateMyselfChip.isSelected)
      ) {
        return !this.clientId || !this.clientSecret || !this.mainKey;
      }

      if (this.currentStep === 3 && this.accountantActivationChip.isSelected) {
        return !this.emailContent ||
          !this.emailObject ||
          !this.emailRecipient ||
          !this.isRecipientSet ||
          !this.isRecipientValid;
      }

      return false;
    },
    submitButtonLabel() {
      if (this.isChooseActivationPersonStep) {
        return this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.cta');
      }

      return this.$t('organisation_settings.tabs.pay_partners.cta');
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions('organisationCredentials', ['createOrganisationCredential', 'updateOrganisationCredential']),
    initData() {
      this.chips[0].label = this.activateMyselfChipLabel;
      this.chips[1].label = this.accountantActivationChipLabel;

      this.emailContent = this.defaultEmailContent;
      this.emailObject = this.defaultEmailObject;
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
      this.initData();
    },
    validateEmail() {
      this.isRecipientValid = isValidEmail(this.emailRecipient);
    },
    handleUpdateStep(nextStep) {
      if (this.currentStep === 2 && nextStep === 3) {
        this.$skAnalytics.track(
          this.activateMyselfChip.isSelected ?
            'fortify_silae_keys_self_submit' :
            'fortify_silae_keys_accountant_submit',
        );
      }

      if (this.currentStep === 1 && nextStep === 2) {
        this.$skAnalytics.track('fortify_silae_activation_modal_next');
      }

      this.currentStep = nextStep;
    },
    handleClose(event) {
      if (this.currentStep !== 3) {
        this.$refs.silaeActivationModal.hide();
        return;
      }

      if (
        this.activateMyselfChip.isSelected &&
        (!this.clientId && !this.mainKey && !this.clientSecret)
      ) {
        return;
      }

      if (
        this.accountantActivationChip.isSelected &&
        this.emailContent === this.defaultEmailContent &&
        this.emailObject === this.defaultEmailObject
      ) {
        return;
      }

      event.preventDefault();

      const baseKey = this.activateMyselfChip.isSelected ?
        'organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.confirm.keys' :
        'organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.confirm.email';

      this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
        cancelLabel: this.$t(`${baseKey}.cancel`),
        description: this.$t(`${baseKey}.description`),
        onConfirm: this.$refs.silaeActivationModal.hide,
        submitLabel: this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.confirm.submit'),
        title: this.$t(`${baseKey}.title`),
      });
    },
    async handleSubmit() {
      this.isSubmitting = true;

      if (this.landOnCredentialsStep) {
        this.$skAnalytics.track('fortify_silae_keys_update');
      }

      const isActivatingThemself = this.activateMyselfChip.isSelected || this.landOnCredentialsStep;
      const isIntegrationPending = this.isIntegrationEnabled('SILAE') && this.isIntegrationPending('SILAE');

      const params = { name: 'SILAE' };

      if (isActivatingThemself || isIntegrationPending) {
        this.$skAnalytics.track('fortify_silae_activation_modal_configuration');

        params.status = 'active';
        params.value = {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          configurationId: this.mainKey,
        };
      } else {
        params.status = 'pending';
      }

      try {
        const promises = [];

        promises.push(this.landOnCredentialsStep ?
          this.updateOrganisationCredential(params) :
          this.createOrganisationCredential(params),
        );

        if (!isActivatingThemself) {
          // do not show the filling step directly once the pending credential created
          this.hasEmailJustBeenSent = true;

          this.$skAnalytics.track('fortify_silae_accountant_email_sent');

          promises.push(
            httpClient.post('/v3/api/mailing/send_raw_email', {
              mailing: {
                body: this.emailContent,
                recipient: this.emailRecipient,
                subject: this.emailObject,
              },
            }),
          );
        }

        await Promise.all(promises);

        if (!isActivatingThemself) {
          this.$skToast({
            variant: 'success',
            message: this.$t('organisation_settings.tabs.pay_partners.fortify.activation_modal.accountant_step.success'),
          });
        }

        this.$refs.silaeActivationModal.hide();
        this.$refs.silaeActivationModal.currentStep = 1;
        this.$emit('submit');
      } catch (error) {
        this.$skToast({ message: this.$t('organisation_settings.tabs.pay_partners.enable.organisation.failure'), variant: 'error' });

        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  // remove global boostrap styles
  p {
    margin: 0;
  }

  hr {
    border: none;
  }

  .silae-activation-modal {
    &__header-icon {
      position: absolute;
      border: 1px solid $sk-grey-10;
      border-radius: 20px 20px 20px 20px;
      background-color: $sk-white;
      height: 104px;
      width: 104px;
      top: 24px;
      left: 24px;

      img {
        display: inline-block;
      }
    }

    &--text {
      color: $sk-grey-50;
    }

    &--description {
      margin-bottom: 24px;
    }

    &--divider {
      background-color: $sk-grey-10;
      height: 1px;
      width: 100%;
      border: none;
      margin: 0;
    }
  }

  .silae-activation-modal__content {
    display: flex;
    flex-direction: column;
    margin: 8px 24px;

    a {
      margin-bottom: 24px;
    }

    &--activation-header {
      margin-bottom: 24px;
    }

    &--configuration-step-header {
      margin-bottom: 16px;
    }
  }

  .silae-activation-modal__email {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid $sk-grey-10;
    border-radius: 8px;
  }

  .silae-activation-modal__sender {
    display: flex;
  }

  .silae-activation-modal__sender-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
    gap: 2px;

    span:last-child {
      color: $sk-grey-50;
    }
  }

  .silae-activation-modal__card {
    display: flex;
    flex-direction: column;
    width: 255.5px;
  }

  .silae-activation-modal__card-description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
  }

  .silae-activation-modal__evp-header {
    display: flex;
    justify-content: space-between;
  }

  .silae-activation-modal__evp-header-icon {
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $sk-warning-10;
    height: 32px;
    width: 32px;
  }

  .silae-activation-modal__card-container {
    display: flex;
  }

  .silae-activation-modal__card-divider {
    background-color: $sk-grey-10;
    border-radius: 1px;
    height: auto;
    margin: 0 32px;
    width: 1px;
  }

  .silae-activation-modal__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .silae-activation-modal__input-row {
    display: flex;
    align-items: center;

    label {
      width: 146px;
      margin-right: 16px;
      flex-shrink: 0;
      margin-bottom: .5rem;
      display: inline-block;
    }
  }
</style>
