<template>
  <!-- eslint-disable  max-len -->
  <div
    :class="columnClasses"
    @click="$emit('click', $event)"
  >
    <div class="employee-counters__table-year-column-title">
      {{ year.year }}
    </div>
    <div
      v-if="year.closed"
      class="employee-counters__table-year-column-cells"
    >
      <TableCell :tooltip="cellTitle" />
      <TableCell :tooltip="cellTitle" />
      <TableCell :tooltip="cellTitle" />
      <TableCell
        v-if="showVariableContractHoursLine"
        :tooltip="cellTitle"
      />
      <TableCell
        v-if="majoration"
        :data="roundedValue(year.over_hours_w_majoration)"
        :subtitle="year.over_hour"
        :tooltip="cellTitle"
      />
      <TableCell
        :show-dash="year.show_dash"
        :data="roundedValue(year.hours_counter - year.manual_changes)"
        :tooltip="cellTitle"
        color="#ff9600"
      />
      <TableCell
        :show-dash="year.show_dash"
        :data="roundedValue(year.manual_changes)"
        :tooltip="cellTitle"
      />
      <TableCell
        :show-dash="year.show_dash"
        :data="roundedValue(year.hours_counter)"
        :tooltip="cellTitle"
        color="#ff9600"
      />
    </div>
    <div
      v-else
      class="employee-counters__table-months-wrapper"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { roundFloat } from '@skello-utils/formatting/numbers';
import { TableCell } from '../shared/components';

export default {
  name: 'HoursCounterYearColumn',
  components: { TableCell },
  props: {
    year: {
      type: Object,
      required: true,
    },
    showVariableContractHoursLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('employeeCounters', ['majoration']),
    columnClasses() {
      return {
        'employee-counters__table-year-column': true,
        'employee-counters__table-year-column--open': !this.year.closed,
        'employee-counters__table-year-column--previsional': this.year.previsional,
      };
    },
    cellTitle() {
      return this.year.closed ? this.$i18n.t('employees.tabs.counters.hours_counter.tooltip.show_more') : '';
    },
  },
  methods: {
    roundedValue(value) {
      return roundFloat(value, 2);
    },
  },
};
</script>
