<template>
  <div :class="bannerContainerClasses">
    <div class="free-trial-banner__wrapper">
      <img
        :src="packOfferImage"
        class="free-trial-banner__wrapper__image"
      >
      <div class="free-trial-banner__wrapper__main-content">
        <div class="free-trial-banner__wrapper__main-content__title-container">
          <SkOroraTag
            class="free-trial-banner__wrapper__main-content__title-container__tag"
            size="large"
            :icon="tagIcon"
            :variant="tagVariant"
          >
            {{ $t(`organisation_settings.tabs.organisation_info.free_trial_banner.tag.${tagTextKey}`) }}
          </SkOroraTag>
          <span class="free-trial-banner__wrapper__main-content__title-container__text">
            {{ $t(`organisation_settings.tabs.organisation_info.free_trial_banner.title.${packOfferName}`) }}
          </span>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="free-trial-banner__wrapper__main-content__paragraph"
          v-html="paragraphText"
        />
        <!-- eslint-enable vue/no-v-html -->
        <SkOroraButton
          class="free-trial-banner__wrapper__main-content__submit"
          :href="buttonHref"
          :icon="buttonIcon"
          :variant="buttonVariant"
          @click.stop="handleClick"
        >
          {{ buttonText }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features';
import BasicPackImg from './assets/basic.png';
import PremiumPackImg from './assets/premium.png';
import SuccessPackImg from './assets/success.png';
import TimeclockStandaloneSuccessPackImg from './assets/timeclock_standalone_success.png';
import BasicDisabledPackImg from './assets/basic-disabled.png';
import PremiumDisabledPackImg from './assets/premium-disabled.png';
import SuccessDisabledPackImg from './assets/success-disabled.png';
import TimeclockStandaloneSuccessDisabledPackImg from './assets/timeclock_standalone_success-disabled.png';

export default {
  name: 'FreeTrialBanner',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      packOfferImages: {
        enabled: {
          basic: BasicPackImg,
          premium: PremiumPackImg,
          success: SuccessPackImg,
          timeclock_standalone_success: TimeclockStandaloneSuccessPackImg,
        },
        disabled: {
          basic: BasicDisabledPackImg,
          premium: PremiumDisabledPackImg,
          success: SuccessDisabledPackImg,
          timeclock_standalone_success: TimeclockStandaloneSuccessDisabledPackImg,
        },
      },
    };
  },
  computed: {
    ...mapGetters('currentOrganisation', ['freeTrialDaysLeft']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation']),

    bannerContainerClasses() {
      return {
        'free-trial-banner': true,
        [`free-trial-banner--${this.packOfferName}`]: !this.disabled,
        'free-trial-banner--disabled': this.disabled,
      };
    },
    buttonHref() {
      return !this.disabled ? null : `tel:${this.phoneNumber}`;
    },
    buttonIcon() {
      return this.disabled ? 'PhoneIcon' : null;
    },
    buttonText() {
      if (!this.disabled) {
        return this.$t('organisation_settings.tabs.organisation_info.free_trial_banner.submit.active');
      }

      return [
        this.$t('organisation_settings.tabs.organisation_info.free_trial_banner.submit.call_us'),
        this.phoneNumber,
      ].join(' ');
    },
    buttonVariant() {
      return this.disabled ? 'primary' : 'secondary';
    },
    tagIcon() {
      return this.disabled ? null : 'GiftIcon';
    },
    tagTextKey() {
      return this.disabled ? 'inactive' : 'active';
    },
    tagVariant() {
      return this.disabled ? 'disabled' : 'primary';
    },
    packOfferImage() {
      return this.disabled ?
        this.packOfferImages.disabled[this.packOfferName] :
        this.packOfferImages.enabled[this.packOfferName];
    },
    packOfferName() {
      return this.isDevFlagEnabled('FEATUREDEV_V5_SELF_SERVE') && this.isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK, 'all') ?
        'timeclock_standalone_success' :
        this.currentOrganisation.attributes.packOffer.name;
    },
    paragraphText() {
      const remainingCount = this.$tc(
        'organisation_settings.tabs.organisation_info.free_trial_banner.description.remaining_count',
        this.freeTrialDaysLeft,
      );

      const paragraphKey = this.disabled ? 'inactive' : 'active';

      return this.$t(
        `organisation_settings.tabs.organisation_info.free_trial_banner.description.paragraph.${paragraphKey}`,
        { remainingCount },
      );
    },
    phoneNumber() {
      return this.$t('organisation_settings.tabs.organisation_info.free_trial_banner.submit.phone_number');
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        this.$skAnalytics.track('opt_out_call_cancelled_subscription');
        return;
      }
      this.$skAnalytics.track('opt_out_cancel_subscription');
      this.emitOnRoot('show-cancel-free-trial-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.free-trial-banner {
  display: flex;
  min-height: 256px;
  padding: 4px;
  border-radius: 8px;
  margin: 40px 0;

  &--basic { background: $sk-basic-gradient; }

  &--success { background: $sk-success-gradient; }

  &--premium { background: $sk-premium-gradient; }

  &--timeclock_standalone_success { background: $sk-timeclock-standalone-success-gradient; }

  &--disabled {
    background: $sk-grey-10;
  }

  &__wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 4px;
    background: $sk-white;

    &__image {
      border-radius: 4px;
      height: 248px;
      width: 248px;
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      padding-left: 24px;
      margin: 24px 48px 24px 0;

      &__title-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        &__tag {
          max-width: fit-content;
        }

        &__text {
          margin-top: 8px;
          font-size: $fs-heading-m;
          font-weight: $fw-semi-bold;
          line-height: 27px;
        }
      }

      &__paragraph {
        font-size: $fs-text-l;
        line-height: 18px;
      }

      &__submit {
        margin-top: 24px;
      }
    }
  }
}
</style>

<style lang="scss">
// We need to use unscoped style to target elements render via v-html
.free-trial-banner__wrapper__main-content__paragraph b {
  font-weight: $fw-semi-bold;
}
</style>
