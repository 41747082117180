<template>
  <SkKpisModal
    ref="duplicateModal"
    :close="close"
    class="sk-duplicate__modal"
  >
    <template #header>
      <div class="kpis__header">
        <div class="kpis__title">
          {{ $t('kpis.modals.duplicate.title', { previous_week_number: previousWeekNumber } ) }}
        </div>
        <div class="kpis__subtitle">
          {{
            $t('kpis.modals.duplicate.subtitle', {
              last_monday: lastMonday, last_sunday: lastSunday
            })
          }}
        </div>
      </div>
    </template>
    <template #body>
      <!-- eslint-disable-next-line vue/no-v-html max-len -->
      <p v-html="$t('kpis.modals.duplicate.body', { week_number: weekNumber, previous_week_number: previousWeekNumber })" />
      <div
        v-if="anyRevenueOrVolume"
        class="kpis__alert"
      >
        <SkInnerBanner
          variant="danger"
          rounded
        >
          {{ $t('kpis.modals.duplicate.erase_alert', { week_number: weekNumber}) }}
        </SkInnerBanner>
      </div>
      <p v-else>
        {{ $t('kpis.modals.duplicate.subbody') }}
      </p>
    </template>
    <template #footer>
      <footer class="sk-confirm-modal__footer">
        <SkOroraButton
          variant="secondary"
          @click.stop="onCancelButton"
        >
          <div>
            {{ $t('kpis.modals.duplicate.buttons.cancel') }}
          </div>
        </SkOroraButton>
        <SkOroraButton
          variant="primary"
          @click="duplicatePredictedVolumesAndRevenues"
        >
          <div>
            {{ $t('kpis.modals.duplicate.buttons.submit') }}
          </div>
        </SkOroraButton>
      </footer>
    </template>
  </SkKpisModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import SkKpisModal from '../SkKpisModal';

export default {
  name: 'DuplicateModal',
  // eslint-disable-next-line vue/no-unused-components
  components: { SkKpisModal },
  props: {
    close: {
      type: Function,
      required: true,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
    predictedKpis: {
      type: Object,
      required: true,
    },
    userKpisSettings: {
      type: Object,
      required: true,
    },
    kpiVolumeName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsState', ['filters']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    weekNumber() {
      return this.date.isoWeek();
    },
    previousWeekNumber() {
      return skDate(this.date).subtract(1, 'week').isoWeek();
    },
    lastMonday() {
      return skDate(this.date).subtract(1, 'week').format('dddd Do');
    },
    lastSunday() {
      return skDate(this.date).subtract(1, 'day').format('dddd Do MMMM YYYY');
    },
    anyRevenueOrVolume() {
      let kpisValuesArray = this.predictedKpis.revenue.daily;

      if (this.userKpisSettings.volume) {
        kpisValuesArray = kpisValuesArray.concat(this.predictedKpis.volume.daily);
      }

      // next filter allows to remove all unwanted values like 0, null, undefined etc
      // allowing to check that at least one value was filled
      return kpisValuesArray.filter(item => item).length > 0;
    },
  },
  methods: {
    ...mapActions('planningsKpis', ['fetchKpis']),
    onCancelButton() {
      this.$skAnalytics.track('duplicated_last_week_cancel');
      this.close();
    },
    duplicatePredictedVolumesAndRevenues() {
      httpClient
        .patch(
          '/v3/api/plannings/kpis/duplicate', {
            shop_id: this.currentShop.id,
            date: this.date.format('DD-MM-YYYY'),
            duplicate_volume: this.userKpisSettings.volume,
          },
        )
        .then(() => {
          this.$skToast({
            message: this.$t('kpis.modals.duplicate.toasts.success'),
            variant: 'success',
            containerId: 'kpis__wrapper',
          });
          this.$skAnalytics.track('duplicated_last_week');
          this.close();
        })
        .catch(() => {
          this.emitOnRoot('kpi-request-failure');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-duplicate__modal {
  color: $sk-black;
  -webkit-font-smoothing: antialiased;

  ::v-deep .sk-modal__header {
    border-bottom: 1px solid $sk-grey-10;
  }

  ::v-deep .sk-modal__body {
    margin: 0 21px !important;
    padding: 24px 0 0 !important;
    font-size: $fs-text-m;
    line-height: 22px;
  }

  footer.sk-confirm-modal__footer {
    display: flex;
    padding: 25px;
  }
}

.kpis__alert {
  font-weight: $fw-regular;
  font-style: normal;
  font-size: $fs-text-m;
  line-height: 22px;
  margin-bottom: 5px;

  .sk-inner-banner {
    padding: 13px 24px;
    font-size: $fs-text-m;
    line-height: 22px;
  }

  ::v-deep svg {
    margin: 4px auto 0;
  }
}

.kpis__subtitle {
  font-weight: normal;
  font-size: $fs-text-m;
  color: $sk-grey;

  &::first-letter {
    text-transform: capitalize;
  }
}
</style>
