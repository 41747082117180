<script>
import {
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';

import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'SmartPlannerAction',
  components: {},
  extends: GenericToolbarAction,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapGetters('planningsAutomaticPlanning', ['isBrainFeatureFlagActive', 'isBrainEnabled']),
    ...mapGetters('planningsState', [
      'isAnyDayLocked',
      'isDailyView',
    ]),
    title() {
      return this.$t('plannings.toolbar.actions_bar.icons_labels.automatic_planning.label');
    },
    icon() {
      return 'MagicWandV2Icon';
    },
    visibleIn() {
      return 'toolbar-m-dropdown-s';
    },
    isNew() {
      return this.isBrainFeatureFlagActive;
    },
    disabled() {
      return this.isAnyDayLocked;
    },
    automaticPlanningTooltip() {
      if (this.isAnyDayLocked) {
        return this.$t('plannings.toolbar.actions_bar.automatic_planning.planning_locked');
      }
      return '';
    },
    tooltip() {
      return this.automaticPlanningTooltip;
    },
    isVisible() {
      return !this.isDailyView && this.currentLicense.attributes.canCreateShifts;
    },
    dataTest() {
      return 'planning-toolbar__optimization';
    },
    active() {
      return this.isBrainFeatureFlagActive;
    },
  },
  methods: {
    ...mapMutations('planningsAutomaticPlanning', ['setBrainShiftsActivation']),
    handler() {
      this.$skAnalytics.track('automatic_planning_button_click');
      if (this.isBrainFeatureFlagActive) {
        if (!this.isBrainEnabled) {
          this.$skAnalytics.track('automatic_planning_enable_ai_v1', {
            source: 'sp_suggested_toolbar_button',
          });
          this.setBrainShiftsActivation(true);
        }

        this.emitOnRoot('set-schedule-recommendation-popover-visibility', {
          show: true,
          initiator: 'toolbar_button',
        });
      } else {
        this.emitOnRoot('open-automatic-planning');
      }
    },
  },
};
</script>
