<template>
  <SkOroraDialog
    :id="modalId"
    :ref="modalRef"
    :title="modalTitle"
    :is-submit-disabled="isDisabled"
    :is-submit-loading="isLoading || isSubmitLoading"
    :submit-button-label="submitButtonLabel"
    size="medium"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <main class="generic-authentication-modal">
        <section>
          <span>{{ modalDescription }}</span>
          <span v-if="isLegacyPOS">{{ modalSubDescription }}</span>
        </section>
        <section class="generic-authentication-modal__inputs-container">
          <div
            v-for="(modalRequirement, index) in modalRequirements"
            :key="`modalRequirement-${index}`"
          >
            <SkInput
              v-model="inputValues[modalRequirement.name]"
              :label="getInputLabel(modalRequirement.name)"
              :errored="hasInputError"
              :error-message="getErrorMessageForNthChild(index)"
              @input="handleInputModification"
            />
          </div>
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'GenericAuthenticationModal',
  props: {
    isSubmitLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      authenticationType: '',
      hasInputError: false,
      integration: {},
      inputValues: {},
      inputErrorMessage: '',
      isSubmitDisabled: true,
      isLoading: false,
      shopId: null,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    providerId() {
      return this.integration.id || '';
    },
    lowercaseProviderId() {
      return this.providerId.toLowerCase();
    },
    uppercaseProviderId() {
      return this.providerId.toUpperCase();
    },
    modalId() {
      return `${this.lowercaseProviderId}-authentication-modal`;
    },
    modalRef() {
      return `${this.lowercaseProviderId}AuthenticationModal`;
    },
    modalTitle() {
      if (this.isLegacyPOS) {
        return this.$t(`organisation_settings.tabs.pos_partners.${this.lowercaseProviderId}.modal.title`);
      }

      return this.$t('organisation_settings.tabs.pos_partners.chift.activate_modal.title', { name: this.providerId });
    },
    modalDescription() {
      if (this.isLegacyPOS) {
        return this.$t(`organisation_settings.tabs.pos_partners.${this.lowercaseProviderId}.modal.description`);
      }

      return this.$t('organisation_settings.tabs.pos_partners.chift.activate_modal.description');
    },
    modalSubDescription() {
      return this.$t(`organisation_settings.tabs.pos_partners.${this.lowercaseProviderId}.modal.sub_description`);
    },
    submitButtonLabel() {
      return this.$t('organisation_settings.tabs.pos_partners.generic.authentication_modal.submit');
    },
    authenticationErrorMessage() {
      return this.$t(
        'organisation_settings.tabs.pos_partners.generic.authentication_modal.error.global',
        { error: this.inputErrorMessage },
      );
    },
    modalRequirements() {
      const requirements = this.integration.requirements;

      if (!requirements || !(this.authenticationType in requirements)) {
        return [];
      }

      return requirements[this.authenticationType]
        .actions
        .find(action => action.type === 'fill_form')
        ?.fields || [];
    },
    isDisabled() {
      return this.modalRequirements.length > 0 && this.isSubmitDisabled;
    },
    isLegacyPOS() {
      return ['TILLER', 'ZELTY', 'ADDITTION', 'REVO', 'AGORA'].includes(this.uppercaseProviderId);
    },
  },
  methods: {
    reinitializeComponentData() {
      Object.assign(this.$data, this.$options.data());
    },
    initializeInputValues() {
      this.modalRequirements.forEach(modalRequirement => {
        this.inputValues[modalRequirement.name] = '';
      });
    },
    openModal({ integration, authenticationType, shopId }) {
      this.integration = integration;
      this.authenticationType = authenticationType;
      this.shopId = shopId;

      this.$nextTick(() => {
        this.initializeInputValues();
        this.$refs[this.modalRef].show();
      });
    },
    closeModal() {
      this.$refs[this.modalRef].hide();
      this.reinitializeComponentData();
    },
    getInputLabel(inputKey) {
      const key = inputKey.toLowerCase();
      return this.$t(`organisation_settings.tabs.pos_partners.${this.lowercaseProviderId}.modal.${key}_input_label`);
    },
    getErrorMessageForNthChild(index) {
      if (index === this.modalRequirements.length - 1) {
        return this.authenticationErrorMessage;
      }

      return '';
    },
    handleInputModification() {
      this.hasInputError = false;
      this.inputErrorMessage = '';
      this.isSubmitDisabled = Object.values(this.inputValues).some(value => !value);
    },
    handleInputError(errorMessage) {
      this.hasInputError = true;
      this.inputErrorMessage = errorMessage;
      this.isLoading = false;
    },
    handleCancel() {
      this.$emit('cancel', { providerId: this.providerId });
      this.closeModal();
    },
    handleSubmit() {
      this.isLoading = true;

      const submitParams = {
        shopId: this.shopId,
        organisationId: this.currentOrganisation.id,
        providerId: this.uppercaseProviderId,
        config: this.inputValues,
        integration: this.integration,
      };

      this.$emit('continue', submitParams);
    },
  },
};

</script>

<style lang="scss" scoped>
.generic-authentication-modal {
  padding: 8px 24px;

  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }
}

</style>
