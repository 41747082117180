<template>
  <div class="sk-form-section">
    <h3 class="sk-form-section__title">
      {{ $t('shop_settings.tabs.rules.counter_rules.section_title') }}
    </h3>
    <SkForm
      class="sk-form__header__title__split"
      split
    >
      <template #title>
        <label>{{ $t('shop_settings.tabs.rules.counter_rules.modulation.activate') }}</label>
      </template>
      <div class="row sk-form__row">
        <div class="col-3 pl-0 shop-settings-rules__tag_and_switch">
          <SkSwitch
            v-if="isHoursCounterEnabled"
            v-model="isModulationActivated"
            v-tooltip="disabledModulationTooltip"
            :disabled="isModulationSwitchDisabled"
          />
          <UpsellPopover
            v-else
            :before-tag-text="$t('shop_settings.tabs.rules.counter_rules.modulation.upsell_tooltip_1')"
            :after-tag-text="$t('shop_settings.tabs.rules.counter_rules.modulation.upsell_tooltip_2')"
            :last-sentence="$t('shop_settings.tabs.rules.counter_rules.modulation.upsell_tooltip_3')"
          />
          <span v-if="!isHoursCounterEnabled">
            <PackTag
              type="success"
              shadow
            />
          </span>
        </div>
        <div
          v-if="modulationStatusPending"
          class="col-6 pl-0 shop-settings-rules__pending-button"
        >
          <SkOroraButton
            v-track="'hours_counter_initialization_resume'"
            v-modal.hours-counter-initialization-modal
            :disabled="!canEditEmployeeDataSettings"
            variant="tertiary"
            size="small"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.action') }}
          </SkOroraButton>
          <p class="shop-settings-rules__pending-explanation">
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.subtitle') }}
          </p>
        </div>
      </div>
    </SkForm>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <IndividualHoursCounterInitializationModal
        v-if="isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')"
        ref="hoursCounterInitializationModal"
        :employees="hoursCounterEmployees"
      />
      <HoursCounterInitializationModal
        v-else
        ref="hoursCounterInitializationModal"
      />
    </MountingPortal>
    <SkForm
      v-if="isModulationActivated"
      :title="$t('shop_settings.tabs.rules.counter_rules.modulation.subtitle_2')"
      split
    >
      <template>
        <div class="row sk-form__row">
          <div class="col-6 pl-0">
            <SkSelectV2
              :value="modulationType"
              :label="$t('shop_settings.tabs.rules.counter_rules.modulation.label')"
              :options="modulationTypes"
              :disabled="!canEditEmployeeDataSettings"
              @input="handleModulationTypeInput"
            />
          </div>
        </div>
      </template>
    </SkForm>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <ChangeModulationMajorationModal ref="changeModulationMajorationModal" />
    </MountingPortal>
    <!--
      this SkFrom is the last one only when
        - paid leave counter is disabled
        - annualization is unavailable
      otherwise, being the "last" is delegated to the last SkForms of the next sections
    -->
    <SkForm
      :title="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.activate')"
      :last="!isPaidLeavesCounterActivated && !isAnnualizationEnabled"
      split
    >
      <div class="row sk-form__row">
        <div class="col-3 pl-0">
          <SkSwitch
            v-model="isPaidLeavesCounterActivated"
            :disabled="!canEditEmployeeDataSettings"
            data-test="shop-settings__leaves-counter__initialization-switch"
          />
        </div>
        <div
          v-if="plcStatusPending"
          class="col-6 pl-0 shop-settings-rules__pending-button"
        >
          <SkOroraButton
            v-track="'paid_leaves_counter_initialization_resume'"
            v-modal.paid-leaves-counter-initialization-modal
            :disabled="!canEditEmployeeDataSettings"
            variant="tertiary"
            size="small"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.action') }}
          </SkOroraButton>
          <p class="shop-settings-rules__pending-explanation">
            {{ $t('shop_settings.tabs.rules.counter_rules.paid_leaves_counter.subtitle') }}
          </p>
        </div>
      </div>
    </SkForm>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <PtoPeriodsInitializationModal
        v-if="yearlyPtoDistinctionActivated"
        ref="paidLeavesCounterInitializationModal"
      />

      <PaidLeavesCounterInitializationModal
        v-else
        ref="paidLeavesCounterInitializationModal"
      />
    </MountingPortal>
    <template v-if="isPaidLeavesCounterActivated">
      <SkForm
        v-if="!yearlyPtoDistinctionActivated"
        :title="$t('shop_settings.tabs.rules.counter_rules.plc_start_month.title')"
        :last="!yearlyPtoDistinctionActivated && !isAnnualizationEnabled"
        split
      >
        <template>
          <div class="row sk-form__row">
            <div class="col-6 pl-0">
              <SkSelectV2
                v-model="plcStartMonth"
                :label="$t('shop_settings.tabs.rules.counter_rules.plc_start_month.label')"
                :options="plcStartMonthOptions"
                :disabled="!canEditShopRulesAndAbsences"
              />
            </div>
          </div>
        </template>
      </SkForm>
      <SkForm
        :title="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition.title')"
        split
      >
        <template>
          <div class="row sk-form__row">
            <div class="col-6 pl-0">
              <SkSelectV2
                v-model="paidVacationCalculationType"
                :label="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition.label')"
                :options="paidVacationCalculationTypes"
                :disabled="!canEditShopRulesAndAbsences"
              />
            </div>
          </div>
        </template>
        <template #title-icon>
          <span>
            <CircledQuestionMarkIcon
              v-tooltip.top="
                $t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition.tooltip')
              "
              width="18"
              height="18"
            />
          </span>
        </template>
      </SkForm>
      <!--
        this SkForm is the last one only when annualization is unavailable
        otherwise, being the "last" is delegated to the last SkForm of the next section
      -->
      <SkForm
        :title="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition_number.title')"
        :last="!isRttCounterFeatureAvailable && !isAnnualizationEnabled"
        split
      >
        <div class="row sk-form__row">
          <div class="col-6 pl-0">
            <SkInput
              v-model="paidVacationAcquisitionNumber"
              :disabled="!canEditShopRulesAndAbsences"
              :errored="!validatePaidVacationAcquisitionNumber"
              :error-message="paidVacationAcquisitionNumberErrorMessage"
              :label="$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition_number.label')"
              min="0"
              max="99.99"
              step="0.01"
              type="number"
            />
          </div>
        </div>
      </SkForm>
    </template>
    <SkForm
      v-if="isRttCounterFeatureAvailable"
      :title="$t('shop_settings.tabs.rules.counter_rules.rtt_counter.activate')"
      :last="
        !isAnnualizationEnabled || (isAnnualizationActive && isAnnualizationCounterCardDisplayed)
      "
      split
    >
      <div class="row sk-form__row">
        <div class="col-3 pl-0 shop-settings-rules__tag_and_switch">
          <SkSwitch
            v-if="isRttCounterAvailable"
            v-model="isRttCounterEnabled"
            v-tooltip="disabledRttModalTooltip"
            :disabled="!canEditEmployeeDataSettings || dailyRateEmployees.length === 0"
            data-test="shop-settings__rtt-counter__initialization-switch"
          />
          <UpsellPopover
            v-else
            :before-tag-text="$t('shop_settings.tabs.rules.counter_rules.rtt_counter.upsell_tooltip_1')"
            :after-tag-text="$t('shop_settings.tabs.rules.counter_rules.rtt_counter.upsell_tooltip_2')"
            :last-sentence="$t('shop_settings.tabs.rules.counter_rules.rtt_counter.upsell_tooltip_3')"
          />
          <span v-if="!isRttCounterAvailable">
            <PackTag
              type="success"
              shadow
            />
          </span>
        </div>
        <div
          v-if="isRttCounterStatusPending"
          class="col-6 pl-0 shop-settings-rules__pending-button"
        >
          <SkOroraButton
            v-modal.rtt-modal
            :disabled="!canEditEmployeeDataSettings"
            variant="tertiary"
            size="small"
          >
            {{ $t('shop_settings.tabs.rules.counter_rules.modulation.action') }}
          </SkOroraButton>
          <p class="shop-settings-rules__pending-explanation">
            {{ $t('shop_settings.tabs.rules.counter_rules.rtt_counter.subtitle') }}
          </p>
        </div>
      </div>
    </SkForm>
    <AnnualizationCounter
      v-if="isAnnualizationEnabled"
      :can-edit-employee-data-settings="canEditEmployeeDataSettings"
    />
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <AnnualizationConfigModal />
      <RttConfigModal ref="rttModal" />
    </MountingPortal>
    <HolidaysCounter class="holiday-settings__container" />
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import { captureException } from '@sentry/vue';

import {
  httpClient,
  msHttpClient,
} from '@skello-utils/clients';

import { svcEmployeesUrl } from '@config/env';
import { arrayToSelectOptions } from '@skello-utils/form';
import PackTag from '@app-js/shared/components/PackTag';

import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import IndividualHoursCounterInitializationModal from './IndividualHoursCounterInitializationModal';
import HoursCounterInitializationModal from './HoursCounterInitializationModal';
import PaidLeavesCounterInitializationModal from './PaidLeavesCounter/PaidLeavesCounterInitializationModal';
import PtoPeriodsInitializationModal from './PaidLeavesCounter/PtoPeriodsInitializationModal';
import RttConfigModal from './RttConfigModal';
import ChangeModulationMajorationModal from './ChangeModulationMajorationModal';
import AnnualizationConfigModal from './AnnualizationConfigModal';
import HolidaysCounter from './HolidaysCounter';
import UpsellPopover from './UpsellPopover';
import AnnualizationCounter from './AnnualizationCounter/index.vue';

const FRENCH_OPENING_DAY_ACQUISITION = 2.5;
const FRENCH_WORKING_DAY_ACQUISITION = 2.08;

export default {
  name: 'CounterRules',
  components: {
    AnnualizationConfigModal,
    AnnualizationCounter,
    ChangeModulationMajorationModal,
    HolidaysCounter,
    HoursCounterInitializationModal,
    IndividualHoursCounterInitializationModal,
    PackTag,
    PaidLeavesCounterInitializationModal,
    PtoPeriodsInitializationModal,
    RttConfigModal,
    UpsellPopover,
  },
  data() {
    return {
      confirmHoursCounterLoading: false,
      confirmPaidLeavesCounterLoading: false,
      hoursCounterEmployees: [],
      isFetchingHoursCounterEmployees: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapState('shopEmployees', ['employees']),
    ...mapState('shopAbsenceConfig', ['shopAbsenceConfig']),
    ...mapState('shopAnnualizationConfig', ['shopAnnualizationConfig']),
    ...mapGetters('config', ['dailyRateCounterType']),
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences', 'canEditEmployeeInfo']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', [
      'checkFeatureFlag',
      'isAnnualizedWorkingTimeAvailable',
      'isDevFlagEnabled',
      'isRttCounterAvailable',
    ]),
    ...mapGetters('shopEmployees', ['annualizableEmployees']),
    disabledModulationTooltip() {
      if (
        !this.canEditEmployeeDataSettings ||
        this.isModulationActivated ||
        this.isFetchingHoursCounterEmployees ||
        this.hoursCounterEmployees.length > 0
      ) return '';

      return this.$t('shop_settings.tabs.rules.counter_rules.modulation.modal.balance.empty_users_explanation');
    },
    isAnnualizationActive() {
      // fetched in AnnualizationCounter
      return !!this.shopAnnualizationConfig;
    },
    isAnnualizationCounterCardDisplayed() {
      return this.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ADVANCED_PARAMETERS') &&
        this.isDevFlagEnabled('FEATUREDEV_ANNUALIZED_WORKING_TIME_V2_ADVANCED_PARAMETERS_PHASE2');
    },
    isModulationSwitchDisabled() {
      let isModulationSwitchDisabled = !this.canEditEmployeeDataSettings;

      if (this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER')) {
        isModulationSwitchDisabled ||=
          this.hoursCounterEmployees.length === 0 &&
          !this.isModulationActivated;
      }

      return isModulationSwitchDisabled;
    },
    isModulationActivated: {
      get() {
        return (this.currentShop.attributes.modulation || this.modulationStatusPending) &&
          this.isHoursCounterEnabled;
      },
      set(newValue) {
        if (newValue) {
          this.$skAnalytics.track('hours_counter_enable');
          this.emitOnRoot(
            MODAL_SHOW_EVENT,
            event,
            this.$refs.hoursCounterInitializationModal.$el.id,
          );
        } else {
          this.$skAnalytics.track('hours_counter_disable');
          this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
            actionText: this.$t('actions.continue'),
            description: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.hours_counter.${this.currentShop.attributes.modulationStatus}.description`),
            onConfirmAsync: this.deactivateHoursCounter,
            submitLabel: this.$t('shop_settings.tabs.rules.counter_rules.deactivate_modal.hours_counter.modulation_activated.submit'),
            title: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.hours_counter.${this.currentShop.attributes.modulationStatus}.title`),
          });
        }
      },
    },
    isPaidLeavesCounterActivated: {
      get() {
        return this.currentShop.attributes.plcInitialized || this.plcStatusPending;
      },

      set(newValue) {
        if (newValue) {
          this.$skAnalytics.track('paid_leaves_counter_enable');
          this.emitOnRoot(
            MODAL_SHOW_EVENT,
            event,
            'paid-leaves-counter-initialization-modal',
          );
        } else {
          this.$skAnalytics.track('paid_leaves_counter_disable');
          this.$root.$emit('confirm', event, {
            title: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.paid_leaves_counter.${this.currentShop.attributes.plcStatus}.title`),
            description: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.paid_leaves_counter.${this.currentShop.attributes.plcStatus}.description`),
            actionText: this.$t('actions.continue'),
            onConfirmSuccess: this.deactivatePaidLeavesCounter,
          });
        }
      },
    },
    isAnnualizationEnabled() {
      return this.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ENABLED');
    },
    isRttCounterEnabled: {
      get() {
        // null means not set
        return !!this.shopAbsenceConfig;
      },
      set(isActive) {
        if (isActive) {
          this.emitOnRoot(
            MODAL_SHOW_EVENT,
            event,
            this.$refs.rttModal.$el.id,
          );
          this.$skAnalytics.track('rtt_counter_activation');
        } else {
          this.$root.$emit('confirm', event, {
            title: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.rtt_counter.${this.shopAbsenceConfig?.attributes?.rttConfig?.initialization_status}.title`),
            description: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.rtt_counter.${this.shopAbsenceConfig?.attributes?.rttConfig?.initialization_status}.description`),
            actionText: this.$t('actions.continue'),
            onConfirmSuccess: this.deactivateRttCounter,
          });
        }
      },
    },
    plcStartMonth: {
      get() {
        return this.currentShop.attributes.plcStartMonth;
      },

      set(newValue) {
        this.setShopAttributes({ plcStartMonth: newValue });
      },
    },
    paidVacationCalculationType: {
      get() {
        return this.currentShop.attributes.paidVacationCalculationType;
      },

      set(newValue) {
        this.setShopAttributes({ paidVacationCalculationType: newValue });
        // if number is default value and we change calculation_type
        // update number value
        const openingDay = this.config.absence_data.opening_day_hours_key;
        const workingDay = this.config.absence_data.working_day_hours_key;
        const calendarDay = this.config.absence_data.calendar_day_hours_key;

        if (
          (newValue === this.config.paid_vacation_calculation_types[openingDay] ||
            newValue === this.config.paid_vacation_calculation_types[calendarDay]) &&
          parseFloat(this.paidVacationAcquisitionNumber) === FRENCH_WORKING_DAY_ACQUISITION
        ) {
          this.paidVacationAcquisitionNumber = FRENCH_OPENING_DAY_ACQUISITION;
        } else if (
          newValue === this.config.paid_vacation_calculation_types[workingDay] &&
          parseFloat(this.paidVacationAcquisitionNumber) === FRENCH_OPENING_DAY_ACQUISITION
        ) {
          this.paidVacationAcquisitionNumber = FRENCH_WORKING_DAY_ACQUISITION;
        }
      },
    },
    paidVacationAcquisitionNumber: {
      get() {
        return this.currentShop.attributes.paidVacationAcquisitionNumber;
      },

      set(newValue) {
        this.setShopAttributes({ paidVacationAcquisitionNumber: newValue });

        this.currentShopError(!this.validatePaidVacationAcquisitionNumber);
      },
    },
    validatePaidVacationAcquisitionNumber() {
      return this.paidVacationAcquisitionNumber !== '' &&
        this.paidVacationAcquisitionNumber >= 0 &&
        this.paidVacationAcquisitionNumber < 100;
    },
    dailyRateEmployees() {
      return this.employees.filter(user => (
        user.relationships.contract.attributes.counterType === this.dailyRateCounterType
      ));
    },
    disabledRttModalTooltip() {
      if (this.dailyRateEmployees.length > 0) return '';

      return this.$t('shop_settings.rtt_modal.empty_users_explanation');
    },
    paidVacationAcquisitionNumberErrorMessage() {
      if (this.paidVacationAcquisitionNumber === '') {
        return this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition_number.error_null');
      } if (this.paidVacationAcquisitionNumber < 0) {
        return this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition_number.error_min');
      }
      return this.$t('shop_settings.tabs.rules.counter_rules.paid_leaves_acquisition_number.error_max');
    },
    modulationType() {
      return this.currentShop.attributes.modulationMajoration;
    },
    modulationTypes() {
      return [
        { id: true, text: this.$t('shop_settings.tabs.rules.counter_rules.modulation.with_majoration') },
        { id: false, text: this.$t('shop_settings.tabs.rules.counter_rules.modulation.without_majoration') },
      ];
    },
    modulationStatusPending() {
      return this.currentShop.attributes.modulationStatus === this.config.modulation_pending;
    },
    plcStatusPending() {
      return this.currentShop.attributes.plcStatus === this.config.plc_pending;
    },
    plcStartMonthOptions() {
      return arrayToSelectOptions(
        this.config.plc_start_months,
        value => this.$t(`shop_settings.tabs.rules.counter_rules.plc_start_months.${value}`),
      );
    },
    paidVacationCalculationTypes() {
      return arrayToSelectOptions(
        this.config.paid_vacation_calculation_types,
        value => this.$t(`shop_settings.tabs.rules.counter_rules.paid_vacation_calculation_types.${value}`),
      );
    },
    canEditEmployeeDataSettings() {
      return this.canEditShopRulesAndAbsences && this.canEditEmployeeInfo;
    },
    isHoursCounterEnabled() {
      return this.checkPackOfferFlag('hours_counter_enabled');
    },
    yearlyPtoDistinctionActivated() {
      return this.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER');
    },
    isRttCounterFeatureAvailable() {
      return this.checkFeatureFlag('FEATURE_RTT_TRACKER');
    },
    isRttCounterStatusPending() {
      return this.shopAbsenceConfig?.attributes?.rttConfig?.initialization_status ===
        this.config.shop_absence_config.pending;
    },
  },
  mounted() {
    if (this.isRttCounterAvailable) {
      this.fetchShopAbsenceConfig(this.currentShop.id);
    }

    if (this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER') && !this.isModulationActivated) {
      this.fetchHoursCounterEmployees();
    }
  },
  methods: {
    ...mapActions('currentShop', ['updateShop']),
    ...mapActions('shopAbsenceConfig', ['fetchShopAbsenceConfig', 'deleteAbsenceConfig']),
    ...mapMutations('currentShop', ['currentShopError', 'setShopAttributes']),
    async fetchHoursCounterEmployees() {
      this.isFetchingHoursCounterEmployees = true;

      try {
        const response = await httpClient.get('/v3/api/users/hours_counters_users', { params: { shop_id: this.currentShop.id } });

        this.hoursCounterEmployees = response.data.data;
      } catch (error) {
        this.$skToast({
          message: this.$t('shop_settings.tabs.rules.counter_rules.modulation.fetch_error'),
          variant: 'error',
        });

        captureException(error);
      } finally {
        this.isFetchingHoursCounterEmployees = false;
      }
    },
    handleModulationTypeInput(newValue, event) {
      if (newValue === this.modulationType) return;

      this.emitOnRoot(MODAL_SHOW_EVENT, event, this.$refs.changeModulationMajorationModal.$el.id);
    },
    deactivateHoursCounter() {
      this.$skAnalytics.track('hours_counter_disable_confirmed');
      const messageKey =
        this.currentShop.attributes.modulationStatus === this.config.modulation_pending ?
          this.config.modulation_pending : this.config.modulation_activated;
      this.confirmHoursCounterLoading = true;

      return httpClient
        .patch(
          `/v3/api/shops/${this.currentShop.id}/initial_counters`,
          { cancel_modulation_activation: true },
        )
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_HOUR_TRACKER_DISABLE);

          this.setShopAttributes({
            modulation: false,
            modulationStatus: this.config.modulation_not_activated,
            modulationMajoration: false,
            countersInitializationDoneAt: '',
          });
          this.$skToast({
            message: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.hours_counter.${messageKey}.success`),
            variant: 'success',
          });

          if (this.isDevFlagEnabled('FEATUREDEV_INDIVIDUAL_HOURS_COUNTER') && !this.isModulationActivated) {
            this.fetchHoursCounterEmployees();
          }
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.confirmHoursCounterLoading = false;
        });
    },
    deactivateRttCounter() {
      this.$skAnalytics.track('rtt_counter_deactivation');

      const userIds = this.dailyRateEmployees.map(user => user.id);
      return Promise.all([
        this.deleteAbsenceConfig(this.currentShop.id),
        msHttpClient.delete(
          `${svcEmployeesUrl}/v1/absence-configs/${this.currentShop.id}/RTT`,
          { data: { userIds } },
        ),
      ])
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.rtt_modal.delete_complete'),
            variant: 'success',
          });
        });
    },
    deactivatePaidLeavesCounter() {
      this.$skAnalytics.track('paid_leaves_counters_disable_confirmed');
      const messageKey =
        this.currentShop.attributes.plcStatus === this.config.plc_pending ?
          this.config.plc_pending : this.config.plc_initialized;
      this.confirmPaidLeavesCounterLoading = true;

      return httpClient
        .delete(`/v3/api/shops/${this.currentShop.id}/initial_paid_leaves_counters`)
        .then(() => {
          this.sendEventLog(EVENT_SUBTYPE_ACTION.SHOP_PTO_TRACKER_DISABLE);

          this.setShopAttributes({
            plcStatus: this.config.plc_not_initialized,
            plcInitializationDoneAt: '',
            plcInitialized: false,
          });

          this.$skToast({
            message: this.$t(`shop_settings.tabs.rules.counter_rules.deactivate_modal.paid_leaves_counter.${messageKey}.success`),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('shop_settings.update_shop.error_message'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.confirmPaidLeavesCounterLoading = false;
        });
    },
    async sendEventLog(logName, payload) {
      try {
        await this.$svcEvents.create(logName, payload);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-settings-rules__pending-button {
  button {
    margin: 0 0 5px -8px;
  }
}

.shop-settings-rules__tag_and_switch {
  display: flex;
  align-items: center;
  width: 100%;
}

.holiday-settings__container {
  padding: 40px 0;
}

.shop-settings-rules__pending-explanation {
  width: 320px;
}
</style>
