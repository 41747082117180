<template>
  <SkModalV2
    id="create-document-modal"
    ref="createDocumentModal"
    :title="$t('employees.tabs.documents.create_modal.title')"
    :tracking-options="trackingOptions"
    :submit-button-label="$t('employees.tabs.documents.create_modal.submit')"
    :submit-disabled="isSubmitDisabled"
    :submit-loading="isUploadingDocuments"
    @cancel="resetAll"
    @close="resetAll"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <VueDropzone
          v-show="!isUploadingDocuments && documentsCount === 0"
          id="create-document-dropzone"
          ref="createDocumentDropzone"
          :include-styling="false"
          :options="isUploadingToDocumentsV2 ? dropzoneOptionsV2 : dropzoneOptions"
          use-custom-slot
          @vdropzone-error="handleError"
          @vdropzone-sending="handleSending"
          @vdropzone-success="handleSuccess"
        >
          <div class="create-document-modal__dropzone-content">
            <div class="create-document-modal__dropzone-label">
              <div>
                {{ $tc('employees.tabs.documents.create_modal.dropzone.label_1', maxDocumentsCount) }}
              </div>
              <div>
                {{ $t('employees.tabs.documents.create_modal.dropzone.label_2') }}
              </div>
              <SkOroraButton
                variant="secondary"
                size="medium"
              >
                {{ $t('employees.tabs.documents.create_modal.dropzone.button') }}
              </SkOroraButton>
            </div>
          </div>
        </VueDropzone>
        <div
          v-if="!isUploadingDocuments && documentsCount > 0"
          class="create-document-modal__documents-content"
        >
          <DocumentRow
            v-for="(_, index) in documents"
            :key="`create-document-${index}`"
            v-model="documents[index]"
            :show-expiration-date="true"
            @remove-file="removeDocument"
          />
        </div>
        <div
          v-if="isUploadingDocuments"
          class="create-document-modal__progress-bar"
        >
          <SkSimpleProgressBar
            :max-step="documentsCount"
            :current-step="documentsCount - remainingDocumentsToUploadCount"
          />
          {{ $tc(
            'employees.tabs.documents.create_modal.uploading',
            documentsCount,
            { count: documentsCount, remaining: documentsCount - remainingDocumentsToUploadCount},
          ) }}
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';

import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { svcDocumentV2Client } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import { captureException } from '@sentry/vue';
import DocumentRow from './DocumentRow';

const MAX_DOCUMENTS = 5;
const MAX_DOCUMENT_SIZE = 50000000; // 5MB

export default {
  name: 'CreateDocumentModal',
  components: { VueDropzone: vue2Dropzone, DocumentRow },
  props: {
    trackingOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      acceptedMimeTypes: [
        'application/msword', // .docx
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .doc,
        'image/jpeg',
        'image/png',
      ],
      dropzoneOptionsV2: {
        accept: this.validateFile,
        autoProcessQueue: false,
        parallelUploads: 1,
        autoQueue: false,
        clickable: true,
        createImageThumbnails: false,
        hiddenInputContainer: '#create-document-dropzone',
        url: '/', // The URL will be changed for each new file being processing
        method: 'put', // Since we're going to do a `PUT` upload to S3 presigned url directly
      },
      dropzoneOptions: {
        accept: this.validateFile,
        autoProcessQueue: false,
        autoQueue: false,
        clickable: true,
        createImageThumbnails: false,
        headers: {
          'X-Request-With': 'XMLHttpRequest',
          Authorization: `Bearer ${authClient.authToken.token}`,
        },
        hiddenInputContainer: '#create-document-dropzone',
        params: {},
        timeout: null,
        url: `/v3/api/users/${this.$router.currentRoute.params.user_id}/documents`,
      },
      documents: [],
      isUploadingDocuments: false,
      remainingDocumentsToUploadCount: 0,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapGetters('currentOrganisation', ['maxDocumentAllowedPerUser']),
    ...mapGetters('currentShop', ['isFeatureEnabledOnShop', 'isDevFlagEnabled']),
    ...mapState('employeeDocuments', ['currentFolder']),
    isUploadingToDocumentsV2() {
      return this.isDevFlagEnabled('FEATUREDEV_UPLOAD_DOCS_TO_V2');
    },
    documentsCount() {
      return this.documents.length;
    },
    maxDocumentsCount() {
      // max docs for premium pack or payroll service enabled - null means unlimited (premium pack)
      return this.maxDocumentAllowedPerUser === null || this.isFeatureEnabledOnShop('payroll') ?
        MAX_DOCUMENTS :
        1;
    },
    isSubmitDisabled() {
      return this.documentsCount === 0 || this.documents.some(doc => doc.title === '');
    },
  },
  mounted() {
    this.$refs.createDocumentDropzone.options.maxFiles = this.maxDocumentsCount;
  },
  methods: {
    ...mapActions('employeeDocuments', ['addDocumentFromResponse', 'addDocumentV2FromResponse']),
    findDocument(fileToFind) {
      return this.documents.find(({ file }) => file.upload.uuid === fileToFind.upload.uuid);
    },
    findDocumentIndex(fileToFind) {
      return this.documents.findIndex(({ file }) => file.upload.uuid === fileToFind.upload.uuid);
    },
    // https://rowanwins.github.io/vue-dropzone/docs/dist/#/additionalParams
    handleSending(file, _xhr, formData) {
      if (this.isUploadingToDocumentsV2) {
        this.handleSendingV2(file, _xhr);
        return;
      }

      const document = this.documents[this.findDocumentIndex(file)];

      formData.append('document[title]', document.title);

      if (document.expirationDate) {
        formData.append('document[expiration_date]', document.expirationDate);
      }

      if (this.currentFolder) {
        formData.append('document[folder]', this.currentFolder);
      }
    },
    // For the svcDocumentV2Client, we need to upload directly to S3 using a presigned url
    // Thus, we need to override the xhr sending method to get the S3 presigned url prior to uploading
    handleSendingV2(file, xhr) {
      const originalSend = xhr.send;
      xhr.send = async () => {
        const createResponse = await this.createDocumentV2(file);
        const url = createResponse.uploadUrl;

        // We set this attribute on the file object to be used in handleSuccessDocumentsV2
        file.document = { ...createResponse };

        xhr.open('PUT', url, true);
        originalSend.call(xhr, file).catch(error => {
          this.handleError(file, error);
        });
      };
    },
    // This methods calls the svcDocumentV2Client to create a document and get the S3 presigned url
    // NB: The document will be deleted by dynamoDB TTL after ~24h if the upload to S3 fails
    async createDocumentV2(file) {
      const localDocument = this.findDocument(file);

      const response = await svcDocumentV2Client.document.create([
        {
          fileName: file.name,
          mimeType: file.type,
          employeeId: this.employee.id,
          title: localDocument.title,
          expirationDate: localDocument.expirationDate,
          folderPath: this.currentFolder ? `/${ this.currentFolder}` : undefined,
          creatorId: this.currentUser.id,
        },
      ]);

      if (!response || !response[0]) {
        const error = new Error(this.$t('employees.tabs.documents.create_modal.errors.upload'));
        this.handleError(file, error);
      }

      return response[0];
    },
    handleSubmit() {
      this.remainingDocumentsToUploadCount = this.documentsCount;
      this.isUploadingDocuments = true;
      this.$refs.createDocumentDropzone.processQueue();
    },
    // After succesfully uploading the files to S3, we need to keep the store up to date
    handleSuccessDocumentsV2(file) {
      const document = this.findDocument(file);
      // We pass the document from the response in handleSendingV2
      this.addDocumentV2FromResponse(file.document);
    },
    async handleSuccess(file, response) {
      if (this.isUploadingToDocumentsV2) {
        this.handleSuccessDocumentsV2(file);
      } else {
        this.addDocumentFromResponse(response);
      }
      this.remainingDocumentsToUploadCount -= 1;

      if (this.remainingDocumentsToUploadCount !== 0) return;

      this.isUploadingDocuments = false;

      this.resetAll();
      this.$skToast({
        message: this.$t('employees.tabs.documents.create_modal.success'),
        variant: 'success',
      });

      try {
        await this.$svcEvents.create(EVENT_SUBTYPE_ACTION.EMPLOYEE_DOCUMENT_UPLOADED, {
          employee: this.employee,
          documentId: response.data.id,
          documentName: response.data.attributes.title,
        });
      } catch (error) {
        captureException(error);
      }

      this.$refs.createDocumentModal.hide();
    },
    handleError(_, error) {
      const defaultError = this.$t('employees.tabs.documents.create_modal.errors.upload');

      this.$skToast({ message: error?.message || defaultError, variant: 'error' });

      this.resetDocuments();
      this.isUploadingDocuments = false;
      throw error;
    },
    removeDocument(fileToRemove) {
      this.documents.splice(this.findDocumentIndex(fileToRemove), 1);
      this.$refs.createDocumentDropzone.removeFile(fileToRemove);
    },
    resetAll() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.resetDocuments();
    },
    resetDocuments() {
      this.documents = [];
      this.$refs.createDocumentDropzone.removeAllFiles();
    },
    showErrorToast(message) {
      this.$skToast({ message, variant: 'error' });
    },
    validateFile(file, done) {
      if (this.documentsCount === this.maxDocumentsCount) {
        this.showErrorToast(this.$tc('employees.tabs.documents.create_modal.errors.count', this.maxDocumentsCount));
        return;
      }

      if (!file || !file.type || !this.acceptedMimeTypes.includes(file.type)) {
        this.showErrorToast(this.$t('employees.tabs.documents.create_modal.errors.format'));
        return;
      }

      if (file.size > MAX_DOCUMENT_SIZE) {
        this.showErrorToast(this.$t('employees.tabs.documents.create_modal.errors.size'));
        return;
      }

      done(); // valid

      this.$refs.createDocumentDropzone.dropzone.enqueueFile(file);
      this.documents.push({
        expirationDate: null,
        file,
        title: file.name.split('.').slice(0, -1).join('.'),
      });
    },
  },
};
</script>

<!-- unscoped style to override dropzone classes -->
<style lang="scss">
// using the dropzone id to not potentially affect other dropzones
#create-document-dropzone {
  .dz-preview,
  .dz-file-preview {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.create-document-modal__progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.create-document-modal__documents-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-document-modal__dropzone-content {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px dashed $sk-grey-10;
}

.create-document-modal__dropzone-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
}
</style>
