<template>
  <div class="files-quota-bar__wrapper">
    <div
      v-if="!isBundleSystemActive(currentShop.id)"
      class="files-quota-bar__label"
    >
      <PackTag
        type="premium"
        shadow
      />
    </div>
    <div class="files-quota-bar__title">
      {{ $t('employees.tabs.documents.files_quota_bar.space_used') }}
    </div>
    <div class="files-quota-bar__progress-bar">
      <SkSimpleProgressBar
        :max-step="quotaLimit"
        :current-step="currentStep"
        :is-red="quotaReached"
      />
    </div>
    <div class="files-quota-bar__quota">
      <div :class="quotaUsedClasses">
        {{ quotaUsed }}
      </div>
      / {{ quotaLimit }}
    </div>
  </div>
</template>

<script>
import PackTag from '@app-js/shared/components/PackTag';
import {
  mapGetters, mapState,
} from 'vuex';

export default {
  name: 'FilesQuotaBar',
  components: { PackTag },
  props: {
    quotaLimit: {
      type: Number,
      required: true,
    },
    quotaUsed: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('features', ['isBundleSystemActive']),
    currentStep() {
      return this.quotaReached ? this.quotaLimit : this.quotaUsed;
    },
    quotaReached() {
      return this.quotaUsed >= this.quotaLimit;
    },
    quotaUsedClasses() {
      return {
        'files-quota-bar__quota__used': true,
        'files-quota-bar__quota__used--over': this.quotaReached,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.files-quota-bar__wrapper {
  width: 100%;
  height: 44px;
  background: $sk-grey-10;
  padding: 11px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.files-quota-bar__label {
  margin-right: 20px;
}

.files-quota-bar__quota {
  display: flex;
  font-weight: bold;
}

.files-quota-bar__quota__used {
  margin-left: 19px;
  margin-right: 5px;
  color: $sk-blue;

  &.files-quota-bar__quota__used--over {
    color: $sk-error;
  }
}

.files-quota-bar__progress-bar {
  margin-left: 20px;
  width: 180px;
}
</style>
