<template>
  <div class="shop-header">
    <ShopV2Icon
      width="24"
      height="24"
      fill="#2b66fe"
    />
    <p>{{ shopName }}</p>
  </div>
</template>

<script>

export default {
  name: 'ShopHeader',
  props: {
    shopName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.shop-header{
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0 4px;
    font-weight: $fw-semi-bold;
    font-size: $fs-text-l;
  }
}

</style>
