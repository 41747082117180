import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import skDate from '@skello-utils/dates';
import GroupedShift from '../class/GroupedShift';

const isNotAbsence = shift => {
  if (shift.relationships.previsionalPoste) {
    return !shift.relationships.previsionalPoste.attributes.absenceKey;
  }
  return shift.attributes.absenceCalculation === '';
};

export const groupShifts = shifts => sortBy(shifts, 'attributes.userId').reduce((acc, item, index) => {
  const lastItem = acc[acc.length - 1];
  const linkedIds = [];
  const lastBindedShiftId = last(lastItem?.id?.split('#'));
  const lastBindedShift = shifts.find(shift => shift.id === lastBindedShiftId);

  const startsAt = item.attributes.previsionalSaved ?
    item.attributes.previsionalStart :
    item.attributes.startsAt;
  const endsAt = lastBindedShift?.attributes?.previsionalSaved ?
    lastBindedShift?.attributes.previsionalEnd :
    lastBindedShift?.attributes.endsAt;

  if (
    lastItem && startsAt === endsAt && // Should link shifts
    isNotAbsence(item) && isNotAbsence(lastItem) // If 1 absence mid-day -> 2 shift groups created
  ) {
    const idsToBind = lastItem.id.split('#');
    const shiftsToBind = shifts.filter(
      s => idsToBind.includes(String(s.id)) || item.id === String(s.id),
    );

    const groupedShift = new GroupedShift(shiftsToBind);
    const indexToSplice = acc.findIndex(shift => `${shift.id}#${item.id}` === groupedShift.id);

    acc.splice(indexToSplice, 2, groupedShift);
    linkedIds.push(item.id, lastItem.id);
  } else if (!linkedIds.includes(item.id)) {
    acc.push(item);
  }
  return acc;
}, []);

const hoursToDateTime = (date, time) => {
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  const dateStr = skDate.utc(date);
  const year = dateStr.year();
  const month = dateStr.month();
  const day = dateStr.date();

  return skDate.utc()
    .set('year', year)
    .set('month', month)
    .set('date', day)
    .set('hour', hours)
    .set('minute', minutes)
    .set('second', 0);
};

export const isShiftInBadgingRange = (matchedBadging, shift) => {
  if (matchedBadging.badgingStartsAt) {
    const badgingSelectedStart = hoursToDateTime(
      matchedBadging.badgingStartsAt, matchedBadging.selectedStartsAt,
    );
    if (badgingSelectedStart.isBefore(matchedBadging.badgingStartsAt)) badgingSelectedStart.add(1, 'day');
  }
  const badgingSelectedStart = matchedBadging.badgingStartsAt ? hoursToDateTime(
    matchedBadging.badgingStartsAt, matchedBadging.selectedStartsAt,
  ) : hoursToDateTime(
    shift.attributes.startsAt, matchedBadging.selectedStartsAt,
  );

  const dayInSecond = 60 * 60 * 24;

  // convert shift and badging in seconds
  const badgingStartsAt = hoursToDateTime(
    shift.attributes.startsAt, matchedBadging.selectedStartsAt,
  ).unix() % dayInSecond;
  const badgingEndsAt = hoursToDateTime(
    shift.attributes.startsAt, matchedBadging.selectedEndsAt,
  ).unix() % dayInSecond;
  const shiftStartsAt = skDate.utc(shift.attributes.startsAt).unix() % dayInSecond;
  const shiftEndsAt = skDate.utc(shift.attributes.endsAt).unix() % dayInSecond;

  // Once with shiftStartsAt as reference then with badgingStartsAt because we cannot
  // know is later or late the day before
  let reference = shiftStartsAt;

  // compute offset between 24h and badging start
  let offsetInSecond = dayInSecond - reference;

  // set shiftStart to 0 and add offset to shift and badge
  let shiftStart = (shiftStartsAt + offsetInSecond) % dayInSecond;
  let shiftEnd = (shiftEndsAt + offsetInSecond) % dayInSecond;
  let badgeIn = (badgingStartsAt + offsetInSecond) % dayInSecond;
  let badgeOut = (badgingEndsAt + offsetInSecond) % dayInSecond;

  if ((badgeIn <= shiftStart && shiftStart <= badgeOut) ||
    (badgeIn <= shiftEnd && shiftEnd <= badgeOut) ||
    (shiftStart <= badgeIn && badgeIn <= shiftEnd) ||
    (shiftStart <= badgeOut && badgeOut <= shiftEnd)) {
    return true;
  }

  reference = badgingStartsAt;

  // compute offset between 24h and badging start
  offsetInSecond = dayInSecond - reference;

  // set shiftStart to 0 and add offset to shift and badge
  shiftStart = (shiftStartsAt + offsetInSecond) % dayInSecond;
  shiftEnd = (shiftEndsAt + offsetInSecond) % dayInSecond;
  badgeIn = (badgingStartsAt + offsetInSecond) % dayInSecond;
  badgeOut = (badgingEndsAt + offsetInSecond) % dayInSecond;

  return (badgeIn <= shiftStart && shiftStart <= badgeOut) ||
  (badgeIn <= shiftEnd && shiftEnd <= badgeOut) ||
  (shiftStart <= badgeIn && badgeIn <= shiftEnd) ||
  (shiftStart <= badgeOut && badgeOut <= shiftEnd);
};
