<template>
  <div class="all-shops-icon-wrapper">
    <div class="medallion-container">
      <SkMedallion
        icon="ShopIcon"
        background-color="#d9e6ff"
        color="#2b66fe"
        size="x-small"
      />
    </div>
    <div class="medallion-container">
      <SkMedallion
        icon="ShopIcon"
        background-color="#d9e6ff"
        color="#2b66fe"
        size="x-small"
      />
    </div>
  </div>
</template>

<script>
import { SkMedallion } from '@skelloapp/skello-ui';

export default {
  name: 'AllShopsIcon',
  components: { SkMedallion },
  props: {
    borderColor: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped>
.all-shops-icon-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 37px; // ~ SkMedallion size (24px) + border (6px) + 2nd medallion left gap
}

.medallion-container {
  &:last-child {
    position: absolute;
    left: 7px;
    border: 3px solid white;
    border-radius: 50%;
    background-color: white;
  }
}

.sk-select__list-item:hover,
.sk-select__selected-option {
  .all-shops-icon-wrapper {
    .medallion-container:last-child {
      border-color: $sk-grey-5;
      background-color: $sk-grey-5;
    }
  }
}
</style>
