<template>
  <SkCard>
    <div :class="cardClass">
      <div
        v-if="isRoot && !currentOrganisation.attributes.clusters"
        :class="{
          'organisation-icon-wrapper': !currentOrganisation.attributes.clusters
        }"
      >
        <SkMedallion
          icon="ShopIcon"
          background-color="#d9e6ff"
          color="#2b66fe"
          :size="medallionSize"
          class="organisation-icon-wrapper_medallion"
        />
        <SkMedallion
          icon="ShopIcon"
          background-color="#d9e6ff"
          color="#2b66fe"
          :size="medallionSize"
          class="organisation-icon-wrapper_medallion"
        />
      </div>
      <div v-else>
        <SkMedallion
          :icon="isRoot ? 'NetworkIcon' : 'ShopIcon'"
          :size="medallionSize"
          background-color="#d9e6ff"
          color="#2b66fe"
        />
      </div>
      <div :class="cardLabelClass">
        <a
          @click="onCardClick"
        >
          <div class="reports__cluster-nodes-card__label-title">
            {{ clusterNode.attributes.name }}
          </div>
          <p class="sk-subtitle--medium">
            {{ countLabel }}
          </p>
        </a>
      </div>
    </div>
    <SkDropdown
      ref="dropdown"
      :prevent-overflow="false"
      placement="bottom-start"
      y-offset="5px"
      trigger="click"
    >
      <template #anchor>
        <SkCircleButton icon="KebabV2Icon" />
      </template>
      <template #menu>
        <div
          v-tooltip="employeeMissing ? $t('reports.card.no_employee') : ''"
          class="reports-actions__menu"
        >
          <div
            :class="excelExportClass"
            @click="handleExport('send-excel-report')"
          >
            {{ $t('reports.card.card_report_export', { to: 'Excel' }) }}
            <span
              v-if="disableExcelExport"
              class="reports-actions__disabled-for-range"
            >
              {{ $t('reports.card.disabled_over_two_months') }}
            </span>
          </div>
          <div
            v-if="isRoot && clusterNodeList.length > 1 && isGroupedExportAvailable"
            :class="menuItemClass"
            @click="handleExport('send-grouped-report')"
          >
            {{ $t('reports.card.grouped_report') }}
            <span
              v-if="isDateRangeExtendedPeriod"
              class="reports-actions__disabled-for-range"
            >
              {{ $t('reports.card.disabled_over_two_months') }}
            </span>
          </div>
          <div v-if="isRoot && groupedGlobalIntegrations.length > 0">
            <div
              v-for="integration in groupedGlobalIntegrations"
              :key="`${integration}-custom`"
              @click="handleExport('send-grouped-integrations', 'global', integration)"
            >
              <span
                v-if="handleExportDisplay(integration)"
                :class="menuItemClass"
              >
                {{
                  $t('reports.card.grouped_integrations', {
                    to: globalIntegrationName(integration)
                  })
                }}
                <span
                  v-if="isDateRangeExtendedPeriod"
                  class="reports-actions__disabled-for-range"
                >
                  {{ $t('reports.card.disabled_over_two_months') }}
                </span>
              </span>
            </div>
          </div>
          <div
            :class="menuItemClass"
            @click="showUnemploymentModal"
          >
            {{ $t('reports.card.unemployment_report') }}
            <span
              v-if="isDateRangeExtendedPeriod"
              class="reports-actions__disabled-for-range"
            >
              {{ $t('reports.card.disabled_over_two_months') }}
            </span>
          </div>
          <div
            v-for="integration in customIntegrations"
            :key="`${integration}-custom`"
            @click="handleExport('send-integration-report', 'custom', integration)"
          >
            <span
              v-if="handleExportDisplay(integration)"
              :class="menuItemClass"
            >
              {{
                $t('reports.card.card_report_export', {
                  to: customIntegrationName(integration)
                })
              }}
              <span
                v-if="isDateRangeExtendedPeriod"
                class="reports-actions__disabled-for-range"
              >
                {{ $t('reports.card.disabled_over_two_months') }}
              </span>
            </span>
          </div>
          <div
            v-for="integration in globalIntegrations"
            :key="`${integration}-global`"
            :class="menuItemClass"
            @click="handleExport('send-integration-report', 'global', integration)"
          >
            {{
              $t('reports.card.card_report_export', {
                to: globalIntegrationName(integration)
              })
            }}
            <span
              v-if="isDateRangeExtendedPeriod"
              class="reports-actions__disabled-for-range"
            >
              {{ $t('reports.card.disabled_over_two_months') }}
            </span>
          </div>
        </div>
      </template>
    </SkDropdown>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <UnemploymentReportModal
        :cluster-node="clusterNode"
        @send-unemployment-report="handleExport('send-unemployment-report', $event)"
      />
    </MountingPortal>
  </SkCard>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { capitalize } from '@skello-utils/formatting/strings';
import { uniq } from '@skello-utils/collection';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { REPORT_NORMAL_PERIOD } from '@app-js/shared/constants/report';

import UnemploymentReportModal from './UnemploymentReportModal';

export default {
  name: 'NodeCard',
  components: { UnemploymentReportModal },
  props: {
    isRoot: {
      type: Boolean,
      default: false,
    },
    clusterNode: {
      type: Object,
      required: true,
    },
    clusterNodeList: {
      type: Array,
      required: true,
    },
    isGroupedExportAvailable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('config', ['config']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    customIntegrations() {
      if (this.isRoot) {
        return uniq(
          this.clusterNodeList.map(clusterNode => clusterNode.attributes.integrations).flat(),
        );
      }

      return this.clusterNode.attributes.integrations;
    },
    groupedGlobalIntegrations() {
      if (!this.isRoot) return [];

      return this.globalIntegrations
        .filter(integration => !this.isNotInEveryClusterNode(integration));
    },
    globalIntegrations() {
      if (this.isRoot) {
        return uniq(
          this.clusterNodeList.map(clusterNode => (
            clusterNode.attributes.globalIntegrations.map(integration => (
              integration.same_integration_key
            ))
          )).flat(),
        );
      }

      return uniq(
        this.clusterNode.attributes.globalIntegrations.map(integration => integration.name).flat(),
      );
    },
    cardClass() {
      return {
        'reports__shop-nodes__label': true,
        'reports__cluster-nodes__label': this.isRoot && this.currentOrganisation.attributes.clusters,
      };
    },
    excelExportClass() {
      return {
        'reports-actions__menu-item': true,
        'reports-actions__menu-item--disabled': this.disableExcelExport,
      };
    },
    isDateRangeExtendedPeriod() {
      return this.isDevFlagEnabled('FEATUREDEV_REPORT_EXPORT_EXTENDED_PERIOD') &&
        skDate(this.$route.query.end_date).diff(skDate(this.$route.query.start_date).add(REPORT_NORMAL_PERIOD, 'M'), 'days') > 0;
    },
    disableExcelExport() {
      return this.employeeMissing || (!this.isRoot && this.isDateRangeExtendedPeriod);
    },
    disableExport() {
      return this.employeeMissing || this.isDateRangeExtendedPeriod;
    },
    menuItemClass() {
      return {
        'reports-actions__menu-item': true,
        'reports-actions__menu-item--disabled': this.disableExport,
      };
    },
    nbEmployee() {
      return this.clusterNode.attributes.employeeCount;
    },
    noEmployee() {
      return this.clusterNode.attributes.employeeCount === 0;
    },
    employeeMissing() {
      return this.noEmployee && !this.isRoot;
    },
    countLabel() {
      if (this.clusterNode.attributes.shopId) {
        return this.$tc('reports.card.users_count', this.nbEmployee);
      }

      return this.$tc('reports.card.shop_nodes_count', this.clusterNodeList.length);
    },
    cardLabelClass() {
      return {
        'reports__cluster-nodes-card__label': true,
        'reports__cluster-nodes-card__label--clickable': !this.isRoot,
      };
    },
    medallionSize() {
      if (this.currentOrganisation.attributes.clusters) return 'large';

      return 'small';
    },
  },
  methods: {
    handleExportDisplay(integration) {
      const isOrganisationLevelIntegration = ['jd_sports', 'recrea'].includes(integration);

      return isOrganisationLevelIntegration ? this.isRoot : !['starbucks'].includes(integration);
    },
    handleExport(exportEvent, type, key) {
      if ((this.disableExcelExport && exportEvent === 'send-excel-report') || (exportEvent !== 'send-excel-report' && this.disableExport)) return;

      this.handleExportTrack(exportEvent);

      let nodes = this.isRoot ? this.clusterNodeList : this.clusterNode;

      if (this.isRoot && type === 'custom') {
        nodes = nodes.filter(node => node.attributes.integrations.includes(key));
      }

      // prevent .filter() returns array of one element
      if (nodes.length === 1) {
        nodes = nodes[0];
      }

      this.$refs.dropdown.hide();

      this.$emit(exportEvent, { nodes, type, key });
    },
    handleExportTrack(exportEvent) {
      const isClusters = this.currentOrganisation.attributes.clusters === true;
      let source = this.isRoot ? 'Organisation/Network' : 'shop';

      let eventName;
      switch (exportEvent) {
        case 'send-excel-report':
          eventName = 'downloaded_report';
          break;
        case 'send-grouped-report':
          eventName = 'downloaded_group_report';
          source = undefined;
          break;
        case 'send-integration-report':
          eventName = 'downloaded_payroll_integration_report';
          break;
        case 'send-unemployment-report':
          eventName = 'downloaded_short_time_working_report';
          break;
        default:
          break;
      }

      if (eventName) {
        this.$skAnalytics.track(
          eventName,
          {
            cluster_id: isClusters ? this.navContext.clusterNodeId : undefined,
            source,
          },
        );
      }
    },
    onCardClick(event) {
      if (!this.isRoot) {
        // onCardClick, prevent default only for reportV3
        // Otherwise we need the href to work natively for V2
        event.preventDefault();

        const query = { ...this.$router.currentRoute.query };
        // When selecting a node using "view all" from dropdown it adds a cluster_node_id
        // in the query params; when selecting a shop, it needs to be removed
        delete query.cluster_node_id;

        this.$router.push({
          name: 'reports',
          params: {
            shop_id: this.clusterNode.attributes.shopId,
            badging: this.clusterNode.attributes.badging,
          },
          query,
        });
      }

      const isClusters = this.currentOrganisation.attributes.clusters === true;
      this.$skAnalytics.track(
        'clicked_on_report_tab',
        {
          cluster_id: isClusters ? this.navContext.clusterNodeId : undefined,
          source: 'Shop_card-view_all',
        },
      );
    },
    customIntegrationName(key) {
      return this.config.environment === 'production' ? capitalize(this.config.pay_exports[key]) : capitalize(key);
    },
    globalIntegrationName(key) {
      return key ? capitalize(key.split('_')[0]) : '';
    },
    showUnemploymentModal(event) {
      if (this.disableExport) return;

      this.$refs.dropdown.hide();
      this.emitOnRoot(MODAL_SHOW_EVENT, event, `unemployment-report-modal-${this.clusterNode.id}`);
    },
    isNotInEveryClusterNode(integrationKey) {
      if (!integrationKey) return true;

      return this.clusterNodeList
        .some(node => !node.attributes.globalIntegrations
          .find(integration => integration.same_integration_key === integrationKey),
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.reports__shop-nodes__label {
  display: flex;
  align-items: center;

  ::v-deep svg {
    width: 18.75px;
    height: 16.67px;
  }
}

.reports__cluster-nodes__label {
  ::v-deep svg {
    width: 26px;
    height: 23px;
  }
}

.reports-actions__menu {
  max-height: 400px;
  padding: 10px 0;
  overflow-y: scroll;
  width: 253px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .16);
}

.reports-actions__menu::-webkit-scrollbar {
  display: none;
}

.reports-actions__menu-item {
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  font-weight: $fw-semi-bold;

  &--disabled {
    color: $sk-grey-30;
    cursor: not-allowed;
  }

  &:hover {
    background: $sk-grey-5;
  }
}

.reports-actions__disabled-for-range {
  font-size: $fs-text-s;
  font-weight: $fw-regular;
}

.reports__cluster-nodes-card__label {
  padding-left: 20px;

  &-title {
    font-weight: $fw-semi-bold;
    font-size: $fs-heading-xs;
    color: $sk-black;
    margin: 0;
  }

  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &--clickable {
    cursor: pointer;
  }
}

.reports__cluster-nodes-cards__wrapper {
  display: flex;
  flex-direction: column;
}

/**  Overlapping shop icons */
.organisation-icon-wrapper {
  display: flex;
  width: 50px;

  .organisation-icon-wrapper_medallion {
    box-sizing: content-box !important;
    border: 3px solid white;

    &:last-of-type {
      margin-left: -15px;
    }
  }
}
</style>
