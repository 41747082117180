<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import GenericDropdownItem from './GenericDropdownItem.vue';

export default {
  name: 'EraseShiftsAction',
  extends: GenericDropdownItem,
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('planningsState', ['isDailyView', 'currentDate']),
    ...mapGetters('planningsShifts', ['shiftsForCurrentWeek', 'dayCellShifts']),
    viewName() {
      return this.isDailyView ? 'day' : 'week';
    },
    title() {
      return this.$t(`plannings.toolbar.actions_bar.icons_labels.erase_shifts.label.${this.viewName}`);
    },
    icon() {
      return 'TrashCanV2Icon';
    },
    visibleIn() {
      return 'dropdown';
    },
    shiftsForCurrentDay() {
      return this.dayCellShifts(this.currentDate, this.shiftsForCurrentWeek);
    },
    shiftsCurrentShop() {
      const shifts = this.isDailyView ? this.shiftsForCurrentDay : this.shiftsForCurrentWeek;

      return shifts.filter(
        shift => Number(shift.attributes.shopId) === Number(this.currentShop.id),
      );
    },
    disabled() {
      return this.currentLicense.attributes.canCreateShifts === false ||
        this.shiftsCurrentShop.length === 0;
    },
    tooltip() {
      if (!this.currentLicense.attributes.canCreateShifts) {
        return this.$t('plannings.toolbar.actions_bar.icons_labels.erase_shifts.tooltip.forbidden');
      }

      return this.$t('plannings.toolbar.actions_bar.icons_labels.erase_shifts.tooltip.nothing');
    },
    isVisible() {
      return true;
    },
    dataTest() {
      return 'planning-toolbar__erase-week-shifts';
    },
  },
  methods: {
    handler(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'erase-shifts-modal');
    },
  },
};
</script>
