<template>
  <div class="employee-primary-shop__section">
    <div class="row sk-form__row sk-form-section__title">
      <div class="col-6">
        <h3 class="sk-header--3">
          <span v-if="isClustersOrganisation">
            {{ $t('employees.tabs.personal_data.assignment.clusters.title') }}
          </span>
          <span v-else>
            {{ $t('employees.tabs.personal_data.assignment.simple.title') }}
          </span>
        </h3>
      </div>
      <div class="col-6 text-right">
        <SkOroraButton
          v-track="'help_shop_access'"
          variant="tertiary"
          icon="ExternalLinkV2Icon"
          @click="handleHelpLinkClick"
        >
          {{ helpLink.text }}
        </SkOroraButton>
      </div>
    </div>
    <SkForm split>
      <template #title>
        <div>
          <b>{{ $t('employees.tabs.personal_data.membership.shop_assignment.primary_title') }}</b>
        </div>
        <label class="sk-form-section__primary-shop-description">
          {{ $t('employees.tabs.personal_data.membership.shop_assignment.primary_subtitle') }}
        </label>
      </template>
      <template>
        <div>
          <SkSelect
            v-model="localAffiliatedShop"
            :disabled="!canEditAffiliation"
            :ajax-client="ajaxClient"
            :ajax-format-response="ajaxFormatResponse"
            :disabled-options="disabledOptions"
            :ajax-format-params="ajaxFormatParams"
            :search-placeholder="$t('labels.search')"
            data-test="employee-primary_shop"
            ajax-url="/v3/api/cluster_nodes"
            paginated
            @close="cancelledShopNodeIds = []"
          >
            <template #empty-option>
              {{ $t('employees.add_employee_modal.affiliations.no_search_results') }}
            </template>
            <template #selected-option="slotProps">
              <div class="sk-select__affiliated-shop__title">
                <SkMedallion
                  icon="ShopIcon"
                  background-color="#d9e6ff"
                  color="#2b66fe"
                  class="sk-select__affiliated-shop__title__icon"
                />
                <div class="sk-select__affiliated-shop__thumbtack">
                  <ThumbtackV2Icon
                    width="12"
                    height="12"
                    fill=""
                    class="sk-select__affiliated-shop-thumbtack__fill"
                  />
                </div>
                <span>
                  {{ slotProps.value.text }}
                </span>
              </div>
            </template>
            <template #option="slotProps">
              <div
                v-tooltip.right-start="nodeHoverTooltip(slotProps.option)"
                class="sk-select__affiliated-shop__title"
              >
                <SkMedallion
                  icon="ShopIcon"
                  background-color="#d9e6ff"
                  color="#2b66fe"
                  class="sk-select__affiliated-shop__title__icon"
                />
                {{ slotProps.option.text }}
              </div>
            </template>
          </SkSelect>
        </div>
      </template>
    </SkForm>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';

export default {
  name: 'PrimaryShopSection',
  data() {
    return {
      ajaxClient: () => httpClient,
      shopNodes: [],
      cancelledShopNodeIds: [],
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentUser', ['currentUser', 'organisationLicenses', 'currentUserUserLicenses']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentLicense', ['canEditEmployeeInfo']),
    ...mapGetters('selectedEmployee', [
      'isCurrentUserOrSystemAdmin',
      'isStrictSubordinateOfCurrentUser',
      'isClustersOrganisation',
      'primaryShopNode',
    ]),

    disabledOptions() {
      return [this.primaryShopNode.id].concat(this.cancelledShopNodeIds);
    },

    clustersOrganisation() {
      return this.currentOrganisation.attributes.clusters;
    },

    currentUserManagesEmployeePrimaryShop() {
      if (this.currentUser.attributes.superAdmin) return true;

      // Current user manages primary shop node directly
      let currentUserUserLicense = this.currentUserUserLicenses.find(
        userLicense => String(userLicense.attributes.clusterNodeId) ===
        String(this.primaryShopNode.id),
      );

      if (currentUserUserLicense) return true;

      // Current user manages a cluster node that contains employee shop node
      currentUserUserLicense = this.currentUserUserLicenses.find(
        userLicense => userLicense.attributes.descendantIds.includes(
          Number(this.primaryShopNode.id),
        ),
      );

      return !!currentUserUserLicense;
    },

    canEditAffiliation() {
      if (!this.canEditEmployeeInfo) return false;

      if (!this.currentUserManagesEmployeePrimaryShop) return false;

      return (this.isCurrentUserOrSystemAdmin || this.isStrictSubordinateOfCurrentUser);
    },

    localAffiliatedShop: {
      get() {
        return {
          id: this.employee.attributes.shopId,
          text: this.employee.attributes.shopName,
        };
      },

      set(newNode) {
        const standardEmployeeLicense =
          last(orderBy(this.organisationLicenses, 'attributes.position'));

        if (this.clustersOrganisation) {
          this.updatePrimaryNode({
            newNode,
            standardEmployeeLicense,
            primaryShopNode: this.primaryShopNode,
          });
        } else {
          this.updatePrimaryShop({ newNode, standardEmployeeLicense });
        }

        this.setEmployeeAttributes({ shopId: newNode.attributes.shopId, shopName: newNode.text });
      },
    },

    helpLink() {
      if (this.isClustersOrganisation) {
        return {
          url: this.$t('employees.tabs.personal_data.assignment.clusters.link'),
          text: this.$t('employees.tabs.personal_data.assignment.clusters.about_assignment'),
        };
      }

      return {
        url: this.$t('employees.tabs.personal_data.assignment.simple.link'),
        text: this.$t('employees.tabs.personal_data.assignment.simple.about_assignment'),
      };
    },
  },

  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes', 'updatePrimaryNode', 'updatePrimaryShop']),
    ajaxFormatParams(params) {
      params.shop_level = true;
      params.configured = true;

      return { ...params };
    },
    ajaxFormatResponse(response) {
      this.shopNodes = response.data.map(clusterNode => ({
        id: clusterNode.id,
        text: clusterNode.attributes.name,
        attributes: {
          name: clusterNode.attributes.name,
          shopId: clusterNode.attributes.shopId ? String(clusterNode.attributes.shopId) : null,
          shopCancelled: clusterNode.attributes.shopCancelled,
          organisationId: String(clusterNode.attributes.organisationId),
          parentId: String(clusterNode.attributes.parentId),
          depth: clusterNode.attributes.depth,
          ancestry: clusterNode.attributes.ancestry,
          editable: clusterNode.attributes.editable,
          ancestorIds: clusterNode.attributes.ancestorIds,
          descendantIds: clusterNode.attributes.descendantIds,
        },
      }));

      this.cancelledShopNodeIds = [
        ...this.cancelledShopNodeIds,
        ...this.shopNodes.filter(shopNode => shopNode.attributes.shopCancelled)
          .map(node => node.id),
      ];

      return orderBy(this.shopNodes, 'attributes.name');
    },
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
    handleHelpLinkClick() {
      window.open(this.helpLink.url, '_blank');
    },
  },
};
</script>

<style lang="scss">
.employee-primary-shop__section {
  padding-bottom: 20px;

  .sk-form__header__title {
    padding-top: 17px;
    color: $sk-grey-50;
  }

  .sk-form-body-section {
    padding-top: 20px;
  }
}

.sk-form-section__primary-shop-description {
  width: 290px;
}

.sk-select__affiliated-shop__title {
  display: flex;
  align-items: center;
  position: relative;

  .sk-select__affiliated-shop__title__icon {
    margin-right: 15px;
  }

  .sk-select__affiliated-shop__thumbtack {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: $sk-blue-5;
  }

  .sk-select__affiliated-shop-thumbtack__fill {
    fill: $sk-blue-50;
  }
}

.sk-form__about-assignment {
  a:hover {
    text-decoration: underline;
  }
}
</style>
