<template>
  <SkOroraDialog
    id="lightspeed-authentication-modal"
    ref="lightspeedAuthenticationModal"
    size="medium"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isLightspeedAuthorizing"
    :submit-button-label="$t('organisation_settings.tabs.pos_partners.lightspeed.modal.submit_label')"
    :title="$t('organisation_settings.tabs.pos_partners.lightspeed.modal.title')"
    @cancel="$emit('cancel')"
    @close="$emit('cancel')"
    @submit="handleSubmit"
  >
    <template #body>
      <main class="lightspeed-authentication-modal">
        <section class="lightspeed-authentication-modal__description sk-ext-medium-regular">
          <p>{{ $t('organisation_settings.tabs.pos_partners.lightspeed.modal.description') }}</p>
          <p>{{ $t('organisation_settings.tabs.pos_partners.lightspeed.modal.sub_description') }}</p>
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';

export default {
  name: 'LightspeedAuthenticationModal',
  data() {
    return {
      authorizeUrl: null,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['loading']),
    ...mapState('config', ['config']),
    ...mapState('partnersTools', ['isLightspeedAuthorizing']),
    isSubmitDisabled() {
      return !this.authorizeUrl || this.authorizeUrl.length === 0;
    },
  },
  methods: {
    ...mapActions('partnersTools', ['authorizeLightspeed']),
    handleSubmit() {
      this.openLightspeedConnectionPage();
    },
    openModal() {
      this.authorizeLightspeed().then(response => {
        this.authorizeUrl = response.authorize_url;
      });
      this.$refs.lightspeedAuthenticationModal.show();
    },
    openLightspeedConnectionPage() {
      // Open lightspeed page in the same tab.
      // After log in lightspeed, the page will com back on the same tab
      this.windowRef = window.open(this.authorizeUrl, '_self', '');
    },
  },
};
</script>
<style lang="scss" scoped>
.lightspeed-authentication-modal {
  padding: 8px 24px;

  &__description {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      margin: 0;
    }
  }
}
</style>
