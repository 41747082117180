<template>
  <div
    :class="hourClasses"
    :style="{...zoomRange.headerDayName, ...headerWidth}"
  >
    {{ displayedHour }}
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { zoomPlanningDay } from '@app-js/plannings/shared/utils/zoom/zoom_helpers';

const ONE_HOUR_IN_MINUTE = 60;

export default {
  name: 'HeaderHours',
  props: {
    headerCurrentDate: {
      type: String,
      required: true,
    },
    isOpeningHour: {
      type: Boolean,
      required: true,
    },
    isClosingHour: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('currentUser', ['planningZoom']),
    ...mapState('planningsState', ['weeklyOptions']),
    displayedHour() {
      return skDate.utc(this.headerCurrentDate).format('HH[h]');
    },
    dayIndex() {
      return skDate.utc(this.currentDate).dayIndex();
    },
    isDayValidated() {
      return this.weeklyOptions.attributes.validatedDays[this.dayIndex];
    },
    isDayIntermediateLocked() {
      return this.weeklyOptions.attributes.intermediateLockedDays[this.dayIndex];
    },
    isDayPermanentLocked() {
      return this.weeklyOptions.attributes.permanentLockedDays[this.dayIndex];
    },
    isDayLocked() {
      return this.isDayValidated || this.isDayIntermediateLocked || this.isDayPermanentLocked;
    },
    hourClasses() {
      return {
        header__hour: true,
        'header__hour--locked': this.isDayLocked,
        'header__hour--opening': this.isOpeningHour,
        'header__hour--closing': this.isClosingHour,
      };
    },
    zoomRange() {
      return {
        headerDayName: zoomPlanningDay(this.planningZoom).headerDay.name,
      };
    },
    headerWidth() {
      const minutesMultiplier = (this.isOpeningHour || this.isClosingHour) ?
        ONE_HOUR_IN_MINUTE / 2 :
        ONE_HOUR_IN_MINUTE;

      return {
        minWidth: `${this.dayViewPlanningSizeVariables.pixelPerMinute * minutesMultiplier}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.header__hour {
  font-size: $fs-text-l;
  color: $sk-grey;
  text-align: center;

  &--opening {
    color: $sk-success;
    text-align: left;
    padding-left: 1px;
  }

  &--closing {
    color: $sk-error;
    text-align: right;
    padding-right: 1px;
  }

  &--locked {
    color: $sk-grey !important;
  }
}
</style>
