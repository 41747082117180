<template>
  <div
    v-if="creating"
    class="bi-partner__spinner-container"
  >
    <SkLoader
      class="bi-partner__spinner"
      size="large"
    />
  </div>
  <div v-else>
    <div class="bi-partner__title">
      <p class="sk-subtitle--large mt-3">
        {{ $t('organisation_settings.tabs.bi_partners.title') }}
      </p>
      <p class="sk-subtitle--large mt-3">
        {{ $t('organisation_settings.tabs.bi_partners.title2') }}
      </p>
    </div>
    <PartnerCard
      ref="dvoreCard"
      v-model="isDvoreActive"
      class="bi-partner__card"
      :disabled="canUpsellDvore || isDvoreLoading"
      :loading="isDvoreLoading"
      :upsell-available="canUpsellDvore"
      icon-name="Dvore"
      name="Dvore"
    >
      <div
        v-if="dvore"
        class="bi-partner__card-description"
      >
        <span>
          {{ $t('organisation_settings.tabs.bi_partners.dvore.token') }}
        </span>
        <span>{{ dvore.authenticationToken }}</span>
      </div>
    </PartnerCard>

    <PartnerCard
      ref="yokitupCard"
      v-model="isYokitupActive"
      class="bi-partner__card"
      :disabled="!isAnalyticsModuleActivated || isYokitupLoading"
      :loading="isYokitupLoading"
      :upsell-available="!isAnalyticsModuleActivated"
      icon-name="Yokitup"
      name="Yokitup"
    >
      <div
        v-if="yokitup"
        class="bi-partner__card-description"
      >
        <span>
          {{ $t('organisation_settings.tabs.bi_partners.yokitup.token') }}
        </span>
        <span>{{ yokitup.authenticationToken }}</span>
      </div>
    </PartnerCard>

    <PartnerCard
      v-if="isPartnerToolGoTenzoEnabled"
      class="bi-partner__card"
      :upsell-available="!isAnalyticsModuleActivated"
      icon-name="GoTenzo"
      :name="$t('organisation_settings.tabs.bi_partners.gotenzo.name')"
      read-only
    >
      <div class="bi-partner__card-description">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('organisation_settings.tabs.bi_partners.gotenzo.description')" />
        <span>
          {{ $t('organisation_settings.tabs.bi_partners.gotenzo.text_before_link') }}
        </span>
        <SkOroraLink
          :href="goTenzoPartnerLink"
          target="_blank"
        >
          <span>{{ $t('organisation_settings.tabs.bi_partners.gotenzo.link') }}</span>
        </SkOroraLink>
        <span>
          {{ $t('organisation_settings.tabs.bi_partners.gotenzo.text_after_link') }}
        </span>
      </div>
    </PartnerCard>

    <PartnerCard
      ref="analyticsApiCard"
      v-model="isAnalyticsApiActive"
      class="bi-partner__card"
      :disabled="!isAnalyticsModuleActivated || isAnalyticsApiLoading"
      :loading="isAnalyticsApiLoading"
      :upsell-available="!isAnalyticsModuleActivated"
      icon-name="SkelloLogo"
      :name="$t('organisation_settings.tabs.bi_partners.analytics_api.name')"
    >
      <div
        v-if="analyticsApi"
        class="bi-partner__card-description"
      >
        <span class="bi-partner__card-description">
          {{ $t('organisation_settings.tabs.bi_partners.analytics_api.token') }}
        </span>
        <span>{{ analyticsApi.authenticationToken }}</span>
        <div class="bi-partner__card-link">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('organisation_settings.tabs.bi_partners.analytics_api.text_before_link')" />
          <!-- eslint-enable vue/no-v-html -->
          <SkOroraLink
            :href="analyticsApiLink"
            target="_blank"
          >
            <span>{{ $t('organisation_settings.tabs.bi_partners.analytics_api.link') }}</span>
          </SkOroraLink>.
        </div>
      </div>
    </PartnerCard>

    <PartnerCard
      :disabled="false"
      class="advance-payment-partners__card"
      icon-name="TimeSkipper"
      :name="$t('organisation_settings.tabs.bi_partners.time_skipper.name')"
      read-only
    >
      <div class="advance-payment-partners__card-description">
        <span>{{ $t('organisation_settings.tabs.bi_partners.time_skipper.link_text') }}</span>
        <SkOroraLink
          :href="timeSkipperLink"
          target="_blank"
          rel="noopener noreferer"
        >
          <span>TimeSkipper</span>
        </SkOroraLink>.
        <p>
          {{ $t('organisation_settings.tabs.bi_partners.time_skipper.description') }}
        </p>
      </div>
    </PartnerCard>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

import { staticRootUrl } from '@config/env';
import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';

import PartnerCard from '../shared/PartnerCard';

export default {
  name: 'BusinessIntelligencePartners',
  components: { PartnerCard },
  data() {
    return {
      dvore: null,
      yokitup: null,
      analyticsApi: null,
      isDvoreLoading: false,
      isYokitupLoading: false,
      isAnalyticsApiLoading: false,
      creating: true,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),

    isDvoreActive: {
      get() {
        return this.dvore?.active;
      },
      set(newValue) {
        if (!newValue) {
          this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
            description: this.$t('organisation_settings.tabs.bi_partners.dvore.actions.deactivate.confirm.description'),
            onConfirmAsync: this.deactivateDvore,
            submitLabel: this.$t('organisation_settings.tabs.bi_partners.dvore.actions.deactivate.confirm.submit_label'),
            title: this.$t('organisation_settings.tabs.bi_partners.dvore.actions.deactivate.confirm.title'),
          });
        } else {
          this.activateApplication(this.dvore, 'Dvore')
            .then(response => {
              this.dvore = response.data.data.attributes;
              this.handleCollapse(this.dvore.active, this.$refs.dvoreCard);
            });
        }
      },
    },
    isAnalyticsApiActive: {
      get() {
        return this.analyticsApi?.active;
      },
      set(newValue) {
        if (newValue) {
          this.activateApplication(this.analyticsApi, 'AnalyticsApi')
            .then(response => {
              this.analyticsApi = response.data.data.attributes;
              this.handleCollapse(this.analyticsApi.active, this.$refs.analyticsApiCard);
            });
        } else {
          this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
            description: this.$t('organisation_settings.tabs.bi_partners.analytics_api.actions.deactivate.confirm.description'),
            onConfirmAsync: this.deactivateAnalytics,
            submitLabel: this.$t('organisation_settings.tabs.bi_partners.analytics_api.actions.deactivate.confirm.submit_label'),
            title: this.$t('organisation_settings.tabs.bi_partners.analytics_api.actions.deactivate.confirm.title'),
          });
        }
      },
    },
    isYokitupActive: {
      get() {
        return this.yokitup?.active;
      },
      set(newValue) {
        if (newValue) {
          this.activateApplication(this.yokitup, 'Yokitup')
            .then(response => {
              this.yokitup = response.data.data.attributes;
              this.handleCollapse(this.yokitup.active, this.$refs.yokitupCard);
            });
        } else {
          this.$root.$emit(MODAL_SHOW_EVENT, null, 'confirm-dialog', {
            description: this.$t('organisation_settings.tabs.bi_partners.yokitup.actions.deactivate.confirm.description'),
            onConfirmAsync: this.deactivateYokitup,
            submitLabel: this.$t('organisation_settings.tabs.bi_partners.yokitup.actions.deactivate.confirm.submit_label'),
            title: this.$t('organisation_settings.tabs.bi_partners.yokitup.actions.deactivate.confirm.title'),
          });
        }
      },
    },
    canUpsellDvore() {
      return !(this.isDvoreActive || this.isAnalyticsModuleActivated);
    },
    isAnalyticsModuleActivated() {
      return this.currentOrganisation.attributes.analyticsModule;
    },
    isPartnerToolGoTenzoEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_PARTNER_TOOL_GOTENZO_ACTIVATED');
    },
    analyticsApiLink() {
      return `${location.protocol}//${location.host}/public/v1/docs/index.html?urls.primaryName=Public%20API%20Docs`;
    },
    goTenzoPartnerLink() {
      let goTenzoFileName = '';

      switch (this.currentUser.attributes.lang) {
        case 'en': goTenzoFileName = 'GOTENZO_COLLECTION_OF_CONSENT.pdf'; break;
        case 'es': goTenzoFileName = 'GOTENZO_FORMULARIO_DE_CONSENTIMIENTO.pdf'; break;
        case 'de': goTenzoFileName = 'GOTENZO_EINHOLUNG_DER_EINWILLIGUNG.pdf'; break;
        default: goTenzoFileName = 'GOTENZO_RECUEIL_DU_CONSENTEMENT.pdf';
      }

      return `${staticRootUrl}/documents/${goTenzoFileName}`;
    },
    timeSkipperLink() {
      switch (this.currentUser.attributes.lang) {
        case 'en': return 'https://www.timeskipper.co/en/';
        case 'es': return 'https://www.timeskipper.co/es/';
        case 'de': return 'https://www.timeskipper.co/de/';
        default: return 'https://www.timeskipper.co/fr/';
      }
    },
  },
  created() {
    // FIXME: do NOT send currentOrganisation.id to backend
    // Backend should only rely on session current_organisation.
    httpClient
      .get(`/v3/api/organisations/${this.currentOrganisation.id}/applications`)
      .then(response => {
        response.data.data.forEach(organisationApplication => {
          if (organisationApplication.attributes.name === 'Dvore') this.dvore = organisationApplication.attributes;
          if (organisationApplication.attributes.name === 'Yokitup') this.yokitup = organisationApplication.attributes;
          if (organisationApplication.attributes.name === 'AnalyticsApi') this.analyticsApi = organisationApplication.attributes;
        });
      })
      .catch(error => { throw error; })
      .finally(() => { this.creating = false; });
  },
  methods: {
    deactivateDvore() {
      this.isDvoreLoading = true;
      return this.updateApplication(this.dvore.id, 'Dvore')
        .then(response => {
          this.dvore = response.data.data.attributes;
          this.handleCollapse(this.dvore.active, this.$refs.dvoreCard);
        })
        .finally(() => {
          this.isDvoreLoading = false;
        });
    },
    deactivateYokitup() {
      this.isYokitupLoading = true;
      return this.updateApplication(this.yokitup.id, 'Yokitup')
        .then(response => {
          this.yokitup = response.data.data.attributes;
          this.handleCollapse(this.yokitup.active, this.$refs.yokitupCard);
        })
        .finally(() => { this.isYokitupLoading = false; });
    },
    deactivateAnalytics() {
      this.isAnalyticsApiLoading = true;
      return this.updateApplication(this.analyticsApi.id, 'AnalyticsApi')
        .then(response => {
          this.analyticsApi = response.data.data.attributes;
          this.handleCollapse(this.analyticsApi.active, this.$refs.analyticsApiCard);
        })
        .finally(() => { this.isAnalyticsApiLoading = false; });
    },
    activateApplication(application, applicationName) {
      this.setLoading(applicationName, true);

      if (application) {
        return this.updateApplication(application.id, applicationName)
          .finally(() => {
            this.setLoading(applicationName, false);
          });
      }
      return this.createApplication(applicationName)
        .finally(() => {
          this.setLoading(applicationName, false);
        });
    },
    updateApplication(applicationId, applicationName) {
      // FIXME: do NOT send currentOrganisation.id to backend
      return httpClient
        .patch(`/v3/api/organisations/${this.currentOrganisation.id}/applications/${applicationId}`)
        .then(response => {
          const biPartnersName = this.getKeyTranslateBiPartners(applicationName);
          const responseMessage = response.data.data.attributes.active ?
            this.$t(`organisation_settings.tabs.bi_partners.${biPartnersName}.actions.activate.success`) :
            this.$t(`organisation_settings.tabs.bi_partners.${biPartnersName}.actions.deactivate.success`);

          this.$skToast({
            message: responseMessage,
            variant: 'success',
          });
          return response;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('organisation_settings.error_message'),
            variant: 'error',
          });
          throw error;
        });
    },
    createApplication(applicationName) {
      // FIXME: do NOT send currentOrganisation.id to backend
      return httpClient
        .post(`/v3/api/organisations/${this.currentOrganisation.id}/applications`, { name: applicationName })
        .then(response => {
          const biPartnersName = this.getKeyTranslateBiPartners(applicationName);
          this.$skToast({
            message: this.$t(`organisation_settings.tabs.bi_partners.${biPartnersName}.actions.activate.success`),
            variant: 'success',
          });
          return response;
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('organisation_settings.error_message'),
            variant: 'error',
          });
          throw error;
        });
    },
    handleCollapse(switchStatus, cardRefs) {
      if (switchStatus) {
        cardRefs.$refs.collapse.openCollapse();
      } else {
        cardRefs.$refs.collapse.closeCollapse();
      }
    },
    getKeyTranslateBiPartners(name) {
      if (name === 'Dvore') return 'dvore';
      if (name === 'Yokitup') return 'yokitup';
      if (name === 'AnalyticsApi') return 'analytics_api';
      return '';
    },
    setLoading(applicationName, status) {
      if (applicationName === 'Dvore') this.isDvoreLoading = status;
      if (applicationName === 'Yokitup') this.isYokitupLoading = status;
      if (applicationName === 'AnalyticsApi') this.isAnalyticsApiLoading = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.bi-partner__spinner-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.bi-partner__spinner {
  color: $sk-blue;
}

.bi-partner__title {
  margin-bottom: 33px;
}

.bi-partner__card {
  margin: 21px 0;
}

.bi-partner__card-description {
  color: $sk-grey;
  margin-top: 10px;
}

.bi-partner__card-link {
  color: $sk-grey;
  margin-top: 15px;
}
</style>
