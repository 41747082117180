<template>
  <img
    width="90"
    src="./images/logo-yokitup.svg"
  >
</template>

<script>
export default {
  name: 'Yokitup',
};
</script>
