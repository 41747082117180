<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
  >
    <path
      :fill="fill"
      d="M10.625 0H0.625V1.25H10.625V0ZM11.25 6.25V5L10.625 1.875H0.625L0 5V6.25H0.625V10H6.875V6.25H9.375V10H10.625V6.25H11.25ZM5.625 8.75H1.875V6.25H5.625V8.75Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#00bada',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 12px; // ie11 support
  width: initial;
  height: 10px; // ie11 support
  height: initial;

  @supports (-ms-ime-align: auto) {
    /* IE Edge 16+ CSS */
    width: 12px; // EDGE support
    height: 10px; // EDGE support
  }
}
</style>
