<template>
  <div>
    <div class="team-schedule-modal__amendments-mechanics__title">
      {{ $t('shop_settings.tabs.teams.variable_hours_modal.cyclic_amendments_section.title') }}
    </div>
    <SkCard class="team-schedule-modal__amendments-mechanics__container">
      <CircledIIcon
        class="team-schedule-modal__amendments-mechanics__icon"
        fill="#2b66fe"
        width="24"
        height="24"
      />
      <!-- eslint-disable max-len, vue/no-v-html -->
      <div
        class="team-schedule-modal__amendments-mechanics__content"
        v-html="$t('shop_settings.tabs.teams.variable_hours_modal.cyclic_amendments_section.amendments_mechanics')"
      />
      <!-- eslint-enable max-len, vue/no-v-html -->
    </SkCard>
    <div
      v-if="teamSchedule"
      class="team-schedule-modal__section"
    >
      <div
        v-show="isPreviousButtonDisplayed"
        class="
          team-schedule-modal__amendments-table__nav-button
          team-schedule-modal__amendments-table__nav-button--previous
        "
        @click="showPreviousColumns"
      >
        <ChevronLeftIcon class="amendments-table__nav-button__icon" />
      </div>
      <div
        v-show="isNextButtonDisplayed"
        class="
          team-schedule-modal__amendments-table__nav-button
          team-schedule-modal__amendments-table__nav-button--next
        "
        @click="showNextColumns"
      >
        <ChevronRightIcon class="amendments-table__nav-button__icon" />
      </div>
      <table class="team-schedule-modal__amendments-table">
        <tr>
          <th colspan="2" />
          <th
            v-for="weekIndex in displayedColumnIndexes"
            :key="weekIndex"
          >
            <VariableContractHoursTag
              class="team-schedule-modal__amendments-table__tag"
              :nb-weeks="teamSchedule.weekCycle"
              :specific-week-index="weekIndex"
            />
          </th>
        </tr>
        <tr
          v-for="(user, userIndex) in teamUsers"
          :key="`user${userIndex}`"
        >
          <td class="team-schedule-modal__amendments-table__user-name">
            <div class="amendments-table__user-name__container">
              <AvatarMedallion
                class="amendments-table__user-name__avatar"
                :medallion-text="userInitials(user)"
              />
              {{ fullName(user) }}
            </div>
          </td>
          <td
            class="team-schedule-modal__amendments-table__contract-hours"
            :style="contractHoursColor(user)"
          >
            {{ userContractHours(user) }}
          </td>
          <template v-if="hasUserHourlyRate(user)">
            <EditableCell
              v-for="weekIndex in displayedColumnIndexes"
              :key="`user${userIndex}-week${weekIndex}`"
              :ref="`user${userIndex}-week${weekIndex}`"
              :cyclic-amendment="cyclicAmendmentsByUser[user.id][weekIndex]"
              :width="cellsWidth"
              @update-hours-value="hoursValue =>
                handleUpdateHoursValue(user.id, weekIndex, hoursValue)"
              @tab-press="() => handleTab(userIndex, weekIndex)"
            />
          </template>
          <template v-else>
            <td
              v-for="weekIndex in displayedColumnIndexes"
              :key="`user${userIndex}-week${weekIndex}`"
              v-tooltip="disabledCellTooltip"
              class="team-schedule-modal__amendments-table__disabled-cell"
              :style="disabledCellWidth"
            >
              -
            </td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { LICENSE_COLORS } from '@app-js/shared/constants/colors';

import VariableContractHoursTag from '@app-js/shared/components/VariableContractHoursTag';
import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';

import EditableCell from './EditableCell';

export default {
  name: 'CyclicAmendmentsHoursSection',
  components: { VariableContractHoursTag, AvatarMedallion, EditableCell },
  props: {
    cyclicAmendmentsByUser: {
      type: Object,
      required: true,
    },
    teamSchedule: {
      type: Object,
      required: true,
    },
    teamUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // index of first column to be displayed (see displayedColumnIndexes)
      firstDisplayedColumnIndex: 0,
    };
  },
  computed: {
    ...mapGetters('employees', ['fullName', 'userInitials']),
    areAllCyclicAmendmentsDisplayed() {
      return this.teamSchedule.weekCycle < 4;
    },
    displayedColumnIndexes() {
      // Only 3 columns can be displayed at once
      // indexes of columns currently displayed
      // in case there are more than 3 weeks in cycle -> need to truncate
      // display maximum 3 indexes, starting with firstDisplayedColumnIndex
      const indexes = [];
      let i = 0;
      while (i < 3 && i < this.teamSchedule.weekCycle) {
        indexes.push(this.firstDisplayedColumnIndex + i);
        i += 1;
      }

      return indexes;
    },
    cellsWidth() {
      return this.teamSchedule.weekCycle > 2 ? '93px' : '139px';
    },
    isPreviousButtonDisplayed() {
      return !this.areAllCyclicAmendmentsDisplayed && this.firstDisplayedColumnIndex > 0;
    },
    isNextButtonDisplayed() {
      return !this.areAllCyclicAmendmentsDisplayed &&
        this.firstDisplayedColumnIndex < this.maxFirstIndex;
    },
    maxFirstIndex() {
      return this.teamSchedule.weekCycle - 3;
    },
    disabledCellWidth() {
      return { width: this.cellsWidth };
    },
    disabledCellTooltip() {
      return this.$t(
        'shop_settings.tabs.teams.variable_hours_modal.cyclic_amendments_section.disabled_cell_tooltip',
      );
    },
  },
  methods: {
    hasUserHourlyRate({ attributes }) {
      return !attributes.onExtra && !attributes.onDayRate;
    },
    userContractHours({ attributes }) {
      if (attributes.onExtra || attributes.onDayRate) {
        const key = attributes.onExtra ? 'extra' : 'day_rate';
        return this.$t(
          `shop_settings.tabs.teams.variable_hours_modal.cyclic_amendments_section.${key}`,
        );
      }

      return `${attributes.currentPermanentContractHours}h`;
    },
    contractHoursColor({ attributes }) {
      return { color: LICENSE_COLORS[attributes.licenseType].color };
    },
    handleUpdateHoursValue(userId, index, value) {
      this.cyclicAmendmentsByUser[userId][index].hours = value;
      if (this.cyclicAmendmentsByUser[userId][index].unedited) {
        this.cyclicAmendmentsByUser[userId][index].unedited = false;
      }
    },
    showPreviousColumns() {
      if (this.firstDisplayedColumnIndex < 2) {
        this.firstDisplayedColumnIndex = 0;
      } else {
        this.firstDisplayedColumnIndex -= 2;
      }
    },
    showNextColumns() {
      this.firstDisplayedColumnIndex += 2;
      if (this.firstDisplayedColumnIndex > this.maxFirstIndex) {
        this.firstDisplayedColumnIndex = this.maxFirstIndex;
      }
    },
    handleTab(userIndex, weekIndex) {
      const lastWeekIndex = this.teamSchedule.weekCycle - 1;
      const lastDisplayedWeekIndex =
        this.displayedColumnIndexes[this.displayedColumnIndexes.length - 1];

      let isFocusDelayed = false;
      if (weekIndex < lastWeekIndex) { // if current cell isn't last in row -> focus next in row
        weekIndex += 1;

        // if we need to change displayed columns -> add delay before focus to wait for DOM change
        if (weekIndex > lastDisplayedWeekIndex) {
          this.showNextColumns();
          isFocusDelayed = true;
        }
      } else { // otherwise, focus next line
        userIndex += 1;
        weekIndex = 0;

        // if we need to change displayed columns -> add delay before focus to wait for DOM change
        if (this.firstDisplayedColumnIndex > 0) {
          this.firstDisplayedColumnIndex = 0;
          isFocusDelayed = true;
        }
      }

      if (isFocusDelayed) {
        setTimeout(() => {
          this.handleFocus(userIndex, weekIndex);
        }, 100);
      } else {
        this.handleFocus(userIndex, weekIndex);
      }
    },
    handleFocus(userIndex, weekIndex) {
      const editableCell = this.$refs[`user${userIndex}-week${weekIndex}`];

      // If tab was pressed on last editable cell -> no next cell to focus
      if (!editableCell) return;

      editableCell[0].handleFocus();
    },
  },
};
</script>

<style lang="scss" scoped>
.team-schedule-modal__amendments-mechanics {
  &__title {
    font-size: $fs-text-l;
    line-height: 16px;
    font-weight: bold;
    padding: 20px 24px 0;
  }

  &__container {
    margin: 24px 24px 0;
    padding: 12px;
    display: flex;
  }

  &__icon {
    margin-left: 10px;
    margin-top: 8px;
    flex-shrink: 0;
  }

  &__content {
    padding-left: 18px;
    color: $sk-grey;
  }
}

.team-schedule-modal__section {
  position: relative;
  padding: 24px;
}

.team-schedule-modal__amendments-table__nav-button {
  position: absolute;
  top: 46px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-radius: 50%;
  box-shadow: 0 8px 16px 0 #0000001f;
  background-color: white;
  cursor: pointer;

  &--previous {
    left: 297px;
  }

  &--next {
    left: 566px;

    .amendments-table__nav-button__icon {
      position: relative;
      left: 2px;
    }
  }
}

.team-schedule-modal__amendments-table {
  border-collapse: collapse;

  td,
  th {
    border-right: 1px solid $sk-grey-10;
    border-bottom: 1px solid $sk-grey-10;
  }

  td {
    height: 54px;
  }

  &__contract-hours {
    width: 61px;
    text-align: center;
  }

  &__disabled-cell {
    text-align: center;
    background-color: $sk-grey-5;
    cursor: not-allowed;
  }

  &__tag {
    margin: 0 auto 11px;
  }

  &__user-name {
    width: 232px;
  }
}

.amendments-table__user-name__container {
  display: flex;
  align-items: center;
}

.amendments-table__user-name__avatar {
  margin-right: 16px;
}
</style>
