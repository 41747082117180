<template>
  <transition name="fade">
    <div
      v-show="isVisible"
      class="all-employees-completed-modal__wrapper"
    >
      <div class="all-employees-completed-modal">
        <div class="all-employees-completed-modal__header">
          <SunIcon
            class="all-employees-completed-modal__sun"
            height="120"
            width="120"
            fill="#ffb545"
          />
          <SkCircleButton
            icon="CrossV2Icon"
            size="medium"
            @click="hide"
          />
        </div>
        <div class="all-employees-completed-modal__body">
          <span class="all-employees-completed-modal__body__title">
            {{ $t('employees.all_employees_completed_modal.title') }}
          </span>
          <span class="all-employees-completed-modal__body__description">
            {{ $t('employees.all_employees_completed_modal.description') }}
          </span>
          <SkOroraButton
            @click="hide"
          >
            {{ buttonLabel }}
          </SkOroraButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AllEmployeesCompletedModal',
  props: {
    buttonLabel: {
      type: String,
      default() {
        return this.$t('employees.all_employees_completed_modal.submit');
      },
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    this.listenOnRoot('show-all-employees-complete-modal', this.show);
    this.listenOnRoot('hide-all-employees-complete-modal', this.hide);

    if (this.isVisible) {
      this.$nextTick(this.show);
    }
  },
  methods: {
    show() {
      this.$emit('show');
      this.isVisible = true;
    },
    hide() {
      if (!this.isVisible) return;
      this.$emit('hide');
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-employees-completed-modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .2);
  z-index: $z-index-modal-backdrop;
  display: flex;

  .all-employees-completed-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 342px;
    height: 427px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    padding: 16px;
    background-image: url('./flubber.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .all-employees-completed-modal__header {
    display: flex;
    justify-content: space-between;

    .all-employees-completed-modal__sun {
      padding: 16px;
      -webkit-animation: spin 7s linear infinite;
      -moz-animation: spin 7s linear infinite;
      animation: spin 7s linear infinite;
    }

    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }

    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  .all-employees-completed-modal__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;

    &__title {
      font-weight: $fw-semi-bold;
      font-size: $fs-heading-m;
      line-height: normal;
      padding-bottom: 16px;
    }

    &__description {
      font-size: $fs-text-l;
      color: $sk-grey-50;
      padding-bottom: 32px;
      line-height: normal;
    }
  }
}

</style>
