<template>
  <div class="settings__teams__container">
    <h1 class="sk-header--1">
      {{ $t('shop_settings.tabs.teams.title') }}
    </h1>
    <p class="sk-subtitle--large">
      {{ $t(`shop_settings.tabs.teams.subtitle.${sector}`) }}
    </p>
    <div class="teams__explanation-card">
      <SkMedallion
        size="small"
        text="i"
        background-color="#2b66fe"
        color="white"
      />
      <div class="teams__explanation-card__content">
        <p class="teams__explanation-card__title">
          {{ $t('shop_settings.tabs.teams.explanation_card.title') }}
        </p>
        <p class="sk-subtitle--medium">
          {{ $t(`shop_settings.tabs.teams.explanation_card.subtitle.${sector}`) }}
        </p>
        <p class="sk-subtitle--medium teams__explanation-card--italic">
          {{ $t(`shop_settings.tabs.teams.explanation_card.examples.${sector}.p1`) }}
        </p>
        <p
          v-if="sector !== 'general'"
          class="sk-subtitle--medium teams__explanation-card--italic"
        >
          {{ $t(`shop_settings.tabs.teams.explanation_card.examples.${sector}.p2`) }}
        </p>
        <p
          v-if="sector !== 'general'"
          class="sk-subtitle--medium teams__explanation-card--italic"
        >
          {{ $t(`shop_settings.tabs.teams.explanation_card.examples.${sector}.p3`) }}
        </p>
      </div>
    </div>
    <div
      v-if="loadingFetch"
      class="teams__spinner"
    >
      <SkLoader size="large" />
    </div>
    <SkTable
      v-else
      :columns="header"
      class="teams__table"
    >
      <TeamRow
        v-for="team in teams"
        :key="team.id"
        :disabled="!canCreatePosteAndTeam"
        :team="team"
        @update-team="handleUpdate"
        @create-team="handleCreate"
        @delete-team="handleDelete"
        @manage-variable-hours="handleVariableContractHoursModal"
      />
    </SkTable>
    <div class="teams__button">
      <SkOroraButton
        icon="PlusSignV2Icon"
        :disabled="isATeamUnsaved"
        @click="addTeam"
      >
        {{ $t('shop_settings.tabs.teams.add_team') }}
      </SkOroraButton>
    </div>
    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <VariableContractHoursModal
        ref="variableContractHoursModal"
        :team="modalTeam"
        :added-users="modalAddedUsers"
        :removed-users="modalRemovedUsers"
        :all-team-user-ids="modalAllTeamUserIds"
        :selected-step="selectedStep"
      />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';

import TeamRow from './TeamRow';
import VariableContractHoursModal from './VariableContractHoursModal';

export default {
  name: 'Teams',
  components: {
    TeamRow,
    VariableContractHoursModal,
  },
  data() {
    return {
      loadingFetch: true,
      modalTeam: null,
      modalAddedUsers: [],
      modalRemovedUsers: [],
      modalAllTeamUserIds: [],
      selectedStep: 1,
      licenseTypeByUser: {},
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teams', 'loadingCreate', 'teamSchedules', 'teamEmployees']),
    ...mapGetters('currentLicense', ['canCreatePosteAndTeam']),
    ...mapGetters('employees', ['fullName']),
    ...mapGetters('currentShop', ['sector', 'isVariableContractHoursAvailable']),
    ...mapGetters('shopTeams', ['teamSchedulesForTeam']),
    header() {
      const header = [
        { title: this.$t('shop_settings.tabs.teams.table.headers.team') },
        { title: this.$t('shop_settings.tabs.teams.table.headers.teammates') },
        { title: null },
      ];
      if (this.isVariableContractHoursAvailable) {
        header.splice(
          2,
          0,
          { title: this.$t('shop_settings.tabs.teams.table.headers.variable_contract_hours'), class: 'rotations-table-header' },
        );
      }
      return header;
    },
    isATeamUnsaved() {
      return this.teams.filter(team => !team.id).length > 0;
    },
    onConfirmDeleteModalDescription() {
      const names =
        this.modalRemovedUsers
          .map(deletedTeammate => this.fullName(deletedTeammate))
          .join(', ');

      return this.$t('shop_settings.tabs.teams.on_confirm_modal.description',
        {
          employees_name: names,
          team_name: this.modalTeam.attributes.name,
        },
      );
    },
  },
  watch: {
    currentShop(newValue) {
      const params = {
        shopId: newValue.id,
        isVariableContractHoursAvailable: this.isVariableContractHoursAvailable,
      };

      Promise.all([
        this.fetchTeams(params),
        this.fetchTeamsEmployees(params),
      ])
        .then(() => {
          this.loadingFetch = false;
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
          });
        });
    },
    teams(newValue) {
      if (newValue.length === 0) {
        this.addTeam();
      }
    },
  },
  mounted() {
    const params = {
      shopId: this.currentShop.id,
      isVariableContractHoursAvailable: this.isVariableContractHoursAvailable,
    };

    Promise.all([
      this.fetchTeams(params),
      this.fetchTeamsEmployees(params),
    ])
      .then(() => {
        this.loadingFetch = false;
      })
      .catch(() => {
        this.$skToast({
          message: this.$t('errors.standard_message'),
          variant: 'error',
        });
      });
  },
  methods: {
    ...mapActions('shopTeams', [
      'fetchTeams',
      'createTeam',
      'updateTeam',
      'deleteTeam',
      'fetchTeamsEmployees',
    ]),
    handleCreate(params) {
      this.$skAnalytics.track('team_add');
      this.createTeam(params)
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.teams.table.actions.create.success_message'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: error.response.data.message,
            variant: 'error',
          });
        });
    },
    handleUpdate(params) {
      this.updateTeam(params)
        .then(() => {
          this.$skToast({
            message: this.$t('shop_settings.tabs.teams.table.actions.update.success_message'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: error.response.data.message,
            variant: 'error',
          });
        });
    },
    handleDelete(team) {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.teams.table.actions.delete.confirmation_message'),
        onConfirm: () => {
          this.deleteTeam(team)
            .then(() => {
              this.$skToast({
                message: this.$t('shop_settings.tabs.teams.table.actions.delete.success_message'),
                variant: 'success',
              });
            });
        },
      });
    },
    computeVariableContractHoursDatas(params) {
      // if we add or remove users for team schedules flow
      // those params must be present in the event
      // otherwise we juste pass a team object
      const isTeamScheduleUsersUpdateStep =
        params.newTeamUserIds &&
        params.currentTeamUserIds;

      this.selectedStep = isTeamScheduleUsersUpdateStep ? 2 : 1;
      this.modalTeam = params.team;
      this.modalAllTeamUserIds = params.allTeamUserIds;

      if (isTeamScheduleUsersUpdateStep) {
        const addedUserIds =
          params.newTeamUserIds.filter(newTeamUserId => (
            !params.currentTeamUserIds.includes(newTeamUserId)),
          );
        const removedUserIds = params.currentTeamUserIds
          .filter(currentTeamUserId => !params.newTeamUserIds.includes(currentTeamUserId));
        this.modalAddedUsers =
          this.teamEmployees.filter(employee => addedUserIds.includes(employee.id));
        this.modalRemovedUsers =
          this.teamEmployees.filter(employee => removedUserIds.includes(employee.id));
      }
    },
    handleVariableContractHoursModal(params) {
      this.resetModalData();
      this.computeVariableContractHoursDatas(params);
      if (this.modalRemovedUsers.length > 0) {
        this.onConfirmDeleteModal();
      } else {
        this.displayVariableHoursModal();
      }
    },
    resetModalData() {
      this.modalAddedUsers = [];
      this.modalRemovedUsers = [];
      this.modalAllTeamUserIds = [];
      this.modalTeam = null;
      this.selectedStep = 1;
    },
    onConfirmDeleteModal() {
      this.$root.$emit('confirm', event, {
        title: this.$t('shop_settings.tabs.teams.on_confirm_modal.title'),
        description: this.onConfirmDeleteModalDescription,
        cancelLabel: this.$t('shop_settings.tabs.teams.on_confirm_modal.cancel'),
        submitLabel: this.$t('shop_settings.tabs.teams.on_confirm_modal.submit'),
        variant: 'danger',
        icon: 'TrashCanIcon',
        descriptionHeader: {
          color: '#d03e50',
          text: this.$t('shop_settings.tabs.teams.on_confirm_modal.warning'),
          icon: 'CircledExclamationMarkIcon',
        },
        submitIcon: 'TrashCanIcon',
        iconColorSchema: { fill: '#d03e50', background: '#fce8e7' },
        onConfirm: () => {
          this.displayVariableHoursModal();
        },
      });
    },
    displayVariableHoursModal() {
      setTimeout(() => {
        this.emitOnRoot(
          MODAL_SHOW_EVENT,
          event,
          this.$refs.variableContractHoursModal.$el.id,
        );
      }, 100);
    },
    addTeam() {
      this.teams.splice(this.teams.length, 0, {
        id: null,
        attributes: { name: null },
        relationships: { users: null },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__teams__container {
  .teams__explanation-card {
    margin: 20px 0 40px;
    width: 100%;
    border: 1px solid $sk-grey-10;
    border-radius: 4px;
    padding: 20px;
    display: flex;

    .teams__explanation-card__content {
      display: flex;
      flex-direction: column;
      padding-left: 15px;

      .teams__explanation-card__title {
        margin-bottom: 5px;
      }
    }
  }

  .teams__spinner {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sk-blue;
  }

  .teams__table {
    width: 100%;

    .teams__table__header-row {
      border-bottom: 1px solid $sk-grey-10;

      .teams__table__header-cell {
        padding-bottom: 10px;
      }
    }
  }
}

.teams__explanation-card--italic {
  font-style: italic;
}

.teams__button {
  padding-bottom: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
