<template>
  <div
    class="icon-wrapper"
    :style="{ backgroundColor: color }"
  >
    <component
      :is="iconName"
      :style="cellStyle"
      :fill="iconColor"
    />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    cellStyle: {
      type: Object,
      default: () => {},
    },
    iconColor: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
