<template>
  <div class="annualization-details__section">
    <div class="annualization-details__section--title">
      {{ $t('shop_settings.tabs.details.annualization.over_hours_section.title') }}
    </div>
    <div class="annualization-details__row">
      <span class="annualization-details__label">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.switch.label')" />
        <!-- eslint-enable vue/no-v-html -->
        <CircledQuestionMarkIcon
          v-tooltip.right="$t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.switch.tooltip')"
          fill="black"
          width="16"
          height="16"
        />
      </span>
      <span class="annualization-details__switch">
        <SkSwitch v-model="areOverhoursActivated" />
      </span>
    </div>
    <div
      v-if="localAreOverhoursActivated"
      class="annualization-details__section annualization-details__section--overhours"
    >
      <div class="annualization-details__row">
        <span class="annualization-details__section--subtitle">
          {{ $t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.subtitle') }}
        </span>
      </div>
      <div class="annualization-details__row">
        <span class="annualization-details__label">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.included.label')" />
          <!-- eslint-enable vue/no-v-html -->
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('shop_settings.tabs.details.annualization.over_hours_section.over_hours.included.tooltip')"
            fill="black"
            width="16"
            height="16"
          />
        </span>
        <SkInputGroup class="annualization-details__double-input--first">
          <SkInput
            v-model="overHoursSlices[0][0]"
            placeholder="0"
            type="number"
            min="0"
            :disabled="!canEditShopRulesAndAbsences"
          />
          <template #append>
            h
          </template>
        </SkInputGroup>
      </div>
      <SliceRow
        v-for="(slice, index) in overHoursSlices"
        :key="'overHours-' + index"
        :slice-number="index"
        :slice="slice"
        type="overHours"
        @handle-delete-slice="overHoursSlices.splice(index, 1)"
      />
      <div
        v-if="overHoursSlices.length < 3"
        class="annualization-details__add-slice-button"
      >
        <SkOroraButton
          icon="PlusSignV2Icon"
          variant="tertiary"
          @click="overHoursSlices.push([0, 0, 0])"
        >
          {{ $t('shop_settings.tabs.details.annualization.over_hours_section.add_slice') }}
        </SkOroraButton>
      </div>
      <div class="annualization-details__row">
        <span class="annualization-details__section--subtitle">
          {{ $t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.subtitle') }}
        </span>
      </div>
      <div class="annualization-details__row">
        <span class="annualization-details__label">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.included.label')" />
          <!-- eslint-enable vue/no-v-html -->
          <CircledQuestionMarkIcon
            v-tooltip.right="$t('shop_settings.tabs.details.annualization.over_hours_section.complementary_hours.included.tooltip')"
            fill="black"
            width="16"
            height="16"
          />
        </span>
        <SkInputGroup class="annualization-details__double-input--first">
          <SkInput
            v-model="complementaryHoursSlices[0][0]"
            placeholder="0"
            type="number"
            min="0"
            :disabled="!canEditShopRulesAndAbsences"
          />
          <template #append>
            %
          </template>
        </SkInputGroup>
      </div>
      <SliceRow
        :slice-number="0"
        :slice="complementaryHoursSlices[1]"
        :complementary-hours-majoration="complementaryHoursSlices[0][1]"
        type="complementaryHours"
        @update-complementary-hours-majoration="value => {
          $set(complementaryHoursSlices[0], 1, value)
        }"
      />
      <SliceRow
        :slice-number="1"
        :slice="complementaryHoursSlices[1]"
        :complementary-hours-majoration="complementaryHoursSlices[1][1]"
        type="complementaryHours"
        @update-complementary-hours-majoration="value => {
          $set(complementaryHoursSlices[1], 1, value)
        }"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapState,
} from 'vuex';

import SliceRow from './SliceRow.vue';

export default {
  name: 'OverHoursSection',
  components: {
    SliceRow,
  },
  props: {
    overHoursSlices: {
      type: Array,
      required: true,
    },
    complementaryHoursSlices: {
      type: Array,
      required: true,
    },
    localAreOverhoursActivated: {
      type: Boolean,
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters('currentLicense', ['canEditShopRulesAndAbsences']),
    ...mapState('shopAnnualizationConfig', ['shopAnnualizationConfig']),
    areOverhoursActivated: {
      get() {
        return this.localAreOverhoursActivated;
      },
      set(isActivating) {
        this.$emit('update-overhours-switch', isActivating);
      },
    },
  },
  mounted() {
    this.localAreOverhoursActivated = this.shopAnnualizationConfig.attributes.overhoursActivated;
  },
};
</script>

<style lang="scss" scoped>
.annualization-details__section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  &--bordered {
    border-bottom: 1px solid $sk-grey-10;
  }

  &--title {
    color: $sk-black;
    font-size: $fs-heading-xs;
    font-weight: $fw-semi-bold;
  }

  &--subtitle {
    color: $sk-black;
    font-size: $fs-text-m;
    font-weight: $fw-semi-bold;
  }

  &--overhours {
    padding: 0;
  }
}

.annualization-details__row {
  display: flex;
  align-items: center;
  gap: 24px;
}

.annualization-details__label {
  display: flex;
  align-items: center;
  width: 360px;
  gap: 4px;
}

.annualization-details__double-input {
  &--first {
    width: 190px;
  }
}

.annualization-details__add-slice-button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
</style>
