<template>
  <!-- eslint-disable max-len -->
  <tr class="employee-amendments-table__amendment">
    <td
      :class="sortingClass"
      class="employee-amendments-table__column__type"
    >
      {{ typeText }}
      <div
        v-if="isTemporaryActive"
        class="employee-amendments-table__current-amendment-tag"
      >
        <SkTag
          variant="blue"
          with-bullet-point
        >
          <span>
            {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.ongoing') }}
          </span>
        </SkTag>
      </div>
    </td>
    <td
      :class="sortingClass"
      class="employee-amendments-table__column__date-added"
    >
      {{ dateAddedText }}
    </td>
    <td
      :class="sortingClass"
      class="employee-amendments-table__column__effective-period"
    >
      {{ effectivePeriodText }}
    </td>
    <td class="employee-amendments-table__column__creator">
      {{ creatorText }}
    </td>
    <td class="employee-amendments-table__column__contract_hours">
      {{ contractHoursText }}
    </td>
    <td class="employee-amendments-table__column__actions">
      <SkCircleButton
        v-if="!isContractReadOnlyView"
        v-tooltip="$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.delete_tooltip')"
        v-track="'remove_admendment'"
        icon="TrashCanV2Icon"
        @click="deleteAmendment"
      />
    </td>
  </tr>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';

export default {
  name: 'AmendmentTableRow',
  props: {
    amendment: {
      type: Object,
      required: true,
    },
    sorting: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['contract']),
    ...mapGetters('contracts', ['isContractReadOnlyView']),

    sortingClass() {
      return {
        'employee-amendments-table-cell--sorting': this.sorting,
      };
    },

    isTemporary() {
      return this.amendment.attributes.temporary === true;
    },

    isTemporaryActive() {
      return this.isTemporary && this.amendment.attributes.activeTemporary === true;
    },

    startsAt() {
      return skDate(this.amendment.attributes.startsAt).utc().format('DD/MM/YYYY');
    },

    endsAt() {
      return skDate(this.amendment.attributes.endsAt).utc().format('DD/MM/YYYY');
    },

    createdAt() {
      return skDate(this.amendment.attributes.createdAt).format('DD/MM/YYYY');
    },

    typeText() {
      return this.amendment.attributes.temporary === true ?
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.type_text.temporary',
          { position: this.amendment.attributes.typePosition }) :
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.type_text.permanent',
          { position: this.amendment.attributes.typePosition });
    },

    dateAddedText() {
      if (!this.amendment.attributes.createdAt) return '-';

      return this.createdAt;
    },

    effectivePeriodText() {
      return this.isTemporary ?
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.effective_period.temporary',
          { starts_at: this.startsAt, ends_at: this.endsAt }) :
        this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.effective_period.permanent',
          { starts_at: this.startsAt });
    },

    creatorText() {
      if (!this.amendment.relationships.creator) return '-';

      return `${this.amendment.relationships.creator.attributes.firstName} ${this.amendment.relationships.creator.attributes.lastName}`;
    },

    contractHoursText() {
      if (!this.amendment.attributes.hours) return '-';

      return `${this.amendment.attributes.hours}${this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.amendments_table.label.hours')}`;
    },
  },

  methods: {
    deleteAmendment() {
      this.$emit('delete-amendment', this.amendment.id, this.employee.id, this.contract.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-amendments-table__amendment {
  &:first-child {
    border-top: 1px solid $sk-grey-10;
  }

  &:last-child {
    border-bottom: 1px solid $sk-grey-10;
  }
}

.employee-amendments-table__column__effective-period {
  white-space: pre;
  text-align: left;
}

.employee-amendments-table__current-amendment-tag {
  display: inline-block;
  padding-left: 15px;
  height: 20px;
}

</style>
