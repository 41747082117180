<template>
  <div
    :class="cellClasses"
    @dblclick="handleCellEdition"
    @click="handleCellHighlight"
  >
    <div :class="wrapperClasses">
      <input
        v-if="editionMode"
        ref="input"
        v-model="cellValue"
        type="number"
        tabindex="-1"
        maxlength="8"
        class="opti-cell__input"
        @blur="onBlur"
        @keyup.enter="onEnter"
        @keydown="validateInput($event)"
      >
      <div
        v-else
        class="opti-cell__value"
      >
        {{ cellValue }}
      </div>
      <!-- TODO - coming next for drag & copy feature -->
      <!-- <div
        v-if="focused"
        class="opti-cell__focus-grab"
        @mousedown="onGrabberMouseDown"
      /> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'Cell',
  props: {
    hourValue: {
      type: Number,
      default: 0,
    },
    posteId: {
      type: Number,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    cellIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      highlighted: false,
      editionMode: false,
      cellValue: null,
    };
  },
  computed: {
    cellClasses() {
      return {
        'opti-cell__container': true,
        'opti-cell__container--highlighted': this.highlighted,
      };
    },
    wrapperClasses() {
      return {
        'opti-cell__wrapper': true,
        'opti-cell__wrapper--edition-mode': this.editionMode,
      };
    },
  },
  mounted() {
    // Setting up unique id to stop highlighting cell.
    this.id = this._uid;

    this.cellValue = this.hourValue.toString();

    this.listenOnRoot('highlighting-opti-cell', id => {
      if (id !== this.id) {
        this.highlighted = false;
      }
    });
  },
  methods: {
    onGrabberMouseDown(event) {
      this.$emit('focus-grab', event.target.closest('.opti-row__data-cell'));
    },
    handleCellHighlight() {
      // Avoid focusing if we are trying to edit the cell
      if (this.editionMode) {
        this.highlighted = false;
        return;
      }

      this.highlighted = true;
      this.emitOnRoot('highlighting-opti-cell', this.id);
    },
    handleCellEdition() {
      // Remove hightlight
      this.highlighted = false;

      // If already editing
      if (this.editionMode) return;

      // Activate edition
      this.editionMode = true;

      // If cellValue is zero, we want to show an empty cell
      if (this.cellValue === '0') {
        this.cellValue = '';
      }

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    validateInput(event) {
      // Allow BackSpace - Delete - Left & Right Arrow
      // 8 -> BackSpace
      // 46 -> Delete
      // 37 -> Arrow Left
      // 39 -> Arrow Right
      if ([8, 46, 37, 39].includes(event.keyCode)) return true;

      // Block everything that is not numeric or . ,
      if (!event.key.match('^[0-9.,]+$')) {
        event.preventDefault();
        return false;
      }

      return true;
    },
    onEnter(event) {
      event.target.blur();
      event.stopPropagation();
    },
    onBlur() {
      // When value is empty, we force zero
      if (this.cellValue === '') {
        this.cellValue = '0';
      }

      // Toggle edition
      this.editionMode = !this.editionMode;

      // Remove highlight
      this.highlighted = false;

      this.updateCellValue();
    },
    updateCellValue() {
      const cellValueInt = parseInt(this.cellValue, 10);
      // If user edited the value and it's different from the original value
      if (cellValueInt === this.hourValue) return;

      const params = {
        cellValue: cellValueInt,
        posteId: this.posteId,
        rowIndex: this.rowIndex,
        cellIndex: this.cellIndex,
      };

      this.emitOnRoot('save-new-cell-value', params);
    },
  },
};
</script>

<style lang="scss" scoped>
.opti-cell__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  color: $sk-black;
  background: $sk-grey-5;
}

.opti-cell__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.opti-cell__wrapper--edition-mode,
.opti-cell__container--highlighted .opti-cell__wrapper {
  border: 2px solid $sk-blue;
}

.opti-cell__focus-grab {
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 8px;
  height: 8px;
  background: $sk-blue;
  border: 1px solid white;
  z-index: 1;
}

.opti-cell__value {
  font-size: $fs-text-m;
}

.opti-cell__value__overs-hours__tooltip {
  background: $sk-black;
  color: white;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: $fs-text-s;
  z-index: 200;
  max-width: 250px;
  text-align: center;
  font-weight: normal;
}

.opti-cell__input {
  width: 100%;
  outline: none;
  border: 0;
  background: $sk-grey-5;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: $fs-text-m;
}

input.opti-cell__input::-webkit-outer-spin-button,
input.opti-cell__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
