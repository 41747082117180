<template>
  <tr :class="holidayTableRowClasses">
    <td
      v-if="paidDaysStep"
      class="holiday-row__cell-icon"
    >
      <CheckMarkIconSmall
        v-if="holidaySettingsGuaranteed"
        fill="#2b66fe"
        width="13"
        height="10"
      />
      <ClosingXIcon
        v-else
        width="13"
        height="10"
      />
    </td>
    <td :class="holidayRowDateColumnClass">
      {{ holidaySettingsFormattedDate }}
    </td>
    <td class="holiday-row__cell-holiday">
      <SkInput
        v-if="editableFields.includes('name')"
        v-model="holidaySettingsName"
      />
      <template v-else>
        {{ holidaySettings.attributes.name }}
      </template>
    </td>
    <td
      v-if="paidDaysStep"
      class="holiday-row__cell-paid"
    >
      <SkSelectV2
        v-model="holidaySettingsIndemnisation"
        :options="holidaySettingsIndemnisationArray"
      />
    </td>
    <td
      v-else
      class="holiday-row__cell-guaranteed"
      :class="holidayRowCellClasses"
    >
      <SkCheckBox
        v-model="holidaySettingsGuaranteed"
        v-tooltip.bottom="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.guaranteed_holiday')"
        class="holiday-row__cell-guaranteed-checkbox"
        :disabled="!editableFields.includes('guaranteed')"
      />
    </td>
    <td v-if="canDelete">
      <span @click="removeHolidaySettings">
        <SkCircleButton
          v-tooltip.bottom="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.delete_holiday')"
          icon="TrashCanV2Icon"
          size="medium"
        />

      </span>
    </td>
  </tr>
</template>

<script>
import skDate from '@skello-utils/dates';

export default {
  name: 'HolidayRow',
  props: {
    holidaySettings: {
      type: Object,
      required: true,
    },
    paidDaysStep: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    editableFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      holidaySettingsIndemnisationArray: [
        { id: 'paid', text: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.paid') },
        { id: 'recover', text: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.second_step.recover') },
      ],
    };
  },
  computed: {
    holidayRowCellClasses() {
      return { 'center-checkbox': this.canDelete };
    },
    holidaySettingsGuaranteed: {
      get() {
        return this.holidaySettings.attributes.guaranteed;
      },
      set(newValue) {
        this.$emit('update-holiday-settings', {
          id: this.holidaySettings.id,
          attribute: 'guaranteed',
          value: newValue,
        });
      },
    },
    holidaySettingsIndemnisation: {
      get() {
        return this.holidaySettings.attributes.paid ? 'paid' : 'recover';
      },
      set(newValue) {
        this.$emit('update-holiday-settings', {
          id: this.holidaySettings.id,
          attribute: 'paid',
          value: newValue === 'paid',
        });
      },
    },
    holidaySettingsName: {
      get() {
        return this.holidaySettings.attributes.name;
      },
      set(newValue) {
        this.$emit('update-holiday-settings', {
          id: this.holidaySettings.id,
          attribute: 'name',
          value: newValue,
        });
      },
    },
    frenchFirstMayTooltip() {
      if (!this.isFrenchFirstMay) return null;

      return this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.paid_french_first_may');
    },
    isFrenchFirstMay() {
      return this.holidaySettings.attributes.name === 'Fête du travail' && this.paidDaysStep;
    },
    holidaySettingsFormattedDate() {
      return skDate(this.holidaySettings.attributes.date).format('dddd DD MMMM');
    },
    holidayTableRowClasses() {
      return {
        'holiday-row': true,
        'holiday-row--guaranteed': this.holidaySettingsGuaranteed,
      };
    },
    holidayRowDateColumnClass() {
      return {
        'holiday-row__cell-date': true,
        'holiday-row__cell-date--first': !this.paidDaysStep,
      };
    },
  },
  methods: {
    removeHolidaySettings() {
      this.$emit('remove-holiday-settings', this.holidaySettings.id);
    },
  },
};
</script>

<style lang="scss" scoped>
tr.holiday-row {
  border-bottom: 1px solid $sk-grey-10;
  height: 60px;

  .holiday-row__cell-icon {
    padding-left: 10px;
  }

  td {
    vertical-align: middle;
  }

  .holiday-row__cell-date {
    text-transform: capitalize;
  }

  .holiday-row__cell-date--first {
    padding-left: 10px;
  }

  .holiday-row__cell-holiday {
    color: $sk-grey;
  }

  .holiday-row__cell-paid {
    padding-right: 10px;
  }

  .holiday-row__cell-guaranteed {
    .holiday-row__cell-guaranteed-checkbox {
      padding-right: 10px;
      float: right;
    }
  }

  .center-checkbox {
    text-align: center;

    .holiday-row__cell-guaranteed-checkbox {
      float: none;
      padding: 0;
      display: inline;
    }
  }
}

</style>
