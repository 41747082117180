<template>
  <TooltipWrapper
    :background-color="backgroundColor"
    :color-text="colorText"
    @mouse-enter="onMouseEvent('mouse-enter')"
    @mouse-leave="onMouseEvent('mouse-leave')"
  >
    <TooltipTitle :title="tooltipData.title" />

    <div class="stacked-column-tooltip__column-wrapper">
      <div class="stacked-column-tooltip__column">
        <!-- eslint-disable-next-line max-len -->
        <span class="stacked-column-tooltip__column-title stacked-column-tooltip__column-metric-label">
          {{ subtitle }}
        </span>
        <!-- eslint-disable-next-line max-len -->
        <div class="stacked-column-tooltip__column-metric stacked-column-tooltip__column-metric-center">
          <div
            class="stacked-column-tooltip__column-legend_icon"
            :style="iconStyle(0)"
          />
          <span class="stacked-column-tooltip__column-metric-label">
            {{ customLabels[0] }}
          </span>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <div class="stacked-column-tooltip__column-metric stacked-column-tooltip__column-metric-center">
          <div
            class="stacked-column-tooltip__column-legend_icon"
            :style="iconStyle(1)"
          />
          <span class="stacked-column-tooltip__column-metric-label">
            {{ customLabels[1] }}
          </span>
        </div>
      </div>
      <div class="stacked-column-tooltip__column stacked-column-tooltip__column-with_margin">
        <div class="stacked-column-tooltip__column-title">
          <span>
            {{ metricTotal }}
          </span>
          <span class="stacked-column-tooltip__column-unit">
            {{ tooltipData.unit }}
          </span>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <div class="stacked-column-tooltip__column-metric stacked-column-tooltip__column-metric-baseline">
          <span>
            {{ tooltipData.firstValue }}
          </span>
          <span class="stacked-column-tooltip__column-unit">
            &nbsp;{{ tooltipData.unit }}
          </span>
          <span class="stacked-column-tooltip__column-metric-label">
            &nbsp;({{ proportionOfTotal(tooltipData.firstValue) }} %)
          </span>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <div class="stacked-column-tooltip__column-metric stacked-column-tooltip__column-metric-baseline">
          <span>
            {{ tooltipData.secondValue }}
          </span>
          <span class="stacked-column-tooltip__column-unit">
            &nbsp;{{ tooltipData.unit }}
          </span>
          <span class="stacked-column-tooltip__column-metric-label">
            &nbsp;({{ proportionOfTotal(tooltipData.secondValue) }} %)
          </span>
        </div>
      </div>
    </div>
  </TooltipWrapper>
</template>

<script>
import TooltipWrapper from '../shared/TooltipWrapper';
import TooltipTitle from '../shared/TooltipTitle';

export default {
  name: 'StackedColumnTooltip',
  components: {
    TooltipWrapper,
    TooltipTitle,
  },
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: 'black',
    },
    colorText: {
      type: String,
      required: false,
      default: 'white',
    },
    tooltipData: {
      type: Object,
      required: true,
    },
    customLabels: {
      type: Array,
      required: false,
      default: null,
    },
    labelColors: {
      type: Array,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    metricTotal() {
      return this.roundIfNecessary(this.tooltipData.firstValue + this.tooltipData.secondValue);
    },
  },
  methods: {
    iconStyle(index) {
      return {
        backgroundColor: this.labelColors[index],
      };
    },
    proportionOfTotal(value) {
      return this.roundIfNecessary((value * 100) / this.metricTotal);
    },
    roundIfNecessary(number) {
      // 10.1234 -> 10.12 ; 10 -> 10
      return Math.round(number * 100) / 100;
    },
    onMouseEvent(event) {
      this.$emit(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.stacked-column-tooltip__column {
  display: flex;
  flex-direction: column;

  &-wrapper {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
  }

  &-with_margin {
    margin-left: 5px;
  }

  &-title {
    margin-bottom: 5px;
    height: 21px;
  }

  &-unit {
    font-size: $fs-text-xs;
  }

  &-metric {
    display: flex;
    color: $sk-grey;
    margin-bottom: 3px;
    height: 21px;

    &-center {
      align-items: center;
    }

    &-baseline {
      align-items: baseline;
    }

    &-label {
      font-size: $fs-text-s;
    }
  }

  &-legend_icon {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 4px;
    padding-left: 3px;
  }
}
</style>
