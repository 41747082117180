<template>
  <div class="kpis__predicted-tab">
    <DatesRow
      :current-shop="currentShop"
      :date="date"
    />
    <div
      ref="predictedTabContent"
      :class="tableMainContentClasses"
    >
      <div
        v-show="kpisLoading"
        class="kpis__read-only__table"
      />
      <slot name="no-planning" />
      <slot name="kpi-pack-offer" />

      <KpiRow
        :date="date"
        :predicted-kpis="predictedKpisData('revenue')"
        :current-shop="currentShop"
        :label="kpisLabels['revenue'].name"
        :icon-label-tooltip="kpisLabels['revenue'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="revenue"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
        predicted
        first-row
      />
      <KpiRow
        v-show="canShowKpisFor('volume')"
        :date="date"
        :predicted-kpis="predictedKpisData('volume')"
        :current-shop="currentShop"
        :label="kpiVolumeName"
        :icon-label-tooltip="kpisLabels['volume'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="volume"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <KpiRow
        v-show="canShowKpisFor('average_ticket')"
        :predicted-kpis="predictedKpisData('average_ticket')"
        :current-shop="currentShop"
        :label="kpisLabels['average_ticket'].name"
        :icon-label-tooltip="kpisLabels['average_ticket'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="average_ticket"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <KpiRow
        v-show="canShowKpisFor('volume_productivity')"
        :predicted-kpis="predictedKpisData('volume_productivity')"
        :current-shop="currentShop"
        :label="kpisLabels['volume_productivity'].name"
        :icon-label-tooltip="kpisLabels['volume_productivity'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="volume_productivity"
        unit="/h"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <KpiRow
        v-show="canShowKpisFor('total_salary_mass', 'salary_mass')"
        :predicted-kpis="predictedKpisData('total_salary_mass')"
        :current-shop="currentShop"
        :label="kpisLabels['total_salary_mass'].name"
        :icon-label-tooltip="kpisLabels['total_salary_mass'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="total_salary_mass"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <KpiRow
        v-show="canShowKpisFor('total_salary_mass_with_costs', 'salary_mass')"
        :predicted-kpis="predictedKpisData('total_salary_mass_with_costs')"
        :current-shop="currentShop"
        :label="kpisLabels['total_salary_mass_with_costs'].name"
        :icon-label-tooltip="kpisLabels['total_salary_mass_with_costs'].tooltip"
        :label-collapsable="canDisplaySalaryMassToggle"
        :collapse-state="isSalaryMassCollapseOpen"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="total_salary_mass_with_costs"
        :unit="currentShop.attributes.currencySymbol"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <div :class="collapseWrapperClasses">
        <KpiRow
          v-show="canShowKpisFor('salary_mass_productive_with_costs', 'salary_mass')"
          key="salary_mass_productive_with_costs"
          :predicted-kpis="predictedKpisData('productive_with_costs')"
          :current-shop="currentShop"
          :label="kpisLabels['salary_mass_productive_with_costs'].name"
          :icon-label-tooltip="kpisLabels['salary_mass_productive_with_costs'].tooltip"
          :kpis-pack-offer-attributes="kpisPackOfferAttributes"
          kpi-name="salary_mass_productive_with_costs"
          :unit="currentShop.attributes.currencySymbol"
          label-icon="CircledQuestionMarkIcon"
          predicted
        />
        <KpiRow
          v-show="canShowKpisFor('salary_mass_unproductive_with_costs', 'salary_mass')"
          key="salary_mass_unproductive_with_costs"
          :predicted-kpis="predictedKpisData('unproductive_with_costs')"
          :current-shop="currentShop"
          :label="kpisLabels['salary_mass_unproductive_with_costs'].name"
          :icon-label-tooltip="kpisLabels['salary_mass_unproductive_with_costs'].tooltip"
          :kpis-pack-offer-attributes="kpisPackOfferAttributes"
          kpi-name="salary_mass_unproductive_with_costs"
          :unit="currentShop.attributes.currencySymbol"
          label-icon="CircledQuestionMarkIcon"
          predicted
        />
      </div>
      <KpiRow
        v-show="canShowKpisFor('salary_mass_ratio')"
        :predicted-kpis="predictedKpisData('salary_mass_ratio')"
        :current-shop="currentShop"
        :label="kpisLabels['salary_mass_ratio'].name"
        :icon-label-tooltip="kpisLabels['salary_mass_ratio'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="salary_mass_ratio"
        unit="%"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
      <KpiRow
        v-show="canShowKpisFor('productivity')"
        :predicted-kpis="predictedKpisData('productivity')"
        :current-shop="currentShop"
        :label="kpisLabels['productivity'].name"
        :icon-label-tooltip="kpisLabels['productivity'].tooltip"
        :kpis-pack-offer-attributes="kpisPackOfferAttributes"
        kpi-name="productivity"
        :unit="`${currentShop.attributes.currencySymbol}/h`"
        label-icon="CircledQuestionMarkIcon"
        predicted
      />
    </div>
    <KpiRow
      :predicted-kpis="predictedKpisData('worked_hours')"
      :current-shop="currentShop"
      :number-of-employees="numberOfEmployees"
      :date="date"
      :label="kpisLabels['planned_hours'].name"
      :icon-label-tooltip="kpisLabels['planned_hours'].tooltip"
      :kpis-pack-offer-attributes="kpisPackOfferAttributes"
      kpi-name="worked_hours"
      unit="h"
      label-icon="CircledQuestionMarkIcon"
      predicted
      last-row
    />
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { mapState } from 'vuex';
import DatesRow from './shared/components/DatesRow';
import KpiRow from './shared/components/KpiRow';

export default {
  name: 'PredictedTab',
  // eslint-disable-next-line vue/no-unused-components
  components: { KpiRow, DatesRow },
  props: {
    currentShop: {
      type: Object,
      required: true,
    },
    kpisLabels: {
      type: Object,
      required: true,
    },
    predictedKpis: {
      type: Object,
      required: true,
    },
    userKpisSettings: {
      type: Object,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
    kpisLoading: {
      type: Boolean,
      required: true,
    },
    kpisPackOfferAttributes: {
      type: Object,
      required: true,
    },
    kpiVolumeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      simplebar: null,
    };
  },
  computed: {
    ...mapState('planningsKpis', ['isSalaryMassCollapseOpen', 'numberOfEmployees']),

    tableMainContentClasses() {
      return {
        'kpis__table-main-content': true,
        'kpis__table-main-content--loading': this.kpisLoading,
      };
    },
    canDisplaySalaryMassToggle() {
      return this.userKpisSettings.totalSalaryMassWithCosts &&
             (this.userKpisSettings.salaryMassProductiveWithCosts ||
              this.userKpisSettings.salaryMassUnproductiveWithCosts);
    },
    collapseWrapperClasses() {
      return {
        'kpis__salary-mass-collapse__wrapper': true,
        'salary-mass-collapse__show ': this.isSalaryMassCollapseOpen,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.simplebar = new SimpleBar(this.$refs.predictedTabContent);
    });
  },
  methods: {
    predictedKpisData(kpiName) {
      if (!this.predictedKpis) return null;

      if (kpiName === 'salary_mass_ratio') {
        return this.predictedKpis.salary_mass_ratio ?
          this.predictedKpis.salary_mass_ratio.salary_mass_ratio_with_costs :
          null;
      }

      if ([
        'total_salary_mass',
        'total_salary_mass_with_costs',
        'productive_with_costs',
        'unproductive_with_costs',
      ].includes(kpiName)) {
        return this.predictedKpis.salary_mass ?
          this.predictedKpis.salary_mass[kpiName] :
          null;
      }

      return this.predictedKpis[kpiName];
    },
    canShowKpisFor(kpiName, kpiDataKey) {
      const predictedData = kpiDataKey ?
        this.predictedKpis[kpiDataKey] :
        this.predictedKpis[kpiName];

      return predictedData && this.userKpisSettings[camelCase(kpiName)];
    },
  },
};
</script>

<style lang="scss" scoped>
.kpis__predicted-tab {
  width: 100%;
}

.kpis__table-main-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 271px;
  overflow-x: hidden;
}

.kpis__read-only__table {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $sk-blue;
  z-index: 1;
}

.kpis__table-main-content--loading {
  opacity: .5;
}

::v-deep .kpis__salary-mass-collapse__wrapper .kpi-label__wrapper {
  padding-left: 5px;
}

.kpis__salary-mass-collapse__wrapper {
  transition: opacity .5s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.kpis__salary-mass-collapse__wrapper.salary-mass-collapse__show {
  opacity: 1;
  height: auto;
}
</style>
