<template>
  <SkModalV2
    id="upload-workload-plan-modal"
    ref="uploadWorkloadPlanModal"
    :icon-options="{
      color: '#000000',
      backgroundColor: 'white',
      name: 'UploadIcon',
    }"
    :title="$t('workload_plan.modals.upload_model.title')"
    @show="clearCache"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <UploadFile
          ref="uploadWorkloadPlanFile"
          :is-file-added="isFileAdded"
          @on-file-change="onChange"
          @on-file-upload="onFileUpload"
        />
        <div
          v-if="errors.length > 0"
          class="upload-workload-plan-modal__errors__panel"
        >
          <div class="upload-workload-plan-modal__errors__icon">
            <CircledExclamationMarkIcon
              fill="#d03e50"
              width="23"
              height="23"
            />
          </div>
          <div class="upload-workload-plan-modal__errors__info-section">
            <p class="upload-workload-plan-modal__errors-summary">
              {{ summaryErrorsMessage }}
            </p>
            <ul>
              <li
                v-for="error in errors"
                :key="error"
              >
                {{ errorMessage(error) }}
              </li>
            </ul>
          </div>
        </div>
      </SkModalSectionV2>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="!isFileAdded || errors.length > 0"
        :loading="isUploadLoading"
        data-test="submit_workload_upload"
        @click="handleSubmit"
      >
        {{ $t('workload_plan.modals.upload_model.action.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { openAndReadWorkloadPlanFile }
  from '@skello-utils/workload_plan/workload_plan_excel_file_reader';
import { manageConflicts }
  from '@skello-utils/workload_plan/workload_plan_conflicts_manager';
import { MAX_WORKLOAD_PLAN_VALUE } from '@skello-utils/workload_plan/workload_plan_excel_validators';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import UploadFile from './UploadFile';

export default {
  name: 'UploadModal',
  components: { UploadFile },
  data() {
    return {
      isFileAdded: false,
      isUploadLoading: false,
      file: null,
      errors: [],
    };
  },
  computed: {
    ...mapState('planningsPostes', ['postes']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapGetters('planningsWorkloadPlans', [
      'localStorageSaveOpenPanelKey',
      'openingHourQuarters',
      'fetchParamsByDay',
    ]),
    ...mapGetters('planningsState', ['visibleDaysConfig']),
    summaryErrorsMessage() {
      return this.$tc('workload_plan.modals.upload_model.errors.summary',
        this.errors.length,
        { number_of_errors: this.errors.length },
      );
    },
  },
  methods: {
    ...mapActions('planningsWorkloadPlans', [
      'fetchWorkloadPlans',
      'upsertWorkloadPlans',
      'setDefaultSelectedPosteInLocalStorage',
    ]),
    ...mapMutations('planningsWorkloadPlans', [
      'setWorkloadPlanConflicts',
      'setWorkloadPlanToSave',
      'setPeriod',
    ]),

    handleSubmit() {
      if (this.isUploadLoading) return;

      this.isUploadLoading = true;

      const openAndReadParams = {
        file: this.file,
        postes: this.postes,
        openingHourQuarters: this.openingHourQuarters,
        visibleDays: this.visibleDaysConfig,
      };
      openAndReadWorkloadPlanFile(openAndReadParams)
        .then(result => {
          if (result.errors && result.errors.length > 0) {
            this.errors = [...result.errors];
            if (result.periodStartsAt && result.periodEndsAt) {
              // TODO DEV-15587 display period in the modal before the errors
            }
            this.isUploadLoading = false;
          } else {
            this.$skAnalytics.track('added_workload_plan', { source: 'templates' });
            this.manageConflictsAndSave(result);
          }
        })
        .catch(this.handleError);
    },
    manageConflictsAndSave(loadingResult) {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;
      const formattedOpeningTime = openingTime.format('HH:mm');
      const formattedClosingTime = closingTime.format('HH:mm');

      const startPeriod = skDate.utc(loadingResult.periodStartsAt, 'DD/MM/YYYY').format();
      const endPeriod = skDate.utc(loadingResult.periodEndsAt, 'DD/MM/YYYY').format();

      const fetchWorkloadPlanParams = this.generateMultiDaysWorkloadPlanFetchParams(
        startPeriod,
        endPeriod,
        formattedOpeningTime,
        formattedClosingTime,
      );

      this.fetchWorkloadPlans({ ...fetchWorkloadPlanParams, saveToStore: false })
        .then(fetchedWorkloadPlans => manageConflicts(
          fetchedWorkloadPlans,
          loadingResult.workloadPlans,
          formattedOpeningTime,
          formattedClosingTime,
        ),
        )
        .then(({ workloadPlansToSave, conflicts }) => {
          if (Object.keys(conflicts).length === 0) {
            return this.saveWorkloadPlans(workloadPlansToSave, startPeriod);
          }

          this.setPeriod({
            start: startPeriod,
            end: endPeriod,
          });
          this.setWorkloadPlanConflicts(conflicts);
          this.setWorkloadPlanToSave(workloadPlansToSave);
          this.redirectToConflictsModal();
          return Promise.resolve();
        })
        .then(this.resetButton);
    },
    saveWorkloadPlans(workloadPlans, startPeriod) {
      const date = skDate(startPeriod).format('YYYY-MM-DD');

      if (Object.keys(workloadPlans).length === 0) {
        this.displaySuccessToast();
        this.redirectToDate(date);
        return Promise.resolve();
      }

      return this.upsertWorkloadPlans(workloadPlans)
        .then(() => {
          this.displaySuccessToast();
          this.setDefaultSelectedPosteInLocalStorage(workloadPlans);
          this.redirectToDate(date);
        })
        .catch(this.handleError);
    },
    generateMultiDaysWorkloadPlanFetchParams(
      startPeriod,
      endPeriod,
      formattedOpeningTime,
      formattedClosingTime,
    ) {
      const startRequestOpeningAndClosing =
        openingAndClosingTimeAt(formattedOpeningTime, formattedClosingTime, startPeriod);
      const endRequestOpeningAndClosing =
        openingAndClosingTimeAt(formattedOpeningTime, formattedClosingTime, endPeriod);

      return {
        closingHoursTime: formattedClosingTime,
        endsAt: endRequestOpeningAndClosing.closingTime,
        openingHoursTime: formattedOpeningTime,
        posteIds: this.postes.map(poste => poste.id),
        shopId: this.currentShop.id,
        startsAt: startRequestOpeningAndClosing.openingTime,
      };
    },
    clearCache() {
      this.errors = [];

      this.isUploadLoading = false;

      if (this.isFileAdded) {
        this.cleanDropZoneQueue();
        this.isFileAdded = false;
      }
    },
    handleError() {
      this.$skToast({
        message: this.$t('workload_plan.modals.errors.generic'),
        variant: 'error',
      });

      this.resetButton();
    },
    cleanDropZoneQueue() {
      this.$refs.uploadWorkloadPlanFile.$refs.uploadFile.removeAllFiles();
      this.errors = [];
      this.isFileAdded = false;
    },
    resetButton() {
      this.isUploadLoading = false;
      this.$refs.uploadWorkloadPlanModal.hide();
    },
    onFileUpload(file) {
      this.file = file;
    },
    onChange(isFileAdded) {
      this.isFileAdded = isFileAdded;
      if (!isFileAdded) this.cleanDropZoneQueue();
    },
    displaySuccessToast() {
      this.$skToast({
        message: this.$t('workload_plan.modals.upload_model.success'),
        variant: 'success',
      });
    },
    redirectToDate(date) {
      /* We want the workload panel open after redirection */
      this.setPanelPreferenceToOpen();

      const currentQuery = this.$router.currentRoute.query;

      // Force reload of the page in case the date has not changed
      if (currentQuery.date === date) {
        const posteIds = this.postes.map(poste => poste.id);
        this.fetchWorkloadPlans(
          this.fetchParamsByDay(date, posteIds),
        );
      } else {
        this.$router.replace({ query: { date } });
      }
    },
    setPanelPreferenceToOpen() {
      localStorage.setItem(this.localStorageSaveOpenPanelKey, true);
      this.emitOnRoot('workload-plan-panel-updated');
    },
    /**
     * Parse error and return right translation.
     * error is either on a direct format ('invalid_postes', 'invalid_structure', ...)
     * Or on a format with the error name + variables like : 'invalid_workload_plans#not_positive_number#B12'
    */
    errorMessage(error) {
      if (['invalid_postes', 'invalid_structure', 'invalid_opening_hours'].includes(error)) {
        return this.$t(`workload_plan.modals.upload_model.errors.${error}`);
      }
      const [_errorType, errorKind, coordinate] = error.split('#');

      return this.$t(`workload_plan.modals.upload_model.errors.${errorKind}`, {
        cell_coordinate: coordinate,
        max_value: MAX_WORKLOAD_PLAN_VALUE,
      });
    },
    redirectToConflictsModal() {
      this.clearCache();
      this.$emit('redirect-to-conflicts');
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-workload-plan-modal {
  &__errors {
    &-summary {
      font-weight: 700;
      margin-bottom: 1px;
    }

    &__panel {
      display: flex;
      padding: 16px 16px 0;
      margin: 24px 0 -6px;
      color: $sk-error;
      background-color: $sk-error-10;
      border-radius: 4px;
    }

    &__icon {
      margin-right: 1rem;
    }

    &__info-section {
      text-align: left;
      word-break: break-word;

      >ul {
        list-style: disc;
        padding: 0 0 0 17px;
      }
    }
  }
}
</style>
