<template>
  <div
    v-if="isOnFinishedStep"
    :class="shiftWrapperClasses"
  >
    <lottie-player
      class="finish-animation"
      background="transparent"
      autoplay="true"
      :src="lottieLogo"
    />
    <div class="shift-step__text sk-display-small-semibold">
      {{ $t('onboarding_modal.loading.finished.first_line') }}<br>
      {{ $t('onboarding_modal.loading.finished.second_line') }}
    </div>
  </div>
  <div
    v-else
    :class="shiftWrapperClasses"
  >
    <SkLoader
      :custom-size="72"
      is-dark-mode
    />
    <div class="shift-step__text sk-display-small-semibold">
      {{ textToDisplay }}
    </div>
  </div>
</template>

<script>
import FinishLottieJson from '@app-js/timeclock_onboarding/assets/finish_lottie.json';
import '@lottiefiles/lottie-player';

const STEP_WAITING_TIME = 4000;

export default {
  name: 'LoadingStep',
  props: {
    isLoadingDone: {
      type: Boolean,
      default: false,
    },
    displaysEmployeesStep: {
      type: Boolean,
      default: true,
    },
    displaysPositionsStep: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOnFinishedStep: false,
      textToDisplay: '',
      backgroundStepNumber: 1,
    };
  },
  computed: {
    shiftWrapperClasses() {
      return {
        'loading-step__wrapper': true,
        [`loading-step__wrapper--background-${this.backgroundStepNumber}`]: true,
      };
    },
    lottieLogo() {
      return JSON.stringify(FinishLottieJson);
    },
  },
  mounted() {
    this.startLoop1();
  },
  methods: {
    startLoop1() {
      if (this.displaysPositionsStep) {
        this.displayPositionText();
      } else if (this.displaysEmployeesStep) {
        this.displayEmployeeText();
      } else {
        this.displayPlanningText();
      }
    },
    startLoop2() {
      this.displayShiftText();
    },
    displayPositionText() {
      this.backgroundStepNumber = 1;
      this.textToDisplay = this.$t('onboarding_modal.loading.position');

      if (this.displaysEmployeesStep) {
        setTimeout(this.displayEmployeeText, STEP_WAITING_TIME);
      } else {
        setTimeout(this.displayPlanningText, STEP_WAITING_TIME);
      }
    },
    displayEmployeeText() {
      this.backgroundStepNumber = 2;
      this.textToDisplay = this.$t('onboarding_modal.loading.employee');

      setTimeout(this.displayPlanningText, STEP_WAITING_TIME);
    },
    displayPlanningText() {
      this.backgroundStepNumber = 3;
      this.textToDisplay = this.$t('onboarding_modal.loading.planning');

      if (this.isLoadingDone) {
        setTimeout(this.displayFinishedText, STEP_WAITING_TIME);
      } else {
        setTimeout(this.startLoop2, STEP_WAITING_TIME);
      }
    },
    displayShiftText() {
      this.backgroundStepNumber = 1;
      this.textToDisplay = this.$t('onboarding_modal.loading.shift');

      setTimeout(this.displayConfigurationText, STEP_WAITING_TIME);
    },
    displayConfigurationText() {
      this.backgroundStepNumber = 2;
      this.textToDisplay = this.$t('onboarding_modal.loading.configuration');

      setTimeout(this.displayAssignmentText, STEP_WAITING_TIME);
    },
    displayAssignmentText() {
      this.backgroundStepNumber = 3;
      this.textToDisplay = this.$t('onboarding_modal.loading.assignment');

      if (this.isLoadingDone) {
        setTimeout(this.displayFinishedText, STEP_WAITING_TIME);
      } else {
        setTimeout(this.startLoop1, STEP_WAITING_TIME);
      }
    },
    displayFinishedText() {
      this.backgroundStepNumber = 4;
      this.isOnFinishedStep = true;

      setTimeout(this.redirectToPlanning, STEP_WAITING_TIME);
    },
    redirectToPlanning() {
      this.$emit('redirect-to-planning');
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin background-variant($url) {
  background: $url no-repeat fixed;
  background-size: cover;
}

.loading-step__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &--background-1 {
    @include background-variant(
      url('../../shared/assets/png/orora_gradient_1.png')
    );
  }
  &--background-2 {
    @include background-variant(
      url('../../shared/assets/png/orora_gradient_2.png')
    );
  }
  &--background-3 {
    @include background-variant(
      url('../../shared/assets/png/orora_gradient_3.png')
    );
  }
  &--background-4 {
    @include background-variant(
      url('../../shared/assets/png/orora_gradient_4.png')
    );
  }
}

.shift-step__text {
  color: $sk-white;
  max-width: 700px;
  text-align: center;
}

.finish-animation {
  width: 72px;
  height: 72px;
}
</style>
