<template>
  <SkModalSection border-bottom="none">
    <SkCard class="holidays-settings-modal__guaranteed-section__card">
      <div class="holidays-settings-modal__guaranteed-section__card-icon">
        <CircledIIcon
          fill=""
          width="24"
          height="24"
        />
      </div>
      <div class="holidays-settings-modal__guaranteed-section__card-description">
        <!-- eslint-disable-next-line max-len -->
        {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.card_description') }}
        <br>
        <div>
          <SkOroraLink
            v-track="'viewed_details_public_holidays_settings'"
            size="small"
            icon="ExternalLinkV2Icon"
            :href="$t('shop_settings.tabs.rules.counter_rules.holidays_counter.article_link')"
            class="holidays-settings-modal__guaranteed-section__card-link"
            target="_blank"
          >
            <!-- eslint-disable-next-line max-len -->
            {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.card_link') }}
          </SkOroraLink>
        </div>
      </div>
      <span class="holidays-settings-modal__guaranteed-section__card-logo">
        <HolidayIcon
          width="80"
          height="90"
        />
      </span>
    </SkCard>
    <div class="holidays-settings-modal__guaranteed-section__content">
      <!-- eslint-disable-next-line max-len -->
      {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.choose_guaranteed_holidays') }}
      <SkTable
        :columns="holidaysColumns"
        class="holidays-settings-modal__guaranteed-section__default-table"
      >
        <HolidayRow
          v-for="holidaySettings in regularHolidaysSettings"
          :key="holidaySettings.id"
          :holiday-settings="holidaySettings"
          :editable-fields="['guaranteed']"
          @update-holiday-settings="updateHolidaySettings"
        />
      </SkTable>
      <div class="custom-holidays-title">
        <!-- eslint-disable-next-line max-len -->
        <span>{{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.choose_custom_guaranteed_holidays') }}</span>
        <NewTag />
      </div>

      <SkTable
        :columns="holidaysColumns"
        class="holidays-settings-modal__guaranteed-section__custom-table"
      >
        <HolidayRow
          v-for="holidaySettings in customHolidaysSettings"
          :key="holidaySettings.id"
          :holiday-settings="holidaySettings"
          :can-delete="!isPastYear"
          :editable-fields="['guaranteed', 'name']"
          @update-holiday-settings="updateHolidaySettings"
          @remove-holiday-settings="removeHolidaySettings"
        />
      </SkTable>
      <div
        v-if="!isPastYear"
        class="holidays-settings-modal__guaranteed-section__button-container"
      >
        <SkDatePicker
          v-model="newHolidayDate"
          input-moment-format="YYYY-MM-DD"
          :clearable="false"
          hide-week-number
          :lang="$i18n.locale"
          :disabled-date="disabledDate"
          no-icon
        >
          <template #input>
            <SkOroraButton
              variant="tertiary"
            >
              <!-- eslint-disable-next-line max-len -->
              + {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.add_custom_day_button') }}
            </SkOroraButton>
          </template>
        </SkDatePicker>
      </div>
      <div :class="contentFooterClass">
        <div class="holidays-settings-modal__guaranteed-section__content-footer-header">
          {{ $t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.total') }}
        </div>
        <!-- eslint-disable max-len -->
        <div>
          <strong>
            {{ $tc('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.day_selected_start', nbHolidaysGuaranteed) }}
          </strong>
          {{ $tc('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.day_selected_end', nbHolidaysGuaranteed) }}
        </div>
        <!-- eslint-enable max-len -->
      </div>
    </div>
  </SkModalSection>
</template>

<script>
import { mapState } from 'vuex';
import skDate from '@skello-utils/dates';
import NewTag from '@app-js/shared/components/NewTag';
import HolidayIcon from '../../../../shared/svg/HolidayIcon';
import HolidayRow from '../components/HolidayRow';

export default {
  name: 'GuaranteedDaysStep',
  components: { HolidayIcon, HolidayRow, NewTag },
  props: {
    holidaysSettings: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      required: true,
    },
    isPastYear: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      holidaysColumns: [
        {
          title:
            this.$t(
              'shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.dates',
              { year: this.year },
            ),
        },
        { title: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.holidays') },
        { title: this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.guaranteed') },
        { title: '' },
      ],
      newHolidayDate: null,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    holidaysGuaranteed() {
      return this.holidaysSettings.filter(
        holidaySettings => holidaySettings.attributes.guaranteed,
      );
    },
    regularHolidaysSettings() {
      return this.holidaysSettings.filter(holiday => !holiday.attributes.custom);
    },
    customHolidaysSettings() {
      return this.holidaysSettings.filter(holiday => holiday.attributes.custom);
    },
    nbHolidaysGuaranteed() {
      return this.holidaysGuaranteed.length;
    },
    oneHolidayGuaranteed() {
      return this.nbHolidaysGuaranteed === 1;
    },
    selectedDates() {
      return this.holidaysSettings.map(
        holidaySetting => holidaySetting.attributes.date,
      );
    },
    contentFooterClass() {
      return {
        'holidays-settings-modal__guaranteed-section__content__footer': true,
        'holidays-settings-modal__guaranteed-section__content__footer--past-year': this.isPastYear,
      };
    },
  },
  watch: {
    newHolidayDate(newValue) {
      this.addCustomHoliday(
        newValue,
        this.$t('shop_settings.tabs.rules.counter_rules.holidays_counter.modal.first_step.add_custom_holiday_text'));
    },
  },
  methods: {
    updateHolidaySettings({ id, attribute, value }) {
      this.$emit('update-holiday-settings', { id, attribute, value });
    },
    addCustomHoliday(date, name = '') {
      this.$emit('add-holiday-settings', { date, name, custom: true });
    },
    removeHolidaySettings(holidaySettingId) {
      this.$emit('remove-holiday-settings', holidaySettingId);
    },
    alreadySelectedDate(date) {
      return this.selectedDates.includes(this.formatDate(date));
    },
    isFromCurrentYear(date) {
      return date.getFullYear() === new Date().getFullYear();
    },
    disabledDate(date) {
      return this.alreadySelectedDate(date) || !this.isFromCurrentYear(date);
    },
    formatDate(date) {
      return skDate(date).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.holidays-settings-modal__guaranteed-section__default-table {
  margin-bottom: 40px;
}

.holidays-settings-modal__guaranteed-section__custom-table {
  margin-bottom: 10px;
}

.holidays-settings-modal__guaranteed-section__card {
  padding: 20px 27px 0 20px;
  height: 140px;
  display: flex;
}

.holidays-settings-modal__guaranteed-section__button-container {
  display: flex;
  justify-content: flex-end;
}

.holidays-settings-modal__guaranteed-section__card-logo {
  margin-top: 10px;
}

.holidays-settings-modal__guaranteed-section__card-icon {
  fill: $sk-blue;
}

.holidays-settings-modal__guaranteed-section__card-link {
  display: flex;
  align-items: center;
  margin-top: 4px;

  &--icon {
    margin-left: 5px;
  }
}

.holidays-settings-modal__guaranteed-section__content {
  margin-top: 30px;

  &--table {
    margin-top: 10px;
  }
}

.holidays-settings-modal__guaranteed-section__content-footer-header {
  color: $sk-grey;
  font-size: $fs-text-s;
}

// Overwrite skTable to force first column to be padded
::v-deep th:first-child {
  .table-column__label {
    padding-left: 5px;
  }
}

// Overwrite skTable to force last column to be on right side
::v-deep th:last-child {
  .table-column__label {
    text-align: right;
    padding-right: 5px;
    display: block;
  }
}

.holidays-settings-modal__guaranteed-section__card-description {
  padding-left: 21px;
  padding-bottom: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 590px;
  line-height: 22px;
  text-align: left;
  color: $sk-grey;
}

.custom-holidays-title {
  display: flex;

  span {
    margin-right: 1em;
  }
}

.sk-new-tag {
  display: flex;
  align-items: center;
}

.holidays-settings-modal__guaranteed-section__content__footer--past-year {
  margin-top: 12px;
}
</style>
