<template>
  <div class="contracts-table__wrapper">
    <SkTable
      :columns="headers"
      :loading="loadingContracts"
      :is-empty="isEmpty || shouldShowUpsell"
      :disabled-arrow="!isMulticontractsEnabled"
      filled-arrow
      @sort="handleSort"
    >
      <template #empty-state>
        <div
          v-if="isMulticontractsEnabled"
          class="contracts-table__empty-state"
        >
          {{ $t(`employees.tabs.contracts.table.${$router.currentRoute.name}.empty_state`) }}
        </div>
        <div v-else>
          <div class="contracts-table__pack-offer-overlay__wrapper">
            <div class="contracts-table__pack-offer-overlay__text">
              {{ $t('employees.tabs.contracts.table.pack_offer.contracts_not_allowed.before_tag') }}
              <PackTag :type="packType" />
              {{ $t('employees.tabs.contracts.table.pack_offer.contracts_not_allowed.after_tag') }}
            </div>

            <div class="contracts-table__pack-offer-overlay__button">
              <SkOroraButton
                size="small"
                @click="requestUpsell"
              >
                {{ $t('employees.tabs.contracts.table.pack_offer.contracts_not_allowed.button') }}
              </SkOroraButton>
            </div>
          </div>
        </div>
      </template>
      <ContractRow
        v-for="contract in displayedContracts"
        :key="contract.id"
        :contract="contract"
        @delete-contract="handleDelete"
      />
    </SkTable>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
import PackTag from '@app-js/shared/components/PackTag';

import ContractRow from './ContractRow';

export default {
  name: 'ContractsTable',
  components: { ContractRow, PackTag },
  computed: {
    ...mapGetters('currentOrganisation', ['isMulticontractsEnabled']),
    ...mapState('currentOrganisation', ['currentOrganisation', 'upsellRequested']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['loadingContracts', 'contract', 'contracts', 'sort']),
    headers() {
      return [
        {
          title: this.$t('employees.tabs.contracts.table.headers.contract_type'),
          name: 'contractType',
          sort: true,
          class: 'contracts-table-header-sort',
        },
        {
          title: this.$t('employees.tabs.contracts.table.headers.created_at'),
          name: 'createdAt',
          sort: true,
          class: 'contracts-table-header-sort',
          defaultSort: 'asc',
        },
        {
          title: this.$t('employees.tabs.contracts.table.headers.created_by'),
        },
        {
          title: this.$t('employees.tabs.contracts.table.headers.contract_counter'),
        },
        {
          title: this.$t('employees.tabs.contracts.table.headers.start_and_end_dates'),
          name: 'startDate',
          sort: true,
          class: 'contracts-table-header-sort',
        },
        {
          title: null, // empty column title to handle kebab icon
        },
      ];
    },
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    // When downselling we virtually elect a contract
    // to be displayed as the current Contract (archived: false)
    // We have to remove it from the displayed contracts in the <ContractsTable>
    displayedContracts() {
      return (!this.isMulticontractsEnabled) ?
        this.contracts.filter(contract => contract.id !== this.contract.id) :
        this.contracts;
    },
    isEmpty() {
      return this.contracts.length === 0 || this.displayedContracts.length === 0;
    },
    shouldShowUpsell() {
      return this.$router.currentRoute.name === 'user_contracts_future' && !this.isMulticontractsEnabled;
    },
  },
  created() {
    this.handleSorting({ sortField: this.sort.sortField, direction: 'asc' });

    // We need to use the user_id from the route
    // To avoid problems when fast switching between employees
    this.fetchContracts({
      employeeId: this.$router.currentRoute.params.user_id,
      filter: this.$router.currentRoute.name,
      sortField: this.sort.sortField,
      direction: this.sort.direction,
    });
  },
  methods: {
    ...mapActions('contracts', ['fetchContracts', 'deleteContract']),
    ...mapMutations('contracts', ['handleSorting']),
    ...mapActions('currentOrganisation', ['requestPackUpsell']),

    handleSort(event, columnName, direction) {
      this.$skAnalytics.track(`multicontract_sorted_${columnName.replace(/[A-Z]/g, name => `_${name.toLowerCase()}`)}`);
      this.handleSorting({ sortField: columnName, direction });
      this.fetchContracts({
        employeeId: this.employee.id,
        filter: this.$router.currentRoute.name,
        sortField: columnName,
        direction,
      });
    },
    handleDelete(contractId, event) {
      this.$root.$emit('confirm', event, {
        title: this.$t('employees.tabs.contracts.actions.delete_contract.label'),
        description: this.$t('employees.tabs.contracts.actions.delete_contract.description'),
        actionText: this.$t('employees.tabs.contracts.actions.delete_contract.action_text'),
        submitLabel: this.$t('employees.tabs.contracts.actions.delete_contract.label'),
        submitIcon: 'TrashCanIcon',
        variant: 'danger',
        icon: 'TrashCanIcon',
        iconColorSchema: { fill: '#d03e50', background: '#fce8e7' },
        // eslint-disable-next-line arrow-body-style
        onConfirmSuccess: () => {
          this.$skAnalytics.track('multicontract_confirm_delete_contract');
          return this.deleteContract({ employeeId: this.employee.id, contractId })
            .then(() => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.actions.delete_contract.success'),
                variant: 'success',
              });
            })
            .catch(() => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.actions.delete_contract.error'),
                variant: 'error',
              });
            });
        },
      });
    },
    requestUpsell() {
      if (this.upsellRequested) {
        this.$skToast({
          message: this.$t('employees.tabs.contracts.pack_offers.upsell_already_requested'),
          variant: 'success',
          containerId: 'employees__container',
        });
        return;
      }

      const params = {
        buttonClicked: this.$t('employees.tabs.contracts.pack_offers.table_contracts'),
        currentPage: this.$t('employees.tabs.contracts.pack_offers.tab_name'),
        upsellType: this.$t('employees.tabs.contracts.pack_offers.action'),
        action: this.$t('employees.tabs.contracts.pack_offers.action'),
      };

      this.requestPackUpsell(params)
        .then(() => {
          this.$skToast({
            message: this.$t('employees.tabs.contracts.pack_offers.success'),
            variant: 'success',
            containerId: 'employees__container',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contracts-table__wrapper {
  ::v-deep th:first-child {
    padding-left: 10px;
    min-width: 230px;
  }

  ::v-deep th {
    width: 15%;

    ::v-deep .table-column__label--sortable {
      margin-left: 0;
    }
  }

  ::v-deep th:nth-child(4) {
    padding-right: 25px;

    .table-column__label {
      max-width: 100px;
    }
  }

  .contracts-table__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fs-text-m;
    margin-top: 40px;
  }

  .contracts-table__pack-offer-overlay__wrapper {
    margin-top: 48px;

    .contracts-table__pack-offer-overlay__text {
      text-align: center;
      line-height: 22px;
      max-width: 350px;
      font-size: $fs-text-m;
      color: $sk-black;
    }

    .contracts-table__pack-offer-overlay__button {
      margin-top: 25px;
      text-align: center;
    }
  }

  ::v-deep .sk-table__empty__row .sk-table__empty__wrapper {
    // skTable souldn't have a fixed height
    height: auto;
  }
}
</style>
