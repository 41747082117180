import i18n from '@plugins/i18n';

/**
 * Safely extract error message string in Skello API response
 */
export const getErrorMessage = (error, fallbackMessage = i18n.t('errors.standard_message')) => {
  if (error && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }
  return fallbackMessage;
};
