<template>
  <!-- eslint-disable  max-len -->
  <div
    id="unfinished-profiles"
    class="dashboard-section"
  >
    <div class="dashboard-title">
      <h2>
        {{ $t('home_dashboard.unfinished_profiles.title') }}
      </h2>
    </div>
    <div class="dashboard-content">
      <div class="flex-between unfinished-profile-content-graph">
        <div class="unfinished-profiles-wrapper">
          <canvas
            id="unfinished-profiles-chart"
            height="120"
          />
          <div id="unfinished-profiles-percentage">
            <span>
              {{ percentUnfinishedProfiles }}%
            </span>
          </div>
        </div>
        <div class="unfinished-profiles-text">
          <div class="unfinished-profiles-number">
            {{ unfinishedLength }}
            {{ $t('home_dashboard.unfinished_profiles.unfinished_profiles') }}
          </div>
          <p class="unfinished-profiles-explanation">
            {{ $t('home_dashboard.unfinished_profiles.unfinished_explanation') }}
          </p>
        </div>
      </div>
      <div
        v-for="(unfinishedProfile, index) in unfinishedProfiles"
        :key="index"
        class="unfinished-profile-item flex-between"
      >
        <a
          :href="unfinishedProfile.link"
          target="blank"
          class="flex-grow flex-start"
          data-seg="DboardCompleteFromAny"
        >
          <div class="unfinished-profile monogram-wrapper">
            <div class="user-monogram sidebar">
              {{ unfinishedProfile.monogram }}
            </div>
          </div>
          <div class="unfinished-profile-name">
            {{ unfinishedProfile.name }}
          </div>
        </a>
        <SkOroraButton
          variant="tertiary"
          size="medium"
        >
          <a
            :href="unfinishedProfile.link"
            class="unfinished-profile-complete"
            target="blank"
            data-seg="DboardCompleteFromBtn"
          >
            {{ $t('home_dashboard.unfinished_profiles.complete') }}
          </a>
        </SkOroraButton>
      </div>
      <div
        v-if="unfinishedProfiles.length === 0"
        class="unfinished-profiles-done unfinished-profile-item flex-center"
      >
        {{ $t('home_dashboard.unfinished_profiles.all_finished') }}
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import { mapState } from 'vuex';

export default {
  name: 'UnfinishedProfilesChart',
  data() {
    return {
      visible: true,
      percentUnfinishedProfiles: null,
      unfinishedLength: 0,
      unfinishedProfiles: [],
      unfinished_profiles_path: '/unfinished_profiles',
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    isSingleShop() {
      return this.currentShop.id !== 'all';
    },
  },
  created() {
    this.listenOnRoot('dashboard-v3-reload-data', this.initialLoad);
  },

  methods: {
    initialLoad() {
      this.replaceCanvas('next');
    },
    getLastMonth() {
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      return lastMonth;
    },
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'UnfinishedProfilesChart');
    },
    replaceCanvas(statut) {
      const params = {
        statut,
      };

      if (this.isSingleShop) params.id = this.currentShop.id;

      return httpClient.get(this.unfinished_profiles_path, { params })
        .then(response => {
          const data = response.data;
          this.percentUnfinishedProfiles = data.percentUnfinishedProfiles;
          this.unfinishedLength = data.unfinishedLength;
          this.unfinishedProfiles = data.unfinishedProfiles;
          this.managedMinusUnfinishedLength = data.managedMinusUnfinishedLength;

          this.drawProfilChart(true);
        }).catch(_error => {
          this.unauthorized = true; // 401, 403 or random error
        }).finally(() => {
          this.handleReloadDone();
        });
    },
    drawProfilChart(animate) {
      const app = this;

      const donutWrapper = document.getElementById('unfinished-profiles-chart');
      const myDoughnutChart = new Chart(donutWrapper, {
        type: 'doughnut',
        data: {
          labels: [
            this.$t('home_dashboard.chart.profiles.finished_profiles.label'),
            this.$t('home_dashboard.chart.profiles.unfinished_profiles.label'),
          ],
          datasets: [
            {
              data: [app.managedMinusUnfinishedLength, app.unfinishedLength],
              backgroundColor: ['rgb(31,190,213)', '#eee'],
              hoverBackgroundColor: ['rgb(31,190,213)', '#eee'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          animation: {
            animateRotate: animate,
            animateScale: false,
          },
          cutoutPercentage: 80,
        },
      });
    },
  },
};
</script>
