import { httpClient } from '@skello-utils/clients';
import ContractTypeKeys from '@app-js/shared/constants/contract_type_keys';
import { setSvcUsersFeatureFlags } from '@skello-utils/feature-flags';

const PERMANENT_CONTRACT_TYPES_KEYS = [ContractTypeKeys.COMMON.PERMANENT];
const FIXED_TERM_CONTRACT_TYPE_KEYS = [
  ContractTypeKeys.COMMON.FIXED_TERM,
  ContractTypeKeys.FR.FIXED_TERM_SEASONAL,
];
const OPTIONAL_END_DATE_CONTRACT_TYPES_KEYS = [
  ContractTypeKeys.DE.MINIJOB,
  ContractTypeKeys.DE.MIDI,
  ContractTypeKeys.ES.PERMANENT_DISCONTINUOUS,
  ContractTypeKeys.ES.PERMANENT_DISCONTINUOUS_FUNCTIONAL_DIVERSITY,
  ContractTypeKeys.ES.INDEFINITE_TERM_FUNCTIONAL_DIVERSITY,
  ContractTypeKeys.GB.PERMANENT_GB,
  ContractTypeKeys.BE.PERMANENT,
  ContractTypeKeys.CH.PERMANENT,
];

export const CONTRACT_TYPE_CATEGORIES = { standard: 'standard', interim: 'interim', intern: 'intern' };
const initialState = {
  config: null,
  featureFlags: null,
  error: false,
  loading: false,
  shopCreationCurrentStep: 'shop',
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchConfigSuccess(state, payload) {
    state.config = payload;
  },

  setFeatureFlagSuccess(state, payload) {
    state.featureFlags = payload;
  },

  fetchConfigError(state, error) {
    state.error = error;
  },

  updateShopCreationCurrentStep(state, step) {
    state.shopCreationCurrentStep = step;
  },
};

const actions = {
  fetchConfig({ commit }) {
    commit('performingRequest');

    return httpClient
      .get('/v3/api/config')
      .then(response => {
        commit('fetchConfigSuccess', response.data.data);

        return response;
      })
      .catch(({ response }) => {
        commit('fetchConfigError', response.data);

        throw response;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
  async fetchFeatureFlags({ commit }) {
    commit('performingRequest');

    try {
      const response = await httpClient.get('/v3/api/feature_flags');
      commit('setFeatureFlagSuccess', response.data);
      setSvcUsersFeatureFlags(response.data);
      return response;
    } catch (err) {
      commit('fetchConfigError', err.data);
      throw err;
    } finally {
      commit('requestComplete');
    }
  },
};

export const getters = {
  allContractTypes: state => state.config.contract_data.contract_types,
  fixedTermContractType(state) {
    return state.config.contract_data.contract_types.filter(
      contractType => FIXED_TERM_CONTRACT_TYPE_KEYS.includes(contractType.key),
    );
  },
  permanentContractTypes(state) {
    return state.config.contract_data.contract_types.filter(
      contractType => PERMANENT_CONTRACT_TYPES_KEYS.includes(contractType.key),
    );
  },
  optionalEndDateContractTypes(state) {
    return state.config.contract_data.contract_types.filter(
      contractType => OPTIONAL_END_DATE_CONTRACT_TYPES_KEYS.includes(contractType.key),
    );
  },
  hourlyRateCounterType(state) {
    return state.config.contract_data.counter_type_standard;
  },
  dailyRateCounterType(state) {
    return state.config.contract_data.counter_type_daily;
  },
  countryConventions(state) {
    return (userCountryCode, currentConventionName) => {
      let countryCode = userCountryCode;
      // For DOM-TOMS -> return French CC
      if (state.config.dom_tom.includes(countryCode.toLowerCase())) {
        countryCode = 'FR';
      }

      const countryConventions = state.config.conventions_collection.filter(
        convention => convention.countries.includes(countryCode),
      );

      // If no conventions exist for country -> return all
      if (countryConventions.length === 0) return state.config.conventions_collection;

      // If current convention isn't in conventions array -> add it
      if (currentConventionName &&
          !countryConventions.some(convention => convention.name === currentConventionName)
      ) {
        countryConventions.unshift(state.config.conventions_collection.find(convention => (
          convention.name === currentConventionName
        )));
      }

      return countryConventions;
    };
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
