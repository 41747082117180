<template>
  <div
    class="survey-popover__content-section"
  >
    <div
      v-for="(option) in formOptions"
      :key="option.id"
      class="survey-popover__content-section__choice-container"
    >
      <SkRadio
        :value="selectedOption"
        :data-value="option.key"
        v-on="$listeners"
      >
        {{ option.label }}
      </Skradio>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleRecommendationDeletionSurvey',
  props: {
    formOptions: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-popover__content-section {
  padding-top: 8px;
  padding-bottom: 16px;
}

.survey-popover__content-section__choice-container {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.survey-popover__content-section__label-container {
  padding-left: 8px;
}
</style>
