<template>
  <div
    :style="maxWidthStyle"
    @mouseenter="instantiatePopper"
    @mouseleave="clearTooltip"
  >
    <MixedLineAndColumnChart
      :series="series"
      :chart-width="chartWidth"
      @data-event="updateTooltipData"
    />
    <!-- eslint-disable vue/no-v-html -->
    <!--
        We use v-show instead of v-if because we need the component in the DOM
        To get the element by Id at the initialisation.
      -->
    <div
      v-show="isPopperDisplayed"
      id="popper-workload-plan"
      class="popper-workload-plan__wrapper"
      v-html="tooltipContent"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import Vue from 'vue';
import {
  mapState,
  mapGetters,
} from 'vuex';
import skDate from '@skello-utils/dates';
import { createPopper } from '@popperjs/core';
import WorkloadPlanTooltip from '@app-js/shared/components/Tooltips/WorkloadPlanTooltip';
import MixedLineAndColumnChart from './MixedLineAndColumnChart';

// Remove 0.2 to every bar chart values so it displays under the line chart
const BAR_CHART_VALUE_PADDING = 0.2;
const NEED_CURVE_TRANSLATION_IN_SECONDS = 485; // Padding to apply to the left to adjust the blue curve to the bars
const WORKLOAD_PLAN_LINE_GRAPH_NAME = 'Workload plan';
const SHIFTED_EMPLOYEES_BARS_GRAPH_NAME = 'Shifted employees';

const CustomWorkloadPlanTooltip = Vue.extend(WorkloadPlanTooltip);

export default {
  name: 'WorkloadPlanChartPanel',
  components: {
    MixedLineAndColumnChart,
  },
  props: {
    posteId: {
      type: String,
      required: false,
      default: null,
    },
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    planningShiftsForAllPostes: {
      type: Array,
      required: false,
      default: () => [],
    },
    planningShiftsForSinglePoste: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      curves: [
        {
          name: WORKLOAD_PLAN_LINE_GRAPH_NAME,
          type: 'line',
          data: [],
        },
        {
          name: SHIFTED_EMPLOYEES_BARS_GRAPH_NAME,
          type: 'column',
          data: [],
        },
      ],
      tooltipContent: '',
      popper: null,
      // The tooltip is displayed dynamicaly following the cursor,
      // The virtualElement represent the cursor,
      // and will be the anchor of the tooltip
      virtualElement: null,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),
    ...mapState('planningsWorkloadPlans', ['workloadPlansByPostes']),
    ...mapGetters('planningsWorkloadPlans', [
      'workloadPlansByHourQuarters',
      'arrayOfOpeningQuarters',
      'workloadPlansByHourQuartersForPeriod',
      'workloadPlansByPostesForPeriod',
    ]),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),

    isPopperDisplayed() {
      return !!this.tooltipContent;
    },
    // We add some px to the chart width to account for the offset
    // added by apex on both sides of the chart
    pixelsMarginAddedByApex() {
      return 25 * this.dayViewPlanningSizeVariables.pixelPerMinute;
    },
    chartWidth() {
      return this.arrayOfOpeningQuarters.length *
        this.dayViewPlanningSizeVariables.pixelPerQuarterHours +
        this.pixelsMarginAddedByApex;
    },
    maxWidthStyle() {
      // crop the last px of the chart (empty spaces) to align with the planning
      // and avoid extra scrolling effect
      return {
        width: `${this.chartWidth - this.pixelsMarginAddedByApex}px`,
        'overflow-x': 'hidden',
        'overflow-y': 'hidden',
      };
    },
    series() {
      return this.curves.map(curve => {
        let data = [];
        if (curve.name === WORKLOAD_PLAN_LINE_GRAPH_NAME) {
          data = this.needsLine;
        } else {
          data = this.predictedShiftsBarsChart;
        }

        return { ...curve, data };
      });
    },
    predictedShiftsBarsChart() {
      if (!this.posteId) {
        return this.emptyChartValues();
      }

      if (this.posteId === 'all') {
        return this.computePredictedShiftsBarsChart(this.planningShiftsForAllPostes);
      }

      return this.computePredictedShiftsBarsChart(this.planningShiftsForSinglePoste);
    },
    needsLine() {
      if (this.posteId === 'all') return this.allPostesNeedsLine;

      // Don't show the line but keep the right chart width
      if (this.posteId === null || !this.workloadPlansByPostes[this.posteId]) {
        return this.emptyChartValues(NEED_CURVE_TRANSLATION_IN_SECONDS);
      }

      return this.singlePosteNeedsLine;
    },

    /*
      Returns an array of coordinates with the selected poste values.
      Format : [{x: '2023-02-06 22:15:00', y: 2}, {x: '2023-02-06 22:30:00', y: 3}]
     */
    singlePosteNeedsLine() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;
      const workloadPlansForPoste = this.workloadPlansByPostesForPeriod(
        this.posteId,
        openingTime.toISOString(),
        closingTime.toISOString(),
      );

      const predictedValues = workloadPlansForPoste.map(
        ({ startsAt, value }) => (
          {
            // Remove 7min30 + width of the curve to change level before the bar
            x: skDate.utc(startsAt).clone().subtract(NEED_CURVE_TRANSLATION_IN_SECONDS, 'seconds').toISOString(),
            y: value || 0,
          }
        ));

      // Duplicate the last element value and add 15 minutes to make the curve stop after the bar
      predictedValues.push(this.lastLineItem(predictedValues));

      return predictedValues;
    },

    /*
      Returns an array of coordinates with the sum of all postes values.
      Format : [{x: '2023-02-06 22:15:00', y: 2}, {x: '2023-02-06 22:30:00', y: 3}]
     */
    allPostesNeedsLine() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;
      const workloadPlanByQuarters = this.workloadPlansByHourQuartersForPeriod(
        openingTime.toISOString(),
        closingTime.toISOString(),
      );

      const predictedValues = Object.keys(workloadPlanByQuarters).map(
        hourQuarter => {
          const sumOfNeeds = workloadPlanByQuarters[hourQuarter].reduce(
            (acc, { value }) => acc + (value || 0),
            0,
          );

          return {
            // Remove 7min30 + width of the curve to change level before the bar
            x: skDate.utc(hourQuarter).subtract(485, 'seconds').toISOString(),
            y: sumOfNeeds,
          };
        });

      // Duplicate the last element to make the curve stop after the bar
      predictedValues.push(this.lastLineItem(predictedValues));

      return predictedValues;
    },

    /**
     * Computes the number of shifts per hour quarter by poste
     *
     * @returns {{x: string, y: object}[]} : Array of coordinates for the bars chart
     */
    computePredictedShiftsBarsChartByPostes() {
      return this.arrayOfOpeningQuarters.map(hourQuarter => {
        const nbShifts = this.planningShiftsForAllPostes.reduce((acc, shift) => {
          if (this.isShiftOnCurrentHourQuarter(shift, hourQuarter)) {
            if (!acc[shift.relationships.poste.id]) {
              acc[shift.relationships.poste.id] = { totalForQuarter: 0 };
            }
            acc[shift.relationships.poste.id].totalForQuarter += 1;
          }
          return acc;
        }, {});

        return {
          x: hourQuarter,
          y: nbShifts,
        };
      });
    },
  },
  mounted() {
    // Generates a virtual element in the DOM that reprensents the cursor
    // It's used by the tootltip as an anchor.
    // See documentation: https://popper.js.org/docs/v2/virtual-elements/
    this.virtualElement = {
      getBoundingClientRect: this.generateGetBoundingClientRect(),
    };

    this.instantiatePopper();

    window.addEventListener('mousemove', this.mousemoveHandler);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.mousemoveHandler);
  },
  methods: {
    /*
      Returns an array of coordinates with null y values to not show a chart.
      Format : [{x: '2023-02-06 22:15:00', y: null}, {x: '2023-02-06 22:30:00', y: null}]
     */
    emptyChartValues(translationToSubstract = 0) {
      const nullValues = this.arrayOfOpeningQuarters.map(x => (
        {
          // A bit more than 8 minutes to have the middle of the curve in the middle of the two bars
          x: skDate(x).clone().subtract(translationToSubstract, 'seconds').toISOString(),
          y: null,
        }
      ));

      // Duplicate the last element value and add 15 minutes to make the curve stop after the bar
      nullValues.push(this.lastLineItem(nullValues));

      return nullValues;
    },
    hourQuarterForIndex(dataPointIndex) {
      return this.arrayOfOpeningQuarters[dataPointIndex];
    },
    isShiftOnCurrentHourQuarter(shift, hourQuarter) {
      // +15 min to handle the case where a shift starts 1->14 minutes after the start of the hour quarter
      return skDate.utc(shift.attributes.startsAt).isBefore(skDate(hourQuarter).add(15, 'minutes')) &&
        skDate.utc(shift.attributes.endsAt).isAfter(hourQuarter);
    },
    lastLineItem(items) {
      const lastItem = { ...items.slice(-1)[0] };
      lastItem.x = skDate(lastItem.x).add(15, 'minutes').toISOString();

      return lastItem;
    },
    /**
     * Computes the number of shifts per hour quarter
     *
     * @param planningShifts : Shifts of the day with the planning filters applied
     * @returns {{x: string, y: number}[]} : Array of coordinates for the bars chart
     */
    computePredictedShiftsBarsChart(planningShifts) {
      return this.arrayOfOpeningQuarters.map(hourQuarter => {
        const nbShifts = planningShifts.reduce((totalForQuarter, shift) => {
          if (this.isShiftOnCurrentHourQuarter(shift, hourQuarter)) {
            return totalForQuarter + 1;
          }
          return totalForQuarter;
        }, 0);

        return {
          x: hourQuarter,
          y: !nbShifts ? 0 : nbShifts - 0.2, // 0.2 to stay under the line
        };
      });
    },
    mousemoveHandler({ clientX: x, clientY: y }) {
      this.mouseX = x;
      this.mouseY = y;
    },
    instantiatePopper() {
      // See documentation for virtual element as anchor
      // https://popper.js.org/docs/v2/virtual-elements/
      if (!this.popper) {
        this.popper = createPopper(
          this.virtualElement,
          document.getElementById('popper-workload-plan'),
          {
            placement: 'bottom-start',
            modifiers: [{
              name: 'offset',
              options: { offset: [20, 20] },
            }],
          },
        );
      }
    },
    updateTooltipData(apexTooltipData) {
      if (!this.popper) return;

      this.virtualElement.getBoundingClientRect =
        this.generateGetBoundingClientRect(this.mouseX, this.mouseY);
      this.popper.update();

      this.generateTooltipContent(apexTooltipData);
    },
    // Dynamicaly generate the tooltip Html,
    // to display it in the popper.
    generateTooltipContent(apexTooltipData) {
      const customTooltip = new CustomWorkloadPlanTooltip({
        parent: this,
        propsData: {
          tooltipDatas: this.getTooltipData(apexTooltipData),
        },
      });
      const tooltipHtml = this.htmlFromTooltip(customTooltip);

      this.tooltipContent = tooltipHtml;
    },
    getTooltipData(apexTooltipData) {
      const startDate = this.hourQuarterForIndex(apexTooltipData.dataPointIndex);
      const startsAt = skDate(startDate).utc().format('HH:mm');
      const endsAt = skDate(startDate)
        .utc()
        .clone()
        .add(15, 'minutes')
        .format('HH:mm');
      const postesForTooltip =
        this.getPostesDataForTooltip(apexTooltipData);

      return {
        startsAt,
        endsAt,
        postes: postesForTooltip,
      };
    },

    /*
    * Returns a list of Postes with its name, plannedShifts and plannedWorkload
    *
    * series: Array containing 2 list of value by chart.
    *     - series[0] is the step chart: Needs from workload plan.
    *     - series[1] is the bar chart: Shifts present on the planning by quarter
    * dataPointIndex: Index of the cursor when it hovers the chart.
    */
    getPostesDataForTooltip(apexTooltipData) {
      const { series, dataPointIndex } = apexTooltipData;

      if (this.posteId === 'all') {
        const hourQuarter = this.hourQuarterForIndex(dataPointIndex);
        const shiftsForThisQuarter = this.computePredictedShiftsBarsChartByPostes.find(
          quarterHour => quarterHour.x === hourQuarter,
        );

        const quarterHour = skDate.utc(this.hourQuarterForIndex(dataPointIndex)).format('YYYY-MM-DDTHH:mm:ss');

        return this.postes.map(poste => {
          const plannedWorkload =
            this.workloadPlansByHourQuarters[quarterHour]
              ?.find(workloadPlans => workloadPlans.posteId === poste.id).value || 0;

          // Check if there are shifts on this quarter and related to the shop.
          const plannedShifts = shiftsForThisQuarter && shiftsForThisQuarter.y[poste.id] ?
            shiftsForThisQuarter.y[poste.id].totalForQuarter : 0;

          return {
            name: poste.attributes.name,
            plannedShifts,
            plannedWorkload,
          };
        });
      }

      const posteName = this.postes.find(poste => poste.id === this.posteId).attributes.name;
      const plannedShifts = this.getRealPlannedShiftValue(series[1][dataPointIndex]);

      return [{
        name: posteName,
        plannedShifts,
        plannedWorkload: series[0][dataPointIndex],
      }];
    },
    // We clear the tooltip when the user
    // scrolls on the chart or leaves a chart.
    clearTooltip() {
      this.tooltipContent = '';

      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    htmlFromTooltip(tooltipInstance) {
      tooltipInstance.$mount();

      const returnHTML = `<div>${tooltipInstance.$el.innerHTML}</div>`;

      tooltipInstance.$destroy();
      return returnHTML;
    },
    /*
    * To display the Bar chart under the Line chart,
    * we remove BAR_CHART_VALUE_PADDING to every plannedShift for every quarter.
    *
    * Here, we want the real value, so we add the BAR_CHART_VALUE_PADDING that was previously removed.
    */
    getRealPlannedShiftValue(plannedShift) {
      return plannedShift > 0 ? plannedShift + BAR_CHART_VALUE_PADDING : 0;
    },
    generateGetBoundingClientRect(x = 0, y = 0) {
      return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popper-workload-plan__wrapper {
  z-index: 100;
}
</style>
