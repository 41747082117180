<template>
  <div class="planning__toolbar-navigation__wrapper">
    <SkNav>
      <!-- Day tab -->
      <div v-tooltip="packOfferTooltip">
        <SkNavItem
          :disabled="!isDayPlanningEnabled"
          :active="isDailyView"
          :to="{ name: 'plannings_days', query: { date: monday } }"
          data-test="planning-toolbar__navigation-day"
          @click.native="setFavoritePlanningView('days')"
        >
          {{ $t('plannings.toolbar.navigation.day') }}
        </SkNavItem>
      </div>

      <!-- Week tab -->
      <SkNavItem
        :active="isWeeklyView"
        :to="{ name: 'plannings_weeks', query: { date: currentDate } }"
        data-test="planning-toolbar__navigation-week"
        @click.native="setFavoritePlanningView('weeks')"
      >
        {{ $t('plannings.toolbar.navigation.week') }}
      </SkNavItem>

      <!-- Month tab -->
      <SkNavItem
        v-track="'month_view_V3_click_month_view'"
        :to="{ name: 'plannings_months', query: { date: currentDate } }"
        :active="isMonthlyView"
        data-test="planning-toolbar__navigation-month"
        @click.native="setFavoritePlanningView('months')"
      >
        <div class="new-feature-container">
          <div>
            {{ $t('plannings.toolbar.navigation.month') }}
          </div>
          <div class="planning__toolbar__new-feature-tag">
            {{ $t('plannings.toolbar.navigation.beta') }}
          </div>
        </div>
      </SkNavItem>
    </SkNav>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';

export default {
  name: 'Navigation',
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('planningsState', ['currentDate', 'monday', 'isDailyView', 'isWeeklyView', 'isMonthlyView']),
    isDayPlanningEnabled() {
      return this.currentOrganisation.attributes.packOffer.day_planning_enabled;
    },
    packOfferNameTranslated() {
      return this.$t(`pack_offers.${this.currentOrganisation.attributes.packOffer.name}`);
    },
    packOfferTooltip() {
      if (this.isDayPlanningEnabled) return '';
      return this.$t('plannings.toolbar.navigation.disabled', {
        pack_name_translated: this.packOfferNameTranslated,
      });
    },
  },
  methods: {
    setFavoritePlanningView(planningView) {
      localStorage.setItem('favoritePlanningView', planningView);
    },
  },
};
</script>

<style lang="scss" scoped>
.planning__toolbar-navigation__wrapper {
  left: 40%;

  // Deep are used here to customize default SkNavItems styles
  ::v-deep .sk-nav__item {
    text-align: center;
  }

  @media (min-width: 1200px) {
    ::v-deep .sk-nav__item {
      min-width: 80px;
    }
  }

  // asked by product
  ::v-deep .sk-nav__item__link {
    padding-bottom: 13px;
    padding-top: 16px;
  }

  ::v-deep .sk-nav {
    border: none;
    overflow-x: inherit;
  }
}

.planning__toolbar__new-feature-tag {
  height: 12px;
  border-radius: 50px;
  padding: 2px 4px;
  background-color: $sk-blue-50;
  font-size: 8px;
  font-weight: 600;
  color: $sk-white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 9px;
}

.new-feature-container {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
</style>
