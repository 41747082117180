<template>
  <div
    v-show="showAlerts"
    ref="planning_alert_wrapper"
    class="planning-row__shift__alerts-wrapper"
    :style="menuPosition"
  >
    <GlobalEvents
      v-if="showAlerts"
      @click="hideAlerts"
      @keydown.esc="hideAlerts"
    />
    <div class="planning-row__shift__alerts-header">
      <span>{{ $t('plannings.alerts.title') }}</span>
      <ClosingXIcon
        class="planning-row__shift__alerts-icon"
        fill="black"
        height="10"
        width="10"
        @click.native="hideAlerts"
      />
    </div>
    <div
      ref="alertSlider"
      class="planning-row__shift__alerts-row-wrapper"
    >
      <div
        v-for="alert in alerts"
        :key="`${shift.id}-${alert.id}`"
        class="planning-row__shift__alerts-row"
      >
        <div class="planning-row__shift__alerts-row__header">
          {{ alert.attributes.title }}
        </div>
        <div class="planning-row__shift__alerts-row__content">
          {{ alert.attributes.message }}
          <SkOroraLink
            v-if="alert.attributes.faqLink"
            :href="alert.attributes.faqLink"
            target="blank"
            size="x-small"
          >
            {{ $t('plannings.alerts.more') }}
          </SkOroraLink>
          <div
            class="planning-row__shift__alerts-row__deactivate-alert"
            @click="toggleDeactivateAlert(alert.id)"
          >
            <SkOroraButton
              variant="tertiary"
              size="small"
              icon="CheckMarkV2Icon"
            >
              {{ $t('plannings.alerts.cta') }}
            </SkOroraButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import GlobalEvents from 'vue-global-events';
import { ClosingXIcon } from '@skelloapp/skello-ui';

export default {
  name: 'ShiftAlerts',
  components: {
    ClosingXIcon,
    GlobalEvents,
  },
  data() {
    return {
      absoluteTop: 0,
      absoluteLeft: 0,
      alerts: [],
      shift: null,
      showAlerts: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters('planningsState', ['monday', 'sunday']),
    ...mapState('currentShop', ['currentShop']),
    menuPosition() {
      return {
        top: `${this.absoluteTop}px`,
        left: `${this.absoluteLeft}px`,
      };
    },
  },
  mounted() {
    this.listenOnRoot('show-alerts', e => {
      // 10 is a pretty margin, 33 is the height of KPIs's bar
      const prettyMargin = 10 + 33;
      const popovWidth = 284;

      this.$refs.alertSlider.scrollTop = 0;
      this.showAlerts = true;
      this.absoluteTop = e.top;
      this.absoluteLeft = e.left;
      this.alerts = e.shift.relationships.alerts;
      this.shift = e.shift;
      this.employeeId = e.shift.attributes.userId;

      this.$nextTick(() => {
        // Handle position for the last day column if the alert popov doesn't have enough space
        if (this.absoluteLeft + popovWidth >= window.innerWidth) {
          this.absoluteLeft -= popovWidth;
        }

        // Handle position for the last user column if the alert popov doesn't have enough space
        const wrapperSize = this.$refs.planning_alert_wrapper.getBoundingClientRect();
        const modalDistanceFromBottom = window.innerHeight - (wrapperSize.y + wrapperSize.height);
        if (modalDistanceFromBottom < 0) {
          this.absoluteTop += modalDistanceFromBottom - prettyMargin;
        }
      });
    });

    this.listenOnRoot('planning-table-scroll', this.handleScroll);
    this.listenOnRoot('hide-alerts-for-shift', e => {
      if (this.shift && parseInt(e.shiftId, 10) === parseInt(this.shift.id, 10)) {
        this.hideAlerts();
      }
    });
    this.listenOnRoot('hide-alerts-global', _ => {
      if (!this.showAlerts) return;

      this.hideAlerts();
    });
  },
  methods: {
    ...mapActions('planningsState', ['deactivateAlert']),
    onMouseEnter() {
      this.showAlerts = true;
    },
    hideAlerts() {
      this.showAlerts = false;
      this.emitOnRoot('modal-alert-close', this.showAlerts);
    },
    handleScroll(scrollValue) {
      this.absoluteTop += scrollValue;
    },
    toggleDeactivateAlert(alertId) {
      const params = {
        employeeId: this.employeeId,
        date: this.monday,
        shopId: this.currentShop.id,
        alertId,
      };
      this.deactivateAlert(params)
        .catch(() => {
          this.$skToast({
            message: this.$t('plannings.alerts.error'),
            variant: 'error',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.planning-row__shift__alerts-wrapper {
  position: fixed;
  display: flex;
  z-index: 8;
  background: white;
  min-height: 125px;
  max-height: 209px;
  width: 284px;
  flex-direction: column;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .24);
  border-radius: 4px;
}

.planning-row__shift__alerts-header {
  color: $sk-black;
  font-weight: 700;
  font-size: $fs-text-m;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid $sk-grey-10;
  padding: 0 16px;
}

.planning-row__shift__alerts-row {
  padding: 12px 0;
  margin: 0 16px;
  flex-direction: column;
}

.planning-row__shift__alerts-row:not(:last-child) {
  border-bottom: 1px solid $sk-grey-10;
}

.planning-row__shift__alerts-row__header {
  font-size: $fs-text-s;
  color: $sk-black;
  font-weight: 700;
  padding-bottom: 2px;
}

.planning-row__shift__alerts-row__content {
  font-size: $fs-text-xs;
  color: $sk-grey;
}

.planning-row__shift__alerts-row-wrapper {
  min-height: 82px;
  max-height: 165px;
  overflow: auto;
}

.planning-row__shift__alerts-icon {
  cursor: pointer;
}

.planning-row__shift__alerts-row__deactivate-alert {
  font-size: $fs-text-xs;
  font-weight: 700;
  color: $sk-blue;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  svg {
    margin-right: 5px;
  }
}
</style>
