import { svcDocumentV2Client } from '@skello-utils/clients';
import i18n from '@plugins/i18n';
import {
  NonSensitiveSkelloFolders, SkelloFolders,
} from '@skelloapp/svc-documents-v2-client';

const initialState = {
  customFolders: [],
  error: null,
  loadingFetchFolders: false,
  loadingCreateFolder: false,
  loadingUpdateFolder: false,
  loadingDeleteFolder: false,
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    }
  },
  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    }
  },
  fetchFoldersSuccess(state, folders) {
    state.customFolders = folders;
  },
  addFolderSuccess(state, folder) {
    state.customFolders.push(folder);
  },
  updateFolderSuccess(state, { folderId, name }) {
    const folder = state.customFolders.find(customFolder => customFolder.id === folderId);
    if (folder) folder.name = name;
  },
  deleteFolderSuccess(state, folderId) {
    const deletedIndex = state.customFolders
      .findIndex(customFolder => customFolder.id === folderId);
    state.customFolders.splice(deletedIndex, 1);
  },
  catchFoldersError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchFolders({ commit }, { shopId }) {
    commit('performingRequest', 'loadingFetchFolders');
    return svcDocumentV2Client.folder
      .find({ shopId })
      .then(response => {
        commit('fetchFoldersSuccess', response?.folders || []);
      })
      .catch(error => {
        commit('catchFoldersError', error);
        reject(error);
      })
      .finally(() => {
        commit('requestComplete', 'loadingFetchFolders');
      });
  },

  createFolder({ commit }, { shopId, name }) {
    commit('performingRequest', 'loadingCreateFolder');
    return svcDocumentV2Client.folder
      .create({ shopId, name })
      .then(folder => {
        commit('addFolderSuccess', folder);
      })
      .catch(error => {
        commit('catchFoldersError', error);
        reject(error);
      })
      .finally(() => {
        commit('requestComplete', 'loadingCreateFolder');
      });
  },

  updateFolder({ commit }, { folderId, name }) {
    commit('performingRequest', 'loadingUpdateFolder');

    return svcDocumentV2Client.folder
      .update(folderId, { name })
      .then(() => {
        commit('updateFolderSuccess', { folderId, name });
      })
      .catch(error => {
        commit('catchFoldersError', error);
        reject(error);
      })
      .finally(() => {
        commit('requestComplete', 'loadingUpdateFolder');
      });
  },

  deleteFolder({ commit }, { folderId }) {
    commit('performingRequest', 'loadingDeleteFolder');

    return svcDocumentV2Client.folder
      .delete(folderId)
      .then(() => {
        commit('deleteFolderSuccess', folderId);
      })
      .catch(error => {
        commit('catchFoldersError', error);
        reject(error);
      })
      .finally(() => {
        commit('requestComplete', 'loadingDeleteFolder');
      });
  },
};

const getters = {
  getCustomFolder: state => folder => state.customFolders
    .find(customFolder => customFolder.id === folder),
  folderName: state => folder => {
    if (SkelloFolders.includes(folder)) {
      return i18n.t(`employees.tabs.documents.hris_folders.${folder}.title`);
    }

    return state.customFolders.find(customFolder => customFolder.id === folder)?.name || '';
  },
  isFoldersManagementEnabled: (_state, _getters, _rootState, rootGetters) => (
    rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_DOCS_FOLDERS_MANAGEMENT')
  ),
  foldersList: (state, selfGetters, _rootState, rootGetters) => {
    const permanentFolders = rootGetters['currentLicense/canOnlyReadHris'] ?
      NonSensitiveSkelloFolders : SkelloFolders;

    if (!selfGetters.isFoldersManagementEnabled) {
      return permanentFolders;
    }

    const sortedCustomFolders = state.customFolders.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    return permanentFolders.concat(sortedCustomFolders.map(folder => folder.id));
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
