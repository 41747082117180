<template>
  <div class="shops-dropdown">
    <SkSelect
      v-model="localClusterNode"
      :ajax-client="ajaxClient"
      :ajax-format-response="ajaxFormatResponse"
      :ajax-format-params="shopNodesAjaxFormatParams"
      ajax-url="/v3/api/shops"
      append-to-body
      force-search-bar-behavior="force-show"
      :disabled-options="[localClusterNode.id]"
      :options="originalOptions"
      :search-placeholder="$t('labels.search')"
      paginated
      @input="onChange"
    >
      <template #empty-option>
        <div class="sk-select__no-search-result">
          {{ $t('navbar.flat_navigation.no_search_results') }}
        </div>
      </template>
      <template #selected-option="{ value }">
        <div
          :title="value.text"
          class="shops-dropdown__item"
        >
          <div class="shops-dropdown__item-icon-container">
            <SkMedallion
              v-if="value.icon === 'shop'"
              icon="ShopIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              size="x-small"
            />
            <SkMedallion
              v-else-if="value.icon === 'cog'"
              icon="CogIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              size="x-small"
            />
            <AllShopsIcon v-else />
          </div>
          <div class="shops-dropdown__item-text text-truncate">
            {{ value.text }}
          </div>
        </div>
      </template>
      <template #option="{ option }">
        <div
          :title="option.text"
          class="shops-dropdown__item"
        >
          <div class="shops-dropdown__item-icon-container">
            <SkMedallion
              v-if="option.icon === 'shop'"
              icon="ShopIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              size="x-small"
            />
            <SkMedallion
              v-else-if="option.icon === 'cog'"
              icon="CogIcon"
              background-color="#d9e6ff"
              color="#2b66fe"
              size="x-small"
            />
            <AllShopsIcon v-else />
          </div>
          <div class="shops-dropdown__item-text text-truncate">
            {{ option.text }}
          </div>
        </div>
      </template>
    </SkSelect>
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';
import AllShopsIcon from './AllShopsIcon';

export default {
  name: 'ShopsDropdown',
  components: { AllShopsIcon },
  data() {
    return {
      ajaxClient: () => httpClient,
      localClusterNode: null,
      originalOptions: [],
    };
  },
  computed: {
    ...mapState('navContext', ['navContext']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentOrganisation', ['atLeastOneShopWithPunchClock']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
  },
  watch: {
    /**
     * Watch navContext changes to update available options. Example: going from
     * "settings" to "employees" makes the "All shops" item appears in the
     * select box.
     */
    $route(to) {
      this.initializeSelectBox(to);
    },
    /**
     * The navContext can also be updated from the outside.
     * This watcher ensures the ShopsDropdown options are synchronized
     * with the navContext.clusterNodeId.
     */
    navContext() {
      this.initializeSelectBox(this.$router.currentRoute);
    },
  },
  created() {
    this.initializeSelectBox(this.$router.currentRoute);
  },
  methods: {
    ajaxFormatResponse(response) {
      return response.data.map(shop => ({
        id: String(shop.attributes.clusterNodeId),
        text: shop.attributes.name,
        shopId: shop.id,
        icon: 'shop',
        badging: shop.attributes.hasPunchClock,
      }));
    },
    shopNodesAjaxFormatParams(params) {
      if (this.$router.currentRoute.name.includes('badgings')) {
        if (this.atLeastOneShopWithPunchClock) {
          params.badging = true;
        }
      }

      return {
        ...params,
        only_active_shops: true,
      };
    },
    /**
     * Build an item which can be displayed in the SkSelect component
     */
    buildItem(clusterNode) {
      if (clusterNode.attributes.isRoot) {
        let text = this.$t('navbar.flat_navigation.all_shops');
        let icon = 'all-shops';

        const isLeaveRequestAllView =
          this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_V3_ALL') && (
            this.$router.currentRoute.name.startsWith('leave_requests_teams') ||
            this.$router.currentRoute.name.startsWith('leave_requests_own'));

        if (
          !this.$router.currentRoute.name.startsWith('home_dashboard') &&
          !this.$router.currentRoute.name.startsWith('user') &&
          !this.$router.currentRoute.name.startsWith('reports') &&
          !this.$router.currentRoute.name.startsWith('analytics') &&
          !isLeaveRequestAllView
        ) {
          // In organisation settings all page
          text = this.$t('navbar.flat_navigation.organisation');
          icon = 'cog';
        }

        return {
          id: String(clusterNode.id),
          text,
          shopId: 'all',
          icon,
        };
      }

      return {
        id: String(clusterNode.id),
        text: clusterNode.attributes.name,
        shopId: clusterNode.attributes.shopId,
        icon: 'shop',
        badging: clusterNode.attributes.badging,
      };
    },
    initializeSelectBox(route) {
      // The option currently selected
      this.localClusterNode = this.buildItem({
        id: this.navContext.clusterNodeId,
        attributes: {
          name: this.navContext.name,
          shopId: this.navContext.shopId,
          isRoot: this.navContext.viewAllShops,
          badging: this.navContext.badging,
        },
      });
      // When on employee or organisation_settings pages, add a special
      // "All shops" item in the list of available options
      if (this.hasAllShopsView(route)) {
        this.originalOptions = [this.buildItem({
          id: this.currentOrganisation.attributes.rootNodeId,
          attributes: {
            shopId: null,
            isRoot: true,
          },
        })];
      } else {
        this.originalOptions = [];
      }
    },
    onChange() {
      const route = {
        params: {
          shop_id: this.localClusterNode.shopId,
          badging: this.localClusterNode.badging,
        },
        query: { ...this.$router.currentRoute.query },
      };
      const routeName = this.$router.currentRoute.name;
      if (routeName.startsWith('user')) {
        // The select_first option reloads the first employee
        route.params.select_first = true;
      } else if (routeName.startsWith('organisation_settings')) {
        route.name = 'shop_settings';
      }

      if (routeName.startsWith('reports')) {
        route.name = 'reports';
      }

      this.$router.push(route);
    },
    hasAllShopsView(route) {
      return route.name.startsWith('home_dashboard') ||
        route.name.startsWith('user') ||
        route.name.startsWith('organisation_settings') ||
        route.name.startsWith('reports') ||
        (this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_V3_ALL') &&
        (route.name.startsWith('leave_requests_teams') ||
        route.name.startsWith('leave_requests_own'))) ||
        (route.name.startsWith('analytics') && route.query.mode !== 'shop');
      // add mode === 'shop' when you don't want the shopsDropDown to have the "view all" option
    },
  },
};
</script>
<style lang="scss" scoped>
.shops-dropdown {
  margin: 0 24px;
}

.shops-dropdown__item {
  display: inline-flex;
  align-items: center;
  width: 205px;
}

.shops-dropdown__item-text {
  max-width: 174px;
}

.sk-select__no-search-result {
  width: 220px;
}

.shops-dropdown__item-icon-container {
  margin-right: 15px;
}
</style>
