<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    id="init-paid-leaves-counter-modal"
    ref="initPaidLeavesCounter"
    :modal-title="$t('employees.tabs.counters.paid_leaves_counter.modal.title')"
    size="inherited"
    @cancel="handleCancel"
  >
    <template #body>
      <SkModalSection
        class="init-paid-leaves-counter-modal__date"
        :class="updateCounter && 'init-paid-leaves-counter-modal__date--update'"
        :border-bottom="updateCounter ? 'none' : 'normal'"
      >
        <div class="init-date-section__init-date">
          <div class="init-date-section__datepicker-title">
            {{ $t('employees.tabs.counters.paid_leaves_counter.modal.init_date.title') }}
            <span>
              <CircledQuestionMarkIcon
                v-tooltip="$t('employees.tabs.counters.paid_leaves_counter.modal.setup_tooltip')"
                class="init-date-section__datepicker-title__help"
              />
            </span>
          </div>
          <div class="init-date-section__datepicker-wrapper">
            <SkDatePicker
              ref="datepicker"
              v-model="employeeCounter.initDate"
              :placeholder="$t('employees.tabs.counters.paid_leaves_counter.modal.init_date.placeholder')"
              :errored="!employeeCounter.initDate"
              :error-message="$t('errors.missing_value')"
              :lang="$i18n.locale"
              input-moment-format="MMMM YYYY"
              type="month"
              append-to-body
              hide-footer
            />
            <p class="sk-subtitle--small">
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.init_date.hint') }}
            </p>
          </div>
        </div>
      </SkModalSection>

      <div
        v-if="!updateCounter"
        class="init-paid-leaves-counter-modal__balance-section-wrapper"
      >
        <SkModalSection
          :class="balanceSectionClass"
          :padding-bottom="false"
          border-bottom="none"
        >
          <div class="balance-section__details">
            <b>{{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.title') }}</b>
            <p
              class="sk-subtitle--medium"
              style="margin: 10px 0 7px;"
            >
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.explanation') }}
            </p>
          </div>
          <SkSwitch
            v-model="inheritBalance"
            class="balance-section__switch"
          />
          <div class="balance-section__card">
            <div class="balance-section__card__icon">
              <CircledIIcon
                fill="#2b66fe"
                width="24"
                height="24"
              />
            </div>
            <div class="balance-section__card__explanation">
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_1_1') }}
              <b>{{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_1_2') }}</b>
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_1_3') }}
              <CircledPlusSignThinIcon class="balance-section__card__explanation__modifier-icon" />
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_1_4') }}
              <br>
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_2_1') }}
              <b>{{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_2_2') }}</b>
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_2_3') }}
              <CircledMinusSignThinIcon class="balance-section__card__explanation__modifier-icon" />
              {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.card.explanation_2_4') }}
            </div>
          </div>
          <div class="balance-section__form">
            <SkMedallion
              :text="initials"
              background-color="#2b66fe"
              color="white"
              class="balance-section__medallion"
            />
            {{ employee.attributes.firstName }} {{ employee.attributes.lastName }}
            <div
              v-tooltip="$t('employees.tabs.counters.paid_leaves_counter.modal.balance.modifier_tooltip')"
              class="balance-section__modifiers"
            >
              <div
                class="balance-section__form__modifier__wrapper"
                @click="selectModifier('-')"
              >
                <CircledMinusSignThinIcon
                  :background-fill="isModifierSelected('-') ? '#2b66fe' : 'none'"
                  :sign-fill="isModifierSelected('-') ? 'white' : '#727272'"
                  :border-fill="isModifierSelected('-') ? '#2b66fe' : '#727272'"
                  class="balance-section__form__modifier"
                  height="18px"
                  width="18px"
                />
              </div>
              <div
                class="balance-section__form__modifier__wrapper"
                @click="selectModifier('+')"
              >
                <CircledPlusSignThinIcon
                  :background-fill="isModifierSelected('+') ? '#2b66fe' : 'none'"
                  :sign-fill="isModifierSelected('+') ? 'white' : '#727272'"
                  :border-fill="isModifierSelected('+') ? '#2b66fe' : '#727272'"
                  height="18px"
                  width="18px"
                  class="balance-section__form__modifier"
                />
              </div>
            </div>
            <SkInputGroup
              :disabled="!inheritBalance"
              :errored="balanceInputError"
              :error-message="$t('employees.tabs.counters.paid_leaves_counter.modal.balance.error_message')"
              class="balance-section__form__input"
            >
              <form @submit.prevent="handleSubmit">
                <SkInput
                  v-model="employeeCounter.balance"
                  :placeholder="$t('employees.tabs.counters.paid_leaves_counter.modal.balance.placeholder')"
                  :disabled="!inheritBalance"
                  type="number"
                  min="0"
                  step="1"
                />
              </form>
              <template #append>
                {{ $t('employees.tabs.counters.paid_leaves_counter.modal.balance.days') }}
              </template>
            </SkInputGroup>
          </div>
        </SkModalSection>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :disabled="!validForm"
        :loading="loading"
        data-test="employee__paid-leaves-counter__initialization-button"
        @click="handleSubmit"
      >
        {{ buttonLabel }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { isFloat } from '@skello-utils/validators';

function getDefaultData(initDate = null) {
  return {
    employeeCounter: {
      initDate,
      balance: null,
      balanceModifier: '+',
    },
    loading: false,
    inheritBalance: false,
  };
}

export default {
  name: 'InitPaidLeavesCounterModal',
  props: {
    initDate: {
      type: Object,
      default: () => null,
    },
    updateCounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return getDefaultData(this.initDate?.format('YYYY-MM-DD'));
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('currentShop', ['currentShop']),
    ...mapGetters('selectedEmployee', ['initials']),

    balanceSectionClass() {
      return {
        'init-paid-leaves-counter-modal__balance-section': true,
        'init-paid-leaves-counter-modal__balance-section--disabled': !this.inheritBalance,
      };
    },
    validForm() {
      if (this.inheritBalance) {
        return this.employeeCounter.balance != null &&
          !this.balanceInputError &&
          !!this.employeeCounter.initDate;
      }

      return !!this.employeeCounter.initDate;
    },

    balanceInputError() {
      if (!this.employeeCounter.balance) return false;

      return !isFloat(this.employeeCounter.balance) ||
        this.employeeCounter.balance < 0;
    },
    buttonLabel() {
      if (this.updateCounter) {
        return this.$t('employees.tabs.counters.paid_leaves_counter.modal_update_confirmation.update');
      }

      return this.$t('employees.tabs.counters.paid_leaves_counter.modal.submit');
    },
  },

  watch: {
    inheritBalance(newValue) {
      this.employeeCounter.balance = newValue ? this.employee.attributes.initialPlc : null;
    },
  },

  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    ...mapActions('employeePaidLeavesCounter', ['initEmployeePaidLeavesCounter', 'fetchEmployeePaidLeavesCounter', 'fetchEmployeePeriodSummaries']),

    selectModifier(modifier) {
      if (!this.inheritBalance) return;
      this.employeeCounter.balanceModifier = modifier;
    },

    isModifierSelected(modifier) {
      return modifier === this.employeeCounter.balanceModifier;
    },
    handleCancel() {
      Object.assign(this.$data, getDefaultData(this.initDate?.format('YYYY-MM-DD')));
      this.$refs.datepicker.resetModified();
    },
    initPTOCounter() {
      if (!this.employeeCounter.balance && this.inheritBalance) return;
      this.loading = true;
      this.initEmployeePaidLeavesCounter({
        shop_id: this.employee.attributes.shopId,
        user_id: this.employee.id,
        start_date: this.employeeCounter.initDate,
        plc_sign: this.employeeCounter.balanceModifier,
        user_initial_plc: this.employeeCounter.balance,
      })
        .then(async () => {
          const toastTranslation = this.updateCounter ?
            'employees.tabs.counters.paid_leaves_counter.modal_update_confirmation.toast.success' :
            'employees.tabs.counters.paid_leaves_counter.modal.toast.success';

          this.$skToast({
            message: this.$t(toastTranslation),
            variant: 'success',
            containerId: 'employees__container',
          });

          const plcData = await this.fetchEmployeePaidLeavesCounter({
            user_id: this.employee.id,
            silentLoading: false,
          });

          this.setEmployeeAttributes({
            plcInitializationDoneAt: plcData.data.user.plc_initialization_done_at,
          });

          this.fetchEmployeePeriodSummaries({ user_id: this.employee.id });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('errors.standard_message'),
            variant: 'error',
            containerId: 'employees__container',
          });
        })
        .finally(() => {
          this.loading = false;
          this.$refs.initPaidLeavesCounter?.hide();
          this.handleCancel();
        });
    },
    handleSubmit(event) {
      if (this.updateCounter) {
        this.$root.$emit('confirm', event, {
          title: this.$t('employees.tabs.counters.paid_leaves_counter.modal_update_confirmation.title'),
          description: this.$t('employees.tabs.counters.paid_leaves_counter.modal_update_confirmation.text'),
          submitLabel: this.$t('employees.tabs.counters.hours_counter.initialization.confirm.submit_label'),
          onConfirm: () => this.initPTOCounter(),
          onCancel: () => { this.initSpinner = false; },
        });
      } else this.initPTOCounter();
    },
  },
};
</script>

<style lang="scss" scoped>
.init-paid-leaves-counter-modal__date {
  width: 620px;

  &--update {
    padding-bottom: 18px !important;
  }

  .init-date-section__init-date {
    display: flex;
    justify-content: space-between;
  }

  .init-date-section__datepicker-title {
    width: 150px;
    font-weight: $fw-semi-bold;
  }

  .init-date-section__datepicker-wrapper {
    width: 340px;
  }
}

.init-paid-leaves-counter-modal__balance-section {
  width: 620px;
  position: relative;

  .balance-section__switch {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .balance-section__card {
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid $sk-grey-10;
    font-size: $fs-text-m;
    color: $sk-grey;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px 10px 10px;
  }

  .balance-section__card__icon {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .balance-section__form {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 10px;
  }

  .balance-section__medallion {
    margin-right: 20px;
  }

  .balance-section__modifiers {
    display: flex;
    margin-right: 18px;
    margin-left: auto;
    cursor: pointer;
  }

  .balance-section__modifier {
    margin-left: 3px;
  }

  .balance-section__form__input {
    margin-right: 0;
    width: 200px;
  }
}

.init-paid-leaves-counter-modal__balance-section--disabled {
  .balance-section__card,
  .balance-section__form,
  .balance-section__form__hint {
    opacity: .3;
  }

  .balance-section__modifiers {
    cursor: not-allowed;
  }
}

.balance-section__card__explanation__modifier-icon {
  vertical-align: middle;
}

.init-date-section__datepicker-title__help {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}

.init-paid-leaves-counter-modal__balance-section-wrapper {
  padding-bottom: 15px;
}

.balance-section__form__modifier__wrapper {
  padding: 0 2px;
  display: flex;
}
</style>
