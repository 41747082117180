const ANALYSIS_TYPE_PAYSLIPS = 'PAYSLIPS';

export const toValidationResponseDTO = (payslip, shopId) => {
  // llmResponses format comes from the websocket
  // The address is on two lines + no employee id
  const llmResponses = payslip.websocketEvent.map(
    ({ output }) => JSON.parse(output)).map(
    output => ({
      ...output,
      type: ANALYSIS_TYPE_PAYSLIPS,
    }));
  const baseMatching = {
    dni: payslip.dni,
    firstName: payslip.firstName,
    startPayrollPeriod: payslip.startDate,
    endPayrollPeriod: payslip.endDate,
    lastName: payslip.lastName,
    streetName: payslip.streetName,
    postalCode: payslip.postalCode,
    city: payslip.city,
  };

  return {
    analysisType: ANALYSIS_TYPE_PAYSLIPS,
    documentId: payslip.newDocumentId,
    shopId,
    llmResponses,
    payload: {
      type: ANALYSIS_TYPE_PAYSLIPS,
      customerMatching: {
        ...baseMatching,
        employeeId: payslip.userId || '',
      },
      skelloMatching: {
        ...baseMatching,
        employeeId: payslip.skelloUserIdMatch || '',
      },
    },
  };
};
