<template>
  <div class="flex-row">
    <div class="flex-m6 flex-row">
      <SkPopover
        :class="cardsClass(true)"
        :disabled="dashboardFeaturesEnabled"
        placement="top"
        as-tooltip
      >
        <template #anchor>
          <div
            data-html="true"
            data-placement="auto"
          >
            <TrialsEndsCard />
          </div>
        </template>
        <template #content>
          <div class="second-line__tooltip">
            {{ $t('home_dashboard.pack_offers.tooltip.before_tag') }}
            <PackTag :type="packOfferName" />
            {{ $t(`home_dashboard.pack_offers.tooltip.pe_reminders_disabled_html`) }}
          </div>
        </template>
      </SkPopover>
      <SkPopover
        :class="cardsClass(true)"
        :disabled="dashboardFeaturesEnabled"
        placement="top"
        as-tooltip
      >
        <template #anchor>
          <div
            data-html="true"
            data-placement="auto"
          >
            <DocsExpiringCard />
          </div>
        </template>
        <template #content>
          <div class="second-line__tooltip">
            {{ $t('home_dashboard.pack_offers.tooltip.before_tag') }}
            <PackTag :type="packOfferName" />
            {{ $t(`home_dashboard.pack_offers.tooltip.doc_expiring_disabled_html`) }}
          </div>
        </template>
      </SkPopover>
      <div class="flex-m6">
        <TurnoverCard />
      </div>
      <div class="flex-m6">
        <DeparturesArrivalsCard />
      </div>
    </div>
    <div class="flex-m6">
      <SkPopover
        :class="cardsClass(false)"
        :disabled="dashboardFeaturesEnabled"
        placement="top"
        as-tooltip
      >
        <template #anchor>
          <div
            data-html="true"
            data-placement="auto"
          >
            <ActualityChart />
          </div>
        </template>
        <template #content>
          <div class="second-line__tooltip">
            {{ $t('home_dashboard.pack_offers.tooltip.before_tag') }}
            <PackTag :type="packOfferName" />
            {{ $t(`home_dashboard.pack_offers.tooltip.actuality_feed_disabled_html`) }}
          </div>
        </template>
      </Skpopover>
      <SkPopover
        :class="cardsClass(false)"
        :disabled="dashboardFeaturesEnabled"
        placement="top"
        as-tooltip
      >
        <template #anchor>
          <div
            data-html="true"
            data-placement="auto"
          >
            <StaffCostsCard />
          </div>
        </template>
        <template #content>
          <div class="second-line__tooltip">
            {{ $t('home_dashboard.pack_offers.tooltip.before_tag') }}
            <PackTag :type="packOfferName" />
            {{ $t(`home_dashboard.pack_offers.tooltip.labor_cost_disabled_html`) }}
          </div>
        </template>
      </Skpopover>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters,
} from 'vuex';

import PackTag from '@app-js/shared/components/PackTag';

import ActualityChart from './Charts/ActualityChart.vue';
import TrialsEndsCard from './Cards/TrialsEndsCard.vue';
import DocsExpiringCard from './Cards/DocsExpiringCard.vue';
import TurnoverCard from './Cards/TurnoverCard.vue';
import DeparturesArrivalsCard from './Cards/DeparturesArrivalsCard.vue';
import StaffCostsCard from './Cards/StaffCostsCard.vue';

export default {
  name: 'DashboardSecondLine',
  components: {
    ActualityChart,
    DeparturesArrivalsCard,
    DocsExpiringCard,
    PackTag,
    TrialsEndsCard,
    TurnoverCard,
    StaffCostsCard,
  },
  data() {
    return {
      isShopPresent: this.$store.state.isShopPresent,
      disabledClass: 'activateTooltip front--disabled',
    };
  },

  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),

    packOfferName() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    dashboardFeaturesEnabled() {
      return this.checkPackOfferFlag('dashboard_reminders_enabled');
    },
  },
  methods: {
    cardsClass(includeFlex) {
      let classname = includeFlex ? 'flex-m6' : null;
      if (!this.dashboardFeaturesEnabled) {
        classname = `${classname} ${this.disabledClass}`;
      }
      return classname;
    },
  },
};
</script>

<style lang="scss" scoped>
  .front--disabled {
    cursor: not-allowed;

    ::v-deep .dashboard-flip-cards {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .second-line__tooltip {
    max-width: 200px;
  }
</style>
