<template>
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="#2b66fe"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2 10.1025V0.9C16.2 0.661305 16.1052 0.432387 15.9364
      0.263604C15.7676 0.0948211 15.5387 0 15.3 0H0.9C0.661305
      0 0.432387 0.0948211 0.263604 0.263604C0.0948211 0.432387
      0 0.661305 0 0.9V17.1C0 17.3387 0.0948211 17.5676 0.263604
      17.7364C0.432387 17.9052 0.661305 18 0.9 18H8.07089C8.02417
      17.6734 8 17.3395 8 17C8 16.7295 8.01535 16.4625 8.04521
      16.2H1.8V1.8H14.4V10.0254C14.5978 10.0086 14.7979 10 15
      10C15.4091 10 15.8101 10.0351 16.2 10.1025ZM9 13.3924C8.97851
      13.4281 8.95734 13.4639 8.93647 13.5H4.5V11.7H9V13.3924ZM11.7
      4.5H4.5V6.3H11.7V4.5ZM11.7 8.1H4.5V9.9H11.7V8.1Z"
      fill="#2b66fe"
    />

    <path
      d="M20.2507 19.7131C19.907 19.7131 19.5685 19.5817 19.3097
      19.3228L18.6754 18.6886C18.4858 18.4989 18.4858 18.1915 18.6754
      18.0019C18.865 17.8123 19.1725 17.8123 19.3621 18.0019L19.9963
      18.6361C20.1387 18.7785 20.3846 18.7785 20.5264 18.6361C20.694
      18.4653 20.694 18.2245 20.549 18.0763L18.7013 16.2293C18.5117
      16.0396 18.5117 15.7322 18.7013 15.5426C18.8909 15.353 19.1983
      15.353 19.388 15.5426L21.2389 17.3936C21.7547 17.9197 21.7547
      18.7701 21.2422 19.293C20.9613 19.5746 20.6034 19.7131 20.2507 19.7131Z"
      fill="#2b66fe"
    />

    <path
      d="M13.1034 15.7131C12.9131 15.7131 12.7319 15.6005 12.6549
      15.4135C12.552 15.1656 12.6698 14.8815 12.9177 14.7792L15.8999
      13.5431C16.5432 13.2817 17.2739 13.4312 17.7644 13.9217L18.1184
      14.2757C18.3081 14.4654 18.3081 14.7728 18.1184 14.9624C17.9288
      15.152 17.6214 15.152 17.4318 14.9624L17.0778 14.6084C16.8668
      14.3974 16.5425 14.3295 16.2681 14.4414L13.2891 15.6756C13.2283
      15.7015 13.1655 15.7131 13.1034 15.7131Z"
      fill="#2b66fe"
    />

    <path
      d="M18.1278 16.7354C17.7886 16.7354 17.4534 16.6034 17.2016
      16.3516C16.9046 16.0539 16.7674 15.6293 16.8347 15.2145C16.902
      14.7997 17.166 14.4405 17.5421 14.2521L19.681 13.181C20.1269
      12.9584 20.646 12.9403 21.1061 13.1312L24.6132 14.5654C24.8617
      14.667 24.9801 14.9504 24.8792 15.1983C24.7776 15.4462 24.4954
      15.5653 24.2462 15.4643L20.7359 14.0289C20.5333 13.9447 20.3088
      13.9525 20.1159 14.0489L17.977 15.12C17.8779 15.1698 17.8113
      15.2604 17.7932 15.3698C17.775 15.4792 17.81 15.586 17.8883 15.6649C17.9925
      15.7691 18.1498 15.7931 18.2818 15.7284L20.1832 14.7705C20.422
      14.6508 20.7146 14.7466 20.8349 14.986C20.9553 15.2255 20.8595 15.5174
      20.6194 15.6378L18.7186 16.5956C18.5303 16.6894 18.3284 16.7354
      18.1278 16.7354Z"
      fill="#2b66fe"
    />

    <path
      d="M19.0428 20.9327C18.6836 20.9327 18.3458 20.7929 18.0915
      20.5386L17.3019 19.749C17.1123 19.56 17.1123 19.252 17.3019
      19.0623C17.4915 18.8727 17.7989 18.8727 17.9885 19.0623L18.7781
      19.8519C18.9198 19.9936 19.1671 19.993 19.3075 19.8519C19.3787
      19.7813 19.4175 19.6869 19.4175 19.5872C19.4175 19.4869 19.3787
      19.393 19.3082 19.3225L18.6739 18.6883C18.4843 18.4986 18.4843
      18.1912 18.6739 18.0016C18.8635 17.812 19.171 17.812 19.3606
      18.0016L19.9948 18.6358C20.2492 18.8895 20.389 19.2274 20.389 19.5872C20.389
      19.9464 20.2492 20.2842 19.9948 20.5386C19.7405 20.7929
      19.4026 20.9327 19.0428 20.9327Z"
      fill="#2b66fe"
    />

    <path
      d="M17.661 21.9982C17.3225 21.9982 16.986 21.8726 16.7271
      21.6209L15.3059 20.331C14.7901 19.8631 14.1229 19.6055 13.4271
      19.6055H13.1036C12.8356 19.6055 12.6182 19.3881 12.6182
      19.1202C12.6182 18.8522 12.8356 18.6348 13.1036 18.6348H13.4271C14.3649
      18.6348 15.2639 18.9817 15.9583 19.612L17.3911 20.9129C17.5497
      21.0656 17.7853 21.0649 17.9296 20.92C18.0092 20.8404 18.048
      20.7465 18.048 20.6469C18.048 20.5472 18.0092 20.4527 17.9387
      20.3822L17.3044 19.7479C17.1148 19.5583 17.1148 19.2509 17.3044
      19.0613C17.494 18.8716 17.8015 18.8716 17.9911 19.0613L18.6253
      19.6955C19.1502 20.2204 19.1502 21.0734 18.6253 21.5976C18.358
      21.8655 18.0092 21.9982 17.661 21.9982Z"
      fill="#2b66fe"
    />

    <path
      d="M22.57 19.8177C22.3862 19.8177 22.2024 19.7879 22.0244
      19.7264L20.8672 19.3303C20.6135 19.2436 20.4783 18.9673 20.5656 18.7136C20.6523
      18.4605 20.9274 18.3233 21.1824 18.412L22.3389 18.8081C22.5881
      18.8922 22.8677 18.8378 23.067 18.6644L24.0785 17.6114C24.2649 17.4179
      24.573 17.4134 24.7652 17.5985C24.9581 17.7842 24.9639 18.0916
      24.7781 18.2851L23.7362 19.3666C23.3977 19.6656 22.9842 19.8177 22.57 19.8177Z"
      fill="#2b66fe"
    />

    <path
      d="M26.0472 20.1515H24.4292C24.1613 20.1515 23.9438 19.9341 23.9438
      19.6661V14.1651C23.9438 13.8971 24.1613 13.6797 24.4292 13.6797H26.0472C26.3151
      13.6797 26.5326 13.8971 26.5326 14.1651V19.6661C26.5326 19.9341
      26.3151 20.1515 26.0472 20.1515ZM24.9146 19.1808H25.5618V14.6505H24.9146V19.1808Z"
      fill="#2b66fe"
    />

    <path
      d="M13.1033 20.1515H11.4854C11.2175 20.1515 11 19.9341 11 19.6661V14.1651C11
      13.8971 11.2175 13.6797 11.4854 13.6797H13.1033C13.3713 13.6797 13.5887
      13.8971 13.5887 14.1651V19.6661C13.5887 19.9341 13.3713 20.1515 13.1033
      20.1515ZM11.9708 19.1808H12.618V14.6505H11.9708V19.1808Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'ContractSpaceIcon',
  props: {
    width: {
      type: String,
      default: '14',
    },
    height: {
      type: String,
      default: '16',
    },
    fill: {
      type: String,
      default: '#2b66fe',
    },
  },
};
</script>
