<template>
  <!-- eslint-disable max-len -->
  <SkModal
    :id="id"
    :modal-title="titleText"
    size="inherited"
    @hidden="emitOnRoot('EmployeeAmendmentsModal::hidden')"
  >
    <template #body>
      <div class="employee-amendments-modal__table-wrapper">
        <AmendmentsTable
          :amendments="amendments"
          sorting
          @delete-amendment="handleDelete"
        />
      </div>
    </template>

    <template #footer>
      <div>
        <SkPopover
          :disabled="canAddAmendment"
          placement="left"
          as-tooltip
        >
          <template
            v-if="!isContractReadOnlyView"
            #anchor
          >
            <SkOroraButton
              v-track="'add_amendment_from_history'"
              :disabled="!canAddAmendment"
              @click="openCreateAmendmentModal"
            >
              {{ $t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.modal.create_amendment') }}
            </SkOroraButton>
          </template>
          <template #content>
            {{ $t('employees.tabs.documents.amendments_disabled.before_tag') }}
            <PackTag :type="packType" />
            {{ $t('employees.tabs.documents.amendments_disabled.after_tag') }}
            <br>
            <br>
            {{ $t('employees.tabs.documents.amendments_disabled.second_line') }}
          </template>
        </SkPopover>
      </div>
    </template>
    <MountingPortal
      v-if="!isContractReadOnlyView"
      mount-to="#modals-portal"
      append
    >
      <create-amendment-modal :tracking-options="trackingOptions" />
      <CreateAmendmentModalV2 :employee-annualization-config="employeeAnnualizationConfig" />
    </MountingPortal>
  </SkModal>
  <!-- eslint-enable max-len -->
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import {
  MODAL_HIDE_EVENT,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

import PackTag from '@app-js/shared/components/PackTag';

import AmendmentsTable from './AmendmentsTable';
import CreateAmendmentModal from './CreateAmendmentModal';
import CreateAmendmentModalV2 from './CreateAmendmentModalV2';

export default {
  name: 'EmployeeAmendmentsModal',
  components: {
    AmendmentsTable,
    CreateAmendmentModal,
    CreateAmendmentModalV2,
    PackTag,
  },
  props: {
    employeeAnnualizationConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      id: 'employee-amendments-modal',
    };
  },
  computed: {
    ...mapState('amendments', ['amendments']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapGetters('selectedEmployee', ['fullName']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('currentShop', ['isAnnualizationContractHoursChangeEnabled']),
    ...mapGetters('contracts', ['isContractReadOnlyView']),

    canAddAmendment() {
      return this.checkPackOfferFlag('contract_amendments_enabled');
    },
    packType() {
      return this.currentOrganisation.attributes.packOffer.name;
    },
    titleText() {
      return this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.modal.title', { name: this.fullName });
    },
    trackingOptions() {
      return {
        submit: 'multicontract_add_contracted_hours_amendment_validate',
        cancel: 'add_amendment_cancel',
      };
    },
  },
  methods: {
    ...mapActions('amendments', ['deleteAmendment']),

    handleDelete(amendmentId, employeeId, contractId) {
      this.$root.$emit('confirm', event, {
        title: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.confirm'),
        // eslint-disable-next-line arrow-body-style
        onConfirmSuccess: () => {
          return this.deleteAmendment({
            employee_id: employeeId,
            amendment_id: amendmentId,
            contract_id: contractId,
          })
            .then(() => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.success'),
                variant: 'success',
                containerId: 'employees__container',
              });
            })
            .catch(error => {
              this.$skToast({
                message: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.delete.failure'),
                variant: 'error',
                containerId: 'employees__container',
              });
            });
        },
      });
    },
    openCreateAmendmentModal(event) {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, this.id);

      if (!this.isAnnualizationContractHoursChangeEnabled) {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-amendment-modal');
        return;
      }

      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-amendment-modal-v2');
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-amendments-modal__table-wrapper {
  width: 900px;
}
</style>
