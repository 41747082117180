<template>
  <div>
    <PostesSelect
      v-model="primaryPosteIds"
      type="primary"
      :disabled-options="disabledPrimaryOptions"
      :size="size"
      :disabled="disabled"
      :shop-id="shopId"
    />
    <PostesSelect
      v-model="secondaryPosteIds"
      type="secondary"
      :disabled-options="disabledSecondaryOptions"
      :size="size"
      :disabled="disabled"
      :shop-id="shopId"
    />
  </div>
</template>

<script>
import PostesSelect from './PostesSelect';

export default {
  name: 'CompetenciesInput',
  components: {
    PostesSelect,
  },
  props: {
    shopId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    primaryPosteIds: {
      get() {
        return this.value.filter(competency => competency.primary)
          .map(competency => competency.id.toString());
      },
      set(newPrimaryPosteIds) {
        const primary = newPrimaryPosteIds.map(posteId => ({
          id: Number(posteId), primary: true,
        }));
        const secondary = this.secondaryPosteIds.map(posteId => ({
          id: Number(posteId), primary: false,
        }));
        this.$emit('input', primary.concat(secondary));
      },
    },
    secondaryPosteIds: {
      get() {
        return this.value.filter(competency => !competency.primary)
          .map(competency => competency.id.toString());
      },
      set(newSecondaryPosteIds) {
        const primary = this.primaryPosteIds.map(posteId => ({
          id: Number(posteId), primary: true,
        }));
        const secondary = newSecondaryPosteIds.map(posteId => ({
          id: Number(posteId), primary: false,
        }));
        this.$emit('input', primary.concat(secondary));
      },
    },
    disabledPrimaryOptions() {
      if (this.secondaryPosteIds.length) {
        return [...this.secondaryPosteIds, 'all'];
      }
      return [];
    },
    disabledSecondaryOptions() {
      if (this.primaryPosteIds.length) {
        return [...this.primaryPosteIds, 'all'];
      }
      return [];
    },
  },
};
</script>
