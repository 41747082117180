<template>
  <div
    :class="tagClasses"
    data-test="team-schedule__tag"
  >
    {{ tagText }}
  </div>
</template>

<script>
export default {
  name: 'VariableContractHoursTag',
  props: {
    nbWeeks: {
      type: Number,
      required: true,
    },
    specificWeekIndex: {
      type: Number,
      default: -1, // -1 to distinguish from actual week indexes (>= 0)
    },
    size: {
      type: String,
      default: '',
    },
    shortenLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagClasses() {
      return {
        'sk-variable-contract-hours-tag': true,
        [`sk-variable-contract-hours-tag--${this.nbWeeks}`]: true,
        'sk-variable-contract-hours-tag--specific-week-index': this.specificWeekIndex > -1,
        'sk-variable-contract-hours-tag--small': this.size === 'small',
      };
    },
    tagText() {
      if (this.specificWeekIndex > -1) {
        return this.$t(`variable_contract_hours.specific_week_tag.${this.specificWeekIndex}`);
      }

      if (this.shortenLabel) {
        return this.$t(`variable_contract_hours.shorten_tag.${this.nbWeeks}`);
      }

      return this.$t(`variable_contract_hours.standard_tag.${this.nbWeeks}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-variable-contract-hours-tag {
  padding: 4px 9px;
  border-radius: 4px;
  font-size: $fs-text-s;
  font-weight: $fw-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 22px;
  width: fit-content;

  &--small {
    font-size: $fs-text-xs;
    height: 16px;
  }

  &--specific-week-index {
    width: 26px;
  }

  &--specific-week-index.sk-variable-contract-hours-tag--small {
    width: 19px;
  }

  &--2 {
    background: #fdbf3f4d;
    color: #fdbf3f;
  }

  &--3 {
    background: #459fff4d;
    color: #459fff;
  }

  &--4 {
    background: rgba($sk-blue-50, .4);
    color: $sk-blue-50;
  }

  &--5 {
    background: #1fbed54d;
    color: #1fbed5;
  }

  &--6 {
    background: #2e26f24d;
    color: #6c66f8;
  }

  &--7 {
    background: #d03e504d;
    color: $sk-error;
  }

  &--8 {
    background: #64ca6a4d;
    color: #64ca6a;
  }

  &--9 {
    background: #bdad9d4d;
    color: #9c7b58;
  }

  &--10 {
    background: #78aaff33;
    color: #78aaff;
  }

  &--11 {
    background: #98488f4d;
    color: #98488f;
  }

  &--12 {
    background: #93adbf4d;
    color: #8fa7b9;
  }

  &--13 {
    background: #f7da554d;
    color: #d4af03;
  }
}
</style>
