/**
 * @typedef {Object} OptionConfig
 * An Object to configure the output of the getOptions functions
 * @property {func(label: <string>)<string>} labelFormatter - a function to format the label
*/
const defaultConfig = {
  labelFormatter: label => label,
};

/**
 * Return type:
 * @typedef {Object} OptionElement
 * An Object suitable to use as SkOroraPopoverFilter input
 * properties listed here are not exhaustive but needed for the component to work
 * @property {number} id
 * @property {string} label
*/

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of employees.
 *
 * example sources:
 * - /v3/api/plannings/users
 * - /v3/api/leave_requests/managed_users
 *
 * @param {Object[]} employeeResponse
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsEmployees(employeeResponse, config = defaultConfig) {
  const { labelFormatter } = config;

  return employeeResponse.reduce((selectOptions, element) => {
    if (element.type === 'user') {
      selectOptions.push({
        id: element.id,
        label: labelFormatter(`${element.attributes.firstName} ${element.attributes.lastName}`),
      });
    }
    return selectOptions;
  }, []);
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of teams.
 *
 * example sources:
 * - /v3/api/teams
 *
 * @param {Object[]} teamsResponse
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsTeams(teamsResponse, config = defaultConfig) {
  const { labelFormatter } = config;

  return teamsResponse.reduce((selectOptions, element) => {
    if (element.type === 'team') {
      selectOptions.push({
        id: element.id,
        label: labelFormatter(element.attributes.name),
      });
    }
    return selectOptions;
  }, []);
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of postes.
 *
 * example sources:
 * - /v3/api/shops/${shopId}/postes
 *
 * @param {Object[]} postesResponse
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsPostes(postesResponse, config = defaultConfig) {
  const { labelFormatter } = config;

  return postesResponse.reduce((selectOptions, element) => {
    if (element.type === 'poste') {
      selectOptions.push({
        id: element.id,
        label: labelFormatter(element.attributes.name),
      });
    }
    return selectOptions;
  }, []);
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of absences.
 *
 * example sources:
 * - /v3/api/absences
 *
 * @param {Object[]} absencesResponse
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsAbsences(absencesResponse, config = defaultConfig) {
  const { labelFormatter } = config;

  return absencesResponse.reduce((selectOptions, element) => {
    if (element.type === 'poste') {
      selectOptions.push({
        id: element.id,
        label: labelFormatter(element.attributes.absenceKey, element.attributes.absencesCountry),
      });
    }
    return selectOptions;
  }, []);
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of statuses.
 *
 * example sources: [ 'pending', 'approved', 'rejected' ]
 *
 * @param {String[]} array of valid statuses
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsStatuses(statuses, config = defaultConfig) {
  const { labelFormatter } = config;

  return statuses.map(status => ({
    id: status,
    label: labelFormatter(status),
  }));
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of availability types.
 *
 * example sources: [ 'availability', 'unavailability' ]
 *
 * @param {String[]} array of valid availability types
 * @param {OptionConfig} [config=defaultConfig]
 *
 * @returns {(OptionElement[]|[])} selectOptions
 */
export function getOptionsAvailabilityTypes(availabilityTypes, config = defaultConfig) {
  const { labelFormatter } = config;

  return availabilityTypes.map(availabilityType => ({
    id: availabilityType,
    label: labelFormatter(availabilityType),
  }));
}

/**
 * Compute array of selectOptions suitable for usage with SkOroraPopoverFilter
 * from a backend formatted list of shops.
 * example sources:
 *
 * - /v3/api/leave_requests/shops
 ** @param {Object[]} shopsResponse - array of shops
  * @param {OptionConfig} [config=defaultConfig] - configuration object
  *
  * @returns {(OptionElement[]|[])} selectOptions
  */
export function getOptionsShops(shopsResponse, config = defaultConfig) {
  const { labelFormatter } = config;

  return shopsResponse.reduce((selectOptions, element) => {
    if (element.type === 'shop') {
      selectOptions.push({
        id: element.id,
        label: labelFormatter(element.attributes.name),
      });
    }
    return selectOptions;
  }, []);
}
