<template>
  <div v-if="isWorkloadPlanPanelOpen">
    <WorkloadPlanLegend
      :poste-id="posteId"
      :postes="postes"
      :is-loading="isWorkloadPlansLoading"
    />
  </div>
</template>

<script>
import WorkloadPlanLegend from './WorkloadPlanLegend';

export default {
  name: 'WorkloadPlanRightPanel',
  components: { WorkloadPlanLegend },
  props: {
    isWorkloadPlanPanelOpen: {
      type: Boolean,
      default: false,
    },
    posteId: {
      type: String,
      required: false,
      default: null,
    },
    postes: {
      type: Array,
      required: false,
      default: () => [],
    },
    isWorkloadPlansLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
