<template>
  <div class="report-rule-row__container">
    <SkForm
      :title="title"
      :last="last"
      split
    >
      <template #title>
        <label>{{ title }}</label>
      </template>
      <template
        v-if="description"
        #description
      >
        <label>{{ description }}</label>
      </template>
      <slot name="middleColumn" />
      <div
        v-if="typeof localMajoration !== 'undefined'"
        class="report-rule-row__majoration-block"
      >
        <div>
          {{ $t('shop_settings.tabs.report_rules.row.majoration') }}
        </div>
        <SkInputGroup
          :errored="erroredMajoration"
          :error-message="errorMajorationMessage"
          class="settings__report-rules__majoration-input"
        >
          <SkInput
            v-model="localMajoration"
            min="0"
            step="1"
            type="number"
          />
          <template #append>
            %
          </template>
        </SkInputGroup>
      </div>
    </SkForm>
  </div>
</template>

<script>
import { isPositiveInteger } from '@skello-utils/validators';

export default {
  name: 'ReportRuleRow',
  props: {
    title: {
      type: String,
      default: null,
    },
    last: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      localMajoration: this.value,
    };
  },
  computed: {
    erroredMajoration() {
      return !isPositiveInteger(this.localMajoration);
    },

    errorMajorationMessage() {
      if (!this.localMajoration) {
        return this.$t('shop_settings.tabs.report_rules.row.majoration_error_message.empty');
      }
      if (!isPositiveInteger(this.localMajoration)) {
        return this.$t('shop_settings.tabs.report_rules.row.majoration_error_message.not_number');
      }

      return null;
    },
  },
  watch: {
    localMajoration(newMajoration) {
      this.$emit('input', newMajoration);
    },
    value(newMajoration) {
      if (this.localMajoration === newMajoration) return;
      this.localMajoration = newMajoration;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-rule-row__container {
  .sk-form__header__title label {
    color: $sk-black;
    font-weight: bold;
  }

  .sk-form__header__description {
    margin-top: 3px;
  }

  .report-rule-row__majoration-block {
    width: 220px;
    margin-left: auto;
    display: flex;
    align-items: center;

    .report-rule-row__majoration-label {
      color: $sk-black;
      font-size: $fs-text-m;
      margin-right: 10px;
    }

    .settings__report-rules__majoration-input {
      width: 110px;
      margin-left: 20px;
    }
  }
}
</style>
