<template>
  <img
    width="42"
    src="./images/logo-zelty.png"
  >
</template>

<script>
export default {
  name: 'Zelty',
};
</script>
