<template>
  <div>
    <div class="optimization__postes_table">
      <HourRow :rows-count="rowsCount" />
      <Row
        v-for="(dayStructure, index) in posteStructure"
        :key="`hours-row-${index}`"
        :is-verification-panel="isVerificationPanel"
        :poste-id="posteId"
        :row-index="index"
        :label="dateLabel(index)"
        :day-structure="dayStructure"
      />
    </div>
    <div
      v-if="isVerificationPanel"
      class="poste-table__caption__wrapper"
    >
      <div
        v-for="key in verificationCaption"
        :key="key"
        class="poste-table__caption"
      >
        <div :class="`poste-table__color-bullet poste-table__color-bullet--${key}`" />
        <div class="poste-table__color-text">
          {{ $t(`optimization.sidebar.poste_table.caption.${key}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';
import Row from './Row';
import HourRow from './HourRow';

export default {
  name: 'PosteTable',
  components: {
    HourRow,
    Row,
  },
  props: {
    posteStructure: {
      type: Array,
      required: true,
    },
    posteId: {
      type: Number,
      required: true,
    },
    isVerificationPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      verificationCaption: ['optimal', 'overstaffed', 'understaffed'],
    };
  },
  computed: {
    rowsCount() {
      return this.posteStructure[0].length;
    },
  },
  methods: {
    dateLabel(dayIndex) {
      return capitalize(skDate().isoWeekday(dayIndex + 1).format('ddd'));
    },
  },
};
</script>

<style lang="scss" scoped>
.optimization__postes_table {
  overflow: auto;
  display: grid;
}

.poste-table__caption__wrapper {
  margin-top: 20px;
}

.poste-table__caption {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.poste-table__color-bullet {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-bottom: 2px;

  &--optimal {
    background-color: $sk-blue;
  }

  &--overstaffed {
    background-color: $sk-warning;
  }

  &--understaffed {
    background-color: $sk-error;
  }
}

.poste-table__color-text {
  flex-shrink: 0;
  line-height: 12px;
  font-size: $fs-text-s;
  color: $sk-grey;
}
</style>
