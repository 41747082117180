<template>
  <div class="comment_manager_container">
    <Comment
      v-for="(comment, index) in comments"
      :key="index"
      :employee-comment="comment"
    />
  </div>
</template>

<script>
import Comment from '@app-js/plannings/shared/components/ShiftActivities/Comments/Comment';

export default {
  name: 'CommentsManager',
  components: { Comment },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.comment_manager_container {
  width: 100%;
}
</style>
