<template>
  <!-- eslint-disable  max-len -->
  <div
    v-if="firstResultsLoaded"
    class="cluster-nodes-app-wrapper"
  >
    <h1 class="sk-header--1">
      {{ $t('organisation_settings.tabs.clusters.title') }}
    </h1>
    <div class="clusters__container__head">
      <p class="clusters__container__head__explanation sk-subtitle--large">
        {{ $t('organisation_settings.tabs.clusters.subtitle') }}
      </p>
    </div>
    <ToolBar
      :organisation-name="organisationName"
      :card-selected="!!currentCluster"
      :current-cluster="currentCluster"
      :toggle-rename-modal="toggleRenameModal"
      :toggle-move-modal="toggleMoveModal"
      :toggle-delete-modal="toggleDeleteModal"
      :user-can-delete-current-cluster="userCanDeleteCurrentCluster"
      :loading="loading"
      :finder-scrollable="finderScrollable"
    />
    <ClusterFinder
      :cluster-list="clusterList"
      :organisation-name="organisationName"
      :handle-click-on-card="handleClickOnCard"
      :current-cluster-id="currentClusterId"
      :toggle-drop-modal="toggleDropModal"
      :show-drop-modal="showDropModal"
      :move-card="moveCard"
      :toggle-create-modal="toggleCreateModal"
      :set-finder-scrollable="setFinderScrollable"
      :loading="loading"
    />
    <DeleteModal
      v-if="currentCluster && showDeleteModal"
      :current-cluster="currentCluster"
      :toggle-delete-modal="toggleDeleteModal"
      :toggle-move-modal="toggleMoveModal"
      :show-delete-modal="showDeleteModal"
      :remove-current-cluster="removeCurrentCluster"
      :move-children-to-new-parent-column="moveChildrenToNewParentColumn"
      :destroy-cluster-node-and-children="destroyClusterNodeAndChildren"
    />
    <MoveModal
      v-if="currentCluster && showMoveModal"
      :current-cluster="currentCluster"
      :toggle-move-modal="toggleMoveModal"
      :remove-current-cluster="removeCurrentCluster"
      :move-children-to-new-parent-column="moveChildrenToNewParentColumn"
      :fetch-children-list="fetchChildrenList"
      :move-all-children-and-destroy-parent="moveAllChildrenAndDestroyParent"
      :move-shop-or-all-children-with-parent="moveShopOrAllChildrenWithParent"
      :breadcrumb-list="moveModalBreadcrumbList"
      :parent="moveModalParent"
      :parent-children="moveModalParentChildren"
      :parent-cluster-node-store="moveModalParentClusterNodeStore"
      :loading="loadingMoveModalData"
      :only-move="onlyMove"
    />
    <RenameModal
      v-if="currentCluster && showRenameModal"
      :current-cluster="currentCluster"
      :show-rename-modal="showRenameModal"
      :toggle-rename-modal="toggleRenameModal"
      :rename-node="renameNode"
    />
    <CreateModal
      v-if="showCreateModal"
      :toggle-create-modal="toggleCreateModal"
      :create-node="createNode"
      :create-cluster-node-loading="createClusterNodeLoading"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import 'regenerator-runtime';
import smoothscroll from 'smoothscroll-polyfill';
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';

import ToolBar from './partials/ToolBar';
import DeleteModal from './partials/DeleteModal';
import RenameModal from './partials/RenameModal';
import CreateModal from './partials/CreateModal';
import MoveModal from './partials/MoveModal';
import ClusterFinder from './partials/ClusterFinder';

smoothscroll.polyfill();

export default {
  name: 'ClusterNodeTreeApp',
  components: {
    ToolBar,
    DeleteModal,
    RenameModal,
    CreateModal,
    MoveModal,
    ClusterFinder,
  },
  data() {
    return {
      firstResultsLoaded: false,
      loading: true,
      clusterList: [],
      currentCluster: null,
      organisationName: '',
      displayedModalName: null,
      moveModalBreadcrumbList: [],
      moveModalParent: null,
      moveModalParentChildren: [],
      moveModalParentClusterNodeStore: {},
      loadingMoveModalData: false,
      loadingDropModalData: false,
      onlyMove: null,
      creationParentId: null,
      finderScrollable: false,
      createClusterNodeLoading: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
    organisationId() {
      return this.currentOrganisation.id;
    },
    currentClusterId() {
      if (this.currentCluster) {
        return this.currentCluster.id;
      }
      return null;
    },
    showCreateModal() {
      return this.displayedModalName === 'CreateModal';
    },
    showRenameModal() {
      return this.displayedModalName === 'RenameModal';
    },
    showDeleteModal() {
      return this.displayedModalName === 'DeleteModal';
    },
    showDropModal() {
      return this.displayedModalName === 'DropModal';
    },
    showMoveModal() {
      return this.displayedModalName === 'MoveModal';
    },
    userCanDeleteCurrentCluster() {
      if (this.currentCluster) {
        return this.currentCluster.siblings_editable;
      }
      return false;
    },
    loadingClass() {
      return this.loading ? 'loading' : '';
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleClickOnCard(clickedItem) {
      if (this.loading) return;

      this.currentCluster = clickedItem;
      this.clusterList.find(
        cluster => cluster.children.includes(clickedItem),
      ).clickedItemId = clickedItem.id;
      this.clusterList = this.clusterList.filter(
        cluster => cluster.parentDepth < clickedItem.depth,
      );
      if (clickedItem.type === 'cluster_node' && (clickedItem.nb_nodes !== 0 || clickedItem.nb_shops !== 0)) {
        this.fetchData();
      }
    },
    setFinderScrollable(canScroll) {
      this.finderScrollable = canScroll;
    },
    removeCurrentCluster() {
      const parentClusterListIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === this.currentCluster.parent_id,
      );
      const parentCluster = this.findClusterInCurrentList(
        parentClusterListIndex - 1,
        this.currentCluster.parent_id,
      );
      const currentClusterList = this.clusterList[parentClusterListIndex];
      currentClusterList.children = currentClusterList.children.filter(
        child => child.id !== this.currentCluster.id,
      );

      parentCluster.nb_node -= 1;
      this.currentCluster = null;
      this.clusterList.pop();
    },
    moveChildrenToNewParentColumn(newParentId) {
      const currentClusterListIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === this.currentCluster.id,
      );
      const currentClusterList = this.clusterList[currentClusterListIndex];
      const newParentClusterListIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === newParentId,
      );
      if (newParentClusterListIndex === -1) { return false; }
      this.clusterList[newParentClusterListIndex].children =
        this.clusterList[newParentClusterListIndex].children.concat(currentClusterList.children);
      return true;
    },
    moveCurrentClusterToNewParentColumn(newParentId) {
      const currentClusterListIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === this.currentCluster.id,
      );
      const currentClusterList = this.clusterList[currentClusterListIndex];
      const newParentClusterListIndex = this.clusterList.findIndex(
        cluster => cluster.parentId === newParentId,
      );
      if (newParentClusterListIndex === -1) { return; }
      const currentClusterCopy = cloneDeep(this.currentCluster);
      currentClusterCopy.parent_id = newParentId;
      currentClusterCopy.depth = this.clusterList[newParentClusterListIndex].parentDepth + 1;
      this.clusterList[newParentClusterListIndex].children.push(currentClusterCopy);
    },
    findNewParentAndOpenIt(id, clusterToMove) {
      const list = this.clusterList.find(
        cluster => cluster.children.some(child => child.id === id),
      );
      const formerClickedLine = [];

      this.clusterList.forEach(column => {
        const clickedItemId = column.clickedItemId;
        const cardClicked = column.children.filter(
          child => child.id === clickedItemId,
        );
        formerClickedLine.unshift(cardClicked);
      });

      if (list) {
        list.clickedItemId = id;
        this.currentCluster = list.children.find(child => child.id === id);
        if (clusterToMove) {
          this.updateClustersInformation(clusterToMove, formerClickedLine.flat());
        }
        this.fetchData();
      }
    },
    updateClustersInformation(clusterToMove, formerClickedLine) {
      const target = this.currentCluster;
      if (clusterToMove.type === 'cluster_node') {
        target.nb_node += (clusterToMove.nb_node + 1);
        target.nb_shop += clusterToMove.nb_shop;
      } else if (clusterToMove.type === 'shop') {
        target.nb_shop += (clusterToMove.nb_shop + 1);
      }

      if (target.parent_id !== clusterToMove.parent_id) {
        let stopIteration = false;
        formerClickedLine.forEach(clickedCard => {
          if (!stopIteration) {
            if (clusterToMove.type === 'cluster_node') {
              clickedCard.nb_node -= (clusterToMove.nb_node + 1);
              clickedCard.nb_shop -= clusterToMove.nb_shop;
            } else if (clusterToMove.type === 'shop') {
              clickedCard.nb_shop -= (clusterToMove.nb_shop + 1);
            }
            if (clickedCard.parent_id === target.parent_id) {
              stopIteration = true;
            }
          }
        });
      }
    },
    findClusterInCurrentList(indexList, id) {
      return this.clusterList[indexList].children.find(
        cluster => cluster.id === id,
      );
    },
    multiColumnFindClusterById(id) {
      for (let index = 0; index < this.clusterList.length; index += 1) {
        const subClusterList = this.clusterList[index];
        if (subClusterList.children.length > 0) {
          const clusterFound = this.findClusterInCurrentList(index, id);
          if (clusterFound) {
            return clusterFound;
          }
        }
      }

      return null;
    },
    toggleCreateModal(event) {
      if (event) {
        const targetParentIdAttr = event.target.attributes.getNamedItem('parent-id');
        if (targetParentIdAttr) {
          this.creationParentId = targetParentIdAttr.value;
        } else {
          this.creationParentId = null;
        }
      }

      if (this.displayedModalName === 'CreateModal') {
        this.displayedModalName = null;
      } else {
        this.displayedModalName = 'CreateModal';
      }
    },
    toggleRenameModal() {
      if (this.displayedModalName === 'RenameModal') {
        this.displayedModalName = null;
      } else {
        this.displayedModalName = 'RenameModal';
      }
    },
    toggleDropModal() {
      if (this.displayedModalName === 'DropModal') {
        this.displayedModalName = null;
      } else {
        this.displayedModalName = 'DropModal';
      }
    },
    toggleDeleteModal() {
      if (!this.currentCluster) return;
      if (!this.currentCluster.siblings_editable) return;
      if (this.displayedModalName === 'DeleteModal') {
        this.displayedModalName = null;
      } else {
        this.displayedModalName = 'DeleteModal';
      }
    },
    toggleMoveModal(onlyMove) {
      this.onlyMove = onlyMove === 'onlyMove';
      if (this.displayedModalName === 'MoveModal') {
        this.displayedModalName = null;
      } else {
        this.fetchChildrenList(this.currentCluster.parent_id);
      }
    },
    createNode(nodeName) {
      if (!nodeName || nodeName.trim().length === 0) {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.clusters.toasts.errors.put_name'),
          variant: 'error',
        });
        return;
      }
      this.loading = true;
      this.createClusterNodeLoading = true;
      httpClient
        .post('/v3/api/cluster_nodes', {
          node_name: nodeName,
          parent_id: this.creationParentId,
          all_tree: true,
        })
        .then(response => {
          this.loading = false;
          this.displayedModalName = null;
          this.loadingDropModalData = false;
          this.loading = false;
          this.clusterList = this.parseDataToClusterList(response.data.tree);
          this.currentCluster = this.multiColumnFindClusterById(response.data.data.id);
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.success.add'),
            variant: 'success',
          });
          this.createClusterNodeLoading = false;
        })
        .catch(e => {
          this.loading = false;
          this.createClusterNodeLoading = false;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.errors.basic'),
            variant: 'error',
          });
        });
    },
    renameNode(newName) {
      if (!newName || newName.trim().length === 0) {
        this.$skToast({
          message: this.$t('organisation_settings.tabs.clusters.toasts.errors.put_name'),
          variant: 'error',
        });
        return;
      }
      this.loading = true;
      httpClient
        .patch('/v3/api/cluster_tree', {
          cluster_node_id: this.currentCluster.id,
          cluster_node_type: this.currentCluster.type,
          new_name: newName,
        })
        .then(response => {
          this.loading = false;
          const cluster = this.multiColumnFindClusterById(this.currentCluster.id);
          cluster.name = response.data.new_cluster.name;
          this.toggleRenameModal();
          // reload cluster navigation app with modified name
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.success.update_name'),
            variant: 'success',
          });
        })
        .catch(e => {
          this.loading = false;
          if (newName === '' || newName === undefined || newName === null) {
            this.$skToast({
              message: this.$t('organisation_settings.tabs.clusters.toasts.errors.put_name'),
              variant: 'error',
            });
          } else {
            this.$skToast({
              message: this.$t('organisation_settings.tabs.clusters.toasts.errors.basic'),
              variant: 'error',
            });
          }
        });
    },
    moveAllChildrenAndDestroyParent() {
      if (this.loadingMoveModalData) {
        return;
      }
      this.loading = true;
      this.loadingMoveModalData = true;
      httpClient
        .get('/v3/api/cluster_tree/move_and_destroy', {
          params: {
            cluster_node_ids: [this.currentCluster.id],
            new_parent_node_id: this.moveModalCurrentParentId,
          },
        })
        .then(response => {
          this.loading = false;
          this.loadingMoveModalData = false;
          this.toggleMoveModal();
          this.clusterList = this.parseDataToClusterList(response.data);
          this.currentCluster = this.multiColumnFindClusterById(this.moveModalCurrentParentId);
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.success.cluster_shop_move'),
            variant: 'success',
          });
        })
        .catch(e => {
          this.loading = false;
          this.loadingMoveModalData = false;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.errors.destroy'),
            variant: 'error',
          });
        });
    },
    async moveCard(clusterToMove, newParentId, newParentDepth, originParentId) {
      this.displayedModalName = null;
      this.loadingDropModalData = true;
      this.loading = true;
      httpClient
        .get('/v3/api/cluster_tree/move', {
          params: {
            cluster_node_ids: [clusterToMove.id],
            new_parent_node_id: newParentId,
          },
        })
        .then(response => {
          this.displayedModalName = null;
          this.loadingDropModalData = false;
          this.loading = false;
          this.clusterList = this.parseDataToClusterList(response.data);
          this.currentCluster = this.multiColumnFindClusterById(newParentId);
          this.$skToast({
            message: this.notifText(clusterToMove),
            variant: 'success',
          });
        })
        .catch(e => {
          this.loading = false;
          this.loadingDropModalData = false;
          this.$skToast({
            message: this.errorText(clusterToMove),
            variant: 'error',
          });
        });
    },
    moveShopOrAllChildrenWithParent() {
      if (this.loadingMoveModalData) {
        return;
      }
      this.loadingMoveModalData = true;
      this.loading = true;
      httpClient
        .get('/v3/api/cluster_tree/move', {
          params: {
            cluster_node_ids: [this.currentCluster.id],
            new_parent_node_id: this.moveModalCurrentParentId,
          },
        })
        .then(response => {
          this.loadingMoveModalData = false;
          this.loading = false;
          this.toggleMoveModal();
          this.clusterList = this.parseDataToClusterList(response.data);
          this.currentCluster = this.multiColumnFindClusterById(this.moveModalCurrentParentId);
          this.$skToast({
            message: this.notifText(this.currentCluster),
            variant: 'success',
          });
        })
        .catch(e => {
          this.loading = false;
          this.loadingMoveModalData = false;
          this.$skToast({
            message: this.errorText(this.currentCluster),
            variant: 'error',
          });
        });
    },
    parseDataToClusterList(data) {
      const newClusterList = [];
      data.forEach(dataColumn => {
        this.setOrganisationName(dataColumn);
        newClusterList.push(
          {
            parentDepth: dataColumn.parent_depth,
            children: dataColumn.children,
            parentId: dataColumn.parent_id,
            clickedItemId: dataColumn.cluster_path_id,
          },
        );
      });
      return newClusterList;
    },
    setOrganisationName(dataColumn) {
      if (this.organisationName === '' && dataColumn.organisation_name) {
        this.organisationName = dataColumn.organisation_name;
      }
    },
    fetchChildrenList(parentId) {
      if (this.loadingMoveModalData) {
        return;
      }
      this.loadingMoveModalData = true;
      this.moveModalCurrentParentId = parentId;
      httpClient
        .get('/v3/api/cluster_tree/children_list', {
          params: {
            parent_node_id: parentId,
            cluster_node_ids: [this.currentCluster.id],
          },
        })
        .then(response => {
          this.moveModalBreadcrumbList = response.data.breadcrumb_list;
          this.moveModalParent = response.data.parent;
          this.moveModalParentClusterNodeStore = response.data.parent_cluster_node_store;
          this.moveModalParentChildren = response.data.parent_children;
          this.loadingMoveModalData = false;
          this.displayedModalName = 'MoveModal';
        })
        .catch(e => {
          this.loadingMoveModalData = false;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.errors.load'),
            variant: 'error',
          });
        });
    },
    destroyClusterNodeAndChildren() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      httpClient
        .delete('/v3/api/cluster_tree', {
          data: {
            cluster_node_ids: [this.currentCluster.id],
          },
        })
        .then(response => {
          this.loading = false;
          this.toggleDeleteModal();
          this.clusterList = this.parseDataToClusterList(response.data);
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.success.destroy'),
            variant: 'success',
          });
        })
        .catch(e => {
          this.loading = false;
          this.toggleDeleteModal();
          this.loadingMoveModalData = false;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.errors.destroy'),
            variant: 'error',
          });
        });
    },
    fetchData() {
      if (this.loading && this.firstResultsLoaded) { return; }
      this.loading = true;
      httpClient
        .get('/v3/api/cluster_tree', {
          params: {
            cluster_node_id: this.currentClusterId,
          },
        })
        .then(response => {
          this.loading = false;
          this.clusterList = this.parseDataToClusterList(response.data);
          if (!this.firstResultsLoaded) {
            this.handleClickOnCard(this.clusterList[0].children[0]);
          }
          this.firstResultsLoaded = true;
          this.$nextTick(() => {
            if (this.finderScrollable) {
              this.$root.$emit('scroll', 'right');
            }
          });
        })
        .catch(e => {
          this.loading = false;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.clusters.toasts.errors.load'),
            variant: 'error',
          });
        });
    },
    notifText(clusterToMove) {
      return clusterToMove.type === 'shop' ?
        this.$t('organisation_settings.tabs.clusters.toasts.success.shop_move') :
        this.$t('organisation_settings.tabs.clusters.toasts.success.cluster_move');
    },
    errorText(clusterToMove) {
      return clusterToMove.type === 'shop' ?
        this.$t('organisation_settings.tabs.clusters.toasts.errors.shop_move') :
        this.$t('organisation_settings.tabs.clusters.toasts.errors.cluster_move');
    },
  },
};
</script>

<style lang="scss" scoped>
.cluster-nodes-app-wrapper {
  width: 100%;
}

.clusters__container__head {
  display: flex;
  position: relative;
}

.clusters__container__head__explanation {
  padding: 5px 0 0;
  color: $sk-grey;
}

.loading {
  cursor: wait;
}
</style>
