<template>
  <div
    v-if="automaticPlanningLoading"
    class="automatic-planning_spinner__wrapper"
  >
    <SkLoader size="x-large" />
    <div class="automatic-planning_spinner--text">
      {{ $t('plannings.table.loading.placeholder') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { SkLoader } from '@skelloapp/skello-ui';

export default {
  name: 'AutomaticPlanningLoader',
  components: {
    SkLoader,
  },
  computed: {
    ...mapState('planningsState', [
      'automaticPlanningLoading',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.automatic-planning_spinner__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8; // Need to go over all v3 planning blocs
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: $fs-text-m;
  color: $sk-blue;
  background-color: rgba($sk-grey-5, .8);
}

.automatic-planning_spinner--text {
  font-weight: 700;
  padding-top: 30px;
}
</style>
