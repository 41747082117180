<template>
  <div
    id="setting-details-container"
    ref="details_container"
    class="details-container"
  >
    <div class="setting-details">
      <div class="setting-details__header">
        <SkCircleButton
          icon="ArrowLeftIcon"
          @click="$router.push({ name: 'shop_settings_counter_rules' })"
        />
        {{ $t('shop_settings.tabs.details.back') }}
      </div>
      <div class="setting-details__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'SettingDetails',
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (vm.canManageShopRulesAndAbsences && vm.canPageBeAccessed) return;

      vm.$router.push({ name: 'shop_settings_counter_rules' });
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.shop_id !== from.params.shop_id) {
      next({ name: 'shop_settings_counter_rules' });
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters('currentLicense', ['canManageShopRulesAndAbsences']),
    ...mapGetters('currentShop', [
      'checkFeatureFlag',
      'isAnnualizedWorkingTimeAvailable',
      'isDevFlagEnabled',
    ]),
    ...mapState('currentShop', ['currentShop']),
    canPageBeAccessed() {
      return this.isAnnualizedWorkingTimeAvailable({ shop: this.currentShop }) &&
        this.currentShop.attributes.isAnnualizationV2Active &&
        this.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ADVANCED_PARAMETERS') &&
        this.isDevFlagEnabled('FEATUREDEV_ANNUALIZED_WORKING_TIME_V2_ADVANCED_PARAMETERS_PHASE2');
    },
  },
  mounted() {
    this.$refs.details_container.scrollIntoView();
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  display: flex;
  padding: 24px;
}

.setting-details {
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 8px 8px 0 0;
  background: $sk-white;
}

.setting-details__header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  color: $sk-black;
  font-size: $fs-text-m;
  font-weight: $fw-semi-bold;
  border-bottom: 1px solid $sk-grey-5;
}

.setting-details__content {
  width: 100%;
}
</style>
