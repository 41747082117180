<template>
  <SkModal
    id="no-users-or-postes-modal"
    ref="noUsersOrPostesModal"
    :display-footer="false"
    :dismissable="false"
  >
    <template #header>
      <div class="modal__header-img">
        <div class="modal__body-medallion">
          <SkMedallion />
        </div>
      </div>
    </template>
    <template #body>
      <div class="modal__body">
        <div class="modal__body-title">
          {{ modalTitle }}
        </div>
        <div class="modal__body-text">
          {{ $t('plannings.modal.no_users_or_postes.main_text') }}
        </div>
        <div class="modal__body-links">
          <div :class="usersLinkClass">
            <div class="modal__body__checkmark-background">
              <CheckMarkIcon
                :fill="usersCheckMarkColor"
                width="14px"
                height="10px"
              />
            </div>
            <router-link :to="addUsersUrl">
              {{ $t('plannings.modal.no_users_or_postes.link.add_users') }}
            </router-link>
          </div>
          <div :class="postesLinkClass">
            <div class="modal__body__checkmark-background">
              <CheckMarkIcon
                :fill="postesCheckMarkColor"
                width="14px"
                height="10px"
              />
            </div>
            <router-link :to="addPostesUrl">
              {{ $t('plannings.modal.no_users_or_postes.link.add_postes') }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>
import { SkMedallion } from '@app-js/shared/assets/svg/branding';

export default {
  name: 'NoUsersOrPostesModal',
  components: { SkMedallion },
  props: {
    shopId: {
      type: [String, Number],
      required: true,
    },
    hasShopUsers: {
      type: Boolean,
      required: true,
    },
    hasShopPostes: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modalTitle() {
      return this.hasShopUsers ?
        this.$t('plannings.modal.no_users_or_postes.title.no_postes') :
        this.$t('plannings.modal.no_users_or_postes.title.no_users');
    },
    usersCheckMarkColor() {
      return this.hasShopUsers ? 'white' : '#dddddd';
    },
    postesCheckMarkColor() {
      return this.hasShopPostes ? 'white' : '#dddddd';
    },
    usersLinkClass() {
      return {
        'modal__body-link-line': true,
        'modal__body-link-line--checked': this.hasShopUsers,
      };
    },
    postesLinkClass() {
      return {
        'modal__body-link-line': true,
        'modal__body-link-line--checked': this.hasShopPostes,
      };
    },
    addUsersUrl() {
      return {
        name: 'users',
        params: { shop_id: this.shopId },
        query: { show_new_employee_modal: true },
      };
    },
    addPostesUrl() {
      return {
        name: 'shop_settings_postes',
        params: { shop_id: this.shopId },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal {

  // Over write sk-modal_header for illustration banner on this kind of modal
  // (Same as onboardings modals)
  .sk-modal__header {
    padding: 0;
  }

  // Remove height: calc(100% - 119px) from Sk-modal to center modale with cover.
  // Deep seems not to be sufficient on safari
  .sk-modal {
    height: inherit !important;
  }
}

// Display toolbar over modal backdrop
#no-users-or-postes-modal {
  top: 50px;
}

.modal__body-medallion {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 64px 120px rgba(0, 0, 0, .1);
  right: 40%;
  top: 95px;
}

.modal__header-img {
  border-top-right-radius: 6px;
  z-index: 0;
  position: relative;
  width: 600px;
  height: 155px;
  background-image: url('../../assets/img/finish-account-creation-background.png');
  background-size: auto 155px;
}

.modal__body {
  padding: 80px 100px;
  margin-top: 40px;
  align-items: center;
}

.modal__body-title {
  margin-top: 10px;
  text-align: center;
  color: $sk-black;
  font-size: $fs-heading-xs;
}

.modal__body-text {
  margin: 22px 0 30px;
  text-align: center;
  color: $sk-grey;
  font-size: $fs-text-m;
}

.modal__body-links {
  padding-left: 110px;
}

.modal__body-link-line {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40px;
  margin-top: 8px;

  a {
    padding: 11.5px 8px;
    border-radius: 3px;
    text-decoration: none;
    color: $sk-blue;

    &:hover {
      text-decoration: none;
      background-color: $sk-blue-5;
      color: $sk-blue;
    }
  }

  .modal__body__checkmark-background {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $sk-grey-10;
  }

  &--checked {
    .modal__body__checkmark-background {
      background-color: $sk-blue;
    }

    a {
      text-decoration: line-through;
      cursor: default;
      pointer-events: none;

      &:hover {
        background-color: white;
      }
    }
  }
}
</style>
