<template>
  <SkModalV2
    id="doc-esignature-introduction-modal"
    ref="modal"
    :display-footer="false"
    :style="modalStyles"
    @hidden="handleCloseModal"
  >
    <template #header>
      <button
        class="close_button"
        @click="handleCloseButtonClick"
      >
        <ClosingXIcon
          width="10px"
          height="10px"
          fill="#FFFFF"
        />
      </button>
      <img
        class="skello_initial"
        src="@app-js/static/shared/assets/img/skello-initial.svg"
      >
    </template>
    <template #body>
      <div
        class="modal_wrapper"
      >
        <div class="white_card">
          {{ newMessage }}
        </div>
        <div class="content">
          <span class="title">{{ title }}</span>
          {{ bodyMessage }}
        </div>
      </div>
    </template>
  </SkModalV2>
</template>

<script>

import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

export default {
  name: 'DocEsignatureIntroductionModal',
  data: () => ({
    modalLeft: '',
    modalTop: '',
  }),
  computed: {
    newMessage() {
      return this.$t('employees.tabs.documents.esginature.introduction.new');
    },
    title() {
      return this.$t('employees.tabs.documents.esginature.introduction.title');
    },
    bodyMessage() {
      return this.$t('employees.tabs.documents.esginature.introduction.body');
    },
    modalStyles() {
      return {
        '--custom-top': this.modalTop,
        '--custom-left': this.modalLeft,
      };
    },
  },
  mounted() {
    this.$root.$on('open-doc-esignature-introduction-modal', this.handleOpenModal);
  },
  methods: {
    handleOpenModal(event, payload) {
      const { top, left } = payload;
      this.modalTop = top;
      this.modalLeft = left;
      this.$root.$emit(MODAL_SHOW_EVENT, event, 'doc-esignature-introduction-modal');
    },
    handleCloseButtonClick() {
      this.$root.$emit(MODAL_HIDE_EVENT, null, 'doc-esignature-introduction-modal');
    },
    handleCloseModal() {
      this.$root.$emit('docs-esignature-introduction-done', event);
    },
  },
};

</script>

<style lang="scss" scoped>
::v-deep .sk-modal {
  position: absolute;
  top: var(--custom-top);
  left: var(--custom-left);
  max-height: 100% !important;
  width: 300px !important;
  height: 187px !important;
  border-radius: 3px;
  overflow: hidden;

  .sk-modal__header__wrapper {
    position: relative;
    top: 16px;
    right: 16px;
  }
}

::v-deep .sk-icon-button {
  &:hover:not(.sk-icon-button--disabled) {
    background: $sk-white !important;
  }
}

::v-deep .sk-icon-button > path {
  &:hover:not(.sk-icon-button--disabled) {
    fill: $sk-blue !important;
  }
}

.close_button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  padding: 0;
  fill: $sk-white;
}

.close_button:hover {
  cursor: pointer;
}

.close_button:focus {
  outline: none;
  fill: $sk-blue;
  background-color: $sk-white;
  cursor: pointer;
}

.skello_initial {
  position: absolute;
  top: 16px;
  left: -28px;
}

.modal_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;
  height: 187px;
  padding: 24px;
  border-radius: 3px;
  color: $sk-white;
  background-color: $sk-blue;
}

.white_card {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: $sk-white;
  color: $sk-blue;
  font-size: $fs-text-s;
}

.content {
  font-size: $fs-text-m;

  .title {
    font-weight: bold;
  }
}

</style>
