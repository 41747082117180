<template>
  <div
    :class="cellClasses"
    @dblclick="handleCellEdition"
    @click="handleCellFocus"
  >
    <div :class="wrapperClasses">
      <input
        v-if="isEditing"
        ref="workloadPlanInputCell"
        v-model="cellValue"
        type="text"
        class="quarter-cell__input"
        :maxlength="3"
        @blur="onBlur"
        @keyup.enter="onEnter"
        @paste="onPaste"
        @keydown="validateInput($event)"
      >
      <div v-else>
        {{ displayedValue }}
      </div>
    </div>
  </div>
</template>

<script>
const MAX_CELL_VALUE = 200;

export default {
  name: 'QuarterHoursInputCell',
  props: {
    id: {
      type: String,
      required: true,
    },
    quarterValue: {
      type: Number,
      required: false,
      default: null,
    },
    isCreation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isFocused: false,
    };
  },
  computed: {
    cellValue: {
      get() {
        return this.quarterValue;
      },
      set(newValue) {
        this.$emit('on-quarter-value-change', Math.min(newValue, MAX_CELL_VALUE));
      },
    },
    cellClasses() {
      return {
        'quarter-hour-input-cell__container': true,
        'quarter-hour-input-cell__container--filled': this.hasCellValue || !this.isCreation,
      };
    },
    wrapperClasses() {
      return {
        'quarter-hour-input-cell__wrapper': true,
        'quarter-hour-input-cell__wrapper--editing': this.isFocused || this.isEditing,
      };
    },
    hasCellValue() {
      return !!this.cellValue;
    },
    displayedValue() {
      return this.hasCellValue ? this.cellValue : this.defaultValue;
    },
    defaultValue() {
      return this.isCreation ? '-' : 0;
    },
  },
  mounted() {
    // If another cell than the current one is focused,
    // We want to defocus this one
    this.listenOnRoot('focusing-cell', this.handleDynamicCellFocus);
  },
  methods: {
    handleDynamicCellFocus(id) {
      if (id !== this.id) this.isFocused = false;
    },
    handleCellFocus() {
      // Avoid focusing if we are trying to edit the cell
      if (this.isEditing) {
        this.isFocused = false;
        return;
      }

      this.isFocused = true;
      this.emitOnRoot('focusing-cell', this.id);
    },
    handleCellEdition() {
      // If already editing
      if (this.isEditing) return;

      this.isFocused = false;
      this.toggleEdition();
      this.$nextTick(() => {
        this.$refs.workloadPlanInputCell.focus();
      });
    },
    onEnter(event) {
      event.target.blur();
      event.stopPropagation();
    },
    onBlur() {
      this.toggleEdition();
    },
    onPaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^\d+$/.test(paste)) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    validateInput(event) {
      // Allow BackSpace - Delete - Left & Right Arrow
      if ([8, 46, 37, 39].includes(event.keyCode)) return true;
      if (this.isCopyPaste(event)) return true;

      // Block everything that is not numeric
      if (!event.key.match('^[0-9]+$')) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    isCopyPaste(event) {
      return (
        (event.keyCode === 86 || event.keyCode === 67) &&
        (event.ctrlKey === true || event.metaKey === true)
      );
    },
    toggleEdition() {
      this.isEditing = !this.isEditing;
    },
  },
};
</script>

<style scoped lang="scss">
.quarter-hour-input-cell__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $sk-grey-10;
  cursor: pointer;

  &--focused {
    border: 2px solid $sk-blue;
    outline-offset: 2px;
  }

  &--filled {
    color: $sk-black;
  }

  .quarter-cell__input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    text-align: center;
    font-size: $fs-text-m;
  }

  .quarter-hour-input-cell__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    user-select: none;

    &--editing {
      border: 2px solid $sk-blue;
    }
  }
}
</style>
