<template>
  <SkModalSection border-bottom="none">
    <h3>{{ $t('employees.add_employee_modal.affiliations.title') }}</h3>
    <div class="sk-subtitle--medium add-employee-modal__affiliation__subtitle">
      {{ $t('employees.add_employee_modal.affiliations.subtitle') }}
    </div>
    <SkSelect
      v-model="localActiveNodes"
      :ajax-client="ajaxClient"
      :ajax-format-params="clusterNodesAjaxFormatParams"
      :ajax-format-response="ajaxFormatResponse"
      :label="$t('employees.add_employee_modal.affiliations.employee_assignment')"
      :disabled-options="disabledOptions"
      :tracking-options="trackingOptions"
      :search-placeholder="$t('labels.search')"
      object-value
      ajax-url="/v3/api/cluster_nodes"
      width="350px"
      height="200px"
      append-to-body
      paginated
      select-all
      multi
      @close="cancelledShopNodeIds = []"
    >
      <template #selected-option="slotProps">
        {{ slotProps.value.length }}
        {{ $t('employees.add_employee_modal.affiliations.employee_assignment_append') }}
      </template>

      <template #empty-option>
        {{ $t('search_bar.no_result') }}
      </template>

      <template #select-all-label>
        {{ $t('employees.add_employee_modal.affiliations.select_all_shops') }}
      </template>

      <template #option="slotProps">
        <div
          v-tooltip.right-start="nodeHoverTooltip(slotProps.option)"
          class="shops-list__item"
        >
          <SkMedallion
            icon="ShopIcon"
            background-color="#d9e6ff"
            class="shops-list__item-icon"
            color="#2b66fe"
          />
          <div
            v-if="isPrimaryShop(slotProps.option)"
            class="shops-list__item-thumbtack"
          >
            <ThumbtackV2Icon
              width="12"
              height="12"
              fill=""
              class="shops-list__item-thumbtack__fill"
            />
          </div>
          <span>
            {{ slotProps.option.text }}
          </span>
        </div>
      </template>
    </SkSelect>
    <div
      v-if="isSystemAdmin"
      class="system-admin__card"
    >
      <div class="system-admin__card__icon">
        <CircledIIcon
          fill="#2b66fe"
          width="24"
          height="24"
        />
      </div>
      <div class="system-admin__card__explanation">
        {{ $t('employees.add_employee_modal.affiliations.system_admin_explanation.first_line') }}
        <br>
        {{ $t('employees.add_employee_modal.affiliations.system_admin_explanation.second_line') }}
      </div>
    </div>
    <SkTable
      :columns="headers"
      :class="headerTableDisplayClasses"
    >
      <NodeRow
        v-for="node in activeNodes"
        :key="node.id"
        :node="node"
        :user="newEmployee"
        :show-opti-cell="isAutomaticPlanningEnabled"
        type="shop"
        append-to-body
        @delete-row="handleDelete"
      />
    </SkTable>
  </SkModalSection>
</template>

<script>
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import {
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';

import { httpClient } from '@skello-utils/clients';
import NodeRow from '../NodeRow';

export default {
  name: 'AffiliationsStep',
  components: { NodeRow },
  data() {
    return {
      ajaxClient: () => httpClient,
      shopNodes: [],
      cancelledShopNodeIds: [],
    };
  },
  computed: {
    ...mapState('newEmployee', {
      userLicenses: state => state.newEmployee.relationships.userLicenses,
    }),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('currentUser', ['organisationLicenses']),
    ...mapState('newEmployee', ['newEmployee', 'activeNodes']),
    ...mapGetters('newEmployee', ['primaryLicense', 'isSystemAdmin']),
    headers() {
      const columns = [
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.shop') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.license') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.in_planning') },
        { title: this.$t('employees.add_employee_modal.affiliations.user_license_table.header.teams') },
      ];

      if (this.isAutomaticPlanningEnabled) { // CompetenciesInput is hidden if no shop has opti activated
        columns.push(
          {
            title: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.title'),
            icon: {
              icon: 'CircledQuestionMarkIcon',
              tooltip: this.$t('employees.tabs.personal_data.assignment.assignment_card.header.postes.tooltip'),
            },
          },
        );
      }

      columns.push({ title: null }); // Closing x column

      return columns;
    },
    isAutomaticPlanningEnabled() {
      return !!this.activeNodes.find(node => node.attributes.canAccessAutomaticPlanning);
    },
    headerTableDisplayClasses() {
      return {
        'add-employee-modal__affiliation-table': !this.isAutomaticPlanningEnabled,
        'add-employee-modal__affiliation-table--small': this.isAutomaticPlanningEnabled,
      };
    },
    emailFilledIn() {
      return !!this.newEmployee.attributes.email;
    },
    disabledOptions() {
      return this.unavailableNodeIdsForLicense.concat(this.cancelledShopNodeIds);
    },
    unavailableNodeIdsForLicense() {
      const userLicense = this.newEmployee.relationships.userLicenses.find(ul => (
        ul.attributes.shopId === String(this.newEmployee.attributes.shopId)
      ));

      if (!userLicense || userLicense.length < 1) return [];

      return [userLicense.attributes.clusterNodeId];
    },

    localActiveNodes: {
      get() {
        return this.activeNodes.map(activeNode => ({
          id: activeNode.id,
          text: activeNode.attributes.name,
          attributes: {
            shopId: activeNode.attributes.shopId,
            name: activeNode.attributes.name,
            parentId: activeNode.attributes.parentId,
            depth: activeNode.attributes.depth,
            ancestry: activeNode.attributes.ancestry,
            automaticPlanningUses: activeNode.attributes.automaticPlanningUses,
            automaticPlanningQuota: activeNode.attributes.automaticPlanningQuota,
          },
        }));
      },

      set(newNodes) {
        const nodes = newNodes.map(node => ({
          id: node.id,
          attributes: {
            shopId: node.attributes.shopId,
            name: node.attributes.name,
            parentId: node.attributes.parentId,
            depth: node.attributes.depth,
            ancestry: node.attributes.ancestry,
            automaticPlanningUses: node.attributes.automaticPlanningUses,
            automaticPlanningQuota: node.attributes.automaticPlanningQuota,
          },
        }));

        const lowestLicense =
          last(orderBy(this.organisationLicenses, 'attributes.position'));

        this.addActiveNodes({ nodes, lowestLicense });
        this.removeActiveNodes(nodes);
      },
    },
    trackingOptions() {
      return {
        cancel: 'add_employee_cancel_shop',
        submit: 'add_employee_validate_shop',
      };
    },
  },
  methods: {
    ...mapMutations('newEmployee', ['addActiveNodes', 'removeActiveNodes']),
    isPrimaryShop(option) {
      const userLicense = this.newEmployee.relationships.userLicenses.find(ul => (
        ul.attributes.shopId === String(this.newEmployee.attributes.shopId)
      ));

      return option.id === userLicense.attributes.clusterNodeId;
    },
    clusterNodesAjaxFormatParams(params) {
      return {
        can_create_employee: true,
        shop_level: true,
        configured: true,
        ...params,
      };
    },
    ajaxFormatResponse(response) {
      this.shopNodes = response.data.map(clusterNode => ({
        id: clusterNode.id,
        text: clusterNode.attributes.name,
        attributes: {
          name: clusterNode.attributes.name,
          shopId: clusterNode.attributes.shopId ? String(clusterNode.attributes.shopId) : null,
          shopCancelled: clusterNode.attributes.shopCancelled,
          organisationId: String(clusterNode.attributes.organisationId),
          parentId: String(clusterNode.attributes.parentId),
          depth: clusterNode.attributes.depth,
          ancestry: clusterNode.attributes.ancestry,
          editable: clusterNode.attributes.editable,
          ancestorIds: clusterNode.attributes.ancestorIds,
          automaticPlanningUses: clusterNode.attributes.automaticPlanningUses,
          automaticPlanningQuota: clusterNode.attributes.automaticPlanningQuota,
        },
      }));

      this.cancelledShopNodeIds = [
        ...this.cancelledShopNodeIds,
        ...this.shopNodes.filter(node => node.attributes.shopCancelled)
          .map(node => node.id),
      ];

      return this.shopNodes;
    },
    handleDelete(nodeToDelete) {
      const nodes = this.activeNodes.filter(node => (
        node.id !== nodeToDelete.id
      ));

      this.removeActiveNodes(nodes);
    },
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
  },
};
</script>
<style lang="scss">
.system-admin__card {
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid $sk-grey-10;
  font-size: $fs-text-m;
  color: $sk-grey;
  display: flex;
  justify-content: flex-start;
  padding: 10px 70px 10px 10px;
}

.system-admin__card__icon {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 20px;
}

.add-employee-modal__affiliation__subtitle {
  width: 550px;
  margin-bottom: 16px;
}

.add-employee-modal__affiliation-table,
.add-employee-modal__affiliation-table--small {
  margin-top: 20px;

  th {
    padding: 0 !important;
  }

  .table-column__label {
    line-height: 1.2em;
    padding-bottom: 4px;
  }
}

.add-employee-modal__affiliation-table {
  th:nth-child(4),
  th:nth-child(5) {
    padding-left: 30px !important;

    &:last-child {
      padding-left: 0 !important;
    }
  }
}

.shops-list__item {
  display: flex;
  position: relative;
  align-items: center;
}

.shops-list__item-icon {
  margin-right: 15px;
}

.shops-list__item-thumbtack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $sk-white;
  background-color: $sk-blue-5;
}

.shops-list__item-thumbtack__fill {
  fill: $sk-blue;
}
</style>
