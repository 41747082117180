import AdminOnboarding from './AdminOnboarding';

const adminOnboardingRoutes = [
  {
    path: '/v3/shops/:shop_id/admin-onboarding',
    component: AdminOnboarding,
    name: 'admin_onboarding',
  },
];

export default adminOnboardingRoutes;
