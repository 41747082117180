<template>
  <img
    src="./images/silae-employee-info.svg"
    alt="Illustration of realtime employee information transfert for Silae"
    width="255px"
    height="160px"
  >
</template>

<script>
export default {
  name: 'SilaeEmployeeInfo',
};
</script>
