import i18n from '@plugins/i18n';

export const CONTRACT_TYPES_COUNTRIES = ['fr', 'es', 'de', 'gb', 'be', 'ch'];

export const getCountryForContractTypes = country => {
  if (CONTRACT_TYPES_COUNTRIES.includes(country)) return country;

  return 'fr';
};

export const getContractTypeTranslationsObject = translations => {
  const contractTypeTranslations = CONTRACT_TYPES_COUNTRIES.map(country => translations[country]);

  return Object.assign({}, ...contractTypeTranslations);
};

export const getContractTypeI18nKey = (country, key) => {
  const parsedCountry = country.toLowerCase();
  const parsedKey = key.toLowerCase();
  return `contract_types.keys.${parsedCountry}.${parsedKey}`;
};

export const getContractTypeKeyText = (country, key) => {
  const i18nKey = getContractTypeI18nKey(country, key);
  return i18n.t(i18nKey);
};
