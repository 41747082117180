<template>
  <!--
    this component is mostly a wrapper of SkOroraButton and SkDropdown
    hence the use of $attrs to made them fall-through,
    allowing the use of only specific wrapper-related props
  -->
  <SkDropdown
    :ref="dropdownRef"
    v-bind="$attrs"
    :y-offset="$attrs['y-offset'] || '2'"
    @hide-dropdown="handleHide"
    @show-dropdown="handleShow"
  >
    <template #anchor>
      <div
        ref="buttonAnchor"
        class="dropdown-button"
      >
        <SkOroraButton
          v-tooltip:[buttonTooltipModifiers]="$attrs.disabled ? buttonTooltip : undefined"
          v-bind="$attrs"
          variant="secondary"
          v-on="items.length === 1 ? { click: items[0].callback } : {}"
        >
          <div class="dropdown-button__content">
            <slot />
            <CaretDownV2Icon
              v-if="showCaret && !$attrs.spinner && items.length > 1"
              :fill="$attrs.disabled ? '#b0b0b0' : undefined"
              :class="{
                'dropdown-button__caret': true,
                'dropdown-button__caret--down': isOpen,
                'dropdown-button__caret--up': !isOpen,
              }"
            />
          </div>
        </SkOroraButton>
      </div>
    </template>
    <template #menu>
      <div
        v-if="!$attrs.spinner && items.length > 1"
        ref="dropdownContent"
        class="dropdown-content"
        :style="dropdownStyle"
      >
        <div
          v-for="(item, index) in items"
          :key="`${itemKey}-${item.id || index}`"
          v-tooltip:[itemsTooltipModifiers]="item.disabled ? item.tooltip : undefined"
          v-track="item.tracker"
          :data-test="item.dataTest"
          :class="{
            'dropdown-content__item': true,
            'dropdown-content__item--disabled': item.disabled,
          }"
          @click.stop="handleClick(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    buttonTooltip: {
      type: String,
      default: null,
    },
    buttonTooltipModifiers: {
      type: Array,
      default: null,
    },
    dropdownRef: {
      type: String,
      default: 'dropdownButton',
    },
    itemKey: {
      type: String,
      default: 'button-dropdown-item',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsTooltipModifiers: {
      type: Array,
      default: null,
    },
    showCaret: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      anchorWidth: null,
      dropdownWidth: null,
      isOpen: false,
    };
  },
  computed: {
    dropdownStyle() {
      return this.anchorWidth > this.dropdownWidth ? { width: `${this.anchorWidth}px` } : {};
    },
  },
  methods: {
    handleHide() {
      this.isOpen = false;
    },
    handleShow() {
      this.isOpen = true;

      if (!this.anchorWidth) {
        this.anchorWidth = this.$refs.buttonAnchor.clientWidth;
      }

      if (!this.dropdownWidth) {
        this.dropdownWidth = this.$refs.dropdownContent.clientWidth;
      }
    },
    handleClick(item) {
      if (item.disabled) return;

      item.callback();
      this.hideDropdown();
    },
    hideDropdown() {
      this.$refs[this.dropdownRef].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  background-color: $sk-white;
}

.dropdown-button__content {
  display: flex;
  align-items: center;
}

.dropdown-button__caret {
  margin-left: 8px;

  &--down {
    transform: rotate(180deg);
    transition: transform .3s;
  }

  &--up {
    transform: rotate(0deg);
    transition: transform .3s;
  }
}

.dropdown-content {
  background: $sk-white;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .16);
  border-radius: 2px;
  padding: 8px 0;
}

.dropdown-content__item {
  padding: 10px 15px;
  color: $sk-black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-content__item:hover {
  background: $sk-grey-10;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-content__item--disabled {
  color: $sk-grey-30;
}

.dropdown-content__item--disabled:hover {
  background: $sk-grey-5;
  cursor: default;
}

</style>
