<template>
  <div :class="containerClasses">
    {{ formatedCellValue }}
  </div>
</template>

<script>
export default {
  name: 'VerificationCell',
  props: {
    hourValue: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    containerClasses() {
      const { previsionnal, real } = this.hourValue;
      return {
        'opti-cell__container': true,
        'opti-cell__container--optimal': previsionnal === real,
        'opti-cell__container--overstaffed': previsionnal < real,
        'opti-cell__container--understaffed': previsionnal > real,
      };
    },
    formatedCellValue() {
      const { previsionnal, real } = this.hourValue;
      if (previsionnal === real) {
        return previsionnal;
      }

      return `${real}/${previsionnal}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.opti-cell__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  cursor: pointer;
  color: $sk-black;
  background: $sk-grey-5;

  &--optimal {
    color: $sk-blue;
  }

  &--overstaffed {
    color: $sk-warning;
  }

  &--understaffed {
    color: $sk-error;
  }
}
</style>
