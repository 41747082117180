<template>
  <!-- eslint-disable -->
  <div class="employee-documents--empty">
    <svg width="205" height="151" viewBox="0 0 307 227" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M271 117C271 147.037 258.958 174.267 239.441 194.117C219.484 214.415 191.71 227 161 227C130.286 227 102.511 214.411 82.5541 194.112C63.0373 174.263 51 147.037 51 117C51 86.9631 63.0373 59.7373 82.5541 39.8879C91.9647 30.3157 103.113 22.4584 115.487 16.8282C129.356 10.5157 144.765 7 161 7C221.751 7 271 56.2486 271 117Z"
        fill="#BAD3FE" fill-opacity="0.2" />
      <path
        d="M207.258 84.4043H158.872L149.298 71H114.317C112.486 71 111 72.484 111 74.3166V156.136C111.132 156.14 111.264 156.148 111.393 156.155L130.57 103.852C130.923 102.544 132.108 101.638 133.462 101.638H210.575V87.7209C210.575 85.8883 209.089 84.4043 207.258 84.4043Z"
        fill="#2b66fe" />
      <path
        d="M199.706 161H113.997C112.025 161 110.59 159.128 111.106 157.222L130.572 103.852C130.925 102.546 132.11 101.639 133.464 101.639H219.173C221.145 101.639 222.064 103.523 222.064 105.417L202.597 158.787C202.245 160.093 201.06 161 199.706 161Z"
        fill="#B1CEFE" />
      <rect x="269.362" y="13" width="2.55319" height="17.0213" rx="1.2766" fill="#D2D7DC" />
      <rect x="279.149" y="20.2344" width="2.55319" height="17.0213" rx="1.2766" transform="rotate(90 279.149 20.2344)"
        fill="#D2D7DC" />
      <rect x="7.23438" y="93" width="2.55319" height="17.0213" rx="1.2766" fill="#D2D7DC" />
      <rect x="17.0215" y="100.234" width="2.55319" height="17.0213" rx="1.2766" transform="rotate(90 17.0215 100.234)"
        fill="#D2D7DC" />
      <rect x="82.9785" y="175.553" width="2.55319" height="17.0213" rx="1.2766" fill="#D2D7DC" />
      <rect x="92.7656" y="182.787" width="2.55319" height="17.0213" rx="1.2766" transform="rotate(90 92.7656 182.787)"
        fill="#D2D7DC" />
      <circle cx="66.2128" cy="10.2128" r="8.71277" stroke="#D2D7DC" stroke-width="3" />
      <circle cx="296.17" cy="126.191" r="8.71277" stroke="#D2D7DC" stroke-width="3" />
    </svg>
    <p class="sk-subtitle--medium employee-documents__empty-message">
      {{ $t('employees.tabs.documents.no_visible_documents') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'NoVisibleDocuments',
};
</script>

<style lang="scss" scoped>
.employee-documents--empty {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;

  p.employee-documents__empty-message {
    margin: 30px 0 0 30px;
  }
}</style>
