<template>
  <div
    v-if="initializationDate"
    class="employee-counters__initialization-date-wrapper"
  >
    <div class="employee-counters__initialization-date-title">
      {{ $t('employees.tabs.counters.hours_counter.initialization.title') }}
    </div>
    <div
      :class="initializationDateValueClasses"
      @click="handleUpdateInitCounter"
    >
      <div>
        {{ initializationDate }}
      </div>
      <div
        v-if="canEditInitDate"
        class="employee-counters__initialization-date-value_icon"
      >
        <SkCircleButton
          icon="PencilV2Icon"
          width="20px"
          height="20px"
        />
      </div>
    </div>
  </div>
</template>
<script>
import skDate from '@skello-utils/dates';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { mapState } from 'vuex';

export default {
  name: 'InitDateLabel',
  props: {
    initDate: {
      type: Object,
      default: () => null,
    },
    tracker: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('selectedEmployee', ['employee']),

    initializationDateValueClasses() {
      return {
        'employee-counters__initialization-date-value': true,
        'employee-counters__initialization-date-value--clickable': this.canEditInitDate,
      };
    },
    canEditInitDate() {
      return this.currentLicense.attributes.canEditEmployeeInfo &&
        this.currentLicense.attributes.canEditShopRulesAndAbsences;
    },
    initializationDate() {
      if (this.tracker === 'pto') {
        return this.initDate ? skDate(this.initDate).format('DD/MM/YYYY') : '';
      }

      const initializationDoneAt = this.employee.attributes.counterInitializationDoneAt;

      if (initializationDoneAt) {
        return skDate(initializationDoneAt).format('DD/MM/YYYY');
      }

      return '';
    },
  },
  methods: {
    handleUpdateInitCounter(event) {
      if (!this.canEditInitDate) return;
      const modalToOpen = this.tracker === 'pto' ? 'init-paid-leaves-counter-modal' : 'init-hours-counter-modal';
      this.emitOnRoot(MODAL_SHOW_EVENT, event, modalToOpen);
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-counters__initialization-date-wrapper {
  align-items: center;
}

.employee-counters__initialization-date-title {
  font-weight: $fw-semi-bold;
}

.employee-counters__initialization-date-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;

  &--clickable {
    cursor: pointer;
  }
}

.employee-counters__initialization-date-value_icon {
  padding: 0 6px;
}
</style>
