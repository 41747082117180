<template>
  <!-- eslint-disable  max-len -->
  <div class="employee-counters__frozen-column">
    <div class="employee-counters__initialization-date-wrapper">
      <InitDateLabel tracker="hours" />
    </div>
    <div class="employee-counters__cell">
      {{ $t('employees.tabs.counters.hours_counter.hours_worked_and_planned_absence') }}
    </div>
    <div class="employee-counters__cell">
      <div>
        {{ $t('employees.tabs.counters.hours_counter.absences_off_counter') }}
        <SkOroraButton
          variant="tertiary"
          size="small"
          data-seg="openListAbsencesHoursCounter"
          @click="showAbsencePanel"
        >
          {{ $t('employees.tabs.counters.hours_counter.absence_types') }}
        </SkOroraButton>
      </div>
    </div>
    <div class="employee-counters__cell">
      {{ $t('employees.tabs.counters.hours_counter.weekly_contract_hours') }}
    </div>
    <div
      v-if="showVariableContractHoursLine"
      class="employee-counters__cell employee-counters__hours-tag__cell"
    >
      {{ $t('employees.tabs.counters.hours_counter.cyclic_contract_hours') }}
    </div>
    <div
      v-if="majoration"
      class="employee-counters__cell"
    >
      <div>
        {{ $t('employees.tabs.counters.hours_counter.over_hours_with_majoration') }}
        <br>
        <span class="employee-counters__table-cell-subtitle">
          ({{ $t('employees.tabs.counters.hours_counter.over_hours_total') }})
        </span>
      </div>
    </div>
    <div class="employee-counters__cell employee-counters__cell--bold">
      {{ $t('employees.tabs.counters.hours_counter.hours_counter_before_manual_changes') }}
    </div>
    <div class="employee-counters__cell">
      {{ $t('employees.tabs.counters.hours_counter.manual_changes') }}
    </div>
    <div class="employee-counters__cell employee-counters__cell--bold">
      {{ $t('employees.tabs.counters.hours_counter.hours_counter_after_manual_changes') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpClient } from '@skello-utils/clients';

import { InitDateLabel } from '../shared/components';

export default {
  name: 'HoursCounterTitlesColumn',
  components: { InitDateLabel },
  props: {
    showVariableContractHoursLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('employeeCounters', ['majoration']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('selectedEmployee', ['employee']),
  },
  methods: {
    async showAbsencePanel(event) {
      try {
        const shop = await this.getShop();
        this.$skAnalytics.track('absence_side_bar_show');
        this.$root.$emit('open-absences-panel', { shop });
      } catch (error) {
        this.$skToast({
          message: this.$t('alert_messages.default'),
          variant: 'error',
        });
      }
    },

    async getShop() {
      if (this.currentShop?.id === 'all') {
        const response =
          await httpClient.get(`/v3/api/shops/${this.employee.attributes.shopId}/show_extended`);

        return response.data.data;
      }

      return this.currentShop;
    },
  },
};
</script>
<style lang="scss" scoped>
.employee-counters__hours-tag__cell {
  justify-content: space-between;
}

.employee-counters__hours-tag {
  margin-right: 17px;
}

.employee-counters__initialization-date-wrapper {
  display: flex;
  height: 172px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $sk-black;
}
</style>
