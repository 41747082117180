<template>
  <SkOroraDialog
    id="tiller-authentication-modal"
    ref="tillerAuthenticationModal"
    size="medium"
    :is-submit-disabled="isSubmitDisabled"
    :is-submit-loading="isTillerAuthenticating"
    :submit-button-label="$t('organisation_settings.tabs.pos_partners.tiller.modal.submit_label')"
    :title="$t('organisation_settings.tabs.pos_partners.tiller.modal.title')"
    @cancel="handleCancel"
    @close="handleCancel"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <main class="tiller-authentication-modal">
        <section class="tiller-authentication-modal__inputs-container">
          <SkInput
            v-model="tillerLogin"
            :label="$t('organisation_settings.tabs.pos_partners.tiller.modal.login')"
            :errored="hasAuthenticationFailed"
          />
          <SkInput
            v-model="tillerPassword"
            :label="$t('organisation_settings.tabs.pos_partners.tiller.modal.password')"
            :errored="hasAuthenticationFailed"
            :error-message="$t('organisation_settings.tabs.pos_partners.tiller.modal.errors.authentication')"
            type="password"
            @keyup.enter="handleSubmit"
          />
        </section>
        <section class="tiller-authentication-modal__description">
          <p class="sk-text-medium-regular">
            {{ $t('organisation_settings.tabs.pos_partners.tiller.modal.description') }}
          </p>
          <p class="sk-text-medium-semibold">
            {{ $t('organisation_settings.tabs.pos_partners.tiller.modal.sub_description') }}
          </p>
        </section>
      </main>
    </template>
  </SkOroraDialog>
</template>

<script>
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'TillerAuthenticationModal',
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasAuthenticationFailed: false,
      isTillerAuthenticating: false,
      tillerLogin: '',
      tillerPassword: '',
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.isUserTyping || this.isPasswordEmpty || this.isLoginEmpty;
    },
    isPasswordEmpty() {
      return !this.tillerPassword || this.tillerPassword === '';
    },
    isLoginEmpty() {
      return [null, undefined, ''].includes(this.tillerLogin);
    },
  },
  methods: {
    handleSubmit() {
      if (this.isSubmitDisabled) return;

      this.isTillerAuthenticating = true;
      this.hasAuthenticationFailed = false;

      httpClient.patch('/v3/api/integrations/tiller/link_shop', {
        updated_shop_id: this.shop.id,
        tiller_login: this.tillerLogin,
        tiller_password: this.tillerPassword,
      })
        .then(() => {
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.tiller.modal.link.success'),
            variant: 'success',
          });
          this.$emit('success');
          this.closeModal();
        })
        .catch(() => {
          this.hasAuthenticationFailed = true;
          this.$skToast({
            message: this.$t('organisation_settings.tabs.pos_partners.tiller.modal.authenticate_error'),
            variant: 'error',
          });
        })
        .finally(() => {
          this.isTillerAuthenticating = false;
        });
    },
    handleCancel() {
      this.resetFields();
      this.$emit('cancel');
    },
    openModal() {
      this.$refs.tillerAuthenticationModal.show();
    },
    closeModal() {
      this.resetFields();
      this.$refs.tillerAuthenticationModal.hide();
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="scss" scoped>
.tiller-authentication-modal {
  padding: 8px 24px;

  &__description,
  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__description {
    margin-top: 24px;

    p {
      margin: 0;
    }
  }
}
</style>
