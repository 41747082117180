<template>
  <tr class="request__row">
    <td
      v-if="isAllRequestsView"
      ref="shopNameLabel"
      class="request__shopname-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="shopNameTooltip">{{ requestShopName }}</span>
    </td>
    <td
      v-if="columns.duration.active"
      ref="durationLabel"
      class="request__duration-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="durationTooltip">{{ requestDuration }}</span>
    </td>
    <td
      v-if="columns.dates.active"
      ref="datesLabel"
      class="request__dates-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="datesTooltip">{{ displayDate }}</span>
    </td>
    <td
      v-if="columns.type.active"
      ref="typeLabel"
      class="request__type-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="typeTooltip">{{ absenceName }}</span>
    </td>
    <template v-if="displayValidatorColumn">
      <template v-if="useSvcRequests">
        <td
          ref="managerLabel"
          class="request__manager-row"
          @click="openManageLeaveRequestModal(request)"
        >
          <span v-tooltip="managerTooltip">
            <template v-if="request.attributes.status === 'pending'">
              {{ receiverName }}
            </template>
            <template v-else>
              {{ validatorName }}
            </template>
          </span>
        </td>
      </template>
      <template v-else>
        <td
          v-if="request.attributes.status !== 'pending'"
          ref="managerLabel"
          class="request__manager-row"
          @click="openManageLeaveRequestModal(request)"
        >
          <span v-tooltip="managerTooltip">
            {{ managerName }}
          </span>
        </td>
        <td v-else>
          -
        </td>
      </template>
    </template>
    <td
      v-if="columns.sent_at.active"
      ref="askedLabel"
      class="request__asked-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="askedTooltip">{{ askedDate }}</span>
    </td>
    <td
      v-if="columns.status.active"
      class="request__status-row"
      @click="openManageLeaveRequestModal(request)"
    >
      <span v-tooltip="statusTooltip">
        <SkTag :variant="statusVariant">
          {{ $t('requests.statuses.' + request.attributes.status) }}
        </SkTag>
      </span>
    </td>
    <td class="request__actions-row">
      <div class="request__actions-row--button">
        <DropdownCircleButton
          placement="left-start"
          size="small"
          trigger="click"
          :button-tooltip="actionButtonTooltip"
          :button-tooltip-modifiers="['left']"
          :icon="actionButtonIcon"
          :icon-color="actionButtonColor"
          :items="actionItems"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import DropdownCircleButton from '@app-js/shared/components/DropdownCircleButton';

import RowMixins from '@app-js/requests/mixins/RowMixins';

export default {
  name: 'LeaveRequestsOwnRow',
  components: { DropdownCircleButton },
  mixins: [RowMixins],
};
</script>
<style lang="scss" scoped>
  .request__row {
    &:hover {
      background: $sk-blue-5;
    }

    td {
      padding: 14.5px 8px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
