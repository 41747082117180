<template>
  <div
    v-if="canAccessContractsTab"
    class="contracts__content-wrapper"
  >
    <CurrentContract />

    <!-- Past & future contracts table -->
    <div class="employees__content-wrapper">
      <div class="contracts-index__wrapper">
        <div class="contracts-index__fixed-inner">
          <div class="contracts-index__tabs-navigation">
            <div class="contracts-index__header">
              <router-link :to="{ name: 'user_contracts_history' }">
                <div class="contracts-index__header-tab">
                  {{ $t('employees.tabs.contracts.table.tabs.history.title') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'user_contracts_future' }">
                <div class="contracts-index__header-tab-future">
                  <div class="contracts-index__header-tab">
                    {{ $t('employees.tabs.contracts.table.tabs.future.title') }}
                  </div>
                  <div
                    v-if="isMulticontractsEnabled && hasAtLeastOneFutureContract"
                    class="contracts-index__header-tab__notification-dot"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <div>
            <router-view :key="$route.path" />
          </div>
        </div>
        <div
          v-if="isMulticontractsEnabled && !loading && pagination.total_pages > 1"
          class="contracts-index__pagination__wrapper"
        >
          <div class="contracts-index__pagination__container">
            <SkPaginate
              v-model="pagination.current_page"
              :total-pages="pagination.total_pages"
              @new-page="handleNewPage"
            />
          </div>
        </div>
      </div>
    </div>

    <MountingPortal
      mount-to="#modals-portal"
      append
    >
      <create-text-document-modal apply-on-selected-contract />
      <duplicate-contract-modal />
      <CreateVersionModal />
    </MountingPortal>
  </div>
</template>
<script>

import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import CurrentContract from './components/CurrentContract';
import CreateTextDocumentModal from '../../shared/components/CreateTextDocumentModal';
import DuplicateContractModal from './components/DuplicateContractModal';
import CreateVersionModal from './components/Versionning/CreateVersionModal';

export default {
  name: 'Contracts',
  components: {
    CurrentContract,
    CreateTextDocumentModal,
    DuplicateContractModal,
    CreateVersionModal,
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['pagination', 'loading', 'hasAtLeastOneFutureContract', 'sort']),
    ...mapGetters('selectedEmployee', ['isCurrentUserOrSystemAdmin', 'isStrictSubordinateOfCurrentUser']),
    ...mapGetters('currentLicense', ['canReadEmployeeInfo']),
    ...mapGetters('currentOrganisation', ['isMulticontractsEnabled']),

    canAccessContractsTab() {
      if (this.isCurrentUserOrSystemAdmin) return true;

      return this.isStrictSubordinateOfCurrentUser && this.canReadEmployeeInfo;
    },
  },
  created() {
    if (!this.canAccessContractsTab) {
      this.$router.replace({ name: 'user_personal', params: { user_id: this.employee.id } });
    }

    this.resetInitialState();
  },
  methods: {
    ...mapActions('contracts', ['fetchContracts']),
    ...mapMutations('contracts', ['resetInitialState']),

    handleNewPage() {
      this.fetchContracts({
        employeeId: this.employee.id,
        filter: this.$router.currentRoute.name,
        sortField: this.sort.sortField,
        direction: this.sort.direction,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contracts__content-wrapper {
  background-color: $sk-grey-5;
  min-height: calc(100vh - 290px);
  padding-bottom: 1px;
}

.contracts-index__fixed-inner {
  padding: 18px 26px;
}

.contracts-index__wrapper {
  background-color: $sk-white;
  border-radius: 4px;

  .contracts-index__tabs-navigation {
    height: 80px;
    display: flex;
  }

  .contracts-index__header {
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a:hover {
      text-decoration: none;
    }

    .router-link-exact-active {
      .contracts-index__header-tab {
        background-color: $sk-blue;
        color: $sk-white;

        &:hover {
          background-color: $sk-blue;
          color: $sk-white;
        }
      }
    }

    .contracts-index__header-tab-future {
      display: flex;

      .contracts-index__header-tab__notification-dot {
        background-color: $sk-blue;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin-left: -20px;
        margin-top: 6px;
      }
    }

    .contracts-index__header-tab {
      font-size: $fs-text-m;
      color: $sk-grey;
      padding: 8px 13px;
      border-radius: 100px;
      margin-right: 10px;

      &:hover {
        background-color: $sk-grey-10;
      }
    }
  }

  .contracts-index__pagination__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .contracts-index__pagination__container {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 25px 138px 40px;
    }
  }
}
</style>
