<template>
  <!-- eslint-disable  max-len -->
  <svg>
    <g filter="url(#lock-filter)">
      <circle
        cx="39"
        cy="29"
        r="15"
        fill="white"
      />
    </g>
    <g>
      <path
        d="M41.8 26.8H41.3429V25.8857C41.3429 24.624 40.3189 23.6 39.0571 23.6C37.7954 23.6 36.7714 24.624 36.7714 25.8857V26.8H36.3143C35.8092 26.8 35.4 27.2091 35.4 27.7143V32.2857C35.4 32.7908 35.8092 33.2 36.3143 33.2H41.8C42.3051 33.2 42.7143 32.7908 42.7143 32.2857V27.7143C42.7143 27.2091 42.3051 26.8 41.8 26.8ZM39.0571 30.9143C38.552 30.9143 38.1429 30.5051 38.1429 30C38.1429 29.4948 38.552 29.0857 39.0571 29.0857C39.5623 29.0857 39.9714 29.4948 39.9714 30C39.9714 30.5051 39.5623 30.9143 39.0571 30.9143ZM40.4743 26.8H37.64V25.8857C37.64 25.104 38.2754 24.4685 39.0571 24.4685C39.8389 24.4685 40.4743 25.104 40.4743 25.8857V26.8Z"
        fill="#2b66fe"
      />
    </g>
    <defs>
      <filter
        id="lock-filter"
        x="0"
        y="0"
        width="78"
        height="78"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LockIcon',
};
</script>
