<template>
  <SkOroraDialog
    id="download_timeclock_step_modal"
    ref="downloadTimeclockModal"
    :title="$t('badgings.timeclock_demo.modals.download_timeclock.title')"
    :cancel-button-label="$t('badgings.timeclock_demo.modals.labels.cancel')"
    :submit-button-label="$t('badgings.timeclock_demo.modals.labels.done')"
    size="medium"
    @submit="handleSubmit"
  >
    <template #body>
      <div class="download-timeclock-modal-description">
        <div class="sk-text-large-regular">
          {{ $t('badgings.timeclock_demo.modals.download_timeclock.description_first') }}
          <SkOroraLink
            :href="timeclockDemoLink"
            target="_blank"
          >
            <span class="timeclock-demo-link">{{ $t('badgings.timeclock_demo.modals.download_timeclock.link') }}</span>
          </SkOroraLink>
          {{ $t('badgings.timeclock_demo.modals.download_timeclock.description_second') }}
        </div>
      </div>
      <div class="container">
        <div class="qr-step__wrapper">
          <div class="qr-step__content">
            <img src="./assets/qr.svg">
            <lottie-player
              :src="lottieQr"
              background="transparent"
              speed="1"
              style="width: 200px; height: 200px"
              loop
              autoplay
            />
          </div>
          <div class="qr-step__description">
            <div class="sk-text-small-regular">
              {{ $t('badgings.timeclock_demo.modals.download_timeclock.qr_description') }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </SkOroraDialog>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import lottieQrFile from './assets/scan.lottie.json';
import '@lottiefiles/lottie-player';

export default {
  name: 'DownloadTimeclockModal',
  computed: {
    ...mapGetters('timeclockOnboarding', ['checklistSteps']),
    lottieQr() {
      return JSON.stringify(lottieQrFile);
    },
    timeclockDemoLink() {
      return this.$t('badgings.timeclock_demo.modals.download_timeclock.redirection_link');
    },
  },
  methods: {
    ...mapMutations('timeclockOnboarding', ['toggleChecklistCollapsed']),
    ...mapActions('timeclockOnboarding', ['updateStepCompletion']),
    handleSubmit() {
      try {
        this.updateStepCompletion({ id: 'download_timeclock_step', completed: true });

        this.emitOnRoot(MODAL_HIDE_EVENT, null, 'download_timeclock_step_modal');
        this.toggleChecklistCollapsed();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .sk-orora-dialog__body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 24px;
  width: 100%;

  .download-timeclock-modal-description {
    width: 100%;

    .timeclock-demo-link {
      font-size: 16px;
    }
  }
}

::v-deep .sk-orora-dialog__footer {
  gap: 12px;
  width: 100%;

  .sk-orora-footer--shadow {
    box-shadow: unset;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.qr-step__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .qr-step__description {
    padding-top: 8px;
    color: $sk-grey-50;
  }
}

.qr-step__content {
  position: relative;
  display: grid;
  place-items: center;
  color: $sk-grey-50;
}

.qr-step__content img {
  position: absolute;
  width: 160px;
  height: 160px;
}
</style>
