const LOCAL_STORAGE_FILTER_KEY = 'planningFilters';
const STORAGE_FILTER_KEYS = ['postes', 'teams', 'users', 'absences'];

class PlanningFilterHandler {
  constructor(setFilters) {
    // mutation from PlanningsState store
    this.setFilters = setFilters;
  }

  /**
   * Apply filters :
   * 1. Override store and local storage data with URL filters if set
   * 2. Use local storage data to override store and url filters (reset or update)
   * 3. return route query with filters
   * This ensures that url filters are the main source of truth to determine the active filters.
   * While allowing for filters to be persisted through local storage if url filters are missing
   * @param {*} to -> current page
   * @param {*} from -> previous page (null on component mounted flow)
   * @returns route query with filters
   */
  applyFilters(to, from) {
    /**
     * We want to prioritize filters in url over those in local storage:
     * This allows the user to retrieve his filters when using an url where they are set
     *** We also dont want to override localstorage when changing shop,
     * because in that case, filters will later be reset
     */
    if (!this.#isSwitchingShop(to, from)) {
      const urlFilters = this.#getUrlFilters(to);
      // If filters are set in URL -> override existing local storage filters with url filters
      if (!this.#areFiltersEmpty(urlFilters)) {
        this.#setLocalStorageFilters(urlFilters, to);
      }
    }

    // Override URL and store filters with local storage filters
    const localStorageFilters = this.#getFormatedLocalStorageFilters();
    // if filters apply to a different shop -> reset filters
    if (!this.#areFiltersEmpty(localStorageFilters) > 0 &&
        to.params.shop_id !== localStorageFilters.shopId) {
      return this.#resetFilters(to);
    }

    // if filters apply to current shop, override store and URL filters
    this.#setStoreFilters(localStorageFilters);
    return this.#addFiltersToRouteQuery(localStorageFilters, to);
  }

  updateFilters(filters, router) {
    this.#setStoreFilters(filters);
    this.#setLocalStorageFilters(filters, router.currentRoute);
    const query = this.#addFiltersToRouteQuery(filters, router.currentRoute);
    this.#updateRoute(query, router);
  }

  #getUrlFilters(route) {
    const { poste_ids: posteIds, team_ids: teamIds, user_ids: userIds,
      absence_ids: absenceIds } = route.query;

    // Convert url parameters to array filters
    const postes = this.#paramAsArray(posteIds);
    const teams = this.#paramAsArray(teamIds);
    const users = this.#paramAsArray(userIds);
    const absences = this.#paramAsArray(absenceIds);

    return this.#cleanFilters({ postes, teams, users, absences });
  }

  #getLocalStorageFilters() {
    const filtersStorageData = localStorage.getItem(LOCAL_STORAGE_FILTER_KEY);
    if (!filtersStorageData) return {};

    return JSON.parse(filtersStorageData);
  }

  // Apply cleanFilters to local storage filters
  #getFormatedLocalStorageFilters() {
    const filters = this.#getLocalStorageFilters();
    return this.#cleanFilters(filters);
  }

  #setStoreFilters(filters) {
    this.setFilters(filters);
  }

  #setLocalStorageFilters(filters, route) {
    const newFilters = this.#cleanFilters(filters);
    newFilters.shopId = route.params.shop_id;

    localStorage.setItem(LOCAL_STORAGE_FILTER_KEY, JSON.stringify(newFilters));
  }

  // Return new route query with filters
  #addFiltersToRouteQuery(filters, route) {
    const { postes, teams, users, absences } = filters;
    const queryFilters = this.#cleanFilters(
      { poste_ids: postes, team_ids: teams, user_ids: users, absence_ids: absences },
    );
    const query = {
      ...route.query,
      ...queryFilters,
    };

    return query;
  }

  #updateRoute(query, router) {
    router.replace({
      name: router.currentRoute.name,
      query,
    });
  }

  // Remove filters from store data, local storage and url
  #resetFilters(route) {
    localStorage.removeItem(LOCAL_STORAGE_FILTER_KEY);
    this.#setStoreFilters({});
    return this.#addFiltersToRouteQuery({}, route);
  }

  // - remove empty array filters
  #cleanFilters(filters) {
    if (!filters) return {};

    const newFilters = { ...filters };
    // Remove empty or undefined filters
    Object.keys(newFilters)
      .filter(key => STORAGE_FILTER_KEYS.includes(key))
      .forEach(key => {
        if (this.#isFilterEmpty(filters[key])) {
          delete newFilters[key];
        }
      });

    return newFilters;
  }

  // Converts URL param to array
  #paramAsArray(param) {
    // Param isn't set in URL
    if (param === undefined) {
      return undefined;
    }
    // Only one value for param in URL -> string
    if (typeof param === 'string') {
      return [param];
    }
    // Several values for param in URL -> array
    return [...param];
  }

  // Returns true if new route shop id is different from previous one
  #isSwitchingShop(to, from) {
    if (!from) return false;

    return to.params.shop_id !== from.params.shop_id;
  }

  // Returns true if all filter params are empty
  #areFiltersEmpty(filters) {
    if (!filters) return true;

    return Object.keys(filters)
      .filter(key => STORAGE_FILTER_KEYS.includes(key))
      .every(key => this.#isFilterEmpty(filters[key]));
  }

  // Returns true if filter is empty
  #isFilterEmpty(filter) {
    return !filter || filter.length === 0;
  }
}

export default PlanningFilterHandler;
