<template>
  <FiltersPopover
    v-if="isNewFiltersEnabled"
    class="toolbar-actions-bar__filters-icon"
    source="planning"
    :are-filters-active="areFiltersActive"
    :data-sources="filtersPopOverDataSources"
    :initial-selected-items="initialFilterSelections"
    :loading="areFiltersLoading"
    :submit-handler="handleSubmitFilters"
  />
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import FiltersPopover from '@app-js/shared/components/FiltersPopover/FiltersPopover';
import PlanningFilterHandler from '@app-js/plannings/shared/utils/planning_filter_handler';
import { paramAsArray } from '@app-js/shared/utils/filters/helpers/formatting_helpers';

export default {
  name: 'PlanningFilters',
  components: { FiltersPopover },
  data() {
    return {
      filterHandler: null,
      initialFilterSelections: {},
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teams', 'teamsLoading']),
    ...mapState('planningsPostes', ['postes', 'postesLoading', 'absences', 'absencesLoading']),
    ...mapState('planningsUsers', ['users']),
    ...mapState('planningsLoading', ['isPlanningDataLoading']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('planningsState', ['weeksToFetch', 'firstMondayOfCurrentPeriod', 'areFiltersActive']),
    areFiltersLoading() {
      return this.postesLoading ||
        this.absencesLoading ||
        this.teamsLoading ||
        this.isPlanningDataLoading;
    },
    isNewFiltersEnabled() {
      return this.isDevFlagEnabled('FEATUREDEV_FILTER_IMPROVEMENT');
    },
    filtersPopOverDataSources() {
      if (!this.isNewFiltersEnabled) {
        return {};
      }

      return { teams: this.teams, postes: this.postes, users: this.users, absences: this.absences };
    },
  },
  mounted() {
    this.$router.onReady(() => {
      this.$nextTick(() => {
        this.initializeFilterSelections();
      });
    });
  },
  created() {
    this.filterHandler = new PlanningFilterHandler(this.setFilters);
  },
  methods: {
    ...mapMutations('planningsState', ['setFilters']),
    ...mapActions('planningsKpis', ['fetchKpis']),
    // eslint-disable-next-line camelcase
    handleSubmitFilters({ team_ids, poste_ids, user_ids, absence_ids }) {
      const filters = {
        teams: team_ids,
        postes: poste_ids,
        users: user_ids,
        absences: absence_ids,
      };

      this.filterHandler.updateFilters(filters, this.$router);

      this.fetchKpis({
        shopId: this.currentShop.id,
        date: this.firstMondayOfCurrentPeriod,
        workedHoursOnly: true,
        filters,
        // The following line will be removed in ticket DEV-15880
        absencesCountry: this.currentShop.attributes.absencesCountry,
        weeksToFetch: this.weeksToFetch,
      });

      this.updateInitialFilterSelections({ absence_ids, poste_ids, team_ids, user_ids });
    },
    initializeFilterSelections() {
      const { team_ids: teamIds,
        poste_ids: posteIds,
        user_ids: userIds,
        absence_ids: absenceIds } = this.$route.query;

      this.initialFilterSelections = {
        team_ids: paramAsArray(teamIds),
        poste_ids: paramAsArray(posteIds),
        user_ids: paramAsArray(userIds),
        absence_ids: paramAsArray(absenceIds),
      };
    },
    updateInitialFilterSelections(newFilters) {
      this.initialFilterSelections = {
        ...this.initialFilterSelections,
        ...newFilters,
      };
    },
  },
};
</script>
