<template>
  <SkModal
    id="dashboard-upsell-modal"
    size="medium"
    :display-footer="false"
    :dismissable="false"
  >
    <template #body>
      <SkModalSection
        border-bottom="none"
        class="dashboard-upsell-modal__section"
      >
        <div class="dashboard-upsell-modal__wrapper">
          <div class="dashboard-upsell-modal__big-title__wrapper">
            <i18n
              path="analytics_dashboard.upsell_modal.big_title.text"
              tag="h3"
              class="dashboard-upsell-modal__big-title sk-header--3"
            >
              <template #highlightText>
                <span class="dashboard-upsell-modal__big-title--highlight">
                  {{ highlightText }}
                </span>
              </template>
            </i18n>
          </div>
          <div class="dashboard-upsell-modal__subtitle__wrapper">
            <!-- eslint-disable vue/no-v-html -->
            <h4
              class="dashboard-upsell-modal__subtitle"
              v-html="$t('analytics_dashboard.upsell_modal.subtitle')"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <div class="dashboard-upsell-modal__three-blocks">
            <div class="dashboard-upsell-modal__three-blocks__block">
              <img
                src="./assets/block-one.svg"
                class="dashboard-upsell-modal__three-blocks__image"
              >
              <div class="dashboard-upsell-modal__three-blocks__text">
                {{ $t('analytics_dashboard.upsell_modal.three_blocks.first_block') }}
              </div>
            </div>

            <div class="dashboard-upsell-modal__three-blocks__block">
              <img
                src="./assets/block-two.svg"
                class="dashboard-upsell-modal__three-blocks__image"
              >
              <div class="dashboard-upsell-modal__three-blocks__text">
                {{ $t('analytics_dashboard.upsell_modal.three_blocks.second_block') }}
              </div>
            </div>

            <div class="dashboard-upsell-modal__three-blocks__block">
              <img
                src="./assets/block-three.svg"
                class="dashboard-upsell-modal__three-blocks__image"
              >
              <div class="dashboard-upsell-modal__three-blocks__text">
                {{ $t('analytics_dashboard.upsell_modal.three_blocks.third_block') }}
              </div>
            </div>
          </div>
          <div class="dashboard-upsell-modal__submits">
            <SkOroraButton @click="requestDemo">
              {{ $t('analytics_dashboard.upsell_modal.request_demo.submit') }}
            </SkOroraButton>
            <a
              href="#"
              class="dashboard-upsell-modal__submits__planning-link"
              @click.prevent="redirectToPlanning"
            >
              {{ $t('analytics_dashboard.upsell_modal.go_back_planning') }}
            </a>
          </div>
        </div>
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { mapState } from 'vuex';

export default {
  name: 'DashboardUpsellModal',
  data() {
    return {
      requestDemoLoading: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('currentOrganisation', ['currentOrganisation']),

    highlightText() {
      return this.$t('analytics_dashboard.upsell_modal.big_title.highlight');
    },
  },
  mounted() {
    this.$skAnalytics.track('analytics_upsell');
  },
  methods: {
    requestDemo() {
      if (this.requestDemoLoading) return;

      this.requestDemoLoading = true;

      const shopId = this.currentShop.id === 'all' ?
        this.currentOrganisation.attributes.firstShopId :
        this.currentShop.id;

      const params = {
        shop_id: shopId,
        user: {
          organisation_id: this.currentOrganisation.id,
          organisation_name: this.currentOrganisation.attributes.name,
          current_shop_id: shopId,
          user_license: this.currentLicense.attributes.originalType,
          button_clicked: this.$t('analytics_dashboard.upsell_modal.request_demo.submit'),
          upsell_type: 'dashboard',
          current_page: 'CommercialDashboard',
        },
      };

      httpClient
        .post('/v3/api/upsells/request_demo', params)
        .then(() => {
          this.$skToast({
            message: this.$t('analytics_dashboard.upsell_modal.request_demo.success'),
            variant: 'success',
            containerId: 'dashboard-upsell-modal',
          });
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('analytics_dashboard.upsell_modal.request_demo.error'),
            variant: 'error',
            containerId: 'dashboard-upsell-modal',
          });
        })
        .finally(() => {
          this.requestDemoLoading = false;
        });
    },
    redirectToPlanning() {
      const shopId = this.currentShop.id === 'all' ?
        this.currentOrganisation.attributes.firstShopId :
        this.currentShop.id;

      const dateParam = localStorage.planningDate || skDate().format('YYYY-MM-DD');
      const planningPath = `/v3/shops/${shopId}/plannings/weeks/employees?date=${dateParam}`;

      this.$router.push(planningPath);
    },
  },
};
</script>

<style lang="scss" scoped>
// Upsell modal style override
#dashboard-upsell-modal {
  top: 49px;

  ::v-deep .sk-icon-button {
    display: none;
  }

  ::v-deep .sk-modal {
    height: calc(100% - 164px);
  }

  ::v-deep .sk-modal__backdrop {
    background: rgba(0, 0, 0, 0);
  }

  ::v-deep .sk-modal__dialog {
    position: relative; // To position the images
  }

  ::v-deep .sk-modal__header {
    border-bottom: 0;
    height: 46px;
  }

  ::v-deep .sk-modal__body-wrapper {
    margin-top: -24px;
  }

  ::v-deep .sk-modal__body {
    padding: 0 40px 21px;
  }

  ::v-deep .sk-modal__section {
    padding: 0;
    margin: 0;
  }

  ::v-deep footer.sk-modal__footer {
    padding: 30px 24px 24px;
  }

  .dashboard-upsell-modal__section {
    padding-top: 0;
  }
}

.dashboard-upsell-modal__big-title__wrapper,
.dashboard-upsell-modal__subtitle__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-upsell-modal__big-title__wrapper {
  margin-bottom: 13px;
  position: relative;
  z-index: 1000;
}

.dashboard-upsell-modal__big-title {
  font-size: $fs-heading-m !important;
  line-height: 36px;
  text-align: center;

  &--highlight {
    color: $sk-blue;
  }
}

.dashboard-upsell-modal__subtitle__wrapper {
  margin-bottom: -21px;
}

.dashboard-upsell-modal__subtitle {
  font-weight: 400;
  font-size: $fs-text-l;
  line-height: 21px;
  color: $sk-grey;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.dashboard-upsell-modal__three-blocks {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1000;

  &__block {
    width: 164px;
  }

  &__image {
    margin-bottom: 19px;
    margin-top: 36px;
    height: 101px;
  }

  &__text {
    font-size: $fs-text-s;
    line-height: 16px;
    text-align: center;
    color: $sk-grey;
  }
}

.dashboard-upsell-modal__submits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-top: 30px;

  &__planning-link {
    margin-top: 14px;
  }
}
</style>
