<template>
  <div :class="matchedBadgingTableClass">
    <SkTable
      :columns="headers"
      :loading="matchedBadgingsLoading"
      :is-empty="isEmpty"
      @sort="handleSort"
    >
      <template #empty-state>
        <div class="matched-shifts__table__empty">
          <EmptyBadgingsLogo />
          <p class="absences__table__empty__message">
            {{ $t('badgings.days.table.empty_state.planned_shifts') }}
          </p>
        </div>
      </template>
      <MatchedBadgingRow
        v-for="matchedBadging in matchedBadgingsPerUser()"
        :key="matchedBadging.shiftId || matchedBadging.badgingId"
        :matched-badging="matchedBadging"
        :at-least-one-grouped-badging="atLeastOneGroupedBadging"
        :class="matchedBadgingRowClass"
        @turned-as-absence="handleTurnedAsAbsence"
      />
    </SkTable>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
  mapActions,
} from 'vuex';

import { FEATURES } from '@app-js/shared/constants/features.js';
import { EmptyBadgingsLogo } from '@app-js/badgings/shared/svg';
import MatchedBadgingRow from '@app-js/badgings/shared/components/MatchedBadgingRow';

export default {
  name: 'MatchedBadging',
  components: { EmptyBadgingsLogo, MatchedBadgingRow },
  data() {
    return {
      dayClocksInOut: false,
    };
  },
  computed: {
    ...mapState('badgings', ['matchedBadgingsLoading', 'matchedBadgings', 'dayLocked']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapGetters('badgings', [
      'atLeastOneGroupedBadging',
      'matchedBadgingsPerUser',
      'areAllRowsValidatedForUser',
    ]),
    ...mapGetters('features', ['isFeatureEnabled']),

    isPlanningFeatureEnabled() {
      return this.isFeatureEnabled(FEATURES.FEATURE_PLANNING, this.currentShop.id, () => true);
    },
    isEmployeeColumnLastFromBlock() {
      return !this.isPlanningFeatureEnabled;
    },
    headers() {
      return [
        {
          title: this.$t('badgings.days.tabs.shifts.headers.validated'),
        },
        {
          title: null, // empty column title to handle users medallions
          isHidden: !this.isPlanningFeatureEnabled,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.employees'),
          name: 'firstName',
          sort: true,
          ...(this.isEmployeeColumnLastFromBlock && { class: 'table-column__border-right' }),
          class: this.isPlanningFeatureEnabled ? '' : 'employee-noplanning-label',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.shift_hours'),
          name: 'predictedStartsAt',
          sort: true,
          defaultSort: 'asc',
          isHidden: !this.isPlanningFeatureEnabled,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.shift_break'),
          class: 'table-column__border-right',
          isHidden: !this.isPlanningFeatureEnabled,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.turned_as_absence_noplanning'),
          isHidden: this.isPlanningFeatureEnabled,
          class: this.isPlanningFeatureEnabled ? '' : 'pl-4',
        },
        {
          title: this.isPlanningFeatureEnabled ?
            this.$t('badgings.days.tabs.shifts.headers.geoloc.name') :
            this.$t('badgings.days.tabs.shifts.headers.geoloc.name_planning'),
          class: this.isPlanningFeatureEnabled ? 'pl-3' : '',
          name: 'geoloc',
          icon: {
            icon: 'CircledQuestionMarkIcon',
            tooltip: this.$t('badgings.days.tabs.shifts.headers.geoloc.tooltip'),
          },
          isHidden: false,
        },
        {
          title: this.isPlanningFeatureEnabled ?
            this.$t('badgings.days.tabs.shifts.headers.badging_hours') :
            this.$t('badgings.days.tabs.shifts.headers.badging_hours_noplanning'),
          class: 'pl-3',
          name: 'badgingStartsAt',
          sort: true,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.badging_break'),
          class: this.isPlanningFeatureEnabled ?
            '' :
            'badging-break-noplanning-label',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.selected_hours'),
          class: this.isPlanningFeatureEnabled ? 'px-3' : 'pl-3',
          name: 'selectedStartsAt',
          sort: true,
        },
        {
          title: this.isPlanningFeatureEnabled ?
            this.$t('badgings.days.tabs.shifts.headers.selected_break') :
            this.$t('badgings.days.tabs.shifts.headers.selected_break_noplanning'),
          class: !this.isPlanningFeatureEnabled ?
            'selected-break-noplanning-label' :
            '',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.turned_as_absence'),
          class: 'table-column__border-right',
          isHidden: !this.isPlanningFeatureEnabled,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.meal'),
          class: 'pl-3',
          isHidden: !this.currentShop.attributes.isMealRuleBenefitInKind,
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.total-duration'),
          class: 'pl-3',
        },
        {
          title: this.$t('badgings.days.tabs.shifts.headers.notes'),
          isHidden: this.dayClocksInOut || !this.isPlanningFeatureEnabled,
        },
        {
          title: this.isPlanningFeatureEnabled ?
            this.$t('badgings.days.tabs.shifts.headers.actions') :
            this.$t('badgings.days.tabs.shifts.headers.ignore_badge'),
          class: this.isPlanningFeatureEnabled ?
            '' :
            'ignore-badge-label',
        },
      ].filter(header => !header.isHidden);
    },
    isEmpty() {
      return !this.matchedBadgingsPerUser() || this.matchedBadgingsPerUser().length === 0;
    },
    matchedBadgingRowClass() {
      return {
        'matched-shifts__table-row--disabled': this.areAllRowsValidatedForUser && this.dayLocked && !this.isEmpty,
      };
    },
    matchedBadgingTableClass() {
      return {
        'matched-shifts__table': true,
        'matched-shifts__table--disabled': this.areAllRowsValidatedForUser && this.dayLocked && !this.isEmpty,
      };
    },
  },
  watch: {
    matchedBadgings() {
      const headerSize = this.headers.length;
      const lastItem = this.headers[headerSize - 1];
      if (lastItem.title && this.atLeastOneGroupedBadging) {
        // Empty column for grouped badging detail caret
        this.headers.push({ title: null });
      } else if (!lastItem.title && !this.atLeastOneGroupedBadging) {
        this.headers.splice(headerSize - 1, 1);
      }
    },
  },
  created() {
    this.fetchPunchClockSettings(this.currentShop.id).then(response => {
      this.dayClocksInOut = response.dayClocksInOut;
    });
  },
  methods: {
    ...mapActions('punchClock', ['fetchPunchClockSettings']),
    ...mapMutations('badgings', ['sortmatchedBadgings', 'updatePlaceholderBadgingByKey', 'updateMatchedBadgingByKey']),
    handleSort(event, columnName, direction) {
      this.sortmatchedBadgings({ columnName, direction });
    },
    handleTurnedAsAbsence(matchedBadging, placeholder) {
      if (placeholder) {
        this.updatePlaceholderBadgingByKey(matchedBadging);
      } else {
        this.updateMatchedBadgingByKey(matchedBadging);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.matched-shifts__table {
  ::v-deep th {
    position: -webkit-sticky;
    background-clip: padding-box;
    top: 0;
    z-index: 1;
    background-color: white;
    border-bottom: 1px solid $sk-grey-10;

    &:first-child {
      padding-left: 10px;
      width: 55px;
    }

    .table-column__label--sortable {
      margin-left: 0;
    }

    .table-column__empty {
      width: 28px;
    }
  }

  ::v-deep th:last-child {
    .table-column__label {
      flex-direction: row-reverse;
    }
  }

  ::v-deep th.employee-noplanning-label {
    padding-left: 1.5rem !important;
    border-right: 1px solid $sk-grey-10;
  }

  ::v-deep th.badging-break-noplanning-label {
    border-right: 1px solid $sk-grey-10;
  }

  ::v-deep th.selected-break-noplanning-label {
    border-right: 1px solid $sk-grey-10;
  }

  ::v-deep th.ignore-badge-label {
    .table-column__label {
      flex-direction: column;
    }
  }

  &--disabled {
    opacity: .6;
  }

  .matched-shifts__table__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;

    .absences__table__empty__message {
      margin-top: 50px;
      color: $sk-black;
      font-size: $fs-heading-xs;
    }
  }

  .matched-shifts__table-row--disabled {
    pointer-events: none;
  }

  // Prevent to display matched badging row on intercom bubble
  table.sk-table {
    margin-bottom: 90px;
  }
}
</style>
