<template>
  <SkModalSectionV2 class="absence-shop-row">
    <label
      for="absenceShop"
      class="sk-text-medium-semibold"
    >
      {{ $t('requests.leave_requests.modal.absence_shop.label') }}
    </label>
    <SkSelectV2
      v-model="localValue"
      :disabled="isDisabled"
      :options="shopsOptions"
      :no-results-label="$t('requests.leave_requests.no_search_results')"
      append-to-body
      data-test="leave-request__select-absence__input"
      input-id="absenceShop"
      name="absenceShop"
      width="394px"
    />
  </SkModalSectionV2>
</template>

<script>
export default {
  name: 'ShopSelectRow',
  props: {
    value: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    shops: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    shopsOptions() {
      return this.shops?.map(({ id, attributes: { name } }) => ({ id, text: name })) || [];
    },
  },
};

</script>
<style lang="scss" scoped>
</style>
