import { roundFloat } from '@skello-utils/formatting/numbers';

const ROUNDING_PRECISION = 1;

export const convertSecondsToMinutes = seconds => seconds / 60;

export const formatBreakOrPauseTime = breakOrPauseTime => {
  if (!breakOrPauseTime) return '0 mn';

  const breakOrPauseTimeInMinutes = convertSecondsToMinutes(breakOrPauseTime);

  if (Number.isInteger(breakOrPauseTimeInMinutes)) {
    return `${breakOrPauseTimeInMinutes} mn`;
  }

  return `${roundFloat(breakOrPauseTimeInMinutes, ROUNDING_PRECISION)} mn`;
};
