<template>
  <img
    width="42"
    src="./images/logo_dvore.png"
  >
</template>

<script>
export default {
  name: 'Dvore',
};
</script>
