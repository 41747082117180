import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import snakeCase from 'lodash/snakeCase';
import Vue from 'vue';

import { associationMatcherItem } from '@skello-utils/association_matchers';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { CONTRACT_TYPE_CATEGORIES } from '@app-js/shared/store/modules/config';

const initialState = {
  /**
   * All contracts is all the employee.contracts
   * Loaded dynamically when Creating or Duplicating a contract
   * Because we need it to avoid overlapping of two contracts
   */
  allContracts: [],
  /**
   * Contracts is only part of employee.contracts
   * It is used by <ContractsTable> to store the list of contracts
   * We want to display related to sorting / pagination
   */
  contracts: [],
  /**
   * Contract is used dynamically
   * - Either to store the current contract of the day for <CurrentContract>
   * - Either to store the contract we want to show / create in <FullContract>
   */
  contract: {},
  /**
   * Used to revert changes made to state.contract
   * IE : Making changes in <FullContract> and "Going Back"
   */
  originalContract: {},
  /**
   *   Used for modals actions (apply template / duplicate / archive)
   */
  selectedContract: null,
  pagination: {
    current_page: 1,
    per_page: 10,
    total_pages: 0,
  },
  sort: {
    sortField: 'createdAt',
    direction: 'asc',
  },
  loading: true,
  loadingContracts: true,
  loadingUpdate: false,
  loadingCreate: false,
  loadingVersion: false,
  hasAtLeastOneFutureContract: null,
  contractsCount: 0,
};

const matchCreatorToContract = (contract, included) => {
  const creator = associationMatcherItem(contract, included, { key: 'creator', type: 'user' });
  Object.assign(contract.relationships, { creator });

  return contract;
};

const mutations = {
  // navigation mutations
  setSelectedContract(state, contract) {
    state.selectedContract = cloneDeep(contract);
  },
  unsetSelectedContract(state) {
    state.selectedContract = null;
  },

  // set contract state AND originalContract state
  // so it does not consider it as contract change.
  setDpaeDepositCompleted(state, dpaeDepositCompleted) {
    Vue.set(state.contract.attributes, 'dpaeDepositCompleted', dpaeDepositCompleted);
    Vue.set(state.originalContract.attributes, 'dpaeDepositCompleted', dpaeDepositCompleted);
  },

  // contract mutation
  setContractAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      // Necessary for reactivity
      // https://vuejs.org/v2/guide/reactivity.html
      Vue.set(state.contract.attributes, attribute, payload[attribute]);
    });
  },
  setSelectedContractAttributes(state, payload) {
    if (!state.selectedContract) return;

    Object.keys(payload).forEach(attribute => {
      Vue.set(state.selectedContract.attributes, attribute, payload[attribute]);
    });
  },
  handleSorting(state, { sortField, direction }) {
    state.sort.sortField = snakeCase(sortField);
    state.sort.direction = direction === 'asc' ? 'desc' : 'asc';
  },

  squashChangesOnContract(state) {
    state.contract = cloneDeep(state.originalContract);
  },

  resetInitialState(state) {
    Object.assign(state, initialState);
  },

  setDefaultAttributesToNewContract(state) {
    // counterType needs to be set to hourly_rate by default
    state.contract = {
      id: null,
      attributes: {
        contractTypeId: null,
        timeBasisCategory: null,
        fixedTermContractReason: null,
        tutor: null,
        payIdentificationNumber: null,
        posteName: null,
        startDate: null,
        trialEnd: null,
        endDate: null,
        status: null,
        level: null,
        step: null,
        dpaeDepositCompleted: null,
        counterType: 'hourly_rate',
        contractHours: null,
        workDays: null,
        salaryCalculationType: 'hourly_wage',
        monthlySalary: null,
        hourlyWage: null,
        hourlyWageWithCosts: null,
        transportCost: null,
      },
      relationships: {},
      type: 'contract',
    };
    state.originalContract = cloneDeep(state.contract);
  },

  setDefaultAttributesToVersionContract(state, payload) {
    if (!state.contract?.attributes) return;

    const { startDate, endDate } = payload;
    state.contract.attributes.startDate = startDate;
    state.contract.attributes.endDate = endDate;
    state.contract.attributes.trialEnd = null;
    state.originalContract = cloneDeep(state.contract);
  },

  fetchAllContractsSuccess(state, payload) {
    const contracts = JSON.parse(payload.data.contracts);
    state.allContracts = contracts.data;
  },

  fetchContractSuccess(state, payload) {
    state.contract = payload.data;
    matchCreatorToContract(state.contract, payload.included);
    state.originalContract = cloneDeep(state.contract);
  },

  createContractSuccessUpdateCount(state) {
    state.contractsCount += 1;
  },

  fetchContractsSuccess(state, payload) {
    const contracts = JSON.parse(payload.data.contracts);
    state.contracts = contracts.data;
    state.hasAtLeastOneFutureContract = payload.data.has_at_least_one_future_contract;
    state.contractsCount = payload.data.contracts_count;
    state.contracts.forEach(contract => matchCreatorToContract(contract, contracts.included));
    state.pagination = contracts.meta.pagination;
  },

  contractsError(state, error) {
    state.error = error;
  },

  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  deleteContractSuccess(state, contractId) {
    const contractIndex =
      state.contracts.findIndex(contract => contract.id === contractId);
    state.contracts.splice(contractIndex, 1);
    state.contractsCount -= 1;
  },
};

const actions = {
  // TODO : If possible merge with fetchContracts later
  //        When it is determined if we refactor allContracts with contracts
  fetchAllContracts({ commit }, fetchParams) {
    return httpClient.get(`/v3/api/users/${fetchParams.employeeId}/contracts?skip_pagination=true`)
      .then(response => {
        commit('fetchAllContractsSuccess', response);
      })
      .catch(({ error }) => {
        throw error;
      });
  },

  fetchCurrentContract({ commit }, fetchParams) {
    commit('performingRequest');

    return httpClient.get(`/v3/api/users/${fetchParams.employeeId}/contracts/current`)
      .then(response => {
        commit('fetchContractSuccess', response.data);
      })
      .catch(({ error }) => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  fetchContract({ commit }, fetchParams) {
    commit('performingRequest');

    httpClient.get(`/v3/api/users/${fetchParams.employeeId}/contracts/${fetchParams.contractId}`)
      .then(response => {
        commit('fetchContractSuccess', response.data);
      })
      .catch(({ error }) => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  fetchContracts({ state, commit }, fetchParams) {
    commit('performingRequest', 'loadingContracts');

    const params = {
      filter: fetchParams.filter || null,
      current_page: state.pagination.current_page,
      per_page: state.pagination.per_page,
      sort_field: state.sort.sortField,
      direction: state.sort.direction,
    };

    return httpClient
      .get(`/v3/api/users/${fetchParams.employeeId}/contracts`, { params })
      .then(response => {
        commit('fetchContractsSuccess', response);
      })
      .catch(({ error }) => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => { commit('requestComplete', 'loadingContracts'); });
  },

  createContract({ state, getters, commit }, params) {
    // Contract object passed as argument or using internally state.contract
    const { attributes } = state.contract;

    // Only from Create contract view
    const createParams = {
      contract: {
        // contract Data
        contract_type_id: attributes.contractTypeId,
        time_basis_category: attributes.timeBasisCategory,
        fixed_term_contract_reason: attributes.fixedTermContractReason,
        tutor: attributes.tutor,
        start_date: attributes.startDate,
        trial_end: attributes.trialEnd,
        end_date: attributes.endDate,
        contract_hours: attributes.contractHours,
        work_days: attributes.workDays,
        status: attributes.status,
        level: attributes.level,
        step: attributes.step,
        poste_name: attributes.posteName,
        pay_identification_number: attributes.payIdentificationNumber,
        pcs_ese: attributes.pcsEse,
        dpae_deposit_completed: attributes.dpaeDepositCompleted,
        // salary data
        salary_calculation_type: attributes.salaryCalculationType,
        counter_type: attributes.counterType,
        hourly_wage: attributes.hourlyWage,
        hourly_wage_with_costs: attributes.hourlyWageWithCosts,
        monthly_salary: attributes.monthlySalary,
        transport_cost: attributes.transportCost,
      },
      cluster_node_id: params.clusterNodeId,
    };

    // When creating a new contract from scratch
    // Only one of these attributes is needed
    // Depending on contract ( hours per week or work days )
    if (getters.isContractCounterTypeHourlyRate && !getters.isContractInterim) {
      delete createParams.contract.work_days;
    } else {
      delete createParams.contract.contract_hours;
    }

    commit('performingRequest', 'loadingCreate');

    return httpClient
      .post(`/v3/api/users/${params.employeeId}/contracts/`, createParams)
      .then(response => {
        commit('fetchContractSuccess', response.data);
        commit('createContractSuccessUpdateCount');
        return response;
      })
      .catch(error => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'loadingCreate');
      });
  },

  createVersionContract({ state, getters, commit }, params) {
    // Contract object passed as argument or using internally state.contract
    const { attributes } = state.contract;

    const versionParams = {
      contract: {
        // contract Data
        contract_type_id: attributes.contractTypeId,
        time_basis_category: attributes.timeBasisCategory,
        fixed_term_contract_reason: attributes.fixedTermContractReason,
        tutor: attributes.tutor,
        start_date: attributes.startDate,
        trial_end: attributes.trialEnd,
        end_date: attributes.endDate,
        contract_hours: attributes.contractHours,
        work_days: attributes.workDays,
        status: attributes.status,
        level: attributes.level,
        step: attributes.step,
        poste_name: attributes.posteName,
        pay_identification_number: attributes.payIdentificationNumber,
        pcs_ese: attributes.pcsEse,
        dpae_deposit_completed: attributes.dpaeDepositCompleted,
        // salary data
        salary_calculation_type: attributes.salaryCalculationType,
        counter_type: attributes.counterType,
        hourly_wage: attributes.hourlyWage,
        hourly_wage_with_costs: attributes.hourlyWageWithCosts,
        monthly_salary: attributes.monthlySalary,
        transport_cost: attributes.transportCost,
        parentId: params.parentId,
      },
      cluster_node_id: params.clusterNodeId,
    };

    // When creating a new contract from scratch
    // Only one of these attributes is needed
    // Depending on contract ( hours per week or work days )
    if (getters.isContractCounterTypeHourlyRate && !getters.isContractInterim) {
      delete versionParams.contract.work_days;
    } else {
      delete versionParams.contract.contract_hours;
    }

    commit('performingRequest', 'loadingVersion');

    const { employeeId, parentId } = params;
    return httpClient
      .post(`/v3/api/users/${employeeId}/contracts/${parentId}/version_contract`, versionParams)
      .then(response => {
        commit('fetchContractSuccess', response.data);
        commit('createContractSuccessUpdateCount');
        return response;
      })
      .catch(error => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'loadingVersion');
      });
  },

  duplicateContract({ commit }, { employeeId, contractId, startDate, endDate }) {
    const duplicateParams = {
      contract: {
        start_date: startDate,
        end_date: endDate,
      },
    };

    commit('performingRequest', 'loadingCreate');

    return httpClient
      .post(`/v3/api/users/${employeeId}/contracts/${contractId}/duplicate`, duplicateParams)
      .then(response => {
        const duplicatedContract = response.data.data;
        if (duplicatedContract.attributes.current) {
          commit('fetchContractSuccess', response.data);
          commit('createContractSuccessUpdateCount');
        }

        return response;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => { commit('requestComplete', 'loadingCreate'); });
  },

  updateContract({ state, commit, rootGetters }, params) {
    const { attributes } = state.contract;

    const updateParams = {
      contract: {
        // contract Data
        contract_type_id: attributes.contractTypeId,
        time_basis_category: attributes.timeBasisCategory,
        fixed_term_contract_reason: attributes.fixedTermContractReason,
        tutor: attributes.tutor,
        start_date: attributes.startDate,
        trial_end: attributes.trialEnd,
        end_date: attributes.endDate,
        contract_hours: attributes.contractHours,
        work_days: attributes.workDays,
        status: attributes.status,
        level: attributes.level,
        step: attributes.step,
        poste_name: attributes.posteName,
        pay_identification_number: attributes.payIdentificationNumber,
        pcs_ese: attributes.pcsEse,
        dpae_deposit_completed: attributes.dpaeDepositCompleted,
        // salary data
        salary_calculation_type: attributes.salaryCalculationType,
        counter_type: attributes.counterType,
        hourly_wage: attributes.hourlyWage,
        hourly_wage_with_costs: attributes.hourlyWageWithCosts,
        monthly_salary: attributes.monthlySalary,
        transport_cost: attributes.transportCost,
      },
      cluster_node_id: params.clusterNodeId,
      incomplete_profiles: rootGetters['employees/displayPayrollPreparation'],
    };

    commit('performingRequest', 'loadingUpdate');

    return httpClient
      .patch(`/v3/api/users/${params.employeeId}/contracts/${params.contractId}`, updateParams)
      .then(response => {
        commit('fetchContractSuccess', response.data);

        if (rootGetters['employees/displayPayrollPreparation']) {
          commit(
            'selectedEmployee/updateSelectedEmployeePayrollMissingAttributes',
            response.data.data.attributes.payrollMissingAttributes,
            { root: true },
          );
        }

        return response;
      })
      .catch(error => {
        commit('contractsError', error.data);
        throw error;
      })
      .finally(() => { commit('requestComplete', 'loadingUpdate'); });
  },
  updateContractHourlyWage({ state, getters, commit, rootGetters }, payload) {
    commit('setContractAttributes', payload);

    // if the contract is interim there is no link between monthlySalary and hourly wage
    // if the calculation type IS monthly salary then montlhly salary is not overwritten
    if (getters.isContractInterim || getters.isSalaryCalculationTypeMonthlySalary) {
      return;
    }

    const { hourlyWage, workDays, monthlySalary } = state.contract.attributes;
    let calculatedMonthlySalary = monthlySalary;

    const activePermanentAmendment = rootGetters['amendments/activePermanentAmendment'];

    const contractHours =
      activePermanentAmendment?.attributes?.hours || state.contract.attributes.contractHours;

    if (getters.isContractCounterTypeHourlyRate && contractHours) {
      calculatedMonthlySalary = (
        hourlyWage * (52 / 12) * contractHours
      ).toFixed(2);
    } else if (getters.isContractCounterTypeDailyRate && workDays) {
      calculatedMonthlySalary = (
        ((hourlyWage * 7) * workDays) / 12
      ).toFixed(2);
    }

    commit('setContractAttributes', { monthlySalary: parseFloat(calculatedMonthlySalary) });
  },
  updateContractMonthlySalary({ state, getters, commit, rootGetters }, payload) {
    commit('setContractAttributes', payload);

    // if the contract is interim there is no link between monthlySalary and hourly wage
    // if the calculation type is NOT monthly salary then hourly hour is not overwritten
    if (getters.isContractInterim || getters.isSalaryCalculationTypeHourlyWage) {
      return;
    }

    const activePermanentAmendment = rootGetters['amendments/activePermanentAmendment'];

    const { workDays, monthlySalary, hourlyWage } = state.contract.attributes;
    let calculatedHourlyWage = hourlyWage;

    const contractHours =
      activePermanentAmendment?.attributes?.hours || state.contract.attributes.contractHours;

    if (getters.isContractCounterTypeHourlyRate && contractHours) {
      calculatedHourlyWage = (
        monthlySalary /
        ((52 / 12) * contractHours)
      ).toFixed(2);
    } else if (getters.isContractCounterTypeDailyRate && workDays) {
      calculatedHourlyWage = (
        ((monthlySalary * 12) / (workDays * 7))
      ).toFixed(2);
    }

    commit('setContractAttributes', { hourlyWage: parseFloat(calculatedHourlyWage) });
  },
  deleteContract({ commit }, deleteParams) {
    return httpClient
      .delete(`/v3/api/users/${deleteParams.employeeId}/contracts/${deleteParams.contractId}`)
      .then(response => {
        commit('deleteContractSuccess', deleteParams.contractId);
        return response;
      })
      .catch(error => {
        throw error;
      });
  },
};

const getters = {
  /*
   * state.allContracts getters
   */
  // Retuns all contracts except the one passed as argument
  // When creating a contract, it is not a problem
  // But :
  // #1 - when editing an existing contract we dont want
  // To block the selection between this contract own dates
  // So we need all the contracts except the one we edit
  // #2 - when duplicating a contract we have
  // to check against allContracts
  allContractsExcept: state => contract => {
    if (!contract.id || state.selectedContract) return state.allContracts;

    return state.allContracts
      .filter(c => contract.id !== c.id);
  },
  // Two cases here
  // When creating / editing a crontract
  // When duplicating a contract
  startDateToCheck(state) {
    return state.selectedContract ?
      state.selectedContract.attributes.startDate :
      state.contract.attributes.startDate;
  },
  // Returns all the <Contract> objects with startDate
  // after state.contract.startDate
  allContractsAfterContractStartDate(state, selfGetters) {
    const startDate = skDate.utc(selfGetters.startDateToCheck);

    return selfGetters.allContractsExcept(state.contract)
      .filter(contract => skDate.utc(contract.attributes.startDate).isAfter(startDate.format('YYYY-MM-DD')));
  },
  // returns the startDate <Date> of the next contract
  // after state.contract
  nextContractStartDate(_state, selfGetters) {
    const futureContracts = orderBy(
      selfGetters.allContractsAfterContractStartDate,
      contract => skDate.utc(contract.attributes.startDate),
      ['asc'],
    );

    return futureContracts.length > 0 ?
      skDate.utc(futureContracts[0].attributes.startDate) :
      null;
  },
  // For a given <Contract> and <Date> object
  // Does the contract covers the given date
  isContractOverlappingDate: _state => (contract, date) => {
    date = skDate.utc(date);

    const start = skDate.utc(contract.attributes.startDate).format('YYYY-MM-DD');
    const end = contract.attributes.endDate ?
      skDate.utc(contract.attributes.endDate).format('YYYY-MM-DD') :
      null;

    // No end is for permanentContract without endDate
    // The [] symbol means both boundaries should be inclusive
    return end ?
      date.isBetween(start, end, undefined, '[]') :
      date.isSameOrAfter(start);
  },
  // Given a <Date>, returns a boolean
  // For a new contract creation startDate => To avoid creating contracts
  // With StartDate overlapping existing contracts
  // eslint-disable-next-line arrow-body-style
  isDateUnavailableAsNewContractStartDate: (state, selfGetters) => date => {
    return selfGetters.allContractsExcept(state.contract)
      .some(contract => selfGetters.isContractOverlappingDate(contract, date));
  },
  // Given a <Date>, returns a boolean
  // To avoid setting contracts endDate (creation or edition)
  // With endDate overlapping existing contracts
  isDateUnavailableAsContractEndDate: (_state, selfGetters) => date => {
    date = skDate.utc(date);

    const startDate = skDate.utc(selfGetters.startDateToCheck);
    const nextContractStartDate = selfGetters.nextContractStartDate;

    // Date only available if in the boundaries :
    // - After contract startDate (included)
    // - Before next contract startDate (not included)
    if (date.isSameOrAfter(startDate.format('YYYY-MM-DD'))) {
      if (!nextContractStartDate) {
        return false;
      }
      if (date.isBefore(nextContractStartDate.format('YYYY-MM-DD'))) {
        return false;
      }
    }

    return true;
  },
  // contract getters
  contractType: (_state, _selfGetters, rootState) => contractTypeId => {
    const contractType = rootState.config.config.contract_data.contract_types
      .find(ct => ct.id === contractTypeId);
    return contractType || {};
  },
  isSameOrAfterStartDate(state) {
    // since contract.startDate got no utc, remove it from currentDate
    const currentDate = skDate().utc(true);

    return currentDate.isSameOrAfter(skDate(state.contract.attributes.startDate).utc(), 'day');
  },
  isBeforeEndDate(state) {
    const currentDate = skDate().utc(true);

    // add 1 day cause the last day contract is still active
    return currentDate.isBefore(skDate(state.contract.attributes.endDate).add(1, 'day'));
  },
  isActive(state) {
    if (!state.contract.attributes.startDate && !state.contract.attributes.endDate) return false;
    if (!state.contract.attributes.startDate) return getters.isBeforeEndDate(state);
    if (!state.contract.attributes.endDate) return getters.isSameOrAfterStartDate(state);

    return getters.isSameOrAfterStartDate(state) && getters.isBeforeEndDate(state);
  },
  isActiveContract: state => contract => {
    if (!contract?.attributes) return undefined;

    const { startDate, endDate } = contract.attributes;

    if (!startDate && !endDate) return true;

    const isSameOrAfterStartDate = getters.isSameOrAfterStartDate({ contract });
    const isBeforeEndDate = getters.isBeforeEndDate({ contract });

    if (!startDate) return isBeforeEndDate;
    if (!endDate) return isSameOrAfterStartDate;

    return isSameOrAfterStartDate && isBeforeEndDate;
  },
  isOver(state) {
    const currentDate = skDate().utc(true);

    // add 1 day cause the last day contract is still active
    return currentDate.isAfter(skDate(state.contract.attributes.endDate).add(1, 'day'));
  },
  isComingUp(state) {
    const currentDate = skDate().utc(true);

    return currentDate.isBefore(skDate(state.contract.attributes.startDate));
  },
  isContractFixedTerm(state, _getters, _rootState, rootGetters) {
    return rootGetters['config/fixedTermContractType']
      .map(({ id }) => id)
      .includes(state.contract.attributes.contractTypeId);
  },
  isContractInterim(state, selfGetters) {
    if (!state.contract.attributes || !state.contract.attributes.contractTypeId) return false;

    const contractCategory =
      selfGetters.contractType(state.contract.attributes.contractTypeId).category;

    return contractCategory === CONTRACT_TYPE_CATEGORIES.interim;
  },
  isContractIntern(state, selfGetters) {
    if (!state.contract.attributes.contractTypeId) return false;

    const contractCategory =
      selfGetters.contractType(state.contract.attributes.contractTypeId).category;

    return contractCategory === CONTRACT_TYPE_CATEGORIES.intern;
  },
  isContractStandard(state, selfGetters) {
    if (!state.contract.attributes.contractTypeId) return false;

    const contractCategory =
      selfGetters.contractType(state.contract.attributes.contractTypeId).category;

    return contractCategory === CONTRACT_TYPE_CATEGORIES.standard;
  },
  isContractPermanent: (state, _selfGetters, rootState, rootGetters) => contractParam => {
    const contract = contractParam || state.contract;

    if (!contract.attributes || !contract.attributes.contractTypeId) return false;

    return rootGetters['config/permanentContractTypes'].map(ct => ct.id).includes(contract.attributes.contractTypeId);
  },
  isContractEndDateOptional: (state, _selfGetters, _rootState, rootGetters) => contractParam => {
    const contract = contractParam || state.contract;

    if (!contract.attributes || !contract.attributes.contractTypeId) return false;
    const optionalEndDateContractTypes = rootGetters['config/optionalEndDateContractTypes'];
    const permanentContractTypes = rootGetters['config/permanentContractTypes'];

    return optionalEndDateContractTypes
      .concat(permanentContractTypes)
      .map(contractType => contractType.id)
      .includes(contract.attributes.contractTypeId);
  },
  // eslint-disable-next-line arrow-body-style
  isInterimContract: (state, selfGetters) => contract => {
    return selfGetters.contractType(contract.attributes.contractTypeId).category ===
          CONTRACT_TYPE_CATEGORIES.interim;
  },
  isContractCounterTypeHourlyRate(state, _selfGetters, _rootState, rootGetters) {
    return state.contract.attributes.counterType === rootGetters['config/hourlyRateCounterType'];
  },
  isContractCounterTypeDailyRate(state, _selfGetters, _rootState, rootGetters) {
    return state.contract.attributes.counterType === rootGetters['config/dailyRateCounterType'];
  },
  isSalaryCalculationTypeMonthlySalary(state, _selfGetters, rootState) {
    return state.contract.attributes.salaryCalculationType ===
      rootState.config.config.contract_data.monthly_salary;
  },
  isSalaryCalculationTypeHourlyWage(state, _selfGetters, rootState) {
    return state.contract.attributes.salaryCalculationType ===
      rootState.config.config.contract_data.hourly_wage;
  },
  // trialEnd must not be after state.contract endDate
  isTrialEndErrored(state) {
    if (!state.contract.attributes) return false;
    if (!state.contract.attributes.startDate || !state.contract.attributes.endDate) return false;

    return skDate(state.contract.attributes.trialEnd)
      .isAfter(state.contract.attributes.endDate);
  },
  hasContractError(state, selfGetters) {
    const attributes = state.contract.attributes;
    if (!attributes) return true;

    if (!attributes.contractTypeId) return true;

    if (!attributes.startDate) return true;

    // contract hours is mandatory if contract is not interim
    if (selfGetters.isContractCounterTypeHourlyRate &&
       (!selfGetters.isContractInterim && !attributes.contractHours)) return true;

    // work days is mandatory for daily rate contracts
    if (selfGetters.isContractCounterTypeDailyRate && !attributes.workDays) return true;

    if (selfGetters.isTrialEndErrored) return true;

    // dates are valid if endDate is filled
    // or not filled only if contract is permanent or optional end date and no contracts in the future
    const areDatesValid = !!attributes.endDate ||
                          (selfGetters.isContractEndDateOptional() &&
                          selfGetters.allContractsAfterContractStartDate.length === 0);

    return !areDatesValid;
  },
  hasContractChanges(state) {
    return JSON.stringify(state.contract.attributes) !==
            JSON.stringify(state.originalContract.attributes);
  },
  contractHasBothLimits: state => contractParam => {
    const contract = contractParam || state.contract;
    return contract.attributes &&
      !!contract.attributes.startDate && !!contract.attributes.endDate;
  },

  // getters for navigation status
  isContractReadOnlyView: (_state, _getters, rootState) => rootState.route.name === 'user_full_contract_show',
  isContractCreationView: (_state, _getters, rootState) => rootState.route.name === 'user_full_contract_new',
  isContractVersionView: (_state, _getters, rootState) => rootState.route.name === 'user_full_contract_version',
  isContractEditView: (_state, _getters, rootState) => rootState.route.name === 'user_full_contract_edit',
  hourlyWageWithCostsEstimation: state => hourlyWageRate => {
    const wageRateMultiplier = (hourlyWageRate / 100) + 1;

    // We need a parseFloat here, because .toFixed() returns a string and not a Float.
    return parseFloat((state.contract.attributes.hourlyWage * wageRateMultiplier).toFixed(2));
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  getters,
  mutations,
  actions,
};
