import skDate from '@skello-utils/dates';

/**
 *
 * workloadPlan : workload plan on which apply the changes. eg: { startsAt: '2023-09-12T11:00:00', value: 21 }
 * workloadPlanOpeningDay : opening day corresponding to this workload plan (since an opening can be on 2 calendar days). eg: '2023-09-12T11:00:00'
 * newDate : opening day on which to put the workload plan
 * @returns workload plan on the newOpeningDay
 */
const changeWorkloadPlanDate = (workloadPlan, workloadPlanOpeningDay, newOpeningDay) => {
  const newOpeningDayDate = skDate.utc(newOpeningDay, 'YYYY-MM-DDTHH:mm:ss');

  // The new workload plan will either be on the newOpeningDay day, or the the next day if the opening hours are on
  // two different days.
  const nbDayToAdd =
    skDate.utc(workloadPlan.startsAt, 'YYYY-MM-DDTHH:mm:ss').isSame(skDate.utc(workloadPlanOpeningDay), 'day') ?
      0 :
      1;
  const newStartsAt = skDate.utc(workloadPlan.startsAt, 'YYYY-MM-DDTHH:mm:ss');

  newStartsAt
    .year(newOpeningDayDate.year())
    .month(newOpeningDayDate.month())
    .date(newOpeningDayDate.date());

  newStartsAt.add(nbDayToAdd, 'day');

  return { ...workloadPlan, startsAt: newStartsAt.format('YYYY-MM-DDTHH:mm:ss') };
};

/**
 * workloadPlansToDuplicate : Workload plans to duplicate on the workload plans default format : { posteId: [workloadPlan1, workloadPlan2, ...]}
 * day : List of days on which we should duplicate the workload plans to. Eg: ['2023-06-11', '2023-08-22', ...]
 * @returns duplicated workload plans, default workload plan format : { posteId: [workloadPlan1, workloadPlan2, ...]}
 */
export const duplicateOnDays = (
  workloadPlansToDuplicate,
  days,
) => {
  const outputWorkloadPlans = {};

  days.forEach(dayToDuplicateTo => {
    Object.keys(workloadPlansToDuplicate).forEach(posteId => {
      // Get the opening day corresponding to the workload plan (date of the first element)
      const workloadPlanOpeningDay = workloadPlansToDuplicate[posteId][0].startsAt;

      // Create copies of the workload plan of this poste on the workloadPlanOpeningDay
      const duplicatedWorkloadPlans =
        workloadPlansToDuplicate[posteId]
          .map(workloadPlan => changeWorkloadPlanDate(
            workloadPlan,
            workloadPlanOpeningDay,
            dayToDuplicateTo,
          ));

      if (!outputWorkloadPlans[posteId]) outputWorkloadPlans[posteId] = [];

      outputWorkloadPlans[posteId].push(...duplicatedWorkloadPlans);
    });
  });

  return outputWorkloadPlans;
};
