<template>
  <SkModalSection
    :class="daySelectionWrapperClasses"
    :border-bottom="borderBottom"
  >
    <div class="day-selection__content-title">
      <!-- eslint-disable-next-line max-len -->
      {{
        $tc(
          'plannings.table.manage_shift_modal.tabs.commun.other_actions.repeat_shift.label',
          !isMultipleWorkShiftsActive
        )
      }}
    </div>
    <div class="day-selection__content__selection">
      <div class="day-selection__content__medallions__wrapper">
        <div
          v-for="(day, index) in possibleSelectionDates"
          :key="`day_${index}`"
          class="day-selection__content__medallions"
        >
          <SkMedallion
            v-tooltip="medallionTooltip(index)"
            class="day-selection__content__medallion"
            :class="isDaySelectable(index) ? 'cursor--pointer' : 'cursor--not-allowed'"
            :text="medallionLetters(day.date)"
            :background-color="backgroundMedallionColor(index)"
            :color="contentMedallionColor(index)"
            size="large"
            @click.native="toggleDaySelection(index)"
          />
          <div
            v-if="isDayLocked(index)"
            class="day-selection__content__medallion__lock-icon__wrapper"
          >
            <CloseLockIcon
              :class="lockIconClasses(index)"
              fill=""
              height="10px"
              width="10px"
            />
          </div>
        </div>
      </div>
      <SkOroraButton
        variant="tertiary"
        size="small"
        class="day-selection__content__toggle-all"
        @click="toggleAllDaysSelection"
      >
        {{ toggleAllLabel }}
      </SkOroraButton>
    </div>
  </SkModalSection>
</template>

<script>
import {
  mapState,
  mapMutations,
} from 'vuex';
import skDate from '@skello-utils/dates';

import {
  dateToWeekDayLetters,
  isContractStartedForUserAtDate,
} from '@app-js/plannings/shared/utils/planning_helpers';

export default {
  name: 'DaySelection',
  props: {
    date: {
      type: Object,
      required: true,
    },
    rowItem: {
      type: Object,
      default: () => {},
    },
    borderBottom: {
      type: String,
      default: 'none',
    },
    isWorkShiftsTab: {
      type: Boolean,
      default: false,
    },
    possibleSelectionDates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('planningsState', ['shiftSelectedDays']),
    ...mapState('planningsShifts', ['isMultipleWorkShiftsActive']),
    areEveryDaysSelected() {
      return this.shiftSelectedDays?.every((daySelection, index) => (
        !!daySelection.selectedStatus || this.possibleSelectionDates[index].isLocked
      ));
    },
    toggleAllLabel() {
      const selection = this.areEveryDaysSelected ? 'unselect_all' : 'select_all';

      return this.$t(`plannings.table.manage_shift_modal.tabs.commun.other_actions.repeat_shift.${selection}`);
    },
    daySelectionWrapperClasses() {
      return {
        'day-selection__content': true,
        'day-selection__content--work-shift': this.isWorkShiftsTab,
      };
    },
    isUnassignedRow() {
      return !this.rowItem.id;
    },
  },
  methods: {
    ...mapMutations('planningsState', ['setDaySelection']),
    isDaySelectable(index) {
      if (this.isCurrentDay(index) || this.isDayLocked(index)) return false;
      if (this.isUnassignedRow) return true;
      if (this.isUserArchived(index)) return false;

      return this.hasUserStartedContract(index);
    },
    isDaySelected(index) {
      return this.shiftSelectedDays[index].selectedStatus;
    },
    isDayLocked(index) {
      return this.possibleSelectionDates[index].isLocked;
    },
    isCurrentDay(index) {
      return this.possibleSelectionDates[index].date === this.date.format('YYYY-MM-DD');
    },
    isUserArchived(index) {
      if (!this.rowItem.attributes.archivedAt) return false; // user not archived

      return skDate(this.possibleSelectionDates[index].date).utc(true)
        .isAfter(skDate(this.rowItem.attributes.archivedAt).utc(true));
    },
    hasUserStartedContract(index) {
      return isContractStartedForUserAtDate(this.rowItem, this.possibleSelectionDates[index].date);
    },
    backgroundMedallionColor(index) {
      if (this.isCurrentDay(index)) return '#eef4ff';

      if (!this.isDaySelectable(index)) return '#f3f3f3';

      return this.isDaySelected(index) ? '#2b66fe' : '#f3f3f3';
    },
    contentMedallionColor(index) {
      if (this.isCurrentDay(index)) return '#2b66fe';

      if (!this.isDaySelectable(index)) return '#55'; // 55 is for opacity

      return this.isDaySelected(index) ? '#FFFFFF' : '#727272';
    },
    medallionTooltip(index) {
      if (this.isCurrentDay(index)) {
        return this.$t('plannings.table.manage_shift_modal.tabs.commun.other_actions.repeat_shift.tooltips.current_day');
      }

      if (!this.isUnassignedRow && this.isUserArchived(index)) {
        return this.$t('plannings.table.manage_shift_modal.tabs.commun.other_actions.repeat_shift.tooltips.user_archived');
      }

      if (!this.isUnassignedRow && !this.hasUserStartedContract(index)) {
        return this.$t('plannings.table.cells.disable_cell_tooltip.employee_has_not_started');
      }

      if (this.isDayLocked(index)) {
        return this.$t('plannings.table.manage_shift_modal.tabs.commun.other_actions.repeat_shift.tooltips.locked_day');
      }

      return '';
    },
    toggleDaySelection(index) {
      if (!this.isDaySelectable(index)) return;
      this.setDaySelection({
        index,
        selectedStatus: !this.isDaySelected(index),
      });
    },
    toggleAllDaysSelection() {
      const selectedStatus = !this.areEveryDaysSelected;

      this.shiftSelectedDays.forEach((day, index) => {
        // never modify the current day selectedStatus or a locked day
        if (!this.isDaySelectable(index)) return;

        this.setDaySelection({ index, selectedStatus });
      });
    },
    lockIconClasses(index) {
      return {
        'day-selection__content__medallion__lock-icon--validated': this.possibleSelectionDates[index].validated,
        'day-selection__content__medallion__lock-icon--intermediate': this.possibleSelectionDates[index].intermediateLocked,
        'day-selection__content__medallion__lock-icon--permanent': this.possibleSelectionDates[index].permanentLocked,
      };
    },
    medallionLetters(date) {
      return dateToWeekDayLetters(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.day-selection__content {
  display: flex;
  flex-direction: row;

  &--work-shift {
    padding: 21px 0 13px 24px !important;
    margin: 24px 24px 15px !important;
    border: 1px solid #e8eaed;
    border-radius: 4px;
  }
}

.day-selection__content-title {
  width: 141px;
  display: flex;
  align-items: flex-start;
  font-weight: 700;
  margin-top: 10px;
}

.day-selection__content__selection {
  margin-bottom: 2px;
}

.day-selection__content__medallions__wrapper {
  display: flex;

  .day-selection__content__medallions {
    position: relative;
  }

  .day-selection__content__medallion__lock-icon__wrapper {
    position: absolute;
    height: 18px;
    width: 18px;
    bottom: -4px;
    right: 6px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .day-selection__content__medallion__lock-icon {
      &--validated {
        fill: $sk-blue;
      }

      &--intermediate {
        fill: $sk-success;
      }

      &--permanent {
        fill: $sk-error;
      }
    }
  }
}

.day-selection__content__medallion {
  margin-right: 10px;
}

.day-selection__content__toggle-all {
  margin-top: 9px;
}

// to move in nom package
.cursor--not-allowed {
  cursor: not-allowed;
}
</style>
