<template>
  <SkModal
    id="save-text-document-modal"
    ref="modal"
    :tracking-options="trackingOptions"
    :modal-title="$t('text_document_templates.save_text_document_modal.title', {
      employee:employeeFullName,
    })"
    @cancel="handleCancel"
  >
    <template #body>
      <SkModalSection border-bottom="none">
        <SkInput
          v-model="title"
          :placeholder="
            $t('text_document_templates.save_text_document_modal.title_input')
          "
        />
        <div class="save-text-document-modal__folder-section">
          <h3>
            {{
              $t('text_document_templates.save_text_document_modal.folder_section')
            }}
          </h3>
          <FoldersSelect
            v-model="selectedFolder"
            :select-label="$t('text_document_templates.save_text_document_modal.save_here')"
            :show-folders="isHrisActive"
          />
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkOroraButton
        :loading="isUploadingToDocumentsV2 ? loadingCreateV2 : loadingCreate"
        @click="handleSubmit"
      >
        {{ $t('text_document_templates.save_text_document_modal.next') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import { svcDocumentV2Client } from '@skello-utils/clients';
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { captureException } from '@sentry/vue';

import { MODAL_SHOW_EVENT } from '@skelloapp/skello-ui';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import FoldersSelect from '../../users/pages/documents/hris_pack/FoldersSelect';

export default {
  name: 'SaveTextDocumentModal',
  components: { FoldersSelect },
  data() {
    return {
      selectedFolder: (this.$route && this.$route.query.folder) || '',
      loadingCreateV2: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('employeeDocuments', ['documents']),
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('textDocumentTemplates', {
      loadingCreate: state => state.loadingCreate,
      textDocument: state => state.selectedTextDocument,
    }),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('currentOrganisation', ['isHrisActive']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('employeeDocuments', ['documentsByFolder']),
    isUploadingToDocumentsV2() {
      return this.isDevFlagEnabled('FEATUREDEV_UPLOAD_DOCS_TO_V2');
    },
    employeeFullName() {
      return this.textDocument ? this.textDocument.attributes.employeeFullName : null;
    },
    employeeId() {
      return this.textDocument ? this.textDocument.employeeId : null;
    },
    title: {
      get() {
        return this.textDocument ? this.textDocument.attributes.title : null;
      },
      set(title) {
        if (this.textDocument) {
          this.textDocument.attributes.title = title;
        }
      },
    },
    trackingOptions() {
      return {
        submit: 'hris_confirmed_window_doc',
        cancel: 'hris_cancel_window_doc',
      };
    },
  },
  watch: {
    employee() {
      if (this.employeeId) {
        this.fetchDocuments({ employeeId: this.employeeId });
      }
    },
    textDocument(textDocument) {
      this.selectedFolder = textDocument.attributes.folder;
    },
  },
  created() {
    if (this.employeeId) {
      this.fetchDocuments({ employeeId: this.employeeId });
    }
  },
  methods: {
    ...mapActions('employeeDocuments', ['fetchDocuments']),
    ...mapActions('textDocumentTemplates', ['createTextDocument']),
    ...mapMutations('textDocumentTemplates', ['selectTextDocument']),
    async handleSubmit(event) {
      if (this.emptyBody()) {
        this.$skToast({
          message: this.$t('text_document_templates.save_text_document_modal.empty_content_document'),
          variant: 'error',
        });

        return;
      }

      if (!this.textDocument.attributes.title) {
        this.$skToast({
          message: this.$t('text_document_templates.save_text_document_modal.empty_title'),
          variant: 'error',
        });

        return;
      }

      let generateEvent;

      try {
        if (this.isUploadingToDocumentsV2) {
          generateEvent = await this.handleTextDocumentV2();
        } else {
          generateEvent = await this.handleTextDocument();
        }

        this.$skToast({
          message: this.$t('text_document_templates.text_document_created'),
          variant: 'success',
          dimension: 'short',
        });

        this.$nextTick(() => {
          this.emitOnRoot(MODAL_SHOW_EVENT, event, 'text-document-created-modal');
        });

        try {
          await this.$svcEvents.create(
            EVENT_SUBTYPE_ACTION.EMPLOYEE_DOCUMENT_UPLOADED,
            generateEvent,
          );
        } catch (error) {
          captureException(error);
        }

        this.closeModal();
      } catch (error) {
        this.$skToast({
          message: this.$t('text_document_templates.save_text_document_modal.error'),
          variant: 'error',
        });
      }
    },
    async handleTextDocument() {
      const params = {
        text_document: {
          text_document_template_id: this.textDocument.attributes.textDocumentTemplateId,
          title: this.textDocument.attributes.title,
          body: this.textDocument.attributes.body,
          folder: this.selectedFolder,
          employee_id: this.textDocument.attributes.employeeId,
        },
        cluster_node_id: this.navContext.clusterNodeId,
      };

      const response = await this.createTextDocument({ params });

      return {
        employee: this.employee,
        documentId: response.data.data.id,
        documentName: response.data.data.attributes.title,
      };
    },
    async handleTextDocumentV2() {
      this.loadingCreateV2 = true;

      const dto = {
        creatorId: this.currentUser.id,
        fileName: this.textDocument.attributes.title,
        mimeType: 'application/pdf',
        templateName: 'TextDocument',
        templateVariables: JSON.stringify({ body: this.textDocument.attributes.body }),
        folderPath: `/${this.selectedFolder}`,
        employeeId: this.textDocument.attributes.employeeId,
      };

      // This needs cleanup when we have migrated to svcDocV2 and removed the old svcDoc
      const response = (await svcDocumentV2Client.document.generate([dto]))[0];
      this.selectTextDocument({
        ...this.textDocument,
        relationships: {
          user: {
            attributes: {
              id: this.textDocument.attributes.employeeId,
              email: this.employee.attributes.email,
            },
          },
          document: {
            id: response.id,
            attributes: {
              title: response.fileName,
              fileName: response.fileName,
              createdAt: response.createdAt,
            },
            relationships: {
              user: {
                attributes: {
                  id: this.textDocument.attributes.employeeId,
                  firstName: this.employee.attributes.firstName,
                  lastName: this.employee.attributes.lastName,
                  email: this.employee.attributes.email,
                },
              },
            },
          },
        },
      });
      this.loadingCreateV2 = false;

      return {
        employee: this.employee,
        documentId: response.id,
        documentName: response.fileName,
      };
    },
    emptyBody() {
      return this.textDocument.attributes.body === '<h1><br></h1>' ||
        this.textDocument.attributes.body === null;
    },
    handleCancel() {
      this.selectedFolder = this.$options.data().selectedFolder;
    },
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      if (this.$refs.modal) {
        this.$refs.modal.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.save-text-document-modal__folder-section {
  margin-top: 20px;
}
</style>
