<template>
  <TitlesColumn class="titles-column">
    <div class="employee-counters__initialization-date-wrapper">
      <InitDateLabel
        tracker="pto"
        :init-date="initDate"
      />
    </div>
    <TitlesColumnCell>
      <div class="center-row-container">
        <div v-tooltip.top="$t('employees.tabs.counters.paid_leaves_counter.tooltip.real_or_previsional_computing')">
          {{ $t('employees.tabs.counters.paid_leaves_counter.acquired_by_month') }}
        </div>
        <SkOroraButton
          variant="tertiary"
          size="small"
          @click="handleLearnMoreClick"
        >
          {{ $t('employees.tabs.counters.paid_leaves_counter.learn_more') }}
        </SkOroraButton>
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div v-tooltip="$t('employees.tabs.counters.paid_leaves_counter.tooltip.planning_paid_leaves')">
        {{ $t('employees.tabs.counters.paid_leaves_counter.taken') }}
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div>
        {{ $t('employees.tabs.counters.paid_leaves_counter.n_1_taken') }}
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div>
        {{ $t('employees.tabs.counters.paid_leaves_counter.n_taken') }}
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <span>
        {{ $t('employees.tabs.counters.paid_leaves_counter.remaining_plc_before_manual_edit') }}
      </span>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <div>
        <div>
          {{ $t('employees.tabs.counters.paid_leaves_counter.manual_edit') }}
        </div>
        <div class="yearly-distinction__manual_edit__subtitle">
          {{ $t('employees.tabs.counters.paid_leaves_counter.manual_edit_subtitle') }}
        </div>
      </div>
    </TitlesColumnCell>
    <TitlesColumnCell>
      <span>
        {{ $t('employees.tabs.counters.paid_leaves_counter.remaining_plc_after_manual_edit') }}
      </span>
    </TitlesColumnCell>
  </TitlesColumn>
</template>

<script>
import {
  TitlesColumn,
  TitlesColumnCell,
  InitDateLabel,
} from '../../shared/components';

export default {
  name: 'YearlyDistinctionTitlesColumn',
  components: { TitlesColumn, TitlesColumnCell, InitDateLabel },
  props: {
    initDate: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    handleLearnMoreClick() {
      window.open(this.$t('employees.tabs.counters.paid_leaves_counter.link'), '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.titles-column {
  padding-top: 0;
}

.yearly-distinction__manual_edit__subtitle {
  color: $sk-grey;
  font-size: $fs-text-xs;
}

.employee-counters__initialization-date-wrapper {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $sk-black;
}

.center-row-container {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
</style>
