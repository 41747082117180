<template>
  <img
    width="60"
    src="./images/logo-tiller.png"
  >
</template>

<script>
export default {
  name: 'Tiller',
};
</script>
