<template>
  <td>
    <div class="user-cell">
      <AvatarMedallion
        size="medium"
        :avatar-url="avatarUrl"
        :medallion-text="userInitials"
      />

      <div
        v-tooltip="userName"
        class="user-cell--content"
      >
        <p>
          {{ userName }}
        </p>
      </div>
    </div>
  </td>
</template>

<script>
import { mapGetters } from 'vuex';
import AvatarMedallion from '@app-js/shared/components/AvatarMedallion';

export default {
  name: 'UserSidebarCell',
  components: { AvatarMedallion },
  props: {
    userId: {
      type: String,
      required: true,
    },
    userFirstName: {
      type: String,
      required: true,
    },
    userLastName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('employees', ['getAvatarUrlForUser']),
    userInitials() {
      return `${this.userFirstName.toUpperCase().charAt(0)}${this.userLastName.toUpperCase().charAt(0)}`;
    },
    userName() {
      return `${this.userFirstName} ${this.userLastName}`;
    },
    avatarUrl() {
      return this.getAvatarUrlForUser(this.userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-cell {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0 12px;

  &--content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p{
      font-size: $fs-text-s;
      font-weight: $fw-regular;
      line-height: 13.38px;
      max-width:90px;
      max-height:26px;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin:0
    }
  }
}
</style>
