<template>
  <div
    :ref="`analytics_dashboard_card-container-${cardName}`"
    :class="cardContainerClasses"
    @mouseleave="clearTooltip"
  >
    <div :class="cardHeaderClasses">
      <div
        v-tooltip="titleTooBigTooltip"
        class="analytics_dashboard__card-left-container"
      >
        <h1
          :ref="`analytics_dashboard_card_title-${cardName}`"
          class="analytics_dashboard__card-title-container"
        >
          <span
            v-for="(value, index) in title"
            :key="index"
            class="analytics_dashboard__card-title"
          >
            {{ value }}
            <span
              v-if="index != title.length - 1"
              class="analytics_dashboard__card-vs"
            >
              {{ $t('analytics_dashboard.cards.versus') }}
            </span>
          </span>
          <span
            v-if="titleDetails"
            class="analytics_dashboard__card-title-details"
          >
            {{ titleDetails }}
          </span>
        </h1>
        <template v-if="subtitle">
          <div
            v-if="isDataLoading"
            class="analytics_dashboard__card-subtitle-loading"
          />
          <span
            v-else
            class="analytics_dashboard__card-subtitle"
          >
            {{ subtitle }}
          </span>
        </template>
      </div>
      <template v-if="showGranularity">
        <div
          v-if="isDataLoading"
          class="analytics_dashboard__sk-select-loading"
        >
          <div class="analytics_dashboard__sk-select-loading__color" />
          <CaretIcon fill="#000000" />
        </div>
        <SkSelectV2
          v-else
          v-model="selectedGranularity"
          class="analytics_dashboard__sk-select"
          :options="granularityOptions"
          :data-test="getSelectedGraph"
        />
      </template>
    </div>
    <div
      v-if="isDataLoading"
      class="analytics_dashboard__card-spinner"
    >
      <SkLoader size="large" />
    </div>
    <div
      v-else
      :id="'body-' + chartComponent"
      :class="cardBodyClasses"
    >
      <div
        v-if="isCardLoading"
        class="analytics_dashboard__card-body-loading"
      >
        <SkLoader size="large" />
      </div>

      <div
        v-else
        :class="apexChartWrapperClasses"
        @scroll="clearTooltip"
        @mouseover="setTooltipContent"
      >
        <component
          :is="chartComponent"
          :id="'component-' + cardName"
          :chart-width="chartWidth"
          :categories="categories"
          :series="series"
          :series-colors="seriesColors"
          :unit="unit"
          @data-event="updateTooltipData"
          @load="applyBorderRadiusCss"
        />
      </div>

      <ChartLegend
        v-if="cardName !== 'revenues'"
        :series="series"
        :series-colors="seriesColors"
        :position="size === 'medium' ? 'right' : 'bottom'"
      />

      <CircledINoFillIcon
        v-if="showNonFullPeriodIcon"
        v-tooltip.bottom="tooltipGranularity"
        fill="#727272"
        width="16"
        height="16"
        class="circled-exclamation-mark-icon"
      />
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <!--
        We use v-show instead of v-if because we need the component in the DOM
        To get the element by Id at the initialisation.
      -->
    <div
      v-show="showPopper"
      :id="'popper-' + cardName"
      class="popper-analytics__wrapper"
      v-html="tooltipContent"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import AnalyticsTooltip from '@app-js/shared/components/Tooltips/AnalyticsTooltip';
import StackedColumnTooltip from '@app-js/shared/components/Tooltips/StackedColumnTooltip';
import { createPopper } from '@popperjs/core';
import skDate from '@skello-utils/dates';
import ChartLegend from '../Charts/ChartLegend';
import BasicColumnChart from '../Charts/BasicColumnChart';
import StackedColumnChart from '../Charts/StackedColumnChart';
import MixedLineAreaChart from '../Charts/MixedLineAreaChart';
import NumberComparison from '../Charts/NumberComparison';

const SIZES = ['small', 'medium', 'large', 'extra-large', 'vertical'];

const CustomAnalyticsTooltip = Vue.extend(AnalyticsTooltip);
const CustomStackedTooltip = Vue.extend(StackedColumnTooltip);

export default {
  name: 'Card',
  components: {
    ChartLegend,
    BasicColumnChart,
    StackedColumnChart,
    MixedLineAreaChart,
    NumberComparison,
    AnalyticsTooltip,
  },
  props: {
    title: {
      type: Array,
      required: true,
    },
    titleDetails: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: true,
      validator: size => SIZES.includes(size),
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    cardName: {
      type: String,
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    tooltipSubtitle: {
      type: String,
      required: false,
      default: null,
    },
    chartComponent: {
      type: String,
      required: false,
      default: 'div',
    },
    computationParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // Show green or red arrow in the background
    // Based on metrics relative performance
    showPerformanceBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDataLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      granularity: null,
      tooltipContent: '',
      isCardLoading: false,
      titleTooBigTooltip: '',
      popper: null,
      previousDataPointIndex: -1,
      chartWidth: 0,
    };
  },
  computed: {
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentOrganisation', ['currentOrganisation']),

    showNonFullPeriodIcon() {
      if (this.isNumberComparisonCard ||
        this.granularity === 'day' ||
        this.metrics[0].data.length === 0
      ) return false;

      const startDate = skDate(this.metrics[0].data[0].date);
      const endDate = skDate(this.metrics[0].data.slice(-1)[0].date);
      if (this.granularity === 'week' &&
        startDate.isMonday() &&
        endDate.isSunday()) {
        return false;
      }
      // Check if full month
      if (this.granularity === 'month' &&
        startDate.date() === 1 &&
        endDate.date() === endDate.daysInMonth()) {
        return false;
      }

      // Check if there is no data in the cards (all value === 0)
      return this.metrics[0].data.some(data => data.value > 0) ||
        this.metrics[1].data.some(data => data.value > 0);
    },
    tooltipGranularity() {
      if (this.granularity === 'day') return '';

      return this.$t(`analytics_dashboard.cards.granularity.period_tooltip.${this.granularity}`);
    },
    isPackOfferPremium() {
      return this.currentOrganisation.attributes.packOffer.name === 'premium';
    },
    showGranularity() {
      return !this.isNumberComparisonCard;
    },
    selectedGranularity: {
      get() {
        return this.granularity;
      },
      set(newValue) {
        if (this.granularity !== newValue) {
          this.isCardLoading = true;
          this.granularity = newValue;

          const preferences = localStorage.getItem(`dashboard_granularity_user_${this.currentUser.id}`);
          const preferencesUpdated = preferences ? JSON.parse(preferences) : {};
          preferencesUpdated[this.cardName] = newValue;

          localStorage.setItem(
            `dashboard_granularity_user_${this.currentUser.id}`,
            JSON.stringify(preferencesUpdated),
          );

          // Charts width need reset after granularity change because it changes the length of the categories
          this.resetChartWidth();

          setTimeout(() => {
            this.isCardLoading = false;
          }, 300);

          this.$skAnalytics.track('new_dashboard', {
            source: this.cardName,
            value: newValue,
          });
        }
      },
    },
    seriesColors() {
      return this.metrics.map(metric => metric.color || '#FB8C76');
    },
    isSpinnerActive() {
      return this.isDataLoading || this.isCardLoading;
    },
    cardBodyClasses() {
      return {
        'analytics_dashboard__card-body': true,
        'analytics_dashboard__card-body__content-centered': this.isNumberComparisonCard,
      };
    },
    cardContainerClasses() {
      const classes = {
        'analytics_dashboard__card-container': true,
        [`analytics_dashboard__card-container-${this.size}`]: true,
      };
      if (this.showPerformanceBackground && !this.isPerformanceNeutral) {
        const backgroundClass = this.metrics.length === 2 && this.isPerformancePositive ?
          'analytics-dashboard__card-green-background' :
          'analytics-dashboard__card-red-background';

        classes[backgroundClass] = true;
      }

      return classes;
    },
    cardHeaderClasses() {
      return {
        'analytics_dashboard__card-header': true,
        'analytics_dashboard__card-header-margin': this.isNumberComparisonCard,
        'analytics_dashboard__card-header-margin--loading': this.isNumberComparisonCard && this.isSpinnerActive,
      };
    },
    tooltipClasses() {
      // Smaller tooltip when no difference is shown (=> no predicted or type of graph)
      const condensedTooltip = (!this.isPackOfferPremium && this.isPredictedPremium) ||
                                !this.showDifferenceInTooltip;

      return {
        'analytic-tooltip__dashboard': true,
        [`analytic-tooltip__dashboard-${condensedTooltip ? 'min' : 'max'}`]: true,
      };
    },
    apexChartWrapperClasses() {
      return {
        'apex-chart-wrapper': true,
        'apex-chart-wrapper__show-scroll': this.isScrollbarNeeded,
      };
    },
    computedWidth() {
      // Dynamic width with arbitrary multiplier so that
      // we have an aerated design.
      return this.categories.length * 50;
    },
    isScrollbarNeeded() {
      // The scroll will happen only when the width needed
      // is larger than the availableWith in the card.
      return !this.isNumberComparisonCard &&
        this.computedWidth >= this.getAvailableWidth();
    },
    granularityOptions() {
      return [
        { id: 'day', text: this.$t('analytics_dashboard.cards.granularity.day') },
        { id: 'week', text: this.$t('analytics_dashboard.cards.granularity.week') },
        { id: 'month', text: this.$t('analytics_dashboard.cards.granularity.month') },
      ];
    },
    getSelectedGraph() {
      if (this.cardName === 'revenue_ratio') return 'select-graph-ratio';
      if (this.cardName === 'details_predicted_turnover_vs_real') return 'select-graph-detail-CA';
      if (this.cardName === 'real_salary_mass_with_costs') return 'select-graph-MS-realise';
      if (this.cardName === 'details_salary_mass_with_costs') return 'select-graph-MS-charge';

      return '';
    },
    // Reformat the metrics data to [metric1, metric2, ...] with
    // metric1 and metric2 = { '18/09': 13, '19/09': 10, ... } or { 'W22': 13, 'W23': 10, ... }
    // or {'Oct 22': 13, 'Nov 22': 10, ...} depending on the granularity
    formattedMetrics() {
      if (!this.metrics || this.metrics.length === 0) return [];

      const formattedDataMetrics = this.computeGranularity('data');
      if (this.computationParams.metricsType !== 'ratio') {
        return formattedDataMetrics;
      }

      const formattedRatioData = this.computeGranularity('ratioData');
      return formattedDataMetrics.map((formattedDataMetric, index) => this.computeRatio(
        formattedDataMetric,
        formattedRatioData[index],
      ));
    },
    // Apex chart categories : ['18/09', '19/90', ...] or ['W22', 'W23'] or ['Oct 22', 'Nov 22']
    categories() {
      if (!this.formattedMetrics || this.formattedMetrics.length === 0) return [];

      // Categories are either '18/09' or 'W22' or 'Sept 22' depending of the granularity
      return Object.keys(this.formattedMetrics[0]).map(this.labelFromDateAndGranularity);
    },
    series() {
      if (!this.formattedMetrics || this.formattedMetrics.length === 0) return [];

      const series = this.metrics
        .map((metric, index) => ({
          premiumMetric: metric.premiumMetric,
          type: metric.chartType,
          name: metric.legendLabel,
          data: !this.isPackOfferPremium && metric.premiumMetric ?
            [] :
            Object.values(this.formattedMetrics[index]),
          total: Object.values(this.formattedMetrics[index]).reduce((acc, value) => (
            acc + value
          ), 0),
        }));

      return series;
    },
    // "Positive Performance" is when 2nd serie total is greather or equal than the 1st series total
    isPerformancePositive() {
      return this.series[1].total > this.series[0].total;
    },
    isPerformanceNeutral() {
      return this.series[0].total === this.series[1].total;
    },
    showPopper() {
      return !!this.tooltipContent && !this.isDataLoading;
    },
    isNumberComparisonCard() {
      return this.chartComponent === 'NumberComparison';
    },
    // Show tooltip difference if the chart is not a StackedColumnChart and if all metrics are allowed
    // The purpose of StackedColumnChart is to visualize proportions but not to calculate a difference between metrics
    showDifferenceInTooltip() {
      return this.chartComponent !== 'StackedColumnChart' &&
        (this.isPackOfferPremium || !this.isPredictedPremium);
    },
    isPredictedPremium() {
      return this.metrics[0].premiumMetric;
    },
    tooltipLabels() {
      return this.metrics.map(metric => metric.tooltipLabel);
    },
  },
  watch: {
    // Edge case: if you change the date while the pointer is on the card,
    // We want to reload the tooltip information without having to move the pointer
    isDataLoading(oldValue, newValue) {
      if (this.isNumberComparisonCard && newValue && newValue !== oldValue) {
        this.clearTooltip();
      }
    },
  },
  beforeUpdate() {
    // First call before resize
    this.resetChartWidth();
  },
  mounted() {
    this.setGranularity();
    this.setEllipsis();

    // For subsequent calls when resizing the window
    window.addEventListener('resize', this.resetChartWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetChartWidth);
  },
  methods: {
    resetChartWidth() {
      const availableWidth = this.getAvailableWidth();

      this.chartWidth = this.computedWidth < availableWidth ?
        availableWidth :
        this.computedWidth;
    },
    instantiatePopper(anchorElement, x, y) {
      if (!this.popper) {
        // https://popper.js.org/docs/v2/
        this.popper = createPopper(
          anchorElement,
          document.getElementById(`popper-${this.cardName}`),
          {
            placement: 'bottom-start',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [x, y],
                  preventOverflow: { enabled: false },
                  flip: { enabled: false },
                },
              },
            ],
          });
      }
    },
    // We clear the tooltip when the user
    // scrolls on the chart or leaves a chart.
    clearTooltip() {
      this.tooltipContent = '';

      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    // Here, we set the tooltip content only for the first card: NumberComparison
    // Because the tooltip is handle differently than for the charts
    setTooltipContent() {
      if (!this.isNumberComparisonCard) return;

      const element = document.querySelector('#component-revenues');
      if (!element) return;

      this.instantiatePopper(element, 0, 0);

      this.tooltipContent = this.getTooltipContent(
        { series: this.series, dataPointIndex: 0 },
        false,
      );
    },
    updateTooltipData({ series, dataPointIndex }) {
      if (this.previousDataPointIndex !== dataPointIndex ||
          !this.seriesIsEmpty(series, dataPointIndex)) {
        this.previousDataPointIndex = dataPointIndex;
        this.clearTooltip();
        return;
      }

      const element = document.querySelector(`#component-${this.cardName} .alex-special`);
      if (!element) return;

      this.instantiatePopper(
        element,
        element.getBoundingClientRect().x,
        // +30 is to give 30px margin between the tooltip and the element
        element.getBoundingClientRect().y + 30,
      );

      this.tooltipContent = this.getTooltipContent({ series, dataPointIndex });
    },
    getTooltipContent(datas, isFromApex = true) {
      const tooltipDatas = this.getTooltipDatas(datas, isFromApex);
      const tooltip = this.generateTooltip(tooltipDatas);

      return this.htmlFromTooltip(tooltip);
    },
    generateTooltip(tooltipDatas) {
      return this.chartComponent === 'StackedColumnChart' ?
        new CustomStackedTooltip(tooltipDatas) :
        new CustomAnalyticsTooltip(tooltipDatas);
    },
    getTooltipDatas(datas, isFromApex) {
      return {
        parent: this,
        propsData: {
          tooltipData: this.generateTooltipData(datas, isFromApex),
          backgroundColor: 'white',
          colorText: 'black',
          labelColors: this.seriesColors,
          showPredicted: this.isPackOfferPremium || !this.isPredictedPremium,
          showDifference: this.showDifferenceInTooltip,
          customLabels: this.tooltipLabels,
          subtitle: this.tooltipSubtitle,
        },
      };
    },
    htmlFromTooltip(tooltipInstance) {
      tooltipInstance.$mount();

      // Get all the active class and create a string from that like : "analytic-tooltip__dashboard analytic-tooltip__dashboard-max"
      const tooltipClasses = Object.keys(this.tooltipClasses).join(' ');
      const returnHTML = `<div class="${tooltipClasses}">${tooltipInstance.$el.innerHTML}</div>`;

      tooltipInstance.$destroy();
      return returnHTML;
    },
    generateTooltipData(datas, isFromApex) {
      return this.chartComponent === 'StackedColumnChart' ?
        this.stackedData(datas) :
        this.analyticsData(datas, isFromApex);
    },
    stackedData({ series, dataPointIndex }) {
      const firstValue = this.roundTwo(series[0][dataPointIndex]);
      const secondValue = this.roundTwo(series[1][dataPointIndex]);

      return {
        title: this.getDisplayedTitle(true),
        firstValue,
        secondValue,
        unit: this.unit,
      };
    },
    analyticsData({ series, dataPointIndex }, isFromApex) {
      let differenceComputation = 0;
      let differencePercentage = 0;
      const realValue = isFromApex ?
        this.roundTwo(series[1][dataPointIndex]) :
        this.roundTwo(series[1].total);
      const predictedValue = isFromApex ?
        this.roundTwo(series[0][dataPointIndex]) :
        this.roundTwo(series[0].total);

      if (this.isPackOfferPremium || !this.isPredictedPremium) {
        differenceComputation = realValue - predictedValue;
        differencePercentage = this.safeDivision(
          differenceComputation,
          predictedValue,
        ) * 100;
      }

      const tooltipData = {
        title: this.getDisplayedTitle(isFromApex),
        predictedValue: this.isPackOfferPremium || !this.isPredictedPremium ?
          predictedValue : 0,
        realValue,
        unit: this.unit,
        differenceComputation,
        differencePercentage,
        performance: this.arrowColor(differenceComputation),
        arrow: this.performance(differenceComputation),
      };

      return tooltipData;
    },
    computeGranularity(dataFieldName) {
      return this.metrics.map(metric => metric[dataFieldName].reduce((acc, { date, value }) => {
        // Sum the metric values by granularity (may be day, week or month).
        // Get the first day of the granularity (day, week of month) in format YYYY-MM-DD
        const key = this.isoStringFromGranularity(date);

        if (!acc[key]) acc[key] = 0;

        // Add the value to the right label (to have the sum per day, week or month)
        acc[key] += value;
        return acc;
      }, {}),
      );
    },
    computeRatio(firstMetric, secondMetric) {
      return Object.keys(firstMetric)
        .reduce((acc, key) => {
          acc[key] = this.safeDivision(firstMetric[key], secondMetric[key]) * 100;
          return acc;
        }, {},
        );
    },
    seriesIsEmpty(series, dataPointIndex) {
      // We don't want to display the tooltip if all the values equal 0
      return series.some(serie => serie[dataPointIndex] > 0);
    },
    getDisplayedTitle(isFromApex) {
      const titleToDisplay = this.title.join(` ${this.$t('analytics_dashboard.cards.versus')} `);

      if (!isFromApex) {
        return `<div>
          ${titleToDisplay}
          <span class="analytics_dashboard__card-title-details">
            ${this.titleDetails}
          </span>
        </div>`;
      }

      return titleToDisplay;
    },
    safeDivision(dividend, divisor) {
      if (divisor === null || divisor === 0) return 0;
      return dividend / divisor;
    },
    performance(differenceComputation) {
      if (differenceComputation === 0) return 'neutral';

      return differenceComputation > 0 ? 'positive' : 'negative';
    },
    arrowColor(differenceComputation) {
      return this.computationParams.objective === 'decrease' ?
        this.performance(differenceComputation * -1) :
        this.performance(differenceComputation);
    },
    setGranularity() {
      const preferences = localStorage.getItem(`dashboard_granularity_user_${this.currentUser.id}`);
      this.granularity = (preferences && JSON.parse(preferences)[this.cardName]) || 'week'; // id of the granularityOptions
    },
    setEllipsis() {
      const el = this.$refs[`analytics_dashboard_card_title-${this.cardName}`];
      if (!el) return;

      this.$nextTick(() => {
        const isEllipsisActive = el.offsetWidth < el.scrollWidth;

        if (isEllipsisActive) this.titleTooBigTooltip = this.title;
      });
    },
    labelFromDateAndGranularity(date) {
      switch (this.selectedGranularity) {
        case 'week':
          return this.$t('analytics_dashboard.cards.granularity.legend.week', { value: skDate(date).format('WW') });
        case 'month':
          return skDate(date).format('MMM YY');
        default:
          return skDate(date).format('DD/MM');
      }
    },
    isoStringFromGranularity(date) {
      const startKey = this.selectedGranularity === 'week' ? 'isoWeek' : this.selectedGranularity;
      return skDate(date).startOf(startKey).format('YYYY-MM-DD');
    },
    roundTwo(number) {
      const float = parseFloat(number);

      if (isNaN(float)) return 0;

      return (Math.round(Math.abs(float) * 100) / 100);
    },
    getAvailableWidth() {
      const wrapperEl = this.$refs[`analytics_dashboard_card-container-${this.cardName}`];
      if (!wrapperEl) return 0;

      const wrapperWidth = Math.floor(wrapperEl.getBoundingClientRect().width);
      const wrapperPadding = 24;
      let substract = 0;

      // Here the space we substract depends on the card size from responsiveness
      // When the legend is on the right we need to substract more space
      // There is a little bit more space to substract due to internal paddings
      const legendOnRight = (this.size === 'medium' && window.innerWidth > 760) ||
       (this.size === 'small' && window.innerWidth > 760 && window.innerWidth < 1140);

      if (this.size === 'small') {
        substract = legendOnRight ? 162 : wrapperPadding * 2;
      } else if (this.size === 'medium') {
        substract = legendOnRight ? 210 : wrapperPadding * 2;
      }

      return wrapperWidth - substract;
    },
    /**
     * The lib apexchart has an issue for rounded corner on stacked column,
     * when you have inconsistent number of series.
     * The hack here is to apply the css dynamically when we load the card component.
     *
     * See issue on apexChart github: https://github.com/apexcharts/apexcharts.js/issues/1779
     */
    applyBorderRadiusCss() {
      const card = document.getElementById(`component-${this.cardName}`);
      const cardBars = Array.from(card.querySelectorAll('.apexcharts-series:nth-of-type(1) > path'));

      /**
      * In our case, stacked column displays 0 to 2 series.
      * We want to apply the radius css to the first serie only,
      * when the value is greater than 0, and the second serie has no value.
      * It means, when only the first serie is displayed.
      */
      this.series[0].data.forEach((value, index) => {
        if (value > 0 && this.series[1].data[index] === 0) {
          cardBars[index].classList.add('apexcharts-bar__fix-stacked-column-radius');
        }
      });
    },
  },
};
</script>

<style lang="scss">
.apex-chart-wrapper {
  &__show-scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    margin-right: 20px;
    padding-right: 20px;
    padding-bottom: 0;
  }

/* Force webkit browsers to show a scrollbar
 * (To make it independent from the OS configuration)
 * Does not apply for Firefox
 */
  &__show-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &__show-scroll::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &__show-scroll::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &__show-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;

    /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .2);
  }

  &__show-scroll::-webkit-scrollbar-track {
    background-color: $sk-white;
    border-radius: 8px;
  }
}

.analytic-tooltip__dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 260px;
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, .16);
  background: white;

  &-min {
    min-height: 77px;
  }

  &-max {
    min-height: 110px;
  }

  .kpi-tooltip__title {
    white-space: normal;
    margin-bottom: 5px;
  }
}

.popper-analytics__wrapper {
  opacity: 1;
  z-index: 100;
}

.analytics_dashboard__card-title-details {
  font-weight: $fw-regular;
  font-style: italic;
  color: $sk-grey;
}

// overwrite the apexchart lib design
// to remove the grey border
.apexcharts-tooltip {
  border: solid 1px transparent !important;
}

// Used to apply rounded corner to stacked column
.apexcharts-bar__fix-stacked-column-radius {
  clip-path: inset(0% 0% -4px 0% round 4px);
}
</style>

<style lang="scss" scoped>
.analytics_dashboard__card-body {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .circled-exclamation-mark-icon {
    position: absolute;
    bottom: 5px;
    right: 0;
  }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.analytics_dashboard__card-container-medium {
  .analytics_dashboard__card-body {
    flex-direction: row;

    @media (max-width: 760px) {
      flex-direction: column;
    }

    &-loading {
      width: 606px;
    }
  }

  .sk-chart__legend {
    flex-direction: column;
    height: 55px;
    margin-top: 11px;

    @media (max-width: 760px) {
      flex-direction: row;
      height: 30px;
      margin-top: -5px;
      margin-left: 0;
    }
  }
}

.analytics_dashboard__card-container-small {
  @media (min-width: 760px) and (max-width: 1139px) {
    .analytics_dashboard__card-body {
      flex-direction: row;
    }

    .sk-chart__legend {
      flex-direction: column;
      height: 55px;
      margin-top: 11px;
    }
  }
}

.analytics_dashboard__card-container {
  background-color: $sk-white;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, .1);
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: 270px;

  &-large {
    height: 256px;
    width: 1260px;
  }

  &-vertical {
    height: 536px;
    width: 404px;
  }

  &-extra-large {
    height: 536px;
    width: 832px;
  }

  @media (max-width: 1139px) {
    margin-right: 0;

    &-small,
    &-medium {
      width: 100%;
    }
  }

  /* dynamique value size of card in percent the
  value is validated by product design */
  @media (min-width: 1140px) {
    &-small {
      width: 32%;
    }

    &-medium {
      width: 66%;
    }
  }
}

.analytics_dashboard__card-header {
  display: flex;
  justify-content: space-between;

  @media (min-width: 1140px) {
    line-height: 1.3;
  }

  // We're using v-deep to overwrite SkSelectV2
  ::v-deep .sk-select__selected-option.sk-select__selected-option--filled {
    background: transparent !important; // doesn't work without !important
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding-right: 0;
    height: fit-content;

    .text-truncate {
      color: $sk-grey;
    }

    .sk-select__caret {
      margin: 0;
      margin-left: 10px;

      path {
        fill: $sk-black;
      }
    }
  }
}

.analytics_dashboard__card-title-container {
  font-size: $fs-text-m;
  margin-bottom: -1px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.analytics_dashboard__card-title {
  font-weight: $fw-semi-bold;
  color: $sk-black;
  margin-right: 2px;
  white-space: nowrap;
}

.analytics_dashboard__sk-select {
  width: 96px !important;

  &-loading {
    display: flex;
    align-items: center;

    &__color {
      background-color: $sk-grey-10;
      height: 18px;
      width: 55px;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
}

.analytics_dashboard__card-left-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.analytics_dashboard__card-subtitle {
  font-style: italic;
  font-size: $fs-text-m;
  color: $sk-grey;

  &-loading {
    width: 176px;
    height: 18px;
    background-color: $sk-grey-10;
    border-radius: 2px;
    margin-top: 8px;
  }
}

.analytics-dashboard__card-green-background {
  background: url('../shared/img/main-card-green-background.svg') no-repeat bottom right;
}

.analytics-dashboard__card-red-background {
  background: url('../shared/img/main-card-red-background.svg') no-repeat top right;
}

.analytics_dashboard__card-vs {
  font-weight: normal;
  font-style: italic;
}

.analytics_dashboard__card-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.analytics_dashboard__card-body__content-centered {
  justify-content: center;
  align-items: center;
}
</style>
