<template>
  <div>
    <PeriodSummariesSection
      v-if="hasYearlyDistinction && hasPaidLeavesCounter"
      :is-loading="periodSummariesLoading"
      :period-summaries="computePeriodSummaries()"
    />
    <div class="employee-counters__wrapper">
      <div
        v-if="!hasPaidLeavesCounter && hasYearlyDistinction"
        class="employee-counters__wrapper--initialization"
      />
      <div
        v-if="plcLoading"
        class="counters-spinner__wrapper"
      >
        <SkLoader />
      </div>
      <PaidLeavesCounterTable :has-yearly-distinction="hasYearlyDistinction" />
      <InitializationOverlay
        v-if="!hasPaidLeavesCounter"
        :title="$t('employees.tabs.counters.paid_leaves_counter.welcome')"
        :description="$t('employees.tabs.counters.paid_leaves_counter.helper')"
        icon="CalendarClockIcon"
      />
    </div>
    <div
      v-if="hasOldCalculationCounter"
      class="employee-paid-leaves-counter__old-calculation"
    >
      <div class="employee-paid-leaves-counter__old-calculation__title">
        {{ $t('employees.tabs.counters.paid_leaves_counter.old_calculation.title') }}
      </div>
      <p>
        {{ $t('employees.tabs.counters.paid_leaves_counter.old_calculation.text') }}
      </p>
    </div>
    <SkInfoCard
      v-if="hasPaidLeavesCounter"
      class="employee-counters__info-card"
    >
      <div class="employee-counters__info-card__title">
        {{ $t('employees.tabs.counters.paid_leaves_counter.explanation_card.title') }}
      </div>
      <template>
        <!-- TODO: DEV-9880 - create specific component to parse translation with html -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="explanationCardText" />
      </template>
    </SkInfoCard>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import skDate from '@skello-utils/dates';
import { nlToBr } from '@skello-utils/formatting/strings';

import InitializationOverlay from './InitializationOverlay';
import PaidLeavesCounterTable from './PaidLeavesCounterTable';
import PeriodSummariesSection from './PeriodSummariesSection';

export default {
  name: 'PaidLeaveCounter',
  components: { InitializationOverlay, PaidLeavesCounterTable, PeriodSummariesSection },
  data() {
    return {
      tableWidth: null,
      horizontallyScrolled: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('employeePaidLeavesCounter', [
      'periodSummariesLoading',
      'periodSummaries',
      'plcLoading',
      'plcStartMonth',
    ]),
    ...mapGetters('selectedEmployee', ['hasPaidLeavesCounter']),
    ...mapGetters('currentShop', ['checkFeatureFlag']),

    explanationCardText() {
      const text =
        this.$t(`employees.tabs.counters.paid_leaves_counter.explanation_card.${this.plcStartMonth}.text`);
      return nlToBr(text);
    },
    hasOldCalculationCounter() {
      return skDate(this.employee.attributes.plcInitializationDoneAt) < skDate('2018-06-01');
    },
    hasYearlyDistinction() {
      return this.checkFeatureFlag(
        'FEATURE_N1_N_PTO_TRACKER',
        this.employee.relationships.shop,
        this.employee.relationships.convention,
      );
    },
  },
  mounted() {
    if (this.hasPaidLeavesCounter) {
      this.fetchEmployeePeriodSummaries({ user_id: this.employee.id });
    }
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapMutations('employeePaidLeavesCounter', ['resetState']),
    ...mapActions('employeePaidLeavesCounter', [
      'fetchEmployeePaidLeavesCounter',
      'fetchEmployeePeriodSummaries',
    ]),
    periodTitle(startDate, endDate, periodLabel) {
      return `${startDate.format('Do MMMM YYYY')} - ${endDate.format('Do MMMM YYYY')} ${periodLabel}`;
    },
    periodLabel(isCurrentPeriod) {
      const content = isCurrentPeriod ?
        this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.current_period_title') :
        this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.previous_period_title');

      return `(${content})`;
    },
    computePeriodSummary(periodSummary, displayLabel = false) {
      const currentDate = skDate();
      const startDate = skDate.utc(periodSummary?.range_date?.start_date);
      const endDate = skDate.utc(periodSummary?.range_date?.end_date);
      const isCurrentPeriod = currentDate >= startDate && currentDate <= endDate;
      const isFuturePeriod = currentDate <= startDate;
      const label = isCurrentPeriod || displayLabel ?
        this.periodLabel(isCurrentPeriod) :
        '';

      const title = this.periodTitle(startDate, endDate, label);
      const data = { ...periodSummary };

      return {
        title,
        isCurrentPeriod,
        isBlank: isFuturePeriod ? true : data.is_blank,
        startDate,
        endDate,
        acquired: data.acquired,
        acquiredBalance: data.acquired_balance,
        balance: data.balance,
        deferralFromPreviousPeriod: data.deferral_from_previous_period,
        deferralToNextPeriod: data.deferral_to_next_period,
        manual: data.manual,
        taken: data.taken,
        takenThisPeriod: data.taken_this_period,
        takenFromNextPeriod: data.taken_from_next_period,
      };
    },
    computePeriodSummaries() {
      const selectedPeriod = this.periodSummaries?.selected_period;
      const previousPeriod = this.periodSummaries?.previous_period;

      if (!selectedPeriod || !previousPeriod) {
        return [
          { title: this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.previous_period_title'), isCurrentPeriod: false, isBlank: true },
          { title: this.$t('employees.tabs.counters.paid_leaves_counter.summary_inserts.current_period_title'), isCurrentPeriod: true, isBlank: true },
        ];
      }

      const computedSelectedPeriod = this.computePeriodSummary(selectedPeriod);

      if (!computedSelectedPeriod.isCurrentPeriod) {
        return [computedSelectedPeriod];
      }

      const computedPreviousPeriod = this.computePeriodSummary(previousPeriod, true);

      return [computedPreviousPeriod, computedSelectedPeriod];
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-paid-leaves-counter__old-calculation {
  color: $sk-grey;
  padding: 30px 24px;
  background: white;
}

.employee-paid-leaves-counter__old-calculation__title {
  font-weight: $fw-semi-bold;
  padding-bottom: 5px;
}

.employee-counters__wrapper--initialization {
  padding-top: 40px;
  background: white;
}
</style>
