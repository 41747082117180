<template>
  <!-- eslint-disable  max-len -->
  <div class="employee-counters__wrapper">
    <div
      v-if="loading"
      class="counters-spinner__wrapper"
    >
      <SkLoader />
    </div>
    <div class="employee-counters__table-wrapper">
      <div class="employee-counters__frozen-column">
        <div class="employee-counters__cell">
          <div class="employee-counters__cell__info-wrapper">
            {{ $t('employees.tabs.counters.day_rate_counter.worked_days_count') }}
            <SkOroraButton
              variant="tertiary"
              size="small"
              :href="$t('employees.tabs.counters.day_rate_counter.intercom_link')"
              class="employee-day-rate-counter__subtitle"
              target="blank"
            >
              {{ $t('employees.tabs.counters.day_rate_counter.learn_more') }}
            </SkOroraButton>
          </div>
        </div>
        <div class="employee-counters__cell">
          {{ $t('employees.tabs.counters.day_rate_counter.yearly_allowed_days_count') }}
        </div>
        <div class="employee-counters__cell employee-counters__cell--bold">
          {{ $t('employees.tabs.counters.day_rate_counter.count_before_manual_edit') }}
        </div>
        <div class="employee-counters__cell">
          {{ $t('employees.tabs.counters.day_rate_counter.manual_edit') }}
        </div>
        <div class="employee-counters__cell employee-counters__cell--bold">
          {{ $t('employees.tabs.counters.day_rate_counter.count_after_manual_edit') }}
        </div>
        <div class="employee-counters__cell">
          {{ $t('employees.tabs.counters.day_rate_counter.days_left') }}
        </div>
      </div>
      <div
        ref="countersTable"
        :style="{ width: tableWidth }"
        class="employee-counters__table-scroll"
      >
        <div class="employee-counters__table">
          <YearColumn
            v-for="year in yearsToDisplay"
            :key="year.year"
            :year="year"
            @click="handleClickOnYearColumn(year)"
          >
            <MonthColumn
              v-for="month in year.months"
              :ref="openMonthColumn(month)"
              :key="month.month"
              :month="month"
              @click="handleClickOnMonthColumn(month)"
            >
              <WeekColumn
                v-for="week in month.weeks"
                :key="week.week"
                :week="week"
              />
            </MonthColumn>
          </YearColumn>
        </div>
      </div>
    </div>
    <PackOfferUpsellOverlay
      v-if="!dayRateCounterEnabled"
      :title="$t('employees.tabs.counters.day_rate_counter.welcome')"
      icon="CalendarClockIcon"
    />
    <SkInfoCard
      v-if="dayRateCounterEnabled"
      class="employee-counters__info-card"
    >
      <div class="employee-counters__info-card__title">
        {{ $t('employees.tabs.counters.day_rate_counter.description_title') }}
      </div>
      <div>{{ $t('employees.tabs.counters.day_rate_counter.description') }}</div>
    </SkInfoCard>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import range from 'lodash/range';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

import skDate from '@skello-utils/dates';

import PackOfferUpsellOverlay from './PackOfferUpsellOverlay';
import YearColumn from './YearColumn';
import MonthColumn from './MonthColumn';
import WeekColumn from './WeekColumn';

export default {
  name: 'DayRateCounter',
  components: {
    PackOfferUpsellOverlay,
    YearColumn,
    MonthColumn,
    WeekColumn,
  },
  data() {
    return {
      tableWidth: null,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('employeeCounters', ['loading', 'dayRateYears']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    dayRateCounterEnabled() {
      return this.checkPackOfferFlag('hours_counter_enabled');
    },
    yearsToDisplay() {
      return this.dayRateCounterEnabled ? this.dayRateYears : this.templateYears;
    },
    templateYears() {
      const templateMonths = [];
      const initMoment = skDate().startOf('year');
      range(10).forEach(_value => templateMonths.push({ month: initMoment.add(1, 'month').locale(this._i18n._vm.locale).format('MMM'), show_dash: true, closed: true },
      ));
      return [
        {
          year: skDate().format('YYYY'),
          months: templateMonths,
        },
      ];
    },
  },
  watch: {
    employee(newValue) {
      if (newValue && newValue.attributes.onDayRate) {
        this.fetchSelectedEmployeeDayRateCounter({
          user_id: newValue.id,
          date: skDate().format(),
          silentLoading: false,
        });
      }
    },
    loading(isLoading) {
      if (!isLoading) {
        this.$nextTick(() => {
          this.scrollToMonth();
        });
      }
    },
  },
  mounted() {
    this.fetchSelectedEmployeeDayRateCounter({
      user_id: this.employee.id,
      date: skDate().format(),
      silentLoading: false,
    }).then(() => {
      this.handleWindowResize();
      this.simplebar = new SimpleBar(this.$refs.countersTable);
      window.addEventListener('resize', this.handleWindowResize);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    ...mapActions('employeeCounters', ['fetchSelectedEmployeeDayRateCounter']),
    openMonthColumn(month) {
      return month.closed ? '' : 'openMonthColumn';
    },
    scrollToMonth() {
      const monthPosition = this.$refs.openMonthColumn[0].$el.getClientRects()[0].left;
      const offSet = this.$refs.countersTable.getClientRects()[0].left;

      this.simplebar.getScrollElement().scrollTo({
        left: monthPosition - offSet,
        behavior: 'smooth',
      });
    },
    // eslint-disable-next-line func-names
    handleWindowResize: debounce(function () {
      const table = this.$refs.countersTable;
      this.tableWidth = `${table.parentElement.clientWidth - (table.offsetLeft + 20)}px`;
    }, 300),
    handleClickOnYearColumn(column) {
      if (!column.closed) return;

      this.fetchSelectedEmployeeDayRateCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      })
        .then(() => {
          this.scrollToMonth();
        });
    },
    handleClickOnMonthColumn(column) {
      if (!column.closed) return;

      this.fetchSelectedEmployeeDayRateCounter({
        user_id: this.employee.id,
        date: column.link,
        silentLoading: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-counters__cell__info-wrapper {
  display: flex;
  align-items: center;
}

.employee-day-rate-counter__subtitle {
  margin-left: 4px;
}

.employee-counters__frozen-column {
  padding-top: 172px;
}
</style>
