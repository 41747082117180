<template>
  <!-- eslint-disable -->
  <svg :width="width" :height="height" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.3241 58H1.56515C0.535146 58 -0.213854 57.022 0.055146 56.027L10.2211 28.156C10.4051 27.474 11.0241 27 11.7311 27H56.4901C57.5201 27 58.0001 27.984 58.0001 28.973L47.8341 56.844C47.6501 57.526 47.0311 58 46.3241 58Z"
      fill="#B1CEFE" />
    <path d="M50.2682 18H48.0002V27H52.0002V19.732C52.0002 18.775 51.2242 18 50.2682 18Z" fill="#2b66fe" />
    <path
      d="M4.00015 11H1.73215C0.776153 11 0.000152588 11.775 0.000152588 12.732V55.46C0.0691526 55.462 0.138153 55.466 0.205153 55.47L4.00015 45.122V11Z"
      fill="#2b66fe" />
    <path
      d="M10.2212 28.156C10.4052 27.474 11.0242 27 11.7312 27H13.7312H48.0002V15V0H33.0002H10.0002V28.761L10.2212 28.156Z"
      fill="#EFF4F8" />
    <path
      d="M17.0002 16H41.0002C41.5522 16 42.0002 15.553 42.0002 15C42.0002 14.447 41.5522 14 41.0002 14H17.0002C16.4482 14 16.0002 14.447 16.0002 15C16.0002 15.553 16.4482 16 17.0002 16Z"
      fill="#92A4B6" />
    <path
      d="M17.0002 9H27.0002C27.5522 9 28.0002 8.553 28.0002 8C28.0002 7.447 27.5522 7 27.0002 7H17.0002C16.4482 7 16.0002 7.447 16.0002 8C16.0002 8.553 16.4482 9 17.0002 9Z"
      fill="#92A4B6" />
    <path
      d="M41.0002 21H17.0002C16.4482 21 16.0002 21.447 16.0002 22C16.0002 22.553 16.4482 23 17.0002 23H41.0002C41.5522 23 42.0002 22.553 42.0002 22C42.0002 21.447 41.5522 21 41.0002 21Z"
      fill="#92A4B6" />
    <path d="M10.0002 28.761V2H7.00015V36.986L10.0002 28.761Z" fill="#D0D8DE" />
    <path d="M7.00015 36.986V4H4.00015V45.211L7.00015 36.986Z" fill="#ADB8C3" />
  </svg>
</template>

<script>
export default {
  name: 'FullFolder',
  props: {
    size: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    width() {
      return this.size === 'small' ? '32' : '45';
    },
    height() {
      return this.size === 'small' ? '32' : '45';
    },
  },
};
</script>
