import cloneDeep from 'lodash/cloneDeep';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import { capitalize } from '@skello-utils/formatting/strings';
import { FEATURES } from '@app-js/shared/constants/features';
import { fetchInChunks } from '@skello-utils/batches';

const initialState = {
  employees: [],
  organisationAdmins: [],
  avatars: [],
  activeTab: 'active',
  searchQuery: '',
  lastQueryTimeStamp: null,
  pagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: null,
  },
  error: null,
  loading: false,
  nextPageLoading: false,
  avatarsLastQueryTimeStamp: null,
  docsEsignature: [],
  docsEsignatureLoading: true,
  hasAtLeastOneIncompleteProfile: false,
  showIncompleteEmployees: false,
  employeePayslipsManagees: [],
  employeePayslipsManageesIsLoading: false,
};

const matchContract = (employee, included) => {
  const contract = associationMatcherItem(employee, included, {
    key: 'contract',
    type: 'contract',
  });

  Object.assign(employee.relationships, { contract });
};

const mutations = {
  setActiveTab(state, { activeTab }) {
    state.activeTab = activeTab;
  },
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },

  setHasAtLeastOneIncompleteProfile(state, hasAtLeastOneIncompleteProfile) {
    state.hasAtLeastOneIncompleteProfile = hasAtLeastOneIncompleteProfile;
  },

  clearSearchQuery(state) {
    state.searchQuery = '';
  },

  setLastQueryTimeStamp(state, id) {
    state.lastQueryTimeStamp = id;
  },

  setEmployeeAttribute(state, { id, attribute, value }) {
    const employee = state.employees.find(emp => emp.id === id);
    if (employee) {
      employee.attributes[attribute] = value;
    }
  },

  setAvatars(state, avatars) {
    // we don't want to overwrite already set avatars & we don't want duplicates
    state.avatars = [...new Set(state.avatars.concat(avatars))];
  },

  setAvatarUrlForUser(state, params) {
    const selectedAvatar = state.avatars
      .find(avatar => parseInt(params.user_id, 10) === avatar.user_id);
    selectedAvatar.url = params.url;
  },

  // Replace an employee in the state.employees array
  replaceEmployee(state, newEmployee) {
    const employee = state.employees.find(emp => emp.id === newEmployee.id);
    if (employee) {
      // cloning here ensure we dont have the same object reference
      // otherwise, the sidebar would update dynamically before saving the employee
      Object.assign(employee, cloneDeep(newEmployee));
    }
  },

  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  performingNextPageRequest(state) {
    state.nextPageLoading = true;
  },

  nextPageRequestComplete(state) {
    state.nextPageLoading = false;
  },

  fetchEmployeesSuccess(state, payload) {
    state.pagination.currentPage = payload.meta.pagination.current_page;
    state.pagination.perPage = payload.meta.pagination.per_page;
    state.pagination.totalPages = payload.meta.pagination.total_pages;

    // Add contract data to each employee object
    payload.data.forEach(employee => {
      matchContract(employee, payload.included);
    });

    if (state.pagination.currentPage > 1) {
      payload.data.forEach(user => state.employees.push(user));
    } else {
      state.employees = payload.data;
    }
  },

  fetchEmployeesError(state, error) {
    state.error = error;
  },

  reset(state) {
    Object.assign(state, initialState);
  },

  resetAvatars(state, timestamp) {
    state.avatars = [];
    state.avatarsLastQueryTimeStamp = timestamp;
  },

  setOrganisationAdmins(state, users) {
    state.organisationAdmins = users;
  },

  setEmployeePayslipsManagees(state, employees) {
    state.employeePayslipsManagees = employees;
  },

  setEmployeePayslipsManageesIsLoading(state, isLoading) {
    state.employeePayslipsManageesIsLoading = isLoading;
  },

  // Docs esignature
  setDocsEsignature(state, payload) {
    state.docsEsignature = payload;
  },

  docsEsignatureComplete(state) {
    state.docsEsignatureLoading = false;
  },

  docsEsingaturePending(state) {
    state.docsEsignatureLoading = true;
  },

  // Errors
  setError(state, error) {
    state.error = error;
  },
  setShowIncompleteEmployees(state, isVisible) {
    state.showIncompleteEmployees = isVisible;
  },
};

const actions = {
  search({ state, dispatch }, params) {
    return dispatch('reloadSidebar', params);
  },

  fetchNextPage({ state, commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      if (state.nextPageLoading || state.pagination.currentPage + 1 > state.pagination.totalPages) {
        resolve();
        return;
      }

      if (!params.silent) commit('performingNextPageRequest');

      params = {
        ...params,
        current_page: state.pagination.currentPage + 1,
        silent: true,
      };

      dispatch('reloadSidebar', params)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('nextPageRequestComplete'));
    });
  },

  fetchUserMissingAttributes({ commit, rootState }) {
    return httpClient
      .get('/v3/api/users/missing_attributes_for_payroll', {
        params: {
          with_missing_attributes: true,
          cluster_node_id: rootState.navContext.navContext.clusterNodeId,
        },
      })
      .then(response => {
        commit('setHasAtLeastOneIncompleteProfile', response.data.has_missing_attributes);
      })
      .catch(error => {
        commit('requestComplete');
        throw error;
      });
  },

  reloadSidebar({ dispatch, state, commit, getters }, params) {
    // The silent option prevents from triggering the canvas loader in the
    // employees sidebar
    if (!params.silent) {
      commit('performingRequest');
    }

    const queryParams = {
      archived: state.activeTab === 'archived',
      per_page: state.pagination.perPage,
      cluster_node_id: params.cluster_node_id,
    };

    if (params.query) {
      queryParams.query = params.query;
    }
    if (params.current_page) {
      queryParams.current_page = params.current_page;
    }
    if (params.per_page) {
      queryParams.per_page = params.per_page;
    }

    if (getters.canAccessPayrollEmployee) {
      // Includes the payroll missing data in the serializer
      queryParams.incomplete_profiles = true;

      // Fetch users with incomplete profiles
      if (params.with_missing_attributes) {
        queryParams.with_missing_attributes = params.with_missing_attributes;
        queryParams.archived = false;
      }
    }

    return httpClient
      .get('/v3/api/users', {
        params: queryParams,
      })
      .then(response => {
        // !queryParams.query is when the query is cleared
        if (!queryParams.query ||
            (!!state.lastQueryTimeStamp && state.lastQueryTimeStamp === params.queryTimeStamp)) {
          commit('fetchEmployeesSuccess', response.data);
          commit('requestComplete');
        }
        dispatch('fetchAvatars', {
          user_ids: state.employees.map(employee => employee.id),
        });
        return response;
      })
      .catch(error => {
        commit('fetchEmployeesError', error);
        commit('requestComplete');
        throw error;
      });
  },
  async fetchAvatars({ state, commit }, params) {
    const currentDate = skDate().utc();
    const lastFetchDate = skDate(state.avatarsLastQueryTimeStamp).utc();
    const resetAvatars = lastFetchDate.isValid() &&
      currentDate.isBefore(lastFetchDate.add(1, 'day'));

    if (!resetAvatars) {
      // We don't want to refetch already existing avatars, so we filter them out
      params.user_ids = params.user_ids.filter(userIdStr => {
        const userId = parseInt(userIdStr, 10);
        return !state.avatars.find(avatar => avatar.user_id === userId);
      });
    }

    if (params.user_ids.length === 0) return null;

    try {
      const accumulatedData = await fetchInChunks(params, '/v3/api/avatars');

      if (resetAvatars) {
        commit('resetAvatars', currentDate.format());
      }
      commit('setAvatars', accumulatedData);
      return accumulatedData;
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  fetchOrganisationAdmins({ state, commit }) {
    return httpClient
      .get('/v3/api/users/administrators', { params: { with_license: true } })
      .then(response => commit('setOrganisationAdmins', response.data.data))
      .catch(error => commit('fetchEmployeesError', error));
  },

  fetchDocumentsEsignature({ commit }, params) {
    commit('docsEsingaturePending');

    const { userId, shopId } = params;
    const queryParams = !!shopId && shopId !== 'all' ? `?shop_id=${params.shopId}` : '';
    const url =
      `/v3/api/users/${userId}/documents/docs_signatures_follow_up${queryParams}`;

    return httpClient
      .get(url)
      .then(response => {
        commit('setDocsEsignature', response.data);
      })
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => {
        commit('docsEsignatureComplete');
      });
  },

  fetchEmployeePayslipsManagees({ state, commit }, params) {
    if (state.employeePayslipsManageesIsLoading) return undefined;

    commit('setEmployeePayslipsManageesIsLoading', true);

    const queryParams = `?cluster_node_id=${params.clusterNodeId}`;

    return httpClient
      .get(`/v3/api/employees/payslips_managees${queryParams}`)
      .then(response => {
        commit('setEmployeePayslipsManagees', response.data.data);
      })
      .finally(() => {
        commit('setEmployeePayslipsManageesIsLoading', false);
      });
  },
};

const gettersList = {
  fullName: _ => employee => (
    `${capitalize(employee.attributes.firstName)} ${capitalize(employee.attributes.lastName)}`
  ),
  userInitials: _ => employee => (
    `${employee.attributes.firstName[0].toUpperCase()}${employee.attributes.lastName[0].toUpperCase()}`
  ),
  yearOfCreation: _ => employee => skDate(employee.attributes.createdAt).year(),
  isArchivedList: state => state.activeTab === 'archived',
  isCurrentlyArchived: state => employee => {
    if (!employee.attributes.archivedAt) return false;

    return skDate(employee.attributes.archivedAt).endOf('day') < skDate().endOf('day');
  },
  isTrialEndSoon: (_state, _selfGetters, rootState) => employee => {
    if (!employee.relationships || !employee.relationships.contract) return false;

    const employeeContract = employee.relationships.contract.attributes;

    const employeeContractType =
      rootState.config.config.contract_data.contract_types.find(
        ct => ct.id === employeeContract.contractTypeId,
      );

    if (!employeeContractType) return false;

    const contractHasNoTrialPeriod = employeeContractType.category === 'interim' ||
                                     employeeContractType.category === 'intern';

    if (contractHasNoTrialPeriod || !employeeContract.trialEnd) { return false; }

    return skDate(employeeContract.trialEnd) >= skDate().startOf('day') &&
           skDate(employeeContract.trialEnd) <= skDate().add(30, 'days');
  },
  getAvatarUrlForUser: state => employeeId => {
    const selectedAvatar = state.avatars
      .find(avatar => avatar.user_id === parseInt(employeeId, 10));
    return selectedAvatar?.url;
  },
  getLicenseEditEmployeeInfo: (_state, _selfGetters, _rootState, rootGetters) => (
    rootGetters['currentLicense/canEditEmployeePersonalInfosAndBankData'] &&
    rootGetters['currentLicense/canEditSelfHr'] &&
    rootGetters['currentLicense/canEditEmployeeInfo']
  ),
  getPackOfferPayroll: (_state, _selfGetters, _rootState, rootGetters) => rootGetters['currentOrganisation/checkPackOfferFlag']('payroll_preparation_enabled'),

  // Checking Integration rules for Payroll
  getClusterNodeIntegration: (_state, _selfGetters, rootState) => {
    const clusterNodeId = rootState.currentShop.currentShop?.attributes?.clusterNodeId;
    return rootState.report.clusterNodes.find(
      clusterNode => String(clusterNode.id) === String(clusterNodeId),
    );
  },

  // Payroll Employee access
  canAccessPayrollEmployee: (state, getters, rootState, rootGetters) => {
    const hasLicenseAccess = getters.getLicenseEditEmployeeInfo;
    const hasPackOfferAccess = rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_PAYROLL,
      rootState.currentShop.id, () => getters.getPackOfferPayroll);

    return (
      hasLicenseAccess &&
      hasPackOfferAccess
    );
  },

  // Payroll Report access
  canAccessPayrollReport: (state, getters) => {
    const hasLicenseAccess = getters.getLicenseEditEmployeeInfo;
    const hasPackOfferAccess = getters.getPackOfferPayroll;

    return (
      hasLicenseAccess &&
      hasPackOfferAccess
    );
  },

  getRouteNameForPayrollPreparation: (state, getters, _rootState, rootGetters) => {
    const {
      'selectedEmployee/getMandatoryAttributesForPayroll': getMandatoryAttributesForPayroll,
      'selectedEmployee/hasMissingMandatoryFieldsForPayroll': hasMissingMandatoryFieldsForPayroll,
    } = rootGetters;

    if (getters.displayPayrollPreparation) {
      return Object.keys(getMandatoryAttributesForPayroll)
        .find(tab => hasMissingMandatoryFieldsForPayroll(tab)) || 'user_personal';
    }

    return 'user_personal';
  },
  displayPayrollPreparation: (state, getters) => {
    const showIncompleteEmployees = state.showIncompleteEmployees;
    const canAccessPayrollEmployee = getters.canAccessPayrollEmployee;

    return showIncompleteEmployees && canAccessPayrollEmployee;
  },
};

export default {
  namespaced: true,
  state: { ...initialState },
  mutations,
  actions,
  getters: gettersList,
};
