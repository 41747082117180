<template>
  <div class="name-column-wrapper">
    <div
      v-for="(employeeInfos, employeeId) in employeesInfos"
      :key="employeeId"
    >
      <NameRow
        v-for="contractInfos in employeeInfos.contracts_infos"
        :key="contractInfos.contract.id"
        :is-annualized="!!employeeInfos.annualization_data"
        :employee="employeeInfos.user_infos"
        :infos="contractInfos"
      />
    </div>
  </div>
</template>

<script>
import NameRow from './NameRow';

export default {
  name: 'NameColumn',
  components: { NameRow },
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.name-column-wrapper {
  padding-top: 114px;
  overflow: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .12);

  &:last-child {
    border-bottom: 1px solid #eee;
  }
}
</style>
