<template>
  <div class="badgings__sidebar">
    <div class="badgings__sidebar-search">
      <SkSearch
        ref="userSearchBar"
        v-model="localQuery"
        :placeholder="$t('actions.search')"
        size="medium"
      />
    </div>
    <ul class="badgings__sidebar__list_item">
      <div v-if="usersLoading">
        <div class="user-item__skeleton--loading">
          <div class="user-item__skeleton__title--loading" />
          <div class="user-item__skeleton__checkmark--loading" />
        </div>
        <div class="user-item__skeleton--loading">
          <div class="user-item__skeleton__title--loading" />
          <div class="user-item__skeleton__checkmark--loading" />
        </div>
        <div class="user-item__skeleton--loading">
          <div class="user-item__skeleton__title--loading" />
          <div class="user-item__skeleton__checkmark--loading" />
        </div>
        <div class="user-item__skeleton--loading">
          <div class="user-item__skeleton__title--loading" />
          <div class="user-item__skeleton__checkmark--loading" />
        </div>
      </div>
      <div v-else-if="users.length === 0">
        {{ $t('badgings.sidebar.empty_users') }}
      </div>
      <li
        v-for="user in users"
        :key="user.id"
      >
        <UserItem :user="user" />
      </li>
    </ul>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import deburr from 'lodash/deburr';
import {
  mapMutations, mapState, mapGetters,
} from 'vuex';

import UserItem from './UserItem';

export default {
  name: 'UsersSidebar',
  components: { UserItem },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState('badgings', ['users', 'usersLoading', 'originalUsers']),
    ...mapGetters('employees', ['fullName']),
    localQuery: {
      get() {
        return this.searchQuery;
      },

      set(newValue) {
        this.debouncedSearch(newValue.trim());
      },
    },
  },
  watch: {
    $route() {
      this.$refs.userSearchBar.clearQuery();
    },
  },
  mounted() {
    this.debouncedSearch = debounce(query => {
      if (query.length === 0) {
        this.setQueryUsers(this.originalUsers);
      } else {
        const usersToFiler = cloneDeep(this.originalUsers);

        const searchResult = usersToFiler.filter(
          user => deburr(`${user.attributes.firstName}${user.attributes.lastName}`.toLowerCase()).includes(deburr(query).toLowerCase()),
        );

        this.setQueryUsers(searchResult);
      }
    }, 200);
  },
  methods: {
    ...mapMutations('badgings', ['setQueryUsers']),
  },
};
</script>
<style lang="scss" scoped>

@keyframes loadingSkeleton {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.user-item__skeleton--loading {
  height: 60px;
  width: 100%;
  background-color: $sk-white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  .user-item__skeleton__title--loading {
    height: 24px;
    width: 70%;
    border-radius: 4px;
    background: linear-gradient(-90deg, #ddd 0%, #f7fafd 30%, #ddd 100%);
    animation: loadingSkeleton 1s ease-in-out infinite;
    background-size: 400% 400%;
  }

  .user-item__skeleton__checkmark--loading {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: linear-gradient(-90deg, #ddd 0%, #f7fafd 30%, #ddd 100%);
    animation: loadingSkeleton 1s ease-in-out infinite;
    background-size: 400% 400%;
    animation-delay: .2s;
  }
}
</style>
