<template>
  <div :class="toolbarClass">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Toolbar',
  computed: {
    ...mapGetters('planningsLoading', ['isProgressiveLoadingEnabled']),
    toolbarClass() {
      return {
        'sk-toolbar__wrapper': true,
        'sk-toolbar__wrapper_fade-in': this.isProgressiveLoadingEnabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sk-toolbar__wrapper {
  min-height: 51px;
  background: $sk-grey-5;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: none;
  z-index: 8;
}

.sk-toolbar__wrapper_fade-in {
  animation: fadeIn .3s ease-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
