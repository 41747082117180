<template>
  <SkEventModal
    :flubber-color="$skColors.skWarning10"
    :header-icon="briefcaseIcon"
    header-icon-color="#ffaa0a"
    submit-button-data-test="post_continue"
    :on-submit-click="handleSubmit"
    :submit-btn-label="$t('onboarding_modal.position.submit_text')"
    :is-submit-disabled="isSubmitDisabled"
    :cancel-btn-label="canGoBack ? $t('onboarding_modal.position.go_back') : ''"
    :on-cancel-click="handleCancel"
  >
    <template #content>
      <div class="position-step__title">
        {{ $tc('onboarding_modal.position.title', countPositionsSelected) }}
      </div>
      <div class="position-step__text">
        {{ $t('onboarding_modal.position.text') }}
      </div>
      <SkOroraChipGroup
        v-if="positions.length > 0"
        v-model="positions"
        :add-chip-button-label="$t('onboarding_modal.position.add_position')"
        add-chip-data-test="add_post"
        variant="pill"
        pill-size="medium"
        @pill-icon-clicked="handlePillIconClicked"
        @add-pill-clicked="handleAddPill"
      />

      <UpsertPosteModal
        :handle-submit="handleUpsertPosteSubmit"
      />
    </template>
  </SkEventModal>
</template>

<script>
import { BriefcaseIcon } from '@skelloapp/skello-ui';
import {
  mapGetters, mapActions,
} from 'vuex';
import UpsertPosteModal from './UpsertPosteModal';

export default {
  name: 'PositionStep',
  components: {
    UpsertPosteModal,
  },
  props: {
    importedPositions: {
      type: Array,
      default: () => [],
    },
    // this props will be filled when going back on this step
    createdPositions: {
      type: Array,
      default: () => [],
    },
    canGoBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      briefcaseIcon: BriefcaseIcon,
      positions: [],
    };
  },
  computed: {
    ...mapGetters('currentShop', ['sector']),

    isSubmitDisabled() {
      return !this.positions.some(position => position.isSelected);
    },
    countPositionsSelected() {
      return this.positions.filter(position => position.isSelected).length;
    },
  },
  mounted() {
    if (this.createdPositions.length > 0) {
      this.positions = this.createdPositions;
    } else if (this.importedPositions.length > 0) {
      this.setPositionsFromImportedPositions();
    } else {
      this.fetchOnboardingPositionsSuggestion().then(suggestions => {
        this.setPositionsFromJson(suggestions);
      });
    }
  },
  methods: {
    ...mapActions('planningsPostes', ['fetchOnboardingPositionsSuggestion']),

    setPositionsFromJson(suggestions) {
      suggestions[this.sector].primary.forEach(position => {
        this.positions.push({
          key: position,
          label: this.$t(`onboarding_modal.position.positions.${this.sector}.${position}`),
          icon: 'PencilV2Icon',
          isSelected: true,
        });
      });
      suggestions[this.sector].secondary.forEach(position => {
        this.positions.push({
          key: position,
          label: this.$t(`onboarding_modal.position.positions.${this.sector}.${position}`),
          icon: 'PencilV2Icon',
          isSelected: false,
        });
      });
    },
    setPositionsFromImportedPositions() {
      this.positions = this.importedPositions.map(position => ({
        key: position,
        label: position,
        icon: 'PencilV2Icon',
        isSelected: true,
        oldPosition: position,
      }));
    },
    handleAddPill(event) {
      this.emitOnRoot('init-upsert-chip-modal', {
        label: '',
        index: undefined,
        event,
      });
    },
    handlePillIconClicked(event, index) {
      this.emitOnRoot('init-upsert-chip-modal', {
        label: this.positions[index].label,
        index,
        event,
      });
    },
    handleSubmit() {
      this.$skAnalytics.track('onboarding_submit_position_step');
      this.$emit('update-positions', this.positions);
      this.$emit('next');
    },
    handleCancel() {
      this.$emit('update-positions', this.positions);
      this.$emit('previous');
    },
    handleUpsertPosteSubmit(newPosition, index) {
      if (index === undefined) {
        this.positions.push({ label: newPosition, isSelected: true, icon: 'PencilV2Icon' });
      } else {
        this.positions[index].label = newPosition;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .position-step__add-position-btn {
    margin-top: 12px;
  }

  .position-step__title {
    font-family: Gellix;
    font-size: $fs-heading-l;
    font-weight: $fw-semi-bold;
    height: 36px;
    line-height: 36px;
  }

  .position-step__text {
    color: $sk-grey-50;
    font-family: Gellix;
    font-size: $fs-text-l;
    font-weight: $fw-regular;
    padding-top: 16px;
    line-height: normal;
    padding-bottom: 32px;
  }
</style>
