<template>
  <transition name="fade">
    <div
      v-if="isVisible"
      class="cancel-free-trial-modal__wrapper"
    >
      <GlobalEvents
        @keydown.esc="close"
        @click="handleClickOutside"
      />
      <div
        ref="cancelFreeTrialModal"
        class="cancel-free-trial-modal"
      >
        <div class="cancel-free-trial-modal__header">
          <GiftIcon
            class="cancel-free-trial-modal__header__icon"
            height="88px"
            width="88px"
            fill="#2b66fe"
          />
          <SkCircleButton
            class="cancel-free-trial-modal__header__button"
            icon="CrossV2Icon"
            size="medium"
            @click="close"
          />
        </div>
        <div class="cancel-free-trial-modal__body">
          <span class="cancel-free-trial-modal__body__title">
            {{ $t('organisation_settings.tabs.organisation_info.cancel_free_trial_modal.title') }}
          </span>
          <span class="cancel-free-trial-modal__body__description">
            {{ $t('organisation_settings.tabs.organisation_info.cancel_free_trial_modal.description') }}
          </span>
          <div class="cancel-free-trial-modal__submit">
            <div class="cancel-free-trial-modal__submit__button">
              <SkOroraButton
                class="cancel-free-trial-modal__submit__button"
                variant="secondary"
                size="large"
                @click="close"
              >
                {{ $t('organisation_settings.tabs.organisation_info.cancel_free_trial_modal.submit.continue') }}
              </SkOroraButton>
            </div>
            <div class="cancel-free-trial-modal__submit__button">
              <SkOroraButton
                class="cancel-free-trial-modal__submit__button"
                size="large"
                :loading="isLoading"
                @click="handleCancelClick"
              >
                {{ $t('organisation_settings.tabs.organisation_info.cancel_free_trial_modal.submit.cancel') }}
              </SkOroraButton>
            </div>
            <span class="cancel-free-trial-modal__submit__description">
              {{ $t('organisation_settings.tabs.organisation_info.cancel_free_trial_modal.submit.description') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import GlobalEvents from 'vue-global-events';

import { svcBillingAutomationClient } from '@skello-utils/clients';
import { mapState } from 'vuex';

export default {
  name: 'CancelFreeTrialModal',
  components: {
    GlobalEvents,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('currentOrganisation', ['currentOrganisation']),
  },
  mounted() {
    this.listenOnRoot('show-cancel-free-trial-modal', this.open);
    this.listenOnRoot('hide-cancel-free-trial-modal', this.close);
  },
  methods: {
    handleCancelClick() {
      this.$skAnalytics.track('opt_out_cancel_subcription_confirmation');
      this.isLoading = true;
      svcBillingAutomationClient.updateSelfServeSubscription({
        organisationId: parseInt(this.currentOrganisation.id, 10),
        shopId: this.currentOrganisation.relationships.owner.attributes.shopId,
        prospectId: this.currentOrganisation.relationships.owner.attributes.prospectId,
        isSubscriptionActivationRequested: false,
      }).then(() => {
        this.$skToast({
          message: this.$t('organisation_settings.update_organisation.success_message'),
          variant: 'success',
        });
        this.$emit('free-trial-cancelled');
        this.close();
      }).catch(() => {
        this.$skToast({
          message: this.$t('organisation_settings.update_organisation.error_message'),
          variant: 'error',
        });
      }).finally(() => { this.isLoading = false; });
    },
    handleClickOutside(event) {
      if (this.$refs.cancelFreeTrialModal.contains(event.target)) return true;

      this.close();
      return true;
    },
    close() {
      if (!this.isVisible) return;

      this.$emit('hide');
      this.isVisible = false;
    },
    open() {
      this.$emit('show');
      this.isVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-free-trial-modal {
  display: flex;
  flex-direction: column;
  width: 342px;
  height: 567px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .2);
    z-index: $z-index-modal-backdrop;
    display: flex;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    height: 171px;
    border-radius: 8px;
    background-image: url('./flubber.png');
    background-size: contain;
    background-repeat: no-repeat;

    &__button {
      margin: 16px 16px 0px 0px;
    }

    &__icon {
      margin: 24px 0px 0px 24px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    &__title {
      font-weight: $fw-semi-bold;
      font-size: $fs-heading-l;
      line-height: 36px;
      padding-bottom: 16px;
    }

    &__description {
      color: $sk-grey-50;
      font-size: $fs-text-l;
      padding-bottom: 32px;
      line-height: 18px;
    }
  }

  &__submit {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;

    &__button {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    &__description {
      color: $sk-grey-50;
      font-size: $fs-text-s;
      line-height: 13px;
    }
  }
}
</style>
