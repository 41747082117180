<template>
  <SkModal
    id="create-amendment-modal"
    ref="createAmendmentModal"
    :modal-title="$t(`${amendmentsLocaleKey}.create_amendment.modal.title`)"
    :tracking-options="trackingOptions"
    @cancel="resetData"
    @close="resetData"
    @show="setData"
  >
    <template #body>
      <div class="create-amendment-modal__form">
        <SkForm
          :title="$t(`${amendmentsLocaleKey}.create_amendment.modal.type`)"
          split
        >
          <div class="create-amendment-modal__form__row">
            <SkSelectV2
              v-model="amendmentType"
              :options="amendmentTypes"
              :label="$t(`${amendmentsLocaleKey}.create_amendment.modal.type`)"
              @input="resetDuration"
            />
          </div>
        </SkForm>
        <SkForm
          :title="durationTitle"
          split
        >
          <div class="create-amendment-modal__form__row">
            <SkDatePicker
              ref="startDatepicker"
              v-model="amendment.starts"
              :disabled-date="disabledDaysStart"
              :errored="amendmentStartDatepickerErrored"
              :error-message="amendmentStartErrorMessage"
              :lang="$i18n.locale"
              :placeholder="$t(`${amendmentsLocaleKey}.create_amendment.modal.start`)"
              class="mr-sm-2"
            />
            <SkDatePicker
              v-if="isTemporary"
              ref="endDatepicker"
              v-model="amendment.ends"
              :errored="amendmentEndDatepickerErrored"
              :error-message="amendmentEndErrorMessage"
              :disabled-date="disabledDaysEnd"
              :lang="$i18n.locale"
              :placeholder="$t(`${amendmentsLocaleKey}.create_amendment.modal.end`)"
            />
          </div>
          <div
            v-if="hasAmendmentRangeError"
            class="create-amendment-modal__date-error"
          >
            {{ $t(`${amendmentsLocaleKey}.create_amendment.errors.non_consecutive`) }}
          </div>
        </SkForm>
        <SkForm
          :title="$t(`${amendmentsLocaleKey}.create_amendment.modal.contract_hours`)"
          split
        >
          <div class="create-amendment-modal__form__row pb-3">
            <SkInputGroup
              :errored="hasAmendmentHoursError"
              :error-message="$t('errors.missing_value')"
              class="create-amendment-modal__form__cell"
            >
              <SkInput
                v-model="amendment.hours"
                type="number"
                min="0"
              />
              <template #append>
                {{ $t('dates.hours') }}
              </template>
            </SkInputGroup>
          </div>
        </SkForm>
      </div>
      <div class="create-amendment-modal__form__footer">
        <div class="create-amendment-modal__form__footer__row">
          <p>{{ $t(`${amendmentsLocaleKey}.create_amendment.modal.current_contract_hours`) }}</p>
          <p>{{ humanizedCurrentPermanentContractHours }}</p>
        </div>
        <div class="create-amendment-modal__form__footer__row">
          <p>{{ $t(`${amendmentsLocaleKey}.create_amendment.modal.new_contract_hours`) }}</p>
          <p class="new-contract-hours">
            {{ legibleNewContractHours }}
          </p>
        </div>
      </div>
    </template>
    <template #submit-btn>
      <SkOroraButton
        v-track="trackingOptions.submit"
        :loading="loading"
        :disabled="disabledSubmit"
        @click="handleSubmit"
      >
        {{ $t(`${amendmentsLocaleKey}.create_amendment.modal.add`) }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import skDate from '@skello-utils/dates';

export default {
  name: 'CreateAmendmentModal',
  props: {
    trackingOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      amendmentsLocaleKey: 'employees.tabs.contracts.full_contract.data.salary.amendments.update_card',
      loading: false,
      amendment: {
        type: 'temporary',
        starts: null,
        ends: null,
        hours: 0,
      },
      amendmentTypes: [
        { id: 'temporary', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.temporary') },
        { id: 'permanent', text: this.$t('employees.tabs.contracts.full_contract.data.salary.amendments.update_card.permanent') },
      ],
      onDataReset: false,
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('contracts', ['contract']),
    ...mapGetters('amendments', ['hasActivePermanentAmendment', 'activePermanentAmendment']),

    amendmentType: {
      get() {
        return this.amendment.type;
      },
      set(newAmendmentType) {
        this.amendment.type = newAmendmentType;
      },
    },
    hasAmendmentRangeError() {
      if (!this.isTemporary) return false;

      if (!this.amendment.starts || !this.amendment.ends) return false;

      return this.amendment.starts > this.amendment.ends;
    },
    amendmentStartNotMonday() {
      if (!this.amendment.starts) return false;

      return !skDate(this.amendment.starts).isMonday();
    },
    amendmentStartDatepickerErrored() {
      if (this.onDataReset) return false;

      return this.hasAmendmentRangeError ||
        !this.amendment.starts ||
        this.amendmentStartNotMonday;
    },
    amendmentStartErrorMessage() {
      if (this.hasAmendmentRangeError) return null;

      if (this.amendmentStartNotMonday) {
        return this.$t(`${this.amendmentsLocaleKey}.create_amendment.errors.wrong_start`);
      }

      return this.$t('errors.missing_value');
    },
    amendmentEndNotSunday() {
      if (!this.amendment.ends) return false;

      return !skDate(this.amendment.ends).isSunday();
    },
    amendmentEndDatepickerErrored() {
      if (!this.isTemporary || this.onDataReset) return false;

      return this.hasAmendmentRangeError ||
        !this.amendment.ends ||
        this.amendmentEndNotSunday;
    },
    amendmentEndErrorMessage() {
      if (this.hasAmendmentRangeError) return null;

      if (this.amendmentEndNotSunday) {
        return this.$t(`${this.amendmentsLocaleKey}.create_amendment.errors.wrong_end`);
      }

      return this.$t('errors.missing_value');
    },
    // TODO : DEV-9586
    currentPermanentContractHours() {
      if (this.hasActivePermanentAmendment) {
        return this.activePermanentAmendment.attributes.hours;
      }
      return this.contract.attributes.contractHours;
    },
    humanizedCurrentPermanentContractHours() {
      return `${this.currentPermanentContractHours} ${this.$t('dates.hours')}`;
    },
    legibleNewContractHours() {
      return `${this.amendment.hours} ${this.$t('dates.hours')}`;
    },
    durationTitle() {
      if (this.isTemporary) {
        return this.$t(`${this.amendmentsLocaleKey}.create_amendment.modal.start_and_end`);
      }
      return this.$t(`${this.amendmentsLocaleKey}.create_amendment.modal.start`);
    },
    isTemporary() {
      return this.amendment.type === this.amendmentTypes[0].id;
    },
    hasAmendmentHoursError() {
      return !this.amendment.hours ||
        isNaN(parseInt(this.amendment.hours, 10)) ||
        parseInt(this.amendment.hours, 10) < 0;
    },
    disabledSubmit() {
      return this.amendmentStartDatepickerErrored || this.amendmentEndDatepickerErrored;
    },
  },
  methods: {
    ...mapMutations('selectedEmployee', ['setEmployeeAttributes']),
    ...mapActions('amendments', ['createAmendment', 'fetchAmendments']),

    disabledDaysStart(date) {
      return !skDate(date).isMonday();
    },
    disabledDaysEnd(date) {
      return !skDate(date).isSunday() || skDate(date).isBefore(this.amendment.starts);
    },
    resetDuration() {
      this.onDataReset = true;
      this.amendment.starts = null;
      this.amendment.ends = null;
      this.$nextTick(() => {
        this.$refs.startDatepicker.resetModified();
        if (this.isTemporary) this.$refs.endDatepicker.resetModified();
        this.onDataReset = false;
      });
    },
    resetData() {
      this.$refs.createAmendmentModal.hide();
      this.onDataReset = true;
      this.amendment.type = this.amendmentTypes[0].id;
      this.amendment.starts = null;
      this.amendment.ends = null;
      this.$nextTick(() => {
        this.$refs.startDatepicker.resetModified();
        if (this.isTemporary) this.$refs.endDatepicker.resetModified();
      });
    },
    setData() {
      this.onDataReset = false;
      this.amendment.hours = this.currentPermanentContractHours;
    },
    handleSubmit() {
      this.loading = true;

      this.createAmendment({
        amendment: {
          starts_at: this.amendment.starts,
          ends_at: this.amendment.ends || null,
          hours: this.amendment.hours,
        },
        employee_id: this.employee.id,
        contract_id: this.contract.id,
      }).then(() => {
        if (this.$route.params.contract_id) {
          this.fetchAmendments({
            employeeId: this.$route.params.user_id,
            contractId: this.$route.params.contract_id,
          });
        }
        this.$refs.createAmendmentModal.hide();
        this.$skToast({
          message: this.$t(`${this.amendmentsLocaleKey}.create_amendment.modal.success`),
          variant: 'success',
          containerId: 'employees__container',
        });
      }).catch(error => {
        this.$skToast({
          message: this.$t('employees.error_message'),
          variant: 'error',
          containerId: 'employees__container',
        });
      }).finally(() => {
        this.loading = false;
        this.resetData();
      });
    },
  },
};
</script>

<style lang="scss">
.create-amendment-modal__form {
  padding: 0 20px;
  border-bottom: 1px solid $sk-grey-10;

  .sk-form--split {
    border: none;

    .sk-form__header label {
      color: $sk-black;
    }
  }

  .create-amendment-modal__form__hours {
    width: calc(50% - 5px);
  }

  &__row {
    margin-left: -50px;
    display: flex;
  }

  &__cell {
    width: calc(50% - 5px);
  }
}

.create-amendment-modal__form__footer {
  border-bottom: 1px solid $sk-grey-10;
  padding: 14px 0;

  &__row {
    margin: 12px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;

    p {
      margin: 0;
    }

    .new-contract-hours {
      font-weight: $fw-semi-bold;
      color: $sk-blue;
    }
  }
}

.create-amendment-modal__date-error {
  color: $sk-error;
  font-size: $fs-text-s;
  margin-bottom: 0;
  margin-left: -50px;
}
</style>
