<template>
  <div class="hour-cell__wrapper">
    <div
      v-for="quarterHour in (1, 4)"
      :key="quarterHour"
      class="quarter-cell__wrapper"
      :style="{width: `${hourCellStoreProps.dayViewPlanningSizeVariables.pixelPerQuarterHours}px`}"
      @dragover.prevent
      @drop="onDropShift(quarterHour, $event)"
    >
      <div :class="isTimeIndicator(quarterHour)" />
    </div>
  </div>
</template>

<script>
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import skDate from '@skello-utils/dates';
import { getDragDataFormat } from '@skello-utils/drag_and_drop';

const QUARTER_VALUE = 15;

export default {
  name: 'HourCell',
  props: {
    currentHourCellDate: {
      type: String,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
    hourCellStoreProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentQuarterHourOfDay: 0,
    };
  },
  computed: {
    currentHour() {
      return skDate.utc(this.currentHourCellDate);
    },
  },
  mounted() {
    this.setCurrentQuarterHourOfDay();
    // Time Indicator needs to be synchronized with minute change on three
    // different between Header, Planning and footer
    // setTimeout is used in a way to wait for a minute change to trigger
    // the setInterval loop
    setTimeout(() => {
      setInterval(() => {
        this.setCurrentQuarterHourOfDay();
      }, 60 * 1000);
    }, (60 - skDate().seconds()) * 1000);
  },
  methods: {
    setCurrentQuarterHourOfDay() {
      // eg : for 8h36 o'clock, we want to know it's the 34th quarter of the days
      const isExactlyOnQuarter = skDate().minutes() % QUARTER_VALUE === 0;
      const addToCurrentQuarterHourOfDay = isExactlyOnQuarter ? 1 : 0;
      this.currentQuarterHourOfDay =
        skDate().hours() * 4 +
        Math.ceil(skDate().minutes() / QUARTER_VALUE) +
        addToCurrentQuarterHourOfDay;
    },
    onDropShift(quarterHour, event) {
      const { shift, user, position } = JSON.parse(
        event.dataTransfer.getData(getDragDataFormat('shift')),
      );

      const { endsAt, startsAt } = shift.attributes;
      const shiftDurationInMinutes = skDate(endsAt).diff(startsAt, 'minutes');

      // find which quarter the shift has been droped on to adjust shift position
      const minutesOffset = Math.floor(position / QUARTER_VALUE) * QUARTER_VALUE;
      // quarterHour index starts at 1
      const minutes = ((quarterHour - 1) * QUARTER_VALUE) - minutesOffset;

      let skNewStartDate = this.currentHour.clone().set('minute', minutes);
      let skNewEndDate = skNewStartDate.clone().add(shiftDurationInMinutes, 'minutes');
      const { openingTime, closingTime } = openingAndClosingTimeAt(
        this.hourCellStoreProps.currentShop.attributes.openingTime,
        this.hourCellStoreProps.currentShop.attributes.closingTime,
        skDate(this.currentDate).utc(true).format(),
      );

      // Specific case: if it's a h24 shop and we drop shift before opening time then we cancel the drag'n'drop
      if (
        this.hourCellStoreProps.is24hShop &&
        skNewStartDate.isBefore(openingTime) &&
        skNewEndDate.isAfter(closingTime)
      ) {
        this.$skToast({
          message: this.$t('plannings.table.manage_shift_modal.tabs.commun.actions.update.day_view.24h_shop_move_shift_to_previous_day'),
          variant: 'error',
        });

        return;
      }

      // Shift was dropped outside of shop opening times
      if (skNewStartDate.isBefore(openingTime)) {
        // Specific case: if it's not a h24 shop then we crop the shift to match the opening hour of the shop
        const newSkDate = skDate(this.currentDate).utc(true).set('hour', openingTime.hour());
        const newDuration = shiftDurationInMinutes - newSkDate.diff(skNewStartDate, 'minutes');

        skNewStartDate = newSkDate.format();
        skNewEndDate = newSkDate
          .clone()
          .add(newDuration, 'minutes')
          .format();
      } else {
        skNewStartDate = skNewStartDate.format();
        skNewEndDate = skNewEndDate.format();
      }

      this.$emit('drop-shift', { shift, user, newStart: skNewStartDate, newEnd: skNewEndDate });
    },
    getQuarterHourOfDay(quarterHour) {
      // quarterHour (index) 1 means XXh00, 2 means XXh15, 3 means XXh30, until 4 for XXh45.
      // quarterHourOfDay (index) 1 means between 00h00 and 00h15, 2 means between 00h15 and 00h30
      // ... until 359 between 23h45 and 00h00
      return this.currentHour.hours() * 4 + quarterHour;
    },
    isTimeIndicator(quarterHour) {
      return {
        'quarter-cell__wrapper--time-indicator':
          skDate(this.currentDate).utc(true).isSame(skDate().utc(true), 'day') && this.getQuarterHourOfDay(quarterHour) === this.currentQuarterHourOfDay,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.hour-cell {
  &__wrapper {
    display: flex;
    box-shadow: 1px 0 $sk-grey-10;
    border-bottom: 1px solid $sk-grey-10;

    &:first-of-type {
      border-left: 1px solid $sk-grey-10;
    }

    .quarter-cell__wrapper {
      display: inline-block;
      height: 100%;
      box-shadow: 1px 0 rgba($sk-grey-10, .2);

      &:nth-child(4n) { box-shadow: none; }

      &--time-indicator {
        position: relative;
        top: 0;
        display: flex;
        height: 100%;
        background-color: rgba($sk-azure-blue, .2);
        z-index: 2;
        pointer-events: none;
      }
    }
  }
}
</style>
