<template>
  <!-- eslint-disable  max-len -->
  <svg
    :fill="fill"
    class="clusters-tree-app-svg"
    width="8"
    height="12"
    viewBox="0 0 8 12"
  >
    <path
      d="M1.41497 2.47401e-07L-2.47409e-07 1.41502L4.58498 6L-1.85074e-06 10.585L1.41497 12L7.41497 6L1.41497 2.47401e-07Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'black',
    },
  },
};
</script>

<style lang="scss" scoped>
.clusters-tree-app-svg {
  margin: auto;
  width: 8px; // ie11 support
  width: initial;
  height: 12px; // ie11 support
  height: initial;

  @supports (-ms-ime-align:auto) {
    /* IE Edge 16+ CSS */
    width: 8px; // edge support
    height: 12px; // edge support
  }
}
</style>
