<template>
  <img
    width="50"
    src="./images/logo-gotenzo.png"
  >
</template>

<script>
export default {
  name: 'GoTenzo',
};
</script>
