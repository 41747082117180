<template>
  <div class="hr-admin-table__column-wrapper">
    <ClosedColumn
      v-if="!columnActive"
      :title="title"
      :employees-infos="employeesInfos"
      :handle-column-click="openColumn"
      background-color="#F0FDFA"
      border-color="#115E59"
      data-test="column__salary-data__closed"
    />
    <div
      v-else
      class="column-wrap column-wrap--open"
      data-test="column__salary-data__opened"
    >
      <div
        class="column__header salary-data-title"
        data-test="column__salary-data__close-button"
        @click="closeColumn"
      >
        {{ title }}

        <div class="column__close-btn">
          <ReportCloseIcon />
        </div>
      </div>

      <table>
        <thead class="column__title-wrap">
          <th>
            <div class="column__title column__title--open">
              {{ $t('reports.salary_data.monthly_contract_hours') }}
            </div>
          </th>
          <th v-if="!showStructuralOverHours">
            <div class="column__title column__title--open">
              {{ $t('reports.salary_data.monthly_over_hours') }}
            </div>
          </th>
          <th v-if="visibleSalary">
            <div class="column__title column__title--open">
              {{ $t('reports.salary_data.hourly_wage', { currencySymbol }) }}
            </div>
          </th>
          <th v-if="visibleSalary">
            <div class="column__title column__title--open">
              {{ $t('reports.salary_data.monthly_salary', { currencySymbol }) }}
            </div>
          </th>
        </thead>

        <tbody>
          <template v-for="employeeInfos in employeesInfos">
            <tr
              v-for="contractInfos in employeeInfos.contracts_infos"
              :key="contractInfos.contract.id"
              class="column__cell-wrap"
            >
              <!-- Volume Horaire Mensuel -->
              <td
                class="column__cell column__cell--open"
              >
                {{ monthlyContractHours(contractInfos) }}
              </td>

              <!-- HS Mensualisées -->
              <td
                v-if="!showStructuralOverHours"
                class="column__cell column__cell--open"
              >
                {{ contractInfos.monthly_over_hours }}
              </td>

              <!-- Taux horaire Brut Moyen -->
              <td
                v-if="visibleSalary"
                class="column__cell column__cell--open"
              >
                {{ hourlyWage(employeeInfos.user_infos, contractInfos) }}
              </td>

              <!-- Salaire mensuel Brut -->
              <td
                v-if="visibleSalary"
                class="column__cell column__cell--open"
              >
                {{ monthlySalary(employeeInfos.user_infos, contractInfos) }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ReportCloseIcon from '@app-js/reports/shared/assets/svg/ReportCloseIcon.vue';
import ClosedColumn from '../ClosedColumn';
import ColumnMixin from '../../mixins/ColumnMixin';

export default {
  name: 'SalaryDataColumn',
  components: { ClosedColumn, ReportCloseIcon },
  mixins: [ColumnMixin],
  props: {
    employeesInfos: {
      type: [Array, Object],
      default: () => [],
    },
    visibleSalary: {
      type: Boolean,
      default: false,
    },
    currentLicensePosition: {
      type: Number,
      default: 0,
    },
    currencySymbol: {
      type: String,
      default: '€',
    },
    showStructuralOverHours: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: this.$t('reports.salary_data.title'),
    };
  },
  methods: {
    monthlyContractHours(contractInfos) {
      if (contractInfos.on_day_rate_contract) return '-';
      const numberOfWeeksInAMonth = (52.0 / 12.0);

      return (contractInfos.contract_hours * numberOfWeeksInAMonth).toFixed(2);
    },
    // Manager can't see hourly_wage of above permission
    hourlyWage(userInfos, contractInfos) {
      if (
        userInfos.user_permission_position <= this.currentLicensePosition &&
        userInfos.id !== this.currentUser.id &&
        this.currentLicensePosition !== 0
      ) return '-';

      return contractInfos.contract.hourly_wage;
    },
    // Manager can't see salary of above permission
    monthlySalary(userInfos, contractInfos) {
      if (
        userInfos.user_permission_position <= this.currentLicensePosition &&
        userInfos.id !== this.currentUser.id &&
        this.currentLicensePosition !== 0
      ) return '-';

      return contractInfos.contract.monthly_salary || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.salary-data-title {
  border-top: 4px solid #115e59;
}

.column__title--open {
  height: 66px;
}
</style>
