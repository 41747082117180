import { httpClient } from '@skello-utils/clients';
import { filterPostes } from '@skello-utils/filters_helper';
import { localizeDefaultPoste } from '@skello-utils/default_poste_helper';

const initialState = {
  postes: [],
  absences: [],
  quickSelectedAbsenceIds: [],
  postesLoading: false,
  absencesLoading: false,
  lastUserPoste: null,
  lastUserPosteLoading: false,
  error: null,
};

const mutations = {
  posteRequestPending(state) {
    state.postesLoading = true;
  },

  posteRequestComplete(state) {
    state.postesLoading = false;
  },

  absenceRequestPending(state) {
    state.absencesLoading = true;
  },

  absenceRequestComplete(state) {
    state.absencesLoading = false;
  },

  fetchPostesSuccess(state, payload) {
    state.postes = payload.data;
    state.postes.forEach(poste => localizeDefaultPoste(poste));
  },

  createPosteSuccess(state, payload) {
    state.postes.splice(state.postes.length, 0, payload.data);
  },

  catchPostesError(state, payload) {
    state.error = payload;
  },

  fetchAbsencesSuccess(state, payload) {
    state.absences = payload.data;
  },

  catchAbsencesError(state, payload) {
    state.error = payload;
  },

  fetchLastUserPosteSuccess(state, payload) {
    if (payload.data) {
      state.lastUserPoste = payload.data;
    }
  },

  fetchLastUserPosteError(state, error) {
    state.error = error;
  },

  lastUserPostePending(state) {
    state.lastUserPosteLoading = true;
  },

  lastUserPosteComplete(state) {
    state.lastUserPosteLoading = false;
  },

  resetLastUserPoste(state) {
    state.lastUserPoste = null;
  },

  addToquickSelectedAbsenceIds(state, { id }) {
    state.quickSelectedAbsenceIds.push(id);
  },

  resetAbsencesForQuickAdd(state) {
    state.quickSelectedAbsenceIds = [];
  },
};

const actions = {
  fetchPostes({ commit }, shopId) {
    commit('posteRequestPending');

    return httpClient
      .get(`/v3/api/shops/${shopId}/postes`)
      .then(response => {
        commit('fetchPostesSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchPostesError', error.response);
        throw error;
      })
      .finally(() => { commit('posteRequestComplete'); });
  },

  async createPoste({ commit, dispatch }, { shopId, poste }) {
    const response = await dispatch('shopPostes/createShopPoste', { shopId, shopPoste: poste }, { root: true });
    commit('createPosteSuccess', response.data);
    return response.data;
  },

  async fetchOnboardingPositionsSuggestion() {
    const response = await import('../../../../admin_onboarding/config/positions-suggestion.json');
    return response.default;
  },

  fetchAbsences({ commit }, shopId) {
    commit('absenceRequestPending');

    return httpClient
      .get('/v3/api/absences', { params: { shop_id: shopId } })
      .then(response => {
        commit('fetchAbsencesSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchAbsencesError', error.response);
        throw error;
      })
      .finally(() => { commit('absenceRequestComplete'); });
  },

  fetchlastUserPoste({ commit }, { shopId, userId }) {
    commit('lastUserPostePending');

    const params = { user_id: userId };

    return httpClient
      .get(`/v3/api/shops/${shopId}/postes/last_user_poste_by_creation_date`, { params })
      .then(response => {
        commit('fetchLastUserPosteSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('fetchLastUserPosteError', error);
        throw error;
      })
      .finally(() => {
        commit('lastUserPosteComplete');
      });
  },

  sortPostes({ commit }, params) {
    commit('posteRequestPending');

    return httpClient
      .patch('/v3/api/postes/sort', params)
      .then(response => response)
      .catch(error => {
        commit('setError', error);
        throw error;
      })
      .finally(() => commit('posteRequestComplete'));
  },
};

const getters = {
  hasDuplicatePostes: state => {
    const uniqPostes = new Set(state.postes.map(poste => poste.attributes.name.toLowerCase()));

    return uniqPostes.size !== state.postes.length;
  },

  positionViewPostes: state => {
    const planningOrderSortMethod =
      (posteA, posteB) => (posteA.attributes.planningOrder - posteB.attributes.planningOrder);
    const aToZSortMethod =
      (posteA, posteB) => (posteA.attributes.name.localeCompare(posteB.attributes.name));

    // Work postes (sorted by planningOrder)
    const workPostes = [...state.postes].sort(planningOrderSortMethod);
    const absencePostes = [...state.absences].sort(aToZSortMethod);

    return [...workPostes, ...absencePostes];
  },

  // Return absences with no shift but also the ones that have just been added
  // trough <QuickAddAbsence> (stored in quickSelectedAbsenceIds and sorted alphabetically)
  absencesForQuickAdd: (state, _selfGetters, _rootState, rootGetters) => {
    const shiftPosteIds = rootGetters['planningsShifts/shiftsCreatedForCurrentWeek']
      .map(shift => shift.relationships.poste.id);
    const sortMethod =
      (posteA, posteB) => (posteA.attributes.name.localeCompare(posteB.attributes.name));

    return state.absences
      .filter(absence => absence.attributes.active &&
        !shiftPosteIds.includes(String(absence.id)) &&
        !state.quickSelectedAbsenceIds.includes(absence.id))
      .sort(sortMethod);
  },

  filteredPostes: (state, selfGetters, rootState, rootGetters) => {
    const { filters } = rootState.planningsState;
    const postes = selfGetters.positionViewPostes;
    const { users } = rootState.planningsUsers;
    const shifts = rootGetters['planningsShifts/shiftsForCurrentWeek'];

    const displayedPostes = filterPostes(filters, postes, users, shifts);

    const alreadyAddedAbsenceIds = displayedPostes
      .filter(poste => poste.attributes.absenceKey !== null)
      .map(poste => poste.id);

    // Absences added with the <QuickAddAbsence> button
    const quickAddedAbsences = state.quickSelectedAbsenceIds
      .filter(absenceId => !alreadyAddedAbsenceIds.includes(absenceId))
      .map(absenceId => state.absences.find(absence => absence.id === absenceId));

    return displayedPostes.concat(quickAddedAbsences);
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
