<template>
  <div
    ref="shiftWrapper"
    v-track="'month_view_V3_click_on_shift'"
    :class="shiftClasses"
    :style="shiftStyle"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <MountingPortal
      v-if="showShiftInfoCard && shiftComponentStoreProps.draggedShift === null"
      mount-to="#shift-info-mounting"
      append
    >
      <ShiftInfoCard
        ref="shiftInfoCard"
        :style="infoCardStyle"
        :shift="shift"
        :current-shop="shiftComponentStoreProps.currentShop"
      />
    </MountingPortal>

    <div
      v-if="isAbsence"
      class="absence-name--wrapper"
    >
      {{ shift.relationships.poste.attributes.name }}
    </div>

    <div
      v-else
      class="shift-time"
    >
      <span>{{ shiftStartTime }}</span>
      <span>{{ shiftEndTime }}</span>
    </div>

    <span
      v-if="hasShiftAlert"
    >
      <CircledExclamationMarkIcon
        class="shift-alert"
        background-color="#fff"
        :fill="shift.attributes.color"
        width="10px"
        height="10px"
        @click.native.stop="openShiftAlertPopup"
      />
    </span>
  </div>
</template>

<script>
import skDate from '@skello-utils/dates';
import ShiftInfoCard from './ShiftInfoCard';

export default {
  name: 'MonthlyWorkingShift',
  components: {
    ShiftInfoCard,
  },
  props: {
    shift: {
      type: Object,
      required: true,
    },
    isLockedDay: {
      type: Boolean,
      default: false,
    },
    shiftComponentStoreProps: {
      type: Object,
      required: true,
    },
    dayCellComponentStoreProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      showShiftInfoCard: false,
      infoCardTop: 0,
      infoCardLeft: 0,
      isShiftAlertDisplayed: false,
    };
  },
  computed: {
    hasShiftAlert() {
      return !this.isFromAnotherShop && this.shift.relationships.alerts?.length;
    },
    shiftStyle() {
      if (this.isAbsence || this.isFromAnotherShop) return {};

      return {
        'background-color': this.shift.attributes.color,
      };
    },
    isFromAnotherShop() {
      return this.shift.attributes.shopId !==
        parseInt(this.shiftComponentStoreProps.currentShop.id, 10);
    },
    isShiftBeingDragged() {
      return this.dayCellComponentStoreProps.draggedShift?.id === this.shift.id &&
        this.dayCellComponentStoreProps.usingDraggedShiftId !== this.shift.id;
    },

    shiftClasses() {
      return {
        'shift-wrapper': true,
        'shift-wrapper--leave-request': this.isPendingLeaveRequest,
        'shift-wrapper--absence': this.isAbsence && !this.isPendingLeaveRequest,
        'shift-wrapper--opacited': !this.shiftComponentStoreProps.isShiftInFilters(this.shift) || this.isShiftBeingDragged,
        'shift-wrapper--from-another-shop': this.isFromAnotherShop,
        'shift-wrapper--after-start': !this.isPendingLeaveRequest && this.startsAfterPrevisionalStart,
        'shift-wrapper--before-end': !this.isPendingLeaveRequest && this.endsBeforePrevisionalEnd,
      };
    },
    shiftStartTime() {
      return this.shift.attributes.showStartTime ? skDate(this.shift.attributes.startsAt).utc().format('HH:mm') : '.';
    },
    shiftEndTime() {
      return this.shift.attributes.showEndTime ? skDate(this.shift.attributes.endsAt).utc().format('HH:mm') : '.';
    },
    startsAfterPrevisionalStart() {
      return skDate(this.shift.attributes.startsAt) >
        skDate(this.shift.attributes.previsionalStart);
    },
    endsBeforePrevisionalEnd() {
      return skDate(this.shift.attributes.endsAt) <
        skDate(this.shift.attributes.previsionalEnd);
    },
    isAbsence() {
      return this.shift.relationships.poste.attributes.absenceKey !== null;
    },
    isPendingLeaveRequest() {
      return this.shift.attributes.isPendingLeaveRequest;
    },
    infoCardStyle() {
      return {
        top: `${this.infoCardTop}px`,
        left: `${this.infoCardLeft}px`,
      };
    },
  },
  mounted() {
    this.listenOnRoot('modal-alert-close', this.handleCloseNotification);
    this.listenOnRoot('planning-horizontal-scroll', () => {
      if (this.isShiftAlertDisplayed) this.openShiftAlertPopup();
      this.onMouseLeave();
    });
  },
  methods: {
    onMouseEnter(event) {
      if (event.target === undefined) return;

      const showShiftInfoCardDelay = 350;
      this.timeout = setTimeout(() => {
        this.showShiftInfoCard = true;
        this.$nextTick(this.computeInfoCardPosition);
      }, showShiftInfoCardDelay);

      if (this.isLockedDay) return;

      const shiftElRect = event.target.getBoundingClientRect();
      const menuPositionLeft = shiftElRect.left + shiftElRect.width - 10; // offset the menu from the alerts (design validated)
      const menuPositionTop = shiftElRect.top + shiftElRect.height;
      this.emitOnRoot('show-shift-menu', {
        shiftId: this.shift.id,
        isShiftFromAnotherShop: this.isFromAnotherShop,
        isPendingLeaveRequest: this.shift.attributes?.isPendingLeaveRequest || false,
        isUnassignedShiftsRow: false,
        top: menuPositionTop,
        left: menuPositionLeft,
        id: this.shift.id,
      });
    },
    onMouseLeave() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.showShiftInfoCard = false;
      this.emitOnRoot('hide-shift-menu');
    },
    handleCloseNotification(value) {
      this.isShiftAlertDisplayed = value;
    },
    openShiftAlertPopup() {
      const shiftRect = this.$refs.shiftWrapper.getBoundingClientRect();

      // These 31px have been validated with design team
      const shiftOffset = 31;
      // Ajust div position
      const menuPositionLeft = shiftRect.left + shiftRect.width - 25;
      const menuPositionTop = shiftRect.top - shiftRect.height / 2;

      this.isShiftAlertDisplayed = true;
      this.emitOnRoot('show-alerts', {
        shift: this.shift,
        top: menuPositionTop - shiftOffset,
        left: menuPositionLeft,
      });
    },
    computeInfoCardPosition() {
      if (this.$refs.shiftWrapper === undefined || this.$refs.shiftInfoCard === undefined) return;

      const shiftRect = this.$refs.shiftWrapper.getBoundingClientRect();

      let left = shiftRect.right + 8;
      const infoCardWidth = this.$refs.shiftInfoCard.$el.offsetWidth;

      if (left + infoCardWidth >= window.innerWidth) {
        left = shiftRect.left - infoCardWidth - 8;
      }

      const infoCardHeight = this.$refs.shiftInfoCard.$el.offsetHeight;
      let top = shiftRect.top - infoCardHeight / 2 + shiftRect.height / 2;

      if (top <= 1) {
        top = 1;
      } else if (top + infoCardHeight >= window.innerHeight - 1) {
        top = window.innerHeight - infoCardHeight - 1;
      }

      this.infoCardTop = top;
      this.infoCardLeft = left;
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-wrapper {
  padding: 4px;
  line-height: 11.15px;
  width: 36px;
  height: 40px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &--opacited {
    opacity: .3;
  }

  &--leave-request {
    color: $sk-grey-50;
    font-weight: $fw-regular;
    background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey-10;

      ::before {
        position: absolute;
        content: '';
        top: 0;
        width: 100%;
        height: 3px;
        background-color: $sk-warning;
        margin-left: -4px;
      }
  }

  &--absence {
    font-weight: $fw-regular;
    background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey;
  }

  &--from-another-shop {
    color: $sk-grey-50;
    background-color: $sk-grey-10;
    border: 1px dashed $sk-grey-50;
  }

  span {
    z-index: 1;
  }

  .shift-alert {
    cursor: pointer;
    position: absolute;
    bottom: 3px;
    right: 3px;
    z-index: 2;
  }

  .shift-time {
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &--after-start {
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 8px;
      height: 100%;
      background:
      repeating-linear-gradient(
        45deg,
        transparent,
        transparent 8px,
        rgba(#000, .05) 8px,
        rgba(#000, .05) 16px
      ),
      $sk-grey;
    }
  }

  &--before-end {
    &::before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 8px;
        height: 100%;
        background:
          repeating-linear-gradient(
            45deg,
            transparent,
            transparent 8px,
            rgba(#000, .05) 8px,
            rgba(#000, .05) 16px
          ),
          $sk-grey;
      }
  }
}

.absence-name--wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 2;
  word-break: break-all;
}
</style>
