<template>
  <SkCard class="pos-card__wrapper">
    <SkCollapse
      ref="collapse"
      :disabled="!switchActivated || toggleDisabled || loading"
    >
      <template #labelLeft>
        <div class="sk-flex pos-card__label">
          <div class="partner-icon sk-flex-center">
            <component
              :is="iconName"
              v-if="iconName"
            />
            <div
              v-if="iconUrl"
              :style="{
                backgroundImage: `url(${iconUrl})`,
                display: 'grid',
                placeItems: 'center',
                backgroundSize: 'contain',
                width: '76%',
                height: '75%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }"
            />
          </div>
          <div class="sk-flex-center">
            <h2 class="sk-header--2">
              {{ name }}
            </h2>
          </div>
          <SkOroraTag
            v-if="pending"
            v-tooltip.right="$t('organisation_settings.tabs.pos_partners.chift.organisation_pending_connection')"
            color="skWarning"
            background-color="skWarning10"
            icon="InfoV2Icon"
            class="pos-card__pending-tag"
          >
            {{ $t('organisation_settings.tabs.pos_partners.chift.pending') }}
          </SkOroraTag>
        </div>
      </template>
      <template #labelRight>
        <div class="switch-wrapper">
          <SkLoader v-if="loading" />
          <SkSwitch
            v-else
            ref="localSwitch"
            v-model="switchActivated"
            v-tooltip.auto="tooltipOnDisabled"
            :disabled="disabled"
            :data-test="testingOptions.toggle"
            @click.native.stop
          />
        </div>
      </template>
      <template #content>
        <div class="pos-card__content">
          <slot />
        </div>
      </template>
    </SkCollapse>
  </SkCard>
</template>

<script>
import {
  Zelty,
  Lightspeed,
  Tiller,
  Laddition,
} from '../shared/icons/index';

export default {
  name: 'PosPartnerCard',
  components: { Zelty, Lightspeed, Tiller, Laddition },
  props: {
    name: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: false,
      default: null,
    },
    iconUrl: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    toggleDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipOnDisabled: {
      type: String,
      default: '',
    },
    testingOptions: {
      type: Object,
      default: () => ({
        toggle: undefined,
      }),
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchActivated: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-card__wrapper {
  padding: 0;

  ::v-deep .sk-collapse__caret-wrapper {
    margin: 0 13px;
  }
}

.pos-card__label {
  padding-left: 14px;
  align-items: center;
}

.pos-card__content {
  padding: 30px;
  padding-top: 10px;
}

.partner-icon {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid $sk-grey-10;
  margin-right: 20px;
}

.switch-wrapper {
  margin-top: -3px;
}

.pos-card__pending-tag {
  margin-left: 16px;
}
</style>
