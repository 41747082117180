import skDate from '@skello-utils/dates';
import { computeShiftsExpandedRange } from '@app-js/plannings/shared/utils/planning_helpers';
import { httpClient } from '@skello-utils/clients';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';

const initialState = {
  isGlobalDataLoading: false,
  isPlanningDataLoading: false,
  isSecondaryDataLoading: false,
  arePlanningDataBatchesLoading: false,
  abortRecursiveBatching: false,
  lastFetchParams: null,
  periodParams: null,
  planningDataStatus: PLANNING_DATA_STATUS.NOT_LOADED,
};

const gettersList = {
  isLoadingInitialData(state) {
    return state.planningDataStatus === PLANNING_DATA_STATUS.NOT_LOADED ||
      state.planningDataStatus === PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED;
  },
  isProgressiveLoadingEnabled(state, getters, rootState, rootGetters) {
    return rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_NEW_PROGRESSIVE_FETCHING_STORE');
  },
  isFirstBatchLoaded(state) {
    return state.planningDataStatus === PLANNING_DATA_STATUS.FIRST_BATCH_LOADED;
  },
  isLoadingCompleted(state) {
    return state.planningDataStatus === PLANNING_DATA_STATUS.ALL_LOADED;
  },
  isLoadingBatches(state) {
    return state.planningDataStatus === PLANNING_DATA_STATUS.LOADING_BATCHES;
  },
};

const mutations = {
  setLastFetchParams(state, params) {
    state.lastFetchParams = params;
  },
  setIsGlobalDataLoading(state, isLoading) {
    state.isGlobalDataLoading = isLoading;
  },
  setIsPlanningDataLoading(state, isLoading) {
    state.isPlanningDataLoading = isLoading;
  },
  setArePlanningDataBatchesLoading(state, areLoading) {
    state.arePlanningDataBatchesLoading = areLoading;
  },
  setAbortRecursiveBatching(state, shouldAbort) {
    state.abortRecursiveBatching = shouldAbort;
  },
  setPlanningDataStatus(state, status) {
    state.planningDataStatus = status;
  },
  setIsSecondaryDataLoading(state, isLoading) {
    state.isSecondaryDataLoading = isLoading;
  },
  resetLoadingState(state) {
    state.arePlanningDataBatchesLoading = false;
    state.isGlobalDataLoading = false;
    state.abortRecursiveBatching = false;
    state.isPlanningDataLoading = false;
  },
  setPeriodParams(state, params) {
    state.periodParams = params;
  },
};

const actions = {
  async fetchPeriodPlanningData({ state, dispatch, commit }, { period, options }) {
    const { shopId, fetchDayRateUsersDaysWorked,
      shouldFetchByBatches, batchSize } = options;

    dispatch('planningsPostes/fetchPostes', shopId, { root: true });
    await dispatch('setInitialLoadingState', { isPeriodMonth: period === 'month' });

    const periodParams = state.periodParams;

    await dispatch('fetchPlanningData', {
      shopId,
      period,
      periodParams,
      fetchDayRateUsersDaysWorked,
      shouldFetchByBatches,
      batchSize,
    });
    if (period !== 'month') commit('resetLoadingState');
  },
  async fetchPlanningShopGlobalData({ commit, dispatch, rootGetters }, { shopId }) {
    commit('setIsGlobalDataLoading', true);
    const baseDataFetch = [
      dispatch('planningsState/fetchShopAlerts', shopId, { root: true }),
      dispatch('planningsState/fetchUserPlanningConfig', shopId, { root: true }),
      dispatch('planningsState/fetchShopPlanningConfig', shopId, { root: true }),
      dispatch('planningsState/fetchWeeklyOptions', shopId, { root: true }),
    ];

    if (rootGetters['planningState/isMonthlyView']) {
      baseDataFetch.push(dispatch('planningsState/fetchMonthlyOptions', shopId, { root: true }));
    }

    await Promise.all(baseDataFetch);

    dispatch('fetchSecondaryPlanningData', { shopId });

    commit('setIsGlobalDataLoading', false);
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
  },
  // Responsible for fetching information that is not needed for the initial load.
  async fetchSecondaryPlanningData({ dispatch, rootState, rootGetters, commit }, { shopId }) {
    commit('setIsSecondaryDataLoading', true);
    const secondaryDataFetch = [
      dispatch('shopTeams/fetchTeams', {
        shopId,
        isVariableContractHoursAvailable: rootGetters['currentShop/isVariableContractHoursAvailable'],
      }, { root: true }),
    ];

    // Fetches absences for creating absence modal
    if (rootState.currentLicense.currentLicense.attributes.canCreateShifts ||
      rootState.currentLicense.currentLicense.attributes.canReadShopRulesAndAbsences) {
      secondaryDataFetch.push(dispatch('planningsPostes/fetchAbsences', shopId, { root: true }));
    }

    await Promise.all(secondaryDataFetch);
    commit('setIsSecondaryDataLoading', false);
  },
  async fetchBatchablePlanningShopDateRangeData({ dispatch, state }, options) {
    const { shopId, period } = options;

    await dispatch('preparePeriodParams', { shopId, period });

    switch (period) {
      case 'day':
        // daily will fetch the entire data immediately
        return dispatch('fetchPeriodPlanningData', { period, options });
      case 'week':
        // weekly will wait for the scroll event to trigger recursive fetching
        return dispatch('fetchPeriodPlanningData', { period, options });
      case 'month': {
        await dispatch('fetchPeriodPlanningData', { period, options });
        // Monthly immediately call the recursive function, unlike weekly whose waiting for a trigger
        const isLastUsersBatch = state.lastFetchParams.isLastUsersBatch;
        return dispatch('fetchBatchableDataRecursively', {
          ...options,
          usersBatchPage: 2,
          isLastUsersBatch,
        });
      }
      default:
        throw new Error(`Unsupported period: ${period}`);
    }
  },

  // Helper functions
  async setInitialLoadingState({ commit }, { isPeriodMonth }) {
    commit('setIsPlanningDataLoading', true);
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
    commit('shopTeams/performingRequest', 'fetchTeamScheduleLoading', { root: true });
    commit('planningsShifts/shiftsRequestPending', isPeriodMonth, { root: true });
    if (isPeriodMonth) {
      commit('planningsState/closeTotalPeriodTab', null, { root: true });
    }
  },

  async preparePeriodParams({ rootGetters, commit }, { shopId, period }) {
    const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';
    const { startsAt, endsAt } = rootGetters['planningsState/periodDates'](dateRangeToRetrieve);
    const periodParams = {
      shop_id: shopId,
      starts_at: startsAt,
      ends_at: endsAt,
      is_monthly_fetch: period === 'month',
    };
    commit('setPeriodParams', periodParams);

    return periodParams;
  },

  async fetchPlanningData({ state, rootGetters, dispatch, commit }, options) {
    /* We will use this function to fetch either the entire data or the first batch
    depending on the variable shouldFetchByBatches */

    const { shopId, period, fetchDayRateUsersDaysWorked,
      shouldFetchByBatches, batchSize, periodParams } = options;

    const isProgressiveLoadingEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_NEW_PROGRESSIVE_FETCHING_STORE');
    const awaitAsyncDataFetch = !isProgressiveLoadingEnabled || !shouldFetchByBatches;

    if (state.abortRecursiveBatching) return; // Abort fetch

    let batchableAsyncDataResponse;
    if (awaitAsyncDataFetch) {
      [, batchableAsyncDataResponse] = await Promise.all([
        dispatch('fetchNonBatchableAsyncData', { shopId, period, periodParams }),
        dispatch('fetchBatchableAsyncData', {
          shopId,
          period,
          fetchDayRateUsersDaysWorked,
          periodParams,
          batchingContext: { shouldFetchByBatches,
            batchSize,
            usersBatchPage: 1,
            overwriteStore: true },
        }),
      ]);
    } else {
      dispatch('fetchNonBatchableAsyncData', { shopId, period, periodParams });
      batchableAsyncDataResponse = await dispatch('fetchBatchableAsyncData', {
        shopId,
        period,
        fetchDayRateUsersDaysWorked,
        periodParams,
        batchingContext: { shouldFetchByBatches,
          batchSize,
          usersBatchPage: 1,
          overwriteStore: true },
      });
    }

    const { isLastUsersBatch, fetchUsersResponse } = batchableAsyncDataResponse;

    if (shouldFetchByBatches) {
      commit('planningsUsers/setPlanningUsers', { users: fetchUsersResponse, overwriteStore: true }, { root: true });
    }

    if (state.abortRecursiveBatching) return; // Abort fetch

    if (awaitAsyncDataFetch) {
      await dispatch('fetchBatchableSyncData', {
        shopId,
        period,
        periodParams,
        batchingContext: {
          shouldFetchByBatches,
          overwriteStore: true,
          usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
        },
      });
    } else {
      dispatch('fetchBatchableSyncData', {
        shopId,
        period,
        periodParams,
        batchingContext: {
          shouldFetchByBatches,
          overwriteStore: true,
          usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
        },
      });
    }

    if (state.abortRecursiveBatching) return; // Abort fetch
    commit('setIsPlanningDataLoading', false);
    if (period === 'week') {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.FIRST_BATCH_LOADED);
    } else if (period === 'month') {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.LOADING_BATCHES);
    } else {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
    }

    // set lastFetchParams to allow the recursive function to fetch the remaining batches
    // from the last point fetched
    commit('setLastFetchParams', { ...options, usersBatchPage: 2, isLastUsersBatch });
  },

  // Fetch remaining batches recursively
  async fetchBatchableDataRecursively({ state, dispatch, commit }, options) {
    if (state.abortRecursiveBatching) {
      commit('resetLoadingState');
      return;
    }

    const { shopId, period, fetchDayRateUsersDaysWorked,
      batchSize, usersBatchPage, isLastUsersBatch } = options;

    if (isLastUsersBatch) {
      commit('resetLoadingState');
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
      return;
    }

    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.LOADING_BATCHES);
    // Unassigned shifts are fetched in the first call, no need to refetch them
    commit('setPeriodParams', { ...state.periodParams, scope: 'assigned' });

    const periodParams = state.periodParams;

    commit('setArePlanningDataBatchesLoading', true);

    const batchingContext = {
      shouldFetchByBatches: true,
      batchSize,
      usersBatchPage,
    };

    const { isLastUsersBatch: newIsLastUsersBatch, fetchUsersResponse } = await dispatch('fetchBatchableAsyncData', {
      shopId,
      period,
      fetchDayRateUsersDaysWorked,
      periodParams,
      batchingContext,
    });
    commit('planningsUsers/setPlanningUsers', { users: fetchUsersResponse, overwriteStore: false }, { root: true });

    if (state.abortRecursiveBatching) {
      commit('resetLoadingState');
      return;
    }
    await dispatch('fetchBatchableSyncData', {
      shopId,
      period,
      periodParams,
      batchingContext: {
        shouldFetchByBatches: true,
        overwriteStore: false,
        usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
      },
    });

    if (state.abortRecursiveBatching) {
      commit('resetLoadingState');
      return;
    }
    await dispatch('fetchBatchableDataRecursively', {
      ...options,
      usersBatchPage: usersBatchPage + 1,
      isLastUsersBatch: newIsLastUsersBatch,
    });
  },

  // Fetch remaining batches non recursively (in one go)
  async fetchBatchableDataNonRecursively({ state, dispatch, commit }, options) {
    if (state.abortRecursiveBatching) {
      commit('resetLoadingState');
      return;
    }

    const { shopId, period, fetchDayRateUsersDaysWorked,
      batchSize, usersBatchPage, isLastUsersBatch } = options;

    if (isLastUsersBatch) {
      commit('resetLoadingState');
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
      return;
    }

    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.LOADING_BATCHES);
    // Unassigned shifts are fetched in the first call, no need to refetch them
    commit('setPeriodParams', { ...state.periodParams, scope: 'assigned' });

    const periodParams = state.periodParams;

    commit('setArePlanningDataBatchesLoading', true);

    const batchingContext = {
      shouldFetchByBatches: false,
      batchSize,
      usersBatchPage,
      forceFetchAllRemainingBatches: true,
    };

    const { fetchUsersResponse } = await dispatch('fetchBatchableAsyncData', {
      shopId,
      period,
      fetchDayRateUsersDaysWorked,
      periodParams,
      batchingContext,
    });

    commit('planningsUsers/setPlanningUsers', { users: fetchUsersResponse, overwriteStore: false }, { root: true });

    if (state.abortRecursiveBatching) {
      commit('resetLoadingState');
      return;
    }
    await dispatch('fetchBatchableSyncData', {
      shopId,
      period,
      periodParams,
      batchingContext: {
        shouldFetchByBatches: false,
        overwriteStore: false,
        usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
        forceFetchAllRemainingBatches: true,
      },
    });

    commit('resetLoadingState');
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
  },

  async fetchNonBatchableAsyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, periodParams },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is not batchable
      and has no dependence with other fetches
    */
    // Compute everything needed
    const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';

    // Start fetching data
    const baseDataFetch = [
      dispatch('planningsState/fetchEvents', periodParams, { root: true }),
      dispatch('planningsState/fetchHolidays', periodParams, { root: true }),
    ];

    const conditionalDataFetch = [];

    if (rootState.currentLicense.currentLicense.attributes.canCreateShifts && period === 'day') {
      conditionalDataFetch.push(
        dispatch('planningsTemplates/fetchTemplates', { shopId, activePlanning: period }, { root: true }),
      );
    }

    if (dateRangeToRetrieve === 'week') {
      conditionalDataFetch.push(
        dispatch('planningsState/fetchWeeklyOptions', shopId, { root: true }),
      );
    } else if (dateRangeToRetrieve === 'month') {
      conditionalDataFetch.push(
        dispatch('planningsState/fetchMonthlyOptions', { shopId }, { root: true }),
      );
    }

    // Only add this extra call to the queries list when the 'extra_contract' alert is activated
    if (rootGetters['planningsState/getActiveAlertsList'].includes('extra_contract')) {
      const extraQuarterDataParams = {
        shop_id: shopId,
        starts_at: skDate(rootGetters['planningsState/monday']).startOf('quarter').format('YYYY-MM-DD'),
        ends_at: skDate(rootGetters['planningsState/sunday']).endOf('quarter').format('YYYY-MM-DD'),
      };

      conditionalDataFetch.push(dispatch('planningsShifts/fetchQuarterDataForAlerts', extraQuarterDataParams, { root: true }));
    }

    await Promise.all(baseDataFetch.concat(conditionalDataFetch));
  },
  async fetchBatchableAsyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, fetchDayRateUsersDaysWorked, periodParams, batchingContext },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is batchable
      and has no dependence with other fetches
    */
    const { shouldFetchByBatches, batchSize, usersBatchPage, overwriteStore = false,
      forceFetchAllRemainingBatches = false } = batchingContext;
    let usersFromLastBatch = null;
    let fetchUsersResponse = null;
    let isLastUsersBatch = true;
    const baseDataFetch = [];

    // If we are fetching by batches, we need to fetch first the users
    const fetchUsersParams = {
      ...periodParams,
      with_first_shift_starts_at: true,
      with_cyclic_amendments: true,
      with_recurrent_shifts: rootGetters['planningsState/isMonthlyView'] || rootGetters['planningsState/isEmployeesView'],
    };

    if (shouldFetchByBatches) {
      const fetchUsersByPageParams = {
        ...fetchUsersParams,
        skip_pagination: false,
        current_page: usersBatchPage,
        per_page: batchSize,
      };

      fetchUsersResponse = await httpClient
        .get('/v3/api/plannings/users', { params: fetchUsersByPageParams });

      const { data: { data: usersResponse, meta } } = fetchUsersResponse;

      const { pagination: { current_page: currentPage, total_pages: totalPages } } = meta;
      isLastUsersBatch = currentPage === totalPages;
      usersFromLastBatch = usersResponse;
    } else if (forceFetchAllRemainingBatches) {
      // This is a middle ground between fetching all users at once and fetching by batches

      fetchUsersParams.skip_pagination = true;
      fetchUsersParams.offset = (usersBatchPage - 1) * batchSize; // fetch only remaining users

      fetchUsersResponse = await httpClient
        .get('/v3/api/plannings/users', { params: fetchUsersParams });

      const { data: { data: usersResponse } } = fetchUsersResponse;
      usersFromLastBatch = usersResponse;

      isLastUsersBatch = true;
    } else {
      baseDataFetch.push(
        dispatch('planningsUsers/fetchPlanningUsers', { params: fetchUsersParams }, { root: true }),
      );
    }

    // Prepare shifts and availabilities fetch
    const shouldOverwriteStore = overwriteStore ||
      (!shouldFetchByBatches && !forceFetchAllRemainingBatches);
    const activeAlertsList = rootGetters['planningsState/getActiveAlertsList'];
    const maxDayStraightNb = rootState.currentShop.currentShop.relationships
      .convention.attributes.maxDayStraightNb;
    const lastBatchUserIds = usersFromLastBatch?.map(user => user.id) || [];
    const basePeriodParams = {
      ...periodParams,
      ...((shouldFetchByBatches || forceFetchAllRemainingBatches) &&
        { user_ids: lastBatchUserIds }),
    };
    const fetchShiftParams =
      period === 'day' ?
        basePeriodParams :
        computeShiftsExpandedRange(basePeriodParams, activeAlertsList, maxDayStraightNb);

    baseDataFetch.push(
      dispatch(
        'planningsShifts/fetchShifts',
        { params: fetchShiftParams, overwriteStore: shouldOverwriteStore },
        { root: true },
      ),
      dispatch(
        'planningsUsers/fetchAvailabilities',
        { params: basePeriodParams, overwriteStore: shouldOverwriteStore },
        { root: true },
      ),
    );

    // Prepare pending leave requests fetch
    let fetchPendingLeaveRequestShiftsParams;
    if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      fetchPendingLeaveRequestShiftsParams = {
        shopIds: JSON.stringify([periodParams.shop_id]),
        startsAt: periodParams.starts_at,
        endsAt: periodParams.ends_at,
        statusFilters: JSON.stringify(['pending']),
        skipPagination: true,
      };
    } else {
      fetchPendingLeaveRequestShiftsParams = {
        ...periodParams,
        statuses: 'pending',
        skip_pagination: true,
      };
    }

    baseDataFetch.push(dispatch(
      'planningsShifts/fetchPendingLeaveRequestShifts',
      fetchPendingLeaveRequestShiftsParams,
      { root: true },
    ));

    // Prepare extra data fetch
    const conditionalDataFetch = [];
    if (fetchDayRateUsersDaysWorked) {
      conditionalDataFetch.push(
        dispatch(
          'planningsUsers/fetchDayRateUsersDaysWorked',
          { params: basePeriodParams, overwriteStore: shouldOverwriteStore },
          { root: true },
        ),
      );
    }

    if (period === 'month') {
      const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';
      const { startsAt } = rootGetters['planningsState/periodDates'](dateRangeToRetrieve);

      conditionalDataFetch.push(
        dispatch('monthlyPlanning/fetchBulkPaidLeavesCounters',
          { params: { shopId,
            userIds: lastBatchUserIds,
            date: startsAt },
          overwriteStore: shouldOverwriteStore },
          { root: true },
        ),
      );
    }

    await Promise.all(baseDataFetch.concat(conditionalDataFetch));

    return {
      isLastUsersBatch,
      fetchUsersResponse:
      fetchUsersResponse ? fetchUsersResponse.data : null,
    };
  },
  async fetchBatchableSyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, periodParams, batchingContext },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is batchable
      and has dependence with other fetches (i.e. needs to wait for other fetches to finish before being called)
    */
    const { shouldFetchByBatches, usersFromLastBatch,
      forceFetchAllRemainingBatches } = batchingContext;
    // Compute everything needed
    const shopIdInt = parseInt(shopId, 10);
    const planningUsers = (shouldFetchByBatches || forceFetchAllRemainingBatches) ?
      usersFromLastBatch :
      rootState.planningsUsers.users;
    const teamIds = [...new Set(planningUsers.flatMap(user => (
      user.relationships.teams.data.map(team => team.id)
    )))];

    // Start fetching data
    if (['week', 'month'].includes(period)) {
      const isProgressiveLoadingEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_NEW_PROGRESSIVE_FETCHING_STORE');
      const awaitAsyncDataFetch = !isProgressiveLoadingEnabled ||
        (!shouldFetchByBatches && !forceFetchAllRemainingBatches);
      if (awaitAsyncDataFetch) {
        await dispatch('shopTeams/fetchTeamSchedules', { teamIds, shopId: shopIdInt }, { root: true });
      } else {
        dispatch('shopTeams/fetchTeamSchedules', { teamIds, shopId: shopIdInt }, { root: true });
      }
    }
    const userIds = planningUsers.map(user => user.id);
    const isAnyShiftsForCurrentPeriod = rootGetters['planningsShifts/shiftsForCurrentPeriod'].some(shift => (
      shift.attributes.shopId === shopIdInt
    ));
    const currentLicenseCanReadEmployeeInfo =
      rootState.currentLicense.currentLicense.attributes.canReadEmployeeInfo;

    if (isAnyShiftsForCurrentPeriod) {
      dispatch('planningsShifts/fetchShiftAlerts', {
        ...periodParams,
        user_ids: userIds,
        readonly: true,
      }, { root: true });
    }

    if (currentLicenseCanReadEmployeeInfo) {
      dispatch('employees/fetchAvatars', {
        ...periodParams,
        user_ids: userIds,
      }, { root: true });
    }

    dispatch('employeeCounters/fetchUsersHoursCounters', { allUserCounters: false, shopId, users: usersFromLastBatch }, { root: true });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
