<template>
  <SkDropdown
    id="navbar__user-dropdown"
    ref="dropdown"
    placement="bottom-start"
    y-offset="10px"
    trigger="click"
    append-to-body
    @show-dropdown="handleShowDropdown"
    @hide-dropdown="handleHideDropdown"
  >
    <template #anchor>
      <div class="navbar__user-dropdown__anchor">
        <SkMedallion
          :text="userInitials"
          background-color="#2b66fe"
          color="white"
          size="small"
        />
        <CaretIcon :class="caretClass" />
        <div
          v-if="showPendingRequestBadge"
          class="navbar__user-dropdown__requests-dot"
        />
      </div>
    </template>
    <template #menu>
      <div class="navbar__user-dropdown__menu">
        <div class="user-dropdown__header">
          <h2 class="user-dropdown__user-name">
            {{ userFullName }}
          </h2>
          {{ currentUser.attributes.email }}
        </div>
        <FreeTrialCard
          v-if="showSelfServeFreeTrial"
          @click.native="closeDropdown"
        />
        <div class="user-dropdown__section">
          <p class="user-dropdown__section-title">
            {{ $t('navbar.profile_settings') }}
          </p>
          <router-link
            :to="{ name: 'profile' }"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__profile-link"
            @click.native="closeDropdown"
          >
            <PersonIcon class="user-dropdown__section-icon" />
            {{ $t('navbar.profile_title') }}
          </router-link>
        </div>
        <div
          v-if="canAccessGeneralSettingsPage"
          class="user-dropdown__section"
        >
          <p class="user-dropdown__section-title">
            {{ $t('navbar.general_settings') }}
          </p>
          <router-link
            :to="{ name: 'organisation_settings' }"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__general-settings-link"
            @click.native="handleOrganisationClick"
          >
            <CogIcon class="user-dropdown__section-icon" />
            {{ currentOrganisation.attributes.name }}
          </router-link>
        </div>
        <div
          v-if="canAccessRequestsPage"
          class="user-dropdown__section"
        >
          <p class="user-dropdown__section-title">
            {{ $t('navbar.requests') }}
          </p>
          <router-link
            v-if="canAccessLeaveRequests"
            :to="leaveRequestsRoute"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__absences-link"
            @click.native="closeDropdown"
          >
            <PalmTreeIcon
              class="user-dropdown__section-icon"
            />
            <span class="user-dropdown__section-notification">
              {{ $t('navbar.absences') }}
              <div
                v-if="canShowPendingRequestsBadge('leave')"
                class="user-dropdown__new-requests__pending-badge"
              >
                {{ decoratedPendingLeaveRequests }}
              </div>
            </span>
          </router-link>
          <router-link
            v-if="canAccessAvailabilities"
            :to="availabilityRequestsRoute"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__availabilities-link"
            @click.native="closeDropdown"
          >
            <CheckedCalendarV2Icon
              class="user-dropdown__section-icon"
            />
            <span class="user-dropdown__section-notification">
              {{ $t('navbar.availabilities') }}
              <div
                v-if="canShowPendingRequestsBadge('availability')"
                class="user-dropdown__new-requests__pending-badge"
              >
                {{ decoratedPendingAvailabilityRequests }}
              </div>
            </span>
          </router-link>
        </div>
        <div
          v-if="impersonateUser"
          class="user-dropdown__section"
        >
          <a
            href="#"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__return-link"
            @click="logOutImpersonate"
          >
            <BackArrowIcon class="user-dropdown__section-icon" />
            {{ $t('navbar.back_office') }}
          </a>
        </div>
        <div class="user-dropdown__section">
          <a
            href="#"
            class="user-dropdown__section-content"
            data-test="navbar__user-dropdown__log-out-link"
            @click="logOut"
          >
            <PowerOffIcon class="user-dropdown__section-icon" />
            {{ $t('navbar.log_out') }}
          </a>
        </div>
      </div>
    </template>
  </SkDropdown>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
} from 'vuex';
import { authClient } from '@skello-utils/clients/auth_client';
import { FEATURES } from '@app-js/shared/constants/features.js';
import FreeTrialCard from './FreeTrialCard';

export default {
  name: 'UserDropdown',
  components: {
    FreeTrialCard,
  },
  data() {
    return {
      caretDown: false,
      pendingRequests: 0,
      FEATURES,
    };
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('currentOrganisation', ['currentOrganisation']),
    ...mapState('navContext', ['navContext']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('requests', ['pendingRequestCounts']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('features', ['isFeatureEnabled']),
    ...mapGetters('requests', [
      'decoratedPendingRequests',
      'decoratedPendingLeaveRequests',
      'totalPendingLeaveRequests',
      'totalPendingAvailabilityRequests',
      'decoratedPendingAvailabilityRequests',
    ]),
    ...mapGetters({
      userInitials: 'currentUser/initials',
      userFullName: 'currentUser/fullName',
      impersonateUser: 'currentUser/impersonate',
      currentUserIsSystemAdmin: 'currentLicense/isSystemAdmin',
      isDevFlagEnabled: 'currentShop/isDevFlagEnabled',
    }),
    ...mapGetters('currentOrganisation', ['isFreeTrialEnabled']),
    showPendingRequestBadge() {
      if (!this.currentLicense.attributes.canManageEmployeeRequests) {
        return false;
      }
      return this.pendingRequestCounts.total > 0;
    },
    showSelfServeFreeTrial() {
      return this.isFreeTrialEnabled && this.currentUserIsSystemAdmin;
    },
    caretClass() {
      const caretOrientation = this.caretDown ? 'down' : 'up';
      return `user-dropdown__caret caret--${caretOrientation}`;
    },
    isEmployee() {
      return Object.values(this.config.licenses_by_level.employees).includes(
        this.currentLicense.attributes.originalType,
      );
    },
    isAvailabilitiesFeatureEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_AVAILABILITIES,
        this.currentShop.id,
        () => true,
      );
    },
    isLeaveRequestsFeatureEnabled() {
      return this.isFeatureEnabled(
        FEATURES.FEATURE_USER_REQUESTS,
        this.currentShop.id, () => (
          this.currentOrganisation.attributes.packOffer.leave_requests_enabled
        ),
      );
    },
    canAccessGeneralSettingsPage() {
      return !this.isEmployee && (this.currentUserIsSystemAdmin ||
        this.currentLicense.attributes.canEditShopPermissions ||
        this.currentLicense.attributes.canCreateShop);
    },
    canFetchRequestsCount() {
      return (this.isAvailabilitiesFeatureEnabled || this.isLeaveRequestsFeatureEnabled) && (
        this.currentUserIsSystemAdmin ||
        this.currentLicense.attributes.canManageEmployeeRequests
      );
    },
    canAccessRequestsPage() {
      return this.canAccessLeaveRequests || this.canAccessAvailabilities;
    },
    canAccessAvailabilities() {
      return this.isAvailabilitiesFeatureEnabled && (this.currentUserIsSystemAdmin ||
        this.currentLicense.attributes.canCreateSelfAvailabilities ||
        this.currentLicense.attributes.canManageEmployeeRequests);
    },
    canAccessLeaveRequests() {
      return this.isLeaveRequestsFeatureEnabled && (this.currentUserIsSystemAdmin ||
        this.currentLicense.attributes.canCreateSelfLeaveRequests ||
        this.currentLicense.attributes.canManageEmployeeRequests);
    },
    shopId() {
      return this.currentShop.id === 'all' ? this.currentUser.attributes.shopId : this.currentShop.id;
    },
    leaveRequestsRoute() {
      const route = {
        name: 'leave_requests',
        params: { shop_id: this.shopId },
      };

      if (this.isDevFlagEnabled('FEATUREDEV_LEAVE_REQUESTS_V3_ALL') &&
      this.currentShop.id === 'all') {
        route.params.shop_id = 'all';
        route.query = { cluster_node_id: this.navContext.clusterNodeId };
      }

      return route;
    },
    availabilityRequestsRoute() {
      return {
        name: 'availability_requests',
        params: { shop_id: this.shopId },
      };
    },
  },
  watch: {
    shopId(value) {
      if (this.canFetchRequestsCount) {
        this.fetchPendingRequestsCount({ shop_id: value });
      }
    },
    canAccessRequestsPage(newValue) {
      if (newValue && this.canFetchRequestsCount) {
        this.fetchPendingRequestsCount({ shop_id: this.shopId });
      }
    },
  },
  mounted() {
    if (this.canFetchRequestsCount) {
      this.fetchPendingRequestsCount({ shop_id: this.shopId });
    }
  },
  methods: {
    ...mapActions('requests', ['fetchPendingRequestsCount']),
    handleShowDropdown() {
      this.caretDown = true;
    },
    handleHideDropdown() {
      this.caretDown = false;
    },
    closeDropdown() {
      this.$nextTick(() => {
        this.$refs.dropdown.hide();
      });
    },
    handleOrganisationClick() {
      this.closeDropdown();
    },
    logOutImpersonate() {
      authClient.logOutImpersonate();

      this.closeDropdown();
    },
    logOut() {
      authClient.logOut();
    },
    canShowPendingRequestsBadge(requestType) {
      if (!this.currentLicense.attributes.canManageEmployeeRequests) {
        return false;
      }

      switch (requestType) {
        case 'leave':
          return this.totalPendingLeaveRequests > 0;
        case 'availability':
          return this.totalPendingAvailabilityRequests > 0;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar__user-dropdown__requests-dot {
  background-color: $sk-error;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  position: absolute;
  left: 19px;
  top: 0;
  border: 2px solid white;
}

.user-dropdown__requests__pending-badge {
  position: absolute;
  right: 30px;
  padding: 0 5px;
  padding-bottom: 1px;
  margin-top: 1px;
  font-size: 11px;
  background-color: $sk-error;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

.user-dropdown__new-requests__pending-badge {
  font-size: $fs-text-xs;
  background-color: $sk-error;
  color: white;
  font-weight: $fw-semi-bold;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__user-dropdown__menu {
  width: 240px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
  border-radius: 2px;
  position: absolute;
  left: -170px;
}

.user-dropdown__header {
  background-color: $sk-blue;
  height: 85px;
  padding: 23px 20px;
  color: white;
  font-size: $fs-text-s;

  .user-dropdown__user-name {
    font-size: $fs-text-m;
    margin: 0 0 7px;
  }
}

.navbar__user-dropdown__anchor {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  position: relative;

  .user-dropdown__caret {
    margin-left: 13px;
    transition: transform .3s;
  }
}

.user-dropdown__section {
  border-bottom: 1px solid $sk-grey-10;
  padding: 10px;
}

p.user-dropdown__section-title {
  color: $sk-grey;
  font-size: 11px;
  font-weight: $fw-medium;
  margin: 0 0 5px;
  padding: 5px 0 0 10px;
}

a.user-dropdown__section-content {
  display: flex;
  align-items: center;
  color: $sk-black;
  padding: 10px;
  width: 100%;

  .user-dropdown__section-notification {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background: $sk-grey-10;
    border-radius: 3px;
    color: $sk-blue;
    text-decoration: none;
  }

  .user-dropdown__section-icon {
    margin-right: 13px;
  }

  /* Icon colors */
  svg::v-deep path {
    fill: $sk-black;
  }

  &:hover {
    svg::v-deep path {
      fill: $sk-blue;
    }
  }
}
</style>
