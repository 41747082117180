<template>
  <!-- eslint-disable  max-len -->
  <SkModal
    v-if="showDeleteModal"
    :on-close="toggleDeleteModal"
  >
    <template #header>
      <ModalHeader
        :title="resolveModalTitle"
        :svg-name="'BinSvg'"
        :svg-background-color="'rgba($sk-error, 0.15)'"
        svg-fill="#FF5355"
      />
    </template>
    <template #body>
      <p
        v-if="currentClusterGotChildren"
        class="modal_delete-warning-text"
      >
        {{ computeWarningText }}
      </p>
      <p class="modal_delete-question">
        {{ computeConfirmationText }}
      </p>
    </template>
    <template #footer>
      <div
        :class="footerClass"
        class="modal-btn_container"
      >
        <div
          class="modal-btn blue-btn"
          @click="resolveAffirmativeAction"
        >
          {{ computeAffirmativeText }}
        </div>
        <div
          class="modal-btn red-btn"
          @click="resolveNegativeAction"
        >
          {{ computeNegativeText }}
        </div>
      </div>
    </template>
  </SkModal>
</template>

<script>

import SkModal from '../../ui/SkModal';
import ModalHeader from '../../ui/ModalHeader';

export default {
  name: 'DeleteModal',
  components: { SkModal, ModalHeader },
  props: {
    showDeleteModal: {
      type: Boolean,
      required: true,
    },
    toggleDeleteModal: {
      type: Function,
      required: true,
    },
    toggleMoveModal: {
      type: Function,
      required: true,
    },
    currentCluster: {
      type: Object,
      default: null,
    },
    removeCurrentCluster: {
      type: Function,
      required: true,
    },
    moveChildrenToNewParentColumn: {
      type: Function,
      required: true,
    },
    destroyClusterNodeAndChildren: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showMoveModal: false,
      breadcrumbList: [],
      parent: null,
      parentChildren: [],
      parentClusterNodeStore: {},
      footerClass: '',
      currentParentId: this.currentCluster.parent_id,
    };
  },
  computed: {
    resolveModalTitle() {
      if (this.currentCluster.name.length > 30) {
        return `${this.$t('organisation_settings.tabs.clusters.delete_node', { item: this.currentCluster.name.substring(0, 35) })}...`;
      }
      return this.$t('organisation_settings.tabs.clusters.delete_node', { item: this.currentCluster.name });
    },
    computeWarningText() {
      if (this.currentCluster.type !== 'shop') {
        return this.$t('organisation_settings.tabs.clusters.delete_node_warning', { item: this.currentCluster.name });
      }
      return this.$t('organisation_settings.tabs.clusters.delete_shop_warning', { item: this.currentCluster.name });
    },
    computeConfirmationText() {
      if (this.currentCluster.type !== 'shop') {
        if (this.currentClusterGotChildren) {
          return this.$t('organisation_settings.tabs.clusters.move_confirmation', { item: this.currentCluster.name });
        }
        return this.$t('organisation_settings.tabs.clusters.delete_node_confirmation', { item: this.currentCluster.name });
      }
      return this.$t('organisation_settings.tabs.clusters.delete_shop_confirmation', { item: this.currentCluster.name });
    },
    computeAffirmativeText() {
      if (this.isClusterNodeWithChildren) {
        return this.$t('organisation_settings.tabs.clusters.move');
      }
      return this.$t('actions.delete');
    },
    computeNegativeText() {
      if (this.isClusterNodeWithChildren) {
        return this.$t('organisation_settings.tabs.clusters.delete');
      }
      return this.$t('actions.cancel');
    },
    currentClusterGotChildren() {
      return (this.currentCluster.nb_node !== 0 || this.currentCluster.nb_shop !== 0);
    },
    isClusterNodeWithChildren() {
      return (this.currentCluster.type !== 'shop' && this.currentClusterGotChildren);
    },
  },
  methods: {
    resolveAffirmativeAction() {
      if (this.isClusterNodeWithChildren) {
        this.toggleMoveModal('deleteAndMove');
      } else {
        this.destroyClusterNodeAndChildren();
      }
    },
    resolveNegativeAction() {
      if (this.isClusterNodeWithChildren) {
        this.destroyClusterNodeAndChildren();
      } else {
        this.toggleDeleteModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_delete_icon_container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba($sk-error, .15);
  display: flex;
  margin-right: 20px;
}

.modal_cluster_icon_container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0d2ed9;
  display: flex;
  margin-right: 14px;
}

.delete_modal-header {
  display: flex;
  align-items: center;
  font-weight: $fw-semi-bold;
  margin-bottom: 16px;
}

.modal_delete-warning-text {
  margin-bottom: 25px;
  font-size: $fs-text-m;
  color: #2d2f30;
}

.modal_delete-question {
  font-size: $fs-text-m;
  font-weight: $fw-semi-bold;
}

.modal-btn_container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
}

.modal-btn {
  text-transform: uppercase;
  text-align: center;
  height: 40px;
  cursor: pointer;
  margin: 0 5px;
  background-color: lightgray;
  font-size: $fs-text-m;
  color: white;
  border-radius: 3px;
  line-height: 17px;

  &.blue-btn {
    padding: 12px 30px 10px;
    background-color: $sk-blue;
  }

  &.red-btn {
    padding: 12px 20px 10px;
    background-color: #ff5355;
  }
}

.breadcrumb-container {
  display: flex;
  background-color: white;
}

.breadcrumb-item {
  display: flex;
  color: #8c9396;
  margin-right: 10px;
  align-items: center;

  &:last-child {
    color: #2d2f30;

    .angle-right {
      display: none;
    }
  }
}

.breadcrumb-item-name {
  margin-right: 6px;
  cursor: pointer;
}

.child-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
}

.children-list-container {
  overflow: auto;
  max-height: 50vh;
}

.shadow-top {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
}

.disabled {
  pointer-events: none;
  opacity: .3;
}
</style>
