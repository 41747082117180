<template>
  <SkModalV2
    :id="`unemployment-report-modal-${clusterNode.id}`"
    :submit-disabled="disableSubmit"
    :title="$t('reports.modals.unemployment_report.modal_title')"
    :testing-options="modalTestingOptions"
    @show="resetData"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div class="unemployment-report-modal__description">
          <IconInfo class="unemployment-report-modal__description-icon-info" />
          <p class="unemployment-report-modal__description-paragraph">
            {{ $t('reports.modals.unemployment_report.help_text') }}
          </p>
        </div>

        <div
          v-if="showSelectEmployees"
          class="unemployment-report-modal__select-employees"
        >
          <strong>{{ $t('reports.select_employees.users_label') }}</strong>
          <SelectEmployees
            ref="selectEmployees"
            class="unemployment-report-modal__input"
            :employees-infos="employeesInfos"
            @set-selected-employees="handleSelectedEmployees"
          />
        </div>

        <div class="unemployment-report-modal__date-picker">
          <strong>{{ $t('reports.modals.unemployment_report.month_select_text') }}</strong>
          <SkDatePicker
            v-model="localDate"
            class="unemployment-report-modal__input"
            type="month"
            :errored="!localDate"
            :error-message="$t('errors.missing_value')"
            :lang="$i18n.locale"
            input-moment-format="MMMM YYYY"
            append-to-body
            hide-footer
          />
        </div>
      </SkModalSectionV2>
    </template>
  </SkModalV2>
</template>

<script>

import skDate from '@skello-utils/dates';
import { MODAL_HIDE_EVENT } from '@skelloapp/skello-ui';
import SelectEmployees from './SelectEmployees';

export default {
  name: 'UnemploymentReportModal',
  components: { SelectEmployees },
  props: {
    isRoot: {
      type: Boolean,
      required: false,
    },
    clusterNode: {
      type: Object,
      required: true,
    },
    employeesInfos: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      localDate: skDate().startOf('month').format('YYYY-MM-DD'),
      selectedUserIds: [],
      modalTestingOptions: {
        submit: 'unemployment-report-modal__submit',
        cancel: 'unemployment-report-modal__cancel',
      },
    };
  },
  computed: {
    disableSubmit() {
      return !this.localDate || (this.showSelectEmployees && this.selectedUserIds.length === 0);
    },
    showSelectEmployees() {
      return Object.keys(this.employeesInfos).length > 0;
    },
  },
  methods: {
    resetData() {
      if (this.showSelectEmployees) {
        this.$refs.selectEmployees.resetData();
      }
    },
    handleSelectedEmployees(newSelectedUserIds) {
      this.selectedUserIds = newSelectedUserIds;
    },
    handleSubmit(event) {
      const params = { date: this.localDate };

      if (this.selectedUserIds.length > 0) {
        params.user_ids = this.selectedUserIds;
      }

      this.$emit('send-unemployment-report', params);
    },
    handleCancel() {
      this.emitOnRoot(MODAL_HIDE_EVENT, null, `unemployment-report-modal-${this.clusterNode.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.unemployment-report-modal__description {
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 56px 18px 22px;
  display: flex;

  .unemployment-report-modal__description-paragraph {
    font-size: $fs-text-m;
    font-weight: 400;
    line-height: 157%;
    color: $sk-grey-50;
    margin: 0;
  }

  .unemployment-report-modal__description-icon-info {
    margin-top: 9px;
    margin-right: 20px;
    width: 70px;
  }
}

.unemployment-report-modal__date-picker,
.unemployment-report-modal__select-employees {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.unemployment-report-modal__input {
  width: 286px !important;
}
</style>
