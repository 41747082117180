export const payrollPreparationMixins = {
  methods: {
    redirectToNextTabIfComplete(tab, onComplete) {
      if (!this.$store.getters['employees/displayPayrollPreparation']) {
        return false;
      }

      const nextTab = this.$store.getters['selectedEmployee/getNextTabIfComplete'](tab);
      if (nextTab) {
        this.$router.push({ name: nextTab }, () => {
          onComplete();
          this.$nextTick(() => document.getElementById('employee-header')?.scrollIntoView());
        });
        return true;
      }

      return false;
    },
    redirectToNextEmployee() {
      if (!this.$store.getters['employees/displayPayrollPreparation']) {
        return;
      }

      const remainingEmployees = this.$store.state.employees.employees;

      // we are on the last employee before calling reloadsidebar
      if (remainingEmployees.length === 1) {
        this.emitOnRoot('show-all-employees-complete-modal');
        return;
      }

      const currentEmployeeId = this.$store.state.selectedEmployee.employee.id;

      const index = remainingEmployees.findIndex(e => e.id === currentEmployeeId);

      // Redirect to the first user, if we are on the last element
      const nextEmployeeId = remainingEmployees[(index + 1) % remainingEmployees.length].id;

      this.$router.replace({ params: { user_id: nextEmployeeId } });
    },
    displayMissingInformationNotification(tab) {
      const hasMissingMandatoryFieldsForPayroll =
        this.$store.getters['selectedEmployee/hasMissingMandatoryFieldsForPayroll'](tab);
      const displayPayrollPreparation = this.$store.getters['employees/displayPayrollPreparation'];

      return displayPayrollPreparation &&
        hasMissingMandatoryFieldsForPayroll;
    },
    hasMissingMandatoryFieldsForPayroll(tab) {
      return this.$store.getters['selectedEmployee/hasMissingMandatoryFieldsForPayroll'](tab);
    },
    showHighlightForField(value) {
      const displayPayrollPreparation = this.$store.getters['employees/displayPayrollPreparation'];

      return displayPayrollPreparation && !value;
    },
  },
};
