<template>
  <div
    :class="{
      'skeleton-line': true,
      'skeleton-line--normal': !emphasized,
      'skeleton-line--emphasized': emphasized,
    }"
  />
</template>

<script>
export default {
  name: 'LineSkeleton',
  props: {
    emphasized: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes loading-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.skeleton-line {
  border-radius: 4px;
}

.skeleton-line--normal {
  animation: loading-animation 1s ease-in-out infinite;
  background: linear-gradient(-90deg, #e9ecee 0%, #f1f6f9 30%, #e9ecee 100%);
  background-size: 400% 400%;
}

.skeleton-line--emphasized {
  animation: loading-animation 1s ease-in-out infinite;
  animation-delay: .4s;
  background: linear-gradient(-90deg, #d3d8dc 0%, #e9f0f6 30%, #d3d8dc 100%);
  background-size: 400% 400%;
}
</style>
