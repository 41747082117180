<template>
  <SkModal
    :id="`move-document-modal-${documentId}`"
    ref="moveDocumentModal"
    :modal-title="$t('employees.tabs.documents.move_modal.title')"
    :submit-button-label="$t('employees.tabs.documents.move_modal.move')"
    :tracking-options="trackingOptions"
    @submit="handleSubmit"
    @hidden="handleCancel"
  >
    <template #body>
      <SkModalSection
        class="move-document-modal__body"
        border-bottom="none"
      >
        <FoldersSelect
          v-model="selectedFolder"
          :select-label="$t('employees.tabs.documents.move_modal.move_here')"
          :show-folders="isHrisActive"
        />
      </SkModalSection>
    </template>
  </SkModal>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
} from 'vuex';

import FoldersSelect from './FoldersSelect';

export default {
  name: 'MoveDocumentModal',
  components: { FoldersSelect },
  props: {
    documentId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      selectedFolder: '',
    };
  },
  computed: {
    ...mapState('selectedEmployee', ['employee']),
    ...mapState('navContext', ['navContext']),
    ...mapGetters('currentOrganisation', ['isHrisActive']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('employeeDocuments', ['isDocumentV2']),
    trackingOptions() {
      return {
        submit: 'hris_move_document_validate',
        cancel: 'hris_move_document_cancel',
      };
    },
  },
  methods: {
    ...mapActions('employeeDocuments', ['updateDocument', 'updateDocumentV2']),
    folderState(folder) {
      return this.folderContents(folder) ? FOLDER_STATES.full : FOLDER_STATES.empty;
    },
    handleSubmit() {
      if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') && this.isDocumentV2(this.documentId)) {
        this.updateDocumentV2({
          folderPath: `/${ this.selectedFolder}`,
          documentId: this.documentId,
        });
      } else {
        this.updateDocument({
          employeeId: this.employee.id,
          folder: this.selectedFolder,
          documentId: this.documentId,
        });
      }
    },
    handleCancel() {
      this.selectedFolder = '';
    },
  },
};
</script>
