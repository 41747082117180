<template>
  <SkModalV2
    id="reset-tracker-modal"
    ref="resetTrackerModal"
    class="reset-tracker-modal"
    :title="$t('reports.modals.reset_tracker.title')"
    @show="resetData"
  >
    <template #body>
      <SkModalSectionV2 border-bottom="none">
        <div>
          {{ $t('reports.modals.reset_tracker.info', { week, date }) }}
        </div>

        <div class="reset-tracker-modal__select-employees">
          <span>
            {{ $t('reports.modals.reset_tracker.select.users_label') }}
          </span>

          <div class="reset-tracker-modal__select-employees-wrapper">
            <SelectEmployees
              ref="selectEmployees"
              :employees-infos="employeesInfos"
              @set-selected-employees="handleSelectedEmployees"
            />
          </div>
        </div>

        <div :class="resetTrackerModalClasses">
          <div class="reset-tracker-modal__option">
            <SkRadio
              id="reset-tracker"
              v-model="trackerAction"
              data-test="reset-tracker-modal__radio-reset"
              data-value="reset"
            >
              <label
                for="reset-tracker"
                class="label--clickable"
              >
                {{ $t('reports.modals.reset_tracker.choice.reset_tracker') }}
              </label>
            </SkRadio>
          </div>
          <div class="reset-tracker-modal__option reset-tracker-modal__second-option">
            <SkRadio
              id="adjust-tracker"
              v-model="trackerAction"
              data-test="reset-tracker-modal__radio-adjust"
              data-value="adjust"
            >
              <label
                for="adjust-tracker"
                class="label--clickable"
              >
                {{ $t('reports.modals.reset_tracker.choice.adjust_tracker') }}
              </label>
            </SkRadio>
          </div>
        </div>

        <div
          v-show="isAdjustTracker"
          border-bottom="none"
        >
          <div class="reset-tracker-modal__adjust-info">
            <InfoV2Icon
              class="reset-tracker-modal__adjust-info-icon"
              width="14"
              fill="#727272"
            />
            {{ $t('reports.modals.reset_tracker.adjust_tracker_info') }}
          </div>
          <table class="reset-tracker-modal__table">
            <colgroup>
              <col
                span="1"
                width="100%"
              >
              <col
                span="3"
                width="66%"
              >
            </colgroup>
            <thead>
              <tr>
                <th />
                <!-- eslint-disable vue/no-v-html -->
                <th v-html="trackerLabelBefore" />
                <th v-html="trackerLabelValue" />
                <th v-html="trackerLabelAfter" />
                <!-- eslint-enable vue/no-v-html -->
              </tr>
            </thead>
            <tbody>
              <TrackerRow
                v-for="user in selectedUsers"
                :key="user.id"
                :user="user"
                :tracker-in-seconds="employeesInfos[user.id].tracker"
                :input-tooltip="inputTooltip(user.id)"
                :is-input-disabled="isInputDisabled(user.id)"
                @update-user-manual-changes="value => handleManualChangeUpdate(user.id, value)"
              />
            </tbody>
          </table>
        </div>
      </SkModalSectionV2>
    </template>
    <template #cancel-btn>
      <SkOroraButton
        data-test="reset-tracker-modal__cancel"
        variant="secondary"
        @click="handleCancel"
      >
        {{ $t('reports.modals.reset_tracker.cancel') }}
      </SkOroraButton>
    </template>
    <template #submit-btn>
      <SkOroraButton
        data-test="reset-tracker-modal__submit"
        :disabled="disabledSubmit"
        @click="handleSubmit"
      >
        {{ $t('reports.modals.reset_tracker.submit') }}
      </SkOroraButton>
    </template>
  </SkModalV2>
</template>

<script>
import Vue from 'vue';

import { mapState } from 'vuex';

import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { nlToBr } from '@skello-utils/formatting/strings';
import skDate from '@skello-utils/dates';

import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-sdk';
import SelectEmployees from './SelectEmployees';
import TrackerRow from './TrackerRow';

export default {
  name: 'V3ResetTracker',
  components: {
    SelectEmployees,
    TrackerRow,
  },
  props: {
    employeesInfos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      manualChangesPerUser: {},
      selectedUserIds: [],
      trackerAction: 'reset',
      trackerLabelAfter: nlToBr(this.$t('reports.modals.reset_tracker.table.tracker_value_after')),
      trackerLabelBefore: nlToBr(this.$t('reports.modals.reset_tracker.table.tracker_value_before')),
      trackerLabelValue: nlToBr(this.$t('reports.modals.reset_tracker.table.change_value')),
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('shopTeams', ['teams']),

    week() {
      return skDate(this.lastPlainWeek).isoWeek();
    },
    date() {
      return skDate(this.lastPlainWeek).format('L');
    },
    disabledSubmit() {
      return this.selectedUsers.length === 0 || (this.formErrored && this.isAdjustTracker);
    },
    users() {
      return Object.values(this.employeesInfos).map(user => user.user_infos).filter(Boolean);
    },
    selectedUsers() {
      return this.users.filter(user => this.selectedUserIds.includes(user.id));
    },
    isAdjustTracker() {
      return this.trackerAction === 'adjust';
    },
    resetTrackerModalClasses() {
      return {
        'reset-tracker-modal__pick-action': true,
        'reset-tracker-modal__pick-action__extra-padding': !this.isAdjustTracker,
      };
    },
    formErrored() {
      const formValues = Object.values(this.manualChangesPerUser);
      return formValues.length === 0 || formValues.some(value => value === '' || String(value).includes('e') || value === Infinity);
    },
    // Returns last sunday of period
    lastPlainWeek() {
      const endDate = skDate.utc(this.$route.query.end_date);

      if (endDate.day() === 0) {
        return endDate.format();
      }

      return endDate.startOf('isoWeek').subtract(1, 'd').format();
    },
  },
  methods: {
    handleCancel() {
      this.$refs.resetTrackerModal.hide();
    },
    handleSubmit(event) {
      const params = {
        shop_id: this.currentShop.id,
        date: this.lastPlainWeek,
        users_ids: this.selectedUserIds,
      };

      if (this.trackerAction === 'reset') {
        const path = '/v3/api/bulk_planning_hours_datas/reset_trackers';
        this.$root.$emit('confirm', event, {
          title: this.$t('reports.modals.reset_tracker.confirm.title'),
          description: this.$t('reports.modals.reset_tracker.confirm.description'),
          submitLabel: this.$t('reports.modals.reset_tracker.confirm.submit_label'),
          cancelLabel: this.$t('reports.modals.reset_tracker.confirm.cancel_label'),
          onConfirmSuccess: () => this.updateTrackers(path, params),
        });
      } else {
        const path = '/v3/api/bulk_planning_hours_datas/adjust_trackers';
        params.manual_changes_per_user = this.manualChangesPerUser;
        this.updateTrackers(path, params);
      }
    },
    inputTooltip(userId) {
      if (!this.isCounterInitialized(userId)) {
        return this.$t('reports.modals.reset_tracker.table.disabled_tooltip.counter_not_initialized');
      }

      if (this.employeesInfos[userId].tracker !== 0 && !this.employeesInfos[userId].tracker) {
        return this.$t('reports.modals.reset_tracker.table.disabled_tooltip.counter_missing_on_week');
      }

      return '';
    },
    isCounterInitialized(userId) {
      return !!this.employeesInfos[userId].user_infos.counter_initialization_done_at;
    },
    isInputDisabled(userId) {
      return !this.isCounterInitialized(userId) ||
        (this.employeesInfos[userId].tracker !== 0 && !this.employeesInfos[userId].tracker);
    },
    updateTrackers(path, params) {
      const tracker = this.trackerAction === 'reset' ?
        'report_update_tracker_reset_confirmation' :
        'report_update_tracker_update_confirmation';
      this.$skAnalytics.track(tracker);

      return httpClient
        .patch(path, params)
        .then(() => {
          if (this.trackerAction === 'reset') {
            this.sendEvent(
              EVENT_SUBTYPE_ACTION.REPORT_BULK_HOUR_TRACKER_RESET,
              { users: this.selectedUsers, date: params.date },
            );
          }

          if (this.trackerAction === 'adjust') {
            this.sendEvent(
              EVENT_SUBTYPE_ACTION.REPORT_BULK_HOUR_TRACKER_UPDATE,
              {
                users: this.selectedUsers,
                date: params.date,
                values: params.manual_changes_per_user,
              });
          }

          this.$refs.resetTrackerModal.hide();

          this.$skToast({
            message: this.$t('reports.modals.reset_tracker.reset_success'),
            variant: 'success',
          });

          this.$emit('trackers-updated');
        })
        .catch(() => {
          this.$skToast({
            message: this.$t('reports.modals.reset_tracker.reset_error'),
            variant: 'error',
          });
        });
    },
    handleManualChangeUpdate(userId, value) {
      Vue.set(this.manualChangesPerUser, userId, value);
    },
    handleSelectedEmployees(newSelectedUserIds) {
      this.selectedUserIds = newSelectedUserIds;
    },
    resetData() {
      this.$refs.selectEmployees.resetData();
    },
    sendEvent(subtype, context) {
      try {
        this.$svcEvents.create(subtype, context);
      } catch (error) {
        captureException(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: inline-block;
  margin-bottom: .5rem;
}

.reset-tracker-modal {
  z-index: 10;
}

.reset-tracker-modal__select-employees {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
  padding: 24px 0;
}

.reset-tracker-modal__select-employees-wrapper {
  width: 265px;
}

.reset-tracker-modal__pick-action {
  display: flex;

  .label--clickable {
    margin: 0;
    font-weight: normal;
    cursor: pointer;
  }

  .reset-tracker-modal__option {
    display: flex;
    align-items: center;
  }

  .reset-tracker-modal__second-option {
    margin-left: 40px;
  }
}

.reset-tracker-modal__pick-action__extra-padding {
  padding-bottom: 13px;
}

.reset-tracker-modal__adjust-info {
  display: flex;
  align-items: center;
  margin: 0 0 16px 4px;
}

.reset-tracker-modal__adjust-info-icon {
  margin-right: 8px;
}

.reset-tracker-modal__table {
  table-layout: fixed;
  width: 100%;
  padding: 0 24px;

  tr th {
    text-align: center;
    font-weight: $fw-semi-bold;
  }
}
</style>
