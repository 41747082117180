<template>
  <div class="maintenance">
    <svg
      width="144"
      height="133"
      viewBox="0 0 144 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="content-container__fence"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M22.44 0H11.76V129.22H22.44V0Z"
          fill="#FBC602"
        />
        <path
          style="mix-blend-mode:multiply"
          opacity="0.5"
          d="M22.44 88.32H11.76V93.2H22.44V88.32Z"
          fill="#F8A101"
        />
        <path
          d="M29.35 124.72H4.85999V132.49H29.35V124.72Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M132.22 0H121.54V129.22H132.22V0Z"
          fill="#FBC602"
        />
        <path
          style="mix-blend-mode:multiply"
          opacity="0.5"
          d="M132.22 88.32H121.54V93.2H132.22V88.32Z"
          fill="#FAA201"
        />
        <path
          d="M139.13 124.72H114.64V132.49H139.13V124.72Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M143.99 7.42H0V37.14H143.99V7.42Z"
          fill="#FDBF3F"
        />
        <path
          d="M30.4 36.05H18.54L1.08997 18.6V8.50999H2.85997L30.4 36.05Z"
          fill="white"
        />
        <path
          d="M55.25 36.05H43.39L15.84 8.50999H27.71L55.25 36.05Z"
          fill="white"
        />
        <path
          d="M80.0999 36.05H68.2299L40.6899 8.50999H52.5599L80.0999 36.05Z"
          fill="white"
        />
        <path
          d="M104.95 36.05H93.08L65.54 8.50999H77.41L104.95 36.05Z"
          fill="white"
        />
        <path
          d="M129.8 36.05H117.93L90.39 8.50999H102.25L129.8 36.05Z"
          fill="white"
        />
        <path
          d="M142.9 24.3V36.05H142.78L115.24 8.50999H127.1L142.9 24.3Z"
          fill="white"
        />
        <path
          d="M143.99 58.6H0V88.32H143.99V58.6Z"
          fill="#FDBF3F"
        />
        <path
          d="M30.4 87.23H18.54L1.08997 69.78V59.69H2.85997L30.4 87.23Z"
          fill="white"
        />
        <path
          d="M55.25 87.23H43.39L15.84 59.69H27.71L55.25 87.23Z"
          fill="white"
        />
        <path
          d="M80.0999 87.23H68.2299L40.6899 59.69H52.5599L80.0999 87.23Z"
          fill="white"
        />
        <path
          d="M104.95 87.23H93.08L65.54 59.69H77.41L104.95 87.23Z"
          fill="white"
        />
        <path
          d="M129.8 87.23H117.93L90.39 59.69H102.25L129.8 87.23Z"
          fill="white"
        />
        <path
          d="M142.9 75.48V87.23H142.78L115.24 59.69H127.1L142.9 75.48Z"
          fill="white"
        />
        <path
          style="mix-blend-mode:multiply"
          opacity="0.5"
          d="M22.44 37H11.76V41.88H22.44V37Z"
          fill="#F8A101"
        />
        <path
          style="mix-blend-mode:multiply"
          opacity="0.5"
          d="M132.22 37H121.54V41.88H132.22V37Z"
          fill="#FAA201"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="430.986"
          y1="1164.89"
          x2="429.7"
          y2="1092.19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDA302" />
          <stop
            offset="1"
            stop-color="#FDC801"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3229.28"
          y1="1164.89"
          x2="3227.99"
          y2="1092.19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDA302" />
          <stop
            offset="1"
            stop-color="#FDC801"
          />
        </linearGradient>
        <clipPath id="clip0">
          <rect
            width="143.99"
            height="132.48"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>

<style lang="scss" scoped>
.maintenance {
  text-align: center;
}
</style>
