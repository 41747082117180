import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';

const FETCH_CONFLICTS_URL = {
  template: '/v3/api/plannings/conflicts/template_conflicts',
  previous_week: '/v3/api/plannings/conflicts/previous_planning_conflicts',
};

const initialState = {
  templates: [],
  conflicts: [],
  conflictsLoading: false,
  templatesLoading: false,
  duplicatingPlanning: false,
  // Object containing template ids as keys with boolean as values
  // to indicate delete request completion status
  deleteTemplatePendingForId: {},
  creatingTemplate: false,
  error: null,
  isConflictSidePanelOpen: false,
};

const mutations = {
  fetchTemplatesPending(state) {
    state.templatesLoading = true;
  },
  fetchTemplatesComplete(state) {
    state.templatesLoading = false;
  },
  fetchConflictsPending(state) {
    state.conflictsLoading = true;
  },
  fetchConflictsComplete(state) {
    state.conflictsLoading = false;
  },
  duplicatePlanningPending(state) {
    state.duplicatingPlanning = true;
  },
  duplicatePlanningComplete(state) {
    state.duplicatingPlanning = false;
  },
  deleteTemplatePending(state, templateId) {
    Vue.set(state.deleteTemplatePendingForId, templateId, true);
  },
  deleteTemplateComplete(state, templateId) {
    Vue.set(state.deleteTemplatePendingForId, templateId, false);
  },
  createTemplatePending(state) {
    state.creatingTemplate = true;
  },
  createTemplateComplete(state) {
    state.creatingTemplate = false;
  },
  catchError(state, payload) {
    state.error = payload;
  },
  fetchTemplateSuccess(state, payload) {
    state.templates = payload.data;
  },
  fetchConflictsSuccess(state, conflicts) {
    state.conflicts = conflicts;
  },
  deleteTemplateSuccess(state, deletedTemplateId) {
    state.templates =
      state.templates.filter(template => template.attributes.id !== deletedTemplateId);
  },
  toggleConflictSidePanel(state, isOpen) {
    state.isConflictSidePanelOpen = isOpen;
  },
};

const actions = {
  fetchTemplates({ commit }, params) {
    commit('fetchTemplatesPending');

    const fetchParams = {
      shop_id: params.shopId,
      active_planning: params.activePlanning,
    };

    return httpClient
      .get('/v3/api/plannings/templates', { params: fetchParams })
      .then(response => {
        commit('fetchTemplateSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('fetchTemplatesComplete');
      });
  },

  fetchConflicts({ commit }, params) {
    commit('fetchConflictsPending');

    const url = params.template.id ?
      FETCH_CONFLICTS_URL.template :
      FETCH_CONFLICTS_URL.previous_week;

    return httpClient
      .post(url, params)
      .then(response => {
        commit('fetchConflictsSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.response);
        throw error;
      })
      .finally(() => {
        commit('fetchConflictsComplete');
      });
  },

  duplicatePlanning({ commit, dispatch, rootGetters }, params) {
    commit('duplicatePlanningPending');

    let url;
    if (params.templateId) {
      const { templateId } = params;
      delete params.templateId;
      url = `/v3/api/plannings/templates/${templateId}/apply`;
    } else {
      url = '/v3/api/plannings/shifts/duplicate_from_previous_week_or_day';
    }

    const quarterDataParams = {
      shop_id: params.shop_id,
      starts_at: params.starts_at,
      ends_at: params.ends_at,
    };

    return httpClient
      .post(url, params)
      .then(() => {
        dispatch('planningsShifts/fetchQuarterDataForAlerts', quarterDataParams, { root: true });
        dispatch('planningsAutomaticPlanning/removeLocalStorageLastResults', {
          shopId: params.shop_id,
        }, { root: true });
        dispatch('employeeCounters/fetchUsersHoursCounters', { shopId: params.shop_id }, { root: true });

        if (rootGetters['annualization/isAnnualizationCurrentlyActiveForCurrentShop']) {
          const annualizationParams = {
            shopId: params.shop_id,
            untilPlanningEndDate: true,
            checkUser: true,
          };
          dispatch('annualization/fetchAndComputeAnnualizationData', annualizationParams, { root: true });
        }
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('duplicatePlanningComplete');
      });
  },

  deleteTemplate({ commit }, params) {
    const templateId = params.template_id;

    commit('deleteTemplatePending', templateId);

    return httpClient
      .delete(`/v3/api/plannings/templates/${templateId}`, { params })
      .then(response => {
        commit('deleteTemplateSuccess', templateId);
        return response;
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('deleteTemplateComplete', templateId);
      });
  },

  createTemplate({ commit }, params) {
    commit('createTemplatePending');

    return httpClient.post('/v3/api/plannings/templates', params)
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('createTemplateComplete');
      });
  },
};

const getters = {
  anyDeleteTemplatePending:
    state => Object.values(state.deleteTemplatePendingForId).some(status => status),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
