<template>
  <div
    id="over-hours-plannings"
    class="dashboard-section bottom"
  >
    <div class="dashboard-title">
      <h2>
        {{ isSingleShop ?
          $t('home_dashboard.chart.over_hours.title_single_shop') :
          $t('home_dashboard.chart.over_hours.title_all_shops')
        }}
      </h2>
    </div>
    <div class="dashboard-content">
      <OverHoursEmployeeList
        v-if="isSingleShop"
        @data-loaded="handleReloadDone"
      />
      <OverHoursShopList
        v-else
        @data-loaded="handleReloadDone"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import OverHoursEmployeeList from './OverHours/OverHoursEmployeeList';
import OverHoursShopList from './OverHours/OverHoursShopList';

export default {
  name: 'OverHoursChart',
  components: {
    OverHoursEmployeeList,
    OverHoursShopList,
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),

    isSingleShop() {
      return this.currentShop && this.currentShop.id !== 'all';
    },
  },
  methods: {
    handleReloadDone() {
      this.emitOnRoot('dashboard-v3-data-loaded', 'OverHoursChart');
    },
  },
};
</script>
