import { msHttpClient } from '@skello-utils/clients';
import { svcDocumentsUrl } from '@config/env';

export class SvcDocumentsClient {
  constructor() {
    this.baseUrl = svcDocumentsUrl;

    if (!this.baseUrl) {
      throw new Error('Couln\'t initialize SvcDocumentsClient');
    }
  }

  async fetch(userId, direction = 'desc') {
    const { data } = await msHttpClient.get(`${this.baseUrl}/documents?user_id=${userId}&direction=${direction}`);

    return data;
  }

  async upload(payload) {
    const formData = new FormData();

    formData.append('document[title]', payload.title);
    formData.append('document[creator_id]', payload.creatorId);
    formData.append('document[user_id]', payload.userId);
    formData.append('document[file]', payload.file);
    formData.append('document[filename]', payload.filename);

    const { data } = await msHttpClient.post(
      `${this.baseUrl}/documents?user_id=${payload.userId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return data;
  }

  async download(documentId, userId) {
    const { data } = await msHttpClient.get(
      `${this.baseUrl}/documents/${documentId}/download?user_id=${userId}`,
      { responseType: 'blob' },
    );

    return data;
  }

  async delete(documentId, userId) {
    await msHttpClient.delete(`${this.baseUrl}/documents/${documentId}?user_id=${userId}`);
  }
}
