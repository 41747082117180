<template>
  <img
    width="35"
    src="./images/logo-cegid.jpg"
  >
</template>

<script>
export default {
  name: 'Cegid',
};
</script>
