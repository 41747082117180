<template>
  <CountersCellPopover
    listener-id="counter-week-detail"
    :popover-height="227"
    ref-name="counter_week_detail_wrapper"
  >
    <template #popover-content="{ counters }">
      <div
        v-if="counters"
        class="counters-cell__counter"
      >
        <div class="counters-cell__counter__no_modulation">
          <div class="counters-cell__counter__no_modulation__title">
            {{ $t('plannings.table.cells.counters.no_modulation.working_hours') }}
          </div>
          <div class="counters-cell__counter__no_modulation__value">
            {{ counters.workHours }}
          </div>
        </div>
        <div class="counters-cell__counter__no-modulation--separator" />
        <div class="counters-cell__counter__no_modulation">
          <div>
            <div class="counters-cell__counter__no_modulation__title">
              {{ $t('plannings.table.cells.counters.no_modulation.absence_paid_by_employer.title') }}
            </div>
          </div>
          <div class="counters-cell__counter__no_modulation__value">
            {{ counters.employerAbsHours }}
          </div>
        </div>
        <div class="counters-cell__counter__no-modulation--separator" />
        <div class="counters-cell__counter__no_modulation">
          <div>
            <div class="counters-cell__counter__no_modulation__title">
              {{ $t('plannings.table.cells.counters.no_modulation.absence_paid_by_other.title') }}
            </div>
            <div class="counters-cell__counter__no_modulation__subtitle">
              {{ $t('plannings.table.cells.counters.no_modulation.absence_paid_by_other.subtitle') }}
            </div>
          </div>
          <div class="counters-cell__counter__no_modulation__value">
            {{ counters.otherAbsHours }}
          </div>
        </div>
        <div class="counters-cell__counter__no-modulation--separator" />
        <div
          class="
                counters-cell__counter__no_modulation
                counters-cell__counter__no_modulation--total
              "
        >
          <div class="counters-cell__counter__no_modulation__title">
            {{ $t('plannings.table.cells.counters.no_modulation.total_hours') }}
          </div>
          <div class="counters-cell__counter__no_modulation__value">
            {{ counters.totalHours }}
          </div>
        </div>
      </div>
    </template>
  </CountersCellPopover>
</template>

<script>
import CountersCellPopover from '@app-js/plannings/shared/components/Counter/CountersCellPopover';

export default {
  name: 'CounterWeekDetail',
  components: {
    CountersCellPopover,
  },
};
</script>

<style lang="scss" scoped>
.counters-cell__counter__no_modulation,
.counters-cell__counter__details {
  font-size: $fs-text-s;
  display: flex;
  justify-content: space-between;
}

.counters-cell__counter__no_modulation__subtitle {
  font-size: $fs-text-xs;
  color: $sk-grey;
}

.counters-cell__counter__no_modulation__value {
  display: flex;
  align-items: center;
  color: $sk-black;
}

.counters-cell__counter__no-modulation--separator,
.counters-cell__counter__details--separator {
  border-bottom: 1px solid $sk-grey-10;
  margin: 20px 0;
}

.counters-cell__counter__no-modulation--separator {
  margin: 13px 0;
}

.counters-cell__counter__no_modulation.counters-cell__counter__no_modulation--total {
  font-weight: bold;
}
</style>
