<template>
  <SkSidePanel
    id="conflict-side-panel-v3"
    :class="sidepanelClasses"
    :title="$t('plannings.toolbar.sidepanel.conflict.header.title')"
    @closed="closeConflictsSidePanel"
  >
    <template #body>
      <div class="conflict-section__wrapper">
        <SkCollapse
          v-for="user in usersConflicts"
          :key="user.id"
          :opened-by-default="usersConflicts.length === 1"
        >
          <template slot="labelLeft">
            <div class="conflict-section__user-label">
              <SkMedallion
                :text="userFirstNameInitial(user)"
                background-color="#2b66fe"
                color="white"
              />
              <div class="conflict-section__username">
                {{ fullName(user) }}
              </div>
            </div>
            <div class="conflict-section__label">
              <span>{{ conflictsCount(user) }}</span>
            </div>
          </template>
          <template slot="content">
            <ConflictItem
              v-for="(conflict, index) in user.conflicts"
              :key="index"
              :conflict="conflict"
            />
          </template>
        </SkCollapse>
      </div>
    </template>
  </SkSidePanel>
</template>

<script>
import {
  mapState,
  mapMutations,
} from 'vuex';

import {
  SkSidePanel,
  SkMedallion,
  SkCollapse,
  SIDEPANEL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

import ConflictItem from './ConflictItem';

export default {
  name: 'ConflictSidePanel',
  components: { SkSidePanel, SkMedallion, SkCollapse, ConflictItem },
  computed: {
    ...mapState('planningsTemplates', ['conflicts', 'isConflictSidePanelOpen']),
    sidepanelClasses() {
      return {
        'conflict-manager__side-panel__wrapper': true,
        'conflict-manager__side-panel--opened': this.isConflictSidePanelOpen,
      };
    },
    usersConflicts() {
      // Group conflicts objects by users ids
      const conflictsByUsers = this.conflicts.reduce((accumulator, conflict) => {
        const userId = conflict.planning_shift.user ?
          conflict.planning_shift.user.id :
          'unassigned';
        if (!accumulator[userId]) {
          accumulator[userId] = [];
        }
        accumulator[userId].push(conflict);
        return accumulator;
      }, {});

      // Array containing user infos + conflicts
      const usersConflicts = Object.keys(conflictsByUsers)
        .reduce((accumulator, userId) => {
          if (userId === 'unassigned') return accumulator;
          // Extract user data from first shift
          const planningShift = conflictsByUsers[userId][0].planning_shift;
          accumulator.push({
            id: planningShift.user.id,
            firstName: planningShift.user.attributes.firstName,
            lastName: planningShift.user.attributes.lastName,
            conflicts: this.sortUserConflicts(conflictsByUsers[userId]),
          });
          return accumulator;
        }, [])
        .sort((a, b) => a.firstName.localeCompare(b.firstName));

      if (conflictsByUsers.unassigned) {
        usersConflicts.push({
          unassignedShift: true,
          conflicts: this.sortUserConflicts(conflictsByUsers.unassigned),
        });
      }

      return usersConflicts;
    },
  },
  watch: {
    isConflictSidePanelOpen(newValue) {
      this.handleShowSidePanel(newValue);
    },
  },
  mounted() {
    this.handleShowSidePanel(this.isConflictSidePanelOpen);
  },
  methods: {
    ...mapMutations('planningsTemplates', ['toggleConflictSidePanel']),
    sortUserConflicts(userConflicts) {
      return userConflicts.sort((a, b) => a.planning_shift.starts_at > b.planning_shift.starts_at);
    },
    userFirstNameInitial(user) {
      return user.unassignedShift ?
        this.$t('plannings.toolbar.sidepanel.conflict.conflict_section.unassigned_shift_initial') :
        user.firstName[0].toUpperCase();
    },
    fullName(user) {
      return user.unassignedShift ?
        this.$t('plannings.toolbar.sidepanel.conflict.conflict_section.unassigned_shift') :
        `${user.firstName} ${user.lastName}`;
    },
    conflictsCount(user) {
      const conflictsCount = user.conflicts.length;
      return this.$tc(
        'plannings.toolbar.sidepanel.conflict.conflict_section.conflicts_count',
        conflictsCount,
        { count: conflictsCount },
      );
    },
    closeConflictsSidePanel(event) {
      // Prevent to close sidebar if click outside
      if (event && event.target.className === 'sk-sidepanel__wrapper') return;

      this.toggleConflictSidePanel(false);
    },
    handleShowSidePanel(isOpen) {
      if (isOpen) {
        this.$root.$emit(SIDEPANEL_SHOW_EVENT, 'conflict-side-panel-v3');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conflict-manager__side-panel__wrapper.sk-sidepanel__wrapper {
  z-index: 1051;

  &.conflict-manager__side-panel--opened {
    background: none;
    pointer-events: none;

    /* Fix issue where click on modal would always close sidepanel */
    ::v-deep .sk-sidepanel__container {
      pointer-events: auto;
    }
  }
}

.conflict-section__wrapper {
  padding-bottom: 10px;
  -webkit-font-smoothing: antialiased;

  /* Design specifications for open/close caret */
  ::v-deep .sk-collapse__caret-wrapper {
    svg {
      margin: 0;

      path {
        fill: black;
      }
    }
  }

  .conflict-section__username {
    font-size: $fs-text-m;
    padding-left: 10px;
    color: $sk-black;
  }
}

.conflict-section__label {
  font-size: $fs-text-s;
  color: $sk-error;
  background-color: $sk-error-10;
  padding: 5px 10px;
  border-radius: 15px;
  min-width: 70px;
  display: flex;
  justify-content: center;
}

.conflict-section__user-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 235px;
}

/* Design specifications for line labels */
::v-deep .sk-collapse__label {
  padding: 16px 0 !important;
  margin: 0 auto;
  width: 90%;
}
</style>
