<template>
  <SkModal
    :id="`document-send-email-modal-${document.id}`"
    ref="modal"
    :modal-title="$t('text_document_templates.document_send_email_modal.title')"
    :submit-button-label="$t('text_document_templates.document_send_email_modal.send_email')"
    @hidden="$emit('hidden')"
  >
    <template #title-icon>
      <div class="document-send-email-modal__icon">
        <CheckMarkIcon fill="#fff" />
      </div>
    </template>
    <template
      v-if="document && document.id"
      #body
    >
      <SkModalSection
        class="document-send-email-modal__sender"
        border-bottom="extended"
      >
        <div class="document-send-email-modal__sender__label">
          {{ $t('text_document_templates.document_send_email_modal.send_to') }}
        </div>
        <div class="document-send-email-modal__sender__card">
          <SkMedallion
            :text="employeeInitials(employee)"
            background-color="#2b66fe"
            color="white"
          />
          <div class="document-send-email-modal__sender__text">
            <div>{{ employee.attributes.firstName }} {{ employee.attributes.lastName }}</div>
            <div class="document-send-email-modal__sender__email">
              {{ employee.attributes.email }}
            </div>
          </div>
        </div>
      </SkModalSection>
      <SkModalSection
        class="document-send-email-modal__form"
        border-bottom="none"
      >
        <SkInput
          v-model="subject"
          v-track="trackingOptions.title"
          :label="$t('text_document_templates.document_send_email_modal.subject_label')"
          :placeholder="$t('text_document_templates.document_send_email_modal.subject_placeholder')"
          class="document-send-email-modal__subject"
        />
        <SkTextarea
          v-model="body"
          :placeholder="$t('text_document_templates.document_send_email_modal.body_placeholder')"
          class="document-send-email-modal__body"
          :max-height="120"
        />
        <DocumentCard :document="document" />
      </SkModalSection>
    </template>
    <template #footer>
      <SkOroraButton
        v-track="trackingOptions.cancel"
        variant="secondary"
        @click="handleFooterCancel"
      >
        {{ $t('actions.cancel') }}
      </SkOroraButton>
      <SkOroraButton
        v-track="trackingOptions.submit"
        :disabled="disabledSubmit"
        :loading="loadingSendEmail"
        @click.prevent="handleSendEmailSubmit"
      >
        {{ $t('text_document_templates.document_send_email_modal.send_email') }}
      </SkOroraButton>
    </template>
  </SkModal>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';

import DocumentCard from '../DocumentCard';

export default {
  name: 'DocumentSendEmailModal',
  components: { DocumentCard },
  props: {
    document: {
      type: Object,
      required: true,
    },
    trackingOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      subject: this.document ? this.document.attributes.title : '',
      body: '',
    };
  },
  computed: {
    ...mapState('employeeDocuments', {
      loadingSendEmail: state => state.loadingSendEmail,
    }),
    ...mapState('textDocumentTemplates', {
      selectedTextDocument: state => state.selectedTextDocument,
    }),
    ...mapGetters({
      employeeInitials: 'employees/userInitials',
    }),
    ...mapGetters('employeeDocuments', ['isDocumentV2']),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    employee() {
      if (this.document) {
        return this.document.relationships.user;
      }
      return null;
    },
    disabledSubmit() {
      return !this.body;
    },
  },
  watch: {
    document(document) {
      this.subject = document.attributes.title;
      this.resetBody();
    },
  },
  methods: {
    ...mapActions('employeeDocuments', ['sendEmail', 'sendEmailV2']),
    resetBody() {
      this.body = '';
    },
    handleFooterCancel(event) {
      return this.$refs.modal.handleFooterCancel(event);
    },
    async handleSendEmailSubmit(event) {
      const successMsg = this.$t('text_document_templates.document_send_email_modal.email_sent');
      const errorMsg = this.$t('text_document_templates.document_send_email_modal.email_sent_error');
      const isFromGen = this.document.id === this.selectedTextDocument?.relationships?.document?.id;

      try {
        // This needs cleanup when we have migrated to svcDocV2 and removed the old svcDoc
        if (this.isDevFlagEnabled('FEATUREDEV_FETCH_DOCS_FROM_V2') &&
          this.document &&
          this.isDocumentV2(this.document.id)) {
          // Only when from document generation, as this modal is also used from employee space
          if (isFromGen) {
            this.$store.commit('employeeDocuments/performingRequest', 'loadingSendEmail');

            // Await 5s here to allow the document generation to complete before sending email
            // If it fails, the user can try again or go back to documents
            await new Promise(resolve => setTimeout(async () => {
              await this.handleSendEmailV2(event);
              this.$store.commit('employeeDocuments/requestComplete', 'loadingSendEmail');
              resolve();
            }, 5000));

            // From employee space, no need to wait for document generation, the doc is already existing
          } else {
            await this.handleSendEmailV2(event);
          }
        } else {
          await this.handleSendEmail(event);
        }

        this.resetBody();

        this.$skToast({
          message: successMsg,
          variant: 'success',
          dimension: 'short',
          containerId: 'employees__container',
        });

        // Delay the handleSubmit to allow the toast to be visible before redirect with handleSubmit
        if (isFromGen) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      } catch (error) {
        this.$skToast({
          message: errorMsg,
          variant: 'error',
          dimension: 'short',
          containerId: 'employees__container',
        });
        throw error;
      }

      this.$refs.modal.handleSubmit(event);
    },
    async handleSendEmail(event) {
      const params = {
        subject: this.subject,
        body: this.body,
      };

      await this.sendEmail({
        document: this.document,
        params,
      });
    },
    async handleSendEmailV2(event) {
      const params = {
        subject: this.subject,
        content: this.body,
      };

      await this.sendEmailV2({
        documentId: this.document.id,
        params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.document-send-email-modal__sender {
  padding: 15px 25px;
  font-size: $fs-text-m;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.document-send-email-modal__sender__label {
  color: $sk-black;
}

.document-send-email-modal__sender__card {
  color: $sk-black;
  background: $sk-grey-10;
  border-radius: 100px;
  margin-left: 25px;
  padding: 10px;
  display: flex;
}

.document-send-email-modal__sender__text {
  margin-left: 12px;
  margin-right: 10px;
  align-items: center;
}

.document-send-email-modal__sender__email {
  color: $sk-grey;
  font-size: $fs-text-s;
  line-height: 15px;
}

/* TODO Make a trait for raw wysiwyg with transparent background */
.document-send-email-modal__form {
  .document-send-email-modal__subject.sk-input {
    .sk-input__input {
      background: none;
    }
  }

  .document-send-email-modal__body.sk-textarea {
    margin-top: 10px;
    margin-bottom: 12px;

    .sk-textarea__input {
      background: none;

      &:hover {
        background: none;
      }
    }
  }
}

.document-send-email-modal__icon {
  color: white;
  background: $sk-blue;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
</style>
