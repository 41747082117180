<template>
  <button
    :style="{ backgroundColor: buttonColor }"
    type="button"
    class="modal-btn"
    @click="onPressAction"
  >
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      required: true,
    },
    onPressAction: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-btn {
  height: 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: normal;
  border: none;
  padding: 0 15px;
  margin: 0 5px;
  text-transform: uppercase;
  font-size: 13px;

  &:hover {
    box-shadow: 3px 3px 4px #dbdbdb;
  }
}
</style>
