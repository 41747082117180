<script>
import GenericToolbarAction from './GenericToolbarAction';

export default {
  name: 'ActionsSeparator',
  extends: GenericToolbarAction,
  props: {
    shouldHideOnSmallScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldBeVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    separator() {
      return true;
    },
    hideOnSmallScreen() {
      return this.shouldHideOnSmallScreen;
    },
    isVisible() {
      return this.shouldBeVisible;
    },
  },
  methods: {},
};
</script>
