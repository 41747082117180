<template>
  <div class="kpi-label__container">
    <div class="kpi-label__envelope">
      <input
        v-if="editionMode"
        ref="input"
        v-model="editedLabel"
        type="text"
        tabindex="-1"
        class="kpi-label__input"
        @blur="onBlur"
        @keydown.esc="onEsc"
        @keyup.enter="onEnter"
      >

      <div
        v-else
        :class="editableWrapperClass"
        @click.stop="onEditableWrapperClick"
      >
        <!-- Tooltip only for editable label -->
        <div
          v-if="isEditable"
          v-tooltip.bottom-start="editedLabel"
          :class="editableLabelClass"
        >
          {{ editedLabel }}
        </div>

        <div
          v-else
          v-tooltip.right-end="kpiLabelTooltip"
          :class="kpiLabelTextClasses"
        >
          {{ editedLabel }}
        </div>

        <div
          v-if="isEditable"
          class="kpi-label__edition-svg-wrapper"
        >
          <PencilIcon fill="#727272" />
        </div>
      </div>

      <div
        v-if="labelCollapsable"
        :class="caretClasses"
        @click="onCollapseClick"
      >
        <CaretIcon class="kpi-label__svg" />
      </div>

      <div
        v-if="labelIcon"
        class="kpi-label__info-svg-wrapper"
      >
        <component
          :is="labelIcon"
          v-tooltip="iconTooltip"
          class="kpi-label__svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'KpiLabel',
  props: {
    kpiName: {
      type: String,
      required: true,
    },
    currentShop: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    labelIcon: {
      type: String,
      default: null,
    },
    labelCollapsable: {
      type: Boolean,
      default: false,
    },
    iconLabelTooltip: {
      type: Object,
      required: true,
    },
    collapseState: {
      type: Boolean,
      default: false,
    },
    predicted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editionMode: false,
      originalLabel: this.label,
      editedLabel: this.label,
    };
  },
  computed: {
    ...mapGetters('planningsKpis', [
      'displayedUsersMissingHourlyWage',
      'displayedUsersMissingHourlyWageWithCosts',
    ]),

    caretClasses() {
      return {
        'kpi-label__caret': true,
        'kpi-label__caret--open': this.collapseState,
        'kpi-label__caret--close': !this.collapseState,
      };
    },
    isEditable() {
      return this.kpiName === 'volume';
    },
    editableLabelClass() {
      return {
        'kpi-label__text': true,
        'kpi-label__truncate': this.isEditable,
      };
    },
    editableWrapperClass() {
      return {
        'kpi-label__wrapper': true,
        'kpi-label__editable': this.isEditable,
      };
    },
    iconTooltip() {
      const predictedOrReal = this.predicted ? 'predicted' : 'real';
      return this.iconLabelTooltip[predictedOrReal];
    },
    isMissingHourlyWages() {
      return ['total_salary_mass'].includes(this.kpiName) &&
        this.displayedUsersMissingHourlyWage.length > 0;
    },
    isMissingHourlyWagesWithCosts() {
      return [
        'salary_mass_productive_with_costs',
        'salary_mass_unproductive_with_costs',
        'total_salary_mass_with_costs',
        'salary_mass_ratio',
      ].includes(this.kpiName) &&
        this.displayedUsersMissingHourlyWageWithCosts.length > 0;
    },
    kpiLabelTextClasses() {
      return {
        'kpi-label__text': true,
        'kpi-label__text--missing': this.isMissingHourlyWages || this.isMissingHourlyWagesWithCosts,
        'kpi-label__text__caret': this.labelCollapsable,
      };
    },
    kpiLabelTooltip() {
      if (!this.isMissingHourlyWages && !this.isMissingHourlyWagesWithCosts) return '';

      let tooltipText = this.$t(`kpis.attributes.${this.kpiName}.tooltip.missing`);

      if (this.isMissingHourlyWages) {
        tooltipText += `${ this.displayedUsersMissingHourlyWage.join('<br>') }`;
      } else if (this.isMissingHourlyWagesWithCosts) {
        tooltipText += `${ this.displayedUsersMissingHourlyWageWithCosts.join('<br>') }`;
      }

      return tooltipText;
    },
  },
  methods: {
    ...mapMutations('planningsKpis', ['toggleSalaryMassCollapse']),
    onCollapseClick() {
      this.toggleSalaryMassCollapse();
    },
    onEditableWrapperClick() {
      if (this.isEditable) {
        this.toggleEdition();
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    toggleEdition() {
      this.editionMode = !this.editionMode;
    },
    onEsc(event) {
      this.onEnter(event);
    },
    onEnter(event) {
      event.target.blur();
      event.stopPropagation();
    },
    onBlur(event) {
      this.submit();
    },
    submit() {
      this.toggleEdition();

      if (this.editedLabel !== this.originalLabel) {
        // Actual new value or NULL if empty
        this.saveNewVolumeName(this.editedLabel || null);
      }
    },
    saveNewVolumeName(newName) {
      return httpClient
        .patch(`/v3/api/shops/${this.currentShop.id}`, { shop: { kpi_volume_name: newName } })
        .then(response => {
          let kpiVolumeName = response.data.data.attributes.kpiVolumeName;

          this.currentShop.attributes.kpiVolumeName = kpiVolumeName;
          // If user has removed custom value, set default value
          if (kpiVolumeName === null) {
            kpiVolumeName = this.getDefaultLabel();
          }
          // Set local values to match updated value
          this.originalLabel = kpiVolumeName;
          this.editedLabel = kpiVolumeName;
        });
    },
    getDefaultLabel() {
      return this.$t('kpis.attributes.volume.name');
    },
  },
};
</script>

<style lang="scss" scoped>
.kpi-label__container {
  height: 100%;
  min-width: 160px;
}

.kpi-label__envelope {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.kpi-label__text {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $fs-text-s;
  padding: 5px;

  &__caret {
    max-width: 115px;
  }
}

.kpi-label__text--missing {
  color: $sk-error;
}

.kpi-label__wrapper {
  cursor: default;
  display: flex;
  align-items: center;
}

.kpi-label__editable:hover {
  cursor: pointer;
  border: 1px solid $sk-grey-10;
  border-radius: 2px;
  margin-left: -1px;
}

.kpi-label__input {
  width: 100px;
  height: 24px;
  border: 1px solid $sk-blue;
  border-radius: 2px;
  padding: 0 5px;
  font-size: $fs-text-s;
}

.kpi-label__truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 110px;
}

.kpi-label__edition-svg-wrapper {
  margin-right: 5px;
}

.kpi-label__info-svg-wrapper {
  position: absolute;
  height: 14px;
  width: 14px;
  right: 12px;
}

.kpi-label__svg {
  margin: 0;
}

/* Hide caret when KPI has no data */
.kpis__no-planning-data .kpi-label__caret { display: none; }

.kpi-label__caret {
  cursor: pointer;
  position: absolute;
  right: 35px;
  max-width: 120px;
}

.kpi-label__caret--open {
  transform: rotate(180deg);
  transition: transform .3s;
}

.kpi-label__caret--close {
  transform: rotate(0deg);
  transition: transform .3s;
}
</style>
