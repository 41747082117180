<template>
  <div v-if="isWorkloadPlanBottomPanelOpen">
    <div
      class="workload-plan-bottom-panel__background--wrapper"
      @click="handleClickOutsideThePanel"
    >
      <div
        ref="workloadPlanBottomPanel"
        class="workload-plan-bottom-panel__container"
        :style="containerStyle"
      >
        <div class="workload-plan-bottom-panel__header">
          <div class="workload-plan-bottom-panel__header--title">
            <PlusSignIcon
              width="15"
              height="15"
              fill="#000000"
            />
            <div class="workload-plan-bottom-panel__header--label">
              {{ bottomPanelTitle }}
            </div>
          </div>
          <SkCircleButton
            icon="CrossV2Icon"
            @click="onCancel"
          />
        </div>
        <Content v-model="editedWorkloadPlans" />
        <div class="workload-plan-bottom-panel__cta">
          <SkOroraButton
            variant="secondary"
            @click="onCancel"
          >
            {{ $t('workload_plan.bottom_panel.actions.cancel') }}
          </SkOroraButton>
          <div class="workload-plan-bottom-panel__cta--with-margin">
            <SkOroraButton
              :disabled="isValidateButtonDisabled"
              :loading="isLoading"
              @click="onValidate"
            >
              {{ bottomPanelCtaLabel }}
            </SkOroraButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import skDate from '@skello-utils/dates';
import Content from './Content';

export default {
  name: 'WorkloadPlanBottomPanel',
  components: { Content },
  data() {
    return {
      isWorkloadPlanBottomPanelOpen: false,
      editedWorkloadPlans: {},
      isLoading: false,
      containerHeight: '550px',
    };
  },
  computed: {
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapGetters('planningsWorkloadPlans', ['hasNeedsOnPeriod']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['postes']),

    isValidateButtonDisabled() {
      return !this.hasUnsavedChange;
    },
    hasUnsavedChange() {
      return Object.keys(this.editedWorkloadPlans).length > 0;
    },
    shopId() {
      return this.currentShop.id;
    },
    isAlreadySavedWorkloadPlans() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;
      return this.hasNeedsOnPeriod(openingTime.toISOString(), closingTime.toISOString());
    },
    bottomPanelTitle() {
      const key = this.isAlreadySavedWorkloadPlans ? 'edit_workload_plans' : 'add_workload_plans';
      const formattedDate = skDate(this.currentDate).format('dddd LL');

      return this.$i18n.t(`workload_plan.bottom_panel.title.${key}`, { date: formattedDate });
    },
    bottomPanelCtaLabel() {
      const key = this.isAlreadySavedWorkloadPlans ? 'edit_workload_plans' : 'add_workload_plans';

      return this.$i18n.t(`workload_plan.bottom_panel.actions.submit.${key}`);
    },
    containerStyle() {
      const windowHeight = window.innerHeight;
      const style = {
        maxHeight: this.containerHeight,
        overflowY: 'unset',
      };

      if (windowHeight < 550) {
        style.maxHeight = '250px';
        style.overflowY = 'auto';
      }

      return style;
    },
  },
  mounted() {
    this.listenOnRoot('open-workload-plan-bottom-panel', this.togglePanel);
  },
  methods: {
    ...mapActions('planningsWorkloadPlans', ['upsertWorkloadPlans', 'fetchWorkloadPlans']),
    handleClickOutsideThePanel(event) {
      if (this.$refs.workloadPlanBottomPanel &&
        !this.$refs.workloadPlanBottomPanel.contains(event.target)) {
        this.onCancel();
      }
    },
    togglePanel() {
      this.editedWorkloadPlans = {};
      this.isWorkloadPlanBottomPanelOpen = !this.isWorkloadPlanBottomPanelOpen;
    },
    onValidate() {
      this.saveWorkloadPlans();
    },
    onCancel() {
      if (this.hasUnsavedChange) {
        this.$root.$emit('confirm', event, {
          description: this.$t('warnings.unsaved_changes'),
          onConfirm: this.togglePanel,
        });
      } else {
        this.togglePanel();
      }
    },
    saveWorkloadPlans() {
      this.isLoading = true;
      this.upsertWorkloadPlans(this.editedWorkloadPlans)
        .then(this.handleSuccess)
        .catch(() => this.$skToast({
          message: this.$t('workload_plan.bottom_panel.actions.error'),
          variant: 'error',
        }),
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSuccess() {
      this.$skToast({
        message: this.$t('workload_plan.bottom_panel.actions.success'),
        variant: 'success',
      });

      if (this.isAlreadySavedWorkloadPlans) {
        this.$skAnalytics.track('edited_workload_plan');
      } else {
        this.$skAnalytics.track('added_workload_plan', { source: 'manual' });
      }

      this.refetchWorkloadPlans();
      this.togglePanel();
    },
    refetchWorkloadPlans() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;

      const payload = {
        posteIds: this.postes.map(poste => poste.id),
        startsAt: openingTime.toISOString(),
        endsAt: closingTime.toISOString(),
        shopId: this.shopId,
        closingHoursTime: closingTime.format('HH:mm'),
        openingHoursTime: openingTime.format('HH:mm'),
      };

      this.fetchWorkloadPlans(payload);
    },
  },
};
</script>

<style scoped lang="scss">
@use '@app-js/plannings/pages/Days/css_variables' as *;

.workload-plan-bottom-panel {
  &__background {
    &--wrapper {
      background: rgba(0, 0, 0, .5);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 8;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 100000;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 10px 16px 20px;
    height: 59px;
    border-bottom: 1px solid $sk-grey-10;

    &--label {
      font-weight: $fw-semi-bold;
      line-height: 27px;
      font-size: $fs-heading-xs;
      padding-left: 15px;
    }

    &--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__cta {
    width: calc(100% - $postes-list-width);
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding: 17px 24px 15px 0;
    height: 72px;
    background-color: $sk-white;

    &--with-margin {
      margin-left: 8px;
    }
  }
}
</style>
