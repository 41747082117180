<template>
  <ApexChart
    id="mixed-line-and-column-chart"
    ref="mixed_line_and_column_chart"
    type="line"
    height="160px"
    :width="chartWidth"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MixedLineAndColumnChart',
  props: {
    series: {
      type: Array,
      required: true,
    },
    chartWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      computedDataPointIndex: 0,
    };
  },
  computed: {
    ...mapState('planningsState', ['dayViewPlanningSizeVariables']),

    workloadPlanOffsetX() {
      return -14 * this.dayViewPlanningSizeVariables.pixelPerMinute;
    },
    chartOptions() {
      return {
        grid: {
          padding: {
            bottom: 0,
            right: 0,
            left: -5,
          },
        },
        colors: ['#2b66fe', '#D9E6FF'],
        chart: {
          offsetX: this.workloadPlanOffsetX,
          offsetY: 5,
          parentHeightOffset: 0,
          toolbar: { show: false },
          zoom: { enabled: false },
          events: {
            mouseMove: this.handleMouseMove,
          },
        },
        legend: { show: false },
        dataLabels: {
          background: { enabled: false },
          enabled: true,
          enabledOnSeries: [1],
          formatter: value => {
            if (!value || value < 0) {
              return '';
            }
            return value.toFixed(0);
          },
          style: {
            colors: ['#000000'],
            fontSize: '9px',
            fontFamily: 'Gellix',
            fontWeight: 700,
          },
        },
        xaxis: {
          type: 'datetime',
          floating: true,
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false },
          tooltip: { enabled: false },
          crosshairs: { show: false },
        },
        markers: {
          size: 0,
          hover: { size: 0 },
        },
        stroke: {
          curve: 'stepline',
          // width of the blue curve
          width: 2.5,
        },
        yaxis: {
          // Set the max to 5 if no values
          max: value => (value ? Math.max(value, 5) : 5),
          min: 0,
          forceNiceScale: true,
          // Always 5 ticks
          tickAmount: 5,
          floating: true,
          labels: {
            style: {
              colors: ['#727272'],
              fontSize: '9px',
              fontFamily: 'Gellix',
            },
            // Slide the Y labels on the chart
            offsetX: -this.workloadPlanOffsetX + 15,
            offsetY: 15,
            formatter: value => value?.toFixed(0),
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          marker: { show: false },
          custom: this.customTooltip,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            borderRadiusApplication: 'end',
            // Width of the bar => 5% white on each sides
            columnWidth: '90%',
            dataLabels: {
              position: 'bottom',
            },
            endingShape: 'rounded',
          },
        },
        // No hover or curve selection
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
  watch: {
    chartWidth() {
      // ApexCharts needs to be refreshed slowly in order for the graph
      // to have time to redraw properly
      setTimeout(() => {
        this.$refs.mixed_line_and_column_chart.refresh();
      }, 200);
    },
  },
  methods: {
    customTooltip({ series }) {
      this.$emit('data-event', { series, dataPointIndex: this.computedDataPointIndex });
      return null;
    },
    /*
    * In this chart, we have 2 series, with a datetime type for the axis.
    * Apex's tooltip is not meant to handle 2 series with different X axis.
    * To be able to have the right data on the tooltip depending on the X axis,
    * We must compute it.
    *
    * This function is a hook, for mouseEvent:
    * event.offsetX : Cursor's position containing the offset
    */
    handleMouseMove(event) {
      // workloadPlanOffsetX is the x offset we set in the css to move the chart
      const xPosition = Math.trunc((
        (event.offsetX + this.workloadPlanOffsetX) /
        this.dayViewPlanningSizeVariables.pixelPerQuarterHours
      ));

      if (xPosition !== this.computedDataPointIndex) {
        this.computedDataPointIndex = xPosition;
      }
    },
  },
};
</script>
