<template>
  <div :class="workloadPlanPanelClasses">
    <div class="workload-plan-panel__left-panel cell--shadow cell--shadow-right">
      <WorkloadPlanLeftPanel
        :poste-id="selectedPosteId"
        :postes="orderedPostes"
        :is-empty-state-displayed="isEmptyStateDisplayed"
        :is-workload-plan-panel-open="showWorkloadPlanPanel"
        :is-workload-plan-enabled="isWorkloadPlanEnabled"
        :is-workload-plans-loading="isWorkloadPlansLoading"
        @on-poste-change="handlePosteChange"
        @toggle-panel="toggleWorkloadPlanPanel"
      />
    </div>
    <WorkloadPlanContentPanel
      :poste-id="selectedPosteId"
      :postes="orderedPostes"
      :show-workload-plan-panel="showWorkloadPlanPanel"
      :planning-shifts-for-all-postes="planningShiftsForAllPostes"
      :planning-shifts-for-single-poste="planningShiftsForSinglePoste"
      :is-empty-state-displayed="isEmptyStateDisplayed"
      :is-workload-plans-loading="isWorkloadPlansLoading"
    />
    <div class="workload-plan-panel__right-panel cell--shadow cell--shadow-left">
      <WorkloadPlanRightPanel
        :is-workload-plan-panel-open="showWorkloadPlanPanel"
        :postes="orderedPostes"
        :poste-id="selectedPosteId"
        :is-workload-plans-loading="isWorkloadPlansLoading"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import WorkloadPlanLeftPanel from './WorkloadPlanLeftPanel';
import WorkloadPlanContentPanel from './WorkloadPlanContentPanel';
import WorkloadPlanRightPanel from './WorkloadPlanRightPanel';

export default {
  name: 'WorkloadPlanPanel',
  components: {
    WorkloadPlanLeftPanel,
    WorkloadPlanContentPanel,
    WorkloadPlanRightPanel,
  },
  data() {
    return {
      isWorkloadPlanPanelOpen: true,
      currentPosteId: null,
      isEmptyStateDisplayed: false,
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('planningsPostes', ['postes']),
    ...mapState('planningsWorkloadPlans', ['workloadPlansByPostes', 'isWorkloadPlansLoading']),
    ...mapGetters('currentLicense', ['canEditManagementIndicators']),
    ...mapGetters('planningsWorkloadPlans', ['hasNeedsOnPeriod']),
    ...mapGetters('currentShop', ['currentShopOpeningAndClosingTime']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    ...mapGetters('planningsUsers', ['filteredUsers']),
    ...mapGetters('planningsWorkloadPlans', [
      'localStorageSaveSelectedPosteKey',
      'localStorageSaveOpenPanelKey',
    ]),
    ...mapGetters('planningsState', ['currentDate']),
    ...mapGetters('planningsShifts', [
      'shiftsForCurrentWeek',
      'dayCellShifts',
    ]),

    selectedPosteId: {
      get() {
        return this.currentPosteId;
      },
      set(newPosteId) {
        // If the value is not changing. Need to whitelist "all" in case the shop has changed
        if (newPosteId !== 'all' && this.currentPosteId === newPosteId) return;

        let valueToSet;
        // If the value is in the postes list, otherwise we fallback on 'all'
        if (this.orderedPostes.find(poste => poste.id === newPosteId)) {
          valueToSet = newPosteId;
        } else {
          valueToSet = this.orderedPostes.length > 1 ? 'all' : this.orderedPostes.at(0)?.id;
        }

        this.currentPosteId = valueToSet;
        localStorage.setItem(
          this.localStorageSaveSelectedPosteKey,
          valueToSet,
        );
      },
    },
    orderedPostes() {
      // we make a copy to avoid updating a computed in another computed
      // which leads to undesired side effects.
      return [...this.postes].sort((posteA, posteB) => (
        posteA.attributes.name.toLowerCase()
          .localeCompare(posteB.attributes.name.toLowerCase())
      ));
    },
    workloadPlanPanelClasses() {
      return {
        'workload-plan-panel__wrapper': true,
        'workload-plan-panel__wrapper--open': this.showWorkloadPlanPanel,
      };
    },
    isWorkloadPlanEnabled() {
      return this.checkPackOfferFlag('workload_plan_enabled');
    },
    showWorkloadPlanPanel() {
      return this.isWorkloadPlanEnabled && this.isWorkloadPlanPanelOpen;
    },
    filteredUserIds() {
      return this.filteredUsers.map(user => user.id);
    },
    /*
      Returns an array of shifts displayed on the planning where the poste matches the selected one
      (taking into account filters if there are any)
     */
    planningShiftsForSinglePoste() {
      return this.planningShiftsForAllPostes.filter(
        shift => shift.relationships.poste.id === this.selectedPosteId,
      );
    },
    /*
      Returns an array of all the shifts displayed on the planning
      (taking into account filters if there are any) no matter the poste.
     */
    planningShiftsForAllPostes() {
      return this.shiftsForDay.filter(shift => (
        shift.attributes.userId &&
        this.filteredUserIds.includes(String(shift.attributes.userId)) &&
        !shift.relationships.poste.attributes.absenceKey &&
        String(shift.attributes.shopId) === this.currentShop.id
      ));
    },
    shiftsForDay() {
      return this.dayCellShifts(this.currentDate, this.shiftsForCurrentWeek);
    },
  },
  watch: {
    workloadPlansByPostes() {
      this.setPosteUserPreference();
      this.setEmptyState();
    },
    planningShiftsForAllPostes() {
      this.setEmptyState();
    },
    $route() {
      this.isEmptyStateDisplayed = false;
    },
  },
  mounted() {
    this.listenOnRoot('workload-plan-panel-updated', this.setOpenPanelPreference);

    this.setOpenPanelPreference();
    this.setPosteUserPreference();
  },
  methods: {
    setPosteUserPreference() {
      const posteIdUserPreference = localStorage.getItem(this.localStorageSaveSelectedPosteKey);
      this.selectedPosteId = posteIdUserPreference || 'all';
    },
    setOpenPanelPreference() {
      const displayWorkloadPlanPanel = localStorage.getItem(this.localStorageSaveOpenPanelKey);
      this.isWorkloadPlanPanelOpen = displayWorkloadPlanPanel !== 'false';
    },
    handlePosteChange(newPosteId) {
      this.selectedPosteId = newPosteId;
    },
    toggleWorkloadPlanPanel() {
      this.isWorkloadPlanPanelOpen = !this.isWorkloadPlanPanelOpen;
      this.setEmptyState();
      localStorage.setItem(this.localStorageSaveOpenPanelKey, this.isWorkloadPlanPanelOpen);
    },
    setEmptyState() {
      const { openingTime, closingTime } = this.currentShopOpeningAndClosingTime;

      this.isEmptyStateDisplayed = this.showWorkloadPlanPanel && this.canEditManagementIndicators &&
        !this.hasNeedsOnPeriod(
          openingTime.toISOString(),
          closingTime.toISOString(),
        ) && !this.planningShiftsForAllPostes.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.workload-plan-panel__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $sk-grey-10;

  &--open {
    min-height: 161px;
  }

  .cell--shadow {
    position: relative;
    z-index: 1;
  }
}

.workload-plan-panel {
  &__left-panel {
    flex: 0 0 160px;
    max-width: 160px;
  }

  &__right-panel {
    flex: 0 0 146px;
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
