<template>
  <SkModalSection border-bottom="none">
    <div class="add-employee-modal_contract-section-header">
      <h3> {{ $t('employees.add_employee_modal.contract_data.title') }} </h3>
    </div>
    <div class="row sk-form__row">
      <div class="col-6 sk-form__col">
        <SkSelect
          v-model="primaryShop"
          :ajax-client="ajaxClient"
          :ajax-format-params="shopNodesAjaxFormatParams"
          :ajax-format-response="ajaxFormatResponse"
          :disabled-options="disabledOptions"
          :label="$t('employees.add_employee_modal.contract_data.fields.affiliated_shop.label')"
          :search-placeholder="$t('labels.search')"
          width="356px"
          ajax-url="/v3/api/cluster_nodes"
          append-to-body
          paginated
          data-test="add-employee-primary_shop"
        >
          <template #empty-option>
            {{ $t('employees.add_employee_modal.affiliations.no_search_results') }}
          </template>

          <template #selected-option="slotProps">
            <div class="add-employee-modal__affiliated-shop__title">
              {{ slotProps.value.text }}
            </div>
          </template>

          <template #option="slotProps">
            <div
              v-tooltip.right-start="nodeHoverTooltip(slotProps.option)"
              class="add-employee-modal__affiliated-shop__title"
            >
              <div class="add-employee-modal__affiliated-shop__list-checked-icon">
                <CheckMarkIcon
                  v-if="selectedShop(slotProps.option)"
                  fill=""
                  class="check-mark-icon"
                />
              </div>
              <SkMedallion
                icon="ShopIcon"
                background-color="#d9e6ff"
                class="add-employee-modal__affiliated-shop__title__icon"
                color="#2b66fe"
              />
              <span>{{ slotProps.option.text }}</span>
            </div>
          </template>
        </SkSelect>

        <div class="add-employee-modal_field__explanation">
          {{ $t('employees.add_employee_modal.contract_data.fields.affiliated_shop.explanation') }}
        </div>
      </div>
      <div class="col-6 sk-form__col">
        <SkSelectV2
          v-model="contractTypeId"
          :options="contractTypesSelect"
          :label="$t('employees.add_employee_modal.contract_data.fields.contract_title')"
          :no-results-label="$t('search_bar.no_result')"
          class="add-employee-modal__contract-type-selector"
          data-test="add-employee-contract_type"
        />
      </div>
    </div>
    <div
      v-if="!interimContractTypeSelected"
      class="row sk-form__row"
    >
      <div class="col-6 sk-form__col">
        <SkSelectV2
          v-model="counterType"
          data-test="add-employee-counter_type"
          :options="counterTypes"
          :label="$t('employees.add_employee_modal.contract_data.fields.counter_type')"
        />
      </div>
      <div class="col-6 sk-form__col add-employee-modal__contract-hours">
        <!-- eslint-disable  max-len -->
        <template v-if="dayRateCounterTypeSelected">
          <SkInputGroup
            :errored="!isWorkDaysValid"
            :error-message="$t('employees.add_employee_modal.contract_data.fields.daily_contract.error_message')"
          >
            <SkInput
              v-model="workDays"
              :label="$t('employees.add_employee_modal.contract_data.fields.daily_contract.label')"
              name="employee[work_days]"
              data-test="add-employee-work_days"
              type="number"
              min="0"
            />
            <template #append>
              {{ $t('employees.add_employee_modal.contract_data.fields.daily_contract.unit') }}
            </template>
          </SkInputGroup>
          <div :class="divClasses">
            {{ $t('employees.add_employee_modal.contract_data.fields.daily_contract.explanation') }}
          </div>
        </template>
        <template v-else>
          <SkInputGroup
            :errored="!isContractHoursValid"
            :error-message="$t('employees.add_employee_modal.contract_data.fields.contract_hours.error_message')"
          >
            <SkInput
              v-model="contractHours"
              :label="$t('employees.add_employee_modal.contract_data.fields.contract_hours.label')"
              name="employee[contract_hours]"
              data-test="add-employee-contract_hours"
              type="number"
              min="0"
            />
            <template #append>
              {{ $t('employees.add_employee_modal.contract_data.fields.contract_hours.unit') }}
            </template>
          </SkInputGroup>
          <div :class="divClasses">
            {{ $t('employees.add_employee_modal.contract_data.fields.contract_hours.explanation') }}
          </div>
        </template>
        <!-- eslint-enable  max-len -->
      </div>
    </div>
  </SkModalSection>
</template>

<script>
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import {
  mapMutations,
  mapState,
} from 'vuex';

import { arrayToSelectOptions } from '@skello-utils/form';
import {
  getCountryForContractTypes,
  getContractTypeKeyText,
} from '@app-js/users/shared/utils/contract_types_helper';
import { httpClient } from '@skello-utils/clients';

export default {
  name: 'ContractDataSection',
  data() {
    return {
      ajaxClient: () => httpClient,
      contractTypesSelect: [],
      counterTypes: [],
      shopNodes: [],
      cancelledShopNodeIds: [],
    };
  },
  computed: {
    ...mapState('newEmployee', ['newEmployee']),
    ...mapState('currentUser', ['organisationLicenses']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('config', ['config']),
    ...mapState('config', {
      contractData: state => state.config.contract_data,
      dailyCounter: state => state.config.contract_data.counter_type_daily,
    }),
    ...mapState('newEmployee', { contract: state => state.newEmployee.relationships.contract }),
    dayRateCounterTypeSelected() {
      return this.contract.attributes.counterType === this.dailyCounter;
    },
    interimContractTypeSelected() {
      if (!this.contract.attributes.contractTypeId) return false;

      return this.config.contract_data.contract_types
        .find(ct => ct.id === this.contract.attributes.contractTypeId)
        .category === 'interim';
    },
    disabledOptions() {
      return this.cancelledShopNodeIds.concat(this.primaryShopNodeId);
    },
    primaryShopNodeId() {
      return this.shopNodes.filter(node => node.attributes.shopId === String(this.primaryShop.id))
        .map(node => node.id);
    },
    primaryShop: {
      get() {
        this.filterContractTypes(this.newEmployee.relationships.shop.attributes.country);

        return {
          id: this.newEmployee.attributes.shopId,
          text: this.newEmployee.relationships.shop.attributes.name,
          country: this.newEmployee.relationships.shop.attributes.country,
        };
      },

      set(newShopNode) {
        const standardEmployeeLicense =
          orderBy(this.organisationLicenses, 'attributes.position').slice(-1)[0];

        this.setPrimaryNode({ clusterNode: newShopNode, standardEmployeeLicense });
      },
    },
    contractTypeId: {
      get() {
        return this.contract.attributes.contractTypeId;
      },

      set(value) {
        this.setContractAttributes({ contractTypeId: value });
      },
    },
    counterType: {
      get() {
        return this.contract.attributes.counterType;
      },

      set(value) {
        this.setContractAttributes({ counterType: value });
      },
    },
    contractHours: {
      get() {
        return this.contract.attributes.contractHours;
      },
      set(newContractHours) {
        this.setContractAttributes({ contractHours: newContractHours });
      },
    },
    workDays: {
      get() {
        return this.contract.attributes.workDays;
      },
      set(newWorkDays) {
        this.setContractAttributes({ workDays: newWorkDays });
      },
    },
    isContractHoursValid() {
      return this.contract.attributes.contractHours === null ||
        this.contract.attributes.contractHours >= 0;
    },
    isWorkDaysValid() {
      return this.contract.attributes.workDays === null ||
        this.contract.attributes.workDays >= 0;
    },
    divClasses() {
      return {
        'add-employee-modal_field__explanation': true,
        'add-employee-modal_field__explanation--errored': this.dayRateCounterTypeSelected ? !this.isWorkDaysValid : !this.isContractHoursValid,
      };
    },
  },
  watch: {
    workDays(newValue) {
      if (this.dayRateCounterTypeSelected) {
        this.checkWorkingDays(newValue);
      }
    },
    counterType(newValue) {
      if (newValue.text === this.dailyCounter) {
        this.checkWorkingDays(this.contract.attributes.workDays);
      }
    },
  },
  mounted() {
    this.counterTypes = arrayToSelectOptions(
      this.contractData.counter_types_collection,
      value => this.$t(`employees.tabs.contract_and_salary.salary_data.counters.fields.contract_type_options.${value}`),
    );
  },
  methods: {
    ...mapMutations('newEmployee', ['setContractAttributes', 'setPrimaryNode']),
    selectedShop(option) {
      return option.attributes.shopId === String(this.newEmployee.attributes.shopId);
    },
    shopNodesAjaxFormatParams(params) {
      return {
        can_create_employee: true,
        shop_level: true,
        configured: true,
        ...params,
      };
    },
    ajaxFormatResponse(response) {
      this.shopNodes = response.data.map(clusterNode => ({
        id: clusterNode.id,
        text: clusterNode.attributes.name,
        attributes: {
          name: clusterNode.attributes.name,
          shopId: clusterNode.attributes.shopId ? String(clusterNode.attributes.shopId) : null,
          shopCancelled: clusterNode.attributes.shopCancelled,
          organisationId: String(clusterNode.attributes.organisationId),
          parentId: String(clusterNode.attributes.parentId),
          depth: clusterNode.attributes.depth,
          ancestry: clusterNode.attributes.ancestry,
          editable: clusterNode.attributes.editable,
          ancestorIds: clusterNode.attributes.ancestorIds,
          descendantIds: clusterNode.attributes.descendantIds,
          automaticPlanningUses: clusterNode.attributes.automaticPlanningUses,
          automaticPlanningQuota: clusterNode.attributes.automaticPlanningQuota,
          country: clusterNode.attributes.country,
        },
      }));

      this.cancelledShopNodeIds = [
        ...this.cancelledShopNodeIds,
        ...this.shopNodes.filter(node => node.attributes.shopCancelled).map(node => node.id),
      ];

      return this.shopNodes;
    },
    checkWorkingDays: debounce(function checkWorkingDays(workDays) {
      if (workDays > 218) {
        this.$skToast({
          message: this.$t('employees.tabs.contract_and_salary.salary_data.annual_worked_days.max_days_warning'),
          variant: 'information',
        });
      }
    }, 700),
    nodeHoverTooltip(node) {
      if (node.attributes.shopCancelled) {
        return this.$t('organisation_settings.shop_cancelled');
      }
      return null;
    },
    filterContractTypes(shopCountry) {
      const country = getCountryForContractTypes(shopCountry.toLowerCase()).toUpperCase();

      this.contractTypesSelect = this.config.contract_data.contract_types
        .filter(ct => ct.country === country)
        .map(ct => ({ id: ct.id, text: getContractTypeKeyText(ct.country, ct.key) }))
        .sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
    },
  },
};
</script>

<style lang="scss">
.add-employee-modal__profil-section-header {
  display: flex;
  justify-content: space-between;
}

.add-employee-modal__affiliated-shop__title {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.add-employee-modal__affiliated-shop__title__icon {
  margin-right: 15px;
}

.add-employee-modal__affiliated-shop__list-checked-icon {
  width: 35px;
}

.add-employee-modal__contract-type-selector {
  width: 356px;
}

.add-employee-modal__contract-hours {
  .sk-input {
    width: 250px !important;
  }
}

.add-employee-modal_field__explanation {
  font-size: $fs-text-s;
  color: $sk-grey;
  margin-left: 10px;
}

.add-employee-modal_field__explanation--errored {
  margin-top: 15px;
}

.check-mark-icon {
  fill: $sk-blue;
  margin-right: 15px;
}
</style>
