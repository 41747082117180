<template>
  <div>
    <div
      v-if="isPlanningDataCompleted"
      id="bodyContentWrapper"
      class="attendance-sheets-esignature-panel__body"
    >
      <SkTag
        class="attendance-sheets-esignature-panel__count-tag"
        variant="green"
      >
        <div class="attendance-sheets-esignature-panel__count-tag__inner">
          {{
            $tc(
              'attendance_sheets_esignature.follow_up_step.tag.text',
              signedUsersCount(),
              { signed_count: signedUsersCount(), total_count: computedUsers.length }
            )
          }}
          <CircledQuestionMarkIcon
            v-tooltip.right="tagTooltip"
            fill="#5fb96e"
            class="attendance-sheets-esignature-panel__count-tag__inner__icon"
          />
        </div>
      </SkTag>
      <SkTable
        :columns="header"
        @sort="handleSort"
      >
        <UserRow
          v-for="user in computedUsers"
          :key="user.id"
          :user="user"
        />
      </SkTable>
    </div>
    <!-- Planning data is loading -->
    <LoaderContainer
      v-else
      :is-side-panel="true"
    />
    <div :class="footerClasses">
      <SkOroraButton
        variant="secondary"
        data-test="es-tracking-close"
        @click="handleClose"
      >
        {{ $t('actions.close') }}
      </SkOroraButton>
      <div
        v-tooltip.left="submitTooltip"
        class="attendance-sheets-esignature-panel-footer__submit-wrapper"
      >
        <SkOroraButton
          v-track="'esignature_download_all_timesheets'"
          data-test="es-tracking-download"
          :disabled="!isPlanningDataCompleted || !canDownloadAll"
          @click="downloadAll"
        >
          {{ $t('attendance_sheets_esignature.follow_up_step.actions.submit') }}
        </SkOroraButton>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import { capitalize } from '@skello-utils/formatting/strings';
import LoaderContainer from '@app-js/plannings/shared/components/LoaderContainer';
import {
  mapState, mapGetters,
} from 'vuex';
import { httpClient } from '@skello-utils/clients';
import UserRow from './UserRow';

export default {
  name: 'FollowUpStep',
  components: {
    UserRow,
    LoaderContainer,
  },
  props: {
    isPlanningDataCompleted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFullyScrolledBottom: false,
      sortConfig: {
        column: '',
        direction: '',
      },
    };
  },
  computed: {
    ...mapState('currentShop', ['currentShop']),
    ...mapState('currentUser', ['currentUser']),
    ...mapState('planningsState', ['weeklyAttendanceSheetSignatures']),
    ...mapState('planningsUsers', { planningUsers: 'users' }),
    ...mapGetters('currentShop', ['isDevFlagEnabled']),
    ...mapGetters('planningsState', [
      'monday',
    ]),
    canDownloadAll() {
      return this.isDevFlagEnabled('FEATUREDEV_DOWNLOAD_SIGNATURES_BUTTON') &&
             this.signedUsersCount() > 0 &&
             !!this.currentUser.attributes.email;
    },
    header() {
      const employeeTitle = this.$t('attendance_sheets_esignature.follow_up_step.header.employee');
      const statusTitle = this.$t('attendance_sheets_esignature.follow_up_step.header.status');
      const actionTitle = this.$t('attendance_sheets_esignature.follow_up_step.header.action');

      return [
        {
          title: employeeTitle,
          name: 'lastName',
          sort: true,
        },
        {
          title: statusTitle,
          name: 'signatureStatus',
          sort: true,
          defaultSort: 'asc',
        },
        {
          title: actionTitle,
        },
      ];
    },
    submitTooltip() {
      let tooltipMsg;
      if (this.isDevFlagEnabled('FEATUREDEV_DOWNLOAD_SIGNATURES_BUTTON')) {
        tooltipMsg = this.currentUser.attributes.email ?
          this.$t('attendance_sheets_esignature.follow_up_step.actions.download_signatures_tooltip') :
          this.$t('attendance_sheets_esignature.follow_up_step.actions.download_signatures_tooltip_no_email');
      } else {
        tooltipMsg = this.$t('attendance_sheets_esignature.follow_up_step.actions.submit_tooltip');
      }
      return tooltipMsg;
    },
    tagTooltip() {
      return this.$t('attendance_sheets_esignature.follow_up_step.tag.tooltip');
    },
    footerClasses() {
      return {
        'attendance-sheets-esignature-panel-footer': true,
        'attendance-sheets-esignature-panel-footer--on-scroll': !this.isFullyScrolledBottom,
      };
    },
    employeesContainer() {
      return document.getElementById('bodyContentWrapper');
    },
    computedUsers() {
      if (!this.weeklyAttendanceSheetSignatures || !this.planningUsers) {
        return [];
      }

      let mappedUsers = this.weeklyAttendanceSheetSignatures.map(weeklyAttendanceSheetSignature => {
        const user = this.planningUsers
          .find(currentUser => currentUser.id === weeklyAttendanceSheetSignature.employeeId);

        if (!user) return null;

        return {
          id: user.id,
          attributes: {
            lastName: capitalize(user.attributes.lastName),
            firstName: capitalize(user.attributes.firstName),
            signatureStatus: weeklyAttendanceSheetSignature.signatureStatus,
            signatureRequestId: weeklyAttendanceSheetSignature.yousignSignatureRequestId,
            externalId: this.buildOriginalYousignExternalId(weeklyAttendanceSheetSignature),
            email: user.attributes.email,
          },
          attendanceSheet: {
            shopId: weeklyAttendanceSheetSignature.shopId,
            date: weeklyAttendanceSheetSignature.date,
          },
        };
      }).filter(Boolean);

      mappedUsers = orderBy(mappedUsers, ['attributes.lastName', 'attributes.signatureStatus'], ['asc', 'asc']);

      if (this.sortConfig.column) {
        mappedUsers = orderBy(mappedUsers, [`attributes.${this.sortConfig.column}`], [this.sortConfig.direction]);
      }

      return mappedUsers;
    },
  },
  created() {
    this.toggleFullyScrolled = debounce(this.toggleFullyScrolled.bind(this), 10);
  },
  mounted() {
    if (this.employeesContainer) {
      this.employeesContainer.addEventListener('scroll', this.toggleFullyScrolled);
    }

    this.toggleFullyScrolled();
  },
  destroyed() {
    this.employeesContainer.removeEventListener('scroll', this.toggleFullyScrolled);
  },
  methods: {
    buildOriginalYousignExternalId(signature) {
      return `${signature.PK}+${parseFloat(signature.SK)}`.replaceAll('#', '-');
    },
    downloadAll() {
      const signatures = this.signedUsers().map(
        signedUser => ({
          signature_request_id: signedUser.attributes.signatureRequestId,
          last_name: signedUser.attributes.lastName,
          external_id: signedUser.attributes.externalId,
          user_id: signedUser.id,
        }),
      );
      httpClient
        .post(
          '/v3/api/request_esignatures/download_week_signed_documents',
          { signatures, shop_id: this.currentShop.id, date: this.monday })
        .then(() => {
          this.handleClose();
          this.$skToast({
            message: this.$t('attendance_sheets_esignature.follow_up_step.status.download_success'),
            variant: 'success',
          });
        })
        .catch(error => {
          this.$skToast({
            message: this.$t('attendance_sheets_esignature.follow_up_step.status.error'),
            variant: 'error',
          });
          throw error;
        });
    },
    toggleFullyScrolled() {
      const el = this.employeesContainer;
      if (!el) return;

      this.isFullyScrolledBottom = el.scrollHeight === el.clientHeight + el.scrollTop;
    },
    handleSort(event, columnName, direction) {
      this.sortConfig = {
        column: columnName,
        direction,
      };
    },
    signedUsers() {
      return this.computedUsers.filter(
        user => user.attributes.signatureStatus === 'signed',
      );
    },
    signedUsersCount() {
      return this.signedUsers().length;
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.attendance-sheets-esignature-panel__body {
  min-height: calc(100vh - 244px);
  height: 100px;
  padding: 0 24px;
  overflow-x: hidden;
  overflow-y: auto;

  ::v-deep th {
    .table-column__label--sortable {
      margin-left: 0;
    }
  }
}

.attendance-sheets-esignature-panel__count-tag {
  padding: 0 !important; // Overriding default padding
  margin-top: 24px;
  margin-bottom: 20px;
  width: 125px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fs-text-m;
  font-weight: 700;
}

.attendance-sheets-esignature-panel__count-tag__inner {
  display: flex;
  align-items: center;
}

.attendance-sheets-esignature-panel__count-tag__inner__icon {
  margin-left: 10px;
}

.attendance-sheets-esignature-panel-footer {
  position: absolute;
  bottom: 0;
  background: white;
  width: 412px;
  padding: 30px 24px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.attendance-sheets-esignature-panel-footer--on-scroll {
  box-shadow: 0 -6px 12px rgba(0, 0, 0, .1);
}

.attendance-sheets-esignature-panel-footer__submit-wrapper {
  margin-left: 10px;
}
</style>
