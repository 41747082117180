<template>
  <div class="planning__toolbar-actions-bar">
    <ToolbarActionItems
      ref="toolbarActionItems"
      :is-data-loading="isLoadingInitialData"
    />
    <ToolbarDropdownActionItems
      v-if="isDropdownDisplayed"
      :is-data-loading="isLoadingInitialData"
    />
    <template v-if="isPublishDisplayed">
      <div class="planning__toolbar__vertical-border" />
      <PublishPlanningAction :is-data-loading="isLoadingInitialDataWithOptions" />
    </template>

    <MountingPortal
      v-if="!isLoadingInitialData"
      mount-to="#modals-portal"
      append
    >
      <CreateFromTemplateModal />
      <EraseShiftModal />
      <PrintModal />
      <SortModal />
      <CreateTemplateModal />
      <DisplayOptionsModal />
      <IntermediateValidatePeriodModal />
      <EsignatureUpsellModal />
      <RequestEsignatureUpsellModal />
      <UploadWorkloadPlanModal
        v-if="isWorkloadPlanEnabled"
        @redirect-to-conflicts="redirectToConflictsModal"
      />
      <DownloadWorkloadPlanModal v-if="isWorkloadPlanEnabled" />
      <DuplicateWorkloadPlanModal
        v-if="isWorkloadPlanEnabled"
        @redirect-to-conflicts="redirectToConflictsModal"
      />
      <ConflictWorkloadPlanModal v-if="isWorkloadPlanEnabled" />
      <ConflictWorkloadPlanSidePanel v-if="isWorkloadPlanEnabled" />
    </MountingPortal>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters, mapActions,
} from 'vuex';
import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';

import PublishPlanningAction from './Actions/PublishPlanningAction';
import ToolbarDropdownActionItems from './Actions/ToolbarDropdownActionItems';
import ToolbarActionItems from './Actions/ToolbarActionItems';
import CreateFromTemplateModal from './CreateFromTemplateModal';
import CreateTemplateModal from './CreateTemplateModal';
import DisplayOptionsModal from './DisplayOptionsModal';
import EraseShiftModal from './EraseShiftModal';
import PrintModal from './PrintModal';
import EsignatureUpsellModal from './EsignatureUpsellModal';
import RequestEsignatureUpsellModal from './RequestEsignatureUpsellModal';
import SortModal from './SortModal';
import IntermediateValidatePeriodModal from './IntermediateValidatePeriodModal';
import ConflictWorkloadPlanSidePanel from './WorkloadPlan/ConflictSidePanel';
import UploadWorkloadPlanModal from './WorkloadPlan/Modals/UploadModal';
import DownloadWorkloadPlanModal from './WorkloadPlan/Modals/DownloadModal';
import DuplicateWorkloadPlanModal from './WorkloadPlan/Modals/DuplicateModal';
import ConflictWorkloadPlanModal from './WorkloadPlan/Modals/ConflictModal';

export default {
  name: 'ActionsBar',
  components: {
    ToolbarActionItems,
    ToolbarDropdownActionItems,
    CreateFromTemplateModal,
    CreateTemplateModal,
    DisplayOptionsModal,
    EraseShiftModal,
    PrintModal,
    EsignatureUpsellModal,
    SortModal,
    IntermediateValidatePeriodModal,
    RequestEsignatureUpsellModal,
    UploadWorkloadPlanModal,
    DownloadWorkloadPlanModal,
    DuplicateWorkloadPlanModal,
    ConflictWorkloadPlanModal,
    ConflictWorkloadPlanSidePanel,
    PublishPlanningAction,
  },
  data() {
    return {
      publishTextOverflowing: false,
      isMenuVisible: true,
    };
  },
  computed: {
    ...mapState('currentLicense', ['currentLicense']),
    ...mapState('planningsState', [
      'weeklyOptionsLoading',
      'monthlyOptionsLoading',
    ]),
    ...mapState('planningsLoading', ['lastFetchParams', 'planningDataStatus']),
    ...mapGetters('planningsLoading', ['isLoadingInitialData', 'isProgressiveLoadingEnabled']),
    ...mapGetters('planningsState', ['isWeeklyView', 'isMonthlyView', 'areFiltersActive', 'isDailyView']),
    ...mapGetters('currentOrganisation', ['checkPackOfferFlag']),
    isWeeklyOptionsLoading() {
      return this.isWeeklyView && this.weeklyOptionsLoading;
    },
    isMonthlyOptionsLoading() {
      return this.isMonthlyView && this.monthlyOptionsLoading;
    },
    isPublishDisplayed() {
      return this.currentLicense.attributes.canPublishPlanning;
    },
    isDropdownDisplayed() {
      return !this.isMonthlyView;
    },
    isWorkloadPlanEnabled() {
      return (this.isDailyView && this.checkPackOfferFlag('workload_plan_enabled'));
    },
    isLoadingInitialDataWithOptions() {
      return this.isLoadingInitialData && (
        this.isWeeklyOptionsLoading ||
        this.isMonthlyOptionsLoading
      );
    },
  },
  watch: {
    planningDataStatus(newValue, oldValue) {
      if (
        this.isProgressiveLoadingEnabled &&
        newValue === PLANNING_DATA_STATUS.FIRST_BATCH_LOADED &&
        oldValue === PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED &&
        this.areFiltersActive
      ) {
        this.$root.$emit('need-all-planning-data');
      }
    },
  },
  mounted() {
    this.$root.$on('need-all-planning-data', this.triggerFetchBatchableDataNonRecursively);
  },
  beforeDestroy() {
    this.$root.$off('need-all-planning-data', this.triggerFetchBatchableDataNonRecursively);
  },
  methods: {
    ...mapActions('planningsLoading', ['fetchBatchableDataNonRecursively']),
    redirectToConflictsModal() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'upload-workload-plan-modal');
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'conflict-workload-plan-modal');
    },
    triggerFetchBatchableDataNonRecursively() {
      const {
        shopId,
        period,
        fetchDayRateUsersDaysWorked,
        batchSize,
        isLastUsersBatch,
      } = this.lastFetchParams;

      this.fetchBatchableDataNonRecursively({
        shopId,
        period,
        fetchDayRateUsersDaysWorked,
        batchSize,
        usersBatchPage: 2,
        isLastUsersBatch,
      });

      // Prevent scrolling in planning to re-fetch data
      this.$root.$off('scrolling');
    },
  },
};
</script>

<style lang="scss" scoped>
.planning__toolbar-actions-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.planning__toolbar__vertical-border {
  width: 25px;
  height: 0;
  border: .1px solid $sk-grey-10;
  transform: rotate(90deg);
}
</style>

<!--
This unscoped style tag is used to override the SkPopover default styles in 2 cases :
- hiding/displaying dropdown and toolbar icon at 1200px breakpoints
- matching design requirements for the coming soon tooltip.
-->
<style lang="scss">
.planning__toolbar-actions-bar {
  .sk-popover--tooltip {
    padding: 5px 8px;
    font-size: $fs-text-s;
    text-align: center;
    max-width: 155px;

    * {
      margin: 0;
      padding: 0;
    }
  }

  // Manage action bar on small screen (< 1200px)
  .hide-on-small-screen {
    display: block;
  }

  .planning__toolbar__settings-menu {
    a.settings-dropdown__item.display-on-small-screen {
      padding: 10px 15px;
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .hide-on-small-screen {
      display: none;
    }

    .planning__toolbar__settings-menu {
      a.settings-dropdown__item.display-on-small-screen {
        display: flex;
      }
    }
  }
}
</style>
