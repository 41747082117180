<template>
  <div class="user-row">
    <div class="user-row__user-info">
      <SkMedallion
        :text="userInitials"
        background-color="#2b66fe"
        color="white"
        class="user-row__user-info__medallion"
      />
      <span class="user-row__user-info__fullname">
        {{ userFullName }}
      </span>
    </div>

    <SkInputGroup class="user-row__input">
      <SkInput
        v-model="userBalance"
        :placeholder="$t('shop_settings.rtt_modal.balance.placeholder')"
        type="number"
        step="1"
      />
      <template #append>
        {{ $t('shop_settings.rtt_modal.balance.days') }}
      </template>
    </SkInputGroup>
  </div>
</template>

<script>

export default {
  name: 'UserBalanceRow',
  props: {
    userFullName: {
      type: String,
      required: true,
    },
    userInitials: {
      type: String,
      required: true,
    },
    initializationCounter: {
      type: Number,
      default: null,
    },
  },
  computed: {
    userBalance: {
      get() {
        return this.initializationCounter;
      },
      set(newValue) {
        this.$emit('new-balance', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.user-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px $sk-grey-10 solid;
  padding: 8px 0;

  &__input {
    width: 132px;
  }

  &__user-info {
    display: flex;
    align-items: center;

    &__medallion {
      margin-right: 13px;
    }

    &__fullname {
      max-width: 350px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
