<template>
  <img
    width="50"
    src="./images/logo-payfit.png"
  >
</template>

<script>
export default {
  name: 'Payfit',
};
</script>
