<template>
  <SkPopover
    ref="scheduleRecommendationSkPopover"
    class="schedule-recommendation-popover"
    placement="top"
    y-offset="4"
    @hide-popover="handlePopoverClose"
  >
    <template #anchor>
      <div class="schedule-recommendation-popover__anchor" />
    </template>
    <template #content>
      <div class="schedule-recommendation-popover__header">
        <div class="schedule-recommendation-popover__tag">
          <span>
            {{ $t('automatic_planning.popover.label') }}
          </span>
        </div>
      </div>
      <div>
        <div v-if="isFailureStep">
          <div class="schedule-recommendation-popover__description--no-title">
            <p>
              {{ $t('automatic_planning.popover.create_failure.content') }}
            </p>
          </div>
          <div class="schedule-recommendation-popover__buttons--flex-end">
            <SkOroraButton @click.stop="handlePopoverCloseOnFailure">
              {{ $t('automatic_planning.popover.disabled.buttons.close_label') }}
            </SkOroraButton>
          </div>
        </div>
        <div v-else-if="isDeactivationStep">
          <div class="schedule-recommendation-popover__description--no-title">
            <p>
              {{ $t('automatic_planning.popover.deactivation_request.content') }}
            </p>
          </div>
          <div class="schedule-recommendation-popover__buttons--flex-end">
            <SkOroraButton
              variant="secondary"
              @click.stop="handlePopoverCloseOnDeactivation"
            >
              {{ $t('automatic_planning.popover.deactivation_request.buttons.stop_label') }}
            </SkOroraButton>
            <SkOroraButton
              @click.stop="handlePopoverCloseOnKeepActivated"
            >
              {{ $t('automatic_planning.popover.deactivation_request.buttons.continue_label') }}
            </SkOroraButton>
          </div>
        </div>
        <div v-else-if="isCreationStep">
          <div class="schedule-recommendation-popover__description">
            <h3>
              {{ $t('automatic_planning.popover.create_loading.title') }}
            </h3>
            <p>
              {{ $t('automatic_planning.popover.create_loading.content') }}
            </p>
          </div>
          <div class="schedule-recommendation-popover__loading">
            <lottie-player
              autoplay="true"
              loop="true"
              :src="lottieLoading"
              style="width: 120px; height: 120px"
            />
          </div>
        </div>
        <div v-else-if="isSuggestionStep">
          <div class="schedule-recommendation-popover__description">
            <h3>
              {{ $t('automatic_planning.popover.create_success.title') }}
            </h3>
            <p>
              {{ $t('automatic_planning.popover.create_success.content') }}
            </p>
          </div>
          <div class="schedule-recommendation-popover__buttons--flex-end">
            <SkOroraButton
              variant="secondary"
              @click.stop="handleGeneratedScheduleDeletion"
            >
              {{ $t('automatic_planning.popover.create_success.buttons.delete_label') }}
            </SkOroraButton>
            <SkOroraButton @click.stop="handleGeneratedScheduleValidation">
              {{ $t('automatic_planning.popover.create_success.buttons.validate_label') }}
            </SkOroraButton>
          </div>
          <div class="schedule-recommendation-popover__loading--absolute-position">
            <lottie-player
              v-if="showCreationSuccessAnimation"
              background="transparent"
              autoplay="true"
              :src="lottieSuccess"
              style="width: 320px"
            />
          </div>
        </div>
        <div v-else-if="isSurveyStep">
          <div class="schedule-recommendation-popover__description">
            <h3>
              {{ $t(`automatic_planning.popover.deletion_survey.title`) }}
            </h3>
            <ScheduleRecommendationDeletionSurvey
              :form-options="formOptions"
              :selected-option="selectedFormOption"
              @input="handleSelectFormOption"
            />
          </div>
          <div class="schedule-recommendation-popover__buttons--flex-end">
            <SkOroraButton
              :disabled="!selectedFormOption"
              @click.stop="handleSubmitSurvey"
            >
              {{ $t('buttons.submit') }}
            </SkOroraButton>
          </div>
        </div>
      </div>
    </template>
  </SkPopover>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';
import { ACTION_COUNT_TYPES } from '@skello-utils/smart_planner/utils';

import ScheduleRecommendationDeletionSurvey from './ScheduleRecommendationDeletionSurvey';
import lottieLoadingJson from './lotties/lottie-loading.json';
import '@lottiefiles/lottie-player';

const INITIATOR = {
  toolbar_button: 'toolbar_button',
  empty_schedule: 'empty_schedule',
};

const initiatorTrackingEventSource = {
  [INITIATOR.toolbar_button]: 'sp_suggested_toolbar_button',
  [INITIATOR.empty_schedule]: 'sp_suggested_empty_schedule',
};

const STEP = {
  SUGGESTION: 'suggestion',
  FAILURE: 'failure',
  DEACTIVATION: 'deactivation',
  CREATION: 'creation',
  SURVEY: 'survey',
};

export default {
  name: 'ScheduleRecommendationPopover',
  components: {
    ScheduleRecommendationDeletionSurvey,
  },
  data() {
    return {
      show: false,
      askDeactivationDone: false,
      showCreationSuccessAnimation: false,
      initiator: '',
      step: STEP.SUGGESTION,
      popoverClosedFromButton: false,
      lottieSuccess: '',
      formOptions: [
        {
          id: 0,
          label: this.$t('automatic_planning.popover.deletion_survey.options.prefer_to_do_it_myself'),
          key: 'prefer_to_do_it_myself',
          value: false,
        },
        {
          id: 1,
          label: this.$t('automatic_planning.popover.deletion_survey.options.does_not_meet_expectations'),
          key: 'does_not_meet_expectations',
          value: false,
        },
        {
          id: 2,
          label: this.$t('automatic_planning.popover.deletion_survey.options.no_time_to_review'),
          key: 'no_time_to_review',
          value: false,
        },
        {
          id: 3,
          label: this.$t('automatic_planning.popover.deletion_survey.options.not_interested'),
          key: 'not_interested',
          value: false,
        },
        {
          id: 4,
          label: this.$t('automatic_planning.popover.deletion_survey.options.not_understood'),
          key: 'not_understood',
          value: false,
        },
        {
          id: 5,
          label: this.$t('automatic_planning.popover.deletion_survey.options.other'),
          key: 'other',
          value: false,
        },
      ],
      selectedFormOption: '',
    };
  },
  computed: {
    ...mapState('planningsAutomaticPlanning', ['brainSettings']),
    ...mapGetters('planningsAutomaticPlanning', [
      'isPlanningElligibleForBrain',
      'isBrainShiftsInPlanning',
      'canAskDeactivation',
    ]),
    isSuggestionStep() {
      return this.step === STEP.SUGGESTION;
    },
    isFailureStep() {
      return this.step === STEP.FAILURE;
    },
    isDeactivationStep() {
      return this.step === STEP.DEACTIVATION;
    },
    isCreationStep() {
      return this.step === STEP.CREATION;
    },
    isSurveyStep() {
      return this.step === STEP.SURVEY;
    },
    lottieLoading() {
      return JSON.stringify(lottieLoadingJson);
    },
  },
  async mounted() {
    const lottieFileJson = await import('./lotties/lottie-success.json');

    this.lottieSuccess = JSON.stringify(lottieFileJson.default);

    this.listenOnRoot('set-schedule-recommendation-popover-visibility', ({
      show = false, // boolean, if true, popover will be displayed
      initiator = '', // string, 'toolbar_button', 'intro_modal' or 'empty_schedule'
    }) => {
      if (initiator === '') {
        throw new Error('Schedule recommendation popover initiator is required.');
      }

      if (this.show === show) {
        return;
      }

      this.show = show;
      this.initiator = initiator;
      this.askDeactivationDone = false;
      this.step = STEP.SUGGESTION;

      // ensure to wait next re-render
      this.$nextTick(() => {
        // avoid flickering
        const timeout = setTimeout(() => {
          if (show) {
            this.handleGenerateSchedule()
              .then(() => this.$refs.scheduleRecommendationSkPopover.open());

            if (this.canAskDeactivation) {
              this.step = STEP.DEACTIVATION;
              return;
            }
          } else {
            this.$refs.scheduleRecommendationSkPopover.hide();
          }

          clearTimeout(timeout);
        }, 10);
      });
    });
  },
  methods: {
    ...mapMutations('planningsAutomaticPlanning', [
      'addBrainActionCount',
      'resetBrainActionCount',
      'setBrainShiftsActivation',
      'setBrainLoading',
      'removeBrainShifts',
    ]),
    ...mapActions('planningsAutomaticPlanning', [
      'fetchBrainShifts',
      'createBrainShiftsOnPlanning',
      'createBrainShiftsOnPlanningLegacy',
      'deleteBrainShiftsOnPlanningLegacy',
    ]),
    handleGenerateSchedule() {
      this.setBrainShiftsActivation(true);
      this.setBrainLoading(true);

      return this.fetchBrainShifts()
        .then(() => {
          this.showCreationSuccessAnimation = true;

          this.$skAnalytics.track('automatic_planning_popover_completed', {
            source: initiatorTrackingEventSource[this.initiator],
          });

          const st = setTimeout(() => {
            this.showCreationSuccessAnimation = false;
            clearTimeout(st);
          }, 2000);
        })
        .catch(error => {
          console.error(error);
          this.step = STEP.FAILURE;
          this.$skAnalytics.track('automatic_planning_popover_error', {
            source: initiatorTrackingEventSource[this.initiator],
          });
        })
        .finally(() => {
          this.setBrainLoading(false);
        });
    },
    handleGeneratedScheduleDeletion() {
      this.$skAnalytics.track('automatic_planning_popover_deleted', {
        source: initiatorTrackingEventSource[this.initiator],
      });

      this.addBrainActionCount(ACTION_COUNT_TYPES.deleteSchedule);

      if (this.brainSettings.actionCount[ACTION_COUNT_TYPES.displayDeleteSurvey] < 1) {
        this.$skAnalytics.track('automatic_planning_popover_survey');
        this.step = STEP.SURVEY;
        return;
      }

      if (this.canAskDeactivation) {
        this.step = STEP.DEACTIVATION;
        return;
      }

      this.closePopoverFromButton();
    },

    async handleGeneratedScheduleValidation() {
      if (this.step === STEP.CREATION) return;
      this.step = STEP.CREATION;
      try {
        await this.createBrainShiftsOnPlanning();

        this.resetBrainActionCount(ACTION_COUNT_TYPES.closePopover);
        this.resetBrainActionCount(ACTION_COUNT_TYPES.deleteSchedule);

        this.$skAnalytics.track('automatic_planning_popover_validated', {
          source: initiatorTrackingEventSource[this.initiator],
        });
      } catch (error) {
        console.error(error);
        this.$skAnalytics.track('automatic_planning_popover_error', {
          source: initiatorTrackingEventSource[this.initiator],
        });
      } finally {
        this.closePopoverFromButton();
      }
    },
    handlePopoverCloseOnFailure() {
      this.closePopoverFromButton();
    },
    handlePopoverClose() {
      if (!this.popoverClosedFromButton) {
        this.addBrainActionCount(ACTION_COUNT_TYPES.closePopover);
        this.$skAnalytics.track('automatic_planning_popover_closed');
      }

      if (this.step === STEP.CREATION) {
        this.$skToast({
          message: this.$t('automatic_planning.toaster.shifts_created'),
          variant: 'success',
        });
      } else {
        this.$skToast({
          message: this.$t('automatic_planning.toaster.shifts_deleted'),
          variant: 'success',
        });
      }

      this.removeBrainShifts();
      this.show = false;
      this.popoverClosedFromButton = false;
    },
    handlePopoverCloseOnDeactivation() {
      this.$skAnalytics.track('automatic_planning_popover_deactivated', {
        source: initiatorTrackingEventSource[this.initiator],
      });
      this.setBrainShiftsActivation(false);
      this.resetBrainActionCount(ACTION_COUNT_TYPES.closePopover);
      this.resetBrainActionCount(ACTION_COUNT_TYPES.deleteSchedule);
      this.askDeactivationDone = true;
      this.closePopoverFromButton();
    },
    handlePopoverCloseOnKeepActivated() {
      this.$skAnalytics.track('automatic_planning_popover_keep_activated', {
        source: initiatorTrackingEventSource[this.initiator],
      });
      this.resetBrainActionCount(ACTION_COUNT_TYPES.closePopover);
      this.resetBrainActionCount(ACTION_COUNT_TYPES.deleteSchedule);
      this.askDeactivationDone = true;
      this.closePopoverFromButton();
    },
    closePopoverFromButton() {
      this.popoverClosedFromButton = true;
      this.$refs.scheduleRecommendationSkPopover.hide();
    },
    handleSubmitSurvey() {
      this.$skAnalytics.track('automatic_planning_popover_survey_submitted', { deletion_survey_response: this.selectedFormOption });
      this.addBrainActionCount(ACTION_COUNT_TYPES.displayDeleteSurvey);
      this.closePopoverFromButton();
    },
    handleSelectFormOption(newValue) {
      this.selectedFormOption = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-recommendation-popover {
  .schedule-recommendation-popover__loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -34px;
    overflow: hidden;
    height: 110px;
  }

  .schedule-recommendation-popover__loading--absolute-position {
    position: absolute;
    top: -100px;
    pointer-events: none;
  }

  .schedule-recommendation-popover__anchor {
    height: 44px;
  }

  .sk-popover-content {
    position: relative;

    div.schedule-recommendation-popover__tag {
      background: $sk-blue-5;
      border-radius: 6px;
      position: absolute;
      top: 16px;
      left: 16px;
      padding: 2px 8px;
      z-index: 999;

      span {
        font-style: normal;
        font-weight: $fw-semi-bold;
        font-size: $fs-text-s;
        line-height: 16px;
        color: $sk-blue-50;
      }
    }

    div.schedule-recommendation-popover__description {
      width: 376px;

      &--no-title,
      &--no-content {
        width: 350px;
        margin-top: 16px;

        p {
          margin-top: 16px;
          word-break: normal;
        }
      }

      h3 {
        margin-top: 16px;
        color: $sk-black;
        font-style: normal;
        font-weight: $fw-semi-bold;
        font-size: $fs-text-l;
        line-height: 24px;
        word-break: normal;
      }

      p {
        word-break: normal;
      }
    }

    div.schedule-recommendation-popover__buttons {
      display: flex;
      flex: auto;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 16px;
      gap: 6px;

      &--flex-end {
        display: flex;
        flex: auto;
        flex-direction: row;
        padding: 0 0 16px;
        justify-content: flex-end;
        position: relative;
        gap: 6px;
      }

      a {
        padding-top: 8px;
        color: $sk-black;
        text-decoration: underline;
      }
    }
  }
}
</style>
