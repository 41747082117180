<template>
  <aside
    v-if="currentShop && !loading"
    class="requests__sidebar"
  >
    <div class="sidebar__menu">
      <router-link
        v-if="showTeamsRequests"
        :to="{ name: teamsRequestsRouteName }"
        data-test="sidebar__menu__teams-requests-link"
      >
        <div class="requests__sidebar-link--team">
          <TeamV2Icon fill="" />
          <div
            v-if="canShowPendingRequestsBadge"
            class="sidebar__menu-badge"
          >
            {{ decoratedPendingRequests }}
          </div>
        </div>
        <span>{{ teamsRequestsItemName }}</span>
      </router-link>
      <div v-tooltip="ownRequestsLinkDisabledTooltip">
        <div :class="disabledClassName">
          <router-link
            v-if="showOwnRequests"
            :class="disabledClassName"
            :to="{
              name: ownRequestRouteName,
              query: { ...$route.query?.cluster_node_id &&
                { cluster_node_id: $route.query.cluster_node_id }
              }}"
            data-test="sidebar__menu__my-requests-link"
          >
            <PalmTreeIcon
              v-if="isLeaveRequestsView"
              fill=""
            />
            <ClipboardV2Icon
              v-else
              fill=""
            />
            <span>{{ ownRequestsItemName }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import {
  mapGetters,
  mapState,
} from 'vuex';

export default {
  name: 'RequestsSidebar',
  computed: {
    ...mapGetters('currentLicense', [
      'canCreateSelfAvailabilities',
      'canCreateSelfLeaveRequests',
      'canManageEmployeeRequests',
    ]),
    ...mapState('currentOrganisation', ['currentOrganisation', 'currentNodeShops']),
    ...mapState('currentShop', ['currentShop']),
    ...mapState('requests', ['pendingRequestCounts']),
    ...mapState('currentUser', ['currentUser', 'loading']),
    ...mapGetters('requests', ['decoratedPendingLeaveRequests', 'decoratedPendingAvailabilityRequests']),
    ...mapGetters('currentUser', ['currentUserInPlanningShopIds']),
    isLeaveRequestsView() {
      return this.$route.name.includes('leave_requests');
    },
    showOwnRequests() {
      return this.isLeaveRequestsView ?
        this.canCreateSelfLeaveRequests :
        this.canCreateSelfAvailabilities;
    },
    showTeamsRequests() {
      // same permission for both teams pages
      return this.canManageEmployeeRequests;
    },
    ownRequestsItemName() {
      return this.isLeaveRequestsView ?
        this.$t('requests.leave_requests.own.navigation') :
        this.$t('requests.availability_requests.own.navigation');
    },
    ownRequestsLinkDisabledTooltip() {
      if (!this.isOwnRequestsButtonDisabled) return null;

      return this.isLeaveRequestsView ?
        this.$t('requests.leave_requests.own.disabled') :
        this.$t('requests.availability_requests.own.disabled');
    },
    ownRequestRouteName() {
      return this.isLeaveRequestsView ? 'leave_requests_own' : 'availability_requests_own';
    },
    teamsRequestsItemName() {
      return this.isLeaveRequestsView ?
        this.$t('requests.leave_requests.teams.navigation') :
        this.$t('requests.availability_requests.teams.navigation');
    },
    teamsRequestsRouteName() {
      return this.isLeaveRequestsView ? 'leave_requests_teams' : 'availability_requests_teams';
    },
    canShowPendingRequestsBadge() {
      if (!this.canManageEmployeeRequests) {
        return false;
      }

      return this.isLeaveRequestsView ?
        this.pendingRequestCounts.pendingLeaveRequestsCount > 0 :
        this.pendingRequestCounts.pendingAvailabilitiesCount > 0;
    },
    decoratedPendingRequests() {
      return this.isLeaveRequestsView ?
        this.decoratedPendingLeaveRequests :
        this.decoratedPendingAvailabilityRequests;
    },
    isOwnRequestsButtonDisabled() {
      let isDisabled = !this.currentUserInPlanningShopIds.length;

      if (this.currentShop.id !== 'all') {
        const currentShopId = parseInt(this.currentShop.id, 10);
        isDisabled = !this.currentUserInPlanningShopIds.includes(currentShopId);
      }

      return isDisabled;
    },
    disabledClassName() {
      return this.isOwnRequestsButtonDisabled ? 'requests__sidebar-link--disabled' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.requests__sidebar {
  margin: 32px;
  min-width: 246px;
}

.sidebar__menu {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $sk-grey-5;
  padding: 20px;
  gap: 10px;

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    max-width: 206px;
    max-height: 40px;

    &:hover {
      background: $sk-grey-10;
      border-radius: 4px;
      text-decoration: none;
    }
    .requests__sidebar-link--team {
      position: relative;

      .sidebar__menu-badge {
        position: absolute;
        top: -2px;
        right: -6px;
        display: flex;
        align-items: center;
        font-size: 8px;
        justify-content: center;
        font-weight: $fw-semi-bold;
        background-color: $sk-error;
        color: white;
        width: 10px;
        height: 10px;
        border-radius: 6px;
        line-height: 1px;
      }
    }

    span {
      color: $sk-black;
    }

    svg {
      fill: $sk-black;
    }

    &.router-link-active {
      border-radius: 4px;
      background-color: $sk-blue-10;

      &:hover {
        background: $sk-blue-5;
      }

      span {
        color: $sk-blue;
      }

      svg {
        fill: $sk-blue-50;
      }
    }
  }
}

.requests__sidebar-link--disabled {
  pointer-events: none;
  opacity: .5;
}
</style>
